<app-header></app-header>

<main>

  <div class="container">
    <h3 class="dark-blue-heading">
      Reset Password
    </h3>
    <div class="flex justify-center" style="background-color: whitesmoke;height: 100vh;">

      <form (submit)="onResetPassword(resetPasswordForm)" #resetPasswordForm="ngForm" class="theme-form reset-password flex flex-col gap-5 w-1/3" style="margin-top: 12px;">

        <mat-form-field>
          <input matInput type="password" name="oldPassword" placeholder="Old password" [(ngModel)]="oldPassword" required>
          <mat-error *ngIf="!oldPassword">Please enter old password</mat-error>

        </mat-form-field>

        <mat-form-field>
          <input matInput type="password" name="newPassword" placeholder="New password" [(ngModel)]="newPassword" (input)="isPasswordValid()" required>
          <mat-error *ngIf="!newPassword">Please enter new password</mat-error>
        </mat-form-field>
        <password-strength-meter [password]="newPassword" (strengthChange)="strengthChange($event)"></password-strength-meter>

        <mat-form-field>
          <input matInput type="password" name="confirmNewPassword" placeholder="Confirm New password" [(ngModel)]="confirmNewPassword" (input)="isPasswordValid()" required>
          <mat-error *ngIf="!confirmNewPassword">Please enter confirm new password</mat-error>
          <mat-error *ngIf="passwordError">{{passwordError}}</mat-error> 
        </mat-form-field>
        <mat-error *ngIf="error">
          <pre style="text-align: left;">{{error}}</pre>
        </mat-error>

        <div class="profile-action">
          <button class="theme-btn" mat-raised-button class="dn-button-primary">Reset password</button>
        </div>
        <div style="text-align: left; font-size: 11px;line-height: 7px;">
          <h1 style="margin-bottom: 14px; font-size: 14px;"><b>Password Policy</b></h1>
          <p>1. Require at least 1 Upper case</p>
          <p>2. Require at least 1 Lower case </p>
          <p>3. Require at least 1 number</p>
          <p>4. Require at least 1 special character</p>
          <p>5. Min Length: 10</p>
      </div>

      </form>

    </div>
  </div>
</main>
