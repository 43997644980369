<mat-card>
    <div class="padding flex justify-end items-center">
        <button mat-raised-button class="dn-button-primary" (click)="openConsentFormDialog();" type="button">
            Add New Form
        </button>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

        <ng-container matColumnDef="sr">
            <th mat-header-cell *matHeaderCellDef> Sr </th>
            <td mat-cell *matCellDef="let element; let i = index;">
                {{i+1}}
            </td>
        </ng-container>

        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> Title </th>
            <td mat-cell *matCellDef="let element; let i = index;">
                {{element.title}}
            </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element; let i = index;" [innerHTML]="element.description">
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element; let i = index;">
                <div class="operation-buttons flex flex-inline justify-start items-center gap-5">
                    <span class="material-icons" (click)="openConsentFormDialog(element, i)" matTooltip="View"
                        matTooltipPosition="above" style="cursor: pointer;">
                        remove_red_eye
                    </span>
                    <span class="material-icons" matTooltip="Delete" (click)="onDeleteClick(element._id, i);"
                        matTooltipPosition="above" style="cursor: pointer;">
                        delete_outline
                    </span>
                </div>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</mat-card>