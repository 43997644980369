<app-header></app-header>

<main>

  <div class="h100 flex">
    <div class="left-col">
      <div class="sidebar-content">
        <mat-expansion-panel [expanded]="true">
          <ul>
            <li routerLink="/drive" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                  true}">
              <i class="material-icons">folder</i> <span>My Drive</span>
            </li>
            <ng-container *ngIf="router.url==='/drive'">
              <li (click)="onCreateNewFolderClick()">
                <i class="material-icons">note_add</i> <span>Create New Folder</span>
              </li>
              <li (click)="onUploadFileClick()">
                <i class="material-icons">cloud_upload</i> <span>Upload File</span>
              </li>
              <li routerLink="/drive/shared-with-me" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                    true}" *ngIf="router.url==='/drive'">
                <i class="material-icons">folder_shared</i> <span>Shared with Me</span>
              </li>
            </ng-container>

            <li routerLink="/drive/phrases" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                  true}">
              <i class="material-icons">description</i> <span>Phrases</span>
            </li>

            <li routerLink="/drive/templates" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                  true}">
              <i class="material-icons">description</i> <span>Templates</span>
            </li>

            <ng-container *ngIf="router.url==='/drive/templates' && isVisibleCreateTemplate">
              <li (click)="createNewTemplate()">
                <i class="material-icons">note_add</i> <span>Create New Template</span>
              </li>
              <li class="upload-template-button">
                <label for="uploadTemplate">
                  <input type="file" (change)="uploadFileAndCreateTemplate($event)" accept=".docx">
                  <i class="material-icons">cloud_upload</i> <span>Upload Template</span>
                </label>
              </li>
            </ng-container>
            <div *ngIf="false" >
            <li (click)="authorize(clientId,redirect_uri,scope,url)" *ngIf="!loggedIn">
              <div class="flex justify-start items-center">
                <mat-icon matTooltip="upload">
                  add_to_drive
                </mat-icon> <span>Connect to Google Drive</span>
              </div>
            </li>
            <li (click)="getToken()" *ngIf="loggedIn" routerLink="/drive/googleDrive" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:
                  true}">
              <div class="flex justify-start items-center">
                <mat-icon matTooltip="upload">
                  add_to_drive
                </mat-icon> <span>Google Drive <small *ngIf="this.connects">(Connected)</small></span>
              </div>
            </li>
            <li (click)="signOut()" *ngIf="loggedIn">
              <div class="flex justify-start items-center">
                <mat-icon matTooltip="disconnet" style="color: rgb(194, 28, 28);">
                  drive_file_move_rtl
                </mat-icon> <span style="color: rgb(194, 28, 28);">Logout of Google Drive</span>
              </div>
            </li>
          </div>
          </ul>

        </mat-expansion-panel>
      </div>
    </div>

    <div class="right-col container flex flex-col relative">

      <ng-container [ngSwitch]="url">
        <app-rrgen-explorer *ngSwitchCase="'/drive/rrgen'" class="flex-grow"></app-rrgen-explorer>
        <app-template-explorer *ngSwitchCase="'/drive/templates'" class="flex-grow"></app-template-explorer>
        <app-drive-phrases *ngSwitchCase="'/drive/phrases'" class="flex-grow"></app-drive-phrases>
        <app-shared-file-explorer *ngSwitchCase="'/drive/shared-with-me'" class="flex-grow"></app-shared-file-explorer>
        <app-file-explorer *ngSwitchDefault></app-file-explorer>
        <app-google-drive (connected)="CheckIfLoggedIn($event)" [loggedIn]="loggedIn" *ngSwitchCase="'/drive/googleDrive'" class="flex-grow"></app-google-drive>
      </ng-container>

    </div>
  </div>
</main>