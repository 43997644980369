import { Component, OnInit } from '@angular/core';
import { UserManagementService } from 'src/app/includes/user-management/user-management.service';
import * as global from '../../../../includes/global'
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-company-facility-tabs',
  templateUrl: './company-facility-tabs.component.html',
  styleUrls: ['./company-facility-tabs.component.css']
})
export class CompanyFacilityTabsComponent implements OnInit {
  defaultTabIndex: Number = 0;
  global = global;
  companyId: string;
  company: any;

  constructor(
    private userManagementService: UserManagementService,
    private _route: ActivatedRoute,
    private _companyService: CompanyService,
    private _toastr: ToastrService,
  ) {
    this.companyId = this._route.snapshot.parent.parent.params.id;
  }

  async ngOnInit(): Promise<void> {
    await this.getCompany();
  }

  async getCompany() {
    const filter = {
      _id: this.companyId
    };
    const project = {
      name: 1,
      portal_type: 1
    };
    const { status, data }: any = await lastValueFrom(this._companyService.getCompany(filter, project));
    if (status == 200) {
      this.company = data;
    }
    else if (status === 500) {
      this._toastr.error(data.message, "ERROR");
    }
  }

  changeCurrentTab($event) {
    this.defaultTabIndex = $event.index;
    this.userManagementService.setCurrentTab($event.index);
  }

}
