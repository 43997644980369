import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { PatientRoundingSheetService } from "../../patient-rounding-sheets/patient-rounding-sheet.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../../services/auth.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import * as _ from 'lodash';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChargesCatagoryService } from 'src/app/shared/popup-dialogs/charges-catagories/charges-catagories-dialog/charges-catagory.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
    selector: 'app-manage-charge',
    templateUrl: './manage-charge.component.html',
    styleUrls: ['./manage-charge.component.css']
})
export class ManageChargeComponent implements OnInit {
    displayedColumns: string[] = ['code', 'description', 'favourite'];
    dataSource: MatTableDataSource<any>;
    favouriteDataSource: MatTableDataSource<any>;
    loaderId = ['loader-01', 'loader-02'];
    filter = 'cpt_id';
    rearrangeFavorite: any = [];
    selectedCategory: any;
    categories = [];
    categoryFlag: Boolean = false
    company_id:any;
    favouriteData = {
        cpts: [],
        icds: []
    };
    searchFilter = {
        all: '',
        favourite: ''
    };
    favourite = {
        cpt_id: [],
        icd_id: []
    }
    icds = [];
    cpts = [];
    allData = [];
    public pagination: {
        pageNo: number,
        hasNextPage: boolean,
        hasPrevPage: boolean,
        totalCount: number,
        totalPages: number
    };
    lastValue = '';
    currentUser:any;
    constructor(
        private service: PatientRoundingSheetService,
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private chargesCatagoryService: ChargesCatagoryService,
        private loader: NgxUiLoaderService,
        private commonService: CommonService,
        private authService: AuthService) {
        this.pagination = {
            pageNo: 1,
            hasNextPage: false,
            hasPrevPage: false,
            totalCount: 0,
            totalPages: 0
        };
        this.currentUser = this.authService.currentUser;
    }

    async ngOnInit() {
        this.company_id = this.route.parent.snapshot.params.id
        
        if(this.company_id){

        }
        else{
            this.company_id = this.commonService.castCompany.subscribe(company=>{
                this.company_id = company._id
                // 
            })
        }
        await this.getCategories();
        // await this.getFavoriteCharges();
        // await this.loadData();
        // this.getFavourites();
    }
    async getCategories() {
        let user = this.currentUser._id
        this.categoryFlag = false;
        let result = this.chargesCatagoryService.getCategories(null, user).subscribe((response: any) => {
            if (response.status == 200) {
                this.categories = response.data;
                //inserting ICD because we are using ICD in overall and for CPTs we are using categories but not for ICDs 
                this.categories.push({title:'ICD', type:'ICD'}) 
                if(this.categories.length <= 0){
                    this.toastr.info("No Charge Category is assigned.")
                }
                //   this.categories.map(c => ({cate: }))
                this.selectedCategory = this.categories[0];
                if (this.selectedCategory.type == 'CPT') {
                    this.filter = 'cpt_id'
                }
                else {
                    this.filter = 'icd_id'
                }
                this.categoryFlag = true;
                this.getFavoriteCharges();
                // this.loadData();
            }
        })
    }

    refreshData(category = null) { 

        if (this.selectedCategory.type == 'CPT') {
            this.filter = 'cpt_id'
        }
        else {
            this.filter = 'icd_id'
        }
        this.getFavoriteCharges();
        // this.loadData();
    }
    rearrangeFavoriteCharges(event: CdkDragDrop<[]>) {
        moveItemInArray(this.rearrangeFavorite, event.previousIndex, event.currentIndex);
        let chargesArray = [];
        if(this.filter == 'cpt_id'){
            this.rearrangeFavorite.map(async charge => {
                await chargesArray.push(charge._id);
            })
        }
        else if(this.filter == 'icd_id'){
            this.rearrangeFavorite.map(async charge => {
                await chargesArray.push(charge._id);
            })
        }
        if (this.filter === 'cpt_id') {
            this.favouriteData.cpts = this.rearrangeFavorite;
            this.favouriteDataSource = new MatTableDataSource<any>(this.favouriteData.cpts);
            this.service.rearrangeFavoriteCharges(chargesArray,  this.filter).subscribe((response: any) => {
                if (response.status == 200) {
                    // console.log("response of rearrange ", response);
                }
            })
        }
        else {
            this.favouriteData.icds = this.rearrangeFavorite;
            this.favouriteDataSource = new MatTableDataSource<any>(this.favouriteData.icds);
            this.service.rearrangeFavoriteCharges(chargesArray,this.filter).subscribe((response: any) => {
                if (response.status == 200) {
                    // console.log("response of rearrange ", response);
                }
            })
        }
    }
    //ali
    addToFavoriteCharges(element) { 
        if (this.filter == 'cpt_id') {
            let data = {
                ids: element.cpt_id_ref,
                isRemove: false,
                filter: this.filter
            };
            let cpts = this.service.setFavoriteCPTs(data).subscribe((response: any) => {
                if (response.status == 200) {
                    this.getFavoriteCharges();
                    // this.loadData(this.pagination.pageNo);
                }
            })
        }
        else {
            let data = {
                //uncommet and comment next two lines respectively to use get categorised charges
                // ids: element.icd_id_ref,
                ids: element._id,
                isRemove: false,
                filter: this.filter
            }; 
            let cpts = this.service.setFavoriteICDs(data).subscribe((response: any) => {
                if (response.status == 200) {
                    this.getFavoriteCharges();
                    // this.loadData(this.pagination.pageNo);
                }
            })
        } 
    }
    //ali
    async getFavoriteCharges() { 
        this.loader.startLoader('loader-02');
        if (this.filter == 'cpt_id') {
            this.service.getFavoriteCPTs(this.filter).subscribe((response: any) => {
                if (response.status == 200) {
                    this.favouriteData.cpts = response.data.array;
                    this.rearrangeFavorite = response.data.array; 
                    this.loadData();
                    this.loader.stopLoader('loader-02');
                    this.favouriteDataSource = new MatTableDataSource<any>(this.favouriteData.cpts);
                }
            })
        }
        else if (this.filter == 'icd_id') {
            this.service.getFavoriteICDs(this.filter).subscribe((response: any) => {
                if (response.status == 200) {
                    this.favouriteData.icds = response.data.array;
                    this.rearrangeFavorite = response.data.array;
                    this.loadData();
                    this.loader.stopLoader('loader-02');
                    this.favouriteDataSource = new MatTableDataSource<any>(this.favouriteData.icds);
                }
            })
        }
    }
    //ali
    isFavourite(element) {
        this.rearrangeFavorite.map(charge => {
            if (charge._id == element._id) {
                return true;
            }
            else {
                return false;
            }
        })
    }
    //ali
    removeFromFavourite(element, fav=null) {  
        if (this.filter == 'cpt_id') {
            let data = {
                ids: fav?element._id:element.cpt_id_ref,
                isRemove: true,
                filter: this.filter
            };
            this.service.setFavoriteCPTs(data).subscribe((response: any) => {
                if (response.status == 200) {
                    this.getFavoriteCharges();
                    // this.loadData(this.pagination.pageNo);
                }
            })
        }
        else {
            let data = {
                ids: fav?element._id:element.icd_id_ref,
                isRemove: true,
                filter: this.filter
            };
            this.service.setFavoriteICDs(data).subscribe((response: any) => {
                if (response.status == 200) {
                    this.getFavoriteCharges();
                    // this.loadData(this.pagination.pageNo);
                }
            })
        }
    }

    searchCharges(value) {
        if (this.lastValue !== '' && value === '') {
            this.loadData(1, '');
            return;
        }
        this.lastValue = value;
        if (value !== '') {
            // setTimeout(function(){    
            //     console.log("calling....");

            this.loadData(1, value);
            // }, 500)
            return;
        }
    } 

    loadData(page = 1, search = '') { 
        // if(this.selectedCategory.type == "CPT"){
        if (!search && search == '') {
            this.loader.startLoader('loader-01');
        }  
        let cr_id = this.selectedCategory._id;
        if (this.selectedCategory.type == "CPT") {
            let result = this.chargesCatagoryService.getCPTCategorisedCharges(cr_id).subscribe((res: any) => {
                if (res.status == 200) {  
                    //@ts-ignore
                    for (let i = 0; i < res.data.array.length; i++) {
                        for (let j = 0; j < this.rearrangeFavorite.length; j++) {
                            //@ts-ignore
                            if (this.rearrangeFavorite[j]._id == res.data.array[i].cpt_id_ref) {
                                //@ts-ignore
                                res.data.array[i].isFavourite = true
                                break;
                            } else {
                                //@ts-ignore
                                res.data.array[i].isFavourite = false
                            }
                        }
                    } 
                    // @ts-ignore
                    this.cpts = res.data.array;
                    this.allData = this.cpts; 
                    this.dataSource = new MatTableDataSource(this.cpts);
                    this.loader.stopLoader('loader-01'); 
                }
            })
        }
        else if (this.selectedCategory.type == "ICD") { 
            if (!search && search == '') {
                this.loader.startLoader('loader-01');
            }
            //uncomment first if and comment second to use categorised Charges
            // let result = this.chargesCatagoryService.getICDCategorisedCharges(cr_id).subscribe((res: any) => {
            let result = this.service.getICDs({ page: page, search: search }).subscribe((res: any) => {
                if (res.status == 200) { 
                   // @ts-ignore
                    for (let i = 0; i < res.data.array.length; i++) {
                        for (let j = 0; j < this.rearrangeFavorite.length; j++) { 
                            //uncomment first if and comment second to use categorised Charges
                            // if (this.rearrangeFavorite[j]._id == res.data.array[i].icd_id_ref) {
                            if (this.rearrangeFavorite[j]._id == res.data.array[i]._id) {
                                //@ts-ignore
                                res.data.array[i].isFavourite = true
                                break;
                            } else {
                                //@ts-ignore
                                res.data.array[i].isFavourite = false
                            }
                        }
                    } 
                    // @ts-ignore
                    // this.cpts = res.data.array;
                    this.icds = res.data.array;
                    this.allData = this.cpts;
                    //@ts-ignore
                    const totalCount = res.data.totalCount;
                    this.pagination.totalCount = totalCount;
                    this.pagination.pageNo = page;
                    this.initPagination();
                    this.dataSource = new MatTableDataSource(this.icds);
                    this.loader.stopLoader('loader-01'); 
                }
            })
        }
 
    } 
    initPagination() {
        this.pagination.totalPages = Math.ceil(this.pagination.totalCount / 10);
        if (this.allData) {
            this.pagination.hasNextPage = this.allData.length > 0 && this.pagination.pageNo < this.pagination.totalPages;
        }
        this.pagination.hasPrevPage = this.pagination.pageNo > 1;
    }

    handleNextClick() {
        this.loadData(this.pagination.pageNo + 1, this.searchFilter.all);
    }

    handlePrevClick() {
        this.loadData(this.pagination.pageNo - 1, this.searchFilter.all);
    }
    getCountofCodes() {
        return ((this.pagination.pageNo * 10) - 9) + ' - ' + (((this.pagination.pageNo * 10) - 10) + Number(this.allData.length)) + " out of " + (this.pagination.totalCount);
    }

}
