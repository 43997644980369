import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NoteAddChargeService {
  private isCPTLoaded$ = new BehaviorSubject(false);
  public castIsCPT = this.isCPTLoaded$.asObservable();
  private isICDLoaded$ = new BehaviorSubject(false);
  public castIsICD = this.isICDLoaded$.asObservable();
  constructor() { }

  setisCPT(value) {
    this.isCPTLoaded$.next(value);
  }
  setisICD(value) {
    this.isICDLoaded$.next(value);
  }
}
