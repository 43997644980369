import { Component, OnInit } from '@angular/core';
import { AWSTranscribeService } from 'src/app/services/aws-transcribe.service';

// import mic from 'microphone-stream'; // collect microphone input as a stream of raw bytes

// import audioUtils from './audioUtils';

@Component({
  selector: 'app-transcribe',
  templateUrl: './transcribe.component.html',
  styleUrls: ['./transcribe.component.css'],
})
export class TranscribeComponent implements OnInit {
  ws_url = '';
  deviceNotSupported = false;
  private socket;
  private micStream;
  private inputSampleRate;
  private sampleRate;
  // eventStreamMarshaller = new marshaller.EventStreamMarshaller(util_utf8_node.toUtf8, util_utf8_node.fromUtf8);

  constructor(private awsTranscribeService: AWSTranscribeService) {}

  ngOnInit(): void {
    if (!window.navigator.mediaDevices.getUserMedia) {
      this.deviceNotSupported= true;
    }
    this.initAWSWebSocketUrl();
  }

  async initAWSWebSocketUrl() {
    const response: any = await this.awsTranscribeService
      .getAwsSignedUrl()
      .toPromise();
    if (response.status == 200) {
      this.ws_url = response.data;
    }
  }
/*
  handleStartClick() {
    window.navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true
  })
  // ...then we convert the mic stream to binary event stream messages when the promise resolves 
  .then(this.streamAudioToWebSocket) 
  .catch(function (error) {
    this.deviceNotSupported= true;
  });
  }

  streamAudioToWebSocket(userMediaStream) {
    //let's get the mic input from the browser, via the microphone-stream module
    this.micStream = new mic();
  
    this.micStream.on("format", function(data) {
        this.inputSampleRate = data.sampleRate;
    });
  
    this.micStream.setStream(userMediaStream);
  
    // Pre-signed URLs are a way to authenticate a request (or WebSocket connection, in this case)
    // via Query Parameters. Learn more: https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html
    let url = this.ws_url;
  
    //open up our WebSocket connection
    this.socket = new WebSocket(url);
    this.socket.binaryType = "arraybuffer";
  
    let sampleRate = 0;
  
    // when we get audio data from the mic, send it to the WebSocket if possible
    this.socket.onopen = function() {
        this.micStream.on('data', function(rawAudioChunk) {
            // the audio stream is raw audio bytes. Transcribe expects PCM with additional metadata, encoded as binary
            let binary = this.convertAudioToBinaryMessage(rawAudioChunk);
  
            if (this.socket.readyState === this.socket.OPEN)
                this.socket.send(binary);
        }
    )};
  
    // handle messages, errors, and close events
    this.wireSocketEvents();
  }
  convertAudioToBinaryMessage(audioChunk) {
    let raw = mic.toRaw(audioChunk);
  
    if (raw == null)
        return;
  
    // downsample and convert the raw audio bytes to PCM
    let downsampledBuffer = audioUtils.downsampleBuffer(raw, this.inputSampleRate, this.sampleRate);
    let pcmEncodedBuffer = audioUtils.pcmEncode(downsampledBuffer);
  
    // add the right JSON headers and structure to the message
    let audioEventMessage= this.getAudioEventMessage(Buffer.from(pcmEncodedBuffer));
  
    //convert the JSON object + headers into a binary event stream message
    let binary = this.eventStreamMarshaller.marshall(audioEventMessage as any);
  
    return binary;
  }
  
getAudioEventMessage(buffer) {
  // wrap the audio data in a JSON envelope
  return {
      headers: {
          ':message-type': {
              type: 'string',
              value: 'event'
          },
          ':event-type': {
              type: 'string',
              value: 'AudioEvent'
          }
      },
      body: buffer
  };
}
wireSocketEvents() {
  // handle inbound messages from Amazon Transcribe
  this.socket.onmessage = function (message:any) {
      //convert the binary event stream message to JSON
      // @ts-ignore
      let messageWrapper = this.eventStreamMarshaller.unmarshall(Buffer(message.data));
      let messageBody = JSON.parse(String.fromCharCode.apply(String, messageWrapper.body));
      if (messageWrapper.headers[":message-type"].value === "event") {
          this.handleEventStreamMessage(messageBody);
      }
      else {
          // transcribeException = true;
          // showError(messageBody.Message);
          // toggleStartStop();
      }
  };

  this.socket.onerror = function () {
      // socketError = true;
      // showError('WebSocket connection error. Try again.');
      // toggleStartStop();
  };
  
  this.socket.onclose = function (closeEvent) {
      this.micStream.stop();
      
      // the close event immediately follows the error event; only handle one.
      // if (!socketError && !transcribeException) {
      //     if (closeEvent.code != 1000) {
      //         showError('</i><strong>Streaming Exception</strong><br>' + closeEvent.reason);
      //     }
      //     toggleStartStop();
      // }
  };
}
handleEventStreamMessage  (messageJson) {
  let results = messageJson.Transcript.Results;

  if (results.length > 0) {
      if (results[0].Alternatives.length > 0) {
          let transcript = results[0].Alternatives[0].Transcript;

          // fix encoding for accented characters
          transcript = decodeURIComponent(escape(transcript));

          // update the textarea with the latest result
          // $('#transcript').val(transcription + transcript + "\n");
          console.log(transcript + "\n")

          // if this transcript segment is final, add it to the overall transcription
          if (!results[0].IsPartial) {
              //scroll the textarea down
              // $('#transcript').scrollTop($('#transcript')[0].scrollHeight);

              // transcription += transcript + "\n";
              console.log(transcript + "\n")
          }
      }
  }
}

closeSocket  () {
  if (this.socket.readyState === this.socket.OPEN) {
      this.micStream.stop();

      // Send an empty frame so that Transcribe initiates a closure of the WebSocket after submitting all transcripts
      let emptyMessage = this.getAudioEventMessage(Buffer.from(new Buffer([])));
      // @ts-ignore
      let emptyBuffer = this.eventStreamMarshaller.marshall(emptyMessage);
      this.socket.send(emptyBuffer);
  }
}
*/

}

