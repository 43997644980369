
<div [ngClass]="{'container': (companySide || user_dashboard) && !(nursingSide)}">

    <h3 class="dark-blue-heading">
        <ng-container *ngIf="!user_dashboard">
            <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
                Back
            </button>
        </ng-container>

        <label [ngClass]="{'label-margin': !user_dashboard}"> Kent Visit Report </label>

        <button style="margin-left: auto;" mat-raised-button class="dn-button-primary" (click)="exportVisitReportAsXLSX();">Export
            As XLSX
        </button>
    </h3>

    <div class="search-block theme-form flex justify-start items-center" 
        style="background-color: white; border-radius: 4px; padding-top: 8px; padding-bottom: 5px; padding-left: 8px;padding-right: 8px;">
        
        <div class="flex flex-grow justify-start items-center gap-2">

            <mat-label style="margin-right:20px;color:#999">Date of Creation </mat-label>
            <input style="width: 180px;" type="text" ngxDaterangepickerMd [(ngModel)]="filter.filterServiceDate"
                (datesUpdated)="changefilterServiceDate($event)" class="mat-like-input" [ranges]="preDateRanges"
                [alwaysShowCalendars]="true" [maxDate]="currentDate" [linkedCalendars]="true"
                [showClearButton]="true" />

            <mat-form-field>
                <mat-label>Select Facility</mat-label>
                <input type="text" placeholder="Select Facility" aria-label="facility" matInput
                    [formControl]="facilityControl" [matAutocomplete]="facilityAutoControl">
                <mat-icon matSuffix style="cursor: pointer;" (click)="showFacilityDropdown();">
                    keyboard_arrow_down</mat-icon>
                <mat-autocomplete #facilityAutoControl="matAutocomplete" autoActiveFirstOption>
                    <mat-option (onSelectionChange)="onSelectFacility(facility._id);"
                        *ngFor="let facility of filteredFacilities | async" [value]="facility.title">
                        <app-facility-title [facility]="facility" [loginStatus]="false"></app-facility-title>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Select Provider</mat-label>
                <input type="text" placeholder="Select Provider" aria-label="provider" matInput
                    [formControl]="providerControl" #trigger="matAutocompleteTrigger"
                    [matAutocomplete]="providerAutoControl">
                <mat-icon matSuffix style="cursor: pointer;" (click)="showProviderDropdown()">
                    keyboard_arrow_down</mat-icon>
                <mat-autocomplete #providerAutoControl="matAutocomplete">
                    <mat-option *ngFor="let provider of filteredProviders | async" [value]="provider.full_name"
                        (click)="onSelectProvider(provider._id);">
                        {{provider.full_name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <button mat-raised-button class="dn-button-primary" (click)="handleApplyFilter();">Apply Filter</button>

            <button mat-raised-button class="dn-button-secondary" (click)="resetFilter();">Reset Filter</button>
        </div>

    </div>

    <mat-card class="vertical-height">

        <div class="table-height">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                <ng-container matColumnDef="patient">
                    <th mat-header-cell *matHeaderCellDef> Patient </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.providerGroup.patient_fullName}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="provider">
                    <th mat-header-cell *matHeaderCellDef> Provider </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.providerGroup.fullName}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="facility">
                    <th mat-header-cell *matHeaderCellDef> Facility </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.providerGroup.facilityTitle}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="sub_seq">
                    <th mat-header-cell *matHeaderCellDef> Subseq </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.providerGroup.subSeqCount}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="initial">
                    <th mat-header-cell *matHeaderCellDef> Initial </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.providerGroup.initialCount}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="grand_total">
                    <th mat-header-cell *matHeaderCellDef> Grand Total </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.grandTotal}}
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
        </div>
        <br>
        <div class="flex justify-between items-center">
            <b>Subsequent Notes Total: {{subsequentTotalNotes}}</b>
            <b>Initial Notes Total: {{initialTotalNotes}}</b>
            <b> Grand Total: {{grandTotal}}</b>
        </div>
    </mat-card>
</div>