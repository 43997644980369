<div [ngClass]="{'container': (isCompanySide || user_dashboard) && !(nursingSide)}" class="flex flex-col">
    <h3 class="dark-blue-heading flex justify-between items-center">
        <ng-container *ngIf="!user_dashboard">
            <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
                Back
            </button>
        </ng-container>

        <label style="white-space: nowrap;"> Wound Assessment Dashboard </label>
        <div class="flex justify-center items-end" style="color: black !important;">
            <input matInput type="text" ngxDaterangepickerMd [(ngModel)]="updationDate" class="mat-like-input"
                [ranges]="preDateRanges" [alwaysShowCalendars]="true" [maxDate]="currentDate" [linkedCalendars]="true"
                [showClearButton]="false" (datesUpdated)="changeUpdationDate($event)" class="date-range-picker" />
        </div>
        <div class="flex flex-row gap-2"></div>
    </h3>

    <mat-card class="wound-assesment-dashboard-card flex flex-col">
        <div class="flex justify-start items-center gap-1">
            <mat-form-field appearance="fill" class="flex-grow">
                <mat-label>Select Facility</mat-label>
                <div class="flex justify-start items-center gap-1">
                    <img *ngIf="selectedFacility" class="facility-icon"
                        [src]="selectedFacility.source === 'PointClickCare' ? '../../../../../../assets/icons/pcc-icon.svg'
            : (selectedFacility.source === 'MatrixCare' ? '../../../../../../assets/icons/matrix.png' : '../../../../../../assets/img/DN.png')" alt="">
                    <input type="text" placeholder="Select Facility" aria-label="facility" matInput
                        [formControl]="facilityControl" (input)="onChangeFacilityText($event);"
                        [matAutocomplete]="facilityAutoControl">
                </div>
                <mat-autocomplete #facilityAutoControl="matAutocomplete" autoActiveFirstOption>
                    <mat-option (onSelectionChange)="onSelectFacility($event, facility);"
                        *ngFor="let facility of filteredFacilities | async" [value]="facility.title">
                        <div class="flex justify-start items-center gap-1">
                        <img [src]="facility.source === 'PointClickCare' ? '../../../../../../assets/icons/pcc-icon.svg'
                        : (facility.source === 'MatrixCare' ? '../../../../../../assets/icons/matrix.png' : '../../../../../../assets/img/DN.png')"
                            class="facility-icon" alt="">
                        <span>{{facility.title}}</span>
                    </div>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Patient Name</mat-label>
                <input matInput type="text" placeholder="Search Patient By Name" name="patientName"
                    [(ngModel)]="filter.patientName" (ngModelChange)="searchPatient();" />
                <div matSuffix>
                    <mat-spinner *ngIf="isDataLoaded" [diameter]="18"></mat-spinner>
                </div>
            </mat-form-field>

            <div class="flex justify-end items-center gap-1" class="bottom-row">
                <button mat-raised-button class="dn-button-primary" (click)="handlePageNavigation(true)"
                    [disabled]="!pagination.hasPrevPage"> Prev</button>

                <span class="single-line-text">
                    {{pagination.skip + companyFacilityPatients.length === 0 ? 0 : pagination.skip + 1 }} -
                    {{pagination.skip +
                    companyFacilityPatients.length}} out of {{pagination.totalRecords}}
                </span>
                <button mat-raised-button class="dn-button-primary" (click)="handlePageNavigation(false)"
                    [disabled]="!pagination.hasNextPage">Next</button>
            </div>
        </div>

        <div *ngIf="selectedPatients.length > 0" class="flex justify-start items-center gap-1">
            <div style="width: max-content;">Selected Patients: {{selectedPatients.length}}</div>

            <mat-form-field>
                <mat-select #censusSelect>
                    <mat-option selected value="" [disabled]="true">
                        Select Patient Census
                    </mat-option>
                    <mat-option *ngFor="let census of censuses" value="{{census._id}}">
                        {{census.date_obj.month}}/{{census.date_obj.day}}/{{census.date_obj.year}}
                        - {{census.name}} -
                        {{census.facility.title | uppercase}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-raised-button class="dn-button-primary" (click)="handleAddToCensus(censusSelect.value);">
                Add to Census
            </button>
        </div>

        <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

        <table mat-table matSort matSortDisableClear [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8"
        (matSortChange)="sortData($event)">

            <ng-container matColumnDef="Expansion Icon">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
                <td mat-cell *matCellDef="let element" style="width: 0px;">
                    <button mat-icon-button aria-label="expand row">
                        <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                        <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="Checkbox">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <mat-checkbox *ngIf="_authService.currentUser.user_type != global.USER_TYPE.WOUND_NURSE 
                        && _authService.currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER"
                        (change)="onSelectPatient($event, element);" (click)="$event.stopPropagation();"></mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.full_name}}
                </td>
            </ng-container>

            <ng-container matColumnDef="Date Of Birth">
                <th mat-header-cell *matHeaderCellDef> Date Of Birth </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.date_of_birth}}
                </td>
            </ng-container>

            <ng-container matColumnDef="Facility">
                <th mat-header-cell *matHeaderCellDef> Facility </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.facility_id.title}}
                </td>
            </ng-container>

            <ng-container matColumnDef="MRN">
                <th mat-header-cell *matHeaderCellDef> MRN </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.patient_id.source === 'PointClickCare' ?
                    element.pcc_mrn_no : element.dn_mrn_no}}
                </td>
            </ng-container>

            <ng-container matColumnDef="Last Seen">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="last_seen">
                    <span class="material-symbols-outlined sort-icon"> sort </span>
                    Last Seen
                  </th>
                <td mat-cell *matCellDef="let element; let i = index;" [innerHTML]="getLastSeen(element)">
                    {{element.patient_id.last_seen}}
                </td>
            </ng-container>

            <ng-container matColumnDef="accordion">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="accordion-cell"
                        [@accordionTrigger]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <mat-tab-group *ngIf="expandedRows.includes(element._id);" class="expanded-child"
                            [(selectedIndex)]="tabIndex" (selectedTabChange)="onTabChange($event);">
                            <mat-tab label="Wounds">
                                <app-wound-care-widget *ngIf="tabIndex === 0" [readOnlyMode]=true
                                    [patient]="element.patient_id" [facility_id]="filter.facility_id"></app-wound-care-widget>
                            </mat-tab>
                            <mat-tab label="Patient Notes">
                                <app-patient-detail-list *ngIf="tabIndex === 1" [patient]="element.patient_id"
                                    [isTelehealthReport]="true"></app-patient-detail-list>
                            </mat-tab>
                            <mat-tab label="Patient Documents">
                                <app-patient-documents-list *ngIf="tabIndex === 2"
                                    [patient]="element.patient_id"></app-patient-documents-list>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" (click)="expandedElement = expandedElement === element ? null : element;
            toggleRow(element._id); tabIndex = 0;">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['accordion']" class="accordion-row"></tr>

        </table>

    </mat-card>
</div>