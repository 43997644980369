import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DashboardService } from '../../dashboard/dashboard.service';
import { MatTableDataSource } from "@angular/material/table";
import { HttpResponse } from '@angular/common/http';
import * as global from '../../global';
import { API_URL } from '../../../../environments/api-url'
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-google-drive',
  templateUrl: './google-drive.component.html',
  styleUrls: ['./google-drive.component.css']
})

export class GoogleDriveComponent implements OnInit {
  @Input() loggedIn;
  token = '';
  files: any;
  displayedColumns: string[] = ['name', 'SharedBy', 'owners', 'createdTime', 'modifiedTime'];
  dataSource: any = [];
  dataSourceArray = [];
  file: any;
  displayName: any;
  @Output()
  connected : EventEmitter<any> = new EventEmitter<any>();
  connects: boolean = false;
  constructor(private dashboardService: DashboardService, private router: Router,private toastr: ToastrService,) { }

  async ngOnInit() {
    setTimeout(() => {
      if (localStorage.getItem('accessToken') == null) {
        this.loggedIn = false;
        this.router.navigate(['/drive'])
      }
    }, 1000);
    setTimeout(async() => {
      if(localStorage.getItem('accessToken')!==null){
        this.token = localStorage.getItem('accessToken');
        await this.getfiles(this.token);
      }

    }, 1000);

  }
  
  async getfiles(token) {
    this.dashboardService.getFiles(token).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.success('Connected Successfully','success');
        this.dataSource = response.data;
        this.connects = true;
        this.connected.emit(this.connects)
        this.dataSource = this.dataSource.filter(row => row.trashed === false);
        this.dataSource.forEach(element => {
          if(element.ownedByMe === true){
            this.displayName = element.owners[0].displayName;
          }
        });
      }
      else {
        this.toastr.error(response.message,'error');
        console.log('not ok response');
      }
    });
  }

  async upload() {
    var accessToken = localStorage.getItem('accessToken')
    const formData = new FormData();
    formData.append('file', this.file);
    formData.append('token', accessToken);
    const response: any = await this.dashboardService.upload(formData).toPromise();
    console.log('ressssponsee',response);
    setTimeout(async () => {
      const res: any = await this.dashboardService.getFiles(this.token).toPromise();
      this.dataSource.push(res.data[0]);
      this.dataSource = res.data;
      this.dataSource = this.dataSource.filter(row => row.trashed === false);
      console.log('FILEEE',this.file);
      this.toastr.success(`${this.file.name} Uploaded successfully`, 'Success');
    }, 2000);
  }

  getItemIcon(item) {
    const templatetypeIconsPath = '../../../assets/img/filetypes';
    if (item.mimeType.includes('spreadsheet')) {
      return (`${templatetypeIconsPath}/xlsx.svg`);
    }
    if (item.mimeType.includes('folder') && item.shared == true) {
      return (`${templatetypeIconsPath}/shared.png`);
    }

    if (item.fileExtension) {
      const { title } = item.name;
      // const extension = title.substr(title.lastIndexOf('.') + 1);
      let extension = item.fileExtension;

      // return (`${templatetypeIconsPath}/unknown.svg`);
      if (item.fileExtension == 'jpeg') {
        extension = 'jpg';
        return (`${templatetypeIconsPath}/${extension}.svg`);
      }
      return (`${templatetypeIconsPath}/${extension}.svg`);
    } else {
      // folder
      return (`../../../assets/img/folder-icon.png`);
    }
  }


  onSelectFile(e) {
    console.log(e.target.files[0]);
    this.file = e.target.files[0];
    this.upload();
  }

  downloadGoogleFile(element,token) {
    console.log('asdasdasdasd', element.id, element.name, element.mimeType);
    try {
      const params = {
        FileId: element.id,
        FileName: element.name,
        token: localStorage.getItem("accessToken")
      }
      return this.dashboardService.downloadFile(global.url + API_URL.ROUTES.downloadGoogleFile, params).subscribe((res: any) => {
        if (res instanceof HttpResponse) {
          console.log('res', res);
          var url = window.URL.createObjectURL(res.body);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = `${element.name}`;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
        }
        if (res.status == 200) {
          return true;
        }
      })
    } catch (error) {
      const { message = 'Something went wrong, please try again' } = error;
    }
  }
}



