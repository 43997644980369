<div [ngClass]="{'container': (isCompanySide || isUserDashboard) && !(nursingSide)}">
    <div class="top-block justify-between items-center">

        <div *ngIf="!shouldShowReport" class="flex justify-between items-center">
            <div class="flex justify-between items-center gap-8">
                <button class="theme-btn" mat-raised-button class="dn-button-secondary" (click)="goBack()">Back</button>
                
                <div class="flex items-center gap-4">
                    <div>
                        <mat-label style="margin-right: 8px; color:#999; white-space: nowrap;">Date of Service </mat-label>
                        <input style="text-align: center;" type="text" ngxDaterangepickerMd
                            [(ngModel)]="filter.last_assessment_date_obj" (datesUpdated)="handleDateOfServiceChange($event)"
                            class="mat-like-input" [ranges]="preDateRanges" [alwaysShowCalendars]="true" [maxDate]="currentDate"
                            [linkedCalendars]="true" />
                    </div>

                    <mat-form-field appearance="fill">
                        <mat-label>Select Facility</mat-label>
                        <div class="flex justify-start items-center gap-1">
                            <img *ngIf="selectedFacility" class="facility-icon"
                                [src]="selectedFacility.source === 'PointClickCare' ? '../../../../../../assets/icons/pcc-icon.svg'
                        : (selectedFacility.source === 'MatrixCare' ? '../../../../../../assets/icons/matrix.png' : '../../../../../../assets/img/DN.png')" alt="">
                            <input type="text" placeholder="Select Facility" aria-label="facility" matInput
                                [formControl]="facilityControl" (input)="onChangeFacilityText($event);"
                                [matAutocomplete]="facilityAutoControl">
                        </div>
                        <mat-autocomplete #facilityAutoControl="matAutocomplete" autoActiveFirstOption>
                            <mat-option (onSelectionChange)="onSelectFacility($event, facility);"
                                *ngFor="let facility of filteredFacilities | async" [value]="facility.title">
                                <div class="flex justify-start items-center gap-1">
                                    <img [src]="facility.source === 'PointClickCare' ? '../../../../../../assets/icons/pcc-icon.svg'
                                    : (facility.source === 'MatrixCare' ? '../../../../../../assets/icons/matrix.png' : '../../../../../../assets/img/DN.png')"
                                        class="facility-icon" alt="">
                                    <span>{{facility.title}}</span>
                                </div>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>

            <button mat-raised-button class="dn-button-primary"
                (click)="initWounds(); shouldShowReport=!shouldShowReport;" [disabled]="!isButtonDisabled">
                Generate Report
            </button>
        </div>

        <ng-container *ngIf="shouldShowReport">
            <div class="flex items-center justify-between gap-4">
                <button type="button" mat-raised-button class="dn-button-secondary" (click)="resetData();">
                    Back
                </button>
    
                <div class="flex justify-start items-center gap-1">
                    <button mat-stroked-button class="dn-button-primary btn-download" matTooltip="Download As XLSX"
                        (click)="exportCustomWoundReportAsXLSX();" [disabled]="wounds.length === 0"
                        matTooltipPosition="above">
                        <span class="material-symbols-outlined">
                            download
                        </span>
                    </button>
    
                    <button mat-raised-button class="dn-button-primary" (click)="saveReport();"
                        [disabled]="wounds.length === 0"> Save </button>
    
                    <div class="flex justify-end items-center gap-1">
                        <button mat-raised-button class="dn-button-primary" (click)="handlePageNavigation(true)"
                            [disabled]="!pagination.hasPrevPage"> Prev</button>
    
                        <span class="single-line-text">
                            {{pagination.skip + wounds.length === 0 ? 0 : pagination.skip + 1 }} -
                            {{pagination.skip +
                            wounds.length}} out of {{pagination.totalRecords}}
                        </span>
                        <button mat-raised-button class="dn-button-primary" (click)="handlePageNavigation(false)"
                            [disabled]="!pagination.hasNextPage">Next</button>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <div style="height: calc(100vh - 167px); width: calc(100vw - 295px); overflow-x: auto; margin-top: 8px;">
        <table *ngIf="shouldShowReport; else elseBlock" mat-table [dataSource]="dataSource" class="mat-elevation-z8 colorHeaderTable"
            matSort>
            <ng-container *ngFor="let column of woundsTable; let index = index;" [matColumnDef]="column.name">
                <th mat-header-cell *matHeaderCellDef>{{column.name}}</th>
                <td mat-cell *matCellDef="let element;">
                    {{element[column.value]}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <ng-template #elseBlock>
        <div class="vertical-height">
            <div class="flex">
                <div class="filters">
                    <div class="filter-header">Data Options</div>
                    <div class="filter-row" *ngFor="let column of columns">
                        <div class="filter-item">
                            <mat-checkbox [checked]="column.isChecked" [value]="column.value"
                                (change)="handleProjection($event, index);">
                                {{column.name}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>

                <div class="column-stage-wrap">
                    <div class="flex gap-2">
                        <div class="column-stage column-parent-wound column"
                            *ngFor="let dataOption of dataOptions; let index=index;">
                            <div class="column-header"><span>{{dataOption.columnName}}</span></div>
                            <div class="rows flex flex-col">
                                <div class="row">
                                    <div class="item" *ngFor="let value of dataOption.values;"
                                        [ngClass]="{'selected': filter[getFieldName(dataOption.columnName)] && filter[getFieldName(dataOption.columnName)]['$in'].includes(value)}"
                                        (click)="toggleClass(value, dataOption.columnName);">
                                        {{value}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </ng-template>

    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

</div>