export class pccFacilityEmailBody {
    email_body: any;
    constructor() {
        this.email_body = `<p>
            Michael Boesveld from PCC provided your contact information.
            <br> <br>
            The medical practitioner already is providing services in the facility mentioned above and is a current
            customer of DoctorNow. DoctorNow is a workflow management platform which allows providers to efficiently
            manage a daily census and create notes. DoctorNow is an established PCC partner, listed on the PCC
            marketplace. For more details about DoctorNow, please visit the PointClickCare Marketplace website at:
            <br> <br>
            <p> https://marketplace.pointclickcare.com/English/directory/partner/1095541/doctornow </p>

            <br> <br> <br>

            There are significant benefits for both the provider and facility. By enabling this PCC-DoctorNow
            integration, facility providers will have all relevant patient information from PCC at their fingertips. The
            facility receives encounter notes directly into the patients PCC chart, ensuring completeness of the medical
            record in a timely manner.
            <br> <br> <br>



            There is <b>NO COST</b> to the skilled nursing facility for this service. This is a formal request to activate the
            integration. See PDF attachment for additional details.

            <br> <br> <br>

            For other questions or concerns, please feel free to reach out anytime.
            <br> <br> <br>



            Thanks!
            <br>

            Kashif
        </p>`;
    }
}