import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NoteAuditoryHistoryService } from '../../note-auditory-history/note-auditory-history.service';
import * as global from '../../global';

@Component({
  selector: 'app-note-delete-icon',
  templateUrl: './note-delete-icon.component.html',
  styleUrls: ['./note-delete-icon.component.css']
})
export class NoteDeleteIconComponent implements OnInit {

  @Input() note;
  showDeleteIcon: boolean = false;
  currentuser: any;
  constructor(private noteAuditoryHistoryService: NoteAuditoryHistoryService, private _authService: AuthService) {
    this.currentuser = this._authService.currentUser;
  }

  ngOnInit(): void {
    if ((this.currentuser.user_type === global.USER_TYPE.TRANSCRIBER || this.currentuser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT) && this.currentuser._id == this.note.uploaded_by_id) {
      this.showDeleteIcon = true;
    }
  }

  getNoteStatus() {
    return this.note.note_status.status === 'not_uploaded' ? 'signed' : this.noteAuditoryHistoryService.formatOperation(this.note.note_status.status);
  }

}
