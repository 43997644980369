import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { RolesService } from 'src/app/services/roles.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface RolesDialogData {
  user:any
}

export interface IRole {
  _id:string,
  title:string
}

@Component({
  selector: 'app-roles-dialog',
  templateUrl: './roles.dialog.html',
  styleUrls: ['./roles.dialog.css']
})
export class RolesDialog implements OnInit {
  loading = false;
  roles:IRole[] = [];
  filteredRoles:IRole[] = [];
  user:any;
  currentUser:any;

  constructor(
    public dialogRef: MatDialogRef<RolesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: RolesDialogData,
    private rolesService:RolesService,
    private toastr:ToastrService,
    private _authService: AuthService
    ) {     
      // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.currentUser = this._authService.currentUser;   
      if(data) {
        this.user = data.user;
      } 

    }

    ngOnInit() {
      this.initRoles();
    }

    initRoles() {
      this.rolesService.getRoles().subscribe((response:any) => {
        if(response.status === 200) {
          this.roles = response.data;
          this.filteredRoles = response.data.slice();
        }
      })
    }

    applyFilter(query:string) {
      query = query.trim();
      this.filteredRoles = this.roles.filter(r => r.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }

    isAssigned(role) {
      if(Array.isArray(this.user.roles)) {
        return this.user.roles.indexOf(role._id) !== -1;
      }
      return false;
    }
    handleChange(event) {
      const checked = event.checked;
      const role_id = event.source.value;
      
      const i = this.user.roles.indexOf(role_id);

        if(checked && i === -1) {
          this.user.roles.push(role_id);
          this.assignRole(role_id);
        } else if(i !== -1) {
          this.user.roles.splice(i,1);
          this.unassignRole(role_id);
        }
    }
    assignRole(role_id) {
      this.rolesService.assignRole(role_id, this.user._id).subscribe((response:any) => {
        if(response.status === 200) {
          this.toastr.success(response.message, "Success");
        } else {
          this.toastr.error(response.message, "Failed");
        }
      })
    }
    unassignRole(role_id) {
        this.rolesService.unassignRole(role_id, this.user._id).subscribe((response:any) => {
          if(response.status === 200) {
            this.toastr.success(response.message, "Success");
          } else {
            this.toastr.error(response.message, "Failed");
          }
        })
    }
}
