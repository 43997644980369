import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-rehospitalization-report-v2',
  templateUrl: './rehospitalization-report-v2.component.html',
  styleUrls: ['./rehospitalization-report-v2.component.css']
})
export class RehospitalizationReportV2Component implements OnInit {
  @Input() rehospitalizationReport = [];
  @Input() facilityTitle: any;
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = [
    'organization', 'faciltiy', 'first_name', 'last_name'];
  constructor() { }

  ngOnInit(): void {
    console.log("rehospitalizationReport", this.rehospitalizationReport);
    this.dataSource.data = this.rehospitalizationReport;
  }

}
