<div calss="container">
    <div class="flex"  *ngIf="providerTranscriber">
        <app-input-with-dropdown
                class="flex-grow"
                [noDropdown]="true"
                placeholder="Search Biller"
                (inputChange)="applyFilter($event.target.value)">
        </app-input-with-dropdown>
    </div>
    <div style="height: 10px;"></div>
    <div style="height: 70vh;overflow: auto;">
        <table
               mat-table [dataSource]="dataSource" matSort  >
    
            <ng-container matColumnDef="biller_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Biller Name</th>
                <td mat-cell *matCellDef="let element" style="width: 20%"> 
                    {{element.first_name}} {{element.last_name}}
                    <div style="font-size: 11px;">{{getEmailId(element)}}</div>
                 </td>
            </ng-container>
    
            <ng-container matColumnDef="provider_name">
                <th mat-header-cell *matHeaderCellDef> Providers </th>
                <td mat-cell *matCellDef="let element" style="width: 60%">
                    <app-mat-multi-select-chips [element]="element" [ngModelData]="element.assos_providers || []" [isProvider]="true" [allData]="element.providers" [isMulti]="true" [selectAll]="true" [providerKey]="'assos_providers'" (onDataSelection)="onDataSelection($event)" (onDataRemove)="onDataRemove($event)"></app-mat-multi-select-chips> 
                </td>
    
            </ng-container>
            
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
        </table>
    </div>
</div>
