<h3>Note Addendum</h3>

<div class="flex flex-col gap-2">

    <ul class="addendum-list">
      <li class="addendum-list-item" *ngFor="let addendum of addendums">
        <div class="addendum-date">Date: {{applyTimezone(addendum.createdAt) | date: global.date_time_format}}</div>
        <pre class="addendum-text" [innerText]="addendum.text"></pre>
      </li>
    </ul>
  

  <mat-form-field>
    <mat-label>Enter addendum text</mat-label>
    <textarea matInput [(ngModel)]="model.addendumText"></textarea>
  </mat-form-field>
  <mat-checkbox [(ngModel)]="model.includeNoteText">Include note text</mat-checkbox>

  <div class="flex flex-row-reverse">
    <button mat-raised-button class="dn-button-primary" (click)="onSaveClick()">Save</button>
  </div>

  <div class="top-right-close-btn">
      <button mat-mini-fab mat-dialog-close ><mat-icon >cancel</mat-icon></button>
    </div>
</div>