<h1 mat-dialog-title>Share {{item.title}}</h1>
    <div mat-dialog-content>
        <mat-form-field class="full-width">
            <mat-chip-list #chipList aria-label="Email selection">
              <input
                    (input)="invalidEmailError=false"
                    placeholder="Email"
                    (keyup)="loadSuggestions($event.target.value)"
                     [matChipInputFor]="chipList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     (matChipInputTokenEnd)="add($event)"
                    #emailInput
                    [formControl]="userControl"
                    [matAutocomplete]="userAutoComplete">
                <mat-autocomplete (optionSelected)="selectEmail($event)"
                                  #userAutoComplete="matAutocomplete">
                    <mat-option *ngFor="let e of userEmails"
                                [value]="e">
                        {{e}}
                    </mat-option>
                </mat-autocomplete>
            </mat-chip-list>
          </mat-form-field>
          <mat-error *ngIf="isOwnEmail">You cannot enter your own email address</mat-error>
          <mat-error *ngIf="invalidEmailError && !isOwnEmail">You must enter a valid email address</mat-error>

          <div class="flex flex-wrap gap-2">
                <mat-chip *ngFor="let email of emails" [selectable]="selectable"
                [removable]="removable" (removed)="remove(email)"> 
                        {{email}}  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
          </div>
    </div>

    <div mat-dialog-actions class="flex gap-5" style="justify-content: space-between;">
      <button mat-raised-button class="dn-button-primary" (click)="onShareClick()">Share</button>
      <button mat-raised-button class="dn-button-secondary" mat-dialog-close type="reset">Cancel</button>
    </div>
