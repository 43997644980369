<div class="phrases-wrapper flex flex-col gap-2">
      
    <input matInput type="text" style="margin:3px 3px 7px 3px; padding-left:7px; border-radius:12px; border: groove;height: 35px;" placeholder="Search System Defined Phrases" [(ngModel)]="searchText" (keyup)="applyFilter()" />

    <div *ngFor="let phraseObj of displayPhrases; let i = index" class="flex items-start justify-baseline gap-2">
        <div class="phrase-wrap flex flex-grow items-start justify-center flex-grow">
            <div style="color:rgb(104, 104, 104); font-size: 12px; padding-top: 30px;">{{ i + 1 }}</div>
 
            <mat-form-field class="flex-grow">
                <mat-label>Shortcut</mat-label>
                <input matInput type="text" style="font-size:12px; font-weight: bold;" [value]="phraseObj ? getMarker() + phraseObj.key : ''" readonly />
            </mat-form-field>

            <button
                *ngIf="phraseObj"
                class="dn-button-primary"
                mat-raised-button
                (click)="applyPhrase( phraseObj )"
            >
                Select
            </button>
        </div>
    </div>
</div>