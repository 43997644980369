<div *ngIf="dictations.length > 0 && dictationsGroup" class="flex flex-col gap-5">
  <div *ngFor="let item of dictationsGroup | keyvalue" [class.activegroup]="isTableVisible(item.key)" class="flex flex-col gap-2">
    <mat-table
    *ngIf="groupDataSource" [dataSource]="groupDataSource">
        <ng-container matColumnDef="uploader_name">
            <mat-header-cell *matHeaderCellDef >Uploaded By</mat-header-cell>
            <mat-cell *matCellDef="let groupData">{{groupData.uploaded_by_id_ref.first_name + " " + groupData.uploaded_by_id_ref.last_name}}</mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="facility_title">
            <mat-header-cell *matHeaderCellDef >Facility</mat-header-cell>
            <mat-cell *matCellDef="let groupData">
                <app-facility-title [facility]="groupData.facility_id_ref"></app-facility-title>
            </mat-cell>
        </ng-container>

        
        <ng-container matColumnDef="count">
            <mat-header-cell *matHeaderCellDef >Count</mat-header-cell>
            <mat-cell *matCellDef="let groupData">{{groupData.count}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="operations">
            <mat-header-cell *matHeaderCellDef >Operations</mat-header-cell>
            <mat-cell *matCellDef="let groupData">
                <div class="flex gap-2">

                  <button mat-raised-button class="dn-button-primary"
                  [matMenuTriggerFor]="multiDownloadAudioMenu"
                  *ngIf="!multiDownloadDictationsInprogress && dictations && dictations.length">Download</button>
                  
                  <mat-menu #multiDownloadAudioMenu="matMenu">
                      <button mat-menu-item (click)="startMultiDownloadDictations('mp4')">MP4</button>
                      <button mat-menu-item (click)="startMultiDownloadDictations('mp3')">MP3</button>
                  </mat-menu>

                  <div class="mat-progress-spinner-progress"
                  style="margin:auto"
                  *ngIf="multiDownloadDictationsInprogress"
                  >
                    <mat-progress-spinner
                    diameter="40"
                    *ngIf="downloadProgress.show"
                    [mode]="downloadProgress.mode"
                    [value]="downloadProgress.progress">
                    </mat-progress-spinner>
                    <span style="color:#000">
                      {{this.downloadProgress.progress}}%
                    </span>
                  </div>

                <button mat-raised-button class="dn-button-primary" (click)="hideTable(item.key)" *ngIf="isTableVisible(item.key)">Hide Details</button>
                <button mat-raised-button class="dn-button-secondary" (click)="showTable(item.key)" *ngIf="!isTableVisible(item.key)">Show Details</button>
              </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="groupDataDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: groupDataDisplayedColumns"></mat-row>
      </mat-table>

          <mat-table
          *ngIf="isTableVisible(item.key) && dictationsDataSource" [dataSource]="dictationsDataSource">

              <ng-container matColumnDef="audio_title">
                  <mat-header-cell  [style.flex]="'0 0 23%'"  *matHeaderCellDef >Title</mat-header-cell>
                  <mat-cell   [style.flex]="'0 0 23%'" *matCellDef="let audio">{{audio.audio_title}}</mat-cell>
              </ng-container>
              
              <ng-container matColumnDef="uploader_name">
                  <mat-header-cell *matHeaderCellDef >Uploaded By</mat-header-cell>
                  <mat-cell *matCellDef="let audio">{{audio.uploaded_by_id_ref.first_name + " " + audio.uploaded_by_id_ref.last_name}}</mat-cell>
              </ng-container>
              
              <ng-container matColumnDef="date_time">
                  <mat-header-cell *matHeaderCellDef >Date of Service</mat-header-cell>
                  <mat-cell *matCellDef="let audio">{{audio.date_time | date: global.date_format}}</mat-cell>
              </ng-container>
              
              <ng-container matColumnDef="date_of_submission">
                  <mat-header-cell *matHeaderCellDef >Date of Submission</mat-header-cell>
                  <mat-cell *matCellDef="let audio">{{(audio.date_of_submission ? audio.date_of_submission : audio.date_time) | date: global.date_format}}</mat-cell>
              </ng-container>
              
              <ng-container matColumnDef="progress_status">
                  <mat-header-cell *matHeaderCellDef >Progress</mat-header-cell>
                  <mat-cell *matCellDef="let audio">
                      <button class="theme-btn status" [class.completed]="audio.status==='COMPLETED'">{{audio.status}}</button>
                  </mat-cell>
              </ng-container>
              
              <ng-container matColumnDef="facility_title">
                  <mat-header-cell *matHeaderCellDef >Facility</mat-header-cell>
                  <mat-cell *matCellDef="let audio">{{audio.facility_id_ref.title}}</mat-cell>
              </ng-container>
              
              <ng-container matColumnDef="audio_length">
                  <mat-header-cell *matHeaderCellDef >Length</mat-header-cell>
                  <mat-cell *matCellDef="let audio">{{audio.audio_length}}</mat-cell>
              </ng-container>
              
              <ng-container matColumnDef="notes_count">
                  <mat-header-cell *matHeaderCellDef >Notes Count</mat-header-cell>
                  <mat-cell *matCellDef="let audio">{{audio.notes_count}}</mat-cell>
              </ng-container>
              
              <mat-header-row *matHeaderRowDef="dictationsDisplayedColumns"></mat-header-row>
          
              <mat-row *matRowDef="let row; columns: dictationsDisplayedColumns"></mat-row>
          
              </mat-table>        

  </div>
</div>
