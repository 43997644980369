import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
// import { partNames } from '@tensorflow-models/posenet';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { QueryReportService } from '../general-report/service/query-report.service';
import { CreateQueryReportDialogComponent } from './create-query-report-dialog/create-query-report-dialog.component';

@Component({
  selector: 'app-query-report',
  templateUrl: './query-report.component.html',
  styleUrls: ['./query-report.component.css']
})
export class QueryReportComponent implements OnInit {

  @Input() parentReport: any;
  displayedColumns: string[] = [
    'sr', 'title', 'actions'];
  dataSource: any = new MatTableDataSource([]);
  @Output() exit = new EventEmitter<boolean>();
  dialogSubscription: any;
  queryReports: any = [];
  companyId: any;

  constructor(
    private _authService: AuthService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _toastr: ToastrService,
    private dialog: MatDialog,
    private _queryService: QueryReportService,
  ) {
    this.companyId = this._route.parent.snapshot.params.id;
  }

  ngOnInit(): void {
    this.getQueryReport();
  }

  getQueryReport() {
    let filter = {
      parent_report_id: this.parentReport.id
    };
    let project = {};
    this._queryService.getQueryReport(filter, project).subscribe((response: any) => {
      if (response.status == 200) {
        this.queryReports = response.data;
        this.dataSource.data = this.queryReports;
        console.log("this,", this.queryReports);
      }
    }, err => {
      this._toastr.error(err, "ERROR");
    }
    );
  }

  deleteQueryReport(id, index) {
    this._queryService.deleteQueryReport(id).subscribe((response: any) => {
      if (response.status == 200) {
        this._toastr.success("Report Deleted", "Success");
        this.dataSource.data = [];
        this.queryReports.splice(index, 1);
        this.dataSource.data = this.queryReports;
      }
    }, err => {
      this._toastr.error(err, "ERROR");
    }
    );
  }

  goBack() {
    this.exit.emit(false);
  }

  executeQueryReport(title, index) {
      title = title.toLowerCase().replaceAll(" report", "").replaceAll(" ", "-");
      this._router.navigate([`/company/${this.companyId}/display-report/${title}`], {
        queryParams: {
          ...this.parentReport
        }
      });
    // const filter = {
    //   _id: id
    // };
    // const project = {
    //   query: 1
    // }
    // this._queryService.executeQueryReport(filter, project).subscribe((response: any) => {
    //   if (response.status == 200) {
    //     this._toastr.success("Report updated", "Success");
    //     console.log("res", response.data);
    //   }
    // }, err => {
    //   this._toastr.error(err, "ERROR");
    // }
    // );

  }

  openCreateQueryReportDialog(queryId = null, index = null) {
    let data: any = {
      parentReportId: this.parentReport.id
    };
    data.queryId = queryId;
    let options: any = {
      width: '50%',
      height: '40vh',
      data: data
    }
    this.dialogSubscription = this.dialog.open(CreateQueryReportDialogComponent, {
      ...options,
    }).afterClosed().subscribe((queryReport) => {
      if (queryReport) {
        this.dataSource.data = [];
        if (!queryId) {       //for update
          this.queryReports.push(queryReport);
        }
        this.queryReports[index] = queryReport;
        this.dataSource.data = this.queryReports;
      }
    });
  }
  reportAllowedtoShow(title){
    return (title == 'Visit Summary Report' || title == 'Missed Facility Report') ? false : true;
  }
}
