import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from './login.service';
import { Subscription, Observable, Subject, lastValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SocketService } from 'src/app/services/socket.service';
import { DatePipe } from '@angular/common';
import * as global from '../global';
import { AuthService } from "../../services/auth.service";
import { PCCService } from "../../services/pcc.service";
import moment from 'moment'
import { CompanyLogoAndMongogramService } from 'src/app/services/company-logo-and-mongogram.service';
import LogRocket from 'logrocket';

export interface User {
    email: string;
    password: string;
    status: number;
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    providers: [DatePipe]
})

export class LoginComponent implements OnInit {
    hide = true;
    iOReQA: Boolean = false;
    public user: any;
    status: any;
    verificationResponse: any;
    datas: any;
    encryptedResponse: any;
    private subscription: Subscription;
    email: string = "";
    password: string = "";
    remember_me_check: boolean = false; // Added remember me check #mohsin-dev
    isInProgress = false;
    companyCode: any;
    companylogoPath: any;
    defaultImg: string;
    companylogoPathAfterLogin: any;
    browserFingerprint;
    showAuthenticationOption: boolean = false
    authMethod: string = "";
    userDetails: any;
    loginForm: NgForm;
    logoutTimerReturnURL:string;

    public constructor(private router: Router,
        public loginService: LoginService,
        private _toastr: ToastrService,
        private _router: Router,
        private route: ActivatedRoute,
        private _socketService: SocketService,
        private authService: AuthService,
        private pccService: PCCService,
        private toastr: ToastrService,
        public datePipe: DatePipe,
        private companyLogoAndMonogramService: CompanyLogoAndMongogramService) {
        // Initialize email if there is value in remembered_email when next time login is opened #mohsin-dev
        const remembered_email = localStorage.getItem('remembered_email');
        if (typeof remembered_email === 'string' && remembered_email.length) {
            this.remember_me_check = true;
            this.email = remembered_email;
        } else {
            this.remember_me_check = false;
        }

        // window.onpopstate = function() {
        //     window.history.forward()
        // }

        let i = 0;
        let key = sessionStorage.key(0);
        while (key !== null) {
            sessionStorage.removeItem(key);
            i++;
            key = sessionStorage.key(i);
        }
    }

    async ngOnInit() {
        if(global.ENVIRONMENT != 'LOCAL' ){
            // LogRocket.init('mdbzwd/logrocket-demo'); //ali
            LogRocket.init('dn_wc/logrocket-demo_wc'); //rafay
        }
        // localStorage.removeItem('currentUser');
        if(this.route.snapshot.queryParams.logoutTimerReturnURL?.trim().length) {
            this.logoutTimerReturnURL = this.route.snapshot.queryParams.logoutTimerReturnURL.trim()
        }
        if (this.route.snapshot.params.company_code) {
            this.companyCode = this.route.snapshot.params.company_code;
            console.log('this.companyCode', this.companyCode);
            localStorage.setItem('companyCode',this.companyCode);
            await this.getCompanyLogo();
        } else {
            this.defaultImg = "/assets/img/DNlogo-new.png";
        }
        const clientDeviceDetails = await this.authService.getClientDeviceDetails();
        this.browserFingerprint = clientDeviceDetails.visitorId;
    }

    navigateToForgotPassword() {
        const code = this.companyCode;
        if (code) {
            this.router.navigate([`forgot-password/${code}`])
        } else {
            this.router.navigate([`forgot-password`])
        }
    }
    navigateToContactUs() {
        const code = this.companyCode;
        if (code) {
            this.router.navigate([`contact-us/${code}`])
        } else {
            this.router.navigate([`contact-us`])
        }
    }
    

    async getCompanyLogo() {
        this.loginService.getCompanyLogo(this.companyCode, null).subscribe((res: any) => {
            if (res.status = 200) {
                if (res.data[0].monogram_aws_path) {
                    this.companylogoPath = res.data[0].monogram_aws_path;
                    this.companyLogoAndMonogramService.setCompanyMonoGram(this.companylogoPath);
                }
                if(res.data[0].logo_aws_path){
                    this.companylogoPathAfterLogin = res.data[0].logo_aws_path;
                    this.companyLogoAndMonogramService.setCompanyLogo(this.companylogoPathAfterLogin);
                }
                if (!res.data[0].monogram_aws_path) {
                    console.log('../../../assets/img/DNlogo-new.png'); 
                    this.defaultImg = "../../../assets/img/DNlogo-new.png";
                }
            } else {
                this.defaultImg = "../../../assets/img/DNlogo-new.png";
            }
        });
    }

    async onCheckLogin(form: NgForm) {
        if (!form.valid || !form.touched || this.isInProgress) return;
        this.isInProgress = true;
        // Add remember_me_check to remember the email next time login is opened #mohsin-dev
        if (form.value.remember_me_check) {
            localStorage.setItem('remembered_email', this.email);
        } else {
            localStorage.removeItem('remembered_email');
        }

        const infoToast = this._toastr.info("Logging in...", 'Please wait', { disableTimeOut: true });
        try {
            const encrypted = await this.loginService.getEncryptPassword(form.value.password).toPromise();
            this.encryptedResponse = encrypted;

            const loginInfoResponse: any = await this.loginService.getLoginInfo(form.value.email, this.encryptedResponse.encreptedPass).toPromise();
            if (loginInfoResponse.status === 200) {
                // this._toastr.success(loginInfoResponse['message'], 'Success');
                const { user } = loginInfoResponse.data;
                if ((user.user_type == '2' && loginInfoResponse.data.array && loginInfoResponse.data.array.length > 0) || (user.user_type == global.USER_TYPE.MEDICAL_ASSISTANT && loginInfoResponse.data.array && loginInfoResponse.data.array.length > 0)) {
                    loginInfoResponse.data.array.some(element => {
                        if (element.qa_e_id == user._id || element.qa_id == user._id) {
                            this.iOReQA = true;
                            localStorage.setItem('iOReQA', JSON.stringify(this.iOReQA))
                        }
                    });
                }

                const auth_key = user.web_auth_key + ".WEB";
                user.auth_key = auth_key;
                // ACTIVITY LOG
                this._socketService.addActivityLog({
                    id: user._id,
                    screen: 'Login',
                    operation: 'Login API Call',
                    datetime: this.datePipe.transform(new Date(), global.date_time_format)

                })
                localStorage.setItem('currentUserAuth', JSON.stringify(user));
                if (user.cognito) {
                    this._toastr.clear(infoToast.toastId);
                    if (user.two_factor_auth) {
                        const verficationCodeInfoToast = this._toastr.info('Sending verification code...', "Please wait", { disableTimeOut: true });

                        this.router.navigate(['/auth']);

                        const respones = await this.loginService.send2FactorVerificationCode(user).toPromise();
                        this._toastr.clear(verficationCodeInfoToast.toastId);

                        this.verificationResponse = respones;
                        if (this.verificationResponse['status'] === 200) {
                            // this._toastr.success(this.verificationResponse['message'], 'Success');
                        } else {
                            this._toastr.error(this.verificationResponse['message'], 'Failed');
                        }
                        this._toastr.clear(verficationCodeInfoToast.toastId);
                    } else {
                        this.authService.currentUser = user;
                        await this.authService.LoginActivity({ operation: "login", platform: "web" }).toPromise();
                        this.authService.redirectToHome()
                    }
                } else {
                    this._toastr.warning('This account is not verified, please verify first', "Warning");
                    // localStorage.setItem('register_auth', JSON.stringify(user));
                    this._router.navigate([`/company/${user.company_id}/user-create-password`],{queryParams: {user_id: user._id, email: user.email}})
                }
            } else {
                this._toastr.error(loginInfoResponse.message, 'Failed');
                this.password = '';
                //  form.resetForm();
            }
            this._toastr.clear(infoToast.toastId);
        } catch (error) {
            console.error(error);
            this._toastr.clear();
        } finally {
            this._toastr.clear(infoToast.toastId);
            this.isInProgress = false;
        }

        if (form.invalid) {
            return;
        }
    }
    async showAuthenticationOptions(form: NgForm) {
        
        if (!form.valid || !form.touched) return;
        // Add remember_me_check to remember the email next time login is opened #mohsin-dev
        if (form.value.remember_me_check) {
            localStorage.setItem('remembered_email', this.email);
        } else {
            localStorage.removeItem('remembered_email');
        }
        if (form.invalid) {
            return;
        }
        this.email = form.value.email;
        this.password = form.value.password;
        const authenticationDetails: any = await lastValueFrom(this.loginService.getAuthenticationDetails(form.value.email.toLowerCase().trim()));
        if(authenticationDetails.status === 200){
            this.userDetails = authenticationDetails.data;
            if(!this.userDetails.google_auth_enabled){
                this.loginwithCognito();
            }else if(this.userDetails.cognito && this.userDetails.google_auth_enabled){
                this.showAuthenticationOption = true;
            }else if(this.userDetails.cognito && this.userDetails.enableCognito && !this.userDetails.google_auth_enabled){
                this.loginwithCognito();
            }else{
                this.loginwithCognito();
            }
            // this.showAuthenticationOption = true;
        }else if(authenticationDetails.status === 1000){
            this._router.navigate([`/company/${authenticationDetails.data.companyId}/user-create-password`],{queryParams: {user_id: authenticationDetails.data.userId, email: authenticationDetails.data.email}})
        }else{
            this._toastr.error(authenticationDetails.message, "Error")
        }
    }
    authencateUser(){
        if(this.authMethod === ""){
            this._toastr.info('Choose Authenticator Option to Authenticate');
        }else{
            if (this.authMethod === "sms" || this.authMethod === "email") {
                this.loginwithCognito();
            } else {
                this.loginwithCognito("google");
            }
        }
    }
    async loginwithCognito(auth = "cognito"){
        this.isInProgress = true;
        // cleanup logout
        this.authService.cleanupLogout();
        const infoToast = this._toastr.info("Logging in...", 'Please wait', { disableTimeOut: true });
        try {
            const loginURL = window.location.href;
            const loginInfoResponse: any = await this.loginService.loginWithCognito(this.email, this.password, moment.tz.guess(), this.browserFingerprint, loginURL, auth).toPromise();
            if (loginInfoResponse.status === 200) {
                const { user, token, mfaSession, userRememberedDevice } = loginInfoResponse.data;
                if (mfaSession) {
                    if (this.companyCode) {
                        const code = this.companyCode;
                        return this.router.navigate([`/auth/${code}`, { mfaSession, email: this.email, authMethod: "cognito"}]);
                    } else {
                        return this.router.navigate(['/auth', { mfaSession, email: this.email, authMethod: "cognito" }]);
                    }
                }

                if ((user.user_type == '2' && loginInfoResponse.data.array && loginInfoResponse.data.array.length > 0) || (user.user_type == global.USER_TYPE.MEDICAL_ASSISTANT && loginInfoResponse.data.array && loginInfoResponse.data.array.length > 0)) {
                    loginInfoResponse.data.array.some(element => {
                        if (element.qa_e_id == user._id || element.qa_id == user._id) {
                            this.iOReQA = true;
                            localStorage.setItem('iOReQA', JSON.stringify(this.iOReQA))
                        }
                    });
                }

                const auth_key = user.web_auth_key + ".WEB";
                user.auth_key = auth_key;

                // ACTIVITY LOG
                this._socketService.addActivityLog({
                    id: user._id,
                    screen: 'Login',
                    operation: 'Login API Call',
                    datetime: this.datePipe.transform(new Date(), global.date_time_format)

                });

                if (token) {
                    this.authService.setToken(token);
                }
                
                localStorage.setItem('currentUserAuth', JSON.stringify(user));
                if(this.companyCode) {
                    localStorage.setItem('companyCode',this.companyCode);
                }
                await this.navigateToVerificationScreen(user,token,infoToast,userRememberedDevice);
            } 
            else if(loginInfoResponse.status === 1000){
                this._router.navigate([`/company/${loginInfoResponse.data.companyId}/user-create-password`],{queryParams: {user_id: loginInfoResponse.data.userId, email: loginInfoResponse.data.email}})
            }
            else {
                this._toastr.error(loginInfoResponse.message, 'Failed');
                this.password = '';
                //  form.resetForm();
            }
            this._toastr.clear(infoToast.toastId);
        } catch (error) {
            console.error(error);
            this._toastr.clear();
        } finally {
            this._toastr.clear(infoToast.toastId);
            this.isInProgress = false;
        }
    }
    async navigateToVerificationScreen(user: any, token: any,infoToast,userRememberedDevice){
        if (user.cognito) {
            this._toastr.clear(infoToast.toastId);
            if(user.enableCognito && !user.cognito && user.recentLogin == undefined) {
                this.authService.currentUser = user;
                this.router.navigate([`/company/${user.company_id}/create-strong-password`]);
            } else if(this.authMethod === "google2FA"){
                this.router.navigate([`/auth/`, {authMethod: "google"}]);
            }else if (!userRememberedDevice && ((user.two_factor_auth && !user.enableCognito) || (!user.cognito && user.recentLogin))) {
                const verficationCodeInfoToast = this._toastr.info('Sending verification code...', "Please wait", { disableTimeOut: true });
                const respones = await this.loginService.send2FactorVerificationCode(user, token && token.idToken).toPromise();
                this._toastr.clear(verficationCodeInfoToast.toastId);

                this.verificationResponse = respones;
                if (this.verificationResponse['status'] === 200) {
                    if (this.companyCode) {
                        const code = this.companyCode;
                        this.router.navigate([`/auth/${code}`, {authMethod: "cognito"}]);
                    } else {
                        this.router.navigate([`/auth/`, {authMethod: "cognito"}]);
                    }

                } else {
                    this._toastr.error(this.verificationResponse['message'], 'Failed');
                }
                this._toastr.clear(verficationCodeInfoToast.toastId);
            } else {
                this.authService.currentUser = user;
                this.authService.redirectToHome();
            }
        } else {
            this._toastr.warning('This account is not verified, please verify first', "Warning");
            localStorage.setItem('register_auth', JSON.stringify(user));
            this._router.navigate([`/company/${user.company_id}/user-create-password`],{queryParams: {user_id: user._id, email: user.email}})
        }
    }
    checkUserDetails(option){
        if(option === 'sms'){
            if(this.userDetails.cognito && this.userDetails.enableCognito){
                return true;
            }else{
                return false;
            }
        }else if(option === 'email'){
            if(this.userDetails.cognito && !this.userDetails.enableCognito && this.userDetails.two_factor_auth){
                return true;
            }else{
                return false;
            }
        }else{
            if(this.userDetails.google_auth_enabled){
                return true;
            }else{
                return false;
            }
        }
    }
}
