<div class="h100 flex flex-row md:flex-col justify-center items-center">
  <mat-card class="w-2/5 md:w-full">

    <mat-card-title class="flex flex-col items-center justify-center">
      <img src="assets/img/DNlogo-new.png">
      <h2>Add Facility</h2>
    </mat-card-title>

    <mat-card-content class="flex flex-col">

      <form>

        <mat-form-field>
          <input matInput type="text" name="facility_name" placeholder="Facility Name" required>
        </mat-form-field>

        <mat-form-field>
          <input matInput type="text" name="address" placeholder="Street Address (Optional)">
        </mat-form-field>

        <mat-form-field>
          <input matInput type="text" name="city" placeholder="City (Optional)">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Select State</mat-label>
          <mat-select>
            <mat-option value="State1">
              State - 1
            </mat-option>
            <mat-option value="State1">
              State - 2
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <input matInput type="text" name="zip_code" placeholder="ZIP Code (Optional)">
        </mat-form-field>

        <div class="flex items-center justify-center">
          <button class="theme-btn flex flex-1 w-1/2" mat-stroked-button type="submit">Save</button>
        </div>

        <div class="member flex items-center justify-center">
          <a href="/login">Login</a>
        </div>

      </form>

    </mat-card-content>

  </mat-card>
</div>