<app-header></app-header>
<mat-sidenav-container>
    <main>
        <div class="container">
            <div class="white-rounded-rect-new flex flex-col">
                <div class="flex justify-between items-center">
                    <div class="patients-page">
                        <mat-form-field style="width: 155px;text-align-last:left; margin-right: 10px;">
                            <mat-select [(ngModel)]="selectedStatus" (selectionChange)="changeStatus($event)">
                                <mat-option disabled value="">Filter by status</mat-option>
                                <mat-option value="">All</mat-option>
                                <mat-option value="pending">Pending</mat-option>
                                <mat-option value="In progress">In progress</mat-option>
                                <mat-option value="Completed">Completed</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field style="width: 155px;text-align-last:left;">
                            <mat-select [(ngModel)]="selectedPriority" (selectionChange)="changePriority($event)">
                                <mat-option disabled value="">Filter by priority</mat-option>
                                <mat-option value="">All</mat-option>
                                <mat-option value="Low">Low</mat-option>
                                <mat-option value="Medium">Medium</mat-option>
                                <mat-option value="High">High</mat-option>
                                <mat-option value="Critical">Critical</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <button mat-raised-button class="dn-button-primary" style="height: 35px;margin-bottom: 11px;"
                     (click)="addNewTicket()">
                        Create Ticket
                    </button>
                </div>
                <div style="position: relative;
                            min-height: 74vh;
                            max-height: 74vh;
                            overflow: auto;">

                    <table mat-table [dataSource]="dataSource">

                        <ng-container matColumnDef="Ticket No.">
                            <th mat-header-cell *matHeaderCellDef style="width: 5%;"> # </th>
                            <td mat-cell *matCellDef="let element;let i = index;" style="cursor: pointer;"> {{ i + 1}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Title">
                            <th mat-header-cell *matHeaderCellDef> Title </th>
                            <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                                {{element.title}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Updation Date">
                            <th mat-header-cell *matHeaderCellDef style="width: 10%;">Date </th>
                            <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                                {{element.updatedAt | date}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Priority">
                            <th mat-header-cell *matHeaderCellDef style="width: 10%;">Priority</th>
                            <td mat-cell *matCellDef="let element" style="cursor: pointer;"
                                [ngClass]="{'lowPriority':element.priority === 'Low','mediumPriority':element.priority === 'Medium','highPriority':element.priority === 'High','criticalPriority':element.priority === 'Critical'}">
                                {{element.priority}}</td>
                        </ng-container>

                        <ng-container matColumnDef="Status">
                            <th mat-header-cell *matHeaderCellDef style="width: 10%;">Status</th>
                            <td mat-cell *matCellDef="let element">
                                {{element.status}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Operations">
                            <th mat-header-cell *matHeaderCellDef style="width: 10%;"></th>
                            <td mat-cell *matCellDef="let element">
                                <div class="flex gap-4">
                                    <i class="icon icon-view" matTooltip="View" matTooltipPosition="above"
                                        (click)="showDetails(element)"></i>
                                    <i class="icon icon-edit" matTooltip="Edit" matTooltipPosition="above"
                                        (click)="editTicket(element)"></i>
                                    <i class="icon icon-delete" (click)="deleteTicket(element)"
                                        *ngIf="element.status != 'In progress'" matTooltip="Delete Ticket"
                                        matTooltipPosition="above"></i>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </div>
        </div>
    </main>
    <mat-sidenav mode="side" [(opened)]="showDetail" position="end">
        <div
            style="background-color: #fff;padding: 65px 0px 100px 0px !important;overflow-y: hidden;overflow-x: hidden; padding-bottom: 33px;">
            <app-user-ticket-details *ngIf="sideBar" (showDetail)="showDetail=$event" [rowData]="rowData"></app-user-ticket-details>
        </div>
    </mat-sidenav>
</mat-sidenav-container>