import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment'
import * as global from '../../../../global';
import { ToastrService } from 'ngx-toastr';
import { Observable, lastValueFrom } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FacilityService } from 'src/app/services/facility.service';
import { Location } from '@angular/common';
import { WoundService } from 'src/app/services/wound.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { SnfWcNurseAssociationService } from 'src/app/includes/snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { NursingService } from 'src/app/includes/nursing-module/nursing.service';
import { WoundNurseRelationService } from 'src/app/services/Modules/woundNurse.service';
import { CommonService } from 'src/app/services/common.service';
import { WoundCareWidgetService } from 'src/app/includes/note-sidebar/wound-care-widget/wound-care-widget.service';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';
import * as _ from 'lodash';

@Component({
  selector: 'app-wound-exceptions-report',
  templateUrl: './wound-exceptions-report.component.html',
  styleUrls: ['./wound-exceptions-report.component.css']
})
export class WoundExceptionsReportComponent implements OnInit {

  currentDate = moment(new Date()).add(1, 'days');
  minDate = moment(new Date()).subtract(6, 'days');
  loaderId = 'app-wound-exceptions-report';
  isSummaryReport: boolean = false;
  filter: any = {
    updationDate: {
      startDate: moment(),
      endDate: moment()
    },
    _id: { $in: [] },
    assoc_company_ids: null,
    state: 'active',
    dateType: 'last_assessment_date_obj',
  };
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 1 Week': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  };
  filteredFacilities: Observable<string[]>;
  displayedColumns: string[] = [
    "Facility",
    "Active Census",
    "Wound Patients",
    "Wounds",
    "Wounds Not Assessed",
    "New FA",
    "New FA Stage 1",
    "New FA Stage 2",
    "New FA Stage 3",
    "New FA Stage 4",
    "New FA Unstageable",
    "New FA DTPI",
    "New FA MMI",
    "New POA",
    "New POA Stage 1",
    "New POA Stage 2",
    "New POA Stage 3",
    "New POA Stage 4",
    "New POA Unstageable",
    "New POA DTPI",
    "New POA MMI",
    "Worsening Stage FA",
    "Worsening Stage POA",
    "Worsening Push FA",
    "Worsening Push POA",
    "Push Unchanged",
    "Push Improving",
  ];

  facilityControl = new FormControl();
  facilities: Array<any> = [];
  report: any = [];
  dataSource: any = new MatTableDataSource([]);
  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    PAGE_LIMIT: 50,
    skip: 0
  };
  currentUser: any;
  companySide: boolean;
  nursingSide: boolean;
  isUserDashboard: Boolean = false;
  isSnfNurse: boolean = false;
  isNurse: boolean = false;
  isWoundNurse: boolean = false;
  nurseAssociatedFacilities: Array<any> = [];
  summary: any = {};
  etiologies: any = [];
  timeoutId: NodeJS.Timeout;

  constructor(
    private router: Router,
    private _toastr: ToastrService,
    private _facilityService: FacilityService,
    private _route: ActivatedRoute,
    private _location: Location,
    private _wound: WoundService,
    private loader: NgxUiLoaderService,
    private _authService: AuthService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
    private _nurseService: NursingService,
    private woundNurseService: WoundNurseRelationService,
    private _commonService: CommonService,
    private _woundCareWidgetService: WoundCareWidgetService,
    private _woundNurseRelationService: WoundNurseRelationService,

  ) {
    this.currentUser = this._authService.currentUser;
    this.filter.assoc_company_ids = this._route.parent.snapshot.params.id;
    if (this._route.parent.snapshot.params.id) {
      this.companySide = true;
    } else {
      this.filter.assoc_company_ids = this.currentUser.company_id;
    }
    if (window.location.pathname.includes('dashboard')) {
      this.isUserDashboard = true;
    }
    if (window.location.pathname.includes('nursing')) {
      this.nursingSide = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      this.isSnfNurse = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.NURSE) {
      this.isNurse = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
      this.isWoundNurse = true;
    }

  }

  async ngOnInit() {
    if (this._authService.currentUser.user_type === global.USER_TYPE.DOCTOR)
      await this.initFacilities();
    else if (this._authService.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE)
      await this.initSnfWcNurseFacilityAssociation();
    else if (this._authService.currentUser.user_type === global.USER_TYPE.WOUND_NURSE)
      await this.initWoundNurseFacilityAssociations();
    else if (this.currentUser.user_type === global.USER_TYPE.NURSE)
      await this.initNurseFacilities();

    await this.handleApplyFilter();
  }

  async initWoundNurseFacilityAssociations() {
    const filter = { associated_wound_nurse_ids: this._authService.currentUser._id };
    const nurseProjection = {
      first_name: 1,
      last_name: 1,
      title: 1,
      full_name: 1
    };
    const facilityProjection = {
      title: 1,
      pcc_facId: 1,
      source: 1,
      pcc_2_legged_authentication: 1
    };
    const nurseFilter: any = {
      company_id: this._authService.currentUser.company_id,
      is_activated: "true",
      user_type: global.USER_TYPE.WOUND_NURSE
    };
    const response: any = await lastValueFrom(this._woundNurseRelationService.getWoundNurseFacilityAssociations(filter, nurseProjection, facilityProjection, nurseFilter));
    if (response.status === 200) {
      if (Array.isArray(response.data) && response.data.length > 0) {
        this.facilities = response.data.map(association => association.facility_id);
      }
    }
    else {
      this._toastr.error(response.data.message, 'Error');
    }
  }

  async initSnfWcNurseFacilityAssociation() {
    const filter = {
      associated_snf_wc_nurse_ids: this._authService.currentUser._id,
      company_id: this._authService.currentUser.company_id
    };
    const nurseProjection = {
      first_name: 1,
      last_name: 1,
      title: 1,
    };
    const facilityProjection = {
      title: 1,
      pcc_facId: 1,
      pcc_2_legged_authentication: 1,
      source: 1,
      pcc_orgUuid: 1
    };
    const response: any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
    if (response.status === 200 && response.data.length > 0) {
      this.facilities = response.data;
    }
    else {
      this._toastr.error(response.data.message, 'Error');
    }
  }

  async initNurseFacilities() {
    const response: any = await lastValueFrom(this._nurseService.getAssociatedFacilities());
    if (response.status === 200 && response.data.array.length > 0) {
      this.facilities = response.data.array;
    }
    else {
      this._toastr.error(response.data.message, 'Error');
    }
  }

  async initFacilities() {
    const filter = {
      provider_ids_ref: this._authService.currentUser._id,
      is_active: true,
      assoc_company_ids: this._authService.currentUser.company_id
    };
    const projection = {
      'title': 1,
      'source': 1,
      'assoc_company_ids': 1
    };
    const response: any = await lastValueFrom(this._facilityService.getFacilities(filter, projection));
    if (response.status === 200) {
      this.facilities = response.data.array;
    }
    else {
      this._toastr.error(response.data.message, 'Error');
    }
  }

  async initWoundExceptionReport() {
    this.filter.pageNo = this.pagination.pageNo;
    this.filter._id.$in = this.facilities.map(facility => facility._id);
    const response: any = await lastValueFrom(this._wound.getWoundExceptionsReport(this.filter));
    if (response.status == 200) {
      this.report = response.data.report;
      if (this.report.length > 0) {
        for (const facility of this.report) {
          facility.patientCount = "Fetching...";
        }
        this.pagination.totalRecords = response.data.totalRecords;
        this.handlePagination();
        this.dataSource.data = this.report;
        this.initPatientsForWoundExceptionReport();
      }
    }
    else if (response.status === 504) {
      this._toastr.error("Request Timed Out");
    }
    else {
      this._toastr.error(response.message, 'Error');
    }
  }

  async initPatientsForWoundExceptionReport() {
    const response: any = await lastValueFrom(this._wound.getPatientsForWoundExceptionsReport({
      assoc_company_ids: this.filter.assoc_company_ids,
      _id: { $in: this.facilities.map(facility => facility._id) }
    }));
    if (response.status == 200) {
      for (const facility of this.report) {
        for (const address of response.data) {
          if (address._id === facility._id) {
            facility.patientCount = address.patientCount;
            break;
          }
        }
        this.dataSource.data = this.report;
      }
    }
    else if (response.status === 504) {
      this._toastr.error("Request Timed Out");
    }
    else {
      this._toastr.error(response.message, 'Error');
    }
  }

  handlePagination() {
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    if (this.pagination.skip + this.report.length < this.pagination.totalRecords) {
      this.pagination.hasNextPage = true;
    }
    else {
      this.pagination.hasNextPage = false;
    }
    if (this.pagination.skip > 0) {
      this.pagination.hasPrevPage = true;
    }
    else {
      this.pagination.hasPrevPage = false;
    }
  }

  changeUpdationDate(event) {
    if (event.startDate && event.endDate) {
      this.filter.updationDate = {
        startDate: null,
        endDate: null
      };
      const noOfDays = this._commonService.getDifferenceOfDates(event.startDate,event.endDate,'days');
      if (noOfDays > 31) {
        this.filter.updationDate = {};
        this.filter.updationDate.startDate = moment(event.startDate);
        this.filter.updationDate.endDate = moment(this.filter.updationDate.startDate).add(31, "days").endOf('day');
        this._toastr.error('Can not select more than 32 days', 'Date Error');
      }
      else {
        this.filter.updationDate.startDate = event.startDate;
        this.filter.updationDate.endDate = event.endDate;
      }
    }
  }

  goBack() {
    this._location.back();
  }
  async handleApplyFilter() {
    this.loader.startLoader(this.loaderId);
    await this.initWoundExceptionReport();
    this.loader.stopLoader(this.loaderId);
  }

  onStateChange(value) {
    this.filter.state = value;
  }
  async resetFilter() {
    delete this.filter.updationDate;
    this.filter.updationDate = {
      startDate: moment(),
      endDate: moment()
    };
    this.filter.orientation = 'landscape';
    this.filter.dateType = 'last_assessment_date_obj';
    this.filter.state = "active";
    await this.handleApplyFilter();
  }

  goToPreviousPage() {
    this.loader.startLoader(this.loaderId);
    this.pagination.pageNo--;
    this.initWoundExceptionReport();
    this.loader.stopLoader(this.loaderId);
  }
  goToNextPage() {
    this.loader.startLoader(this.loaderId);
    this.pagination.pageNo++;
    this.initWoundExceptionReport();
    this.loader.stopLoader(this.loaderId);
  }

  async exportWoundExceptionReportAsXLSX() {
    this.filter._id.$in = this.facilities.map(facility => facility._id);
    if (this.report.length > 0) {
      this._toastr.info('Exporting as XLSX...', 'Please wait', { timeOut: 70000 });
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this._toastr.success("Report has been sent to your email", "Success");
      }, 5000);
      // const infoToastr = this._toastr.info('Exporting as XLSX...', 'Please wait', { disableTimeOut: true });
      const response: any = await lastValueFrom(this._wound.exportWoundExceptionReportAsXLSX(this.filter));
      // if (response instanceof HttpResponse) {
      //   const url = window.URL.createObjectURL(response.body);
      //   const a = document.createElement('a');
      //   document.body.appendChild(a);
      //   a.setAttribute('style', 'display: none');
      //   a.href = url;
      //   a.download = `Wound Exceptions Report`;
      //   a.click();
      //   window.URL.revokeObjectURL(url);
      //   a.remove();
      //   this._toastr.clear(infoToastr.toastId);
      // }
      // else {
      //   this._toastr.clear(infoToastr.toastId);
      //   this._toastr.error(response.message, 'Error');
      // }
    }
  }

}
