<h1>PT</h1>
<table mat-table [dataSource]="dataSourcePT">
    <ng-container matColumnDef="primary_med">
        <th mat-header-cell *matHeaderCellDef>Primary Med. Dx</th>
        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
            <strong>{{element.primary_med}} </strong>
            <span [matTooltip]="getPrimaryMedDescription(element)" matTooltipPosition="before">{{element.primary_med_description}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="treatment_dx">
        <th mat-header-cell *matHeaderCellDef>Treatment Dx</th>
        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
            <strong>{{element.treatment}} </strong>
            <span [matTooltip]="getTreatmentDescription(element)" matTooltipPosition="before">{{element.treatment_description}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="dos">
        <th mat-header-cell *matHeaderCellDef>DOS</th>
        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
            {{element.dos | date}}
        </td>
    </ng-container>
    <ng-container matColumnDef="transfers">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">Transfers</th>
        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
            <span [matTooltip]="getTransfers(element)" matTooltipPosition="before">{{element.transfers}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="bed_mobility">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">Bed Mobility</th>
        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
            <span [matTooltip]="getBedMobility(element)" matTooltipPosition="before">{{element.bed_mobility}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="gait">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">Gait</th>
        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
            <span [matTooltip]="getGait(element)" matTooltipPosition="before">{{element.gait}}</span>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumnsPT; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsPT;"></tr>
</table>

<div *ngIf="isLoading"
   style="display: flex; justify-content: center; align-items: center; background: white;">
  <mat-progress-spinner 
    [diameter]="30"
    color="primary" 
    mode="indeterminate">
  </mat-progress-spinner>
</div>

<h1>OT</h1>
<table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="dos">
        <th mat-header-cell *matHeaderCellDef>DOS</th>
        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
            {{element.dos | date}}
        </td>
    </ng-container>
    <ng-container matColumnDef="primary_med">
        <th mat-header-cell *matHeaderCellDef>Primary Med. Dx</th>
        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
            <strong>{{element.primary_med}} </strong>
            <span [matTooltip]="getPrimaryMedDescription(element)" matTooltipPosition="before">{{element.primary_med_description}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="treatment_dx">
        <th mat-header-cell *matHeaderCellDef>Treatment Dx</th>
        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
            <strong>{{element.treatment}} </strong>
            <span [matTooltip]="getTreatmentDescription(element)" matTooltipPosition="before">{{element.treatment_description}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="lb_dressing">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">LB Dressing</th>
        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
            <span [matTooltip]="getlbDressing(element)" matTooltipPosition="before">{{element.lb_dressing}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="ub_dressing">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">UB Dressing</th>
        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
            <span [matTooltip]="getUbDressing(element)" matTooltipPosition="before">{{element.ub_dressing}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="toileting">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">Toileting</th>
        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
            <span matTooltipPosition="before" [matTooltip]="getToileting(element)">{{element.toileting}}</span>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumnsOT; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsOT;"></tr>
</table>