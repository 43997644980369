






import { Component, OnInit, ViewChild } from '@angular/core';
import moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { PCCService } from 'src/app/services/pcc.service';
import * as global from '../global';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators'
import { isArray, isObject } from 'lodash';
import { MergeFacilityService } from './merge-facilities.service';
import { FacilityService } from 'src/app/services/facility.service';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { PatientListService } from '../patient-list/patient-list-service';
import { MatPaginator } from '@angular/material/paginator';
import { Console } from 'console';
import { MatDialog } from '@angular/material/dialog';
import {TestinputDialogueComponent} from './testinput-dialogue/testinput-dialogue.component'
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';

@Component({
  selector: 'app-merge-facilities',
  templateUrl: './merge-facilities.component.html',
  styleUrls: ['./merge-facilities.component.css']
})
export class MergeFacilitiesComponent implements OnInit {
  dob: string;
  name_P1: String = '';
  name_P2: String = '';

  facility_one: any;
  facility_two: any;

  filter1 = {
    title: ''
  };
  filter2 = {
    title: ''
  }
  facilitysSearchLoaderId = 'pcc-facilitys-loader';
  facilitysSearchLoaderId2 = 'pcc-facilitys-loader2';

  loaderId2 = 'loader-02';
  loaderId = 'loader-01';
  filterSearch = "";
  facilitysByProviderControl = new FormControl();
  facilitysByProviderControltwo = new FormControl();
  facilitysByProviderone = [];
  facilitysByProvidertwo = [];

  applySearchFilter: boolean;
  searchText2: any;
  searchText: any;
  searchText3: any;
  searchText4: any;
  revertStatus: boolean;
  revertStatusone: boolean;
  mergeStatusone: boolean;
  mergeStatustwo: boolean;
  choice: number;
  swapDoneCheck: any;
  facilities: any;
  dataSource: MatTableDataSource<unknown>;
  companies: any;
  pccfacilities: any;
  dataSourcePcc: MatTableDataSource<unknown>;
  dataSourceAll: MatTableDataSource<unknown>;
  params: {
    id: string
  }
  manualPair: any;
  filterFormGroup: FormGroup;
  patients: any;
  pccPatients: any;
  currentUser: any;
  pccSubscription: any;
  pccGlobalPatients: any;
  displayedColumns: string[] = ['dn', 'sign', 'pcc', 'import', 'status', 'actions'];
  dnElement: any;
  pccElement: any;
  @ViewChild(MatPaginator, { static: true } as any) paginator: MatPaginator;
  allAuditPatients: any;
  showPccLogo: boolean = false;

  constructor(private mergeFacilityService: MergeFacilityService,
    private _authService: AuthService,
    private _pccService: PCCService,
    private loader: NgxUiLoaderService,
    private pccService: PCCService,
    private facilityService: FacilityService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService,
    private _patientListService: PatientListService,
    private toastr: ToastrService) {
    this.filter1.title = '';
    this.filter2.title = '';
    this.choice = 0;
    this.params = {
      id: this.route.parent.snapshot.params.id
    };
    this.currentUser = this.authService.currentUser;

  }

  // ^(.*?(\b    \b)[^$]*)$

  ngOnInit(): void {
    this.filterFormGroup = new FormGroup({
      facility: new FormControl(null),
      pccfacility: new FormControl(null),
      patient: new FormControl(null),
      pccpatient: new FormControl(null),
      patientSearchInput: new FormControl(''),
      patientStatus: new FormControl('Current'),
      pageSize: new FormControl(200),
    });
    this.manualPair = [];
    this.revertStatusone = true;
    this.revertStatus = true;
    this.mergeStatusone = false;
    this.mergeStatustwo = false;

    this.initFacilitysByProvider();
    this.initFacilitysByProvidertwo();
    this.initPCCFacilities();
    this.initAllFacilities();
  }

  async pccfacilityChange(e: any) {
    this.showPccLogo = true;
    if(!e) return;
    this.facilitysByProviderone = e;
    //console.log("eee", e, this.patients?.length);

    this.facility_two = e;
    this.pccPatients=[];

    let query = {
      src_facility_id: this.facility_one._id,
      dst_facility_id: this.facility_two._id,
      is_active: true
    }
    const { pcc_facId: pccFacId, pcc_orgUuid } = this.facility_two;
    
    await this.mergeFacilityService.getMergingAudit(query).subscribe(async (res:any)=>{

      let response=res.data;
      //console.log("response : audit", response);
      this.allAuditPatients=response?.pairs;

      if(this.allAuditPatients?.length>0){
        //console.log("response : in if", this.allAuditPatients?.length);

        this.patients.forEach(element => {
          let pair;

            pair = {
              dn: element,
              pcc: undefined,
              check: true,
              mergingDone: false,
              manual_paired: false
    
            }

            this.pccPatients.push(pair);
          })


        for(let pairs of this.allAuditPatients){

          const args: any = {
            pccOrgUuid: pcc_orgUuid,
            pccFacID: pccFacId,
            pccPatientStatus: undefined,
            pccPatientID:pairs.pcc,
            pccPatientName: undefined,
            noPagination: true
          }
          let pcc;
          //console.log("going to get pcc getting");
          
          this.loader.startLoader('loader-03');

          await this.pccService.getPatientsByFacilityID(args).subscribe(
            async (response: any) => {
              this.loader.stopLoader('loader-03');
    
              if (response.status === 200) {
                //console.log("loggg pcc id patient",response.data.data );
                pcc= response.data.data;
                let pair = {
                  dn: pairs.dn,
                  pcc: pcc,
                  check: false,
                  mergingDone: true,
                  manual_paired: false,
                  dob_matched:true
                }

                let objIndex = this.pccPatients.findIndex((obj => (obj.dn?._id?.toString() == pairs.dn?._id?.toString())));
                this.pccPatients[objIndex] = pair;

                //console.log("into pcc getting",this.pccPatients);
                this.dataSource.data = this.pccPatients;
                this.dataSource.paginator = this.paginator;
              }
            })

        }

          this.dataSource.data = this.pccPatients;
          this.dataSource.paginator = this.paginator;
    
      }else{
        if (this.patients?.length > 0) {

          this.patients.forEach(element => {
    
            let pair = {
              dn: element,
              pcc: undefined,
              check: true,
              mergingDone: false,
              manual_paired: false,
              dob_matched:true
            }
            this.pccPatients.push(pair);
    
          });
          this.dataSource.data = this.pccPatients;
          this.dataSource.paginator = this.paginator;
    
    
        }

      }
      //console.log(" this.pcc", this.pccPatients );

    });

    
  }

  async facilityChange(e: any) {
    //console.log("eee2", e);
    if(!e) return;

    this.patients = [];
    this.pccPatients = [];
    this.dataSource = new MatTableDataSource(this.pccPatients);
    this.facilitysByProvidertwo = e;
    this.facility_two = null;
    this.searchText="";
    this.pccPatients=[];
    await this.initPatients(e);
    this.facility_one = e;

  }

  async patientChange(e: any) {
    //console.log("eee2", e);
    this.dnElement = e;

  }

  async pccpatientChange(e: any) {
    //console.log("eee2", e);
    this.pccElement = e;

  }
  clearAll() {

    this.facility_one = null;
    this.facility_two = null;
    this.pccPatients = [];
    this.dataSource = new MatTableDataSource(this.pccPatients);
    this.patients = [];
    this.pccGlobalPatients = [];
    this.manualPair = [];
    this.searchText = '';
    this.searchText4 = '';
    this.searchText2 = '';
    this.searchText3 = '';
    this.dataSourceAll = new MatTableDataSource([]);
    this.dataSourcePcc = new MatTableDataSource([]);

  }
  AddPair() {
    if (this.pccElement && this.dnElement) {
      //console.log("inedx", this.pccPatients.findIndex((obj => (obj.dn == this.dnElement && obj.pcc == this.pccElement))))
      if (this.pccPatients.findIndex((obj => (obj.dn == this.dnElement && obj.pcc == this.pccElement))) < 0) {

        let pair = {
          dn: this.dnElement,
          pcc: this.pccElement,
          check: true,
          mergingDone: false,
          manual_paired: true,
          dob_matched:true
        }
        this.manualPair.push(pair);
        this.pccPatients.push(pair);
        this.dataSource = new MatTableDataSource(this.pccPatients);
        this.searchText3 = '';
        this.searchText4 = '';
      } else {
        this.toastr.warning('Pair Already Selected', 'Warning');
      }
    } else {
      this.toastr.warning('Kindly Select Both type of Patients', 'Warning');
    }
  }

  async initPatients(fac) {

    this.patients = [];
    var details = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <any>null,
      primary_doctor_id: <string>null,
      index: <string>null
    };
    details.id = this.currentUser._id;
    details.auth_key = this.currentUser.auth_key;
    details.usertype = this.currentUser.user_type;
    details.index = null;
    this.loader.startLoader('loader-01');
    const company_id = this.params.id;
    const response: any = await this._patientListService.getPatientsByFacilityId_v2(details, null, { active: true, facility: fac._id }, company_id).toPromise();
    if (response.status === 200) {
         

      this.patients = response.data.array;
      this.loader.stopLoader('loader-01');
      this.toastr.success('Patients Fetched from DB Successfully.', 'Success');

      if (this.patients?.length > 0) {

        this.patients.forEach(element => {

          let pair = {
            dn: element,
            pcc: undefined,
            check: true,
            mergingDone: false,
            manual_paired: false,
            dob_matched:false
          }
          this.pccPatients.push(pair);

        });
        this.dataSource = new MatTableDataSource(this.pccPatients);
        this.dataSource.paginator = this.paginator;


      }

      //console.log('all patients',this.pccPatients, this.patients);

    }

  }

  async fetchPcc(item:any) {
    if (this.facility_one && this.facility_two) {
      
      let first_name_only= item?.dn?.first_name?.split(" ", 1)[0];
      let last_name_only =item?.dn?.last_name?.split(" ", 1)[0];
      
      //console.log("itemm : ", first_name_only+ ' ' + last_name_only);

      if(last_name_only)
      await this.initPccPatientSingle(this.facility_two, item?.dn, first_name_only+ ' ' + last_name_only );
      else
      await this.initPccPatientSingle(this.facility_two, item?.dn, first_name_only );

      //this.pccElement=
    } else {
      this.toastr.warning('Kindly select both facilities', 'warning');
    }


  }


  async fetchAll(){
    if (this.facility_one && this.facility_two) {
      
      for(let item of this.pccPatients){
  

          let first_name_only= item?.dn?.first_name?.split(" ", 1)[0];
          let last_name_only =item?.dn?.last_name?.split(" ", 1)[0];
          
          //console.log("itemm : ", first_name_only+ ' ' + last_name_only);
          if(item?.pcc){
            //console.log("already pcc fetched : ",item?.pcc );
          
          }else{

            if(last_name_only)
            await this.initPccPatientSingle(this.facility_two, item?.dn, first_name_only+ ' ' + last_name_only, true );
            else
            await this.initPccPatientSingle(this.facility_two, item?.dn, first_name_only, true );
          }
        }

     
    } else {
      this.toastr.warning('Kindly select both facilities', 'warning');
    }

  }

  async initPCCFacilities() {
    const company_id = this.params.id;

    let companyPopulation: facilityPopulationInterface = {
      collection: 'companies',
      field: ["company_id"],
      filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: ['$$company_id', '$_id'] }] } },
      project: { name: 1 },
    };
    let companyAssocPopulation: facilityPopulationInterface = {
      collection: 'companies',
      field: ["assoc_company_ids"],
      filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: [true, { $in: ['$_id', '$$assoc_company_ids'] }] }] } },
      project: { name: 1 },
    };
    
    const facilitiesResponse: any = await this.facilityService.getFacilities({ pcc_facId: { $exists: true }, is_active: true, assoc_company_ids: company_id }, { _id: 1, title: 1, pcc_facId: 1, pcc_orgUuid: 1, company_id: 1, assoc_company_ids: 1, source: 1}, [companyPopulation, companyAssocPopulation]).toPromise();
    if (facilitiesResponse.status == 200) {
      this.pccfacilities = facilitiesResponse.data.array;
      this.pccfacilities=this.sortTitleVise(this.pccfacilities);
      this.dataSourcePcc = new MatTableDataSource(this.pccfacilities);

      //console.log("this.initPCCFacilities", this.pccfacilities);
    }
  }


  async initPccPatientSingle(facility, dn_pat, dn_pat_name = null, all=false) {
    if (!facility) return;
    const { pcc_facId: pccFacId, pcc_orgUuid } = facility;
    //console.log("pcc_facId pcc_facId", pccFacId);
    if (!pccFacId) {
      return;
    }

    const args: any = {
      pccOrgUuid: pcc_orgUuid,
      pccFacID: pccFacId,
      pccPatientStatus: undefined,
      pccPatientName: dn_pat_name,
      // pageSize: 200,
      noPagination: true
    }

    this.loader.startLoader('loader-02');

    this.pccSubscription = await this.pccService
      .getPatientsByFacilityID(args)
      .subscribe(
        async (response: any) => {
          this.loader.stopLoader('loader-02');
          
          if (response.status === 200) {
            
            this.pccGlobalPatients = response.data.data;
            if (this.pccGlobalPatients[0] != null) {

              if(!all)
              this.toastr.success('Patients Fetched from PCC Successfully.', 'Success');

              for (let pccElement of this.pccGlobalPatients) {
                let patientPccFullName=pccElement?.firstName?.toLowerCase().trim()+' '+pccElement?.lastName?.toLowerCase().trim();

                let dn_first_name_only= dn_pat?.first_name?.toLowerCase().trim().split(" ", 1)[0];
                let dn_last_name_only =dn_pat?.last_name?.toLowerCase().trim().split(" ", 1)[0];
          

                let isPatientMatched=(patientPccFullName.includes(dn_first_name_only) )&&(patientPccFullName.includes(dn_last_name_only))

                if(isPatientMatched){
                      if((pccElement?.birthDate==dn_pat?.date_of_birth)){

                        let objIndex = this.pccPatients.findIndex((obj => (obj.dn?._id?.toString() == dn_pat?._id?.toString())));
                        
                        //console.log("comparison  in if: ", pccElement?.firstName, objIndex)
                        
                        if(objIndex>=0){
                          this.pccPatients[objIndex].pcc = pccElement;
                          this.pccPatients[objIndex].dob_matched=true;
      
                          //console.log("afterr  : ",this.pccPatients);
                          this.dataSource.data = this.pccPatients;
                          this.dataSource.paginator = this.paginator;
      
                        }
      
                          return;
                      }else{
                        let objIndex = this.pccPatients.findIndex((obj => (obj.dn?._id?.toString() == dn_pat?._id?.toString())));
                        
                        //console.log("comparison  in else: ", pccElement?.firstName, objIndex)
                        
                        if(objIndex>=0){
                          this.pccPatients[objIndex].pcc = pccElement;
                          this.pccPatients[objIndex].dob_matched=false;

                          //console.log("afterr  : ",this.pccPatients);
                          this.dataSource.data = this.pccPatients;
                          this.dataSource.paginator = this.paginator;
      
                        }
      
                          return;
                      }
                  }
              }

            }else{
              if(!all){

                this.toastr.warning('No patient found with same name in PCC.', 'Warning');
              }

            }

          } else {
            this.toastr.error(response.message, 'Error');
          }
          //console.log("pccPatients ", this.pccPatients);
        }
      );


  }


  async initPccPatientSingleManual(facility, dn_pat, first_name, last_name, all=false) {
    if (!facility) return;
    const { pcc_facId: pccFacId, pcc_orgUuid } = facility;
    //console.log("pcc_facId pcc_facId", pccFacId);
    if (!pccFacId) {
      return;
    }

    const args: any = {
      pccOrgUuid: pcc_orgUuid,
      pccFacID: pccFacId,
      pccPatientStatus: undefined,
      pccPatientName: last_name?first_name+' '+last_name:first_name,
      // pageSize: 200,
      noPagination: true
    }

    this.loader.startLoader('loader-02');

    this.pccSubscription = await this.pccService
      .getPatientsByFacilityID(args)
      .subscribe(
        async (response: any) => {
          this.loader.stopLoader('loader-02');
          
          if (response.status === 200) {
            
            this.pccGlobalPatients = response.data.data;
            if (this.pccGlobalPatients[0] != null) {

              if(!all)
              this.toastr.success('Patients Fetched from PCC Successfully.', 'Success');

              for (let pccElement of this.pccGlobalPatients) {

                      if((pccElement?.birthDate==dn_pat?.date_of_birth)){

                        let objIndex = this.pccPatients.findIndex((obj => (obj.dn?._id?.toString() == dn_pat?._id?.toString())));
                        
                        //console.log("comparison  in if: ", pccElement?.firstName, objIndex)
                        
                        if(objIndex>=0){
                          this.pccPatients[objIndex].pcc = pccElement;
                          this.pccPatients[objIndex].dob_matched=true;
      
                          //console.log("afterr  : ",this.pccPatients);
                          this.dataSource.data = this.pccPatients;
                          this.dataSource.paginator = this.paginator;
      
                        }
      
                          return;
                      }else{
                        let objIndex = this.pccPatients.findIndex((obj => (obj.dn?._id?.toString() == dn_pat?._id?.toString())));
                        
                        //console.log("comparison  in else: ", pccElement?.firstName, objIndex)
                        
                        if(objIndex>=0){
                          this.pccPatients[objIndex].pcc = pccElement;
                          this.pccPatients[objIndex].dob_matched=false;

                          //console.log("afterr  : ",this.pccPatients);
                          this.dataSource.data = this.pccPatients;
                          this.dataSource.paginator = this.paginator;
      
                        }
      
                          return;
                      }
                  
              }

            }else{
              if(!all){

                this.toastr.warning('No patient found with same name in PCC.', 'Warning');
              }

            }

          } else {
            this.toastr.error(response.message, 'Error');
          }
          //console.log("pccPatients ", this.pccPatients);
        }
      );


  }


  async dialogToGetInputManual(item){

    const obj = {
      title: 'Kindly enter patient name to search manually : ',
      instruction:'*please select one',
      input: {item:item,facility:this.facility_two},
    }
    const dialogRef = this.dialog.open(TestinputDialogueComponent, {
      data: obj,
      width: '35%'
    });

    dialogRef.afterClosed().subscribe(async (data) => {
      if(data){

      //  let first_name_only= data?.split(" ", 2)[0];
      //  let last_name_only =data?.split(" ", 2)[1];
        

          if(data){
            let objIndex = this.pccPatients.findIndex((obj => (obj.dn?._id?.toString() == item?.dn?._id?.toString())));
            //console.log("operation need to be done", data);
                        
            //console.log("comparison  in if: ", data?.firstName, objIndex)
            
            if(objIndex>=0){
              this.pccPatients[objIndex].pcc = data;
              this.pccPatients[objIndex].dob_matched=true;

              //console.log("afterr  : ",this.pccPatients);
              this.dataSource.data = this.pccPatients;
              this.dataSource.paginator = this.paginator;

            }
          }
          // await this.initPccPatientSingleManual(this.facility_two, item?.dn, first_name_only?.trim(), last_name_only?.trim(), false );
          // else
          // await this.initPccPatientSingleManual(this.facility_two, item?.dn, first_name_only?.trim(), null, false );


      }else{
        //console.log("operation need to be skipped");
      }

    });

  }


  async initPccPatients(facility, dn_pat_name = null) {
    if (!facility) return;
    const { pcc_facId: pccFacId, pcc_orgUuid } = facility;
    //console.log("pcc_facId pcc_facId", pccFacId);
    if (!pccFacId) {
      
      return;
    }
    this.pccPatients = [];
    let pccpResponse = [];
    this.pccGlobalPatients = [];

    const args: any = {
      pccOrgUuid: pcc_orgUuid,
      pccFacID: pccFacId,
      pccPatientStatus: undefined,
      pccPatientName: null,
      // pageSize: 200,
      noPagination: true
    }
    if (this.pccSubscription)
      this.pccSubscription.unsubscribe();
    this.loader.startLoader('loader-02');

    this.pccSubscription = await this.pccService
      .getPatientsByFacilityID(args)
      .subscribe(
        async (response: any) => {
          this.loader.stopLoader('loader-02');
          this.toastr.success('Patients Fetched from PCC Successfully.', 'Success');

          if (response.status === 200) {

            this.pccGlobalPatients = response.data.data;
            if (this.patients?.length > 0 && this.pccGlobalPatients[0] != null) {

              await this.pccGlobalPatients.filter((pccElement) => {

                this.patients.forEach(element => {
                  if ((element?.first_name == pccElement?.firstName) && (element?.last_name == pccElement?.lastName) && (element?.date_of_birth == pccElement?.birthDate)) {
                    //console.log("comparison  : ", element?.first_name, pccElement?.firstName)
                    let pair = {
                      dn: element,
                      pcc: pccElement,
                      check: true,
                      mergingDone: false,
                      manual_paired: false,
                      dob_matched:true

                    }
                    this.pccPatients.push(pair);
                    return pccElement;
                  }
                });
                this.dataSource = new MatTableDataSource(this.pccPatients);

              })

            }

          } else {
            this.toastr.error(response.message, 'Error');
          }
          //console.log("pccPatients ", this.pccPatients);
        }
      );


  }

  changeDot(item, status) {
    let objIndex = this.pccPatients.findIndex((obj => (obj.dn == item.dn && obj.pcc == item.pcc)));
    this.pccPatients[objIndex].mergingDone = status;
    //console.log("this.mergingDone ", item, objIndex);

  }

  changecheck(item) {
    let objIndex = this.pccPatients.findIndex((obj => (obj.dn == item.dn && obj.pcc == item.pcc)));
    this.pccPatients[objIndex].check = !item.check;
    //console.log("this.pccPatients", item, objIndex);

  }

  async initAllFacilities() {
    const company_id = this.params.id;
    //console.log("company_id", company_id);
    const filter = {
      is_active: true,
      assoc_company_ids: company_id,
      pcc_facId: { $exists: false },
      merged:true
    }
    const projection = {
      'title': 1,
      'address': 1,
      'city': 1,
      'state': 1,
      'zip_code': 1,
      'is_active': 1,
      'company_id': 1,
      'source': 1,
      'assoc_company_ids': 1,
      'pcc_facId':1

    }

    let companyPopulation: facilityPopulationInterface = {
      collection: 'companies',
      field: ["company_id"],
      filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: ['$$company_id', '$_id'] }] } },
      project: { name: 1 },
    };
    let companyAssocPopulation: facilityPopulationInterface = {
      collection: 'companies',
      field: ["assoc_company_ids"],
      filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: [true, { $in: ['$_id', '$$assoc_company_ids'] }] }] } },
      project: { name: 1 },
    };

    const response: any = await this.facilityService.getFacilities(filter, projection, [companyPopulation, companyAssocPopulation]).toPromise();
    if (response.status === 200) {
      this.facilities = response.data.array;
      this.facilities=this.sortTitleVise(this.facilities);

      //console.log("this.initAllFacilities", this.facilities);

    }
    this.dataSourceAll = new MatTableDataSource(this.facilities);


  }


  getPCCStatus(facility) {
    if (facility.pcc_facId) {
      return "PCC";
    } else return "Non PCC";
  }
  isPCC(facility) {
    if (facility.pcc_facId) {
      return true;
    } else return false;
  }
  displayFacilityFn(facility): string {
    return facility && facility.title ? facility.title : '';
  }

  async initFacilitysByProvider() {

    this.facilitysByProviderControl.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(async (name) => this.doInitFacilitys(name));
  }

  async doInitFacilitys(name) {
    this.facility_one = null;
    if (!name) return;

    if (isObject(name)) {
      this.facility_one = name;
      this.swapDoneCheck = this.facility_one._id;
      //console.log("this.facility_one?.pcc_facId 111", this.facility_one?.pcc_facId, this.facility_two?.pcc_facId);


      if (this.facility_two?.pcc_facId) {
        if (this.facility_one?.pcc_facId) {
          this.toastr.error('Both Facilities Cant be PCC.', 'Error');
          this.facility_one = null
          return;
        }
      }
      if (this.facility_one && this.facility_two) {
        if (!this.facility_two?.pcc_facId) {
          if (!this.facility_one?.pcc_facId) {
            this.toastr.error('Both Facilities Cant be Non-PCC.', 'Error');
            this.facility_one = null
            return;
          }
        }
      }
      ////console.log("name :", this.facility_one);
      if ((this.facility_one) && this.facility_two?.pcc_facId) {

        let swap = this.facility_one;
        this.facility_one = this.facility_two;
        this.facility_two = swap;
      }



      this.revertStatusone = true;
      this.revertStatus = true;
      this.mergeStatusone = false;
      this.mergeStatustwo = false;
      return;
    }
    this.toastr.info('Fetching Facility', 'Wait....');

    this.filter1.title = name?.trim();
    //console.log("this.filter1", this.filter1);


    await this.mergeFacilityService.searchTheFacility(this.filter1)
      .subscribe((facilitysResponse: any) => {
        //console.log("resssss 1", facilitysResponse);
        if (facilitysResponse.data?.length == 0) {
          this.toastr.warning(' 0 Facility found.', 'Warning');
          this.revertStatusone = true;
          this.revertStatus = true;
          this.mergeStatusone = false;
          this.mergeStatustwo = false;
        } else {
          //this.toastr.success('Facility one has been fetched.', 'Success');
        }
        this.facilitysByProviderone = facilitysResponse.data;
      })
  }

  async initFacilitysByProvidertwo() {

    this.facilitysByProviderControltwo.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(async (name) => this.doInitFacilitysTwo(name));
  }

  async doInitFacilitysTwo(name) {
    this.facility_two = null;
    if (!name) return;
    if (isObject(name)) {
      this.facility_two = name;

      //console.log("this.facility_one?.pcc_facId 222", this.facility_one?.pcc_facId, this.facility_two?.pcc_facId);
      if (this.facility_one?.pcc_facId) {
        if (this.facility_two?.pcc_facId) {
          this.facility_two = null;
          this.toastr.error('Both Facilities Cant be PCC.', 'Error');
          return;
        }
      }
      if (this.facility_one && this.facility_two) {

        if (!this.facility_one.pcc_facId) {
          if (!this.facility_two.pcc_facId) {
            this.facility_two = null;
            this.toastr.error('Both Facilities Cant be Non-PCC.', 'Error');
            return;
          }
        }
      }

      //console.log("resss 2:", this.facility_two);
      if ((this.facility_one) && this.facility_two?.pcc_facId) {

        let swap = this.facility_one;
        this.facility_one = this.facility_two;
        this.facility_two = swap;

        //console.log("swaap 2:");

      }
      this.revertStatusone = true;
      this.revertStatus = true;
      this.mergeStatusone = false;
      this.mergeStatustwo = false;
      return;
    }
    this.toastr.info('Fetching Facility', 'Wait....');

    this.filter2.title = name?.trim();
    //console.log("this.filter2", this.filter2);


    await this.mergeFacilityService.searchTheFacility(this.filter2)
      .subscribe((facilitysResponse: any) => {
        ////console.log("resssss 1", facilitysResponse);
        if (facilitysResponse.data?.length == 0) {
          this.toastr.warning(' 0 Facility found.', 'Warning');
          this.revertStatusone = true;
          this.revertStatus = true;
          this.mergeStatusone = false;
          this.mergeStatustwo = false;
        } else {
          //this.toastr.success('Facility two has been fetched.', 'Success');
        }
        this.facilitysByProvidertwo = facilitysResponse.data;
      })
  }


  getLayout() {
    if (this.swapDoneCheck) {
      if (this.swapDoneCheck == this.facility_one?._id) {
        return true;
      } else if (this.swapDoneCheck != this.facility_one?._id) {
        return false;
      }
    }
    else {
      return false;
    }
  }

  clearFilterOne() {
    this.revertStatus = true;
    this.mergeStatusone = false;
    this.mergeStatustwo = false;



    this.filter1.title = '';
    this.facility_one = null;
    this.facilitysByProviderone = [];
    this.choice = 0;
  }

  clearFilterTwo() {

    this.revertStatus = true;
    this.mergeStatusone = false;
    this.mergeStatustwo = false;



    this.filter2.title = '';

    this.facility_two = null;
    this.facilitysByProvidertwo = []
    this.choice = 0;
  }

  async facility_one_to_facility_two() {
    //console.log("queryyy oo: 11", this.pccPatients);
    let query;
    query = {
      src_facility_id: this.facility_one._id,
      dst_facility_id: this.facility_two._id,
      is_active: true
    }
    await this.mergeFacilityService.getMergingAudit(query).subscribe(async (res: any) => {
      let result = res.data;
      this.choice = 1;
      if (result) {
        //console.log("audittt", result);

      } else {

        var pair_ids = [];
        for (let item of this.pccPatients) {

          if (item.check) {
            //pair_ids.push({dn:item?.dn?._id, pcc:item?.pcc?.patientId, check:item?.check});
            pair_ids.push({ dn: item?.dn, pcc: item?.pcc, check: item?.check })
          }
        }
        let noMatch = []; let leftOuts = [];


        for (let item of this.patients) {
          if (!pair_ids.find(function (element) { return element.dn._id == item._id })) {
            if (this.pccPatients.find(function (element) { return (item?.first_name == element?.pcc?.firstName) && (item?.last_name == element?.pcc?.lastName) && (item?.date_of_birth == element?.pcc?.birthDate) })) {
              leftOuts.push(item._id);
            } else {
              noMatch.push(item._id);
            }
          }
        }

        //console.log("queryyy oo: 11", pair_ids, this.pccPatients);
        let filter = {
          pairs: pair_ids,
          dn_fac: this.facility_one,
          pcc_fac: this.facility_two,
          noMatch: noMatch,
          leftOuts
        }
        await this.mergeFacilityService.mergeTheFacility(filter).subscribe((res: any) => {
          let result2 = res.data;
          this.toastr.success('Merge is Successfull.', 'Success');


        })
      }
    });
  }



  selectAll() {

    for (let item in this.pccPatients) {
      this.pccPatients[item].check = true;
    }

  }

  async facility_one_to_facility_two_() {
    let query;
    query = {
      src_facility_id: this.facility_one._id,
      dst_facility_id: this.facility_two._id,
    }
    if (this.facility_one.pcc_facId) {
      query.is_srcP_pcc = true;
    }
    if (this.facility_two.pcc_facId) {
      query.is_dstP_pcc = true;
    }
    if (this.facility_one._id == this.facility_two._id) {
      this.toastr.error('Same Facility can not be merged.', 'ERROR');
      this.clearFilterTwo();
      this.clearFilterOne();
      return;
    }
    if (!this.facility_one?.pcc_facId) {
      this.toastr.error('Source must be PCC.', 'ERROR');
      this.clearFilterTwo();
      this.clearFilterOne();
      return;
    }

    this.mergeStatusone = true;
    this.mergeStatustwo = true;

    await this.mergeFacilityService.getMergingAudit(query).subscribe(async (res: any) => {
      let result = res.data;
      this.choice = 1;
      //console.log("audittt", result);
      if (result) {
        this.toastr.error('Facility are already Merged.', 'ERROR');
        this.revertStatusone = false;
        this.revertStatus = false;
      }
      else {
        await this.mergeFacilityService.mergeTheFacility(query).subscribe((res: any) => {
          let result2 = res.data;
          this.toastr.success("Merging Done", 'Success');
          this.revertStatusone = false;
          this.revertStatus = false;

        })
      }
    })

  }


  async revertChanges_one_to_two() {


    let query;
    query = {
      src_facility_id: this.facility_one._id,
      dst_facility_id: this.facility_two._id,
      is_active: true
    }
    await this.mergeFacilityService.getMergingAudit(query).subscribe(async (res: any) => {
      let result = res.data;
      this.choice = 1;
      if (result) {

        let filter = {
          dn_fac: this.facility_one,
          pcc_fac: this.facility_two,

        }
        await this.mergeFacilityService.revertTheFacility(filter).subscribe((res: any) => {
          let result = res.data;
          //console.log("resresults : 122", result);
          this.toastr.success('Merge is Reverted.', 'Success');

        })

      } else {
        this.toastr.warning('Havent merged yet.', 'Warning');

      }
    })

  }


  async mergeOne_(item) {
    //console.log("revert onee", item);
    this.changeDot(item, true);
  }

  async revertOne_(item) {
    //console.log("revert onee", item);
    this.changeDot(item, false);

  }

  async mergeAll(){

    let pairs=[];

    for(let item of this.pccPatients){
      if(item?.pcc&&item?.dob_matched&&item?.check){
        pairs.push(item);
        //console.log("merge matchh", pairs);
      }
    }
    if(pairs?.length>0)
    await this.mergeOne(pairs, false);

  }

  async revertAll(){
    let pairs=[];
    for(let item of this.pccPatients){
      if(item?.pcc&&!item?.check){

        pairs.push(item);
        
        //console.log("revert matchh", pairs);
      }
    }
    
    if(pairs?.length>0)
     await this.revertOne(pairs, false)
  }


  async mergeOne(item, all=false) {
    //console.log("merge onee", item);
    this.loader.startLoader('loader-03');

    let filter = {
      pairs: isArray(item)?item: [item],
      dn_fac: this.facility_one,
      pcc_fac: this.facility_two,
      noMatch: null,
      leftOuts: null
    }

    //console.log("queryyy oo: 11", filter, this.pccPatients);

    // await this.mergeFacilityService.mergeTheFacilitySingle(filter).subscribe((res: any) => {
    //   let result2 = res.data;
    //   this.loader.stopLoader('loader-03');

    //   this.toastr.success('Merge is Successfull.', 'Success');
    //   this.changeDot(item, true);

    // })
    await this.mergeFacilityService.mergeTheFacilitySingle_v2(filter).subscribe((res: any) => {
      let result2 = res.data;
      this.loader.stopLoader('loader-03');
      if(!all)
      this.toastr.success('Merge is Successfull.', 'Success');
      if(isArray(item)){
        for(let itm of item){
          this.changeDot(itm, true);
          this.changecheck(itm);
        }
      }
      else{
        this.changeDot(item, true);
        this.changecheck(item);
        
      }

    })

  }

  async revertOne(item, all=false) {
    //console.log("revert onee", item);
    this.loader.startLoader('loader-03');

    let filter = {
      dn_fac: this.facility_one,
      pcc_fac: this.facility_two,
      pairs: isArray(item)?item: [item],
      noMatch: null,
      leftOuts: null
    }
    // await this.mergeFacilityService.revertTheFacilitySingle(filter).subscribe((res: any) => {
    //   let result = res.data;
    //   this.loader.stopLoader('loader-03');

    //   //console.log("resresults : 122", result);
    //   this.toastr.success('Merge is Reverted.', 'Success');
    //   this.changeDot(item, false);

    // })
    await this.mergeFacilityService.revertTheFacilitySingle_v2(filter).subscribe((res: any) => {
      let result = res.data;
      this.loader.stopLoader('loader-03');

      //console.log("resresults : 122", result);
      
      if(!all)
      this.toastr.success('Merge is Reverted.', 'Success');

      if(isArray(item)){
        for(let itm of item){
          this.changeDot(itm, false);
          this.changecheck(itm);
        }
      }
      else{
        this.changeDot(item, false);
        this.changecheck(item);
        
      }

    })
  }

  sortTitleVise(arrayTosort){
      let users=arrayTosort;
      let result=users.sort(function(a, b){
          if(a.title?.toLowerCase() < b.title?.toLowerCase()) { return -1; }
          if(a.title?.toLowerCase() > b.title?.toLowerCase()) { return 1; }
          return 0;
      })
      return result;
  }

  async initPccPatientSingle_forRevert(facility, dn_pat_name = null) {

    const { pcc_facId: pccFacId, pcc_orgUuid } = facility;
  
    const args: any = {
      pccOrgUuid: pcc_orgUuid,
      pccFacID: pccFacId,
      pccPatientStatus: undefined,
      pccPatientName: dn_pat_name,
      noPagination: true
    }
  
    await this.pccService.getPatientsByFacilityID(args).subscribe(
        async (response: any) => {

          if (response.status === 200) {
            let pcc= response.data.data;
          }
        })
  }

}


