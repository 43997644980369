import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment'
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { WoundService } from 'src/app/services/wound.service';
import { TranscriptionCreateService } from '../../transcription-create/transcription-create.service';
import * as global from '../../../includes/global';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-wound-notes',
  templateUrl: './wound-notes.component.html',
  styleUrls: ['./wound-notes.component.css']
})
export class WoundNotesComponent implements OnInit {
  global = global;
  providerControl = new FormControl();
  facilityControl = new FormControl();
  filter: any = {
    updated_date: {
      startDate: moment().subtract(2, 'days'),
      endDate: moment()
    }
  }
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  c_date = moment(new Date());
  facilities: Array<any> = [];
  providers: Array<any> = [];
  provider: any;
  filteredFacilities: Observable<string[]>;
  filteredProviders: Observable<string[]>;
  company_id: any;
  facility: any;
  dataSource: any = new MatTableDataSource([]);
  displayedColumns = ['no', 'name','facility', "provider",'created_at', 'updated_at', 'note_created'];
  currentUser: any;
  allWoundNotes: Array<any> = [];
  selectedStatus = "no";
  showPccLogo: boolean = false;
  constructor(
    private _transcriptionCreateService: TranscriptionCreateService,
    private userTypesRelationshipService: UserTypesRelationshipService,
    private route: ActivatedRoute,
    private _woundService: WoundService,
    private _authService: AuthService,
    private loader: NgxUiLoaderService,
    private toastr: ToastrService
  ) {
    this.currentUser = this._authService.currentUser;
  }

  ngOnInit(): void {
    if (window.location.pathname.includes('company') && !window.location.pathname.includes('manage-companies')) {
      this.company_id = this.route.parent.parent.snapshot.params.id;
    }
    else if (window.location.pathname.includes('manage-companies')) {
      this.company_id = this.route.parent.parent.snapshot.params.id;
    } else {
      this.company_id = this.currentUser.company_id;
    }
    this.filter.company_id = this.company_id;
    this.getProviderList();
    this.initFacilities();
    this.handleApplyFilterClick();
  }
  changeServiceDate(event) {
    if (event.startDate && event.endDate) {
      this.filter.updated_date = {
        startDate: null,
        endDate: null
      }
      this.filter.updated_date.startDate = event.startDate.toDate();
      this.filter.updated_date.endDate = event.endDate.toDate();
    }
  }
  onSelectProvider(item = null) {
    if (item) {
      this.initFacilities(item);
      this.provider = item;
      this.filter.provider_id = item._id;
    }else{
      delete this.filter.provider_id;
    }
  }
  async initFacilities(provider = null) {
    let response: any = {};
    if (provider) {
      response = await this._transcriptionCreateService.getFacilities(provider._id).toPromise();
    }
    if (response.status === 200) {
      this.facilities = response.data.array;
      this.filteredFacilities = this.facilityControl.valueChanges.pipe(
        startWith(''),
        map((value:any) => this.filterFacilities(value))
      );
    }
  }
  onSelectFacility(item = null) {
    if (item) {
      this.facility = item;
      this.filter.facility_id = item._id;
      if (item.pcc_facId || item.source === 'PointClickCare') {
        this.showPccLogo = true;
      } else {
        this.showPccLogo = false;
      }
    } else {
      this.facility = null;
      if (this.filter.facility_id) {
        delete this.filter.facility_id;
      }
    }
  }
  getProviderList() {
    this.userTypesRelationshipService.getDoctorsList('true', this.company_id).subscribe((response: any) => {
      if (response.status === 200) {
        this.providers = response.data;
        if (this.providers.length > 0) {
          // this.providers.sort(this.sortProviders);
          this.providers = this.sortFirstNameVise(this.providers);
          this.filteredProviders = this.providerControl.valueChanges.pipe(
            startWith(''),
            map((value:any) => this.filterProvider(value)));
        }
      }
    });
  }
  clearProviderFilter(){
      this.providerControl.setValue('');
  }
  clearFacilityFilter(){
      this.facilityControl.setValue('');
      this.showPccLogo = false;
  }
  getProvControlStatus(){
    if(this.providerControl?.value&&(this.providerControl?.value!='')) return true; else return false;
  }
  getFacControlStatus(){
    if(this.facilityControl?.value&&(this.facilityControl?.value!='')) return true; else return false;
  }
  sortProviders(a, b) {
    if (a.first_name < b.last_name) {
      return -1;
    }
    if (a.first_name > b.last_name) {
      return 1;
    }
    return 0;
  }
  private filterProvider(value: string): string[] {
    if (value && this.providers.length > 0) {
      const filterValue = value.toLowerCase();
      let users = this.providers.filter(option => (option.middle_name && option.middle_name.toLowerCase().indexOf(filterValue) === 0) || option.last_name.toLowerCase().indexOf(filterValue) === 0 || option.first_name.toLowerCase().indexOf(filterValue) === 0);
      return this.sortFirstNameVise(users);
    }
    else {
      return this.sortFirstNameVise(this.providers);
    }
  }
  sortFirstNameVise(arrayTosort) {
    let users = arrayTosort;
    let result = users.sort(function (a, b) {
      if (a.first_name?.toLowerCase() < b.first_name?.toLowerCase()) { return -1; }
      if (a.first_name?.toLowerCase() > b.first_name?.toLowerCase()) { return 1; }
      return 0;
    })
    return result;
  }
  private filterFacilities(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      if(value.length == 1) {
        this.showPccLogo = false;
      }
      return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) === 0);
    }
    else {
      return this.facilities;
    }
  }
  async handleApplyFilterClick() {
    if(!this.providerControl.value){
      delete this.filter.provider_id;
    }
    this.loader.startLoader('loader-01');
    let response: any = await this._woundService.getWoundNotes(this.filter).toPromise();
    if (response.status == 200) {
      this.allWoundNotes = response.data;
      this.selectedStatus = "no";
      if (this.selectedStatus == "no") {
        let woundNotes = [];
        this.allWoundNotes.forEach((element) => {
          if (element.note_status == "No") {
            woundNotes.push(element)
          }
        });
        this.dataSource.data = woundNotes;
      } 
      this.loader.stopLoader('loader-01');
    }
  }
  resetFilter() {
    this.showPccLogo = false;
    this.facilities = [];
    this.providerControl.setValue('');
    this.facilityControl.setValue('');
    this.filter.updated_date = {
      startDate: moment().subtract(2, 'days'),
      endDate: moment(),
    };
   this.handleApplyFilterClick();
  }
  onSelectStatus() {
    if (this.selectedStatus == "yess") {
      let woundNotes = [];
      this.allWoundNotes.forEach((element) => {
        if (element.note_status == "Yes") {
          woundNotes.push(element)
        }
      });
      this.dataSource.data = woundNotes;
    }
    else if (this.selectedStatus == "no") {
      let woundNotes = [];
      this.allWoundNotes.forEach((element) => {
        if (element.note_status == "No") {
          woundNotes.push(element)
        }
      });
      this.dataSource.data = woundNotes;
    } else {
      this.dataSource.data = this.allWoundNotes;
    }
  }
  getFullPatientName(patient) {
    if (patient?.last_name && patient?.first_name) {
      return patient?.last_name + ", " + patient?.first_name;
    } else {
      return "";
    }
  }
  downloadReport() {
    // console.log(this.dataSource.data);
      this._woundService.exportWoundNotesReportAsXLSX(this.dataSource.data).subscribe((response: any) => {
        if (response instanceof HttpResponse) {
            var url = window.URL.createObjectURL(response.body);
            var a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = `Wound Notes Report`;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
            this.toastr.success("Report Downloaded", "Success");
        }
    });
  }
  getFullName(element){
    if(element._id){
      return element.first_name + " " + element.last_name + ", " + element.title;
    }else{
      return "";
    }
  }
}
