import {Component, Input, OnInit, ViewChild, OnDestroy, AfterViewInit} from '@angular/core';
import {TranscriptionDetailService} from "../transcription-detail/transcription-detail.service";
import {AudioService} from "../../services/Modules/audio.service";

@Component({
    selector: 'app-audio-chunk',
    templateUrl: './audio-chunk.template.html',
    styleUrls: ['./audio-chunk.component.css']
})
export class AudioChunkComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() audioId: string;
    displayedColumns: string[] = ['text', 'duration'];
    chunks = [
        {
            id: '1',
            name: 'ch#502',
            start_time: '0',
            end: '00:55',
            transcript: 'test test test test test test test'
        },
        {
            id: '1',
            name: 'ch#502',
            start_time: '3',
            end: '00:55',
            transcript: 'test test test test test test test'
        },
        {
            id: '1',
            name: 'ch#502',
            start_time: '7',
            end: '00:55',
            transcript: 'test test test test test test test'
        }
    ];
    constructor(private ts_detail_service: TranscriptionDetailService,
                private audioService: AudioService) {
    }
    ngOnInit() {
        this.audioService.getAudioChunks({
            audio_id: this.audioId
        }).subscribe( (data) => {
            // @ts-ignore
            this.chunks = data.data.array;
            //
        });
    }
    playChunk(chunk = '0', d, element) {
        //
        // @ts-ignore
        let container = document.getElementById(element._id);
        let childs = container.children;
        // @ts-ignore
        childs[0].style.display = 'none';
        // @ts-ignore
        childs[1].style.display = 'block';
        setTimeout(() => {
            // @ts-ignore
            childs[0].style.display = 'block';
            // @ts-ignore
            childs[1].style.display = 'none';
        }, (parseFloat(d) * 1000));
        let duration = parseFloat(d).toFixed(0).toString();
        this.ts_detail_service.chunk.next({chunk, duration});
    }
    updateChunk(chunk, event) {
        //
        let data = {
            _id: chunk._id,
            transcript: event.target.innerText
        };
        this.audioService.updateChunk(data).subscribe( (data) => {
            //
            this.ts_detail_service.chunk_update.next(true);
        });
    }
    ngAfterViewInit() {
    }

    ngOnDestroy() {
    }
}
