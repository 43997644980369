 <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="immunization">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Immunization
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.immunization}}
        </td>
    </ng-container>
    <ng-container matColumnDef="administrationDateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Date/Time
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.administrationDateTime | date: 'MM/dd/yyyy'}}
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<br />
<div *ngIf="apiData" class="flex justify-start items-center gap-2">
    <button mat-raised-button class="dn-primary-button" [disabled]="apiData.paging.page === 1" (click)="navigate(-1)">Prev</button>
    <span style="font-size: 12px;">Page {{apiData.paging.page}}</span>
    <button mat-raised-button class="dn-primary-button" [disabled]="!apiData.paging.hasMore" (click)="navigate(1)">Next</button>
</div>
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
