
<ng-container>
    <div class=" white-rounded-rect">
        <mat-radio-group [(ngModel)]="radioButtonValue" (change)="onSelectOption($event)">
            <mat-label class="font-sizes">View Note Aging Report By: </mat-label>
            <mat-radio-button value="snf_wc_nurse" style="margin-left: 3rem;"
                *ngIf="currentUser.user_type == 'snf_wc_nurse'; else elseShowProviderBlock">SNF WC Nurse</mat-radio-button>
            <ng-template #elseShowProviderBlock>
                <mat-radio-button value="provider" style="margin-left: 3rem;">Provider</mat-radio-button>
            </ng-template>
            <mat-radio-button value="facility" style="margin-left: 3rem;">Facility</mat-radio-button>
        </mat-radio-group>
    
    <div *ngIf="index == 0">
        <div class="flex justify-between">
            <h2 style="color:#000;margin-top:0;margin-bottom:0">Filter</h2>
            <div class="flex gap-2">
                <button mat-raised-button class="dn-button-primary" (click)="handleApplyFilterClick();">Apply Filter
                </button>
                <button mat-raised-button class="dn-button-secondary" (click)="resetFilter();">Reset Filter
                </button>
            </div>
        </div>
        <div class="flex justify-start items-baseline gap-2">
            <div class="flex justify-start items-baseline" style="width: 25%;">
                <mat-form-field appearance="fill" style="width: 100%;">
                    <div class="flex items-start justify-center">
                        <img *ngIf="showPccLogo" src="../../../assets/icons/pcc-icon.svg" width="20px" height="20px"
                            alt="">
                        <input type="text" placeholder="Select Facility" aria-label="facility" matInput
                            [formControl]="facilityControl" [matAutocomplete]="facilityAutoControl"
                            [(ngModel)]="facilityMOdel">
                        <mat-autocomplete #facilityAutoControl="matAutocomplete" autoActiveFirstOption>
                            <mat-option *ngFor="let facility of filteredFacilities | async" [value]="facility.title"
                            (click)="onSelectFacility(facility);">
                            <app-facility-title [facility]="facility"></app-facility-title>
                        </mat-option>
                        </mat-autocomplete>
                        <button  *ngIf="facilityMOdel" mat-button mat-icon-button matSuffix (click)="clearFacilityFilter()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </mat-form-field>
            </div>
        </div>
    </div>
    </div>
    <div style="margin-top: 2rem;">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="snf_wc_nurse" *ngIf="index == 0 && currentUser.user_type == 'snf_wc_nurse'; else elseProviderBlock">
                <th mat-header-cell *matHeaderCellDef>SNF WC Nurse</th>
                <td mat-cell *matCellDef="let element">
                    {{element.provider_ids_ref?.first_name }} {{element.provider_ids_ref?.last_name }},
                    {{element.provider_ids_ref?.title }}
                    {{element.provider_ids_ref.is_activated !== 'true' ? '(Inactive)' : ''}}
                </td>
            </ng-container>

            <ng-template #elseProviderBlock>
            <ng-container matColumnDef="provider" *ngIf="index == 0; else elseBlock">
                <th mat-header-cell *matHeaderCellDef>Provider</th>
                <td mat-cell *matCellDef="let element">
                    {{element.provider_ids_ref?.first_name }} {{element.provider_ids_ref?.last_name }},
                    {{element.provider_ids_ref?.title }}
                    {{element.provider_ids_ref.is_activated !== 'true' ? '(Inactive)' : ''}}
                </td>
            </ng-container>
        </ng-template>
            <ng-template #elseBlock>
                <ng-container matColumnDef="facility">
                    <th mat-header-cell *matHeaderCellDef>Facility</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.title }}
                    </td>
                </ng-container>
            </ng-template>
            <ng-container matColumnDef="0-6 hrs">
                <th mat-header-cell *matHeaderCellDef>0-6 hrs</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.transcriptions.zeroToSixHrs }}
                </td>
            </ng-container>
            <ng-container matColumnDef="7-13 hrs">
                <th mat-header-cell *matHeaderCellDef>7-13 hrs</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.transcriptions.sevenToThirteenHrs }}
                </td>
            </ng-container>
            <ng-container matColumnDef="14-20 hrs">
                <th mat-header-cell *matHeaderCellDef>14-20 hrs</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.transcriptions.forteenToTwentyHrs }}
                </td>
            </ng-container>
            <ng-container matColumnDef="21-27 hrs">
                <th mat-header-cell *matHeaderCellDef>21-27 hrs</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.transcriptions.twentyoneTotwentysevenHrs }}
                </td>
            </ng-container>
            <ng-container matColumnDef="28-34 hrs">
                <th mat-header-cell *matHeaderCellDef>28-34 hrs</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.transcriptions.twentyeightToThirtyfourHrs }}
                </td>
            </ng-container>
            <ng-container matColumnDef="35-41 hrs">
                <th mat-header-cell *matHeaderCellDef>35-41 hrs</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.transcriptions.thirtyfiveToFortyoneHrs }}
                </td>
            </ng-container>
            <ng-container matColumnDef="42-48 hrs">
                <th mat-header-cell *matHeaderCellDef>42-48 hrs</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.transcriptions.fortytwoTofortyEightHrs }}
                </td>
            </ng-container>
            <ng-container matColumnDef="48+ hrs">
                <th mat-header-cell *matHeaderCellDef>48+ hrs</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.transcriptions.fortyeightAboveHrs }}
                </td>
            </ng-container>
            <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef>Total</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.transcriptions.total }}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</ng-container>