<div [ngClass]="{'container': (companySide || user_dashboard) && !(nursingSide)}">
    <h3 class="dark-blue-heading flex justify-between items-center">
        <ng-container *ngIf="!user_dashboard">
            <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
                Back
            </button>
        </ng-container>

        <label [ngClass]="{'label-margin': !user_dashboard}"> {{reportName}} </label>
        <div class="flex justify-center items-end" style="color: black !important;" >
            <input matInput type="text" ngxDaterangepickerMd [(ngModel)]="filter.updationDate" class="mat-like-input"
                [ranges]="preDateRanges" [alwaysShowCalendars]="true" [maxDate]="currentDate" [linkedCalendars]="true"
                [showClearButton]="false" (datesUpdated)="changeUpdationDate($event)" class="date-range-picker"  />
        </div>
        <div class="flex gap-2">
            <mat-icon style="cursor: pointer;" (click)="exportHpOperationalReportAsXLSX();" matTooltip="Export As CSV"
                matTooltipPosition="above"> download</mat-icon>
        </div>
    </h3>
    <div class="search-block theme-form filter-bar justify-start items-center"
        style="background-color: white; border-radius: 4px; padding-top: 8px; padding-bottom: 5px; padding-left: 8px;padding-right: 8px;">
        <div class="flex flex-grow justify-start items-center gap-2">
            <mat-form-field appearance="fill" class="date-width">
                <mat-label>Select Date Type</mat-label>
                <mat-select (selectionChange)="filter.dateType = $event.value;" [value]="filter.dateType">
                    <mat-option value="createdAt">Created On</mat-option>
                    <mat-option value="updatedAt">Updated On</mat-option>
                    <mat-option value="last_assessment_date_obj">Date Of Service</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="sm:w-1/2 md:w-full">
                <mat-label>Select Facility</mat-label>
                <input  id="facilityControlInput" type="text" placeholder="Select Facility" aria-label="facility" matInput
                    [formControl]="facilityControl" [matAutocomplete]="facilityAutoControl">
                <mat-autocomplete #facilityAutoControl="matAutocomplete" autoActiveFirstOption>
                    <mat-option (onSelectionChange)="onSelectFacility($event, facility);"
                        *ngFor="let facility of filteredFacilities | async" [value]="facility.title">
                        {{facility.title}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field appearance="fill" class="state-width">
                <mat-label>Select State</mat-label>
                <mat-select [value]="filter.state" (selectionChange)="onStateChange($event.value)">
                    <mat-option value="all">All</mat-option>
                    <mat-option value="active">Active</mat-option>
                    <mat-option value="remission">Remission</mat-option>
                    <mat-option value="amputated">Amputated</mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-raised-button class="dn-button-primary btn-width" (click)="handleApplyFilter();">Apply
            </button>

            <button mat-raised-button class="dn-button-secondary btn-width" (click)="resetFilter();">Reset
            </button>
        </div>
    </div>
    <mat-card class="vertical-height">
        <div class="table-height">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                <ng-container matColumnDef="provider">
                    <th mat-header-cell *matHeaderCellDef>Provider</th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{getProviderName(element)}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="facility">
                    <th mat-header-cell *matHeaderCellDef>Facility</th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.facility_id.title}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="patient">
                    <th mat-header-cell *matHeaderCellDef>Patient</th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.patient_id?.first_name}}, {{element.patient_id?.last_name}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="wound_no">
                    <th mat-header-cell *matHeaderCellDef>Wound #</th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.woundNo}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="photo_taken_date">
                    <th mat-header-cell *matHeaderCellDef>Photo Taken Date</th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{getPhotoTakenDate(element.wound_images_time)}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="last_assement_date">
                    <th mat-header-cell *matHeaderCellDef>Wound Assessment Date</th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{getWoundAssessmentDate(element)}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="note_created_date">
                    <th mat-header-cell *matHeaderCellDef>Note Created</th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{getAnyDate(element?.recentNote?.createdAt)}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="note_signed_date">
                    <th mat-header-cell *matHeaderCellDef>Note Signed</th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{getSignedDate('note',element)}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="charge_created_date">
                    <th mat-header-cell *matHeaderCellDef>Charge Created</th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{getAnyDate(element?.recentCharge?.createdAt)}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="charge_submitted_date">
                    <th mat-header-cell *matHeaderCellDef>Charge Submitted</th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{getSignedDate('charge',element)}}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
        </div>
        <div class="flex justify-end items-center gap-1">
            <button mat-raised-button class="dn-button-primary" (click)="goToPreviousPage()"
                [disabled]="!pagination.hasPrevPage"> Prev</button>
            <span>
                {{pagination.skip + operationalReport.length === 0 ? 0 : pagination.skip + 1 }} - {{pagination.skip
                +
                operationalReport.length}} out of {{pagination.totalRecords}}
            </span>
            <button mat-raised-button class="dn-button-primary" (click)="goToNextPage()"
                [disabled]="!pagination.hasNextPage">Next</button>
        </div>
    </mat-card>
</div>