import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import * as global from '../../global';
import { TranscriptionCreateService } from '../../transcription-create/transcription-create.service';

@Component({
  selector: 'app-audio-note-list',
  templateUrl: './audio-note-list.component.html',
  styleUrls: ['./audio-note-list.component.css']
})
export class AudioNoteListComponent implements OnInit {

  @Input() audio_id: string;
  @Input() audio: any;
  provider_id:any;
  notes:any = [];
  currentUser: any;
  isIQAofProvider: any;
  isEQAofProvider: any;

  constructor(private dashboardService:DashboardService,
    private _transcriptionCreateService: TranscriptionCreateService,
    private _authService: AuthService
  ) { 
  }
  
  async ngOnInit(){
    console.log("audio inputtt : ", this.audio,this.audio_id);
    this.provider_id=this.audio.uploaded_by_id;
    //this.initNotes();
     await this.getRelation(this.provider_id);
  }

  async initNotes() {



    this.dashboardService.getTeamNotes({audio_id: this.audio_id}).subscribe((response:any) => {
      this.notes = response.data.array;
      if(global.ENVIRONMENT === "LOCAL") {
       console.log("notes",response.data.array);
      }
    }) 
  }

  async getRelation(provider){
    console.log("this.tranFilters.provider : ", provider);
    let provider_id=provider;
    this.isEQAofProvider=false;
    this.isIQAofProvider=false;

      await this._transcriptionCreateService.getRelations(provider_id).subscribe(async (res:any)=>{
        this.isEQAofProvider=false;
        this.isIQAofProvider=false;
        if(res.data[0]){
          let response=res.data?.trans[0];
          console.log("response : ", response,this._authService.currentUser._id?.toString());

          if(this._authService.currentUser._id?.toString()==response.qa_id?.toString()){
              this.isIQAofProvider=true;
              await this.applyNoteFiltersforQAs('IQA');
              return;
          }
          if(this._authService.currentUser._id?.toString()==response.qa_e_id?.toString()){
              this.isEQAofProvider=true;
              await this.applyNoteFiltersforQAs('EQA');
              return;
          }
          console.log("1st ", this.isIQAofProvider, this.isEQAofProvider);
        }
        if(this._authService.currentUser.user_type=='2'){
          await this.initNotes();
        }else{
          await this.applyNoteFiltersforQAs('null');
        }

      })
  }

  async applyNoteFiltersforQAs(type?){

      await this.dashboardService.getTeamNotesForTranscribers({audio_id: this.audio_id},type).subscribe((res:any)=>{
        this.notes = res.data.array;
      })

  }


}
