import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as global from '../../../../includes/global';
import { AuthService } from "../../../../services/auth.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { MatTableDataSource } from '@angular/material/table';
import { ChargesCatagoryService } from 'src/app/shared/popup-dialogs/charges-catagories/charges-catagories-dialog/charges-catagory.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CensusPatientListService } from 'src/app/includes/census-patient-list/census-patient-list.service';
import { PatientRoundingSheetService } from 'src/app/includes/patient-rounding-sheets/patient-rounding-sheet.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ModifierService } from 'src/app/services/modifier.service';
import { time } from 'console';
// import { SidebarDiagnosisService } from '../../sidebarDiagnonses/sidebar-diagnoses/sidebar-diagnosis.service';

@Component({
    selector: 'app-sidebar-cpts',
    templateUrl: './sidebar-cpts.component.html',
    styleUrls: ['./sidebar-cpts.component.css']
})
export class SidebarCptsComponent implements OnInit, OnChanges {
    loaderId = 'loader-02';
    global = global;
    @Input() charge:any;
    @Input() patientId;
    @Input() ownerId;
    @Input() visitDate;
    @Input() patientName;
    // @Input() params: any;
    params: any = {};
    @Output() closeView = new EventEmitter<boolean>();
    cpts = [];
    reserved_cpts = [];
    model = {
        owner_id: '',
        patient_id: '',
        rounding_sheet_id: '',
        cpt_id: [],
        cpt_modifiers: [],
        billing_note: '',
        note: '',
        status: '',
        visit_date: '',
        facility_id: '',
        measure: false
    };
    searchFilter = {
        cpt: '',
    };
    public paginationChargesAll: {
        pageNo: number,
        hasNextPage: boolean,
        hasPrevPage: boolean,
        totalCount: number,
        totalPages: number
    };
    public paginationDiagnosesAll: {
        pageNo: number,
        hasNextPage: boolean,
        hasPrevPage: boolean,
        totalCount: number,
        totalPages: number
    };
    allDiagnoses: any = [];
    allCharges: any = [];
    selectedCharges: any = []
    selectedDiagnoses: any = []
    visible = true;
    selectable = true;
    removable = true;
    displayedColumns = ['fvrt', 'code', 'checkbox']
    selectedDisplayedColumns = ['code', 'checkbox']
    dataSourceAllCPTs: any = new MatTableDataSource([]);
    dataSourceFvrtCPTs: any;
    dataSourceSelectedCPTs: any;
    searchCPT: Boolean = false;
    chargeFilter = '';
    diagnoseFilter = '';
    fvrtCPTTab = true;
    allCPTTab = false;
    categories = [];
    selectedCategory: any;
    loading: Boolean = true
    starter: Boolean = true;

    modifiers:{
        code: string,
        description: string
    }[];

    constructor(
        private service: PatientRoundingSheetService,
        private toastr: ToastrService,
        private router: Router,
        private chargesCatagoryService: ChargesCatagoryService,
        private authService: AuthService,
        private censusPatientListService: CensusPatientListService,
        private loader: NgxUiLoaderService,
        private modifierService: ModifierService,
        // private sidebarDiagnosisService:SidebarDiagnosisService
    ) {
        this.paginationChargesAll = {
            pageNo: 1,
            hasNextPage: false,
            hasPrevPage: false,
            totalCount: 0,
            totalPages: 0
        }; 
        this.censusPatientListService.castReportMeasure.subscribe(value => this.model.measure=value);
        this.modifiers = this.modifierService.getModifiers();
    }
    async ngOnChanges(changes: SimpleChanges) {
        // console.log("ngOnchages called----");
        // await this.getFvrtCPTs();
        // await this.getAllCPTs();
        const chargeChanges = changes['charge'];
        const curCharge  = JSON.stringify(chargeChanges.currentValue);
        const prevCharge = JSON.stringify(chargeChanges.previousValue);
        if(curCharge !== prevCharge && this.charge) {
            this.initializeDrafted(this.charge);
        }
    }
    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.selectedCharges, event.previousIndex, event.currentIndex); 
        // this.sidebarDiagnosisService.setSelectedICDs(this.selectedCharges)
        // this.sidebarDiagnosisService.setSele
        this.censusPatientListService.setCPTsForCharge(this.selectedCharges);
    }
    async ngOnInit() { 
        await this.getFvrtCPTs();
        await this.getAllCPTs();

        // this.censusPatientListService.castCptsForCharge.subscribe(cpts =>{
        //     if(!cpts){
        //         this.model.
        //     }
        // })  

        this.censusPatientListService.castReportMeasure.subscribe(value => {
            // console.log()
            this.model.measure = value; 
        })
    }
    async initializeDrafted(charge) {
        if (charge) {  
            charge.cpt_id.forEach(element => {
                this.addToModel(element,null, 'cpt')
            });
            
            this.model.cpt_modifiers = charge.cpt_modifiers || [];
            // initialziing fav checkboxes
            for (let k = 0; k < this.selectedCharges.length; k++) {
                for (let i = 0; i < this.cpts.length; i++) {
                    if (this.cpts[i]._id == this.selectedCharges[k]._id) {
                        this.cpts[i].selected = true;
                    } 
                } 
            }
            // initializing all checkboxes
            for (let k = 0; k < this.selectedCharges.length; k++) {
                for (let i = 0; i < this.allCharges.length; i++) {
                    if (this.allCharges[i]._id == this.selectedCharges[k]._id) {
                        this.allCharges[i].selected = true;
                    } 
                } 
            }
            // if (this.draftedData && Array.isArray(this.draftedData.cpt_modifiers)) {
                // this.model.cpt_modifiers = this.charge.cpt_modifiers;
            // }
            this.censusPatientListService.setCPTModifiersForCharge(this.model.cpt_modifiers);

            this.censusPatientListService.setReportMeasure(false);
        } else {
            this.selectedCharges = [];
            for (const cpt of this.cpts) {
                cpt.selected = false;
            }
            for (const charge of this.allCharges) {
                charge.selected = false;
            }
        }
    }
    async getFvrtCPTs() {
        let res:any = await this.service.getFavoriteCPTs().toPromise()
                // @ts-ignore
                this.cpts = res.data.array;
                // @ts-ignore 
                this.reserved_cpts = res.data.array;
                for (let k = 0; k < this.selectedCharges.length; k++) {
                    for (let i = 0; i < this.cpts.length; i++) {
                        if (this.cpts[i]._id == this.selectedCharges[k]._id) {
                            this.cpts[i].selected = true;
                        } 
                    } 
                }
                this.dataSourceFvrtCPTs = new MatTableDataSource(this.cpts);
            // });
    }
    async getAllCPTs(page = 1, search = "") { 
        let response: any = await this.service.getCPTs({ page: page, search: search }).toPromise()
            if (response.status == 200) {
                if (search.trim() == '') {
                    this.allCharges.push(...response.data.array);
                }
                else {
                    this.allCharges = response.data.array;
                }
                for (let i = 0; i < this.allCharges.length; i++) {
                    for (let j = 0; j < this.cpts.length; j++) {
                        if (this.allCharges[i]._id.toString() == this.cpts[j]._id.toString()) {
                            this.allCharges[i].fvrt = true;
                        }
                    }
                    // uncomment if want to show selected area 
                    for (let k = 0; k < this.selectedCharges.length; k++) {
                        if (this.allCharges[i]._id == this.selectedCharges[k]._id) {
                            this.allCharges[i].selected = true;
                        } 
                    }
                }
                const totalCount = response.data.totalCount;
                this.paginationChargesAll.totalCount = totalCount;
                this.paginationChargesAll.pageNo = page;
                this.initPaginationChargesAll();
                // await this.initializeDrafted(this.charge);
                this.dataSourceAllCPTs.data = this.allCharges;

            }
        // })
    }
    isFavourite(element, type, tab) {
        if (type == 'cpt') {
            if (tab == 'fvrts') {
                return true
            }
        }
    }

    addFavouriteCharges(charge) {
        this.loader.startLoader('loader-02');
        let data = {
            ids: charge._id,
            isRemove: false,
            filter: 'cpt_id'
        };
        let cpts = this.service.setFavoriteCPTs(data).subscribe((response: any) => {
            if (response.status == 200) {
                this.cpts.push(charge)
                this.allCharges.map(char => {
                    if (char._id == charge._id) {
                        char.fvrt = true;
                        this.dataSourceAllCPTs.data = this.allCharges
                    }
                })
                this.dataSourceFvrtCPTs = new MatTableDataSource(this.cpts);
                this.loader.stopLoader('loader-02');
            }
        })
    }

    removeFavouriteCharges(charge) {
        let index = -1;
        for (let i = 0; i < this.cpts.length; i++) {
            if (charge._id == this.cpts[i]._id) {
                index = i;
            }
        }
        if (index > -1) {
            this.cpts.splice(index, 1)
            this.dataSourceFvrtCPTs = new MatTableDataSource(this.cpts);
        }
        this.allCharges.map(char => {
            if (char._id == charge._id) {
                char.fvrt = false;
                this.dataSourceAllCPTs.data = this.allCharges
            }
        })

        let data = {
            ids: charge._id,
            isRemove: true,
            filter: 'cpt_id'
        };
        this.service.setFavoriteCPTs(data).subscribe((response: any) => {
            if (response.status == 200) {
                // this.toastr.success('Charge removed from favorites', 'Success');
            }
        })
    }
    onListDropfvrtCPTs(event: CdkDragDrop<any[]>) {
        let filter = 'cpt_id';
        moveItemInArray(this.cpts, event.previousIndex, event.currentIndex);
        this.dataSourceFvrtCPTs = new MatTableDataSource(this.cpts);
        this.service.rearrangeFavoriteCharges(this.cpts.map(c => c._id), filter)
            .subscribe((response: any) => {
            })
    }
    addToModel(element, event, type) {
        this.clearSearchField()
        if (type == 'cpt') {
            if (element.selected) {
                let index = this.model.cpt_id.indexOf(element._id);
                if (index > -1) {
                    element.selected = false;
                    this.selectedCharges.splice(index, 1)
                    this.model.cpt_id.splice(index, 1)
                    this.allCharges.map(d => {
                        if (d._id == element._id) {
                            d.selected = false;
                        }
                    })
                    this.cpts.map(d => {
                        if (d._id == element._id) {
                            d.selected = false;
                        }
                    })
                }
                else {
                    element.selected = true;
                    this.selectedCharges.push(element)
                    this.model.cpt_id.push(element._id);
                    this.allCharges.map(d => {
                        if (d._id == element._id) {
                            d.selected = true;
                        }
                    })
                    this.cpts.map(d => {
                        if (d._id == element._id) {
                            d.selected = true;
                        }
                    })
                }
            }
            else if (!element.selected) {
                element.selected = true;
                this.selectedCharges.push(element)
                this.model.cpt_id.push(element._id);
                this.allCharges.map(d => {
                    if (d._id == element._id) {
                        d.selected = true;
                    }
                })
                this.cpts.map(d => {
                    if (d._id == element._id) {
                        d.selected = true;
                    }
                })
            }
            this.dataSourceFvrtCPTs = new MatTableDataSource(this.cpts);
            this.dataSourceSelectedCPTs = new MatTableDataSource(this.selectedCharges);
            this.censusPatientListService.setCPTsForCharge(this.model.cpt_id);
            // this.censusPatientListService.setSelected
            this.censusPatientListService.setCPTModifiersForCharge(this.model.cpt_modifiers);
            this.censusPatientListService.setReportMeasure(false);
        }
    }
    changeReportMeasure(event){
        this.censusPatientListService.setReportMeasure(event);
    }
    removeCharge(charge) {
        let index = this.model.cpt_id.indexOf(charge._id);
        if (index > -1) {
            this.selectedCharges.splice(index, 1)
            this.model.cpt_id.splice(index, 1)
        }
        this.dataSourceSelectedCPTs = new MatTableDataSource(this.selectedCharges);
        this.allCharges.map(a => {
            if (a._id == charge._id) {
                a.selected = false;
            }
        })
        this.dataSourceAllCPTs.data = this.allCharges;
        this.cpts.map(a => {
            if(a._id == charge._id){
                a.selected = false;
            }
        })
        this.dataSourceFvrtCPTs = new MatTableDataSource(this.cpts);
    }
    searchCharge(value) {
        this.searchFilter.cpt = value?.trim()?.toLowerCase();
        if ((!value || value == '') && !this.starter) {
            return;
        }
        // else {
        if (this.allCPTTab) {
            this.getAllCPTs(1, value)
        }
        else if (this.fvrtCPTTab) {
            this.applyFilterCPT(this.searchFilter.cpt)
        }
        // }
    }
    applyFilterCPT(value) {
        if (value) {
            let filteredData = [];
            this.reserved_cpts.map(a => {
                if (a.code.trim().toLowerCase().includes(value) || a.description.trim().toLowerCase().includes(value)) {
                    filteredData.push(a)
                }
            })
            this.dataSourceFvrtCPTs = new MatTableDataSource(filteredData)
        }
        else {
            this.dataSourceFvrtCPTs = new MatTableDataSource(this.reserved_cpts)
        }
    }
    clearChargeFilter() {
        this.chargeFilter = "";
        this.allCharges = []
        this.getAllCPTs(1, '')
    }

    handlePrevClick(type) {
        if (type == 'chargesAll') {
            this.getAllCPTs(this.paginationChargesAll.pageNo - 1, this.searchFilter.cpt);
        }
        else if (type == 'diagnoseAll') {
            // this.getAllICDs(this.paginationDiagnosesAll.pageNo - 1, this.searchFilter.icd);
        }
    }
    handleNextClick(type) {
        if (type == 'chargesAll') {
            this.getAllCPTs(this.paginationChargesAll.pageNo + 1, this.searchFilter.cpt);
        }
        else if (type == 'diagnoseAll') {
            // this.getAllICDs(this.paginationDiagnosesAll.pageNo + 1, this.searchFilter.icd);
        }
    }

    getCountofCodes(type) {
        if (type == 'chargesAll') {
            // return ((this.paginationChargesAll.pageNo * 10) - 9) + ' - ' + (((this.paginationChargesAll.pageNo * 10) - 10) + Number(this.allCharges.length)) + " out of " + (this.paginationChargesAll.totalCount);
            return '1 - ' + (Number(this.allCharges.length)) + " out of " + (this.paginationChargesAll.totalCount);
        }
        else if (type == 'diagnoseAll') {
            // return ((this.paginationDiagnosesAll.pageNo * 10) - 9) + ' - ' + (((this.paginationDiagnosesAll.pageNo * 10) - 10) + Number(this.allDiagnoses.length)) + " out of " + (this.paginationDiagnosesAll.totalCount);
        }
    }
    initPaginationChargesAll() {
        this.paginationChargesAll.totalPages = Math.ceil(this.paginationChargesAll.totalCount / 10);
        if (this.allCharges) {
            this.paginationChargesAll.hasNextPage = this.allCharges.length > 0 && this.paginationChargesAll.pageNo < this.paginationChargesAll.totalPages;
        }
        this.paginationChargesAll.hasPrevPage = this.paginationChargesAll.pageNo > 1;
    }
    onTabClickCharges(event) {
        // this.chargeFilter = null;
        this.starter = false;
        if (event.tab.textLabel.trim().toLowerCase() == 'all') {
            this.searchCPT = true;
            this.allCPTTab = true;
            this.fvrtCPTTab = false;
        }
        else {
            this.searchCPT = false;
            this.fvrtCPTTab = true;
            this.allCPTTab = false;
        }
        this.searchCharge(this.chargeFilter)
    }

    clearSearchField() {
        this.chargeFilter = '';
    }

    handleModifierChange(cpt, modifier) { 
        const cpt_modifier = this.model.cpt_modifiers.find(cpt_modifier => cpt_modifier.cpt === cpt._id);
        if(cpt_modifier) {
            cpt_modifier.modifier = modifier
        } else {
            this.model.cpt_modifiers.push({
                cpt: cpt._id,
                modifier
            })
        }
        this.censusPatientListService.setCPTModifiersForCharge(this.model.cpt_modifiers);
    }

    getCptModifier(cpt) {
        const cpt_modifier = this.model.cpt_modifiers?.find(cpt_modifier => cpt_modifier.cpt === cpt._id);
        if(cpt_modifier) {
            return cpt_modifier.modifier
        } 
    }
}
