import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HttpClient } from "@angular/common/http";
import * as global from '../global';
import {API_URL} from "../../../environments/api-url";

@Injectable({
  providedIn: 'root'
})
export class TemplateAssociationService {

  currentUser:any;
  userInfo:any = {};
  constructor(
    private authService: AuthService,
    private httpclient: HttpClient,
  ) { 
    this.currentUser = this.authService.currentUser;
    this.userInfo.id = this.currentUser._id;
    this.userInfo.usertype = this.currentUser.user_type;
    this.userInfo.auth_key = this.currentUser.auth_key;
  }

  // getTempAssociationData
  getTempAssociationData(company_id = null) {
    const detail = {
      ...this.userInfo,
      company_id, 
    }; 
    return this.httpclient.post(global.url + API_URL.TEMP_ASSOCIATION.getTempAssociationData, {details: detail}).pipe();
  }

}
