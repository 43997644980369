import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { PCCService } from 'src/app/services/pcc.service';
import { MergePatientsService } from '../../merge-patients/merge-patients.service';
import * as global from '../../global';
import { ToastrService } from 'ngx-toastr';
import { Patient } from 'src/app/classes/patient';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators'
import { isArray, isObject } from 'lodash';


@Component({
  selector: 'app-merge-patients-data',
  templateUrl: './merge-patients-data.component.html',
  styleUrls: ['./merge-patients-data.component.css']
})
export class MergePatientsDataComponent implements OnInit {

  dob: string;
  name_P1: String = '';
  name_P2: String = '';
  selections={
    notes:true,
    charges:true,
    wounds:true,
    census:true,
    ADT:true,
    documents:true,
    allergies:true,
    comments:true,
    others:true,
    mrn:true,
    is_active:false
  }
  patient_one: any;
  patient_two: any;

  patient_one_after: any;
  patient_two_after: any;

  filter1 = {
    first_name: '',
    last_name: '',
    // date_of_birth:''
  };
  filter2 = {
    first_name: '',
    last_name: '',
    // date_of_birth:''
  }
  patientsSearchLoaderId = 'pcc-patients-loader3';
  patientsSearchLoaderId2 = 'pcc-patients-loader4';
  filterSearch = "";
  patientsByProviderControl = new FormControl();
  patientsByProviderControltwo = new FormControl();
  patientsByProviderone = [];
  patientsByProvidertwo = [];

  applySearchFilter: boolean;

  revertStatus: boolean;
  revertStatusone: boolean;
  mergeStatusone: boolean;
  mergeStatustwo: boolean;
  choice: number;
  first_id:any;
  second_id:any;

  constructor(private mergePatientService: MergePatientsService,
    private _authService: AuthService,
    private _pccService: PCCService,
    private loader: NgxUiLoaderService,

    private commonService: CommonService,
    private toastr: ToastrService) {
    this.filter1.first_name = '';
    this.filter1.last_name = '';

    this.filter2.first_name = '';
    this.filter2.last_name = '';
    this.choice=0;
    // this.filter1.date_of_birth='';
  }

  ngOnInit(): void {
    this.revertStatusone = true;
    this.revertStatus = true;
    this.mergeStatusone=false;
    this.mergeStatustwo=false;


  }


  onDateChange(event) {
    this.dob = '';
    // this.dob = new Date(event.value).toLocaleDateString()
    this.dob = event.value;
    //this.filter1.date_of_birth = moment(event.value).format(global.moment_date_format2);
    //this.filter2.date_of_birth = moment(event.value).format(global.moment_date_format2);
  }

  displayPatientFn(patient): string {
    return patient && patient.first_name ? patient.first_name + " " + patient.last_name : '';
  }


  async fetchOne(){
    this.toastr.info('Fetching Patient', 'Wait....');
    await this.mergePatientService.searchThePatientById(this.first_id).subscribe((res: any) => {
      if(res.data.length==0){
        this.toastr.warning(' 0 Patient found.', 'Warning'); 
        this.revertStatusone = true;
        this.revertStatus = true;
        this.mergeStatusone=false;
        this.mergeStatustwo=false;
        return;
      }else{
        this.patient_one = res.data;
        this.patient_one_after = null;
        ////console.log("name :", this.patient_one);
        this.revertStatusone = true;
        this.revertStatus = true;
        this.mergeStatusone=false;
        this.mergeStatustwo=false;
        this.patientsByProviderone = res.data;
        this.toastr.success('Patient one has been fetched.', 'Success');
      }
      
    });
  }

  getFacility(patient){
    if(Array.isArray(patient?.facility_id_ref)&&patient?.facility_id_ref[0]){
      return patient?.facility_id_ref[0]?.title;
    }else if(Array.isArray(patient?.facility)&&patient?.facility[0]){
      return patient?.facility[0]?.title;}else{return '';}
  }
  async fetchTwo(){
    this.toastr.info('Fetching Patient', 'Wait....');
    await this.mergePatientService.searchThePatientById(this.second_id).subscribe((res: any) => {
      if(res.data.length==0){
        this.toastr.warning(' 0 Patient found.', 'Warning'); 
        this.revertStatusone = true;
        this.revertStatus = true;
        this.mergeStatusone=false;
        this.mergeStatustwo=false;
        return;
      }else{
        this.patient_two = res.data;
        this.patient_two_after = null;
        ////console.log("name :", this.patient_one);
        this.revertStatusone = true;
        this.revertStatus = true;
        this.mergeStatusone=false;
        this.mergeStatustwo=false;
        this.patientsByProviderone = res.data;
        this.toastr.success('Patient one has been fetched.', 'Success');
      }
      
    });
  }


  async searchThePatientbyId(patient_id,whichOne) {


      await this.mergePatientService.searchThePatientById(patient_id).subscribe((res: any) => {
        if(whichOne=='1'){
          this.patient_one_after = res.data;
        }else if (whichOne=='2'){
          this.patient_two_after = res.data;
        }
      })



  }

  async searchThePatientOnebyId(patient_id) {

    await this.mergePatientService.searchThePatientById(patient_id).subscribe((res: any) => {
        this.patient_one = res.data;
      
    });
  }

  async searchThePatientTwobyId(patient_id) {

    await this.mergePatientService.searchThePatientById(patient_id).subscribe((res: any) => {

        this.patient_two = res.data;
    });
  }

  validInput() {
    if ((this.filter1.first_name != '' && this.filter1.first_name) && (this.filter1.last_name != '' && this.filter1.last_name) && (this.filter2.first_name != '' && this.filter2.first_name) && (this.filter2.last_name != '' && this.filter2.last_name)) {
      if (this.filter1.first_name === this.filter2.first_name && this.filter1.last_name === this.filter2.last_name) {
        return false;
      } else
        return true;
    }
    else
      return false;
  }
  
  clearFilterOne(){
    this.revertStatus = true;
    this.mergeStatusone=false;
    this.mergeStatustwo=false;


    this.filter1.first_name = '';
    this.filter1.last_name = '';
    this.patient_one=null;
    this.patient_one_after=null;

    this.patientsByProviderone=[];
    this.choice=0;
  }

  clearFilterTwo(){

    this.revertStatus = true;
    this.mergeStatusone=false;
    this.mergeStatustwo=false;


    this.filter2.first_name = '';
    this.filter2.last_name = '';
    this.patient_two=null;
    this.patient_two_after=null;

    this.patientsByProvidertwo=[]
    this.choice=0;
  }

  async revertMerge(){
    //console.log("choicee", this.choice);
    if(this.choice==1){
      await this.revertChanges_one_to_two();
    }else if(this.choice==2){
      await this.revertChanges_two_to_one();
    }
  }

  async patient_one_to_patient_two() {
      let query;
      query = {
        src_patient_id: this.patient_one._id,
        dst_patient_id: this.patient_two._id,
        selection:this.selections
      }
      if (this.patient_one.pcc_patientId) {
        query.is_srcP_pcc = true;
      }
      if (this.patient_two.pcc_patientId) {
        query.is_dstP_pcc = true;
      }
      if(this.patient_one._id==this.patient_two._id){
        this.toastr.error('Same Patients can not be merged.', 'ERROR');
        this.clearFilterTwo();
        this.clearFilterOne();
        return;
      }

      this.mergeStatusone=true;
      this.mergeStatustwo=true;
      //console.log("queryyy oo: 11", query);
      this.loader.startLoader(this.patientsSearchLoaderId);
      this.toastr.info("Please Wait! Merging in progress..", 'Wait');

      await this.mergePatientService.getMergingAudit(query).subscribe(async (res: any) => {
        let result = res.data;
        this.choice=1;
        //console.log("audittt", result);
        if (result) {
      this.loader.stopLoader(this.patientsSearchLoaderId);

          this.toastr.error('Patients are already Merged.', 'ERROR');
          this.revertStatusone = false;
          this.revertStatus = false;

        }
        else {

          await this.mergePatientService.mergeThePatientData(query).subscribe(async (res: any) => {
            let result2 = res.data;
            
            this.toastr.success("Merging Done", 'Success');
            this.revertStatusone = false;
            this.revertStatus = false;
            
            await this.searchThePatientbyId(this.patient_one._id,'1');
            await this.searchThePatientbyId(this.patient_two._id,'2');
            
            this.loader.stopLoader(this.patientsSearchLoaderId);

          })
        }
      })

  }

  async patient_two_to_patient_one() {
      let query;
      query = {
        src_patient_id: this.patient_two._id,
        dst_patient_id: this.patient_one._id,
        selection:this.selections

      }
      if (this.patient_two.pcc_patientId) {
        query.is_srcP_pcc = true;
      }
      if (this.patient_one.pcc_patientId) {
        query.is_dstP_pcc = true;
      }
      if(this.patient_one._id==this.patient_two._id){
        this.toastr.error('Same Patients can not be merged.', 'ERROR');
        this.clearFilterTwo();
        this.clearFilterOne();
        return;
      }
      //console.log("queryyy oo 22:", query);
      this.mergeStatusone=true;
      this.mergeStatustwo=true;
      this.loader.startLoader(this.patientsSearchLoaderId);
      this.toastr.info("Please Wait! Merging in progress..", 'Wait');
      
      await this.mergePatientService.getMergingAudit(query).subscribe(async (res: any) => {
        let result = res.data;
        //console.log("audittt", result);
        this.choice=2;

        if (result) {
          this.loader.stopLoader(this.patientsSearchLoaderId);

          this.toastr.error('Patients are already Merged.', 'ERROR');
          this.revertStatusone = true;
          this.revertStatus = false;
        }
        else {
          await this.mergePatientService.mergeThePatientData(query).subscribe(async (res: any) => {
            let result2 = res.data;

            this.toastr.success("Merging Done", 'Success');
            this.revertStatusone = true;
            this.revertStatus = false;

            await this.searchThePatientbyId(this.patient_one._id,'1');
            await this.searchThePatientbyId(this.patient_two._id,'2');

            this.loader.stopLoader(this.patientsSearchLoaderId);

            ////console.log("resresults : 111", result2);
          })

        }
      })

  }

  async revertChanges_two_to_one() {
    if (true) {
      let query;
      query = {
        src_patient_id: this.patient_two._id,
        dst_patient_id: this.patient_one._id,
      }
      this.revertStatusone = true;
      this.revertStatus = true;
      ////console.log("queryyy oo 11:", query);
      this.loader.startLoader(this.patientsSearchLoaderId);
      this.toastr.info("Please Wait! Reverting in progress..", 'Wait');

      await this.mergePatientService.getMergingAudit(query).subscribe(async (res: any) => {
        let result = res.data;
        if (result) {
          await this.mergePatientService.revertThePatient(query).subscribe(async (res: any) => {
            let result = res.data;
            this.toastr.success('Merge is Reverted.', 'Success');
            ////console.log("resresults : 122", result);
            this.choice=0;
            this.mergeStatusone=false;
            this.mergeStatustwo=false;
            await this.searchThePatientbyId(this.patient_one._id,'1');
            await this.searchThePatientbyId(this.patient_two._id,'2');
            this.loader.stopLoader(this.patientsSearchLoaderId);

          })
        }
        else {
          this.loader.stopLoader(this.patientsSearchLoaderId);

          this.toastr.error('Patients are Not Merged.', 'ERROR');
          this.choice=0;
          this.mergeStatusone=false;
          this.mergeStatustwo=false;
        }
      })



    } else {
      //this.toastr.error('Kindly type both First and Last Name for Both Patients', 'ERROR');
    }
  }

  async revertChanges_one_to_two() {
    if (true) {
      let query;
      query = {
        src_patient_id: this.patient_one._id,
        dst_patient_id: this.patient_two._id,
      }
      this.revertStatusone = true;
      this.revertStatus = true;
      ////console.log("queryyy oo 22:", query);
      this.loader.startLoader(this.patientsSearchLoaderId);
      this.toastr.info("Please Wait! Reverting in progress..", 'Wait');

      await this.mergePatientService.getMergingAudit(query).subscribe(async (res: any) => {
        let result = res.data;
        if (result) {
          await this.mergePatientService.revertThePatient(query).subscribe(async (res: any) => {
            let result = res.data;
            ////console.log("resresults : 122", result);
            this.toastr.success('Merge is Reverted.', 'Success');
            ////console.log("resresults : 122", result);
            this.choice=0;
            this.mergeStatusone=false;
            this.mergeStatustwo=false;
            await this.searchThePatientbyId(this.patient_one._id,'1');
            await this.searchThePatientbyId(this.patient_two._id,'2');
            this.loader.stopLoader(this.patientsSearchLoaderId);

          })
        }
        else {
          this.loader.stopLoader(this.patientsSearchLoaderId);

          this.toastr.error('Patients are Not Merged.', 'ERROR');
          this.choice=0;
          this.mergeStatusone=false;
          this.mergeStatustwo=false;
        }
      })

    } else {
      //this.toastr.error('Kindly type both First and Last Name for Both Patients', 'ERROR');
    }
  }

}
