import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-amputation-report',
  templateUrl: './amputation-report.component.html',
  styleUrls: ['./amputation-report.component.css']
})
export class AmputationReportComponent implements OnInit {
  @Input() amputationReport = [];
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = [
    'organization', 'faciltiy', 'first_name', 'last_name'];
  constructor() { }

  ngOnInit(): void {
    console.log("amputationReport", this.amputationReport);
    this.dataSource.data = this.amputationReport;
  }

}
