<ng-container *ngIf="dataSource">
    <div class="filter-form white-rounded-rect">
        <div class="flex justify-between">
            <h2 style="color:#000;margin-top:0;margin-bottom:0">Filter</h2>

            <div class="filter-controls flex gap-2">
                <button mat-raised-button class="dn-button-primary"
                    (click)="filter.active=true; isVisibleTable=false; handleApplyFilterClick();">Apply Filter
                </button>
                <button mat-raised-button class="dn-button-secondary"
                    (click)="this.isVisibleTable = false; resetFilter()">Reset Filter
                </button>
            </div>

        </div>
        <div class="flex flex-wrap justify-start items-baseline gap-2">

            <div class="flex justify-start items-baseline input-with-cross">
                <mat-label style="margin-right:20px;color:#999">Date of Service</mat-label>

                <input *ngIf="serviceDateFilter" type="text" ngxDaterangepickerMd [(ngModel)]="filter.date_of_service"
                    (datesUpdated)="changeServiceDate($event)" class="mat-like-input" [ranges]="preDateRanges"
                    [alwaysShowCalendars]="true" [maxDate]="this.c_date" [linkedCalendars]="true"
                    [showClearButton]="true" />
                <button mat-icon-button
                    *ngIf="filter.date_of_service && (filter.date_of_service?.startDate || filter.date_of_service?.endDate)"
                    (click)="filter.date_of_service = null">
                    <mat-icon>close</mat-icon>
                </button>
            </div>

            <div class="flex justify-start items-baseline input-with-cross">
                <mat-label style="margin-right:20px;color:#999">Date of Submission</mat-label>

                <input *ngIf="submissionDateFilter" type="text" ngxDaterangepickerMd
                    [(ngModel)]="filter.date_of_submission" (datesUpdated)="changeSubmissionDate($event)"
                    class="mat-like-input" [ranges]="preDateRanges" [alwaysShowCalendars]="true" [maxDate]="this.c_date"
                    [linkedCalendars]="true" [showClearButton]="true" />
                <button mat-icon-button
                    *ngIf="filter.date_of_submission && (filter.date_of_submission.startDate || filter.date_of_submission.endDate)"
                    (click)="filter.date_of_submission = null">
                    <mat-icon>close</mat-icon>
                </button>
            </div>

            <div class="flex justify-start items-baseline">
                <mat-form-field appearance="fill">

                    <input type="text" [(ngModel)]="provideModel" placeholder="Select Provider" aria-label="provider"
                        matInput [formControl]="providerControl" [matAutocomplete]="providerAutoControl">
                        <mat-autocomplete #providerAutoControl="matAutocomplete" autoActiveFirstOption>
                        <mat-option (click)="onSelectProvider()">--none--</mat-option>
                        <mat-option *ngFor="let provider of filteredProvider | async" [value]="provider.name"
                            (click)="onSelectProvider(provider);">
                            {{provider.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <button style="height:10px;" *ngIf="provideModel" mat-button mat-icon-button matSuffix (click)="clearProviderFilter()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>

            <div class="flex justify-start items-baseline">
                <mat-form-field appearance="fill">

                    <input type="text" placeholder="Select Facility" aria-label="facility" matInput
                        [formControl]="facilityControl" [matAutocomplete]="facilityAutoControl"
                        [(ngModel)]="facilityMOdel">
                    <mat-autocomplete #facilityAutoControl="matAutocomplete" autoActiveFirstOption>
                        <mat-option (click)="onSelectFacility()">--none--</mat-option>
                        <mat-option *ngFor="let facility of filteredFacilities | async" [value]="facility.title"
                            (click)="onSelectFacility(facility);">
                            {{facility.title}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="br-spacing"></div>
    <div class="filter-data white-rounded-rect flex justify-center gap-5">
        <div class="flex flex-grow justify-evenly items-start gap-5">
            <div class="filter-data-element flex flex-col gap-1"
                *ngFor="let filterElement of filterData()">
                <strong class="flex-header" style="font-weight: 500;">{{filterElement.label}}</strong>
                <span class="flex-date-service">{{filterElement.value}}</span>
            </div>
        </div>

        <button mat-raised-button class="dn-button-primary" (click)="isVisibleTable=true"
            *ngIf="!isVisibleTable && sortedData && sortedData.length">View
        </button>
        <button mat-raised-button (click)="isVisibleTable=false" class="dn-button-secondary" *ngIf="isVisibleTable && sortedData && sortedData.length">
            Hide
        </button>
    </div>
    <div class="br-spacing"></div>
</ng-container>

<div class="flex justify-center items-center" *ngIf="!dataSource">
    <mat-progress-spinner diameter="50" mode="indeterminate">
    </mat-progress-spinner>
</div>

<div *ngIf="isDictationsVisible()" class="flex flex-col gap-2">
    <button mat-raised-button class="dn-button-primary" style=" align-self: start;" (click)="setDictationsToShow(null)">
        Go Back
    </button>

    <mat-table *ngIf="dictationsToShow" [dataSource]="dictationsToShow">
        <ng-container matColumnDef="audio_title" >
            <mat-header-cell [style.flex]="'0 0 23%'" style="font-weight: 500 !important;font-size: 14px; color: black"
                *matHeaderCellDef>Title
            </mat-header-cell>
            <mat-cell style="cursor: pointer;" (click)="selectedAudio($event, audio)" [style.flex]="'0 0 23%'" *matCellDef="let audio">{{audio.dic_title |
                slice:0:25}}{{audio.dic_title.length > 25 ? ' ...' : ''}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="uploader_name" style="cursor: pointer;" (click)="selectedAudio($event, audio)">
            <mat-header-cell style="font-weight: 500 !important;font-size: 14px; color: black" *matHeaderCellDef>
                Uploaded By
            </mat-header-cell>
            <mat-cell *matCellDef="let audio" style="cursor: pointer;" (click)="selectedAudio($event, audio)">
                <span *ngIf="audio.uploaded_by_id_ref">
                    {{audio.uploaded_by_id_ref?.first_name + " " + audio.uploaded_by_id_ref?.last_name}}
                </span>
            </mat-cell>
        </ng-container>


        <ng-container matColumnDef="date_time">
            <mat-header-cell style="font-weight: 500 !important;font-size: 14px; color: black" *matHeaderCellDef>Date of
                Service
            </mat-header-cell>
            <mat-cell *matCellDef="let audio" (click)="selectedAudio($event, audio)" style="cursor: pointer;">{{getServiceDate(audio)}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="date_of_submission">
            <mat-header-cell style="font-weight: 500 !important;font-size: 14px; color: black; cursor: pointer;" *matHeaderCellDef>Date of
                Submission
            </mat-header-cell>
            <mat-cell *matCellDef="let audio" style="cursor: pointer;" (click)="selectedAudio($event, audio)">{{(audio.date_of_submission ? audio.date_of_submission : audio.date_time)
                | date: global.date_format}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="progress_status">
            <mat-header-cell style="font-weight: 500 !important;font-size: 14px; color: black" *matHeaderCellDef>
                Progress
            </mat-header-cell>
            <mat-cell *matCellDef="let audio" style="cursor: pointer;" (click)="selectedAudio($event, audio)">
                <button class="theme-btn status"
                    [class.completed]="getdictStatus(audio)==='COMPLETED'">{{getdictStatus(audio)}}</button>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="facility_title">
            <mat-header-cell style="font-weight: 500 !important;font-size: 14px; color: black;" *matHeaderCellDef>
                Facility
            </mat-header-cell>
            <mat-cell *matCellDef="let audio" style="cursor: pointer;" (click)="selectedAudio($event, audio)">
                <span *ngIf="audio.facility_id_ref">
                    <app-facility-title [facility]="audio.facility_id_ref"></app-facility-title>

                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="patient_name">
            <mat-header-cell style="font-weight: 500 !important;font-size: 14px; color: black" *matHeaderCellDef>
                Patient
            </mat-header-cell>
            <mat-cell *matCellDef="let audio" style="cursor: pointer;" (click)="selectedAudio($event, audio)">
                <span *ngIf="audio.patient_id_ref; else elseblock">
                    {{audio.patient_id_ref?.last_name + " " + audio.patient_id_ref?.first_name}}
                </span>
                <ng-template #elseblock>
                    <span>
                        -
                    </span>
                </ng-template>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="audio_length">
            <mat-header-cell style="font-weight: 500 !important;font-size: 14px; color: black;" *matHeaderCellDef>
                Length
            </mat-header-cell>
            <mat-cell *matCellDef="let audio" style="cursor: pointer;" (click)="selectedAudio($event, audio)">{{audio.dic_length}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="notes_count">
            <mat-header-cell style="font-weight: 500 !important;font-size: 14px; color: black;" *matHeaderCellDef>Notes
                Count
            </mat-header-cell>
            <mat-cell *matCellDef="let audio" style="cursor: pointer;" (click)="selectedAudio($event, audio)">{{audio.notes.count}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="operations">
            <mat-header-cell style="font-weight: 500 !important;font-size: 14px; color: black;" *matHeaderCellDef>
                Operations
            </mat-header-cell>
            <mat-cell *matCellDef="let audio" style="cursor: pointer;" (click)="selectedAudio($event, audio)">
                <i class="icon icon-view" matTooltip="Details" matTooltipPosition="above"
                    (click)="selectedAudio($event, audio)">
                </i>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    </mat-table>
</div>

<div *ngIf="isVisibleTable" class="flex flex-col gap-2" [class.hidden]="isDictationsVisible()">
    <div class="dictations-group-dateofservice" *ngFor="let dateGroup of dictationsGroup ">
        <div class="flex flex-col gap-2">
            <div class="group-index-titles">
                <span>{{getDate(dateGroup._id)}}</span>
            </div>
            <div class="card">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="left" style="width:20%">Uploaded By</th>
                            <th class="left" style="width:35%">Facility</th>
                            <th class="left" style="width:10%">Count</th>
                            <th class="left" style="width:15%">Operations</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let gDictations of dateGroup.groupedData">
                            <td>
                                {{gDictations.audiodata?.user?.first_name}} {{gDictations.audiodata?.user?.last_name}}
                            </td>
                            <td>
                                {{gDictations.audiodata?.facility?.title}}
                            </td>
                            <td>
                                {{gDictations.audiodata?.total}}
                            </td>
                            <td class="left">
                                <div class="flex gap-2">
                                    <i class="icon icon-view" matTooltip="Details" matTooltipPosition="above"
                                        (click)="setDictationsToShow(gDictations.audiodata)">
                                    </i>
                                </div>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>