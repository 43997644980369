import { Component, OnInit, Input } from '@angular/core';
import { PCCService } from 'src/app/services/pcc.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatTableDataSource } from '@angular/material/table';
import { IActivitiesOfDailyLivingScoreResponse } from 'src/app/services/pccAPIInterfaces';

@Component({
  selector: 'app-activities-of-daily-living-score',
  templateUrl: './activities-of-daily-living-score.component.html',
  styleUrls: ['./activities-of-daily-living-score.component.css']
})
export class ActivitiesOfDailyLivingScoreComponent implements OnInit {

  @Input('patient') patient;
  // @Input('pccOrgUuid') pccOrgUuid;
  constructor(private _pccService: PCCService,
    private toastr:ToastrService,
    private loader: NgxUiLoaderService) { }
  pccOrgUuid:string;
  apiData: IActivitiesOfDailyLivingScoreResponse;
  page = 1;
  dataSource;
  displayedColumns = [
    "adlScore",
    "adlScoreDate",
    "modifiedBy",
    "modifiedDateTime",
  ];

  loaderId ='medications-loader';
  inprogress = false;



  ngOnInit(): void {
    this.pccOrgUuid = this.patient.facility_id_ref.pcc_orgUuid;
    this.loadData(this.page);
  }

  loadData(page) {
    this.loader.startLoader(this.loaderId);
    let authMode;
    if(!this.patient.facility_id_ref.pcc_2_legged_authentication) {
      authMode = 'APP'
    }
    this._pccService.getActivitiesOfDailyLivingScore({
        pccOrgUuid: this.pccOrgUuid,
        pccPatientId: this.patient.pcc_patientId,
        pccPage: String(page),
        authMode
      }).subscribe((response: any) => {

      if (response.status === 200) {
        this.apiData = response.data;
        this.dataSource = new MatTableDataSource(this.apiData.data);
        this.page = page;
      } else {
        this.toastr.error('Something went wrong while fetching ActivitiesOfDailyLiving data');
      }
    }, (error) => {
      this.toastr.error('Something went wrong while fetching ActivitiesOfDailyLiving data');
    }, () => {
      this.loader.stopLoader(this.loaderId);
    })
  }
  navigate(prevNext) {
    const newPageNo = this.page + prevNext;
    this.loadData(newPageNo);
  }

}
