import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import {Location} from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyService } from 'src/app/services/Modules/company.service';

@Component({
  selector: 'app-dialog-census-column-admin',
  templateUrl: './dialog-census-column-admin.component.html',
  styleUrls: ['./dialog-census-column-admin.component.css']
})
export class DialogCensusColumnAdminComponent implements OnInit {
  company_id: any;
  census_columns: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogCensusColumnAdminComponent>,
    private authService: AuthService,
    private companyService:CompanyService,
    private toastr: ToastrService,
  ) { 
    this.currentUser = this.authService.currentUser;
    this.company_id=this.data?.company_id;

    console.log("data company id : ", data, this.company_id );
  }

  currentUser: any;
  census: Boolean = false;

  allcolumns=['operations','name','note','comments_count','admission_date','date_of_birth','discharge','location','payer','mrn','last_seen','pcc_patient_diagnosis','pcp'];

  async ngOnInit(): Promise<void> {
    this.census_columns=['operations','name','note'];

    if(this.company_id){

      this.companyService.getCensusColumnSetting(this.company_id).subscribe((res:any)=>{
        
        if(res.data?.length){
          this.census_columns=[...res.data, ...this.census_columns];    
        }else{
          this.census_columns=['operations','name','note'];
        }
        this.census_columns=Array.from(new Set(this.census_columns));
        console.log("responseeee  : : compnay census col settings : ",this.census_columns,  res.data);

      })
    }
  }


  handlecensus_columnsCheckboxes($event) {

    if($event.source.checked) {

      let value = $event.source.value.toString();
      let index=this.census_columns?.findIndex(e=>e?.toString()==value?.toString());
      if(index>=0){
      }else{
        this.census_columns.push(value);
      }
    }else if(!$event.source.checked){

      let value = $event.source.value.toString();
      this.census_columns=this.census_columns?.filter(e=>e!=value);
    }
   
  }

  checkExistence(value){
    //console.log("this.census_columns",this.census_columns);
    return this.census_columns?.findIndex(e=>e?.toString()==value?.toString())>=0?true:false;
  }


  setcensus_columns(){

    this.companyService.updateCensusColumnSetting(this.census_columns, this.company_id).subscribe((res:any)=>{
        
      console.log("responseeee  : : compnay census col settings : ", this.census_columns, res.data);
      this.dialogRef.close(true);

    })

  }

}
