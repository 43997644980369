import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { DashboardService } from "src/app/includes/dashboard/dashboard.service";
import { CompanyService } from "src/app/services/Modules/company.service";
import { AuthService } from "src/app/services/auth.service";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
    selector: "app-approve-by-admin",
    templateUrl: "./approve-by-admin.component.html",
    styleUrls: ["./approve-by-admin.component.css"]
})
export class ApproveByAdminComponent implements OnInit {
    user_id: any;
    user_obj: any;
    twofacTrue: boolean = false;
    twofacAuthCode: any;
    currentUser: any;
    isCodeSent: boolean = false;
    loaderId = "app-wound-reports";
    isUserActive = false;
    userInfo=
    {
        name:'',
        email:''
    };
    constructor(
        private route: ActivatedRoute,
        private dashboardService: DashboardService,
        private _toastr: ToastrService,
        private companyService: CompanyService,
        private _router: Router,
        private loader: NgxUiLoaderService,
        private _authService: AuthService
    ) {
        this.currentUser = this._authService.currentUser;
        console.log("this.user_id: ", this.route.snapshot.params.id);
        this.user_id = this.route.snapshot.params.id;
    }

    async ngOnInit(): Promise<void> {
        this.userNotApproved();
        this.twofacTrue = false;
        if (this.currentUser) {
            await this.getUser();
        } else {
            this._toastr.error(
                "Login to the Admin Portal and Click Again"
            );
            this._router.navigate(["/admin-login"]);
        }
    }

    userNotApproved() {
        if (this.user_obj) {
            if (
                this.user_obj.is_approved_by_admin == "true" ||
                this.user_obj.is_activated == "true"
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    async get2FacAuth() {
        this.loader.startLoader(this.loaderId);
        this.companyService.get2FacAuth(this.user_id).subscribe((res: any) => {
            this.loader.stopLoader(this.loaderId);
            if (res.status == 200) {
                this._toastr.success("Two Factor authentication code sent");
                this.isCodeSent = true;
                this.loader.stopLoader(this.loaderId);
            } else {
                this.isCodeSent = false;
            }
        });
    }

    async verify2FacAuth() {
        this.loader.startLoader(this.loaderId);
        this.companyService
            .verify2FacAuth(this.user_id, this.twofacAuthCode)
            .subscribe((res: any) => {
                console.log("res : get2FacAuth : ", res);
                if (res && res.status == 200 && res.data == true) {
                    this.twofacTrue = true;
                    this.approveUser()
                } else {
                    this.loader.stopLoader(this.loaderId);
                    if (res && res.status == 200 && res.data == false) {
                        this._toastr.error(
                            "Invalid 2 factor authentication code"
                        );
                    } else {
                        this._toastr.error("Something went wrong");
                    }
                    this.twofacTrue = false;
                }
            });
    }

    async approveUser() {
        this.loader.startLoader(this.loaderId);
        if (this.userNotApproved()) {
            if (this.twofacTrue) {
                this.companyService
                    .approveUserActivation(this.user_id, "true")
                    .subscribe((res: any) => {
                        this.loader.stopLoader(this.loaderId);
                        console.log("res : get2FacAuth : ", res);
                        if (res.status == 200) {
                            this.userNotApproved()
                            this._toastr.success("User Activated Successfully");
                            this.getUser();
                            this.isUserActive = true;
                        } else {
                            this._toastr.error("User Activation Failed");
                        }
                    });
            }
        }
    }

    async getUser() {
        const userDetail: any = await this.dashboardService
            .getUserVerificationStatus(this.user_id)
            .toPromise();
        if (userDetail.status == 200) {
            console.log("userDetail: ", userDetail);
            this.user_obj = userDetail.user;
            this.userInfo.email=this.user_obj.email
            this.userInfo.name= `${this.user_obj.last_name}, ${this.user_obj.first_name}`
        }
    }
}
