<app-header [style.display]="fullScreen? 'none' : 'flex'"></app-header>
<div class="container flex flex-col" [class.removeContainer]="fullScreen">

  <mat-card *ngIf="!reportView" class="patient-card" [loaderId]="loaderId1" ngxUiLoaderBlurred
    style="height: 46px; width: 100%; text-align-last: left;margin-bottom: 5px !important; display: flex; flex-direction: row;">
    <mat-card-content class="flex justify-between items-baseline gap-2" style="width: 100%; margin-left: 10px;
    margin-right: 10px">
      <div style="width: 50%; display: flex; flex-direction: row; align-self: center;">

        <div style="width: 35%; display: flex;">

          <i style="margin-right: 15px;  align-self: center;" class=" icon icon-backR" matTooltip="RRGen List"
            matTooltipPosition="above" (click)="goBackToRrgen()"></i>

          <i *ngIf="!fullScreen" style="margin-right: 15px; align-self: center;" class=" icon icon-expand"
            matTooltip="FullScreen On" matTooltipPosition="above" (click)="$event.preventDefault(); toggleScreen()"></i>
          <i *ngIf="fullScreen" style="margin-right: 15px; align-self: center;" class=" icon icon-collapse"
            matTooltip="FullScreen Off" matTooltipPosition="above"
            (click)="$event.preventDefault(); toggleScreen()"></i>
          <i style="margin-right: 15px; align-self: center;" class=" icon icon-unlock" matTooltip="Unlock Document"
            matTooltipPosition="above" (click)="$event.preventDefault();unlockDocument()"></i>

          <i *ngIf="!showImageDiv" style="margin-right: 15px;align-self: center;" class=" icon icon-download"
            matTooltip="Download Report" matTooltipPosition="above" (click)="downloadSummary()"></i>

          <button *ngIf="showImageDiv" mat-raised-button class="dn-button-primary" (click)="previeweReport()"
            style="margin-left: 10px;color: black;background: none;padding: 0px;text-decoration: underline;">
            Preview Report</button>

        </div>

        <div style="width: 25%; display: flex;">
          <div class="flex gap-2 justify-center items-center">
            <i class="icon icon-back1" matTooltip="Previous Page" matTooltipPosition="above"
              (click)="navigateButton(-1, element)" style="width: 40px;height: 25px;align-self: center;"></i>
            <mat-form-field class="manual-page">
              <input style=" text-align: center;text-align-last: center; color: black;"
                [disabled]="isNavigationInProgress" matInput type="number" [(ngModel)]="row"
                (ngModelChange)="jumpToPage(row)" min="1" [max]="documentPages.length" />
            </mat-form-field>
            <i class="icon icon-next1" matTooltip="Next Page" matTooltipPosition="above"
              (click)="navigateButton(1, element)" style="width: 40px;height: 25px;align-self: center;"></i>
          </div>
        </div>

        <div style="width: 40%; display: flex;justify-content: space-evenly;">
          <button *ngIf="showImageDiv" mat-raised-button class="dn-button-primary" (click)="toggleFlip()"
            style="margin-left: 10px;color: black;background: none;padding: 0px;text-decoration: underline;">
            {{ flip == 'active' ? 'Show Image' : 'Show Text' }}
          </button>

          <button *ngIf="!showImageDiv" mat-raised-button class="dn-button-primary" (click)="showImageDiv=true"
            style="margin-left: 10px;color: black;background: none;padding: 0px;text-decoration: underline;">
            Show Image
          </button>

          <button mat-raised-button class="dn-button-primary" style="margin-left: 10px;" (click)="generateReport()"
            style="margin-left: 10px;color: black;background: none;padding: 0px;text-decoration: underline;">
            Generate Report
          </button>
        </div>
      </div>

      <div style="width: 50%; display: flex; flex-direction: row ;align-items: baseline;justify-content: flex-end;">

        <div class="patient-attribute flex gap-2 items-baseline justify-between"
          style="width: 20%;">
          <mat-form-field appearance="fill" style="width: 115px;">
            <mat-label>Claimant Id</mat-label>
            <input placeholder="Enter ID" style="display: inline-block;width:auto;" matInput (keyup)="changedValue()"
              type="text" [(ngModel)]="selectedClaimentId" [value]="selectedClaimentId" />
          </mat-form-field>
        </div>
        <div class="patient-attribute flex gap-2 items-baseline justify-between"
          style="width: 24%;">
          <mat-form-field appearance="fill" style="width: 140px;">
            <mat-label>Claimant</mat-label>
            <input placeholder="Enter Name" style="display: inline-block;width:auto;" matInput type="text"
              (keyup)="changedValue()" [(ngModel)]="selectedClaimentName" [value]="selectedClaimentName" />
          </mat-form-field>
        </div>
        <div class="patient-attribute flex gap-2 items-baseline justify-between"
          style="width: 18%;">
          <mat-form-field class="patient-date">
            <mat-label>DOI</mat-label>
            <input matInput [matDatepicker]="pick" name="selectedDOI" (dateChange)="dateChangeDOI($event)"
              (keyup)="changedValue()" [value]="selectedClaimentDOI" [ngModel]="selectedClaimentDOI" />
            <mat-datepicker-toggle matSuffix [for]="pick"></mat-datepicker-toggle>
            <mat-datepicker #pick></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="patient-attribute flex gap-2 items-baseline justify-between"
          style="width: 16%;">
          <mat-form-field class="patient-date">
            <mat-label>DOB</mat-label>
            <input matInput [matDatepicker]="picker" name="selectedDOB" (dateChange)="dateChangeDOB($event)"
              (keyup)="changedValue()" [value]="selectedClaimentDOB" [ngModel]="selectedClaimentDOB" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <div class="division flex gap-2 flex-1" [class.removeScroll]="showImageDiv"
    [class.addScroll]="!showImageDiv">
    <div class="flex flex-col flex-1" style="width: 50% !important;" [class.removeScroll]="showImageDiv"
      [class.addScroll]="!showImageDiv">

      <app-report-view id="reportView" *ngIf="!showImageDiv && !saved " [report]="report" style="overflow-y: auto;">
      </app-report-view>
      <app-report-view id="reportView" *ngIf="!showImageDiv && saved" [savedReport]="savedReport"
        style="overflow-y: auto;">
      </app-report-view>
      <div *ngIf="showImageDiv" class="flex flex-col gap-2" style="margin-top: 4px;">
        <div class="tp-wrapper flex flex-grow" style="height: fit-content;">
          <div class="tp-box" [@flipState]="flip" *ngIf="row && row > 0 && row <= documentPages.length">
            <div [loaderId]="loaderId" ngxUiLoaderBlurred style="flex-direction: column !important;">
              <div class="tp-box__side tp-box__front flex-1 flex flex-row justify-end items-baseline"
                style="padding-top: 5px; display: flex; flex-direction: column !important;">
                <pinch-zoom [overflow]="true" id="imageID" backgroundColor="#FFF" style="overflow-y: auto !important; ">
                  <img [src]="getImageUrl(row)" alt="" (load)="handleImageLoad()" id="image" />
                </pinch-zoom>
              </div>

              <div style=" display: flex; width: 85%;position: absolute; justify-content: flex-end; margin-top: 10px;">
                <i class="icon icon-rotate" matTooltip="Rotate Page" style="z-index: 999 !important;width: 47px; height: 50px;" matTooltipPosition="above"
                  (click)="rotatePage()" ></i>
              </div>

            </div>
            <ngx-ui-loader [hasProgressBar]="true" [loaderId]="loaderId"></ngx-ui-loader>
            <div class="tp-box__side tp-box__back">
              <div
                style="overflow-y: auto; font-size: 12px; word-break: keep-all; white-space: pre-wrap; padding: 10px;"
                [innerHTML]="getImageText()" contenteditable>
              </div>

            </div>
          </div>
        </div>

        <div style="height: 10px;"></div>

      </div>
    </div>
    <div class="flex flex-col" style="width:50%;">
      <div class="flex-col flex-1 gap-0" style="position: relative;">
        <div class="flex table-wrapper" style="overflow: auto;">
          <div *ngIf="edit && !reportView" style="display: flex; flex-direction: row;">

            <div
              style="width: 22%;display: flex; flex-direction: column; align-items: center; margin-left: 5px; margin-right: 12px;">
              <button *ngIf="edit || reportView"
                style="margin-bottom: 3px; background: none;text-decoration: underline;" mat-raised-button
                class="dn-button-primary" (click)="goBack()">Back to List</button>
              <div *ngFor="let thumb  of thumbnailArray(thumbsDiv); index as i">
                <div style="display:flex; align-items: center;  flex-direction:column">
                  <div
                    style="flex-direction: row; box-sizing: border-box;display: flex; margin: 3px; align-items: center;">
                    <i *ngIf="i == thumbControls" class="icon icon-back1" matTooltip="Previous Pages"
                      matTooltipPosition="above" (click)="changeThumbnails(-(thumbsDiv),false)"
                      style="margin-left: 2px;"></i>

                    <img style="width: 100px; height: 80px; margin: 3px; margin-bottom: 3px;"
                      [class.image-border]="selectedRow == thumbnail + i" [src]="getImageUrl(thumbnail + i)" alt=""
                      (load)="handleImageLoad()" (click)="imageClick($event, element)" />

                    <i *ngIf="i == thumbControls" class="icon icon-next1" matTooltip="Next Pages"
                      matTooltipPosition="above" (click)="changeThumbnails(thumbsDiv, false)"
                      style="margin-right: 2px;"></i>
                  </div>
                  <a href="#" style="color: white; font-size: 11px"> {{thumbnail + i}} </a>
                </div>

              </div>
            </div>
            <app-page-edit-form *ngIf="documentPages.length > 0 && edit && documentPages[row-1]"
              style="width: 100%; overflow: auto;" [pageItem]="documentPages[row-1]" [savedReport]="savedReport"
              (onSaveClick)="onSaveClick($event)" (onaddNewPage)="addNewPage($event)"
              (onSummaryChange)="onSummaryChange()" (pageReport)="pageReport($event)">
            </app-page-edit-form>
          </div>
          <div *ngIf="dataSource && !edit">
            <table mat-table [dataSource]="dataSource" matSort class="document-list-table" id="matTable">

              <ng-container matColumnDef="checkbox">
                <th class="page-number" mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                <td mat-cell *matCellDef="let element" style="border-bottom: hidden;vertical-align: middle;padding-left: 15px;
                padding-right: 10px;">
                  <mat-checkbox (change)="changeCheckBox(element, $event);" [checked]="element.is_checked">
                  </mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="provider">
                <th class="provider" mat-header-cell *matHeaderCellDef mat-sort-header class="row-height">
                  Provider
                </th>
                <td mat-cell *matCellDef="let element" style="border-bottom: hidden;vertical-align: bottom;"
                  class="row-height">

                  <mat-form-field *ngIf="element.addNewProvider" style="max-width:100px">
                    <mat-label>Select Provider</mat-label>
                    <mat-select aria-required="true" [disabled]="element.page_no!= row"
                      [(ngModel)]="element.selected.provider">
                      <mat-option (click)="onSelectProvider(element,'Add New')">Add New</mat-option>
                      <mat-option *ngFor="let e of element.provider " [value]="e"
                        (click)="onSelectProvider(element, e)">
                        {{e}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="flex gap-2 items-baseline" *ngIf="!element.addNewProvider">
                    <mat-form-field style="max-width:100px">
                      <mat-label>Provider</mat-label>
                      <input matInput type="text" [(ngModel)]="element.selected.provider" aria-required="true"
                        name="selectedProvider" />
                    </mat-form-field>
                    <a *ngIf="element.page_no== row" href="#"
                      (click)="$event.preventDefault();element.addNewProvider=true;element.selected.provider=''">X</a>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="date">
                <th class="date" mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                <td mat-cell *matCellDef="let element"
                  style="border-bottom: hidden;vertical-align: bottom; max-width: 100px;">
                 
                  <mat-form-field class="flex flex-row items-baseline" *ngIf="element.addNewDate "
                    style="max-width: 100px !important; ">
                    <mat-label>Select Date</mat-label>
                    <mat-select style="max-width: 100px !important;" [disabled]="element.page_no!= row"
                      aria-required="true" [(ngModel)]="element.selected.date"
                      (ngModelChange)="onSelectDate(element, element.selected.date)">
                      <mat-option disabled (click)="onSelectDate(element, 'Add New')" value="">Add New</mat-option>
                      <mat-option style="max-width: 100px !important;" *ngFor="let e of element.date" [value]="e">
                        {{e | date: 'MM/dd/yyyy'}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <div class="flex flex-row gap-2 items-baseline" *ngIf="!element.addNewDate"
                    style="max-width:100px">
                    <mat-form-field style="max-width:100px">
                      <mat-label>Select Date</mat-label>
                      <input matInput [matDatepicker]="pick" name="selected.date"
                        (ngModelChange)="convert_date(element, element.selected.date)" [ngModel]="element.selected.date"
                        [value]="element.selected.data | date: 'MM/dd/yyyy'" />
                      <mat-datepicker-toggle matSuffix [for]="pick"></mat-datepicker-toggle>
                      <mat-datepicker #pick></mat-datepicker>
                    </mat-form-field>
                    <a *ngIf="element.page_no== row" href="#"
                      (click)="$event.preventDefault();element.addNewDate=true;element.selected.date=''">X</a>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="date_type">
                <th class="date-type" mat-header-cell *matHeaderCellDef mat-sort-header>Date Type </th>
                <td mat-cell *matCellDef="let element" style="border-bottom: hidden;vertical-align: bottom;"
                  (click)="$event.preventDefault();onEditClick(element)">
                  <mat-form-field style="max-width:80px">
                    <input matInput type="text" [disabled]="element.page_no!= row" [value]="getDateType(element)"
                      readonly>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="note_type">
                <th class="page-type" mat-header-cell *matHeaderCellDef mat-sort-header>Note Type </th>
                <td mat-cell *matCellDef="let element" style="border-bottom: hidden;vertical-align: bottom;">
                  <div class="flex flex-row gap-2 items-baseline" *ngIf="!element.addNewNoteType">
                    <mat-form-field style="max-width:90px">
                      <input matInput type="text" [disabled]="element.page_no!= row"
                        [value]="element.selected.note_type" readonly>
                    </mat-form-field>
                    <a *ngIf="element.page_no== row" href="#"
                      (click)="$event.preventDefault();element.addNewNoteType=true;element.selected.note_type=''">X</a>
                  </div>
                  <mat-form-field style="max-width:100px" *ngIf="element.addNewNoteType">
                    <mat-label>Select Note Type</mat-label>
                    <mat-select [disabled]="element.page_no!= row" aria-required="true"
                      [(ngModel)]="element.selected.note_type">
                      <mat-option disabled (click)="onSelectNote('Add New',element)" value="">Add New</mat-option>
                      <mat-option *ngFor="let e of element.note_type" [value]="e" (click)="onSelectNote(e, element)">
                        {{e}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="impression">
                <th class="page-detail" mat-header-cell *matHeaderCellDef mat-sort-header> Note Detail </th>
                <td mat-cell *matCellDef="let element" style="border-bottom: hidden;vertical-align: bottom;"
                  (click)="$event.preventDefault();onEditClick(element)">
                  <mat-form-field style="max-width:110px">
                    <input matInput type="text" [disabled]="element.page_no!= row" [value]="element.impression"
                      readonly>
                  </mat-form-field>

                </td>
              </ng-container>

              <ng-container matColumnDef="page_no">
                <th class="page-number" mat-header-cell *matHeaderCellDef mat-sort-header> Page# </th>
                <td mat-cell *matCellDef="let element" style="border-bottom: hidden;vertical-align: middle;">
                  <a href="#" (click)="$event.preventDefault();onEditClick(element)">{{element.page_no}}</a>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                [ngClass]="{'highlighted': selectedRow == row.page_no}" (click)="pageNumClick(row.page_no, element)">
              </tr>
            </table>
          </div>

        </div>
        <mat-paginator [ngStyle]="{display: table ? 'block': 'none'}" [pageSizeOptions]="[10, 30, 100]"
          showFirstLastButtons>
        </mat-paginator>

      </div>

      <div *ngIf="edit" class="flex justify-between items-end">
        <div class="flex gap-5"
          style="width: 76%; text-align: center;background: white;place-content: center; ">
          <i class="icon icon-back1" matTooltip="Previous Page" matTooltipPosition="above"
            style="width: 40px;height: 35px;" (click)="navigateButton(-1, element)"></i>
          <div class="flex gap-4" style="margin-left:5px;align-items: center;"
            *ngFor="let thumb  of thumbnailArray(thumbsDiv); index as i">
            <a href="#" style="color: black; font-size: 11px;"
              (click)="$event.preventDefault();pageNumClick(thumbnail + i)"
              [class.page-number-underline]="selectedRow == thumbnail + i">
              {{thumbnail + i}} </a>
          </div>
          <i class="icon icon-next1" matTooltip="Next Page" matTooltipPosition="above"
            (click)="navigateButton(1, element)" style="width: 40px;height: 35px;"></i>
        </div>
      </div>

    </div>
    <ngx-ui-loader [hasProgressBar]="true" [loaderId]="loaderId1"></ngx-ui-loader>

  </div>
</div>