
<div style=" flex-direction: row; box-sizing: border-box; display: inherit !important;">

    <div [loaderId]="loaderId" ngxUiLoaderBlurred>


        <div  style="overflow-y: auto;flex-direction: column;box-sizing: border-box; background:#2d3e5b;min-width: max-content;" class="user-side-charges">

            <div class="flex gap-5 billerFilters">
                <div style="flex: 100%; min-width: auto;" class="flex items-start justify-center gap-5">
                    

                     <mat-form-field appearance="fill" style="max-width: 200px; flex:30%; margin-left:10px;">
                        <mat-label>Charge Status</mat-label>
                        <mat-select [(ngModel)]="filters.chargeType" (selectionChange)="changeChargeType(); initProviderSide()">
                            <mat-option value='draft'>Drafted </mat-option>
                            <mat-option value='submit'>Submitted </mat-option>
                            <mat-option value='billed'>Billed </mat-option>
                        </mat-select>
                    </mat-form-field> 


                    <mat-form-field *ngIf="searchBy=='patient'&&true" appearance="fill" style="flex: 70%; min-width: auto;">
                        <mat-label>Search Patient by Full Name (min 4 letters)</mat-label>
                        <input matInput (keyup)="applyFilterPatient($event.target.value)" [(ngModel)]="filters.patient">
                    </mat-form-field> 
                    <input *ngIf="currentUser.user_type === global.USER_TYPE.BILLER && searchBy=='patient'" class="biller_filer_by_dos" style="margin-top: 6px!important;width: 38%;" class="mat-like-input" type="text"
                        [(ngModel)]="filters.dos" ngxDaterangepickerMd [locale]="{applyLabel: 'ok', format: 'MM/DD/YYYY'}"
                        placeholder="Filter by DOS" (datesUpdated)="changeCreationDate($event)"
                        [ranges]="preDateRanges" [alwaysShowCalendars]="true" [maxDate]="currentDate" [linkedCalendars]="true"
                        [showClearButton]="true" />

                    <mat-form-field style="width: 50%;"  *ngIf="currentUser.user_type === global.USER_TYPE.BILLER && searchBy=='patient'">
                        <mat-label>Providers</mat-label>
                        <mat-select name="provider" #provider="ngModel" [(ngModel)]="filters.provider"
                        (ngModelChange)="handleproviderChange(provider)">
                        <input matInput placeholder="Search" [(ngModel)]="searchTextProvider"
                            [ngModelOptions]="{standalone: true}" style="color:#000;height: 40px;padding-left: 5px;" />
                        <mat-option *ngFor="let provider of providers | searchProvider : searchTextProvider"
                            value="{{provider._id}}">
                            {{provider.first_name}} {{provider.last_name}} {{provider.title}}
                        </mat-option>
                        </mat-select>
                    </mat-form-field>
                    
                    <mat-form-field style="width: 50%;" appearance="fill" *ngIf="currentUser.user_type === global.USER_TYPE.BILLER && searchBy=='patient'">
                        <mat-label>Select by Facility</mat-label>
                        <mat-select [(ngModel)]="filters.facility" (selectionChange)="applyFilterFacility($event)">
                            <input matInput placeholder="Search" [(ngModel)]="searchText"
                                style="height: 40px;padding-left: 5px;" (keydown)="$event.stopPropagation()">
                            <mat-option [value]="selected">--All--</mat-option>
                            <mat-option placeholder="Facility"
                                *ngFor="let facility of allFacilities | searchFacility : searchText"
                                [value]="facility._id">
                                <app-facility-title [facility]="facility"></app-facility-title>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill" *ngIf="(searchBy=='facesheet')" style="flex: 70%; min-width: auto;">
                        <mat-label>Face Sheet</mat-label>
                        <mat-select value="" (selectionChange)="applyFaceSheetFilter($event.value)"
                            [(ngModel)]="faceSheetFilter">
                            <mat-option disabled value="">---</mat-option>
                            <mat-option value="all" *ngIf="searchBy=='patient'">All</mat-option>
                            <mat-option value="view" *ngIf="searchBy=='facesheet'">With FaceSheet</mat-option>
                            <mat-option value="upload" *ngIf="searchBy=='facesheet'">Missing Facesheet</mat-option>
                            <mat-option value="missing" *ngIf="searchBy=='patient'">Missing Facesheet</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div style="flex: 65%; min-width: auto;" class="flex justify-end items-center gap-2">
                   
                    <button class="dn-button-secondary" mat-raised-button (click)="clearFilter()">
                        Clear Filter
                    </button>
                    <button class="dn-button-primary" mat-raised-button (click)="captureCharges()"
                        *ngIf="allPatients?.length && filters.chargeType == 'submit'">Generate Report
                    </button>
                 
                    <button class="dn-button-primary" mat-raised-button (click)="submitAll()"
                        *ngIf="chargesIDs.length > 0 && filters.chargeType == 'draft'  ">Submit All
                    </button>
                    <div class="justify-end items-center" *ngIf="this.allPatients">
                        <button mat-raised-button class="dn-button-primary" [disabled]="!pagination.hasPrevPage"
                            (click)="handlePrevClick()"> Prev</button>
                        <span style="background: white; border-radius: 2px; font-size: 12px;padding: 5px;margin-top:auto;">
                            {{getcountofPatients()}}</span>
                        <button mat-raised-button class="dn-button-primary" [disabled]="!pagination.hasNextPage"
                            (click)="handleNextClick()">Next</button>
                    </div>
                </div>
            </div>
        

            <div class="flex gap-2"
                style="overflow: auto; background: white; height: calc(100vh - 150px)">

                    <ngx-ui-loader *ngIf="searchBy=='patient'" loaderId="loaderp"></ngx-ui-loader>
                    <ngx-ui-loader *ngIf="searchBy=='facesheet'" loaderId="loaderf"></ngx-ui-loader>

                <table *ngIf="!loading" mat-table [dataSource]="dataSource" multiTemplateDataRows matSort (matSortChange)="sortData($event)"
                    style="align-self: flex-start;">


                     <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-checkbox #myCheckbox (change)="selectChargeAll($event);"></mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let element"
                            style="width: 50px; padding-left: 10px; text-align: center; cursor: pointer;">
                            <mat-checkbox
                                [disabled]="(element.is_approved == 'true' || element.cpt_id.length == 0 || element.icd_id.length == 0 || !checkVoidCensus(element?.rounding_sheet_id))"
                                (change)="selectCharge(element, $event);" [checked]="selectedCharge(element)">
                            </mat-checkbox>
                        </td>
                    </ng-container> 

                    <ng-container matColumnDef="checkBox">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Completed</th>
                        <td mat-cell *matCellDef="let element"
                            style="padding-left: 10px; text-align: center; cursor: pointer;">
                            <mat-checkbox (change)="changeCheckBox(element, $event);"
                                [checked]="checkBoxValue(element)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="visit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="position: fixed;">Date
                            of Service</th>
                        <td mat-cell *matCellDef="let element" style="cursor: pointer;">{{
                            getVisit(element)}}</td>
                    </ng-container>

                    <ng-container matColumnDef="time">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="position: fixed;">Visit Date</th>
                        <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                          
                            {{getVisitDate(element )}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="create_time">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="position: fixed;">
                            Created At</th>
                        <td mat-cell *matCellDef="let element" style="width: 15% ; cursor: pointer;">
                            {{element.createdAt | date : 'MM/dd/yyyy h:mm a'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Expansion Icon">
                        <th mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
                        <td mat-cell *matCellDef="let element" style="width: 0px;">
                            <button mat-icon-button aria-label="expand row" (click)="expandedElementId = expandedElementId === element._id ? null : element._id;
                            toggleRow(element._id); tabIndex = 0;">
                                <mat-icon *ngIf="expandedElementId !== element._id">keyboard_arrow_down</mat-icon>
                                <mat-icon *ngIf="expandedElementId === element._id">keyboard_arrow_up</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="patient_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="position: fixed;">Name
                        </th>
                        <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                            {{getPatientName(element)}}
                        </td>
                    </ng-container>
              

                    <ng-container matColumnDef="location">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Facility</th>
                        <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                            <app-facility-title [facility]="element.facility_id_ref"></app-facility-title>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="face_sheet">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Face Sheet</th>
                        <td mat-cell *matCellDef="let element; let index=index;" style="cursor: pointer;">
                                <div class="flex items-center justify-center gap-1">
                                    <button mat-icon-button matTooltip="Upload Face Sheet" 
                                    (click)="handleUploadFacesheetClick(element, index)">
                                        <mat-icon>file_upload</mat-icon>
                                    </button>
    
                                    <button mat-icon-button matTooltip="Downlaod Face Sheet" 
                                    [disabled]="!downloadFaceSheetActive(element, index)"
                                    (click)="handleDownloadFacesheetClick(element, index)">
                                        <mat-icon>file_download</mat-icon>
                                    </button>
                                </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="audit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Audit</th>
                        <td mat-cell *matCellDef="let element; let index=index;" style="cursor: pointer;">
                            <div style="color: blue;"
                                (click)="auditClicked(element)">View</div> 
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="charge">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Charge</th>
                        <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                            <div>
                                <span style="font-weight: bold;"> ICD: </span>
                                <span>{{element.icdString}}</span>
                            </div>
                            <div>
                                <span style="font-weight: bold;"> CPT:</span>
                                <span>{{element.cptString}}</span>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="biller_note">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Biller Note</th>
                        <td mat-cell *matCellDef="let element; let index=index;" style="cursor: pointer;">
                            <div *ngIf="element.note!=''" style="color: blue;" (click)="viewBillerNote(element.note)">view</div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="timer">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                            <span *ngIf="element.is_approved !== 'true'"
                                style="display: inline-flex; place-content: center;">
                                <countdown-timer class="duration" [end]="getEndTimeForTimer(element)"
                                    (zeroTrigger)="triggerFunction()">
                                </countdown-timer>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="operation">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let element" style="text-align: center;">
                            <button mat-raised-button class="dn-button-primary"
                                *ngIf="element.patient_id?.pcc_patientId"
                                [routerLink]="['/patient-detail/' + element.patient_id._id + '/facesheet']">Facesheet</button>

                        </td>
                    </ng-container>
                    <ng-container matColumnDef="approve">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;"></th>
                        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer; ">

                            <mat-form-field appearance="fill" style="align-self: flex-end; margin-right: 5px;"
                                *ngIf="billingComapny != null && billingComapny.length > 1 ">
                                <mat-label>Resend to Billing Company</mat-label>
                                <mat-select value="all"
                                    (selectionChange)="billingCompanyselection($event.value, element)">
                                    <mat-option *ngFor="let billing of billingComapny " [value]="billing">
                                        {{billing.billingCompanyId.name}}
                                    </mat-option>
                                    <mat-option value="">Send to All</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button mat-raised-button class="dn-button-primary" style="margin-right: 5px;"
                                *ngIf="billingComapny != null && billingComapny.length == 1"
                                (click)="billingCompanyselection(billingComapny[0], element)">{{getValue(billingComapny[0])}}</button>

                            <button mat-raised-button style="color: black;" class="dn-button-primary" [disabled]='true'
                                style="margin-left: 5px;"
                                *ngIf="element.is_approved === 'true' && dataLoaded === true">Completed</button>

                            <button mat-raised-button class="dn-button-primary" (click)="toggleApproveCharge(element)"
                                style="margin-left: 5px;"
                                *ngIf="element.is_approved !== 'true' && dataLoaded === true">Approve</button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="note">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
                            <span *ngIf="element.note_id && element.note_id.is_signed === 'false'"
                                matTooltip="View Note" matTooltipPosition="above" class="material-icons"
                                (click)="viewNote(element)">
                                description
                            </span>
                           
                        </td>
                    </ng-container> 
                    <ng-container matColumnDef="view">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
                            <div class="flex items-center justify-center gap-1">
                                <i matTooltip="view" matTooltipPosition="above" class="icon icon-view"
                                    (click)="patientClicked(element)"></i>
                                    <i matTooltip="Edit" matTooltipPosition="above" class="icon icon-edit" *ngIf="filters.chargeType === 'submit'"
                                    (click)="editChargeClicked(element)"></i>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
                            <i matTooltip="Delete" matTooltipPosition="above" class="icon icon-delete"
                                (click)="deleteChargeClicked(element)"
                                *ngIf="element.is_approved !== 'true' && checkVoidCensus(element?.rounding_sheet_id)"></i>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="accordion">
                        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                            <div class="accordion-cell"
                                [@accordionTrigger]="element._id == expandedElementId ? 'expanded' : 'collapsed'">
                                <mat-tab-group *ngIf="expandedRows.includes(element._id);" class="expanded-child"
                                    [(selectedIndex)]="tabIndex" (selectedTabChange)="onTabChange($event);">
                                    <mat-tab label="Patient Notes">
                                        <app-patient-detail-list *ngIf="tabIndex === 0" [patient]="element.patient_id"
                                            [isTelehealthReport]="true"></app-patient-detail-list>
                                    </mat-tab>
                                    <mat-tab label="Patient Documents">
                                        <app-patient-documents-list *ngIf="tabIndex === 1"
                                            [patient]="element.patient_id"></app-patient-documents-list>
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                        </td>
                    </ng-container>
        
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let element; columns: displayedColumns;">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: ['accordion']" class="accordion-row"></tr>
                </table>
            </div>
        </div>
    </div>
</div>