<div class="container">
    <mat-card class=" vertical-height">
        <div class="flex justify-between items-center space-flex">
            <button mat-raised-button class="dn-button-secondary" type="button" (click)="goBack();">Back</button>
            <button mat-raised-button class="dn-button-primary" (click)="openSendEmailTemplateDialog();" type="button">Compose Email</button>
          </div> 
        <mat-card-content>
            <mat-tab-group [(selectedIndex)]="tabIndex" (selectedTabChange)="onTabChange($event)">
                <mat-tab disabled>       
                </mat-tab>

                <mat-tab label="Email Template">
                    <div class="mat-tab-content">
                        <app-email-template *ngIf="tabIndex === 1"></app-email-template>
                    </div>
                </mat-tab>

                <mat-tab label="Emails">
                    <div class="mat-tab-content">
                        <app-email *ngIf="tabIndex === 2 && isOneTimeEmailReceived"></app-email>
                    </div>
                </mat-tab>

                <mat-tab label="Automated Emails">
                    <div class="mat-tab-content">
                        <app-automated-email *ngIf="tabIndex === 3 && isAutomaticEmailReceived"></app-automated-email>
                    </div>
                </mat-tab>
                <mat-tab disabled>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </mat-card>
</div>
