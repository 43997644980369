
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../login/login.service';
import * as global from '../global';
import { MatDialog } from '@angular/material/dialog';
import { DialogInformationComponent } from '../dialog-information/dialog-information.component';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  companyCode: any;
  defaultImg: string;
  companylogoPath: any;
  companylogoPathSubscription: any;
  // implemented #mohsin-dev
  constructor(
    private _location: Location,
    private _toastr: ToastrService, 
    private dialog: MatDialog,
    public loginService: LoginService)
     { }
  status: any = null;
  enableCognito = false;
  cognito = false;

  async ngOnInit() {

      this.defaultImg = "../../../assets/img/DNlogo-new.png";
    
  }

  async getCompanyLogo() {
        this.defaultImg = "../../../assets/img/DNlogo-new.png";

  }


  onContactUs(contactUsForm) {

    if (!contactUsForm.valid || !contactUsForm.touched) return;

    console.log("contactUsForm : ", contactUsForm.value);

    const filter = contactUsForm.value;

   this.loginService.sendContactUsEmail({filter:filter}).subscribe((res:any)=>{

      let dialogRef = this.dialog.open(DialogInformationComponent, {
        data: {
          message: 'Thank you for contacting DN. We will be in touch with you shortly.',
          button:'Back to Login'
        }
      });
      dialogRef.afterClosed().subscribe(async (data) => {
        if(data?.back){
          this._location.back();
        }

      })

    })

  }
}