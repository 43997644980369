<ngx-ui-loader loaderId="patient-list-loader-s"></ngx-ui-loader>

<div style="display: flex; flex-direction: column !important; height: 700px;">
    <mat-card>
        <mat-card-content>
            <mat-tab-group (selectedTabChange)="onTabClick($event)">
                <mat-tab *ngIf="facility.pcc_facId" label="PCC Patients">
                    <div class="mat-tab-content">
                        <div class="flex flex-col items-start">

                                <mat-form-field style="width: 100%; margin-top: 15px;">
                                    <mat-label>Search by Name</mat-label>
                                    <input matInput (ngModelChange)="applyPccFilter()" [(ngModel)]="pccpatientName">
                                </mat-form-field>

                            <div style="height: 435px; width: 100%; overflow-y: auto; margin-top:5px; display: block;">
                                <table class="table-row-hover patient-list-table" mat-table
                                    [dataSource]="PccdataSource" >
                                    <ng-container matColumnDef="checkbox">
                                        <th mat-header-cell *matHeaderCellDef ></th>
                                        <td mat-cell *matCellDef="let element">
                                            <mat-checkbox [checked]="isPccPatientSelected(element)"
                                                (change)="handlePccSelectPatient($event, element)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef > Name</th>
                                        <td mat-cell *matCellDef="let element">
                                            {{ element.lastName+', '+ element.firstName}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="date_of_birth">
                                        <th mat-header-cell *matHeaderCellDef > Date of Birth</th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.birthDate}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="gender">
                                        <th mat-header-cell *matHeaderCellDef > Gender</th>
                                        <td mat-cell *matCellDef="let element"> {{element.gender}} </td>
                                    </ng-container>

                                    <tr mat-row *matRowDef="let row; columns: displayedPccColumns;"
                                        [ngClass]="{selectable: selectable, selected: currentPatient && currentPatient.patientId == row.patientId}">
                                    </tr>
                                        <tr mat-header-row *matHeaderRowDef="displayedPccColumns" class="hover-row"></tr>

                                </table>
                            </div>
                            <div class="flex justify-center items-center" style="margin-top:10px;">
                                <div class="flex justify-center items-center" style="margin-right:200px;">
                                    <button mat-raised-button class="dn-button-primary"
                                        [disabled]="!Pccpagination.hasPrevPage" (click)="handlePccPrevClick()">
                                        Prev</button>

                                        <span style="background: white; border-radius: 2px; font-size: 12px;padding: 5px; margin: 20px;">
                                            {{'Page: '+Pccpagination.page}}</span>

                                    <button mat-raised-button class="dn-button-primary"
                                        [disabled]="!Pccpagination.hasNextPage"
                                        (click)="handlePccNextClick()">Next</button>
                                </div>

                            </div>

                        </div>

                    </div>
                </mat-tab>
                <mat-tab  label="DN Patients">
                    <div class="mat-tab-content">

                        <div class="flex flex-col items-start">

                                <mat-form-field style="width: 100%; margin-top: 15px;">
                                    <mat-label>Search by Name</mat-label>
                                    <input matInput (ngModelChange)="applyFilter()" [(ngModel)]="patientName">
                                </mat-form-field>
                            <div style="height: 465px; width: 100%; overflow-y: auto; margin-top:5px; display: block;">
                                <table  class="table-row-hover patient-list-table" mat-table
                                    [dataSource]="DNdataSource" >
                                    <ng-container matColumnDef="checkbox">
                                        <th mat-header-cell *matHeaderCellDef ></th>
                                        <td mat-cell *matCellDef="let element">
                                            <mat-checkbox [checked]="isPatientSelected(element)"
                                                (change)="handleSelectPatient($event, element)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef > Name</th>
                                        <td mat-cell *matCellDef="let element">
                                            <app-patient-name [patient]="element"></app-patient-name>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="date_of_birth">
                                        <th mat-header-cell *matHeaderCellDef > Date of Birth</th>
                                        <td mat-cell *matCellDef="let element">
                                            {{ getDOB(element) }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="gender">
                                        <th mat-header-cell *matHeaderCellDef > Gender</th>
                                        <td mat-cell *matCellDef="let element"> {{element.gender}} </td>
                                    </ng-container>

                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                        [ngClass]="{selectable: selectable, selected: currentPatient && currentPatient.patient_id == row.patient_id}">
                                    </tr>
                                    <ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="hover-row"></tr>
                                    </ng-container>
                                </table>
                            </div>
                            <div class="flex justify-center items-center" style="margin-top:10px;">
                                <div class="flex justify-center items-center" style="margin-right:200px;">
                                    <button mat-raised-button class="dn-button-primary"
                                        [disabled]="!pagination.hasPrevPage" (click)="handlePrevClick()"> Prev</button>

                                    <span style="background: white; border-radius: 2px; font-size: 12px;padding: 5px;">
                                        {{getcountofPatients()}}</span>

                                    <button mat-raised-button class="dn-button-primary"
                                        [disabled]="!pagination.hasNextPage" (click)="handleNextClick()">Next</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </mat-card>
    <div *ngIf="selectedPatients.length||selectedPccPatients?.length" style="margin-top: 10px;" class="flex justify-end items-center">>

        <span style="background: white; border-radius: 2px; font-size: 12px;padding: 5px; margin-right: 20px;">
            {{'Selected Patients: '+totalList()}}</span>

        <button mat-raised-button class="dn-button-primary"
            (click)="handleSaveClick()">Save and Add</button>
    </div>

    <div class="top-right-close-btn">
        <button mat-mini-fab mat-dialog-close (click)="handleCancelClick()">
            <mat-icon>cancel</mat-icon>
        </button>
    </div>
</div>