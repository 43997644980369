import { Component,Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TranscriptionCreateService } from '../transcription-create.service';
import * as global from '../../global';
import moment from 'moment'
// UPLOAD FILE DIALOG
export interface AddendumNoteDialogData {
  note
}

@Component({
  selector: 'app-addendum-note-dialog',
  templateUrl: './addendum-note-dialog.component.html',
  styleUrls: ['./addendum-note-dialog.component.css']
})
export class AddendumNoteDialog implements OnInit {
  global = global;
  note:any;
  model = {
    noteId: '',
    addendumText:'',
    includeNoteText: false
  }
  addendums:any[] = [];

  constructor(
    public dialogRef: MatDialogRef<AddendumNoteDialog>,
    private transcriptionCreateService:TranscriptionCreateService,
    @Inject(MAT_DIALOG_DATA) public data: AddendumNoteDialogData) { 
      if(data) {
        this.note = data.note;
        this.model.noteId = data.note._id
      }
    }
    
    ngOnInit(): void {
      this.initAddendums();
    }

    initAddendums() {
      this.transcriptionCreateService.getNoteAddendums({noteId: this.model.noteId}).subscribe((response:any) => {
        if(response.status === 200) {
          this.addendums = response.data.array;
        }
      });
    }

    onSaveClick() {
      this.transcriptionCreateService.addendumNote(this.model).subscribe((response:any) => {
        console.log(response);
        
        this.dialogRef.close(this.model);
      })
    }
    
    applyTimezone(date) {
        return moment(new Date(date).toLocaleString('en-US', { timeZone:  this.note.dates.timezone })).format('MMM DD, YYYY h:mm a');
    }
}
