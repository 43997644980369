<h3>Add Payor Contact</h3>
<form (submit)="addContact(facilityForm.value)" #facilityForm="ngForm">

    <div class="flex flex-col gap-5">
        <div class="flex flex-row gap-2">
            <mat-form-field>
                <input
                        matInput
                        type="text"
                        name="first_name"
                        #first_name="ngModel"
                        [(ngModel)]="model.first_name"
                        placeholder="First Name"
                        onkeypress="return /[a-z]/i.test(event.key)"
                        required/>
            </mat-form-field>
            <mat-form-field>
                <input
                        matInput
                        type="text"
                        name="last_name"
                        #last_name="ngModel"
                        [(ngModel)]="model.last_name"
                        placeholder="Last Name"
                        onkeypress="return /[a-z]/i.test(event.key)"
                        required/>
            </mat-form-field>
        </div>
        <div class="flex flex-row gap-2">
            <mat-form-field>
                <input
                        matInput
                        type="text"
                        name="position"
                        #position="ngModel"
                        [(ngModel)]="model.position"
                        placeholder="Position"
                        required
                />
            </mat-form-field>
            <mat-form-field>
                <input
                        matInput
                        type="text"
                        name="email"
                        #extension="ngModel"
                        [(ngModel)]="model.email"
                        placeholder="Email"
                        required/>
            </mat-form-field>
            <mat-form-field>
                <input
                        matInput
                        type="text"
                        name="contact_phone"
                        #contact_phone="ngModel"
                        [(ngModel)]="model.phone"
                        placeholder="Phone"
                        required/>
            </mat-form-field>
        </div>
    </div>
    <div class="flex flex-row items-center justify-center gap-2">
        <button class="theme-btn" mat-stroked-button
                [disabled]="!facilityForm.valid"
                type="submit">Save</button>
        <button class="theme-btn" mat-stroked-button
                type="button" mat-dialog-close>Cancel</button>
    </div>

</form>

<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>
