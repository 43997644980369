import { Component, Input, OnInit } from '@angular/core';
import { NoteAuditoryHistoryService } from '../../note-auditory-history/note-auditory-history.service';

@Component({
  selector: 'app-note-time-logs',
  templateUrl: './note-time-logs.component.html',
  styleUrls: ['./note-time-logs.component.css']
})
export class NoteTimeLogsComponent implements OnInit {

  @Input('note') note: any;
  timeLogs: any;
    constructor(private noteAuditoryHistory:NoteAuditoryHistoryService,) { }

  ngOnInit() {
    console.log('app-note-time-logs');
    
    this.initNoteTimeHistory();
  }

  initNoteTimeHistory(filter?) {
    filter = {
     note_id:this.note._id
   }   
    this.noteAuditoryHistory.getNoteTimeLogs(filter).subscribe((response:any) => {
     if(response.status === 200) {
       this.timeLogs = response.data.time_logs;
     }
     
   });
 }

}
