import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CompanyService } from 'src/app/services/Modules/company.service';
import { CommonService } from 'src/app/services/common.service';
import { MatTableDataSource } from "@angular/material/table";
import { AppointmentService } from 'src/app/services/appointment.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-change-appointment-status-dialog',
  templateUrl: './change-appointment-status-dialog.component.html',
  styleUrls: ['./change-appointment-status-dialog.component.css']
})
export class ChangeAppointmentStatusDialogComponent implements OnInit {
  dataSource: any = new MatTableDataSource([]);// Your data source array
  displayedColumns: string[] = ['color','status', 'enable']; // Columns to display in the table
  loaderId = "change-status-loader";
  company_id: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private dialogRef: MatDialogRef<ChangeAppointmentStatusDialogComponent>,private loader: NgxUiLoaderService,
    private companyService: CompanyService, public commonService: CommonService,  private appointmentService: AppointmentService,private toastr: ToastrService, public route: ActivatedRoute) {
      const currentCompany = this.commonService.getLocalStorageItem('admin-Dashboard');
        if(currentCompany) {
          this.company_id = currentCompany.currentCompanyId;
        }else {
          this.company_id = this.route.snapshot.firstChild.params.id;
        }
     }

  ngOnInit(): void {
    this.getCompanyAppointmentVisitStatus()
    this.getAppointmentStatus();
    if(this.data){
    }
  }
  getCompanyAppointmentVisitStatus() {
    this.loader.startLoader(this.loaderId);
    this.companyService
        .getCompanyAppointmentVisitStatus(this.company_id)
        .subscribe((response: any) => {
            this.dataSource.data = response.data;
            this.loader.stopLoader(this.loaderId);
        });
}
  onCancel(): void {
    this.dialogRef.close(); // Close the dialog without saving changes
  }

  onSave(): void {
    this.dialogRef.close();
  }
  getAppointmentStatus(){
    this.appointmentService
        .getOneAppointment(this.data.appointment_id)
        .subscribe((response: any) => {
          this.toggleEnabled(response.data.appointment_status,true)
        });
  }
  onEnableChange(row: any,event) {
    // Call your API to update the enable status
    if(!event.checked){
      event.checked = true
      event.source.checked = true;
      this.dataSource.data.forEach(appointmentStatus => {
        appointmentStatus.enabled = appointmentStatus._id === row._id ? true : false;
      });
      return false;
    }
    if(!row.enabled){
      this.dataSource.data.forEach(appointmentStatus => {
        appointmentStatus.enabled = appointmentStatus._id === row._id ? event.checked : false;
      });
      let data: any = {
        _id:this.data.appointment_id,
        appointment_status:row._id
      }
      this.appointmentService
          .updateAppointmentStatus(data)
          .subscribe((response: any) => {
            this.toastr.success(response.message, "Success");
          });
   }
  }
  toggleEnabled(_id: any,checked: boolean) {
    this.dataSource.data.forEach(appointmentStatus => {
      appointmentStatus.enabled = appointmentStatus._id === _id ? checked : false;
    });
  }
}
