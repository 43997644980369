<div *ngIf="transcriptions" class="transcriptions-group" style="flex-direction: column;">
    <div *ngIf="!forPatientDetailList" class="flex justify-center items-center flex-grow" style="width: 100%;">
        <div style=" text-align: end !important;">
            <div class="group-index-title" *ngIf="group_index && group_index != 'undefined' ">
                <span>{{group_index !== 'undefined' ? group_index : 'N/A'}}</span>
            </div>
        </div>
    </div>
    <div *ngIf="transcriptions && !forPatientDetailList && transcriptions.length > 0">
        <div *ngIf="isVisibleSelectAll" class="flex gap-2"
            style="margin-left: 10px; margin-bottom: 10px;margin-top: 0px; font-size: 13px; color:grey; font-weight: 500;">
            <mat-checkbox (change)="selectAllTranscriptions($event.checked)" [ngModel]="isCheckedAll"></mat-checkbox>
            &nbsp; Select All
        </div>
    </div>
    <ul class="transcription-file-block" *ngIf="!forPatientDetailList">
        <ng-container *ngFor="let transcription of transcriptions">
            <li *ngIf="transcription?.empty">
                <div class="flex-grow">No Notes</div>
            </li>
            <li>

                <div class="flex" *ngIf="!transcription?.empty && transcription?.is_active == 'true'">
                    <div class="flex-grow-0" style="margin-right: 5px;" *ngIf="transcription">
                        <mat-checkbox (change)="toggleTranscriptionMultiSelect.emit(transcription)"
                            *ngIf="shouldShowCheckbox(transcription)"
                            [checked]="isTranscriptionInMultiSelect(transcription)">
                        </mat-checkbox>
                    </div>
                    <div class="flex flex-col gap-2 flex-grow">
                        <div class="flex flex-grow">
                            <div (click)="goToNoteEditor(transcription)" class="flex justify-start items-center gap-5">
                                <div class="flex flex-grow-0 justify-center items-center">
                                    <img class="transcription-icon" *ngIf="transcription.is_signed == 'false'"
                                        src="../../../assets/img/file.png">
                                    <img class="transcription-icon"
                                        *ngIf="transcription.is_signed == 'true' && transcription.is_completed != 'true'"
                                        src="../../../assets/img/signed_file.png">
                                    <img class="transcription-icon"
                                        *ngIf="transcription.is_signed == 'true' && transcription.is_completed == 'true'"
                                        src="../../../assets/img/completed_file.png">
                                   
                                </div>
                            </div>
                            <div class="column-title flex flex-grow justify-start items-center">
                                <div>
                                    <div>
                                        {{commonService.cleanTranscriptionTitle(transcription.transcribtion_title) |
                                        uppercase}}
                                    </div>
                                    <div *ngIf="transcription?.audio_id_ref?.audio_title" style="font-size: 11px;">
                                        {{transcription?.audio_id_ref?.audio_title}} |
                                        {{transcription?.audio_id_ref?.audio_length}}
                                    </div>
                                   
                                </div>
                            </div>
                            <div class="flex flex-grow-0 justify-end items-center">

                                <div class="flex justify-end items-center gap-4">
                                    <div class="status-buttons flex gap-4">

                                        <i matTooltip="View" matTooltipPosition="above" class="icon icon-view"
                                            *ngIf="isVisibleControls && transcription.is_trashed == 'true'"
                                            (click)="showTrashedNotes(transcription)"></i>
                                        <i matTooltip="View" matTooltipPosition="above" class="icon icon-view"
                                            *ngIf="isVisibleControls && transcription.is_trashed == 'false' && currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER"
                                            (click)="goToNoteEditor(transcription)"></i>
                                        <i matTooltip="Restore Note"
                                            *ngIf="(isVisibleControls && transcription.is_trashed && tranFilters.progress_status == 'Trashed' && (currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER && currentUser.user_type != global.USER_TYPE.WOUND_NURSE))"
                                            matTooltipPosition="above" (click)="restoreTranscription(transcription)"
                                            class="icon icon-undo"></i>
                                        <i matTooltip="Delete" matTooltipPosition="above"
                                            (click)="deleteTranscription(transcription, transcription.audio_id ? 'dict' : 'tran')"
                                            *ngIf="isVisibleButton(transcription, 'deleteButton') && checkVoidCensus(transcription?.rounding_sheet_id)"
                                            class="icon icon-delete"></i>
                                        <ng-container *ngIf="!checkVoidCensus(transcription?.rounding_sheet_id)">
                                            <img width="22px" src="../../../../assets/icons/void_census.png" alt="">
                                        </ng-container>
                                        <i matTooltip="Download" matTooltipPosition="above"
                                            (click)="$event.preventDefault();downloadTranscription_encrypted(transcription)"
                                            *ngIf="isVisibleControls && isVisibleButton(transcription, 'downloadButton')"
                                            class="icon icon-download"></i>

                                    </div>
                                    <div (click)="goToNoteEditor(transcription)" class="flex justify-start items-center gap-4">
                                        <div class="flex gap-4">
                                            <div class="small column-provider" *ngIf="!isInfoExcluded('provider')">
                                                <span>
                                                    {{transcription?.doctor_id_ref?.first_name}}
                                                    {{transcription?.doctor_id_ref?.last_name}},
                                                    {{transcription?.doctor_id_ref?.title}}
                                                </span>
                                            </div>
                                            <div class="small column-patient" *ngIf="!isInfoExcluded('patient')">
                                                <span *ngIf="transcription.patient_id_ref">
                                                    {{transcription.patient_id_ref.first_name + ' ' +
                                                    transcription.patient_id_ref.last_name}}
                                                </span>
                                            </div>
                                            <div class="small column-patient" *ngIf="shouldShowEncounter">
                                                <span>
                                                    {{ (transcription.template_id_ref && transcription.template_id_ref.for_note_type) ? transcription.template_id_ref.for_note_type : '' }}
                                                </span>
                                            </div>
                                            <div class="small column-dos" *ngIf="!isInfoExcluded('dos')"
                                                style="width: 175px;">
                                              
                                                {{getServiceDate(transcription)}}
                                            </div>
                                            <div class="small column-dos">
                                                <ng-container *ngIf="tranFilters.progress_status != 'Trashed'">
                                                    {{transcription.note_status?
                                                    getNoteStatus(transcription.note_status.status) :''}}
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="isVisibleControls && transcription.is_trashed && tranFilters.progress_status == 'Trashed'">
                                                    Trashed
                                                </ng-container>
                                            </div>
                                            <div class="status-col flex" *ngIf="showStatus(transcription)">
                                                <button class="dn-button-primary"
                                                    [class.published]="transcription.is_published === 'true'"
                                                    [class.in-review]="transcription.is_published === 'false' && transcription.is_locked === true"
                                                    style="font-size: 12px; min-width: 108px !important;max-width: 108px;"
                                                    mat-raised-button>
                                                    <span *ngIf="transcription.is_published === 'true'">
                                                        PUBLISHED
                                                    </span>
                                                    <span *ngIf="isQA">
                                                        <span
                                                            *ngIf="transcription.is_signed === 'false' && transcription.is_locked === false
                                      && transcription.is_published === 'false' && transcription.is_completed === 'false'">
                                                            IN PROGRESS
                                                        </span>
                                                        <span
                                                            *ngIf="transcription.is_signed === 'false' && transcription.is_locked === true
                                      && transcription.is_published === 'false' && transcription.is_completed === 'false'">
                                                            SFR
                                                        </span>
                                                    </span>
                                                    <span *ngIf="!isQA">
                                                        <span
                                                            *ngIf="transcription.is_signed === 'false' && transcription.is_locked === false
                                      && transcription.is_published === 'false' && transcription.is_completed === 'false'">
                                                            IN PROGRESS
                                                        </span>
                                                        <span
                                                            *ngIf="transcription.is_signed === 'false' && transcription.is_locked === true
                                      && transcription.is_published === 'false' && transcription.is_completed === 'false'">
                                                            UNDER REVIEW
                                                        </span>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div *ngIf="noteError(transcription)" style="font-size: 11px; color:red;">
                            {{noteError(transcription)}}
                        </div>
                    </div>

                </div>
            </li>
        </ng-container>
    </ul>

    <ng-template matTabContent #dnNotesTemplate>
        <div [class.note-list-height]="isTelehealthReport" class="dn-notes-wrapper">
            <div class="flex flex-grow justify-center items-center" style="width: 100%;" *ngIf="group_index">
                <div style=" text-align: end !important;">
                    <div class="group-index-title">
                        <span>{{group_index !== 'undefined' ? group_index : 'N/A'}}</span>
                    </div>
                </div>
            </div>
            <mat-form-field appearance="fill" style="width: 10%; margin-left: 0.5rem;">

                <mat-label>{{ currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE || (currentUser.user_type ===
                    global.USER_TYPE.VIEW_ONLY_USER && currentUser.company_type === global.COMPANY_TYPE.SNF) ? 'Filter
                    by
                    SNF WC NURSE':'Filter by Provider'}}</mat-label>
                <mat-select [(ngModel)]="provider" (ngModelChange)="handleProviderChange(provider)">
                    <input  placeholder="Search" [(ngModel)]="searchTextProvider"
                        [ngModelOptions]="{standalone: true}" class="dn-notes-search" />
                    <mat-option value="">--All--</mat-option>
                    <mat-option *ngFor="let provider of providers | searchProvider: searchTextProvider"
                        [value]="provider._id" placeholder="Provider">
                        {{provider.first_name}} {{provider.last_name}}, {{provider.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="transcriptions && transcriptions.length > 0 && showSelectAll">
                <div *ngIf="isVisibleSelectAll" class="flex gap-2"
                    style="margin-left: 10px; margin-bottom: 10px;margin-top: 5px; font-size: 13px; color:grey; font-weight: 500;">
                    <mat-checkbox (change)="selectAllTranscriptions($event.checked)" [ngModel]="isCheckedAll">
                    </mat-checkbox>
                    &nbsp; Select All
                </div>
            </div>
            <ul class="transcription-file-block">
                <ng-container *ngFor="let transcription of transcriptions">
                    <li *ngIf="transcription?.empty">
                        <div class="flex-grow">No Notes</div>
                    </li>
                    <li *ngIf="!transcription.filter">
                        <div class="flex" *ngIf="!transcription?.empty && transcription?.is_active == 'true'">
                            <div class="flex-grow-0" style="margin-right: 5px;"
                                *ngIf="isVisibleButton(transcription, 'downloadButton')">
                                <mat-checkbox (change)="toggleTranscriptionMultiSelect.emit(transcription)"
                                    [checked]="isTranscriptionInMultiSelect(transcription)">
                                </mat-checkbox>
                            </div>
                            <div (click)="goToNoteEditor(transcription)" class="flex justify-start items-center gap-5">
                                <div class="flex flex-grow-0 justify-center items-center">
                                    <img class="transcription-icon" *ngIf="transcription.is_signed == 'false'"
                                        src="../../../assets/img/file.png">
                                    <img class="transcription-icon"
                                        *ngIf="transcription.is_signed == 'true' && transcription.is_completed != 'true'"
                                        src="../../../assets/img/signed_file.png">
                                    <img class="transcription-icon"
                                        *ngIf="transcription.is_signed == 'true' && transcription.is_completed == 'true'"
                                        src="../../../assets/img/completed_file.png">
                                </div>
                            </div>
                            <div (click)="goToNoteEditor(transcription)" class="column-title flex flex-grow justify-start items-center">
                                <div>
                                    <div>
                                        {{commonService.cleanTranscriptionTitle(transcription.transcribtion_title) |
                                        uppercase}}
                                    </div>
                                    <div *ngIf="transcription?.audio_id_ref?.audio_title" style="font-size: 11px;">
                                        {{transcription?.audio_id_ref?.audio_title}} |
                                        {{transcription?.audio_id_ref?.audio_length}}
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-grow-0 justify-end items-center">
                                <div class="flex gap-4 justify-end items-center">
                                    <div class="status-buttons flex gap-4" [ngClass]="{'icons-visibility': isTelehealthReport}">
                                        <div *ngIf="isTelehealthReport  && currentUser.user_type != global.USER_TYPE.SNF_WC_NURSE"
                                            class="flex justify-start items-center">
                                            <span *ngIf="transcription.audit.length > 0"
                                                [ngClass]="{'Wound_Report_Uploaded': transcription.audit.length > 0}"
                                                style="font-size:27px;"
                                                class="material-symbols-outlined icon-navy report-icon"
                                                matTooltip="Group Wound Assessment Report">
                                                lab_profile
                                                <ng-container
                                                    *ngIf="transcription.audit.length > 0 && transcription.patient_id_ref.source=='PointClickCare' && transcription.facility_id_ref.source=='PointClickCare'">
                                                    <img class="sub-icon pcc-icon"
                                                        src="../../../assets/icons/pcc-icon.svg" />
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="transcription.audit.length > 0 && transcription.patient_id_ref.source=='MatrixCare'">
                                                    <img class="matrix-icon-wound-report"
                                                        src="../../../assets/icons/matrix.png" alt="">
                                                </ng-container>
                                            </span>
                                            <span *ngIf="transcription.audit.length === 0" [matMenuTriggerFor]="menu"
                                                style="font-size: 27px; margin-right: 0.5rem;"
                                                class="material-symbols-outlined icon-navy report-icon"
                                                matTooltip="Group Wound Assessment Report">
                                                lab_profile
                                            </span>
                                            <mat-menu #menu="matMenu" [class]="'menus-panel'">
                                                <button class="flex justify-start items-center gap-2"
                                                    mat-menu-item
                                                    (click)="generateWoundsReportPreview(transcription,false)">
                                                    <span class="tag-style">Preview Report</span>
                                                </button>
                                                <button class="flex justify-start items-center gap-2"
                                                    mat-menu-item
                                                    (click)="generateWoundsReportPreview(transcription,true)">
                                                    <span class="tag-style">Download Report</span>
                                                </button>
                                                <button
                                                    *ngIf="transcription.patient_id_ref.pcc_patientId && transcription.facility_id_ref.pcc_facId"
                                                    class="flex justify-start items-center gap-2"
                                                    mat-menu-item (click)="uploadWoundReportToPCC(transcription)">
                                                    <span class="tag-style">Upload Report to PCC</span>
                                                </button>
                                            </mat-menu>
                                        </div>

                                        <i matTooltip="View" matTooltipPosition="above" class="icon icon-view"
                                            (click)="goToNoteEditor(transcription)"></i>
                                        <i matTooltip="Restore Note"
                                            *ngIf="isVisibleControls && transcription.is_trashed == 'true'"
                                            matTooltipPosition="above" (click)="restoreTranscription(transcription)"
                                            class="icon icon-undo"></i>
                                        <i matTooltip="Delete" matTooltipPosition="above"
                                            (click)="deleteTranscription(transcription, transcription.audio_id ? 'dict' : 'tran')"
                                            *ngIf="isVisibleButton(transcription, 'deleteButton') && checkVoidCensus(transcription?.rounding_sheet_id) && !isTelehealthReport"
                                            class="icon icon-delete"></i>
                                        <ng-container *ngIf="!checkVoidCensus(transcription?.rounding_sheet_id)">
                                            <img width="22px" src="../../../../assets/icons/void_census.png" alt="">
                                        </ng-container>
                                        <i matTooltip="Download" matTooltipPosition="above"
                                            (click)="$event.preventDefault();downloadTranscription_encrypted(transcription)"
                                            *ngIf="isVisibleControls && isVisibleButton(transcription, 'downloadButton')"
                                            class="icon icon-download"></i>
                                    </div>
                                    <div (click)="goToNoteEditor(transcription)" class="flex justify-start items-center gap-4">
                                        <div class="flex gap-4">
                                            <div class="small column-provider" *ngIf="!isInfoExcluded('provider')">
                                                <span>
                                                    {{transcription?.doctor_id_ref?.first_name}}
                                                    {{transcription?.doctor_id_ref?.last_name}},
                                                    {{transcription?.doctor_id_ref?.title}}
                                                </span>
                                            </div>
                                            <div class="small column-patient" *ngIf="!isInfoExcluded('patient')">
                                                <span *ngIf="transcription.patient_id_ref">
                                                    {{transcription.patient_id_ref.first_name + ' ' +
                                                    transcription.patient_id_ref.last_name}}
                                                </span>
                                            </div>
                                            <div class="small column-patient" *ngIf="shouldShowEncounter">
                                                <span>
                                                    {{ (transcription.template_id_ref && transcription.template_id_ref.for_note_type) ? transcription.template_id_ref.for_note_type : '' }}
                                                </span>
                                            </div>
                                            <div class="small column-dos" *ngIf="!isInfoExcluded('dos')"
                                                style="width: 175px;">
                                                {{getServiceDate(transcription)}}
                                            </div>
                                            <div class="small column-dos">
                                                {{transcription.note_status?
                                                getNoteStatus(transcription.note_status.status) :
                                                ''}}
                                            </div>
                                            <div class="status-col flex" *ngIf="showStatus(transcription)">
                                                <button class="dn-button-primary"
                                                    [class.published]="transcription.is_published === 'true'"
                                                    [class.in-review]="transcription.is_published === 'false' && transcription.is_locked === true"
                                                    style="font-size: 12px; min-width: 108px !important;max-width: 108px;"
                                                    mat-raised-button>
                                                    <span *ngIf="transcription.is_published === 'true'">
                                                        PUBLISHED
                                                    </span>
                                                    <span *ngIf="isQA">
                                                        <span
                                                            *ngIf="transcription.is_signed === 'false' && transcription.is_locked === false
                                                          && transcription.is_published === 'false' && transcription.is_completed === 'false'">
                                                            IN PROGRESS
                                                        </span>
                                                        <span
                                                            *ngIf="transcription.is_signed === 'false' && transcription.is_locked === true
                                                          && transcription.is_published === 'false' && transcription.is_completed === 'false'">
                                                            SFR
                                                        </span>
                                                    </span>
                                                    <span *ngIf="!isQA">
                                                        <span
                                                            *ngIf="transcription.is_signed === 'false' && transcription.is_locked === false
                                                          && transcription.is_published === 'false' && transcription.is_completed === 'false'">
                                                            IN PROGRESS
                                                        </span>
                                                        <span
                                                            *ngIf="transcription.is_signed === 'false' && transcription.is_locked === true
                                                          && transcription.is_published === 'false' && transcription.is_completed === 'false'">
                                                            UNDER REVIEW
                                                        </span>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ng-container>
            </ul>
        </div>
    </ng-template>


    <mat-tab-group *ngIf="forPatientDetailList || isTelehealthReport" (selectedTabChange)="onTabClickCharges($event)">
        <mat-tab label="DN Notes">
            <ng-container *ngTemplateOutlet="dnNotesTemplate"></ng-container>
        </mat-tab>
        <mat-tab label="PCC Notes" *ngIf="patient.pcc_patientId">
            <ng-template matTabContent>
                <app-pcc-notes-list [patient]="patient" (pccNotes)="pccNotes($event)">
                </app-pcc-notes-list>
            </ng-template>
        </mat-tab>
    </mat-tab-group>


</div>