<app-header></app-header>
<div class="container">
    <mat-tab-group style="background-color: white;">

        <mat-tab *ngIf="isProductOwner()" label="All Practices">
            <ng-template matTabContent>
                <app-manage-companies>
                </app-manage-companies>
            </ng-template>
        </mat-tab>

        <mat-tab *ngIf="isFullAdmin()||isProductOwner()" label="My Practices">
            <ng-template matTabContent>
                <app-manage-my-companies>
                </app-manage-my-companies>
            </ng-template>
        </mat-tab>

        <mat-tab *ngIf="isProductOwner()" label="Association">
            <ng-template matTabContent>
                <app-company-assosiation>
                </app-company-assosiation>
            </ng-template>
        </mat-tab>

    </mat-tab-group>

</div>