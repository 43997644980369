<p style="padding: 0 10px;">
    Administration > Route > Coding
</p>
<table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="system">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            System
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.system}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Code
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.code}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="display">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Display
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.display}}
        </td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>