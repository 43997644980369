<app-biller-header *ngIf="currentUser.user_type === global.USER_TYPE.BILLER"></app-biller-header>

<div style="background: #44597d;" class="admin-charge-capture-card" [ngClass]="{'container': currentUser.user_type === global.USER_TYPE.BILLER}">
    <mat-card>
        <mat-card-content>
            <mat-tab-group (selectedTabChange)="onTabClick($event)">
                <mat-tab label="All Charges" >
                    <div class="mat-tab-content" style="min-height: 80vh; background: rgba(45, 62, 91, 0.7); ">
                       
<div style="background-color: #44597d; padding-top: 5px; margin: 5px;" >
    <ng-container>
        <div class=" white-rounded-rect" style="padding: 17px;">
            <div class="flex justify-between">
                <h2 style="color:#000;margin-top:0;margin-bottom:0">Filter</h2>

                <div class="flex gap-2 filter-controls">
                    <button mat-raised-button class="dn-button-primary" (click)="handleApplyFilterClick();">Apply Filter
                    </button>

                    <button mat-raised-button class="dn-button-secondary" (click)="resetFilter();">Reset Filter
                    </button>
                    <div>
                        <button mat-raised-button class="dn-button-primary" [matMenuTriggerFor]="menu">
                            Download Report
                        </button>
                        <mat-menu #menu="matMenu" [class]="'menus-panel'">
                            <button class="flex items-start justify-center gap-2" mat-menu-item
                                (click)="downloadReport('grouped',false,'')">
                                <span class="tag-style">Grouped Report</span>
                            </button>
                            <button class="flex items-start justify-center gap-2" mat-menu-item
                                (click)="downloadReport('full',false,'xlsx')">
                                <span class="tag-style">Full Report</span>
                            </button>
                            <button class="flex items-start justify-center gap-2" mat-menu-item (click)="downloadReport('full',false,'csv')">
                                <span class="tag-style">DN CSV Report</span>
                            </button>
                            <button class="flex items-start justify-center gap-2" mat-menu-item (click)="downloadReport('full', true,'')">
                                <span class="tag-style">Full Report Email</span>
                            </button>
                            <button class="flex items-start justify-center gap-2" mat-menu-item
                                (click)="downloadJSONReport()">
                                <span class="tag-style">JSON Report</span>
                            </button>
                        </mat-menu>
                    </div>
                    <button mat-raised-button class="dn-button-primary" (click)="downloadBatchReport()">Batch FaceSheet
                        Download</button>
                        <div>
                            <button *ngIf="selectedCharges.length > 0" mat-raised-button class="dn-button-primary"
                                [matMenuTriggerFor]="selectedChargeMenu" style="margin-right: 5px;">Bill Selected Charges</button>
                            <button *ngIf="selectedCharges.length > 0" mat-raised-button class="dn-button-primary"
                                (click)="resubmitCharges()">Resubmit</button>
                            <mat-menu #selectedChargeMenu="matMenu" [class]="'menus-panel'">
                                <button class="flex items-start justify-center gap-2" mat-menu-item (click)="billedAllCharges('');">
                                    <span class="tag-style">None</span>
                                </button>
                                <button class="flex items-start justify-center gap-2" mat-menu-item
                                    (click)="billedAllCharges('billed');">
                                    <span class="tag-style">Billed</span>
                                </button>
                                <button class="flex items-start justify-center gap-2" mat-menu-item
                                    (click)="billedAllCharges('coded');">
                                    <span class="tag-style">Coded</span>
                                </button>
                                <button class="flex items-start justify-center gap-2" mat-menu-item
                                    (click)="billedAllCharges('addendum_req');">
                                    <span class="tag-style">Addendum REQ</span>
                                </button>
                            </mat-menu>
                        </div>

                </div>
            </div>
            <div class="flex gap-2">

                <div class="flex justify-start items-baseline input-with-cross">
                    <div class="flex flex-col justify-start items-baseline" style="margin-right: 15px;">
                        <mat-form-field appearance="fill" class="status-select">
                            <mat-label>Date Filter</mat-label>
                            <mat-select name="dateFilter" [(value)]="dateFilter" 
                                (selectionChange)="handleApplyFilterClick()">
                                
                                <mat-option  value="dos">Date of Service</mat-option>
                                <mat-option  value="doc">Date of Creation</mat-option>
                                <mat-option  value="dou">Last Updated</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <input *ngIf="this.serviceDateFilter" type="text" ngxDaterangepickerMd
                           [(ngModel)]="filter.date_of_service" (datesUpdated)="changeServiceDate($event)"
                           class="mat-like-input" [ranges]="preDateRanges" [alwaysShowCalendars]="true"
                           [maxDate]="this.c_date" [linkedCalendars]="true" [showClearButton]="true" />
                </div>
                <div class="flex justify-start items-baseline charges-provider-field">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Select Provider</mat-label>
                        <input type="text" placeholder="Select Provider" aria-label="provider" matInput
                               [formControl]="providerControl" [matAutocomplete]="providerAutoControl" (ngModelChange)="checkProvider()"
                               (click)="getProviderList()">
                        <mat-autocomplete #providerAutoControl="matAutocomplete">
                            <mat-option *ngFor="let provider of filteredProviders | async" [value]="provider.full_name"
                                        (click)="onSelectProvider(provider);">
                                {{provider.full_name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <button  style="height:10px;"  *ngIf="getProvControlStatus()" mat-button mat-icon-button matSuffix (click)="clearProviderFilter()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            
                    <mat-form-field appearance="fill" class="charges-provider-field">
                        <div class="flex justify-start items-center">
                            <img *ngIf="showPccLogo" src="../../../assets/icons/pcc-icon.svg" width="20px" height="20px" alt="">
                            <img src="../../../../assets/icons/matrix.png" *ngIf="showMatrixLogo"
                                 style="height: 12px;width: 20px;  margin-right: 5px;vertical-align: middle;" alt="">
                            <img src="../../../../assets/img/DN.png" *ngIf="showDnLogo" class="dn-icon" alt="">
                            <input type="text" placeholder="Select Facility" aria-label="facility" matInput
                                   [formControl]="facilityControl" [matAutocomplete]="facilityAutoControl"
                                   [(ngModel)]="facilityModel" (ngModelChange)="removeFacility($event);" (click)="reSelectFacility()">
                            <mat-autocomplete #facilityAutoControl="matAutocomplete" autoActiveFirstOption
                                panelWidth="auto">
                                <mat-option *ngIf="currentUser.user_type === global.USER_TYPE.BILLER"
                                            (click)="onSelectFacility()" [value]="All">All</mat-option>
                                <mat-option *ngFor="let facility of filteredFacilities | async" [value]="facility.title"
                                            (click)="onSelectFacility(facility);">
                                    <app-facility-title [facility]="facility"></app-facility-title>
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                    </mat-form-field>
            
                <div class="flex justify-start items-center">
                    
                <ng-multiselect-dropdown  #codes style="width:200px;"
                                              name="codes"
                                              [placeholder]="'Select CPT codes'"
                                              [settings]="cptCodesDropdownSettings"
                                              [data]="cpts"
            
                                              [(ngModel)]="selectedCptCodes"
                                              (onSelect)="onCodeSelect($event); "
                                              (onDeSelect)="onCodeDeSelect($event);">

                    </ng-multiselect-dropdown>

                </div>

            
                <mat-form-field *ngIf="showPatientsSearch" appearance="fill" style="align-self: flex-end;">
                    <mat-label>Search by Patient Name</mat-label>
                    <input matInput [(ngModel)]="searchText" (keyup)="applyFilter()">
                </mat-form-field>
                <div class="flex justify-between items-center gap-1">
            
                    <mat-form-field appearance="fill" class="dropdown">
                        <mat-label>Charge Status</mat-label>
                        <mat-select name="status" [(ngModel)]="chargeStatus" #statusValue
                                    (selectionChange)="onSelectStatus($event.value)">
                            <mat-option value="default">
                                All
                            </mat-option>
                            <mat-option value="submit">Submitted
                            </mat-option>
                            <mat-option value="draft">Draft
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
            
                    <mat-form-field appearance="fill" class="dropdown" *ngIf="filter.status === 'submit'">
                        <mat-label>Bill Status</mat-label>
                        <mat-select [value]="filter.billStatus" (selectionChange)="onSelectBillStatus($event.value)">
                            <mat-option value="all">
                                All
                            </mat-option>
                            <mat-option value="">
                                None
                            </mat-option>
                            <mat-option value="billed">
                                Billed
                            </mat-option>
                            <mat-option value="coded">
                                Coded
                            </mat-option>
                            <mat-option value="addendum_req">
                                Addendum REQ
                            </mat-option>
                            <mat-option value='on_hold'>
                                On Hold
                            </mat-option>
                            <mat-option value='missed_visit'>
                                Missed Visit
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
            
                    <mat-form-field appearance="fill" class="dropdown" *ngIf="filter.facility_id">
                        <mat-label>Charges View</mat-label>
                        <mat-select name="status" [(ngModel)]="chargeView" #groupedViewValue
                                    (selectionChange)="onSelectView($event.value)">
                            <mat-option value="group">Grouped
                            </mat-option>
                            <mat-option value="flat">Flat
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
            
                </div>
            
            </div>
                                           
        </div>
        <div class="br-spacing"></div>
        <div *ngIf="chargeView=='group'" class="flex justify-center white-rounded-rect" style="padding:17px">
            <div class="flex justify-between items-center w-full">
                <div class="flex flex-col items-center justify-center">
                    <div class="flex items-center justify-start">
                        <strong style="font-weight: 500;">Date of Service
                        </strong>
                    </div>
                    <div class="flex items-center justify-start">
                        <span>-
                        </span>
                    </div>

                </div>
                <div class="flex flex-col items-center justify-center">
                    <div class="flex items-center justify-start">
                        <strong style="font-weight: 500;">Creation Date
                        </strong>
                    </div>
                    <div class="flex items-center justify-end">
                        <span>
                            {{filter?.creation_date?.startDate | date}} - {{this.filter?.creation_date?.endDate | date}}
                        </span>
                    </div>
                </div>
                <div class="flex flex-col items-center justify-center">
                    <strong style="font-weight: 500;">Provider</strong>
                    <div class="flex items-center justify-start">
                        <span>
                            {{ fitlerButtonClicked && provider ? provider?.first_name : null}}
                            {{ fitlerButtonClicked && provider ? provider?.last_name : null}}
                        </span>
                    </div>
                </div>
                <div class="flex flex-col items-center justify-center">
                    <strong style="font-weight: 500;">Facility</strong>
                    <div class="flex items-center justify-start">
                        <span>
                            {{ fitlerButtonClicked && facility ? facility?.title : null}}
                        </span>
                    </div>
                </div>
                <div class="flex flex-col items-center justify-center">
                    <strong style="font-weight: 500;">Charge Status</strong>
                    <div class="flex items-center justify-start">
                        <span>-
                        </span>
                    </div>
                </div>
                <div class="flex flex-col items-center justify-center">
                    <span *ngIf="!loading">Total
                        <br>
                        {{totalRecords}}
                    </span>
                    <span *ngIf="loading">Total
                        <br>
                        loading...
                    </span>
                </div>
                <div class="flex flex-col items-center justify-center">
                    <button *ngIf="!isVisibleTable && totalRecords!==0" mat-raised-button
                        (click)="isVisibleTable=true; isVisibleDetailTable=false; showPatientsSearch=false; searchText=''" class="dn-button-primary">View
                    </button>
                    <button *ngIf="isVisibleTable && totalRecords!==0" mat-raised-button (click)="isVisibleTable=false"
                        class="dn-button-secondary">Hide
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="chargeView=='group'" class="br-spacing"></div>
    </ng-container>

    <div class="flex flex-col gap-2" *ngIf="chargeView=='flat'">
        <app-show-charges-table *ngIf="flatcharges" 
        [company_id]="company_id"
        [chargesArray]="flatcharges"
        [chargeFilter]="filter"
        (searchPatient)="initFlatCharges();"
        [pagination]="pagination"
        (operationInChildComponent)="operationInChildComponent()">
    </app-show-charges-table>
    </div>
    <div class="flex flex-col gap-2" *ngIf="isVisibleDetailTable&&chargeView=='group'">
        <button mat-raised-button class="dn-button-primary" style="align-self: start;"
            (click)="isVisibleDetailTable=false; isVisibleTable=true; showPatientsSearch=false; searchText=''">Go
            Back
        </button>
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort (matSortChange)="sortData($event)">
        
            <ng-container matColumnDef="checkbox">

                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox #myCheckbox (change)="selectChargeAll($event);"></mat-checkbox>
                    Select All
                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-checkbox (change)="selectCharge(element, $event);" [checked]="selectedCharge(element)">
                    </mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="date_of_creation">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Date of Creation</th>
                <td mat-cell *matCellDef="let element">
                    {{getDateOfCreation(element)}}
                </td>
            </ng-container>


            <ng-container matColumnDef="Expansion Icon">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
                <td mat-cell *matCellDef="let element" style="width: 0px;">
                    <button mat-icon-button aria-label="expand row" (click)="expandedElementId = expandedElementId === element._id ? null : element._id;
                                toggleRow(element._id); accordianTabIndex = 0;">
                        <mat-icon *ngIf="expandedElementId !== element._id">keyboard_arrow_down</mat-icon>
                        <mat-icon *ngIf="expandedElementId === element._id">keyboard_arrow_up</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="patient_name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Patient </th>
                <td mat-cell *matCellDef="let element">
                    {{element?.patient_id?.first_name}}
                    {{element?.patient_id?.last_name}}
                </td>

            </ng-container>

            <ng-container matColumnDef="facility_title">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Facility </th>
                <td mat-cell *matCellDef="let element">
                    <img src="../../../../assets/icons/pcc-icon.svg" *ngIf="isPCCFacilityDetails(element)"
                        class="pcc-icon" alt="">
                    <img src="../../../../assets/icons/matrix.png" *ngIf="isMatrixFacilityDetails(element)" style="height: 12px;
                                                                                                        width: 20px;  
                                                                                                        margin-right: 5px;
                                                                                                        vertical-align: middle;"
                        alt="">
                    <img src="../../../../assets/img/DN.png" *ngIf="isDNFacilityDetails(element)" class="dn-icon"
                        alt="">
                    {{element?.facility_id_ref?.title}}
                </td>

            </ng-container>

            <ng-container matColumnDef="facility_state">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> State </th>
                <td mat-cell *matCellDef="let element">
                    {{ element && element.facility_id_ref && element.facility_id_ref.state >= 0 && usa_states[element.facility_id_ref.state] ? usa_states[element.facility_id_ref.state].abbreviation : '' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="provider_name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Provider </th>
                <td mat-cell *matCellDef="let element">
                    {{element?.owner_id?.first_name}}
                    {{element?.owner_id?.last_name}}

                </td>

            </ng-container>

            <ng-container matColumnDef="census_name">
                <th mat-header-cell *matHeaderCellDef> Census </th>
                <td mat-cell *matCellDef="let element">
                    {{element?.rounding_sheet_id?.name}}
                </td>

            </ng-container>

            <ng-container matColumnDef="dos">
                <th mat-header-cell *matHeaderCellDef> DOS </th>
                <td mat-cell *matCellDef="let element">
                    {{getVisitDate(element)}}
                </td>
            </ng-container>

            <ng-container matColumnDef="dob">
                <th mat-header-cell [hidden]="currentUser.user_type !== global.USER_TYPE.BILLER" *matHeaderCellDef> DOB
                </th>
                <td mat-cell [hidden]="currentUser.user_type !== global.USER_TYPE.BILLER" *matCellDef="let element">
                    {{element?.patient_id?.date_of_birth}}
                </td>
            </ng-container>

            <ng-container matColumnDef="updatedAt">
                <th mat-header-cell *matHeaderCellDef> Updated At </th>
                <td mat-cell *matCellDef="let element">
                    {{getDateOfUpdation(element) }}
                </td>
            </ng-container>

            <ng-container matColumnDef="progress_status">
                <th mat-header-cell *matHeaderCellDef> Charge Status </th>
                <td mat-cell *matCellDef="let element">
                    {{element?.status === 'submit' ? 'submitted': element?.status}}
                </td>
            </ng-container>

            <ng-container matColumnDef="bill_status">
                <th mat-header-cell *matHeaderCellDef> Billed Status </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field appearance="fill" class="dropdown">
                        <mat-select name="bill_status" [(ngModel)]="element.bill_status"
                            (selectionChange)="billedAllCharges($event.value,element)">
                            <mat-option value="">
                                None
                            </mat-option>
                            <mat-option value="billed">
                                Billed
                            </mat-option>
                            <mat-option value="coded">
                                Coded
                            </mat-option>
                            <mat-option value="addendum_req">
                                Addendum REQ
                            </mat-option>
                            <mat-option value='on_hold'>
                                On Hold
                            </mat-option>
                            <mat-option value='missed_visit'>
                                Missed Visit
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="face_sheet">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Face Sheet</th>
                <td mat-cell *matCellDef="let element; let index=index;" style="cursor: pointer;">
                    <div class="flex gap-1">
                        <button mat-icon-button matTooltip="Upload Face Sheet" 
                        (click)="handleUploadFacesheetClick(element, index)">
                            <mat-icon>file_upload</mat-icon>
                        </button>

                        <button mat-icon-button matTooltip="Downlaod Face Sheet" 
                        [disabled]="!downloadFaceSheetActive(element, index)"
                        (click)="handleDownloadFacesheetClick(element, index)">
                            <mat-icon>file_download</mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="View Face Sheet" [disabled]="!downloadFaceSheetActive(element, index)"
                            (click)="viewPDF(element)">
                            <mat-icon>visibility</mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="audit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Audit</th>
                <td mat-cell *matCellDef="let element; let index=index;" style="cursor: pointer;">
                    <div style="color: blue;" (click)="auditClicked(element)">view</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="eas">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip=" Editted After submission">EAS</th>
                <td mat-cell *matCellDef="let element" [ngClass]="{
                    'yes' : element.edited_after_submission,
                    'no' : !element.edited_after_submission }">  
                    {{getEASValue(element) }} 
                </td>
            </ng-container>

            <ng-container matColumnDef="biller_note">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Biller Note</th>
                <td mat-cell *matCellDef="let element; let index=index;" style="cursor: pointer;">
                    <div *ngIf="element.note!=''" style="color: blue;" (click)="viewBillerNote(element.note)">view</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="operation">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element;let index=index">
                    <div class="flex gap-2">
                        <i class="icon icon-file" matTooltip="View Note" matTooltipPosition="above" (click)=" viewNoteDialog(element)"></i>
                        <i class="icon icon-view" matTooltip="Details" matTooltipPosition="above"
                            style="cursor: pointer;" (click)=" openDialog(element);">
                        </i>
                        <i class="icon icon-delete" matTooltip="Delete" matTooltipPosition="above"
                            *ngIf="element.status == 'draft'" (click)="onDeleteClick(element,index)">
                        </i>
                        <i *ngIf = "element.status == 'submit' && checkVoidCensus(element.rounding_sheet_id)"
                         class="icon icon-edit" matTooltip="Edit Charge" matTooltipPosition="above"
                            (click)="editCharge(element);" style="cursor: pointer;">
                        </i>
                        <mat-icon style="cursor: pointer;" matTooltip="Comment" matTooltipPosition="above" (click)="openChargeCommentDialog(element, index);">comment</mat-icon>
                        <div *ngIf="!checkVoidCensus(element.rounding_sheet_id)">
                            <img width="22px" src="../../../../assets/icons/void_census.png" alt="">
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="accordion">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="accordion-cell"
                        [@accordionTrigger]="element._id === expandedElementId ? 'expanded' : 'collapsed'">
                        <mat-tab-group *ngIf="expandedRows.includes(element._id);" class="expanded-child"
                            [(selectedIndex)]="accordianTabIndex" (selectedTabChange)="onTabChange($event);">
                            <mat-tab label="Patient Notes">
                                <app-patient-detail-list *ngIf="accordianTabIndex === 0" [patient]="element.patient_id"
                                    [isTelehealthReport]="true"></app-patient-detail-list>
                            </mat-tab>
                            <mat-tab label="Patient Documents">
                                <app-patient-documents-list *ngIf="accordianTabIndex === 1"
                                    [patient]="element.patient_id"></app-patient-documents-list>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['accordion']" class="accordion-row"></tr>
        </table>
    </div>

    <div class="flex flex-col gap-2" *ngIf="isVisibleTable&&chargeView=='group'">
        <div class="notes-group-dateofservice" *ngFor="let dateGroup of charges">
            <div class="flex flex-col gap-2">
                <div class="group-index-title">
                    <span>{{dateGroup._id| date:'MM/dd/yyyy'}}</span>
                </div>
                <div class="card">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="left" style="width:23.3%">Provider</th>
                                <th class="left" style="width:20%">Facility</th>
                                <th class="left" style="width:5%">State</th>
                                <th class="left" style="width:23.3%">Census</th>
                                <th class="left" style="width:10%">Count</th>
                                <th class="left" style="width:10%">Charge Status</th>
                                <th class="left" style="width:10%">Billed Status</th>
                                <th class="left" style="width:10%">Operations</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of dateGroup.groupedData">
                                <td>
                                    {{data?.chargeList?.user?.first_name}}
                                    {{data?.chargeList?.user?.last_name}}, {{data?.chargeList?.user?.title}}
                                </td>
                                <td (click)="onFacilityClick(data)"
                                    onmouseover="this.style.color='blue'; this.style.cursor='pointer'; this.style.textDecoration='underline'"
                                    onmouseout="this.style.color=''; this.style.cursor='default';this.style.textDecoration='none';">
                                    <img src="../../../../assets/icons/pcc-icon.svg" *ngIf="isPCCFacility(data.chargeList.facility?.source)"
                                        class="pcc-icon" alt="">
                                    <img src="../../../../assets/icons/matrix.png" *ngIf="isMatrixFacility(data.chargeList.facility?.source)" style="height: 12px;
                                                                                                            width: 20px;  
                                                                                                            margin-right: 5px;
                                                                                                            vertical-align: middle;" alt="">
                                    <img src="../../../../assets/img/DN.png" *ngIf="isDNFacility(data.chargeList.facility?.source)" class="dn-icon"
                                        alt="">
                                    {{data?.chargeList?.facility?.title}}
                                </td>
                                <td>
                                    {{ data && data.chargeList && data.chargeList.facility && data.chargeList.facility.state >= 0 && usa_states[data.chargeList.facility.state] ? usa_states[data.chargeList.facility.state].abbreviation : '' }}
                                </td>
                                <td>
                                    {{data?.chargeList?.census?.name}}
                                </td>

                                <td>
                                    {{data?.chargeList?.count}}
                                </td>

                                <td>
                                    {{data?.chargeList?._id?.status === 'submit' ? 'submitted' :
                                    data?.chargeList?._id?.status}}
                                </td>
                                <td>
                                    {{data?.chargeList?._id?.bill_status}}
                                </td>
                                <td class="left">
                                    <div class="flex gap-2">
                                        <i class="icon icon-view" matTooltip="Details" matTooltipPosition="above"
                                            (click)="initSoloGroupCharge(data);">
                                        </i>
                                    </div>
                                </td>

                            </tr>

                            <tr style="background-color: #44597d;color: white; ">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <b style="float: right;">
                                        Total Count: {{totalCount(dateGroup.groupedData)}}
                                    </b>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
    </div>
        </mat-tab>


            <mat-tab label="Search by Patient">
                    <div class="mat-tab-content">
                            <app-biller-charges-admin [searchBy]="p"></app-biller-charges-admin>
                    </div>
                
            </mat-tab>
            <mat-tab *ngIf="global.ENVIRONMENT === this.global.LOCAL || global.ENVIRONMENT === this.global.DEV || global.ENVIRONMENT === this.global.APP" label="Search by FaceSheet">
                    <div class="mat-tab-content">
                            <app-biller-charges-admin [searchBy]="f"></app-biller-charges-admin>
                    </div>
            </mat-tab>
        </mat-tab-group>
        </mat-card-content>
    </mat-card>
</div>
