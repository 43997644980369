<div class="container">
    <mat-card>
        <mat-card-content>
            <mat-tab-group [selectedIndex]='defaultTabIndex' (selectedTabChange)="changeCurrentTab($event)">

                <mat-tab label="Profile">
                    <div class="mat-tab-content">
                        <app-company-facility-form ></app-company-facility-form>
                    </div>
                </mat-tab>

                <mat-tab label="Permissions" *ngIf="company.portal_type === global.PORTAL_TYPE.WC">
                    <div class="mat-tab-content">
                        <app-company-facility-permissions ></app-company-facility-permissions>
                    </div>
                </mat-tab>
                <mat-tab label="Contact" *ngIf="company.portal_type === global.PORTAL_TYPE.WC">
                    <div class="mat-tab-content">
                        <app-company-facility-contact ></app-company-facility-contact>
                    </div>
                </mat-tab>

                <mat-tab label="Settings">
                    <div class="mat-tab-content">
                        <app-facility-settings ></app-facility-settings>
                    </div>
                </mat-tab>

            </mat-tab-group>
        </mat-card-content>
    </mat-card>
</div>