<div  class="flex flex-col">
    <mat-card>
        <mat-card-content>
            <div class="flex flex-col gap-2" style="background: white; padding:1px 5px 10px 5px; border-radius: 10px; 
            justify-content: space-evenly !important; align-content: center;">
                <ngx-ui-loader loaderId="loader-01"></ngx-ui-loader>
                <ngx-ui-loader loaderId="loader-02"></ngx-ui-loader>
                <ngx-ui-loader loaderId="loader-03"></ngx-ui-loader>

                <form [formGroup]="filterFormGroup" class="flex justify-around gap-7"
                    style="height: 100%;">

                    <div class="flex flex-col justify-around gap-5" style="width: 90%;">
                        <div class="flex justify-around gap-4">


                            <mat-form-field class="w-full">
                                <mat-select placeholder="DN Facilities" formControlName="facility"
                                    (selectionChange)="facilityChange($event.value)"  class="font-sizes-family">
                                    <mat-option value="" class="font-sizes-family">DN Facilities</mat-option>
                                    <input placeholder="Search" [(ngModel)]="searchText2"
                                        [ngModelOptions]="{standalone: true}"
                                        class="select-input-class"
                                        (keydown)="$event.stopPropagation()"  >
                                    <mat-option *ngFor="let facility of facilities | searchFacility : searchText2"
                                        [value]="facility"  >
                                        <app-facility-title [facility]="facility" [defaultSource]="PointClickCare">
                                        </app-facility-title>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="w-full">
                                <div class="flex justify-start items-center">
                                <img src="../../../assets/icons/pcc-icon.svg" *ngIf="showPccLogo" width="15px" height="15px"
                                alt="">
                                <mat-select placeholder="PCC Facilities" formControlName="pccfacility"
                                    (selectionChange)="pccfacilityChange($event.value)" class="font-sizes-family">
                                    <mat-option value="" class="font-sizes-family">PCC Facilities</mat-option>
                                    <input  placeholder="Search" [(ngModel)]="searchText"
                                        [ngModelOptions]="{standalone: true}"
                                        class="select-input-class"
                                        (keydown)="$event.stopPropagation()">
                                    <mat-option *ngFor="let facility of pccfacilities | searchFacility : searchText"
                                        [value]="facility">
                                        <app-facility-title [facility]="facility" [defaultSource]="PointClickCare">
                                        </app-facility-title>
                                    </mat-option>
                                </mat-select>
                            </div>
                            </mat-form-field>

                            <button class="dn-button-primary" [disabled]="!(facility_one && facility_two)" style=" flex: 10%; margin-left: 15px; min-width: auto;"
                                (click)="fetchAll()" mat-raised-button>Match All
                            </button>

                            <button class="dn-button-primary" [disabled]="!(facility_one && facility_two)" style=" flex: 10%; margin-left: 5px; min-width: auto;"
                                (click)="mergeAll()" mat-raised-button>Merge All
                            </button>

                            <button class="dn-button-secondary" [disabled]="!(facility_one && facility_two)" style=" flex: 10%; margin-left: 5px; min-width: auto; white-space: nowrap;"
                                (click)="revertAll()" mat-raised-button>Revert All
                            </button>

                            <button class="dn-button-secondary" style=" flex: 10%; margin-left: 5px; min-width: auto; white-space: nowrap;"
                                (click)="clearAll()" mat-raised-button>Clear All
                            </button>

                        </div>

                        <div class="flex flex-col items-start justify-baseline gap-5"
                            style="overflow-y: auto;">
                            <table mat-table [dataSource]="dataSource">

                                <ng-container matColumnDef="dn">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Patients From DN Facility
                                    </th>
                                    <td mat-cell *matCellDef="let item">
                                        {{item?.dn?.first_name+' '+item?.dn?.last_name+' ( '+item?.dn?.date_of_birth+'
                                        )'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="sign">
                                    <th mat-header-cell *matHeaderCellDef>
                                        To
                                    </th>
                                    <td mat-cell *matCellDef="let item">
                                        {{'->'}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="pcc">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Patients From PCC API
                                    </th>
                                    <td mat-cell *matCellDef="let item">
                                        <div *ngIf="item?.pcc&&item?.dob_matched" style="color: rgb(109, 168, 91);">
                                            {{item?.pcc?.firstName+' '+
                                            item?.pcc?.lastName+' ( '+
                                            item?.pcc?.birthDate+'
                                            )'}}
                                        </div>
                                        <div *ngIf="item?.pcc&&!item?.dob_matched" style="color: rgb(114, 35, 35);">
                                            {{item?.pcc?.firstName+' '+
                                            item?.pcc?.lastName+' ( '+
                                            item?.pcc?.birthDate+'
                                            )'}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="import">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Is Imported Already
                                    </th>
                                    <td mat-cell *matCellDef="let item">
                                        <img src="../../../assets/icons/pcc-icon.svg" *ngIf="item?.dn?.pcc_patientId"
                                            class="pcc-icon" alt="">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Merge Status
                                    </th>
                                    <td mat-cell *matCellDef="let item">
                                        <img style="width: 14px;" src="../../../assets/img/green_dot.png"
                                            *ngIf="item?.mergingDone" alt="">
                                        <img style="width: 14px;" src="../../../assets/img/red_dot.png"
                                            *ngIf="!item?.mergingDone" alt="">
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Actions
                                    </th>
                                    <td mat-cell *matCellDef="let item">

                                        <button *ngIf="((facility_one&&facility_two))&&(!item.pcc)"
                                            class="dn-button-primary"
                                            style=" flex: 10%; margin-left: 1px; min-width: auto;"
                                            (click)="fetchPcc(item)" mat-raised-button>Match
                                        </button>

                                        <button *ngIf="((facility_one&&facility_two))&&(!item.pcc)"
                                            class="dn-button-primary"
                                            style=" flex: 10%; margin-left: 15px; min-width: auto;"
                                            (click)="dialogToGetInputManual(item)" mat-raised-button>Search
                                        </button>

                                        <button *ngIf="(item.pcc)" [disabled]="!(item?.check)" class="dn-button-primary"
                                            style=" flex: 10%; margin-left: 1px; min-width: auto;"
                                            (click)="mergeOne(item); " mat-raised-button>Merge
                                        </button>

                                        <button *ngIf="(item.pcc)" [disabled]="(item?.check)"
                                            class="dn-button-secondary"
                                            style=" flex: 10%; margin-left: 15px; margin-right: 20px; min-width: auto;"
                                            (click)="revertOne(item);" mat-raised-button>Revert
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </div>
                        <mat-paginator [pageSizeOptions]="[15, 25, 50]" showFirstLastButtons></mat-paginator>

                    </div>

                </form>
            </div>
        </mat-card-content>
    </mat-card>
</div> 