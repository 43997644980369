import { Component, Input, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { FacilityService } from 'src/app/services/facility.service';

@Component({
  selector: 'app-pcc-account-permissions',
  templateUrl: './pcc-account-permissions.component.html',
  styleUrls: ['./pcc-account-permissions.component.css']
})
export class PccAccountPermissionsComponent implements OnInit {

  @Input() user;
  displayedColumns: string[]; // = ['Permission', 'Description'];
  scopesMap: {[key: string]: string[]} = {};
  facilities: any = [];
  constructor(private facilityService: FacilityService) {
   }

  ngOnInit(): void {
    this.scopesMap = this.getPrivilegeScopesMap();
    const actions = Object.keys(this.scopesMap);
    this.displayedColumns = ['Scope', ...actions];
    if(this.user) {
      this.initFacilities();
    }
  }
  
  async initFacilities() {
    if(this.user.pcc_account?.orgUuid) {
      const resposne:any = await lastValueFrom(this.facilityService.getFilteredFacilities(this.user.company_id, this.user.pcc_account.orgUuid));
      if(resposne.status === 200) {
        this.facilities = resposne.data;
      }
    }
  }

  getPrivilegeScopes() {
    if(!this.user?.pcc_account?.privilegeScopes) {
      return [];
    }
    return this.user.pcc_account.privilegeScopes;
  }

  getPrivilegeScopesObjects() {
    /**
      * Structure of privilegeScopes: Array of strings, convert to array of objects like {operation: 'read', feature: 'patient'}
     */
    const scopesData = this.getPrivilegeScopes().map(scope => {
      const [_, operation] = scope.split('.');
      const [userLabel, scopeLabel] = _.split('/');
      return {operations: [operation], scopeLabel, userLabel};
    });

    // remove duplicates and merge operations
    const uniqueScopes = new Set(); 
    scopesData.forEach(scope => {
      if(!uniqueScopes.has(scope.scopeLabel)) {
        uniqueScopes.add(scope.scopeLabel);
      } else {
        const existingScope = scopesData.find(s => s.scopeLabel === scope.scopeLabel);
        existingScope.operations = [...existingScope.operations, ...scope.operations];
      }
    });
    return Array.from(uniqueScopes).map(scope => {
      const scopeData = scopesData.find(s => s.scopeLabel === scope);
      return {
        scopeLabel: scopeData.scopeLabel,
        userLabel: scopeData.userLabel,
        operations: scopeData.operations
      }
    });
  }

  getKeys() {
    const uniqueOperations = new Set();
    this.getPrivilegeScopes().forEach(scope => {
      const [_, operation] = scope.split('.');
      uniqueOperations.add(operation);
    });

    return Array.from(uniqueOperations);
  }

  getPrivilegeScopesMap() {
    return this.getPrivilegeScopes().reduce((acc, scope) => {
      const [_, operation] = scope.split('.');
      const [userLabel, feature] = _.split('/');
      if(!acc[operation]) {
        acc[operation] = [];
      }
      acc[operation].push(feature);
      return acc
    }, {});
  }
  describePermission(permission:string) : string {
    const [userLabel, permissionName] = permission.split('/');
    const [feature, operation] = permissionName.split('.');

    // feature is camelCase, so we need to add spaces between words and capitalize the first letter
    const featureLabel = feature.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());

    return `${userLabel} can ${operation} ${featureLabel}`;
  }

  formatScopeLabel(scope:string) {
    return scope.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
  }
}
