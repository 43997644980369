import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PCCService } from 'src/app/services/pcc.service';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../global';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from "@angular/material/sort";
import { DashboardService } from '../dashboard/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { TranscriptionCreateService } from '../transcription-create/transcription-create.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PatientImporterDialog } from '../patient-rounding-sheets/patient-importer-dialog/patient-importer-dialog';
import { Location } from '@angular/common';
import { NoteEditorService } from '../note-editor/note-editor.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { fromEvent, lastValueFrom, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { PCCPatientListService } from './pcc-patient-list.service';
import moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { PatientImporterRecurrentComponent } from '../patient-rounding-sheets/patient-importer-recurrent/patient-importer-recurrent.component';
import { SnfWcNurseAssociationService } from '../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { WoundNurseRelationService } from 'src/app/services/Modules/woundNurse.service';

export type Pagination = {
  hasNextPage: boolean;
  hasPrevPage: boolean;
  page: number;
  pageSize: number;
}
@Component({
  selector: 'app-pcc-patient-list',
  templateUrl: './pcc-patient-list.component.html',
  styleUrls: ['./pcc-patient-list.component.css'],
})
export class PccPatientListComponent implements OnInit, OnDestroy {
  filterFormGroup: FormGroup;

  global = global;
  facilities: any[] = [];
  patients: any[] = [];
  filteredPatients: any[] = [];
  isSelected: boolean = false;
  displayedColumns: string[] = [
    'checkbox',
    'name',
    'date_of_birth',
    'location',
    'admission_date',
    'payer',
    // 'discharge_date',
    'status',
    'last_seen',
    'operations',
  ];
  dataSource: any;
  filterDropdownActive = true;
  // activePatientStatuses:string[] = [];
  currentUser;
  associatedProviders: any[] = [];
  selectedProviderId: string = '';
  @ViewChild(MatSort) sort: MatSort;

  public pagination: Pagination;

  patientStatuses = ['All', 'Current', 'New', 'Discharged'];
  // patientStatuses = ['All', 'New', 'Discharged'];
  loaderId = 'patient-list-loader';

  // @ViewChild('patientSearchInput') patientSearchInput: ElementRef;
  patientsLoaded = false;

  loadPageEventEmitter: EventEmitter<void> = new EventEmitter();
  loadPageEventEmitterSub: any;

  selectedPatients: any[] = [];
  public pccUserInfo;
  isLoading: boolean = false;
  // pccDefaultFacility = this.pccService.defaultFac;

  error2LeggedAuth;
  searchText: any;
  constructor(
    public pccService: PCCService,
    private _authService: AuthService,
    private _dashboardService: DashboardService,
    private _noteEditorService: NoteEditorService,
    private toastr: ToastrService,
    public _commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private _transcriptionCreateService: TranscriptionCreateService,
    private location: Location,
    private loader: NgxUiLoaderService,
    private pccPatientListService: PCCPatientListService,
    public _pccService: PCCService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
    private woundNurseService : WoundNurseRelationService
  ) {
    this.currentUser = this._authService.currentUser;
    // this.pccService.defaultFac$.subscribe(fac => this.pccDefaultFacility = fac);
    this.pccService.pccUserinfo$.subscribe(pccUserInfo => this.pccUserInfo = pccUserInfo);

  }
  ngOnDestroy(): void {
    this.loadPageEventEmitterSub.unsubscribe();

    this.pccPatientListService.changePagination(this.pagination);
    this.pccPatientListService.changeFilterFormGroupValue(
      this.filterFormGroup.value
    );
  }

  async ngOnInit() {
    this.filterFormGroup = new FormGroup({
      facility: new FormControl(null),
      patientSearchInput: new FormControl(''),
      providerId: new FormControl(''),
      patientStatus: new FormControl('Current'),
      pageSize: new FormControl(200),
    });
    if (this.filterFormGroup.get('patientStatus').value != 'Current') {
      this.patientStatuses = ['All', 'Current', 'New', 'Discharged'];
    }
    this.loadPageEventEmitterSub = this.loadPageEventEmitter.subscribe(() => {

      if (this.filterFormGroup.get('patientStatus').value != 'Current') {
        this.displayedColumns = [
          'checkbox',
          'name',
          'date_of_birth',
          'location',
          'admission_date',
          'discharge_date',
          'status',
          'payer',
           'last_seen',
          'operations',
        ];
      }
      else {
        this.displayedColumns = [
          'checkbox',
          'name',
          'date_of_birth',
          'location',
          'admission_date',
          'payer',
          // 'discharge_date',
          'status',
           'last_seen',
          'operations',
        ];
      }

      const facility = this.filterFormGroup.get('facility').value;
      const facilityExists = this.facilities.find(f => f._id === facility._id);
      if (facility && facilityExists) {
        this.initPatients(
          facility,
          this.filterFormGroup.get('patientStatus').value,
          this.filterFormGroup.get('patientSearchInput').value
        );
      }
    });

    this.pccPatientListService.pagination$.subscribe((pagination) => {
      this.pagination = pagination;
    });
    this.pccPatientListService.filterFormGroupValue$.subscribe(
      (filterFormGroupValue) => {
        this.filterFormGroup.patchValue(filterFormGroupValue);
      }
    );


    this.route.paramMap.subscribe((params: ParamMap) => {
      this.pagination.page = parseInt(params.get('page')) || 1;
      this.loadPageEventEmitter.emit();
    });
    this.filterFormGroup.get('facility').valueChanges.subscribe((val) => {
      this.pagination.page = 1;
      if(this.router.url.split(';')[0] === '/patients/pcc-list'){
        this.router.navigate([
          '/patients/pcc-list',
        ]);
      }
      this.loadPageEventEmitter.emit();
    });
    this.filterFormGroup
      .get('patientSearchInput')
      .valueChanges.pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((val) => {

        this.pagination.page = 1;
        this.loadPageEventEmitter.emit();
        // this.initPatients(this.filterFormGroup.get('facility').value, this.filterFormGroup.get('patientStatus').value, val)
      });
    this.filterFormGroup.get('patientStatus').valueChanges.subscribe((val) => {
      this.selectedPatients = [];
      this.isSelected = false;
      if(this.router.url.split(';')[0] === '/patients/pcc-list'){
        this.router.navigate([
          '/patients/pcc-list',
        ]);
      }
      this.pagination.page = 1;
      this.loadPageEventEmitter.emit();
      // this.initPatients(this.filterFormGroup.get('facility').value, val, this.filterFormGroup.get('patientSearchInput').value)
    });
    this.filterFormGroup.get('pageSize').valueChanges.subscribe((val) => {
      this.selectedPatients = [];
      this.isSelected = false;
      if(this.router.url.split(';')[0] === '/patients/pcc-list'){
        this.router.navigate([
          '/patients/pcc-list',
        ]);
      }
      this.pagination.pageSize = parseInt(val);
      this.pagination.page = 1;
      this.loadPageEventEmitter.emit();
      // this.initPatients(this.filterFormGroup.get('facility').value, this.filterFormGroup.get('patientStatus').value, this.filterFormGroup.get('patientSearchInput').value)
    });
    this.filterFormGroup.get('providerId').valueChanges.subscribe((val) => {
      this.selectedProviderId = val
    });
    await this.initFacilities();
    console.log(this.selectedProviderId);
  }
  getLastSeen(patient) {
    let last_seen='-';
    if(patient?.payor?.last_seen){
        if(Array.isArray(patient?.payor?.last_seen)&&patient?.payor?.last_seen?.length){

          for (let element of patient?.payor?.last_seen) {
            if (element.company_id?.toString().includes(this.currentUser?.company_id?.toString())) {
              if (element.user_type && element.user_type === global.USER_TYPE.WOUND_NURSE) {
                last_seen = `<strong>(WN)</strong> ${element.last_seen || ''}`;
                break;
              } else {
                last_seen = element.last_seen || '';
                break;
              }
            }
          };

        }else{
            last_seen= patient?.payor?.last_seen||'';
        }
    }
    return last_seen;

    // return patient?.payor?.last_seen||'';

    // let lastSeen = '';
    // if(patient?.last_seen) 
    // const { recentNote } = patient;
    // if (recentNote && recentNote.doctor_id_ref) {

    //   const { first_name, last_name } = recentNote.doctor_id_ref;
    //   lastSeen += `${first_name[0]}${last_name[0]}`.toUpperCase();
    //   // console.log("recentNote", recentNote);
    //   // lastSeen += ` - ${moment(new Date(recentNote.filterServiceDate).toLocaleString('en-US', { timeZone: recentNote.timezone })).format('M/D/YYYY')}`;
    //   if (recentNote.date_obj) {
    //     lastSeen += ` - ${this._commonService.dateObjToString(recentNote.date_obj, false)}`;
    //   }
    //   else {
    //     const [year, month, day] = this._commonService.convertDateToStringforMoment(moment(recentNote.filterServiceDate)).split("-");
    //     lastSeen += ` -${month}/${day}/${year}`;
    //   }
    // }
    // return lastSeen;
  }
  async initFacilities() {
    let getFacilities: Observable<any>;
    if (this.currentUser.user_type == global.USER_TYPE.TRANSCRIBER) {
      getFacilities = this._dashboardService.getAssociatedFacilityOfTranscriber(); // for transcriber
    }
    else if (this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
      getFacilities = this._dashboardService.getAssociatedFacilityOfMedicalAssistant(); // for Medical Assistant
    }
    else if (this.currentUser.user_type == global.USER_TYPE.BUSINESS_OFFICER) {
      getFacilities = this._dashboardService.getAssociatedFacilityOfBusinessOfficer(this.currentUser._id, this.currentUser.user_type); // for Medical Assistant
    }
    else if (this.currentUser.user_type == global.USER_TYPE.DOCTOR) {
      getFacilities = this._noteEditorService.getFacilities(
        this.currentUser._id
      ); // for provider
    }
    else if (this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE) {
      const filter = {
        associated_snf_wc_nurse_ids: this.currentUser._id,
      };
      const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      const facilityProjection = {};
      getFacilities = this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection);
    }
    else if (this.currentUser.user_type == global.USER_TYPE.WOUND_NURSE) {
      const filter = {
        company_id_ref: this.currentUser.company_id,
    };
    const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
    };
    const facilityProjection = {};
      getFacilities = this.woundNurseService.getWoundNurseFacilities(filter, nurseProjection, facilityProjection);
    }
    if (!getFacilities) return;

    const response: any = await getFacilities.toPromise();

    if (response.status === 200) {

      if (this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE || this.currentUser.user_type == global.USER_TYPE.WOUND_NURSE) {
        this.facilities = response.data.filter((f) => f.pcc_facId);
      }
      else {
        this.facilities = response.data.array.filter((f) => f.pcc_facId);
      }
      this.facilities.sort(function (a, b) { return a.title > b.title ? 1 : -1; });

      if (this.facilities.length) {


        this._pccService.pccUserinfo$.subscribe((pccUserinfo: any) => {
          this.pccUserInfo = pccUserinfo || this.pccUserInfo;

          const defaultFacility = this.defaultFacility();
          const firstFacility = this.facilities[0];
          const facility = defaultFacility || firstFacility;
          this.filterFormGroup
            .get('facility')
            .setValue(facility);
        });
      }
    }

    // this.pccService.getFacilities().subscribe((response:any) => {
    //   if(response.status === 200) {
    //     this.facilities = response.data.data.map(fac => {
    //       fac.facId = String(fac.facId);
    //       return fac;
    //     });
    //   }
    // }, error => console.error(error), () => this.loading = false);
  }

  getPayor(element){
    // if(Array.isArray(element.pcc_coverage_data?.payers)) {
    //   const primaryPayor = element.pcc_coverage_data.payers.find(payer => payer.payerRank === 'Primary');
    //   if(primaryPayor) {
    //     return primaryPayor.payerName;
    //   }
    // } if(element.adtRecord?.payerName) {
    //   return element.adtRecord.payerName
    // } else {
    //   return element.payor||'';
    // }
    if(!element) return '-';
   // console.log("element.pcc_coverage_data : ", element.pcc_coverage_data);
    if(Array.isArray(element.pcc_coverage_data?.payers)) {
      const primaryPayor = element.pcc_coverage_data.payers.find(payer => payer.payerRank === 'Primary');
      if(primaryPayor) {
        return primaryPayor.payerName;
      }
    }else if(element.non_pcc_payerName){
      return element.non_pcc_payerName;
    }else{
      return element.payor||'';
    }  
  }

  initPatients(facility, pccPatientStatus, pccPatientName) {
    if (!facility) return;
    const { pcc_facId: pccFacId, pcc_orgUuid } = facility;
    if (!pccFacId) {

      return;
    }

    if (!facility.pcc_2_legged_authentication) {
      if (
        // User is Not logged in so cannot access via 2-legged 
        !this.isLoggedIntoPCC() ||
        // User is logged but facility is not logged in so cannot access via 2-legged 
        (this.isLoggedIntoPCC() && !this.pccService.isLoggedInFacility(facility))
      ) {
        this.error2LeggedAuth = 'Please login to PCC to view data.';
        return;
      }
    } else {
      this.error2LeggedAuth = '';
    }
    // if(!this.pccService.hasPCCOrgUUID()) {
    //   return this.toastr.warning('You must logged into PCC atleast once')
    // }
    this.loader.startLoader(this.loaderId);

    if (pccPatientStatus === 'All') {
      pccPatientStatus = undefined; // optional
    }
    if (typeof pccPatientName !== 'string' || pccPatientName.length === 0) {
      pccPatientName = undefined;
    }

    const args: any = {
      pccOrgUuid: pcc_orgUuid,
      pccFacID: pccFacId,
      pccPatientStatus: pccPatientStatus,
      pccPatientName: pccPatientName,
      page: this.pagination.page,
      pageSize: this.pagination.pageSize,
    }
    if (this.isLoggedIntoPCC()) {
      if (this.pccService.isLoggedInFacility(facility)) {
        args.authMode = 'USER'
      } else {
        args.authMode = 'APP'
      }
    }
    this.isLoading = true
    this.pccService
      //.getPatientsByFacilityID(args)
      .getPatientsByFacilityIDWithRecentNote(args)
      .subscribe(
        (response: any) => {
          if (response.status === 200) {
            this.patients = response.data.data;
            console.log('responsessss', response.data);
            // this.activePatientStatuses = this.getPatientsStatus();
            this.filteredPatients = this.patients.slice();
            this.dataSource = new MatTableDataSource(this.filteredPatients);
            this.pagination.hasNextPage = true;
            this.patientsLoaded = true;
            if (this.currentUser.user_type == global.USER_TYPE.TRANSCRIBER || this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
              this.initAssociatedProvidersList();
              this.selectedProviderId = this.filterFormGroup.get('providerId').value;
            }else if (this.currentUser.user_type == global.USER_TYPE.BUSINESS_OFFICER) {
              this.initAssociatedProvidersListForBO();
              this.selectedProviderId = this.filterFormGroup.get('providerId').value;
            } else if(this.currentUser.user_type == global.USER_TYPE.WOUND_NURSE) {
              this.initAssociatedProvidersForWoundNurse();
              this.selectedProviderId = this.filterFormGroup.get('providerId').value;
            }
          } else {
            this.dataSource = [];
            if(Array.isArray(response.data?.errors)) {
              const status = response.data.errors[0].status;
              if(status == "401") {
                if(this.pccService.isLoggedInFacility(facility)) {
                  this.toastr.error('You do not have to access to ' + facility.title + ' facility.', 'Error');
                } else {
                  this.toastr.error('DN portal does not have access to ' + facility.title + ' facility.', 'Error');
                }
              }
            } else {
              this.toastr.error(response.message, 'Error');
            }
          }
        },
        (error) => this.toastr.error('Something went wrong, please try again'),
        () => {
          this.loader.stopLoader(this.loaderId);
          this.isLoading = false
        }
      );
  }

  initAssociatedProvidersListForBO() {
    this._transcriptionCreateService
      .getAssociatedDoctorsListForBO(this.currentUser._id, this.currentUser.company_id, this.currentUser.user_type)
      .subscribe((response: any) => {
        if (response.status === 200) {
          this.associatedProviders = response.data.filter((p) => {
            const selectedPccFacility = this.facilities.find(
              (f) => f.pcc_facId
            );
            return selectedPccFacility.provider_ids_ref.indexOf(p._id >= 0);
          });
        }
      });
  }

  async initAssociatedProvidersForWoundNurse() {
    const response: any = await lastValueFrom(this._transcriptionCreateService.getAssociatedDoctorsListForWoundNurse(this.currentUser._id, this.currentUser.company_id));
    if (response.status === 200) {
      this.associatedProviders = response.data || [];
      this.associatedProviders = this.associatedProviders.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));;
    }
  }

  initAssociatedProvidersList() {
    if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT) {
      this._transcriptionCreateService
        .getAssociatedDoctorsListForMA()
        .subscribe((response: any) => {
          if (response.status === 200) {
            this.associatedProviders = response.data.filter((p) => {
              const selectedPccFacility = this.facilities.find(
                (f) => f.pcc_facId
              );
              return selectedPccFacility.provider_ids_ref.indexOf(p._id >= 0);
            });
          }
        });
    }
    else {
      this._dashboardService
        .getAssociatedDoctorsList(this.currentUser.company_id)
        .subscribe((response: any) => {
          if (response.status === 200) {
            this.associatedProviders = response.data.filter((p) => {
              const selectedPccFacility = this.facilities.find(
                (f) => f.pcc_facId
              );
              return selectedPccFacility.provider_ids_ref.indexOf(p._id >= 0);
            });
          }
        });
    }
  }

  // patientFilterByStatus(patient) {
  //   return this.activePatientStatuses.indexOf(patient.patientStatus) >= 0;
  // }

  applyFilter(query) {
    // console.log(query)
    this.filteredPatients = this.patients.filter((patient) => {
      const patientName = `${patient.firstName} ${patient.middleName} ${patient.lastName} `;
      return (
        patientName.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) !==
        -1
      ); //&& this.patientFilterByStatus(patient)
    });
    this.dataSource = new MatTableDataSource(this.filteredPatients);
  }

  getSelectedFacility() {
    return this.filterFormGroup.get('facility')?.value;
  }
  getSelectedProviderId() {
    console.log("this.filterFormGroup.get('providerId')?.value",this.filterFormGroup.get('providerId')?.value);
    
    return this.filterFormGroup.get('providerId')?.value;
  }
  async handleImportPatient(pccPatient) {
    const patient = await this.importPCCFacilityAndPatient(pccPatient.patientId);
    if (this.currentUser.user_type == 1 && patient) {
      this.showPatientImporterDialog(patient);
    }
  }
  async importPCCFacilityAndPatient(pccPatientId) {
    const infoToast = this.toastr.info('Importing Patient', 'Please wait', {
      disableTimeOut: true,
    });

    try {
      const facility = this.getSelectedFacility();
      const pccOrgUuid = facility.pcc_orgUuid;
      const pccFacId = facility.pcc_facId;
      const primaryProviderId = (this.currentUser.user_type == global.USER_TYPE.DOCTOR || this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE) ? this.currentUser._id : this.getSelectedProviderId()
      console.log('.................',primaryProviderId);
      
      if (!primaryProviderId) {
        return this.toastr.error('Please select primary provider')
      }
      const company_id = this._authService.currentUser.company_id;
      const response: any = await this.pccService
        .importPCCFacilityAndPatient(pccOrgUuid, pccFacId, primaryProviderId, pccPatientId, company_id)
        .toPromise();

      if (response.status === 200) {
        // this.toastr.success('Imported Successfully', 'Success');
        this.toastr.clear(infoToast.toastId);
        return response.data;
      } else {
        if(Array.isArray(response.data?.errors)) {
          const status = response.data.errors[0].status;
          if(status == "401") {
            if(this.pccService.isLoggedInFacility(facility)) {
              this.toastr.error('You do not have to access to this patient or facility.', 'Error');
            } else {
              this.toastr.error('DN portal does not have access to this patient or facility.', 'Error');
            }
          }
        } else {
          this.toastr.error(
            'Patient could not be imported at this time.',
            'Failed'
          );
        }
      }
    } catch (error) {
      this.toastr.error(
        'Patient could not be imported at this time.',
        'Failed'
      )
    } finally {
      this.toastr.clear(infoToast.toastId)
    }

  }

  importPCCPatient(pccPatientId) {
    let primaryProviderId;

    if (this.currentUser.user_type == global.USER_TYPE.TRANSCRIBER || this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type == global.USER_TYPE.BUSINESS_OFFICER || this.currentUser.user_type == global.USER_TYPE.WOUND_NURSE) {
      primaryProviderId = this.selectedProviderId;
    } else if (this.currentUser.user_type == global.USER_TYPE.DOCTOR || this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE) {
      primaryProviderId = this.currentUser._id;
    }

    if (!primaryProviderId) {
      return this.toastr.warning(
        'Please select primary Provider to import patient.',
        'Warning'
      );
    }
    const infoToast = this.toastr.info('Importing Patient', 'Please wait', {
      disableTimeOut: true,
    });
    const facility = this.filterFormGroup.get('facility').value;
    const selectedFacilityId = facility._id;
    const pccOrgUuid = facility.pcc_orgUuid;

    this.pccService
      .importPCCPatient(pccOrgUuid, pccPatientId, primaryProviderId, selectedFacilityId)
      .subscribe(
        (response: any) => {
          if (response.status === 200) {
            this.toastr.clear(infoToast.toastId)
            // this.toastr.success('Imported Successfully', 'Success');
            const patient = response.data;
            console.log('response.data', response.data);
            console.log('patient', patient);
            if (response.message == 'Patient already exists') {
              this.toastr.error(
                'Patient already imported.',
                'Failed'
              );
              return;
            }
            if (this.currentUser.user_type == 1 || this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type == global.USER_TYPE.BUSINESS_OFFICER) {
              this.showPatientImporterDialog(patient);
            }
          } else {
            if(Array.isArray(response.data?.errors)) {
              const status = response.data.errors[0].status;
              if(status == "401") {
                if(this.pccService.isLoggedInFacility(facility)) {
                  this.toastr.error('You do not have to access to this patient or facility.', 'Error');
                } else {
                  this.toastr.error('DN portal does not have access to this patient or facility.', 'Error');
                }
              }
            } else {
              this.toastr.error(
                'Patient could not be imported at this time.',
                'Failed'
              );
            }
          }
        },
        () =>
          this.toastr.error(
            'Patient could not be imported at this time.',
            'Failed'
          ),
        () => this.toastr.clear(infoToast.toastId)
      );
  }

  getPatientsStatus() {
    const patientStatuses = {};
    for (let patient of this.patients) {
      patientStatuses[patient.patientStatus] = true;
    }
    return Object.keys(patientStatuses);
  }

  isPaginationVisible() {
    if (
      this.filterFormGroup.get('facility').value &&
      this.patients.length === this.filteredPatients.length
    ) {
      return true;
    }
  }

  navigatePrevNext(page) {
    this.selectedPatients = [];
    this.isSelected = false;
    this.router.navigate([
      '/patients/pcc-list',
      { page: this.pagination.page + page },
    ]);
  }
  showPatientImporterDialog(patient) {
    console.log('showPatientImporterDialog', patient);

    const obj = {
      filter_facility_id: this.getSelectedFacility()?._id,
      filter_provider_id: this.selectedProviderId
    }

    let keyName = 'patient';
    if (Array.isArray(patient)) {
      keyName = 'patients';
    }

    this.dialog.open(PatientImporterDialog, {
      width: '50%',
      data: { [keyName]: patient, action: 'import', only_pcc: true, obj: obj },
    });
  }

  showRecurrentPatientImporterDialog(patient) {
    console.log('showRecurrentPatientImporterDialog', patient);

    const obj = {
      filter_facility_id: this.getSelectedFacility()?._id,
      filter_provider_id: this.selectedProviderId
    }

    let keyName = 'patient';
    if (Array.isArray(patient)) {
      keyName = 'patients';
    }

    this.dialog.open(PatientImporterRecurrentComponent, {
      width: '50%',
      data: { [keyName]: patient, action: 'import', only_pcc: true, obj: obj },
    });
  }

  importPCCFacilities() {
    if (!this.pccService.hasPCCOrgUUID()) {
      return this.toastr.warning('You must logged into PCC atleast once')
    }
    this.pccService.importPCCFacilities().subscribe(
      (response: any) => {
        if (response.status === 200) {
          console.log('Facilities has been imported from pcc');
          this.initFacilities();
        }
      },
      () => { }
    );
  }

  isPatientSelected(patient) {
    return this.selectedPatients.indexOf(patient.patientId) >= 0;
  }
  handleSelectPatient(e, patient) {
    const index = this.selectedPatients.indexOf(patient.patientId);
    if (e.checked) {
      if (index === -1) {
        this.selectedPatients.push(patient.patientId);
      }
    } else {
      if (index >= 0) {
        this.selectedPatients.splice(index, 1);
      }
    }
  }

  async importMultipleRecurrentPatients(){

    

  }

  async importMultiplePatients(recurrent=false) {
    let primaryProviderId;

    if (this.currentUser.user_type == global.USER_TYPE.TRANSCRIBER || this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type == global.USER_TYPE.BUSINESS_OFFICER || this.currentUser.user_type == global.USER_TYPE.WOUND_NURSE) {
      primaryProviderId = this.selectedProviderId;
    } else if (this.currentUser.user_type == global.USER_TYPE.DOCTOR || this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE) {
      primaryProviderId = this.currentUser._id;
    }

    if (!primaryProviderId) {
      return this.toastr.warning(
        'Please select primary Provider to import patient.',
        'Warning'
      );
    }




    const facility = this.filterFormGroup.get('facility').value;
    const selectedFacilityId = facility._id;
    const pccOrgUuid = facility.pcc_orgUuid;

    const importedPatients = [];
    const totalSelectedPatients = this.selectedPatients.length;
    for await (const pccPatientId of this.selectedPatients) {
      const infoToast = this.toastr.info(`Importing Patients(${importedPatients.length + 1} of ${totalSelectedPatients})`, 'Please wait', {
        disableTimeOut: true,
      });


      const response: any = await this.pccService
        .importPCCPatient(pccOrgUuid, pccPatientId, primaryProviderId, selectedFacilityId)
        .toPromise()
        .catch((e) => {
          this.toastr.error(
            'Patient could not be imported at this time.',
            'Failed'
          );
        });

      if (response.status === 200) {
        this.toastr.clear(infoToast.toastId);
        console.log('response.data', response.data);
        if (Array.isArray(response.data)) {
          importedPatients.push(response.data[0]);
        } else {
          if(response.patient)
          importedPatients.push(response.patient);
          else
          importedPatients.push(response.data);
        }
        console.log('importedPatients', importedPatients);
        this.selectedPatients = [];
      } else {
        this.toastr.error(
          'Patient could not be imported at this time.',
          'Failed'
        );
      }


    }
    this.toastr.success(`${importedPatients.length} patients imported successfully`, 'Success');
    if(recurrent){
      this.showRecurrentPatientImporterDialog(importedPatients);
    }else{
      this.showPatientImporterDialog(importedPatients);

    }
  }

  // defaultFacility() {
  //   return this.facilities.find(fac => fac.pcc_facId == this.pccUserInfo?.defaultFacId)
  // }

  defaultFacility() {
    return this.facilities.find(fac => this.pccService.isDefaultLoggedInFacility(fac))
  }
  isLoggedIntoPCC() {
    return !!this.pccUserInfo;
  }

  sortData(sort: Sort) {
    console.log("this.filteredPatients", this.filteredPatients);

    this.filteredPatients = this.filteredPatients.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return compare(a.lastName, b.lastName, isAsc);

        case 'date_of_birth': return compare(new Date(a.birthDate).getTime(), new Date(b.birthDate).getTime(), isAsc);

        case 'gender': return compare(a.gender, b.gender, isAsc);

        case 'payer': return compare(this.getPayor(a?.payor), this.getPayor(b?.payor), isAsc);

        case 'location': return compare(a.roomDesc, b.roomDesc, isAsc);

        case 'admission_date': return compare(new Date(a.admissionDate).getTime(), new Date(b.admissionDate).getTime(), isAsc);

        case 'discharge_date': return compare(new Date(a.dischargeDate).getTime(), new Date(b.dischargeDate).getTime(), isAsc);

        case 'last_seen' : return compare(this.getLastSeen(a), this.getLastSeen(b), isAsc);
        // case 'payer': return compare(new Date(a.updation_date.toString()).getTime(), new Date(b.updation_date.toString()).getTime(), isAsc);

        // case 'status': return compare(new Date(a.updation_date.toString()).getTime(), new Date(b.updation_date.toString()).getTime(), isAsc);

        default: return 0;
      }
    });
    this.dataSource = new MatTableDataSource(this.filteredPatients);

  }

  onSelectAllPatients(isSelected) {
    this.selectedPatients = [];
    if (isSelected) {
      this.selectedPatients = this.filteredPatients.map(patient => patient.patientId);
    }
  }

}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
