<app-header></app-header>

<main>
  <div style="position: relative;"   class="container flex flex-col gap-2">
    <div class="census-header flex items-center gap-2">
      <button class="dn-button-secondary" mat-raised-button (click)="goBack()">
        Go Back
      </button>

      <div style="color: #fff;" class="flex justify-between gap-2">
        <div>Total Patients: {{ census?.patientList?.length }}</div>
        <app-facility-title [facility]="census?.facility"></app-facility-title>
      </div>
    </div>
    <div class="payers-container" style="color: #fff; font-size:13px">
        <div class="payers-item" *ngFor="let group of groupedPatientsByPayer | keyvalue">
          <mat-checkbox [checked]="isPayerGroupedPatientsChecked(group)" (change)="handlePayerGroupedPatientsCheck($event, group)">
            {{group.key}} ({{group.value.length}})
          </mat-checkbox>
        </div>
    </div>
    <app-input-with-dropdown placeholder="Search Patient by Name"
        (inputChange)="applyFilter($event.target.value)" [noDropdown]='true'> 
    </app-input-with-dropdown>
    <button mat-raised-button class="dn-button-primary" style="margin-bottom: 10px;" *ngIf="selectedPatients.length > 0"
      (click)="importMultiplePatients()"
      >Import {{selectedPatients.length}} Patients</button>
    <div *ngIf="census">
        <table *ngIf="census.patientList" class="table-row-hover roundingsheet-patient-list-table" mat-table matSort [dataSource]="dataSource"
        (matSortChange)="sortData($event)">
              <ng-container matColumnDef="patient">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="patient">Patient Name</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.lastName }}, {{ element.firstName }}
                </td>
              </ng-container>
              <ng-container matColumnDef="room_no">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="room_no">Room #</th>
                <td mat-cell *matCellDef="let element">
                  {{ getPatientRoomNo(element) }}
                </td>
              </ng-container>
              <ng-container matColumnDef="admit_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="admit_date">Admit Date</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.admissionDate | date: 'M/d/yyyy'}}
                </td>
              </ng-container>
              <ng-container matColumnDef="primary_payer">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="primary_payer">Primary Payer</th>
                <td mat-cell *matCellDef="let element">
                    {{element.adtRecord?.payerName}}
                </td>
              </ng-container>
              <ng-container matColumnDef="operations">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <div class="operation-buttons flex items-center gap-5">  
                        <mat-checkbox [checked]="isPatientChecked(element)" (change)="handlePatientCheck($event, element)">
                        </mat-checkbox>
                      </div>
                </td>
              </ng-container>
              <ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              </ng-container>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
              ></tr>
        </table>
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>
</main>