import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { ToastrService } from 'ngx-toastr';
import { BillerProviderAssociationService } from '../biller-provider-association.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-biller-provider-association',
  templateUrl: './biller-provider-association.component.html',
  styleUrls: ['./biller-provider-association.component.css']
})
export class BillerProviderAssociationComponent implements OnInit {

  public response: any;
  public relations: any;
  public dataSource: any;
  public billers: any = [];
  providers: any = [];
  providersName: any = [];
  selectedProviders: any = [];
  providerTranscriber: Boolean = true;
  providerBiller: Boolean = false;
  providerDropdownSettings: any = {};
  assigned: Boolean = false;
  currentUser: any;
  company_id: any;

  displayedColumns2: string[] = ['biller_name', 'provider_name'];
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public services: BillerProviderAssociationService,
    public userTypesRelationshipService: UserTypesRelationshipService,
    private toastr: ToastrService,
    private authService: AuthService,
    private route: ActivatedRoute,
  ) {
    this.currentUser = this.authService.currentUser;
    this.providerDropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'full_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: true,
    };
  }

  async ngOnInit() {
    // if(this.currentUser.account_type == 'company'){
    // this.company_id = this.currentUser.company_id; 
    // }
    if (window.location.pathname.includes('company')) {
      this.company_id = this.route.parent.snapshot.params.id
  }
  else {
      this.company_id = '';
  }
    await this.loadDataForBiller();
  }

  applyFilter(filterValue: string) {
    let filter = filterValue.trim().toLowerCase();
    if (filter) {
      let filterVal = [];
      this.billers.forEach(biller => {
        if (biller.first_name?.toLowerCase().includes(filter) || biller.last_name?.toLowerCase().includes(filter)) {
          filterVal.push(biller)
          // this.dataSource = new MatTableDataSource(this.filteredData);
        }
      });
      this.dataSource = filterVal;
    } else if (filter == '') {
      this.dataSource = this.billers;
    }
  }

  async loadDataForBiller() {
    // return new Promise((resovle, reject) => {
    let res = await this.services.getAllBillers(this.company_id).subscribe(async data => {
      this.billers = data;
      this.billers = this.billers.data;  
      await this.userTypesRelationshipService.getDoctorsList('true', this.company_id, null, null, null).subscribe(async data => {
        
        this.providers = data; 
        this.providers = this.providers.data; 
        await this.services.getBillerRelations(this.company_id).subscribe(async data => {
          this.relations = data;    
          if (this.relations.status === 200) {
            // if (this.relations.data[0]?.assoc_provider_id?.length > 0) { 
              this.billers.forEach(async biller => {
                // let assos_provider = [];
                this.relations.data?.forEach(async relation => {
                  if (biller._id == relation.biller_id?._id) { 
                    biller.assos_providers = relation.assoc_provider_id;
                  }
                });
                biller.providers = this.providers
              }); 
              
              this.dataSource = new MatTableDataSource(this.billers); 
            // }
            this.assigned = true;
          }
        })
      })
    });

    // });
  }
  getEmailId(element) {
    return element?.email ? element.email : '';
  }
  // biller-provider 
  onProviderSelect(provider, biller) { 
    const details = {
      providerID: provider._id,
      billerID: biller._id, 
      company_id: this.company_id,
    }  
    this.createRelationBillerProvider(details);

  }

  onDataSelection($event) {
    // let providers = element.associated_provider_ids.map(a => a._id);
    // let idx = this.users.findIndex(user => user._id.toString == element._id.toString());
    // this.users[idx] = element;
    // this.dataSource.data = this.users;
    if($event.data == 'selectAll') {
      this.onSelectAllProvider($event.element.assos_providers, $event.element);

    }
    else if($event.data == 'unSelect') {
      this.onDeSelectAllProvider([], $event.element);
    }
    else {
      if(!$event.status) {
        this.onProviderDeSelect($event.data, $event.element);
      }
      else {
        this.onProviderSelect($event.data, $event.element);
      }
    }
  }

  onDataRemove($event) {
    this.onProviderDeSelect($event.data, $event.element);
  }

  onSelectAllProvider(event, biller) {
    // this.selectedProviders = event;
    const idsOfAllProviders = [];
    event.forEach(element => {
      idsOfAllProviders.push(element._id);
    });

    const details = {
      billerID: biller._id,
      providerID: idsOfAllProviders,
      company_id: this.company_id,
    }
    this.createRelationBillerProvider(details); 
  }

  onDeSelectAllProvider(event, biller) {
    console.log('event',event);
    this.selectedProviders = event;

    const details = {
      billerID: biller,
      providerID: event,
      company_id: this.company_id,
    }
    this.createRelationBillerProvider(details); 
  }
  
  onProviderDeSelect(provider, biller) { 
    const details = {
      billerID: biller._id,
      providerID: provider._id
    }
    this.removeRelationBillerProvider(details);
    biller.assos_providers = biller.assos_providers.filter(biller => biller._id.toString() != provider._id.toString())
  }


  createRelationBillerProvider(details) { 
    this.services.createRelationBillerProvider(details).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("Relationship updated")
      }
    })
  }
  removeRelationBillerProvider(details) { 
    this.services.removeRelationBillerProvider(details).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("Relationship updated")
      }
    })
  }


  // async getBillerRelations() {
  //   this.services.getBillerRelations().subscribe(data => {
  //     this.response = data;
  //     if (this.response.status === 200) {
  //       if (this.response.data[0] && this.response.data[0].assoc_provider_id && this.response.data[0].assoc_provider_id.length > 0) {
  //         let selectedProviderIds = this.response.data[0].assoc_provider_id;
  //         selectedProviderIds.map(a => {
  //           this.providers.map(pro => {
  //             if (a == pro._id) {
  //               this.selectedProviders.push(pro['first_name'] + ' ' + pro['last_name']);
  //             }
  //           })
  //         })
  //       }

  //     }
  //   })
  // }

}
