import { Component, OnInit, OnDestroy } from '@angular/core';
import { WebSocketService } from './web-socket.service'
import { AudioRecordingService } from './audio-recording.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as global from '../../includes/global';
import { API_URL } from "../../../environments/api-url";
import { DashboardService } from '../dashboard/dashboard.service';
import { FeedbackService } from '../feedback/feedback.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

// import { TranscribeStreamingClient, StartMedicalStreamTranscriptionCommand } from '@aws-sdk/client-transcribe-streaming';
//Pr59i9peb5nZWAzMxzYm_HR_ client id new

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.css']
})
export class AudioComponent implements OnInit {
  clientId = "466640582368-hb1vlnod1pl17hn52v0bk8oq48e2jg2u.apps.googleusercontent.com";

  // redirect_uri of the project

  redirect_uri = "http://localhost:4200/audio";

  // scope of the project

  scope = "https://www.googleapis.com/auth/drive";

  DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/people/v1/rest"];

  // the url to which the user is redirected to
  authorized = false;
  url = "";
  file: any;
  files: any;
  response: any;
  timePeriods = [
    {name :'phrases', is_active: true},
    {name :'census-comments', is_active : false},
    {name :'wound-care-widget',is_active : false},
    {name :'note-patient-details',is_active : false},
    {name :'note-auditory-history',is_active : false},
  ];
  period: any;

  
  constructor(private webSocketService: WebSocketService,
    private audioRecordingService: AudioRecordingService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private dashboardService: DashboardService,
    private service: FeedbackService
  ) { }

  ngOnInit(): void {
    this.webSocketService.listen('test event').subscribe((data) => {
      console.log('data', data);
    });

    this.webSocketService.listen('resMessage').subscribe((data) => {
      console.log('receieved message again', data);
    });
    if (this.route.snapshot.queryParamMap.has('code')) {
      console.log('code',this.route.snapshot.queryParams.code);
    }

  }
  
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.timePeriods, event.previousIndex, event.currentIndex);
  }
  // startRecording() {
  //   this.webSocketService.emit('message', { message: 'Hey, I have an important message!' });
  //   navigator.mediaDevices.getUserMedia({audio: true}).then(function(stream){
  //     console.log('stream',stream);
  //   });
  // }

  authorize(clientId, redirect_uri, scope, url) {
    url = "https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=" + redirect_uri
      + "&prompt=consent&response_type=code&client_id=" + clientId + "&scope=" + scope
      + "&access_type=offline";
    // this line makes the user redirected to the url
    this.authorized = true;
    window.location = url;
    
 
    
    
  }

  tabChange(period,index){
    console.log('$event',period);
    this.period = period.name;
    this.timePeriods.forEach((element,i) => {
      if(index !== i )
      element.is_active = false
    });
    period.is_active = true;
  }
 
  upload() {

    //   const urlParams = new URLSearchParams(window.location.search);
    //   const code = this.route.snapshot.queryParams.code;
    //   const scope = "https://www.googleapis.com/auth/drive";
    //   const client_secret = "zrvvDwodG02rxUzAFaSzcMda";
    //   const redirect_uri = "http://localhost:4200/audio";
    //   var access_token = "";
    //   var client_id = "466640582368-hb1vlnod1pl17hn52v0bk8oq48e2jg2u.apps.googleusercontent.com";
    //   this.url = "https://accounts.google.com/o/oauth2/v2/auth";

    //   var data = {
    //     code: code,
    //     redirect_uri: redirect_uri,
    //     client_secret: client_secret,
    //     client_id: client_id,
    //     scope: scope,
    //     grant_type: "authorization_code",
    //   }
    //   this.dashboardService.getGoogleToken(data).subscribe((data) => {
    //       console.log(data);
    //     })

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const redirect_uri = "http://localhost:4200/audio" // replace with your redirect_uri;
    // const client_secret = "zrvvDwodG02rxUzAFaSzcMda"; // replace with your client secret
    const scope = "https://www.googleapis.com/auth/drive";
    var access_token = "";
    var client_id = "466640582368-hb1vlnod1pl17hn52v0bk8oq48e2jg2u.apps.googleusercontent.com"// replace it with your client id;


    // $.ajax({
    //   type: 'POST',
    //   url: "https://www.googleapis.com/oauth2/v4/token",
    //   data: {
    //     code: code
    //     , redirect_uri: redirect_uri,
    //     client_secret: client_secret,
    //     client_id: client_id,
    //     scope: scope,
    //     grant_type: "authorization_code"
    //   },
    //   dataType: "json",
    //   success: function (resultData) {
    //     this.response = resultData;
    //     console.log('sucessss', this.response);
    //     console.log('refresh token', typeof resultData.refreshToken);
    //     console.log('refresh token', resultData.refreshToken);


    //     localStorage.setItem("accessToken", resultData.access_token);
    //     localStorage.setItem("refreshToken", resultData.refreshToken);
    //     localStorage.setItem("expires_in", resultData.expires_in);
    //     //  window.history.pushState({}, document.title, "/GitLoginApp/" + "upload.html");
    //   }
    // });
  }
}
