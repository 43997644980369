<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
<div class="flex flex-col">
    <div class="flex" style="padding: 10px;">
        <mat-form-field style="width: 180px;">
            <mat-label>Select Procedure Type</mat-label>
            <mat-select [value]="selectedProcedureType" (selectionChange)="procedureChange($event)">
                <mat-option *ngFor="let procedure of allProcedureTypes" [value]="procedure">{{procedure.title}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="flex">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 note-cpt-table" matSort>
            <ng-container matColumnDef="checkbox">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                </th>
                <td mat-cell *matCellDef="let element" style="width: 50px;">
                    <mat-checkbox 
                    (click)="shouldDisableCheckbox() ? null : toggleCPTSelection(element)"
                    name="cpt_fvrt"
                    [disabled]="shouldDisableCheckbox()"
                    [checked]="cptsSelected(element)">
                    </mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    <ng-container *ngIf="element.code">
                        {{element.code}} ({{element.description}})
                    </ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="fvrt">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                </th>
                <td mat-cell *matCellDef="let element" style="width: 50px;">
                    <span *ngIf="element.fvrt === true"
                    (click)="$event.preventDefault();$event.stopPropagation();toggleFavouriteCharges(element,'remove')"
                    style="cursor: pointer" class="material-icons">
                    star
                </span>
                <span class="material-icons" *ngIf="element.fvrt !== true"
                    (click)="$event.preventDefault();$event.stopPropagation();toggleFavouriteCharges(element)"
                    style="cursor: pointer">star_outline</span>
                </td>
            </ng-container>
        
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

</div>