<div class="flex-col">
    <div class="flex justify-between items-center gap-1 action-buttons">
        <div>

        </div>
        <div>
            <button [ngClass]="{'action-buttons-disable': checkSigneNote(),'action-buttons-enable': !checkSigneNote()}"
                [disabled]="checkSigneNote()" matTooltip="Undo Action" (click)="undoAction()"><span
                    class="material-icons">undo</span></button>
            <button [ngClass]="{'action-buttons-disable': checkSigneNote(),'action-buttons-enable': !checkSigneNote()}"
                [disabled]="checkSigneNote()" style="margin-left: 3px;" matTooltip="Redo Action"
                (click)="redoAction()"><span class="material-icons">redo</span></button>
        </div>
    </div>
    <div class="selected-items" *ngIf="selectedCharges.length">
        <div cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListDisabled]="charge?.status == 'submit'">
            <div cdkDrag *ngFor="let element of selectedCharges; let i = index" class="item-row flex gap-4"
                [class.procedure-type-cpts]="isElementProcedureType(element)"
                [ngStyle]="{ background: i % 2 == 0 ? '#f4f5f8' : 'white'}">
                <mat-checkbox (change)="removeCharge(element, $event, 'cpt')" 
                [checked]="true" [disabled]="shouldDisableCheckbox()">
                    {{element.code}} <span>({{element.description}})</span>
                </mat-checkbox>
                <div class="form-fields-container" class="flex gap-2 items-end justify-center">
                    <mat-form-field *ngIf="loggedInUser.portal_type === global.PORTAL_TYPE.NON_WC" style="width:110px">
                        <mat-label>POS</mat-label>
                        <mat-select [(ngModel)]="element.selected_pos" (selectionChange)="handlePOSChange(element, $event.value)">
                            <mat-select-trigger>
                                {{element.selected_pos}}
                            </mat-select-trigger>
                            <mat-option *ngFor="let pos of element.pos_ids" [value]="pos.code.toString()">({{pos.code}})
                                {{pos.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="modifier-width">
                        <mat-label>Modifier</mat-label>
                        <mat-select [name]="'modifier_' + element.code" #modifierRef [value]="getCptModifier(element)"
                            (selectionChange)="handleModifierChange(element, modifierRef.value)">
                            <mat-select-trigger>
                                {{modifierRef.value}}
                            </mat-select-trigger>
                            <mat-option [value]="">--None--</mat-option>
                            <mat-option *ngFor="let modifier of modifiers" [value]="modifier.code">
                                ({{modifier.code}}) {{modifier.description}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field style="width:60px">
                        <mat-label>Unit</mat-label>
                        <input (keyup)="handleModifierUnitChange(element, $event.target.value)"
                            (change)="handleModifierUnitChange(element, $event.target.value)" type="number" min="1" max="99"
                            [value]="getCptModifierUnit(element)" matInput placeholder="Unit" name="modifierUnit" style="width: 100%;">
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    


    <div style="margin: 10px;">
        <mat-form-field style="width: 100%;height: 44px;margin-top: -10px;" appearance="fill">
            <mat-label>Search</mat-label>
            <input matInput placeholder="search charges" (keyup)="searchCharge($event.target.value)" name="chargeFilter" style="width: 100%;">
            <button mat-button mat-icon-button matSuffix (click)="clearSearchField();searchCharge('');"
                *ngIf="chargeFilter">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>

    <div class="flex flex-col note-sidebar" >
        <ng-container>
            <div *ngIf="currentCompany.should_allow_customized_tab" cdkDropList cdkDropListOrientation="horizontal" class="flex justify-center tabs-list"
              (cdkDropListDropped)="drop_($event)">
              <ng-container *ngFor="let tab of tabsToBeDisplayed; let index = index">
                <div 
                class="tabs-group tabs-ripple-container" cdkDrag matRipple 
                (click)="tabChange(tab,index)" 
                [ngStyle]="{'border-bottom':tab === activeTab ? '2px solid #3f51b5' : '' }"
                >
                    <div [ngSwitch]="tab">
                        <div *ngSwitchCase="'HP E&M Codes'">Template linked E&M codes</div>
                        <div *ngSwitchDefault>{{ tab }}</div>
                    </div>
                </div>
              </ng-container>
            </div>
            <div *ngIf="!currentCompany.should_allow_customized_tab" class="flex justify-start tabs-list">
              <ng-container *ngFor="let tab of tabsToBeDisplayed; let index = index">
                <div class="tabs-group tabs-ripple-container" 
                (click)="tabChange(tab,index)"
                [ngStyle]="{'border-bottom':tab === activeTab ? '2px solid #3f51b5' : '' }"
                >
                <div [ngSwitch]="tab">
                    <div *ngSwitchCase="'HP E&M Codes'">Template linked E&M codes</div>
                    <div *ngSwitchDefault>{{ tab }}</div>
                </div>
                </div>
              </ng-container>
            </div>
            <div *ngIf="activeTab == 'Favorites'">
                <span style=" width: 100%; height: 100%; background-color: rgba(81, 99, 99, 0.8);overflow: auto;">
                    <table mat-table [dataSource]="dataSourceFvrtCPTs" matSort cdkDropList
                        (cdkDropListDropped)="onListDropfvrtCPTs($event)" *ngIf="fvrtCPTTabData">
                        <ng-container matColumnDef="checkbox">
                            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                            </th>
                            <td mat-cell *matCellDef="let element" style="width: 50px;">
                                <mat-checkbox 
                                    (change)="toggleCPTsinChange(element);"
                                    [checked]="element.selected" 
                                    name="cpt_fvrt" 
                                    [disabled]="shouldDisableCheckbox()">
                                </mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="fvrt">
                            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                            </th>
                            <td mat-cell *matCellDef="let element" style="width: 50px;">
                                <!-- *ngIf="isFavourite(element, 'cpt', 'fvrts')" -->
                                <span
                                    (click)="$event.preventDefault();$event.stopPropagation();removeFavouriteCharges(element)"
                                    style="cursor: pointer" class="material-icons">
                                    star
                                </span>
                                <!-- <span class="material-icons" *ngIf="!isFavourite(element, 'cpt', 'fvrts')"
                                            (click)="addToFavoriteCharges(element)"
                                            style="cursor: pointer">star_outline</span> -->
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="code">
                            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                            </th>
                            <td mat-cell *matCellDef="let element" 
                                (click)="shouldDisableCheckbox() ? null : toggleCPTsinChange(element);"
                                style="cursor: pointer;">
                                {{element.code}}
                                <span cdkDrag>({{element.description}})</span>
                            </td>
                        </ng-container>
                        <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
                        <!-- <tr *matHeaderRowDef="displayedColumns"></tr> -->
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </span>
            </div>
            <div *ngIf="activeTab == 'All'">
                <span style=" width: 100%; height: 100%; background-color: rgba(81, 99, 99, 0.8);overflow: auto;">
                    <table mat-table [dataSource]="dataSourceAllCPTs" *ngIf="allCPTTabData">
                        <ng-container matColumnDef="checkbox">
                            <th class="header-data" mat-header-cell *matHeaderCellDef>
                            </th>
                            <td mat-cell *matCellDef="let element" style="width: 50px;">
                                <mat-checkbox 
                                    (change)="toggleCPTsinChange(element)"
                                    [checked]="getCheckedStatus(element)" 
                                    name="cpt_all" 
                                    [disabled]="shouldDisableCheckbox()">
                                </mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="fvrt">
                            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                            </th>
                            <td mat-cell *matCellDef="let element" style="width: 50px;">
                                <!-- *ngIf="!isFavourite(element, 'cpt', 'all')" -->
                                <span *ngIf="element.fvrt === true"
                                    (click)="$event.preventDefault();$event.stopPropagation();removeFavouriteCharges(element)"
                                    style="cursor: pointer" class="material-icons">
                                    star
                                </span>
                                <!-- *ngIf="!isFavourite(element, 'cpt', 'all')" -->
                                <span *ngIf="element.fvrt !== true" class="material-icons"
                                    (click)="$event.preventDefault();$event.stopPropagation();addFavouriteCharges(element)"
                                    style="cursor: pointer">star_outline</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="code">
                            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                            </th>
                            <td mat-cell *matCellDef="let element" 
                                (click)="shouldDisableCheckbox() ? null : toggleCPTsinChange(element)"
                                style="cursor: pointer;">
                                {{element.code}}
                                <span>({{element.description}})</span>
                            </td>
                        </ng-container>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
                </span>
                <div style="height: 5px;"></div>
                <div class="flex justify-center items-center gap-2 table-nav">

                    <span style="background: white; border-radius: 2px; font-size: 12px;padding: 5px;">
                        {{getCountofCodes()}}</span>

                    <button mat-raised-button class="dn-button-primary"
                        [disabled]="!paginationChargesAll.hasNextPage" type="button" (click)="handleNextClick()">
                        Next </button>
                </div>
            </div>
            <div *ngIf="activeTab == 'Company'">
                <span style=" width: 100%; height: 100%; background-color: rgba(81, 99, 99, 0.8);overflow: auto;">
                    <table mat-table [dataSource]="dataSourceCompanyCPTs" *ngIf="companyCPTTabData">
                        <ng-container matColumnDef="checkbox">
                            <th class="header-data" mat-header-cell *matHeaderCellDef>
                            </th>
                            <td mat-cell *matCellDef="let element" style="width: 50px;">
                                <mat-checkbox 
                                    (change)="toggleCPTsinChange(element)"
                                    [checked]="getCheckedStatus(element)"
                                    [disabled]="shouldDisableCheckbox()"
                                    name="cpt_all">
                                </mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="fvrt">
                            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                            </th>
                            <td mat-cell *matCellDef="let element" style="width: 50px;">
                                <!-- *ngIf="!isFavourite(element, 'cpt', 'all')" -->
                                <span *ngIf="element.fvrt === true"
                                    (click)="$event.preventDefault();$event.stopPropagation();removeFavouriteCharges(element)"
                                    style="cursor: pointer" class="material-icons">
                                    star
                                </span>
                                <!-- *ngIf="!isFavourite(element, 'cpt', 'all')" -->
                                <span *ngIf="element.fvrt !== true" class="material-icons"
                                    (click)="$event.preventDefault();$event.stopPropagation();addFavouriteCharges(element)"
                                    style="cursor: pointer">star_outline</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="code">
                            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                            </th>
                            <td mat-cell *matCellDef="let element" 
                                (click)="shouldDisableCheckbox() ? null : toggleCPTsinChange(element)"
                                style="cursor: pointer;">
                                {{element.code}}
                                <span>({{element.description}})</span>
                            </td>
                        </ng-container>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <ngx-ui-loader [loaderId]="loaderId2"></ngx-ui-loader>
                </span>
                <div style="height: 5px;"></div>
                <div class="flex gap-2 justify-center items-center table-nav">

                    <span style="background: white; border-radius: 2px; font-size: 12px;padding: 5px;">
                        {{getCountofCompanyCodes()}}</span>

                    <button mat-raised-button class="dn-button-primary"
                        [disabled]="!paginationChargesCompany.hasNextPage" type="button" (click)="handleNextClickCompany()">
                        Next </button>
                </div>
            </div>
            <div *ngIf="activeTab == 'History'">
                <app-note-cpt-history [note]="note" [searchCharges]="chargeFilter" [selectedCharges]="getSelectedChargesCopy()" [showCPTHistory]= "true" [fvrtCPTs]="fvrtCPTs"
                (toggleFvrtCPTsinHistory)="toggleFvrtCPTsinHistory($event)"
                (toggleCPTsinHistory)="toggleCPTsinChange($event)"></app-note-cpt-history>
            </div>
            <div *ngIf="activeTab == 'HP E&M Codes'">
                <app-note-cpt-history [note]="note" [emCodeEditorCpts]="emCodeEditorCpts" [searchCharges]="chargeFilter" [selectedCharges]="getSelectedChargesCopy()" [showCPTHistory]= "false" [fvrtCPTs]="fvrtCPTs"
                (toggleFvrtCPTsinHistory)="toggleFvrtCPTsinHistory($event)"
                (toggleCPTsinHistory)="toggleCPTsinChange($event)"></app-note-cpt-history>
            </div>
            <div *ngIf="activeTab == 'Procedure Codes'">
                <app-note-cpt-procedure-codes [searchCharges]="chargeFilter" [note]="note" [selectedCharges]="getSelectedChargesCopy()" [fvrtCPTs]="fvrtCPTs"
                (toggleFvrtCPTsinHistory)="toggleFvrtCPTsinHistory($event)"
                (toggleCPTsinHistory)="toggleCPTsinChange($event)"></app-note-cpt-procedure-codes>
            </div>
          </ng-container>
          
    </div>

    <div *ngIf="(!questionnaireResults && !hideMipsReportMeasure)" style="margin: 0 10px" class="note-sidebar-cpts">
        <mat-checkbox *ngIf="this.loggedInUser.mip_reporting&&!mipsInThisYear()" [(ngModel)]="model.measure" name="measure"
            (change)="changeReportMeasure($event.checked)" disabled>Report MIPS Measures</mat-checkbox>
    </div>
    <div style="margin: 0 10px" *ngIf="questionnaireResults||mipsInThisYear()">
        <span style="color:red; font-size: 12px;">Report MIPS Measures already submitted</span>
    </div>
</div>