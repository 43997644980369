import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { CreateEmailTemplateDialogComponent } from '../create-email-template-dialog/create-email-template-dialog.component';
import { SendEmailTemplateDialogComponent } from '../send-email-template-dialog/send-email-template-dialog.component';
import { EmailTemplateService } from './service/email-template.service';

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.css']
})
export class EmailTemplateComponent implements OnInit, OnDestroy {
  @Output() showEmailTemplate = new EventEmitter<boolean>();

  displayedColumns: string[] = [
    'sr', 'title', 'subject', 'createdAt', 'updatedAt', 'actions'];
  dataSource: any = new MatTableDataSource([]);
  emailTemplates = [];
  filter = {};
  project = {};
  dialogSubscription;
  constructor(
    private _authService: AuthService,
    private dialog: MatDialog,
    private _emailTemplateService: EmailTemplateService,
    private _toastr: ToastrService,


  ) { }

  ngOnInit() {
    this.getEmailTemplate();
  }

  ngOnDestroy(): void {
    if (this.dialogSubscription) {
      this.dialogSubscription.unsubscribe();
    }
  }
  getEmailTemplate() {
    this.project = {
      title: 1,
      subject: 1,
      createdAt: 1,
      updatedAt: 1
    }
    this._emailTemplateService.getEmailTemplate(this.filter, this.project).subscribe((response: any) => {
      if (response.status == 200) {
        this.emailTemplates = response.data;
        this.dataSource.data = this.emailTemplates;
      }
    }, err => {
      this._toastr.error(err, "ERROR");
    }
    );

  }

  goBack() {
    // this.showEmailTemplate.emit(false);
    this._emailTemplateService.changeIsCompanyEmailVisible(false);
  }

  deleteEmailTemplate(id, index) {
    this._emailTemplateService.deleteEmailTemplate(id).subscribe((response: any) => {
      if (response.status == 200) {
        this._toastr.success("Email template deleted", "Success");
        this.dataSource.data = [];
        this.emailTemplates.splice(index, 1);
        this.dataSource.data = this.emailTemplates;
        // this.dataSource.data = this.dataSource.data.filter(emailTemaplate => emailTemaplate._id !== id);
      }
    }, err => {
      this._toastr.error(err, "ERROR");
    }
    );
  }
  openTemplateDialog(emailTemplateId = null, index = null): void {
    let options: any = {
      width: '70%',
      height: '63vh'
    }
    options.data = emailTemplateId;
    this.dialogSubscription = this.dialog.open(CreateEmailTemplateDialogComponent, {
      ...options,
    }).afterClosed().subscribe((emailTemplate) => {
      if (emailTemplate) {
        this.dataSource.data = [];
        if (!emailTemplateId) { //For update view
          this.emailTemplates.push(emailTemplate);
        }
        this.emailTemplates[index] = emailTemplate;
        this.dataSource.data = this.emailTemplates;
      }
    });
  }

}
