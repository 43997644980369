<h3>
  <ng-container *ngIf="type === 'note' && !hideDetails">
    <ng-container *ngIf="note?.is_signed === 'false'">
      Sign Note Confirmation
    </ng-container>
    <ng-container *ngIf="note?.is_signed === 'true'">
      Upload Note Confirmation
    </ng-container>
  </ng-container>
  <ng-container *ngIf="type === 'addendum'">
    Addendum Note Confirmation
  </ng-container>
</h3>

<div class="flex flex-col gap-2" style="font-size: 12px;line-height: 2;">


  <div class="flex flex-col">

    <h3 style="text-align: center;font-weight: bold;">{{note?.facility_id_ref?.title}}</h3>

    <div style="background-color: rgb(223, 223, 223); border:1px solid #000;padding:4px"><strong>Resident Name:</strong> {{note?.patient_id_ref?.name}}</div>
      

      <div *ngIf="type === 'note'">
        <strong>Effective Date: </strong>
        <span>{{currentDate | date: 'MM/dd/yyyy H:mm'}}</span>
      </div>

      <div>
        <strong>Type: </strong>
        <span>{{note?.transcribtion_title}}</span>
      </div>
      
      <div style="border-bottom: 1px solid #000; margin:4px 0"></div>

      <div>
        <u><strong>Date of Service: </strong></u>
        <span>{{isValidDateObj() ? getDOS() : note?.date_of_service | date: 'MM/dd/yyyy hh:mm a'}}</span>
      </div>

      <div>
        <u><strong>Visit Type: </strong></u>
        <span>{{note?.transcribtion_title | uppercase}}</span>
      </div>

      <div>
        <u><strong>Transition of Care: </strong></u>
        <span>N/A</span>
      </div>

      <div>
        <u><strong>History Details: </strong></u>
        <span>N/A</span>
      </div>
      
    
      <div *ngIf="type === 'note'">
        <u><strong>Note Details: </strong></u>
        <pre [innerText]="note?.transcribtion_text"></pre>
      </div>

      <div *ngIf="type === 'addendum'">
        <u><strong>Addendum Details: </strong></u>
        <pre [innerText]="note?.transcribtion_text"></pre>
      </div>

    <br />
    <div>
      <u><strong>Provider Name: </strong></u>
      <span>{{getProviderName()}}</span>
    </div>

    <div *ngIf="type === 'note'">
      <u><strong>Signed Date: </strong></u>
      <span>{{currentDate | date: 'MM/dd/yyyy hh:mm a'}}</span>
    </div>
    <div *ngIf="type === 'addendum'">
      <u><strong>Addendum Date: </strong></u>
      <span>{{currentDate | date: 'MM/dd/yyyy hh:mm a'}}</span>
    </div>
  </div>


  
  <ng-container *ngIf="type === 'note' && !hideDetails">

    <mat-error style="text-align: right;" *ngIf="hasPCCPatient(note) && !hasLoggedInToPCC() && !canUploadNoteToPCCUsingTwoLegged() && note.is_signed === 'true'">You are not logged into PCC. Are you sure you want to manually upload?</mat-error>
  </ng-container>

  <div class="flex flex-row-reverse items-center gap-5">


    <ng-container *ngIf="type === 'note' && !hideDetails"> 
      <ng-container *ngIf="note?.is_signed === 'false'">
        <div class="flex flex-col justify-start items-end">
          <mat-checkbox
          [(ngModel)]="showDialog"
          (change)="$event.checked = !$event.checked"
          >
            Do not show this confirmation screen next time.
          </mat-checkbox>
          <div>
            You can re-enable it in
            <button mat-button [routerLink]="['/user-settings']" style="font-size:12px; padding:0">Settings</button>
          </div>
        </div>
      </ng-container>
  
      <ng-container *ngIf="note?.is_signed === 'true'">
        <div class="flex flex-col justify-start items-end">
          <mat-checkbox 
          [(ngModel)]="showDialog"
          (change)="$event.checked = !$event.checked"
          >
            Do not show this confirmation screen next time.
          </mat-checkbox>
          <div>
            You can re-enable it in
            <button mat-button [routerLink]="['/user-settings']" style="font-size:12px; padding:0">Settings</button>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <button mat-raised-button class="dn-button-secondary" [mat-dialog-close]="false">Cancel</button>

    <ng-container *ngIf="type === 'note' && !hideDetails">
      <button mat-raised-button class="dn-button-primary" [mat-dialog-close]="true" (click)="updateOtherSettings()">
        {{confirmButtonLabel || 'Confirm & Upload'}}
      </button>
    </ng-container>

    <ng-container *ngIf="type === 'addendum' && !payload">
      <button mat-raised-button class="dn-button-primary" [mat-dialog-close]="{action: 'saveAddendum'}">
        {{confirmButtonLabel || 'Save'}}
      </button>
    </ng-container>
    <ng-container *ngIf="type === 'addendum' && payload">
      <button mat-raised-button class="dn-button-primary" [mat-dialog-close]="{action: 'saveAddendum'}" *ngIf="payload.action === 'save'">
        {{confirmButtonLabel || 'Save'}}
      </button>
      <button mat-raised-button class="dn-button-primary" [mat-dialog-close]="{action: 'uploadAddendum'}" *ngIf="payload.action === 'upload'">
        Upload
      </button>
    </ng-container>

  </div>

  <div class="top-right-close-btn">
      <button mat-mini-fab mat-dialog-close ><mat-icon >cancel</mat-icon></button>
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

</div>