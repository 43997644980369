import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { PCCService } from 'src/app/services/pcc.service';
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { FacilityActivationEmailDialogComponent } from './facility-activation-email-dialog/facility-activation-email-dialog.component';

@Component({
  selector: 'app-facility-activation-email',
  templateUrl: './facility-activation-email.component.html',
  styleUrls: ['./facility-activation-email.component.css']
})
export class FacilityActivationEmailComponent implements OnInit {

  displayedColumns: string[] = [
    // 'emailIds', 'ccEmailIds', 
    'recipient', 'regarding', 'provider_name', 'company_name', 'createdAt', 'actions'];
  dataSource: any = new MatTableDataSource([]);
  @Output() back = new EventEmitter<boolean>();
  facilityActivationDetails: any = [];
  company_id: any;
  companyName: any;
  @Input() currentCompany: any;


  constructor(
    private dialog: MatDialog,
    private pccService: PCCService,
    private _route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.initFacilityActivationDetails();
  }


  initFacilityActivationDetails() {
    this.pccService.getFacilityActivationDetails({}).subscribe((res: any) => {
      console.log("res", res);
      this.facilityActivationDetails = res.data;
      this.dataSource.data = this.facilityActivationDetails;
    });
  }
  goBack() {
    this.back.emit(false);
  }
  openDialog(id = null ) {
    let option: any = {
      company: this.currentCompany
    };
    if(id) {
      option.facilityActivationId = id;
    }
    let dialogRef = this.dialog.open(FacilityActivationEmailDialogComponent, {
      width: '70%',
      height: '90vh',
      panelClass: 'pcc-email-template-dialog-container-class',
      data: option
    });
    dialogRef.afterClosed().subscribe((data) => {
      
      if (data) {
        // this.facilityActivationDetails.push(data);
        // this.dataSource.data = this.facilityActivationDetails;
        this.initFacilityActivationDetails();

      }
    });
  }
}
