import { Component, ViewChild, DoCheck, OnInit, ElementRef, AfterViewInit, OnDestroy, NgZone, ChangeDetectorRef, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { Location, DatePipe } from '@angular/common';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {
    TranscriptionDetailService,
    FilesAdd,
    CreatePdfService
} from '../transcription-detail/transcription-detail.service';
import { NoteEditorService } from './note-editor.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranscriptionTemplateDialog } from '../transcription-create/transcription-template-dialog/transcription-template-dialog.component';
import { PatientsDialog } from '../transcription-create/patients-dialog/patients-dialog.component';

import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, ParamMap, RoutesRecognized } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { BehaviorSubject, lastValueFrom, Observable, combineLatest, Subscription, forkJoin, from } from 'rxjs';
import { map, startWith, filter, pairwise } from 'rxjs/operators'
import { Template } from '../templates/templates.component';
import * as global from '../global';
import { SocketService } from 'src/app/services/socket.service';
import { PatientDetailService } from '../patient-detail/patient-detail.service';
import { PatientAddDialog } from '../transcription-create/patient-add-dialog/patient-add-dialog.component';
import { CommonService } from 'src/app/services/common.service';
import { TranscriptionService } from 'src/app/services/transcription.service';
import { AuthService } from 'src/app/services/auth.service';
import * as _ from 'lodash';
import swal from 'sweetalert';
import { PCCService } from 'src/app/services/pcc.service';
import { FormControl, Validators } from '@angular/forms';
import { NoteBuilderService } from "../../services/note-builder.service";
import { TemplatesService } from "../templates/templates.service";
import { DictionaryDialogComponent } from "../../shared/popup-dialogs/dictionary-dialog/dictionary-dialog.component";
import moment from 'moment-timezone';
import { AddendumNoteDialog } from '../transcription-create/addendum-note-dialog/addendum-note-dialog.component';
import { UploadNoteConfirmationDialog } from '../transcription-create/upload-note-confirmation-dialog/upload-note-confirmation-dialog.component';
import { AddendumService } from '../transcription-create/note-addendums/addendum-service.service';
import { UserService } from 'src/app/services/Modules/user.service';
import { Note } from './model/note'
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NoteAuditoryHistoryService } from '../note-auditory-history/note-auditory-history.service';
import { CKEditorComponent } from 'ckeditor4-angular';
import { PhrasesService, systemDefinedPhrases, removeUnSelectedFields } from '../phrases/phrases.service';
import { DictionaryEditorDialogComponent } from '../phrases/phrase-editor-dialog/phrase-editor-dialog.component';
import { CKEditorService } from './ckeditor.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { RouterExtService } from 'src/app/services/router-ext.service';
import { GoBackUrlService } from 'src/app/services/goBackUrl.service';
import { GlobalPubSub } from 'src/app/services/global-pub-sub.service';
import { CensusListService } from '../census-list/census-list..service';
import { CensusPatientListService } from '../census-patient-list/census-patient-list.service';
import { TranscriptionCreateService } from '../transcription-create/transcription-create.service';
import { PccWidgetService } from '../pcc-widget/pcc-widget.service';
import { SidebarDiagnosisService } from 'src/app/components/sidebar-addCharges/sidebarDiagnonses/sidebar-diagnoses/sidebar-diagnosis.service';
import { NoteSidebarComponent } from '../note-sidebar/note-sidebar.component';
import { PatientRoundingSheetService } from '../patient-rounding-sheets/patient-rounding-sheet.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NoteAddChargeService } from '../note-sidebar/note-addCharge/note-add-charge.service';
import { CompanyService } from 'src/app/services/Modules/company.service'
import { PatientListService } from '../patient-list/patient-list-service';
import { ReasonDialogComponent } from './reason-dialog/reason-dialog.component';
import { WoundCareWidgetService } from '../note-sidebar/wound-care-widget/wound-care-widget.service';
import { DialogEmailSendingComponentComponent } from '../dialog-email-sending-component/dialog-email-sending-component.component';
// import * as htmlCompare from 'html-compare';
import { WoundService } from '../../services/wound.service';
import { debounce } from 'lodash';
import { FacilitySettingsService } from 'src/app/components/company/company-facilities/facility-settings/facility-settings.service';
import { calculateArea } from '../note-sidebar/wound-care-widget/wound-utils';
import { PatientAddService } from '../patient-add/patient-add-service';
import { Buffer } from 'buffer';
import { ProcedureNotesService } from 'src/app/services/procedure-notes.service';

declare function NUSA_floatAble(): any;
declare function NUSA_sticky(): any;
declare function reIntializeNusa(): any;
declare function loadnVoqKit(username, key): any;
declare function destroynVoqKit(): any;
declare function enableSpellCheck(status, type): any;

@Component({
    selector: 'app-note-editor',
    templateUrl: './note-editor.component.html',
    styleUrls: ['./note-editor.component.css'],
    providers: [DatePipe]
})


export class NoteEditorComponent implements OnInit, OnDestroy, DoCheck, AfterViewInit {    
    @ViewChild('mainEditor', { static: false }) mainEditor: CKEditorComponent;
    @ViewChild('addendumEditor', { static: false }) addendumEditor: CKEditorComponent;    

    mainEditorInstance: any = null;
    addendumEditorInstance: any = null;
    
    global = global;
    id: {
        note?: string,
        audio?: string,
    };
    htmlContent: string = '';
    timeUp: Boolean = false;
    originaNoteText: string = '';
    template_attached = false;
    data: any;
    currentaudio: any;
    currentaudioFacilityTitle: any;
    showLowMemoryBanner: boolean = false;
    startTime: any;
    pausedTime: any;
    resumeTime = 0;
    totalTime = 0;
    tempTime: any;
    lastStrockedTime: any;
    currentStrockTime: any;
    timeElapsedFlag = false;
    chargeCaptureFlag = false;
    currentUser: any;
    superAdmin: any;
    transcription: any;
    pathfile: any;
    selectedFile: any;
    selectedFacilityTitle: any;
    transcriptionFiles: Array<any> = [];
    dictationFiles: Array<any> = [];
    nextPrev = 'tran';
    currentFileIndex: number = -1;
    currentFileIndexDict: number = -1;
    saveChargeAfterNote = false;
    is_uploadPcc_permission_enabled = false;
    hide_addendum_button: any = -1;
    hide_upload_addendum_button: any = -1;
    getFacilitySettingsResponse: any;

    addendumContainsAsterisks: boolean = false;
    containsAsterisks: boolean = false;
    addendumContainsUnselectedDropdowns: boolean = false;
    containsUnselectedDropdowns: boolean = false;
    containsUnresolvedPhrases: boolean = false;

    // response:any;
    docFileResult: any;
    title: string = '';
    public backURL: any;
    private fromSuperAdmin: any;
    public hide: any;
    public backURLString = '/transcription-detail';
    public loading = false;
    public isNew: boolean = false; // determine if creating new transcription or editing existed one
    public category: string = '';
    public readonly: any;
    public paramEditable: any;
    public doctors: any;
    public facilities: any;
    public facility_title: string = '';
    public patients: any;
    // public filteredPatients: any;
    public patientSource: string = 'portal';

    public selectedDoctorId: string = '';
    public selectedFacilityId: string = '';
    public selectedDoctorName: string = '';
    public currentDate: Date = new Date();
    public date_of_service: Date | string = moment.utc().toDate();
    // public date:string = null;
    public selectedPatientId: string = '';
    public selectedPatient: any = '';

    currentTemplate: Template = null;

    public formatted_date_of_service: String; // displayed on create transcription against audio #mohsin-dev
    public currentDoctor: any; // for dict create page only #mohsin-dev
    isPrevDisabled: boolean = false;
    isNextDisabled: boolean = false;
    initialState: string = null;
    isTouched: boolean = false;

    private MAX_CKEDITOR_LENGTH: number = 150000;

    isAddMore = false;
    permission = {
        is_qa: false,
        is_editable: true,
        is_locked: false
    };
    timePicked = false;
    recentNotes: Array<any> = [];

    sidebarVisible: boolean = true;
    videoSidebarVisible: Boolean = false;


    patientControl = new FormControl();
    filteredPatients: Observable<any[]>;
    patient_audio = {
        is_patient: false,
        name: undefined
    };


    transcriptionCreateLoader = 'transcription-create-loader';
    transcriptionCreateLoader_inprogress = false;

    audioId = '';
    cke4Config;
    editorInstance: any;
    configg = {
        placeholder: 'Type the content here!',
        isReadOnly: false
    };
    count: number = 0;
    backSlider = 3;
    speedSlider = 1.0;

    ckEditorReady = false;
    eventCheck: any;
    phrases = [];
    save_submit = false;

    isCreatingAddendum = false;

    existInNoteText = {
        facility: false,
        provider: false,
        patient: false,
        date_of_service: false
    };

    isMatchAudioDOSWithNoteDOS = false;

    // selected from sidebar
    addendum: any = {
        text: ''
    };

    // NEW STUFF
    loaderId = 'note-editor-loader';
    note: Note; // all selected values
    addendum_counts: {
        saved: number,
        total: number,
        uploaded: number
    }
    buttonsState = {
        save: false,
        submitForReview: false,
        revert: false,
        sign: false,
        upload: false,
        addendum: false,
        uploadAddendum: false
    }

    originalNoteState: {
        provider: Record<string, any>,
        facility: Record<string, any>,
        patient: Record<string, any>,
        title: string,
        dates: Record<string, any>,
        htmlText: string
    }
    originalAddendumState;
    recentAudit?: {
        user_id: {
            _id: string,
            first_name: string,
            last_name: string
        },
        doctor_id: string,
        facility_id: string,
        note_id: string,
        audio_id: string,
        operation: String,
        note_text: String,
        timestamp: Date
    }
    relationship: {
        assoc_trancriber_id: string[],
        qa_id: string,
        qa_e_id: string,
        assoc_ma_ids: string[];
    }
    assoc_provider_ids = [];

    isActiveTimeout = null;
    isActiveAutoSaveDraftNoteTimeout = null;
    isActiveAutoSaveNoteTimeout = null;

    editLockTime = null;
    editLockTimeInterval = 10; // seconds

    censusId: string;

    dateOfService: any = new Date();
    @ViewChild('patientAutoComplete', { read: MatAutocompleteTrigger }) patientAutoComplete: MatAutocompleteTrigger;

    @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

    dashboardData = {
        notes: [],
        audios: [],
        note: {
            next: null,
            prev: null,
        },
        audio: {
            next: null,
            prev: null,
        },
    }

    autoSavedOn: Date;
    telemedicine: any = false;
    associatedProvidersDropDown: boolean = false;
    associatedProviders: any = [];
    hasAssociatedProviders: boolean = false;
    coSignedProvider: any = {};
    coSignedProviderReserve: any = {};
    timer: any;
    saveError: boolean = false;
    charges: any;
    hiddenFacilites: any = [];
    patientListData: any = {};
    patientListSubscription: any;
    nextPatientNote: boolean = false;
    patientId: string;
    charge: any;
    chargeCaptureChargeModel: any;
    noteEditorIcds: any[] = [];
    noteEditorCpts: any[] = [];
    telemedPhrase: any = false;
    showOnceTemplateDialog = false;
    drafteNoteState: string;
    @ViewChild(NoteSidebarComponent) noteSidebarComponent: NoteSidebarComponent;
    aDAte: any;
    time_zone = 'America/Los_Angeles';
    zone = "";
    dateOfS = "DOS"
    serviceDate: any = new Date();
    dateChangeObj = {};
    newNote = false;
    // facility_id;
    woundPhrases
    isChargeCaptureLoaded: boolean = false;
    subscription: Subscription;
    shouldShowTemplateDialog: boolean = true;
    mustSignNote: boolean = false;
    carryChargeStatusIcds: boolean = false;
    carryChargeStatusCpts: boolean = false;
    isActiveAutoSaveNotechargeTimeout: any;
    OldchargeCaptureChargeModel: any;
    dragonTimeoutId: any;
    // phrases:any;
    dictionary: any;
    emCodeEditorCpts: any[] = [];
    noteErrors: {
        [key: string]: {
            message: string,
            reason: "pcc_error" | "aesterik"
        }
    };
    refreshICD10Phrase = null;
    autoSaveInprogress: boolean = false;
    handleEditorCount = 0;
    companySubmitMipsStatus: boolean = false;
    handleEditorChangeDebouncedCount = 0;
    configuration: any = {
        wound_assessment_report_category: "",
        progress_note_submission_type: "",
        wound_assessment_report_submission_type: "",
        wound_assessment_report_name: ""
    };
    woundsDocumentedError = false;
    noteTitleControl = new FormControl(null, Validators.required);
    totalWoundsLength: any;
    mustCheckWoundDatesforSignNote: Boolean = false;
    woundNosWithoutDates: any = [];
    woundsDateErrorforSignNote: boolean = false;
    isPatientTags: boolean = false;
    newHtmlText: any;
    alreadyAssociatedTags = [];
    noteEditorPatientTags = [];
    
    shouldShowNoteTimeLogs: boolean = false;
    timerRunning = false;
    shouldShowTimer: boolean = false;
    displayTime = '00:00:00';
    private timerInterval: any;
    private elapsedTime: number = 0;
    companyPermissions: any;
    myEditor;

    public constructor(
        public TranscriptionDetail: TranscriptionDetailService,
        private noteEditorService: NoteEditorService,
        private addendumService: AddendumService,
        private toastr: ToastrService,
        private createpdfservice: CreatePdfService,
        private router: Router,
        private dashboardService: DashboardService,
        private _location: Location,
        private _route: ActivatedRoute,
        private _router: Router,
        private encryptDecryptService: EncryptDecryptService,
        private dialog: MatDialog,
        public _date_pipe: DatePipe,
        private templateService: TemplatesService,
        private _socketService: SocketService,
        private commonService: CommonService,
        private builderService: NoteBuilderService,
        private transcriptionService: TranscriptionService,
        private _authService: AuthService,
        private _pccService: PCCService,
        private userService: UserService,
        private loader: NgxUiLoaderService,
        private noteAuditoryHistoryService: NoteAuditoryHistoryService,
        private phrasesService: PhrasesService,
        private ckeditorService: CKEditorService,
        private goBackUrlService: GoBackUrlService,
        private _censusPatientListService: CensusPatientListService,
        private censusListService: CensusListService,
        private _changeDetectorRefs: ChangeDetectorRef,
        private _transcriptionCreateService: TranscriptionCreateService,
        private ngZone: NgZone,
        private pccWidgetService: PccWidgetService,
        private sidebarDiagnosisService: SidebarDiagnosisService,
        private censusPatientListService: CensusPatientListService,
        private patientRoundingSheetService: PatientRoundingSheetService,
        private _noteAddChargeService: NoteAddChargeService,
        private _company: CompanyService,
        private _patientsListService: PatientListService,
        private woundCareWidgetService: WoundCareWidgetService,
        private woundService: WoundService,
        private _facilitySettingsService: FacilitySettingsService,
        private editPatientService: PatientAddService,
        private procedureNotesService: ProcedureNotesService,
    ) {
        (window as any).changeInCKEditorbySpellChecker = this.changeInCKEditorbySpellChecker.bind(this);
        this.currentUser = this._authService.currentUser;
        // this.currentUser.enabled_speech_kit = false;
        this.telemedicine = this._route.snapshot.paramMap.get('telemedicine');
        if (!this.telemedicine || this.telemedicine == false || this.telemedicine == 'false') {
            this.telemedicine = false;
            this.videoSidebarVisible = false
        }
        else {
            this.telemedicine = true;
            this.videoSidebarVisible = true
        }
        // @ts-ignore
        // window.customplugin_oninsertAbbr = this.initNewMentions.bind(this);

        this.cke4Config = this.ckeditorService.getDefaultConfig();
        this.cke4Config.exportPdf_options = {
            margin_top: '1.5cm',
            margin_bottom: '1.5cm',
            margin_left: '1.5cm',
            margin_right: '1.5cm',
        }


        // if([global.DEV, global.LOCAL].indexOf(global.ENVIRONMENT) >= 0) {
        const extraPlugins = this.cke4Config.extraPlugins.split(',');
        this.cke4Config.extraPlugins = [...extraPlugins, 'selectdropdown', 'textFieldDropdown', 'phrasesautocomplete', 'icdautocomplete', 'asteriskjump'].join(',')
        if (global.ENVIRONMENT === global.LOCAL) {
            this.cke4Config.extraPlugins += ',sourcearea'
        }
        this.cke4Config.disallowedContent = 'div';
        this.cke4Config.extraAllowedContent = 'p span[id][style](prevnote-phrase,phrase,pcc-phrase,phrase-error,asteriskjump,text-align-center,text-align-right,icd10-code,pdpm_highlight,note-signed-on,content-hidden); *(undeletable); *[contenteditable]'
        // }

        this.phrasesService.resetPhrasesData();
        // this.startTime =  moment(new Date());
        this.noteTitleControl.markAsTouched();
        this.noteTitleControl.markAsDirty();
    }

    async ckeditorReady(e) {
        const { editor } = e;
        this.myEditor = editor;
        editor.widgets.on('instanceCreated', (evt) => {
        });
    }

    initSimpelboxWidget = (evt) => {
        evt.sender.editables.content.$.addEventListener('input', this.handleInputChange)
    }
    handleInputChange = (e) => {
        const ccName = e.target.innerText;

        this.initNewMentions(ccName);

    }
    closeSidebar(event) {
        this.sidebarVisible = false;
    }
    closeVideoSidebar(event) {
        this.videoSidebarVisible = false;
    }

    initNewMentions(ccName) {
        const ccData = this.noteEditorService.getCC(ccName);

        if (ccData) {

            // let cke = _.cloneDeep(this.cke4Config);
            // let mentions = cke.mentions ? [ ...cke.mentions ] : [];

            // mentions = [
            //     ...mentions,
            //     {
            //         feed: ccData['@pe'],
            //         marker: '$',
            //         minChars: 0
            //     },

            //     {
            //         feed: ccData['@ros'],
            //         marker: '%',
            //         minChars: 0
            //     }
            // ];
            // cke.mentions = mentions;

            // this.cke4Config = cke;
            this.cke4Config.mentions = this.cke4Config.mentions.concat([
                {
                    feed: ccData['@pe'],
                    marker: '$',
                    minChars: 0
                },

                {
                    feed: ccData['@ros'],
                    marker: '%',
                    minChars: 0
                }
            ])

            this.reloadCKEditor(1000);

        }

    }
    setLoading(isLoading, isBlockUI) {
        if (!isBlockUI && isLoading) {
            this.loader.startBackgroundLoader(this.loaderId);
        } else if (!isBlockUI && !isLoading) {
            this.loader.stopBackgroundLoader(this.loaderId);
        } else if (isBlockUI && isLoading) {
            this.loader.startLoader(this.loaderId);
        } else if (isBlockUI && !isLoading) {
            this.loader.stopLoader(this.loaderId);
        }
    }
    initGlobalScope() {
        window['angularComponentReference'] = {
            component: this, zone: this.ngZone,
            componentDataMembers: {
                componentName: 'note-editor',
                phrases: this.phrasesService.castPhrases.pipe(map((phrases: any) => {
                    const woundPhrases = ['icd10wound@dn']
                    if (this.currentUser.company_type === 'Wound' || this.currentUser.company_type === global.COMPANY_TYPE.SNF) {
                        return phrases;
                    } else {
                        return phrases.filter(phrase => !woundPhrases.includes(phrase.key));
                    }
                })),

                selectdropdown: {
                    disableOptionSelection: () => {
                        return this.note.isSigned() && !this.isCreatingAddendum
                    },
                    disableDropdownRender: () => {
                        return this.note.isSigned() && !this.isCreatingAddendum
                    },
                    showAsPlainText: () => {
                        return this.note.isSigned() && !this.isCreatingAddendum
                    }
                },
                textFieldDropdown: {
                    showAsPlainText: () => {
                        return this.note.isSigned() && !this.isCreatingAddendum
                    }
                }
            },
            componentFunctions: {
                isNoteSigned: () => this.isNoteSigned(),
                generatePDFNote: () => this.generatePDFNote(),
                applyDblClickToSelectPhrases: (editor) => this.applyDblClickToSelectPhrases(editor),
                resolveSystemDefinedPhraseValue: (key, extra) => this.resolveSystemDefinedPhraseValue(key, this.note, extra),
                hasPCCPatient: () => !!(this.note.patient?.pcc_patientId),
                creatingAddendum: () => (this.isCreatingAddendum && (this.note.note_status.status === 'uploaded')),
                getICDs: (data) => this.censusListService.getICDs({ all: true, ...data }),
                addICD: (data) => this.addICD(data),
                applyAsteriskJump: (htmlText) => this.applyAsteriskJump(htmlText),
                initDynamicPhrasesToCKEditor: (query) => this.initDynamicPhrasesToCKEditor(query),
                updateWoundfromNote: (id, addendum_id, extended_id,key, value) => this.updateWoundfromNote(id, addendum_id, extended_id, key, value),
                updateProcedureNotefromNote: (id, header_id, key, value, label = '') => this.updateProcedureNotefromNote(id, header_id, key, value, label),
                initProcedurePhrasesToCKEditor: (query) => this.initProcedurePhrasesToCKEditor(query),
                onResolvePhraseStart: (phrase) => {

                    this.loader.startBackgroundLoader("phrase_loader");
                    
                    // if (this.phrasesService.isAPIDependentPhrase(phrase)) {
                    //     if (this.phrasesService.phrasesData[this.note.patient._id]) {
                    //         const value = this.phrasesService.phrasesData[this.note.patient._id][phrase];
                    //         if (value === undefined) {
                    //         }
                    //     }
                    // }
                    // this._changeDetectorRefs.detectChanges();
                },
                onResolvePhraseEnd: (phrase, error) => {

                    this.loader.stopAllLoader("phrase_loader");
                    setTimeout(() => {


                        this._changeDetectorRefs.detectChanges();


                    });


                    // if (this.phrasesService.isAPIDependentPhrase(phrase)) {
                    //     setTimeout(() => {
                    //     }, 1000)
                    // }
                }
            }
        };
    }

    async ngOnInit() {       
        await this.getCompanyPermissions();
        await this.checkSpellChecker();    
        
        let chargeStatusres: any = await lastValueFrom(this._company.getCompanyChargeandSignNoteStatus(this.currentUser.company_id)) as any;
        if (chargeStatusres.status == 200) {
            if (chargeStatusres.data[0].charges_carry_forward_icds != undefined || chargeStatusres.data[0].charges_carry_forward_icds != null) {
                this.carryChargeStatusIcds = chargeStatusres.data[0].charges_carry_forward_icds;
            }
            if (chargeStatusres.data[0].charges_carry_forward_cpts != undefined || chargeStatusres.data[0].charges_carry_forward_cpts != null) {
                this.carryChargeStatusCpts = chargeStatusres.data[0].charges_carry_forward_cpts;
            }
            if (chargeStatusres.data[0].should_sign_note != undefined || chargeStatusres.data[0].should_sign_note != null) {
                this.mustSignNote = chargeStatusres.data[0].should_sign_note;
            }
            if (chargeStatusres.data[0].wound_dates_for_sign_note != undefined || chargeStatusres.data[0].wound_dates_for_sign_note != null) {
                this.mustCheckWoundDatesforSignNote = chargeStatusres.data[0].wound_dates_for_sign_note;
            }
        }
        
        this.initGlobalScope();
        this._censusPatientListService.castAddCharge.subscribe(charge => {
            this.charges = charge || []
        })

        this.dashboardService.castNotes.subscribe(notes => {
            this.dashboardData.notes = notes || [];
        });
        this.dashboardService.castAudios.subscribe(audios => {
            this.dashboardData.audios = audios || [];
        });

        this._route.paramMap.subscribe(async (params: ParamMap) => {
            this.loader.startBackgroundLoader(this.loaderId);
            this.paramEditable = !!params.get('editable');
            // census > create note
            const paramCensusId = this._route.snapshot.paramMap.get('census_id');
            if (this._route.snapshot.paramMap.get('shouldShowTemplateDialog') === "false") {
                this.shouldShowTemplateDialog = false;
            }
            if (paramCensusId) {
                this.censusId = paramCensusId;
                // this.note.rounding_sheet_id = paramCensusId;
            }
            const paramPatientId = this._route.snapshot.paramMap.get('patient_id');
            if (paramPatientId) {
                this.patientId = paramPatientId;
                // this.note.rounding_sheet_id = paramCensusId;
            }
            const noteId = params.get('note_id');
            if (noteId) {
                await this.existingOnInit(noteId);
                await this.initFacilitySettings();
            } else {
                this.newNote = true;
                await this.newOnInit();
            }

            let response: any = await lastValueFrom(this._facilitySettingsService.getFacilitySettings(this.note.facility._id, this.currentUser.company_id));
            if(response.status == 200){
                this.getFacilitySettingsResponse = response.data;
            }

            this.setStatesForAddendumButton();

            this.loader.stopBackgroundLoader(this.loaderId);
            if (this.currentUser.enabled_speech_kit) {
                if (this.currentUser.enabled_speech_kit.includes("dragon")) {
                    if (this.note.is_signed == 'false') {
                        this.dragonTimeoutId = setTimeout(() => {
                            this.loadNuanceBrowserKit()
                        }, 5000);
                    }
                } else if (this.currentUser.enabled_speech_kit.includes("nVoq")) {
                    loadnVoqKit(global.NVOQ_CREDS.USERNAME, global.NVOQ_CREDS);
                }
            }
            if (this.censusId) {
                // this.censusId = paramCensusId;
                this.note.rounding_sheet_id = paramCensusId;
            }
            if (this.note?.note_status?.status == 'published' &&
                (this.currentUser.user_type == global.USER_TYPE.DOCTOR || this.currentUser.user_type == 'provider' || this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE)
                // && this.currentUser._id == this.note.provider._id
                &&
                this.currentUser.title != 'M.D.' && this.currentUser.title != 'M.D' && this.currentUser.title != 'MD' &&
                this.currentUser.title != 'D.O.' && this.currentUser.title != 'D.O' && this.currentUser.title != 'DO'
            ) {
                await this.dashboardService.assocProviders(this.note.provider._id, this.note.facility._id).subscribe((response: any) => {
                    if (response.status == 200) {
                        if (this.note?.coSign_status?.status) {
                            this.associatedProvidersDropDown = true
                        }
                        if (response.data.length > 0) {
                            this.hasAssociatedProviders = true;
                            this.associatedProviders = response.data;
                            // this.coSignedProvider = this.note.note_status.id

                            this.coSignedProvider = this.associatedProviders.filter(a => {
                                if (a._id == this.note?.coSign_status?.coSigned_to) {
                                    return a
                                }
                            })
                            this.coSignedProvider = this.coSignedProvider[0];
                        }
                    }
                })
            }

            this.initNextPrev();
        })
        await this.getPhrases();
        const res = await lastValueFrom(this._company.getCompany({_id : this.currentUser.company_id}, { should_show_note_timelogs_tab: 1, portal_type: 1 })) as any;
        if(res.status == 200) {
            this.shouldShowNoteTimeLogs = res.data.should_show_note_timelogs_tab;
        }

        this.phrasesService.castPhrases.subscribe(dictionary => {
            this.dictionary = dictionary;
        });

        this.ckEditorReady = true;

    }

    async setStatesForAddendumButton() {
        if (this.note.isSigned() && !this.note.isUploaded()) {
            this.hide_addendum_button = false;
            if (this.getFacilitySettingsResponse) {
                if (this.getFacilitySettingsResponse.configuration && this.getFacilitySettingsResponse.configuration.progress_note_submission_type == "pcc") {
                    this.is_uploadPcc_permission_enabled = true;
                    this.hide_addendum_button = true;
                    this.hide_upload_addendum_button = false;
                }
                else {
                    this.hide_upload_addendum_button = true;
                    this.is_uploadPcc_permission_enabled = false;
                }
            }
        }

        if ((this.addendum_counts?.saved >= 0 && this.note.isSigned() && !this.note.isUploaded()) || ((this.addendum_counts?.saved === 0 && this.note.isUploaded())))
            this.hide_addendum_button = false;
    }
    
    async addICD(icd) {
        if (this.chargeCaptureChargeModel && this.chargeCaptureChargeModel.status === 'submit') return;
        this.noteEditorIcds.push({ ...icd });

        // const beforeSidebarVisibleState = this.sidebarVisible;


        // this.sidebarVisible = true;
        // const icds = [...(this.chargeCaptureCharge?.icd_id || []), ...this.noteEditorIcds];
        
        // const exists = this.noteEditorIcds.find(i => i.code === icd.code);
        // if (!exists) {
        //     // this.noteSidebarComponent.addICD(icd);
        //     this.noteEditorIcds.push({...icd, selected: true});
        // }

        // await this.noteSidebarComponent.addCharge();
        // this.sidebarVisible = beforeSidebarVisibleState;
    }
    async addCPT(cpt) {
        if (this.chargeCaptureChargeModel && this.chargeCaptureChargeModel.status === 'submit') return;
        this.noteEditorCpts.push({ ...cpt });

        // const beforeSidebarVisibleState = this.sidebarVisible;


        // this.sidebarVisible = true;
        // const icds = [...(this.chargeCaptureCharge?.icd_id || []), ...this.noteEditorIcds];

        // const exists = this.noteEditorIcds.find(i => i.code === icd.code);
        // if (!exists) {
        //     // this.noteSidebarComponent.addICD(icd);
        //     this.noteEditorIcds.push({...icd, selected: true});
        // }

        // await this.noteSidebarComponent.addCharge();
        // this.sidebarVisible = beforeSidebarVisibleState;
    }
    async handleICDsSave(data) {
        if (data.wound_id) {
            this.refreshICDWound10phrase(data);
            for (const icd of data.icds_data) {
                const icdExists = !!this.noteEditorIcds.find(_icd => _icd._id === icd._id);
                if (!icdExists) {
                    icd['wound_diagnose'] = true;
                    this.addICD(icd);
                }
            }
        } else {
            for (const icd of data) {
                const icdExists = !!this.noteEditorIcds.find(_icd => _icd._id === icd._id);
                if (!icdExists) {
                    this.addICD(icd);
                }
            }
        }
    }
    handleChargeChange(e) {
        this.chargeCaptureChargeModel = e;
        if (!this.chargeCaptureChargeModel.charge_id && this.chargeCaptureChargeModel.charge_id == '') {
            this.autoSavecharge("charge");
        }
    }
    pccSignInAlert() {
        if (!this.isNew && this.note.hasPCCPatient() && this.note.isSigned() && !this.note.isUploaded() && (!this.hasLoggedInToPCC() || !this.canUploadNoteToPCCUsingTwoLegged()) && this.note.isRelatedProvider(this.currentUser._id)) {
            swal({
                title: "Sorry",
                text: "This note was NOT uploaded into PCC. Please sign in to PCC to upload the note.",
                icon: "warning",
                buttons: [
                    false, true
                ],
                dangerMode: false,
            })
                .then((res) => {
                });
        }

    }
    onNoteEdiorCptsChange(e) {
        this.noteEditorCpts = e;
    }

    handleChargeICDChange(e) {
        this.autoSavecharge("charge");
    }
    async initCharge() {
        if (!this.newNote && this.note.patient) {
            const details = {
                patient_id: this.note.patient._id,
                rounding_sheet_id: this.note.rounding_sheet_id,
                visit_date: this.note.dates.service,
                status: null
            }
            let response: any = await this.censusListService.getCharge(details).toPromise()
            // subscribe((response: any) => {
            if (response.status === 200) {
                this.charge = response.data;
            }
            // })
        }
    }

    async onChangeCoSignCheckBox(checked) {
        if (this.note?.coSign_status?.status == true) {
            this.coSignNote(false)
        }
        else {
            // this.coSignNote(true)
            // coSignSelect.open()
        }
    }

    isProvider() {
        if (this.currentUser.user_type == '1') {
            return true;
        }
        else
            return false;
    }

    isSnfWcNurse() {
        if (this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE) {
            return true;
        }
        else
            return false;
    }

    async newOnInit() {
        this.note = new Note();
        //this.note.htmlText=this.note.htmlText+'<br>_ _ _<br>'
        this.isNew = true;

        this.audioId = this._route.snapshot.paramMap.get('audio_id');
        if (this.audioId) {
            await this.populateAudioPlayer();
        }
        await this.populateProviderList();
        await this.populateFacilityList();
        await this.populatePatientsList();
        this.populateDOS();


        const date_obj_param = this._route.snapshot.paramMap.get('date_obj');
        if (date_obj_param) {
            const [month, day, year] = date_obj_param.split('-');

            const date = new Date();
            const hour = date.getHours();
            const minute = date.getMinutes();

            this.note.date_obj = {
                year: parseInt(year),
                month: parseInt(month),
                day: parseInt(day),
                hour,
                minute
            }
        } else {
            // const date = new Date();
            // const year = date.getFullYear();
            // const month = date.getMonth() + 1;
            // const day = date.getDate()
            // const hour = date.getHours();
            // const minute = date.getMinutes();

            // this.note.date_obj = {
            //     year,
            //     month,
            //     day,
            //     hour,
            //     minute
            // }
        }

        await this.initCharge();
        this.config.editable = true;


        // census > create note
        const paramCensusId = this._route.snapshot.paramMap.get('census_id');
        if (paramCensusId) {
            this.showTemplateDialog(this.shouldShowTemplateDialog);
        }
        await this.patientControl.valueChanges.subscribe(this.handlePatientSelection.bind(this));

        // this.angularEditor.change.subscribe(() => {
        //     this.autoSaveNote();
        // })

        // await this.initDraftNote();

    }

    async initDraftNote() {
        if (!this.note.facility || !this.note.provider || !this.note.patient) return;

        this.drafteNoteState = 'Checking for draft note...';
        const draftNoteResponse: any = await lastValueFrom(this.noteEditorService.getDraftNoteByUserId(this.note.facility._id, this.note.provider._id, this.note.patient._id, this.note.rounding_sheet_id))
        if (draftNoteResponse.status === 200) {
            const draftNote = draftNoteResponse.data;
            if (draftNote) {
                this.drafteNoteState = 'Restoring draft note...';
                await this.restoreDraftNote(draftNote);
                this.drafteNoteState = 'Restored draft note';
            }
        }
        this.mainEditor.change.subscribe(() => {
            this.autoSaveDraftNote();
        })
    }

    async restoreDraftNote(draftNote) {
        if (!draftNote) return;

        const { note, recentAudit } = draftNote;

        this.note = new Note(note);
        if (draftNote.note.facility) {
            this.note.facility = this.facilities.find(f => f._id === draftNote.note.facility._id);
        }
        if (draftNote.note.provider) {
            this.note.provider = this.doctors.find(d => d._id === draftNote.note.provider._id);
        }
        if (draftNote.note.patient) {
            this.note.patient = this.patients.find(p => p._id === draftNote.note.patient._id);
        }

        this.recentAudit = recentAudit;

        this.dateOfService = new Date(new Date(this.note.dates.service))
        // this.dateOfService = new Date(new Date(this.note.dates.service))

    }
    async handlePatientSelection(patient) {
        if (!patient || typeof patient !== 'object' || !Object.prototype.hasOwnProperty.call(patient, '_id')) return;
        const excludeNoteId = this.note?._id
        const response: any = await this.noteEditorService.getFollowUpNoteAsJson(patient._id, this.note.provider._id, excludeNoteId).toPromise();

        if (response.status === 200) {
            this.note.followUpNote = response.data

            if (this.note.followUpNote.transcribtion_title?.match(/init/i)) {

                await this.initFollowUpTemplate();

            }
        }
        this.checkAndShowOnceTemplateDialog();

        // Disabled prefetch phrases
        // this.phrasesService.prefetchSystemDefinedPhraseValues({ ...this.note, patient: patient });
    }
    async initFollowUpTemplate() {
        // getFollowUpTemplateByFacilityId
        // const templateResponse: any = await this.templateService.getFollowUpTemplateByFacilityId(this.note.facility._id, this.currentUser._id).toPromise();
        this.showOnceTemplateDialog = true;

        // if (this.note.htmlText.length > 0 && confirm('Would you like to discard current note text and use Follow Up template?')) {
        // if (this.note.htmlText.length > 0  ) {
        //     this.handleTemplateSelect(templateResponse.data);
        // }

    }
    async existingOnInit(noteId: string, draftNote?) {
        let noteResponseData;
        if (draftNote) {
            noteResponseData = draftNote;
        } else {
            const noteResponse: any = await this.noteEditorService.getNoteById(noteId).toPromise();
            if (noteResponse.status === 200) {
                if (noteResponse.data.note.title.replace(/\s/g, '').toLowerCase() === "notitle") {
                    if (noteResponse.data.note.htmlText === "" || noteResponse.data.note.htmlText === "No Text") {
                        noteResponse.data.note.htmlText = "";
                    }
                    noteResponse.data.note.title = "";
                }
                this.config.editable = true;
                // if (noteResponse.data.note.htmlText.replace(/\s/g, '').toLowerCase() === "notext") {
                //     noteResponse.data.note.htmlText = "";
                // }
                noteResponseData = noteResponse.data;
            }
            else if (noteResponse.status === 403) {
                // this.toastr.error(noteResponse.message, 'ERROR');
                this.router.navigate(['/403']);
            }
        }
        if (!noteResponseData) return;

        const { note, addendum_counts, recentAudit, relationship, assoc_provider_ids } = noteResponseData;
        this.note = new Note(note);
        if (this.note.is_signed == 'true') {
            this.buttonsState.save = true;            
        }
        this.pccSignInAlert();

        if (this.note.template_id_ref != null && this.note.template_id_ref != undefined) {
            this.emCodeEditorCpts = this.note.template_id_ref.assoc_cpt_ids;
        }
        if (this.note.is_signed == "true" && this.note.note_status.status == "uploaded") {
            let currentDate = moment(this.note.dates.sign).format('MM/DD/YYYY hh:mm').toString();
            if (this.note.facility.source === "PointClickCare") {
                if (this.note.wound_report_uploaded) {
                    this.drafteNoteState = `Note and Wound Report Uploaded to PCC on ${currentDate}`;
                } else {
                    this.drafteNoteState = `Note Uploaded to PCC on ${currentDate}`;
                }
            } else if (this.note.facility.source === "MatrixCare") {
                if (this.note.wound_report_uploaded) {
                    this.drafteNoteState = `Note and Wound Report Uploaded to MatrixCare on ${currentDate}`;
                } else {
                    this.drafteNoteState = `Note Uploaded to MatrixCare on ${currentDate}`;
                }
            } else {
                if (this.note.wound_report_uploaded) {
                    this.drafteNoteState = `Note and Wound Report Signed and Uploaded on ${currentDate}`;
                } else {
                    this.drafteNoteState = `Note Signed and Uploaded on ${currentDate}`;
                }
            }
        }
        this.noteEditorService.castnoteEditorTemplateCPTS.subscribe((data) => {
            if (data) {
                this.noteEditorCpts = data
            }
        });
        // if(!this.note.date_obj) {
        //     const date = this.serviceDate;
        //     const year = date.getFullYear();
        //     const month = date.getMonth() + 1;
        //     const day = date.getDate()
        //     const hour = date.getHours();
        //     const minute = date.getMinutes();

        //     this.note.date_obj = {
        //         year,
        //         month,
        //         day,
        //         hour,
        //         minute
        //     }
        // }

        // Note Lock
        //    this.timer = setInterval(async()=>{
        //        if(this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type === global.USER_TYPE.DOCTOR){
        //         if(!this.note.isEditLocked(this.currentUser._id) || this.note.lockedBy == undefined){
        //             const lockResponse: any = await this.noteEditorService.setNoteLock(noteId,this.currentUser._id).toPromise();
        //             if(lockResponse.status == 200) {
        //                 this.note.lockedBy = this.currentUser._id;
        //             }
        //         }
        //         }
        //     },30000);

        this.addendum_counts = addendum_counts;
        this.recentAudit = recentAudit;
        this.relationship = relationship;
        this.assoc_provider_ids = assoc_provider_ids;

        this.initPhrases();
        await this.populateFacilityList();

        await this.populatePatientsList();
        this.saveOriginalNoteState();
        if (this.note.facility.pcc_timeZone) {
            this.time_zone = this.note.facility.pcc_timeZone
        }
        else if (this.note.facility.timeZone) {
            this.time_zone = this.note.facility.timeZone
        }
        this.zone = moment.tz(this.time_zone).zoneAbbr()
        this.dateOfS = this.dateOfS + " shown in " + this.zone;

        // this.note.dates.service = new Date(new Date(this.note.dates.service).toLocaleString('en-US', {timeZone: this.time_zone}))
        this.serviceDate = new Date(new Date(this.note.dates.service).toLocaleString('en-US', { timeZone: this.time_zone }))
        // this.dateOfService = this.note.dates.service;
        // const disabledSidebarParam = this._route.snapshot.paramMap.get('disable-sidebar');
        // if (disabledSidebarParam) {
        //     this.sidebarVisible = false;
        // }
        this.sidebarVisible = true;


        // Disabled prefetch phrases
        // if (!this.note.isSigned() && !this.note.isUploaded()) {
        //     this.patientControl.valueChanges.subscribe(value => {
        //         if (!Object.prototype.hasOwnProperty.call(value, '_id')) return;
        //         this.phrasesService.prefetchSystemDefinedPhraseValues({ ...this.note, patient: value });
        //     })
        // }

        this.autoUpdateNoteEditLock();
        //to disbale functionalities for co-sign initiator
        if (this.note?.coSign_status?.status && this.note?.coSign_status.coSigned_by == this.currentUser._id) {
            this.config.editable = false;
            this.configg.isReadOnly = true
        }
        if (this.note.phrasesUpdateNeeded) {
            if (!this.note.isSigned() || !this.note.isUploaded()) {
                // remove signature
                this.removeSignatureFromNoteText();
                this.refreshPhrases().then(() => {
                    this.note.phrasesUpdateNeeded = false;
                    this.doAutosaveNote('note_only');
                    // this.noteEditorService.saveNote(this.note, this.censusIdForPatientSeen).toPromise();
                });
            }
        }
        await this.initCharge();
    }

    removeSignatureFromNoteText() {
        const lastPwithDashes = this.note.htmlText.lastIndexOf('<p>---</p>');
        if (lastPwithDashes === -1) return;
        const beforeDashes = this.note.htmlText.substring(0, lastPwithDashes);
        // const afterDashes = this.note.htmlText.substring(lastPwithDashes + 9);
        this.note.htmlText = beforeDashes;
    }

    populateDOS() {
        // populate from census > create note
        const paramDOS = this._route.snapshot.paramMap.get('dos');

        if (paramDOS) {
            this.time_zone = 'America/New_York';
            if (this.note.facility.pcc_timeZone) {
                this.time_zone = this.note.facility.pcc_timeZone
            }
            else if (this.note.facility.timeZone) {
                this.time_zone = this.note.facility.timeZone
            }
            this.zone = moment.tz(this.time_zone).zoneAbbr()
            this.dateOfS = this.dateOfS + " shown in " + this.zone;
            const dos = new Date(new Date(moment(new Date(paramDOS)).utc().toString()).toLocaleString('en-US', { timeZone: this.time_zone }))
            if (this.censusId || this.note?.rounding_sheet_id) {
                // if(true){
                // this.note.dates.service = dos;
                this.serviceDate = dos;

                if (this.isNew) {
                    // const newDOS = this.note.dates.service;
                    const newDOS = this.serviceDate;
                    let dat = new Date(newDOS)
                    const tempDOS = new Date(dat.valueOf())

                    const currentDate = new Date(new Date(moment(new Date()).utc().toString()).toLocaleString('en-US', { timeZone: this.time_zone }))
                    newDOS.setHours(currentDate.getHours());
                    newDOS.setMinutes(currentDate.getMinutes());
                    newDOS.setSeconds(currentDate.getSeconds());
                    this.serviceDate = newDOS;

                    const curentDate = moment(new Date()).utc().toDate();
                    tempDOS.setHours(curentDate.getHours());
                    tempDOS.setMinutes(curentDate.getMinutes());
                    tempDOS.setSeconds(curentDate.getSeconds());
                    this.note.dates.service = tempDOS;
                    // 
                }

            }
            else {
                const timeStr = moment().format('HH:mm');
                const newDate = moment(dos);
                const time = moment(timeStr, 'HH:mm');
                newDate.set({
                    hour: time.get('hour'),
                    minute: time.get('minute'),
                    second: time.get('second')
                });
                this.note.dates.service = newDate.toDate();
                this.dateOfService = new Date(new Date(this.note.dates.service))
                // this.dateOfService = new Date(new Date(this.note.dates.service))
            }


        } else {

            if (this.note.hasAudio()) {
                //mohsin
                this.note.dates.service = this.note.audio.filterServiceDate;
                //ali
                this.dateOfService = new Date(new Date(this.note.audio.filterServiceDate))
            }

            const _currentDate = new Date();
            this.note.date_obj = {
                year: _currentDate.getFullYear(),
                month: _currentDate.getMonth() + 1,
                day: _currentDate.getDate(),
                hour: _currentDate.getHours(),
                minute: _currentDate.getMinutes()
            }
        }

    }   // Save the original note state
    saveOriginalNoteState(saveWoundData = false) {
        if (saveWoundData) {
            // TODO: Cause of note freezing:
            // this.updateWoundfromNote(null, null, null, true);
        }
        const { provider, facility, patient, title, dates, htmlText } = this.note;
        this.originalNoteState = { provider, facility, patient, title, dates, htmlText };
    }

    // Compare the original note state with the current state
    compareOriginalNoteState() {
        const { provider, facility, patient, title, dates, htmlText } = this.note;
        return _.isEqual({ provider, facility, patient, title, dates, htmlText }, this.originalNoteState);
    }
    saveOriginalAddendumState() {
        const { text } = this.addendum;
        this.originalAddendumState = text;
    }
    compareOriginalAddendumState() {
        const { text } = this.addendum;
        return text === this.originalAddendumState;
    }

    async populatePatientsList() {
        if (this.note.facility) {
            // await this.getFacilityPatientsByProviderId(this.note.provider._id, this.note.facility._id);
            await this.getFacilityPatients(this.note.facility._id);
        }
        this.filteredPatients = this.patientControl.valueChanges
            .pipe(
                startWith(''),
                map((value: any) => {
                    if (value) {
                        return typeof value === 'string' ? value : value.name
                    }
                    return '';
                }),
                map(name => name ? this._filterPatient(name) : this.getFilteredPatients()),
            );
        if (this.isNew) {
            if (Array.isArray(this.patients)) {
                if (this.note.hasAudio() && this.note.audio.patient_id_ref) {
                    let patient_id;
                    if (typeof this.note.audio.patient_id_ref !== 'string') {
                        patient_id = this.note.audio.patient_id_ref._id;
                    } else {
                        patient_id = this.note.audio.patient_id_ref;
                    }

                    this.note.patient = this.patients.find(p => p._id === patient_id);
                } else {

                    // populate from census > create note
                    const paramPatientId = this._route.snapshot.paramMap.get('patient_id');
                    if (paramPatientId) {
                        const patientId = paramPatientId;
                        this.note.patient = this.patients.find(p => p._id === patientId);
                    }
                }
            }
        } else {
            const patient_id = this.note.patient?._id || this.note.patient_id;
            // this.note.patient = this.patients.find(p => p._id === patient_id);
            const patient = this.patients.find(p => p._id === patient_id);
            if (patient) {
                this.note.patient = patient;
            }
        }

        if (this.note.patient) {
            this.handlePatientSelection(this.note.patient);
        }
    }
    showCoSign() {
        if (!this.note?.coSign_status?.status) {
            if (this.note?.note_status?.status == 'published'
                && (this.currentUser.user_type == global.USER_TYPE.DOCTOR || this.currentUser.user_type == 'provider' || this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE)
                && this.currentUser._id == this.note.provider._id
                && (this.note?.provider?.title?.trim() != 'M.D.'.trim() && this.note?.provider?.title?.trim() != 'M.D'.trim() && this.note?.provider?.title?.trim() != 'MD'.trim())
                && (this.note?.provider?.title?.trim() != 'D.O.'.trim() && this.note?.provider?.title?.trim() != 'D.O'.trim() && this.note?.provider?.title?.trim() != 'DO'.trim())
                && (this.associatedProviders.length > 0)
            ) {
                return true;
            }
            else {
                return false
            }
        }
        else {
            if ((this.currentUser.user_type == global.USER_TYPE.DOCTOR || this.currentUser.user_type == 'provider' || this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE)
                // && this.currentUser._id == note.provider._id
                // && (note?.provider?.title?.trim() != 'M.D.'.trim() && note?.provider?.title?.trim() != 'M.D'.trim() && note?.provider?.title?.trim() != 'MD'.trim() )&&
                //     note?.provider?.title?.trim() != 'D.O.'.trim() && note?.provider?.title?.trim() != 'D.O'.trim() && note?.provider?.title?.trim() != 'DO'.trim()
            ) {
                this.associatedProvidersDropDown = false;
                this.coSignedProvider = {}
                return true;
            }
            else {
                this.associatedProvidersDropDown = false;
                this.coSignedProvider = {}
                return false
            }
        }
    }
    isNoteCoSigned(note) {
        if (this.note?.coSign_status?.status && (this.note?.coSign_status?.coSigned_to == this.currentUser._id)) {
            return true
        }
        else {
            return false
        }
    }

    isNoteSigned() {
        return this.note?.isSigned()
    }

    async populateProviderList() {
        if (this.note.hasAudio()) {
            // for audio note
            this.doctors = [this.note.audio.uploaded_by_id_ref];
            this.note.provider = this.note.audio.uploaded_by_id_ref;

        } else {
            // for simple note
            if (this.currentUser.user_type == global.USER_TYPE.TRANSCRIBER) {
                const doctorsResponse: any = await this.noteEditorService.getAssociatedDoctorsList(this.currentUser.company_id).toPromise();
                if (doctorsResponse.status == 200) {
                    this.doctors = doctorsResponse.data;
                }

                try {
                    const paramProviderId = this._route.snapshot.paramMap.get('provider_id');
                    let providerId;
                    if (paramProviderId) {
                        providerId = paramProviderId;
                    } else {
                        let tranFilters: any = localStorage.tranFilters;
                        if (tranFilters) {
                            tranFilters = JSON.parse(tranFilters);
                            if (tranFilters.provider) {
                                providerId = tranFilters.provider._id;
                            }
                        }
                    }
                    this.note.provider = this.doctors.find(d => d._id === providerId);
                } catch (error) { 
                    console.log('Error in populateProviderList() : ' + error?.message);
                }
            }
            else if (this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
                const doctorsResponse: any = await this._transcriptionCreateService.getAssociatedDoctorsListForMA().toPromise();
                if (doctorsResponse.status == 200) {
                    this.doctors = doctorsResponse.data;
                }

                try {
                    const paramProviderId = this._route.snapshot.paramMap.get('provider_id');
                    let providerId;
                    if (paramProviderId) {
                        providerId = paramProviderId;
                    } else {
                        let tranFilters: any = localStorage.tranFilters;
                        if (tranFilters) {
                            tranFilters = JSON.parse(tranFilters);
                            if (tranFilters.provider) {
                                providerId = tranFilters.provider._id;
                            }
                        }
                    }
                    this.note.provider = this.doctors.find(d => d._id === providerId);
                } catch (error) { 
                    console.log('Error in populateProviderList() : ' + error?.message);
                }
            }
            else {
                this.doctors = [this.currentUser];
                this.note.provider = this.currentUser;
            }
        }
        this.handleProviderChange();
    }

    async populateFacilityList() {
        if (this.note.provider) {
            await this.getFacilities(this.note.provider._id);
        }

        if (this.isNew) {
            try {
                // populate from audio
                if (this.note.hasAudio() && this.note.audio.facility_id_ref) {
                    let facility_id_ref;
                    if (typeof this.note.audio.facility_id_ref !== 'string') {
                        facility_id_ref = this.note.audio.facility_id_ref._id;
                    } else {
                        facility_id_ref = this.note.audio.facility_id_ref;
                    }

                    this.note.facility = this.facilities.find(f => f._id === facility_id_ref);
                    // return await this.getFacilityPatientsByProviderId(this.note.provider._id, this.note.facility._id);
                    return await this.getFacilityPatients(this.note.facility._id);
                }


                // populate from census > create note
                const paramFacilityId = this._route.snapshot.paramMap.get('facility_id');
                if (paramFacilityId) {
                    const facilityId = paramFacilityId;
                    this.note.facility = this.facilities.find(f => f._id === facilityId);
                    return await this.handleFacilityChange();
                }

                // popualte from dashboard > filters
                let tranFilters: any = localStorage.tranFilters;
                if (tranFilters) {
                    tranFilters = JSON.parse(tranFilters);
                    const facilityId = tranFilters.facility._id;
                    this.note.facility = this.facilities.find(f => f._id === facilityId);
                    return await this.handleFacilityChange();
                }
            } catch (error) { 
                console.log('Error in populateFacilityList() : ' + error?.message);
            }
        } else {
            if (this.facilities.find(f => f._id === this.note.facility._id)) {
                this.note.facility = this.facilities.find(f => f._id === this.note.facility._id);
            }
        }
    }
    async populateAudioPlayer() {
        this.note.audio = await this.getAudioDetailById(this.audioId);
    }
    useAudioDataInNote() {
        const { uploaded_by_id_ref, facility_id_ref } = this.note.audio;

        this.note.provider = uploaded_by_id_ref;
        this.note.facility = facility_id_ref;
        this.note.title = this.note.audio.audio_title;
    }

    async saveNote(type?) {
        if (type == 'auto') {
            this.clearAutoSaveNote();
        }
        this.clearAutoSaveDraftNote();
        if (type != 'auto')
            this.loader.startBackgroundLoader(this.loaderId);
        this.chargeCaptureFlag = false;
        if (!this.timePicked) {
            if (this.censusId && this.note.date_obj) {
                const currentDate = new Date();
                this.note.date_obj.hour = currentDate.getHours();
                this.note.date_obj.minute = currentDate.getMinutes();
            }
            if (this.note.date_obj && (!((this.note.date_obj?.hour) || (this.note.date_obj?.minute)))) {
                const currentDate = new Date();
                this.note.date_obj.hour = currentDate.getHours();
                this.note.date_obj.minute = currentDate.getMinutes();
            }
        }
        if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT) {
            this.note.note_status.status = "in_progress";
        }
        // this.note.htmlText=this.note.htmlText.replace(/\n/g, '');
        if (this.note.title === "") {
            this.note.title = "NO TITLE";
        }
        const response: any = await this.noteEditorService.saveNote(this.note, this.censusIdForPatientSeen).toPromise();
        let noteId;
        if (response.status === 200) {
            this.drafteNoteState = "Note is Saved at: " + moment(new Date()).format('HH:mm:ss').toString();

            // TODO: uncomment
            // if(response.data.htmlText) {
            //     this.note.htmlText = response.data.htmlText;
            // }
            if (this.note.title === "NO TITLE") {
                this.note.title = "";
            }
            if (type != 'auto')
                this.toastr.success(`Note has been updated successfully`);
            if (response.data.provider) {
                Object.keys(response.data.provider).forEach(key => this.note.provider[key] = response.data.provider[key])
                // this.note.provider = Object.assign(this.note.provider, response.data.provider);
            }
            if (!this.note._id || this.note.is_draft === 'true') {
                // new note created
                noteId = response.data.noteId;
                if ((this.chargeCaptureChargeModel) && (this.chargeCaptureChargeModel.status !== 'submit' || !this.chargeCaptureChargeModel.status) && this.saveChargeAfterNote) {
                    this.chargeCaptureChargeModel['note_id'] = noteId;
                    // await this.saveDraftCharge();
                }
                if (type != 'auto')
                    this.toastr.success(`Note has been created successfully`);
                // @ts-ignore
                if (this.nextPatientNote) {
                    // removed 'disable-sidebar': 1
                    await this.router.navigate([`/note-editor/${noteId}`, { ...this._route.snapshot.params, nextPatientNote: this.nextPatientNote }], { replaceUrl: true });
                }
                else {
                    // removed 'disable-sidebar': 1
                    await this.router.navigate([`/note-editor/${noteId}`, { ...this._route.snapshot.params }], { replaceUrl: true });
                }
            } else {
                if ((this.chargeCaptureChargeModel) && (this.chargeCaptureChargeModel.status !== 'submit' || !this.chargeCaptureChargeModel.status) && this.saveChargeAfterNote) {
                    this.chargeCaptureChargeModel['note_id'] = this.note._id;
                    // await this.saveDraftCharge();
                }
                this.saveOriginalNoteState(true);
            }

            if (this.sidebarVisible) {
                this.sidebarVisible = false;
                setTimeout(() => {
                    this.sidebarVisible = true;
                })
            }
            this.timePicked = false;
        }
        else if (response.status !== 200) {
            this.saveError = true;
        }
        if (this.subscription) {
            this._noteAddChargeService.setisCPT(false);
            this._noteAddChargeService.setisICD(false);
            this.subscription.unsubscribe();
        }
        if (type != 'auto')
            this.loader.stopBackgroundLoader(this.loaderId);
        return noteId;
    }

    async saveNoteWithCharge() {
        if (this.censusId || this.note?.rounding_sheet_id) {
            this.chargeCaptureFlag = true;
            if (this.sidebarVisible) {
                if (this.currentUser?.charge_status && this.note.facility?.enabled_charge_capture) {
                    if (this.noteSidebarComponent.tab != "Charge Capture") {
                        await this.noteSidebarComponent.updatedArray.forEach((tab) => {
                            if (tab.name == "Charge Capture") {
                                tab.is_active = true;
                                this.noteSidebarComponent.tab = "Charge Capture";
                            } else {
                                tab.is_active = false;
                            }
                        });

                        this.subscription = combineLatest(this._noteAddChargeService.castIsICD,
                            this._noteAddChargeService.castIsCPT).subscribe(async (data) => {
                                if (data[0] == true && data[1] == true && this.chargeCaptureFlag == true) {
                                    await this.saveChargeCapturewithNote();
                                }
                            });
                    } else {
                        await this.saveChargeCapturewithNote();
                    }
                } else {
                    await this.saveNote();
                }
            } else {
                await this.saveNote();
            }
        } else {
            await this.saveNote();
        }
    }
    async saveChargeCapturewithNote() {
        this.saveChargeAfterNote = false;
        if (this.noteSidebarComponent?.noteAddChargeComponent) {
            const icdsExist = this.noteSidebarComponent.noteAddChargeComponent.icdsExist.getValue();
            const cptsExist = this.noteSidebarComponent.noteAddChargeComponent.cptsExist.getValue();
            let obj = {};
            if (!icdsExist && cptsExist) {
                obj = {
                    title: "Do you want to save the note?",
                    text: "Charges have not been placed!",
                    icon: "warning",
                    buttons: [
                        "No", "Save Note"
                    ],
                    dangerMode: false,
                }
            }
            else if (!cptsExist && icdsExist) {
                obj = {
                    title: "Do you want to save the note?",
                    text: "Charges have not been placed!",
                    icon: "warning",
                    buttons: [
                        "No", "Save Note"
                    ],
                    dangerMode: false,
                }
            } if (!icdsExist && !cptsExist) {
                return await this.saveNote();
            } else if (!icdsExist || !cptsExist) {
                swal(obj)
                    .then(async (proceed) => {
                        if (proceed) {
                            // if(icdsExist && cptsExist){
                            return await this.saveNote()
                            // }
                        } else {
                            this.chargeCaptureFlag = false;
                            // this._noteAddChargeService.setisCPT(false);
                            // this._noteAddChargeService.setisICD(false);
                            if (this.subscription) {
                                this.subscription.unsubscribe();
                            }
                        }
                    });
            } else {
                this.saveChargeAfterNote = true;
                await this.saveNote();
            }

        } else {
            return await this.saveNote()
        }
    }
    async saveDraftNote() {
        this.noteEditorService.saveDraftNote(this.note, this.censusIdForPatientSeen).subscribe((response: any) => {
            if (response.status === 200) {
                const { noteId, recentAudit } = response.data;
                this.recentAudit = recentAudit;
                this.drafteNoteState = "Note saved as draft";
                this.note._id = noteId;
                // this.toastr.success(`Note has been auto saved`);
            }

        }, () => { }, () => { })
    }
    async saveDraftCharge() {
        this.sidebarVisible = true;
        if ((this.chargeCaptureChargeModel) && (this.chargeCaptureChargeModel.status !== 'submit' || !this.chargeCaptureChargeModel.status)) {
            // await this.saveDraftCharge();
        }
        if (this.noteSidebarComponent) {
            if (this.noteSidebarComponent.noteAddChargeComponent) {
                const value = this.noteSidebarComponent.noteAddChargeComponent.isChargeLoading.getValue()
                // .subscribe((value) => {
                // setTimeout(() => {
                // 
                if (value === false) {
                    await this.noteSidebarComponent.noteAddChargeComponent.addCharge(this.chargeCaptureChargeModel.status || 'draft', false, "false");
                }
            }
            // }) 
            // })
        }
    }
    AutosaveDraftCharge(status?) {
        this.sidebarVisible = true;

        if (this.noteSidebarComponent) {
            if (this.noteSidebarComponent.noteAddChargeComponent) {
                const value = this.noteSidebarComponent.noteAddChargeComponent.isChargeLoading.getValue()

                if (value === false) {
                    this.noteSidebarComponent.noteAddChargeComponent.showAutoSaveMessage();
                    if (!this.chargeCaptureChargeModel.charge_id) {
                        const res = new Promise((resolve, reject) => {
                            setTimeout(async () => {
                                const result = await this.noteSidebarComponent.noteAddChargeComponent.AutoaddCharge(this.chargeCaptureChargeModel.status || 'draft', false, "false");
                                resolve(result);
                            }, 2540);
                        });
                        
                        return res;

                    } else {
                        const res = this.noteSidebarComponent.noteAddChargeComponent.AutoaddCharge(this.chargeCaptureChargeModel.status || 'draft', false, "false");
                        return res;
                    }
                }
            }

        }
    }
    async saveDraftCharge_() {
        if (this.chargeCaptureChargeModel.status === 'submit') return;
        this.chargeCaptureChargeModel.charge_id = this.chargeCaptureChargeModel._id;

        const castIcdsForChargeSubscription = this.censusPatientListService.castIcdsForCharge.subscribe(icd_ids => {
            const castCptsForChargeSubscription = this.censusPatientListService.castCptsForCharge.subscribe(cpt_ids => {
                let isChanged = false;

                const charge = {
                    ...this.chargeCaptureChargeModel,
                    patient_id: this.chargeCaptureChargeModel.patient_id._id,
                    icd_id: this.chargeCaptureChargeModel.icd_id.map(e => e._id),
                    cpt_id: this.chargeCaptureChargeModel.cpt_id.map(e => e._id),
                }

                for (const icd of this.noteEditorIcds) {
                    const exists = charge.icd_id.find(icd_id => icd_id === icd._id);
                    if (!exists) {
                        charge.icd_id.push(icd._id);
                        isChanged = true;
                    }
                }
                for (const icd_id of icd_ids) {
                    const exists = charge.icd_id.find(_icd_id => _icd_id === icd_id);
                    if (!exists) {
                        charge.icd_id.push(icd_id);
                        isChanged = true;
                    }
                }
                for (const cpt_id of cpt_ids) {
                    const exists = charge.cpt_id.find(_cpt_id => _cpt_id === cpt_id);
                    if (!exists) {
                        charge.cpt_id.push(cpt_id);
                        isChanged = true;
                    }
                }
                if (!charge.icd_id || (Array(charge.icd_id) && charge.icd_id.length === 0) || !charge.cpt_id || (Array(charge.cpt_id) && charge.cpt_id.length === 0)) {
                    return false;
                }

                if (isChanged) {
                    this.patientRoundingSheetService.addCharge(charge).subscribe((response: any) => {
                        if (response.status === 200) {
                            this.toastr.success(`Charge has been saved`);
                        }
                    })
                }
                // castCptsForChargeSubscription.unsubscribe()
            }).unsubscribe();
            // castIcdsForChargeSubscription.unsubscribe();
        }).unsubscribe();
    }
    isPccFacility() {
        if (this.note.facility?.pcc_facId) {
            return true;
        } else {
            return false;
        }
    }
    get censusIdForPatientSeen() {
        if (this.note && this.note.rounding_sheet_id) return this.note.rounding_sheet_id
    }

    async submitForReviewNote() {
        this.loader.startBackgroundLoader(this.loaderId);
        this.noteEditorService.submitForReviewNote(this.note._id).subscribe((response: any) => {
            if (response.status === 200) {
                this.note = Object.assign(this.note, response.data.note);
                this.recentAudit = response.data.recentAudit;

                this.toastr.success(`Note has been ${this.formatNoteAuditOperation(this.recentAudit.operation)} successfully`);

                // this._router.navigateByUrl('/dashboard;category=dict');
                this.goBack()
            }
        }, () => { }, () => this.loader.stopBackgroundLoader(this.loaderId))
    }

    async coSignNote(status) {
        // this.loader.startBackgroundLoader(this.loaderId);
        this.coSignedProviderReserve = this.coSignedProvider;
        this.noteEditorService.coSignNote(this.note, this.coSignedProvider._id, this.note.provider._id, status).subscribe((response: any) => {
            if (response.status === 200) {
                this.note = Object.assign(this.note, response.data.note);
                this.note.coSign_status = response.data.note.coSign_status
                this.config.editable = false;
                this.configg.isReadOnly = true;
                this.toastr.success(`Note's Co-Signed status changed`);
            }
        },
            () => { },
            () => this.loader.stopBackgroundLoader(this.loaderId))
    }

    async revertNote() {
        let dialogRef = this.dialog.open(ReasonDialogComponent, {
            data: {
                title: 'Please Enter Reason to Return.',
                censusId: this.note.rounding_sheet_id,
                patientId: this.note.patient?._id,
            },
            width: '500px'
        });

        dialogRef.afterClosed().subscribe(async (data) => {
            if (data) {

                this.loader.startBackgroundLoader(this.loaderId);
                this.noteEditorService.revertNote(this.note._id).subscribe((response: any) => {
                    if (response.status === 200) {
                        this.note = Object.assign(this.note, response.data.note);
                        this.recentAudit = response.data.recentAudit;
                        this.config.editable = false;
                        this.toastr.success(`Note has been ${this.formatNoteAuditOperation(this.recentAudit.operation)} successfully`);
                        this.buttonsState.sign = false;

                        this.goBack()

                    }

                }, () => { }, () => this.loader.stopBackgroundLoader(this.loaderId))
            } else {
            }
        });
    }

    // gotoNextPatientInCensus() {
    //     if (this.patientListData?.patientList?.length > 0 && this.patientListData?.patientIndex < this.patientListData?.patientList?.length - 1 && this.nextPatientNote) {
    //         let index = this.patientListData.patientIndex;
    //         this.patientListData.patient_id = this.patientListData.patientList[++index]._id;
    //         ++this.patientListData.patientIndex;
    //         this._censusPatientListService.setPatientListData(this.patientListData);
    //         let obj = JSON.parse(JSON.stringify(this.patientListData));
    //         delete obj.patientList;
    //         delete obj.patientIndex;
    //         this._router.navigate(['/note-editor/new', obj]);
    //         return;
    //     }
    // }

    async signNote() {
        // if(this.note.hasPCCPatient()) {
        //     if(!this._pccService?.pccUserinfo) {
        //         // return this.toastr.error("You need to login to PCC first", "Error");
        //     }
        // }
        this.clearAutoSaveNote();
        let noteId = this.note._id;
        if (!this.compareOriginalNoteState()) {
            const _noteId: any = await this.saveNote();
            if (!noteId) {
                noteId = _noteId;
            }
        }
        else {
            // if(this.chargeCaptureCharge  && (this.chargeCaptureCharge.status !== 'submit' || !this.chargeCaptureCharge.status))  {
            //     await this.saveNoteWithCharge();
            // }
        }
        // this.coSignNote(true)
        // setTimeout(() => {
        // this.loader.startBackgroundLoader(this.loaderId);
        // if (this.nextPatientNote) {
        //     this.patientListSubscription = this._censusPatientListService.castPatientListData.subscribe(patientListData => {
        //         this.patientListData = patientListData;
        //     });
        // }
        if (this.mustCheckWoundDatesforSignNote && this.woundNosWithoutDates && this.woundNosWithoutDates.length > 0) {
            this.toastr.error("Facility Acquired or Prior to Admission dates must be completed prior to signing a note", "Error");
            this.woundsDateErrorforSignNote = true;
            return;
        } else this.woundsDateErrorforSignNote = false
        const date_obj = this.commonService.dateObjToDate(this.note.date_obj)
        // is future date
        // if (date_obj > new Date()) {
        if (moment(date_obj).isSameOrAfter(moment().add(1, 'days'))) {
            this.toastr.error("Note cannot be signed in future date of service", "Error");
            return;
        }
        this.buttonsState.save = true;
        if (this.currentUser?.other_settings?.note_sign_confirmation === false) {
            if ((this.note?.provider?._id.toString() != this.currentUser._id.toString()) || !this.coSignedProvider?._id || (this.note?.coSign_status?.status && this.note?.coSign_status?.coSigned_by != this.note?.provider?._id)) {
                /// this.doSignNote(noteId)

                if (await this.doSignNote(noteId)) {

                } else if (noteId) {

                    //For opening next patient's note
                    if (Object.values(this.patientListData).length > 0 && this.nextPatientNote) {
                        // this.gotoNextPatientInCensus();
                    }
                    else {
                        // await this.saveNoteWithCharge();
                        const CensusId = this._route.snapshot.paramMap.get('census_id') || this.note.rounding_sheet_id;
                        if (CensusId) {
                            //window.location.reload();
                            //this.navigate(`/note-editor/${noteId}`);
                            await this.existingOnInit(noteId);
                            // if (!this.note?.coSign_status?.status && (this.note?.provider?._id == this.currentUser._id || this.isAssociatedProvider())) {
                            //     if(!this.note.hasPCCPatient()){
                            //         await this.doUploadNote(true);

                            //     }
                            // }
                            // this.router.navigate([`/note-editor/${noteId}`], { queryParams: { id: this.censusId } });
                        }
                    }
                }


            }
            else {
                this.coSignNote(true)
            }
        } else {
            this.showUploadNoteConfirmationDialog({ type: 'note', confirmButtonLabel: 'Confirm & Sign', noteId, time_zone: this.time_zone }, async () => {
                if ((this.note?.provider?._id.toString() != this.currentUser._id.toString()) || !this.coSignedProvider?._id || (this.note?.coSign_status?.status && this.note?.coSign_status?.coSigned_by != this.note?.provider?._id)) {
                    await this.doSignNote(noteId);
                    if (noteId) {

                        //For opening next patient's note
                        if (Object.values(this.patientListData).length > 0 && this.nextPatientNote) {
                            // this.gotoNextPatientInCensus();
                        }
                        else {
                            // await this.saveNoteWithCharge();
                            const CensusId = this._route.snapshot.paramMap.get('census_id') || this.note.rounding_sheet_id;
                            if (CensusId) {
                                await this.existingOnInit(noteId);
                                // if (!this.note?.coSign_status?.status && (this.note?.provider?._id == this.currentUser._id || this.isAssociatedProvider())) {
                                //     if(!this.note.hasPCCPatient()){
                                //         await this.doUploadNote(true);
                                //     }
                                // }
                                //this.navigate(`/note-editor/${noteId}`);
                                //window.location.reload();
                                // this.router.navigate([`/note-editor/${noteId}`], { queryParams: { id: this.censusId } });
                            }
                        }
                    }
                }
                else {
                    this.coSignNote(true)
                }

            });
        }
        this.hideNuanceBrowserKit();

        if ((this.chargeCaptureChargeModel) && (this.chargeCaptureChargeModel.status !== 'submit' || !this.chargeCaptureChargeModel.status)) {

            this.chargeCaptureChargeModel['note_id'] = this.note._id;

            if (this.chargeCaptureChargeModel.cpt_id.length > 0 || this.chargeCaptureChargeModel.icd_id.length > 0) {
                await this.AutosaveDraftCharge("auto");

            }
        }
        // })
    }

    async doSignNote(noteId?) {
        // let hasPCCPatient = this.note.hasPCCPatient();
        this.loader.startBackgroundLoader(this.loaderId);
        let hasLoggedInToPCC = this.hasLoggedInToPCC();
        let emailIds = [];
        // const { configuration } = await this.noteEditorService.initFacilitySettings(this.note.facility._id);
        if (this.configuration.progress_note_submission_type === "email" ||
            this.configuration.wound_assessment_report_submission_type === "email") {
            this.loader.stopBackgroundLoader(this.loaderId);
            const data: any = {
                facility_id: this.note.facility,
                note_id: this.note._id,
                shouldReturnEmailIds: true
            };
            if (this.configuration.progress_note_submission_type === "email" &&
                this.configuration.wound_assessment_report_submission_type === "email") {
                data.message = 'Email progress note & wound assessment report to the following:';
            }
            else if (this.configuration.progress_note_submission_type === "email") {
                data.message = 'Email progress note to the following:';
            }
            else if (this.configuration.wound_assessment_report_submission_type === "email") {
                data.message = 'Email wound assessment report to the following:';
            }
            emailIds = await this.noteEditorService.openEmailSendingDialog(DialogEmailSendingComponentComponent, data);
            if (!(Array.isArray(emailIds) && emailIds.length > 0)) {
                emailIds = [];
            }
        }
        this.loader.startBackgroundLoader(this.loaderId);
        // const response: any = await this.noteEditorService.signNote(noteId || this.note._id, hasPCCPatient && hasLoggedInToPCC).toPromise()
        const response: any = await lastValueFrom(this.noteEditorService.signNoteV2(noteId || this.note._id, hasLoggedInToPCC, emailIds))
            .catch(() => this.toastr.error(`Something went wrong while signing note, please try again`, "Failed"));

        const noteErrors = response.data?.noteErrors;
        if (response.woundReportError) {
            setTimeout(() => this.toastr.error(response.woundReportError), 3000)
        }
        if (noteErrors) {
            const message = Object.values(noteErrors).map((error: any) => error.message).join(', ');
            this.noteErrors = noteErrors
            this.toastr.error("User do not have access to the facility.please contact your facility for further assistance");
            this.loader.stopBackgroundLoader(this.loaderId);
        } else if (response.status === 200) {
            this.loader.stopBackgroundLoader(this.loaderId);
            const { zipBuffer, pdfBuffer, noteBuffer } = response;
            if (zipBuffer) {
                this.convertToBlobAndDownload(zipBuffer, "Wound Assessment Report & Note", ".zip");
            }
            else {
                if (pdfBuffer)
                    this.convertToBlobAndDownload(pdfBuffer, this.configuration.wound_assessment_report_name, ".pdf");
                if (noteBuffer)
                    this.convertToBlobAndDownload(noteBuffer, this.note.title, ".pdf");
            }
            this.noteErrors = {}
            // if(this.associatedProvidersDropDown && this.coSignedProvider && !this.note?.coSign_status?.status){
            //     this.coSignNote(true);
            // }
            this.note = Object.assign(this.note, response.data.note);

            //save charge too



            this.recentAudit = response.data.recentAudit;
            this.toastr.success(response.message);

            // if(hasLoggedInToPCC && this.note.pcc_progressNoteId) {
            //     if(this.configuration.wound_assessment_report_submission_type === "pcc"){
            //         await this.uploadWoundReportToPCC();
            //     }
            // }
            // this.gotoNextPatientInCensus();
            // this.toastr.success(`Note has been signed successfully`);

            // if((hasLoggedInToPCC || this.canUploadReportToPCCUsingTwoLegged()) && this.note.pcc_progressNoteId) {
            //     const payload = {
            //         noteId: this.note._id
            //     }
            //     this.woundService.generateWoundReportUploadToPcc(payload)
            //     .subscribe((response: any) => {
            //         if(response.status === 200){
            //             let currentDate = moment(new Date()).format('MM/DD/YYYY hh:mm').toString()
            //             this.toastr.success(`Wound Report has uploaded to PCC successfully`);
            //             this.drafteNoteState = `Note and Wound Report Uploaded to PCC on ${currentDate}`;
            //         }

            //     });
            // }

            // census > create note
            // if (hasPCCPatient && hasLoggedInToPCC) {
            let conditionToSendEmail = false;
            let conditionToSendEmailfromUser = false;

            // if (this.note.facility?.pcc_facId && this.note.facility?.pcc_orgUuid) {
            //     conditionToSendEmail = this.currentUser.other_settings.pcc_email;
            // } else {
            //     conditionToSendEmail = this.currentUser.other_settings.non_pcc_email;
            // }
            //check facility pcc status,    check from settings to show this
            conditionToSendEmail = this.note.facility?.enabled_note_email;

            if (conditionToSendEmail) {
                if (this.currentUser?.notes_email_facilities?.findIndex((e) => { return e?.toString() == this.note.facility?._id?.toString() }) >= 0) {
                    conditionToSendEmailfromUser = true;
                } else {
                    conditionToSendEmailfromUser = false;
                }
                // if (this.note.facility?.pcc_facId && this.note.facility?.pcc_orgUuid) {
                //     conditionToSendEmailfromUser = this.currentUser.other_settings.pcc_email;
                // } else {
                //     conditionToSendEmailfromUser = this.currentUser.other_settings.non_pcc_email;
                // }

                if (conditionToSendEmailfromUser) {
                    const data = {
                        message: 'Email progress note to the following:',
                        facility_id: this.note.facility,
                        note_id: this.note._id
                    };
                    await this.noteEditorService.openEmailSendingDialog(DialogEmailSendingComponentComponent, data);
                }
            }

            // if (!this.mustSignNote) {
            //     if (!this.note?.coSign_status?.status && (this.note?.provider?._id == this.currentUser._id || this.isAssociatedProvider())) {
            //         if (!this.note.hasPCCPatient()) {

            //             await this.doUploadNote(true, true);
            //         }
            //     }
            //     return false;
            // } else {
            //     if (!this.note?.coSign_status?.status && (this.note?.provider?._id == this.currentUser._id || this.isAssociatedProvider())) {
            //         if (!this.note.hasPCCPatient()) {

            //             await this.doUploadNote(true, false);
            //         }
            //     }
            //     return false;
            // }
            await this.existingOnInit(this.note._id);
            return true;
        } else {
            if ((hasLoggedInToPCC && this.canUploadNoteToPCCUsingTwoLegged()) && this.note.patient.pcc_patientId) {
                if (response.message.toLowerCase().indexOf('token') >= 0) {
                    this._pccService.invalidateToken();
                    this.toastr.error(`${response.message}, Please sign in to PCC and try again`, "Failed");
                } else {
                    this.toastr.error(response.message, "Failed");
                }
            } else {
                this.toastr.error(response.message, "Failed");
            }
            this.loader.stopBackgroundLoader(this.loaderId);
            await this.existingOnInit(this.note._id);
        }
        this.loader.stopBackgroundLoader(this.loaderId);
    }

    checkSpellChecker() {
        return new Promise((resolve, reject) => {
            if (this.currentUser) {
                this._company.getSpellCheckOption(this.currentUser?._id).subscribe((res: any) => {
                    if(this.currentUser.portal_type === global.PORTAL_TYPE.NON_WC) {
                        if (res.data == true) {
                            if(this.currentUser.portal_type === global.PORTAL_TYPE.NON_WC) {
                                enableSpellCheck(true, global.PORTAL_TYPE.NON_WC);
                            }
                            
                        } else if (res.data == false) {
                            if(this.currentUser.portal_type === global.PORTAL_TYPE.NON_WC) {
                                enableSpellCheck(false, global.PORTAL_TYPE.NON_WC);
                            }
                        } else {
                            // enableSpellCheck(false);
                        }
                    }
                    else {
                        enableSpellCheck(false, global.PORTAL_TYPE.WC);
                    }
                    
                    resolve(null);
                }, error => {
                    resolve(null);
                });
            } else {
                resolve(null);
            }
        });
    }

    hasNoteError() {
        if (this.noteErrors && Object.values(this.noteErrors).length) {
            return Object.values(this.noteErrors).map(error => error.message).join(', ');
        }
    }

    hideUploadButton() {
        if (!this.note?.coSign_status?.status && (this.note?.provider?._id == this.currentUser._id || this.isAssociatedProvider())) {
            if (!this.note.hasPCCPatient()) {
                return true;
            }
        }
        return false;
    }
    hideStrikeOutButton() {
        return this.hideUploadButton() || !this.note.isUploaded() || !this.hasLoggedInToPCCFacility() || this.note.pcc_progressNoteStrikeOut;
    }

    navigateBackToCensus() {
        // census > create note
        // this._location.back();
        // return;
        const paramCensusId = this._route.snapshot.paramMap.get('census_id') || this.note.rounding_sheet_id;
        if (paramCensusId) {
            this.router.navigate([`/census/${paramCensusId}`], { replaceUrl: true });
            return true;
        }
    }

    hasLoggedInToPCC() {
        return !!this._pccService?.pccUserinfo;
    }

    hasLoggedInToPCCFacility() {
        return this.note && this._pccService.isLoggedInFacility(this.note.facility)
    }

    canUploadNoteToPCCUsingTwoLegged() {
        return this._authService.canUploadNoteToPCCUsingTwoLegged([this.note.uploadedBy._id, this.note.provider._id]);
    }

    canUploadReportToPCCUsingTwoLegged() {
        return this._authService.canUploadReportToPCCUsingTwoLegged();
    }

    async uploadNote() {
        if (this.note.hasPCCPatient()) {

            if (this.currentUser?.other_settings?.note_upload_confirmation === false) {
                this.doUploadNote();
            } else {
                if (!(this.note.hasPCCPatientandDnFacility())) {
                    this.showUploadNoteConfirmationDialog({ type: 'note', confirmButtonLabel: 'Confirm & Upload' }, async () => {
                        this.doUploadNote();
                    })
                }
            }


        } else {
            this.doUploadNote();
        }
        this.hideNuanceBrowserKit();
    }

    async doUploadNote(auto = false, back = false) {
        let hasLoggedInToPCC = this.hasLoggedInToPCC();
        await this.noteEditorService.uploadNoteV2(this.note._id, hasLoggedInToPCC, []).subscribe(async (response: any) => {

            const noteErrors = response.data?.noteErrors;
            if (response.woundReportError) {
                setTimeout(() => this.toastr.error(response.woundReportError), 3000)
            }
            if (noteErrors) {
                this.noteErrors = noteErrors
                const message = Object.values(noteErrors).map((error: any) => error.message).join(', ');
                this.toastr.error(message);
                this.loader.stopBackgroundLoader(this.loaderId);
            } else if (response.status === 200) {
                this.loader.stopBackgroundLoader(this.loaderId);
                this.noteErrors = {}
                this.note = Object.assign(this.note, response.data.note);
                this.recentAudit = response.data.recentAudit;
                if (!auto) {
                    this.toastr.success(response.message);
                    // this.toastr.success(`Note has been ${this.formatNoteAuditOperation(this.recentAudit.operation)} successfully`);
                    // if(this.configuration.wound_assessment_report_submission_type === "pcc"){
                    //     await this.uploadWoundReportToPCC();
                    // }
                    if (this.currentUser.user_type == global.USER_TYPE.TRANSCRIBER) {
                        this._location.back();
                    }
                    else {
                        // census > create note
                        this.navigateBackToCensus();
                    }
                }
                if (back) {
                    this.navigateBackToCensus();
                } else if (auto) {
                    await this.existingOnInit(this.note._id);

                }
            } else {
                this.toastr.error(response.message, "Failed");
            }
        });
    }

    componentLoaded() {
        return this.ckEditorReady && this.note;
    }
    async getPhrases() {
        return new Promise<void>((resolve, reject) => {
            this.templateService.getPhrases().subscribe((res) => {
                // @ts-ignore
                this.phrases = res.data.phrases;
                let mentions = [
                    {
                        feed: this.phrases,
                        marker: '##',
                        minChars: 0
                    }
                ];
                let cke = _.cloneDeep(this.cke4Config);
                cke.mentions = mentions;
                this.cke4Config = cke;
                this.reloadCKEditor();
                resolve(null);
            }, error => {
                reject();
            });
        })
    }

    reloadCKEditor(ms = 1) {
        if (!this.myEditor) return;
        // const editorName = this.myEditor.name;
        // this.myEditor.destroy();
        
        // setTimeout(() => {
        //     // this.angularEditor = CKEDITOR.replace(this.angularEditor.elementRef.nativeElement.querySelector('textarea'), this.cke4Config)
        //     // this.myEditor.setData(this.note.htmlText)
        // }, 1)

        this.ckEditorReady = false;
        setTimeout(() => this.ckEditorReady = true, ms);
    }

    async applyNoteBuilder() {
        if (this.note.hasAudio() && this.patient_audio.is_patient) {
            const data = {
                patient: this.note.audio.patient_id_ref.name,
                provider: `${this.note.provider.first_name} ${this.note.provider.last_name}, ${this.note.provider.title}`,
                facility: this.showFacility(),
                dos: this.note.audio ? this.note.audio.filterServiceDate : (!this.isNew && !this.note.audio && this.note ? this.note.dates.service : '')
            };
            this.templateService.applyNoteBuilder(data).subscribe(res => {

                // @ts-ignore
                let data = res.data;
                Object.keys(data).forEach((key, index) => {
                    let editor = this.note.htmlText
                        .replace(new RegExp(key, 'g'), data[key]);
                    this.note.htmlText = editor;
                });
            })
        }
    }

    displayPatientFn(patient): string {
        return patient && patient.name ? patient.name : '';
    }

    private _filterPatient(name): any[] {
        const filterValue = name.toLowerCase();

        return this.getFilteredPatients().filter(patient => patient.name.toLowerCase().indexOf(filterValue) === 0);
    }
    chunkPlay() {
        this.TranscriptionDetail.chunk.subscribe((x) => {
            let audio = document.getElementById('transcription');
            // @ts-ignore
            audio.currentTime = x.chunk;
            // @ts-ignore
            audio.play();
            // @ts-ignore
            let limit = parseFloat(x.duration) + parseFloat(x.chunk);
            let timer = setInterval(function () {
                // @ts-ignore
                if ((parseFloat(audio.currentTime)) > limit) {
                    // @ts-ignore
                    audio.pause();
                    clearInterval(timer);
                }
            }, 1000);
        })
    }

    ngAfterViewInit() {
        this.chunkPlay();
        document.addEventListener('keydown', this.handlePedal);        
    }

    ngOnDestroy() {
        if (this.mainEditorInstance) {
            this.mainEditorInstance.destroy(true);            
        }
        if (this.addendumEditorInstance) {
            this.addendumEditorInstance.destroy(true);
        }
        
        enableSpellCheck(true, global.PORTAL_TYPE.NON_WC);
        if (this.currentUser.enabled_speech_kit) {
            if (this.currentUser.enabled_speech_kit.includes("dragon")) {
                if (this.dragonTimeoutId) {
                    clearTimeout(this.dragonTimeoutId);
                }
                document.cookie = 'NUSA_Guids=;Max-Age=-99999999;';
                this.hideNuanceBrowserKit();
            } else if (this.currentUser.enabled_speech_kit.includes("nVoq")) {
                var nVoqscript = document.getElementById("nVoq");
                if (nVoqscript) {
                    nVoqscript.parentNode.removeChild(nVoqscript);
                    destroynVoqKit();
                }
            }
        }
        document.removeEventListener('keydown', this.handlePedal);
        delete window['angularComponentReference'];
        if (this.isNew && this.isActiveAutoSaveDraftNoteTimeout !== null && this.isNew && this.note && this.note.is_draft !== 'false') {
            this.saveDraftNote();
        }
        if (this.isNew && this.isActiveAutoSaveNoteTimeout !== null && this.isNew && this.note) {
            this.saveNote();
        }

        this.clearAutoSaveDraftNote();
        this.noteEditorService.setnoteEditorTemplateCPTS(null);
        // this.clearNoteEditLock();
        this.phrasesService.resetPhrasesData();
        localStorage.removeItem("censusPatientListData");
        if (this.patientListSubscription) {
            this.patientListSubscription.unsubscribe();
        }
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        // if(!this.note.isEditLocked(this.currentUser._id)){
        //     this.noteEditorService.setNoteLock(this._route.snapshot.params.note_id,null).toPromise();
        // }
        // if(this.timer){
        // clearInterval(this.timer);
        // }
    }

    handlePedal = async (event) => {
        if (event.code === 'PageUp' || event.code === 'PageDown') {

            event.preventDefault();

            let audio = document.getElementById('transcription');
            if (event.code === 'PageDown') {
                try {
                    // @ts-ignore
                    if (audio.paused) {

                        // @ts-ignore
                        audio.playbackRate = this.speedSlider;
                        // @ts-ignore
                        await audio.play();

                    } else {

                        // @ts-ignore
                        audio.currentTime = (parseInt(audio.currentTime) - 2).toString();
                        // @ts-ignore
                        await audio.pause();

                    }
                } catch (error) {
                    console.log('Error in handlePedal() : ' + error?.message);
                }
            } else {
                try {

                    // @ts-ignore
                    audio.playbackRate = this.speedSlider;
                    // @ts-ignore
                    audio.currentTime = (parseInt(audio.currentTime) - this.backSlider).toString();
                    // @ts-ignore
                    await audio.play();
                } catch (error) {
                    console.log('Error in handlePedal() : ' + error?.message);
                }
            }
        }
    }

    duplicateNote(note) {
        localStorage.setItem('duplicateNote', JSON.stringify(note));
        const params: any = { new: 1 };
        if (note.audio_id) {
            params.audio_id = note.audio_id;
            params.category = 'dict';
        } else {
            params.category = 'tran';
        }
        this.reloadScreen(params);
    }

    showFacility() {
        let title = 'None';
        if (this.isNew && this.currentaudio) {
            title = this.currentaudio.facility_id_ref.title;
        } else if (this.selectedFile) {
            title = this.selectedFile.facility_id_ref.title;
        }

        return title;
    }

    /* #khaleeq
    checkNoteQA */
    checkNoteQA(doctor_id, transcriber_id) {
        const params = {
            doctor_id: doctor_id,
            transcriber_id: transcriber_id
        };
        return new Promise<void>((resolve, reject) => {
            this.transcriptionService.checkNoteQA(params).subscribe(
                data => {

                    if (data.success === true) {
                        this.permission.is_qa = true;
                    }
                    resolve(null);
                },
                error => {

                    resolve(null);
                }
            );
        });
    }

    toggleNoteLock(lock = true) {

        const params = {
            note_id: this.selectedFile._id,
            is_locked: lock
        };
        this.transcriptionService.toggleNoteLock(params).subscribe(
            async data => {

                this.toastr.success('Note lock status has been changed', 'Updated');
                // await this.initializeData();
            },
            error => {

            }
        );
    }

    /* template conditions here */
    isLocked(): Boolean {
        if (this.permission.is_qa === false &&
            this.selectedFile.is_locked === true &&
            // tslint:disable-next-line:triple-equals
            this.currentUser.user_type != global.USER_TYPE.DOCTOR && this.currentUser.user_type != global.USER_TYPE.SNF_WC_NURSE) {
            return true;
        } else if (this.permission.is_qa === true && this.selectedFile.is_published === 'true') {
            return true;
        } else if ((this.permission.is_qa === false
            && this.currentUser.user_type == '2'
            && this.selectedFile.is_published === 'true') || (this.permission.is_qa === false
                && this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT
                && this.selectedFile.is_published === 'true')) {
            return true;
        } else {
            return false;
        }
    }

    isSubmit(): Boolean {
        if (this.permission.is_qa === false
            && this.selectedFile.is_published == 'false'
            && this.selectedFile.is_locked === false) {
            return true;
        } else if (this.permission.is_qa === true
            && this.selectedFile.is_published == 'false'
            && this.selectedFile.is_locked === false) {
            return true;
        } else {
            return false;
        }
    }

    async getAudioDetailById(audio_id: string) {
        const response: any = await this.dashboardService.getAudioDetailById(audio_id, this.currentUser._id, this.currentUser.auth_key, this.currentUser.user_type).toPromise();

        if (response.status === 200) {
            return response.data;
        }

        this.toastr.error('Audio does not exist', 'Error');
        // setTimeout(() => this.router.navigate(['/dashboard']));
    }
    getAudioDateObj() {

        if (this.note?.audio?.date_obj) {
            return this.commonService.dateObjToString(this.note?.audio?.date_obj);

        } else {
            return '';
        }
    }
    async getTranscriptionById(transcription_id) {
        const response: any = await this.transcriptionService.get_transcription_by_id(transcription_id).toPromise();
        if (response.status === 200) {
            if (response.data.is_active === 'true') {
                return response.data;
            }
        }
        //this.toastr.error('Note does not exist', 'Error');
        //setTimeout(() => this.router.navigate(['/dashboard']));
    }

    // isEditorEditable() {
    //     if (this.isNew) {
    //         this.config.editable = true;
    //         this.configg.isReadOnly = false
    //     } else if (this.currentUser && this.selectedFile) {
    //         if (this.currentUser.user_type == 1) {
    //             // Provider
    //             // tslint:disable-next-line:triple-equals
    //             this.config.editable = this.selectedFile.is_signed != 'true';
    //         } else {
    //             // Transcriber
    //             if (this.selectedFile.is_locked && !this.permission.is_qa) {
    //                 this.config.editable = false;
    //             } else {
    //                 // tslint:disable-next-line:triple-equals
    //                 this.config.editable = this.selectedFile.is_published != 'true';
    //             }
    //         }
    //         if (this.readonly === 'true') {
    //             this.config.editable = false;
    //         }
    //         if (this.paramEditable === 'true' && this.note.hasAudio() && this.recentAudit?.operation.includes('revert')) {
    //             this.config.editable = true;
    //         }
    //     }
    // }

    config: AngularEditorConfig = {
        editable: false,
        spellcheck: false,
        height: '100%',
        placeholder: 'Enter text here...',
        translate: 'no',
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ]
    };


    async getPatientsForDoctor(item) {
        // DOCTOR 1
        const patientsResponse: any = await this.noteEditorService.getPatients(item).toPromise();

        if (patientsResponse.status === 200) {
            this.patients = patientsResponse.data.array
                .filter(patient => patient.is_active === 'true').map(patient => ({
                    ...patient,
                    name: this.commonService.getPatientFullNameFormat2(patient),
                }));
            this.patients.sort((a, b) => {
                if (a.name > b.name) return 1;
                if (b.name > a.name) return -1;

                return 0;
            });
            // this.filteredPatients = this.patients.slice()
            this.patientControl.setValue('');

        }
    }

    async getPateintsForTranscriber(item) {
        // TANSCRIBER 2
        const associatedDoctorsListResponse: any = await this.noteEditorService.getAssociatedDoctorsList(this.currentUser.company_id).toPromise();
        if (associatedDoctorsListResponse.status === 200) {
            // const associatedDoctorsList = associatedDoctorsListResponse.data.array; // array
            const associatedDoctorsList = associatedDoctorsListResponse.data; // array
            const associatedDoctorsIds = associatedDoctorsList.map(d => d._id);
            item.associated_doctors_ids = associatedDoctorsIds; // additional parameter

            const patientsResponse: any = await this.noteEditorService.getPatients(item).toPromise();
            if (patientsResponse.status === 200) {
                this.patients = patientsResponse.data.array
                    .filter(patient => patient.is_active === 'true').map(patient => ({
                        ...patient,
                        name: this.commonService.getPatientFullNameFormat2(patient),
                    }));

                this.patients.sort((a, b) => {
                    if (a.name > b.name) return 1;
                    if (b.name > a.name) return -1;

                    return 0;
                });
                // this.filteredPatients = this.patients.slice()
                this.patientControl.setValue('');
            }
        }
    }

    async getFacilityPatients(facilityId) {
        // const companyId = this.currentUser.company_id;
        const companyId = this.note.provider.company_id;
        // const { data, status } = await lastValueFrom(this._patientsListService.getPatientsV2OnlyPatient(companyId, facilityId)) as any;
        const { data, status } = await lastValueFrom(this._patientsListService.getPatientsV2OnlyPatient(companyId, facilityId, null, this.note?.patient?.first_name)) as any;

        if (status === 200) {
            this.patients = data.array;
        }
    }
    async getFacilityPatientsByProviderId(provider_id, facility_id) {
        const response: any = await this.noteEditorService.getFacilityPatientsByProviderId(provider_id, facility_id).toPromise();

        if (response.status === 200) {
            this.patients = response.data.array;
        }
    }


    isHTMLContentValid() {
        const tmpElem = document.createElement('div');
        tmpElem.innerHTML = this.htmlContent;
        return tmpElem.innerText.trim().length > 0;
    }


    validateTranscription() {
        let hasError = !this.isHTMLContentValid() || !this.selectedPatientId || !this.title;
        if (this.category === 'tran') {
            hasError = hasError || !this.selectedDoctorId || !this.selectedFacilityId;
        }
        if (this.selectedPatient) {
            this.selectedPatientId = this.selectedPatient._id;
        }
        if (hasError) {
            if (!this.title) {
                this.toastr.error('Please enter title and try again', 'Failed');
                return false;
            }
            if (!this.isHTMLContentValid()) {
                this.toastr.error('Please enter text and try again', 'Failed');
                return false;
            }
            if (!this.selectedPatientId) {
                this.toastr.error('Please select patient and try again', 'Failed');
                return false;
            }
            if (this.category === 'tran') {
                if (!this.selectedDoctorId) {
                    this.toastr.error('Please select provider and try again', 'Failed');
                    return false;
                }
                if (!this.selectedFacilityId) {
                    this.toastr.error('Please select facility and try again', 'Failed');
                    return false;
                }
            }
        }

        return true;
    }

    async saveTranscription(options?) {
        if (!this.validateTranscription()) {
            return;
        }

        const transcription = this.initTranscription(this.selectedFile);
        if (this.isNew) {
            // ACTIVITY LOG
            this._socketService.addActivityLog({
                id: this.currentUser._id,
                screen: 'Create Note',
                operation: 'Create Note API Call',
                datetime: this._date_pipe.transform(new Date(), global.date_time_format)
            });
            return await this.createNewTranscription(transcription, options);
        } else {
            // ACTIVITY LOG
            this._socketService.addActivityLog({
                id: this.currentUser._id,
                screen: 'Update Note',
                operation: 'Update Note API Call',
                datetime: this._date_pipe.transform(new Date(), global.date_time_format)
            });
            return await this.saveExistingTranscription(transcription, undefined, options);
        }

    }

    async saveAndSubmit() {
        this.save_submit = true;
        return await this.saveTranscription();
    }

    resetData() {
        if (this.category === 'dict') {
            this.htmlContent = '';
            this.title = '';
            if (!this.patient_audio.is_patient) {
                this.selectedPatient = '';
                this.selectedPatientId = '';
            }
        }
        if (this.category === 'tran' && this.isNew) {
            this.htmlContent = '';
            this.title = '';
            this.selectedPatient = '';
            this.selectedPatientId = '';
            this.selectedFacilityId = '';
        }
    }


    async createNewTranscription(transcription, options?) {
        return new Promise((resolve, reject) => {


            // Creating new text transcription #mohsin-dev
            this.loading = true;
            transcription.is_active = 'true';
            transcription.is_locked = this.save_submit;
            transcription.is_signed = transcription.is_signed || 'false';
            transcription.is_completed = transcription.is_completed || 'false';
            transcription.uploaded_by_id = this.currentUser._id;
            transcription.date_of_service = this.date_of_service;
            transcription.creation_date = this._date_pipe.transform(this.currentDate, global.date_time_format);

            if (this.category === 'dict') {
                transcription.doctor_id = this.currentDoctor._id;
                transcription.doctor_name = this.commonService.getUserFullName(this.currentDoctor);
                transcription.facility_title = this.currentaudio.facility_id_ref.title;
                transcription.facility_id = this.currentaudio.facility_id;
                transcription.audio_id = this.currentaudio._id;
                // transcription.date =  this._date_pipe.transform(this.currentaudio.date_time, global.date_time_format);
            } else {
                transcription.doctor_id = this.selectedDoctorId;
                const selectedDoctor = this.note.provider;
                if (selectedDoctor) {
                    transcription.doctor_name = this.commonService.getUserFullName(selectedDoctor);
                }
                const selectedFacility = this.facilities.find(f => f._id === this.selectedFacilityId);
                if (selectedFacility) {
                    transcription.facility_id = selectedFacility._id;
                    transcription.facility_title = selectedFacility.title;
                }

            }

            const selectedPatient = this.patients.find(p => p._id === this.selectedPatientId);
            if (selectedPatient) {
                transcription.patient_id = selectedPatient._id;
                transcription.patient_name = this.commonService.getPatientFullName(selectedPatient);
            }

            if (this.currentUser.user_type == global.USER_TYPE.DOCTOR || this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE) {
                transcription.is_published = 'true';
                transcription.is_locked = true;
            } else {
                transcription.is_published = 'false';
                transcription.is_locked = this.save_submit;
            }

            // transcribt.transcribtion_text =this.encryptDecryptService.encrypt(this.htmlContent); // decrypt transaction_text // removed client side encryption #mohsin-dev
            // this.encryptDecryptService.encrypt(this.htmlContent).subscribe(data => {

            // encrypt transcription_text with server #mohsin-dev
            // transcription.transcribtion_text = String(data);
            // });
            this.noteEditorService.create(transcription, this.category).subscribe((response: any) => {
                // this.response = response;
                if (response.status == 200) {
                    this.toastr.success('Success', response.message);
                    this.isNew = false;
                    // this.goBack();
                    this.loading = false;
                    this.selectedFile = response.data;
                    // localStorage.setItem('selectedFile', JSON.stringify(this.response.data));
                    // localStorage.setItem('transcriptionFiles', JSON.stringify([this.response.data, ...this.transcriptionFiles]));

                    // this._router.navigate(['/transcription-create', {category: this.category}])

                    if (this.isAddMore) {
                        const routeMapParams: any = { category: this.category, new: 1 };
                        const audio_id_param = this._route.snapshot.paramMap.get('audio_id');
                        if (audio_id_param) {
                            routeMapParams.audio_id = audio_id_param;
                        }
                        if (!options?.preventReload) {
                            this.reloadScreen(routeMapParams);
                        }
                        // this.reloadScreen({category: this.category, new: 1, addMore: 1});
                    } else {
                        const id = response.data._id
                        const routeMapParams: any = { category: this.category, id };
                        if (this.category == 'dict' && response.data.audio_id) {
                            routeMapParams.audio_id = response.data.audio_id
                        }
                        if (!options?.preventReload) {
                            if (this.save_submit) {
                                this.goBack();
                            } else {
                                this.reloadScreen(routeMapParams);
                            }
                        }

                        // this.goBack();
                    }
                    resolve(response.data);
                } else {
                    this.toastr.error('Failed', response.message);
                }
            });


        })

    }

    saveExistingTranscription = async (transcription, callback = null, options?) => {
        return new Promise<void>(async (resolve, reject) => {
            this.loading = true;

            const {
                _id: transcribtion_id,
                transcribtion_text
            } = transcription;
            const data = {
                transcribtion_id,
                transcribtion_title: this.generateTranscriptionTitle(),
                transcribtion_text
            }

            this.noteEditorService.updateExisting(data, this.category).subscribe((response: any) => {
                // this.response = response;
                if (response.status == 200) {
                    this.saveInitialState();
                    if (typeof callback === 'function') {
                        callback();
                    } else {
                        this.toastr.success('Success', response.message);
                        // this.goBack();
                        // this.reloadScreen();
                        if (this.transcriptionFiles) {
                            this.transcriptionFiles = this.transcriptionFiles.map((t: any) => {
                                if (t._id === this.selectedFile._id) {
                                    return { ...t, ...transcription };
                                }
                                return t;
                            });
                            this.loading = false;
                        }
                    }
                    resolve(null);
                } else {
                    this.toastr.error('Failed', response.message);
                }
            });

        })

    };


    changeCurrentAudioStatus(statusId) {
        let tempPath = this.note.audio.aws_path;
        this.createpdfservice.changeCurrentAudioStatus(this.note.audio, statusId).subscribe((response: any) => {
            if (response.status === 200) {
                this.note.audio = response.data;
                // if (this.note.audio.aws_path.indexOf('s3.amazonaws.com') === -1) {
                //     this.encryptDecryptService.decrypt(this.note.audio.aws_path, 'd7XBUv]Ms<[U{4KH+YyJ<ep5+-mnaE').subscribe(
                //         data => {
                //             // this.note.audio.aws_path = data;
                //         }, error => {
                //         }
                //     );
                // }
                this.note.audio.aws_path = tempPath;
                // this.note.audio.progress_status = (this.note.audio.progress_status === '19') ? 'IN PROGRESS' : 'COMPLETED';
            }

        });
    }

    /*
    Sign Transcription sets is_signed flag to true in the database #mohsin-dev
    */
    async signTranscription(is_signed) {
        this.loading = true;
        const transcription = this.initTranscription(this.selectedFile);
        transcription.is_signed = is_signed;
        transcription.is_completed = 'false';
        transcription.transcribtion_id = this.selectedFile._id;

        if (is_signed == 'true') {
            await this.saveExistingTranscription(transcription, () => {
            });
            this.config.editable = false;
        } else {
            this.config.editable = true;
        }

        // ACTIVITY LOG
        this._socketService.addActivityLog({
            id: this.currentUser._id,
            screen: 'Update Note',
            operation: 'Sign Note Status Change API Call',
            datetime: this._date_pipe.transform(new Date(), global.date_time_format)
        });

        //START signTranscription
        this.noteEditorService.signTranscription(transcription, this.category).subscribe((response: any) => {
            if (response.status == 200) {
                this.toastr.success('Success', response.message);
                // this.goBack()
                // if (is_signed == 'true') {
                //     // this.navigateEditTranscription(1);
                //     // this.initializeData();
                //     this.reloadScreen();
                // } else {
                //     this.reloadScreen();
                // }
            } else {
                this.toastr.error('Failed', response.message);
            }
            this.loading = false;
        }, (error) => {
            console.error(error);
            this.toastr.error('Something went wrong, please try again', 'Error');
            this.loading = false;
        });
        //END signTranscription
    }

    async completeTranscription(is_completed) {
        this.loading = true;
        const transcription = this.initTranscription(this.selectedFile);
        transcription.is_completed = is_completed;
        transcription.transcribtion_id = this.selectedFile._id;
        const toastInfo = this.toastr.info('Processing...', 'Please wait', { disableTimeOut: true });

        // ACTIVITY LOG
        this._socketService.addActivityLog({
            id: this.currentUser._id,
            screen: 'Update Note',
            operation: 'Upload Note Status Change API Call',
            datetime: this._date_pipe.transform(new Date(), global.date_time_format)
        });

        const response: any = await this.noteEditorService.completeTranscription(transcription).toPromise();
        if (response.status === 200) {
            this.toastr.success('Success', response.message);
            this.saveInitialState();
            // this.reloadScreen();
        } else {
            this.toastr.error(response.message, 'Failed');
        }
        this.loading = false;
        this.toastr.clear(toastInfo.toastId);
    }

    isSignAndUploadVisibleForNewNote() {
        if (this.isNew && (this.currentUser.user_type == global.USER_TYPE.DOCTOR || this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE)) {
            const patient = this.note.patient;

            if (patient?.pcc_patientId || patient?.source === 'PointClickCare') {
                return true;
            }
        }
        return false;
    }
    async signAndUploadTranscription() {

        if (!this._pccService.pccUserinfo) {
            this.toastr.error('Please login with PCC first', 'Error')
            return false;
        }

        let selectedPatient;
        if (Array.isArray(this.patients) && this.selectedPatientId) {
            selectedPatient = this.patients.find(p => p._id === this.selectedPatientId);
        } else {
            selectedPatient = this.selectedPatient
        }

        if (!selectedPatient.pcc_patientId) {
            this.toastr.error('Selected patient is not imported from pcc', 'Error')
            return false;
        }

        if (!this.validateTranscription()) {
            return false;
        }


        if (!this.selectedFile) {
            this.loading = true;

            this.selectedFile = {};
            this.selectedFile = await this.saveTranscription({ preventReload: true })
            this.selectedFile.facility_id_ref = this.note.facility;
            const routeMapParams: any = { category: this.category, id: this.selectedFile._id };
            if (this.category == 'dict' && this.selectedFile.audio_id) {
                routeMapParams.audio_id = this.selectedFile.audio_id
            }
            let url = `/transcription-create;category=${routeMapParams.category};id=${this.selectedFile._id}`;
            if (routeMapParams.audio_id) {
                url += `;audio_id=${routeMapParams.audio_id}`
            }
            this._location.go(url)
            this.config.editable = false;

        }

        this.showUploadNoteConfirmationDialog({ type: 'note' }, async () => {


            const transcription = this.initTranscription(this.selectedFile);
            transcription.is_signed = 'true';
            transcription.is_completed = 'true';
            transcription.transcribtion_id = this.selectedFile._id;

            await this.saveExistingTranscription(transcription, () => {
            }, { preventReload: true });

            // ACTIVITY LOG
            this._socketService.addActivityLog({
                id: this.currentUser._id,
                screen: 'Update Note',
                operation: 'Sign Note Status Change API Call',
                datetime: this._date_pipe.transform(new Date(), global.date_time_format)
            });

            //START signTranscription
            this.noteEditorService.signAndUploadTranscription(transcription, this.category).subscribe((response: any) => {
                if (response.status == 200) {
                    this.toastr.success('Success', response.message);

                    this.selectedFile.pcc_progressNoteId = response.data.pcc_progressNoteId;
                    this.selectedFile.pcc_uploaded_date = response.data.pcc_uploaded_date;
                    // this.navigateEditTranscription(1);
                    this.saveInitialState();
                    this.config.editable = false;

                } else {
                    this.toastr.error('Failed', response.message);
                }
                this.loading = false;

            }, (error) => {

                console.error(error);
                this.toastr.error('Something went wrong, please try again', 'Error');
                this.loading = false;
            });

        });
    }

    isLoggedInPCC() {
        return !!this._pccService.pccUserinfo;
    }
    // fileNameGenarator() {
    //   var user = this.currentUser;
    //   var date = new Date();
    //   var monthList = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Oct','Nov','Dec'];
    //   var hours = date.getHours();
    //   var minutes = date.getMinutes();
    //   var ampm = hours >= 12 ? 'pm' : 'am';
    //   hours = hours % 12;
    //   hours = hours ? hours : 12; // the hour '0' should be '12'
    //   var minutesCon = minutes < 10 ? '0'+minutes : minutes;
    //   var time = hours + '_' + minutesCon + '_' + ampm;
    //   var name = user.first_name.replace(' ','_')+'_'+user.last_name.replace(' ','_')+'_'+monthList[date.getMonth()] + "_" + date.getDate() + "_" + date.getFullYear() + "_" + time;
    //   // Removed file extension .docx from title string #mohsin-dev
    //   return date.getTime() + '_' +name; // Added timestamp #mohsin-dev
    // }
    generateTranscriptionTitle() {
        // const white_space = '_';
        // const datetime = this._date_pipe.transform(this.currentDate, 'MMM dd yyyy mm ss aaaaa\'m\'');
        // const timestamp = this.currentDate.getTime();
        // const selectedPatientName = this.selectedPatient ? `${this.selectedPatient.first_name.trim()} ${this.selectedPatient.middle_name && this.selectedPatient.middle_name + ' '}${this.selectedPatient.last_name.trim()}` : "";
        // const generatedTitle = `${timestamp} ${selectedPatientName.trim()} ${datetime}`;
        // return generatedTitle.replace(/\s/g,white_space);

        return this.currentDate.getTime() + '_' + this.title ? this.title.trim().replace(/\s/g, '_') : '';
    }

    formattedDataGenarator() {
        var date = new Date();
        var monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Oct', 'Nov', 'Dec'];
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        var minutesCon = minutes < 10 ? '0' + minutes : minutes;
        var time = hours + ':' + minutesCon + ' ' + ampm;
        var formatted = monthList[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear() + ' ' + time;
        return formatted;
    }

    checkAudioIdExists() {
        return !!this.currentaudio;
    }
    /*
    * Fixed go back button #mohsin-dev
    *
    */
    goBack() {
        if (this.note?.is_signed === undefined || this.note?.is_signed === "false") {
            if (!this.compareOriginalNoteState()) {
                if (!confirm('If you leave before saving, your changes will be lost.')) {
                    return;
                }
            }
        }
        const backUrl = this.goBackUrlService.getBackUrl({
            // '/transcription-detail': '/dashboard;category=dict;status=All_Dictations',
        });
        if (backUrl) {
            this.router.navigateByUrl(backUrl);
        } else {
            this._location.back();
        }

        // this.goBack();
        // if(this.category === 'dict') {
        //   this.router.navigate(['/transcription-detail', {category: this.category }])
        // } else {
        //   this.router.navigate(['/dashboard', {category: this.category }])
        // }
        //
        // this.router.navigate(['/dashboard', {category: 'tran', status: 'Un_Signed'}]);
    }

    /**
     * Created iniTranscription to reuse function #mohsin-dev
     */
    initTranscription(transcription?) {
        try {
            if (!transcription) {
                transcription = {};
            }
            transcription.id = this.currentUser._id;
            transcription.usertype = this.currentUser.user_type;
            transcription.auth_key = this.currentUser.auth_key;

            // transcription.transcribtion_id = this.selectedFile ? this.selectedFile._id : null; // added missing transcribtion_id field #mohsin-dev
            transcription.transcribtion_title = this.generateTranscriptionTitle(); // added transcribtion_title field #mohsin-dev
            transcription.transcribtion_text = this.htmlContent; // added transcribtion_text field #mohsin-dev


            transcription.doctor_name = this.selectedDoctorName;
            transcription.patient_id = this.selectedPatientId;
            if (this.selectedPatient) {
                transcription.patient_name = this.commonService.getPatientFullName(this.selectedPatient);
            }
            transcription.date_of_service = this.date_of_service;
            // transcription.date = this.date;

            return transcription;
        } catch (error) {
            this.toastr.error('Something went wrong, please try again', 'Error');
            console.log('Error in initTranscription() : ' + error?.message);
            this.router.navigate(['/dashboard'])
        }
    }

    /**
     * Get Facilities by doctor
     */
    async getFacilities(doctorId) {

        // this.loading = true;
        const response: any = await this.noteEditorService.getFacilities(doctorId).toPromise();
        if (response.status === 200) {
            this.facilities = response.data.array;
        }
        if (this.isNew) {
            this.selectedFacilityId = '';
        }
        // this.loading = false;
    }

    reviseNote(is_published) {
        // @ts-ignore
        // @ts-ignore
        swal({
            title: "Are you sure?",
            text: "Once revised, you will not be able to undo this!",
            icon: "warning",
            buttons: [
                true, true
            ],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    this.publishTranscription(is_published);
                } else {
                }
            });
    }
    /*
    Publish Transcription sets is_published flag to true|false in the database #mohsin-dev
    */
    publishTranscription(is_published) {
        if (this.htmlContent.trim().length === 0) {
            this.toastr.error('Please enter text and try again', 'Failed');
            return;
        }
        this.loading = true;

        const transcription = this.initTranscription(this.selectedFile);
        transcription.is_published = is_published;
        transcription.is_signed = 'false';
        const infoToast = this.toastr.info('Processsing...', 'Please wait', { disableTimeOut: true });

        const {
            _id: transcribtion_id,
            transcribtion_text
        } = transcription;
        const data = {
            transcribtion_id,
            transcribtion_title: this.generateTranscriptionTitle(),
            transcribtion_text,
            is_published: is_published,
            is_signed: 'false'
        }

        this.saveExistingTranscription(data, async () => {

            // ACTIVITY LOG
            this._socketService.addActivityLog({
                id: this.currentUser._id,
                screen: 'Update Note',
                operation: 'Publish Note Status Change API Call',
                datetime: this._date_pipe.transform(new Date(), global.date_time_format)
            });
            const {
                _id: transcribtion_id,
            } = transcription;
            const _data = {
                transcribtion_id,
                is_published: is_published,
                is_signed: 'false'
            }
            const publishTranscriptionResponse: any = await this.noteEditorService.publishTranscription(_data, this.category).toPromise();

            if (publishTranscriptionResponse.status == 200) {
                if (is_published == 'true') {
                }
                // this.goBack();
                this.reloadScreen();

            } else {
                this.toastr.error('Failed', publishTranscriptionResponse.message);
            }
            this.toastr.clear(infoToast.toastId);
            this.loading = false;
        });
    }


    getCurrentNoteIndex() {
        return this.dashboardData.notes.findIndex(note => note._id === this.note._id);
    }
    getNextPrevNoteUrl(nextPrev: 1 | -1) {
        const currentIndex = this.getCurrentNoteIndex();
        if (currentIndex !== -1) {
            const nextIndex = currentIndex + nextPrev;
            if (nextIndex >= 0 && nextIndex < this.dashboardData.notes.length) {
                const noteId = this.dashboardData.notes[nextIndex]._id;
                if (noteId) {
                    return `/note-editor/${noteId}`;
                }
            }
        }
    }

    getCurrentAudioIndex() {
        return this.dashboardData.audios.findIndex(audio => audio._id === this.note?.audio?._id);
    }
    async getNextPrevAudioUrl(nextPrev: 1 | -1) {
        const currentIndex = this.getCurrentAudioIndex();
        if (currentIndex !== -1) {
            const nextIndex = currentIndex + nextPrev;
            if (nextIndex >= 0 && nextIndex < this.dashboardData.audios.length) {
                const audioId = this.dashboardData.audios[nextIndex]._id;
                if (audioId) {
                    const audioNoteResponse: any = this.dashboardService.checkAudioNote(audioId).toPromise();
                    if (audioNoteResponse.status === 200) {
                        return `/note-editor/${audioNoteResponse.data._id}`;
                    } else {
                        return `/note-editor/new;audio_id=${audioId}`;
                    }
                }
            }
        }
    }
    async initNextPrev() {
        this.dashboardData.note.prev = this.getNextPrevNoteUrl(-1);
        this.dashboardData.note.next = this.getNextPrevNoteUrl(1);

        this.dashboardData.audio.prev = await this.getNextPrevAudioUrl(-1);
        this.dashboardData.audio.next = await this.getNextPrevAudioUrl(1);
    }

    navigate(url) {
        if (this.buttonsState.save) {
            // this._router.navigateByUrl(url);
            this.router.navigateByUrl('/note-editor', { skipLocationChange: true }).then(() => {
                this.router.navigateByUrl(url + ';disable-sidebar=1');
            })
        }
        else {
            this.router.navigateByUrl('/note-editor', { skipLocationChange: true }).then(() => {
                this.router.navigateByUrl(url + ';disable-sidebar=1');
            })
        }
    }

    isChooseTemplateButtonVisible() {
        if (!this.isNew && (this.currentUser.user_type == global.USER_TYPE.DOCTOR
            || this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE)
            && this.selectedFile && this.currentUser._id !== this.selectedFile.uploaded_by_id) {
            return false;
        }

        if (this.selectedFile && this.selectedFile.is_published == 'true') {
            return false;
        }

        return true;
    }

    showTemplateDialog(shouldShowTemplateDialog = true) {
        if (!this.note.facility) {
            return this.toastr.error('Please select facility first');
        }
        if (!this.note?.patient?._id) {
            return this.toastr.error('Please select patient first');
        }
        if (!shouldShowTemplateDialog) {
            return;
        }
        this.showOnceTemplateDialog = true;
        const dialogRef = this.dialog.open(TranscriptionTemplateDialog, {
            width: '600px',
            data: {
                currentTemplate: this.currentTemplate,
                currentUser: this.currentUser,
                facilityId: this.note.facility._id,
                patientId: this.note.patient?._id || this.note.patient_id,
                providerId: this.note.provider?._id,
                note: this.note
            }
        });
        dialogRef.afterClosed().subscribe(async (data) => {
            try {
                this.autocomplete.closePanel()
            } catch (error) {
                console.log('Error in showTemplateDialog() : ' + error?.message);
            }
            if (!data) {
                return;
            }
            const { blank = null, template = null, note = null } = data;

            if (note) {
                this.handleNoteSelect(note);
            } else if (blank) {
                this.note.title = 'NO TITLE';
                this.note.htmlText = 'No Text';
            } else if (template) {
                this.handleTemplateSelect(template)
            }
        });
    }
    showPatientDialog() {
        if (!this.note.facility) {
            return this.toastr.error('Please select facility first');
        }

        const dialogRef = this.dialog.open(PatientsDialog, {
            width: '80%',
            data: {
                provider: this.note.provider,
                facility: this.note.facility,
                patient: this.note.patient
            }
        });
        dialogRef.afterClosed().subscribe(async (patient) => {
            if (patient) {
                const patientIndex = this.patients.findIndex(p => p._id === patient._id);
                if (patientIndex >= 0) {
                    this.patients[patientIndex] = patient;
                } else {
                    this.patients.push(patient);
                }
                this.note.patient = patient;
            }
        });
    }

    showPhraseDialog() {
        const dialogRef = this.dialog.open(DictionaryDialogComponent, {
            width: '400px'
        });

        dialogRef.afterClosed().subscribe(async (data) => {

            this.loader.startBackgroundLoader(this.loaderId);
            await this.getPhrases();
            this.loader.stopBackgroundLoader(this.loaderId);

        });

    }

    showDictionaryEditorDialogComponent() {
        const dialogRef = this.dialog.open(DictionaryEditorDialogComponent, {
            width: '800px'
        });

        dialogRef.afterClosed().subscribe(async (data) => {

            // this.loader.startBackgroundLoader(this.loaderId);
            // await this.getPhrases();
            // this.loader.stopBackgroundLoader(this.loaderId);

        });

    }

    showPatientAddDialog() {
        const activeElement = document.activeElement as HTMLInputElement;
        if (activeElement) {
            activeElement.blur();
        }
        const dialogRef = this.dialog.open(PatientAddDialog, {
            width: '80%',
            data: {
                selectedFacilityId: (this.category === 'dict' && this.currentaudio) ? this.currentaudio.facility_id : this.selectedFacilityId,
                selectedDoctorId: this.selectedDoctorId
            }
        });
        dialogRef.beforeClosed().subscribe(async (patient: any) => {
            if (patient && patient._id) {
                patient.name = this.commonService.getPatientFullNameFormat2(patient);

                this.patients.push(patient);
                this.note.patient = patient;
            }
        });
    }
    showAddendumNoteDialog() {
        const dialogRef = this.dialog.open(AddendumNoteDialog, {
            width: '50%',
            data: {
                noteId: this.selectedFile._id
            }
        });
        dialogRef.beforeClosed().subscribe(async (data: any) => {

        });
    }
    showUploadNoteConfirmationDialog(options, onConfirmation?: Function) {
        const { type, confirmButtonLabel, noteId, time_zone } = options;
        const dialogRef = this.dialog.open(UploadNoteConfirmationDialog, {
            width: '80%',
            data: {
                type,
                noteId: this.note._id || noteId,
                rawText: !this.isCreatingAddendum ? this.note.htmlText : this.addendum.text,
                payload: this.addendum,
                confirmButtonLabel,
                time_zone
            }
        });
        dialogRef.beforeClosed().subscribe(async (confirm: any) => {
            if (confirm) {
                if (typeof onConfirmation === 'function') {
                    onConfirmation(confirm);
                }
            }
        });
    }


    doCheckIsTouched() {
        if (this.isNew) {
            const patient = this.selectedPatientId || this.selectedPatient;
            this.isTouched = this.htmlContent.length > 0 && patient && !!this.selectedFacilityId && !!this.title;
        } else if (this.selectedFile && this.htmlContent) {
            this.isTouched = JSON.stringify([this.selectedFile,
            this.htmlContent, this.title]) !== this.initialState;
        }
        return this.isTouched;
    }

    doCheckExistInTextNote() {       
        if (this.note?.date_obj && this.note?.htmlText) {
            const { year, month, day } = this.note.date_obj;
            const _month = month < 10 ? `0${month}` : month;
            const _day = day < 10 ? `0${day}` : month;
            this.existInNoteText.date_of_service = this.note.htmlText.includes(`${month}/${day}/${year}`) || this.note.htmlText.includes(`${_month}/${_day}/${year}`)
        }
    }

    doCheckDateOfServiceMatch() {
        if (this.note?.hasAudio?.()) {
            const exactMatch = this.note.audio.filterServiceDate === this.note.dates.service;
            let sDateAudio = JSON.parse(JSON.stringify(this.note.audio.filterServiceDate));
            let sDateNote = JSON.parse(JSON.stringify(this.note.dates.service));
            const dateAudio = moment(new Date(sDateAudio)).format('MM/DD/YYYY')
            const dateNote = moment(new Date(sDateNote)).format('MM/DD/YYYY')
            const matchDateOnly = dateAudio === dateNote;
            if (exactMatch) {
                this.isMatchAudioDOSWithNoteDOS = true;
            } else if (matchDateOnly) {
                this.isMatchAudioDOSWithNoteDOS = true;
            } else {
                this.isMatchAudioDOSWithNoteDOS = false;
            }    
        }
    }

    pccInProgress() {
        return this._pccService.inprogress;
    }


    checkAndShowOnceTemplateDialog() {
        if (this.isNew && !this.showOnceTemplateDialog) {
            if (this.note.facility && this.note.patient && this.note.patient._id) {
                this.showTemplateDialog(this.shouldShowTemplateDialog);
                this.showOnceTemplateDialog = true;
            }
        }
        if (this.isNoTitleNote() && this.note.htmlText.length === 0) {
            this.showTemplateDialog();
        }
    }

    doCheckButtonStates() {
        if (!this.note) {
            return;
        }

        if (this.isCreatingAddendum) {
            this.addendumContainsUnselectedDropdowns = this.note.containsUnselectedDropdowns(this.addendum.text);
            this.addendumContainsAsterisks = this.note.containsAsterisks(this.addendum.text)
        }

        const loader = this.loader.getLoader(this.loaderId);

        const phraseLoader = this.loader.getLoader('phrase_loader');
        if ((loader && Object.keys(loader.tasks).length) || (phraseLoader && Object.keys(phraseLoader.tasks).length)) {
            this.buttonsState.save = false;
            this.buttonsState.submitForReview = false;
            this.buttonsState.revert = false;
            this.buttonsState.sign = false;
            this.buttonsState.upload = false;
            this.buttonsState.addendum = false;
            this.buttonsState.uploadAddendum = false;
            return;
        }

        if (this.isNew && !this.note.isInvalid()) {
            this.buttonsState.save = true;
            if (this.isUserType(global.USER_TYPE.DOCTOR) || this.isUserType(global.USER_TYPE.SNF_WC_NURSE)) {
                // this.buttonsState.sign = true; // Before false, Now True: Beacuse now on sign note save api calls fist
            }
            if (this.note.htmlText.length > 0 && this.buttonsState.save === true) {
                this.pccWidgetService.changeSaveNoteStatus(true);
                this.pccWidgetService.changeNote(this.note);
            }
            return;
        }
        if (!this.isNew && this.note.isInvalid()) {
            this.buttonsState.save = false;
            this.buttonsState.sign = false;            
            return;
        }

        if (!this.note?.coSign_status?.status && (this.note?.provider?._id == this.currentUser._id || this.isAssociatedProvider()) && !this.note.isSigned()) {
            this.buttonsState.sign = true;
        }
        else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && !this.note.isSigned()) {
            this.buttonsState.sign = true;
        }
        else {
            this.buttonsState.sign = false;
        }
        // Note Buttons
        if (this.note.isInProgress(this.currentUser._id, this.relationship, this.assoc_provider_ids)) {
            this.config.editable = true;

            if (!this.compareOriginalNoteState()) {
                this.buttonsState.save = true;
                this.buttonsState.submitForReview = false;

                if (this.note.htmlText.length > 0 && this.buttonsState.save === true) {
                    this.pccWidgetService.changeSaveNoteStatus(true);
                    this.pccWidgetService.changeNote(this.note);
                }

            } else {
                this.buttonsState.save = false;
                this.buttonsState.submitForReview = true;
            }
        }
        else if (this.note.isInMAReview(this.currentUser._id, this.relationship)) {
            if (this.note.note_status.status === 'in_progress' || this.note.note_status.status === 'published') {
                this.config.editable = true;
            }

            if (!this.compareOriginalNoteState()) {
                this.buttonsState.save = true;
                this.buttonsState.submitForReview = false;
                this.buttonsState.revert = false;

                if (this.note.htmlText.length > 0 && this.buttonsState.save === true) {
                    this.pccWidgetService.changeSaveNoteStatus(true);
                    this.pccWidgetService.changeNote(this.note);
                }

            } else if (this.currentUser.user_type === this.global.USER_TYPE.DOCTOR || this.currentUser.user_type === this.global.USER_TYPE.SNF_WC_NURSE) {
                this.buttonsState.save = false;
                this.buttonsState.submitForReview = false;
                this.buttonsState.revert = false;
            } else {
                this.buttonsState.save = false;
                if (this.note.note_status.status === 'in_progress') {
                    this.buttonsState.submitForReview = true;
                }
                this.buttonsState.revert = false;
            }
        }
        else if (this.note.isInQAIReview(this.currentUser._id, this.relationship)) {
            this.config.editable = true;

            if (!this.compareOriginalNoteState()) {
                this.buttonsState.save = true;
                this.buttonsState.submitForReview = false;
                this.buttonsState.revert = false;
                if (this.note.htmlText.length > 0 && this.buttonsState.save === true) {
                    this.pccWidgetService.changeSaveNoteStatus(true);
                    this.pccWidgetService.changeNote(this.note);
                }
            } else {
                this.buttonsState.save = false;
                this.buttonsState.submitForReview = true;
                this.buttonsState.revert = true;
            }
        } else if (this.note.isInQAEReview(this.currentUser._id, this.relationship)) {
            this.config.editable = true;

            if (!this.compareOriginalNoteState()) {
                this.buttonsState.save = true;
                this.buttonsState.submitForReview = false;
                this.buttonsState.revert = false;

                if (this.note.htmlText.length > 0 && this.buttonsState.save === true) {
                    this.pccWidgetService.changeSaveNoteStatus(true);
                    this.pccWidgetService.changeNote(this.note);
                }

            } else {
                this.buttonsState.save = false;
                this.buttonsState.submitForReview = true;
                this.buttonsState.revert = true;
            }
        } else if (this.note.isPublished(this.currentUser._id, this.relationship, this.assoc_provider_ids)) {
            this.containsUnselectedDropdowns = this.note.containsUnselectedDropdowns();             
            this.containsAsterisks = this.note.containsAsterisks();
            this.config.editable = true;
            this.buttonsState.submitForReview = false;
            if (!this.compareOriginalNoteState()) {
                this.buttonsState.save = true;
                if (this.note.htmlText.length > 0 && this.buttonsState.save === true) {
                    this.pccWidgetService.changeSaveNoteStatus(true);
                    this.pccWidgetService.changeNote(this.note);
                }
               
                if (this.containsAsterisks || this.containsUnselectedDropdowns) {
                    this.buttonsState.sign = false;
                } else {
                    // 
                    // Before false, Now True: Beacuse now on sign note save api calls fist
                    if (!this.note?.coSign_status?.status && (this.note?.provider?._id == this.currentUser._id || this.isAssociatedProvider()) && !this.note.isSigned()) {
                        this.buttonsState.sign = true;
                    }
                    else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && !this.note.isSigned()) {
                        this.buttonsState.sign = true;
                    }
                    else {
                        this.buttonsState.sign = false;
                    }
                }
                this.buttonsState.revert = false;
            } else {
                this.buttonsState.save = false;
                if (this.containsAsterisks || this.containsUnselectedDropdowns) {
                    this.buttonsState.sign = false;
                } else {
                    // 
                    // Before false, Now True: Beacuse now on sign note save api calls fist
                    if (!this.note?.coSign_status?.status && (this.note?.provider?._id == this.currentUser._id || this.isAssociatedProvider()) && !this.note.isSigned()) {
                        this.buttonsState.sign = true;
                    }
                    else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && !this.note.isSigned()) {
                        this.buttonsState.sign = true;
                    }
                    else {
                        this.buttonsState.sign = false;
                    }
                }
                if ((this.note?.coSign_status?.coSigned_to == this.currentUser._id)) {
                    this.buttonsState.revert = false
                }
                else if (this.note.uploadedBy?._id !== this.currentUser._id && !this.isAssociatedProvider()) {
                    if (this.relationship?.assoc_ma_ids?.includes(this.note.uploadedBy?._id)) {
                        this.buttonsState.revert = false;
                    }
                    else {
                        this.buttonsState.revert = true;
                    }
                } else {
                    this.buttonsState.revert = false;
                }
            }
        } else if (this.note.hasPCCPatient()) {

            if (this.note.isUploaded()) {
                this.buttonsState.save = false;
                this.buttonsState.submitForReview = false;
                this.buttonsState.revert = false;
                if (!this.note?.coSign_status?.status && (this.note?.provider?._id == this.currentUser._id || this.isAssociatedProvider()) && !this.note.isSigned()) {
                    this.buttonsState.sign = true;
                }
                else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && !this.note.isSigned()) {
                    this.buttonsState.sign = true;
                }
                else {
                    this.buttonsState.sign = false;
                }
                this.buttonsState.upload = false;
                if (!this.isCreatingAddendum) {
                    this.config.editable = false;
                }
            } else if (this.note.isSigned()) {

                // this.buttonsState.sign = false;
                if (!this.note?.coSign_status?.status && (this.note?.provider?._id == this.currentUser._id || this.isAssociatedProvider()) && !this.note.isSigned()) {
                    this.buttonsState.sign = true;
                }
                else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && !this.note.isSigned()) {
                    this.buttonsState.sign = true;
                }
                else {
                    this.buttonsState.sign = false;
                }
                this.buttonsState.save = false;
                this.buttonsState.submitForReview = false;
                this.buttonsState.revert = false;
                this.config.editable = false;

                if (this.note.isSigned() && !this.note.isUploaded() && this.isCreatingAddendum && !this.is_uploadPcc_permission_enabled) {
                    this.config.editable = true;
                }

                if (!this.hide_addendum_button && this.isCreatingAddendum) {
                    this.config.editable = true;
                }                
                if (this.hasLoggedInToPCC() || this.canUploadNoteToPCCUsingTwoLegged()) {
                    if ((this.hasLoggedInToPCCFacility() || this.canUploadNoteToPCCUsingTwoLegged()) && this.note.coSign_status && this.note.coSign_status.status === true && (this.currentUser._id == this.note.coSign_status.coSigned_to || this.currentUser._id == this.note.coSign_status.coSigned_by) && this.note.isSigned()) {
                        this.buttonsState.upload = true;
                    }
                    else if (this.currentUser.user_type === global.USER_TYPE.DOCTOR && !this.note.isRelatedProvider(this.currentUser._id)) {
                        this.buttonsState.upload = false;
                    }
                    // else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && this.note.isSigned()){
                    //     this.buttonsState.upload = true;
                    // }
                    else {
                        this.buttonsState.upload = true;
                    }
                } else {
                    this.buttonsState.upload = false;
                }
                if (this.note.hasPCCPatientandDnFacility()) {
                    if ((this.currentUser.user_type === global.USER_TYPE.DOCTOR || this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) && !this.note.isRelatedProvider(this.currentUser._id)) {
                        this.buttonsState.upload = false;
                    } else {
                        this.buttonsState.upload = true;
                    }

                }
                // this.buttonsState.upload = true;

                // if(this.note.isSigned(this.currentUser._id)) {
                //     this.buttonsState.revert = true;
                // }
            } else {
                if (this.note.provider._id === this.currentUser._id && this.note.note_status.status === 'published') {
                    this.buttonsState.sign = true;
                } else {
                    this.buttonsState.sign = false;
                }
                if (!this.note?.coSign_status?.status && (this.note?.provider?._id == this.currentUser._id || this.isAssociatedProvider()) && !this.note.isSigned()) {
                    this.buttonsState.sign = true;
                }
                else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && !this.note.isSigned()) {
                    this.buttonsState.sign = true;
                }
                else {
                    this.buttonsState.sign = false;
                }
                this.buttonsState.save = false;
                this.buttonsState.submitForReview = false;
                this.buttonsState.revert = false;
                this.buttonsState.upload = false;
                if (this.relationship?.assoc_ma_ids?.includes(this.currentUser._id)) {
                    this.config.editable = true;
                    if (!this.compareOriginalNoteState()) {
                        this.buttonsState.save = true;
                        if (this.note.htmlText.length > 0 && this.buttonsState.save === true) {
                            this.pccWidgetService.changeSaveNoteStatus(true);
                            this.pccWidgetService.changeNote(this.note);
                        }
                    } else {
                        this.buttonsState.save = false;
                    }

                }
            }
        } else if (!this.note.hasPCCPatient()) {            
            if (this.note.isUploaded()) {
                this.buttonsState.save = false;
                this.buttonsState.submitForReview = false;
                this.buttonsState.revert = false;
                // this.buttonsState.sign = false;
                if (!this.note?.coSign_status?.status && (this.note?.provider?._id == this.currentUser._id || this.isAssociatedProvider()) && !this.note.isSigned()) {
                    this.buttonsState.sign = true;
                }
                else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && !this.note.isSigned()) {
                    this.buttonsState.sign = true;
                }
                else {
                    this.buttonsState.sign = false;
                }
                this.buttonsState.upload = false;
                this.buttonsState.revert = false;
                if (!this.isCreatingAddendum) {
                    this.config.editable = false;
                }

            } else if (this.note.isSigned()) {

                this.buttonsState.save = false;
                this.buttonsState.submitForReview = false;
                this.buttonsState.revert = false;
                if (!this.note?.coSign_status?.status && (this.note?.provider?._id == this.currentUser._id || this.isAssociatedProvider()) && !this.note.isSigned()) {
                    this.buttonsState.sign = true;
                }
                else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && !this.note.isSigned()) {
                    this.buttonsState.sign = true;
                }
                else {
                    this.buttonsState.sign = false;
                }
                // below if-else changed so the initator of co-sign after co-sigining the note will not be able to perform other tasks
                // this.buttonsState.upload = true;

                if (!this.note?.coSign_status?.status && (this.note?.provider?._id == this.currentUser._id || this.isAssociatedProvider())) {
                    /////     this will uploaded by provider itself or its assosiated providers

                    // this.buttonsState.upload = true;
                    // if(this.buttonsState.upload){
                    this.buttonsState.upload = false;
                    //}
                }
                else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to) {
                    /////     this will uploaded by co Signed provider
                    this.buttonsState.upload = true;
                }
                else if (this.currentUser.user_type == global.USER_TYPE.TRANSCRIBER || this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
                    /////     this will uploaded by Transcribes or MAs 
                    this.buttonsState.upload = true;
                } {
                    this.buttonsState.sign = false;
                }
                /////   disable to auto upload note after sign

                // this.buttonsState.revert = true;
                if (!this.isCreatingAddendum) {
                    this.config.editable = false;
                }
            } else {
                this.buttonsState.save = false;
                this.buttonsState.submitForReview = false;
                this.buttonsState.revert = false;
                if (!this.note?.coSign_status?.status && (this.note?.provider?._id == this.currentUser._id || this.isAssociatedProvider()) && !this.note.isSigned() && !this.isNew) {
                    this.buttonsState.sign = true;
                }
                else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && !this.note.isSigned() && !this.isNew) {
                    this.buttonsState.sign = true;
                }
                else {
                    this.buttonsState.sign = false;
                }
                this.buttonsState.upload = false;
                this.buttonsState.revert = false;
                if (this.relationship?.assoc_ma_ids?.includes(this.currentUser._id)) {
                    this.config.editable = true;
                    if (!this.compareOriginalNoteState()) {
                        this.buttonsState.save = true;
                        if (this.note.htmlText.length > 0 && this.buttonsState.save === true) {
                            this.pccWidgetService.changeSaveNoteStatus(true);
                            this.pccWidgetService.changeNote(this.note);
                        }
                    } else {
                        this.buttonsState.save = false;
                    }

                }


            }

        } else {

            this.buttonsState.save = false;
            this.buttonsState.submitForReview = false;
            this.buttonsState.revert = false;
            // this.buttonsState.sign = false;
            if (!this.note?.coSign_status?.status && (this.note?.provider?._id == this.currentUser._id || this.isAssociatedProvider()) && !this.note.isSigned()) {
                this.buttonsState.sign = true;
            }
            else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && !this.note.isSigned()) {
                this.buttonsState.sign = true;
            }
            else {
                this.buttonsState.sign = false;
            }
            this.buttonsState.upload = false;
            this.buttonsState.revert = false;

            if (this.isNew) {
                this.config.editable = true;
            }
            else {
                this.config.editable = false;
            }
        }

        if (this.isCreatingAddendum && this.addendum && (this.isLoggedInPCC() || this.canUploadNoteToPCCUsingTwoLegged())) {
            this.buttonsState.uploadAddendum = true;
        } else {
            this.buttonsState.uploadAddendum = false;
        }

        // extra filter applied as pipeline
        
        if (this.note.containsAsterisks()) {
            // // this.buttonsState.sign = false;
            // if (!this.note?.coSign_status?.status && (this.note?.provider?._id == this.currentUser._id || this.isAssociatedProvider()) && !this.note.isSigned()) {
            //     this.buttonsState.sign = true;
            // }
            // else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && !this.note.isSigned()) {
            //     this.buttonsState.sign = true;
            // }
            // // }
            // else {
            //     this.buttonsState.sign = false;
            // }
            this.buttonsState.sign = false;

        }
        //to disbale functionalities for co-sign initiator
        if (this.note?.coSign_status?.status && this.note?.coSign_status?.coSigned_by == this.currentUser._id) {
            this.config.editable = false;
            this.configg.isReadOnly = true
        }
        //to disable edit functionality
        // if(!this.isNew && this.note.isEditLocked(this.currentUser._id)) {
        //     this.configg.isReadOnly = true;
        //     this.config.editable = false;
        // }
        if ((this.addendum_counts?.saved >= 0 && this.note.isSigned() && !this.note.isUploaded()) || ((this.addendum_counts?.saved === 0 && this.note.isUploaded())))
            this.hide_addendum_button = false;

        this.setStatesForAddendumButton();

        this.containsUnresolvedPhrases = this.note.containsUnresolvedPhrases(this.dictionary);
        if(this.containsUnresolvedPhrases) {
            this.buttonsState.save = false;
            this.buttonsState.submitForReview = false;
            this.buttonsState.revert = false;
            this.buttonsState.sign = false;
            this.buttonsState.upload = false;
            this.buttonsState.addendum = false;
            this.buttonsState.uploadAddendum = false;

            this.setStatesForAddendumButton();

            return;
        }
    }
    
    doCheckThrottled = _.throttle(() => {
        // this.doCheckIsTouched();
        this.doCheckExistInTextNote();
        this.doCheckDateOfServiceMatch();
        this.doCheckButtonStates();
    }, 5000);

    ngDoCheck() {
        this.doCheckThrottled();
        // this.doCheckShowOnceTemplateDialog(); 

    }
    
    isCoSigned() {
        if (this.note.coSign_status.coSigned_to == this.currentUser._id) {
            return true
        }
        else {
            return false
        }
    }
    readyToCosigned() {
        if (this.note?.note_status?.status.toLowerCase() == 'published') {
            return 'Ready to Co-sign'
        }
        else {
            return "Co-signed"
        }
    }
    coSigner() {
        if ((this.note?.coSign_status?.coSigned_by == this.currentUser._id)) {
            if (this.note.coSigned_provider?.first_name) {
                return `Co-Signer ${this.note.coSigned_provider.title},${this.note.coSigned_provider.first_name} ${this.note.coSigned_provider.last_name}`
            }
            else {
                return `Co-Signer ${this.coSignedProviderReserve?.title},${this.coSignedProviderReserve?.first_name} ${this.coSignedProviderReserve?.last_name}`
            }
        }
        else {
            return "Co-Signer"
        }
    }

    getSelectedFacility() {
        if (this.isNew) {
            if (this.currentaudio) {
                return this.currentaudio.facility_id_ref;
            } else {
                return this.facilities.find(f => f._id === this.selectedFacilityId);
            }
        } else {
            return this.selectedFile.facility_id_ref;
        }
    }
    getSelectedProvider() {
        return this.currentDoctor;
    }
    getSelectedPatient() {
        return this.selectedPatient;
    }
    getSelectedDateOfService() {
        return this.date_of_service;
    }
    async reloadScreen(routeMapParams?) {
        this.updateCurrentTranscriptionState();
        // const audio_id_param = this._route.snapshot.paramMap.get('audio_id');
        // const id_param = this._route.snapshot.paramMap.get('id');
        if (!routeMapParams) {
            routeMapParams = {
                category: this.category,
            };
            const audio_id_param = this._route.snapshot.paramMap.get('audio_id');
            const id_param = this._route.snapshot.paramMap.get('id');

            if (id_param) {
                routeMapParams.id = id_param;
            }
            if (audio_id_param) {
                routeMapParams.audio_id = audio_id_param;
            }

        }
        await this.router.navigateByUrl(`/dashboard;category=${this.category}`, { skipLocationChange: true })
        // .then(() => {
        //     // const routeMapParams:any = { category: this.category };
        //     // if(id_param) routeMapParams.id = id_param;
        //     // if(audio_id_param) routeMapParams.audio_id = audio_id_param;
        // });
        await this.router.navigate(['/transcription-create', routeMapParams]);
    }

    updateCurrentTranscriptionState() {
        if (!this.selectedFile) {
            return;
        }
        // localStorage.setItem('selectedFile', JSON.stringify(this.selectedFile));

        let transcriptionFiles = this.transcriptionFiles;
        if (transcriptionFiles) {
            const prevTranscription = this.transcriptionFiles.find(t => t._id === this.selectedFile._id);
            if (prevTranscription) {
                transcriptionFiles = this.transcriptionFiles.map(t => {
                    if (t._id === this.selectedFile._id) {
                        return this.selectedFile;
                    }
                    return t;
                });
            } else {
                transcriptionFiles = [this.selectedFile, ...this.transcriptionFiles];
            }
            localStorage.setItem('transcriptionFiles', JSON.stringify(transcriptionFiles));
        }

        this.saveInitialState();
    }

    saveInitialState() {
        this.initialState = JSON.stringify([{ ...this.selectedFile },
        this.htmlContent, this.title]);
    }

    getFilteredPatients() {

        if (!Array.isArray(this.patients)) return [];

        const facility_id = this.note.facility?._id;

        const portalPatients = [];
        const pccPatients = [];

        let resultPatients = [];
        resultPatients = _.cloneDeep(this.patients);

        if (facility_id) {
            resultPatients = resultPatients.filter(p => p.facility_id === facility_id);
        } else {
            resultPatients = [];
        }
        // Do not allow selecting Non PCC Patient in PCC Facility in Note Editor
        if (this.note.facility && this.note.facility.pcc_facId) {
            resultPatients = resultPatients.filter(p => p.pcc_patientId);
        }
        return resultPatients;
    }

    handlePatientSelect($event) {
        this.selectedPatientId = $event._id

    }

    async handleFacilityChange() {
        // this._censusPatientListService.setFacilityForCharge(this.note.facility._id);
        await this.getFacilityPatients(this.note.facility._id);
        this.patientControl.setValue('');

        // this.initDraftNote();
    }
    async handlePatientChange($event) {
        this.note.patient = $event.option.value;
        await this.initCharge();
        // this._censusPatientListService.setPatientForCharge(this.note.patient)
        // this.initDraftNote();
    }
    async handleProviderChange() {
        this.note.facility = null;
        this.note.patient = null;
        this.patients = [];
        this.getFacilities(this.note.provider._id);

        this.initPhrases();
        this.initCoSign();
        // this.initDraftNote();
    }
    initCoSign() {

    }
    initPhrases() {
        this.phrasesService.getNoteBuilderDictionary(this.note.provider._id).subscribe();
        this.phrasesService.getWoundPhrases();
    }
    isPCCNote() {
        return this.note.patient?.pcc_patientId
    }
    createAddendum() {
        this.config.editable = true;
        this.isCreatingAddendum = true;
        // this.addendum.text = ''; //this.note.htmlText;
        if (this.currentUser.enabled_speech_kit) {
            if (this.currentUser.enabled_speech_kit.includes("dragon")) {
                this.dragonTimeoutId = setTimeout(() => {
                    this.loadNuanceBrowserKit()
                }, 5000);
            } else if (this.currentUser.enabled_speech_kit.includes("nVoq")) {
                loadnVoqKit(global.NVOQ_CREDS.USERNAME, global.NVOQ_CREDS);
            }
        }
        this.saveOriginalAddendumState();
    }
    uploadAddendum() {
        if (this.addendum) {
            this.addendum.action = 'upload';
        }
        this.showUploadNoteConfirmationDialog({ type: 'addendum' }, async (confirm) => {
            if (!confirm) {

                this.config.editable = false;
                this.isCreatingAddendum = false;
                this.isCreatingAddendum = false;
                this.note.htmlText = this.originaNoteText;
                // this.saveInitialState();
                return;
            }

            // this.loader.startBackgroundLoader(this.loaderId);

            this.addendumService.uploadAddendumNote({
                addendumId: this.addendum._id,
                includeNoteText: false
            }).subscribe((response: any) => {
                if (response.status === 200) {
                    this.toastr.success('Addendum uploaded successfully', 'Success');

                    this.sidebarVisible = true;

                    const { addendum_counts } = response.data;

                    this.addendum_counts = addendum_counts;

                    this.discardAddendum();
                }


            }, (error) => {
                this.toastr.error('Something went wrong, please try again', 'Failed');

            }, () => {
                // this.loader.stopBackgroundLoader(this.loaderId);
            })

        });
        this.hideNuanceBrowserKit();
    }
    strikeOutNote() {
        swal({
            title: "Strike out Note",
            text: "Are you sure you want to strike out this note?",
            icon: "warning",
            buttons: [
                'Cancel', true
            ],
            dangerMode: false,
        })
            .then(async (res) => {
                if (res) {
                    // TODO: call pcc strikeOutNote api
                    const response: any = await lastValueFrom(this.noteEditorService.strikeOutNote(this.note._id));
                    if (response.status === 200) {
                        this.note.pcc_progressNoteStrikeOut = true;
                        this.toastr.success('Note striken out successfully', 'Success');

                    } else {
                        this.toastr.error(response.error_message, 'Failed');
                    }
                }
            });
    }
    async saveAddendum() {

        if (this.note.hasPCCPatient() || this.canUploadNoteToPCCUsingTwoLegged()) {

            if (this.addendum) {
                this.addendum.action = 'save';
            }

            if (!this.hasLoggedInToPCC() && !this.canUploadNoteToPCCUsingTwoLegged()) {
                this.showUploadNoteConfirmationDialog({ type: 'addendum' }, async (confirm) => {


                    if (!confirm) {

                        this.config.editable = false;
                        this.isCreatingAddendum = false;
                        this.isCreatingAddendum = false;
                        return;
                    }

                    this.doSaveAddendum();

                });
            } else {
                await this.doSaveAddendum();
                this.uploadAddendum();
            }

        } else {
            this.doSaveAddendum();
        }

    }

    doSaveAddendum() {

        this.loader.startBackgroundLoader(this.loaderId);

        const currentDate = new Date();
        let year: any = currentDate.getFullYear();
        let month: any = currentDate.getMonth() + 1;
        let day: any = currentDate.getDate();
        let hour: any = currentDate.getHours();
        let minute: any = currentDate.getMinutes();
        let createdDateObj = {
            year,
            month,
            day,
            hour,
            minute
        }
        if (this.timePicked) {
            createdDateObj.hour = this.note.date_obj.hour;
            createdDateObj.minute = this.note.date_obj.minute;
        }
        this.addendumService.saveAddendumNote({
            addendumId: this.addendum?._id,
            noteId: this.note._id,
            addendumText: this.addendum.text,
            includeNoteText: false,
            createdDateObj
        }).subscribe((response: any) => {
            if (response.status === 200) {
                this.toastr.success('Addendum saved successfully', 'Success');

                this.sidebarVisible = true;

                const { addendum, addendum_counts } = response.data;



                this.addendum_counts = addendum_counts;

                if ((this.note.hasPCCPatient() || this.note.hasMatrixPatient()) && !addendum.uploadedAt) {
                    this.addendum = addendum;
                    this.saveOriginalAddendumState();
                } else {
                    this.discardAddendum();
                }
                if (this.noteSidebarComponent) {
                    this.noteSidebarComponent.refreshWoundsAfterResolvingPhrase();
                }
                if (!this.hide_addendum_button && this.note.isSigned() && !this.note.isUploaded())
                    window.location.reload()
            }

        }, (error) => {
            this.toastr.error('Something went wrong, please try again', 'Failed');

        }, () => {
            this.loader.stopBackgroundLoader(this.loaderId);
        })

    }
    discardAddendum() {

        this.config.editable = false;
        this.isCreatingAddendum = false;
        this.addendum = {
            text: ''
        }
        // this.saveInitialState();
    }

    handleAddendumClick(addendum) {
        // if (this.note.hasPCCPatient() && this.note.canCreateAddendum(this.currentUser._id)) {
        if (this.note.hasPCCPatient() || this.note.hasMatrixPatient()) {
            this.loadAddendum(addendum);
        }
    }

    async handleNoteSelect(note) {
        this.note.title = note.transcribtion_title;
        // this.note.htmlText = this.resetPhrasesFromNoteText(note.transcribtion_text);
        // // this.note.htmlText = note.transcribtion_text;
        // //this.note.htmlText=this.note.htmlText+'<br>_ _ _<br>'

        // setTimeout(() => {

        //     // this.applyAesterikJump();
        //     this.applyWhileHasPhrasesReplacement().then(() => {
        //         this.toastr.success('All phrases are updated')
        //     })
        // }, 100)

        this.note.htmlText = note.transcribtion_text;
        const cleanedNoteText = this.noteEditorService.cleanTagsInNoteHTMLText(this.note.htmlText);
        this.note.htmlText = cleanedNoteText;
        if (!this.note.isSigned() || !this.note.isUploaded()) {
            this.removeSignatureFromNoteText();
            this.refreshPhrases();
        }
    }

    resetPhrasesFromNoteText(noteText) {
        const e = document.createElement('div');
        e.innerHTML = noteText;
        const phraseNodes = Array.from(e.querySelectorAll('.phrase'));
        for (const phraseNode of phraseNodes) {
            const attrId = phraseNode.getAttribute('id');
            let phraseKey;
            if (attrId && attrId.indexOf('-') >= 0 && attrId === "dos-dn") {
                phraseKey = `.${attrId.replace('-', '@')}`;
                const replacementNode = document.createTextNode(phraseKey);
                if (phraseNode) {
                    const parentNode = phraseNode.parentNode;
                    parentNode.insertBefore(replacementNode, phraseNode);
                    parentNode.removeChild(phraseNode);
                }
            }

        }
        return e.innerHTML;
    }
    generatePDFNote() {
        this.noteEditorService.generatePDFNote(this.note._id).subscribe((res: any) => {
            if (res instanceof HttpResponse) {
                var url = window.URL.createObjectURL(res.body);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                let day = (this.note.date_obj.day < 10) ? ('0' + (this.note.date_obj.day).toString()) : (this.note.date_obj.day).toString();
                let month = (this.note.date_obj.month < 10) ? ('0' + (this.note.date_obj.month).toString()) : (this.note.date_obj.month).toString();
                let year = (this.note.date_obj.year);
                let dateobj = month + '-' + day + '-' + year;
                a.download = `${this.note.patient?.first_name[0] + '_' + this.note.patient?.last_name}_${dateobj}_Wound Progress Note.pdf`;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
            }
        });
    }

    async handleTemplateSelect(template) {  // this.noteEditorCpts
        if (Array.isArray(template.assoc_cpt_ids)) {
            this.emCodeEditorCpts = template.assoc_cpt_ids;
            await this.noteEditorService.updateNoteTemplateId(this.note._id, template._id).toPromise();
        }

        this.note.title = template.title;

        const cleanedTemplateText = this.templateService.cleanTemplateText(template.template_text);

        this.note.htmlText = this.applyAsteriskJump(cleanedTemplateText);

        if (template.note_builder_id) {
            await this.applyNoteBuilder();
        }

        setTimeout(() => {
            this.applyWhileHasPhrasesReplacement();

            setTimeout(() => {
                const { instance } = !this.isCreatingAddendum ? this.mainEditor : this.addendumEditor;
                const editor = instance;
                this.applyDblClickToSelectPhrases(editor);
            })
        }, 100)
    }

    applyAsteriskJump(template) {
        // const $ = cheerio.load(template);
        // $('p').each((i, $p) => {
        //     
        //     const content = $($p).html().split(/\*{3}/).map(e => `<span>${e}</span>`).join('<span class="asterisk">***</span>')
        //     $($p).html(content)
        // })
        // const updatedTemplate = $('body').html();
        // return updatedTemplate;
        return template.replace(/\*{3}/g, '<span class="asteriskjump">***</span>')
    }

    applyDblClickToSelectPhrases(editor) {
        const allElements: any = editor.container.$.querySelectorAll(
            '.phrase, .asteriskjump'
        );

        const handleDblClick = (e) => {
            e.preventDefault();
            const sel = editor.getSelection();
            const element = sel.getStartElement();
            sel.selectElement(element);
        }
        for (const element of Array.from(allElements) as any) {
            element.addEventListener('dblclick', handleDblClick);
        }
    }
    loadAddendum(addendum) {

        this.createAddendum();
        this.addendum = addendum;
        this.saveOriginalAddendumState();
    }
    isUserType(user_type) {
        return this.currentUser.user_type == user_type;
    }

    formatNoteAuditOperation(operation) {
        //   const labels = {
        //       create: 'created',
        //       save: 'saved',
        //       delete: 'deleted',
        //       revise: 'revised',
        //       'un-sign': 'un-signed',
        //       sign: 'signed',
        //       upload: 'uploaded',
        //       publish: 'ready for review',
        //       published: 'ready for review',
        //       not_uploaded: 'not uploaded',
        //       internal_qa: 'submitted to internal QA',
        //       external_qa: 'submitted to external QA',
        //       manual_upload: 'manually uploaded',
        //       addendum_save: 'addendum saved',
        //       addendum_upload: 'addendum uploaded',
        //       addendum_uploaded: 'addendum uploaded',
        //       revert_qai: 'returned by internal QA',
        //       revert_qae: 'returned by external QA',
        //       revert_provider: 'returned by provider',
        //   }
        //   return labels[operation] || operation;
        return this.noteAuditoryHistoryService.formatOperation(operation)
    }

    getRecentAuditText() {
        if (!this.recentAudit) return '';
        const operation = this.formatNoteAuditOperation(this.recentAudit.operation);
        // if(operation.includes('addendum')) {
        //     return `An ${operation} against this note on ${moment(this.recentAudit.timestamp).format(global.moment_date_time_format)}`

        // } else {
        //     return `Note has been ${operation} on ${moment(this.recentAudit.timestamp).format(global.moment_date_time_format)}`
        // }

        const titleCaseOperation = `${operation.slice(0, 1).toUpperCase()}${operation.slice(1, operation.length)}`;
        // const dateFormatted = `${moment(this.recentAudit.timestamp).format(global.moment_date_time_format)}`;
        // const dateFormatted = `${moment(new Date(this.recentAudit.timestamp)).format(global.moment_date_time_format)}`;
        const dateFormatted = `${moment(new Date(this.recentAudit.timestamp)).format(global.moment_date_time_format)}`;

        if (this.note.hasPCCPatient() && this.note.isUploadedToPCC() && (operation === 'uploaded' || operation === 'addendum uploaded')) {
            return `${titleCaseOperation} into <img src="../../../assets/icons/pcc-icon.svg" /> PCC on ${dateFormatted}`;
        } else {
            if (this.isNew && operation === 'draft_save') {
                return `${titleCaseOperation} on ${dateFormatted}`;
            }
        }
    }
    diff_hour(pk, la) {

        var diff = (pk.getTime() - la.getTime()) / 1000;
        diff /= 60;
        diff /= 60;
        return diff;//Math.abs(Math.round(diff));
    }
    addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
        // this.events.push(`${type}: ${event.value}`);
        
        this.dateChangeObj = {
            year: event.value.getFullYear(),
            month: event.value.getMonth(),
            day: event.value.getDate(),
            hours: event.value.getHours(),
            minutes: event.value.getMinutes(),
            seconds: event.value.getSeconds()
        }


        const newDOS = new Date(new Date(moment(new Date(event.value)).utc().toString()).toLocaleString('en-US', { timeZone: this.time_zone }))
        let dat = new Date(newDOS)
        const tempDOS = new Date(dat.valueOf())

        let clientOffSet = moment(event.value).utcOffset();
        let facOffSet = moment().tz(this.time_zone).utcOffset();
        // let facOffSet = moment(aa).utcOffset();

        let gValue, lValue;
        if (clientOffSet >= facOffSet) {
            gValue = clientOffSet;
            lValue = facOffSet;
        }
        else {
            gValue = facOffSet;
            lValue = clientOffSet;
        }
        // let diffBetweenOffset = 300 - -300 = 300 + 300 = 600
        
        let diffBetweenOffset = gValue - lValue
        let newDate;
        // newDate = moment(event.value).add(diffBetweenOffset, 'minutes')
        if (diffBetweenOffset >= 0) {
            if (clientOffSet < 0 && facOffSet < 0) {
                newDate = moment(event.value).subtract(diffBetweenOffset, 'minutes')
            }
            else {
                newDate = moment(event.value).add(diffBetweenOffset, 'minutes')
            }
        }
        else {
            newDate = moment(event.value).add(diffBetweenOffset, 'minutes')
        }

        this.note.dates.service = newDate;

        const curentDate = moment(event.value).utc().toDate();
        tempDOS.setHours(curentDate.getHours());
        tempDOS.setMinutes(curentDate.getMinutes());
        tempDOS.setSeconds(curentDate.getSeconds());
        // this.note.dates.service = tempDOS;

        // this.note.dates.service = new Date(new Date(moment(event.value).utc().toString()).toLocaleString('en-US', {timeZone: this.time_zone}))
        // this.note.dates.service = new Date(new Date(event.value).toLocaleString('en-US', {timeZone: this.time_zone}))
        // this.note.dates.service = event.value.toISOString()

        // this.note.dates.service = new Date(moment(event.value.toString()).utc());
        this.note.changeDateObj = this.dateChangeObj;

    }

    onDOSChange(event) {

        // this.attachTimeToDOS();

        // this.autoSaveDraftNote();
    }

    handleNoteTitleChange() {
        // this.autoSaveDraftNote();
    }
    attachTimeToDOS() {
        // if (browser.tz != note.tz){
        //     diff = 13hr
        //     dos = add diff in saving dos
        // }
        // this.note.dates.service =  this.dateOfService;
        if (this.note.date_obj) {
            const currentDate = new Date();
            // get hours in 24 format
            this.note.date_obj.hour = currentDate.getHours();
            this.note.date_obj.minute = currentDate.getMinutes();

        }
        if (this.note.hasAudio()) {
            let diff = this.getDOSDiff();
            if (diff !== 0) {


                const newDate = moment(this.note.audio.filterServiceDate).subtract('days', diff).toDate();

                this.note.dates.service = newDate;
                // this.note.dates.service = this.commonService.attachTimeToDate(this.note.dates.service, this.note.audio.filterServiceDate);
            } else {
                this.note.dates.service = this.note.audio.filterServiceDate;
            }
        }
        else {
            if (this.censusId || this.note?.rounding_sheet_id) {
                this.note.dates.service = this.dateOfService;
            }
            else {
                this.note.dates.service = this.commonService.attachTimeToDate(this.dateOfService);
            }
        }
    }

    getDOSDiff() {

        const audioDOS = new Date(new Date(this.note.audio.filterServiceDate))
        // const audioDOS = new Date(new Date(this.note.audio.filterServiceDate))

        return moment(audioDOS).endOf('day').diff(moment(this.dateOfService).endOf('day'), 'days');
    }
    getServiceDate() {

        if (this.note.hasAudio()) {
            if (this.note.audio.date_obj) {

            } else {

            }
            return moment(new Date(this.note.audio.filterServiceDate)).format('MMM DD, YYYY h:mm a');
        } else if (this.note.dates) {
            return moment(new Date(this.note.dates.service)).format('MMM DD, YYYY h:mm a');
        }
        return '';

    }
    getNoteDOS() {
        if (!this.note.facility) return;

        if (this.note.facility.pcc_timeZone) {
            this.time_zone = this.note.facility.pcc_timeZone
        }
        else if (this.note.facility.timeZone) {
            this.time_zone = this.note.facility.timeZone
        }
        this.zone = moment.tz(this.time_zone).zoneAbbr()
        this.serviceDate = new Date(new Date(this.note.dates.service).toLocaleString('en-US', { timeZone: this.time_zone }))

        return this.serviceDate;
        // return moment(new Date(this.note.dates.service)).format('MMM DD, YYYY h:mm a');
    }
    getAudioDOS() {
        // return moment(new Date(this.note.audio.filterServiceDate)).format('MMM DD, YYYY h:mm a');
        return moment(new Date(this.note.audio.filterServiceDate)).format('MMM DD, YYYY h:mm a');
    }
    getAudioDOC() {
        // return moment(new Date(this.note.audio.filterUploadingDate)).format('MMM DD, YYYY h:mm a');
        return moment(new Date(this.note.audio.filterUploadingDate)).format('MMM DD, YYYY h:mm a');
    }
    getEndTimeForTimer() {
        // let time = moment(new Date(this.note.audio.filterUploadingDate)).add(1, 'days');
        let time = moment(new Date(this.note.audio.filterUploadingDate)).add(1, 'days');
        return time.toString()

    }
    getStratTimeForTimer() {
        let time = moment(new Date(this.note.audio.filterUploadingDate));
        // let time = moment(new Date(this.note.audio.filterUploadingDate));
        return time.toString()
    }

    triggerFunction() {
        this.timeUp = true;
    }
    // applyDateTimezone(date) {
    //         return this.noteEditorService.applyDateTimezone(this.note, date);
    // }
    autoSaveDraftNote() {

        this.clearAutoSaveDraftNote();

        if (this.isActiveAutoSaveDraftNoteTimeout === null && this.note && this.note.is_draft !== 'false') {
            // this.drafteNoteState = "Note Changes Detected & Saved in 5 sec..."  + moment().format('hh:mm:ss a');
            this.isActiveAutoSaveDraftNoteTimeout = setTimeout(() => {
                this.isActiveAutoSaveDraftNoteTimeout = false;
                //this.drafteNoteState = "Saving as draft note...";
                this.saveDraftNote();
            }, 10000);
        }

    }


    async doAutosaveNote(type?) {
        if (!this.timePicked) {
            if (this.censusId && this.note.date_obj) {
                const currentDate = new Date();
                this.note.date_obj.hour = currentDate.getHours();
                this.note.date_obj.minute = currentDate.getMinutes();
            }
            if (this.note.date_obj && (!((this.note.date_obj?.hour) || (this.note.date_obj?.minute)))) {
                const currentDate = new Date();
                this.note.date_obj.hour = currentDate.getHours();
                this.note.date_obj.minute = currentDate.getMinutes();
            }
        }
        if (type === 'note_only') {
            this.clearAutoSaveNote();

            if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT) {
                this.note.note_status.status = "in_progress";
            }
            if (!this.note.htmlText) return;
            if (this.note.title == "") {
                this.note.title = "NO TITLE";
            }
            const response: any = await this.noteEditorService.saveNote(this.note, this.censusIdForPatientSeen).toPromise();
            if (response.status === 200) {
                if (this.note.title == "" || this.note.title == "NO TITLE") {
                    this.note.title = "";
                }
                this.drafteNoteState = "Note Auto Saved at: " + moment(new Date()).format('HH:mm:ss').toString();
                this.saveOriginalNoteState(true);

            }
        } else if (type == 'both') {
            let observables: Observable<any>[] = [];
            this.clearAutoSaveNote();

            if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT) {
                this.note.note_status.status = "in_progress";
            }
            if (!this.note.htmlText) return;
            if (this.note.title == "") {
                this.note.title = "NO TITLE";
            }
            observables.push(this.noteEditorService.saveNote(this.note, this.censusIdForPatientSeen))
            // const response: any = await .toPromise();
            // if (response.status === 200) {
            //     if(this.note.title == "" || this.note.title == "NO TITLE"){
            //         this.note.title = "";
            //     }
            //     this.drafteNoteState = "Note Auto Saved at: " + moment(new Date()).format('HH:mm:ss').toString();

            //     this.saveOriginalNoteState(true);

            // }


            //// compare with previous charges
            if (this.chargeCaptureChargeModel.cpt_id.length > 0 || this.chargeCaptureChargeModel.icd_id.length > 0) {
                if (this.compareICDandCPTs()) {

                    if ((this.chargeCaptureChargeModel) && (this.chargeCaptureChargeModel.status !== 'submit' || !this.chargeCaptureChargeModel.status)) {
                        if ((this.chargeCaptureChargeModel.charge_id) && this.chargeCaptureChargeModel.charge_id != '') {
                            this.chargeCaptureChargeModel['note_id'] = this.note._id;
                            this.OldchargeCaptureChargeModel = this.chargeCaptureChargeModel;
                            observables.push(from(this.AutosaveDraftCharge("auto")))
                            // this.drafteNoteState = "Note Auto Saved at: " + moment(new Date()).format('HH:mm:ss').toString();
                        }
                    }
                }
            }
            forkJoin(observables).subscribe(([
                noteSaveResult,
                chargeSaveResult
            ]) => {
                let currentDate = moment(new Date()).format('HH:mm:ss').toString()
                let message = '';
                if (noteSaveResult?.status === 200 && chargeSaveResult?.status === 200) {
                    if (this.note.title == "" || this.note.title == "NO TITLE") {
                        this.note.title = "";
                    }
                    this.saveOriginalNoteState(true);
                    message = 'Note and Charge Auto Saved at: ' + currentDate;
                } else if (noteSaveResult?.status === 200) {

                    if (this.note.title == "" || this.note.title == "NO TITLE") {
                        this.note.title = "";
                    }
                    this.saveOriginalNoteState(true);
                    message = 'Note Auto Saved at: ' + currentDate;
                } else if (chargeSaveResult?.status === 200) {
                    // charge only
                    message = 'Charge Auto Saved at: ' + currentDate;
                }
                if (message) {
                    this.drafteNoteState = message;
                }
            });
        } else {
            if (this.chargeCaptureChargeModel.cpt_id.length > 0 || this.chargeCaptureChargeModel.icd_id.length > 0) {
                if (this.compareICDandCPTs()) {
                    if ((this.chargeCaptureChargeModel) && (this.chargeCaptureChargeModel.status !== 'submit' || !this.chargeCaptureChargeModel.status)) {
                        this.chargeCaptureChargeModel['note_id'] = this.note._id;
                        this.OldchargeCaptureChargeModel = this.chargeCaptureChargeModel;
                        await this.AutosaveDraftCharge("auto");
                    }
                }
            }
        }
    }

    compareArrays(arr1, arr2) {


        if (arr1.length != arr2.length) {
            return false;
        }
        else {
            let result = false;

            for (let i = 0; i < arr1.length; i++) {

                if (arr1[i]?.toString() != arr2[i]?.toString()) {
                    return false;
                }
                else {
                    result = true;
                }

            }
            return result;

        }

    }

    async compareICDandCPTs() {
        if (this.chargeCaptureChargeModel) {
            let new_cpts = this.chargeCaptureChargeModel.cpt_id;
            let new_icds = this.chargeCaptureChargeModel.icd_id;
            let old_cpts, old_icds;
            if (this.OldchargeCaptureChargeModel) {
                old_cpts = this.OldchargeCaptureChargeModel.cpt_id;
                old_icds = this.OldchargeCaptureChargeModel.icd_id;

            } else {
                return true;
            }

            return !(this.compareArrays(new_cpts, old_cpts) && this.compareArrays(new_icds, old_icds))
        }
    }

    async doAutosaveCharge(type?) {
        if (type == 'auto') {
            this.clearAutoSaveNote();
        }
        if (!this.timePicked) {
            if (this.censusId && this.note.date_obj) {
                const currentDate = new Date();
                this.note.date_obj.hour = currentDate.getHours();
                this.note.date_obj.minute = currentDate.getMinutes();
            }
            if (this.note.date_obj && (!((this.note.date_obj?.hour) || (this.note.date_obj?.minute)))) {
                const currentDate = new Date();
                this.note.date_obj.hour = currentDate.getHours();
                this.note.date_obj.minute = currentDate.getMinutes();
            }
        }
        if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT) {
            this.note.note_status.status = "in_progress";
        }
        this.note.htmlText = this.note.htmlText.replace(/\n/g, '');
        if (!this.note.htmlText) return;
        const response: any = await this.noteEditorService.saveNote(this.note, this.censusIdForPatientSeen).toPromise();
        if (response.status === 200) {
            this.drafteNoteState = "Note Auto Saved at: " + moment(new Date()).format('HH:mm:ss').toString();
            this.saveOriginalNoteState(true);

        }
        if ((this.chargeCaptureChargeModel) && (this.chargeCaptureChargeModel.status !== 'submit' || !this.chargeCaptureChargeModel.status)) {
            this.chargeCaptureChargeModel['note_id'] = this.note._id;
            await this.AutosaveDraftCharge("auto");
        }
    }

    autoSavecharge(type?) {
        if (!(this.currentUser.enabled_speech_kit && this.currentUser.enabled_speech_kit.includes("dragon"))) {
            this.clearAutoSaveNotecharge();        
            if (this.isActiveAutoSaveNotechargeTimeout === null && this.note) {                
                this.isActiveAutoSaveNotechargeTimeout = setTimeout(() => {
                    if (type == "charge") {
                        this.doAutosaveNote('charges');

                    }
                }, 2000);
            }
        }
    }

    autoSaveNote() {
        if (!(this.currentUser.enabled_speech_kit && this.currentUser.enabled_speech_kit.includes("dragon"))) {

            this.clearAutoSaveNote();

            const phraseLoader = this.loader.getLoader('phrase_loader');
            if (this.isActiveAutoSaveNoteTimeout === null && this.note) {
                // this.drafteNoteState = "Note Changes Detected & Saved in 5 sec..."  + moment().format('hh:mm:ss a');
            
                this.isActiveAutoSaveNoteTimeout = setTimeout(() => {
                    if (!this.checkEnabledState()) {

                        // let conditionofPhrasde = this.loader.getLoader("phrase_loader");

                        // //////        condition to see if pharase is being resolved
                        // if (conditionofPhrasde.tasks) {
                        this.drafteNoteState = "Auto Saving Note and Charges...";
                        if (this.currentUser.user_type === this.global.USER_TYPE.SNF_WC_NURSE) {
                            this.doAutosaveNote('note_only');
                        } else {
                            this.doAutosaveNote('both');
                        }
                        // }
                    }
                }, 10000);

            }
        }

    }
    // Add this function outside of handleEditorChange
    autoUpdateNoteEditLock() {

        // this.updateNoteEditLock()

        // this.clearNoteEditLock();
        // if(!this.isNoteEditLockedForMe()) {
        //     this.updateNoteEditLock()

        //     if (this.note && this.note._id && !this.note.isPublished() && !this.note.isSigned() && !this.note.isUploaded()) {
        //         this.editLockTime = setTimeout(() => {
        //             this.autoUpdateNoteEditLock()
        //         }, 1000 * this.editLockTimeInterval);

        //     }
        // }

    }
    clearNoteEditLock() {

        if (this.editLockTime !== null) {
            clearTimeout(this.editLockTime);
            this.isActiveTimeout = null;        
        }
    }
    clearAutoSaveNote() {

        if (this.isActiveAutoSaveNoteTimeout !== null) {
            clearTimeout(this.isActiveAutoSaveNoteTimeout);
            this.isActiveAutoSaveNoteTimeout = null;
        }
    }
    clearAutoSaveNotecharge() {

        if (this.isActiveAutoSaveNotechargeTimeout !== null) {
            clearTimeout(this.isActiveAutoSaveNotechargeTimeout);
            this.isActiveAutoSaveNotechargeTimeout = null;            
        }
    }
    clearAutoSaveDraftNote() {

        if (this.isActiveAutoSaveDraftNoteTimeout !== null) {
            clearTimeout(this.isActiveAutoSaveDraftNoteTimeout);
            this.isActiveAutoSaveDraftNoteTimeout = null;        
        }
    }
    timeCalculation() {
        // this.lastStrockedTime = new Date()
        this.totalTime = this.totalTime + this.resumeTime;
        this.currentStrockTime = new Date()
        // if (this.isNew && !this.startTime && this.note.htmlText) {
        if (this.isNew && !this.startTime) {
            this.lastStrockedTime = this.currentStrockTime;
            //start time for a new note on key press
            this.startTime = new Date();
        }
        if (!this.isNew && !this.startTime) {
            this.startTime = new Date();
            this.lastStrockedTime = this.currentStrockTime;
            // this.totalTime = 
        }
        if (this.startTime && this.lastStrockedTime) {
            setInterval(() => {
                let timeElpased = Math.abs(this.lastStrockedTime.getTime() - (new Date()).getTime()) / 1000;
                if (Number(timeElpased) > 10) {
                    // if(this.timeElapsedFlag){

                    // this.totalTime = this.startTime.setSeconds(this.startTime.getSeconds() + 10)
                    this.timeElapsedFlag = true;
                    // }
                }
                else {
                    this.resumeTime = timeElpased
                    // if(this.timeElapsedFlag){
                    // }
                    // else{
                    //     this.totalTime = timeElpased;
                    // } 

                }
                // }
            }, 1000)
            this.lastStrockedTime = new Date();
        }
    }

    // Add this function outside of handleEditorChange


    handleEditorChangeDebounced = debounce(() => {
        this.showLowMemoryBanner = this.note.htmlText.length > this.MAX_CKEDITOR_LENGTH;
        this.updateTagFromNote();
        this.autoSaveNote();

    }, 1000);

    handleEditorChange(e) {      
        this.handleEditorChangeDebounced();
    }

    findNoteEditorPatientTags(noteText) {      // find all patient tags used in note editor for respective note (note html text passed as parameter)
        let currentIndex = -1;
        let htmlTextIndices = [];
        let noteTags = [];

        // find indices of all tags and save them in array for new html text
        while ((currentIndex = noteText.indexOf('data-tag-id', currentIndex + 1)) !== -1) {
            htmlTextIndices.push(currentIndex);
        }

        for (let index = 0; index < htmlTextIndices.length; index++) {
            let startingIndex = htmlTextIndices[index] + 13;         // excluding data-tag-id="  
            let id = noteText.substring(startingIndex, startingIndex + 24);
            let findTag = noteTags.includes(id);         // contains ids of all the tags, whose resolved phrase text is removed
            if (!findTag)
                noteTags.push(id);
        }
        return noteTags;
    }

    async updateTagFromNote() {
        try {
            let selectedTags = [];
            let newNoteTags = [];
            let tags = this.note.patient.tag_ids_ref;
            let patientId = this.note.patient._id;
            let oldNoteTags = [];

            setTimeout(async () => {
                let oldHtmlText;

                if (this.isPatientTags) {
                    oldHtmlText = this.newHtmlText;
                    this.newHtmlText = this.note.htmlText;
                }
                else {
                    oldHtmlText = this.originalNoteState.htmlText;
                    this.newHtmlText = this.note.htmlText;
                    // if (tags.length > 0)
                    this.noteEditorPatientTags = this.findNoteEditorPatientTags(this.originalNoteState.htmlText)   // see if respective tags are already used in note editor
                    for (let index = 0; index < tags.length; index++) {                            // see if respective tags are not used already in note editor
                        let findTag = this.noteEditorPatientTags.includes(tags[index]);
                        if (!findTag) {
                            let object = { id: tags[index], isAlreadyExist: false };
                            this.alreadyAssociatedTags[index] = object
                        }
                        else {
                            let object = { id: tags[index], isAlreadyExist: true };
                            this.alreadyAssociatedTags[index] = object
                        }
                    }

                }

                if (this.newHtmlText.length <= oldHtmlText.length) {    // if text is removed

                    newNoteTags = this.findNoteEditorPatientTags(this.newHtmlText);
                    oldNoteTags = this.findNoteEditorPatientTags(oldHtmlText);

                    let removedTags = [];
                    // Find removed tags
                    oldNoteTags.forEach(element => {
                        if (!newNoteTags.includes(element)) {
                            removedTags.push(element);
                        }
                    });

                    if (removedTags.length > 0) {
                        tags.map(tagId => {
                            let findTag = selectedTags.includes(tagId);
                            let removedTag = removedTags.includes(tagId);
                            if (!findTag && !removedTag)
                                selectedTags.push(tagId)
                        })

                        let res: any = await lastValueFrom(this.editPatientService.editPatient({ _id: patientId }, { $set: { tag_ids_ref: selectedTags } }));
                        if (res.status == 200) {
                            this.toastr.success("Successfully Tag(s) Removed From Patient", "Success");
                            this.alreadyAssociatedTags = [];
                            this.note.patient.tag_ids_ref = selectedTags;
                            this.noteEditorPatientTags = this.findNoteEditorPatientTags(this.note.htmlText)   // see if respective tags are already used in note editor
                        } else
                            this.toastr.warning(res.message);
                    }
                }
                this.isPatientTags = true;
            }, 0)

        }
        catch (error) {
            console.log('Error in updateTagFromNote() : ' + error?.message);
        }
    }

    replacePhraseWithOriginalKeys(noteText) {
        let phraseDone = {};
        // ignore wound phrases
        const woundPhraseKeys = [
            'woundassessment_',
            'woundplan_',
            'woundhistory_',
            'kentimaging_',
            'nursewoundassessment_',
            'woundassessmentshort_',

            // procedure types phrases
            'debridement_',
            'chemical_cautery_',
            'biopsy_',
            'compression_',
            'negative_pressure_',
            'skin_substitute_',
            'ultramist_',
            'testing_',
            'hussnain_',
            'time_',
            'time_square_',
            'i+d_',
            'nail_plate_avulsion_',
            'time_',
            'ultramist_',
            'bacterial_imaging_',
            'callus_pairing_'
        ];
        const tmpElement = document.createElement('div');
        tmpElement.innerHTML = noteText;
        tmpElement.querySelectorAll('span[data-phrase]').forEach((phraseElement) => {
            const phraseKey = phraseElement.getAttribute('data-phrase');
            const ignorePhrase = woundPhraseKeys.find((key) => phraseKey.toLowerCase().startsWith(key));
            if (ignorePhrase) {
                return;
            }
            if (phraseDone[phraseKey]) {
                phraseElement.remove();
            } else {
                phraseDone[phraseKey] = true;
                phraseElement.outerHTML = `.${phraseKey}`;
            }
        });
        return tmpElement.innerHTML;
    }

    refreshPhrases() {
        const { instance } = !this.isCreatingAddendum ? this.mainEditor : this.addendumEditor;
        return new Promise<void>((resolve, reject) => {

            // let noteText = this.note.htmlText;
            let noteText = this.note.htmlText;
            const noteTextWithPhraseKeys = this.replacePhraseWithOriginalKeys(noteText);

            this.note.htmlText = noteTextWithPhraseKeys;
            instance.setData(noteTextWithPhraseKeys);
            setTimeout(() => {
                this.toastr.info('Refreshing all phrases');
                this.applyWhileHasPhrasesReplacement().then(() => {
                    this.toastr.success('All phrases are refreshed');
                    resolve(null);
                }).catch(reject)
            }, 100)
        })
    }
    async applyWhileHasPhrasesReplacement(phraseText = null) {
        let phraseInfo;

        const { instance } = !this.isCreatingAddendum ? this.mainEditor : this.addendumEditor;

        // let noteText = this.note.htmlText;
        let noteText = phraseText ? phraseText : instance.getData();

        // ignore pcc phrase for non pcc patients
        let ignorePharses = [];
        if (!this.note.patient.pcc_patientId) {
            ignorePharses = systemDefinedPhrases.filter(phrase => phrase.key.includes('@pcc')).map(phrase => phrase.key)
        }

        while ((phraseInfo = this.phrasesService.hasPhrase(noteText, ignorePharses))) {
            let { match, key, value } = phraseInfo;
            const keyWithoutMarker = key.substring(this.phrasesService.marker.length);
            if (value === undefined) {
                this.loader.startBackgroundLoader(this.loaderId);
                value = await this.resolveSystemDefinedPhraseValue(
                    key, this.note, { phrase: { key: keyWithoutMarker } }
                );
                this.loader.stopBackgroundLoader(this.loaderId);
            }

            const phraseElementClass = ['phrase'];
            const isPCCPhrase = key.indexOf('@pcc') >= 0;
            if (isPCCPhrase) phraseElementClass.push('pcc-phrase');
            const isPrevNotePhrase = key.indexOf('prevnote:') >= 0;
            if (isPrevNotePhrase) phraseElementClass.push('prevnote-phrase');

            if (typeof value === 'string') {
                noteText = noteText.replace(this.phrasesService.getRegex(keyWithoutMarker, 'g'), `<span data-phrase="${keyWithoutMarker}" class="${phraseElementClass.join(' ')}" id="${keyWithoutMarker.replace('@', '-')}">${value}</span>`);
            } else if (typeof value === 'object' && value !== null && Object.prototype.hasOwnProperty.call(value, 'error')) {
                const error = (value as any).error;
                const defaultText = `<span data-phrase="${keyWithoutMarker}" class="${[...phraseElementClass, 'phrase-error'].join(' ')}">${error}</span> <span class="asteriskjump">***</span>`;
                noteText = noteText.replace(this.phrasesService.getRegex(keyWithoutMarker, 'g'), defaultText);
            }
            if(!phraseText){
                instance.setData(noteText);
            }
        }
        if(phraseText) return noteText;
        // this.note.htmlText = noteText;
    }

    isPatientTagPhraseUsedInNoteEditor(phraseId) {
        let isPhraseResolved = false;

        for (let tag of this.alreadyAssociatedTags) {
            if (tag.id == phraseId) {
                if (tag.isAlreadyExist == false) {
                    tag.isAlreadyExist = true;
                    return false;
                }
                else
                    this.toastr.info("This Tag is already associated with this Patient & already used in this note", "Info");
                isPhraseResolved = true;
                return true;
            }
        }

        if (!isPhraseResolved) {
            let findTagInNoteEditor = this.noteEditorPatientTags.includes(phraseId);
            if (findTagInNoteEditor) {
                this.toastr.info("This Tag is already used in this note", "Info");
                return true;
            }
            else
                return false;    // if tag is neither used in note editor, nor associated before
        }
    }

    async getTagPhraseValue(key, note, extra) {
        try {
            if (!note.patient.tag_ids_ref || note.patient.tag_ids_ref.length < 2) {

                let selectedTags = [], findTag, toastrId;
                if (note.patient.tag_ids_ref) {
                    selectedTags = note.patient.tag_ids_ref;
                    findTag = selectedTags.includes(extra.phrase._id);
                }

                let resolveResult;

                let isTagPhraseUsed = this.isPatientTagPhraseUsedInNoteEditor(extra.phrase._id);

                if (!findTag && !isTagPhraseUsed) {
                    resolveResult = await this.phrasesService.resolveSystemDefinedPhraseValue(
                        key,
                        note,
                        extra
                    );
                    toastrId = this.toastr.info("Adding Tag To Patient", "Please Wait...", { disableTimeOut: true });
                    selectedTags.push(extra.phrase._id);
                    let res: any = await lastValueFrom(this.editPatientService.editPatient({ _id: note.patient._id }, { $set: { tag_ids_ref: selectedTags } }));
                    if (res.status == 200) {
                        this.toastr.clear(toastrId.toastId);
                        this.toastr.success("Successfully Associated Tag with Patient", "Success");
                        for (let index = 0; index < selectedTags.length; index++) {
                            let object = { id: selectedTags[index], isAlreadyExist: true };
                            this.alreadyAssociatedTags[index] = object
                        }
                        note.patient.tag_ids_ref = selectedTags;
                    } else {
                        this.toastr.clear(toastrId.toastId);
                        this.toastr.warning(res.message);
                    }
                }
                return resolveResult;
            }
            else {
                let findTag = note.patient.tag_ids_ref.includes(extra.phrase._id);
                if (findTag)       // if tag is already assigned, and it is used in note editor again then it is reolved only one time
                {
                    let resolveResult;

                    let isTagPhraseUsed = this.isPatientTagPhraseUsedInNoteEditor(extra.phrase._id);
                    if (!isTagPhraseUsed) {
                        resolveResult = await this.phrasesService.resolveSystemDefinedPhraseValue(
                            key,
                            note,
                            extra
                        );
                        return resolveResult;
                    }
                }
                else
                    this.toastr.error("2 Tags are already associated with this Patient")
            }
        }
        catch (error) {
            console.log('Error in getTagPhraseValue() : ' + error?.message);
            return error;
        }
    }

    async resolveSystemDefinedPhraseValue(key, note, extra?) {
        if (extra) {
            const { phrase } = extra;
            if (phrase.key.match(/^kentimaging_(\d+|all)\@dn$/m)) {
                this.noteEditorCpts = phrase.extraData.cpt_codes;
            } else if (phrase.key === 'telemedconsent@dn') {
                this.telemedPhrase = !this.telemedPhrase;
            } else if ((phrase.key.match(/^woundprocedure_\d+\@dn$/m) || phrase.key === 'woundprocedure_all@dn' || phrase.procedure_type)) {
                let options = extra.phrase.procedure_type_id ? {
                    procedure_type_id: extra.phrase.procedure_type_id,
                    dataset_id: extra.phrase.dataset_id,
                    patient_id: note.patient._id
                } : phrase.key === 'woundprocedure_all@dn' ? { patient_id: note.patient._id, date_obj: note.date_obj } : { wound_id: phrase._id };
                const response = phrase.key.includes("woundprocedure") ? await lastValueFrom(this.phrasesService.resolveWoundProcedurePhrase(options)) : await lastValueFrom(this.phrasesService.resolveProcedurePhrase(options));
                if (response['status'] == 200) {
                    if(response['data']['array'] && Array.isArray(response['data']['array'])){
                        if (response['data']['array'].length === 0) {
                            return 'No procedures performed on this DOS.';
                        }
                        if(this.companyPermissions && this.companyPermissions.should_allow_auto_procedure_coding){
                            this.noteEditorCpts = [];
                            response['data']['array'].forEach(data => {
                                data.cpt_items.forEach((cpt) => {
                                    cpt['key'] = extra.phrase.procedure_type_id ? phrase.key : `woundprocedure-${data['procedureNoteData']._id}`;
                                    cpt['wound_id'] = data.wound._id;
                                    this.noteEditorCpts.push(cpt);
                                });
                            });
                        }
                        extra['resolvedProcedureNote'] = response['data']['array'];
                    } else {
                        response['data'].cpt_items.forEach((cpt) => {
                            cpt['key'] = extra.phrase.procedure_type_id ? phrase.key : response['data']['procedureNoteData'].procedure_category_id;
                            cpt['wound_id'] = extra.phrase.procedure_type_id ? phrase.wound_id : phrase._id
                        });
                        if(this.companyPermissions && this.companyPermissions.should_allow_auto_procedure_coding){
                            this.noteEditorCpts = response['data'].cpt_items;
                        }
                        extra['resolvedProcedureNote'] = response['data'];
                    }
                } else {
                    return {
                        error: `${response['message']}`
                    }
                }
            }
        }
        try {
            if (key.startsWith('.tag_')) {
                return await this.getTagPhraseValue(key, note, extra);
            }
            else {
                if(!extra) extra = {}
                if(this.companyPermissions.should_show_wound_location){
                    extra['should_show_wound_location'] = true;
                }else extra['should_show_wound_location'] = false
                extra['should_show_procedure_note'] = this.companyPermissions.should_show_procedure_note;
                let resolveResult = await this.phrasesService.resolveSystemDefinedPhraseValue(
                    key,
                    note,
                    extra
                );
                if (key.includes('.woundassessment')) {
                    setTimeout(() => {
                        this.handlecheckallWoundAssessmentPhrase(null);
                    }, 2000)
                } else if ((key.match(/^.woundprocedure_\d+\@dn$/m) || key === ".woundprocedure_all@dn" || (extra.phrase && extra.phrase.procedure_type))) {
                    await this.applyWhileHasPhrasesReplacement(resolveResult).then((result) => {
                        resolveResult = result;
                    })
                }
                if (typeof (resolveResult) === "object" && resolveResult.refreshWounds) {
                    if (this.noteSidebarComponent) {
                        this.noteSidebarComponent.refreshWoundsAfterResolvingPhrase();
                    }
                    return resolveResult.phraseResolve;
                } else {
                    return resolveResult;
                }
            }
        } catch (error) {
            console.log('Error in resolveSystemDefinedPhraseValue() : ' + error?.message);
            return '';
        }
    }

    onReadyCkeditor(editorType: string, event: any) {
        if (editorType === 'main') {
            this.mainEditorInstance = event.editor;
        } else if (editorType === 'addendum') {
            this.addendumEditorInstance = event.editor;
        }
        
        const { editor } = event;
        this.ckeditorService.ckeditorRemoveTitle(editor);
        this.ckeditorService.setEditorInstance(editor);

        editor.on('paste', (e) => {
            const { data, editor } = e;
            if (data.type === 'text') {
                const modifiedhtml = e.data.dataValue
                    .split('<br />')
                    .join('<p><br /></p>');
                // @ts-ignore
                var filter = new CKEDITOR.filter('p b br'),
                    // Parse the HTML string to a pseudo-DOM structure.
                    // @ts-ignore
                    fragment = CKEDITOR.htmlParser.fragment.fromHtml(modifiedhtml),
                    // @ts-ignore
                    writer = new CKEDITOR.htmlParser.basicWriter();

                filter.applyTo(fragment);
                fragment.writeHtml(writer);
                const html = writer.getHtml();
                e.data.dataValue = html;
            }
        });
        this.disableEditorForNurse();
        // const handleClickOnAnchor = (e) => {
        //     if(typeof e.target.href != 'undefined' && e.ctrlKey == true) {
        //         e.preventDefault();
        //         window.open(e.target.href, 'new' + e.screenX);
        //     }
        // }

        // editor.on('change', (e) => {

        //     editor.container.querySelectorAll('a').forEach(a => {
        //         a.addEventListener('click', handleClickOnAnchor)
        //     })
        // })
    }

    disableEditorForNurse() {
        if (this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
            this.config.editable = false;
        }
    }

    showNextPrevButtons() {
        let next, prev;

        if (this.loader.hasRunningTask(false, this.loaderId)) return false;

        if (this.note && this.note.hasAudio() && this.dashboardData.audios.length) {
            next = this.dashboardData.audio.next;
            prev = this.dashboardData.audio.prev;
        } else {
            next = this.dashboardData.note.next;
            prev = this.dashboardData.note.prev;
        }

        if (this.isNew && !next && !prev) {
            return false;
        }
        return true;
    }

    bannerOnTop() {
        if (this.showLowMemoryBanner) {
            return 'banner-visible';
        } 
        return 'banner-hidden';
    }

    noteStateCssClasses() {
        if (this.note && this.note.isUploaded()) {
            return 'note-uploaded'
        } else if (this.note && this.note.isSigned()) {
            return 'note-signed';
        } else if (this.note && this.note._id) {
            return 'note-unsigned';
        } else {
            return 'note-new';
        }
    }


    isNoteEditLocked() {
        if (!this.note.edit_lock) return false;
        const lockedOn = moment(new Date(this.note.edit_lock.lockedOn));
        const currentTime = moment();
        const diff = currentTime.diff(lockedOn, 'seconds');
        return diff <= this.editLockTimeInterval;
    }
    isNoteEditLockedForMe() {
        return this.isNoteEditLocked() && this.note.edit_lock.userId !== this.currentUser._id
    }

    updateNoteEditLock() {
        this.noteEditorService.updateNoteEditLock(this.note._id).subscribe((response: any) => {
            if (response.status === 200) {
                this.note.edit_lock = response.data;
            }
        })
    }

    async initDynamicPhrasesToCKEditor(query) {
        if (!this.note.patient) return;
        if (!query.match(/^(wound|kent|nurse|tag)/m)) return; // only wound and kent phrases
        if (this.currentUser.company_type !== 'Wound' && this.currentUser.company_type !== global.COMPANY_TYPE.SNF) return;

        return await new Promise(async (resolve, reject) => {
            const { status, data, message } = await lastValueFrom(this.phrasesService.getDynamicPhrases(query.toLowerCase(), { patient_id: this.note.patient._id, company_id: this.currentUser.company_id })) as any;
            if (status == 200) {
                const { array, ...extraData } = data;
                let result = null;
                if(this.companyPermissions.should_show_procedure_note){
                    result = array.map(wound => ({ ...wound, id: wound.key, value: undefined, type: 'system-defined' }));
                }else{
                    result = array.filter((wound) => !wound.key.match(/^woundprocedure_\d+\@dn$/m)).map(wound => ({ ...wound, id: wound.key, value: undefined, type: 'system-defined' }));
                }
                const phrasesBuckets = result.reduce((buckets, phrase) => {
                    const [str] = phrase.key.match(/^[a-z]+_/);
                    if (!Array.isArray(buckets[str])) {
                        buckets[str] = [];
                    }
                    buckets[str].push(phrase);
                    return buckets;
                }, {});

                for (const key in phrasesBuckets) {
                    if (Object.hasOwnProperty.call(phrasesBuckets, key)) {
                        const bucket = phrasesBuckets[key];
                        const _allIndex = bucket.findIndex(phrase => phrase.key.match(/_all/));
                        let _allPhrase;
                        if (_allIndex !== -1) {
                            _allPhrase = bucket[_allIndex];
                            bucket.splice(_allIndex, 1);
                        }
                        bucket.sort((a, b) => {
                            return b.key.localeCompare(a.key, undefined, { numeric: true, sensitivity: 'base' })
                        });
                        if (_allIndex !== -1) {
                            bucket.push(_allPhrase);
                        }
                    }
                }
                const phrases = Object.keys(phrasesBuckets).reduce((arr, str) => {
                    return [...arr, ...phrasesBuckets[str]];
                }, []);

                return resolve({
                    phrases,
                    ...extraData
                });
            } else {
                reject(message || 'Something went wrong')
            }
        });

    }
    async initProcedurePhrasesToCKEditor(procedure_type_query) {
        if (!this.note.patient || procedure_type_query.length < 3) return;
        return await new Promise(async (resolve, reject) => {
            const { status, data, message } = await lastValueFrom(this.phrasesService.getProcedurePhrases(procedure_type_query.toLowerCase(), this.note.patient._id, this.currentUser.company_id)) as any;
            if (status == 200) {
                const { _id: procedure_type_id, procedure_type, datasets } = data;
                if (datasets) {
                    const phrases = datasets.map(dataset => ({
                        procedure_type_id, procedure_type,
                        ...dataset, dataset_id: dataset._id, id: dataset.phrase, key: dataset.phrase, value: undefined, type: 'system-defined'
                    }));
                    return resolve({
                        phrases
                    })
                }
            }
            reject(message || 'Something went wrong')
        });
    }

    getDOS() {
        // if(this.isNew) {
        //     const date_obj = this._route.snapshot.paramMap.get('date_obj');
        //     if(date_obj) {
        //         return `${date_obj.replace(/-/g, '/')} ${hour}:${minute}`;
        //     } else {
        //         return moment().format('YYYY-MM-DD');
        //     }
        // } else if(this.note.date_obj) {
        //     let {year, month, day} = this.note.date_obj;
        //     if(month < 10){
        //       month = '0' + month;
        //     }
        //     if(day < 10){
        //       day = '0' + day;
        //     }
        //     return `${month}/${day}/${year} ${hour}:${minute}`;
        // }
        // return moment(this.serviceDate).format('MM/DD/YYYY hh:mm');
        if (!this.censusId && this.isNew) {
            return moment().format('YYYY-MM-DD');
        }
        if (this.isValidDateObj()) {
            // if (!this.timePicked && ) {
            //     if (this.note?.date_obj && (!this.note.date_obj?.hour))
            //         this.note.date_obj.hour = new Date().getHours();
            //     if (this.note?.date_obj && (!this.note.date_obj?.minute))
            //         this.note.date_obj.minute = new Date().getMinutes();
            // }

            return this.commonService.dateObjToString(this.note.date_obj);
        }
        return '';
    }
    isValidDateObj() {
        return this.commonService.isValidDateObj(this.note.date_obj);
    }

    handleDateChange(date) {
        const [year, month, day] = date.split('-');

        const currentDate = new Date();
        const hour = currentDate.getHours();
        const minute = currentDate.getMinutes();
        this.note.date_obj = {
            year: parseInt(year), month: parseInt(month), day: parseInt(day), hour, minute
        };
    }
    hideNuanceBrowserKit() {
        var nusa_reference = document.getElementById("NUSAI_CommandBar_Container");
        var nusa_reference_bar = document.getElementById("dragonBar");
        if (nusa_reference) {
            if (nusa_reference_bar.style.transform) {
                localStorage.setItem("NUSA_Position", nusa_reference_bar.style.transform);
            }
            NUSA_floatAble();
            nusa_reference.style.display = "none";
        }
    }
    loadNuanceBrowserKit() {
        document.cookie = `NUSA_Guids=${global.DRAGON_SPEECH_CREDIENTIALS.Token}/${global.DRAGON_SPEECH_CREDIENTIALS.GUID}`;
        var dragon_script = document.getElementById("dragon_speech");
        if (dragon_script) {
            var nusa_reference = document.getElementById("NUSAI_CommandBar_Container");
            var nusa_reference_bar = document.getElementById("dragonBar");
            if (nusa_reference) {
                nusa_reference.style.display = "block";
                reIntializeNusa();
                NUSA_sticky();
                if (localStorage.getItem("NUSA_Position")) {
                    nusa_reference_bar.style.transform = localStorage.getItem("NUSA_Position");
                }
            }
        } else {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = "https://speechanywhere.nuancehdp.com/Mainline/scripts/Nuance.SpeechAnywhere.js";
            script.id = "dragon_speech";
            document.getElementsByTagName("head")[0].appendChild(script);
        }
    }

    jumpToAsterisk(direction) {
        let editor = !this.isCreatingAddendum ? this.mainEditor : this.addendumEditor;
        
        editor.instance.focus();
        const $cke = editor.instance.container.$;
        const $cke_wysiwyg_div = $cke.querySelector('.cke_wysiwyg_div');
        let keyCode, ctrlKey, shiftKey;
        if (direction > 0) {
            keyCode = 74;
            ctrlKey = true;
        } else {
            keyCode = 74;
            ctrlKey = true;
            shiftKey = true;
        }
        $cke_wysiwyg_div.dispatchEvent(new KeyboardEvent('keydown', { keyCode, ctrlKey, shiftKey } as KeyboardEventInit));
    }

    onchangeTime(event) {
        this.timePicked = true;
        if (this.note?.date_obj) {
            this.note.date_obj.hour = Number(event.hour);
            this.note.date_obj.minute = Number(event.min);
            this.note.timeChangedObj = this.note?.date_obj;
        }
    }

    checkEnabledState() {
        if (this.isNoTitleNote()) {
            return true;
        }
        if (this.isNew) {
            if (this.note.htmlText !== '' && this.note.title !== '') {
                return false;
            } else {
                return true;
            }
        } else {
            if (!this.buttonsState.save) {
                if (this.timePicked && this.note.htmlText.length > 0) {
                    if (this.isCreatingAddendum || this.note.isSigned() == true) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if (this.note.htmlText.length > 0 && !this.compareOriginalNoteState()) {
                        if (this.note.isSigned() == true) {
                            return true;
                        }
                        return false;
                    } else {
                        return true;
                    }
                }
            }
        }
        return false;            
    }

    isNoTitleNote(): boolean {
        if (!this.note?.title.trim() || this.note.title === "NO TITLE" ) return true;
        return false;
    }

    isAssociatedProvider() {
        if (this.assoc_provider_ids) {
            return this.assoc_provider_ids.indexOf(this.currentUser._id) >= 0;
        }
        return false;
    }

    saveBarPosition($event) {
    
    }

    async updateWoundfromNote(id?, addendum_id?, extended_id?, key?, value?, getValuesfromNoteEditor = false) {
        let wound = {};
        if (getValuesfromNoteEditor) {
            // const { instance } = this.angularEditor;
            // var result = htmlCompare.compare(instance.getData(), this.originalNoteState.htmlText);
            // if (result.different) {
            //     result.changes.map(function (change) {
            //         if (change.after.$parent[0].parent && change.after.$parent[0].parent.attribs) {
            //             let parentAttr = change.after.$parent[0].parent.attribs;
            //             if (parentAttr['data-sub-phrase-key'] && parentAttr['data-wound'] && parentAttr['data-sub-phrase-key'] === "woundassessment") {
            //                 id = parentAttr['data-wound'];
            //                 if (change.after.$parent[0].attribs) {
            //                     let nodeAttr = change.after.$parent[0].attribs;
            //                     if (nodeAttr['data-wound-field'] && nodeAttr['data-wound-field'] === "woundlocation") {
            //                         let data = change.before.$node[0].data;
            //                         key = "body_part";
            //                         value = data
            //                     }

            //                 }
            //             }
            //         }
            //     });
            // } else {
            // }
        }
        if (id && key && ["published","in_progress"].includes(this.note.note_status.status)) {
            if(extended_id){
                wound["extended_wound_model"] = {
                    header_id: extended_id,
                    value: value
                }
            }else{
                wound[key] = value;
            }
            const checkChildWounds = this.noteSidebarComponent.checkChildWoundExists(id);
            const isWoundLocked = this.noteSidebarComponent.isWoundLocked(id);
            const { status, data } = await lastValueFrom(this.woundCareWidgetService.updateWoundData(id, wound, checkChildWounds))
            if (status == 200) {
                this.noteSidebarComponent.updateWoundfromNote(id, wound);
                if (key === 'exudate' && value === 'None') {
                    let obj = {
                        _id: id,
                        exudate_type: 'None'
                    };
                    await lastValueFrom(this.woundCareWidgetService.updateWoundData(id, { exudate_type: 'None' }, checkChildWounds));
                    this.noteSidebarComponent.updateWoundfromNote(id, { exudate_type: 'None' });
                    await this.handleChangeWoundDetails(obj);
                } else if (key === 'body_part') {
                    let obj = {
                        _id: id,
                        woundlocation: value
                    };
                    await this.handleChangeWoundDetails(obj);
                } else { 
                    let obj = {
                        _id: id
                    };
                    obj[`${key}`] = value;
                    await this.handleChangeWoundDetails(obj);
                }
            }
        } else if (id && key) {
            if(extended_id){
                wound["extended_wound_model"] = {
                    header_id: extended_id,
                    value: value
                }
            }else{
                wound[key] = value;
            }
            const { status, data } = await lastValueFrom(this.woundCareWidgetService.updateWoundData(addendum_id, wound, true));
            if (status == 200) {
                this.noteSidebarComponent.updateWoundfromNote(addendum_id, wound, true);
                if (key === 'exudate' && value === 'None') {
                    let obj = {
                        _id: id,
                        addendum_id: addendum_id,
                        exudate_type: 'None'
                    };
                    await lastValueFrom(this.woundCareWidgetService.updateWoundData(addendum_id, { exudate_type: 'None' }, true));
                    this.noteSidebarComponent.updateWoundfromNote(addendum_id, { exudate_type: 'None' }, true);
                    await this.handleChangeWoundDetails(obj);
                } else if (key === 'body_part') {
                    let obj = {
                        _id: id,
                        addendum_id: addendum_id,
                        woundlocation: value
                    };
                    await this.handleChangeWoundDetails(obj);
                }
            }
        }
    }

    async updateProcedureNotefromNote(id, header_id, key, value, label = '') {
        const updatedTemplateObj = {
            inputs: [],
            template: this.note.htmlText
        }
        if (label) {
            this.setUpdatedValuesinNote(updatedTemplateObj, id, header_id, value, true, label);
            value = updatedTemplateObj.inputs.reduce((result, input) => result * Number(input.value), 1);
            this.setUpdatedValuesinNote(updatedTemplateObj, id, header_id, value, true);
        } else {
            this.setUpdatedValuesinNote(updatedTemplateObj, id, header_id, value, false);
        }
        const updateObj = {
            header_id,
            value,
            inputs: updatedTemplateObj.inputs
        };
        try {
            await lastValueFrom(this.procedureNotesService.updateProcedureNotesData(id, updateObj));
            this.note.htmlText = updatedTemplateObj.template;
        } catch (error) {
            this.toastr.error('Something went wrong', 'Failed');
            console.log('Error in updateProcedureNotefromNote() : ' + error?.message);
        }
    }

    setUpdatedValuesinNote(updatedTemplateObj, id, header_id, value, is_input_field, label = '') {
        const noteEditorText = document.createElement("div");
        noteEditorText.innerHTML = updatedTemplateObj.template;
        const childElements = Array.from(noteEditorText.querySelectorAll(`[data-procedure='${id}']`));
        for (let i = childElements.length - 1; i >= 0; i--) {
            const conditions = `[data-header="${header_id}"]`;
            const $procedureNoteFields = Array.from(childElements[i].querySelectorAll(conditions));
            if ($procedureNoteFields.length) {
                for (const $procedureNoteField of $procedureNoteFields) {
                    if (!is_input_field) {
                        const $dropdowns = Array.from($procedureNoteField.querySelectorAll('span.select-dropdown'));
                        if ($dropdowns.length) {
                            for (const $dropdown of $dropdowns) {
                                if (value === "" || value === null) {
                                    $dropdown.removeAttribute('data-options-selected');
                                    $dropdown.innerHTML = $dropdown.getAttribute('data-label');
                                } else {
                                    if ($dropdown.getAttribute('data-multiselect') === 'true') {
                                        $dropdown.setAttribute('data-options-selected', value.split(", ").join("|"));
                                    } else {
                                        $dropdown.setAttribute('data-options-selected', value);
                                    }
                                    $dropdown.innerHTML = value;
                                }
                            }
                        }
                    } else if (label) {
                        const $dropdowns = Array.from($procedureNoteField.querySelectorAll('span.text-field-dropdown'));
                        if ($dropdowns.length) {
                            for (const $dropdown of $dropdowns) {
                                const $label = $dropdown.getAttribute('data-label');
                                if ($label === label) {
                                    if (value === "" || value === null) {
                                        $dropdown.removeAttribute('data-location-value');
                                        $dropdown.innerHTML = label;
                                    } else {
                                        $dropdown.setAttribute('data-location-value', value);
                                        $dropdown.innerHTML = value;
                                    }
                                }
                                if (i == 0 && updatedTemplateObj.inputs.every(input => input.label !== $label)) {
                                    updatedTemplateObj.inputs.push({
                                        label: $label,
                                        value: $dropdown.getAttribute('data-location-value')
                                    });
                                }
                            }
                        }
                    } else {
                        const $results = Array.from($procedureNoteField.querySelectorAll('span.text-field-result'));
                        if ($results.length) {
                            for (const $result of $results) {
                                if (value === "" || value === null) {
                                    const label = $result.getAttribute('data-label');
                                    $result.removeAttribute('data-location-value');
                                    $result.innerHTML = label;
                                } else {
                                    $result.setAttribute('data-location-value', value);
                                    $result.innerHTML = value;
                                }
                            }
                        }
                    }
                }
            }
        }
        updatedTemplateObj.template = noteEditorText.innerHTML;
    }

    refreshICDWound10phrase(data) {
        let noteEditorText = document.createElement("div");
        noteEditorText.innerHTML = this.note.htmlText;
        let woundChildElements = noteEditorText.querySelectorAll("[data-phrase='icd10wound@dn']");
        if (woundChildElements) {
            woundChildElements.forEach((woundDiv) => {
                const $woundData = woundDiv.querySelector(`[data-wound="${data.wound_id}"]`);
                if ($woundData) {
                    const $woundICD10Phrases = Array.from(woundDiv.querySelectorAll(`[data-icd10-phrase]`));
                    const $allResolvedWoundICD10PhrasesIDs = $woundICD10Phrases.map((phrase) => phrase.getAttribute('data-icd10-phrase'));
                    if (JSON.stringify($allResolvedWoundICD10PhrasesIDs) !== JSON.stringify(data.icds_data.map((icd) => icd._id))) {
                        $woundData.innerHTML = `<span data-wound="${data.wound_id}">Wound # ${data.woundNo || ''} - ${data?.icds_data.map(icd => `<span data-icd10-phrase="${icd._id}">` + icd.code + ` (` + icd.mediumDescription + ')</span>').join(', ') || ''} - <span class="asteriskjump">***</span></span>`;
                        this.note.htmlText = noteEditorText.innerHTML;
                    }
                }
            });
        }
    }
    refreshICD10phrase(data) {
        let noteEditorText = document.createElement("div");
        noteEditorText.innerHTML = this.note.htmlText;
        let woundChildElements = noteEditorText.querySelectorAll("[data-phrase='icd10@dn']");
        if (woundChildElements) {
            woundChildElements.forEach((woundDiv) => {
                if (data.length > 0) {
                    const $noteEditorIcds = Array.from(woundDiv.querySelectorAll("[data-icd10-phrase]"));
                    const $allResolvedWoundICD10PhrasesIDs = $noteEditorIcds.map((phrase) => phrase.getAttribute('data-icd10-phrase'));
                    if (JSON.stringify($allResolvedWoundICD10PhrasesIDs) !== JSON.stringify(data.map((icd) => icd._id))) {
                        const resolvedStr = data
                            .reduce((acc, next) => {
                                return [
                                    ...acc,
                                    `<span class="icd10-code" data-icd10-phrase="${next._id}">${next.description} (<span>${next.code}</span>)</span>`,
                                ];
                            }, [])
                            .join('<br />');
                        woundDiv.innerHTML = resolvedStr;
                        this.note.htmlText = noteEditorText.innerHTML;
                    }
                } else {
                    woundDiv.innerHTML = ".";
                    this.note.htmlText = noteEditorText.innerHTML;
                }
            });
        }
    }
    onNoteEdiorIcdsChange(data) {
        clearTimeout(this.refreshICD10Phrase);
        this.refreshICD10Phrase = setTimeout(() => {
            this.refreshICD10phrase(data);
        }, 3000);
    }
    async handleChangeWoundDetails(data, returnChangedElement = false) {
        if (data.wound_recognition_date_type !== undefined && this.mustCheckWoundDatesforSignNote) {
            if (data.wound_recognition_date_type === '') {
                this.woundNosWithoutDates.push(data.woundNo)
            } else {
                const index = this.woundNosWithoutDates.indexOf(data.woundNo);
                if (index > -1) {
                    this.woundNosWithoutDates.splice(index, 1);
                }
            }
            if (this.woundNosWithoutDates.length > 0) {
                this.woundsDateErrorforSignNote = true
            } else this.woundsDateErrorforSignNote = false
        }
        let noteEditorText = document.createElement("div");
        noteEditorText.innerHTML = this.isCreatingAddendum ? this.addendum.text : this.note.htmlText;
        let woundChildElements = Array.from(noteEditorText.querySelectorAll(`[data-wound='${data._id}']`));
        for (let i = woundChildElements.length - 1; i >= 0; i--) {
            const phraseKey = woundChildElements[i].getAttribute('data-sub-phrase-key');
            const addendum_id = woundChildElements[i].getAttribute('data-addendum');
            let isAddendumPhrase = addendum_id ? data.addendum_id ? addendum_id.toString() === data.addendum_id.toString() ? true : false : false : true;
            if (isAddendumPhrase) {
                for (const key in data) {
                    if (Object.prototype.hasOwnProperty.call(data, key)) {
                        let value = data[key];
                        if (typeof (value) === "string" && value.trim() !== '' && (key === 'epithelial' || key === 'slough' || key === 'granulation' || key === 'eschar')) {
                            if (!value.includes(key.toString())) {
                                value = value.trim() + ` ${key}`;
                            }
                        }
                        let conditions = '';
                        if(this.companyPermissions.should_show_wound_location){
                            conditions = `[data-wound-field="${key === 'etiolgy' ? 'etiology' : key === 'stage' ? 'severity' : key}"]`
                        }else{
                            conditions = `[data-wound-field="${key === 'etiolgy' ? 'etiology' : key === 'stage' ? 'severity' : key === 'woundlocation' ? 'body_part' : key}"]`
                        }
                        const $woundField = woundChildElements[i].querySelector(conditions);
                        if ($woundField) {
                            // if is dropdown
                            if ((key === 'body_part' || key === 'woundlocation') && !this.companyPermissions.should_show_wound_location) {
                                const $dropdowns = Array.from($woundField.querySelectorAll('span.text-field-dropdown'));
                                if ($dropdowns.length) {
                                    for (const $dropdown of $dropdowns) {
                                        if (value === "" || value === null) {
                                            $dropdown.removeAttribute('data-location-value');
                                            $dropdown.innerHTML = 'LOCATION';
                                        } else {
                                            $dropdown.setAttribute('data-location-value', value);
                                            $dropdown.innerHTML = value;
                                        }
                                    }
                                }
                            } else {
                                const $dropdowns = Array.from($woundField.querySelectorAll('span.select-dropdown'));
                                if ($dropdowns.length) {
                                    for (const $dropdown of $dropdowns) {
                                        if ($dropdown.getAttribute('data-multiselect') === 'true') {
                                            if (value === "" || value === null) {
                                                $dropdown.removeAttribute('data-options-selected');
                                                $dropdown.innerHTML = $dropdown.getAttribute('data-label');
                                            } else {
                                                $dropdown.setAttribute('data-options-selected', value.split(", ").join("|"));
                                                $dropdown.innerHTML = value;
                                            }
                                        } else {
                                            if (value === "" || value === null) {
                                                $dropdown.removeAttribute('data-options-selected');
                                                $dropdown.innerHTML = $dropdown.getAttribute('data-label');
                                            } else {
                                                $dropdown.setAttribute('data-options-selected', value);
                                                $dropdown.innerHTML = value;
                                            }
                                        }
                                    }
                                } else {
                                    if (value === "" || value === null || value === undefined) {
                                        if (key === "woundlocation") {
                                            $woundField.innerHTML = '<span class="asteriskjump">***</span>'
                                        } else $woundField.innerHTML = " ";
                                    } else {
                                        if (value === false) {
                                            $woundField.innerHTML = '<span class="asteriskjump">***</span>'
                                        } else {
                                            if (value === true) {
                                                $woundField.innerHTML = this.getTunnelingUnderMiningValue(key, data);
                                            } else {
                                                $woundField.innerHTML = value
                                            }
                                        }
                                    }
                                }
                            }
                            if (phraseKey === "woundassessment" || phraseKey === "nursewoundassessment") {
                                const phraseText = removeUnSelectedFields(phraseKey, woundChildElements[i].innerHTML, false);
                                woundChildElements[i].innerHTML = phraseText.split('<br>').filter((ele) => ele.trim() !== "").join('<br>');
                            }
                        } else {
                            //Adding Field in the phrase
                            if (!['_id', 'populated_extended_model', 'extended_wound_model','addendum_id', 'woundNo', 'body_part', 'tunneling_direction', 'tunneling_distance', 'undermining_distance', 'undermining_from', 'undermining_to', 'wound_recognition_date_type'].includes(key) && phraseKey === "woundassessment" && value !== '') {
                                if (key === 'tunneling' || key === 'under_mining') {
                                    value = this.getTunnelingUnderMiningValue(key, data);
                                }
                                let extended_header_id = null;
                                if(data.extended_wound_model.length > 0){
                                    const extendedObj = data.populated_extended_model.find((pEM) => pEM.header.toUpperCase() === key.split("_").join(" ").toUpperCase());
                                    if(extendedObj){
                                        extended_header_id = extendedObj._id;
                                    }
                                }
                                const phraseText = await this.phrasesService.addremovedFieldsinPhrase('woundassessment', key, woundChildElements[i].innerHTML.split('<br>'), value, extended_header_id);
                                woundChildElements[i].innerHTML = phraseText;
                            }
                        }
                    }
                }
                if (Object.prototype.hasOwnProperty.call(data, 'length') && Object.prototype.hasOwnProperty.call(data, 'width')) {
                    const $woundField = woundChildElements[i].querySelector(`[data-wound-field="area"]`);
                    if ($woundField) {
                        $woundField.innerHTML = calculateArea(data);
                    }
                }
            }
        };

        if (!returnChangedElement) {
            if (this.isCreatingAddendum) {
                this.addendum.text = noteEditorText.innerHTML
            } else {
                this.note.htmlText = noteEditorText.innerHTML;
            }
        } else {
            return woundChildElements[0].innerHTML;
        }
    }
    async handleProcedureNoteDetailsChange(detailsObj) {
        const data = detailsObj.data;
        if((Array.isArray(data.addOrUpdatedProcedure) && data['addOrUpdatedProcedure'].length > 0) || (Array.isArray(data.deletedProcedure) && data['deletedProcedure'].length > 0) ){
            data['addOrUpdatedProcedure'].forEach(async (procedure) => {
                if (procedure.phrase_template_changed) {
                    if (this.noteSidebarComponent && this.noteSidebarComponent.noteAddChargeComponent && this.noteSidebarComponent.noteAddChargeComponent.noteSidebarCPTs) {
                        this.noteSidebarComponent.noteAddChargeComponent.noteSidebarCPTs.removeICDsUsingModifer([`woundprocedure-${procedure._id}`]);
                    }
                    this.resolveProcedureNoteUpdatedPhrase({ phrase_key: 'procedure', updatedData: procedure });
                } else {
                    this.updateProcedureNoteDetailChange({ phrase_key: 'procedure', sub_phrase_key: 'woundprocedure', data_key_attr: 'procedure_note_data', updatedData: procedure });
                }
            });
            if (data['deletedProcedure'].length > 0) this.deleteProcedureNoteTemplate(data['deletedProcedure'].map((procedure) => procedure._id), 'procedure', detailsObj['totalProcedureNotes']);
            if (detailsObj['totalProcedureNotes'] === 0) {
                if(this.noteSidebarComponent && this.noteSidebarComponent.noteAddChargeComponent && this.noteSidebarComponent.noteAddChargeComponent.noteSidebarCPTs) {
                    this.noteSidebarComponent.noteAddChargeComponent.noteSidebarCPTs.removePreviousProcedureCPTs([], true);
                }
                return;
            }
            await this.procedureNotesService.getCalculatedProcedureCPTs(this.note.date_obj, this.note.provider.company_id, this.note.patient?._id || this.note.patient_id).subscribe((result) => {
                if(result['status'] === 200){
                    const calculatedCPTs = []
                    const allCalculatedCPTs = result['data'];
                    allCalculatedCPTs.forEach((cpt, index) => {
                        if (cpt.code) {
                            cpt.code['unit'] = cpt.unit;
                            cpt.code['key'] = cpt.keys;
                            cpt.code['updated'] = true;
                            calculatedCPTs.push(cpt.code);
                        }
                    });
                    this.noteEditorCpts = [...calculatedCPTs];
                }
            })
        }
    }
    async resolveProcedureNoteUpdatedPhrase(options: { phrase_key: string, updatedData: any }) {
        const noteEditorText = document.createElement("div");
        noteEditorText.innerHTML = this.note.htmlText;
        const childElements = Array.from(noteEditorText.querySelectorAll(`[data-${options.phrase_key}='${options.updatedData._id}']`));
        if (childElements.length) {
            for (const procedureNote of childElements) {
                const parentNode = procedureNote.parentElement;
                const key = `${parentNode.getAttribute('data-phrase')}`;
                const wound_id = procedureNote.getAttribute('data-wound');
                const extra = {
                    phrase: { id: key, key: key, type: "system-defined", _id: wound_id },
                    should_show_procedure_note: this.companyPermissions.should_show_procedure_note,
                    should_show_wound_location: this.companyPermissions.should_show_wound_location
                };
                const updatedTemplate = await this.resolveSystemDefinedPhraseValue(`.${key}`, this.note, extra);
                if (updatedTemplate) {
                    procedureNote.outerHTML = updatedTemplate;
                }
            }
        } else {
            const childElements = Array.from(noteEditorText.querySelectorAll(`[data-phrase='woundprocedure_all@dn']`));
            if (childElements.length) {
                for (const procedureNote of childElements) {
                    const key = `${procedureNote.getAttribute('data-phrase')}`;
                    const extra = {
                        phrase: { id: key, key: key, type: "system-defined" },
                        should_show_procedure_note: this.companyPermissions.should_show_procedure_note,
                        should_show_wound_location: this.companyPermissions.should_show_wound_location
                    };
                    const updatedTemplate = await this.resolveSystemDefinedPhraseValue(`.${key}`, this.note, extra);
                    if (updatedTemplate) {
                        procedureNote.innerHTML = updatedTemplate;
                    }
                }
            }
        }
        this.note.htmlText = noteEditorText.innerHTML;
    }
    deleteProcedureNoteTemplate(procedureIdsArray, phrase_key, totalProcedures){
        const { instance } = !this.isCreatingAddendum ? this.mainEditor : this.addendumEditor;
        const noteEditorText = document.createElement("div");
        noteEditorText.innerHTML = instance.getData();
        if(totalProcedures === 0){
            let childElement = Array.from(noteEditorText.querySelectorAll(`[data-${phrase_key}='${procedureIdsArray[0]}']`));
            for (let i = childElement.length - 1; i >= 0; i--) {
                childElement[i].parentElement.innerHTML = 'No procedures performed on this DOS.';
            }
        } else {
            procedureIdsArray.forEach((procedureId) => {
                let childElements = Array.from(noteEditorText.querySelectorAll(`[data-${phrase_key}='${procedureId}']`));
                for (let i = childElements.length - 1; i >= 0; i--) {
                    childElements[i].parentElement.removeChild(childElements[i]);
                }
            });
        }
        this.note.htmlText = noteEditorText.innerHTML;
        instance.setData(noteEditorText.innerHTML);
    }
    updateProcedureNoteDetailChange(options: { phrase_key: string, sub_phrase_key: string, data_key_attr: string, updatedData: any }) {
        const { updatedData } = options;
        const noteEditorText = document.createElement("div");
        noteEditorText.innerHTML = this.note.htmlText;
        const childElements = Array.from(noteEditorText.querySelectorAll(`[data-${options.phrase_key}='${updatedData._id}']`));
        for (let i = childElements.length - 1; i >= 0; i--) {
            updatedData[`${options.data_key_attr}`].forEach(data => {
                const value = data.value;
                const conditions = `[data-header="${data.header_id}"]`;
                const $procedureNoteFields = Array.from(childElements[i].querySelectorAll(conditions));
                if ($procedureNoteFields.length) {
                    for (const $procedureNoteField of $procedureNoteFields) {
                        if ( data.inputs && data.inputs.length > 0) {
                            const $dropdowns = Array.from($procedureNoteField.querySelectorAll('span.text-field-dropdown'));
                            if ($dropdowns.length) {
                                for (const $dropdown of $dropdowns) {
                                    const label = $dropdown.getAttribute('data-label');
                                    const input = data.inputs.find(input => input.label === label);
                                    if (input) {
                                        if (input.value === "" || input.value === null) {
                                            $dropdown.removeAttribute('data-location-value');
                                            $dropdown.innerHTML = label;
                                        } else {
                                            $dropdown.setAttribute('data-location-value', input.value);
                                            $dropdown.innerHTML = input.value;
                                        }
                                    }
                                }
                            }
                            const $results = Array.from($procedureNoteField.querySelectorAll('span.text-field-result'));
                            if ($results.length) {
                                for (const $result of $results) {
                                    if (value === "" || value === null) {
                                        const label = $result.getAttribute('data-label');
                                        $result.removeAttribute('data-location-value');
                                        $result.innerHTML = label;
                                    } else {
                                        $result.setAttribute('data-location-value', value);
                                        $result.innerHTML = value;
                                    }
                                }
                            }
                        } else {
                            const $dropdowns = Array.from($procedureNoteField.querySelectorAll('span.select-dropdown'));
                            if ($dropdowns.length) {
                                for (const $dropdown of $dropdowns) {
                                    if (value === "" || value === null) {
                                        $dropdown.removeAttribute('data-options-selected');
                                        $dropdown.innerHTML = $dropdown.getAttribute('data-label');
                                    } else {
                                        if ($dropdown.getAttribute('data-multiselect') === 'true') {
                                            $dropdown.setAttribute('data-options-selected', value.split(", ").join("|"));
                                        } else {
                                            $dropdown.setAttribute('data-options-selected', value);
                                        }
                                        $dropdown.innerHTML = value;
                                    }
                                }
                            }
                        }
                    }
                }
            });
        }
        this.note.htmlText = noteEditorText.innerHTML;
    }
    async handleHtmlTextChange(details) {
        let key = details.key;
        for await (const k of key) {
            const childElements = document.querySelectorAll(k.includes("_") ? `[id="${k}"]` : `[data-procedure="${k.split("-")[1]}"]`);
            childElements.forEach((ele) => {
                k.includes("_")  ? ele.parentElement.remove() : ele.remove();
            });
        }
        if (details.wound_id) {
            await this.phrasesService.removeDotphraseDate(details.wound_id, this.note.date_obj).toPromise();
        }
        const { instance } = !this.isCreatingAddendum ? this.mainEditor : this.addendumEditor;
        this.note.htmlText = instance.getData();
    }

    checkSignedEnableButton() {
        if (this.isNoTitleNote()) {
            return true;
        }

        // Regular expression to strip HTML tags and get the text content
        const tempTextContent = this.note.htmlText.replace(/<[^>]*>/g, '').trim();
        return !this.buttonsState.sign || tempTextContent.length === 0;
    }

    async generateWoundsReportPreview(downloadReport = false) {
        let dateAssessment = '';
        if (this.note.date_obj) {
            let day = (this.note.date_obj.day < 10) ? ('0' + (this.note.date_obj.day).toString()) : (this.note.date_obj.day).toString();
            let month = (this.note.date_obj.month < 10) ? ('0' + (this.note.date_obj.month).toString()) : (this.note.date_obj.month).toString();
            let year = (this.note.date_obj.year);
            dateAssessment = month + '/' + day + '/' + year;
        }
        let patientObj = {
            date_of_birth: this.note.patient.date_of_birth,
            first_name: this.note.patient.first_name,
            gender: this.note.patient.gender,
            last_name: this.note.patient.last_name,
            _id: this.note.patient._id,
            facilityObject: [this.note.facility],
            timeZone: this.note.facility.timeZone ? this.note.facility.timeZone : 'America/New_York'
        }
        let response: any = await this.woundCareWidgetService.generateReportForAllWounds(patientObj, "active", this.note.date_obj).toPromise();
        if (response.status == 200) {
            if (downloadReport) {
                this.commonService.convertToBlobAndDownload(response.data, `${this.configuration.wound_assessment_report_name}_${dateAssessment}`, "pdf");
            } else {
                const blob = new Blob([Buffer.from(response.data)], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                window.open(url, '_blank');
                window.URL.revokeObjectURL(url);
            }
        } else {
            if (response.status == 500) {
                this.toastr.warning(`No wound found assessed on ${dateAssessment}`, '');
            }
        }
    }
    showUploadWoundReportButton() {
        // check if has wounds
        return true;
    }
    convertToBlobAndDownload(buffer, title: string, fileExtension: string) {
        const pdfBlob = new Blob([Buffer.from(buffer.data)], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = `${title}${fileExtension}`;
        link.click();
    }
    async initFacilitySettings() {
        let response: any = await this._facilitySettingsService.getFacilitySettings(this.note.facility._id, this.currentUser.company_id).toPromise();
        if (response.status === 200) {
            this.configuration = response.data.configuration;
            if (!this.configuration.wound_assessment_report_name || this.configuration.wound_assessment_report_name === "") {
                this.configuration.wound_assessment_report_name = "Wound Assessment Report";
            }
        }
    }
    uploadWoundReportToPCC() {
        if (this.hasLoggedInToPCC() || this.canUploadReportToPCCUsingTwoLegged()) {
            const payload = {
                noteId: this.note._id
            }
            this.woundService.generateWoundReportUploadToPcc(payload)
                .subscribe((response: any) => {
                    if (response.status === 200) {
                        let currentDate = moment(new Date()).format('MM/DD/YYYY hh:mm').toString()
                        this.toastr.success(`Wound Report has uploaded to PCC successfully`);
                        this.note.wound_report_uploaded = true;
                        if (this.note.is_signed === "true" && this.note.pcc_progressNoteId) {
                            this.drafteNoteState = `Note and Wound Report Uploaded to PCC on ${currentDate}`;
                        } else {
                            this.drafteNoteState = `Wound Report Uploaded to PCC on ${currentDate}`;
                        }
                    } else {
                        this.toastr.error(response.message, "Failed");
                    }
                });
        } else {
            this.toastr.error("Report was NOT uploaded into PCC. Please sign in to PCC to upload the report.");
        }
    }
    uploadWoundReportToMatrix() {
        const payload = {
            noteId: this.note._id
        }
        this.woundService.generateWoundReportUploadToMatrix(payload)
            .subscribe((response: any) => {
                if (response.status === 200) {
                    let currentDate = moment(new Date()).format('MM/DD/YYYY hh:mm').toString()
                    this.toastr.success(`Wound Report has uploaded to MatrixCare successfully`);
                    this.note.wound_report_uploaded = true;
                    if (this.note.is_signed === "true") {
                        this.drafteNoteState = `Note and Wound Report Uploaded to MatrixCare on ${currentDate}`;
                    } else {
                        this.drafteNoteState = `Wound Report Uploaded to MatrixCare on ${currentDate}`;
                    }
                } else {
                    this.toastr.error(response.message, "Failed");
                }
            });
    }

    uploadWoundReport() {
        if (this.note.facility.source === 'PointClickCare') {
            this.uploadWoundReportToPCC()
        } else if (this.note.facility.source === 'MatrixCare') {
            this.uploadWoundReportToMatrix()
        }
    }
    getAllWoundNoWithoutDates() {
        return this.woundNosWithoutDates.join(", ");
    }
    async handleChangeinWoundPhrases(data, phrasekey) {
        return new Promise<void>(async (resolve, reject) => {
            let noteEditorText = document.createElement("div");
            noteEditorText.innerHTML = this.note.htmlText;
            let $allWoundAssessmentPhrases = Array.from(noteEditorText.querySelectorAll(`[data-phrase="${phrasekey}_all@dn"]`));
            if ($allWoundAssessmentPhrases && $allWoundAssessmentPhrases.length > 0) {
                for await (const allWoundAssessmentPhrase of $allWoundAssessmentPhrases) {
                    data.wounds.forEach(async (wound) => {
                        let $singlePhrases = allWoundAssessmentPhrase.querySelector(`[data-wound="${wound._id}"]`);
                        if ($singlePhrases) {
                            delete wound.woundNo;
                            this.handleChangeWoundDetails(wound, true).then((result) => {
                                if (result !== $singlePhrases.innerHTML) {
                                    $singlePhrases.innerHTML = result;
                                    this.note.htmlText = noteEditorText.innerHTML;
                                }
                            });
                        } else {
                            const key = `.${phrasekey}_${wound.woundNo}@dn`;
                            let resolvePhrase = {
                                phrase: {
                                    extraData: {},
                                    id: `${phrasekey}_${wound.woundNo}@dn`,
                                    key: `${phrasekey}_${wound.woundNo}@dn`,
                                    type: "system-defined",
                                    value: undefined,
                                    _id: wound._id
                                }
                            }
                            await this.resolveSystemDefinedPhraseValue(key, this.note, resolvePhrase).then((result) => {
                                allWoundAssessmentPhrase.appendChild(document.createElement("br"));
                                allWoundAssessmentPhrase.appendChild(document.createElement("br"));
                                const phraseElement = document.createElement("span");
                                phraseElement.innerHTML = result;
                                const woundPhrase = phraseElement.querySelector(`[data-sub-phrase-key="${phrasekey}"]`);
                                allWoundAssessmentPhrase.appendChild(woundPhrase);
                                this.note.htmlText = noteEditorText.innerHTML;
                            });
                        }
                    });
                }
            }
            resolve(null);
        });
    }
    async handlecheckallWoundAssessmentPhrase(data) {
        let woundslength = this.totalWoundsLength;
        const phraseKeys = ['woundplan', 'woundassessment'];
        let noteEditorText = document.createElement("div");
        noteEditorText.innerHTML = this.note.htmlText;
        if (data) {
            this.woundNosWithoutDates = data.wounds.filter((wound) => wound.wound_recognition_date_type === "").map((w) => w.woundNo);
            if (this.mustCheckWoundDatesforSignNote && this.woundNosWithoutDates.length > 0) {
                this.woundsDateErrorforSignNote = true
            } else this.woundsDateErrorforSignNote = false
            this.totalWoundsLength = data.wounds.length;
            woundslength = data.wounds.length
            if (data.checkAllPhraseKey) {
                // for (const key of phraseKeys){
                //     this.handleChangeinWoundPhrases(data, key);
                // }
                for await (const wound of data.wounds) {
                    await this.handleChangeWoundDetails(wound);
                }
                // data.wounds.forEach(async (wound) => {
                //     delete wound.woundNo;
                // });

                // await this.handleChangeinWoundPhrases(data, 'woundassessment');
                // await this.handleChangeinWoundPhrases(data, 'woundplan')
            }
        }
        if (this.note.is_signed === 'false') {
            let $allWoundAssessmentPhrases = Array.from(noteEditorText.querySelectorAll(`[data-phrase="woundassessment_all@dn"]`));
            if ($allWoundAssessmentPhrases && $allWoundAssessmentPhrases.length > 0) {
                const $woundAssessmentPhrases = noteEditorText.querySelectorAll(`[data-sub-phrase-key="woundassessment"]`);
                let allWoundIds = []
                $woundAssessmentPhrases.forEach((phrase) => {
                    allWoundIds.push(phrase.getAttribute('data-wound'));
                });
                const uniqueWoundIds = Array.from(new Set(allWoundIds));
                if (uniqueWoundIds.length < woundslength) {
                    this.woundsDocumentedError = true;
                } else {
                    this.woundsDocumentedError = false;
                }
            }
        }
    }
    handleinsertPhraseText(data) {
        let key = '.' + data.phrase.key;
        let noteEditorText = document.createElement("div");
        noteEditorText.innerHTML = this.note.htmlText;
        noteEditorText.innerHTML = noteEditorText.innerHTML + `<p><br/></p><p>${key}</p>`;
        this.note.htmlText = noteEditorText.innerHTML;
        setTimeout(() => {
            this.applyWhileHasPhrasesReplacement();
        }, 100)
    }
    changeInCKEditorbySpellChecker() {
        setTimeout(() => {
            const { instance } = !this.isCreatingAddendum ? this.mainEditor : this.addendumEditor;
            this.note.htmlText = instance.getData();
            this.handleEditorChangeDebounced();
        }, 1000)
    }
    getTunnelingUnderMiningValue(key, data, appendAsterikClass = true) {
        let valueString = "";
        if (key === 'under_mining') {
            if (data.under_mining === true && data.undermining_to != "" && data.undermining_from != "") {
                valueString = `from ${data.undermining_from} o’clock to ${data.undermining_to} o’clock`;
            } else if (data.under_mining === true && (data.undermining_to !== "" || data.undermining_from !== "")) {
                if (data.undermining_to == "") {
                    valueString = `${data.undermining_from} o’clock`;
                } else {
                    valueString = `${data.undermining_to} o’clock`;
                }
            }
            if (data.under_mining === true && data.undermining_distance != "") {
                valueString = valueString != "" ? valueString + `, ${data.undermining_distance} cm.` : `${data.undermining_distance} cm.`;
            }
        } else {
            if (data.tunneling === true && data.tunneling_direction != "") {
                valueString = `${data.tunneling_direction} o’clock`;
            }
            if (data.tunneling === true && data.tunneling_distance != "") {
                valueString = valueString != "" ? valueString + `, ${data.tunneling_distance} cm.` : `${data.tunneling_distance} cm.`;
            }
        }

        if (valueString === "" && appendAsterikClass) return '<span class="asteriskjump">***</span>';
        else return valueString;
    }

    toggleTimer() {
        if (this.timerRunning) {
            this.stopTimer();
        } else {
            this.startTimer();
        }
    }

    startTimer() {
        this.shouldShowTimer = true;
        this.timerRunning = true;
        this.startTime = Date.now() - this.elapsedTime;
        this.timerInterval = setInterval(() => {
            this.updateDisplayTime();
        }, 1000);
    }

    stopTimer() {
        this.timerRunning = false;
        clearInterval(this.timerInterval);
        this.elapsedTime = Date.now() - this.startTime;
        this.updateDisplayTime();
        this.addTimelogToNote();

    }

    updateDisplayTime() {
        const currentTime = Date.now();
        const elapsedTimeInSeconds = Math.floor((currentTime - this.startTime) / 1000);
        const hours = Math.floor(elapsedTimeInSeconds / 3600);
        const minutes = Math.floor((elapsedTimeInSeconds % 3600) / 60);
        const seconds = elapsedTimeInSeconds % 60;

        this.displayTime = `${this.formatTime(hours)}:${this.formatTime(minutes)}:${this.formatTime(seconds)}`;
    }

    formatTime(value: number): string {
        return value < 10 ? `0${value}` : `${value}`;
    }

    addTimelogToNote() {
        this.note.time_logs.time = this.displayTime;
        this.note.time_logs.name = `${this.currentUser.first_name}, ${this.currentUser.last_name}, ${this.currentUser.title}`;
        this.note.time_logs.user_type = this.currentUser.user_type;
        this.note.time_logs.date = this.commonService.convertDateToStringforMoment(moment());
        this.saveTimeLogs();
    }

    saveTimeLogs() {
        const timeLogs : any = lastValueFrom(this.noteEditorService.saveTimeLogs(this.note._id,this.note.time_logs));
        if(timeLogs.status == 200) {
        
        }
    }

    uploadWoundImagesReportToPCC(){
        if(this.noteSidebarComponent && this.noteSidebarComponent.skinwoundCareComponent){
            this.noteSidebarComponent.skinwoundCareComponent.groupWoundImgs(true);
        }
    }
    async getCompanyPermissions(){
        const response: any = await lastValueFrom(this._company.getCompany({ _id: this.currentUser.company_id }, { should_show_wound_assessment: 1, should_show_procedure_note: 1, should_allow_auto_procedure_coding: 1, should_show_skin_sub_refferal: 1, should_show_wound_location: 1 }));
        if (response.status == 200) {
            this.companyPermissions = response.data;
        }
    }
}
