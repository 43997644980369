import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CKEditorService } from 'src/app/includes/note-editor/ckeditor.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WoundService } from 'src/app/services/wound.service';
import { lastValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PhrasesService } from 'src/app/includes/phrases/phrases.service';

@Component({
  selector: 'app-procedure-phrase-editor',
  templateUrl: './procedure-phrase-editor.component.html',
  styleUrls: ['./procedure-phrase-editor.component.css']
})
export class ProcedurePhraseEditorComponent implements OnInit {
  procedureDatasets: any = [];
  phrase_key: String;
  cke4Config;
  marker = '.';
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  should_show_procedure_note: boolean = false;
  phrase_template: string = '';
  procedureNotePhrases: { header, header_type, _id }[] = [];
  displayProcedureNotePhrases: { header, header_type, _id }[];
  company_id: string = "";
  viewOnly: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ProcedurePhraseEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public procedure_data: ProcedurePhraseEditorComponent,
    private ckeditorService: CKEditorService,
    private _woundService: WoundService,
    private _toaster: ToastrService,
    private ngZone: NgZone,
    private phrasesService: PhrasesService,

  ) {
    if (procedure_data) {
      this.procedureNotePhrases = procedure_data.procedureNotePhrases || [];
      this.should_show_procedure_note = procedure_data.should_show_procedure_note || false;
      this.displayProcedureNotePhrases = this.procedureNotePhrases || [];
      this.procedureDatasets = procedure_data.procedureDatasets || [];
      this.phrase_key = procedure_data.phrase_key || '';
      this.phrase_template = (this.should_show_procedure_note ? this.procedureDatasets.procedure_note_template : this.procedureDatasets.template) || '';
      this.company_id = procedure_data.company_id ? procedure_data.company_id : null;
      this.viewOnly = procedure_data.viewOnly || false;
    }
    this.cke4Config = {
      ...this.ckeditorService.getDefaultConfig(),
      editable: !this.viewOnly
    };

    const extraPlugins = this.cke4Config.extraPlugins.split(',');
    this.cke4Config.extraPlugins = [...extraPlugins, 'selectdropdown', 'woundphrasesautocomplete'].join(',')
    // allow data attribute
    this.cke4Config.extraAllowedContent = 'p span[id][data-*](*)'
  }

  ngOnInit(): void {
    this.initPhrases();
    this.dialogRef.afterClosed().subscribe(() => {
      this.phrasesService.setWoundTemplatePhrases([]);
    });
    this.initGlobalScope();
  }
  initGlobalScope() {
    window['angularComponentReference'] = {
      component: this, zone: this.ngZone,
      componentDataMembers: {
        componentName: 'procedure-phrase-editor',
        phrases: this.phrasesService.castWoundTemplatePhrases
      },
      componentFunctions: {
        resolveSystemDefinedPhraseValue: (key, extra) => this.resolveSystemDefinedPhraseValue(key),

        onResolvePhraseStart: (phrase) => {
        },
        onResolvePhraseEnd: (phrase, error) => {
        }
      }
    };
  }
  async initPhrases() {
    let response = await this._woundService.getAllDatasetinCompany(null, "1", this.company_id).toPromise();
    if (response['status'] === 200) {
      this.phrasesService.setWoundTemplatePhrases([...this.procedureNotePhrases.reduce((acc, dataset) => {
        const transformedHeader = this.transformHeaderToPhrase(dataset.header);
        if (dataset.header_type && dataset.header_type === "Input") {
          acc.push({ key: transformedHeader });
          acc.push({ key: `${transformedHeader}_result` });
        } else {
          acc.push({ key: transformedHeader });
        }
        return acc;
      }, []), ...response['data'].map((dataSet) => ({ key: this.mapWoundKeys(dataSet.header.toLowerCase().replaceAll(" ", "_")) })), { key: "provider@dn" }]);
    } else {
      this.phrasesService.setWoundTemplatePhrases([...this.procedureNotePhrases.reduce((acc, dataset) => {
        const transformedHeader = this.transformHeaderToPhrase(dataset.header);
        if (dataset.header_type && dataset.header_type === "Input") {
          acc.push({ key: transformedHeader });
          acc.push({ key: `${transformedHeader}_result` });
        } else {
          acc.push({ key: transformedHeader });
        }
        return acc;
      }, []), { key: "provider@dn" }]);
    }
  }
  mapWoundKeys(key) {
    if (key === "wound_type") return "etiology";
    else if (key === "location") return "woundlocation";
    else return key
  }
  transformHeaderToPhrase(header: string) {
    return header.toLowerCase().trim().replace(/\s/g, '_');
  }
  async resolveSystemDefinedPhraseValue(key) {
    return key;
  }
  async saveProceduretemplate() {
    const infoToast = this._toaster.info('Updating Procedure Template', 'Please wait', { disableTimeOut: true });

    if (this.should_show_procedure_note) {
      this.procedureDatasets.procedure_note_template = this.phrase_template;
    } else {
      this.procedureDatasets.template = this.phrase_template;
    }
    const updateDataObj = {
      template: this.procedureDatasets.template,
      procedure_note_template: this.procedureDatasets.procedure_note_template,
    }
    const response = await lastValueFrom(this._woundService.updateProcedureDataset(this.procedureDatasets._id, updateDataObj));
    if (response['status'] == 200) {
      const allData = response['data']['array'];
      this._toaster.clear(infoToast.toastId);
      this._toaster.success("Updated Procedure Template Successfully", "Success");
      this.dialogRef.close(this.procedureDatasets);
    } else {
      this._toaster.clear(infoToast.toastId);
      this._toaster.error("Something Went Wrong", "Failed");
    }
  }

  onReadyCkeditor(e) {
    const { editor } = e;
    this.ckeditorService.ckeditorRemoveTitle(editor);
  }
}
