import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { isArray } from 'lodash';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { NursingService } from '../nursing.service';
import * as global from '../../global';
import { API_URL } from "../../../../environments/api-url";
import { MatDialog } from '@angular/material/dialog';
import { ExportNotesDialogComponent } from 'src/app/shared/popup-dialogs/export-notes-dialog/export-notes-dialog.component';
import { NoteEditorService } from '../../note-editor/note-editor.service';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/Modules/company.service';


@Component({
  selector: 'app-nursing-dashboard',
  templateUrl: './nursing-dashboard.component.html',
  styleUrls: ['./nursing-dashboard.component.css']
})
export class  NursingDashboardComponent implements OnInit {
  global = global;
  leftColClose: boolean;
  leftColWidth: boolean;
  selectedProvider: any;
  selectedfacility: any;
  facilityControl = new FormControl();
  providerControl = new FormControl();
  assoc_providersList: any;
  assoc_Facilities: any;
  transGroup = {
    group: 'Group by Date of Service',
    value: 'date_of_service'
  };
  transGrouping = [
    {
      group: 'Group by Date of Service',
      value: 'date_of_service'
    },
    {
      group: 'Group by Date of Creation',
      value: 'creation_date'
    }
  ];
  transcriptionsGroupingKey: string = 'date_of_service';
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  noteDateFilter = {
    startDate: moment().subtract(7, 'days'),
    endDate: moment()
  };
  searchTextProvider: any;
  searchText:any;
  groupedSortedTranscriptions: {};
  sortedTransriptions: any;
  multiSelectedTranscriptions: Array<any> = [];
  public element: string;
  newTitleElem: any;
  headingTags: any;
  title: any;
  titleContainer: any;
  dashboardView:boolean=true;
  reportView: any;
  currentUser: any;
  reportsNames: any=[];
  tranFilters: any = {
    dob: '',
    dos: '',
    facility: '',
    provider: '',
    patient: '',
    progress_status: 'Unsigned',
    note_status: '',
  };
  statusOptionTrans = ['Unsigned', 'Signed', 'Uploaded', 'Trashed', 'Co-Signed']

  constructor(
    private _service: NursingService,
    public _commonService: CommonService,
    private toastr: ToastrService,
    private dashboard: DashboardService,
    private dialog: MatDialog,
    private noteEditorService: NoteEditorService,
    private companyService: CompanyService,
    private _authService: AuthService,
  ) {
    this.currentUser = this._authService.currentUser;
    this.getSettings();
   }

  ngOnInit(): void {
    this.reportView='';
    this.initFacilities();
  }
  closeLeftCol() {
    this.leftColClose = true;
    this.leftColWidth = true;
  }

  openLeftCol() {
    this.leftColWidth = false;
    setTimeout(() => {
      this.leftColClose = false;
    }, 250);
  }
  refreshDashboard() {
    this.dashboardView=true;
  }
  openReport(route){
    this.dashboardView=false;
      this.reportView=route||'';
    console.log("reportView : ", this.reportView);
  }
  getSettings(){
    this.companyService.getCompanyReportSettings(this.currentUser.company_id).subscribe((response: any) => {
      if (Array.isArray(response.data) && response.data.length > 0) {
        this.reportsNames = [];
        for (const reportSetting of response.data) {
          if (reportSetting.selected && reportSetting.user_type.indexOf("nurse") > -1) {
            if (this.currentUser.company_type === this.global.COMPANY_TYPE.WOUND && reportSetting.title == 'DNS Healing Report') {
              reportSetting.title = 'Post Rounds Report'
            }
            if (this.currentUser.company_type === this.global.COMPANY_TYPE.WOUND && reportSetting.title == 'Post-Rounds Wound Report') {
              reportSetting.title = 'Wound Assessments'
            }
            if (reportSetting.url === "pressure-wound-report" &&
              this.currentUser.company_type !== this.global.COMPANY_TYPE.WOUND &&
              this.currentUser.company_type !== this.global.COMPANY_TYPE.SNF) continue;
            this.reportsNames.push(reportSetting);
          }
        }
      }
    })
  }
  async initFacilities() {
    await this._service.getAssociatedFacilities().subscribe((res: any) => {
      if (res.status == 200) {
        this.assoc_Facilities = res.data.array;
        this.selectedfacility = this.assoc_Facilities[0];
        this.getProvidersByFacility(0);
      }
    });
  }
  getProvidersByFacility(index) {
    if (this.assoc_Facilities && this.assoc_Facilities.length > 0) {
    this.assoc_providersList = this.assoc_Facilities[index].provider_ids_ref;
    this.assoc_providersList.sort((a, b) => a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase()));
    this.selectedProvider = this.assoc_providersList[0];
    }
    this.getAllProviderNotes();
  }
  async getAllProviderNotes() {
    let start = this.noteDateFilter.startDate;
    let end = this.noteDateFilter.endDate;
    console.log("selectedfacility : ", this.selectedfacility, this.selectedProvider);
   
    if(this.selectedfacility&&this.selectedProvider){
      await this._service.getAllProviderNotes(this.selectedfacility._id, this.selectedProvider._id, end, start, this.tranFilters.progress_status.toLowerCase()).subscribe((res: any) => {
        if (res.status == 200) {
          this.sortedTransriptions = res.data.array;
          this.groupedSortedTranscriptions = this.groupItemList(this.sortedTransriptions,
            this.transcriptionsGroupingKey);
        }
      })
    }else{
        console.log("selectedfacility or selectedProvider, doewsn't exist: ", this.selectedfacility, this.selectedProvider);
    }
  }

  onChangeStatusTrans(value) {
    this.tranFilters.progress_status = value;
    this.getAllProviderNotes();
  }
  async exportNote(option: any) {
    if (option === 'download') {
      if (this.multiSelectedTranscriptions.length > 0) {
        this.toastr.info('Downloading Notes', 'Downloading...');
        this.multiSelectedTranscriptions = this.multiSelectedTranscriptions.map(d => d?._id)
      }
      const fileName = `${this.multiSelectedTranscriptions.length} note(s).pdf`;
      //const fileName = `${this.multiSelectedTranscriptions.length} note(s).zip`;

      
      await this.dashboard.downloadFile(global.url + API_URL.NOTE.encrypted_zip_file, this.multiSelectedTranscriptions).subscribe((res: any) => {
      //await this.dashboard.downloadFile(global.url + API_URL.NOTE.generateMultiplePDF, this.multiSelectedTranscriptions).subscribe((res: any) => {
        if (res instanceof HttpResponse) {

          var url = window.URL.createObjectURL(res.body);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
        this.toastr.info('File Downloaded successfully.', 'Success');

        } else {

        }
      }, (err) => {
        this.toastr.error('Something went wrong while downloading notes', 'Failed');
      },
        () => {
        });
      this.multiSelectedTranscriptions = []
      this.multiSelectedTranscriptions.length = 0;
    }
    else if (option === 'send_to_email') {
      const dialogRef = this.dialog.open(ExportNotesDialogComponent, { width: '40%' });
      dialogRef.afterClosed().subscribe(async result => {
        if (result.emails.length > 0) {
          this.toastr.info('Sending email', 'Sending...');
          if (this.multiSelectedTranscriptions.length > 0) {
            this.multiSelectedTranscriptions = this.multiSelectedTranscriptions.map(d => d?._id)
          }
          
          this.noteEditorService.generatePDFsLinksAndEmail(result.emails, this.multiSelectedTranscriptions, this.selectedProvider?.first_name, this.selectedfacility?.title)

          //this.noteEditorService.generateEncryptedPDFsAndEmail(result.emails, this.multiSelectedTranscriptions, this.selectedProvider?.first_name, this.selectedfacility?.title)
          //this.noteEditorService.generatePDFsAndEmail(result.emails, this.multiSelectedTranscriptions, this.selectedProvider?.first_name, this.selectedfacility?.title)
            .subscribe((response: any) => {
              if (response.status == 200) {
                this.toastr.info('Email sent');
              }
            }, () => {
            }, () => {
              this.multiSelectedTranscriptions = []
              this.multiSelectedTranscriptions.length = 0;
            })
        }
      })
    }
  }
  displayProviderFn(provider): string {
    return provider && provider.first_name ? provider.first_name + " " + provider.last_name + ", " + provider.title : '';
  }
  displayFacilityFn(facility): string {
    return facility?.title ? facility.title : '';
  }
  applyDateFilterTrans($event: any) {
    this.getAllProviderNotes();
  }
  groupTranscriptionsBy(group_index) {
    this.transcriptionsGroupingKey = group_index;
    this.groupedSortedTranscriptions = this.groupItemList(this.sortedTransriptions, group_index);
  }
  groupItemList(itemList, groupByIndex) {
    let timezone = "America/New_York"
    const grouped = {};
    // for (const i in itemList) {
    for (let row of itemList) {
      const item = { ...row };
      if (item.facility_id_ref && item.facility_id_ref.pcc_timeZone) {
        timezone = item.facility_id_ref.pcc_timeZone
      }
      else if (item.facility_id_ref && item.facility_id_ref.timeZone) {
        timezone = item.facility_id_ref.timeZone
      }

      let groupIndex = item[groupByIndex];
      if (groupByIndex.indexOf('date') >= 0) {
        if (groupByIndex == 'date_time') {
          groupIndex = moment(new Date(item.filterServiceDate).toLocaleString('en-US', { timeZone: timezone })).format('MMM DD, YYYY');
        }
        else if (groupByIndex == 'uploading_date') {
          groupIndex = moment(new Date(item.filterUploadingDate).toLocaleString('en-US', { timeZone: timezone })).format('MMM DD, YYYY');

        }
        else if (groupByIndex == 'creation_date') {
          groupIndex = moment(new Date(item.filterCreationDate).toLocaleString('en-US', { timeZone: timezone })).format('MMM DD, YYYY');
        }
        else if (groupByIndex == 'date_of_service') {
          groupIndex = moment(new Date(item.filterServiceDate).toLocaleString('en-US', { timeZone: timezone })).format('MMM DD, YYYY');
        }
      }

      // if invalid key make undefined
      if (!groupIndex || groupIndex == 'Invalid date') {
        groupIndex = 'undefined';
      }

      if (!isArray(grouped[groupIndex])) {
        grouped[groupIndex] = [];
      }
      grouped[groupIndex].push(item);
    }
    for (let key in grouped) {
      // if one non empty found
      if (grouped[key].find(e => !e.empty)) {
        grouped[key] = grouped[key].filter(e => !e.empty);
      }
    }
    return grouped;
  }
  toggleTranscriptionMultiSelect(transcription: any) {
    const i = this.multiSelectedTranscriptions.indexOf(transcription);
    if (i === -1) {
      this.multiSelectedTranscriptions.push(transcription);
    } else {
      this.multiSelectedTranscriptions.splice(i, 1);
    }
  }
  isNotesLoading() {
  }
}
