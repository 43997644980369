import {Component, OnDestroy, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { Location } from '@angular/common';
import {
    TranscriptionDetailService,
    UploadService,
    FilesAdd,
    DownloadService,
    CreatePdfService
} from './transcription-detail.service';
import {DashboardService} from '../dashboard/dashboard.service';
import {ToastrService} from 'ngx-toastr';
import {map, startWith} from 'rxjs/operators';

import {HttpClient, HttpResponse} from '@angular/common/http';
import {Subscription, Observable, Subject, lastValueFrom} from 'rxjs';
import * as global from '../global';
import {FileConversionService} from 'src/app/services/file-conversion.service';
import {EncryptDecryptService} from 'src/app/services/encrypt-decrypt.service';
import {TranscriptionCreateService} from '../transcription-create/transcription-create.service';
import {AuthService} from 'src/app/services/auth.service';
import {DatePipe} from '@angular/common';
import {Lightbox} from 'ngx-lightbox';
import {API_URL} from "../../../environments/api-url";
import {AudioService} from 'src/app/services/Modules/audio.service';
import moment from 'moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NoteEditorService } from '../note-editor/note-editor.service';
import { FormControl } from '@angular/forms';
import { PatientListService } from '../patient-list/patient-list-service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

export interface TranscriptionFile {
    transcribtion_title: string;
    date: string;
    time: string;
    view: string;
}

export interface TranscriptionComment {
    _id: any;
    audio_id: any;
    type: string;
    text_comment: string;
    replying_id: any;
    replying_picture: any;
    date_time: any;
}

export interface OnInit {
    ngOnInit(): void;
}


@Component({
    selector: 'app-transcription-detailr',
    templateUrl: './transcription-detail.component.html',
    styleUrls: ['./transcription-detail.component.css'],
    providers: [DatePipe]
})
 

export class TranscriptionDetailComponent implements OnInit, OnDestroy {
    currentRouteUrl: string;
   loaderId = 'autonotecreate';
    
    public constructor(private router: Router, public TranscriptionDetail: TranscriptionDetailService,
                       private Upload: UploadService, private httpclient: HttpClient, private addfile: FilesAdd, private downloadservice: DownloadService,
                       private createpdfservice: CreatePdfService,
                       private dashboardService: DashboardService,
                       private toastr: ToastrService,
                       private fileConversionService: FileConversionService,
                       private _router: Router,
                       private EncryptDecryptService: EncryptDecryptService,
                       private _transcriptionCreateService: TranscriptionCreateService,
                       private _route: ActivatedRoute,
                       private _authService: AuthService,
                       private _date_pipe: DatePipe,
                       private loader: NgxUiLoaderService,
                       private _noteEditorService: NoteEditorService,
                       private _lightbox: Lightbox,
                       private _audioService: AudioService ,
                       private _location: Location,
                       private _patientsListService: PatientListService,
    ) {
        // this.data = localStorage.getItem('currentUser');
        this.datas = this._authService.currentUser;
        // this.datas = JSON.parse(this.data);
        // console.log(this.data);
    }
    timeUp:Boolean = false;
    chunk_view = false;
    global: any = global;
    public loading = false;
    startTime: any;
    endTime: any;
    details: any;
    filesToUpload: any;
    filedata: any;
    pathfile: any;
    error: string;
    public data: any;
    public datas: any; // current user details
    public dates: any;
    public datasource: any;
    public currentaudio: any;
    public transcribtion: any;
    public commentsArray: any;
    public newUploadedFile: any;
    private subscription: Subscription;
    private audio = new Audio();
    audioCurrentTime: any;
    updatedStatusAudio: any;
    apiUrl = global.url + API_URL.ROUTES.uploadFile;
    response: any;
    editor = '';
    currentUser:any;

    // public uploader: FileUploader = new FileUploader({url: this.apiUrl });


    transcriptionFiles: any[] = [];

    transcriptionComments: TranscriptionComment[] = [];
    album: Array<any> = [];

    async ngOnInit() {
        this.chunkPlay();
        if (!this.datas) {
            // if not login, stop and redirect to login #mohsin-dev
            localStorage.setItem('currentUser', 'null');
            this._router.navigate(['/login']);
            return;
        }
        this.loading = true;
        this.audioCurrentTime = 0;
        if (this.datas === null) {
            localStorage.removeItem('currentUser');
            this.router.navigate(['/login']);
            return;
        }

        const audio_id = this._route.snapshot.paramMap.get('audio_id');
        this.currentaudio = await this.getAudioById(audio_id);
        
        if (this.currentaudio.aws_path.indexOf('s3.amazonaws.com') === -1) {
            this.EncryptDecryptService.decrypt(this.currentaudio.aws_path, 'd7XBUv]Ms<[U{4KH+YyJ<ep5+-mnaE').subscribe(
                data => {

                    this.currentaudio.aws_path = data;
                }, error => {
                }
            );
        }
        await this.populatePatientsList()
        this.loading = false;

        // this.getTranscriptionDetail();
        this.getTranscriptionComments();
    }


    async noteEditor() {
        this.loader.startLoader(this.loaderId);

        let date_obj;
         
          const date = new Date();
          let year: any = date.getFullYear();
          let month: any = date.getMonth() + 1;
          let day: any = date.getDate();
          if (month < 10) {
            month = '0' + month;
          }
          if (day < 10) {
            day = '0' + day;
          }
          date_obj = `${month}-${day}-${year}`;

  
        const noteId: any = await this.createNote();

        console.log("currentaudio : ", this.currentaudio);

        this._router.navigate(['/note-editor/', noteId,{audio_id: this.currentaudio._id}])
      }
      
      createNote() {
        return new Promise<void>(async (resolve, reject) => {
         let note_date_obj = {};
         const currentDate = new Date();
        if(this.currentaudio?.date_obj){
            note_date_obj = this.currentaudio?.date_obj;
        }else if(this.currentaudio?.filterServiceDate){
            console.log(this.currentaudio?.filterServiceDate);
            let filterDate = this.currentaudio?.filterServiceDate.toString().split("T");
            note_date_obj = {
                day: filterDate[0].split("-")[2],
                month: filterDate[0].split("-")[1],
                year: filterDate[0].split("-")[0],
                hour: currentDate.getHours(),
                minute: currentDate.getMinutes(),
              }
        }else{
            note_date_obj = {
              day: currentDate.getDate(),
              month: currentDate.getMonth()+1,
              year: currentDate.getFullYear(),
              hour: currentDate.getHours(),
              minute: currentDate.getMinutes(),
            }
        }
          console.log("this.currentaudio._id : ", this.currentaudio);


          let note = {
            patient: { _id: this.patient._id },
            facility: { _id: this.currentaudio.facility_id_ref?._id },
            date_obj: note_date_obj,
            htmlText: "No Text",
            title: "No Title",
            audio:this.currentaudio,
            dates: { creation: new Date(), service: new Date() },
            provider: { _id: this.currentaudio.uploaded_by_id_ref?._id },
            uploadedBy: { _id: this._authService.currentUser._id }      // current User
          };
          if(this.currentaudio?.rounding_sheet_id){
            note['rounding_sheet_id'] = this.currentaudio?.rounding_sheet_id;
          }
          this._noteEditorService.saveNote(note).subscribe((response: any) => {
            if (response.status === 200) {
              resolve(response.data.noteId);
            }
            else if (response.status === 409) {
              this.toastr.warning(response.message);
              resolve();
              this.loader.stopLoader(this.loaderId);
            }
            else {
              reject();
              this.loader.stopLoader(this.loaderId);
            }
          });
        })
      }

    chunkPlay() {
        this.TranscriptionDetail.chunk.subscribe((x) => {

            let audio = document.getElementById('dictation');
            // @ts-ignore
            audio.currentTime = x;
            // @ts-ignore
            audio.play();
        })
    }

    getServiceDate(audio){
        if(audio && audio.date_obj){
            const { year, month, day, hour, minute } = audio.date_obj;

            let toDay = moment({
                year: year,
                month: month - 1,
                day: day,
                hour: hour,
                minute: minute
            });
            return toDay.format('MMM DD, YYYY h:mm a');
            const _month = month < 10 ? `0${month}` : month;
            const _day = day < 10 ? `0${day}` : day;
            return `${_month}-${_day}-${year} ${hour}:${minute}`;
        }else{
            if(audio && audio.filterServiceDate){
                return moment(new Date(audio.filterServiceDate).toLocaleString('en-US', { timeZone:  audio.timezone })).format('MMM DD, YYYY h:mm a');
                // return moment(new Date(audio.filterServiceDate)).format('MMM DD, YYYY h:mm a');
            }
            else{
                return '';
            }
        }
    } 
    getCreationDate(audio){ 
        if (audio && audio.uploading_date) {
            let time = moment(new Date(audio.uploading_date)).format('MMM DD, YYYY h:mm a');
            return time;
        }
        else {
            return '';
        }
    }
    getEndTimeForTimer(audio){ 
        if(audio && audio.filterUploadingDate){ 
            let time = moment(new Date(audio.filterUploadingDate).toLocaleString('en-US', { timeZone:  audio.timezone })).add(1,'days');
            return time.toString()
        }
        else{
            return '';
        }
    }
    getStratTimeForTimer(audio){ 
        if(audio && audio.filterUploadingDate){ 
            let time = moment(new Date(audio.filterUploadingDate).toLocaleString('en-US', { timeZone:  audio.timezone }));
            return time.toString()
        }
        else{
            return '';
        }
    }
    triggerFunction(){
        this.timeUp = true;
    }
    isProvider(){
        if(this.datas.user_type == '1'){
            return true;
        }
        else
        return false;
    }
    async getAudioById(audio_id) {
        const filter = {
            _id: audio_id
        };
        const projection = {}
        const populate = {
            uploaded_by_id_ref: ['_id', 'first_name', 'last_name'],
            facility_id_ref: ['_id', 'title'],
            patient_id_ref: ['_id', 'first_name', 'middle_name', 'last_name'],
        }
        // const audioDetailResponse:any = await this._audioService.getAudio(filter, projection, populate).toPromise();
        const audioDetailResponse: any = await this.dashboardService.getAudioDetailById(audio_id, this._authService.currentUser.id, this._authService.currentUser.auth_key, this._authService.currentUser.usertype).toPromise()

        if (audioDetailResponse.status === 200) {
            if (audioDetailResponse.data.is_trashed === 'false') {
                return audioDetailResponse.data;
            }
        }

        this.toastr.error("Audio does not exist", 'Error');
        setTimeout(() => this.router.navigate(['/dashboard']));
    }

    // getTranscriptionDetail(){
    //   console.log('in getTranscriptionDetail function');
    //   this.transcriptionFiles = [];
    //   this.TranscriptionDetail.getTranscriptionDetail(this.datas._id, this.datas.auth_key, this.datas.user_type,
    //     this.currentaudio._id).subscribe(data => {
    //     this.datasource = data;
    //     this.transcriptionFiles = this.transcriptionFiles.concat(this.datasource.data.array);
    //     let statusFilter;
    //     if(this.datas.user_type == global.USER_TYPE.DOCTOR) {
    //       statusFilter =  item => item.is_active == 'true' && item.is_published == 'true';
    //     } else {
    //       statusFilter =  item => item.is_active == 'true';
    //     }
    //     this.transcriptionFiles = this.transcriptionFiles.filter(statusFilter);
    //   });
    // }
    getTranscriptionComments() {
        this.currentaudio && this.TranscriptionDetail.getTranscriptionComments(this.datas._id, this.datas.auth_key, this.datas.user_type,
            this.currentaudio._id).subscribe(data => {
            this.commentsArray = data;
            this.transcriptionComments = this.commentsArray.data.array;
            setTimeout(this.scrollToBottom)
        });
    }

    addComment(comment) {
        const createdAt = this._date_pipe.transform(new Date(), global.date_time_format);
        comment = comment.trim();
        if (comment.length === 0) return;

        this.currentaudio && this.TranscriptionDetail.saveTranscriptionComments(
            this.datas._id, this.datas.auth_key, this.datas.user_type,
            this.currentaudio._id, createdAt, comment, this.datas.profile_pic, global.TEXT
        ).subscribe((response: any) => {
            if (response.status === 200) {
                //   const comment = {
                //     _id: this.datas._id,
                //     audio_id: this.currentaudio._id,
                //     type: global.TEXT,
                //     text_comment: this.editor,
                //     replying_id: this.datas._id,
                //     replying_picture: this.datas.profile_pic,
                //     date_time: new Date(),
                // }
                //   this.transcriptionComments.push(comment);
                this.editor = '';
                this.getTranscriptionComments();
            }
        })

    } 

    scrollToBottom() {
        const commentContainer = document.querySelector('.transcription-comment-tab');
        if (commentContainer) {
            commentContainer.scrollTop = commentContainer.scrollHeight
        }
    };

    makeFileRequest(url: string, params: Array<File>, files: Array<File>, filetitle: any) {
        const type = '13';
        return new Promise<void>((resolve, reject) => {
            const formData: any = new FormData();
            const xhr = new XMLHttpRequest();
            formData.append('pdf', files[0]);
            /** In Angular 5, including the header Content-Type can invalidate your request */
            formData.append('id', this.datas._id);
            formData.append('auth_key', this.datas.auth_key);
            formData.append('user_type', this.datas.user_type);
            formData.append('type', type);
            formData.append('title', filetitle);
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        //resolve(JSON.parse(xhr.response));
                        resolve();
                    } else {
                        reject(xhr.response);
                    }
                }
            };
            xhr.open('POST', url, true);
            xhr.send(formData);
        });
    }

    upload(params: Array<File>) {
        this.loading = true;
        const title = params[0].name.replace(/ /g, "_");
        var extension = title.split('.');
        this.makeFileRequest(global.url + API_URL.ROUTES.uploadFile, [], params, title).then((result) => {
            this.pathfile = result;
            this.loading = false;
            if (this.pathfile.status == 200) {
                this.transcribtion = {
                    id: <string>null,
                    user_type: <string>null,
                    auth_key: <string>null,
                    transcribtion_title: <string>null,
                    audio_id: <string>null,
                    date_of_service: <string>null,
                    uploaded_by_id: <string>null,
                    is_signed: <string>null,
                    doctor_id: <string>null
                };
                this.transcribtion.transcribtion_title = title,
                    this.transcribtion.id = this.datas._id,
                    this.transcribtion.user_type = this.datas.user_type;
                this.transcribtion.auth_key = this.datas.auth_key;
                this.transcribtion.audio_id = this.currentaudio._id,
                    this.transcribtion.date_of_service = this.formattedDataGenarator(),
                    this.transcribtion.uploaded_by_id = this.datas._id,
                    this.transcribtion.is_signed = (extension[extension.length - 1] == 'pdf') ? 'true' : 'false';
                this.transcribtion.doctor_id = this.currentaudio.uploaded_by_id;

                this.addfile.fileadd(this.transcribtion).subscribe(data => {
                    this.changeCurrentAudioStatus(20);
                    this.newUploadedFile = data;
                    if (this.newUploadedFile.status == 200) {
                        // this.getTranscriptionDetail();
                        this.toastr.success('Success', this.newUploadedFile.message);
                        this.dashboardService.getUserDetail(this.currentaudio.uploaded_by_id).subscribe(data => {
                            this.response = data;
                            var emailDetails = {
                                email: this.response.data.array.email,
                                text: "You got new note request \r <a href='" + global.app_base_url + "'>Click here</a> to visit the DN portal.",
                                subject: this.transcribtion.transcribtion_title
                            };
                            this.dashboardService.sendNewDictaionRequestEmail(emailDetails).subscribe(data => {
                                console.log(data)
                            });
                        });
                    } else {
                        this.toastr.error('Failed', this.newUploadedFile.message);
                    }


                });
            } else {
                this.toastr.error('Failed', this.newUploadedFile.message);
            }

        }, (error) => {
            this.loading = false;
            this.toastr.error('Failed', 'Uploading failed');
        });

    }

    fileChangeEvent(fileInput: any) {
        this.filesToUpload = <Array<File>>fileInput.target.files;
        this.upload(this.filesToUpload);
    }

    /**
     * onChange upload file event
     * Converts docx file to and redirects to create dictation page and initializes editor with converted text
     * #mohsin-dev
     */
    uploadFileAndCreateTranscription(event) {
        const userInfo = {
            id: this.datas._id,
            auth_key: this.datas.auth_key,
            userType: this.datas.user_type
        }
        const [file] = <Array<File>>event.target.files;
        var filename = file.name;
        this.fileConversionService
            .makeFileRequest(userInfo, global.url + API_URL.ROUTES.uploadFile, file, filename)
            .then((result: any) => {
                if (result.status == 200) {
                    localStorage.setItem('convertedHTML', result.data);
                    this.router.navigate(['/transcription-create', {
                        category: 'dict',
                        new: 1,
                        audio_id: this.currentaudio._id
                    }])
                } else {
                    console.error(result.message)
                }
            }, console.error);
    }

    download(downloadType, recording) {
        console.log(recording);
        // fixed mp3/mp4 file naming to audio_file_name and dictation title #mohsin-dev
        const dateObj = new Date(recording.date);
        const date = `${dateObj.getDate()}-${dateObj.getMonth()}-${dateObj.getFullYear()}`;
        const file_name = (`${recording.audio_title}_${recording.uploader_name}_${recording.facility_title}_${date}`).replace(/\s/g, '_');
        console.log(file_name);
        let downloadResponse;
        let infoToastr: any;
        if (downloadType == 'mp4') {
            recording.file_name = file_name + '.mp4';
            infoToastr = this.toastr.info("Converting audio file...", "Please wait", {
                disableTimeOut: true
            });
            downloadResponse = this.dashboardService
                .downloadFile(global.url + API_URL.ROUTES.downloadMP4File, {recording})
            // this.downloadFile(global.url + '/downloadMP4File',recording);
        } else {
            // Added toastr notification for file conversion #mohsin-dev
            infoToastr = this.toastr.info("Converting audio file...", "Please wait", {
                disableTimeOut: true
            });
            downloadResponse = this.dashboardService
                .downloadFile(global.url + API_URL.ROUTES.convertMp4ToMp3Anddownload, {recording})

            // this.dashboardService.convertMp4ToMp3Anddownload(recording).subscribe(data => {
            //   this.toastr.clear(infoToastr.toastrId);
            //   recording.file_name = file_name + '.mp3';
            //     // this.downloadFile(global.url + '/downloadMP3File',recording);
            //   });
        }
        downloadResponse.subscribe((res: any) => {
            console.log(res)
            if (infoToastr) {
                this.toastr.clear(infoToastr.toastrId)
            }

            if (res instanceof HttpResponse) {
                // this.downloadProgress.show = false;
                localStorage.removeItem('downloadInProgress');
                // saveAs(res, fileName);
                console.log("Should download start")
                var url = window.URL.createObjectURL(res.body);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                console.log(url)
                a.download = `${recording.audio_title}.${downloadType}`;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
            } else {
                if (res.type === 0) {
                } else if (res.type === 3) {
                    const {loaded, total} = res;
                    const progress = Math.round(loaded / total * 100);
                    // console.log(res.data.loaded);
                }
            }
        })
    }

    downloadFile(url, params) {
        const infoToastr: any = this.toastr.info("Preparing file download", "Please wait...", {
            disableTimeOut: true
        })

        return this.httpclient
            .get(url, {
                responseType: 'blob',
                params: {
                    ...params
                }
            }).pipe(
                map(res => {
                    return {
                        filename: params.file_name,
                        data: res
                    };
                }))
            .subscribe((res: any) => {
                this.toastr.clear(infoToastr.toastrId)
                console.log(res);
                var url = window.URL.createObjectURL(res.data);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element

            }, error => {
                //console.log('download error:', JSON.stringify(error));
            }, () => {
                console.log('Completed file download.');
            });
    }

    selectedfile(file) {
        localStorage.setItem('selectedFile', JSON.stringify(file));

        this.initTranscriptionFiles();

        localStorage.setItem('backURL', '/transcription-detail');
    }

    initTranscriptionFiles() {
        const transcriptionFiles = this.transcriptionFiles; 
        localStorage.setItem('transcriptionFiles', JSON.stringify(transcriptionFiles)); // for next and prev buttons
    }

    /*
    * Unsign Transcription #mohsin-dev
    */
    async unSignTranscription(transcription, category) {
        transcription.is_signed = 'false';
        transcription.transcribtion_id = transcription._id;
        transcription.id = this.datas._id;
        transcription.auth_key = this.datas.auth_key;
        transcription.usertype = this.datas.user_type;
        const toastInfo = this.toastr.info("Unsigning...", "Please wait", {disableTimeOut: true});
        const response: any = await this._transcriptionCreateService.unSignTranscription(transcription, category).toPromise();
        if (response.status === 200) {
        } else {
            this.toastr.error(response.message, "Failed");
        }
        this.toastr.clear(toastInfo.toastId);
    }


    async completeTranscription(transcription) {
        transcription.is_completed = 'true';
        transcription.transcribtion_id = transcription._id;
        transcription.id = this.datas._id;
        transcription.auth_key = this.datas.auth_key;
        transcription.usertype = this.datas.user_type;
        const toastInfo = this.toastr.info("Processing...", "Please wait", {disableTimeOut: true});
        const response: any = await this._transcriptionCreateService.completeTranscription(transcription).toPromise();
        if (response.status === 200) {

        } else {
            this.toastr.error(response.message, "Failed");
        }
        this.toastr.clear(toastInfo.toastId);
    }

    async revertTranscription(transcription, category) {
        transcription.is_completed = 'false';
        transcription.transcribtion_id = transcription._id;
        transcription.id = this.datas._id;
        transcription.auth_key = this.datas.auth_key;
        transcription.usertype = this.datas.user_type;
        const toastInfo = this.toastr.info("Processing...", "Please wait", {disableTimeOut: true});
        const response: any = await this._transcriptionCreateService.completeTranscription(transcription).toPromise();
        if (response.status === 200) {

        } else {
            this.toastr.error(response.message, "Failed");
        }
        this.toastr.clear(toastInfo.toastId);
    }

    async deleteTranscription(transcription, category) {
        if (!confirm("Are you sure you want to delete?")) return;


        transcription.is_active = 'false';
        transcription.transcribtion_id = transcription._id;
        transcription.id = this.datas._id;
        transcription.auth_key = this.datas.auth_key;
        transcription.usertype = this.datas.user_type;
        const toastInfo = this.toastr.info("Deleting...", "Please wait", {disableTimeOut: true});
        const response: any = await this._transcriptionCreateService.deleteTranscription(transcription, category).toPromise();
        if (response.status === 200) {
        } else {
            this.toastr.error(response.message, "Failed");
        }
        this.toastr.clear(toastInfo.toastId);
    }

    docxFile(detail) {
        if (!detail.path) return false;
        var path = detail.path.split('.');
        if (path[path.length - 1] == 'docx') {
            return true;
        } else {
            return false;
        }
    }

    downloadall() {
        this.datasource.data.array.forEach(value => {
            this.downloadservice.download(value);
        });
    }

    playAudio() {
        this.audio.src = this.currentaudio.aws_path;
        this.audio.load();
        if (this.audio.currentTime >= this.audio.duration) {
            this.audioCurrentTime = 0;
        }
        this.audio.currentTime = this.audioCurrentTime;     // sets the current position of audio
        this.audio.play();
    }

    pauseAudio() {
        this.audioCurrentTime = this.audio.currentTime;
        this.audio.pause();
    }

    changeCurrentAudioStatus(statusId) {
        this.createpdfservice.changeCurrentAudioStatus(this.currentaudio, statusId).subscribe(audiObj => {
            this.updatedStatusAudio = audiObj;
            if (this.updatedStatusAudio.status === 200) {
                this.currentaudio.status = (this.updatedStatusAudio.data.progress_status === '19') ? 'IN PROGRESS' : 'COMPLETED';
            }

        });
    }

    formattedDataGenarator() {
        var date = new Date();
        var monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Oct', 'Nov', 'Dec'];
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        var minutesCon = minutes < 10 ? '0' + minutes : minutes;
        var time = hours + ':' + minutesCon + ' ' + ampm;
        var formatted = monthList[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear() + " " + time;
        return formatted;
    }

    /*
    * Export as PDF #mohsin-dev
    */
    async downloadAsPDF(transcription) {
        /**
         * FLOW: Decrypt Transcript Text => Generate PDF => Get Doctor Name => Generate File Name => Download PDF File
         */
        const infoToastr = this.toastr.info("Generating PDF", "Please wait...", {disableTimeOut: true});

        try {
            transcription.transcribtion_text = await this.EncryptDecryptService.decrypt(transcription.transcribtion_text).toPromise();
            const generatePDFResponse: any = await this.dashboardService.generatePDF(transcription).toPromise();
            if (generatePDFResponse.status !== 200) throw new Error(generatePDFResponse.message);

            const doctorUserDetailsResponse: any = await this.dashboardService.getUserDetail(transcription.doctor_id).toPromise();
            if (doctorUserDetailsResponse.status !== 200) throw new Error(doctorUserDetailsResponse.message);

            const doctor = doctorUserDetailsResponse.data.array;
            const doctorName = doctor.first_name + ' ' + doctor.last_name;
            const file_name = transcription.transcribtion_title + '_' + this.currentaudio.facility_title + '_' + doctorName;
            this.downloadFile(global.url + '/downloadAsPDF', {
                file_name: file_name
            });
        } catch (error) {
            const {message = "Something went wrong, please try again"} = error;
            this.toastr.error(message);
        } finally {
            this.toastr.clear(infoToastr.toastId);
            this.toastr.success("Downloaded successFully")
        }
    }

    openImage(comment) {
        console.log(comment)
        this.album = [{
            src: comment.path
        }];
        this._lightbox.open(this.album, 0);
    }

    ngOnDestroy() {
        if (this.audio) {
            this.audio.pause();
        }
    }
    goBack(){
        this._location.back();
    }
    filteredPatients: Observable<any[]>;
    patientControl = new FormControl();
    public patients: any;
    patient: any;
    @ViewChild('patientAutoComplete', { read: MatAutocompleteTrigger }) patientAutoComplete: MatAutocompleteTrigger;

    async populatePatientsList() {
        if (this.currentaudio.facility_id_ref?._id) {
            // await this.getFacilityPatientsByProviderId(this.note.provider._id, this.note.facility._id);
            await this.getFacilityPatients(this.currentaudio.facility_id_ref?._id);
        }
        this.filteredPatients = this.patientControl.valueChanges
            .pipe(
                startWith(''),
                map((value:any) => {
                    if (value) {
                        return typeof value === 'string' ? value : value.name
                    }
                    return '';
                }),
                map(name => name ? this._filterPatient(name) : this.getFilteredPatients()),
            );
    }

    async getFacilityPatients(facilityId) {
        // const companyId = this.currentUser.company_id;
        const companyId = this.datas.company_id;
        // const { data, status } = await lastValueFrom(this._patientsListService.getPatientsV2OnlyPatient(companyId, facilityId)) as any;
        const { data, status } = await lastValueFrom(this._patientsListService.getPatientsV2OnlyPatient(companyId, facilityId,null, null)) as any;

        if (status === 200) {
            this.patients = data.array;
        }
    }

    private _filterPatient(name): any[] {
        const filterValue = name.toLowerCase();

        return this.getFilteredPatients().filter(patient => patient.name.toLowerCase().indexOf(filterValue) === 0);
    }

    getFilteredPatients() {

        if (!Array.isArray(this.patients)) return [];

        const facility_id =this.currentaudio.facility_id_ref?._id;

        const portalPatients = [];
        const pccPatients = [];

        let resultPatients = [];
        resultPatients = this.patients;

        if (facility_id) {
            resultPatients = resultPatients.filter(p => p.facility_id === facility_id);
        } else {
            resultPatients = [];
        }
        return resultPatients;
    }

    displayPatientFn(patient): string {
        return patient && patient.name ? patient.name : '';
    }
}
