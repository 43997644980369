import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CKEditorService } from '../note-editor/ckeditor.service';

export interface Notes {
  notes: String;
}

@Component({
  selector: 'app-open-release-dialog',
  templateUrl: './open-release-dialog.component.html',
  styleUrls: ['./open-release-dialog.component.css']
})
export class OpenReleaseDialogComponent implements OnInit {
  cke4Config;
  addNotes: any;

  constructor(public dialogRef: MatDialogRef<OpenReleaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Notes,
    private ckeditorService: CKEditorService) {

    this.cke4Config = {
      ...this.ckeditorService.getDefaultConfig(),
      editable: true
    };
    if (data) {
      this.addNotes = data;
      // console.log("data",data);
    }
  }

  ngOnInit(): void {
  }

  onReadyCkeditor(e) {
    const {editor} = e;
    
    this.ckeditorService.ckeditorRemoveTitle(editor)
}

}