import { Component, OnInit } from '@angular/core';
import {DoctorFeeStructure} from '../../../classes/doctor_fee_structure'
import { DoctorProfileService } from 'src/app/services/Modules/doctorProfile.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/Modules/user.service';
import { AppointmentDataService } from '../../appointment/service/data.service';
import { Payor } from 'src/app/classes/payor';
@Component({
  selector: 'app-doctor-profile-fee-structure',
  templateUrl: './doctor-profile-fee-structure.component.html',
  styleUrls: ['./doctor-profile-fee-structure.component.css']
})
export class DoctorProfileFeeStructureComponent implements OnInit {
  imeCompanies:Payor[] = [];


  model:DoctorFeeStructure;

  doctorFeeStructureLoader = 'doctor-fee-structure-loader';

  constructor(private doctorProfileService:DoctorProfileService, 
    private loader: NgxUiLoaderService,
    private route: ActivatedRoute,
    private userService:UserService,
    private appointmentDataService: AppointmentDataService) {

    this.model = new DoctorFeeStructure();
  }


  async ngOnInit() {
    const provider_id = this.route.snapshot.paramMap.get('provider_id');

    const filter = {
      _id: provider_id
    }
    const projection = {
      user_type: 1
    }
    const userResponse:any = await this.userService.getUser(filter, projection).toPromise();
    if(userResponse.status === 200) {
      this.initFeeStructure(userResponse.data);
    }
    this.initImeCompanies();
  }

  initFeeStructure(user) {
    this.model.user_id_ref = user._id;
    this.model.user_type = user.user_type;
    this.loader.startLoader(this.doctorFeeStructureLoader);

    const filter = {
      user_id_ref: user._id
    }
    this.doctorProfileService.getFeeStructure(filter,{}).subscribe((response:any) => {
      if(response.status === 200 && response.data) {
        this.model = response.data;
      } 
    }, (error) => {
      console.error('Error occured', error.toString());
      
    }, () => {
      this.loader.stopLoader(this.doctorFeeStructureLoader);
    })
  }
  initImeCompanies() {
    this.appointmentDataService.getPayors().subscribe((response:any) => {
      if(response.status === 200) {
        this.imeCompanies = response.data.array;
      }
    })
  }
  handleSubmit(values) {
    this.loader.startLoader(this.doctorFeeStructureLoader);
    
    this.doctorProfileService.saveFeeStructure(this.model).subscribe((response:any) => {
    }, (error) => {
      console.error('Error occured', error.toString());
      
    }, () => {
      this.loader.stopLoader(this.doctorFeeStructureLoader);
    })
  }

}
