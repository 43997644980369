import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-census-column',
  templateUrl: './census-column.component.html',
  styleUrls: ['./census-column.component.css']
})
export class CensusColumnComponent implements OnInit {
  @Input('company') company;
  @Output() exit = new EventEmitter<boolean>();
  @Output() updateCurrentCompany = new EventEmitter<boolean>();
  constructor(private authService: AuthService, private companyService: CompanyService, private toastr: ToastrService) { }
  company_id: any;
  census_columns: any;
  currentUser: any;
  census: Boolean = false;
  allcolumns = ['operations', 'name', 'note', 'comments_count', 'admission_date', 'date_of_birth', 'discharge', 'location', 'payer', 'mrn', 'last_seen', 'pcc_patient_diagnosis', 'pcp'];
  async ngOnInit(): Promise<void> {
    this.currentUser = this.authService.currentUser;
    this.company_id = this.company._id;
    this.census_columns = ['operations', 'name', 'note'];
    if (this.company_id) {
      this.companyService.getCensusColumnSetting(this.company_id).subscribe((res: any) => {
        if (res.data?.length) {
          this.census_columns = [...res.data, ...this.census_columns];
        } else {
          this.census_columns = ['operations', 'name', 'note'];
        }
        this.census_columns = Array.from(new Set(this.census_columns));
      })
    }
  }
  handlecensus_columnsCheckboxes($event) {
    if ($event.source.checked) {
      let value = $event.source.value.toString();
      let index = this.census_columns?.findIndex(e => e?.toString() == value?.toString());
      if (index >= 0) {
      } else {
        this.census_columns.push(value);
      }
    } else if (!$event.source.checked) {
      let value = $event.source.value.toString();
      this.census_columns = this.census_columns?.filter(e => e != value);
    }
  }
  checkExistence(value) {
    return this.census_columns?.findIndex(e => e?.toString() == value?.toString()) >= 0 ? true : false;
  }
  setcensus_columns() {
    const infoToast = this.toastr.info('Updating Settings...', 'Please wait', { disableTimeOut: true });
    this.companyService.updateCensusColumnSetting(this.census_columns, this.company_id).subscribe((res: any) => {
      if (res.status == 200) {
        this.toastr.success(res.message, 'Success');
        this.toastr.clear(infoToast.toastId)
      } else {
        this.toastr.error(res.message, 'Failed');
        this.toastr.clear(infoToast.toastId)
      }
    })
  }
  goBack() {
    this.exit.emit(false);
    this.updateCurrentCompany.emit(this.company);
  }
}
