import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { Pagination } from "./pcc-patient-list.component";


@Injectable({
    providedIn: 'root',
})
export class PCCPatientListService {
    private filterFormGroupValue = new BehaviorSubject<any>(
        {
        });
    filterFormGroupValue$ = this.filterFormGroupValue.asObservable();

    changeFilterFormGroupValue(val) {
        this.filterFormGroupValue.next(val);
    }

    private pagination = new BehaviorSubject<Pagination>({
        page: 1,
        pageSize: 200,
        hasNextPage: false,
        hasPrevPage: false
      });
    pagination$ = this.pagination.asObservable();

    changePagination(val) {
        this.pagination.next(val);
    }


}