<app-header></app-header>

<main>
    <div class="container">
      <div class="dark-blue-heading flex justify-between items-center">
        <h3>All Notifications</h3>
        </div>
        <div class="flex justify-center" *ngIf="!notificationsLoaded">
          <mat-progress-spinner diameter="50" mode="indeterminate">
          </mat-progress-spinner>
        </div>
        <mat-card class="notifications-list"  *ngIf="notificationsLoaded && notifications.length === 0">
            <mat-card-content >
              <div style="min-height: 60px;
              display: flex;
              justify-content: center;
              align-items: center;">
                No notifications to show.
              </div>
              </mat-card-content>
        </mat-card>
          <div *ngIf="notifications.length > 0">
            <mat-card class="notifications-list notification-card"  [class.unreadNotification]="notification.seenBy.indexOf(currentUser._id) === -1" 
            *ngFor="let notification of notifications">
              <mat-card-content class="card-content">
                <div class="notification-item">
                    <div class="notification-description">
                      <div class="notification-icon">
                        <mat-icon>notifications_none</mat-icon>
                      </div>
                      <div (click)="openURL(notification)">
                        <span style="font-weight: 600; font-size: 16px;"  *ngIf="notification.createdById">{{notification.createdById['first_name']}} {{notification.createdById['last_name']}}</span>
                       <div style="font-weight: 500; font-size: 12px;" >Click here to view the notification. </div>
                      </div>
                    </div>
    
                    <div class="notification-end">
                      <div class="notifcation-date">{{ notification.createdAt | date: 'dd/MM/yyyy' }}</div>
                      <div style="display: flex;align-items: center; justify-content: center; margin: 5px;">
                      </div>
                    </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
      </div>
</main>