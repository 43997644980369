
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { PhrasesService } from '../phrases.service';
import { DictionaryElement } from '../user-defined-phrases/user-defined-phrases.component';

@Component({
  selector: 'app-wound-pharases',
  templateUrl: './wound-pharases.component.html',
  styleUrls: ['./wound-pharases.component.css']
})
export class WoundPharasesComponent implements OnInit {


  dictionary: Array<DictionaryElement>;
  company_id: any;
  @Input() note: any;
  currentUser: any;

  constructor(
    private phrasesService: PhrasesService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.currentUser = this.authService.currentUser;
  }
  async ngOnInit(): Promise<void> {

    if (window.location.pathname.includes('company')) {
      this.company_id = this.route.parent.snapshot.params.id;
    }
    else {
      // this.company_id = '';
      this.company_id = this.currentUser.company_id;
    }


    console.log("hello : ", /^(wound|kent)/m);
    // await this.phrasesService.getWoundPhrases_old(this.note.patient._id).subscribe((res:any)=>{
    await this.phrasesService.getDynamicPhrases_old('wound|kent', { patient_id: this.note.patient?._id }).subscribe((res: any) => {

      this.dictionary = res.data.array;
      console.log("data :  result, ", res.data.array);

    })


    if (this.dictionary?.length > 0) {
      this.sortPhrases(this.dictionary);
    }
  }


  getMarker() {
    return this.phrasesService.marker
  }
  sortPhrases(arr: any[]) {
    arr.sort((a, b) => a.key.toLowerCase().localeCompare(b.key.toLowerCase()));
  }
}

