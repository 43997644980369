<div class="flex flex-col">
    <mat-tab-group class="flex-grow" #tabGroup (selectedTabChange)="tabChange($event)">
        <mat-tab label="Diagnosis">
            <app-sidebar-diagnoses *ngIf="showICDs" [note]="note" [charge]="charge" [noteEditorIcds]="noteEditorIcds"></app-sidebar-diagnoses>
        </mat-tab>
        <mat-tab label="Charges">
            <app-sidebar-cpts *ngIf="showCPTs" [charge]="charge"></app-sidebar-cpts>
            <ng-container *ngIf="reportMeasure">
                <app-sidebar-mips  [note]="note">  </app-sidebar-mips>
            </ng-container>
        </mat-tab>
        <mat-tab label="Comments">
            <app-comments></app-comments>
        </mat-tab>
    </mat-tab-group>

    <div style="margin: 10px;">
        <app-biller-note (reset)="reset()" 
        [note]="note"
        [charge]="charge"
        [comment]='comment'  
        ></app-biller-note>
    </div>
    <app-mips-questionnaire-results *ngIf="charge" [charge]="charge"></app-mips-questionnaire-results>
</div>