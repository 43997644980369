import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/Modules/user.service';
import { TicketService } from './ticket.service';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit {
  showDetail: boolean = false;
  sideBar: boolean = false;
  dataSource: any = new MatTableDataSource([])
  displayedColumns: string[] = ['Ticket No.', 'Title', 'Updation Date', 'Priority', 'CreatorName', 'patientName', 'company', 'Status'];
  rowData: any;
  selectedStatus = 'pending';
  selectedPriority = '';
  allCompanies;
  selectedCompany = '';
  selectedCompanyName='All';
  // selectedThree = '';
  selectedUserName = '';
  filteredProvider: Observable<string[]>;
  pagesize = 50;
  lastpagecount = 0;
  updatepage = 0;
  pageNo = 1;

  public paginationTicketsAll: {
    hasNextPage: boolean,
    hasPrevPage: boolean,
    totalCount: number,
    totalPages: number
  };
  totalTickets: any = 0;
  ticketsData: any = [];
  totalTicketsCount = 0;

  params: {
    id: string
  }
  users: any = [];
  userTypeChanged: boolean = false;
  selectedUserType: any;
  filter: {};
  selectedUser: any;

  filteredUsers: Observable<string[]>;
  userControl = new FormControl();

  displayCommentSidenav: boolean = false;
  currentUser: any;
  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    PAGE_LIMIT: 50,
    skip: 0
  };
  constructor(private ticketService: TicketService, private route: ActivatedRoute, private userService: UserService,private companyService: CompanyService,private toast: ToastrService,
      private authService: AuthService
    ) {
    this.paginationTicketsAll = {
      hasNextPage: false,
      hasPrevPage: false,
      totalCount: 0,
      totalPages: 0
    };
    this.currentUser = this.authService.currentUser;
    this.params = {
      id: this.route.snapshot.parent.params.id
    };

  }

  ngOnInit() {
    if(this.currentUser.product_owner) {
      this.getTickets();
      this.getCompanies();
    }
    else {
      this.filterCompany(this.currentUser.company_id);
    }
    this.initUsers();
  }

  private filterProvider(value: string): string[] {
    if (value && this.users.length > 0) {
      const filterValue = value.toLowerCase();
      console.log('filterValue', filterValue);
      // return this.users.filter(option => (option.middle_name && option.middle_name.toLowerCase().indexOf(filterValue) === 0) || option.last_name.toLowerCase().indexOf(filterValue) === 0 || option.first_name.toLowerCase().indexOf(filterValue) === 0);
      return this.users.filter(option => (option.full_name.toLowerCase().indexOf(filterValue) > -1));
    }
    else {
      return this.users;
    }
  }

  getTickets() {
    return new Promise<void>((resolve, reject) => {
      let company_id;
      if(!this.currentUser.product_owner) {
        company_id = this.currentUser.company_id
      }
      this.ticketService.getTickets(this.pagination.pageNo, null, this.selectedStatus, this.selectedPriority, company_id).subscribe((Response: any) => {
        if (Response.status == 200) {
          resolve(null);
          this.pagination.totalRecords = Response.TotalTickets;
          this.ticketsData = Response.data;
          // console.log('Response', Response);
          this.dataSource.data = this.ticketsData;
          this.handlePagination();
          // this.totalTicketsCount = Response.data.length;
          // this.totalTickets = Response.TotalTickets;
          // this.getTotalTickets();
          // this.filters(this.selectedStatus,this.selectedPriority,this.selectedUserName);
        } else {
          console.log('Response in else', Response);
        }
      }, error => {
        reject('error => ' + error.toString());
      });
    });
  }

  handlePagination() {
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    if (this.pagination.skip + this.ticketsData.length < this.pagination.totalRecords) {
      this.pagination.hasNextPage = true;
    }
    else {
      this.pagination.hasNextPage = false;
    }
    if (this.pagination.skip > 0) {
      this.pagination.hasPrevPage = true;
    }
    else {
      this.pagination.hasPrevPage = false;
    }
  }

  goToPreviousPage() {
    this.pagination.pageNo--;
    this.pagination.hasPrevPage = false;
    this.getTickets();
  }
  goToNextPage() {
    this.pagination.pageNo++;
    this.pagination.hasNextPage = false;
    this.getTickets();
  }

  initUsers = async () => {
    this.filter = {
        is_activated: 'true',
        user_type: { $ne: 'user' },
    }
    
    const projection = {
      'first_name': 1,
      'last_name': 1,
      'title': 1,
    }

    const companyUsersResponse: any = await this.userService.getUsers(this.filter, projection).toPromise();
    if (companyUsersResponse.status === 200) {
      this.users = companyUsersResponse.data.map(p => Object.assign(p, {
        full_name: `${p.first_name} ${p.last_name}, ${p.title}`
      }));
      this.filteredUsers = this.userControl.valueChanges.pipe(
        startWith(''),
        map((value:any) => this.filterProvider(value)));
    }
  }

  getTotalTickets() {
    this.updatepage = this.pageNo - 1 // 1-1 = 0
    this.lastpagecount = this.updatepage * this.pagesize; // 0 * 3 = 0
    this.lastpagecount = this.lastpagecount + this.totalTicketsCount; // 0 + 3 = 3

    return this.lastpagecount, this.totalTickets;
  }

  changeStatus($event, element) {
    this.ticketService.changeStatus(element._id, $event.value).subscribe((Response: any) => {
      if (Response.status == 200) {
        this.filters(this.selectedStatus, this.selectedPriority, this.selectedUserName,this.selectedCompany);
        console.log('Reponse', Response);
      }
    })

  }
  filterStatusParent($event){
    this.filters(this.selectedStatus, this.selectedPriority, this.selectedUserName,this.selectedCompany);
  }
  async filterStatus($event) {
    this.pagination = {
      pageNo: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalRecords: 0,
      PAGE_LIMIT: 50,
      skip: 0
    };
    // this.dataSource.filter = $event.value.trim().toLowerCase();
    // if ($event.value == '') {
    //   await this.getTickets();
    // } else {
    this.filters(this.selectedStatus, this.selectedPriority, this.selectedUserName,this.selectedCompany);
    // this.ticketService.filterStatus($event.value).subscribe((Response: any) => {
    //   if (Response.status == 200) {
    //     console.log('Reponse', Response);
    //     this.dataSource.data = Response.data;
    //   }
    // });
    // }
  }

  async changePriority($event) {
    this.pagination = {
      pageNo: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalRecords: 0,
      PAGE_LIMIT: 50,
      skip: 0
    };
    // // this.dataSource.filter = $event.value.trim().toLowerCase();
    // if ($event.value == '') {
    //   await this.getTickets();
    // } else {
    this.filters(this.selectedStatus, this.selectedPriority, this.selectedUserName,this.selectedCompany);
    // this.ticketService.changePriority($event.value).subscribe((Response: any) => {
    //   if (Response.status == 200) {
    //     console.log('Reponse', Response);
    //     this.dataSource.data = Response.data;
    //   }
    // });
    // }
  }
  async getCompanies() {
    const response: any = await this.companyService.getAllCompanies().toPromise();
    if (response.status == 200) {
      this.allCompanies = response.data;
    }
    else if (response.status == 403) {
      this.toast.error(response.message, "ERROR");
    }
  }

  async filterCompany(company=null) {
    if (company) {
      this.selectedCompany = company._id || company;
      this.selectedCompanyName=company.name || '';

    } else {
      this.selectedCompany='';
      this.selectedCompanyName='All'
    }
    this.filters(this.selectedStatus, this.selectedPriority, this.selectedUserName,this.selectedCompany);
  }

  // async changeUserType($event) {
  //   if ($event.value == '') {
  //     await this.getTickets();
  //   } else {
  //     this.userTypeChanged = true;
  //     this.selectedUserType = $event.value;
  //     await this.initUsers();
  //     this.ticketService.changeUserType($event.value).subscribe((Response: any) => {
  //       if (Response.status == 200) {
  //         console.log('Reponse', Response);
  //         this.dataSource.data = Response.data;
  //       }
  //     });
  //   }
  // }

  async filterUserName(user=null) {
    this.pagination = {
      pageNo: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalRecords: 0,
      PAGE_LIMIT: 50,
      skip: 0
    };
    // console.log('$event', $event.value);
    // if ($event.value == '') {
    //   await this.getTickets();
    // } else {
    console.log('user', user);
    if (user) {
      this.selectedUserName = user._id;
    } else {
      this.selectedUserName = '';
    }
    this.filters(this.selectedStatus, this.selectedPriority, this.selectedUserName,this.selectedCompany);
    // this.ticketService.filterUserName($event.value).subscribe((Response: any) => {
    //   if (Response.status == 200) {
    //     console.log('Reponse', Response);
    //     this.dataSource.data = Response.data;
    //   }
    // });
    // }
  }

  filters(status, priority, user_id,company_id) {
    if(!this.currentUser.product_owner) {
      company_id = this.currentUser.company_id;
    }
    this.ticketService.filtering(status, priority, user_id, company_id,this.pagination.pageNo).subscribe((res: any) => {
      if (res.status == 200) {
        console.log('response', res);
        this.ticketsData = res.data;
        this.dataSource.data = this.ticketsData;company_id
        this.pagination.totalRecords = res.TotalTickets;
        this.handlePagination();
      }
    });
  }

  showDetails(data) {
    this.showDetail = true;
    this.sideBar = false;
    setTimeout(() => {
      this.sideBar = true;
    }, 5);
    this.rowData = data;
  }

  updateStatus($event) {
    console.log('$event in tickets from ticket details',$event);
    this.getTickets();
  }

  async handlePrevClick() {
    this.pagination.pageNo += 1;
    await this.getTickets();
    //this.totalTicketsCount -=this.totalTicketsCount;
  }

  async handleNextClick() {
    this.pagination.pageNo += 1;
    await this.getTickets();
    // this.totalTicketsCount +=this.totalTicketsCount;
  }

  clearFilters() {
    this.selectedStatus = '';
    this.selectedPriority = '';
    this.selectedUserName = '';
    if(this.currentUser.product_owner) {
      this.selectedCompany='';
      this.selectedCompanyName='All'
      this.getTickets();
    }
    else {
      this.filterCompany(this.currentUser.company_id);
    }
    this.userControl.setValue('');
      
  }
}
