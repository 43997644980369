import moment from "moment";

export const calculateTimeDifference = (wound, parentWound) => {
  const pastDate = parentWound.createdAt;
  let equalOrFutureDate;
  if (parentWound.state === 'active') {
    equalOrFutureDate = new Date();
  } else {
    // equalOrFutureDate = wound.createdAt;
    equalOrFutureDate = parentWound.remission_date;
  }
  
  const days = Math.floor(moment(equalOrFutureDate).diff(pastDate, "hours") / 24);
  const weeks = Math.floor(days / 7);
  const remaining_days = days % 7;
  if (days < 7) {
    if (days <= 1) {
      return `${days} day`;
    } else {
      return `${days} days`;
    }
  } else {
    if (remaining_days === 0) {
      if (weeks === 1) {
        return `${weeks} week`;
      } else {
        return `${weeks} weeks`;
      }
    } else {
      return `${weeks} w ${remaining_days} d`;
    }
  }
  // const weeks = days/7;
  // if (days < 7) {
  // } else {
  //     return `${weeks} weeks`;
  // }
};
export const calculateAreaforBanner = (wound): any => {
  // TODO: write formula
  if (wound?.source?.includes("kent")) {
    if (wound?.area) {
      return Math.round(Number(wound.area));
    } else {
      return 0;
    }
  } else {
    const value = Number(wound.length) * Number(wound.width);
    return Math.round(value);
  }
};
export const calculateVolumeforBanner = (wound): any => {
  // TODO: write formula
  if (wound?.source?.includes("kent")) {
    if (wound?.volume) {
      return Math.round(Number(wound.volume));
    } else {
      const value = Number(wound.area) * Number(wound.depth);
      return Math.round(value);
    }
  } else {
    const value = Number(wound.length) * Number(wound.width) * Number(wound.depth);
    return Math.round(value);
  }
};
export const calculateArea = (wound): any => {
  // TODO: write formula
  if (wound?.source?.includes("kent")) {
    if (wound?.area) {
      return Number(wound.area);
    } else {
      return 0;
    }
  } else {
    const value = Number(wound.length) * Number(wound.width);
    return Number(value.toFixed(4));
  }
};
export const calculateVolume = (wound): any => {
  // TODO: write formula
  if (wound?.source?.includes("kent")) {
    if (wound?.volume) {
      return Number(wound.volume);
    } else {
      if(wound?.area){
        const value = Number(wound.area) * Number(wound.depth);
        return Number(value.toFixed(4));
      }else{
        return 0;
      }
    }
  } else {
    const value = Number(wound.length) * Number(wound.width) * Number(wound.depth);
    return Number(value.toFixed(4));
  }
};
export const getFieldName = (header) => {
  header = header.trim().toLowerCase().replace(/ /g, '_');
  switch (header) {
    case 'wound_edges':
      return 'exposed_structure'
    case 'exudate_amount':
      return 'exudate'
    case 'wound_type':
      return 'etiolgy'
    case 'wound_odor':
      return 'odor'

    case 'location':
      return 'body_part'

    case 'severity':
      return 'stage'

    case 'laterality':
      return 'body_side'

    case 'exposed_tissues':
      return 'exposed_tissues'
  }
  return header;
  
}
export const calculateAreaSubscore = (childWound): any => {
  if(calculateArea(childWound) == 0){
    return 0;
  }
  else if(calculateArea(childWound) < 0.3){
    return 1;
  }
  else if(calculateArea(childWound) >=0.3 && calculateArea(childWound)<=0.6){
    return 2;
  }
  else if(calculateArea(childWound) >=0.7 && calculateArea(childWound) <=1.0){
    return 3;
  }
  else if(calculateArea(childWound) >=1.1 && calculateArea(childWound) <=2.0){
    return 4;
  }
  else if(calculateArea(childWound) >= 2.1 && calculateArea(childWound) <= 3.0){
    return 5;
  }
  else if(calculateArea(childWound) >=3.1 && calculateArea(childWound) <=4.0){
    return 6;
  }
  else if(calculateArea(childWound) >=4.1 && calculateArea(childWound) <= 8.0){
    return 7;
  }
  else if(calculateArea(childWound) >=8.1 && calculateArea(childWound) <= 12.0){
    return 8;
  }
  else if(calculateArea(childWound) >= 12.1 && calculateArea(childWound) <=24.0){
    return 9;
  }
  else if(calculateArea(childWound) > 24.0){
    return 10;
  }
};
export const calculateExudateAmount = (childWound): any =>{
  if( childWound.exudate== 'None'){
    return 0;
  }
  else if(childWound.exudate == 'Scant'){
    return 1;
  }
  else if(childWound.exudate == 'Moderate'){
    return 2;
  }
  else if(childWound.exudate == 'Heavy'){
    return 3;
  }
  else if(childWound.exudate == ""){
    return 0;
  }

};
export const calculateTissueType = (childWound): any =>{
  const checksObject = {
    epithelialcheck: false,
    granulationcheck: false,
    sloughcheck: false,
    escharcheck: false,
  };
  const Objkeys=["epithelial", "granulation", "slough", "eschar"]
  for (const key in childWound) {
    if (Objkeys.includes(key)) {
      if(childWound[key]!=""){
        if(key=="epithelial") checksObject.epithelialcheck=true;
        if(key=="granulation") checksObject.granulationcheck=true;
        if(key=="slough") checksObject.sloughcheck=true;
        if(key=="eschar") checksObject.escharcheck=true;

      }
    }
  }
  // console.log('check', checksObject)
  const keys = Object.keys(checksObject);
  // Generate all combinations of keys
  const combinations = [];

// Write if conditions for each checksObject
  if (checksObject.epithelialcheck && checksObject.granulationcheck && checksObject.sloughcheck && checksObject.escharcheck) {
    return 4;
  } else if (checksObject.epithelialcheck && checksObject.granulationcheck && checksObject.sloughcheck) {
    return 3; 
  } else if(checksObject.epithelialcheck && checksObject.granulationcheck && checksObject.escharcheck){
    return 4;
  }else if (checksObject.granulationcheck && checksObject.sloughcheck && checksObject.escharcheck) {
    return 4;
  } else if (checksObject.epithelialcheck && checksObject.granulationcheck) {
    return 2;
  } else if (checksObject.epithelialcheck && checksObject.sloughcheck) {
    return 3;
  } else if (checksObject.epithelialcheck && checksObject.escharcheck) {
    return 4;
  } else if (checksObject.granulationcheck && checksObject.sloughcheck) {
    return 3;
  } else if (checksObject.granulationcheck && checksObject.escharcheck) {
    return 4;
  } else if (checksObject.sloughcheck && checksObject.escharcheck) {
    return 4;
  } else if (checksObject.epithelialcheck) {
    return 1;
  } else if (checksObject.granulationcheck) {
    return 2;
  } else if (checksObject.sloughcheck) {
    return 3;
  } else if (checksObject.escharcheck) {
    return 4;
  }
  else {
    return 0;
  }
}
