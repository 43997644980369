<div class="flex flex-col">

    <div class="flex flex-col gap-2"
        style="background: white; padding:15px 5px 10px 5px; border-radius: 10px; justify-content: space-evenly !important;">
        <ngx-ui-loader [loaderId]="patientsSearchLoaderId"></ngx-ui-loader>



        <div style="justify-content: space-evenly; align-content: center;" class="flex gap-4">

            <div class="flex justify-center items-center w-2/5">
                <ng-container>

                            <mat-form-field style="width: 100%;height: 74px;">
                                <mat-label>Search First Patient (by _id) </mat-label>
                                <input matInput type="text" name="patientsByProviderone" [(ngModel)]="first_id">
                            </mat-form-field>
                        
                </ng-container>

                <ng-container>
                    <button class="dn-button-primary" style="flex: 10%; min-width: auto; margin-left: 15px;"
                        (click)="fetchOne()" mat-raised-button>Fetch
                    </button>
                </ng-container>
            </div>
            <div class="flex justify-center items-center w-2/5">
                <ng-container>

                            <mat-form-field style="width: 100%;height: 74px;">
                                <mat-label>Search Second Patient (by _id)</mat-label>
                                <input matInput type="text" name="patientsByProvidertwo" [(ngModel)]="second_id">

                            </mat-form-field>
                        
                </ng-container>

                <ng-container>
                    <button class="dn-button-primary" style="flex: 10%; min-width: auto; margin-left: 15px;"
                        (click)="fetchTwo()" mat-raised-button>Fetch
                    </button>
                </ng-container>
            </div>

        </div>

        <div class="flex w-full gap-4" style="justify-content: space-evenly; align-content: center; padding-bottom: 17px;">

            <div class="flex justify-center items-center gap-4 w-2/5">
                <div class="w-full" *ngIf="(patient_one)">
                    <mat-card class="flex flex-col w-full" style="background-color:rgb(255 218 218);">
                        <mat-card-content>
                            <div class="flex w-full">

                                <div class="flex flex-col w-full">

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px;">
                                        <div class="flex-grow"> {{"Name: "+ patient_one.first_name+" "+ patient_one.last_name}}
                                        </div>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px;">
                                        <div class="flex-grow"> {{"DOB: "+ patient_one.date_of_birth}} </div>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{ "Gender: "+patient_one.gender}} <br></div>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <mat-checkbox name="mrn" [(ngModel)]="selections.mrn"
                                            [checked]="selections.mrn">
                                            <span style="font-size: 16px;"> {{"MRN: "+ patient_one.mrn}} </span>
                                        </mat-checkbox>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <mat-checkbox name="Notes" [(ngModel)]="selections.notes"
                                            [checked]="selections.notes">
                                            <span style="font-size: 16px;"> {{"Notes: "+ patient_one.notesCount}}
                                            </span>
                                        </mat-checkbox>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <mat-checkbox name="Charges" [(ngModel)]="selections.charges"
                                            [checked]="selections.charges">
                                            <span style="font-size: 16px;"> {{"Charges: "+ patient_one.chargeCount}}
                                            </span>
                                        </mat-checkbox>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <mat-checkbox name="Notes" [(ngModel)]="selections.wounds"
                                            [checked]="selections.wounds">
                                            <span style="font-size: 16px;"> {{ "Wounds: "+patient_one.woundCount}}
                                            </span>
                                        </mat-checkbox>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <mat-checkbox name="Notes" [(ngModel)]="selections.census"
                                            [checked]="selections.census">
                                            <span style="font-size: 16px;"> {{"Census: "+ patient_one.censusCount}}
                                            </span>
                                        </mat-checkbox>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <mat-checkbox name="Notes" [(ngModel)]="selections.ADT"
                                            [checked]="selections.ADT">
                                            <span style="font-size: 16px;"> {{ "ADT History: "+patient_one.adtCount}}
                                            </span>
                                        </mat-checkbox>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <mat-checkbox name="Notes" [(ngModel)]="selections.documents"
                                            [checked]="selections.documents">
                                            <span style="font-size: 16px;"> {{"Documents: "+ patient_one.docsCount}}
                                            </span>
                                        </mat-checkbox>
                                    </div>


                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <mat-checkbox name="Notes" [(ngModel)]="selections.allergies"
                                            [checked]="selections.allergies">
                                            <span style="font-size: 16px;"> {{ "Allergies: "+patient_one.allergyCount}}
                                            </span>
                                        </mat-checkbox>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <mat-checkbox name="Notes" [(ngModel)]="selections.comments"
                                            [checked]="selections.comments">
                                            <span style="font-size: 16px;"> {{"Comments: "+
                                                patient_one.commntsCount}}</span>
                                        </mat-checkbox>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div> {{"Source: "}}{{patient_one.source||'DN'}} </div>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div *ngIf="patient_one.pcc_patientId" class="flex-grow"> {{ "Pcc
                                            ID:"+patient_one.pcc_patientId}} </div>
                                    </div>
                                    <div class="flex w-2/3"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"Facility: "+
                                            (getFacility(patient_one))}}
                                        </div>
                                    </div>
                                    <div class="flex w-1/3"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"_id: "}}{{patient_one._id}}</div>
                                    </div>

                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>

                </div>

            </div>
            <div class="flex justify-center items-center gap-4 w-2/5">
                <div class="w-full" *ngIf="(patient_two)">
                    <mat-card class="flex flex-col w-full" style="background-color:rgb(179 239 148)">
                        <mat-card-content>
                            <div class="flex w-full">

                                <div class="flex flex-col w-full">

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px;">
                                        <div class="flex-grow"> {{"Name: "+ patient_two.first_name+" "+ patient_two.last_name}}
                                        </div>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px;">
                                        <div class="flex-grow"> {{"DOB: "+ patient_two.date_of_birth}} </div>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{ "Gender: "+patient_two.gender}} <br></div>
                                    </div>


                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <mat-checkbox name="mrn" [(ngModel)]="selections.mrn"
                                            [checked]="selections.mrn">
                                            <span style="font-size: 16px;"> {{"MRN: "+ patient_two.mrn}} </span>
                                        </mat-checkbox>
                                    </div>


                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <mat-checkbox name="Notes" [(ngModel)]="selections.notes"
                                            [checked]="selections.notes">
                                            <span style="font-size: 16px;"> {{"Notes: "+ patient_two.notesCount}}
                                            </span>
                                        </mat-checkbox>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <mat-checkbox name="Charges" [(ngModel)]="selections.charges"
                                            [checked]="selections.charges">
                                            <span style="font-size: 16px;"> {{"Charges: "+ patient_two.chargeCount}}
                                            </span>
                                        </mat-checkbox>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <mat-checkbox name="Notes" [(ngModel)]="selections.wounds"
                                            [checked]="selections.wounds">
                                            <span style="font-size: 16px;"> {{ "Wounds: "+patient_two.woundCount}}
                                            </span>
                                        </mat-checkbox>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <mat-checkbox name="Notes" [(ngModel)]="selections.census"
                                            [checked]="selections.census">
                                            <span style="font-size: 16px;"> {{"Census: "+ patient_two.censusCount}}
                                            </span>
                                        </mat-checkbox>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <mat-checkbox name="Notes" [(ngModel)]="selections.ADT"
                                            [checked]="selections.ADT">
                                            <span style="font-size: 16px;"> {{ "ADT History: "+patient_two.adtCount}}
                                            </span>
                                        </mat-checkbox>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <mat-checkbox name="Notes" [(ngModel)]="selections.documents"
                                            [checked]="selections.documents">
                                            <span style="font-size: 16px;"> {{"Documents: "+ patient_two.docsCount}}
                                            </span>
                                        </mat-checkbox>
                                    </div>


                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <mat-checkbox name="Notes" [(ngModel)]="selections.allergies"
                                            [checked]="selections.allergies">
                                            <span style="font-size: 16px;"> {{ "Allergies: "+patient_two.allergyCount}}
                                            </span>
                                        </mat-checkbox>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <mat-checkbox name="Notes" [(ngModel)]="selections.comments"
                                            [checked]="selections.comments">
                                            <span style="font-size: 16px;"> {{"Comments: "+
                                                patient_two.commntsCount}}</span>
                                        </mat-checkbox>
                                    </div>


                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"Source: "}}{{patient_two.source||'DN'}} </div>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div *ngIf="patient_two.pcc_patientId" class="flex-grow"> {{ "Pcc ID:
                                            "+patient_two.pcc_patientId}} </div>
                                    </div>


                                    <div class="flex w-2/3"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"Facility: "+
                                            (getFacility(patient_two))}}
                                        </div>
                                    </div>
                                    <div class="flex w-1/3"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"_id: "}}{{patient_two._id}}</div>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>

                </div>

            </div>
        </div>


        <div style="justify-content: space-evenly; align-content: center; padding-bottom: 17px;" class="flex flex-col gap-6">
            <div *ngIf="(patient_one&&patient_two)" class="flex justify-center items-center gap-6">
                <mat-checkbox name="is_active" [(ngModel)]="selections.is_active" [checked]="selections.is_active">
                    <span style="padding-top: 7px; font-size: 15px;"> {{'Inactive Source Patient'}} </span>
                </mat-checkbox>

                <div style="padding-top: 7px; margin-left: 20px; font-size: 15;">Merge : </div>

                <div style="padding-top: 7px; margin-left: 20px;">
                    <button style=" flex: 10%; min-width: auto; " [disabled]="mergeStatusone"
                        (click)="patient_one_to_patient_two()" mat-raised-button>
                        <span style="color:rgb(177, 26, 26);font-size: 15;">
                            {{'(X) '+patient_one.first_name+" "+patient_one.last_name}}
                        </span>
                        <span style="color:black;">--></span>
                        <span style="color:rgb(67, 107, 47);font-size: 15;">
                            {{patient_two.first_name+" "+patient_two.last_name}}

                        </span>

                    </button>
                </div>

                <div style="padding-top: 7px; margin-left: 20px;">
                    <button style=" flex: 10%;  min-width: auto; " [disabled]="mergeStatustwo"
                        (click)="patient_two_to_patient_one()" mat-raised-button>

                        <span style="color:rgb(67, 107, 47);font-size: 15;">
                            {{'(X) '+patient_two.first_name+" "+patient_two.last_name}}
                        </span>
                        <span style="color:black;">--></span>
                        <span style="color:rgb(177, 26, 26);font-size: 15;">
                            {{patient_one.first_name+" "+patient_one.last_name}}
                        </span>

                    </button>
                </div>

                <div>
                    <button class="dn-button-secondary" style=" flex: 10%; margin-left: 15px; min-width: auto;"
                        [disabled]="revertStatus" (click)="revertMerge()" mat-raised-button>Revert Merge
                    </button>
                </div>
            </div>

        </div>



        <div class="flex w-full gap-4" style="justify-content: space-evenly; align-content: center; padding-bottom: 17px;">
            <div class="flex justify-center items-center gap-4 w-2/5">
                <div class="w-full" *ngIf="(patient_one_after)">
                    <mat-card class="flex flex-col w-full" style="background-color:rgb(255 218 218);">
                        <mat-card-content>
                            <div class="flex w-full">

                                <div class="flex flex-col w-full">

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px;">
                                        <div class="flex-grow"> {{"Name: "+ patient_one_after.first_name+" "+
                                            patient_one_after.last_name}}
                                        </div>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px;">
                                        <div class="flex-grow"> {{"DOB: "+ patient_one_after.date_of_birth}} </div>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"MRN: "+ patient_one_after.mrn}} </div>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{ "Gender: "+patient_one_after.gender}} <br></div>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"Notes: "+ patient_one_after.notesCount}} </div>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"Charges: "+ patient_one_after.chargeCount}} </div>
                                    </div>


                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{ "Wounds: "+patient_one_after.woundCount}} </div>
                                    </div>


                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"Census: "+ patient_one_after.censusCount}} </div>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{ "ADT History: "+patient_one_after.adtCount}} </div>
                                    </div>



                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"Documents: "+ patient_one_after.docsCount}} </div>
                                    </div>


                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{ "Allergies: "+patient_one_after.allergyCount}} </div>
                                    </div>



                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">

                                        <div class="flex-grow"> {{"Comments: "+ patient_one_after.commntsCount}}</div>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"Source: "}}{{patient_one_after.source||'DN'}} </div>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div *ngIf="patient_one_after.pcc_patientId" class="flex-grow"> {{ "Pcc
                                            ID:"+patient_one_after.pcc_patientId}} </div>
                                    </div>


                                    <div class="flex w-2/3"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"Facility: "+
                                            (getFacility(patient_one_after))}}
                                        </div>
                                    </div>
                                    <div class="flex w-1/3"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"_id: "}}{{patient_one_after._id}}</div>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>

                </div>

            </div>
            <div class="flex justify-center items-center gap-4 w-2/5">
                <div class="w-full" *ngIf="(patient_two_after)">
                    <mat-card class="flex flex-col w-full" style="background-color:rgb(179 239 148)">
                        <mat-card-content>
                            <div class="flex w-full">

                                <div class="flex flex-col w-full">

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px;">
                                        <div class="flex-grow"> {{"Name: "+ patient_two_after.first_name+" "+
                                            patient_two_after.last_name}}
                                        </div>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px;">
                                        <div class="flex-grow"> {{"DOB: "+ patient_two_after.date_of_birth}} </div>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"MRN: "+ patient_two_after.mrn}} </div>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{ "Gender: "+patient_two_after.gender}} <br></div>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"Notes: "+ patient_two_after.notesCount}} </div>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"Charges: "+ patient_two_after.chargeCount}} </div>
                                    </div>



                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{ "Wounds: "+patient_two_after.woundCount}} </div>
                                    </div>


                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"Census: "+ patient_two_after.censusCount}} </div>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{ "ADT History: "+patient_two_after.adtCount}} </div>
                                    </div>



                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"Documents: "+ patient_two_after.docsCount}} </div>
                                    </div>


                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{ "Allergies: "+patient_two_after.allergyCount}} </div>
                                    </div>


                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">

                                        <div class="flex-grow"> {{"Comments: "+ patient_two_after.commntsCount}}</div>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"Source: "}}{{patient_two_after.source||'DN'}} </div>
                                    </div>

                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div *ngIf="patient_two_after.pcc_patientId" class="flex-grow"> {{
                                            "PccID:"+patient_two_after.pcc_patientId}} </div>
                                    </div>


                                    <div class="flex w-2/3"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"Facility: "+
                                            (getFacility(patient_two_after))}}
                                        </div>
                                    </div>
                                    <div class="flex w-1/3"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"_id: "}}{{patient_two_after._id}}</div>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>

                </div>

            </div>
        </div>




    </div>