<div class="flex gap-2 note-addendum">
    <mat-list class="flex-grow">

        <ng-container *ngFor="let addendum of addendums">
            <mat-list-item class="note-addendum-item" (click)="handleAddendumClick($event, addendum)">
                <div class="flex items-start justify-center gap-2" mat-line style="height: auto;">
                    <i class="icon icon-view" matTooltip="View" matTooltipPosition="above"
                        (click)="showAddendumNoteDialog(addendum)"></i>
                    <div class="addendum-date" style="margin-left: auto">
                        Addendum was
                        <ng-container
                            *ngIf="addendum.uploadedAt && addendum.facility_source != 'MatrixCare' && addendum.patient_source != 'MatrixCare'">
                            uploaded to
                            <img src="../../../../assets/icons/pcc-icon.svg"
                                style="width: 15px;vertical-align: middle;bottom: 2px;position: relative;" />
                            PCC on {{addendum.uploadedAt | date: global.date_time_format}}
                        </ng-container>
                        <ng-container
                            *ngIf="addendum.uploadedAt && addendum.facility_source == 'MatrixCare' && addendum.patient_source == 'MatrixCare'">
                            uploaded to
                            <img src="../../../../assets/icons/matrix.png"
                                style="width: 15px;vertical-align: middle;bottom: 2px;position: relative;" />
                            Matrix on {{addendum.uploadedAt | date: global.date_time_format}}
                        </ng-container>
                        <ng-container *ngIf="!addendum.uploadedAt">
                            saved
                            on {{addendum.createdAt | date: global.date_time_format}}
                        </ng-container>
                    </div>
                </div>
            </mat-list-item>
            <mat-divider></mat-divider>
        </ng-container>
    </mat-list>
</div>