<div [ngClass]="{'container': (companySide || user_dashboard) && !(nursingSide)}">

    <h3 class="dark-blue-heading">
        <ng-container *ngIf="!user_dashboard">
            <button type="button" style="height: min-content;" mat-raised-button class="dn-button-secondary"
                (click)="goBack()">
                Back
            </button>
        </ng-container>

        <label [ngClass]="{'label-margin': !user_dashboard}"> HP Type Tabular Report </label>

        <button style="margin-left: auto;" mat-raised-button class="dn-button-primary" (click)="exportReportAsXLSX();">Export
            As XLSX
        </button>
    </h3>

    <div class="search-block theme-form items-start justify-center" 
        style="background-color: white; border-radius: 4px; padding-top: 8px; padding-bottom: 5px; padding-left: 8px;padding-right: 8px;">
        
        <div class="flex items-center justify-start gap-2 flex-grow">

            <mat-label style="margin-right:20px;color:#999">Date of Creation </mat-label>
            <input style="width: 180px;" *ngIf="iscreationDateFilterSelected" type="text" ngxDaterangepickerMd
                [(ngModel)]="filter.creationDate" (datesUpdated)="changeCreationDate($event)" class="mat-like-input"
                [ranges]="preDateRanges" [alwaysShowCalendars]="true" [maxDate]="currentDate"
                [linkedCalendars]="true" [showClearButton]="true" />

            <mat-form-field>
                <mat-label>Select Facility</mat-label>
                <input type="text" placeholder="Select Facility" aria-label="facility" matInput
                    [formControl]="facilityControl" [matAutocomplete]="facilityAutoControl">
                <mat-icon matSuffix style="cursor: pointer;" (click)="showFacilityDropdown();">
                    keyboard_arrow_down</mat-icon>
                <mat-autocomplete #facilityAutoControl="matAutocomplete" autoActiveFirstOption>
                    <mat-option (onSelectionChange)="onSelectFacility($event, facility);"
                        *ngFor="let facility of filteredFacilities | async" [value]="facility.title">
                        {{facility.title}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <button mat-raised-button class="dn-button-primary" (click)="handleApplyFilter();">Apply Filter
            </button>

            <button mat-raised-button class="dn-button-secondary" (click)="resetFilter();">Reset Filter
            </button>
        </div>
    </div>

    <mat-card class="vertical-height">

        <div class="table-height">
            
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.patient_id?.name}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="created_date">
                    <th mat-header-cell *matHeaderCellDef> Date </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.createdAt | date }}
                    </td>
                </ng-container>


                <ng-container matColumnDef="wound_num">
                    <th mat-header-cell *matHeaderCellDef> Wound Number </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.woundNo}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef> Location </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{getLocation(element?.body_part,element?.body_side)}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="wound_type">
                    <th mat-header-cell *matHeaderCellDef> Wound Type </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.etiolgy}}
                    </td>
                </ng-container>


                <ng-container matColumnDef="stage">
                    <th mat-header-cell *matHeaderCellDef> Stage </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.stage}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="size">
                    <th mat-header-cell *matHeaderCellDef> Size (L,W,D) cm</th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{getsize(element.length,element.width,element.depth)}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Current Wound Status </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{getWoundStatus(element.state)}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="weeks">
                    <th mat-header-cell *matHeaderCellDef> Weeks in Treatment </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{dateDifference(element?.createdAt)}}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
    </mat-card>
</div>