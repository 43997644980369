<form #form="ngForm" (ngSubmit)="handleSubmit(form)">
    <div class="flex justify-end gap-5">
        <button mat-raised-button class="dn-button-primary" type="submit">Save</button>
    </div>
    <br />
    
    <div class="flex flex-col fields-sections">
        <div class="fields-section">
            <mat-form-field class="field-item">
                <input matInput placeholder="Name" name="name" [(ngModel)]="model.name">
            </mat-form-field>
            <mat-form-field>
                <input matInput [matDatepicker]="datePicker" (focus)="datePicker.open()"
                       placeholder="Start Date" name="date" [(ngModel)]="model.date">
                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
            </mat-form-field>
            <mat-radio-group class="flex flex-inline items-center gap-5" name="is_active" [(ngModel)]="model.is_active">
                <mat-radio-button value="true">Active</mat-radio-button>
                <mat-radio-button value="false">Inactive</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="fields-section">
            <mat-radio-group class="flex flex-inline items-center gap-5" name="type" [(ngModel)]="model.type">
               <mat-radio-button value="Personal">Personal</mat-radio-button>
               <mat-radio-button value="Business">Business</mat-radio-button>
           </mat-radio-group>
            <mat-form-field class="field-item">
                <input matInput placeholder="Tax ID #" name="tax_id" [(ngModel)]="model.tax_id">
            </mat-form-field>
            <mat-form-field class="field-item" >
                <input matInput placeholder="Practice / Group Name"name="practice_group_name" [(ngModel)]="model.practice_group_name">
            </mat-form-field>
        </div>
        <div class="fields-section">
            <mat-form-field class="field-item">
                <input matInput placeholder="Primary practice address (City/State/Zip)"  name="primary_practice_address" [(ngModel)]="model.primary_practice_address">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Phone" name="phone" [(ngModel)]="model.phone">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Fax" name="fax" [(ngModel)]="model.fax">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Email" name="email" [(ngModel)]="model.email">
            </mat-form-field>
        </div>
        <div class="label">Please provide all practice locations as cases are assigned based on geographic proximity to claimants home.</div>
        <div class="fields-section">
            <mat-form-field class="field-item">
                <input matInput placeholder="Additional practice address" name="additional_practice_address" [(ngModel)]="model.additional_practice_address">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Phone" name="additional_phone" [(ngModel)]="model.additional_phone">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Fax" name="additional_fax" [(ngModel)]="model.additional_fax">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Email" name="additional_email" [(ngModel)]="model.additional_email">
            </mat-form-field>
        </div>
        <div class="fields-section">
            <mat-form-field class="field-item">
                <input matInput placeholder="Specialty" name="speciality" [(ngModel)]="model.speciality">
            </mat-form-field>
            <mat-form-field class="field-item">
                <input matInput placeholder="American Specialty Board & Status" name="american_speciality_board_status" [(ngModel)]="model.american_speciality_board_status">
            </mat-form-field>
            <mat-form-field class="field-item">
                <input matInput placeholder="Secondary Board & Status" name="secondary_board_status" [(ngModel)]="model.secondary_board_status">
            </mat-form-field>
        </div>
        <div class="fields-section">
            <mat-form-field class="field-item">
                <input matInput placeholder="Office contact person name"  name="office_contact_person_name" [(ngModel)]="model.office_contact_person_name">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Phone"  name="office_contact_phone" [(ngModel)]="model.office_contact_phone">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Position or Title"  name="office_contact_position_title" [(ngModel)]="model.office_contact_position_title">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Email"  name="office_contact_email" [(ngModel)]="model.office_contact_email">
            </mat-form-field>
        </div>
    </div>
    <ngx-ui-loader [loaderId]="doctorGeneralDetailsLoader"></ngx-ui-loader>
</form>
