<div mat-dialog-content style="padding: 26px">
    <table mat-table class="colorHeaderTable" [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="assocCompanies">
            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Company Name
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.name}}
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>