import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { PCCPatientListService } from '../pcc-patient-list/pcc-patient-list.service';
import { RecurrentOptionDialogComponent } from '../recurrent-option-dialog/recurrent-option-dialog.component';
import { PCCService } from 'src/app/services/pcc.service';
import { PatientListService } from '../patient-list/patient-list-service';
// import { ActivatedRoute, Router } from '@angular/router';
// import { CensusRecurrentService } from 'src/app/services/censusRecurrent.service';
// import { FacilityService } from 'src/app/services/facility.service';
// import { CompanyService } from 'src/app/services/Modules/company.service';
// import { CreateCensusCalenderComponent } from '../create-census-calender/create-census-calender.component';
// import { DashboardService } from '../dashboard/dashboard.service';
// import { PatientRoundingSheetService } from '../patient-rounding-sheets/patient-rounding-sheet.service';
export type Pagination = {
  hasNextPage: boolean;
  hasPrevPage: boolean;
  page: number;
  pageSize: number;
}
@Component({
  selector: 'app-sheduler-patient-dialog',
  templateUrl: './sheduler-patient-dialog.component.html',
  styleUrls: ['./sheduler-patient-dialog.component.css']
})
export class ShedulerPatientDialogComponent implements OnInit {

  date_obj: any;
  currentUser: any;
  currentUserDetails: any;
  provider: any;
  facility: any;
  DNdataSource: any;
  PccdataSource: any;
  loaderId = 'patient-list-loader-s';
  patientName: any;
  pccpatientName: any;
  dob: any;
  allPatients: any;
  pagination: {
    pageNo: number,
    hasNextPage: boolean,
    hasPrevPage: boolean,
    totalCount: number,
    totalPages: number
  };
  public Pccpagination: Pagination;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [];
  displayedPccColumns: string[] = [];
  selectedPatients: any;
  active: boolean;
  timeoutId: any;
  tabIndex: any;
  allPccPatients: any;
  selectedPccPatients: any;
  filteredPatients: any;
  pccUserInfo: any;
  patientsLoaded: boolean;
  PcctimeoutId: any;
  finalSelectedPatients: any;
  ImportedPccPatients: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ShedulerPatientDialogComponent>,
    private dialog: MatDialog,
    private _authService: AuthService,
    private loader: NgxUiLoaderService,
    private _patientListService: PatientListService,
    private toastr: ToastrService,
    public pccService: PCCService,
    private pccPatientListService: PCCPatientListService,
    // private facilityService: FacilityService,
    // private _patientRoundingSheetService: PatientRoundingSheetService,
    // private _dashboardService: DashboardService,
    // private _route: ActivatedRoute,
    // private _router: Router,
    // private _CensusRecurrentService: CensusRecurrentService,
    // private _company: CompanyService,


  ) {
    this.displayedColumns = ['checkbox', 'name', 'date_of_birth', 'gender'];
    this.displayedPccColumns = ['checkbox', 'name', 'date_of_birth', 'gender'];
    this.selectedPatients = [];
    this.selectedPccPatients = [];
    this.filteredPatients = [];
    this.finalSelectedPatients = [];
    this.ImportedPccPatients = [];
    this.currentUser = this._authService.currentUser;
    this.currentUserDetails = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type
    }

    var [year, month, day] = this.getDate().split('-');
    this.date_obj = {
      year, month, day
    }
    //console.log("data : in dialog : ", this.data);
    if (this.data) {
      this.provider = this.data.provider_id;
      this.facility = this.data.facility_id;
    }

  }

  ngOnDestroy(): void {

    this.pccPatientListService.changePagination(this.pagination);

  }

  ngOnInit(): void {
    this.pagination = {
      pageNo: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalCount: 0,
      totalPages: 1
    };
    this.Pccpagination = {
      hasNextPage: false,
      hasPrevPage: false,
      page: 0,
      pageSize: 50
    }


    this.initPatients(1);
    this.initPccPatients(this.facility, null);

  }


  async initPatients(page, index = null) {

    var details = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <any>null,
      primary_doctor_id: <string>null,
      index: <string>null
    };
    details.id = this.currentUser._id;
    details.auth_key = this.currentUser.auth_key;
    details.usertype = this.currentUser.user_type;
    details.index = index;
    this.loader.startLoader(this.loaderId);

    this.getPatientsV2(index, page);


    // this.applyFilterDate();
  }


  onTabClick(event) {
    this.tabIndex = event.index;

    return;
  }


  applyFilter() {

    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {

      this.initPatients(1)
    }, 1000);

  }

  clearFilter() {
    this.active = true;
    this.dob = '';
    this.patientName = '';
    this.dob = null;
  }

  async getPatientsV2(index, page) {
    const provider = this.provider;
    const company_id = provider.company_id;
    let facility_id = this.facility._id;
    const searchText = this.patientName;
    let provider_id;
    if (!facility_id || facility_id === 'undefined') {
      // provider_id = provider._id;
      facility_id = undefined;
    }
    const dob = this.dob;
    const { data, status } = await lastValueFrom(this._patientListService.getPatientsV2(company_id, facility_id, provider_id, searchText, dob, page, index)) as any;
    if (status === 200) {
      this.loader.stopLoader(this.loaderId);

      this.allPatients = data.array.filter(patient => patient.is_active === 'true');
      const totalCount = data.totalCount;
      //console.log("this.allPatients : ", this.allPatients);

      this.pagination.totalCount = totalCount;
      this.pagination.pageNo = page;
    } else {
      this.loader.stopLoader(this.loaderId);

      this.allPatients = [];
      this.pagination.totalCount = 0;
      this.pagination.pageNo = 1;
    }
    this.initPagination();
    this.initTableDataSource(this.allPatients);
  }

  initPagination() {
    this.pagination.totalPages = Math.ceil(this.pagination.totalCount / 50);
    if (this.allPatients) {
      this.pagination.hasNextPage = this.allPatients.length > 0 && this.pagination.pageNo < this.pagination.totalPages;
    }
    this.pagination.hasPrevPage = this.pagination.pageNo > 1;
    // this.loader.stopLoader('loader-01');
  }
  handleNextClick() {
    this.initPatients(this.pagination.pageNo + 1);
  }

  handlePrevClick() {
    this.initPatients(this.pagination.pageNo - 1);
  }

  handlePccNextClick() {
    this.Pccpagination.page = this.Pccpagination.page + 1;
    this.initPccPatients(this.facility, this.pccpatientName);
  }

  handlePccPrevClick() {
    if (this.Pccpagination.page > 1) {
      this.Pccpagination.page = this.Pccpagination.page - 1;
      this.initPccPatients(this.facility, this.pccpatientName);
    }
  }

  totalList() {
    return this.selectedPccPatients.length + this.selectedPatients.length;
  }

  getcountofPatients() {
    if (this.allPatients)
      return ((this.pagination.pageNo * 50) - 49) + ' - ' + (((this.pagination.pageNo * 50) - 50) + Number(this.allPatients.length)) + " out of " + (this.pagination.totalCount);
    else return 0;
  }

  getDate() {
    /////   date from calender
    return moment(new Date()).format('YYYY-MM-DD');
  }

  initTableDataSource(patients) {
    this.DNdataSource = new MatTableDataSource(patients);
  }

  getDOB(patient) {
    if (patient.date_of_birth) {
      let splitDate = patient.date_of_birth.split('-');
      let month = splitDate[1];
      let day = splitDate[2];
      let year = splitDate[0];
      let newDate = month + '-' + day + '-' + year;
      return newDate;
    } else if (patient.date_of_birth_d) {
      return patient.date_of_birth_d;
    } else {
      return 'Jan 1, 1955';
    }
  }
  getPccDOB(patient) {
    if (patient.birthDate) {
      let splitDate = patient.birthDate.split('-');
      let month = splitDate[1];
      let day = splitDate[2];
      let year = splitDate[0];
      let newDate = month + '-' + day + '-' + year;
      return newDate;
    } else {
      return patient.birthDate?.toLocaleDateString();
    }
  }
  isPatientSelected(patient) {
    if (!this.selectedPatients) return false;
    return this.selectedPatients.findIndex((p) => p._id === patient._id) >= 0;
  }
  handleSelectPatient(e, patient) {
    const index = this.selectedPatients.findIndex((p) => p._id === patient._id);
    if (e.checked) {
      if (index === -1) {
        this.selectedPatients.push(patient);
      }
    } else {
      if (index >= 0) {
        this.selectedPatients.splice(index, 1);

      }
    }
    //console.log("this.selectedPatients : ", this.selectedPatients);
  }

  async getfacilitiesfromMaping(patient) {
    let newPatients = [], i = 0;
    if (!patient) return;

    return new Promise<void>((resolve, reject) => {

      for (let p of patient) {
        this._patientListService.getPatient_Maping_population({ patient_id: p._id ? p._id : p[0]._id, company_id: this.currentUser.company_id }).subscribe((res: any) => {
          let result = res?.data;
          let pat = JSON.parse(JSON.stringify(p));
          
          if (pat.facility_id_ref) {
            pat.facility_id_ref = result[0].facility_id;
          } else {
            pat[0].facility_id_ref = result[0].facility_id;
          }
          newPatients.push(pat);
          this.finalSelectedPatients = newPatients;
          // console.log("resultttn 2", this.finalSelectedPatients,i,patient.length);
          i++;
          if (i == patient.length) {
            console.log("patient.length resolve : :", patient.length);
            resolve(null);
          }
        })
      }
    });
  }


  async handleSaveClick() {
    if (this.facility?.pcc_facId) {
      this.selectedPccPatients;
      this.selectedPatients;
      await this.importMultiplePatients(true);
      this.finalSelectedPatients = await this.checkFinalListForDuplication([...this.selectedPatients, ...this.ImportedPccPatients]);
      // console.log("funciton  if", this.finalSelectedPatients);
      await this.getfacilitiesfromMaping(this.finalSelectedPatients);
      console.log("funciton  after correction", this.finalSelectedPatients);

      let not_validPatients;

      if (this.finalSelectedPatients?.length) {
        // console.log("this.facility?._id : ", this.facility)
        let validPatients = this.finalSelectedPatients.filter(p => p.facility_id_ref ? (p.facility_id_ref._id === this.facility?._id) : (p[0].facility_id_ref._id === this.facility?._id));
        not_validPatients = this.finalSelectedPatients.filter(p => p.facility_id_ref ? (p.facility_id_ref._id != this.facility?._id) : (p[0].facility_id_ref._id != this.facility?._id));
        this.finalSelectedPatients = validPatients;
        console.log("all counts v, iv, fs", validPatients?.length, not_validPatients?.lenght, this.finalSelectedPatients?.length);

        if (!(validPatients?.length)) {
          this.toastr.info('All Patients are moved to another facilities.');
          return;
        }

        if ((not_validPatients.length)) {
          this.toastr.info(`${not_validPatients.length} Patient(s) are moved to another facilities.`);
        }

        if (this.finalSelectedPatients?.length) {
          this.handleAddToRecurrentCensus();

        }

      }


    } else {
      this.finalSelectedPatients = this.selectedPatients;
      console.log("funciton ended else ", this.finalSelectedPatients);

      this.handleAddToRecurrentCensus();
    }

  }

  async checkFinalListForDuplication(finalList) {
    // check if pccpatientId is already in patientsArray
    //console.log("finalList check : ", finalList);
    let Unique = [], result = [];
    for (let patient of finalList) {
      if (patient)
        if (!Unique.includes(patient?._id.toString())) {
          Unique.push(patient?._id.toString());
          result.push(patient);
        }
    }

    return result;
  }

  handleCancelClick() {
    this.dialogRef.close(null);
  }


  async handleAddToRecurrentCensus() {
    await this.showDialog(true);

  }

  applyPccFilter() {
    clearTimeout(this.PcctimeoutId);
    this.PcctimeoutId = setTimeout(() => {
      this.Pccpagination.hasNextPage = false;
      this.Pccpagination.hasPrevPage = false;
      this.Pccpagination.page = 1;
      this.initPccPatients(this.facility, this.pccpatientName);
    }, 1000);
  }

  async showDialog(e) {
    //console.log("e : ", e);
    let date_obj = moment().format('YYYY-MM-DD').split('-');
    let date_obj_toPass;
    const [year, month, day] = date_obj;
    date_obj_toPass = {
      year, month, day
    }

    const obj = {
      //...this.data,
      date_obj: date_obj_toPass,
      from_patient: true
    }
    const dialogRefr = this.dialog.open(RecurrentOptionDialogComponent, {
      data: obj,
      width: '40%'
    });

    dialogRefr.afterClosed().subscribe(async (data) => {
      if (data) {
        //console.log("closedd true", data);

        let datatoSend = {
          inputData: this.data,
          date_obj: this.date_obj,
          selectedPatients: this.finalSelectedPatients,
          recurrenceMap: data.recurrenceMap,
          date_objects: data.date_objects,
          census_name: data.census_name
        };

        this.dialogRef.close(datatoSend);

      } else {
        //console.log("closedd false", data);
      }
    });

  }

  async initPccPatients(facility, pccPatientName) {
    let pccPatientStatus = 'All';

    if (!facility) return;
    const { pcc_facId: pccFacId, pcc_orgUuid } = facility;
    if (!pccFacId) {

      return;
    }

    this.loader.startLoader(this.loaderId);

    if (pccPatientStatus === 'All') {
      pccPatientStatus = undefined; // optional
    }
    if (typeof pccPatientName !== 'string' || pccPatientName.length === 0) {
      pccPatientName = undefined;
    }

    const args: any = {
      pccOrgUuid: pcc_orgUuid,
      pccFacID: pccFacId,
      pccPatientStatus: pccPatientStatus,
      pccPatientName: pccPatientName,
      page: this.Pccpagination.page,
      pageSize: this.Pccpagination.pageSize,
    }

    this.pccService
      //.getPatientsByFacilityID(args)
      .getPatientsByFacilityIDWithOutRecentNote(args)
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.allPccPatients = response.data.data;
            this.filteredPatients = this.allPccPatients.slice();

            this.PccdataSource = new MatTableDataSource(this.filteredPatients);

            if (response.data.paging) {
              this.Pccpagination.hasNextPage = response.data.paging.hasMore;
              this.Pccpagination.hasPrevPage = response.data.paging.page > 1 ? true : false;
              this.Pccpagination.page = response.data.paging.page;
            }

            this.patientsLoaded = true;

          } else {
            this.PccdataSource = [];
            this.toastr.error(response.message, 'Error');
          }
        },
        (error) => this.toastr.error('Something went wrong, please try again'),
        () => {
          this.loader.stopLoader(this.loaderId);
        }
      );
  }

  isPccPatientSelected(patient) {
    return this.selectedPccPatients.indexOf(patient.patientId) >= 0;
  }
  handlePccSelectPatient(e, patient) {
    const index = this.selectedPccPatients.indexOf(patient.patientId);
    if (e.checked) {
      if (index === -1) {
        this.selectedPccPatients.push(patient.patientId);
      }
    } else {
      if (index >= 0) {
        this.selectedPccPatients.splice(index, 1);
      }
    }
    //console.log("this.selectedPccPatients : ", this.selectedPccPatients);
  }

  async importMultiplePatients(recurrent = false) {
    let primaryProviderId;

    primaryProviderId = this.provider._id;
    //console.log("primaryProviderId : ", primaryProviderId);

    if (!primaryProviderId) {
      return this.toastr.warning(
        'Please select primary Provider to import patient.',
        'Warning'
      );
    }

    const facility = this.facility;
    const selectedFacilityId = facility._id;
    const pccOrgUuid = facility.pcc_orgUuid;

    const importedPatients = [];
    const totalSelectedPatients = this.selectedPccPatients.length;
    this.ImportedPccPatients = [];
    for await (const pccPatientId of this.selectedPccPatients) {
      const infoToast = this.toastr.info(`Importing Patients(${importedPatients.length + 1} of ${totalSelectedPatients})`, 'Please wait', {
        disableTimeOut: true,
      });


      const response: any = await this.pccService
        .importPCCPatient(pccOrgUuid, pccPatientId, primaryProviderId, selectedFacilityId)
        .toPromise()
        .catch((e) => {
          this.toastr.error(
            'Patient could not be imported at this time.',
            'Failed'
          );
        });

      if (response.status === 200) {
        this.toastr.clear(infoToast.toastId);
        //console.log('response.data', response.data);
        if (Array.isArray(response.data)) {
          importedPatients.push(response.data[0]);
        } else {
          if (response.patient)
            importedPatients.push(response.patient);
          else
            importedPatients.push(response.data);
        }
        //console.log('importedPatients', importedPatients);
        this.ImportedPccPatients = importedPatients;
      } else {
        this.toastr.error(
          'Patient could not be imported at this time.',
          'Failed'
        );
      }


    }
    this.toastr.success(`${importedPatients.length} patients imported successfully`, 'Success');
    // if(recurrent){
    //   this.handleAddToRecurrentCensus();
    // }else{
    //   //console.log("never should be printed");

    // }
  }


}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
