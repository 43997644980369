import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrganizationDialogComponent } from '../organization-dialog/organization-dialog.component';
import { OranizationService } from '../organization-dialog/oranization.service';
import * as global from '../../../app/includes/global';
import { UserService } from 'src/app/services/Modules/user.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { lastValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-company-organizations',
  templateUrl: './company-organizations.component.html',
  styleUrls: ['./company-organizations.component.css']
})
export class CompanyOrganizationsComponent implements OnInit {

  @Input() currentCompany;
  dataSource = new MatTableDataSource<any>();
  displayedColumns = ['title', 'tin', 'users', 'operations'];
  global = global;
  users: any = [];
  userDropdownSettings: any = {};
  loaderId = 'organization-loader';
  relations: any = [];
  organizations: any = [];
  constructor(
    private dialog: MatDialog,
    private organizationService: OranizationService,
    private userService: UserService,
    private loader: NgxUiLoaderService,
    private toastr: ToastrService,

  ) {
    this.userDropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'full_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: true,
    };
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId);
    await this.getAllCompanyOrganizations();
    await this.getUsers();
    await this.loadRelations();
    this.loader.stopLoader(this.loaderId);

  }

  async getAllCompanyOrganizations() {
    this.organizationService.getAllCompanyOrganizations(this.currentCompany._id).subscribe(async (res: any) => {
      if (res.status == 200) {
        this.organizations = res.data;
        this.dataSource.data = res.data;
      }
    });
  }

  async getUsers() {
    const filter: any = {
      company_id: this.currentCompany._id,
      user_type: global.USER_TYPE.DOCTOR
    };
    const projection = {
      first_name: 1,
      last_name: 1,
      full_name: 1,
      title: 1,
      associated_company_ids: 1,
      company_id: 1
    };
    return new Promise<void>((resolve, reject) => {
      this.userService.getUsers(filter, projection).subscribe(async (response: any) => {
        if (response.status === 200) {
          this.users = response.data;
          resolve();
        }
      }, error => {
        reject('error => ' + error.toString());
      });
    });
  }

  openOrganizationDialog(org = null) {
    const dialogRef = this.dialog.open(OrganizationDialogComponent, {
      width: '29%',
      height: '35%',
      data: { company_id: this.currentCompany._id || null, organization: org ? org : null }
    });
    dialogRef.afterClosed().subscribe(async (data: any) => {
      if (data) {
        this.loader.startLoader(this.loaderId);
        let found = false;
        this.organizations.forEach((org, index) => {
          if (org._id.toString() === data._id.toString()) {
            org.title = data.title;
            org.tin = data.tin;
            found = true;
            return;
          }
        });
        if (!found) {
          this.organizations.push(data);
        }
        this.dataSource.data = this.organizations.slice();

        this.loader.stopLoader(this.loaderId);
      } else {
        console.log('no data!');
      }
    });
  }

  async onChangeStatus(org, $event) {
    const res: any = await lastValueFrom(this.organizationService.deleteOrganization(org._id, $event.checked));
    if(res.status == 200) {
      !res.data.is_active ? org.is_active = false : org.is_active = true;
      this.toastr.success(res.message,'Success');
    }
  }

  async loadRelations() {
    let filter = { company_id: this.currentCompany._id };
    const populate = { user_ids : 'first_name last_name title' };
    const relations: any = await lastValueFrom(this.organizationService.getOrganizationUserRelation(filter, populate));
    if (relations.status == 200) {
      this.relations = relations.data;
      this.organizations.forEach(org => {
        this.relations.forEach(relation => {
          if (org._id === relation.organization_id) {
            org.user_ids = relation.user_ids;
          }
        });
      });
    }
  }

  async onUserSelect($event, element) {
    const filter = { organization_id: element._id, company_id: this.currentCompany._id };
    const data = { user_ids: $event._id };

    const relationResponse: any = await lastValueFrom(this.organizationService.updateOrganizationUserRelation(filter, data));
    if (relationResponse.status == 200) {
      this.toastr.success("Relation Updated");
    }
    else {
      this.toastr.error(relationResponse.message);
    }
  }

  async onUserDeSelect($event, element) {
    const filter = { organization_id: element._id, company_id: this.currentCompany._id };
    const data = { user_ids: $event._id };
    let isRemoving: boolean = true;

    const relationResponse: any = await lastValueFrom(this.organizationService.updateOrganizationUserRelation(filter, data, isRemoving));
    if (relationResponse.status == 200) {
      this.toastr.success("Relation Updated");
    }
    else {
      this.toastr.error(relationResponse.message);
    }
  }

  async onSelectAllUsers($event, element) {
    const filter = { organization_id: element._id, company_id: this.currentCompany._id };
    const data = { user_ids: $event };

    const relationResponse: any = await lastValueFrom(this.organizationService.updateOrganizationUserRelation(filter, data));
    if (relationResponse.status == 200) {
      this.toastr.success("Relations Updated");
    }
    else {
      this.toastr.error(relationResponse.message);
    }
  }

  async onDeSelectAllUsers($event, element) {
    const filter = { organization_id: element._id, company_id: this.currentCompany._id };
    const data = { user_ids: $event };
    let isRemoving: boolean = true;

    const relationResponse: any = await lastValueFrom(this.organizationService.updateOrganizationUserRelation(filter, data, isRemoving));
    if (relationResponse.status == 200) {
      this.toastr.success("Relations Updated");
    }
    else {
      this.toastr.error(relationResponse.message);
    }
  }
}
