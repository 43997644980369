import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-new-appointment',
  templateUrl: './add-new-appointment.component.html',
  styleUrls: ['./add-new-appointment.component.css']
})
export class AddNewAppointmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
