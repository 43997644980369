<div class="flex gap-5" style="background: white;margin-bottom: 7px;padding-left: 8px;
padding-right: 8px; align-items: center; padding-top: 5px; padding-bottom: 5px">
    <mat-form-field style="width: 100%;">
        <mat-label>Patient First Name</mat-label>
        <input matInput type="text" name="patient" #patient="ngModel" [readonly]="!canEditPatientInfo" [(ngModel)]="patientInfo.first_name"
            placeholder="First Name">
    </mat-form-field>
    <mat-form-field style="width: 100%;" >
        <mat-label>Patient Last Name</mat-label>
        <input matInput type="text" name="patient" #patient="ngModel" [readonly]="!canEditPatientInfo" [(ngModel)]="patientInfo.last_name"
            placeholder="Last Name">
    </mat-form-field> 
    <mat-form-field style="width: 100%;" *ngIf="canEditPatientInfo">
        <mat-label>Gender</mat-label>
            <mat-select name="patient"  #patient="ngModel" [(ngModel)]="patientInfo.gender"
            placeholder="Gender" required>
                <mat-option *ngFor="let option of genderOptions" [value]="option">{{ option }}</mat-option>
              </mat-select>
    </mat-form-field>
    <mat-form-field style="width: 100%;" *ngIf="canEditPatientInfo">
        <mat-label>DOB</mat-label>
        <input matInput type="date" name="dob" [(ngModel)]="patientInfo.dob"
            placeholder="DOB">
    </mat-form-field>
    <mat-form-field style="width: 100%;">
        <mat-label>Visit Date</mat-label>
        <input matInput type="text" readonly name="visit_date" value="{{getVisitDate(params)| date:'M/dd/yyyy'}}"
            placeholder="Visit Date">
    </mat-form-field>

    <button class="dn-button-primary" mat-raised-button type="button" (click)="model.status= 'draft';saveData()"
        [disabled]="disableButton(model) || disableButtons " 
        *ngIf="(currentUser.user_type != 6 || currentUser.user_type != global.USER_TYPE.BILLER) && params.backCtrl == 'false'"
        title= "Charges will be saved as draft and can be edited.">Save as Draft</button>
        <button class="dn-button-primary" mat-raised-button type="submit"
            [disabled]="disableButton(model, 'submit') || disableButtons"  
            *ngIf="(currentUser.user_type != 6 || currentUser.user_type != global.USER_TYPE.BILLER) && params.backCtrl == 'false'"
            (click)="model.status= 'submit'; model.is_approved = 'true'; submitCharge()"
            title= "Charges will be submitted & approved. Will not be editable">Submit</button>
            <button class="dn-button-primary" mat-raised-button type="submit"
            *ngIf="currentUser.user_type == 6 || currentUser.user_type == global.USER_TYPE.BILLER || params.backCtrl == 'true'"
            [disabled]="disableButton(model,'submit') || disableButtons"  
            (click)="model.status= 'submit'; model.is_approved = 'true'; resubmit()"
            title= "Charges will be resubmitted & approved.">Resubmit</button>
            <button class="dn-button-primary" mat-raised-button type="submit"
            *ngIf="currentUser.user_type == global.USER_TYPE.BILLER"
            [disabled]="disableButton(model) || disableButtons"  
            (click)="model.status= 'submit'; model.is_approved = 'true'; billedCharge()"
            title= "Charges will be billed & approved.">Bill</button>
        <button class="dn-button-secondary" mat-raised-button (click)="cancel()" type="button">Cancel
        </button>
</div>
    <div class="flex flex-col gap-4" style="background: white; height: calc(100vh - 130px); overflow: auto; padding-left: 10px ;padding-bottom: 20px ;">        

        <div class="flex flex-col">
            <div style="display: flex; flex-direction: row; margin-bottom: 0px !important; align-items: baseline;">
                <mat-label><strong>Diagnosis</strong></mat-label>
                <mat-form-field *ngIf="searchICD" appearance="fill" style="margin-left: 2%; display: flex;">
                    <mat-label>Type And Press Enter</mat-label>
                    <input matInput placeholder="Type Diagnosis/ICD10"
                        (keyup)="searchDiagnosesWithDelay($event.target.value);" (keydown)="$event.stopPropagation();"
                        [(ngModel)]="diagnoseFilter" name="diagnoseFilter">
                    <div matSuffix class="flex justify-between items-center gap-1">
                        <mat-spinner *ngIf="isDataLoading" [diameter]="18"></mat-spinner>
                        <mat-icon mat-button mat-icon-button (click)="clearSearchField();searchDiagnose('');"
                            *ngIf="diagnoseFilter">close</mat-icon>
                    </div>
                </mat-form-field>
            </div>
            <div class="flex gap-5"s style="align-items: flex-end; ">
                <span style="width: 100%;align-self: self-start;max-height: 570px; min-height: 250px; overflow: auto;">
                    <mat-tab-group (selectedTabChange)="onTabClickDiagnose($event)">
                        <mat-tab label="Favorites">
                            <ng-template matTabContent>
                                <span style=" width: 100%; height: 100%;overflow: auto;">
                                    <table mat-table [dataSource]="dataSourceFvrtICDs" matSort
                                    cdkDropList 
                                    (cdkDropListDropped)="onListDropfvrtICDs($event)">
                                        <ng-container matColumnDef="checkbox">
                                            <th class="header-data" mat-header-cell *matHeaderCellDef
                                                mat-sort-header-container  > </th>
                                            <td mat-cell *matCellDef="let element" style="width: 50px;">
                                                <mat-checkbox 
                                                    (change)="addToModel(element, $event, 'icd');"
                                                    [checked]="element.selected" 
                                                    name="icd_fvrt">
                                                </mat-checkbox>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="fvrt">
                                            <th class="header-data" mat-header-cell *matHeaderCellDef
                                                mat-sort-header-container> </th>
                                            <td mat-cell *matCellDef="let element" style="width: 50px;">
                                                <span
                                                    (click)="$event.preventDefault();$event.stopPropagation();removeFavouriteDiagnoses(element)"
                                                    style="cursor: pointer"
                                                    class="material-icons">
                                                    star
                                                </span>  
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="code">
                                            <th class="header-data" mat-header-cell *matHeaderCellDef
                                                mat-sort-header-container > </th>
                                            <td mat-cell *matCellDef="let element" (click)="addToModel(element, $event, 'icd')" style="cursor: pointer;"
                                            >
                                                <div [class.pdpm_highlight]="element.pdpm_status">
                                                    {{element.code}}
                                                    <span>({{element.description}})</span>
                                                </div>
                                            </td>
                                        </ng-container>
                                       
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag
                                        [ngClass]="row.isExpired ? 'red-row' : ''"></tr>
                                    </table>
                                </span>
                            </ng-template>
                        </mat-tab>

                        <mat-tab label="My ICDs">
                            <ng-template matTabContent>
                                <span style=" width: 100%; height: 100%;overflow: auto;">
                                    <table mat-table [dataSource]="dataSourceAllICDs" matSort>
                                        <ng-container matColumnDef="checkbox">
                                            <th class="header-data" mat-header-cell *matHeaderCellDef
                                                mat-sort-header-container> </th>
                                            <td mat-cell *matCellDef="let element" style="width: 50px;">
                                                <mat-checkbox (change)="addToModel(element, $event, 'icd')"
                                                    [checked]="element.selected" name="icd_all">
                                                </mat-checkbox>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="fvrt">
                                            <th class="header-data" mat-header-cell *matHeaderCellDef
                                                mat-sort-header-container> </th>
                                            <td mat-cell *matCellDef="let element" style="width: 50px;">
                                                <span *ngIf="element.fvrt === true"
                                                    (click)="$event.preventDefault();$event.stopPropagation();removeFavouriteDiagnoses(element)"
                                                    style="cursor: pointer" class="material-icons">
                                                    star
                                                </span>
                                                <span class="material-icons" *ngIf="element.fvrt !== true"
                                                    (click)="$event.preventDefault();$event.stopPropagation();addToFavouriteDiagnoses(element)"
                                                    style="cursor: pointer">star_outline</span>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="code">
                                            <th class="header-data" mat-header-cell *matHeaderCellDef
                                                mat-sort-header-container> </th>
                                            <td mat-cell *matCellDef="let element"
                                                (click)="addToModel(element, 'row', 'icd')" style="cursor: pointer;">
                                                <div [class.pdpm_highlight]="element.pdpm_status">
                                                    {{element.code}}
                                                    <span>({{element.description}})</span>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                            [ngClass]="row.isExpired ? 'red-row' : ''"></tr>
                                    </table>
                                    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
                                </span>
                                <div style="height: 5px;"></div>
                                <div class="table-nav flex justify-center items-center gap-2">
                                </div>
                            </ng-template>
                        </mat-tab>

                        <mat-tab label="PCC Diagnosis" *ngIf="note?.patient?.pcc_patientId">
                            <ng-template matTabContent> 
                                <app-conditions  [note]="note" [patient]="note.patient"  [showCheckboxes]="true" [selectedDiagnoses]="selectedDiagnoses"></app-conditions>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Matrix" *ngIf="note?.patient?.source === 'MatrixCare'">
                            <ng-template matTabContent>
                                <app-matrix-diagnosis 
                                    #pccDiagnoseChild
                                    [showCheckboxes]="true" 
                                    [selectedDiagnoses]="getSelectedDiagonoseCopy()"
                                    [patient]="note?.patient"
                                    (pccDiagnosesChange)="toggleICDsinHistory($event)">
                                </app-matrix-diagnosis>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </span>

                <span style="width: 100%;align-self: self-start;max-height: 570px; min-height: 250px; overflow: auto;">
                    <mat-tab-group>
                        <mat-tab label="Selected">
                            <ng-template matTabContent>
                                <span style="width: 100%; height: 100%;overflow: auto;" >
                                    <table cdkDropList  (cdkDropListDropped)="onListDropICD($event)" mat-table [dataSource]="dataSourceSelectedICDs" matSort>
                                        <ng-container matColumnDef="checkbox">
                                            <th class="header-data" mat-header-cell *matHeaderCellDef
                                                mat-sort-header-container > </th>
                                            <td mat-cell *matCellDef="let element" style="width: 50px;">
                                                <mat-checkbox (change)=" removeDiagnose(element, $event, 'icd');"
                                                [checked]="true">
                                                </mat-checkbox>
                                            </td>
                                        </ng-container> 
                                        <ng-container matColumnDef="code">
                                            <th class="header-data" mat-header-cell *matHeaderCellDef
                                                mat-sort-header-container > </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div [class.pdpm_highlight]="element.pdpm_status">
                                                    {{element.code}}
                                                    <span>({{element.description}})</span>
                                                </div>
                                            </td>
                                        </ng-container> 
                                        <tr cdkDrag style="cursor: move;" mat-row *matRowDef="let row; columns: selectedDisplayedColumns;"  ></tr>
                                    </table>
                                </span>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </span>
            </div>


        </div>

        <div class="flex flex-col"> 
            <div style="display: flex; flex-direction: row; margin-bottom: 0px !important; align-items: baseline;">
                <mat-label><strong>Charges</strong></mat-label>
                <mat-form-field *ngIf="searchCPT" appearance="fill" style="margin-left: 2%; display: flex;">
                    <mat-label>Search</mat-label>
                    <input matInput 
                        placeholder="search charges" 
                        (keyup)="searchCharge($event.target.value)"
                        [(ngModel)] = "chargeFilter"
                        name = "chargeFilter"> 
                </mat-form-field>
                <button mat-button *ngIf="chargeFilter" matSuffix mat-icon-button aria-label="Clear" (click)="chargeFilter='';clearChargeFilter()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <div class="flex gap-5" style="align-items: flex-end;">
                <span style="width: 100%;align-self: self-start;max-height: 570px; min-height: 250px; overflow: auto;"> 

                    <mat-tab-group (selectedTabChange)="onTabClickCharges($event)">
                        <mat-tab label="Favorites">
                            <ng-template matTabContent>
                                <span
                                    style=" width: 100%; height: 100%; background-color: rgba(81, 99, 99, 0.8);overflow: auto;">
                                    <table mat-table [dataSource]="dataSourceFvrtCPTs" matSort 
                                    cdkDropList 
                                    (cdkDropListDropped)="onListDropfvrtCPTs($event)">
                                        <ng-container matColumnDef="checkbox">
                                            <th class="header-data" mat-header-cell *matHeaderCellDef
                                                mat-sort-header-container  > </th>
                                            <td mat-cell *matCellDef="let element" style="width: 50px;">
                                                <mat-checkbox 
                                                    (change)="addToModel(element, $event, 'cpt');"
                                                    [checked]="element.selected" 
                                                    name="cpt_fvrt">
                                                </mat-checkbox>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="fvrt">
                                            <th class="header-data" mat-header-cell *matHeaderCellDef
                                                mat-sort-header-container > </th>
                                                <td mat-cell *matCellDef="let element" style="width: 50px;">
                                                    <span  
                                                        (click)="$event.preventDefault();$event.stopPropagation();removeFavouriteCharges(element)" 
                                                        style="cursor: pointer" 
                                                        class="material-icons">
                                                        star
                                                    </span>
                                                </td>
                                        </ng-container>
                                        <ng-container matColumnDef="code">
                                            <th class="header-data" mat-header-cell *matHeaderCellDef
                                                mat-sort-header-container > </th>
                                            <td mat-cell *matCellDef="let element"  
                                            (click)="addToModel(element, $event, 'cpt')" style="cursor: pointer;">
                                            {{element.code}}
                                                <span>({{element.description}})</span>
                                            </td>
                                        </ng-container>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag ></tr>
                                    </table>
                                </span>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="All">
                            <ng-template matTabContent>
                                <span
                                    style=" width: 100%; height: 100%; background-color: rgba(81, 99, 99, 0.8);overflow: auto;">
                                    <table mat-table [dataSource]="dataSourceAllCPTs" matSort>
                                        <ng-container matColumnDef="checkbox">
                                            <th class="header-data" mat-header-cell *matHeaderCellDef
                                                mat-sort-header-container > </th>
                                            <td mat-cell *matCellDef="let element" style="width: 50px;">
                                                <mat-checkbox 
                                                    (change)="addToModel(element, $event, 'cpt')"
                                                    [checked]="element.selected"  
                                                    name ="cpt_all">
                                                </mat-checkbox>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="fvrt">
                                            <th class="header-data" mat-header-cell *matHeaderCellDef
                                                mat-sort-header-container > </th>
                                                <td mat-cell *matCellDef="let element"  style="width: 50px;">
                                                    <span  *ngIf="element.fvrt === true"
                                                        (click)="$event.preventDefault();$event.stopPropagation();removeFavouriteCharges(element)" 
                                                        style="cursor: pointer"
                                                        class="material-icons">
                                                        star
                                                    </span> 
                                                    <span  *ngIf="element.fvrt !== true"
                                                        class="material-icons"
                                                        (click)="$event.preventDefault();$event.stopPropagation();addFavouriteCharges(element)"
                                                        style="cursor: pointer">star_outline</span>
                                                </td>
                                        </ng-container>
                                        <ng-container matColumnDef="code">
                                            <th class="header-data" mat-header-cell *matHeaderCellDef
                                                mat-sort-header-container> </th>
                                            <td mat-cell *matCellDef="let element" (click)="addToModel(element, $event, 'cpt')" style="cursor: pointer;" >
                                                {{element.code}}
                                                <span>({{element.description}})</span>
                                            </td>
                                        </ng-container>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
                                    </table> 
                                    <ngx-ui-loader [loaderId]="loaderId2"></ngx-ui-loader>
                                </span>
                                <div style="height: 5px;"></div>
                                <div class="table-nav flex justify-center items-center gap-2">

                                    <span style="background: white; border-radius: 2px; font-size: 12px;padding: 5px;">
                                        {{getCountofCodes('chargesAll')}}</span>

                                    <button mat-raised-button class="dn-button-primary"
                                        [disabled]="!paginationChargesAll.hasNextPage" type="button"
                                        (click)="handleNextClick('chargesAll')"> Next </button>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab *ngIf="currentUser.user_type === '6'" label="HP E&M Codes">
                            <ng-template matTabContent>
                                <app-note-cpt-history [note]="note" [emCodeEditorCpts]="associatedTemplateCPTs" [searchCharges]="chargeFilter" [selectedCharges]="getSelectedChargesCopy()" [showCPTHistory]= "false" [fvrtCPTs]="reserved_cpts"
                                    (toggleFvrtCPTsinHistory)="toggleFvrtCPTsinHistory($event)"
                                    (toggleCPTsinHistory)="toggleCPTsinChange($event)"></app-note-cpt-history>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </span>
                <span style="width: 100%;align-self: self-start;max-height: 570px; min-height: 250px; overflow: auto;">
                    <mat-tab-group>
                        <mat-tab label="Selected">
                            <ng-template matTabContent>
                                <span style="width: 50%; height: 100%;overflow: auto;">
                                    <table cdkDropList  (cdkDropListDropped)="onListDropCPT($event)" [cdkDropListDisabled] = "dragable()" mat-table [dataSource]="dataSourceSelectedCPTs" matSort>
                                        <ng-container matColumnDef="checkbox">
                                            <th class="header-data" mat-header-cell *matHeaderCellDef
                                                mat-sort-header-container > </th>
                                            <td mat-cell *matCellDef="let element" style="width: 50px;">
                                                <mat-checkbox (click)="removeCharge(element, $event, 'cpt');" 
                                                    [checked]="true">
                                                </mat-checkbox>
                                            </td>
                                        </ng-container> 
                                        <ng-container matColumnDef="code">
                                            <th class="header-data" mat-header-cell *matHeaderCellDef
                                                mat-sort-header-container > </th>
                                            <td mat-cell *matCellDef="let element" [class.procedure-type-cpts]="isElementProcedureType(element)">{{element.code}}
                                                <span>({{element.description}})</span>
                                            </td>
                                        </ng-container> 
                                        <ng-container matColumnDef="modifier">
                                            <th class="header-data" mat-header-cell *matHeaderCellDef
                                            mat-sort-header-container > </th>
                                            <td mat-cell *matCellDef="let element"> 
                                                <mat-form-field>
                                                    <mat-label>Modifier</mat-label>
                                                    <mat-select   #modifierRef [value]="getCptModifier(element)" (selectionChange)="handleModifierChange(element, modifierRef.value)" >
                                                        <mat-select-trigger>
                                                            {{modifierRef.value}}
                                                        </mat-select-trigger>
                                                        <mat-option *ngFor="let modifier of modifiers"
                                                                    [value]="modifier.code"> ({{modifier.code}}) {{modifier.description}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-form-field style="width:50px; margin-left: 5px;">
                                                    <input (keyup)="handleModifierUnitChange(element, $event.target.value)"
                                                        (change)="handleModifierUnitChange(element, $event.target.value)" type="number" min="1"
                                                        max="99"  [value]="getCptModifierUnit(element)" matInput placeholder="Unit" name="modifierUnit" style="width: 100%;">
                                                </mat-form-field>
                                            </td>
                                        </ng-container>
                                        <tr cdkDrag style="cursor: move;" mat-row *matRowDef="let row; columns: selectedDisplayedColumnsCharges;" ></tr>
                                    </table>
                                </span>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </span>
            </div>
        </div>

        <div *ngIf="!showBillerAddNote" class="gap-2">
            <mat-form-field
                style="width: 100%; background: #f4f5f8;border-radius: 10px; padding: 5px; margin-bottom: 0px !important;"
                appearance="fill">
                <mat-label>Biller Note</mat-label>
                <input matInput type="text"  [(ngModel)]="model.note" placeholder="description"
                    name="description">
            </mat-form-field>
        </div>
        <div *ngIf="showBillerAddNote" class="gap-2">
            <mat-form-field
                style="width: 94%; background: #f4f5f8;border-radius: 10px; padding: 5px; margin-bottom: 0px !important;"
                appearance="fill">
                <mat-label>Biller Note</mat-label>
                <input matInput type="text"  [(ngModel)]="model.note" placeholder="description"
                    name="description">
            </mat-form-field>
            <button style="width: 5%;border-radius: 10px; padding: 5px; margin-bottom: 0px !important;" appearance="fill"
                class="dn-button-primary" mat-raised-button type="submit"
                [disabled]="disableButton(model) || disableButtons"
                (click)="model.status= 'submit'; model.is_approved = 'true'; resubmit()"
                title="Charges will be resubmitted & approved.">Add Note</button>
        </div>
        <div style="display: flex; flex-direction: row; margin-left: 10px; align-items: center;margin-bottom: 10px !important;"
            class="gap-5"> 
            <mat-checkbox *ngIf="isMipReportingEnabled()" style="margin-left: 10px;" [(ngModel)]="model.measure" (change)="changeReportMeasure($event.checked)" name="measure">Report MIPS Measures</mat-checkbox>
        </div>


        <ng-container *ngIf="model.measure && note && (currentUser.user_type != 6 || currentUser.user_type != global.USER_TYPE.BILLER)" style="margin-left: 10px;">
            <app-sidebar-mips *ngIf="isMipReportingEnabled()" [note]="note">  </app-sidebar-mips>
        </ng-container>
        <app-mips-questionnaire-results *ngIf="showMipsQuestionaireResults" [charge]="draftedData"></app-mips-questionnaire-results>
    </div>
    <ngx-ui-loader loaderId="loader-01"></ngx-ui-loader>