<table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="facId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            facId
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.facId}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="highPriority">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            highPriority
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.highPriority}}
        </td>
    </ng-container>
    <ng-container matColumnDef="noteType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            noteType
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.noteType}}
        </td>
    </ng-container>
    <ng-container matColumnDef="orgUuid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            orgUuid
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.orgUuid}}
        </td>
    </ng-container>
    <ng-container matColumnDef="progressNoteTypeId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            progressNoteTypeId
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.progressNoteTypeId}}
        </td>
    </ng-container>
    <ng-container matColumnDef="sections">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            sections
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.sections}}
        </td>
    </ng-container>
    <ng-container matColumnDef="showOn24HourReport">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            showOn24HourReport
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.showOn24HourReport}}
        </td>
    </ng-container>
    <ng-container matColumnDef="showOnShiftReport">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            showOnShiftReport
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.showOnShiftReport}}
        </td>
    </ng-container>
   
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<div *ngIf="apiData" class="flex justify-start items-center gap-2">
    <button mat-raised-button class="dn-primary-button" [disabled]="apiData.paging.page === 1"
        (click)="navigate(-1)">Prev</button>
    <span style="font-size: 12px;">Page {{apiData.paging.page}}</span>
    <button mat-raised-button class="dn-primary-button" [disabled]="!apiData.paging.hasMore"
        (click)="navigate(1)">Next</button>
</div>
<ngx-ui-loader [loaderId]="loaderId" bgsPosition= "center-center"></ngx-ui-loader>
