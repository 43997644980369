import { Component, Inject } from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import * as global from '../../../includes/global';
import { ToastrService } from "ngx-toastr";
import { PatientRoundingSheetCommentsService } from "../../../services/patient-roundingsheet-comments.service";
import { AuthService } from "src/app/services/auth.service";

export interface PatientRoundingSheetCommentsDialogData {
    // action:String // create, rename 
    patient:any,
    census:any
  }
  @Component({
    selector: 'patient-roundingsheet-comments-dialog',
    templateUrl: './patient-roundingsheet-comments-dialog.component.html',
    styleUrls: ['./patient-roundingsheet-comments-dialog.css']
  })
  export class PatientRoundingSheetCommentsDialog {
    global=global;
    patient:any;
    census:any;
    userInitials: any;
    patientRoundingSheetComments = [];

    activePatientNote:any = {
      note_detail: ''
    };
    loading = false;
    isEditMode = false;

    constructor(
      public dialogRef: MatDialogRef<PatientRoundingSheetCommentsDialog>,
      @Inject(MAT_DIALOG_DATA) public data: PatientRoundingSheetCommentsDialogData,
      private patientRoundingSheetCommentsService:PatientRoundingSheetCommentsService,
      private toastr:ToastrService,
      private authService:AuthService) {
        if(data && data.patient._id) {
          this.patient = data.patient;
          this.census = data.census;
          this.initPatientNotes();
        }      
      }

      initPatientNotes() {
        this.loading = true;
        this.patientRoundingSheetCommentsService.getPatientRoundingSheetComments(this.patient._id, this.census._id).subscribe((response:any) => {
          this.patientRoundingSheetComments = response.data.array;
          if(this.patientRoundingSheetComments.length) {
            this.activePatientNote = this.patientRoundingSheetComments[0];
            console.log("sea",this.activePatientNote);
            console.log("name",this.activePatientNote.created_by.first_name.charAt(0));
            console.log("name",this.activePatientNote.created_by.last_name.charAt(0));
            this.userInitials = this.activePatientNote.created_by.first_name.charAt(0) + this.activePatientNote.created_by.last_name.charAt(0) + ": ";
          }
          this.loading = false;
        })
      }

      handlePatientNoteClick(event, patientRoundingSheetComments) {
        if(event.target.classList.contains('btn-delete')) return;
        
        this.activePatientNote = {...patientRoundingSheetComments};      
      }

      isPatientNotesViewActive() {
        return !!this.activePatientNote;
      }
      isNoteTextareReadonly() {
       return !this.isEditMode;
      }
      handleCreateNewClick() {
        this.isEditMode = true;
        this.activePatientNote = {
          note_detail: ''
        };
      }
      isButtonsVisible() {
        return this.isEditMode;
      }
      handleEditClick() {
        this.isEditMode = true;
      }
      handleDeleteClick(patientRoundingSheetCommentsId) {
        this.patientRoundingSheetCommentsService.deletePatientNote(patientRoundingSheetCommentsId)
        .subscribe((response:any) => {
          console.log(response);
          this.initPatientNotes();
        })
      }
      handleCancelEditMode() {
        this.isEditMode = false;
        if(this.activePatientNote && !this.activePatientNote._id) {
          this.activePatientNote = undefined;
        }
      }
      handleSubmitForm() {
        // if(!this.isEditMode) return;
        const infoToast = this.toastr.info('Saving Comment...', 'Please wait', {disableTimeOut: true})
        if(this.activePatientNote._id) {
          // update
          this.patientRoundingSheetCommentsService.updatePatientRoundingSheetComment(this.activePatientNote._id, this.activePatientNote.patient_id, this.activePatientNote.note_detail, this.activePatientNote.created_by)
          .subscribe((response:any) => {
            this.isEditMode = false;
            // this.initPatientNotes();
            this.patientRoundingSheetComments = this.patientRoundingSheetComments.map(note => {
              if(note._id === response.data._id) {
                return response.data;
              }
              return note;
            });
            this.activePatientNote = {...response.data};
            this.toastr.clear(infoToast.toastId);
            this.toastr.success('Comment Saved Successfully')
            this.dialogRef.close()
          })
        } else {
          // create
          this.patientRoundingSheetCommentsService.addPatientRoundingSheetComment(this.patient._id, this.activePatientNote.note_detail, this.authService.currentUser._id, this.census._id)
          .subscribe((response:any) => {
            this.isEditMode = false;
            // this.initPatientNotes();
            this.patientRoundingSheetComments.push(response.data);
            this.activePatientNote = {...response.data};
            this.toastr.clear(infoToast.toastId);
            this.toastr.success('Comment Saved Successfully')
            this.dialogRef.close()
          });
        }
        
        
      }
  }
