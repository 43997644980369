<form class="ticket-form" (ngSubmit)="submitTicketForm()" #form2="ngForm">
    <mat-form-field class="example-full-width">
        <mat-label>Title</mat-label>
        <input matInput type="text" name="title" #title="ngModel" [(ngModel)]="ticketModel.title"
            placeholder="Enter title..." required>
        <mat-error *ngIf="title.invalid  && (title.dirty || title.touched)">
            Please enter title
        </mat-error>
    </mat-form-field>
    <mat-form-field style="width: 100%;"  *ngIf="globals.USER_TYPE.MEDICAL_ASSISTANT === currentUser.user_type  
    || globals.USER_TYPE.BUSINESS_OFFICER=== currentUser.user_type || globals.USER_TYPE.TRANSCRIBER=== currentUser.user_type">
        <mat-label>Select associated provider</mat-label>
        <div class="flex justify-start items-center">
            <mat-select [(ngModel)]="provider_id"  [ngModelOptions]="{standalone: true}">
                    <input  placeholder="Search" [(ngModel)]="searchText"
                        [ngModelOptions]="{standalone: true}" style="color:#000;height: 40px;padding-left: 5px;border: none;width: 100%;">
                        <mat-option *ngFor="let provider of providers | searchProvider : searchText"
                        (click)="getAssociatedProviderFacility(provider._id)"
                            [value]="provider._id">
                            {{provider.first_name+' '+provider.last_name+', '+provider.title}}
                        </mat-option>
            </mat-select>
        </div>
    </mat-form-field>
    <mat-form-field class="w-full" *ngIf="globals.USER_TYPE.DOCTOR === currentUser.user_type || globals.USER_TYPE.SNF_WC_NURSE === currentUser.user_type || globals.USER_TYPE.WOUND_NURSE === currentUser.user_type
    || globals.USER_TYPE.MEDICAL_ASSISTANT === currentUser.user_type || globals.USER_TYPE.BUSINESS_OFFICER=== currentUser.user_type || globals.USER_TYPE.TRANSCRIBER=== currentUser.user_type">
        <mat-label>Select facility</mat-label>
        <div class="flex items-center">
            <span *ngIf="showIcon">
                <img src="../../../assets/icons/pcc-icon.svg" *ngIf="isPCCFacility(ticketModel.facility_id) ? isPCCFacility(ticketModel.facility_id) : ''" width="20px"
                    height="20px" alt="">
            </span>
            <mat-select [(value)]="selected" name="facility_id" #facility_id="ngModel"
                [(ngModel)]="ticketModel.facility_id" required>
                <input  placeholder="Search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
                    style="color:#000;height: 40px;padding-left: 5px;border: none;width: 100%;" />
                <mat-option *ngFor="let facility of facilities | searchFacility: searchText" value="{{facility._id}}"
                    (click)="getFacilityPatients(facility._id,true)">
                    <app-facility-title [facility]="facility"></app-facility-title>
                </mat-option>
            </mat-select>
        </div>
    </mat-form-field>
    <div class="flex gap-2">
        <mat-form-field class="example-full-width">
            <mat-label>Select patient</mat-label>
            <mat-select [(value)]="patientSelected" name="patient_id" #patient_id="ngModel"
                [(ngModel)]="ticketModel.patient_id" required>
                <input  placeholder="Search" [(ngModel)]="patientSearch" [ngModelOptions]="{standalone: true}"
                    style="color:#000;height: 40px;padding-left: 5px;width: 100%;border: none;" />
                <mat-option *ngFor="let patient of patients | searchDNPatient: patientSearch" value="{{patient._id}}"
                    (click)="selectPatient(patient)">
                    {{patient.last_name}}&nbsp;{{patient.first_name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="flex justify-between items-center">
        <mat-radio-group aria-label="Select an option" name="priority" #priority="ngModel"
            [(ngModel)]="ticketModel.priority" required>
            <mat-radio-button type="radio" value="Low" style="margin-right: 20px;"><span
                    style="background-color: rgb(91, 160, 0);border-radius: 3px;padding: 0px 10px 0px 10px; color: white;font-size: 11px;">Low</span>
            </mat-radio-button>
            <mat-radio-button type="radio" checked value="Medium" style="margin-right: 20px;"><span
                    style="background-color: rgb(59, 180, 250);border-radius: 3px;padding: 0px 10px 0px 10px;color: white;font-size: 11px;">Medium</span>
            </mat-radio-button>
            <mat-radio-button type="radio" value="High" style="margin-right: 20px;"><span
                    style="background-color: rgb(255, 123, 0);border-radius: 3px;padding: 0px 10px 0px 10px; color: white;font-size: 11px;">High</span>
            </mat-radio-button>
            <mat-radio-button type="radio" value="Critical" style="margin-right: 20px;"><span
                    style="background-color: rgb(155, 10, 0);border-radius: 3px;padding: 0px 10px 0px 10px; color: white;font-size: 11px;">Critical</span>
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <div class="editor-block">
        <div class="h100">
            <ckeditor name="description" #description="ngModel" [(ngModel)]="ticketModel.description" 
                name="description_value" editorUrl="../../../../assets/ckeditor/ckeditor.js"
                [readOnly]="!cke4Config.editable" [config]="cke4Config" (ready)="onReadyCkeditor($event)" required>
            </ckeditor>
        </div>
        <div class="editor-action flex justify-end items-center">
            <button mat-raised-button class="dn-button-secondary" type="button" (click)="closeDialog()">
                Cancel
            </button>
            <button mat-raised-button class="dn-button-primary" [disabled]="!form2.form.valid||disableSubmitButton" type="submit">
                Submit
            </button>
        </div>
    </div>
</form>