import {Injectable} from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpRequest,
    HttpEvent,
    HttpEventType,
    HttpErrorResponse
} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import * as global from '../global';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import {API_URL} from "../../../environments/api-url";
import {Subject} from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class TranscriptionDetailService {
    data: any;
    public chunk = new Subject<Object>();
    public chunk_update = new Subject<boolean>();
    public constructor(
        private httpclient: HttpClient,
        private _router: Router,
        private _authService: AuthService) {
        // this.data = localStorage.getItem('currentUser');
        this.data = this._authService.currentUser;
    }

    // getTranscriptionDetail(id, auth_key, user_type, audio_id) {
    //   var json = {
    //     id:id,
    //     auth_key:auth_key,
    //     usertype:user_type,
    //     audio_id:audio_id
    //   }
    //   return this.httpclient.post(global.url + '/getTranscribtions', {details: json}).pipe();
    // }
    getTranscriptionComments(id, auth_key, user_type, audio_id) {
        var json = {
            id: id,
            auth_key: auth_key,
            usertype: user_type,
            audio_id: audio_id
        }
        return this.httpclient.post(global.url + API_URL.AUDIO.getAudioReplies, {details: json}).pipe();
    }

    saveTranscriptionComments(id, auth_key, user_type, audio_id, createdAt, comment, pic, type) {
        var json = {
            id: id,
            auth_key: auth_key,
            usertype: user_type,
            audio_id: audio_id,
            date_time: createdAt,
            text_comment: comment,
            replying_picture: pic,
            type: type
        }
        return this.httpclient
            .post(global.url + API_URL.AUDIO.addReply, {details: json}).pipe();
    }
}

@Injectable({
    providedIn: 'root',
})
export class UploadService {
    apiUrl = global.url + API_URL.ROUTES.uploadFile;
    type = '13';
    pdf: any;

    constructor(private http: HttpClient) {
    }

    upload(fd, formData) {
        this.pdf = formData;
        const headers = new Headers();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        // const options = new RequestOptions({ headers : headers });
        return this.http.post(`${this.apiUrl}`, fd).pipe(
            // map(event => this.getEventMessage(event, formData)),
            // catchError(this.handleError)
        );
    }
}

@Injectable({
    providedIn: 'root',
})
export class FilesAdd {
    datas: any;
    currentaudio: any;

    public constructor(
        private httpclient: HttpClient,
        private _authService: AuthService) {
        // this.datas = localStorage.getItem('currentUser');
        this.datas = this._authService.currentUser;
        this.currentaudio = localStorage.getItem('CurrentRecording');
    }

    fileadd(transcribt) {
        return this.httpclient.post(global.url + '/addTranscribtion', {transcribtion: transcribt}).pipe();

    }

}

@Injectable({
    providedIn: 'root',
})
export class DownloadService {
    public constructor(private httpclient: HttpClient) {
    }

    download(value: any) {
        return this.httpclient
            .get(value.path, {
                responseType: 'blob'
                // search: // query string if have
            }).pipe(
                map(res => {
                    return {
                        filename: value.transcribtion_title,
                        data: res
                    };
                }))
            .subscribe(res => {
                var url = window.URL.createObjectURL(res.data);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
            }, error => {
            }, () => {
            });
    }
}

@Injectable({
    providedIn: 'root',
})
export class CreatePdfService {
    datas: any;

    // currentaudio: any;
    public constructor(
        private httpclient: HttpClient,
        private _authService: AuthService) {
        // this.datas = localStorage.getItem('currentUser');
        this.datas = this._authService.currentUser;
        // this.currentaudio = localStorage.getItem('CurrentRecording');
        // this.datas = JSON.parse(this.datas);
        // this.currentaudio = JSON.parse(this.currentaudio);

    }

    createfile(transcriptiondata, start_time, end_time, transcribtion_title) {
        const add = {
            id: <string>null,
            usertype: <string>null,
            auth_key: <string>null,
            transcriptionText: <string>null,
            start_time: <string>null,
            end_time: <string>null,
            transcribtion_title: <string>null
        };
        add.transcriptionText = transcriptiondata;
        add.id = this.datas._id;
        add.usertype = this.datas.user_type;
        add.auth_key = this.datas.auth_key;
        add.start_time = start_time;
        add.end_time = end_time;
        add.transcribtion_title = transcribtion_title;
        return this.httpclient.post(global.url + '/createPDFandUpload', {pdf: add}).pipe();
    }

    changeCurrentAudioStatus(audio, statusId) {
        const detail = {
            id: <string>null,
            usertype: <string>null,
            auth_key: <string>null,
            progress_status: <string>null,
            is_trashed: <string>null,
            audio_id: <string>null
        };
        detail.id = this.datas._id;
        detail.usertype = this.datas.user_type;
        detail.auth_key = this.datas.auth_key;
        detail.progress_status = statusId;
        detail.is_trashed = audio.is_trashed;
        detail.audio_id = audio._id;
        return this.httpclient.post(global.url + API_URL.AUDIO.updateAudioStatus, {details: detail}).pipe();
    }

    convertDocxFileToPdf(transcribt) {
        const detail = {
            id: <string>null,
            usertype: <string>null,
            auth_key: <string>null,
            title: <string>null,
            path: <string>null
        };
        detail.id = this.datas._id;
        detail.usertype = this.datas.user_type;
        detail.auth_key = this.datas.auth_key;
        detail.title = transcribt.transcribtion_title;
        detail.path = transcribt.path;
        return this.httpclient.post(global.url + API_URL.ROUTES.docxToPdfUpload, {details: detail}).pipe();
    }
    goBack(){
        
    }
}
