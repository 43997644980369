import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as io from 'socket.io-client';
@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  socket : any;
  readonly uri = 'http://localhost:8086';
  constructor() { 
    // this.socket = io(this.uri);
   }
  

  listen(eventName: string){
    return new Observable((subscriber)=>{
      this.socket.on(eventName, (data)=>{
        console.log('dataa',this.socket.id);
        subscriber.next(data);
      });
    });
  }

  emit(eventName: string, data: any){
    this.socket.emit(eventName,data);
  }
}
