import { Component, Inject, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// UPLOAD FILE DIALOG
export interface DoctorDocumentsUploadDialogData {
  doctor
}

@Component({
  selector: 'app-doctor-documents-upload-dialog',
  templateUrl: './doctor-documents-upload-dialog.component.html',
  styleUrls: ['./doctor-documents-upload-dialog.component.css']
})
export class DoctorDocumentsUploadDialog implements OnInit {
  doctor;
  emptyError=false;
  constructor(
    public dialogRef: MatDialogRef<DoctorDocumentsUploadDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DoctorDocumentsUploadDialogData) {
    if (data) {
      this.doctor = data.doctor
    }
  }

  ngOnInit(): void {
  }
}
