import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FacilityService } from 'src/app/services/facility.service';
import { WoundService } from 'src/app/services/wound.service';
import { Location } from '@angular/common';
import moment from 'moment'
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import * as global from 'src/app/includes/global';
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpResponse } from '@angular/common/http';
import { UserService } from 'src/app/services/Modules/user.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-debridement-report',
  templateUrl: './debridement-report.component.html',
  styleUrls: ['./debridement-report.component.css']
})
export class DebridementReportComponent implements OnInit {
  displayedColumns: string[] = [
    'pod', 'woundsCount', 'debridementCount', 'debridementRate'];
  woundHealingReport: any = [];
  loaderId = 'app-debridement-report';
  filter: any = {
    creationDate: {
      startDate: moment().subtract(6, 'days').toDate(),
      endDate: moment().toDate()
    },
    providerId: null,
    companyId: null
  };
  currentDate = moment(new Date());
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 1 Week': [moment().subtract(6, 'days'), moment()],
    'Last 2 Week': [moment().subtract(13, 'days'), moment()],
    'Last 3 Week': [moment().subtract(20, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };

  providers: Array<any> = [];
  dataSource: any = new MatTableDataSource([]);
  filteredProviders: Observable<string[]>;
  providerControl = new FormControl();
  currentUser: any;
  companySide: boolean;
  nursingSide: boolean;
  user_dashboard: Boolean = false;
  constructor(
    private router: Router,
    private _toastr: ToastrService,
    private _facilityService: FacilityService,
    private _route: ActivatedRoute,
    private _location: Location,
    private _wound: WoundService,
    private _userTypesRelationshipService: UserTypesRelationshipService,
    private loader: NgxUiLoaderService,
    private _userService: UserService,
    private _authService: AuthService

  ) {
    this.filter.companyId = this._route.parent.snapshot.params.id;
    this.currentUser = this._authService.currentUser;
    
    if(this._route.parent.snapshot.params.id){
      this.companySide=true;
    }else{
      this.filter.companyId = this.currentUser.company_id;
    }
    if(window.location.pathname.includes('dashboard')){
      this.user_dashboard = true;
    }
    if(window.location.pathname.includes('nursing')){
      this.nursingSide=true;
    }
  }

  async ngOnInit(): Promise<void> {
    this.loader.startLoader(this.loaderId);
    await this.getProviderList();
    this.initDebridementReport();
    this.loader.stopLoader(this.loaderId);
  }

  private filterProvider(value: string): string[] {
    if (value && this.providers.length > 0) {
      const filterValue = value.toLowerCase();
      return this.providers.filter(option => (option.middle_name && option.middle_name.toLowerCase().indexOf(filterValue) === 0) || option.last_name.toLowerCase().indexOf(filterValue) === 0 || option.first_name.toLowerCase().indexOf(filterValue) === 0);
    }
    else {
      return this.providers;
    }
  }
  getProviderList() {
    const filter = {
      company_id: this.filter.companyId,
      user_type: global.USER_TYPE.DOCTOR
    };
    const projection = {
      first_name: 1,
      last_name: 1,
      full_name: 1
    };
    return new Promise<void>((resolve, reject) => {
      this._userService.getUsers(filter, projection).subscribe((response: any) => {
        if (response.status === 200) {
          this.providers = response.data;
          this.selectDefaultProvider();
          this.showDropdown();
          resolve(null);
        }
      }, error => {
        reject('error => ' + error.toString());
      });
    });

  }
  selectDefaultProvider() {
    if (this.providers.length > 0) {
      this.providerControl.setValue(this.providers[0].full_name);
      this.filter.providerId = this.providers[0]._id;
    }
  }
  initDebridementReport() {
    this.loader.startLoader(this.loaderId);
    this._wound.getWoundDebridementReport(this.filter).subscribe((response: any) => {
      if (response.status == 200) {
        this.woundHealingReport = response.data;
        this.dataSource.data = this.woundHealingReport;
        this.loader.stopLoader(this.loaderId);
      }
      else {
        this.loader.stopLoader(this.loaderId);
      }
    }, err => {
      this._toastr.error(err, "ERROR");
      this.loader.stopLoader(this.loaderId);
    }
    );
  }

  changeCreationDate(event) {
    if (event.startDate && event.endDate) {
      this.filter.creationDate.startDate = moment(event.startDate).toDate();
      this.filter.creationDate.endDate = moment(event.endDate).toDate();
    }
  }

  onSelectProvider(event) {
    this.filter.providerId = event;
  }
  showDropdown(): void {
    this.filteredProviders = this.providerControl.valueChanges.pipe(
      startWith(''),
      map((value:any) => this.filterProvider(value)));
  }
  calculateRate(wound) {
    let rate = 0;
    if (wound.debridementCount) {
      rate = (wound.debridementCount / wound.woundsCount) * 100;
    }
    return rate.toFixed(2) + ' %';
  }
  goBack() {
    this._location.back();
  }
  handleApplyFilter() {
    this.initDebridementReport();
  }
  resetFilter() {
    delete this.filter.creationDate;
    const defaultDate = {
      startDate: moment().subtract(6, 'days').toDate(),
      endDate: moment().toDate()
    };
    this.filter.creationDate = defaultDate;
    this.selectDefaultProvider();
    this.initDebridementReport();
  }
  exportDebridementReportAsXLSX() {
    const infoToastr = this._toastr.info('Exporting as XLSX...', 'Please wait', { disableTimeOut: true });
    this._wound.exportDebridementReportAsXLSX(this.filter).subscribe((response: any) => {
      if (response instanceof HttpResponse) {
        const url = window.URL.createObjectURL(response.body);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `Debridement Report`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
        this._toastr.clear(infoToastr.toastId);
      }
    }, error => {
      console.log("err", error);
      this._toastr.clear(infoToastr.toastId);
    });
  }
}
