import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import { Router } from '@angular/router';
// import { Http, ResponseContentType } from '@angular/http';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DashboardService } from '../dashboard/dashboard.service';
import { CreatePdfService } from '../transcription-detail/transcription-detail.service';
import { ToastrService } from 'ngx-toastr';
import {EncryptDecryptService} from '../../services/encrypt-decrypt.service'
import { TranscriptionCreateService } from '../transcription-create/transcription-create.service';
import { Location } from '@angular/common';
import * as global from '../global';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-transcription-view',
  templateUrl: './transcription-view.component.html',
  styleUrls: ['./transcription-view.component.css']
})

export class TranscriptionViewComponent implements OnInit {
  public selectedFile: any;
  public filedata: any;
  public title: any;
  public date: any;
  public filepath: any;
  public currentuser: any;
  public user: any;
  public backURL:any;
  public backURLString = '/transcription-detail'
  public fileExtension:string;
  public statusResponse:any;
  public updatedFileResponse:any;
  public decrypted_transcription_text:any;
  public category:any;
  private loading:boolean = false;
public enctxt : string;
public dectxt : string;

isPrevDisabled:boolean=false;
isNextDisabled:boolean=false;
currentFileIndex:number=-1;
transcriptionFiles:Array<any>=[];

  public constructor(
    private router: Router, 
    public sanitizer: DomSanitizer,
    private encryptDecryptService: EncryptDecryptService,
    private TranscriptionCreate:TranscriptionCreateService,
    private toastr:ToastrService,
    private _location: Location,
    private dashboardService:DashboardService,
    private _authService: AuthService
    ) {
      // this.currentuser = localStorage.getItem('currentUser');
      // this.user = JSON.parse(this.currentuser);
      this.currentuser = this._authService.currentUser;
      if(!this.user) {
        // if not login, stop and redirect to login #mohsin-dev
        localStorage.setItem('currentUser', 'null');
        this.router.navigate(['/login']);
        return;
      }
      this.transcriptionFiles = JSON.parse(localStorage.getItem('transcriptionFiles'))

      this.initializeData();
  }
  async initializeData() {
    this.loading = true;
    const selectedFile = localStorage.getItem('selectedFile');
    // this.backURL =localStorage.getItem('backURL');
    // if(this.backURL){
    //   this.backURLString = this.backURL;
    // }  
    this.selectedFile = JSON.parse(selectedFile);
    this.category = localStorage.getItem('category');


    this.currentFileIndex = this.transcriptionFiles.findIndex(t => t && this.selectedFile._id === t._id);

    this.title = this.selectedFile.transcribtion_title;
    const decrypted_transcription_text = await this.encryptDecryptService.decrypt(this.selectedFile.transcribtion_text).toPromise();
    
    this.decrypted_transcription_text = decrypted_transcription_text;
    this.loading = false;

    this.initNextPrev();
  }
  ngOnInit() {
    
    if ( this.currentuser === null) {
      localStorage.removeItem('currentUser');
      this.router.navigate(['/login']);
      return;
    }
    // this.currentuser = JSON.parse(this.currentuser);
    this.currentuser = this.currentuser;
    // this.file = this.filedata;
    
  }
  signTranscription() {
    if(this.loading) return;
    
    this.loading = true;
    
    const transcription =this.selectedFile;
    
    transcription.is_signed='true';
    this.TranscriptionCreate.signTranscription({
      ...transcription,
      transcribtion_id: this.selectedFile._id,
      id:this.currentuser._id,
      usertype:this.currentuser.user_type,
      auth_key:this.currentuser.auth_key
    },this.category).subscribe((response:any) => {
      if(response.status == 200) {
        this.toastr.success('Success', response.message);
        // this.goBack()
      } else {
        this.toastr.error('Failed', response.message);
      }
      this.loading = false;

    },(error) => {
      console.error(error);
      this.toastr.error('Failed', "Something went wrong, please try again.");
      this.loading = false;

    })
  }
  
  unsignTranscription() {
    if(this.loading) return;

    this.loading = true;
    const transcription =this.selectedFile;
    
    transcription.is_signed='false';
    this.TranscriptionCreate.unSignTranscription({
      ...transcription,
      transcribtion_id: this.selectedFile._id,
      id:this.currentuser._id,
      usertype:this.currentuser.user_type,
      auth_key:this.currentuser.auth_key
    },this.category).subscribe((response:any) => {
      if(response.status == 200) {
        this.toastr.success('Success', response.message);
        // this.goBack()
      } else {
        this.toastr.error('Failed', response.message);
      }
      this.loading = false;
    },(error) => {
      console.error(error);
      this.toastr.error('Failed', "Something went wrong, please try again.");
      this.loading = false;

    })
  }

  async publishTranscription() {
    this.loading = true;
    const infoToast = this.toastr.info('Processing...', 'Please wait', {disableTimeOut:true});
    const transcribt= this.selectedFile;
    
    transcribt.auth_key = this.user.auth_key;
    transcribt.transcribtion_id = transcribt._id;
    transcribt.id = this.user._id;
    transcribt.usertype = this.user.user_type;
    
    const doctorsListResponse:any = await this.TranscriptionCreate.getDoctorsList().toPromise();
    if(doctorsListResponse.status=== 200) {

      const doctors = doctorsListResponse.data;
      const selectedDoctor = doctors.find(doctor => doctor._id === transcribt.doctor_id);
        // get selected doctor info
        let emailDetails = {
            email: selectedDoctor.email,                  
            text:`New Transcription <strong>${transcribt.transcribtion_title}</strong> uploaded by <strong>${this.user.first_name} ${this.user.last_name}</strong> in <strong>${transcribt.facility_title}</strong>. <a href="https://doctornow.io">Click Here</a> to visit the DN portal.`,
            subject :transcribt.transcribtion_title
        };
        this.dashboardService.sendNewDictaionRequestEmail(emailDetails).subscribe( async (data:any) => {
          if(data.status === 200) {
            transcribt.is_published = 'true';
           const publishTranscriptionResposne:any = await this.TranscriptionCreate.publishTranscription(transcribt,this.category).toPromise();
           if(publishTranscriptionResposne.status === 200) {
            this.goBack();
           }
           this.toastr.clear(infoToast.toastId);
           this.loading = false;
          } else {
            // this.toastr.error('Failed', 'Some problem occured while sending notification to doctor');                            
            this.loading = false;
          }
          
          
        });
    }
      
  }

  goBack() {
    this._location.back()
 }

 
 navigateEditTranscription(prevNext:number) {
   if(this.loading) return;

  const next = prevNext > 0;
  const prev = prevNext < 0;

  const nextIndexToShow = this.currentFileIndex + prevNext;
  if(nextIndexToShow>=0 && nextIndexToShow < this.transcriptionFiles.length) {
    this.currentFileIndex = nextIndexToShow;
    localStorage.setItem('selectedFile', JSON.stringify(this.transcriptionFiles[nextIndexToShow]));
    this.initializeData();
  }

  
}

initNextPrev() {
    this.isNextDisabled = (this.currentFileIndex+1) >=  this.transcriptionFiles.length;
    this.isPrevDisabled = (this.currentFileIndex-1) < 0;
    
} 

}

