import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, DoCheck, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CensusPatientListService } from 'src/app/includes/census-patient-list/census-patient-list.service';
import { PatientRoundingSheetService } from 'src/app/includes/patient-rounding-sheets/patient-rounding-sheet.service';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ElasticSeachService } from 'src/app/services/elasticSearch.service';
import { NoteAddChargeService } from '../../../note-add-charge.service';
import { ConditionsComponent } from 'src/app/includes/patient-detail/conditions/conditions.component';
import { ChargeAction } from '../../note-add-charge.component';
import * as global from "../../../../../../includes/global"
import { DialogConfirmationComponent } from 'src/app/includes/census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { PhrasesService } from 'src/app/includes/phrases/phrases.service';
import { AuthService } from 'src/app/services/auth.service';
import { lastValueFrom } from 'rxjs';
import { IcdCodesService } from 'src/app/services/Modules/icd-codes.service';

import { NoteCommentsService } from '../../../../note-comments/note-comments.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-note-sidebar-icds',
  templateUrl: './note-sidebar-icds.component.html',
  styleUrls: ['./note-sidebar-icds.component.css']
})
export class NoteSidebarIcdsComponent implements OnInit, DoCheck {
  @Input() charge: any;
  @Input() note: any;
  @Input() tabsToBeDisplayed;
  @Input() currentCompany;
  @Output() icdsChange = new EventEmitter<any>();
  @ViewChild('pccDiagnoseChild') pccDiagnoseChild: ConditionsComponent
  fvrtICDs = [];
  allICDs = [];
  reserved_icds = [];
  selectedDiagnoses: any = []
  dataSourceFvrtICDs: any = new MatTableDataSource([])
  dataSourceAllICDs: any = new MatTableDataSource([])
  displayedColumns = ['fvrt', 'code', 'checkbox']
  selectedDisplayedColumns = ['code', 'checkbox']
  activeTab = '';
  diagnoseFilter = '';
  model = {
    owner_id: '',
    patient_id: '',
    rounding_sheet_id: '',
    icd_id: [],
    wound_diagnose_icds: [],
    billing_note: '',
    note: '',
    status: '',
    visit_date: '',
    facility_id: '',
    measure: false
  };
  searchFilter = {
    icd: ''
  };
  starter: Boolean = true;
  searchICD: Boolean = false;
  fvrtICDTab = true;
  allICDTab = false;
  @Input() noteEditorIcds: any[] = [];
  loaderId = 'loader-01';
  removed = true;
  public paginationDiagnosesAll: {
    pageNo: number,
    hasNextPage: boolean,
    hasPrevPage: boolean,
    totalCount: number,
    totalPages: number
  };
  undo: ChargeAction[] = [];
  redo: ChargeAction[] = [];
  allicdsLoaded = false;
  global = global;
  companyWound = false;
  timeoutId: any;
  isDataLoading: boolean;
  currentUser: any;
  constructor(
    private noteCommentsService: NoteCommentsService,
    private service: PatientRoundingSheetService,
    private _addChargeService: NoteAddChargeService,
    private censusPatientListService: CensusPatientListService,
    private _authService: AuthService,
    private loader: NgxUiLoaderService,
    private elasticSearch: ElasticSeachService,
    private _phraseService: PhrasesService,
    private dialog: MatDialog,
    private _ICDcodesService: IcdCodesService, 
    private _toaster: ToastrService,
  ) {
    this.paginationDiagnosesAll = {
      pageNo: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalCount: 0,
      totalPages: 0
    };
    this.currentUser = this._authService.currentUser;
  }
  ngDoCheck(): void {
    if (this.noteEditorIcds) {
      this.noteEditorIcds.forEach(_icd => {
        const exists = this.selectedDiagnoses.find(icd => icd._id == _icd._id)
        if (!exists) {
          if(_icd.wound_diagnose !== undefined && _icd.wound_diagnose === true){
            if(!this.model.wound_diagnose_icds.includes(_icd._id)){
              this.model.wound_diagnose_icds.push(_icd._id);
            }
          }
          this.addToModel(_icd, null, 'icd', false)
        }
      })
    }
  }


  async ngOnInit() {
    this.currentUser = this._authService.currentUser
    this.check_conditions()
    this.tabChange(this.tabsToBeDisplayed[0])
    if (this._authService.currentUser.company_type.toLowerCase().includes("wound")) {
      this.companyWound = true;
    }
    this.undo = [];
    this.redo = [];
    if (this.charge && Array.isArray(this.charge.icd_id)) {
      this.charge.icd_id.map(icd => {
        this.addToModel(icd, null, 'icd', true)
      })
    }
    if(this.charge && this.charge.wound_diagnose_icds){
      this.model.wound_diagnose_icds = this.charge.wound_diagnose_icds
    }
    this.arrangeIcds();
    this.allicdsLoaded = true;
    await this.getFvrtICDs();
    await this.getAllICDs();
    this._addChargeService.setisICD(true);
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedDiagnoses, event.previousIndex, event.currentIndex);
    // this.sidebarDiagnosisService.setSelectedICDs(this.selectedDiagnoses)
    this.model.icd_id = this.selectedDiagnoses.map(icd => icd._id);
    this.icdsChange.emit({selectedIcds: this.selectedDiagnoses, woundIcds: this.model.wound_diagnose_icds});
  }
  drop_(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.tabsToBeDisplayed, event.previousIndex, event.currentIndex);
    this.noteCommentsService.updateTabsOrder(this.tabsToBeDisplayed, 'diagnosis').subscribe((Response: any) => {
      if (Response.status == 200) {
        this._toaster.success("Setting Updated")
      }
      else {
        this._toaster.error("Something went wrong while updating setting.")
      }
    });
    this.check_conditions() 
  }
  tabChange(tab) {
    this.onTabClickDiagnose(tab)
    this.activeTab = tab;
  }
  check_conditions() {
    if (this.note.patient.source != 'MatrixCare') {
      this.tabsToBeDisplayed = this.tabsToBeDisplayed.filter(r => r != 'Matrix')
    }
    if (!this.note.patient.pcc_patientId) {
      this.tabsToBeDisplayed = this.tabsToBeDisplayed.filter(r => r != 'PCC Diagnosis')
    }
  }
  pccDiagnosesChange(icds) {
    const dnIcds = this.selectedDiagnoses.filter((icd) => {
      return !icd.pcc
    })
    let mergedArray = [...dnIcds, ...icds]
    let uniqueMergedArray = mergedArray.reduce((acc, icd) => {
      let alreadyExist = acc.find(a => a._id === icd._id)
      if (!alreadyExist) {
        return [...acc, icd]
      }
      return acc;
    }, [])
    this.selectedDiagnoses = uniqueMergedArray;
    this.model.icd_id = uniqueMergedArray.map(icd => icd._id);
    this.icdsChange.emit({selectedIcds: this.selectedDiagnoses, woundIcds: this.model.wound_diagnose_icds});
    console.log('this.selectedDiagnoses', this.selectedDiagnoses);

  }

  async getFvrtICDs() {
    let res: any = await this.service.getFavoriteICDs().toPromise()
    // @ts-ignore
    this.fvrtICDs = res.data.array;
    // @ts-ignore
    this.reserved_icds = res.data.array;
    for (let k = 0; k < this.selectedDiagnoses.length; k++) {
      for (let i = 0; i < this.fvrtICDs.length; i++) {
        if (this.fvrtICDs[i]._id == this.selectedDiagnoses[k]._id) {
          this.fvrtICDs[i].selected = true;
        }
      }
    }
    this.dataSourceFvrtICDs.data = this.fvrtICDs;
  }
  async getAllICDs(page = 1, search = "") {
    this.loader.startLoader(this.loaderId);
    this.isDataLoading = true;
    let response: any = await lastValueFrom (this.elasticSearch.searchICDCodewithPagination(search, (page + 1) * 10, 0));

    if (response.status == 200) {
      this.allICDs = response.data;
      // for (let i = 0; i < this.allICDs.length; i++) {
      //   this.allICDs[i]._id = this.allICDs[i]['mongo_id'];
      //   delete this.allICDs[i].mongo_id;
      // }
      for (let i = 0; i < this.allICDs.length; i++) {
        if (this.allICDs[i].description.trim() == '')
          this.allICDs[i].description = this.allICDs[i].mediumDescription;
        for (let j = 0; j < this.fvrtICDs.length; j++) {
          if (this.allICDs[i]._id.toString() == this.fvrtICDs[j]._id.toString()) {
            this.allICDs[i].fvrt = true;
          }
        }
      }
      // // uncomment if want to show selected area 
      for (let i = 0; i < this.fvrtICDs.length; i++) {
        if (this.fvrtICDs[i].description.trim() == '')
          this.fvrtICDs[i].description = this.fvrtICDs[i].mediumDescription;
        for (let k = 0; k < this.selectedDiagnoses.length; k++) {
          if (this.fvrtICDs[i]._id == this.selectedDiagnoses[k]._id) {
            this.fvrtICDs[i].selected = true;
          }
        }
      }
      // // initializing all checkboxes
      for (let k = 0; k < this.selectedDiagnoses.length; k++) {
        for (let i = 0; i < this.allICDs.length; i++) {
          if (this.allICDs[i]._id == this.selectedDiagnoses[k]._id) {
            this.allICDs[i].selected = true;
          }
        }
      }

      const totalCount = response.data.totalRecords;
      this.paginationDiagnosesAll.totalCount = totalCount;
      this.paginationDiagnosesAll.pageNo = page;
      this.initPaginationDiagnosesAll();
      this.dataSourceAllICDs.data = this.allICDs;
      this.loader.stopLoader(this.loaderId);
      this.isDataLoading = false;
    }
  }
  initPaginationDiagnosesAll() {
    this.paginationDiagnosesAll.totalPages = Math.ceil(this.paginationDiagnosesAll.totalCount / 10);
    if (this.allICDs) {
      this.paginationDiagnosesAll.hasNextPage = this.allICDs.length > 0 && this.paginationDiagnosesAll.pageNo < this.paginationDiagnosesAll.totalPages;
    }
    this.paginationDiagnosesAll.hasPrevPage = this.paginationDiagnosesAll.pageNo > 1;
  }
  clearSearchField() {
    this.diagnoseFilter = '';
  }
  addToModel(element, event, type, initialIcds = false) {
    console.log("element======: ", element);
    this.clearSearchField()
    if (type == 'icd') {
      if (this.undo.length == 4) {
        this.undo.shift();
      }
      if (element.selected) {
        let index = this.selectedDiagnoses.findIndex(icd => icd._id == element._id);
        console.log(index);
        if (index > -1) {
          if (this.allicdsLoaded) {
            this.undo.push({
              action: "remove",
              data: element
            });
          }
          element.selected = false;
          this.selectedDiagnoses.splice(index, 1);
          // this.model.icd_id.splice(index, 1)
          this.allICDs.map(d => {
            if (d._id == element.mongo_id) {
              d.selected = false;
            }
          })
          this.fvrtICDs.map(d => {
            if (d._id == element._id) {
              d.selected = false;
            }
          })
        }
        else {
          if (this.allicdsLoaded) {
            this.undo.push({
              action: "add",
              data: element
            });
          }
          element.selected = true;
          this.selectedDiagnoses.push(element);
          // this.model.icd_id.push(element._id);
          this.allICDs.map(d => {
            if (d._id == element._id) {
              d.selected = true;
            }
          })
          this.fvrtICDs.map(d => {
            if (d._id == element._id) {
              d.selected = true;
            }
          })
        }
      }
      else if (!element.selected) {
        if (this.allicdsLoaded) {
          this.undo.push({
            action: "add",
            data: element
          });
        }
        element.selected = true;
        this.selectedDiagnoses.push(element)
        // this.model.icd_id.push(element._id);
        this.allICDs.map(d => {
          if (d._id == element._id) {
            d.selected = true;
          }
        })
        this.fvrtICDs.map(d => {
          if (d._id == element._id) {
            d.selected = true;
          }
        })
      }
      let noteEditorIcdsIndex = this.noteEditorIcds.findIndex(icd => icd._id === element._id);
      if (noteEditorIcdsIndex > -1) {
        this.noteEditorIcds.splice(noteEditorIcdsIndex, 1)
      }
      this.model.icd_id = this.selectedDiagnoses.map(icd => icd._id);
      this.dataSourceFvrtICDs.data = this.fvrtICDs;
      // this.censusPatientListService.setICDsForCharge(this.model.icd_id); 
      // console.log("this.model.icd_id: ", this.model.icd_id);
      if(!initialIcds){
        this.icdsChange.emit({selectedIcds: this.selectedDiagnoses, woundIcds: this.model.wound_diagnose_icds});
      }
      this.arrangeIcds();
    }
  }
  removeDiagnose(diagnose, removeWoundDiagnoseICDs = false) {

    let noteEditorIcdsIndex = this.noteEditorIcds.findIndex(icd => icd._id === diagnose._id);
    if (noteEditorIcdsIndex > -1) {
      this.noteEditorIcds.splice(noteEditorIcdsIndex, 1)

    }
    let index = this.selectedDiagnoses.findIndex(icd => icd._id === diagnose._id);
    if (index > -1) {
      this.selectedDiagnoses.splice(index, 1)
      // this.model.icd_id = this.selectedDiagnoses.map(icd => icd._id)
    }
    // remove from noteEditorIcds
    let noteEditorIcds_index = this.noteEditorIcds.findIndex(item => item._id === diagnose._id);
    if (noteEditorIcds_index > -1) {
      this.noteEditorIcds.splice(noteEditorIcds_index, 1)
    }
    this.model.icd_id = this.selectedDiagnoses.map(icd => icd._id)
    this.allICDs.map(a => {
      if (a._id == diagnose._id) {
        a.selected = false;
      }
    })
    this.dataSourceAllICDs.data = this.allICDs;
    this.fvrtICDs.map(a => {
      if (a._id == diagnose._id) {
        a.selected = false;
      }
    });
    if (this.allicdsLoaded) {
      if (this.undo.length == 4) {
        this.undo.shift();
      }
      this.undo.push({
        action: "remove",
        data: diagnose
      });
    }
    const woundIndex = this.model.wound_diagnose_icds.findIndex((icd) => diagnose._id === icd );
    if(woundIndex > -1 && removeWoundDiagnoseICDs){
      this.model.wound_diagnose_icds.splice(woundIndex, 1);
    }
    this.icdsChange.emit({selectedIcds: this.selectedDiagnoses, woundIcds: this.model.wound_diagnose_icds});
    this.dataSourceFvrtICDs.data = this.fvrtICDs;
    // console.log("After removing icd : ", this.model.icd_id);

  }

  searchDiagnosesWithDelay(value) {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.searchDiagnose(value);
    }, 1000);
  }

  searchDiagnose(value) {
    this.diagnoseFilter = value;
    this.searchFilter.icd = value?.trim()?.toLowerCase();
    // if ((!value || value == '') && !this.starter) {
    //   return;
    // }
    if (this.allICDTab) {
      this.getAllICDs(1, value)
    }
    else if (this.fvrtICDTab) {
      this.applyFilterICD(this.searchFilter.icd);
    } else {
      this.pccDiagnoseChild?.applyFilterICD(this.searchFilter.icd);
    }
  }

  applyFilterICD(value) {
    if (value) {
      let filteredData = [];
      this.reserved_icds.map(a => {
        if (a?.code?.trim()?.toLowerCase()?.includes(value) || a?.description?.trim()?.toLowerCase()?.includes(value)) {
          filteredData.push(a)
        }
      })
      this.dataSourceFvrtICDs.data = filteredData;
    }
    else {
      this.dataSourceFvrtICDs.data = this.reserved_icds;
    }
  }
  onTabClickDiagnose(tab) {
    this.starter = false;
    if (tab == 'All') {
      this.searchICD = true;
      this.allICDTab = true;
      this.fvrtICDTab = false;
    }
    else if (tab == 'Favorites') {
      this.searchICD = false;
      this.fvrtICDTab = true;
      this.allICDTab = false;
    } else {
      this.searchICD = false;
      this.fvrtICDTab = false;
      this.allICDTab = false;
    }
    this.searchDiagnose(this.diagnoseFilter)
  }
  onListDropfvrtICDs(event: CdkDragDrop<any[]>) {
    let filter = 'icd_id'
    moveItemInArray(this.fvrtICDs, event.previousIndex, event.currentIndex);
    this.dataSourceFvrtICDs.data = this.fvrtICDs;
    this.service.rearrangeFavoriteCharges(this.fvrtICDs.map(c => c._id), filter)
      .subscribe((response: any) => {
      })
  }
  isICDChecked(diagnosis) {
    if (diagnosis.selected) return true;
    if (this.noteEditorIcds.findIndex(icd => icd.code === diagnosis.code) >= 0) {
      diagnosis.selected = true;
      return true;
    }
    return false;
  }

  removeFavouriteDiagnoses(diagnose) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: "Are you sure you want to remove this from the favourites?"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === "true") {
        let index = -1;
        for (let i = 0; i < this.fvrtICDs.length; i++) {
          if (diagnose._id == this.fvrtICDs[i]._id) {
            index = i;
          }
        }
        if (index > -1) {
          this.fvrtICDs.splice(index, 1)
          this.dataSourceFvrtICDs.data = this.fvrtICDs;
        }
        this.allICDs.map(diagnos => {
          if (diagnos._id == diagnose._id) {
            diagnos.fvrt = false;
            this.dataSourceAllICDs.data = this.allICDs;
          }
        })
        let data = {
          ids: diagnose._id,
          isRemove: true,
          filter: 'icd_id'
        };
        this.service.setFavoriteICDs(data).subscribe((response: any) => {

        })
      }
    });
  }
  addToFavouriteDiagnoses(diagnose) {
    this.loader.startLoader('loader-01');
    let data = {
      ids: diagnose._id,
      isRemove: false,
      filter: 'icd_id'
    };
    let icds = this.service.setFavoriteICDs(data).subscribe((response: any) => {
      if (response.status == 200) {
        this.fvrtICDs.push(diagnose);
        this.allICDs.map(diagnos => {
          if (diagnos.code == diagnose.code) {
            diagnos.fvrt = true;
            this.dataSourceAllICDs.data = this.allICDs;
          }
        })
        this.dataSourceFvrtICDs.data = this.fvrtICDs;
        this.loader.stopLoader('loader-01');
      }
    })

  }
  handlePrevClick() {
    this.getAllICDs(this.paginationDiagnosesAll.pageNo - 1, this.searchFilter.icd);
  }
  handleNextClick(type) {
    this.getAllICDs(this.paginationDiagnosesAll.pageNo + 1, this.searchFilter.icd);
  }

  getCountofCodes() {
    // return ((this.paginationDiagnosesAll.pageNo * 10) - 9) + ' - ' + (((this.paginationDiagnosesAll.pageNo * 10) - 10) + Number(this.allDiagnoses.length)) + " out of " + (this.paginationDiagnosesAll.totalCount);
    return '1 - ' + (Number(this.allICDs.length)) + " out of " + (this.paginationDiagnosesAll.totalCount);
  }
  undoAction() {
    if (this.undo.length > 0) {
      this.allicdsLoaded = false;
      let ele = this.undo.pop();
      if (ele.action == "add") {
        this.removeDiagnose(ele.data);
      } else {
        this.addToModel(ele.data, null, 'icd');
      }
      if (this.redo.length == 4) {
        this.redo.shift();
      }
      this.redo.push(ele);
      this.allicdsLoaded = true;
    }
  }
  redoAction() {
    if (this.redo.length > 0) {
      this.allicdsLoaded = false;
      let ele = this.redo.pop();
      if (ele.action == "add") {
        this.addToModel(ele.data, null, 'icd');
      } else {
        this.removeDiagnose(ele.data);
      }
      if (this.undo.length == 4) {
        this.undo.shift();
      }
      this.undo.push(ele);
      this.allicdsLoaded = true;
    }
  }
  checkSigneNote() {
    if (this.charge == null || this.charge.status == "draft") {
      return false;
    } else {
      return true;
    }
  }
  toggleICDsinHistory(icd) {
    this.addToModel(icd, null, 'icd');
  }
  toggleFvrtICDsinHistory(data) {
    if (data.action === "remove") {
      this.removeFavouriteDiagnoses(data.icd);
    } else {
      this.addToFavouriteDiagnoses(data.icd);
    }
  }
  getSelectedDiagonoseCopy() {
    return JSON.parse(JSON.stringify(this.selectedDiagnoses));
  }
  async refreshicd10codes() {
    // console.log(this.note);
    let response: any = await this._phraseService.resolveWoundPhrase(null, this.note.patient._id, true, undefined, null, false, true).toPromise();
    if (response.status == 200) {
      while(this.selectedDiagnoses.length > 0){
        this.removeDiagnose(this.selectedDiagnoses[0]);
      }
      response.data.forEach((diagnose) => {
        if (diagnose.wound_diagnosis) {
          diagnose.wound_diagnosis.icd_id.forEach((icd10) => {
            if (!this.selectedDiagnoses.find((icd) => icd._id === icd10._id)) {
              this.addToModel(icd10, null, 'icd')
            }
          });
        }
      });
      // // uncomment if want to show selected area 
      this.dataSourceAllICDs.data = this.allICDs;
      this.dataSourceFvrtICDs = this.fvrtICDs;
    }
  }
  arrangeIcds(){
    const selectedIcds = this.selectedDiagnoses.map((icd)=> icd._id);
    let icdIdsArranged = this.model.wound_diagnose_icds.concat(selectedIcds.filter((icd)=> !this.model.wound_diagnose_icds.includes(icd)));
    if(icdIdsArranged.length !== selectedIcds.length){
      icdIdsArranged = icdIdsArranged.filter((icd)=> selectedIcds.includes(icd));
    }
    this.selectedDiagnoses = this.selectedDiagnoses.sort(function (a, b) {
      return icdIdsArranged.indexOf(a._id.toString()) - icdIdsArranged.indexOf(b._id.toString());
    });
  }
  
  shouldDisableCheckbox() {
    const isSameProvider = this.note.provider._id === this.currentUser._id && this.currentUser.user_type === global.USER_TYPE.DOCTOR;
    const isAssociatedProvider = this.note.provider._id != this.currentUser._id && this.currentUser.user_type === global.USER_TYPE.DOCTOR;
    const isMedicalAssistant = this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT;
    if(isSameProvider || isMedicalAssistant) {
      return false;
    }
    if(isAssociatedProvider) {
      return true;
    }
  }
}
