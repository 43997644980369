import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TemplateExplorerService } from 'src/app/includes/templates/template-explorer/template-explorer.service';
import { WoundService } from 'src/app/services/wound.service';

@Component({
  selector: 'app-add-new-cpt-code',
  templateUrl: './add-new-cpt-code.component.html',
  styleUrls: ['./add-new-cpt-code.component.css']
})
export class AddNewCptCodeComponent implements OnInit {
  newCPTmodel = {
    conceptId: "",
    code: "",
    clinicianDescriptorId: "",
    title: "",
    description: "",
    place_of_service: "",
    company_id: null
  };
  loaderId = 'app-add-new-cpt-code-dialog';
  cpts: any;
  searchTextCode : any;
  constructor(
    private toastr: ToastrService,
    private loader: NgxUiLoaderService,
    private _woundService: WoundService,
    private dialogRef: MatDialogRef<AddNewCptCodeComponent>,
    private templateExplorerService: TemplateExplorerService,
    @Inject(MAT_DIALOG_DATA) public data: AddNewCptCodeComponent,

  ) { }

  async ngOnInit() {
    console.log('data',this.data);
    await this.initcptCodes();
  }

  async initcptCodes() {
    return new Promise<void>((resolve, reject) => {
      this.templateExplorerService.getAllCPTs().subscribe((response: any) => {
        if (response.status == 200) {
          this.cpts = response.data;
          resolve(null);
        } else {
          console.log('something went wrong');
        }
      }, err => {
        reject();
        console.log('something went wrong', err);
      });
    })
  }

  async handleSubmit(e) {
    this.loader.startLoader(this.loaderId);
    let toastrId = this.toastr.info("Adding New Code", "Please Wait...", { disableTimeOut: true });
    try {
      this.newCPTmodel.company_id = this.data;
      console.log('this.newCPTmodel',this.newCPTmodel);
      let response: any = await this._woundService.addNewCPTCode(this.newCPTmodel).toPromise();
      if (response.status == 200) {
        this.toastr.clear(toastrId.toastId);
        // this.toastr.success("Code Added Succesfully", "Success");
        this.loader.stopLoader(this.loaderId);
        this.dialogRef.close({ data: response });
      } else {
        this.toastr.clear(toastrId.toastId);
        this.loader.stopLoader(this.loaderId);
        this.toastr.error(response.message, 'Error');
      }
    } catch (err) {
      this.toastr.clear(toastrId.toastId);
      this.loader.stopLoader(this.loaderId);
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  keyPressAlphanumeric(event) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9\s/-_-.-]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  clearDetails() {
    this.newCPTmodel = {
      conceptId: "",
      code: "",
      clinicianDescriptorId: "",
      title: "",
      description: "",
      place_of_service: "",
      company_id: null
    };
  }
}