import { Component, OnInit, Input } from '@angular/core';
import { PCCService } from 'src/app/services/pcc.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatTableDataSource } from '@angular/material/table';
import * as global from '../../global'
@Component({
  selector: 'app-immunizations',
  templateUrl: './immunizations.component.html',
  styleUrls: ['./immunizations.component.css']
})
export class ImmunizationsComponent implements OnInit {
  global=global;
  @Input('patient') patient;
  // @Input('pccOrgUuid') pccOrgUuid;
  constructor(private _pccService: PCCService,
    private toastr:ToastrService,
    private loader: NgxUiLoaderService) { }
  pccOrgUuid:string;
  apiData;
  page = 1;
  dataSource;
  displayedColumns = [
    // "immunizationId",
    // "patientId",
    "administrationDateTime",
    "immunization",
    // "cvxCode",
    // "consentStatus",
    // "consentConfirmedBy",
    // "consentConfirmedDate",
    // "educationProvidedDate",
    // "educationProvidedBy",
    // "educationProvided",
    // "routeOfAdministration",
    // "amountAdministered", // { "value", "unit", },
    // "locationGiven",
    // "manufacturerName",
    // "substanceExpirationDate",
    // "lotNumber",
    // "administeredBy",
    // "notes",
    // "induration",
    // "results",
    // "multiStep",
    // "nextStepRequired",
    // "given",
    // "resultsPending",
    // "trackResults",
    // "createdDateTime",
    // "step",
  ];

  loaderId ='immunizations-loader';
  inprogress = false;

  ngOnInit(): void {
    this.pccOrgUuid = this.patient.facility_id_ref.pcc_orgUuid;
    this.loadData(this.page);
  }

  loadData(page) {
    this.loader.startLoader(this.loaderId);

    let authMode;
    if(!this.patient.facility_id_ref.pcc_2_legged_authentication) {
      authMode = 'APP'
    }
    this._pccService.getImmunizations({
        pccOrgUuid: this.pccOrgUuid,
        pccPatientId: this.patient.pcc_patientId,
        pccPage: String(page),
        authMode
      }).subscribe((response: any) => {

      if (response.status === 200) {
        this.apiData = response.data;
        this.dataSource = new MatTableDataSource(this.apiData.data);
        this.page = page;
      } else {
        this.toastr.error('Something went wrong while fetching Immunizations data');
      }
    }, (error) => {
      this.toastr.error('Something went wrong while fetching Immunizations data');
    }, () => {
      this.loader.stopLoader(this.loaderId);
    })
  }
  navigate(prevNext) {
    const newPageNo = this.page + prevNext;
    this.loadData(newPageNo);
  }

}
