import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class CKEditorService {
    private config = {
        mentions: [],
        extraPlugins: 'divarea,justify,spacingsliders,autocomplete,image',// autoplaceholder,
        autoplaceholder: {
            tokenList: {
            },
            defaultText: 'Add Value'
        },
        spacingsliders: {
            lineheight_min: 1,
            lineheight_max: 20,
            letterspacing_min: 0,
            letterspacing_max: 20
        },
        font_defaultLabel: 'Times New Roman',
        // allowedContent: "span[data-*]",
        extraAllowedContent: "p(text-align-center,text-align-right); img; p span table; ul.list_based_phrase",
        removePlugins: 'font,scayt,wsc',
        // removeButtons: 'NumberedList,BulletedList',
        exportPdf_fileName: function (editor) {
            const $title = document.querySelector('#note-title') as HTMLInputElement;
            return $title ? $title.value : 'Note';
        }
    };
    private editorInstance = null;

    async saveData() {

    }

    getDefaultConfig() {
        return this.config;
    }

    ckeditorRemoveTitle( editor ) {
        const cke_wysiwyg_div = editor.container.$.querySelector('.cke_wysiwyg_div');
        cke_wysiwyg_div.removeAttribute('title');
        cke_wysiwyg_div.removeAttribute('aria-label');
    }

    setEditorInstance( instance ) {
        this.editorInstance = instance;
    }

    getEditorInstance() {
        return this.editorInstance;
    }

    destroyEditorInstance() {
        this.editorInstance = null;
    }

    getCursorRootParent() {
        try {
            const
                selection = this.editorInstance?.getSelection(),
                startElement = selection.getStartElement(),
                editorClassName = 'cke_wysiwyg_div';

            let parentElement = startElement;

            do {
                const nextParentElement = parentElement.getParent();

                if ( nextParentElement?.hasClass( editorClassName ) === true ) {
                    break;
                }

                parentElement = nextParentElement;
            } while ( !parentElement?.hasClass( editorClassName ));

            return parentElement || null;
        } catch ( error ) {
            return null;
        }
    }

    getCursorDirectParent() {
        try {
            const selection = this.editorInstance?.getSelection();

            return selection.getStartElement() || null;
        } catch ( error ) {
            return null;
        }
    }

    isCursorOnEmptyLine() {
        const selection = this.editorInstance?.getSelection();

        if ( selection == null || selection.getRanges().length === 0 ) {
            return true;
        }

        const pElem = this.getCursorRootParent();

        if ( pElem == null ) {
            return true;
        }

        return pElem.getText?.()?.trim() === '';
    }

    isCursorAtStartOfLine() {
        const
            selection = this.editorInstance?.getSelection(),
            ranges = selection?.getRanges();
        
        if ( !ranges?.length ) {
            return false;
        }
    
        const
            range = ranges[ 0 ],
            cursorPosition = range.startOffset;
    
        return cursorPosition === 0;
    }

    moveNextForNewHeading() {
        if ( this.editorInstance == null ) {
            return;
        }

        const range = this.editorInstance.createRange();
        range.moveToElementEditEnd( range.root );
        this.editorInstance.getSelection().selectRanges( [ range ] );
        this.editorInstance.execCommand( 'enter' );
        this.editorInstance.execCommand( 'enter' );
    }
}
