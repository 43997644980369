<div class="container flex flex-col">
    <h3 class="top-bar flex justify-between items-center">
        <button *ngIf="isCompanySide" type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
            Back
        </button>
        <div class="font">Wound Outcome Report
        </div>

        <div class="flex justify-center items-end" style="color: black !important;">
            <input matInput type="text" ngxDaterangepickerMd [(ngModel)]="filter.last_assessment_date_obj"
                class="mat-like-input" [ranges]="preDateRanges" [alwaysShowCalendars]="true" [maxDate]="currentDate"
                [linkedCalendars]="true" [showClearButton]="false" (datesUpdated)="handleDateOfServiceChange($event)"
                class="date-range-picker" />
        </div>

        <div class="flex flex-row gap-2">
            <mat-icon matTooltip="Download Report" style="cursor: pointer;"
                [matMenuTriggerFor]="sendMenu">download</mat-icon>

            <mat-menu #sendMenu="matMenu" xPosition="before">
                <button mat-menu-item
                    (click)="convertToXLSX();">Export
                    As XLSX</button>

                <button mat-menu-item (click)="convertToPDF();">Export
                    As PDF</button>
            </mat-menu>
        </div>

    </h3>
    <mat-card class="flex flex-col" style="height: calc(99% - 45px);">

        <div class="flex items-center gap-2">

            <mat-form-field appearance="standard" style="flex: 12%;">
                <mat-label>Days To Remission</mat-label>
                <mat-select (selectionChange)="onChangeReportType($event);" [value]="filter.reportType">
                    <mat-option value="avg">Average Days to Remission</mat-option>
                    <mat-option value="median">Median Days to Remission</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard">
                <mat-label>Display Data By</mat-label>
                <mat-select (selectionChange)="filter.displayBy=$event.value;" [value]="filter.displayBy">
                    <mat-option value="facility">Facility</mat-option>
                    <mat-option value="wound_outcomes">Wound Outcomes</mat-option>
                    <mat-option value="provider">Provider</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" class="w-2/5">
                <mat-label>Facility</mat-label>
                <mat-select multiple [value]="selectedFacilities" (closed)="searchText='';">
                    <mat-select-trigger>
                        <div class="justify-start flex items-center">
                            <span>{{selectedFacility ? selectedFacility.title : ''}}</span>
                            <span *ngIf="selectedFacilities.length > 1">
                                (+{{ selectedFacilities.length - 1 }} {{ selectedFacilities.length === 2 ? 'other' :
                                'others' }})
                            </span>
                        </div>
                    </mat-select-trigger>
                    <input #searchFacilitiesInput matInput placeholder="Search" [(ngModel)]="searchText"
                        [ngModelOptions]="{standalone: true}"
                        style="color:#000;height: 40px;padding-left: 5px; ; position: sticky; top: 0; z-index:1000; background-color: lightgray; "
                        (keydown)="$event.stopPropagation()" />
                    <mat-option value="all" disabled> All </mat-option>
                    <mat-option (onSelectionChange)="onSelectFacility($event, facility);"
                        *ngFor="let facility of facilities | searchFacility: searchText" [value]="facility._id"
                        style="color: rgb(43, 41, 41);">
                        {{facility.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" class="w-1/3">
                <mat-label>Provider</mat-label>
                <mat-select multiple [value]="selectedProviders" (closed)="searchTextProvider='';">
                    <mat-select-trigger>
                        <div class="justify-start flex items-center">
                            <span>
                                {{selectedProvider ? selectedProvider.last_name + ', ' + selectedProvider.first_name :
                                ''}}
                            </span>
                            <span *ngIf="selectedFacilities.length > 1">
                                (+{{ selectedFacilities.length - 1 }} {{ selectedFacilities.length === 2 ? 'other' :
                                'others' }})
                            </span>
                        </div>
                    </mat-select-trigger>
                    <input #searchProvidersInput matInput placeholder="Search" [(ngModel)]="searchTextProvider"
                        [ngModelOptions]="{standalone: true}"
                        style="color:#000;height: 40px;padding-left: 5px; ; position: sticky; top: 0; z-index:1000; background-color: lightgray; "
                        (keydown)="$event.stopPropagation()" />
                    <mat-option value="all" disabled> All </mat-option>
                    <mat-option (onSelectionChange)="onSelectProvider($event, provider);"
                        *ngFor="let provider of providers | searchProvider : searchTextProvider" [value]="provider._id"
                        style="color: rgb(43, 41, 41);">
                        {{provider.full_name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-raised-button class="dn-button-primary" (click)="initWoundOutcomeReport();"
                [disabled]="filter.facilities.length === 0 && filter.providers.length === 0 
                || filter.displayBy === 'provider' && filter.providers.length === 0 
                || filter.displayBy === 'facility' && filter.facilities.length === 0 ">Apply</button>
        </div>

        <div style="overflow-x: auto;">
            <div *ngFor="let data of report; let i=index;">
                <h4 *ngIf="reportDisplayType === 'provider'">{{data.full_name}}</h4>
                <h4 *ngIf="reportDisplayType === 'facility'">{{data.title}}</h4>
                <table id="table{{i}}" mat-table [dataSource]="data.woundOutcomeReport" class="mat-elevation-z8"
                    matSort>

                    <ng-container matColumnDef="etiolgy">
                        <th mat-header-cell *matHeaderCellDef> Primary Etiology </th>
                        <td mat-cell *matCellDef="let element; let i = index;">
                            {{element._id}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef>
                            <h4 *ngIf="reportDisplayType === 'provider'">{{data.full_name}} Total:</h4>
                            <h4 *ngIf="reportDisplayType === 'facility'">{{data.title}} Total:</h4>
                            <h4 *ngIf="reportDisplayType === 'wound_outcomes'">Total:</h4>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="remission">
                        <th mat-header-cell *matHeaderCellDef> Total In Remission </th>
                        <td mat-cell *matCellDef="let element; let i = index;">
                            {{element.remission}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef>{{data.remissionSum}}</td>
                    </ng-container>

                    <ng-container matColumnDef="outcome">
                        <th mat-header-cell *matHeaderCellDef> Total Outcomes </th>
                        <td mat-cell *matCellDef="let element; let i = index;">
                            {{element.outcome}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef>{{data.outcomeSum}}</td>
                    </ng-container>

                    <ng-container matColumnDef="daysToRemission">
                        <th mat-header-cell *matHeaderCellDef> {{fetchedReportType === 'avg' ? 'Average Days To Heal' :
                            'Median Days To Heal'}} </th>
                        <td mat-cell *matCellDef="let element; let i = index;">
                            {{element.daysToRemission}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef>{{data.daysToRemissionSum}}</td>
                    </ng-container>

                    <ng-container matColumnDef="percentage">
                        <th mat-header-cell *matHeaderCellDef> % In Remission </th>
                        <td mat-cell *matCellDef="let element; let i = index;">
                            {{element.percentage}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef>{{data.percentageSum}}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns;"></tr>
                </table>

            </div>

            <table id="totalTable" *ngIf="isTotalBarVisible" mat-table [dataSource]="totalTable" class="mat-elevation-z8" matSort>

                <ng-container matColumnDef="etiolgy">
                    <th mat-header-cell *matHeaderCellDef> Overall Total: </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                    </td>
                </ng-container>

                <ng-container matColumnDef="remission">
                    <th mat-header-cell *matHeaderCellDef> Total In Remission </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.remissionTotal}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="daysToRemission">
                    <th mat-header-cell *matHeaderCellDef> {{fetchedReportType === 'avg' ? 'Average Days To Heal' :
                        'Median Days To Heal'}} </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.daysToRemissionTotal}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="outcome">
                    <th mat-header-cell *matHeaderCellDef> Total Outcome </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.outcomeTotal}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="percentage">
                    <th mat-header-cell *matHeaderCellDef> % in Remission </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.percentageTotal}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-card>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>