<app-header *ngIf="currentUser && currentUser.user_type != '6'"></app-header>
<app-biller-header *ngIf="currentUser && currentUser.user_type == '6'"></app-biller-header>
<main>
    <div class="container flex flex-col">

        <mat-card class="flex-grow">
            <mat-card-content>

                <div class="header flex justify-between items-center">
                    <span>Facesheet</span>
                    <div class="flex gap-2">
                        <button mat-raised-button class="dn-button-secondary" (click)="goBack()">Go Back</button>
                        <button mat-raised-button class="dn-button-primary" *ngIf="patient"
                            (click)="generateFacesheetAndDownload(patient._id)">Download as PDF</button>
                    </div>
                </div>
                <br />
                <div style="position: relative;"> 

                    <mat-grid-list role="list" cols="7" rowHeight="50px" style="margin-bottom: 1px;">
                        <mat-grid-tile class="heading-tile">
                            <mat-label>Patient Name</mat-label>
                        </mat-grid-tile>
                        <mat-grid-tile class="heading-tile">
                            <mat-label>DOB</mat-label>
                        </mat-grid-tile>
                        <mat-grid-tile class="heading-tile">
                            <mat-label>Gender</mat-label>
                        </mat-grid-tile>
                        <mat-grid-tile class="heading-tile">
                            <mat-label>Phone #</mat-label>
                        </mat-grid-tile>
                        <mat-grid-tile class="heading-tile">
                            <mat-label>SSN #</mat-label>
                        </mat-grid-tile>
                        <mat-grid-tile class="heading-tile">
                            <mat-label>Admit Date</mat-label>
                        </mat-grid-tile>
                        <mat-grid-tile class="heading-tile">
                            <mat-label>Authorization #</mat-label>
                        </mat-grid-tile>
                    </mat-grid-list>

                    <ng-container *ngIf="patient && patient.pcc_patientId">
                        <mat-grid-list role="list" cols="7" rowHeight="50px">
                            <mat-grid-tile>{{patient.name}}</mat-grid-tile>
                            <mat-grid-tile>{{patient.date_of_birth | date: 'MM/dd/yyyy' }}</mat-grid-tile>
                            <mat-grid-tile>{{patient.gender}}</mat-grid-tile>
                            <mat-grid-tile>{{patient.phone}}</mat-grid-tile>
                            <mat-grid-tile>{{patient.pcc_payload?.ssn}}</mat-grid-tile>
                            <mat-grid-tile>{{getAdmissionDate(patient)}}</mat-grid-tile>
                            <mat-grid-tile> </mat-grid-tile>
                        </mat-grid-list>

                        <mat-grid-list role="list" cols="7" rowHeight="50px" style="margin-top: 30px;">
                            <mat-grid-tile class="heading-tile" colspan="2">
                                <mat-label class="text-inside-grid">Recent address of the patient</mat-label>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5">
                                <div class="text-inside-grid">
                                    {{patient.address || ''}}
                                </div>
                            </mat-grid-tile>

                            <mat-grid-tile class="heading-tile" colspan="2">
                                <mat-label class="text-inside-grid">Primary Insurance</mat-label>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5">
                                <div class="text-inside-grid">
                                    {{formatInsuranceName(patient.pcc_coverage_data, 'Primary')}}
                                </div>
                            </mat-grid-tile>

                            <mat-grid-tile class="heading-tile" colspan="2">
                                <mat-label class="text-inside-grid">Secondary Insurance</mat-label>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5">
                                <div class="text-inside-grid">
                                    {{formatInsuranceName(patient.pcc_coverage_data, 'Secondary')}}
                                </div>
                            </mat-grid-tile>

                            <mat-grid-tile class="heading-tile" colspan="2">
                                <mat-label class="text-inside-grid">Tertiary Insurance</mat-label>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5">
                                <div class="text-inside-grid">
                                    {{formatInsuranceName(patient.pcc_coverage_data, 'Tertiary')}}
                                </div>
                            </mat-grid-tile>

                            <mat-grid-tile class="heading-tile" colspan="2">
                                <mat-label class="text-inside-grid">Primary Insurance - Policy Number</mat-label>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5">
                                <div class="text-inside-grid">
                                    {{formatPolicyNumber(patient.pcc_coverage_data, 'Primary')}}
                                </div>
                            </mat-grid-tile>

                            <mat-grid-tile class="heading-tile" colspan="2">
                                <mat-label class="text-inside-grid">Secondary Insurance - Policy Number</mat-label>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5">
                                <div class="text-inside-grid">
                                    {{formatPolicyNumber(patient.pcc_coverage_data, 'Secondary')}}
                                </div>
                            </mat-grid-tile>

                            <mat-grid-tile class="heading-tile" colspan="2">
                                <mat-label class="text-inside-grid">Tertiary Insurance - Policy Number</mat-label>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5">
                                <div class="text-inside-grid">
                                    {{formatPolicyNumber(patient.pcc_coverage_data, 'Tertiary')}}
                                </div>
                            </mat-grid-tile>

                            <mat-grid-tile class="heading-tile" colspan="2">
                                <mat-label class="text-inside-grid">Medicaid Number</mat-label>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5">
                                <div class="text-inside-grid">
                                    {{patient?.pcc_payload?.medicaidNumber || ""}}
                                </div>
                            </mat-grid-tile>

                            <mat-grid-tile class="heading-tile" colspan="2">
                                <mat-label class="text-inside-grid">Medicare Beneficiary Identifier</mat-label>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5">
                                <div class="text-inside-grid">
                                    {{patient?.pcc_payload?.medicareBeneficiaryIdentifier || ""}}
                                </div>
                            </mat-grid-tile>

                        </mat-grid-list>
                    </ng-container>
                    
                    <ng-container *ngIf="patient && patient.source == 'MatrixCare'">
                        <mat-grid-list role="list" cols="7" rowHeight="50px">
                            <mat-grid-tile>{{patient.name}}</mat-grid-tile>
                            <mat-grid-tile>{{patient.date_of_birth | date: 'MM/dd/yyyy' }}</mat-grid-tile>
                            <mat-grid-tile>{{getPatientGender(patient.gender)}}</mat-grid-tile>
                            <mat-grid-tile>{{patient.phone}}</mat-grid-tile>
                            <mat-grid-tile>{{patient.patient_ssn}}</mat-grid-tile>
                            <mat-grid-tile>{{patient.last_admit_date | date: 'MM/dd/yyyy'}}</mat-grid-tile>
                            <mat-grid-tile> </mat-grid-tile>
                        </mat-grid-list>

                        <mat-grid-list role="list" cols="7" rowHeight="50px" style="margin-top: 30px;">
                            <mat-grid-tile class="heading-tile" colspan="2">
                                <mat-label class="text-inside-grid">Recent address of the patient</mat-label>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5">
                                <div class="text-inside-grid">
                                    {{patient.address || ''}}
                                </div>
                            </mat-grid-tile>

                            <mat-grid-tile class="heading-tile" colspan="2">
                                <mat-label class="text-inside-grid">Primary Insurance</mat-label>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5">
                                <div class="text-inside-grid">
                                    {{getInsuranceCompany('Primary','company')}}
                                </div>
                            </mat-grid-tile>

                            <mat-grid-tile class="heading-tile" colspan="2">
                                <mat-label class="text-inside-grid">Secondary Insurance</mat-label>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5">
                                <div class="text-inside-grid">
                                    {{getInsuranceCompany('Secondary', 'company')}}
                                </div>
                            </mat-grid-tile>

                            <mat-grid-tile class="heading-tile" colspan="2">
                                <mat-label class="text-inside-grid">Tertiary Insurance</mat-label>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5">
                                <div class="text-inside-grid">
                                    {{getInsuranceCompany('Tertiary', 'company')}}
                                </div>
                            </mat-grid-tile>

                            <mat-grid-tile class="heading-tile" colspan="2">
                                <mat-label class="text-inside-grid">Primary Insurance - Policy Number</mat-label>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5">
                                <div class="text-inside-grid">
                                    {{getInsuranceCompany('Primary', 'policy')}}
                                </div>
                            </mat-grid-tile>

                            <mat-grid-tile class="heading-tile" colspan="2">
                                <mat-label class="text-inside-grid">Secondary Insurance - Policy Number</mat-label>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5">
                                <div class="text-inside-grid">
                                    {{getInsuranceCompany('Secondary', 'policy')}}
                                </div>
                            </mat-grid-tile>

                            <mat-grid-tile class="heading-tile" colspan="2">
                                <mat-label class="text-inside-grid">Tertiary Insurance - Policy Number</mat-label>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="5">
                                <div class="text-inside-grid">
                                    {{getInsuranceCompany('Tertiary', 'policy')}}
                                </div>
                            </mat-grid-tile>
                        </mat-grid-list>
                    </ng-container>
                    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
                </div>

            </mat-card-content>
        </mat-card>

    </div>
</main>