import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  @Input() first_page_no:number = 1;
  @Input() current_page_no:number = 1;
  @Input() last_page_no:number = null;
  @Output() onClickNext: EventEmitter<void> = new EventEmitter();
  @Output() onClickPrev: EventEmitter<void> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  isNextVisible() {
    return this.last_page_no ? this.current_page_no < this.last_page_no : false
  }
  isPrevVisible() {
  return this.current_page_no > this.first_page_no;
  }
}
