<div style="display: flex;justify-content: center">
    <mat-form-field class="form-filed-time">
        <mat-label>{{label}}</mat-label>
        <input type="text" matInput [readonly]="readonly" [value]="value" />
        <span matSuffix style="cursor: pointer;" [class.disabled]="isDisabledWatch" (click)="showDropDown(true)" class="material-symbols-outlined icon-navy">
            schedule 
        </span>
    </mat-form-field>
</div>
<div *ngIf="showdropdown" class="main">
    <div class="flex flex-col">
        <div class="flex flex-grow justify-center items-center" style="border-bottom: 1px solid #b7b7b7;padding: 5px;
        font-size: smaller;
        font-weight: bolder;">
            <div class="flex-grow" style="text-align: center;">Hour</div>
            <div class="flex-grow" style="text-align: center;">Minutes</div>
            <div *ngIf="format == '12'" class="flex-grow" style="text-align: center;">Meridiem</div>
        </div>
        <div class="flex flex-grow" style="height: 174px;position: sticky;font-size: 14px;">
            <div class="flex flex-col flex-grow justify-start items-center"
                style="overflow-y: auto;border-right: 1px solid #b7b7b7;">
                <div *ngFor="let hour of hourlist;let i=index"
                    [ngClass]="selectedHour == hour ? 'selected' : 'deselect'"
                    style="width: 100%;text-align: center;cursor: pointer;" (click)="selectHour(hour)">
                    {{hour}}
                </div>
            </div>
            <div class="flex flex-col flex-grow justify-start items-center"style="overflow-y: auto;">
                <div *ngFor="let min of minlist;let i=index" style="width: 100%;text-align: center;cursor: pointer;"
                    [ngClass]="selectedMin == min ? 'selected' : 'deselect'" (click)="selectMin(min)">
                    {{min}}
                </div>
            </div>
            <div *ngIf="format == '12'" class="flex flex-col flex-grow justify-center items-center"
                style="overflow-y: auto;border-left: 1px solid black;">
                <div *ngFor="let med of meridiemlist;let i=index"
                    style="width: 100%;text-align: center;cursor: pointer;"
                    [ngStyle]="{'margin-bottom': i == 1 ? '11px' : '0px'}"
                    [ngClass]="selectedMed == med ? 'selected' : 'deselect'" (click)="selectMed(med)">
                    {{med}}
                </div>
            </div>
        </div>
        <div class="flex flex-grow justify-center items-center" style="border-top: 1px solid #b7b7b7;padding: 5px;">
            <div class="flex-grow" style="text-align: center;color: green;">
                <mat-icon matSuffix style="cursor: pointer;" (click)="confirmTime()">check</mat-icon>
            </div>
            <div class="flex-grow" style="text-align: center;color: red;">
                <mat-icon matSuffix style="cursor: pointer;" (click)="showDropDown(false)">close</mat-icon>
            </div>
        </div>
    </div>
</div>