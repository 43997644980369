import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import * as global from '../global';
import { AuthService } from '../../services/auth.service';
import { API_URL } from '../../../environments/api-url';
import { CommonService } from 'src/app/services/common.service';

@Injectable({
  providedIn: 'root',
})
export class CensusCalenderService {
  currentUser: any;
  company_id: any;

  constructor(
    private httpclient: HttpClient,
    private _authService: AuthService,
    private commonService: CommonService
  ) {
    this.currentUser = this._authService.currentUser;
  }

  
  getCensuses(filter) {
    this.commonService.castCompany.subscribe(company => {
      this.company_id = company?._id
    })

    if(!this.company_id){
      this.company_id=this.currentUser.company_id;
    }

    let details: any = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      companyId: this.company_id,

      ...filter
    };

    return this.httpclient
      .post(global.url + API_URL.CENSUS_CALENDER.getCensuses, { details })
      .pipe();
  }


  
}
