import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UserManagementService } from '../user-management/user-management.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { EmailTemplateService } from '../email-template/service/email-template.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogCensusColumnAdminComponent } from '../dialog-census-column-admin/dialog-census-column-admin.component';
import { FormControl } from '@angular/forms';
import { MipsService } from 'src/app/services/Modules/mips.service';
import * as global from '../../includes/global'
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-manage-companies',
  templateUrl: './manage-companies.component.html',
  styleUrls: ['./manage-companies.component.css']
})
export class ManageCompaniesComponent implements OnInit, OnDestroy {
  @ViewChild('woundTab', { static: false }) woundTab;
  global = global;
  companies: any = [];
  public dataSource: any;
  displayedColumns: string[] = ['active', 'name', 'created_at', 'providerCount', 'facilityCount',
    'pccFacilityCount',
    // 'nonPccFacilityCount',
    // 'email',
    // 'providerCount',
    // 'carry_charges',
    // 'census_inactive_reason',
    // 'signed_notes',
    'permissions',
    // 'patient_search_accross_company',
    // 'census_cols',
    // 'allow_hl',
    'operations'];
  @ViewChild(MatSort) sort: MatSort;
  isShowCompanyEmail: boolean = false;
  isManageCompaniesVisible: boolean = true;
  emailTemplateSubscription: any;
  isCompanySettingsVisible: boolean = false;
  currentCompany: any;
  providersCount = [];
  FacilitiesCount = [];
  pccFacilitiesCount = [];
  company_id: any;
  charge_edit_duration = 0;
  mipsQuestions: any[] = [];
  showMips: boolean = false;
  showNurseCensus: boolean = false;
  showNursePatients: boolean = false;
  isWoundOrSnfCompany: boolean = false;

  checkboxOptions = [
    { label: 'Must Sign Note for Submit a Charge', property: 'should_sign_note' },
    { label: 'Validate wound dates prior to note signing', property: 'wound_dates_for_sign_note' },
    { label: 'Carry Forward ICDs', property: 'charges_carry_forward_icds' },
    { label: 'Carry Forward CPTs', property: 'charges_carry_forward_cpts' },
    { label: 'Patient Search Across Company', property: 'patient_search_accross_company' },
    // { label: 'Allow HL', property: 'allow_hl' },
    { label: 'Census Inactive Reason', property: 'census_inactive_reason' },
    { label: 'View Eligibility Status', property: 'view_eligibility_status' },
    { label: 'Tags', property: 'should_show_tag' },
    { label: 'Update Patient Info While Submitting charge', property: 'can_edit_patient_while_submitting_charges' },
    { label: 'Out-patient module', property: 'show_outpatient_module' },
    { label: 'Show Assessment', property: 'should_show_wound_assessment' },
    { label: 'Note Time Logs', property: 'should_show_note_timelogs_tab', portal_type : 'non_wc' },
    { label: 'Allow Multiple CPTs to Submit Charge', property: 'allow_multiple_cpts', portal_type : 'non_wc' },
    { label: 'Enable Consent Form', property: 'should_show_consent_form', portal_type : 'non_wc' },
    { label: 'Tags', property: 'should_show_tag', portal_type : 'non_wc' },
    { label: 'Add Patients To census', property: 'add_patients_to_census', portal_type : 'non_wc' }, 
    { label: 'Wound Menu Location', property: 'should_show_wound_location' }, 
    { label: 'Skin Sub Refferal', property: 'should_show_skin_sub_refferal' }, 
    { label: 'Show Procedure Note', property: 'should_show_procedure_note' }, 
    // { label: 'Allow Users for Customized Tabs', property: 'should_allow_customized_tab' }, 
    { label: 'Auto Procedure Coding', property: 'should_allow_auto_procedure_coding' }, 
    { label: 'Encounters', property: 'should_show_encounter' }, 
  ];
  selectedCompany: any = {};
  @Input() dataForMyCompanies: any;
  myAssociationSide: boolean;
  woundOrSnfTypeCompanies: any = [];
  copyDataSettings = {
    copyDatasetFrom: null,
    copyDatasetTo: null,
    copyFromUserType: '1',
    copyToUserType: '1',
    copyAllData: true
  }
  reportsNames=
 [
  // { title: 'DNS Report', url: 'dns-report' , selected:false , user_type:[]},
  { title: 'DNS Healing Report', url: 'dns-healing-report' , selected:false , user_type:[]},
  // { title: 'DNS Tissue Analytic Report', url: 'dns-tissue-analytic-report', selected:false , user_type:[]},
  { title: 'Post-Rounds Wound Report', url: 'dns-tissue-analytic-report' , selected:false , user_type:[]},
  { title: 'QAPI Report', url: 'qapi-report' , selected:false , user_type:[]},
  // { title: 'Visit Summary Report', url: '' , selected:false , user_type:[]},
  // { title: 'Visit Report', url: 'visit-report' , selected:false , user_type:[]},
  // { title: 'Discharged Patient Report', url: 'discharged-patient-report' , selected:false , user_type:[]},
  // { title: 'Missed Facility Report', url: 'missed-facility-report' , selected:false , user_type:[]},
  // { title: 'Debridement Report', url: 'debridement-report' , selected:false , user_type:[]},
  // { title: 'Healing Success Rate Report', url: 'healing-success-rate-report' , selected:false , user_type:[]},
  // { title: 'UWH Type Tabular Report', url: 'uwh-type-tabular-report' , selected:false , user_type:[]},
  // {title : 'Kent Visit Report', url: 'kent-visit-report', selected:false , user_type:[]},
  { title: 'Charges Report', url: 'admin-charges-report' , selected:true, user_type:[]},
  { title: 'LOS Report', url: 'los-report' , selected:true, user_type:[]},
  { title: 'IPA Report', url: 'ipa-report' , selected:true, user_type:[]},
  { title: 'Operational Report', url: 'hp-operational-report' , selected:false , user_type:[]},
  { title: 'Wound Report by Etiology', navigationPath: 'pressure-wound-report', url: 'pressure-wound-report', selected: true, user_type: [] }, 
  { title: 'Custom Wound Report', navigationPath: 'custom-wound-report', url: 'custom-wound-report', selected: true, user_type: [] }, 
  { title: 'Wound Exceptions Report', navigationPath: 'wound-exceptions-report', url: 'wound-exceptions-report', selected: true, user_type: [] }, 
  { title: 'Post Rounds Report V2', navigationPath: 'post-rounds-report', url: 'post-rounds-report', selected: true, user_type: [] }, 
  { title: 'Wound Assessment Dashboard', navigationPath: 'telehealth-wound-assessment-report', url: 'telehealth-wound-assessment-report', selected: true, user_type: [] }, 
  { title: 'Days To Remission Report', navigationPath: 'days-to-remission-report', url: 'days-to-remission-report', selected: true, user_type: [] }, 
  { title: 'Wound Outcome Report', navigationPath: 'wound-outcome-report', url: 'wound-outcome-report', selected: true, user_type: [] }, 
];
  activeFilter: any;
  allPermissions = [];
  parentCompanyId: any;
  adminId: any;
  parentCompanyCategory: any;
  findTagPermissions: any;
  addPatientsToCensusPermissionId: any;
  constructor(
    private companyService: CompanyService,
    private _location: Location,
    private router: Router,
    private userManagementService: UserManagementService,
    private toastr: ToastrService,
    private _commonService: CommonService,
    private _emailTemplateService: EmailTemplateService,
    private dialog: MatDialog,
    private mipsService: MipsService,
    private route: ActivatedRoute,
    private commonService: CommonService
  ) {

  }

  async ngOnInit() {
    if (window.location.pathname.includes('company')) {
      const currentCompany = this.commonService.getLocalStorageItem('admin-Dashboard');
      if (currentCompany) {
        this.selectedCompany = currentCompany;
      }
    }
    this.dataSource = new MatTableDataSource();
    this.dataSource.sort = this.sort;
    // await this.getAllPermissions();
    this.activeFilter = 'true';
    if (this.dataForMyCompanies?.assosiation) {
      this.myAssociationSide = true;
      this.adminId = this.dataForMyCompanies.adminId;
      this.parentCompanyId = this.dataForMyCompanies.parentCompanyId;
      this.displayedColumns = ['name', 'created_at', 'providerCount', 'facilityCount',
        'pccFacilityCount',
        // 'nonPccFacilityCount',
        // 'email',
        // 'providerCount',
        // 'carry_charges',
        // 'census_inactive_reason',
        // 'signed_notes',
        'permissions',
        // 'patient_search_accross_company',
        // 'census_cols',
        // 'allow_hl',
        'operations'];
    }
    this.dataSource.filterPredicate = (data:
      { name: string }, filterValue: string) =>
      data.name.trim().toLowerCase().indexOf(filterValue?.toLowerCase()) !== -1;

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'created_at': return new Date(item.createdAt);
        default: return item[property];
      }
    };
    this.initCompanies("true");

    this.emailTemplateSubscription = this._emailTemplateService.castIsCompanyVisible.subscribe((isShowCompanyEmail) => {
      this.isShowCompanyEmail = isShowCompanyEmail;
    });
   

    if (this.selectedCompany.portal_type === global.PORTAL_TYPE.WC) {
      this.checkboxOptions = this.checkboxOptions.filter(option => option.portal_type != 'non_wc');
    }
  }
  ngOnDestroy(): void {
    if (this.emailTemplateSubscription) {
      this.emailTemplateSubscription.unsubscribe();
    }
  }

  async getAllPermissions() {
    const response: any = await lastValueFrom(this.companyService.getAllPermissions({}, {}));
    if (response.status === 200) {
      this.allPermissions = response.data;
      this.findTagPermissions = this.allPermissions.find(permission => permission.key == 'should_show_tag');
      this.findTagPermissions = this.findTagPermissions?._id;
      this.addPatientsToCensusPermissionId = this.allPermissions.find(permission => permission.key === 'add_patients_to_census')?._id;
    }
  }

  saveSettings() {
    this.companyService.saveCompanyReportSettings(this.currentCompany._id, this.reportsNames).subscribe((response: any) => {
      this.toastr.success('Settings Updated Successfully');
    })
  }
  saveChargeSetting() {
    this.companyService.saveChargeSetting(this.currentCompany._id, this.charge_edit_duration).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success('Charge Edit Duration Updated Successfully');
      }
    })
  }
  companyTabClick(event, tab) {
    if (tab.textLabel === "Reports") {
      this.woundTab.realignInkBar();
    }
  }
  getChargeSetting() {
    this.companyService.getChargeSetting(this.currentCompany._id).subscribe((response: any) => {
      if (response.status == 200 && response.charge_edit_duration) {
        this.charge_edit_duration = response.charge_edit_duration;
      }
      else { }
    })
  }
  getSettings() {
    this.companyService.getCompanyReportSettings(this.currentCompany._id).subscribe((response: any) => {
      if (response.charge_edit_duration) {
        this.charge_edit_duration = response.charge_edit_duration;
      }
      if (response.data?.length) {
        let tempArry = []
        response.data.forEach(element => {
          const index = this.reportsNames.findIndex(e => e.url == element.url);
          if (index >= 0) {
            this.reportsNames[index].selected = element.selected;
            this.reportsNames[index].user_type = element.user_type;
            // tempArry.push(this.reportsNames[index]);
          }
        });
        if (this.isWoundOrSnfCompany) {
          if (this.currentCompany.company_type === global.COMPANY_TYPE.SNF) {
            this.reportsNames = this.reportsNames.filter(report => report.title != 'Charges Report' && report.title !== 'LOS Report' && report.title !== 'IPA Report');
            this.reportsNames = this.reportsNames.filter(data => { if (data.title == 'DNS Healing Report') data.title = 'Post Rounds Report'; if (data.title == 'Post-Rounds Wound Report') data.title = 'Wound Assessments'; return data });
          } else {
            this.reportsNames = this.reportsNames.filter(data => { if (data.title == 'DNS Healing Report') data.title = 'Post Rounds Report'; if (data.title == 'Post-Rounds Wound Report') data.title = 'Wound Assessments'; return data })
          }
        } else {
          this.reportsNames = this.reportsNames;
        }
      } else {
        this.refreshSettings();
        this.saveSettings();
      }
      console.log("this.reports Names  : ", this.reportsNames);
    })
  }
  async initCompanies(filter?) {
    let query = {};

    if (this.myAssociationSide) {
      const getCompany = await this.companyService.getCompanyName(this.parentCompanyId).toPromise()
      this.parentCompanyCategory = getCompany.data.category;
      if (this.parentCompanyCategory == 'practice') {
        query = {
          adminId: this.adminId,
          parentCompanyId: this.parentCompanyId,
          getAllChilds: true
        }
      } else {
        query = {
          adminId: this.adminId,
          parentCompanyId: this.parentCompanyId,
        }
      }
      if (getCompany) {
        this.companyService.getAllAssosiatedCompaniesWithCount(query).subscribe((response: any) => {
          if (response.status == 200) {
            this.companies = response.data;
            this.companies.forEach(element => {
              element.name = element.name.toLowerCase();
              element.firstSelectedPermission = this.allPermissions.find(permissions => element.permissions.includes(permissions._id)) || {};
            });
            this.dataSource.data = this.companies;
            this.dataSource.sort = this.sort;
            this.applyCompanyFilter(filter);
          }
          else if (response.status == 403) {
            this.toastr.error(response.message, "ERROR");
          }

        })
      }
    } else {
      this.companyService.getAllCompaniesWithCount().subscribe((response: any) => {
        if (response.status == 200) {
          this.companies = response.data;
          this.companies.forEach(element => {
            element.name = element.name?.toLowerCase();
            element.firstSelectedPermission = this.allPermissions.find(permissions => element.permissions.includes(permissions._id)) || {};
          });
          this.dataSource.data = this.companies;
          this.dataSource.sort = this.sort;
          this.applyCompanyFilter(filter);
        }
        else if (response.status == 403) {
          this.toastr.error(response.message, "ERROR");
        }

      })
    }
  }

  applyCompanyFilter(filterValue: any) {
    this.activeFilter = filterValue;
    if (filterValue === "all") {
      this.dataSource.data = this.companies;
    } else if (filterValue === "false") {
      this.dataSource.data = this.companies.filter(company => company.is_active == false);
    } else {
      this.dataSource.data = this.companies.filter(company => company.is_active == true);
    }
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: any) {
    this.dataSource.filter = filterValue;
  }

  // onDeleteClick(element){
  //   console.log("onDeleteClick: ", element);
  // }

  addCompany() {
    this.company_id = this.route.parent.snapshot.params.id;
    this.router.navigateByUrl(`company/${this.company_id}/register-company`)
  }

  goBack() {
    this.refreshSettings();
    this._location.back();
  }
  refreshSettings() {
    this.isWoundOrSnfCompany = false;
    if (this.currentCompany.company_type === global.COMPANY_TYPE.WOUND || this.currentCompany.company_type === global.COMPANY_TYPE.SNF) {
      this.isWoundOrSnfCompany = true;
    }
    this.woundOrSnfTypeCompanies = this.companies.filter((company) => company._id && company._id.toString() !== this.currentCompany._id && company.is_active === true && (company.company_type === global.COMPANY_TYPE.WOUND || company.company_type === global.COMPANY_TYPE.WOUND ));
    this.reportsNames=this.reportsNames.map((e:any)=>{return {title:e.title,url:e.url ,selected:false,user_type:e.user_type}});
  }
  changeUserActiveStatus(element, status) {
    this.userManagementService.changeUserActiveStatus(element.user_id._id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      } else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }

  changeCompanyActiveStatus(element, status) {
    this.companyService.changeCompanyActiveStatus(element._id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.initCompanies((!status).toString());
        this.toastr.success(response.message, 'Success');

      } else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  changeCarryForwardStatus(element, status, type) {
    this.companyService.changeCompanyCarryForwardtatus(element._id, status, type).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      } else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  changeInactiveReasonStatus(element, status) {
    this.companyService.changeInactiveReasonStatus(element._id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      } else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  viewEligibilityStatus(element, status) {
    this.companyService.viewEligibilityStatus(element._id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      } else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  companyClicked(company) {
    this._commonService.setCompanySelected(company)
    this.router.navigateByUrl(`/dashboard`, { skipLocationChange: true }).then(() => {
      this._commonService.setLocalStorageItem('admin-Dashboard',
        {
          currentCompanyType: company.company_type,
          currentCompanyName: company.name,
          portal_type: company.portal_type
        });
      this.router.navigate([`/company/${company._id}/dashboard`]);
    });
  }
  loadCompanyEmail() {
    this.isManageCompaniesVisible = false;
    this.isShowCompanyEmail = true;
  }
  updateComapny(element, field, value) {
    let data = {
      _id: element._id,
      [field]: value
    }
    this.companyService.updateCompany(data).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      } else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }


  updateCensusColumnSettings(company_id = null) {
    const obj = {
      company_id: company_id
    }
    if (!company_id) return;

    let dialogRef = this.dialog.open(DialogCensusColumnAdminComponent, {
      data: obj,
      minWidth: '50%'
    });

    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        this.toastr.success('Census Settings Updated.');
      } else {
        console.log("canceled census column dialogue");
      }
    });

  }

  updateCurrentCompany($event) {
    console.log('$event', $event);
    this.companies.forEach(element => {
      if (element._id == $event._id) {
        console.log('yes', element._id == $event._id);
        element.logo_aws_path = $event.logo_aws_path;
        element.monogram_aws_path = $event.monogram_aws_path;
        console.log('element', element);
      }
    });
    // this.initCompanies();
    this.currentCompany = $event;
    this.dataSource.data = [];
    this.dataSource.data = this.companies;
    this.dataSource.sort = this.sort;
    this.applyCompanyFilter("true");
    console.log('this data', this.dataSource.data);
    //console.log('this companies', this.companies);
  }
  async getAllMips(company) {
    let res: any = await this.mipsService.getAllMips().toPromise();
    if (res.status == 200) {
      this.mipsQuestions = res.data.array;
      this.mipsQuestions.forEach((mip) => {
        if (mip.associated_company_ids.includes(company._id)) {
          mip.is_active_for_company = true;
        } else {
          mip.is_active_for_company = false;
        }
      })
    }
  }
  async saveMipsSetting(event, currentCompany, mip) {
    let mipDetail = { mipId: mip._id, mip_quality_number: mip.quality_number }
    let res: any = await this.mipsService.saveMipsSetting(event.checked, currentCompany._id, mipDetail).toPromise();
    if (res.status == 200) {
      if (event.checked) {
        this.toastr.success('MIP ' + mip.quality_number + ' successfully activated')
      }
      else {
        this.toastr.success('MIP ' + mip.quality_number + ' successfully deactivated')
      }

    }
  }
  async toggleCompanyMips(event, currentCompany) {
    this.showMips = event.checked;
    let res: any = await this.mipsService.enableCompanyMips(event.checked, currentCompany._id).toPromise();
    if (res.status == 200) {
      if (event.checked) {
        this.toastr.success('Enable Measure Reporting successfully activated')
      }
      else {
        this.toastr.success('Enable Measure Reporting successfully deactivated')
      }
      console.log('mips Enabled/Disabled.......');
    }
  }
  getCompanyMipsEnabledStatus(company) {
    this.companyService.getCompanyName(company._id).subscribe((response: any) => {
      if (response.status == 200) {
        this.showMips = response.data.enable_company_mips;
      }
    })
  }

  getProviderTotalCount() {
    let sum = this.dataSource.data.filter(f => f.is_active && f.providers).map(p => p.providers?.providerCount).reduce((pv, cv) => pv + cv, 0);
    return sum;
  }

  getFacilitiesTotalCount() {
    let sum = this.dataSource.data.filter(f => f.is_active && f.facilities).map(p => p.facilities?.facilityCount).reduce((pv, cv) => pv + cv, 0);
    return sum;
  }

  getPccFacilitiesTotalCount() {
    let sum = this.dataSource.data.filter(f => f.is_active && f.pccFacilties).map(p => p.pccFacilties?.PCCfacilityCount).reduce((pv, cv) => pv + cv, 0);
    return sum;
  }

  async copyDataSetFromOtherCompany(){
    if(this.copyDataSettings.copyDatasetFrom === null){
      this.toastr.error("Select Company First");
      return;
    }
    const toastrId = this.toastr.info("Syncing Wound Data...", "Please Wait...", { disableTimeOut: true });
    this.copyDataSettings.copyDatasetTo = this.currentCompany._id
    const response: any = await this.companyService.copyDataSetfromOtherCompany(this.copyDataSettings).toPromise();
    if(response.status === 200){
      this.toastr.success(response.message);
    }
  }
  filterUSerTypes(){
    const allUserType ={}
    for (const [key, value] of Object.entries(global.USER_TYPE)) {
      if(key.toString() !== "COMPANY"){
        allUserType[`${key}`] = value;
      }
    }
    return allUserType;
  }
}
