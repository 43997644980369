<div class="h100 flex xs:flex-col justify-center items-center">
  <mat-card class="w-2/5 xs:w-full">


    <mat-card-title class="flex flex-col justify-center items-center">
      <img [src]="companylogoPath ? companylogoPath : defaultImg">
    </mat-card-title>
    <mat-card-content class="flex flex-col">

      <form *ngIf="!showAuthenticationOption" (submit)="showAuthenticationOptions(loginForm)" #loginForm="ngForm">
        <mat-error *ngIf="status != 200 && status!= null">Invalid Credentials!</mat-error>
        <mat-form-field>
          <mat-label>Email address</mat-label>
          <input matInput type="text"
                 name="email"
                 id="user-email"
                 #emailField="ngModel" [(ngModel)]="email" required>
          <mat-error *ngIf="emailField.invalid">Please enter email</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Password</mat-label>
          <input matInput type="password"
                 name="password"
                 id="user-password"
                 #passwordField="ngModel" [(ngModel)]="password" ngModel required
                 [type]="hide ? 'password' : 'text'">
                 <mat-icon matSuffix style="cursor: pointer;" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}} </mat-icon>
          <mat-error *ngIf="passwordField.invalid">Please enter password</mat-error>
        </mat-form-field>

        <div class="remember-me flex justify-between items-center">
          <div class="w-2/5">
            <mat-checkbox name="remember_me_check" [(ngModel)]="remember_me_check">Remember me</mat-checkbox>
          </div>
          <div class="w-2/5 flex justify-end">
            <a style="cursor: pointer;" (click)="navigateToForgotPassword()">Forgot password?</a>
          </div>
        </div>
        
          <div class="flex flex-col justify-center items-center gap-2">
            
              <button style="width: 50%;" mat-raised-button class="dn-button-primary" id="form-submit"
                mat-stroked-button type="submit">Login</button>
                
            <div *ngIf="logoutTimerReturnURL">
                <br />
                <mat-error style="padding: 0 10%;
                text-align: center;">Idle timeout reached! To ensure your account's safety, you've been automatically logged out. Log in to continue.</mat-error>
            </div>
            <div class="flex justify-between items-center" style="width: 100%;">
              
              <div class="flex justify-center items-end" style="flex:57%">
                <a style="cursor: pointer; margin-top: 15px;" (click)="navigateToContactUs()">Contact Us</a>
              </div>
              <div class="justify-center items-end" *ngIf="companylogoPath" style="flex:43%">
                  <img [src]="companylogoPathAfterLogin ? companylogoPathAfterLogin : ''" height="50px" width="50px" alt="">
              </div>
            </div>
        
          </div>
        <div class="member flex flex-col justify-center items-center">
        </div>
      </form>
      <div class="flex flex-col flex-grow gap-1" style="align-items: center;" *ngIf="showAuthenticationOption">
        <div class="flex-grow" style="font-size: large;font-weight: bold;">Select Authentication Method</div>
        <mat-radio-group [(ngModel)]="authMethod" class="flex flex-inline gap-5" name="authentication">
          <mat-radio-button *ngIf="checkUserDetails('sms')" value="sms">SMS</mat-radio-button>
          <mat-radio-button *ngIf="checkUserDetails('email')" value="email">Email</mat-radio-button>
          <mat-radio-button *ngIf="checkUserDetails('google')" value="google2FA">Google Authenticator</mat-radio-button>
        </mat-radio-group>
        <button style="width: 50%;" (click)="authencateUser()" mat-raised-button class="dn-button-primary w-1/2" [disabled]="isInProgress && (authMethod !== '')"  mat-stroked-button>Next</button>
      </div>
    </mat-card-content>

  </mat-card>
</div>
