
<div >
    <div style="background-color: #cedbdf; height: 56px; padding-left: 15px; padding-right: 15px;margin-left: 5px;"
        class="flex justify-between items-center">
        <b style="font-size: 16px;">{{company.name}}</b>

        <span style="color: firebrick; cursor: pointer;" (click)="closeSideBar()">Close</span>
    </div>

    <div class="flex flex-col" style="padding: 0px 0px 0px 10px;">
        <ul>
            <li *ngFor="let cptCode of cptCodes" class="flex justify-between items-center">
                <span>{{cptCode.codeDescription}}</span>
                
                <span style="cursor: pointer;" (click)="removeCPT(cptCode)">X</span>
            </li>

        </ul>
    </div>
</div>
