import { Component, ViewChild, ChangeDetectorRef, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { TranscriptionTemplateDialog } from '../transcription-create/transcription-template-dialog/transcription-template-dialog.component';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '../dashboard/dashboard.service';
import * as global from '../global';
import { TranscriptionCreateService } from '../transcription-create/transcription-create.service';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatSort, MatSortable, Sort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { PatientRoundingSheetService } from '../patient-rounding-sheets/patient-rounding-sheet.service';
import { MatDialog } from '@angular/material/dialog';
import { PatientRoundingSheetCommentsDialog } from '../../shared/popup-dialogs/patient-roundingsheet-comments-dialog/patient-roundingsheet-comments.component';
import { AudioListDialog } from '../../shared/popup-dialogs/audio-list-dialog/audio-list-dialog.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import moment from 'moment'
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { Patient } from 'src/app/classes/patient';
import { CensusPatientListService } from './census-patient-list.service';
import { FormControl } from '@angular/forms';
import { lastValueFrom, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators'
import { PCCService } from 'src/app/services/pcc.service';
import { CensusFileUploadDialogeComponent } from 'src/app/shared/popup-dialogs/census-file-upload/census-file-upload-dialoge/census-file-upload-dialoge.component';
import { eventTypes as pccEventTypes } from 'src/app/constants/pcc_webhook_constants';
import { GoBackUrlService } from 'src/app/services/goBackUrl.service';
import { API_URL } from '../../../environments/api-url'
import { HttpResponse } from '@angular/common/http';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PatientImporterDialog } from '../patient-rounding-sheets/patient-importer-dialog/patient-importer-dialog';
import { RehabDocsPopupComponent } from 'src/app/shared/popup-dialogs/rehab-docs-popup/rehab-docs-popup/rehab-docs-popup.component';
import { WatchHelpVideoDialog } from '../drive/watch-help-video-dialog.component';
import { ViewNoteDialog } from '../transcription-create/view-note-dialog/view-note-dialog.component';
import { DialogConfirmationComponent } from './dialog-confirmation/dialog-confirmation.component';
import { DrugHistoryConsentDialog } from './drug-history-consent-dialog/drug-history-consent-dialog.component';
import { EligibilityDialog } from './eligibility-dialog/eligibility-dialog.component';
import { CensusListService } from '../census-list/census-list..service';
import { PatientRoundingSheetCommentsService } from 'src/app/services/patient-roundingsheet-comments.service';
import { CustomPdfDialogComponent } from './custom-pdf-dialog/custom-pdf-dialog.component';
import { PatientDiagnosisDialogComponent } from '../patient-diagnosis-dialog/patient-diagnosis-dialog.component';
import { PatientAddFormDialogComponent } from '../patient-add-form/patient-add-form-dialog/patient-add-form-dialog.component';
import { PatientEditDialogComponent } from './patient-edit-dialog/patient-edit-dialog.component';
import { DialogWithChoiceComponent } from './dialog-with-choice/dialog-with-choice.component';
import { PatientListService } from '../patient-list/patient-list-service';
import { Note } from '../note-editor/model/note';
import { NoteEditorService } from '../note-editor/note-editor.service';
import { AddTicketsComponent } from '../tickets/add-tickets/add-tickets.component';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { WoundCareWidgetService } from '../note-sidebar/wound-care-widget/wound-care-widget.service';
import { WoundService } from 'src/app/services/wound.service';
import { WoundCareWidgetComponent } from '../note-sidebar/wound-care-widget/wound-care-widget.component';
import { MatSelectChange } from '@angular/material/select';
import { TagsService } from 'src/app/services/tags.service';
import { PatientAddService } from '../patient-add/patient-add-service';
import { PatientsWithActiveWoundsComponent } from './patients-with-active-wounds/patients-with-active-wounds.component';
import { MatrixService } from 'src/app/services/matrix.service';
import { DuplicatePatientDialogComponent } from '../matrix-patients/duplicate-patient-dialog/duplicate-patient-dialog.component';
import { PatientDocumentsUploadDialog } from '../patient-documents-list/patient-documents-upload-dialog/patient-documents-upload-dialog.component';
import { FaceSheetService } from '../patient-detail/facesheet/face-sheet.service';
import { FileExplorerService } from '../drive/file-explorer/file-explorer.service';
import { Buffer } from 'buffer';
import swal from 'sweetalert';
import { AggrementDialogComponent } from '../manage-companies/consent/aggrement-dialog/aggrement-dialog.component';
import { PdfViewerPopComponent } from '../patient-documents-list/pdf-viewer-pop/pdf-viewer-pop.component';
import { ConsentService } from '../manage-companies/consent/service/consent.service';


@Component({
    selector: 'app-census-patient-list',
    templateUrl: './census-patient-list.component.html',
    styleUrls: ['./census-patient-list.component.css']
})
export class CensusPatientListComponent implements OnInit, OnDestroy {
    tags = [];
    allTags = [];
    loading: boolean = false;
    global = global;
    pccEventTypes = pccEventTypes;
    census: any;
    selection = new SelectionModel<any>(true, []);
    displayedColumns: string[] = [];
    displayedColumnsInactive: string[] = [];
    dataSource: MatTableDataSource<any>;
    currentUser: any;
    filterDropdownActive = false;
    dateOfBirth: any;
    location_sort_order: string = 'desc';
    view_eligibility_status: boolean = false;
    should_show_consent_form: boolean = false;
    isDeviceIPad: boolean = false;
    should_show_ipa: boolean = false;
    isDeviceMicrosoftSurface: boolean = false;
    enableEditIndex = null;

    params: {
        id: string
    }
    recentComments: any = [];

    loaderId = 'episodes-of-care-loader';
    patientsSearchLoaderId = 'pcc-patients-loader';

    filterSeen: 'seen' | 'not_seen' | 'all' = 'all';
    filterSort: 'seen' | 'not_seen' | 'room_no' | 'new_patient' |
        'status' | 'name' | 'dob' | 'room_no' | 'admission_date' | 'discharge_date' | 'payor' |
        'user_sort' | 'last_seen' | 'location';

    filterSearch = "";
    tagSearch = "";
    patientsByProvider: Patient[] = [];

    patientsByProviderControl = new FormControl();
    filteredPatientsByProvider: Observable<any[]>;


    pccPatients: Patient[] = [];

    pccPatientsControl = new FormControl();
    filteredPccPatients: Observable<any[]>;
    censusFileUploadDialogeComponent: any;

    patientStatuses = ['All', 'Current', 'New', 'Discharged'];
    searchPatientStatus = 'Current';
    selectedPatients: any[] = [];
    telemedicine: string = 'false';
    reloadingCensus = false;
    selectedPatientsToImport = [];
    defaultPotraitFontSize: string;
    defaultLandscapeFontSize: string;
    currentPatient: any;
    patientsImportSource: any;
    sources = [
        // {value: 'pcc', label: 'PCC'},
        { value: 'dn', label: 'DN' },
        { value: 'MatrixCare', label: 'MatrixCare' }
    ]
    displayCommentSidenav: boolean = false;
    commentCount: any;
    recentNotes: any = [];
    show_card_view: Boolean = true;
    show_adt_view: Boolean = false;
    censusPatientSortSubscription: any;
    admin_flag: Boolean = false;
    detect_refresh_child: Boolean = false;
    error2LeggedAuth: String = 'Please login to PCC to view data.';
    error2LeggedAuthFlag: Boolean = false;
    census_id = "";
    isActivePatientList: boolean = true;
    AllmissingVisits: any;
    calView: any;
    AllInactiveReasons: any;
    is_company_side: Boolean = false;
    isUserLoggedIntoPCC: boolean = false;
    isEQAofProvider: boolean;
    isIQAofProvider: boolean;
    isMAofProvider: boolean;
    isTagsDisplayAllowed: boolean = false;
    isShowTagsToaster: boolean = false;
    isMedications: boolean = false;
    census_inactive_reason: Boolean = false;
    configuration: any = {
        wound_assessment_report_category: "",
        progress_note_submission_type: "",
        wound_assessment_report_submission_type: "",
        wound_assessment_report_name: ""
    };
    current_company_id: string = "";
    newMatrixImportedList: any = [];
    consentAudits: any = [];
    company: any;
    constructor(
        private changeDetectorRefs: ChangeDetectorRef,
        private _patientRoungingSheetService: PatientRoundingSheetService,
        private _router: Router,
        private censusListService: CensusListService,
        private _toastr: ToastrService,
        private matrixService: MatrixService,
        private transcriptionCreateService: TranscriptionCreateService,
        public commonService: CommonService,
        private authService: AuthService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private loader: NgxUiLoaderService,
        private patientRoundingSheetService: PatientRoundingSheetService,
        private _location: Location,
        private censusPatientListService: CensusPatientListService,
        private pccService: PCCService,
        private goBackUrlService: GoBackUrlService,
        private patientRoundingSheetCommentsService: PatientRoundingSheetCommentsService,
        private dashboardService: DashboardService,
        private companyService: CompanyService,
        private _patientListService: PatientListService,
        private _noteEditorService: NoteEditorService,
        private woundCareWidgetService: WoundCareWidgetService,
        private woundService: WoundService,
        private tagsService: TagsService,
        private editPatientService: PatientAddService,
        private _faceSheetService: FaceSheetService,
        private fileExplorerService: FileExplorerService,
        private _consentService: ConsentService,

    ) {
        this.currentUser = this.authService.currentUser;
        this.authService.onUserAuthChange.subscribe(currentUser => this.currentUser = currentUser);
        this.params = {
            id: this.route.snapshot.params.id
        };
        this.telemedicine = this.route.snapshot.paramMap.get('telemedicine');
        this.calView = localStorage.getItem('calView');
        console.log("this,calView : ", this.calView);
        if (window.location.pathname.includes('company')) {
            this.is_company_side = true;
            this.current_company_id = this.route.parent.snapshot.params.id;
        }
        this.route.params.subscribe(
            async (params: Params) => {
                this.params = {
                    id: params['id'],
                };


                this.admin_flag = params['admin']
                if (params['error2LeggedAuthFlag'] == 'true') {
                    this.error2LeggedAuthFlag = true
                }
                else {
                    this.error2LeggedAuthFlag = false
                }
                if (localStorage.getItem('hide_census') == 'true') {
                    this.show_card_view = false
                }
                // if (this.params.id != 'null') {
                //   // this.ngOnInit();
                //   this.initAutoRefreshFunctions(60);
                // }

            })
    }
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatTable) table: MatTable<any>;


    transformPatientList(patientList, newPatient?) {
        for (let patient of patientList) {
          if (!patient.is_patient_consent)
            patient.patientConsentToolTip = "CCM Consent not obtained";
          else
            patient.patientConsentToolTip = "CCM consent already obtained";
          if (!patient.is_patient_pcm_consent)
            patient.patientPCMConsentToolTip = "PCM Consent not obtained";
          else
            patient.patientPCMConsentToolTip = "PCM consent already obtained";
          if (newPatient) patient = newPatient;
          // if (patient.last_admit_date_obj && patient.last_discharge_date_obj
          //   && Object.values(patient.last_admit_date_obj).length > 0
          //   && Object.values(patient.last_discharge_date_obj).length > 0) {
          //   const last_discharge_date = `${patient.last_discharge_date_obj.month}/${patient.last_discharge_date_obj.day}/${patient.last_discharge_date_obj.year}`;
          //   const last_admit_date = `${patient.last_admit_date_obj.month}/${patient.last_admit_date_obj.day}/${patient.last_admit_date_obj.year}`;
          //   if (moment(last_discharge_date).isBefore(last_admit_date)) {
          //     patient.isPatientReadmitted = true;
          //   }
          //   else if (moment(last_discharge_date).isAfter(last_admit_date)) {
          //     patient.isPatientDischarged = true;
          //   }
          //   else if (moment(last_discharge_date).isSame(last_admit_date)) {
          //     patient.isPatientDischarged = true;
          //   }
          // }
          // else if (!patient.last_admit_date_obj && patient.last_discharge_date_obj) {
          //   patient.isPatientDischarged = true;
          // }
          
          const last_patient_status = patient.getPatientStatus(this.census.facility);
          if(last_patient_status === 'Discharged') {
            patient.isPatientDischarged = true;
          } else if(last_patient_status === 'Re-Admitted') {
            patient.isPatientReadmitted = true;
          } else {
            patient.isPatientAdmitted = true;
          }
          let lastCPT = this.getLastCPT(patient);
          patient.lastCPT = lastCPT;
          if (Array.isArray(patient.non_pcc_payload)) {
            for (const item of patient.non_pcc_payload) {
              if (item.census_id_ref == this.census._id) {
                patient.non_pcc_payload = item;
              }
            }
          }
          if (newPatient) break;
        }
      }

    ngOnDestroy(): void {
        if (this.censusPatientSortSubscription) {
            this.censusPatientSortSubscription.unsubscribe();
        }

    }

    isMatrixFacility(facility) {
        if (facility) {
            if (facility.source == "MatrixCare") {
                return true;
            }
        }
    }
    isMatrixFacilitytable(patient) {
        if (patient) {
            if (patient.source == "MatrixCare") {
                return true;
            }
        }
    }

    async ngOnInit() {
        if (this.params.id != 'null') {
            this.selection.clear()
            this.selectedPatientsToImport = []
            // JSON.parse removed causing parse errors for patients i.e JSON.parse(this.currentUser)
            this.currentUser = this.currentUser;
            if (!this.currentUser) {
                // if not login, stop and redirect to login #mohsin-dev
                localStorage.removeItem('currentUser');
                this._router.navigate(['/login']);
                return;
            }
            await this.getCompany();
            if (this.company.portal_type === global.PORTAL_TYPE.NON_WC) {
                this.isDeviceIPad = /iPad/i.test(navigator.userAgent) || /Macintosh/i.test(navigator.userAgent) && ('ontouchstart' in window || navigator.maxTouchPoints && navigator.maxTouchPoints > 2);
                this.isDeviceMicrosoftSurface = /Surface/i.test(navigator.userAgent) || /Windows NT/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;
            }
            this.initPatientsByProvider();
            this.initPccPatients();
            // await this.getEligiblityStatus(this.currentUser.company_id)


            await this.getRecentNoteByCensusId();
            // this.defaultPotraitFontSize = this.currentUser.other_settings?.census_potrait_font_size;
            // this.defaultLandscapeFontSize = this.currentUser.other_settings?.census_landscape_font_size;
            // show operations column only for doctor
            // if(this.currentUser.user_type == 1) {

            this.displayedColumns = ['switch-status', 'patient-consent-status', 'is_patient_pcm_consent', 'name', 'deleteNote', 'note', 'operations', 'comments_count', 'pcc_patient_diagnosis', 'discharge',
                // 'charge',
                //  'charge-status',
                'date_of_birth', 'location', 'admission_date',
                // 'discharge_date', 
                'payer','primary_secondary_payor','pcp', 'mrn', 'last_seen', 'last_cpt', 'edit_patient'];
            // remove // 'status',  'source',
            // } else {
            // this.displayedColumns = ['name', 'date_of_birth', 'gender'];
            // }
            this.displayedColumnsInactive = ['name', 'operations', 'comments_count', 'date_of_birth', 'in_active_reason'];
            this.displayedColumns = ['checkbox', 'switch-status', 'name', 'deleteNote', 'note', 'operations', 'comments_count', 'pcc_patient_diagnosis', 'discharge',
                'date_of_birth', 'location', 'admission_date',
                //  'discharge_date',
                'payer' ,'primary_secondary_payor','pcp', 'mrn', 'last_seen', 'last_cpt', 'edit_patient'];
            if(this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
                this.displayedColumns = this.displayedColumns.filter(a => a != 'note' && a != 'deleteNote' );
            }
            // await this.getSkinSubRefferalSettings();
            await this.getColumnsFromSettings();

            var item = {
                id: <string>null,
                auth_key: <string>null,
                usertype: <any>null,
                primary_doctor_id: <string>null
            };
            item.id = this.currentUser._id;
            item.auth_key = this.currentUser.auth_key;
            item.usertype = this.currentUser.user_type;
            if (this.params.id)
                await this.initCensus();
            await this.refreshCensusPCCPatients();

            if (this.company.portal_type === global.PORTAL_TYPE.NON_WC)
                this.getConsentAudit();

            // if(this.isOwner(this.census)){
            //   this.displisIQAofProviderayedColumns = ['switch-status', 'name', 'note', 'discharge',
            //   // 'charge',
            //   // 'charge-status',
            //    'date_of_birth', 'location', 'admission_date', 'discharge_date', 'payer', 'comments_count', 'last_seen', 'operations'];
            // }
            // else{
            //   this.displayedColumns = ['switch-status', 'name', 'note', 'discharge',
            //   // 'charge',
            //   // 'charge-status',
            //   'date_of_birth', 'location', 'admission_date', 'discharge_date', 'payer', 'comments_count', 'last_seen', 'operations', 'checkbox'];
            // }


            this.censusPatientListService.castFilterSort.subscribe(value => {
                this.filterSort = 'not_seen';
                this.doSortPatients();
            })


            // this.censusPatientListService.castFilterSearch.subscribe(value => {
            //   this.filterSearch = value;
            //   this.applyFilter(value);
            // })

            // this.getPatientCommentCount(this.params.id);
            // await this.getCensusRecentComment(this.params.id);
            await this.getMissingVisits(this.params.id);
            await this.getInactiveReasons(this.params.id)
            this.handleActivationFilter("active");
        }
        this.pccService.pccUserinfo$.subscribe((pccUserinfo: any) => {
            if (pccUserinfo) {
                this.isUserLoggedIntoPCC = true;
            }
        });
        await this.refreshRelation((this.census.createdBy?._id) ? this.census.createdBy?._id : this.census.createdBy);
        if (this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
            await this.getParentWoundsCount(this.census.patientList.map(p => p._id));
        }

    }

    changePatientConsent(element, event, index) {
        if (!element.is_patient_consent) {
            swal({
                title: "Have you obtained a CCM consent and documented this?",
                text: '',
                icon: "warning",
                buttons: [
                    "No", "Yes"
                ],
                dangerMode: true,
            })
                .then(async (res) => {
                    if (res) {
                        let res: any = await lastValueFrom(this.editPatientService.editPatient({ _id: element._id }, { $set: { is_patient_consent: true } }));
                        if (res.status == 200) {
                            this.dataSource.data[index].is_patient_consent = true;
                            this.dataSource.data[index].patientConsentToolTip = "Patient has alredy provided his consent"
                            this._toastr.success(res.message, "Success");
                        } else
                            this._toastr.error("Something Went Wrong");
                        return;
                    } else {
                        return;
                    }
                })
        }
    }
    changePatientPCMConsent(element, event, index) {
        if (!element.is_patient_pcm_consent) {
            swal({
                title: "Have you obtained a PCM consent and documented this?",
                text: '',
                icon: "warning",
                buttons: [
                    "No", "Yes"
                ],
                dangerMode: true,
            })
                .then(async (res) => {
                    if (res) {
                        let res: any = await lastValueFrom(this.editPatientService.editPatient({ _id: element._id }, { $set: { is_patient_pcm_consent: true } }));
                        if (res.status == 200) {
                            this.dataSource.data[index].is_patient_pcm_consent = true;
                            this.dataSource.data[index].patientPCMConsentToolTip = "Patient has alredy provided his consent"
                            this._toastr.success(res.message, "Success");
                        } else
                            this._toastr.error("Something Went Wrong");
                        return;
                    } else {
                        return;
                    }
                })
        }
    }



    handleSelectChange(patient) {
        if (patient.selectedTags.length == 0) {
            patient.first_tag_name = "";
            patient.first_tag_color = "";
            patient.second_tag_name = "";
            patient.second_tag_color = "";
        }
        let index = 0;
        for (let selectedTag of patient.selectedTags) {
            for (let tag of this.tags) {
                if (!tag.isAlreadyVisited) {
                    tag.is_selected = false;
                }
                if (tag._id == selectedTag) {
                    if (index == 0) {
                        patient.first_tag_name = tag.name;
                        patient.first_tag_color = tag.color;
                        patient.second_tag_name = "+ Tag";
                        patient.second_tag_color = "";
                    }
                    if (index == 1) {
                        patient.second_tag_name = tag.name;
                        patient.second_tag_color = tag.color;
                    }
                    tag.is_selected = true;
                    tag.isAlreadyVisited = true;
                }
            }
            index++;
        }

        this.tags = this.tags.map(tag => {
            tag.is_active = true;
            delete tag.isAlreadyVisited;
            return tag
        })
        this.disableCheckbox(patient);
    }

    disableCheckbox(patient) {
        let length = patient.selectedTags.length;
        if (length == 2) {
            for (let tag of this.tags) {
                if (tag.is_selected) {
                    tag.is_active = true;
                }
                else
                    tag.is_active = false;

            }
        }

    }

    async triggerEvent($event, patient) {
        let toastrId;
        if (!$event && patient.previousSelectedTags.join() != patient.selectedTags.join()) {         // when drop down closes after opening
            toastrId = this._toastr.info("Updating Tag for Patient", "Please Wait...", { disableTimeOut: true });

            let res: any = await lastValueFrom(this.editPatientService.editPatient({ _id: patient._id }, { $set: { tag_ids_ref: patient.selectedTags } }));
            if (res.status == 200) {
                this._toastr.clear(toastrId.toastId);
                this._toastr.success(res.message, "Success");
                if (this.params.id) {
                    this.initCensus();
                    this.filterSort = 'not_seen';
                    this.doSortPatients();
                }
            } else {
                this._toastr.clear(toastrId.toastId);
                this._toastr.warning(res.message);
            }
        }
    }
    showTagsToaster() {
        this.isShowTagsToaster = true;
    }

    async addNewTag(index: any, data: any) {
        this.enableEditIndex = index;
        this.dataSource.data[index].shouldUpdate = true;
        let res: any = await lastValueFrom(this.tagsService.getAllTags({ is_deleted: false }, { _id: 1, color: 1, name: 1 }, true, data.tag_ids_ref));
        if (res.status == 200) {
            this.tags = res.data;
            this.allTags = [...this.tags];
            if (this.tags.length == 0 && this.isShowTagsToaster)
                this._toastr.info('There is no tag to show');
            this.disableCheckbox(this.dataSource.data[index]);
        } else
            this._toastr.error('Something went wrong', 'Error');
        this.enableEditIndex = index;
        this.dataSource.data[index].shouldUpdate = false;
    }

    searchTag($event) {
        if ($event.target.value != "")
            this.tags = this.tags.filter(tag => {
                return tag.name.toLowerCase().includes($event.target.value.toLowerCase());
            })
        else
            this.tags = this.allTags;
    }

    setSortOnInit() {
        if (localStorage.getItem('sortFilter') != undefined) {
            let sortObj = JSON.parse(localStorage.getItem('sortFilter'));
            if (this.census._id.toString() == sortObj.prevId) {
                this.sort.sort(({ id: sortObj.sortColumn, start: sortObj.sortDirection }) as MatSortable);
                this.dataSource.sort = this.sort;

                this.sortData({ active: sortObj.sortColumn, direction: sortObj.sortDirection });

                const activeSortHeader = this.sort.sortables.get(sortObj.sortColumn);
                activeSortHeader['_setAnimationTransitionState']({
                    fromState: sortObj.sortDirection,
                    toState: 'active',
                });
            }
        }
    }

    async getParentWoundsCount(patientIds) {
        const countResponse: any = await lastValueFrom(this.woundCareWidgetService.getParentWoundsCount(patientIds));
        if (countResponse.status == 200) {
            this.addWoundCountsToPatient(countResponse.data);
        }
    }

    addWoundCountsToPatient(counts) {
        this.census.patientList.forEach(patient => {
            counts.forEach(patientCount => {
                if (patient._id.toString() === patientCount._id.toString()) {
                    patientCount.states.forEach(state => {
                        if (state.state === 'active') {
                            patient.woundCount = state.count;
                        }
                    });
                }
            });
        });
    }

    // getPatientCommentCount(census_id) {
    //   // console.log(census_id)
    //   this.patientRoundingSheetCommentsService.getPatientCommentCount(census_id).subscribe((response: any)=>{
    //     if(response.status === 200) {
    //       // console.log("count",response);
    //       this.commentCount = response.data.array;
    //     }
    //   });
    // }

    async refreshCensusPCCPatients() {
        const lastRefreshedOn = this.census.pcc_patients_last_refrehed_on;
        if (!!lastRefreshedOn && moment(lastRefreshedOn).isValid()) {
            const mlastRefreshedOn = moment(lastRefreshedOn);
            // check if last refreshed on is less than 24 hours
            const mNow = moment();
            const diff = mNow.diff(mlastRefreshedOn, 'hours');
            if (diff < 24) {
                return; // no refresh needed because its already refreshed
            }
        }

        const details = {
            ...this.authService.authObject,
            censusId: this.census._id
        }
        const { status, data } = await lastValueFrom(this._patientRoungingSheetService.refreshCensusPCCPatients(details)) as any;
        if (status === 200 && data?.updatePatientsCount > 0) {
            this._toastr.success('PCC patients data synced successfully');
            this.initCensus(true)

        }
    }
    async getColumnsFromSettings() {
        if (((this.currentUser.other_settings?.census_columns) ? this.currentUser.other_settings?.census_columns?.length : false)) {
            let columns = this.currentUser.other_settings?.census_columns;
            let newColumns = [];
            console.log("in iff provider settings");

            for (let element of this.displayedColumns) {

                if (element == 'checkbox' || element == 'switch-status' || element == 'edit_patient' || element == 'deleteNote' || element == 'operations' || element == 'name' || element == 'note') {
                    newColumns.push(element);
                }
                else {
                    if (columns?.findIndex(e => e?.toString() == element?.toString()) >= 0)
                        newColumns.push(element);
                }

            }
            this.displayedColumns = newColumns;

            // console.log("this.displayedColumns : ",newColumns, this.displayedColumns);
            //'checkbox', 'switch-status', 'edit_patient'
        } else {
            let newColumns = [];
            console.log("in else company settings");

            await this.companyService.getCensusColumnSetting(this.currentUser.company_id).subscribe((res: any) => {
                console.log("res compnay census settings : ", res.data);

                if (res.data?.length) {
                    //
                    let columns = Array.from(new Set(res.data));

                    for (let element of this.displayedColumns) {

                        if (element == 'checkbox' || element == 'switch-status' || element == 'edit_patient' || element == 'deleteNote' || element == 'operations' || element == 'name' || element == 'note') {
                            newColumns.push(element);
                        }
                        else {
                            if (columns?.findIndex(e => e?.toString() == element?.toString()) >= 0)
                                newColumns.push(element);
                        }

                    }
                    this.displayedColumns = newColumns;


                } else {
                    //

                }
                // this.census_columns=Array.from(new Set(this.census_columns));

            })

        }
    }
    async getSkinSubRefferalSettings () {
        await this.companyService.getCensusColumnSetting(this.currentUser.company_id).subscribe((res: any) => {
            // console.log("res compnay census settings : ", res.data);
            if (res.status == 200) {
                //
                let columns = Array.from(new Set(res.data));
                let idx = columns.findIndex(column => column === 'skin_sub_referral')
                if(idx < 0) {
                    this.displayedColumns =  this.displayedColumns.filter(column => column !== 'skin_sub_referral')
                }
                


            } else {
                //

            }
            // this.census_columns=Array.from(new Set(this.census_columns));

        })
    }

    updatePatientSkinSubRefferal(element, $event, index) {
        if(!element.skin_relation) {
            swal({
                title: "Skin Sub Referral",
                text: 'A Skin Sub Notification has been sent to your admin.',
                // icon: "success",
                // dangerMode: false,
                buttons: [
                    "No", "Yes"
                  ],
              })
                .then(async (res) => {
                
                if (res) {
                    let data = {
                        patient_id: element._id,
                        census_id: this.census._id,
                        dos: this.census.date_obj,
                        facility_id: this.census.facility._id,
                        user_id: this.census.provider._id,
                        company_id: this.census.company._id
                    }
                    let response = lastValueFrom(this._patientRoungingSheetService.createSkinSubRefferal(data));
                    element.skin_relation = true;
                }
                  // if (res) {
                  //   let res: any = await lastValueFrom(this.PatientService.editPatient({ _id: element._id }, { $set: { is_patient_consent: true } }));
                  //   if (res.status == 200) {
                  //     this.dataSource.data[index].is_patient_consent = true;
                  //     this.dataSource.data[index].patientConsentToolTip = "Patient has alredy provided his consent"
                  //     this._toastr.success(res.message, "Success");
                  //   } else
                  //     this._toastr.error("Something Went Wrong");
                  //   return;
                  // } else {
                  //   return;
                  // }
                })
        }
            
    }
    async getMissingVisits(censusid) {
        let filter = {
            censusid: censusid
        }
        this.censusPatientListService.getCensusAllPatientsMissingVisit(filter).subscribe((response: any) => {
            if (response.status === 200) {
                this.AllmissingVisits = response.data;
                if (this.census?.patientList) {
                    this.census.patientList.map(data => {

                        delete data?.missing_visit;
                        this.AllmissingVisits.map(ele => {

                            if (data._id == ele.patientid) {
                                data["missing_visit"] = ele;
                            }
                        });

                    });

                }
            }
        });

    }
    getCommentstoShow(element) {

        let short_message,
            full_message,
            letter_count = 35;

        if (element?.missing_visit) {
            // return (element?.missing_visit?.reason) ? (element?.missing_visit?.reason?.substring(0, 27)) : '--';
            if (element?.missing_visit?.reason) {
                full_message = element?.missing_visit?.reason;
                short_message = this.trimString(full_message, letter_count);
            } else {
                short_message = '--';
                full_message = "";
            }
        } else {
            // return (element?.census_comment?.comment) ? (element?.census_comment?.comment.substring(0, 27)) : '--';
            if (element?.census_comment?.comment) {
                full_message = element?.census_comment?.comment;
                short_message = this.trimString(full_message, letter_count);
            } else {
                short_message = '--';
                full_message = "";
            }
        }

        return {
            'full_message': full_message,
            'short_message': short_message
        };
    }

    trimString(message, count) {

        let trimmedString;

        if (message.length > count) {

            //trim the string to the letter_count which is 27
            trimmedString = message.substr(0, count);

            //re-trim if we are in the middle of a word
            trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))) + "...";

        } else {
            trimmedString = message;
        }

        return trimmedString;
    }

    getInActiveReasontoShow(element) {

        if (element?.inactive_reason) {
            return (element?.inactive_reason?.reason) ? (element?.inactive_reason?.reason?.substring(0, 57)) :
                'No reason was selected';

        } else {
            return 'No reason was selected';
        }
    }

    async getInactiveReasons(censusid) {
        let filter = {
            censusid: censusid
        }
        this.censusPatientListService.getCensusAllPatientsReasons(filter).subscribe((response: any) => {
            if (response.status === 200) {
                this.AllInactiveReasons = response.data;

                if (this.census?.patientList) {
                    this.census.patientList.map(data => {


                        delete data?.inactive_reason;
                        this.AllInactiveReasons.map(ele => {


                            if (data._id == ele.patientid) {

                                data["inactive_reason"] = ele;
                            }
                        });


                    });

                }
            }
        });

    }

    async sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async initAutoRefreshFunctions(mints: any) {

        // while (true) {

        //   await this.sleep(mints / 2 * 60000);
        //   console.log("gonna refresh again");
        //   await this.reloadCensus();
        //   await this.sleep(mints / 2 * 60000);


        // }

    }

    showPatientDiagnosisDialog(patient_id) {
        let patientDiagnosisDialog = this.dialog.open(PatientDiagnosisDialogComponent, {
            width: '40%',
            height: '70vh',
            data: patient_id
        });
    }
    async getCensusRecentComment(census_id) {
        // console.log(census_id)
        this.patientRoundingSheetCommentsService.getCensusRecentComment(census_id).subscribe((response: any) => {
            if (response.status === 200) {
                this.recentComments = response.data;
                if (this.census?.patientList) {
                    this.census.patientList.map(data => {
                        delete data?.census_comment; //To remove census comment fetched from census pipeline
                        this.recentComments.map(ele => {

                            if (data._id == ele.patient_id) {
                                data["census_comment"] = ele;
                            }
                        });
                    });
                }
            }
        });
    }
    uploadFileDialoge() {
        this.censusFileUploadDialogeComponent = this.dialog.open(CensusFileUploadDialogeComponent, {
            width: '40%',
            data: {
                provider: this.currentUser._id,
                facility: this.census.facility,
                census_id: this.params.id,
                company_id: this.currentUser.company_id
            }
        })
        this.censusFileUploadDialogeComponent.afterClosed().subscribe(data => {
            if (data) {
                // console.log(data.doc_id)
                this.censusPatientListService.subscribeRehabReportsResult(data.doc_id)
                this.censusListService.getRehabDocsCount(this.params.id).subscribe((response: any) => {
                    if (response.status == 200) {
                        this.census.rehab_docs_count = response.data;
                    }
                })
            }
        });
    }
    patientSlected(patient, event) {
        if (event) {
            this.selection.toggle(patient)
            this.selectedPatientsToImport.push(patient)
        }
        else {
            let index = this.selectedPatientsToImport.map(function (e) {
                return e._id;
            }).indexOf(patient._id);
            // let index = this.selectedPatientsToImport.indexOf(patient);
            if (index > -1) {
                this.selectedPatientsToImport.splice(index, 1)
                this.selection.clear()
                this.selection.select(...this.selectedPatientsToImport)
            }
        }
    }
    async importPatientDialoge() {
        console.log("selectedPatientsToImport", this.selectedPatientsToImport);
        let keyName = 'patients';
        if (Array.isArray(this.selectedPatientsToImport.length > 1)) {
            keyName = 'patients';
        }
        let only_pcc = !!this.selectedPatientsToImport.find(p => !!p.pcc_patientId);
        const obj = {
            filter_facility_id: this.census.facility._id,
            filter_provider_id: this.census.createdBy,
            oldCensusId: this.params.id
        }
        const dialogRef = await this.dialog.open(PatientImporterDialog, {
            width: '50%',
            data: { [keyName]: this.selectedPatientsToImport, action: 'import', only_pcc: only_pcc, obj: obj },
        });
        dialogRef.afterClosed().subscribe(async (data) => {

            this.selectedPatientsToImport = [];
            this.selection.clear()
            if (data) {
                this.detect_refresh_child = this.detect_refresh_child ? false : true
            }
            else {
                this.initPatientsByProvider();
            }
        });

        // this.initPatientsByProvider();
    }
    showAddChargeIcon(patient) {
        if (patient.charges && patient.charges.length > 0) {
            return true
        }
        else {
            return false
        }
    }
    async openEditCharge(patient) {
        if (this.isOwner(this.census)) {
            const chargeResponse: any = await this.censusListService.getCharge({
                patient_id: patient._id,
                rounding_sheet_id: this.census._id,
            }).toPromise();
            if (chargeResponse.status === 200) {
                let date_obj;
                if (chargeResponse.data.date_obj) {
                    // console.log("charge.date_obj: ", charge.date_obj);
                    let { year, month, day, hour, minute } = chargeResponse.data.date_obj;
                    if (month < 10) {
                        month = '0' + month;
                    }
                    if (day < 10) {
                        day = '0' + day;
                    }
                    let str = `${month}-${day}-${year}`;
                    if (hour && minute) {
                        if (minute < 10) {
                            minute = `0${minute}`
                        }
                        if (hour < 10) {
                            hour = `0${hour}`
                        }
                        str += ` ${hour}:${minute}`
                    }
                    date_obj = str;
                }
                this.commonService.setDraftedCharge(chargeResponse.data);
                this._router.navigate(['/patient-charge'], {
                    queryParams: {
                        patient_id: chargeResponse.data.patient_id._id,
                        rs_id: chargeResponse.data?.rounding_sheet_id,
                        visit_date: chargeResponse.data.visit_date.toString(),
                        patient_name: chargeResponse.data.patient_id.first_name + ' ' + chargeResponse.data.patient_id.last_name,
                        dob: chargeResponse.data.patient_id.date_of_birth,
                        // facility_id: patient.facility_id
                        facility_id: chargeResponse.data.facility_id_ref,
                        charge_id: chargeResponse.data._id,
                        date_obj,
                    }
                });
            }
        }
    }
    async openCharges(patient) {
        // if(!this.currentUser.charge_status) {
        //   this._toastr.error("Charge already created")
        //   return;
        // }  
        if (this.census && this.isOwner(this.census) && (!this.hasChargeAndStatus(patient, 'submit') && !this.hasChargeAndStatus(patient, 'draft'))) {
            // if (this.census  && (!this.hasChargeAndStatus(patient, 'submit') && !this.hasChargeAndStatus(patient, 'draft'))) {
            // return true;
            this.commonService.setDraftedCharge(null)
            let patient_id = patient._id;
            let rs_id = this.params.id;
            localStorage.setItem('hide_census', "true");
            this._router.navigate(['/patient-charge'], {
                queryParams: {
                    patient_id: patient_id,
                    rs_id: rs_id,
                    visit_date: this.census.date.toString(),
                    timeZone: this.census.facility.pcc_timeZone || this.census.facility.timeZone,
                    patient_name: patient.first_name + ' ' + patient.last_name,
                    dob: patient.date_of_birth,
                    // facility_id: patient.facility_id
                    facility_id: this.census.facility._id,
                    url: "census"
                }
            });
        }
        else if (this.census && this.isOwner(this.census) && (this.hasChargeAndStatus(patient, 'draft') || this.hasChargeAndStatus(patient, 'submit'))) {
            // else if (this.census && (this.hasChargeAndStatus(patient, 'draft') || this.hasChargeAndStatus(patient, 'submit'))) {
            const chargeResponse: any = await this.censusListService
                .getCharge({
                    patient_id: patient._id,
                    rounding_sheet_id: this.census._id,
                })
                .toPromise();
            this.commonService.setDraftedCharge(chargeResponse.data);
            let patient_id = patient._id;
            let rs_id = this.params.id;
            localStorage.setItem('hide_census', "true");
            this._router.navigate(['/patient-charge'], {
                queryParams: {
                    patient_id: patient_id,
                    rs_id: rs_id,
                    visit_date: this.census.date.toString(),
                    timeZone: this.census.facility.pcc_timeZone || this.census.facility.timeZone,
                    patient_name: patient.first_name + ' ' + patient.last_name,
                    dob: patient.date_of_birth,
                    // facility_id: patient.facility_id
                    facility_id: this.census.facility._id,
                    url: "census"
                }
            });
        }
        if (!this.isOwner(this.census)) {
            this._toastr.error("Not accessible")
            return;
        }
        else {
            // this._toastr.error("Charge already created")
            return;
        }

        // return false;


    }
    facilityAuthorization(facility) {
        if (!facility.pcc_2_legged_authentication) {
            if (
                // User is Not logged in so cannot access via 2-legged 
                !this.isLoggedIntoPCC() ||
                // User is logged but facility is not logged in so cannot access via 2-legged 
                (this.isLoggedIntoPCC() && !this.pccService.isLoggedInFacility(facility))
            ) {
                this.error2LeggedAuth = 'Please login to PCC to view data.';
                this.error2LeggedAuthFlag = true
                return;
            }
        }
        else {
            this.error2LeggedAuthFlag = false
            this.error2LeggedAuth = '';
        }
    }
    async noteEditor(element, telemedicine, index) {
        this.loader.startLoader(this.loaderId);
        if (this.getRecentNoteInCensus(element)) {
            const response = this.verifyNoteAccessForUser(this.getRecentNoteInCensus(element), 'View');
            if (response.status) {
                const noteId = this.getRecentNoteInCensus(element)._id;
                this.loader.stopLoader(this.loaderId);
                if (window.location.pathname.includes('company'))
                    return this._router.navigate([`/company/${this.currentUser.company_id}/note-editor/${noteId}`]);
                else
                    return this._router.navigate(['/note-editor/', noteId]);
            } else {
                return this._toastr.warning(response.message);
            }

        }
        let date_obj;
        if (this.census.date_obj) {
            let { year, month, day } = this.census.date_obj;
            if (month < 10) {
                month = '0' + month;
            }
            if (day < 10) {
                day = '0' + day;
            }
            date_obj = `${month}-${day}-${year}`;
        } else {
            const date = new Date(this.census.date);
            let year: any = date.getFullYear();
            let month: any = date.getMonth() + 1;
            let day: any = date.getDate();
            if (month < 10) {
                month = '0' + month;
            }
            if (day < 10) {
                day = '0' + day;
            }
            date_obj = `${month}-${day}-${year}`;
        }

        let rs_id = this.params.id;
        let routerMapParams: any = {
            patient_id: element._id,
            facility_id: this.census.facility._id,
            dos: this.census.date,
            census_id: this.census._id,
            telemedicine: telemedicine,
            provider_id: this.census.createdBy,
            nextPatientNote: true,
        }
        if (date_obj) {
            routerMapParams.date_obj = date_obj;
        }
        const noteId: any = await this.createNote(
            element._id,
            this.census.facility._id,
            this.census._id,
            this.census.createdBy,
        );
        // try {
        //   note = await this.showTranscriptionTemplateDialog({ ...note });
        // } catch (err) {
        //   console.log("err", err);
        //   this.loader.stopLoader(this.loaderId);
        //   return;
        // }

        // const noteId: any = await this.saveNote(note);
        // if (!noteId) {
        //   return;
        // }
        let routerParams: any = {
            patient_id: element._id,
            facility_id: this.census.facility._id,
            dos: this.census.date,
            rs_id: this.census._id,
            visit_date: this.census.date.toString(),
            patient_name: element.first_name + ' ' + element.last_name,
            dob: element.date_of_birth
        }
        if (date_obj) {
            routerParams.date_obj = date_obj;
        }
        this.censusPatientListService.setChargeData(routerMapParams)
        if (element.charges) {
            this.censusPatientListService.setCharge(element.charges)
        }

        localStorage.setItem('hide_census', "true");
        if (window.location.pathname.includes('company'))
            this._router.navigate([`/company/${this.currentUser.company_id}/note-editor/${noteId}`]);
        else
            this._router.navigate(['/note-editor/', noteId])
        this.loader.stopLoader(this.loaderId);
    }
    RedirectToChargeCaptureInNote(element) {
        console.log("e", element);
        if (this.checkVoidCensus()) {
            const patientCharge = this.hasPatientChargeInCensus(element);
            const recentNote = this.getRecentNoteInCensus(element);
            let date_obj;
            if (this.census.date_obj) {
                let { year, month, day } = this.census.date_obj;
                if (month < 10) {
                    month = '0' + month;
                }
                if (day < 10) {
                    day = '0' + day;
                }
                date_obj = `${month}-${day}-${year}`;
            } else {
                const date = new Date(this.census.date);
                let year: any = date.getFullYear();
                let month: any = date.getMonth() + 1;
                let day: any = date.getDate();
                if (month < 10) {
                    month = '0' + month;
                }
                if (day < 10) {
                    day = '0' + day;
                }
                date_obj = `${month}-${day}-${year}`;
            }

            let routerMapParams: any = {
                patient_id: element._id,
                facility_id: this.census.facility._id,
                dos: this.census.date,
                census_id: this.census._id,
                provider_id: this.census.createdBy,
                isChargeTabActive: true,
                shouldShowTemplateDialog: false
            }
            if (date_obj) {
                routerMapParams.date_obj = date_obj;
            }
            if (patientCharge) {
                if (patientCharge.note_id) {
                    this._router.navigate([`/note-editor/${patientCharge.note_id}`]);
                } else {
                    this._router.navigate([`/note-editor/new`, routerMapParams]);
                }
            } else {
                if (recentNote && recentNote?.is_signed === "false") {
                    this._router.navigate([`/note-editor/${recentNote._id}`]);
                    console.log(recentNote);
                } else {
                    this._router.navigate(['/note-editor/new', routerMapParams])
                }
            }
        }
    }
    async getRecentNoteByCensusId() {
        const response: any = await this.censusPatientListService.getRecentNoteByCensusId(this.params.id).toPromise();
        if (response.status === 200) {
            this.recentNotes = response.data;
        }
    }
    async initCensus(reloadselection = false) {
        if (!reloadselection)
            this.loader.startLoader(this.loaderId);

        const censusResponse: any = await this.censusPatientListService.getCensus(this.params.id).toPromise();

        if (censusResponse.status === 200) {

            if (!reloadselection)
                this.loader.stopLoader(this.loaderId);

            console.log('init cens', censusResponse.data);
            this.isTagsDisplayAllowed = censusResponse.data.company.should_show_tag;
            if (censusResponse?.data?.patientList) {
                this.census_id = censusResponse.data['_id'];
                const { patientList, ...data } = censusResponse.data;
                this.census = {
                    ...data,
                    patientList: patientList?.filter(p => p.is_active === 'true').map(p => new Patient(p)) as Patient[]
                };
                this.transformPatientList(this.census.patientList);

                let currentPatientsSeen = this.census.currentPatientsSeen;
                for (const patient of this.census.patientList) {
                    if (patient.last_admit_date_obj && patient.last_discharge_date_obj
                        && Object.values(patient.last_admit_date_obj).length > 0
                        && Object.values(patient.last_discharge_date_obj).length > 0) {
                        const last_discharge_date = `${patient.last_discharge_date_obj.month}/${patient.last_discharge_date_obj.day}/${patient.last_discharge_date_obj.year}`;
                        const last_admit_date = `${patient.last_admit_date_obj.month}/${patient.last_admit_date_obj.day}/${patient.last_admit_date_obj.year}`;
                        if (moment(last_discharge_date).isBefore(last_admit_date)) {
                            patient.isPatientReadmitted = true;
                        }
                        else if (moment(last_discharge_date).isAfter(last_admit_date)) {
                            patient.isPatientDischarged = true;
                        }
                    }
                    else if (!patient.last_admit_date_obj && patient.last_discharge_date_obj) {
                        patient.isPatientDischarged = true;
                    }
                    let lastCPT = this.getLastCPT(patient);
                    patient.lastCPT = lastCPT;
                    if (Array.isArray(patient.non_pcc_payload)) {
                        for (const item of patient.non_pcc_payload) {
                            if (item.census_id_ref == this.census._id) {
                                patient.non_pcc_payload = item;
                            }
                        }
                    }
                    patient.isSeen = false;
                    if (currentPatientsSeen.includes(patient._id)) patient.isSeen = true;
                    if(patient.source === 'MatrixCare' || patient.facility_id_ref.source === 'MatrixCare') {
                        const { primary_payor, secondary_payor } = patient.getPrimaryAndSecondaryMatrixPayors(patient)
                        patient["primary_matrix_payor"] = primary_payor
                        patient["secondary_matrix_payor"] = secondary_payor
                    }
                }
            }
            if(this.company.portal_type === global.PORTAL_TYPE.NON_WC) {
                if (this.currentUser.other_settings.census_pdf_column?.Medication) {
                    this.getPatientMipsStatus();
                }
                else {
                    this.isMedications = true;
                }
            }
            else {
                this.isMedications = true;
            }
            
            if (!this.census?.patientList) return;

            if (this.census.facility?.pcc_facId) {
                const existance = this.sources?.filter((el) => { return el.value == 'pcc' })
                if (existance.length == 0) {
                    this.sources.push({ value: 'pcc', label: 'PCC' })
                }
            }

            this.initTableDataSource();
            if (this.isActivePatientList) {
                this.filterTableForActiveOrInactivePatients(true);
            }
            else {
                this.filterTableForActiveOrInactivePatients(false);
            }

            this.checkEligibilityStatus();
        }
        if (this.census.facility?.pcc_facId) {
            this.patientsImportSource = 'pcc';
            // return 'pcc';
        }
        else if (this.census.facility?.source) {
            this.patientsImportSource = 'MatrixCare'
            // return 'MatrixCare';
        }
        else {
            this.patientsImportSource = 'dn'
            // return 'dn';
        }
        console.log("patientsImportSource", this.patientsImportSource);
    }

    checkEligibilityStatus() {
        if (!this.census?.patientList || !this.isActivePatientList || !this.view_eligibility_status) {
            return;
        }

        // const patients = this.census.patientList.filter( patient => ( patient.source === 'PointClickCare' ));
        const patients = this.census.patientList;

        const promises = patients.map(patient => {
            patient.eligibilityStatus = 'loading';

            return this.censusPatientListService.getPatientEligibilityStatus(patient._id)
                .then((response: any) => {
                    patient.eligibilityStatus = null;
                    patient.eligibilityData = null;
                    patient.eligibilityIcon = null;

                    if (response.status === 200 && response.data.eligibilityStatus != null) {
                        const eligibilityStatus = response.data.eligibilityStatus.toUpperCase();

                        patient.eligibilityStatus = eligibilityStatus;
                        patient.eligibilityData = response.data.eligibilityData;

                        switch (eligibilityStatus) {
                            case 'ACTIVE COVERAGE':
                            case 'ACTIVE - FULL RISK CAPITATION':
                            case 'ACTIVE - SERVICES CAPITATED':
                            case 'ACTIVE - SERVICES CAPITATED TO PRIMARY CARE PHYSICIAN':
                            case 'ACTIVE - PENDING INVESTIGATION':
                            case 'CO-PAYMENT':
                            case 'COVERAGE BASIS':
                            case 'OUT OF POCKET (STOP LOSS)':
                            case 'UNLIMITED':
                            case 'COST CONTAINMENT':
                            case 'PRIMARY CARE PROVIDER':
                            case 'PRE-EXISTING CONDITION':
                            case 'PRIOR YEAR(S) HISTORY':
                            case 'SPEND DOWN':
                                patient.eligibilityIcon = '../../../assets/icons/approved-check.png';
                                break;
                            case 'DEDUCTIBLE':
                            case 'EXCLUSIONS':
                                                            case 'LIMITATIONS':
                            case 'MANAGED CARE COORDINATOR':
                            case 'NOT DEEMED A MEDICAL NECESSITY':
                            case 'OTHER OR ADDITIONAL PAYOR':
                                patient.eligibilityIcon = '../../../assets/icons/warning.png';
                                break;
                            default:
                                patient.eligibilityIcon = '../../../assets/icons/minus.png';
                        }
                    }

                    return response;
                });
        });

        return Promise.all(promises);
    }

    openEligibilityDialog(patient) {
        const dialogRef = this.dialog.open(EligibilityDialog, {
            data: {
                eligibilityIcon: patient.eligibilityIcon,
                eligibilityStatus: patient.eligibilityStatus,
                eligibilityData: patient.eligibilityData
            },
            panelClass: 'eligibility-dialog-wrap'
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
        });
    }

    getpatientsImportSource(census) {
        if (census) {
            if (census.facility?.pcc_facId) {
                this.patientsImportSource = 'pcc';
                return 'pcc';
            }
            else if (census.facility?.source) {
                this.patientsImportSource = 'MatrixCare'
                return 'MatrixCare';
            }
            else {
                this.patientsImportSource = 'dn'
                return 'dn';
            }
        }
    }
    sourceChange(value) {
        this.patientsImportSource = value;
        console.log("Source cahnged clicked========: ", this.patientsImportSource)
    }
    initTableDataSource() {
        if (this.census) {
            if (this.dataSource) {
                this.dataSource.data = this.census.patientList;
            } else {
                this.dataSource = new MatTableDataSource(this.census.patientList);
                this.dataSource.sort = this.sort;
            }
            if (this.isTagsDisplayAllowed)
                this.addNewTag(0, this.census.patientList[0]);
            // this.censusPatientSortSubscription = this.censusPatientListService.castCensusPatientSort.subscribe(sort => {
            //   // console.log("sort", sort);
            //   if (sort) {
            //     this.sortData(sort);
            //   }
            // });
        }
    }

    async initPatientsByProvider() {
        // const patientsByProviderResponse:any = await this.censusPatientListService.getFacilityPatientsByProviderId(this.currentUser._id, this.census.facility._id).toPromise();
        // if(patientsByProviderResponse.status !== 200) return;
        // this.patientsByProvider = patientsByProviderResponse.data.array;

        // this.filteredPatientsByProvider = this.patientsByProviderControl.valueChanges
        // .pipe(
        //     startWith(''),
        //     map((value:any) => typeof value === 'string' ? value : value.name),
        //     map(name => name ? this._filterPatientsByProvider(name) : this.getFilteredPatients(this.patientsByProvider))
        // );

        this.patientsByProviderControl.valueChanges
            .pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe(async (name) => this.doInitPatients(name));
    }
    doInitPatients(name) {
        if (!name) return;
        if (!(typeof name == 'string')) return;
        this.loader.startLoader(this.patientsSearchLoaderId);
        console.log("this.patientsImportSource :;;;;;;;", this.patientsImportSource)
        if (this.patientsImportSource == 'MatrixCare') {
            var details = {
                id: <string>null,
                auth_key: <string>null,
                usertype: <any>null,
                primary_doctor_id: <string>null,
                index: <string>null,
                assocPatient: null,
            };
            details.id = this.currentUser._id;
            details.auth_key = this.currentUser.auth_key;
            details.usertype = this.currentUser.user_type;
            details.assocPatient = "matrixP"
            let filter = {
                facility: this.census.facility._id,
                patientName: name
            }
            this.filteredPatientsByProvider = this._patientListService
                .getMatrixPatientByFacilityId(details, null, filter)
                // .getPatientsByFacilityId(details, null ,filter)
                .pipe(
                    tap((patientsResponse: any) => {
                        this.patientsByProvider = patientsResponse.data.array.map(patient => {
                            if (!patient.is_patient_consent)
                                patient.patientConsentToolTip = "CCM Consent not obtained";
                            else
                                patient.patientConsentToolTip = "CCM consent already obtained";
                            if (!patient.is_patient_pcm_consent)
                                patient.patientPCMConsentToolTip = "PCM Consent not obtained";
                            else
                                patient.patientPCMConsentToolTip = "PCM consent already obtained";
                            return new Patient(patient);
                        });
                        this.loader.stopLoader(this.patientsSearchLoaderId);
                    }),
                    map((patientsResponse: any) => {
                        return patientsResponse.data.array
                            ?.filter(p => !!p)
                            .map((p) => ({
                                ...p,
                                name: this.commonService.getPatientFullNameFormat2(p)
                            }));
                    })
                );
        }
        else if (this.patientsImportSource == 'dn') {
            var details = {
                id: <string>null,
                auth_key: <string>null,
                usertype: <any>null,
                primary_doctor_id: <string>null,
                index: <string>null,
                assocPatient: null,
            };
            details.id = this.currentUser._id;
            details.auth_key = this.currentUser.auth_key;
            details.usertype = this.currentUser.user_type;
            details.assocPatient = "dn"
            let filter = {
                facility: this.census.facility._id,
                patientName: name
            }
            this.filteredPatientsByProvider = this._patientListService
                .getPatientsByFacilityId(details, null, filter)
                .pipe(
                    tap((patientsResponse: any) => {
                        this.patientsByProvider = patientsResponse.data.array.map(patient => {
                            if (!patient.is_patient_consent)
                                patient.patientConsentToolTip = "CCM Consent not obtained";
                            else
                                patient.patientConsentToolTip = "CCM consent already obtained";
                            if (!patient.is_patient_pcm_consent)
                                patient.patientPCMConsentToolTip = "PCM Consent not obtained";
                            else
                                patient.patientPCMConsentToolTip = "PCM consent already obtained";
                            return new Patient(patient);
                        });
                        this.loader.stopLoader(this.patientsSearchLoaderId);
                    }),
                    map((patientsResponse: any) => {
                        return patientsResponse.data.array
                            ?.filter(p => !!p)
                            .map((p) => ({
                                ...p,
                                name: this.commonService.getPatientFullNameFormat2(p),
                            }));
                    })
                );
        }
        else {
            this.filteredPatientsByProvider = this._patientListService
                .getPatientsV2(
                    this.census.companyId,
                    this.census.facility._id,
                    undefined,
                    name)
                .pipe(
                    tap((patientsResponse: any) => {
                        this.patientsByProvider = patientsResponse.data.array.map(patient => {
                            if (!patient.is_patient_consent)
                                patient.patientConsentToolTip = "CCM Consent not obtained";
                            else
                                patient.patientConsentToolTip = "CCM consent already obtained";
                            if (!patient.is_patient_pcm_consent)
                                patient.patientPCMConsentToolTip = "PCM Consent not obtained";
                            else
                                patient.patientPCMConsentToolTip = "PCM consent already obtained";
                            return new Patient(patient);
                        });
                        this.loader.stopLoader(this.patientsSearchLoaderId);
                    }),
                    map((patientsResponse: any) => {
                        return patientsResponse.data.array
                            ?.filter(p => !!p)
                            .map((p) => ({
                                ...p,
                                name: this.commonService.getPatientFullNameFormat2(p),
                            }));
                    })
                );
        }
    }
    async initPccPatients() {
        this.pccPatientsControl.valueChanges
            .pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe(async (name) => this.doInitPccPatients(name));
    }
    async doInitPccPatients(name) {
        if (!name) return;
        // if(!this.pccService.hasPCCOrgUUID()) {
        //   return this._toastr.warning('You must logged into PCC atleast once')
        // }

        if (this.searchPatientStatus === 'All') {
            this.searchPatientStatus = ''
        }

        this.loader.startLoader(this.patientsSearchLoaderId);


        const args: any = {
            pccOrgUuid: this.census.facility.pcc_orgUuid,
            pccFacID: this.census.facility.pcc_facId,
            pccPatientStatus: this.searchPatientStatus,
            pccPatientName: name,
            page: 1,
            pageSize: 200
        }

        if (this.isLoggedIntoPCC()) {
            if (this.pccService.isLoggedInFacility(this.census.facility)) {
                args.authMode = 'USER'
            } else {
                args.authMode = 'APP'
            }
        }

        this.filteredPccPatients = this.pccService
            .getPatientsByFacilityID(
                args
            )
            .pipe(
                tap((pccPatientsResponse: any) => {
                    this.pccPatients = pccPatientsResponse.data.data;
                    this.loader.stopLoader(this.patientsSearchLoaderId);
                    pccPatientsResponse.data.data.map(patient => {
                        if (!patient.is_patient_consent)
                            patient.patientConsentToolTip = "CCM Consent not obtained";
                        else
                            patient.patientConsentToolTip = "CCM consent already obtained";
                        if (!patient.is_patient_pcm_consent)
                            patient.patientPCMConsentToolTip = "PCM Consent not obtained";
                        else
                            patient.patientPCMConsentToolTip = "PCM consent already obtained";
                    })
                }),
                map((pccPatientsResponse: any) => {
                    return pccPatientsResponse.data.data
                        ?.filter(p => !!p)
                        .map((p) => ({
                            ...p,
                            name: `${p.lastName}, ${p.firstName}`,
                            pcc_patientId: String(p.patientId),
                        }));
                })
            );

    }
    private _filterPatientsByProvider(name): any[] {
        const filterValue = name.toLowerCase();

        return this.getFilteredPatients(this.patientsByProvider).filter(patient => patient.name.toLowerCase().indexOf(filterValue) === 0);
    }
    private _filterPccPatients(name): any[] {
        const filterValue = name?.toLowerCase();
        return this.getFilteredPatients(this.pccPatients)?.filter(patient => patient.name.toLowerCase().indexOf(filterValue) === 0);
    }
    getFilteredPatients(patients) {
        if (!Array.isArray(patients)) return [];

        let resultPatients = [];
        // resultPatients = _.cloneDeep(this.patientsByProvider);
        for (const patient of patients) {
            let alreadyExistsInRoundingSheet;
            if (patient.patientId) {
                alreadyExistsInRoundingSheet = this.census.patientList.find(p => p.pcc_patientId === String(patient.patientId));
            } else {
                alreadyExistsInRoundingSheet = this.census.patientList.find(p => p._id === patient._id);
            }
            if (!alreadyExistsInRoundingSheet) {
                resultPatients.push(patient)
            }
        }
        return resultPatients;
    }
    getFilteredPccPatients(patients) {
        if (!Array.isArray(patients)) return [];

        // patients = patients.map(p => ({
        //   ...p,
        //   name: `${p.lastName}, ${p.firstName}`,
        //   pcc_patientId: p.patientId
        // }) )
        let resultPatients = [];
        // resultPatients = _.cloneDeep(this.patientsByProvider);
        for (const patient of patients) {
            const alreadyExistsInRoundingSheet = this.census.patientList.find(p => String(p.pcc_patientId) === String(patient.patientId));
            if (!alreadyExistsInRoundingSheet) {
                resultPatients.push(patient)
            }
        }
        return resultPatients;
    }
    displayPatientFn(patient): string {
        return patient && patient.name ? patient.name : '';
    }
    async isPatientAlreadyExistsInCensus(patient) {

        const patientExistsInCurrentCensus = this.census.patientList.find(p => {
            if (patient._id) {
                if (p._id === patient._id) {
                    return 'current';
                }
            } else {
                if (String(p.pcc_patientId) === String(patient.patientId)) {
                    return 'current';
                }
            }
        });

        if (patientExistsInCurrentCensus) return 'current';

        const details = {
            ...this.authService.authObject,
            patient_id: patient._id,
            pcc_patientId: patient.pcc_patientId,
            pcc_orgUuid: patient.orgUuid
        }
        const response: any = await this._patientRoungingSheetService.checkIfPatientExistInAssociatedProviderCensus(details).toPromise();
        if (response.status === 200) {
            if (response.data.length > 0) return response.data;
        }
        return false;
    }

    getDOS(census) {
        if (census.date_obj) {
            let { year, month, day } = census?.date_obj;
            if (month < 10) {
                month = '0' + month;
            }
            if (day < 10) {
                day = '0' + day;
            }
            return `${month}/${day}/${year}`;
        }

        if (!('pcc_timeZone' in census.facility || 'timeZone' in census.facility)) {
            census.facility.timeZone = 'America/New_York';
        }
        // console.log('pcc_timeZone' in patientRoundingSheet.facility || 'timeZone' in patientRoundingSheet.facility)
        let timezone = census.facility.pcc_timeZone ? census.facility.pcc_timeZone : census.facility.timeZone;
        let serviceDate = moment(census.date).utc().tz(timezone).format('MM/DD/YYYY')
        return serviceDate;

    }
    addPatientToCurrrentRoundingSheet(patient, notification = true) {
        // const patient = event.option.value;
        this.patientsByProviderControl.reset();

        let infoToast;
        const details = {
            ...this.authService.authObject,
            patientRoundingSheetId: this.census._id,
            patientId: patient._id,
            companyId: this.currentUser.company_id,
            facility: this.census.facility._id
        }
        if (notification) {
            infoToast = this._toastr.info('Adding Patient to Census', "Please wait", { disableTimeOut: true });
        }
        if(this.company.portal_type === global.PORTAL_TYPE.NON_WC) {
            if (this.currentUser.other_settings.census_pdf_column?.Medication || this.currentUser.other_settings.census_pdf_column?.mips) {
                this.isMedications = false;
            }
        }
        else {
            this.isMedications = true;
        }
        this._patientRoungingSheetService.addPatientToPatientRoundingSheet(details).subscribe(async (response: any) => {
            if (response.status === 200) {
                // console.log(response);
                const { chargesOwner, ...patient } = response.data;
                if (patient.last_admit_date_obj && patient.last_discharge_date_obj
                    && Object.values(patient.last_admit_date_obj).length > 0
                    && Object.values(patient.last_discharge_date_obj).length > 0) {
                    const last_discharge_date = `${patient.last_discharge_date_obj.month}/${patient.last_discharge_date_obj.day}/${patient.last_discharge_date_obj.year}
              `;
                    const last_admit_date = `${patient.last_admit_date_obj.month}/${patient.last_admit_date_obj.day}/${patient.last_admit_date_obj.year}`;
                    if (moment(last_discharge_date).isBefore(last_admit_date)) {
                        patient.isPatientReadmitted = true;
                    }
                    else if (moment(last_discharge_date).isAfter(last_admit_date)) {
                        patient.isPatientDischarged = true;
                    }
                }
                else if (!patient.last_admit_date_obj && patient.last_discharge_date_obj) {
                    patient.isPatientDischarged = true;
                }
                let lastCPT = this.getLastCPT(patient);
                patient.lastCPT = lastCPT;
                const isPatientExistInCurrentCensus = this.census.patientList.find(p => {
                    if (p.pcc_patientId) {
                        return p.pcc_patientId === patient.pcc_patientId;
                    } else if (p._id) {
                        return p._id === patient._id;
                    }
                });
                if (!isPatientExistInCurrentCensus) {
                    if (patient.pcc_patientId) {
                        patient.source = 'PointClickCare';
                    }
                    this.census.patientList.push(new Patient(patient));
                    if (this.currentUser.other_settings.census_pdf_column?.Medication || this.currentUser.other_settings.census_pdf_column?.mips || this.currentUser.other_settings?.census_columns) {
                        this.getPatientMipsStatus()
                      }
                    if (this.isActivePatientList) {
                        this.filterTableForActiveOrInactivePatients(true);
                    }
                    else {
                        this.filterTableForActiveOrInactivePatients(false);
                    }
                    this.transformPatientList(this.census.patientList, this.census.patientList[this.census.patientList.length - 1]);
                    this.census.chargesOwnerList?.push(chargesOwner);
                    this.doSortPatients();
                }
                //this.reloadCensus();
                // this.table.renderRows();
                // this.initCensus();
                // this.patientsByProviderControl.setValue('');

                // Do not show Success toast message on patient import in Census
                // if(notification) {
                //   this._toastr.success('Patient added to Census successfully');
                // }
                if (infoToast) {
                    this._toastr.clear(infoToast.toastId)
                }
            } else {
                if (response.data) {
                    let patient = response.data;

                    if (this.census.patientsInactive.includes(patient._id)) {
                        this.census.patientsInactive = this.census.patientsInactive.filter(function (element) {
                            return element != patient._id;
                        })
                    }
                    if (this.currentUser.other_settings.census_pdf_column?.Medication || this.currentUser.other_settings.census_pdf_column?.mips || this.currentUser.other_settings?.census_columns) {
                        this.getPatientMipsStatus()
                    }
                    if (this.isActivePatientList) {
                        this.filterTableForActiveOrInactivePatients(true);
                    }
                    else {
                        this.filterTableForActiveOrInactivePatients(false);
                    }
                    this.doSortPatients();
                    this._toastr.success("Patient Added Successfully");

                } else {

                    if (infoToast) {
                        this._toastr.clear(infoToast.toastId)
                    }
                    this._toastr.warning(response.message);

                }
            }
        }, () => { }, () => () => {
            if (infoToast) {
                this._toastr.clear(infoToast.toastId)
            }
        })

    }

    async handleMatrixAddToCensus(selectedPatients) {

        // if (!selectedPatientRoundingSheet) {
        //   return this._toastr.error('Please select census', 'Error');
        // }
        selectedPatients = [selectedPatients];
        this.newMatrixImportedList = [];
        for await (let patient of selectedPatients) {
            await this.importMatrixPatient(patient);
        }
        this._toastr.info('Adding Patients to Census');
        if (this.newMatrixImportedList?.length) {
            const recurrent = false;

            for await (let patnt of this.newMatrixImportedList) {
                this.addPatientToCurrrentRoundingSheet(patnt, false);
            }

            this.newMatrixImportedList = [];
        }
        return;
    }

    async importMatrixPatient(matrixPatientId) {

        let primaryProviderId;
        if (this.currentUser.user_type == global.USER_TYPE.BUSINESS_OFFICER) {
            primaryProviderId = null; //this.selectedProviderId;
        } else if (this.currentUser.user_type == global.USER_TYPE.DOCTOR || this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE) {
            primaryProviderId = this.currentUser._id;
        } else if (this.currentUser.user_type == global.USER_TYPE.TRANSCRIBER || this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
            primaryProviderId = this.census.createdBy;
        }

        if (!primaryProviderId) {
            return this._toastr.warning(
                'Please select primary Provider to import patient.',
                'Warning'
            );
        }
        const infoToast = this._toastr.info('Importing Patient', 'Please wait', {
            disableTimeOut: true,
        });
        const selectedFacilityId = this.census.facility._id;

        return new Promise((resolve, reject) => {

            const Matrix_patient_id = matrixPatientId?._id ? matrixPatientId?._id : matrixPatientId;

            this.matrixService.importMatrixPatient(Matrix_patient_id, primaryProviderId, selectedFacilityId)
                .subscribe(
                    (response: any) => {
                        this._toastr.clear(infoToast.toastId)
                        if (response.status === 200) {
                            // this.toastr.success('Imported Successfully', 'Success');
                            const patient = response.data;
                            if (response.message == 'Patient already exists') {
                                // this._toastr.warning(
                                //   'Patient already exists.',
                                //   'Warning'
                                // );

                                if (patient?.length > 1) {

                                    const dialogRef = this.dialog.open(DuplicatePatientDialogComponent, {
                                        width: '70%',
                                        height: '50%',
                                        data: {
                                            incoming: matrixPatientId,
                                            existing: patient,
                                            source: 'MatrixCare'
                                        }
                                    });
                                    dialogRef.afterClosed().subscribe(async (data: any) => {
                                        if (data && data.addnew == true) {
                                            this.matrixService.importMatrixPatient(Matrix_patient_id, primaryProviderId, selectedFacilityId, true).subscribe((res: any) => {
                                                if (res.status === 200) {
                                                    if (res.data) {
                                                        this.newMatrixImportedList.push(res.data);
                                                    }
                                                    resolve(1);
                                                }
                                            })
                                        } else if (data && data.addnew == false && data.patient) {
                                            const source_patients = data.source_patients;
                                            this._toastr.info('Merging in progress...');
                                            this.matrixService.mergeMatrixPatient(Matrix_patient_id, data.patient._id, source_patients, primaryProviderId, selectedFacilityId).subscribe((res: any) => {
                                                if (res.status === 200) {
                                                    if (res.data) {
                                                        this.newMatrixImportedList.push(res.data);
                                                    }
                                                    resolve(1);
                                                }
                                            })
                                        } else {
                                            resolve(1);
                                        }
                                    });
                                } else if (patient?.length == 1) {
                                    if (patient[0]) {
                                        this.newMatrixImportedList.push(patient[0]);
                                    }
                                    resolve(1);
                                }
                            } else {
                                this.newMatrixImportedList.push(patient);
                                resolve(1);
                            }
                        }
                    }
                );
        });

    }

    importAndAddPatientToCurrrentRoundingSheet(pccPatient, sync) {
        // if(!this.pccService.hasPCCOrgUUID()) {
        //   return this._toastr.warning('You must logged into PCC atleast once')
        // }
        this.pccPatientsControl.reset();
        this.pccPatientsControl.markAsDirty();
        this.pccPatientsControl.markAsTouched();
        this.pccPatientsControl.updateValueAndValidity();

        // const pccPatient = event.option.value;
        const pccPatientId = pccPatient.patientId;
        const primaryProviderId = this.currentUser._id;
        const facilityId = this.census.facility._id;
        const pccOrgUuid = this.census.facility.pcc_orgUuid;
        this.loader.startLoader(this.loaderId);
        const infoToast = this._toastr.info('Importing Patient', 'Please wait', {
            disableTimeOut: true,
        });
        this.pccService
            .importPCCPatient(
                pccOrgUuid,
                pccPatientId,
                primaryProviderId,
                facilityId,
                sync
            )
            .subscribe(
                async (response: any) => {

                    if (response.status === 200) {

                        let relation;
                        let patient = Array.isArray(response.data) ? response.data[0] : response.data;
                        await this._patientListService.getOnePatient_fromCompFacPatient({ patient_id: patient?._id, company_id: this.currentUser.company_id }).subscribe((res: any) => {
                            relation = res?.data;

                            if (response.message == 'Patient already exists' && relation?.facility_id !== this.census.facility._id) {
                                this._toastr.error(`${response.message} in another facility`); //${patient.facility_id_ref.title}`);
                                return;
                            }

                            if (Array.isArray(response.data)) {
                                this.addPatientToCurrrentRoundingSheet(patient);
                            } else {
                                this.addPatientToCurrrentRoundingSheet(response.data);
                            }


                        })


                        // const event = {
                        //   option: {
                        //     value: response.data
                        //   }
                        // }

                    } else {
                        this._toastr.error(response.message);
                    }
                },
                () =>
                    this._toastr.error(
                        'Patient could not be imported at this time.',
                        'Failed'
                    ),
                () => {
                    this.loader.stopLoader(this.loaderId);
                    this._toastr.clear(infoToast.toastId)
                }
            );
    }
    handleSeenFilter(e) {
        let filteredPatients = this.census.patientList;
        if (this.filterSeen === 'seen') {
            filteredPatients = filteredPatients?.filter(p => this.isPatientSeenInCensus(p));
        } else if (this.filterSeen === 'not_seen') {
            filteredPatients = filteredPatients?.filter(p => !this.isPatientSeenInCensus(p));
        }
        this.dataSource = new MatTableDataSource(filteredPatients);
    }
    handleSortFilter(e) {
        this.filterSort = e.value;
        localStorage.setItem('sortFilter', JSON.stringify({ sorValue: this.filterSort, prevId: this.census._id.toString() }));
        // this.censusPatientListService.sortFilterValue = this.filterSort;
        this.censusPatientListService.changeFilterSort(e.value);
        if (e == "room_no" || e == "location") {
            this.location_sort_order = this.location_sort_order == "asc" ? "desc" : "asc"
            let sort = {
                active: 'location',
                direction: this.location_sort_order
            }
            this.censusPatientListService.setcensusPatientSort(sort);
        }
        // this.doSortPatients();   
    }

    doSortPatients() {
        let dirc = 'asc';
        if (localStorage.getItem('sortFilter') != undefined) {
            let sortObj = JSON.parse(localStorage.getItem('sortFilter'));
            if (this.census._id.toString() == sortObj.prevId) {
                this.filterSort = sortObj.sorValue || sortObj.sortColumn;
                dirc = sortObj.sortDirection;
            }
            else {
                this.filterSort = 'not_seen';
            }
        }
        else {
            this.filterSort = 'not_seen';
        }
        if (this.filterSort === 'user_sort') {
            this.initTableDataSource();
            if (this.isActivePatientList) {
                this.filterTableForActiveOrInactivePatients(true);
            }
            else {
                this.filterTableForActiveOrInactivePatients(false);
            }
            return;
        }

        let patientList = _.clone(this.census.patientList);
        const oldList = _.clone(this.census.patientList);
        if (this.filterSort === 'room_no' || this.filterSort === 'location') {
            // if(this.census?.facility?.source=='PointClickCare'||this.census?.facility?.source=='MatrixCare'){
            patientList = this.sortPatientListByRoomNo(patientList, dirc);
            // }else{
            //   patientList=patientList;
            // }
            if (!patientList.length) return;
        } else {
            patientList.sort((a, b) => {
                const adtA = a.adtRecord;
                const adtB = b.adtRecord;
                switch (this.filterSort) {

                    case 'status':
                        return compare(this.isPatientSeenInCensus(a) ? 1 : -1, this.isPatientSeenInCensus(b) ? 1 : -1, true)


                    case 'seen':
                        return compare(this.isPatientSeenInCensus(a) ? 1 : -1, this.isPatientSeenInCensus(b) ? 1 : -1, false)

                    case 'not_seen':
                        return compare(this.isPatientSeenInCensus(a) ? 1 : -1, this.isPatientSeenInCensus(b) ? 1 : -1, true)

                    case 'name':
                        const aName = this.commonService.getPatientFullNameFormat2(a);
                        const bName = this.commonService.getPatientFullNameFormat2(b);
                        return compare(aName, bName, true);

                    case 'dob':
                        return compare(new Date(a.date_of_birth).getTime(), new Date(b.date_of_birth).getTime(), true);

                    case 'new_patient':
                        // if (this.getRowClass(a) === 'PRIMARY_DARK' && this.getRowClass(b) !== 'PRIMARY_DARK') {
                        //     return 1;
                        // } else if (this.getRowClass(a) !== 'PRIMARY_DARK' && this.getRowClass(b) === 'PRIMARY_DARK') {
                        //     return -1;
                        // } else {
                        //     return 0;
                        // }
                        const dateA = new Date(a.getAdmissionDate()).getTime();
                        const dateB = new Date(b.getAdmissionDate()).getTime();
                        return compareDate(new Date(dateA).getTime(), new Date(dateB).getTime(), true);

                    case 'admission_date':
                        if (a.getAdmissionDate() && b.getAdmissionDate()) {
                            const dateA = new Date(a.getAdmissionDate()).getTime();
                            const dateB = new Date(b.getAdmissionDate()).getTime();
                            return compare(dateA, dateB, true);
                        }
                        return -1;
                    case 'discharge_date':
                        const dischargeA = a.getDischargeDate();
                        const dischargeB = b.getDischargeDate();
                        if (dischargeA && dischargeB) {
                            const dateA = new Date(dischargeA).getTime();
                            const dateB = new Date(dischargeB).getTime();
                            return compare(dateA, dateB, true);
                        }
                        return -1;


                    case 'payor':
                        if (adtA && adtB) {
                            return compare(adtA.payerName?.toLowerCase(), adtB.payerName?.toLowerCase(), true);
                        }
                        return -1;

                    case 'last_seen':
                        if (a.last_seen.length > 0 && b.last_seen.length > 0) {
                            return compare(new Date(a.last_seen[0].last_seen), new Date(b.last_seen[0].last_seen), true);
                        }
                        return -1;

                    default: return 0;
                }
            })
        }
        // this.census.patientList = patientList.map(p => new Patient(p));;
        const list = this.getPatientListToupdateBySort(oldList, patientList);

        // this.dataSource = new MatTableDataSource(patientList);
        this.initTableDataSource();
        if (this.isActivePatientList) {
            this.filterTableForActiveOrInactivePatients(true);
        }
        else {
            this.filterTableForActiveOrInactivePatients(false);
        }
        // this.filterSort = 'user_sort';
        this.censusPatientListService.updatePatientListSortOrder(this.census._id, list.map(p => p._id))
            // this.censusPatientListService.updatePatientListSortOrder(this.census._id, this.census.patientList.map(p => p._id))
            .subscribe((response: any) => {
            })
    }

    getPatientListToupdateBySort(old_list, new_list) {
        console.log("old_list : ", old_list);
        console.log("new_list : ", new_list);

        let remainingArray = [], resultArray = new_list;
        for (let patient of old_list) {
            const index = new_list.findIndex((p: any) => {
                return p._id?.toString() == patient._id?.toString()
            });
            if (index < 0) {
                remainingArray.push(patient)
            }
        }
        if (remainingArray && remainingArray.length) {
            resultArray = new_list.concat(remainingArray);
        }

        this.census.patientList = resultArray.map(p => new Patient(p));
        return resultArray;
    }

    getSeenPatientsCount() {
        return this.census?.patientList.reduce((count, patient) => count + (this.isPatientSeenInCensus(patient) ? 1 : 0), 0)
    }

    getNotSeenPatientsCount() {
        return this.census?.patientList.reduce((count, patient) => count + (!this.isPatientSeenInCensus(patient) ? 1 : 0), 0)
    }

    async handleDuplications(pccPatient, duplication) {
        let primaryProviderId = this.census.provider_detail?._id || this.currentUser._id;
        const facility = this.census.facility;
        const selectedFacilityId = facility._id;
        return new Promise((resolve, reject) => {
            if (pccPatient && (duplication?.length) && (duplication?.length)) {
                if ((duplication?.length == 1) && (pccPatient?._id?.toString() == duplication[0]?._id?.toString())) {
                    resolve(1);
                    return;
                }
                const dialogRef = this.dialog.open(DuplicatePatientDialogComponent, {
                    width: '70%',
                    height: '50%',
                    data: {
                        incoming: pccPatient,
                        existing: duplication,
                        source: 'PointClickCare'
                    }
                });
                dialogRef.afterClosed().subscribe(async (data: any) => {
                    console.log("add existing data : ", data);
                    if (data && data.addnew == true) {
                        resolve(1);

                    } else if (data && data.addnew == false && data.patient) {
                        const source_patients = data.source_patients;
                        const Pcc_patient_id = pccPatient?._id ? pccPatient?._id : pccPatient;
                        this._toastr.info('Merging in progress...');

                        this.pccService.mergePccPatient(Pcc_patient_id, data.patient._id, source_patients, primaryProviderId, selectedFacilityId).subscribe((res: any) => {
                            if (res.status === 200) {
                                console.log("response.dataaaa : ", res.data);
                                resolve(1);
                            }
                        })
                    } else {
                        resolve(1)
                    }
                });
            } else {
                resolve(1)
            }
        })
    }

    applyFilter(filterValue: string) {
        if (this.dataSource) {
            this.censusPatientListService.changeFilterSearch(filterValue);

            this.dataSource.filter = filterValue.trim().toLowerCase();
            this.dataSource.filterPredicate = function (data, filter: string): boolean {
                const patientName = `${data.last_name.toLowerCase()}, ${data.first_name.toLowerCase()}${data.middle_name ? ' ' + data.middle_name.toLowerCase() + '.' : ''}`
                return patientName.includes(filter);
            };
        }
    }

    handleFilter(result) {
        this.filterDropdownActive = false;
        if (!result) {
            return;
        } else if (result.filter === 'cancel') {
            return;
        } else if (result.filter === 'reset') {
            result = null;
            this.initCensus(true);
            return;
        } else if (!result.payload) {
            return;
        }
        else {
            this.dateOfBirth = result.payload;
        }
    }


    filterDictationsByDateOfService(date_of_birth) {
        this.filterItemsByDate(date_of_birth, 'date_of_birth', this.census);
    }
    filterItemsByDate(date, dateKeyInObject, itemList) {
        const filterDateTime = new Date(date);
        const filterDate = filterDateTime.getDate();
        const filterMonth = filterDateTime.getMonth() + 1;
        const filterYear = filterDateTime.getFullYear();

        itemList = itemList?.filter(t => {
            const dateTime = new Date(t[dateKeyInObject]);
            const date = dateTime.getDate();
            const month = dateTime.getMonth() + 1;
            const year = dateTime.getFullYear();

            return date === filterDate && month === filterMonth && year === filterYear;
        });
        this.dataSource = itemList;
    }

    getRecentPatientADTRecord(patient) {
        // const sortedADTRecords = _.clone(patient.adtRecords);
        // sortedADTRecords.sort((a,b) => new Date(b.effectiveDateTime).getTime() -  new Date(a.effectiveDateTime).getTime());
        // console.log({
        //   adtRecords: patient.adtRecords,
        //   sortedADTRecords,
        // });

        // return sortedADTRecords.adtRecords[0];

        return patient.adtRecords[0];
    }
    getDischargeDate(patient: Patient) {
        // return patient.adtRecords.find(adt => adt.actionCode === 'DD' || adt.actionCode === 'DHOME')?.effectiveDateTime;
        return patient.getDischargeDate()
    }

    getPatientLocation(patient) {
        const adt = patient.adtRecord;
        const pcc_payload = patient.pcc_payload;
        let location = '';

        if (patient?.source == 'PointClickCare') {
            if (pcc_payload && pcc_payload.floorDesc) {
                if (pcc_payload.floorDesc) {
                    location += pcc_payload.floorDesc;
                }
                if (pcc_payload.roomDesc) {
                    location += ' ' + pcc_payload.roomDesc;
                }
                if (pcc_payload.bedDesc) {
                    location += ' - ' + pcc_payload.bedDesc;
                }
            } else if (adt) {
                if (adt.floorDesc) {
                    location += adt.floorDesc;
                }
                if (adt.roomDesc) {
                    location += ' ' + adt.roomDesc;
                }
                if (adt.bedDesc) {
                    location += ' - ' + adt.bedDesc;
                }
            }
        }
        if (patient?.source == 'MatrixCare') {
            // location = patient?.last_room_num;
            if (patient?.last_room_num) {
                if (patient?.matrix_payload?.facility_id_pointOfCare) {
                    location = patient?.matrix_payload?.facility_id_pointOfCare + '-' + (patient?.last_room_num || '');
                } else {
                    patient?.last_room_num;
                }
            }
            else {
                location = patient?.matrix_payload?.facility_id_pointOfCare
            }
        }
        else {
            if (patient?.non_pcc_payload) {
                location = patient?.non_pcc_payload?.room_no || '';
            } else {
                location = patient.last_room_num || '';
            }

        }
        return location || patient.last_room_num;
    }
    getPatientLocationObject(patient) {
        const regex = /(?<floorDesc>.+)\s(?<roomDesc>\d+)\s-\s(?<bedDesc>[A-z0-9])$/m;

        let locationObject = {
            floorDesc: '',
            roomDesc: '',
            bedDesc: '',
            wing: '' // only for matrix
        }
        // let matrixLocationObject = {
        //   // floorDesc: '',
        //   wing: '',
        //   roomDesc: '', 
        //   bedDesc: ''
        // }
        if (patient?.source == 'PointClickCare') {
            if (patient.last_room_num_adt &&
                patient.last_room_num_adt.floorDesc &&
                patient.last_room_num_adt.roomDesc &&
                patient.last_room_num_adt.bedDesc) {
                locationObject = {
                    floorDesc: patient.last_room_num_adt.floorDesc,
                    roomDesc: patient.last_room_num_adt.roomDesc,
                    bedDesc: patient.last_room_num_adt.bedDesc,
                    wing: ''
                };
            } else if (patient.last_room_num) {
                let match;
                if (match = regex.exec(patient.last_room_num.trim())) {
                    locationObject = {
                        floorDesc: match.groups.floorDesc,
                        roomDesc: match.groups.roomDesc,
                        bedDesc: match.groups.bedDesc,
                        wing: ''
                    };
                }
            } else if (
                patient.pcc_payload &&
                patient.pcc_payload.floorDesc &&
                patient.pcc_payload.roomDesc &&
                patient.pcc_payload.bedDesc
            ) {
                locationObject = {
                    floorDesc: patient.pcc_payload.floorDesc,
                    roomDesc: patient.pcc_payload.roomDesc,
                    bedDesc: patient.pcc_payload.bedDesc,
                    wing: ''
                };
            } else if (patient.adtRecord) {
                locationObject = {
                    floorDesc: patient.adtRecord.floorDesc,
                    roomDesc: patient.adtRecord.roomDesc,
                    bedDesc: patient.adtRecord.bedDesc,
                    wing: ''
                };
            }
        }
        else if (patient?.source == 'MatrixCare') {
            if (patient.last_room_num?.includes('/')) {
                locationObject = {
                    floorDesc: '',
                    roomDesc: ((patient.last_room_num?.includes('/') ? patient.last_room_num.split('/') : '-')[0] || patient.last_room_num),
                    bedDesc: ((patient.last_room_num?.includes('/') ? patient.last_room_num.split('/') : '-')[1] || patient.last_room_num),
                    wing: patient.matrix_payload?.facility_id_pointOfCare || '-'
                };
            } else {
                locationObject = {
                    floorDesc: '',
                    roomDesc: (patient.last_room_num || '-'),
                    bedDesc: '',
                    wing: patient.matrix_payload?.facility_id_pointOfCare || '-'
                };
            }
        }
        else if (patient?.non_pcc_payload && patient?.source != 'MatrixCare') {
            let match;
            if (match = regex.exec(patient?.non_pcc_payload?.room_no)) {
                locationObject = {
                    floorDesc: match.groups.floorDesc,
                    roomDesc: match.groups.roomDesc,
                    bedDesc: match.groups.bedDesc,
                    wing: ''
                };
            } else {
                if (patient.last_room_num?.includes('/')) {
                    locationObject = {
                        floorDesc: '',
                        roomDesc: ((patient.last_room_num?.includes('/') ? patient.last_room_num.split('/') : '-')[0] || patient.last_room_num),
                        bedDesc: ((patient.last_room_num?.includes('/') ? patient.last_room_num.split('/') : '-')[1] || patient.last_room_num),
                        wing: '-'
                    };
                } else {
                    locationObject = {
                        floorDesc: '',
                        roomDesc: (patient.last_room_num || '-'),
                        bedDesc: '',
                        wing: '-'
                    };
                }
            }
        }
        return locationObject;
    }
    async handleRemovePatientClick(patientId) {

        let dialogRef;
        let cases = 0;
        if (Array.isArray(this.census.patientsInactive) && this.census.patientsInactive.length > 0 && this.census.patientsInactive.includes(patientId)) {
            cases = 1;
            dialogRef = this.dialog.open(DialogConfirmationComponent, {
                data: {
                    message: this.isActivePatientList ? "Are you sure you want to remove this patient from the census?" : "Are you sure you want to delete this patient from census?"
                }
            });
        } else {
            console.log("this.currentUser : ", this.census_inactive_reason);

            if (this.census_inactive_reason) {
                cases = 2;
                dialogRef = this.dialog.open(DialogWithChoiceComponent, {
                    data: {
                        message: this.isActivePatientList ? "Are you sure you want to remove this patient from the census?" : "Are you sure you want to delete this patient from census?"
                    }
                });
            } else {
                cases = 1;
                dialogRef = this.dialog.open(DialogConfirmationComponent, {
                    data: {
                        message: this.isActivePatientList ? "Are you sure you want to remove this patient from the census?" : "Are you sure you want to delete this patient from census?"
                    }
                });
            }
        }

        let confirmed = "false";
        dialogRef.afterClosed().subscribe(result => {
            if (cases == 1 ? (result === "true") : (result?.result === "true")) {

                const details: any = {
                    id: this.currentUser._id,
                    auth_key: this.currentUser.auth_key,
                    usertype: this.currentUser.user_type,

                    patientRoundingSheetId: this.params.id,
                    patientId
                }
                var notes = this.recentNotes.find(note => note.patient_id_ref === patientId);
                if (notes?.is_signed === "false") {
                    details.noteId = notes._id
                }
                var patient = this.census.patientList.find(patient => patient._id == patientId);
                if (patient.charges?.length > 0) {
                    var charges = []
                    charges = patient.charges?.filter(charge => charge.status == "draft" && charge.is_active == true);
                    if (charges?.length > 0) {
                        details.charges = charges;
                    }
                }
                let isInactive = false;
                let hasPatientInactiveReasonItems = Array.isArray(this.census.patientsInactive) && this.census.patientsInactive.length > 0 && this.census.patientsInactive.includes(patientId);
                if (hasPatientInactiveReasonItems) {
                    isInactive = true;
                    details.isDeletePermanentaly = true;
                }
                else {
                    let today = new Date();
                    let todaydate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                    today.setDate(today.getDate() + 1);
                    let futuredate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                    let reason = result.reason;
                    let filter = {
                        patientid: patientId,
                        censusid: this.census_id,
                        reason: reason,
                        currentDate: {
                            todaydate,
                            futuredate
                        }
                    }

                    // Remove app independent call and pass payload to removePatientFromPatientRoundingSheet
                    details.addPatientinActiveReasonPayload = filter
                    // this.censusPatientListService.addPatientinActiveReason(filter).subscribe((response: any) => {
                    //   if (response.status === 200) {
                    //     if (this.census?.patientList) {
                    //       this.census.patientList.map(data => {

                    //         if (data._id == patientId) {
                    //           delete data?.inactive_reason;

                    //           data["inactive_reason"] = response.data;
                    //         }

                    //       });

                    //     }
                    //   }
                    // });

                    details.isDeletePermanentaly = true;
                }

                this._patientRoungingSheetService.removePatientFromPatientRoundingSheet(details).subscribe((response: any) => {
                    if (response.status === 200) {

                        if (hasPatientInactiveReasonItems) {
                            const addPatientinActiveReasonResponse = response.addPatientinActiveReasonResponse;
                            if (this.census?.patientList) {
                                this.census.patientList.map(data => {

                                    if (data._id == patientId) {
                                        delete data?.inactive_reason;

                                        data["inactive_reason"] = addPatientinActiveReasonResponse;
                                    }

                                });
                            }
                        }


                        if (details.isDeletePermanentaly) {
                            const index = this.census.patientList.findIndex(patient => patient._id === patientId);
                            const i = this.census.patientsInactive.indexOf(patientId);
                            if (index !== -1) {
                                this.census.patientList.splice(index, 1);
                            }
                            if (i !== -1) {
                                this.census.patientsInactive.splice(i, 1);
                            }
                            this.filterTableForActiveOrInactivePatients(true);
                        }
                        else {
                            this.census.patientsInactive.push(patientId);
                            this.filterTableForActiveOrInactivePatients(true);
                        }
                    }
                })
            }
        });

    }

    // showPatientCommentsDialog(patient) {
    //   const dialogRef = this.dialog.open(PatientRoundingSheetCommentsDialog, {
    //     width: 'auto',
    //     data: {
    //       patient,
    //       census: this.census
    //     }
    //   });
    //   dialogRef.beforeClosed().subscribe(async () => {
    //     this.initCensus()
    //   });
    // }

    showCommentSidenav(patient) {
        this.currentPatient = patient;
    }

    showAudioListDialog(patient) {
        const dialogRef = this.dialog.open(AudioListDialog, {
            width: 'auto',
            data: {
                patient,
                census: this.census
            }
        });
        // dialogRef.beforeClosed().subscribe(async () => {
        //   this.initPatientRoundingSheet()
        // });
    }

    getOwnerInfo(owner_id) {
        return this.census.chargesOwnerList.find(u => u._id === owner_id)
    }
    formatProviderDateTag(patient) {
        let str = '';
        const charge = this.hasPatientChargeInCensus(patient);
        if (charge) {
            const provider = this.getOwnerInfo(charge.owner_id);
            if (provider) {
                const { first_name, last_name } = provider;

                str += `${first_name[0]}${last_name[0]}`.toUpperCase();
            }
            if (charge) {
                const visit_date = new Date(charge.visit_date);

                str += ` ${visit_date.getMonth() + 1}/${visit_date.getDate()}`;
            }
        }
        return str;
    }
    chargeStatusClass(patient) {

        let cssClass = '';
        const { patient_charges_count, patient_dictations_count } = patient;

        if (this.isPatientSeenInCensus(patient)) {
            //cssClass = 'darkBlueBg';
        } else if (!patient_charges_count && patient_dictations_count === 0) {
            cssClass = 'darkGrayBg';
        } else if (patient_dictations_count > 0) {
            const dos = moment(patient.patient_dictation_obj?.date_time);
            const censusDate = moment(this.census.date);
            if (dos.isValid() && dos.startOf('day').isSame(censusDate.startOf('day'))) {
                cssClass = 'darkGrayBg'
            } else {
                //cssClass = 'darkBlueBg'
            }
        } else if (!patient_charges_count) {
            let visitDate = moment(patient_charges_count?.visit_date);
            const censusDate = moment(this.census.date);
            if (visitDate.isValid() && visitDate.startOf('day').isSame(censusDate.startOf('day'))) {
                cssClass = 'darkGrayBg';
            } else {
                //cssClass = 'darkBlueBg';
            }
        } else {
            //cssClass = 'darkBlueBg';
        }
        return cssClass;
    }

    getRowClass(patient) {
        // const patientCharge = this.hasPatientChargeInCensus(patient);
        // const patientDictation = this.hasPatientDictationInCensus(patient);

        // if (this.isPatientSeen(patient))
        // {
        //   return 'PRIMARY_DARK'; // OLD Patient
        // }
        // else if (!patientCharge && !patientDictation)
        // {
        //   return 'DARK_GRAY'; // NEW Patient
        // }
        // else if (patientDictation)
        // {
        //   let dos = moment(patientDictation.filterServiceDate);
        //   let censusDate = moment(this.census.date);

        //   if (dos.isValid() && dos.startOf('day').isSame(censusDate.startOf('day')))
        //   {
        //     return 'DARK_GRAY'; // NEW Patient
        //   }
        //   else
        //   {
        //     return 'PRIMARY_DARK'; // OLD Patient
        //   }
        // }
        // else if (patientCharge)
        // {
        //   let visitDate = moment(patientCharge.visit_date);
        //   let censusDate = moment(this.census.date);
        //   if (visitDate.isValid() && visitDate.startOf('day').isSame(censusDate.startOf('day')))
        //   {
        //     return 'DARK_GRAY'; // NEW Patient
        //   }
        //   else
        //   {
        //     return 'PRIMARY_DARK'; // OLD Patient
        //   }
        // }
        // else
        // {
        //   return 'PRIMARY_DARK'; // OLD Patient
        // }

        if (this.censusPatientListService.isPatientFollowUp(this.census, patient)) {
            return 'PRIMARY_DARK'
        } else {
            return 'DARK_GRAY';
        }
    }

    isPrimaryDarkRow(patient) {

    }
    isDarkGrayRow(patient) {
        return
    }
    isPatientSeen(patient) {
        return this.censusPatientListService.isPatientSeen(this.census, patient);
    }
    isPatientSeenInCensus(patient) {
        return this.censusPatientListService.isPatientSeenInCensus(this.census, patient);
    }
    hasPatientChargeInCensus(patient) {
        return this.censusPatientListService.hasPatientChargeInCensus(this.census, patient);
    }
    hasPatientDictationInCensus(patient) {
        return this.censusPatientListService.hasPatientDictationInCensus(this.census, patient);
    }
    markPatientSeen(patient, isSeen) {
        if (isSeen) patient.isSeen = true;
        else if (!isSeen) patient.isSeen = false;
        return;

        if (isSeen) {
            this.census.currentPatientsSeen.push(patient._id);
        } else {
            const index = this.census.currentPatientsSeen.indexOf(patient._id);
            this.census.currentPatientsSeen.splice(index, 1);
        }
        const details = {
            ...this.authService.authObject,
            patientRoundingSheetId: this.census._id,
            patientsSeen: this.census.currentPatientsSeen
        }
        this.patientRoundingSheetService.updatePatientRoundingSheetSeen(details).subscribe((response: any) => {
            // console.log(response);

        })
    }
    hasNoChargeAndDictation(patient) {
        // return patient.patient_charges_count === null && patient.patient_dictations_count === 0
        return patient.charges.length === 0 && patient.audios.length === 0;

    }
    hasChargeStatusEmpty(patient) {
        // if(patient.patient_charges_count) {
        //   return !patient.patient_charges_count.status || patient.patient_charges_count.status === '';
        // }
        // return false;
        const patientCharge = this.hasPatientChargeInCensus(patient)
        if (patientCharge) {
            return patientCharge.status ? false : true;
        }
        return false;
    }
    hasChargeAndStatus(patient, status) {
        const patientCharge = this.hasPatientChargeInCensus(patient)
        if (patientCharge) {
            return patientCharge.status === status;
        }
        return false;
    }
    onRowClick($event, patient_id) {
        if (this.checkVoidCensus()) {
            if ($event.target.classList.value.indexOf('icon') >= 0 || $event.target.tagName.toLowerCase() === 'a') return;
            // localStorage.setItem('patient_id', patient_id);
            localStorage.setItem('hide_census', "true");
            if (window.location.pathname.includes('company')) {
                return this._router.navigate([`/company/${this.currentUser.company_id}/patient-detail/${patient_id}`]);
            } else {
                return this._router.navigate([`patient-detail/${patient_id}`]);
            }
        }
    }

    goBack() {
        // const backUrl = this.goBackUrlService.getBackUrl({
        //   '/patient-charge': '/census'
        // });
        // if (backUrl) {
        //   this._router.navigateByUrl(backUrl);
        // } else {
        if (this.calView == 'true') {
            // this._location.
            // this._location.back();
            this._router.navigateByUrl('/census')
            console.log("getCurrentNavigation : ", this._router);

        } else {

            this._location.back();
            // this._router.navigateByUrl('/census')

        }
        // }
    }
    getPatientsCounts(type) {
        if (!this.census) return 0;
        const { patientList = [] } = this.census;
        switch (type) {
            case 'total':
                return patientList.length
            case 'new':
                {
                    let count = 0;
                    for (const patient of patientList) {
                        if (!this.censusPatientListService.isPatientFollowUp(this.census, patient)) {
                            count++;
                        }
                    }
                    return count;
                }

            case 'followUp':
                {
                    let count = 0;
                    for (const patient of patientList) {
                        // console.log({
                        //   'patient': `${patient.first_name} ${patient.last_name}`,
                        //   isPatientFollowUp: this.censusPatientListService.isPatientFollowUp(this.census, patient)
                        // })
                        if (this.censusPatientListService.isPatientFollowUp(this.census, patient)) {
                            count++;
                        }
                    }
                    return count;
                }
            case 'discharge':
                {
                    let count = 0;
                    for (const patient of patientList) {
                        if (patient.pcc_payload?.dischargeDate) {
                            count++;
                        }
                    }
                    return count;
                }
        }
    }

    togglePatientFromQueueForImport($event, patient) {
        $event.preventDefault();
        $event.stopPropagation();
        const index = this.selectedPatients.findIndex(p => {
            if (Object.prototype.hasOwnProperty.call(p, '_id')) {
                return (p._id === patient._id)
            } else {
                return (p.patientId === patient.patientId)
            }
        });
        if (index >= 0) {
            this.selectedPatients.splice(index, 1);
        } else {
            this.selectedPatients.push(patient);
        }
    }
    selectedPatientForImport(patient) {
        return this.selectedPatients.findIndex(p => {
            if (Object.prototype.hasOwnProperty.call(p, '_id')) {
                return (p._id === patient._id)
            } else {
                return (p.patientId === patient.patientId)
            }
        }) >= 0;

    }

    async importMultiplePatients(patientsWithActiveWounds = [], showSuccessToaster = true, totalPatients) {
        const primaryProviderId = this.currentUser._id;


        if (!primaryProviderId) {
            return this._toastr.warning(
                'Please select primary Provider to import patient.',
                'Warning'
            );
        }
        const importedPatients = [];
        let pccPatients = [];
        let pccPatientIds = [];
        let totalSelectedPatients = 0;

        const selectedFacilityId = this.census.facility._id;
        const pccOrgUuid = this.census.facility.pcc_orgUuid;
        if (patientsWithActiveWounds.length > 0) {
            this.selectedPatients = patientsWithActiveWounds
            pccPatients = this.selectedPatients?.filter((p) =>
                Object.prototype.hasOwnProperty.call(p, "pcc_patientId")
            );
            pccPatientIds = pccPatients.map((p) => p.pcc_coverage_data.patientId)
            totalSelectedPatients = totalPatients;
        } else {
            pccPatients = this.selectedPatients?.filter(p => Object.prototype.hasOwnProperty.call(p, 'patientId'));
            pccPatientIds = pccPatients.map(p => p.patientId)
            totalSelectedPatients = pccPatients.length;
        }
        console.log(pccPatients);
        for (const pccPatientId of pccPatientIds) {
            const infoToast = this._toastr.info(`Importing Patients (${importedPatients.length + 1} of ${totalSelectedPatients})`, 'Please wait', {
                disableTimeOut: true,
            });

            const response: any = await this.pccService
                .importPCCPatient(pccOrgUuid, pccPatientId, primaryProviderId, selectedFacilityId)
                .toPromise()
                .catch((e) => {
                    this._toastr.error(
                        'Patient could not be imported at this time.',
                        'Failed'
                    );
                });

            if (response.status === 200) {
                let relation;
                let patient = Array.isArray(response.data) ? response.data[0] : response.data;
                await this._patientListService.getOnePatient_fromCompFacPatient({ patient_id: patient?._id, company_id: this.currentUser.company_id }).subscribe((res: any) => {
                    relation = res?.data;

                    if (response.message == 'Patient already exists' && relation?.facility_id == this.census.facility._id) {
                        const importedPatient = patient;
                        this.addPatientToCurrrentRoundingSheet(importedPatient, false);
                        importedPatients.push(response.data);
                        this.selectedPatients = [];
                        if (showSuccessToaster) {
                            this._toastr.success(`Patients imported successfully`, 'Success');
                        }
                    } else if (response.message == 'Patient already exists' && relation?.facility_id !== this.census.facility._id) {
                        this._toastr.error(`Patient ${patient.last_name}, ${patient.first_name} already exists in another facility.`, 'Error'); //${response.data[0].facility_id_ref.title}`, 'Error');
                    } else if (response.message !== 'Patient already exists') {
                        const importedPatient = response.data;
                        this.addPatientToCurrrentRoundingSheet(importedPatient, false);
                        importedPatients.push(response.data);
                        this.selectedPatients = [];
                        if (showSuccessToaster) {
                            this._toastr.success(`Patients imported successfully`, 'Success');
                        }
                    }
                    this._toastr.clear(infoToast.toastId);

                })

            } else {
                this._toastr.error(
                    'Patient could not be imported at this time.',
                    'Failed'
                );
            }


        }
    }

    async handleImportPatient(pccPatient, sync) {
        if (this.checkVoidCensus) {
            const checkPatient = await this.isPatientAlreadyExistsInCensus(pccPatient);
            if (checkPatient) {
                const first_name = pccPatient.firstName;
                const last_name = pccPatient.lastName;
                if (checkPatient === 'current') {


                    // return this._toastr.warning(`${last_name}, ${first_name} already exits in current census`);



                } else {
                    if (Array.isArray(checkPatient) && checkPatient.length > 0) {
                        if (checkPatient[0].provider_ids === this.currentUser._id) {
                            // return this._toastr.warning(`${last_name}, ${first_name} already exits in your other census`)
                        } else {
                            // return this._toastr.warning(`${last_name}, ${first_name} already exists in other provder's census`)
                        }
                    }
                }
            }

            this.importAndAddPatientToCurrrentRoundingSheet(pccPatient, sync)
        }
    }

    async handleAddPatient(patient) {
        if (this.checkVoidCensus()) {
            const checkPatient = await this.isPatientAlreadyExistsInCensus(patient);
            if (checkPatient) {
                const patientName = this.commonService.getPatientFullNameFormat2(patient);
                if (checkPatient === 'current') {
                    //return this._toastr.warning(`${patientName} already exits in current census`)
                } else {
                    if (Array.isArray(checkPatient) && checkPatient.length > 0) {
                        if (checkPatient[0].provider_ids === this.currentUser._id) {
                            // return this._toastr.warning(`${patientName} already exits in your other census`)
                        } else {
                            // return this._toastr.warning(`${last_name}, ${first_name} already exists in other provder's census`)
                        }
                    }
                }
            }

            this.addPatientToCurrrentRoundingSheet(patient)
        }
    }

    getLastSeen(patient) {
        let company_id = this.currentUser?.company_id?.toString();
        let user_id = this.currentUser?._id?.toString();
        let self_seen = this.currentUser.other_settings?.census_columns.includes('self'); // Last seen - self
        let other_seen = this.currentUser.other_settings?.census_columns.includes('providers'); // Last seen - other providers in company
        if (window.location.pathname.includes('company')) {
          this.commonService.castCompany.subscribe(company => {
            if (company) {
              company_id = company._id.toString();
            }
          });
        }
    
        let last_seen = '';
        const reversed_last_seen = patient?.last_seen?.slice().reverse();
        if (reversed_last_seen) {
          if (Array.isArray(reversed_last_seen) && reversed_last_seen.length) {
            for (let element of reversed_last_seen) {
              if (element?.company_id?.toString()?.includes(company_id)) {
                if (element?.user_id != undefined) {
                  if (self_seen && element?.user_id?.toString()?.includes(user_id) && !other_seen) {
                    last_seen = element?.last_seen || '';
                    break;
                  } else if (!self_seen && !element?.user_id?.toString()?.includes(user_id) && other_seen) {
                    last_seen = element?.last_seen || '';
                    break;
                  } else {
                    last_seen = element?.last_seen || '';
                    break;
                  }
                } else {
                  last_seen = element?.last_seen || '';
                  break;
                }
              }
            }
          } else {
            last_seen = patient?.last_seen || '';
          }
        }
        return last_seen;
    }

    getLastCPT(patient) {
        let lastCPT = null;
        let patientCharges = patient.charges?.map(obj => {
            return { ...obj, updatedAt: moment(obj.updatedAt) };
        });
        const sortedCharges = patientCharges?.sort(
            (objA, objB) => Number(objB.updatedAt) - Number(objA.updatedAt)
        );
        for (let i = 0; i < sortedCharges?.length; i++) {
            if (sortedCharges[i].cpt_id?.length > 0) {
                lastCPT = sortedCharges[i].cpt_id[sortedCharges[i].cpt_id.length - 1]
                break;
            }
        }
        let result = patient.chargesCPT?.filter(cpt => cpt._id == lastCPT)
        if (result) {
            return result[0]?.code
        } else {
            return ''
        }
    }
    async refreshRelation(provider?) {
        console.log("this.tranFilters.provider : ", provider);
        let provider_id = provider;
        await this.transcriptionCreateService.getRelations(provider_id).subscribe((res: any) => {
            this.isEQAofProvider = false;
            this.isIQAofProvider = false;
            this.isMAofProvider = false;

            if (res.data) {
                let response = res.data?.trans[0];
                let responsema = res.data?.ma[0];

                console.log("response : ", responsema?.assoc_ma_ids, this.authService.currentUser._id);

                if (this.currentUser._id?.toString() == response.qa_id?.toString()) {
                    this.isIQAofProvider = true;
                }
                if (this.currentUser._id?.toString() == response.qa_e_id?.toString()) {
                    this.isEQAofProvider = true;
                }
                if (responsema) {
                    if ((responsema.assoc_ma_ids.findIndex((e) => { return e?.toString() == this.authService.currentUser._id?.toString() })) >= 0) {
                        this.isMAofProvider = true;
                        console.log("user is ma : ", this.isMAofProvider);
                    }
                }
                console.log("1st ", this.isIQAofProvider, this.isEQAofProvider, this.isMAofProvider);
            }

            // if(this._authService.currentUser._id?.toString()==response.qa_id?.toString()){
            //     this.isIQAofProvider=true;
            // }else{
            //     this.isIQAofProvider=false;
            // }
            // if(this._authService.currentUser._id?.toString()==response.qa_e_id?.toString()){
            //     this.isEQAofProvider=true;
            // }else{
            //     this.isEQAofProvider=false;
            // }

            this.setSortOnInit();

        })
    }


    getRecentNoteInCensus(patient) {
        // console.log("patient",patient);
        // this.recentNotes.map(ele => {
        //   if (patient._id == ele.patient_id_ref) {
        //     delete patient.recentNoteInCensus;
        //     patient.recentNoteInCensus = ele;
        //   }
        // });

        // const { recentNoteInCensus } = patient;
        // if (recentNoteInCensus && recentNoteInCensus.rounding_sheet_id === this.census._id) {
        //   
        //   return recentNoteInCensus;
        // }
        // return recentNoteInCensus;
        // return false;
        return this.recentNotes.find(note => note.patient_id_ref === patient._id);
    }
    isOwner(census) {
        return this.currentUser._id == census?.createdBy
    }
    isPccSearchPatientEnabled(census) {
        // if(this.route.snapshot.paramMap.get('showPCCSearchPatient')) {
        //   return true;
        // }
        let isAssociatedMT = this.currentUser.user_type == global.USER_TYPE.TRANSCRIBER;
        let isAssociated_MA = this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT;
        let isAssociated_BO = this.currentUser.user_type === global.USER_TYPE.BUSINESS_OFFICER;
        if (isAssociatedMT || isAssociated_MA || isAssociated_BO) {
            return true;
        }
        return this.isOwner(census);
        // if(census && census.facility && census.facility.pcc_facId) {
        // }
        // return false;
    }

    async reloadCensus() {
        // if(!this.pccService.hasPCCOrgUUID()) {
        //   return this._toastr.warning('You must logged into PCC atleast once')
        // }
        if (this.reloadingCensus) return;

        const pccPatients = this.census.patientList?.filter(p => p.pcc_patientId).map(p => ({
            _id: p._id,
            pcc_patientId: p.pcc_patientId,
            pcc_orgUuid: p.pcc_payload?.orgUuid
        }));
        this.reloadingCensus = true;
        const facilityId = this.census.facility._id;
        this.pccService.syncPatientsData(facilityId, pccPatients).subscribe(async (response: any) => {
            if (response.status === 200) {
                await this.initCensus(true)
            }
        }, () => {
        }, () => this.reloadingCensus = false)
    }

    formatCommentHTML(census_comment) {
        // console.log(census_comment);
        if (!census_comment?.note_detail) return '';
        return census_comment.note_detail.trim().replace(/\n/g, '<br />');
    }

    showAddCharge(element) {
        if (!this.currentUser.charge_status) {
            return false;
        }

        if (this.census && this.isOwner(this.census) && (!this.hasChargeAndStatus(element, 'submit') && !this.hasChargeAndStatus(element, 'draft'))) {
            return true;
        }

        return false;
    }
    setFontSizeForPotrait(value, e) {
        if (e.checked === true) {
            this.defaultPotraitFontSize = value;
            this.currentUser.other_settings.census_potrait_font_size = value;
            this.authService.setOtherUserSettings(this.currentUser.other_settings).subscribe(res => {
                // console.log(res.data);
                if (res.data) {
                    this._toastr.success("PDF potrait default font size changed");
                }
            });
        }
    }

    setFontSizeForLandscape(value, e) {
        // console.log(e);
        if (e.checked === true) {
            this.defaultLandscapeFontSize = value;
            this.currentUser.other_settings.census_landscape_font_size = value;
            this.authService.setOtherUserSettings(this.currentUser.other_settings).subscribe(res => {
                // console.log(res.data);
                if (res.data) {
                    this._toastr.success("PDF landscape default font size changed");
                }
            });
        }
    }
    printCensusPatientList() {
        // console.log( this.currentUser.other_settings?.census_pdf_orientation);
        //  console.log( this.currentUser.other_settings?.census_pdf_fontSize);
        //  console.log(this.currentUser.other_settings?.census_pdf_column);
        //  let user = JSON.parse(localStorage.getItem('currentUserAuth'));
        //  console.log(typeof user);
        //  console.log(user);

        if (this.currentUser.other_settings.census_pdf_orientation &&
            this.currentUser.other_settings.census_pdf_fontSize &&
            this.currentUser.other_settings.census_pdf_column) {
            let censusData = JSON.parse(JSON.stringify(this.census));
            if (this.selectedPatientsToImport.length > 0) {
                censusData.patientList = this.selectedPatientsToImport;
            }
            censusData.patientList.forEach(patient => {
                patient.last_seen.forEach(lastSeen => {
                    if (lastSeen.company_id?.toString().includes(this.currentUser?.company_id?.toString())) {
                        if (lastSeen.user_type && lastSeen.user_type === global.USER_TYPE.WOUND_NURSE) {
                            lastSeen.last_seen = `<strong>(WN)</strong> ${lastSeen.last_seen || ''}`;
                        }
                      }
                });
            });

            try {
                let params: any = {
                    // censusId: this.census._id,
                    census: censusData,
                    orientation: this.currentUser.other_settings.census_pdf_orientation,
                    fontSize: this.currentUser.other_settings.census_pdf_fontSize,
                    column: this.currentUser.other_settings.census_pdf_column
                }
                const infoToastr: any = this._toastr.info('Generating PDF', 'Please wait...');

                return this.dashboardService.downloadFile(global.url + API_URL.PATIENT_ROUNDING_SHEET.generateCensusPatientListAndDownloadAsPDF, params).subscribe((res: any) => {
                    if (res instanceof HttpResponse) {

                        var url = window.URL.createObjectURL(res.body);
                        var a = document.createElement('a');
                        document.body.appendChild(a);
                        a.setAttribute('style', 'display: none');
                        a.href = url;
                        a.download = `Census ${this.census.name} - ${this.census.facility.title}`;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        a.remove(); // remove the element
                        this._toastr.clear(infoToastr.toastId);
                    } else {
                        if (res.type === 0) {
                        } else if (res.type === 3) {
                            const { loaded, total } = res;
                            const progress = Math.round(loaded / total * 100);
                            if (progress > 0) {
                            }
                        }
                    }
                    if (res.status == 200) {
                        return true;
                    }
                })
            }
            catch (error) {
                const { message = 'Something went wrong, please try again' } = error;
                this._toastr.error(message);
            }
        }
        else {
            this._toastr.error("Change PDF download settings in settings page under census tab");
        }
    }

    printCensusWoundPatientList() {

        let censusData = JSON.parse(JSON.stringify(this.census));

        try {
            let params: any = {
                // censusId: this.census._id,
                census: censusData,
                orientation: this.currentUser.other_settings.census_pdf_orientation,
                fontSize: this.currentUser.other_settings.census_pdf_fontSize,
                column: this.currentUser.other_settings.census_pdf_column
            }
            const infoToastr: any = this._toastr.info('Generating Wound PDF File', 'Please wait...');

            return this.dashboardService.downloadFile(global.url + API_URL.PATIENT_ROUNDING_SHEET.generateCensusWoundAndDownloadAsPDF, params).subscribe((res: any) => {
                if (res instanceof HttpResponse) {

                    var url = window.URL.createObjectURL(res.body);
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.setAttribute('style', 'display: none');
                    a.href = url;
                    a.download = `Census Wounds ${this.census.name} - ${this.census.facility.title}`;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove(); // remove the element
                    this._toastr.clear(infoToastr.toastId);
                } else {
                    if (res.type === 0) {
                    } else if (res.type === 3) {
                        const { loaded, total } = res;
                        const progress = Math.round(loaded / total * 100);
                        if (progress > 0) {
                        }
                    }
                }
                if (res.status == 200) {
                    return true;
                }
            })
        }
        catch (error) {
            const { message = 'Something went wrong, please try again' } = error;
            this._toastr.error(message);
        }

    }

    generateCensusPatientListAndDownloadAsPDF() {
        // console.log(font);
        try {
            let params: any = {
                // censusId: this.census._id,
                census: this.census,
            }
            let data;
            let customPdfDialogRef = this.dialog.open(CustomPdfDialogComponent, {
                width: '40%',
                height: '70vh'
            });
            customPdfDialogRef.afterClosed().subscribe(result => {
                // console.log("Dialog Result",result);
                params = { ...params, ...result };
                if (params.fontSize && Object.values(params.column).length > 0 && params.orientation) {
                    // console.log("params",params);
                    const infoToastr: any = this._toastr.info('Generating PDF', 'Please wait...');
                    return this.dashboardService.downloadFile(global.url + API_URL.PATIENT_ROUNDING_SHEET.generateCensusPatientListAndDownloadAsPDF, params).subscribe((res: any) => {
                        if (res instanceof HttpResponse) {

                            var url = window.URL.createObjectURL(res.body);
                            var a = document.createElement('a');
                            document.body.appendChild(a);
                            a.setAttribute('style', 'display: none');
                            a.href = url;
                            a.download = `Census ${this.census.name} - ${this.census.facility.title}`;
                            a.click();
                            window.URL.revokeObjectURL(url);
                            a.remove(); // remove the element
                            this._toastr.clear(infoToastr.toastId);
                        } else {
                            if (res.type === 0) {
                            } else if (res.type === 3) {
                                const { loaded, total } = res;
                                const progress = Math.round(loaded / total * 100);
                                if (progress > 0) {
                                }
                            }
                        }
                        if (res.status == 200) {
                            return true;
                        }
                    })
                }
            });
        } catch (error) {
            const { message = 'Something went wrong, please try again' } = error;
            this._toastr.error(message);
            // } finally {
            //   this._toastr.clear(infoToastr.toastId);
            // }
        }
    }

    onListDrop(event: CdkDragDrop<any[]>) {
        // Swap the elements around
        const patients = this.dataSource.data.map(patient => patient._id);
        const missingPatients = this.census.patientList.map(patient => {
            if (!patients.includes(patient._id)) {
                return patient;
            }
        }).filter(patient => patient);
        this.census.patientList = [...this.dataSource.data, ...missingPatients].map(p => new Patient(p));
        // this.census.patientList = this.dataSource.data;
        moveItemInArray(this.census.patientList, event.previousIndex, event.currentIndex);
        this.initTableDataSource();
        this.censusPatientListService.updatePatientListSortOrder(this.census._id, this.census.patientList.map(p => p._id))
            .subscribe((response: any) => {
                this.filterSort = 'not_seen';
            })
        if (this.isActivePatientList) {
            this.filterTableForActiveOrInactivePatients(true);
        }
        else {
            this.filterTableForActiveOrInactivePatients(false);
        }
    }
    viewRehabDocs(census_id) {
        this.dialog.open(RehabDocsPopupComponent, { data: census_id, width: '80%', height: '100vh' })

    }
    showViewNoteDialog(note) {
        let redirectWithoutPreview = this.currentUser?.other_settings?.preview_note === false;
        let time_zone = 'America/New_York'
        if (this.census && this.census.facility) {
            time_zone = this.census.facility.pcc_timeZone ? this.census.facility.pcc_timeZone : this.census.facility.timeZone
        }
        console.log("in showViewNoteDialo g", note);

        let message = "Note is Ready For Provider Review.";
        if (note.note_status.status == 'internal_qa' || note.note_status.status == 'revert_qae') {
            message = "Note is under QAI Review.";
        } else if (note.note_status.status == 'external_qa' || note.note_status.status == 'revert_provider') {
            message = "Note is under QAE Review.";
        } else if (note.note_status.status == 'in_progress' || note.note_status.status == 'revert_qai') {
            message = "Note is in progress.";
        } else if (note.note_status.status == 'uploaded' || note.note_status.status == 'not_uploaded' || note.note_status.status == 'signed') {
            message = "Note is Signed.";
        }


        let notForProvider = [
            'in_progress',
            'internal_qa',
            'revert_qai',
            'external_qa',
            'revert_qae',
            'revert_provider',
        ];

        let notFortranscriber = [
            'in_progress'
        ];


        let notForIQA = [
            'internal_qa'
        ]

        let notForEQA = [
            'external_qa'

        ]

        console.log("ote.note_status.status : ", note.note_status.status);


        if (this.currentUser.user_type == '2') {

            if (this.isIQAofProvider) {

                if (notForIQA.includes(note.note_status.status?.toString())) {
                    const dialogRef = this.dialog.open(ViewNoteDialog, {
                        width: '80%',
                        //height:'80%',
                        data: {
                            note_id: note._id,
                            void_census: this.checkVoidCensus(),
                            timezone: time_zone
                        }
                    });
                } else {
                    this._toastr.warning('You are not allowed to view note.\n' + message);
                }

            } else if (this.isEQAofProvider) {

                if (notForEQA.includes(note.note_status.status?.toString())) {
                    const dialogRef = this.dialog.open(ViewNoteDialog, {
                        width: '80%',
                        //height:'80%',
                        data: {
                            note_id: note._id,
                            void_census: this.checkVoidCensus(),
                            timezone: time_zone
                        }
                    });
                } else {
                    this._toastr.warning('You are not allowed to view note.\n' + message);
                }

            }
            else {

                if (notFortranscriber.includes(note.note_status.status?.toString())) {
                    const dialogRef = this.dialog.open(ViewNoteDialog, {
                        width: '80%',
                        //height:'80%',
                        data: {
                            note_id: note._id,
                            void_census: this.checkVoidCensus(),
                            timezone: time_zone
                        }
                    });
                } else {
                    this._toastr.warning('You are not allowed to view note.\n' + message);
                }

            }

        }
        else if (this.currentUser.user_type == 'medical_assistant') {
            // if(notFortranscriber.includes(note.note_status.status?.toString())){
            const dialogRef = this.dialog.open(ViewNoteDialog, {
                width: '80%',
                //height:'80%',
                data: {
                    note_id: note._id,
                    void_census: this.checkVoidCensus(),
                    timezone: time_zone
                }
            });
            // }else{
            //   this._toastr.warning('You are not allowed to view note.\n'+message);
            // }
        }
        else {
            let company_id = '';
            if (this._router.url.includes("/company")) {
              company_id = this.route.snapshot.parent.params.id;
            }
            if (!notForProvider.includes(note.note_status.status?.toString()) && this.census.is_void === false) {
                if (redirectWithoutPreview === true) {
                  if (this._router.url.includes("/company"))
                    return this._router.navigate([`company/${company_id ? company_id : this.currentUser.company_id}/note-editor/${note._id}`]);
                  else
                    return this._router.navigate(['/note-editor/', note._id]);
                }
                const dialogRef = this.dialog.open(ViewNoteDialog, {
                  width: '80%',
                  //height:'80%',
                  data: {
                    note_id: note._id,
                    void_census: this.checkVoidCensus(),
                    timezone: time_zone
                  }
                });
              }
              else if (this.census.is_void === true) {
                const dialogRef = this.dialog.open(ViewNoteDialog, {
                  width: '80%',
                  //height:'80%',
                  data: {
                    note_id: note._id,
                    void_census: this.checkVoidCensus(),
                    timezone: time_zone
                  }
                });
              }
              else {
                this._toastr.warning('You are not allowed to view note.\n' + message);
              }

        }



        // dialogRef.beforeClosed().subscribe(async (confirm: any) => {
        // });
    }

    sortData(sort: Sort) {
        const isAsc = sort.direction === 'asc';
        localStorage.setItem('sortFilter', JSON.stringify({ sortColumn: sort.active, sortDirection: sort.direction, prevId: this.census._id.toString() }));

        const oldList = _.clone(this.census.patientList);
        let patientList = _.clone(this.census.patientList);
        if (sort.active === 'location') {
            // if(this.census?.facility?.source=='PointClickCare'||this.census?.facility?.source=='MatrixCare'){
            patientList = this.sortPatientListByRoomNo(patientList, sort.direction);
            // }else{
            // patientList=patientList;
            // }
            if (!patientList.length) return;
        } else
        // if(sort.active === 'last_seen'){

        //         patientList.sort((a: any, b: any) =>{

        //             const lsa= this.getLastSeen(a);
        //             const lsb= this.getLastSeen(b);
        //             const normalizeA=Array.isArray(lsa)? lsa[0] : lsa;
        //             const normalizeB=Array.isArray(lsb)? lsb[0] : lsb;
        //             let dateA=null;
        //             let dateB=null;

        //             if (normalizeA && normalizeB) {
        //               dateA= (normalizeA?.split('-')[1])?.trim();
        //               dateB= (normalizeB?.split('-')[1])?.trim();
        //             }else if (normalizeA) {
        //               dateA= (normalizeA?.split('-')[1])?.trim();
        //             }else if (normalizeB) {
        //               dateB= (normalizeB?.split('-')[1])?.trim();
        //             }

        //           let aValue = (dateA) ? Number(Date.parse(moment(dateA).format('YYYY MM DD'))) : Number(Date.parse(moment(0).format('YYYY MM DD')));
        //           let bValue = (dateB) ? Number(Date.parse(moment(dateB).format('YYYY MM DD'))) : Number(Date.parse(moment(0).format('YYYY MM DD')));
        //             console.log("aValue : ", normalizeA,aValue);
        //             if (isAsc) {
        //                 return aValue - bValue;
        //             }else{
        //                 return bValue - aValue;
        //             }
        //         });
        // }else 
        {
            // if(sort.active === 'location' && this.census.facility.source !== 'MatrixCare') { 
            //   if(this.census.facility.source !== 'MatrixCare'){ 
            //     patientList = this.sortPatientListByRoomNo(patientList, sort.direction);
            //   }
            // }  else {
            patientList.sort((a, b) => {
                let result;
                const adtA = a.adtRecord;
                const adtB = b.adtRecord;

                switch (sort.active) {

                    case 'switch-status':
                        return compare(this.isPatientSeenInCensus(a) ? 1 : -1, this.isPatientSeenInCensus(b) ? 1 : -1, isAsc)

                    case 'name':
                        const aName = this.commonService.getPatientFullNameFormat2(a);
                        const bName = this.commonService.getPatientFullNameFormat2(b);
                        return compare(aName, bName, isAsc);
                    case 'note':
                        const noteA = this.getRecentNoteInCensus(a);
                        const noteB = this.getRecentNoteInCensus(b);
                        if (noteA) {
                            return 1;
                        } else if (noteB) {
                            return -1;
                        } else {
                            return 0;
                        }
                    case 'discharge':
                        try {
                            const dischargeDateA = a.last_discharge_date;
                            const dischargeDateB = b.last_discharge_date;
                            if (dischargeDateA && dischargeDateB) {
                                return compare(new Date(dischargeDateA).getTime(), new Date(dischargeDateB).getTime(), isAsc);
                            }

                        } catch (error) {

                        }
                        return isAsc ? 1 : -1;

                    case 'charge':
                        const patientChargeA = this.hasPatientChargeInCensus(a);
                        const patientChargeB = this.hasPatientChargeInCensus(b);
                        if (patientChargeA) {
                            return 1;
                        } else if (patientChargeB) {
                            return -1;
                        } else {
                            return 0;
                        }
                        return 1;
                    // case 'charge-status':
                    // return 1;
                    case 'date_of_birth':
                        return compare(new Date(a.date_of_birth).getTime(), new Date(b.date_of_birth).getTime(), isAsc);
                    case 'gender':
                        return compare(a.gender, b.gender, isAsc);
                    case 'pcp':
                        return compare(a?.primary_practitioner_first_name, b?.primary_practitioner_first_name, isAsc);
                    //  case 'location':
                    //   { 
                    //     if(this.census.facility.source == 'MatrixCare'){
                    //       return compare(a?.matrix_payload?.facility_id_pointOfCare, b?.matrix_payload?.facility_id_pointOfCare, isAsc); 
                    //       // let sort1 = compare(a?.matrix_payload?.facility_id_pointOfCare, b?.matrix_payload?.facility_id_pointOfCare, isAsc); 
                    //       // sort1.sort((a,b) =>{

                    //       // })
                    //       // return compare(a?.last_room_num, b?.last_room_num, isAsc); 
                    //     }
                    //   }
                    // case 'facility':
                    //   return 1;
                    case 'admission_date':
                        try {
                            const admissionDateA = a.getAdmissionDate();
                            const admissionDateB = b.getAdmissionDate();

                            return compare(new Date(admissionDateA).getTime(), new Date(admissionDateB).getTime(), isAsc);

                        } catch (error) {
                            return -1;
                        }
                    case 'discharge_date':
                        try {
                            const dischargeDateA = a.getDischargeDate();
                            const dischargeDateB = b.getDischargeDate();

                            return compare(new Date(dischargeDateA).getTime(), new Date(dischargeDateB).getTime(), isAsc);

                        } catch (error) {
                            return -1;
                        }
                    case 'payer':
                        try {
                            const payerA = adtA.payerName;
                            const payerB = adtB.payerName;
                            if (payerA && payerB) {
                                return compare(payerA, payerB, isAsc);
                            } else if (payerA) {
                                return 1;
                            } else if (payerB) {
                                return -1;
                            } else {
                                return 0;
                            }
                        } catch (error) {
                            return -1;
                        }
                    case 'comments_count':
                        try {
                            const commentA = adtA.census_comment;
                            const commentB = adtB.census_comment;
                            if (commentA && commentB) {
                                return compare(commentA, commentB, isAsc);
                            } else if (commentA) {
                                return 1;
                            } else if (commentB) {
                                return -1;
                            } else {
                                return 0;
                            }
                        } catch (error) {
                            return -1;
                        }
                    case 'last_seen':
                        try {

                            const lsa = this.getLastSeen(a);
                            const lsb = this.getLastSeen(b);
                            const normalizeA = lsa != '' ? lsa : 'Max-12/12/2099';
                            const normalizeB = lsb != '' ? lsb : 'Max-12/12/2099';

                            if (normalizeA && normalizeB) {

                                let dateA = (normalizeA?.split('-')[1])?.trim();
                                let dateB = (normalizeB?.split('-')[1])?.trim();

                                return compare(new Date(dateA).getTime(), new Date(dateB).getTime(), isAsc);

                            } else if (normalizeA) {
                                return (isAsc ? 1 : -1);
                            } else if (normalizeB) {
                                return (isAsc ? 1 : -1);
                            }
                            else {
                                return 0;
                            }

                        } catch (error) {

                        }
                    case 'last_cpt':
                        return compare(a.lastCPT, b.lastCPT, isAsc);
                }
            });

        }
        // this.dataSource.data = patientList;
        const list = this.getPatientListToupdateBySort(oldList, patientList);

        this.dataSource.data = list;
        const oldPatientListState = JSON.stringify(this.census.patientList);
        const newPatientListState = JSON.stringify(patientList);
        this.census.patientList = patientList.map(p => new Patient(p));
        if (this.isActivePatientList) {
            this.filterTableForActiveOrInactivePatients(true);
        }
        else {
            this.filterTableForActiveOrInactivePatients(false);
        }
        this.filterSort = 'not_seen';
        if (oldPatientListState !== newPatientListState) {
            this.censusPatientListService.updatePatientListSortOrder(this.census._id, list.map(p => p._id)).subscribe()
        }
    }

    sortPatientListByRoomNo(patientList, order = 'asc') {
        const roomNumberTypes = {
            formatted: [],
            numbered: [],
            unformatted: [],
            empty: []
        }
        for (const patient of patientList) {
            const roomNumberObject = this.getPatientLocationObject(patient);
            if (patient.source != 'MatrixCare') {
                if (roomNumberObject.floorDesc && roomNumberObject.roomDesc && roomNumberObject.bedDesc) {
                    roomNumberTypes.formatted.push({
                        patient_id: patient._id,
                        value: roomNumberObject
                    });
                } else {
                    roomNumberTypes.formatted.push({
                        patient_id: patient._id,
                        value: {
                            floorDesc: roomNumberObject.floorDesc || '',
                            roomDesc: roomNumberObject.roomDesc || '',
                            bedDesc: roomNumberObject.bedDesc || '',
                            wing: roomNumberObject.wing || ''
                        }
                    });
                }
            }
            else if (patient.source == 'MatrixCare') {
                roomNumberTypes.formatted.push({
                    patient_id: patient._id,
                    value: roomNumberObject
                });
            }
            // } 
            else {
                const roomNumberString = this.getPatientLocation(patient)
                if (roomNumberString && roomNumberString.length > 0 && roomNumberString.match(/^\d+$/)) {  //Unit 15-2
                    roomNumberTypes.numbered.push({
                        patient_id: patient._id,
                        value: parseInt(roomNumberString)
                    });
                } else if (typeof roomNumberString === 'number') {
                    roomNumberTypes.numbered.push({
                        patient_id: patient._id,
                        value: roomNumberString
                    });
                } else if (typeof roomNumberString === 'string' && roomNumberString.length === 0) {
                    roomNumberTypes.empty.push({
                        patient_id: patient._id,
                        value: roomNumberString
                    });
                } else {
                    roomNumberTypes.unformatted.push({
                        patient_id: patient._id,
                        value: roomNumberString
                    });
                }
            }
        }
        //f0r matrix patients
        if (patientList.length > 0 && patientList[0].source == 'MatrixCare') {
            roomNumberTypes.formatted.sort((a, b) => {
                const roomNoA = a.value;
                const roomNoB = b.value;
                if (order === 'asc') {
                    if (roomNoA.wing.localeCompare(roomNoB.wing) == 0) {
                        const roomAInt = parseInt(roomNoA.roomDesc);
                        const roomBInt = parseInt(roomNoB.roomDesc);
                        if (!isNaN(roomAInt) && !isNaN(roomBInt)) {
                            const roomResult = roomAInt - roomBInt;
                            if (roomResult === 0) {
                                return roomNoA.bedDesc.localeCompare(roomNoB.bedDesc);
                            } else {
                                return roomResult;
                            }
                        } else {
                            if (roomNoA.roomDesc.localeCompare(roomNoB.roomDesc) === 0) {
                                return roomNoA.bedDesc.localeCompare(roomNoB.bedDesc);
                            } else {
                                return roomNoA.roomDesc.localeCompare(roomNoB.roomDesc);
                            }
                        }
                        // if (!isNaN(roomAInt) && !isNaN(roomBInt)) {
                        //   const roomResult = roomAInt - roomBInt;
                        //   if (roomResult === 0) {
                        //     return roomNoA.wing.localeCompare(roomNoB.wing);
                        //   } else {
                        //     return roomResult;
                        //   } 
                        // } 
                        // else { 
                        //     return roomNoA.wing.localeCompare(roomNoB.wing); 
                        // }
                    } else {
                        return roomNoA.wing.localeCompare(roomNoB.wing);
                    }
                }
                else if (order === 'desc') {
                    if (roomNoA.wing.localeCompare(roomNoB.wing) === 0) {
                        const roomAInt = parseInt(roomNoA.roomDesc);
                        const roomBInt = parseInt(roomNoB.roomDesc);

                        if (!isNaN(roomAInt) && !isNaN(roomBInt)) {
                            const roomResult = roomBInt - roomAInt;
                            if (roomResult === 0) {
                                return roomNoB.bedDesc.localeCompare(roomNoA.bedDesc);
                            } else {
                                return roomResult;
                            }
                        } else {
                            if (roomNoA.roomDesc.localeCompare(roomNoB.roomDesc) === 0) {
                                return roomNoB.bedDesc.localeCompare(roomNoA.bedDesc);
                            } else {
                                return roomNoB.roomDesc.localeCompare(roomNoA.roomDesc);
                            }
                        }

                        // if (!isNaN(roomAInt) && !isNaN(roomBInt)) {
                        //   const roomResult = roomBInt - roomAInt;
                        //   if (roomResult === 0) { 
                        //     return roomNoB.wing.localeCompare(roomNoA.wing);
                        //   } else {
                        //     return roomResult;
                        //   } 
                        // } 
                        // else { 
                        //     return roomNoB.wing.localeCompare(roomNoA.wing); 
                        // }
                    } else {
                        return roomNoB.wing.localeCompare(roomNoA.wing);
                    }
                } else {
                    return 0;
                }
            });
        }
        // for non matrix
        else {
            roomNumberTypes.formatted.sort((a, b) => {
                const roomNoA = a.value;
                const roomNoB = b.value;
                if (order === 'asc') {
                    if (roomNoA.floorDesc.localeCompare(roomNoB.floorDesc) === 0) {
                        const roomAInt = parseInt(roomNoA.roomDesc);
                        const roomBInt = parseInt(roomNoB.roomDesc);
                        if (!isNaN(roomAInt) && !isNaN(roomBInt)) {
                            const roomResult = roomAInt - roomBInt;
                            if (roomResult === 0) {
                                return roomNoA.bedDesc.localeCompare(roomNoB.bedDesc);
                            } else {
                                return roomResult;
                            }
                        } else {
                            if (roomNoA.roomDesc.localeCompare(roomNoB.roomDesc) === 0) {
                                return roomNoA.bedDesc.localeCompare(roomNoB.bedDesc);
                            } else {
                                return roomNoA.roomDesc.localeCompare(roomNoB.roomDesc);
                            }
                        }
                    } else {
                        return roomNoA.floorDesc.localeCompare(roomNoB.floorDesc);
                    }

                }
                else if (order === 'desc') {
                    if (roomNoA.floorDesc.localeCompare(roomNoB.floorDesc) === 0) {
                        const roomAInt = parseInt(roomNoA.roomDesc);
                        const roomBInt = parseInt(roomNoB.roomDesc);
                        if (!isNaN(roomAInt) && !isNaN(roomBInt)) {
                            const roomResult = roomBInt - roomAInt;
                            if (roomResult === 0) {
                                return roomNoB.bedDesc.localeCompare(roomNoA.bedDesc);
                            } else {
                                return roomResult;
                            }
                        } else {
                            if (roomNoA.roomDesc.localeCompare(roomNoB.roomDesc) === 0) {
                                return roomNoB.bedDesc.localeCompare(roomNoA.bedDesc);
                            } else {
                                return roomNoB.roomDesc.localeCompare(roomNoA.roomDesc);
                            }
                        }
                    } else {
                        return roomNoB.floorDesc.localeCompare(roomNoA.floorDesc);
                    }
                } else {
                    return 0;
                }
            });
        }
        roomNumberTypes.numbered.sort((a, b) => {
            if (order === 'asc') {
                return a.value - b.value;
            } else if (order === 'desc') {
                return b.value - a.value;
            } else {

            }
        });
        roomNumberTypes.unformatted.sort();

        const roomNumberTypesSorted = [...roomNumberTypes.numbered, ...roomNumberTypes.formatted, ...roomNumberTypes.unformatted, ...roomNumberTypes.empty];
        return roomNumberTypesSorted.map(roomNumberType => patientList.find(patient => patient._id === roomNumberType.patient_id));
    }
    castCensusPaitentSort(sort) {
        if (sort.active == "location" && sort.direction != '')
            this.location_sort_order = sort.direction == "asc" ? "desc" : 'asc'
        this.censusPatientListService.setcensusPatientSort(sort);
    }

    isLoggedIntoPCC() {
        return !!this.pccService.pccUserinfo;
    }
    async refreshScreen() {
        this.currentUser = this.currentUser;
        if (!this.currentUser) {
            // if not login, stop and redirect to login #mohsin-dev
            localStorage.removeItem('currentUser');
            this._router.navigate(['/login']);
            return;
        }
        this.getRecentNoteByCensusId();
        // this.defaultPotraitFontSize = this.currentUser.other_settings?.census_potrait_font_size;
        // this.defaultLandscapeFontSize = this.currentUser.other_settings?.census_landscape_font_size;
        // show operations column only for doctor
        // if(this.currentUser.user_type == 1) {
        this.displayedColumns = ['switch-status', 'name', 'deleteNote', 'note', 'operations', 'comments_count', 'discharge',
            // 'charge',
            //  'charge-status',
            'date_of_birth', 'location', 'admission_date',
            // 'discharge_date', 
            'payer','primary_secondary_payor', 'pcp', 'mrn', 'last_seen', 'last_cpt', 'edit_patient'];
        // remove // 'status',  'source',
        // } else {
        // this.displayedColumns = ['name', 'date_of_birth', 'gender'];
        // }

        this.displayedColumns = ['checkbox', 'switch-status','name', 'deleteNote', 'note', 'operations', 'comments_count', 'discharge',
            'date_of_birth', 'location', 'admission_date',
            //  'discharge_date',
            'payer','primary_secondary_payor', 'pcp', 'mrn', 'last_seen', 'last_cpt', 'edit_patient'];
        // await this.getSkinSubRefferalSettings();
        await this.getColumnsFromSettings();

        var item = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <any>null,
            primary_doctor_id: <string>null
        };
        item.id = this.currentUser._id;
        item.auth_key = this.currentUser.auth_key;
        item.usertype = this.currentUser.user_type;

        await this.initCensus();

        // if(this.isOwner(this.census)){
        //   this.displayedColumns = ['switch-status', 'name', 'note', 'discharge',
        //   // 'charge',
        //   // 'charge-status',
        //    'date_of_birth', 'location', 'admission_date', 'discharge_date', 'payer', 'comments_count', 'last_seen', 'operations'];
        // }
        // else{
        //   this.displayedColumns = ['switch-status', 'name', 'note', 'discharge',
        //   // 'charge',
        //   // 'charge-status',
        //   'date_of_birth', 'location', 'admission_date', 'discharge_date', 'payer', 'comments_count', 'last_seen', 'operations', 'checkbox'];
        // }



        this.censusPatientListService.castFilterSort.subscribe(value => {
            this.filterSort = value;
            this.doSortPatients();
        })
        this.censusPatientListService.castFilterSearch.subscribe(value => {
            this.filterSearch = value;
            this.applyFilter(value);
        })

        this.initPatientsByProvider();
        this.initPccPatients();
        // this.getPatientCommentCount(this.params.id);
        // this.getCensusRecentComment(this.params.id);
        // console.log("cens", this.census);
    }
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
            this.selectedPatientsToImport = []
            return;
        }

        this.selection.select(...this.dataSource.data);
        this.selectedPatientsToImport = [...this.dataSource.data];
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    verifyNoteAccessForUser(note_id, msg) {
        let reponse = {
            status: false,
            message: 'This User Can not delete Note'

        }
        if (!note_id) return reponse;

        let message = "Note is Ready For Provider Review.";

        if (note_id.note_status.status == 'internal_qa' || note_id.note_status.status == 'revert_qae') {
            message = "Note is under QAI Review.";
        } else if (note_id.note_status.status == 'external_qa' || note_id.note_status.status == 'revert_provider') {
            message = "Note is under QAE Review.";
        } else if (note_id.note_status.status == 'in_progress' || note_id.note_status.status == 'revert_qai') {
            message = "Note is in progress.";
        } else if (note_id.note_status.status == 'uploaded' || note_id.note_status.status == 'not_uploaded' || note_id.note_status.status == 'signed') {
            message = "Note is Signed.";
        }


        let notForProvider = [
            'in_progress',
            'internal_qa',
            'revert_qai',
            'external_qa',
            'revert_qae',
            'revert_provider',
        ];

        let notFortranscriber = [
            'in_progress'
        ];


        let notForIQA = [
            'internal_qa'
        ]

        let notForEQA = [
            'external_qa'

        ]

        // console.log("note .stetus : ", note_id.note_status.status);


        if (this.currentUser.user_type == '2') {

            if (this.isIQAofProvider) {

                if (notForIQA.includes(note_id.note_status.status?.toString())) {
                    reponse.message = `${msg} ing note is in progress`;
                    reponse.status = true;

                } else {
                    reponse.message = `You are not allowed to ${msg} note.\n` + message;
                    reponse.status = false;
                }

            } else if (this.isEQAofProvider) {

                if (notForEQA.includes(note_id.note_status.status?.toString())) {
                    reponse.message = `${msg} ing note is in progress`;
                    reponse.status = true;

                } else {
                    reponse.message = `You are not allowed to ${msg} note.\n` + message;
                    reponse.status = false;
                }

            }
            else {

                if (notFortranscriber.includes(note_id.note_status.status?.toString())) {
                    reponse.message = `${msg} ing note is in progress`;
                    reponse.status = true;

                } else {
                    reponse.message = `You are not allowed to ${msg} note.\n` + message;
                    reponse.status = false;
                }

            }

        }
        else if (this.currentUser.user_type == 'medical_assistant') {
            reponse.message = `${msg} ing note is in progress`;
            reponse.status = true;
        }
        else {
            if (!notForProvider.includes(note_id.note_status.status?.toString())) {
                reponse.message = `${msg} ing note is in progress`;
                reponse.status = true;

            } else {
                reponse.message = `You are not allowed to ${msg} note.\n` + message;
                reponse.status = false;
            }

        }

        return reponse;

    }

    handleRemoveCensusNote(note, element) {
        const response = this.verifyNoteAccessForUser(note, 'Delete');
        const note_id = note._id
        if (response.status) {
            let dialogRef = this.dialog.open(DialogConfirmationComponent, {
                data: {
                    message: "Are you sure you want to delete this Progress Note?"
                }
            });
            let confirmed = "false";
            dialogRef.afterClosed().subscribe(result => {
                if (result === "true") {
                    const details = {
                        id: this.currentUser._id,
                        auth_key: this.currentUser.auth_key,
                        usertype: this.currentUser.user_type,
                        note_id: note_id
                    }



                    this._patientRoungingSheetService.removeNoteFromPatientRoundingSheet(details).subscribe((response: any) => {
                        if (response.status === 200) {
                            this._toastr.success('Patient note deleted successfully');
                            const noteIndex = this.recentNotes.findIndex(note => note._id == note_id);
                            if (noteIndex > -1) {
                                this.recentNotes.splice(noteIndex, 1);
                                if (element?.charges && (element?.charges.length > 0)) {
                                    element.charges[0].note_id = null;
                                }
                            }
                            // this.dataSource.data.map(item => {
                            //   if (item.recentNoteInCensus._id === note_id) {
                            //     delete item.recentNoteInCensus
                            //     return
                            //   }
                            // })
                            element.isSeen = response.currentPatientsSeen;

                        }
                        else {
                            this._toastr.error(response.error);
                        }
                    })
                }
            });
        } else {
            this._toastr.warning(response.message);
        }
    }
    async deleteAllPatients() {
        const not_dischargePatientList = this.census.patientList.filter(patient => !patient.last_discharge_date).map(patient => patient._id);
        const dischargePatientList = this.census.patientList.filter(patient => patient.last_discharge_date).map(patient => patient._id);
        if (not_dischargePatientList.length == this.census.patientList.length) {
            this._toastr.info("No discharged patients!");
        } else {
            let dialogRef = this.dialog.open(DialogConfirmationComponent, {
                data: {
                    message: this.isActivePatientList ? "Are you sure you want to inactivate all discharged patients?" : "Are you sure you want to delete all discharged patients?"
                }
            });
            let confirmed = "false";
            dialogRef.afterClosed().subscribe(async result => {
                if (result === "true") {
                    var details: any = {
                        id: <string>null,
                        auth_key: <string>null,
                        usertype: <any>null,
                        census_id: <string>null,
                        patientList: [],
                        patientsInactive: [],
                        shouldDeletePermanentaly: false
                    };
                    details.id = this.currentUser._id;
                    details.auth_key = this.currentUser.auth_key;
                    details.usertype = this.currentUser.user_type;
                    details.census_id = this.census_id;
                    if (this.isActivePatientList) {
                        details.patientsInactive = Array.from(new Set([...this.census.patientsInactive,
                        ...dischargePatientList]));
                    }
                    else {
                        details.shouldDeletePermanentaly = true;
                        details.patientList = not_dischargePatientList;
                        details.patientsInactive = this.census.patientsInactive.filter(patientId => !dischargePatientList.includes(patientId));
                    }

                    await this._patientRoungingSheetService.updatePatientListinRoundingSheet(details).subscribe(async (response: any) => {
                        if (response.status == 200) {
                            if (this.isActivePatientList) {
                                this.census.patientsInactive = Array.from(new Set([...this.census.patientsInactive,
                                ...dischargePatientList]));
                                this.census.patientList = this.census.patientList.filter(patient => !dischargePatientList.includes(patient._id));
                                this.filterTableForActiveOrInactivePatients(true);
                            }
                            else {
                                this.census.patientList = this.census.patientList.filter(patient => !patient.last_discharge_date);
                                this.census.patientsInactive = this.census.patientsInactive.filter(patientId => !dischargePatientList.includes(patientId));
                                this.dataSource.data = this.census.patientList;
                                this.filterTableForActiveOrInactivePatients(false);
                            }
                            this._toastr.success(response.message);
                        } else {
                            this._toastr.error(response.error);
                        }
                    });
                }
            });
        }
    }
    getDate(date) {
        // let dateObj = new Date(date);
        // console.log(dateObj);
        return moment(new Date(date).toLocaleString('en-US')).format('MM/DD/YYYY');
    }
    getAdmissionDate(patient) {
        if (!(patient instanceof Patient)) {
            patient = new Patient(patient);
        }
        const admitionDate = patient.getAdmissionDate()
        return admitionDate;
    }

    addPatientForm() {
        let obj: any = {};
        obj.census_id = this.census._id;
        obj.facility_id = this.census.facility._id;
        obj.createdBy = this.census.createdBy;
        // console.log('obj', obj);

        const dialogRef = this.dialog.open(PatientAddFormDialogComponent, {
            height: '95%',
            width: '55%',
            data: obj
        });
        dialogRef.afterClosed().subscribe(async (data) => {
            console.log('daataaa', data);
            setTimeout(() => {
                if (!data.exists) {
                    this.initCensus();
                } else {
                    console.log('exists true');
                    let dialogReff = this.dialog.open(DialogConfirmationComponent, {
                        data: {
                            message: "This patient already exists, Do you want to add it to census ?"
                        }
                    });
                    dialogReff.afterClosed().subscribe(async result => {
                        if (result === "true") {
                            console.log('result', result);
                            console.log('patient to add', data.patient);
                            this.addPatientToCurrrentRoundingSheet(data.patient);
                        } else {
                            console.log('result', result);
                        }
                    });
                }
            }, 1000);
        });
    }

    openEditPatientDialog(patient, index) {
        let data = { ...patient, censusIdRef: this.census._id };
        this.dialog.open(PatientEditDialogComponent, {
            width: '500px',
            panelClass: 'pcc-email-template-dialog-container-class',
            data
        }).afterClosed().subscribe((patient: any) => {
            if (patient) {
                console.log("patient", patient);
                this.dataSource.data = [];
                if (Array.isArray(patient.non_pcc_payload)) {
                    for (const ele of patient.non_pcc_payload) {
                        if (ele.census_id_ref == this.census._id) {
                            patient.non_pcc_payload = ele;
                        }
                    }
                    this.census.patientList[index].non_pcc_payload = patient.non_pcc_payload;
                }
                this.census.patientList[index].primary_practitioner_last_name = patient.primary_practitioner_last_name;
                this.census.patientList[index].primary_practitioner_first_name = patient.primary_practitioner_first_name;
                this.census.patientList[index].last_admit_date = patient.last_admit_date;
                this.census.patientList[index].last_admit_date_obj = patient.last_admit_date_obj;
                this.census.patientList[index].non_pcc_payerName = patient.non_pcc_payerName;
                this.census.patientList[index].last_room_num = patient.last_room_num;
                if (patient?.source == 'MatrixCare' && this.census.patientList[index].matrix_payload) {
                    this.census.patientList[index].matrix_payload.facility_id_pointOfCare = patient.matrix_payload?.facility_id_pointOfCare;
                }
                this.dataSource.data = this.census.patientList;
                this.filterTableForActiveOrInactivePatients(true);
            }
        });
    }

    handleActivationFilter($event) {
        if (Array.isArray(this.census.patientsInactive)) {
            if ($event === "active") {
                this.isActivePatientList = true;
                this.filterTableForActiveOrInactivePatients(true);
                if (this.displayedColumns.indexOf('checkbox') === -1) {
                    // this.displayedColumns.splice(4, 0, 'operations');
                    this.displayedColumns.splice(0, 0, 'checkbox');
                    this.displayedColumns.splice(1, 0, 'switch-status');
                    this.displayedColumns.splice(this.displayedColumns.length, 0, 'edit_patient');
                }
            }
            else {
                this.isActivePatientList = false;
                this.filterTableForActiveOrInactivePatients(false);
                // this.displayedColumns.splice(this.displayedColumns.indexOf('operations'), 1);

                const i = this.displayedColumns.indexOf('checkbox');
                if (i !== -1)
                    this.displayedColumns.splice(i, 1);
                const j = this.displayedColumns.indexOf('switch-status');
                if (j !== -1)
                    this.displayedColumns.splice(j, 1);
                const k = this.displayedColumns.indexOf('edit_patient');
                if (k !== -1)
                    this.displayedColumns.splice(k, 1);
            }
        }
        else {
            this.census.patientsInactive = [];
        }
    }
    restoreInactivePatient(patientId) {
        this.census.patientsInactive = this.census.patientsInactive.filter(id => id !== patientId);
        const payload = {
            patientRoundingSheetId: this.census._id,
            patientsInactive: this.census.patientsInactive
        };
        this._patientRoungingSheetService.updatePatientRoundingSheetInactive(payload).subscribe((response: any) => {
            if (response.status === 200) {
                // this.census.patientList = response.data.patientList;
                this.filterTableForActiveOrInactivePatients(false);
            }
        });
    }
    filterTableForActiveOrInactivePatients(isActive: boolean) {
        if (isActive) {
            this.dataSource.data = this.census.patientList.filter(patient => !this.census.patientsInactive?.includes(patient._id));
        }
        else {
            this.dataSource.data = this.census.patientList.filter(patient => this.census.patientsInactive?.includes(patient._id));
        }
    }
    checkVoidCensus() {
        if (this.census?.is_void) {
            if (this.census.is_void === true) return false;
            else return true;
        } else {
            return true
        }
    }

    saveNote(note) {
        return new Promise<void>((resolve, reject) => {
            // const dateObj = this.commonService.splitMomentToObj(moment());
            this._noteEditorService.saveNote(note).subscribe((response: any) => {
                if (response.status === 200) {
                    resolve(response.data.noteId);
                }
                else if (response.status === 409) {
                    this._toastr.warning(response.message);
                    resolve(null);
                    this.loader.stopLoader(this.loaderId);
                }
                else {
                    reject();
                    this.loader.stopLoader(this.loaderId);
                }
            });
        });
    }
    createNote(patient_id, facility, rounding_sheet_id, provider) {
        return new Promise<void>((resolve, reject) => {
            // const dateObj = this.commonService.splitMomentToObj(moment());
            let user_type = 'Other'
            if (this.isEQAofProvider) {
                user_type = 'EQA';
            } else if (this.isIQAofProvider) {
                user_type = 'IQA';
            }
            const currentDate = new Date();
            let note_date_obj = {
                day: this.census.date_obj.day,
                month: this.census.date_obj.month,
                year: this.census.date_obj.year,
                hour: currentDate.getHours(),
                minute: currentDate.getMinutes(),
            }
            const note = {
                patient: { _id: patient_id },
                facility: { _id: facility },
                rounding_sheet_id,
                date_obj: note_date_obj,
                htmlText: "No Text",
                title: "No Title",
                dates: { creation: new Date(), service: new Date() },
                provider: { _id: provider },
                uploadedBy: { _id: this.currentUser._id },
                user_type: user_type
            };
            this._noteEditorService.saveNote(note).subscribe((response: any) => {
                if (response.status === 200) {
                    resolve(response.data.noteId);
                }
                else if (response.status === 409) {
                    this._toastr.warning(response.message);
                    resolve(null);
                    this.loader.stopLoader(this.loaderId);
                }
                else {
                    reject();
                    this.loader.stopLoader(this.loaderId);
                }
            });
        })
    }
    // createNote(patient_id, facility, rounding_sheet_id, provider) {
    //   const currentDate = new Date();
    //   const note_date_obj = {
    //     day: this.census.date_obj.day,
    //     month: this.census.date_obj.month,
    //     year: this.census.date_obj.year,
    //     hour: currentDate.getHours(),
    //     minute: currentDate.getMinutes(),
    //   };
    //   const note = {
    //     patient: { _id: patient_id },
    //     facility: { _id: facility },
    //     rounding_sheet_id,
    //     date_obj: note_date_obj,
    //     htmlText: "No Text",
    //     title: "No Title",
    //     dates: { creation: new Date(), service: new Date() },
    //     provider: { _id: provider },
    //     uploadedBy: { _id: this.currentUser._id }
    //   };
    //   return note;
    // }
    showTranscriptionTemplateDialog(note) {
        return new Promise<void>((resolve, reject) => {
            this.dialog.open(TranscriptionTemplateDialog, {
                width: '600px',
                data: {
                    currentUser: this.currentUser,
                    facilityId: this.census.facility._id,
                    patientId: note.patient._id,
                    providerId: this.census.createdBy,
                    note: note
                }
            }).afterClosed().subscribe((data: any) => {
                const template = data.template;
                if (template && template.template_text && template.title) {
                    if (Array.isArray(template.assoc_cpt_ids)) {
                        this._noteEditorService.setnoteEditorTemplateCPTS(template.assoc_cpt_ids);
                    }
                    note.htmlText = template.template_text;
                    note.title = template.title;
                    resolve(note);
                }
                else if (data.blank === true) {
                    resolve(note);
                }
                else {
                    reject();
                }
            });
        });
    }
    CensusCopiedorNot() {
        if (this.census?.is_copied) {
            return this.census?.is_copied;
        } else {
            return false;
        }
    }
    checkPatienMovedorNot(patient) {
        let index = this.census?.movedPatients?.indexOf(patient._id);
        if (index > -1) {
            return true;
        } else {
            return false;
        }
    }
    anotherCheckForMoved(patient, index) {
        if (patient.company_facility) {
            if (patient.company_facility.facility_id?._id?.toString().includes(this.census?.facility?._id?.toString())) {
                this.census?.movedPatients?.splice(index, 1);
                return false;
            } else return true;
        } else {
            return true
        }
    }
    getMovedPatientFacility(patient) {
        if (patient.company_facility) {
            return patient.company_facility.facility_id?.title;
        } else {
            return patient.facility_id_ref?.title;
        }
    }
    deleteMovedPatients() {
        const movedPatientsList = this.census?.movedPatients?.map(patient => patient._id);
        const notMovedPatientsList = this.census.patientList.filter((patient) => !movedPatientsList.includes(patient._id.toString())).map(patient => patient._id);
        if (movedPatientsList?.length <= 0) {
            this._toastr.info("No moved patients!");
        } else {
            let dialogRef = this.dialog.open(DialogConfirmationComponent, {
                data: {
                    message: "Are you sure you want to delete all moved patients?"
                }
            });
            let confirmed = "false";
            dialogRef.afterClosed().subscribe(async result => {
                if (result === "true") {
                    var details: any = {
                        census_id: <string>null,
                        patientList: []
                    };
                    details.census_id = this.census_id;
                    details.patientList = notMovedPatientsList;
                    await this.censusPatientListService.deleteAllMovedPatientsinRoundingSheet(details).subscribe(async (response: any) => {
                        if (response.status == 200) {
                            this.census.patientList = this.census.patientList.filter((patient) => !movedPatientsList.includes(patient._id.toString()));
                            this.census.movedPatients = []
                            this.dataSource.data = this.census.patientList;
                            this._toastr.success(response.message);
                        } else {
                            this._toastr.error(response.error);
                        }
                    });

                }
            });
        }
    }
    changeStyle($event, patient) {
        if (this.checkVoidCensus()) {
            let iconn_badge = document.getElementById(patient._id);
            if (iconn_badge) {
                if ($event.type == 'mouseover') {
                    iconn_badge.style.display = "block";
                } else {
                    iconn_badge.style.display = "none";
                }
            }
        }
    }
    async deleteDraftedCharge(patient) {
        let dialogRef = this.dialog.open(DialogConfirmationComponent, {
            data: {
                message: "Are you sure you want to delete drafted charge?"
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result === "true") {
                const patientCharge = this.hasPatientChargeInCensus(patient);
                if (patientCharge) {
                    let infoToast = this._toastr.info('Deleting Drafted Charge', "Please wait", { disableTimeOut: true });
                    const response: any = await this.censusPatientListService.deleteCharge(patientCharge._id).toPromise();
                    if (response.status) {
                        this._toastr.clear(infoToast.toastId);
                        this._toastr.success("Deleted Drafted Charge", "Success");
                        patient.charges = patient.charges.filter((charge) => charge._id != patientCharge._id)
                        patient.isSeen = response.currentPatientsSeen;
                    }
                }
            }
        });
    }
    showUploadReportToPCC(element) {
        let recentNote = this.getRecentNoteInCensus(element);
        if (recentNote && recentNote.patient.pcc_patientId && recentNote.facility.pcc_facId) return true;
        return false;
    }
    async generateWoundsReportPreview(note, downloadReport = false) {
        let dateAssessment = '';
        if (note.date_obj) {
            let day = (note.date_obj.day < 10) ? ('0' + (note.date_obj.day).toString()) : (note.date_obj.day).toString();
            let month = (note.date_obj.month < 10) ? ('0' + (note.date_obj.month).toString()) : (note.date_obj.month).toString();
            let year = (note.date_obj.year);
            dateAssessment = month + '/' + day + '/' + year;
        }
        let patientObj = {
            date_of_birth: note.patient.date_of_birth,
            first_name: note.patient.first_name,
            gender: note.patient.gender,
            last_name: note.patient.last_name,
            _id: note.patient._id,
            facilityObject: [note.facility],
            timeZone: note.facility.timeZone ? note.facility.timeZone : 'America/New_York'
        }
        let response: any = await lastValueFrom(this.woundCareWidgetService.generateReportForAllWounds(patientObj, "active", note.date_obj));
        if (response.status == 200) {
            if (downloadReport) {
                this.commonService.convertToBlobAndDownload(response.data, `${this.configuration.wound_assessment_report_name}_${dateAssessment}`, "pdf");
            } else {
                const blob = new Blob([Buffer.from(response.data)], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                window.open(url, '_blank');
                window.URL.revokeObjectURL(url);
            }
        } else {
            if (response.status == 500) {
                this._toastr.warning(`No wound found assessed on ${dateAssessment}`, '');
            }
        }
    }
    uploadWoundReportToPCC(note) {
        if (this.hasLoggedInToPCC() || this.authService.canUploadReportToPCCUsingTwoLegged()) {
            const payload = {
                noteId: note._id
            }
            this.woundService.generateWoundReportUploadToPcc(payload)
                .subscribe((response: any) => {
                    if (response.status === 200) {
                        let currentDate = moment(new Date()).format('MM/DD/YYYY hh:mm').toString()
                        this._toastr.success(`Wound Report has uploaded to PCC successfully`);
                        // if (note.is_signed === "true" && note.pcc_progressNoteId) {
                        //   this.drafteNoteState = `Note and Wound Report Uploaded to PCC on ${currentDate}`;
                        // } else {
                        //   this.drafteNoteState = `Wound Report Uploaded to PCC on ${currentDate}`;
                        // }
                    } else {
                        this._toastr.error(response.message, "Failed");
                    }
                });
        } else {
            this._toastr.error("Report was NOT uploaded into PCC. Please sign in to PCC to upload the report.");
        }
    }
    hasLoggedInToPCC() {
        return !!this.pccService?.pccUserinfo;
    }

    addNewTicket(data: any) {
        console.log(data);
        const dialogRef = this.dialog.open(AddTicketsComponent, {
            height: '80%',
            width: '55%',
            data: data
        });
        dialogRef.afterClosed().subscribe(async (data) => {
            console.log('out if data', data);
            if (data) {
                console.log('in if data', data);
            }
        });
    }

    private redirectToScriptSureModule(patientId) {
        const currentUrl = this._router.url;
    
        this._router.navigate(['/patient-scriptsure', patientId], {
          queryParams: {
            from: currentUrl
          }
        });
      }
    
      async openScriptSureModule(patient) {
        patient.isScriptSureLoading = true;
        const response: any = await this.censusPatientListService.checkPatientComplianceWithScriptSure(patient._id);
        patient.isScriptSureLoading = false;
    
        if (response.status !== 200) {
          this._toastr.error("Something Went Wrong")
          return;
        }
    
        if (response.data.isCompliant === false) {
          this._toastr.error(response.data.error, null, {
            positionClass: 'toast-center-center',
            timeOut: 3000,
            closeButton: true
          });
    
          return;
        }
    
        if (response.data.patient.download_drug_history_consent == null) {
          const dialogRef = this.dialog.open(DrugHistoryConsentDialog);
    
          dialogRef.afterClosed().subscribe(async result => {
            if (result && result.saveClick === true) {
              patient.isScriptSureLoading = true;
              const updateResponse: any = await this.censusPatientListService.updatePatientDrugHistoryConsent(patient._id, result.consentGiven);
    
              if (updateResponse.status !== 200) {
                patient.isScriptSureLoading = false;
                this._toastr.error("Something Went Wrong")
                return;
              }
    
              this.redirectToScriptSureModule(patient._id);
            }
          });
    
          return;
        }
    
        this.redirectToScriptSureModule(patient._id);
      }

    watchHelpVideo(videoId) {
        const dialogRef = this.dialog.open(WatchHelpVideoDialog, {
            data: { videoId }
        });

        dialogRef.afterClosed().subscribe(console.log);
    }

    isPermittedFor(permission) {
        return this.currentUser.permissions?.find(perm => (perm.key === permission)) != null;
    }
    async uploadWoundReportsinBulk() {
        const payload = {
            date_obj: this.census.date_obj,
            patientList: this.selectedPatientsToImport.map((p) => p._id),
            timeZone: this.census.facility.pcc_facId ? this.census.facility.pcc_timeZone : this.census.facility.timeZone,
            census_id: this.census._id,
            facility_id: this.census.facility._id
        }
        const response: any = await this.woundService.uploadWoundReportsinBulk(payload).toPromise();
        if (response.status == 200) {
            this._toastr.success(response.message, "Succes");
        } else {
            this._toastr.error("Something went wrong");
        }
    }

    openWoundDialog(element) {
        let note = this.getRecentNoteInCensus(element);
        let patientWound = this.dialog.open(WoundCareWidgetComponent, {
            maxWidth: '100vw',
            maxHeight: '90vh',
            height: '90%',
            width: '98%',
            panelClass: 'full-screen-modal',
            data: { patient: element, provider: this.census.provider, note: note }
        });
    }
    checkWoundReportUploaded(note) {
        if (Array.isArray(note.audit) && note.audit.length > 0) return true;
        else return false;
    }
    async addPatientswithActiveWounds() {
        console.log(this.census);
        const patientIds = this.census.patientList.filter(patient => !this.census.movedPatients.includes(patient._id)).map(patient => patient._id);
        const response: any = await lastValueFrom(this.woundService.getPatientswithActiveWounds(this.census.facility._id, patientIds, this.currentUser.company_id));
        if (response.status === 200) {
            const patientWound = this.dialog.open(PatientsWithActiveWoundsComponent, {
                maxWidth: '100vw',
                maxHeight: '90vh',
                height: '90%',
                width: '98%',
                panelClass: 'full-screen-modal',
                data: { patients: response.data }
            });
            patientWound.afterClosed().subscribe(async data => {
                if (data && data.importPatientsList) {
                    await this.importMultiplePatientswithActiveWound(data.importPatientsList);
                }
            });
        }
    }
    async importMultiplePatientswithActiveWound(patientsList) {
        if (this.patientsImportSource === "dn" || this.patientsImportSource === 'MatrixCare') {
            let importedPatients = 0;
            for await (const patient of patientsList) {
                const infoToast = this._toastr.info(`Importing Patients (${importedPatients + 1} of ${patientsList.length})`, "Please wait",
                    {
                        disableTimeOut: true
                    });
                await this.addPatientToCurrrentRoundingSheet(patient, false);
                importedPatients++;
                this._toastr.clear(infoToast.toastId);
            }
            this._toastr.success("All Patients Added Successfully");
        } else {
            const pccPatients = patientsList?.filter((p) => Object.prototype.hasOwnProperty.call(p, "pcc_patientId"));
            const nonPccPatients = patientsList?.filter((p) => !Object.prototype.hasOwnProperty.call(p, "pcc_patientId"));
            let importedPatients = 0;
            const totalPatients = pccPatients.length + nonPccPatients.length;
            if (pccPatients.length > 0) {
                await this.importMultiplePatients(pccPatients, false, totalPatients);
            }
            importedPatients = pccPatients.length;
            if (nonPccPatients.length > 0) {
                for await (const patient of nonPccPatients) {
                    const infoToast = this._toastr.info(`Importing Patients (${importedPatients + 1} of ${patientsList.length})`, "Please wait",
                        {
                            disableTimeOut: true
                        });
                    await this.addPatientToCurrrentRoundingSheet(patient, false);
                    importedPatients++;
                    this._toastr.clear(infoToast.toastId);
                }
            }
            this._toastr.success("All Patients Added Successfully");

        }
    }

    async handleUploadFacesheetClick(element, index) {
        this.showPatientDocumentsUploadDialog(index, element._id)
    }
    showPatientDocumentsUploadDialog(index, patientId) {
        const dialogRef = this.dialog.open(
            PatientDocumentsUploadDialog,
            { width: '80%' }
        );
        dialogRef.beforeClosed().subscribe(async (fileInput: any) => {
            if (fileInput) {
                const [file] = fileInput.files;
                const infoToast = this._toastr.info('Uploading Face Sheet...', 'Please wait')
                const company_id = this.authService.currentUser.company_id;
                this._faceSheetService.uploadFaceSheet(file, patientId, company_id).subscribe((response: any) => {
                    if (response.status === 200) {
                        const { _id, title, aws_path } = response.data;
                        const faceSheet = {
                            _id,
                            title,
                            aws_path
                        };
                        this.census.patientList
                        this.census.patientList[index].faceSheet = faceSheet
                        this.dataSource = new MatTableDataSource(this.census.patientList);
                        this.dataSource.sort = this.sort;
                        this._toastr.success("Face Sheet Uploaded", 'Success');
                    }
                    else {
                        console.error(response.data.status, response.data.message);
                        this._toastr.error(response.data.message, 'Failed');
                    }
                });
            }
        });
    }
    downloadFaceSheetActive(element) {
        if (!element) return true;
        if (element.source === 'PointClickCare') return false;
        if (element.source === 'MatrixCare') return false;
        if (element.faceSheet && element.faceSheet?.aws_path) return false;
        return true;
    }
    async handleDownloadFacesheetClick(element, index) {
        if (element.source === 'PointClickCare') {
            let hasPCCFacesheetData = false;
            const details = {
                ...this.authService.authObject,
                patientId: element._id,
            }
            const { status, data } = await lastValueFrom(this.pccService.syncCoveragesData({ details })) as any;
            if (status === 200) {
                const _patient = data;
                if ((_patient?.pcc_coverage_data?.Primary || _patient?.pcc_coverage_data?.Secondary || _patient?.pcc_coverage_data?.Tertiary)) {
                    hasPCCFacesheetData = true
                }
            }
            if (hasPCCFacesheetData) {
                this.generateFacesheetAndDownloadAsPDF(element._id);
                return;
            }
        }
        if (element.source === 'MatrixCare') {
            this.generateFacesheetForMatrixAndDownloadAsPDF(element._id);
            return;
        }
        const infoToast = this._toastr.info('Preparing file to download...', 'Please wait', { disableTimeOut: true });
        try {
            const downloadFileResponse: any = await this.fileExplorerService.downLoadAnyFileWithURL(element.faceSheet.aws_path, element.faceSheet.title, element._id).toPromise();
            var url = window.URL.createObjectURL(downloadFileResponse);
            var a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = <string>element.faceSheet.title;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        } catch (error) {
            this._toastr.error('Unable to download file.', 'Failed');
        } finally {
            this._toastr.clear(infoToast.toastId);
            this._toastr.success("Downloaded SuccessFully")
        }
    }
    generateFacesheetAndDownloadAsPDF(patient_id) {
        const infoToastr: any = this._toastr.info('Generating PDF', 'Please wait...');
        try {
            const params = {
                patient_id
            }
            return this.dashboardService.downloadFile(global.url + API_URL.PATIENT.generateFacesheetAndDownloadAsPDF, params).subscribe((res: any) => {
                if (res instanceof HttpResponse) {
                    var url = window.URL.createObjectURL(res.body);
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.setAttribute('style', 'display: none');
                    a.href = url;
                    a.download = 'facesheet.pdf';
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove(); // remove the element
                    this._toastr.clear(infoToastr.toastId);
                } else {
                    if (res.type === 0) {
                    } else if (res.type === 3) {
                        const { loaded, total } = res;
                        const progress = Math.round(loaded / total * 100);
                        if (progress > 0) {
                        }
                    }
                }
                if (res.status == 200) {
                    return true;
                }
            })
        } catch (error) {
            const { message = 'Something went wrong, please try again' } = error;
            this._toastr.error(message);
        } finally {
            this._toastr.clear(infoToastr.toastId);
            this._toastr.success("Downloaded successFully")
        }
    }

    getPatientMipsStatus() {
        let mips = false;
        let medication = false;
        let pccData = {};
        this.isMedications = false;
        if (this.currentUser.other_settings.census_pdf_column?.Medication) {
          medication = true;
          pccData = {
            pcc_facId: this.census.facility.pcc_facId,
            pcc_orgUuid: this.census.facility.pcc_orgUuid
          }
        }
        this.censusPatientListService.getPatientMipsStatus(this.census._id, this.census.patientList, mips, medication, pccData).subscribe((res: any) => {
          if (res.status == 200) {
            for (let patient of res.data) {
              let idx = this.census.patientList.findIndex((p) => p._id.toString() == patient._id.toString());
              if (idx > -1) {
                if (this.currentUser.other_settings.census_pdf_column?.Medication) {
                  this.census.patientList[idx].medications = patient.medications;
                }
                let idx2 = this.currentUser.other_settings?.census_columns.findIndex(column => column == 'mips')
                if (this.currentUser.other_settings.census_pdf_column?.mips || idx2 > -1) {
                  this.census.patientList[idx].mipsStatus = patient.mipsStatus
                }
              }
    
            }
            this.isMedications = true;
          }
          else {
            this.isMedications = true;
          }
        })
      }

    generateFacesheetForMatrixAndDownloadAsPDF(patient_id) {
        const infoToastr: any = this._toastr.info('Generating PDF', 'Please wait...');
        try {
            const params = {
                source: 'MatrixCare',
                patient_id
            }
            return this.dashboardService.downloadFile(global.url + API_URL.PATIENT.generateFacesheetForMatrixAndDownloadAsPDF, params).subscribe((res: any) => {
                if (res instanceof HttpResponse) {
                    var url = window.URL.createObjectURL(res.body);
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.setAttribute('style', 'display: none');
                    a.href = url;
                    a.download = 'facesheet.pdf';
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove(); // remove the element
                    this._toastr.clear(infoToastr.toastId);
                } else {
                    if (res.type === 0) {
                    } else if (res.type === 3) {
                        const { loaded, total } = res;
                        const progress = Math.round(loaded / total * 100);
                        if (progress > 0) {
                        }
                    }
                }
                if (res.status == 200) {
                    return true;
                }
            })
        } catch (error) {
            const { message = 'Something went wrong, please try again' } = error;
            this._toastr.error(message);
        } finally {
            this._toastr.clear(infoToastr.toastId);
            this._toastr.success("Downloaded successFully")
        }
    }

    openDialog(patient, index) {
        if (patient.consentFormAWSPath) this.openPDFViewerDialog(patient.consentFormAWSPath)
        else this.openAggrementDialog(patient, index);
    }

    openPDFViewerDialog(awsPath) {
        this.dialog.open(PdfViewerPopComponent, {
            width: '50vw',
            height: '95vh',
            data: {
                file_path: awsPath
            }
        });
    }

    openAggrementDialog(patient, index): void {
        const options: any = {
            width: '816px',
            height: '90%',
            data: {
                census_id: this.census._id,
                date_obj: this.census.date_obj,
                patient_id: patient._id
            }
        };
        this.dialog.open(AggrementDialogComponent, {
            ...options,
        }).afterClosed().subscribe((data) => {
            if (data) {
                patient.consentFormAWSPath = data.aws_path;
                this.census.patientList[index].consentFormAWSPath = data.aws_path;
            }
        });
    }

    getConsentAudit() {
        const patientIds = this.census.patientList.map(patient => patient._id);
        const filter = {
          patient_id: patientIds,
          is_deleted: false,
          'date_obj.day': this.census.date_obj.day,
          'date_obj.month': this.census.date_obj.month,
          'date_obj.year': this.census.date_obj.year
        };
        const project = {
          patient_id: 1,
          aws_path: 1
        };
        this._consentService.getConsentAudit(filter, project).subscribe({
          next: (response: any) => {
            if (response.status == 200) {
              this.consentAudits = response.data;
              if (this.consentAudits.length > 0) {
                for (const consentAudit of this.consentAudits) {
                  const index = patientIds.indexOf(consentAudit.patient_id);
                  if (index > -1) {
                    this.census.patientList[index].consentFormAWSPath = consentAudit.aws_path;
                  }
                }
                this.dataSource.data = [];
                this.dataSource.data = this.census.patientList;
              }
            }
            else if (response.status === 500) {
              this._toastr.error(response.message, "ERROR in getting Consent Audit");
            }
          }, error: error => {
            this._toastr.error(error, "ERROR in getting Consent Audit");
          }
        });
      }

    async getCompany() {
        const filter = {
            _id: this.currentUser.company_id
        };
        const project = {
            name: 1,
            permissions: 1,
            company_type: 1,
            census_inactive_reason: 1,
            should_show_consent_form: 1,
            view_eligibility_status: 1,
            portal_type: 1
        };
        // const populate = {
        //     permissions: "key value"
        // };
        const response: any = await lastValueFrom(this.companyService.getCompany(filter, project));
        if (response.status === 200) {
            this.company = response.data;
            // [this.view_eligibility_status, this.should_show_consent_form, this.should_show_ipa] = this.companyService.validateCompanyPermission(response.data.permissions,
            //     ["view_eligibility_status", "should_show_consent_form", "should_show_ipa"]);
            this.census_inactive_reason = response.data.census_inactive_reason;
            this.should_show_consent_form = response.data.should_show_consent_form;
            this.view_eligibility_status = response.data.view_eligibility_status;

        }
    }


}


function compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    if (typeof a === 'string' && typeof b === 'string') {
        return isAsc ? a.localeCompare(b) : b.localeCompare(a);
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

function compareDate(a: number | string, b: number | string, isAsc: boolean) {
    isAsc = false;
    const dateA = typeof a === 'string' ? new Date(a) : new Date(a);
    const dateB = typeof b === 'string' ? new Date(b) : new Date(b);
    console.log('dateA', dateA);
    console.log('dateB', dateB);
    if (dateA < dateB) {
        return isAsc ? -1 : 1;
    } else if (dateA > dateB) {
        return isAsc ? 1 : -1;
    } else {
        return 0;
    }
}

