import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment'
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-mip-report-audit-popup',
  templateUrl: './mip-report-audit-popup.component.html',
  styleUrls: ['./mip-report-audit-popup.component.css']
})
export class MipReportAuditPopupComponent implements OnInit {

  haveErrors = false;
  constructor(
    private _route: ActivatedRoute,
    public _authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

   }

  ngOnInit(): void {
    if(this.data && this.data.error_string?.length > 0){
      this.haveErrors = true;
    }
    console.log('data--------------------------', this.data)
  }
  getDate(){
    if(this.data.updatedAt){
      let dd =  `${moment(this.data.updatedAt).format('MM/DD/yyyy h:mm a')}`
      return dd
    }
    else 
    return ''
  }

}
