<div class="container">
    <div style="display: flex; place-content: space-between; align-items: center;margin-bottom: 6px;">
        <div style="display: flex; white-space: nowrap;">
            <mat-radio-group class="light flex flex-row" [(ngModel)]="activatedRoute" (ngModelChange)="changeRoute()">
                <mat-radio-button value="notes">Notes</mat-radio-button>
                <mat-radio-button *ngIf="company_id" value="census-notes">Census</mat-radio-button>
                <mat-radio-button
                    *ngIf="company_id && (isWoundCompany || selectedCompany.company_type === global.COMPANY_TYPE.SNF)"
                    value="census-wound-notes">Wound
                    Notes</mat-radio-button>
                <mat-radio-button
                    *ngIf="company_id && selectedCompany.company_type != global.COMPANY_TYPE.SNF && currentUser?.additional_user_role != global.USER_TYPE.CNP"
                    value="admin-charges">Charge Capture</mat-radio-button>
                <mat-radio-button
                    *ngIf="company_id && isWoundCompany && selectedCompany.company_type != global.COMPANY_TYPE.SNF && currentUser?.additional_user_role != global.USER_TYPE.CNP"
                    value="charge-duration-report">Charge Delay</mat-radio-button>
                <mat-radio-button
                    *ngIf="(selectedCompany.company_type != 'Wound' && selectedCompany.company_type != global.COMPANY_TYPE.SNF)"
                    value="dictations">Dictations</mat-radio-button>
                <mat-radio-button *ngIf="company_id" value="wound-reports">
                    {{ isWoundCompany ? 'Wound Reports' : 'Reports' }}
                </mat-radio-button>
                <mat-radio-button *ngIf="currentUser.company_type !== global.COMPANY_TYPE.NURSING"
                    value="wound-enterprise-reports">Wound Enterprise Reports</mat-radio-button>
                <mat-radio-button *ngIf="company_id" value="wound-reports-v2">Reports-V2</mat-radio-button>
                <mat-radio-button value="note-aging-report">Note Aging Report</mat-radio-button>
                <mat-radio-button *ngIf="selectedCompany.portal_type === 'non_wc'" value="consent-form" >Consent</mat-radio-button>
                <mat-radio-button *ngIf="selectedCompany.portal_type == 'non_wc'" value="dns-logs">DNS Audit</mat-radio-button>
            </mat-radio-group>
            <br>
        </div>
        <span *ngIf="!this.currentUser.product_owner"
            style="background: white; color: black;border-radius: 10px;padding: 10px;">
            {{selectedCompany.name}}
        </span>
        <div *ngIf="this.currentUser.product_owner">
            <mat-form-field style="color: black !important;background: white;border-radius: 6px; height: 60px;
    padding-left: 10px; padding-right: 10px; margin-right: 4px;" class="flex-grow">

                <mat-select [value]="currentCompany">
                    <input placeholder="Search" [(ngModel)]="searchCompanyText"
                        [ngModelOptions]="{standalone: true}" style="color:#000;height: 40px;padding-left: 5px; border: none; width: 100%;" />
                    <mat-option *ngFor="let company of companies | searchCompany : searchCompanyText"
                        [value]="company._id" (onSelectionChange)="navigate($event, company._id)">
                        {{company.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </div>
    <ng-container *ngIf="isDataLoaded">
        <router-outlet></router-outlet>
    </ng-container>
</div>