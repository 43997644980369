import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PhraseEditorDialogSystemComponent } from '../phrases/phrase-editor-dialog-system/phrase-editor-dialog-system.component';
import { DictionaryEditorDialogComponent } from '../phrases/phrase-editor-dialog/phrase-editor-dialog.component';
import { PhrasesService } from '../phrases/phrases.service';
import { ActivatedRoute } from '@angular/router';
import { CompanyUserProfileComponent } from 'src/app/components/company';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { ProviderPhraseAssociationService } from '../phrases/phrases-association/phrases-association.service';
import { SystemphraseeditorComponent } from '../phrases/system-phrase-editor/systemphraseeditor/systemphraseeditor.component';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'src/app/services/Modules/user.service';
import { USER_TYPE } from '../global';
import { DrivePhraseService } from '../drive-phrases/drive-phrase.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AddUserPhraseToProviderComponent } from './add-user-phrase-to-provider/add-user-phrase-to-provider.component'
import { DialogConfirmationComponent } from '../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { DashboardService } from '../dashboard/dashboard.service';
import { CommonService } from 'src/app/services/common.service';
import * as global from '../../includes/global';
import { lastValueFrom } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';


export type DictionaryElement = {
  _id?: string,
  key: string,
  value: string,
  select?: boolean
}

@Component({
  selector: 'app-phrase-component',
  templateUrl: './phrase-component.html',
  styleUrls: ['./phrase-component.css']
})
export class PhraseComponent implements OnInit {
  dictionary: Array<DictionaryElement>;
  allCurrentUserPhrases: Array<DictionaryElement>;
  newDictionaryElement: DictionaryElement;
  searchText: String;
  isNew: boolean = false;
  selectedIndex = 0;
  // displayedColumnsSystemPhrases: string[] = ['sr','shortcut','provider', 'operations'];
  displayedColumnsSystemPhrases: string[] = ['sr', 'shortcut', 'operations'];
  dataSourceSystemPhrases: any;
  displayedColumnsUserPhrases: string[] = ['select', 'shortcut', 'operations'];
  dataSourceUserPhrases: any;
  displayedColumns: string[] = ['providers', 'phrases'];
  public dataSource = [];
  public relationData: any;
  phrasesDropdownSettings = {};
  company_id: any;
  addPhraseKey = '';
  all_selected: any;
  searchValue: string = "";

  selectedItems = [];
  dropdownList = [];

  phrases: any = [];
  providerDropdownSettings: any;
  providers: any = [];
  params: any;
  selectedProvider: any = {};

  // **************
  masterSelected: boolean;
  checklist: any;
  checkedList: any;
  showButton = false;
  phraseArray: any = [];
  // **************

  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalPhrases: 0,
    totalPages: 0,
    currentRecords: 0
  };
  PAGE_LIMIT = 10;
  currentUser: any;
  currentUserdictionary: any = [];
  global = global;
  company_type: any = 'Wound';
  loaderId = 'phrase-loader';
  phrases_user_id: any;
  company: any;

  constructor(private dialog: MatDialog,
    private phrasesService: PhrasesService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private authService: AuthService,
    private phraseAssociateService: ProviderPhraseAssociationService,
    private userService: UserService,
    private drivePhraseService: DrivePhraseService,
    private _authService: AuthService,
    private dashboardService: DashboardService,
    private commonService: CommonService,
    private loader: NgxUiLoaderService,



  ) {
    this.providerDropdownSettings = {

      singleSelection: false,
      idField: '_id',
      textField: 'full_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: false,
    };
    this.currentUser = this._authService.currentUser;
    // ******************
    this.masterSelected = false;
    // ******************
  }

  async ngOnInit() {
    const currentCompany = this.commonService.getLocalStorageItem('admin-Dashboard');
    if(currentCompany) {
        this.company_type = currentCompany.currentCompanyType;
        this.company = currentCompany;
    }
    await this.getProviders();
    // this.phrasesService.getNoteBuilderDictionary().subscribe();

    // this.phrasesService.castUserDefinedPhrases.subscribe((userdefinedphrases) => {
    //   this.dictionary = userdefinedphrases;
    //   this.dataSourceUserPhrases = new MatTableDataSource(this.dictionary);
    // });



    await this.getCurrentUserPhrases(this.currentUser._id);
    await this.getNoteBuilderDictionaryAdmin(this.selectedProvider._id);


    if (this.dictionary?.length > 1) {
      this.updatePageCountForFirstPage();
    }

    if (window.location.pathname.includes('company')) {
      this.company_id = this.route.parent.snapshot.params.id;
    }
    else {
      this.company_id = '';
    }


  }

  async getCurrentUserPhrases(id) {
    this.drivePhraseService.getNoteBuilderDictionary(id).subscribe((response: any) => {
      if (response.status === 200 && response.data) {
        this.allCurrentUserPhrases = [];
        this.allCurrentUserPhrases = response.data[0].dictionary;
      }else {
        console.log('something went wrong');
      }
    });
  }

  updatePageCountForFirstPage() {
    if (this.pagination.currentRecords < this.pagination.totalPhrases) {
      this.pagination.hasNextPage = true;
      const length = this.dictionary?.length || 0;
      this.pagination.currentRecords = (this.pagination.pageNo - 1) * this.PAGE_LIMIT + length;
    }else if (this.pagination.currentRecords > this.pagination.totalPhrases) {
      this.pagination.currentRecords = this.pagination.totalPhrases;
      this.pagination.hasNextPage = false;
    }
    if(this.pagination.pageNo > 1) {
      this.pagination.hasPrevPage = true;
    } else {
      this.pagination.hasPrevPage = false;
    }
  }


  async getProviders() {
    // const company_id = this.params.id;
    const filter = {
      is_activated: 'true',
      user_type: this.company_type === global.COMPANY_TYPE.SNF ? USER_TYPE.SNF_WC_NURSE : USER_TYPE.DOCTOR,
      company_id: this.route.parent.snapshot.params.id
    }
    const projection = {
      'account_type': 1,
      'first_name': 1,
      'last_name': 1,
      'title': 1,
    }

    const companyUsersResponse: any = await this.userService.getUsers(filter, projection).toPromise();
    if (companyUsersResponse.status === 200) {
      let providerss = companyUsersResponse.data.map(p => Object.assign(p, {
        full_name: p.account_type === 'company' ?
          `${p.first_name} ${p.last_name}, ${p.title} (company owner)` :
          `${p.first_name} ${p.last_name}, ${p.title}`
      }));
      this.providers = providerss.sort((a, b) => a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase()));
      this.selectedProvider = this.providers[0];
    }

    this.getSystemPhrases();
  }
  getData(element) {

  }

  onProviderClick($event, provider) {
    if ($event.isUserInput) {
      this.pagination = {
        pageNo: 1,
        hasNextPage: false,
        hasPrevPage: false,
        totalPhrases: 0,
        totalPages: 0,
        currentRecords: 0
      };
      this.selectedProvider = provider;
      console.log("provider", provider);
      // this.pagination.pageNo = 1;
      this.getNoteBuilderDictionaryAdmin(provider._id);
      this.masterSelected = false;
      this.showButton = false;
    }
  }
  async getNoteBuilderDictionaryAdmin(id) {
    const filter: any = {
      pageNo: this.pagination.pageNo
    }
    this.drivePhraseService.getNoteBuilderDictionaryAdmin(id, filter).subscribe((response: any) => {
      if (response.status == 200) {
        this.pagination.totalPhrases = response.data?.totalPhrases;
        this.dictionary = response.data?.phrases.dictionary;
        if (this.currentUser._id === id) {
          this.currentUserdictionary = response.data?.phrases.dictionary;
        }
        this.phrases_user_id = response.data?.phrases.user_id;
      } else if (!response.data.dictionary) {
        this.dictionary = [];
        this.pagination.currentRecords = 0;
        this.pagination.totalPhrases = response.data.totalPhrases;
        this.pagination.hasNextPage = false;
        this.pagination.hasPrevPage = false;
        this.phrases_user_id = "";
      }
      if (filter.pageNo) {
        this.updatePageCountForFirstPage();
      }
      if (this.dictionary?.length > 0) {
        this.sortPhrases(this.dictionary);
      }

      this.dataSourceUserPhrases = new MatTableDataSource(this.dictionary);
          });
  }

  async goToNextPage() {
    this.pagination.pageNo = this.pagination.pageNo + 1;
    this.pagination.currentRecords = (this.pagination.pageNo - 1) * this.PAGE_LIMIT + this.dictionary.length;
    if (this.pagination.currentRecords > this.pagination.totalPhrases) {  //Page count should not exceed total limit
      this.pagination.currentRecords = this.pagination.currentRecords - (this.pagination.currentRecords - this.pagination.totalPhrases);
    }
    if (this.pagination.currentRecords === this.pagination.totalPhrases) {
      this.pagination.hasNextPage = false;
    }
    await this.getNoteBuilderDictionaryAdmin(this.selectedProvider._id);
    this.pagination.hasPrevPage = true;
  }

  async goToPreviousPage() {
    if (this.pagination.pageNo > 1) {
      this.pagination.pageNo = this.pagination.pageNo - 1;
      this.pagination.currentRecords = (this.pagination.pageNo - 1) * this.PAGE_LIMIT + this.dictionary.length;
      await this.getNoteBuilderDictionaryAdmin(this.selectedProvider._id);
    }
    if (this.pagination.pageNo === 1) {
      this.pagination.hasPrevPage = false;
    }
    this.pagination.hasNextPage = true;
  }

  //Old code with key and value pair GET()//

  // getSystemPhrases() {
  //   this.phrasesService.getPhrases(this.company_id).subscribe((data: any) => {
  //     this.phrases = data.data;
  //     this.sortPhrases(this.phrases);
  //     this.dataSourceSystemPhrases = new MatTableDataSource(this.phrases);
  //   });

  // }

  clickTab() {
    if (this.selectedIndex == 0) {
      this.showDictionaryEditorDialog();
    } else if (this.selectedIndex == 1) {
      this.addSystemPhrase();
    }
  }

  addDictionaryElement() {
    this.newDictionaryElement = {
      key: '',
      value: '',
    }
    this.isNew = true;
  }

  addToProvider() {
    if (this.phraseArray.length) {
      let obj = {
        providers: JSON.parse(JSON.stringify(this.providers)),
        phrase: JSON.parse(JSON.stringify(this.phraseArray)),
        selectedProviderId: this.selectedProvider._id
      }
      const dialogRef = this.dialog.open(AddUserPhraseToProviderComponent, {
        width: '800px',
        height: '50vh',
        data: obj,
      });
    } else {
      this.toastr.info('Please select atleast one phrase', 'Info')
    }
  }

  showDictionaryEditorDialog(dictionaryElement?) {
    console.log('dictionary', this.dictionary);
    let obj: any = {
      width: '800px',
    }
    if (dictionaryElement) {
      obj.data = JSON.parse(JSON.stringify(dictionaryElement));
    }
    else {
      obj.data = this.allCurrentUserPhrases;
    }
    const dialogRef = this.dialog.open(DictionaryEditorDialogComponent, obj);
    dialogRef.afterClosed().subscribe(async (_dictionaryElement) => {
      if (_dictionaryElement) {
        if (dictionaryElement) {
          this.updateNoteBuilderDictionary(_dictionaryElement);
        } else {
          this.saveDictionaryElement(_dictionaryElement);
        }
      }
    });
  }

  updateNoteBuilderDictionary(newDictionaryElement) {
    if (this.company_id) {
      newDictionaryElement.providerId = this.selectedProvider._id;
    }
    this.phrasesService.updateNoteBuilderDictionary(newDictionaryElement).subscribe((response: any) => {

      const dElementToUpdate = this.dictionary.find(dElement => dElement._id === newDictionaryElement._id);
      if(dElementToUpdate) {
        dElementToUpdate.value = newDictionaryElement.value;
      }

      if (response.status === 200) {
        this.toastr.success('Successfully Updated','Success')
        this.getNoteBuilderDictionaryAdmin(this.selectedProvider._id)
        this.isNew = false;
      }
    })
  }

  async saveDictionaryElement(newDictionaryElement) {
    this.phrasesService.saveNoteBuilderDictionary(newDictionaryElement).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.success('Successfully Added','Success')
        this.getNoteBuilderDictionaryAdmin(this.selectedProvider._id)
        this.isNew = false;
      }
    })
  }

  async deleteFromNoteBuilderDictionary(dictionaryElement) {
    const message = 'Are you sure you want to delete this Note?'
    const dialogBoxValue = await this.dashboardService.DeleteConfirmationDialog(message);
      if (dialogBoxValue === "true") {
        this.drivePhraseService.deleteFromNoteBuilderDictionary(dictionaryElement, this.selectedProvider?._id).subscribe(async (response: any) => {
          if (response.status === 200) {
            // TODO: bookmark
            this.dataSourceUserPhrases.data = this.dataSourceUserPhrases.data.filter(ele => dictionaryElement._id !== ele._id);
            // this.dictionary = response.data.dictionary;
            this.isNew = false;
            if (this.currentUser._id === this.selectedProvider._id) {
              await this.getNoteBuilderDictionaryAdmin(this.currentUser._id);
            }
          }
        });
      }else{
        () => this.toastr.error('Something went wrong while removing Notes', 'Failed')
      }
  }

  getSystemPhrases() {
    this.phrasesService.getPhrases().subscribe((data: any) => {
      this.phrases = data.data;
      this.sortPhrases(this.phrases);
      this.dataSourceSystemPhrases = new MatTableDataSource(this.phrases);
    });

  }

  addSystemPhrase(addPhraseKey?) {
    const dialogRef = this.dialog.open(PhraseEditorDialogSystemComponent, {
      width: '800px',
      data: addPhraseKey
    });
    dialogRef.afterClosed().subscribe(async (_addPhraseKey) => {
      console.log("phrase", _addPhraseKey);
      if (_addPhraseKey) {
        this.phrasesService.addPhrase(_addPhraseKey).subscribe((response: any) => {
          if (response.status == 200) {
            this.toastr.success("Phrase Added");
            this.getSystemPhrases();
          }
        });
      } else {
        console.log("phrase error");
      }

    });
  }

  editSystemPhrase(key, _id) {

    const dialogRef = this.dialog.open(SystemphraseeditorComponent, {
      width: '800px',
      data: key,
    });
    dialogRef.afterClosed().subscribe(async (key) => {
      if (key) {
        this.phrasesService.updatePhrase(key, _id).subscribe((response: any) => {
          if (response.status == 200) {
            this.phrases = this.phrases.map((phrase) => {
              if (phrase._id == response.data._id) {
                return response.data;
              }
              return phrase;
            });
            this.toastr.success("Phrase updated!");
            this.getSystemPhrases();

          }
        });
      } else {
        // console.log('something went wrong!');
        // this.toastr.error("Something went wrong");
      }

    });
  }

  async deleteSystemPhrase(_id) {
    const message = 'Are you sure you want to delete this phrase?'
    const dialogBoxValue = await this.dashboardService.DeleteConfirmationDialog(message);
      if (dialogBoxValue === "true") {
        this.phrasesService.deletePhrase(_id).subscribe((response: any) => {
          if (response.status == 200) {
            this.toastr.success('Phrase Deleted');
            this.getSystemPhrases();
          }
        });
      }else{
        () => this.toastr.error('Something went wrong while removing phrase', 'Failed')
      }
  }



  //Old code with key and value pair ADD()//

  // addSystemPhrase(addPhraseKey?) {
  //   const dialogRef = this.dialog.open(PhraseEditorDialogSystemComponent, {
  //     width: '800px',
  //     data: addPhraseKey
  //   });
  //   dialogRef.afterClosed().subscribe(async (_addPhraseKey) => {
  //     console.log("phrase", _addPhraseKey);
  //     if (_addPhraseKey.key && _addPhraseKey.value) {
  //       this.phrasesService.addPhrase(_addPhraseKey, this.company_id).subscribe((response: any) => {
  //         if (response.status == 200) {
  //           this.toastr.success("Phrase Added");
  //           console.log('response', response);
  //           this.getSystemPhrases();
  //         }
  //       });
  //     } else {
  //       console.log("phrase error");
  //     }

  //   });
  // }

  //Old code with key and value pair EDIT()//

  // editSystemPhrase(key, value, _id) {
  //   let obj = {
  //     key: key,
  //     value: value
  //   }
  //   const dialogRef = this.dialog.open(SystemphraseeditorComponent, {
  //     width: '800px',
  //     data: obj,
  //   });
  //   dialogRef.afterClosed().subscribe(async (key) => {
  //     if (key.key && key.value) {
  //       this.phrasesService.updatePhrase(key, _id).subscribe((response: any) => {
  //         if (response.status == 200) {
  //           console.log(response);
  //           this.phrases = this.phrases.map((phrase) => {
  //             if (phrase._id == response.data._id) {
  //               return response.data;
  //             }
  //             return phrase;
  //           });
  //           this.toastr.success("Phrase updated!");
  //           console.log('response', response);
  //           this.getSystemPhrases();

  //         }
  //       });
  //     } else {
  //       // console.log('something went wrong!');
  //       // this.toastr.error("Something went wrong");
  //     }

  //   });
  // }

  //Old code with key and value pair DELETE()//

  // deleteSystemPhrase(key) {
  //   let dialogRef = this.dialog.open(DialogConfirmationComponent, {
  //     data: {
  //       message: "Are you sure you want to delete this phrase?"
  //     }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result === "true") {
  //       this.phrasesService.deletePhrase(key).subscribe((response: any) => {
  //         if (response.status == 200) {
  //           console.log(response);
  //           this.toastr.success('Phrase Deleted');
  //           this.getSystemPhrases();
  //         }
  //       });
  //     }
  //   });
  // }

  getMarker() {
    return this.phrasesService.marker
  }

  // *******************
  // Check All Checkbox Checked
  onchange(e, $event) {

    if ($event.checked) {
      this.phraseArray.push(e);
    } else if (!$event.checked) {
      const index = this.phraseArray.indexOf(e)
      if (index > -1) {
        this.phraseArray.splice(index, 1);
      }
    }
    if (this.phraseArray.length > 0) {
      this.showButton = true;
    } else {
      this.showButton = false;
    }
    const id = e._id;
    const isChecked = $event.checked;
    // if (isChecked) {
    //   this.showButton = true;
    // } else if(isChecked == false){
    //   this.phraseArray.forEach(element => {
    //     if(element.select == true){
    //       this.showButton = true;
    //     }else if(element.select == false){
    //       this.showButton = false;
    //     }
    //   });
    //   this.showButton = false;
    // }

    this.dictionary.map((d) => {
      if (id === d._id) {
        this.masterSelected = false;
        d.select = isChecked;
        return d;
      }
      return d;
    });
  }

  onchangeMaster($event) {
    const isChecked = $event.checked;
    if (isChecked) {
      this.phraseArray = [];
      this.dictionary.forEach(element => {
        this.phraseArray.push(element);
        element.select = true;
      });
    } else {
      this.dictionary.forEach(element => {
        this.phraseArray = [];
        element.select = false;
      });
    }
    if (this.phraseArray.length > 0) {
      this.showButton = true;
    } else {
      this.showButton = false;
    }
  }
  // *******************


  applyFilter(filterValue: string) {

    // this.dataSourceUserPhrases.data.map(data => {
    //   if (data.value.includes(filterValue) || data.key.includes(filterValue)) {
    //     filterValue = data.key.trim().toLowerCase();
    //   }
    // });

    this.dataSourceUserPhrases.filter = filterValue.trim().toLowerCase();
  }
  applySystemPhrasesFilter(filterValue: string) {
    if (filterValue[0] == '.') {
      filterValue = filterValue.substring(1);
    }
    let filter = filterValue.trim().toLowerCase();
    if (filter) {
      let filterVal = [];
      this.phrases.forEach(phrase => {
        if (phrase.key?.toLowerCase().includes(filter)) {
          filterVal.push(phrase)
        }
      });
      this.dataSourceSystemPhrases = new MatTableDataSource(filterVal);
    } else if (filterValue == '') {
      this.dataSourceSystemPhrases = new MatTableDataSource(this.phrases);
    }
  }

  sortPhrases(arr: any[]) {
    arr.sort((a, b) => a.key.toLowerCase().localeCompare(b.key.toLowerCase()));
  }

  async getAllPhrasesByProviderId(provider_id) {
    this.loader.startLoader(this.loaderId);
    const phrases : any = await lastValueFrom(this.drivePhraseService.getAllPhrasesByProviderId(provider_id));
    if(phrases.status == 200) {
      this.loader.stopLoader(this.loaderId);
      let obj = {
        providers: JSON.parse(JSON.stringify(this.providers)),
        phrase: JSON.parse(JSON.stringify(phrases.data.phrases.dictionary)),
        selectedProviderId: this.selectedProvider._id
      }
      this.dialog.open(AddUserPhraseToProviderComponent, {
        width: '800px',
        height: '50vh',
        data: obj,
      });
    }
  }

  async onShareAllPhrases() {
    await this.getAllPhrasesByProviderId(this.selectedProvider._id);
  }
}