import { IFile, IFolder } from './models';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { AuthService } from 'src/app/services/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatChipInputEvent } from "@angular/material/chips";
import { DashboardService } from "../dashboard/dashboard.service";
import { FormControl } from "@angular/forms";

// SHARED BY ME DIALOG
export interface ShareItemDialogData {
    item: IFile | IFolder,
    emails: String[]
}

@Component({
    selector: 'share-item-dialog',
    templateUrl: './share-item-dialog.html',
    styleUrls: ['./drive.component.css']
})
export class ShareItemDialog {
    invalidEmailError = false;
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    item: IFile | IFolder;
    emails: String[] = [];
    currentUser: any;
    isOwnEmail = false;
    userEmails = [];
    selectedEmail = '';
    userControl = new FormControl();
    @ViewChild('emailInput', { static: true }) emailElement: ElementRef;


    constructor(
        public dialogRef: MatDialogRef<ShareItemDialog>,
        private _authService: AuthService,
        private dashboardService: DashboardService,
        @Inject(MAT_DIALOG_DATA) public data: ShareItemDialogData) {
        if (data && data.item) {
            this.item = data.item;
            this.emails = [...data.item.shared_by_email];
        }
        this.currentUser = this._authService.currentUser;
    }

    loadSuggestions(text) {
        if (text === '' || text.trim() === '') return;
        this.dashboardService.loadEmailSuggestions(text.trim())
            .subscribe((res) => {
                // @ts-ignore
                this.userEmails = res.data;
            });
    }

    selectEmail(value) {

        this.selectedEmail = value.option.value;
        this.emailElement.nativeElement.value = this.selectedEmail;

        this.emails.push(this.selectedEmail);
        this.emailElement.nativeElement.value = '';
    }

    isSame(A, B) {
        if (this.emails.length !== this.item.shared_by_email.length) {
            return false;
        }

        return A.every(e => B.includes(e));
    }

    onShareClick() {
        if (this.invalidEmailError || this.isOwnEmail) {
            return;
        }

        const hasDiff = !this.isSame(this.emails, this.item.shared_by_email);

        this.dialogRef.close(hasDiff ? this.emails : undefined);
    }

    add(event: MatChipInputEvent): void {

        const input = event.input;
        const value = (event.value || '').trim();
        const nativeValue = (this.emailElement.nativeElement.value || '').trim();
        if (nativeValue == '') return;
        const isValidEmail = /^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/.test(nativeValue);

        this.isOwnEmail = nativeValue === this.currentUser.email;

        if (isValidEmail && !this.emails.includes(nativeValue) && !this.isOwnEmail) {
            this.emails.push(nativeValue);
        }
        if (nativeValue.length > 0) {
            this.invalidEmailError = !isValidEmail;
        }

        if (nativeValue) {
            this.emailElement.nativeElement.value = '';
        }
        // // Reset the input value
        // if (input && isValidEmail && !this.isOwnEmail) {
        //     input.value = '';
        // }
    }

    remove(email: String): void {
        const index = this.emails.indexOf(email);

        if (index >= 0) {
            this.emails.splice(index, 1);
        }
    }
}
