<div class="flex flex-col gap-2">
    <br />
    <span style="margin-bottom: 10px; display: contents; text-align: center;">
        <h3>Charges Category</h3>
    </span>
    <div class="flex justify-between items-start gap-2">
        <mat-form-field class="flex flex-row" appearance="fill">
            <mat-label>Enter categery name</mat-label>
            <input matInput type="text" [(ngModel)]="category" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)">
        </mat-form-field>
        <mat-form-field class="flex-row" appearance="fill">
            <mat-label>Select Category Type</mat-label>
            <mat-select required [(ngModel)]="selectedCategoryType">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let type of categoryTypes" [value]="type">
                    {{type}}
                </mat-option>
            </mat-select> 
        </mat-form-field>
        <button *ngIf="create && !update" class="theme-btn" mat-raised-button (click)="createCategory()"
            [disabled]="!category || !selectedCategoryType">Create</button>
        <button *ngIf="!create && update" class="theme-btn" mat-raised-button (click)="updateCategory()"
            [disabled]="!category || !selectedCategoryType">Update</button>
        <button  class="theme-btn" mat-raised-button (click)="manageCategories()" [routerLink]="['./manage-category']">Manage Catagories</button>

    </div>

    <div class="flex justify-between items-start gap-2">
        <mat-form-field class="flex">
            <input type="text" matInput (keyup)="applyFilter($event.target.value)"
                placeholder="Filter Catagories by name" />
        </mat-form-field>

    </div>
    <div ngxUiLoaderBlurred>
        <table mat-table [dataSource]="dataSource" matSort *ngIf="!loading">
            <ng-container matColumnDef="title">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container style="width: 20%;"> Title </th>
                <td mat-cell *matCellDef="let element">{{element.title}} </td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container style="width: 10%;"> Type </th>
                <td mat-cell *matCellDef="let element">{{element.type}} </td>
            </ng-container>


            <ng-container matColumnDef="assoc_pro">
                <th class="header-data" style="width: 40%;" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                    Associated Providers
                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-chip-list>
                        <mat-chip *ngFor="let provider of element.assoc_pro" [selectable]="selectable"
                            [removable]="removable" (removed)="removeProvider(provider, element)">
                            {{getProviderName(provider)}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>

                    </mat-chip-list>
                </td>
            </ng-container>

            <ng-container matColumnDef="providers">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container> Providers </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field>
                        <input type="text" placeholder="Select Provider" aria-label="provider" matInput
                               [formControl]="providerControl" [matAutocomplete]="providerAutoControl">
                        <mat-autocomplete #providerAutoControl="matAutocomplete" autoActiveFirstOption>
                            <mat-option (click)="onSelectProvider()">--none--</mat-option>
                            <mat-option *ngFor="let provider of filteredProvider | async"
                                        (click)="updateRealtion(provider, element)">
                                {{provider.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </td>
            </ng-container>
            <ng-container matColumnDef="operations">
                <th class="header-data" mat-header-cell *matHeaderCellDef style="width: 11%;"></th>
                <ng-container *matCellDef="let element">
                    <td mat-cell style="max-width: 120px" class="buttons-div">
                        <div class="operation-buttons buttons-div flex flex-row items-center gap-5">
                            <i class="icon icon-edit" matTooltip="Edit" matTooltipPosition="above"
                                (click)="onEditClick(element)"></i>

                            <i class="icon icon-delete" matTooltip="Delete" matTooltipPosition="above"
                                (click)="onDeleteClick(element)"></i>
                        </div>
                    </td>
                </ng-container>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
        <ngx-ui-loader [hasProgressBar]="false" [loaderId]="loaderId"></ngx-ui-loader>
    </div>
</div>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>
