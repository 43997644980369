    <div class="flex justify-center items-center gap-5" style="background: #cedbdf; padding: 5px; border-radius: 4px;">
  
    <app-input-with-dropdown class="flex-grow" [noDropdown]="true" placeholder="Search Admin by Name"
      (inputChange)="applyFilter($event.target.value)">
    </app-input-with-dropdown>
    <mat-form-field>
      <mat-label>User Status</mat-label>
      <mat-select value="true" (selectionChange)="applyUserFilter($event)">
        <mat-option value="true">Active</mat-option>
        <mat-option value="false">Inactive</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div style="height: 3px;"></div>
  <div style="overflow-x: auto; ">
  
    <table mat-table [dataSource]="dataSource" matSort>
  
      <ng-container matColumnDef="first_name">
        <th mat-header-cell *matHeaderCellDef style="width: 8%;"> First Name </th>
        <td mat-cell *matCellDef="let element">
          {{element._id !== currentCompany?.user_id?._id ?(element.first_name | titlecase):null}}
          <b *ngIf="element._id === currentCompany?.user_id?._id">
            {{element.first_name | titlecase}}
          </b>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="last_name">
        <th mat-header-cell *matHeaderCellDef style="width: 10%;"> Last Name </th>
        <td mat-cell *matCellDef="let element">
          {{element._id !== currentCompany?.user_id?._id ?(element.last_name | titlecase):null}}
          <b *ngIf="element._id === currentCompany?.user_id?._id">
            {{element.last_name | titlecase}}
          </b>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef style="width: 18%;"> Email </th>
        <td mat-cell *matCellDef="let element" style="overflow-wrap: break-word;">
          <a>
          <span *ngIf="element._id !== currentCompany?.user_id?._id" [routerLink]="['../../doctor-profile', {provider_id: element._id}]">
            {{element.email | titlecase}}            
          </span>
          <br>
          <small *ngIf="element.recentLogin==undefined && element._id !== currentCompany?.user_id?._id" style="color: gray; cursor: pointer;" (click)="ResendEmail(element._id)"><a>Resend Registration Email</a></small>
          <b *ngIf="element._id === currentCompany?.user_id?._id">
            {{element.email | titlecase}}
          </b>
          <br>
          <small *ngIf="element.recentLogin==undefined && element._id === currentCompany?.user_id?._id" style="color: gray; cursor: pointer;" (click)="ResendEmail(element._id)"><a>Resend Registration Email</a></small>
        </a>
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="last_login">
        <th mat-header-cell *matHeaderCellDef style="width: 13%;"> Last Login</th>
        <td mat-cell *matCellDef="let element">
          {{element.recentLogin ?(element.recentLogin|date :'dd-MMM-yy h:mm:ss a '):null}}
  
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Active</th>
        <td mat-cell *matCellDef="let element" style="text-align-last: center;">
          <mat-checkbox class="top-margin" [checked]="element.is_activated == 'true'"
            [disabled]="element._id === currentCompany?.user_id?._id"
            (change)="changeUserActiveStatus(element, $event.checked)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="charge-status">
        <th mat-header-cell *matHeaderCellDef>Charge Capture</th>
        <td mat-cell *matCellDef="let element" style="text-align-last: center;">
          <mat-checkbox class="tp-margin" [checked]="element.charge_status == true" [disabled]="disabled"
            (change)="changeChargeStatus(element, $event.checked)">
          </mat-checkbox>
        </td>
      </ng-container>  
      <ng-container matColumnDef="telemedicine">
        <th mat-header-cell *matHeaderCellDef>Telemed</th>
        <td mat-cell *matCellDef="let element" style="text-align-last: center;">
          <mat-checkbox class="tp-margin" [checked]="element.other_settings.show_telemedicine == true"
            [disabled]="disabled" (change)="changeTelemedicineStatus(element, $event.checked)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="pcc">
        <th mat-header-cell *matHeaderCellDef>PCC</th>
        <td mat-cell *matCellDef="let element" style="text-align-last: center;">
          <mat-checkbox class="tp-margin" [checked]="element.has_pcc_account == 'true'" [disabled]="disabled"
            (change)="changePccStatus(element, $event.checked)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="cognito">
        <th mat-header-cell *matHeaderCellDef>Cognito</th>
        <td mat-cell *matCellDef="let element" style="text-align-last: center;">
          <mat-checkbox class="tp-margin" [checked]="element.enableCognito == true" [disabled]="disabled"
            (change)="changeCognitoStatus(element, $event.checked)">
          </mat-checkbox>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="admin">
        <th mat-header-cell *matHeaderCellDef>Admin</th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field style="width: 90px;">
            <mat-select (selectionChange)="handleChangeAdminType(element._id,$event)" [ngModel]="element.admin"
              [disabled]="currentUser._id == element._id || element.admin_type == 'system_admin'">
              <mat-option value="none">
                None
              </mat-option>
              <mat-option value="full_admin">
                Full Admin
              </mat-option>
              <mat-option value="transcriber_admin">
                Transcriber Admin
              </mat-option>  
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="user-type">
        <th mat-header-cell *matHeaderCellDef>User Type</th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field style="width: 90px;">
            <mat-select (selectionChange)="handleChangeUserType(element._id,$event)" [ngModel]="usersFilter.user_type">
              <mat-option value="1">
                Provider
              </mat-option>
              <mat-option value="2">
                Transcriber
              </mat-option>
              <mat-option value="3">
                Manager
              </mat-option>
              <mat-option value="5">
                Nurse
              </mat-option>
              <mat-option value="4">
                PA
              </mat-option>
              <mat-option value="6">
                Biller
              </mat-option>
              <mat-option value="medical_assistant">
                Medical Assistant
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="companies">
        <th mat-header-cell *matHeaderCellDef>Company</th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field style="color: black !important;width: 90px;" class="flex-grow">
            <input type="text"  aria-label="company" matInput [formControl]="companyControl"
              [matAutocomplete]="companyAutoControl">
            <mat-autocomplete #companyAutoControl="matAutocomplete">
              <mat-option *ngFor="let company of filteredCompanies | async" [value]="company.name ?company.name:''"
                (click)="onCompanyChange(element , company._id);">
                {{company.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </td>
      </ng-container>
  
  
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
  </div>