<div class="mat-dialog-header flex justify-between items-center">
    <h2 mat-dialog-title>Diagnosis Group</h2>

    <div class="flex items-center justify-center gap-8">
        <div class="pagination flex items-center justify-end">
            <button mat-raised-button class="dn-button-primary" style="margin-right:3px;" 
            (click)="addNewWoundGroup()"> Add New Group</button>

            <button mat-raised-button class="dn-button-primary" [disabled]="!isValid()" style="margin-left:3px;"
                (click)="saveWoundDiagnosisAndClose()">Save</button>
        </div>
        <span class="material-symbols-outlined mat-dialog-close-icon" mat-dialog-close>cancel</span>
    </div>
</div>



<div>
    
    <div class="top-block flex justify-between items-start gap-x-16">
        <mat-form-field class="input-field" style="width: 400px;">
            <mat-select placeholder="Select Etiology" [value]="selectedWoundEtiology"
                (selectionChange)="handleWoundEtiologyChange($event)">
                <input matInput (keyup)="searchWoundEtiologies($event.target.value)"
                    style="color:#000;height: 40px;padding-left: 5px;" />
                <mat-option id="{{woundEtiology._id}}" *ngFor="let woundEtiology of woundEtiologies"
                    [value]="woundEtiology">{{woundEtiology.label}}</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="info-msg">
            Please click on "save as draft or submit" in the charge capture tab to save your current changes. Otherwise, these changes will be lost
        </div>
    </div>
    <div class="wound-wrapper" mat-dialog-content style="position: relative;">
        <ng-container *ngIf="!initializing && selectedWoundEtiology && woundEtiologyGroupsWithTags.length === 0">
            <mat-error>No diagnosis found in selected Etiology</mat-error>
        </ng-container>
        <div class="wound-column-container">
            <div class="wound-column" *ngFor="let woundEtiologyGroup of woundEtiologyGroupsWithTags">
                <mat-label *ngIf="woundEtiologyGroup.deletable" class="wound-group-deletable-title label"
                    (click)="deleteWoundGroup(woundEtiologyGroup)">-{{woundEtiologyGroup._id.title}}</mat-label>
                <mat-label class="label" *ngIf="!woundEtiologyGroup.deletable">{{woundEtiologyGroup._id.title}}
                </mat-label>
                <div>
                    <div class="flex flex-col gap-5 items-start">
                        <div class="flex justify-between items-center">
                            <div class="flex-grow flex" *ngIf="!isICDSearchMode(woundEtiologyGroup._id._id)">
                                <button mat-button *ngFor="let tag of woundEtiologyGroup.tags"
                                    [class.active-item]="isWoundGroupTagSelected(tag, woundEtiologyGroup._id._id)"
                                    (click)="selectWoundGroupTag(tag, woundEtiologyGroup._id._id)">
                                    {{tag.label}}
                                </button>
                            </div>

                            <div *ngIf="isICDSearchMode(woundEtiologyGroup._id._id)" class="flex-grow">
                                <mat-form-field style="width: 100%;">
                                    <input matInput placeholder="Search ICD"
                                        (keyup)="searchNewDiagnose($event.target.value, woundEtiologyGroup._id._id)"
                                        style="color:#000;height: 40px;padding-left: 5px;" />
                                </mat-form-field>
                            </div>
                            <span class="material-symbols-outlined icon-search" (click)="toggleICDSearchMode(woundEtiologyGroup._id._id)">search</span>

                        </div>
                        <mat-list class="icd-list" *ngIf="woundGroupDetailICDs[woundEtiologyGroup._id._id]">
                            <mat-list-item
                                *ngFor="let woundGroupDetailICD of woundGroupDetailICDs[woundEtiologyGroup._id._id]"
                                class="icd-item"
                                [class.active-item]="isWoundGroupDetailsICDSelected(woundGroupDetailICD, woundEtiologyGroup._id._id)"
                                (click)="selectWoundGroupDetailsICD(woundGroupDetailICD, woundEtiologyGroup._id._id)">
                                <div class="flex justify-between items-center" style="width: 100%;">
                                    <div [class.pdpm_highlight]="woundGroupDetailICD.pdpm_status">
                                        {{woundGroupDetailICD.code
                                        + ' (' + woundGroupDetailICD.mediumDescription +')'}}</div>
                                    <span class="material-symbols-outlined icon-remove" 
                                    *ngIf="isWoundGroupDetailsICDSelected(woundGroupDetailICD, woundEtiologyGroup._id._id)"
                                        mat-icon-button
                                        (click)="$event.stopPropagation();removeWoundGroupDetailsICD(woundGroupDetailICD, woundEtiologyGroup._id._id)">
                                        cancel
                                    </span>
                                </div>
                            </mat-list-item>
                        </mat-list>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>
