import { Component, OnInit, DoCheck, AfterViewInit, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { IFolder as IFileExplorerFolder } from './models';
import { IFolder as ITemplateExplorerFolder } from '../templates/models';
import { IFolder } from '../../shared/folder.interface'
import { FileExplorerComponent } from './file-explorer/file-explorer.component'
import { CreateNewFolderDialog } from './create-new-folder-dialog.component'
import { UploadFileDialog } from './upload-file-dialog.component'
import { TemplateExplorerComponent } from '../templates/template-explorer/template-explorer.component';
import { FileConversionService } from 'src/app/services/file-conversion.service';
import * as global from '../global'
import moment from 'moment'
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from "../../../environments/api-url";
import { TemplateExplorerService } from '../templates/template-explorer/template-explorer.service';
// import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { DashboardService } from '../dashboard/dashboard.service';
import * as $ from 'jquery';


@Component({
    selector: 'app-documents',
    templateUrl: './drive.component.html',
    styleUrls: ['./drive.component.css']
})
export class DriveComponent implements OnInit, DoCheck {
    dataSource = [];
    currentUser: any;
    parent_folder_id: string = '';
    url = '/drive';

    clientId = "139947491760-3lp787e77igif4vkdg4r9t2tdf8u4o7m.apps.googleusercontent.com";
    redirect_uri = global.ENVIRONMENT == 'LOCAL' ? "http://localhost:4200/drive" : (global.ENVIRONMENT == 'DEV' ? "https://dev.doctornow.io/drive" : null);
    scope = "https://www.googleapis.com/auth/drive";
    @ViewChild(FileExplorerComponent) fileExplorerComponent: FileExplorerComponent;
    @ViewChild(TemplateExplorerComponent) templateExplorerComponent: TemplateExplorerComponent;

    isVisibleCreateTemplate = false;
    loggedIn: boolean;
    files: any;
    connects: boolean = false;
    constructor(
        protected dialog: MatDialog,
        protected route: ActivatedRoute,
        public router: Router,
        protected fileConversionService: FileConversionService,
        protected toastr: ToastrService,
        private _authService: AuthService,
        private templateExplorerService: TemplateExplorerService,
        // private authService: SocialAuthService,
        private dashboardService: DashboardService,

    ) {
    }

    ngOnInit() {
        // if (localStorage.getItem('google_auth') !== null) {
        //     this.loggedIn = true;
        // }
        if (this.route.snapshot.queryParams.code) {
            localStorage.setItem('code', this.route.snapshot.queryParams.code);
            console.log('already logged in')
            this.loggedIn = true;
        }
        if (localStorage.getItem('accessToken') != null) {
            console.log('already logged in')
            this.loggedIn = true;
        }

        const currentUser = this._authService.currentUser;
        if (currentUser) {
            this.currentUser = currentUser;
        }
        this.url = this.router.url;
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.url = val.url;
            }
        });
        this.templateExplorerService.castProvider.subscribe(provider => this.isVisibleCreateTemplate = !!provider)
    }

    CheckIfLoggedIn( valueEmitted ) {
        this.connects = valueEmitted;
    }

    ngDoCheck() {
        const explorerComponent = this.fileExplorerComponent || this.templateExplorerComponent;

        if ( explorerComponent ) {
            this.parent_folder_id = explorerComponent.historyStackTop === null ? '' : explorerComponent.historyStackTop._id;
        }
    }

    onCreateNewFolderClick() {
        const dialogRef = this.dialog.open(CreateNewFolderDialog, { width: '500px', data: { action: 'create' } });
        dialogRef.afterClosed().subscribe((title) => {
            if (!title) return;

            const explorerComponent = this.fileExplorerComponent || this.templateExplorerComponent;
            const parent_folder_id = explorerComponent.historyStackTop === null ? '' : explorerComponent.historyStackTop._id;
            const folder: IFolder = {
                _id: '',
                parent_folder_id,
                title: title,
                creation_date: moment(new Date()).format(global.moment_date_time_format),
                updation_date: moment(new Date()).format(global.moment_date_time_format),
                owner_id: this.currentUser._id,
                owner_name: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
                is_deleted: 'false'
            };
            if (this.fileExplorerComponent) {
                (<IFileExplorerFolder>folder).shared_by_email = [];
            }
            explorerComponent.createNewFolder(folder)
        })
    }
    //changes
    onUploadFileClick() {
        const dialogRef = this.dialog.open(UploadFileDialog, { width: '500px' });
        dialogRef.afterClosed().subscribe((fileInput) => {
            if (!fileInput || (fileInput && fileInput.files.length === 0)) return;
            const fileBlob: any = fileInput.files;
            for (let i = 0; i < fileBlob.length; i++) {
                this.fileExplorerComponent.uploadFile(fileBlob[i]);
            }
        })
    }

    uploadFileAndCreateTemplate(event) {
        const parent_folder_id = this.templateExplorerComponent.historyStackTop === null ? '' : this.templateExplorerComponent.historyStackTop._id;

        const userInfo = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            userType: this.currentUser.user_type
        }
        const [file] = <Array<File>>event.target.files;
        var filename = file.name;
        const infoToast = this.toastr.info('Processing...', "Please wait", { disableTimeOut: true });
        this.fileConversionService
            .makeFileRequest(userInfo, global.url + API_URL.ROUTES.uploadFile, file, filename)
            .then((result: any) => {
                if ( result.status == 200 ) {
                    localStorage.setItem('convertedHTML', result.data);
                    this.router.navigate(['/template-create', { parent_folder_id }])
                } else {
                    this.toastr.error(result.message, 'Failed');
                }
                this.toastr.clear(infoToast.toastId);
            }, console.error);
    }

    createNewTemplate() {
        if ( !this.parent_folder_id ) {
            this.toastr.error( 'Please select facility and try again!', 'Failed' );
            return;
        }

        this.router.navigate([ '/template-create', { parent_folder_id: this.parent_folder_id }]);
    }

    onSharedByMeClick() {
        this.router.navigate(['shared-with-me'], { relativeTo: this.route })
    }
    // async signInHandler() {
    //     if (localStorage.getItem('google_auth') !== null) {
    //         this.loggedIn = true;
    //     } else {
    //         let result;
    //         await this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((data) => {
    //             result = JSON.stringify(data);
    //             if (data) {
    //                 this.loggedIn = true;
    //             }
    //         })
    //         localStorage.setItem('google_auth', result);
    //     }
    // }

    signOut(): void {
        // this.authService.signOut();
        localStorage.removeItem('code');
        localStorage.removeItem('accessToken');
        this.router.navigate(['drive'])
        this.loggedIn = false;
    }

    authorize(clientId, redirect_uri, scope, url) {
        url = "https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=" + redirect_uri
            + "&prompt=consent&response_type=code&client_id=" + clientId + "&scope=" + scope
            + "&access_type=offline";
        // this line makes the user redirected to the url
        window.location = url;

    }

    // getToken() {
    //     if (localStorage.getItem("accessToken") === null) {

    //         const urlParams = new URLSearchParams(window.location.search);
    //         const code = urlParams.get('code');
    //         const redirect_uri = this.redirect_uri // replace with your redirect_uri;
    //         const client_secret = "1rHzmWDfQUUlu9CTNvtu3GgA"; // replace with your client secret
    //         const scope = "https://www.googleapis.com/auth/drive";
    //         var access_token = "";
    //         var client_id = "139947491760-3lp787e77igif4vkdg4r9t2tdf8u4o7m.apps.googleusercontent.com"// replace it with your client id;


    //         $.ajax({
    //             type: 'POST',
    //             url: "https://www.googleapis.com/oauth2/v4/token",
    //             data: {
    //                 code: code
    //                 , redirect_uri,
    //                 client_secret: client_secret,
    //                 client_id: client_id,
    //                 scope: scope,
    //                 grant_type: "authorization_code"
    //             },
    //             dataType: "json",
    //             success: function (resultData) {
    //                 this.response = resultData;
    //                 localStorage.setItem("accessToken", resultData.access_token);
    //             }
    //         });
    //     }
    // }
}
