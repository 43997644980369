<mat-card class="h100" style="padding-top:0; overflow: auto">
    <mat-card-content>
        <div class="filter-form white-rounded-rect" style="padding: 16px 0 0 !important;">
            <button mat-raised-button
                    style="width:100%"
                    type="button"
                    (click)="schduleBtnClick()"
                    class="dn-button-primary">
                <mat-icon>add</mat-icon>
                New Appointment
            </button>
            <mat-calendar [selected]="calenderSelectedValue"
                          (selectedChange)="calenderSelectedValue=$event;dateSelection.emit($event)">
            </mat-calendar>
            <div class="flex flex-col items-start gap-3">
                <div class="flex flex-col gap-5 items-start"
                     style="padding-left: 10px;"
                     >
                    <div class="input-with-cross">
                        <mat-form-field>
                            <mat-label>Select Provider</mat-label>
                            <mat-select (selectionChange)="providerChange($event)">
                                <mat-option *ngFor="let pro of providers"
                                            [value]="pro._id">{{pro.first_name + ' ' + pro.last_name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="input-with-cross">
                        <mat-form-field>
                            <mat-label>Select Facility</mat-label>
                            <mat-select (selectionChange)="facilityChange($event)">
                                <mat-option *ngIf="filters && !filters.facility_id">
                                    <mat-error>Select Provider First</mat-error>
                                </mat-option>
                                <mat-option *ngFor="let f of facilities"
                                            [value]="f._id">{{f.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                            <div>
                            </div> 
                    <div>
                        <button mat-raised-button
                                style="width:100%"
                                type="button"
                                (click)="goToQuickCall()"
                                class="dn-button-primary">
                            <mat-icon>call</mat-icon>
                            Quick Calls
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
