<mat-toolbar class="flex flex-row">
  <div class="flex items-center">
    <a [routerLink]="['/dashboard']">
          <img src="assets/img/logo.png">
          <img src="assets/img/DNlogo-new.png">
      </a>
        <ul class="top-nav">
          <li routerLinkActive="active"><a routerLink="/dashboard">Dashboard</a></li>       
          <li routerLinkActive="active"><a routerLink="/privacy-policy">Privacy Policy</a></li>       
        </ul>
    </div>
  </mat-toolbar>
  