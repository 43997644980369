<div mat-dialog-content style="padding: 26px">
    <table mat-table [dataSource]="dataSource" class="colorHeaderTable" matSort>
        <ng-container matColumnDef="provider">
            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Provider
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.first_name}}
                {{element.middle_name ? element.middle_name : null}}
                {{element.last_name}}
            </td>
        </ng-container>

        <ng-container matColumnDef="recentLogin">
            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Last Login
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.recentLogin ? (element.recentLogin|date :'dd-MMM-yy h:mm:ss a ') : null}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>