import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { MatSort, Sort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { TrainingSystemService } from '../training-system.service';
import { TranscriptionDetailService } from 'src/app/includes/transcription-detail/transcription-detail.service';
import { AudioService } from 'src/app/services/Modules/audio.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl } from '@angular/forms';
import moment from 'moment'
import { stat } from 'fs'; 
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

interface IFilter {
  active: boolean,
  date_of_service: {
    startDate: moment.Moment,
    endDate: moment.Moment
  },
  selectedProvider: string, // _id 
  selectedStatus: string,
}

@Component({
  selector: 'app-training-system',
  templateUrl: './training-system.component.html',
  styleUrls: ['./training-system.component.css']
})
export class TrainingSystemComponent implements OnInit {
  providerFormControl = new FormControl();
  filteredProvider: Observable<string[]>;
  displayedColumns: string[] = [];
  dataSource: any;
  allProviders: any = [];
  uniqueProviders: any = [];
  loaderId = 'loader-01';
  disabled = false;
  checked = false;
  chunks: any = [];
  pro: Boolean = false;
  loading : Boolean = false;
  // selectedProvider: any = '';
  // selectedStatus: any = '';
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  filter: IFilter;
  appliedFilter: IFilter;

  statusOption = ['Completed', 'Pending', 'Exported'];

  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true } as any) paginator: MatPaginator;
  public pagination: {
    pageNo: number,
    hasNextPage: boolean,
    hasPrevPage: boolean,
    totalCount: number,
    totalPages: number
  };


  constructor(
    public trainingService: TrainingSystemService,
    private ts_detail_service: TranscriptionDetailService,
    private audioService: AudioService,
    private loader: NgxUiLoaderService,
    private toastr: ToastrService,
  ) {
    this.pagination = {
      pageNo: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalCount: 0,
      totalPages: 0
    };
    this.filter = this.filterInitialState();
  }

  async ngOnInit()  {
    this.displayedColumns = ['id', 'provider', 'dictation_id', 'chunk_id', 'dos', 'transcription', 'action', 'status', 'exported',];
    this.getProviders();
    this.initData(1);
    this.filteredProvider = await this.providerFormControl.valueChanges.pipe(
      startWith(''),
      map((value:any) => this.filterProvider(value))
    );
  }
  private filterProvider(value: any)  { 
    if (value) { 
      const filterValue = value.toLowerCase() ;
      return this.uniqueProviders.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    else { 
      return this.uniqueProviders;
    }
  }

  applyFilter(filter?: IFilter) {
    this.loader.startLoader('loader-01');
    if (filter) {
      this.filter = filter;
    } else {
      this.pagination.pageNo = 1;
      this.filter.active = true;
    }

    this.trainingService.getAudioChunks(this.pagination.pageNo, this.filter.active ? this.filter : null).subscribe((response: any) => {
      if (response.status === 200) {
        const totalCount = response.data.totalCount;
        this.pagination.totalCount = totalCount;

        this.chunks = response.data.array;
        this.dataSource = new MatTableDataSource(this.chunks);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.initPagination(); 
          this.loader.stopLoader('loader-01'); 
      }
    });
  }

  filterInitialState(): IFilter {
    return {
      active: false,
      date_of_service: null,
      selectedProvider: '',
      selectedStatus: '',
    };
  }

  async initData(page) {
    this.loader.startLoader('loader-01'); 
    this.trainingService.getAudioChunks(page, this.filter.active ? this.filter : null).subscribe((response: any) => {
      if (response.status == 200) { 

        const totalCount = response.data.totalCount;
        this.pagination.totalCount = totalCount;
        this.pagination.pageNo = page;

        this.chunks = response.data.array;
        // if (this.allProviders.length < 1) {
        // this.getProviders();
        // this.allProviders = [...response.data.providers];

        // const map = new Map();
        // for (const item of this.allProviders) {
        //   if (!map.has(item._id)) {
        //     map.set(item._id, true);    // set any value to Map
        //     this.uniqueProviders.push({
        //       id: item._id,
        //       name: item.first_name + ' ' + item.last_name
        //     });
        //   }
        // }
        // this.uniqueProviders.sort(this.sortProviders)
        // }

        this.dataSource = new MatTableDataSource(this.chunks);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.initPagination();
        if(this.uniqueProviders ){
          this.loader.stopLoader('loader-01');
        }
      }
    })
  }

  async getProviders() {
    const pro = await this.trainingService.getProviders().subscribe((response: any) => {
      if (response.status == 200) { 
        // this.allProviders = [...response.data];
        this.allProviders  = response.data; 

        const map = new Map();
        for (const item of this.allProviders) {
          if (!map.has(item._id) && item.first_name) {
            map.set(item._id, true);    // set any value to Map
            this.uniqueProviders.push({
              id: item._id,
              name: item.first_name + ' ' + item.last_name + ',' + item.title,
            });
          }
        }
        this.uniqueProviders.sort(this.sortProviders)
        this.loader.stopLoader('loader-01'); 
      }
    })
  }

  resetFilter() {
    this.filter = this.filterInitialState();
    this.appliedFilter = { ...this.filter };
    this.applyFilter();
  }

  sortProviders(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  initPagination() {
    this.pagination.totalPages = Math.ceil(this.pagination.totalCount / 20);
    this.pagination.hasNextPage = this.chunks.length > 0 && this.pagination.pageNo < this.pagination.totalPages;
    this.pagination.hasPrevPage = this.pagination.pageNo > 1;
    // this.loader.stopLoader('loader-01');
  }
  handleNextClick() {
    this.initData(this.pagination.pageNo + 1);

  }

  handlePrevClick() {
    this.initData(this.pagination.pageNo - 1);
  }

  getCreatedDate(element) {
    if (element.created_at) {
      return new Date(element.created_at).toLocaleDateString();
    }
    else {
      return '';
    }
  }

  getDuration(element) {
    if (element.duration) {
      return Math.round((element.duration * 100) / 100)
      // return element.duration.toFixed(2);
    }
    else {
      return '';
    }
  }

  getExportStatus(element){
    if(element ){
      if(element.exported){
        return "Exported";
      }
      else {
        return ""
      }
    }
    else{
      return ""
    }
  }

  getStatus(element) {
    if (element.status) {
      if (element.status == "Completed")
        return true;
    }
    else {
      return false;
    }
  }

  onSelectProvider(provider = null) {
    if (provider && provider != null) {
      this.filter.selectedProvider = provider.id
    }
    else {
      this.filter.selectedProvider = null;
    }
  }

  changeStatus(chunk, event) {
    let status;
    if (event) {
      status = "Completed";
    }
    else {
      status = "Pending";
    }
    this.trainingService.updateChunkStatus(status, chunk._id).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.info('Chunk Status Updated');
      }
    })
  }

  playChunk(chunk = '0', d, element) {
    //
    // @ts-ignore
    let container = document.getElementById(element._id);
    let childs = container.children;
    // @ts-ignore
    childs[0].style.display = 'none';
    // @ts-ignore
    childs[1].style.display = 'block';
    setTimeout(() => {
      // @ts-ignore
      childs[0].style.display = 'block';
      // @ts-ignore
      childs[1].style.display = 'none';
    }, (parseFloat(d) * 1000));
    let duration = parseFloat(d).toFixed(0).toString();
    this.ts_detail_service.chunk.next({ chunk, duration });
  }

  getProvider(element) {
    if (element.provider) {
      return element.provider.first_name + ' ' + element.provider.last_name;
    }
    else {
      return '';
    }
  }
  updateChunk(chunk, event) {
    let data = {
      _id: chunk._id,
      transcript: event.target.innerText
    };
    this.audioService.updateChunk(data).subscribe((data) => {
      this.ts_detail_service.chunk_update.next(true);
    });
  }

  getChunkName(element) {
    const [dictaionID, chunk] = element.chunk_name.split('-');
    return chunk;
  }
  getDictationID(element) {
    const [dictaionID, chunk] = element.chunk_name.split('-');
    return dictaionID;
  }

  onChangeStatus(status = null) {
    if (status) {
      this.filter.selectedStatus = status;
      return status;
    }
    else {
      this.filter.selectedStatus = null;
      return ''
    }
  }

  async generateCSV(filter?: IFilter) {
    this.toastr.info('Generating CSV...');
    if (filter) {
      this.filter = filter;
    } else {
      this.filter.active = true;
    }
    this.trainingService.generateCSV(this.filter.active ? this.filter : null).subscribe((response: any) => {
      if (response.status == 200) {
        const data = response.data;
        const headerList = ['wav_filename', 'wav_filesize', 'transcript']
        let array = typeof data != 'object' ? JSON.parse(data) : data;
        let finalJson = [];
        array.forEach(async chunk => {
          let row = {
            // created_date: new Date().toLocaleDateString(),
            wav_filename: chunk.chunk_name ? chunk.chunk_name + '.wav ': '',
            wav_filesize: chunk.chunk_size ? chunk.chunk_size : '',
            transcript: chunk.transcript ? chunk.transcript : ' ',
          }
          await finalJson.push(row);
        });

        let str = '';
        // let row = 'Sr. No';
        let row = '';
        for (let index in headerList) {
          row += headerList[index] + ',';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < finalJson.length; i++) {
          let line = '';
          for (let index in headerList) {
            let head = headerList[index].trim();
            line += finalJson[i][head] + ',';
          }
          str += line + '\r\n';
        }
        this.trainingService.downlaodCSV(str);
        return str;
      }
    })
  }
  async getPhrasesCSV(filter?: IFilter) {
    this.toastr.info('Wait...', 'Generating Phrases CSV');
    if (filter) {
      this.filter = filter;
    } else {
      this.filter.active = true;
    }
    this.trainingService.getPhrasesCSV(this.filter.active ? this.filter : null).subscribe((response: any) => {
      if (response.status == 200) {
        this.trainingService.downlaodCSV(response.phrasesData); 
      }
    })
  }

}

