<table  mat-table [dataSource]="dataSource" matSort> 

    <ng-container matColumnDef="icd10_checkbox">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            
        </th>
        <td mat-cell *matCellDef="let element">
            <mat-checkbox
            (change)="toggleSelectICDCode(element.icd10, element.icd10Description)"
            [checked]="!!isICDCodeSelected(element.icd10)">
                {{element.icd10}}
            </mat-checkbox>
        </td>
    </ng-container>

    <ng-container matColumnDef="icd10">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            ICD 10
        </th>
        <td mat-cell *matCellDef="let element" [class.pdpm_highlight]="element.pdpm_status">
            <mat-checkbox 
            *ngIf="showCheckboxes"
            (change)="toggleSelectICDCode(element.diagnosis_code,element.diagnosis_code_text)"
            [checked]="!!isICDCodeSelected(element.diagnosis_code)">
                {{element.diagnosis_code}}
            </mat-checkbox>
            <ng-container *ngIf="!showCheckboxes" >
                {{element.diagnosis_code}}
            </ng-container>
        </td>
    </ng-container>
    

    <ng-container matColumnDef="icd10Description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            ICD 10 Description
        </th>
        <td mat-cell *matCellDef="let element" [class.pdpm_highlight]="element.pdpm_status">
            {{element.diagnosis_code_text}}
        </td>
    </ng-container>
    

    <ng-container matColumnDef="rankDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Rank Description
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.rankDescription}}
        </td>
    </ng-container>
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Date
        </th>
        <td mat-cell *matCellDef="let element" [class.pdpm_highlight]="element.pdpm_status">
            {{element.diagnosis_date_time | date: 'MM/dd/yyyy'}}
        </td>
    </ng-container> 
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<br />
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>