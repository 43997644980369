<div class="container">
    <mat-card>
        <div class="flex justify-between items-center">
            <button mat-raised-button class="dn-button-secondary" type="button"
                (click)="goBack();">Back</button>
                <h2>{{parentReport?.title}}</h2>
            <button mat-raised-button class="dn-button-primary" (click)="openCreateQueryReportDialog();"
                type="button">+</button>
        </div>
        <br>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

            <ng-container matColumnDef="sr">
                <th mat-header-cell *matHeaderCellDef> Sr </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{i + 1}}
                </td>
            </ng-container>

            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef> Title </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element?.title}}
                </td>
            </ng-container>


            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    <div class="operation-buttons buttons-div flex flex-row items-center gap-5">
                        <span class="material-icons" matTooltip="Edit" matTooltipPosition="above"
                            style="cursor: pointer;" (click)="openCreateQueryReportDialog(element._id, i);">
                            edit_note
                        </span>
                        <span class="material-icons" matTooltip="Delete" matTooltipPosition="above"
                            style="cursor: pointer;" (click)="deleteQueryReport(element._id, i)">
                            delete_outline
                        </span>
                        <a style="cursor: pointer" *ngIf="reportAllowedtoShow(element.title)" (click)="executeQueryReport(element.title, i)">Show Report</a>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

    </mat-card>

</div>

