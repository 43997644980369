<mat-tab-group>
    <mat-tab label="User Defined Phrases"> 
        <ng-template matTabContent>
            <app-user-defined-phrases [note]="note"></app-user-defined-phrases>
        </ng-template>
    </mat-tab>
    <mat-tab label="System Defined Phrases"> 
        <ng-template matTabContent>
            <app-system-defined-phrases
                [note]="note"
                [selectedSource]="selectedSource"
                (insertPhraseText)="insertPhraseText.emit($event)"
            ></app-system-defined-phrases>
        </ng-template>
    </mat-tab>
    
    <mat-tab *ngIf="currentUser.company_type === 'Wound' || currentUser.company_type === global.COMPANY_TYPE.SNF" label="Wound Phrases"> 
        <ng-template matTabContent>
            <app-woundphraseboth [note]="note"></app-woundphraseboth>
        </ng-template>
    </mat-tab>

    <mat-tab label="Template Headings"> 
        <ng-template matTabContent>
            <app-template-headings [forNoteType]="forNoteType" [useDragonStyle]="useDragonStyle"></app-template-headings>
        </ng-template>
    </mat-tab>
</mat-tab-group> 