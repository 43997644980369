import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../global'
import {API_URL} from "../../../environments/api-url";


@Injectable({
  providedIn: 'root'
})
export class CompaniesConfigrationService {
  currentUser: any;
  constructor(
    private authService: AuthService,
    private httpclient: HttpClient,

  ) { 
    this.currentUser = this.authService.currentUser
  }

  setCompaniesConfigration(model = {}, configID = null){
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type, 
      model: model,
      configID
    };
    return this.httpclient.post(global.url + API_URL.COMPANIES_CONFIGRATION.setCompaniesConfigration, {details: details});
  }

  getCompaniesConfigration(company){ 
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type, 
      company_id: company
    };
    return this.httpclient.post(global.url + API_URL.COMPANIES_CONFIGRATION.getCompaniesConfigration, {details: details}).pipe();
  }

  deleteConfigration(configID = null){ 
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type, 
      configID
    };
    return this.httpclient.post(global.url + API_URL.COMPANIES_CONFIGRATION.deleteConfigration, {details: details}).pipe();
  }

}
