import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { Observable, lastValueFrom } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/services/auth.service';
import { defer } from 'rxjs';
import * as global from '../../../app/includes/global';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css'],
})
export class AdminDashboardComponent implements OnInit {
  activatedRoute: string = 'dictations'
  filteredCompanies: Observable<string[]>;
  companyControl = new FormControl();
  companies: any = [];
  company_id: any;
  selectedCompany: any = {};
  currentCompany: any;
  currentUser: any;
  searchCompanyText: any;
  selectedRadio: any = 'notes';
  isWoundCompany: boolean = false;
  global = global;
  should_show_consent_form: boolean = false;
  isDataLoaded: boolean = false;
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private companyService: CompanyService,
    private _commonService: CommonService,
    private _authService: AuthService,
    private toastr: ToastrService
  ) {
    this.activatedRoute = this._route.snapshot.firstChild.routeConfig.path;
    this.currentUser = this._authService.currentUser;
    this.company_id = this._route.parent.snapshot.params.id;
    // this._route.queryParams.subscribe(params => {
    //   this.selectedRadio = params.selectedRadio;
    //   this._router.navigate([`/company/${this.company_id}/dashboard/${this.selectedRadio}`]);
    // });
    // return;
  }

  async ngOnInit() {
    // this._route.parent.paramMap.subscribe(async (params:ParamMap) => {
    //   console.log(params.get('id'));
    //   console.log(typeof params.get('id'));
    //   this.company_id = parseInt(params.get('id'));

    // });

    await this.getCompany();
    if (this.currentUser.product_owner) {
      this.getCompanies();
    }

    this.filteredCompanies = this.companyControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => this.filterCompany(value)));
  }
  private filterCompany(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.sortNameVise(this.companies.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0));
    }
    else {
      return this.sortNameVise(this.companies);
    }
  }

  async getCompany() {
    const filter = {
      _id: this.company_id
    };
    const project = {
      name: 1,
      permissions: 1,
      company_type: 1,
      portal_type: 1
    };
    const response: any = await lastValueFrom(this.companyService.getCompany(filter, project));
    if (response.status == 200) {
      this.selectedCompany = response.data;
      this._commonService.setLocalStorageItem('admin-Dashboard', { portal_type: response.data.portal_type, currentCompanyType: response.data.company_type, currentCompanyName: response.data.name, currentCompanyId: response.data._id });
      if (response.data.company_type.toLowerCase() === 'wound') {
        this.isWoundCompany = true;
      }
      // if (response.data.permissions.length > 0) {
      //   [this.should_show_consent_form] = this.companyService.validateCompanyPermission(response.data.permissions, ["should_show_consent_form"]);
      // }
      this.isDataLoaded = true;
    }
    else if (response.status === 500) {
      this.toastr.error(response.message, "ERROR");
    }
  }

  sortNameVise(arrayTosort) {
    let users = arrayTosort;
    let result = users.sort(function (a, b) {
      if (a.name?.toLowerCase() < b.name?.toLowerCase()) { return -1; }
      if (a.name?.toLowerCase() > b.name?.toLowerCase()) { return 1; }
      return 0;
    })
    return result;
  }


  changeRoute() {
    this.selectedRadio = this.activatedRoute;
    return this._router.navigate([`${this.activatedRoute}`], { relativeTo: this._route });
  }

  async getCompanies() {
    const response: any = await this.companyService.getAllCompanies().toPromise();
    // console.log("init companies");
    if (response.status == 200) {
      this.companies = response.data;
      this.companies = this.sortNameVise(this.companies);
      // console.log("companies",this.companies);
      this.currentCompany = this._route.parent.snapshot.params.id;
      const presentCompany = this.companies.find(company => this.company_id === company._id);
      if (presentCompany && presentCompany?.company_type.toLowerCase() === 'wound') {
        this.isWoundCompany = true;
      }
      const index = this.companies.findIndex(company => this.currentCompany === company._id);
      this.currentCompany = this.companies[index]._id;
      this.isDataLoaded = true;
    }
    else if (response.status == 403) {
      this.toastr.error(response.message, "ERROR");
    }
  }
  navigate($event, id) {
    if ($event.isUserInput) {
      this.currentCompany = id;
      if (this.currentCompany !== this._route.parent.snapshot.params.id) {
        this._router.navigateByUrl(`/dashboard`, { skipLocationChange: true }).then(() =>
          this._router.navigate([`/company/${this.currentCompany}/dashboard/${this.activatedRoute}`], { relativeTo: this._route })
        );
      }
    }

  }

}
