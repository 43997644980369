import { Component, EventEmitter, Injectable, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { lastValueFrom } from 'rxjs';
import { WoundCareWidgetService } from '../wound-care-widget.service';
import moment from 'moment'
import { WoundCareWidgetItemComponent } from '../wound-care-widget-item/wound-care-widget-item.component';
import { FacilitySettingsService } from 'src/app/components/company/company-facilities/facility-settings/facility-settings.service';
import { AuthService } from 'src/app/services/auth.service';
import { WoundCareWidgetChildItemComponent } from '../wound-care-widget-item/wound-care-widget-child-item/wound-care-widget-child-item.component';
import { CompanyService } from 'src/app/services/Modules/company.service';

function getRandom(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}



@Injectable()
@Component({
  selector: 'app-wound-care-widget-wound-list',
  templateUrl: './wound-care-widget-wound-list.component.html',
  styleUrls: ['./wound-care-widget-wound-list.component.css']
})
export class WoundCareWidgetWoundListComponent implements OnInit {

  woundsLoaderId = 'woundsLoaderId' + Math.random()

  @Input() patient;
  @Input() provider;
  @Input() note;
  @Input() configuration;
  @Input() companyPermissions;
  @Input() woundState: string;
  @Input() readOnlyMode: any;
  @Input() isPreview;
  @Input() created_userType;
  @Input() created_by;
  @Input() facility_id;
  @Input() creationDate;
  @Input() should_show_view_icon: boolean;

  @Output() onICDsSave: EventEmitter<any> = new EventEmitter();
  @Output() woundAnalyticReport: EventEmitter<any> = new EventEmitter();
  @Output() woundDetailsChange: EventEmitter<any> = new EventEmitter();
  @Output() procedureNoteDetailsChange: EventEmitter<any> = new EventEmitter();
  @Output() allWoundIds: EventEmitter<any> = new EventEmitter();
  @Output() checkallWoundAssessmentPhrase: EventEmitter<any> = new EventEmitter();
  @ViewChild('latestWoundChild') woundCareWidgetItemComponent: WoundCareWidgetItemComponent;
  // @ViewChild(WoundCareWidgetChildItemComponent)  childWoundItem: WoundCareWidgetChildItemComponent;
  wounds: any[] = [];
  currentUser: any;
  woundsWithoutSort: any[] = [];
  skinSubRefferal: boolean = false;
  
  constructor(
    private loader: NgxUiLoaderService,
    private woundCareWidgetService: WoundCareWidgetService,
    private _facilitySettingsService: FacilitySettingsService,
    private _authService: AuthService,
    private _companyService: CompanyService
  ) {
    this.currentUser = this._authService.currentUser;
  }

  async ngOnInit() {
    this.initWounds();
    if (!this.configuration) {
      const response: any = await lastValueFrom(this._facilitySettingsService.getFacilitySettings(this.facility_id, this.currentUser.company_id));
      if (response.status === 200) {
        this.configuration = response.data.configuration;
      }
    }
    if(!this.companyPermissions){
      const response: any = await lastValueFrom(this._companyService.getCompany({ _id: this.currentUser.company_id }, { should_show_wound_assessment: 1, should_show_procedure_note: 1, should_show_skin_sub_refferal: 1 }));
      if (response.status == 200) {
          this.companyPermissions = response.data;
          if(this.companyPermissions['should_show_skin_sub_refferal'] != undefined) {
            this.skinSubRefferal = this.companyPermissions['should_show_skin_sub_refferal'];
          }
      }
    }
    else {
      console.log('this.companyPermissions', this.companyPermissions)
      if(this.companyPermissions['should_show_skin_sub_refferal'] != undefined) {
        this.skinSubRefferal = this.companyPermissions['should_show_skin_sub_refferal'];
      }
    }
    if (this.should_show_view_icon == undefined)
        this.should_show_view_icon = false;
  }

  initDummyWounds() {

    this.wounds = Array.from({ length: 4 }).map((e, i: number) => ({
      woundNo: (i + 1),
      wod: new Date(),
      wound_images: [
        `https://picsum.photos/seed/${Math.random()}/200/200`,
        `https://picsum.photos/seed/${Math.random()}/200/200`,
        `https://picsum.photos/seed/${Math.random()}/200/200`,
        `https://picsum.photos/seed/${Math.random()}/200/200`,
        `https://picsum.photos/seed/${Math.random()}/200/200`,
        `https://picsum.photos/seed/${Math.random()}/200/200`,
        `https://picsum.photos/seed/${Math.random()}/200/200`,
        `https://picsum.photos/seed/${Math.random()}/200/200`,
      ],
      body_side: 'Left',
      etiolgy: 'Suprior',
      body_oart: 'Leg',
      wound_type: 'Pressure',
      stage: '1',
      length: 4,
      width: 3,
      depth: 2,

      state: this.woundState,
      parent_wound: true,

      child_wounds: Array.from({ length: getRandom(1, 5) }).map((e, i) => ({
        woundNo: (i + 1),
        wound_image: `https://picsum.photos/seed/${Math.random()}/200/200`,
        dos: new Date(),
        stage: '1',
        length: 4,
        width: 3,
        state: this.woundState,
        depth: 2,
      }))

    }));
  }
  ngOnChanges(changes: SimpleChanges) {
    if ('creationDate' in changes) {
      if(changes.creationDate.previousValue !== undefined){
        this.creationDate = changes.creationDate.currentValue 
        this.initWounds();
      }
      console.log('Input data changed:', changes.creationDate);
    }
  }
  async initWounds(emitwoundPhraseOutput = false, openCreateWoundMenu = false) {
    this.loader.startLoader(this.woundsLoaderId);
    let dateRange: any;
    if(this.creationDate) {
      dateRange = JSON.parse(JSON.stringify(this.creationDate));
      dateRange = {
        startDate: moment(dateRange.startDate),
        endDate: moment(dateRange.endDate)
      }
    }
    
    const { status, data } = await lastValueFrom(this.woundCareWidgetService.getWounds(this.patient._id, this.woundState, this.created_userType, this.facility_id, this.created_by, dateRange));
    if (status == 200) {
      this.sendAllWoundIds(data);
      this.woundsWithoutSort = JSON.parse(JSON.stringify(data));
      console.log(this.woundsWithoutSort);
      this.wounds = data
        .map(this._sortParentWithChildWounds)
        if(emitwoundPhraseOutput){
          this.checkallWoundAssessmentPhrase.emit({wounds: this.mapDataforwoundPhrases(data), callCountApi: true, openPopMenu: openCreateWoundMenu, checkAllPhraseKey: true});
        }else{
          if(openCreateWoundMenu){
            this.openMenuofLatestWound();
          }else{
            this.checkallWoundAssessmentPhrase.emit({wounds: this.mapDataforwoundPhrases(data), callCountApi: false, openPopMenu: openCreateWoundMenu, checkAllPhraseKey: false });
          }
        }
      // .map(this._identifyMostRecentWound)
    }
    this.loader.stopLoader(this.woundsLoaderId);


  }

  async hanldeCheckallWoundAssessmentPhrase($event){
    if($event.openPopMenu === true){
      await this.initWounds(true);
    }
  }
  filterWounds(wounds, woundState) {
    return wounds.filter(wound => {
      return wound.state === woundState

    })
  }

  private _sortWounds = (wounds, order = -1) => {
    return wounds.slice().sort((a, b) => {
      // normalize
      let aCreatedAt, bCreatedAt;
      if (a.createdAt.match(/^\d+\/\d+\/\d+$/m)) {
        // prase MM/DD/YYYY using moment
        aCreatedAt = moment(a.createdAt, 'MM/DD/YYYY').toDate()
      } else {
        aCreatedAt = new Date(a.createdAt)
      }
      if (b.createdAt.match(/^\d+\/\d+\/\d+$/m)) {
        bCreatedAt = moment(b.createdAt, 'MM/DD/YYYY').toDate()
      } else {
        bCreatedAt = new Date(b.createdAt)
      }

      if (order < 0) {
        return new Date(bCreatedAt).getTime() - new Date(aCreatedAt).getTime()
      } else {
        return new Date(aCreatedAt).getTime() - new Date(bCreatedAt).getTime()
      }
    });
  }
  private _sortParentWithChildWounds = (parentWithChildWounds) => {
    const { latest_child_wound, ...parentWound } = parentWithChildWounds;
    // flatten
    const [newParentWound, ...latestChildWound] = this._sortWounds([parentWound, ...parentWithChildWounds.latest_child_wound], -1);
    newParentWound.latest_child_wound = latestChildWound;
    // maintain parent wound state
    newParentWound.state = parentWound.state;
    return newParentWound;
  }
  private _identifyMostRecentWound(parentWithChildWounds) {
    const { latest_child_wound, ...parentWound } = parentWithChildWounds;
    if (latest_child_wound.length > 0) {
      latest_child_wound[0]._most_recent_wound = true;
    } else {
      parentWound._most_recent_wound = true;
    }
    parentWound.latest_child_wound = latest_child_wound;
    return parentWound;
  }
  removeWoundfromList(wound: any) {
    this.wounds = this.wounds.filter(wounds => {
      if (wounds.latest_child_wound.length > 0) {
        if (wounds.latest_child_wound[0]._id != wound._id) {
          return wounds;
        }
      } else {
        if (wounds._id != wound) {
          return wounds
        }
      }
    });
  }
  updateWoundfromNote(id, data, justUpdateObject = false) {
    let updatedData = false;
    this.wounds.forEach((wound) => {
      if (!wound.parent_wound) {
        if (wound._id === id) {
          for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
              if(key === 'extended_wound_model'){
                wound[key].forEach((ewObj) => {
                  if(ewObj.header_id.toString() === data[key].header_id.toString()){
                    ewObj.value = data[key].value;
                    return;
                  }
                });
              }else{
                const value = data[key];
                wound[key] = value;
              }
            }
          }
          return;
        }
      } else {
        wound.latest_child_wound.forEach(async (latestWound) => {
          if (latestWound._id === id) {
            updatedData = true;
            for (const key in data) {
              if (Object.prototype.hasOwnProperty.call(data, key)) {
                if (key === 'extended_wound_model') {
                  latestWound[key].forEach((ewObj) => {
                    if (ewObj.header_id.toString() === data[key].header_id.toString()) {
                      ewObj.value = data[key].value;
                      return;
                    }
                  });
                } else {
                  const value = data[key];
                  latestWound[key] = value;
                }
              }
            }
            if(this.woundCareWidgetItemComponent){
              this.woundCareWidgetItemComponent.updateDetailsfromNote(wound._id, data);
            }
            if(!justUpdateObject){
              await lastValueFrom(this.woundCareWidgetService.updateWoundData(wound._id, data))
            }
            if (wound.child_wounds.length > 0 && wound.child_wounds[0]._id === wound._id) {
              for (const key in data) {
                if (Object.prototype.hasOwnProperty.call(data, key)) {
                  if (key === 'extended_wound_model') {
                    wound.child_wounds[0][key].forEach((ewObj) => {
                      if (ewObj.header_id.toString() === data[key].header_id.toString()) {
                        ewObj.value = data[key].value;
                        return;
                      }
                    });
                  } else {
                    const value = data[key];
                    wound.child_wounds[0][key] = value;
                  }
                }
              }
            }
          }
        });
      }
    });
    if(justUpdateObject && !updatedData){
      this.wounds.forEach((wound) => {
        wound.child_wounds.forEach(async (childWound)=> {
          if (childWound._id === id) {
            if(this.woundCareWidgetItemComponent){
              for (const key in data) {
                if (Object.prototype.hasOwnProperty.call(data, key)) {
                  if(key === 'extended_wound_model'){
                    childWound[key].forEach((ewObj) => {
                      if(ewObj.header_id.toString() === data[key].header_id.toString()){
                        ewObj.value = data[key].value;
                        return;
                      }
                    });
                  }else{
                    const value = data[key];
                    childWound[key] = value;
                  }
                }
              }
            }
            if(wound._id === id){
              wound.latest_child_wound.forEach(async (latestWound) => {
                  for (const key in data) {
                    if (Object.prototype.hasOwnProperty.call(data, key)) {
                      if(key === 'extended_wound_model'){
                        latestWound[key].forEach((ewObj) => {
                          if(ewObj.header_id.toString() === data[key].header_id.toString()){
                            ewObj.value = data[key].value;
                            return;
                          }
                        });
                      }else{
                        const value = data[key];
                        latestWound[key] = value;
                      }
                    }
                  }
              })
              for (const key in data) {
                if (Object.prototype.hasOwnProperty.call(data, key)) {
                  if(key === 'extended_wound_model'){
                    wound[key].forEach((ewObj) => {
                      if(ewObj.header_id.toString() === data[key].header_id.toString()){
                        ewObj.value = data[key].value;
                        return;
                      }
                    });
                  }else{
                    const value = data[key];
                    wound[key] = value;
                  }
                }
              }
              if(this.woundCareWidgetItemComponent){
                this.woundCareWidgetItemComponent.updateDetailsfromNote(id,data);
              }
              console.log("Updating Parent wound");
              await lastValueFrom(this.woundCareWidgetService.updateWoundData(wound.parent_wound, data))
            }
          }
          return;
        });
      });
    }
  }
  updateAddendumWoundFromNote(id, data){
    this.woundCareWidgetItemComponent.updateAddendumWoundFromNote(id,data);
  }
  checkChildWoundExists(wound_id){
    console.log(wound_id);
    const woundCheck = this.wounds.findIndex((w)=> w._id.toString() === wound_id);
    if(woundCheck > -1){
      return false
    }else{
      return true;
    }
  }
  isWoundLocked(wound_id){
    let woundDetails = null;
    this.wounds.forEach((wound) => {
      if (!wound.parent_wound) {
        if (wound._id === wound_id) {
          woundDetails = wound;
        }
      } else {
        wound.latest_child_wound.forEach(async (latestWound) => {
          if (latestWound._id === wound_id) {
            woundDetails = latestWound;
          }
        })
      }
    });
    return true;
  }
  handleICDsSave(icds) {
    console.log('wound-care-widget-wound-list > handleICDsSave')
    this.onICDsSave.emit(icds);
  }
  handleWoundDetailsChange(data){
    this.wounds.forEach((wound) => {
      wound.latest_child_wound.forEach(async (latestWound) => {
        if (latestWound._id === data._id) {
          if (wound.child_wounds.length > 0 && wound.child_wounds[0]._id === wound._id) {
            for (const key in data) {
              if (Object.prototype.hasOwnProperty.call(data, key) && key !== "_id") {
                const value = data[key];
                wound[key] = value;
              }
            }
            for (const key in data) {
              if (Object.prototype.hasOwnProperty.call(data, key) && key !== "_id") {
                const value = data[key];
                wound.child_wounds[0][key] = value;
              }
            }
          }
        }
      });
    });
    this.woundDetailsChange.emit(data);
  }
  handleProcedureNoteDetailsChange(data) {
    this.procedureNoteDetailsChange.emit(data);
  }
  sendAllWoundIds(data){
    const IDS=data.map((e)=>{return e._id});
    this.allWoundIds.emit(IDS);
  }

  selectedWound: any;
  showWoundReport(wound) {
    if(this.selectedWound){
      if(wound._id == this.selectedWound._id){
        return;
      }
    }
    for(let woundObj of this.wounds) {
      if(woundObj.latest_child_wound.length > 0){
       if(woundObj.latest_child_wound[0]._id == wound._id) {
          woundObj.latest_child_wound[0].isSelected = true;
        }
        if(this.selectedWound) {
          if(this.selectedWound._id == woundObj.latest_child_wound[0]._id) {
            woundObj.latest_child_wound[0].isSelected = false;
          }
        } 
      }
      else {
        if(wound._id == woundObj._id) {
          woundObj.isSelected = true;
        }
        if(this.selectedWound) {
          if(this.selectedWound._id == woundObj._id) {
            woundObj.isSelected = false;
          }
        }
      }
      
      
    }
    this.selectedWound = wound;
    this.woundAnalyticReport.emit(wound);
  }
  refreshWoundsandCheckWoundsinNoteEditor(openPopMenu = false){
    if(!openPopMenu){
      this.initWounds(true, openPopMenu);
    }else{
      this.checkallWoundAssessmentPhrase.emit({length: this.wounds.length, callCountApi: true, openPopMenu: openPopMenu});
    }
  }
  getAllWounds(){
    return this.woundsWithoutSort;
  }
  openMenuofLatestWound(){
    this.woundCareWidgetItemComponent.showWoundDialog();
  }
  mapDataforwoundPhrases(data){
    const specificKeys = ['etiolgy','body_part','stage','direction','exposed_tissues','periwound','eschar','granulation','slough',
      'epithelial','infection_signs','exudate_type','exudate','exposed_structure','odor','pain','primary_treatment','secondary_dressing','change_frequency','pressure_reduction/off_loading','dressings','cleanse_wound_with','wound_status','length','width','depth','area',
      'volume','tunneling','tunneling_distance','tunneling_direction','under_mining','undermining_to','undermining_from','undermining_distance',
      'latest_child_wound', '_id', 'woundNo', 'extended_wound_model', 'populated_extended_model'
    ];
    let mappedData = [];
    data.forEach((wound)=>{
      const specificKeysObject = specificKeys.reduce((acc, key) => {
        if (wound.hasOwnProperty(key)) {
            if(['stage','tunneling','tunneling_distance','tunneling_direction','under_mining','undermining_to','undermining_from','undermining_distance','length', 'width','depth', 'area'].includes(key)){
              acc[key] = wound.latest_child_wound.length > 0 ? wound.latest_child_wound[0][key] : wound[key]
            }else{
              acc[key] = wound[key];
            }
        }
        return acc;
      }, {});
      if(wound.latest_child_wound.length > 0 && wound.latest_child_wound[0].is_clone){
        specificKeysObject['addendum_id'] = wound.latest_child_wound[0]._id
      }
      mappedData.push(specificKeysObject);
    });
    return mappedData;
  }
}
