<mat-tab-group *ngIf="patient">

    <mat-tab label="DN Notes"> 
        <ng-template matTabContent>
            <app-notes-list [excludeNoteId]="note._id" [patientId]="patient?._id" [providerId]="note?.provider?._id"></app-notes-list>
        </ng-template>
    </mat-tab>
    <mat-tab label="PCC Notes" *ngIf="patient.pcc_patientId"> 
        <ng-template matTabContent>
            <app-pcc-notes-list [patient]="patient"></app-pcc-notes-list>
        </ng-template>
    </mat-tab>
    <mat-tab label="PCC Note Types" *ngIf="patient.pcc_patientId&&false"> 
        <ng-template matTabContent>
            <app-pcc-note-types-list [note]="note" [patient]="patient"></app-pcc-note-types-list>
        </ng-template>
    </mat-tab>
    <mat-tab label="Documents" *ngIf="patient"> 
        <ng-template matTabContent>
            <app-patient-documents-list [patient]="patient"></app-patient-documents-list>
        </ng-template>
    </mat-tab>
</mat-tab-group>