<app-header></app-header>

<main>
  <div class="container flex flex-col gap-2">
    <div class="flex flex-row justify-between items-baseline gap-2">

        <button class="dn-button-secondary" mat-raised-button (click)="goBack()">
          Go Back</button>
          <app-input-with-dropdown
              class="flex"
              placeholder="Search Patient by Name"
              (inputChange)="applyFilter($event.target.value)"
              [dropdownActive]="filterDropdownActive"
              (onDropdownChange)="filterDropdownActive = $event"
              [value]="filterSearch">
              <app-filter-form
              category="patient"
              (onFilter)="handleFilter($event)"
              [filterData]="patientRoundingSheet?.patientList"
              ></app-filter-form>
              </app-input-with-dropdown>

            <mat-card style="padding-top:0;padding-bottom:0;height:42px">
              <mat-card-content>
                <mat-form-field>
                  <mat-label>Sort</mat-label>
                  <mat-select
                  [disabled]="!patientRoundingSheet"
                  name="filterSort"
                  [(ngModel)]="filterSort"
                  (ngModelChange)="handleSortFilter($event)">
                      <mat-option value="seen">Seen ({{getSeenPatientsCount()}})</mat-option>
                      <mat-option value="not_seen">Not Seen ({{getNotSeenPatientsCount()}})</mat-option>
                      <mat-option value="name">Name</mat-option>
                      <mat-option value="room_no">Room No</mat-option>
                      <mat-option value="new_patient">New Patient</mat-option>
                  </mat-select>
              </mat-form-field>
              </mat-card-content>
            </mat-card>

            <mat-card style="padding-top:0;padding-bottom:0;height:42px">
              <mat-card-content>
                <mat-form-field>
                    <mat-label>Add Patient to Census</mat-label>                                            
                    <input matInput type="text"  name="patientsByProvider" [formControl]="patientsByProviderControl"
                        [matAutocomplete]="patientAutoComplete">
                    <mat-autocomplete #patientAutoComplete="matAutocomplete" (optionSelected)="addPatientToCurrrentRoundingSheet($event)"
                        [displayWith]="displayPatientFn">
                        <mat-option *ngFor="let patient of filteredPatientsByProvider | async" [value]="patient">
                            <app-patient-name [patient]="patient"></app-patient-name>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
              </mat-card-content>
            </mat-card>
      </div>
      <div style="color:#FFF" class="flex justify-between">
        <div>Name: {{patientRoundingSheet?.name}}</div>
        <div>Total Patients: {{patientRoundingSheet?.patientList?.length}}</div>
        <div class="flex justify-center gap-1">
          <span>
            Facility:
          </span>
          <app-facility-title [facility]="patientRoundingSheet?.facility"></app-facility-title>
        </div>
      </div>
      <div style="position: relative;">
        <table class="table-row-hover roundingsheet-patient-list-table" mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="switch-status">
            <th mat-header-cell *matHeaderCellDef >Status</th>
            <td mat-cell *matCellDef="let element">
                  <mat-slide-toggle (change)="markPatientSeen(element, !isPatientSeen(element))" [checked]="isPatientSeen(element)" [class.isSeen]="isPatientSeen(element)"  [class.isNotSeen]="!isPatientSeen(element)"></mat-slide-toggle>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef > Name </th>
            <td mat-cell *matCellDef="let element" (click)="onRowClick($event,element.patient_id)">
                <span>
                  {{element.name}}
                </span>

            </td>
          </ng-container>

          <ng-container matColumnDef="charge-status">
            <th mat-header-cell *matHeaderCellDef ></th>
            <td mat-cell *matCellDef="let element">
              <div class="flex justify-end items-center gap-2">
                <img src="../../../assets/discharge.png" alt="discharge" style="width: 24px;height:24px;" *ngIf="element.getDischargeDate()">
                <img src="../../../assets/icons/amount-draft.png" alt="draft" style="width: 24px;height:24px;" *ngIf="hasChargeAndStatus(element, 'draft')">
                <img src="../../../assets/icons/amount-submit.png" alt="submit" style="width: 24px;height:24px;" *ngIf="hasChargeAndStatus(element, 'submit')">
                <mat-icon style="color: #34c759;font-weight:bold"  *ngIf="isPatientSeen(element)">done</mat-icon>

              <span [class]="'provider-date-tag ' + chargeStatusClass(element)"
              style="min-width:60px;min-height:24px;box-sizing:border-box;" *ngIf="formatProviderDateTag(element)"
              >
                {{formatProviderDateTag(element) || ''}}
              </span>
            </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="date_of_birth">
            <th mat-header-cell *matHeaderCellDef > DOB </th>
            <td mat-cell *matCellDef="let element"> {{element.date_of_birth | date: global.date_format}} </td>
          </ng-container>

          <ng-container matColumnDef="gender">
            <th mat-header-cell *matHeaderCellDef > Gender </th>
            <td mat-cell *matCellDef="let element"> {{element.gender}} </td>
          </ng-container>

          <ng-container matColumnDef="facility">
            <th mat-header-cell *matHeaderCellDef > Facility </th>
            <td mat-cell *matCellDef="let element">
              <app-facility-title [facility]="element.facility_id_ref.title"></app-facility-title>
            </td>
          </ng-container>
          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef> Room No </th>
            <td mat-cell *matCellDef="let element"> {{getPatientLocation(element)}}</td>
          </ng-container>
          <ng-container matColumnDef="admission_date">
            <th mat-header-cell *matHeaderCellDef > A/D </th>
            <td mat-cell *matCellDef="let element"> {{element?.pcc_payload?.admissionDate | date: 'M/d/yyyy'}}  </td>
          </ng-container>
          <ng-container matColumnDef="discharge_date">
            <th mat-header-cell *matHeaderCellDef > D/C </th>
        <td mat-cell *matCellDef="let element"> {{element.getDischargeDate() | date: 'M/d/yyyy'}}  </td>
          </ng-container>
          <ng-container matColumnDef="payer">
            <th mat-header-cell *matHeaderCellDef > Payor </th>
            <td mat-cell *matCellDef="let element"> {{getRecentPatientADTRecord(element)?.payerName}}  </td>
          </ng-container>
          <ng-container matColumnDef="comments_count">
            <th mat-header-cell *matHeaderCellDef> Comments </th>
            <td mat-cell *matCellDef="let element" style="padding-right:20px;">

              <a href="#" style="max-width: 200px;display:block;" (click)="$event.preventDefault(); showPatientCommentsDialog(element)">
                <ng-container *ngIf="element.patient_roundingsheet_comment">
                  {{element.patient_roundingsheet_comment}}
                </ng-container>
                <ng-container *ngIf="!element.patient_roundingsheet_comment">
                  --
                </ng-container>
              </a>

            </td>
          </ng-container>

          <ng-container matColumnDef="operations">
            <th mat-header-cell *matHeaderCellDef>Details</th>
            <ng-container *matCellDef="let element">
                <td mat-cell *ngIf="element.is_active == 'true'">
                  <div class="operation-buttons flex flex-row items-center gap-1">
                    <a
                    *ngIf="patientRoundingSheet"
                    [routerLink]="['/note-editor/new',
                        {
                          patient_id: element._id,
                          facility_id: patientRoundingSheet.facility._id,
                          dos: patientRoundingSheet.date,
                          census_id: patientRoundingSheet._id
                        }
                      ]" class="flex items-center justify-center">
                      <i class="icon icon-add"
                      matTooltip="Create Note" matTooltipPosition="above"></i>
                    </a>
                    <a 
                    href="#"
                    matTooltip="Add Charge"
                     style="color:#7f7e7e !important"
                    (click)="$event.preventDefault(); openCharges(element)"
                    matTooltipPosition="above">
                      <mat-icon>add</mat-icon>
                    </a>
                  <i *ngIf="element.patient_dictations_count > 0" class="icon icon-audio"
                  matTooltip="Audios" matTooltipPosition="above"
                  (click)="showAudioListDialog(element)"></i>


                  <i class="icon icon-delete"
                  matTooltip="Delete" matTooltipPosition="above"
                  (click)="handleRemovePatientClick(element.patient_id)"></i>
                  </div>
                </td>
            </ng-container>
          </ng-container>

          <ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
          </ng-container>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"

            [class.darkBlueBg]="getRowClass(row) === 'PRIMARY_DARK'"
            [class.darkGrayBg]="getRowClass(row) === 'DARK_GRAY'"

          ></tr>
        </table>
        <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

      </div>
    </div>
</main>
