import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {DoctorTimeShedule} from '../../../classes/doctor_time_schedule';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { DoctorProfileService } from 'src/app/services/Modules/doctorProfile.service';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/Modules/user.service';
@Component({
  selector: 'app-doctor-profile-time-schedule',
  templateUrl: './doctor-profile-time-schedule.component.html',
  styleUrls: ['./doctor-profile-time-schedule.component.css']
})
export class DoctorProfileTimeScheduleComponent implements OnInit {

  global=global;
  displayedColumns = [ 'day', 'start_time', 'end_time'];
  // removed: 'availablity',
  dataSource: MatTableDataSource<any>;

  model: DoctorTimeShedule;

  doctorTimeSheduleLoader = 'doctor-time-shedule-loader';
  constructor(private loader: NgxUiLoaderService, 
    private doctorProfileService:DoctorProfileService,
    private route: ActivatedRoute,
    private userService:UserService) { 

    this.model = new DoctorTimeShedule();


  }
  async ngOnInit() {
    const provider_id = this.route.snapshot.paramMap.get('provider_id');

    const filter = {
      _id: provider_id
    }
    const projection = {
      user_type: 1
    }
    const userResponse:any = await this.userService.getUser(filter, projection).toPromise();
    
    if(userResponse.status === 200) {
      this.initTimeSchedule(userResponse.data);
    }
  }

  initTableData() {
      const {user_id_ref, user_type, ...daysShedule} =  this.model;
      for(let day in daysShedule) {
        daysShedule[day] = daysShedule[day];
        daysShedule[day].day = day;
      }
      
      this.dataSource = new MatTableDataSource(Object.values(daysShedule));
  }

  
  initTimeSchedule(user) {
    this.model.user_id_ref = user._id;
    this.model.user_type = user.user_type;
    this.loader.startLoader(this.doctorTimeSheduleLoader);

    const filter = {
      user_id_ref: user._id
    }
    const projection = {
      user_id_ref:1,
      user_type:1,
      Monday  :1,
      Tuesday :1,
      Wednesday :1,
      Thursday  :1,
      Friday  :1,
      _id: 0
    }
    this.doctorProfileService.getTimeSchedule(filter,projection).subscribe((response:any) => {
      if(response.status === 200 && response.data) {
        this.model = response.data;
      } 
    }, (error) => {
      console.error('Error occured', error.toString());
      
    }, () => {
      this.loader.stopLoader(this.doctorTimeSheduleLoader);
      this.initTableData();
    })
  }
  handleSubmit(values) {
    this.loader.startLoader(this.doctorTimeSheduleLoader);
    
    this.doctorProfileService.saveTimeSchedule(this.model).subscribe((response:any) => {
    }, (error) => {
      console.error('Error occured', error.toString());
      
    }, () => {
      this.loader.stopLoader(this.doctorTimeSheduleLoader);
    })
  }

  isAvailable(day) {
    const available = this.model[day] ? !!this.model[day].availability : false;

    
    return available
  }
}
