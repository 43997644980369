<div class="flex justify-between items-center" style="margin-bottom: 5px;">
    <div></div>
    <div>
      <button mat-raised-button class="dn-button-primary" (click)="goToPreviousPage()"
        [disabled]="!pagination.hasPrevPage">
        Prev</button>
      <span>
        {{pagination.skip + doctors.length === 0 ? 0 : pagination.skip + 1 }} - {{pagination.skip + doctors.length}} out
        of {{pagination.totalRecords}}
      </span>
      <button mat-raised-button class="dn-button-primary" (click)="goToNextPage()"
        [disabled]="!pagination.hasNextPage">Next</button>
    </div>
  </div>
  
  <div class="flex justify-center items-center gap-5" style="background: #cedbdf; padding: 5px; border-radius: 4px;">
    <div class="flex justify-center items-center w-full">
      <mat-card style="padding: 0px;" class="flex-grow" style="display: flex; height: 45px;">
        <input type="text" class="search-field" placeholder="Search User by Name" name="title"
          [(ngModel)]="userNameRegExp" (ngModelChange)="searchDoctors()" />
        <mat-spinner *ngIf="hasDoctorsLoaded" style="margin-left: auto;" matSuffix [diameter]="18">
        </mat-spinner>
      </mat-card>
    </div>
    <button mat-raised-button class="dn-button-primary" (click)="exportUsersAsXLSX()">
      Export As XLSX
    </button>
    <mat-form-field>
      <mat-label>User Status</mat-label>
      <mat-select value="true" (selectionChange)="applyUserFilter($event)">
        <mat-option value="true">Active</mat-option>
        <mat-option value="false">Inactive</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div style="height: 3px;"></div>
  <div style="overflow-x: auto;" class="vertical-height">
  
    <table mat-table [dataSource]="dataSource">
  
      <ng-container matColumnDef="first_name">
        <th mat-header-cell *matHeaderCellDef style="width: 5%;"> First Name </th>
        <td mat-cell *matCellDef="let element" 
        [routerLink]="['../../doctor-profile', {provider_id: element._id, company_id : element.company_id }]"
          style="cursor: pointer;">
          <a>{{element._id !== currentCompany?.user_id?._id ?(element.first_name | titlecase):null}}</a>
          <b *ngIf="element._id === currentCompany?.user_id?._id">
            <a>{{element.first_name | titlecase}}</a>
          </b>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="last_name">
        <th mat-header-cell *matHeaderCellDef style="width: 5%;"> Last Name </th>
        <td mat-cell *matCellDef="let element" [routerLink]="['../../doctor-profile', {provider_id: element._id, company_id : element.company_id}]"
          style="cursor: pointer;">
          <a>{{element._id !== currentCompany?.user_id?._id ?(element.last_name | titlecase):null}}</a>
          <b *ngIf="element._id === currentCompany?.user_id?._id">
            <a>{{element.last_name | titlecase}}</a>
          </b>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element" style="width: 20%; overflow-wrap: anywhere;">
          <span *ngIf="element._id !== currentCompany?.user_id?._id">
            {{element.email | titlecase}}
  
          </span>
          <br>
          <small *ngIf="element.recentLogin==undefined && element._id !== currentCompany?.user_id?._id"
            style="color: gray; cursor: pointer;" (click)="ResendEmail(element._id)"><a>Resend Registration Email</a></small>
          <b *ngIf="element._id === currentCompany?.user_id?._id">
            {{element.email | titlecase}}
  
          </b>
          <br>
          <small *ngIf="element.recentLogin==undefined && element._id === currentCompany?.user_id?._id"
            style="color: gray; cursor: pointer;" (click)="ResendEmail(element._id)"><a>Resend Registration Email</a></small>
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="last_login">
        <th mat-header-cell *matHeaderCellDef style="width: 8%;"> Last Login</th>
        <td mat-cell *matCellDef="let element">
          {{element.recentLogin ?(element.recentLogin):null}}
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Active</th>
        <td mat-cell *matCellDef="let element" style="text-align-last: center;">
          <mat-checkbox class="top-margin" [checked]="element.is_activated == 'true'"
            [disabled]="element._id === currentCompany?.user_id?._id"
            (change)="changeUserActiveStatus(element, $event.checked)">
          </mat-checkbox>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="user-permissions">
        <th mat-header-cell *matHeaderCellDef>Permissions</th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field appearance="fill" [ngClass]="{
                'user-permissions-form-field': true,
                'permissions-selected': element.permissions.length > 0
              }">
            <mat-label>Select permissions...</mat-label>
            <mat-select multiple disableRipple [ngModel]="element.permissions"
              (selectionChange)="changeUserPermissions( element, $event )">
              <mat-select-trigger>
                {{ resolvePermission(element.permissions[0]) }}
                <span *ngIf="element.permissions.length > 1" class="example-additional-selection">
                  (+{{ element.permissions.length - 1 }} {{ element.permissions.length === 2 ? 'other' : 'others' }})
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let perm of permissionsList" [value]="perm.key">{{ perm.value }}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="speech-kit">
        <th mat-header-cell *matHeaderCellDef>SpeechKit</th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field style="width: 90px;">
            <mat-select (selectionChange)="changeDragonStatus(element._id,$event)" [ngModel]="element.enabled_speech_kit">
              <mat-option value="">
                None
              </mat-option>
              <mat-option value="dragon">
                Dragon
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="cognito">
        <th mat-header-cell *matHeaderCellDef>Cognito</th>
        <td mat-cell *matCellDef="let element" style="text-align-last: center;">
          <mat-checkbox class="tp-margin" [checked]="element.enableCognito == true" [disabled]="disabled"
            (change)="changeCognitoStatus(element, $event.checked)">
          </mat-checkbox>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="admin">
        <th mat-header-cell *matHeaderCellDef>Admin</th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field style="width: 90px;">
            <mat-select (selectionChange)="handleChangeAdminType(element._id,$event)" [ngModel]="element.admin"
              [disabled]="currentUser._id == element._id || element.admin_type == 'system_admin'">
              <mat-option value="none">
                None
              </mat-option>
              <mat-option value="full_admin">
                Full Admin
              </mat-option>
              <mat-option *ngIf="company_type !== 'Wound'" value="transcriber_admin">
                Transcriber Admin
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="user_type">
        <th mat-header-cell *matHeaderCellDef>User Type</th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field style="width: 100%;">
            <mat-label style="color: black;"> {{getRole(element.user_type)}}</mat-label>
            <mat-select (selectionChange)="handleChangeUserType(element._id,$event)" [ngModel]="usersFilter.user_type">
              <mat-option value="1">
                Provider
              </mat-option>
              <mat-option value="test_provider">
                Test Provider
              </mat-option>
              <mat-option  value="cnp" *ngIf="company_type == 'Wound'">
                CNP - Clinical Nursing Practitioner
              </mat-option>
              <mat-option value="2" *ngIf="company_type !== 'Wound'">
                Transcriber
              </mat-option>
              <mat-option value="nurse">
                Nurse
              </mat-option>
              <mat-option value="view_only_user">
                View Only User
              </mat-option>
            <mat-option [value]="global.USER_TYPE.SNF_WC_NURSE">
                SNF WC Nurse
            </mat-option>
            <mat-option [value]="global.USER_TYPE.WOUND_NURSE">
                Wound Nurse
            </mat-option>
              <mat-option value="6">
                Biller
              </mat-option>
              <mat-option value="medical_assistant">
                Medical Assistant
              </mat-option>
              <mat-option value="business_officer" *ngIf="isOptionVisibleForCompany('business_officer')">
                Business Officer
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="companies">
        <th mat-header-cell *matHeaderCellDef>Practice</th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field style="color: black !important" class="flex-grow">
            <input type="text" aria-label="company" matInput [formControl]="companyControl"
              [matAutocomplete]="companyAutoControl">
            <mat-autocomplete #companyAutoControl="matAutocomplete" [panelWidth]="'auto'">
              <mat-option *ngFor="let company of filteredCompanies | async" [value]="company.name ?company.name:''"
                (click)="onCompanyChange(element , company._id);">
                {{company.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container  matColumnDef="enable_email_notfication">
        <th mat-header-cell *matHeaderCellDef>Email Notification</th>
        <td mat-cell *matCellDef="let element" style="text-align-last: center;" >
          <mat-checkbox class="tp-margin" 
            *ngIf="element.admin_type === 'full_admin' || element.admin_type === 'system_admin'"
            [checked]="element.email_notification_status === true"
            (change)="emailNotificationStatus(element, $event.checked)">
          </mat-checkbox>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="operations">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
  
  </div>
  