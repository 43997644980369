<app-header></app-header>
<div class="container" [loaderId]="loaderId" ngxUiLoaderBlurred>
  <div class="flex justify-between items-center gap-2"
  style="background: white;align-items: center !important; padding-left: 10px;"> 

  <div>
    <mat-form-field appearance="fill" style="height: 43px;background-color: white;">
      <mat-label>Filter by Document</mat-label>
      <input matInput type="text"   (inputChange)="applyFilterNew($event.target.value)">
    </mat-form-field>
  </div>

  <div>
    <mat-form-field appearance="fill" style="height: 43px;background-color: white;">
      <mat-label>Filter by Provider</mat-label>
      <input matInput type="text"  (inputChange)="applyFilterProviderNew($event.target.value)">
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="patient-date"
        style="background: white; height: 39px; width: -webkit-fill-available; padding-top: 3px;padding-left: 6px;border-radius: 4px;">
        <mat-label>Filter by DOS</mat-label>
        <input matInput [matDatepicker]="pick" name="selectedDOS" (dateChange)="dateChangeDOSNew($event)"
          [value]="selectedDOS" [ngModel]="selectedDOS" />
        <mat-datepicker-toggle matSuffix [for]="pick"></mat-datepicker-toggle>
        <mat-datepicker #pick></mat-datepicker>
      </mat-form-field>
   
  </div>

  <div>
    <mat-form-field appearance="fill" style="height: 43px;">
      <mat-label>Filter by Vendor</mat-label>
      <mat-select [(ngModel)]="selectedCompany" name="status">
        <mat-option (click)="onChangeVendor()">--none--</mat-option>
        <mat-option *ngFor="let company of companies" [value]="company._id"
          (click)="onItemSelectNew(company.abbreviation);">
          {{company.abbreviation}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div>
    <button class="dn-button-primary" mat-raised-button (click)="filterData()">Apply Fliter</button>
    <button class="dn-button-secondary" mat-raised-button (click)="clearfilterData()" style="margin-left: 5px; margin-right: 5px">Clear
      Fliter</button>
  </div>

</div>
  <div style="height: 5px;"></div>
  <div class="flex flex-row justify-center items-center gap-2 table-nav">

    <button mat-raised-button class="dn-button-primary"  [disabled] = "!pagination.hasPrevPage"
      (click)="handlePrevClick()">Prev</button>

      <span style="background: white; border-radius: 2px; font-size: 12px;padding: 5px;"> {{getCountOfDocuments()}}</span>

    <button mat-raised-button class="dn-button-primary"  [disabled] = "!pagination.hasNextPage"
      (click)="handleNextClick()">Next</button>
  </div>

  <div style="height: 5px;"></div>
  <div *ngIf="dataSource && loading===false" style="overflow-y: auto;">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="sr">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container> SR# </th>
        <td mat-cell *matCellDef="let element;  let i = index"> {{i+1}} </td>
      </ng-container>

      <ng-container matColumnDef="document_title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container> Document Title </th>
        <td mat-cell *matCellDef="let element"> {{getFolderTitle(element)}} </td>
      </ng-container>

      <ng-container matColumnDef="insurance_company">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container> Company </th>
        <td mat-cell *matCellDef="let element"> {{element.insurance_comapny}} </td>
      </ng-container>

      <ng-container matColumnDef="provider">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container style="width: 190px"> Provider </th>
        <td mat-cell *matCellDef="let element" style="width: 190px; text-align: left"> {{getProvider(element)}} </td>
      </ng-container>
      <ng-container matColumnDef="uploading_date" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container style="width: 155px"> Uploading Date </th>
        <td mat-cell *matCellDef="let element" style="width: 155px; text-align: left"> {{element.uploading_date  | date:'short'}} </td>
      </ng-container>

      <ng-container matColumnDef="pages">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container style="padding-right: 5px;"> Pages </th>
        <td mat-cell *matCellDef="let element"> {{element.total_pages}}</td>
      </ng-container>
      <ng-container matColumnDef="processing_date" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container style=" width: 155px"> Processing Date
        </th>
        <td mat-cell *matCellDef="let element" style="width: 155px; text-align: left"> {{element.processing_date | date:'short'}}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container > Status </th>
        <td mat-cell *matCellDef="let element"> {{getStatus(element)}}</td>
      </ng-container>

      <ng-container matColumnDef="operations">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container style="width: 155px;"></th>
        <ng-container *matCellDef="let element">
          <td mat-cell  class="buttons-div"  style="text-align: left;width: 155px;">
            <div *ngIf="element.status === 'completed'" class="flex flex-row items-center justify-center space-x-15 operation-buttons buttons-div">
                <i class="icon icon-edit" matTooltip="Edit" matTooltipPosition="above"
                            (click)="onDetailClick(element)"></i>
                <i matTooltip="Preview Report" matTooltipPosition="above" (click)="onViewReportClick(element)"
                                   class="icon icon-view"></i>
                <i *ngIf="element.locked" style="margin-right: 10px;  align-self: center;" class="icon icon-lock" matTooltip="locked"
                                   matTooltipPosition="above"  ></i>
            </div>
            <div *ngIf="element.status === 'in progress'" class="operation-buttons buttons-div flex flex-row items-center justify-center">
              <div style="color: #1f98d7;">IN PROGRESS</div>
            </div>
            <div *ngIf="element.status === 'error'" class="operation-buttons buttons-div flex flex-row items-center justify-center gap-5">
              <div style="color: red;">ERROR</div>
            </div>
          </td>
        </ng-container>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
  </div>

</div>
<ngx-ui-loader [hasProgressBar]="true" [loaderId]="loaderId"></ngx-ui-loader>