import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { lastValueFrom } from 'rxjs';
import { BillerDashboardService } from '../biller-dashboard.service';

@Component({
  selector: 'app-charge-comment-dialog',
  templateUrl: './charge-comment-dialog.component.html',
  styleUrls: ['./charge-comment-dialog.component.css']
})
export class ChargeCommentDialogComponent implements OnInit {

  charge: any;
  loaderId = 'app-charge-comment-dialog';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChargeCommentDialogComponent>,
    private _billerDashboardService: BillerDashboardService,
    private _loader: NgxUiLoaderService,
    private _toastr: ToastrService,
  ) {
    this.charge = data;
  }

  ngOnInit(): void {
  }

  async updateCharge() {
    this._loader.startLoader(this.loaderId);
    const updateQuery = {
      $set: {
        note: this.charge.note
      }
    }
    const response: any = await lastValueFrom(this._billerDashboardService.updateCharge(this.charge._id, updateQuery));
    if (response.status === 200) {
      this.dialogRef.close(response.data);
      this._loader.stopLoader(this.loaderId);
    }
    else {
      this._toastr.error(response.message, 'Error');
      this._loader.stopLoader(this.loaderId);
    }
  }

}
