import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import * as global from '../global';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import {API_URL} from "../../../environments/api-url";

@Injectable({
    providedIn: 'root',
})
export class AdminNotificationsService {

    currentUser: any;
    constructor(private httpclient: HttpClient,
                private _authService: AuthService) {
        this.currentUser = this._authService.currentUser;
    }

    getUserTypes() {

        var item ={
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type
        };

        return this.httpclient.post(global.url + API_URL.USER.getUserTypes, {details: item}).pipe();
    }
    getAllNotificationOperations() {

        var item ={
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type
        };

        return this.httpclient
            .post(global.url + API_URL.ROUTES.getAllNotificationOperations, {details: item}).pipe();
    }

    updateNotificationOperation(notificationOperation) {

        var item ={
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,

            notificationOperation
        };

        return this.httpclient
            .post(global.url + API_URL.ROUTES.updateNotificationOperation, {details: item}).pipe();
    }
    addNotes(note) {
        const details = {
            ...this._authService.authObject,
            createdBy : this._authService.currentUser._id,
            note 
        }
        return this.httpclient
            .post(global.url + API_URL.RELEASE.addNotes, {details: details}).pipe();
      }
}
