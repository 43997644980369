import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { PCCService } from 'src/app/services/pcc.service';
import { PatientListService } from '../patient-list/patient-list-service';

@Component({
  selector: 'app-patient-diagnosis-dialog',
  templateUrl: './patient-diagnosis-dialog.component.html',
  styleUrls: ['./patient-diagnosis-dialog.component.css']
})
export class PatientDiagnosisDialogComponent implements OnInit {

  currentUser: any;
  patient: any;

  constructor(
    private _pccService: PCCService,
    private _authService: AuthService,
    private _patientService: PatientListService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.currentUser = this._authService.currentUser;
  }

  ngOnInit(): void {
    this.getPccPatient();
  }
  async getPccPatient() {
    this.currentUser = this._authService.currentUser;
    const patient_id = this.data;
    const userInfo = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      patient_id
    };
    console.log("userInfo", userInfo);
    const patientResponse: any = await this._patientService.getPatient(userInfo).toPromise();
    if (patientResponse.status === 200) {
      console.log("patientResponse", patientResponse.data.patient);
      this.patient = patientResponse.data.patient;
    }
  }
}
