<h3 style="font-weight: bold;text-align: center;">P360 Response</h3>
 <span *ngIf="haveErrors">
    <div style="width: 100%; max-height: 30% ; background: white; overflow: auto;" *ngIf="haveErrors"> 
        <div class="flex gap-2">
            <mat-list class="flex-grow" style="overflow: auto;height: 400px;" >
                <ng-container >
                    <mat-list-item class="auditory-history-item" > 
                        <div  mat-line >
                            <div style="place-content: space-between;" class="flex gap-2">>
                                <span>
                                    <p> MIP Uploaded Date:  </p>
                                </span>
                                <p>
                                    {{getDate()}}
                                </p>
                            </div>
                        </div>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </ng-container>
                <ng-container *ngFor="let error of data.error_string">
                    <mat-list-item class="auditory-history-item" > 
                        <div  mat-line >
                            <div class="flex items-center gap-2">
                                <p class="flex items-center">
                                    <ng-container>
                                        {{error}}
                                    </ng-container>
                                </p>  
                            </div>
                        </div>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </ng-container> 
            </mat-list>
        </div>  
    </div>
</span>
<span *ngIf="!haveErrors">
    <div style="width: 100%; max-height: 30% ; background: white; overflow: auto;">
        <div class="flex gap-2">
            <mat-list class="flex-grow" style="overflow: auto;height: 400px;" >
                <ng-container >
                    <mat-list-item class="auditory-history-item" > 
                        <div  mat-line >
                            <div style="place-content: space-between;" class="flex gap-2">
                                <span>
                                    <p> MIP Uploaded Date:  </p>
                                </span> 
                            </div>
                        </div>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </ng-container> 
            </mat-list>
        </div>
    </div> 
</span>


<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>