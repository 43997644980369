<form #form="ngForm" (submit)="handleSubmit(form)">
    <div class="flex flex-col">
        <div class="flex flex-grow gap-2">
            <mat-form-field class="flex-grow" appearance="fill">
                <mat-label>Title</mat-label>
                <input matInput placeholder="Enter Title (Optional)" [(ngModel)]="newCPTmodel.title" name="title"
                    (keypress)="keyPressAlphanumeric($event)" />
            </mat-form-field>
            <mat-form-field class="flex-grow" appearance="fill">
                <mat-label>Code</mat-label>
                <input matInput placeholder="Enter Code" [(ngModel)]="newCPTmodel.code" #newcpt_code="ngModel" name="code"
                (keypress)="keyPressNumbers($event)" required />
                <mat-error *ngIf="newcpt_code.invalid">
                    Enter Code
                </mat-error>
            </mat-form-field>
            <mat-form-field class="flex-grow" appearance="fill">
                <mat-label>Concept Id</mat-label>
                <input matInput placeholder="Enter Concept Id (Optional)" [(ngModel)]="newCPTmodel.conceptId" name="concept_code"
                (keypress)="keyPressNumbers($event)" />
            </mat-form-field>
        </div>
        <div class="flex flex-grow gap-2">
            <mat-form-field class="w-1/3" appearance="fill">
                <mat-label>Clinical Id</mat-label>
                <input matInput placeholder="Enter Clinical Id (Optional)" [(ngModel)]="newCPTmodel.clinicianDescriptorId" name="clinical_code"
                (keypress)="keyPressNumbers($event)" />
            </mat-form-field>
            <mat-form-field class="flex-grow" appearance="fill">
                <mat-label>Description</mat-label>
                <input matInput placeholder="Enter Description" [(ngModel)]="newCPTmodel.description" #newcpt_description="ngModel" name="description"
                (keypress)="keyPressAlphanumeric($event)" required />
                <mat-error *ngIf="newcpt_description.invalid">
                    Enter Description
                </mat-error>
            </mat-form-field>
        </div>
        <div class="flex justify-end gap-5">
            <button type="button" mat-raised-button class="dn-button-secondary" (click)="clearDetails()">
                Clear Details
            </button>
            <button mat-raised-button class="dn-button-primary" type="submit" [disabled]="form.invalid">
                Add New Code
            </button>
        </div>
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</form>