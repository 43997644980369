import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { lastValueFrom } from 'rxjs';
import { WoundCareWidgetService } from '../wound-care-widget.service';
import { getFieldName } from '../wound-utils';

@Component({
  selector: 'app-set-wound-stage-dialog',
  templateUrl: './set-wound-stage-dialog.component.html',
  styleUrls: ['./set-wound-stage-dialog.component.css']
})
export class SetWoundStageDialogComponent implements OnInit {
   loaderId = 'set-wound-stage-dialog-loader';
    provider;
    patient;
    wound;
    dataset:any[] = [];
    selected:any = [];

    selectedDataset = {
      // etiolgy: '',
      // body_part: '',
      stage: '',
      // body_side: '',
      // state: '',
      // direction: '',
      // wound_recognition_date: null,
      // wound_recognition_date_type: ''
    }

    laterality = [
      'R',
      'L',
      'BL'
    ]
    constructor(
        private loader: NgxUiLoaderService, 
        public dialogRef: MatDialogRef<SetWoundStageDialogComponent>, 
        @Inject(MAT_DIALOG_DATA)public data: any, private woundCareWidgetService: WoundCareWidgetService,
      ) {
      if(this.data) {
        this.provider = data.provider;
        this.patient = data.patient;
        this.wound = data.wound;
        for (const key in this.selectedDataset) {
          if (Object.prototype.hasOwnProperty.call(this.selectedDataset, key)) {
            this.selectedDataset[key] = this.wound[key];
          }
        }
        
        // if(!this.selectedDataset.wound_recognition_date) {
        //   if(this.patient.pcc_payload) {
        //     this.selectedDataset.wound_recognition_date = new Date(this.patient.pcc_payload.admissionDate);
        //   } else {
        //     this.selectedDataset.wound_recognition_date = new Date();
        //   }
        // }
      }
   }

  async ngOnInit() {
    this.dialogRef.beforeClosed().subscribe(async () => {
      this.updateWoundData();
    });
    this.loader.startLoader(this.loaderId);
    const loaderInstance = this.loader.getLoader(this.loaderId);
    console.log(loaderInstance)
    const {status, data} = await lastValueFrom(this.woundCareWidgetService.getAllDataset(this.provider.company_id));
    if(status == 200) {
      this.dataset = data.filter(column => this.getFieldName(column.header) === 'stage');
    }
    this.loader.stopLoader(this.loaderId);
  }

  displayItem(item) {
    switch (item.data_type) {
      case 'Text':
        return item.value;
    
      case 'Dropdown':
        return 
    }
  }

  toggleSelect(data) {
    if(this.selected.indexOf(data) === -1) {
      this.selected.push(data);
    } else {
      const i = this.selected.indexOf(data);
      this.selected.splice(i,1);
    }
  }
  isSelected(data) {
    const i = this.selected.indexOf(data);
    return i > -1;
  }

  isFieldValid(header) {
    const fieldName = this.getFieldName(header);
    return !!this.selectedDataset[fieldName];
  }

  isFieldValueSelected(value, header) {
    if(typeof value === 'string') value = value.trim();
    const fieldName = this.getFieldName(header);
    console.log({
      header,
      fieldName, 
      value
    })
    if(fieldName === 'direction') {
      let values = this.selectedDataset[fieldName]?.split(', ') || [];
      const valueIndex = values.indexOf(value);
      return valueIndex >= 0
    } else {
      return this.selectedDataset[fieldName] === value;
    }
  }
  selectFieldValue(value, header) {
    const fieldName = this.getFieldName(header);
    if(typeof value === 'string') value = value.trim();
    if(fieldName === 'etiolgy') {
      
    }
    if(fieldName === 'direction') {
      let values = this.selectedDataset[fieldName]?.split(', ') || [];
      const valueIndex = values.indexOf(value);
      if(valueIndex >= 0) {
        values.splice(valueIndex, 1);
      } else {
        values.push(value);
      }
      let commaSeperated:any = Array.from(new Set(values.filter(e => !!e)));
      commaSeperated = commaSeperated.length === 1 ? commaSeperated[0] : commaSeperated.join(', ');
      this.selectedDataset[fieldName] = commaSeperated
    } else {
      this.selectedDataset[fieldName] = value;

      if(fieldName === 'wound_recognition_date_type' && this.selectedDataset[fieldName] === 'present_on_admission') {
        this.selectedDataset['wound_recognition_date'] = this.patient?.pcc_payload?.admissionDate;
      }
    }
  }
  handleCustomTextChange(value, header) {
    const fieldName = this.getFieldName(header);
    if(fieldName === 'direction') {
      const fieldValues = value.split(', ').map(e => e.trim()).filter(e => !!e);
      const prevValues = this.selectedDataset[fieldName].split(', ').map(e => e.trim()).filter(e => !!e);
      for (const value of fieldValues) {
        if(prevValues.indexOf(value) === -1) {
          prevValues.push(value)
        }
      }
      this.selectedDataset[fieldName] = prevValues.map(e => e.trim()).filter(e => !!e).join(', ');
    } else {
      this.selectedDataset[fieldName] = value;
    }
  }

  getFieldName(header) {
    return getFieldName(header)
  }

  getCustomTextValue(header) {
    const fieldName = this.getFieldName(header);
    const value = this.selectedDataset[fieldName] || '';

    const column = this.dataset.find(column => column.header === header);
    if(fieldName === 'direction') {
      const newValues = value.split(', ');
      for (const data of column.rows) {
        const index = newValues.findIndex(v => v === data.value.trim());
        if(index >= 0) {
          newValues.splice(index, 1);
        }
      }
      return newValues.filter(v => !!v).join(', ');
    } else {
      for (const data of column.rows) {
        if(data.value === value) {
          return '';
        }      
      }
    }
    return value;
  }
  allFieldsValid() {
    return [this.selectedDataset['wound_recognition_date_type'], ...Object.values(this.selectedDataset)].every(field => !!field)
  }
  async updateWoundData() {
    const {status, data} = await lastValueFrom(this.woundCareWidgetService.updateWoundData(this.wound._id, this.selectedDataset));
    if(status == 200) {
      for (const key in this.selectedDataset) {
        if (Object.prototype.hasOwnProperty.call(this.selectedDataset, key)) {
          this.wound[key] = this.selectedDataset[key];
        }
      }
    }
  }
  async updateWoundDataAndClose() {
    // await this.updateWoundData();
    this.dialogRef.close(this.selectedDataset);
  }
}

function union_arrays (x, y) {
  var obj = {};
  for (var i = x.length-1; i >= 0; -- i)
     obj[x[i]] = x[i];
  for (var i = y.length-1; i >= 0; -- i)
     obj[y[i]] = y[i];
  var res = []
  for (var k in obj) {
    if (obj.hasOwnProperty(k))  // <-- optional
      res.push(obj[k]);
  }
  return res;
}
