import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../../includes/global';
import {API_URL} from "../../../environments/api-url";


@Injectable({
    providedIn: 'root',
})
export class NursingService {
    currentUser: any;
    
    constructor(private httpclient: HttpClient,
        private _authService: AuthService,
        ) {
        this.currentUser = this._authService.currentUser;
    }

    getAssociatedFacilities(){
        let data = {
            nurse_id: this.currentUser._id,
            company_id: this.currentUser.company_id
        }
        return this.httpclient.post(global.url + API_URL.NURSE.getAssociatedFacilities, {details: data}).pipe();
    }
    getAllProviderNotes(facility_id, doctor_id, from_date, to_date, status){
        let data = {
            auth_key: this.currentUser.auth_key,
            facility_id,
            doctor_id,
            from_date,
            to_date,
            status
        }
        return this.httpclient.post(global.url + API_URL.NURSE.getAllProviderNotes, {details: data}).pipe();  
    }
}