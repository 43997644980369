import { Component, DoCheck, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Toast, ToastrService } from 'ngx-toastr';
import { CensusPatientListService } from 'src/app/includes/census-patient-list/census-patient-list.service';
import { PatientRoundingSheetService } from 'src/app/includes/patient-rounding-sheets/patient-rounding-sheet.service';
import { AuthService } from 'src/app/services/auth.service';
import { MipsService } from 'src/app/services/Modules/mips.service';

@Component({
  selector: 'app-biller-note',
  templateUrl: './biller-note.component.html',
  styleUrls: ['./biller-note.component.css']
})
export class BillerNoteComponent implements OnInit, OnDestroy, DoCheck {
  billerNote: any = '';
  currentUser: any;
  model = {
    owner_id: '',
    patient_id: '',
    rounding_sheet_id: '',
    icd_id: [],
    cpt_id: [],
    billing_note: '',
    note: '',
    status: '',
    visit_date: '',
    facility_id: '',
    measure: false,
    charge_id: '',
    cpt_modifiers: [],
    // coSignedProvider: ''
  };
  @Output() reset = new EventEmitter<boolean>();
  // @Input() comment;
  // @Output() billerNoteData = new EventEmitter<any>();
  @Input('note') note;
  @Input('charge') charge; 
  @Output('onChargeAdd') onChargeAdd = new EventEmitter<any>();

  subscriptions: any[] = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private censusPatientListService: CensusPatientListService,
    private patientRoundingSheetService: PatientRoundingSheetService,
    private toastr: ToastrService,
    private mipsService: MipsService
  ) { 
    
    this.currentUser = this.authService.currentUser;

  }
  ngOnDestroy(): void {
    if (this.subscriptions) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }
  ngDoCheck(){
    this.censusPatientListService.castCptModifiersForCharge.subscribe(cpt_modifier => {
      this.model.cpt_modifiers = cpt_modifier;
    })
  }

  ngOnInit(): void {
    this.subscriptions.push(this.censusPatientListService.castReportMeasure.subscribe(report => {
      if (report) {
        this.model.measure = report;
      }
      else {
        this.model.measure = false;
      }
    }));
    this.subscriptions.push(this.censusPatientListService.castIcdsForCharge.subscribe(icds => {
      this.model.icd_id = icds;
    }));
    this.subscriptions.push(this.censusPatientListService.castCptsForCharge.subscribe(cpts => {
      this.model.cpt_id = cpts;
    }));
    this.subscriptions.push(this.censusPatientListService.castCptModifiersForCharge.subscribe(cpt_modifier => {
      this.model.cpt_modifiers = cpt_modifier;
    }));
    this.subscriptions.push(this.censusPatientListService.castBillerComments.subscribe(data => {
      this.model.note = data
    }));
    this.subscriptions.push(this.censusPatientListService.chargeData.subscribe(data => {
      this.model.patient_id = data?.patient_id;
      this.model.visit_date = data?.dos;
      this.model.rounding_sheet_id = data?.census_id;
      this.model.facility_id = data?.facility_id;
    }));
  }
  async addCharge(statuss = 'submit') {
    this.model.status = statuss;
    this.model.owner_id = this.authService.currentUser._id
    if (this.model.icd_id) {
      if (this.model.icd_id.length <= 0) {
        this.toastr.error("Select Diagnosis")
        return;
      }
    }
    else {
      this.toastr.error("Select Diagnosis")
      return;
    }
    if (this.model.cpt_id) {
      if (this.model.cpt_id.length <= 0) {
        this.toastr.error("Select Charges")
        return;
      }
    }
    else {
      this.toastr.error("Select Charges")
      return;
    }
    if (this.note) {
      this.model.visit_date = this.note.dates.service;
      this.model.rounding_sheet_id = this.note.rounding_sheet_id;
      this.model.patient_id = this.note.patient._id;
      this.model.facility_id = this.note.facility._id;
    }
    if (this.charge) {
      this.model.charge_id = this.charge._id
    }
    this.censusPatientListService.castCptModifiersForCharge.subscribe(cpt_modifier => {
      
      this.model.cpt_modifiers = cpt_modifier || this.charge.cpt_modifiers;
    })
    // if(this.note.coSign_status.coSigned_by){
    //   this.model.coSignedProvider = this.note.coSign_status.coSigned_by;
    // }
    this.reset.emit(true);
    const res: any = await this.patientRoundingSheetService.addCharge(this.model).toPromise();
    if (res.status == 200) {
      this.toastr.success('Charge created', 'Success');
      this.censusPatientListService.setCPTsForCharge([]);
      this.censusPatientListService.setBillerComments('');
      this.censusPatientListService.setICDsForCharge([]);
      this.model.note = '';
      this.model.measure = false;
      this.reset.emit(true);
      
      this.onChargeAdd.emit(res.data);
      const [charge] = res.data.array;
      this.subscriptions.push(this.mipsService.castQuestionnaireResults.subscribe((questionnaireResults: any) => {
        // const {measure, answer} = data;
        
        if (Array.isArray(questionnaireResults) && questionnaireResults.length > 0) {
          this.mipsService.saveQuestionnareResults(questionnaireResults.map(r => ({ measure_id: r.measure._id, answer: r.answer, matched: r.measure.matched })), charge._id, this.currentUser.company_id)
            .toPromise();
        }
      }))
      // this.router.navigate([`/census/${this.params.rs_id}`]);
    }

  }

  disableButton(){
    if(this.model?.status == 'submit' || this.charge?.status == "submit"){ 
        return true;
    }
    else if(this.model.icd_id.length <= 0 || this.model.cpt_id.length <= 0){  
        return true
    }
    else { 
        return false;
    }
}
  cancel() {
    this.censusPatientListService.setCPTsForCharge([]);
    this.censusPatientListService.setICDsForCharge([]);
    this.censusPatientListService.setReportMeasure(false);
    this.model.note = '';
    this.model.measure = false;
    this.reset.emit(true);
    // this.router.navigate([`/census/${this.params.rs_id}`]);
  }
  saveData() {
    this.model.status = 'draft'
    this.addCharge(this.model.status)
  }
  // changeBillerNote(){
  //   console.log("in billerNote:", this.model.note);
  //   this.billerNoteData.emit(this.model.note);
  // }

}
