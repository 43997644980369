import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { USER_TYPE } from '../../../../includes/global'
import { MatTableDataSource } from '@angular/material/table';
import { FacilityService } from 'src/app/services/facility.service';
import { UserService } from 'src/app/services/Modules/user.service';

@Component({
  selector: 'app-ime-provider-association',
  templateUrl: './ime-provider-association.component.html',
  styleUrls: ['./ime-provider-association.component.css']
})
export class ImeProviderAssociationComponent implements OnInit {

  public response: any;
  public dataSource: any;
  public imes: any = [];
  providers: any = [];
  providersName: any = [];
  selectedProviders: any = [];
  providerTranscriber: Boolean = true;
  providerBiller: Boolean = false;
  providerDropdownSettings: any = {};

  displayedColumns: string[] = ['ime', 'provider'];
  @ViewChild(MatSort) sort: MatSort;

  params: {
    id: string
  }

  loaderId = 'facility-provider-association-loader';

  constructor(
    public companyService: CompanyService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService,
    private userService: UserService,
  ) {
    this.providerDropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'full_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true,
    };
    this.params = {
      id: this.route.parent.parent.snapshot.params.id
    };
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId);

    await this.initProviders();
    await this.initIMEs();


    this.loader.stopLoader(this.loaderId);

  }

  initIMEs = async () => {
    const company_id = this.params.id;

    const filter = {
      is_active: true,
      company_id
    }
    const projection = {
      'title': 1,
      'company_id': 1,
      'user_ids': 1,
    }

    const imesResponse: any = await this.companyService.getPayors(filter, projection).toPromise();


    if (imesResponse.status === 200) {
      this.imes = imesResponse.data.array;
      for (const ime of this.imes) {
        ime.user_ids = ime.user_ids
          .filter((facilityProvider) => {
            if (typeof facilityProvider === 'string') {
              return this.providers.find(provider => provider._id === facilityProvider);
            }
            return true;
          }).map((imeProvider) => {
            if (typeof imeProvider === 'string') {
              return this.providers.find(provider => provider._id === imeProvider);
            } else {
              return imeProvider;
            }
          })

      }
      this.dataSource = new MatTableDataSource(this.imes);
    }
  }

  initProviders = async () => {
    const company_id = this.params.id;

    const filter = {
      is_activated: 'true',
      account_type: {$ne: 'company'},
      user_type: USER_TYPE.DOCTOR,
      company_id
    }

    const projection = {
      'first_name': 1,
      'last_name': 1,
      'title': 1,
    }

    const companyUsersResponse: any = await this.userService.getUsers(filter, projection).toPromise();


    if (companyUsersResponse.status === 200) {
      this.providers = companyUsersResponse.data.map(p => Object.assign(p, {
        full_name: `${p.first_name} ${p.last_name}, ${p.title}`
      }));
    }
  }
  applyFilter(filterValue: string) {
    let filter = filterValue.trim().toLowerCase();
    if (filter) {
      let filterVal = [];
      this.imes.forEach(ime => {
        if (ime.title.trim().toLowerCase().includes(filter)) {
          filterVal.push(ime)
          this.dataSource = new MatTableDataSource(filterVal);
        }
      });
    }
    else {
      this.dataSource = this.imes;
    }
  }

  // biller-provider 
  onProviderSelect(provider, ime) {
    this.updatePayorUsers(ime);
  }
  onSelectAllProvider(providers, ime) {
    console.log({
      providers,
       ime
    });

    this.updatePayorUsers(ime);
  }
  onDeSelectAllProvider(providers, ime) {
    console.log({
      providers,
      facility: ime
    });

    this.updatePayorUsers(ime);
  }
  onProviderDeSelect(provider, ime) {
    this.updatePayorUsers(ime);
  }

  updatePayorUsers(ime) {
    const { user_ids } = ime;
    return this.companyService.updatePayorUsers(ime._id, user_ids.map(p => p._id)).toPromise()
  }
}
