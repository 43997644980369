import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranscriptionCreateService } from '../transcription-create.service';
import * as global from '../../global';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PCCService } from 'src/app/services/pcc.service';
import { AuthService } from 'src/app/services/auth.service';
import { NoteEditorService } from '../../note-editor/note-editor.service';
import { CensusPatientListService } from '../../census-patient-list/census-patient-list.service';
// UPLOAD FILE DIALOG
export interface UploadNoteConfirmationDialogData {
  type;
  noteId;
  rawText: string;
  payload: any;
  confirmButtonLabel: string;
}

@Component({
  selector: 'app-upload-note-confirmation-dialog',
  templateUrl: './upload-note-confirmation-dialog.component.html',
  styleUrls: ['./upload-note-confirmation-dialog.component.css']
})
export class UploadNoteConfirmationDialog implements OnInit {
  global = global;
  type: 'note' | 'addendum';
  payload;
  confirmButtonLabel: string;
  noteId;
  note;
  rawText: string;
  showDialog: Boolean ;
  loaderId = 'note-upload-confirmation-loader';
  currentDate = new Date();
  timezone = 'America/Los_Angeles' ;
  hideDetails: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<UploadNoteConfirmationDialog>,
    private loader: NgxUiLoaderService,
    private _pccService: PCCService,
    private transcriptionCreateService: TranscriptionCreateService,
    private authService: AuthService,
    private censusPatientListService: CensusPatientListService,
    private noteEditorService: NoteEditorService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) { 
      this.type = data.type;
      this.noteId = data.noteId;
      this.rawText = data.rawText;
      this.payload = data.payload;
      this.confirmButtonLabel = data.confirmButtonLabel;
      this.timezone = data.time_zone;
      if (this.data.hideDetails) this.hideDetails = this.data.hideDetails;
    }
  }

  ngOnInit(): void {
    this.initNote();
  }

  initNote() {
    this.loader.startLoader(this.loaderId);

    this.transcriptionCreateService.previewNoteAsPCC({ noteId: this.noteId, rawText: this.rawText }).subscribe((response: any) => {
      if (response.status === 200) {
        this.loader.stopLoader(this.loaderId);
        this.note = response.data;
      }
    });
  }

  getNoteText() {
    if (this.note) {
      return this.note.transcribtion_text.replace(/\n/g, '<br />');
    }
    return ''
  }
  hasPCCPatient(note) {
    return !!note?.patient_id_ref.pcc_patientId;
  }
  hasLoggedInToPCC() {
    return !!this._pccService?.pccUserinfo;
  }

  async toggleConfirmationDialog(setting) {
    const otherSettings = {
      [setting]: typeof this.authService.currentUser.other_settings[setting] === 'boolean' ? !this.authService.currentUser.other_settings[setting] : false
    }
    await this.authService.setOtherUserSettings(otherSettings).toPromise();
  }
  applyDateTimezone(date) {
    return this.noteEditorService.applyDateTimezone(this.note, date);
  }
  getDOS() {
    if(this.note.date_obj) {
      let {year, month, day, hour, minute} = this.note.date_obj;
      if(month < 10){
          month = '0' + month;
      }
      if(day < 10){
          day = '0' + day;
      }
      let str = `${month}/${day}/${year}`;
      if(hour && minute) {
          str += ` ${hour}:${minute}`
      }
      return str;
  }
    if (this.note && this.note.date_of_service) {
      // this.zone  = moment.tz(this.time_zone).zoneAbbr() 
      let serviceDate = new Date(new Date(this.note.date_of_service).toLocaleString('en-US', { timeZone: this.timezone }))

      return serviceDate;
    }
    else {
      return; 
    }
  }

  toggle($event){
    this.showDialog = $event.checked;
  }

  updateOtherSettings(){
    if(this.showDialog){
      if(this.note?.is_signed === 'false'){
        this.toggleConfirmationDialog('note_sign_confirmation');
      }else if(this.note?.is_signed === 'true'){
        this.toggleConfirmationDialog('note_upload_confirmation');
      }
    }
  }
  isValidDateObj() {
    if(this.note?.date_obj) {
        let {year, month, day, hour, minute} = this.note.date_obj;

        if((year !== undefined) && (month !== undefined) && (day !== undefined) && (hour !== undefined) && (minute !== undefined)) {
              return true;
        }
    }
    return false;
}

getProviderName() {
  if(!this.note) return;
  let provider = this.note.doctor_id_ref;
  if(this.note.coSign_status?.status === true && this.note.coSigned_provider) {
    provider = this.note.coSigned_provider;
  }

  // {{note?.doctor_id_ref?.first_name}} {{note?.doctor_id_ref?.last_name}}, {{note?.doctor_id_ref?.title}}
  return `${provider?.first_name} ${provider?.last_name}, ${provider?.title}`
}


  canUploadNoteToPCCUsingTwoLegged() {
    return this.authService.canUploadNoteToPCCUsingTwoLegged([this.note.uploaded_by_id_ref, this.note.doctor_id_ref._id])
  }
}