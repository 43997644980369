<mat-form-field appearance="fill" style="margin-left: 10px; max-width: 20%;">
    <mat-label *ngIf="currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE">Filter SNF WC Nurse</mat-label>
    <mat-label *ngIf="currentUser.user_type === global.USER_TYPE.DOCTOR">Filter by Provider</mat-label>
    <mat-select [(ngModel)]="provider" (ngModelChange)="handleProviderChange()">
        <input matInput placeholder="Search" [(ngModel)]="searchTextProvider" [ngModelOptions]="{standalone: true}"
            style="color:#000;height: 40px;padding-left: 5px;" />
        <mat-option value="">--All--</mat-option>
        <mat-option *ngFor="let provider of providers | searchProvider: searchTextProvider" [value]="provider._id"
            placeholder="Provider">
            {{provider.first_name}} {{provider.last_name}}, {{provider.title}}
        </mat-option>
    </mat-select>
</mat-form-field>
<div [class.table-height]="isTelehealthReport">
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="icon">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            </th>
            <td mat-cell *matCellDef="let element">
                <img class="transcription-icon" src="../../../assets/img/completed_file.png">
            </td>
        </ng-container>
        <ng-container matColumnDef="note_type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Note Type
            </th>
            <td mat-cell *matCellDef="let element" (click)="showViewNoteDialog(element)">
                <div class="flex items-center gap-1">
                    <i class="icon icon-view" matTooltip="View Note" matTooltipPosition="above">
                    </i>

                    <span
                        [ngStyle]="{textDecoration: note?.pcc_progressNoteStrikeOut && isCurrentNote(element) ? 'line-through;' : 'auto'}">{{element.progressNoteType
                        | uppercase}}</span>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="created_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Created Date
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.createdDate | date: global.date_time}}
            </td>
        </ng-container>

        <ng-container matColumnDef="created_by">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Created By
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.createdBy}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
<ngx-ui-loader [loaderId]="loaderId" bgsPosition="center-center"></ngx-ui-loader>
