import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import * as global from '../../global';
import { API_URL } from "../../../../environments/api-url";

@Injectable({
  providedIn: 'root'
})
export class MoveWoundService {
  currentUser: any;

  constructor(
    private httpclient: HttpClient,
    private commonService: CommonService,
    private _authService: AuthService
  ) {
    this.currentUser = this._authService.currentUser;
  }


  getWoundById(wound_id) {

    let company_id
    this.commonService.castCompany.subscribe(company => { company_id = company?._id })

    if (!company_id) company_id = this.currentUser.company_id;

    let item = {
      user_id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      company_id: company_id,
      wound_id: wound_id
    };

    return this.httpclient.post(global.url + API_URL.MOVE_WOUND.getWoundById, { details: item }).pipe();
  }

  getAllCensus(providerId, facilityId) {

    let company_id
    this.commonService.castCompany.subscribe(company => { company_id = company?._id })
    if (!company_id) company_id = this.currentUser.company_id;

    let item = {
      user_id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      company_id: company_id,
      provider_id: providerId,
      facility_id: facilityId
    };

    return this.httpclient.post(global.url + API_URL.MOVE_WOUND.getAllCensus, { details: item }).pipe();
  }

  getAllPatientsByCensusId(census_id) {

    let company_id
    this.commonService.castCompany.subscribe(company => { company_id = company?._id })
    if (!company_id) company_id = this.currentUser.company_id;

    let item = {
      user_id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      company_id: company_id,
      census_id: census_id
    };

    return this.httpclient.post(global.url + API_URL.MOVE_WOUND.getAllPatientsByCensusId, { details: item }).pipe();
  }

  moveWoundToPatient(data) {

    let company_id
    this.commonService.castCompany.subscribe(company => { company_id = company?._id })
    if (!company_id) company_id = this.currentUser.company_id;

    let item = {
      user_id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      company_id: company_id,
      ...data
    };

    return this.httpclient.post(global.url + API_URL.MOVE_WOUND.moveWoundToPatient, { details: item }).pipe();
  }
}
