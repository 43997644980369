import {Component, OnInit, ViewChild, ChangeDetectorRef, Input} from '@angular/core';
import {PatientListService} from '../patient-list/patient-list-service';
import {PatientDetailService} from './patient-detail-list.service';
import {Router, ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TranscriptionCreateService} from '../transcription-create/transcription-create.service';
import * as global from '../global';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {EncryptDecryptService} from '../../services/encrypt-decrypt.service';
import {DashboardService} from '../dashboard/dashboard.service';
import {AuthService} from 'src/app/services/auth.service';
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import { MatDialog } from '@angular/material/dialog';
import { ExportNotesDialogComponent } from 'src/app/shared/popup-dialogs/export-notes-dialog/export-notes-dialog.component';
import { NoteEditorService } from '../note-editor/note-editor.service';
import { API_URL } from 'src/environments/api-url';

@Component({
    selector: 'app-patient-detail-list',
    templateUrl: './patient-detail-list.component.html',
    styleUrls: ['./patient-detail-list.component.css']
})
export class PatientDetailListComponent implements OnInit {
    global = global;
    loading: boolean = false;
    transcriptionFiles = [];
    transcriptionComments = [];

    displayedColumns: string[] = [];
    dataSource: any;
    totalNotesCount: number = 0;
    updateTotalNotesCount: boolean = false;
    currentUser: any = null;
    @Input() patient:any;
    @Input() isTelehealthReport = false;
    @ViewChild(MatSort) sort: MatSort;
    multiSelectedTranscriptions: Array<any> = [];
    usermultiSelectedTranscription: any[];
    updatedCount: any;

    constructor(
        // private _patientService: PatientListService,
        private _patientDetailService: PatientDetailService,
        private _transcriptionCreateService: TranscriptionCreateService,
        private router: Router,
        private toastr: ToastrService,
        public changeDetectorRefs: ChangeDetectorRef,
        private http: HttpClient,
        private dashboard: DashboardService,
        private EncryptDecryptService: EncryptDecryptService,
        private _authService: AuthService,
        private dialog: MatDialog,
        private noteEditorService: NoteEditorService,
    ) {
    }

    async ngOnInit() {

        this.loading = true;
        // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.currentUser = this._authService.currentUser;
        this.displayedColumns = ['title', 'creation_date', 'doctor_name', 'facility_title', 'operations'];
        
        this.initData()
        console.log("child");
    }
    async initData(){
        const userInfo = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,

            patient_id: this.patient?._id,
            company_id: this.currentUser.company_id,
        }; 

        const transcriptionsByPatientResponse: any = await this._patientDetailService.getTranscriptionsByPatientId(userInfo).toPromise();
        if (transcriptionsByPatientResponse.status === 200) {

            this.transcriptionFiles = transcriptionsByPatientResponse.data.array;
            this.transcriptionFiles.sort((a, b) => {
                const Adate = a?.type === 'doctor' ? a?.date_of_service : a?.date;
                const Bdate = b?.type === 'doctor' ? b?.date_of_service : b?.date;
                return new Date(Bdate).getTime() - new Date(Adate).getTime();
            });
            this.dataSource = new MatTableDataSource(this.transcriptionFiles);
            this.dataSource.sort = this.sort;

        }

        this.loading = false;
    }
    reloadData(event){
        this.initData();
    }
    totalFilesCount(event){
        this.totalNotesCount=event;
        this.updateTotalNotesCount=true;
    }

    updateCount($event) {
        this.updatedCount = $event;
    }

    toggleTranscriptionMultiSelect(transcription: any) { 
        const i = this.multiSelectedTranscriptions.indexOf(transcription); 
        if (i === -1) {
            this.multiSelectedTranscriptions.push(transcription);
        } else {
            this.multiSelectedTranscriptions.splice(i, 1);
        } 
    }
    async exportNote(operation) {
        if (operation === 'download') {
            if (this.multiSelectedTranscriptions.length > 0) {
                this.toastr.info('Downloading Notes', 'Downloading...');
                this.multiSelectedTranscriptions = this.multiSelectedTranscriptions
                .filter(d => d.uploaded_by_id == this.currentUser._id)
                .map(d => d._id);
            }
            const fileName = `${this.multiSelectedTranscriptions.length} note(s).zip`;
            await this.dashboard.downloadFile(global.url + API_URL.NOTE.generateMultiplePDF, this.multiSelectedTranscriptions).subscribe((res: any) => {
                if (res instanceof HttpResponse) {

                    var url = window.URL.createObjectURL(res.body);
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.setAttribute('style', 'display: none');
                    a.href = url;
                    a.download = fileName;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove(); // remove the element
                } else {

                }
            }, (err) => {
                this.toastr.error('Something went wrong while downloading notes', 'Failed');
            },
                () => {
                });
            this.multiSelectedTranscriptions = []
            this.multiSelectedTranscriptions.length = 0;
        }
        else if (operation === 'send_to_email') {
            const dialogRef = this.dialog.open(ExportNotesDialogComponent, { width: '40%' });
            dialogRef.afterClosed().subscribe(async result => {
                if (result.emails.length > 0) {
                    this.toastr.info('Sending emails', 'Sending...');
                    if (this.multiSelectedTranscriptions.length > 0) {
                        this.multiSelectedTranscriptions = this.multiSelectedTranscriptions
                        .filter(d => d.uploaded_by_id == this.currentUser._id)
                        .map(d => d._id);
                    }
                    // @ts-ignore    
                    this.noteEditorService.generatePDFsLinksAndEmail(result.emails, this.multiSelectedTranscriptions)

                        //                    this.noteEditorService.generatePDFsAndEmail(result.emails, this.multiSelectedTranscriptions, this.tranFilters.provider?.name, this.tranFilters.facility?.title)

                        .subscribe((response: any) => {
                            if (response.status == 200) {
                                this.toastr.info('Emails sent');
                            }
                        }, () => {
                        }, () => {
                            this.multiSelectedTranscriptions = []
                            this.multiSelectedTranscriptions.length = 0;
                        })
                }
            })
        }
    }

    isEditable(transcription) {
        if (!transcription) {
            return false;
        }

        if (this.currentUser.user_type == 1 && transcription.is_signed != 'true') {
            return true;
        } else if ((this.currentUser.user_type == 2 && transcription.is_published != 'true' && transcription.is_signed != 'true') || (this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT && transcription.is_published != 'true' && transcription.is_signed != 'true')) {
            return true;
        }
        return false;
    }

    /*
    * Unsign Transcription #mohsin-dev
    */
    async unSignTranscription(transcription, category) {
        transcription.is_signed = 'false';
        transcription.transcribtion_id = transcription._id;
        transcription.id = this.currentUser._id;
        transcription.auth_key = this.currentUser.auth_key;
        transcription.usertype = this.currentUser.user_type;
        const toastInfo = this.toastr.info('Unsigning...', 'Please wait', {disableTimeOut: true});
        const response: any = await this._transcriptionCreateService.unSignTranscription(transcription, category).toPromise();
        if (response.status === 200) {
        } else {
            this.toastr.error(response.message, 'Failed');
        }
        this.toastr.clear(toastInfo.toastId);
    }

    async deleteTranscription(transcription, category) {
        if (!confirm('Are you sure you want to delete?')) {
            return;
        }


        transcription.is_active = 'false';
        transcription.transcribtion_id = transcription._id;
        transcription.id = this.currentUser._id;
        transcription.auth_key = this.currentUser.auth_key;
        transcription.usertype = this.currentUser.user_type;
        const toastInfo = this.toastr.info('Deleting...', 'Please wait', {disableTimeOut: true});
        const response: any = await this._transcriptionCreateService.deleteTranscription(transcription, category).toPromise();
        if (response.status === 200) {
            this.transcriptionFiles = this.transcriptionFiles.filter(t => t != transcription);
            this.dataSource = new MatTableDataSource(this.transcriptionFiles);
            this.dataSource.sort = this.sort;
            this.changeDetectorRefs.detectChanges();
        } else {
            this.toastr.error(response.message, 'Failed');
        }
        this.toastr.clear(toastInfo.toastId);
    }

    selectedfile(file) {
        localStorage.setItem('selectedFile', JSON.stringify(file));
        const transcriptionFiles = this.transcriptionFiles;
        localStorage.setItem('transcriptionFiles', JSON.stringify(transcriptionFiles)); // for next and prev buttons
    }

    onEditClick(file) {
        this.selectedfile(file);
        const category = file.date_of_service ? 'tran' : 'dict';

        this.router.navigateByUrl(`/dashboard;category=${category}`, {skipLocationChange: true})
            .then(() => this.router.navigate(['/transcription-create', {category: category}]));
    }

    onViewClick(file) {
        this.selectedfile(file);
    }

    /*
    * Export as PDF #mohsin-dev
    */
    async downloadAsPDF(transcription) {
        /**
         * FLOW: Decrypt Transcript Text => Generate PDF => Get Doctor Name => Generate File Name => Download PDF File
         */
        const infoToastr = this.toastr.info('Generating PDF', 'Please wait...', {disableTimeOut: true});

        try {
            transcription.transcribtion_text = await this.EncryptDecryptService.decrypt(transcription.transcribtion_text).toPromise();

            const generatePDFResponse: any = await this.dashboard.generatePDF(transcription).toPromise();
            if (generatePDFResponse.status !== 200) {
                throw new Error(generatePDFResponse.message);
            }

            const doctorUserDetailsResponse: any = await this.dashboard.getUserDetail(transcription.doctor_id).toPromise();
            if (doctorUserDetailsResponse.status !== 200) {
                throw new Error(doctorUserDetailsResponse.message);
            }

            const doctor = doctorUserDetailsResponse.data.array;
            const doctorName = doctor.first_name + ' ' + doctor.last_name;
            const file_name = transcription.transcribtion_title + '_' + transcription.facility_id_ref.title + '_' + doctorName;
            this.downloadFile(global.url + '/downloadAsPDF', {
                file_name: file_name
            });
        } catch (error) {
            const {message = 'Something went wrong, please try again'} = error;
            this.toastr.error(message);
        } finally {
            this.toastr.clear(infoToastr.toastId);
        }
    }

    downloadFile(url, params) {
        const infoToastr: any = this.toastr.info('Preparing file download', 'Please wait...', {
            disableTimeOut: true
        });

        return this.http
            .get(url, {
                responseType: 'blob',
                params: {
                    ...params
                }
            }).pipe(
                map((res: any) => {
                    return {
                        filename: params.file_name,
                        data: res
                    };
                }))
            .subscribe((res: any) => {
                this.toastr.clear(infoToastr.toastrId);
                var url = window.URL.createObjectURL(res.data);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element

            }, error => {

            }, () => {

            });
    }
    getRecentPatientADTRecord(patient) {
        patient.adtRecords.sort((a,b) => new Date(b.effectiveDateTime).getTime() -  new Date(a.effectiveDateTime).getTime())
        return patient.adtRecords[0];
      }
}
