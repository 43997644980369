<h3>
  Audit
</h3>

<div class="flex flex-col gap-2" style="font-size: 12px;">


  <div class="flex flex-col">
    <div>
      <u><strong>Audit Created By: </strong></u>
      <span>{{audit?.user_id?.first_name}} {{audit?.user_id?.last_name}}</span>
    </div> 

    <div>
      <u><strong>Audit Date: </strong></u>
      <span>{{getAuditDateWithTime(audit)  | date: 'MM/dd/yyyy hh:mm a'}}</span>
    </div>

    <div>
      <u><strong>Operation: </strong></u>
      <span>{{audit?.operation | titlecase}}</span>
    </div>
    
    <div *ngIf="audit.doctor_id_ref">
      <u><strong>Provider: </strong></u>
      <span>{{audit?.doctor_id_ref?.first_name}} {{audit?.doctor_id_ref?.last_name}}</span>
    </div> 
    <div *ngIf="audit.patient_id_ref">
      <u><strong>Patient: </strong></u>
      <span>{{audit?.patient_id_ref?.last_name}}, {{audit?.patient_id_ref?.middle_name}} {{audit?.patient_id_ref?.first_name}}</span>
    </div> 
    <div *ngIf="audit.patient_id_ref">
      <u><strong>DOS: </strong></u>
      <span>{{applyTimezone(audit.timestamp)}}</span>
    </div> 

    <div>
      <u><strong>Note Text: </strong></u>
      <div class="note_text" style="max-height: 65vh;overflow: auto;" [innerHTML]="audit.note_text"></div>
    </div>

  </div>


  <div class="flex gap-5 justify-end">
    <button mat-raised-button class="dn-button-secondary" mat-dialog-close>Close</button>
  </div>
  <div class="top-right-close-btn">
      <button mat-mini-fab mat-dialog-close ><mat-icon >cancel</mat-icon></button>
    </div>

</div>