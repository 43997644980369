import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as global from '../global';


@Injectable({
  providedIn: 'root',
})
export class PatientScriptSureService {
    constructor(
        private httpclient: HttpClient
    ) {}

    vendorLogin( payload ) {
        return this.httpclient
            .post( `${ global.url }/scriptsure/vendor-login`, payload )
            .toPromise()
            .then(( response: any ) => {
                if ( response.status !== 200 ) {
                    throw new Error( response.message );
                }

                return response;
            });
    }

    vendorLogout( payload ) {
        return this.httpclient
            .get( `${ global.url }/scriptsure/vendor-logout`, {
                params: payload
            })
            .toPromise()
            .then(( response: any ) => {
                if ( response.status !== 200 ) {
                    throw new Error( response.message );
                }

                return response;
            });
    }
}
