<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

    <ng-container matColumnDef="sr">
        <th mat-header-cell *matHeaderCellDef> Sr </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            {{i + 1}}
        </td>
    </ng-container>

    <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef> Subject </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            {{element.subject}}
        </td>
    </ng-container>

    <ng-container matColumnDef="emailBody">
        <th mat-header-cell *matHeaderCellDef> Email Body </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            <div [innerHTML]="element.body">
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            <div class="operation-buttons buttons-div flex items-center gap-5">
                <span class="material-icons" matTooltip="View" matTooltipPosition="above" style="cursor: pointer;"
                    (click)="openSendEmailTemplateDialog(element._id, i);">
                    remove_red_eye
                </span>
                <span class="material-icons" matTooltip="Delete" (click)="deleteEmail(element._id, i)"
                    matTooltipPosition="above" style="cursor: pointer;">
                    delete_outline
                </span>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>