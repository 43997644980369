import { Component, OnInit, Input } from '@angular/core';
import * as global from '../../global'
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { PCCService } from 'src/app/services/pcc.service';
@Component({
  selector: 'app-note-patient-details',
  templateUrl: './note-patient-details.component.html',
  styleUrls: ['./note-patient-details.component.css']
})
export class NotePatientDetailsComponent implements OnInit {
  global = global;
  @Input() patient: any;
  @Input() note: string;

  isPCCLoggedIn = false;
  showCommunicationTab = true;

  constructor(
    private authService: AuthService,
    private _pccService: PCCService,
  ) { }

  ngOnInit(): void {
    if (window.location.pathname.includes("note-editor")) {
      this.showCommunicationTab = false;
    }
    this.isPCCLoggedIn = !!this._pccService.pccUserinfo
    this._pccService.pccUserinfo$.subscribe(data => {
      this.isPCCLoggedIn = !!data;
    });
  }

}
