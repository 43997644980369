import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import moment from 'moment';
// import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import { FacilityService } from 'src/app/services/facility.service';
import { ReportModuleService } from '../report-module.service'; 
import { MatTableDataSource } from '@angular/material/table';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-company-audit-reports',
  templateUrl: './company-audit-reports.component.html',
  styleUrls: ['./company-audit-reports.component.css']
})
export class CompanyAuditReportsComponent implements OnInit {

  currentUser: any;
  company_id: any = '';
  facilities: any = [];
  selectedFacility : any;
  selectedFacilityId: any;
  providers: any = [];
  selectedProvider : any;
  selectedProviderId : any;
  projection = {};
  filter = {
    is_active: true,
    company_id: '',
    facility_id: '',
    provider_id: '',
    date_of_submission: {
      startDate: moment().subtract(7, 'days'),
        endDate: moment()
    }, 
    operation: ''
  };
  searchFacilityText: any;
  searchProviderText: any;
  public submissionDateFilter: Boolean = true;
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  public c_date = moment(new Date());
  public dataSource: any; 
  public dataSource2: any; 
  public dataSource3: any; 
  public auditGroupedData: any; 
  public auditProviderGroupedData: any; 
  public expandedData: any; 
  displayedColumns: string[] = ['operation', 'count'];
  displayedColumns2: string[] = ['provider', 'facility', 'operation', 'count' , ]; 
  chargeCount: any;
  chargeCount2: any;
  auditGroupedScreen = true;
  providerGroupedAuditScreen = false;
  selectedgroup;
  loaderId = 'company-audit-report';
  
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private facilityService: FacilityService,
    private reportModuleService: ReportModuleService,
    private loader: NgxUiLoaderService,
  ) { 
    this.currentUser = this.authService.currentUser;
    if (window.location.pathname.includes('company')) {
      this.company_id = this.route.parent.snapshot.params.id
    }
    else {
        this.company_id = '';
    }
    this.projection = {
      'title': 1,
      'address': 1,
      'city': 1,
      'state': 1,
      'zip_code': 1,
      'is_active': 1
    }
    this.filter = { 
      is_active: true,
      company_id: this.company_id,
      facility_id: '',
      provider_id: '',
      date_of_submission: {
        startDate: moment().subtract(7, 'days'),
        endDate: moment(),
      }, 
      operation: ''
    }
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId); 
    console.log('in company audit.....................') 
    await this.getFacilities();
  }

  async getFacilities(){
    let population = 'true';
    let res = await lastValueFrom(this.facilityService.getAllFacilities(this.filter, this.projection, population)) as any;
    console.log("res---- ", res)
    if(res.status == 200){
      this.facilities = res.data.array;
      await this.applyFilter()
    }
  }

  applyFacilityChange(event){
    if(event.value && event.value !== '') {
      this.selectedFacilityId = event.value;
      this.filter.facility_id = this.selectedFacilityId;
      this.selectedFacility = this.facilities.find((f) => f._id == event.value);

      this.providers = this.selectedFacility.provider_ids_ref;

      this.selectedProviderId = '';
      this.filter.provider_id = '';

    } else if(!event.value) {
      this.selectedFacilityId = event.value;
      this.selectedFacility = '';
      this.filter.facility_id = this.selectedFacilityId;
      let allProviders = [];
      this.facilities.map(fac => { allProviders.push(...fac.provider_ids_ref) }) 
      let uniqueProviders = []; 
      allProviders.map(p => {
        if(uniqueProviders.some(a => a._id == p._id)){
        }
        else{
          uniqueProviders.push(p)
        } 
      })
      // console.log("uniqueProviders: ", uniqueProviders); 
      this.providers =  uniqueProviders;

      this.selectedProviderId = '';
      this.filter.provider_id = '';
    } 
  }

  applyProviderChange(event = null){ 

    if(event.value && event.value !== '') {
      this.selectedProviderId = event.value;
      this.filter.provider_id = this.selectedProviderId;
      let selectedProvider = this.providers.find((p) => p._id == event.value);
    } else if (!event.value) {
      this.selectedProviderId = event.value;
      this.filter.provider_id = this.selectedProviderId;
      this.selectedProviderId = '';
      this.filter.provider_id = '';
      // this.selectedFacility = '';
      // this.filter.facility_id = '';
    }
    // if(!event.value){
    //   this.selectedProviderId = '';
    //   this.filter.provider_id = '';
    // } 
  }
 

  async getProviderGroupedAudit(element){
    this.loader.startLoader(this.loaderId); 
    // console.log('Element ===== ', element)
    this.providerGroupedAuditScreen = true;
    this.auditGroupedScreen = false;
    let measure_id = element._id.measure_id
    this.filter.operation = element._id
    let res = await lastValueFrom(this.reportModuleService.getCompanyAuditReport(this.filter))as any;
    if(res.status == 200){
      this.auditProviderGroupedData = res.data;
      this.chargeCount2 = res.chargeCount;
      this.dataSource2 = new MatTableDataSource(this.auditProviderGroupedData); 
    }
    this.loader.stopLoader(this.loaderId);
  }

  async applyFilter(){
    this.loader.startLoader(this.loaderId); 
    console.log('apppppllllllllyyyyyyy');
    this.auditGroupedScreen = true;
    this.providerGroupedAuditScreen = false;
    // console.log('Filter---------', this.filter)
    if(this.filter.operation) {
      this.filter.operation = '';
    }
    let res = await lastValueFrom(this.reportModuleService.getCompanyAuditReport(this.filter)) as any;
    if(res.status == 200){
      console.log('apppppllllllllyyyyyyy')
      this.auditGroupedData = res.data;
      this.chargeCount = res.chargeCount;
      this.dataSource = new MatTableDataSource(this.auditGroupedData); 
    }
    this.loader.stopLoader(this.loaderId);
  }


  isPCCFacility() {
    return this.selectedFacility != undefined && this.selectedFacility != 'all' && (this.selectedFacility?.source === 'PointClickCare' || this.selectedFacility?.pcc_facId)
  }
  isMatrixFacility() {
    return this.selectedFacility != undefined && this.selectedFacility != 'all' && this.selectedFacility?.source === 'MatrixCare'
  }
  isDNFacility() {
    return this.selectedFacility != undefined && this.selectedFacility != 'all' && this.selectedFacility?.source != 'PointClickCare' && !this.selectedFacility?.pcc_facId && this.selectedFacility?.source != 'MatrixCare'
  }
  
  changeSubmissionDate(event){
    // console.log('----event-----', event)
    // console.log('----event startDate-----', moment(moment(event.startDate).toLocaleString()).format('MM/DD/YYYY'))
    // let endDate = moment(moment(event.endDate).toLocaleString()).format('MM/DD/YYYY')
    // let startDate = moment(moment(event.startDate).toLocaleString()).format('MM/DD/YYYY')
    this.filter.date_of_submission = {
      startDate: event.startDate,
      endDate: event.endDate,
    }

  }


  getProviderName(element){
    if(element.user){
      return `${element.user.last_name} ${element.user.first_name}`
    }
    else{
      return '--'
    }
  }
  getProviderNameExpanded(){
    if(this.expandedData.user){
      return `${this.expandedData.user.last_name} ${this.expandedData.user.first_name}`
    }
    else{
      return '--'
    }
  }

  async goToDetailScreen(element){ 
    this.auditGroupedScreen = false;
    this.providerGroupedAuditScreen = false;
    this.expandedData = element
    this.dataSource3 = new MatTableDataSource(this.expandedData.data); 
  }

  goBack(){ 
    if(this.providerGroupedAuditScreen){
      this.auditGroupedScreen = true;
      this.providerGroupedAuditScreen = false;
    }
    
  }

}
