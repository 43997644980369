import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {TimeSchedule} from "../../../../classes/time_schedule";
import {ActivatedRoute} from "@angular/router";
import moment from "moment";
import {DoctorProfileService} from "../../../../services/Modules/doctorProfile.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {CompanyService} from "../../../../services/Modules/company.service";
import {AuthService} from "../../../../services/auth.service";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-user-time-schedule',
    templateUrl: './user-time-schedule.component.html',
    styleUrls: ['./user-time-schedule.component.css']
})
export class UserTimeScheduleComponent implements OnInit {

    global = global;
    displayedColumns = ['day', 'start_time', 'end_time'];
    dataSource: MatTableDataSource<any>;
    model = new TimeSchedule();
    minTime = moment().format('LT');
    facilities = [];
    selectedFacility = undefined;

    timeScheduleLoader = 'time-schedule-loader';

    constructor(private route: ActivatedRoute,
                private loader: NgxUiLoaderService,
                private companyService: CompanyService,
                private authService: AuthService,
                private toastr: ToastrService,
                private profileService: DoctorProfileService) {
    }

    async ngOnInit() {
        const provider_id = this.route.snapshot.params.id;
        this.companyService
        //@ts-ignore
            .getCompanyFacilities(this.authService.companyId, provider_id)
            .subscribe( res => {
                this.facilities = res.data.array;
                if (this.selectedFacility === undefined) {
                    this.selectedFacility = this.facilities[0]._id;
                    this.initTimeSchedule();
                } else {
                    this.initTimeSchedule();
                }
            });
    }

    refreshSchedule(event) {

        this.initTimeSchedule();
    }

    getMinTime(event) {

        return '3:00 AM';
    }

    initTableData() {
        const {user_id_ref, user_type, facility_id_ref, ...DaySchedule} = this.model;
        for (let day in DaySchedule) {
            DaySchedule[day] = DaySchedule[day];
            DaySchedule[day].day = day;
        }

        this.dataSource = new MatTableDataSource(Object.values(DaySchedule));
    }


    initTimeSchedule() {
        this.model.user_id_ref = this.route.snapshot.params.id;
        this.model.user_type = '1';
        this.loader.startLoader(this.timeScheduleLoader);

        const filter = {
            user_id_ref: this.model.user_id_ref,
            facility_id_ref: this.selectedFacility
        };
        this.profileService.getTimeScheduleList(filter)
            .subscribe(res => {

            });
        const projection = {};
        this.profileService.getTimeSchedule(filter, projection).subscribe((response: any) => {
            if (response.status === 200 && response.data) {
                this.model = response.data;
            } else if (response.status === 200) {
                this.model = new TimeSchedule();
            }
        }, (error) => {
            console.error('Error occured', error.toString());

        }, () => {
            this.loader.stopLoader(this.timeScheduleLoader);
            this.initTableData();
        })
    }

    getMinutes(time = '12:00') {
        let splitted = time.split(':');
        let minutes = (parseInt(splitted[0]) * 60 + parseInt(splitted[1]));

        return minutes;
    }

    handleSubmit(values) {
        let timeValid = true;

        Object.keys(this.model)
            .filter( key => {
                return this.model[key]?.availability === true;
            })
            .filter( key => {
                let start = moment(this.model[key].start_time, ["h:mm A"]).format("HH:mm");
                let end = moment(this.model[key].end_time, ["h:mm A"]).format("HH:mm");
                if (this.getMinutes(start) >= this.getMinutes(end)) {
                    this.toastr.error('Some schedule with invalid time', 'Error');
                    timeValid = false;
                    return;
                }
            });
        if (!timeValid) return;
        this.loader.startLoader(this.timeScheduleLoader);
        this.model.facility_id_ref = this.selectedFacility;
        this.model.user_id_ref = this.route.snapshot.params.id;
        this.profileService.saveTimeSchedule(this.model)
            .subscribe((response: any) => {
                this.toastr.success('Schedule update', 'Success');
        }, (error) => {
            console.error('Error occured', error.toString());

        }, () => {
            this.loader.stopLoader(this.timeScheduleLoader);
        })
    }

    isAvailable(day) {
        const available = this.model[day] ? !!this.model[day].availability : false;


        return available
    }

}
