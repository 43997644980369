<app-header></app-header>

<main>

  <div class="pdf-view flex flex-col">
    <div class="pdf-view-header flex">
      <div class="flex-shrink-0">
        <mat-icon class="back-icon" [routerLink]="[backURLString]">arrow_back_ios</mat-icon>
        </div>

        <div class="flex justify-center items-center">
          
          <div class="flex-grow-0">
            <img *ngIf="file.is_signed == 'false'" src="../../../assets/img/file.png">
            <img *ngIf="file.is_signed == 'true'" src="../../../assets/img/signed_file.png">            
          </div>
          <div class="flex flex-col">
            <div class="title">{{title}}</div>
            <div>
              <span class="date">{{date | date :'MMM dd, yyyy, h:mm:ss a'}}</span>
            </div>
          </div>
        </div>
        <div class="flex justify-end items-center">
          <div class="pdf-file-action">            
            <i class="material-icons" (click)="download()">cloud_download</i>
          </div>
        </div>

      </div>
      <div class="pdf-file">   
        <ngx-doc-viewer [url]="filepath" viewer="google" style="width:100%;height: 95vh"></ngx-doc-viewer>
      </div>
      <div class="verify-file" *ngIf="user.user_type =='1'">
          <button mat-fab color="accent" (click)="changeFileSignStatus()">
              <i class="material-icons" title="Change File Status">cloud_done</i>
          </button>
        </div>
    </div>

</main>
