import { Component, OnInit, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { MipsService } from "src/app/services/Modules/mips.service";
import { PdfViewerPopComponent } from "../../patient-documents-list/pdf-viewer-pop/pdf-viewer-pop.component";
import { AuthService } from "src/app/services/auth.service";

@Component({
    selector: "app-mips-admin-list",
    templateUrl: "./mips-admin-list.component.html",
    styleUrls: ["./mips-admin-list.component.css"]
})
export class MipsAdminListComponent implements OnInit {
    @Input() mipsQuestions;
    @Input() currentCompany;
    disableMips: boolean = false;

    constructor(private mipsService: MipsService, private dialog: MatDialog,
    private _toastr: ToastrService,
    private authService: AuthService,
    ) {
    }

    ngOnInit(): void {
      if(!this.authService.currentUser.product_owner) {
        if((this.authService.currentUser.admin_type != 'system_admin'  && this.authService.currentUser.admin_type) != 'full_admin'
        || this.currentCompany._id.toString() != this.authService.currentUser.company_id.toString())
          {
            this.disableMips = true;
          }
      }
    }

    async saveMipsSetting(event, currentCompany, mip) {
      if(!this.authService.currentUser.product_owner) {
        if(this.authService.currentUser.admin_type != 'system_admin'  && this.authService.currentUser.admin_type != 'full_admin'
        && this.currentCompany._id.toString() != this.authService.currentUser.company_id.toString())
        this._toastr.error('User not Authorized to perform this action.')  
        return;
      }
        let mipDetail = {
            mipId: mip._id,
            mip_quality_number: mip.quality_number
        };
        let res: any = await this.mipsService
            .saveMipsSetting(event.checked, currentCompany._id, mipDetail)
            .toPromise();
        if (res.status == 200) {
            if(event.checked){
                this._toastr.success('MIP '+ mip.quality_number +' successfully activated')
              }
              else{
                this._toastr.success('MIP '+ mip.quality_number +' successfully deactivated')
              }
        }
    }

    async handleViewMIPSClick(file_path) {
        this.dialog.open(PdfViewerPopComponent, {
            width: "50vw",
            height: "95vh",
            data: {
                file_path: file_path
            }
        });
    }
}
