import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { WoundCareWidgetService } from '../wound-care-widget/wound-care-widget.service';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../../../includes/global';
import { WoundCareWidgetComponent } from '../wound-care-widget/wound-care-widget.component';


@Component({
  selector: 'app-wound-care-main-widget',
  templateUrl: './wound-care-main-widget.component.html',
  styleUrls: ['./wound-care-main-widget.component.css']
})
export class WoundCareMainWidgetComponent implements OnInit {

  @Input() provider;
  @Input() note;
  @Input() patient;
  @Input() readOnlyMode: any;
  @Input() configuration: any;


  @Output() onICDsSave: EventEmitter<any> = new EventEmitter();
  @Output() woundDetailsChange: EventEmitter<any> = new EventEmitter();
  @Output() checkallWoundAssessmentPhrase: EventEmitter<any> = new EventEmitter();
  @ViewChild(WoundCareWidgetComponent) woundCareWidgetComponent: WoundCareWidgetComponent;
  activatedRouter: string;
  tabIndex: number = 0;
  currentUser: any;
  providerWoundStateCounts = {
    activeCount: 0,
    remissionCount: 0,
    amputatedCount: 0,
    archiveCount: 0
  }
  nurseWoundStateCounts = {
    activeCount: 0,
    remissionCount: 0,
    amputatedCount: 0,
    archiveCount: 0
  }
  totalProviderWounds = 0;
  totalNurserWounds = 0;
  allDataLoaded = false;
  global = global;
  constructor(
    private _woundCareService: WoundCareWidgetService,
    private _authService: AuthService
  ) {
    const currentUser = this._authService.currentUser;
    if (currentUser) {
      // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.currentUser = currentUser;
    }
  }

  async ngOnInit(): Promise<void> {
    await this.getwoundCountState(true, this.global.USER_TYPE.DOCTOR);
    await this.getwoundCountState(false, this.global.USER_TYPE.SNF_WC_NURSE);
    this.allDataLoaded = true;
  }
  onTabChanged(event) {
    console.log(event);
    this.tabIndex = event?.index
    // if (event?.index == 0) {
    //   this.tabIndex = 0;
    //   this.activatedRouter = 'provider'
    // }
    // if (event?.index == 1) {
    //   this.tabIndex = 1;
    //   this.activatedRouter = 'nurse'
    // }
  }
  async handlecheckallWoundAssessmentPhrase(event){
    if(this.currentUser.user_type === global.USER_TYPE.DOCTOR){
      if(this.tabIndex == 0){
        this.totalProviderWounds = event.length;
      }else{
        if(event.callCountApi){
          await this.getwoundCountState(true,"1");
        }
        this.totalNurserWounds = event.length;
        if(event.openPopMenu){
          this.tabIndex = 0;
          setTimeout(()=>{
            this.woundCareWidgetComponent.openMenuofLatestWound();
          },2000)
        }
      }
    }else{
      if(this.tabIndex == 1){
        this.totalProviderWounds = event.length;
      }else{
        if(event.callCountApi){
          await this.getwoundCountState(true, this.global.USER_TYPE.DOCTOR);
        }
        this.totalNurserWounds = event.length;
      }
    }
    this.checkallWoundAssessmentPhrase.emit(event);
  }
  handleICDsSave(icds) {
    this.onICDsSave.emit(icds);
  }
  handleWoundDetailsChange(data) {
    this.woundDetailsChange.emit(data);
  }
  getwoundCountState(provider = true, userType) {
    return new Promise<void>((resolve, reject) => {
      this._woundCareService.getAllWoundsParentStatesCount(this.note?.patient?._id, userType).subscribe((res: any) => {
        if (res.status === 200) {
          if(provider){
            this.totalProviderWounds = 0;
          }
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i]._id == 'active') {
              if(provider){
                this.providerWoundStateCounts.activeCount = res.data[i].count;
                this.totalProviderWounds += res.data[i].count;
              }else{
                this.nurseWoundStateCounts.activeCount = res.data[i].count;
                this.totalNurserWounds += res.data[i].count;
              }
            }
            if (res.data[i]._id == 'remission') {
              if(provider){
                this.providerWoundStateCounts.remissionCount = res.data[i].count;
                this.totalProviderWounds += res.data[i].count;
              }else{
                this.nurseWoundStateCounts.remissionCount = res.data[i].count;
                this.totalNurserWounds += res.data[i].count;
              }
            }
            if (res.data[i]._id == 'amputated') {
              if(provider){
                this.providerWoundStateCounts.amputatedCount = res.data[i].count;
                this.totalProviderWounds += res.data[i].count;
              }else{
                this.nurseWoundStateCounts.amputatedCount = res.data[i].count;
                this.totalNurserWounds += res.data[i].count;
              }
            }
            if (res.data[i]._id == 'archived') {
              if(provider){
                this.providerWoundStateCounts.archiveCount = res.data[i].count;
                this.totalProviderWounds += res.data[i].count;
              }else{
                this.nurseWoundStateCounts.archiveCount = res.data[i].count;
                this.totalNurserWounds += res.data[i].count;
              }
            }
          }
        }
        resolve(null);
      }, error => {
        reject('error => ' + error.toString());
      });
    });
  }
}
