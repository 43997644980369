import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { MatTabGroup, MatTabHeader } from '@angular/material/tabs';
import * as global from '../global';

@Component({
  selector: 'app-phrases',
  templateUrl: './phrases.component.html',
  styleUrls: [ './phrases.component.css' ]
})
export class PhrasesComponent implements OnInit {
  @Input() note: any;
  @Input() selectedSource: any = null;
  @Input() forNoteType: any = null;
  @Output() insertPhraseText: EventEmitter<any> = new EventEmitter();
  @Input() useDragonStyle: any = false;
  @Input() updateTabGroup: any = null;
  @ViewChild( MatTabGroup, { static: false }) tabGroup: MatTabGroup;
  currentUser: any;
  global = global;

  constructor( private authService: AuthService ) {
    this.currentUser = this.authService.currentUser;
  }

  ngOnInit(): void {}
}
