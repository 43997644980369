import { Injectable } from '@angular/core';
import * as global from '../includes/global';

@Injectable({
  providedIn: 'root',
})
export class ModifierService {
  private modifiers = [
    {
      code: '25',

      description:
        'Significant, separately identifiable E&M service by the same physician on the day of a procedure',
    },

    {
      code: '26',

      description: 'Professional Component',
    },

    {
      code: '50',

      description: 'Bilateral Procedure',
    },

    {
      code: '59',

      description: 'Distinct Procedural Service',
    },

    {
      code: '95',

      description: 'Synchronous Telemedicine Service',
    },

    {
      code: 'AI',

      description:
        'Principal Physician of Record - Used by the Admitting or Attending Physician who oversees patient’s care,',
    },

    {
      code: 'CS',

      description: 'Cost-Sharing Waived for COVID-19 Testing-Related Services',
    },

    {
      code: 'GT',

      description:
        'Service Rendered via Interactive Audio and Telecommunications Systems',
    },

    {
      code: 'LT',

      description: 'Left Side',
    },

    {
      code: 'Q6',

      description: 'LOCUM TENENS MD SERVICE',
    },

    {
      code: 'RT',

      description: 'Right Side',
    },
    {
      code: 'GW',

      description: 'Enrolled in a Hospice',
    },
    {
      code: 'X1',

      description: 'Continuous/ Broad Services',
    },
    {
      code: 'X2',

      description: 'Continuous/ Focused Services',
    },
    {
      code: 'X3',

      description: 'Episodic/ Broad Services',
    },
    {
      code: 'X4',

      description: 'Episodic/ Focused Services',
    },
    {
      code: 'X5',

      description: 'Only as Ordered by Another Clinician',
    },
    {
      code: 'SS',

      description: 'Skin Substitute',
    },
    {
      code: 'JW',
      description: 'Discarded drug not administered'
    },
    {
      code: '79',
      description: 'Physician performed an unrelated procedure within the global period'
    }
   
  ];

  constructor() {}

  getModifiers () {
    return this.modifiers;
  }
}
