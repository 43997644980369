import moment from "moment";

interface INote {
    _id?:string;
    title:string,
    htmlText:string;
    uploadedBy:{};
    provider:{};
    facility:{};
    patient:{};
    // state: {
    //     active:boolean,
    //     published:boolean,
    //     locked:boolean,
    //     signed:boolean,
    //     uploaded:boolean
    // }
    is_active: 'true'|'false';
    is_signed: 'true'|'false';
    is_draft: 'true'|'false';

    dates: {
        service: Date | any,

        creation: Date | any,

        sign: Date,

        upload: Date,

        timezone?:string
    };


    note_status: {
        status: 'in_progress'|'internal_qa'|'external_qa'|'published'|'signed',
        id: string|any; // 'owner_id'|'qa_internal_id'|'qa_external_id'|'provider_id'
    };
    coSign_status:{
        status,
        coSigned_to,
        coSigned_by,
    };
    coSigned_provider?:{
        _id,
        first_name,
        last_name,
        title
    }

    audio?:{};

    patient_id?:string;

    rounding_sheet_id?:string;

    edit_lock: {
        lockedOn: Date,
        userId: string
    }

    followUpNote?:{
        _id:string,
        data: {title: string, section: string}[];
    };
    lockedBy?: string

}

export class Note implements INote {
    _id?:string;
    title: string;
    htmlText: string;
    uploadedBy: any;
    provider: any;
    facility: any;
    patient: any;
    
    // state: { active: boolean; published: boolean; locked: boolean; signed: boolean; uploaded: boolean; };
    is_active: 'true'|'false';
    is_signed: 'true'|'false';
    is_draft: 'true'|'false';
    
    dates: {
        service: Date | any,

        creation: Date | any,

        sign: Date,
        
        upload: Date,

        timezone?:string
    }


    note_status: {
        status,
        id
    }
    coSign_status:{
        status ,
        coSigned_to,
        coSigned_by
    }
    coSigned_provider?:{
        _id,
        first_name,
        last_name,
        title
    }

    audio?;

    pcc_progressNoteId?;

    patient_id?:string;

    rounding_sheet_id?:string;

    edit_lock: {
        lockedOn: Date,
        userId: string
    }

    followUpNote?:{
        _id:string,
        transcribtion_title?:string,
        data: {title: string, section: string}[];
    };
    lockedBy?: string
    constructor(note?) {
        this._id = note?._id || null;
        this.audio = note?.audio;
        this.title = note?.title ||  '';
        this.htmlText = note?.htmlText || '';
        this.dates = note?.dates || {
            service: new Date(),
            creation: new Date(),
            sign: null,
            upload: null,
        },
        this.uploadedBy = note?.uploadedBy;
        this.provider = note?.provider;
        this.facility = note?.facility;
        this.patient = note?.patient;
        this.note_status = note?.note_status;
        this.coSign_status = note?.coSign_status; 
        this.coSigned_provider = note?.coSigned_provider; 
        this.is_signed = note?.is_signed;
        this.is_active = note?.is_active;
        this.is_draft = note?.is_draft;
        this.pcc_progressNoteId = note?.pcc_progressNoteId;
        this.patient_id = note?.patient_id;
        this.rounding_sheet_id = note?.rounding_sheet_id;
        this.edit_lock = note?.edit_lock;

        this.followUpNote = note?.followUpNote;
        this.lockedBy = note?.lockedBy;
    }

    isInProgress(userId, relationship) {
        if(!this._id) return false;
        // return this.note_status.status === 'in_progress' && this.note_status.id === userId;
        return this.note_status.status === 'in_progress' && (relationship?.assoc_trancriber_id.includes(userId) || this.uploadedBy == userId);
    }
    isInMAReview(userId, relationship) {
        if(!this._id) return false;
        // return this.note_status.status === 'internal_qa' && this.note_status.id === userId;
        
        return this.note_status.status === 'in_progress' && relationship?.assoc_ma_ids?.includes(userId);
    }

    isInQAIReview(userId, relationship) {
        if(!this._id) return false;
        // return this.note_status.status === 'internal_qa' && this.note_status.id === userId;
        return this.note_status.status === 'internal_qa' && relationship.qa_id === userId;
    }

    isInQAEReview(userId, relationship) {
        if(!this._id) return false;
        // return this.note_status.status === 'external_qa' && this.note_status.id === userId;
        return this.note_status.status === 'external_qa' && relationship.qa_e_id === userId;
    }

    isPublished(userId?, relationship?) {
        if(!this._id) return false;

        const isNotePublished = this.note_status.status === 'publish' || this.note_status.status === 'published';
        if(userId && relationship) {
            // return isNotPublished && this.note_status.id === userId;
            return isNotePublished && (this.provider._id === userId || userId === this.coSign_status?.coSigned_to);
        } else {
            return isNotePublished &&  userId === this.coSign_status?.coSigned_to;
        }
    }
    
    isSigned() {
        if(!this._id) return false;

        const isNotUploaded = this.note_status.status === 'not_uploaded' || this.is_signed === 'true';
        return isNotUploaded;
        // if(userId) {
        //     return isNotUploaded && this.note_status.id === userId;
        // } else {
        //     return isNotUploaded;
        // }
        // return this.is_signed === 'true';
    }

    isUploaded() {
        if(!this._id) return false;
        return this.note_status.status === 'uploaded';
    }
    isUploadedToPCC() {
        if(!this._id) return false;
        return !!this.pcc_progressNoteId;
    }

    
    hasPCCPatient() {
        if(!this._id) return false;
        return !!this.patient?.pcc_patientId;
    }

    canCreateAddendum(userId) {
        return this.note_status.status === 'uploaded' && this.uploadedBy?._id === userId;
    }
    isInvalid() {
        if(!this.title) return "Title is required";
        if(!this.htmlText) return "Note text is required";
        if(!moment(this.dates?.service).isValid()) return "Note DOS is required";
        if(!this.provider) return "Provider is required";
        if(!this.facility) return "Facility is required";
        if(!(this.patient && this.patient._id)) return "Patient is required";
        return false;
    }
    relation(userId, relationship) {
        return {
            owner: this.isInProgress(userId, relationship),
            isQAI: this.isInQAIReview(userId, relationship),
            isQAE: this.isInQAEReview(userId, relationship),
            provider: this.isPublished(userId),
        }
    }

    hasAudio() {
        return !!this.audio;
    }

    isRelatedProvider(user_id) {
        // user_id === this.note_status
        
        return this.provider?._id === user_id || user_id == this.coSign_status?.coSigned_to;
    }

    containsAsterisks() {
        return this.htmlText.match(/\*{3,}/) !== null
    }
    isEditLocked(userId) {
        if(this.lockedBy) {
            return userId != this.lockedBy;
        }
        else return false;
    }
}
