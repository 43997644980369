import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Payor} from "../../../classes/payor";
import {Interface} from "readline";
import {AppointmentDataService} from "../../../components/appointment/service/data.service";
import {ToastrService} from "ngx-toastr";

class Contact {
    payor: any;
    first_name: string;
    last_name: string;
    extension: string;
    phone: string;
    position: string;
    email: string;
}

@Component({
    selector: 'app-payor-contact-dialog',
    templateUrl: './payor-contact-dialog.component.html',
    styleUrls: ['./payor-contact-dialog.component.css']
})
export class PayorContactDialogComponent implements OnInit {
    payor_id: any;
    public model = new Contact();
    update = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private toastr: ToastrService,
                private dialogRef: MatDialogRef<PayorContactDialogComponent>,
                private dataService: AppointmentDataService) {
        if (data.payor_id) {
            this.payor_id = data.payor_id;
        } else if (data.update === true) {
            this.update = true;
            this.model = data.contact;
        }

    }

    ngOnInit(): void {
    }

    addContact(obj) {
        if (this.update === true) {
            this.updateContact(this.model);
            return;
        }
        this.model.payor = this.payor_id;
        this.dataService.addPayorContact(this.model)
            .subscribe( res => {

                this.toastr.success('IME Contact added', 'Success');
                this.dialogRef.close();
                this.model = new Contact();
            })
    }

    updateContact(contact) {
        this.dataService.updatePayorContact(this.model)
            .subscribe(res => {
                this.toastr.success('IME Contact updated', 'Success');
                this.dialogRef.close();
                this.model = new Contact();
            });
    }

}
