import { Component, OnInit } from '@angular/core';
// import MicrophoneStream from 'microphone-stream'; // collect microphone input as a stream of raw bytes
// import getUserMedia from 'get-user-media-promise';
// const audioUtils = require('./audioUtils');  // for encoding audio data as PCM
// const crypto            = require('crypto'); // tot sign our pre-signed URL
// const v4                = require('./aws-signature-v4'); // to generate our pre-signed URL
import { EventStreamCodec } from "@smithy/eventstream-codec";
import { toUtf8, fromUtf8 } from '@smithy/util-utf8'; // utilities for encoding and decoding UTF8
import arrayBufferToBuffer from 'arraybuffer-to-buffer';
// our converter between binary event streams messages and JSON
import { AmazonMedicalTranscribeService } from '../amazon-medical-transcribe.service';

@Component({
  selector: 'app-amazon-medical-transcribe',
  templateUrl: './amazon-medical-transcribe.component.html',
  styleUrls: ['./amazon-medical-transcribe.component.css']
})
export class AmazonMedicalTranscribeComponent implements OnInit {
  isMicOn = false;
  enableStart = true;
  transcript = '';
  micStream: any;
  url: any;
  ref: any;
  sampleRate = 0;
  inputSampleRate;
  socket;
  languageCode;
  region;
  transcription = "";
  socketError = false;
  transcribeException = false;
  eventStreamMarshaller = new EventStreamCodec(toUtf8, fromUtf8);

  constructor(
    private amazonMedicalTranscribeService: AmazonMedicalTranscribeService,
    ) {
  }

  ngOnInit(): void {
    if (!window.navigator.mediaDevices.getUserMedia) {
      // Use our helper method to show an error on the page
      // showError('We support the latest versions of Chrome, Firefox, Safari, and Edge. Update your browser and try your request again.');
      console.log("Error:  We support the latest versions of Chrome, Firefox, Safari, and Edge. Update your browser and try your request again.");

      // maintain enabled/distabled state for the start and stop buttons
      this.stopButtonClick()
    }
  }
  pcmEncode(input) {
    var offset = 0;
    var buffer = new ArrayBuffer(input.length * 2);
    var view = new DataView(buffer);
    for (var i = 0; i < input.length; i++, offset += 2) {
      var s = Math.max(-1, Math.min(1, input[i]));
      view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
    }
    return buffer;
  }
  downsampleBuffer(buffer, inputSampleRate = 44100, outputSampleRate = 16000) {

    if (outputSampleRate === inputSampleRate) {
      return buffer;
    }

    var sampleRateRatio = inputSampleRate / outputSampleRate;
    var newLength = Math.round(buffer.length / sampleRateRatio);
    var result = new Float32Array(newLength);
    var offsetResult = 0;
    var offsetBuffer = 0;

    while (offsetResult < result.length) {
      var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
      var accum = 0,
        count = 0;

      for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
        accum += buffer[i];
        count++;
      }
      result[offsetResult] = accum / count;
      offsetResult++;
      offsetBuffer = nextOffsetBuffer;
    }
    return result;
  }

  convertAudioToBinaryMessage(audioChunk) {
    // 
    // let raw = MicrophoneStream.toRaw(audioChunk);
    // 
    // if (raw == null)
      return;

    // 
    // downsample and convert the raw audio bytes to PCM
    // let downsampledBuffer = this.downsampleBuffer(raw, this.inputSampleRate, this.sampleRate);
    // let pcmEncodedBuffer = this.pcmEncode(downsampledBuffer);

    // add the right JSON headers and structure to the message
    // let audioEventMessage: any = this.getAudioEventMessage(Buffer.from(pcmEncodedBuffer));

    // convert the JSON object + headers into a binary event stream message
    // let binary = this.eventStreamMarshaller.marshall(audioEventMessage);

    // return binary;
  }
  wireSocketEvents() {
    
    // let comContext = this;
    // handle inbound messages from Amazon Transcribe
    this.socket.onmessage = this.handleSocketMessage

    this.socket.onerror = (error) => {
      this.socketError = true;
      console.log("WebSocket connection error. Try again.");
      // showError('WebSocket connection error. Try again.');
      // toggleStartStop();
      this.stopButtonClick()
    };

    this.socket.onclose = (closeEvent) => {
      this.micStream.stop();

      // the close event immediately follows the error event; only handle one.
      if (!this.socketError && !this.transcribeException) {

        if (closeEvent.code != 1000) {
          console.log('</i><strong>Streaming Exception</strong><br>' + closeEvent.reason);
          // showError('</i><strong>Streaming Exception</strong><br>' + closeEvent.reason);
        }
        // toggleStartStop();
        this.stopButtonClick()
      }
    };
  }

  handleSocketMessage(message: any) {

    // 
    //convert the binary event stream message to JSON
    if (!this.eventStreamMarshaller) {
      
    }
    let messageWrapper = this.eventStreamMarshaller.decode(Buffer.from(message.data));
    let messageBody = JSON.parse(String.fromCharCode.apply(String, messageWrapper.body));
    
    if (messageWrapper.headers[":message-type"].value === "event") {
      this.handleEventStreamMessage(messageBody);
    }
    else {
      
      this.transcribeException = true;
      console.log("error in wireSocketEvent: ", messageBody.Message);
      // showError(messageBody.Message);
      this.stopButtonClick()
      // toggleStartStop();
    }
  }
  handleEventStreamMessage(messageJson) {
    // 
    let results = messageJson.Transcript.Results;

    if (results.length > 0) {
      if (results[0].Alternatives.length > 0) {
        let transcript = results[0].Alternatives[0].Transcript;

        // fix encoding for accented characters
        transcript = decodeURIComponent(escape(transcript));

        // update the textarea with the latest result
        // $('#transcript').val(this.transcription + transcript + "\n");
        console.log("==: ", this.transcription + transcript);

        // if this transcript segment is final, add it to the overall transcription
        if (!results[0].IsPartial) {
          //scroll the textarea down
          // $('#transcript').scrollTop($('#transcript')[0].scrollHeight);

          this.transcription += transcript + "\n";
        }
      }
    }
  }

  startButtonClick() {
    this.enableStart = false;
    console.log("Start Button Clicked");
    // first we get the microphone input from the browser (as a promise)...
    window.navigator.mediaDevices.getUserMedia({
      // getUserMedia({
      video: false,
      audio: true
    })
      // ...then we convert the mic stream to binary event stream messages when the promise resolves 
      .then((stream) => this.streamAudioToWebSocket(stream))
      .catch(function (error) {
        console.log(error);
        console.log("Error--------:", error);
        // 
        // showError('There was an error streaming your audio to Amazon Transcribe. Please try again.');
        // toggleStartStop();
      });

  }
  //   createPresignedUrl() {
  //     let endpoint = "transcribestreaming.us-east-1.amazonaws.com:8443";

  //     // get a preauthenticated URL that we can use to establish our WebSocket
  //     return v4.createPresignedURL(
  //         'GET',
  //         endpoint,
  //         '/stream-transcription-websocket',
  //         'transcribe',
  //         crypto.createHash('sha256').update('', 'utf8').digest('hex'), {
  //             'key': $('#access_id').val(),
  //             'secret': $('#secret_key').val(),
  //             'sessionToken': $('#session_token').val(),
  //             'protocol': 'wss',
  //             'expires': 15,
  //             'region': 'us-east-1',
  //             'query': "language-code=en-US&media-encoding=pcm&sample-rate=44100"
  //         }
  //     );
  // }
  streamAudioToWebSocket(userMediaStream) {
    // 
    //let's get the mic input from the browser, via the microphone-stream module
    // this.micStream = new MicrophoneStream();
    
    this.micStream.on("format", (data) => {
      
      this.inputSampleRate = 44100 //data.sampleRate;
    });
    this.micStream.setStream(userMediaStream);
    // 
    // Pre-signed URLs are a way to authenticate a request (or WebSocket connection, in this case)
    // via Query Parameters. Learn more: https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html
    // this.url = this.createPresignedUrl()
    // this.url = 'wss://transcribestreaming.us-east-1.amazonaws.com:8443/stream-transcription-websocket?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H6BY4RZ2R7V7AEH%2F20210811%2Fus-east-1%2Ftranscribe%2Faws4_request&X-Amz-Date=20210811T103841Z&X-Amz-Expires=15&X-Amz-Signature=aacf9b3a438dd69e462f07d33fe3e32cd5ac39dbfd82b02f3a925ff38050a5ad&X-Amz-SignedHeaders=host&language-code=en-US&media-encoding=pcm&sample-rate=44100'

    this.amazonMedicalTranscribeService.getAwsSignedUrl().subscribe((res: any) => {
      if (res.status == 200) {
        // if (this.url) {
          this.url = res.data;
          // this.url = "wss://transcribestreaming.us-east-1.amazonaws.com:8443/stream-transcription-websocket?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H6BY4RZ2R7V7AEH%2F20210811%2Fus-east-1%2Ftranscribe%2Faws4_request&X-Amz-Date=20210811T111907Z&X-Amz-Expires=15&X-Amz-Signature=750ce478318dbd23032e91efc1750cb37603e082b9a052c8e913dea6d641fb18&X-Amz-SignedHeaders=host&language-code=en-US&media-encoding=pcm&sample-rate=44100";
          console.log("URL: ", this.url);
          //open up our WebSocket connection
          // 
          this.socket = new WebSocket(this.url);
          this.socket.binaryType = "arraybuffer";
          // 
          // when we get audio data from the mic, send it to the WebSocket if possible
          this.socket.addEventListener('open', (ref) => {
            let binary;
            console.log("ref: ", ref);
            
            let cont = this;
            this.micStream.on("data", function (rawAudioChunk) {
              
              // the audio stream is raw audio bytes. Transcribe expects PCM with additional metadata, encoded as binary
              binary = cont.convertAudioToBinaryMessage(rawAudioChunk);
              // 

              if (cont.socket.readyState === cont.socket.OPEN)
                cont.socket.send(binary);
            })
          })
          // 
          this.wireSocketEvents();
        }
        else {
          console.log("failiar");

        }
    })

  }

  async getAwsSignedUrl() {
    console.log("getAwsSignedUrl========: ");
    // 
    return await this.amazonMedicalTranscribeService.getAwsSignedUrl().toPromise()
  }

  stopButtonClick() {
    console.log("Stop Button Clicked");
    this.enableStart = true;
    this.closeSocket();

  }
  closeSocket() {
    if (this.socket.readyState === this.socket.OPEN) {
      this.micStream.stop();
      alert('Socket closed')
      // Send an empty frame so that Transcribe initiates a closure of the WebSocket after submitting all transcripts
      let emptyMessage: any = this.getAudioEventMessage(new Buffer([]));
      let emptyBuffer = this.eventStreamMarshaller.encode(emptyMessage);
      this.socket.send(emptyBuffer);
    }
  }
  getAudioEventMessage(buffer) {
    // wrap the audio data in a JSON envelope
    return {
      headers: {
        ':message-type': {
          type: 'string',
          value: 'event'
        },
        ':event-type': {
          type: 'string',
          value: 'AudioEvent'
        }
      },
      body: buffer
    };
  }



}
