
<div style="display: flex; width: 100%; height:80%; justify-content: center;align-items: center; flex-direction: column;">
    <div class="flex flex-row xs:flex-col items-center justify-center" style="width: 100%;">
        <mat-card class="w-40 md:w-full">
      
          <mat-card-title class="flex flex-col items-center justify-center">
            <img src="./../../assets/img/DNlogo-new.png">
          </mat-card-title>
          <mat-card-content class="flex flex-col">
            <div style="display: flex; justify-content: center; flex-direction: column;"
            >          
            <div class="card-content">
                <div class="card-label">User Status :</div>
                <p class="card-value" [ngClass]="userNotApproved() && !isUserActive ? 'color-red': 'color-green'">
                    {{userNotApproved() && !isUserActive? "Not Verified": "Verified" }}
                </p>
            </div>     
                <div class="card-content">
                    <mat-form-field >
                        <mat-label>Name</mat-label>
                        <input  matInput type="text" [(ngModel)]="userInfo.name" readonly placeholder="Name"/>
                    </mat-form-field>
                </div>
                <div class="card-content">
                    <mat-form-field>
                        <mat-label>Email</mat-label>
                        <input  matInput type="text" [(ngModel)]="userInfo.email" readonly placeholder="Name"/>
                    </mat-form-field>
                </div>
                <div *ngIf="!twofacTrue && userNotApproved()" style="margin: auto;">
                    <button *ngIf="!twofacTrue && !isCodeSent && userNotApproved()" mat-raised-button class="dn-button-primary button"
                        (click)="get2FacAuth()">
                        Verify User
                    </button>
                </div>
                <div class="card-content"  *ngIf="!twofacTrue && userNotApproved() && isCodeSent">
                    <mat-form-field  class="white-input">
                        <mat-label>Enter verification code</mat-label>
                        <input matInput type="text" [(ngModel)]="twofacAuthCode" />
                    </mat-form-field>
                </div>
                    <div class="card-content" *ngIf="!twofacTrue && userNotApproved() && isCodeSent">
                        <button mat-raised-button class="dn-button-primary button"
                            (click)="verify2FacAuth()">
                            Approve User
                        </button>
                    </div>
            </div>
          </mat-card-content>
      
        </mat-card>
      </div>
</div>
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

