import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CensusPatientListService } from 'src/app/includes/census-patient-list/census-patient-list.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-census-file-upload-dialoge',
  templateUrl: './census-file-upload-dialoge.component.html',
  styleUrls: ['./census-file-upload-dialoge.component.css']
})
export class CensusFileUploadDialogeComponent implements OnInit {
  currentUser:any
  fileTypes =
    [
      { name: 'Goal Status Report (NetHealth)', value: 'Goal Status Report' },
      { name: 'Weekly Functional Status Report (NetHealth)', value: 'Weekly Functional Status Report' },
      { name: 'Census Details Report (NetHealth)', value : 'Patient Census Details Report' },
      { name: 'TENs Report (NetHealth)', value : 'Tens Report'}
    ];
  selectedFileType:any;
  selectedFiles: FileList;
  censusFile:any;
  progressInfos = []
  @ViewChild('file_uploader') file_uploader: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<CensusFileUploadDialogeComponent>,
    private authService: AuthService,
    private censusPatientListService: CensusPatientListService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService,
  ) {
    this.currentUser = this.authService.currentUser;
   }

  ngOnInit(): void {
  }
  selectFiles(event) {
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
  }
  upload(input){
    this.toastr.info('Uploading File');
    let document = input.files[0]; 
    this.censusPatientListService.uploadCensusFile(this.data.census_id,this.data.provider, this.data.facility._id,this.selectedFileType,document,this.data.company_id).subscribe((response:any)=>{
      if(response.status == 200){
        this.toastr.success('File Uploaded');
        console.log("response", response);
        this.censusFile = null;
        this.selectedFileType = null;
        this.dialogRef.close(true);
      }
      else{
        this.toastr.error('Something went wrong while Uploading File');
      }
    })
  }
  //if multiple is enabled
  fileName(){
    if(this.selectedFiles){
      if(this.selectedFiles.length == 1){
        return this.selectedFiles[0].name;
      }
      else if(this.selectedFiles.length > 1){
        return this.selectedFiles[0].name + ' +' + (Number(this.selectedFiles.length)-1) + ' more' ; 
      }
    }
  }
  cancelClicked(){
    this.dialogRef.close();
  }
}
