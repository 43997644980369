import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ToastrService } from "ngx-toastr";


// UPLOAD FILE DIALOG
export interface RrgenExplorerFilesUploadDialogData {
  parent_id: string,
  filesObject: any;
}
@Component({
  selector: 'rrgen-explorer-files-upload-dialog',
  templateUrl: './rrgen-explorer-files-upload-dialog.html',
  styleUrls: ['./rrgen-explorer-files-upload-dialog.css']
})
export class RRgenExplorerFilesUploadDialog implements OnInit {
  filesObject: any;
  filesData = {};

  dataSource : MatTableDataSource<any>;
  displayedColumns = [
    "serial_no",
    "file_name",
    "sign",
    "type",
  ];

  constructor(
    public dialogRef: MatDialogRef<RRgenExplorerFilesUploadDialog>,
    @Inject(MAT_DIALOG_DATA) public data: RrgenExplorerFilesUploadDialogData,
    private toastr:ToastrService) {
    this.filesObject = data.filesObject;
    
    for (const file of this.files) {
      this.filesData[file.name]= {
        type: 'Other',
        sign: true
      }
    }
  }
  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.files);
  }

  get folderName(): string{
    return <string>Object.keys(this.filesObject)[0];
  }
  get files(): any[] {
    return <any[]>Object.values(this.filesObject)[0];
  }

  upload() {

    const isMedFileExists = Object.values(this.filesData).find((fileData: any) => fileData.type === 'Medical Record');
    if (!isMedFileExists) {
      return this.toastr.error('You must select atleast one Medical Record file')
    }

    this.dialogRef.close(this.filesData)
  }
}
