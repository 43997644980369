<div class="centered">
    <div class="flex items-center justify-center" style="color: white;">
        <h1 >403 - Forbidden </h1>
        
    </div>
    <div class="flex items-center justify-center">
        <p style="color: white;"> You are not authorized to view this page.</p>
    </div>
    <div class="flex items-center justify-center">
        <p *ngIf="!currentUser" style="color: white;">Please <a href="">login</a> to view this page.</p>
    </div>
</div>