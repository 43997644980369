<div id="communicationTab" style="overflow-y: hidden;" [ngClass]="(comments.length > 0) ? 'media':'media2'"
  [ngStyle]="{height: !getParent()? '99%':'97%'}">
  <ng-container>
    <div style="overflow-y: scroll;" id="mainDiv"
      [ngStyle]="{height: !getParent()? 'calc(100vh - 268px)':'calc(100vh - 430px)'}">
      <div *ngFor="let comment of comments; let i=index" style="margin-bottom: 5px;">
        <div style="background-color: white;margin-bottom: 5px;border-radius: 5px;">
          <div class="flex flex-col" style="width: 100%;height: 100%;padding-left: 5px;">
            <div class="flex justify-between items-center" style="margin-bottom: -21px;margin-top: -5px;">
              <div class="flex-grow">
                <strong>{{comment?.created_by?.first_name}} {{comment?.created_by?.last_name}}</strong>
              </div>
              <div class="flex-grow justify-end items-start">
                <p style="font-size: smaller; margin-top: 5px;">{{comment?.updatedAt | date:'medium'}}</p>
              </div>
            </div>
            <div style="margin-top: 1rem;">
              <img *ngIf=comment?.attachment_link style="height: 500px;  width: 100%;" [src]="comment?.attachment_link"
                alt="">
              <p style="margin-bottom: 0px;font-size: 13px;" *ngIf="comment?.comment!=='undefined'"
                [innerHTML]="comment?.comment"></p>
              <div style="margin-top: 5px;">
                <small *ngIf="sameUser(comment)" style="color:rgb(0, 0, 136); cursor: pointer;font-size: 14px;"
                  (click)="editCommunicationComment(comment)">Edit</small><span
                  *ngIf="sameUser(comment)">&nbsp;&nbsp;&bull;</span>
                <small *ngIf="sameUser(comment)"
                  style="color:rgb(146, 0, 19); cursor: pointer;margin-left: 10px;font-size: 14px;"
                  (click)="deleteCommunicationComment(comment._id,i)">Delete</small><span
                  *ngIf="sameUser(comment)">&nbsp;&nbsp;&bull;</span>
                <small *ngIf="comment?.count"
                  style="color:rgb(0, 0, 136); cursor: pointer;margin-left: 10px;font-size: 14px;"
                  (click)="replyToComment(comment)">Reply ({{comment?.count}})</small>
                <small *ngIf="!comment?.count"
                  style="color:rgb(0, 0, 136); cursor: pointer;margin-left: 10px;font-size: 14px;"
                  (click)="replyToComment(comment)">Reply</small>
              </div>
            </div>
            <div *ngIf="comment.selected" class="conatiner-panel animate-top">
              <div *ngFor="let repliedcomment of repliedComments; let i=index">
                <div class="flex flex-col" style="width: 100%;height: 100%;">
                  <div class="flex justify-between items-center"
                    style="margin-top: -5px;">
                    <div class="flex-grow">
                      <strong>{{repliedcomment?.created_by?.first_name}}
                        {{repliedcomment?.created_by?.last_name}}</strong>
                    </div>
                    <div class="flex-grow justify-end items-start">
                      <p style="font-size: smaller;">{{repliedcomment?.updatedAt | date:'medium'}}</p>
                    </div>
                  </div>
                  <div>
                    <img *ngIf=repliedcomment?.attachment_link style="height: 500px;  width: 100%;"
                      [src]="repliedcomment?.attachment_link" alt="">
                    <p style="margin-bottom: 0px;font-size: 13px" *ngIf="repliedcomment?.comment!=='undefined'"
                      [innerHTML]="repliedcomment?.comment"></p>
                    <div *ngIf="sameUser(repliedcomment)" style="margin-top: 5px;">
                      <small style="color:rgb(0, 0, 136); cursor: pointer;font-size: 14px;"
                        (click)="editCommunicationComment_reply(repliedcomment)">Edit</small><span>&nbsp;&nbsp;&bull;</span>
                      <small style="color:rgb(146, 0, 19); cursor: pointer;margin-left: 10px;font-size: 14px;"
                        (click)="deleteCommunicationComment_reply(repliedcomment._id,i)">Delete</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex" style="display: flex;">
                <mat-form-field class="example-full-width flex-grow" style="color: black !important;" 
                  appearance="fill">
              <div style="display:flex">

                  <textarea matInput type="text" #repliedSection id="repliedSection_input"
                    [value]="editCommentText_reply" [mention]="providers" (itemSelected)="addMentions_reply($event)"
                    [mentionConfig]="{triggerChar:'@', dropUp:true, labelKey:'last_name', disableSearch:true, allowSpace:true}"
                    [mentionListTemplate]="mentionListTemplate" (searchTerm)="serchWithTerm($event)"
                    placeholder="Add a Reply to Comment"></textarea>

                    <div style="display:flex; flex-direction:row-reverse">
                      <button mat-button mat-icon-button matSuffix (click)="onSaveButton_reply()">
                        <mat-icon>send</mat-icon>
                      </button>
                      <button *ngIf="isEdit_reply" mat-button mat-icon-button matSuffix (click)="clearReply()">
                        <mat-icon>close</mat-icon>
                      </button>
                    </div>
                  </div>
                  <ng-template #mentionListTemplate let-item="item">
                    <span>{{item.last_name+' '+item.first_name+', '+item.title}}</span>
                  </ng-template>

                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="comments.length == 0 && dataLoaded" style="display: flex;justify-content: center;margin-top: 35vh;">
        There are no comments at this time!</div>
    </div>
  </ng-container>

  <div *ngIf="dataLoaded"
    style="position: sticky; width: 99%; bottom: 1px; left:1px; right:1px; background-color: whitesmoke;">
    <div class="flex" style="display: flex;">

      <mat-form-field class="mention-list flex-grow" style="color: black !important;width: 100%; margin-top: 3px; " appearance="fill">
        <mat-label>Leave a comment (use &#64;Last Name for tagging
          providers) </mat-label>
          <div style="display:flex">
        <textarea matInput type="text" id="commentSection_input" [mention]="providers" [value]="editCommentText"
          (itemSelected)="addMentions($event)"
          [mentionConfig]="{triggerChar:'@', dropUp:true, labelKey:'full_name', disableSearch:true, allowSpace:true}"
          [mentionListTemplate]="mentionListTemplate" (searchTerm)="serchWithTerm($event)"
          placeholder="Enter a Comment">
        </textarea>
        <div style="display:flex; flex-direction:row-reverse">
          <button mat-icon-button matSuffix (click)="onSaveButton()">
            <mat-icon>send</mat-icon>
          </button>
          <button *ngIf="isEdit" mat-icon-button matSuffix (click)="clearComment()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
          <ng-template #mentionListTemplate let-item="item">
            <span>{{item.last_name+' '+item.first_name+', '+item.title}}</span>
          </ng-template>
        </mat-form-field>
    </div>
  </div>

</div>