import { Injectable } from '@angular/core';
import * as global from '../includes/global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PusherService } from './pusher-service';
import {eventTypes} from '../constants/pcc_webhook_constants'
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import {API_URL} from "../../environments/api-url";
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class MatrixService {
 private currentUser:any;
 private userInfo:any;

  defaultFac;

  company_id: any;

 constructor(
    private httpclient: HttpClient, 
    private _authService:AuthService, 
    private _route:ActivatedRoute,
    private _router:Router,
    private _pusherService:PusherService,
    private _toastr:ToastrService,
    private commonService: CommonService,
   ) {
    this._init()
  }

  _init() {
    this.currentUser = this._authService.currentUser;
    if(!this.currentUser) {
      return
    }

    this.userInfo = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type
    }

  }
  
  mergeMatrixPatient( matrixPatientID,duplicatePatientID,source_patients,primaryProviderID, facilityID) {
    this.commonService.castCompany.subscribe(company=>{
       this.company_id = company?._id
     })
   if(!this.company_id){
     this.company_id=this.currentUser.company_id
   }
   const details = {
     id: this.currentUser._id,
     auth_key: this.currentUser.auth_key,
     usertype: this.currentUser.user_type,
     company_id:this.company_id,
     duplicatePatientID,
     source_patients,
     matrixPatientID,
     primaryProviderID,
     facilityID
   }
   return this.httpclient.post(global.url + API_URL.MATRIXCARE_MIRTH.mergeMatrixPatient, details);
 }

  importMatrixPatient( matrixPatientID, primaryProviderID, facilityID, createAnyway = false) {
    this.commonService.castCompany.subscribe(company=>{
       this.company_id = company?._id
     })
   if(!this.company_id){
     this.company_id=this.currentUser.company_id
   }
   const details = {
     id: this.currentUser._id,
     auth_key: this.currentUser.auth_key,
     usertype: this.currentUser.user_type,
     company_id:this.company_id,

     matrixPatientID,
     primaryProviderID,
     facilityID,
     createAnyway
   }

   return this.httpclient.post(global.url + API_URL.MATRIXCARE_MIRTH.importMatrixPatient, details);
 }


}
