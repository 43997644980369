import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from 'src/app/includes/dashboard/dashboard.service';
import { TemplatesService } from 'src/app/includes/templates/templates.service';
import { UserManagementService } from 'src/app/includes/user-management/user-management.service';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../../../includes/global';

@Component({
  selector: 'app-doctor-profile',
  templateUrl: './doctor-profile.component.html',
  styleUrls: [ './doctor-profile.component.css' ]
})
export class DoctorProfileComponent implements OnInit {
  defaultTabIndex: Number = 0;
  global = global;
  currentUser: any;
  userId;
  checkUrl;
  onlyProfile: boolean = false;
  showBar: boolean = false;
  currentTab;
  userDetail: any;
  selectedUserType: any;

  constructor(
    private _route: ActivatedRoute,
    private authService: AuthService,
    private dashboardService: DashboardService,
    private userManagementService: UserManagementService,
  ) {
    if(this._route.snapshot.params.census) {
      this.defaultTabIndex = 1;
    }
  }

  ngOnInit(): void {
    this.userManagementService.currentTab.subscribe((currentTab: string) => {
      this.currentTab = currentTab;
    });
    if (this.currentTab) {
      this.defaultTabIndex = this.currentTab;
      // this.userManagementService.setCurrentTab(null);
    }
    this.currentUser = this.authService.currentUser;
    this.userId = this._route.snapshot.params.provider_id;
    this.getUser();
  }

  async getUser() {
    const response: any = await this.dashboardService.getUserDetail(this.userId).toPromise();
    if ( response.status == 200 ) {
      if ([ 'nurse', 'medical_assistant' ].includes( response.data.array.user_type.toLowerCase())) {
        this.onlyProfile = true;
      }
      this.selectedUserType = response.data.array.user_type;
      console.log(this.selectedUserType);
    }

    this.userDetail = response.data.array;
    this.showBar = true;
  }

  changeCurrentTab( $event ) {
    this.defaultTabIndex = $event.index;
    this.userManagementService.setCurrentTab( $event.index );
  }
}
