import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, NgZone, OnDestroy } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TemplatesService } from '../templates/templates.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Location, DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { Template } from '../templates/templates.component';
import * as global from '../global';
import { FileConversionService } from 'src/app/services/file-conversion.service';
import { TranscriptionCreateService } from '../transcription-create/transcription-create.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { SocketService } from 'src/app/services/socket.service';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from "../../../environments/api-url";
import { FormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { lastValueFrom, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { TitleService } from 'src/app/services/title.service';
import * as _ from 'lodash';
import { USER_TYPE } from '../global'
import { MatDialog } from '@angular/material/dialog';
import { PreviewTemplateDialog } from './preview-template-dialog/preview-template-dialog.component';
import { CKEditorService } from '../note-editor/ckeditor.service';
import { PhrasesService } from '../phrases/phrases.service';
import { TemplateExplorerService } from '../templates/template-explorer/template-explorer.service';
import { CommonService } from 'src/app/services/common.service';
import { CensusListService } from '../census-list/census-list..service';
import { GeneralConfirmationDialogComponent } from '../general-confirmation-dialog/general-confirmation-dialog.component';
import moment from 'moment';
import swal from 'sweetalert';
import { SnfWcNurseAssociationService } from '../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { CompanyService } from 'src/app/services/Modules/company.service';

@Component({
    selector: 'app-template-create',
    templateUrl: './template-create.component.html',
    styleUrls: [ './template-create.component.css' ],
    providers: [DatePipe]
})
export class TemplateCreateComponent implements OnInit, AfterViewInit, OnDestroy {
    global = global;
    USER_TYPE = USER_TYPE;
    loading: boolean = false;
    
    config: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '100%',
        placeholder: 'Enter text here...',
        translate: 'no',
        customClasses: [
            {
                name: "quote",
                class: "quote",
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: "titleText",
                class: "titleText",
                tag: "h1",
            },
        ]
    }
    currentUser: any;
    template: Template;
    allFacilities = [];
    noteTitles = [];
    selectedFacilities = [];
    selectedNoteTitles = [];
    allNoteTitles = [];
    note_builder_list = [];
    selected_builder = '';
    // @ViewChild(SelectAutocompleteComponent) multiSelect: SelectAutocompleteComponent;

    sidebarVisible: boolean = false;

    @ViewChild('facilityInput') facilityInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;

    separatorKeysCodes: number[] = [ENTER, COMMA];
    facilityCtrl = new FormControl();
    filteredFacilities: Observable<any[]>;
    facilities = [];

    noteTitleDropdownSettings = {
        singleSelection: true,
        idField: '_id',
        textField: 'title',
        allowSearchFilter: false,
    };
    cke4Config;
    provider;
    companySide: Boolean = false;
    company_id = '';
    template_type = 'user_defined';
    export: boolean;
    adminCreate: boolean;
    adminSave: boolean;
    templateIdFromUrl: any = "";
    facilitySources = [
        {
            value: null,
            label: '--unselect--'
        },
        {
            value: 'dn',
            label: 'DN'
        },
        {
            value: 'pcc',
            label: 'PCC'
        },
        {
            value: 'matrix',
            label: 'MatrixCare'
        }
    ];
    selectedFacilitySource = null;
    forNoteTypes = [
        {
            value: null,
            label: '--unselect--'
        },
        {
            value: 'IE',
            label: 'Initial'
        },
        {
            value: 'FU',
            label: 'Follow Up'
        },
        {
            value: 'NS',
            label: 'Not Seen'
        }
    ];
    selectedForNoteType = null;
    selectedIT2FU: any; // selected InitialTemplateId to FollowUp
    useDragonStyle: boolean = false;
    templateOptions: any =  [
        { value : "Provider Initial Evaluation Note"},
        { value : "Provider Consultation Note"},
        { value : "Provider Follow Up Note"},
        { value : "Procedure Note"},
        { value : "H&P Note"},
        { value : "Discharge Note"},
        { value : "Annual Note"},
        { value : "Follow Up Note"},
        { value : "Treatment Note"}
    ];
    updatedOptions: any;
    CPTDropdownSettings = {};
    selectedItems = [];
    dropdownList = [];
    cpts: any;
    cptsModel: any;
    companyForEditingTemplates: any;
    iscompanySameAndAdmin: boolean = false;
    company: any;
    constructor(
        private _templatesService: TemplatesService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _location: Location,
        private _toastr: ToastrService,
        private _encryptDecryptService: EncryptDecryptService,
        private _date_pipe: DatePipe,
        private _fileConversionService: FileConversionService,
        private _transcriptionCreateService: TranscriptionCreateService,
        private _dashboardService: DashboardService,
        private _socketService: SocketService,
        private _authService: AuthService,
        private titleService: TitleService,
        private dialog: MatDialog,
        private ckeditorService: CKEditorService,
        private ngZone: NgZone,
        private phrasesService: PhrasesService,
        private templateExplorerService: TemplateExplorerService,
        private commonService: CommonService,
        private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
        private censusListService: CensusListService,
        private _companyService: CompanyService
    ) {
        this.currentUser = this._authService.currentUser;

        this.cke4Config = this.ckeditorService.getDefaultConfig();
        const extraPlugins = this.cke4Config.extraPlugins.split(',');
        this.cke4Config.extraPlugins = [...extraPlugins, 'sourcearea', 'selectdropdown', 'phrasesautocomplete', 'icdautocomplete'].join(',');
    }

    async ngOnInit() {
        this.initCPTCodes();
        this.dropdownList = [];
        this.selectedItems = [];
        this.CPTDropdownSettings = {
            singleSelection: false,
            idField: '_id',
            textField: 'showText', 
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 5,
            allowSearchFilter: true,
            enableCheckAll: false,
        };
        this.phrasesService.getNoteBuilderDictionary( this.currentUser._id ).subscribe();
        this.initGlobalScope();

        if ( !this.currentUser ) {
            // if not login, stop and redirect to login #mohsin-dev
            this._router.navigate(['/login']);
            return;
        }

        this.templateIdFromUrl = this._route.snapshot.paramMap.get('id');
        if(this._route.snapshot.paramMap.get('company_id')) {
            this.companyForEditingTemplates = this._route.snapshot.paramMap.get('company_id');
            if((this.companyForEditingTemplates === this.currentUser.company_id) && (this.currentUser.admin_type == 'full_admin' || this.currentUser.admin_type == 'system_admin')) {
                this.iscompanySameAndAdmin = true;
                console.log('can User edit this template ? ',this.iscompanySameAndAdmin);
            }
        }

        const current_date = new Date();
        this.template = {
            title: '',
            template_text: '',
            creation_date: current_date,
            updation_date: current_date,
            updation_date_string: '',
            creation_date_string : '',
            facility_id: '',
            noteTitle_ids: '',
            owner_id: '',
            // owner_id: this.provider ? this.provider._id : this.currentUser._id,
            is_deleted: "false",
            note_builder_id: '',
        }
        this._route.paramMap.subscribe(async (params: ParamMap) => {
            this.company_id = params.get('companyId');
            // if (window.location.pathname.includes('drive')) {
            //     this.companySide = false;
            // } else {
            //     this.companySide = true;
            // }
            if (window.location.pathname.includes('company')&&!(window.location.pathname.includes('drive'))) {
                this.companySide = true;
            } else {
                this.companySide = false;
            }
            if (this.company_id) {
                this.template_type = 'system_defined'
                // console.log('company side',this.company_id);
                // console.log('template type',this.template_type);
                if (this._route.snapshot.paramMap.get('template_type')) {
                    this.template_type = 'user_defined';
                    // console.log("sdfjkl", this.template);
                }
            } else {
                this.template_type = 'user_defined'
                this.company_id = this.currentUser.company_id
                this.companySide = false;
            }
        })
        // console.log("company side", this._route.snapshot.params.companyId)
        await this.getCompany();
        let templateId
        if (!this.companySide) {
                        this.templateExplorerService.castProvider.subscribe(provider => {
                this.provider = provider;
            });

            this.template.owner_id = this.provider ? this.provider._id : this.currentUser._id;
            this.template.facility_ids = [ this._route.snapshot.paramMap.get( 'parent_folder_id' ) ];
            templateId = this._route.snapshot.paramMap.get('id');
        } else {
                        this.export = true;
            this.provider = this._route.snapshot.paramMap.get('provider')
            this.template.facility_ids = [this._route.snapshot.paramMap.get('facility')]
            templateId = this._route.snapshot.paramMap.get('id');
                        this.template.owner_id = this.provider && this.provider != "undefined" ? this.provider : this.currentUser._id;
        }
        
        this.loading = true;
        if ( templateId ) {
            const templateByIdResponse: any = await this._templatesService.getTemplateById(templateId).toPromise();
            if (templateByIdResponse.status === 200) {
                const template: Template = <Template>templateByIdResponse.data;
                template.template_text = <string>await this._encryptDecryptService.decrypt(template.template_text).toPromise();
                if(template.assoc_cpt_ids) {
                    for(let cpt of template.assoc_cpt_ids) {
                       cpt["showText"] = `${cpt.code} / ${cpt.description.length > 30? cpt.description.substring(0, 30)+'...' : cpt.description}`;
                    }   
                }
                this.template = template;
                localStorage.removeItem('template');
                this.updatedOptions = this.search(this.template.title, this.templateOptions);
                if (this.updatedOptions == undefined) {
                    this.templateOptions.unshift({'value':this.template.title});
                }
            }
        }
        const convertedHTML = localStorage.getItem('convertedHTML');
        if (convertedHTML) {
            this.template.template_text = convertedHTML;
            localStorage.removeItem('convertedHTML');
        }
        if (this.companySide && this.template_type === 'user_defined') {
            await this.initFacilities(this.provider);
        }
        else if(this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT){
            await this.initFacilities(this.provider._id);
        } else {
            await this.initFacilities();
        }
        await this.initTitles();
        this.loading = false;

        this.selectForNoteType();
        this.selectFacilitySource();

        this.filteredFacilities = this.facilityCtrl.valueChanges.pipe(
            startWith(''),
            map(( filterValue: string | null ) => this.filterFacility( filterValue ))
        );
    }

    async getCompany() {
        const filter = {
            _id: this.company_id
        };
        const project = {
            name: 1,
            portal_type: 1,
            company_type: 1
        };
        const { status, data }: any = await lastValueFrom(this._companyService.getCompany(filter, project));
        if (status == 200) {
            this.company = data;
        }
        else if (status === 500) {
            this._toastr.error(data.message, "ERROR");
        }
    }

    search(nameKey, myArray){
        for (var i=0; i < myArray.length; i++) {
            if (myArray[i].value === nameKey) {
                console.log('myArray[i]',myArray[i]);
                return myArray[i];
            }
        }
    }
    ngAfterViewInit() {
        this._templatesService.getNoteBuilderList().subscribe(response => {
            // @ts-ignore
            this.note_builder_list = response.data.array;
            
        });
    }
    initGlobalScope() {
        window['angularComponentReference'] = {
            component: this, zone: this.ngZone,
            componentDataMembers: {
                componentName: 'template-create',
                phrases: this.phrasesService.castPhrases,
                selectdropdown: {
                    disableOptionSelection: () => true
                }
            },

            componentFunctions: {
                getICDs: (data) => this.censusListService.getICDs(data),
                getSource: () => this.selectedFacilitySource,
                filterPhrasesBySource: this.phrasesService.filterPhrasesBySource
            }
        };
    }

    ngOnDestroy() {
        this.ckeditorService.destroyEditorInstance();
        delete window['angularComponentReference'];
    }

    handleFacilityInputFocus(event) {
        this.facilityCtrl.setValue(event.target.value);
    }

    addFacilityAsChip(event: MatChipInputEvent): void {
        const input = event.input;
        const value: any = event.value;

        // Add our payor
        this.facilities.push(value);
        this.template.facility_ids = this.facilities.map(f => f._id);

        // Reset the input value
        if (input) {
            input.value = '';
        }

        this.facilityCtrl.setValue(null);
    }

    removeFacilityAsChip(facility: any): void {
        const index = this.facilities.indexOf(facility);

        if (index >= 0) {
            this.facilities.splice(index, 1);
            this.template.facility_ids = this.facilities.map(f => f._id);
        }
    }

    selectedFacilityAsChip(event: MatAutocompleteSelectedEvent): void {
        let find_facility = this.facilities.find(f => event.option.value._id == f._id);
        if (!find_facility) {
            this.facilities.push(event.option.value);
            this.template.facility_ids = this.facilities.map(f => f._id);
        }
        else
            this._toastr.info("Facility already selected", 'Info');


        this.facilityInput.nativeElement.value = '';
        this.facilityCtrl.setValue(null);
    }

    private filterFacility( value: any ): any[] {
        let
            filterValue = null,
            providerId = null;

        if ( value ) {
            filterValue = typeof value === 'string' ? value.toLowerCase() : value;
        } else {
            providerId = this.provider?._id ?? this.currentUser._id;
        }

        return this.allFacilities
            .slice()
            .filter( facility => {
                if ( this.selectedFacilitySource === null ) {
                    return true;
                }

                switch ( facility.source ) {
                    case 'PointClickCare':
                        return this.selectedFacilitySource === 'pcc';
                    case 'MatrixCare':
                        return this.selectedFacilitySource === 'matrix';
                    default:
                        return this.selectedFacilitySource === 'dn';
                }
            })
            .filter( facility => {
                if ( filterValue === null ) {
                    return facility;
                    // return facility.provider_ids_ref.indexOf( providerId ) >= 0;
                }

                const facilityTitle = facility.title.toLowerCase();

                if ( typeof filterValue === 'string' ) {
                    return facilityTitle.includes( filterValue );
                }

                return facilityTitle.indexOf( filterValue ) === 0;
            });
    }

    async onNoteTypeChange() {
        if ( this.selectedForNoteType === 'FU' && !this.template._id ) {
            try {
                const response: any = await this._templatesService.getInitialTemplatesByFacilityIds( this.template.facility_ids ).toPromise();

                if ( response.status !== 200 ) {
                    throw new Error( 'Error Occurred' );
                }

                if ( response.data.length > 0 ) {
                    swal({
                        title: "Confirmation!",
                        text: "Would you like to use any previous initial template to follow up?",
                        buttons: [ 'No', 'YES' ]
                    })
                        .then( usePreviousInitial => {
                            if ( usePreviousInitial !== true ) {
                                return;
                            }

                            const buttons = response.data.reduce(( accumulator, current ) => {
                                return {
                                    ...accumulator,
                                    [ current._id ]: {
                                        text: current.title.toUpperCase(),
                                        value: current._id
                                    }
                                };
                            }, {});

                            buttons.cancel = {
                                text: 'Cancel',
                                value: null,
                                visible: true
                            };

                            swal( 'Choose one of them:', {
                                className: 'swal-choose-template-modal',
                                buttons
                            })
                                .then( result => {
                                    if ( !result ) {
                                        return;
                                    }

                                    const template2FollowUp = response.data.find( templateObj => templateObj._id === result );

                                    if ( template2FollowUp == null ) {
                                        return;
                                    }

                                    this.followUpTemplate( template2FollowUp );
                                });
                        });
                }
            } catch ( error ) {
                console.log( error );
            }
        }
    }

    private followUpTemplate( template2FollowUp ) {
        function upper( text ) {
            return text.toUpperCase();
        }

        const
            editorInstance = this.ckeditorService.getEditorInstance(),
            rawHeadings = template2FollowUp.template_text ?
                this.phrasesService.getAllHeadings( template2FollowUp.template_text ) :
                [],
            prevTemplateHeadings = rawHeadings
                .map( heading => {
                    let headingText = heading.trim();

                    if ( headingText === '' ) {
                        return null;
                    }

                    if ( headingText.endsWith( ':' ) === false ) {
                        headingText += ':';
                    }

                    return headingText;
                })
                .filter( headingText => ( headingText != null ));

        if ( editorInstance == null || prevTemplateHeadings.length === 0 ) {
            return;
        }

        const promise = new Promise( resolve => {
            editorInstance.setData( '', function() {
                this.updateElement();
                setTimeout(() => resolve( null ));
            });
        });

        promise.then(() => {
            const templateHeadings = this.phrasesService.getTemplateHeadings();

            prevTemplateHeadings
                .map( headingText => {
                    let headingTextToFind = headingText.endsWith( ':' ) ?
                        headingText.slice( 0, -1 ) :
                        headingText;

                    headingTextToFind = headingTextToFind.toUpperCase();

                    const found = templateHeadings.find( headingObj => {
                        const currHeading = headingObj.heading;
            
                        if ( typeof currHeading === 'string' && upper( currHeading ) === headingTextToFind ) {
                            return true;
                        }
            
                        if ( Array.isArray( currHeading ) && currHeading.map( upper ).includes( headingTextToFind )) {
                            return true;
                        }
            
                        return false;
                    });

                    const relevantPhrase = found?.phrases?.[0] ?? null;

                    return {
                        headingText,
                        relevantPhrase: relevantPhrase == null ? null : `${ this.phrasesService.marker }${ relevantPhrase }`
                    };
                })
                .forEach(({ headingText, relevantPhrase }, index ) => {
                    if ( index > 0 ) {
                        this.ckeditorService.moveNextForNewHeading();
                    }

                    let headingToInsert = `<strong>${ headingText }</strong> `;

                    if ( relevantPhrase != null ) {
                        headingToInsert += relevantPhrase;
                    }
    
                    editorInstance.insertHtml( headingToInsert );
                });
        });
    }

    onFacilitySourceChange() {
        this.facilities = [];
        this.template.facility_ids = this.facilities.map(f => f._id);
    }

    async saveTemplate() {
        const isEdit = this.template._id ? true : false;
        const editorInstance = this.ckeditorService.getEditorInstance();

        // It looks necessary because when any incorrect word (or sentence) has been corrected via spell checking, then it does not update ngModel.
        this.template.template_text = editorInstance.getData();

        if (this.template.title.length === 0) {
            this._toastr.error("Please enter template title and try again", 'Failed');
            return;
        }
        // if ( isEdit === false && this.selectedFacilitySource === null ) {
        //     this._toastr.error("Please select facility source and try again", 'Failed');
        //     return;
        // }
        if (this.template.facility_ids.length === 0 || this.template.facility_ids[0] == "" || this.template.facility_ids[0] == "undefined") {
            this._toastr.error("Please select facility and try again", 'Failed');
            return;
        }
        if (this.template.template_text.length === 0) {
            this._toastr.error("Please enter template text and try again", 'Failed');
            return;
        }

        // if ( isEdit === false ) {
        //     const
        //         headings = this.phrasesService.getAllHeadings( this.template.template_text ),
        //         erroredHeadings = headings
        //             .map( heading => this.phrasesService.isHeadingValid( heading ) ? null : heading )
        //             .filter( erroredHeading => erroredHeading !== null );

        //     if ( erroredHeadings.length > 0 ) {
        //         const anyHeadingWithoutColon = erroredHeadings.some( erroredHeading => ( erroredHeading.endsWith( ':' ) === false ));

        //         let template = `
        //             Following headings are invalid:<br /><br />
        //             <strong>${ erroredHeadings.join( '<br />' ) }</strong>
        //         `;

        //         if ( anyHeadingWithoutColon === true ) {
        //             template += `
        //                 <br /><br />
        //                 <small><em>Also make sure all headings should end with colon (:)</em></small>
        //             `;
        //         }

        //         swal({
        //             content: {
        //                 element: 'div',
        //                 attributes: {
        //                     style: 'text-align: left;',
        //                     innerHTML: `${ template }`
        //                 }
        //             }
        //         });

        //         return;
        //     }
        // }

        // if (
        //     isEdit === false &&
        //     this.phrasesService.doesEditorContainLastSectionSeparator( this.template.template_text ) === false
        // ) {
        //     this._toastr.error( 'Please enter "---" at the end of the note above the signature line and try again!', 'Failed' );
        //     return;
        // }

        // if (
        //     isEdit === false &&
        //     [ 'dn', 'matrix' ].includes( this.selectedFacilitySource ) === true &&
        //     this.phrasesService.doesEditorContainSignaturePhrase( this.template.template_text ) === false
        // ) {
        //     this._toastr.error( 'Please enter `.signature@dn` at the end and try again!', 'Failed' );
        //     return;
        // }

        this.loading = true;
        this.template.template_text = this.template.template_text.replace(/<\/strong>:/g, ':</strong>')
        const encrypted_template_text = <string>await this._encryptDecryptService.encrypt(this.template.template_text).toPromise();
        const for_note_type = this.selectedForNoteType;
        const creation_date = this._date_pipe.transform(this.template.creation_date, global.date_time_format);
        const updation_date = this._date_pipe.transform(new Date(), global.date_time_format);
        const creation_date_string : string = this.commonService.convertDateToStringforMoment(moment());
        const updation_date_string : string = this.commonService.convertDateToStringforMoment(moment());

        if ( isEdit === true ) {

            // update

            // ACTIVITY LOG
            this._socketService.addActivityLog({
                id: this.currentUser._id,
                screen: 'Create Template',
                operation: 'Update Template API Call',
                datetime: this._date_pipe.transform(new Date(), global.date_time_format)
            })

            this.template.title = this.template.title.toUpperCase();
            this.template.updation_date = updation_date;
            this.template.updation_date_string = updation_date_string;

            if ( this.companySide && this.template_type === 'user_defined' ) {
                const dialogRef = this.dialog.open(GeneralConfirmationDialogComponent, {
                    width: '500px',
                    data: { title: "Edit Confirmation", message: "Your are trying to update someone'e else template. Are you sure ! you want to save it ?" }
                });
                dialogRef.afterClosed().subscribe(async (result) => {
                    if (!result) {
                        return;
                    }
                    const response: any = await this._templatesService.updateTemplate({
                        ...this.template,
                        template_text: encrypted_template_text,
                        for_note_type,
                        creation_date,
                        creation_date_string,
                        updation_date,
                        updation_date_string,
                    }).toPromise();
                    if (response.status === 200) {
                        this.goBack();
                    } else {
                        this._toastr.error(response.message, 'Failed');
                    }
                });
            }
            else {
                const response: any = await this._templatesService.updateTemplate({
                    ...this.template,
                    template_text: encrypted_template_text,
                    for_note_type,
                    creation_date,
                    creation_date_string,
                    updation_date,
                    updation_date_string
                }).toPromise();
                if (response.status === 200) {
                    this.goBack();
                } else {
                    this._toastr.error(response.message, 'Failed');
                }
            }
        } else {
            // create new


            // ACTIVITY LOG
            this._socketService.addActivityLog({
                id: this.currentUser._id,
                screen: 'Create Template',
                operation: 'Create Template API Call',
                datetime: this._date_pipe.transform(new Date(), global.date_time_format)
            })

            const response: any = await this._templatesService.addNewTemplate({
                ...this.template,
                template_text: encrypted_template_text,
                for_note_type,
                creation_date,
                updation_date,
                creation_date_string,
                updation_date_string,
            }, this.company_id, this.template_type).toPromise();
            if (response.status === 200) {
                this.goBack();
            } else {
                this._toastr.error(response.message, 'Failed');
            }
        }
        this.loading = false;
    }

    uploadFileAndCreateTranscription(event) {
        const userInfo = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            userType: this.currentUser.user_type
        }
        const [file] = <Array<File>>event.target.files;
        if (!file) return;
        var filename = file.name;
        this.template.title = filename.substr(0, filename.lastIndexOf('.'));


        const infoToast = this._toastr.info('Importing template...', "Please wait", { disableTimeOut: true });
        this._fileConversionService
            .makeFileRequest(userInfo, global.url + API_URL.ROUTES.uploadFile, file, filename)
            .then((result: any) => {
                if (result.status == 200) {
                    this.template.template_text = result.data;
                } else {
                    console.error(result.message);
                    this._toastr.error(result.message, 'Failed');
                }
                this._toastr.clear(infoToast.toastId);
            }, console.error);
    }

    async initFacilities(provider_id?) {
        let doctor_id;
        if ( provider_id ) {
            doctor_id = provider_id;
        } else {
            doctor_id = this.currentUser._id;
        }
        let response: any = {};

        if(this.companySide){
            response = await this._transcriptionCreateService.getFacilitiesByCompanyID(this.company_id).toPromise();
        }
        else if ( this.currentUser.user_type == global.USER_TYPE.DOCTOR || this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT ) {
            response = await this._transcriptionCreateService.getFacilities(doctor_id).toPromise();
        }
        else if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
            const filter = {
                associated_snf_wc_nurse_ids: doctor_id,
            };
            const nurseProjection = {
                first_name: 1,
                last_name: 1,
                title: 1,
            };
            const facilityProjection = {
                title: 1,
                pcc_facId: 1,
                source: 1,
                pcc_2_legged_authentication: 1
            };
            const facilitiesResponse: any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
            if (facilitiesResponse.status === 200) {
                response = facilitiesResponse;
                response.data.array = facilitiesResponse.data;
            }
        }
        else {
            response = await this._dashboardService.getAssociatedFacilityOfTranscriber().toPromise();
        }

        if ( response.status === 200 ) {
            if(this.companySide){
                this.allFacilities = response?.facilities;
            }else
            {
                this.allFacilities = response?.data?.array;
            }
            this.facilities = this.allFacilities.filter(f => this.template.facility_ids.indexOf(f._id) >= 0);
        }
    }

    async initTitles() {
        let respons: any = {};
        respons = await this.titleService.getMyTitles().subscribe((response: any) => {
            if (response.status == 200) {
                this.noteTitles = response.data.array;
                this.allNoteTitles = response.data.array.map(a => (a.title));
            }
        });
    }

    private selectForNoteType() {
        if ( this.template._id ) {
            this.selectedForNoteType = this.template.for_note_type || null;
        } else {
            this.selectedForNoteType = 'IE';
        }
    }

    private selectFacilitySource() {
        if ( this.allFacilities.length === 0 ) {
            return;
        }

        const parentFolderId = this._route.snapshot.paramMap.get( 'parent_folder_id' );

        let editTemplateFacilityIds = [];

        if (
            this.template._id &&
            this.template.facility_ids != null &&
            Array.isArray( this.template.facility_ids ) &&
            this.template.facility_ids.length > 0
        ) {
            editTemplateFacilityIds = this.template.facility_ids;
        }

        const foundFacility = this.allFacilities.find( facility => {
            if ( editTemplateFacilityIds.length > 0 ) {
                return editTemplateFacilityIds.some( id => id === facility._id );
            }

            if ( parentFolderId ) {
                return facility._id === parentFolderId;
            }

            return false;
        });

        if ( foundFacility == null ) {
            return;
        }

        switch ( foundFacility.source ) {
            case 'PointClickCare':
                this.selectedFacilitySource = 'pcc';
                break;
            case 'MatrixCare':
                this.selectedFacilitySource = 'matrix';
                break;
            default:
                this.selectedFacilitySource = 'dn';
        }
    }

    goBack() {

        // console.log("ran",
        // window.history.go(-1)
        // );
        // history.back();
        // console.log(window.location);

        this._location.back();
    }

    handleNoteTitleChange( noteTitle ) {
        let titl = this.noteTitles.find((n, i) => n.title.toUpperCase().trim() == noteTitle.toUpperCase().trim());
        this.template.noteTitle_ids = titl._id;
    }

    handleNoteBuilderChange( builder ) {
        this.template.note_builder_id = builder._id;
    }

    showPreviewTemplateDialog() {
        const dialogRef = this.dialog.open(PreviewTemplateDialog, {
            width: '80%',
            data: {
                title: this.template.title,
                rawText: this.template.template_text   //+`<br><p>___</p>`
            }
        });
        // dialogRef.beforeClosed().subscribe(async (confirm: any) => {

        // });
    }

    onReadyCkeditor( e ) {
        const { editor } = e;

        this.ckeditorService.setEditorInstance( editor );
        this.ckeditorService.ckeditorRemoveTitle( editor );
        if(this.company.portal_type === global.PORTAL_TYPE.NON_WC){
            this.initTemplateText();
        }
    }

    async exportTemplate() {
        let title = this.template.title;
        let templateText = this.template.template_text;

        if (this.currentUser.user_type == global.USER_TYPE.DOCTOR || this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE) {
            let facilityId;

            this.commonService.castFacility.subscribe(facility => {
                facilityId = facility;
                console.log('facility id', facility);
            });

            const response: any = await this._templatesService.addNewTemplate({
                ...this.template,
                template_text: templateText,
                owner_id: this.currentUser._id,
                facility_ids: facilityId
            }, this.company_id, this.template_type = 'user_defined').toPromise();
            if (response.status === 200) {
                console.log(response);
                this.goBack();
            } else {
                this._toastr.error(response.message, 'Failed');
            }

        }
    }

    initCPTCodes() {
        this.templateExplorerService.getAllCPTs().subscribe((response:any)=>{
            if(response.status == 200){
                this.cpts = response.data;
                for(let cpt of this.cpts) {
                    cpt["showText"] = `${cpt.code} / ${cpt.description.length > 30? cpt.description.substring(0, 30)+'...' : cpt.description}`;
                }
                console.log('Response CPTS in templates',this.cpts);
            }
        })
        
    }

    onCPTSelect($event) {
        if(this.template._id){
            this.createCptTemplateRelation($event);
        }
    }

    createCptTemplateRelation($event){
        this.templateExplorerService.createCptTemplateRelation(this.template._id,$event._id).subscribe((res:any)=>{
            if(res.status == 200){
                console.log('relation Updated');
            }
        });
    }

    onCPTDeSelect($event) {  
        console.log('$event',$event);
        if(this.template._id){
            this.deleteCptTemplateRelation($event);
        }
    }

    deleteCptTemplateRelation($event){
        this.templateExplorerService.deleteCptTemplateRelation(this.template._id,$event._id).subscribe((res:any)=>{
            if(res.status == 200){
                console.log('relation Updated');
            }
        });
    }

    applyDragonSymbol() {
        const editorInstance = this.ckeditorService.getEditorInstance();

        if ( editorInstance == null ) {
            return;
        }

        const data = editorInstance.getData();

        let modifiedData = data;

        modifiedData = modifiedData.replace( /<span class="asteriskjump">\*{3}<\/span>/ig, this.phrasesService.dragonSymbol );
        modifiedData = modifiedData.replace( /\*{3}/g, this.phrasesService.dragonSymbol );

        // if data has not been modified, then do nothing...
        if ( modifiedData === data ) {
            return;
        }

        editorInstance.setData( modifiedData );
        setTimeout(() => {
            this.template.template_text = modifiedData;
        }, 10 );
    }

    onDragonStyleChange( event ) {
        this.useDragonStyle = event.checked;

        if ( this.useDragonStyle === true ) {
            this.applyDragonSymbol();
        }
    }
    initTemplateText() {
        // in case of edit, do nothing...
        if ( this.template._id ) {
            return;
        }

        const editorInstance = this.ckeditorService.getEditorInstance();

        if ( editorInstance == null ) {
            return;
        }

        if ( this.template?.template_text?.trim() === '' ) {
            editorInstance.setData(`
                <p><br></p>
                <p><br></p>
                <p>${ this.phrasesService.lastSectionSeparator }</p>
                <p>${ this.phrasesService.marker }${ this.phrasesService.preferredEndingPhrase }</p>
            `
            );
        }
    }
}
