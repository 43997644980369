import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment'
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { AdminDictationsService } from '../../admin-dictations/admin-dictations.service';
import { TranscriptionCreateService } from '../../transcription-create/transcription-create.service';
import { BillerDashboardService } from '../biller-dashboard.service';
import { ChargesPopupComponent } from '../charges-popUp/charges-popup/charges-popup.component';
import * as global from '../../global';
import { CommonService } from 'src/app/services/common.service';
import { I } from '@angular/cdk/keycodes';
import { HttpResponse } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DashboardService } from '../../dashboard/dashboard.service';
import { API_URL } from '../../../../environments/api-url'
import { PdfViewerPopComponent } from '../../patient-documents-list/pdf-viewer-pop/pdf-viewer-pop.component';
import { FaceSheetService } from '../../patient-detail/facesheet/face-sheet.service';
import { PatientDocumentsUploadDialog } from '../../patient-documents-list/patient-documents-upload-dialog/patient-documents-upload-dialog.component';
import { DialogConfirmationComponent } from '../../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { FileExplorerService } from '../../drive/file-explorer/file-explorer.service';
import swal from 'sweetalert';
import { TemplateExplorerService } from '../../templates/template-explorer/template-explorer.service';
import { PCCService } from 'src/app/services/pcc.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { AuditPopupComponent } from '../audit-popup/audit-popup/audit-popup.component';
import { ChargeCommentDialogComponent } from '../charge-comment-dialog/charge-comment-dialog.component';
import { FacilityService } from 'src/app/services/facility.service';
import { PatientDocumentService } from '../../patient-documents-list/patient-document-list.service';
import { ViewNoteDialog } from '../../transcription-create/view-note-dialog/view-note-dialog.component';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';
import { NotificationsService } from '../../notifications/notifications.service';
import { UserService } from 'src/app/services/Modules/user.service';
import { WoundCareWidgetService } from '../../note-sidebar/wound-care-widget/wound-care-widget.service';
import usa_states from '../../../shared/popup-dialogs/facility-add-dialog/usa_states';
import ExcelJS from 'exceljs';
import { flatten } from 'flat';

interface IFilter {
  active: boolean,
  creation_date: {
    startDate: any | Date | moment.Moment,
    endDate: any | Date | moment.Moment
  },
  date_of_service: {
    startDate: any | Date | moment.Moment,
    endDate: any | Date | moment.Moment
  },
  user: string,
  facility: string,
  patient: string,
  progress_status: string
}
interface BReport {
  dos: string,
  provider: any[]
}
@Component({
  selector: 'app-admin-charge-capture',
  templateUrl: './admin-charge-capture.component.html',
  styleUrls: ['./admin-charge-capture.component.css'],
  animations: [
    trigger('accordionTrigger', [
      state('collapsed', style({ height: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AdminChargeCaptureComponent implements OnInit {
  filteredFacilities: Observable<string[]>;
  facilityControl = new FormControl();
  filteredProvider: Observable<string[]>;
  providerControl = new FormControl();
  global = global;
  usa_states = usa_states;

  public isVisibleTable: boolean = true;
  isVisibleDetailTable: boolean = false;
  public sortedData: Array<any>;
  public notes: Array<any>;
  public group_notes: Array<any>;
  public notesGroup;
  public sortNotesGroup;
  public c_user;
  chargesPopup: any;
  public c_date = moment(new Date());
  public creationDateFilter: Boolean = true;
  public serviceDateFilter: Boolean = true;
  displayedColumns: string[] = ['date_of_creation', 'Expansion Icon', 'patient_name', 'facility_title', 'facility_state', 'dos', 'dob', 'updatedAt', 'provider_name', 'census_name', 'progress_status', 'face_sheet', 'audit','eas', 'biller_note', 'operation'];
  chargeStatus: string = 'default';
  @ViewChild(MatSort) sort: MatSort;
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  provideModel: any = '';
  facilityMOdel: any = "";
  company_id: any;
  // filter: any = {
  //   creation_date: {
  //     startDate: moment().subtract(7, 'days').toDate(),
  //     endDate: moment().toDate()
  //   },
  //   date_of_service: null

  // };
  filter: any = {
    creation_date: null,
    date_of_service: {
      startDate: moment().subtract(2, 'days'),
      endDate: moment()
    },
    dateFilter: 'dos',
    cpt_id: null,
    billStatus: "all"
  };
  dateFilter: String = "dos";
  appliedFilter: IFilter;
  users: Array<any> = [];
  providers: Array<any> = [];
  note_types: Array<any> = [];
  facilities: Array<any> = [];
  patients: Array<any> = [];
  uniqueProviders: any = [];
  chargesArray: any = [];
  showPatientsSearch: boolean = false;
  searchText: string = "";
  status = [
    { title: 'Unsigned' },
    { title: 'Signed' },
    { title: 'Uploaded' }
  ];
  filteredProviders: Observable<string[]>;


  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };

  currentDate = new Date();
  notesToShow = null;
  currentRouteUrl: any;
  charges: any = [];
  provider: any;
  facility: any;
  groupedDates: string[];
  groupedData: any = [];
  groupedCharges: any = [];
  dataSource: any = new MatTableDataSource([])
  totalRecords: number = 0;
  fitlerButtonClicked: boolean = false;
  currentUser: any;
  facilityModel: any = '';
  showPccLogo: boolean = false;
  showMatrixLogo: boolean = false;
  showDnLogo: boolean = false;
  selectedCharges: any = [];
  cpts: any = [];
  cptCodesDropdownSettings: any = {};
  cptFilter: any;
  tabIndex: any = 0;
  p = 'patient';
  f = 'facesheet';
  companyName: any;
  loading: boolean;
  auditPopup: any;
  Separatecharges: any;
  company: any;
  allComFacilities: any;
  chargeView: any;
  flatcharges: any[];
  flatLoader: boolean=false;
  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    PAGE_LIMIT: 20,
    skip: 0,
    shouldShowCount: false
  };
  selectedCptCodes: any[] = [];
  timeoutId: NodeJS.Timeout;
  notification_response: any;
  isAdminSide: boolean = false;
  expandedRows: any = [];
  expandedElementId: null;
  accordianTabIndex: number = 0;

  constructor(
    private _dictationService: AdminDictationsService,
    private _transcriptionCreateService: TranscriptionCreateService,
    public _authService: AuthService,
    private toastr: ToastrService,
    private _router: Router,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private templateExplorerService: TemplateExplorerService,
    private billerDashboardService: BillerDashboardService,
    private dialog: MatDialog,
    private companyService: CompanyService,
    public userTypesRelationshipService: UserTypesRelationshipService,
    private dashboardService: DashboardService,
    private _faceSheetService: FaceSheetService,
    private fileExplorerService: FileExplorerService,
    private pccService: PCCService,
    private facilityService: FacilityService,
    private patientDocumentService: PatientDocumentService,
    private notificationsService: NotificationsService,
    private userService: UserService,
    private _woundCareWidgetService: WoundCareWidgetService,


  ) {
    this.chargeView='group';
    this.currentUser = this._authService.currentUser;
    if (this.currentUser.user_type != global.USER_TYPE.BILLER) {
      this.displayedColumns.splice(0, 1);
    }
    this.cptCodesDropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'code',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      enableCheckAll: false
    };
  }

  ngOnInit(): void {
    this._woundCareWidgetService.setIsTeleHealthReport(true);
    if (window.location.pathname.includes('company')) {
      console.log('this.route.parent.parent.snapshot.params.id', this.route.parent.parent.snapshot.params.id);
      this.isAdminSide = true;
      this.company_id = this.route.parent.parent.snapshot.params.id;
    }
    else if (window.location.pathname.includes('manage-companies')) {
      this.company_id = this.route.parent.parent.snapshot.params.id;
    }
    else if (this.currentUser.user_type === global.USER_TYPE.BILLER) {
      this.company_id = this.currentUser.company_id;
      this.chargeStatus = 'submit';
      this.filter.status = 'submit';
      this.displayedColumns = ['date_of_creation', 'Expansion Icon', 'patient_name', 'facility_title', 'facility_state', 'dos', 'dob', 'updatedAt', 'provider_name', 'census_name', 'progress_status', 'face_sheet' ,'audit','eas', 'biller_note', 'operation'];
    }
    this.getProviderList();
    this.initCpts();
    this.initCompanyFacilities();
    this.initFacilities();
    if (this.commonService.getLocalStorageItem("admin-charge-capture")) {
      const { dateRange } = this.commonService.getLocalStorageItem("admin-charge-capture");
      this.filter.date_of_service = {
        startDate: moment(dateRange.startDate),
        endDate: moment(dateRange.endDate)
      };
    }
    this.initCharges();
    this.filteredProviders = this.providerControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => this.filterProvider(value)));
    this.filteredFacilities = this.facilityControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => this.filterFacilities(value)));
    if (this.currentUser.user_type != 1 || this.currentUser.user_type != global.USER_TYPE.DOCTOR || this.currentUser.user_type != global.USER_TYPE.SNF_WC_NURSE) {
      this.displayedColumns = this.displayedColumns.filter(column => column != 'biller_note');
    }
  }
  clearProviderFilter() {
    this.providerControl.setValue('');
    this.provider = null;
    this.filter.provider_id = null;
  }
  clearFacilityFilter() {
    this.facilityControl.setValue('');
    this.facilityModel = '';
    this.filter.facility_id = null;
    this.showPccLogo = false;
    this.showMatrixLogo = false;
    this.showDnLogo = false;
  }
  async initCompanyFacilities() {
    const filter = {assoc_company_ids: this.company_id, is_active: true };
    const projection = {
      'title': 1,
      'is_active': 1,
      'source': 1,
      'assoc_company_ids': 1
    }

    let companyAssocPopulation: facilityPopulationInterface = {
      collection: 'companies',
      field: ["assoc_company_ids"],
      filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: [true, { $in: ['$_id', '$$assoc_company_ids'] }] }] } },
      project: { name: 1 }
    };
    
    const response: any = await this.facilityService.getFacilities(filter, projection, [companyAssocPopulation]).toPromise();
    if (response.status === 200) {
      this.allComFacilities = response.data.array;
    }
  }
  getProvControlStatus() {
    if (this.providerControl?.value && (this.providerControl?.value != '')) return true; else return false;
  }
  getFacControlStatus() {
    if (this.facilityControl?.value && (this.facilityControl?.value != '')) return true; else return false;
  }
  private filterFacilities(value: string): string[] {
    if (this.filter?.provider_id) {
      if (value) {
        if (value.length == 1) {
          this.showPccLogo = false;
        }
        const filterValue = value.toLowerCase();
        return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) > -1);
      }
      else {
        return this.facilities;
      }
    } else {
      if (value) {
        if (value.length == 1) {
          this.showPccLogo = false;
        }
        const filterValue = value.toLowerCase();
        return this.allComFacilities.filter(option => option.title.toLowerCase().indexOf(filterValue) > -1);
      }
      else {
        return this.allComFacilities;
      }
    }
  }

  private filterProvider(value: string): string[] {
    if (value && this.providers.length > 0) {
      const filterValue = value.toLowerCase();
      let users = this.providers.filter(option => option.full_name.toLowerCase().includes(filterValue));
      return this.sortFirstNameVise(users);
    }
    else {
      return this.sortFirstNameVise(this.providers);
    }
  }

  onTabClick(event) {
    this.tabIndex = event.index;
    if (this.currentUser.product_owner) {
      this.tabIndex--;
    }
    return;
  }

  onSelectBillStatus(billStatus) {
    if (this.filter.status === "submit") {
      this.filter.billStatus = billStatus;
    }
    else {
      delete this.filter.billStatus;
    }
  }

  initFlatCharges() {
    this.filter.pageNo = 1;
    this.filter.company_id = this.company_id;
    this.filter.dateFilter = this.dateFilter;
    this.filter.cpt_id = this.cptFilter;
    if (this.chargeStatus == 'default') {
      delete this.filter?.status;
    } else {
      this.filter.status = this.chargeStatus
    }

    if (!this.filter.date_of_service) return;
    this.flatcharges=[];
    this.getCompanyName();
    this.flatLoader=true;
    this.billerDashboardService.getFlatChargesByFilters(this.filter).subscribe(async (response: any) => {
      this.loading = false;
      if (response.status === 200) {
        this.flatLoader=false;

        if(response.data && response.data.charges){
          this.pagination.totalRecords = response.data.totalRecords;
          let chargesResp=[];
            for (const item of response.data.charges) {

              const provider = item?.user;
              const facility = item?.facility;
              const census = item?.census;
              const idDetails = item?._id;

              if (Array.isArray(item.chargeList)) {
                for await (let singleCharge of item.chargeList) {
                  if (singleCharge?.facility_id_ref) {
                    singleCharge.facility_id_ref = facility;
                  }
                  if (singleCharge?.owner_id) {
                    singleCharge.owner_id = provider;
                  }
                  if (singleCharge?.rounding_sheet_id) {
                    singleCharge.rounding_sheet_id = census;
                  }
                  chargesResp.push(singleCharge);
                }
              }

            }
            console.log("this.flatcharges chargesResp", chargesResp);
          this.flatcharges = this.sortFlatChargesByCreationDate(chargesResp);
          this.handlePagination();
        }
      }
    });
  }

  onSelectView(view) {
    this.chargeView = view;
    this.charges=[];
    this.dataSource.data=[];
    this.chargesArray=[];
    this.Separatecharges=[];
    this.flatcharges=[];
    
    if(view === 'group') {
      this.isVisibleDetailTable=false; 
      this.isVisibleTable=true;
      this.showPatientsSearch=false;

      this.initCharges();
    }else if(view === 'flat'){
      this.initFlatCharges();
    }
  }


  async initSoloGroupCharge(group) {
    let charge_id = group?.chargeList?.chargeList || [];

    const provider = group?.chargeList?.user;
    const facility = group?.chargeList?.facility;
    const census = group?.chargeList?.census;

    this.billerDashboardService.getChargeGroupSeparate(charge_id, this.company_id).subscribe(async (response: any) => {
      if (response.status === 200) {
        this.Separatecharges = response.data;
        console.log("Separatecharges : ", this.Separatecharges);

        for await (const ele of this.Separatecharges) {
          if (ele?.facility_id_ref) {
            ele.facility_id_ref = facility;
          }
          if (ele?.owner_id) {
            ele.owner_id = provider;
          }
          if (ele?.rounding_sheet_id) {
            ele.rounding_sheet_id = census;
          }
        }
        let checkStatus = this.Separatecharges[0].status;
        if (checkStatus == 'submit') {
          let findIndex = this.displayedColumns.findIndex((c) => c === 'checkbox')
          if (!(findIndex > -1)) {
            this.displayedColumns.splice(0, 0, 'checkbox');
          }
          let findIndex2 = this.displayedColumns.findIndex((c) => c === 'bill_status')
          if (!(findIndex2 > -1)) {
            this.displayedColumns.splice(10, 0, 'bill_status');
          }
        } else {
          let findIndex = this.displayedColumns.findIndex((c) => c === 'checkbox')
          if (findIndex > -1) {
            this.displayedColumns.splice(findIndex, 1);
          }
          let findIndex2 = this.displayedColumns.findIndex((c) => c === 'bill_status')
          if (findIndex2 > -1) {
            this.displayedColumns.splice(findIndex2, 1);
          }
        }
        this.showPatientsSearch = true;
        this.isVisibleDetailTable = true;
        this.isVisibleTable = false;
        this.dataSource.data = this.Separatecharges;
        this.chargesArray = this.Separatecharges;

      }
    });

  }

  async initCharges() {
    this.filter.company_id = this.company_id;
    this.filter.dateFilter = this.dateFilter;
    this.filter.cpt_id = this.cptFilter;
    if (this.chargeStatus == 'default') {
      delete this.filter?.status;
    } else {
      this.filter.status = this.chargeStatus
    }
    if (this.filter.status === "submit" && !("billStatus" in this.filter)) {
      this.filter.billStatus = "all";
    }

    if (!this.filter.date_of_service) return;
    this.getCompanyName();
    this.loading = true;
    this.billerDashboardService.getChargesByFilters(this.filter).subscribe(async (response: any) => {
      this.loading = false;
      if (response.status === 200) {
        this.totalRecords = 0;
        this.charges = response.data;
        // console.log("this.charges", this.charges);

        for (const item of this.charges) {
          if (Array.isArray(item.groupedData)) {

            for (const key in item) {
              for (const value of item[key]) {
                if (typeof value === "object" && value !== null) {
                  this.totalRecords += value?.chargeList?.count;
                }
              }
            }
          }
        }

        //this.charges = await this.populateFeildsinCharges(this.charges);
        this.charges = this.sortChargesByCreationDate(this.charges);
      }
    });
  }
  auditClicked(element) {
    console.log("Audit clicked: ", element)
    let charge_id = element._id;
    this.auditPopup = this.dialog.open(AuditPopupComponent, { data: charge_id, width: '40%', maxHeight: "40%" })
    this.auditPopup.afterClosed().subscribe(async result => {

    });
  }

  operationInChildComponent(event?) {
     // this.initCharges();
  }

  async populateFeildsinCharges(charges) {
    console.log("populate Feilds in Charges");
    this.totalRecords = 0;

    for await (const charge of charges) {
      if (charge.groupedData?.length > 0 && Array.isArray(charge.groupedData)) {
        for await (const group of charge.groupedData) {
          if (group?.chargeList.chargeList?.length > 0) {
            const provider = group?.chargeList?.user;
            const facility = group?.chargeList?.facility;
            const census = group?.chargeList?.census;
            this.company = group?.chargeList?.company_id;

            this.totalRecords += group?.chargeList?.count;

            for await (const ele of group.chargeList.chargeList) {
              if (ele?.facility_id_ref) {
                ele.facility_id_ref = facility;
              }
              if (ele?.owner_id) {
                ele.owner_id = provider;
              }
              if (ele?.rounding_sheet_id) {
                ele.rounding_sheet_id = census;
              }
              if (ele?.company_id) {
                ele.company_id = this.company;
              }
            }
          }
        }
      }
    }
    return charges;
  }

  sortChargesByCreationDate(charges) {
    console.log("sort Charges By CreationDate");
    var byDate = charges.slice(0);
    byDate.sort(function (a, b) {
      return Date.parse(a._id) < Date.parse(b._id) ? 1 : Date.parse(a._id) > Date.parse(b._id) ? -1 : 0;
    });
    return byDate;
  }

  sortFlatChargesByCreationDate(charges) {
    console.log("sort Charges By CreationDate");
    var byDate = charges.slice(0);
    byDate.sort(function (a, b) {
      return Date.parse(a.createdAt) < Date.parse(b.createdAt) ? 1 : Date.parse(a.createdAt) > Date.parse(b.createdAt) ? -1 : 0;
    });
    return byDate;
  }

  checkProvider() {
    if (this.providerControl.value == '') {
      delete this.filter?.provider_id;
    }
  }
  async getProviderList() {
    const filter = {
      is_activated: 'true',
      company_id: this.company_id,
      user_type: global.USER_TYPE.DOCTOR,

    }
    const projection = {
      'first_name': 1,
      'last_name': 1,
      'title': 1,
      'full_name': 1
    }
    this.userService.getUsers(filter, projection).subscribe((response: any) => {
      if (response.status === 200) {
        this.providers = response.data;
        if (this.providers.length > 0) {
          this.providers = this.sortFirstNameVise(this.providers);
          this.filteredProviders = this.providerControl.valueChanges.pipe(
            startWith(''),
            map((value: any) => this.filterProvider(value))
          );
        }
      }
    });

  }

  initCpts() {
    this.templateExplorerService.getAllCPTs().subscribe((response: any) => {
      if (response.status === 200) {
        if (response.data?.length > 0) {

          this.cpts = response.data
          // console.log("this.cpts in charges admin", this.cpts);
        }
      }
    });
  }

  async onCodeSingleSelect($event) {
    this.cptFilter = $event._id;
    console.log("this.cptFilter Select single : ", this.cptFilter);

  }

  async onCodeSingleDeSelect($event) {
    this.cptFilter = null;

    console.log("this.cptFilter deSelect single : ", this.cptFilter);
  }

  async onCodeSelect($event) {
    if (!this.cptFilter || this.cptFilter == undefined || this.cptFilter == null || this.cptFilter.length < 1) this.cptFilter = [];
    this.cptFilter.push($event._id);
    console.log("this.cptFilter Select : ", this.cptFilter);

  }

  async onCodeDeSelect($event) {
    let index = this.cptFilter.indexOf($event._id);

    if (index >= 0) {
      this.cptFilter.splice(index, 1);
    }
    console.log("this.cptFilter deSelect : ", index, this.cptFilter);
  }




  sortProviders(a, b) {
    if (a.first_name < b.last_name) {
      return -1;
    }
    if (a.first_name > b.last_name) {
      return 1;
    }
    return 0;
  }
  async initFacilities(provider = null) {
    let response: any = {};
    if (provider) {
      response = await this._transcriptionCreateService.getFacilities(provider._id).toPromise();
    }
    if (response.status === 200) {
      this.facilities = response.data.array;
      this.filteredFacilities = this.facilityControl.valueChanges.pipe(
        startWith(''),
        map((value: any) => this.filterFacilities(value))
      );
    }
  }
  async reSelectFacility() {
    if (this.filter.provider_id) {
      let response: any = {};
      response = await this._transcriptionCreateService.getFacilities(this.filter.provider_id).toPromise();
      if (response.status === 200) {
        this.facilities = response.data.array;
        this.filteredFacilities = this.facilityControl.valueChanges.pipe(
          startWith(''),
          map((value: any) => this.filterFacilities(value))
        );
      }
    } else {
      this.facilities = this.allComFacilities;
      this.filteredFacilities = this.facilityControl.valueChanges.pipe(
        startWith(''),
        map((value: any) => this.filterFacilities(value))
      );
    }
  }
  sortFirstNameVise(arrayTosort) {
    let users = arrayTosort;
    let result = users.sort(function (a, b) {
      if (a.first_name?.toLowerCase() < b.first_name?.toLowerCase()) { return -1; }
      if (a.first_name?.toLowerCase() > b.first_name?.toLowerCase()) { return 1; }
      return 0;
    })
    return result;
  }
  changeServiceDate(event) {
    // if (event.startDate && event.endDate) {
    //   this.filter.date_of_service = {
    //     startDate: null,
    //     endDate: null
    //   }
    //   this.filter.date_of_service.startDate = event.startDate;
    //   this.filter.date_of_service.endDate = event.endDate;
    //   // this.filter.date_of_service.startDate = event.startDate.toDate();
    //   // this.filter.date_of_service.endDate = event.endDate.toDate();
    //   let startDate = moment(event.startDate);
    //   let endDate = moment(event.endDate);
    //   let noOfDays = endDate.diff(startDate, 'days');
    //   console.log("biller check", this.filter.provider_id)
    //   if (this.filter.provider_id && (this._authService.currentUser.user_type == 6 || this._authService.currentUser.user_type == global.USER_TYPE.BILLER)) {
    //     console.log("biller have selected provider");

    //   } else {

    //     if (noOfDays > 30) {
    //       this.serviceDateFilter = false;
    //       this.filter.date_of_service.endDate = moment(this.filter.date_of_service?.startDate).add(29, "days").startOf('day');
    //       endDate = this.filter.date_of_service.endDate;
    //       this.toastr.error('Can not select more than 30 days', 'ERROR');

    //       setTimeout(() => {
    //         this.serviceDateFilter = true;
    //       }, 300)
    //     }

    //   }
    //   const dateRange = {
    //     startDate: this.commonService.convertDateToStringforMoment(this.filter.date_of_service.startDate),
    //     endDate: this.commonService.convertDateToStringforMoment(this.filter.date_of_service.endDate)
    //   };
    //   this.commonService.setLocalStorageItem("admin-charge-capture", { dateRange : dateRange });
    // }
    if (event.startDate && event.endDate) {
      this.filter.date_of_service = {
        startDate: null,
        endDate: null
      };
      const noOfDays = this.commonService.getDifferenceOfDates(event.startDate, event.endDate,'days');
      if (noOfDays > 29) {
        this.filter.date_of_service.startDate = moment(event.startDate.toDate());
        this.filter.date_of_service.endDate = moment(this.filter.date_of_service.startDate.toDate()).add(29, "days");
        this.toastr.error('Can not select more than 30 days', 'Date Error');
      }
      else {
        this.filter.date_of_service.startDate = event.startDate.toDate();
        this.filter.date_of_service.endDate = event.endDate.toDate();
      }
    }
  }

  onSelectProvider(item = null) {
    console.log("item", item);
    if (item) {
      this.initFacilities(item);
      this.provider = item;
      this.filter.provider_id = item._id;
    } else {
      this.provider = null;
      this.filter.provider_id = null;
      if ((this._authService.currentUser.user_type == 6 || this._authService.currentUser.user_type == global.USER_TYPE.BILLER)) {

        if (this.filter.date_of_service.startDate && this.filter.date_of_service.endDate) {

          const noOfDays = this.commonService.getDifferenceOfDates(this.filter.date_of_service.startDate, this.filter.date_of_service.endDate,'days');

          if (noOfDays > 30) {
            this.serviceDateFilter = false;
            this.filter.date_of_service.endDate = moment();
            this.filter.date_of_service.startDate = moment(this.filter.date_of_service?.endDate).subtract(7, "days").startOf('day');
            this.toastr.error('Can not select more than 30 days', 'ERROR');

            setTimeout(() => {
              this.serviceDateFilter = true;
            }, 300)
          }

        }

      }
    }
    // if ($event.isUserInput) {
    //   console.log("provider", provider);
    //   this.initFacilities(provider);
    //   this.filter.provider_id = provider._idonSelectProvider;
    //   this.providerControl.setValue(provider.full_name);
    // }
  }
  onSelectFacility(item = null) {
    if (item) {
      this.facility = item;
      this.filter.facility_id = item._id;
      if (item.pcc_facId || item.source === 'PointClickCare') {
        this.showPccLogo = true;
      } else {
        this.showPccLogo = false;
      }
      if (item.source === 'MatrixCare') {
        this.showMatrixLogo = true;
      } else {
        this.showMatrixLogo = false;
      }
      if (item.source != 'PointClickCare' && item.source != 'MatrixCare' && !item.pcc_facId) {
        this.showDnLogo = true;
      } else {
        this.showDnLogo = false;
      }
    } else {
      if (this.currentUser.user_type === global.USER_TYPE.BILLER) {
        this.facilityModel = 'All';
        this.filter.facility_id = null;
      } else {
        this.facilityModel = '';
        this.filter.facility_id = null;
      }
    }
  }
  onSelectStatus(item = null) {
    if (item == 'submit' || item == 'draft' || item == 'billed') {
      this.filter.status = item;
    }
    else {
      this.chargeStatus = 'default';
      delete this.filter?.status;
    }
    if (this.filter.status !== "submit") {
        this.filter.billStatus = "all";
    }
  }
  changeCreationDate(event) {
    const noOfDays = this.commonService.getDifferenceOfDates(event.startDate, event.endDate,'days');
    if (noOfDays > 30) {
      this.creationDateFilter = false;
      this.filter.creation_date.endDate = moment(this.filter.creation_date.startDate).add(29, "days").startOf('day').toDate();
      this.toastr.error('Can not select more than 30 days', 'ERROR');

      setTimeout(() => {
        this.creationDateFilter = true;
      }, 300)
    }
  }
  getDate(date) {
    return moment(new Date(date)).format('MMM DD, YYYY');
  }
  totalCount(groupedData) {
    if (Array.isArray(groupedData)) {
      // console.log("grouped", groupedData);
      let totalCount = 0;
      for (let i = 0; i < groupedData.length; i++) {
        // console.log("fd",groupedData[i]?.notedata?.total);
        totalCount += groupedData[i]?.chargeList?.count;
      }
      // console.log("totalCount", totalCount);
      return totalCount;
    }
  }
  applyFilter() {
    const text = this.searchText.toLowerCase();
    this.dataSource.data = this.chargesArray.filter(data => data.patient_id.first_name.toLowerCase().includes(text) || data.patient_id.last_name.toLowerCase().includes(text));
  }
  handleApplyFilterClick() {
    
    this.charges=[];
    this.dataSource.data=[];
    this.chargesArray=[];
    this.Separatecharges=[];
    this.flatcharges=[];
    
    if(this.chargeView === 'group') {
      this.isVisibleDetailTable = false;
      this.isVisibleTable = true;
      this.fitlerButtonClicked = true;

      this.initCharges();
    }else if(this.chargeView === 'flat'){
      this.initFlatCharges();
    }

  }
  resetFilter() {
    if (this._authService.currentUser.user_type == 6 || this._authService.currentUser.user_type == global.USER_TYPE.BILLER) {
      this.chargeStatus = 'submit';
    }
    else {
      this.chargeStatus = 'default';
    }
    this.showPatientsSearch = false;
    this.searchText = '';
    this.showPccLogo = false;
    this.showMatrixLogo = false;
    this.showDnLogo = false;
    this.fitlerButtonClicked = false;
    this.provider = null;
    this.facility = null;
    this.selectedCptCodes = null;
    this.cptFilter = [];
    this.isVisibleDetailTable = false;
    this.isVisibleTable = true;
    delete this.filter?.creation_date;
    delete this.filter?.provider_id;
    delete this.filter?.facility_id;
    delete this.filter?.status;
    delete this.filter?.billStatus;
    // this.filter.date_of_service.startDate = moment().subtract(7, 'days').toDate();
    // this.filter.date_of_service.endDate = moment().toDate();
    this.providerControl.setValue("");
    this.facilityControl.setValue("");
    this.initCharges();
  }
  showChargeDetail(charges) {
    this.showPatientsSearch = true;
    this.isVisibleDetailTable = true;
    this.isVisibleTable = false;
    this.dataSource.data = charges;
    this.chargesArray = charges;

  }
  //page, doctor: any = null, companyId = null, filter = {}, charge_id = null
  openDialog(element) {
    console.log(element);
    this.billerDashboardService.getPatientsChargesAdmin(null, null, this.company_id, {}, element._id).subscribe((response: any) => {
      if (response.status == 200) {
        if (Array.isArray(response.data) && response.data.length === 1) {
          let patient = response.data[0];
          this.chargesPopup = this.dialog.open(ChargesPopupComponent, { data: { patient, arrayOfcharges: this.chargesArray, route: this.route, adminSide: true }, width: '80%' });
          // this.chargesPopup.afterClosed().subscribe(() => {
          //   this.initCharges();
          //   this.isVisibleDetailTable = false;
          //   this.isVisibleTable = true;
          // });
        }
      }
    });
  }

  async onDeleteClick(element, index) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: "Are you sure you want to delete this Charge?"
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result === "true") {
        const infoToast = this.toastr.info('Deleting Charge...', 'Please wait');
        let response: any = {};
        response = await this._transcriptionCreateService.deleteDraftedCharge(element._id, element.note_id).toPromise();
        if (response.status === 200) {
          this.dataSource.data = [];
          this.chargesArray.splice(index, 1);
          this.dataSource.data = this.chargesArray;
          this.dataSource.sort = this.sort;

          this.toastr.success("Charge deleted", "success");
          this.initCharges();
        } else {
          this.toastr.error(response.message, 'Failed');
        }
      }
    });
  }
  getVisitDate(element) {
    if (element.date_obj) {
      // console.log("element.date_obj: ", element.date_obj);
      let { year, month, day, hour, minute } = element.date_obj;
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      let str = `${month}/${day}/${year}`;
      return str
    }
    else {
      let timeZone = 'America/New_York'
      if (element.facility_id_ref.pcc_timeZone) {
        timeZone = element.facility_id_ref.pcc_timeZone
      }
      else if (element.facility_id_ref.timeZone) {
        timeZone = element.facility_id_ref.timeZone
      }
      return moment(new Date(element.visit_date).toLocaleString('en-US', { timeZone: timeZone })).format('MM/DD/YYYY')
    }
    // return moment(new Date(date).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })).format('MMM DD, YYYY h:mm a');
  }
  getdate(date, data = null) { 
    let d;
    if(data && data.facilityTimeZone){ 
      d = moment(new Date(date).toLocaleString('en-US', { timeZone: data.facilityTimeZone.timeZone })).format('MMM DD, YYYY');  
    }
    else{
      d = moment(new Date(date).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })).format('MMM DD, YYYY');
    }
    return d
    
  }
  getdateOnly(date) {
    return moment(new Date(date)).format('MMM DD, YYYY');
  }
  getEASValue(element){
    if(element.edited_after_submission){
      return 'Yes'
    }
    else{
      return 'No'
    }
  }
  getDateOfUpdation(element) {
    // 'MMM d, y, h:mm a'
    let timeZone = 'America/New_York'
    if (element.facility_id_ref.pcc_timeZone) {
      timeZone = element.facility_id_ref.pcc_timeZone
    }
    else if (element.facility_id_ref.timeZone) {
      timeZone = element.facility_id_ref.timeZone
    }
    if (element.updatedAt) {
      return moment(new Date(element.updatedAt).toLocaleString('en-US', { timeZone: timeZone })).format('MMM DD, YYYY')
    }
    else return '-'
  }
  getDateOfCreation(element) {
    let timeZone = 'America/New_York'
    if (element.facility_id_ref.pcc_timeZone) {
      timeZone = element.facility_id_ref.pcc_timeZone
    }
    else if (element.facility_id_ref.timeZone) {
      timeZone = element.facility_id_ref.timeZone
    }
    if (element.createdAt) {
      return moment(new Date(element.createdAt).toLocaleString('en-US', { timeZone: timeZone })).format('MMM DD, YYYY')
    }
    else {
      return '-'
    }
    // return moment(new Date(element?.createdAt)).format('MMM DD, YYYY');
    if ((element.date_obj || element.date_obj_str) && (this.filter.dateFilter == "doc" || this.filter.dateFilter == "dos")) {
      let datee = element.date_obj_str ? element.date_obj_str : element.date_obj.year.toString() + '-' + element.date_obj.month.toString() + '-' + element.date_obj.day.toString();
      return moment(new Date(datee)).format('MMM DD, YYYY');
    } else {
      // console.log("element.createdAt", element?.createdAt);
      return moment(new Date(element?.createdAt)).format('MMM DD, YYYY');
    }
  }

  async editCharge(charge) {
    let response: any = await this.billerDashboardService.getPatientsChargesAdmin(null, null, this.company_id, {}, charge._id).toPromise()
    if (response.status == 200) {
      if (Array.isArray(response.data) && response.data.length === 1) {
        let patientCharge = response.data[0];
        console.log("Edit Charge Clicked: ", patientCharge)
        let date_obj;
        if (patientCharge.date_obj) {
          // console.log("patientCharge.date_obj: ", patientCharge.date_obj);
          let { year, month, day, hour, minute } = patientCharge.date_obj;
          if (month < 10) {
            month = '0' + month;
          }
          if (day < 10) {
            day = '0' + day;
          }
          let str = `${month}-${day}-${year}`;
          if (hour && minute) {
            if (minute < 10) {
              minute = `0${minute}`
            }
            if (hour < 10) {
              hour = `0${hour}`
            }
            str += ` ${hour}:${minute}`
          }
          date_obj = str;
        }
        this.commonService.setDraftedCharge(patientCharge);
        let route = '';
        if(this.isAdminSide) {
          route = `/company/${this.company_id}/dashboard/patient-charge`
        }
        else {
          route = '/biller-charges/patient-charge';
        }
        this._router.navigate([route], {
          queryParams: {
            patient_id: patientCharge.patient_id._id,
            rs_id: patientCharge.rounding_sheet_id._id,
            visit_date: patientCharge.visit_date.toString(),
            patient_name: patientCharge.patient_id.first_name + ' ' + patientCharge.patient_id.last_name,
            dob: patientCharge.patient_id.date_of_birth,
            // facility_id: patient.facility_id
            facility_id: patientCharge.facility_id_ref._id,
            charge_id: patientCharge._id,
            date_obj,
            backCtrl: true,
          }
        });
      }
    }
  }
  downloadReport(type = "grouped", shouldSendViaEmail = false,reportType) {
    let allNotesData = [];
    let dateName = ('(' + moment(new Date(this.filter.date_of_service?.startDate)).format('MMM Do')) + ' - ' + (moment(new Date(this.filter.date_of_service?.endDate)).format('MMM Do') + ')');
    let fileName = (this.companyName || 'All Charges Report') + ' ' + (dateName || '');
    console.log("dateName : ", fileName);
    if (type === "grouped") {
      this.charges.forEach((charges) => {
        charges.groupedData.forEach((gData) => {
          allNotesData.push({
            "provider": gData?.chargeList?.user?.first_name + " " + gData?.chargeList?.user?.last_name,
            "dos": this.getdateOnly(charges._id),
            "facility": gData?.chargeList?.facility?.title,
            "state": gData && gData.chargeList && gData.chargeList.facility && gData.chargeList.facility.state >= 0 && usa_states[gData.chargeList.facility.state] ? usa_states[gData.chargeList.facility.state].abbreviation : '',
            "census": gData?.chargeList?.census?.name,
            "status": gData?.chargeList?._id?.status === 'submit' ? 'submitted' : gData?.chargeList?._id?.status,
            "bill_status": gData?.chargeList?._id?.bill_status,
            "total": gData?.chargeList?.count
          });
        });
      });

      if (this.filter.dateFilter == "doc") {

        this.billerDashboardService.exportGroupedReportAsXLSX_DOC(allNotesData).subscribe((response: any) => {
          if (response instanceof HttpResponse) {
            var url = window.URL.createObjectURL(response.body);
            var a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = `Grouped Charges Report`;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
            this.toastr.success("Report Downloaded", "Success");
          }
        });
      } else if (this.filter.dateFilter == "dou") {

        this.billerDashboardService.exportGroupedReportAsXLSX_DOU(allNotesData).subscribe((response: any) => {
          if (response instanceof HttpResponse) {
            var url = window.URL.createObjectURL(response.body);
            var a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = `Grouped Charges Report`;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
            this.toastr.success("Report Downloaded", "Success");
          }
        });
      } else {

        this.billerDashboardService.exportGroupedReportAsXLSX(allNotesData).subscribe((response: any) => {
          if (response instanceof HttpResponse) {
            var url = window.URL.createObjectURL(response.body);
            var a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = `Grouped Charges Report`;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
            this.toastr.success("Report Downloaded", "Success");
          }
        });
      }


    } else {
      this.generateFullReport(shouldSendViaEmail,reportType);
    }
  }

  async generateFullReport(shouldSendViaEmail,reportType) {
    let allNotesData = [];
    let dateName = ('(' + moment(new Date(this.filter.date_of_service?.startDate)).format('MMM Do')) + ' - ' + (moment(new Date(this.filter.date_of_service?.endDate)).format('MMM Do') + ')');
    let fileName = (this.companyName || 'All Charges Report') + ' ' + (dateName || '');

    if (shouldSendViaEmail) {
      this.toastr.info('Exporting as XLSX...', 'Please wait', { timeOut: 10000 });
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
        this.toastr.success("Report sent to your email");
      }, 5000);
    }
    this.billerDashboardService.getChargesByFilters_v1(this.filter).subscribe(async (response: any) => {
      if (response.status === 200) {
        let ResCharges = await this.populateFeildsinCharges(response.data);
        ResCharges = this.sortChargesByCreationDate(ResCharges);

        ResCharges.forEach((charges) => {
          charges.groupedData.forEach((gData) => {
            gData?.chargeList?.chargeList.forEach((cData) => {
              let icd_codes = "";
              let cpt_codes = "";
              let cpt_POS = "";
              let cpt_units = "";
              let cpt_modifiers = "";

              cData?.icd_id.forEach((icd, index) => {
                if (index == 0) {
                  icd_codes += icd?.code;
                }
                else {
                  icd_codes += ", " + icd?.code;
                }
              });
              cData?.cpt_id.forEach((cpt, index) => {
                if (index == 0) {
                  cpt_codes += cpt?.code;
                  if (cpt?.place_of_service)
                    cpt_POS += (cpt?.place_of_service || '');
                }
                else {
                  cpt_codes += ", " + cpt?.code;
                  if (cpt?.place_of_service)
                    cpt_POS += ", " + (cpt?.place_of_service || '');
                }
              });
              if (Array.isArray(cData?.cpt_modifiers)) {
                cData.cpt_modifiers.forEach((cptModifier, index) => {
                  if (cptModifier?.modifier) {
                    if (index === 0) {
                      cpt_modifiers += cptModifier.modifier;
                    } else {
                      cpt_modifiers += ", " + cptModifier.modifier;
                    }
                  }
                });
              }
              if (cData && Array.isArray(cData.cpt_id)) {
                // cData.cpt_modifiers.forEach((cptModifier, index) => {
                //   if (cptModifier.unit) {
                //     if (index == 0) {
                //       cpt_units += cptModifier.unit;
                //     }
                //     else {
                //       cpt_units += ", " + cptModifier.unit;
                //     }
                //   }
                // });
                const unitsMap = Array.isArray(cData.cpt_modifiers)
                  ? new Map(cData.cpt_modifiers.map(cpt_modifier => [cpt_modifier.cpt, cpt_modifier.unit]))
                  : new Map();
                cpt_units = cData.cpt_id.map(cpt => unitsMap.get(cpt._id) || "1").join(', ');
              }

              let date_obj = cData?.date_obj?.month.toString() + '/' + cData?.date_obj?.day.toString() + '/'  + cData.date_obj?.year?.toString();
              let stateAbbreviation = cData && cData.facility_id_ref && cData.facility_id_ref.state >= 0 && usa_states[cData.facility_id_ref.state] ? usa_states[cData.facility_id_ref.state].abbreviation : ''
              allNotesData.push({
                "created_at": this.getdate(cData?.createdAt),
                "provider": cData?.owner_id?.first_name + " " + cData?.owner_id?.last_name,
                // "dos":   this.commonService.dateObjToString_format2(cData.date_obj, false),  //this.getdateOnly(date_obj), // 
                "dos": this.getDOS(cData.date_obj),  //this.getdateOnly(date_obj), // 
                "csv_dos": date_obj,
                "facility": cData?.facility_id_ref?.title,
                "state": stateAbbreviation,
                "census": cData?.rounding_sheet_id?.name,
                "status": cData?.status === 'submit' ? 'submitted' : cData?.status,
                "bill_status": cData?.bill_status,
                "patient": cData?.patient_id?.first_name + " " + cData?.patient_id?.last_name,
                // "date_of_birth": cData?.patient_id?.date_of_birth,
                "date_of_birth": this.getDOB(cData?.patient_id?.date_of_birth),
                "csv_dob": this.commonService.convertDateToStringforMoment(moment(cData.patient_id.date_of_birth), false, false, true),
                "updated_at": this.getdate(cData?.updatedAt, cData),
                "icd": icd_codes,
                "cpt": cpt_codes,
                "charge_id": cData?._id,
                "external_provider_id": cData?.owner_id?.trueRCMID,
                // "external_facility_id": cData?.facility_id_ref?.trueRCMID,
                "pos": cpt_POS,
                "Snpi": cData?.Snpi,
                "Pnpi": cData?.Pnpi,
                "modifier": cData?.company_id?.modifier,
                "cpt_unit": cpt_units,
                "cpt_modifiers2": cData?.cpt_modifiers,
                "cpt_ids": cData?.cpt_id,
                "cpt_modifier": cpt_modifiers,
                "patient_fname": cData?.patient_id?.first_name,
                "patient_lname": cData?.patient_id?.last_name,
              });
            })
          });
        });
    
        if (reportType === 'csv') {
          await this.convertToCSV(allNotesData, fileName);
        } else {
          await this.convertToXLSX(allNotesData, dateName, fileName, shouldSendViaEmail);
        }
      }
    });
  }
  getPCPName(patient) {
    let first_name = '';
    let last_name = '';
    if (patient) {
      if (patient.pcc_primaryPractitioner?.firstName && patient.pcc_primaryPractitioner?.firstName != undefined && patient.pcc_primaryPractitioner?.firstName != null)
        first_name = patient.pcc_primaryPractitioner.firstName
      if (patient.pcc_primaryPractitioner?.lastName && patient.pcc_primaryPractitioner?.lastName != undefined && patient.pcc_primaryPractitioner?.lastName != null)
        last_name = patient.pcc_primaryPractitioner.lastName
    }
    return first_name + " " + last_name;
  }

  formatInsuranceName(coverage_data, payerRank) {
    if (!coverage_data) {
      return 'N/A';
    }
    if (coverage_data?.payers) {
      if (Array.isArray(coverage_data?.payers)) {
        const primaryPayor = coverage_data.payers.find(payer => payer.payerRank === payerRank);
        if (primaryPayor) {
          return primaryPayor.payerName;
        }
      } else {
        return '';
      }
    }
    else {
      const payer = coverage_data[payerRank];
      if (payer) {
        return payer.payerName;
      }
      return 'N/A';
    }
  }

  formatPolicyNumber(coverage_data, payerRank) {
    if (!coverage_data) {
      return 'N/A';
    }
    const payer = coverage_data[payerRank];
    if (payer?.issuer?.planNumber) {
      return payer.issuer.planNumber;
    }
    return 'N/A';
  }

  async convertToXLSX(chargesData, dateName, fileName, sendtoEmail=false) {
    if (chargesData.length > 0) {
      const infoToastr = this.toastr.info('Fetching Data...', "Please Wait");
      const reportTitle = 'Charge Capture - ' + (fileName || 'All Charges Report') 
      const columnsData = [
        { header: "DOS", key: "dos", width: 22 },
        { header: "DOB", key: "date_of_birth", width: 22 },
        { header: "Patient ID", key: "patient_id", width: 25 },
        { header: "Patient First Name", key: "patient_fname", width: 20 },
        { header: "Patient Last Name", key: "patient_lname", width: 20 },
        { header: "Patient Name", key: "patient", width: 20 },
        { header: "Provider ID", key: "provider_id", width: 25 },
        { header: "Provider", key: "provider", width: 20 },
        { header: "External Provider ID", key: "external_provider_id", width: 25 },
        { header: "Facility ID", key: "facility_id", width: 25 },
        { header: "Facility", key: "facility", width: 30 },
        { header: "Facility NPI", key: "facility_npi", width: 20 }, 
        { header: "Census", key: "census", width: 15 },
        { header: "Charge Status", key: "status", width: 20 },
        { header: "Billed Status", key: "bill_status", width: 20 },
        { header: "Biller Note", key: "note", width: 20 },
        { header: "Submit Date", key: "submit_date", width: 20 },
        { header: "Date of Creation", key: "created_at", width: 20 },
        { header: "Update Date", key: "updated_at", width: 20 },
        { header: "Provider NPI", key: "Pnpi", width: 20 },
        { header: "Supervisor NPI", key: "Snpi", width: 20 },
        { header: "Modifier", key: "modifier", width: 20 },
        { header: "Charge ID", key: "charge_id", width: 25 },
        { header: "POS", key: "pos", width: 20 },
        { header: "CPT Codes", key: "cpt", width: 20 },
        { header: "ICD Codes", key: "icd", width: 20 },
        { header: "CPT Modifiers", key: "cpt_modifier", width: 20 },
        { header: "CPT Units", key: "cpt_unit", width: 20 },
        { header: "Source", key: "source", width: 20 },
        { header: "SSN", key: "ssn", width: 20 },
        { header: "Medicare Number", key: "medicare_number", width: 20 },
        { header: "Medicaid Number", key: "medicaid_number", width: 20 },
        { header: "Patient Address", key: "patient_address", width: 40 },
        { header: "City", key: "city", width: 20 },
        { header: "State", key: "state", width: 20 },
        { header: "Zip Code", key: "zip_code", width: 20 },
        { header: "PCP", key: "pcp_name", width: 20 },
        { header: "Admission Date", key: "admission_date", width: 20 },
        { header: "Primary Insurance", key: "primary_insurance", width: 30 },
        { header: "Secondary Insurance", key: "secondary_insurance", width: 30 },
        { header: "Primary Insurance - Policy Number", key: "primary_insurance_policy_number", width: 20 },
        { header: "Secondary Insurance - Policy Number", key: "secondary_insurance_policy_number", width: 20 },
        { header: "Gender", key: "patient_gender", width: 20 },
        { header: "Primary Physician/Attending Physician", key: "primary_physician", width: 20 },
      ]
      if(sendtoEmail) {
        this.billerDashboardService.exportFullReportAsXLSX(chargesData, this.company, sendtoEmail, columnsData).subscribe((response: any) => {
          if (response instanceof HttpResponse) {
            if (response.status == 200) {
              this.toastr.success("Report Send to Email", "Success");
            }
            else {
              this.toastr.error("Error occurred while attempting to send the report via email", "Error");
            }
          }
          
          
        })
      }
      else {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(`${reportTitle}`);
        worksheet.columns = columnsData;
        for (const charge of chargesData) {
          worksheet.addRow(flatten(charge));
        }
        worksheet.getRow(1).eachCell((cell) => {
          cell.font = { bold: true };
        });
        worksheet.eachRow((row) => {
          row.eachCell((cell) => {
            cell.alignment = { wrapText: true };
          })
        })
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        
        
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${reportTitle}.xlsx`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          this.toastr.success("Report Downloaded", "Success");
          this.toastr.clear(infoToastr.toastId);
      }
      
      
      
    }
    else {
      this.toastr.info('No Data To Export!', "Info");
    }
  }
  getDOB(dob) {
    if (dob) {
      let datesplit = dob.split('-');
      let year = datesplit[0];
      let month = datesplit[1];
      let day = datesplit[2];
      let formatted_date_of_birth;
      let alphaMonth = this.months[Number(month - 1)];
      datesplit = `${alphaMonth} ${day}, ${year}`
      formatted_date_of_birth = datesplit;
      return formatted_date_of_birth;
    }
  }

  getDOS(date_obj) {
    if (date_obj) {
      let datesplitDos = '';
      let { year, month, day, hour = 0, minute = 0 } = date_obj;
      let formatted_date_of_service;
      let alphaMonth = this.months[Number(month - 1)];
      datesplitDos = `${alphaMonth} ${day}, ${year}`
      formatted_date_of_service = datesplitDos;
      return formatted_date_of_service;
    }
  }
  async getCompanyName() {
    const companyName = await this.companyService.getCompanyName(this.filter.company_id).toPromise()
    this.companyName = companyName.data?.name;
    console.log("this.companyData : ", this.companyName);
  }

  checkVoidCensus(patientRoundingSheet) {
    if (patientRoundingSheet) {
      if (patientRoundingSheet?.is_void) {
        if (patientRoundingSheet.is_void === true) return false;
        else return true;
      } else {
        return true
      }
    } else {
      return true;
    }
  }
  onFacilityClick(data) {
    this.facilityControl.setValue(data?.chargeList?.facility?.title);
    this.onSelectFacility(data?.chargeList?.facility)
    this.handleApplyFilterClick()
  };
  generateFacesheetAndDownloadAsPDF(patient_id) {
    console.log('patient id', patient_id);
    const infoToastr: any = this.toastr.info('Generating PDF', 'Please wait...');
    try {
      const params = {
        patient_id
      }
      return this.dashboardService.downloadFile(global.url + API_URL.PATIENT.generateFacesheetAndDownloadAsPDF, params).subscribe((res: any) => {
        if (res instanceof HttpResponse) {

          var url = window.URL.createObjectURL(res.body);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = 'facesheet.pdf';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
          this.toastr.clear(infoToastr.toastId);
        } else {
          if (res.type === 0) {
          } else if (res.type === 3) {
            const { loaded, total } = res;
            const progress = Math.round(loaded / total * 100);
            if (progress > 0) {
            }
          }
        }
        if (res.status == 200) {
          return true;
        }
      })
    } catch (error) {
      const { message = 'Something went wrong, please try again' } = error;
      this.toastr.error(message);
    } finally {
      this.toastr.clear(infoToastr.toastId);
    }
  }
  viewPDF(element, index?) {
    if (element.faceSheet?.aws_path && element.faceSheet.aws_path.includes("http")) {
      this.dialog.open(PdfViewerPopComponent, {
        width: '50vw',
        height: '95vh',
        data: { file_path: element.faceSheet.aws_path, file: element }
      })
    } else {
      let data = {
        patient_id: element.patient_id._id,
        aws_path: element.faceSheet?.aws_path,
        title: element.faceSheet?.title
      }
      this.patientDocumentService.generateViewPdfUrl(data).subscribe((response: any) => {
        if (response.status === 200) {
          if (!element.faceSheet) {
            let faceSheet = {
              aws_path: null,
              title: null
            }
            element = {
              ...element, faceSheet
            }
          }
          element.faceSheet.aws_path = response.document.aws_path
          element.faceSheet.title = response.document.title
          this.dialog.open(PdfViewerPopComponent, {
            width: '50vw',
            height: '95vh',
            data: {
              file_path: element.faceSheet.aws_path,
              ...(element.company_facility_patients &&
                element.company_facility_patients.facility_id &&
                element.company_facility_patients.facility_id.source &&
                { file: element })
            }
          });
        }
        else {
          this.toastr.error('Something went wrong please try again.')
        }
      }, () => this.toastr.error('Something went wrong please try again.')
      )
    }
  }
  isPCCFacility(source) {
    return source?.toLowerCase() === 'pointclickcare';
  }
  isMatrixFacility(source) {
    return source?.toLowerCase() === 'matrixcare';
  }
  isDNFacility(source) {
    return source?.toLowerCase() != 'pointclickcare' && source?.toLowerCase() != 'matrixcare';
  }
  isPCCFacilityDetails(facility) {
    let source = facility.facility_id_ref.source;
    return source?.toLowerCase() === 'pointclickcare';
  }
  isMatrixFacilityDetails(facility) {
    let source = facility.facility_id_ref.source;
    return source?.toLowerCase() === 'matrixcare';
  }
  isDNFacilityDetails(facility) {
    let source = facility.facility_id_ref.source;
    return source?.toLowerCase() != 'pointclickcare' && source?.toLowerCase() != 'matrixcare';
  }
  viewBillerNote(note) {
    swal({
      title: "Biller Comment",
      text: note,
      dangerMode: true,
      buttons: [, 'Close'],
    })
      .then(() => {
      });
  }

  async downloadFileWithPresignedURL(element, index) {
    console.log("patient_id : ", element);
    if (element.patient_id?.pcc_patientId && element.patient_id?.pcc_coverage_data) {
      const infoToast = this.toastr.info('Preparing file to download...', 'Please wait', { disableTimeOut: true });
      if (this.generateFacesheetAndDownloadAsPDF(element.patient_id._id)) {
        this.toastr.clear(infoToast.toastId);
      } else {
        this.showPatientDocumentsUploadDialog(index, element.patient_id._id);
      }
    } else {
      if (!element.faceSheet) {
        this.showPatientDocumentsUploadDialog(index, element.patient_id._id)
      } else {
        const infoToast = this.toastr.info('Preparing file to download...', 'Please wait', { disableTimeOut: true });
        try {
          const downloadFileResponse: any = await this.fileExplorerService.downLoadAnyFileWithURL(element.faceSheet.aws_path, element.faceSheet.title, element.patient_id?._id).toPromise();
          var url = window.URL.createObjectURL(downloadFileResponse);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = <string>element.faceSheet.title;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        } catch (error) {
          this.toastr.error('Unable to download file.', 'Failed');
        } finally {
          this.toastr.clear(infoToast.toastId);
        }
      }
    }
  }

  async downloadFile(awsPath, title) {
    const infoToast = this.toastr.info('Preparing file to download...', 'Please wait', { disableTimeOut: true });
    try {
      const downloadFileResponse: any = await this.fileExplorerService.downLoadAnyFile(awsPath, title).toPromise();
      var url = window.URL.createObjectURL(downloadFileResponse);
      var a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = <string>title;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    } catch (error) {
      this.toastr.error('Unable to download file.', 'Failed');
    } finally {
      this.toastr.clear(infoToast.toastId);
    }
  }
  showPatientDocumentsUploadDialog(index, patientId) {
    console.log("this.chargesArray", this.chargesArray);
    const dialogRef = this.dialog.open(
      PatientDocumentsUploadDialog,
      { width: '80%' }
    );
    dialogRef.beforeClosed().subscribe(async (fileInput: any) => {
      if (fileInput) {
        const [file] = fileInput.files;
        const infoToast = this.toastr.info('Uploading Face Sheet...', 'Please wait')
        const company_id = this._authService.currentUser.company_id;
        this._faceSheetService.uploadFaceSheet(file, patientId, company_id).subscribe((response: any) => {
          if (response.status === 200) {
            const { _id, title, aws_path } = response.data;
            const faceSheet = {
              _id,
              title,
              aws_path
            };

            let charge = this.chargesArray[index];
            charge['faceSheet'] = faceSheet;
            this.dataSource.data[index].faceSheet = faceSheet;
            this.toastr.success("Face Sheet Uploaded", 'Success');
            this.downloadFaceSheetActive(this.chargesArray[index], index);
          }
          else {
            console.error(response.data.status, response.data.message);
            this.toastr.error(response.data.message, 'Failed');
          }
        });
      }
    });
  }
  async downloadBatchReport() {
    this.toastr.info('Downloading Batch Notes Report', 'Downloading...');
    let chargeIds = [];
    this.charges.forEach((charges) => {
      const report = {} as BReport;
      report.dos = this.getDate(charges._id);
      report.provider = [];
      charges.groupedData.forEach((gData) => {
        for(let id of gData.chargeList?.chargeList)
          chargeIds.push({chargeId:id, dos: report.dos})
      });
      
    });
    await this.billerDashboardService.generateBatchReportofFacesheets(chargeIds).subscribe((res) => {
      if (res instanceof HttpResponse) {
        var url = window.URL.createObjectURL(res.body);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = "Batch Report.zip";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
        this.toastr.success('Batch Report Downloaded', 'Success');
      } else {
      }
    }, (err) => {
      this.toastr.error('Something went wrong while downloading batch report', 'Failed');
    },
      () => {
      });
  }

  sortData(sort: Sort) {
    const data = this.chargesArray.slice();
    this.chargesArray = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {

        case 'patient_name':

          return compare(a.patient_id?.last_name, b.patient_id?.last_name, isAsc);

        default: return 0;
      }
    });
    // this.dataSource = this.selectedpatients;
    this.dataSource = new MatTableDataSource(this.chargesArray);
    this.dataSource.sort = this.sort;

  }


  selectedCharge(element) {
    if (this.selectedCharges.findIndex(charge => charge == element._id) > -1) {
      return true
    }
    else {
      return false
    }
  }
  selectCharge(element, event) {
    if (event.checked) {
      this.selectedCharges.push(element._id)
    }
    if (!event.checked) {
      let index = this.selectedCharges.indexOf(element._id);
      if (index > -1) {
        this.selectedCharges.splice(index, 1)
      }
    }
  }
  selectChargeAll($event) {
    if ($event.checked) {
      let allCharges = this.dataSource.data;
      this.selectedCharges = allCharges.map(charg => charg._id);
    }
    if (!$event.checked) {
      this.selectedCharges = [];
    }
  }
  viewNoteDialog(charge) {
    let time_zone = 'America/New_York'
    if (charge && charge.facility_id_ref) {
      time_zone = charge.facility_id_ref.pcc_timeZone ? charge.facility_id_ref.pcc_timeZone : charge.facility_id_ref.timeZone
    }
    const dialogRef = this.dialog.open(ViewNoteDialog, {
      width: '80%',
      //height:'80%',
      data: {
        note_id: charge.note_id,
        timezone: time_zone
        // void_census: this.checkVoidCensus(charge.rounding_sheet_id),
      }
    });
  }
  async billedAllCharges(billStatus, charge?) {
    let notificationResponse: any;
    if (charge) {
      this.selectedCharges = [];
      this.selectedCharges.push(charge._id)
    }
    let startDate = moment(this.filter.date_of_service.startDate).format("MM/DD/YYYY");
    let endDate = moment(this.filter.date_of_service.endDate).format("MM/DD/YYYY");

    let response: any = await this.billerDashboardService.SubmitAllandApprove(this.selectedCharges, 'billed', billStatus).toPromise();
    if (response.status == 200) {
      this.selectedCharges = [];
      if (billStatus == 'addendum_req') {
        let query = {
          type: "success",
          recipient_ids: [`${charge.owner_id._id}`],
          recipient_type: "biller_addendum_req",
          description: `${startDate}:${endDate}:${charge.owner_id._id}:${charge.facility_id_ref._id}`,
          createdById: this.currentUser._id,
          routeUrl: "",
          seenBy: [],
          relationIds: []
        }
        this.notification_response = await this.notificationsService.addNotification(query).subscribe((response: any) => {
          if (response.status == 200) {
            this.toastr.success("Charge status set to addendum & Notification sent", "Success");
          }
          else
            this.toastr.error(response.message, 'error');
        });
      }
      else
        this.toastr.success("All Selected Charges are Updated", "Success");
      this.initCharges();
      this.isVisibleDetailTable = false;
      if (charge) this.isVisibleDetailTable = true;
    } else {
      this.toastr.error("Something went Wrong", "Error");
    }

  }

  ngOnDestroy() {
    this._woundCareWidgetService.setIsTeleHealthReport(false);
    if (this.notification_response)
      this.notification_response.unsubscribe();
  }

  async archivedAllCharges() {
    let response: any = await this.billerDashboardService.SubmitAllandApprove(this.selectedCharges, 'archive').toPromise();
    if (response.status == 200) {
      this.selectedCharges = [];
      this.toastr.success("All Selected Charges are archived", "Success");
      this.initCharges();
      this.isVisibleDetailTable = false;
    } else {
      this.toastr.error("Something went Wrong", "Error");
    }
  }

  generateFacesheetForMatrixAndDownloadAsPDF(patient_id) {
    const infoToastr: any = this.toastr.info('Generating PDF', 'Please wait...');
    try {
      const params = {
        source: 'MatrixCare',
        patient_id
      }
      return this.dashboardService.downloadFile(global.url + API_URL.PATIENT.generateFacesheetForMatrixAndDownloadAsPDF, params).subscribe((res: any) => {
        if (res instanceof HttpResponse) {

          var url = window.URL.createObjectURL(res.body);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = 'facesheet.pdf';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
          this.toastr.clear(infoToastr.toastId);
        } else {
          if (res.type === 0) {
          } else if (res.type === 3) {
            const { loaded, total } = res;
            const progress = Math.round(loaded / total * 100);
            if (progress > 0) {
            }
          }
        }
        if (res.status == 200) {
          return true;
        }
      })
    } catch (error) {
      const { message = 'Something went wrong, please try again' } = error;
      this.toastr.error(message);
    } finally {
      this.toastr.clear(infoToastr.toastId);
    }
  }

  uploadFaceSheetActive(element, index) {
    return true;
  }
  downloadFaceSheetActive(element, index) {
    const charge = element;
    const patient = charge.patient_id;
    if (!patient) return false;
    if (patient.source === 'PointClickCare') return true;
    if (patient.source === 'MatrixCare' && patient.matrix_payload?.coverages?.length > 0) return true;
    if (charge.faceSheet) return true;

    return false;
  }
  async handleUploadFacesheetClick(element, index) {
    const charge = element;
    const patient = charge.patient_id;
    if (!index)
      index = this.chargesArray.findIndex(charge => charge._id == element._id);
    this.showPatientDocumentsUploadDialog(index, patient._id);
  }

  async handleDownloadFacesheetClick(element, index) {
    const charge = element;
    const patient = charge.patient_id;

    if (patient.source === 'PointClickCare') {
      let hasPCCFacesheetData = false;
      const details = {
        ...this._authService.authObject,
        patientId: patient._id,
      }
      const { status, data } = await lastValueFrom(this.pccService.syncCoveragesData({ details })) as any;
      if (status === 200) {
        const _patient = data;
        if ((_patient?.pcc_coverage_data?.Primary || _patient?.pcc_coverage_data?.Secondary || _patient?.pcc_coverage_data?.Tertiary)) {
          hasPCCFacesheetData = true
        }
      }
      if (hasPCCFacesheetData) {
        this.generateFacesheetAndDownloadAsPDF(patient._id);
        return;
      }
    }
    if (patient.source === 'MatrixCare' && patient.matrix_payload?.coverages?.length > 0) {
      this.generateFacesheetForMatrixAndDownloadAsPDF(patient._id);
      return;
    }
    const infoToast = this.toastr.info('Preparing file to download...', 'Please wait', { disableTimeOut: true });
    try {
      const downloadFileResponse: any = await this.fileExplorerService.downLoadAnyFileWithURL(element.faceSheet.aws_path, element.faceSheet.title, element.patient_id?._id).toPromise();
      var url = window.URL.createObjectURL(downloadFileResponse);
      var a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = <string>element.faceSheet.title;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    } catch (error) {
      this.toastr.error('Unable to download file.', 'Failed');
    } finally {
      this.toastr.clear(infoToast.toastId);
    }
  }

  downloadJSONReport() {
    this.billerDashboardService.downloadJSONFile(this.filter).subscribe((data) => {
      if (data['status'] == 200) {
        const jsonData = JSON.stringify(data['submitCharges']);
        const file = new Blob([jsonData], { type: 'text/json' });
        this.download(file, 'submit-charges.json')
      }
      else {
        this.toastr.error('No Data Found', 'ERROR')
      }
    })
  }

  download(blob, filename) {
    var a = document.createElement("a"),
      url = URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }

  openChargeCommentDialog(charge, index) {
    const config = {
      data: _.cloneDeep(charge),
      width: '50%'
    }
    this.dialog.open(ChargeCommentDialogComponent, config).afterClosed().subscribe((updatedCharge) => {
      if (updatedCharge) {
        charge.note = updatedCharge.note;
        this.chargesArray[index].note = updatedCharge.note;
      }
    });
  }
  removeFacility($event) {
    if ($event === "")
      delete this.filter.facility_id;
  }
  handlePagination() {
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    if (this.pagination.skip + this.flatcharges.length < this.pagination.totalRecords) {
      this.pagination.hasNextPage = true;
    }
    else {
      this.pagination.hasNextPage = false;
    }
    if (this.pagination.skip > 0) {
      this.pagination.hasPrevPage = true;
    }
    else {
      this.pagination.hasPrevPage = false;
    }
    this.pagination.shouldShowCount = true;
  }

  resubmitCharges() {
    this.billerDashboardService.SubmitAllandApprove(this.selectedCharges,'resubmit', null).subscribe((res: any)=> {
      if(res.status == 200) {
        this.selectedCharges = [];
        this.toastr.success('Successfully Resubmitted','Success');
      }
    });
  }

  toggleRow(id) {
    this.expandedRows.splice(this.expandedRows.indexOf(id), 1);
    this.expandedRows.push(id);
  }

  onTabChange(event) {
    this.accordianTabIndex = event.index;
  }
  async convertToCSV(chargesData, fileName) {
    if (chargesData.length === 0) {
      this.toastr.info('No Data To Export!', "Info");
      return;
    }
    const flattenedData = this.flattenChargesData(chargesData);
    flattenedData.forEach((row) => {
      let cptCode = row.cpt;
      if (row['cpt_modifiers2'] && row['cpt_modifiers2'].length === 0) {
        row.new_cpt_unit = '1';
      }
      row.cpt_ids.forEach((cpt) => {
        row.cpt_modifiers2.forEach((modifier)=> {
          if (cpt._id.toString() === modifier.cpt.toString() && cptCode === cpt.code) {
            row.new_cpt_unit = modifier.unit;
            row.new_cpt_modifier = modifier.modifier || '';
          }
          if (!row['new_cpt_unit']) {
            row.new_cpt_unit = '1';
          }
        });
      });
    });
    console.log('flattenedData: ', flattenedData);
    const csvContent = this.generateCSVContent(flattenedData);
    const reportTitle = 'Charge Capture - ' + (fileName || 'All Charges Report');
    this.downloadCSV(csvContent, reportTitle);
    this.toastr.success("Report Downloaded", "Success");
  }
  flattenChargesData(chargesData) {
    return chargesData.flatMap(charge => {
      const cpts = charge.cpt.split(', ');
      const units = charge.cpt_unit ? charge.cpt_unit.split(', ') : [];
      const modifiers = charge.cpt_modifier ? charge.cpt_modifier.split(', ') : [];
      const icds = charge.icd ? charge.icd.split(', ') : [];
      return cpts.map((cpt, index) => {
        const col_modifiers = modifiers[index] ? modifiers[index].split(',') : [];
        const cptModifiers = {
          cpt_modifier_1: col_modifiers[0] || '',
          cpt_modifier_2: '',
          cpt_modifier_3: '',
          cpt_modifier_4: '',
        };
        return {
          ...charge,
          cpt,
          cpt_unit: units[index] || '',
          ...cptModifiers,
          icd_1: icds[0] || '',
          icd_2: icds[1] || '',
          icd_3: icds[2] || '',
          icd_4: icds[3] || '',
        };
      });
    });
  }

  generateCSVContent(chargesData) {
    const newColumnsData = [
      { header: "Patient_Last_Name", key: "patient_lname" },
      { header: "Patient_First_Name", key: "patient_fname" },
      { header: "Patient_DOB", key: "csv_dob" },
      { header: "Claim ID", key: "charge_id" },
      { header: "Date Of Service", key: "csv_dos" },
      { header: "Rendering Provider", key: "provider" },
      { header: "Billing Provider", key: "provider" },
      { header: "Facility", key: "facility" },
      { header: "Procedure Code", key: "cpt" },
      { header: "Units", key: "new_cpt_unit" },
      { header: "DX1", key: "icd_1" },
      { header: "DX2", key: "icd_2" },
      { header: "DX3", key: "icd_3" },
      { header: "DX4", key: "icd_4" },
      { header: "MOD1", key: "new_cpt_modifier" },
      { header: "MOD2", key: "cpt_modifier_2" },
      { header: "MOD3", key: "cpt_modifier_3" },
      { header: "MOD4", key: "cpt_modifier_4" },
    ];

    const csvRows = [newColumnsData.map(col => col.header).join(',')];

    for (const charge of chargesData) {
      const row = [];
      newColumnsData.forEach(col => {
        let value;
        if (col.header === "Billing Provider") {
          value = this.companyName;
        } else {
          value = charge[col.key];
        }
        row.push(value !== undefined && value !== null ? `"${value}"` : '""');
      });
      csvRows.push(row.join(','));
    }

    return csvRows.join('\n');
  }

  downloadCSV(csvContent, reportTitle) {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${reportTitle}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}