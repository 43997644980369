<div class="flex flex-row gap-2" style="height: 100vh;overflow: auto;">
    <table  class="w-full" mat-table matSort
      [dataSource]="dataSource"  style="width: 414px;height: fit-content;">
     
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="name" (click)="filterSort = 'name'">Patients Name </th>
        <td mat-cell *matCellDef="let element" (click)="onRowClick($event, element._id)">
            <div [class.white-text-selected]="clickedRows.has(element)">
          <app-patient-title [patient]="element"></app-patient-title>
        </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="note">Note</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="getRecentNoteInCensus(element)">
            <app-note-status-icon [note]="getRecentNoteInCensus(element)"
              (click)="showViewNoteDialog(getRecentNoteInCensus(element)._id)">
            </app-note-status-icon>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="operations">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="operations">Actions</th>
        <ng-container *matCellDef="let element; let i=index">
          <td mat-cell *ngIf="element.is_active == 'true'">
            <div class="operation-buttons flex flex-row items-center gap-1">
              <a *ngIf="census && isOwner(census) ||census && this.currentUser.user_type===global.USER_TYPE.MEDICAL_ASSISTANT || census && this.currentUser.user_type===global.USER_TYPE.TRANSCRIBER "
                (click)="noteEditor(element, false, i);show_selected(element)" class="flex items-center justify-center">
                <i class="icon icon-add" matTooltip="Create Note" matTooltipPosition="above"></i>
              </a>

              
            </div>
          </td>
        </ng-container>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row [class.demo-row-is-clicked]="clickedRows.has(row)"  style="cursor:hover;" *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
  </div>