<div class="flex flex-col items-center">

    <b style="font-size: 16px; margin-bottom: 13px;">{{title}}</b>

    <div class="flex flex-col items-start" style="flex: 90%;">

        <div style="margin-top: 2px;" class="flex flex-row justify-start items-start">

            <label class="field field_v1 w-full">
                <textarea style="font-size: 13px;" class="field__input" ngModel matInput type="text" #comment="ngModel"
                    placeholder="Ex. It feels like..."></textarea>
            </label>
            

        </div>
    </div>
    <mat-dialog-actions style="justify-content: flex-end; margin-top: 10px;">
        <button mat-raised-button color="warn" (click)="handleClose(false)">Cancel</button> &nbsp;&nbsp;
        <button mat-raised-button color="primary" (click)="handleClose(comment.value)">Send</button>
    </mat-dialog-actions>

    <ngx-ui-loader [loaderId]="mail_loader" bgsPosition= "center-center"></ngx-ui-loader>

</div>