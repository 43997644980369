<h3>Add Custom Phrase</h3>
<form (submit)="addPhrase(facilityForm.value)" #facilityForm="ngForm">

    <div class="flex flex-col">
        <mat-form-field>
            <input matInput type="text" name="facility_title"
                   #facility_title="ngModel"
                   [(ngModel)]="phrase"
                   placeholder="Phrase Text" required>
            <mat-error *ngIf="facility_title.invalid">Please enter phrase</mat-error>
        </mat-form-field>
    </div>
    <div class="flex justify-center items-center gap-2">
        <button class="theme-btn" mat-stroked-button
                [disabled]="!facilityForm.valid"
                type="submit">Save</button>
        <button class="theme-btn" mat-stroked-button type="button" mat-dialog-close>Cancel</button>
    </div>

</form>

<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>
