<div class="child-wound-item flex flex-col gap-1" *ngIf="childWound" [ngClass]="{'non-clickable': childWound.state === 'archived' || actualParentWound.state === 'archived'}">
    <div class="child-wound-item-main flex flex-col gap-1">
        <div class="flex justify-between items-center gap-2">
            <div class="flex justify-center items-center gap-1">
                <div class="flex flex-col justify-center items-center">
                    <strong *ngIf="childWound.created_by_user_type === '1' && currentUser.user_type != global.USER_TYPE.MEDICAL_ASSISTANT">PR</strong>
                    <strong *ngIf="childWound.created_by_user_type === 'snf_wc_nurse'">NS</strong>
                    <strong *ngIf="childWound.created_by_user_type === 'wound_nurse'">WN</strong>
                    <strong *ngIf="childWound.created_by_user_type === global.USER_TYPE.MEDICAL_ASSISTANT || currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT">MA</strong>
                </div>
                <div class="child-wound-image-wrapper flex justify-center items-center">
                    <div class="child-wound-image cursor-pointer" (click)="showWoundImagePreviewDialog(childWound, 0)"
                        [style.backgroundImage]="mainImage ? 'url(' + mainImage + ')' : 'none'">
                        <div class="child-wound-image-caption top"><strong>{{childWound.created_At}}</strong></div>
                    </div>
                </div>
            </div>
            <div class="child-wound-details flex-grow">
                <div class="flex justify-end items-end">
                    <b *ngIf="childWound.is_clone" style="float: right; cursor: default;" matTooltip="Addendum Wound">
                        ADDENDUM
                    </b>
                </div>
                <div class="flex justify-between items-center">
                    <div class="flex-grow" (click)="!readOnlyMode && !isPreview && handleChildWoundClick()"
                        style="cursor:pointer">
                        <div>Severity: <strong> {{childWound.stage}}</strong></div>
                        <div>Length: {{childWound.length}} cm</div>
                        <div>Width: {{childWound.width}} cm</div>
                        <div>Depth: {{childWound.depth}} cm</div>
                        <div><strong>Area: {{calculateArea(childWound)}} <var>cm<sup>2</sup></var></strong></div>
                        <div><strong>Volume: {{calculateVolume(childWound)}} <var>cm<sup>3</sup></var></strong>
                        </div>
                    </div>
                    <mat-icon *ngIf="checkLockConditions()">lock</mat-icon>
                </div>
                <div *ngIf="currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE" class="cursor-pointer" (click)="viewScores()"><strong>Push Score: {{calculateAreaSubscore(childWound)+calculateExudateAmount(childWound)+calculateTissueType(childWound)}}</strong></div>
                <div class="child-wound-actions-container">
                    <div fxLayout *ngIf="!childWound._most_recent_wound || childWound.parent_wound === null">
                        <button [ngClass]="childWound.totalProcedureNotes > 0 ? 'green-background': ''" mat-raised-button class="dn-button-primary" (click)="showProcedureNoteDialog()" style="color: white;">
                            Procedure Notes ({{childWound.totalProcedureNotes}})
                        </button>
                    </div>
                    <div fxLayout class="actions-container">
                        <span *ngIf="childWound.is_clone && !childWound.is_wound_locked && isDnFacility()"
                            (click)="lockWoundManually(childWound)" style="float: right; padding-bottom: 4px; cursor: pointer;"
                            class="material-symbols-outlined icon-navy" matTooltip="Lock Wound">
                            lock
                        </span>
                        <span><a *ngIf="currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER || isTeleHealthReport
                                            ||(isAdminSide&&(currentUser.admin_type == 'full_admin'|| currentUser.admin_type == 'system_admin'))"
                                (click)="downloadSingleImage()" style="color: #23406D;cursor: pointer;"
                                matTooltip="Download Wound Image"><mat-icon>image</mat-icon></a></span>
                        <span
                            *ngIf="childWound.state === 'active' && actualParentWound.state !== 'archived' && (isPreview || !childWound._most_recent_wound) && !isTeleHealthReport && should_show_view_icon"
                            style="float: right;" class="material-symbols-outlined icon-navy report-icon"
                            (click)="showChildWoundReport.emit({downloadReport: false})"
                            matTooltip="View Wound Assessment Report">remove_red_eye</span>
                        <span
                            *ngIf="childWound.state === 'active' && actualParentWound.state !== 'archived' && currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER"
                            style="float: right;" class="material-symbols-outlined icon-navy report-icon"
                            (click)="downloadChildWoundPDF()" matTooltip="Download Wound Assessment Report"> file_download
                        </span>
                        <span
                            *ngIf="canUploadReport() && childWound.state === 'active' && actualParentWound.state !== 'archived' && (currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER && currentUser.user_type != global.USER_TYPE.BUSINESS_OFFICER && currentUser.user_type != global.USER_TYPE.WOUND_NURSE) && !isTeleHealthReport && !childWound.is_clone"
                            style="float: right;" class="material-symbols-outlined icon-navy report-icon"
                            (click)="showChildWoundReport.emit({downloadReport: true})"
                            matTooltip="Upload Wound Assessment Report to PCC">file_upload</span>
                        <span class="material-symbols-outlined" style="cursor: pointer;" *ngIf="skinSubRefferal"
                            [ngClass]="{'activeColor': childWound.skin_relation == true}" style="cursor: pointer;"
                            (click)="updatePatientSkinSubRefferal()" matTooltip="Skin Sub Refferal" matTooltipPosition="above">
                            add_alert
                        </span>
                        <mat-icon
                            *ngIf="childWound.state === 'active' && actualParentWound.state !== 'archived' && (currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER && currentUser.user_type != global.USER_TYPE.WOUND_NURSE) && !isTeleHealthReport"
                            matTooltipPosition="above" matTooltip="Archive Wound"
                            (click)="openConfirmationDialog(childWound, 'archived');" class="icon">delete</mat-icon>
                        <mat-icon
                            *ngIf="childWound.state === 'archived' && 
                                                            (actualParentWound.state !== 'archived' || actualParentWound._id === childWound._id)"
                            (click)="changeWoundState(childWound, 'active');" matTooltip="Restore Wound" matTooltipPosition="above"
                            class="icon" id="clickable">restore</mat-icon>
                        <span
                            *ngIf="childWound.state === 'active' && actualParentWound.state !== 'archived' && !hasChildWounds() && (!isPreview && childWound._most_recent_wound) && (currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER && currentUser.user_type != global.USER_TYPE.WOUND_NURSE) && !isTeleHealthReport && !childWound.is_clone"
                            style="float: right;" class="material-symbols-outlined icon-navy report-icon"
                            (click)="moveWoundToCensus(childWound)" matTooltip="Move Wound">
                            swap_horiz
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
