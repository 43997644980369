<div class="flex flex-col flex-grow">
  <div class="dark-blue-heading flex flex-wrap items-center">
    <div class="flex items-center gap-2">
      <i  (click)="upOneLevel()"
            *ngIf="historyStack.length > 0 && templatesLoaded"
            class="icon icon-back"></i>
          <h3>{{header_title}}
            <ng-container *ngFor="let folderItem of historyStack">
              > {{folderItem.title}}
            </ng-container>
          </h3> 
        </div>
      
        <div class="template-controls-bar">
            <button mat-mini-fab
             matTooltip="Reload"
            (click)="reload()"
            aria-label="icon-button"
             style="margin-right:10px;transition: none !important;box-shadow: none !important; background:none !important;">
                <mat-icon>refresh</mat-icon>
            </button>
    <button mat-mini-fab
             matTooltip="Delete"
             *ngIf="selectedItem && selectedItem['template_text']"
            (click)="deleteTemplate(selectedItem)"
            aria-label="icon-button"
             style="margin-right:10px;transition: none !important;box-shadow: none !important; background:none !important;">
                <mat-icon>delete</mat-icon>
            </button>
    
            <button mat-mini-fab
             matTooltip="View as List"
             *ngIf="view_type !== 'LIST'"
            (click)="toggleTemplatesView()"
            aria-label="icon-button"
             style="margin-right:10px;transition: none !important;box-shadow: none !important; background:none !important;">
                <mat-icon>view_list</mat-icon>
            </button>
            <button mat-mini-fab
             matTooltip="View as Grid"
             *ngIf="view_type !== 'GRID'"
            (click)="toggleTemplatesView()"
            aria-label="icon-button"
             style="margin-right:10px;transition: none !important;box-shadow: none !important; background:none !important;">
                <mat-icon>grid_on</mat-icon>
            </button>
        </div>
    </div>
    


    
    <div class="template-container-sidebar-wrapper dismis-template-selection flex flex-1 justify-evenly items-stretch">
        <div 
          class="template-container dismis-template-selection flex-grow"
          #templateContainer
          (click)="onTemplateContainerClick($event)">
          <ngx-file-drop
          *ngIf="!isSharedTemplateExplorer" 
          [showBrowseBtn]="false"
          multiple="true"
           (onFileDrop)="dropped($event)" >
            </ngx-file-drop>
            <ng-container *ngIf="templatesLoaded">
              <div
                *ngIf="!isEmpty() && view_type === 'GRID'" 
                class="dismis-template-selection flex flex-row flex-wrap"
                gutterSize="15px"
                id="templates-grid">
                  <ng-container *ngFor="let item of allTemplatesOrInsuranceCompanies" class="dismis-template-selection">
                    <div class="file-folder-item-wrapper dismis-template-selection flex items-center justify-start">
                      <div 
                        [id]="item._id"
                        class="flex-col"
                        >
                          <ng-container *ngIf="item.file_path">
                            <div class="flex flex-col justify-center items-center" 
                              (dblclick)="!navigating && onFileDoubleClick(item)"
                              (click)="onFileClick(item)"
                              (contextmenu)="onItemContextMenu($event, item)"
                              [ngClass]="{'file-folder-item':true, 'file-item':true, selected:item.selected}"
                              [ngStyle]="{'opacity': navigating ? '.6' : '1' }">
                              <span class="item-icon">
                                <img [src]="getItemIcon(item)" onerror="this.src='../../../assets/img/filetypes/unknown.svg'" />
                              </span>
                              <span class="item-title">
                                {{item.title.length > 20 ? ((item.title | slice:0:20) + '...') : item.title}}
                              </span>
                            </div>
                          </ng-container>
                  
                            <ng-container *ngIf="!item.file_path">
                              <div class="flex flex-col justify-center items-center" 
                                (dblclick)="!navigating && onFolderDoubleClick(item)"
                                (click)="onFolderClick(item, $event)"
                                (contextmenu)="onItemContextMenu($event, item)"
                                [ngClass]="{'file-folder-item':true, 'folder-item':true, selected: item.selected}" 
                                [ngStyle]="{'opacity': navigating ? '.6' : '1' }">
                                <span class="item-icon">
                                  <img src="../../../assets/img/folder-icon.png" />
                                </span>
                                <span class="item-title">
                                  {{item.title.length > 20 ? ((item.title | slice:0:20) + '...') : item.title}}
                                </span>
                              </div>
                            </ng-container>
                      </div>
                    </div>
                  </ng-container>
              </div>
          
              <table
              *ngIf="view_type === 'LIST'"
              mat-table 
              [dataSource]="dataSource"
              (matSortChange)="sortData($event)"
              matSort>
                  <ng-container matColumnDef="title">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
                      <td mat-cell *matCellDef="let element">
                        <div>
                          <div class="file-folder-item flex gap-2 items-start justify-start">
                                <span class="item-icon">
                                  <img *ngIf="element" [src]="getItemIcon(element)" onerror="this.src='../../../assets/img/filetypes/unknown.svg'" />
                                </span>
                                <div>{{element.title}}</div>
                          </div>
                        </div>
                      </td>
                  </ng-container>
                  <ng-container matColumnDef="creation_date">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created on </th>
                      <td mat-cell *matCellDef="let element"> 
                        {{element.creation_date | date: global.date_time_format}}
                      </td>
                  </ng-container>
                  <ng-container matColumnDef="updation_date">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Last updated on </th>
                      <td mat-cell *matCellDef="let element"> 
                        {{element.updation_date | date: global.date_time_format}}
                      </td>
                  </ng-container>
                  
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                  [ngClass]="{'file-folder-item':true, 'file-item':row.file_path, 'folder-item':!row.file_path, selected:row.selected}"
                  (dblclick)="row.file_path ? !navigating && onFileDoubleClick(row) : onFolderDoubleClick(row)"
                  (click)="row.file_path ? !navigating && onFileClick(row) : onFolderClick(row)"
                  (contextmenu)="onItemContextMenu($event, row)"
                  ></tr>
                  
              </table>
          
                <div class="empty-folder-view flex flex-col items-center justify-center" *ngIf="isEmpty()">
                  <img src="../../../assets/img/empty-fodler-icon.svg" />
                  <div>No items</div>
              </div>
            </ng-container> 
        </div>
        <div [ngClass]="{'template-sidebar-wrapper':true, 'template-sidebar-active': !!historyStackTop}">

            <div class="template-sidebar flex flex-col" *ngIf="selectedItem && showTemplateSidebar">
              <div class="flex flex-col items-center justify-center template-sidebar-details-item">
                <img [src]="getItemIcon(selectedItem)" style="height: 80px;" onerror="this.src='../../../assets/img/filetypes/unknown.svg'" />
                    <p style="color:#000; text-align: center; word-break: break-word;">{{selectedItem.title}}</p>
                </div>
                <mat-tab-group>
                    <mat-tab label="Details"> 
                      <div class="template-sidebar-details-content flex flex-col gap-5">
                        <div class="flex template-sidebar-details-item">
                              <div class="template-sidebar-item-label">Type</div>
                              <div class="template-sidebar-item-content">{{selectedItem.template_text ? 'Template' : 'Folder'}}</div>
                          </div>
                          <div class="flex template-sidebar-details-item">
                              <div class="template-sidebar-item-label">Owner</div>
                              <div class="template-sidebar-item-content">{{selectedItem.owner_id === currentUser._id ? 'Me' : selectedItem.owner_name}}</div>
                          </div>
                          <div class="flex template-sidebar-details-item">
                              <div class="template-sidebar-item-label">Created on</div>
                              <div class="template-sidebar-item-content">{{selectedItem.creation_date | date: global.date_time_format}}</div>
                          </div>
                          <div class="flex template-sidebar-details-item">
                              <div class="template-sidebar-item-label">Last modified on</div>
                              <div class="template-sidebar-item-content">{{selectedItem.updation_date | date: global.date_time_format}}</div>
                          </div>
                      </div>
                    </mat-tab>
                  </mat-tab-group>
            </div>
            <p style="color:#000; text-align: center; word-break: break-word;font-size: 12px;">Uploading Options</p>
            <div class="flex flex-col gap-5" style="padding:20px">
              <mat-form-field>
                <mat-label>Insurance Company</mat-label>
                <input matInput [value]="historyStack[0]?.abbreviation" disabled>
              </mat-form-field>

              <mat-form-field>
                <mat-label>Provider</mat-label>
                <mat-select [(ngModel)]="uploadingOptions.provider_id" name="provider" [disabled]="historyStack.length > 1">
                  <mat-option *ngFor="let provider of providers" [value]="provider._id">{{provider.first_name}}
                    {{provider.last_name}}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <input matInput [matDatepicker]="datePicker" (focus)="datePicker.open()" placeholder="Date"
                  [(ngModel)]="uploadingOptions.date" name="date" [disabled]="historyStack.length > 2">
                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
              </mat-form-field>

              <mat-form-field>
                <mat-label>Facility</mat-label>
                <mat-select [(ngModel)]="uploadingOptions.location" name="location" [disabled]="historyStack.length > 2">
                  <mat-option *ngFor="let location of locations" [value]="location">{{location}}</mat-option>
                </mat-select>
              </mat-form-field>

            </div>
        </div>
    </div>