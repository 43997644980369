<app-header></app-header>

<main *ngIf="componentLoad" [class.audio]="category=='dict'" [class.tran]="category=='tran'">
    <div class="container flex flex-col">
        <div class="transcription-audio flex flex-col" *ngIf="category == 'dict'">

            <div class="flex items-center" *ngIf="!currentaudio">
                {{loading ? 'Loading' : 'No Audio Exists'}}
            </div>
            <div  *ngIf="currentaudio" class="flex gap-2">
                <div class="flex justify-start items-center w-1/4">

                    <div class="flex-grow-0">
                        <span class="dp"></span>
                    </div>

                    <div class="flex justify-start items-center">
                        <div class="flex flex-col">
                            <div class="title">{{currentaudio.audio_title}}</div>
                            <div>
                                <span class="date">{{currentaudio.date_time | date: global.date_time_format}}</span>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="flex justify-center items-center">
                    <div class="transcription-player" style="flex:1">
                        <audio id="transcription" [src]="currentaudio.aws_path" controls controlsList="nodownload"
                               style="width:100%"></audio>
                    </div>
                </div>


                <div class="flex items-center justify-end w-1/4">
                    <div class="flex flex-col gap-2">
                        <div class="duration flex justify-end items-center">

                            <div class="flex flex-col" style="margin-right: 30px">
                                <label style="margin-top: -19px" for="back-slider">Back</label>
                                <mat-slider id="back-slider"
                                            min="1"
                                            max="5"
                                            step="1"
                                            thumbLabel="true"
                                            [(ngModel)]="backSlider"
                                            style="height: 14px; top: -13px; right: 5px">
                                </mat-slider>
                            </div>

                            <div class="flex flex-col">
                                <label style="margin-top: -19px" for="speed-slider">Speed</label>
                                <mat-slider id="speed-slider"
                                            min="0.5"
                                            max="2.0"
                                            step="0.5"
                                            thumbLabel="true"
                                            [(ngModel)]="speedSlider"
                                            style="height: 14px; top: -13px; right: 5px"></mat-slider>
                            </div>
                            {{currentaudio.audio_length}}
                        </div>
                        <div class="status-col">

                            <button
                                    *ngIf="this.currentUser.user_type == global.USER_TYPE.DOCTOR || this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE"
                                    class="theme-btn status"
                                    style="top:0 !important; right: 7px !important;"
                                    class="dn-button-primary"
                                    [class.completed]="currentaudio.status==='COMPLETED'"
                                    mat-raised-button>{{currentaudio.status}}</button>

                            <app-switch-button
                                    *ngIf="currentaudio"
                                    labelOff="In Progress"
                                    labelOn="Completed"
                                    [thirdForm]="false"
                                    (handleOff)="changeCurrentAudioStatus(19)"
                                    (handleOn)="changeCurrentAudioStatus(20)"

                                    [checked]="currentaudio.status==='COMPLETED'"
                            >
                            </app-switch-button>

                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="editor-sidebar-wrapper flex flex-1 items-start">
            <div class="flex flex-col">

                <div class="editor-top flex flex-col">
                    <div class="flex gap-2">
                        <div class="editor-top-left">
                            <div class="flex flex-row flex-wrap gap-2 items-baseline">
                                <ng-container class="doctor-facility-select" *ngIf="category === 'tran' && isNew">

                                    <mat-form-field *ngIf="currentUser.user_type == 2 || currentUser.user_type == 'medical_assistant'">
                                        <mat-label>Provider</mat-label>
                                        <mat-select [(ngModel)]="selectedDoctorId" aria-required="true"
                                                    class="uppercase">
                                            <mat-option *ngFor="let doctor of doctors" value="{{doctor._id}}"
                                                        (click)="getFacilities(doctor._id)">
                                                <ng-container *ngIf="doctor">
                                                    {{doctor.first_name}} {{doctor.last_name}}, {{doctor.title}}
                                                </ng-container>
                                            </mat-option>

                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field class="flex-grow" *ngIf="currentUser.user_type == 1">
                                        <input class="uppercase" matInput placeholder="Provider" [readonly]="true"
                                               [value]="currentUser.first_name + ' ' + currentUser.last_name + ', ' + currentUser.title"/>
                                    </mat-form-field>

                                    <mat-form-field>
                                        <mat-label>Facility</mat-label>
                                        <mat-select [(ngModel)]="selectedFacilityId"
                                                    (ngModelChange)="handleFacilityChange()" aria-required="true"
                                                    class="uppercase">
                                            <mat-option *ngIf="!selectedDoctorId">
                                                <mat-error>Select Provider First</mat-error>
                                            </mat-option>
                                            <mat-option value="{{facility._id}}" *ngFor="let facility of facilities">
                                                <app-facility-title [facility]="facility"></app-facility-title>
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field>
                                        <mat-label>Patient</mat-label>
                                        <input matInput type="text" [(ngModel)]="selectedPatient" [formControl]="patientControl"
                                            [matAutocomplete]="patientAutoComplete">
                                        <mat-autocomplete #patientAutoComplete="matAutocomplete" [displayWith]="displayPatientFn">
                                            <mat-option value="" (click)="showPatientAddDialog()">
                                                <strong>+ Add New Patient</strong>
                                            </mat-option>
                                            <mat-option *ngFor="let patient of filteredPatients | async"
                                                        [value]="patient">
                                                <app-patient-name [patient]="patient"></app-patient-name>
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>


                                    <mat-form-field>
                                        <input matInput [matDatepicker]="picker" [max]="currentDate"
                                               (focus)="picker.open()"
                                               placeholder="Date of Service" [(ngModel)]="date_of_service" required>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                        <mat-error *ngIf="!date_of_service">Please enter date of service</mat-error>
                                    </mat-form-field>


                                </ng-container>

                                <ng-container *ngIf="!isNew || category === 'dict'">

                                    <mat-form-field class="flex-grow" *ngIf="currentDoctor">
                                        <input class="uppercase" matInput placeholder="Provider" readonly
                                               [value]="currentDoctor.first_name + ' ' + currentDoctor.last_name + ', ' + currentDoctor.title"/>
                                    </mat-form-field>

                                    <mat-form-field class="flex-grow">
                                        <input class="uppercase" matInput placeholder="Facility" readonly
                                               [value]="showFacility()"/>
                                               <img src="../../../assets/icons/pcc-icon.svg" style="width: 15px;" matPrefix *ngIf="currentaudio?.facility_id_ref?.pcc_facId || selectedFile?.facility_id_ref?.pcc_facId"/>
                                    </mat-form-field>
                                    <mat-form-field class="flex-grow" *ngIf="selectedPatient && !isNew">
                                        <input class="uppercase"
                                               matInput placeholder="Patient" readonly
                                               [value]="commonService.getPatientFullNameFormat2(selectedPatient)"/>
                                               <img src="../../../assets/icons/pcc-icon.svg" style="width: 15px;" matPrefix *ngIf="selectedPatient.pcc_patientId"/>
                                    </mat-form-field>
                                    <ng-container
                                    *ngIf="isNew">

                                        <mat-form-field>
                                            <mat-label>Patient</mat-label>
                                            <input matInput *ngIf="patient_audio.is_patient" type="text" [(ngModel)]="patient_audio.name" value="{{patient_audio.name}}" disabled>
                                            <div *ngIf="!patient_audio.is_patient">
                                                <input matInput type="text" [(ngModel)]="selectedPatient" [formControl]="patientControl"
                                                       [matAutocomplete]="patientAutoComplete">
                                                <mat-autocomplete #patientAutoComplete="matAutocomplete" [displayWith]="displayPatientFn">
                                                    <mat-option value="" (click)="showPatientAddDialog()">
                                                        <strong>+ Add New Patient</strong>
                                                    </mat-option>
                                                    <mat-option *ngFor="let patient of filteredPatients | async"
                                                                [value]="patient">
                                                        <app-patient-name [patient]="patient"></app-patient-name>
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </div>
                                            <input matInput style="display: none">
                                        </mat-form-field>
                                    </ng-container>

                                    <mat-form-field class="flex-grow">
                                        <input class="uppercase" class="uppercase" matInput placeholder="DOS" readonly
                                               [value]="(currentaudio ? currentaudio.date_time : (!isNew && !currentaudio && selectedFile ? selectedFile.date_of_service : '')) | date: global.date_format"/>
                                               
                                    </mat-form-field>

                                </ng-container>

                            </div>
                        </div>
                        <div class="editor-top-right flex flex-row flex-wrap justify-end items-baseline gap-2" *ngIf="!loading && !hide" >
                             <ng-container *ngIf="nextPrev === 'tran' && !isNew && (transcriptionFiles && transcriptionFiles.length > 1)">
                                <button class="dn-button-primary" mat-raised-button [disabled]="isPrevDisabled"
                                        (click)="navigateEditTranscription(-1)" *ngIf="!isPrevDisabled"><i
                                        class="material-icons">arrow_back_ios</i>Prev
                                </button>
                                <button class="dn-button-primary" mat-raised-button [disabled]="isNextDisabled"
                                        (click)="navigateEditTranscription(1)" *ngIf="!isNextDisabled">Next<i
                                        class="material-icons">arrow_forward_ios</i></button>
                            </ng-container>

                            <ng-container *ngIf="nextPrev === 'dict' && !isNew &&  (dictationFiles && dictationFiles.length > 1)" >
                                <button class="dn-button-primary" mat-raised-button [disabled]="isPrevDisabled"
                                        (click)="navigateEditDictation(-1)"  ><i
                                        class="material-icons">arrow_back_ios</i>Prev
                                </button>
                                <button class="dn-button-primary" mat-raised-button [disabled]="isNextDisabled"
                                        (click)="navigateEditDictation(1)"  >Next<i
                                        class="material-icons">arrow_forward_ios</i></button>
                            </ng-container>
                            <button class="dn-button-primary"
                                    *ngIf="isTouched && !isCreatingAddendum"
                                    mat-raised-button
                                    (click)="saveTranscription()"> Save
                            </button>
                            <button class="dn-button-primary"
                                    *ngIf="(isNew && isTouched && currentUser.user_type == '2') || (isNew && isTouched && currentUser.user_type == 'medical_assistant')"
                                    mat-raised-button
                                    (click)="saveAndSubmit()"> Save & Submit for review
                            </button>
                            <button class="dn-button-primary" *ngIf="isTouched && isNew" mat-raised-button
                                    (click)="isAddMore=1; saveTranscription()">Save And Create New
                            </button>
                            <button *ngIf="isNew" (click)="goBack()"
                                    class="dn-button-secondary"
                                    mat-raised-button>Cancel</button>


                            <button class="dn-button-primary" mat-raised-button
                                    *ngIf="permission.is_qa === true
                                    && selectedFile.is_locked === true
                                    && selectedFile.is_published == 'false'"
                                    (click)="toggleNoteLock(false)"
                                    color="primary">
                                <img src="../../../assets/revise.png"
                                     style="margin-right: 5px; margin-left: 0; margin-bottom: 4px"
                                     height="20" width="20"/>
                                Revert
                            </button>
                            <button class="dn-button-primary" mat-raised-button
                                    (click)="publishTranscription('true')"
                                    [disabled]="isNew || (!isNew && selectedFile && selectedFile.is_published == 'true')"
                                    *ngIf="!isNew && selectedFile
                      && selectedFile.is_published != 'true'
                      && permission.is_qa === true
                      && selectedFile.is_locked === true
                      && currentUser.user_type == 2"
                                    style="margin-right: unset !important;"
                            >
                                Publish
                            </button>
                            <div *ngIf="selectedFile">
                                <button class="dn-button-primary" mat-raised-button
                                        *ngIf="isSubmit()"
                                        (click)="toggleNoteLock(true)"
                                >
                                    Submit for Review
                                </button>
                            </div>
                            <div *ngIf="selectedFile">
                                <button class="dn-button-primary" mat-raised-button
                                        *ngIf="isLocked()"
                                        color="primary">
                                    <img src="../../../assets/locked.png"
                                         style="margin-right: 5px; margin-left: 0; margin-bottom: 4px"
                                         height="20" width="20"/>
                                    Locked
                                </button>
                            </div>
                            <button class="dn-button-secondaryz" mat-raised-button
                                    (click)="reviseNote('false')"
                                    *ngIf="!isNew && currentUser.user_type == 1
                                    && (selectedFile
                                     && selectedFile.is_signed != 'true'
                                     && selectedFile.is_published == 'true')"
                            >
                                Revise Note
                            </button>
                            <ng-container
                                        *ngIf="!isNew && (currentUser.user_type == global.USER_TYPE.DOCTOR || currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE) && 
                                        selectedFile && selectedFile.is_published === 'true' && 
                                        selectedFile.is_completed !== 'true' && !hide && !pccInProgress() && getSelectedPatient()">
                                    <ng-container 
                                    *ngIf="isLoggedInPCC()">
                                    <app-switch-button
                                            *ngIf="selectedPatient.pcc_patientId"
                                            [manualChange]="true"
                                            [dark]="true"
                                            labelOff="UNSIGN"
                                            labelOn="SIGN AND UPLOAD"
    
                                            (handleOff)="signTranscription('false')"
                                            (handleOn)="signAndUploadTranscription()"
    
                                            [checked]="selectedFile.is_signed === 'true'"
                                    >
                                    </app-switch-button>

                                    <app-switch-button
                                            *ngIf="!selectedPatient.pcc_patientId"
                                            [dark]="true"
                                            labelOff="UNSIGN"
                                            labelOn="SIGN"
     
                                            (handleOff)="signTranscription('false')"
                                            (handleOn)="signTranscription('true')"
     
                                            [checked]="selectedFile.is_signed === 'true'"
                                    >
                                    </app-switch-button>
                                    </ng-container>
                               <app-switch-button
                                       *ngIf="!isLoggedInPCC()"
                                       [dark]="true"
                                       labelOff="UNSIGN"
                                       labelOn="SIGN"

                                       (handleOff)="signTranscription('false')"
                                       (handleOn)="signTranscription('true')"

                                       [checked]="selectedFile.is_signed === 'true'"
                               >
                               </app-switch-button>
                            </ng-container>
                            <ng-container *ngIf="!isNew && selectedFile && selectedFile.is_signed === 'true'">
                                <mat-checkbox *ngIf="selectedFile.is_completed !== 'true'"
                                              (click)="completeTranscription('true')">
                                    UPLOAD
                                </mat-checkbox>
                                <ng-container
                                *ngIf="selectedFile.is_completed == 'true' && (currentUser.user_type == global.USER_TYPE.DOCTOR || currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE) && !hide">
                                    <app-switch-button
                                        *ngIf="!isPCCNote()"
                                            [dark]="true"
                                            labelOff="UNSIGN"
                                            labelOn="UPLOAD"

                                            (handleOff)="signTranscription('false')"
                                            (handleOn)="completeTranscription('true')"

                                            [checked]="selectedFile.is_completed === 'true'"
                                    >
                                    </app-switch-button>
                                   <ng-container *ngIf="isPCCNote()">
                                       <mat-slide-toggle [checked]="true" (click)="$event.preventDefault()">Note Uploaded</mat-slide-toggle>
                                       <button mat-raised-button class="dn-button-primary" (click)="createAddendum()" *ngIf="!isCreatingAddendum && selectedFile?.addendum_counts?.saved === 0">Create Addendum</button>
                                       <ng-container *ngIf="isCreatingAddendum">
                                           <ng-container *ngIf="!selectedAddendum">
                                           <button mat-raised-button class="dn-button-primary" (click)="saveAddendum()">Save Addendum</button>
                                           </ng-container>
                                           <ng-container *ngIf="selectedAddendum">
                                               <button mat-raised-button class="dn-button-primary" (click)="saveAddendum()" *ngIf="touchedSelectedAddendum">Save Addendum</button>
                                               <button mat-raised-button class="dn-button-primary" (click)="uploadAddendum()" *ngIf="!touchedSelectedAddendum">Upload Addendum</button>
                                           </ng-container>

                                           <button mat-raised-button class="dn-button-primary" (click)="discardAddendum()">Discard</button>
                                       </ng-container>
                                   </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>

                    <div class="flex gap-2 items-center">
                        <mat-form-field class="flex-grow">
                            <input class="uppercase" matInput
                                   placeholder="Note Title"
                                   [(ngModel)]="title"
                                   [readonly]="!config.editable"
                                   required/>
                        </mat-form-field>

                        <button class="dn-button-primary" mat-raised-button (click)="signAndUploadTranscription()"
                                *ngIf="isSignAndUploadVisibleForNewNote()" style="position: relative; top:-10px;">
                            Sign and Upload
                        </button>
                        <button class="dn-button-primary" mat-raised-button (click)="showChooseTemplateDialog()"
                                *ngIf="isChooseTemplateButtonVisible()" style="position: relative; top:-10px;">Select
                            Template
                        </button>
                        <button class="dn-button-primary"
                                (click)="showPhraseDialog()"
                                style="position: relative; top:-10px;"
                                mat-raised-button>
                            Dictionary
                        </button>
                    </div>
                    <div class="flex" style="margin-bottom: 20px;">
                        <div class="bordered_text" *ngIf="isNew && selectedPatient?.pcc_patientId || isCreatingAddendum">
                            You must use 
                            <span [class.valid]="existInNoteText.facility" [class.invalid]="!existInNoteText.facility"><strong><u>Facility Name</u></strong></span>, 
                            <span [class.valid]="existInNoteText.provider" [class.invalid]="!existInNoteText.provider"><strong><u>Provider Name</u></strong></span>, 
                            <span [class.valid]="existInNoteText.patient" [class.invalid]="!existInNoteText.patient"><strong><u>Patient Name</u></strong></span>, & 
                            <span [class.valid]="existInNoteText.date_of_service" [class.invalid]="!existInNoteText.date_of_service"><strong><u>Date of Service</u></strong></span>
                             in the note text.
                        </div> 
                        <div class="bordered_text" style="margin-left: auto" *ngIf="!isNew && selectedFile?.pcc_uploaded_date">
                            This note was uploaded to <img src="../../../assets/icons/pcc-icon.svg" style="width: 15px;vertical-align: middle;bottom: 2px;position: relative;" /> PCC on {{selectedFile.pcc_uploaded_date | date: global.date_time_format}}
                        </div> 
                    </div>
                </div>
                <div class="transcription-editor" class="flex" aria-readonly="true">
                    <ckeditor [(ngModel)]="htmlContent" class="h100"
                              editorUrl="../../../assets/ckeditor/ckeditor.js"
                              [readOnly]="!config.editable"
                              [config]="cke4Config"
                              type="divarea"
                              #angularEditor></ckeditor>
                </div>
            </div>
            <div class="sidebar-wrapper flex flex-col">
                <button class="sidebar-toggle-btn" (click)="sidebarVisible=!sidebarVisible">
                  <mat-icon *ngIf="sidebarVisible">keyboard_arrow_right</mat-icon>
                  <mat-icon *ngIf="!sidebarVisible">keyboard_arrow_left</mat-icon>
                </button>
                <app-note-comments class="sidebar-comments flex"
                                   *ngIf="sidebarVisible && selectedFile"
                                   [audioId]="audioId"
                                   [noteId]="selectedFile._id"
                                   ></app-note-comments>
            </div>
        </div>
        <ngx-ui-loader [loaderId]="transcriptionCreateLoader"></ngx-ui-loader>

    </div>

</main>