import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { lastValueFrom } from 'rxjs';
import { CptIcdAssociationService } from 'src/app/includes/cpt-icd-association/cpt-icd-association.service';
import {WoundEtiologyGroup, WoundEtiologyGroupDetails} from '../wound-groups.component'
import {WoundTag} from '../../wound-tags/wound-tags.component'
import { WoundService } from 'src/app/services/wound.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmationComponent } from '../../../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import moment from 'moment';

@Component({
  selector: 'app-wound-groups-icdtags-mapping',
  templateUrl: './wound-groups-icdtags-mapping.component.html',
  styleUrls: ['./wound-groups-icdtags-mapping.component.css']
})
export class WoundGroupsICDTagsMappingComponent implements OnInit, OnChanges {
  @Input() company_id: string;
  @Input() woundEtiologyGroupDetails: WoundEtiologyGroupDetails[] = [];
  @Input() activeWoundEtiologyGroup: WoundEtiologyGroup;
  dataSource = new MatTableDataSource();
  selectedCode = new FormControl();
  displayedColumns: string[] = ['icd-code', 'tags'];
  loaderId = "app-cpt-icd-association";
  icdCodeList: any = [];
  icdCodeNewList: any = [];
  cptCodes: any;
  searchText: any;
  diagnoseFilter = '';
  filter: any;
  tags: WoundTag[] = [];

  selectedDetailICD:any = {};

  showNewhandleAddNewWoundEtiologyGroupDetailsInputValue = false;
  newSelectedDetailICDInputValue:any;
  newTagsInputValue:WoundTag[] = [];
  


  tagsDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'label',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 10,
    allowSearchFilter: true,
    enableCheckAll: false,
  };

  newWoundEtiologyGroupDetailsDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'label',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 10,
    allowSearchFilter: true,
    enableCheckAll: false,
  };
  currentUser: any;
  disableEditing: boolean = false;

  constructor(
    private dialog: MatDialog,
    private _cpticd: CptIcdAssociationService,
    private loader: NgxUiLoaderService,
    private _toaster: ToastrService,
    private _authService: AuthService,
    private _wound: WoundService,
  ) {
    this.currentUser = this._authService.currentUser;
    if (this.currentUser.admin_type == 'system_admin') {
      this.displayedColumns.push('operation');
    } else {
      this.disableEditing = true;
    }
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId);
    await Promise.all([this.initWoundTags(), this.getAllICDs()]);
    this.loader.stopLoader(this.loaderId);

    this.initEtiologyGroupDetails();
  }
  async initEtiologyGroupDetails() {
    this.dataSource.data = this.woundEtiologyGroupDetails;
    // this.initCPTCodes();

  
    this.updateSelectedICDs();
    
  }
    ngOnChanges(changes: SimpleChanges): void {
      if(!changes.woundEtiologyGroupDetails?.firstChange) {
        this.initEtiologyGroupDetails();
      }
  }
  async initWoundTags() {
    let {status, data} = await lastValueFrom(this._wound.getWoundTags(this.company_id)) as any;
    if (status == 200) {
      this.tags = data;
      
         
    }
  }
  updateSelectedICDs() {
    for (const detail of this.woundEtiologyGroupDetails) {
      
      if(!detail.icd_id) continue;
      const icd = this.icdCodeList.find(x => x._id === detail.icd_id._id);
      if(!icd) {
        this.icdCodeList.push(detail.icd_id);
        this.selectedDetailICD[detail._id] = detail.icd_id;
      } else {
        this.selectedDetailICD[detail._id] = icd;
      }
    }
  }
  getSelectedICD(icd) {
    this.icdCodeList.find(x => x._id === icd._id);
  }
  applyFilter(filterValue: string) {
    this.filter = filterValue.trim().toLowerCase();
    if (this.filter) {
      let filterVal = [];
      this.woundEtiologyGroupDetails.forEach(woundEtiologyGroupDetail => {
        // if (woundEtiologyGroupDetail.icd_id.includes(this.filter)) {
        //   filterVal.push(code)
        //   this.dataSource.data = filterVal;
        // }
      });
      console.log(filterVal);
    } else {
      this.dataSource.data = this.woundEtiologyGroupDetails;
    }
  }
  initCPTCodes() {
    this._cpticd.getAllCPTs().toPromise().then((res) => {
      if (res['status'] == 200) {
        this.cptCodes = res['data'];
        this.dataSource.data = res['data'];
        this.loader.stopLoader(this.loaderId);
      } else {
        this._toaster.error("Somethimg Went Wrong", "Failed");
      }
    });
  }
  async getAllICDs(search = "") {
    const {status, data} =  await lastValueFrom(this._cpticd.getICDs({ search: search })) as any;
    if (status == 200) {
      const searchedICDS = data.array.map(icd => {
        if (!icd.end_date)
          icd.end_date = "";
        const [month, day, year] = icd.end_date.split("/");
        if (icd.end_date != "" && moment(`${year}-${month}-${day}`).isBefore(moment()))
          icd.status = 'Expired';
        else
          icd.status = 'Active';
        return icd;
      });
      this.icdCodeList = searchedICDS.filter(icd => icd.status == 'Active');;
    } else {
      this._toaster.error("Somethimg Went Wrong", "Failed");
    }
  }
  
  async getAllNewICDs(search = "") {
    const {status, data} =  await lastValueFrom(this._cpticd.getICDs({ search: search })) as any;
    if (status == 200) {
      const searchedICDS = data.array.map(icd => {
        if (!icd.end_date)
          icd.end_date = "";
        const [month, day, year] = icd.end_date.split("/");
        if (icd.end_date != "" && moment(`${year}-${month}-${day}`).isBefore(moment()))
          icd.status = 'Expired';
        else
          icd.status = 'Active';
        return icd;
      });
      this.icdCodeNewList = searchedICDS.filter(icd => icd.status == 'Active');;
    } else {
      this._toaster.error("Somethimg Went Wrong", "Failed");
    }
  }
  
  addRelation(icdcode, element, index) {
    const {_id: icd_id} = icdcode;
    
    }
    handleICDChange($event, element) {
      this.selectedDetailICD[element._id] = $event.value;
      element.icd_code = $event.value;
      console.log('handleICDChange');
      this.updateWoundEtiologyGroupDetails(
        element
      )
    }
    handleNewICDChange($event, element?) {
      if(element) {
        this.selectedDetailICD[element._id] = $event.value;
        element.icd_code = $event.value;
        console.log('handleICDChange');
        this.updateWoundEtiologyGroupDetails(
          element
        )
      } else {
        this.newSelectedDetailICDInputValue = $event.value  
      }
    }
  async searchDiagnose(value, selectedIcd) {
    await this.getAllICDs(value);
    
      const exists = this.icdCodeList.find(x => x._id === selectedIcd._id);
      if(!exists) {
        this.icdCodeList.push(selectedIcd);
      }
  }
  async searchNewDiagnose(value) {
    await this.getAllNewICDs(value);
  }
  removeRelation(cpt_id, icd_id, index, index2) {
    this.loader.startLoader(this.loaderId);
    if (this.filter) {
      index = this.cptCodes.findIndex(x => x._id === cpt_id);
    }
    const infoToastr = this._toaster.info('Removing Relation...', 'Please wait', { disableTimeOut: true });
    this._cpticd.removeRelation(cpt_id, icd_id).toPromise().then((res: any) => {
      if (res.status == 200) {
        this.cptCodes[index].cptrelation[0].icd_code_ids.splice(index2, 1);
        this.loader.stopLoader(this.loaderId);
        this._toaster.clear(infoToastr.toastId);
        this._toaster.success("Relation Removed Successfully", "Success");
      } else {
        this.loader.stopLoader(this.loaderId);
        this._toaster.clear(infoToastr.toastId);
        this._toaster.error("Somethimg Went Wrong", "Failed");
      }
    });
  }
  async addWoundEtiologyGroupDetails(woundEtiologyGroupId, icd_id) {
    
    const {status, data} =  await lastValueFrom(this._wound.addWoundEtiologyGroupDetails(
      woundEtiologyGroupId,
      icd_id,
    )) as any;
    console.log({
      status, data
    })
  }
  async updateWoundEtiologyGroupDetails(woundEtiologyGroupDetails) {
    let {_id : woundEtiologyGroupDetailsId, icd_id, tags} = woundEtiologyGroupDetails;
    
    tags = tags.map(tag => tag._id);
    const {status, data} =  await lastValueFrom(this._wound.updateWoundEtiologyGroupDetails(
      woundEtiologyGroupDetailsId,
      icd_id._id,
      tags
    )) as any;
    console.log({
      status, data
    })
  }

  async deleteWoundEtiologyGroupDetails(woundEtiologyGroupDetails, i) {
    const {status, data} =  await lastValueFrom(this._wound.updateWoundEtiologyGroupDetails(
      woundEtiologyGroupDetails._id,
      undefined,
      true
    )) as any;
    console.log({
      status, data
    });
    if(status == 200) {
      this.woundEtiologyGroupDetails.splice(i, 1); 
    }
  }
  onTagSelect(tag, element) {
    // element.tags.push($event);
    console.log('onTagSelect', {tag, element});
    this.updateWoundEtiologyGroupDetails(element)
   
    
  }
  onTagDeSelect(tag, element) {
    console.log('onTagDeSelect', {tag, element})
    this.updateWoundEtiologyGroupDetails(element)
  }

  handleAddNewWoundEtiologyGroupDetails() {
    // this.newSelectedDetailICDInputValue
    // this.newTagsInputValue
    this.showNewhandleAddNewWoundEtiologyGroupDetailsInputValue = true;
    this.getAllNewICDs();
    
  }
  async addNewWoundEtiologyGroupDetails() {

    if(!this.activeWoundEtiologyGroup || !this.newSelectedDetailICDInputValue || this.newTagsInputValue.length == 0) {
      this._toaster.warning('Please fill all the fields', 'Warning');
      return;
    }
    const newWoundEtiologyGroupDetails:WoundEtiologyGroupDetails = {
      group_id: this.activeWoundEtiologyGroup._id,
      icd_id: this.newSelectedDetailICDInputValue,
      tags: this.newTagsInputValue,
    }
    this.showNewhandleAddNewWoundEtiologyGroupDetailsInputValue = false;
    const {data, status} = await lastValueFrom(this._wound.addWoundEtiologyGroupDetails(newWoundEtiologyGroupDetails.group_id, newWoundEtiologyGroupDetails.icd_id._id, newWoundEtiologyGroupDetails.tags.map(tag => tag._id))) as any;
    console.log(data);
    
    if(status == 200) {
      this.woundEtiologyGroupDetails.push(data);
      this.dataSource.data = this.woundEtiologyGroupDetails;
      this.updateSelectedICDs();
      this.newTagsInputValue = [];
      this.newSelectedDetailICDInputValue = undefined;
    }
  }

  async deleteNewWoundEtiologyGroupDetails(woundEtiologyGroupDetails, i) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: `Are you sure you want to delete this icd tag?`
      }
    }); 
    dialogRef.afterClosed().subscribe(async result => {
      if (result === "true") {
        const { data, status } = await lastValueFrom(this._wound.updateWoundEtiologyGroupDetails(woundEtiologyGroupDetails, undefined, undefined, true)) as any;
        if (status == 200) {
          this.woundEtiologyGroupDetails.splice(i, 1);
          this.dataSource.data = this.woundEtiologyGroupDetails;
          this._toaster.success(`ICD Tag removed Successfully`, 'Success');

        }
      }
    });
  }
}

