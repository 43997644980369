<main>
    <div class="privacy-policy">
        <div class="privacy-policy-header dark-blue-heading">
          <h2>Privacy Policy</h2>
        </div>
        <div class="content">
            <p>Welcome to Doctornow, Inc (“DN”). DN provides voice transcription services via a mobile application, in addition to document management service via its website. The app provides an interface for the user to register an account, and create a voice dictation. DN is strongly committed to protecting the privacy of your information and providing a safe and secure environment for all its users.</p>
            <p>To be clear about the terminology used in this Policy Statement, the term “Personal Information” means information about you that is personally identifiable, like your name, address, e-mail address, or phone number, as well as other non-public information that is associated with this personal information. The term “Anonymous Information” means information that is not associated with or linked to your Personal Information. Anonymous Information does not enable identification of or contact with individual persons.</p>
            <p>The app uses two-factor authentication by default, which means is that you would receive a secondary code on either on your email address and/or via a text message on your mobile phone number. For added security, you can only be logged into either your web account or your mobile account but not both at the same time.</p>

            <h3>What information do we collect?</h3>
            <p>We collect information from you during this signup process. While registering on our website, as appropriate, you may be asked to enter your: name, e-mail address, phone number, and contact information. This information is used to create a unique account for you that you would use to login to the app. </p>
           
            <h3>Profile Information:</h3>
            <p>After registration, you may provide additional information to your profile. Providing additional information beyond what is required at registration is entirely optional, but enables you to better understand our interface and make orders easily.</p>
            <p>DN will keep the data that you provide until you delete it from your account. The user has the ability to edit/modify/delete all the data that is there in our system. You may request a copy of all your personal data that you have provided us to build your profile.</p>
            <p>We only process data that is lawfully provided consent by you. You may at any time request to withdraw your consent and we will delete all data associated with your account. If you have any questions, you may contact us at info&#64;doctornow.io.</p>
            <h3>How do you use my personal information?</h3>
            <p>Your personal information is entered into our system when you register. This information is only used to identify your unique account and associate any documents that are either created directly by you and created on your behalf automatically or by admin staff who is authorized to have access to your information. No one outside the authorized staff can see or have access to your personal information, such as your name, address, phone number, and any other document. Your personal information is never shared with a third party, and it is against our privacy policy to do so.</p>
            <h3>What do we use your information for?</h3>
            <p>Any of the information we collect from you may be used in one of the following ways:</p>
            <ul>
                <li>To personalize your experience </li>
                <li>To improve customer service. (your information helps us to more effectively respond to your requests and support needs)</li>
                <li>Your information is private with us, and will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent.</li>
                <li>To send important emails.</li>
                <li>The email address you provide for order processing may be used to send you information and notifications about the status of the work performed by the system or other staff. </li>        
            </ul>
            
            <h3>How do we protect your information?</h3>
            <p>We implement a variety of security measures to safeguard your personal information when you place an order. Our server is SSL-encrypted. SSL means "Secure Socket Layer." All data stored on our system is also SSL-encrypted at-rest or within the database.</p>
            <p>Should you find the need to ensure that privacy above and beyond this policy, please contact info&#64;doctornow.io or call the number at the bottom of this page. We offer the use of a secure server. All supplied sensitive/credit information is also transmitted via SSL technology and then encrypted into our Payment gateway database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential. After a transaction, your private information (credit cards, social security numbers, financials, etc.) will be stored upon your consent for further transactions.</p>

            <h3>How do you use my audio materials?</h3>
            <p>Your audio materials are used for transcription purposes only. The audios are used solely by the transcriptionists who type up the audio, after which they are deleted from our database. All typists are required to sign a legally binding Confidentiality/ND Agreement with us upon hire. You can be guaranteed that all audio materials will not be shared with any third party. It is against our privacy policy to do so.</p>

            <h3>How can I be certain that my audio materials are secure on your server?</h3>
            <p>Our server is SSL-encrypted. You will note when you receive your automated emails concerning the completion of your files that the transcripts are encrypted. This is automatically done by our server for your protection. Should you find the need to ensure that privacy above and beyond this policy, please contact info&#64;gmrtranscription.com or call the number at the bottom of this page.</p>

            <h3>Can I view and edit my personal information?</h3>
            <p>Yes. You can log into your account, view any files, and edit your personal information at any time. The account belongs to YOU, and you have every right to edit your personal information. You may also change your email address or password at any time.</p>

            <h3>Do we use cookies?</h3>
            <p>Yes (Cookies are small files that a site or its service provider transfers to your computers hard drive through your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain information. We use cookies to keep track of the advertisements.</p>

            <h3>Do we disclose any information to outside parties?</h3>
            <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</p>

            <h3>Security Assurance</h3>
            <p>We take every reasonable step for safeguarding the Personal Data provided through the website from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, considering that no Internet, email or another electronic transmission can be fully secure or error-free at all times, you need to take precautionary steps while deciding what information you send to us in this way.</p>
            <p>We encrypt every sensitive information like credit card details while collecting it so that it gets transmitted to us in a secure way. You can verify this from your end by looking for a lock icon in the address bar and looking for "https" at the beginning of the address of the web page. Only those employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information. We keep the computers/servers which are used for storing personally identifiable information stored in a secure environment.</p>

            <h3>Your Consent</h3>
            <p>By using our site, you consent to our privacy policy.</p>

            <h3>Changes to our Privacy Policy</h3>
            <p>DN may update this Privacy Policy from time to time - we encourage you to periodically review this page for the latest information on our privacy practices. We reserve the right to modify this policy. If we decide to change our privacy policy, we will post those changes on this page.</p>

            <h3>Opt-Out / Corrections</h3>
            <p>Should you ever receive anything other than an automated email regarding your transcripts or an email from management concerning any questions we may have about your work, please contact info&#64;doctornow.io and ask to be removed from our mailing list. Should you receive any type of solicitation or non-work-related emails after asking us to remove you from our mailing list, again, please contact us at info&#64;doctornow.io. We will take immediate action.</p>

            <p>Upon your request, we will</p>
            <ul>
                <li>correct or update your personal information and/or</li>
                <li>stop sending emails to your email address.</li>
            </ul>

            <h3>Legal Disclaimer:</h3>
            <p>It is possible that we may need to disclose personal information when required by law. We will disclose such information wherein we have a good-faith belief that it is necessary to comply with a court order, ongoing judicial proceeding, or other legal process served on our company or to exercise our legal rights or defend against legal claims.</p>

            <h3>Contacting Us</h3>
            <p>If you have any questions or suggestions regarding this Policy, please contact us at </p>
            <p>Direct: 425.502.8065</p>
            <p>Email: info&#64;doctornow.io</p>
                    
        </div>
      </div>
      <div class="footer">
        &copy; 2019 Doctornow
      </div>
</main>