import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { API_URL } from "../../../environments/api-url";
import * as global from '../../includes/global';

@Injectable({
  providedIn: 'root'
})
export class CptCodesService {

  constructor(private _httpclient: HttpClient) { }

  updateCpt( cptItem: any){
    return this._httpclient.post(global.url + API_URL.CPT.updateCpt, cptItem);
  }
  
}
