import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-trans-report',
  templateUrl: './trans-report.component.html',
  styleUrls: ['./trans-report.component.css']
})
export class TransReportComponent implements OnInit {
  @Input() transReportData: any;
  displayedColumns = ['provider', 'in_progress', 'completed','initial', 'follow_up','others', 'total']
  dataSource: any;

  constructor() { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.transReportData);
  }

  getInprogressCount(element) {
    let inprogressCount = 0;
    for (let group of element) {
      if (group._id.progress_status?.trim() == '19') {
        inprogressCount = inprogressCount + group.total;
      }
    }
    return inprogressCount
  }
  getCompletedCount(element) {
    let completedCount = 0;
    for (let group of element) {
      if (group._id.progress_status?.trim() == '20') {
        completedCount = completedCount + group.total;
      }
    }
    return completedCount;
  }
  getTotalCount(element) {
    let totalCount = 0;
    totalCount = this.getCompletedCount(element) + this.getInprogressCount(element);
    return totalCount;
  }
  getProviderName(element) {
    if (element && element[0]?.provider[0]) {
      return element[0].provider[0].first_name + " " + element[0]?.provider[0].last_name;
    }
  }
  getInitialCount(element) {
    let initialCount = 0;
    for (let group of element) {
      if (group._id.results[0]?.match?.trim().toLowerCase() == 'init') {
        initialCount = initialCount + group.total;
      }
    }
    return initialCount;
  }
  getFollowUpCount(element) {
    let followUpCount = 0;
    for (let group of element) {
      if (group._id.results[0]?.match?.trim().toLowerCase() == 'follow') {
        followUpCount = followUpCount + group.total;
      }
    }
    return followUpCount;
  }
  getOthersCount(element) {
    // console.log("element: ", element);
    let othersCount = 0;
    for (let group of element) {
      // console.log("group: ", group);
      if (!group._id.results[0] || group._id.results.length <= 0) {
        othersCount = othersCount + group.total;
      }
    }
    return othersCount;
  }
}
