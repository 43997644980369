<div mat-dialog-content class="procedure-note-dialog">
    <div class="flex-col dialog-header gap-2">
        <div class="flex flex-row w-full justify-between items-center">
            <span class="header-text">
                Procedure Notes
            </span>
            <span *ngIf="selectedProcedureTypeData.selectedProcedureType && !selectedProcedureTypeData.selectedProcedureCategory"
                class="warning-text">
                <sup>*</sup>Please Select Procedure Category
            </span>
            <div>
                <button class="next-prev-button" [disabled]="currentPageIndex == 0" (click)="onPrevPage()">
                    Prev
                </button>
                <button class="next-prev-button"
                    [disabled]="(currentPageIndex)+1 == (getTotalPages()) || procedureNoteDataSet.length == 0"
                    (click)="onNextPage()">
                    Next
                </button>
                <button mat-icon-button aria-label="Close" (click)="closeDialog()">
                    <mat-icon class="dialog-close-btn">close</mat-icon>
                </button>
            </div>
        </div>
        <div class="flex w-full justify-between">
            <div class="flex-container">
                <div style="display: flex; white-space: nowrap;">
                    <mat-radio-group class="light flex flex-row" [value]="activatedRouteIndex">
                        <mat-radio-button [disabled]="addingNewProcedureData && i !== activatedRouteIndex " *ngFor="let procedure of allProcedureNotesData; let i = index"
                            [value]="i">
                            <ng-container class="flex justify-centter">
                                <span (click)="changeRoute(i)">{{getHeading(procedure)}}</span>
                                <span *ngIf="procedure.procedure_id !== null" class="flex justify-center"><mat-icon style="font-size: medium; color: red; margin-left: 5px" matTooltip="Delete Procedure Data" (click)="deleteProcedureData(procedure, i)">remove_circle_outline</mat-icon></span>
                            </ng-container>
                        </mat-radio-button>
                    </mat-radio-group>
                    <br>
                </div>
            </div>
            <div>
                <button class="next-prev-button" [disabled]="addingNewProcedureData && selectedProcedureTypeData.selectedProcedureType._id === ''" (click)="addNewProcedureData()">Add New Procedure Data</button>
            </div>
        </div>
    </div>
    <div *ngIf="currentPageIndex === 0 && procedureNotesDataId !== ''">
        <div class="dataset-header">
            <div class="dataset-header-data">
                <label class="dataset-header-label">Wound</label>
                <div class="dataset-row">
                    <div class="dataset-row-data">
                        <label>{{ data.location }}</label>
                    </div>
                </div>
            </div>
        </div>
        <hr class="line-break">
        <div class="flex flex-row w-full justify-around items-center">
            <div class="dataset-header">
                <div class="dataset-header-data items-center">
                    <label>Procedure Type</label>
                    <div class="dataset-row">
                        <div class="dataset-row-data">
                            <mat-form-field>
                                <mat-select [(ngModel)]="selectedProcedureTypeData.selectedProcedureType._id"
                                    (selectionChange)="onChangeProcedureType()" name="procedure-type"
                                    placeholder="Procedure Type" required>
                                    <mat-option *ngFor="let type of allProcedureTypes" value="{{type._id}}">
                                        {{type.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dataset-header">
                <div class="dataset-header-data items-center">
                    <label>Procedure Category</label>
                    <div class="dataset-row">
                        <div class="dataset-row-data">
                            <mat-form-field>
                                <mat-select [(ngModel)]="selectedProcedureTypeData.selectedProcedureCategory._id"
                                    name="procedure-category" placeholder="Procedure Category" (selectionChange)="onChangeProcedureCategory()" required>
                                    <mat-option *ngFor="let type of allProcedureCategories" value="{{type._id}}">
                                        {{type.category}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="line-break">
    </div>
    <ng-container *ngFor="let item of procedureNoteDataSet; let i = index">
        <div class="dataset-header">
            <app-procedure-note-checkbox *ngIf="item.header_type === 'Checkbox'" [options]="item.rows"
                [label]='item.header' [multiselect]=item.multiselect_enabled
                [selectedOptions]="getOptionsForHeader(item._id)" (optionSelected)="onOptionsSelected($event, item._id)"
                [default_value]="item.default_value" [categorySelected]="selectedProcedureTypeData.selectedProcedureCategory?._id">
            </app-procedure-note-checkbox>

            <app-procedure-note-dropdown *ngIf="item.header_type === 'Dropdown'" [options]="item.rows"
                [label]="item.header" [multiselect]=item.multiselect_enabled
                [selectedOptions]="getOptionsForHeader(item._id)" (optionSelected)="onOptionsSelected($event, item._id)"
                [default_value]="item.default_value" [categorySelected]="selectedProcedureTypeData.selectedProcedureCategory?._id">
            </app-procedure-note-dropdown>

            <div *ngIf="item.header_type === 'Input'">
                <div class="dataset-header-data">
                    <label class="dataset-header-label">{{ item.header }}</label>
                    <div class="dataset-input">
                        <div class="dataset-row">
                            <ng-container *ngFor="let label of item.rows">
                                <div class="dataset-row-data">
                                    <mat-form-field class="flex-1">
                                        <mat-label>{{label.value}}</mat-label>
                                        <input matInput type="number"
                                            [value]="getInputValue(item._id, 'input', label.value)"
                                            (input)="onChangeInput(item, label.value, $event)">
                                        <span matSuffix>{{label.unit}}</span>
                                    </mat-form-field>
                                </div>
                            </ng-container>
                        </div>
                        <div class="dataset-input-text">
                            {{ item.header }}: {{getInputValue(item._id, 'header')}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr *ngIf="i !== procedureNoteDataSet.length - 1" class="line-break">
    </ng-container>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>