import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, lastValueFrom } from 'rxjs';
import { WoundCareWidgetService } from 'src/app/includes/note-sidebar/wound-care-widget/wound-care-widget.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { WoundService } from 'src/app/services/wound.service';
import { getFieldName } from 'src/app/includes/note-sidebar/wound-care-widget/wound-utils';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SaveReportDialogComponent } from '../save-report-dialog/save-report-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { Location } from '@angular/common';
import moment from 'moment'
import * as global from '../../../../../global';
import { CustomWoundReportService } from '../custom-wound-report.service';
import { HttpResponse } from '@angular/common/http';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { WoundNurseRelationService } from 'src/app/services/Modules/woundNurse.service';
import { SnfWcNurseAssociationService } from 'src/app/includes/snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { FacilityService } from 'src/app/services/facility.service';
import { CommonService } from 'src/app/services/common.service';
import { NursingService } from 'src/app/includes/nursing-module/nursing.service';

@Component({
  selector: 'app-data-option',
  templateUrl: './data-option.component.html',
  styleUrls: ['./data-option.component.css']
})
export class DataOptionComponent implements OnInit {
  project: any = {};
  global = global;
  getFieldName = getFieldName;
  dataSetSettings: any;
  displayedColumns: string[] = [];
  headers: string[] = [];
  dataSource: any = new MatTableDataSource([]);
  dataSets: any = [];
  filter: any = {
    last_assessment_date_obj: {
      startDate: moment().subtract(6, "days"),
      endDate: moment()
    }
  };
  facilityControl = new FormControl();
  loaderId = 'app-data-option';
  dataOptions: any = [];
  columns: any = [];
  isButtonDisabled: boolean = false;
  currentUser: any;
  companyId: any;
  isCompanySide: boolean = false;
  isUserDashboard: boolean;
  nursingSide: boolean;
  isSnfNurse: boolean;
  isNurse: boolean;
  isWoundNurse: boolean;
  wounds: any = [];
  pagination: any = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    PAGE_LIMIT: 20,
    skip: 0
  };
  shouldShowReport: boolean = false;
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 1 Week': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  };
  facilities: Array<any> = [];
  filteredFacilities: Observable<string[]>;
  @Output() updateData = new EventEmitter<any>();
  @Input() dataOption;
  @Output() shouldShowSavedReports = new EventEmitter<boolean>();
  woundsTable: any = [];
  selectedFacility: any;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _companyService: CompanyService,
    private _woundCareWidgetService: WoundCareWidgetService,
    private _toastr: ToastrService,
    private _route: ActivatedRoute,
    private _location: Location,
    private _woundService: WoundService,
    private loader: NgxUiLoaderService,
    private _authService: AuthService,
    private _customWoundReportService: CustomWoundReportService,
    private _woundNurseRelationService: WoundNurseRelationService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
    private _facilityService: FacilityService,
    private _commonService: CommonService,
    private _nurseService: NursingService,
  ) {
    this.currentUser = this._authService.currentUser;
    this.companyId = this._route.parent.snapshot.params.id;
    if (this._route.parent.snapshot.params.id) {
      this.isCompanySide = true;
    } else {
      this.companyId = this.currentUser.company_id;
    }
    if (window.location.pathname.includes('dashboard')) {
      this.isUserDashboard = true;
    }
    if (window.location.pathname.includes('nursing')) {
      this.nursingSide = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      this.isSnfNurse = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.NURSE) {
      this.isNurse = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
      this.isWoundNurse = true;
    }
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId);
    // await this.getDataSetsColumns();
    if (this.isCompanySide) {
      this.initFacilities(true);
    }
    else {
      if (this._authService.currentUser.user_type === global.USER_TYPE.DOCTOR)
        await this.initFacilities();
      else if (this._authService.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE)
        await this.initSnfWcNurseFacilityAssociation();
      else if (this._authService.currentUser.user_type === global.USER_TYPE.WOUND_NURSE)
        await this.initWoundNurseFacilityAssociations();
      else if(this.currentUser.user_type === global.USER_TYPE.NURSE)
      await this.initNurseFacilities();
    }
    await this.initDataSet();
    if (this.dataOption) {
      this.selectDefaultOption();
    }
    this.loader.stopLoader(this.loaderId);
  }

  async initDataSet() {
    const { status, data } = await lastValueFrom(this._woundService.getAllDatasetinCompany(null,
      this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE ? global.USER_TYPE.SNF_WC_NURSE : global.USER_TYPE.DOCTOR)) as any;
    if (status == 200 && Array.isArray(data) && data.length > 0) {
      this.dataSets = data;
      this.headers = this.dataSets.map(dataSet => dataSet.header)
        .filter(header => header !== "INFECTION SIGNS" && header !== "WOUND EDGES");
      this.columns = [
        { name: "FACILITY", value: "facility_title", isChecked: false },
        { name: "PATIENT", value: "patient_name", isChecked: false },
        { name: "PROVIDER", value: "provider_name", isChecked: false },
        // { name: "MRN", value: "mrn", isChecked: false },
        { name: "FIRST EVALUATION DATE", value: "first_date_of_evaluation", isChecked: false },
        { name: "LAST EVALUATION DATE", value: "last_date_of_evaluation", isChecked: false },
        { name: "DAYS IN TREATMENT", value: "days_in_treatment", isChecked: false },
        { name: "SIGNED DATE", value: "signed_date", isChecked: false },
        // { name: "WOUND NO", value: "woundNo", isChecked: false },
        ...this.headers.map(header => {
          return {
            name: header,
            value: getFieldName(header),
            isChecked: false
          }
        })
      ];
      this._commonService.sortArray(this.columns, "name");
    }
    else {
      this._toastr.error("Something Went Wrong", 'Error');
    }
  }

  selectDefaultOption() {
    const { filter, project } = this.dataOption;
    if (filter.last_assessment_date_obj) {
      filter.last_assessment_date_obj.startDate = moment(filter.last_assessment_date_obj.startDate);
      filter.last_assessment_date_obj.endDate = moment(filter.last_assessment_date_obj.endDate);
    }
    this.filter = filter;
    this.project = project;
    if (this.filter.facility_id) {
      const facility = this.facilities.find(facility => facility._id === this.filter.facility_id);
      if (facility) {
        this.selectedFacility = facility;
        this.facilityControl.setValue(this.selectedFacility.title);
      }
    }
    for (const column of this.columns) {
      if (typeof this.project[column.value] === "number")
        column.isChecked = true;
    }
    this.displayedColumns = this.columns.filter(column => column.isChecked).map(column => column.name);
    this.isButtonDisabled = Object.values(this.filter).length > 0 && Object.values(this.project).length > 0;
    this.woundsTable = this.columns.filter(column => this.displayedColumns.includes(column.name));
    for (const displayedColumn of this.displayedColumns) {
      const column = this.getColumn(displayedColumn);
      if (column && Object.values(column).length > 0)
        this.dataOptions = [...this.dataOptions, this.getColumn(displayedColumn)];
    }
  }

  async onSelectFacility($event, facility) {
    if ($event.isUserInput) {
      this.selectedFacility = facility;
      this.filter.facility_id = facility._id;
    }
  }

  async initWoundNurseFacilityAssociations() {
    const filter = { associated_wound_nurse_ids: this._authService.currentUser._id };
    const nurseProjection = {
      first_name: 1,
      last_name: 1,
      title: 1,
      full_name: 1
    };
    const facilityProjection = {
      title: 1,
      pcc_facId: 1,
      source: 1,
      pcc_2_legged_authentication: 1
    };
    const nurseFilter: any = {
      company_id: this._authService.currentUser.company_id,
      is_activated: "true",
      user_type: global.USER_TYPE.WOUND_NURSE
    };
    const response: any = await lastValueFrom(this._woundNurseRelationService.getWoundNurseFacilityAssociations(filter, nurseProjection, facilityProjection, nurseFilter));
    if (response.status === 200) {
      if (Array.isArray(response.data) && response.data.length > 0) {
        this.facilities = response.data.map(association => association.facility_id);
        // this.onSelectFacility(this.facilities[0]._id);
        this.showFacilityDropdown();
      }
    }
  }

  async initSnfWcNurseFacilityAssociation() {
    const filter = {
      associated_snf_wc_nurse_ids: this._authService.currentUser._id,
      company_id: this._authService.currentUser.company_id
    };
    const nurseProjection = {
      first_name: 1,
      last_name: 1,
      title: 1,
    };
    const facilityProjection = {
      title: 1,
      pcc_facId: 1,
      pcc_2_legged_authentication: 1,
      source: 1,
      pcc_orgUuid: 1
    };
    const response: any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
    if (response.status === 200 && response.data.length > 0) {
      this.facilities = response.data;
      this.showFacilityDropdown();
    }
  }

  async initNurseFacilities() {
    const response: any = await lastValueFrom(this._nurseService.getAssociatedFacilities());
    if (response.status === 200 && response.data.array.length > 0) {
      this.facilities = response.data.array;
      this.showFacilityDropdown();
    }
  }

  /****************Don't Remove normalizeData Function *********************/
  // normalizeData() {
  //   const result = {};
  //   this.dataSets.forEach(dataSet => {
  //     const columnName = dataSet.header;
  //     const columnValues = dataSet.rows.map(row => row.value);
  //     columnValues.forEach((value, index) => {
  //       const tableRow = {
  //         [columnName]: value
  //       };
  //       result[index] = { ...result[index], ...tableRow };
  //       console.log("index", result[index], tableRow, index);
  //     });
  //   });
  //   return Object.values(result);
  // }
  goBack() {
    this.shouldShowSavedReports.emit(true);
  }

  getColumn(header) {
    const index = this.dataSets.findIndex(dataSet => dataSet.header === header);
    if (index > -1) {
      return { columnName: header, values: this.dataSets[index].rows.map(row => row.value) };
    }
  }

  showFacilityDropdown(): void {
    this.filteredFacilities = this.facilityControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => this.filterFacilities(value)));
  }

  onChangeFacilityText(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    if (inputValue.trim() === '') {
      this.selectedFacility = null;
      delete this.filter.facility_id;
    }
  }

  private filterFacilities(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) !== -1);
  }

  async initFacilities(isCompanySide = false) {
    const filter = {
      provider_ids_ref: this._authService.currentUser._id,
      is_active: true,
      assoc_company_ids: this._authService.currentUser.company_id
    };
    if (isCompanySide) {
      delete filter.provider_ids_ref;
      filter.assoc_company_ids = this.companyId;
    }
    const projection = {
      'title': 1,
      'source': 1,
      'assoc_company_ids': 1
    };
    const response: any = await lastValueFrom(this._facilityService.getFacilities(filter, projection));
    if (response.status === 200) {
      this.facilities = response.data.array;
      this.showFacilityDropdown();
    }
    else {
      this._toastr.error(response.data.message, 'Error');
    }
  }

  selectDefaultFacility() {
    if (this.facilities.length > 0) {
      // this.facility_id = null;
      this.facilityControl.setValue(this.facilities[0].title);
    }
  }

  
  resetData() {
    // this.project = {};
    // this.filter = {
    //   last_assessment_date_obj: {
    //     startDate: moment().subtract(6, "days"),
    //     endDate: moment()
    //   }
    // };
    // this.displayedColumns = [];
    // this.dataSource.data = [];
    // this.wounds = [];
    // this.woundsTable = [];
    // this.dataOptions = [];
    // this.isButtonDisabled = false;
    // this.selectedFacility = null;
    // this.facilityControl.setValue("");
    // delete this.filter.facility_id;
    // for (const column of this.columns) {
    //   column.isChecked = false;
    // }
    // this.dataOption = null;
    this.shouldShowReport = false;
    this.pagination = {
      pageNo: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalRecords: 0,
      PAGE_LIMIT: 20,
      skip: 0
    };
  }

  toggleClass(value, header) {
    if (value) {
      const key = getFieldName(header);
      if (!this.filter[key]) {
        this.filter[key] = { $in: [value] };
      }
      else {
        const index = this.filter[key]["$in"].indexOf(value);
        if (index === -1) {
          this.filter[key]["$in"] = [...this.filter[key]["$in"], value];
        }
        else {
          this.filter[key]["$in"].length === 1 ? delete this.filter[key] : this.filter[key]["$in"].splice(index, 1);
        }
      }
      this.isButtonDisabled = Object.values(this.filter).length > 0 && Object.values(this.project).length > 0;
    }
  }

  async getDataSetsColumns() {
    const response: any = await lastValueFrom(this._companyService.getDataSetColumnsSetting(null, this.companyId,
      this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE ? global.USER_TYPE.SNF_WC_NURSE : global.USER_TYPE.DOCTOR));
    if (response.status == 200) {
      this.dataSetSettings = response.data;
    }
    else {
      this._toastr.error(response.message, 'Error');
    }
  }
  // reArrangeDataSet(colIDs) {
  //   if (colIDs.length > 0) {
  //     this.dataset = this.dataSets.sort(function (a, b) {
  //       return colIDs.indexOf(a._id.toString()) - colIDs.indexOf(b._id.toString());
  //     });
  //   } else {
  //     this.dataset = this.dataSets;
  //   }
  // }

  handleProjection($event) {
    const index = this.columns.findIndex(column => column.value === $event.source.value);
    const i = this.dataOptions.findIndex(dataOption => dataOption.columnName === this.columns[index].name);
    if ($event.source.checked) {
      this.project[$event.source.value] = 1;
      this.displayedColumns.push(this.columns[index].name);
      this.columns[index].isChecked = true;
      if (i === -1) {
        const column = this.getColumn(this.columns[index].name);
        if (column && Object.values(column).length > 0) {
          this.dataOptions = [...this.dataOptions, this.getColumn(this.columns[index].name)];
        }
      }
    }
    else {
      delete this.project[$event.source.value];
      this.displayedColumns.splice(this.displayedColumns.indexOf(this.columns[index].name), 1);
      this.columns[index].isChecked = false;
      if (i > -1)
        this.dataOptions.splice(i, 1);
      delete this.filter[$event.source.value];
    }
    this.isButtonDisabled = Object.values(this.filter).length > 0 && Object.values(this.project).length > 0;
    this.woundsTable = this.columns.filter(column => this.displayedColumns.includes(column.name));
    this.woundsTable.sort((a, b) => {
      const indexA = this.displayedColumns.indexOf(a.name);
      const indexB = this.displayedColumns.indexOf(b.name);
      return indexA - indexB;
    });
  }

  async handlePageNavigation(shouldgoBack: boolean) {
    shouldgoBack ? this.pagination.pageNo-- : this.pagination.pageNo++;
    await this.initWounds();
  }

  async initWounds() {
    this.loader.startLoader(this.loaderId);
    this.filter.company_id = this.companyId;
    const response: any = await lastValueFrom(this._woundService.fetchWounds(this.filter, this.project, this.pagination.pageNo));
    if (response.status === 200) {
      this.wounds = response.data.wounds;
      this.pagination.totalRecords = response.data.totalRecords;
      this.handlePagination();
      this.dataSource.data = this.wounds;
    }
    else {
      this._toastr.error(response.message, 'Error');
    }
    this.loader.stopLoader(this.loaderId);
  }

  handlePagination() {
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    if (this.pagination.skip + this.wounds.length < this.pagination.totalRecords) {
      this.pagination.hasNextPage = true;
    }
    else {
      this.pagination.hasNextPage = false;
    }
    if (this.pagination.skip > 0) {
      this.pagination.hasPrevPage = true;
    }
    else {
      this.pagination.hasPrevPage = false;
    }
  }

  handleDateOfServiceChange(event) {
    if (event.startDate && event.endDate) {
      this.filter.last_assessment_date_obj = {
        startDate: null,
        endDate: null
      };
      const noOfDays = this._commonService.getDifferenceOfDates(event.startDate, event.endDate,'days');
      if (noOfDays > 29) {
        this.filter.last_assessment_date_obj = {};
        this.filter.last_assessment_date_obj.startDate = moment(event.startDate);
        this.filter.last_assessment_date_obj.endDate = moment(this.filter.last_assessment_date_obj.startDate).add(29, "days").endOf('day');
        this._toastr.error('Can not select more than 30 days', 'Date Error');
      }
      else {
        this.filter.last_assessment_date_obj.startDate = event.startDate;
        this.filter.last_assessment_date_obj.endDate = event.endDate;
      }
    }
  }

  saveReport() {
    this.dialog.open(SaveReportDialogComponent, { width: '500px' }).afterClosed().subscribe(async (data) => {
      if (data) {
        this.loader.startLoader(this.loaderId);
        const payload = {
          ...data,
          filter: JSON.stringify(this.filter),
          project: JSON.stringify(this.project)
        }
        const response: any = await lastValueFrom(this._customWoundReportService.addCustomWoundReport(payload));
        if (response.status == 200) {
          this.updateData.emit(response.data);
          this.goBack();
          this._toastr.success(response.message, 'Success');
        }
        else {
          this._toastr.error(response.message, 'Error');
        }
        this.loader.stopLoader(this.loaderId);
      }
    });
  }

  async exportCustomWoundReportAsXLSX() {
    if (this.filter && this.project && Object.keys(this.filter).length != 0 && Object.keys(this.project).length != 0) {
      const infoToastr = this._toastr.info('Exporting as XLSX...', 'Please wait', { disableTimeOut: true });
      const response: any = await lastValueFrom(this._woundService.exportCustomWoundReportAsXLSX(this.filter, this.project, this.woundsTable));
      if (response instanceof HttpResponse) {
        const url = window.URL.createObjectURL(response.body);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `Custom Wound Report`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this._toastr.clear(infoToastr.toastId);
      }
      else {
        console.log("Err in exportcustomWoundReportAsXLSX", response);
        this._toastr.clear(infoToastr.toastId);
      }
    } else {
      this._toastr.error('Please select filter and project', 'Error');
    }

  }

}
