import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import * as global from '../includes/global';
import { AuthService } from './auth.service';
import { API_URL } from '../../environments/api-url';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PatientRoundingSheetCommentsService {
  currentUser: any;

  constructor(
    private httpclient: HttpClient,
    private _authService: AuthService
  ) {
    this.currentUser = this._authService.currentUser;
  }

  getPatientRoundingSheetComments(patient_id, rounding_sheet_id) {
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,

      patient_id,
      rounding_sheet_id
    };

    return this.httpclient
      .post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getPatientRoundingSheetComments, { details })
      .pipe(
      //   map((response:any) => {
      //   if(response.status === 200) {
      //     if(Array.isArray(response.data.array) && response.data.array.length > 1) {
      //       response.data.array.census_comment.sort((a: any, b: any) => {
      //         return new Date(b.updatedAt).getMilliseconds() - new Date(a.updatedAt).getMilliseconds();
      //       });
      //     }
      //   }
      //   return response;
      // })
      );
  }

  addPatientRoundingSheetComment(patient_id, note_detail, created_by, rounding_sheet_id) {
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,

      patient_id,
      note_detail,
      created_by,
      is_active: true,
      rounding_sheet_id
    };

    return this.httpclient
      .post(global.url + API_URL.PATIENT_ROUNDING_SHEET.addPatientRoundingSheetComment, { details })
      .pipe();
  }
  updatePatientRoundingSheetComment(_id, patient_id, note_detail, created_by) {
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,

      _id,
      patient_id,
      note_detail,
      created_by
    };

    return this.httpclient
      .post(global.url + API_URL.PATIENT_ROUNDING_SHEET.updatePatientRoundingSheetComment, { details })
      .pipe();
  }
  deletePatientNote(_id) {
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,

      _id,
      is_active: false
    };

    return this.httpclient
      .post(global.url + API_URL.PATIENT_ROUNDING_SHEET.updatePatientRoundingSheetComment, { details })
      .pipe();
  }

  addCensusComment(data) {

    return this.httpclient
      .post(global.url + API_URL.PATIENT_ROUNDING_SHEET.addCensusComment, {details: data})
      .pipe();
  }
  
  getCensusComment(patient_id, rounding_sheet_id, company_id, parentCommentId?) {
    let details: any = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      company_id,
      patient_id,
      rounding_sheet_id
    };
    if(parentCommentId) {
      details.parentCommentId = parentCommentId;
    }

    return this.httpclient
      .post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getCensusComment, { details })
      .pipe();
  }
  getPatientCommentCount(rounding_sheet_id) {
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,

      rounding_sheet_id
    };

    return this.httpclient
      .post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getPatientCommentCount, { details })
      .pipe();
  }
  deleteCensusComment(commentId) {
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,

      commentId
    };
    return this.httpclient
      .post(global.url + API_URL.PATIENT_ROUNDING_SHEET.deleteCensusComment, { details })
      .pipe();
  }

  editCensusComment(comment) {
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,

      comment
    };
    return this.httpclient
      .post(global.url + API_URL.PATIENT_ROUNDING_SHEET.editCensusComment, { details })
      .pipe();
  }
  getCensusRecentComment(censusId) {
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      company_id: this.currentUser.company_id,
      censusId
    };
    return this.httpclient
      .post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getCensusRecentComment, { details })
      .pipe();
  }

  
}
