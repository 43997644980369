    <div [ngClass]="{'container': (companySide || user_dashboard) && !(nursingSide)}">
    <h3 class="dark-blue-heading flex justify-between items-center">
        <ng-container *ngIf="!user_dashboard">
            <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
                Back
            </button>
        </ng-container>
        <div [ngClass]="{'label-margin': !user_dashboard}">
            <label style="white-space: nowrap;"> {{title}} </label>
        </div>

        <div class="flex justify-center items-end" *ngIf="user_dashboard">
            <input matInput type="text" ngxDaterangepickerMd [(ngModel)]="filter.updationDate" class="mat-like-input"
                [ranges]="preDateRanges" [alwaysShowCalendars]="true" [maxDate]="currentDate" [linkedCalendars]="true"
                [showClearButton]="false" (datesUpdated)="changeUpdationDate($event)" class="date-range-picker"/>
        </div>
        <div class="flex gap-2">
            <mat-icon matTooltip="Download Report" style="cursor: pointer;" [matMenuTriggerFor]="sendMenu">download</mat-icon>
             
            <mat-menu #sendMenu="matMenu" xPosition="before">
                <button mat-menu-item (click)="exportDnsHealingReportAsXLSX('landscape', 'download');">Export
                    As XLSX</button>
            
                <button mat-menu-item (click)="exportDnsHealingReportAsXLSX('landscape', 'email');">Send via email</button>
                <button mat-menu-item (click)="exportDnsHealingReportAsPDF('landscape');">Export
                    As PDF</button>
            </mat-menu>
        </div>
        
    </h3>

    <div class="search-block theme-form filter-bar"
        style="background-color: white; border-radius: 4px; padding-top: 8px; padding-bottom: 5px; padding-left: 8px;padding-right: 8px;"
        class="items-start justify-center">

        <div class="flex items-center justify-start gap-2">
            <mat-form-field appearance="fill" class="date-width">
                <mat-label>Select Date Type</mat-label>
                <mat-select (selectionChange)="filter.dateType = $event.value;" [value]="filter.dateType">
                    <mat-option value="createdAt">Created On</mat-option>
                    <mat-option value="updatedAt">Updated On</mat-option>
                    <mat-option value="last_assessment_date_obj">Date Of Service</mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!user_dashboard">
                <input style="width: 180px;" type="text" ngxDaterangepickerMd [(ngModel)]="filter.updationDate"
                    (datesUpdated)="changeUpdationDate($event)" class="mat-like-input" [ranges]="preDateRanges"
                    [alwaysShowCalendars]="true" [maxDate]="currentDate" [linkedCalendars]="true"
                    [showClearButton]="false" />
            </div>
            <mat-form-field appearance="fill" class="sm:w-1/2 md:w-full">
                <mat-label>Select Facility</mat-label>
                <input id="facilityControlInput" type="text" placeholder="Select Facility" aria-label="facility" matInput
                    [formControl]="facilityControl" [matAutocomplete]="facilityAutoControl">
                <mat-autocomplete #facilityAutoControl="matAutocomplete" autoActiveFirstOption>
                    <mat-option (onSelectionChange)="onSelectFacility($event, facility);"
                        *ngFor="let facility of filteredFacilities | async" [value]="facility.title">
                        {{facility.title}}
                    </mat-option>
                </mat-autocomplete>

            </mat-form-field>

            <mat-form-field appearance="fill" class="state-width">
                <mat-label>Select State</mat-label>
                <mat-select [value]="filter.state" (selectionChange)="onStateChange($event.value)">
                    <mat-option value="all">All</mat-option>
                    <mat-option value="active">Active</mat-option>
                    <mat-option value="remission">Remission</mat-option>
                    <mat-option value="amputated">Amputated</mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-raised-button class="dn-button-primary btn-width"  (click)="handleApplyFilter();">Apply
            </button>

            <button mat-raised-button class="dn-button-secondary btn-width" (click)="resetFilter();">Reset
            </button>
        </div>

    </div>

    <mat-card class="vertical-height">
        <div class="table-height">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                <ng-container matColumnDef="room_number">
                    <th mat-header-cell *matHeaderCellDef> Room # </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.patient_id?.last_room_num}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Last Name, First Name </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.patient_id?.last_name}}, {{element?.patient_id?.first_name}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="body_part">
                    <th mat-header-cell *matHeaderCellDef> Wound Location </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.body_part}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="etiolgy">
                    <th mat-header-cell *matHeaderCellDef> Etiology </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.etiolgy}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="last_admit_date">
                    <th mat-header-cell *matHeaderCellDef> Facility Admission Date </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.patient_id.last_admit_date_obj}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="latest_evaluation_date">
                    <th mat-header-cell *matHeaderCellDef> Latest Evaluation Date </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.last_assessment_date}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="stage">
                    <th mat-header-cell *matHeaderCellDef> Stage/Severity </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.stage}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="length">
                    <th mat-header-cell *matHeaderCellDef> Length </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.length}} cm
                    </td>
                </ng-container>

                <ng-container matColumnDef="width">
                    <th mat-header-cell *matHeaderCellDef> Width </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.width}} cm
                    </td>
                </ng-container>

                <ng-container matColumnDef="depth">
                    <th mat-header-cell *matHeaderCellDef> Depth </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.depth}} cm
                    </td>
                </ng-container>
                <ng-container matColumnDef="wound_status">
                    <th mat-header-cell *matHeaderCellDef> Wound Status </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.wound_status}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="wound_recognition_date">
                    <th mat-header-cell *matHeaderCellDef> Date Wound Acquired </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.wound_recognition_date}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="wound_recognition_date_type">
                    <th mat-header-cell *matHeaderCellDef> Acquired in House? </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.wound_recognition_date_type}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="dressing_change_frequency">
                    <th mat-header-cell *matHeaderCellDef> Dressing Change Frequency </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.change_frequency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="cleanse_wound_with">
                    <th mat-header-cell *matHeaderCellDef> Cleanse Wound With </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.cleanse_wound_with}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="primary_treatment">
                    <th mat-header-cell *matHeaderCellDef> Primary Treatment </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.primary_treatment}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="secondary_dressings">
                    <th mat-header-cell *matHeaderCellDef> Secondary Dressings </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.secondary_dressing}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
            <div class="flex justify-between items-center bottom-row">
                <b>POA: {{poaCount}}</b>
                <b>Facility Acquired: {{facilityAcquiredCount}}</b>
                <b>Open: {{openWoundsCount}}</b>
                <b>In Remission: {{inRemissionWoundsCount}}</b>
                <div class="flex justify-between items-center">
                    <button mat-raised-button class="dn-button-primary" (click)="goToPreviousPage()"
                        [disabled]="!pagination.hasPrevPage"> Prev</button>
                    <span>
                        {{pagination.skip + dnsReport.length === 0 ? 0 : pagination.skip + 1 }} - {{pagination.skip +
                        dnsReport.length}} out of {{pagination.totalRecords}}
                    </span>
                    <button mat-raised-button class="dn-button-primary" (click)="goToNextPage()"
                        [disabled]="!pagination.hasNextPage">Next</button>
                </div>
            </div>
        </div>
    </mat-card>
</div>