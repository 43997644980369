<form #form="ngForm" (ngSubmit)="handleSubmit(form)">
    <div class="flex justify-end gap-5">
        <button mat-raised-button class="dn-button-primary">Save</button>
    </div>
    <br />
    
    <div class="fields-sections flex flex-col">
        <div class="fields-section">
            <mat-form-field>
                <mat-label>Select IME Company</mat-label>
                <mat-select
                            required
                            name="ime_company_ref"
                            [(ngModel)]="model.ime_company_ref">
                    <mat-option *ngFor="let c of imeCompanies"
                                [value]="c._id">{{c.abbreviation}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="fields-section">
            <mat-form-field class="field-item">
                <input matInput placeholder="Doctor's Rate" [(ngModel)]="model.doctor_rate" name="doctor_rate">
            </mat-form-field>
            <mat-form-field class="field-item">
                <input matInput placeholder="IME Rate" [(ngModel)]="model.ime_rate" name="ime_rate">
            </mat-form-field>
            <mat-form-field class="field-item">
                <input matInput placeholder="Negotiated Rate" [(ngModel)]="model.negotiated_rate" name="negotiated_rate">
            </mat-form-field>
    
            <mat-form-field class="field-item">
                <input matInput placeholder="Page Size" [(ngModel)]="model.page_size" name="page_size">
            </mat-form-field>
            <mat-form-field class="field-item">
                <input matInput placeholder="Doctor Cut" [(ngModel)]="model.doctor_cut" name="doctor_cut">
            </mat-form-field>
    
            <mat-form-field class="field-item">
                <input matInput placeholder="PKPR Cut" [(ngModel)]="model.pkpr_cut" name="pkpr_cut">
            </mat-form-field>
        </div>
    
        <div class="label">Services</div>
        <div class="fields-section">
            <mat-form-field class="field-item">
                <input matInput placeholder="Addendum" [(ngModel)]="model.service_addendum" name="service_addendum">
            </mat-form-field>
            <mat-form-field class="field-item">
                <input matInput placeholder="No Show" [(ngModel)]="model.service_no_show" name="service_no_show">
            </mat-form-field>
            <mat-form-field class="field-item">
                <input matInput placeholder="Rush report" [(ngModel)]="model.service_rush_report" name="service_rush_report">
            </mat-form-field>
        </div>
    
        <div class="label">Excessive Record Fee</div>
        <div class="fields-section">
            <mat-form-field class="field-item">
                <input matInput placeholder="100-250" [(ngModel)]="model.excessive_record_fee_100_250" name="excessive_record_fee_100_250">
            </mat-form-field>
            <mat-form-field class="field-item">
                <input matInput placeholder="250-500" [(ngModel)]="model.excessive_record_fee_250_500" name="excessive_record_fee_250_500">
            </mat-form-field>
            <mat-form-field class="field-item">
                <input matInput placeholder="550+" [(ngModel)]="model.excessive_record_fee_500_plus" name="excessive_record_fee_500_plus">
            </mat-form-field>
            <mat-form-field class="field-item">
                <input matInput placeholder="Deposition Fee (Phone)" [(ngModel)]="model.excessive_record_deposition_fee_phone" name="excessive_record_deposition_fee_phone">
            </mat-form-field>
        </div>
    
        <div class="label">Depositin Fee (In Court)</div>
        <div class="fields-section">
            <mat-form-field class="field-item">
                <input matInput placeholder="Half Day" [(ngModel)]="model.deposition_fee_court_half_day" name="deposition_fee_court_half_day">
            </mat-form-field>
            <mat-form-field class="field-item">
                <input matInput placeholder="Full Day" [(ngModel)]="model.deposition_fee_court_full_day" name="deposition_fee_court_full_day">
            </mat-form-field>
            <mat-form-field class="field-item">
                <input matInput placeholder="Peer Review Fee" [(ngModel)]="model.deposition_fee_court_peer_review" name="deposition_fee_court_peer_review">
            </mat-form-field>
            <mat-form-field class="field-item">
                <input matInput placeholder="File Review" [(ngModel)]="model.deposition_fee_court_file_review" name="deposition_fee_court_file_review">
            </mat-form-field>
        </div>
    </div>
    <ngx-ui-loader [loaderId]="doctorFeeStructureLoader"></ngx-ui-loader>

</form>