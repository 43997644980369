<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

    <ng-container matColumnDef="sr">
        <th mat-header-cell *matHeaderCellDef> Sr </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            {{i + 1}}
        </td>
    </ng-container>

    <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef> Subject </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            {{element.subject}}
        </td>
    </ng-container>

    <ng-container matColumnDef="emailBody">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            <div [innerHTML]="element.body"> 
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef> Duration </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            <mat-form-field appearance="fill">
                <mat-label>Duration</mat-label>
                <mat-select [value]="element.duration" (selectionChange)="handleDurationChange($event.value, element)">
                    <mat-option value="second">
                        Every Second
                    </mat-option>
                    <mat-option value="minute">
                        Every Minute
                    </mat-option>
                    <mat-option value="hour">
                        Every Hour
                    </mat-option>
                    <mat-option value="day">
                        Every Day
                    </mat-option>
                    <mat-option value="week">
                        Every Week
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            <mat-form-field appearance="fill">
                <mat-label>Status</mat-label>
                <mat-select [value]="element.status" (selectionChange)="handleStatusChange($event.value, element)">
                    <mat-option value="active">
                        Active
                    </mat-option>
                    <mat-option value="inactive">
                        Inactive
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </td>
    </ng-container>
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            <div class="operation-buttons buttons-div flex items-center gap-5">
                <span class="material-icons"
                    matTooltip="Edit" matTooltipPosition="above" style="cursor: pointer;" (click)="openSendEmailTemplateDialog(element._id, i);">
                    edit_note
                </span>
                <span class="material-icons" (click)="deleteEmail(element._id, i)" matTooltip="Delete" matTooltipPosition="above"
                    style="cursor: pointer;">
                    delete_outline
                </span>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>