<div class="flex">
    <app-input-with-dropdown class="flex-grow" [noDropdown]="true" placeholder="Search Facility"
        (inputChange)="applyFilter($event)">
    </app-input-with-dropdown>
</div>
<div style="height: 10px;"></div>
<div style="height: 68vh;overflow: auto;">
    <table mat-table [dataSource]="dataSource" matSort>
    
        <ng-container matColumnDef="facility">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Facility</th>
            <td mat-cell *matCellDef="let element" style="width: 15%">
                <img *ngIf="element.source === 'PointClickCare' || element.pcc_facId"
                    src="../../../assets/icons/pcc-icon.svg" alt="" style="width: 34px;
                            height: 17px;
                            margin-top: -4px;">
                <img *ngIf="element.source === 'MatrixCare' " src="../../../assets/icons/matrix.png" alt="" style="height: 12px;
                            width: 20px;  
                            margin-right: 5px;
                            vertical-align: middle;">
                <img *ngIf="element.source != 'PointClickCare' && !element.pcc_facId &&element.source != 'MatrixCare'"
                    class="dn-icon" src="../../../assets/img/DN.png" alt="">
                {{element.title}}
            </td>
        </ng-container>
    
        <ng-container matColumnDef="associated_nurses_name">
            <th mat-header-cell *matHeaderCellDef> Associated Nurses </th>
            <td mat-cell *matCellDef="let element ; let i = index;" style="width: 45%">
                <app-mat-multi-select-chips [element]="element" [ngModelData]="element.associated_snf_wc_nurse_ids || []" [isMulti]="true" [isProvider]="true" [allData]="element.allNurses" [selectAll]="true" [providerKey]="'associated_snf_wc_nurse_ids'" (onDataSelection)="onDataSelection($event)" (onDataRemove)="onDataRemove($event)"></app-mat-multi-select-chips> 
            </td>
        </ng-container>
        <ng-container matColumnDef="template">
            <th mat-header-cell *matHeaderCellDef> Template </th>
            <td mat-cell *matCellDef="let element" style="width: 40%">
                <app-mat-multi-select-chips [element]="element" [ngModelData]="element.template_ids_ref || []" [isMulti]="true" [isTemplate]="true" [allData]="element.allTemp" [selectAll]="true" [providerKey]="'template_ids_ref'" (onDataSelection)="onDataSelection($event)" (onDataRemove)="onDataRemove($event)"></app-mat-multi-select-chips> 
            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
    </table>
</div>
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
