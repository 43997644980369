<div class="checkboxDiv">
    <label class="checkboxLabel">{{ label }}</label>
    <div class="checkboxMain">
        <ng-container *ngFor="let option of options">
            <div style="display: flex; margin-right: 10px; align-items: center; min-width: 120px;">
                <label style="cursor: pointer;">
                    <input id="checkbox-{{ label }}" type="checkbox" [checked]="categorySelected && selectedOptions.includes(option.value)"
                        (change)="selectOption(option.value)"
                        style="margin-right: 5px; width: 20px; height: 20px; cursor: pointer; vertical-align: bottom;">
                    {{ option.value }}
                </label>
            </div>
        </ng-container>
    </div>
</div>