<table  mat-table [dataSource]="dataSource" class="zebra-striped" matSort>
    <ng-container matColumnDef="icd10_checkbox">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            
        </th>
        <td mat-cell *matCellDef="let element">
            <mat-checkbox
            (change)="shouldDisableCheckbox() ? null : toggleSelectICDCode(element.icd10, element.icd10Description);"
            [checked]="!!isICDCodeSelected(element.icd10)"
            [disabled]="shouldDisableCheckbox()">
                {{element.icd10}}
            </mat-checkbox>
        </td>
    </ng-container>

    <ng-container matColumnDef="icd10">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            ICD 10
        </th>
        <td mat-cell *matCellDef="let element" [class.pdpm_highlight]="element.pdpm_status">
            <mat-checkbox 
            *ngIf="showCheckboxes"
            (change)="toggleSelectICDCode(element.icd10, element.icd10Description)"
            [checked]="!!isICDCodeSelected(element.icd10)"
            [disabled]="shouldDisableCheckbox()">
                {{element.icd10}}
            </mat-checkbox>
            <ng-container *ngIf="!showCheckboxes" >
                {{element.icd10}}
            </ng-container>
        </td>
    </ng-container>
    

    <ng-container matColumnDef="icd10Description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            ICD 10 Description
        </th>
        <td mat-cell *matCellDef="let element" [class.pdpm_highlight]="element.pdpm_status">
            {{element.icd10Description}}
        </td>
    </ng-container>
    

    <ng-container matColumnDef="rankDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Rank Description
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.rankDescription}}
        </td>
    </ng-container>
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Date
        </th>
        <td mat-cell *matCellDef="let element" [class.pdpm_highlight]="element.pdpm_status">
            {{element.createdDate | date: 'MM/dd/yyyy'}}
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<br />
<div class="mat-paginator-sticky" *ngIf="apiData" class="flex justify-start items-center gap-2">
    <button mat-raised-button class="dn-primary-button" [disabled]="apiData.paging.page === 1"
        (click)="navigate(-1)">Prev</button>
    <span style="font-size: 12px;">Page {{apiData.paging.page}}</span>
    <button mat-raised-button class="dn-primary-button" [disabled]="!apiData.paging.hasMore"
        (click)="navigate(1)">Next</button>
</div>
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>