import { Component, OnInit, ViewChild } from '@angular/core';
import { UserTypesRelationshipService } from '../../services/user-types-relationship.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import * as global from "src/app/includes/global";
import { CommonService } from 'src/app/services/common.service';

@Component({
    selector: 'app-relationship',
    templateUrl: './relationship.component.html',
    styleUrls: ['./relationship.component.css']
})
export class RelationshipComponent implements OnInit {
    currentCompany : any = {};
    global = global;
    doctorNameSearchQuery: string;
    public doctors: any = [];
    public transcribers: any = [];
    public responseTrans: any;
    public response: any;
    public dataSource: any;

    // public dataSource2: any;
    public billers: any = [];
    public providers: any = [];
    public providersName: any = [];
    selectedProviders: any;
    providerTranscriber: Boolean = true;
    providerBiller: Boolean = true;
    providerProvider: Boolean = true;
    activatedRoute: any;
    activatedRouter: string = 'provider-facility'

    transcriberForm: FormGroup;
    selectedTranscribers: any = [];
    transcriberDropdownSettings: any = {};
    providerDropdownSettings: any = {};
    qaDropdownSettings: any = {};
    qa_eDropdownSettings: any = {};
    qaList = [];
    selectedItems: any = [];
    currentDoctor: string = '';
    swalOptions: any = {};
    company_id: any;
    company_type: string;
    currentUser: any;
    tabIndex = 0;
    label: string = 'View-Only User-Provider';
    selectedTabName: string = 'Practice-Facility';
    company: any;

    public constructor(
        public services: UserTypesRelationshipService,
        private toastr: ToastrService,
        private transcriber: FormBuilder,
        private router: Router,
        private authService: AuthService,
        private _location: Location,
        private companyService: CompanyService,
        private _commonService: CommonService,
        private route: ActivatedRoute
    ) {
        this.currentUser = this.authService.currentUser;
        if (window.location.pathname.includes('company')) {
            this.company_id = this.route.parent.snapshot.params.id
        } else {
            this.company_id = '';
        }
        if (this._commonService.getLocalStorageItem('admin-Dashboard'))
            this.company = this._commonService.getLocalStorageItem('admin-Dashboard');
    }

    displayedColumns: string[] = ['doctor_name', 'transcriber_name', 'trusted_trans', 'qa', 'qa_e'];
    // displayedColumns2: string[] = ['biller_name', 'provider_name' ];
    @ViewChild(MatSort) sort: MatSort;


    async loadData() {
        return new Promise<void>((resovle, reject) => {
            // this.services.getTranscriberList('true').subscribe(data => {
            this.services.getTranscriberList('true', this.company_id).subscribe(data => {
                this.responseTrans = data;

                this.transcribers = this.responseTrans.data;
                //console.log("transcribers: ",this.transcribers)
                this.transcribers.forEach(element => {
                    element['full_name'] = element['first_name'] + ' ' + element['last_name'];
                });

                this.services.getDoctorsWithRelashionship(this.company_id).subscribe(async data => {
                    this.response = data;
                    this.doctors = this.response.data;
                    //
                    this.processDoctorData(this.doctors);
                    this.doctors.forEach(doctor => {
                        // var ids = doctor.assoc_trancriber_id;
                        // if (ids && ids.length > 0) {
                        //     var transcribersList = [];
                        //     for (var i = 0; i < ids.length; i++) {
                        //         var trans = this.transcribers.filter(obj => obj._id == ids[i]);
                        //         if (trans.length > 0) {
                        //             transcribersList.push(trans[0]);
                        //         }
                        //         if (i == (ids.length - 1)) {
                        //             doctor['transcribers'] = transcribersList;
                        //         }
                        //     }
                        // } else {
                        //     doctor['transcribers'] = [];
                        // }
                        doctor.transcribers = [];
                        doctor.only_QA=[];
                        doctor.only_QAE=[];
                        doctor.only_Trusted=[];

                        if (Array.isArray(doctor.assoc_trancriber_id)) {
                            for (const t_id of doctor.assoc_trancriber_id) {
                                const t = this.transcribers.find(transcriber => transcriber._id === t_id);
                                if (t) {
                                    doctor.transcribers.push(t);
                                }
                            }
                            doctor.only_QA=this.getAvailableTranscribersForQA(doctor);
                            doctor.only_QAE=this.getAvailableTranscribersForQAE(doctor);
                            doctor.only_Trusted=this.getAvailableTranscribersForTrustedTrans(doctor);

                        }
                        resovle();
                    });
                    setTimeout(() => {
                        this.dataSource = new MatTableDataSource(this.doctors);
                        console.log('this.doctors',this.doctors);

                    }, 100);

                });

                // https://www.npmjs.com/package/ng-multiselect-dropdown
                this.transcriberDropdownSettings = {
                    singleSelection: false,
                    idField: '_id',
                    textField: 'full_name',
                    selectAllText: 'Select All',
                    unSelectAllText: 'UnSelect All',
                    itemsShowLimit: 10,
                    allowSearchFilter: true,
                    enableCheckAll: true,
                };
                this.qaDropdownSettings = {
                    singleSelection: true,
                    idField: '_id',
                    textField: 'full_name',
                    selectAllText: 'Select All',
                    unSelectAllText: 'UnSelect All',
                    itemsShowLimit: 10,
                    allowSearchFilter: false,
                    enableCheckAll: false,
                };
                this.qa_eDropdownSettings = {
                    singleSelection: true,
                    idField: '_id',
                    textField: 'full_name',
                    selectAllText: 'Select All',
                    unSelectAllText: 'UnSelect All',
                    itemsShowLimit: 10,
                    allowSearchFilter: false,
                    enableCheckAll: false,
                };
            });
        });
    }


    async ngOnInit() {
        if (this.currentUser.product_owner) {
            this.selectedTabName = 'Practice-Facility';
        }else {
            this.selectedTabName = 'Provider-Facility';
        }
        if(this.currentUser.admin_type === global.ADMIN_TYPE.WOUND_NURSE_ADMIN)
        this.selectedTabName = 'Wound-Nurse';
        // this.label = this.currentCompany.currentCompanyType === global.COMPANY_TYPE.SNF ? 'View-Only User-SNF-NURSE' : 'View-Only User-Provider'
        if (this._commonService.getLocalStorageItem('admin-Dashboard')) {
            this.company_type = this._commonService.getLocalStorageItem('admin-Dashboard').currentCompanyType;
            this.currentCompany = this._commonService.getLocalStorageItem('admin-Dashboard');
        }
        if(!this.company_type) {
            await this.getCompanyType();
        }
        await this.loadData();
    }

    getEmailId(element) {
        return element?.email ? element.email : '';
    }
    onTabClick(event) {
        this.tabIndex = event.index;
        this.selectedTabName = event.tab.textLabel;
        // if (this.currentUser.product_owner) {
        //     this.tabIndex--;
        // }
        return;
        if (event?.index == 0) {
            this.tabIndex = 0;
            this.activatedRouter = 'provider-facility'
            this.providerBiller = false;
            this.providerTranscriber = false
        }
        if (event?.index == 1) {
            this.tabIndex = 1;
            this.activatedRouter = 'providers-providers'
            this.providerBiller = false;
            this.providerTranscriber = false;
        }
        if (event?.index == 2) {
            this.tabIndex = 2;
            this.activatedRouter = 'providers-biller'
            this.providerBiller = !this.providerBiller;
            this.providerTranscriber = false;
        }

        // if(event?.index == 4){
        //     this.tabIndex = 4;
        //     this.activatedRouter == 'template-association'
        //     this.providerBiller = false;
        //     this.providerTranscriber = false
        // }
        if (event?.index == 3) {
            this.tabIndex = 3;
            this.activatedRouter = 'phrases-association'
            this.providerBiller = false;
            this.providerTranscriber = false
        }
        if (event?.index == 4) {
            this.tabIndex = 4;
            this.activatedRouter = 'ma-association'
            this.providerTranscriber = false
        }
        if (event?.index == 5) {
            this.tabIndex = 5;
            this.activatedRouter = 'provider-transcriber'
            this.providerTranscriber = !this.providerTranscriber;
            this.providerBiller = false;
        }
        if (event?.index == 6) {
            this.tabIndex = 6;
            this.activatedRouter = 'billing-association'
            this.providerBiller = false;
            this.providerTranscriber = false
        }
        if (event?.index == 7) {
            this.tabIndex = 7;
            this.activatedRouter = 'cpt-icd-mapping'
            this.providerBiller = false;
            this.providerTranscriber = false
        }
        if (event?.index == 8) {
            this.tabIndex = 8;
            this.activatedRouter = 'companies-config'
            this.providerBiller = false;
            this.providerTranscriber = false
        }

    }
    changeRoute() {
        if (this.activatedRouter == 'provider-transcriber') {
            this.providerTranscriber = !this.providerTranscriber;
            this.providerBiller = false;
        }
        else if (this.activatedRouter == 'providers-biller') {
            this.providerBiller = !this.providerBiller;
            this.providerTranscriber = false;
            // this.router.navigateByUrl('/biller-provider-relation')
            // this.dataSource = [];
            // this.loadDataForBiller();
            // this.getBillerRelations();
        }
        else if (this.activatedRouter == 'providers-providers') {
            // this.providerProvider= this.providerProvider;
            this.providerBiller = false;
            this.providerTranscriber = false;
        }
        else if (this.activatedRouter == 'provider-facility') {
            this.providerBiller = false;
            this.providerTranscriber = false
        }
        else if (this.activatedRouter == 'phrases-association') {
            this.providerBiller = false;
            this.providerTranscriber = false
        } else if (this.activatedRouter == 'cpt-icd-mapping') {
            this.providerBiller = false;
            this.providerTranscriber = false
        } else if (this.activatedRouter == 'companies-config') {
            this.providerBiller = false;
            this.providerTranscriber = false
        }
        // else{
        //     this.providerBiller = false;
        //     this.providerTranscriber = false
        // }

    }
    async getCompanyType() {
        const company_id = this.company_id;
        this.companyService.getCompanyName(company_id).subscribe((res: any) => {
        const company = res.data;
        this.company_type = company.company_type;
        this._commonService.setLocalStorageItem('admin-Dashboard', { currentCompanyType: company.company_type, currentCompanyName: company.name, currentCompanyId : company._id , portal_type: company.portal_type});
        this.currentCompany = this._commonService.getLocalStorageItem('admin-Dashboard');
        });
    }
    async processDoctorData(data) {
        let processed = [];
        for (let i = 0; i < data.length; i++) {
            // console.log("data : ", data[i]);
            processed[i] = _.cloneDeep(data[i]);
            if (data[i].qa) {
                processed[i].qaElement = [];
                processed[i].qaElement[0] = processed[i].qa;
            }
            if (data[i].qa_e) {
                processed[i].qa_eElement = [];
                processed[i].qa_eElement[0] = processed[i].qa_e;
            }
            if(data[i].trsuted){
                processed[i].trsutedElement = [];
                processed[i].trsutedElement[0] = processed[i].trsuted;
            }
        }

        this.doctors = processed;
    }


    async changeQA(transcriber, doctor, load=false) {
        this.toastr.info('QA change is in progress', 'Updating...', {
            timeOut: 0
        });

        const params = {
            doctor_id: doctor._id,
            transcriber_id: transcriber
        };
        this.services.changeQA(params).subscribe(
            async data => {

                // location.reload();
                if(!load){
                    await this.loadData();
                    this.toastr.success('QA assigned successfully',
                        'Success', {
                        timeOut: 3000
                    });
                }
            },
            error => {

            }
        );
    }

    async changeTrustedTrans(transcriber, doctor) {
        this.toastr.info('Trusted Transcriber change is in progress', 'Updating...', {
            timeOut: 0
        });

        const params = {
            doctor_id: doctor._id,
            transcriber_id: transcriber
        };
        this.services.changeTrustedTrans(params).subscribe(
            async data => {

                // location.reload();
                await this.loadData();
                this.toastr.success('Trusted Transcriber assigned successfully',
                    'Success', {
                    timeOut: 3000
                });
            },
            error => {

            }
        );
    }
 
    async changeQAE(transcriber, doctor, load=false) {
        this.toastr.info('QA External change is in progress', 'Updating...', {
            timeOut: 0
        });

        const params = {
            doctor_id: doctor._id,
            transcriber_id: transcriber
        };
        this.services.changeQAE(params).subscribe(
            async data => {

                // location.reload();
                if(!load){
                    await this.loadData();
                    this.toastr.success('QA External assigned successfully',
                        'Success', {
                        timeOut: 3000
                    });
                }
            },
            error => {

            }
        );
    }


    async selectTrustedTrans(_id: any, doctor) {
        
        if(_id.toString()==doctor.qa?._id?.toString()){
            console.log("was I-QA before : ", _id?.toString(), doctor.qa?._id?.toString());
            await this.changeQA(null, doctor, true);
        }else if(_id?.toString()==doctor.qa_e?._id?.toString()){
            console.log("was E-QA before : ", _id?.toString(), doctor.qa?._id?.toString());
            await this.changeQAE(null, doctor, true);
        }

        await this.changeTrustedTrans(_id, doctor);
    }

    async removeTrustedTrans(event, doctor) {
        await this.changeTrustedTrans(null, doctor);
    }


    async selectQA(event, doctor) {
        await this.changeQA(event, doctor);
    }

    async removeQA(event, doctor) {
        await this.changeQA(null, doctor);
    }

    async selectQAE(event, doctor) {
        await this.changeQAE(event, doctor);
    }

    async removeQAE(event, doctor) {
        await this.changeQAE(null, doctor);
    }

    onTranscriberSelect(item: any, doctor_id: any) {
        this.currentDoctor = doctor_id;
    }

    onSelectAllTranscriber(items: any, doctor_id: any) {
        this.currentDoctor = doctor_id;
    }

    onTranscriberDeSelect(items: any, doctor_id: any) {
        this.currentDoctor = doctor_id;
    }

    createRelationship(doctor) {
        var transList = [];
        if (doctor.transcribers.length) {
            for (var i = 0; i < doctor.transcribers.length; i++) {
                transList.push(doctor.transcribers[i]._id);
            }
        }
        var updateObject = {
            doctor_id: doctor._id,
            assoc_trancriber_id: transList
        };
        console.log('updateObject',updateObject);
        this.services.createRelationship(updateObject).subscribe(data => {
            this.response = data;
            if (this.response.status == 200) {
                this.toastr.success('Success', this.response.message);
            } else {
                this.toastr.error('Failed', this.response.message);
            }
        });

    }

    createRelationshipAll(doctor) {
        let transList = [];
        transList = this.transcribers.map(t => t._id)
        var updateObject = {
            doctor_id: doctor._id,
            assoc_trancriber_id: transList
        };
        this.services.createRelationship(updateObject).subscribe(data => {
            this.response = data;
            if (this.response.status == 200) {
                this.toastr.success('Success', this.response.message);
            } else {
                this.toastr.error('Failed', this.response.message);
            }
        });
    }

    deSelectAllTranscribers(doctor) {
        let transList = [];
        var updateObject = {
            doctor_id: doctor._id,
            assoc_trancriber_id: transList
        };
        this.services.createRelationship(updateObject).subscribe(data => {
            this.response = data;
            if (this.response.status == 200) {
                this.loadData();
                this.toastr.success('Success', this.response.message);
            } else {
                this.toastr.error('Failed', this.response.message);
            }
        });
    }

    applyFilter(filterValue: string) {
        // this.dataSource.filter = filterValue.trim().toLowerCase();
        let filter = filterValue.trim().toLowerCase();
        if (filter) {
            let filterVal = [];
            this.doctors.forEach(doctor => {
                if (doctor.first_name?.toLowerCase().includes(filter) || doctor.last_name?.toLowerCase().includes(filter)) {
                    filterVal.push(doctor)
                }
            });
            this.dataSource = filterVal;
        } else if (filter == '') {
            this.dataSource = this.doctors;
        }
    }

    getAvailableTranscribersForQA(doctor) {
        const { transcribers, qaElement, qa_eElement, trsutedElement } = doctor;
        let QAI=qaElement?qaElement[0]._id:null;
        let QAE=qa_eElement?qa_eElement[0]._id:null;
        let TT=trsutedElement?trsutedElement[0]._id:null;

        // console.log("transcribers : ", transcribers, QAI, QAE, TT);

        return transcribers.filter((t) => {return !(t._id==TT)});
        // return transcribers.filter((t) => {return !(t._id==TT||t._id==QAE)});

    }
    getAvailableTranscribersForQAE(doctor) {
        const { transcribers, qaElement, qa_eElement, trsutedElement } = doctor;
        let QAI=qaElement?qaElement[0]._id:null;
        let QAE=qa_eElement?qa_eElement[0]._id:null;
        let TT=trsutedElement?trsutedElement[0]._id:null;

        // console.log("transcribers : ", transcribers, QAI, QAE, TT);

        return transcribers.filter((t) => {return !(t._id==TT)});
        // return transcribers.filter((t) => {return !(t._id==QAI||t._id==TT)});

    }
    getAvailableTranscribersForTrustedTrans(doctor) {
        const { transcribers, qaElement, qa_eElement, trsutedElement } = doctor;
        let QAI=qaElement?qaElement[0]._id:null;
        let QAE=qa_eElement?qa_eElement[0]._id:null;
        // console.log("transcribers : ", transcribers, QAI, QAE);
        
        return transcribers.filter((t) => {return !(t._id==QAI||t._id==QAE)});
    }
    goBack() {
        this._location.back();
    }

    onDataSelection(element, type) {
        if(type == 'transcribers'){
            if(element.element.transcribers.length == 0) {
                this.deSelectAllTranscribers(element.element)
            }
            else {
                this.onSelectAllTranscriber(element.element.transcribers, element.element._id);
                this.createRelationshipAll(element.element);
            }
            
        }
        else if(type == 'trsutedElement') {
            // this.selectTrustedTrans()
        }
        else if(type =='') {

        }
    }
    
    onDataRemove($event, type) {
        if(type == 'transcribers'){
            this.onTranscriberDeSelect($event.data, $event.element._id);
            this.createRelationship($event.element);
        }
        else if(type == 'trsutedElement') {

        }
        else if(type =='') {
            
        }
    }


}