<h2 mat-dialog-title>Eligibility Response</h2>
<div mat-dialog-content>
    <div class="status-heading">
        <h1>
            <img
                [src]="eligibilityIcon"
                [alt]="eligibilityStatus"
            />
            {{ eligibilityStatus }}
        </h1>
        <mat-icon
            matTooltip="Eligibility Alerts"
            matTooltipPosition="left"
            (click)="showAlerts()"
            *ngIf="eligibilityAlerts.length > 0"
        >error</mat-icon>
    </div>
    <div class="panels-wrap">
        <div class="panel panel-patient-info">
            <div class="panel-heading">
                <strong>PATIENT</strong> INFORMATION
            </div>
            <div class="panel-content">
                <div class="basic-info-wrap">
                    <label>Patient Name</label>
                    <span>{{ eligibilityData.Subscriber.Name }}</span>
                </div>
                <div class="basic-info-wrap">
                    <label>Date Of Birth</label>
                    <span>{{ eligibilityData.Subscriber.DateOfBirth }}</span>
                </div>
                <div class="basic-info-wrap">
                    <label>Gender</label>
                    <span>{{ resolveGender( eligibilityData.Subscriber.Gender ) }}</span>
                </div>
                <div class="basic-info-wrap">
                    <label>Relationship</label>
                    <span>{{ eligibilityData.Patient.Relationship }}</span>
                </div>
                <div class="basic-info-wrap">
                    <label>Address</label>
                    <span>{{ eligibilityData.extraProps.patientObj.address }}</span>
                </div>
                <div class="basic-info-wrap">
                    <label>Insured</label>
                    <span>{{ eligibilityData.Subscriber.Name }}</span>
                </div>
                <ng-template [ngIf]="eligibilityData.Subscriber.Identifiers != null && eligibilityData.Subscriber.Identifiers.length > 0">
                    <div class="basic-info-wrap" *ngFor="let identifier of eligibilityData.Subscriber.Identifiers">
                        <label>{{ identifier.Description }}</label>
                        <span>{{ identifier.ID }}</span>
                    </div>
                </ng-template>
                <div class="basic-info-wrap">
                    <label>Payer</label>
                    <span>{{ eligibilityData.Source.Name }}</span>
                </div>
            </div>
        </div>
        <div class="panel panel-transaction-summary">
            <div class="panel-heading">
                <strong>TRANSACTION</strong> SUMMARY
            </div>
            <div class="panel-content">
                <div class="basic-info-wrap">
                    <label>Submit Date</label>
                    <span>{{ eligibilityData.extraProps.submitDate }}</span>
                </div>
                <div class="basic-info-wrap">
                    <label>InstaMed Trace #</label>
                    <span>{{ eligibilityData.InstaMedTraceNumber }}</span>
                </div>
                <div class="basic-info-wrap">
                    <label>Customer Trace #</label>
                    <span>{{ eligibilityData.CustomerTraceNumber }}</span>
                </div>
                <div class="basic-info-wrap">
                    <label>Billing Provider Name</label>
                    <span>{{ eligibilityData.Receiver.Name }}</span>
                </div>
                <div class="basic-info-wrap">
                    <label>Billing Provider ID</label>
                    <span>{{ eligibilityData.Receiver.ReceiverID }}</span>
                </div>
            </div>
        </div>
        <div class="panel panel-benefits-summary">
            <div class="panel-heading">
                <strong>BENEFITS</strong> SUMMARY
            </div>
            <div class="panel-content">
                <div class="basic-info-wrap">
                    <label>In / Out of Network</label>
                    <span>{{ eligibilityData.extraProps.selectedInNetwork === true ? 'IN NETWORK' : 'OUT OF NETWORK' }}</span>
                </div>
                <ng-template [ngIf]="selectedServiceType != null">
                    <div class="basic-info-wrap">
                        <label>Service Type</label>
                        <span>{{ selectedServiceType.ServiceTypeDescription }} ({{ selectedServiceType.ServiceTypeCode }})</span>
                    </div>
                    <ng-template [ngIf]="selectedServiceType.CoverageLevels[ 0 ] != null">
                        <div class="basic-info-wrap">
                            <label>Coverage Level</label>
                            <span>{{ selectedServiceType.CoverageLevels[ 0 ].CoverageLevelDescription }}</span>
                        </div>
                        <div class="basic-info-wrap" *ngFor="let benefit of selectedServiceType.CoverageLevels[ 0 ].Benefits">
                            <label>{{ benefit.BenefitDescription }}</label>
                            <span>
                                <span *ngFor="let detail of benefit.Details">
                                    {{ detail }}<br />
                                </span>
                            </span>
                        </div>
                    </ng-template>
                </ng-template>
            </div>
        </div>
        <div class="panel panel-benefit-dates" *ngIf="eligibilityData.BenefitDates != null && eligibilityData.BenefitDates.length > 0">
            <div class="panel-heading">
                <strong>BENEFIT</strong> DATES
            </div>
            <div class="panel-content">
                <div class="basic-info-wrap" *ngFor="let benefitDate of eligibilityData.BenefitDates">
                    <label>{{ benefitDate.Description }} ({{ benefitDate.Code }})</label>
                    <span>
                        {{ benefitDate.BeginDate }}
                        <ng-template [ngIf]="benefitDate.EndDate">
                            - {{ benefitDate.EndDate }}
                        </ng-template>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="panel panel-detailed-benefits" *ngIf="eligibilityData.ServiceTypes.length > 0">
        <div class="panel-heading" (click)="toggleDetailedBenefits()">
            <mat-icon>{{ showDetailedBenefits ? 'remove' : 'add' }}</mat-icon>
            <span><strong>DETAILED</strong> BENEFITS</span>
        </div>
        <div class="panel-content" *ngIf="showDetailedBenefits">
            <div class="service-type-wrap" *ngFor="let serviceType of eligibilityData.ServiceTypes">
                <label>{{ serviceType.ServiceTypeDescription }}</label>
                <table *ngFor="let coverageLevel of serviceType.CoverageLevels">
                    <caption>{{ coverageLevel.CoverageLevelDescription }}</caption>
                    <thead>
                        <tr>
                            <th style="width: 25%;">Benefit</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let benefit of coverageLevel.Benefits">
                            <td>{{ benefit.BenefitDescription }}</td>
                            <td>
                                <span *ngFor="let detail of benefit.Details">
                                    {{ detail }}<br />
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-raised-button class="dn-button-secondary" tabindex="-1" mat-dialog-close>Close</button>
</mat-dialog-actions>