<mat-card>
    <mat-card-content>
        <div class="top-block flex space-between items-center">
            <mat-form-field>
                <mat-select [(ngModel)]="selectUserTypes" (ngModelChange)="ngOnInit()" name="data-set-type" placeholder="User Types">
                    <mat-option *ngFor="let types of allUserList" [value]="types.id">
                        {{types.text}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-raised-button class="dn-button-primary" *ngIf="showButton() && currentUser.admin_type == 'system_admin'" (click)="addColumn()">Add Column</button>
        </div>
    </mat-card-content>
</mat-card>

<div class="flex gap-2 container-dataset" cdkDropList (cdkDropListDropped)="drop($event)" cdkDropListOrientation="horizontal">
    <mat-card *ngFor="let col of columns;let i = index" cdkDrag (cdkDragEnded)="dragEnded($event, false, col)" (cdkDragStarted)="dragStart($event)" class="header-card">
        <mat-card-content>
            <div class="col-header">
                <ng-container *ngIf="col.header_confirmed == false">
                    <mat-form-field class="w-full">
                        <mat-select *ngIf="set == 1" [(ngModel)]="col.header" name="data-set-header" placeholder="Data Set Header">
                            <mat-option [disabled]="dataset.selected === true" *ngFor="let dataset of datasetHeaders" value="{{dataset.header}}">
                                <ng-container>{{dataset.header}}</ng-container>
                            </mat-option>
                        </mat-select>
                        <input matInput *ngIf="set == 2" [(ngModel)]="col.header" type="text" name="{{col.header}}"
                            placeholder="Header" required>
                        <mat-error *ngIf="col.header != ''">Please enter header</mat-error>
                    </mat-form-field>

                    <div class="flex justify-between items-end">
                        <mat-checkbox [(ngModel)]="col.multiselect_enabled">
                            Multi Select
                        </mat-checkbox>

                        <ng-container *ngIf="currentUser.admin_type == 'system_admin'">
                            <button class="save-image" (click)="ConfirmColumnHeader(i)">
                                <img src="assets\icons\save-white.png" />
                            </button>
                        </ng-container>
                    </div>
                </ng-container>

                <div class="header-text" (click)="toggleHeaderState(i)" *ngIf="col.header_confirmed == true">
                    {{col.header}}
                </div>

                <ng-container *ngIf="currentUser.admin_type == 'system_admin'">
                    <button class="close-image" (click)="deleteColumn(i)">
                        <img src="assets\icons\minus.png" />
                    </button>
                </ng-container>
            </div>
                
            <div *ngIf="col.header_confirmed == true">
                <button id="{{col.header_id}}" (click)="getDataSetData(col.header_id)" mat-raised-button class="dn-button-primary w-full">
                    Swap Column with Other Dataset
                </button>
                <div *ngIf="showMatMenu && matMenuLoaderId === col.header_id" [style.top]="divTop" [style.left]="divLeft" class="menuDropdown">
                    <ng-container *ngFor="let item of swapMenuData">
                        <button class="w-full" mat-menu-item (click)="swapColumn(i, item)">{{ item.header }}</button>
                    </ng-container>
                </div>
            </div> 
                
            <div class="col-content">
                <div id="rowDragg" cdkDropList (cdkDropListDropped)="dropRow($event,col)"  cdkDropListOrientation="vertical">
                    <div *ngFor="let rows of col.row;let x = index" cdkDrag (cdkDragEnded)="dragEnded($event,true, col)" (cdkDragStarted)="dragStart($event,true)">
                        <div class="item">
                            <div class="flex justify-between items-center">
                                <div>
                                    <p (click)="toggleRowState(i, x)" *ngIf="rows.row_confirmed == true && rows.data_type != 'Dropdown'">
                                        {{changetoString(i, x)}}
                                    </p>
                                    <mat-chip-list (click)="toggleRowState(i, x)" *ngIf="rows.row_confirmed == true && rows.data_type == 'Dropdown'" cdkDropList cdkDropListOrientation="horizontal">
                                        {{rows.dropdownTitle}}

                                        <mat-chip *ngFor="let data of rows.value">
                                            {{data}}
                                        </mat-chip>
                                    </mat-chip-list>

                                    <mat-form-field *ngIf="rows.row_confirmed == false">
                                        <mat-select name="data-set-type" [(ngModel)]="rows.data_type" [disabled]="disableEditing" (ngModelChange)="changeType(i, x)" required placeholder="Data Types">
                                            <mat-option *ngFor="let types of data_types" [value]="types">
                                                {{types}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field *ngIf="rows.row_confirmed == false && rows.data_type == 'Text'">
                                        <input matInput type="text" [(ngModel)]="rows.value" name="{{rows.value}}" required>
                                    </mat-form-field>

                                    <mat-form-field *ngIf="rows.row_confirmed == false && rows.data_type == 'Date'">
                                        <input matInput [matDatepicker]="picker" (focus)="picker.open()" [min]="minDate"
                                            name="date" placeholder="Date" [(ngModel)]="rows.value" required>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                        <mat-error *ngIf="!date">Please enter date </mat-error>
                                    </mat-form-field>

                                    <mat-form-field *ngIf="rows.row_confirmed == false && rows.data_type == 'Dropdown'">
                                        <input matInput placeholder="Dropdown Title" type="text" [(ngModel)]="rows.dropdownTitle" name="{{rows.dropdownTitle}}" required>
                                    </mat-form-field>

                                    <mat-form-field *ngIf="rows.row_confirmed == false && rows.data_type == 'Dropdown'">
                                        <mat-label>Dropdown Data</mat-label>
                                        <mat-chip-list #chipList aria-label="Data selection">
                                            <mat-chip *ngFor="let value of rows.value"
                                                (removed)="remove(value,i,x)">
                                                {{value}}
                                                <button matChipRemove>
                                                    <mat-icon>cancel</mat-icon>
                                                </button>
                                            </mat-chip>
                                            <input placeholder="New Data..." [matChipInputFor]="chipList"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                (matChipInputTokenEnd)="add($event,i,x)">
                                        </mat-chip-list>
                                    </mat-form-field>
                                </div>

                                <div *ngIf="currentUser.admin_type == 'system_admin' && rows.row_confirmed != false">
                                    <button class="close-image" (click)="deleteRow(i, x)">
                                        <img src="assets/icons/minus.png" />
                                    </button>
                                </div>
                            </div> 

                            <div class="flex justify-end" *ngIf="currentUser.admin_type == 'system_admin' && rows.row_confirmed == false">
                                <button class="save-image" *ngIf="rows.row_confirmed == false" (click)="ConfirmRow(i, x)">
                                    <img src="assets/icons/save-white.png" />
                                </button>
                                <button class="close-image" (click)="deleteRow(i, x)">
                                    <img src="assets/icons/minus.png" />
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <ng-container *ngIf="currentUser.admin_type == 'system_admin'">
                <button class="add-image" *ngIf="col.header_confirmed == true" (click)="addRow(i)">
                    <img src="assets\icons\plus.png" />
                </button>
                <button class="add-image" *ngIf="col.header_confirmed == false" matTooltip="First Confirm Header">
                    <img src="assets\icons\plus-disabled.png" />
                </button>
            </ng-container>

        </mat-card-content>
    </mat-card>

    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

</div>