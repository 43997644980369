<div class="container flex-col">
    <div class="dark-blue-heading flex justify-between items-center">
        <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
            Back
        </button>
        <label style="white-space: nowrap;"> Post Rounds Report V2 </label>
        <div>
            <mat-icon matTooltip="Download Report" style="cursor: pointer;"
                [matMenuTriggerFor]="sendMenu">download</mat-icon>

            <mat-menu #sendMenu="matMenu" xPosition="before">
                <button mat-menu-item (click)="exportDnsHealingReportAsXLSX('download');">Export As XLSX</button>
                <button mat-menu-item (click)="exportDnsHealingReportAsXLSX('email');">Send via email</button>
            </mat-menu>
        </div>
    </div>
    <mat-card class="row-height flex flex-col">

        <div class="flex justify-end items-center gap-1">

            <mat-form-field appearance="fill" class="date-width">
                <mat-label>Select Date Type</mat-label>
                <mat-select (selectionChange)="filter.dateType = $event.value;" [value]="filter.dateType">
                    <mat-option value="wound_creation_date">Created On</mat-option>
                    <mat-option value="updatedAt">Updated On</mat-option>
                    <mat-option value="last_assessment_date_obj">Date Of Service</mat-option>
                </mat-select>
            </mat-form-field>

            <input style="width: 180px;" type="text" ngxDaterangepickerMd [(ngModel)]="filter.updationDate"
                (datesUpdated)="changeUpdationDate($event)" class="mat-like-input" [ranges]="preDateRanges"
                [alwaysShowCalendars]="true" [maxDate]="currentDate" [linkedCalendars]="true"
                [showClearButton]="true" />


                <mat-form-field appearance="fill" class="w-1/2 sm:w-full md:w-full">
                    <mat-label>Select Facility</mat-label>
                <input id="facilityControlInput" type="text" placeholder="Select Facility" aria-label="facility"
                    matInput [formControl]="facilityControl" [matAutocomplete]="facilityAutoControl">
                <mat-autocomplete #facilityAutoControl="matAutocomplete" autoActiveFirstOption>
                    <mat-option (onSelectionChange)="onSelectFacility($event, facility);"
                        *ngFor="let facility of filteredFacilities | async" [value]="facility.title">
                        {{facility.title}}
                    </mat-option>
                </mat-autocomplete>

            </mat-form-field>

            <mat-form-field style="width: 95px;">
                <mat-label>Select State</mat-label>
                <mat-select [value]="filter.state" (selectionChange)="onStateChange($event.value)">
                    <mat-option value="all">All</mat-option>
                    <mat-option value="active">Active</mat-option>
                    <mat-option value="remission">Remission</mat-option>
                    <mat-option value="amputated">Amputated</mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-raised-button class="dn-button-primary" (click)="handleApplyFilter();">Apply</button>

            <button mat-raised-button class="dn-button-secondary" (click)="resetFilter();">Reset</button>

        </div>
    </mat-card>

    <mat-card class="table-height flex flex-col flex-grow">

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

            <ng-container matColumnDef="room_number">
                <th mat-header-cell *matHeaderCellDef> Room # </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.patient_id.last_room_num}}
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Last Name, First Name </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.patient_last_name}},
                    {{element.patient_first_name}}
                </td>
            </ng-container>

            <ng-container matColumnDef="body_part">
                <th mat-header-cell *matHeaderCellDef> Wound Location </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.body_part}}
                </td>
            </ng-container>

            <ng-container matColumnDef="etiolgy">
                <th mat-header-cell *matHeaderCellDef> Etiology </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.etiolgy}}
                </td>
            </ng-container>

            <ng-container matColumnDef="stage">
                <th mat-header-cell *matHeaderCellDef> Stage/Severity </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.stage}}
                </td>
            </ng-container>

            <ng-container matColumnDef="last_admit_date_obj">
                <th mat-header-cell *matHeaderCellDef> Facility Admission Date </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.patient_id.last_admit_date_obj}}
                </td>
            </ng-container>
            <ng-container matColumnDef="latest_evaluation_date">
                <th mat-header-cell *matHeaderCellDef> Latest Evaluation Date </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.last_assessment_date}}
                </td>
            </ng-container>

            <ng-container matColumnDef="length">
                <th mat-header-cell *matHeaderCellDef> Length </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.length}} cm
                </td>
            </ng-container>

            <ng-container matColumnDef="width">
                <th mat-header-cell *matHeaderCellDef> Width </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.width}} cm
                </td>
            </ng-container>

            <ng-container matColumnDef="depth">
                <th mat-header-cell *matHeaderCellDef> Depth </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.depth}} cm
                </td>
            </ng-container>

            <ng-container matColumnDef="wound_status">
                <th mat-header-cell *matHeaderCellDef> Wound Status </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.wound_status}}
                </td>
            </ng-container>

            <ng-container matColumnDef="wound_recognition_date">
                <th mat-header-cell *matHeaderCellDef> Date Wound Acquired </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.wound_recognition_date}}
                </td>
            </ng-container>

            <ng-container matColumnDef="wound_recognition_date_type">
                <th mat-header-cell *matHeaderCellDef> In House ACQ? </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.wound_recognition_date_type}}
                </td>
            </ng-container>
            <ng-container matColumnDef="dressing_change_frequency">
                <th mat-header-cell *matHeaderCellDef> Dressing Change Frequency </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.dressing_change_frequency}}
                </td>
            </ng-container>

            <ng-container matColumnDef="cleanse_wound_with">
                <th mat-header-cell *matHeaderCellDef> Cleanse Wound With </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.cleanse_wound_with}}
                </td>
            </ng-container>

            <ng-container matColumnDef="primary_treatment">
                <th mat-header-cell *matHeaderCellDef> Primary Treatment </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.primary_treatment}}
                </td>
            </ng-container>

            <ng-container matColumnDef="secondary_dressings">
                <th mat-header-cell *matHeaderCellDef> Secondary Dressings </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.secondary_dressings}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
        <br>

        <div class="flex justify-between items-center"style="margin-bottom: 0px;">
            <b>POA: {{poaCount}}</b>
            <b>Facility Acquired: {{facilityAcquiredCount}}</b>
            <b>Open: {{openWoundsCount}}</b>
            <b>In Remission: {{inRemissionWoundsCount}}</b>
            <div>
                <button mat-raised-button class="dn-button-primary" (click)="goToPreviousPage()"
                    [disabled]="!pagination.hasPrevPage"> Prev</button>
                <span>
                    {{pagination.skip + dnsReport.length === 0 ? 0 : pagination.skip + 1 }} - {{pagination.skip
                    +
                    dnsReport.length}} out of {{pagination.totalRecords}}
                </span>
                <button mat-raised-button class="dn-button-primary" (click)="goToNextPage()"
                    [disabled]="!pagination.hasNextPage">Next</button>
            </div>
        </div>
    </mat-card>
</div>