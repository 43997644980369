import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../../../includes/global';
@Component({
  selector: 'app-manage-cpts-icds-settings',
  templateUrl: './manage-cpts-icds-settings.component.html',
  styleUrls: ['./manage-cpts-icds-settings.component.css']
})
export class ManageCptsIcdsSettingsComponent implements OnInit {
  tabIndex = 0;
  activatedRouter: string = 'manage-cpt-codes'
  currentUser;
  global = global;

  constructor(private _authService: AuthService) {
    this.currentUser = this._authService.currentUser;
   }

  ngOnInit(): void {
  }
  onTabClick(event) {
    if (event.index == 0) {
      this.tabIndex = 0;
      this.activatedRouter = 'icds'
    }
    else if (event.index == 1) {
      this.tabIndex = 1;
      this.activatedRouter = 'ie-fu-mapping'
    }
    else if (event.index == 2) { // if current user is not super admin but only company admin, then only this option is visible on index 0
      this.tabIndex = 2;
      this.activatedRouter = 'manage-cpt-codes'
    }
    else if (event.index == 3) {
      this.tabIndex = 3;
      this.activatedRouter = 'cpt-pos'
    }
    else if (event.index == 4) {
      this.tabIndex = 4;
      this.activatedRouter = 'cpts'
    }
  }
}
