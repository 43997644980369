import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FacilityService } from 'src/app/services/facility.service';
import { UserService } from 'src/app/services/Modules/user.service';
import { USER_TYPE } from '../global';

@Component({
  selector: 'app-nurse-facility-association',
  templateUrl: 'nurse-facility-association.component.html',
  styleUrls: ['nurse-facility-association.component.css']
})
export class NurseFacilityAssociationComponent implements OnInit {
  public response: any;
  public dataSource: any;
  public facilities: any = [];
  providers: any = [];
  providersName: any = [];
  selectedProviders: any = [];
  nurseDropdownSettings: any = {};
  companySide: Boolean = false;
  displayedColumns: string[] = ['facility', 'nurse'];
  @ViewChild(MatSort) sort: MatSort;
  searchProviderText: any = [];
  params: {
    id: string
  }
  filterStatus = true;
  loaderId = 'nurse-facility-association';

  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalFacilities: 0,
    totalPages: 0,
    currentRecords: 0
  };
  facilityTitleRegExp = "";
  NurseTitleRegExp = "";
  PAGE_LIMIT = 20;
  timeoutId: any;
  hasFacilitiesLoaded: boolean = false;
  hasNurseLoaded: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService,
    private facilityService: FacilityService,
    private userService: UserService,
    private router: Router
  ) {
    this.nurseDropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'full_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: true,
    };
    if (window.location.pathname.includes('company')) {
      this.companySide = true
      this.params = {
        id: this.route.parent.snapshot.params.id
      };
    }
    else {
      this.companySide = false
      this.params = {
        id: ''
      };
    }
  }

  async ngOnInit(): Promise<void> {
    this.searchProviderText = Array(this.PAGE_LIMIT).fill('');
    this.loader.startLoader(this.loaderId);
    await this.initProviders();
    await this.initAssociationData()
    if (this.facilities.length > 1) {
        this.updatePageCountForFirstPage();
    }
    this.loader.stopLoader(this.loaderId);
  }
  initProviders = async () => {
    const company_id = this.params.id;

    const filter = {
      is_activated: 'true',
      user_type: USER_TYPE.NURSE,
      company_id
    }
    const projection = {
      'account_type': 1,
      'first_name': 1,
      'last_name': 1,
      'title': 1,
    }
    const companyUsersResponse: any = await this.userService.getUsers(filter, projection).toPromise();
    if (companyUsersResponse.status === 200) {
      this.providers = companyUsersResponse.data.map(p => Object.assign(p, {
        full_name: p.account_type === 'company' ?
          `${p.first_name} ${p.last_name}, ${p.title} (company owner)` :
          `${p.first_name} ${p.last_name}, ${p.title}`
      }));
    }
  }
  searchFacilities() {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.hasFacilitiesLoaded = true;
      this.initAssociationData();
    }, 1000);
  }
  searchNurses() {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.hasNurseLoaded = true;
      this.initAssociationData();
    }, 1000);
  }
  applyActiveFilter(event) {
    this.filterStatus = event.value;
    this.initAssociationData();
  }
  applyFilter(filterValue: string) {
    let filter = filterValue.trim().toLowerCase();
    if (filter) {
      let filterVal: any= [];
      this.facilities.forEach(facility => {
        if (facility.title.trim().toLowerCase().includes(filter)) {
          filterVal.push(facility)
          this.dataSource = new MatTableDataSource(filterVal);
        }
      });
    } else {
      this.dataSource = this.facilities;
    }
  }
  onDataSelection($event) {
    // let providers = element.associated_provider_ids.map(a => a._id);
    // let idx = this.users.findIndex(user => user._id.toString == element._id.toString());
    // this.users[idx] = element;
    // this.dataSource.data = this.users;
    if($event.data == 'selectAll') {

      this.onSelectAllNurse($event.element.nurse_ids_ref, $event.element);

    }
    else if($event.data == 'unSelect') {
      this.onDeSelectAllNurse([], $event.element);
    }
    else {
      if($event.status) {
        this.onNurseSelect($event.data, $event.element);
      }
      else {
        this.onNurseDeSelect($event.data, $event.element);
      }
    }
  }

  onDataRemove($event) {
    this.onNurseDeSelect($event.data, $event.element);
  }
  
  onNurseSelect(nurse, facility) {
    // if(nurse[0] === 'selectAll') {
    //   facility.nurse_ids_ref = facility.nurseDropDown;
    //   nurse.shift();
    // }
    // else if(nurse[0] === 'unSelect') {
    //   facility.nurse_ids_ref = [];
    //   nurse.shift();
    // }
    this.updateFacilityNurse(facility);
  }

  onSelectAllNurse(nurses, facility) {
    return this.facilityService.updtaeFacilityNurseAssociation(facility._id, nurses.map(p => p._id)).toPromise();
  }
 
  onDeSelectAllNurse(nurses, facility) {
    return this.facilityService.updtaeFacilityNurseAssociation(facility._id, nurses.map(p => p._id)).toPromise();
  }

  onNurseDeSelect(nurse, facility) {
    facility.nurse_ids_ref = facility.nurse_ids_ref.filter(user => user._id != nurse._id) 
    this.updateFacilityNurse(facility);
  }
  async initAssociationData() {
    this.loader.startLoader(this.loaderId);
    const company_id = this.params.id;

    const filter: any = {
      //is_active: null,
      is_active: this.filterStatus,
      company_id,
      pageNo: this.pagination.pageNo
    };
    if (this.facilityTitleRegExp !== "") {
      filter.title = this.facilityTitleRegExp;
      delete filter.pageNo;
    }
    if (this.NurseTitleRegExp !== "") {
      filter.nurseTitle = this.NurseTitleRegExp;
      delete filter.pageNo;
    }
    const projection = {
      'title': 1,
      'address': 1,
      'city': 1,
      'state': 1,
      'zip_code': 1,
      'nurse_ids_ref': 1
    }
    let result: any = await this.facilityService.getAllFacilitiesProviderTemplateAssoc(filter, projection).toPromise();
    if (result.status == 200) {
      this.pagination.totalFacilities = result.data.totalFacilities;
      this.pagination.currentRecords = result.data.facilities.length;
      this.facilities = result.data.facilities;
      this.facilities.map(ele => {
        ele.nurse_ids_ref.map(temp => {
          temp.full_name = temp.first_name + " " + temp.last_name + ", " + temp.title;
          return temp;
        });
        return ele;
      });
      this.facilities.map(facility => {
        facility.nurseDropDown = this.providers;
      })
      if (filter.title) { //update page count for title search
        this.pagination.currentRecords = this.pagination.totalFacilities;
        this.pagination.hasNextPage = false;
        this.pagination.hasPrevPage = false;
      }
      else if (filter.pageNo) {
        this.updatePageCountForFirstPage();
      }
      this.dataSource = new MatTableDataSource(this.facilities);
      this.loader.stopLoader(this.loaderId);
      this.hasFacilitiesLoaded = false;
      this.hasNurseLoaded = false;
    }
    else if (result.status == 403) {
      this.toastr.error(result.message, "ERROR");
      this.router.navigate(['/403']);
    }
  }
  updateFacilityNurse(facility) {
    const { nurse_ids_ref } = facility;
    return this.facilityService.updtaeFacilityNurseAssociation(facility._id, nurse_ids_ref.map(p => p._id)).toPromise();
  }
  updatePageCountForFirstPage() {
    if (this.pagination.currentRecords < this.pagination.totalFacilities) {
      this.pagination.hasNextPage = true;
      this.pagination.currentRecords = (this.pagination.pageNo - 1) * this.PAGE_LIMIT + this.facilities.length;
    }
  }
  async goToPreviousPage() {
    if (this.pagination.pageNo > 1) {
      this.pagination.pageNo = this.pagination.pageNo - 1;
      this.pagination.currentRecords = (this.pagination.pageNo - 1) * this.PAGE_LIMIT + this.facilities.length;
      await this.initAssociationData();
    }
    if (this.pagination.pageNo === 1) {
      this.pagination.hasPrevPage = false;
    }
    this.pagination.hasNextPage = true;
  }
  async goToNextPage() {
    this.pagination.pageNo = this.pagination.pageNo + 1;
    this.pagination.currentRecords = (this.pagination.pageNo - 1) * this.PAGE_LIMIT + this.facilities.length;
    if (this.pagination.currentRecords > this.pagination.totalFacilities) {  //Page count should not exceed total limit
      this.pagination.currentRecords = this.pagination.currentRecords - (this.pagination.currentRecords - this.pagination.totalFacilities);
    }
    if (this.pagination.currentRecords === this.pagination.totalFacilities) {
      this.pagination.hasNextPage = false;
    }
    await this.initAssociationData();
    this.pagination.hasPrevPage = true;
  }

  compareNurses(nurse1: any, nurse2: any): boolean {
    return nurse1 && nurse2 ? nurse1._id === nurse2._id : nurse1 === nurse2;
  }
}
