import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment'
import { ToastrService } from 'ngx-toastr';
import { Observable, lastValueFrom } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FacilityService } from 'src/app/services/facility.service';
import { Location } from '@angular/common';
import { WoundService } from 'src/app/services/wound.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { BillerDashboardService } from 'src/app/includes/biller-dashboard/biller-dashboard.service';
import * as global from '../../../../../global';
import { SnfWcNurseAssociationService } from 'src/app/includes/snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';
import { NursingService } from 'src/app/includes/nursing-module/nursing.service';
import { WoundNurseRelationService } from 'src/app/services/Modules/woundNurse.service';
import ExcelJS from 'exceljs';
import { flatten } from 'flat';
import { CommonService } from 'src/app/services/common.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-post-rounds-report',
  templateUrl: './post-rounds-report.component.html',
  styleUrls: ['./post-rounds-report.component.css']
})
export class PostRoundsReportComponent implements OnInit {

  currentDate = moment(new Date());
  minDate = moment(new Date()).subtract(6, 'days');
  loaderId = 'post-rounds-report';
  filter: any = {
    updationDate: {
      startDate: moment(),
      endDate: moment()
    },
    dateType: 'last_assessment_date_obj',
    facilityId: null,
    companyId: null,
    state: 'all'
  };
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 1 Week': [moment().subtract(6, 'days'), moment()]
  };
  filteredFacilities: Observable<string[]>;
  displayedColumns: string[] = [
    'room_number', // Room #
    'name', // Last Name, First Name
    'body_part', // Wound Location
    'etiolgy', // Etiology
    'stage', // Severity
    'last_admit_date_obj', // Days in Tx
    'last_assessment_date', // Latest Evaluation Date
    'length', // Lenth
    'width', // Width
    'depth', // Depth
    'wound_status',
    'wound_recognition_date_type', // Present on Admission
    'wound_recognition_date', // Date Acquired
    'change_frequency', // Dressing Change Frequency
    'cleanse_wound_with', // Cleanse Wound With
    'primary_treatment',
    'secondary_dressing', // Secondary Dressings
  ];
  facilityControl = new FormControl();
  facilities: Array<any> = [];
  postRoundsReport: any = [];
  dataSource: any = new MatTableDataSource([]);
  poaCount: number = 0;
  facilityAcquiredCount: number = 0;
  openWoundsCount: number = 0;
  inRemissionWoundsCount: number = 0;
  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    PAGE_LIMIT: 50,
    skip: 0
  };
  searchText: any;
  isCompanySide: boolean = false;
  currentUser: any;
  base_url: string;
  nursingSide: boolean;
  user_dashboard: Boolean = false;
  facilityInputText: any;
  shouldShowTable: boolean = false;
  isSnfNurse: boolean = false;
  isNurse: boolean = false;
  isWoundNurse: boolean = false;
  nurseAssociatedFacilities: any = [];
  selectedFacility: any;
  slicedReport: any = [];
  constructor(
    private router: Router,
    private _toastr: ToastrService,
    private _facilityService: FacilityService,
    private _route: ActivatedRoute,
    private _location: Location,
    private _wound: WoundService,
    private loader: NgxUiLoaderService,
    private _authService: AuthService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
    private _nurseService: NursingService,
    private woundNurseService: WoundNurseRelationService,
    private _commonService: CommonService,
  ) {
    this.currentUser = this._authService.currentUser;
    this.filter.companyId = this._route.parent.snapshot.params.id;
    if (this._route.parent.snapshot.params.id) {
      this.isCompanySide = true;
    } else {
      this.filter.companyId = this.currentUser.company_id;
    }
    if (window.location.pathname.includes('dashboard')) {
      this.user_dashboard = true;
    }
    if (window.location.pathname.includes('nursing')) {
      this.nursingSide = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      this.isSnfNurse = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.NURSE) {
      this.isNurse = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
      this.isWoundNurse = true;
    }
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId);
    await this.initFacilities();
    this.selectDefaultFacility();
    this.filteredFacilities = this.facilityControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => this.filterFacilities(value)));
    this.loader.stopLoader(this.loaderId);
  }
  private filterFacilities(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) > -1);
    }
    else {
      return this.facilities;
    }
  }
  selectDefaultFacility() {
    if (this.facilities.length > 0) {
      this.selectedFacility = this.facilities[0];
    }
    this.filter.facilityId = this.selectedFacility._id;
    // this.facilityControl.setValue(this.facilities[0].title);
  }

  async initPostRoundsReport(shouldDownload: boolean = false) {
    // this.loader.startLoader(this.loaderId);
    const infoToastr = this._toastr.info('Fetching Data...', "Please Wait");
    if(!shouldDownload) {
      this.pagination.pageNo = 1;
    }
    // delete this.filter.companyId;
    this.filter.pageNo = this.pagination.pageNo;
    delete this.filter.exportOption;
    const response: any = await lastValueFrom(this._wound.getPostRoundsReport(this.filter));
    if (response.status == 200) {
      this.postRoundsReport = response.data.report;
      this.pagination.totalRecords = response.data.totalRecords;
      if (this.postRoundsReport.length > 0) {
        this.postRoundsReport.sort((a, b) => {
          if (a.patient_id && b.patient_id) {
            return a.patient_id.full_name.toLowerCase().localeCompare(b.patient_id.full_name.toLowerCase());
        }
        });
      }
      const skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
      const limit = this.pagination.PAGE_LIMIT;
      this.slicedReport = this.postRoundsReport.slice(skip, skip + limit);
      if (!shouldDownload) {
        this.shouldShowTable = true;
        this.handlePagination();
        this.countValues();
        this.dataSource.data = this.slicedReport;
      }
      else {
        this._toastr.clear(infoToastr.toastId);
        this.convertToXLSX(this.postRoundsReport);
      }
    }
    else if (response.status === 504) {
      this._toastr.clear(infoToastr.toastId);
      this._toastr.error("Gateway Timeout");
    }
    else if (response.data.status === 500 || response.status === 500) {
      this._toastr.clear(infoToastr.toastId);
      this._toastr.error("Something went wrong");
    }
    this._toastr.clear(infoToastr.toastId);
    // this.loader.stopLoader(this.loaderId);
  }

  countValues() {
    [this.inRemissionWoundsCount, this.openWoundsCount,
    this.poaCount, this.facilityAcquiredCount] = [0, 0, 0, 0];
    for (const report of this.postRoundsReport) {
      if (report.wound_recognition_date_type === "yes") {
        this.facilityAcquiredCount++;
      }
      else {
        this.poaCount++;
      }
      if (report.state === "active") {
        this.openWoundsCount++;
      }
      else if (report.state === "remission") {
        this.inRemissionWoundsCount++;
      }
    }
  }

  handlePagination() {
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    if (this.pagination.skip + this.slicedReport.length < this.pagination.totalRecords) {
      this.pagination.hasNextPage = true;
    }
    else {
      this.pagination.hasNextPage = false;
    }
    if (this.pagination.skip > 0) {
      this.pagination.hasPrevPage = true;
    }
    else {
      this.pagination.hasPrevPage = false;
    }
  }

  async initFacilities() {
    if (this.isCompanySide) {
      const filter = {
        is_active: true,
        assoc_company_ids: this._route.parent.snapshot.params.id
      };
      const project = {
        _id: 1,
        title: 1,
        pcc_facId: 1,
        pcc_orgUuid: 1,
        company_id: 1,
        assoc_company_ids: 1,
        source: 1,
        provider_ids_ref: 1
      };
      const facilitiesResponse: any = await lastValueFrom(this._facilityService.getFacilities(filter, project));
      if (facilitiesResponse.status === 200) {
        const { array: facilities } = facilitiesResponse.data;
        if (facilities.length > 0)
          this.facilities = facilitiesResponse.data.array.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
      }
    }
    else if (this.isSnfNurse) {
      const filter = {
        associated_snf_wc_nurse_ids: this.currentUser._id,
        company_id: this._authService.currentUser.company_id
      };
      const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      const facilityProjection = {
        title: 1,
        pcc_facId: 1,
        pcc_2_legged_authentication: 1,
        source: 1
      };
      const response: any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
      if (response.status === 200 && response.data.length > 0) {
        this.facilities = response.data.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
      }
    }
    else if (this.isNurse) {
      const response: any = await lastValueFrom(this._nurseService.getAssociatedFacilities());
      if (response.status === 200 && response.data.array.length > 0) {
        this.facilities = response.data.array.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
      }
    } else if (this.isWoundNurse) {
      const filter = {
        company_id_ref: this._authService.currentUser.company_id,
        associated_wound_nurse_ids: this._authService.currentUser._id
      };
      const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      const facilityProjection = {
        title: 1,
        pcc_facId: 1,
        pcc_2_legged_authentication: 1,
        source: 1
      };
      const facilitiesResponse: any = await lastValueFrom(this.woundNurseService.getWoundNurseFacilities(filter, nurseProjection, facilityProjection));
      if (facilitiesResponse.status === 200) {
        this.facilities = facilitiesResponse.data.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
      }
    }
    else if (this.currentUser.user_type === global.USER_TYPE.DOCTOR) {
      const filter = {
        is_active: true,
        provider_ids_ref: this.currentUser._id,
        assoc_company_ids: this.currentUser.company_id
      };
      const project = {
        _id: 1,
        title: 1,
        pcc_facId: 1,
        pcc_orgUuid: 1,
        source: 1,
        provider_ids_ref: 1
      };
      const facilitiesResponse: any = await lastValueFrom(this._facilityService.getFacilities(filter, project));
      if (facilitiesResponse.status == 200 && facilitiesResponse.data.array.length > 0) {
        this.facilities = facilitiesResponse.data.array.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
      }
    }
  }

  sortTitleVise(arrayTosort) {
    let facilities = arrayTosort;
    let result = facilities.sort(function (a, b) {
      if (a.title.toLowerCase() < b.title?.toLowerCase()) { return -1; }
      if (a.title.toLowerCase() > b.title?.toLowerCase()) { return 1; }
      return 0;
    })
    return result;
  }

  onSelectFacility($event, facility) {
    if ($event.isUserInput) {
      this.selectedFacility = facility;
      this.filter.facilityId = this.selectedFacility._id;
    }
  }

  onChangeDate(event) {
    if (event.startDate && event.endDate) {
      event = JSON.parse(JSON.stringify(event));
      const noOfDays = this._commonService.getDifferenceOfDates(event.startDate,event.endDate,'days');
      if (noOfDays > 6) {
        this.filter.updationDate = {};
        this.filter.updationDate.startDate = moment(event.startDate);
        this.filter.updationDate.endDate = moment(this.filter.updationDate.startDate).add(6, "days").endOf('day');
        this._toastr.error('Can not select more than 7 days', 'Date Error');
      }
      else {
        this.filter.updationDate.startDate = moment(event.startDate);
        this.filter.updationDate.endDate = moment(event.endDate);
      }
    }
  }

  goBack() {
    this._location.back();
  }

  calculateArea(ele) {
    if (ele.earliestRevisionArea && ele.recentWound) {
      return 100 - ((ele.recentWound.recentRevisionArea / ele.earliestRevisionArea) / 100);
    }
    return ele.earliestRevisionArea;
  }

  onStateChange(value) {
    this.filter.state = value;
  }

  async resetFilter() {
    delete this.filter.updationDate;
    const defaultDate = {
      startDate: moment(),
      endDate: moment()
    };
    this.filter.updationDate = defaultDate;
    this.filter.state = "all";
    this.filter.dateType = 'last_assessment_date_obj';
    this.selectDefaultFacility();
    await this.initPostRoundsReport();
  }

  async exportPostRoundsReport(exportOption: string) {
    delete this.filter.pageNo;
    this.filter.exportOption = exportOption;
    this.filter.facilityName = this.selectedFacility.title;
    const message = exportOption === "xlsx" ? "XLSX" : "PDF";
    const infoToastr = this._toastr.info(`Exporting as ${message}...`, 'Please wait', { disableTimeOut: true });
    const response: any = await lastValueFrom(this._wound.exportPostRoundsReport(this.filter));
    if (response instanceof HttpResponse && exportOption === "pdf") {
      const url = window.URL.createObjectURL(response.body);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = `Post Rounds Report V2.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
      this._toastr.clear(infoToastr.toastId);
    }
    else if (exportOption === "xlsx") {
      this._toastr.clear(infoToastr.toastId);
      this._toastr.success(`Report has been sent to your email`, "Success");
    }
    else {
      this._toastr.clear(infoToastr.toastId);
      this._toastr.error(response.message, 'Error');
    }
  }

  async convertToXLSX(wounds) {
    const infoToastr = this._toastr.info('Fetching Data...', "Please Wait");
    const columnsData = [
      { header: "Room #", key: "patient_id.last_room_num", width: 11 + 0.70 },
      { header: "Last Name, First Name", key: "patient_id.full_name", width: 20 + 0.70 },
      { header: "Location", key: "body_part", width: 10.86 + 0.70 },
      { header: "Etiology", key: "etiolgy", width: 10.86 + 0.70 },
      { header: "Stage / Severity", key: "stage", width: 14.71 + 0.70 },
      { header: "Facility Admission Date", key: "patient_id.last_admit_date_obj", width: 14.57 + 0.70 },
      { header: "Latest Evaluation Date", key: "last_assessment_date", width: 14.29 + 0.70 },
      { header: "L", key: "length", width: 5.29 + 0.70 },
      { header: "W", key: "width", width: 5.43 + 0.70 },
      { header: "D", key: "depth", width: 5.14 + 0.70 },
      { header: "Status", key: "wound_status", width: 15.14 + 0.70 },
      { header: "In House ACQ?", key: "wound_recognition_date_type", width: 9.43 + 0.70 },
      { header: "Date Wound Acquired", key: "wound_recognition_date", width: 11.86 + 0.70 },
      { header: 'Dressing Change Frequency', key: 'change_frequency', width: 11.43 + 0.70 },
      { header: 'Cleanse Wound With', key: 'cleanse_wound_with', width: 15.71 + 0.70 },
      { header: 'Primary Treatment', key: 'primary_treatment', width: 12 + 0.70 },
      { header: 'Secondary Dressings', key: 'secondary_dressing', width: 9.71 + 0.70 },
    ];
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Post Rounds Report V2");
    worksheet.pageSetup.orientation = 'landscape';
    worksheet.pageSetup.horizontalCentered = true;
    worksheet.pageSetup.verticalCentered = true;
    worksheet.columns = columnsData;
    worksheet.insertRow(0, []);
    const startDate = this._commonService.convertDateToStringforMoment(moment(this.filter.updationDate.startDate));
    const endDate = this._commonService.convertDateToStringforMoment(moment(this.filter.updationDate.endDate));
    const cell = worksheet.getCell("A1");
    cell.value = `${startDate.split("-")[1]}/${startDate.split("-")[2]}/${startDate.split("-")[0]} - ${endDate.split("-")[1]}/${endDate.split("-")[2]}/${endDate.split("-")[0]}`;
    cell.value = this.selectedFacility.title;
    cell.font = { bold: true };
    worksheet.mergeCells('A1:F1');
    cell.alignment = { horizontal: 'center' };
    const cell3 = worksheet.getCell("N1");
    cell3.value = `${startDate.split("-")[1]}/${startDate.split("-")[2]}/${startDate.split("-")[0]} - ${endDate.split("-")[1]}/${endDate.split("-")[2]}/${endDate.split("-")[0]}`;
    cell3.font = { bold: true };
    worksheet.mergeCells('N1:Q1');
    cell3.alignment = { horizontal: 'center' };
    const cell2 = worksheet.getCell("G1");
    cell2.value = "Wound Report";
    cell2.font = { bold: true };
    worksheet.mergeCells('G1:M1');
    cell2.alignment = { horizontal: 'center' }
    for (const wound of wounds) {
      worksheet.addRow(flatten(wound));
    }
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true };
    });
    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.alignment = { wrapText: true };
      })
    })
    worksheet.pageSetup.showGridLines = true;
    worksheet.pageSetup.fitToPage = true;
    worksheet.pageSetup.fitToWidth = 1;
    worksheet.pageSetup.fitToHeight = 0;
    worksheet.pageSetup.printTitlesRow = '1:1';
    worksheet.autoFilter = {
      from: 'A2',
      to: 'Q2',
    };
    let headerRow2 = worksheet.getRow(1);
    headerRow2.eachCell(function (cell) {
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    })
    let headerRow = worksheet.getRow(2);
    headerRow.eachCell(function (cell) {
      cell.font = { bold: true };
      cell.alignment = { vertical: 'middle', wrapText: true };
    });
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Post Rounds Report V2.xlsx';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    this._toastr.clear(infoToastr.toastId);
  }

  handlePageNavigation(shouldgoBack: boolean) {
    shouldgoBack ? this.pagination.pageNo-- : this.pagination.pageNo++;
    const skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    const limit = this.pagination.PAGE_LIMIT;
    this.slicedReport = this.postRoundsReport.slice(skip, skip + limit);
    this.handlePagination();
    this.dataSource.data = [...this.slicedReport];
  }

}
