import {Component,OnInit} from '@angular/core';
import {CodeVerificationService} from './code-verification.service';
import {ToastrService} from 'ngx-toastr';
import {LoginService} from '../login/login.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as global from '../global';
import { CompanyLogoAndMongogramService } from 'src/app/services/company-logo-and-mongogram.service';
import { ForgetPasswordService } from '../forgot-password/forgot-password.service';


@Component({
    selector: 'app-code-verification',
    templateUrl: './code-verification.component.html',
    styleUrls: ['./code-verification.component.css']
})

export class CodeVerificationComponent implements OnInit {
    email: string = null;
    verificationcode: string = null;
    status: string = null;
    password: string = "";
    confirm_password: string = "";
    passwordError: string = "";
    passwordStrength: number = 0;
    companyCode: any;
    defaultImg: string;
    companylogoPath: any;
    companylogoPathSubscription: any;
    error: string;
    public timerInterval: any;
    display: any;
    policyUpperCase: boolean = false;
    policyLowerCase: boolean = false;
    policyNumber: boolean = false;
    policySpecialCharacter: boolean = false;
    policyLength: boolean = false;
    hide = true;
    verifyHide = true;
    disableButton: boolean = false;
    constructor(
        private _codeVerification: CodeVerificationService,
        private _toastr: ToastrService,
        private _loginService: LoginService,
        private _router: Router,
        private route: ActivatedRoute,
        public loginService: LoginService,
        private companyLogoAndMonogramService: CompanyLogoAndMongogramService,
        private _forgetPasswordService: ForgetPasswordService,

    ) {
        
    } 

    async ngOnInit() {
        // localStorage.removeItem('currentUser');
    let forgetPasswordCredientials = JSON.parse(localStorage.getItem('forgetPasswordCredientials'));
    localStorage.removeItem('forgetPasswordCredientials');
    if(!forgetPasswordCredientials){
      this._router.navigate(['/forgot-password']);
    }
    this.email = forgetPasswordCredientials.email;
    if(this.route.snapshot.params.company_code){
        this.companyCode = this.route.snapshot.params.company_code;
        console.log('this.companyCode',this.companyCode);
        await this.getCompanyLogo();
    }else{
        this.defaultImg = "../../../assets/img/DNlogo-new.png";
    }
    this.timer(2);

    }

    async getCompanyLogo(){
        this.companylogoPathSubscription = this.companyLogoAndMonogramService.castCompanyMonogram.subscribe((res:any)=>{
            if(res){
              this.companylogoPath = res;
            }else{
              this.defaultImg = "../../../assets/img/DNlogo-new.png";
            }
        });
    }

    ngOnDestroy(): void {
        if(this.companylogoPathSubscription){
          this.companylogoPathSubscription.unsubscribe();
        }
    }

    navigateToLogin(){
        if(this.companyCode){
            const code = this.companyCode;
            this._router.navigate([`/login/${code}`]);
        }else{
            this._router.navigate([`/login`]);
        }
    }

    onCodeVerification(codeVerificationForm) {
        if (!codeVerificationForm.valid || !codeVerificationForm.touched) return;

        const {verificationcode} = codeVerificationForm.value;

        if (!verificationcode) return;

        const details = {
            email: this.email,
            verificationcode: String(verificationcode)
        };

        const infoToastr = this._toastr.info('Verifying code...', 'Please wait', {disableTimeOut: true});
        this._codeVerification.checkPasswordResetCode(details).subscribe((response: any) => {
            this.status = response.status;
            if (response.status === 200) {
                this._toastr.success(response.message, 'Success');
                this.verificationcode = String(verificationcode);
            } else {
                this._toastr.error(response.message, 'Failed');
            }
        }, console.error, () => this._toastr.clear(infoToastr.toastId))
    }

    clearErrors() {
      this.error = '';
    }
    
    onResetPassword(resetPasswordForm, codeVerificationForm) {
        
        if (this.display =='00:00') {
            this._toastr.error('Verification Code Expire, Please Click on Resend', 'Error');
            return;
        }
        let check = this.checkPassword(this.password);
        if (check) {
            // if (this.passwordStrength < 3) resetPasswordForm.controls['password'].setErrors({'incorrect': true})
            if (!codeVerificationForm.valid || !codeVerificationForm.touched) {
                this.error = '\nPlease enter verification code';
                return;
            }
            const { verificationcode } = codeVerificationForm.value;
            if (!verificationcode) {
                this.error = '\nPlease enter verification code';
                return;
            }
            if (this.password !== this.confirm_password) {
                this.error = "\nConfirm Password does not match\n";
                resetPasswordForm.controls['confirm_password'].setErrors({ 'incorrect': true })
            }
            if (!resetPasswordForm.valid || !resetPasswordForm.touched) return;
            else {
                this.error = '';
                const details = {
                    email: this.email,
                    verificationCode: String(verificationcode)
                };
                const infoToastr = this._toastr.info('Reseting password...', 'Please wait', { disableTimeOut: true });
                // encrypt the password first
                this._loginService.getEncryptPassword(this.password).subscribe((passResponse: any) => {
                    details['password'] = passResponse.encreptedPass; // change plain password with encrypted
                    this._codeVerification.checkPasswordResetCodeAndRestPassword(details).subscribe((response: any) => {
                        this.status = response.status;
                        if (response.status === 200) {
                            this._toastr.success(response.message, 'Success');
                            if (this.companyCode) {
                                const code = this.companyCode;
                                this._router.navigate([`/login/${code}`]);
                            } else {
                                this._router.navigate([`/login`]);
                            }
                        } else {
                            this._toastr.error(response.message, 'Failed');
                        }
                    }, console.error, () => this._toastr.clear(infoToastr.toastId))

                }, (error) => {
                    this._toastr.clear(infoToastr.toastId)
                    console.error(error);
                });
            }

        }
    }

    strengthChange(passwordStrength) {
        this.passwordStrength = passwordStrength;
    }

    isPasswordValid() {
        this.checkPassword(this.password);
        if (this.password != this.confirm_password) {
            this.passwordError = "Confirm Password does not match";
        } else if (this.passwordStrength < 3) {
            const strengthMsg: any = ["Password strength is low", "Password strength low", "Password should not contain common words"];
            this.passwordError = strengthMsg[String(this.passwordStrength)];
        } else {
            this.passwordError = "";
        }
    }

    checkPassword(myString) {
        let minLength = true;
        var specials = /[^A-Za-z 0-9]/g;
        let hasSpecial = specials.test(myString);
        let hasNumber = /\d/.test(myString);
        let hasUpper = /[A-Z]/.test(myString);
        let hasLower = /[a-z]/.test(myString);
        let passwordLength = myString.length;
        if (passwordLength < 10) {
          minLength = false;
        }
        const valid = hasNumber && hasUpper && hasLower && hasSpecial && minLength;
        if (!valid) {
          this.error = '\n';
          if (!hasUpper) {
            this.error += 'Password should have atleast one Uppercase Letter\n';
            this.policyUpperCase = false;
          } else if (hasUpper) {
            this.policyUpperCase = true;
          }
          if (!hasLower) {
            this.error += 'Password should have atleast one Lowercase Letter\n';
            this.policyLowerCase = false;
          } else if (hasLower) {
            this.policyLowerCase = true;
          }
          if (!hasNumber) {
            this.error += 'Password should have atleast one number\n';
            this.policyNumber = false;
          } else if (hasNumber) {
            this.policyNumber = true;
          }
          if (!hasSpecial) {
            this.error += 'Password should have atleast one Special character\n';
            this.policySpecialCharacter = false
          } else if (hasSpecial) {
            this.policySpecialCharacter = true
          }
          if (!minLength) {
            this.error += 'Password Lenght should be greater than 10\n';
            this.policyLength = false;
          } else if (minLength) {
            this.policyLength = true;
          }
        //   this.enableConfirmPassword = false;
        //   this.enableResetButton=false;
          return false;
        }
        this.policyUpperCase = true;
        this.policyLowerCase = true;
        this.policyNumber = true;
        this.policySpecialCharacter = true
        this.policyLength = true;
        // this.enableConfirmPassword = true
        return true;
      }

    resendConfirmationCode(){
      this.disableButton = true;
        const details = {email: this.email};
        const infoToastr = this._toastr.info('Sending password reset code...', 'Please wait', { disableTimeOut: true })
        this._forgetPasswordService.sendPasswordResetCode(details).subscribe((res: any) => {
            if (res.status === 200) {
              this._toastr.clear(infoToastr.toastId);
              this._toastr.success("Reset Code Sent", "Success");
              this.timer(2);
            } else {
              this._toastr.clear(infoToastr.toastId);
              this._toastr.error(res.message, 'Failed');
            }
          });
      }
    
      timer(minute) {
        // let minute = 1;
        let seconds: number = minute * 60;
        let textSec: any = '0';
        let statSec: number = 60;
    
        const prefix = minute < 10 ? '0' : '';
    
        this.timerInterval = setInterval(() => {
          seconds--;
          if (statSec != 0) statSec--;
          else statSec = 59;
    
          if (statSec < 10) {
            textSec = '0' + statSec;
          } else textSec = statSec;
    
          this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
          if(this.display.includes('-')){
            this.display = '00:00';
            clearInterval(this.timerInterval);
          }
          if (seconds <= 0) {
            if(this.display !== '00:00'){
              this.display = '00:00';
            }
            // console.log('finished');
            clearInterval(this.timerInterval);
          }
          this.disableButton = false;
        }, 1000);
      }
}
