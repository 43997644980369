import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment'
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FacilityService } from 'src/app/services/facility.service';
import { Location } from '@angular/common';
import { WoundService } from 'src/app/services/wound.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as global from 'src/app/includes/global';
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { UserService } from 'src/app/services/Modules/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/Modules/company.service';

@Component({
  selector: 'app-admin-charges-report',
  templateUrl: './admin-charges-report.component.html',
  styleUrls: ['./admin-charges-report.component.css']
})
export class AdminChargesReportComponent implements OnInit {

  loaderId = 'app-charges-report';
  companyID: '';
  companies = [];
  selectedCompany: any;
  companyName = "";
  filter: any = {
    facilityId: null,
    providerId: null,
    companyId: null,
    reportType: 'monthly',
    selectedMonth: '',
    reportStatus: 'submit'
  };

  facilityControl = new FormControl();
  providerControl = new FormControl();
  providers: Array<any> = [];
  filteredProviders: Observable<string[]>;
  companyId: any;
  chargesReport: any = [];
  companySide: boolean;
  currentUser: any;
  monthName = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");
  reportType = 'monthly';
  user_dashboard: Boolean = false;

  constructor(
    private _toastr: ToastrService,
    private _route: ActivatedRoute,
    private _location: Location,
    private _wound: WoundService,
    private loader: NgxUiLoaderService,
    private _userService: UserService,
    private _authService: AuthService,
    private companyService: CompanyService
  ) {
    this.currentUser = this._authService.currentUser;
    this.filter.companyId = this._route.parent.snapshot.params.id;
    if (window.location.pathname.includes('dashboard')) {
      this.user_dashboard = true;
    }
    if (this._route.parent.snapshot.params.id) {
      this.companySide = true;
      this.companyID = this._route.parent.snapshot.params.id
    } else {
      this.filter.companyId = this.currentUser.company_id;
      this.companyID = this.currentUser.company_id;
    }
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId);
    var d = new Date();
    d.setDate(1);
    for (let i = 0; i < 12; i++) {
      this.monthName[d.getMonth()] = this.monthName[d.getMonth()] + ' ' + d.getFullYear();
      d.setMonth(d.getMonth() - 1);
    }

    this.monthName = this.monthName.sort((a: any, b: any) => {
      let aa: any = a.split(" ");
      let bb: any = b.split(" ");
      return aa[1] - bb[1] || aa[0] - bb[0];
    })

    this.filter.selectedMonth = this.monthName[0];
    if (this.currentUser.product_owner) {
      let res: any = await this.companyService.getAllCompanies().toPromise();
      if (res.status == 200) {
        this.companies = res.data
        this.filter.company_id = this.companies[0]._id;
        // this.model.company_id = this.companies[0]._id;
        this.selectedCompany = this.companies[0];
        this.getProviderList();
      }
    }
    else {
      let res: any = await this.companyService.getCompanyName(this.companyID).toPromise();
      if (res.status == 200) {
        this.companyName = res.data.name;
        this.selectedCompany = res.data;
        this.filter.company_id = this.companyID;
        this.getProviderList();
      }
    }

  }

  private filterProvider(value: string): string[] {
    if (value && this.providers.length > 0) {
      const filterValue = value.toLowerCase();
      // return this.providers.filter(option => (option.middle_name && option.middle_name.toLowerCase().indexOf(filterValue) !== -1) || option.last_name.toLowerCase().indexOf(filterValue) !== -1 || option.first_name.toLowerCase().indexOf(filterValue) !== -1);
      return this.providers.filter(option => option.full_name.toLowerCase().match(filterValue));
    }
    else {
      return this.providers;
    }
  }

  getProviderList() {
    const filter: any = {
      company_id: this.filter.companyId || this.filter.company_id,
      user_type: global.USER_TYPE.DOCTOR
    };
    const projection = {
      first_name: 1,
      last_name: 1,
      full_name: 1,
      title:1
    };
    return new Promise<void>((resolve, reject) => {
      this._userService.getUsers(filter, projection).subscribe((response: any) => {
        if (response.status === 200) {
          this.providers = response.data;
          this.providers.unshift({ _id: 'all', full_name: 'All' });
          this.selectDefaultProvider();
          this.showProviderDropdown();
          // this.loader.stopLoader(this.loaderId);
          resolve(null);
        }
      }, error => {
        reject('error => ' + error.toString());
      });
    });

  }

  onSelectProvider(event) {
    // console.log("Provider event",event)
    if (event === 'all') {
      this.filter.providerId = null;
    }
    else {
      this.filter.providerId = event;
    }
  }
  showProviderDropdown(): void {
    this.filteredProviders = this.providerControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => this.filterProvider(value)));
  }

  selectDefaultProvider() {
    if (this.providers.length > 0) {
      this.filter.providerId = this.providers[0]._id;
      this.initChargesReport()
      this.providerControl.setValue(this.providers[0].full_name);
    }
  }

  providerExists: boolean = false;
  keys: any = [];
  keys2: any = [];
  initChargesReport() {
    this.loader.startLoader(this.loaderId);
    this.keys = [];
    this.keys2 = [];
    this.chargesReport = [];
    this.reportType = this.filter.reportType;
    if (this.filter.reportType == 'monthly') {
      this.filter.startDate = new Date();
      var date: any = new Date();
      date = new Date(date.getFullYear(), date.getMonth() - 11 + 1, 1);
      // date = date.setMonth(-11);
      this.filter.endDate = date;

    }
    if (this.filter.providerId == 'all' || this.filter.providerId == null) {
      this.filter.providers = this.providers;
    }
    this._wound.getAdminChargesReport(this.filter).subscribe((response: any) => {
      if (response.status == 200) {
        this.providerExists = response.providerExists;
        this.chargesReport = response.reportData;
        // console.log("data", this.chargesReport);

        this.keys = Object.keys(this.chargesReport);

        if (response.providerExists) {
          if (this.keys.length > 0) {
            for (let key of this.keys) {
              this.keys2.push(Object.keys(this.chargesReport[key]));

            }
          }

        }

        // console.log("2nd keys", this.keys2)

        this.loader.stopLoader(this.loaderId);

        // this.dataSource.data = this.visitReport;

      }
      else {
        this.loader.stopLoader(this.loaderId);

      }
    }, err => {
      this.loader.stopLoader(this.loaderId);
      this._toastr.error(err, "ERROR");
    }
    );
  }

  getTotalEncounter(data, type) {
    let total = 0;
    if (type == 'monthly') {
      for (let obj of data) {
        total = total + obj.encounter
      }
      return total;
    }
    else if (!this.providerExists && type == 'daily') {
      for (let key of this.keys) {
        for (let data of this.chargesReport[key]) {
          total = total + data.encounter;
        }
      }
      return total;
    }
    else if (this.providerExists && type == 'daily') {
      for (let key in data) {
        for (let obj of data[key]) {
          total = total + obj.encounter;
        }
      }
      return total;
    }
  }

  applyCompanyFilter(event) {
    this.filter.companyId = event.value;
    this.getProviderList();
  }



  goBack() {
    this._location.back();
  }

  handleApplyFilter() {
    this.initChargesReport();
  }

  resetFilter() {
    this.filter.reportType = 'monthly';
    this.filter.reportStatus = 'submit';
    this.filter.selectedMonth = this.monthName[0];
    this.filter.company_id = this.companies[0]._id;
    this.filter.companyId = this.companies[0]._id;
    this.getProviderList()
  }

  exportChargesReportAsXLSX() {
    const infoToastr = this._toastr.info('Exporting as XLSX...', 'Please wait', { disableTimeOut: true });
    let idx = this.companies.findIndex((company) => { return company._id.toString() === this.filter.companyId.toString() });
    this.filter.companyName = this.companies[idx].name;
    if (!this.providerExists) {
      idx = this.providers.findIndex((provider) => { return provider._id.toString() === this.filter.providerId.toString() });
      this.filter.providerName = this.providers[idx].full_name;
    }
    else {
      this.filter.providerName = this.providers[0]._id;
    }

    this._wound.exportChargesReportAsXLSX(this.filter, this.chargesReport).subscribe((response: any) => {
      if (response instanceof HttpResponse) {
        const url = window.URL.createObjectURL(response.body);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `${this.filter.reportType}_${this.filter.companyName}_${this.filter.providerName}`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
        this._toastr.clear(infoToastr.toastId);
      }
    }, error => {
      // console.log("err", error);
      // this._toastr.clear(infoToastr.toastId);
    });
  }

}
