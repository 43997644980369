<div class="flex flex-col flex-grow">
  <div class="dark-blue-heading">
    <div class="flex justify-start items-center gap-2">
      <i (click)="upOneLevel()" *ngIf="historyStack.length > 0 && filesLoaded" class="icon icon-back"></i>
      <h3 class="header-top-margin">{{header_title}}
        <ng-container *ngFor="let folderItem of historyStack">
          > {{folderItem.title}}
        </ng-container> 
      </h3>
    </div>

    <div class="file-controls-bar">
      <button mat-mini-fab matTooltip="Reload" (click)="reload()" aria-label="icon-button"
        style="margin-right:10px;transition: none !important;box-shadow: none !important; background:none !important;">
        <mat-icon>refresh</mat-icon>
      </button>
      <button mat-mini-fab matTooltip="Download" *ngIf="selectedItem && selectedItem['file_path']"
        (click)="downloadFile(selectedItem)" aria-label="icon-button"
        style="margin-right:10px;transition: none !important;box-shadow: none !important; background:none !important;">
        <mat-icon>cloud_download</mat-icon>
      </button>
      <button mat-mini-fab matTooltip="Rename" *ngIf="selectedItem && !selectedItem['file_path']"
        (click)="onRenameItemClick(selectedItem)" aria-label="icon-button"
        style="margin-right:10px;transition: none !important;box-shadow: none !important; background:none !important;">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-mini-fab matTooltip="Share" *ngIf="selectedItem" (click)="onShareItemClick(selectedItem)"
        aria-label="icon-button"
        style="margin-right:10px;transition: none !important;box-shadow: none !important; background:none !important;">
        <mat-icon>person_add</mat-icon>
      </button>
      <button mat-mini-fab matTooltip="Details" *ngIf="selectedItem" (click)="showFileSidebar=!showFileSidebar"
        aria-label="icon-button"
        style="margin-right:10px;transition: none !important;box-shadow: none !important; background:none !important;">
        <mat-icon>info</mat-icon>
      </button>


      <button mat-mini-fab matTooltip="View as List" *ngIf="view_type !== 'LIST'" (click)="toggleFilesView()"
        aria-label="icon-button"
        style="margin-right:10px;transition: none !important;box-shadow: none !important; background:none !important;">
        <mat-icon>view_list</mat-icon>
      </button>
      <button mat-mini-fab matTooltip="View as Grid" *ngIf="view_type !== 'GRID'" (click)="toggleFilesView()"
        aria-label="icon-button"
        style="margin-right:10px;transition: none !important;box-shadow: none !important; background:none !important;">
        <mat-icon>grid_on</mat-icon>
      </button>

      <button mat-mini-fab matTooltip="Help"
        aria-label="icon-button"
        style="transition: none !important;box-shadow: none !important; background:none !important;"
        [matMenuTriggerFor]="helpMenu"
      >
        <mat-icon>help</mat-icon>
      </button>
      <mat-menu #helpMenu="matMenu" xPosition="before">
        <button mat-menu-item (click)="watchHelpVideo('system-phrases')">Watch System Phrases</button>
        <button mat-menu-item (click)="watchHelpVideo('template-headings')">Watch Template Headings</button>
        <button mat-menu-item (click)="watchHelpVideo('template-guide')">Watch Template Guide</button>
      </mat-menu>
    </div>
  </div>




  <div class="file-container-sidebar-wrapper dismis-file-selection flex justify-evenly items-stretch flex-grow">
    <div class="file-container dismis-file-selection flex-grow" #fileContainer
      (click)="onFileContainerClick($event)">
      <ngx-file-drop *ngIf="!isSharedFileExplorer" [showBrowseBtn]="false" multiple="true"
        (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
      </ngx-file-drop>
      <ng-container *ngIf="filesLoaded">
        <div *ngIf="!isEmpty() && view_type === 'GRID'" class="dismis-file-selection flex flex-wrap"
          gutterSize="15px" id="files-grid">
          <ng-container *ngFor="let item of allFilesAndFolders" class="dismis-file-selection">
            <div class="file-folder-item-wrapper dismis-file-selection flex justify-center items-start">
              <div [id]="item._id" class="flex flex-col">
                <ng-container *ngIf="item.file_path">
                  <div class="flex flex-col justify-center items-center" (dblclick)="onFileDoubleClick(item)"
                    (click)="onFileClick(item)" (contextmenu)="onItemContextMenu($event, item)"
                    [ngClass]="{'file-folder-item':true, 'file-item':true, selected:item.selected}"
                    [ngStyle]="{'opacity': navigating ? '.6' : '1' }">
                    <span class="item-icon">
                      <img [src]="getItemIcon(item)" onerror="this.src='../../../assets/img/filetypes/unknown.svg'" />
                    </span>
                    <span class="item-title">
                      {{item.title.length > 20 ? ((item.title | slice:0:20) + '...') : item.title}}
                    </span>
                  </div>
                </ng-container>

                <ng-container *ngIf="!item.file_path">
                  <div class="flex flex-col justify-center items-center" (dblclick)="onFolderDoubleClick(item)"
                    (click)="onFolderClick(item, $event)" (contextmenu)="onItemContextMenu($event, item)"
                    [ngClass]="{'file-folder-item':true, 'folder-item':true, selected: item.selected}"
                    [ngStyle]="{'opacity': navigating && (historyStackTop && historyStackTop._id !== item._id) ? '.6' : '1' }">
                    <span class="item-icon">
                      <img src="../../../assets/img/folder-icon.png" />
                    </span>
                    <span class="item-title">
                      {{item.title.length > 20 ? ((item.title | slice:0:20) + '...') : item.title}}
                    </span>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
        <div style="max-height: 70%; overflow: auto;">
          <table *ngIf="view_type === 'LIST'" mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)"
            matSort>
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
              <td mat-cell *matCellDef="let element">
                <div [id]="element._id">
                  <div class="file-folder-item flex justify-start items-center gap-2">
                    <span class="item-icon">
                      <img *ngIf="element" [src]="getItemIcon(element)"
                        onerror="this.src='../../../assets/img/filetypes/unknown.svg'" />
                      <mat-icon *ngIf="!ownedItem(element) && !element.file_path" class="shared-item-icon" matSuffix>
                        person</mat-icon>
                    </span>
                    <div>{{element.title}}</div>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="owner_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Uploaded By </th>
              <td mat-cell *matCellDef="let element">
                {{element.owner_name}}
              </td>
            </ng-container>
            <ng-container matColumnDef="creation_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Created on </th>
              <td mat-cell *matCellDef="let element">
                {{formatDate(element.creation_date) || formatDate(element.created_at)}}
              </td>
            </ng-container>
            <ng-container matColumnDef="updation_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Last updated on </th>
              <td mat-cell *matCellDef="let element">
                {{formatDate(element.updation_date) || formatDate(element.updated_at)}}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
              [ngClass]="{'file-folder-item':true, 'file-item':row.file_path, 'folder-item':!row.file_path, selected:row.selected}"
              (dblclick)="row.file_path ? onFileDoubleClick(row) : onFolderDoubleClick(row)"
              style="max-height: 100px !important;" (click)="row.file_path ? onFileClick(row) : onFolderClick(row)"
              (contextmenu)="onItemContextMenu($event, row)"></tr>
          </table>
        </div>
        <div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
          [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu" #contextMenuTrigger="matMenuTrigger">
        </div>


        <mat-menu #contextMenu="matMenu">
          <ng-template matMenuContent let-item="item">
            <button mat-menu-item (click)="onShareItemClick(item)" *ngIf="ownedItem(item)" autofocus>Share</button>
            <button mat-menu-item (click)="onRenameItemClick(item)"
              *ngIf="ownedItem(item) && !item.file_path">Rename</button>
            <button mat-menu-item (click)="onDownloadFileClick(item)" *ngIf="item.file_path" download>Download</button>
            <button mat-menu-item (click)="onDeleteItemClick(item)" *ngIf="ownedItem(item)">Delete</button>
          </ng-template>
        </mat-menu>

        <div class="empty-folder-view flex flex-col justify-center items-center" gIf="isEmpty()">
          <img src="../../../assets/img/empty-fodler-icon.svg" />
          <div>No files or folders</div>
        </div>
      </ng-container>
    </div>
    <div class="pdf-file" style="max-height: 70%;">
      <ngx-doc-viewer *ngIf="selectedItem" [url]="file_path" viewer="google" style="width:100%;height: 100%;">
      </ngx-doc-viewer>
    </div>
    <div [ngClass]="{'file-sidebar-wrapper':true, 'file-sidebar-active': selectedItem && showFileSidebar}">

      <div class="file-sidebar flex flex-col" *ngIf="selectedItem && showFileSidebar">
        <div class="file-sidebar-details-item flex flex-col justify-center items-center">
          <img [src]="getItemIcon(selectedItem)" style="height: 80px;"
            onerror="this.src='../../../assets/img/filetypes/unknown.svg'" />
          <p style="color:#000; text-align: center; word-break: break-word;">{{selectedItem.title}}</p>
        </div>
        <mat-tab-group>
          <mat-tab label="Details">
            <div class="file-sidebar-details-content flex flex-col gap-5">
              <div class="file-sidebar-details-item flex">
                <div class="file-sidebar-item-label">Type</div>
                <div class="file-sidebar-item-content">{{selectedItem.file_path ? 'File' : 'Folder'}}</div>
              </div>
              <div class="file-sidebar-details-item flex justify-start items-center"
                *ngIf="ownedItem(selectedItem)">
                <div class="file-sidebar-item-label">Shared with</div>
                <div class="file-sidebar-item-content flex flex-wrap gap-1">
                  <ng-container *ngIf="!selectedItem.shared_by_email.length">
                    None
                  </ng-container>
                  <span class="circle-badge" *ngFor="let email of selectedItem.shared_by_email" [matTooltip]="email">
                    {{email | slice:0:1 | uppercase}}
                  </span>
                </div>
              </div>
              <div class="file-sidebar-details-item flex">
                <div class="file-sidebar-item-label">Owner</div>
                <div class="file-sidebar-item-content">{{selectedItem.owner_id === currentUser._id ? 'Me' :
                  selectedItem.owner_name}}</div>
              </div>
              <div class="file-sidebar-details-item flex">
                <div class="file-sidebar-item-label">Created on</div>
                <div class="file-sidebar-item-content">
                  {{selectedItem.creation_date}}</div>
              </div>
              <div class="file-sidebar-details-item flex">
                <div class="file-sidebar-item-label">Last modified on</div>
                <div class="file-sidebar-item-content">
                  {{selectedItem.updation_date}}</div>
              </div>
              <div class="file-sidebar-details-item item-description flex">
                <div *ngIf="!ownedItem(selectedItem)">{{selectedItem.description}}</div>
                <div class="flex gap-2 justify-evenly items-center grow"
                  *ngIf="ownedItem(selectedItem)">
                  <textarea #itemDescription [(ngModel)]="selectedItem.description"
                    (blur)="updateItemDescription(selectedItem, itemDescription.value)" class="flex-grow"
                    placeholder="Add a description" spellcheck="false" [disabled]="!isEditingDescription"></textarea>
                  <button mat-mini-fab>
                    <i *ngIf="!isEditingDescription" (click)="isEditingDescription=true;itemDescription.focus()"
                      class="material-icons">edit</i>
                    <i *ngIf="isEditingDescription" (click)="updateItemDescription(selectedItem, itemDescription.value)"
                      class="material-icons">done</i>
                  </button>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Activity">
            <div class="file-sidebar-activities flex flex-col gap-5">
              <ng-container *ngIf="selectedItem.drive_activity">
                <div *ngFor="let drive_activity of selectedItem.drive_activity" class="file-sidebar-activities-item">
                  <div class="activity_date_time">{{drive_activity.date_time}}</div>
                  <div [innerHTML]="drive_activity.activity_description" class="activity_description"></div>
                </div>
              </ng-container>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>