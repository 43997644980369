<div class="flex justify-start items-between gap-5">
    <div class="flex justify-start items-center w-full">
        <mat-card class="search-card flex-grow">
            <mat-spinner *ngIf="hasBillerLoaded" style="margin-left: auto;" matSuffix [diameter]="18"></mat-spinner>
        </mat-card>
    </div>
</div>
<div style="height: 10px;"></div>
<table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="biller">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Biller</th>
        <td mat-cell *matCellDef="let element" style="width: 15%">
            {{element.first_name}} {{element.last_name}}
        </td>
    </ng-container>

    <ng-container matColumnDef="companies">
        <th mat-header-cell *matHeaderCellDef> Practices </th>
        <td mat-cell *matCellDef="let element" style="width: 42%">
                <mat-form-field style="width: 100%">
                    <mat-select [ngModel]="element.associated_company_ids" [multiple]="true" [compareWith]="compareCompanies">
                        <mat-select-trigger>
                            <mat-chip-list>
                              <mat-chip *ngFor="let company of element.associated_company_ids" style="margin-right: 5px;" [removable]="true" (removed)="onCompanyDeSelect(company, element)">
                                {{ company.name }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                              </mat-chip>
                            </mat-chip-list>
                          </mat-select-trigger>
                      <mat-option *ngFor="let company of companies" [value]="company" (onSelectionChange)="onCompanySelect($event, element)">{{ company.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>