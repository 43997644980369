import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { TemplatesService } from './templates.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

export interface Template {
  noteTitle_ids: string;
  _id?: string;
  // parent_folder_id:string;
  title: string;
  template_text: string;
  creation_date: Date | string;
  creation_date_string: string;
  updation_date: Date | string;
  updation_date_string: string;
  facility_id: string;
  facility_ids?: Array<string>;
  owner_id?: string;
  is_deleted: string;
  note_builder_id: string;
  is_active?: Boolean;
  assoc_cpt_ids? : any;
  template_type? : string;
  hidden_for? : any;
  for_note_type?: any;
}

// const sample = {
//   "template_title":"1566891878134_Doctor_1_Test_Aug_27_2019_12_44_pm",
//   "template_text":"8NL/iH0kQqSvAjk3uF0xq1dX7G47794lq4Tr7v9KR6w=",
//   "creation_date":"2019-08-27T07:44:38.134Z",
//   "updation_date":"2019-08-27T07:44:38.134Z",
//   "owner_id":"5d4d2b834ae7d048c0b0d346",
//   "is_deleted":"false"
// }

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css']
})
export class TemplatesComponent implements OnInit {
  loading: boolean = false;
  templates: Template[] = [];

  currentUser: any;
  displayedColumns: string[] = [];
  dataSource: any;


  constructor(
    private _templatesService: TemplatesService,
    private _changeDetectorRefs: ChangeDetectorRef,
    private _router: Router,
    private _authService: AuthService

  ) {
    // this.currentUser = localStorage.getItem('currentUser');
    this.currentUser = this._authService.currentUser;
  }

  @ViewChild(MatSort) sort: MatSort;

  async ngOnInit() {
    // this.currentUser = JSON.parse(this.currentUser);
    this.currentUser = this.currentUser;
    if (!this.currentUser) {
      // if not login, stop and redirect to login #mohsin-dev
      localStorage.removeItem('currentUser');
      this._router.navigate(['/login']);
      return;
    }

    this.loading = true;
    const templatesResponse: any = await this._templatesService.getMyTemplates().toPromise();
    if (templatesResponse.status === 200) {
      this.displayedColumns = ['title', 'creation_date', 'updation_date', 'operations'];

      this.templates = templatesResponse.data;
      this.dataSource = new MatTableDataSource(this.templates.map((t: any, i: number) => { t.serial_no = (i + 1); return t }));
      this.dataSource.sort = this.sort;
    }
    this.loading = false;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.filterPredicate = function (data, filter: string): boolean {
      return data.template_title.toLowerCase().includes(filter);
    };
  }
  editTemplate(template: Template) {
    localStorage.setItem('template', JSON.stringify(template));
    this._router.navigate(['/template-create']);
  }
  deleteTemplate(template: Template) {
    if (confirm("Are you sure you want to permanently delete this template?")) {
      template.is_deleted = 'true';
      this._templatesService.deleteTemplate(template).subscribe((response: any) => {
        this.templates = this.templates.filter(t => t._id !== template._id);
        this.dataSource = new MatTableDataSource(this.templates);
        this._changeDetectorRefs.detectChanges();
      });

    }
  }
}
