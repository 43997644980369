import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { ProfileService } from '../profile/profile.service';
import { UserManagementService } from '../user-management/user-management.service';
import { startWith, map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { RolesDialog } from 'src/app/shared/popup-dialogs/roles-dialog/roles.dialog';
import * as global from '../../includes/global';
import { HttpResponse } from '@angular/common/http';
import moment from 'moment'
import { CommonService } from 'src/app/services/common.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DialogConfirmationComponent } from '../census-patient-list/dialog-confirmation/dialog-confirmation.component';

@Component({
  selector: 'app-manager-list',
  templateUrl: './manager-list.component.html',
  styleUrls: ['./manager-list.component.css']
})
export class ManagerListComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['first_name', 'last_name', 'email', 'last_login', 'enable_dictation', 'status'];
  @ViewChild(MatSort) sort: MatSort;
  loading = false;
  public currentUser: any;
  public response: any;
  public dataSource: any;
  company_id: any;
  companies: any = [];
  public roleDialogRef;
  localOrDevEnvironment: Boolean = false;
  filteredCompanies: Observable<string[]>;
  companyControl = new FormControl();

  usersFilter = {
    user_type: '3'
  };
  currentCompany: any;
  managers: any;
  is_activated = 'true';
  userRoleTypeSubscription: any;
  selectedUserTypeSubscription: any;
  selectedUserType: any;
  loaderId = 'manager-list-loader';
  company_type: any;
  searchQuery: any = '';
  constructor(
    private loader: NgxUiLoaderService,
    public services: UserTypesRelationshipService,
    private _router: Router,
    private userManagementService: UserManagementService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private _authService: AuthService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private _profileService: ProfileService,
    private commonService: CommonService


  ) {
    this.currentUser = this._authService.currentUser;
    this.displayedColumns = ['first_name', 'last_name', 'email', 'last_login', 'enable_dictation', 'timezone', 'status', 'tat_status', 'rrgen', 'cognito'];

    if (this.currentUser.admin_type === "full_admin" || this.currentUser.admin_type === "system_admin") {
      this.displayedColumns.push('user-type');
    }
    if (this.currentUser.product_owner) {
      this.displayedColumns.push('companies');
    }
    // this.displayedColumns.push('operations');
    if (!this.currentUser.product_owner) {
      this.displayedColumns = this.displayedColumns.filter(f => f !== 'two-factor');
      this.displayedColumns = this.displayedColumns.filter(f => f !== 'cognito');
    }
    if (!this.currentUser) {
      // if not login, stop and redirect to login #mohsin-dev
      localStorage.setItem('currentUser', 'null');
      this._router.navigate(['/login']);
      return;
    }
    this.loading = true;
    // this.company_id = this.currentUser.company_id;
    // if(this.currentUser.account_type == 'company'){
    //   this.company_id = this.currentUser.company_id;
    // }

    // if(this.currentUser.account_type == 'company' && !window.location.pathname.includes('manage-companies')){  

    if (window.location.pathname.includes('company') && !window.location.pathname.includes('manage-companies')) {
      this.company_id = this.route.parent.parent.parent.snapshot.params.id;
    }
    else if (window.location.pathname.includes('manage-companies')) {
      this.company_id = this.route.parent.parent.parent.snapshot.params.id;
    }


  }

  ngOnDestroy(): void {
    if (this.userRoleTypeSubscription) {
      this.companyService.changeUserRoleType(null);
      this.userRoleTypeSubscription.unsubscribe();
    }
    if (this.selectedUserTypeSubscription) {
      this.selectedUserTypeSubscription.unsubscribe();
    }
    
  }

  async ngOnInit() {
    try {
      if (this.currentUser.product_owner) {
        await this.getCompanies();
      }
      this.getCompanyType();
      this.selectedUserTypeSubscription = this.commonService.castselectedUserType.subscribe(userType => {
        if (userType) {
          this.selectedUserType = userType;
        }
      });
      this.getManagerList();
      this.filteredCompanies = this.companyControl.valueChanges.pipe(
        startWith(''),
        map((value:any) => this.filterCompany(value)));
    } catch (err) {
      console.log("err", err);
    }
  }
  private filterCompany(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.companies.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    else {
      return this.companies;
    }
  }

  applyUserFilter(event) {

    this.searchQuery = null;

    setTimeout(() => {
      this.searchQuery = '';
      this.dataSource.filter = '';
      this.is_activated = event.value == "true" ? "true" : "false";
      this.getManagerList( true );
    }, 10);
  }

  getCompanies() {
    return new Promise<void>((resolve, reject) => {
      this.companyService.getAllCompanies().subscribe((response: any) => {
        if (response.status == 200) {
          this.companies = response.data;
          this.currentCompany = this.route.parent.parent.parent.snapshot.params.id
          var index = this.companies.findIndex(company => this.currentCompany === company._id);
          this.currentCompany = this.companies[index];
          this.companies.splice(index, 1);
          this.companyControl.setValue(this.currentCompany?.name);
          resolve(null);
        }
        else if (response.status == 403) {
          this.toastr.error(response.message, "ERROR");
        }
      }, err => {
        reject();
        this.toastr.error(err, "ERROR");
      })
    });
  }
  async getCompanyType() {
    const company_id = this.company_id;
    await this.companyService.getCompanyName(company_id).subscribe((res: any) => {
      let company = res.data;
      this.company_type = company.company_type
    })
  }
  handleChangeAdminType(userId, event) {
    var update = {
      admin_type: event.value,
      user_id: userId
    }
    this._profileService.updateAdminType(update).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("Admin type changed");
        //  this.getDoctorList();
      }
    });
  }

  deleteUser(id: string) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: "Are you sure you want to delete this User ?"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === "true") {
        this._profileService.deleteUser(id).subscribe((res: any) => {
          // this.doctors = this.doctors.filter(user => user._id !== id)
          // this.dataSource.data = [];
          // this.dataSource.data = this.doctors;
          this.getManagerList();
        });
      } else {
        return;
      }
    })
  }
  getManagerList( showLoader: boolean = false ) {
    showLoader && this.loader.startLoader(this.loaderId);
    this.services.getManagerList(this.is_activated, this.company_id).subscribe(data => {
      showLoader && this.loader.stopLoader(this.loaderId);
      this.response = data;
      this.managers = this.response.data;
      this.sortUsers(this.managers);
      this.managers.sort(function (a, b) { return a.first_name > b.first_name ? 1 : -1; });
      this.managers.forEach(manager => {
        manager.companies = this.companies
        if (manager.recentLogin) {
          manager.recentLogin = moment(manager.recentLogin).tz('America/Los_Angeles').format('YYYY-MMM-DD h:mm A');
        }
        this.companies.forEach(company => {
          if (manager.company_id == company._id) {
            manager.selectedCompany = company._id;
          }
        });
        if (manager.admin_type == "full_admin" || manager.admin_type == "system_admin") {
          manager.admin = 'full_admin'
        }
        else if (manager.admin_type == "transcriber_admin") {
          manager.admin = 'transcriber_admin'
        }
        else if (manager.admin_type == "none") {
          manager.admin = 'none'
        }
      });
      this.dataSource = new MatTableDataSource(this.managers);
      this.userRoleTypeSubscription = this.companyService.castUserRoleType.subscribe(role => {
        if (role) {
          this.dataSource.data = this.managers;
          if (role === "all") {
            this.dataSource.data = this.managers;
          }
          else {
            this.dataSource.data = this.dataSource.data.filter(manager => manager.admin_type === role);
          }
        }
      });
    });
  }
  changeCognitoStatus(manager, status) {
    let updateObject = {
      _id: manager._id,
      enableCognito: status
    };

    this.userManagementService.updateUser(updateObject).subscribe((response: any) => {

      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  onCompanyChange(element, company) {
    this.companyService.companyChangeForUser(element._id, company).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("Company Changed")
        this.dataSource.data = this.dataSource.data.filter(user => user._id !== element._id);
        // this.getCompanies();
      }
    })
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  showRolesDialog(user) {
    this.roleDialogRef = this.dialog.open(RolesDialog, { width: '80%', data: { user } });
  }

  changeUserActiveStatus(userId: string, status) {
    this.userManagementService.changeUserActiveStatus(userId, status).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.success(response.message, 'Success');
      } else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }

  changeAdminStatus(manager, status) {
    this.userManagementService.changeAdminStatus(manager._id, this.company_id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  changeTATStatus(manager, status) {
    this.userManagementService.changeTATStatus(manager._id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  changeRRGenStatus(manager, status) {
    this.userManagementService.changeRRGenStatus(manager._id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  changeTwoFactorAllowance(provider, status) {
    let updateObject = {
      _id: provider._id,
      allow_two_factor_change_by_admin: status
    };

    this.userManagementService.updateUser(updateObject).subscribe((response: any) => {

      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }

  handleChangeUserType(userId, event) {
    let additionalRole: string = '';
    var update = {
      user_type: event.value,
      user_id: userId
    }
    if(event.value == 'test_provider') {
      additionalRole = 'demo_provider';
    }else if(event.value == 'cnp') {
      additionalRole = 'cnp';
    }
    this._profileService.updateUserType(update,additionalRole).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("User type changed");
        this.getManagerList();
      }
    });
  }
  ResendEmail(userId) {
    this.companyService.ResendEmail(userId).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.success("Email sent successfully");
      }
    });
  }

  exportUsersAsXLSX() {
    this.loader.startLoader(this.loaderId);
    this.companyService.exportUsersAsXLSX(this.company_id, this.selectedUserType, this.is_activated).subscribe((response: any) => {
      if (response instanceof HttpResponse) {
        this.loader.stopLoader(this.loaderId);
        // this.toastr.success("Status changed");
        var url = window.URL.createObjectURL(response.body);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `Users`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }
    });
  }
  changeDictationStatus(provider, status) {
    let updateObject = {
      _id: provider._id,
      is_dictation_enabled: status
    };

    this.userManagementService.updateUser(updateObject).subscribe((response: any) => {

      if (response.status == 200) {
        if (this.currentUser._id === response.data._id) {
          const currentUser = JSON.parse(localStorage.getItem("currentUser"));
          currentUser.is_dictation_enabled = response.data.is_dictation_enabled;
          localStorage.setItem("currentUser", JSON.stringify(currentUser));
        }
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  sortUsers(arr: any[]) {
    arr.sort((a, b) => a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase()));
  }

}
