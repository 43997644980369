import { SelectionModel } from "@angular/cdk/collections";
import { Component, Inject, OnInit, Optional } from "@angular/core";
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef
} from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { AuthService } from "src/app/services/auth.service";
import { CommonService } from "src/app/services/common.service";
import * as global from '../../global';

@Component({
    selector: "app-patients-with-active-wounds",
    templateUrl: "./patients-with-active-wounds.component.html",
    styleUrls: ["./patients-with-active-wounds.component.css"]
})
export class PatientsWithActiveWoundsComponent implements OnInit {
    displayedColumns: string[] = [
        "checkbox",
        "name",
        "dob",
        "dos",
        "last_seen"
    ];
    dataSource: any = new MatTableDataSource([]);
    selection = new SelectionModel<any>(true, []);
    selectedPatientsToImport: any = [];
    importPatients: Boolean = true;
    currentUser: any;
    allData: any = [];
    global = global;
    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public dataFromCensus: any,
        private _authService: AuthService,
        private _commonService: CommonService,
        private _dialogRef: MatDialogRef<PatientsWithActiveWoundsComponent>
    ) {
        this.currentUser = this._authService.currentUser;
    }

    ngOnInit(): void {
        this.selection.clear();
        if (this.dataFromCensus) {
            this.allData = this.dataFromCensus.patients;
            this.dataSource.data = this.dataFromCensus.patients;
            console.log(this.dataFromCensus);
        }
    }
    isAllSelected() {
        const numSelected = this.selectedPatientsToImport.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }
    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? "Deselect" : "Select"} all`;
        }
        return `${this.selection.isSelected(row) ? "Deselect" : "Select"} row ${
            row.position + 1
        }`;
    }
    patientSelected(patient, $event) {
        console.log($event);
        if ($event) {
            this.selectedPatientsToImport.push(patient);
        } else {
            let index = this.selectedPatientsToImport
                .map(function (e) {
                    return e._id;
                })
                .indexOf(patient._id);
            if (index > -1) {
                this.selectedPatientsToImport.splice(index, 1);
            }
        }
    }
    masterToggle() {
        if (this.isAllSelected()) {
            this.selectedPatientsToImport = [];
            return;
        }
        this.selectedPatientsToImport = this.dataSource.data.map(
            (wound) => wound.patient_id
        );
    }
    getWoundAssessmentDate(wounds) {
        let { year, month, day } = wounds[wounds.length - 1].last_assessment_date_obj;
        if (month < 10) month = `0${month}`;
        if (day < 10) day = `0${day}`;
        return `${month}-${day}-${year}`;
    }
    getLastSeen(patient) {
        let company_id = this.currentUser?.company_id?.toString();
        if (window.location.pathname.includes("company")) {
            this._commonService.castCompany.subscribe((company) => {
                if (company) {
                    company_id = company._id.toString();
                }
            });
        }

        let last_seen = "";
        if (patient?.last_seen) {
            if (
                Array.isArray(patient?.last_seen) &&
                patient?.last_seen?.length
            ) {
                for (let element of patient?.last_seen) {
                    if (element.user_type && element.user_type === global.USER_TYPE.WOUND_NURSE) {
                        last_seen = `<strong>(WN)</strong> ${element.last_seen || ''}`;
                        break;
                      } else {
                        last_seen = element.last_seen || '';
                        break;
                      }
                }
            } else {
                last_seen = "";
            }
        }
        return last_seen;
    }
    rowSelected(patient_id) {
        if (
            this.selectedPatientsToImport.findIndex(
                (ele) => ele._id.toString() === patient_id.toString()
            ) > -1
        ) {
            return true;
        } else {
            return false;
        }
    }
    importClick(){
    const selectedWounds = this.dataSource.data.filter((wound) =>
        this.rowSelected(wound.patient_id._id)
    );
    this.dataSource.data = selectedWounds;
    this.importPatients = false;
    this.displayedColumns = ["name", "dob", "dos", "last_seen"];
    }
    importMultiplePatients() {
        this._dialogRef.close({
            importPatientsList: this.selectedPatientsToImport
        });
    }
    cancel(){
        this.displayedColumns = ["checkbox","name", "dob", "dos", "last_seen"];
        this.dataSource.data = this.allData;
        this.importPatients = true;
    }
}
