import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TherapyService } from '../therapy.service';

@Component({
  selector: 'app-weekly-functional-status-report',
  templateUrl: './weekly-functional-status-report.component.html',
  styleUrls: ['./weekly-functional-status-report.component.css']
})
export class WeeklyFunctionalStatusReportComponent implements OnInit {
  @Input('patient') patient: any;
  patientWFSReport: any;
  dataSource: any = new MatTableDataSource([]);
  dataSourcePT: any = new MatTableDataSource([]);
  displayedColumnsPT: string[] = ['dos', 'gait', 'transfers', 'bed_mobility'];
  displayedColumnsOT: string[] = ['dos', 'ub_dressing', 'lb_dressing', 'toileting'];
  physician: any;
  roomNumber: any;

  constructor(private therapyService: TherapyService) { }

  async ngOnInit() {
    await this.getWeeklyFunctionalStatusReport();

  }

  async getWeeklyFunctionalStatusReport() {
    let patientName = this.patient.last_name + ', ' + this.patient.first_name.trim();
    // let patientName = 'barker, joann';
    this.therapyService.getWeeklyFunctionalStatusReport(patientName).subscribe(async (res: any) => {
      if (res.status == 200) {
        this.patientWFSReport = res.data.records;
        await this.normalizepatientWFSReport(this.patientWFSReport);
      } else {
        console.log('something went wrong');
      }
    });
  }

  async normalizepatientWFSReport(data: any) {
    let OccupationalTherapyTable = [];
    let PhysicalTherapyTable = [];
    for (let recordsIndex = 0; recordsIndex < data.length; recordsIndex++) {
      const element = data[recordsIndex];
      this.physician = element.physician ? element.physician : '';
      this.roomNumber = element.room_number ? element.room_number : '';
      //occupational table

      for (let ot = 0; ot < element.ot.length; ot++) {
        const ots = element.ot[ot];
        if (ots.data_type === 'date') {
          for (let otPairs = 0; otPairs < ots.pairs.length; otPairs++) {
            const otPair = ots.pairs[otPairs];
            if (OccupationalTherapyTable.find(
              t =>
                t.dos == ots.data &&
                (t['ub_dressing'] == otPair.value ? otPair.key == 'ub_dressing' : false || t['ub_dressing'] == '') &&
                (t['lb_dressing'] == otPair.value ? otPair.key == 'lb_dressing' : false || t['lb_dressing'] == '') &&
                (t['toileting'] == otPair.value ? otPair.key == 'toileting' : false || t['toileting'] == ''))) {
              // console.log('existed');
            } else {
              OccupationalTherapyTable.push({
                dos: ots.data,
                'ub_dressing': otPair.key == 'ub_dressing' ? otPair.value : '',
                'lb_dressing': otPair.key == 'lb_dressing' ? otPair.value : '',
                'toileting': otPair.key == 'toileting' ? otPair.value : ''
              });
            }
          }
        }
      }

      //physical table
      for (let pt = 0; pt < element.pt.length; pt++) {
        const pts = element.pt[pt];
        if (pts.data_type === 'date') {
          for (let ptPairs = 0; ptPairs < pts.pairs.length; ptPairs++) {
            const ptPair = pts.pairs[ptPairs];
            if (PhysicalTherapyTable.find(
              t =>
                t.dos == pts.data &&
                (t.transfers == ptPair.value ? ptPair.key == 'transfers' : false || t.transfers == '') &&
                (t.bed_mobility == ptPair.value ? ptPair.key == 'bed_mobility' : false || t.bed_mobility == '') &&
                (t.gait == ptPair.value ? ptPair.key == 'gait' : false || t.gait == ''))) {
              // console.log('existed');
            } else {
              PhysicalTherapyTable.push({
                dos: pts.data,
                'transfers': ptPair.key == 'transfers' ? ptPair.value : '',
                'bed_mobility': ptPair.key == 'bed_mobility' ? ptPair.value : '',
                'gait': ptPair.key == 'gait' ? ptPair.value : '',
              });

            }
          }
        }
      }
    }
    
    this.dataSourcePT.data = this.mergeDuplicatesPT(PhysicalTherapyTable);
    this.dataSource.data = this.mergeDuplicatesOT(OccupationalTherapyTable);
  }

  mergeDuplicatesPT(data) {
    let Unique = [];
    for (let object of data) {
      let index = Unique.findIndex(e => e.dos == object.dos);
      if (index < 0) {
        Unique.push(object);
      } else {
        let obj = {
          dos: object.dos, physician: object.physician, room_number: object.room_number,
          transfers: (object.transfers + ' ' + Unique[index].transfers).trim(),
          bed_mobility: (object.bed_mobility + ' ' + Unique[index].bed_mobility).trim(),
          gait: (object.gait + ' ' + Unique[index].gait).trim()
        };
        Unique[index] = obj;
      }
    }
    return Unique;
  }

  mergeDuplicatesOT(data) {
    let Unique = [];
    for (let object of data) {
      let index = Unique.findIndex(e => e.dos == object.dos);
      if (index < 0) {
        Unique.push(object);
      } else {
        let obj = {
          dos: object.dos, physician: object.physician, room_number: object.room_number,
          ub_dressing: (object.ub_dressing + ' ' + Unique[index].ub_dressing).trim(),
          lb_dressing: (object.lb_dressing + ' ' + Unique[index].lb_dressing).trim(),
          toileting: (object.toileting + ' ' + Unique[index].toileting).trim()
        };
        Unique[index] = obj;
      }
    }
    return Unique;
  }
}
