<div class="flex flex-col">
    <mat-list class="flex-grow">
        <h3 mat-subheader style="margin-bottom:0">Comments
            <ng-container *ngIf="commentsLoaded">
                ({{comments.length}})
            </ng-container>
        </h3>
        <div class="flex justify-center items-center">
            <mat-progress-spinner *ngIf="comments.length === 0 && !commentsLoaded" diameter="40"
                mode="indeterminate">
            </mat-progress-spinner>
        </div>
        <ng-container *ngFor="let comment of comments">
            <mat-list-item class="comment-item">
                <p class="comment-meta comment-meta-user-name" mat-line>{{comment.user.first_name}}
                    {{comment.user.last_name}}</p>
                <p class="comment-text" mat-line>{{comment.text}}</p>
                <p class="comment-meta comment-meta-date" mat-line align="right">{{comment.createdAt}}</p>
            </mat-list-item>
            <mat-divider></mat-divider>
        </ng-container>
    
        <ng-container *ngFor="let c of audioComments">
            <mat-list-item class="comment-item">
                <p class="comment-meta comment-meta-user-name" mat-line>
                    {{c.users[0].first_name}} {{c.users[0].last_name}}
                </p>
                <p class="comment-text" mat-line>
                    {{c.text_comment}}
                </p>
                <p class="comment-meta comment-meta-date" mat-line align="right">
                    {{c.date_time | date: global.date_time_format}}
                </p>
            </mat-list-item>
            <mat-divider></mat-divider>
        </ng-container>
    
    </mat-list>
    <div class="flex items-center gap-5 comment-editor">
        <mat-form-field class="flex-grow">
            <textarea matInput [(ngModel)]="editor.comment" placeholder="Leave a comment"></textarea>
        </mat-form-field>
        <button class="comment-add-btn" (click)="addComment()">Add</button>
    </div>
</div>