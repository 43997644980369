<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close (click)="closeDialog(true)"><mat-icon>cancel</mat-icon></button>
</div>
<div class="flex flex-col justify-start gap-2 w-99%"
    style="height:420px; margin: 15px; overflow-y: hidden; overflow-x: hidden;">
    <mat-label>{{title}}</mat-label>
    <form [formGroup]="filterFormGroup" class="flex flex-col items-start gap-2">
        <mat-form-field *ngIf="!toEdit">
            <mat-select formControlName="company">

                <div style="display:flex; flex-direction:row">
                    <input  placeholder="Search" [(ngModel)]="searchTextCompany"
                        [ngModelOptions]="{standalone: true}"
                        style="color:#000;height: 40px;padding-left: 7px; padding-right: 7px; border: none; width: 100%;"
                        (keydown)="$event.stopPropagation()">

                    <button *ngIf="searchTextCompany" mat-button mat-icon-button matSuffix
                        (click)="clearInputcompany()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>

                <mat-option *ngFor="let item of allCompanies | searchCompany : searchTextCompany" [value]="item">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
    <ng-multiselect-dropdown *ngIf="selectedCompany" name="companies" style="margin-top: 15px;"
        [(ngModel)]="selectedCompany.relation" [placeholder]="'Select Practice'" [data]="allOtherCompanies"
        [settings]="DropdownSettings" (onSelect)="onCompanySelect($event, selectedCompany); "
        (onSelectAll)="onSelectAllCompany(selectedCompany); " (onDeSelectAll)="onDeSelectAllCompany(selectedCompany);"
        (onDeSelect)="onCompanyDeSelect($event, selectedCompany);">
    </ng-multiselect-dropdown>

</div>