import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CKEditorService } from 'src/app/includes/note-editor/ckeditor.service';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { PCCService } from 'src/app/services/pcc.service';
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { pccFacilityEmailBody } from '../../admin-pcc-facilities/email-template-dialog/model/pccFacilityEmailBody';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
@Component({
  selector: 'app-facility-activation-email-dialog',
  templateUrl: './facility-activation-email-dialog.component.html',
  styleUrls: ['./facility-activation-email-dialog.component.css']
})
export class FacilityActivationEmailDialogComponent implements OnInit {


  emailObject = new pccFacilityEmailBody();

  activationModel: any = {
    recipient: "",
    regarding: "",
    provider: "",
    company: "",
    subject: ""
  };
  cke4Config;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();

  @ViewChild('emailIdInput') emailIdInput: ElementRef<HTMLInputElement>;

  emailIds: any = [];
  emailIdCtrl = new FormControl();
  ccEmailIdCtrl = new FormControl();
  selectedFiles: any;
  fileName: string = 'SelfServe Activation for Marketplace Applications - Quick Reference Guide.pdf';
  file: any;
  currentUser: any;
  ccEmailIds: any = [];
  showRemoveFileButton: boolean = false;
  companyName: any;
  company: any;
  providers: any = [];
  filteredProviders: Observable<string[]>;
  providerControl = new FormControl();
  facilityActivationId: any;
  facilityActivation: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ckeditorService: CKEditorService,
    private pccService: PCCService,
    private _toastr: ToastrService,
    private _authService: AuthService,
    public dialogRef: MatDialogRef<FacilityActivationEmailDialogComponent>,
    private companyService: CompanyService,
    public userTypesRelationshipService: UserTypesRelationshipService,
    private _route: ActivatedRoute,

  ) {
    this.cke4Config = {
      ...this.ckeditorService.getDefaultConfig(),
      editable: true
    };
    this.currentUser = this._authService.currentUser;
    this.company = this.data.company;
    this.activationModel.company = this.data.company.name;
    if(this.data.facilityActivationId) {
      this.facilityActivationId = this.data;
      this.cke4Config.editable = false;
    }
  }

  ngOnInit(): void {
    this.getProviderList();
    this.filteredProviders = this.providerControl.valueChanges.pipe(
      startWith(''),
      map((value:any) => this.filterProvider(value)));
    this.getPccFacilityActivationNote();
    if(this.facilityActivationId) {
      this.initFacilityActivationDetails();
    }
  }
  private filterProvider(value: string): string[] {
    if (value && this.providers.length > 0) {
      const filterValue = value.toLowerCase();
      return this.providers.filter(option => (option.middle_name && option.middle_name.toLowerCase().indexOf(filterValue) === 0) || option.last_name.toLowerCase().indexOf(filterValue) === 0 || option.first_name.toLowerCase().indexOf(filterValue) === 0);
    }
    else {
      return this.providers;
    }
  }
  initFacilityActivationDetails() {
    this.pccService.getFacilityActivationDetails(this.facilityActivationId).subscribe((res: any) => {
      this.facilityActivation = res.data;
        this.emailIds = this.facilityActivation?.emailIds;
        this.activationModel.recipient = this.facilityActivation?.recipient;
        this.activationModel.regarding = this.facilityActivation?.regarding;
        this.activationModel.provider = this.facilityActivation?.provider_id_ref.first_name +" "+this.facilityActivation?.provider_id_ref.last_name;
        this.activationModel.company = this.facilityActivation?.company_id_ref;
        this.activationModel.subject = this.facilityActivation?.subject;
    });
  }

  getPccFacilityActivationNote() {
    this.companyService.getPccFacilityActivationNote(this.company._id).subscribe((response: any) => {
      if(response.status === 200 && response.data) {
        this.emailObject.email_body = response.data.pcc_facility_activation_note;
      } 
    });
  }
  savePccFacilityActivationNote() {
    this.companyService.setPccFacilityActivationNote(this.company._id, this.emailObject.email_body).subscribe((response: any) => {
      if(response.status === 200) {
        this._toastr.success("Note Saved", "Success");
      } 
    });
  }
  getProviderList() {
    this.userTypesRelationshipService.getDoctorsList('true', this.company._id).subscribe((response: any) => {
      console.log("res data", response.data);
      if (response.status === 200) {
        this.providers = response.data;
        if (this.providers.length > 0) {
          console.log("this.providers[0].full_name", this.providers[0].full_name);
          this.providerControl.setValue(this.providers[0].full_name);
          this.activationModel.provider = this.providers[0];
        }
      }
    });
  }

  addEmailIdAsChip(event: MatChipInputEvent): void {
    const input = event.input;
    const value: any = event.value;

    this.emailIds.push(value);

    if (input) {
      input.value = '';
    }

    this.ccEmailIdCtrl.setValue(null);
  }

  addCCEmailIdAsChip(event: MatChipInputEvent): void {
    const input = event.input;
    const value: any = event.value;
    console.log("vale", value);
    this.ccEmailIds.push(value);

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.emailIdCtrl.setValue(null);
  }

  removeEmailIdAsChip(emailId: any): void {
    const index = this.emailIds.indexOf(emailId);
    if (index >= 0) {
      this.emailIds.splice(index, 1);
    }
  }

  removeCCEmailIdAsChip(ccEmailId: any): void {
    const index = this.ccEmailIds.indexOf(ccEmailId);
    if (index >= 0) {
      this.ccEmailIds.splice(index, 1);
    }
  }

  handleEmailIdInputFocus(event) {
    console.log("handle", event);
    this.emailIdCtrl.setValue(event.target.value);
  }

  handleCCEmailIdInputFocus(event) {
    console.log("handle", event);
    this.ccEmailIdCtrl.setValue(event.target.value);
  }

  selectedEmailIdAsChip(event: MatAutocompleteSelectedEvent): void {
    // this.emailIds.push(event.option.value);
    console.log("auto", this.emailIds);

    this.emailIdInput.nativeElement.value = '';
    this.emailIdCtrl.setValue(null);
  }

  onSelectFile(e) {
    this.fileName = e.target.files[0].name;
    this.file = e.target.files[0];
    this.showRemoveFileButton = true;
    console.log("e", this.fileName);

  }
  onDeselectFile(e) {
    this.fileName = "";
    this.file = undefined;
    this.showRemoveFileButton = false;
    e.value = "";
  }
  displayProviderFn(provider): string {
    return provider && provider.full_name ? provider.full_name : '';
  }

  onSelectProvider($event, provider) {
    if ($event.isUserInput) {
      console.log("provider", provider);
      this.activationModel.provider = provider;
    }
  }

  submitActivationForm() {
    this.activationModel.emailIds = this.emailIds;
    this.activationModel.ccEmailIds = this.ccEmailIds;
    this.activationModel.email_body = this.emailObject.email_body;
    this.activationModel.company = this.company;
    // this.activationModel.facility = this.data.facility;
    const formData = new FormData();
    formData.append('file', this.file);
    formData.append('user_id', this.currentUser._id);
    formData.append('auth_key', this.currentUser.auth_key);
    formData.append('usertype', this.currentUser.user_type);
    formData.append('emailIds', this.emailIds);
    formData.append('subject', this.activationModel.subject);
    formData.append('provider', JSON.stringify(this.activationModel.provider));
    formData.append('company', JSON.stringify(this.company));
    formData.append('recipient', this.activationModel.recipient);
    formData.append('regarding', this.activationModel.regarding);

    // for (let key in this.activationModel) {
    //   formData.append(key, this.activationModel[key]);
    // }
    this.pccService.generateFacilityActivationEmail(formData).subscribe((response: any) => {
      if (response.status === 200) {
        this.activationModel.company = this.data.name;
        this._toastr.success("Email Sent", "Success");
        this.dialogRef.close(response.data);
      }
      else {
        this._toastr.error('something went wrong', 'Error');
      }
    });

  }
}
