<div calss="container">
    <div class="flex">
        <app-input-with-dropdown
            class="flex flex-row"
                [noDropdown]="true"
                placeholder="Search Facility"
                (inputChange)="applyFilter($event.target.value)">
        </app-input-with-dropdown>
    </div>
    <div style="height: 10px;"></div>
    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="provider">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Providers</th>
            <td mat-cell *matCellDef="let element" style="width: 20%">
            </td>
        </ng-container>

        <ng-container matColumnDef="facility">
            <th mat-header-cell *matHeaderCellDef> Facilities</th>
            <td mat-cell *matCellDef="let element" style="width: 50%">
            </td>

        </ng-container> 
        <ng-container matColumnDef="template">
            <th mat-header-cell *matHeaderCellDef> Templates</th>
            <td mat-cell *matCellDef="let element" style="width: 15%">
            </td>

        </ng-container>
          
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
