<div class="wrapper">
  <div class="form-group flex flex-row items-baseline">
    <div class="flex-grow">Screen for maltreatment?</div>
    <div style="flex: 0 0 150px">
      <button
        class="button"
        [class.active]="model.SCREEN_FOR_MALTREATMENT === true"
        mat-button
        (click)="resetModel(); model.SCREEN_FOR_MALTREATMENT = true"
      >
        Yes
      </button>
      <button
        class="button"
        [class.active]="model.SCREEN_FOR_MALTREATMENT === false"
        mat-button
        (click)="resetModel(); model.SCREEN_FOR_MALTREATMENT = false"
      >
        No
      </button>
    </div>
  </div>
  <ng-container *ngIf="model.SCREEN_FOR_MALTREATMENT === true">
    <div class="form-group">
      <div class="uppercase-label">Client is:</div>
<div class="flex flex-row justify-start items-baseline">
        <div class="flex-grow">Elder with a caregiver?</div>
        <div style="flex: 0 0 150px">
          <button
            class="button"
            [class.active]="model.ELDER_WITH_A_CAREGIVER === true"
            mat-button
            (click)="model.ELDER_WITH_A_CAREGIVER = true"
          >
            Yes
          </button>
          <button
            class="button"
            [class.active]="model.ELDER_WITH_A_CAREGIVER === false"
            mat-button
            (click)="model.ELDER_WITH_A_CAREGIVER = false"
          >
            No
          </button>
        </div>
      </div>
<div class="flex flex-row justify-start items-baseline">
        <div class="flex-grow">Caregiver of an elder?</div>
        <div style="flex: 0 0 150px">
          <button
            class="button"
            [class.active]="model.CAREGIVER_OF_AN_ELDER === true"
            mat-button
            (click)="model.CAREGIVER_OF_AN_ELDER = true"
          >
            Yes
          </button>
          <button
            class="button"
            [class.active]="model.CAREGIVER_OF_AN_ELDER === false"
            mat-button
            (click)="model.CAREGIVER_OF_AN_ELDER = false"
          >
            No
          </button>
        </div>
      </div>
    </div>
    <ng-container
      *ngIf="
        model.ELDER_WITH_A_CAREGIVER !== null ||
        model.CAREGIVER_OF_AN_ELDER !== null
      "
    >
      <div class="form-group">
        <div class="uppercase-label">Patient Screening Exclusions:</div>
  <div class="flex flex-row justify-start items-baseline">
          <div class="flex-grow"></div>
          <div class="flex justify-start items-baseline">
            <mat-form-field>
              <mat-select
                name="patientScreeningExclusionsOption"
                [(ngModel)]="model.PATIENT_SCREENING_EXCLUSIONS"
              >
                <mat-option value="" [disabled]="true"> Select </mat-option>
                <mat-option
                  *ngFor="let patientScreeningExclusionsOption of patientScreeningExclusionsOptions"
                  [value]="patientScreeningExclusionsOption"
                >
                  {{ patientScreeningExclusionsOption }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <ng-container
        *ngIf="
          model.PATIENT_SCREENING_EXCLUSIONS === patientScreeningExclusionsOptions[3]
        "
      >
        <div class="form-group">
          <div class="uppercase-label">
            Do you suspect abuse by: A caregiver?
          </div>
    <div class="flex flex-row justify-start items-baseline">
            <div class="flex-grow">Sale 1-5</div>
            <mat-form-field>
              <mat-select
                name="scale_elderWithCareGiver"
                [(ngModel)]="model.scale.ELDER_WITH_A_CAREGIVER"
              >
                <mat-option value="" [disabled]="true"> Select </mat-option>
                <mat-option
                  *ngFor="let scaleOption of scaleOptions; let i = index"
                  [value]="scaleOption"
                >
                  {{ i + 1 }}. {{ scaleOption }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="form-group">
          <div class="uppercase-label">
            Do you suspect abuse by: A care receiver or other?
          </div>
    <div class="flex flex-row justify-start items-baseline">
            <div class="flex-grow">Sale 1-5</div>
            <mat-form-field>
              <mat-select
                name="scale_caregiverOfAnElder"
                [(ngModel)]="model.scale.CAREGIVER_OF_AN_ELDER"
              >
                <mat-option value="" [disabled]="true"> Select </mat-option>
                <mat-option
                  *ngFor="let scaleOption of scaleOptions; let i = index"
                  [value]="scaleOption"
                >
                  {{ i + 1 }}. {{ scaleOption }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <ng-container
          *ngIf="
            (model.scale.ELDER_WITH_A_CAREGIVER &&
              model.scale.ELDER_WITH_A_CAREGIVER !== scaleOptions[0]) ||
            (model.scale.CAREGIVER_OF_AN_ELDER &&
              model.scale.CAREGIVER_OF_AN_ELDER !== scaleOptions[0])
          "
        >
          <div class="form-group">
            <div class="uppercase-label">
              What kind(s) of abuse(s) is (are) suspected?
            </div>
            <ng-multiselect-dropdown
              name="kindOfAbuseValue"
              placeholder="Select"
              [(ngModel)]="model.kindOfAbuseValue"
              [data]="kindOfAbuseOptions"
              [settings]="kindOfAbuseDropdownSettings"
            >
            </ng-multiselect-dropdown>
          </div>

          <div class="form-group">
            <div class="uppercase-label">When is intervention needed?</div>
      <div class="flex flex-row justify-start items-baseline">
              <div class="flex-grow"></div>
              <mat-form-field>
                <mat-select
                  name="whenInterventionNeeded"
                  [(ngModel)]="model.whenInterventionNeededValue"
                >
                  <mat-option value="" [disabled]="true"> Select </mat-option>
                  <mat-option
                    *ngFor="
                      let whenInterventionNeededOption of whenInterventionNeededOptions
                    "
                    [value]="whenInterventionNeededOption"
                  >
                    {{ whenInterventionNeededOption }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="form-group">
            <div class="uppercase-label">Follow-up Plan Documentation</div>
      <div class="flex flex-row justify-start items-baseline">
              <div class="flex-grow">Follow-up plan documented</div>
              <div style="flex: 0 0 150px">
                <button
                  class="button"
                  [class.active]="
                    model.FOLLOW_UP_PLAN_DOCUMENTATION
                      .FOLLOW_UP_PLAN_DOCUMENTED === true
                  "
                  mat-button
                  (click)="
                    model.FOLLOW_UP_PLAN_DOCUMENTATION.FOLLOW_UP_PLAN_DOCUMENTED = true
                  "
                >
                  Yes
                </button>
                <button
                  class="button"
                  [class.active]="
                    model.FOLLOW_UP_PLAN_DOCUMENTATION
                      .FOLLOW_UP_PLAN_DOCUMENTED === false
                  "
                  mat-button
                  (click)="
                    model.FOLLOW_UP_PLAN_DOCUMENTATION.FOLLOW_UP_PLAN_DOCUMENTED = false
                  "
                >
                  No
                </button>
              </div>
            </div>
            <div
              class="flex justify-start items-baseline"
              *ngIf="
                model.FOLLOW_UP_PLAN_DOCUMENTATION.FOLLOW_UP_PLAN_DOCUMENTED ===
                true
              "
            >
              <div class="flex-grow"></div>
              <div style="margin-left: auto" class="flex">
                <mat-form-field>
                  <mat-option value="" [disabled]="true"> Identify Method </mat-option>
                  <mat-select
                    name="followUpOptions"
                    [(ngModel)]="
                      model.FOLLOW_UP_PLAN_DOCUMENTATION.FOLLOW_UP_OPTION
                    "
                  >
                    <mat-option value="" [disabled]="true"> Select </mat-option>
                    <mat-option
                      *ngFor="let followUpOption of followUpOptions"
                      [value]="followUpOption"
                    >
                      {{ followUpOption }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div
              class="flex justify-start items-baseline"
              *ngIf="
                model.FOLLOW_UP_PLAN_DOCUMENTATION.FOLLOW_UP_PLAN_DOCUMENTED ===
                false
              "
            >
              <div class="flex-grow">Why Not?</div>
              <mat-form-field>
                <mat-select
                  name="whyNotOptions"
                  [(ngModel)]="model.FOLLOW_UP_PLAN_DOCUMENTATION.WHY_NOT"
                >
                  <mat-option
                    *ngFor="let whyNotOption of whyNotOptions"
                    [value]="whyNotOption"
                  >
                    {{ whyNotOption }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="answer">
    <div [class.negative-answer]="isNegativeCode(answer)">
      <div class="answer_code">{{ answer }}</div>
      <div class="answer_description">{{ answerDescription }}</div>
    </div>
  </ng-container>
</div>
