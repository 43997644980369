<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

    <ng-container matColumnDef="totalReferrals">
        <th mat-header-cell *matHeaderCellDef>Total Referrals</th>
        <td mat-cell *matCellDef="let element; let i = index;">
            {{element?.wounds?.totalReferrals ? element?.wounds?.totalReferrals : 0}}
        </td>
    </ng-container>

    <ng-container matColumnDef="totalWounds">
        <th mat-header-cell *matHeaderCellDef>Total Wounds</th>
        <td mat-cell *matCellDef="let element; let i = index;">
            {{element?.wounds?.totalWounds ? element?.wounds?.totalWounds : 0}}
        </td>
    </ng-container>

    <ng-container matColumnDef="residentsWithWounds">
        <th mat-header-cell *matHeaderCellDef>Residents With Wounds</th>
        <td mat-cell *matCellDef="let element; let i = index;">
            {{element?.wounds?.residentsWithWounds ? element?.wounds?.residentsWithWounds : 0}}
        </td>
    </ng-container>

    <ng-container matColumnDef="facilityAcquired">
        <th mat-header-cell *matHeaderCellDef>Facility Acquired</th>
        <td mat-cell *matCellDef="let element; let i = index;">
            {{element?.wounds?.facilityAcquired ? element.wounds.facilityAcquired : 0}}
        </td>
    </ng-container>

    <ng-container matColumnDef="facilityAcquiredPI/PU">
        <th mat-header-cell *matHeaderCellDef>Facility Acquired PI/PU</th>
        <td mat-cell *matCellDef="let element; let i = index;">
            {{element?.wounds['facilityAcquiredPI/PU'] ? element?.wounds['facilityAcquiredPI/PU'] : 0}}
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>