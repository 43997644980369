import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { PatientRoundingSheetCommentsService } from 'src/app/services/patient-roundingsheet-comments.service';
import { CensusCommentsService } from './census-comments.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-census-comments',
  templateUrl: './census-comments.component.html',
  styleUrls: ['./census-comments.component.css']
})
export class CensusCommentsComponent implements OnInit {
  @Input() censusId: String;
  @Input() patientId: String;
  comments: Array<any> = [];
  commentsLoaded = false;
  comment: any;
  currentUser: any;
  buttonState: boolean;
  constructor(private censusCommentsService: CensusCommentsService,
    private patientRoundingSheetCommentsService: PatientRoundingSheetCommentsService,
    private _authService: AuthService,
    private toaster: ToastrService,
    private commonService: CommonService,
    ) {
      this.currentUser = this._authService.currentUser;

  }
  ngOnInit(): void {
    this.censusCommentsService.get_census_comments(this.patientId,this.censusId).subscribe((response: any) => {
      if (response.status === 200) {
          this.comments = response.data.array;
      }
    }, console.error, () => this.commentsLoaded = true);
    this.buttonState=false;

  }

  getComments(){
    this.censusCommentsService.get_census_comments(this.patientId,this.censusId).subscribe((response: any) => {
      if (response.status === 200) {
          this.comments = response.data.array;
      }
    }, console.error, () => this.commentsLoaded = true);
  }

  addComment() {
    if (this.comment !== "") {
      this.buttonState=true;

      let details: any = {};
      if(!this.commonService.validateComment(this.comment)){
        this.toaster.error('No special character allowed', 'Error');
        this.buttonState=false;
        return
      }
      details.patient_id = this.patientId,//this.patient._id;
      details.comment = this.comment;
      details.created_by = this.currentUser._id;
      details.rounding_sheet_id = this.censusId;

      this.patientRoundingSheetCommentsService.addCensusComment(details).subscribe((response: any) => {
        // console.log(response);
        this.buttonState=false;
        if (response.status === 200) {
          this.comment = "";
        }
          this.getComments();
        
      });
    }
  }
  
}
