import { Component, OnInit, Inject } from '@angular/core';
import { WoundService } from 'src/app/services/wound.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'src/app/services/common.service';
import { TemplateExplorerService } from 'src/app/includes/templates/template-explorer/template-explorer.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { lastValueFrom } from 'rxjs';
import moment from 'moment';
@Component({
  selector: 'app-dialogue-add-cpt-code',
  templateUrl: './dialogue-add-cpt-code.component.html',
  styleUrls: ['./dialogue-add-cpt-code.component.css']
})
export class DialogueAddCptCodeComponent implements OnInit {
newCPTmodel : any = {
    conceptId: "",
    code: "",
    clinicianDescriptorId: "",
    title: "",
    description: "",
    end_date: "",
    company_id: null,
    isCptCode: true,     // used in backend api "addNewCPTCode"
    isDuplicateAllowed: false,  // used in backend api "addNewCPTCode" 
    use_once_per_admit: false,
    default_unit: ""
  };
  place_of_service = [];
  pos_ids = [];
  public current_date = new Date();
  isEmptyCodeDescription = true;
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = ['Code', 'Description'];
  cpts = [];
  isShowTable = false;
  isCloseDialogue = false;
  pos = [];
  expiry_date = null; // just initialize date object

  constructor(private toastr: ToastrService,
    private _woundService: WoundService,
    private dialogRef: MatDialogRef<DialogueAddCptCodeComponent>,
    private dialog: MatDialog,
    private commonService: CommonService,
    private companyService: CompanyService,
    @Inject(MAT_DIALOG_DATA) public data: DialogueAddCptCodeComponent) {
      
     }

  ngOnInit(): void {
    this.getPOS();
  }

  getPOS(){
    this.companyService.getAllPOS({}, {}).subscribe((res: any) => {
      if (res.status == 200) {
        this.pos = res.data;
      } else {
        console.log('something went wrong');
      }
    });
  }
  checkIsEmpty(){
    if (this.newCPTmodel.code.trim().length === 0)
      this.isEmptyCodeDescription = true;
    else if (this.newCPTmodel.description.trim().length === 0)
      this.isEmptyCodeDescription = true;
    else
      this.isEmptyCodeDescription = false;
  
  }

  async handleSubmit(e) {
    let response: any, toastrId;
    this.checkIsEmpty();

    try {
        if (!this.isCloseDialogue) {
        toastrId = this.toastr.info("Adding New Code", "Please Wait...", { disableTimeOut: true });

        // if (this.newCPTmodel.end_date && !this.isShowTable)
        //   this.newCPTmodel.end_date = this.commonService.convertDateToStringforMoment(this.newCPTmodel.end_date, false, false, true);

          if (this.expiry_date != null)
            this.newCPTmodel.end_date = moment(this.expiry_date).format('MM/DD/YYYY');

          if (this.newCPTmodel.default_unit == 0)
            this.newCPTmodel.default_unit = "";  
          this.newCPTmodel.company_id = this.data;
          response = await lastValueFrom(this._woundService.addNewCPTCode(this.newCPTmodel));
        if (response.status == 200) {
          // when jsonData.posID is an array of ids, "isMultiplePos" is set true
          this.updatePOSRelation(this.newCPTmodel.company_id, response.cptcodeData._id, this.place_of_service, true, true);
          this.toastr.clear(toastrId.toastId);
          this.toastr.success("Code Added Succesfully", "Success");
          this.dialogRef.close({ data: response });
        } else {
          this.toastr.clear(toastrId.toastId);
          this.cpts = response.data;
          this.dataSource.data = this.cpts;
          this.isShowTable = true;
          this.checkIsEmpty();

        }
      }
      else
        this.dialogRef.close({ data: response });

    } catch (err) {
      this.toastr.clear(toastrId.toastId);
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  addDuplicateCode(event: any){
    this.newCPTmodel.isDuplicateAllowed = true;
    this.handleSubmit(event);
  }

  closeDialogue(event: any){
    this.isCloseDialogue = true;
    this.handleSubmit(event);
  }


  clearDetails() {
    this.newCPTmodel = {
      conceptId: "",
      code: "",
      clinicianDescriptorId: "",
      title: "",
      description: "",
      company_id: null,
      isCptCode: true,
      isDuplicateAllowed: false,
      end_date: "",
      use_once_per_admit: false,
      default_unit: ""
    };
    this.place_of_service = [];
    this.isEmptyCodeDescription = true;
    this.isShowTable = false;
    this.expiry_date = null;
  }

  keyPressAlphanumeric(event) {
    const inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9\s/-_-.-]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressNumbers(event) {
    if (event.target.value == 0){
      event.target.value = "";
    }
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
        event.preventDefault();
        return false;
    }
    else {
        return true;
    }
  }

  async updatePOSRelation(companyId, cptId, posIDs, isCreating: boolean, isMultiplePos = false) {
    this.companyService.updatePOSRelation(companyId, cptId, posIDs, isCreating, isMultiplePos).subscribe((res: any) => {
      if (res.status == 200) {
        this.toastr.success('CPT POS Relation Added!', 'Success');
      } else {
        console.log('something went wrong');
      }
    });
  }

  triggerEvent(value) {
    this.place_of_service = [];
    this.place_of_service = this.pos.filter(pos => this.pos_ids.includes(pos._id));
  }

  setOncePerAdmit($event) {
    this.newCPTmodel.use_once_per_admit = $event.checked;
  }

}
