import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-last-admission',
  templateUrl: './last-admission.component.html',
  styleUrls: ['./last-admission.component.css']
})
export class LastAdmissionComponent implements OnInit {

  @Input('data') data;
  dataSource;
  displayedColumns = [
    "directions",
    "dose",
    "doseUOM",
    "frequency",
    "indicationsForUse",
    "scheduleType",
    "startDateTime",
  ];
  constructor() { }

  ngOnInit(): void {

    this.dataSource = new MatTableDataSource(this.data);
  }
}
