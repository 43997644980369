
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { PCCService } from 'src/app/services/pcc.service';
import * as global from '../global';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MergeFacilityService } from '../merge-facilities/merge-facilities.service';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import {TestinputDialogueComponent} from '../merge-facilities/testinput-dialogue/testinput-dialogue.component';
import { ViewNoteDialog } from '../transcription-create/view-note-dialog/view-note-dialog.component';

@Component({
  selector: 'app-merge-duplicate-name-patient',
  templateUrl: './merge-duplicate-name-patient.component.html',
  styleUrls: ['./merge-duplicate-name-patient.component.css']
})
export class MergeDuplicateNamePatientComponent implements OnInit {

  dataSource: MatTableDataSource<unknown>;
  companies: any;
  dataSourcePcc: MatTableDataSource<unknown>;
  dataSourceAll: MatTableDataSource<unknown>;
  params: {
    id: string
  }
  patients: any;
  currentUser: any;
  displayedColumns: string[] = ['orignal','duplicate','sign' ,'wrong', 'wrongDuplicate' , 'merge', 'actions'];

  @ViewChild(MatPaginator, { static: true } as any) paginator: MatPaginator;
  showPccLogo: boolean = false;
  wrongPatients: any[];

  constructor(private mergeFacilityService: MergeFacilityService,
    private _authService: AuthService,
    private loader: NgxUiLoaderService,
    private pccService: PCCService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private _matDialog: MatDialog,
    private authService: AuthService,
    private toastr: ToastrService) 
    {
        this.params = {
          id: this.route.parent.snapshot.params.id
        };
        this.currentUser = this.authService.currentUser;
  }

  ngOnInit(): void {
    this.wrongPatients=[];

    this.initWrongPatients();
  }

  async initWrongPatients() {
    this.wrongPatients = [];
    this.dataSource = new MatTableDataSource([]);

    var details = {};
    // this.loader.startLoader('loader-05');
    this.toastr.info("Fetching data...")
    const response: any = await this.mergeFacilityService.getDuplicateNamePatients(details).toPromise();
    if (response && response.data) {
      this.wrongPatients = response.data;
      this.wrongPatients = this.wrongPatients.map((patient: any) => {
        const newObj={
          ...patient,
          merged:false, 
          duplicate:null
        };
        return newObj;
      });
      // this.loader.stopLoader('loader-05');
      if (this.wrongPatients?.length > 0) {
        this.dataSource = new MatTableDataSource(this.wrongPatients);
        this.dataSource.paginator = this.paginator;
      }
      else{
        this.toastr.info("No Data found...")
      }
    }
    else{
      this.toastr.info("Something went wrong.")
    }
    this.toastr.clear()
  }

  findDuplicate(item: any) {
    this.wrongPatients = this.wrongPatients.map((patient: any) => {
      if (patient._id == item._id) {
        patient.duplicate = null;
        patient.wrongduplicate = null;
      }
      return patient;
    });
    if (this.wrongPatients?.length > 0) {
      this.dataSource = new MatTableDataSource(this.wrongPatients);
      this.dataSource.paginator = this.paginator;
    }

    let latestTranscription = null;
    let dupTranscription = null;
    let latestDuplicate= null;
    let firstTranscription = null;
    let dupCount = 0;
    let correctCount = 0;

    let wrongdupTranscription=null;
    let wronglatestDuplicate = null;
    let wrongdupCount = null;
    let wrongduplicate = null;

    this.mergeFacilityService.getExistingDuplicateWithInitialNote(item).subscribe(async (response: any) => {

      if(response.data){
         latestTranscription = response.data.latestTranscription;
         dupTranscription = response.data.dupTranscription;
         firstTranscription = response.data.firstTranscription;
         dupCount = response.data.dupCount;
         correctCount = response.data.correctCount;
         latestDuplicate = response.data.latestDuplicate;


         wronglatestDuplicate = response.data.wronglatestDuplicate;
         wrongdupTranscription = response.data.wrongdupTranscription;
         wrongdupCount = response.data.wrongdupCount;
         wrongduplicate = response.data.wrongduplicate;

      }
      if (response.data?._id) {
          this.wrongPatients = this.wrongPatients.map((patient: any) => {
            if (patient._id === item._id) {
              patient.duplicate = response.data;
              patient.latestDuplicate = latestDuplicate;
              patient.pcc=false;
              patient.latestTranscription = latestTranscription;
              patient.dupTranscription = dupTranscription;
              patient.firstTranscription = firstTranscription;
              patient.dupCount = dupCount;
              patient.correctCount = correctCount;

              ///////
              patient.wrongduplicate = wrongduplicate;
              patient.wronglatestDuplicate = wronglatestDuplicate;
              patient.wrongdupTranscription = wrongdupTranscription;
              patient.wrongdupCount = wrongdupCount;

            }
            return patient;
          });
          if (this.wrongPatients?.length > 0) {
            this.dataSource = new MatTableDataSource(this.wrongPatients);
            this.dataSource.paginator = this.paginator;
          }
      }else{
        const resp:any = await this.mergeFacilityService.getExistingPatientFromPcc(item).toPromise();
        if(resp.data){
          this.wrongPatients = this.wrongPatients.map((patient: any) => {
            if (patient._id === item._id) {
              patient.duplicate = resp.data;
              patient.latestTranscription = latestTranscription;
              patient.firstTranscription = firstTranscription;
              patient.dupCount = dupCount;
              patient.correctCount = correctCount;
              patient.pcc=true;

              ////

              patient.wrongduplicate = wrongduplicate;
              patient.wronglatestDuplicate = wronglatestDuplicate;
              patient.wrongdupTranscription = wrongdupTranscription;
              patient.wrongdupCount = wrongdupCount;
              
            }
            return patient;
          });
          if (this.wrongPatients?.length > 0) {
            this.dataSource = new MatTableDataSource(this.wrongPatients);
            this.dataSource.paginator = this.paginator;
          }
        }else{
          this.wrongPatients = this.wrongPatients.map((patient: any) => {
            if (patient._id === item._id) {
              patient.duplicate = null;
              patient.latestTranscription = latestTranscription;
              patient.firstTranscription = firstTranscription;
              patient.dupCount = dupCount;
              patient.correctCount = correctCount;
              patient.pcc=false;

              /////

              patient.wrongduplicate = wrongduplicate;
              patient.wronglatestDuplicate = wronglatestDuplicate;
              patient.wrongdupTranscription = wrongdupTranscription;
              patient.wrongdupCount = wrongdupCount;

            }
            return patient;
          });
          if (this.wrongPatients?.length > 0) {
            this.dataSource = new MatTableDataSource(this.wrongPatients);
            this.dataSource.paginator = this.paginator;
          }
        }
      }
    });
    
  }

  mergePatient(item:any) {
    this.mergeFacilityService.mergePaientAndInactive(item).subscribe((response: any) => {
      if (response.data) {
        this.toastr.success('Patient Merged Successfully.', 'Success');
        this.wrongPatients = this.wrongPatients.map((patient: any) => {
          if (patient._id === item._id) {
            patient.merged = true;
            patient.datamerged = false;
          }
          return patient;
        });
        if (this.wrongPatients?.length > 0) {
          this.dataSource = new MatTableDataSource(this.wrongPatients);
          this.dataSource.paginator = this.paginator;
        }
      }
    });
  }

  viewNoteDialog(noteId) {
    this._matDialog.open(ViewNoteDialog, {
      width: '80%',
      data: {
        note_id: noteId,
      }
    });
  }

  mergePatientsData(item: any) {
    this.mergeFacilityService.mergePatientsDataForNameChange(item).subscribe((response: any) => {
      if (response.data) {
        this.toastr.success('Patient Data Merged Successfully.', 'Success');
        this.wrongPatients = this.wrongPatients.map((patient: any) => {
          if (patient._id === item._id) {
            patient.datamerged = true;
          }
          return patient;
        });
        if (this.wrongPatients?.length > 0) {
          this.dataSource = new MatTableDataSource(this.wrongPatients);
          this.dataSource.paginator = this.paginator;
        }
      }
    });
  }

  fetchAll(){
    this.toastr.info('Fetching All Duplicate Patients', 'Please Wait...');
    for (let i = 0; i < this.wrongPatients.length; i++) {
      this.findDuplicate(this.wrongPatients[i]);
    }
  }

  showPccDetailsInText(item: any) {
    const pccpayload = item.duplicate.pcc_payload;
    var pccData = pccpayload.orgUuid?.slice(-6) + ' - ' + pccpayload.facId?.toString()?.slice(-6) + ' - ' + item.duplicate?.pcc_patientId;
    return pccData;
  }

}


