import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { WoundService } from 'src/app/services/wound.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment'
import { CompanyService } from 'src/app/services/Modules/company.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import * as global from '../../global';


@Component({
  selector: 'app-wound-reports',
  templateUrl: './wound-reports.component.html',
  styleUrls: ['./wound-reports.component.css']
})
export class WoundReportsComponent implements OnInit {

  global = global;
  displayedColumns: string[] = ['sr', 'title', 'actions', 'permissions'];
  dataSource: any = new MatTableDataSource([]);
  params: any;
  companyId: any;
  loaderId = 'app-wound-reports';
  isDnsWeeklyReportRunning: boolean = false;
  woundReports: any = [
    // { title: 'DNS Report', navigationPath: 'dns-report' , url: 'dns-report' , selected:true , user_type:[]},
    { title: 'DNS Healing Report', navigationPath: 'dns-healing-report', url: 'dns-healing-report', selected: true, user_type: [] },
    { title: 'Wound Report by Etiology', navigationPath: 'pressure-wound-report', url: 'pressure-wound-report', selected: true, user_type: [] },
    { title: 'Custom Wound Report', navigationPath: 'custom-wound-report', url: 'custom-wound-report', selected: true, user_type: [] },
    { title: 'Wound Exceptions Report', navigationPath: 'wound-exceptions-report', url: 'wound-exceptions-report', selected: true, user_type: [] },
    // { title: 'DNS Tissue Analytic Report', navigationPath: 'dns-tissue-analytic-report', url: 'dns-report' ,selected:true, user_type:[]},
    { title: 'Post-Rounds Wound Report', navigationPath: 'dns-tissue-analytic-report', url: 'dns-tissue-analytic-report', selected: true, user_type: [] },
    { title: 'QAPI Report', navigationPath: 'qapi-report', url: 'qapi-report', selected: true, user_type: [] },
    // { title: 'Visit Summary Report', navigationPath: '' , url: '' ,selected:true, user_type:[]},
    // { title: 'Visit Report', navigationPath: 'visit-report' , url: 'visit-report' ,selected:true, user_type:[]},
    // { title: 'Discharged Patient Report', navigationPath: 'discharged-patient-report' , url: 'discharged-patient-report' ,selected:true, user_type:[]},
    // { title: 'Missed Facility Report', navigationPath: 'missed-facility-report' , url: 'missed-facility-report' ,selected:true, user_type:[]},
    // { title: 'Debridement Report', navigationPath: 'debridement-report' , url: 'debridement-report' ,selected:true, user_type:[]},
    // { title: 'Healing Success Rate Report', navigationPath: 'healing-success-rate-report' , url: 'healing-success-rate-report' ,selected:true, user_type:[]},
    // { title: 'HP Type Tabular Report', navigationPath: 'uwh-type-tabular-report' , url: 'uwh-type-tabular-report' ,selected:true, user_type:[]},
    // {title : 'Kent Visit Report', navigationPath: 'kent-visit-report', url: 'kent-visit-report' ,selected:true, user_type:[]},
    { title: 'Charges Report', navigationPath: 'admin-charges-report', url: 'admin-charges-report', selected: true, user_type: [] },
    { title: 'LOS Report', navigationPath: 'los-report', url: 'los-report', selected: true, user_type: [] },
    { title: 'IPA Report', navigationPath: 'ipa-report', url: 'ipa-report', selected: true, user_type: [] },
    { title: 'Operational Report', navigationPath: 'hp-operational-report', url: 'hp-operational-report', selected: false, user_type: [] },
    { title: 'Post Rounds Report V2', navigationPath: 'post-rounds-report', url: 'post-rounds-report', selected: true, user_type: [] },
    { title: 'Wound Assessment Dashboard', navigationPath: 'telehealth-wound-assessment-report', url: 'telehealth-wound-assessment-report', selected: true, user_type: [] },
    { title: 'Days To Remission Report', navigationPath: 'days-to-remission-report', url: 'days-to-remission-report', selected: true, user_type: [] },
    { title: 'Wound Outcome Report', navigationPath: 'wound-outcome-report', url: 'wound-outcome-report', selected: true, user_type: [] },

  ];
  settingsReports: any;
  quarterlyDateRange = {
    "q1": {
      creationDate: {
        startDate: "2022-01-01 00:00:00",
        endDate: "2022-03-31 23:59:59"
      }
    },
    "q2": {
      creationDate: {
        startDate: "2022-04-01 00:00:00",
        endDate: "2022-06-30 23:59:59"
      }
    },
    "q3": {
      creationDate: {
        startDate: "2022-07-01 00:00:00",
        endDate: "2022-09-30 23:59:59"
      }
    },
    "q4": {
      creationDate: {
        startDate: "2022-10-01 00:00:00",
        endDate: "2022-12-31 23:59:59"
      }
    }
  };
  filter: any = {
    quarter: "",
    companyId: null,
    creationDate: null,
  };
  currentUser: any;
  currentCompany: any = {};
  users: any[] = [
    { name: 'Provider', value: 'provider' },
    { name: 'Nurse', value: 'nurse' },
    { name: 'View Only User', value: 'view_only_user' },
    { name: 'SNF WC Nurse', value: 'snf_wc_nurse' },
    { name: 'Wound Nurse', value: 'wound_nurse' },
    { name: 'Medical Assistant', value: 'medical_assistant' },
  ];

  constructor(
    private _toastr: ToastrService,
    private _location: Location,
    private _route: ActivatedRoute,
    private _router: Router,
    private _woundService: WoundService,
    private loader: NgxUiLoaderService,
    private companyService: CompanyService,
    private _authService: AuthService,
    private commonService: CommonService,

  ) {
    // this._route.queryParams.subscribe(params => {
    //   this.params = params;
    // });
    this.companyId = this._route.parent.parent.snapshot.params.id;
    this.currentUser = this._authService.currentUser
    const currentMonth = moment().month() + 1;
    if (currentMonth >= 1 && currentMonth <= 3) {
      this.filter.quarter = "Q1";
    }
    else if (currentMonth >= 4 && currentMonth <= 6) {
      this.filter.quarter = "Q2";
    }
    else if (currentMonth >= 7 && currentMonth <= 9) {
      this.filter.quarter = "Q3";
    }

    else if (currentMonth >= 10 && currentMonth <= 12) {
      this.filter.quarter = "Q4";
    }
    this.onQuarterlyMenuChange(this.filter.quarter);

  }

  async ngOnInit() {
    const currentCompany = this.commonService.getLocalStorageItem('admin-Dashboard');
    if (currentCompany) {
      this.currentCompany = currentCompany;
    }
    this.settingsReports = [];
    this.loader.startLoader(this.loaderId);
    this.initDnsWeeklyReportJobStatus();

    this.dataSource.data = this.settingsReports;
    this.getSettings();
    if (this.commonService.getLocalStorageItem('admin-Dashboard'))
      this.currentCompany.company_type = this.commonService.getLocalStorageItem('admin-Dashboard').currentCompanyType;

    if (this.currentCompany.company_type === global.COMPANY_TYPE.SNF) {
      const index = this.displayedColumns.indexOf('nurse');
      if (index >= 0) {
        this.displayedColumns.splice(index, 1);
      }
      this.users = this.users.filter(user => user.name != 'Provider' && user.name != 'Nurse' && user.name != 'Wound Nurse');
    } else if (global.ENVIRONMENT == 'LOCAL' || global.ENVIRONMENT == 'WOUNDCARE_DEV') {
      this.users = this.users.filter(user => user.name != 'SNF WC Nurse');
    }
  }

  getLabel(users: any): string {
    const selectedCount = users.length;
    const userTypes = users.map(user => user.replace(/_/gi, ' '));
    if (selectedCount === 0) {
      return 'No Users selected';
    } else if (selectedCount === 1) {
      return userTypes[0];
    } else {
      return `${userTypes[0]} + (${selectedCount - 1} other${selectedCount > 2 ? 's' : ''})`;
    }
  }

  RemoveDuplicateIds(IdsArray) {
    console.log("Unique : ", IdsArray);
    let Unique = [];
    for (let id of IdsArray) {
      if (!Unique.includes(id?.toString())) {
        Unique.push(id?.toString());
      }
    }
    return Unique;
  }

  changeUserAccess(index, checked, user_type) {
    this.loader.startLoader(this.loaderId);
    if (index >= 0) {
      if (checked) {
        let array = this.settingsReports[index].user_type;
        this.settingsReports[index].user_type = this.RemoveDuplicateIds(array)
      } else {
        this.settingsReports[index].user_type = this.settingsReports[index].user_type.filter(item => item != user_type);
      }
      this.companyService.saveCompanyReportSettings(this.companyId, this.settingsReports).subscribe((response: any) => {
        this.getSettings();
        this.loader.stopLoader(this.loaderId);
      });
    }
  }

  onChangeOption(index, user_types, user_type) {
    const isChecked = user_types.includes(user_type);
    this.changeUserAccess(index, isChecked, user_type);
  }

  getSettings() {
    this.companyService.getCompanyReportSettings(this.companyId).subscribe((response: any) => {
      this.settingsReports = [];
      if (response.data?.length) {
        response.data.forEach(element => {
          const index = this.woundReports.findIndex(e => e.url == element.url);
          if (index >= 0) {
            this.woundReports[index].selected = element.selected;
            this.woundReports[index].user_type = element.user_type;
            if (element.selected) {
              this.settingsReports.push(this.woundReports[index]);
            }
          }
        });
        if (this.currentUser.company_type.toLowerCase() == "wound" || this.currentCompany.currentCompanyType === global.COMPANY_TYPE.SNF) {
          this.dataSource.data = this.settingsReports.filter(data => {
            if (data.title == 'DNS Healing Report') data.title = 'Post Rounds Report';
            if (data.title == 'Post-Rounds Wound Report') data.title = 'Wound Assessments';
            if (data.url === "pressure-wound-report" &&
              this.currentCompany.company_type !== this.global.COMPANY_TYPE.WOUND &&
              this.currentCompany.company_type !== this.global.COMPANY_TYPE.SNF) return;
            return data
          })
        } else {
          this.dataSource.data = this.settingsReports;
        }
      } else {
        this.dataSource.data = [];
      }
    })
  }

  checkNurseAccess(element) {
    if (element.user_type.includes('nurse')) {
      return true;
    } return false;
  }
  checkViewOnlyUserAccess(element) {
    if (element.user_type.includes(global.USER_TYPE.VIEW_ONLY_USER)) {
      return true;
    } return false;
  }
  checkProviderAccess(element) {
    if (element.user_type.includes('provider')) {
      return true;
    } return false;
  }

  initDnsWeeklyReportJobStatus() {
    this._woundService.getDnsWeeklyReportJobStatus({}).subscribe((response: any) => {
      if (response.status === 200) {
        if (response.data === "running") {
          this.isDnsWeeklyReportRunning = true;
        }
        else {
          this.isDnsWeeklyReportRunning = false;
        }
        this.loader.stopLoader(this.loaderId);
      } else {
        this.loader.stopLoader(this.loaderId);
        this._toastr.error(response.message, "ERROR");
      }
    }, err => {
      this.loader.stopLoader(this.loaderId);
      this._toastr.error(err, "ERROR");
    });
  }
  generateDnsWeeklyExcelReport() {
    this.loader.startLoader(this.loaderId);
    this._woundService.generateDnsWeeklyExcelReport({ companyId: this.companyId }).subscribe((response: any) => {
      if (response.status === 200) {
        this.isDnsWeeklyReportRunning = true;
        this.loader.stopLoader(this.loaderId);
        // this._toastr.success("Auto Report Generation Started", "Success");
      }
      else {
        this.loader.stopLoader(this.loaderId);
      }
    }, err => {
      this._toastr.error(err, "ERROR");
      this.loader.stopLoader(this.loaderId);
    });
  }
  deleteDnsWeeklyReportJob() {
    this.loader.startLoader(this.loaderId);
    this._woundService.deleteDnsWeeklyReportJob({}).subscribe((response: any) => {
      if (response.status === 200) {
        this.isDnsWeeklyReportRunning = false;
        this.loader.stopLoader(this.loaderId);
        // this._toastr.success("Auto Report Generation Stopped", "Success");
      }
      else {
        this.loader.stopLoader(this.loaderId);
      }
    }, err => {
      this._toastr.error(err, "ERROR");
      this.loader.stopLoader(this.loaderId);
    });
  }

  onQuarterlyMenuChange(value) {
    this.filter.quarter = value;
    const { creationDate } = this.quarterlyDateRange[value.toLowerCase()];
    this.filter.creationDate = creationDate;
    console.log("filter", this.filter);
  }
  generateQuarterlyQapiReport() {
    this.loader.startLoader(this.loaderId);
    this.filter.companyId = this.companyId;
    this._woundService.exportQuarterlyQapiReportAszip(this.filter).subscribe((response: any) => {
      if (response.status === 200) {
        const toastrHeading = "Qapi Report Status";
        if (response.data.isPending) {
          this._toastr.info("In Progress...", toastrHeading);
        }
        else {
          this._toastr.success("Started", toastrHeading);
        }
        this.loader.stopLoader(this.loaderId);
      }
      else {
        this.loader.stopLoader(this.loaderId);
      }
    }, err => {
      this._toastr.error(err, "ERROR");
      this.loader.stopLoader(this.loaderId);
    });
  }
  navigateToReportPage(element) {
    return this._router.navigate([`/company/${this.companyId}/${element.navigationPath}`]);
  }
}
