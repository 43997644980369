<div class="flex flex-col flex-grow">
  <div class="dark-blue-heading flex flex-wrap justify-between items-center">
    <div class="flex items-center gap-2">
      <h3>Google Drive  (Signed In as {{displayName}})
      </h3>
    </div>
    <div>
      <label for="files" style="cursor: pointer;">
        <mat-icon matTooltip="Upload File">
        add_to_drive
      </mat-icon>
        </label>
      <input type="file" style="display: none;" id="files" (change)="onSelectFile($event)">
    </div>
  </div>
  <div style="overflow: auto;">
    <ng-container>
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element" (click)="downloadGoogleFile(element)">
            <div class="template-folder-item flex items-center gap-2">
              <span class="item-icon">
                <img *ngIf="element" [src]="getItemIcon(element)"
                  onerror="this.src='../../../assets/img/filetypes/unknown.svg'" />
              </span>
              <div style="cursor: pointer;">{{element.name}}</div>
            </div>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="owners">
          <th mat-header-cell *matHeaderCellDef> Owner </th>
          <td mat-cell *matCellDef="let element"> {{element.owners[0].displayName}} </td>
        </ng-container>
  
        <ng-container matColumnDef="createdTime">
          <th mat-header-cell *matHeaderCellDef> Created Time </th>
          <td mat-cell *matCellDef="let element"> {{element.createdTime| date }} </td>
        </ng-container>
  
        <ng-container matColumnDef="SharedBy">
          <th mat-header-cell *matHeaderCellDef> Shared By </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.sharingUser"> {{element.sharingUser.displayName}} </ng-container>
            <ng-container *ngIf="!element.sharingUser"> N/A </ng-container>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="modifiedTime">
          <th mat-header-cell *matHeaderCellDef> Last Modified </th>
          <td mat-cell *matCellDef="let element"> {{element.modifiedTime| date }} </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      </table>
    </ng-container>
  </div>
</div>