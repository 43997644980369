import { Component } from '@angular/core';
import { RegisterService } from './register.service';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
import * as global from '../global'
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent {
  // implemented #mohsin-dev
  global=global;
  user_type:string="";
  title:string=""; // only for transcriber
  firstname:string="";
  lastname:string="";
  email:string="";
  mobile:string="";
  // phone_number:string="";
  password:string="";
  confirm_password:string="";
  passwordStrength: number = 0;
  passwordError:String="";
  submitted=false;
  passError:String="";

  // accountType: 'user'|'company' = 'user';
  accountType:  'user'|'company' = 'company';
  titles = ["M.D.", "D.O.", "PA-C", "ARNP", "R.N.", "N.P.", "P.A.", "L.Ac", "DNP", "CWS", "CWSP", "WOCN","ANP-BC", "CWCN"];
  titleControl = new FormControl();
  displayProviderFn(title): string {
      return title;
  }
  
  constructor(
      private _registerService:RegisterService,
      private _toastr:ToastrService,
      private _loginService:LoginService,
      private _router:Router
    ){}
  
  onRegister(registerForm) {
      const {
        user_type,
        title, // only for provider
        firstname,
        lastname,
        email,
        mobile,
        // phone_number,
        password,
        has_pcc_account
      } = registerForm.value;
      
      if(this.passwordStrength < 3) registerForm.controls['password'].setErrors({'incorrect': true})
      if(this.password !== this.confirm_password) registerForm.controls['confirm_password'].setErrors({'incorrect': true})
      if(!registerForm.valid || !registerForm.valid) return;

      //
      const details = {
            user_type: this.getUserType(user_type),
            account_type: this.accountType,
            title,
            firstname,
            lastname,
            email,
            mobile,
            // phone_number,
            password,
            profile_pic: "https://i.imgur.com/RXB5dFH.png",
            is_verified: 'false',
            email_notification: 'true',
            push_notification: 'true',
            registered_by: '26',
            email_auth_notification: 'true',
            sms_auth_notification: 'false',
            device_token: '',
            has_pcc_account
      };
      details.title = this.title;
      
      if(user_type === 'transcriber') {
        details['title'] = title;
      }

      const infoToastr = this._toastr.info('Registering...', 'Please wait',{disableTimeOut:true});
      // encrypt the password first
      this._loginService.getEncryptPassword(password).subscribe((passResponse:any) => {
        details['password'] = passResponse.encreptedPass; // change plain password with encrypted
        // register now
        console.log("Registering user: ", details);
        
        this._registerService.doRegister(details).subscribe((response:any) => {
          if(response.status === 200) {   
            const auth_key = response.data.web_auth_key + ".WEB"; 
            response.data.auth_key =  auth_key;
            localStorage.setItem('register_auth', JSON.stringify(response.data))
            this._router.navigate(['/email-verification']);
            // this._router.navigate(['/login']);
          } else {
            this._toastr.error(response.message, "Failed");
          }
        }, console.error, () => this._toastr.clear(infoToastr.toastId));

      }, (error) => {
        this._toastr.clear(infoToastr.toastId)
        console.error(error);
      });
  }
  onRegisterCompany(registerForm) {
    const {
      company_name,
      company_description,
      email,
      mobile,
      // phone_number,
      password,
      firstname,
      lastname,
    } = registerForm.value;
    
    if(this.passwordStrength < 3) registerForm.controls['password'].setErrors({'incorrect': true})
    if(this.password !== this.confirm_password) registerForm.controls['confirm_password'].setErrors({'incorrect': true})
    if(!registerForm.valid || !registerForm.valid) return;

    //
    const details = {
          title: this.titles[0],
          user_type: global.USER_TYPE.DOCTOR,
          account_type: this.accountType,
          company_name,
          company_description,
          email,
          mobile,
          // phone_number,
          password,
          firstname,
          lastname,
          profile_pic: "https://i.imgur.com/RXB5dFH.png",
          is_verified: 'false',
          email_notification: 'true',
          push_notification: 'true',
          registered_by: '26',
          email_auth_notification: 'true',
          sms_auth_notification: 'false',
          device_token: '',
    };


    const infoToastr = this._toastr.info('Registering...', 'Please wait',{disableTimeOut:true});
    // encrypt the password first
    this._loginService.getEncryptPassword(password).subscribe((passResponse:any) => {
      details['password'] = passResponse.encreptedPass; // change plain password with encrypted
      // register now
      console.log("registering company: ", details);
      
      this._registerService.doRegister(details).subscribe((response:any) => {
        if(response.status === 200) {   
          const auth_key = response.data.web_auth_key + ".WEB"; 
          response.data.auth_key =  auth_key;
          localStorage.setItem('register_auth', JSON.stringify(response.data))
          this._router.navigate(['/email-verification']);
          // this._router.navigate(['/login']);
        } else {
          this._toastr.error(response.message, "Failed");
        }
      }, console.error, () => this._toastr.clear(infoToastr.toastId));

    }, (error) => {
      this._toastr.clear(infoToastr.toastId)
      console.error(error);
    });
}
  getUserType(type) {
      if (type === 'provider' || type === 'company') {
          return '1';
      } else if (type === 'transcriber') {
          return '2';
      } else if (type === 'manager') {
          return '3';
      } else if (type === 'pa') {
          return '4';
      } else if (type === 'nurse') {
          return '5';
      } else if (type === 'biller') {
        return '6';
    }
  }

  strengthChange(passwordStrength) {
    this.passwordStrength = passwordStrength; 
  }

  isPasswordValid() {
    if(this.password != this.confirm_password) {
      this.passError = "Confirm Password does not match";
    } else
     if(this.passwordStrength < 3) {
      const strengthMsg:any = ["Password strength is low",
          "Password strength low",
          "Password should not contain common words"];
      this.passwordError = strengthMsg[String(this.passwordStrength)];
    } else {
      this.passwordError = "";
    }
  }
}
