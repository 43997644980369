<div [ngClass]="{'container': (companySide==true || user_dashboard)}">

    <h3 class="dark-blue-heading flex justify-between items-center">
        <ng-container *ngIf="!user_dashboard">
            <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
                Back
            </button>
        </ng-container>
        <div [ngClass]="{'label-margin': false}">
            <label style="white-space: nowrap;"> Charges Report </label>
        </div>
        <div class="filter-controls flex gap-2">
            <button mat-raised-button class="dn-button-primary" (click)="handleApplyFilter();">Apply Filter
            </button>

            <button mat-raised-button class="dn-button-secondary" (click)="resetFilter();">Reset Filter
            </button>
            <button mat-raised-button class="dn-button-primary" (click)="exportChargesReportAsXLSX();">Export
                As XLSX
            </button>
        </div>
    </h3>

    <div class="search-block theme-form filter-bar flex justify-start items-center gap-2"
        style="background-color: white; border-radius: 4px; padding-top: 8px; padding-bottom: 5px; padding-left: 8px;padding-right: 8px;">

        <div class="flex items-start justify-baseline">
            <mat-form-field appearance="fill">
                <mat-label>Report Type</mat-label>
                <mat-select [(ngModel)]="filter.reportType" (selectionChange)="filter.reportType=$event.value">
                    <mat-option value="monthly">Monthly</mat-option>
                    <mat-option value="daily">Daily</mat-option>
                </mat-select>
            </mat-form-field>

        </div>

        <div class="flex items-start justify-baseline">
            <mat-form-field appearance="fill" *ngIf="filter.reportType=='daily'">
                <mat-label>Select Month</mat-label>
                <mat-select [(ngModel)]="filter.selectedMonth"
                    (selectionChange)="filter.selectedMonth=$event.value">
                    <mat-option [value]="month" *ngFor="let month of monthName">{{month}}</mat-option>
                </mat-select>
            </mat-form-field>

        </div>

        <div class="flex items-start justify-baseline">
            <mat-form-field appearance="fill">
                <mat-label>Practices</mat-label>
                <mat-select value="true" [(ngModel)]="filter.company_id"
                    (selectionChange)="applyCompanyFilter($event)">
                    <mat-option *ngFor="let company of companies"
                        value="{{company._id}}">{{company.name}}</mat-option>
                </mat-select>
            </mat-form-field>

        </div>

        <div class="flex items-start justify-baseline">
            <mat-form-field appearance="fill">
                <mat-label>Select Provider</mat-label>
                <input type="text" placeholder="Select Provider" aria-label="provider" matInput
                    [formControl]="providerControl" #trigger="matAutocompleteTrigger"
                    [matAutocomplete]="providerAutoControl">
                <mat-icon matSuffix style="cursor: pointer;" (click)="showProviderDropdown()">
                    keyboard_arrow_down</mat-icon>
                <mat-autocomplete #providerAutoControl="matAutocomplete">
                    <mat-option *ngFor="let provider of filteredProviders | async" [value]="provider.full_name"
                        (click)="onSelectProvider(provider._id);">
                        {{provider.full_name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>

        <div class="flex items-start justify-baseline">
            <mat-form-field appearance="fill">
                <mat-label>Report Status</mat-label>
                <mat-select [(ngModel)]="filter.reportStatus" (selectionChange)="filter.reportStatus=$event.value">
                    <mat-option value="submit">Submit</mat-option>
                    <mat-option value="draft">Draft</mat-option>
                </mat-select>
            </mat-form-field>

        </div>
    
    </div>
    <div *ngIf="keys.length > 0 && !providerExists" class="flex flex-col gap-3">
        <div class="notes-group-dateofservice" *ngFor="let key of keys; let i = index;">
            <div class="flex flex-col gap-2">
                <div class="group-index-title">
                    <span>{{key}}</span>
                </div>
                <div class="card">
                    <table class="table">
                        <thead>
                            
                            <th>Facility</th>
                            <th>Encounter</th>
                        </thead>
                        <tbody>


                            <ng-container *ngFor="let data of chargesReport[key]; let j = index;">
                                <tr>
                                    <td *ngIf="providerExists">{{data.providerName}}</td>
                                    <td>{{data.facilityName}}</td>
                                    <td>{{data.encounter}}</td>

                                </tr>

                                <tr *ngIf="j == chargesReport[key].length - 1 && reportType === 'monthly'"
                                    style="background-color: #44597d;color: white; ">
                                    
                                    <td></td>
                                    <td>
                                        <b>
                                            Total Count: {{getTotalEncounter(chargesReport[key], 'monthly')}}
                                        </b>
                                    </td>
                                </tr>

                                <tr *ngIf="j == chargesReport[key].length - 1 && i == keys.length - 1 && reportType === 'daily'"
                                    style="background-color: #44597d;color: white; ">
                                    <td></td>
                                    <td>
                                        <b>
                                            Total Count: {{getTotalEncounter(chargesReport[key], 'daily')}}
                                        </b>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>

                </div>
            </div>

        </div>
    </div>

    <div *ngIf="keys.length > 0 && keys2.length > 0 && providerExists" class="flex flex-col gap-3">
        <div class="notes-group-dateofservice" *ngFor="let key of keys; let i = index;">
            <div class="group-index-title-2">
                <span>{{key}}</span>
            </div>

            <ng-container *ngFor="let key2 of keys2[i]; let j = index;">
                <div class="flex flex-col gap-2">
                    <div class="group-index-title">
                        <span>{{key2}}</span>
                    </div>
                    <div class="card">
                        <table class="table">
                            <thead>
                                <th>Facility</th>
                                <th>Encounter</th>
                            </thead>
                            <tbody>


                                <ng-container *ngFor="let data of chargesReport[key][key2]; let k = index;">
                                    <tr>
                                        <td>{{data.facilityName}}</td>
                                        <td>{{data.encounter}}</td>

                                    </tr>

                                    <tr *ngIf="k == chargesReport[key][key2].length - 1 && reportType === 'monthly'"
                                        style="background-color: #44597d;color: white; ">
                                        <td></td>
                                        <td>
                                            <b>
                                                Total Count: {{getTotalEncounter(chargesReport[key][key2], 'monthly')}}
                                            </b>
                                        </td>
                                    </tr>

                                    <tr *ngIf="k == chargesReport[key][key2].length - 1 && j == keys2[i].length - 1 && reportType === 'daily'"
                                        style="background-color: #44597d;color: white; ">
                                        <td></td>
                                        <td>
                                            <b>
                                                Total Count: {{getTotalEncounter(chargesReport[key], 'daily')}}
                                            </b>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>

                    </div>
                </div>
            </ng-container>




        </div>
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

</div>