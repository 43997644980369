import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as usaStates from 'src/app/shared/popup-dialogs/facility-add-dialog/usa_states';
import { CommonService } from '../services/common.service';
import { CompanyService } from '../services/Modules/company.service';
import { AuthService } from '../services/auth.service';
import {
	lastValueFrom,
	Observable,
	of
} from 'rxjs';
import { FacilityService } from '../services/facility.service';
import {
	FormControl
} from '@angular/forms';
import {
	startWith,
	map
} from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-appointment-add-patient-dialog',
  templateUrl: './appointment-add-patient-dialog.component.html',
  styleUrls: ['./appointment-add-patient-dialog.component.css']
})
export class AppointmentAddPatientDialogComponent implements OnInit {
  patientData: any = {}; // Object to hold form data
  company_id:any;
  genderIdenties = [
    { value: 'Male' },
    { value: 'Female' },
    { value: 'Transgender male' },
    { value: 'Transgender female' },
    { value: 'genderqueer' },
  ];
  marital_status_array = [
    { value: 'Single' },
    { value: 'Married' },
    { value: 'Divorced' },
    { value: 'Widowed' },
    { value: 'Legally Separated' },
    { value: 'Unknown' },
  ];
  races = [
    { value: 'American Indian/Alaska Native' },
    { value: 'Asian' },
    { value: 'Black/African American' },
    { value: 'Native Hawaiian' },
    { value: 'White' },
    { value: 'Patient Refused' },
    // { value: 'White, not of Hispanic Origin' },

  ]
  userCompany: any;
  usaStates = usaStates.default;
  searchState: any;
  filteredFacilities: Observable < string[] > ;
	facilities: Array < any > = [];
  facilityControl = new FormControl();
  constructor(public dialogRef: MatDialogRef < AppointmentAddPatientDialogComponent >,private commonService :CommonService, private companyService:CompanyService, private _authService: AuthService,private facilityService: FacilityService,@Inject(MAT_DIALOG_DATA) public data: any,    public route: ActivatedRoute,
  ) {
    this.usaStates.shift();
    const currentCompany = this.commonService.getLocalStorageItem('admin-Dashboard');
		if(currentCompany) {
		  this.company_id = currentCompany.currentCompanyId;
		}else {
      this.company_id = this.route.snapshot.firstChild.params.id;
    }
  }

  ngOnInit(): void {
    this.getCompanyName();
    this.initCompanyFacilities()
  }
  async getCompanyName() {
    const companyName = await this.companyService.getCompanyName(this.company_id).toPromise()
    this.userCompany = companyName.data.name;
    this.patientData.mrn = this.commonService.generateUniqueString(this.userCompany);
  }
  onSaveClick(): void {
    const { first_name, last_name, gender, date_of_birth, financial_class,facility_id,address } = this.patientData;
    
    if (first_name && last_name  && date_of_birth || facility_id && address) {
      this.dialogRef.close(this.patientData);
    }
  }
  dateOfBirth($event) {
    this.patientData.date_of_birth = $event;
  }
  handlePhoneModelChange(value) {
    let cleaned = value?.replace(/\D/g, '');
    if (cleaned?.length === 0) {
      cleaned = '';
    } else if (cleaned?.length <= 3) {
      cleaned = cleaned?.replace(/^(\d{0,3})/, '($1)');
    } else if (cleaned?.length <= 6) {
      cleaned = cleaned?.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else if (cleaned?.length <= 10) {
      cleaned = cleaned?.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    } else {
      cleaned = cleaned?.substring(0, 10);
      cleaned = cleaned?.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    }
    setTimeout(() => {

      this.patientData.phone = cleaned;
      // this.phoneCleaned = cleaned;
    })
  }
  allowOnlyText(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (event.charCode == 45 || event.charCode == 32) {
      return true;
    }
    if (charCode > 90 && charCode < 97) {
      return false;
    }

    if (charCode < 65 || charCode > 122) {
      return false;
    }
    return true;
  }
  keyPressAlphanumeric(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9\s/-_-.,-]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    else {
      return true;
    }
  }
  async initCompanyFacilities() {
		const company_id = this.company_id;

		const filter: any = {
			is_active: true,
			company_id,
			source: 'all',
			trueRcmStatus: 'all'
		};

		const projection = {
			'title': 1,
			'address': 1,
			'city': 1,
			'state': 1,
			'zip_code': 1,
			'provider_ids_ref': 1,
			// 'template_ids_ref': 1
		}
		let result: any = await this.facilityService.getAllFacilitiesProviderTemplateAssoc(filter, projection).toPromise();
		if (result.status == 200) {
			this.facilities = result.data.facilities;
			this.filteredFacilities = this.facilityControl.valueChanges.pipe(
				startWith(''),
				map((value: any) => this.filterFacilities(value))
			);
		} else {
			console.log('error', result);
		}
	}
  private filterFacilities(value: string): string[] {
		if (value) {
			const filterValue = value.toLowerCase();
			if (value.length == 1) {
				// this.showPccLogo = false;
			}
			return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) === 0);
		} else {
			return this.facilities;
		}
	}
  isPCCFacility(facility_id) {
    for (const facility of this.facilities) {
      if (facility_id === facility._id) {
        if (facility.pcc_facId) {
          return true;
        }
      }
    }
  }
  isMatrixFacility(facility_id){
    for(const facility of this.facilities) {
      if(facility_id === facility._id) {
        if(facility.source == 'MatrixCare') {
          return true;
        }
      }
    }
  } 
}
