import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { WoundService } from 'src/app/services/wound.service';
import { CreateWoundDialogComponent } from '../../create-wound-dialog/create-wound-dialog.component';
import { WoundCareWidgetService } from '../../wound-care-widget.service';
import { WoundImagePreviewDialogComponent } from '../../wound-image-preview-dialog/wound-image-preview-dialog.component';
import { calculateTimeDifference, calculateArea, calculateVolume, calculateAreaSubscore, calculateExudateAmount, calculateTissueType } from '../../wound-utils'
import swal from 'sweetalert';
import * as global from "../../../../global"
import { AuthService } from 'src/app/services/auth.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { DialogConfirmationComponent } from 'src/app/includes/census-patient-list/dialog-confirmation/dialog-confirmation.component';
import {MoveWoundToCensusComponent} from '../move-wound-to-census/move-wound-to-census.component'
import { PCCService } from 'src/app/services/pcc.service';
import { Subscription, lastValueFrom } from 'rxjs';
import { ProcedureNoteDialogComponent } from 'src/app/includes/procedure-notes/procedure-note-dialog/procedure-note-dialog.component';

@Component({
  selector: 'app-wound-care-widget-child-item',
  templateUrl: './wound-care-widget-child-item.component.html',
  styleUrls: ['./wound-care-widget-child-item.component.css']
})
export class WoundCareWidgetChildItemComponent implements OnInit, OnDestroy {

  @Input() provider;
  @Input() patient;
  @Input() note;
  @Input() childWound;
  @Input() mainWound;
  @Input() configuration;
  @Input() companyPermissions;
  @Input() readOnlyMode : any;
  @Input() actualParentWound;
  @Input() isPreview;
  @Output() WoundStatechange = new EventEmitter<any>();
  @Output() onICDsSave = new EventEmitter<any>();
  @Output() parentWoundDetailsChange = new EventEmitter<any>();
  @Output() showChildWoundReport = new EventEmitter<any>();
  @Output() procedureNoteDetailsChange: EventEmitter<any> = new EventEmitter();
  @Output() archiveWound = new EventEmitter<any>();
  @Output() refreshWounds = new EventEmitter<any>();
  @Input() childWoundsLoaderId;
  @Input() changeWoundStateEvent;
  @Input() should_show_view_icon;
  @Input() skinSubRefferal;
  mainImage: string;
  global = global;
  currentUser: any;
  dialogSubscription: any;
  isAdminSide: boolean =  false;
  isTeleHealthReport: boolean = false;
  telehealthReportSubscription: Subscription;
  should_show_wound_location: boolean = false;

  constructor(
    private dialog: MatDialog,
    private woundCareWidgetService: WoundCareWidgetService,
    private woundService: WoundService,
    private _authService: AuthService,
    private loader: NgxUiLoaderService,
    private toastr: ToastrService,
    private _pccService: PCCService,
    private _toaster: ToastrService,
  ) {
    if (window.location.pathname.includes('company')) {
      this.isAdminSide = true;
    }
   }

  ngOnInit(): void {
    console.log('skinSubRefferalskinSubRefferalskinSubRefferal', this.skinSubRefferal)
    this.currentUser = this._authService.currentUser;
    this.mainImage = this.childWound.wound_images[0];
    this.telehealthReportSubscription = this.woundCareWidgetService.isTeleHealthReport$.subscribe(value => {
      this.isTeleHealthReport = value;
    });
    this.should_show_wound_location = this.companyPermissions.should_show_wound_location;
  }

  ngOnDestroy() {
    if (this.dialogSubscription) {
      this.dialogSubscription.unsubscribe();
    }
    this.telehealthReportSubscription.unsubscribe();
  }

  calculateTimeDifference(wound) {
    return calculateTimeDifference(wound, this.actualParentWound);
  }
  calculateArea(wound) {
    return calculateArea(wound);
  }
  calculateVolume(wound) {
    return calculateVolume(wound);
  }
  handleChildWoundClick() {
    this.showWoundCreateDialog(this.childWound);
  }

  async lockWoundManually(wound){
    const response:any = await this.woundService.lockWoundManually(wound._id);
      if(response.status === 200){
        this.actualParentWound['is_wound_locked'] = true;
        this.childWound['is_wound_locked'] = true;
        this.toastr.success("Wound locked successfully");
          this.parentWoundDetailsChange.emit({_id: this.actualParentWound._id, is_wound_locked: true})
      }else{
        this.toastr.error("Wound could not lock.");
      }
  }

  async showWoundCreateDialog(childWound) {
    console.log("this.childWound", childWound, this.mainWound);
    let wound = null;
    if(childWound._id.toString() === this.actualParentWound._id.toString()){
      const response:any = await this.woundService.getWoundfromParentSchema(childWound._id);
      if(response.status === 200 && response.data){
        wound = response.data;
      }else{
        wound = childWound
      }
    }else{
      wound = childWound
    }
    if(this.checkLockConditions()){
        wound['is_wound_locked'] = true;
    }else{
      wound['is_wound_locked'] = false;
    }
    let updatePhrasesData = false;
    if(this.note.note_status.status === 'published' && this.mainWound && this.mainWound.child_wounds[0]._id.toString() === wound._id.toString()){
      updatePhrasesData = true;
    }else if((this.note.date_obj.day === wound.last_assessment_date_obj.day && this.note.date_obj.month === wound.last_assessment_date_obj.month && this.note.date_obj.year === wound.last_assessment_date_obj.year) && this.mainWound && this.mainWound.child_wounds[0]._id.toString() !== wound._id.toString()){
      updatePhrasesData = true;
    }else if((this.note.date_obj.day === wound.last_assessment_date_obj.day && this.note.date_obj.month === wound.last_assessment_date_obj.month && this.note.date_obj.year === wound.last_assessment_date_obj.year) && this.mainWound && this.mainWound.child_wounds[0]._id.toString() === wound._id.toString()){
      updatePhrasesData = true;
    }else if(this.note.note_status.status === 'uploaded'){
      updatePhrasesData = true;
    }
    console.log("updatePhrasesData", updatePhrasesData)
    const actualWoundState = this.mainWound === undefined ? childWound.state : this.mainWound.state
    const dialogRef = this.dialog.open(CreateWoundDialogComponent, {
      maxWidth: '98vw',
      maxHeight: '90vh',
      height: '85%',
      width: '98%',
      panelClass: 'full-screen-modal',
      data: {
        provider: this.provider,
        patient: this.patient,
        wound: wound,
        childWound: wound, 
        onICDsSave: this.onICDsSave,
        checkParentWoundUpdate: true,
        updatePhrasesData,
        actualWoundState,
        should_show_wound_location: this.should_show_wound_location
      }
    });
    dialogRef.afterClosed().subscribe(async (data) => {
      if (data?.childWound) {
        if(childWound._id.toString() !== this.actualParentWound._id.toString()){
            this.childWound = Object.assign(this.childWound, data.childWound);
        }
        // if (data.wound.state != data.state && (wound._most_recent_wound || childWound._id.toString() === this.actualParentWound._id.toString() || (['remission','amputated'].includes(data.state)))) {
        if (data.wound.state != data.state) {
          if(actualWoundState !== data.state){
            this.WoundStatechange.emit(data.childWound);
            this.woundCareWidgetService.setState(true);
          }
        }
      }
      if(data.updateData){
        if(wound.is_clone){
          data.updateData['_id'] = wound.parent_wound;
          data.updateData['addendum_id'] = wound._id;
        }
        this.parentWoundDetailsChange.emit(data.updateData)
      }
    });
  }

  showWoundImagePreviewDialog(wound, imageIndex = 0) {
    const dialogRef = this.dialog.open(WoundImagePreviewDialogComponent, {
      width: 'auto',
      data: { wound: wound, imageIndex: imageIndex, mainImage: this.mainImage }
    });
  }

  async downloadChildWoundPDF() {
    this.woundService.exportWoundAsPdf(this.childWound._id, null, this.patient).
      subscribe(async (data: any) => {
        // this.wound.child_wounds = child_wounds;
        if (data instanceof HttpResponse) {
          console.log("in httpresponse")
          var url = window.URL.createObjectURL(data.body);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          let date = 'no_date';
          if (this.childWound.created_At) {
            date = moment(this.childWound.created_At).format('YYYY-MM-DD');
          } else if (this.childWound.createdAt) {
            date = moment(this.childWound.createdAt).format('YYYY-MM-DD');
          }
          a.download = `${this.configuration.wound_assessment_report_name}_${date}`;

          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
        }
        else {
          console.log("in else")
          if (data.type === 0) {
          } else if (data.type === 3) {
            const { loaded, total } = data;
            const progress = Math.round(loaded / total * 100);
            if (progress > 0) {
            }
          }
        }
        if (data.status == 200) {
          console.log("in status")
          return true;
        }

      }
      )
  }

  openConfirmationDialog(wound, state) {
    const dialogRef = this.dialog.open(DialogConfirmationComponent, {
      width: '25rem',
      data: {
        message: "Confirm to archive this wound?"
      }
    });
    this.dialogSubscription = dialogRef.afterClosed().subscribe(response => {
      if (response === "true") {
        this.changeWoundState(wound, state);
      }
    });
  }

  changeWoundState(wound, state) {
    // this.loader.startLoader(this.childWoundsLoaderId);
    let data = {
      state: state,
      wound_ids: [wound._id]
    }

    this.woundService.changeMultipleWoundsState(data).subscribe(async (res: any) => {
      if (res.status === 200) {
        wound.state = state;
        if (!wound.parent_wound) {
          this.changeWoundStateEvent.emit(this.actualParentWound);
        }

        // this.wound
        if (wound.parent_wound) {
          const index = this.mainWound.child_wounds.findIndex((childWound) => childWound._id.toString() === wound._id.toString() )
          let allActiveChildWounds = this.mainWound.child_wounds.filter((w) => w.state === 'active');
          let activeIndex = allActiveChildWounds.findIndex((childWound) => childWound._id.toString() === wound._id.toString() );
          const changeWoundField = [
            'etiolgy', 'body_part', 'body_side', 'direction', 'exposed_tissues', 'periwound', 'eschar', 'granulation', 'slough', 'epithelial', 'infection_signs',
            'exudate_type', 'exudate', 'exposed_structure', 'odor', 'wound_recognition_undetermined', 'wound_recognition_date', 'on_set_date', 'wound_recognition_date_type',
            'pain', 'primary_treatment', 'secondary_dressing', 'change_frequency', 'pressure_reduction/off_loading', 'dressings', 'cleanse_wound_with', 'wound_status'];
            if(state == 'archived'){
              if((activeIndex === 0 && allActiveChildWounds.length >= 2)){
                let updateObj = {};
                const secondLatestWound = allActiveChildWounds[1];
                for (const key in wound) {
                  if (Object.prototype.hasOwnProperty.call(secondLatestWound, key) && changeWoundField.includes(key)) {
                    const value = secondLatestWound[key];
                    updateObj[key] = value;
                  }
                }
                await lastValueFrom(this.woundCareWidgetService.updateWoundData(this.actualParentWound._id, updateObj , true));
                this.parentWoundDetailsChange.emit({_id: this.actualParentWound._id, ...updateObj});
                this.refreshWounds.emit();
              } else if (allActiveChildWounds.length === 0 && this.actualParentWound.parentWound) {
                console.log(this.actualParentWound.parentWound);
                let updateObj = {};
                const mainWound = this.actualParentWound.parentWound;
                for (const key in wound) {
                  if (Object.prototype.hasOwnProperty.call(mainWound, key) && changeWoundField.includes(key)) {
                    const value = mainWound[key];
                    updateObj[key] = value;
                  }
                }
                await lastValueFrom(this.woundCareWidgetService.updateWoundData(this.actualParentWound._id, updateObj, true));
                this.parentWoundDetailsChange.emit({ _id: this.actualParentWound._id, ...updateObj });
                this.refreshWounds.emit();
              }else if(this.mainWound._id.toString() === wound._id.toString() && allActiveChildWounds.length >= 1){
                let updateObj = {};
                const secondLatestWound = allActiveChildWounds[0];
                for (const key in wound) {
                  if (Object.prototype.hasOwnProperty.call(secondLatestWound, key) && changeWoundField.includes(key)) {
                    const value = secondLatestWound[key];
                    updateObj[key] = value;
                  }
                }
                await lastValueFrom(this.woundCareWidgetService.updateWoundData(this.actualParentWound._id, updateObj , true));
                this.parentWoundDetailsChange.emit({_id: this.actualParentWound._id, ...updateObj});
                this.refreshWounds.emit();
              }
              if(index > -1){
                this.mainWound.child_wounds[index].state = state
              }
              
            }else{
              if(index > -1){
                this.mainWound.child_wounds[index].state = state
              }
              let allActiveChildWounds = this.mainWound.child_wounds.filter((w) => w.state === 'active');
              let activeIndex = allActiveChildWounds.findIndex((childWound) => childWound._id.toString() === wound._id.toString() )
              let updateObj = {};
              if(activeIndex === 0){
                for (const key in wound) {
                  if (Object.prototype.hasOwnProperty.call(wound, key) && changeWoundField.includes(key)) {
                    const value = wound[key];
                    updateObj[key] = value;
                  }
                }
                await lastValueFrom(this.woundCareWidgetService.updateWoundData(this.actualParentWound._id, updateObj , true));
                this.parentWoundDetailsChange.emit({_id: this.actualParentWound._id, ...updateObj});
                this.refreshWounds.emit();
              }
            }
        }
        this.woundCareWidgetService.setState(true);
        // this.loader.stopLoader(this.childWoundsLoaderId);
      }
      else {
        // this.loader.stopLoader(this.childWoundsLoaderId);
        this.toastr.error("Something Went Wrong", "ERROR");
      }
    });
  }
  calculateAreaSubscore(childWound){
    return calculateAreaSubscore(childWound);
  }

  calculateExudateAmount(childWound){
    return calculateExudateAmount(childWound);
  }
  calculateTissueType(childWound){
    return calculateTissueType(childWound);
  }
  viewScores() {
    swal({
      title: "Sub-Scores",
      text: `Area-SubScore : ${this.calculateAreaSubscore(this.childWound)} 
      Exudate-SubScore: ${this.calculateExudateAmount(this.childWound)}
      Tissue-SubScore: ${this.calculateTissueType(this.childWound)}`,
      dangerMode: true,
      buttons: [, 'Close'],
    })
      .then(() => {
      });
  }
  downloadSingleImage(){
    let filename= this.childWound.wound_images[0]?.split('/');
    if(filename)
    filename=filename[filename?.length-1]?.split('?')[0];
    if(!filename) {
      this._toaster.warning('No Wound Image Found');
      return;
    }
    const details = [{
      patient_id: this.childWound.patient_id,
      filename: filename,
      woundNo: this.actualParentWound.woundNo,
      wound_id: this.actualParentWound._id,
      patient_name: `${this.patient.last_name}, ${this.patient.first_name}`,
      dob: this.patient.date_of_birth,
      dos: this.actualParentWound.date_of_service,
      created_at: this.childWound.created_At,
      wound_location: this.actualParentWound.body_part,
      facility_name: this.note ? this.note.facility.title : this.actualParentWound.facility_id_ref.title
    }]
    this.woundCareWidgetService.downloadWoundImagesZip(details).subscribe((response: any) => {
      if (response instanceof HttpResponse) {
          var url = window.URL.createObjectURL(response.body);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = `Single Wound Image.pdf`;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
      }else{
      }
    });
    console.log(details);
  }
  moveWoundToCensus(childWound) {
    const dialogRef = this.dialog.open(MoveWoundToCensusComponent, {
      width: '500px',
      data: childWound
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  isDnFacility() {
    let source;
    if (this.note) {
      if (this.note.facility.source) {
        source = this.note.facility.source
      }
    }
    else
      source = this.actualParentWound.facility_id_ref.source;
    if (source == 'PointClickCare' || source == 'MatrixCare') {
      return false;
    }
    return true;
  }
  hasChildWounds() {
    return this.childWound?.child_wounds?.length > 0
  }

  canUploadReport() {
    // console.log('canUploadReport', this.note,this.note?.facility?.source)
      if(this.note?.facility?.source === 'PointClickCare') {
        // console.log(this._pccService.isLoggedInFacility(this.note.facility), this._authService.canUploadReportToPCCUsingTwoLegged());
        return this._pccService.isLoggedInFacility(this.note.facility) || this._authService.canUploadReportToPCCUsingTwoLegged();
      } else if(this.note?.facility?.source == 'MatrixCare') {
        return true;
      }else{
        return false;
      }
  }
  checkLockConditions(){
    if(this.childWound.is_clone && this.childWound.is_wound_locked){
      return true
    }else if(this.childWound.is_clone){
      return false
    }else if(this.childWound.is_wound_locked){
      return true
    }else{
     return !this.childWound._most_recent_wound && this.actualParentWound.state !== 'archived'
    }
  }

  updatePatientSkinSubRefferal() {
    if(!this.childWound.skin_relation) {
      swal({
        title: "Skin Sub Referral",
        text: 'A Skin Sub Notification has been sent to your admin.',
        // icon: "success",
        // dangerMode: false,
        buttons: [
          "No", "Yes"
        ],
      })
      .then(async (res) => {
            
        if (res) {
          let data = {
            wound_id: this.actualParentWound._id,
            is_parent_wound: true,
          }
          let response: any = lastValueFrom(this.woundCareWidgetService.updateWoundData(this.childWound._id,{skin_relation: true}, true));
          this.childWound.skin_relation = true;
        }
              
      })
    }
  }
  showProcedureNoteDialog() {
    let procedureDialogRef = this.dialog.open(ProcedureNoteDialogComponent, {
      height: '95vh',
      data: {
        wound_id: this.childWound._id,
        company_id: this.currentUser.company_id,
        patient_id: this.note.patient._id,
        facility_id: this.note.facility._id,
        parent_wound: this.actualParentWound._id,
        created_by: this.note.provider._id,
        census_id: this.note.rounding_sheet_id,
        location: this.actualParentWound.body_part,
        date_obj: this.childWound.last_assessment_date_obj
      }
    });
    procedureDialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        this.childWound.totalProcedureNotes = data.totalProcedureNotes;
        this.procedureNoteDetailsChange.emit(data);
      }
    });
  }
}