<div id="container-dataset" class="flex space-between gap-2 h-full">
    <mat-card>
        <mat-card-content>
            <div class="col-header">
                <strong>Questions</strong>
                <ng-container *ngIf="!dataSetQuestionChange">
                    <button mat-raised-button class="dn-button-primary"
                        (click)="dataSetQuestionChange = true; cancelAnswer()">
                        Add Question
                    </button>
                </ng-container>
            </div>

            <div class="col-content">
                <div *ngFor="let dataSet of allDataSet;let i = index" [class.active-item]="dataSet.selected === true">
                    <div class="item flex justify-between items-center" (click)="selectDataSet(dataSet)">
                        <div>{{dataSet.question}}</div>
                        <div class="action-buttons">
                            <span class="material-icons" (click)="editDataSet(dataSet)">edit</span>
                            <span class="material-icons" (click)="DeleteQuestionDialog(dataSet)">delete</span>
                        </div>
                    </div>
                </div>

                <div *ngIf="dataSetQuestionChange" class="add flex gap-4">
                    <mat-form-field>
                        <textarea matInput [(ngModel)]="question" rows="2"></textarea>
                    </mat-form-field>
                    <div class="add-action-buttons">
                        <button mat-raised-button class="dn-button-primary" (click)="addNewQuestion()">Confirm</button>
                        <button mat-raised-button color="warn" class="dn-button-primary" (click)="cancelQuestion()">Cancel</button>
                    </div>
                </div>
            </div>

        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-content>
            <div class="col-header">
                <strong>Answers</strong>
                <ng-container *ngIf="!dataSetAnswerChange">
                    <button mat-raised-button class="dn-button-primary" (click)="dataSetAnswerChange = true">
                        Add Answers
                    </button>
                </ng-container>
            </div>

            <div class="col-content">
                <div *ngFor="let answer of selectedDataSet?.answers;let i = index">
                    <div class="item flex justify-between items-center">
                        <div>{{answer.value}}</div>
                        <div class="action-buttons">
                            <span class="material-icons" (click)="editAnswer(answer)">edit</span>
                            <span class="material-icons" (click)="DeleteAnswerDialog(answer, i)">delete</span>
                        </div>
                    </div>
                </div>

                <div *ngIf="dataSetAnswerChange" class="add flex gap-4">
                    <mat-form-field>
                        <textarea matInput [(ngModel)]="answer" rows="2"></textarea>
                    </mat-form-field>
                    <div class="add-action-buttons">
                        <button mat-raised-button class="dn-button-primary" (click)="confirmAnswer()">Confirm</button>
                        <button mat-raised-button color="warn" class="dn-button-primary" (click)="cancelAnswer()">Cancel</button>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>