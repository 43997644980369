<div class="template-headings-wrap flex flex-col gap-2">

    <input matInput type="text" class="search-input" placeholder="Search Template Headings" [(ngModel)]="searchText" (keyup)="applyFilter()" />

    <div
        *ngFor="let headingObj of displayHeadings; let i = index"
        class="heading-wrap flex items-center justify-start flex-grow gap-2"
    >
        <div class="counter">{{ i + 1 }}</div>

        <div class="text flex-grow">
            <small *ngIf="showRelevantPhrase( headingObj )">{{ headingObj.relevantPhrase }}</small>
            <span>{{ headingObj.headingText }}</span>
        </div>

        <button
            class="dn-button-primary"
            mat-raised-button
            (click)="applyHeading( headingObj )"
        >
            Select
        </button>
    </div>
</div>