import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as global from '../global';
import { AuthService } from "src/app/services/auth.service";
import {API_URL} from "../../../environments/api-url";

@Injectable({
    providedIn: 'root',
  })
  export class RRGenExplorerService {
    currentUser:any=null;
    userInfo:any = {};
    
    constructor(private httpclient: HttpClient,
      private _authService: AuthService) {
      // const currentUser = localStorage.getItem('currentUser');
      const currentUser = this._authService.currentUser;
      if(currentUser) {
        // this.currentUser = JSON.parse(currentUser);
        this.currentUser = currentUser;
        if(this.currentUser) {
          this.userInfo.id = this.currentUser._id;
          this.userInfo.usertype = this.currentUser.user_type;
          this.userInfo.auth_key = this.currentUser.auth_key;
        }
      }
    }

    getTemplatesByFacilityId(facility_id) {
      const payload = {
        ...this.userInfo,
        facility_id
      };
      return this.httpclient
          .post(global.url + API_URL.TEMPLATE.getTemplatesByFacilityId, {template: payload}).pipe();
    }


    getFilesAndFoldersByParentFolderId(parent_folder_id) {
      const payload = {
        ...this.userInfo,
        parent_folder_id
      };
      return this.httpclient.post(global.url_documents + '/getFilesAndFoldersByParentFolderId', {payload: payload}).pipe();
    }
    deleteTemplate(template_id) {
      const payload = {
        ...this.userInfo,
        template_id,
        is_deleted: 'true',
        updation_date: new Date()
      };
      return this.httpclient.post(global.url + API_URL.TEMPLATE.deleteTemplate, {template: payload}).pipe();
    }

    downloadTemplate(template_id) {
      const payload = {
        ...this.userInfo,
        email:this.currentUser.email,
        template_id,
      };
      return this.httpclient.post(global.url + '/downloadTemplate', {template: payload},{
        responseType: 'blob'
      }).pipe();
    }

    updateItemDescription(item_id, description, item_type) {
      const payload = {
        ...this.userInfo,
        item_id,
        description,
        item_type,
        updation_date: new Date()
      };
      return this.httpclient.post(global.url + API_URL.DOCUMENT.updateItemDescription, {item: payload}).pipe();
    }
    createNewFolder(folder) {
      const payload = {
        ...this.userInfo,
        ...folder,
        drive_name: 'drive-rrgen'
      };
      return this.httpclient.post(global.url_documents + '/createFolder', {folder: payload}).pipe();
    }

    createFolderAndUploadFilesToRRGen(insurance_company_id, folderName, files, uploadingOptions) {
      return new Promise((resolve, reject) => {
        const formData: any = new FormData();
        const xhr = new XMLHttpRequest();
        // formData.append(type, file);
        /** In Angular 5, including the header Content-Type can invalidate your request */
        
        formData.append('id', this.currentUser._id);
        formData.append('auth_key', this.currentUser.auth_key);
        formData.append('usertype', this.currentUser.user_type);

        let other = 1;
        for (let i = 0; i < files.length; i++) {
          const type = files[i].fileData.type;
          if(type === "Medical Record" || type === 'Cover Letter') {
            formData.append(type, files[i].file);
          } else if(type === 'Other') {
            formData.append(`file${other}`, files[i].file);
            other++;
          }
        }
        for (const key in uploadingOptions) {
          if (uploadingOptions.hasOwnProperty(key)) {
            formData.append(key, uploadingOptions[key]);
          }
        }
        formData.append('insurance_comapny_id', insurance_company_id)
        formData.append('dropped_folder', folderName)
        formData.append('drive_name', 'drive-rrgen')
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    resolve(JSON.parse(xhr.response));
                } else {
                    reject(xhr.response);
                }
            }
        };
        xhr.open('POST', global.url + API_URL.RRGEN.uploadFilesAndStartProcess, true);
        xhr.send(formData);
    });
    }

  }
