import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-daily-appointment-list',
  templateUrl: './daily-appointment-list.component.html',
  styleUrls: ['./daily-appointment-list.component.css']
})
export class DailyAppointmentListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
