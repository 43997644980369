<form #form="ngForm" (ngSubmit)="handleSubmit(form)">
    <div class="flex gap-5"     style="place-content: space-between;" >
        <button mat-raised-button class="dn-button-secondary" type="button" (click)="goBack()">Go Back</button>
        <button mat-raised-button class="dn-button-primary" type="submit" [disabled]="!form.valid">Save</button>
    </div>
    <br />
    <div class="fields-sections flex flex-col">
        
        <div class="fields-section">
            <mat-form-field class="field-item">
                <input matInput placeholder="First Name" [(ngModel)]="model.first_name" name="first_name" (keypress)="allowOnlyText($event)">
            </mat-form-field>
            <mat-form-field class="field-item">
                <input matInput placeholder="Last Name" [(ngModel)]="model.last_name" name="last_name" (keypress)="allowOnlyText($event)">
            </mat-form-field>

            <mat-form-field class="field-item">
                <mat-label>Title</mat-label>
                <input matInput type="text" [(ngModel)]="model.title" name="title" [matAutocomplete]="titleAutoComplete"
                style="cursor: pointer;" [formControl]="titleControl">
                <mat-icon matSuffix style="cursor: pointer;">keyboard_arrow_down</mat-icon>
                <mat-autocomplete [displayWith]="displayProviderFn"
                                #titleAutoComplete="matAutocomplete">
                                <mat-option *ngFor="let title of titles" [value]="title">
                                    {{title}}
                                </mat-option>
                            </mat-autocomplete> 
                <mat-error *ngIf="!form.value.title">Please select title</mat-error>
            </mat-form-field>
        </div>

        <div class="fields-section">
            <div>
                <mat-form-field class="field-item" *ngIf="!isEditing" style="width: 25rem;">
                    <input matInput placeholder="Office Phone" [(ngModel)]="model.office_phone" name="office_phone" readonly>
                </mat-form-field>

                <mat-form-field class="field-item" *ngIf="isEditing" style="margin-right: 10px;width: 10rem;">
                    <mat-label>Select Country</mat-label>
                    <mat-select (selectionChange)="countryFilter($event.value)" required name="country">
                        <mat-option *ngFor="let country of countries" 
                        value="{{country}}">{{country}}
                    </mat-option>
                    </mat-select>
                    <mat-error *ngIf="!form.value.country">Please select Country</mat-error>
                </mat-form-field>

                <mat-form-field class="field-item" *ngIf="isEditing" style="width: 10rem;">
                    <input matInput type="text" id="mobile" name="mobile" #mobile="ngModel" [(ngModel)]="model.office_phone"
                    (ngModelChange)="handlePhoneModelChange($event)" (paste)="onPaste($event)" [maxlength]="maxMobileLength"
                    placeholder="Mobile Number" required (keypress)="keyPressNumbers($event)" [disabled]="!selectedCountry">
                <mat-error *ngIf="mobile.invalid && (mobile.dirty || mobile.touched)">Please enter valid mobile
                </mat-error>
                </mat-form-field>

                <span *ngIf="isEditing" (click)="updatePhone()" style="color: blue ; cursor: pointer;"> Save </span>
                <span *ngIf="!isEditing && currentUser.product_owner" (click)="edit()" style="color: blue ; cursor: pointer;"> Edit </span>
                <span *ngIf="isEditing" (click)="cancel()" style="color: rgb(193, 77, 77) ; cursor: pointer;"> Cancel </span>
            </div>
            <mat-form-field class="field-item">
                <input matInput placeholder="Secondary Phone" [(ngModel)]="model.secondary_phone" name="secondary_phone">
            </mat-form-field>
            <mat-form-field class="field-item">
                <input matInput placeholder="Work Email" [(ngModel)]="model.work_email" name="work_email" readonly>
            </mat-form-field>


        </div>
        
        <div class="fields-section">
            <mat-form-field class="field-item">
                <input matInput placeholder="Secondary Email Address" [(ngModel)]="model.secondary_email_address" name="secondary_email_address">
            </mat-form-field>

            
            <mat-form-field class="field-item">
                <input matInput placeholder="Time Zone" value="America / Los Angeles" readonly>
            </mat-form-field>

            <mat-form-field class="field-item">
                <input matInput placeholder="NPI" [(ngModel)]="model.npi" name="npi" [required]="isMandatoryNPI"
                    onkeypress="return (event.charCode > 47 && event.charCode < 58) || (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)">
                <mat-error *ngIf="!form.value.npi">Please enter NPI</mat-error>
            </mat-form-field>
            <mat-form-field class="field-item">
                <input matInput placeholder="TIN" [(ngModel)]="model.tin" name="tin" [readonly]="true">
            </mat-form-field>
        </div>


        <div *ngIf="model.title=='N.P.'||model.title=='P.A.'||model.title=='NP'||model.title=='PA'">
            <span style="font-weight: 500;margin-top: 10px;">Supervising Provider Details</span>
            <div class="fields-section">
                <mat-form-field class="field-item">
                    <input matInput placeholder="Name" name="supervisingProvider" [(ngModel)]="supervisingProvider">
                </mat-form-field>
                <mat-form-field class="field-item">
                    <input matInput placeholder="trueRCMID" name="supervisingProviderTrueRCMID" [(ngModel)]="supervisingProviderTrueRCMID">
                </mat-form-field>
            </div>
        </div>

        <div style="border-bottom: 5px solid #eee;" *ngIf="!hidePermissions"><br /></div>
    </div>
    <ngx-ui-loader [loaderId]="doctorProfileInformationLoader"></ngx-ui-loader>
</form>