import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FacilityService } from 'src/app/services/facility.service';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-company-add-facility',
  templateUrl: './company-add-facility.component.html',
  styleUrls: ['./company-add-facility.component.css']
})
export class CompanyAddFacilityComponent implements OnInit {
  associate;
  provider_id;
  facility;

  constructor(private facilityService: FacilityService, private toastr: ToastrService, private route?: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
        this.associate = params['associate'];
        this.provider_id = params['provider_id'];
      }
    )
  }

  getNewFacility(newFacility) {
    this.facility = newFacility;
    if (this.associate == 'yes') {
      this.updateFacilityProviderTempalteAssoc(this.facility.data._id, this.provider_id, []);
    }
  }
  async updateFacilityProviderTempalteAssoc(facility, provider, templates, template_id?, provider_id?) {
    this.facilityService.updateFacilityProviderTempalteAssoc(facility, [provider], templates, template_id, provider_id).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.info("Relation Updated")
      }
    })
  }
}
