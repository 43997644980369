import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../../includes/global';
import { API_URL } from './../../../environments/api-url';


@Injectable({
  providedIn: 'root'
})
export class TicketService {

  company_id : any;
  
  constructor(private httpclient: HttpClient, private _authService: AuthService) { 
    this.company_id = _authService.currentUser.company_id;
  }

  addTicket(data) {
    const details = {
      ...this._authService.authObject,
      url: global.base_url,
      data: data,
      company_id : this.company_id
    }
    return this.httpclient.post(global.url + API_URL.TICKET.addTicket, { details: details }).pipe();
  }

  editTicket(ticket_id, data) {
    const details = {
      ...this._authService.authObject,
      ticket_id: ticket_id,
      data: data,
    }
    return this.httpclient.post(global.url + API_URL.TICKET.editTicket, { details: details }).pipe();
  }

  getTickets(pageNo?, user_id?, status?, priority?, company_id?) {
    const details : any  = {}
    if(pageNo) {
      details.pageNo = pageNo;
    }
    if(user_id) {
      details.user_id = user_id;
    }
    if(status) {
      details.status = status;
    }
    if(priority) {
      details.priority = priority;
    }
    if(company_id) {
      details.company_id =  company_id;
    }
    console.log('details',details);
    return this.httpclient.post(global.url + API_URL.TICKET.getTickets, { details: details }).pipe();
  }

  deleteTicket(ticket_id) {
    const details = {
      ...this._authService.authObject,
      ticket_id
    }
    return this.httpclient.post(global.url + API_URL.TICKET.deleteTickets, { details: details }).pipe();
  }

  filterStatus(status) {
    const details = {
      ...this._authService.authObject,
      status
    }
    return this.httpclient.post(global.url + API_URL.TICKET.filterStatus, { details: details }).pipe();
  }

  filtering(status, priority, user_id, company_id ,pageNo?) {
    const details :any = {
      status,
      priority,
      user_id,
      company_id
    }
    if(pageNo) {
      details.pageNo = pageNo;
    }
    return this.httpclient.post(global.url + API_URL.TICKET.filtering, { details: details }).pipe();
  }

  filterStatusUser(status, user_id) {
    const details = {
      ...this._authService.authObject,
      status,
      user_id
    }
    return this.httpclient.post(global.url + API_URL.TICKET.filterStatusUser, { details: details }).pipe();
  }

  changePriority(priority) {
    const details = {
      ...this._authService.authObject,
      priority
    }
    return this.httpclient.post(global.url + API_URL.TICKET.changePriority, { details: details }).pipe();
  }

  changePriorityUser(priority, user_id) {
    const details = {
      ...this._authService.authObject,
      priority,
      user_id
    }
    return this.httpclient.post(global.url + API_URL.TICKET.changePriorityUser, { details: details }).pipe();
  }

  changeUserType(user_type) {
    const details = {
      ...this._authService.authObject,
      user_type
    }
    return this.httpclient.post(global.url + API_URL.TICKET.changeUserType, { details: details }).pipe();
  }

  filterUserName(user_id) {
    const details = {
      ...this._authService.authObject,
      user_id
    }
    return this.httpclient.post(global.url + API_URL.TICKET.filterUserName, { details: details }).pipe();
  }

  changeStatus(ticket_id, status) {
    const details = {
      ...this._authService.authObject,
      ticket_id,
      status
    }
    return this.httpclient.post(global.url + API_URL.TICKET.changeStatus, { details: details }).pipe();
  }

  postComments(ticket_id, comment, creatorName) {
    const details = {
      ...this._authService.authObject,
      ticket_id,
      comment,
      creatorName
    }
    return this.httpclient.post(global.url + API_URL.TICKET.postComment, { details: details }).pipe();
  }

  getComments(ticket_id) {
    const details = {
      ...this._authService.authObject,
      ticket_id,
    }
    return this.httpclient.post(global.url + API_URL.TICKET.getComments, { details: details }).pipe();
  }

  deleteComment(_id) {
    const details = {
      ...this._authService.authObject,
      _id,
    }
    return this.httpclient.post(global.url + API_URL.TICKET.deleteComment, { details: details }).pipe();
  }

  editComments(_id, newComment) {
    const details = {
      ...this._authService.authObject,
      _id,
      newComment
    }
    console.log('EDIT COMMENT IN SERVICE', details);
    return this.httpclient.post(global.url + API_URL.TICKET.editComment, { details: details }).pipe();
  }
}

