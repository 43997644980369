import { Injectable } from "@angular/core";
import { API_URL } from "../../environments/api-url";
import * as global from '../includes/global';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import moment from 'moment'
@Injectable({
    providedIn: 'root'
})
export class ElasticSeachService {
    constructor(private _http: HttpClient) {
    }
    searchCPTCode(seachKey) {
        return this._http.post(global.url + API_URL.ELASTIC_SEARCH.searchCPTCode, {query: seachKey}).pipe();
    }
    searchICDCode(seachKey, isShowProvAssocIcds = false, provider_ids_ref?, company_id?) {
        let details = { query: seachKey };
        if (isShowProvAssocIcds)
            details['isShowProvAssocIcds'] = isShowProvAssocIcds;
        if (provider_ids_ref)
            details['provider_ids_ref'] = provider_ids_ref;
        if (company_id)
            details['company_id'] = company_id;
        
        return this._http.post(global.url + API_URL.ELASTIC_SEARCH.searchICDCode, details).pipe();
    }
    searchICDCodewithPagination(searchKey, size, from, should_show_active_icds = true, company_id?) {
        var data = {
            query: searchKey,
            size: size,
            from: from,
        }
        if (company_id)
            data['company_id'] = company_id;

        return this._http.post(global.url + API_URL.ELASTIC_SEARCH.searchICDCodewithPagination, { data: data }).pipe(
            map((response: any) => {
                if (response.status === 200) {
                    response.data.map(icd => {
                        if (icd.end_date) {
                            const [month, day, year] = icd.end_date.split("/");
                            if (moment(`${year}-${month}-${day}`).isBefore(moment())) {
                                icd.isExpired = true;
                            }
                        }
                        return icd;
                    });
                    if (should_show_active_icds)
                        response.data = response.data.filter(icd => icd.isExpired == undefined)
                    return response;
                }
            })
        );
    }
}