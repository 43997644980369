import { Component, OnInit } from '@angular/core';
import * as global from '../global'
import { PatientListService } from '../patient-list/patient-list-service';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { BillerDashboardService } from '../biller-dashboard/biller-dashboard.service';
import { lastValueFrom } from 'rxjs';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { CensusListService } from '../census-list/census-list..service';
@Component({
  selector: 'app-census-both',
  templateUrl: './census-both.component.html',
  styleUrls: ['./census-both.component.css']
})
export class CensusBothComponent implements OnInit {
  global = global;
  currentUser;
  currentUserDetails;
  defaultTabIndex: Number = 0;
  calView: any;
  admin: Boolean = false;
  company: Boolean = false;
  chargeStatus: Boolean = false;
  detect_refresh_child;
  chargesResponse: any;
  draftCount: any;
  missingFaceSheetsCount: any;
  newSelectedProvider: void;
  addendumReqCount: any;
  isNurseUser: boolean = false;
  mipsData: any = [];
  userMips: any = [];
  selectedMip: any;
  censusData: any;
  userId: any = '';
  userCompany: any;

    constructor(private _authService: AuthService,
    private route: ActivatedRoute,
    private billerDashboardService: BillerDashboardService,
    private companyService: CompanyService,
    private censusService: CensusListService
    ) {

      this.calView=localStorage.getItem('calView');


      console.log("calView : router", this.calView);
      if(this.calView=='true'){
        this.defaultTabIndex=1;
        localStorage.setItem('calView','false');

      }
      this.currentUser = this._authService.currentUser;
      if (this.currentUser.charge_status) {
        this.chargeStatus = true;
      }
      this.currentUserDetails = {
        id: this.currentUser._id,
        auth_key: this.currentUser.auth_key,
        usertype: this.currentUser.user_type
      }
      if (this.currentUser.user_type.toLowerCase() === 'nurse') {
        this.isNurseUser = true;
      }
     }

  async ngOnInit() {
    const filter = {
      _id: this.currentUser.company_id
    };
    const project = {
      name: 1,
      enable_company_mips: 1,
      company_type: 1,
      should_show_census_charges_count: 1
    };
    const populate = {
      permissions: "key value"
    };
    const response: any = await lastValueFrom(this.companyService.getCompany(filter, project));
    this.userCompany = response.data;
    if (window.location.pathname.includes('admin')) {
      this.admin = true;
    }
    else if (window.location.pathname.includes('company')) {
      this.company = true;
    }
    else {
      this.admin = false;
      if(this.userCompany.enable_company_mips) {
        this.getUserEnabledMips(this.currentUser._id);
      }
    }
    // await this.getCount(null);
    // await this.getMissingFacesheets(null);
  }

  ngOnDestroy(){
    console.log("ng destroy : ");

  }

  getUserEnabledMips(id) {
    this.userId = id || this.currentUser._id;
    let obj: any = { providerId: this.userId}
    // this.mipsService.getMipsDetails(this.currentUser.company_id, obj).subscribe((data: any) => {
    //   console.log('data............',data)
    //   this.mipsData = data.mipsCount;
    // })
    this.censusService.getUserEnabledMips(id).subscribe((data: any) => {
      console.log('mips data', data);
      this.userMips = data.enabled_mips;
      // this.selectedMip = this.userMips[0];
    })
  }

  async getCount(provider_id) {
    let filter = {
      patient: '',
      dos: null,
      facility: '',
      provider: '',
      facesheetFilter: 'all',
      // chargeType: 'submit'
      chargeType: ''
      // is_archived: falsek
    }
    let doctorId = provider_id ? provider_id : this._authService.currentUser._id;
    let res: any = await this.billerDashboardService.getCount(doctorId, filter).toPromise()
    if (res.status == 200) { 
      this.chargesResponse = res;
      this.draftCount = res.draftCount;
    }
  }

  async getMissingFacesheets(provider_id) {
    let ownerId =  provider_id ? provider_id : this._authService.currentUser._id;
    let companyId = this._authService.currentUser.company_id;
    let filter = {
      chargeType: "submit",
      dos: null,
      facesheetFilter: "upload",
      facility: "",
      patient: "",
      provider: ""
    }
    let res: any = await this.billerDashboardService.getMissingFacesheets(1, ownerId, companyId, filter).toPromise()
    if (res.status == 200) { 
      this.missingFaceSheetsCount = res.totalCount;
    }
  }
  
  async getAddendumReqCount(provider_id) {
    let ownerId =  provider_id ? provider_id : this._authService.currentUser._id;
    let companyId = this._authService.currentUser.company_id;
    let filter = {
      company_id: companyId,
      owner_id : ownerId,
      bill_status : 'addendum_req',
      is_active : true
    }
    let res: any = await this.billerDashboardService.getAddendumReqCount(filter).toPromise()
    if (res.status == 200) { 
      this.addendumReqCount = res.count;
    }
  }

  async refreshCounts(provider_id) {
    this.newSelectedProvider = provider_id;
    await this.getCount(provider_id);
    await this.getMissingFacesheets(provider_id);
    await this.getAddendumReqCount(provider_id);
  }

  onMipsSelect(data) {
    data.loading = true;
    this.getSelectedMipData(data);
  }

  getSelectedMipData(mipData) {
    this.censusService.getSelectedMipData(this.censusData, this.currentUser.company_id, this.userId, mipData).subscribe((data: any) => {
      // console.log('getSelectedMipData',data)
      mipData.loading = false;
      if(data.uniqueObjects.length > 0) {
        mipData.count = data.uniqueObjects[0].encountPercentage
      }
      else {
        mipData.count =  0;
      }
      
      // this.mipsData = data.uniqueObjects;
    })
  }

  mipsUpdate(event) {
    if(this.userCompany.enable_company_mips) {
      this.getUserEnabledMips(this.userId);
      this.censusData = event;
    }
    
  }

}
