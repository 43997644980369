<form #form="ngForm" (ngSubmit)="handleSubmit(form)">
    <div class="flex flex-col fields-sections">
        <div class="fields-section">

            <mat-form-field class="field-item">
                <input matInput placeholder="Title" [(ngModel)]="model.title" name="title" required/>
            </mat-form-field>

            <mat-form-field class="field-item">
                <input matInput placeholder="Address" [(ngModel)]="model.address" name="address"/>
            </mat-form-field>

            <mat-form-field class="field-item">
                <input matInput placeholder="Street" [(ngModel)]="model.street" name="street"/>
            </mat-form-field>
            <mat-form-field class="field-item">
                <input matInput placeholder="City" [(ngModel)]="model.city" name="city"/>
            </mat-form-field>
            <mat-form-field class="field-item">
                <mat-select placeholder="State" [(ngModel)]="model.state" name="state">
                    <mat-option *ngFor="let state of usa_states; let i = index" [value]="numberToString(i+1)">
                        {{state.abbreviation}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="field-item">
                <input matInput placeholder="Zip Code" [(ngModel)]="model.zip_code" name="zip_code"/>
            </mat-form-field>
        </div>
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

    <br/>

    <div class="flex justify-end gap-5">
        <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
            Go Back
        </button>
        <button mat-raised-button class="dn-button-primary" type="submit">
            Save
        </button>
    </div>
    <div class="flex items-center">
        <button
                mat-raised-button
                (click)="openDialog()"
                type="button"
                class="dn-button-primary">
            Add more Contact
        </button>
    </div>
    <br/>
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="name">
            <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header-container>
                Name
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.last_name + ' ' + element.first_name}}
            </td>
        </ng-container>

        <ng-container matColumnDef="position">
            <th

                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header-container>
                Position
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.position}}
            </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th

                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header-container>
                Email
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.email}}
            </td>
        </ng-container>

        <ng-container matColumnDef="phone">
            <th

                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header-container>
                Phone
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.phone}}
            </td>
        </ng-container>
        
        <ng-container matColumnDef="operations">
            <th  mat-header-cell *matHeaderCellDef></th>
            <ng-container *matCellDef="let element">
                <td mat-cell style="max-width: 120px;" class="buttons-div">
                    <div class="operation-buttons buttons-div flex flex-row items-center gap-5">
                        <i
                                class="icon icon-edit"
                                matTooltip="Edit"
                                matTooltipPosition="above"
                                (click)="editFacilityContact(element)"
                        ></i>
                    </div>
                </td>
            </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</form>
