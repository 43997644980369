<div [ngClass]="{'container': (companySide || isUserDashboard) && !(nursingSide)}">
    <h3 class="dark-blue-heading flex justify-between items-center">
        <ng-container *ngIf="!isUserDashboard && !nursingSide">
            <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
                Back
            </button>
        </ng-container>

        <div [ngClass]="{'label-margin': !isUserDashboard}">
            <label style="white-space: nowrap;">
                {{isSummaryReport ? 'Summary Pressure Report' : 'Wound Report by Etiology'}}
            </label>
        </div>

        <div class="flex justify-center items-end" *ngIf="isUserDashboard">
            <input matInput type="text" ngxDaterangepickerMd [(ngModel)]="filter.updationDate" class="mat-like-input"
                [ranges]="preDateRanges" [alwaysShowCalendars]="true" [maxDate]="currentDate" [linkedCalendars]="true"
                [showClearButton]="false" (datesUpdated)="changeUpdationDate($event)" class="date-range-picker"  />
        </div>

        <div class="flex gap-2">
            <mat-icon style="cursor: pointer;" (click)="exportPressureWoundReportAsXLSX('download');" matTooltip="Export As XLSX"
                matTooltipPosition="above"> download</mat-icon>
                <mat-icon style="cursor: pointer;" (click)="exportPressureWoundReportAsXLSX('email');" matTooltip="Send via Email"
                matTooltipPosition="above"> outgoing_mail</mat-icon>
        </div>

    </h3>

    <div class="search-block theme-form filter-bar flex justify-start items-center"
        style="background-color: white; border-radius: 4px; padding-top: 8px; padding-bottom: 5px; padding-left: 8px;padding-right: 8px;">

        <div class="flex flex-grow justify-start items-center gap-2">
            <mat-form-field *ngIf="!isSummaryReport" appearance="fill" class="date-width">
                <mat-label>Select Wound Type</mat-label>
                <mat-select (selectionChange)="changeEtiology($event.value)" [value]="filter.etiolgy" multiple>
                    <mat-checkbox (change)="selectAllWoundTypes($event)" style="padding-left: 16px; font-size: 25px !important;" name="allWoundTypes"
                    [(ngModel)]="masterSelected" [value]="-1"> Select All
                </mat-checkbox>
                    <mat-option *ngFor="let etiology of etiologies" [value]="etiology">{{etiology}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="date-width">
                <mat-label>Select Date Type</mat-label>
                <mat-select (selectionChange)="filter.dateType = $event.value;" [value]="filter.dateType">
                    <mat-option value="updatedAt">Updated On</mat-option>
                    <mat-option value="last_assessment_date_obj">Date Of Service</mat-option>
                </mat-select>
            </mat-form-field>
            
            <div *ngIf="!isUserDashboard">
                <input style="width: 180px;" type="text" ngxDaterangepickerMd [(ngModel)]="filter.updationDate"
                    (datesUpdated)="changeUpdationDate($event)" class="mat-like-input" [ranges]="preDateRanges"
                    [alwaysShowCalendars]="true" [maxDate]="currentDate" [linkedCalendars]="true"
                    [showClearButton]="false" />
            </div>

            <mat-form-field appearance="fill" class="sm:w-1/2 md:w-full">
                <mat-label>Select Facility</mat-label>
                <input  id="facilityControlInput" type="text" placeholder="Select Facility" aria-label="facility" matInput
                    [formControl]="facilityControl" [matAutocomplete]="facilityAutoControl">
                <mat-autocomplete #facilityAutoControl="matAutocomplete" autoActiveFirstOption>
                    <mat-option (onSelectionChange)="onSelectFacility($event, facility);"
                        *ngFor="let facility of filteredFacilities | async" [value]="facility.title">
                        {{facility.title}}
                    </mat-option>
                </mat-autocomplete>

            </mat-form-field>

            <button mat-raised-button class="dn-button-primary btn-width" (click)="handleApplyFilter();">Apply
            </button>

            <button mat-raised-button class="dn-button-secondary btn-width" (click)="resetFilter();">Reset
            </button>
        </div>
    </div>

    <mat-card class="vertical-height" *ngIf="!isSummaryReport">
        <div class="table-height">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                <ng-container matColumnDef="room_number">
                    <th mat-header-cell *matHeaderCellDef> Room # </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.patient_id.last_room_num}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Last Name, First Name </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.patient_id.full_name}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="body_part">
                    <th mat-header-cell *matHeaderCellDef> Wound Location </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.body_part}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="etiolgy">
                    <th mat-header-cell *matHeaderCellDef> Etiology </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.etiolgy}}
                    </td>
                </ng-container>


                <ng-container matColumnDef="wound_recognition_date">
                    <th mat-header-cell *matHeaderCellDef> Facility Admission Date </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.wound_recognition_date}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="last_assessment_date_obj">
                    <th mat-header-cell *matHeaderCellDef> Initial Wound Assessment Date </th>
                    <td mat-cell *matCellDef="let element; let i = index;">{{element.patient_id.initial_wound_assessment_date_obj}}</td>
                </ng-container>

                <ng-container matColumnDef="stage">
                    <th mat-header-cell *matHeaderCellDef> Stage/Severity </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.stage}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="wound_status">
                    <th mat-header-cell *matHeaderCellDef> Wound Status </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.wound_status}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="wound_recognition_date_type">
                    <th mat-header-cell *matHeaderCellDef> Facility Acquired? </th>
                    <td mat-cell *matCellDef="let element; let i = index;" class="text-center">
                        {{element.wound_recognition_date_type}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="push_score">
                    <th mat-header-cell *matHeaderCellDef> Push Score </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.push_score}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="push_trend">
                    <th mat-header-cell *matHeaderCellDef> Push Trend </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.push_trend}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="key_assessment_parameters">
                    <th mat-header-cell *matHeaderCellDef> Key Assessment Parameters </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.key_assessment_parameters}}
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div class="flex justify-between items-center bottom-row">
                <b>
                    No of Patients : {{pagination.totalRecords}}
                </b>
                <b>
                    No of Active Wounds : {{pagination.totalRecords}}
                </b>
                <div class="flex justify-between items-center gap-1">
                    <button mat-raised-button class="dn-button-primary" (click)="goToPreviousPage()"
                        [disabled]="!pagination.hasPrevPage"> Prev</button>

                    <span>
                        {{pagination.skip + report.length === 0 ? 0 : pagination.skip + 1 }} - {{pagination.skip +
                        report.length}} out of {{pagination.totalRecords}}
                    </span>
                    <button mat-raised-button class="dn-button-primary" (click)="goToNextPage()"
                        [disabled]="!pagination.hasNextPage">Next</button>
                </div>
            </div>
        </div>
        <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
    </mat-card>

    <mat-card class="vertical-height" *ngIf="isSummaryReport">
        <div class="flex flex-col justify-center items-start gap-1" style="font-size: 16px;">
            <b>Summary: </b>
            <div class="flex justify-between items-center gap-2" style="width: 50%;">
                <div class="flex flex-col justify-start items-start gap-1">
                    <div>Number of Patients: </div>
                    <div>Total Number of Active Wounds: </div>
                    <div>Facility Acquired Wounds: </div>
                    <div>Present on Admission: </div>
                </div>
                <div class="flex flex-col justify-start items-start gap-1">
                    <div>{{summary.total}}</div>
                    <div>{{summary.active}}</div>
                    <div>{{summary.facility_aquired}}</div>
                    <div>{{summary.present_on_admission}}</div>
                </div>
            </div>
            <b> Breakdown by Status:</b>
            <div class="flex justify-between items-center gap-2" style="width: 50%;">
                <div class="flex flex-col justify-start items-start gap-1">
                    <div>Remission: </div>
                    <div>Amputated: </div>
                    <div>Closed: </div>
                </div>
                <div class="flex flex-col justify-start items-start gap-1">
                    <div>{{summary.remission}}</div>
                    <div>{{summary.amputated}}</div>
                    <div>{{summary.closed}}</div>
                </div>
            </div>
            <b>Breakdown by Stage: Pressure </b>
            <div class="flex justify-between items-center gap-2" style="width: 50%;">
                <div class="flex flex-col justify-start items-start gap-1">
                    <div>Stage 1: </div>
                    <div>Stage 2: </div>
                    <div>Stage 3: </div>
                    <div>Stage 4: </div>
                    <div>Unstageable: </div>
                    <div>Deep Tissue Pressure Injury: </div>
                </div>
                <div class="flex flex-col justify-start items-start gap-1">
                    <div>{{summary.stage1}}</div>
                    <div>{{summary.stage2}}</div>
                    <div>{{summary.stage3}}</div>
                    <div>{{summary.stage4}}</div>
                    <div>{{summary.unstageable}}</div>
                    <div>{{summary.dti}}</div>
                </div>
            </div>
        </div>
        <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
    </mat-card>
</div>