
    <mat-card class="vertical-height">
        <form (ngSubmit)="submitForm()" class="profile-space">
            <div class="flex items-center">
                <button mat-raised-button class="dn-button-secondary" type="button" (click)="goBack();">Back</button>
            </div>
            <br>
            <div class="flex gap-2">
                <div class="w-1/2 flex flex-col gap-2">
                    <div class="flex gap-2">
                        <mat-form-field style="width: 48%">
                            <mat-label>Name</mat-label>
                            <input matInput type="text" name="name" [value]="company?.name" (input)="onNameChange($event.target.value)">
                        </mat-form-field>
                    </div>

                    <div class="flex gap-2">
                        <mat-form-field style="width: 48%">
                            <mat-label>Provider</mat-label>
                            <input matInput type="text" name="provider" #provider="ngModel"
                                [ngModel]="company?.providers?.providerCount" readonly>
                        </mat-form-field>
                    </div>
                    <div style="width: 48%" class="flex justify-between items-center">
                        <b>
                            Logo
                        </b> &nbsp;
                        <div class="flex justify-between items-center">
                            <input name="logo" (change)="onLogoSelect($event)" hidden accept="image/*" type="file"
                                id="logo" #logo />
                            <img [src]="logoSrcPath" *ngIf="logoSrcPath" style="width: 100px; margin-right: 45px;">
                            <a *ngIf="!isLogoRemovebuttonVisible" #file_uploader_btn style="cursor: pointer;"
                                type="button" (click)="logo.click()">
                                {{ companyLogoPath != '' ? 'Change Logo' : 'Upload Logo' }}</a>
                            <a *ngIf="isLogoRemovebuttonVisible" style="cursor: pointer;" (click)="logo.click()">Change</a>
                            <a *ngIf="isLogoRemovebuttonVisible" style="cursor: pointer;color:rgb(189, 0, 0);margin-left: 5px;" (click)="onLogoDeselect(logo)">Remove</a>
                        </div>
                    </div>
                    <img [src]="companyLogoPath" alt="logo" *ngIf="companyLogoPath && !isLogoRemovebuttonVisible" style="width: 48%;height: 48%;">
                </div>
                <div class="w-1/2 flex flex-col gap-2">
                    <div class="flex gap-2">
                        <mat-form-field style="width: 48%">
                            <mat-label>Owner Name</mat-label>
                            <input matInput type="text" name="lastName" #lastName="ngModel"
                                [ngModel]="companyOwnerFullName" readonly>
                        </mat-form-field>
                    </div>
                    <div class="flex items-center">
                        <mat-form-field style="width: 48%">
                            <mat-label>Facility</mat-label>
                            <input matInput type="text" name="facility" #facility="ngModel"
                                [ngModel]="company?.facilities?.facilityCount" readonly>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="field-item" style="width: 48%">
                            <input matInput placeholder="TIN" [(ngModel)]="company.tin" name="tin" [value]="company.tin"
                                (input)="onTinChange($event.target.value)">
                        </mat-form-field>
                    </div>
                    <div style="width: 48%" class="flex justify-between items-center">
                        <b>
                            Monogram
                        </b> &nbsp;
                        <div class="flex items-center">
                            <input name="monogram" (change)="onMonogramSelect($event)" hidden accept="image/*"
                                type="file" id="monogram" #monogram />
                            <img [src]="monogramSrcPath" *ngIf="monogramSrcPath"
                                style="width: 100px; margin-right: 45px;">
                            <a *ngIf="!isMonogramRemovebuttonVisible" style="cursor: pointer;"
                                type="button" (click)="monogram.click()">
                                {{ companyMonogramPath != '' ? 'Change Monogram' : 'Upload Monogram' }}</a>
                            <a *ngIf="isMonogramRemovebuttonVisible" style="cursor: pointer;" (click)="monogram.click()">Change</a>
                            <a *ngIf="isMonogramRemovebuttonVisible" style="cursor: pointer;color:rgb(189, 0, 0);margin-left: 5px;" (click)="onMonogramDeselect(monogram)">Remove</a>
                        </div>
                    </div>
                    <img [src]="companyMonogramPath" alt="logo" *ngIf="companyMonogramPath && !isMonogramRemovebuttonVisible" style="width: 48%;height: 48%;">
                    <div class="flex items-center">
                            <button mat-raised-button type="button" class="dn-button-primary" type="submit"
                            [disabled]="disableButton()">Save</button>
                    </div>
                </div>
            </div>
        </form>
    </mat-card>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
