import { Component, ViewChild, ChangeDetectorRef, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '../dashboard/dashboard.service';
import * as global from '../global';
import { TranscriptionCreateService } from '../transcription-create/transcription-create.service';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/services/auth.service';
import {MatSort, Sort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import { PatientRoundingSheetService } from '../patient-rounding-sheets/patient-rounding-sheet.service';
import { MatDialog } from '@angular/material/dialog';
import {PatientRoundingSheetCommentsDialog} from '../../shared/popup-dialogs/patient-roundingsheet-comments-dialog/patient-roundingsheet-comments.component';
import {AudioListDialog} from '../../shared/popup-dialogs/audio-list-dialog/audio-list-dialog.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import moment from 'moment'
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { Patient } from 'src/app/classes/patient';
import { PatientRoundingSheetPatientListService } from './patient-rounding-sheet-patient-list.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators'

@Component({
  selector: 'app-patient-rounding-sheet-patient-list',
  templateUrl: './patient-rounding-sheet-patient-list.component.html',
  styleUrls: ['./patient-rounding-sheet-patient-list.component.css']
})


export class PatientRoundingSheetPatientListComponent implements OnInit {
  loading: boolean = false;
  global = global;
  patientRoundingSheet:any;

  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any>;
  currentUser: any;
  filterDropdownActive = false;
  dateOfBirth:any;

  params: {
    id:string
  }

  loaderId = 'episodes-of-care-loader';

  filterSeen:'seen'|'not_seen'|'all' = 'all';
  filterSort:'seen'|'not_seen'|'room_no'|'new_patient'|
  'status'|'name'|'dob'|'room_no'|'admission_date'|'admission_date'|'discharge_date'|'payor';

  filterSearch = "";

  patientsByProvider:Patient[] = [];
  
  patientsByProviderControl = new FormControl();
  filteredPatientsByProvider: Observable<any[]>;
  
  constructor(
    private _patientRoungingSheetService: PatientRoundingSheetService,
    private _router: Router,
    private _toastr: ToastrService,
    private _changeDetectorRefs: ChangeDetectorRef,
    private transcriptionCreateService: TranscriptionCreateService,
    public commonService: CommonService,
    private authService: AuthService,
    private route:ActivatedRoute,
    private dialog: MatDialog,
    private loader: NgxUiLoaderService,
    private patientRoundingSheetService:PatientRoundingSheetService,
    private _location: Location,
    private patientRoundingSheetPatientListService: PatientRoundingSheetPatientListService
  ) {
    this.currentUser = this.authService.currentUser;
    this.params = {
      id: this.route.snapshot.params.id
    };
  }
  @ViewChild(MatSort) sort: MatSort;



  async ngOnInit() {
    // JSON.parse removed causing parse errors for patients i.e JSON.parse(this.currentUser)
    this.currentUser = this.currentUser;
    if (!this.currentUser) {
      // if not login, stop and redirect to login #mohsin-dev
      localStorage.removeItem('currentUser');
      this._router.navigate(['/login']);
      return;
    }
    // show operations column only for doctor
    // if(this.currentUser.user_type == 1) {
      this.displayedColumns = [ 'switch-status', 'name', 'charge-status', 'date_of_birth', 'location', 'admission_date',  'discharge_date', 'payer', 'comments_count', 'operations'];
      // remove // 'status',  'source',
    // } else {
    // this.displayedColumns = ['name', 'date_of_birth', 'gender'];
    // }
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <any>null,
      primary_doctor_id: <string>null
    };
    item.id = this.currentUser._id;
    item.auth_key = this.currentUser.auth_key;
    item.usertype = this.currentUser.user_type;


    await this.initPatientRoundingSheet();

    this.patientRoundingSheetPatientListService.castFilterSort.subscribe(value => {
      this.filterSort = value;
      this.doSortPatients();
    })
    this.patientRoundingSheetPatientListService.castFilterSearch.subscribe(value => {
      this.filterSearch = value;
      this.applyFilter(value);
    })

    this.initPatientsByProvider()
  }

  openCharges(patient) {
      let patient_id = patient._id;
      let rs_id = this.params.id;
      this._router.navigate(['/patient-charge'], {
          queryParams: {
              patient_id: patient_id,
              rs_id: rs_id,
              visit_date: this.patientRoundingSheet.date.toString(),
              patient_name: patient.name,
            facility_id: patient.facility_id
          }
      });
  }

  async initPatientRoundingSheet() {

    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,

      patientRoundingSheetId: this.params.id
    }

    const sanitizedDetails = this.commonService.sanitizeObjectInput(details, ['id', 'patientRoundingSheetId']);
    this.loader.startLoader(this.loaderId);
    const patientRoundingSheetResponse:any = await this._patientRoungingSheetService.getPatientRoundingSheet(sanitizedDetails).toPromise();

    if(patientRoundingSheetResponse.status === 200) {
      const {patientList, ...data} = patientRoundingSheetResponse.data;
      this.patientRoundingSheet = {
        ...data,
        patientList: patientList.map(p => new Patient(p)) as Patient[]
      };
      this.initTableDataSource();
      this.loader.stopLoader(this.loaderId);

    }


  }

  initTableDataSource() {
    this.dataSource = new MatTableDataSource(this.patientRoundingSheet.patientList);
    this.dataSource.sort = this.sort;
  }
  
  async initPatientsByProvider(){
    let sanitizedCurrentUserId = this.commonService.sanitizeInput(this.currentUser._id);
    let sanitizedfacility_id = this.commonService.sanitizeInput(this.patientRoundingSheet.facility._id);
    const patientsByProviderResponse: any = await this.patientRoundingSheetPatientListService.getFacilityPatientsByProviderId(sanitizedCurrentUserId, sanitizedfacility_id).toPromise();
    if(patientsByProviderResponse.status !== 200) return;
    this.patientsByProvider = patientsByProviderResponse.data.array;

    this.filteredPatientsByProvider = this.patientsByProviderControl.valueChanges
    .pipe(
        startWith(''),
        map((value:any) => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterPatientsByProvider(name) : this.getFilteredPatientsByProvider())
    );
  }
  private _filterPatientsByProvider(name): any[] {
    const filterValue = name.toLowerCase();

    return this.getFilteredPatientsByProvider().filter(patient => patient.name.toLowerCase().indexOf(filterValue) === 0);
}
getFilteredPatientsByProvider() {
    if (!Array.isArray(this.patientsByProvider)) return [];


    let resultPatients = [];
    // resultPatients = _.cloneDeep(this.patientsByProvider);
    for (const patient of this.patientsByProvider) {
      const alreadyExistsInRoundingSheet = this.patientRoundingSheet.patientList.find(p => p._id === patient._id);
      if(!alreadyExistsInRoundingSheet) {
        resultPatients.push(patient)
      }
    }
    return resultPatients;
}
displayPatientFn(patient): string {
  return patient && patient.name ? patient.name : '';
}
addPatientToCurrrentRoundingSheet(event) {
  const patient = event.option.value;
  this.patientRoundingSheet.patientList.push(patient);
  const details = {
    ...this.authService.authObject,
    patientRoundingSheetId: this.patientRoundingSheet._id,
    patientId: patient._id
  }
  const sanitizedDetails = this.commonService.sanitizeObjectInput(details, ['patientId', 'patientRoundingSheetId']);
  const infoToast = this._toastr.info('Adding Patient to Census', "Please wait", { disableTimeOut: true });
      this._patientRoungingSheetService.addPatientToPatientRoundingSheet(sanitizedDetails).subscribe((response:any) => {
    if(response.status === 200) {
      this.initPatientRoundingSheet();
      this.patientsByProviderControl.setValue('');
      this._toastr.success('Patient added to Census successfully');
    } else {
      this._toastr.warning(response.message);
    }
  }, () => {}, () => () => this._toastr.clear(infoToast.toastId))
  
}
  handleSeenFilter(e) {
    let filteredPatients = this.patientRoundingSheet.patientList;
    if(this.filterSeen === 'seen') {
      filteredPatients = filteredPatients.filter(p => this.isPatientSeen(p));
    } else if (this.filterSeen === 'not_seen') {
      filteredPatients = filteredPatients.filter(p => !this.isPatientSeen(p));
    }
    this.dataSource = new MatTableDataSource(filteredPatients);
  }
  handleSortFilter(e) {
    this.patientRoundingSheetPatientListService.changeFilterSort(e);
  }

  doSortPatients() {
    const patientList = _.clone(this.patientRoundingSheet.patientList);
    patientList.sort((a, b) => {
      const adtA = this.getRecentPatientADTRecord(a);
      const adtB = this.getRecentPatientADTRecord(b);
      switch (this.filterSort) {

        case 'status':
          return compare(this.isPatientSeen(a) ? 1 : -1, this.isPatientSeen(b) ? 1 : -1, true)


        case 'seen':
          return compare(this.isPatientSeen(a) ? 1 : -1, this.isPatientSeen(b) ? 1 : -1, false)

        case 'not_seen':
          return compare(this.isPatientSeen(a) ? 1 : -1, this.isPatientSeen(b) ? 1 : -1, true)

        case 'name':
          return compare(a.name, b.name, true);

        case 'dob':
          return compare(new Date(a.date_of_birth).getTime(), new Date(b.date_of_birth).getTime(), true);

        case 'room_no':
          if(adtA && adtB) {
            let roomNoA = adtA.roomDesc;
            let roomNoB = adtB.roomDesc;
            try {
              roomNoA = parseInt(adtA.roomDesc);
              roomNoB = parseInt(adtB.roomDesc);
            } catch (error) {}
            return compare(roomNoA, roomNoB, true);
          } else {
            return compare(this.getPatientLocation(a), this.getPatientLocation(b), true);
          }

          case 'new_patient':
          if(this.getRowClass(a) === 'PRIMARY_DARK' && this.getRowClass(b) !== 'PRIMARY_DARK') {
            return 1;
          } else if(this.getRowClass(a) !== 'PRIMARY_DARK' && this.getRowClass(b) === 'PRIMARY_DARK') {
            return -1;
          } else {
            return 0;
          }

          case 'admission_date':
          if(a.pcc_payload?.admissionDate && b.pcc_payload?.admissionDate) {
            const dateA = new Date(a.pcc_payload.admissionDate).getTime() ;
            const dateB = new Date(b.pcc_payload.admissionDate).getTime();
            return compare(dateA, dateB, true);
          }
          return -1;
          case 'discharge_date':
          const dischargeA = a.getDischargeDate();
          const dischargeB = b.getDischargeDate();
            if(dischargeA && dischargeB) {
              const dateA = new Date(dischargeA).getTime();
              const dateB = new Date(dischargeB).getTime();
              return compare(dateA, dateB, true);
            }
            return -1;


        case 'payor':
        if(adtA && adtB) {
          return compare(adtA.payerName?.toLowerCase(), adtB.payerName?.toLowerCase(), true);
        }
        return -1;

        default: return 0;
      }
    })

    this.dataSource = new MatTableDataSource(patientList);
  }

  getSeenPatientsCount() {
    return this.patientRoundingSheet?.patientList.reduce((count, patient) => count + (this.isPatientSeen(patient) ? 1 : 0), 0)
  }

  getNotSeenPatientsCount() {
    return this.patientRoundingSheet?.patientList.reduce((count, patient) => count + (!this.isPatientSeen(patient) ? 1 : 0), 0)
  }

  applyFilter(filterValue: string) {
    this.patientRoundingSheetPatientListService.changeFilterSearch(filterValue);
    
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.filterPredicate = function (data, filter: string): boolean {
      return data.name.toLowerCase().includes(filter);
    };
  }

  handleFilter(result) {
    this.filterDropdownActive = false;
    if (!result) {
      return;
    } else if (result.filter === 'cancel') {
      return;
    } else if (result.filter === 'reset') {
      result = null;
      this.initPatientRoundingSheet();
      return;
    } else if (!result.payload) {
      return;
    }
    else {
      this.dateOfBirth = result.payload;
    }
  }


  filterDictationsByDateOfService(date_of_birth) {
   this.filterItemsByDate(date_of_birth, 'date_of_birth', this.patientRoundingSheet);
  }
  filterItemsByDate(date, dateKeyInObject, itemList) {
    const filterDateTime = new Date(date);
    const filterDate = filterDateTime.getDate();
    const filterMonth = filterDateTime.getMonth() + 1;
    const filterYear = filterDateTime.getFullYear();

    itemList = itemList.filter(t => {
      const dateTime = new Date(t[dateKeyInObject]);
      const date = dateTime.getDate();
      const month = dateTime.getMonth() + 1;
      const year = dateTime.getFullYear();

      return date === filterDate && month === filterMonth && year === filterYear;
    });
  this.dataSource =itemList;
  }

  getRecentPatientADTRecord(patient) {
    // const sortedADTRecords = _.clone(patient.adtRecords);
    // sortedADTRecords.sort((a,b) => new Date(b.effectiveDateTime).getTime() -  new Date(a.effectiveDateTime).getTime());
    
    // console.log({
    //   adtRecords: patient.adtRecords,
    //   sortedADTRecords,
    // });
    

    // return sortedADTRecords.adtRecords[0];

    return patient.adtRecords[0];
  }
  getDischargeDate(patient:Patient) {
    // return patient.adtRecords.find(adt => adt.actionCode === 'DD' || adt.actionCode === 'DHOME')?.effectiveDateTime;
    return patient.getDischargeDate()
  }
  getPatientLocation(patient) {
    const adt = this.getRecentPatientADTRecord(patient);
    let location = '';
    if(adt) {
      if(adt.floorDesc) {
        location+=adt.floorDesc;
      }
      if(adt.roomDesc) {
        location+= ' ' + adt.roomDesc;
      }
      if(adt.bedDesc) {
        location+= ' - ' + adt.bedDesc;
      }
    }
    return location;
  }
  handleRemovePatientClick(patientId) {
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,

      patientRoundingSheetId: this.params.id,
      patientId
    }
    const sanitizedDetails = this.commonService.sanitizeObjectInput(details, ['id', 'patientRoundingSheetId', 'patientId']);
    this._patientRoungingSheetService.removePatientFromPatientRoundingSheet(sanitizedDetails).subscribe((response:any) => {
      if(response.status === 200) {
        this.patientRoundingSheet.patientList = this.patientRoundingSheet.patientList.filter(p => p._id !== patientId);

        this.initTableDataSource();
        this.doSortPatients();
      }
    })
  }

  showPatientCommentsDialog(patient) {
    const dialogRef = this.dialog.open(PatientRoundingSheetCommentsDialog, {
        width: 'auto',
        data: {
          patient
        }
    });
    dialogRef.beforeClosed().subscribe(async () => {
      this.initPatientRoundingSheet()
    });
}

showAudioListDialog(patient) {
  const dialogRef = this.dialog.open(AudioListDialog, {
      width: 'auto',
      data: {
        patient,
        census_id: this.patientRoundingSheet._id
      }
  });
  // dialogRef.beforeClosed().subscribe(async () => {
  //   this.initPatientRoundingSheet()
  // });
}

formatProviderDateTag(patient) { 
if(patient._id === '5f6af094f93b9dba57a612d7') {
  
}
  let str = '';
  const {patient_charges_owner : provider, patient_charges_count : charge} = patient;
  if(provider) {
    const {first_name, last_name} = provider;

    str += `${first_name[0]}${last_name[0]}`.toUpperCase();
  }
  if(charge) {
    const visit_date = new Date(charge.visit_date);

    str += ` ${visit_date.getMonth()+1}/${visit_date.getDate()}`;
  }
  return str;
}
chargeStatusClass(patient) {

  let cssClass = '';
  const { patient_charges_count, patient_dictations_count } = patient;

  if(this.isPatientSeen(patient)) {
    //cssClass = 'darkBlueBg';
  } else if (!patient_charges_count && patient_dictations_count  === 0) {
    cssClass = 'darkGrayBg';
  } else if (patient_dictations_count > 0) {
    const dos = moment(patient.patient_dictation_obj?.date_time);
    const censusDate = moment(this.patientRoundingSheet.date);
    if(dos.isValid() && dos.startOf('day').isSame(censusDate.startOf('day'))) {
      cssClass = 'darkGrayBg'
    } else {
      //cssClass = 'darkBlueBg'
    }
  } else if(!patient_charges_count) {
    let visitDate = moment(patient_charges_count?.visit_date);
    const censusDate = moment(this.patientRoundingSheet.date);
    if(visitDate.isValid() && visitDate.startOf('day').isSame(censusDate.startOf('day'))) {
      cssClass = 'darkGrayBg';
    } else {
      //cssClass = 'darkBlueBg';
    }
  } else {
    //cssClass = 'darkBlueBg';
  }
  return cssClass;
}

getRowClass(patient) {
  if (this.patientRoundingSheet.patientsSeen !== null && this.patientRoundingSheet.patientsSeen.includes(patient._id))
  {
    return 'PRIMARY_DARK'; // OLD Patient
  }
  else if (patient.patient_charges_count == null && patient.patient_dictations_count === 0)
  {
    return 'DARK_GRAY'; // NEW Patient
  }
  else if (patient.patient_dictations_count !== null && patient.patient_dictations_count > 0)
  {
    let dos = moment(patient.patient_dictation_obj?.filterServiceDate);
    let censusDate = moment(this.patientRoundingSheet.date);

    if (dos.isValid() && dos.startOf('day').isSame(censusDate.startOf('day')))
    {
      return 'DARK_GRAY'; // NEW Patient
    }
    else
    {
      return 'PRIMARY_DARK'; // OLD Patient
    }
  }
  else if (patient.patient_charges_count !== null)
  {
    let visitDate = moment(patient.patient_charges_count?.visit_date);
    let censusDate = moment(this.patientRoundingSheet?.date);
    if (visitDate.isValid() && visitDate.startOf('day').isSame(censusDate.startOf('day')))
    {
      return 'DARK_GRAY'; // NEW Patient
    }
    else
    {
      return 'PRIMARY_DARK'; // OLD Patient
    }
  }
  else
  {
    return 'PRIMARY_DARK'; // OLD Patient
  }
}

isPrimaryDarkRow(patient) {

}
isDarkGrayRow(patient) {
  return
}
isPatientSeen(patient) {
  return this.patientRoundingSheet.currentPatientsSeen.indexOf(patient._id) >= 0;
}
markPatientSeen(patient, isSeen) {

  if(isSeen) {
    this.patientRoundingSheet.currentPatientsSeen.push(patient._id);
  } else {
    const index = this.patientRoundingSheet.currentPatientsSeen.indexOf(patient._id);
    this.patientRoundingSheet.currentPatientsSeen.splice(index, 1);
  }
  const details = {
    ...this.authService.authObject,
    patientRoundingSheetId: this.patientRoundingSheet._id,
    patientsSeen: this.patientRoundingSheet.currentPatientsSeen
  }
  const sanitizedDetails = this.commonService.sanitizeObjectInput(details, ['patientRoundingSheetId']);
  this.patientRoundingSheetService.updatePatientRoundingSheetSeen(sanitizedDetails).subscribe((response: any) => {
    console.log(response);

  })
}
hasNoChargeAndDictation(patient) {
  return patient.patient_charges_count === null && patient.patient_dictations_count === 0
}
hasChargeStatusEmpty(patient) {
  if(patient.patient_charges_count) {
    return !patient.patient_charges_count.status || patient.patient_charges_count.status === '';
  }
  return false;
}
hasChargeAndStatus(patient, status) {
  if(patient._id === '5f6af094f93b9dba57a612d7') {
    
  }
  if(patient.patient_charges_count) {
    return patient.patient_charges_count.status === status;
  }
  return false;
}
onRowClick($event, patient_id) {
  if($event.target.classList.value.indexOf('icon') >= 0 || $event.target.tagName.toLowerCase() === 'a') return;
  // localStorage.setItem('patient_id', patient_id);
  this._router.navigate([`patient-detail/${patient_id}`]);
}

goBack() {
  this._location.back();
}

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  if(typeof a === 'string' && typeof b === 'string') {
    return isAsc ? a.localeCompare(b) : b.localeCompare(a);
  }
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
