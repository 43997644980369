import { Injectable } from '@angular/core';
import { RouterExtService } from './router-ext.service';

@Injectable({
  providedIn: 'root',
})
export class GoBackUrlService {
  defaultRouteMapping = {
    '/pccAuthRedirect': '/dashboard',
  };

  constructor(private routerExtService: RouterExtService) {}

  getBackUrl(routeMaping = {}) {
    const _routeMapping = {
      ...this.defaultRouteMapping,
      ...routeMaping,
    };
    
    let previous = this.routerExtService.getPreviousUrl();
    
    for (const previousRoute in _routeMapping) {
      if (Object.prototype.hasOwnProperty.call(_routeMapping, previousRoute)) {
        const nextRoute = _routeMapping[previousRoute];
        if (previous.includes(previousRoute)) {
          return nextRoute;
        }
      }
    }
    return '';
  }
}
