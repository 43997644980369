import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialog } from "@angular/material/dialog";
import { UserService } from "src/app/services/Modules/user.service";
import { AuthService } from "src/app/services/auth.service";
import * as global from "src/app/includes/global";
import { AppointmentService } from "src/app/services/appointment.service";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CompanyService } from "src/app/services/Modules/company.service";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { finalize } from "rxjs/operators";
import { DialogConfirmationComponent } from "../census-patient-list/dialog-confirmation/dialog-confirmation.component";
import { AppointmentTypeDialogComponent } from "../appointment-type-dialog/appointment-type-dialog.component";
import { VisitStatusDialogComponent } from "../visit-status-dialog/visit-status-dialog.component";
import { CommonService } from "src/app/services/common.service";
import { IAppointmentStatus, IAppointmentType, ICompany } from "../../classes/company";
import { IData, IProvider, IUser } from "./company-settings.model";

@Component({
    selector: "app-company-settings-dialog",
    templateUrl: "./company-settings-dialog.component.html",
    styleUrls: ["./company-settings-dialog.component.css"]
})
export class CompanySettingsDialogComponent implements OnInit {
    providerId: string;
    providerCanChangeAppointmentStatus = false;
    dataSource = new MatTableDataSource<unknown>([]);
    appointmentTypes: IAppointmentType[] = []; // Initialize as an empty array
    appointmentVisitStatus: IAppointmentStatus[] = []; // Initialize as an empty array
    displayedColumns: string[] = ["name", "duration",'color', "action"];
    displayedVisitStatusColumns: string[] = ["name", 'color', "action"];
    selectedStartHour: string = "07";
    selectedStartMinute: string = "00";
    selectedEndHour: string = "19";
    selectedEndMinute: string = "00";
    currentCompanyId: string;
    searchTextProvider: string;
    company_id: string;
    uniqueProviders: IProvider[] = [];
    loaderId = 'scheduler-settings-loader';
    daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    selectedDays: string[] = []; // Array to store selected days
    users: Array<IUser> = [];
    columnSettings: string[] = []; // Array to store checked columns
    workingHours: { daysOfWeek: any[]; startTime: string; endTime: string }[] = [];
    hours: string[] = Array.from({ length: 24 }, (_, i) =>
        i.toString().padStart(2, "0")
    );
    minutes: string[] = Array.from({ length: 60 }, (_, i) =>
        i.toString().padStart(2, "0")
    );
    appointmentColumns: string[] = [
        "time",
        "name",
        "dob",
        "gender",
        "appointment",
        "provider",
        "charge",
        'status'
    ];
    constructor(
        public dialogRef: MatDialogRef<CompanySettingsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IData,
        private appointmentService: AppointmentService,
        private companyService: CompanyService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private loader: NgxUiLoaderService,
        private dialog: MatDialog,
        private userService: UserService,
        private authService: AuthService,
        public commonService: CommonService,
    ) {
        const currentCompany = this.commonService.getLocalStorageItem('admin-Dashboard');
        this.company_id = currentCompany?.currentCompanyId ?? this.route.snapshot.firstChild.params.id;
    }

    ngOnInit() {
        this.initializeData();
    }
    initializeData(): void {
        try {
            switch (this.data.type) {
                case "company_time":
                    this.getCompanyName();
                    break;
                case "appointment_type":
                    this.getSettings();
                    break;
                case "business_hour":
                    this.getResources();
                    break;
                // Add more cases as needed
                case "view-option":
                    this.getAppointmentSettings();
                    break;
                case "visit-status":
                    this.getCompanyAppointmentVisitStatus();
                    break;
                default:
                    console.warn("Unexpected type:", this.data.type);
            }
        } catch (error) {
            console.error("Error initializing data:", error);
        }
    }
    onNoClick(): void {
        this.dialogRef.close();
    }
    getCompanyName(): void {
        let companyId = this.company_id;
        this.loader.startLoader(this.loaderId);
        if (this.currentCompanyId) companyId = this.currentCompanyId;
        this.companyService
            .getCompanyName(companyId)
            .subscribe(response => {
                if (response.status == 200 && response.data) {
                    const [startHour, startMinute] =
                        response.data.businessStartTime.split(":");
                    this.selectedStartHour = startHour;
                    this.selectedStartMinute = startMinute;
                    const [endHour, endMinute] =
                        response.data.businessEndTime.split(":");
                    this.selectedEndHour = endHour;
                    this.selectedEndMinute = endMinute;
                    this.providerCanChangeAppointmentStatus = response.data?.provider_can_change_status ?? false;
                    this.loader.stopLoader(this.loaderId);
                }
            })

    }
    getResources(): void {
        this.loader.startLoader(this.loaderId);
        const filter = {
            is_activated: "true",
            company_id: this.company_id,
            user_type: global.USER_TYPE.DOCTOR
        };
        const projection = {
            first_name: 1,
            last_name: 1,
            title: 1,
            company_id: 1
        };
        this.userService
            .getUsers(filter, projection)
            .subscribe(async (response: any) => {
                if (response.status == 200) {
                    // this.users = [...response.data];
                    this.users = response.data.map((u) => {
                        u.name = `${u.first_name} ${u.last_name}`;
                        if (u.user_type == "1") {
                            u.name += `, ${u.title}`;
                        }
                        return u;
                    });
                    // this.providers = this.providers.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));
                    const map = new Map();
                    for (const item of this.users) {
                        if (!map.has(item._id) && item.first_name) {
                            map.set(item._id, true); // set any value to Map
                            this.uniqueProviders.push({
                                id: item._id,
                                name: `${item.first_name} ${item.last_name}, ${item.title}`,
                                first_name:`${item.first_name}`,
                                last_name:`${item.last_name}`
                            });
                        }
                    }
                    this.uniqueProviders = this.sortFirstNameVise(
                        this.uniqueProviders
                    );
                    this.loader.stopLoader(this.loaderId);
                }
            });
    }
    sortFirstNameVise<T extends { first_name: string }>(arrayTosort: T[]): T[] {
        let users = arrayTosort;
        let result = users.sort(function (a, b) {
            if (a.first_name?.toLowerCase() < b.first_name?.toLowerCase()) {
                return -1;
            }
            if (a.first_name?.toLowerCase() > b.first_name?.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        return result;
    }
    onSelectProvider(item = null): void {
        this.toggleLoader(true);
        const data = {
            company_id: this.company_id,
            provider_id: this.providerId
        };
        this.appointmentService.getUserBusinesshour(data).subscribe(
            (response: any) => {
                if (response.status === 200) {
                    this.selectedDays = response.data.map(
                        (selectedDay) => selectedDay.daysOfWeek[0]
                    );
                    this.workingHours = response.data || [];
                    this.toggleLoader(false);
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }
    onStartTimeChange(): void {
        const time = `${this.selectedStartHour}:${this.selectedStartMinute}`;
    }
    onEndTimeChange(): void {
        const time = `${this.selectedEndHour}:${this.selectedEndMinute}`;
    }
    updateCompany(): void {
        this.loader.startLoader(this.loaderId);
        let data = {
            _id: this.company_id,
            businessStartTime: `${this.selectedStartHour}:${this.selectedStartMinute}`,
            businessEndTime: `${this.selectedEndHour}:${this.selectedEndMinute}`,
            provider_can_change_appointment_status: this.providerCanChangeAppointmentStatus,
        };
        this.companyService.updateCompany(data)
            .pipe(finalize(() => this.loader.stopLoader(this.loaderId)))
            .subscribe((response: any) => {
                if (response.status == 200) {
                    this.getCompanyName();
                    this.toastr.success(response.message, "Success");
                    this.onNoClick();
                } else {
                    this.toastr.error(response.message, "Failed");
                }
        });
    }
    addAppointmentTypeDialog(data = null): void {
        const dialogRef = this.dialog.open(AppointmentTypeDialogComponent, {
            width: "400px",
            data: data
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.handleAppointmentTypesAction(result);
            }
        });
    }
    visitStatusDialog(data = null): void {
        const dialogRef = this.dialog.open(VisitStatusDialogComponent, {
            width: "400px",
            data: data
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.handleVisitStatusAction(result);
            }
        });
    }
    handleAppointmentTypesAction(result): void {
        // Add appointment type
        let message = "";
        if (result.action === "add") {
            this.appointmentTypes.push(result.appointmentType);
            message = "Appointment Type added Successfully.";
        }

        // Update appointment type
        if (result.action === "update") {
            const index = this.appointmentTypes.findIndex(
                (type) => type._id === result.appointmentType._id
            );
            if (index !== -1) {
                this.appointmentTypes[index] = result.appointmentType;
                message = "Appointment Type updated Successfully.";
            }
        }

        // Delete appointment type
        if (result.action === "delete") {
            const index = this.appointmentTypes.findIndex(
                (type) => type._id === result.appointmentType._id
            );
            // const index = this.appointmentTypes.findIndex(type => type._id === result.appointmentTypeId);
            if (index !== -1) {
                this.appointmentTypes.splice(index, 1);
                message = "Appointment Type deleted Successfully.";
            }
        }
        this.loader.startLoader(this.loaderId);
        // Save changes to the backend API
        this.companyService
            .saveCompanyAppointmentTypes(
                this.company_id,
                this.appointmentTypes
            )
            .subscribe(
                (response: any) => {
                    if (response.status === 200) {
                        this.toastr.success(message, "Success");
                        this.getSettings();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    handleVisitStatusAction(result): void {
        // Add appointment type
        let message = "";
        if (result.action === "add") {
            this.appointmentVisitStatus.push(result.visitStatus);
            message = "Visit Status added Successfully.";
        }

        // Update appointment type
        if (result.action === "update") {
            const index = this.appointmentVisitStatus.findIndex(
                (type) => type._id === result.visitStatus._id
            );
            if (index !== -1) {
                this.appointmentVisitStatus[index] = result.visitStatus;
                message = "Visit Status updated Successfully.";
            }
        }

        // Delete appointment type
        if (result.action === "delete") {
            const index = this.appointmentVisitStatus.findIndex(
                (type) => type._id === result.visitStatus._id
            );
            // const index = this.appointmentTypes.findIndex(type => type._id === result.appointmentTypeId);
            if (index !== -1) {
                this.appointmentVisitStatus.splice(index, 1);
                message = "Visit Status deleted Successfully.";
            }
        }
        this.loader.startLoader(this.loaderId);
        // Save changes to the backend API
        this.companyService
            .saveVisitStatus(
                this.company_id,
                this.appointmentVisitStatus
            )
            .subscribe(
                (response: any) => {
                    if (response.status === 200) {
                        this.toastr.success(message, "Success");
                        this.getCompanyAppointmentVisitStatus();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    updateAppointmentType(index, data): void {
        this.addAppointmentTypeDialog(data);
    }
    updateVisitStatus(index, data): void {
        this.visitStatusDialog(data);
    }
    deleteAppointmentType(index, appointment_type): void {
        let dialogReference = this.dialog.open(DialogConfirmationComponent, {
            data: {
                message:
                    "Are you sure you want to delete this appointment Type?"
            }
        });
        dialogReference.afterClosed().subscribe(async (result) => {
            if (result === "true") {
                const type = {
                    action: "delete",
                    appointmentType: appointment_type
                };
                this.handleAppointmentTypesAction(type);
            }
        }),
            () =>
                this.toastr.error(
                    "Something went wrong removing Patient Census",
                    "Failed"
                );
    }
    deleteVisitStatus(index, visitStatus) {
        let dialogReference = this.dialog.open(DialogConfirmationComponent, {
            data: {
                message:
                    "Are you sure you want to delete this visit status?"
            }
        });
        dialogReference.afterClosed().subscribe(async (result) => {
            if (result === "true") {
                const type = {
                    action: "delete",
                    visitStatus
                };
                this.handleVisitStatusAction(type);
            }
        }),
            () =>
                this.toastr.error(
                    "Something went wrong removing Patient Census",
                    "Failed"
                );
    }
    getSettings(): void {
        this.loader.startLoader(this.loaderId);
        this.companyService
            .getCompanyAppointmentTypes(this.company_id)
            .subscribe((response: any) => {
                this.appointmentTypes = response.data;
                this.dataSource.data = response.data;
                this.loader.stopLoader(this.loaderId);
            });
    }
    getCompanyAppointmentVisitStatus(): void {
        this.loader.startLoader(this.loaderId);
        this.companyService
            .getCompanyAppointmentVisitStatus(this.company_id)
            .subscribe(response => {
                this.appointmentVisitStatus = response.data;
                this.dataSource.data = response.data;
                this.loader.stopLoader(this.loaderId);
            });
    }
    addWorkingDay(day: string): void {
        const existingDayIndex = this.workingHours.findIndex((item) =>
            item.daysOfWeek.includes(day)
        );

        if (existingDayIndex !== -1) {
            // If the day is already added, remove it
            this.workingHours.splice(existingDayIndex, 1);
        } else {
            // If the day is not added, add it
            this.workingHours.push({
                daysOfWeek: [day],
                startTime: "",
                endTime: ""
            });
        }
    }
    // Function to toggle selection of a day
    toggleDaySelection(day: string): void {
        if (this.selectedDays.includes(day)) {
            // If the day is already selected, remove it
            this.selectedDays = this.selectedDays.filter(
                (selectedDay) => selectedDay !== day
            );
        } else {
            // If the day is not selected, add it
            this.selectedDays.push(day);
        }
    }
    // Function to copy times to all selected days
    copyTimesToAll(): void {
        if (this.selectedDays.length > 0 && this.workingHours.length > 0) {
            const selectedDayTimes = this.workingHours.find((item) =>
                item.daysOfWeek.includes(this.selectedDays[0])
            );

            // Update times for all selected days
            this.selectedDays.forEach((day) => {
                const existingDayIndex = this.workingHours.findIndex((item) =>
                    item.daysOfWeek.includes(day)
                );

                if (existingDayIndex !== -1) {
                    // If the day already exists, update the times
                    this.workingHours[existingDayIndex].startTime =
                        selectedDayTimes.startTime;
                    this.workingHours[existingDayIndex].endTime =
                        selectedDayTimes.endTime;
                } else {
                    // If the day does not exist, add a new row
                    // this.workingHours.push({ daysOfWeek: [day], startTime: selectedDayTimes.startTime, endTime: selectedDayTimes.endTime });
                }
            });
        }
    }
    toggleLoader(loading: boolean): void {
        if (loading) {
            this.loader.startLoader("business-loader");
        } else {
            this.loader.stopLoader("business-loader");
        }
    }
    saveWorkingHours(): void {
        const data = {
            company_id: this.authService.currentUser.company_id,
            provider_id: this.providerId,
            businessHours: this.workingHours
        };
        this.appointmentService.saveUserBusinesshour(data).subscribe(
            (response: any) => {
                if (response.status === 200) {
                    this.toastr.success(response.message, "Success");
                    // this.getSettings();
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }
    // Function to handle checkbox change event
    updateCheckedColumns(column: string, event: any): void {
        if (event.source.checked) {
        // Add column to checkedColumns if checked
        if (!this.columnSettings.includes(column)) {
            this.columnSettings.push(column);
        }
        } else {
        // Remove column from checkedColumns if unchecked
        const index = this.columnSettings.indexOf(column);
        if (index !== -1) {
            this.columnSettings.splice(index, 1);
        }
        }
    }
    getAppointmentSettings(): void {
        this.loader.startLoader(this.loaderId);
        this.companyService
            .getAppointmentColumnSetting(this.company_id)
            .subscribe((response: any) => {
                this.columnSettings = response.data;
                this.loader.stopLoader(this.loaderId);
            });
    }
    saveSettings(): void {
        this.companyService
            .updateAppointmentColumnSetting(this.authService.currentUser.company_id,this.columnSettings)
            .subscribe((response: any) => {
                if(response.status === 200){
                    this.onNoClick()
                    this.toastr.success(response.message, "Success");
                    this.getAppointmentSettings()
                    
                }
            });
    }
}
