import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EmailTemplateService } from '../email-template/service/email-template.service';
import { SendEmailTemplateDialogComponent } from '../send-email-template-dialog/send-email-template-dialog.component';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit, OnDestroy {
  emails: any;
  loaderId = 'email';
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = [
    'sr', 'subject', 'emailBody', 'actions'];
  dialogSubscription: any;

  constructor(
    private _emailTemplateService: EmailTemplateService,
    private _toastr: ToastrService,
    private loader: NgxUiLoaderService,
    private dialog: MatDialog,

  ) { }

  ngOnInit(): void {
    this.getEmail();
  }
  ngOnDestroy() {
  }

  deleteEmail(emailId, index) {
    this._emailTemplateService.deleteEmail(emailId).subscribe((response: any) => {
      if (response.status == 200) {
        this.emails.splice(index, 1);
        this.dataSource.data = [];
        this.dataSource.data = this.emails;
      }
    }, err => {
      this._toastr.error(err, "ERROR");
    }
    );
  }

  openSendEmailTemplateDialog(emailId, index): void {
    let options: any = {
      width: '70%',
      height: '70vh'
    }
    options.data = {
      emailId: emailId,
      isOneTimeEmail: true
    };
    this.dialogSubscription = this.dialog.open(SendEmailTemplateDialogComponent, options).afterClosed()
      .subscribe();
  }

  getEmail() {
    this.loader.startLoader(this.loaderId);
    const project = {
      subject: 1,
      body: 1,
    };
    const filter = {
      is_automatic: false
    };
    this._emailTemplateService.getEmail(filter, project).subscribe((response: any) => {
      if (response.status == 200) {
        this.emails = response.data;
        this.dataSource.data = this.emails;
        this.loader.stopLoader(this.loaderId);
      }
    }, err => {
      this._toastr.error(err, "ERROR");
    }
    );
  }

}
