<div class="container">
    <div class="flex">
    </div>
    <mat-card>
        <mat-card-content>
            <div class="flex gap-5" style="place-content: space-between !important;">
                <div  > 

                        <mat-form-field appearance="fill">
                            <mat-label>User Type</mat-label>
                            <mat-select (selectionChange)="handleChangeUserType()" [(ngModel)]="usersFilter.user_type">
                                <mat-option value="allUserType">
                                    All Users
                                </mat-option>
                                <mat-option value="1" *ngIf="currentCompany.company_type !== global.COMPANY_TYPE.SNF" >
                                    Providers
                                </mat-option>
                                <mat-option value="test_provider" *ngIf="currentCompany.company_type !== global.COMPANY_TYPE.SNF" >
                                    Test Providers
                                </mat-option>
                                <mat-option value="2" *ngIf="currentCompany.company_type == global.COMPANY_TYPE.NURSING">
                                    Transcribers
                                </mat-option>
                                <mat-option value="5" *ngIf="currentCompany.company_type !== global.COMPANY_TYPE.SNF">
                                    Nurse
                                </mat-option>
                                <mat-option value="6" *ngIf="currentCompany.company_type !== global.COMPANY_TYPE.SNF">
                                    Biller
                                </mat-option>
                                <mat-option value="medical_assistant">
                                    Medical Assistants
                                </mat-option>
                                <mat-option [value]="USER_TYPE.SNF_WC_NURSE" *ngIf="currentCompany.company_type?.toLowerCase() === global.COMPANY_TYPE.SNF">
                                    SNF WC Nurse
                                </mat-option>
                                <mat-option [value]="USER_TYPE.WOUND_NURSE" *ngIf="currentCompany.company_type !== global.COMPANY_TYPE.SNF && currentCompany.company_type !== global.COMPANY_TYPE.NURSING ">
                                    Wound Nurse
                                </mat-option>
                                <mat-option [value]="USER_TYPE.VIEW_ONLY_USER">
                                    View Only User
                                </mat-option>
                                <mat-option value="business_officer" *ngIf="isOptionVisibleForCompany('business_officer') ||  currentCompany.company_type !== global.COMPANY_TYPE.SNF">
                                    Business Officer
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill" style="margin-left: 15px;">
                            <mat-label>Role Type</mat-label>
                            <mat-select [(value)]="defaultUserRoleType" (selectionChange)="handleChangeRoleType($event.value)">
                                <mat-option value="all">
                                    All
                                </mat-option>
                                <mat-option value="full_admin">
                                    Full Admin
                                </mat-option>
                                <mat-option *ngIf="currentCompany.company_type !== global.COMPANY_TYPE.SNF" value="transcriber_admin">
                                    Transcriber Admin
                                </mat-option>
                                <mat-option value="none">
                                    None
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                </div>
                <div class="gap-5">
                    <button mat-raised-button class="dn-button-primary" [routerLink]="['../add']">
                        Add User
                    </button>
                </div>
            </div>


            <router-outlet></router-outlet>
        </mat-card-content>
    </mat-card>
</div>