import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { UserManagementService } from '../../user-management/user-management.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { FormControl } from '@angular/forms';
import { Observable, lastValueFrom } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ProfileService } from '../../profile/profile.service';
import { HttpResponse } from '@angular/common/http';
import moment from 'moment'
import { CommonService } from 'src/app/services/common.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DialogConfirmationComponent } from '../../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import * as global from '../../../includes/global'
import { UserService } from 'src/app/services/Modules/user.service';

@Component({
  selector: 'app-biller-list',
  templateUrl: './biller-list.component.html',
  styleUrls: ['./biller-list.component.css']
})
export class BillerListComponent implements OnInit, OnDestroy {
  // 'biller-profile', 
  // displayedColumns: string[] = ['first_name', 'last_name', 'email', 'last_login', 'status','two-factor'];
  displayedColumns: string[] = ['first_name', 'last_name', 'email', 'last_login', 'timezone', 'status', 'telemed', 'pcc', 'cognito', 'enable_dictation', 'user-permissions', 'admin-access'];
  usersFilter = {
    user_type: '6'
  };
  @ViewChild(MatSort) sort: MatSort;
  loading = false;
  public billers: any;
  public currentUser: any;
  public response: any;
  public dataSource: any;
  company_id: any;
  companies: any = [];
  filteredCompanies: Observable<string[]>;
  companyControl = new FormControl();
  currentCompany: any;
  userRoleTypeSubscription: any;
  selectedUserTypeSubscription: any;
  selectedUserType: any;
  loaderId = 'biller-list-loader';
  company_type: any;
  global = global;
  selectedRoleType: string;
  selectedPermissions: string[] = [];
  permissionsList: any;
  company: any;
  constructor(
    private loader: NgxUiLoaderService,
    public services: UserTypesRelationshipService,
    private _router: Router,
    private userManagementService: UserManagementService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private _authService: AuthService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private _profileService: ProfileService,
    private commonService: CommonService,
    private userService: UserService,
  ) {
    this.currentUser = this._authService.currentUser;
    if (this.currentUser.admin_type === "full_admin" || this.currentUser.admin_type === "system_admin") {
      console.log(this.displayedColumns);
      this.displayedColumns.push('user-type');
    }
    if (this.currentUser.product_owner) {
      this.displayedColumns = ['first_name', 'last_name', 'email', 'last_login', 'timezone', 'status', 'telemed', 'pcc','cognito','admin_', 'enable_dictation'];
      this.displayedColumns.push('companies');
    }
    // this.displayedColumns.push('operations');
    if (!this.currentUser.product_owner) {
      this.displayedColumns = this.displayedColumns.filter(f => f !== 'two-factor');
    }
    if (!this.currentUser) {
      // if not login, stop and redirect to login #mohsin-dev
      localStorage.setItem('currentUser', 'null');
      this._router.navigate(['/login']);
      return;
    }
    this.loading = true;
    // this.company_id = this.currentUser.company_id;
    // if(this.currentUser.account_type == 'company'){
    //   this.company_id = this.currentUser.company_id;
    // }

    // if(this.currentUser.account_type == 'company' && !window.location.pathname.includes('manage-companies')){  

    if (window.location.pathname.includes('company') && !window.location.pathname.includes('manage-companies')) {
      this.company_id = this.route.parent.parent.parent.snapshot.params.id;
    }
    else if (window.location.pathname.includes('manage-companies')) {
      this.company_id = this.route.parent.parent.parent.snapshot.params.id;
    }


  }
  changeAdminStatus(provider, status) {
    this.userManagementService.changeAdminStatus(provider._id, this.company_id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  ngOnDestroy(): void {
    if (this.userRoleTypeSubscription) {
      this.companyService.changeUserRoleType(null);
      this.userRoleTypeSubscription.unsubscribe();
    }
    if (this.selectedUserTypeSubscription) {
      this.selectedUserTypeSubscription.unsubscribe();
    }
  }
  async ngOnInit() {
    this.userService.getUserPermission(this.company_id).subscribe((response: any) => {
      if (response.status !== 200) return;
      this.permissionsList = response.data.filter((permission) => {
        if (permission.key !== "charge_status" && permission.key !== "show_matrixCare" && permission.key !== "eprescription" && permission.key !== "enabled_speech_kit" && permission.key !== "enable_auto_note_initiation" && permission.key !== "spell_check_status") {
          return true;
        }
        return false;
      });
    });
    await this.getCompany();
    try {
      if (this.currentUser.product_owner) {
        await this.getCompanies();
      }
      this.selectedUserTypeSubscription = this.commonService.castselectedUserType.subscribe(userType => {
        if (userType) {
          this.selectedUserType = userType;
          console.log('this.this.selectedUserType', this.selectedUserType);
        }
      });
      this.getBillers();
      this.filteredCompanies = this.companyControl.valueChanges.pipe(
        startWith(''),
        map((value: any) => this.filterCompany(value)));
      this.userRoleTypeSubscription = this.companyService.castUserRoleType.subscribe(role => {
        if (role) {
          this.selectedRoleType = role;
        }
      });
    } catch (err) {
      console.error("err", err);
    }
    if (this.company.portal_type === global.PORTAL_TYPE.NON_WC) {
      this.displayedColumns = this.displayedColumns.filter(column => column != 'telemed' && column != 'pcc' && column != 'enable_dictation');
    }
    else {
      this.displayedColumns = this.displayedColumns.filter(column => column != 'user-permissions' && column != 'admin-access');
      
    }
  }
  private filterCompany(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.companies.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    else {
      return this.companies;
    }
  }

  getCompanies() {
    return new Promise<void>((resolve, reject) => {
      this.companyService.getAllCompanies({ company_type: { $ne: global.COMPANY_TYPE.SNF } }).subscribe((response: any) => {
        if (response.status == 200) {
          this.companies = response.data;
          resolve(null);
        }
        else if (response.status == 403) {
          this.toastr.error(response.message, "ERROR");
        }
      }, err => {
        reject();
        this.toastr.error(err, "Error");
      });
    });
  }

  async getCompanyType() {
    const company_id = this.company_id;

    await this.companyService.getCompanyName(company_id).subscribe((res: any) => {
      let company = res.data;
      this.company_type = company.company_type
    })
  }

  async getCompany() {
    const filter = {
      _id: this.company_id
    };
    const project = {
      name: 1,
      company_type: 1,
      portal_type: 1
    };
    const { status, data }: any = await lastValueFrom(this.companyService.getCompany(filter, project));
    if (status == 200) {
      this.company = data;
    }
    else if (status === 500) {
      this.toastr.error(data.message, "ERROR");
    }
  }

  deleteUser(id: string) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: "Are you sure you want to delete this User ?"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === "true") {
        this._profileService.deleteUser(id).subscribe((res: any) => {
          console.log('resposne', res);
          // this.doctors = this.doctors.filter(user => user._id !== id)
          // this.dataSource.data = [];
          // this.dataSource.data = this.doctors;
          if (res.status == 200)
            this.toastr.success("User Deleted Successfully");
          else
            this.toastr.warning(res.message);
          this.getBillers();
        });
      } else {
        return;
      }
    })
  }

  changeTwoFactorAllowance(provider, status) {
    let updateObject = {
      _id: provider._id,
      allow_two_factor_change_by_admin: status
    };

    this.userManagementService.updateUser(updateObject, this.company_id).subscribe((response: any) => {

      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  getBillers() {
    this.services.getBillersList(this.company_id).subscribe(data => {
      this.response = data;
      this.billers = this.response.data;
      this.sortUsers(this.billers);
      this.currentCompany = this.route.parent.parent.parent.snapshot.params.id
      var index = this.companies.findIndex(company => this.currentCompany === company._id); this.currentCompany = this.companies[index];
      this.companies.splice(index, 1);
      this.companyControl.setValue(this.currentCompany?.name);
      let perListKeys = this.permissionsList.map(per => per.key)
      this.billers.forEach(biller => {
        biller.companies = this.companies
        if (biller.recentLogin) {
          biller.recentLogin = moment(biller.recentLogin).tz('America/Los_Angeles').format('YYYY-MMM-DD h:mm A');
        }
        this.companies.forEach(company => {
          if (biller.company_id == company._id) {
            biller.selectedCompany = company._id;
          }
        });
        if (biller.admin_type == "full_admin" || biller.admin_type == "system_admin") {
          biller.admin = 'full_admin'
        }
        else if (biller.admin_type == "transcriber_admin") {
          biller.admin = 'transcriber_admin'
        }
        else if (biller.admin_type == "none") {
          biller.admin = 'none'
        }
        biller.permissions = biller.permissions.map(per => per.key);
        biller.permissions = biller.permissions.filter(per => perListKeys.includes(per))
      });
      this.dataSource = new MatTableDataSource(this.billers);
      //filter role
      this.userRoleTypeSubscription = this.companyService.castUserRoleType.subscribe(role => {
        if (role) {
          this.dataSource.data = this.billers;
          if (role === "all") {
            this.dataSource.data = this.billers;
          }
          else {
            this.dataSource.data = this.dataSource.data.filter(biller => biller.admin_type === role);
          }
        }
      });
    }, console.error, () => this.loading = false);
  }
  onCompanyChange(element, company) {
    this.companyService.companyChangeForUser(element._id, company).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("Company Changed")
        this.dataSource.data = this.dataSource.data.filter(user => user._id !== element._id);
        // this.getCompanies();
      }
    })
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  changeUserActiveStatus(userId: string, status) {
    this.userManagementService.changeUserActiveStatus(userId, status).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.success(response.message, 'Success');
      } else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  changeTelemedicineStatus(provider, status) {
    this.userManagementService.changeTelemedicineStatus(provider._id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  handleChangeUserType(userId, event) {
    let additionalRole: string = '';
    var update = {
      user_type: event.value,
      user_id: userId
    }
    if (event.value == 'test_provider') {
      additionalRole = 'demo_provider';
    } else if (event.value == 'cnp') {
      additionalRole = 'cnp';
    }
    this._profileService.updateUserType(update, additionalRole).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("User type changed");
        this.getBillers();
      }
    });
  }
  updateUserOptionStatus(key, user, status) {
    let updateObject: any = {
      _id: user._id,
    };
    updateObject[key] = status;
    this.userManagementService.updateUser(updateObject).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    });
  }
  handleChangeAdminType(userId, event) {
    var update = {
      admin_type: event.value,
      user_id: userId
    }
    this._profileService.updateAdminType(update).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("Admin type changed");
      }
    });
  }
  ResendEmail(userId) {
    //    console.log("userId",userId);
    this.companyService.ResendEmail(userId).subscribe((response: any) => {
      if (response.status === 200) {
        console.log(response.data);
        this.toastr.success("Email sent successfully");
      }
    });
  }
  exportUsersAsXLSX() {
    this.loader.startLoader(this.loaderId);
    this.companyService.exportUsersAsXLSX(this.company_id, this.selectedUserType, null, null, this.selectedRoleType).subscribe((response: any) => {
      console.log("response.body", response);
      if (response instanceof HttpResponse) {
        this.loader.stopLoader(this.loaderId);
        // this.toastr.success("Status changed");
        var url = window.URL.createObjectURL(response.body);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `Users`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }
    });
  }
  changeDictationStatus(provider, status) {
    let updateObject = {
      _id: provider._id,
      is_dictation_enabled: status
    };

    this.userManagementService.updateUser(updateObject).subscribe((response: any) => {

      if (response.status == 200) {
        if (this.currentUser._id === response.data._id) {
          const currentUser = JSON.parse(localStorage.getItem("currentUser"));
          currentUser.is_dictation_enabled = response.data.is_dictation_enabled;
          localStorage.setItem("currentUser", JSON.stringify(currentUser));
        }
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  sortUsers(arr: any[]) {
    arr.sort((a, b) => a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase()));
  }
 

  async changeUserPermissions(element, event) {
    this.selectedPermissions = event.value;
    element.permissions = event.value;
    let filterArray = this.permissionsList.filter((per) => element.permissions.findIndex((selectedPer) => selectedPer === per.key) > -1);
    let details = {
      userId: element._id,
      company_id: this.company_id,
      filterArray: filterArray,
    }
    await this.userService.setUserPermission(details).subscribe((response: any) => {
      if (response.status === 200) {
        console.log("permissions set successfully");
        this.toastr.success("Permission Updated successfully")
      }
    });
  }
  resolvePermission(key) {
    if (key) {
      let index = this.permissionsList.findIndex(permissions => permissions.key == key);
      return this.permissionsList[index].value;
    }
  }

}
