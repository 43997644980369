import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as global from '../../../includes/global'
import { DoctorDocumentsUploadDialog } from './doctor-documents-upload-dialog/doctor-documents-upload-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DoctorDocumentService } from './doctor-document-file.service'
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-doctor-profile-documents',
  templateUrl: './doctor-profile-documents.component.html',
  styleUrls: ['./doctor-profile-documents.component.css']
})
export class DoctorProfileDocumentsComponent implements OnInit {
  global=global;
  displayedColumns = ['document_title', 'uploaded_at', 'operations'];
  dataSource: MatTableDataSource<any>;

  model;
  documentFileUploaders = [{name:''}];

  provider_id:string;
  constructor(
    private dialog: MatDialog,
    private toastr: ToastrService,
    private doctorDocumentService: DoctorDocumentService,
    private route: ActivatedRoute,
    ) { 

    this.model = [];


  }

  ngOnInit() {
    this.provider_id = this.route.snapshot.paramMap.get('provider_id');
    this.initDoctuments();
  }

  initDoctuments() {
    const filterQuery = {
      provider_id_ref: this.provider_id,
      is_active: true
    }
    this.doctorDocumentService.getDoctorDocumentFiles(filterQuery).subscribe((response:any) => {
      
      if(response.status === 200) {
    
        this.model = response.data.array;
        this.dataSource = new MatTableDataSource(this.model);

      }
      
    })
  }

  deleteDocumentFile(element) {
    const updateObject = {
      _id: element._id,

      is_active: false
    }
    this.doctorDocumentService.updateDoctorDocumentFile(updateObject).subscribe((response:any) => {
      
      if(response.status === 200) {
    
        this.initDoctuments();

      }
      
    })
  }
  showDoctorDocumentFileUploadDialog() {
    const dialogRef = this.dialog.open(
      DoctorDocumentsUploadDialog,
      { width: '80%', data: { } }
    );
    dialogRef.beforeClosed().subscribe(async (fileInput: any) => {
      if(!fileInput.files) return;
      const [file] = fileInput.files;
      const infoToast = this.toastr.info('Uploading Document...', 'Please wait')

      try {

        this.doctorDocumentService.saveDoctorDocumentFile(file, this.provider_id).subscribe((response: any) => {
          if (response.data) {
              this.initDoctuments();
          }
        })
      } catch (error) {
        this.toastr.error('Something went wrong please try again', 'Failed');

      } finally {
        this.toastr.clear(infoToast.toastId);
      }
    });
  }
  
  getItemIcon(title) {
    const extension = title.substr(title.lastIndexOf('.') + 1);
    const filetypeIconsPath = '../../../../assets/img/filetypes';

    return (`${filetypeIconsPath}/${extension}.svg`);
  }

  downloadFile(element) {

    var url = element.aws_path
    var a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = element.title;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove(); // remove the element
  }
}
