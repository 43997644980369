import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { ViewNoteDialog } from '../../transcription-create/view-note-dialog/view-note-dialog.component';
import swal from 'sweetalert';

@Component({
  selector: 'app-duplicate-patient-dialog',
  templateUrl: './duplicate-patient-dialog.component.html',
  styleUrls: ['./duplicate-patient-dialog.component.css']
})
export class DuplicatePatientDialogComponent implements OnInit {
  incoming: any;
  existing: any;
  duplicates: any=[];
  source_ids: any;
  onlyOne: boolean;
  constructor(public dialogRef: MatDialogRef<DuplicatePatientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog) {
      this.source_ids=[];
      this.onlyOne=false;
      if(data) {
        this.incoming = data.incoming;
        console.log("data.source=='MatrixCare' : ", data.existing, data.source=='MatrixCare',data.source)
        if(data.source=='MatrixCare'||data.source=='PointClickCare'){
          this.duplicates=data.existing;
        }else{
          this.duplicates = data.existing?.filter((e:any)=>{return !(e?._id?.toString()==this.incoming._id?.toString())});
        }
        if(this.duplicates&&this.duplicates.length){
          this.duplicates=this.duplicates.map((e)=>{
            // const NameStatus=this.firstNameMatch(e);
            // return {...e, selected:NameStatus}});
            return {...e, selected:false}});

            this.duplicates=this.duplicates.sort((a, b) => {
              if (this.firstNameMatch(a) > this.firstNameMatch(b)) return -1;
              if (this.firstNameMatch(b) > this.firstNameMatch(a)) return 1;

              return 0;
          });

          if(this.duplicates.length==1){
            this.existing = this.duplicates[0];
            this.onlyOne=true;
          }
        }
        
      }
      console.log("existing : ", this.duplicates);
     }
 
  ngOnInit(): void {

  }

  getId(item){
    let id=item?._id?.toString().slice(-6);
    if(item.source=='MatrixCare'){
      id=item?.matrix_payload?.patient_id;
    }else if (item.source=='PointClickCare'){
      id=item?.pcc_patientId;
    }else{
      return id;
    }
    return id;
  }

  selectedOne(element){
    return (element._id?.toString()==this.existing?._id?.toString());
  }

  getFormatedDate(date_of_birth){
    if(date_of_birth)
      return moment(new Date(date_of_birth)).format('MM/DD/YYYY');
    else return '';  
  }
  shouldAddNew(value) {
    if(value){
      let data={
        addnew:value, 
        patient: this.existing,
        source_patients: []
      }
      this.dialogRef.close(data);
    }else{

      ///  confirmation

      swal({
        title: "Are you sure?",
        text: "Once merged, you will not be able to undo this!",
        icon: "warning",
        buttons: [
          true, true
        ],
        dangerMode: true,
      })
          .then((yes) => {
            if (yes) {
              let src=this.duplicates.filter((e:any)=>{return !(e._id?.toString()==this.existing?._id?.toString()) });
              src=src.filter((e:any)=>{return e.selected});
          
                let data={
                  addnew:value, 
                  patient: this.existing,
                  source_patients: src.map((e)=>{return e._id})
                }
                this.dialogRef.close(data);
            } else {
              console.log("user is not sure, he wanna review his decisions");
            }
          });


      
    }
  }

  viewNote(note){
    console.log("note : ", note);
    const dialogRef = this.dialog.open(ViewNoteDialog, {
      width: '80%',
      //height:'80%',
      data: {
        note_id: note._id,
        readOnly: true
      }
    });
  }

  selectExisting(element){
    this.existing = element;
  }

  selectAgain(){
    this.existing = null;
  }

  firstNameMatch(element){
    if(element.first_name==this.incoming.first_name) 
      return true; 
    else return false;
  }

}
