import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { calculateArea, calculateVolume } from '../../../../../note-sidebar/wound-care-widget/wound-utils';
import moment from 'moment'
import Chart from 'chart.js/auto';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-wounnd-graph-dialog',
  templateUrl: './wounnd-graph-dialog.component.html',
  styleUrls: ['./wounnd-graph-dialog.component.css']
})
export class WounndGraphDialogComponent implements OnInit {
  canvas: any;
  ctx: any;
  chart: Chart<"line", any[], string>;
  @ViewChild('lineChart') lineChart: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
  ) {
   }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    this.createGraph(this.data.wounds)
  }
  createGraph(wounds) {
  const  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

    let charge_phrase = []
    let enteredDotphrases = wounds[0].entered_dot_phrases;
    enteredDotphrases.forEach((ele)=>{
        if(ele.enter_date){
          if(ele.enter_date.day < 10){
            charge_phrase.push({
              key: ele.key,
              enter_date: months[ele.enter_date.month - 1] + "/" +"0"+ ele.enter_date.day + "/" + ele.enter_date.year
            })
          }else{
            charge_phrase.push({
              key: ele.key,
              enter_date: months[ele.enter_date.month - 1] + "/" + ele.enter_date.day + "/" + ele.enter_date.year
            })
          }
        }
      });
      let woundDates = []
      woundDates = wounds.map(wound=> moment(wound.createdAt).format('MMM/DD/YYYY'));
    let area = JSON.parse(JSON.stringify(wounds.map(calculateArea)))
    area.forEach((ar,index)=>{
      if(area.length > 1){
        if(ar <= 0 && (index < area.length - 1)){
          area[index] = null
        } 
      }else{
        if(ar <= 0){
          area[index] = null
        }
      }
    })
    let volume = JSON.parse(JSON.stringify(wounds.map(calculateVolume)))
    volume.forEach((vol,index)=>{
      if(volume.length > 1){
        if(vol <= 0 && (index < volume.length - 1)){
          volume[index] = null
        }
      }else{
        if(vol <= 0){
          volume[index] = null
        }
      }
    });
    console.log(area,volume);
    area.forEach((ar,index)=>{
      if(ar === null){
        volume.splice(index,1);
        area.splice(index,1);
        woundDates.splice(index,1);
      }
    })
    volume.forEach((vol,index)=>{
      if(vol === null){
        volume.splice(index,1);
        console.log(index);
        area.splice(index,1);
        woundDates.splice(index,1);
      }
    })
    console.log(area,volume);
    // let days = this.wounds.map((e, i) => `${i + 1}`);
    enteredDotphrases = charge_phrase;
    woundDates.forEach((d, indexx)=>{
      let index = enteredDotphrases.findIndex((c)=> c?.enter_date === d);
      if(index > -1){
        enteredDotphrases.splice(index,1); 
      }
    });
    enteredDotphrases = enteredDotphrases.map((p) => p.enter_date);
    let allDates=[...woundDates, ...enteredDotphrases];
    if(allDates.length > area.length){
      let count = allDates.length - area.length;
      for (let i = 0; i < count; i++) {
        area.push(area[area.length - 1]);
        volume.push(volume[volume.length - 1]);
      }
    }
    // dates[0] = 'Oct/25/2022';
    let pointScale = [];
    allDates.forEach((d)=>{
      let index = charge_phrase.findIndex((c)=> c?.enter_date === d); 
      if(index > -1){
        var letterImage = new Image();
        letterImage.width = 20;
        letterImage.height = 20;
        if(charge_phrase[index].key.toLowerCase().includes('debridement')){
          letterImage.src ="../../../../../../assets/icons/letterD.png";
        }else if(charge_phrase[index].key.toLowerCase().includes('biopsy')){
          letterImage.src ="../../../../../../assets/icons/letterB.png";
        }else{
          letterImage.src ="../../../../../../assets/icons/letterG.png";
        }
        pointScale.push(letterImage);
      }else{
        pointScale.push('circle')
      }
    })
    console.log({
      area,
      volume,
      allDates
    });

    this.canvas = this.lineChart.nativeElement;
    this.ctx = this.canvas.getContext('2d');
    if (this.chart) {
      this.chart.destroy();
    }
    this.chart = new Chart(this.ctx, {
      type: 'line',
      data: {
        datasets: [{
          label: 'Area',
          data: area,
          backgroundColor: "rgb(115 185 243 / 65%)",
          borderColor: "green",
          pointStyle: pointScale
          // fill: true,
        },
        {
          label: 'Volume',
          data: volume,
          backgroundColor: "#47a0e8",
          borderColor: "blue",
          // fill: true,
        }],
        labels: allDates
      },
      options: {
        plugins: {
          subtitle: {
            display: true,
            color: "#FF0000",
            text: 'D=Debridement  B=Biopsy  G=Graft'
        }
        },
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }

    });

  }

}
