import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-data-export',
  templateUrl: './data-export.component.html',
  styleUrls: ['./data-export.component.css']
})
export class DataExportComponent implements OnInit {
  title:'Json Details';
  displayedColumns: string[] = ['header', 'value'];
  dataSource: any = new MatTableDataSource([])
  tableData: any = [];
  jsonData: any;
  constructor(
    private _authService: AuthService
  ) {
   }

  async ngOnInit() {
    this._authService.dataExportApi().subscribe((res:any) => {
      this.jsonData = res.data;
      const jsonData = JSON.parse(JSON.stringify(this.jsonData));
      this.extractData(jsonData);
      this.title = res.details.title;
    });
  }

  private extractData(data: any, parentKey?: string) {
    for (const key in data) {
      const currentKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof data[key] === 'object') {
        this.extractData(data[key], currentKey);
      } else {
        this.tableData.push({ header: currentKey, value: data[key] });
      }
    }
    this.dataSource.data = this.tableData;
  }

}
