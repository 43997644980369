import { Component,Inject, OnInit, ViewChild, EventEmitter} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { PatientListComponent } from '../../patient-list/patient-list.component';
import { PatientAddDialog } from '../patient-add-dialog/patient-add-dialog.component';
import { PatientDetailDialog } from '../patient-detail-dialog/patient-detail-dialog.component';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

// UPLOAD FILE DIALOG
export interface PatientsDialogData {
  provider:any,
  facility:any,
  patient:any,
  associated_doctors_ids?:Array<string>;
}

@Component({
  selector: 'app-patients-dialog',
  templateUrl: './patients-dialog.component.html',
  styleUrls: ['./patients-dialog.component.css']
})
export class PatientsDialog implements OnInit {
  currentUser:any;
  provider:any;
  facility:any;
  patient:any;
  @ViewChild(PatientListComponent) patientListComponent:PatientListComponent;


  constructor(
    public dialogRef: MatDialogRef<PatientsDialog>,
    private dialog: MatDialog, 
    private router: Router,
    private authService:AuthService,
    @Inject(MAT_DIALOG_DATA) public data: PatientsDialogData) { 
      this.currentUser = this.authService.currentUser;
      if(data) {
        this.facility = data.facility;
        this.provider = data.provider;
        this.patient = data.patient;
      };
    }
    
    ngOnInit(): void {
      
      this.router.events.subscribe((val) => {
        this.dialog.closeAll();
      });
    }
    
    onAddNewClick($event) {
      this.showPatientAddDialog();
    }
    onEditClick($event) {
      const onSuccess = new EventEmitter();
      onSuccess.subscribe(() => {
        this.showPatientAddDialog();
      })
      this.patientListComponent.editPatient($event,onSuccess);
    }

    onDeleteClick($event) {
      this.patientListComponent.deletePatient($event);
    }

    onDetailClick($event) {
      this.patientListComponent.patientDetail($event);
      
      this.showPatientDetailDialog();
    }

    onSelectPatient(e) {
      if(e) this.patient = e;
      this.dialogRef.close(e);
    }
    
    showPatientAddDialog() {
      const dialogRef = this.dialog.open(PatientAddDialog, {width: '80%', data: {}});
      dialogRef.beforeClosed().subscribe((patient:any) => {
        this.patient = patient;
        
        var details = {
          id: <string>null,
          auth_key: <string>null,
          usertype: <any>null,
          primary_doctor_id: <string>null,
          index:<string>null
        };
        details.id = this.currentUser._id;
        details.auth_key = this.currentUser.auth_key;
        details.usertype = this.currentUser.user_type;
        details.index = 'All';

        this.patientListComponent.initPatients(details);
      });
    }
    showPatientDetailDialog() {
      const dialogRef = this.dialog.open(PatientDetailDialog, {width: '80%', data: {}});
    }
}
