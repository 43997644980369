import { Component, OnInit, Input, Output, EventEmitter, DoCheck, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranscriptionCreateService } from 'src/app/includes/transcription-create/transcription-create.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { DashboardService } from 'src/app/includes/dashboard/dashboard.service';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as global from '../../includes/global';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SocketService } from 'src/app/services/socket.service';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from "../../../environments/api-url";
import moment from 'moment'
// import { timeAsMs } from '@fullcalendar/core/datelib/marker';
import { truncate } from 'lodash';

@Component({
    selector: 'app-audio-list',
    templateUrl: './audio-list.component.html',
    styleUrls: ['./audio-list.component.css'],
    providers: [
        DatePipe
    ]
})
export class AudioListComponent {
    global: any = global;
    @Input() audios: Array<any> = [];
    @Input() group_index: string;
    @Input() multiSelectedDictations: Array<any> = [];
    @Input() isMultiDownloadDictationsOn: boolean;
    // @Input() surpassedTAT: boolean;
    @Output() initDictationsAndTranscriptions = new EventEmitter();
    @Output() toggleDictationMultiDownloads = new EventEmitter();

    currentUser: any;
    isCheckedAll = false;
    downloadProgress = {
        show: false,
        audio_id: null,
        progress: 0,
        // mode: 'query'
        mode: 'indeterminate'
    };
    timeUp: Boolean = false;
    surpassedTAT: Boolean = false;
    allAudios:any;

    constructor(
        private _toastr: ToastrService,
        private _transcriptionCreateService: TranscriptionCreateService,
        private _encryptDecryptService: EncryptDecryptService,
        private _dashboardService: DashboardService,
        private _httpClient: HttpClient,
        private _router: Router,
        private datePipe: DatePipe,
        private _socketService: SocketService,
        private _authService: AuthService
    ) {
        // this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
        this.currentUser = this._authService.currentUser;
        localStorage.removeItem('downloadInProgress'); 
    } 
    
    filterSurpassedTAT(value) {
        if (!value) {
            this.audios = this.allAudios;
        }
        else{ 
            this.audios = [];
            this.allAudios.map(audio=>{
                let hours = moment().diff(moment(new Date(audio.filterUploadingDate).toLocaleString('en-US', { timeZone:  audio.timezone })), 'hours');
                if(hours >= 24
                    ){
                    this.audios.push(audio);
                }
            })

        }
    }


    ngOnInit() {
        // console.log("surpassedTAT in ngOnInit: ", this.surpassedTAT);
        this.allAudios = this.audios;
    }

    hasAudios() {
        const found = this.audios.find(a => a._id);
        return found;
    }
    isProvider() {
        if (this.currentUser.user_type == '1') {
            return true;
        }
        else
            return false;
    }

    async selectedAudio($event, recording, audiosLength) {
        console.log('audio',recording);
        //

        //block click

        // if(this.isMultiDownloadDictationsOn) {

        //   const excludeClasses = ['mat-checkbox-inner-container', 'mat-icon']
        //   for(let cls of excludeClasses) {
        //    if($event.target.classList.value.indexOf(cls) >= 0) return;
        //   }

        //    this.toggleDictationMultiDownloads.emit(recording);
        //    return;
        //  }

        /* commented by mohsin */
        // if (this.currentUser) {

        //     let res = await this._dashboardService
        //         .checkAudioNote(recording._id).toPromise();


        //     // @ts-ignore
        //     if (res.status === 400) {
        //         localStorage.setItem('category', 'dict');
        //         // localStorage.setItem('audiosLength', audiosLength);
        //         localStorage.setItem('dictationFiles', JSON.stringify(this.audios));
        //         this._router.navigate(['/transcription-detail', { audio_id: recording._id }]);
        //         localStorage.setItem('CurrentRecording', JSON.stringify(recording));
        //     } else { // @ts-ignore
        //         // @ts-ignore
        //         if (res.status === 200) {
        //             // let emptyAudios=[];
        //             // localStorage.setItem('dictationFiles', JSON.stringify(emptyAudios));
        //             // localStorage.setItem('dictationFiles', JSON.stringify(this.audios));
        //             // @ts-ignore
        //             const routerMapParams: any = { category: 'tran', id: res.data._id, readonly: false };
        //             routerMapParams.audio_id = recording._id;
        //             routerMapParams.category = 'dict';

        //             // this._router.navigate(['/transcription-create', routerMapParams]);
        //             // @ts-ignore
        //             this._router.navigate(['/note-editor/' + res.data._id]);
        //         }
        //     }
        // }
 
        this._router.navigate(['/transcription-detail', { audio_id: recording._id }]);

    }
    getEndTimeForTimer(audio) {
        if (audio && audio.filterUploadingDate) {
            let time = moment(new Date(audio.filterUploadingDate).toLocaleString('en-US', { timeZone: audio.timezone })).add(1, 'days');
            return time.toString()
        }
        else {
            return '';
        }
    }
    getStratTimeForTimer(audio) {
        if (audio && audio.filterUploadingDate) {
            let time = moment(new Date(audio.filterUploadingDate).toLocaleString('en-US', { timeZone: audio.timezone }));
            return time.toString()
        }
        else {
            return '';
        }
    }

    triggerFunction() {
        this.timeUp = true;
    }

    getUploadingDate(audio) {
        if (audio && audio.uploading_date) {
            let time = moment(new Date(audio.uploading_date)).format('MMM DD, YYYY h:mm a');
            return time;
        }
        else {
            return '';
        }
    }

    getServiceDate(audio) {
        //ali
        if(audio && audio.date_obj){
            const { year, month, day, hour, minute } = audio.date_obj;
            const _month = month < 10 ? `0${month}` : month;
            const _day = day < 10 ? `0${day}` : day;
            return `${_month}-${_day}-${year} ${hour}:${minute}`;
        }else{
            if (audio && audio.filterServiceDate) {
                return moment(new Date(audio.filterServiceDate).toLocaleString('en-US', { timeZone: audio.timezone })).format('MMM DD, YYYY h:mm a');
            }
            else {
                return '';
            }
        }
        //mohsin
        //return moment(audio.filterServiceDate).format('MMM DD, YYYY h:mm a');
    }
    /*
    * Delete Audio (dictation) #mohsin-dev
    */
    deleteAudio(audio, shouldDelete = true) {
        this._dashboardService.deleteAudio(audio._id, shouldDelete).subscribe((response: any) => {
            if (response.status === 200) {
                this._toastr.success(response.message, 'Success');
                audio.is_trashed = shouldDelete ? 'true' : 'false';
                this.audios = this.audios.map(t => t._id === audio._id ? audio : t);

                this.initDictationsAndTranscriptions.emit({ audios: this.audios });
            } else {
                this._toastr.error(response.message, 'Failed');
            }
        });
    }


    download(downloadType, recording) {
        const isDownloadInProgress = localStorage.getItem('downloadInProgress');
        if (isDownloadInProgress === '1') {
            this._toastr.warning('Please wait until it finishes then try again', 'Already in Progress', { extendedTimeOut: 5000 });
            return;
        }

        this.downloadProgress.show = true;
        localStorage.setItem('downloadInProgress', '1');
        this.downloadProgress.progress = 0;
        this.downloadProgress.audio_id = recording._id;

        // fixed mp3/mp4 file naming to audio_file_name and dictation title #mohsin-dev
        const dateObj = new Date(recording.date);
        const date = `${dateObj.getDate()}-${dateObj.getMonth()}-${dateObj.getFullYear()}`;
        const file_name = (`${recording.audio_title}
        _${recording.uploaded_by_id_ref.first_name + ' ' + recording.uploaded_by_id_ref.last_name}
        _${recording.facility_id_ref.title}_${date}`).replace(/\s/g, '_');


        let infoToastr: any;

        let downloadResponse;
        if (downloadType == 'mp4') {

            // ACTIVITY LOG
            this._socketService.addActivityLog({
                id: this.currentUser._id,
                screen: 'Dashboard',
                operation: 'Mp4 Download',
                datetime: this.datePipe.transform(new Date(), global.date_time_format)
            });

            recording.file_name = file_name + '.mp4';
            downloadResponse = this._dashboardService
                .downloadFile(global.url + API_URL.ROUTES.downloadMP4File, { recording });
            // this.downloadFile(global.url + '/downloadMP4File',recording);

        } else {

            // ACTIVITY LOG
            this._socketService.addActivityLog({
                id: this.currentUser._id,
                screen: 'Dashboard',
                operation: 'Mp3 Download',
                datetime: this.datePipe.transform(new Date(), global.date_time_format)
            });

            // Added toastr notification for file conversion #mohsin-dev
            infoToastr = this._toastr.info('Converting audio file...', 'Please wait', { disableTimeOut: true });
            downloadResponse = this._dashboardService
                .downloadFile(global.url + API_URL.ROUTES.convertMp4ToMp3Anddownload, { recording });
            // this._dashboardService.convertMp4ToMp3Anddownload(recording).subscribe(data => {
            //   recording.file_name = file_name + '.mp3';
            //     this.downloadFile(global.url + '/downloadMP3File',recording);
            //   }, console.error, () => this._toastr.clear(infoToastr.toastrId));
        }

        downloadResponse.subscribe((res: any) => {
            if (infoToastr) {
                this._toastr.clear(infoToastr.toastrId);
            }

            if (res instanceof HttpResponse) {
                this.downloadProgress.show = false;
                localStorage.removeItem('downloadInProgress');
                // saveAs(res, fileName);
                var url = window.URL.createObjectURL(res.body);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = `${recording.audio_title}.${downloadType}`;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
            } else {
                if (res.type === 0) {
                } else if (res.type === 3) {
                    const { loaded, total } = res;
                    const progress = Math.round(loaded / total * 100);
                    if (progress > 0) {
                        this.downloadProgress.mode = 'determinate';
                        this.downloadProgress.progress = progress;
                    }
                }
            }
            // if(data) {

            //   saveAs(data, fileName);
            // }
        });

    }

    downloadFile(url, params) {
        const infoToastr: any = this._toastr.info('Preparing file download', 'Please wait...', {
            disableTimeOut: true
        });

        const headers = new HttpHeaders();
        // .set('Access-Control-Allow-Headers', 'Content-Length')
        // .set('Access-Control-Expose-Headers', 'Content-Length')
        headers.append('Access-Control-Allow-Headers', 'Content-Length');
        headers.append('Access-Control-Expose-Headers', 'Content-Length');
        headers.append('Content-Type', 'application/octet-stream');
        let fileDownloadToast = null;
        return this._httpClient
            .get(url, {
                responseType: 'blob',
                reportProgress: true,
                observe: 'events',
                params: {
                    ...params
                },
                headers: headers
            }).pipe(
                map(res => {
                    return {
                        filename: params.file_name,
                        data: res
                    };
                }))
            .subscribe((res: any) => {
                this._toastr.clear(infoToastr.toastrId);
                if (fileDownloadToast === null) {
                    fileDownloadToast = this._toastr.info('Audio is being downloaded...', 'Please wait', { disableTimeOut: true });
                }
                if (res.data instanceof HttpResponse) {
                    var url = window.URL.createObjectURL(res.data.body);
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.setAttribute('style', 'display: none');
                    a.href = url;
                    a.download = res.filename;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove(); // remove the element
                } else {
                    // 0 request initiated, 3 = in progress

                    if (res.data.type === 0) {
                        // this.downloadProgress.mode = 'buffer';
                        this.downloadProgress.mode = 'determinate';
                        this.downloadProgress.progress = 0;
                    } else if (res.data.type === 3) {
                        const { loaded, total } = res.data;
                        this.downloadProgress.progress = Math.round(loaded / total * 100);
                    }
                }

            }, error => {
            }, () => {
                setTimeout(() => {
                    this.downloadProgress.show = false;
                    if (fileDownloadToast) {
                        this._toastr.clear(fileDownloadToast.toastrId);
                    }
                    localStorage.removeItem('downloadInProgress');
                }, 1000);
            });
    }

    isDictationInMultiDownloads(audio) {
        for (let a of this.multiSelectedDictations) {
            if (a._id === audio._id) {
                return true;
            }
        }
        return false;
    }

    ngDoCheck() {
        let isCheckedAll = true;
        for (let t of this.audios) {
            if (this.multiSelectedDictations.indexOf(t) === -1) {
                isCheckedAll = false;
                break;
            }
        }
        this.isCheckedAll = isCheckedAll;
    }

    selectGroupedDictations(selectAll = true) {
        this.audios.forEach(t => {
            const i = this.multiSelectedDictations.indexOf(t);
            if (i === -1 && selectAll && !t.empty) {
                this.multiSelectedDictations.push(t);
            } else if (!selectAll && !t.empty) {
                this.multiSelectedDictations.splice(i, 1);
            }
        })
    }


}
