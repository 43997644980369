import {Component, OnInit, Input, AfterViewInit, EventEmitter, Output} from '@angular/core';
import * as global from '../../global';
import {AuthService} from 'src/app/services/auth.service';
import { TemplatesService } from '../../templates/templates.service';
import { MatTableDataSource } from '@angular/material/table';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
    selector: 'app-note-templates',
    templateUrl: './note-templates.component.html',
    styleUrls: ['./note-templates.component.css'],
})
export class NoteTemplatesComponent implements OnInit {
   @Input() note:any;
   @Input() provider:any;
   @Input() facility:any;
   @Output() onTemplateSelect:EventEmitter<any> = new EventEmitter();
   templates:any[] = [];
   
   displayedColumns: string[];
   dataSource: any;
   loaderId = 'note-templates-loader';

    constructor(
        private templatesService:TemplatesService,
        private loader: NgxUiLoaderService
    ) {
        this.displayedColumns = ['title', 'operations'];
    }

    ngOnInit() {
        
        console.log({
            facility: this.facility,
            provider: this.provider
        })
        if(this.facility && this.provider) {
            this.initTemplates();
        }
    }

    initTemplates() {
        this.loader.startLoader(this.loaderId); 
        this.templatesService.getTemplatesByFacilityId(this.facility._id, this.provider._id).subscribe((response:any) => {
            if(response.status === 200){
                this.templates = response.data
                this.dataSource = new MatTableDataSource(this.templates);
                this.loader.stopLoader(this.loaderId);
            }
        })
    }

    handleSelectTempalte(template) {
        this.onTemplateSelect.emit(template);
    }
}
