<mat-radio-group 
class="flex gap-2"
aria-label="Phrase Type"
[(ngModel)]="dictionaryElement.phrase_type">
    <mat-radio-button value="text_based">Text Based</mat-radio-button>
    <mat-radio-button value="list_based">List Based</mat-radio-button>
</mat-radio-group>
<br />
<div class="flex flex-col gap-2">
<mat-form-field>
    <input matInput type="text" name="phrase_key" #phrase_key="ngModel" [(ngModel)]="dictionaryElement.key"
    (blur)="checkDuplicate(dictionaryElement.key)" placeholder="Shortcut" [disabled]="!isNew" required pattern="[a-z]+" onkeypress="return /[a-z]/.test(event.key)">
    <mat-error *ngIf="phrase_key.invalid">Please enter valid shortcut, it should not contains spaces or special characters</mat-error>
</mat-form-field>
<mat-error *ngIf="isShortcutAlreadyExists(dictionaryElement.key)" style="font-size: 12px;">
    Shortcut already exists, please enter different one.
</mat-error>
<small *ngIf="uniqueKeyError"style="color: red; font-size: 11px">Please enter unique shortcut, that does not already exist.</small>

<ng-container  *ngIf="dictionaryElement.phrase_type === 'text_based'">
    <div style="height: 400px;">
        <ckeditor
         [(ngModel)]="dictionaryElement.value" name="phrase_value" class="h100"
            editorUrl="../../../../assets/ckeditor/ckeditor.js" 
            [readOnly]="!cke4Config.editable" 
            [config]="cke4Config"
            type="divarea" (ready)="onReadyCkeditor($event)"></ckeditor>
    </div>
</ng-container>

<ng-container *ngIf="dictionaryElement.phrase_type === 'list_based'">
    <mat-form-field class="phrase-chip-list">Item
        <mat-chip-grid #chipGrid aria-label="">
            @for (item of dictionaryElement.value; track item) {
              <mat-chip-row
                (removed)="remove(item)"
                [editable]="true"
                (edited)="edit(item, $event)"
                [aria-description]="'press enter to edit ' + item">
                {{item}}
                <button matChipRemove [attr.aria-label]="'remove ' + item">
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
            }
            <input placeholder="New Item..."
                   [matChipInputFor]="chipGrid"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   [matChipInputAddOnBlur]="addOnBlur"
                   (matChipInputTokenEnd)="add($event)"/>
          </mat-chip-grid>
      </mat-form-field>    
</ng-container>
<div class="flex justify-center items-center gap-2">
    <button class="dn-button-primary" mat-raised-button [disabled]="!dictionaryElement.key || !dictionaryElement.value || isShortcutAlreadyExists(dictionaryElement.key) || uniqueKeyError" (click)="onSave()">Save</button>
    <button class="dn-button-secondary" mat-raised-button type="button" mat-dialog-close>Cancel</button>
</div>
</div>
<div class="top-right-close-btn">
<button mat-mini-fab mat-dialog-close nofocus>
    <mat-icon>cancel</mat-icon>
</button>
</div>