import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { QueryReportService } from '../../general-report/service/query-report.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import moment from 'moment'
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-create-query-report-dialog',
  templateUrl: './create-query-report-dialog.component.html',
  styleUrls: ['./create-query-report-dialog.component.css']
})
export class CreateQueryReportDialogComponent implements OnInit {
  loaderId = 'create-query-report-dialog';
  companies: any = [];
  model: any = {
    title: null,
    query: null,
    parentReportId: null,
    queryType: "find",
    displayType: "table",
    dateOfService: null
  };
  filter: any = {};
  project: any = {};
   serviceDateFilter: Boolean = true;


  constructor(
    private dialogRef: MatDialogRef<CreateQueryReportDialogComponent>,
    private _toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _queryService: QueryReportService,
    private loader: NgxUiLoaderService,
    private _commonService: CommonService

  ) {
    if (this.data) {
      this.model.parentReportId = this.data.parentReportId;
      this.filter._id = this.data.queryId;
    }
  }

  async ngOnInit() {
    if (this.filter._id) {
      this.getQueryReport();
    }
  }


  getQueryReport() {
    this.loader.startLoader(this.loaderId);
    this._queryService.getQueryReport(this.filter, this.project).subscribe((response: any) => {
      if (response.status == 200) {
        const { title, query, display_type, query_type } = response.data;
        this.model.title = title;
        this.model.displayType = display_type;
        this.model.queryType = query_type;
        this.model.query = JSON.stringify(query);
        this.loader.stopLoader(this.loaderId);
      }
    }, err => {
      this._toastr.error(err, "ERROR");
    }
    );
  }

  changeServiceDate(event) {
    if (event.startDate && event.endDate) {
      this.model.dateOfService = {
        startDate: null,
        endDate: null
      }
      this.model.dateOfService.startDate = event.startDate.toDate();
      this.model.dateOfService.endDate = event.endDate.toDate();
      const noOfDays = this._commonService.getDifferenceOfDates(event.startDate, event.endDate,'days');
      if (noOfDays > 30) {
        this.serviceDateFilter = false;
        this.model.dateOfService.endDate = moment(this.model.dateOfService?.startDate).add(29, "days").startOf('day');
        this._toastr.error('Can not select more than 30 days', 'ERROR');

        setTimeout(() => {
          this.serviceDateFilter = true;
        }, 300)
      }
    }
  }


  submitForm() {
    this.loader.startLoader(this.loaderId);
    // if (this.model.query) {
    //   this.model.query = JSON.stringify(this.model.query);
    // }
    if (this.filter._id) {
      this.model._id = this.filter._id;
      this._queryService.updateQueryReport(this.model).subscribe((response: any) => {
        if (response.status == 200) {
          this.loader.stopLoader(this.loaderId);
          this._toastr.success("Report updated", "Success");
          this.dialogRef.close(response.data);
        }
      }, err => {
        this.loader.stopLoader(this.loaderId);
        this._toastr.error(err, "ERROR");
      }
      );

    }
    else {
      this._queryService.createQueryReport(this.model).subscribe((response: any) => {
        if (response.status == 200) {
          this._toastr.success("Report Saved", "Success");
          this.dialogRef.close(response.data);
        }
      }, err => {
        this._toastr.error(err, "ERROR");
      }
      );
    }
  }

}
