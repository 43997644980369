import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as global from '../../../includes/global';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { ProcedureNotesService } from 'src/app/services/procedure-notes.service';
import { CompanyService } from 'src/app/services/Modules/company.service'
import { ProcedureDataset, ProcedureType } from '../../wound/procedure-datasets/procedure-datasets.component';
import { lastValueFrom } from 'rxjs';
import { WoundService } from 'src/app/services/wound.service';
import { ToastrService } from 'ngx-toastr';
import _ from 'lodash';

interface ProcedureNoteData {
  header_id: string,
  value: string,
  inputs?: [{
    label: string,
    value: string
  }]
}
interface DatatSetRow {
  value: String,
  unit: String
}
interface ProcedureNoteDataset {
  _id: String,
  header: String,
  header_type: String,
  multiselect_enabled: Boolean,
  rows: Array<DatatSetRow>,
  procedure_type_id: String,
  default_value: String,
  formula: String
}

interface ProcedureTypeData {
  procedure_id: string,
  procedure_note_data: ProcedureNoteData[],
  selectedProcedureType: {
    _id: string,
    title: string
  },
  selectedProcedureCategory: {
    _id: string,
    category: string
  }
}

@Component({
  selector: 'app-procedure-note-dialog',
  templateUrl: './procedure-note-dialog.component.html',
  styleUrls: ['./procedure-note-dialog.component.css']
})
export class ProcedureNoteDialogComponent implements OnInit {
  selectedOptions: { [header: string]: string } = {};
  allProcedureNotesData: Array<ProcedureTypeData> = [];
  selectedProcedureTypeData: ProcedureTypeData = {
    procedure_id: '',
    procedure_note_data: [],
    selectedProcedureType: null,
    selectedProcedureCategory: null
  };
  procedureTypeDataCopy: ProcedureTypeData;
  allProcedureTypes: Array<ProcedureType> = [];
  oldAllProcedureTypeData: Array<ProcedureTypeData> = [];
  currentUser: any;
  selectUserType: string;
  company_id: string;
  procedureNoteDataSet: ProcedureNoteDataset[] = [];
  wound_id: string;
  procedureNotesDataId: string = '';
  companySettings: string[];
  datasetFetched: boolean = false;
  loaderId = 'app-procedure-note-dialog';
  currentPageIndex: number = 0;
  firstPageSize: number = 7;
  pageSize: number = 10;
  totalEntries: number;
  currentDataSet: string[];
  allProcedureCategories: ProcedureDataset[];
  activatedRouteIndex: number =  -1;
  addingNewProcedureData: boolean = false;
  updatedProcedureTypeData = {
    addOrUpdatedProcedure: [],
    deletedProcedure: []
  };
  constructor(
    private _wound: WoundService,
    private _procedureNotes: ProcedureNotesService,
    private companyService: CompanyService,
    public dialogRef: MatDialogRef<ProcedureNoteDialogComponent>,
    private _authService: AuthService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService
  ) {
    this.currentUser = this._authService.currentUser;
    this.company_id = this.currentUser.company_id;
    this.selectUserType = this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT ? global.USER_TYPE.DOCTOR : this._authService.userType;
  }

  async ngOnInit() {
    this.allProcedureNotesData = new Array<ProcedureTypeData>();
    this.loader.startLoader(this.loaderId);
    await this.initProcedureType();
    await this.getProcedureNotesData();
    if (this.selectedProcedureTypeData.selectedProcedureType._id) {
      await this.getProcedureDataset();
    }
    this.loader.stopLoader(this.loaderId);
  }
  async initProcedureType() {
    let response = await lastValueFrom(this._wound.getProcedureTypes(this.company_id));
    if (response['status'] == 200) {
      this.allProcedureTypes = response['data'].array as ProcedureType[];
    } else {
      this.allProcedureTypes = [];
    }
  }
  async initProcedureCategory() {
    let response = await lastValueFrom(this._wound.getProcedureDatasets(this.selectedProcedureTypeData.selectedProcedureType._id));
    if (response['status'] == 200) {
      this.allProcedureCategories = response['data'].array as ProcedureDataset[];
    } else {
      this.allProcedureCategories = [];
    }
  }

  async onChangeProcedureType() {
    this.loader.startLoader(this.loaderId);
    await this.getProcedureDataset();
    this.selectedProcedureTypeData.selectedProcedureType.title = this.allProcedureTypes.find(type => type._id.toString() === this.selectedProcedureTypeData.selectedProcedureType._id.toString()).title;
    this.selectedProcedureTypeData.selectedProcedureCategory =  this.allProcedureCategories.length === 1 ? {_id: this.allProcedureCategories[0]._id, category: this.allProcedureCategories[0].category} : {_id: "", category: ""};
    this.allProcedureNotesData[this.activatedRouteIndex].selectedProcedureType = this.selectedProcedureTypeData.selectedProcedureType;
    this.allProcedureNotesData[this.activatedRouteIndex].selectedProcedureCategory = this.selectedProcedureTypeData.selectedProcedureCategory;
    this.selectedProcedureTypeData.procedure_note_data = this.allProcedureNotesData[this.activatedRouteIndex].procedure_note_data;
    this.loader.stopLoader(this.loaderId);
  }

  onChangeProcedureCategory(){
    this.selectedProcedureTypeData.selectedProcedureCategory.category = this.allProcedureCategories.find(category => category._id.toString() === this.selectedProcedureTypeData.selectedProcedureCategory._id.toString()).category;
    this.allProcedureNotesData[this.activatedRouteIndex].selectedProcedureCategory = this.selectedProcedureTypeData.selectedProcedureCategory;
  }

  async getProcedureDataset() {
    await this.initProcedureCategory();
    await this.getProcedureNoteDataSetColumnsSetting();
    await this.paginateDataSet();
  }

  async paginateDataSet() {
    let startIndex, endIndex, currentPageSize;

    if (this.currentPageIndex === 0) {
      currentPageSize = this.firstPageSize;
      startIndex = 0;
    } else {
      startIndex = (this.currentPageIndex - 1) * this.pageSize + this.firstPageSize;
      currentPageSize = this.pageSize;
    }

    endIndex = Math.min(startIndex + currentPageSize, this.totalEntries);

    this.currentDataSet = this.companySettings.slice(startIndex, endIndex);
    await this.getAllDatasets(this.currentDataSet);
  }

  onNextPage(): void {
    if (this.currentPageIndex < this.getTotalPages()) {
      this.currentPageIndex++;
      this.paginateDataSet();
    }
  }

  onPrevPage(): void {
    if (this.currentPageIndex > 0) {
      this.currentPageIndex--;
      this.paginateDataSet();
    }
  }

  getTotalPages(): number {
    return Math.ceil((this.totalEntries - this.firstPageSize) / this.pageSize) + 1;
  }

  async getProcedureNoteDataSetColumnsSetting() {
    const response = await this.companyService.getProcedureNoteDataSetColumnsSetting(this.company_id, this.selectUserType, this.selectedProcedureTypeData.selectedProcedureType._id.toString()).toPromise();
    this.companySettings = response['data'];
    this.totalEntries = this.companySettings.length;
  }

  async getProcedureNotesData() {
    try {
      const response = await this._procedureNotes.getProcedureNotesData(this.data.wound_id).toPromise();
      if (response['status'] === 200) {
        const data = response['data'];
        if (Array.isArray(data) && data.length > 0) {
          data.forEach((d) => {
            let procedureDataObj = {} as ProcedureTypeData;
            procedureDataObj.procedure_id = d._id || '';
            procedureDataObj.selectedProcedureType = d.procedure_type_id || { _id: "", title: "" };
            procedureDataObj.selectedProcedureCategory = d.procedure_category_id || { _id: "", category: "" };
            procedureDataObj.procedure_note_data = d.procedure_note_data || [];
            this.allProcedureNotesData = [...this.allProcedureNotesData, procedureDataObj];
          });
          this.selectedProcedureTypeData.procedure_note_data = this.allProcedureNotesData[0].procedure_note_data;
          this.selectedProcedureTypeData.selectedProcedureCategory = this.allProcedureNotesData[0].selectedProcedureCategory;
          this.selectedProcedureTypeData.selectedProcedureType = this.allProcedureNotesData[0].selectedProcedureType;
          this.activatedRouteIndex = 0;
          this.procedureNotesDataId = data[0]._id;
        } else {
          this.initializeNewProcedureData();
          this.addingNewProcedureData = true;
        }
      } else {
        this.allProcedureNotesData = [];
        this.procedureNotesDataId = null;
        this.selectedProcedureTypeData = {
          selectedProcedureType: null,
          selectedProcedureCategory: null,
          procedure_note_data: [],
          procedure_id: ''
        };
      }
      this.oldAllProcedureTypeData = [...this.allProcedureNotesData];
      this.procedureTypeDataCopy = JSON.parse(JSON.stringify(this.selectedProcedureTypeData));
    } catch (error) {
      this.toastr.error('Something went wrong fetching data', 'Failed');
    }
  }

  async getAllDatasets(header_ids) {
    this.loader.startLoader(this.loaderId);

    try {
      const response = await this._procedureNotes.getProcedureNoteDataset(this.selectUserType, this.selectedProcedureTypeData.selectedProcedureType._id, this.company_id, header_ids).toPromise();
      if (response['status'] === 200) {
        this.loader.stopLoader(this.loaderId);
        this.procedureNoteDataSet = response['data'];
        this.datasetFetched = true;
      } else {
        this.procedureNoteDataSet = [];
        this.datasetFetched = false;
      }
    } catch (error) {
      this.toastr.error('Something went wrong fetching data', 'Failed');
    }
  }

  getOptionsForHeader(header_id) {
    const header = this.selectedProcedureTypeData.procedure_note_data.find(data => data.header_id == header_id);
    if (this.selectedProcedureTypeData.procedure_note_data && header) {
      return header.value ? header.value.split(', ') : [];
    }
    return [];
  }

  onOptionsSelected(options: string[], header_id: string): void {
    const formattedOptions = options.join(', ');
    this.selectedOptions[header_id] = formattedOptions;
    this.saveDialogData(header_id, formattedOptions);
  }

  getInputValue(header_id, type, label) {
    const header = this.selectedProcedureTypeData.procedure_note_data.find(data => data.header_id == header_id);
    let value = '';
    if (header) {
      if (type == 'header') {
        value = header.value;
      } else {
        const input = header.inputs.find(input => input.label == label);
        if (input) {
          value = input.value;
        }
      }
    }
    return value;
  }

  onChangeInput(procedureDataset, label, event) {
    let existingEntryIndex = this.selectedProcedureTypeData.procedure_note_data.findIndex(entry => entry.header_id === procedureDataset._id);
    if (existingEntryIndex !== -1) {
      const inputIndex = this.selectedProcedureTypeData.procedure_note_data[existingEntryIndex].inputs.findIndex(entry => entry.label === label);
      if (inputIndex !== -1) {
        this.selectedProcedureTypeData.procedure_note_data[existingEntryIndex].inputs[inputIndex].value = event.target.value;
      } else {
        this.selectedProcedureTypeData.procedure_note_data[existingEntryIndex].inputs.push({ label, value: event.target.value });
      }
    } else {
      this.selectedProcedureTypeData.procedure_note_data.push({ header_id: procedureDataset._id, value: '0', inputs: [{ label, value: event.target.value }] });
      existingEntryIndex = this.selectedProcedureTypeData.procedure_note_data.findIndex(entry => entry.header_id === procedureDataset._id);
    }
    this.multiplyInputs(existingEntryIndex, procedureDataset.formula || '');
  }

  multiplyInputs(existingEntryIndex, formula) {
    let operationResult = 1;
    if(formula) {
      const labelMap = this.selectedProcedureTypeData.procedure_note_data[existingEntryIndex].inputs.reduce((map, item) => {
        map[item.label] = item.value;
        return map;
      }, {});
      const formulaWithValues = this.replaceLabelsWithValues(formula, labelMap);
      this.selectedProcedureTypeData.procedure_note_data[existingEntryIndex].value = (new Function('return ' + formulaWithValues)()).toFixed(2).toString();
      return;
    }
    this.selectedProcedureTypeData.procedure_note_data[existingEntryIndex].inputs.forEach((input) => {
      operationResult *= Number(input.value);
    });
    this.selectedProcedureTypeData.procedure_note_data[existingEntryIndex].value = operationResult.toString();
  }
  replaceLabelsWithValues(text, labelMap) {
    const splitText = text.split(' ');
    const operators = ["+", "-", "*", "/"];
    const defaultValues = {
      '*': 1,
      '+': 0,
      '-': 0,
      '/': 1
    };
    // Process each token in splitText
    splitText.forEach((item, index) => {
      if (labelMap.hasOwnProperty(item)) {
        splitText[index] = labelMap[item];
      } else if (!operators.includes(item)) {
        const operator = this.getNearestOperator(splitText, index, operators);
        const defaultValue = operator ? defaultValues[operator] : '';
        splitText[index] = defaultValue;
      }
    });
  
    return splitText.join(' ');
  }

  // Function to find the nearest operator before a given index
  getNearestOperator(tokens, index, operators) {
    for (let i = index - 1; i >= 0; i--) {
      if (operators.includes(tokens[i])) {
        return tokens[i];
      }
    }
    return null; // No operator found before this index
  }
  saveDialogData(header_id: string, value: string): void {
    const existingEntryIndex = this.selectedProcedureTypeData.procedure_note_data.findIndex(entry => entry.header_id === header_id);

    if (existingEntryIndex !== -1) {
      this.selectedProcedureTypeData.procedure_note_data[existingEntryIndex].value = value;
    } else {
      this.selectedProcedureTypeData.procedure_note_data.push({ header_id, value });
    }

  }

  async closeDialog() {
    await this.addOrEditProcedureNotesData();
    if (this.updatedProcedureTypeData.addOrUpdatedProcedure.length > 0 || this.updatedProcedureTypeData.deletedProcedure.length > 0) {
      this.dialogRef.close({data: this.updatedProcedureTypeData, totalProcedureNotes: this.allProcedureNotesData.filter(procedure => procedure.selectedProcedureType._id !== '').length});
    } else {
      this.dialogRef.close(null);
    }
  }

  async addOrEditProcedureNotesData() {
    if (
      (JSON.stringify(this.selectedProcedureTypeData) !== JSON.stringify(this.procedureTypeDataCopy) && this.selectedProcedureTypeData.selectedProcedureType && this.selectedProcedureTypeData.selectedProcedureCategory)
    ) {
      this.loader.startLoader(this.loaderId);
      const data = {
        ...this.data,
        procedure_type_id: !this.selectedProcedureTypeData.selectedProcedureType._id ? null : this.selectedProcedureTypeData.selectedProcedureType._id,
        procedure_category_id: !this.selectedProcedureTypeData.selectedProcedureCategory._id ? null : this.selectedProcedureTypeData.selectedProcedureCategory._id,
        procedure_note_data: this.selectedProcedureTypeData.procedure_note_data
      };
      if (this.procedureNotesDataId) {
        await this.updateProcedureNotesData(data);
      }
      else {
        await this.addProcedureNotesData(data);
        this.addingNewProcedureData = false;
      }
      this.updatedProcedureTypeData.addOrUpdatedProcedure.push({
        _id: this.procedureNotesDataId,
        phrase_template_changed: JSON.stringify(this.selectedProcedureTypeData.selectedProcedureType) !== JSON.stringify(this.procedureTypeDataCopy.selectedProcedureType) || JSON.stringify(this.selectedProcedureTypeData.selectedProcedureCategory) !== JSON.stringify(this.procedureTypeDataCopy.selectedProcedureCategory),
        procedure_note_data: this.selectedProcedureTypeData.procedure_note_data
      });
      this.loader.stopLoader(this.loaderId);
    }
  }

  async addProcedureNotesData(data) {
    try {
      const response = await this._procedureNotes.addProcedureNotesData(data).toPromise();
      if (response['status'] === 200) {
        this.procedureNotesDataId = response['data']._id;
        this.allProcedureNotesData[this.activatedRouteIndex].procedure_id = this.procedureNotesDataId;
        this.selectedProcedureTypeData.procedure_id = this.procedureNotesDataId;
        this.toastr.success('Procedure note selection saved succesfully', 'Success');
      } else {
        this.toastr.error('Error saving procedure note selection', 'Error');
      }
    } catch (error) {
      this.toastr.error('Something went wrong', 'Failed');
    }
  }

  async updateProcedureNotesData(data) {
    try {
      const response = await this._procedureNotes.updateProcedureNotesData(this.procedureNotesDataId, data).toPromise();
      if (response['status'] === 200) {
        this.toastr.success('Procedure note selection saved succesfully', 'Success');
      } else {
        this.toastr.error('Error saving procedure note selection', 'Error');
      }
    } catch (error) {
      this.toastr.error('Something went wrong', 'Failed');
    }
  }

  async changeRoute(index) {
    if (this.selectedProcedureTypeData.selectedProcedureType._id && this.addingNewProcedureData) {
      await this.addOrEditProcedureNotesData();
    }
    else if (this.addingNewProcedureData) {
      return;
    } else if (this.procedureNotesDataId) this.addingNewProcedureData = false
    this.activatedRouteIndex = index;
    this.loader.startLoader(this.loaderId);
    this.procedureNotesDataId = this.allProcedureNotesData[index].procedure_id.toString();
    this.selectedProcedureTypeData = this.allProcedureNotesData[index];
    await this.getProcedureDataset();
    this.procedureTypeDataCopy = JSON.parse(JSON.stringify(this.selectedProcedureTypeData));
    this.loader.stopLoader(this.loaderId);

  }

  async addNewProcedureData() {
    if (this.allProcedureNotesData.length > 0) {
      await this.addOrEditProcedureNotesData();
    }
    this.initializeNewProcedureData();
    this.addingNewProcedureData = true;
  }

  getHeading(procedure) {
    let heading = '';
    if (procedure.selectedProcedureType) heading += procedure.selectedProcedureType.title;
    if (procedure.selectedProcedureCategory) heading += ' - ' + procedure.selectedProcedureCategory.category;
    return heading;
  }
  /**
   * Deletes a specific procedure note data entry from the list and updates the UI accordingly.
   * @param procedureData The data object of the procedure note to be deleted.
   * @param index The index of the procedure note in the allProcedureNotesData array.
   */
  async deleteProcedureData(procedureData: any, index: number): Promise<void> {
    this.loader.startLoader(this.loaderId);
    const response = await lastValueFrom(this._procedureNotes.deleteProcedureNotesData(procedureData.procedure_id));
    if (response['status'] === 200) {
      const deletedProcedureType = this.allProcedureNotesData[index].selectedProcedureType.title;
      if (this.allProcedureNotesData.length > 1) {
        this.allProcedureNotesData.splice(index, 1);
        
        if (this.activatedRouteIndex === index) {
          if (!this.addingNewProcedureData) {
            this.activatedRouteIndex = this.allProcedureNotesData.length - 1;
            this.procedureNotesDataId = this.allProcedureNotesData[this.activatedRouteIndex].procedure_id.toString();
            this.selectedProcedureTypeData = this.allProcedureNotesData[this.activatedRouteIndex];
            await this.getProcedureDataset();
            this.procedureTypeDataCopy = JSON.parse(JSON.stringify(this.selectedProcedureTypeData));
          }
        } else {
          if (this.addingNewProcedureData) {
            this.activatedRouteIndex = this.allProcedureNotesData.length - 1;
          }
        }
      } else {
        this.allProcedureNotesData.splice(index, 1);
        this.initializeNewProcedureData();
        this.addingNewProcedureData = true;
      }
      this.updatedProcedureTypeData.deletedProcedure.push({
        woundId: this.data.wound_id,
        _id: procedureData.procedure_id,
        procedureType: deletedProcedureType
      });
      this.loader.stopLoader(this.loaderId);
    } else {
      this.loader.stopLoader(this.loaderId);
      this.toastr.error('Something went wrong while deleting procedure', 'Failed');
    }
  }
  initializeNewProcedureData() {
    if(this.addingNewProcedureData) return;
    const dataObj = {} as ProcedureTypeData;
    dataObj.procedure_id = null;
    dataObj.selectedProcedureType = { _id: "", title: "" };
    dataObj.selectedProcedureCategory = { _id: "", category: "" };
    dataObj.procedure_note_data = [];
    this.allProcedureNotesData = _.cloneDeep([...this.allProcedureNotesData, dataObj]);
    this.activatedRouteIndex = this.allProcedureNotesData.length - 1;
    this.selectedProcedureTypeData.procedure_note_data = [];
    this.selectedProcedureTypeData.selectedProcedureCategory = dataObj.selectedProcedureCategory;
    this.selectedProcedureTypeData.selectedProcedureType = dataObj.selectedProcedureType;
    this.procedureNotesDataId = null;
    this.procedureNoteDataSet = [];
    this.procedureTypeDataCopy = JSON.parse(JSON.stringify(this.selectedProcedureTypeData));
  }
}