<p style="padding: 0 10px;">
    Response
</p>
<table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            description
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.description}}
        </td>
    </ng-container>

    <ng-container matColumnDef="recordedBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            recordedBy
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.recordedBy}}
        </td>
    </ng-container>
    

    <ng-container matColumnDef="recordedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            recordedDate
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.recordedDate}}
        </td>
    </ng-container>
    

    <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            value
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.value}}
        </td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>