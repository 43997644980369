import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { PhrasesService } from '../phrases.service';
import { DictionaryElement } from '../user-defined-phrases/user-defined-phrases.component';
import { ProcedurePhraseEditorComponent } from '../../wound/procedure-datasets/procedure-phrase-editor/procedure-phrase-editor.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-procedurephrase',
  templateUrl: './procedurephrase.component.html',
  styleUrls: ['./procedurephrase.component.css']
})
export class ProcedurephraseComponent implements OnInit {

  dictionary: Array<DictionaryElement>;
  company_id: any;
  @Input() note: any;
  currentUser: any;

  constructor(
    private dialog: MatDialog,
    private phrasesService: PhrasesService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private authService: AuthService
  ) {
    this.currentUser = this.authService.currentUser;
  }
  async ngOnInit(): Promise<void> {
    if (window.location.pathname.includes('company')) {
      this.company_id = this.route.parent.snapshot.params.id;
    }
    else {
      this.company_id = this.currentUser.company_id;
    }
    this.initProcedurePhrases();
  }

  async initProcedurePhrases() {
    try {
      const details = {
        procedure_type_query: '',
        patient_id: this.note.patient?._id,
        company_id: this.company_id
      }
      const response = await lastValueFrom(this.phrasesService.getProcedurePhrases_old(details));
      if (response['status'] === 200) {
        this.dictionary = response['data']['array'];
        if (this.dictionary.length > 0) {
          this.sortPhrases(this.dictionary);
        }
      } else {
        this.toastr.error(response['message'], 'Error');
        this.dictionary = [];
      }
    } catch (error) {
      this.toastr.error(error['message'], 'Error');
    }
  }

  getMarker() {
    return this.phrasesService.marker
  }
  sortPhrases(arr: any[]) {
    arr.sort((a, b) => a.key.toLowerCase().localeCompare(b.key.toLowerCase()));
  }
  openPhraseViewer(element) {
    const data = {
      procedureDatasets: element,
      phrase_key: this.getMarker() + element.key,
      company_id: this.company_id,
      viewOnly: true
    };
    this.dialog.open(ProcedurePhraseEditorComponent, {
      width: '800px',
      data
    });
  }
}

