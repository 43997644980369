<ng-container>
    <div class=" white-rounded-rect">
        <div class="flex justify-between">
            <h2 style="color:#000;margin-top:0;margin-bottom:0">Filter</h2>
            <div class="flex gap-2">
                <button mat-raised-button class="dn-button-primary" (click)="handleApplyFilterClick();">Apply
                    Filter</button>
                <button mat-raised-button class="dn-button-secondary" (click)="resetFilter();">Reset Filter</button>
            </div>
        </div>
        <div class="flex justify-start items-baseline gap-2" style="position: sticky;">
            <div class="flex justify-start items-baseline input-with-cross">
                <mat-label style="margin-right:20px;color:#999;">Date Range</mat-label>
                <input *ngIf="this.serviceDateFilter"  style="width: 175px" type="text" ngxDaterangepickerMd
                    [(ngModel)]="filter.date_of_service" (datesUpdated)="changeServiceDate($event)"
                    class="mat-like-input" [ranges]="preDateRanges" [alwaysShowCalendars]="true" [maxDate]="this.c_date"
                    [linkedCalendars]="true" [showClearButton]="true" />
            </div>
            <div class="flex justify-start items-baseline" style="width: 25%;">
                <mat-form-field style="width: 100%;">
                    <div class="flex justify-start items-center">
                        <img *ngIf="showPccLogo" src="../../../assets/icons/pcc-icon.svg" width="20px" height="20px"
                            alt="">
                        <input type="text" style="display:flex;" placeholder="Select Facility" aria-label="facility"
                            matInput [formControl]="facilityControl" [matAutocomplete]="facilityAutoControl"
                            [(ngModel)]="facilityMOdel">
                        <mat-autocomplete #facilityAutoControl="matAutocomplete" autoActiveFirstOption>
                            <mat-option
                                *ngIf="currentUser.user_type === global.USER_TYPE.DOCTOR || currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE"
                                (click)="onSelectFacility()">--none--</mat-option>
                            <mat-option *ngIf="currentUser.user_type === global.USER_TYPE.BILLER"
                                (click)="onSelectFacility()" [value]="All">All</mat-option>
                            <mat-option *ngFor="let facility of filteredFacilities | async" [value]="facility.title"
                                (click)="onSelectFacility(facility);">
                                <app-facility-title [facility]="facility"></app-facility-title>
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </mat-form-field>
                <button style="height:10px;" *ngIf="facilityMOdel" mat-button mat-icon-button matSuffix
                    (click)="clearFacilityFilter()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div style="margin-top: 2rem;">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="sr">
                <th mat-header-cell *matHeaderCellDef>Sr #</th>
                <td mat-cell *matCellDef="let element;  let i = index">
                    {{i+1}}
                </td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>Date</th>
                <td mat-cell *matCellDef="let element">
                    {{element.date}}
                </td>
            </ng-container>
            <ng-container matColumnDef="sent_to">
                <th mat-header-cell *matHeaderCellDef>Sent To</th>
                <td mat-cell *matCellDef="let element">
                    <ul class="email-list">
                        <li *ngFor="let email of element.emails">{{ email }}</li>
                    </ul>
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let element">
                    <button mat-raised-button class="dn-button-primary"
                        (click)="downloadReport(element)">Download</button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</ng-container>