<form #form="ngForm" (submit)="handleSubmit(form)">
    <div class="flex flex-col">

          <mat-form-field class="flex-grow" appearance="fill">
            <mat-label>Code</mat-label>
            <input matInput placeholder="Enter Code" [(ngModel)]="newICDmodel.icdCode"
                #newicd_code="ngModel" name="code" (change)="checkIsEmpty()" required />
            <mat-error *ngIf="newicd_code.invalid">
                Please Enter Code
            </mat-error>
        </mat-form-field>

        <mat-form-field class="flex-grow" appearance="fill">
            <mat-label>Description</mat-label>
            <input matInput placeholder="Enter Description" [(ngModel)]="newICDmodel.description"
                #newicd_description="ngModel" name="description" (change)="checkIsEmpty()" required />
            <mat-error *ngIf="newicd_description.invalid">
                Please Enter Description
            </mat-error>
        </mat-form-field>

        <mat-form-field class="flex-grow" appearance="fill">
            <mat-label>Medium Description</mat-label>
            <input matInput placeholder="Enter Medium Description" [(ngModel)]="newICDmodel.mediumDescription"
                #newicd_mediumDescription="ngModel" name="mediumDescription" />
            <mat-error *ngIf="newicd_mediumDescription.invalid">
                Please Enter Description
            </mat-error>
        </mat-form-field>

        <div class="flex justify-start items-baseline" style="gap: 200px;">
        
            <mat-form-field>
                <input matInput [matDatepicker]="picker" [min]="this.current_date" placeholder="Choose Expiry Date"
                    [(ngModel)]="expiry_date" #newicd_end_date="ngModel" name="end_date" >
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        
            <mat-checkbox [checked]="newICDmodel.pdpm_status" (change)="setPdpm($event.checked)"> PDPM Status</mat-checkbox>
        </div>

        <div class="flex justify-end gap-5" style="margin-top: 20px;">
            <button type="button" mat-raised-button class="dn-button-secondary" (click)="clearDetails()">
                Clear Details
            </button>
            <button mat-raised-button class="dn-button-primary" type="submit" [disabled]="isButtonDisable">
                Add New Code
            </button>
        </div>
    </div>
</form>


<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>

<div *ngIf="isShowTable">
    <mat-card>
        <div style="margin-top: 1rem;height: 18vh;overflow: auto;" class="flex justify-start items-start" > 
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="Code">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;">Code</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.code}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;">Description</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.description}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Medium Description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 40%;">Medium Description</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.mediumDescription}}
                            
                    </td>
                </ng-container> 

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-card>
    <div style="margin-top: 1rem;" class="flex justify-start items-baseline gap-5">   
            <mat-label>Do you want to add ICD Code {{newICDmodel.icdCode}} again? </mat-label>           
            <button type="button" mat-raised-button class="dn-button-primary" type="submit" (click)="addDuplicateCode(event)" [disabled]="isButtonDisable">
                YES
            </button>
            <button mat-raised-button class="dn-button-secondary" [disabled]="isButtonDisable" (click)="closeDialogue(event)">
                NO
            </button>
    </div>
</div>