import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PatientListService } from '../../patient-list/patient-list-service';
// import { PCCService } from 'src/app/services/pcc.service';

@Component({
  selector: 'app-matrix-patient-coverages',
  templateUrl: './matrix-patient-coverages.component.html',
  styleUrls: ['./matrix-patient-coverages.component.css']
})
export class MatrixPatientCoveragesComponent implements OnInit {

  @Input('patient') patient;
  // @Input('pccOrgUuid') pccOrgUuid;
  constructor(
    private patientListService: PatientListService,
    private toastr:ToastrService,
    private loader: NgxUiLoaderService) { }
  pccOrgUuid:string;
  coveragesData;
  dataSource;
  patientCoverages
  
  p_insuranceGroupNumber //empty
p_insurancePolicyNumber  //policy Number
p_insurance_company_id // not using
p_insurance_holder_name // insured patient
p_insurance_company_name  //insurance company
p_insurance_carrier   //insurance company
p_insurance_alternate_id //insurance plan ID alternate 
p_insurance_identifier  //insurance plan ID
p_insurance_text  //insurance plan text IN1.2.2

  displayedColumns = [
    "insuredPerson",
    // "insuranceType",
    "insurancePlan",
    "policyNumber",
    "insurancePlanID",
    "insuranceCompany", 
    'planType',
    'planEffectiveDate'
  ];

  loaderId ='patient-coverages-loader';
  inprogress = false;

  ngOnInit(): void {
    if(this.patient && this.patient.matrix_payload?.coverages){
      this.patientCoverages = this.patient.matrix_payload.coverages;
      this.dataSource = new MatTableDataSource(this.patientCoverages);
      console.log("Patient coverages data in coverages---------------: ", this.patientCoverages);
    }
    // this.pccOrgUuid = this.patient.facility_id_ref.pcc_orgUuid;
    // if(!this.patientCoverages || this.patientCoverages.length < 0){
    //   this.loadData();
    // }
  }

  async loadData() {
    this.loader.startLoader(this.loaderId);
    let response:any = await this.patientListService.getInsuranceInfo(this.patient._id).toPromise();
    if(response.status == 200){
      console.log('insurance data: ', response)
      this.coveragesData = response.data;
      let primaryArray = [];
      let secondaryArray = [];
      let primaryObj = {};
      let secondaryObj = {};
      let coverages  = []
      for (const key in this.coveragesData) {
        if (Object.prototype.hasOwnProperty.call(this.coveragesData, key)) {
          if(key.includes('p_')){ 
            let colName = key.replace('p_', '');
            primaryArray.push(  { [colName]: this.coveragesData[key], isPrimary: key.includes('p_') })
          }
          if(key.includes('s_')){ 
            let colName = key.replace('s_', '');
            secondaryArray.push(  { [colName]: this.coveragesData[key], isPrimary: key.includes('p_') })
          }
          
        }
      }
      primaryObj = {...primaryArray}
      secondaryObj = {...secondaryArray} 
      
      coverages.push([...primaryArray], [...secondaryArray])
      console.log("Coverages-----------------: ", coverages);
      
      this.dataSource = new MatTableDataSource(coverages);
    }
    else {
      this.toastr.error('Something went wrong while fetching Patient Coverages data');
    } 
    this.loader.stopLoader(this.loaderId);
  }
  getInsuredPerson(elemnt){   
      return this.patient.last_name + ' ' + this.patient.first_name; 
      // for (const iterator of elemnt) {
      //   // console.log('iterator: ', iterator)
      //   for (const key in iterator) {  
      //     // console.log("==========key==========: ", key)
      //     if(key == 'insurance_holder_name'){
      //       return iterator[key]
      //     }
      //   } 
      // }    
  }
  getInsuranceType(elemnt){ 
    for (const iterator of elemnt) {
      for (const key in iterator) {  
        if(key == 'insurance_holder_name'){
          return iterator['isPrimary'] == true? 'Primary': 'Secondary'
        }
      } 
    } 
  }
  getInsurancePlan(elemnt){ 
    let insID = ""
    if(this.patientCoverages){
      if(elemnt.insurance_identifier || elemnt.insurance_identifier != '' ){
        insID = elemnt.insurance_identifier
      }
      else{
        insID = elemnt.insurance_alternate_id
      } 
    }
    // else{
    //   for (const iterator of elemnt) {
    //     for (const key in iterator) {  
    //       if(key == 'insurance_identifier' && (iterator[key] || iterator[key] != "")){
    //         insID = iterator[key]
    //       }
    //       else if(key == 'insurance_alternate_id' && insID == ""){
    //         insID = iterator[key]
    //       }  
    //     } 
    //   }  
    // }
    return insID
  }
  getPolicyNumber(elemnt){ 
    if(this.patientCoverages){ 
        return elemnt.insurance_policy_number 
    }
    // else{
    //   for (const iterator of elemnt) {
    //     for (const key in iterator) {  
    //       if(key == 'insurancePolicyNumber'){
    //         return iterator[key]
    //       }
    //     } 
    //   }  
    // }
  }
  getInsurancePlanID(elemnt){ 
    if(this.patientCoverages){ 
      return elemnt.insurance_id_text 
    }
    // else{
    //   for (const iterator of elemnt) {
    //     for (const key in iterator) {  
    //       if(key == 'insurance_text'){
    //         return iterator[key]
    //       }
    //     } 
    //   }
    // }
  }
  getInsuranceCompany(elemnt){ 
    if(this.patientCoverages){ 
      return elemnt.insurance_company_name 
    }
    // else{
    //   for (const iterator of elemnt) {
    //     for (const key in iterator) {  
    //       if(key == 'insurance_company_name'){
    //         return iterator[key]
    //       }
    //     } 
    //   }
    // }
  } 
  getPlanType(elemnt){ 
    if(this.patientCoverages){ 
      return elemnt.plan_type 
    }
    else{ 
      return "";
    }
  } 
  getPlanEffectiveDate(elemnt){ 
    if(this.patientCoverages){ 
      return elemnt.plan_effective_date 
    }
    else{ 
      return "";
    }
  } 
}

