import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, lastValueFrom } from 'rxjs';
import { WoundCareWidgetService } from 'src/app/includes/note-sidebar/wound-care-widget/wound-care-widget.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { WoundService } from 'src/app/services/wound.service';
import { getFieldName } from 'src/app/includes/note-sidebar/wound-care-widget/wound-utils';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { Location } from '@angular/common';
import moment from 'moment';
import * as global from '../../../../global';
import { HttpResponse } from '@angular/common/http';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { WoundNurseRelationService } from 'src/app/services/Modules/woundNurse.service';
import { SnfWcNurseAssociationService } from 'src/app/includes/snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { FacilityService } from 'src/app/services/facility.service';
import { CommonService } from 'src/app/services/common.service';
import { NursingService } from 'src/app/includes/nursing-module/nursing.service';
import ExcelJS from 'exceljs';
import { flatten } from 'flat';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import { UserService } from 'src/app/services/Modules/user.service';
import { DashboardService } from 'src/app/includes/dashboard/dashboard.service';

@Component({
  selector: 'app-wound-outcome-report',
  templateUrl: './wound-outcome-report.component.html',
  styleUrls: ['./wound-outcome-report.component.css']
})
export class WoundOutcomeReportComponent implements OnInit {
  project: any = {};
  global = global;
  searchText: string = "";
  dataSetSettings: any;
  displayedColumns: string[] = ["etiolgy", "remission", "daysToRemission", "outcome", "percentage"];
  headers: string[] = [];
  dataSource: any = new MatTableDataSource([]);
  totalTable: any = new MatTableDataSource([]);
  dataSets: any = [];
  filter: any = {
    last_assessment_date_obj: {
      startDate: moment().subtract(6, "days"),
      endDate: moment()
    },
    reportType: "avg",
    displayBy: "wound_outcomes",
    facilities: [],
    providers: [],
  };
  facilityControl = new FormControl();
  searchTextProvider: any = '';
  loaderId = 'app-wound-outcome-report';
  dataOptions: any = [];
  currentUser: any;
  isCompanySide: boolean = false;
  isUserDashboard: boolean;
  nursingSide: boolean;
  isSnfNurse: boolean;
  isNurse: boolean;
  isWoundNurse: boolean;
  wounds: any = [];
  pagination: any = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    PAGE_LIMIT: 20,
    skip: 0
  };
  currentDate = moment();
  selectedFacilities: string[] = [];
  selectedProviders: string[] = [];
  shouldShowReport: boolean = false;
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 1 Week': [moment().subtract(6, 'days'), moment()],
    'Last 2 Week': [moment().subtract(13, 'days'), moment()],
    'Last 3 Week': [moment().subtract(20, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  facilities: Array<any> = [];
  filteredFacilities: Observable<string[]>;
  @Output() updateData = new EventEmitter<any>();
  @Input() dataOption;
  @Output() shouldShowSavedReports = new EventEmitter<boolean>();
  woundsTable: any = [];
  selectedFacility: any;
  selectedProvider: any;
  avgReport: any = [];
  medianReport: any = [];
  totalFacilityAvgDaysToRemission: number = 0;
  totalAvgDaysToRemission: number = 0;
  totalNumberOfWoundsForFacility: number = 0;
  totalNumberOfWounds: number = 0;
  totalMedianDaysToRemission: number = 0;
  totalFacilityMedianDaysToRemission: number = 0;
  @ViewChild('searchFacilitiesInput') inputElement!: ElementRef;
  docNowFacilitiesCount: number = 0;
  daysToRemissionFacilitiesCount: number = 0;
  report: any = [];
  providers: any = [];
  total: any = {
    remissionTotal: 0,
    outcomeTotal: 0,
    daysToRemissionTotal: 0,
    percentageTotal: 0
  };
  isTotalBarVisible: boolean = false;
  fetchedReportType = "";
  reportDisplayType = "";

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _companyService: CompanyService,
    private _woundCareWidgetService: WoundCareWidgetService,
    private _toastr: ToastrService,
    private _route: ActivatedRoute,
    private _location: Location,
    private _woundService: WoundService,
    private loader: NgxUiLoaderService,
    private _authService: AuthService,
    private _woundNurseRelationService: WoundNurseRelationService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
    private _facilityService: FacilityService,
    private _commonService: CommonService,
    private _nurseService: NursingService,
    private renderer: Renderer2,
    private _userService: UserService,
    private _dashboardService: DashboardService,

  ) {
    this.currentUser = this._authService.currentUser;
    this.currentUser.full_name = `${this.currentUser.first_name} ${this.currentUser.last_name} ${this.currentUser.title}`;
    if (this._route.parent.snapshot.params.id) {
      this.isCompanySide = true;
      this.filter.company_id = this._route.parent.snapshot.params.id;
    } else {
      this.filter.company_id = this.currentUser.company_id;
    }
    if (window.location.pathname.includes('dashboard')) {
      this.isUserDashboard = true;
    }
    if (window.location.pathname.includes('nursing')) {
      this.nursingSide = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      this.isSnfNurse = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.NURSE) {
      this.isNurse = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
      this.isWoundNurse = true;
    }
    this.filter.user_type = this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE ? global.USER_TYPE.SNF_WC_NURSE : global.USER_TYPE.DOCTOR;
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId);
    if (this.isCompanySide) {
      this.initFacilities(true);
      this.initProviders();
    }
    else {
      if (this._authService.currentUser.user_type === global.USER_TYPE.DOCTOR) {
        await this.initFacilities();
        await this.getAssociatedProviders();
      }
      else if (this._authService.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE)
        await this.initSnfWcNurseFacilityAssociation();
      else if (this._authService.currentUser.user_type === global.USER_TYPE.WOUND_NURSE)
        await this.initWoundNurseFacilityAssociations();
      else if (this.currentUser.user_type === global.USER_TYPE.NURSE)
        await this.initNurseFacilities();
    }
    // await this.initWoundOutcomeReport();
    this.loader.stopLoader(this.loaderId);
  }

  ngAfterViewInit() {
    this.renderer.selectRootElement(this.inputElement.nativeElement).focus();
  }

  async onSelectFacility($event, facility) {
    if ($event.isUserInput) {
      this.filter.facilities = [];
      this.selectedFacilities = [];
      this.selectedFacility = null;
      if ($event.source._selected) {
        this.filter.facilities.push(facility);
        this.selectedFacilities.push(facility._id);
        this.selectedFacility = facility;
      }
      else {
        const index = this.selectedFacilities.indexOf(facility);
        if (index > -1) {
          this.filter.facilities.splice(index, 1);
          this.selectedFacilities.splice(index, 1);
          this.selectedFacility = null;
        }
      }
    }
  }

  async onSelectProvider($event, provider) {
    if ($event.isUserInput) {
      this.filter.providers = [];
      this.selectedProviders = [];
      this.selectedProvider = null;
      if ($event.source._selected) {
        this.filter.providers.push(provider);
        this.selectedProviders.push(provider._id);
        this.selectedProvider = provider;
      }
      else {
        const index = this.selectedProviders.indexOf(provider._id);
        if (index > -1) {
          this.filter.facilities.splice(index, 1);
          this.selectedProviders.splice(index, 1);
          this.selectedProvider = null;
        }
      }

    }
  }

  onChangeReportType($event) {
    this.filter.reportType = $event.value;
  }

  async initWoundOutcomeReport() {
    this.loader.startLoader(this.loaderId);
    this.resetTotalCount();
    const response: any = await lastValueFrom(this._woundService.getWoundOutcomeReport(this.filter));
    if (response.status == 200) {
      this.report = response.data.report;
      this.total = response.data.total;
      this.totalTable.data = [response.data.total];
    }
    else if (response.status === 204) {
      this._toastr.info(response.data.message);
    }
    else if (response.status === 504) {
      this._toastr.error("Request Timed Out");
    }
    else if (response.data.status === 500 || response.status === 500) {
      this._toastr.error("Something Went Wrong");
    }
    this.isTotalBarVisible = this.filter.displayBy !== "wound_outcomes";
    this.fetchedReportType = this.filter.reportType;
    this.reportDisplayType = this.filter.displayBy;
    this.loader.stopLoader(this.loaderId);
  }

  resetTotalCount() {
    this.totalFacilityAvgDaysToRemission = 0;
    this.totalAvgDaysToRemission = 0;
    this.totalNumberOfWoundsForFacility = 0;
    this.totalNumberOfWounds = 0;
    this.totalMedianDaysToRemission = 0;
    this.totalFacilityMedianDaysToRemission = 0;
    this.docNowFacilitiesCount = 0;
    this.daysToRemissionFacilitiesCount = 0;
  }

  async initWoundNurseFacilityAssociations() {
    const filter = { associated_wound_nurse_ids: this._authService.currentUser._id };
    const nurseProjection = {
      first_name: 1,
      last_name: 1,
      title: 1,
      full_name: 1
    };
    const facilityProjection = {
      title: 1,
      pcc_facId: 1,
      source: 1,
      pcc_2_legged_authentication: 1
    };
    const nurseFilter: any = {
      company_id: this._authService.currentUser.company_id,
      is_activated: "true",
      user_type: global.USER_TYPE.WOUND_NURSE
    };
    const response: any = await lastValueFrom(this._woundNurseRelationService.getWoundNurseFacilityAssociations(filter, nurseProjection, facilityProjection, nurseFilter));
    if (response.status === 200) {
      if (Array.isArray(response.data) && response.data.length > 0) {
        this.facilities = response.data.map(association => association.facility_id);
        // this.onSelectFacility(this.facilities[0]._id);
        this.showFacilityDropdown();
      }
    }
  }

  async initSnfWcNurseFacilityAssociation() {
    const filter = {
      associated_snf_wc_nurse_ids: this._authService.currentUser._id,
      company_id: this._authService.currentUser.company_id
    };
    const nurseProjection = {
      first_name: 1,
      last_name: 1,
      title: 1,
    };
    const facilityProjection = {
      title: 1,
      pcc_facId: 1,
      pcc_2_legged_authentication: 1,
      source: 1,
      pcc_orgUuid: 1
    };
    const response: any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
    if (response.status === 200 && response.data.length > 0) {
      this.facilities = response.data;
      this.showFacilityDropdown();
    }
  }

  async initNurseFacilities() {
    const response: any = await lastValueFrom(this._nurseService.getAssociatedFacilities());
    if (response.status === 200 && response.data.array.length > 0) {
      this.facilities = response.data.array;
      this.showFacilityDropdown();
    }
  }

  getColumn(header) {
    const index = this.dataSets.findIndex(dataSet => dataSet.header === header);
    if (index > -1) {
      return { columnName: header, values: this.dataSets[index].rows.map(row => row.value) };
    }
  }

  showFacilityDropdown(): void {
    this.filteredFacilities = this.facilityControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => this.filterFacilities(value)));
  }

  onChangeFacilityText(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    if (inputValue.trim() === '') {
      this.selectedFacility = null;
      delete this.filter.facility_id;
    }
  }

  private filterFacilities(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) !== -1);
  }

  async initFacilities(isCompanySide = false) {
    const filter = {
      provider_ids_ref: this._authService.currentUser._id,
      is_active: true,
      assoc_company_ids: this._authService.currentUser.company_id
    };
    if (isCompanySide) {
      delete filter.provider_ids_ref;
      filter.assoc_company_ids = this.filter.company_id;
    }
    const projection = {
      title: 1,
      pcc_facId: 1,
      pcc_2_legged_authentication: 1,
      source: 1,
      pcc_orgUuid: 1
    };
    const response: any = await lastValueFrom(this._facilityService.getFacilities(filter, projection));
    if (response.status === 200) {
      this.facilities = response.data.array;
    }
    else {
      this._toastr.error(response.data.message, 'Error');
    }
  }

  async initProviders() {
    const filter: any = {
      company_id: this.filter.companyId,
      user_type: global.USER_TYPE.DOCTOR
    };
    const projection = {
      first_name: 1,
      last_name: 1,
      full_name: 1,
      title: 1
    };
    const response: any = await lastValueFrom(this._userService.getUsers(filter, projection));
    if (response.status === 200) {
      this.providers = response.data;
      this.showFacilityDropdown();
    }
    else {
      this._toastr.error(response.data.message, 'Error');
    }
  }

  async getAssociatedProviders() {
    const { status, data }: any = await lastValueFrom(this._dashboardService.getP2PRelation(null, this.currentUser._id));
    if (status === 200) {
      if (data.assoc_provider_id && Array.isArray(data.assoc_provider_id) && data.assoc_provider_id.length > 0) {
        for (const associatedProvider of data.assoc_provider_id) {
          associatedProvider.full_name = `${associatedProvider.first_name} ${associatedProvider.last_name} ${associatedProvider.title}`;
        }
        this.providers = [this.currentUser, ...data.assoc_provider_id];
      }
      else {
        this.providers = [this.currentUser];
      }
    }
    else {
      this._toastr.error(data.message, 'Error');
    }
  }

  goBack() {
    this._location.back();
  }

  selectDefaultFacility() {
    if (this.facilities.length > 0) {
      // this.facility_id = null;
      this.facilityControl.setValue(this.facilities[0].title);
    }
  }

  handleDateOfServiceChange(event) {
    if (event.startDate && event.endDate) {
      this.filter.last_assessment_date_obj = {
        startDate: null,
        endDate: null
      };
      // const startDate = moment(event.startDate);
      // const endDate = moment(event.endDate);
      // const noOfDays = endDate.diff(startDate, 'days');
      // if (noOfDays > 29) {
      //   this.filter.last_assessment_date_obj = {};
      //   this.filter.last_assessment_date_obj.startDate = moment(event.startDate);
      //   this.filter.last_assessment_date_obj.endDate = moment(this.filter.last_assessment_date_obj.startDate).add(29, "days").endOf('day');
      //   this._toastr.error('Can not select more than 30 days', 'Date Error');
      // }
      // else {
      this.filter.last_assessment_date_obj.startDate = event.startDate;
      this.filter.last_assessment_date_obj.endDate = event.endDate;
      // }
    }
  }
  convertToPDF() {
    if (this.report.length > 0) {
      const reportTitle = this.filter.reportType === 'avg' ? 'Average Days to Remission'
        : 'Median Days to Remission';
      const doc = new jsPDF();
      doc.setFontSize(9);
      for (let i = 0; i < this.report.length; i++) {
        if (this.reportDisplayType === "facility") {
          const title = this.report[i].title;
          doc.text(title, 10, 10);
        }
        else if (this.reportDisplayType === "provider") {
          const title = this.report[i].full_name;
          doc.text(title, 10, 10);
        }
        const height = (doc as any).autoTable.previous.finalY || 0;
        autoTable(doc, { html: `#table${i}`, useCss: true, });
        if (i === this.report.length - 1 && this.fetchedReportType !== "wound_outcomes") {
          autoTable(doc, { html: `#totalTable`, useCss: true, });
        }
        if (i < this.report.length - 1) {
          doc.addPage();
        }
      }
      doc.save(`Wound Outcome Report.pdf`);
    }
    else {
      this._toastr.info('No Data To Export!', "Info");
    }
  }


  async convertToXLSX() {
    if (this.report.length > 0) {
      const infoToastr = this._toastr.info('Fetching Data...', "Please Wait");
      const reportTitle = "Wound Outcome Report";
      const columnsData = [
        { header: "Primary Etiology", key: "_id", width: 30 },
        { header: "Total In Remission", key: "remission", width: 30 },
        { header: "Total Outcomes", key: "outcome", width: 30 },
        {
          header: `${this.fetchedReportType === 'avg' ? 'Average Days To Heal' :
            'Median Days To Heal'}`, key: "daysToRemission", width: 30
        },
        { header: "% In Remission", key: "percentage", width: 30 }
      ];
      const workbook = new ExcelJS.Workbook();
      for (const data of this.report) {
        let worksheetName = reportTitle;
        if (this.reportDisplayType === "facility") {
          worksheetName = data.title;
        }
        else if (this.reportDisplayType === "provider") {
          worksheetName = data.full_name;
        }
        const worksheet = workbook.addWorksheet(worksheetName);
        worksheet.columns = columnsData;
        for (const wound of data.woundOutcomeReport) {
          worksheet.addRow(flatten(wound));
        }
        worksheet.getRow(1).eachCell((cell) => {
          cell.font = { bold: true };
        });
        const total: any = {
          _id: "Total : ",
          daysToRemission: data.daysToRemissionSum,
          outcome: data.outcomeSum,
          percentage: data.percentageSum,
          remission: data.remissionSum
        }
        if (this.reportDisplayType === 'provider') {
          total._id = `${data.full_name} Total : `;
        }
        if (this.reportDisplayType === 'facility') {
          total._id = `${data.title} Total : `;
        }
        worksheet.addRow(total);
        worksheet.getRow(data.woundOutcomeReport.length + 2).eachCell((cell) => {
          cell.font = { bold: true };
        });
        worksheet.eachRow((row) => {
          row.eachCell((cell) => {
            cell.alignment = { wrapText: true };
          })
        });
      }
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${reportTitle}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      this._toastr.clear(infoToastr.toastId);
    }
    else {
      this._toastr.info('No Data To Export!', "Info");
    }
  }

}
