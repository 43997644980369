<div class="flex flex-col justify-center items-center">
    <mat-form-field style="width: 100%;">
        <mat-label>Biller Note</mat-label>
        <textarea matInput style="height: 8vh;" name="comment" [(ngModel)]="charge.note"
            placeholder="description"></textarea>
    </mat-form-field>
    <div class="flex gap-1">
        <button mat-raised-button class="dn-button-primary" (click)="updateCharge();">Add Note</button>
        <button mat-raised-button class="dn-button-secondary" mat-dialog-close>Cancel</button>
    </div>
    <ngx-ui-loader [loaderId]=loaderId></ngx-ui-loader>
</div>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>