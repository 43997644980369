<app-header></app-header>
<main>
  <div class="container flex flex-col">
    <mat-card>
      <mat-card-content>
        <div class="flex flex-row gap-2">
          <button [disabled]="isRecording" mat-raised-button class="dn-button-primary" (click)="startRecording()">
            Start Recording
          </button>
          <button [disabled]="!isRecording" mat-raised-button class="dn-button-primary" (click)="closeSocket()">
            Stop Recording
          </button>
        </div>

        <br />
        
        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label>Transcriptions</mat-label>
          <textarea matInput rows="10" placeholder="Ex. It makes me feel..." [(ngModel)]="transcription"></textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>  
  </div>
</main>

  
  