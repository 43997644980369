import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import * as global from '../includes/global';
import { API_URL } from "../../environments/api-url";
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class WoundEnterpriseReportService {

  constructor(private http: HttpClient) { }

  getWoundEnterpriseReportById(id: any){
    return this.http.post(global.url + API_URL.WOUND_ENTERPRISE_REPORT.getWoundEnterpriseReportById,{data:{id}}).pipe();
  }

  updateWoundEnterpriseReport(filter: any, query: any) {
    return this.http.post(global.url + API_URL.WOUND_ENTERPRISE_REPORT.updateWoundEnterpriseReport, { filter: filter, query: query }).pipe();
  }

  deleteWoundEnterpriseReport(_id: any){
    return this.http.post(global.url + API_URL.WOUND_ENTERPRISE_REPORT.deleteWoundEnterpriseReport,{data: _id}).pipe();
  }

  getWoundEnterpriseReports() {
    return this.http.post(global.url + API_URL.WOUND_ENTERPRISE_REPORT.getWoundEnterpriseReports,{data:{}}).pipe();
  }

  addWoundEnterpriseReport(reportData: any) {
    return this.http.post(global.url + API_URL.WOUND_ENTERPRISE_REPORT.addWoundEnterpriseReport,{data:reportData}).pipe();
  }
}
