<ng-container *ngIf="data.type !== 'detail'">
    <div class="flex flex-col gap-2" style="position: relative;">
        <div class="flex items-center justify-center">
            <h1 mat-dialog-title style="color: black"> {{measureElement.measure_number}} Questions </h1>
        </div>
    
        <div class="flex items-center gap-2">
            <button class="dn-button-primary" mat-raised-button [disabled]="navigationStack.length === 0"
                (click)="goToParentQuestion()" matTooltip="Go Back">
            </button>
            <div class="flex-grow">
                <ng-container *ngIf="navigationStack.length > 0">
                    {{getParentQuestion().question}}
                </ng-container>
            </div>
            <div *ngIf="currentUser.product_owner || currentUser.admin_type == 'system_admin'">
                <button class="dn-button-primary" mat-raised-button (click)="handleAddQuestions()"
                    matTooltip="Add Question">+</button>
            </div>
        </div>
        <table mat-table [dataSource]="dataSource" cdkDropList (cdkDropListDropped)="onListDrop($event)">
    
            <ng-container matColumnDef="question">
                <th class="header-data" mat-header-cell *matHeaderCellDef>
                    Question
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.question }}
                </td>
            </ng-container>
            <ng-container matColumnDef="answer">
                <th class="header-data" mat-header-cell *matHeaderCellDef>
                    Code
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.answer }}
                </td>
            </ng-container>
            <ng-container matColumnDef="condition">
                <th class="header-data" mat-header-cell *matHeaderCellDef>
                    Condition
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.condition }}
                </td>
            </ng-container>
            <ng-container matColumnDef="performance_type">
                <th class="header-data" mat-header-cell *matHeaderCellDef>
                    SubType
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.performance_type }}
                </td>
            </ng-container>
            <ng-container matColumnDef="data_source">
                <th class="header-data" mat-header-cell *matHeaderCellDef>
                    DataSource
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.data_source }}
                </td>
            </ng-container>
    
    
    
            <ng-container matColumnDef="sub-questions">
                <th class="header-data" mat-header-cell *matHeaderCellDef>
                    Sub-Questions
                </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-button (click)="showMeasureNumberSubQuestions(element)">{{ element.sub_questions_count
                        }}</button>
                </td>
            </ng-container>
            <ng-container matColumnDef="order">
                <th class="header-data" mat-header-cell *matHeaderCellDef>
                    Order
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.sortOrder }}
                </td>
            </ng-container>
            <ng-container matColumnDef="operations">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="currentUser.product_owner || currentUser.admin_type == 'system_admin'" class="flex gap-10 justify-center items-center">
                        <i class="icon icon-edit" (click)="editQuestion(element)" matTooltip="Edit"
                            matTooltipPosition="above"></i>
                        <i class="icon icon-delete" (click)="deleteQuestion(element._id)" matTooltip="Delete"
                            matTooltipPosition="above"></i>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row cdkDrag *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    
    </div>
    
</ng-container>
<ng-container>
    <div class="div-chart">
        <canvas #barChart></canvas>
    </div>
</ng-container>

<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>