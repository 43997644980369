import { Component, ChangeDetectorRef, Input, OnInit, Output, EventEmitter, HostListener, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { PatientRoundingSheetCommentsService } from 'src/app/services/patient-roundingsheet-comments.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-census-comment',
  templateUrl: './census-comment.component.html',
  styleUrls: ['./census-comment.component.css']
})
export class CensusCommentComponent implements OnInit {

  constructor(
    private patientRoundingSheetCommentsService: PatientRoundingSheetCommentsService,
    private _authService: AuthService,
    private route: ActivatedRoute,
    private toaster: ToastrService,
    private changeDetectorRef: ChangeDetectorRef,
    private commonService: CommonService,
  ) {
    this.currentUser = this._authService.currentUser;
    this.censusId = this.route.snapshot.params.id
  }
  active: Boolean = false;
  currentUser: any;
  @HostListener("click")
  clicked() {
    this.active = false;
  }
  @HostListener("document:click")
  clickedOut() {
    if (this.active) {
      this.cancelsideNav();
    } else {
      this.active = true;
    }
  }
  @Output() recentComment = new EventEmitter();
  @Output() closeSideNav = new EventEmitter();
  @ViewChild('commentSection') commentSection: ElementRef<HTMLInputElement>
  @Input() patient;
  comment: any = "";
  repliedCommentCount: any;
  file: any;
  censusId: any;
  comments: any;
  repliedComments: any = [];
  updatedCommentCount: any;
  userComment: any;
  replyCommentView: boolean = false;
  isEdit: boolean = false;
  editComment: any;

  ngOnInit(): void {
    if (this.patient
      // && this.patient.census_comment.created_by == this.currentUser._id
    ) {
      console.log("data", this.patient);
      if (this.patient.census_comment === undefined) {
        this.isEdit = false;
      }
      else {
        this.editCensusComment(this.patient.census_comment);
      }
    }
    this.getComments();
  }
  ngAfterViewInit() {
    this.commentSection.nativeElement.focus();
    this.changeDetectorRef.detectChanges();
  }
  getComments() {
    this.patientRoundingSheetCommentsService.getCensusComment(this.patient?._id, this.censusId, this.currentUser.company_id).subscribe((response: any) => {
      // console.log("sjdaklfjl", response.data);
      if (response.status === 200) {
        this.comments = response.data.array;
        // this.repliedCommentCount = response.data.repliedCommentCount
      }
    });
  }
  // getPatientCommentCount(census_id) {
  //   // console.log(census_id)
  //   this.patientRoundingSheetCommentsService.getPatientCommentCount(census_id).subscribe((response: any) => {
  //     if (response.status === 200) {
  //       // console.log("count",response);
  //       this.updatedCommentCount = response.data.array;
  //       this.updatedCount.emit(this.updatedCommentCount);
  //     }
  //   });
  // }
  onSelectFile(e) {
    this.file = e.target.files[0];
  }

  clear() {
    this.file = undefined;
  }

  getRepliedComments(parentCommentId) {
    this.patientRoundingSheetCommentsService.getCensusComment(this.patient?._id, this.censusId, this.currentUser.company_id,parentCommentId).subscribe((response: any) => {
      // console.log("sjdaklfjl",response);
      if (response.status === 200) {
        this.repliedComments = response.data.array;
      }
    });
  }

  addComment() {
    if (this.comment !== "" || this.file !== undefined) {
      let details: any = {};
      if(!this.commonService.validateComment(this.comment)){
        this.toaster.error('No special character allowed', 'Error');
        return
      }
      details.patient_id = this.patient._id;
      details.comment = this.comment;
      details.created_by = this.currentUser._id;
      details.rounding_sheet_id = this.censusId;
      details.company_id = this.currentUser.company_id;
      if (this.replyCommentView && this.userComment) {
        details.parentCommentId = this.userComment._id;
      }
      const sanitizedDetails = this.commonService.sanitizeObjectInput(details, ["patient_id", "created_by", "rounding_sheet_id"]);
      this.patientRoundingSheetCommentsService.addCensusComment(sanitizedDetails).subscribe((response: any) => {
        if (response.status === 200) {
          this.comment = "";
          this.file = undefined;
        }
        if (response.data.parentCommentId) {
          this.repliedComments.push(response.data);
          this.getRepliedComments(response.data.parentCommentId);
          this.getComments();
          this.recentComment.emit(this.censusId);
        }
        else {
          this.getComments();
          this.recentComment.emit(this.censusId);
        }
      });
    }
  }
  deleteCensusComment(commentId, index) {
    // console.log(commentId);
    if(this.editComment._id.toString() == commentId.toString()) {
      this.isEdit = false;
      this.comment = '';
      this.editComment = {};
    }
    this.patientRoundingSheetCommentsService.deleteCensusComment(commentId).subscribe((response: any) => {
      if (response.status === 200) {
        // console.log(response.data);
        this.toaster.success("Comment deleted successfully");
        this.comments.splice(index, 1);
        this.recentComment.emit(this.censusId);
      }
    });
  }

  editCensusComment(comment) {
    this.comment = comment?.comment;
    this.editComment = comment;
    this.isEdit = true;
  }
  updateCensusComment() {
    if(!this.commonService.validateComment(this.comment)){
      this.toaster.error('No special character allowed', 'Error');
      return
    }
    this.editComment.comment = this.comment;
    this.patientRoundingSheetCommentsService.editCensusComment(this.editComment).subscribe((response: any) => {
      if (response.status === 200) {
        // console.log(response.data);
        this.getComments();
        this.comment = "";
        this.isEdit = false;
        this.recentComment.emit(this.censusId);
      }
    });
  }

  replyToComment(userComment) {
    this.comment = "";
    this.isEdit = false;
    this.getRepliedComments(userComment._id);
    this.userComment = userComment;
    this.replyCommentView = true;
    console.log(userComment);
  }

  cancelsideNav() {
    this.closeSideNav.emit(false);
  }
}
