<div *ngIf="tableData" class="mat-elevation-z2 w-full" id="table-container">
  <div class="flex justify-center items-center">
      <h1>{{title}}</h1>
    </div>
    <table mat-table [dataSource]="dataSource" class="custom-table">
  
      <ng-container matColumnDef="header">
        <th mat-header-cell *matHeaderCellDef class="header-cell">Key</th>
        <td mat-cell *matCellDef="let element" class="key-cell">{{ element.header }}</td>
      </ng-container>
  
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef class="header-cell">Description</th>
        <td mat-cell *matCellDef="let element">{{ element.value }}</td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>