import { Component, OnInit, Inject, NgZone, Input } from '@angular/core';
import { CompanyService } from '../services/Modules/company.service';
import { ZoomMtg } from '@zoom/meetingsdk';
import ZoomMtgEmbedded from '@zoom/meetingsdk/embedded';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Clipboard } from '@angular/cdk/clipboard';
import swal from 'sweetalert';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'app-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.css']
})
export class ZoomComponent implements OnInit {

  @Input() patient: any;
  @Input() note: any;
  currentUser: any;

  currentTabFor = '';
  currentMeetingLink = '';

  zoomApiSignature = '';
  canjoinMeeting = false;
  isMeetingJoined = false;

  meetingLink = null;
  meetingPassword = null;
  meetingNumber = null;
  meetingUserName = '';

  doctorZoomMeetingLoaderId = "dr-zoom-loader";
  nurseZoomMeetingLoaderId = "nurse-zoom-loader";

  client = ZoomMtgEmbedded.createClient();

  constructor(
    private companyService: CompanyService,
    public httpClient: HttpClient,
    @Inject(DOCUMENT) document,
    private ngZone: NgZone,
    private router: Router,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService,
    private clipboard: Clipboard,
    private _authService: AuthService,
  ) {
    this.currentUser = this._authService.currentUser;
    this.meetingUserName = this.currentUser.first_name + ' ' + this.currentUser.last_name;
  }

  async ngOnInit() {
  }


  copyToClipboard() {
    this.clipboard.copy(this.currentMeetingLink);
    swal({
      title: "Copied",
      text: 'Link copied to clipboard!',
      dangerMode: true,
      buttons: [, 'Close'],
    })
      .then(() => {
      });
  }

  async ngOnDestroy() {
    if (this.client) {
      await this.client.endMeeting()
        .then(async () => {
          await ZoomMtgEmbedded.destroyClient();
          console.log('Meeting ended successfully');
        })
        .catch(error => {
          console.error('Error ending meeting:', error);
        });
    }
  }


  async createMeeting() {

    try {
      await this.generateMeetingLink();
      this.isMeetingJoined = true;

      let payload = {
        sdkSecret: 'Uo7wBKwo7EpVn72JT9f2xiBnWcO13hHn',
        sdkKey: 'e_g4EtwDTUiNYjfrAjHnXA',
        meetingNumber: this.meetingNumber,
        passWord: this.meetingPassword,
        role: 1,
        userName: this.meetingUserName,
        userEmail: '',
        leaveUrl: '',
      };

      // console.log('payload.............', payload);

      const infoToast = this.toastr.info('Creating Meeting...', 'Please wait', { disableTimeOut: true });
      const result: any = await this.companyService.getSignature(payload).toPromise();

      if (result.signature) {
        this.zoomApiSignature = result.signature;

        let meetingSDKElement = document.getElementById('meetingSDKElement');

        await this.client.init({
          zoomAppRoot: meetingSDKElement,
          webEndpoint: 'zoom.us',
          language: 'en-US',
          patchJsMedia: true,
          customize: {
            video: {
              isResizable: true,
              viewSizes: {
                default: {
                  width: 600,
                  height: 500
                },
                ribbon: {
                  width: 300,
                  height: 200
                }
              }
            }
          }
        }).then(async () => {
          await this.client.join({
            signature: this.zoomApiSignature,
            sdkKey: payload.sdkKey,
            meetingNumber: payload.meetingNumber,
            password: payload.passWord,
            userName: payload.userName,
            userEmail: payload.userEmail
          }).then(() => {

            console.log('joined successfully');
            this.toastr.success('Meeting created successfully', 'Success');

            let getCurrentMeetingInfo = this.client.getCurrentMeetingInfo();
            let inviteEmail = getCurrentMeetingInfo.inviteEmail;
            // console.log('Meeting Info............', inviteEmail)

            const linkRegex = /(https?:\/\/[^\s]+)/;
            const meetingLink = inviteEmail.match(linkRegex)?.[0];

            if (meetingLink) {
              // console.log("Meeting Link:", meetingLink);
              this.currentMeetingLink = meetingLink;
            } else {
              console.log("No meeting link found in the provided string.");
            }

          }).catch((error) => {
            console.log('join error', error);
            this.toastr.clear(infoToast.toastId)
          })
        }).catch((error) => {
          console.log('init error', error);
          this.toastr.clear(infoToast.toastId)
        })

      }
      this.toastr.clear(infoToast.toastId)
    } catch (error) {
      console.error('Error creating Zoom meeting:', error);
    }
  }

  async generateMeetingLink() {

    this.loader.startLoader(this.doctorZoomMeetingLoaderId);
    this.loader.startLoader(this.nurseZoomMeetingLoaderId);

    const details = {
      provider_id: this.currentUser._id,
      census_id: this.note.rounding_sheet_id,
    }

    const result: any = await this.companyService.generateMeetingLink(details).toPromise();

    if (result.meetingLink) {

      this.meetingLink = result.meetingLink.join_url;
      this.meetingPassword = result.meetingLink.password;
      this.meetingNumber = '';

      const url: string = this.meetingLink;
      const pattern: RegExp = /\/([^/]+)\?/;
      const match: RegExpMatchArray | null = url.match(pattern);

      if (match && match.length > 1) {

        const valueBeforeQuestionMark: string = match[1];
        this.meetingNumber = valueBeforeQuestionMark;
        // console.log("Meeting Number :", valueBeforeQuestionMark);

      } else {
        console.log("No match found.");
      }

      this.loader.stopLoader(this.doctorZoomMeetingLoaderId);
      this.loader.stopLoader(this.nurseZoomMeetingLoaderId);
    }

  }

  // joinMeeting() {
  //   window.open(this.meetingLink, '_blank');
  // }

  onTabChange(event: MatTabChangeEvent) {
    console.log('Tab changed:', event.tab.textLabel);
    if (event.tab.textLabel == 'Nurse Meeting') {
      this.currentTabFor = 'nurse';
    } else if (event.tab.textLabel == 'Docotr') {
      this.currentTabFor = 'doctor';
    }

  }


}