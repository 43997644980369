<ng-container>
    <div class=" white-rounded-rect" style="padding: 17px;">
        <div class="flex justify-between">
            <h2 style="color:#000;margin-top:0;margin-bottom:0">Filter</h2>

            <div class="flex flex-row gap-2 filter-controls">
                <button mat-raised-button class="dn-button-primary" (click)="handleApplyFilterClick();">Apply Filter
                </button>

                <button mat-raised-button class="dn-button-secondary" (click)="resetFilter();">Reset Filter
                </button>
                <button mat-raised-button class="dn-button-primary" (click)="downloadReport();">Download Report
                </button>
            </div>
        </div>
        <div class="flex flex-row items-baseline gap-10">
            <div class="flex items-baseline">
                <mat-form-field style="width: 100%;">
                    <mat-label>Select Provider</mat-label>
                    <input type="text" placeholder="Select Provider" aria-label="provider" matInput
                        [formControl]="providerControl" [matAutocomplete]="providerAutoControl">
                    <mat-autocomplete #providerAutoControl="matAutocomplete">
                        <mat-option (click)="onSelectProvider()">--none--</mat-option>
                        <mat-option *ngFor="let provider of filteredProviders | async" [value]="provider.full_name"
                            (click)="onSelectProvider(provider);">
                            {{provider.full_name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <button  style="height:10px;"  *ngIf="getProvControlStatus()" mat-button mat-icon-button matSuffix (click)="clearProviderFilter()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <div class="flex items-baseline">
                <mat-form-field appearance="fill">
                    <div class="flex flex-row items-start justify-center">
                        <img *ngIf="showPccLogo" src="../../../assets/icons/pcc-icon.svg" width="20px" height="20px" alt="">
                    <input type="text" placeholder="Select Facility" aria-label="facility" matInput
                        [formControl]="facilityControl" [matAutocomplete]="facilityAutoControl">
                    <mat-autocomplete #facilityAutoControl="matAutocomplete" autoActiveFirstOption>
                        <mat-option (click)="onSelectFacility()">--All--</mat-option>

                        <mat-option *ngFor="let facility of filteredFacilities | async" [value]="facility.title"
                        (click)="onSelectFacility(facility);">
                        <app-facility-title [facility]="facility"></app-facility-title>
                    </mat-option>
                    </mat-autocomplete>
                    </div>
                </mat-form-field>
                <button  style="height:10px;"  *ngIf="getFacControlStatus()" mat-button mat-icon-button matSuffix (click)="clearFacilityFilter()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <div class="flex items-baseline input-with-cross">
                <mat-label style="margin-right:20px;color:#999">Updation Date</mat-label>

                <input style="width: 180px;" type="text" ngxDaterangepickerMd [(ngModel)]="filter.updated_date"
                    (datesUpdated)="changeServiceDate($event)" class="mat-like-input" [ranges]="preDateRanges"
                    [alwaysShowCalendars]="true" [maxDate]="this.c_date" [linkedCalendars]="true"
                    [showClearButton]="true" />
            </div>
            <div class="justify-start flex flex-row items-baseline">
                <mat-form-field appearance="fill">
                    <mat-label>Created Note</mat-label>
                    <mat-select [(ngModel)]="selectedStatus">
                        <mat-option value="all" (click)="onSelectStatus()">--All--</mat-option>
                        <mat-option value="yess" (click)="onSelectStatus()">Yes</mat-option>
                        <mat-option value="no" (click)="onSelectStatus()">No</mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
        </div>
    </div>
    <div style="margin-top: 10px">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="no">
                <th mat-header-cell *matHeaderCellDef>Wound No#</th>
                <td mat-cell *matCellDef="let element">
                    {{element.woundNo}}
                </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Patient Name</th>
                <td mat-cell *matCellDef="let element">
                    {{getFullPatientName(element.patient_id)}}
                </td>
            </ng-container>
            <ng-container matColumnDef="facility">
                <th mat-header-cell *matHeaderCellDef>Facility</th>
                <td mat-cell *matCellDef="let element">
                    {{element.facility_id?.title}}
                </td>
            </ng-container>
            <ng-container matColumnDef="provider">
                <th mat-header-cell *matHeaderCellDef>Provider Name</th>
                <td mat-cell *matCellDef="let element">
                    {{getFullName(element.created_by)}}
                </td>
            </ng-container>
            <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef>Created At</th>
                <td mat-cell *matCellDef="let element">
                    {{element.createdAt | date: global.date_format}}
                </td>
            </ng-container>
            <ng-container matColumnDef="updated_at">
                <th mat-header-cell *matHeaderCellDef>Updated At</th>
                <td mat-cell *matCellDef="let element">
                    {{element.updatedAt | date: global.date_format}}
                </td>
            </ng-container>
            <ng-container matColumnDef="note_created">
                <th mat-header-cell *matHeaderCellDef>Note Created</th>
                <td mat-cell *matCellDef="let element">
                    {{element.note_status}}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <ngx-ui-loader loaderId="loader-01"></ngx-ui-loader>
    </div>
</ng-container>