<mat-form-field style="margin:20px">
    <mat-label>Payor Type</mat-label>
    <mat-select name="payor" required #payor="ngModel" [(ngModel)]="payerType" (ngModelChange)="loadData(page, payerType)">
        <mat-option *ngFor="let payor of pccPayerTypes" [value]="payor">{{payor}}
        </mat-option>
    </mat-select>
</mat-form-field>
<table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="episodeOfCareId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Episode Of Care Id
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.episodeOfCareId}}
        </td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Name
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.name}}
        </td>
    </ng-container>
    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Type
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.type}}
        </td>
    </ng-container>
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Status
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.status}}
        </td>
    </ng-container>
    <ng-container matColumnDef="model">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Model
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.model}}
        </td>
    </ng-container>
    <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Duration
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.duration}}
        </td>
    </ng-container>
    <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Start Date
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.startDate}}
        </td>
    </ng-container>
    <ng-container matColumnDef="payerName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Payer Name
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.payerName}}
        </td>
    </ng-container>
    <ng-container matColumnDef="payerType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Payer Type
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.payerType}}
        </td>
    </ng-container>
    <ng-container matColumnDef="payerCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Payer Code
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.payerCode}}
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<br />
<div *ngIf="apiData" class="flex flex-row gap-2 items-start justify-center">
    <button mat-raised-button class="dn-primary-button" [disabled]="apiData.paging.page === 1"
        (click)="navigate(-1)">Prev</button>
    <span style="font-size: 12px;">Page {{apiData.paging.page}}</span>
    <button mat-raised-button class="dn-primary-button" [disabled]="!apiData.paging.hasMore"
        (click)="navigate(1)">Next</button>
</div>
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
