<h1 mat-dialog-title>Save Report</h1>
<form>
    <div mat-dialog-content>
        <mat-form-field class="full-width">
            <input matInput placeholder="Folder Title" name="title"  [(ngModel)]="title" required>
        </mat-form-field>
    </div>
    <div mat-dialog-actions class="flex gap-5 justify-between items-center">
        <div>
            <button mat-raised-button class="dn-button-primary" [disabled]="!title" (click)="closeDialog(false);">Save</button>
            <button mat-raised-button class="dn-button-primary" [disabled]="!title" (click)="closeDialog(true);">Save as favorite</button>
        </div>
        <button mat-raised-button class="dn-button-secondary" mat-dialog-close type="reset">Cancel</button>
    </div>
</form>