<div class="flex flex-row justify-end items-start">
    <span class="material-symbols-outlined mat-dialog-close-icon" mat-dialog-close>cancel</span>
</div>
<div class="flex items-center justify-start">
    <h4>Patient Name</h4>
</div>

<div class="flex flex-col justify-start items-center gap-1">
    <span *ngFor="let patient of patients">
        {{patient.last_name}}, {{patient.first_name}}
    </span>
</div>
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>