import { Component, Inject, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { TagsService } from 'src/app/services/tags.service';
import { lastValueFrom } from 'rxjs';
import { CKEditorService } from 'src/app/includes/note-editor/ckeditor.service';
import { PhrasesService } from 'src/app/includes/phrases/phrases.service';
@Component({
  selector: 'app-add-tags-dialogue',
  templateUrl: './add-tags-dialogue.component.html',
  styleUrls: ['./add-tags-dialogue.component.css']
})
export class AddTagsDialogueComponent implements OnInit {
  newTag = {
    name: '',
    color: '#2bc6b9',
    phrase: '',
    phrase_text: '',
    is_deleted: false
  };

  isCloseDialogue = false;
  isButtonDisable = true;
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = ['name', 'color', 'phrase', 'phrase_text'];
  tags = [];
  isShowTable = false;
  phrase: string = '';
  cke4Config;

  constructor(private toastr: ToastrService, private dialogRef: MatDialogRef<AddTagsDialogueComponent>, private tagsService: TagsService, private phrasesService: PhrasesService,
    @Inject(MAT_DIALOG_DATA) public data: AddTagsDialogueComponent, private ckeditorService: CKEditorService) {
      this.cke4Config = {
        ...this.ckeditorService.getDefaultConfig(),
        editable: true
      };
     }

  ngOnInit(): void {
  }

  checkPhraseText(){
    let phrase_text = this.tagsService.extractContentFromHtmlTag(this.newTag.phrase_text);
    if (phrase_text.trim().length === 0)
      return true;
    return false;  
  }

  async handleSubmit(event: any) {
    let res: any, toastrId: any;
    this.checkIsEmpty();
    try {
      if (!this.isCloseDialogue) {
        let isContainSpace = this.checkPhraseText();

        if (isContainSpace)
          this.newTag.phrase_text = '';
        if (!this.isButtonDisable) {
          toastrId = this.toastr.info("Adding New Tag", "Please Wait...", { disableTimeOut: true });
          res = await lastValueFrom(this.tagsService.createTag({ phrase: this.newTag.phrase }, this.newTag));
          if (res.status == 200) {
            this.toastr.clear(toastrId);
              this.toastr.success(res.message, "Success");

            this.dialogRef.close({ data: res });
          } else {
            this.toastr.clear(toastrId.toastId);
            res.data[0].extracted_phrase_text = this.tagsService.extractContentFromHtmlTag(res.data[0].phrase_text);
            this.tags = res.data;
            this.dataSource.data = this.tags;
            this.isShowTable = true;
            this.phrase = res.data[0].phrase;
            this.isButtonDisable = true;
            this.toastr.warning(res.message);
          }
        }
      }
      else
        this.dialogRef.close({ data: res });


    } catch (err) {
      this.toastr.clear(toastrId);
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  closeDialog() {
    this.isCloseDialogue = true;
    this.dialogRef.close();
  }

  checkIsPhraseExist() {
    this.checkIsEmpty();
    if (this.phrase == this.newTag.phrase)
      this.isButtonDisable = true;
    else
      this.isButtonDisable = false;

  }

  checkIsEmpty() {
    if (this.newTag.name.trim().length === 0)
      this.isButtonDisable = true;
    else
      this.isButtonDisable = false;
  }

  generatePhrase() {
    let prefix = '.tag_';
    let suffix = '@dn';
    this.newTag.name = this.newTag.name.trim();
    this.newTag.name = this.newTag.name.replace(/\s+/g, " ");               // remove extra white spaces in between words
    let words = this.newTag.name.toLowerCase().split(' ');
    let phrase = prefix.concat(words.join("_"));
    this.newTag.phrase = phrase.concat(suffix);

    this.checkIsPhraseExist();
  }

  clearDetails() {
    this.newTag = {
      name: '',
      color: '#2bc6b9',
      phrase: '',
      phrase_text: '',
      is_deleted: false
    };
    this.isButtonDisable = true;
    this.isShowTable = false;
  }


}
