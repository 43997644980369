<app-biller-header *ngIf="currentUser.user_type == 6"></app-biller-header>
<app-header *ngIf="currentUser.user_type != 6 && !admin"></app-header>
<div style=" flex-direction: row; box-sizing: border-box; display: inherit !important;"> 

    <div class="container" style="font-size: 13px; line-height: 30px;overflow: auto;"
        [ngClass]="admin? 'admin-side-report':''">
        <div style="display: flex; justify-content: space-between; background: white;padding-right: 12px;" >
            <button mat-raised-button class="dn-button-primary"
                style="margin-left: 10px;color: black;background: none;text-decoration: underline;"
                (click)= "goBack()" >
                Back
            </button>
            <div
                style="display: flex;background-color: white;place-content: center;font-size: 25px;font-weight: bold;padding: 4px;">
                Approved Visit List
            </div>
            <button mat-raised-button class="dn-button-primary"
                style="margin-left: 10px;color: black;background: none;text-decoration: underline;"
                (click)="downloadReport()">
                Download
            </button>

        </div>
        <hr style="border: 2px solid black;margin-top: 0px;margin-bottom: 0px;" />
         
        <div id="chargeCapture" style="width: 100%; background: white">
            <div  style="background-color: white;" *ngFor="let record of records" style="width: 100%;"> 
                <div style="display: flex;  width: 100%;">
                    <div style="width: 100%; display: flex; flex-direction: column;padding-left: 5px;">
                        <div style="font-weight: bold;font-size: 12px; width: 100%;"> {{getpatientInfo(record)}}</div>
                    </div>
                </div>
                <table style="border: 1px solid black;border-collapse: collapse;">
                    <tr style="background-color: #cedbdf; width: 100%;font-size: 12px;">
                        <th style="width: 6%;border: 1px solid #959cb6;border-collapse: collapse;">Visit Date</th>
                        <th style="width: 17%;border: 1px solid #959cb6;border-collapse: collapse;">Provider</th>
                        <th style="width: 5%;border: 1px solid #959cb6;border-collapse: collapse;">CPT's</th>
                        <th style="width: 20%;border: 1px solid #959cb6;border-collapse: collapse;">CPT Description</th>
                        <th style="width: 5%;border: 1px solid #959cb6;border-collapse: collapse;">ICD's</th>
                        <th style="width: 20%;border: 1px solid #959cb6;border-collapse: collapse;">ICD10 Description</th>
                        <th style="width: 20%;border: 1px solid #959cb6;border-collapse: collapse;">Facility</th>
                    </tr>
                    <tr *ngFor="let i of getCodesLength(record)" style="border: 0em; border-color: white;font-size: 11px;">
                        <td style="border: 1px solid #959cb6;border-collapse: collapse; padding-left: 5px;">{{getVisitDate(i, record)}}</td>
                        <td style="border: 1px solid #959cb6;border-collapse: collapse; padding-left: 5px;">{{getProvider(i, record)}} </td>
                        <td style="border: 1px solid #959cb6;border-collapse: collapse; padding-left: 5px;">{{getCPT(i, record)}}</td>
                        <td style="border: 1px solid #959cb6;border-collapse: collapse; padding-left: 5px;">{{getCPTName(i, record)}}</td>
                        <td style="border: 1px solid #959cb6;border-collapse: collapse; padding-left: 5px;">{{getICD(i, record)}}</td>
                        <td style="border: 1px solid #959cb6;border-collapse: collapse; padding-left: 5px;">{{getICDName(i, record)}}</td>
                        <td style="border: 1px solid #959cb6;border-collapse: collapse; padding-left: 5px;">{{getAddress(i, record)}}</td>
                    </tr>
                </table>
            </div>
        </div>

    </div>
</div>