<form class="flex flex-col" (ngSubmit)="submitForm()">

    <div class="flex">
        <mat-form-field appearance="fill" class="dropdown">
            <mat-label></mat-label>
            <mat-select [value]="model.type">
                <mat-option value="allergy">
                    Allergy
                </mat-option>
            </mat-select>
        </mat-form-field> &nbsp;

        <mat-form-field style="width: 100%">
            <mat-label>Allergen</mat-label>
            <input matInput type="text" name="allergen" #allergen="ngModel" [(ngModel)]="model.allergen">
        </mat-form-field>
    </div>

    <mat-form-field style="width: 100%;">
        <input required matInput [matDatepicker]="picker" (focus)="picker.open()" name="pBithdate"
            placeholder="Onset Date" [(ngModel)]="model.onsetDate" (ngModelChange)="onSetDateChange($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker startView="multi-year" [startAt]="!model.onSetDate"></mat-datepicker>
        <mat-error *ngIf="!model.onSetDate">Please enter on set Date </mat-error>
    </mat-form-field>
    <mat-form-field style="width: 100%">
        <mat-label>Reaction Type</mat-label>
        <input matInput type="text" name="reactionType" #reactionType="ngModel" [(ngModel)]="model.reactionType">
    </mat-form-field>


    <div class="flex justify-end items-center gap-7">
        <button mat-raised-button class="dn-button-secondary" mat-dialog-close type="button">Cancel</button>
        <button mat-raised-button type="button" class="dn-button-primary" type="submit">Save</button>
    </div>
</form>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>