import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/services/Modules/company.service';

@Component({
  selector: 'app-company-setting',
  templateUrl: './company-setting.component.html',
  styleUrls: ['./company-setting.component.css']
})
export class CompanySettingComponent implements OnInit {

  @Input('company') company;
  @Output() exit = new EventEmitter<boolean>();
  @Output() updateFacesheetSettinginList = new EventEmitter<boolean>();
  @Output() updateIPAReportSetting = new EventEmitter<boolean>();


  constructor(
    private companyService: CompanyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  getCompanyUploadingMethods(company_type: string) {
    return this.companyService.getCompanyUploadingMethods(company_type);
  }
  updateFacesheetSetting(company_id, value) {
    this.company.should_upload_facesheet = value;
    let data = {
      company_id: company_id,
      value: value
    }
    this.companyService.updateFacesheetSetting(data).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      } else {
        this.toastr.error(response.message, 'Failed');
      }
    });
  }

  updateIPASetting(company_id, value) {
    this.company.should_show_ipa = value;
    this.updateIPAReportSetting.emit(value)
    let data = {
      company_id: company_id,
      value: value
    }
    this.companyService.updateIPASetting(data).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      } else {
        this.toastr.error(response.message, 'Failed');
      }
    });
  }

  updateFacesheetExtactionSetting(company_id, value) {
    this.company.should_extract_facesheet_data = value;
    let data = {
      company_id: company_id,
      value: value
    }
    this.companyService.updateFacesheetExtactionSetting(data).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      } else {
        this.toastr.error(response.message, 'Failed');
      }
    });
  }

  updateNoteUploadingMethodSetting(company_id, value) {
    this.company.note_uploading_method = value;
    let data = {
      company_id: company_id,
      value: value
    }
    this.companyService.updateNoteUploadingMethodSetting(data).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      } else {
        this.toastr.error(response.message, 'Failed');
      }
    });
  }

  goBack() {
    this.exit.emit(false);
    this.updateFacesheetSettinginList.emit(this.company);
  }

}
