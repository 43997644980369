import { Component, OnInit } from '@angular/core';
import {DoctorGeneralDetails} from '../../../classes/doctor_general_details'
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { DoctorProfileService } from 'src/app/services/Modules/doctorProfile.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/Modules/user.service';
@Component({
  selector: 'app-doctor-profile-general-detail',
  templateUrl: './doctor-profile-general-detail.component.html',
  styleUrls: ['./doctor-profile-general-detail.component.css']
})
export class DoctorProfileGeneralDetailComponent implements OnInit {
  model:DoctorGeneralDetails;
  constructor(private doctorProfileService:DoctorProfileService, 
    private loader: NgxUiLoaderService,
    private route: ActivatedRoute,
    private userService:UserService) { 
    this.model = new DoctorGeneralDetails();
  }
  doctorGeneralDetailsLoader = 'doctor-general-details-loader';

  async ngOnInit() {
    const provider_id = this.route.snapshot.paramMap.get('provider_id');

    const filter = {
      _id: provider_id
    }
    const projection = {
      user_type: 1
    }
    const userResponse:any = await this.userService.getUser(filter, projection).toPromise();
    if(userResponse.status === 200) {
      this.initGeneralDetail(userResponse.data);
    }
  }

  initGeneralDetail(user) {
    this.model.user_id_ref = user._id;
    this.model.user_type = user.user_type;
    this.loader.startLoader(this.doctorGeneralDetailsLoader);

    const filter = {
      user_id_ref: user._id
    }
    this.doctorProfileService.getGeneralDetails(filter,{}).subscribe((response:any) => {
      if(response.status === 200 && response.data) {
        this.model = response.data;
      } 
    }, (error) => {
      console.error('Error occured', error.toString());
      
    }, () => {
      this.loader.stopLoader(this.doctorGeneralDetailsLoader);
    })
  }
  handleSubmit(values) {
    this.loader.startLoader(this.doctorGeneralDetailsLoader);
    
    this.doctorProfileService.saveGeneralDetails(this.model).subscribe((response:any) => {
    }, (error) => {
      console.error('Error occured', error.toString());
      
    }, () => {
      this.loader.stopLoader(this.doctorGeneralDetailsLoader);
    })
  }

}
