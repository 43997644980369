<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close (click)="closeDialog()"><mat-icon>cancel</mat-icon></button>
</div>
<div class="flex flex-col items-start gap-2 w-full"
    style="margin: 15px; overflow: hidden;">
    <h2 class="heading-margins">Create Organization</h2>
    <div class="flex flex-col container-data">
        <mat-form-field class="example-full-width">
            <mat-label>Title</mat-label>
            <input matInput type="text" name="title" #title="ngModel" [(ngModel)]="model.title"
                placeholder="Enter title..." required>
            <mat-error *ngIf="title.invalid  && (title.dirty || title.touched)">
                Please enter title
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label>TIN</mat-label>
            <input matInput type="text" name="tin" #tin="ngModel" [(ngModel)]="model.tin" placeholder="Enter TIN..."
                required>
            <mat-error *ngIf="tin.invalid  && (tin.dirty || tin.touched)">
                Please enter TIN
            </mat-error>
        </mat-form-field>
    </div>
    <button mat-raised-button class="dn-button-primary button-margin" (click)="onSaveOrganization()"
            [disabled]="!model.title || !model.tin">
            Submit
    </button>
</div>