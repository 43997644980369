<div class="h100 flex xs:flex-col justify-center items-center">
    <mat-card class="w-2/5 xs:w-full">
  
      <mat-card-title class="flex flex-col justify-center items-center">
        <img src="assets/img/DNlogo-new.png">
        <h2>2-Factor Authentication For Admin</h2>
      </mat-card-title>
  
      <mat-card-content class="flex flex-col">
  
        <form (submit)="onCheckPasscode(loginForm)" #loginForm="ngForm">
  
          <div class="flex flex-col justify-center items-center">
          <mat-error *ngIf="status != 200 && status!= null">Invalid Code</mat-error>
          <div class="passcode-row" *ngIf="!mfaSession">Six digit Authentication code has been sent to your email or phone number</div>
          <div class="passcode-row" *ngIf="mfaSession">Six digit Authentication code has been sent to your email or phone number</div>
              <div class="passcode-row">
              <mat-form-field>
                <input autofocus matInput [type]="hide ? 'password' : 'text'" id="auth-password" name="passcode" placeholder="Enter Passcode" maxlength="6" #password="ngModel" ngModel
                  required [disabled]="display =='00:00'">
                  <mat-icon matSuffix style="cursor: pointer;" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-error *ngIf="password.invalid">Please enter passcode</mat-error>
              </mat-form-field>
            </div>
  
          </div>
  
          <div class="flex justify-center items-center">
            <button class="theme-btn w-full" id= "auth-submit-btn" mat-stroked-button type="submit" [disabled]="display =='00:00'">Authenticate</button>
          </div>
  
        </form>
        <div class="resend-code">
          If you have not received your temporary authentication code 2 minutes, click 
          <button class="resend-code-link" type="button" mat-button (click)="resendAuthCode()" [disabled]="display != '00:00' || disableButton">
              resend
          </button>
          to receive a new code. ({{display}})
        </div>

      </mat-card-content>
  
    </mat-card>
  </div>
  