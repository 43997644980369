<div class="time-picker">
  <div class="time-section">
    <h4>Start Time</h4>
    <div  class="flex flex-row gap-4" style="align-items: center;">
      <mat-form-field appearance="fill">
        <mat-label>Hours</mat-label>
        <mat-select [(ngModel)]="selectedStartHour" (selectionChange)="onStartTimeChange()">
          <mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }}</mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field appearance="fill">
        <mat-label>Minutes</mat-label>
        <mat-select [(ngModel)]="selectedStartMinute" (selectionChange)="onStartTimeChange()">
          <mat-option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  
  </div>

  <div class="time-section">
    <h4>End Time</h4>
    <div  class="flex flex-row gap-4" style="align-items: center;">
      <mat-form-field appearance="fill">
        <mat-label>Hours</mat-label> 
        <mat-select [(ngModel)]="selectedEndHour" (selectionChange)="onEndTimeChange()">
          <mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Minutes</mat-label>
        <mat-select [(ngModel)]="selectedEndMinute" (selectionChange)="onEndTimeChange()">
          <mat-option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</mat-option>
        </mat-select>
      </mat-form-field>
   </div>
  </div>
  
</div>
<ngx-ui-loader loaderId="scheduler-settings-loader"></ngx-ui-loader>
<div>
  <button mat-button class="dn-button-primary" style="margin-left: 2rem;margin-bottom: 1rem;" (click)="updateComapny()">Save</button>
</div>
<mat-card>
  <button mat-button class="dn-button-primary" style="margin-left: 2rem;margin-bottom: 1rem;" (click)="addAppointmentTypeDialog()">Add Appointment Type</button>
  <table mat-table  [dataSource]="dataSource" class="table-row-hover mat-table">

      <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef >Name</th>
          <td mat-cell *matCellDef="let element; let i = index;">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="duration">
          <th mat-header-cell *matHeaderCellDef >Duration</th>
          <td mat-cell *matCellDef="let element; let i = index;">{{ element.duration }}</td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef >Action</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <div class="flex items-center" style="gap: 1rem;">
            <a  style="cursor: pointer" (click)="updateAppointmentType(i, element)">
              <i class="icon icon-edit" matTooltip="Edit" matTooltipPosition="above" id="edit-icon"></i>
            </a>
            <a  style="cursor: pointer;" (click)="deleteAppointmentType(i, element)">
              <i class="icon icon-delete" matTooltip="Delete Tag" matTooltipPosition="above" id="delete-icon"></i>
            </a>
          </div>
        </td>
    </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="mat-row"
          *matRowDef="let row; columns: displayedColumns;" ></tr>
  </table>
  <div class="flex items-center" style="margin: 16px 0;" class="no-appointments"
      *ngIf="dataSource.data.length === 0">
      No appointment types found
  </div>
</mat-card>
<div style="margin: 1rem;">
  <h4>User Business Hour</h4>
  <div>
    <mat-form-field appearance="fill" class="w-1/2"><mat-label>Select Provider</mat-label>
      <mat-select name="provider" #provider="ngModel" [(ngModel)]="formData.providerId"
      (ngModelChange)="onSelectProvider()">
      <input matInput placeholder="Search" style="color:#000;height: 40px;padding-left: 5px;" />
      <mat-option *ngFor="let provider of uniqueProviders"
        value="{{provider.id}}">
        {{provider.name}}
      </mat-option>
    </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="formData.providerId">
    <ngx-ui-loader loaderId="business-loader"></ngx-ui-loader>
    <div *ngFor="let day of ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];let d = index" class="day-circle"  [class.selected]="selectedDays.includes(d)" (click)="toggleDaySelection(d); addWorkingDay(d)">
      {{ day }}
    </div>

    <div *ngFor="let workingDay of workingHours;let i = index">
      <mat-form-field>
        <mat-label>Select Day</mat-label>
        <input matInput  [(ngModel)]="daysOfWeek[workingDay.daysOfWeek[0]]" disabled>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Start Time</mat-label>
        <input matInput type="time" [(ngModel)]="workingDay.startTime">
      </mat-form-field>

      <mat-form-field>
        <mat-label>End Time</mat-label>
        <input matInput type="time" [(ngModel)]="workingDay.endTime">
      </mat-form-field>
      <button mat-button *ngIf="i==0 && workingHours.length > 1" (click)="copyTimesToAll()"> <mat-icon>content_copy</mat-icon>Copy Times to All</button>
    </div>
    <div>
      <button mat-button class="dn-button-primary" (click)="saveWorkingHours()">Save</button>
    </div>
  </div>
</div>