import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PCCPatient } from 'src/app/classes/pccPatient';
import { AuthService } from 'src/app/services/auth.service';
import { GoBackUrlService } from 'src/app/services/goBackUrl.service';
import { PCCService } from 'src/app/services/pcc.service';
import { CensusPatientListService } from '../census-patient-list/census-patient-list.service';
import { PatientImporterDialog } from '../patient-rounding-sheets/patient-importer-dialog/patient-importer-dialog';
import { Location } from '@angular/common';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-active-census-patient-list',
  templateUrl: './active-census-patient-list.component.html',
  styleUrls: ['./active-census-patient-list.component.css']
})
export class ActiveCensusPatientListComponent implements OnInit, AfterViewInit {
  currentUser;

  displayedColumns: string[] = [
    'patient',
    'room_no',
    'admit_date',
    // 'primary_payer',
    'operations',
  ]
  dataSource: MatTableDataSource<any>;

  census: any;
  params: {
    id: string
  }

  groupedPatientsByPayer;

  loaderId = 'active-census-patientlist-loader';
  @ViewChild(MatSort) sort: MatSort;

  selectedPatients = [];
  searchValue:string = '';
  constructor(
    private loader: NgxUiLoaderService,
    private censusPatientListService: CensusPatientListService,
    private toastr: ToastrService,
    private pccService: PCCService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private dialog: MatDialog,
    private goBackUrlService: GoBackUrlService,
    private router: Router,
    private location: Location,
    private changeDetectorRefs: ChangeDetectorRef,
    private commonService: CommonService
  ) { 
    this.params = {
      id: this.route.snapshot.params.id
    };
    this.currentUser = this.authService.currentUser;

  }

  ngAfterViewInit() {
  }
  ngOnInit(): void {
    this.initCensus();
  }

  async initCensus() {

    this.loader.startLoader(this.loaderId);
    let sanitizedParamId = this.commonService.sanitizeInput(this.params.id);
    const censusResponse: any = await this.censusPatientListService.getActiveCensus(sanitizedParamId).toPromise();

    if (censusResponse.status === 200) {
      console.log('init cens', censusResponse);
      const { patientList, ...data } = censusResponse.data;
      this.census = {
        ...data,
        patientList: patientList.map(p => new PCCPatient(p)) as PCCPatient[]
      };

      this.initTableDataSource();
      this.loader.stopLoader(this.loaderId);
      this.groupPatientsByPayer()
    } 
  }

  initTableDataSource() {
    this.dataSource = new MatTableDataSource(this.census.patientList);
    this.dataSource.sort = this.sort;
  }
  showPatientImporterDialog(patient) {
    console.log('showPatientImporterDialog');

    let keyName = 'patient';
    if(Array.isArray(patient)) {
      keyName = 'patients';
    }

    this.dialog.open(PatientImporterDialog, {
      width: '50%',
      data: { [keyName]: patient, action: 'import', only_pcc: true, obj:{filter_facility_id:this.census?.facility._id} },
    });
  }
  importPCCPatient(pccPatientId) {
    let primaryProviderId = this.currentUser._id;


    if (!primaryProviderId) {
      return this.toastr.warning(
        'Please select primary Provider to import patient.',
        'Warning'
      );
    }
    const infoToast = this.toastr.info('Importing Patient', 'Please wait', {
      disableTimeOut: true,
    });
    const facility = this.census.facility;
    const selectedFacilityId = facility._id;
    const pccOrgUuid = facility.pcc_orgUuid;
    let sanitizedSelectedFacilityId = this.commonService.sanitizeInput(selectedFacilityId);
    this.pccService
      .importPCCPatient(pccOrgUuid, pccPatientId, primaryProviderId, sanitizedSelectedFacilityId)
      .subscribe(
        (response: any) => {
          if (response.status === 200) {
            this.toastr.clear(infoToast.toastId)
            // this.toastr.success('Imported Successfully', 'Success');
            const patient = response.data;

            if (this.currentUser.user_type == 1) {
              this.showPatientImporterDialog(patient);
            }
          } else {
            this.toastr.error(
              'Patient could not be imported at this time.',
              'Failed'
            );
          }
        },
        () =>
          this.toastr.error(
            'Patient could not be imported at this time.',
            'Failed'
          ),
        () => this.toastr.clear(infoToast.toastId)
      );
  }
  showPrimaryPayer(coverage_data) {
    if(!coverage_data)  {
      return 'N/A';
    }
    // 
    for (const payer of coverage_data.payers) {
      if(payer.payerRank === 'Primary') {
        return payer.payerName
      }
    }
    
    return 'N/A';
  }
  goBack() {
    const backUrl = this.goBackUrlService.getBackUrl();
    if (backUrl) {
      this.router.navigateByUrl(backUrl);
    } else {
      this.location.back();
    }
  }
  groupPatientsByPayer() {
    this.groupedPatientsByPayer = {};
    if(this.census) {
      for (const pccPatient of this.census.patientList) {
        if(pccPatient.adtRecord) {
          if(!Array.isArray(this.groupedPatientsByPayer[pccPatient.adtRecord.payerName])) {
            this.groupedPatientsByPayer[pccPatient.adtRecord.payerName] = [];
          }
          this.groupedPatientsByPayer[pccPatient.adtRecord.payerName].push(pccPatient);
        }
      }
    }
  }
  sortData(sort: Sort) {
    const data = this.dataSource.data;
    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'patient':
          const patientNameA = `${a.lastName}, ${a.firstName}`
          const patientNameB = `${b.lastName}, ${b.firstName}`
          return compare(patientNameA, patientNameB, isAsc);

          case 'room_no':
            const adtA = a.adtRecord;
            const adtB = b.adtRecord;
            if (adtA && adtB) {
              let roomNoA = adtA.roomDesc;
              let roomNoB = adtB.roomDesc;
              try {
                roomNoA = parseInt(adtA.roomDesc);
                roomNoB = parseInt(adtB.roomDesc);
                if (isNaN(roomNoA)) {
                  return -1;
                } else if (isNaN(roomNoB)) {
                  return 1;
                } else {
                  return compare(roomNoA, roomNoB, isAsc);
                }
              } catch (error) { }
            }
            return -1;

          case 'admit_date': 
            return compare(new Date(a.admissionDate).getTime(), new Date(b.admissionDate).getTime(), isAsc);

          case 'primary_payer':
            const payerA =  a.adtRecord.payerName;
            const payerB = b.adtRecord.payerName;
            return compare(payerA, payerB, isAsc);
      }
    })
  }
  isPayerGroupedPatientsChecked(group) {
    const pccPatients = group.value;
    return pccPatients.every(pccPatient => this.isPatientChecked(pccPatient))
  }
  isPatientChecked(pccPatient) {
    return !!this.selectedPatients.find(_pccPatient => _pccPatient.patientId === pccPatient.patientId);
  }
  handlePayerGroupedPatientsCheck(event, group) {
    const pccPatients = group.value;
    pccPatients.forEach(pccPatient => this.handlePatientCheck(event, pccPatient));
    
    if(event.checked) {
      this.dataSource.data = (this.selectedPatients);
    } else {
      this.dataSource.data = (this.census.patientList);
    }
    this.changeDetectorRefs.detectChanges();
  }
  handlePatientCheck(event, pccPatient) {
    
    if(event.checked) {
      this.selectedPatients = [...this.selectedPatients, pccPatient];
    } else {
      this.selectedPatients = this.selectedPatients.filter(_pccPatient => _pccPatient.patientId !== pccPatient.patientId);
    }
  }
  async importMultiplePatients() {
    const primaryProviderId = this.currentUser._id
   


    const facility = this.census.facility;
    const selectedFacilityId = facility._id;
    const pccOrgUuid = facility.pcc_orgUuid;

    const importedPatients = [];
    const totalSelectedPatients = this.selectedPatients.length;
    for (const pccPatient of this.selectedPatients) {
      const pccPatientId = pccPatient.patientId;
        const infoToast = this.toastr.info(`Importing Patients (${importedPatients.length+1} of ${totalSelectedPatients})`, 'Please wait', {
          disableTimeOut: true,
        });
        let sanitizedPrimaryProviderId = this.commonService.sanitizeInput(primaryProviderId);
        let sanitizedSelectedFacilityId = this.commonService.sanitizeInput(selectedFacilityId);
        const response: any = await this.pccService
          .importPCCPatient(pccOrgUuid, pccPatientId, sanitizedPrimaryProviderId, sanitizedSelectedFacilityId)
          .toPromise()
          .catch((e) => {
            this.toastr.error(
              'Patient could not be imported at this time.',
              'Failed'
            );
          });
          
        if(response.status === 200) {
          importedPatients.push(response.data);
          this.selectedPatients = [];
        } else {
          this.toastr.clear(infoToast.toastId);
          this.toastr.error(
            'Patient could not be imported at this time.',
            'Failed'
          );
        }
        
      
    }
    if(importedPatients.length > 0) {
      this.toastr.success(`${importedPatients.length} patients imported successfully`, 'Success');
      this.showPatientImporterDialog(importedPatients);
    } else {
      this.toastr.error(`No patients imported`, 'Failed');
    }
  }
  

  getPatientRoomNo(patient) {
    if(patient.floorDesc && patient.roomDesc && patient.bedDesc) {
      return `${patient.floorDesc} ${patient.roomDesc} - ${patient.bedDesc}`;
    } else if(patient.floorDesc && patient.roomDesc) {
      return `${patient.floorDesc} ${patient.roomDesc}`;
    } 
    return '';
  }

  applyFilter(filterValue) {
    
    // 
    // const patients = this.dataSource.data;
    // this.dataSource.data = patients.filter(patient => {
    //   return `${ patient.lastName }, ${ patient.firstName }`.toLowerCase().includes(this.searchValue.toLowerCase())
    // });
    
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  if(typeof a === 'string' && typeof b === 'string') {
    return isAsc ? a.localeCompare(b) : b.localeCompare(a);
  }
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
