<div class="patient-dialog-container">
  <div class="patient-field">
    <mat-form-field class="flex-grow">
      <input matInput type="text" name="patient_first_name" placeholder="First Name" [(ngModel)]="patient_first_name"
        required>
    </mat-form-field>
  </div>
  <div class="patient-field">
    <mat-form-field class="flex-grow">
      <input matInput type="text" name="patient_last_name" placeholder="Last Name" [(ngModel)]="patient_last_name"
        required>
    </mat-form-field>
  </div>
  <div class="patient-field">
    <mat-form-field class="flex-grow">
      <input matInput type="date" name="patient_dob" placeholder="Date of birth" [(ngModel)]="patient_dob"
        required>
    </mat-form-field>
  </div>
  <div class="patient-field">
    <mat-form-field class="flex-grow">
    <mat-select name="patient_gender"  placeholder="Gender" [(ngModel)]="patient_gender" required>
      <mat-option *ngFor="let option of genderOptions" [value]="option">{{ option }}</mat-option>
    </mat-select>
  </mat-form-field>
  </div>
  <div class="flex justify-end items-end gap-2">
    <button class="dn-button-primary" mat-raised-button type="button" (click)="submit()">Submit</button>
  </div>
</div>
<div class="top-right-close-btn">
  <button mat-mini-fab mat-dialog-close nofocus>
    <mat-icon>cancel</mat-icon>
  </button>
</div>