import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { AuthService } from "src/app/services/auth.service";
import { MatDialog } from '@angular/material/dialog';
import { WoundCareWidgetService } from "../wound-care-widget.service";
import { ImageEditorComponent } from "../image-editor/image-editor.component";
import * as global from 'src/app/includes/global'

export interface WoundImageUploadDialogData {
  wound: any,
}
@Component({
  selector: 'wound-image-upload-dialog',
  templateUrl: './wound-image-upload-dialog.component.html',
  styleUrls: ['./wound-image-upload-dialog.css']
})
export class WoundImageUploadDialogComponent {
  global = global;
  wound: any;
  image: any;
  status = false;
  selectedImages: any[] = [];
  constructor(
    public dialogRef: MatDialogRef<WoundImageUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WoundImageUploadDialogData,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private woundCareWidgetService: WoundCareWidgetService,
    private dialog: MatDialog,
    private toastr: ToastrService) {
    if (data && data.wound) {
      this.wound = data.wound;
    }
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      for (const file of event.target.files) {
        file.src = this.imagePreviewUrl(file);
        this.selectedImages.push(file);
      }
      // const file = event.target.files[0];
      // this.image = file;
      // console.log(this.image)
    }
  }
  removeImage(image) {
    const index = this.selectedImages.indexOf(image);
    if (index > -1) {
      this.selectedImages.splice(index, 1);
    }
  }

  imagePreviewUrl(image) {
    const src = URL.createObjectURL(image);
    return this.sanitizer.bypassSecurityTrustUrl(src)
  }
  async uploadWoundImages() {
    const infoToast = this.toastr.info('Uploading images...', '', {
      disableTimeOut: true
    });
    let uploadedImages = [];
    for (const image of this.selectedImages) {
      const { status, message, data } = await lastValueFrom(this.woundCareWidgetService.uploadWoundImage(image, this.wound)) as any;
      if (status == 200) {
        uploadedImages.push(data);
        // if(Array.isArray(this.wound.wound_images)){
        //   this.wound.wound_images.push(data);
        // } else {
        //   this.wound.wound_images = [data];
        // }
        // const index = this.selectedImages.indexOf(image);
        // if (index > -1) {
        //   this.selectedImages.splice(index, 1);
        // }
      }
    }

    if (uploadedImages.length === 0) {
      this.toastr.error(`Somthing went wrong while uploading image.`, 'Error');
    } else {
      this.toastr.success(`${uploadedImages.length} Images uploaded successfully.`, 'Success');
    }
    this.dialogRef.close(uploadedImages);
  }
  imageEdit(image,index) {
    const dialogRef = this.dialog.open(ImageEditorComponent, {
      width: 'auto', 
      data: {
        src: image,
        editor: true
      }
      });
      dialogRef.afterClosed().subscribe((result:any)=>{
        if(result.edit){
          this.selectedImages[index] = result.editedImage;
        }
      });
  }
}
