<div class="flex justify-start items-start gap-2" style="overflow: auto; height: 100%;">
    <mat-card style="min-width: 22%; height: 100%;">
        <mat-card-content>
            <div class="col-header"> Procedure Types </div>

            <div class="col-content">
                <div *ngFor="let procedureType of procedureTypes;let i = index">
                    <div class="item flex justify-between items-center"
                        [class.active-item]="activeProcedureType?._id === procedureType?._id">
                        <p *ngIf="!isEditingProcedureType(procedureType, i)"
                            (click)="handleProcedureTypeClick(procedureType, i)">
                            {{procedureType.title}}
                        </p>
                        <ng-container *ngIf="currentUser.admin_type == 'system_admin'">
                            <button class="close-image"
                                (click)="deleteProcedureType(procedureType, i)">
                                <img src="assets\icons\minus.png" />
                            </button>
                        </ng-container>
                    </div>
                </div>

                <div class="item flex justify-between items-center" *ngIf="showNewProcedureTypeInput && currentUser.admin_type == 'system_admin'">
                    <mat-form-field>
                        <input matInput matInputAutofocus type="text" [(ngModel)]="newProcedureTypeInputValue"
                            (keyup)="handleEditProcedureTypeInputValueChange($event)" name="newInputValue" required>
                    </mat-form-field>
                    <button class="save-image" (click)="addNewProcedureType(newProcedureTypeInputValue)">
                        <img src="assets\icons\save-white.png" />
                    </button>
                </div>
            </div>

            <ng-container *ngIf="currentUser.admin_type == 'system_admin'" >
                <button class="add-image" (click)="handleAddNewProcedureType()">
                    <img src="assets\icons\plus.png" />
                </button>
            </ng-container>

        </mat-card-content>
    </mat-card>

    <mat-card style="flex: 1; min-width: 22%; height: 100%;" *ngIf="activeProcedureType">
        <mat-card-content>
            <div class="col-header"> {{activeProcedureType? activeProcedureType.title + '/' : ''}}Category Data </div>
            
            <mat-tab-group #tabGroup (selectedTabChange)="onTabClick($event)" style="height: calc(100% - 47px)"
                [(selectedIndex)]="selectedTabIndex">
                <mat-tab label="Mapping">
                    <ng-container *ngIf="tabIndex == 0">
                        <app-procedure-category-data style="height: 100%;" *ngIf="procedureDatasets"
                            [company_id]="company_id" [activeProcedureType]="activeProcedureType"
                            [procedureDatasets]="procedureDatasets"
                            [companyPermissions]="companyPermissions"></app-procedure-category-data>
                    </ng-container>
                </mat-tab>
                <mat-tab label="Category Templates">
                    <ng-container *ngIf="tabIndex == 1">
                        <app-procedure-category-templates *ngIf="procedureDatasets" [company_id]="company_id"
                            [activeProcedureType]="activeProcedureType" [procedureDatasets]="procedureDatasets"
                            [procedureNotePhrases]="procedureNotePhrases"
                            [companyPermissions]="companyPermissions"></app-procedure-category-templates>
                    </ng-container>
                </mat-tab>
            </mat-tab-group>

        </mat-card-content>

        <ngx-ui-loader [loaderId]="loaderId2"></ngx-ui-loader>
    </mat-card>

    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>