import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MoveWoundService } from './move-wound.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { WoundService } from 'src/app/services/wound.service';

@Component({
  selector: 'app-move-wound',
  templateUrl: './move-wound.component.html',
  styleUrls: ['./move-wound.component.css']
})
export class MoveWoundComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  woundSearchLoaderId = "wound-search-loader-id";
  censusTableColumns: string[] = ["title", "date", "patient_count"];
  patientTableColumns: string[] = ["name", "gender", "dob", "move"];
  wound: any;
  allCensus: any;
  allPatients: any;
  wound_id: any;
  censusTableDataSource: any = new MatTableDataSource([])
  patientTableDataSource: any = new MatTableDataSource([])
  providerId: any;
  facilityId: any;
  showCensusTable: boolean = false;
  showPatientTable: boolean = false;
  showDetails: boolean = false;

  constructor(
    private toastr: ToastrService,
    private moveWoundService: MoveWoundService,
    private loader: NgxUiLoaderService,
    private woundService: WoundService,
  ) { }

  ngOnInit(): void {
  }

  async fetchWound() {

    this.toastr.info('Fetching Wound', 'Wait....');
    this.loader.startLoader(this.woundSearchLoaderId);

    this.showCensusTable = false;
    this.showPatientTable = false;
    this.showDetails = false;

    const getWoundResponse: any = await this.moveWoundService.getWoundById(this.wound_id).toPromise();
    if (getWoundResponse.status == 200) {

      this.wound = getWoundResponse.data;
      this.showDetails = true;
      this.toastr.success('Wound has been fetched.', 'Success');
      this.providerId = this.wound.census_id?.createdBy?._id;
      this.facilityId = this.wound.facility_id?._id;

      if (this.providerId && this.facilityId) {
        this.toastr.info('Fetching Census', 'Wait....');
        await this.moveWoundService.getAllCensus(this.providerId, this.facilityId).subscribe((res: any) => {
          if (res.status == 200) {
            this.allCensus = res.data;
            this.censusTableDataSource = res.data;
            this.censusTableDataSource.sort = this.sort;
            this.showCensusTable = true;
            this.toastr.success('Census has been fetched.', 'Success');
            this.loader.stopLoader(this.woundSearchLoaderId);
          }
        });
      }

    } else {
      this.loader.stopLoader(this.woundSearchLoaderId);
      throw new Error("No Wound found")
    }
  }

  async onRowClick(rowData: any) {

    this.toastr.info('Fetching Patients', 'Wait....');
    this.loader.startLoader(this.woundSearchLoaderId);

    const getPatientsResponce: any = await this.moveWoundService.getAllPatientsByCensusId(rowData._id).toPromise();
    if (getPatientsResponce.status == 200) {

      this.allPatients = getPatientsResponce.data[0];
      this.patientTableDataSource = getPatientsResponce.data[0]?.patientList;
      this.patientTableDataSource.sort = this.sort;
      this.showPatientTable = true;

      this.toastr.success('Patients has been fetched.', 'Success');
      this.loader.stopLoader(this.woundSearchLoaderId);

    } else {
      throw new Error("No Wound found")
    }
  }

  async moveWound(element: any) {

    if (this.wound.parent_wound && this.wound.parent_wound != null && this.wound.parent_wound != undefined) {
      this.toastr.error("This is a Child Wound", "Cannot Move");
      return;
    }
    const currentCensus = this.allCensus.filter(census => census._id == this.allPatients._id);
    const censusDetail = { _id: currentCensus[0]._id, date_obj: currentCensus[0].date_obj };
    const details: any = { census: censusDetail, wound_id: this.wound_id, wound_patient_id: this.wound.patient_id?._id, patient_id: element._id }
    if (Array.isArray(this.wound.child_wounds) && this.wound.child_wounds.length > 0) details.child_wounds = this.wound.child_wounds;
    if (this.wound.parentWound?._id) details.parentWound = this.wound.parentWound;

    this.toastr.info('Moving Wound...', 'Please wait');
    this.loader.startLoader(this.woundSearchLoaderId);

    const moveWoundResponse: any = await this.moveWoundService.moveWoundToPatient(details).toPromise();
    this.loader.stopLoader(this.woundSearchLoaderId);

    if (moveWoundResponse.status == 200) {
      this.showDetails = false;
      this.showCensusTable = false;
      this.showPatientTable = false;
      this.toastr.success('Wound Moved Successfully', 'Success');
      this.loader.stopLoader(this.woundSearchLoaderId);

    } else {
      this.toastr.error("Something Went Wrong", "ERROR");
      this.loader.stopLoader(this.woundSearchLoaderId);
    }
  }

}
