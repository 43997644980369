import {Injectable} from '@angular/core';
import moment from 'moment'
import { BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class SidebarDiagnosisService {
    selectICDs = new BehaviorSubject<any[]>([]);
    public castSelectedICDs = this.selectICDs.asObservable();

    // toggleSelectICDCode(code) {
    //     const selectedICDCodes = this.selectedICDCodes.getValue();
    //     const index = selectedICDCodes.indexOf(code);
    //     if(index === -1) {
    //         this.selectedICDCodes.next([...selectedICDCodes, code]);
    //     } else {
    //         this.selectedICDCodes.next([code]);
    //     }
    // }

    setSelectedICDs(selectedICDCodes) { 
            this.selectICDs.next(selectedICDCodes);
    }
}