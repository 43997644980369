import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AuthService } from "src/app/services/auth.service";
import { OrderService } from "src/app/services/order.service";
import { DialogConfirmationComponent } from "../census-patient-list/dialog-confirmation/dialog-confirmation.component";
@Component({
    selector: "app-order-setting",
    templateUrl: "./order-setting.component.html",
    styleUrls: ["./order-setting.component.css"]
})
export class OrderSettingComponent implements OnInit {
    
    tabs: any[] = []; // Define the structure of tab data
    showIcons: boolean[][] = []; // 2D Array to control visibility of edit and delete icons for each tab
    showInput: boolean[][] = []; // 2D Array to control visibility of editable input for each tab
    showTabIcons: boolean[] = []; // 2D Array to control visibility of edit and delete icons for each tab
    // private _authService: any;
    loaderId = "order-setting-loader";
    constructor(
        private orderService: OrderService,
        private _authService: AuthService,
        private loader: NgxUiLoaderService,
        private _toastr: ToastrService,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.loader.startLoader(this.loaderId);
        this.getAllOrderSet();
    }
    private handleSuccess(response: any): void {
        this._toastr.success(response.message, "Success");
        console.log("Operation successful:", response);
        this.getAllOrderSet();
    }

    private handleError(error: any): void {
        console.error("Error:", error);
    }

    private getDataForRequest(
        orderSetID?: any,
        order?: any,
        updateOrderSet?: any
    ): any {
        return {
            company_id: this._authService.currentUser.company_id,
            orderSetID,
            order,
            updateOrderSet
        };
    }

    addNewOrderSet(order_set: any): void {
        this.loader.startLoader(this.loaderId);
        const data = this.getDataForRequest(undefined, undefined, undefined);
        data.order_set = order_set;

        this.orderService.addNewOrderSet(data).subscribe(
            (response) => this.handleSuccess(response),
            (error) => this.handleError(error)
        );
    }

    addNewOrder(orderSetId: any, order: any): void {
        this.loader.startLoader(this.loaderId);
        const data = this.getDataForRequest(orderSetId, order, undefined);

        this.orderService.addNewOrder(data).subscribe(
            (response) => this.handleSuccess(response),
            (error) => this.handleError(error)
        );
    }

    updateOrderSet(updateOrderSet: any): void {
        this.loader.startLoader(this.loaderId);
        const data = this.getDataForRequest(
            undefined,
            undefined,
            updateOrderSet
        );

        this.orderService.updateOrderSet(data).subscribe(
            (response) => this.handleSuccess(response),
            (error) => this.handleError(error)
        );
    }

    getAllOrderSet(): void {
        const data = this.getDataForRequest();
        this.orderService.getOrders(data).subscribe(
            (response: any) => {
                this.tabs =
                    response.data.length > 0 ? response.data[0].order_set : [];
                this.initializeArrays();
                this.loader.stopLoader(this.loaderId);
                console.log("Successfully retrieved order set:", response);
            },
            (error) => this.handleError(error)
        );
    }

    deleteOrderSet(orderSetID): void {
        this.loader.startLoader(this.loaderId);
        const data = this.getDataForRequest(orderSetID, undefined, undefined);

        this.orderService.deleteOrderSet(data).subscribe(
            (response) => this.handleSuccess(response),
            (error) => this.handleError(error)
        );
    }

    deleteOrder(orderSetID, order): void {
        this.loader.startLoader(this.loaderId);
        const data = this.getDataForRequest(orderSetID, order, undefined);

        this.orderService.deleteOrder(data).subscribe(
            (response) => this.handleSuccess(response),
            (error) => this.handleError(error)
        );
    }
    // Function to create a new tab
    createNewTab(): void {
        const newTab = {
            title: "Enter Tab Name", // Set the default title for the new tab
            order: [], // Initialize with an empty array for checkboxes
            // Add other necessary fields here
            editMode: false
        };
        this.addNewOrderSet(newTab);
        this.tabs.push(newTab);
        this.initializeArrays();
        // });
    }
    // Function to add a new checkbox to a specific tab
    addCheckbox(tabIndex: number, order_set_id): void {
        const newCheckbox = {
            name: "", // Set the default name for the new checkbox
            // value: false, // Set the default value for the new checkbox
            description: "" // Set the description for the new checkbox
        };
        // Specify the index where you want to insert the new checkbox
        const insertionIndex = this.tabs[tabIndex].order.length;
        if (this.isNameValid(this.tabs[tabIndex].order)) {
            // Use splice to insert the new checkbox at the specified index
            this.tabs[tabIndex].order.splice(insertionIndex, 0, newCheckbox);
            this.initializeArrays();
            this.showInput[tabIndex][insertionIndex] = true;
        }
    }

    // Function to toggle the visibility of edit and delete icons on hover
    toggleIcons(tabIndex: number, checkboxIndex: number, show: boolean): void {
        this.showIcons[tabIndex][checkboxIndex] = show;
    }

    // Function to toggle the visibility of editable input on click
    toggleInput(tabIndex: number, checkboxIndex: number, show: boolean): void {
        this.showInput[tabIndex][checkboxIndex] = show;
    }

    // Function to edit a checkbox
    editCheckbox(tabIndex: number, checkboxIndex: number): void {
        const editedCheckbox = this.tabs[tabIndex].order[checkboxIndex];
        // Add logic to edit the checkbox (if needed)
        console.log(
            "Edit checkbox at Tab Index:",
            tabIndex,
            "Checkbox Index:",
            checkboxIndex,
            "New Value:",
            editedCheckbox
        );
    }

    // Function to delete a checkbox
    deleteCheckbox(
        tabIndex: number,
        checkboxIndex: number,
        order_set_id: any
    ): void {
        // Remove the checkbox at the specified index from the specified tab's order array
        let dialogRef = this.dialog.open(DialogConfirmationComponent, {
            data: {
                message: "Are you sure you want to delete this Order?"
            }
        });
        dialogRef.afterClosed().subscribe(async (result) => {
            if (result === "true") {
                this.deleteOrder(
                    order_set_id,
                    this.tabs[tabIndex].order[checkboxIndex]
                );
                this.tabs[tabIndex].order.splice(checkboxIndex, 1);
                this.initializeArrays();
            }
        }),
            () =>
                this._toastr.error(
                    "Something went wrong removing Order",
                    "Failed"
                );

        // Add logic to delete the checkbox (if needed)
        console.log(
            "Delete checkbox at Tab Index:",
            tabIndex,
            "Checkbox Index:",
            checkboxIndex
        );
    }

    // Helper function to initialize the showIcons and showInput arrays
    private initializeArrays(): void {
        const numberOfTabs = this.tabs.length;
        this.showTabIcons = new Array(numberOfTabs).fill(false);
        // Initialize arrays for each tab
        this.showIcons = new Array(numberOfTabs)
            .fill([])
            .map(() => new Array<boolean>().fill(false));
        this.showInput = new Array(numberOfTabs)
            .fill([])
            .map(() => new Array<boolean>().fill(false));
    }
    saveOrder() {
        console.log("saveOrder", this.tabs);
    }

    // Function to toggle the visibility of edit and delete icons on hover for tabs
    toggleTabIcons(tabIndex: number, show: boolean): void {
        this.showTabIcons[tabIndex] = show; // Use the renamed variable for tab icons
    }

    // Function to toggle the visibility of editable input for tabs on click
    toggleTabInput(tabIndex: number, show: boolean): void {
        this.showInput[tabIndex][0] = show; // Assuming there is always at least one checkbox
    }

    // Function to edit a tab title
    editTabTitle(tabIndex: number): void {
        const editedTab = this.tabs[tabIndex];
        // Add logic to edit the tab (if needed)
        console.log(
            "Edit tab title at Tab Index:",
            tabIndex,
            "New Title:",
            editedTab.title
        );
    }

    // Function to delete a tab
    deleteTab(tabIndex: number, order_set_id): void {
        // Remove the tab at the specified index from the tabs array
        let dialogRef = this.dialog.open(DialogConfirmationComponent, {
            data: {
                message: "Are you sure you want to delete this Order Set?"
            }
        });
        dialogRef.afterClosed().subscribe(async (result) => {
            if (result === "true") {
                this.deleteOrderSet(order_set_id);
                this.tabs.splice(tabIndex, 1);
                this.initializeArrays();
            }
        }),
            () =>
                this._toastr.error(
                    "Something went wrong removing Order Set",
                    "Failed"
                );
        // Add logic to delete the tab (if needed)
        console.log("Delete tab at Tab Index:", tabIndex);
    }
    // Function to toggle the edit mode for a tab
    toggleEditMode(tab: any): void {
        tab.editMode = true;
    }

    // Function to save the edited tab name
    saveTabName(tab: any): void {
        // Add logic to save the tab name (if needed)
        this.updateOrderSet(tab);
        tab.editMode = false;
    }

    // Function to cancel the tab name edit
    cancelEdit(tab: any): void {
        // Add logic to revert changes (if needed)
        tab.editMode = false;
    }

    // Function to save the edited or added description
    saveDescription(
        tabIndex: number,
        checkboxIndex: number,
        order_set_id: any
    ): void {
        // Add logic to save the description (if needed)
        if (this.tabs[tabIndex].order[checkboxIndex].name.trim() !== "") {
            this.addNewOrder(
                order_set_id,
                this.tabs[tabIndex].order[checkboxIndex]
            );
            this.showInput[tabIndex][checkboxIndex] = false;
        }
    }
    isNameValid(checkboxes: { name: string; description: string }[]): boolean {
        return checkboxes.every((checkbox) => checkbox.name.trim() !== "");
    }
}
