<div class="wrapper flex flex-col">

  <div class="flex">
    <p style="margin-left:10px;white-space: nowrap;">{{updatedCount >= 0 ? updatedCount : updateTotalNotesCount?totalNotesCount:transcriptionFiles.length}} File(s)</p>
   <div class="export-button-container" >
    <button mat-raised-button class="dn-button-primary" style="height: 2.5rem; margin-top: 10px;"
      *ngIf="multiSelectedTranscriptions.length > 0" [matMenuTriggerFor]="downloadNoteBtn">
      Export Notes
    </button>
   </div>
    <mat-menu #downloadNoteBtn="matMenu">
      <button mat-menu-item (click)="exportNote('download')">Download</button>
      <button mat-menu-item (click)="exportNote('send_to_email')">Send to Email</button>
    </mat-menu>

  </div>
  <app-transcription-list 
    [transcriptions]="transcriptionFiles" 
    [excludeInfo]="['patient']" 
    [isVisibleControls]="false"
    [isVisibleSelectAll]="true"  
    [forPatientDetailList]="true" 
    [patient]="patient"
    (toggleTranscriptionMultiSelect)="toggleTranscriptionMultiSelect($event)"
    (initDictationsAndTranscriptions)="reloadData($event)"
    (totalFiles)="totalFilesCount($event)"
    (updateCount) = "updateCount($event)"
    [multiSelectedTranscriptions]="multiSelectedTranscriptions"
    [isTelehealthReport]="isTelehealthReport"
    >
  </app-transcription-list>
</div>
<ngx-loading [show]="loading"></ngx-loading>