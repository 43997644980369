import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { IcdCodesService } from 'src/app/services/Modules/icd-codes.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { DialogueManageIcdCodesComponent } from './dialogue-manage-icd-codes/dialogue-manage-icd-codes.component';
import { DialogueAddIcdCodeComponent } from './dialogue-add-icd-code/dialogue-add-icd-code.component';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/Modules/user.service';
import { lastValueFrom } from 'rxjs';
import { AddIcdToProviderComponent } from './add-icd-to-provider/add-icd-to-provider.component';
import { ElasticSeachService } from 'src/app/services/elasticSearch.service';

@Component({
  selector: 'app-manage-icd-codes',
  templateUrl: './manage-icd-codes.component.html',
  styleUrls: ['./manage-icd-codes.component.css']
})
export class ManageIcdCodesComponent implements OnInit {

  dataSource: any = new MatTableDataSource([]);
  icds = [];
  enableEditIndex = null;
  valueToBeSearched: string = "";
  displayedColumns: string[] = ['Code', 'Description', 'Medium Description', 'Status', 'Operation'];
  previousMediumDescription: String = "";
  timeoutId: any;
  isIcdLoaded = false;
  currentUser: any;
  company_id: any;
  searchCompanyText: any;
  companies: any;
  selectedCompany: any;
  providerDropdownSettings: any;
  providers: any = [];
  selectedProvider: any = {};
  selectedIcds: any = [];
  selectAll: boolean;
  searchText: String;
  selectedProviderDetails: any;
  @Input() isHide;
  selectedFilter = 'All';

  constructor(private _ICDcodesService: IcdCodesService, private toastr: ToastrService, private dialog: MatDialog, private elasticSearchService: ElasticSeachService,
    private companyService: CompanyService, private _authService: AuthService, private route: ActivatedRoute, private userService: UserService) {
    this.providerDropdownSettings = {

      singleSelection: false,
      idField: '_id',
      textField: 'full_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: false,
    };
    this.currentUser = this._authService.currentUser;
    this.selectAll = false;
  }

  ngOnInit() {
    window.location.pathname.includes('company') ? this.company_id = this.route.parent.snapshot.params.id : this.company_id = ''
    // if (window.location.pathname.includes('company')) {
    //   this.initAssociatedCompanies();
    // }
    this.displayAllCodes();

    this.selectedProvider = this.currentUser._id;
  }

  async initAssociatedCompanies() {
    let query = {
      adminId: this.currentUser._id,
      parentCompanyId: this.company_id
    }
    this.companyService.getAllAssosiatedCompaniesWithCount(query).subscribe(async (response: any) => {
      if (response.status == 200) {
        this.companies = response.data;
        this.selectedCompany = this.companies[0]._id
      }
      else
        this.toastr.error(response.message, "ERROR");

    })
  }

  async onCompanyChange($event) {
    this.selectedCompany = $event.value;
    this.valueToBeSearched = "";
  }

  addToProvider() {
    if (this.selectedIcds.length) {
      let obj = {
        icd_codes: JSON.parse(JSON.stringify(this.selectedIcds)),
        company_id: this.selectedCompany
      }
      const dialogRef = this.dialog.open(AddIcdToProviderComponent, {
        width: '800px',
        height: '50vh',
        data: obj,
      });
      dialogRef.afterClosed().subscribe(result => {
        this.icds.forEach(icd => icd.select = false);
      })
    } else
      this.toastr.info('Please select atleast one icd', 'Info')
    this.selectedIcds = [];
  }

  onChangeAll($event) {
    const isChecked = $event.checked;
    if (isChecked) {
      this.selectedIcds = [];
      this.icds.forEach(icd => {
        this.selectedIcds.push(icd);
        icd.select = true;
      });
    } else {
      this.icds.forEach(icd => {
        this.selectedIcds = [];
        icd.select = false;
      });
    }
  }

  onChange(icd, $event) {
    if ($event.checked) {
      this.selectedIcds.push(icd);
    } else if (!$event.checked) {
      const index = this.selectedIcds.indexOf(icd)
      if (index > -1) {
        this.selectedIcds.splice(index, 1);
      }
    }
    const id = icd._id;
    const isChecked = $event.checked;

    this.icds.map((icd) => {
      if (id === icd._id) {
        this.selectAll = false;
        icd.select = isChecked;
        return icd;
      }
      return icd;
    });
  }

  async displayProviderAssociatedIcds() {
    // let filter = { provider_ids_ref: this.selectedProvider };
    let filter: any;
    if (window.location.pathname.includes('company')) {           // for admin side
      filter['company_id'] = this.selectedCompany;
    }
    else {
      filter = { provider_ids_ref: this.currentUser._id, company_id: this.currentUser.company_id };     //for user side
    }

    let res: any = await lastValueFrom(this._ICDcodesService.fetchProviderAssociatedIcds(filter, {}, false));
    if (res.status === 200) {
      if (res.data) {
        this.icds = res.data;
        this.dataSource.data = this.icds;
      }
      else
        this.icds = [];
    }
    else if (res.status === 500)
      this.toastr.warning(res.message)
  }

  setValueToBeSearched(filterValue: any) {
    this.valueToBeSearched = filterValue.trim();

  }

  async searchValue(page = 1) {
    clearTimeout(this.timeoutId);
    this.isIcdLoaded = true;
    this.timeoutId = setTimeout(() => {
      this.displayAllCodes(page, this.valueToBeSearched);

    }, 2000);
    this.isIcdLoaded = false;
  }

  updateIcd(index: any, data: any) {
    if (window.location.pathname.includes('company'))
      data['company_id'] = this.selectedCompany;
    else
      data['company_id'] = this.currentUser.company_id;

    data['provider_ids_ref'] = this.selectedProvider;
    data['is_deleted'] = false;
    this.enableEditIndex = index;
    this.dataSource.data[index].shouldUpdate = true;
    const dialogRef = this.dialog.open(DialogueManageIcdCodesComponent, {
      height: '600px',
      width: '700px',
      data: data
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.dataSource.data = [];
        this.icds[index] = data;
        this.dataSource.data = this.icds;
      }
    });
    this.enableEditIndex = index;
    this.dataSource.data[index].shouldUpdate = false;
  }

  async displayAllCodes(page = 1, search = "") {
    let res: any = await lastValueFrom(this.elasticSearchService.searchICDCodewithPagination(search, (page + 1) * 10, 0, false));
    if (res.status === 200) {
      this.icds = res.data;
      this.dataSource.data = this.icds;
    }
    else
      this.toastr.warning(res.message)

  }

  addIcd() {
    const dialogRef = this.dialog.open(DialogueAddIcdCodeComponent, {
      height: '600px',
      width: '700px',
    });
    dialogRef.afterClosed().subscribe();

  }

  onChangeFilter($event) {
    this.selectedFilter = $event.value.trim();
    if (this.selectedFilter === 'All') {
      this.dataSource.filter = '';
    } else {
      this.dataSource.filter = this.selectedFilter;
    }
  }

}