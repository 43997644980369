<div class="flex gap-2 note-auditory-history">
    <mat-list class="flex-grow">
        <ng-container *ngFor="let audit of audits">
            <mat-list-item class="note-auditory-history-item">
                <div  mat-line >
                    <div class="flex items-start justify-between gap-2">
                        <div class="flex">
                        <i class="icon icon-view mr-2" 
                        matTooltip="View" matTooltipPosition="above" 
                        (click)="showViewNoteAuditDialog(audit)"></i>
                        
                        <p class="flex items-start justify-center note-auditory-history-m-0" style="margin: unset;">
                            <ng-container *ngIf="isOwner(audit.user_id)">
                                {{audit.user_id.first_name}} {{audit.user_id.last_name}}
                            </ng-container>

                            <ng-container *ngIf="!isOwner(audit.user_id)">
                                <span *ngIf="isProvider()">
                                    {{audit.user_id.first_name}} {{audit.user_id.last_name}}
                                </span>
                                <span *ngIf="!isProvider() && audit.user_id">
                                    {{audit.user_id.first_name}} {{audit.user_id.last_name}}
                                </span>
                            </ng-container>
                            <mat-icon style="color: #ddd;">keyboard_arrow_right</mat-icon>
                            <span>
                                {{formatOperation(audit.operation, audit.source)}}
                            </span>
                        </p>
                        </div>
                        <div class="flex">
                            <p class="note-auditory-history-date note-auditory-history-mb-0" style="margin: unset;">
                                {{getAuditDate(audit)}}
                            </p>
                        </div>
                    </div>

                </div>
            </mat-list-item>
            <mat-divider></mat-divider>
        </ng-container>
        
        <mat-list-item class="note-auditory-history-item" *ngIf="note.hasAudio()">
            <div  mat-line >
                <div class="flex items-start justify-center gap-2">

                    <p class="flex items-start justify-center">
                        Dictation created on: {{applyDateTimezone(note.audio.filterUploadingDate) | date: global.date_time_format}}
                    </p>
                    
                </div>

            </div>
        </mat-list-item>
    </mat-list>
</div>