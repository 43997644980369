import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { Observable, lastValueFrom } from 'rxjs';
import * as global from '../../../includes/global';
import { AuthService } from 'src/app/services/auth.service';
import { FacilityService } from 'src/app/services/facility.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { PatientListService } from '../../patient-list/patient-list-service';
import { LosReportService } from './los-report.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Location } from '@angular/common';
import { SnfWcNurseAssociationService } from '../../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { NursingService } from '../../nursing-module/nursing.service';
import { WoundNurseRelationService } from 'src/app/services/Modules/woundNurse.service';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';

@Component({
  selector: 'app-los-report',
  templateUrl: './los-report.component.html',
  styleUrls: ['./los-report.component.css']
})
export class LosReportComponent implements OnInit {

  currentUser: any;
  companyID: '';
  companies = [];
  facilities = [];
  providers = [];
  selectedCompany: any;
  selectedFacility: any;
  selectedProvider: any;
  companyName = "";
  filter = {
    company_id: '',
    facility_id: '',
    provider_id: '',
    // status : 'submit',
    reportDateFilter: {
      startDate: moment().subtract(7, 'days'),
      endDate: moment(),
    }
  }
  projection = {};
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  displayedColumns = ['patient'
    , 'facility', 'ipa', 'admit', 'discharge', 'los', 'readmit'
  ]
  dataSource: any;
  reportData = [];
  loaderId = 'losreport';
  selected = 'none';
  searchText: any;
  isSnfNurse: boolean = false;
  isNurse: boolean = false;
  isWoundNurse: boolean = false;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private facilityService: FacilityService,
    private losReportService: LosReportService,
    private _location: Location,
    private _patientListService: PatientListService,
    public loader: NgxUiLoaderService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
    private _nurseService: NursingService,
    private woundNurseService: WoundNurseRelationService


  ) {
    this.currentUser = this.authService.currentUser;
    this.companyID = this.route.parent.snapshot.params.id || this.currentUser.company_id
    if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      this.isSnfNurse = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.NURSE) {
      this.isNurse = true;
    }
  }

  async ngOnInit() {
    // this.loader.startLoader('losReport');
    if (this.currentUser.product_owner) {
      let res: any = await this.companyService.getAllCompanies().toPromise();
      if (res.status == 200) {
        this.companies = res.data
        this.filter.company_id = this.companies[0]._id;
        // this.model.company_id = this.companies[0]._id;
        this.selectedCompany = this.companies[0];
      }
    }
    else {
      let res: any = await this.companyService.getCompanyName(this.companyID).toPromise();
      if (res.status == 200) {
        this.companyName = res.data.name;
        this.selectedCompany = res.data;
        this.filter.company_id = this.companyID;
        this.companies.push(res.data)
      }
    }
    this.loadData();
  }

  async loadData() {
    if (this.isSnfNurse) {
      const filter = {
        associated_snf_wc_nurse_ids: this.currentUser._id,
      };
      const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      const facilityProjection = {
        title: 1,
        pcc_facId: 1,
        pcc_2_legged_authentication: 1,
        source: 1
      };
      const response: any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
      if (response.status === 200 && response.data.length > 0) {
        this.facilities = response.data
        this.selectedFacility = this.facilities[0];
        this.filter.facility_id = this.selectedFacility._id;
        this.applyFacilityChange({ value: this.filter.facility_id })
      }
    }
    else if(this.isNurse) {
      const response: any = await lastValueFrom(this._nurseService.getAssociatedFacilities());
      if (response.status === 200 && response.data.array.length > 0) {
        this.facilities = response.data.array
        this.selectedFacility = this.facilities[0];
        this.filter.facility_id = this.selectedFacility._id;
        this.applyFacilityChange({ value: this.filter.facility_id })
      }
    } else if(this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
      const filter = {
        company_id_ref: this.companyID,
    };
    const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
    };
    const facilityProjection = {
        title: 1,
        pcc_facId: 1,
        pcc_2_legged_authentication: 1,
        source: 1
    };
    const facilitiesResponse : any = await lastValueFrom(this.woundNurseService.getWoundNurseFacilities(filter, nurseProjection, facilityProjection));
    if (facilitiesResponse.status === 200) {
        this.facilities = facilitiesResponse.data;
        this.selectedFacility = this.facilities[0];
        this.filter.facility_id = this.selectedFacility._id;
        this.applyFacilityChange({ value: this.filter.facility_id })
    }
    }
    else {
      this.projection = {
        'title': 1,
        'address': 1,
        'city': 1,
        'state': 1,
        'zip_code': 1,
        'is_active': 1,
        'source': 1,
        'provider_ids_ref': 1
      }

      let filter = {
        assoc_company_ids: this.filter.company_id,
        is_active: true
      }

      let providerPopulation: facilityPopulationInterface = {
        collection: 'users',
        field: ["provider_ids_ref"] ,
        filter: { $expr: { $and: [{ $eq: ["true", '$is_activated'] }, { $eq: [true, { $in: ['$_id', '$$provider_ids_ref'] }] }] } },
        project: {
          first_name: 1, last_name: 1, title: 1, recentLogin: 1, trueRCMID: 1,
          full_name: { $concat: ["$first_name", "$last_name", ", ", "$title"] }
        },
      };

      let facilitiesResponse: any = await this.facilityService.getFacilities(filter, this.projection, [providerPopulation]).toPromise();
      if (facilitiesResponse.status == 200) {
        this.facilities = facilitiesResponse.data.array
        this.selectedFacility = this.facilities[0];
        // this.getFacilityPatients(this.selectedFacility._id);
        this.filter.facility_id = this.selectedFacility._id;
        this.applyFacilityChange({ value: this.filter.facility_id })
        // this.applyFilter();
  
      }
    }
    
  }

  async getFacilityPatients(id) {
    this.loader.startLoader(this.loaderId);
    // console.log(id)
    var details = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <any>null,
      primary_doctor_id: <string>null,
      index: <string>null,
      all: <Boolean>true
    };
    details.id = this.currentUser._id;
    details.auth_key = this.currentUser.auth_key;
    details.usertype = this.currentUser.user_type;
    details.index = null;
    details.all = true;

    // this.loader.startLoader('loader-033');
    // const company_id = this.params.id;

    const response: any = await this._patientListService.getPatientsByFacilityId_v2(details, null, { facility: id, getNotes: true }, null).toPromise();
    if (response.status === 200) {


      var patients = response.data.array;
      this.dataSource = new MatTableDataSource(patients);
      this.loader.stopLoader(this.loaderId);
      // console.log("facility Patients", patients)
      // this.loader.stopLoader('loader-033');
      // this.toastr.success('Patients Fetched from DB Successfully.', 'Success');

      // console.log('all patients', this.pccPatients, this.patients);

    }

  }

  getFullName(pro) {
    return pro.first_name + " " +pro.last_name + ", " + pro.title;
  }

  getDays(element) {
    if ((element.last_admit_date || element.last_admit_date_string) && (element.last_discharge_date || element.last_discharge_date_string)) {
      let date1: any = new Date(element.last_admit_date) || new Date(element.last_admit_date_string);
      let date2: any = new Date(element.last_discharge_date || new Date(element.last_discharge_date_string));
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      element.days = diffDays;
      return element.days

    }
    else {
      element.days = "";
      return "";
    }

  }

  getAdmitFormatedDate(element) {
    if (element.last_admit_date || element.last_admit_date_string) {

      const today = new Date(element.last_admit_date) || new Date(element.last_admit_date_string);
      const yyyy = today.getFullYear();
      let mm: any = today.getMonth() + 1; // Months start at 0!
      let dd: any = today.getDate();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      const formattedToday = mm  + '/' + dd +  '/' + yyyy;
      element.admitDate = formattedToday;
      return formattedToday;
    }
    else {
      element.admitDate = "";
      return "";
    }
  }

  getDischargeFormatedDate(element) {
    if (element.last_discharge_date || element.last_discharge_date_string) {

      const today = new Date(element.last_discharge_date) || new Date(element.last_discharge_date_string);
      const yyyy = today.getFullYear();
      let mm: any = today.getMonth() + 1; // Months start at 0!
      let dd: any = today.getDate();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      const formattedToday =  mm + '/'+ dd + '/' + yyyy;
      element.dischargeDate = formattedToday;
      return formattedToday;
    }
    else {
      element.dischargeDate = "";
      return ""
    }
  }


  applyProviderChange(event) {
    this.filter.provider_id = event.value;
    this.applyFilter();
  }

  applyFacilityChange(event) {
    this.filter.facility_id = event.value;
    if (event.value) {
      this.selectedFacility = this.facilities.map(fac => {
        if (fac._id == this.filter.facility_id) {
          return fac;
        }
      }).filter(a => a)
      this.selectedFacility = this.selectedFacility[0];
      this.getFacilityPatients(this.selectedFacility._id);
      // this.providers = this.selectedFacility.provider_ids_ref;
      // this.selectedProvider = this.providers[0];
      // this.filter.provider_id = this.selectedProvider._id;
    }
    // this.applyFilter()
  }

  applyDateFilterReport(dates) {
    this.filter.reportDateFilter = { ...dates }
    this.applyFilter();
  }

  applyCompanyFilter(event) {
    this.filter.company_id = event.value;
    let com = this.companies.map(c => {
      if (c._id == this.filter.company_id) {
        return c;
      }
    }).filter(a => a)
    this.selectedCompany = com[0];
    this.filter.company_id = this.selectedCompany._id
    // console.log("this.selectedCompany----------- ", this.selectedCompany);
    this.loadData()
  }

  async applyFilter() {
    // console.log("Apply filter called------: ", this.filter);
    // let res:any = await this.chargesReportService.getChargesReport(this.filter).toPromise();
    // if(res.status == 200){
    //   this.reportData = res.reportData;
    //   this.dataSource = new MatTableDataSource(this.reportData)
    // }
  }

  downloadLosReport() {
    if(this.dataSource.filteredData.length > 0){
      this.losReportService.downloadLosReport(this.dataSource.filteredData, this.selectedFacility.title).subscribe((data: any) => {
        if (data instanceof HttpResponse) {
          // console.log("in httpresponse")
          var url = window.URL.createObjectURL(data.body);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = 'LOS-Report';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
        }
        else {
          // console.log("in else")
          if (data.type === 0) {
          } else if (data.type === 3) {
            const { loaded, total } = data;
            const progress = Math.round(loaded / total * 100);
            if (progress > 0) {
            }
          }
        }
        if (data.status == 200) {
          // console.log("in status")
          return true;
        }
      })
    }
    
  }

  searchProvider(filterValue: string) {
    let filter = filterValue.trim().toLowerCase();
    if (filter) {
      let filterVal = [];
      this.reportData.forEach(report => {
        if (report[0].provider.first_name.trim().toLowerCase().includes(filter) || (report[0].provider.last_name.trim().toLowerCase().includes(filter))) {
          filterVal.push(report)
          this.dataSource = new MatTableDataSource(filterVal);
          // this.dataSource = filterVal;
        }
      });
    }
    else {
      this.dataSource = new MatTableDataSource(this.reportData);

    }
  }

  getProviderName(element) {
    if (element[0]?.provider.title) {
      return `${element[0].provider.first_name} ${element[0].provider.last_name},${element[0].provider.title}`
    }
    else if (element[0].provider) {
      return `${element[0].provider.first_name} ${element[0].provider.last_name}`
    }
    else {
      return ""
    }
  }

  getSubmitted(element) {
    if (element[0]._id.status == 'submit') {
      return element[0].total;
    }
    else if (element.length > 1 && element[1]._id.status == 'submit') {
      return element[1].total;
    }
    else {
      return 0;
    }
  }

  getDrafted(element) {
    if (element[0]._id.status == 'draft') {
      return element[0].total;
    }
    else if (element.length > 1 && element[1]._id.status == 'draft') {
      return element[1].total;
    }
    else {
      return 0;
    }
  }

  goBack() {
    this._location.back();
  }


}
