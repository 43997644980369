import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  activatedRoute:string = 'doctor-list'
  addUser:Boolean = false;
  constructor(private _router:Router, private _route:ActivatedRoute, private _locatoion:Location) { 
    this.activatedRoute = this._route.snapshot.firstChild.routeConfig.path;
  }

  ngOnInit() {
  }

  changeRoute() {
    this.addUser = false
    this._router.navigate([`${this.activatedRoute}`], { relativeTo: this._route })
  }
  addUserClicked(){
    this.addUser = true;
  }
  goBack(){
    // this._locatoion.back();
    if(!this.addUser){
      this._locatoion.back();
    }
    else{
      this.addUser = false;
    }
  }
  cancelClicked(){ 
    this.addUser = false;
  }
}
