import { Component, Input, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { PatientMedicalHistoryService } from '../../adminside-patients/admin-patient-medical-history/patient-medical-history.service';
import * as global from '../../../includes/global'
import { AuthService } from 'src/app/services/auth.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { ToastrService } from 'ngx-toastr';

export interface PatientMedicalHistoryDataset {
    owner_id: string;
    company_id: string;
    is_deleted: boolean;
    header: string;
    rows: {
      data_type: string,
      value: string,
    }[];
}
export interface PatientMedicalHistory {
  patient_id: string;
  selected_data: {
    group_id: string;
    items: string[]
  }[]
}
@Component({
  selector: 'app-patient-medical-history',
  templateUrl: './patient-medical-history.component.html',
  styleUrls: ['./patient-medical-history.component.css']
})
export class PatientMedicalHistoryComponent implements OnInit {
  @Input() patient:any;
  global = global;
  currentUser : any = {};
  patientMedicalHistory: PatientMedicalHistory;
  patientMedicalHistoryDataset: PatientMedicalHistoryDataset;
  pmhDataSet = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private _patientMedicalHistoryService:PatientMedicalHistoryService,
    private authService : AuthService,
    private toaster: ToastrService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
      this.initPatientMedicalHistorySelectedData();
      this.initPatientMedicalHistoryDataset();
      // this.extractMultiselectCustomValues();
  }

  async initPatientMedicalHistorySelectedData() {
    const {data, status} = await lastValueFrom(this._patientMedicalHistoryService.getPatientMedicalHistory(this.patient._id)) as any;
    if (status == 200) {
      if(data) {
        this.patientMedicalHistory = data;
      } else {
        this.patientMedicalHistory = {
          patient_id: this.patient._id,
          selected_data: []
        }
      }
    }
  }
  async initPatientMedicalHistoryDataset() {
    const {data, status} = await lastValueFrom(this._patientMedicalHistoryService.getAllDatasetinCompany()) as any;
    if (status == 200) {
      this.patientMedicalHistoryDataset = data;
      this.pmhDataSet = data;
    }
  }

  isSelectedItem(group_id: string, item: string) {
    const group = this.patientMedicalHistory?.selected_data?.find(x => x.group_id == group_id);
    if(group) {
      return group.items.includes(item);
    } else {
      return false;
    }
  }

  canRemove(group_id: string, item: string) {
    const group = this.patientMedicalHistory?.selected_data?.find(x => x.group_id == group_id);
    if(group) {
      if(group.items.includes(item)) {
        this.toaster.info('Cannot delete selected value','Info');
        return true;
      } else {
        return false;
      }
    }
  }

  selectItem(group_id: string, item: string) {
    if(this.currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER) return;
    const group = this.patientMedicalHistory?.selected_data?.find(x => x.group_id == group_id);
    if(group) {
      let index = group.items.indexOf(item);
      if(index === -1) {
        group.items.push(item);
        // group.items = [item];
      } else {
        group.items.splice(index, 1);
      }
    } else {
      this.patientMedicalHistory.selected_data.push({
        group_id: group_id,
        items: [item]
      });
    }
    this.updatePatientMedicalHistory(this.patientMedicalHistory.selected_data);
  }

  async updatePatientMedicalHistory(selected_data) {
    let {data, status} = await lastValueFrom(this._patientMedicalHistoryService.updatePatientMedicalHistory(this.patient._id, selected_data)) as any;
    if (status == 200) {
      console.log('updated');
      this.toaster.success("Updated Successfully", 'SUCCESS');
    }
  }


  async addNewFreeTextField(event: MatChipInputEvent, column) {
    if (event.value.trim().length > 0) {
      let response: any = await lastValueFrom(this._patientMedicalHistoryService.addNewRowinWoundDataSet(column._id, { data_type: "Free Text", value: event.value }));
      if (response.status == 200) {
        this.initPatientMedicalHistoryDataset();
        this.toaster.success("Row Added Successfully", 'SUCCESS');
      }
    }
  }

  async removeFreeTextField(valueRemove, column) {
    let isSelected = this.canRemove(column._id,valueRemove.value);
    if(!isSelected) {
      let response: any = await lastValueFrom(this._patientMedicalHistoryService.removeRowfromWoundDataSet(column._id, valueRemove._id, true));
      if (response.status == 200) {
        this.initPatientMedicalHistoryDataset();
        this.toaster.success("Row Deleted Successfully", 'SUCCESS');
      }
    }
  }

  async extractMultiselectCustomValues(column) {
    let allFilterValues: any = [];
    let data: any = this.pmhDataSet.filter(dataset => dataset._id == column._id)[0];
    if (data && data.free_text) {
      for (let index = 0; index < data.free_text.length; index++) {
        allFilterValues.push(data.free_text[index].value);
      }
    }

    return allFilterValues;
  }

}
