import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from "../../../environments/api-url";
import * as global from '../../includes/global';

@Injectable({
  providedIn: 'root'
})
export class WoundNurseRelationService {

  constructor(private _httpclient: HttpClient) { }

    getWoundNurseProviderRelations(filter: any) {
        return this._httpclient.post(global.url + API_URL.WoundNurse.getWoundNurseProviderRelations, { filter: filter })

    }

    updateWoundNurseProviderRelation(filter: any, data: any, isRemoving?: boolean) {
        return this._httpclient.post(global.url + API_URL.WoundNurse.updateWoundNurseProviderRelation, { filter: filter, associated_provider_ids: data, isRemoving: isRemoving })
    }

    getWoundNurseFacilityAssociations(filter: any, nurseProjection: any, facilityProjection: any, nurseFilter) {
        return this._httpclient.post(global.url + API_URL.WoundNurse.getWoundNurseFacilityAssociations, { filter: filter, nurseProjection: nurseProjection, facilityProjection: facilityProjection, nurseFilter });
    }

    getWoundNurseFacilityRelations(filter, project, pageNo) {
        return this._httpclient.post(global.url + API_URL.WoundNurse.getWoundNurseFacilityRelations, { filter, project, pageNo });
    }

    updateWoundNurseFacilitiesRelation(filter: any, projection: any, company_id, isRemoving?: boolean) {
        return this._httpclient.post(global.url + API_URL.WoundNurse.updateWoundNurseFacilitiesRelation, { filter: filter, projection: projection, company_id_ref : company_id , isRemoving : isRemoving })
    }

    getWoundNurseFacilities(filter: any, nurseProjection: any, facilityProjection: any) {
        return this._httpclient.post(global.url + API_URL.WoundNurse.getWoundNurseFacilities, { filter: filter, nurseProjection: nurseProjection, facilityProjection: facilityProjection });
    }
}
