
<h3>Roles</h3>
<app-input-with-dropdown
[noDropdown]="true" 
placeholder="Search Role"
(inputChange)="applyFilter($event.target.value)">
>
</app-input-with-dropdown>
<br />
<div class="flex flex-col gap-2">
    <div class="row" *ngFor="let role of filteredRoles">
        <mat-checkbox (change)="handleChange($event)" [checked]="isAssigned(role)" [value]="role._id">
            {{role.title}}
        </mat-checkbox>  
    </div>
</div>
    
<div class="top-right-close-btn">
  <button mat-mini-fab mat-dialog-close ><mat-icon >cancel</mat-icon></button>
</div>