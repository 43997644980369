<form #form="ngForm" (submit)="handleSubmit(form)">
    <div class="flex flex-col">

        <mat-form-field class="flex-grow" appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Enter Name" [(ngModel)]="newTag.name" #tag_name="ngModel" name="name"
                (change)="checkIsEmpty(); generatePhrase();" required />
            <mat-error *ngIf="tag_name.invalid">
                Please Enter Name
            </mat-error>
        </mat-form-field>
        <mat-form-field class="flex-grow" appearance="fill">
            <mat-label>Phrase</mat-label>
            <input matInput [(ngModel)]="newTag.phrase" #phrase="ngModel" name="phrase" readonly />
        </mat-form-field>

        <div class="flex items-start justify-start gap-24">
            <mat-label>Color</mat-label>
            <input [(colorPicker)]="newTag.color" [style.background]="newTag.color"
                style="padding: 1rem 1rem;border-radius: 100rem;width: 0rem;height: 1rem;" [(ngModel)]="newTag.color"
                #color="ngModel" name="color" />
        </div>

        <mat-label style="margin-top: 1rem; margin-bottom: 2rem;">Phrase Text</mat-label>
        <div class="editor-block" style="height: 15rem;">
                <ckeditor [(ngModel)]="newTag.phrase_text" name="phraseText" class="h100" 
                    editorUrl="../../../../../assets/ckeditor/ckeditor.js" [readOnly]="!cke4Config.editable"
                    [config]="cke4Config" type="divarea">
                </ckeditor>
       
    </div>

        <div class="flex items-end justify-end gap-4" style="margin-top: 25px;">
            <button type="button" mat-raised-button class="dn-button-secondary" (click)="clearDetails()">
                Clear Details
            </button>
            <button mat-raised-button class="dn-button-secondary" type="button" (click)="closeDialog()">
                Cancel
            </button>
            <button mat-raised-button class="dn-button-primary" type="submit" [disabled]="isButtonDisable">
                Add New Tag
            </button>
        </div>
    </div>
</form>


<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>


<div *ngIf="isShowTable">

    <ng-container>
        <div style="margin-top: 2rem;">
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="color">
                    <th mat-header-cell *matHeaderCellDef>Color</th>
                    <td mat-cell *matCellDef="let element">
                        <input [style.background]="element.color"
                            style="padding: 1rem 1rem;border-radius: 100rem;width: 0rem;height: 1rem;" />
                    </td>
                </ng-container>

                <ng-container matColumnDef="phrase">
                    <th mat-header-cell *matHeaderCellDef>Phrase</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.phrase }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="phrase_text">
                    <th mat-header-cell *matHeaderCellDef>Phrase text</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.extracted_phrase_text }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </ng-container>

</div>