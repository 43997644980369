import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { WoundService } from 'src/app/services/wound.service';
import { PatientRoundingSheetService } from '../../patient-rounding-sheets/patient-rounding-sheet.service';
import * as global from "../../../includes/global"

@Component({
  selector: 'app-note-cpt-history',
  templateUrl: './note-cpt-history.component.html',
  styleUrls: ['./note-cpt-history.component.css']
})
export class NoteCptHistoryComponent implements OnInit {

  dataSource: any = new MatTableDataSource([]);
  loaderId = 'app-note-cpt-history';
  @Input() note: any;
  @Input() showCPTHistory: any;
  @Input() searchCharges = '';
  @Input() selectedCharges: any; 
  @Input() emCodeEditorCpts: any[] = [];
  @Input() fvrtCPTs: any; 
  @Output() toggleFvrtCPTsinHistory = new EventEmitter<any>();
  @Output() toggleCPTsinHistory = new EventEmitter<any>();
  cpts: any = [];
  displayedColumns: string[] = ['checkbox','code','fvrt'];
  selectedCPTCodes : any[] = [];
  searchFilter = '';
  fvrtCPTCodes: any[] = [];
  templateSelectedCpts: any[] = [];
  timeOutId : any;
  currentUser: any;
  global = global;
  constructor(
    private loader: NgxUiLoaderService,
    private _toastr: ToastrService,
    private _patientRoundingSheetService: PatientRoundingSheetService,
    private _woundService: WoundService,
    private _authService: AuthService
  ) {
  }
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if(changes.emCodeEditorCpts != undefined || changes.emCodeEditorCpts != null){
      if(changes.emCodeEditorCpts.previousValue != undefined) {
        this.templateSelectedCpts = changes.emCodeEditorCpts.currentValue;
        if(this.showCPTHistory){
          await this.initCPTHistory();
        }else{
          this.loaderId = 'pp-note-uwh-code';
          await this.initUWH_EMHistroty();
        }
      }
    }
    if(changes.selectedCharges != undefined || changes.selectedCharges != null){
      if(JSON.stringify(this.selectedCPTCodes) != JSON.stringify(changes.selectedCharges.previousValue) && changes.selectedCharges.previousValue != undefined) {
        this.selectedCharges = changes.selectedCharges.currentValue;
        this.selectedCPTCodes = this.selectedCharges;
        if(this.showCPTHistory){
          await this.initCPTHistory();
        }else{
          this.loaderId = 'pp-note-uwh-code';
          await this.initUWH_EMHistroty();
        }
      }
    }
    if(changes.searchCharges != undefined && changes.searchCharges != null){
      if( changes.searchCharges.previousValue !=undefined ){
        this.searchCharges = changes.searchCharges.currentValue;
        this.searchFilter = this.searchCharges;
        if(this.timeOutId){
          clearTimeout(this.timeOutId);
          this.timeOutId = setTimeout(() => {
            this.callFunctionOnCondition(this.searchFilter)
          }, 2000);
        }else{
          this.timeOutId = setTimeout(() => {
            this.callFunctionOnCondition(this.searchFilter)
          }, 2000);
        }
      }
    }
  }
  async ngOnInit(): Promise<void> {
    this.currentUser = this._authService.currentUser;
    if(this.selectedCharges){
      this.selectedCPTCodes = this.selectedCharges;
    }
    if(this.fvrtCPTs){
      this.fvrtCPTCodes = this.fvrtCPTs; 
    }
    if(this.searchCharges){
      this.searchFilter = this.searchCharges;
    }
    if(this.showCPTHistory){
      await this.initCPTHistory();
    }else{
      if(this.emCodeEditorCpts){
        this.templateSelectedCpts = this.emCodeEditorCpts;
      }
      this.loaderId = 'pp-note-uwh-code';
      await this.initUWH_EMHistroty();
    }
  }
  async callFunctionOnCondition(search = ""){
    if(this.showCPTHistory || this.templateSelectedCpts.length > 0){
      await this.filterCPTHistory(search);
    }else{
      this.loaderId = 'pp-note-uwh-code';
      await this.initUWH_EMHistroty(0,search);
    }
  }
  async initUWH_EMHistroty(page = 1, search = "") {
    if(this.templateSelectedCpts.length <= 0){
      this.loader.startLoader(this.loaderId);
    const payload = {
      company_id: this._authService.currentUser.company_id,
      search
    };
    let response: any = await this._woundService.getAllUWHCode(payload).toPromise();
    if (response.status == 200) {
        this.cpts = response.data.array;
      //setting fvrts flag true
      for (let i = 0; i < this.cpts.length; i++) {
        if(this.fvrtCPTCodes.findIndex(charges => charges._id.toString() === this.cpts[i].cpt_id_ref.toString()) > -1){
          this.cpts[i].fvrt = true;
        }else{
          this.cpts[i].fvrt = false;
        }
        if(this.selectedCPTCodes.findIndex(charges => charges._id.toString() === this.cpts[i].cpt_id_ref.toString()) > -1){
          this.cpts[i].selected = true;
        }else{
          this.cpts[i].selected = false;
        }
      }
      const totalCount = response.data.totalCount;
      this.dataSource.data = this.cpts;
      this.loader.stopLoader(this.loaderId);
    }
    }else{
      this.cpts = this.templateSelectedCpts;
      for (let i = 0; i < this.cpts.length; i++) {
        if(this.fvrtCPTCodes.findIndex(charges => charges._id.toString() === this.cpts[i]._id.toString()) > -1){
            this.cpts[i].fvrt = true;
          }else{
            this.cpts[i].fvrt = false;
          }
          if(this.selectedCPTCodes.findIndex(charges => charges._id.toString() === this.cpts[i]._id.toString()) > -1){
            this.cpts[i].selected = true;
          }else{
            this.cpts[i].selected = false;
          }
      }
      this.dataSource.data = this.cpts;
    }
  }
  filterCPTHistory(search = "") {
    if(search == ""){
      this.dataSource.data = this.cpts;
    }else{
      let filterCPTs = this.cpts.filter((cp)=> cp.code.includes(search) || cp.description.toLowerCase().includes(search.toLowerCase()));
      this.dataSource.data = filterCPTs;
    }
  }
  initCPTHistory() {
    this.loader.startLoader(this.loaderId);
    const payload = {
      patientId: this.note.patient._id,
      status: null,
    };
    this._patientRoundingSheetService.getCharges(payload).subscribe((response: any) => {
      if (response.status == 200 && Array.isArray(response.data.array) && response.data.array.length) {
        let uniqueCPTs = [];
        for (const charge of response.data.array) {
          if (charge.cpt_id?.length > 0) {
            for (const cpt of charge.cpt_id) {
              let cptExists = false;
              for (const uniqueCPT of uniqueCPTs) {
                if (uniqueCPT.code === cpt.code) {
                  cptExists = true;
                  break;
                }
              }
              if (!cptExists) {
                if (this.fvrtCPTCodes.findIndex(charges => charges._id.toString() === cpt._id.toString()) > -1) {
                  cpt["fvrt"] = true;
                } else {
                  cpt["fvrt"] = false;
                }
                uniqueCPTs.push(cpt);
              }
            }
          }
        }
        this.cpts = uniqueCPTs;
        this.dataSource.data = this.cpts;
        this.loader.stopLoader(this.loaderId);
      }
      else {
        this.loader.stopLoader(this.loaderId);
      }
    }, err => {
      this.loader.stopLoader(this.loaderId);
      this._toastr.error(err, "ERROR");
    }
    );
  }
  cptsSelected(element) {
    if (element.selected) return true;
    if (this.selectedCPTCodes.findIndex(cpt => cpt.code === element.code) >= 0) {
      element.selected = true;
      return true;
    }
    element.selected = false;
    return false;
  }
  toggleFavouriteCharges(element, action = "add") {
    let data = {};
    if(this.showCPTHistory || this.templateSelectedCpts.length > 0){
      data = {
        cpts: element,
        action
      };
    }else{
      let ele = element;
      ele._id = ele.cpt_id_ref;
      delete ele.cpt_id_ref;
      data = {
        cpts: element,
        action
      }
    }
    if (action == "add") {
      element.fvrt = true;
    } else {
      element.fvrt = false;
    }
    this.toggleFvrtCPTsinHistory.emit(data);
  }
  toggleCPTSelection(element) {
    let ele = element;
    if(!this.showCPTHistory && this.templateSelectedCpts.length <= 0){
      ele._id = ele.cpt_id_ref;
      delete ele.cpt_id_ref;
    }
    this.toggleCPTsinHistory.emit(ele);
    element.selected = !element.selected;
    return false;
  }

  shouldDisableCheckbox() {
    const isSameProvider = this.note.provider._id === this.currentUser._id && this.currentUser.user_type === global.USER_TYPE.DOCTOR;
    const isAssociatedProvider = this.note.provider._id != this.currentUser._id && this.currentUser.user_type === global.USER_TYPE.DOCTOR;
    const isMedicalAssistant = this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT;
    if(isSameProvider || isMedicalAssistant) {
      return false;
    }
    if(isAssociatedProvider) {
      return true;
    }
  }
}
