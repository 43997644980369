

import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { MipsService } from 'src/app/services/Modules/mips.service';
import Chart from 'chart.js/auto'

@Component({
  selector: 'app-my-mips',
  templateUrl: './my-mips.component.html',
  styleUrls: ['./my-mips.component.css']
})
export class MyMipsComponent implements OnInit {
  currentUser: any;
  @Input() prevMipsValue;
  mipsGraphData: any = [];
  canvas: any;
  ctx: any;
  @ViewChild('barChart') barChart: any;
  chart: Chart;

  constructor(
    private _authService: AuthService,
    private mipsService: MipsService
  ) {
    this.currentUser = this._authService.currentUser || localStorage.getItem('currentUser');
  }



  ngOnInit() {
    console.log('prevMipsValue', this.prevMipsValue);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentQuarter = Math.floor(currentMonth / 3) + 1;
    const startMonth = (currentQuarter - 1) * 3;
    const quarters = [];
    for (let quarter = 1; quarter <= currentQuarter; quarter++) {
      const startMonthOfQuarter = (quarter - 1) * 3;
      const endMonthOfQuarter = startMonthOfQuarter + 2;
      if (startMonthOfQuarter > currentMonth) {
        break;
      }
      const startDate = new Date(currentYear, startMonthOfQuarter, 1);
      const endDate = new Date(currentYear, endMonthOfQuarter + 1, 0);
      quarters.push({
        quarter: quarter,
        startDate: startDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }),
        endDate: endDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
      });
    }
    console.log('quarterlyMonths', quarters)
    let selectedQtr = {
    };

    selectedQtr = quarters[this.prevMipsValue];
    console.log("this.currentUser", this.currentUser);

    selectedQtr['providerId'] = this.currentUser._id;

    this.mipsService.getMipsDetails(this.currentUser.company_id, selectedQtr).subscribe((res: any) => {
      console.log(res);
      if (res.status == 200) {
        this.mipsGraphData = res.mipsCount;
        this.createGraph();
      }
    })
    console.log(quarters);

  }

  createGraph() {
    this.canvas = this.barChart.nativeElement;
    this.ctx = this.canvas.getContext('2d');
    if (this.chart) {
      this.chart.destroy();
    }
    const colors: string[] = [];
    for (let data of this.mipsGraphData) {
      const red = Math.floor(Math.random() * 256);
      const green = Math.floor(Math.random() * 256);
      const blue = Math.floor(Math.random() * 256);
      const alpha = Math.random().toFixed(2); // Random alpha value between 0 and 1

      const rgbaColor = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
      colors.push(rgbaColor);
    }
    this.chart = new Chart(this.ctx, {
      type: 'bar',
      data: {
        datasets: [{
          label: 'MIPS Count',
          data: this.mipsGraphData.map(form => form.Count),
          backgroundColor: colors,
          borderColor: 'rgba(0, 0, 0, 1)',
          borderWidth: 1
        },
        ],
        labels: this.mipsGraphData.map(form => `${form.mips_code}`)
      },
    });
  }

}
