<h2>{{woundNameDialogue}} Wound Enterprise Report</h2>
<form #form="ngForm" (submit)="handleSubmit(form)">
    <div class="flex flex-col">
        <mat-form-field class="flex-grow" appearance="fill">
            <mat-label>Title</mat-label>
            <input matInput placeholder="Enter Title" (change)="checkIsEmpty()" 
            [(ngModel)]="newWoundEnterpriseReport.title" #title="ngModel" name="title"
                required />
            <mat-error *ngIf="title.invalid">
                Please Enter Title
            </mat-error>
        </mat-form-field>
        <div class="editor-block" style="margin-top: 1rem;">
            <mat-form-field class="full-width">
                <mat-label>Iframe Source Url</mat-label>
                <textarea rows="4" cols="40" (change)="checkIsEmpty()" [(ngModel)]="newWoundEnterpriseReport.iframe_value" #iframe_value="ngModel" name="iframe_value" matInput></textarea>
              </mat-form-field>
    </div>

        <div class="flex justify-end gap-5" style="margin-top: 25px;">
            <button type="button" mat-raised-button class="dn-button-secondary" (click)="clearDetails()">
                Clear Details
            </button>
            <button mat-raised-button class="dn-button-secondary" type="button" (click)="closeDialog()">
                Cancel
            </button>
            <button mat-raised-button class="dn-button-primary" type="submit" [disabled]="isButtonDisable">
                Save
            </button>
        </div>
    </div>
</form>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>
