<mat-card class="wrapper flex gap-1" style="display: flex; flex-direction: column;">
    <div style="width: 100%; display: flex; padding-top: 8px" *ngIf="company.portal_type === global.PORTAL_TYPE.WC">
        <mat-label style="width: 25%; align-self: center;">
            <ng-container *ngIf="facility?.source === 'PointClickCare'">PCC</ng-container>
            <ng-container *ngIf="facility?.source === 'MatrixCare'">Matrix</ng-container>
            Wound Assessment Report Category:</mat-label>
        <mat-form-field appearance="fill">
            <mat-select (selectionChange)="configuration.wound_assessment_report_category = $event.value;
        onSelectionChange();" [value]="configuration.wound_assessment_report_category">
                <mat-option value="single">Single</mat-option>
                <mat-option value="group">Grouped</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-label style="margin-left: 15px; margin-left: 15px; align-self: center;">Wound Assessment Report Name:</mat-label>
        <mat-form-field style="margin-left: 5px;" class="field-item">
            <input matInput placeholder="Report Name" [(ngModel)]="configuration.wound_assessment_report_name"
                name="report_name" />
        </mat-form-field>
    </div>
    <div style="width: 100%; display: flex; padding-top: 8px" *ngIf="company.portal_type === global.PORTAL_TYPE.WC">
        <mat-label style="width: 25%; align-self: center;">
            <ng-container *ngIf="facility?.source === 'PointClickCare'">PCC</ng-container>
            <ng-container *ngIf="facility?.source === 'MatrixCare'">Matrix</ng-container>
            Progress Note Submission Type:</mat-label>
        <mat-form-field appearance="fill" >
            <mat-select (selectionChange)="configuration.progress_note_submission_type = $event.value;
        onSelectionChange();" [value]="configuration.progress_note_submission_type">
                <mat-option selected value="none">None</mat-option>
                <mat-option selected value="pcc" *ngIf="facility?.source === 'PointClickCare'">Upload To PCC</mat-option>
                <mat-option value="matrix" *ngIf="facility?.source === 'MatrixCare'">Upload To Matrix</mat-option>
                <mat-option value="download">Download</mat-option>
                <mat-option value="email">Send Via Email</mat-option>
            </mat-select>
        </mat-form-field>        
    </div>
    <div style="width: 100%;display: flex;padding-top: 8px" *ngIf="company.portal_type === global.PORTAL_TYPE.WC">
        <mat-label style="width: 25%; align-self: center;">
            <ng-container *ngIf="facility?.source === 'PointClickCare'">PCC</ng-container>
            <ng-container *ngIf="facility?.source === 'MatrixCare'">Matrix</ng-container>
            Wound Assessment Report Submitting Type :</mat-label>
        <mat-form-field appearance="fill" >
            <mat-select (selectionChange)="configuration.wound_assessment_report_submission_type = $event.value;
        onSelectionChange();" [value]="configuration.wound_assessment_report_submission_type">
                <mat-option selected value="none">None</mat-option>
                <mat-option selected value="pcc" *ngIf="facility?.source === 'PointClickCare'">Upload To PCC</mat-option>
                <mat-option value="matrix" *ngIf="facility?.source === 'MatrixCare'">Upload To Matrix</mat-option>
                <mat-option value="download">Download</mat-option>
                <mat-option value="email">Send Via Email</mat-option>
            </mat-select>
        </mat-form-field>

        

        <ng-container *ngIf="facility?.source === 'PointClickCare'">
            <mat-label  style="margin-left: 15px; margin-right: 15px; align-self: center;">PCC Document Category:</mat-label>
            <mat-form-field appearance="fill" >
                <mat-select (selectionChange)="configuration.document_category = $event.value;
            onSelectionChange();" [value]="configuration.document_category">
                <ng-container *ngFor="let category of documentCategories">
                    <mat-option selected value="{{category.id}}">{{category.description}}</mat-option>
                </ng-container>
                </mat-select>
            </mat-form-field>
            <button 
            *ngIf="facility?.source === 'PointClickCare'"
            style="margin-left: 10px;" mat-button (click)="handleRefreshFacility()" 
            [disabled]="refeshingFacility" type="button">
                <mat-icon *ngIf="!refeshingFacility">refresh</mat-icon>
                <mat-spinner *ngIf="refeshingFacility" [diameter]="22">
                </mat-spinner>
            </button> 
        </ng-container>
    </div>
    <div style="width: 100%;display: flex;padding-top: 12px" *ngIf="company.portal_type === global.PORTAL_TYPE.NON_WC">
        <mat-label style="width: 25%; align-self: center;">Matrix Destination Folder to Upload Wound Report: </mat-label>
        <mat-form-field style="margin-left: 5px;" class="field-item" >
            <input matInput placeholder="Destination Folder Name" [(ngModel)]="configuration.matrix_wound_report_destination_folder" name="wound_folder_name"/>
        </mat-form-field>
    </div>
    <div style="width: 100%;display: flex;padding-top: 8px" *ngIf="company.portal_type === global.PORTAL_TYPE.NON_WC">
        <mat-label style="width: 25%; align-self: center;">Matrix Destination Folder to Upload PDF Note: </mat-label>
        <mat-form-field style="margin-left: 5px;" class="field-item" >
            <input matInput placeholder="Destination Folder Name" [(ngModel)]="configuration.matrix_pdf_note_destination_folder" name="pdfNote_folder_name"/>
        </mat-form-field>
    </div>
    <div style="width: 100%;display: flex;padding-top: 8px; justify-content: flex-end;">
        <button style="margin-left: 10px;" mat-raised-button (click)="onSelectionChange()" type="button"
            class="dn-button-primary">
            Save
        </button>
    </div>
    <ngx-ui-loader [loaderId]=loaderId></ngx-ui-loader>
</mat-card>