<form #form="ngForm">
    <div class="flex flex-col">

          <mat-form-field class="flex-grow" appearance="fill">
            <mat-label>Code</mat-label>
            <input matInput disabled placeholder="Enter Code" [(ngModel)]="newICDmodel.code"
                #newicd_code="ngModel" name="code" (change)="checkIsEmpty()" required />
            <mat-error *ngIf="newicd_code.invalid">
                Please Enter Code
            </mat-error>
        </mat-form-field>

        <mat-form-field class="flex-grow" appearance="fill">
            <mat-label>Description</mat-label>
            <input matInput placeholder="Enter Description" [(ngModel)]="newICDmodel.description"
                #newicd_description="ngModel" name="description" (change)="checkIsEmpty()" required />
            <mat-error *ngIf="newicd_description.invalid">
                Please Enter Description
            </mat-error>
        </mat-form-field>

        <mat-form-field class="flex-grow" appearance="fill">
            <mat-label>Medium Description</mat-label>
            <input matInput placeholder="Enter Medium Description" [(ngModel)]="newICDmodel.mediumDescription"
                #newicd_mediumDescription="ngModel" name="mediumDescription" />
            <mat-error *ngIf="newicd_mediumDescription.invalid">
                Please Enter Description
            </mat-error>
        </mat-form-field>

        <div class="flex justify-start items-baseline" style="gap: 200px;">
        
            <mat-form-field>
                <input matInput [matDatepicker]="picker" [min]="this.current_date" placeholder="Choose Expiry Date"
                    [(ngModel)]="expiry_date" #newicd_end_date="ngModel" name="end_date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        
            <mat-checkbox [checked]="newICDmodel.pdpm_status" (change)="setPdpm($event.checked)"> PDPM Status</mat-checkbox>
        </div>

        <div class="flex justify-end gap-5" style="margin-top: 25px;">
            <button type="button" mat-raised-button class="dn-button-secondary" (click)="clearDetails()">
                Clear Details
            </button>
            <button mat-raised-button class="dn-button-secondary" type="button" (click)="closeDialog()">
                Cancel
            </button>
            <button mat-raised-button class="dn-button-primary" type="submit" (click)="save()" [disabled]="isEmptyCodeDescription">
                Save
            </button>
        </div>
    </div>
</form>


<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>