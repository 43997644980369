<table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="insuredPerson">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Insured Person
        </th>
        <td mat-cell *matCellDef="let element">
            {{getInsuredPerson(element)}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="insuranceType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Insurance Type 
        </th>
        <td mat-cell *matCellDef="let element">
            {{getInsuranceType(element)}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="insurancePlan">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Insurance Plan
        </th>
        <td mat-cell *matCellDef="let element">
            {{getInsurancePlan(element)}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="policyNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Policy Number
        </th>
        <td mat-cell *matCellDef="let element">
            {{getPolicyNumber(element)}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="insurancePlanID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Insurance Plan ID
        </th>
        <td mat-cell *matCellDef="let element">
            {{getInsurancePlanID(element)}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="insuranceCompany">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Insurance Company
        </th>
        <td mat-cell *matCellDef="let element">
            {{getInsuranceCompany(element)}}
        </td>
    </ng-container>
    <ng-container matColumnDef="planType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Plan Type
        </th>
        <td mat-cell *matCellDef="let element">
            {{getPlanType(element)}}
        </td>
    </ng-container>
    <ng-container matColumnDef="planEffectiveDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Plan Effective Date
        </th>
        <td mat-cell *matCellDef="let element">
            {{getPlanEffectiveDate(element)}}
        </td>
    </ng-container>
 
    
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>