import { Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MipsService } from 'src/app/services/Modules/mips.service';
import * as _ from 'lodash'
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-mips-questionnaire',
  templateUrl: './mips-questionnaire.component.html',
  styleUrls: ['./mips-questionnaire.component.css'],
})
export class MipsQuestionnaireComponent implements OnInit, OnChanges {

  @Input() measure;
  @Input() draftMips;
  @Output() onAnswerCalculation = new EventEmitter<any>();
  questions: any[] = [];
  otherClicked:any;
  currentQuestionIndex = 0;
  answer;
  questionInput:any;
  questionAnswerMap:any = {};
  currentUser:any;
  codes: any=[];
  reasons:any = [];

  chads_scrore:any;
  mipNumberSix: boolean;

  constructor(
    private authService:AuthService,
    private mipsService: MipsService
    ) {
      this.currentUser = this.authService.currentUser;
   }

  ngOnInit(): void {
    // this.initQuestions();
  }

  async getNegativeCodes(){
    this.codes=this.measure.negative_codes;
    ///  assign negative codes from measure object
    
  }

  ngOnChanges(changes: SimpleChanges) {
    const measureChanges = changes['measure'];
    console.log("measureChanges", this.measure);
    if(!measureChanges) return;
    let curMeasure  = JSON.stringify(measureChanges.currentValue);
    let prevMeasure = JSON.stringify(measureChanges.previousValue);
    if(curMeasure !== prevMeasure) {
      this.chads_scrore=null;
      if(this.measure.quality_number=='326'||this.measure.quality_number==326){
          this.mipNumberSix=true;
          this.questions=[];
          this.reasons=[];
          this.otherClicked=null;
          this.questionInput='';

          if(this.draftMips&&this.draftMips.length&&(this.chads_scrore==''||this.chads_scrore==null)){
              const index = this.draftMips.findIndex((e:any)=>{return this.measure._id?.toString()==e.measure_id?.toString()});
              if(index>=0 && this.draftMips[index].chads_scrore){
                  this.chads_scrore=this.draftMips[index].chads_scrore;
                  this.initQuestions();
                  this.getNegativeCodes();
              }
          }
      }else{
        this.mipNumberSix=false;
        this.initQuestions();
        this.getNegativeCodes()
      }

    }
  }

  scoreChange(){
    this.initQuestions();
    this.getNegativeCodes()
  }

  initQuestions() {
      this.questions=[];
      this.reasons=[];
      this.otherClicked=null;
      this.questionInput='';
      this.mipsService.getQuestions(this.measure.quality_number, null, this.currentUser.company_id).subscribe((response:any) => {
        if(response.status === 200) {
          this.questions = response.data.array;
          this.answer = null;
        }
      })
  }

  handleQuestionInput(){
    this.answer = '';
    this.reasons=this.reasons.filter(r => r._id!=null);
    this.reasons.push({question:this.questionInput, answer:'', _id:null});
    // this.otherClicked.question=this.questionInput;
    this.calculateAnswer(this.otherClicked, true);
  }

  async handleQuestionSelect(data) {
    const {question, goToNext = true} = data;
    this.questionAnswerMap[this.measure.quality_number] = question;
    // console.log("handlee questionnnn selectttt", data, this.questionAnswerMap[this.measure.quality_number]);

    if(question.sub_questions_count > 0) {

      this.reasons.push({question:question.question, answer:question.answer, _id:question._id});
      const subQuestionsResposne:any = await this.mipsService.getQuestions(this.measure.quality_number, question._id, this.currentUser.company_id).toPromise();
      if(subQuestionsResposne.status === 200) {
        this.questions = subQuestionsResposne.data.array;
      }
    } else {
      if(question.question?.trim().endsWith("Other")) {
        this.otherClicked=question;
      }else{
        this.calculateAnswer(question, goToNext);
      }
    }
  }
  notEligible() {
    this.answer = false;
  }
  calculateAnswer(question, goToNext = true) {
    this.answer = question.answer;
    if(this.measure.quality_number == 126||this.measure.quality_number == 181){
      this.reasons=question.reasons;
    }
    // console.log('Data Completeness Met + Denominator Exception',{measure: this.measure, answer: question.answer, question_id: question._id,question:question.question, reasons:this.reasons , chads_scrore:this.chads_scrore,  goToNext})
    this.onAnswerCalculation.emit({measure: this.measure, answer: question.answer, question_id: question._id,question:question.question, reasons:this.reasons , chads_scrore:this.chads_scrore,  goToNext});
  }
  showAnswer() {
    return this.answer
  }

  isNegativeCode(answer) {
    if(answer&&this.codes) {
      for (const code of this.codes) {
        if(answer.trim().toUpperCase().includes(code.trim().toUpperCase())) {
          return true
        }
      }
    } 
    return false;
  }

  isSelectedQuestion(question) {
    const selectedQuestion = this.questionAnswerMap[this.measure.quality_number];
    let draftedStatus=false;
    if(selectedQuestion) {
      draftedStatus = selectedQuestion._id === question._id;
    }


    if(!draftedStatus&&this.draftMips){
      if(this.draftMips&&this.draftMips.length){
          const index = this.draftMips.findIndex((e:any)=>{return this.measure._id?.toString()==e.measure_id?.toString()});
          if(index>=0 ){
            if(question._id==this.draftMips[index].question_id){
              draftedStatus=true;
            }else if(this.draftMips[index].reasons&&this.draftMips[index].reasons.length){
              // console.log("question.answer in draft: ", this.draftMips[index], question);
              // if(question.answer.includes('---')||question.answer.includes('---')){
              //   draftedStatus=true;
              // }
            }
          }
      }
    }
    return draftedStatus;
  }

}
