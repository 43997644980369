import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { FacilityService } from 'src/app/services/facility.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { PCCService } from 'src/app/services/pcc.service';
import { FacilityDeactivationDialogComponent } from './email-template-dialog/facility-deactivation-dialog.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';
import { NgForm } from '@angular/forms';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-admin-pcc-facilities',
  templateUrl: './admin-pcc-facilities.component.html',
  styleUrls: ['./admin-pcc-facilities.component.css'],
})
export class AdminPccFacilitiesComponent implements OnInit {

  displayedColumns: string[] = ['Fac ID', 'Facility-Title', 'address','Company', 'Active','company_assoc_date','Activation Date', 'De-activation Date', 'Activation Actions', 'Actions'];
  dataSource: any = new MatTableDataSource([])
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('searchFacilityByFacIdAndOrgUuidForm') searchFacilityByFacIdAndOrgUuidForm: NgForm;
  selectedActivations: any[] = [];
  currentCompany: any;
  companies: any;
  currentUser: any;
  selectedCompany: any;
  filteredFacilities: any;
  newPage: number;
  pageNo: number;
  activeFilter: string = 'all';
  selectedCompanyId: any = '';
  searchFacilityBy:'title'|'facId_orgUuid' = 'facId_orgUuid';
  loaderId = 'pcc-company-facilities-loader';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private pccService: PCCService,
    private facilityService: FacilityService,
    private toast: ToastrService,
    private companyService: CompanyService,
    private _authService: AuthService,
    private dialog: MatDialog,
    private loader: NgxUiLoaderService,
  ) {
    this.currentUser = this._authService.currentUser;
  }

  paging: any = {};
  activations: any = [];
  facilities: any = [];
  showCompanies: boolean = false;
  selectedFacility: any;

  async ngOnInit() {
    await this.initFacilities();
    this.currentCompany = this.route.snapshot.parent.parent.params.id;
    if (this.currentUser.product_owner) {
      this.getCompanies();
    }
    this.route.queryParams.subscribe(params => {
      this.pageNo = parseInt(params['page']) || 1;
      // this.initActivations(this.pageNo);
    });


  }


  async initFacilities() {
    let companyPopulation: facilityPopulationInterface = {
      collection: 'companies',
      field: ["company_id"], 
      filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: ['$$company_id', '$_id'] }] } },
      project: { name: 1 }, 
    };
    let companyAssocPopulation: facilityPopulationInterface = {
      collection: 'companies',
      field: ["assoc_company_ids"],
      filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: [true, { $in: ['$_id', '$$assoc_company_ids'] }] }] } },
      project: { name: 1 }, 
    };

    const facilitiesResponse: any = await this.facilityService.getFacilities({ pcc_facId: { $exists: true } }, { _id: 1, title: 1, pcc_facId: 1, pcc_orgUuid: 1, source: 1, company_id: 1, assoc_company_ids: 1, company_assoc_date: 1, is_active: 1 }, [companyPopulation, companyAssocPopulation]).toPromise();
    if (facilitiesResponse.status == 200) {
      this.facilities = facilitiesResponse.data.array;
      console.log('this.facilities',this.facilities);
    }
  }
  async initActivations(page) {
    this.loader.startLoader(this.loaderId);
    this.paging.page = page;
    const facilitiesResponse: any = await this.pccService.getPopulatedActivations(page).toPromise()
    if (facilitiesResponse.status == 200) {
      this.activations = facilitiesResponse.data.data;
      this.paging = facilitiesResponse.data.paging;
      this.activations.forEach(this.normalizeActivation.bind(this));
      console.log('this.activations',this.activations);
      this.dataSource.data = this.activations;
      this.dataSource.sort = this.sort;
      this.sortPhrases(this.activations);

    }
    if (facilitiesResponse.status) {
      this.loader.stopLoader(this.loaderId);
    }
  }

  getAssociationDate(facility) {
    if(facility?.company_assoc_date != undefined || facility?.company_assoc_date != null){
          const assocDate = facility.company_assoc_date;
          const [date, time] = assocDate.split(' ')
          let splittedDate = date.split('-');
          let year = splittedDate[0];
          let month = splittedDate[1];
          let day = splittedDate[2];
          let formattedDate = `${month}/${day}/${year}`;
          return formattedDate;
    }else{
      return '';
    }
  }

  async getInitials(companyName) {
    if (companyName.indexOf(' ') >= 0) {
        let mrn = '';
        let matches = companyName.match(/\b(\w)/g);
        let acronym = matches.join('');
        mrn = acronym.toUpperCase();
        return mrn;
      } else {
        let mrn = '';
        mrn = companyName.toUpperCase();
        return mrn;
    }
  }

  applysort($event) {
    this.sortData({ active: $event, direction: 'desc' });
  }

  sortData(sort: Sort) {
    const data = this.activations.slice();
    data.forEach(element => {
      let splittedDate = element.company_assoc_date?.substring(0, 10);
      element.splittedDate = splittedDate;
    });
    this.activations = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'assoc_date':
          return compare(new Date(a.splittedDate || 1).getTime(), new Date(b.splittedDate || 1).getTime(), isAsc);

        case 'activation_date':
          return compare(new Date(a.splittedDate || 1).getTime(), new Date(b.splittedDate || 1).getTime(), isAsc);
        default: return 0;
      }
    });
    this.dataSource = new MatTableDataSource(this.activations);
  }

  getCount() {
    return this.activations.length;
  }

  sortPhrases(arr: any[]) {
    this.dataSource.data = arr.sort((a, b) => a.facilityName.toLowerCase().localeCompare(b.facilityName.toLowerCase()));
  }

  async getCompanies() {
    const response: any = await this.companyService.getAllCompanies().toPromise();
    if (response.status == 200) {
      this.companies = response.data;
    }
    else if (response.status == 403) {
      this.toast.error(response.message, "ERROR");
    }
  }

  isActivationImported(activation) {
    return this.facilities.find(f => f.pcc_facId == activation.facId && f.pcc_orgUuid == activation.orgUuid && f.is_active)
  }

  navigatePrevNext(nextPrev) {
    const page = this.paging.page + nextPrev;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        page
      }
    });
  }
  toggleActivationCheck(activation, checked) {
    if (checked) {
      if (this.currentUser.product_owner) {
        this.showCompanies = true;
        this.selectedFacility = activation;
        if(this.selectedActivations.findIndex(ele => ele.facId === activation.facId) === -1) {
          this.selectedActivations.push(activation);
        }
      }
      else {
        this.selectedActivations.push(activation);
      }
    } else {
      const index = this.selectedActivations.indexOf(activation);
      if (index >= 0) {
        this.selectedActivations.splice(index, 1);
        if (this.selectedActivations.length == 0) {
          this.showCompanies = false;
          this.selectedCompany = undefined;
        }
        this.selectedFacility = undefined;
      }
    }
  }

  onSelectCompany($event, company_id) {
    if ($event.isUserInput) {
      if (this.selectedFacility && company_id !== 'default') {
        // this.selectedActivations.push(this.selectedFacility);
        this.selectedCompany = company_id;
      }
    }
  }
  isActivationChecked(activation) {
    return this.selectedActivations.indexOf(activation) >= 0;
  }
  async importActivationsFacilities() {
    let company_id = null;
    if (this.currentUser.product_owner && this.selectedCompany) {
      company_id = this.selectedCompany;
    }
    else if (this.currentUser.product_owner && this.selectedCompany === undefined) {
      alert("Company not selected");
      return;
    }
    const label = `${this.selectedActivations.length} ${this.selectedActivations.length === 1 ? 'Facility' : 'Facilities'}`;
    const infoToast = this.toast.info(`Importing ${label}`, 'Please wat')
    const pccFacs = this.selectedActivations.map(a => ({ pccFacId: a.facId, pccOrgUuid: a.orgUuid }));

    const response: any = await this.pccService.importActivations(pccFacs, company_id).toPromise()
    if (response.status == 200) {
      await this.initFacilities();
      this.activations.forEach(this.normalizeActivation.bind(this));
      this.facilities = this.facilities.concat(response.data.array);
      this.dataSource.data = this.activations;
      this.toast.success(`${label} imported successfully`, 'Success')
      this.selectedActivations = [];
    }
    this.toast.clear(infoToast.toastId);
  }

  applyFilter(filterValue: string) {
    let filter = filterValue.trim().toLowerCase();
    if (filter) {
      let filterVal = [];
      this.activations.forEach(activation => {
        if (activation.facilityName.trim().toLowerCase().includes(filter)) {
          filterVal.push(activation)
        }
      });
      this.dataSource.data = filterVal;
    } else {
      this.dataSource.data = this.activations;
    }
  }

  applyActiveFilter(filterValue: string) {
    this.dataSource.data = this.activations;
    // if (filterValue === "true") {
    //   this.dataSource.data = this.dataSource.data.filter(activation => this.facilities.find(f => f.pcc_facId == activation.facId && f.pcc_orgUuid == activation.orgUuid) !== undefined);
    // }
    // else if (filterValue === "false") {
    //   this.dataSource.data = this.dataSource.data.filter(activation => this.facilities.find(f => f.pcc_facId == activation.facId && f.pcc_orgUuid == activation.orgUuid) === undefined);
    // }
    if (filterValue === "true") {
        this.dataSource.data = this.dataSource.data.filter(activation => activation.is_active);
      }
      else if (filterValue === "false") {
        this.dataSource.data = this.dataSource.data.filter(activation => !activation.is_active);
      }
  }
  showEmailTemplateDialog(title, facility) {
    let dialogRef = this.dialog.open(FacilityDeactivationDialogComponent, {
      width: '50%',
      height: '90vh',
      panelClass: 'pcc-email-template-dialog-container-class'
    });
    dialogRef.afterClosed().subscribe((facility) => {
      //   if(facility) {
      //   this.dataSource.data.forEach(element => {
      //     if (facility.pcc_facId == element.facId && facility.pcc_orgUuid == element.orgUuid) {
      //       element.is_active = facility.is_active;
      //       facility.deactivation_date && (element.deactivation_date = facility.deactivation_date); 
      //     }
      //   });
      // }
    });
  }

  applyCompanyFilter(company_id) {
    console.log('company_id',company_id);
    this.selectedCompanyId = company_id;
    this.facilityService.getFilteredFacilities(company_id).subscribe(async (res: any) => {
      if (res.status == 200) {
        this.filteredFacilities = res.data;
        await this.getPccFacilities();
        this.activations.forEach(activation => {
          this.filteredFacilities.forEach(f => {
            if (f.pcc_facId == activation.facId && f.pcc_orgUuid == activation.orgUuid) {
              activation.associatedCompanyNames = [];
              f.assoc_company_ids.forEach(async e => {
                let shortCompanyName = await this.getInitials(e.name);
                activation.associatedCompanyNames.push(shortCompanyName)
              });
              activation.is_active = f.is_active;
              f.deactivation_date && (activation.deactivation_date = f.deactivation_date);
            }
          });
        });
        this.activations = this.activations.filter(a => a.is_active)
        console.log('this.activations',this.activations);
        this.dataSource.data = this.activations;
        this.dataSource.sort = this.sort;
        this.sortPhrases(this.activations);
      
      }else {
        console.log('error');
      }
    });
  }

  async getPccFacilities() {
    this.route.queryParams.subscribe(params => {
      this.newPage = parseInt(params['page']) || 1;
    });
    const facilitiesResponse: any = await this.pccService.getPopulatedActivations(this.newPage).toPromise()
    if (facilitiesResponse.status == 200) {
      this.activations = facilitiesResponse.data.data;
      this.paging = facilitiesResponse.data.paging;
    }
  }
  downloadAsXLSX() {
    let filters: any = {};
    if(this.activeFilter == 'true') {
      filters.isActive = true;
      filters.isDeleted = false;
    }else if(this.activeFilter == 'false') {
      filters.isActive = false;
      filters.isDeleted = true;
    }else {
      filters.allFacilities = 'all';
    }
    if(this.selectedCompanyId != '') {
      filters.company_id = this.selectedCompanyId;
    }
    this.pccService.exportPccFacilitiesAsXLSX(filters).subscribe((response: any) => {
      if (response instanceof HttpResponse) {
        // this.toastr.success("Status changed");
        var url = window.URL.createObjectURL(response.body);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `PCC Facilities`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }
    });
  }
  getFacAndOrganizationID(element){
    let value = ''
    if( element && element.organization_id){
      value = element.organization_id
    }
    if(element && element.facId){
      value = value + element.facId
    }
    return value
  }

  normalizeActivation(activation) {
    if(activation.activationDate) {
      const [year, month, day ] = activation.activationDate.split("-");
      activation.activationDate = `${month}-${day}-${year}`;
    }
    
    this.facilities.forEach(f => {
      if (f.pcc_facId == activation.facId && f.pcc_orgUuid == activation.orgUuid) {
        activation.associatedCompanyNames = [];
        f.assoc_company_ids.forEach(async e => {
          let shortCompanyName = await this.getInitials(e.name);
          if(!activation.associatedCompanyNames.includes(shortCompanyName)) {
            activation.associatedCompanyNames.push(shortCompanyName);
          }
        });
        f.company_assoc_date.forEach(element => {
          if(element.company_id == this.currentCompany) {
            activation.company_assoc_date = element.assoc_date;
          }
        });
        activation.is_active = f.is_active;
        activation.organization_id = f.organization_id;
        activation.address = f.address;
        f.deactivation_date && (activation.deactivation_date = f.deactivation_date);
      }
    });
  }

  handleSearchFacilitiesByChange(value) {
    if(value === 'title') {
      this.initActivations(this.pageNo);
    }
  }
  async searchFacilityByFacIdAndOrgUuid() {
    if(this.searchFacilityByFacIdAndOrgUuidForm.invalid) {
      return;
    }
    const facilityId = this.searchFacilityByFacIdAndOrgUuidForm.value.facilityId.trim();
    const orgUUID = this.searchFacilityByFacIdAndOrgUuidForm.value.orgUUID.trim();

    try {
        this.loader.startLoader(this.loaderId);

        const facilityResponse: any = await lastValueFrom(this.pccService.getFacility(facilityId, orgUUID))
        if (facilityResponse.status == 200) {
          this.activations = [facilityResponse.data]
          this.activations.forEach(this.normalizeActivation.bind(this));
          this.dataSource.data = this.activations;
        } else {
          throw new Error("No facility found")
        }
      
    } catch (error) {
      this.activations = []
      this.activations.forEach(this.normalizeActivation.bind(this));
      this.dataSource.data = this.activations;
    } finally {
      this.loader.stopLoader(this.loaderId);

    }
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  if (typeof a === 'string' && typeof b === 'string') {
    return isAsc ? a.localeCompare(b) : b.localeCompare(a);
  }
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}