<ul style="font-size: 12px;">
  <li *ngFor="let question of questions; let index = index">
    <div class="flex gap-2 justify-between items-center">
      <div class="flex-grow">{{ question.question }}</div>
      <div class="flex flex-row gap-2" *ngIf="showControls(question)">
        <button
          mat-raised-button
          class="dn-button-primary"
          (click)="handleYesClick(question)"
        >
          Yes
        </button>
        <button
          mat-raised-button
          class="dn-button-secondary"
          (click)="handleNoClick(question)"
        >
          No
        </button>
      </div>
    </div>
    <app-mips-questionaire-item
      *ngIf="question.subQuestions"
      [questions]="question.subQuestions"
      (onYesClick)="handleYesClick($event)"
      (onNoClick)="handleNoClick($event)"
    ></app-mips-questionaire-item>
  </li>
</ul>