<div class="container flex flex-col gap-2">
    <div>
        <button class="dn-button-secondary"
        mat-raised-button routerLink="../">Go Back
        </button>
    </div>
    <div class="flex flex-grow justify-start items-start gap-2" id="container-dataset"
    style="overflow: auto;height: 94%;">
        <mat-card style="min-width: 22%;height: 99%;" *ngFor="let col of columns;let i = index">
            <mat-card-content style="height: 100%;" class="col-header">
                <div class="flex flex-col flex-grow gap-2" style="width: 100%;height: 100%;">
                    <div class="flex justify-center items-center" style="position: relative;flex:10%;">
                        <div class="flex-grow">

                            <mat-form-field *ngIf="col.header_confirmed == false">
                                <input matInput [(ngModel)]="col.header" type="text" name="{{col.header}}"
                                    placeholder="Header" required>
                                <mat-error *ngIf="col.header != ''">Please enter header</mat-error>
                            </mat-form-field>
                                <p style="text-align: center;"
                                    (click)="toggleHeaderState(i)" *ngIf="col.header_confirmed == true">
                                    <b>{{col.header}}</b>
                                </p>
                            </div>
                        <div style="position: absolute; right:0">
                            <button style="margin-left: 15px;" id="close-image" *ngIf="col.header_confirmed == false"
                                (click)="ConfirmColumnHeader(i)"><img class="img-sign"
                                    src="assets\icons\save-white.png" /></button>
                            <button style="float: right;" id="close-image" (click)="deleteColumn(i)"><img class="img-sign"
                                    src="assets\icons\minus.png" /></button>
                        </div>
                    </div>
                    <div style="flex:5%;" *ngIf="col.header_confirmed == true" class="divider"></div>
                    <div style="flex:75%;" style="overflow: auto;">
                        <div class="flex flex-col flex-grow">
                            <div *ngFor="let rows of col.row;let x = index">
                                <div class="flex gap-1" style="align-items: center;">
                                    <div class="w-4/5">
                                        <p style="width: 100%" (click)="toggleRowState(i,x)"
                                            *ngIf="rows.row_confirmed == true && rows.data_type != 'Dropdown'">
                                            {{changetoString(i,x)}}</p>
                                        <mat-chip-list (click)="toggleRowState(i,x)" style="width: 100%;"
                                            *ngIf="rows.row_confirmed == true && rows.data_type == 'Dropdown'" cdkDropList
                                            cdkDropListOrientation="horizontal">
                                            <mat-chip *ngFor="let data of rows.Value">
                                                {{data}}
                                            </mat-chip>
                                        </mat-chip-list>
                                        <mat-form-field style="width: 100%;"
                                            *ngIf="rows.row_confirmed == false && rows.data_type == 'Text'">
                                            <input matInput type="text" [(ngModel)]="rows.Value" name="{{rows.Value}}" required>
                                        </mat-form-field>
                                    </div>
                                    <div style="width: 100%;" class="w-1/5">
                                        <button *ngIf="rows.row_confirmed == false" id="close-image"
                                            (click)="ConfirmRow(i,x)">
                                            <img class="img-sign" src="assets\icons\save-white.png" /></button>
                                        <button style="float: right;height: min-content;" id="close-image"
                                            (click)="deleteRow(i,x)"><img class="img-sign"
                                                src="assets\icons\minus.png" /></button>
                                    </div>
                                </div>
                                <div style="padding: 2px 0px 2px 0px" *ngIf="rows.row_confirmed == true" class="divider">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-grow" style="display: flex;justify-content: center;flex:10%;">
                        <button *ngIf="col.header_confirmed == true" id="close-image" (click)="addRow(i)"><img
                                src="assets\icons\plus.png" /></button>
                        <button *ngIf="col.header_confirmed == false" matTooltip="First Confirm Header"
                            id="close-image"><img src="assets\icons\plus-disabled.png" /></button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <button mat-raised-button class="dn-button-primary" (click)="addColumn()">Add Column
        </button>
        <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
    </div>
</div>
