<table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="payerName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Payer Name
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.payerName}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="accountNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Account Number
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.accountNumber}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="accountDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Account Description
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.accountDescription}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="payerCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Payer Code
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.payerCode}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="payerCode2">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Payer Code 2
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.payerCode2}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="issuer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Issuer
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.issuer?.issuerName}}
        </td>
    </ng-container>

    <ng-container matColumnDef="payerType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Payer Type
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.payerType}}
        </td>
    </ng-container>
    <ng-container matColumnDef="insuredParty">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Insured Party
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.insuredParty?.firstName}} {{element.insuredParty?.lastName}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="pps">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            PPS
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.pps}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="payPlanType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Pay Plan Type
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.payPlanType}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="payerId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Payer Id
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.payerId}}
        </td>
    </ng-container>

    <ng-container matColumnDef="payerRank">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Payer Rank
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.payerRank}}
        </td>
    </ng-container>
    
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>