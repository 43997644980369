<div class="flex justify-between items-center gap-2"
    style="background: white; padding-left: 10px;padding-right: 10px; border-radius: 10px;">
    <div class="flex justify-start items-center gap-2">
        <mat-form-field appearance="fill" style="align-self: flex-end;">
            <mat-label>Search by Name</mat-label>
            <input matInput (ngModelChange)="applyFilter()" [(ngModel)]="filter.patientName">
        </mat-form-field>

        <mat-form-field appearance="fill" style="align-self: flex-end;">
            <mat-label>Search by DOB</mat-label>
            <input matInput [matDatepicker]="dospicker" (focus)="dospicker.open()"
                placeholder="Date of Birth" [(ngModel)]="dd" (dateChange)="onDateChange($event)" >
            <mat-datepicker-toggle matSuffix [for]="dospicker"></mat-datepicker-toggle>
            <mat-datepicker #dospicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field *ngIf="!advanceSearchList && currentUser.user_type != global.USER_TYPE.WOUND_NURSE" appearance="fill" style="align-self: flex-end;">
            <mat-label>{{(currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE || (currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER && currentUser.company_type === global.COMPANY_TYPE.SNF) ? 'Select by SNF WC NURSE' : 'Select by Provider')}}</mat-label>
            <mat-select [disabled]="selectable" [(ngModel)]="filter.provider" (ngModelChange)="handleProviderChange($event)">
                <input class="select-input-class" placeholder="Search" [(ngModel)]="searchTextProvider" [ngModelOptions]="{standalone: true}"
                 />
                <mat-option *ngFor="let provider of assocProviders | searchProvider: searchTextProvider" [value]="provider._id" placeholder="Provider">
                    {{provider.first_name}} {{provider.last_name}}, {{provider.title}}
                </mat-option>
            </mat-select>
        </mat-form-field>
            <mat-form-field *ngIf="!advanceSearchList" appearance="fill" style="align-self: flex-end; width: 350px;">
                <mat-label>Select by Facility</mat-label>
                <div class="flex justify-start items-center">
                    <img src="../../../assets/icons/pcc-icon.svg" *ngIf="isPCCFacility(filter.facility)" width="20px" height="20px"
                        alt="">
                    <img src="../../../assets/icons/matrix.png" *ngIf="isMatrixFacility(filter.facility)" 
                        style="height: 12px;
                        width: 20px;  
                        margin-right: 5px;
                        vertical-align: middle;"
                        alt="">
                    <mat-select [disabled]="selectable" [(ngModel)]="filter.facility"
                        (ngModelChange)="handleFacilityChange($event)">
                        <input placeholder="Search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
                            class="select-input-class" />
                        <mat-option *ngFor="let facility of facilities | searchFacility: searchText" [value]="facility._id"
                            placeholder="Facility">
                            <app-facility-title [facility]="facility"></app-facility-title>
                        </mat-option>
                    </mat-select>
                </div>
            </mat-form-field>
            <mat-form-field *ngIf="advanceSearchList" appearance="fill" style="align-self: flex-end;">
                <mat-label>Search by DN MRN</mat-label>
                <input matInput (ngModelChange)="applyFilter()" [(ngModel)]="filter.dn_mrnNo">
            </mat-form-field>
            <mat-form-field *ngIf="advanceSearchList" appearance="fill" style="align-self: flex-end;">
                <mat-label>Search by PCC MRN</mat-label>
                <input matInput (ngModelChange)="applyFilter()" [(ngModel)]="filter.pcc_mrnNo">
            </mat-form-field>
            <mat-form-field *ngIf="advanceSearchList && currentUser.portal_type === global.PORTAL_TYPE.NON_WC" appearance="fill" style="align-self: flex-end;">
                <mat-label>Search by SSN</mat-label>
                <input matInput (ngModelChange)="applyFilter()" [(ngModel)]="filter.patient_ssn">
            </mat-form-field>

    </div>
    <div class="flex justify-start items-center gap-2">
        <button *ngIf="!selectable" class="dn-button-secondary" style="flex: 10%; min-width: auto;" mat-raised-button
            (click)="clearFilter()">Clear Filter
        </button>
        <button class="dn-button-primary" style="flex: 20%; min-width: auto;" mat-raised-button 
            *ngIf="(currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER && currentUser.user_type != global.USER_TYPE.WOUND_NURSE)"
            (click)="onAddNewClick.emit()">Add New Patient
        </button>
    </div>

</div>

<div style="height: 5px;"></div>

<app-alphabetic-index-bar *ngIf="!advanceSearchList" [activeIndex]="activeAlphabeticBarIndex"
    (onIndexButtonClick)="handleAphabeticIndexButtonClick($event)"></app-alphabetic-index-bar>
<div style="height: 5px;"></div>
<div class="flex justify-start items-center gap-2">
    <form *ngIf="selectedPatients.length > 0" class="flex justify-start items-baseline gap-2"
        style="position: relative;bottom: -6px;"
        (submit)="$event.preventDefault(); handleAddToCensus(selectedPatientRoundingSheet.value)">

        <mat-form-field *ngIf="advanceSearchList" style="align-self: flex-end; width: 350px;">
            <div class="flex justify-start items-start">
                <img src="../../../assets/icons/pcc-icon.svg" *ngIf="isPCCFacility(filter.facility)" width="20px" height="20px"
                    alt="">
                <img src="../../../assets/icons/matrix.png" *ngIf="isMatrixFacility(filter.facility)" 
                    style="height: 12px;
                    width: 20px;  
                    margin-right: 5px;
                    vertical-align: middle;"
                    alt="">
                <mat-select name="facilitySelect" [disabled]="selectable" [(ngModel)]="filter.facility"
                    (ngModelChange)="handleFacilityChange($event)">
                    <input matInput placeholder="Search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
                        style="color:#000;height: 40px;padding-left: 5px;" />
                    <mat-option *ngFor="let facility of facilities | searchFacility: searchText" [value]="facility._id"
                        placeholder="Facility">
                        <app-facility-title [facility]="facility"></app-facility-title>
                    </mat-option>
                </mat-select>
            </div>
        </mat-form-field>


        <div style="color: #FFF; width: max-content;">Selected Patients: {{selectedPatients.length}}</div>
                <mat-form-field>
                    <mat-select name="patientRoundingSheet" #selectedPatientRoundingSheet value="" required>
                        <mat-option value="" [disabled]="true">
                            Select Patient Census
                        </mat-option>
                        <mat-option class="option-width" *ngFor="let patientRoundingSheet of filterpatientRoundingSheets"
                            value="{{patientRoundingSheet._id}}">
                            {{getDOS(patientRoundingSheet)}} - {{patientRoundingSheet.name}} -
                            {{patientRoundingSheet.facility?.title | uppercase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
        <button mat-raised-button class="dn-button-primary" type="submit">
            Add to Census
        </button>
        <button *ngIf="isNursingCompany" mat-raised-button class="dn-button-primary" type="button"
            (click)="handleAddToRecurrentCensus()">
            Create Recurrent Census
        </button>
    </form>

    <div class="flex flex-grow justify-center items-center gap-2 table-nav">

        <button mat-raised-button class="dn-button-primary" [disabled]="!pagination.hasPrevPage"
            (click)="handlePrevClick()"> Prev</button>

        <span style="background: white; border-radius: 2px; font-size: 12px;padding: 5px;">
            {{getcountofPatients()}}</span>

        <button mat-raised-button class="dn-button-primary" [disabled]="!pagination.hasNextPage"
            (click)="handleNextClick()">Next</button>
    </div>
    <div *ngIf="selectedPatients.length > 0 && currentUser.user_type == global.USER_TYPE.DOCTOR && currentUser.company_type !== global.COMPANY_TYPE.NURSING
        "><button mat-raised-button class="dn-button-primary"
        (click)="handleExportPatientsClick()">Export Patients</button></div>
</div>
<div style="height: 5px;"></div>

<div style="position: relative;" [class.scrollable]="selectable"> 
    <table class="table-row-hover patient-list-table" mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">

        <ng-container matColumnDef="checkbox"> 
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let element"> 
                <mat-checkbox *ngIf="currentUser.user_type != global.USER_TYPE.WOUND_NURSE && currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER" [checked]="isPatientSelected(element)" (change)="handleSelectPatient($event, element)"></mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
            <td mat-cell *matCellDef="let element" (click)="onRowClick($event,element)"> 
                <app-patient-name [patient]="element"></app-patient-name>
            </td>
        </ng-container>

        <ng-container matColumnDef="date_of_birth">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date of Birth</th>
            <td mat-cell *matCellDef="let element"> 
                {{ getDOB(element) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="gender">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Gender</th>
            <td mat-cell *matCellDef="let element"> {{element.gender}} </td>
        </ng-container>
        <ng-container matColumnDef="mrn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> MRN</th>
            <td mat-cell *matCellDef="let element"> {{element.mrn}} </td>
        </ng-container>

        <ng-container matColumnDef="facility">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Facility</th>
            <td mat-cell *matCellDef="let element">
                <app-facility-title [facility]="element?.facility_id_ref"></app-facility-title>
            </td>
        </ng-container>
        <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Location</th>
            <td mat-cell *matCellDef="let element"> {{getRecentPatientADTRecord(element)?.floorDesc}}
                {{getRecentPatientADTRecord(element)?.roomDesc}} - {{getRecentPatientADTRecord(element)?.bedDesc}}</td>
        </ng-container>
        <ng-container matColumnDef="admission_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Admission Date</th>
            <td mat-cell *matCellDef="let element"> {{element?.pcc_payload?.admissionDate | date: 'M/d/yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="discharge_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Discharge Date</th>
            <td mat-cell *matCellDef="let element">
                {{getRecentPatientADTRecord(element)?.actionCode === 'DD' || getRecentPatientADTRecord(element)?.actionCode === 'DHOME' ? (getRecentPatientADTRecord(element)?.updatedAt | date: 'M/d/yyyy') : ''}}
            </td>
        </ng-container>
        <ng-container *ngIf="false" matColumnDef="payer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Payor</th>
            <td mat-cell *matCellDef="let element"> {{getPayor(element)}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
            <td mat-cell *matCellDef="let element"> {{element?.pcc_payload?.patientStatus}} </td>
        </ng-container>
        <ng-container matColumnDef="source">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Source</th>
            <td mat-cell *matCellDef="let element"> {{element.source || 'portal'}} </td>
        </ng-container>
        <ng-container matColumnDef="last_seen">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="last_seen">Last Seen</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="getLastSeen(element)">
            </td>
          </ng-container>
        <ng-container *ngIf="false" matColumnDef="face_sheet">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Face Sheet</th>
            <td mat-cell *matCellDef="let element; let index=index;" style="cursor: pointer;">
                <div *ngIf="element.faceSheet" class="flex flex-inline justify-start items-center gap-2">
                    <i (click)="viewPDF(element.faceSheet.aws_path)" class="icon icon-view" matTooltip="View"
                        matTooltipPosition="above"></i>
                    <i (click)="downloadFile(element)" class="icon icon-download" matTooltip="Download"
                        matTooltipPosition="above"></i>
                </div>
                <div *ngIf="!element.faceSheet" style="color: red;"
                    (click)="showPatientDocumentsUploadDialog(element)">Upload</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="operations">
            <th mat-header-cell *matHeaderCellDef>Details</th>
            <ng-container *matCellDef="let element">
                <td mat-cell *ngIf="element.is_active == 'true'">
                    <div class="operation-buttons flex flex-inline justify-start items-center gap-5">

                        <i class="icon icon-edit" matTooltip="Edit" matTooltipPosition="above"
                            (click)="onEditClick.emit(element.patient_id)"></i>
 
                        <i class="icon icon-delete" matTooltip="Delete" matTooltipPosition="above"
                            (click)="deleteDialogeConfirmation(element.patient_id)"></i>
                    </div>
                </td>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="!selectable">
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="hover-row"></tr>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{selectable: selectable, selected: currentPatient && currentPatient.patient_id == row.patient_id}"
            (dblclick)="selectable && onSelect.emit(row)"></tr>
    </table>
    <ngx-ui-loader loaderId="patient-list-loader"></ngx-ui-loader>
</div>