import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Input, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { WoundService } from 'src/app/services/wound.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

export interface WoundTag {
  _id?:string,
  label:string,
}

@Component({
  selector: 'app-wound-tags',
  templateUrl: './wound-tags.component.html',
  styleUrls: ['./wound-tags.component.css']
})
export class WoundTagsComponent implements OnInit {
  @Input() company_id:string;
  separatorKeysCodes: number[] = [ENTER, COMMA];
 
  tags: WoundTag[];
  loaderId = 'app-wound-tags';
  newInputValue:string = '';
  showNewInput = false;
  editingItem:WoundTag= null;
  currentUser: any;
  constructor(private _wound: WoundService,
    private toastr: ToastrService,
    private _authService: AuthService,
    private loader: NgxUiLoaderService) {
    this.currentUser = this._authService.currentUser;
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId);
    await this.initWoundTags();
    this.loader.stopLoader(this.loaderId);
  }

  async initWoundTags() {
    let {status, data} = await lastValueFrom(this._wound.getWoundTags(this.company_id)) as any;
    if (status == 200) {
      this.tags = data;
      
         
    }
  }

  rowConfirmed() {
    return true;
  }

  handleAddNew() {
    this.showNewInput = true;
  }
  handleNewInputValueChange($event) {
    if(this.newInputValue == ''){
      return;
    }
    this.addNew(this.newInputValue);
    this.newInputValue = '';
    this.showNewInput = false;
  }

  async addNew(value) {
    const {status, data} = await lastValueFrom(this._wound.addWoundTag(this.company_id, value)) as any;
    if(status == 200) {     
      this.tags.push(data as WoundTag);
    }
  }

  isEditingItem(tag, i) {
    return this.editingItem === tag;
  }

  editItem(tag, i) {
    console.log(tag);
    this.editingItem = tag;
    this.newInputValue = tag.label;
  }

  async deleteItem(tag, i) {
    const index = this.tags.findIndex(_tag => _tag._id === tag._id);
    const {status, data} = await lastValueFrom(this._wound.updateWoundTag(tag._id, undefined, true)) as any;
    if(status == 200 && index >=0) {     
      this.tags.splice(index, 1);
    }
  }

  handleEditInputValueChange($event, i) {
    // is enter key pressed
    console.log({
      '$event.keyCode': $event.keyCode,
      $event,
      i
    })
    if ($event.keyCode === ENTER) {
      this.updateItem($event.target.value, i);
      this.editingItem = null;
    }
  }

  updateItem(value, i) {
    console.log({value, i})
    this.tags[i].label = value;
      this.editingItem = null;
  }
  
}
