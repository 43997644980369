
<app-header></app-header>

<main [class]="noteStateCssClasses()">
    <div class="container flex-col">
        <div class="editor-sidebar-wrapper flex flex-1" style="position: relative;">
            <ng-container *ngIf="note">

                <div *ngIf="telemedicine" class="sidebar-wrapper flex flex-col justify-start items-stretch">
                     
                    <app-sidebar-video-call class="flex" *ngIf="videoSidebarVisible" >
                    </app-sidebar-video-call>

                <button  class="sidebar-toggle-btn" (click)="videoSidebarVisible=!videoSidebarVisible">
                    <mat-icon *ngIf="videoSidebarVisible">keyboard_arrow_left</mat-icon>
                    <mat-icon *ngIf="!videoSidebarVisible">keyboard_arrow_right</mat-icon>
                </button>
                </div>
                <div *ngIf="!telemedicine && note.patient" class="sidebar-wrapper flex flex-col justify-start items-stretch">
                    
                    <app-patient-list-siderbar  [note]="note" [hidden]='!sidebarVisiblepatients' *ngIf="note"></app-patient-list-siderbar>
                    <button class="sidebar-toggle-btn" (click)="sidebarVisiblepatients=!sidebarVisiblepatients">
                        <mat-icon *ngIf="!sidebarVisiblepatients">keyboard_arrow_right</mat-icon>
                        <mat-icon *ngIf="sidebarVisiblepatients">keyboard_arrow_left</mat-icon>
                    </button>
                </div>

                <div class="flex flex-col">
                    <div class="transcription-audio flex flex-col" *ngIf="note.hasAudio()">
                        <div class="flex gap-2">
                            <div class="flex items-center justify-start">
            
                                <div class="flex-shrink-0">
                                    <span class="dp"></span>
                                </div>
            
                                <div class="flex justify-start items-center">
                                    <div class="flex-col">
                                        <div class="title">{{note.audio.audio_title}}</div>
                                        <div>
                                            <span class="date">DOS: {{getAudioDOS() | date: global.date_time_format}}</span>
                                        </div>
                                        <div>
                                            <span class="date">DOC: {{getAudioDOC() | date: global.date_time_format}}</span>
                                        </div>
                                    </div>
                                </div>
            
                            </div>
            
                            <div class="flex flex-grow justify-center items-center">
                                <div class="transcription-player" style="flex:1">
                                    <audio id="transcription" [src]="note.audio.aws_path" controls controlsList="nodownload"
                                           style="width:100%"></audio>
                                </div>
                            </div>
            
            
                            <div class="flex justify-end items-center">
                                <div class="flex flex-col gap-2">
                                    <div class="duration flex justify-between items-center">
            
                                        <div class="flex-col" style="margin-right: 30px">
                                            <label style="margin-top: -19px" for="back-slider">Back</label>
                                            <mat-slider id="back-slider"
                                                        min="1"
                                                        max="5"
                                                        step="1"
                                                        thumbLabel="true"
                                                        [(ngModel)]="backSlider"
                                                        style="height: 14px; top: -13px; right: 5px">
                                            </mat-slider>
                                        </div>
            
                                        <div class="flex-col">
                                            <label style="margin-top: -19px" for="speed-slider">Speed</label>
                                            <mat-slider id="speed-slider"
                                                        min="0.5"
                                                        max="2.0"
                                                        step="0.5"
                                                        thumbLabel="true"
                                                        [(ngModel)]="speedSlider"
                                                        style="height: 14px; top: -13px; right: 5px"></mat-slider>
                                        </div>
                                        {{note.audio.audio_length}}
                                    </div>
                                    <div class="status-col flex justify-between items-center">
            
                                        <button
                                                *ngIf="currentUser.user_type == global.USER_TYPE.DOCTOR || currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE"
                                                class="theme-btn status"
                                                style="top:0 !important; right: 7px !important;"
                                                class="dn-button-primary"
                                                [class.completed]="note.audio.progress_status=='20'"
                                                mat-raised-button>{{note.audio.progress_status=='20' ? 'COMPLETED' : 'IN PROGRESS'}}</button>
                                        <div style="width: 236px;">
                                            
                                        <app-switch-button
                                        labelOff="In Progress"
                                        labelOn="Completed"
                                        [thirdForm]="false"
                                        (handleOff)="changeCurrentAudioStatus(19)"
                                        (handleOn)="changeCurrentAudioStatus(20)"
    
                                        [checked]="note.audio.progress_status=='20'"
                                >
                                </app-switch-button>
                                        </div>
            
                                    </div>
                                </div>
                            </div>
                        </div>
            
                    </div>
                    <div class="editor-top flex flex-col gap-2">
                        <div class="editor-buttons flex flex-row justify-start items-center gap-2">
                            <div><button class="dn-button-secondary" mat-raised-button (click)="goBack()">Back</button></div>
                            <div class="flex justify-center items-center gap-2 flex-grow">
                                <button class="dn-button-primary" style="min-width: 140px !important" mat-raised-button (click)="saveNote()" [disabled]="!buttonsState.save">Save</button>
                                <button class="dn-button-primary" style="min-width: 140px !important" mat-raised-button (click)="submitForReviewNote()" [disabled]="!buttonsState.submitForReview">Submit for Review</button>
                                <button *ngIf="!note.isSigned() && !note.isUploaded()" class="dn-button-primary" style="min-width: 140px !important" mat-raised-button (click)="revertNote()" [disabled]="!buttonsState.revert">Return</button>
                                <button class="dn-button-primary" style="min-width: 140px !important" mat-raised-button (click)="signNote()" [disabled]="!buttonsState.sign">Sign</button>
                                <button class="dn-button-primary" style="min-width: 140px !important" mat-raised-button (click)="uploadNote()" [disabled]="!buttonsState.upload">Upload</button>

                                <ng-container *ngIf="note.isUploaded()">
                                        <ng-container *ngIf="note.hasPCCPatient()">
                                            <button class="dn-button-primary" style="min-width: 140px !important" mat-raised-button (click)="createAddendum()" *ngIf="!isCreatingAddendum && addendum_counts?.saved === 0">Create Addendum</button>
                                            <button class="dn-button-primary" style="min-width: 140px !important" mat-raised-button (click)="saveAddendum()" *ngIf="isCreatingAddendum && !compareOriginalAddendumState()">Save Addendum</button>
                                            <button class="dn-button-primary" style="min-width: 140px !important" mat-raised-button (click)="uploadAddendum()" [disabled]="!buttonsState.uploadAddendum" *ngIf="isCreatingAddendum && compareOriginalAddendumState() && addendum?._id">Upload Addendum</button>
                                            <button class="dn-button-secondary" style="min-width: 140px !important" mat-raised-button (click)="discardAddendum()" *ngIf="isCreatingAddendum">Discard</button>
                                        </ng-container>

                                        <ng-container *ngIf="!note.hasPCCPatient()">
                                            <button class="dn-button-primary" style="min-width: 140px !important" mat-raised-button (click)="createAddendum()" *ngIf="!isCreatingAddendum">Create Addendum</button>
                                            <button class="dn-button-primary" style="min-width: 140px !important" mat-raised-button (click)="saveAddendum()" *ngIf="isCreatingAddendum && !compareOriginalAddendumState()">Save Addendum</button>
                                            <button class="dn-button-primary" style="min-width: 140px !important" mat-raised-button (click)="uploadAddendum()" *ngIf="isCreatingAddendum && compareOriginalAddendumState() && addendum?._id">Upload Addendum</button>
                                            <button class="dn-button-secondary" style="min-width: 140px !important" mat-raised-button (click)="discardAddendum()" *ngIf="isCreatingAddendum">Discard</button>
                                        </ng-container>

                                </ng-container>
                                <span *ngIf="showCoSign()">
                                    <mat-checkbox class="tp-margin"
                                    
                                        [checked]="note?.coSign_status?.status == true"
                                        [disabled]="note?.coSign_status?.status"
                                        (change)="associatedProvidersDropDown = !associatedProvidersDropDown; onChangeCoSignCheckBox($event.checked)">{{isCoSigned()? readyToCosigned() : coSigner()}}
                                    </mat-checkbox>
                                </span>
                                <mat-form-field *ngIf = "associatedProvidersDropDown" [disabled]="note?.coSign_status?.status">
                                    <mat-label>Provider</mat-label>
                                    <mat-select  aria-required="true" 
                                                #coSignSelect
                                                [disabled]="note?.coSign_status?.status"
                                                class="uppercase"
                                                [(ngModel)]="coSignedProvider"
                                                >
                                        <mat-option *ngFor="let doctor of associatedProviders" [value]="doctor">
                                            <ng-container *ngIf="doctor">
                                                {{doctor.first_name}} {{doctor.last_name}}, {{doctor.title}}
                                            </ng-container>
                                        </mat-option>
        
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <ng-container *ngIf="showNextPrevButtons()">
                                <ng-container *ngIf="dashboardData.audios.length === 0">
                                    <div class="flex gap-2">
                                        <button class="dn-button-primary" mat-raised-button [disabled]="!dashboardData.note.prev" (click)="navigate(dashboardData.note.prev)" matTooltip="Previous Note"><</button>
                                        <button class="dn-button-primary" mat-raised-button [disabled]="!dashboardData.note.next" (click)="navigate(dashboardData.note.next)" matTooltip="Next Note">></button>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="dashboardData.audios.length > 0">
                                    <div class="flex gap-2">
                                        <button class="dn-button-primary" mat-raised-button [disabled]="!dashboardData.audio.prev" (click)="navigate(dashboardData.audio.prev)" matTooltip="Previous Dictation"><</button>
                                        <button class="dn-button-primary" mat-raised-button [disabled]="!dashboardData.audio.next" (click)="navigate(dashboardData.audio.next)" matTooltip="Next Dictation">></button>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="flex flex-row items-baseline gap-2">
                            <div class="editor-fields flex flex-grow items-baseline gap-2">
                                <ng-container *ngIf="isNew">

                                        <mat-form-field>
                                            <mat-label>Provider</mat-label>
                                            <mat-select [(ngModel)]="note.provider" aria-required="true"
                                                        class="uppercase"
                                                        (ngModelChange)="handleProviderChange()">
                                                <mat-option *ngFor="let doctor of doctors" [value]="doctor">
                                                    <ng-container *ngIf="doctor">
                                                        {{doctor.first_name}} {{doctor.last_name}}, {{doctor.title}}
                                                    </ng-container>
                                                </mat-option>
                
                                            </mat-select>
                                        </mat-form-field>
                
                                        <mat-form-field>
                                            <mat-label>Facility</mat-label>
                                            <img matPrefix class="pcc-icon" src="../../../assets/icons/pcc-icon.svg" *ngIf="note.facility?.pcc_facId" />
                                            <mat-select [(ngModel)]="note.facility" name="facility" (ngModelChange)="handleFacilityChange()"
                                                aria-required="true" class="uppercase">
                                                <mat-option *ngIf="!note.provider">
                                                    <mat-error>Select Provider First</mat-error>
                                                </mat-option>
                                                <mat-option *ngFor="let facility of facilities" [value]="facility">
                                                    <app-facility-title [facility]="facility"></app-facility-title>
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                
                                        <mat-form-field>
                                            <mat-label>Patient</mat-label>
                                            <img matPrefix class="pcc-icon" src="../../../assets/icons/pcc-icon.svg" *ngIf="note.patient?.pcc_patientId" />                                            
                                            <input matInput type="text" [(ngModel)]="note.patient" name="patient" [formControl]="patientControl"
                                                [matAutocomplete]="patientAutoComplete"
                                                (ngModelChange)="handlePatientChange()">
                                            <mat-autocomplete #patientAutoComplete="matAutocomplete"
                                                [displayWith]="displayPatientFn">
                                                <mat-option *ngIf="!note.facility">
                                                    <mat-error>Select Facility First</mat-error>
                                                </mat-option>
                                                <mat-option value="" (click)="showPatientAddDialog()">
                                                    <strong>+ Add New Patient</strong>
                                                </mat-option>
                                                <mat-option *ngFor="let patient of filteredPatients | async" [value]="patient" (click)="patientAutoComplete.closePant()">
                                                    <app-patient-name [patient]="patient"></app-patient-name>
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                        <button class="advanced-patient-search-btn" mat-icon-button (click)="showPatientDialog()">
                                            <mat-icon  matTooltip="Advanced Patient Search" matTooltipPosition="top">search</mat-icon>
                                        </button>
                                        <ng-container *ngIf="config.editable">
                                            <mat-form-field  style="width: 150px;" >
                                                <mat-datepicker-toggle matSuffix [for]="picker2" ></mat-datepicker-toggle>
                                                <ngx-mat-datetime-picker #picker2 [enableMeridian]="true" [showSeconds]="false" >
                                                    <mat-error *ngIf="!dateOfService">Please enter date of service</mat-error>
                                                </ngx-mat-datetime-picker>
                                            </mat-form-field>
                                        </ng-container>

                                        <ng-container *ngIf="!config.editable">

                                            <ng-container  *ngIf="!note.hasAudio()">
                                                <mat-form-field  style="width: 150px;">
                                                    <mat-datepicker-toggle matSuffix [for]="picker2" ></mat-datepicker-toggle>
                                                    <ngx-mat-datetime-picker #picker2 [enableMeridian]="true" [showSeconds]="false" >
                                                        <mat-error *ngIf="!note.dates.service">Please enter date of service</mat-error>
                                                    </ngx-mat-datetime-picker>
                                                </mat-form-field>
                                            </ng-container>
    
                                            <ng-container  *ngIf="note.hasAudio()">
                                                <mat-form-field style="width: 150px;">
                                                    <mat-label>Date of Service</mat-label>
                                                    <input type="text" readonly matInput value="{{getNoteDOS() | date: global.date_time_format}}">
                                                </mat-form-field>
                                            </ng-container>
                                        </ng-container>

    
                                        <mat-form-field class="flex-grow">
                                            <input class="uppercase" matInput placeholder="Note Title" [(ngModel)]="note.title" class="uppercase" onkeypress="return /[0-9a-z\s\.]/i.test(event.key)"  required />
                                        </mat-form-field>
                                
                                
                                    </ng-container>
                                    <ng-container *ngIf="!isNew">
                                        
                                        <mat-form-field>
                                            <mat-label>Provider</mat-label>
                                            <input type="text" readonly matInput value="{{note.provider.first_name}} {{note.provider.last_name}}, {{note.provider.title}}">
                                        </mat-form-field>
            
                                        <ng-container *ngIf="!config.editable">
                                            <mat-form-field>
                                                <mat-label>Facility</mat-label>
                                                <img matPrefix class="pcc-icon" src="../../../assets/icons/pcc-icon.svg" *ngIf="note.facility?.pcc_facId" />
                                                <input type="text" readonly matInput value="{{note.facility.title}}">
                                            </mat-form-field>
                                        </ng-container>
                                        
                                        <ng-container *ngIf="config.editable">
                                            <mat-form-field>
                                                <mat-label>Facility</mat-label>
                                                <img matPrefix class="pcc-icon" src="../../../assets/icons/pcc-icon.svg" *ngIf="note.facility?.pcc_facId" />
                                                <mat-select [(ngModel)]="note.facility" name="facility" (ngModelChange)="handleFacilityChange()"
                                                    aria-required="true" class="uppercase">
                                                    <mat-option *ngIf="!note.provider">
                                                        <mat-error>Select Provider First</mat-error>
                                                    </mat-option>
                                                    <mat-option *ngFor="let facility of facilities" [value]="facility">
                                                        <app-facility-title [facility]="facility"></app-facility-title>
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </ng-container>

                                        <ng-container *ngIf="!config.editable">
                                            <mat-form-field>
                                                <mat-label>Patient</mat-label>
                                                <img matPrefix class="pcc-icon" src="../../../assets/icons/pcc-icon.svg" *ngIf="note.patient?.pcc_patientId" />
                                                <input type="text" readonly matInput value="{{note.patient?.last_name}}, {{note.patient?.first_name}} {{note.patient?.middle_name ? note.patient?.middle_name : ''}}">
                                            </mat-form-field>
                                        </ng-container>
                                        
                                        <ng-container *ngIf="config.editable">
                                            <mat-form-field>
                                                <mat-label>Patient</mat-label>
                                                <img matPrefix class="pcc-icon" src="../../../assets/icons/pcc-icon.svg" *ngIf="note.patient?.pcc_patientId" />                                            
                                                <input matInput type="text" [(ngModel)]="note.patient" name="patient" [formControl]="patientControl"
                                                    [matAutocomplete]="patientAutoComplete">
                                                <mat-autocomplete #patientAutoComplete="matAutocomplete"
                                                    [displayWith]="displayPatientFn">
                                                    <mat-option *ngIf="!note.facility">
                                                        <mat-error>Select Facility First</mat-error>
                                                    </mat-option>
                                                    <mat-option value="" (click)="showPatientAddDialog()">
                                                        <strong>+ Add New Patient</strong>
                                                    </mat-option>
                                                    <mat-option *ngFor="let patient of filteredPatients | async" [value]="patient" (click)="patientAutoComplete.closePant()">
                                                        <app-patient-name [patient]="patient"></app-patient-name>
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>   
                                            <button class="advanced-patient-search-btn" mat-icon-button (click)="showPatientDialog()">
                                                <mat-icon matTooltip="Advanced Patient Search" matTooltipPosition="top">search</mat-icon>
                                            </button> 
                                        </ng-container>
                                        
                                        <ng-container *ngIf="config.editable">
                                            <mat-form-field  style="width: 150px;">
                                                <mat-datepicker-toggle matSuffix [for]="picker2" ></mat-datepicker-toggle>
                                                <ngx-mat-datetime-picker #picker2 [enableMeridian]="true" [showSeconds]="false" >
                                                    <mat-error *ngIf="!note.dates.service">Please enter date of service</mat-error>
                                                </ngx-mat-datetime-picker>
                                            </mat-form-field>
                                        </ng-container>

                                        <ng-container *ngIf="!config.editable">
                                            <mat-form-field style="width: 150px;">
                                                <mat-label>Date of Service</mat-label>
                                                <input type="text" readonly matInput value="{{getNoteDOS() | date: global.date_time_format}}">
                                            </mat-form-field>
                                        </ng-container>
                                        <ng-container *ngIf="!config.editable">
                                            <mat-form-field class="flex-grow">
                                                <input class="uppercase" matInput placeholder="Note Title" [value]="note.title" readonly class="uppercase" required />
                                            </mat-form-field>
                                        </ng-container>

                                        <ng-container *ngIf="config.editable">
                                            <mat-form-field class="flex-grow">
                                                <input class="uppercase" matInput placeholder="Note Title" [(ngModel)]="note.title" class="uppercase" onkeypress="return /[0-9a-z\s\.]/i.test(event.key)" required />
                                            </mat-form-field>
                                        </ng-container>
                                  
                                
                                    </ng-container>
                                </div>
                                
                                <button 
                                *ngIf="isNew || (!note.isSigned() && !note.isUploaded())"
                                class="dn-button-primary" mat-raised-button (click)="showTemplateDialog()"
                                     >Select Template
                                </button>
                        </div>
                        <span class="flex flex-row justify-end items-center" >
                            <mat-icon *ngIf="note?.rounding_sheet_id" matTooltip="Charge" style="cursor: pointer;" (click)="sidebarVisible=true">attach_money</mat-icon>
                            </span>

                            <div class="flex flex-col">
                            
                            <span *ngIf="saveError" class="flex flex-row justify-end items-center">
                                <mat-error>*Unable to save note</mat-error>
                            </span>
                            <span *ngIf="note.hasAudio() && !isProvider()">
                                <span *ngIf="!timeUp" style="font-size: 11px;">
                                    TAT:
                                     <countdown-timer style="font-size: 11px;" 
                                    [end]="getEndTimeForTimer(audio)" (zeroTrigger)="triggerFunction()">
                                </countdown-timer> 
                                </span>
                                <span *ngIf="timeUp" style="color: red; font-size: 11px;">
                                    TAT: -
                                    <countdown-timer style="font-size: 11px;" 
                                   [start]="getStratTimeForTimer(audio)" (zeroTrigger)="triggerFunction()">
                               </countdown-timer> 
                                </span>
                            </span>
                            
                            <span *ngIf="note.hasAudio() && !isMatchAudioDOSWithNoteDOS" style="vertical-align: -webkit-baseline-middle; padding-left: 10px;" >
                                <span *ngIf="isNew || !note.isSigned()">
                                    <span style="font-size:12px;position:relative;top:-7px">
                                        <span style="color:rgb(255, 86, 48)">WARNING: 
                                            Audio Date of Service does not match the Note Date of Service.
                                        </span>
                                    </span>
                                </span>
                            </span>
                            <span *ngIf="!note.hasAudio() && !existInNoteText.date_of_service">
                                <span *ngIf="isNew || !note.isSigned()">
                                    
                                        <span style="font-size:12px;position:relative;top:-35px; color:rgb(255, 86, 48)">WARNING: 
                                            Note Date of Service does not match the Date of Service specified with the note.
                                        </span>

                                </span>
                            </span>
                            <span *ngIf="!isNew && note.hasPCCPatient() && note.isSigned() && !note.isUploaded() && !hasLoggedInToPCC() && note.isRelatedProvider(currentUser._id)">
                                <span style="font-size:12px;position:relative;top:-7px; color:rgb(255, 86, 48)">This note was NOT uploaded into PCC. Please sign in to PCC to upload the note.</span>
                            </span>
                            <span *ngIf="!isNew && note.containsAsterisks() && note.isPublished() && !note.isSigned()">
                                <span style="font-size:12px;position:relative;top:-7px; color:rgb(255, 86, 48)">WARNING:
                                    The *** must be filled or removed to sign the note.
                                </span>
                            </span>
                        </div>
                        <div class="flex flex-wrap justify-between">
                            <div class="audit-text">
                                <ng-container *ngIf="autoSavedOn">
                                    Auto saved on: {{autoSavedOn|date: global.date_time_format}}
                                </ng-container>
                            </div>
                            <div class="audit-text" *ngIf="getRecentAuditText()" [innerHTML]="getRecentAuditText()" style="white-space:nowrap;"></div>
                        </div>
                    </div>

                    <div class="transcription-editor flex-grow" aria-readonly="true">
                        
                        <ng-container *ngIf="ckEditorReady">
                            <ckeditor 
                            *ngIf="!isCreatingAddendum"
                            [(ngModel)]="note.htmlText" 
                            name="htmlText" 
                            class="h100" 
                            editorUrl="../../../assets/ckeditor/ckeditor.js"
                            [readOnly]="!config.editable"
                            [config]="cke4Config" type="divarea" #angularEditor (change)="handleEditorChange($event)" (ready)="onReadyCkeditor($event)"></ckeditor>

                            
                            <ckeditor 
                            *ngIf="isCreatingAddendum"
                            [(ngModel)]="addendum.text" 
                            name="htmlText" 
                            class="h100" 
                            editorUrl="../../../assets/ckeditor/ckeditor.js"
                            [readOnly]="!config.editable"
                            [config]="cke4Config" type="divarea" #angularEditor (change)="handleEditorChange($event)" (ready)="onReadyCkeditor($event)"></ckeditor>
                        </ng-container>
                    </div>

                </div>
    
                <div class="sidebar-wrapper flex flex-col justify-start items-stretch">
                    <button class="sidebar-toggle-btn" (click)="sidebarVisible=!sidebarVisible">
                        <mat-icon *ngIf="sidebarVisible">keyboard_arrow_right</mat-icon>
                        <mat-icon *ngIf="!sidebarVisible">keyboard_arrow_left</mat-icon>
                    </button>
                    <app-note-sidebar class="flex" *ngIf="sidebarVisible && note"
                        [audioId]="audioId" 
                        [note]="note" 
                        [addendum_counts]="addendum_counts" 
                        [noteId]="note._id"  
                        (onAddendumClick)="handleAddendumClick($event)" 
                        (onTemplateSelect)="handleTemplateSelect($event)"
                        (closeSidebar)="closeSidebar($event)">
                    </app-note-sidebar>
                </div>
            </ng-container>
            <ngx-ui-loader [loaderId]="loaderId" bgsPosition= "center-center"></ngx-ui-loader>
            <ngx-ui-loader loaderId="phrase_loader" bgsPosition= "center-center"></ngx-ui-loader>
        </div>
    </div>
</main>
