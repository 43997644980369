import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import moment from 'moment'
import { ToastrService } from 'ngx-toastr';
import { Observable, lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AdminDictationsService } from '../../admin-dictations/admin-dictations.service';
import { TranscriptionCreateService } from '../../transcription-create/transcription-create.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BillerDashboardService } from '../../biller-dashboard/biller-dashboard.service';
import { MatDialog } from '@angular/material/dialog';
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { startWith, map } from 'rxjs/operators';
import { CensusNotesServiceService } from '../census-notes-service.service';
import { HttpResponse } from '@angular/common/http';
import { CommonService } from 'src/app/services/common.service';
import * as global from "src/app/includes/global";
import { SnfWcNurseAssociationService } from '../../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { UserService } from 'src/app/services/Modules/user.service';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';
import { FacilityService } from 'src/app/services/facility.service';


interface IFilter {
  active: boolean,
  creation_date: {
    startDate: any | Date | moment.Moment,
    endDate: any | Date | moment.Moment
  },
  date_of_service: {
    startDate: any | Date | moment.Moment,
    endDate: any | Date | moment.Moment
  },
  user: string,
  facility: string,
  patient: string,
  progress_status: string
}

@Component({
  selector: 'app-census-notes',
  templateUrl: './census-notes.component.html',
  styleUrls: ['./census-notes.component.css']
})
export class CensusNotesComponent implements OnInit {
  filteredFacilities: Observable<string[]>;
  facilityControl = new FormControl();
  filteredProvider: Observable<string[]>;
  providerControl = new FormControl();

  global = global;
  public isVisibleTable: boolean = true;
  isVisibleDetailTable: boolean = false;
  public sortedData: Array<any>;
  public notes: Array<any>;
  public group_notes: Array<any>;
  public notesGroup;
  public sortNotesGroup;
  public c_user;
  chargesPopup: any;
  public c_date = moment(new Date());
  public creationDateFilter: Boolean = true;
  public serviceDateFilter: Boolean = true;
  displayedColumns: string[] = ['date_of_creation', 'patient_name', 'facility_title', 'dos', 'updatedAt', 'provider_name', 'census_name', 'progress_status', 'operation'];
  chargeStatus: string = 'default';
  @ViewChild(MatSort) sort: MatSort;

  provideModel: any = '';
  facilityMOdel: any = "";
  company_id: any;
  // filter: any = {
  //   creation_date: {
  //     startDate: moment().subtract(7, 'days').toDate(),
  //     endDate: moment().toDate()
  //   },
  //   date_of_service: null

  // };
  filter: any = {
    creation_date: null,
    date_of_service: {
      startDate: moment().subtract(2, 'days'),
      endDate: moment()
    }

  };
  appliedFilter: IFilter;
  users: Array<any> = [];
  providers: Array<any> = [];
  note_types: Array<any> = [];
  facilities: Array<any> = [];
  patients: Array<any> = [];
  uniqueProviders: any = [];
  status = [
    { title: 'Unsigned' },
    { title: 'Signed' },
    { title: 'Uploaded' }
  ];
  filteredProviders: Observable<string[]>;


  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };

  currentDate = new Date();
  notesToShow = null;
  currentRouteUrl: any;
  charges: any = [];
  provider: any;
  facility: any;
  groupedDates: string[];
  groupedData: any = [];
  groupedCharges: any = [];
  dataSource: any = new MatTableDataSource([])
  totalRecords: number = 0;
  fitlerButtonClicked: boolean = false;
  censuses: any = [];
  showPccLogo: boolean = false;
  currentCompany: any = {};
  selectedUser: any;

  constructor(
    private _dictationService: AdminDictationsService,
    private _transcriptionCreateService: TranscriptionCreateService,
    private _authService: AuthService,
    private toastr: ToastrService,
    private _router: Router,
    private route: ActivatedRoute,
    private billerDashboardService: BillerDashboardService,
    private _censusNotesServiceService: CensusNotesServiceService,
    private dialog: MatDialog,
    private facilityService: FacilityService,
    public userTypesRelationshipService: UserTypesRelationshipService,
    private _commonService: CommonService,
    private _userService: UserService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService
  ) {
    if (this._commonService.getLocalStorageItem('admin-Dashboard'))
      this.currentCompany.company_type = this._commonService.getLocalStorageItem('admin-Dashboard').currentCompanyType;
  }

  async ngOnInit() {
    if (window.location.pathname.includes('company') && !window.location.pathname.includes('manage-companies')) {
      this.company_id = this.route.parent.parent.snapshot.params.id;
    }
    else if (window.location.pathname.includes('manage-companies')) {
      this.company_id = this.route.parent.parent.snapshot.params.id;
    }
    if (this.currentCompany.company_type === global.COMPANY_TYPE.SNF){
      await this.initAssociatedSnfWcNurseList();
    }
    else{
      await this.getProviderList();
    }
      const isDOSFilterObj = JSON.parse(localStorage.getItem('census_dos_filter_obj'));
      if (isDOSFilterObj) {
        this.filter.date_of_service = {
          startDate: moment(moment(isDOSFilterObj.startDate).toLocaleString()),
          endDate: moment(moment(isDOSFilterObj.endDate).toLocaleString()),
      };
      }
      const isProviderFilter = JSON.parse(localStorage.getItem('census_provider_filter'));
      if (isProviderFilter) {
        this.initFacilities(isProviderFilter);
        this.provider = isProviderFilter;
        this.filter.provider_id = isProviderFilter._id;
        this.providerControl.setValue(isProviderFilter.full_name);
      }
      const isFacilityFilter = JSON.parse(localStorage.getItem('census_facility_filter'));
      if (isFacilityFilter) {
        this.facility = isFacilityFilter;
        this.filter.facility_id = isFacilityFilter._id;
        if (isFacilityFilter.pcc_facId || isFacilityFilter.source === 'PointClickCare') {
          this.showPccLogo = true;
        } else {
          this.showPccLogo = false;
        }
        this.facilityControl.setValue(isFacilityFilter.title);
      }
      if (isProviderFilter || isFacilityFilter) {
        this.isVisibleDetailTable = false;
        this.isVisibleTable = true;
        this.fitlerButtonClicked = true;
      }
    this.initFacilities();
    this.initCensusChargeReport();
    this.filteredProviders = this.providerControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => this.filterProvider(value)));
    this.filteredFacilities = this.facilityControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => this.filterFacilities(value)));

  }
  async initAssociatedSnfWcNurseList() {
    const filter = {
      company_id: this.company_id,
      user_type: global.USER_TYPE.SNF_WC_NURSE
    };
    const project = {
      first_name: 1,
      last_name: 1,
      title: 1,
      full_name: 1
    };
    const response: any = await lastValueFrom(this._userService.getUsers(filter, project));
    if (response.status === 200) {
      this.providers = response.data;
    }
  }

  clearProviderFilter() {
    this.provideModel = '';
    this.providerControl.setValue('');
    this.initFacilities();
  }
  clearFacilityFilter() {
    this.facilityMOdel = '';
    this.filter.facility_id = null;
    this.facility = null;
    this.facilityControl.setValue('');
    this.showPccLogo = false;
  }
  getProvControlStatus() {
    if (this.providerControl?.value && (this.providerControl?.value != '')) return true; else return false;
  }
  getFacControlStatus() {
    if (this.facilityControl?.value && (this.facilityControl?.value != '')) return true; else return false;
  }
  sortFirstNameVise(arrayTosort) {
    let users = arrayTosort;
    let result = users.sort(function (a, b) {
      if (a.first_name?.toLowerCase() < b.first_name?.toLowerCase()) { return -1; }
      if (a.first_name?.toLowerCase() > b.first_name?.toLowerCase()) { return 1; }
      return 0;
    })
    return result;
  }

  private filterFacilities(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      if (value.length == 1) {
        this.showPccLogo = false;
      }
      return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) === 0);
    }
    else {
      return this.facilities;
    }
  }

  private filterProvider(value: string): string[] {
    if (value && this.providers.length > 0) {
      const filterValue = value.toLowerCase();
      let users = this.providers.filter(option => (option.middle_name && option.middle_name.toLowerCase().indexOf(filterValue) === 0) || option.last_name.toLowerCase().indexOf(filterValue) === 0 || option.first_name.toLowerCase().indexOf(filterValue) === 0);
      return this.sortFirstNameVise(users);
    }
    else {
      return this.sortFirstNameVise(this.providers);
    }


  }

  initCensusChargeReport() {
    this.filter.company_id = this.company_id;
    this._censusNotesServiceService.getCensusChargesReport(this.filter).subscribe((response: any) => {
      if (response.status === 200) {
        this.censuses = response.data;
        this.totalRecords = 0;
        for (const group of this.censuses) {
          if (Array.isArray(group?.groupedData)) {
            for (const item of group?.groupedData) {
              this.totalRecords += item.census.length;
            }
          }
        }
        this.censuses = this.sortCensusesByCreationDate(this.censuses);
      }
    });
  }
  sortCensusesByCreationDate(censuses) {
    var byDate = censuses.slice(0);
    byDate.sort(function (a, b) {
      return a._id < b._id ? 1 : a._id > b._id ? -1 : 0;
    });
    return byDate;
  }
  async getProviderList() {
    const response: any = await lastValueFrom(this.userTypesRelationshipService.getDoctorsList(null, this.company_id, null, null, null, null, null, true));
    if (response.status === 200) {
      this.providers = response.data;
    }
  }

  normalizeProviders() {
    this.providers = this.sortFirstNameVise(this.providers);
    this.filteredProviders = this.providerControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => this.filterProvider(value)));
  }

  sortProviders(a, b) {
    if (a.first_name < b.last_name) {
      return -1;
    }
    if (a.first_name > b.last_name) {
      return 1;
    }
    return 0;
  }
  async initFacilities(provider = null) {
    let response: any = {};
    if (provider) {
      response = await this._transcriptionCreateService.getFacilities(provider._id).toPromise();
    } else {
      const assoc_company_ids = this.company_id;

      const filter = {
        is_active: true,
        assoc_company_ids
      }
      const projection = {
        'title': 1,
        'address': 1,
        'city': 1,
        'state': 1,
        'zip_code': 1,
        'source': 1,
        'provider_ids_ref': 1,
        'assoc_company_ids': 1,
      }

      let companyAssocPopulation: facilityPopulationInterface = {
        collection: 'companies',
        field: ["assoc_company_ids"],
        filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: [true, { $in: ['$_id', '$$assoc_company_ids'] }] }] } },
        project: { name: 1 },
      };

      response = await this.facilityService.getFacilities(filter, projection, [companyAssocPopulation]).toPromise();
      this.provider = null;
      this.filter.provider_id = null;
    }
    if (response.status === 200) {
      this.facilities = response.data.array;
      this.filteredFacilities = this.facilityControl.valueChanges.pipe(
        startWith(''),
        map((value: any) => this.filterFacilities(value))
      );
      if (this.filter?.facility_id && this.facilities.length > 0) {
        const alreadyexist = this.facilities.filter((facility) => facility?._id?.toString() == this.filter?.facility_id?.toString());
        if (!alreadyexist.length) {
          this.clearFacilityFilter();
        }
      }
    }
  }



  async getAssociatedSnfWcNurseFacilities(snfWcNurse) {
    const filter = {
      associated_snf_wc_nurse_ids: snfWcNurse._id,
      company_id: this.company_id
    };
    const nurseProjection = {
      first_name: 1,
      last_name: 1,
      full_name: 1,
      title: 1,
    };
    const facilityProjection = {
      title: 1,
      pcc_facId: 1,
      pcc_2_legged_authentication: 1,
      source: 1,
      pcc_orgUuid: 1
    };
    const response: any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
    if (response.status === 200 && response.data.length > 0) {
      this.facilities = response.data;
      this.filteredFacilities = this.facilityControl.valueChanges.pipe(
        startWith(''),
        map((value: any) => this.filterFacilities(value))
      );
    }
  }

  changeServiceDate(event) {
    if (event.startDate && event.endDate) {
      let census_dos_filter_obj = {
        startDate: event.startDate,
        endDate: event.endDate
      }
      localStorage.setItem('census_dos_filter_obj', JSON.stringify(census_dos_filter_obj));
      this.filter.date_of_service = {
        startDate: null,
        endDate: null
      }
      this.filter.date_of_service.startDate = event.startDate;
      this.filter.date_of_service.endDate = event.endDate;
      const noOfDays = this._commonService.getDifferenceOfDates(event.startDate, event.endDate,'days');
      if (noOfDays > 30) {
        this.serviceDateFilter = false;
        this.filter.date_of_service.endDate = moment(this.filter.date_of_service?.startDate).add(29, "days").startOf('day');
        this.toastr.error('Can not select more than 30 days', 'ERROR');

        setTimeout(() => {
          this.serviceDateFilter = true;
        }, 300)
      }
    }
  }

  onSelectProvider(item = null) {
    if (item) {
      this.selectedUser = item;
      if (this.currentCompany.company_type === global.COMPANY_TYPE.SNF)
        this.getAssociatedSnfWcNurseFacilities(item);
      else
        this.initFacilities(item);
      this.provider = item;
      this.filter.provider_id = item._id;
      const ProviderValue = {
        _id: item._id,
        full_name: item.full_name
      }
      localStorage.setItem('census_provider_filter', JSON.stringify(ProviderValue));
    }
  }
  onSelectFacility(item = null) {
    if (item) {
      this.facility = item;
      this.filter.facility_id = item._id;
      const FacilityValue = {
        _id:item._id,
        title:item.title
      }
      localStorage.setItem('census_facility_filter', JSON.stringify(FacilityValue));
      if (item.pcc_facId || item.source === 'PointClickCare') {
        this.showPccLogo = true;
      } else {
        this.showPccLogo = false;
      }
    }
  }

  changeCreationDate(event) {
    const noOfDays = this._commonService.getDifferenceOfDates(event.startDate, event.endDate,'days');
    if (noOfDays > 30) {
      this.creationDateFilter = false;
      this.filter.creation_date.endDate = moment(this.filter.creation_date.startDate).add(29, "days").startOf('day');
      this.toastr.error('Can not select more than 30 days', 'ERROR');

      setTimeout(() => {
        this.creationDateFilter = true;
      }, 300)
    }
  }
  getDate(date) {
    return moment(new Date(date)).format('MMM DD, YYYY');
  }
  totalCount(groupedData) {
    if (Array.isArray(groupedData)) {
      let totalCount = 0;
      for (let i = 0; i < groupedData.length; i++) {
        totalCount += groupedData[i]?.chargeList?.count;
      }
      return totalCount;
    }
  }
  handleApplyFilterClick() {
    this.isVisibleDetailTable = false;
    this.isVisibleTable = true;
    this.fitlerButtonClicked = true;
    this.initCensusChargeReport();
  }
  resetFilter() {
    this.chargeStatus = 'default';
    this.fitlerButtonClicked = false;
    this.provider = undefined;
    this.facility = undefined;
    this.showPccLogo = false;
    this.isVisibleDetailTable = false;
    this.isVisibleTable = true;
    delete this.filter?.creation_date;
    delete this.filter?.provider_id;
    delete this.filter?.facility_id;
    delete this.filter?.status;
    this.filter.date_of_service = {
      startDate: moment().subtract(7, 'days'),
      endDate: moment(),
    }
    this.filter.creation_date = null;
    this.providerControl.setValue("");
    this.facilityControl.setValue("");
    this.initCensusChargeReport();
    localStorage.removeItem('census_dos_filter_obj');
    localStorage.removeItem('census_provider_filter');
    localStorage.removeItem('census_facility_filter');
  }
  getVisitDate(date) {
    return moment(new Date(date).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })).format('MMM DD, YYYY h:mm a');
  }
  getNotesCount(census) {
    let [signedCount, savedCount] = [0, 0];
    if (Array.isArray(census?.notes) && census.notes.length > 0) {
      for (const note of census.notes) {
        if (note?.note_status?.status === "in_progress" || note?.note_status?.status === "internal_qa" || note?.note_status?.status === "external_qa" || note?.note_status?.status === "draft_save" || note?.note_status?.status === "published") {
          savedCount++;
        }
        else if (note?.note_status?.status === "signed" || note?.note_status?.status === "uploaded" || note?.note_status?.status === "not_uploaded") {
          signedCount++;
        }
      }
      return `${savedCount} / ${signedCount}`;
    }
    else {
      return `${savedCount} / ${signedCount}`;
    }
  }
  getChargesCount(census) {
    let [draftCount, submittedCount] = [0, 0];
    if (Array.isArray(census.charges) && census.charges.length > 0) {
      for (const charge of census.charges) {
        if (charge.status === "draft") {
          draftCount++;
        }
        else if (charge.status === "submit") {
          submittedCount++;
        }
      }
      return `${draftCount} / ${submittedCount}`;
    }
    else {
      return `${draftCount} / ${submittedCount}`;
    }
  }
  showStatus(census) {
    let submittedCount: any = 0;
    let signedCount: any = 0;
    if (Array.isArray(census?.charges) && census.charges.length > 0) {
      submittedCount = census.charges.reduce((accumulater, current) => current.status === "submit" ? ++accumulater : accumulater, submittedCount);
    }

    if (Array.isArray(census?.notes) && census.notes.length > 0) {
      signedCount = census.notes.reduce((accumulater, current) => {
        return (current?.note_status?.status === "signed" ||
          current?.note_status?.status === "uploaded" ||
          current?.note_status?.status === "not_uploaded") ? ++accumulater : accumulater
      }, signedCount)
    }
    if (submittedCount === signedCount) {
      return 'green';
    }
    else if (submittedCount < signedCount) {
      return 'red';
    }
    else if (submittedCount > signedCount) {
      return 'yellow';
    }
  }
  redirectToCensusScreen(census) {
    // if (this._authService.currentUser._id === census?.createdBy?._id && census?._id) {
    this._router.navigate([`/census/${census._id}`]);
    // }
  }
  redirectToChargeScreen(census) {
    // if (this._authService.currentUser._id === census?.createdBy?._id) {
    this._router.navigate([`company/${this.company_id}/user-management/doctor-profile`, { provider_id: this._authService.currentUser._id, census: true, facility_id: census.facility._id }]);
    // }
  }

  exportCensusReportAsExcel() {
    this._censusNotesServiceService.exportCensusReportAsExcel(this.filter, 'census', this.currentCompany.company_type).subscribe((data: any) => {
      if (data instanceof HttpResponse) {
        // console.log("in httpresponse")
        var url = window.URL.createObjectURL(data.body);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = 'Census-Report';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }
      else {
        // console.log("in else")
        if (data.type === 0) {
        } else if (data.type === 3) {
          const { loaded, total } = data;
          const progress = Math.round(loaded / total * 100);
          if (progress > 0) {
          }
        }
      }
      if (data.status == 200) {
        // console.log("in status")
        return true;
      }
    })
  }
}
