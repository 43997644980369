import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { NoteEditorService } from '../note-editor/note-editor.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-dialog-email-sending-component',
  templateUrl: './dialog-email-sending-component.component.html',
  styleUrls: ['./dialog-email-sending-component.component.css']
})
export class DialogEmailSendingComponentComponent implements OnInit {

  title:String;
  reason:String='All treatment completed';
  facility_id:any;
  shouldReturnEmailIds: boolean = false;
  constructor(public dialogRef: MatDialogRef<DialogEmailSendingComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService,
    private noteEditorService: NoteEditorService,
    private loader: NgxUiLoaderService,
    private companyService: CompanyService,) { 
      this.shouldReturnEmailIds = Boolean(data.shouldReturnEmailIds);
    }
  output:any;
  emails:any;
  note_id:any;
  finalEmails:any = [];
  mail_loader = 'mail_loader';
  ngOnInit(): void {
    this.emails=[];
    this.finalEmails=[];
    this.title = this.data.message;
    this.facility_id=this.data.facility_id;
    this.note_id=this.data.note_id;

    console.log("this.data", this.data);
    
    this.companyService.getFacilityContacts({ facility: this.facility_id._id?this.facility_id._id:this.facility_id })
    .subscribe(res => {
        console.log("contactsss", res.data.array);
        res.data.array?.forEach((element)=>{
          this.addEmail(element?.email);
        })
    });

 
  }

  handleChangeReason(){

  }

  async filtermails() {
    this.finalEmails=[];
    this.emails.filter((element)=>{
      if(element.check){
        this.finalEmails.push(element.email);
      }
      return;
    });
    console.log("filtered mails : ",this.emails );
    return Promise.resolve(null);
  }

  addEmail(mail){
    console.log("target value : ", mail);
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    let obj={
      email:mail,
      check:true,
    }
    console.log("indexx : ", !(this.emails?.findIndex(e=>e.email?.toString()===mail?.toString())>=0));
    if(!(this.emails?.findIndex(e=>e.email?.toString()===mail?.toString())>=0)){
     if(pattern.test(mail)){
      
        this.emails.push(obj);
     }else{
        this.toastr.error('Please enter a valid email address', 'Invalid Email');
     }
    }else{
      this.toastr.warning('Email already exists, Kindly Review again', 'Warning');

    }
  }

  async handleClose(result){

    if(result){
      await this.filtermails();
      if(this.finalEmails?.length>0 && this.shouldReturnEmailIds){
        this.dialogRef.close(this.finalEmails);
      }
      else if(this.finalEmails?.length>0 && !this.shouldReturnEmailIds){

        this.loader.startLoader(this.mail_loader);
        this.noteEditorService.generatePDFsLinksAndEmail(this.finalEmails, [this.note_id])
          .subscribe((response: any) => {
            this.loader.stopLoader(this.mail_loader);
            if (response.status == 200) {
              this.toastr.info('Email sent');
              this.dialogRef.close();

            }
          })

      }
    }else{
      this.dialogRef.close([]);
    }
    
  }

}
