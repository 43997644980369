import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as global from '../global';
import {API_URL} from "../../../environments/api-url";
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';


@Injectable({
  providedIn: 'root',
})
export class PatientDetailService {
  userInfo:any = {};
  currentUser: any;
  public pccUserinfo;  
  patientAllergy = new BehaviorSubject<any>(null);
  castpatientAllergy = this.patientAllergy.asObservable();
  constructor(private httpclient: HttpClient,private _authService: AuthService) {
    const currentUser = this._authService.currentUser;
    if(currentUser) {
        this.currentUser =  currentUser;
        this.userInfo = {
            usertype: this.currentUser.user_type,
            auth_key: this.currentUser.auth_key,
            id: this.currentUser._id
        }
    }
  }

  onChangePatientAllergy(obj) {
    this.patientAllergy.next(obj);
  }

  getTranscriptionsByPatientId(payload) {
    return this.httpclient.post(global.url + '/getTranscriptionsByPatientId', { patient: payload }).pipe();
  }

  getPatientAllergies(data) {
    return this.httpclient
      .post(global.url + API_URL.PATIENT.getPatientAllergies, data).pipe();
  }
  getAllergyIntolerances(data) {
    return this.httpclient
      .post(global.url + API_URL.PATIENT.getAllergyIntolerances, data).pipe();
  }
  getPatientConditions(data) {
    return this.httpclient
      .post(global.url + API_URL.PATIENT.getPatientConditions, data).pipe();
  }

  addPatientAllergies(data) {
    return this.httpclient
      .post(global.url + API_URL.PATIENT.addPatientAllergies, data).pipe();
  }

  deletePatientAllergies(data) {
    return this.httpclient
      .post(global.url + API_URL.PATIENT.deletePatientAllergies, data).pipe();
  }

  updatePatientAllergies(data) {
    return this.httpclient
      .post(global.url + API_URL.PATIENT.updatePatientAllergies, data).pipe();
  }
  getPatientADTHistory(data) {
    return this.httpclient
      .post(global.url + API_URL.PATIENT.getPatientADTHistory, data).pipe();
  }

  addPatientTabSettings(tabSettings, forPatients) {
    const details = {
      ...this.userInfo,
      tabSettings,
      forPatients
    }
    return this.httpclient.post(global.url + API_URL.USER.setPatientTabSettings, { details: details }).pipe();
  }
  getPatientTabSettings(forPatients) {
    const details = {
      ...this.userInfo,
      forPatients
    }
    return this.httpclient.post(global.url + API_URL.USER.getPatientTabSettings, { details: details }).pipe();
  }
  getCCD(patient) {
    const details = {
      patient,
      ...this.userInfo
    }
    return this.httpclient.post(global.url + API_URL.MATRIXCARE_MIRTH.getCCD, { details: details }).pipe();
  }
}