<form>
  <h1 mat-dialog-title>Upload Files</h1>
  <div mat-dialog-content>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="serial_no">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
          #
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{i+1}}
        </td>
      </ng-container>
      <ng-container matColumnDef="file_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
          File Name
        </th>
        <td mat-cell *matCellDef="let element">
          <input type="text" matInput readonly [value]="element.name">
        </td>
      </ng-container>
      <ng-container matColumnDef="sign">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox name="sign" *ngIf="filesData[element.name].type === 'Sign Form'" name="sign" [(ngModel)]="filesData[element.name].sign">
            Sign
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
          Type
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field>
            <mat-label>Type</mat-label>
            <mat-select name="type" [(ngModel)]="filesData[element.name].type">
              <mat-option value="Medical Record">
                Medical Record
              </mat-option>
              <mat-option value="Cover Letter">
                Cover Letter
              </mat-option>
              <mat-option value="Sign Form">
                Sign Form
              </mat-option>
              <mat-option value="Other">
                Other
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div mat-dialog-actions class="flex gap-5 justify-end items-center">
    <button mat-raised-button class="dn-button-secondary" mat-dialog-close type="reset">Cancel</button>
    <button mat-raised-button class="dn-button-primary" (click)="upload()">Upload</button>
  </div>
</form>