
<app-header *ngIf="!admin && !company"></app-header>

<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

<main>
  <div class="container" *ngIf="scriptsureLaunchUrl != null">
    <div class="scriptsure-block">
      <button class="dn-button-secondary" mat-raised-button (click)="onClose()">Close</button>
      <iframe
        frameBorder="0"
        [src]="scriptsureLaunchUrl | safe"
      ></iframe>
    </div>
  </div>
</main>
