import { Component,Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TranscriptionCreateService } from '../transcription-create.service';
import * as global from '../../global';
import { NgxUiLoaderService } from 'ngx-ui-loader';
// UPLOAD FILE DIALOG
export interface ViewNoteAddendumDialog {
  type;
  noteId;
  rawText:string;
}

@Component({
  selector: 'app-view-note-addendum-dialog',
  templateUrl: './view-note-addendum-dialog.component.html',
  styleUrls: ['./view-note-addendum-dialog.component.css']
})
export class ViewNoteAddendumDialog implements OnInit {
  global = global;
  
  addendum;

  loaderId = 'view-note-addendum-loader';
  
  constructor(
    public dialogRef: MatDialogRef<ViewNoteAddendumDialog>,
    // private loader: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: ViewNoteAddendumDialog) { 
      if(data) {
        this.addendum = data.addendum;
         
      }
    }
    
    ngOnInit(): void {
    }

  
}
