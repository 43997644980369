<h3>
  Note
</h3>
<div class="flex flex-col gap-2" style="font-size: 12px;">


  <div class="flex flex-col">
    <div>
      <u><strong>Facility: </strong></u>
      <span>{{facility?.title}}</span>
    </div>
    <div>
      <u><strong>Created By: </strong></u>
      <span>{{pccNote?.createdBy}}</span>
    </div>
    <div>
      <u><strong>Patient: </strong></u>
      <span>{{patient.last_name}}, {{patient.first_name}} {{patient.middle_name}}</span>
    </div>
    <div>
      <u><strong>Created Date: </strong></u>
      <span>{{pccNote.createdDate | date: 'MM/dd/yyyy hh:mm a'}}</span>
    </div>

    <div>
      <u><strong>Note Type: </strong></u>
      <span>{{pccNote?.progressNoteType | uppercase}}</span>
    </div>
  
    <ng-container *ngIf="isLoading">
      <mat-progress-spinner diameter="20" mode="indeterminate">
      </mat-progress-spinner>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <div *ngFor="let section of pccNote.sections">
        <u><strong>{{section.name}}:</strong></u>
        <span *ngIf="!isNoteTextSection(section.name)">{{section.value}}</span>
        <div style="position: relative;" *ngIf="isNoteTextSection(section.name)">
          <pre style="max-height: 65vh;overflow: auto;" [innerHTML]="section.value" style="min-height: 64px;"></pre>
          <mat-icon style="position: absolute;top:0;right:20px;" (click)="copyToClipboard(section.value)"
          matTooltip="Copy to Clipboard" matTooltipPosition="above">
            copy_all
          </mat-icon>
        </div>
      </div>     
    </ng-container>

  </div>


  <div class="flex gap-5 justify-end">
    <button mat-raised-button class="dn-button-secondary" mat-dialog-close>Close</button>
  </div>
  <div class="top-right-close-btn">
      <button mat-mini-fab mat-dialog-close ><mat-icon >cancel</mat-icon></button>
    </div>

</div>