import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {AuthService} from "src/app/services/auth.service";
import { isArray } from 'util';
import {API_URL} from "../../../environments/api-url";
import * as global from '../global';
import moment from 'moment'
import { OpenReleaseDialogComponent } from '../../includes/open-release-dialog/open-release-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ReleaseService } from './release.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OpenPostDialogComponent } from '../open-post-dialog/open-post-dialog.component';
import { AdminNotificationsService } from '../admin-notifications-settings/admin-notification.service';
import { Location } from '@angular/common';


export interface notes {
  createdAt: string;
  note: string;
}

@Component({
  selector: 'app-release',
  templateUrl: './release.component.html',
  styleUrls: ['./release.component.css']
})
export class ReleaseComponent implements OnInit {

  currentUser: any;

  // displayedColumns: string[] = ['createdAt', 'note'];
  transcriptionsGroupingKey: string = 'createdAt';
  // dataSource = new MatTableDataSource();
  dataSource : any;
  // @ViewChild(MatSort) sort: MatSort;
  notes: any;
  admin = false;
  selectedTabIndex: number = 0;
  loaderId = 'releaseLoader';
  webReleases: any;
  mobileReleases: any;
  constructor(  private httpclient: HttpClient,
                private _authService: AuthService,
                private dialog: MatDialog,
                private toastr: ToastrService,
                private router: Router,
                private releaseService: ReleaseService,
                private loader: NgxUiLoaderService,
                private _adminNotificationsService:AdminNotificationsService,
                private _location: Location,
                ) { }

  ngOnInit(): void {
    // this.dataSource.sort = this.sort;
    this.currentUser =JSON.parse(localStorage.getItem('currentUser'))

    if(window.location.pathname.includes('company')){
      this.admin = true;
    }
    if (!this.admin) {
      const container = document.getElementById('releaseContainer');
      container.setAttribute('style', 'padding-top: 5px !important;');
    }
    this.showWebReleases();
  }

openReleaseDialog(addNotes){
  const dialogRef = this.dialog.open(OpenReleaseDialogComponent, {
      width: '800px',
      data: addNotes
    });
    dialogRef.afterClosed().subscribe(async (addNotes) => {
      let noteType = '';
      if (this.selectedTabIndex === 0) noteType = 'web';
      if (this.selectedTabIndex === 1) noteType = 'mobile';
      if (addNotes) {
        this.releaseService.addNotes(addNotes,noteType).subscribe((response: any) => {
          if (response.status == 200) {
            // console.log('response', response);
            this.toastr.success('Saved');
            if (this.selectedTabIndex === 0) this.showWebReleases();
            if (this.selectedTabIndex === 1) this.showMobileReleases();
          }
        });
      } else {
        console.log('something went wrong!');
      }

    });
}
openReleaseDialogForEdit(note,noteId){
  const dialogRef = this.dialog.open(OpenReleaseDialogComponent, {
    width: '800px',
    data: note
  });
  dialogRef.afterClosed().subscribe(async (addNotes) => {
      console.log('addnotes',addNotes);
    if (addNotes) {
      this.releaseService.editNote(addNotes,noteId._id).subscribe((response: any) => {
        if (response.status == 200) {
          // console.log('response', response);
          this.toastr.success('Saved');
          if (this.selectedTabIndex === 0) this.showWebReleases();
          if (this.selectedTabIndex === 1) this.showMobileReleases();
          
        }
      });
    } else {
      console.log('something went wrong!');
    }

  });
}

  deleteNote(id,index) {
    this.releaseService.deleteNote(id).subscribe((response: any) => {
      if (response.status == 200) {
        // console.log('response', response);
        this.toastr.success('Deleted Successfully');
        if (this.selectedTabIndex === 0) this.webReleases.splice(index, 1);
        if (this.selectedTabIndex === 1) this.mobileReleases.splice(index, 1);
      }
    });
  }
  tabChanged($event) {
    this.selectedTabIndex = $event.index;
    if (this.selectedTabIndex == 0) this.showWebReleases();
    if (this.selectedTabIndex == 1) this.showMobileReleases();
  }
  showWebReleases() {
    this.loader.startLoader('releaseLoader');
    this.releaseService.getNotes('web').subscribe((response: any) => {
      if (response.status == 200) {
        this.webReleases = response.data;
        this.loader.stopLoader('releaseLoader');
      } else {
        console.log('something went wrong!');
      }
    });
  }
  showMobileReleases() {
    this.loader.startLoader('releaseLoader');
    this.releaseService.getNotes('mobile').subscribe((response: any) => {
      if (response.status == 200) {
        this.mobileReleases = response.data;
        this.loader.stopLoader('releaseLoader');
      } else {
        console.log('something went wrong!');
      }
    });
  }
goBack() {
  this._location.back();
}

  openReleaseNotesDialog(addNotes, noteId) {
    addNotes['isShareReleaseNotes'] = true;
    if (this.selectedTabIndex == 0)
      addNotes['noteType'] = 'web';
    if (this.selectedTabIndex == 1)
      addNotes['noteType'] = 'mobile';

    const dialogRef = this.dialog.open(OpenPostDialogComponent, {
      width: '800px',
      data: addNotes
    });
    dialogRef.afterClosed().subscribe(async (addNotes) => {});
  }


}
