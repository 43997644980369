import { Component, OnInit } from '@angular/core';
import { FeedbackService } from '../feedback/feedback.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogFeedbackComponent } from './dialog-feedback/dialog-feedback.component';


@Component({
  selector: 'app-admin-feedback',
  templateUrl: './admin-feedback.component.html',
  styleUrls: ['./admin-feedback.component.css']
})
export class AdminFeedbackComponent implements OnInit {
  data = {};
  result: any;
  constructor(private service: FeedbackService, private dialog: MatDialog) { }
  displayedColumns: string[] = ['Sr No.','Name', 'Issue Type','Comment','Status'];
  dataSource;


  ngOnInit(): void {
    this.getData();
  }
  getData() {
    this.service.getData(this.data).subscribe((res:any) => {
      // console.log(res.data);
      this.result = res.data;
      this.dataSource = this.result;
    });
  }
  openDialog(row: any) {
    this.dialog.open(DialogFeedbackComponent, {
    data: row,
    height: '55%',
    width: '55%',
    }); 
  }
}
