// import { Injectable } from '@angular/core';
// import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
// import { Observable } from 'rxjs';
// import { AuthService } from 'src/app/services/auth.service';

// @Injectable({
//   providedIn: 'root'
// })
// export class AuthenticationGuard implements CanActivate {
//   constructor(
//     private _authService: AuthService,
//     private _router: Router,
//   ) { }
//   canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//     if (this._authService.currentUser) {
//       return true;
//     }
//     else {
//       this._router.navigate(['/login']);
//       return false;
//     }
//   }
//   canActivateChild(
//     next: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//     if (this._authService.currentUser) {
//       return true;
//     }
//     else {
//       this._router.navigate(['/login']);
//       return false;
//     }
//   }

// }


import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs'
import { AuthService } from 'src/app/services/auth.service';

export interface IDeactivateComponent {
  canExit: () => boolean | Observable<boolean> | Promise<boolean>;
}


@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard {
  authService: AuthService = inject(AuthService);
  router: Router = inject(Router);
  constructor(
    private _authService: AuthService,
    private _router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean | Observable<boolean> | Promise<boolean> {
    if (this._authService.currentUser) {
      return true;
    }
    else {
      this._router.navigate(['/login']);
      return false;
    }
  }


  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }

  canDeactivate(component: IDeactivateComponent, currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot, nextState: RouterStateSnapshot) {
    return component.canExit();
  }
}