import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { ProviderPhraseAssociationService } from '../phrases/phrases-association/phrases-association.service';

@Component({
  selector: 'app-medical-assistant-association',
  templateUrl: './medical-assistant-association.component.html',
  styleUrls: ['./medical-assistant-association.component.css']
})
export class MedicalAssistantAssociationComponent implements OnInit {


  displayedColumns: string[] = ['providers', 'medical_assistants'];
  public dataSource = [];
  public providers: any = [];
  public relationData: any;
  medicalAssistantDropdownSettings = {};
  company_id: any;
  providerPhrase: Boolean = true;

  selectedItems = [];
  dropdownList = [];
  medicalAssistants: any = [];
  selectedProvider: any;

  constructor(
    public services: UserTypesRelationshipService,
    private route: ActivatedRoute,
    private phraseAssociateService: ProviderPhraseAssociationService,
    private toastr: ToastrService
  ) { }



  async ngOnInit() {

    this.dropdownList = [];
    this.selectedItems = [];

    this.medicalAssistantDropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'full_name',
      selectAllText: 'Select All',
      enableCheckAll: true,
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 'All',
      allowSearchFilter: true
    };


    if (window.location.pathname.includes('company')) {
      this.company_id = this.route.parent.snapshot.params.id
    }
    else {
      this.company_id = '';
    }
    await this.loadDataForProvider();
  }

  async loadDataForProvider() {
    const filter: any = {
      company_id: this.company_id,
    };
    const projection = {
      'title': 1,
      'first_name': 1,
      'last_name': 1,
      'full_name': 1
    }
    let res = await this.phraseAssociateService.getAllSameCompanyProviders(filter,projection).subscribe((data: any) => {
      if (data.status == 200) {
        this.loadDataForMAs();
        this.providers = data;
        this.providers = this.providers.data;
        this.dataSource = this.providers;
        // console.log('providers',this.dataSource);
      }
    });
  }

  async loadDataForMAs() {
    this.services.getMAList('true', this.company_id).subscribe((res: any) => {
      // console.log("response",res.data);
      if (res.status == 200) {
        this.loadRelations();
        this.medicalAssistants = res.data;
        this.medicalAssistants.forEach(element => {
          element['full_name'] = element['first_name'] + ' ' + element['last_name'];
        });
        // console.log('medical assistants', this.medicalAssistants);
        this.providers.forEach(provider => {
          provider.medicalAssistants = this.medicalAssistants;
        });
      }
    });
  }

  async loadRelations() {
    this.services.getMAAssociations(this.company_id).subscribe((res: any) => {
      if (res.status == 200) {
        // console.log('response loadRelations',res);
        let associatedMAs = res.data;
        //console.log('response',associatedPhrases);
        this.providers.forEach(provider => {
          // console.log('provider',provider);
          associatedMAs.forEach(ma => {
            //console.log('Phrases',phrase);
            if (ma.provider_id == provider._id) {
              if (ma?.assoc_ma_ids) {
                ma.assoc_ma_ids = ma.assoc_ma_ids.map(data => {
                  data.full_name = data.first_name + ' ' + data.last_name
                  return data;
                }
                );

                provider.assoc_ma_ids = ma.assoc_ma_ids;
              }
              else {
                provider.assoc_ma_ids = [];
              }
            }
          });
        });
        // console.log("in ma",this.providers);
      }
    });
  }

  getMarker() {
    //return this.phrasesService.marker
  }

  onDataSelection($event) {
    // let providers = element.associated_provider_ids.map(a => a._id);
    // let idx = this.users.findIndex(user => user._id.toString == element._id.toString());
    // this.users[idx] = element;
    // this.dataSource.data = this.users;
    if($event.data == 'selectAll') {
      this.onSelectAll($event.element.assos_providers, $event.element);

    }
    else if($event.data == 'unSelect') {
      this.onDeSelectAll([], $event.element);
    }
    else {
      if(!$event.status) {
        this.onMADeSelect($event.data, $event.element);
      }
      else {
        this.onMASelect($event.data, $event.element);
      }
    }
  }

  onDataRemove($event) {
    this.onMADeSelect($event.data, $event.element);
  }

  onSelectAll(event, provider) {
    const details = {
      ProviderID: provider._id,
      MA_ID: provider.assoc_ma_ids.map(a => a._id ),
      company_id: this.company_id
    }
    this.createRelationProvider2MA(details);
  }

  onDeSelectAll(event, provider) {
    const details = {
      ProviderID: provider._id,
      MA_ID: [],
      company_id: this.company_id
    }
    this.createRelationProvider2MA(details);
  }

  onMASelect(phrase, selectedProvider) {

    const details = {
      ProviderID: selectedProvider._id,
      MA_ID: phrase._id,
      // company_id: selectedProvider.company_id
      company_id: this.company_id
    }
    this.createRelationProvider2MA(details);
  }
  createRelationProvider2MA(details) {
    this.services.createRelationProvider2MA(details).subscribe((response: any) => {
      if (response.status == 200) {
        // console.log('success');
        this.toastr.success("Relationship updated");
      }
    })
  }

  onMADeSelect(phrase, selectedProvider) {
    const details = {
      ProviderID: selectedProvider._id,
      MA_ID: phrase._id,
      // company_id: selectedProvider.company_id
      company_id: this.company_id
    }
    this.deleteRelationProvider2MA(details);
    selectedProvider.assoc_ma_ids = selectedProvider.assoc_ma_ids.filter(pro=> pro._id.toString() !== phrase._id.toString())
  }

  deleteRelationProvider2MA(details) {
    this.services.deleteRelationProvider2MA(details).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("Relationship updated");
      }
    })
  }


  applyFilter(filterValue: string) {
    let filter = filterValue.trim().toLowerCase();
    if (filter) {
      let filterVal = [];
      this.providers.forEach(provider => {
        if (provider.full_name.trim().toLowerCase().includes(filter)) {
          filterVal.push(provider)
          this.dataSource = filterVal;
        }
      });
    }
    else {
      this.dataSource = this.providers;
    }
  }

}
