<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

    <ng-container matColumnDef="checkbox">
        <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
        </th>
        <td mat-cell *matCellDef="let element" style="width: 50px;">
            <mat-checkbox 
            (change)="toggleICDSelection(element);" 
            name="icd_fvrt"
            [disabled]="shouldDisableCheckbox()"
            [checked]="icdSelected(element)">
            </mat-checkbox>
        </td>
    </ng-container>
    <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            <div [class.pdpm_highlight]="element.pdpm_status">
                {{element.code}}
                <span>({{element.description}})</span>
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="fvrt">
        <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
        </th>
        <td mat-cell *matCellDef="let element" style="width: 50px;">
            <span *ngIf="element.fvrt === true"
            (click)="$event.preventDefault();$event.stopPropagation();toggleFavouriteDiagnoses(element,'remove')"
            style="cursor: pointer" class="material-icons">
            star
        </span>
        <span class="material-icons" *ngIf="element.fvrt !== true"
            (click)="$event.preventDefault();$event.stopPropagation();toggleFavouriteDiagnoses(element)"
            style="cursor: pointer">star_outline</span>
        </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>