<div class="form-height">
    <div class="form-distance flex justify-center items-center"><h2>Patient Edit Form</h2></div>
<form (ngSubmit)="updatePatient()" #form="ngForm" class="flex flex-col justify-center items-start" style="margin-right: 20px;">
    <mat-form-field class="form-height-spacing">
        <mat-label>Admission Date</mat-label>
        <input required matInput [matDatepicker]="picker" (onClick)="picker.open()" [min]="minDate" [max]="maxDate"
            name="admissionDate" [(ngModel)]="model.lastAdmitDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker startView="multi-year"></mat-datepicker>
        <mat-error *ngIf="!model.lastAdmitDate">Please enter admission Date </mat-error>
    </mat-form-field>
    <mat-form-field class="form-height-spacing">
        <mat-label>Primary Practitioner Name</mat-label>
        <input matInput type="text" name="primaryPractitionerFullName" #primaryPractitionerFullName="ngModel"
            [(ngModel)]="model.primaryPractitionerFullName">
        <mat-error
            *ngIf="primaryPractitionerFullName.invalid  && (primaryPractitionerFullName.dirty || primaryPractitionerFullName.touched)">
            Please enter Primary Practitioner Name
        </mat-error>
    </mat-form-field>

    <mat-form-field class="form-height-spacing">
        <mat-label>Room</mat-label>
        <input matInput type="text" name="roomNo" #roomNo="ngModel" [(ngModel)]="model.roomNo">
        <mat-error *ngIf="roomNo.invalid  && (roomNo.dirty || roomNo.touched)">
            Please enter Room No
        </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="model.source=='MatrixCare'&&data?.matrix_payload" class="form-height-spacing">
            <mat-label>Wing Number</mat-label>
            <input matInput type="text" name="wingNum" #wingNum="ngModel" [(ngModel)]="model.wingNum">
            <mat-error *ngIf="wingNum.invalid  && (wingNum.dirty || wingNum.touched)">
                Please enter Wing Number
            </mat-error>
        </mat-form-field>
    <mat-form-field class="form-height-spacing">
        <mat-label>Payer Name</mat-label>
        <input matInput type="text" name="payerName" #payerName="ngModel" [(ngModel)]="model.payerName">
        <mat-error *ngIf="payerName.invalid  && (payerName.dirty || payerName.touched)">
            Please enter Payer Name
        </mat-error>
    </mat-form-field>

    <div class="flex justify-center items-center gap-7 patients-buttons-margin">
        <button mat-raised-button class="dn-button-secondary" mat-dialog-close type="button">Cancel</button>
        <button mat-raised-button [disabled]="!form.form.valid"
            type="submit" class="dn-button-patientupdate">Update</button>
    </div>
    </form>

</div>