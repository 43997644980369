import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

// import { USER_TYPE } from '../../../../../includes/global'
import { CompanyService } from 'src/app/services/Modules/company.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserService } from 'src/app/services/Modules/user.service';
import { FormControl } from '@angular/forms';
import { USER_TYPE } from 'src/app/includes/global';
import { RegisterService } from 'src/app/includes/register/register.service';

const user_types = {
    'provider': USER_TYPE.DOCTOR,
    'company': USER_TYPE.DOCTOR,
    'transcriber': USER_TYPE.TRANSCRIBER,
    'manager': USER_TYPE.MANAGER,
    'pa': USER_TYPE.PA,
    'nurse': USER_TYPE.NURSE,
    'biller': USER_TYPE.BILLER
}


@Component({
    selector: 'app-company-user-management-form',
    templateUrl: './company-user-management-form.component.html',
    styleUrls: ['./company-user-management-form.component.css']
})
export class CompanyUserManagementFormComponent implements OnInit {
    USER_TYPE = USER_TYPE;
    loaderId = 'company-user-management-form-loader';
    model = {
        user_type: '',
        title: '',
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        speciality: '',
        // password: '',
        company_id: null
    }
    user_id: string;

    titles = ["M.D.", "D.O.", "PA-C", "ARNP", "R.N.", "N.P.", "P.A.", "L.Ac", "DNP", "CWS", "CWSP", "WOCN", "ANP-BC", "CWCN" ];
    titleControl = new FormControl();
    displayProviderFn(title): string {
        return title;
    }
    companies:any = [];
    @Output() cancelClicked: EventEmitter<any> = new EventEmitter<any>(); 

    constructor(private _location: Location,
        private route: ActivatedRoute,
        private companyService: CompanyService,
        private userService: UserService,
        private loader: NgxUiLoaderService,
        private registerService: RegisterService,
        private toastr: ToastrService) {
        this.model.company_id = this.route.parent.parent.snapshot.params.id;
        this.user_id = this.route.snapshot.params.id;

    }

    ngOnInit(): void {
        this.getAllCompanies();
        if (this.user_id) {
            this.loader.startLoader(this.loaderId);

            const filter = {
                _id: this.user_id
            }
            const projection = {
                user_type: 1,
                title: 1,
                first_name: 1,
                last_name: 1,
                email: 1,
                mobile: 1,
                company_id: 1
            }

            this.userService.getUser(filter, projection).subscribe((response: any) => {
                if (response.status === 200) {
                    this.model = response.data;

                    const { user_type } = this.model;
                    for (const key in user_types) {
                        if (user_types[key] == user_type) {
                            this.model.user_type = key;
                            break;
                        }
                    }

                }
                this.loader.stopLoader(this.loaderId);
            })

        }
        
    } 

    goBack() {
        this._location.back();
    }

    getUserType(type) {

        return user_types[type];

    }

    handleSubmit(e) {
        if (!e.valid) return;  
        const user_type = this.getUserType(this.model.user_type);
        const data = {
            ...this.model,
            user_type
        }
        
        try {  
            this.loader.startLoader(this.loaderId);
            
            // e.reset(); 
            // if (this.user_id) {
            //     this.userService.updateUser(data).subscribe((response: any) => {
            //         this.loader.stopLoader(this.loaderId);
            //         this.toastr.success('User updated succesfully', 'Success')
            //     })
            // } else {
                
                this.companyService.addUser(data).subscribe(
                // this.registerService.doRegister(data).subscribe(
                    (res) => {
                        this.loader.stopLoader(this.loaderId); 
                        if (res.status === 200) {
                            this.toastr.success('User added successfully', 'Success');
                            this.cancelClicked.emit();
                            this._location.back();
                            e.reset(); 
                        } else {
                            this.toastr.error('Email already exist', 'Error');
                        }
                    }
                );

            // }
        } catch (error) {
            this.loader.stopLoader(this.loaderId);
            this.toastr.error('Something went wrong', 'Error');
        }
    }
    cancel(){ 
        this.cancelClicked.emit()
    }
    getAllCompanies(){
        this.companyService.getAllCompanies().subscribe((response:any)=>{
            if(response.status == 200){
                // console.log("response: ", response);
                this.companies = response.data
            }
            else if (response.status == 403) {
                this.toastr.error(response.message, "ERROR");
              }
        })
    }
}
