import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IcdCodesService } from 'src/app/services/Modules/icd-codes.service';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-dialogue-manage-icd-codes',
  templateUrl: './dialogue-manage-icd-codes.component.html',
  styleUrls: ['./dialogue-manage-icd-codes.component.css']
})
export class DialogueManageIcdCodesComponent implements OnInit {
  newICDmodel = {
    code: "",
    description: "",
    mediumDescription: "",
    end_date: "",
    pdpm_status: false,
    status: "",
    icd_code_id: ""
  };

  previousData: any;
  object: any;
  isEmptyCodeDescription = false;
  expiry_date = null;
  public current_date = new Date();

  constructor( private dialogRef: MatDialogRef<DialogueManageIcdCodesComponent>, private _ICDcodesService: IcdCodesService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {  
    this.object = data;     // this is done bcz value got changes

    this.setPreviousData(this.object)

  }

  ngOnInit(): void {
    this.setIcdData(this.object);
  }

  setIcdData(data: any){
    this.newICDmodel = {
      code: data.code,
      description: data.description,
      mediumDescription: data.mediumDescription,
      end_date: data.end_date,
      pdpm_status: data.pdpm_status,
      status: data.status,
      icd_code_id: data.icd_code_id ? data.icd_code_id : data._id
    };
    if (this.newICDmodel.end_date != "")
      this.expiry_date = moment(this.newICDmodel.end_date, 'MM/DD/YYYY', true).format();
    
    this.isEmptyCodeDescription = false;
  }

  closeDialog() {
    const data = this.getPreviousData()
    this.dialogRef.close(data);
  }

  setPreviousData(data: any){
    this.previousData = data;
  }

  getPreviousData(){
    return this.previousData;

  }

  checkIsEmpty(){
    if (this.newICDmodel.code.trim().length === 0)
      this.isEmptyCodeDescription = true;
    else if (this.newICDmodel.description.trim().length === 0)
      this.isEmptyCodeDescription = true;
    else
      this.isEmptyCodeDescription = false;

  }

  setPdpm(value: any){
    this.newICDmodel.pdpm_status = value;
  }

  async save() {
    this.checkIsEmpty();
    if (this.expiry_date != null)
      this.newICDmodel.end_date = moment(this.expiry_date).format('MM/DD/YYYY');
    else
      this.newICDmodel.end_date = "";

    let filter = { _id: this.newICDmodel.icd_code_id };
    let query = { description: this.newICDmodel.description, mediumDescription: this.newICDmodel.mediumDescription, code: this.newICDmodel.code, pdpm_status: this.newICDmodel.pdpm_status, end_date: this.newICDmodel.end_date }
    let details = { filter: filter, query: query };
    // if (this.isHide) {               // for user side
    //   filter = { provider_ids_ref: this.data.provider_ids_ref, icd_code_id: this.newICDmodel.icd_code_id };
    //   details = { filter: filter, query: {description: this.newICDmodel.description, mediumDescription: this.newICDmodel.mediumDescription} };
    // }
    // else {
    //   filter = { _id: this.newICDmodel.icd_code_id, isAdminSide: true, company_id: this.data.company_id };
    //   let query = { description: this.newICDmodel.description, mediumDescription: this.newICDmodel.mediumDescription, code: this.newICDmodel.code, pdpm_status: this.newICDmodel.pdpm_status, end_date: this.newICDmodel.end_date }
    //   details = { filter: filter, query: query };
    // }

    let res: any = await lastValueFrom(this._ICDcodesService.updateICD(details));
    if (res.status === 200)
      this.toastr.success("Updated Successfully!")
    else
      this.toastr.warning(res.message);
    this.dialogRef.close(res.data);

  }

  clearDetails() {
    this.newICDmodel = {
      code: this.newICDmodel.code,
      description: "",
      mediumDescription: "",
      end_date: "",
      pdpm_status: false,
      status: "",
      icd_code_id: this.newICDmodel.icd_code_id
    };
    this.expiry_date = null;
    this.isEmptyCodeDescription = true;

  }



}
