import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { lastValueFrom } from 'rxjs';
import { UserService } from 'src/app/services/Modules/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { FacilityService } from 'src/app/services/facility.service';
import * as global from '../../includes/global';
import { WoundNurseRelationService } from 'src/app/services/Modules/woundNurse.service';
import { MatTableDataSource } from '@angular/material/table';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';


@Component({
  selector: 'app-wound-nurse-facility-association',
  templateUrl: './wound-nurse-facility-association.component.html',
  styleUrls: ['./wound-nurse-facility-association.component.css']
})
export class WoundNurseFacilityAssociationComponent implements OnInit {
  companyId: any;
  facilities: any = [];
  loaderId: string = "app-wound-nurse-facility-association";
  displayedColumns: string[] = ['facility', 'associated_nurses_name'];
  nurseDropdownSettings: any = {};
  nurses: any = [];
  associatedFacilities: any = [];
  dataSource: any = new MatTableDataSource([]);
  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    PAGE_LIMIT: 20,
    skip: 0
  };
  timeoutId: any;
  isDataLoaded: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private userService: UserService,
    private _authService: AuthService,
    private loader: NgxUiLoaderService,
    private facilityService: FacilityService,
    private woundNurseRelationService : WoundNurseRelationService,

  ) {
    this.nurseDropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'full_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: true,
    };
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId);
    this.companyId = this.route.parent.snapshot.params.id
    // await this.initCompanyFacilities();
    await this.initWoundNurses();
    await this.initWoundNurseFacilityAssociations();
    this.loader.stopLoader(this.loaderId);
  }

  async initCompanyFacilities() {
    const filter = { assoc_company_ids: this.companyId, is_active: true };
    const projection = {
      'title': 1,
      'is_active': 1,
      'source': 1,
      'assoc_company_ids': 1
    }

    let companyAssocPopulation: facilityPopulationInterface = {
      collection: 'companies',
      field: ["assoc_company_ids"],
      filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: [true, { $in: ['$_id', '$$assoc_company_ids'] }] }] } },
      project: { name: 1 },
    };
    
    const response: any = await this.facilityService.getFacilities(filter, projection, [companyAssocPopulation]).toPromise();
    if (response.status === 200) {
      this.facilities = response.data.array;
    }
  }

  async initWoundNurses() {
    const nurseFilter: any = { company_id: this.companyId, user_type: global.USER_TYPE.WOUND_NURSE };
    if (this._authService.currentUser.admin_type === global.ADMIN_TYPE.WOUND_NURSE_ADMIN) {
      nurseFilter.admin_type = global.ADMIN_TYPE.WOUND_NURSE_ADMIN;
    }
    const projection = { first_name: 1, last_name: 1, title: 1, email: 1, full_name: 1 };
    const response: any = await lastValueFrom(this.userService.getUsers(nurseFilter, projection));
    if (response.status === 200) {
      this.nurses = response.data;
      console.log('nurses', this.nurses);

    }
    else
      this.toastr.warning(response.message);
  }

  async initWoundNurseFacilityAssociations(title = "") {
    const filter: any = { assoc_company_ids: this.companyId };
    if(title) {
      filter.title =  {  
        $regex: title,
        $options: "i"
      };
    }
    const nurseProjection = {
      first_name: 1,
      last_name: 1,
      title: 1,
      full_name: 1
    };
    const facilityProjection = {
      title: 1,
      pcc_facId: 1,
      source: 1,
      pcc_2_legged_authentication: 1
    };
    const nurseFilter: any = {
      company_id: this.companyId,
      is_activated: "true",
      user_type: global.USER_TYPE.WOUND_NURSE
    };
    const project = {
      'title': 1,
      'source': 1,
      associated_wound_nurse_ids: 1
    };
    if (this._authService.currentUser.admin_type === global.ADMIN_TYPE.WOUND_NURSE_ADMIN) {
      nurseFilter.admin_type = global.ADMIN_TYPE.WOUND_NURSE_ADMIN;
    }
    const response: any = await lastValueFrom(this.woundNurseRelationService.getWoundNurseFacilityRelations(filter, project, filter.title ? 0 : this.pagination.pageNo));
    if (response.status === 200) {
      this.pagination.totalRecords = response.data.totalRecords;
      this.facilities = response.data.woundNurses;
      this.handlePagination();
      this.populateTable();
      // this.associatedFacilities = response.data;
    }
  }

  populateTable() {
    for (const facility of this.facilities) {
      facility.allNurses = this.nurses
      // for (const associatedFacility of this.associatedFacilities) {
      //   if (associatedFacility.facility_id._id === facility._id) {
      //     facility.associated_wound_nurse_ids = associatedFacility.associated_wound_nurse_ids;
      //   }
      // }
    }
    this.dataSource.data = this.facilities;
  }

  onDataRemove($event) {
    this.onNurseDeSelect($event.data, $event.element);
  }

  onDataSelection($event) {
    // let providers = element.associated_provider_ids.map(a => a._id);
    // let idx = this.users.findIndex(user => user._id.toString == element._id.toString());
    // this.users[idx] = element;
    // this.dataSource.data = this.users;
    if($event.data == 'selectAll') {
      this.onSelectAllNurse($event.element.assos_providers, $event.element);

    }
    else if($event.data == 'unSelect') {
      this.onDeSelectAllNurse([],$event.element);
    }
    else {
      if($event.status) {
        this.onNurseSelect($event.data, $event.element);
      }
      else {
        this.onNurseDeSelect($event.data, $event.element);
      }
    }
  }

  onNurseSelect(event, element) {
    const filter = { facility_id: element._id, company_id_ref : this.companyId };
    const projection = { associated_wound_nurse_ids: event._id };
    this.updateRelations(filter,projection,this.companyId);
  }

  onNurseDeSelect(event, element) {
    const filter = { facility_id: element._id, company_id_ref : this.companyId };
    const projection = { associated_wound_nurse_ids: event._id };
    let isRemoving : boolean = true;
    this.updateRelations(filter,projection,this.companyId, isRemoving);
    element.associated_wound_nurse_ids = element.associated_wound_nurse_ids.filter(provider => provider._id.toString() != event._id.toString())

  }

  onSelectAllNurse($event, element) {
    element.associated_wound_nurse_ids = element.allNurses;
    $event = element.associated_wound_nurse_ids.map(a => a._id);
    const filter = { facility_id: element._id, company_id_ref : this.companyId };
    const projection = { associated_wound_nurse_ids: $event };
    this.updateRelations(filter,projection,this.companyId);
  }

  onDeSelectAllNurse(event, element) {
    const filter = { facility_id: element._id, company_id_ref : this.companyId };
    const projection = { associated_wound_nurse_ids: event._id };
    let isRemoving : boolean = true;
    this.updateRelations(filter,projection,this.companyId, isRemoving);
  }

  updateRelations(filter,projection,companyId, isRemoving?) {
    this.woundNurseRelationService.updateWoundNurseFacilitiesRelation(filter, projection, companyId, isRemoving).subscribe((res: any) => {
      if (res.status == 200) {
        this.toastr.success("Relation Updated");
        this.loader.stopLoader(this.loaderId);
      }
      else {
        this.toastr.warning(res.message);
        this.loader.stopLoader(this.loaderId);
      }
    });
  }

  searchFacility(event) {
    const filterValue = (event.target as HTMLInputElement).value;
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        this.isDataLoaded = true;
        await this.initWoundNurseFacilityAssociations(filterValue);
        this.isDataLoaded = false;
      }, 1000);
  }

  async handlePageNavigation(shouldgoBack: boolean) {
    this.loader.startLoader(this.loaderId);
    shouldgoBack ? this.pagination.pageNo-- : this.pagination.pageNo++;
    await this.initWoundNurseFacilityAssociations();
    this.loader.stopLoader(this.loaderId);
  }


  handlePagination() {
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    if (this.pagination.skip + this.facilities.length < this.pagination.totalRecords) {
      this.pagination.hasNextPage = true;
    }
    else {
      this.pagination.hasNextPage = false;
    }
    if (this.pagination.skip > 0) {
      this.pagination.hasPrevPage = true;
    }
    else {
      this.pagination.hasPrevPage = false;
    }
  }
}
