import {Injectable} from '@angular/core';
import * as global from '../global';
import {API_URL} from "../../../environments/api-url";
import { AuthService } from 'src/app/services/auth.service';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class PatientRoundingSheetPatientListService {
    private filterSort = new BehaviorSubject<'seen'|'not_seen'|'room_no'|'new_patient'|
    'status'|'name'|'dob'|'room_no'|'admission_date'|'admission_date'|'discharge_date'|'payor'>(null);
    castFilterSort= this.filterSort.asObservable();
    private filterSearch = new BehaviorSubject<string>("");
    castFilterSearch= this.filterSearch.asObservable();
    

    constructor(
      private httpclient: HttpClient,
      private authService:AuthService) {
    }

    changeFilterSort(value:'seen'|'not_seen'|'room_no'|'new_patient'|
    'status'|'name'|'dob'|'room_no'|'admission_date'|'admission_date'|'discharge_date'|'payor') {
        this.filterSort.next(value);
    }
    changeFilterSearch(value) {
        if(value !== this.filterSearch.getValue()) {
            this.filterSearch.next(value);
        }
    }
    getFacilityPatientsByProviderId(provider_id, facility_id) {
        const patient = {
          ...this.authService.authObject,
            provider_id,
            facility_id,
        }
  
        return this.httpclient
            .post(global.url + API_URL.PATIENT.getFacilityPatientsByProviderId, {patient}).pipe();
    }
}
