<div class="flex flex-col">

    <div class="flex flex-col gap-2"
        style="background: white; padding:15px 5px 10px 5px; border-radius: 10px; justify-content: space-evenly !important;">
        <ngx-ui-loader [loaderId]="facilitySearchLoaderId"></ngx-ui-loader>
        <div style="justify-content: space-evenly; align-content: center;" class="flex gap-4">

            <div class="flex justify-center items-center w-2/5">
                <ng-container>
                    <mat-form-field style="width: 100%">
                        <mat-label>Search First Facility (by _id) </mat-label>
                        <input matInput type="text" name="facilityOne" [(ngModel)]="first_facility_id">
                    </mat-form-field>
                </ng-container>
                <ng-container>
                    <button class="dn-button-primary" style="flex: 10%; min-width: auto; margin-left: 15px;"
                        (click)="fetchOne()" mat-raised-button>Fetch</button>
                </ng-container>
            </div>
            <div class="flex justify-center items-center w-2/5">
                <ng-container>
                    <mat-form-field style="width: 100%">
                        <mat-label>Search Second Facility (by _id)</mat-label>
                        <input matInput type="text" name="facilityTwo" [(ngModel)]="second_facility_id">
                    </mat-form-field>
                </ng-container>
                <ng-container>
                    <button class="dn-button-primary" style="flex: 10%; min-width: auto; margin-left: 15px;"
                        (click)="fetchTwo()" mat-raised-button>Fetch</button>
                </ng-container>
            </div>
        </div>

        <div style="justify-content: space-evenly; align-content: center; padding-bottom: 17px;"
            class="flex gap-4 w-full">
            <div class="flex justify-center items-center gap-4" style="flex:40%;">
                <div class="w-full" *ngIf="(facility_one)">
                    <mat-card class="flex flex-col w-full" style="background-color:rgb(255 218 218);">
                        <mat-card-content>
                            <div class="flex w-full">
                                <div class="flex flex-col w-full">
                                    <div class="flex-grow flex"
                                        style="justify-content: space-between; align-content: center; font-size: 16px;">
                                        <div class="flex-grow"> {{"Title: "+ facility_one.title}}</div>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div> {{"Source: "}}{{facility_one.source||'DN'}} </div>
                                    </div>
                                    <div class="flex w-1/3"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"_id: "}}{{facility_one._id}}</div>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;"> {{"Census: "+
                                            facility_one.censusCount}}</span>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;"> {{"Patients: "+
                                            facility_one.patientCount}}</span>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;"> {{"Notes: "+
                                            facility_one.notesCount}}</span>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;"> {{"Charges: "+
                                            facility_one.chargeCount}}</span>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;"> {{"Submitted Charges: " +
                                            facility_one.submitChargeCount}}</span>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;">Associations (Company, Template,
                                            Provider)</span>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>

            <div class="flex justify-center items-center gap-4" style="flex:40%;">
                <div class="w-full" *ngIf="(facility_two)">
                    <mat-card class="flex flex-col w-full" style="background-color:rgb(179 239 148)">
                        <mat-card-content>
                            <div class="flex w-full">
                                <div class="flex flex-col w-full">
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px;">
                                        <div class="flex-grow"> {{"Title: "+ facility_two.title}}</div>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div> {{"Source: "}}{{facility_two.source||'DN'}} </div>
                                    </div>
                                    <div class="flex w-1/3"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"_id: "}}{{facility_two._id}}</div>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;"> {{"Census: "+
                                            facility_two.censusCount}}</span>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;"> {{"Patients: "+
                                            facility_two.patientCount}}</span>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;"> {{"Notes: "+
                                            facility_two.notesCount}}</span>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;"> {{"Charges: "+
                                            facility_two.chargeCount}}</span>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;"> {{"Submitted Charges: " +
                                            facility_two.submitChargeCount}}</span>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;">Associations (Company, Template,
                                            Provider)</span>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>


        <div style="justify-content: space-evenly; align-content: center; padding-bottom: 17px;" class="flex flex-col gap-6">
            <div *ngIf="(facility_one&&facility_two)" class="flex justify-center items-center gap-6">
                <div style="padding-top: 7px; margin-left: 20px; font-size: 15;">Merge : </div>
                <div style="padding-top: 7px; margin-left: 20px;">
                    <button style=" flex: 10%; min-width: auto; " [disabled]="mergeStatusone"
                        (click)="patient_one_to_patient_two()" mat-raised-button>
                        <span style="color:rgb(177, 26, 26);font-size: 15;">{{'(X) '+facility_one.title}}</span>
                        <span style="color:black;">--></span>
                        <span style="color:rgb(67, 107, 47);font-size: 15;">{{facility_two.title}}</span>
                    </button>
                </div>
                <div style="padding-top: 7px; margin-left: 20px;">
                    <button style=" flex: 10%;  min-width: auto; " [disabled]="mergeStatustwo"
                        (click)="patient_two_to_patient_one()" mat-raised-button>
                        <span style="color:rgb(67, 107, 47);font-size: 15;">{{'(X) '+facility_two.title}}</span>
                        <span style="color:black;">--></span>
                        <span style="color:rgb(177, 26, 26);font-size: 15;">{{facility_one.title}}</span>
                    </button>
                </div>
                <div>
                    <button class="dn-button-secondary" style=" flex: 10%; margin-left: 15px; min-width: auto;"
                        [disabled]="revertStatus" (click)="revertMerge()" mat-raised-button>Revert Merge</button>
                </div>
            </div>
        </div>


        <div style="justify-content: space-evenly; align-content: center; padding-bottom: 17px; flex:100%;"
            class="flex gap-4">
            <div class="flex justify-center items-center gap-4" style="flex:40%;">
                <div class="w-full" *ngIf="(facility_one_after)">
                    <mat-card class="flex flex-col" style="background-color:rgb(255 218 218);">
                        <mat-card-content>
                            <div class="flex w-full">
                                <div class="flex flex-col w-full">
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px;">
                                        <div class="flex-grow"> {{"Title: "+ facility_one_after.title}}</div>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div> {{"Source: "}}{{facility_one_after.source||'DN'}} </div>
                                    </div>
                                    <div class="flex w-1/3"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"_id: "}}{{facility_one_after._id}}</div>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;">
                                            {{"Census:"+facility_one_after.censusCount}}</span>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;">
                                            {{"Patients:"+facility_one_after.patientCount}}</span>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;">
                                            {{"Notes:"+facility_one_after.notesCount}}</span>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;">
                                            {{"Charges:"+facility_one_after.chargeCount}}</span>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;"> {{"Submitted Charges:
                                            "+facility_one_after.submitChargeCount}}</span>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;">Associations (Company, Template,Provider)</span>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>

            <div class="flex justify-center items-center gap-4" style="flex:40%;">
                <div class="w-full" *ngIf="(facility_two_after)">
                    <mat-card class="flex flex-col w-full" style="background-color:rgb(179 239 148)">
                        <mat-card-content>
                            <div class="flex w-full">
                                <div class="flex flex-col w-full">
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px;">
                                        <div class="flex-grow"> {{"Title: "+ facility_two_after.title}}</div>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div> {{"Source: "}}{{facility_two_after.source||'DN'}} </div>
                                    </div>
                                    <div class="flex w-1/3"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <div class="flex-grow"> {{"_id: "}}{{facility_two_after._id}}</div>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;">
                                            {{"Census:"+facility_two_after.censusCount}}</span>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;">
                                            {{"Patients:"+facility_two_after.patientCount}}</span>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;">
                                            {{"Notes:"+facility_two_after.notesCount}}</span>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;">
                                            {{"Charges:"+facility_two_after.chargeCount}}</span>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;"> {{"Submitted Charges:
                                            "+facility_two_after.submitChargeCount}}</span>
                                    </div>
                                    <div class="flex flex-grow"
                                        style="justify-content: space-between; align-content: center; font-size: 16px; ">
                                        <span style="font-size: 16px;">Associations (Company, Template,Provider)</span>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>