import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { AssosiationDialogComponent } from '../assosiation-dialog/assosiation-dialog.component';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-company-assosiation',
  templateUrl: './company-assosiation.component.html',
  styleUrls: ['./company-assosiation.component.css']
})
export class CompanyAssosiationComponent implements OnInit {

  currentUser: any;
  allCompanies: any;
  allOtherCompanies: any;

  constructor(
    private dialog: MatDialog,
    private companyService: CompanyService,
    private toastr: ToastrService,
    private _commonService: CommonService,
    private _authService: AuthService,

  ) {
    this.currentUser = this._authService.currentUser;
  }

  ngOnInit(): void {
    this.getAllCompanies();

  }

  getAllCompanies() {
    this.companyService.getAllCompaniesWithCompanyAssosiation({}).subscribe((res: any) => {
      if (res.status == 200) {
        if (res.data) {
          this.allCompanies = res.data.filter((ele: any) => {
            return ele.relation?.length
          });

        }
        console.log("this.allCompanies", this.allCompanies);
      }
    })
  }

  onCompanyDeSelect(event, selectedCompany) {
    console.log("event, selectedCompany : ", event, selectedCompany);

    const relationIndex = selectedCompany.relation.findIndex((e) => { return (e._id?.toString() == event._id.toString()) })
    if (relationIndex > -1) {
      selectedCompany.relation.splice(relationIndex, 1);
    }

    const index = this.allCompanies.findIndex((e) => { return (e._id?.toString() == selectedCompany._id.toString()) })
    this.allCompanies[index] = selectedCompany;

    this.saveinDB(selectedCompany);

  }

  saveinDB(element) {
    const assosiated_company_id = element.relation?.map(e => e._id) || [];
    const parent_company_id = element._id;
    const created_by = this.currentUser._id;

    this.companyService.saveCompanyCompanyRelation({ parent_company_id: parent_company_id, assosiated_company_id: assosiated_company_id, created_by: created_by }).subscribe((res: any) => {
      this.toastr.success('Association has updated successfully');
      this.getAllCompanies();
    })

  }

  removeFullassosiation(selectedCompany) {

    selectedCompany.relation = [];

    const index = this.allCompanies.findIndex((e) => { return (e._id?.toString() == selectedCompany._id.toString()) })
    this.allCompanies[index] = selectedCompany;

    this.saveinDB(selectedCompany);
  }

  editassosiation(companyId) {
    this.openAssosiationDialog(companyId)
  }

  openAssosiationDialog(companyId?) {

    const dialogRef = this.dialog.open(AssosiationDialogComponent, {
      width: '60%',
      data: { company_id: companyId || null }
    });
    dialogRef.afterClosed().subscribe(async (data: any) => {
      if (data) {
        console.log("data : ", data);
      }
      this.getAllCompanies();
    });

  }

  async updateCompanyAssociation(companyAssociation, isChecked, value) {
    if (isChecked) {
      companyAssociation.shared_modules = Array.from(new Set([...companyAssociation.shared_modules, value]));
    }
    else {
      companyAssociation.shared_modules.splice(companyAssociation.shared_modules.indexOf(value), 1);
    }
    const filter = {
      parent_company_id: companyAssociation._id
    };
    const updateQuery = {
      $set: {
        shared_modules: companyAssociation.shared_modules
      }
    };
    const response = await lastValueFrom(this.companyService.updateCompanyAssociation(filter, updateQuery));
    if (response.status === 200) {
      this.toastr.success('Association updated successfully', 'Success');
    }
    else {
      this.toastr.error('Something went wrong', 'Failure');
    }
  }

}
