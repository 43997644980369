import { Component, DoCheck, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import * as global from '../../../../includes/global';
import { AuthService } from "../../../../services/auth.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { MatTableDataSource } from '@angular/material/table';
import { ChargesCatagoryService } from 'src/app/shared/popup-dialogs/charges-catagories/charges-catagories-dialog/charges-catagory.service';
// import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CensusPatientListService } from 'src/app/includes/census-patient-list/census-patient-list.service';
import { PatientRoundingSheetService } from 'src/app/includes/patient-rounding-sheets/patient-rounding-sheet.service';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { SidebarDiagnosisService } from './sidebar-diagnosis.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
@Component({
    selector: 'app-sidebar-diagnoses',
    templateUrl: './sidebar-diagnoses.component.html',
    styleUrls: ['./sidebar-diagnoses.component.css']
})
export class SidebarDiagnosesComponent implements OnInit, DoCheck, OnChanges, OnDestroy {
    global = global;
    @Input() note:any;
    @Input() charge:any;
    @Input() patientId;
    @Input() ownerId;
    @Input() visitDate;
    @Input() patientName;
    @Input() noteEditorIcds: any[] = [];
    // @Input() params: any;
    params: any = {};
    @Output() closeView = new EventEmitter<boolean>();
    loaderId = 'loader-01';
    icds = [];
    reserved_icds = [];
    model = {
        owner_id: '',
        patient_id: '',
        rounding_sheet_id: '',
        icd_id: [],
        billing_note: '',
        note: '',
        status: '',
        visit_date: '',
        facility_id: '',
        measure: false
    };
    searchFilter = {
        icd: ''
    };
    public paginationChargesAll: {
        pageNo: number,
        hasNextPage: boolean,
        hasPrevPage: boolean,
        totalCount: number,
        totalPages: number
    };
    public paginationDiagnosesAll: {
        pageNo: number,
        hasNextPage: boolean,
        hasPrevPage: boolean,
        totalCount: number,
        totalPages: number
    };
    allDiagnoses: any = [];
    allCharges: any = [];
    selectedCharges: any = []
    selectedDiagnoses: any = []
    visible = true;
    selectable = true;
    removable = true;
    displayedColumns = ['fvrt', 'code', 'checkbox']
    selectedDisplayedColumns = ['code', 'checkbox']
    dataSourceAllICDs: any;
    dataSourceFvrtICDs: any;
    dataSourceSelectedICDs: any;
    searchICD: Boolean = false;
    chargeFilter = '';
    diagnoseFilter = '';
    fvrtICDTab = true;
    allICDTab = false;
    categories = [];
    selectedCategory: any;
    loading: Boolean = true
    starter: Boolean = true;

    constructor(
        private service: PatientRoundingSheetService,
        private toastr: ToastrService,
        private router: Router,
        private chargesCatagoryService: ChargesCatagoryService,
        private authService: AuthService,
        private censusPatientListService: CensusPatientListService,
        private loader: NgxUiLoaderService,
        private sidebarDiagnosisService:SidebarDiagnosisService
    ) {
        this.paginationDiagnosesAll = {
            pageNo: 1,
            hasNextPage: false,
            hasPrevPage: false,
            totalCount: 0,
            totalPages: 0
        };
    }
    ngDoCheck():void {
        // this.syncCheckboxes();
        this.model.icd_id = this.selectedDiagnoses.map(icd => icd._id);
        this.censusPatientListService.setICDsForCharge(this.model.icd_id);
    }
    ngOnChanges(changes: SimpleChanges): void {
        const chargeChanges = changes['charge'];
        const curCharge  = JSON.stringify(chargeChanges.currentValue);
        const prevCharge = JSON.stringify(chargeChanges.previousValue);
        if(curCharge !== prevCharge) {
            this.initializeDrafted(this.charge);
        }
    }
    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.selectedDiagnoses, event.previousIndex, event.currentIndex); 
        this.sidebarDiagnosisService.setSelectedICDs(this.selectedDiagnoses)
    }

    ngOnInit(): void { 
        // this.getCategoriesCPT()
        // this.getFvrtCPTs();
        // console.log("in  sidebar diagniss charge = ", this.charge);
        
        this.getFvrtICDs();
        let icds = this.noteEditorIcds;
        this.sidebarDiagnosisService.castSelectedICDs.subscribe((_icds => {
            if(_icds.length > 0){
                icds = [...icds, ..._icds].reduce((acc, next) => {
                    if(acc.find(icd => icd.code === next.code)) {
                        return acc;
                    } else {
                        return [...acc, next];
                    }
                }, []); 
            }
            else{
            }
           
            this.selectedDiagnoses = icds;
            this.model.icd_id = icds.map(icd => icd._id);
            this.censusPatientListService.setICDsForCharge(this.model.icd_id);
            this.syncCheckboxes(icds);
        }));
        // this.getFvrtICDs();        
    }

    ngOnDestroy(): void {
    }
    initializeDrafted(charge) {
        if (charge && Array.isArray(charge.icd_id)) {
          charge.icd_id.forEach((element) => {
            if (
              !this.selectedDiagnoses.find((icd) => icd._id === element._id)
            ) {
              this.addToModel(element, null, 'icd');
            }
          });
          // initialziing fav checkboxes
          for (let k = 0; k < this.selectedDiagnoses.length; k++) {
            for (let i = 0; i < this.icds.length; i++) {
              if (this.icds[i]._id == this.selectedDiagnoses[k]._id) {
                this.icds[i].selected = true;
              }
            }
          }
          // initializing all checkboxes
          for (let k = 0; k < this.selectedDiagnoses.length; k++) {
            for (let i = 0; i < this.allDiagnoses.length; i++) {
              if (this.allDiagnoses[i]._id == this.selectedDiagnoses[k]._id) {
                this.allDiagnoses[i].selected = true;
              }
            }
          }
        } else {
            if(JSON.stringify(this.noteEditorIcds) === JSON.stringify(this.selectedDiagnoses)) {
                // do nothing
            } else {
                this.selectedDiagnoses = [];
                for (const icd of this.icds) {
                    icd.selected = false;
                }
                for (const diagnosis of this.allDiagnoses) {
                    diagnosis.selected = false;
                }
            }
        }
    }

    syncCheckboxes(icds) {
        // console.log('syncCheckboxes=============')
        // 
        for (let k = 0; k < icds.length; k++) {
          for (let i = 0; i < this.icds.length; i++) {
            if (this.icds[i]._id == icds[k]._id) {
              this.icds[i].selected = true;
            }
          }
        }
        for (let k = 0; k < icds.length; k++) {
          for (let i = 0; i < this.allDiagnoses.length; i++) {
            if (this.allDiagnoses[i]._id == icds[k]._id) {
              this.allDiagnoses[i].selected = true;
            }
          }
        }
    }
    isICDChecked(diagnosis) {
        if(diagnosis.selected) return true;
        if(this.noteEditorIcds.findIndex(icd => icd._id === diagnosis._id) >= 0 ) {
            diagnosis.selected = true;
            return true;
        }
        return false;
    }

    getFvrtICDs() {
        this.service.getFavoriteICDs()
            .subscribe(res => {
                this.getAllICDs();
                // @ts-ignore
                this.icds = res.data.array;
                // @ts-ignore
                this.reserved_icds = res.data.array;
                for (let k = 0; k < this.selectedDiagnoses.length; k++) {
                    for (let i = 0; i < this.icds.length; i++) {
                        if (this.icds[i]._id == this.selectedDiagnoses[k]._id) {
                            this.icds[i].selected = true;
                        } 
                    } 
                }
                this.dataSourceFvrtICDs = new MatTableDataSource(this.icds);
            });
    }
    getAllICDs(page = 1, search = "") {
        this.service.getICDs({ page: page, search: search }).subscribe((response: any) => {
            if (response.status == 200) {
                if (search.trim() == '') {
                    this.allDiagnoses.push(...response.data.array);
                }
                else {
                    this.allDiagnoses = response.data.array;
                }
                for (let i = 0; i < this.allDiagnoses.length; i++) {
                    for (let j = 0; j < this.icds.length; j++) {
                        if (this.allDiagnoses[i]._id.toString() == this.icds[j]._id.toString()) {
                            this.allDiagnoses[i].fvrt = true;
                        }
                    }
                }
                // uncomment if want to show selected area 
                // for (let i = 0; i < this.allDiagnoses.length; i++) {
                //     for (let k = 0; k < this.selectedDiagnoses.length; k++) { 
                //         if (this.allDiagnoses[i]._id == this.selectedDiagnoses[k]._id) { 
                //             this.allDiagnoses[i].selected = true;
                //         } 
                //     }
                // } 
                 // initializing all checkboxes
                for (let k = 0; k < this.selectedDiagnoses.length; k++) {
                    for (let i = 0; i < this.allDiagnoses.length; i++) {
                        if (this.allDiagnoses[i]._id == this.selectedDiagnoses[k]._id) {
                            this.allDiagnoses[i].selected = true;
                        } 
                    } 
                }

                const totalCount = response.data.totalCount;
                this.paginationDiagnosesAll.totalCount = totalCount;
                this.paginationDiagnosesAll.pageNo = page;
                this.initPaginationDiagnosesAll();
                this.dataSourceAllICDs = new MatTableDataSource(this.allDiagnoses);
            }
        })
    }
    isFavourite(element, type, tab) {

        if (type == 'icd') {
            if (tab == 'fvrts') {
                return true
            }
        }
    }
    addToFavouriteDiagnoses(diagnose) {
        this.loader.startLoader('loader-01');
        let data = {
            ids: diagnose._id,
            isRemove: false,
            filter: 'icd_id'
        };
        let icds = this.service.setFavoriteICDs(data).subscribe((response: any) => {
            if (response.status == 200) {
                this.icds.push(diagnose);
                this.allDiagnoses.map(diagnos => {
                    if (diagnos._id == diagnose._id) {
                        diagnos.fvrt = true;
                        this.dataSourceAllICDs = new MatTableDataSource(this.allDiagnoses)
                    }
                })
                this.dataSourceFvrtICDs = new MatTableDataSource(this.icds)
                this.loader.stopLoader('loader-01');
            }
        })

    }

    removeFavouriteDiagnoses(diagnose) {
        let index = -1;
        for (let i = 0; i < this.icds.length; i++) {
            if (diagnose._id == this.icds[i]._id) {
                index = i;
            }
        }
        if (index > -1) {
            this.icds.splice(index, 1)
            this.dataSourceFvrtICDs = new MatTableDataSource(this.icds)
        }
        this.allDiagnoses.map(diagnos => {
            if (diagnos._id == diagnose._id) {
                diagnos.fvrt = false;
                this.dataSourceAllICDs = new MatTableDataSource(this.allDiagnoses)
            }
        })
        let data = {
            ids: diagnose._id,
            isRemove: true,
            filter: 'icd_id'
        };
        this.service.setFavoriteICDs(data).subscribe((response: any) => {

        })

    }


    onListDropfvrtICDs(event: CdkDragDrop<any[]>) {
        let filter = 'icd_id'
        moveItemInArray(this.icds, event.previousIndex, event.currentIndex);
        this.dataSourceFvrtICDs = new MatTableDataSource(this.icds);
        this.service.rearrangeFavoriteCharges(this.icds.map(c => c._id), filter)
            .subscribe((response: any) => {
            })
    }
    addToModel(element, event, type) {
        this.clearSearchField()
        if (type == 'icd') {
            
            if (element.selected) {
                let index = this.selectedDiagnoses.findIndex(icd => icd._id === element._id);
                if (index > -1) {
                    element.selected = false;
                    this.selectedDiagnoses.splice(index, 1)
                    // this.model.icd_id.splice(index, 1)
                    this.allDiagnoses.map(d => {
                        if (d._id == element._id) {
                            d.selected = false;
                        }
                    })
                    this.icds.map(d => {
                        if (d._id == element._id) {
                            d.selected = false;
                        }
                    })
                }
                else {
                    element.selected = true;
                    this.selectedDiagnoses.push(element)
                    // this.model.icd_id.push(element._id);
                    this.allDiagnoses.map(d => {
                        if (d._id == element._id) {
                            d.selected = true;
                        }
                    })
                    this.icds.map(d => {
                        if (d._id == element._id) {
                            d.selected = true;
                        }
                    })
                }
            }
            else if (!element.selected) {
                element.selected = true;
                this.selectedDiagnoses.push(element)
                // this.model.icd_id.push(element._id);
                this.allDiagnoses.map(d => {
                    if (d._id == element._id) {
                        d.selected = true;
                    }
                })
                this.icds.map(d => {
                    if (d._id == element._id) {
                        d.selected = true;
                    }
                })
            }
            this.model.icd_id = this.selectedDiagnoses.map(icd => icd._id);
            this.dataSourceFvrtICDs = new MatTableDataSource(this.icds);
            this.dataSourceSelectedICDs = new MatTableDataSource(this.selectedDiagnoses);
            this.censusPatientListService.setICDsForCharge(this.model.icd_id);
            // this.sidebarDiagnosisService.setSelectedICDs(this.selectedDiagnoses);
        }
    }
    removeDiagnose(diagnose) {
        
        let index = this.selectedDiagnoses.findIndex(icd => icd._id === diagnose._id);
        if (index >= 0) {
            this.selectedDiagnoses.splice(index, 1)
            this.model.icd_id = this.model.icd_id
        }
        this.model.icd_id = this.selectedDiagnoses.map(icd => icd._id)
        this.dataSourceSelectedICDs = new MatTableDataSource(this.selectedDiagnoses);
        this.allDiagnoses.map(a => {
            if (a._id == diagnose._id) {
                a.selected = false;
            }
        })
        this.dataSourceAllICDs = new MatTableDataSource(this.allDiagnoses);
        this.icds.map(a => {
            if(a._id == diagnose._id){
                a.selected = false;
            }
        })
        this.dataSourceFvrtICDs = new MatTableDataSource(this.icds);
    }
    searchDiagnose(value) {
        this.searchFilter.icd = value?.trim()?.toLowerCase();
        if ((!value || value == '' ) && !this.starter) {
            return;
        }
        // else {
        if (this.allICDTab) {
            this.getAllICDs(1, value)
        }
        else if (this.fvrtICDTab) {
            this.applyFilterICD(this.searchFilter.icd);
        }
        // }
    }
    applyFilterICD(value) {
        if (value) {
            let filteredData = [];
            this.reserved_icds.map(a => {
                if (a.code.trim().toLowerCase().includes(value) || a.description.trim().toLowerCase().includes(value)) {
                    filteredData.push(a)
                }
            })
            this.dataSourceFvrtICDs = new MatTableDataSource(filteredData)
        }
        else {
            this.dataSourceFvrtICDs = new MatTableDataSource(this.reserved_icds)
        }
    }
    clearDiagnoseFilter() {
        this.diagnoseFilter = " ";
        this.getAllICDs(1, '')
    }


    handlePrevClick(type) {
        if (type == 'chargesAll') {
            // this.getAllCPTs(this.paginationChargesAll.pageNo - 1, this.searchFilter.cpt);
        }
        else if (type == 'diagnoseAll') {
            this.getAllICDs(this.paginationDiagnosesAll.pageNo - 1, this.searchFilter.icd);
        }
    }
    handleNextClick(type) {
        if (type == 'chargesAll') {
            // this.getAllCPTs(this.paginationChargesAll.pageNo + 1, this.searchFilter.cpt);
        }
        else if (type == 'diagnoseAll') {
            this.getAllICDs(this.paginationDiagnosesAll.pageNo + 1, this.searchFilter.icd);
        }
    }

    getCountofCodes(type) {
        if (type == 'chargesAll') {
            // return ((this.paginationChargesAll.pageNo * 10) - 9) + ' - ' + (((this.paginationChargesAll.pageNo * 10) - 10) + Number(this.allCharges.length)) + " out of " + (this.paginationChargesAll.totalCount);
        }
        else if (type == 'diagnoseAll') {
            // return ((this.paginationDiagnosesAll.pageNo * 10) - 9) + ' - ' + (((this.paginationDiagnosesAll.pageNo * 10) - 10) + Number(this.allDiagnoses.length)) + " out of " + (this.paginationDiagnosesAll.totalCount);
            return '1 - ' + (Number(this.allDiagnoses.length)) + " out of " + (this.paginationDiagnosesAll.totalCount);
        }
    }

    initPaginationDiagnosesAll() {
        this.paginationDiagnosesAll.totalPages = Math.ceil(this.paginationDiagnosesAll.totalCount / 10);
        if (this.allDiagnoses) {
            this.paginationDiagnosesAll.hasNextPage = this.allDiagnoses.length > 0 && this.paginationDiagnosesAll.pageNo < this.paginationDiagnosesAll.totalPages;
        }
        this.paginationDiagnosesAll.hasPrevPage = this.paginationDiagnosesAll.pageNo > 1;
    }
    onTabClickDiagnose(event) {
        // this.diagnoseFilter = null;
        this.starter = false;
        if (event.tab.textLabel.trim().toLowerCase() == 'all') {
            this.searchICD = true;
            this.allICDTab = true;
            this.fvrtICDTab = false;
        }
        else {
            this.searchICD = false;
            this.fvrtICDTab = true;
            this.allICDTab = false;
            // this.applyFilterICD('');
        }
        this.searchDiagnose(this.diagnoseFilter)
    }

    clearSearchField() {
        this.diagnoseFilter = '';
    }

}
