import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { QueryReportService } from '../service/query-report.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-create-general-report-dialog',
  templateUrl: './create-general-report-dialog.component.html',
  styleUrls: ['./create-general-report-dialog.component.css']
})
export class CreateGeneralReportDialogComponent implements OnInit {
  loaderId = 'create-general-report-dialog';
  companies: any = [];
  allCompanies: any = [];
  model: any = {
    title: null,
    companyIds: null,
  };
  companyControl = new FormControl();
  filteredCompanies: Observable<string[]>;
  generalReportId;

  @ViewChild('companyInput') companyInput: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private dialogRef: MatDialogRef<CreateGeneralReportDialogComponent>,
    private _toastr: ToastrService,
    private _companyService: CompanyService,
    private _queryService: QueryReportService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private loader: NgxUiLoaderService,

  ) {
    if (this.data) {
      this.generalReportId = this.data;
    }

  }

  async ngOnInit() {
    await this.getCompanies();
    this.filteredCompanies = this.companyControl.valueChanges.pipe(
      startWith(''),
      map((value:any) => this.filterCompany(value)));
    if (this.generalReportId) this.getGeneralReport();
  }

  private filterCompany(value: string): string[] {
    if (value && typeof value === "string") {
      const filterValue = value.toLowerCase();
      return this.allCompanies.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    else {
      return this.allCompanies;
    }
  }

  getGeneralReport() {
    let filter = {
      _id: this.generalReportId
    };
    let project = {
      title: 1,
      associated_company_ids: 1
    };
    this._queryService.getGeneralReport(filter, project).subscribe((response: any) => {
      if (response.status == 200) {
        const { title, associated_company_ids } = response.data;
        this.model.title = title;
        this.companies = associated_company_ids;
      }
    }, err => {
      this._toastr.error(err, "ERROR");
    }
    );
  }

  addCompanyAsChip(event: MatChipInputEvent): void {
    const input = event.input;
    const value: any = event.value;
    if (typeof value === "object" && value !== null) {
      this.companies.push(value);
    }

    if (input) {
      input.value = '';
    }

    this.companyControl.setValue(null);
  }

  removeCompanyAsChip(company: any): void {
    const index = this.companies.indexOf(company);
    if (index >= 0) {
      this.companies.splice(index, 1);
    }
  }

  handleCompanyInputFocus(event) {
    this.companyControl.setValue(event.target.value);
  }


  async getCompanies() {
    this.loader.startLoader(this.loaderId);
    const response: any = await this._companyService.getAllCompanies().toPromise();
    if (response.status == 200) {
      // console.log("init companies", response.data);
      this.allCompanies = response.data;
      this.loader.stopLoader(this.loaderId);
    }
    else if (response.status == 403) {
      this._toastr.error(response.message, "ERROR");
      this.loader.stopLoader(this.loaderId);
    }
  }

  // onSelectCompany(companyId) {
  //   if (companyId !== "none") {
  //     this.model.companyId = companyId;
  //   }
  //   else {
  //     this.model.companyId = null;
  //   }
  // }

  onSelectCompany(event: MatAutocompleteSelectedEvent): void {
    this.companies.push(event.option.value);
    this.companyInput.nativeElement.value = '';
    this.companyControl.setValue(null);
  }

  submitForm() {
    this.model.companyIds = this.companies.map(company => company._id);
    if (this.generalReportId) {
      this.model._id = this.generalReportId;
      this._queryService.updateGeneralReport(this.model).subscribe((response: any) => {
        if (response.status == 200) {
          this._toastr.success("Report Updated", "Success");
          this.dialogRef.close(response.data);
        }
      }, err => {
        this._toastr.error(err, "ERROR");
      }
      );
    }
    else {
      this._queryService.createGeneralReport(this.model).subscribe((response: any) => {
        if (response.status == 200) {
          this._toastr.success("Report Saved", "Success");
          this.dialogRef.close(response.data);
        }
      }, err => {
        this._toastr.error(err, "ERROR");
      }
      );
    }
  }

}
