import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { WoundService } from 'src/app/services/wound.service';
import { PatientRoundingSheetService } from '../../patient-rounding-sheets/patient-rounding-sheet.service';

@Component({
  selector: 'app-procedure-codes',
  templateUrl: './procedure-codes.component.html',
  styleUrls: ['./procedure-codes.component.css']
})
export class ProcedureCodesComponent implements OnInit {
  @Input() company_id:string;
  loaderId = 'app-procedure-codes';
  loaderId2 = 'app-procedure-codes2';
  allProcedureTypes: any = [];
  activatedProcedure: any;
  allCPTs: any = [];
  chargeFilter = '';
  currentUser: any;
  constructor(
    private toastr: ToastrService,
    private loader: NgxUiLoaderService,
    private _authService: AuthService,
    private _patientRoundingSheetservice: PatientRoundingSheetService,
    private _woundService: WoundService
  ) {
    this.currentUser = this._authService.currentUser;
  }

  async ngOnInit(): Promise<void> {
    this.loader.startLoader(this.loaderId);
    await this.getAllProcdureTypes();
    await this.getAllCPTCodes();
    this.loader.stopLoader(this.loaderId);
  }
  async getAllProcdureTypes() {
    let response: any = await this._woundService.getProcedureTypeswithCPTRelation({ company_id: this.company_id }).toPromise();
    if (response.status == 200) {
      this.allProcedureTypes = response.data.array
      this.activatedProcedure = this.allProcedureTypes[0];
    }
  }
  async getAllCPTCodes(search = "") {
    let response: any = await this._patientRoundingSheetservice.getCPTs({ search: search }).toPromise()
    if (response.status == 200) {
      if (search.trim() == '') {
        this.allCPTs.push(...response.data.array);
      }
      else {
        this.allCPTs = response.data.array;
      }
      this.allCPTs.forEach((cpt)=>{
        if(this.activatedProcedure?.relation?.cpt_id_ref.findIndex((relationCpt)=> relationCpt._id === cpt._id) > -1){
          cpt.selected = true;
        }else{
          cpt.selected = false;
        }
      })
  }
}
  async handleProcedureTypeClick(procedureType, i) {
    this.loader.startLoader(this.loaderId2);
    this.activatedProcedure = procedureType;
    this.allCPTs = [];
    await this.getAllCPTCodes();
    this.chargeFilter = '';
    this.loader.stopLoader(this.loaderId2);
  }
  getCPTCodeShortDescription(description){
    return (description.length > 38 ? (description.substring(0,35) + '...') : description );
  }
  async handleCPTCodeClick(code){
    if (this.currentUser.admin_type != 'system_admin') {
      return;
    }
    if(code.selected){
      this.toastr.info("Removing Relation","Please Wait...");
      let ids = this.activatedProcedure.relation?.cpt_id_ref.filter((cpt)=> cpt._id != code._id);
      let details = {
        procedure_type_id: this.activatedProcedure._id,
        cpt_id_ref: ids.map((c)=> c._id),
      }
      let response:any = await this._woundService.removeProcedureCodeRelation(details).toPromise();
      if(response.status == 200){
        this.activatedProcedure.relation.cpt_id_ref = ids;
        code.selected = false;
        this.toastr.success(response.message,"Success");
      }
    }else{
      this.toastr.info("Making Relation","Please Wait...");
      let details = {
        procedure_type_id: this.activatedProcedure._id,
        cpt_id_ref: [code._id],
        company_id: this.company_id
      }
      let response:any = await this._woundService.addNewProcedureCodeRelation(details).toPromise();
      if(response.status == 200){
        if(this.activatedProcedure.relation?.cpt_id_ref?.length > 0){
          this.activatedProcedure.relation?.cpt_id_ref.push(code);
        }else{
          this.activatedProcedure.relation = {};
          this.activatedProcedure.relation.cpt_id_ref = [];
          this.activatedProcedure.relation?.cpt_id_ref.push(code);
        }
        code.selected = true;
        this.toastr.success(response.message,"Success");
      }
    }
  }
}
