<div class="container">
    <mat-card>
        <mat-card-content>
            <form (ngSubmit)="handleSubmit(form.value)" #form="ngForm">
                <div class="flex flex-col">
                    <div class="fields-section">
                        <mat-form-field class="field-item">
                            <input
                                    matInput
                                    type="text"
                                    name="title"
                                    #title="ngModel"
                                    [(ngModel)]="model.title"
                                    placeholder="Vendor Name"
                                    required
                            />
                            <mat-error
                                    *ngIf="title.invalid && (title.dirty || title.touched)"
                            >
                                Please enter vendor name
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="field-item">
                            <input
                                    matInput
                                    type="text"
                                    name="description"
                                    #description="ngModel"
                                    [(ngModel)]="model.description"
                                    placeholder="Description"
                                    required
                            />
                            <mat-error
                                    *ngIf="
                  description.invalid &&
                  (description.dirty || description.touched)
                "
                            >
                                Please enter description
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="field-item">
                            <input
                                    matInput
                                    type="text"
                                    name="abbreviation"
                                    #abbreviation="ngModel"
                                    [(ngModel)]="model.abbreviation"
                                    placeholder="Abbreviation"
                                    required
                            />
                            <mat-error
                                    *ngIf="
                  abbreviation.invalid &&
                  (abbreviation.dirty || abbreviation.touched)
                "
                            >
                                Please enter abbreviation
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="field-item">
                            <input
                                    matInput
                                    type="email"
                                    name="email"
                                    #email="ngModel"
                                    [(ngModel)]="model.email"
                                    placeholder="Email"
                                    required
                            />
                            <mat-error
                                    *ngIf="email.invalid && (email.dirty || email.touched)"
                            >
                                Please enter Email
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="field-item">
                            <input
                                    matInput
                                    type="text"
                                    name="address"
                                    #address="ngModel"
                                    [(ngModel)]="model.address"
                                    placeholder="Address 1"
                                    required
                            />
                            <mat-error
                                    *ngIf="address.invalid && (address.dirty || address.touched)"
                            >
                                Please enter Address
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="field-item">
                            <input
                                    matInput
                                    type="text"
                                    name="address_2"
                                    #address_2="ngModel"
                                    [(ngModel)]="model.address_2"
                                    placeholder="Address 2"

                            />

                        </mat-form-field>
                        <mat-form-field class="field-item">
                            <mat-select name="state"
                                        #state="ngModel"
                                        [(ngModel)]="model.state"
                                        placeholder="State"
                                        required>
                                <mat-option *ngFor="let state of usa_states; let i = index"
                                            [value]="numberToString(i+1)">
                                    {{state.abbreviation}}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                    *ngIf="state.invalid && (state.dirty || state.touched)"
                            >
                                Please enter State
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="field-item">
                            <input
                                    matInput
                                    type="text"
                                    name="city"
                                    #city="ngModel"
                                    [(ngModel)]="model.city"
                                    placeholder="City"
                                    required
                            />
                            <mat-error
                                    *ngIf="city.invalid && (city.dirty || city.touched)"
                            >
                                Please enter City
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="field-item">
                            <input
                                    matInput
                                    type="text"
                                    name="zip"
                                    #zip="ngModel"
                                    [(ngModel)]="model.zip"
                                    placeholder="Zip"
                                    required
                            />
                            <mat-error
                                    *ngIf="zip.invalid && (zip.dirty || zip.touched)"
                            >
                                Please enter Zip
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="field-item">
                            <input
                                    matInput
                                    type="text"
                                    name="phone"
                                    #phone="ngModel"
                                    [(ngModel)]="model.phone"
                                    placeholder="Phone"
                                    required
                            />
                            <mat-error
                                    *ngIf="phone.invalid && (phone.dirty || phone.touched)"
                            >
                                Please enter Phone
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div>
                        <h3>Contact Person</h3>
                        <div class="fields-section" id="primary-contact">
                            <mat-form-field class="field-item">
                                <input
                                        matInput
                                        type="text"
                                        name="first_name"
                                        #first_name="ngModel"
                                        [(ngModel)]="model.contact.first_name"
                                        placeholder="First Name"
                                        onkeypress="return /[a-z]/i.test(event.key)"
                                        required
                                />
                                <mat-error
                                        *ngIf="first_name.invalid && (first_name.dirty || first_name.touched)"
                                >
                                    Please enter first name
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="field-item">
                                <input
                                        matInput
                                        type="text"
                                        name="last_name"
                                        #last_name="ngModel"
                                        [(ngModel)]="model.contact.last_name"
                                        placeholder="Last Name"
                                        onkeypress="return /[a-z]/i.test(event.key)"
                                        required
                                />
                                <mat-error
                                        *ngIf="last_name.invalid && (last_name.dirty || last_name.touched)"
                                >
                                    Please enter last name
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="field-item">
                                <input
                                        matInput
                                        type="text"
                                        name="position"
                                        #position="ngModel"
                                        [(ngModel)]="model.contact.position"
                                        placeholder="Position"
                                        required
                                />
                                <mat-error
                                        *ngIf="position.invalid && (position.dirty || position.touched)"
                                >
                                    Please enter position
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="field-item">
                                <input
                                        matInput
                                        type="text"
                                        name="extension"
                                        #email="ngModel"
                                        [(ngModel)]="model.contact.email"
                                        placeholder="Email"
                                        required
                                />
                                <mat-error
                                        *ngIf="email.invalid && (email.dirty || email.touched)"
                                >
                                    Please enter email
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="field-item">
                                <input
                                        matInput
                                        type="text"
                                        name="contact_phone"
                                        #contact_phone="ngModel"
                                        [(ngModel)]="model.contact.phone"
                                        placeholder="Phone"
                                        required
                                />
                                <mat-error
                                        *ngIf="contact_phone.invalid && (contact_phone.dirty || contact_phone.touched)"
                                >
                                    Please enter phone
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <br/>
                <div class="flex flex-row justify-end gap-5">
                    <button
                            mat-raised-button
                            class="dn-button-secondary"
                            type="button"
                            (click)="goBack()"
                    >
                        Go Back
                    </button>
                    <button
                            mat-raised-button
                            [disabled]="!form.form.valid"
                            type="submit"
                            class="dn-button-primary"
                    >
                        Save
                    </button>
                </div>
            </form>
            <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

            <div class="flex justify-start items-center gap-5">
                <button
                        mat-raised-button
                        (click)="openDialog()"
                        class="dn-button-primary">
                    Add more Contact
                </button>
            </div>
            <br/>
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="name">
                    <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header-container>
                        Name
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.last_name + ' ' + element.first_name + ', ' + element.position}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="position">
                    <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header-container>
                        Position
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.position}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header-container>
                        Email
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.email}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="phone">
                    <th

                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header-container>
                        Phone
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.phone}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="operations">
                    <th  mat-header-cell *matHeaderCellDef></th>
                    <ng-container *matCellDef="let element">
                        <td mat-cell style="max-width: 120px;" class="buttons-div">
                            <div class="operation-buttons buttons-div flex flex-row items-center gap-5">
                                <i
                                        class="icon icon-edit"
                                        matTooltip="Edit"
                                        matTooltipPosition="above"
                                        (click)="editPayorContact(element)"
                                ></i>
                                <i
                                        *ngIf="element._id !== model.contact._id"
                                        class="icon icon-delete"
                                        matTooltip="Delete"
                                        matTooltipPosition="above"
                                        (click)="deletePayorContact(element)"
                                ></i>
                            </div>
                        </td>
                    </ng-container>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </mat-card-content>
    </mat-card>
</div>
