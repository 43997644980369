<div class="flex justify-end items-end" style="margin-bottom: 10px;">
    <button mat-raised-button class="dn-button-primary" (click)="toggleColorPicker()">Select Color</button>
    <input
        style="width: 35px; border: 1px solid black; border-radius: 52px; height: 34px; cursor: pointer; margin-left: 5px;"
        [(colorPicker)]="color" [style.background]="color" [cpToggle]='false' (colorPickerChange)='onColorChange(color)'
        *ngIf="colorPickerOpen" />
    <color-picker #colorPicker [cpOutputFormat]="'hex'" [style.display]="colorPickerOpen ? 'block' : 'none'"
        (colorPickerCancel)="closeColorPicker()"></color-picker>
</div>
<table mat-table class="table-row-hover" [dataSource]="dataSource">

    <ng-container matColumnDef="checkbox">
        <th mat-header-cell *matHeaderCellDef></th>
        <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox></mat-checkbox>
            Select All
        </th>
        <td mat-cell *matCellDef="let element">
            <mat-checkbox (change)="handleAddCpt($event, element)">
            </mat-checkbox>
        </td>
    </ng-container>

    <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef> CPTS </th>
        <td mat-cell *matCellDef="let element" style="cursor: pointer;">
            {{element.code}}
            <span>({{element.description}})</span>
    </ng-container>

    <tr mat-header-row class="header-row" *matHeaderRowDef="displayedColumns; sticky:true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<div class="flex justify-end items-end gap-2" style="margin-top: 10px;">
    <button mat-raised-button class="dn-button-primary" (click)="applyColor()">
        Apply
    </button>
    <button mat-raised-button class="dn-button-secondary" mat-dialog-close>
        Cancel
    </button>
</div>

<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close><mat-icon>cancel</mat-icon></button>
</div>