<table  mat-table [dataSource]="dataSource" matSort> 

    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Date
        </th>
        <td mat-cell *matCellDef="let element">
            <ng-container>
                {{element.date}}
            </ng-container>
        </td>
    </ng-container>
    

    <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            ICD 10 
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.code}}
        </td>
    </ng-container>
    

    <ng-container matColumnDef="diagnosis">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Icd 10 Description
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.diagnosis}}
        </td>
    </ng-container> 
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<br />
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>