import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as global from '../global'
import { ToastrService } from 'ngx-toastr';
import { HttpResponse } from '@angular/common/http';
import { DashboardService } from '../dashboard/dashboard.service';
import {MatTableDataSource} from "@angular/material/table";
@Component({
  selector: 'app-dictations-group',
  templateUrl: './dictations-group.component.html',
  styleUrls: ['./dictations-group.component.css']
})
export class DictationsGroupComponent implements OnInit {
  global=global;
  @Input() dictations:Array<any> = [];
  dictationsGroup: any;
  groupDataSource: any;
  dictationsDataSource: any;
  groupDataDisplayedColumns: string[]  = ['uploader_name', 'facility_title', 'count', 'operations'];
  dictationsDisplayedColumns: string[]  = ['audio_title','uploader_name', 'date_time', 'date_of_submission', 'facility_title', 'progress_status', 'audio_length', 'notes_count'];

  visibleTables:string[] = [];
  
  
  downloadProgress = {
    show: false,
    progress: 0,
    mode: 'indeterminate'
  }
  multiDownloadDictationsInprogress:boolean = false;

  multiSelectedTranscriptions:Array<any> = [];

  @Input() showingDetailsId:string = null;
  @Output() setShowingDetailsId:EventEmitter<string> = new EventEmitter<string>();

  constructor(public toastr:ToastrService,public  dashboardService:DashboardService) { 
    if(this.dictations || this.dictations.length === 0) return;
    
    this.dictationsGroup = this.groupDictations(this.dictations);
  }

  ngOnInit() {
    this.dictationsGroup = this.groupDictations(this.dictations);
    
    const count = this.dictations.length;
    const {uploader_name, facility_title} = this.dictations[0];
    this.groupDataSource = new MatTableDataSource([{uploader_name, facility_title, count}]);
    this.dictationsDataSource = new MatTableDataSource(this.dictations);

  }
  
  /**
   * Group dictations by Provider
   */
  groupDictations(dictations:Array<any>) {
    const groups = {};
    dictations.forEach(dictation => {
      if(!Array.isArray(groups[dictation.uploaded_by_id])) {
        groups[dictation.uploaded_by_id] = [dictation];
      } else {
        groups[dictation.uploaded_by_id].push(dictation);
      }
    });

    return groups;
  }

  isTableVisible(groupId) {
    return this.visibleTables.indexOf(groupId) !== -1;
  }

  hideTable(groupId) {
    const i = this.visibleTables.indexOf(groupId);
    if(i !== -1) {
      this.visibleTables.splice(i,1);
    }
    this.showingDetailsId = null;
    this.setShowingDetailsId.emit(this.showingDetailsId);
  }

  showTable(groupId) {
    const i = this.visibleTables.indexOf(groupId);
    if(i === -1) {
      this.visibleTables.push(groupId);
    }
    this.showingDetailsId = groupId;
    this.setShowingDetailsId.emit(this.showingDetailsId);
  }


  
  startMultiDownloadDictations(downloadType) {
      
    this.multiDownloadDictationsInprogress = true;
    this.downloadProgress.show = true;
    this.downloadProgress.progress = 0;

    const fileName = `${this.dictations.length} dictation(s).zip`;
    this.dashboardService.multiDownloadDictationsByIds(downloadType, this.dictations).subscribe((res:any) => {

      if(res instanceof HttpResponse) {
          this.multiDownloadDictationsInprogress = false;
          this.downloadProgress.show = false;
          // saveAs(res, fileName);
          var url = window.URL.createObjectURL(res.body);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element   
      } else {
        if(res.type === 0) {
        } else if(res.type === 3) {
          const {loaded, total} = res;
          const progress = Math.round(loaded / total * 100);
          if(progress > 0) {
            this.downloadProgress.mode = 'determinate';
            this.downloadProgress.progress = progress;
          }
        }
      }
      // if(data) {

      //   saveAs(data, fileName);
      // }
    }, () => {
      this.toastr.error("Something went wrong while downloading dictations", "Failed")
    },
    () => { 
      this.multiDownloadDictationsInprogress = false;
      this.downloadProgress.show = false;
      this.downloadProgress.progress = 0;
    })
  }


}
