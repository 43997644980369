import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from 'src/environments/api-url';
import * as global from '../../includes/global';

@Injectable({
  providedIn: 'root'
})
export class AmazonMedicalTranscribeService {
  currentUser: any;

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private httpclient: HttpClient,
    private toastr: ToastrService,
  ) { 
    this.currentUser = this._authService.currentUser;
  }

  getAwsSignedUrl(){
    let details = {
      // ...this._authService.
      ...this.currentUser
    }
    return this.httpclient.post(global.url + API_URL.AWS.getAwsSignedUrl, { details: details }).pipe();
  }


}
