import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import * as _ from 'lodash';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-mips-questionnaire-181',
  templateUrl: './mips-questionnaire-181.component.html',
  styleUrls: ['./mips-questionnaire-181.component.css'],
})
export class MipsQuestionnaire181Component implements OnInit, DoCheck {
  @Input() measure;
  @Output() onAnswerCalculation = new EventEmitter<any>();
  questions: any[] = [];

  currentQuestionIndex = 0;
  answer: string;
  answerDescription: string;
  currentUser: any;

  model: any;

  showScale: any = {
    elderWithCareGiver: false,
    caregiverOfAnElder: false,
  };
  patientScreeningExclusionsOptions: string[] = [
    'Patient Refused',
    'Participation would jeopardize patient health',
    'No reason',
    "N/A - Complete questions below"
  ];
  scaleOptions: string[] = [
    'No, not at all',
    'Only slightly, doubtful',
    'Possibly, somewhat',
    'Probably, quite likely',
    'Yes, definitely',
  ];
  kindOfAbuseOptions: string[] = [
    'Physical',
    'Psychological',
    'Financial',
    'Neglect (includes passive and active)',
  ];
  whenInterventionNeededOptions: string[] = [
    'Immediately',
    'Within 24 hours',
    '24-72 Hours',
    '1 Week',
    '2 or more weeks',
  ];
  followUpOptions: string[] = [
    'Documented in chart',
    'Referred to Social Service',
    'Reported to State',
    'Reported to Other',
  ];
  whyNotOptions: string[] = ['Refused (patient ineligible)', 'Urgent/Emergent (patient ineligible)', 'No reason given'];
  kindOfAbuseDropdownSettings = {
    singleSelection: false,
    // idField: '_id',
    // textField: 'full_name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 10,
    allowSearchFilter: true,
  };

  negativeCodes = [

    '1123F',

    // from email
    'G8536', 
    'G8735'
  ]

  constructor(
    private authService: AuthService,
  ) {
    this.currentUser = this.authService.currentUser;
    this.resetModel();
  }
  resetModel() {
    this.model = {
      SCREEN_FOR_MALTREATMENT: null,
      // PATIENT_SCREENING_EXCLUSIONS: {
      //   PATIENT_REFUSED: null,
      //   PARTICIPATION_WOULD_JEOPARDIZE_PATIENT_HEALTH: null,
      //   NO_REASON: null,
      // },
      PATIENT_SCREENING_EXCLUSIONS: null,

      ELDER_WITH_A_CAREGIVER: null,
      CAREGIVER_OF_AN_ELDER: null,
      scale: {
        ELDER_WITH_A_CAREGIVER: '',
        CAREGIVER_OF_AN_ELDER: '',
      },
      kindOfAbuseValue: [],
      whenInterventionNeededValue: '',

      FOLLOW_UP_PLAN_DOCUMENTATION: {
        FOLLOW_UP_PLAN_DOCUMENTED: null,
        FOLLOW_UP_OPTION: "",
        WHY_NOT: null,
      },
    };

    this.answer = null;
    this.answerDescription = null;
  }
  resetAnswer() {
    (this.answer = null), (this.answerDescription = null);
  }
  ngDoCheck(): void {
    this.doCheckAnswer();
  }

  ngOnInit(): void {
    this.initQuestions();
  }

  doCheckAnswer() {
    // const {
    //   PATIENT_REFUSED,
    //   PARTICIPATION_WOULD_JEOPARDIZE_PATIENT_HEALTH,
    //   NO_REASON,
    // } = this.model.PATIENT_SCREENING_EXCLUSIONS;
    
    // NO
    if (this.model.SCREEN_FOR_MALTREATMENT === false) {
      this.answer = 'G8536';
      this.answerDescription =
        'No documentation of an elder maltreatment screen, reason not given.';
      return this.doneAnswer();
    }
    if (this.model.SCREEN_FOR_MALTREATMENT === true) {
      // No, No
      // if (
      //   this.model.ELDER_WITH_A_CAREGIVER === false &&
      //   this.model.CAREGIVER_OF_AN_ELDER === false
      // ) {
      //   this.answer = 'G8734';
      //   this.answerDescription =
      //     'Elder maltreatment screen documented as negative, follow-up is not required.';
      //   return this.doneAnswer();
      // }
      if (
        // this.model.ELDER_WITH_A_CAREGIVER === false &&
        // this.model.CAREGIVER_OF_AN_ELDER === false
        this.model.PATIENT_SCREENING_EXCLUSIONS !== null
      ) {
        switch (this.model.PATIENT_SCREENING_EXCLUSIONS) {
          case this.patientScreeningExclusionsOptions[0]:
          case this.patientScreeningExclusionsOptions[1]:
            this.answer = 'G8535';
            this.answerDescription =
              'Elder maltreatment screen not documented; documentation that patient is not eligible for the elder maltreatment screen at the time of the encounter.';
              return this.doneAnswer();

          case this.patientScreeningExclusionsOptions[2]:
            this.answer = 'G8536';
            this.answerDescription =
              'No documentation of an elder maltreatment screen, reason not given.';
              return this.doneAnswer();
        
          default:
            break;
        }
        // if (this.model.PATIENT_SCREENING_EXCLUSIONS === this.patientScreeningExclusionsOptions[2]) {
        //   // The code G8536 is recorded if #3 is selected and the measure is exited
        //   this.answer = 'G8536';
        //   this.answerDescription =
        //     'No documentation of an elder maltreatment screen, reason not given.';
        //   return this.doneAnswer();
        // }
        // if (
        //   [PATIENT_REFUSED, PARTICIPATION_WOULD_JEOPARDIZE_PATIENT_HEALTH].some(
        //     (val) => val === true
        //   )
        // ) {
        //   // The response is recorded and the code G8535 is recorded for the encounter if either #1 or #2 are selected
        //   this.answer = 'G8535';
        //   this.answerDescription =
        //     'Elder maltreatment screen not documented; documentation that patient is not eligible for the elder maltreatment screen at the time of the encounter.';
        //   return this.doneAnswer();
        // }
      }
      if (
        this.model.scale.ELDER_WITH_A_CAREGIVER === this.scaleOptions[0] &&
        this.model.scale.CAREGIVER_OF_AN_ELDER === this.scaleOptions[0]
      ) {
        this.answer = 'G8734';
        this.answerDescription =
          'Elder maltreatment screen documented as negative, follow-up is not required.';
        return this.doneAnswer();
      }
      if (
        this.model.FOLLOW_UP_PLAN_DOCUMENTATION.FOLLOW_UP_PLAN_DOCUMENTED ===
        false
      ) {
        if (
          this.model.FOLLOW_UP_PLAN_DOCUMENTATION.WHY_NOT === this.whyNotOptions[0] ||
          this.model.FOLLOW_UP_PLAN_DOCUMENTATION.WHY_NOT === this.whyNotOptions[1]
        ) {
          this.answer = 'G8941';
          this.answerDescription =
            'Elder maltreatment screen documented as positive, follow- up plan not documented, documentation the patient is not eligible for follow-up plan at the time of the encounter.';
          return this.doneAnswer();
        }
        if (
          this.model.FOLLOW_UP_PLAN_DOCUMENTATION.WHY_NOT === this.whyNotOptions[2]
        ) {
          this.answer = 'G8735';
          this.answerDescription =
            'Elder maltreatment screen documented as positive, follow- up plan not documented, and reason not given.';
          return this.doneAnswer();
        }
      }
      if (
        this.model.FOLLOW_UP_PLAN_DOCUMENTATION.FOLLOW_UP_PLAN_DOCUMENTED ===
          true &&
        this.model.FOLLOW_UP_PLAN_DOCUMENTATION.FOLLOW_UP_OPTION
      ) {
        this.answer = 'G8733';
        this.answerDescription =
          'Elder maltreatment screen documented as positive AND a follow-up plan is documented.';
        return this.doneAnswer();
      }
    }
    
    this.resetAnswer();
  }
  initQuestions() {}
  
  doneAnswer() {
    if(this.answer) {
      
      this.onAnswerCalculation.emit({
        question: {
          measure_number: this.measure.quality_number,
          answer: this.answer,
        },
        goToNext: false
      });
    }
  }

  handleDoneClick() {
    this.onAnswerCalculation.emit({
      question: {
        measure_number: this.measure.quality_number,
        answer: this.answer,
      },
      goToNext: false
    });
  }

  isNegativeCode(answer) {
    
    return [
      // mip 181
      'G8536', 
      'G8735',
      '1123F-8P'
    ].includes(answer.trim().toUpperCase());
  }
}
