<div class="flex flex-wrap gap-2" style="overflow: auto; background: white;">
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="provider">
            <th mat-header-cell *matHeaderCellDef>Provider</th>
            <td mat-cell *matCellDef="let element" style="width: 20%;cursor: pointer;">
                {{getProviderName(element)}}
            </td>
        </ng-container>
        <ng-container matColumnDef="in_progress">
            <th mat-header-cell *matHeaderCellDef>In Progress</th>
            <td mat-cell *matCellDef="let element" style="width: 10%;cursor: pointer;">
                {{getInprogressCount(element)}}
            </td>
        </ng-container>
        <ng-container matColumnDef="completed">
            <th mat-header-cell *matHeaderCellDef>Completed</th>
            <td mat-cell *matCellDef="let element" style="width: 10%;cursor: pointer;">
                {{getCompletedCount(element)}}
            </td>
        </ng-container>
        <ng-container matColumnDef="initial">
            <th mat-header-cell *matHeaderCellDef>Initial</th>
            <td mat-cell *matCellDef="let element" style="width: 10%;cursor: pointer;">
                {{getInitialCount(element)}}
            </td>
        </ng-container>
        <ng-container matColumnDef="follow_up">
            <th mat-header-cell *matHeaderCellDef>Follow Up</th>
            <td mat-cell *matCellDef="let element" style="width: 10%;cursor: pointer;">
                {{getFollowUpCount(element)}}
            </td>
        </ng-container>
        <ng-container matColumnDef="others">
            <th mat-header-cell *matHeaderCellDef>Others</th>
            <td mat-cell *matCellDef="let element" style="width: 10%;cursor: pointer;">
                {{getOthersCount(element)}}
            </td>
        </ng-container>
        <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef>Total</th>
            <td mat-cell *matCellDef="let element" style="width: 10%;cursor: pointer;">
                {{getTotalCount(element)}}
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>