<div class="right-col container flex" id="bar">
  <div class="sidebar-content" style="flex-direction:column">
    <h3 class="dark-blue-heading flex flex-grow" style="width: 100rem;padding: 0px !important; ">
      <div class="flex flex-grow" style="margin-left: 10px;"> MIPS </div>
    </h3>

    <div class="mips-questions-visualization">
      <canvas #barChart></canvas>
    </div>
  </div>
</div>