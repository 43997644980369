<main>
    <div style="min-height: 100%; box-sizing: border-box; background: #44597d; padding: .5rem;" class="flex flex-col">
        <div class="editor-sidebar-wrapper flex flex-1" style="position: relative;">
            <ng-container>
                <div class="flex flex-col gap-2">
                    <div class="mobile-editor transcription-editor flex-grow" aria-readonly="true">

                        <ng-container *ngIf="ckEditorReady">
                            <ckeditor
                                    *ngIf="!isCreatingAddendum"
                                    [(ngModel)]="template.template_text"
                                    name="htmlText"
                                    class="h100"
                                    editorUrl="../../../assets/ckeditor/ckeditor.js"
                                    [readOnly]="false"
                                    [config]="cke4Config" type="divarea" #angularEditor (change)="handleEditorChange($event)" (ready)="onReadyCkeditor($event)"></ckeditor>
                            <ckeditor
                                    *ngIf="isCreatingAddendum"
                                    [(ngModel)]="addendum.text"
                                    name="htmlText"
                                    class="h100"
                                    editorUrl="../../../assets/ckeditor/ckeditor.js"
                                    [readOnly]="false"
                                    [config]="cke4Config" type="divarea" #angularEditor (change)="handleEditorChange($event)" (ready)="onReadyCkeditor($event)"></ckeditor>
                        </ng-container>
                    </div>
                    <div class="flex justify-center items-start">
                        <button class="dn-button-primary"
                                style="min-width: 140px !important" mat-raised-button
                                (click)="saveTemplate()">Save
                        </button>
                    </div>
                </div>
            </ng-container>
            <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
        </div>
    </div>
</main>
