import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FacilityService } from 'src/app/services/facility.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { UserService } from 'src/app/services/Modules/user.service';
import { USER_TYPE } from '../global';
import { AuthService } from 'src/app/services/auth.service';
import { TemplatesService } from '../templates/templates.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-template-facility-association',
  templateUrl: './template-facility-association.component.html',
  styleUrls: ['./template-facility-association.component.css']
})
export class TemplateFacilityAssociationComponent implements OnInit {

  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = ['template', 'facility'];
  facilityDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'title',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 10,
    allowSearchFilter: true,
    enableCheckAll: true,
  };
  facilities: any = [];
  associatedFacilities: any = [];
  companySide: Boolean = false;
  companyId: any;
  loaderId: string = "app-template-facility-association";
  templates: any = [];
  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    PAGE_LIMIT: 20,
    skip: 0
  };
  isSearchingFacilities: boolean = false;
  isSearchingTemplates: boolean = false;
  templateTitle: string = "";
  facilityTitle: string = "";
  timeoutId: any;
  orignalData: any;
  defaultSource: any;
  
  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private userService: UserService,
    private _authService: AuthService,
    private _facilityService: FacilityService,
    private loader: NgxUiLoaderService,
    private _templatesService: TemplatesService
  ) { 
    if (window.location.pathname.includes('company')) {
      this.companySide = true
      this.companyId = this.route.parent.snapshot.params.id

    }
    else {
      this.companySide = false
      this.companyId = ''
    }
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId);
    await this.initCompanyFacilities();
    await this.initTemplateFacilityAssociations();
  }

  async initTemplateFacilityAssociations() {
    this.loader.startLoader(this.loaderId);
    const filter: any = {
      company_id: this.companyId,
      is_deleted: "false",
      template_type : 'system_defined'
    };
    const response: any = await lastValueFrom(this._templatesService.getTemplateFacilityAssociations(filter,
      { facilityTitle: this.facilityTitle, templateTitle: this.templateTitle, pageNo: this.pagination.pageNo }));
    if (response.status === 200) {
      this.templates = response.data.templates;
      this.pagination.totalRecords = response.data.totalRecords;
      this.handlePagination();
      if (this.templates.length > 0)
        this.normalizeData();
    }
    this.isSearchingFacilities = false;
    this.isSearchingTemplates = false;
    this.loader.stopLoader(this.loaderId);
  }

  searchWithPagination(value) {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(async () => {
      if (value)
        this.isSearchingFacilities = true;
      else
        this.isSearchingTemplates = true;
      await this.initTemplateFacilityAssociations();
    }, 1000);
  }

  async initCompanyFacilities() {
    const filter = { assoc_company_ids: this.companyId, is_active: true };
    const projection = {
      title: 1,
      pcc_facId: 1,
      source: 1,
      pcc_2_legged_authentication: 1,
      pcc_orgUuid: 1
    }
    const response: any = await lastValueFrom(this._facilityService.getFacilities(filter, projection));
    if (response.status === 200 && Array.isArray(response.data.array) && response.data.array.length > 0) {
      this.facilities = response.data.array;
      // for (const facility of this.facilities) {
      //   if (facility.source === 'PointClickCare' || facility.pcc_facId)
      //     facility.title = `<img src="../../../assets/icons/pcc-icon.svg" alt="" style="width: 34px;
      //    height: 17px; margin-top: -4px;"> ${facility.title}`;
      //   else if (facility.source === 'MatrixCare' || facility.pcc_facId)
      //     facility.title = `<img src="../../../assets/icons/pcc-icon.svg" alt="" style="width: 34px;
      //     height: 17px; margin-top: -4px;"> ${facility.title}`;
      //   else if (facility.source != 'PointClickCare' && !facility.pcc_facId && facility.source != 'MatrixCare')
      //     facility.title = `<img class="dn-icon" src="../../../assets/img/DN.png" alt=""> ${facility.title}`;
      // }
    }
  }

  normalizeData() {
    if (this.facilities.length > 0) {
      for (const template of this.templates) {
        template.facilities = this.facilities;
      }
    }
    this.orignalData = JSON.parse(JSON.stringify(this.templates));
    this.dataSource.data = this.templates;
    
  }

  handlePagination() {
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    if (this.pagination.skip + this.templates.length < this.pagination.totalRecords) {
      this.pagination.hasNextPage = true;
    }
    else {
      this.pagination.hasNextPage = false;
    }
    if (this.pagination.skip > 0) {
      this.pagination.hasPrevPage = true;
    }
    else {
      this.pagination.hasPrevPage = false;
    }
  }

  goToPreviousPage() {
    this.pagination.pageNo--;
    this.initTemplateFacilityAssociations();
  }
  goToNextPage() {
    this.pagination.pageNo++;
    this.initTemplateFacilityAssociations();
  }

  async onDataSelection($event, index) {
    // let providers = element.associated_provider_ids.map(a => a._id);
    // let idx = this.users.findIndex(user => user._id.toString == element._id.toString());
    // this.users[idx] = element;
    // this.dataSource.data = this.users;
    let template = $event.element;
    let facility = $event.data;
    let provider_id;
        if(template === 'selectAll') {
          template.associatedFacilities = template.facilities;
          this.onSelectAllTemplate(template.facilities, template, index);
          return;
        }
        else if(template === 'unSelect') {
          facility.associatedFacilities = [];
          this.onDeSelectAllTemplate([], template, index);
          return
        }
        else {
          if($event.status) {
            const filter = { _id: facility._id };
            const query = { $addToSet: { template_ids_ref: template._id } };
            template.associatedFacilities = [...template.associatedFacilities, facility];
            const response: any = await lastValueFrom(this._templatesService.updateTemplateAssociation(filter, query));
            if (response.status == 200) {
              // this.templates[index].associatedFacilities.push(facility);
              this.toastr.success("Association Updated Successfully");
              this.loader.stopLoader(this.loaderId);
            }
            else {
              this.toastr.warning(response.message);
              this.loader.stopLoader(this.loaderId);
            }
          } 
          else {
            this.onTemplateDeSelect(facility, template, index);
          }
        }
    
    
  }
  
  onDataRemove($event, index) {
      this.onTemplateDeSelect($event.data, $event.element, index);
  }

  async onSelectAllTemplate(facilities, template, index) {
    this.loader.startLoader(this.loaderId);
    const filter = { _id: { $in: facilities.map(facility => facility._id) }, shouldUpdateMultiple: true };
    const query = { $addToSet: { template_ids_ref: template._id } };
    const response: any = await lastValueFrom(this._templatesService.updateTemplateAssociation(filter, query));
    if (response.status == 200) {
      this.templates[index].associatedFacilities = facilities;
      this.toastr.success("Association Updated Successfully");
      this.loader.stopLoader(this.loaderId);
    }
    else {
      this.toastr.warning(response.message);
      this.loader.stopLoader(this.loaderId);
    }
  }

  async onTemplateDeSelect(facility, template, index) {
    this.loader.startLoader(this.loaderId);
    const filter = { _id: facility._id };
    const query = { $pull: { template_ids_ref: template._id } };
    const response: any = await lastValueFrom(this._templatesService.updateTemplateAssociation(filter, query));
    if (response.status == 200) {
      template.associatedFacilities = template.associatedFacilities.filter(ele => ele._id !== facility._id);
      this.templates[index].associatedFacilities = this.templates[index].associatedFacilities.filter(ele => ele._id !== facility._id);
      this.toastr.success("Association Updated Successfully");
      this.loader.stopLoader(this.loaderId);
    }
    else {
      this.toastr.warning(response.message);
      this.loader.stopLoader(this.loaderId);
    }
  }

  async onDeSelectAllTemplate(facilities, template, index) {
    this.loader.startLoader(this.loaderId);
    const filter = { _id: { $in: template.associatedFacilities.map(facility => facility._id) }, shouldUpdateMultiple: true };
    const query = { $pull: { template_ids_ref: template._id } };
    const response: any = await lastValueFrom(this._templatesService.updateTemplateAssociation(filter, query));
    if (response.status == 200) {
      this.templates[index].associatedFacilities = [];
      this.toastr.success("Association Updated Successfully");
      this.loader.stopLoader(this.loaderId);
    }
    else {
      this.toastr.warning(response.message);
      this.loader.stopLoader(this.loaderId);
    }
  }


// isLoggedInFacility(facility:any) {
//   return this.pccService.isLoggedInFacility(facility)
// }

// isLoggedIntoPCC() {
//   return !!this.pccUserInfo;
// }
  

}
