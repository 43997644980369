<div [ngClass]="{'container': (companySide || user_dashboard) && !(nursingSide)}">

    <h3 class="dark-blue-heading">
        <ng-container *ngIf="!user_dashboard">
            <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
                Back
            </button>
        </ng-container>

        <label [ngClass]="{'label-margin': !user_dashboard}"> Healing Success Rate Report </label>

        <button style="margin-left: auto;" mat-raised-button class="dn-button-primary" (click)="exportWoundHealingRateReportAsXLSX();">Export
            As XLSX
        </button>
    </h3>

    <div class="search-block theme-form flex justify-start items-center" 
        style="background-color: white; border-radius: 4px; padding-top: 8px; padding-bottom: 5px; padding-left: 8px;padding-right: 8px;">
        
        <div class="flex flex-grow justify-start items-center gap-2">

            <mat-label style="margin-right:20px;color:#999">Date of Creation </mat-label>
            <input style="width: 180px;" type="text" ngxDaterangepickerMd [(ngModel)]="filter.creationDate"
                (datesUpdated)="changeCreationDate($event)" class="mat-like-input" [ranges]="preDateRanges"
                [alwaysShowCalendars]="true" [maxDate]="currentDate" [linkedCalendars]="true"
                [showClearButton]="true" />

            <mat-form-field>
                <mat-label>Select Provider</mat-label>
                <input type="text" placeholder="Select Provider" aria-label="provider" matInput
                    [formControl]="providerControl" #trigger="matAutocompleteTrigger"
                    [matAutocomplete]="providerAutoControl">
                <mat-icon matSuffix style="cursor: pointer;" (click)="showDropdown()">
                    keyboard_arrow_down</mat-icon>
                <mat-autocomplete #providerAutoControl="matAutocomplete">
                    <mat-option *ngFor="let provider of filteredProviders | async" [value]="provider.full_name"
                        (onSelectionChange)="onSelectProvider(provider._id);">
                        {{provider.full_name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <button mat-raised-button class="dn-button-primary" (click)="handleApplyFilter();">Apply Filter</button>

            <button mat-raised-button class="dn-button-secondary" (click)="resetFilter();">Reset Filter</button>
        </div>
    </div>

    <mat-card class="vertical-height">

        <div class="table-height">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                <ng-container matColumnDef="pod">
                    <th mat-header-cell *matHeaderCellDef> Pod </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.pod}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="provider">
                    <th mat-header-cell *matHeaderCellDef> Provider </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.fullName}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="woundsCount">
                    <th mat-header-cell *matHeaderCellDef> Total Wounds </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.woundsCount}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="remissionWoundsCount">
                    <th mat-header-cell *matHeaderCellDef> Wounds In Remission </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.remissionWoundsCount}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="amputatedWoundsCount">
                    <th mat-header-cell *matHeaderCellDef> Amputated Wounds </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.amputatedWoundsCount}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="remissionRate">
                    <th mat-header-cell *matHeaderCellDef> Remission Rate </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.remissionRate}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="amputationRate">
                    <th mat-header-cell *matHeaderCellDef> Amputatation Rate </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.amputationRate}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-card>
</div>
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>