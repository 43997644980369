<div class="flex justify-between items-center gap-2">
    <ng-multiselect-dropdown name="provider" style="width: 100% !important;" appearance="fill"
    [settings]="providerDropdownSettings"
    [placeholder]="placeHolder"
    [data]="providers"
    (onSelect)="onProviderSelect($event)" 
    (onDeSelect)="onProviderDeselect($event);"
    (onSelectAll)="onAllProviderSelect($event)" 
    (onDeSelectAll)="onAllProviderDeSelect($event)"
    >
   </ng-multiselect-dropdown>
 
    <div>
        <button mat-flat-button mat-button color="primary" mat-dialog-close style="background-color: #43adff;" [disabled]="sharedProviders.length === 0" (click)="sharePharses()">
            Share
        </button>
    </div>
</div>