<form #form="ngForm" (ngSubmit)="handleSubmit(form)">
    <div  class="fields-sections flex flex-col">
        
        <div class="fields-section" *ngIf="model">
            <mat-form-field class="field-item">
                <input matInput placeholder="Company Name" [(ngModel)]="model.name" name="name" (keypress)="keyPressAlphanumeric($event)">
            </mat-form-field>
            
            <mat-form-field class="field-item">
                <input matInput type="email" placeholder="Email" [(ngModel)]="model.email" name="email" required pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$">
            </mat-form-field>
            <mat-form-field class="field-item" style="grid-column-start: 1;grid-column-end: 3;">
                <input matInput placeholder="Address 1" [(ngModel)]="model.address" name="address" required (keypress)="keyPressAlphanumeric1($event)">
            </mat-form-field>
            <mat-form-field class="field-item" style="grid-column-start: 1;grid-column-end: 3;">
                <input matInput placeholder="Address 2" [(ngModel)]="model.address_2" name="address_2" (keypress)="keyPressAlphanumeric1($event)">
            </mat-form-field>
            <mat-form-field class="field-item">
                <mat-select placeholder="State" [(ngModel)]="model.state" name="state" required>
                    <mat-option *ngFor="let state of usa_states; let i = index" [value]="state.abbreviation">
                        {{state.abbreviation}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="field-item">
                <input matInput placeholder="City" [(ngModel)]="model.city" name="city" required (keypress)="allowOnlyText($event)"> 
            </mat-form-field>
            <mat-form-field class="field-item">
                <input matInput placeholder="Zip" [(ngModel)]="model.zip" name="zip" required (keypress)="keyPressNumbers($event)">
            </mat-form-field>
            <mat-form-field class="field-item">
                <input matInput placeholder="Phone" [(ngModel)]="model.phone" name="phone" required maxlength="12" (keypress)="keyPressNumbers($event)">
            </mat-form-field>
        </div>
        <br>
        <mat-checkbox class="example-margin" (change)="onCheckboxChange($event)">Set Legal Authenticator </mat-checkbox>
        <div class="fields-section" *ngIf="setLegalAuthenticator">
            <mat-form-field style="width: 100%">
                <mat-label>First Name</mat-label>
                <input matInput type="text" placeholder="First Name" [(ngModel)]="legalModel.firstName"
                    name="firstName">
            </mat-form-field>

            <mat-form-field style="width: 100%">
                <mat-label>Last Name</mat-label>
                <input matInput type="text" placeholder="Last Name" [(ngModel)]="legalModel.lastName" name="lastName">
            </mat-form-field>

            <mat-form-field style="width: 100%">
                <mat-label>Street Address</mat-label>
                <input matInput type="text" placeholder="Street Address" [(ngModel)]="legalModel.streetAddressLine"
                    name="streetAddressLine">
            </mat-form-field>

            <mat-form-field style="width: 100%">
                <mat-label>Represented Organization</mat-label>
                <input matInput type="text" placeholder="Represented Organization"
                    [(ngModel)]="legalModel.representedOrganization" name="representedOrganization">
            </mat-form-field>

            <mat-form-field style="width: 100%">
                <mat-label>City</mat-label>
                <input matInput type="text" placeholder="City" [(ngModel)]="legalModel.city" name="legalAuthCity">
            </mat-form-field>

            <mat-form-field style="width: 100%">
                <mat-label>State</mat-label>
                <input matInput type="text" placeholder="State" [(ngModel)]="legalModel.state" name="legalAuthState">
            </mat-form-field>

            <mat-form-field style="width: 100%">
                <mat-label>ZipCode</mat-label>
                <input matInput type="text" placeholder="ZipCode" [(ngModel)]="legalModel.zipCode" name="zipCode">
            </mat-form-field>

            <mat-form-field style="width: 100%">
                <mat-label>Telecom</mat-label>
                <input matInput type="text" placeholder="Telecom" [(ngModel)]="legalModel.telecom" name="telecom">
            </mat-form-field>
        </div>
    </div>
    <br />
    <div class="flex justify-end gap-5">
        <button mat-raised-button class="dn-button-primary" type="submit">Save</button>
        
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</form>