<div class="container flex flex-col" *ngIf="showBar">
    <mat-card class="flex-grow">
        <mat-card-content>
            <mat-tab-group [selectedIndex]='defaultTabIndex' (selectedTabChange)="changeCurrentTab($event)">
                <mat-tab label="Profile"> 
                    <div class="mat-tab-content">
                        <app-doctor-profile-information></app-doctor-profile-information>
                    </div>
                </mat-tab>

                <mat-tab label="Census" *ngIf="!onlyProfile">
                    <div class="mat-tab-content">
                        <app-census-list-v1></app-census-list-v1>
                    </div>
                </mat-tab>
                
                <mat-tab label="Facilities" *ngIf="!onlyProfile">
                    <div class="mat-tab-content">
                        <app-doctor-profile-facilities></app-doctor-profile-facilities>
                    </div>
                </mat-tab>
               
                <mat-tab label="Charge Capture" *ngIf="(currentUser.additional_user_role != global.USER_TYPE.CNP && !onlyProfile)&&!(selectedUserType==global.USER_TYPE.SNF_WC_NURSE)">
                    <div class="mat-tab-content">
                        <app-biller-dashboard></app-biller-dashboard>
                    </div>
                </mat-tab> 
                <mat-tab label="Templates" *ngIf="!onlyProfile">
                    <div class="mat-tab-content">
                        <app-template-explorer template_type="user_defined"></app-template-explorer>
                    </div>
                </mat-tab>
                 
                <mat-tab label="Associated SNF WC Nurse" *ngIf="(!onlyProfile)&&(selectedUserType==global.USER_TYPE.SNF_WC_NURSE)">
                    <div class="mat-tab-content">
                        <app-associated-provider-information></app-associated-provider-information>
                    </div>
                </mat-tab>
                <mat-tab label="Associated Providers" *ngIf="(!onlyProfile)&&!(selectedUserType==global.USER_TYPE.SNF_WC_NURSE)">
                    <div class="mat-tab-content">
                        <app-associated-provider-information></app-associated-provider-information>
                    </div>
                </mat-tab>
                <mat-tab label="ScriptSure" *ngIf="!onlyProfile && currentUser.product_owner">
                    <div class="mat-tab-content">
                        <app-doctor-profile-scriptsure [userDetail]="userDetail"></app-doctor-profile-scriptsure>
                    </div>
                </mat-tab>
                
                <mat-tab label="PCC Permissions" *ngIf="currentUser.has_pcc_account === 'true'">
                    <div class="mat-tab-content">
                        <app-pcc-account-permissions [user]="userDetail"></app-pcc-account-permissions>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </mat-card>
</div>