import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment'
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FacilityService } from 'src/app/services/facility.service';
import { Location } from '@angular/common';
import { WoundService } from 'src/app/services/wound.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as global from 'src/app/includes/global';
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { UserService } from 'src/app/services/Modules/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-kent-visit-report',
  templateUrl: './kent-visit-report.component.html',
  styleUrls: ['./kent-visit-report.component.css']
})
export class KentVisitReportComponent implements OnInit {

  currentDate = moment(new Date());
  minDate = moment(new Date()).subtract(6, 'days');
  loaderId = 'app-visit-report';
  filter: any = {
    filterServiceDate: {
      startDate: moment().subtract(6, 'days').toDate(),
      endDate: moment().toDate()
    },
    facilityId: null,
    providerId: null,
    companyId: null
  };
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 1 Week': [moment().subtract(6, 'days'), moment()],
    // 'Last 2 Week': [moment().subtract(13, 'days'), moment()],
    // 'Last 3 Week': [moment().subtract(20, 'days'), moment()],
    // 'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    // 'This Month': [moment().startOf('month'), moment().endOf('month')],
    // 'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  isfilterServiceDateFilterSelected: boolean = true;
  filteredFacilities: Observable<string[]>;
  displayedColumns: string[] = [
    'patient', 'provider', 'facility', 'sub_seq', 'initial', 'grand_total'];
  facilityControl = new FormControl();
  providerControl = new FormControl();
  facilities: Array<any> = [];
  providers: Array<any> = [];
  filteredProviders: Observable<string[]>;
  companyId: any;
  visitReport: any = [];
  dataSource: any = new MatTableDataSource([]);
  initialTotalNotes: number = 0;
  subsequentTotalNotes: number = 0;
  grandTotal: number = 0;
  currentUser: any;
  companySide: boolean;
  nursingSide: boolean;
  user_dashboard: Boolean = false;
  constructor(
    private router: Router,
    private _toastr: ToastrService,
    private _facilityService: FacilityService,
    private _route: ActivatedRoute,
    private _location: Location,
    private _wound: WoundService,
    private loader: NgxUiLoaderService,
    private _userTypesRelationshipService: UserTypesRelationshipService,
    private _userService: UserService,
    private _authService: AuthService,
    private _commonService: CommonService

  ) {
    this.filter.companyId = this._route.parent.snapshot.params.id;
    this.currentUser = this._authService.currentUser;
    
    if(this._route.parent.snapshot.params.id){
      this.companySide=true;
    }else{
      this.filter.companyId = this.currentUser.company_id;
    }
    if(window.location.pathname.includes('dashboard')){
      this.user_dashboard = true;
    }
    if(window.location.pathname.includes('nursing')){
      this.nursingSide=true;
    }
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId);
    await this.initFacilities();
    // await this.getProviderList();
    this.selectDefaultFacility();
    this.selectDefaultProvider();
  }
  private filterFacilities(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) !== -1);
    }
    else {
      return this.facilities;
    }
  }
  private filterProvider(value: string): string[] {
    if (value && this.providers.length > 0) {
      const filterValue = value.toLowerCase();
      // return this.providers.filter(option => (option.middle_name && option.middle_name.toLowerCase().indexOf(filterValue) !== -1) || option.last_name.toLowerCase().indexOf(filterValue) !== -1 || option.first_name.toLowerCase().indexOf(filterValue) !== -1);
      return this.providers.filter(option => option.full_name.toLowerCase().match(filterValue));
    }
    else {
      return this.providers;
    }
  }
  getProvidersByFacility(facilityId) {
    return new Promise<void>((resolve, reject) => {
      this._userService.getUsersByFacility(facilityId).subscribe((response: any) => {
        if (response.status === 200) {
          this.providers = response.data;
          this.providers.unshift({ _id: 'all', full_name: 'All' });
          this.selectDefaultProvider();
          this.showProviderDropdown();
          resolve(null);
        }
      }, error => {
        reject('error => ' + error.toString());
      });
    });
  }
  getProviderList() {
    const filter: any = {
      company_id: this.filter.companyId,
      user_type: global.USER_TYPE.DOCTOR
    };
    const projection = {
      first_name: 1,
      last_name: 1,
      full_name: 1
    };
    return new Promise<void>((resolve, reject) => {
      this._userService.getUsers(filter, projection).subscribe((response: any) => {
        if (response.status === 200) {
          this.providers = response.data;
          this.providers.unshift({ _id: 'all', full_name: 'All' });
          this.selectDefaultProvider();
          this.showProviderDropdown();
          resolve(null);
        }
      }, error => {
        reject('error => ' + error.toString());
      });
    });

  }

  onSelectProvider(event) {
    console.log(event);
    this.filter.providerId = event;
    if (event === 'all') {
      this.filter.providerId = null;
    }
    else {
      this.filter.providerId = event;
    }
  }
  showProviderDropdown(): void {
    this.filteredProviders = this.providerControl.valueChanges.pipe(
      startWith(''),
      map((value:any) => this.filterProvider(value)));
  }

  showFacilityDropdown(): void {
    this.filteredFacilities = this.facilityControl.valueChanges.pipe(
      startWith(''),
      map((value:any) => this.filterFacilities(value)));
  }
  selectDefaultProvider() {
    if (this.providers.length > 0) {
      this.filter.providerId = null;
      this.providerControl.setValue(this.providers[0].full_name);
    }
  }
  selectDefaultFacility() {
    if (this.facilities.length > 0) {
      this.filter.facilityId = null;
      this.facilityControl.setValue(this.facilities[0].title);
    }
  }
  initVisitReport() {
    this.grandTotal = 0;
    this.subsequentTotalNotes = 0;
    this.initialTotalNotes = 0;
    this.loader.startLoader(this.loaderId);
    this._wound.getKentVisitReport(this.filter).subscribe((response: any) => {
      if (response.status == 200) {
        this.visitReport = response.data;
        
        for (const report of this.visitReport) {
          if (this.visitReport.length > 0) {
            this.grandTotal += report.grandTotal;
            this.initialTotalNotes += report.providerGroup.initialCount;
            this.subsequentTotalNotes += report.providerGroup.subSeqCount;

          }
        }
        this.dataSource.data = this.visitReport;
        console.log("this.visitReport", this.visitReport);
        this.loader.stopLoader(this.loaderId);
      }
      else {
        this.loader.stopLoader(this.loaderId);

      }
    }, err => {
      this.loader.stopLoader(this.loaderId);
      this._toastr.error(err, "ERROR");
    }
    );
  }

  async initFacilities() {
    const filter = {
      assoc_company_ids: this.filter.companyId,
      is_active: true
    };
    const projection = {
      title: 1,
      source: 1,
      assoc_company_ids: 1
    };

    let companyAssocPopulation: facilityPopulationInterface = {
      collection: 'companies',
      field: ["assoc_company_ids"],
      filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: [true, { $in: ['$_id', '$$assoc_company_ids'] }] }] } },
      project: { name: 1 },
    };
    
    const facilitiesResponse: any = await this._facilityService.getFacilities(filter, projection, [companyAssocPopulation]).toPromise();
    if (facilitiesResponse.status == 200) {
      this.facilities = facilitiesResponse.data.array;
      this.filter.facilityId = this.facilities[0]._id; 
      this.onSelectFacility(this.facilities[0]._id);
      this.initVisitReport();
      // this.facilities.unshift({ _id: 'all', title: 'All' });
      this.showFacilityDropdown();
    }
  }
  async onSelectFacility(value) {
      this.filter.facilityId = value;
      if (value === 'all') {
        this.filter.facilityId = null;
        await this.getProviderList();
      }
      else {
        await this.getProvidersByFacility(this.filter.facilityId);
      }
    
  }

  changefilterServiceDate(event) {
    if (event.startDate && event.endDate) {
      const noOfDays = this._commonService.getDifferenceOfDates(event.startDate, event.endDate,'days');
      if (noOfDays > 7) {
        this.filter.filterServiceDate = {};
        this.filter.filterServiceDate.startDate = moment(event.startDate).toDate();
        this.filter.filterServiceDate.endDate = moment(this.filter.filterServiceDate.startDate).add(6, "days").startOf('day').toDate();
        this._toastr.error('Can not select more than 7 days', 'Date Error');
      }
      else {
        this.filter.filterServiceDate.startDate = moment(event.startDate).toDate();
        this.filter.filterServiceDate.endDate = moment(event.endDate).toDate();
      }
    }
  }

  goBack() {
    this._location.back();
  }
  handleApplyFilter() {
    this.initVisitReport();
  }

  resetFilter() {
    delete this.filter.filterServiceDate;
    const defaultDate = {
      startDate: moment().subtract(6, 'days').toDate(),
      endDate: moment().toDate()
    };
    this.filter.filterServiceDate = defaultDate;
    this.selectDefaultFacility();
    this.selectDefaultProvider();
    this.initVisitReport();
  }
  exportVisitReportAsXLSX() {
    const infoToastr = this._toastr.info('Exporting as XLSX...', 'Please wait', { disableTimeOut: true });
    this._wound.exportVisitReportAsXLSX(this.filter).subscribe((response: any) => {
      if (response instanceof HttpResponse) {
        const url = window.URL.createObjectURL(response.body);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `Visit Report`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
        this._toastr.clear(infoToastr.toastId);
      }
    }, error => {
      // console.log("err", error);
      this._toastr.clear(infoToastr.toastId);
    });
  }

}
