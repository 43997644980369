<div calss="container">
    <div class="flex items-start justify-between gap-5">
        <div *ngIf="providerProvider" class="flex justify-start items-center w-full">
            <mat-card class="search-card flex-grow" >
            <input type="text" class="search-field" placeholder="Search Provider" name="title"
            [(ngModel)]="userNameRegExp" (ngModelChange)="searchProviders()" />
            <mat-spinner *ngIf="isProviderLoaded" style="margin-left: auto;" matSuffix [diameter]="30">
            </mat-spinner>
        </mat-card>
        </div>
        <div class="flex justify-start items-center gap-2">
            <div class="flex flex-grow justify-center items-center gap-2 table-nav">
                <button mat-raised-button class="dn-button-primary" (click)="goToPreviousPage()"
                    [disabled]="!pagination.hasPrevPage"> Prev</button>
                <span style="width: 120px">
                    {{pagination.skip + providers.length === 0 ? 0 : pagination.skip + 1 }} - {{pagination.skip +
                    providers.length}} out
                    of {{pagination.totalRecords}}
                </span>
                <button mat-raised-button class="dn-button-primary" (click)="goToNextPage()"
                    [disabled]="!pagination.hasNextPage">Next</button>
            </div>
        </div>
    </div>
    <div style="height: 10px;"></div>
    <div style="height: 70vh;overflow: auto;">
        <table
                mat-table [dataSource]="dataSource" matSort  >
    
            <ng-container matColumnDef="provider_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Provider Name</th>
                <td mat-cell *matCellDef="let element" style="width: 20%"> 
                    {{element.full_name}} 
                    <div style="font-size: 11px;">{{element.email? element.email : ''}}</div> 
                </td>
            </ng-container>
    
            <ng-container matColumnDef="asso_provider_name">
                <th mat-header-cell *matHeaderCellDef> Associated Providers </th>
                <td mat-cell *matCellDef="let element ; let i = index;" style="width: 60%">
                    <app-mat-multi-select-chips [element]="element" [ngModelData]="element.assos_providers || []" [isMulti]="true" [isProvider]="true" [allData]="element.dropdown  |  orderBy:'full_name'" [providerKey]="'assos_providers'" [selectAll]="true" (onDataSelection)="onDataSelection($event)" (onDataRemove)="onDataRemove($event)"></app-mat-multi-select-chips> 
                </td>
    
            </ng-container>
    
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
        </table>
    </div>


</div>