<table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="recordedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Recorded Date
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.recordedDate |date: 'MM/dd/yyyy'}}
        </td>
    </ng-container>
    <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Value
        </th>
        <td mat-cell *matCellDef="let element">
            {{getValue(element)}}
        </td>
    </ng-container>
    <ng-container matColumnDef="method">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Method
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.method}}
        </td>
    </ng-container>
    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Type
        </th>
        <td mat-cell *matCellDef="let element">
            {{startCase(element.type)}}
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<br />
<div *ngIf="apiData" class="flex justify-start items-center gap-2">
    <button mat-raised-button class="dn-primary-button" [disabled]="apiData.paging.page === 1" (click)="navigate(-1)">Prev</button>
    <span style="font-size: 12px;">Page {{apiData.paging.page}}</span>
    <button mat-raised-button class="dn-primary-button" [disabled]="!apiData.paging.hasMore" (click)="navigate(1)">Next</button>
</div>
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
