import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Input, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { WoundService } from 'src/app/services/wound.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { lastValueFrom } from 'rxjs';
import {WoundTag} from '../wound-tags/wound-tags.component';
import { FormControl } from '@angular/forms';
import { CptIcdAssociationService } from '../../cpt-icd-association/cpt-icd-association.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmationComponent } from '../../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

export interface WoundEtiology {
  _id?:string,
  label:string,

  createdAt?:string,
  updatedAt?:string,
}
export interface WoundEtiologyGroup {
  _id?:string,
  etiology_id:string,
  title:string,

  createdAt?:string,
  updatedAt?:string,
}
export interface WoundEtiologyGroupDetails {
  _id?:string,
  group_id:string,
  icd_id:any,
  tags: WoundTag[],
  
  createdAt?:string,
  updatedAt?:string,
}

@Component({
  selector: 'app-wound-groups',
  templateUrl: './wound-groups.component.html',
  styleUrls: ['./wound-groups.component.css']
})
export class WoundGroupsComponent implements OnInit {
  @Input() company_id:string;
  separatorKeysCodes: number[] = [ENTER, COMMA];
 
  etiologies: WoundEtiology[] = [];
  activeEtiology:WoundEtiology = null;


  woundEtiologyGroups: WoundEtiologyGroup[] = [];
  activeWoundEtiologyGroup:WoundEtiologyGroup = null;


  woundEtiologyGroupDetails: WoundEtiologyGroupDetails[] = null;

  loaderId = 'app-wound-groups';
  newEtiologyInputValue:string = '';
  showNewEtiologyInput = false;
  editingEtiology:WoundEtiology= null;


  newWoundEtiologyGroupInputValue:string = '';
  showNewWoundEtiologyGroupInput = false;
  editingWoundEtiologyGroup:WoundEtiologyGroup= null;

  editingWoundEtiologyGroupDetails:WoundEtiologyGroupDetails= null;

  selectedICDCode;
  icdCodeList: any = [];
  currentUser: any;


  constructor(private _wound: WoundService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private _cpticd: CptIcdAssociationService,
    private _authService: AuthService,
    private loader: NgxUiLoaderService) {
    this.currentUser = this._authService.currentUser;
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId);
    await Promise.all([this.initWoundEtiologies(), this.getICDs()]);
    this.loader.stopLoader(this.loaderId);
  }

  async initWoundEtiologies() {
    let {status, data} = await lastValueFrom(this._wound.getWoundEtiologies(this.company_id)) as any;
    if (status == 200) {
      this.etiologies = data as WoundEtiology[];
    }
  }

  async initWoundEtiologyGroup() {
    let {status, data} = await lastValueFrom(this._wound.getWoundEtiologyGroups(this.activeEtiology._id)) as any;
    if (status == 200) {
      this.woundEtiologyGroups = data as WoundEtiologyGroup[];
      console.log(this.woundEtiologyGroups)
    }
  }

  async initWoundEtiologyGroupDetails() {
    
    let {status, data} = await lastValueFrom(this._wound.getWoundEtiologyGroupDetails(this.activeWoundEtiologyGroup._id)) as any;
    if (status == 200) {
      this.woundEtiologyGroupDetails = data as WoundEtiologyGroupDetails[];
      // this.selectedICDCode = this.icdCodeList.find(icdcode => icdcode.code == this.woundEtiologyGroupDetails.icd_id.code);
      // 
    }
  }

  rowConfirmed() {
    return true;
  }


  isEditingEtiology(etiology, i) {
    return this.editingEtiology === etiology;
  }

  isEditingWoundEtiologyGroup(woundEtiologyGroup, i) {
    return this.editingWoundEtiologyGroup === woundEtiologyGroup;
  }

  isEditingWoundEtiologyGroupDetails(woundEtiologyGroupDetails, i) {
    return this.editingWoundEtiologyGroup === woundEtiologyGroupDetails;
  }

  handleAddNewEtiology() {
    this.showNewEtiologyInput = true;
  }
  handleNewEiologyInputValueChange($event) {
    // is enter key pressed
    console.log({
      '$event.keyCode': $event.keyCode,
      $event
    })
    if ($event.keyCode === ENTER) {
      this.addNewEtiology(this.newEtiologyInputValue);
      this.newEtiologyInputValue = '';
      this.showNewEtiologyInput = false;
    }
  }

  async addNewEtiology(value) {
    let {status, data} = await lastValueFrom(this._wound.addWoundEtiology(this.company_id, value)) as any;
    if (status == 200) {
      this.etiologies.push(data as WoundEtiology);
      this.newEtiologyInputValue = '';
      this.showNewEtiologyInput = false;
      this.toastr.success('Successfully Added');
    }
  }
  editEtiology(etiology, i) {
    console.log(etiology);
    this.editingEtiology = etiology;
    this.newEtiologyInputValue = etiology.label;
  }

  handleEditEiologyInputValueChange($event, i) {
    // is enter key pressed
    console.log({
      '$event.keyCode': $event.keyCode,
      $event,
      i
    })
    if ($event.keyCode === ENTER) {
      this.updateEtiology($event.target.value, i);
      this.editingEtiology = null;
    }
  }

  updateEtiology(value, i) {
    console.log({value, i})
    this.etiologies[i].label = value;
    this.editingEtiology = null;
  }


  async deleteEtiology(etiology, i) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: `Are you sure you want to delete this ${etiology.label} etiology?`
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result === "true") {
        const index = this.etiologies.findIndex(_etiology => _etiology._id === etiology._id);
        const { status, data } = await lastValueFrom(this._wound.updateWoundEtiology(etiology._id, undefined, true)) as any;
        if (status == 200) {
          if (index >= 0) {
            this.etiologies.splice(index, 1);
            if (this.activeEtiology != null) {
              if (this.activeEtiology._id === etiology._id) {
                this.activeEtiology = null;
                this.activeWoundEtiologyGroup = null;
              }
            }
            this.toastr.success(`Etiology ${etiology.label} removed Successfully`, 'Success');
          }
        }
      }
    });
  }

  async addNewWoundEtiologyGroup(value, i?) {
    let {status, data} = await lastValueFrom(this._wound.addWoundEtiologyGroup(this.activeEtiology._id, value)) as any;
    if (status == 200) {
      this.woundEtiologyGroups.push(data as WoundEtiologyGroup);
      this.newWoundEtiologyGroupInputValue = '';
      this.showNewWoundEtiologyGroupInput = false;
    }
  }
  editWoundEtiologyGroup(woundEtiologyGroup, i) {
    console.log(woundEtiologyGroup);
    this.editingWoundEtiologyGroup = woundEtiologyGroup;
    this.newEtiologyInputValue = woundEtiologyGroup.title;
  }


  handleAddNewWoundEtiologyGroup() {
    this.showNewWoundEtiologyGroupInput = true;
  }
  handleEditWoundEtiologyGroupInputValueChange($event, i) {
    // is enter key pressed
    console.log({
      '$event.keyCode': $event.keyCode,
      $event,
      i
    })
    if ($event.keyCode === ENTER) {
      this.addNewWoundEtiologyGroup($event.target.value, i);
      this.editingWoundEtiologyGroup = null;
    }
  }

  updateWoundEtiologyGroup(value, i) {
    console.log({value, i})
    this.woundEtiologyGroups[i].title = value;
    this.editingWoundEtiologyGroup = null;
  }

  async deleteWoundEtiologyGroup(woundEtiologyGroup, i) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: `Are you sure you want to delete this ${woundEtiologyGroup.title} group?`
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result === "true") {
        const index = this.woundEtiologyGroups.findIndex(_woundEtiologyGroup => _woundEtiologyGroup._id === woundEtiologyGroup._id);
        const { status, data } = await lastValueFrom(this._wound.updateWoundEtiologyGroup(woundEtiologyGroup._id, undefined, true)) as any;
        if (status == 200) {
          if (index >= 0) {
            this.woundEtiologyGroups.splice(index, 1);
            this.activeWoundEtiologyGroup = null;
          } 
          this.toastr.success(`${woundEtiologyGroup.title} group removed Successfully`, 'Success');
        }
      }
    });
  }

  // editWoundEtiologyGroupDetails(woundEtiologyGroupDetails, i) {
  //   console.log(woundEtiologyGroupDetails);
  //   this.editingWoundEtiologyGroupDetails = woundEtiologyGroupDetails;
  //   this.newInputValue = woundEtiologyGroupDetails.title;
  // }

  handleEtiologyClick(etiology, i) {
    this.activeEtiology = etiology;
    this.activeWoundEtiologyGroup = null;
    this.initWoundEtiologyGroup();
  }
  
  handleWoundEtiologyGroupClick(woundEtiologyGroup, i) {
    this.activeWoundEtiologyGroup = woundEtiologyGroup;
    this.initWoundEtiologyGroupDetails();
  } 
  
  searchDiagnose(value) {
    this.getICDs(value);
  }

  async getICDs(search = "") {
    const {status, data} = await lastValueFrom(this._cpticd.getICDs({ search: search })) as any;
    
    if (status == 200) {
      this.icdCodeList = data.array;
      // woundEtiologyGroupDetails?.icd_id
    } else {
      // this._toaster.error("Somethimg Went Wrong", "Failed");
    }
  }

  addRelation(icdcode){
    console.log(icdcode)
  }

  async drop(event: CdkDragDrop<string[]>) {
    await moveItemInArray(this.etiologies, event.previousIndex, event.currentIndex);
    const etiologiesOrder = this.etiologies.map(etiology => etiology._id);
    const result = await lastValueFrom(this._wound.updateEtiologiesOrder(etiologiesOrder,this.company_id)) as { status };
    if (result.status == 200) {
      this.toastr.success('Updated Successfully');
    }
    else {
      this.toastr.error('Failed to update');
    }
  }

}
