<mat-tab-group (selectedTabChange)="onTabChange($event)">
    <mat-tab label="Provider Meeting">

        <div *ngIf="currentMeetingLink" style="margin-top: 10px;">
            <mat-form-field style="width: 50%; margin-left: 20px;">
                <mat-label>Meeting Link</mat-label>
                <input matInput type="text" name="currentMeetingLink" [(ngModel)]="currentMeetingLink"
                    placeholder="Meeting Link" style="margin-top: 20px;">
            </mat-form-field>
            <button mat-raised-button class="dn-button-primary" (click)="copyToClipboard()"
                style="margin-right: 10px; margin-left: 20px;">Copy Link</button>
        </div>

        <div id="meetingSDKElement" style="margin: 10px;"> </div>

        <button *ngIf="!isMeetingJoined" mat-raised-button class="dn-button-primary" (click)="createMeeting()"
            style="margin-left: 20px;">Create Meeting</button>
        <ngx-ui-loader [loaderId]="doctorZoomMeetingLoaderId"></ngx-ui-loader>

    </mat-tab>
</mat-tab-group>