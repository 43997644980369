import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CensusListService } from 'src/app/includes/census-list/census-list..service';
import { CensusPatientListService } from 'src/app/includes/census-patient-list/census-patient-list.service';
import { PatientRoundingSheetService } from 'src/app/includes/patient-rounding-sheets/patient-rounding-sheet.service';
import { AuthService } from 'src/app/services/auth.service';
import { MipsService } from 'src/app/services/Modules/mips.service';
import { BillerNoteComponent } from '../biller-note/biller-note/biller-note.component';
import { SidebarDiagnosesComponent } from '../sidebarDiagnonses/sidebar-diagnoses/sidebar-diagnoses.component';
import { SidebarDiagnosisService } from '../sidebarDiagnonses/sidebar-diagnoses/sidebar-diagnosis.service';
// import { CensusPatientListService } from 'src/app/includes/census-patient-list/census-patient-list.service';

@Component({
  selector: 'app-sidebar-charges',
  templateUrl: './sidebar-charges.component.html',
  styleUrls: ['./sidebar-charges.component.css']
})
export class SidebarChargesComponent implements OnInit, OnDestroy, AfterViewInit {
  loading: Boolean = true;
  comment: any = ''
  params: any = {};
  billerNote: any = "";
  showCPTs = true;
  showICDs = true;
  carryOverCharge = {};
  // billerNote: any = ''
  @Input() note: any;
  @Input() noteEditorIcds: any[] = [];
  @Output() sidebarClose = new EventEmitter<boolean>();
  @Output() onChargeChange = new EventEmitter<any>();
  // closeSideBar: Boolean = false;
  reportMeasure: boolean;
  @ViewChild('tabGroup') tabGroup;
  showMips: boolean = false;
  mipsQuestionnaireResult: any;
  charge: any;
  currentUser: any;
  rs_id;
  patient_id;
  facility_id;
  provider_id;

  @ViewChild(BillerNoteComponent) billerNoteComponent: BillerNoteComponent;
  @ViewChild(SidebarDiagnosesComponent) sidebarDiagnosesComponent: SidebarDiagnosesComponent;

  constructor(
    private censusPatientListService: CensusPatientListService,
    private censusListService: CensusListService,
    private mipsService: MipsService,
    private authService: AuthService,
    private sidebarDiagnosisService: SidebarDiagnosisService,
    private service: PatientRoundingSheetService,
    private route: ActivatedRoute,
    
  ) { 
    // route.queryParams.subscribe(params => {
    //   this.params = params;
    // })
    // const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    // let patient = urlParams.get('patient_id');
    // let rsID = urlParams.get('rs_id');
    // this.router.snapshot.paramMap.get('document_id');
    this.patient_id = this.route.snapshot.paramMap.get('patient_id');
    this.rs_id = this.route.snapshot.paramMap.get('census_id');
    this.facility_id = this.route.snapshot.paramMap.get('facility_id');
    this.provider_id = this.route.snapshot.paramMap.get('provider_id'); 
    this.params = {
      rs_id: this.rs_id,
      patient_id: this.patient_id,
      facility_id: this.facility_id,
      provider_id: this.provider_id,
    }
    this.currentUser = this.authService.currentUser;
  }
  ngOnDestroy(): void {
    this.censusPatientListService.setReportMeasure(false);
    this.sidebarDiagnosisService.setSelectedICDs([]);
  }

  ngOnInit() {
    
    this.initCharge();
    this.censusPatientListService.castReportMeasure.subscribe(value => {
      this.reportMeasure = value;
    });
  }

  initCharge() {
    const details = {
      patient_id: this.note.patient._id,
      rounding_sheet_id: this.note.rounding_sheet_id,
      visit_date: this.note.dates.service,
      status: null
    }
    this.censusListService.getCharge(details).subscribe(async (response: any) => {
      if (response.status === 200) { 
        this.charge = response.data;
        this.onChargeChange.emit(this.charge);
        if(!this.charge){ 
          let recentcharge = await this.service.getRecentCharge(this.params).subscribe((res:any)=>{
            if(res.status == 200){

                this.carryOverCharge = res.data;  
                this.charge = this.carryOverCharge;
                this.onChargeChange.emit(this.charge);

                delete this.charge.status;
                // if(this.draftedData){
                //     // console.log("getRecentCharge res: ", this.draftedData);
                //     this.draftedData?.icd_id.forEach(element => {
                //         this.addToModel(element, null, 'icd')
                //     });
                //     this.draftedData?.cpt_id.forEach(element => {
                //         this.addToModel(element, null, 'cpt')
                //     });
                //     // if (this.draftedData && Array.isArray(this.draftedData.cpt_modifiers)) {
                //     //     this.model.cpt_modifiers = this.draftedData.cpt_modifiers;
                //     // }
                // }
            }
        })
        }
      }

    })
  }

  ngAfterViewInit(): void {
    this.showMips = this.tabGroup.selectedIndex === 1;
  }
  reset() {
    // this.closeSideBar = true;
    this.comment = '';
    this.sidebarClose.emit(true);
    this.showCPTs = false;
    this.showICDs = false;
    setTimeout(() => {
      this.censusPatientListService.setCPTsForCharge([])
      this.censusPatientListService.setICDsForCharge([])
      this.censusPatientListService.setCPTModifiersForCharge([]);
      this.showCPTs = true;
      this.showICDs = true;
    }, 1000);

  }

  tabChange(e) {
    this.showMips = e.tab.textLabel === 'Charges'
  }
  // handleChargeAdd(chargeData) {
  //   
  //   const [charge] = chargeData.array;
  //   
  //   this.mipsService.castQuestionnaireResults.subscribe((questionnaireResults:any) => {
  //     // const {measure, answer} = data;
  //     if(Array.isArray(questionnaireResults) && questionnaireResults.length > 0) {
  //       this.mipsService.saveQuestionnareResults(questionnaireResults.map(r => ({measure_id: r.measure._id, answer: r.answer})), charge._id,this.currentUser.company_id)
  //       .subscribe((resposne:any) => {
  //         console.log(resposne);

  //       })
  //     }
  //   })

  // }

  async addCharge() {
    await this.billerNoteComponent.addCharge();
  }
  async addICD(icd) {
    await this.sidebarDiagnosesComponent.addToModel(icd, null, 'icd');
  }
}
