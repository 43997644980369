import { Component, DoCheck, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { TicketCommentsComponent } from '../ticket-details/ticket-comments/ticket-comments.component';
import { TicketService } from '../ticket.service';

@Component({
  selector: 'app-user-ticket-details',
  templateUrl: './user-ticket-details.component.html',
  styleUrls: ['./user-ticket-details.component.css']
})
export class UserTicketDetailsComponent implements OnInit {

  @ViewChild(TicketCommentsComponent) child:TicketCommentsComponent;
  @Input()rowData;
  @Output() showDetail = new EventEmitter<any>();
  comments : string = '';
  currentUser: any;
  editComment : any = {};
  
  constructor(private ticketService : TicketService,private authService : AuthService) { }
  

  ngOnInit() {
    this.currentUser = this.authService.currentUser;
  }

  goBack(){
    this.showDetail.emit(false);
  }

  postComments(){
    if(Object.values(this.editComment).length > 0){
      this.ticketService.editComments(this.editComment._id,this.comments).subscribe((Response:any)=>{
        if(Response.status == 200){
          this.child.getComments();
          this.editComment = {};
          this.comments = '';
        }else{
          console.log('Response failed');
        }
      });
    }else{
      this.ticketService.postComments(this.rowData._id,this.comments,this.currentUser.first_name+' '+this.currentUser.last_name).subscribe((Response:any)=>{
        if(Response.status == 200){
          this.child.getComments();
          this.comments = '';
        }else{
          console.log('Response failed');
        }
      });
    }
  }
}
