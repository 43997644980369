import { Component, ViewChild, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { PatientListService } from './patient-list-service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '../dashboard/dashboard.service';
import * as global from '../global';
import { TranscriptionCreateService } from '../transcription-create/transcription-create.service';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from '@angular/material/dialog';
import { PatientImporterDialog } from '../patient-rounding-sheets/patient-importer-dialog/patient-importer-dialog';
import * as _ from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import moment from 'moment'
import { Patient } from 'src/app/classes/patient';
import { PatientRoundingSheetService } from '../patient-rounding-sheets/patient-rounding-sheet.service';
import { lastValueFrom } from 'rxjs';
import { RecurrentOptionDialogComponent } from '../recurrent-option-dialog/recurrent-option-dialog.component';
import { CensusRecurrentService } from 'src/app/services/censusRecurrent.service';
import { DialogConfirmationComponent } from '../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { PdfViewerPopComponent } from '../patient-documents-list/pdf-viewer-pop/pdf-viewer-pop.component';
import { PatientDocumentsUploadDialog } from '../patient-documents-list/patient-documents-upload-dialog/patient-documents-upload-dialog.component';
import { FaceSheetService } from '../patient-detail/facesheet/face-sheet.service';
import { FileExplorerService } from '../drive/file-explorer/file-explorer.service';
import { SnfWcNurseAssociationService } from '../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { ViewOnlyUsersService } from 'src/app/services/Modules/view-only-users.service';
import { WoundNurseRelationService } from 'src/app/services/Modules/woundNurse.service';

interface IFilter {
  active: boolean,
  patientName: string,
  dob?: String | any,
  facility?: String,
  provider?: any,
  dn_mrnNo?: String,
  pcc_mrnNo?: String,
  patient_ssn?:String
  // index: String,
}

export interface OnInit {
  ngOnInit(): void;
}


@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css']
})


export class PatientListComponent implements OnInit {
  loading: boolean = false;
  global = global;
  allPatients: Patient[] = [];
  patients: Patient[] = [];
  facilities: Array<any> = [];
  assocProviders: Array<any> = [];
  displayedColumns: string[] = [];
  dataSource: any;
  currentUser: any;
  filterDropdownActive = false;
  dateOfBirth: any;
  dob: any;
  filter: IFilter;
  appliedFilter: IFilter;
  public pagination: {
    pageNo: number,
    hasNextPage: boolean,
    hasPrevPage: boolean,
    totalCount: number,
    totalPages: number
  };

  loaderId = 'patient-list-loader';
  inprogress = false;

  activeAlphabeticBarIndex = 'All';
  patientRoundingSheets: any[] = [];
  filterpatientRoundingSheets: any[] = [];
  selectedPatients: any[] = [];
  selectedPatientRoundingSheet: any;

  @Input() selectable;
  @Input() provider: any;
  @Input() facility: any;
  @Input() patient: any;

  @Output() onAddNewClick: EventEmitter<any> = new EventEmitter();
  @Output() onEditClick: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteClick: EventEmitter<any> = new EventEmitter();
  @Output() onDetailClick: EventEmitter<any> = new EventEmitter();

  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  searchTextProvider: any;
  searchText: any;
  timeoutId: any;
  recurrenceMap: any;
  date_objects: any;
  selectedProvider: any;
  advanceSearchList = false;
  useFiltersforAdvanceSearch = false;
  searchList = false;
  userFilter = false;
  dd: any;
  isNursingCompany = false;
  constructor(
    private _patientListService: PatientListService,
    private _router: Router,
    private _toastr: ToastrService,
    private _changeDetectorRefs: ChangeDetectorRef,
    private transcriptionCreateService: TranscriptionCreateService,
    private _dashboardService: DashboardService,
    public commonService: CommonService,
    private authService: AuthService,
    private dialog: MatDialog,
    private loader: NgxUiLoaderService,
    private _CensusRecurrentService: CensusRecurrentService,
    private _patientRoundingSheets: PatientRoundingSheetService,
    private _transcriptionCreateService: TranscriptionCreateService,
    private _faceSheetService: FaceSheetService,
    private fileExplorerService: FileExplorerService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
    private viewOnlyUsersService: ViewOnlyUsersService,
    private woundNurseService: WoundNurseRelationService,
  ) {
    this.selectedProvider = {};
    this.currentUser = this.authService.currentUser;
    this.pagination = {
      pageNo: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalCount: 0,
      totalPages: 0
    };
    this.filter = this.filterInitialState();
    if (this.currentUser.company_type === global.COMPANY_TYPE.NURSING) this.isNursingCompany = true;
  }
  @ViewChild(MatSort) sort: MatSort;


  async ngOnInit() {
    // JSON.parse removed causing parse errors for patients i.e JSON.parse(this.currentUser)
    this.currentUser = this.currentUser;
    if (!this.currentUser) {
      // if not login, stop and redirect to login #mohsin-dev
      localStorage.removeItem('currentUser');
      this._router.navigate(['/login']);
      return;
    }
    this.displayedColumns = ['checkbox', 'name', 'date_of_birth', 'facility', 'last_seen', 'operations'];

    if (window.location.href.toString().includes('advance-search-list')) {
      this.displayedColumns = ['checkbox', 'name', 'date_of_birth', 'facility', 'mrn', 'last_seen', 'operations'];
      this.advanceSearchList = true;
      if (localStorage.getItem("advance-search") != null) {
        this.useFiltersforAdvanceSearch = true;
        this.filter = JSON.parse(localStorage.getItem("advance-search"));
      } else {
        this.useFiltersforAdvanceSearch = false;
      }
      this.filter.dob ? this.getdob() : this.filter.dob = '';
    }

    else if (window.location.href.toString().includes('list')) {
      this.displayedColumns = ['checkbox', 'name', 'date_of_birth', 'facility', 'mrn', 'last_seen','operations'];
      this.searchList = true;
      if (localStorage.getItem("myPatientTab:search") != null) {
        this.userFilter = true;
        this.filter = JSON.parse(localStorage.getItem("myPatientTab:search"));
      } else {
        this.userFilter = false;
      }
      this.filter.dob ? this.getdob() : this.filter.dob = '';
    }
    // show operations column only for doctor
    // if(this.currentUser.user_type == 1) {
    // Remove // 'admission_date',  'discharge_date', 'payer', 'status',  'source', 'location', 
    // } else {
    // this.displayedColumns = ['name', 'date_of_birth', 'gender'];
    // } 

    if (this.selectable) {
      this.displayedColumns = ['name', 'date_of_birth', 'facility', 'last_seen',
        'operations'
      ];
    }
    if (this.provider) {
      this.filter.provider = this.provider._id;
      // this.selectedProvider = this.provider;
    }
    if (this.facility) {
      this.filter.facility = this.facility._id;
    }

    if(this.currentUser.user_type != global.USER_TYPE.WOUND_NURSE) {
      await this.initAssocProviders();
    }
    if (this.assocProviders?.length > 0) {
      if (localStorage.getItem("patient_list_provider") !== null) {
        this.filter.provider = localStorage.getItem("patient_list_provider");
        await this.initFacilities(localStorage.getItem("patient_list_provider"));
      }
      else {
        if (this.currentUser.user_type === global.USER_TYPE.DOCTOR || this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
          this.filter.provider = this.currentUser._id;
          await this.initFacilities(this.currentUser._id);
        } else if(this.currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER) {
          this.filter.provider = this.assocProviders[0]._id;
          await this.initFacilities(this.filter.provider);
        }
      }
    }
    if (this.facilities?.length > 0) {
      if (!this.advanceSearchList || this.useFiltersforAdvanceSearch) {
        this.initPatients(1);
      }
      if (this.currentUser.user_type === global.USER_TYPE.DOCTOR || this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
        this.initPatientRoundingSheets(this.currentUser._id, this.filter.facility);
      }
      else if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER || this.currentUser.user_type === global.USER_TYPE.BUSINESS_OFFICER || this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
        this.initPatientRoundingSheets(this.filter.provider, this.filter.facility);
      }
      else if (this.currentUser.user_type === global.USER_TYPE.BUSINESS_OFFICER) {

      }
    }
    if(this.currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER || this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
      this.displayedColumns = this.displayedColumns.filter(column => column != 'operations');
      if(this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
        await this.initFacilitiesForWoundNurse();
        if(!this.advanceSearchList) {
          this.initPatients(1);
        }
      }
    }
  }


  ngOnDestroy() {
    if ( window.location.pathname.includes('patient-detail') || window.location.pathname.includes('patient-add') ) {
      return;
    } else if ( window.location.pathname.includes('advance-search-list') ) {
      localStorage.removeItem("myPatientTab:search")
      return;
    } else {
      localStorage.removeItem("advance-search")
      return;
    }
  }

  async initFacilitiesForWoundNurse() {
    const filter = {
      company_id_ref: this.currentUser.company_id,
      associated_wound_nurse_ids: this.currentUser._id
    };
    const nurseProjection = {
      first_name: 1,
      last_name: 1,
      title: 1,
    };
    const facilityProjection = {
      title: 1,
      pcc_facId: 1,
      pcc_2_legged_authentication: 1,
      source: 1
    };
    const facilitiesResponse : any = await lastValueFrom(this.woundNurseService.getWoundNurseFacilities(filter, nurseProjection, facilityProjection));
    if (facilitiesResponse.status === 200) {
      this.facilities = this.sortTitleVise(facilitiesResponse.data);
      this.filter.facility = this.facilities[0]._id;
    }
  }

  getdob() {
    const date = new Date(this.filter.dob).getDate();
    const [m, d, y] = this.filter.dob.split(' ');
    const newDate = moment(new Date(this.filter.dob)).set({ 'year': y, 'month': m, 'date': date });
    this.dd = newDate;
  }

  initPatientRoundingSheets(provider_id?, facility_id?) {
    // const details = {
    //   ...this.currentUserDetails
    // }

    this._patientRoundingSheets.getCensusList(facility_id, provider_id).subscribe((response: any) => {
      if (response.status === 200) {
        this.patientRoundingSheets = response.data.array;
        this.filterpatientRoundingSheets = response.data.array;
      }
    })
  }
  filterInitialState(): IFilter {
    if (this.advanceSearchList) {
      return {
        active: true,
        patientName: '',
        dob: '',
        dn_mrnNo: '',
        pcc_mrnNo: '',
        patient_ssn:''

      };
    } else {
      return {
        active: true,
        patientName: '',
        dob: '',
        facility: '',
        provider: '',
        // index:'All',
      };
    }
  }


  async initPatients(page, index = null) {

    var details = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <any>null,
      primary_doctor_id: <string>null,
      index: <string>null
    };
    details.id = this.currentUser._id;
    details.auth_key = this.currentUser.auth_key;
    details.usertype = this.currentUser.user_type;
    details.index = index;
    this.loader.startLoader(this.loaderId);

    // if (this.filter.facility) {
    //   await this.getPatientsByFacilityId(details, page);

    // } else {
    //   if (details.usertype == 1) {
    //     await this.getPatientsForDoctor(details, page);
    //   } else {
    //     await this.getPateintsForTranscriber(details, page);
    //   }
    // }
    await this.getPatientsV2(index, page);
    this.loader.stopLoader(this.loaderId);


    // this.applyFilterDate();
  }

  addPatient() {
    console.log('yess medical assistant');
  }

  initTableDataSource(patients) {
    if ( this.advanceSearchList === true && this.filter.patientName.length === 0 && !this.filter.dn_mrnNo && !this.filter.pcc_mrnNo && !this.filter.patient_ssn && ( !this.filter.dob || this.filter.dob === '' ) ) {
      this.pagination.totalCount = 0;
      localStorage.removeItem("advance-search");
    }
    this.dataSource = new MatTableDataSource(patients);
  }
  async getPatientsV2(index, page) {
    let provider, company_id, provider_id, mrnNo, pcc_mrnNo, facility_id, patient_ssn;
    let searchText = this.filter.patientName;
    const dob = this.filter.dob;
    if (!this.advanceSearchList) {
      if(!this.filter.facility){
        if(this.facilities.length > 0){
          this.filter.facility = this.facilities[0]._id
          facility_id = this.filter.facility;
        } 
      }
      else {
        facility_id = this.filter.facility;
      }
      provider = this.assocProviders.find(provider => provider._id === this.filter.provider);
      provider_id = provider._id;
      company_id = provider.company_id;
      if(this.searchList){
        localStorage.setItem("myPatientTab:search", JSON.stringify(this.filter));
      }
      if (!facility_id || facility_id === 'undefined') {
        provider_id = provider._id;
        facility_id = undefined;
      }
    } else {
      company_id = this.currentUser.company_id;
      mrnNo = this.filter.dn_mrnNo;
      pcc_mrnNo = this.filter.pcc_mrnNo;
      patient_ssn = this.filter.patient_ssn
      localStorage.setItem("advance-search", JSON.stringify(this.filter));
      if(searchText.includes(",")){
        const searchSplitText = searchText.split(",");
        if(searchSplitText.length > 1 && searchSplitText[0].length > searchSplitText[1].length) {
          searchText = searchSplitText[0].trim();
        }else if(searchSplitText.length > 1 && searchSplitText[1].length > searchSplitText[0].length){
          searchText = searchSplitText[1].trim();
        }
      }
    }
    const { data, status, message } = await lastValueFrom(this._patientListService.getPatientsV2(company_id, facility_id, provider_id, searchText, dob, page, index, mrnNo, pcc_mrnNo,patient_ssn, this.advanceSearchList)) as any;
    if (status === 200) {
      this.allPatients = data.array.filter(patient => patient.is_active === 'true');
      const totalCount = data.totalCount;
      this.pagination.totalCount = totalCount;
      this.pagination.pageNo = page;
    } else {
      this._toastr.error(message, "Error");
      this.allPatients = [];
      this.pagination.totalCount = 0;
      this.pagination.pageNo = 1;
    }
    this.initPagination();
    this.initTableDataSource(this.allPatients);
    this.dataSource.sort = this.sort;
  }
  async getPatientsByFacilityId(item, page) {
    // DOCTOR 1
    // const infoToast = this._toastr.info("Fetching patients...", "Please wait");
    const response: any = await this._patientListService.getPatientsByFacilityId(item, page, this.filter).toPromise();
    if (response.status === 200) {
      this.allPatients = response.data.array
        .filter(patient => patient.is_active === 'true');
      this.allPatients.forEach(element => {
        if (element.date_of_birth_d) {
          element.date_of_birth_d = element.date_of_birth_d.substr(0, 10)
        }
      });
      const totalCount = response.data.totalCount;
      this.pagination.totalCount = totalCount;
      this.pagination.pageNo = page;
      this.initPagination();
      this.initTableDataSource(this.allPatients);
      this.dataSource.sort = this.sort;
    }
  }
  async getPatientsForDoctor(item, page) {

    // DOCTOR 1
    // const infoToast = this._toastr.info("Fetching patients...", "Please wait");
    const response: any = await this._patientListService.getPatientList(item, page, this.filter.active ? this.filter : null).toPromise();
    if (response.status === 200) {
      this.allPatients = response.data.array
        .filter(patient => patient.is_active === 'true');

      const totalCount = response.data.totalCount;
      this.pagination.totalCount = totalCount;
      this.pagination.pageNo = page;
      this.initPagination();
      this.initTableDataSource(this.allPatients);
      this.dataSource.sort = this.sort;
    }
  }
  async getPateintsForTranscriber(item, page) {
    // TANSCRIBER 2
    // const infoToast = this._toastr.info("Fetching patients...", "Please wait");
    // if (this.associated_doctors_ids) {
    //   item.associated_doctors_ids = this.associated_doctors_ids; // use prop ids to get patients
    //   await this.getPatients(item, page);
    // } else {
    // }

    const associatedDoctorsListResponse: any = await this.transcriptionCreateService.getAssociatedDoctorsList(this.currentUser.company_id).toPromise();

    if (associatedDoctorsListResponse.status === 200) {
      // const associatedDoctorsList = associatedDoctorsListResponse.data.array; // array
      const associatedDoctorsList = associatedDoctorsListResponse.data; // array
      const associatedDoctorsIds = associatedDoctorsList.map(d => d._id);
      item.associated_doctors_ids = associatedDoctorsIds; // additional parameter
      await this.getPatients(item, page);
    }
  }

  isPCCFacility(facility_id) {
    for (const facility of this.facilities) {
      if (facility_id === facility._id) {
        if (facility.pcc_facId) {
          return true;
        }
      }
    }
  }
  isMatrixFacility(facility_id) {
    for (const facility of this.facilities) {
      if (facility_id === facility._id) {
        if (facility.source == 'MatrixCare') {
          return true;
        }
      }
    }
  }


  getDOB(patient) {
    if (patient.date_of_birth) {
      let splitDate = patient.date_of_birth.split('-');
      let month = splitDate[1];
      let day = splitDate[2];
      let year = splitDate[0];
      let newDate = month + '/' + day + '/' + year;
      return newDate;
    } else if (patient.date_of_birth_d) {
      return patient.date_of_birth_d;
    } else {
      return '01/01/1955';
    }
  }

  async getPatients(item, page) {
    const patientsResponse: any = await this._patientListService.getPatientList(item, page, this.filter.active ? this.filter : null).toPromise();
    if (patientsResponse.status == 200) {
      this.allPatients = patientsResponse.data.array
        .filter(patient => patient.is_active === 'true')
      const totalCount = patientsResponse.data.totalCount;
      this.pagination.totalCount = totalCount;
      this.pagination.pageNo = page;
      this.initPagination();
      this.initTableDataSource(this.allPatients);
      this.dataSource.sort = this.sort;
    }
  }

  initPagination() {
    this.pagination.totalPages = Math.ceil(this.pagination.totalCount / 50);
    if (this.allPatients) {
      this.pagination.hasNextPage = this.allPatients.length > 0 && this.pagination.pageNo < this.pagination.totalPages;
    }
    this.pagination.hasPrevPage = this.pagination.pageNo > 1;
    // this.loader.stopLoader('loader-01');
  }
  handleNextClick() {
    this.initPatients(this.pagination.pageNo + 1);
  }

  handlePrevClick() {
    this.initPatients(this.pagination.pageNo - 1);
  }
  getcountofPatients() {
    return ( this.allPatients.length > 0 ? (this.pagination.pageNo * 50) - 49 : 0 ) + ' - ' + (((this.pagination.pageNo * 50) - 50) + Number(this.allPatients.length)) + " out of " + (this.pagination.totalCount);
  }

  editPatient(patient_id, onSuccess?) {

    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      patient_id: <string>null,
    };
    item.id = this.currentUser._id;
    item.auth_key = this.currentUser.auth_key;
    item.usertype = this.currentUser.user_type;
    item.patient_id = patient_id;

    // const infoToastr = this._toastr.info('Fetching patient info...', "Please wait");
    this._patientListService.getPatient(item).subscribe((response: any) => {
      if (response.status === 200) {
        const { patient } = response.data;
        patient.gender = patient.gender?.charAt(0).toUpperCase() + patient.gender.substring(1).toLowerCase();
        localStorage.setItem('editPatient', JSON.stringify(patient));
        if (onSuccess) {
          onSuccess.emit();
        }
      } else {
        this._toastr.error(response.message, "Failed");
      }
    }, console.error, () => {
      // this._toastr.clear(infoToastr.toastId)
    })
  }

  deleteDialogeConfirmation(patient_id) {
    // if(!confirm('Are you sure you want to delete?')) return;
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: "Are you sure you want to delete this Patient?"
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result === "true") {
        this.onDeleteClick.emit(patient_id);
      } else {
        console.log("deletio rejected");
      }
    })
  }

  deletePatient(patient_id, onSuccess?) {
    // if (!confirm("Are you sure you want to delete?")) return;
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      patient_id: <string>null,
      is_active: <string>null,
    };
    item.id = this.currentUser._id;
    item.auth_key = this.currentUser.auth_key;
    item.usertype = this.currentUser.user_type;
    item.patient_id = patient_id;
    item.is_active = "false";

    const infoToastr = this._toastr.info('Checking Patients data...', "Please wait");
    this._patientListService.deletePatient(item).subscribe((response: any) => {
      if (response.status === 200) {
        this._toastr.success(response.message, "Success");

        this.allPatients = this.allPatients.filter((patient: any) => patient.patient_id !== patient_id);
        this.initTableDataSource(this.allPatients);
        this._changeDetectorRefs.detectChanges();
        if (onSuccess) {
          onSuccess.emit();
        }
      } else {
        this._toastr.error(response.message, "Failed");
      }
    }, console.error, () => { this._toastr.clear(infoToastr.toastId) })
  }

  applyFilter() {
    // this.filter.patientName = filterValue.trim();
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      if ( this.advanceSearchList ) {
        if ( this.filter.patientName.length >= 4 || this.filter.dn_mrnNo || this.filter.pcc_mrnNo || this.filter.patient_ssn || ( this.filter.dob && this.filter.dob !== '' ) ) {
          this.initPatients(1)
        }
        this.allPatients = [];
        this.pagination.totalCount = 0;
        this.pagination.pageNo = 1;
        this.initPagination();
        this.initTableDataSource(this.allPatients);
        this.dataSource.sort = this.sort;
      } else if ( !this.advanceSearchList ) {
        this.initPatients(1)
      }
    }, 1000);
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    // this.dataSource.filterPredicate = function (data, filter: string): boolean {
    //   return data.name.toLowerCase().includes(filter);
    // };
  }
  clearFilter() {
    localStorage.removeItem("advance-search")
    localStorage.removeItem("myPatientTab:search")

    this.filter.active = true;
    this.filter.patientName = '';
    this.filter.dob = '';
    this.dob = null;
    this.dd = '';
    this.filter.dn_mrnNo = '';
    this.filter.pcc_mrnNo = '';
    this.filter.patient_ssn = '';
    if (!this.advanceSearchList) {
      this.filter.facility = Array.isArray(this.facilities) && this.facilities.length > 0 ? this.facilities[0]._id : ''
      this.initPatients(1)
    } else {
      this.pagination.totalCount = 0;
      this.pagination.pageNo = 1;
      this.initPagination();
      this.allPatients = [];
      this.initTableDataSource(this.allPatients);
      this.dataSource.sort = this.sort;
    }
  }

  filterOnPatiens() {
    if( this.advanceSearchList === true && this.filter.patientName.length === 0 && !this.filter.dn_mrnNo && !this.filter.pcc_mrnNo && !this.filter.patient_ssn  && ( !this.filter.dob || this.filter.dob === '' ) ){
      this.allPatients = [];
      this.initTableDataSource(this.allPatients)
    } else {
      this.filter.active = true;
      this.initPatients(1);
      if ((this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER || this.currentUser.user_type === global.USER_TYPE.BUSINESS_OFFICER) && this.filter?.provider !== undefined && this.filter?.facility !== undefined) {
        this.initPatientRoundingSheets(this.filter.provider, this.filter.facility);
      }
      else if (this.currentUser.user_type === global.USER_TYPE.DOCTOR || this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
        this.initPatientRoundingSheets(this.currentUser._id, this.filter.facility);
      }
    }
  }
  sortFirstNameVise(arrayTosort) {
    let users = arrayTosort;
    let result = users.sort(function (a, b) {
      if (a.first_name?.toLowerCase() < b.first_name?.toLowerCase()) { return -1; }
      if (a.first_name?.toLowerCase() > b.first_name?.toLowerCase()) { return 1; }
      return 0;
    })
    return result;
  }
  sortPayorVise(arrayTosort) {
    let users = arrayTosort;
    let result = users.sort(function (a, b) {
      if (this.getPayor(a)?.toLowerCase() < this.getPayor(b)?.toLowerCase()) { return -1; }
      if (this.getPayor(a)?.toLowerCase() > this.getPayor(b)?.toLowerCase()) { return 1; }
      return 0;
    })
    return result;
  }
  sortTitleVise(arrayTosort) {
    let users = arrayTosort;
    let result = users.sort(function (a, b) {
      if (a.title?.toLowerCase() < b.title?.toLowerCase()) { return -1; }
      if (a.title?.toLowerCase() > b.title?.toLowerCase()) { return 1; }
      return 0;
    })
    return result;
  }
  onDateChange(event) {
    // let date 
    this.dob = '';
    // this.dob = new Date(event.value).toLocaleDateString()
    this.dob = event.value;
    this.filter.dob = moment(event.value).format(global.moment_date_format2);
    if(!event.value) {
      this.filter.dob = '';
    }
    this.filterOnPatiens()
  }

  patientDetail(patient_id) {
    localStorage.setItem('patient_id', patient_id);
  }
  handleFilter(result) {
    this.filterDropdownActive = false;
    if (!result) {
      return;
    } else if (result.filter === 'cancel') {
      return;
    } else if (result.filter === 'reset') {
      result = null;

      var details = {
        id: <string>null,
        auth_key: <string>null,
        usertype: <any>null,
        primary_doctor_id: <string>null,
        index: <string>null
      };
      details.id = this.currentUser._id;
      details.auth_key = this.currentUser.auth_key;
      details.usertype = this.currentUser.user_type;
      details.index = this.activeAlphabeticBarIndex;

      // this.initPatients(details);
      return;
    } else if (!result.payload) {
      return;
    }
    else {

      if (result.filter === 'facility') {
        let items = _.cloneDeep(this.allPatients);
        items = items.filter(t => {
          return t.facility_id === result.payload;
        });
        this.dataSource = items;
        return;
      }
      if (result.filter === 'patient') {
        let items = _.cloneDeep(this.allPatients);
        items = items.filter(t => {
          return t.patient_id === result.payload._id;
        });
        this.dataSource = items;
        return;
      }

      this.dateOfBirth = result.payload;
      this.applyFilterDate();
    }

    // reset initialization first

    // this.patients = result;


    // this.init();

  }
  applyFilterDate() {
    if (!this.allPatients) {
      return;
    }
    else {
      this.filterDictationsByDateOfService(this.dateOfBirth);
    }
  }

  filterDictationsByDateOfService(date_of_birth) {
    this.filterItemsByDate(date_of_birth, 'date_of_birth', this.allPatients);
  }
  filterItemsByDate(date, dateKeyInObject, itemList) {
    const filterDateTime = new Date(date);
    const filterDate = filterDateTime.getDate();
    const filterMonth = filterDateTime.getMonth() + 1;
    const filterYear = filterDateTime.getFullYear();

    itemList = itemList.filter(t => {
      const dateTime = new Date(t[dateKeyInObject]);
      const date = dateTime.getDate();
      const month = dateTime.getMonth() + 1;
      const year = dateTime.getFullYear();

      return date === filterDate && month === filterMonth && year === filterYear;
    });
    this.dataSource = itemList;
  }

  onRowClick($event, patient) {
    if ($event.target.classList.value.indexOf('icon') >= 0) return;
    if (this.selectable) {
      this.onSelect.emit(patient);
    } else {
      this.onDetailClick.emit(patient._id);
    }
  }

  getRecentPatientADTRecord(patient) {
    patient.adtRecords.sort((a, b) => new Date(b.effectiveDateTime).getTime() - new Date(a.effectiveDateTime).getTime())

    return patient.adtRecords[0];
  }

  getPayor(element) {
    if (Array.isArray(element.pcc_coverage_data?.payers)) {
      const primaryPayor = element.pcc_coverage_data.payers.find(payer => payer.payerRank === 'Primary');
      if (primaryPayor) {
        return primaryPayor.payerName;
      }
    } if (element.adtRecord?.payerName) {
      return element.adtRecord.payerName
    } else {
      if (element.non_pcc_payerName) {
        return element.non_pcc_payerName;
      }
      return element.payor || '';
    }
  }

  handleAddPatientToRoundingSheetClick(patient) {
    this.showPatientImporterDialog(patient)

  }
  showPatientImporterDialog(patient) {
    this.dialog.open(PatientImporterDialog, { width: '50%', data: { patient, action: 'import' } });
  }
  isAddToRoundingSheetButtonVisible() {
    return this.currentUser.user_type == global.USER_TYPE.DOCTOR 
    || this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE 
    && this.currentUser.has_pcc_account == 'true'
  }

  handleAphabeticIndexButtonClick(index) {

    // var details = {
    //   id: <string>null,
    //   auth_key: <string>null,
    //   usertype: <any>null,
    //   primary_doctor_id: <string>null,
    //   index:<string>null
    // };
    // details.id = this.currentUser._id;
    // details.auth_key = this.currentUser.auth_key;
    // details.usertype = this.currentUser.user_type;
    // details.index = index; 
    this.initPatients(1, index);
  }

  async initFacilities(provider_id,refresh=false) {
    // const item:any = {
    //   id: this.currentUser._id,
    //   auth_key: this.currentUser.auth_key,
    //   usertype: this.currentUser.user_type
    // }
    if (this.currentUser.user_type == global.USER_TYPE.DOCTOR) {
      const facilitiesResponse: any = await this.transcriptionCreateService.getFacilities(provider_id).toPromise();
      if (facilitiesResponse.status === 200) {
        this.facilities = this.sortTitleVise(facilitiesResponse.data.array);
        //this.facilities.sort(function (a, b) { return a.title > b.title ? 1 : -1; });
      }
    } 
    else if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE || this.currentUser.company_type === global.COMPANY_TYPE.SNF) {
      const filter = {
        associated_snf_wc_nurse_ids: provider_id,
        company_id: this.authService.currentUser.company_id
      };
      const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      const facilityProjection = {
        title: 1,
        pcc_facId: 1,
        source: 1,
        pcc_2_legged_authentication: 1,
        pcc_orgUuid: 1
      };
      const facilitiesResponse: any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
      if (facilitiesResponse.status === 200) {
        this.facilities = this.sortTitleVise(facilitiesResponse.data);
      }
    }
    else if (this.currentUser.user_type == 2 || this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type == global.USER_TYPE.BUSINESS_OFFICER || (this.currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER && this.currentUser.company_type != global.COMPANY_TYPE.SNF)) {
      // const associatedFacilityOfTranscriberResponse: any = await this._dashboardService.getAssociatedFacilityOfTranscriber().toPromise();
      const associatedFacilityOfTranscriberResponse: any = await this.transcriptionCreateService.getFacilities(provider_id).toPromise();
      if (associatedFacilityOfTranscriberResponse.status === 200) {
        this.facilities = associatedFacilityOfTranscriberResponse.data.array;
        if (localStorage.getItem("patient_list_facility") === null) {
          localStorage.setItem("patient_list_facility", this.facilities[0]._id);
          this.initPatientRoundingSheets(this.facilities[0]._id);
        }else{
          this.initPatientRoundingSheets(localStorage.getItem("patient_list_provider"), localStorage.getItem("patient_list_facility"));
        }
      }
    }
    if (Array.isArray(this.facilities) && this.facilities.length > 0) {
      if (localStorage.getItem("patient_list_facility") !== null) {
        const fac = localStorage.getItem("patient_list_facility")?.toString();
        if ( this.facilities.findIndex((e:any)=>{return e._id?.toString()==fac}) >= 0 ){          
          this.filter.facility = localStorage.getItem("patient_list_facility");
        } else {
          this.filter.facility = this.facilities[0]._id;
          localStorage.setItem("patient_list_facility", this.facilities[0]._id);
        }
      }
    }
    if(refresh){
      this.handleFacilityChange(this.filter.facility);
    }
    // this.initPatients(1);
  }

  getLastSeen(patient) {
    let last_seen='-';
    if(patient?.last_seen){
        if(Array.isArray(patient?.last_seen)&&patient?.last_seen?.length){

          for (let element of patient?.last_seen) {
            if (element.company_id?.toString().includes(this.currentUser?.company_id?.toString())) {
              if (element.user_type && element.user_type === global.USER_TYPE.WOUND_NURSE) {
                last_seen = `<strong>(WN)</strong> ${element.last_seen || ''}`;
                break;
              } else {
                last_seen = element.last_seen || '';
                break;
              }
            }
          };

        }else{
            last_seen= patient?.last_seen||'';
        }
    }
    return last_seen;
    // let lastSeen = '';
    // const { recentNote } = patient;
    // if (recentNote && recentNote.doctor_id_ref) {

    //   const { first_name, last_name } = recentNote.doctor_id_ref;
    //   lastSeen += `${first_name[0]}${last_name[0]}`.toUpperCase();
    //   // console.log("recentNote", recentNote);
    //   // lastSeen += ` - ${moment(new Date(recentNote.filterServiceDate).toLocaleString('en-US', { timeZone: recentNote.timezone })).format('M/D/YYYY')}`;
    //   if (recentNote.date_obj) {
    //     lastSeen += ` - ${this.commonService.dateObjToString(recentNote.date_obj, false)}`;
    //   }
    //   else {
    //     const [year, month, day] = this.commonService.convertDateToStringforMoment(moment(recentNote.filterServiceDate)).split("-");
    //     lastSeen += ` -${month}/${day}/${year}`;
    //   }
    // }
    // return lastSeen;
  }

  sortData(sort: Sort) {
    const data = this.allPatients.slice();
    this.allPatients = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {

        case 'payer':

          return compare(this.getPayor(a), this.getPayor(b), isAsc);


        //   case 'face_sheet':
        //     return compare(this.checkFaceSheet(a), this.checkFaceSheet(b), isAsc);

        // case 'location':
        //   if (a.facility_id_ref && a.facility_id_ref.title && b.facility_id_ref && b.facility_id_ref.title) {
        //     return compare(a.facility_id_ref.title, b.facility_id_ref.title, isAsc);
        //   }
        //   else {
        //     return;
        //   }
        // case 'visit':
        //   if ((a.visit_date||a.date_obj) && (b.visit_date||b.date_obj)) {
        //     let dateA=this.getVisitDate(a);
        //     let dateB=this.getVisitDate(b);

        //     return compare(dateA, dateB, isAsc);
        //   }
        // case 'time':
        //   if ((a.visit_date||a.date_obj) && (b.visit_date||b.date_obj)) {
        //     let dateA=this.getVisitDate(a);
        //     let dateB=this.getVisitDate(b);

        //     return compare(dateA, dateB, isAsc);
        //   }
        // case 'create_time':
        //   if (a.createdAt && b.createdAt) {
        //     return compare(new Date(a.createdAt).getTime(), new Date(b.createdAt).getTime(), isAsc);
        //   }
        // case 'approve':
        //   if (a.is_approved && b.is_approved) {
        //     return compare(a.is_approved, b.is_approved, isAsc);
        //   }
        //   else {
        //     return;
        //   }

        default: return 0;
      }
    });
    // this.dataSource = this.selectedpatients;
    this.dataSource = new MatTableDataSource(this.allPatients);
    this.dataSource.sort = this.sort;
  }


  async initAssocProviders() {
    // const item:any = {
    //   id: this.currentUser._id,
    //   auth_key: this.currentUser.auth_key,
    //   usertype: this.currentUser.user_type
    // }
    // console.log(this.currentUser.user_type);
    if (this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER) {
      const assocProvidersResponse: any = await this._dashboardService.getAssociatedDoctorsList(this.currentUser.company_id).toPromise();
      if (assocProvidersResponse.status === 200) {
        this.assocProviders = assocProvidersResponse.data;
        this.assocProviders = this.sortFirstNameVise(assocProvidersResponse.data);
      }
      this.getFacilitiesByLocalStorage();
    }
    if (this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
      const assocProvidersResponse: any = await this._transcriptionCreateService.getAssociatedDoctorsListForMA(this.currentUser._id).toPromise();
      if (assocProvidersResponse.status === 200) {
        this.assocProviders = assocProvidersResponse.data;
        this.assocProviders = this.assocProviders.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));         
      }
      this.getFacilitiesByLocalStorage();

    }
    else if (this.currentUser.user_type == global.USER_TYPE.DOCTOR) {
      const assocProvidersResponse: any = await this._dashboardService.getP2PRelation().toPromise();
      if (assocProvidersResponse.status === 200) {
        this.assocProviders = assocProvidersResponse.data.assoc_provider_id;
        // this.assocProviders = [this.currentUser, ...assocProvidersResponse.data.assoc_provider_id];
        this.assocProviders.unshift(this.currentUser);
        this.assocProviders = this.assocProviders.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));;
      }
      if (!this.filter.provider) {
        this.filter.provider = this.currentUser._id
        if (localStorage.getItem("patient_list_provider") !== null) {
          this.filter.provider = localStorage.getItem("patient_list_provider");
        }
      }
    }
    else if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      const filter = {
        snf_wc_nurse_id: this.currentUser._id,
        company_id: this.currentUser.company_id
      };
      const project = {
        first_name: 1,
        last_name: 1,
        title: 1,
        company_id: 1
      };
      const response: any = await this._snfWcNurseAssociationService.getAssociatedSnfWcNurses(filter, project).toPromise();
      if (response.status === 200) {
        this.assocProviders = response.data.associated_snf_wc_nurse_ids || [];
        this.assocProviders.unshift(this.currentUser);
        this.assocProviders = this.assocProviders.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));;
      }
      if (!this.filter.provider) {
        this.filter.provider = this.currentUser._id
        if (localStorage.getItem("patient_list_provider") !== null) {
          this.filter.provider = localStorage.getItem("patient_list_provider");
        }
      }
    } else if(this.currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER) {
      const doctorsResponse : any = await lastValueFrom(this.viewOnlyUsersService.getAssociatedProvidersListForViewOnlUsers({user_id : this.currentUser._id, company_id : this.currentUser.company_id}));
      this.assocProviders = doctorsResponse.data || [];
      this.assocProviders = this.assocProviders.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));;
      if (!this.filter.provider) {
        if (localStorage.getItem("patient_list_provider") !== null) {
          this.filter.provider = localStorage.getItem("patient_list_provider");
        }else {
          this.filter.provider = this.assocProviders[0]._id;
        }
      }
    } else if(this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
      const response: any = await lastValueFrom(this._transcriptionCreateService.getAssociatedDoctorsListForWoundNurse(this.currentUser._id, this.currentUser.company_id));
        if (response.status === 200) {
        this.assocProviders = response.data || [];
        this.assocProviders = this.assocProviders.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));;
      }
      if (!this.filter.provider) {
        if (localStorage.getItem("patient_list_provider") !== null) {
          this.filter.provider = localStorage.getItem("patient_list_provider");
        }else {
          this.filter.provider = this.assocProviders[0]._id;
        }
      }
    }
    if (this.currentUser.user_type === global.USER_TYPE.BUSINESS_OFFICER) {
      this._transcriptionCreateService
        .getAssociatedDoctorsListForBO(this.currentUser._id, this.currentUser.company_id, this.currentUser.user_type)
        .subscribe((response: any) => {
          if (response.status === 200) {
            this.assocProviders = response.data;  
            this.assocProviders = this.assocProviders.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));         
          }
          this.getFacilitiesByLocalStorage();
          this.commonService.sortProviderDropdown(this.assocProviders);
        });
    }
  }

  getFacilitiesByLocalStorage(){
    if (this.assocProviders?.length > 0) {
      if (!this.filter.provider._id && localStorage.getItem("patient_list_provider") === null) {
        this.filter.provider = this.assocProviders[0]._id;
        localStorage.setItem("patient_list_provider", this.filter.provider);
      }else{
        this.filter.provider = this.filter.provider._id;
        this.filter.provider = localStorage.getItem("patient_list_provider");
      }
      this.initFacilities(this.filter.provider);
    }

  }

  async handleProviderChange(e) {
    console.log("event", e);
    console.log("event", this.filter);
    this.selectedProvider._id = e;
    localStorage.setItem("patient_list_provider", e);
    await this.initFacilities(this.filter.provider, true);
  }
  handleFacilityChange(e) {
    console.log("event", e);
    localStorage.setItem("patient_list_facility", e);
    if(this.advanceSearchList){
      if ((this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER || this.currentUser.user_type === global.USER_TYPE.BUSINESS_OFFICER) && this.filter?.provider !== undefined && this.filter?.facility !== undefined) {
        this.initPatientRoundingSheets(this.filter.provider, this.filter.facility);
      }
      else if (this.currentUser.user_type === global.USER_TYPE.DOCTOR || this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
        this.initPatientRoundingSheets(this.currentUser._id, this.filter.facility);
      }
    }else{
      this.selectedPatients = [];
      this.filterOnPatiens()
    }
  }

  isPatientSelected(patient) {
    return this.selectedPatients.findIndex((p) => p._id === patient._id) >= 0;
  }
  handleSelectPatient(e, patient) {
    const index = this.selectedPatients.findIndex((p) => p._id === patient._id);
    if (e.checked) {
      if (index === -1) {
        if (this.filter.facility === '') {
          if (this.selectedPatients.length <= 0) {
            this.filterpatientRoundingSheets = [];
          }
          for (const census of this.patientRoundingSheets) {
            if (census.facility?._id === patient.facility_id_ref?._id) {
              this.filterpatientRoundingSheets = Array.from(new Set([...this.filterpatientRoundingSheets, census]));
            }
          }
        }
        this.selectedPatients.push(patient);
      }
    } else {
      if (index >= 0) {
        this.selectedPatients.splice(index, 1);
        if (this.filter.facility === '') {
          if (this.selectedPatients.findIndex((selectedPatient) => selectedPatient.facility_id_ref?._id === patient.facility_id_ref?._id) === -1) {
            this.filterpatientRoundingSheets = this.filterpatientRoundingSheets.filter(census => census.facility?._id !== patient.facility_id_ref?._id)
          }
        }
      }
    }
  }
  handleAddToCensus(selectedPatientRoundingSheet) {
    if (!selectedPatientRoundingSheet) {
      return this._toastr.error('Please select census', 'Error');
    }
    let validPatients = [];
    let not_validPatients = [];
    if (this.filter.facility === ""||this.advanceSearchList) {
      let selectedCensus = this.patientRoundingSheets.filter((facility) => facility._id === selectedPatientRoundingSheet);
      validPatients = [...this.selectedPatients];
      not_validPatients = validPatients.filter((validPatient) => validPatient.facility_id !== selectedCensus[0].facility._id);
      validPatients = validPatients.filter((validPatient) => validPatient.facility_id === selectedCensus[0].facility._id);
      validPatients = validPatients.map((patient) => patient._id);
    } else {
      validPatients = this.selectedPatients.map((patient) => patient._id);
    }

    console.log("validPatients : ", selectedPatientRoundingSheet, this.selectedPatients, validPatients);
    if(validPatients&&validPatients.length==0&&this.advanceSearchList){
      if(this.selectedPatients.length - validPatients.length>0){
        let message = ((this.selectedPatients.length - validPatients.length).toString()) + " Patient(s) are in ";
              not_validPatients.forEach((notValid) => {
                message += notValid.facility_id_ref.title + ", ";
              });
        this._toastr.warning(message);
      }
      return;
    }
    this._patientRoundingSheets.addPatientsToPatientRoundingSheet(selectedPatientRoundingSheet, validPatients)
      .subscribe((response: any) => {
        if (response.status === 200) {
          if (this.selectedPatients.length > validPatients.length) {
            let message = ((this.selectedPatients.length - validPatients.length).toString()) + " Patient(s) are in ";
            not_validPatients.forEach((notValid) => {
              message += notValid.facility_id_ref.title + ", ";
            });
            this._toastr.warning(message);
          } else {
            this._toastr.success(response.message);
          }
          this.selectedPatients = [];
        } else {
          this._toastr.error(response.message);
        }
      })
  }
  viewPDF(filePath) {
    this.dialog.open(PdfViewerPopComponent, {
      width: '50vw',
      height: '95vh',
      data: {
        file_path: filePath
      }
    });
  }
  showPatientDocumentsUploadDialog(patient) {
    const dialogRef = this.dialog.open(
      PatientDocumentsUploadDialog,
      { width: '80%' }
    );
    dialogRef.beforeClosed().subscribe(async (fileInput: any) => {
      if (fileInput) {
        const [file] = fileInput.files;
        const infoToast = this._toastr.info('Uploading Face Sheet...', 'Please wait')
        const company_id = this.authService.currentUser.company_id;
        this._faceSheetService.uploadFaceSheet(file, patient.patient_id, company_id).subscribe((response: any) => {
          if (response.status === 200) {
            const { _id, title, aws_path } = response.data;
            const faceSheet = {
              _id,
              title,
              aws_path
            };
            patient.faceSheet = faceSheet;
            this._toastr.success("Face Sheet Uploaded", 'Success');
          }
          else {
            console.error(response.data.status, response.data.message);
            this._toastr.error(response.data.message, 'Failed');
          }
        });
      }
    });
  }

  async downloadFile(element) {
    const infoToast = this._toastr.info('Preparing file to download...', 'Please wait', { disableTimeOut: true });
    try {
      const downloadFileResponse: any = await this.fileExplorerService.downLoadAnyFile(element.faceSheet.aws_path, element.faceSheet.title).toPromise();
      var url = window.URL.createObjectURL(downloadFileResponse);
      var a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = <string>element.faceSheet.title;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    } catch (error) {
      this._toastr.error('Unable to download file.', 'Failed');
    } finally {
      this._toastr.clear(infoToast.toastId);
    }
  }

  getDOS(patientRoundingSheet) {
    if (patientRoundingSheet.date_obj) {
      let { year, month, day } = patientRoundingSheet.date_obj;
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      return `${month}/${day}/${year}`;
    }

    // console.log("getdos", patientRoundingSheet)
    if (!('pcc_timeZone' in patientRoundingSheet.facility || 'timeZone' in patientRoundingSheet.facility)) {
      patientRoundingSheet.facility.timeZone = 'America/New_York';
    }
    // console.log('pcc_timeZone' in patientRoundingSheet.facility || 'timeZone' in patientRoundingSheet.facility)
    let timezone = patientRoundingSheet.facility.pcc_timeZone ? patientRoundingSheet.facility.pcc_timeZone : patientRoundingSheet.facility.timeZone;
    let serviceDate = moment(patientRoundingSheet.date).utc().tz(timezone).format('MM/DD/YYYY')
    return serviceDate;
  }

  async handleAddToRecurrentCensus() {
    await this.showDialog(true);

  }


  async showDialog(e) {
    console.log("e : ", e);
    let date_obj = moment().format('YYYY-MM-DD').split('-');
    let date_obj_toPass;
    const [year, month, day] = date_obj;
    date_obj_toPass = {
      year, month, day
    }

    const obj = {
      //...this.data,
      date_obj: date_obj_toPass,
      from_patient: true
    }
    const dialogRef = this.dialog.open(RecurrentOptionDialogComponent, {
      data: obj,
      width: '40%'
    });

    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        console.log("closedd true", data);
        let recurrenceMap = data.recurrenceMap;
        let date_objects = data.date_objects;
        let census_name = data.census_name;
        this._toastr.info('Adding Patients to census');

        await this.addRecurrentPatientRoundingSheets(null, date_objects, recurrenceMap, census_name);
      } else {
        console.log("closedd false", data);
        this.recurrenceMap = [];
        this.date_objects = [];
      }
    });

  }


  async addRecurrentPatientRoundingSheets(previous_census_id, date_objects, recurrence_map, census_name) {

    let date_obj = moment().format('YYYY-MM-DD').split('-');
    let date_obj_toPass;
    const [year, month, day] = date_obj;
    date_obj_toPass = {
      year, month, day
    }


    this.selectedPatients = this.selectedPatients.map((patient) => patient._id);

    const currentDate = new Date();

    date_obj_toPass.hour = currentDate.getHours();
    date_obj_toPass.minute = currentDate.getMinutes();

    // if (localStorage.getItem("patient_list_provider") !== null) {
    //   this.selectedProvider._id=localStorage.getItem("patient_list_provider");
    //   await this.initFacilities(localStorage.getItem("patient_list_provider"));
    // }
    // else {
    //   this.selectedProvider=this.currentUser;
    //   await this.initFacilities(this.currentUser._id);
    // }

    const details = {
      id: this.currentUser._id,
      doctor_id: this.filter.provider,
      facilityId: this.filter.facility,
      name: census_name,
      ischargecheck: false,
      isnotecheck: false,
      parent_census_id: previous_census_id,
      date_objs: date_objects,
      provider_id: this.filter.provider,
      company_id: this.currentUser.company_id,
      recurrence_map: recurrence_map,
      patientList: this.selectedPatients,
      date: currentDate,
      date_obj: date_obj_toPass
    }



    if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER) {
      details.doctor_id = this.currentUser?._id;
    }
    console.log("details : :", details);


    //  return;

    await this._CensusRecurrentService.addNewRecurrentCensusesWithParent(details).subscribe(async (response: any) => {
      if (response.status === 200) {
        this.selectedPatients = [];
        this._toastr.success('Patient Added Successfully');
        // this.dialogRef.close(response.data);
        console.log("res", response);

      }
    });
  }


  handleExportPatientsClick() {
    console.log("🚀 ~ file: patient-list.component.ts:1416 ~ PatientListComponent ~ this._patientListService.exportPatients ~ this.selectedPatients:", this.selectedPatients)
    const patientIds = this.selectedPatients.map((patient) => patient._id);

    this._patientListService.exportPatients(patientIds).subscribe(async (response: any) => {
      if (response.status === 200) {
        this.selectedPatients = [];
        this._toastr.success(response.message);
        // this.dialogRef.close(response.data);
        console.log("res", response);

      }
    }, (error) => {
      this._toastr.error('Failed to generate report');
    });
  }





}


function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}