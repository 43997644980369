import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { WoundService } from 'src/app/services/wound.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment'
import { CompanyService } from 'src/app/services/Modules/company.service';

@Component({
  selector: 'app-wound-reports-v2',
  templateUrl: './wound-reports-v2.component.html',
  styleUrls: ['./wound-reports-v2.component.css']
})
export class WoundReportsV2Component implements OnInit {


  displayedColumns: string[] = [ 'sr', 'title', 'actions'];
  dataSource: any = new MatTableDataSource([]);
  params: any;
  companyId: any;
  loaderId = 'app-wound-reports';
  isDnsWeeklyReportRunning: boolean = false;
  woundReports: any = [
    { title: 'DNS Report', navigationPath: 'dns-report-v2' , selected:true , user_type:[]},
    { title: 'Post Rounds Report', navigationPath: 'post-rounds-report' , selected:true, user_type:[]},
    // { title: 'DNS Tissue Analytic Report', navigationPath: 'dns-tissue-analytic-report', selected:true, user_type:[]},
    // { title: 'Post-Rounds Wound Report', navigationPath: 'dns-tissue-analytic-report' , selected:true, user_type:[]},
    { title: 'QAPI Report', navigationPath: 'qapi-report-v2' , selected:true, user_type:[]},
    // { title: 'Visit Summary Report', navigationPath: '' , selected:true, user_type:[]},
    // { title: 'Visit Report', navigationPath: 'visit-report' , selected:true, user_type:[]},
    // { title: 'Discharged Patient Report', navigationPath: 'discharged-patient-report' , selected:true, user_type:[]},
    // { title: 'Missed Facility Report', navigationPath: 'missed-facility-report' , selected:true, user_type:[]},
    // { title: 'Debridement Report', navigationPath: 'debridement-report' , selected:true, user_type:[]},
    // { title: 'Healing Success Rate Report', navigationPath: 'healing-success-rate-report' , selected:true, user_type:[]},
    { title: 'UWH Type Tabular Report', navigationPath: 'uwh-type-tabular-report-v2' , selected:true, user_type:[]},
    // {title : 'Kent Visit Report', navigationPath: 'kent-visit-report', selected:true, user_type:[]},
    { title: 'Charges Report', navigationPath: 'admin-charges-report' , selected:true, user_type:[]},
    { title: 'LOS Report', navigationPath: 'los-report' , selected:true, user_type:[]},
    { title: 'IPA Report', navigationPath: 'ipa-report' , selected:true, user_type:[]},
  ];
  settingsReports:any;
  quarterlyDateRange = {
    "q1": {
      creationDate: {
        startDate: "2022-01-01 00:00:00",
        endDate: "2022-03-31 23:59:59"
      }
    },
    "q2": {
      creationDate: {
        startDate: "2022-04-01 00:00:00",
        endDate: "2022-06-30 23:59:59"
      }
    },
    "q3": {
      creationDate: {
        startDate: "2022-07-01 00:00:00",
        endDate: "2022-09-30 23:59:59"
      }
    },
    "q4": {
      creationDate: {
        startDate: "2022-10-01 00:00:00",
        endDate: "2022-12-31 23:59:59"
      }
    }
  };
  filter: any = {
    quarter: "",
    companyId: null,
    creationDate: null,
  };
  constructor(
    private _toastr: ToastrService,
    private _location: Location,
    private _route: ActivatedRoute,
    private _router: Router,
    private _woundService: WoundService,
    private loader: NgxUiLoaderService,
    private companyService: CompanyService,
  ) {
    // this._route.queryParams.subscribe(params => {
    //   this.params = params;
    // });
    this.companyId = this._route.parent.parent.snapshot.params.id;
    const currentMonth = moment().month() + 1;
    if (currentMonth >= 1 && currentMonth <= 3) {
      this.filter.quarter = "Q1";
    }
    else if (currentMonth >= 4 && currentMonth <= 6) {
      this.filter.quarter = "Q2";
    }
    else if (currentMonth >= 7 && currentMonth <= 9) {
      this.filter.quarter = "Q3";
    }

    else if (currentMonth >= 10 && currentMonth <= 12) {
      this.filter.quarter = "Q4";
    }
    this.onQuarterlyMenuChange(this.filter.quarter);
  }
  ngOnInit(): void {
    this.settingsReports=[];
    this.loader.startLoader(this.loaderId);
    this.initDnsWeeklyReportJobStatus();

    this.dataSource.data = this.woundReports;
    // this.getSettings();
  }

  RemoveDuplicateIds(IdsArray) {
    console.log("Unique : ", IdsArray);
    let Unique = [];
      for (let id of IdsArray) {
          if (!Unique.includes(id?.toString())) {
              Unique.push(id?.toString());
          }
      }
      return Unique;
  }

  changeNurseAccess(index, checked){
    console.log("checked v: ",index, checked);
    this.loader.startLoader(this.loaderId);
    if(index>=0){
      if(checked){
        // let array=this.settingsReports[index].user_type;
        // array.push('nurse');
        // this.settingsReports[index].user_type=this.RemoveDuplicateIds(array)
        this.settingsReports[index].user_type=['nurse'];
      }else{
        // const ind=this.settingsReports[index].user_type?.findIndex(e=>e=='nurse');
        // this.settingsReports[index].user_type.splice(ind,1);
        this.settingsReports[index].user_type=[];
      }
      console.log("this.settingsReports : ", this.settingsReports);
      this.companyService.saveCompanyReportSettings(this.companyId,this.settingsReports).subscribe((response: any) => {
        this.getSettings();
  
      })
    }
  }

  getSettings(){
    this.companyService.getCompanyReportSettings(this.companyId).subscribe((response: any) => {

        if(response.data?.length){
          this.settingsReports=[];
          response.data.forEach(element => {
              const index=this.woundReports.findIndex(e=>e.title==element.title);
              if(index>=0){
                this.woundReports[index].selected=element.selected;
                this.woundReports[index].user_type=element.user_type;
              }
              if(element.selected){
                this.settingsReports.push(this.woundReports[index]);
              }
              if(window.location.pathname.includes('woundcare')){
                this.settingsReports.filter(woundSetting => {
                  if (woundSetting.title === 'DNS Healing Report') { woundSetting.title = 'Wound Report'; return woundSetting } else {
                    return woundSetting;
                  }
                })
              }
          });
          this.dataSource.data = this.settingsReports;
          this.loader.stopLoader(this.loaderId);
        }else{
          this.dataSource.data=[];
        }
    })
  }
  checkNurseAccess(element){
    if(element.user_type.includes('nurse')){
      return true;
    } return false;
  }
  initDnsWeeklyReportJobStatus() {
    this._woundService.getDnsWeeklyReportJobStatus({}).subscribe((response: any) => {
      if (response.status === 200) {
        if (response.data === "running") {
          this.isDnsWeeklyReportRunning = true;
        }
        else {
          this.isDnsWeeklyReportRunning = false;
        }
        this.loader.stopLoader(this.loaderId);
      } else {
        this.loader.stopLoader(this.loaderId);
        this._toastr.error(response.message, "ERROR");
      }
    }, err => {
      this.loader.stopLoader(this.loaderId);
      this._toastr.error(err, "ERROR");
    });
  }
  generateDnsWeeklyExcelReport() {
    this.loader.startLoader(this.loaderId);
    this._woundService.generateDnsWeeklyExcelReport({ companyId: this.companyId }).subscribe((response: any) => {
      if (response.status === 200) {
        this.isDnsWeeklyReportRunning = true;
        this.loader.stopLoader(this.loaderId);
        // this._toastr.success("Auto Report Generation Started", "Success");
      }
      else {
        this.loader.stopLoader(this.loaderId);
      }
    }, err => {
      this._toastr.error(err, "ERROR");
      this.loader.stopLoader(this.loaderId);
    });
  }
  deleteDnsWeeklyReportJob() {
    this.loader.startLoader(this.loaderId);
    this._woundService.deleteDnsWeeklyReportJob({}).subscribe((response: any) => {
      if (response.status === 200) {
        this.isDnsWeeklyReportRunning = false;
        this.loader.stopLoader(this.loaderId);
        // this._toastr.success("Auto Report Generation Stopped", "Success");
      }
      else {
        this.loader.stopLoader(this.loaderId);
      }
    }, err => {
      this._toastr.error(err, "ERROR");
      this.loader.stopLoader(this.loaderId);
    });
  }

  onQuarterlyMenuChange(value) {
    this.filter.quarter = value;
    const { creationDate } = this.quarterlyDateRange[value.toLowerCase()];
    this.filter.creationDate = creationDate;
    console.log("filter", this.filter);
  }
  generateQuarterlyQapiReport() {
    this.loader.startLoader(this.loaderId);
    this.filter.companyId = this.companyId;
    this._woundService.exportQuarterlyQapiReportAszip(this.filter).subscribe((response: any) => {
      if (response.status === 200) {
        const toastrHeading = "Qapi Report Status";
        if (response.data.isPending) {
          this._toastr.info("In Progress...", toastrHeading);
        }
        else {
          this._toastr.success("Started", toastrHeading);
        }
        this.loader.stopLoader(this.loaderId);
      }
      else {
        this.loader.stopLoader(this.loaderId);
      }
    }, err => {
      this._toastr.error(err, "ERROR");
      this.loader.stopLoader(this.loaderId);
    });
  }
  navigateToReportPage(element) {
    return this._router.navigate([`/company/${this.companyId}/${element.navigationPath}`]);
  }

}
