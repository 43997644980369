<div class="flex justify-start items-start gap-2" style="overflow: auto; height: 100%;">
    <mat-card style="min-width: 22%; height: 100%;">
        <mat-card-content>
            <div class="col-header"> Tags </div>

            <div class="col-content">
                <div *ngFor="let tag of tags;let i = index">
                    <div class="item flex justify-between items-center">
                        <p *ngIf="!isEditingItem(tag, i)">{{tag.label}}</p>

                        <ng-container *ngIf="currentUser.admin_type == 'system_admin'">
                            <ng-container *ngIf="isEditingItem(tag, i)">
                                <button class="save-image" (click)="updateItem(newInputValue, i)">
                                    <img src="assets\icons\save-white.png" />
                                </button>
                            </ng-container>
                            <button class="close-image" (click)="deleteItem(tag, i)">
                                <img src="assets\icons\minus.png" />
                            </button>
                        </ng-container>
                    </div>

                    <div>
                        <mat-form-field style="width: 100%;" *ngIf="isEditingItem(tag, i)">
                            <input matInput type="text" [(ngModel)]="newInputValue"
                                (keyup)="handleEditInputValueChange($event, i)" name="newInputValue" required>
                        </mat-form-field>
                    </div>
                </div>

                <div class="item flex justify-between items-center" *ngIf="showNewInput && currentUser.admin_type == 'system_admin'">
                    <mat-form-field>
                        <input matInput type="text" [(ngModel)]="newInputValue" name="newInputValue" required>
                    </mat-form-field>

                    <button class="save-image" (click)="handleNewInputValueChange($event)">
                        <img src="assets\icons\save-white.png" />
                    </button>
                </div>
            </div>

            <ng-container *ngIf="currentUser.admin_type == 'system_admin'">
                <button class="add-image" (click)="handleAddNew()">
                    <img src="assets\icons\plus.png" />
                </button>
            </ng-container>
        </mat-card-content>
    </mat-card>

    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>