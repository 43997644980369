import { Component, OnInit, Input } from '@angular/core';
import { PCCService } from 'src/app/services/pcc.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-patient-coverages',
  templateUrl: './patient-coverages.component.html',
  styleUrls: ['./patient-coverages.component.css']
})
export class PatientCoveragesComponent implements OnInit {

  @Input('patient') patient;
  // @Input('pccOrgUuid') pccOrgUuid;
  constructor(private _pccService: PCCService,
    private toastr:ToastrService,
    private loader: NgxUiLoaderService) { }
  pccOrgUuid:string;
  apiData;
  dataSource;
  displayedColumns = [
    "payerName",
    "accountNumber",
    "accountDescription",
    "payerCode",
    "payerCode2",
    "issuer", // issuer.issuername
    "payerType",
    "insuredParty", // insuredParty.firstName + insuredParty.lastName
    "pps",
    "payPlanType",
    "payerId",
    "payerRank",

  ];

  loaderId ='patient-coverages-loader';
  inprogress = false;

  ngOnInit(): void {
    this.pccOrgUuid = this.patient.facility_id_ref.pcc_orgUuid;
    this.loadData();
  }

  loadData() {
    this.loader.startLoader(this.loaderId);
    let authMode;
    if(!this.patient.facility_id_ref.pcc_2_legged_authentication) {
      authMode = 'APP'
    }
    this._pccService.getPatientCoverages({
        pccOrgUuid:this.pccOrgUuid,
        pccPatientId:this.patient.pcc_patientId,
        authMode
      }).subscribe((response: any) => {

      if (response.status === 200) {
        this.apiData = response.data;
        this.dataSource = new MatTableDataSource(this.apiData.payers);
      } else {
        this.toastr.error('Something went wrong while fetching Patient Coverages data');
      }
    }, (error) => {
      this.toastr.error('Something went wrong while fetching Patient Coverages data');
    }, () => {
      this.loader.stopLoader(this.loaderId);
    })
  }
}
