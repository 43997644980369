    <mat-toolbar class="site-header flex" *ngIf="currentUser.user_type === global.USER_TYPE.WOUND_NURSE">
        <div class="flex flex-row items-center">
            <a [routerLink]="['/dashboard']">
                <img [src]="companylogoPath ? companylogoPath : defaultImg">
            </a>
            <ul class="top-nav">
                <ng-container *ngFor="let menu of menus">
                  <li style="cursor: pointer;" routerLinkActive="active"
                    [ngClass]="{active: isActive(menu)}"><a (click)="navigateToURL(menu)">{{menu.title}}</a></li>
                </ng-container>
              </ul>
        </div>
        <div class="flex justify-end items-center gap-2">
            <div class="flex items-center">
                <span class="profile_pic"
                    [ngStyle]="{'background-image': 'url(' + (currentUser.profile_pic || 'assets/img/default-avatar.png') + ')'}"></span>
                <button class="welcome" mat-button [matMenuTriggerFor]="menu">
                    Welcome {{currentUser.first_name}} {{currentUser.last_name}}
                </button>
                <mat-menu #menu="matMenu" [class]="'menus-panel'">
                    <button class="flex items-center gap-2" mat-menu-item
                        [routerLink]="['/profile']">

                        <i class="material-icons">group</i>
                        <span class="tag-style">Profile</span>
                    </button>
                    <button *ngIf="(currentUser.admin_type=='full_admin'  || currentUser.admin_type=='system_admin') && !companyPath" class="flex items-center gap-2" mat-menu-item target="_blank" (click)=" checkAuthForAdmin(); setCompany(currentUser.company_id)">
                        <i class="material-icons">compare_arrows</i>
                        <span class="tag-style">Manage Portal</span>
                    </button>
                    <button class="flex items-center gap-2" mat-menu-item
                        (click)="logout()">
                        <i class="material-icons">exit_to_app</i>
                        <span class="tag-style">Logout</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </mat-toolbar>