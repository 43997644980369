import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { PatientRoundingSheetService } from '../patient-rounding-sheet.service';
import { PatientRoundingSheetDialog } from '../create-patient-rounding-sheet-dialog/create-patient-rounding-sheet-dialog';
import * as global from '../../global'
import { NgxUiLoaderService } from 'ngx-ui-loader';
import moment from 'moment';
import { PatientListService } from '../../patient-list/patient-list-service';
import { RecurrentOptionDialogComponent } from '../../recurrent-option-dialog/recurrent-option-dialog.component';
import { CensusRecurrentService } from 'src/app/services/censusRecurrent.service';
import { lastValueFrom } from 'rxjs';
import { SnfWcNurseAssociationService } from '../../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { TranscriptionCreateService } from '../../transcription-create/transcription-create.service';

export interface PatientImporterDialogData {
  patient: any;
  patients: any;
  action: string;
  only_pcc: boolean;
  obj: any,
  oldCensusId?: any,
}


@Component({
  selector: 'app-patient-importer-dialog',
  templateUrl: './patient-importer-dialog.html',
  styleUrls: ['./patient-importer-dialog.css']
})
export class PatientImporterDialog
  implements OnInit {
  global = global;
  currentUser: any;
  currentUserDetails: any;
  patient: any;
  patients: any;
  patientRoundingSheets: any = [];
  total: number = 0;
  selectedPatientRoundingSheet: any;

  action: string;
  only_pcc: boolean;
  loaderId = 'patient-importer-loader';

  showLimit = 5;
  oldCensusId: any;
  recurrenceMap: any[];
  date_objects: any[];
  providers: any = [];
  userDropdownPlaceHolder: string = "Providers";
  selectedUser: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PatientImporterDialogData,
    private _authService: AuthService,
    private _patientRoundingSheets: PatientRoundingSheetService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private _CensusRecurrentService:CensusRecurrentService,
    private patientListService: PatientListService,
    public dialogRef: MatDialogRef<PatientImporterDialog>,
    private loader: NgxUiLoaderService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
    private _dashboardService: DashboardService,
    private _transcriptionCreateService : TranscriptionCreateService
  ) {
    this.currentUser = this._authService.currentUser;
    this.currentUserDetails = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type
    }
    if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) this.userDropdownPlaceHolder = "SNF WC Nurses";
    if (data) {
      this.patient = data.patient;
      this.patients = data.patients;

      
      this.getfacilitiesfromMaping(this.patient, 1);
      this.getfacilitiesfromMaping(this.patients, 2);

      console.log('this.patients',this.patients, data);

      this.action = data.action;
      this.only_pcc = !!data.only_pcc;
    }
    if (data?.obj?.oldCensusId) {
      this.oldCensusId = data.obj.oldCensusId;
    }
  }
  async getfacilitiesfromMaping(patient, type){
    let newPatients=[];
    if(!patient) return;

    if(type==1){

      await this.patientListService.getPatient_Maping_population({ patient_id: patient._id?patient._id:patient[0]._id, company_id: this.currentUser.company_id }).subscribe((res:any)=>{
        let result=res?.data;
        let pat=JSON.parse(JSON.stringify(patient));

        if(pat.facility_id_ref){
          pat.facility_id_ref=result[0].facility_id;
        }else{
          pat[0].facility_id_ref=result[0].facility_id;
        }

        this.patient=pat;
        console.log("resultttn 1", this.patient);

      })
    }

    if (type==2){

      for(let p of patient){
        await this.patientListService.getPatient_Maping_population({ patient_id: p._id?p._id:p[0]._id, company_id: this.currentUser.company_id }).subscribe((res:any)=>{
            let result=res?.data;
            let pat=JSON.parse(JSON.stringify(p));
  
            if(pat.facility_id_ref){
              pat.facility_id_ref=result[0].facility_id;
            }else{
              pat[0].facility_id_ref=result[0].facility_id;
            }
            newPatients.push(pat);
            this.patients=newPatients;
            console.log("resultttn 2", this.patients);
  
          })
        }
        
    }

  }


  async ngOnInit() {
    if (this.currentUser.user_type === global.USER_TYPE.DOCTOR) await this.getAssociatedProviders();
    else if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) await this.getAssociatedSnfWcNurses();
    else if (this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) await this.initAssociatedProvidersForWoundNurse();
    if (this.currentUser.user_type != global.USER_TYPE.WOUND_NURSE) {
      this.initPatientRoundingSheets(null, this.currentUser, this.showLimit);
    }else {
      this.initPatientRoundingSheets(null, this.selectedUser, this.showLimit);
    }
    this.selectedUser = this.currentUser;
  }
  async getAssociatedProviders() {
    const response: any = await lastValueFrom(this._dashboardService.getP2PRelation(null, this.currentUser._id));
    if (response.status === 200) {
      this.providers = response.data.assoc_provider_id;
      this.providers.unshift(this.currentUser);
    }
  }
  async getAssociatedSnfWcNurses() {
    const filter = {
      snf_wc_nurse_id: this.currentUser._id,
      company_id: this.currentUser.company_id
    };
    const project = {
      first_name: 1,
      last_name: 1,
      title: 1,
      company_id: 1
    };
    const response: any = await lastValueFrom(this._snfWcNurseAssociationService.getAssociatedSnfWcNurses(filter, project));
    if (response.status === 200 && response.data.associated_snf_wc_nurse_ids) {
      this.providers = response.data.associated_snf_wc_nurse_ids || [];
      this.providers = this.providers.concat(response.data.snf_wc_nurse_id);
    }
  }
  async initAssociatedProvidersForWoundNurse() {
    const response: any = await lastValueFrom(this._transcriptionCreateService.getAssociatedDoctorsListForWoundNurse(this.currentUser._id, this.currentUser.company_id));
    if (response.status === 200) {
      this.providers = response.data || [];
      if (this.providers.length > 0)
        this.selectedUser = this.providers[0];
    }
  }
  initPatientRoundingSheets($event, user?, limit?) {
    if ($event === null || $event && $event.isUserInput) {
      this.selectedUser = user;
      // const details = {
      //   ...this.currentUserDetails
      // }
      this.loader.startLoader(this.loaderId);
      if (this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER || this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type === global.USER_TYPE.BUSINESS_OFFICER) {
        this._patientRoundingSheets.getCensusList(this.data?.obj?.filter_facility_id, this.data?.obj?.filter_provider_id, limit).subscribe((response: any) => {
          if (response.status === 200) {
            // console.log(response.data);
            this.patientRoundingSheets = response.data.array;
            this.total = response.data.total;
          }
        }, () => { }, () => this.loader.stopLoader(this.loaderId))
      }
      else {
        this._patientRoundingSheets.getCensusList(this.data?.obj?.filter_facility_id, user._id, limit).subscribe((response: any) => {
          if (response.status === 200) {
            // if(this.only_pcc) {
            //   this.patientRoundingSheets = response.data.array.filter(prs => !!prs.facility.pcc_facId)
            // } else {
            // }
            this.selectedPatientRoundingSheet = response.data.array[0]?._id;
            this.patientRoundingSheets = response.data.array;
            this.total = response.data.total;
          }
        }, () => { }, () => this.loader.stopLoader(this.loaderId))
      }
    }
  }

  getDOS(patientRoundingSheet) {
    if (patientRoundingSheet.date_obj) {
      let { year, month, day } = patientRoundingSheet.date_obj;
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      return `${month}/${day}/${year}`;
    }

    // console.log("getdos", patientRoundingSheet)
    if (!('pcc_timeZone' in patientRoundingSheet.facility || 'timeZone' in patientRoundingSheet.facility)) {
      patientRoundingSheet.facility.timeZone = 'America/New_York';
    }
    // console.log('pcc_timeZone' in patientRoundingSheet.facility || 'timeZone' in patientRoundingSheet.facility)
    let timezone = patientRoundingSheet.facility.pcc_timeZone ? patientRoundingSheet.facility.pcc_timeZone : patientRoundingSheet.facility.timeZone;
    let serviceDate = moment(patientRoundingSheet.date).utc().tz(timezone).format('MM/DD/YYYY')
    return serviceDate;
  }

  handleAddClick() {
    if (!this.selectedPatientRoundingSheet) return;
    if (this.patient) {
      this.addPatientToPatientRoundingSheet()
    } else if (this.patients) {
      this.addPatientsToPatientRoundingSheet();
    }
    this.dialogRef.close(true);
  }
  addPatientToPatientRoundingSheet() {
    const details = {
      ...this.currentUserDetails,
      patientRoundingSheetId: this.selectedPatientRoundingSheet,
      patientId: this.patient._id?this.patient._id:this.patient[0]._id,
    }
    const infoToast = this.toastr.info('Adding patient to census', 'Please wait', { disableTimeOut: true });

    this._patientRoundingSheets.addPatientToPatientRoundingSheet(details).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.success('Patient added Successfully', 'Success');
      } else {
        this.toastr.warning(response.message);
      }
    },
      () => this.toastr.error('Something went wrong adding patient', 'Failed'),
      () => {
        this.dialogRef.close()
        this.toastr.clear(infoToast.toastId)
      });
      
  }

  addPatientsToPatientRoundingSheet() {
    const infoToast = this.toastr.info('Adding patients to census', 'Please wait', { disableTimeOut: true });
    // this.toastr.clear(infoToast.toastId)

    console.log("patients : ", this.patients, this.data?.obj, infoToast.toastId);

    let validPatients = this.patients.filter(p => p.facility_id_ref?(p.facility_id_ref._id === this.data?.obj?.filter_facility_id):(p[0].facility_id_ref._id === this.data?.obj?.filter_facility_id));
    let not_validPatients = this.patients.filter(p => p.facility_id_ref?(p.facility_id_ref._id != this.data?.obj?.filter_facility_id):(p[0].facility_id_ref._id != this.data?.obj?.filter_facility_id));
    let ids = validPatients.map(p => p._id ? p._id : p[0]._id);
    console.log("ids.length : ", ids.length);
    if (ids.length > 0) {
      console.log("in iffff : ",infoToast.toastId);
      this._patientRoundingSheets.addPatientsToPatientRoundingSheet(this.selectedPatientRoundingSheet, ids, this.oldCensusId)
        .subscribe((response: any) => {
          if (response.status === 200) {
            if((this.patients.length - ids.length) > 0){
              let message = ((this.patients.length - ids.length).toString()) + " Patient(s) are Moved to ";
              not_validPatients.forEach((notValid)=>{
                message += notValid.facility_id_ref?notValid.facility_id_ref.title:notValid[0].facility_id_ref.title + " ";
              });
              this.toastr.warning(message);
            }else{
              this.toastr.success(response.message);
            }
            this.patients = [];
          }
          else if (response.status === 403) {
            this.toastr.warning(response.message);
          }
        },
          () => this.toastr.error('Something went wrong adding patient', 'Failed'),
          () => {
            this.dialogRef.close()
            this.toastr.clear(infoToast.toastId)
          });
    }else{
      console.log("in elseee : ",infoToast.toastId);
      if(infoToast?.toastId){
        this.toastr.clear(infoToast.toastId)
      }
      let message = ((this.patients.length - ids.length).toString()) + " Patient(s) are Moved to ";
      not_validPatients.forEach((notValid)=>{
        message += notValid.facility_id_ref?notValid.facility_id_ref.title:notValid[0].facility_id_ref.title + " ";
      });
      this.toastr.warning(message);
    }
  }

  showCreateRoundingSheetDialog() {
     console.log("this.data.obj", this.data.obj);
    this.data.obj = { ...this.data.obj, isPatientImport: true };
    const dialogRef = this.dialog.open(PatientRoundingSheetDialog,
      {
        data: this.data.obj,
        width: '50%'
      });
    dialogRef.afterClosed().subscribe(data => {
      if (!data) {
        return;
      }
      this.selectedPatientRoundingSheet = data._id;
      this.handleAddClick();

    });
  }

  async handleAddToRecurrentCensus(){
    await this.showDialog(true);

  }


  async showDialog(e){
    console.log("e : ", e);
      let date_obj=moment().format('YYYY-MM-DD').split('-');
      let date_obj_toPass;
        const [year, month, day] = date_obj;
        date_obj_toPass = {
          year, month, day
        }
      
      const obj = {
        //...this.data,
        date_obj: date_obj_toPass,
        from_pcc_patient:true
      }
      const dialogRef = this.dialog.open(RecurrentOptionDialogComponent, {
        data: obj,
        width: '40%'
      });

      dialogRef.afterClosed().subscribe(async (data) => {
        if(data){
          console.log("closedd true", data);
          let recurrenceMap=data.recurrenceMap;
          let date_objects=data.date_objects;
          let census_name=data.census_name;
          let facility_id=this.data.obj.filter_facility_id;

          await this.addRecurrentPatientRoundingSheets(facility_id,date_objects,recurrenceMap,census_name);
        }else{
          console.log("closedd false", data);
          this.recurrenceMap=[];
          this.date_objects=[];
        }
      });

  }


  async addRecurrentPatientRoundingSheets(facility_id, date_objects, recurrence_map,census_name) {

    let date_obj=moment().format('YYYY-MM-DD').split('-');
    let date_obj_toPass;
      const [year, month, day] = date_obj;
      date_obj_toPass = {
        year, month, day
      }
      let patientList;
      let not_validPatients;
      if (this.patient) {
        let validPatient=this.patient?this.patient._id:this.patient[0]._id;
        if(validPatient.facility_id_ref._id === this.data?.obj?.filter_facility_id){
        }
        patientList=validPatient;
      } else if (this.patients) {

        // patientList=this.patients.map((p:any) => { return p?p._id:p[0]._id});
        let validPatients = this.patients.filter(p => p.facility_id_ref?(p.facility_id_ref._id === this.data?.obj?.filter_facility_id):(p[0].facility_id_ref._id === this.data?.obj?.filter_facility_id));
        not_validPatients = this.patients.filter(p => p.facility_id_ref?(p.facility_id_ref._id != this.data?.obj?.filter_facility_id):(p[0].facility_id_ref._id != this.data?.obj?.filter_facility_id));
        patientList = validPatients.map(p => p._id ? p._id : p[0]._id);

      }
      console.log("patientList : ", patientList, this.patients);
      const currentDate = new Date();
  
      date_obj_toPass.hour = currentDate.getHours();
      date_obj_toPass.minute = currentDate.getMinutes();

      
    const details = {
      id:this.currentUser?._id,
      doctor_id:this.currentUser?._id,
      facilityId: facility_id,
      name: census_name,
      ischargecheck: false,
      isnotecheck: false,
      parent_census_id:null,
      date_objs:date_objects,
      provider_id:this.currentUser._id,
      company_id:this.currentUser.company_id,
      recurrence_map:recurrence_map,
      patientList:patientList,
      date:currentDate,
      date_obj:date_obj_toPass
    }
 
    if(!(patientList.length)){
      this.toastr.info('All Patients are moved to another facilities.');
      this.dialogRef.close([]);
      return;
    }
    
    if((not_validPatients.length)){
      this.toastr.info(`${not_validPatients.length} Patient(s) are moved to another facilities.`);
    }

    if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER) {
      details.doctor_id = this.currentUser?._id;
    }
    console.log("details : :", details);


    //  return;

    await this._CensusRecurrentService.addNewRecurrentCensusesWithParent(details).subscribe(async (response: any) => {
      if (response.status === 200) {
        // this.toastr.clear(infoToast.toastId);
        this.toastr.success(`${patientList.length} Patient(s) Added Successfully`);
         this.dialogRef.close(response.data);
        console.log("res", response);
  
      }
    });
  }




}
