<div [ngClass]="{'container': companySide==true}">

    <mat-card class="screen-spacing">
        <div class="flex justify-between items-center">
            <div class="flex" style="align-items: center;">
                <button type="button" style="height: min-content;" mat-raised-button class="dn-button-secondary"
                    (click)="goBack()">
                    Back
                </button>
                <h2 class="heading-margin">UWH Type Tabular Report</h2>
            </div>
            <div class="gap-2">
                <mat-label style="margin-right:20px;color:#999">Date of Creation </mat-label>
                <input style="width: 190px; margin-right: 20px;" *ngIf="iscreationDateFilterSelected" type="text" ngxDaterangepickerMd
                    [(ngModel)]="filter.creationDate" (datesUpdated)="changeCreationDate($event)" class="mat-like-input"
                    [ranges]="preDateRanges" [alwaysShowCalendars]="true" [maxDate]="currentDate"
                    [linkedCalendars]="true" [showClearButton]="true" />

                <mat-form-field style="width: 19rem; margin-right: 20px;">
                    <mat-label>Select Facility</mat-label>
                    <input type="text" placeholder="Select Facility" aria-label="facility" matInput
                        [formControl]="facilityControl" [matAutocomplete]="facilityAutoControl">
                    <mat-icon matSuffix style="cursor: pointer;" (click)="showFacilityDropdown();">
                        keyboard_arrow_down</mat-icon>
                    <mat-autocomplete #facilityAutoControl="matAutocomplete" autoActiveFirstOption>
                        <mat-option (onSelectionChange)="onSelectFacility($event, facility);"
                            *ngFor="let facility of filteredFacilities | async" [value]="facility.title">
                            {{facility.title}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <button mat-raised-button class="dn-button-primary" (click)="handleApplyFilter();" style="margin-right: 9px;">Apply Filter
                </button>

                <button mat-raised-button class="dn-button-secondary" (click)="resetFilter();" style="margin-right: 9px;">Reset Filter
                </button>

                <button mat-raised-button class="dn-button-primary" (click)="exportReportAsXLSX();">Export
                    As XLSX
                </button>
            </div>
        </div>
        <div style="overflow: auto; height: 70vh;">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.patientFullName}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="created_date">
                    <th mat-header-cell *matHeaderCellDef> Date </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.createdAt | date }}
                    </td>
                </ng-container>


                <ng-container matColumnDef="wound_num">
                    <th mat-header-cell *matHeaderCellDef> Wound Number </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.woundNo}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef> Location </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.location}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="wound_type">
                    <th mat-header-cell *matHeaderCellDef> Wound Type </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.etiolgy}}
                    </td>
                </ng-container>


                <ng-container matColumnDef="stage">
                    <th mat-header-cell *matHeaderCellDef> Stage </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.stage}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="size">
                    <th mat-header-cell *matHeaderCellDef> Size (L,W,D) cm</th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.size}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Current Wound Status </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.current_wound_status}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="weeks">
                    <th mat-header-cell *matHeaderCellDef> Weeks in Treatment </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.weeksInTx}}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
    </mat-card>
</div>