import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { AddTicketsComponent } from '../add-tickets/add-tickets.component';
import { TicketCommentsComponent } from '../ticket-details/ticket-comments/ticket-comments.component';
import { TicketService } from '../ticket.service';
import { DialogConfirmationComponent } from '../../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { result } from 'lodash';

@Component({
  selector: 'app-user-tickets',
  templateUrl: './user-tickets.component.html',
  styleUrls: ['./user-tickets.component.css']
})
export class UserTicketsComponent implements OnInit {
  selectedStatus = '';
  selectedPriority = '';
  showDetail: boolean = false;
  displayedColumns: string[] = ['Ticket No.', 'Title', 'Updation Date', 'Priority', 'Status', 'Operations'];
  rowData: any;
  currentUser: any;
  dataSource: any = new MatTableDataSource([])
  facilities: any;
  ticketsData: any;
  sideBar: boolean = false;
  url: any;
  constructor(private dialog: MatDialog,
    private ticketService: TicketService,
    private authService: AuthService,
    private toaster: ToastrService) {
    this.currentUser = this.authService.currentUser;
  }

  ngOnInit() {
    this.getTickets();
  }

  getTickets() {
    this.ticketService.getTickets(null, this.currentUser._id).subscribe((Response: any) => {
      if (Response.status == 200) {
        this.dataSource.data = Response.data;
        this.ticketsData = Response.data;
      } else {
        console.log('Response in else', Response);
      }
    })
  }

  deleteTicket(ticket) {
    let dialogRef= this.dialog.open(DialogConfirmationComponent,{
      data:{
        message: 'Are you sure you want to delete this ticket?'
      }
    });
    dialogRef.afterClosed().subscribe(result =>{
      if (result === "true") {
        if (ticket.status === 'pending' || ticket.status === 'Completed') {
          this.ticketService.deleteTicket(ticket._id).subscribe((Response: any) => {
            if (Response.status == 200) {
              this.toaster.success("Ticket deleted successfully");
              this.getTickets();
            } else {
              console.log('Response in else', Response);
            }
          });
        } else {
          this.toaster.warning("Cannot delete ticket is In progress");
        }
      }
    })
  }
  
  changeStatus($event) {
    this.filters(this.selectedStatus, this.selectedPriority, this.currentUser._id);
  }

  changePriority($event) {
    this.filters(this.selectedStatus, this.selectedPriority, this.currentUser._id);
  }

  filters(status, priority, user_id,company_id?) {
    this.ticketService.filtering(status, priority, user_id,company_id).subscribe((res: any) => {
      if (res.status == 200) {
        console.log('response', res);
        this.dataSource.data = res.data;
      }
    });
  }

  showDetails(data) {
      this.showDetail = true;
      this.sideBar = false;
      setTimeout(() => {
        this.sideBar = true;
      }, 5);
      this.rowData = data;
  }

  addNewTicket() {
    const dialogRef = this.dialog.open(AddTicketsComponent, {
      height: '550px',
      width: '55%',
    });
    dialogRef.afterClosed().subscribe(async (data) => {
      this.getTickets();
    });
  }

  editTicket(data) {
    console.log('data', data);
    data["is_editing_ticket"] = true;
    const dialogRef = this.dialog.open(AddTicketsComponent, {
      height: '75%',
      width: '55%',
      data: data
    });
    dialogRef.afterClosed().subscribe((ticketEdit) => {
      console.log('data after close', ticketEdit);
      this.getTickets();
      if(ticketEdit){
        if(this.showDetail){
          this.showDetails(ticketEdit);
        }
      }
    });
  }

}
