<h1 mat-dialog-title>Company Settings</h1>
<div mat-dialog-content>
  <ngx-ui-loader loaderId="scheduler-settings-loader"></ngx-ui-loader>
  <!-- Add your company settings form or content here -->
  <ng-container *ngIf="data.type === 'company_time'">
      <mat-checkbox [(ngModel)]="providerCanChangeAppointmentStatus">Provider can change the status.</mat-checkbox>
      <div class="time-picker">
          <div class="time-section">
              <h4>Start Time</h4>
              <div fxLayout="row" fxLayoutGap="16px" style="align-items: center;">
                  <mat-form-field appearance="fill">
                      <mat-label>Hours</mat-label>
                      <mat-select [(ngModel)]="selectedStartHour" (selectionChange)="onStartTimeChange()">
                          <mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }}</mat-option>
                      </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="fill">
                      <mat-label>Minutes</mat-label>
                      <mat-select [(ngModel)]="selectedStartMinute" (selectionChange)="onStartTimeChange()">
                          <mat-option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>

          </div>
          <div class="time-section">
              <h4>End Time</h4>
              <div fxLayout="row" fxLayoutGap="16px" style="align-items: center;">
                  <mat-form-field appearance="fill">
                      <mat-label>Hours</mat-label>
                      <mat-select [(ngModel)]="selectedEndHour" (selectionChange)="onEndTimeChange()">
                          <mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }}</mat-option>
                      </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="fill">
                      <mat-label>Minutes</mat-label>
                      <mat-select [(ngModel)]="selectedEndMinute" (selectionChange)="onEndTimeChange()">
                          <mat-option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
          </div>
      </div>
  </ng-container>
  <mat-card *ngIf="data.type === 'appointment_type'">
    <button mat-button class="dn-button-primary" style="margin-bottom: 1rem;" (click)="addAppointmentTypeDialog()">Add
      Appointment Type</button>
    <table mat-table [dataSource]="dataSource" class="table-row-hover mat-table">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element; let i = index;">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef>Duration</th>
        <td mat-cell *matCellDef="let element; let i = index;">{{ element.duration }}</td>
      </ng-container>
      <ng-container matColumnDef="color">
        <th mat-header-cell *matHeaderCellDef >Color</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <span [style.background]="element.color" style="display: inline-block; width: 20px; height: 20px; border-radius: 50%; margin-left: 5px;"></span>
        </td>
    </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <div class="flex" style="gap: 1rem;">
            <a style="cursor: pointer" (click)="updateAppointmentType(i, element)">
              <i class="icon icon-edit" matTooltip="Edit" matTooltipPosition="above" id="edit-icon"></i>
            </a>
            <a style="cursor: pointer;" (click)="deleteAppointmentType(i, element)">
              <i class="icon icon-delete" matTooltip="Delete Tag" matTooltipPosition="above" id="delete-icon"></i>
            </a>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="mat-row" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div style="margin: 16px 0;" class="no-appointments flex flex-col justify-center items-center"
      *ngIf="dataSource.data.length === 0">
      No appointment types found
    </div>
  </mat-card>
  <div *ngIf="data.type === 'business_hour'" style="margin: 1rem;">
    <h4>User Business Hour</h4>
    <div>
      <mat-form-field appearance="fill" class="flex w-1/2"><mat-label>Select Provider</mat-label>
        <mat-select name="provider" #provider="ngModel" [(ngModel)]="providerId"
          (ngModelChange)="onSelectProvider()">
          <input matInput placeholder="Search" [(ngModel)]="searchTextProvider" [ngModelOptions]="{standalone: true}"
            style="color:#000;height: 40px;padding-left: 5px;" />
          <mat-option *ngFor="let provider of uniqueProviders | searchProvider : searchTextProvider"
            value="{{provider.id}}">
            {{provider.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="providerId">
      <ngx-ui-loader loaderId="business-loader"></ngx-ui-loader>
      <div *ngFor="let day of ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];let d = index" class="day-circle"
        [class.selected]="selectedDays.includes(d)" (click)="toggleDaySelection(d); addWorkingDay(d)">
        {{ day }}
      </div>
      <div *ngFor="let workingDay of workingHours;let i = index">
        <mat-form-field>
          <mat-label>Select Day</mat-label>
          <input matInput [(ngModel)]="daysOfWeek[workingDay.daysOfWeek[0]]" disabled>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Start Time</mat-label>
          <input matInput type="time" [(ngModel)]="workingDay.startTime">
        </mat-form-field>

        <mat-form-field>
          <mat-label>End Time</mat-label>
          <input matInput type="time" [(ngModel)]="workingDay.endTime">
        </mat-form-field>
        <button mat-button *ngIf="i==0 && workingHours.length > 1" (click)="copyTimesToAll()">
          <mat-icon>content_copy</mat-icon>Copy Times to All</button>
      </div>
      <div>

      </div>
    </div>
  </div>
  <div *ngIf="data.type === 'view-option'">
    <h2>Appointment Column Settings</h2>
    <div *ngFor="let column of appointmentColumns">
        <mat-checkbox value="column" [checked]="columnSettings.includes(column)" (change)="updateCheckedColumns(column, $event)">
          {{ column | uppercase }}
       </mat-checkbox>
    </div>
  </div>
  <div *ngIf="data.type==='visit-status'">
    <h2>Appointment Visit Status</h2>
    <mat-card>
      <button mat-button class="dn-button-primary" style="margin-bottom: 1rem;" (click)="visitStatusDialog()">Add
        Visit Status</button>
      <table mat-table [dataSource]="dataSource" class="table-row-hover mat-table">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element; let i = index;">{{ element.name }}</td>
        </ng-container>
        <ng-container matColumnDef="color">
          <th mat-header-cell *matHeaderCellDef >Color</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            <span [style.background]="element.color" style="display: inline-block; width: 20px; height: 20px; border-radius: 50%; margin-left: 5px;"></span>
          </td>
      </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            <div class="flex flex-row gap-1">
              <a (click)="!element?.readOnly && updateVisitStatus(i, element)">
                <i [class.disabled]="element?.readOnly" class="icon icon-edit" matTooltip="Edit" matTooltipPosition="above" id="edit-icon"></i>
              </a>
              <a (click)="!element?.readOnly && deleteVisitStatus(i, element)">
                <i [class.disabled]="element?.readOnly" class="icon icon-delete" matTooltip="Delete Tag" matTooltipPosition="above" id="delete-icon"></i>
              </a>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedVisitStatusColumns"></tr>
        <tr mat-row class="mat-row" *matRowDef="let row; columns: displayedVisitStatusColumns;"></tr>
      </table>
      <div style="margin: 16px 0;" class="no-appointments flex flex-col items-center justify-center"
        *ngIf="dataSource.data.length === 0">
        No Visit Status found
      </div>
    </mat-card>
  </div>

</div>
<div mat-dialog-actions style="align-items: baseline;">
  <button mat-raised-button class="dn-button-secondary" (click)="onNoClick()">Close</button>
  <button mat-raised-button class="dn-button-primary" *ngIf="data.type === 'company_time'"
    style="margin-left: 2rem;margin-bottom: 1rem;" (click)="updateCompany()">Save</button>
  <button mat-raised-button class="dn-button-primary" class="dn-button-primary" *ngIf="data.type === 'business_hour'"
    (click)="saveWorkingHours()">Save</button>
  <button mat-raised-button class="dn-button-primary" class="dn-button-primary" *ngIf="data.type === 'view-option'"
    (click)="saveSettings()">Save Settings</button>
</div>