import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as global from 'src/app/includes/global';
import { AuthService } from "src/app/services/auth.service";
import { API_URL } from "src/environments/api-url";
import { BehaviorSubject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
    providedIn: 'root',
})
export class CustomWoundReportService {


    constructor(private httpclient: HttpClient,
        private _authService: AuthService, private dialog: MatDialog,) {
    }


    addCustomWoundReport(payload) {
        return this.httpclient
            .post(global.url + API_URL.CUSTOM_WOUND_REPORT.addCustomWoundReport, { ...payload });
    }

    getCustomWoundReport(filter, project) {
        return this.httpclient
            .post(global.url + API_URL.CUSTOM_WOUND_REPORT.getCustomWoundReport, { filter, project });
    }

    updateCustomWoundReport(filter, updateQuery) {
        return this.httpclient
            .post(global.url + API_URL.CUSTOM_WOUND_REPORT.updateCustomWoundReport, { filter, updateQuery });
    }

}
