import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as global from '../includes/global';
import { from } from 'rxjs';
import {API_URL} from "../../environments/api-url";

@Injectable({
    providedIn: 'root'
})
export class EncryptDecryptService {
    key: string = 'PdLvXiM1YlMFtyQQKBgQDslTObLB3exJQ4+CnsNtgBGJHBBxgKfaGl/36wnhtF3Q';
    constructor(
        private http: HttpClient
    ) { }

    encrypt(decrypted) {
        return from(new Promise((resolve, reject)=>{
            this.http.post(global.url + API_URL.AUTH.encryptString, {str : decrypted, key: this.key}).subscribe((data:any) => {
                resolve(data.str);
            }, reject);
        }));
    }

    decrypt(encrypted, key = '') {
        let k;
        if (key !== '') {
            k = key;
        } else {
            k = this.key;
        }
        return from(new Promise((resolve, reject)=>{
            this.http.post(global.url + API_URL.AUTH.decryptString, {str: encrypted, key: k}).subscribe((data:any) => {
                resolve(data.str);
            }, reject);
        }));
    }
}
