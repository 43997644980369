import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { lastValueFrom } from 'rxjs';
import { WoundService } from 'src/app/services/wound.service';

@Component({
  selector: 'app-patient-name-dialog',
  templateUrl: './patient-name-dialog.component.html',
  styleUrls: ['./patient-name-dialog.component.css']
})
export class PatientNameDialogComponent implements OnInit {
  loaderId = 'app-patient-name-dialog';
  patients: any = [];


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _woundService: WoundService,
    private _toastr: ToastrService,
    private loader: NgxUiLoaderService,

  ) { }

  async ngOnInit(): Promise<void> {
    await this.initPatients();
  }

  async initPatients() {
    this.loader.startLoader(this.loaderId);
    const projection = {
      'first_name': 1,
      'last_name': 1,
      'source': 1
    };
    const response: any = await lastValueFrom(this._woundService.getDaysToRemissionAugmentation(this.data, projection));
    if (response.status === 200) {
      this.patients = response.data;
      this.loader.stopLoader(this.loaderId);
    }
    else {
      this._toastr.error(response.data.message, 'Error');
      this.loader.stopLoader(this.loaderId);
    }
  }

}
