import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PatientMedicalHistoryService } from './patient-medical-history.service';

interface Row {
  row_id: String,
  Value: any,
  row_confirmed: Boolean,
  data_type: String
}
interface DataSet {
  header_id: String,
  header: String,
  header_confirmed: Boolean,
  row: Array<Row>
}

@Component({
  selector: 'app-admin-patient-medical-history',
  templateUrl: './admin-patient-medical-history.component.html',
  styleUrls: ['./admin-patient-medical-history.component.css']
})
export class AdminPatientMedicalHistoryComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  data_types = [
    "Text",
    "Date",
    "Dropdown"
  ];
  minDate = moment(new Date()).subtract(7, 'day').toDate();
  columns: DataSet[];
  dataSet: [];
  loaderId = 'app-admin-patient-medical-history';
  constructor(private _patientMedicalHistoryService: PatientMedicalHistoryService,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService) {
  }

  async ngOnInit() {
    this.columns = [];
    this.loader.startLoader(this.loaderId);
    await this.getAllDatasets();
    this.loader.stopLoader(this.loaderId);
  }
  addColumn() {
    var myObject = {} as DataSet;
    myObject.header_id = "";
    myObject.header = "";
    myObject.header_confirmed = false;
    myObject.row = [];
    this.columns.push(myObject);
  }
  async deleteColumn(index) {
    const infoToast = this.toastr.info('Deleting Header', 'Please wait', { disableTimeOut: true });
    if (this.columns[index].header_confirmed == true) {
      let response = await this._patientMedicalHistoryService.removeHeaderfromWoundDataSet(this.columns[index].header_id).toPromise();
      if (response['status'] == 200) {
        this.columns.splice(index, 1);
        this.toastr.clear(infoToast.toastId);
        this.toastr.success('Header Deleted Successfully', 'Success');
      } else {
        this.toastr.clear(infoToast.toastId);
        this.toastr.error('Something went wrong deleting header', 'Failed');
      }
    } else {
      this.columns.splice(index, 1);
      this.toastr.clear(infoToast.toastId);
      this.toastr.success('Header Deleted Successfully', 'Success');
    }
  }
  toggleHeaderState(index) {
    this.columns[index].header_confirmed = !this.columns[index].header_confirmed;
  }
  async ConfirmColumnHeader(index) {
    if (!(this.columns[index].header == "")) {
      if (this.columns[index].header_id == "") {
        const infoToast = this.toastr.info('Adding Header', 'Please wait', { disableTimeOut: true });
        let response = await this._patientMedicalHistoryService.addNewHeaderinWoundDataSet(this.columns[index].header).toPromise();
        if (response['status'] == 200) {
          this.columns[index].header_confirmed = !this.columns[index].header_confirmed;
          this.columns[index].header_id = response['data']['_id'];
          this.toastr.clear(infoToast.toastId);
          this.toastr.success('Header Added Successfully', 'Success');
        } else {
          this.toastr.clear(infoToast.toastId);
          this.toastr.error('Something went wrong adding header', 'Failed');
        }
      } else {
        const infoToast = this.toastr.info('Updating Header', 'Please wait', { disableTimeOut: true });
        let response = await this._patientMedicalHistoryService.updateHeader(this.columns[index].header, this.columns[index].header_id).toPromise();
        if (response['status'] == 200) {
          this.columns[index].header_confirmed = !this.columns[index].header_confirmed;
          this.toastr.clear(infoToast.toastId);
          this.toastr.success('Header Updated Successfully', 'Success');
        } else {
          this.toastr.clear(infoToast.toastId);
          this.toastr.error('Something went wrong', 'Failed');
        }
      }
    }
  }
  toggleRowState(col_index, row_index) {
    this.columns[col_index].row[row_index].row_confirmed = !this.columns[col_index].row[row_index].row_confirmed;
  }
  async ConfirmRow(i, x) {
    if (!(this.columns[i].row[x].Value == "")) {
      if (this.columns[i].row[x].row_id == "") {
        const infoToast = this.toastr.info('Adding Row', 'Please wait', { disableTimeOut: true });
        let item = {
          data_type: this.columns[i].row[x].data_type,
          value: this.columns[i].row[x].Value
        };
        let response = await this._patientMedicalHistoryService.addNewRowinWoundDataSet(this.columns[i].header_id, item).toPromise();
        if (response['status'] == 200) {
          this.columns[i].row[x].row_id = response['row_id'];
          this.columns[i].row[x].row_confirmed = !this.columns[i].row[x].row_confirmed;
          this.toastr.clear(infoToast.toastId);
          this.toastr.success('Row Added Successfully', 'Success');
        } else {
          this.toastr.clear(infoToast.toastId);
          this.toastr.error('Something went wrong adding row', 'Failed');
        }
      } else {
        const infoToast = this.toastr.info('Updating Row', 'Please wait', { disableTimeOut: true });
        let response = await this._patientMedicalHistoryService.updateRowinDataset(this.columns[i].header_id, this.columns[i].row[x].row_id, this.columns[i].row[x].data_type, this.columns[i].row[x].Value).toPromise();
        if (response['status'] == 200) {
          this.columns[i].row[x].row_confirmed = !this.columns[i].row[x].row_confirmed;
          this.toastr.clear(infoToast.toastId);
          this.toastr.success('Row Updated Successfully', 'Success');
        } else {
          this.toastr.clear(infoToast.toastId);
          this.toastr.error('Something went wrong adding row', 'Failed');
        }
      }
    }
  }
  addRow(col_index) {
    var rowObject = {} as Row;
    rowObject.Value = "";
    rowObject.row_id = "";
    rowObject.row_confirmed = false;
    rowObject.data_type = "Text";
    this.columns[col_index].row.push(rowObject);
  }
  async deleteRow(col_index, row_index) {
    const infoToast = this.toastr.info('Deleting Row', 'Please wait', { disableTimeOut: true });
    if (this.columns[col_index].row[row_index].row_confirmed == true) {
      let response = await this._patientMedicalHistoryService.removeRowfromWoundDataSet(this.columns[col_index].header_id, this.columns[col_index].row[row_index].row_id).toPromise();
      if (response['status'] == 200) {
        this.columns[col_index].row.splice(row_index, 1);
        this.toastr.clear(infoToast.toastId);
        this.toastr.success('Row Deleted Successfully', 'Success');
      } else {
        this.toastr.clear(infoToast.toastId);
        this.toastr.error('Something went wrong deleting row', 'Failed');
      }
    } else {
      this.columns[col_index].row.splice(row_index, 1);
      this.toastr.clear(infoToast.toastId);
      this.toastr.success('Row Deleted Successfully', 'Success');
    }
  }
  changetoString(col_index, row_index) {
    if (this.columns[col_index].row[row_index].data_type == 'Date') {
      return this.columns[col_index].row[row_index].Value.format('DD/MM/YYYY');
    }
    return this.columns[col_index].row[row_index].Value;
  }
  add(event: MatChipInputEvent, col_index, row_index): void {
    const value = (event.value || '').trim();
    if (value) {
      this.columns[col_index].row[row_index].Value.push(value);
    }
    // Clear the input value
    event.input.value = '';
  }
  remove(value: string, col_index, row_index): void {
    const index = this.columns[col_index].row[row_index].Value.indexOf(value);

    if (index >= 0) {
      this.columns[col_index].row[row_index].Value.splice(index, 1);
    }
  }
  changeType(col_index, row_index) {
    if (this.columns[col_index].row[row_index].data_type == 'Date') {
      this.columns[col_index].row[row_index].Value = moment(new Date());
    }
    if (this.columns[col_index].row[row_index].data_type == 'Dropdown') {
      this.columns[col_index].row[row_index].Value = [];
    }
  }
  async getAllDatasets() {
    let response = await this._patientMedicalHistoryService.getAllDatasetinCompany().toPromise();
    if (response['status'] == 200) {
      this.dataSet = response['data'];
      this.dataSet.forEach((element: any, index) => {
        let myObject = {} as DataSet;
        myObject.header_id = element['_id'];
        myObject.header = element['header'];
        myObject.header_confirmed = true;
        myObject.row = [];
        element.rows.forEach(element => {
          let rowObject = {} as Row;
          if (element['data_type'] == "Date") {
            rowObject.Value = moment(element['value']);
          } else {
            rowObject.Value = element['value'];
          }
          rowObject.row_id = element['_id'];
          rowObject.row_confirmed = true;
          rowObject.data_type = element['data_type'];
          myObject.row.push(rowObject);
        });
        this.columns.push(myObject);
      });
    }
  }
}
