import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
// import { Http, ResponseContentType } from '@angular/http';
import {catchError, map, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {DashboardService} from '../dashboard/dashboard.service';
import {CreatePdfService} from '../transcription-detail/transcription-detail.service';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from 'src/app/services/auth.service';


@Component({
    selector: 'app-pdf-view',
    templateUrl: './pdf-view.component.html',
    styleUrls: ['./pdf-view.component.css']
})

export class PdfViewComponent implements OnInit {
    public file: any;
    public filedata: any;
    public title: any;
    public date: any;
    public filepath: any;
    public currentuser: any;
    public user: any;
    public backURL: any;
    public backURLString = '/transcription-detail';
    public fileExtension: string;
    public statusResponse: any;
    public updatedFileResponse: any;


    public constructor(
        private router: Router,
        public sanitizer: DomSanitizer,
        private http: HttpClient,
        private dashboardService: DashboardService,
        private createPdfService: CreatePdfService,
        private toastr: ToastrService,
        private _router: Router,
        private _authService: AuthService
    ) {
        this.filedata = localStorage.getItem('selectedFile');
        // this.currentuser = localStorage.getItem('currentUser');
        this.currentuser = this._authService.currentUser;
        if (!this.currentuser) {
            // if not login, stop and redirect to login #mohsin-dev
            localStorage.setItem('currentUser', 'null');
            this._router.navigate(['/login']);
            return;
        }
        // this.user = JSON.parse(this.currentuser);
        this.user = this.currentuser;
        this.backURL = localStorage.getItem('backURL');
        if (this.backURL) {
            this.backURLString = this.backURL;
        }
        this.file = JSON.parse(this.filedata);
        this.title = this.file.transcribtion_title;
        this.date = (this.file.date) ? this.file.date : this.file.date_time;
        this.filepath = this.file.path;
        if (!this.filepath) {
            return;
        }
        var extension = this.filepath.split('.');
        this.fileExtension = extension[extension.length - 1];
    }

    ngOnInit() {

        if (this.currentuser === null) {
            localStorage.removeItem('currentUser');
            this.router.navigate(['/login']);
        }
        // this.file = this.filedata;

    }

    changeFileSignStatus() {
        this.createPdfService.convertDocxFileToPdf(this.file).subscribe(data => {
            var title = this.file.transcribtion_title.split('.');
            this.updatedFileResponse = data;
            if (this.updatedFileResponse.status == 200) {
                this.dashboardService.changeFileSignStatus({
                    is_signed: 'true',
                    id: this.file._id,
                    path: this.updatedFileResponse.data,
                    transcribtion_title: title[0] + '.pdf'
                }, (this.file.audio_id) ? true : false).subscribe(data => {
                    this.statusResponse = data;
                    if (this.statusResponse.status == 200) {
                        this.toastr.success('Success', this.statusResponse.message);
                        this.router.navigate([this.backURLString]);
                    } else {
                        this.toastr.error('Failed', this.statusResponse.message);
                    }
                });
            } else {
                this.toastr.error('Failed', this.updatedFileResponse.message);
            }
        });

    }

    download() {
        return this.http
            .get(this.filepath, {
                //  responseType: ResponseContentType.Blob
                responseType: 'blob'
                // search: // query string if have
            }).pipe(
                map(res => {
                    return {
                        filename: this.title,
                        data: res
                    };
                }))
            .subscribe(res => {
                var url = window.URL.createObjectURL(res.data);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
            }, error => {
            }, () => {
            });
    }
}

