<ng-container *ngIf="view === ''">
    <p style="padding: 0 10px;">
        Schedules
    </p>
    <table mat-table [dataSource]="dataSource" matSort>
    
        <ng-container matColumnDef="dose">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Dose
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.doseent}}
            </td>
        </ng-container>
    
        <ng-container matColumnDef="doseUOM">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Dose UOM
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.doseUOMent}}
            </td>
        </ng-container>
        
    
        <ng-container matColumnDef="scheduleType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Schedule Type
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.scheduleType}}
            </td>
        </ng-container>
        
    
        <ng-container matColumnDef="frequency">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Frequency
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.frequency}}
            </td>
        </ng-container>
        
    
        <ng-container matColumnDef="indicationsForUse">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Indications For Use
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.indicationsForUse}}
            </td>
        </ng-container>
        
    
        <ng-container matColumnDef="directions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Directions
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.directions}}
            </td>
        </ng-container>
        
    
        <ng-container matColumnDef="startDateTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Start Date Time
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.startDateTime}}
            </td>
        </ng-container>
        
    
        <ng-container matColumnDef="endDateTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                End Date Time
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.endDateTime}}
            </td>
        </ng-container>
        
    
        <ng-container matColumnDef="lastAdministration">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Last Administration
            </th>
            <td mat-cell *matCellDef="let element">
                <a href="#" (click)="$event.preventDefault(); changeView('lastAdministration', element.lastAdministration)" *ngIf="element.lastAdministration">View</a>
                <span *ngIf="!element.lastAdministration">-</span>
            </td>
        </ng-container>
        
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</ng-container>


<ng-container *ngIf="view === 'lastAdministration'">    
    <button mat-raised-button class="dn-button-secondary" (click)="changeView('', null)">Go Back to Schedules</button>
    <app-last-admission [data]="viewData"></app-last-admission>
</ng-container>