import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-associated-provider-dialog',
  templateUrl: './associated-provider-dialog.component.html',
  styleUrls: ['./associated-provider-dialog.component.css']
})
export class AssociatedProviderDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA)public data: any) { }

  dataSource: any;

  displayedColumns: any = [ 'provider', 'recentLogin'];
  

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data);
  }

}
