import { Component, OnInit ,Input} from '@angular/core';
import { Router } from '@angular/router';
import { MatTable, MatTableDataSource } from "@angular/material/table";

import { CensusPatientListService } from '../census-patient-list/census-patient-list.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Patient } from 'src/app/classes/patient';
import { AuthService } from 'src/app/services/auth.service';
import { ViewNoteDialog } from '../transcription-create/view-note-dialog/view-note-dialog.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-patient-list-siderbar',
  templateUrl: './patient-list-siderbar.component.html',
  styleUrls: ['./patient-list-siderbar.component.css']
})
export class PatientListSiderbarComponent implements OnInit {

  params: {
    id: string,
    patient_id:string
  }
  @Input() note: any;
  clickedRows = new Set<any>();
  dataSource: MatTableDataSource<any>;
  recentNotes: any = [];
  loaderId = 'episodes-of-care-loader-sidenav';
  patientsImportSource: 'dn' | 'pcc';
  currentUser: any;
  displayedColumns:Array<String>
  census: any;
  constructor( private dialog: MatDialog,private censusPatientListService: CensusPatientListService,private _router: Router
   ,private loader: NgxUiLoaderService,  private authService: AuthService,) { 
    this.currentUser = this.authService.currentUser;
    this.authService.onUserAuthChange.subscribe(currentUser => this.currentUser = currentUser);
   
   }

  async ngOnInit() {
    this.displayedColumns = [ 'name','note','operations']
    await this.initCensus();
    await this.getRecentNoteByCensusId();
   
  }
  initTableDataSource() {

    if (this.dataSource) {
      this.dataSource.data = this.census.patientList;
    } else {
      this.dataSource = new MatTableDataSource(this.census.patientList);
    }
    console.log("akjsd", this.dataSource);
    
  }
  async initCensus() {
    this.loader.startLoader(this.loaderId);
    const censusResponse: any = await this.censusPatientListService.getCensus(this.note.rounding_sheet_id).toPromise();

    if (censusResponse.status === 200) {
      this.loader.stopLoader(this.loaderId);
      console.log('init cens', censusResponse);
      if (censusResponse?.data?.patientList) {
        const { patientList, ...data } = censusResponse.data;
        this.census = {
          ...data,
          patientList: patientList.filter(p => p.is_active === 'true').map(p => new Patient(p)) as Patient[]
        };
        let row  = this.census.patientList.filter(item=>item._id==this.note.patient._id)
        this.clickedRows.add(row[0])
        
      }
      this.patientsImportSource = this.census.facility.pcc_facId ? 'pcc' : 'dn';
      this.initTableDataSource();

    }
  }
  noteEditor(element, telemedicine, index) {
    let rs_id = this.note.rounding_sheet_id;
    let routerMapParams: any = {
      patient_id: element._id,
      facility_id: this.census.facility._id,
      dos: this.census.date,
      census_id: this.census._id,
      telemedicine: telemedicine,
      provider_id: this.census.createdBy,
      nextPatientNote: true
    }

    let routerParams = {
      patient_id: element._id,
      facility_id: this.census.facility._id,
      dos: this.census.date,
      rs_id: this.census._id,
      visit_date: this.census.date.toString(),
      patient_name: element.first_name + ' ' + element.last_name,
      dob: element.date_of_birth,
    }
    this.censusPatientListService.setChargeData(routerMapParams)
    if (element.charges) {
      this.censusPatientListService.setCharge(element.charges)
    }
    // let obj: any = JSON.parse(JSON.stringify(routerMapParams));
    // obj.patientList = this.census.patientList;
    // obj.patientIndex = index;
    // this.censusPatientListService.setPatientListData(obj);
    // localStorage.setItem('hide_census', "true");
    this._router.navigate(['/note-editor/new', routerMapParams])
  }
  show_selected(row) {
    this.clickedRows.clear()
    this.clickedRows.add(row)
  }
  isOwner(census) {
    return this.currentUser._id == census?.createdBy
  }
  getRecentNoteInCensus(patient) {
    // console.log("patient",patient);
    this.recentNotes.map(ele => {
      if (patient._id == ele.patient_id_ref) {
        delete patient.recentNoteInCensus;
        patient.recentNoteInCensus = ele;
      }
    });

    const { recentNoteInCensus } = patient;
    // if (recentNoteInCensus && recentNoteInCensus.rounding_sheet_id === this.census._id) {
    //   return recentNoteInCensus;
    // }
    return recentNoteInCensus;
    return false;
  }
  showViewNoteDialog(note_id) {
    const dialogRef = this.dialog.open(ViewNoteDialog, {
      width: '80%',
      //height:'80%',

      data: {
        note_id: note_id
      }
    });
    // dialogRef.beforeClosed().subscribe(async (confirm: any) => {
    // });
  }
  async getRecentNoteByCensusId() {
    const response: any = this.censusPatientListService.getRecentNoteByCensusId(this.note.rounding_sheet_id).toPromise();
    if (response.status === 200) {
      console.log("res", response.data);
      this.recentNotes = response.data;
    }
  }

}
