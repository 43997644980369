import { AfterViewInit, Component, DoCheck, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, firstValueFrom, lastValueFrom } from 'rxjs';
import { CensusListService } from 'src/app/includes/census-list/census-list..service';
import { PatientRoundingSheetService } from 'src/app/includes/patient-rounding-sheets/patient-rounding-sheet.service';
import { AuthService } from 'src/app/services/auth.service';
import { MipsService } from 'src/app/services/Modules/mips.service';
import { NoteAddChargeService } from '../note-add-charge.service';
import * as global from '../../../global';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { SidebarMipsComponent } from 'src/app/components/sidebar-addCharges/sidebar-mips/sidebar-mips.component';
import moment from 'moment';
import { UpdatePatientInfoDialogComponent } from '../../update-patient-info-dialog/update-patient-info-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NoteSidebarIcdsComponent } from './note-sidebar-icds/note-sidebar-icds/note-sidebar-icds.component';
import { DialogConfirmationComponent } from 'src/app/includes/census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { NoteCommentsService } from '../../note-comments/note-comments.service';
import { BillerDashboardService } from 'src/app/includes/biller-dashboard/biller-dashboard.service';
import { NotificationsService } from 'src/app/includes/notifications/notifications.service';
import { NoteSidebarCptsComponent } from './note-sidebar-cpts/note-sidebar-cpts/note-sidebar-cpts.component';
export interface ChargeAction {
  action: any,
  data: any
}
@Component({
  selector: 'app-note-add-charge',
  templateUrl: './note-add-charge.component.html',
  styleUrls: ['./note-add-charge.component.css']
})
export class NoteAddChargeComponent implements OnInit, DoCheck {

  @Input() note: any;
  @Input() noteEditorIcds: any[] = [];
  @Input() noteEditorCpts: any[] = [];
  @Input() emCodeEditorCpts: any[] = [];
  @Input() telemedPhrase: any = false
  @Input() signedNotesStatus: any;
  @Input() carryChargeStatusIcds: any;
  @Input() carryChargeStatusCpts: any;
  @Output() sidebarClose = new EventEmitter<boolean>();
  @Output() onChargeChange = new EventEmitter<any>();
  @Output() onChargeAndICDChange = new EventEmitter<any>()
  @Output() onPhrasesChange = new EventEmitter<any>();
  @Output() noteEditorCptsChange = new EventEmitter<any>()
  @Output() noteEditorIcdsChange = new EventEmitter<any>();
  @ViewChild(NoteSidebarIcdsComponent) noteSidebarICDs: NoteSidebarIcdsComponent;
  @ViewChild(NoteSidebarCptsComponent) noteSidebarCPTs: NoteSidebarCptsComponent;
  @Input() companySubmitMipsStatus: any;


  @ViewChild('tabGroup') tabGroup;
  showMips: boolean = false;
  showMipsToaster: boolean = true;
  showCPTs = true;
  showICDs = true;
  chargesLoader: Boolean = false;
  charge: any = {};
  currentUser: any;
  rs_id;
  patient_id;
  facility_id;
  provider_id;
  params: any = {};
  carryOverCharge = {};
  reportMeasure: Boolean = false;
  disableClick: Boolean = false;
  canEditPatientInfo:boolean=false
  recentMips:any;
  tabIndex: Number = 0;
  model = {
    owner_id: '',
    patient_id: '',
    rounding_sheet_id: '',
    icd_id: [],
    cpt_id: [],
    wound_diagnose_icds: [],
    billing_note: '',
    note: '',
    status: '',
    visit_date: '',
    facility_id: '',
    measure: false,
    charge_id: '',
    cpt_modifiers: [],
    is_approved: "false",
    note_id: '',
    patient_first_name:'',
    patient_last_name:'',
    patient_dob:'',
    patient_gender:'',
    source:'',
    // coSignedProvider: ''
  };
  mipPayload = {};
  subscriptions: any[] = [];
  isChargeLoading: BehaviorSubject<boolean> = new BehaviorSubject(null);
  cptsExist: BehaviorSubject<boolean> = new BehaviorSubject(null);
  icdsExist: BehaviorSubject<boolean> = new BehaviorSubject(null);
  submitDoclose: boolean = false;
  global = global;

  buttonStates = {
    saveAsDraft: false
  }
  originalChargeState;
  setTimeOutId: NodeJS.Timeout;
  questionnaireResults:any;
  hideMipsReportMeasure: boolean = false;
  autoSaveMessage: string;

  chargeForMips: any;
  submittedMips: any = [];
  availableMips: any;
  denominatorCalulationInProgressValue: any = false;
  @ViewChild(SidebarMipsComponent) _sidebarMipsComponent: SidebarMipsComponent;
  mipSubscription: any;

  currentCompany: any;
  submitButtonText: string = "Submit";
  canSubmitChargeWithMupltipleCpts: any = false;
  customizedTabs: {};
  constructor(
    private noteCommentsService: NoteCommentsService,
    private censusListService: CensusListService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private service: PatientRoundingSheetService,
    private toastr: ToastrService,
    private patientRoundingSheetService: PatientRoundingSheetService,
    private mipsService: MipsService,
    private dialog: MatDialog,
    private _noteAddChargeService: NoteAddChargeService,
    private _companyService: CompanyService
  ) {
    this.patient_id = this.route.snapshot.paramMap.get('patient_id');
    this.rs_id = this.route.snapshot.paramMap.get('census_id');
    this.facility_id = this.route.snapshot.paramMap.get('facility_id');
    this.provider_id = this.route.snapshot.paramMap.get('provider_id');
    this.params = {
      rs_id: this.rs_id,
      patient_id: this.patient_id,
      facility_id: this.facility_id,
      provider_id: this.provider_id,
    }
    this.currentUser = this.authService.currentUser;
  }

  async ngOnInit() {
    //this.getrecentMip()
    await this.getCurrentCompany();
    if(this.currentCompany.portal_type === global.PORTAL_TYPE.NON_WC) {
      await this.getCompanySettingForSubmittingCharge();
    }
    this.noteCommentsService.getTabsOrder(!!this.currentCompany.should_allow_customized_tab).subscribe((response: { status, message, data }) => {
      if (response.data) {
        this.customizedTabs = response.data
      }
    })
    this.autoSaveMessage='';
    this._noteAddChargeService.setisCPT(false);
    this._noteAddChargeService.setisICD(false);
    if (this.authService.currentUser.user_type === global.USER_TYPE.TRANSCRIBER || this.authService.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT) {
      this.model.owner_id = this.note.provider._id;
    }
    else {
      this.model.owner_id = this.authService.currentUser._id;
    }
    await this.initCharge();
    this.getrecentMip()
    this._sidebarMipsComponent?.refreshMeasuresonInit();
    if(this.charge){
      await this.initQuestionaireResults();
    }
    this.saveOriginalChargeState();
    console.log("noteeeee", this.note);
    
  }

  async getCompanySettingForSubmittingCharge() {
    const res = await lastValueFrom(this._companyService.getCompany({ _id: this.currentUser.company_id }, { allow_multiple_cpts: 1 }));
    if(res.status == 200) {
      this.canSubmitChargeWithMupltipleCpts = res.data.allow_multiple_cpts;
    }
  }

  async getCurrentCompany() {
    const filter = {
      _id: this.currentUser.company_id
    };
    const projection = {
      charge_edit_duration: 1,
      can_edit_patient_while_submitting_charges: 1,
      portal_type: 1,
      isMipReportEnabled: 1,
      name: 1,
      company_type: 1,
      should_allow_customized_tab: 1
    };
    const response: any = await lastValueFrom(this._companyService.getCompany(filter, projection));
    if (response.status === 200) {
      this.currentCompany = response.data;
      this.canEditPatientInfo=response.data.can_edit_patient_while_submitting_charges
      console.log("this.currentCompany", this.currentCompany);
    }
  }

  ngDoCheck() {
    this.doCheckButtonStates();
  }

  doCheckButtonStates() {
    if (this.disableButtonForDraft() || this.disableClick) {
      this.buttonStates.saveAsDraft = false;
      return;
    }

    if (this.isChargeTouched()) {
      if(this.model.cpt_id.length == 0 && this.model.icd_id.length == 0){
        this.buttonStates.saveAsDraft = false;
      }else{
        this.buttonStates.saveAsDraft = true;
      }
    } else {
      this.buttonStates.saveAsDraft = false;
    }
  }

  isChargeTouched() {
    return !this.compareOriginalChargeState()
  }

  compareOriginalChargeState() {
    const _model = { ...this.model };
    _model.icd_id = this.model.icd_id.map(icd => typeof icd === 'string' ? icd : icd._id)
    _model.cpt_id = this.model.cpt_id.map(cpt => typeof cpt === 'string' ? cpt : cpt._id)

    return JSON.stringify(_model) === this.originalChargeState;
  }
  async getrecentMip(){
    this.availableMips = [];
    const details = {
      patient_id: this.note.patient._id
    }
    this.service.getrecentMip(details).subscribe((res: any) => {
      if (res.data.allMips?.length > 0) {
        let year = (new Date()).getFullYear();
        let recentOneMip;
        if(res.data.recentOneMip){
          recentOneMip = res.data.recentOneMip;
        }
        let allRecentMips = res.data.allMips.filter((mip: any) => mip.date_obj?.year == year);
        if(allRecentMips.length >= 0){
          allRecentMips = allRecentMips.map((mip: any) => {return mip.recentMip;});
          allRecentMips = [].concat(...allRecentMips);
          allRecentMips = allRecentMips.filter((obj, index, self) => index === self.findIndex(q => q.measure_id[0]?._id?.toString() == obj.measure_id[0]?._id?.toString()));
          recentOneMip.recentMip = allRecentMips;
        }
        this.recentMips = recentOneMip;

        if (this.recentMips) {
          this.submittedMips = this.recentMips.recentMip;
        }
      }
      // console.log("this.mipsInTh isYear() : ", this.mipsInThisYear());
    })

  }

  async checkRecentMipForCharge() {
    const details = {
      patient_id: this.note.patient._id
    }
    this.service.getrecentMip(details).subscribe((res: any) => {
      if (res.data.allMips?.length > 0) {
        let year = (new Date()).getFullYear();
        let recentOneMip;
        if(res.data.recentOneMip){
          recentOneMip = res.data.recentOneMip;
        }
        let allRecentMips = res.data.allMips.filter((mip: any) => mip.date_obj?.year == year);
        if(allRecentMips.length >= 0){
          allRecentMips = allRecentMips.map((mip: any) => {return mip.recentMip;});
          allRecentMips = [].concat(...allRecentMips);
          allRecentMips = allRecentMips.filter((obj, index, self) => index == self.findIndex(q => q.measure_id[0]?._id?.toString() == obj.measure_id[0]?._id?.toString()));
          recentOneMip.recentMip = allRecentMips;
        }
        this.recentMips = recentOneMip;

        if (this.recentMips) {
          this.submittedMips = this.recentMips.recentMip;
        }
      }

    })
  }
  saveOriginalChargeState() {
    const _model = { ...this.model };
    _model.icd_id = this.model.icd_id.map(icd => typeof icd === 'string' ? icd : icd._id)
    _model.cpt_id = this.model.cpt_id.map(cpt => typeof cpt === 'string' ? cpt : cpt._id)
    return this.originalChargeState = JSON.stringify(_model);
  }
  async initCharge() {
    this.chargesLoader = false
    let cpt_modifiers = [];
    const details = {
      patient_id: this.note.patient._id,
      rounding_sheet_id: this.note.rounding_sheet_id,
      visit_date: this.note.dates.service,
      status: null
    }
    let response: any = await this.censusListService.getCharge(details).toPromise();
    if (response.status === 200) {
      this.charge = JSON.parse(JSON.stringify(response.data));
      this.mipPayload = JSON.parse(JSON.stringify(this.charge));
      this.checkDuration(this.charge);
      //if there is no drafted charge than we will get most recent charge based on owner and patient ids
      if (response.data == null) {
        let res: any = await this.service.getRecentCharge(details).toPromise()
        if (res.status == 200) {
          cpt_modifiers = res.data.cpt_modifiers ? JSON.parse(JSON.stringify(res.data.cpt_modifiers)) : [];
          let responseCharge = JSON.parse(JSON.stringify(res.data));

          responseCharge.icd_id=responseCharge.icd_id?.filter((icd:any)=>{
            const [month, day, year] = icd.end_date.split("/");
            if ((!(moment(`${year}-${month}-${day}`).isBefore(moment())))||(icd.end_date == "")) {
              return icd;
            }
          })

          console.log("res.data, carryOverCharge", responseCharge)
          this.carryOverCharge = responseCharge; //res.data;
          this.charge = this.carryOverCharge;
          if(!this.carryChargeStatusIcds && this.charge){
           this.charge.icd_id = []
          }
          if(!this.carryChargeStatusCpts && this.charge){
            this.charge.cpt_id = []
          }
          // if(!this.carryChargeStatusCpts){

          // }
          //deleting this status property to avoid any flags manipulatoin as we'll just carying this charge as hint
          delete this.charge.status;
        }
        // this.isChargeLoading.next(null);
      } else {
        cpt_modifiers = JSON.parse(JSON.stringify(response.data.cpt_modifiers));
      }
      if (!this.charge?.status || this.charge?.status !== "submit") {
        this.isChargeLoading.next(false);
      }
      else {
        this.isChargeLoading.next(null);
      }
      if (this.note) {
        this.model.visit_date = this.note.dates.service;
        this.model.rounding_sheet_id = this.note.rounding_sheet_id;
        this.model.patient_id = this.note.patient._id;
        this.model.facility_id = this.note.facility._id;
        this.model.note_id = this.note._id;
        this.model.patient_first_name=this.note.patient.first_name,
        this.model.patient_last_name=this.note.patient.last_name,
        this.model.patient_dob=this.note.patient.date_of_birth,
        this.model.patient_gender=this.note.patient.gender,
        this.model.source=this.note.patient.source

      }
      // reason to check this.charge.status is that we only previoulsy existed charge will have status otherwise we'll create new one
      if (this.charge && this.charge.status) {
        if(this.charge._id){
          this.model.charge_id = this.charge._id;
          this.chargeForMips=this.charge;
        }
        this.model.cpt_modifiers = cpt_modifiers;
        this.model.note = this.charge.note;
        this.model.patient_first_name = this.charge.patient_first_name;
        this.model.patient_last_name = this.charge.patient_last_name;
        this.model.patient_dob = this.charge.patient_dob;
        this.model.patient_gender = this.charge.patient_gender;
        this.model.cpt_id = this.charge?.cpt_id || [];
        this.model.icd_id = this.charge?.icd_id || [];
        this.model.wound_diagnose_icds = this.charge?.wound_diagnose_icds || [];
        if(this.model.cpt_id.length > 0 || this.model.icd_id.length > 0){
          this.charge.cpt_id.forEach((chrge) => {
            const modifierIndex = cpt_modifiers.findIndex((mod) => mod.cpt === chrge._id)
            if(modifierIndex > -1){
              chrge['unit'] = cpt_modifiers[modifierIndex].unit;
            }
          })
          this.noteEditorCptsChange.emit(this.charge.cpt_id);
        }
      }else{
        if(this.charge?.cpt_id && this.charge?.icd_id){
          this.model.cpt_id = this.charge?.cpt_id;
          this.model.icd_id = this.charge?.icd_id;
          this.noteEditorCptsChange.emit(this.charge.cpt_id);
        }
      }
      this.chargesLoader = true;
      this.onChargeChange.emit(this.model);
    }
  }

  async saveData() {
    await this.addCharge('draft', false, "false");
    this.saveOriginalChargeState()
  }

  checkIfMultipleCPTSAllowed() {
    if(!this.canSubmitChargeWithMupltipleCpts && this.currentCompany.portal_type === global.PORTAL_TYPE.NON_WC) {
      let dialogRef = this.dialog.open(DialogConfirmationComponent, {
        data: {
          message: 'You have selected more than one CPT <br> Are you sure to submit?'
        }
      });
      dialogRef.afterClosed().subscribe(async result => {
        if (result === 'true') {
          this.addCharge();
          this.saveOriginalChargeState();
        }
        else if (result === 'false') {
          dialogRef.close();
        }
      });
    } else {
      this.addCharge();
      this.saveOriginalChargeState();
    }
  }

  async getMipAvailableAndSubmitStatus(){
    return new Promise(async (resolve, reject) => {
      const questionnaireResults = await firstValueFrom(this.mipsService.castQuestionnaireResults);
          console.log("questionnaireResults?.length, this.availableMips?.length", questionnaireResults?.length, this.availableMips?.length);
          if (Array.isArray(questionnaireResults) && questionnaireResults.length > 0) {
            if(this.availableMips&&(this.availableMips.length==questionnaireResults.length)){
              resolve(true);
            }else{
              resolve(false);
            }
          }else{
            if(this.recentMips){
              resolve(true);
            }else if(this.availableMips&&this.availableMips.length>0){
              resolve(false);
            }
          }
          resolve(true);
    });
  }

  availableMipsEvent(e){
    this.availableMips=e;
  }

  showMipsToasterOnce(e) {
    this.showMipsToaster =  e;
  }


  async submitCharge() {
    if(this.model.cpt_id.length > 0 && this.model.icd_id.length > 0) {
      if (this.signedNotesStatus) {
        if (this.note.is_signed === "true") {
          if (this.canEditPatientInfo) {
            this.editPatientInfoPopup()
          }
          else if (global.PORTAL_TYPE.WC) {
            this.checkIfMultipleCPTSAllowed();
          } else {
            await this.addCharge();
            this.saveOriginalChargeState();
          }
        } else {
          this.toastr.info("Note must be signed first before submitting the charges");
        }
      } else {
        if (this.canEditPatientInfo) {
          this.editPatientInfoPopup()
        } else if (global.PORTAL_TYPE.WC) {
          this.checkIfMultipleCPTSAllowed();
        } else {
          await this.addCharge();
          this.saveOriginalChargeState();
        }
      }
    }else if(this.model.cpt_id.length == 0){
      this.toastr.error("Atleast 1 Charge should be selected");
    }else{
      this.toastr.error("Atleast 1 Diagnose should be selected");
    }
  }

  showAutoSaveMessage(){
    this.autoSaveMessage = "Charges Auto Saved at: " + moment(new Date()).format('HH:mm:ss').toString();
  }

  async AutoaddCharge(statuss = 'submit', doClose = true, approved = "true", type?) {
    if(this.model.cpt_id.length > 0 || this.model.icd_id.length > 0){
      this.model.status = statuss;
      this.model.is_approved = approved;
      if (this.submitDoclose) {
        doClose = false;
      }
      // if (this.model.cpt_id.length > 0 && this.model.icd_id.length <= 0) {
      //   this.cptsExist.next(true);
      //   this.icdsExist.next(false);
      //   return;
      // } else if (this.model.icd_id.length > 0 && this.model.cpt_id.length <= 0) {
      //   this.cptsExist.next(false);
      //   this.icdsExist.next(true);
      //   return;
      // } else if (this.model.icd_id.length <= 0 && this.model.cpt_id.length <= 0) {
      //   this.cptsExist.next(false);
      //   this.icdsExist.next(false);
      //   return;
      // }
      // else {
      this.cptsExist.next(true);
      this.icdsExist.next(true);
      // }

      // console.log("in note add charge screen: ", this.model);
      this.disableClick = true;
      this.model.cpt_id = Array.from(new Set(this.model.cpt_id));
      this.model.icd_id = Array.from(new Set(this.model.icd_id));
      const res: any = await this.patientRoundingSheetService.addCharge(this.model).toPromise();
      if (res.status == 200) {
        this.disableClick = false;
        this.saveOriginalChargeState();
        if (this.charge?.status && this.charge?.status == "draft") {
          if (JSON.stringify(this.charge.icd_id.map(a => a._id)) !== JSON.stringify(this.model.icd_id) ||
          JSON.stringify(this.charge.cpt_id.map(a => a._id)) !== JSON.stringify(this.model.cpt_id)) {

          }
        }
        else {

        }
        this.model.charge_id = res.data.array[0]._id;
        // this.onChargeChange.emit(this.model);
        this.submitDoclose = false;
        this.model.note = '';
        this.model.measure = false;
        const [charge] = res.data.array;
        this.chargeForMips=charge;
        if(statuss=='submit'){
          const questionnaireResults = await firstValueFrom(this.mipsService.castQuestionnaireResults);
          if (Array.isArray(questionnaireResults) && questionnaireResults.length > 0) {
            this.mipsService.saveQuestionnareResults(questionnaireResults.map(r => ({ measure_id: r.measure._id, answer: r.answer, matched: r.measure.matched, reasons:r.reasons, question_id:r.question_id })), charge._id, this.currentUser.company_id)
            .subscribe((res:any)=>{
              this.getrecentMip();
              this.toastr.success('MIPS are Submitted Successfully', 'Success');
              this.mipsService.setQuestionnairesResult([]);
              this._sidebarMipsComponent?.refreshMeasuresonInit();
              this.getrecentMip();
            })
          }
        }else{
          this._sidebarMipsComponent?.refreshMeasuresonInit();
          this.getrecentMip();
        }
        return res;
      }
    }
  }

  getMipsValueForButton(){
    let resp=true;
    this.mipsService.castQuestionnaireResults.subscribe(res=>{
      if (Array.isArray(res) && res.length >0) {
        resp= false;
      } 
      else 
        resp= true;
    });
    return resp;
  }
 
  denominatorCalulationInProgress(e){
    this.denominatorCalulationInProgressValue=e;
  }

  getRecentMipsEvent(e){
    if(e){
      this.getrecentMip();
    }
  }

  async saveMipsasDraft(){
    const questionnaireResults = await firstValueFrom(this.mipsService.castQuestionnaireResults);
    if (Array.isArray(questionnaireResults) && questionnaireResults.length > 0&&this.chargeForMips) {
      this.mipsService.draftQuestionnareResults(questionnaireResults.map(r => ({ measure_id: r.measure._id, answer: r.answer, matched: r.measure.matched, reasons:r.reasons, question_id:r.question_id })), this.model.charge_id||this.chargeForMips._id, this.currentUser.company_id)
        .toPromise();
    }
  }

  async addCharge(statuss = 'submit', doClose = true, approved = "true", type?) {
    this.model.status = statuss;
    this.model.is_approved = approved;
    if (this.submitDoclose) {
      doClose = false;
    }
    this.cptsExist.next(true);
    this.icdsExist.next(true);
    this.disableClick = true;
    this.model.cpt_id = Array.from(new Set(this.model.cpt_id));
    this.model.icd_id = Array.from(new Set(this.model.icd_id));
    if(this.charge.canEdit) this.model.status = "resubmit";
    const res: any = await this.patientRoundingSheetService.addCharge(this.model).toPromise();
    if (res.status == 200) {
      this.disableClick = false;
      if (this.charge && this.charge.status) {
        if( statuss === "submit"){
          this.toastr.success('Charge is Submitted Successfully', 'Success');
        }else{
          if (JSON.stringify(this.charge.icd_id.map(icds => icds._id)) !== JSON.stringify(this.model.icd_id) ||
          JSON.stringify(this.charge.cpt_id.map(cpts => cpts._id)) !== JSON.stringify(this.model.cpt_id)) {
            this.toastr.success('Charge updated', 'Success');
          }
        }
      }
      else {
        if( statuss === "submit"){
          this.toastr.success('Charge is Submitted Successfully', 'Success');
        }else{
          this.toastr.success('Charge created', 'Success');
        }
      }
      this.model.charge_id = res.data.array[0]._id;
      this.submitDoclose = false;
      this.model.measure = false;
      const [charge] = res.data.array;
      this.chargeForMips=charge;

      if(statuss=='submit'){
        const questionnaireResults = await firstValueFrom(this.mipsService.castQuestionnaireResults);
        if (Array.isArray(questionnaireResults) && questionnaireResults.length > 0) {
          this.mipsService.saveQuestionnareResults(questionnaireResults.map(r => ({ measure_id: r.measure._id, answer: r.answer, matched: r.measure.matched, reasons:r.reasons, question_id:r.question_id  })), charge._id, this.currentUser.company_id)
          .subscribe((res:any)=>{
            this.getrecentMip();
            this.toastr.success('MIPS are Submitted Successfully', 'Success');
            this.mipsService.setQuestionnairesResult([]);
            this._sidebarMipsComponent?.refreshMeasuresonInit();
            this.getrecentMip();
          })
        }
      }else{
        this._sidebarMipsComponent?.refreshMeasuresonInit();
        this.getrecentMip();
      }

    }

  }
  ngOnDestroy(): void {
    this._noteAddChargeService.setisCPT(false);
    this._noteAddChargeService.setisICD(false);
  }
  cancel() {
    this.sidebarClose.emit(true);
  }

  disableButton() {
    if ((this.model.icd_id.length <= 0 && this.model.cpt_id.length <= 0)||this.note?.provider?._id !== this.currentUser._id){
      this.hideMipsReportMeasure = true;
    }else{
      this.hideMipsReportMeasure = false;
    }
    if (this.charge && this.charge.canEdit) return false;
    if (this.note?.provider?._id !== this.currentUser._id) {
      return true;
    }else{
      this.hideMipsReportMeasure = false;
    }
    if (this.model?.status == 'submit' || this.charge?.status == "submit") {
      return true;
    }
    if (this.model.icd_id.length <= 0 || this.model.cpt_id.length <= 0) {
      return true;
    }
    else {
      return false;
    }
  }
  disableButtonForDraft() {
    if (this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER || this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT) {
      if (this.model?.status == 'submit' || this.charge?.status == "submit") {
        return true;
      }
      return false;
    }
    if (this.note?.provider?._id !== this.currentUser._id) {
      return true;
    }
    if (this.model?.status == 'submit' || this.charge?.status == "submit") {
      return true;
    }
    else {
      return false;
    }
  }
  tabChange(e) {
    this.tabIndex = e.index;
    this.showMips = e.tab.textLabel === 'Charges'
  }
  navigate() {
    this.tabIndex = 1;
  }
  cptsChange(cptsData) {
    this.model.cpt_id = cptsData.map((c) => c._id);
    clearTimeout(this.setTimeOutId);
    this.setTimeOutId = setTimeout(() => {
      if (this.buttonStates.saveAsDraft) {
        this.onChargeAndICDChange.emit(true);
      }
    }, 2000);
      this.onChargeChange.emit(this.model);
      this.cptsExist.next(true);
      this.noteEditorCptsChange.emit(cptsData);
  }
  icdsChange(data) {
    if(data.selectedIcds){
      this.model.icd_id = data.selectedIcds.map(icd=> icd._id);
      this.noteEditorIcdsChange.emit(data.selectedIcds);
    }
    if(data.woundIcds){
      this.model.wound_diagnose_icds = data.woundIcds;
    }
    this.onChargeChange.emit(this.model);
    clearTimeout(this.setTimeOutId);
    this.setTimeOutId = setTimeout(() => {
      if (this.buttonStates.saveAsDraft) {
        this.onChargeAndICDChange.emit(true);
      }
    }, 2000);
    // if (this.model.icd_id.length > 0) {
      this.icdsExist.next(true);
    // }
    // else {
    //   this.icdsExist.next(false)
    // }
  }
  modifiersChange(modifiers) {
    this.model.cpt_modifiers = [...modifiers];
    this.onChargeChange.emit(this.model);
    clearTimeout(this.setTimeOutId);
    if(this.model.cpt_id.length > 0 && this.model.icd_id.length > 0){
      this.setTimeOutId = setTimeout(() => {
        if (this.buttonStates.saveAsDraft) {
          this.onChargeAndICDChange.emit(true);
        }
      }, 2000);
    }
  }
  comments(billerNote) {
    this.model.note = billerNote;
    this.onChargeChange.emit(this.model);
  }
  mipReportMeasure(event) {
    this.model.measure = event;
    this.reportMeasure = event;
  }
  mipsInThisYear(){
    if(this.recentMips){
      let year=(new Date()).getFullYear();
      let mipYear = this.recentMips?.date_obj?.year;

      if(mipYear==year) return true;
      mipYear=(new Date(this.recentMips.createdAt)).getFullYear();

      if(mipYear==year) return true;
      else return false;
    }
    return false;
  }
  isMipReportingEnabled() {
    return this.note?.provider?.mip_reporting && !this.hideMipsReportMeasure;
  }
  async initQuestionaireResults() {
    const response:any = await lastValueFrom(this.mipsService.getQuestionnareResults(this.charge._id, this.currentUser.company_id));
    if(response.status === 200 && response.data.array.length > 0) {
      this.questionnaireResults = response.data.array;
    }
  }
  mipsExists() { 
    return Array.isArray(this.questionnaireResults) && this.questionnaireResults.length > 0;
  }
  handleRemovePhrasesfromHtmlText($event) {
      this.onPhrasesChange.emit($event);
  }
  checkDuration(element) {
    if (element) {
      if (element.status == 'draft') {
        return true;
      }
      else if (element.status == 'submit') {
        let startDate = null, editDuration = this.currentCompany.charge_edit_duration;
        if (element.first_submission_date) {
          startDate = moment(element.first_submission_date).startOf('day')
        }
        else {
          startDate = moment(element.submit_date).startOf('day');
          editDuration = 5;
        }
        let current = moment().endOf('day')
        let daysDiffer = current.diff(startDate, 'days')
        if (daysDiffer < editDuration) {
          this.charge.canEdit = true;
          this.submitButtonText = "Resubmit";
          return true;
        }
        else {
          return false;
        }
      }
    }
    else {
      return false
    }
  }
  removeDiagnosesUsingWoundDiagnosis(allICDs){
    allICDs.forEach((diagnosis)=>{
      this.noteSidebarICDs.removeDiagnose(diagnosis, true);
    });
  }
  editPatientInfoPopup() {
    const dialogRef = this.dialog.open(UpdatePatientInfoDialogComponent, {
      width: '600px',
      data: {
        patient_first_name: this.model.patient_first_name,
        patient_last_name: this.model.patient_last_name,
        patient_dob: this.model.patient_dob,
        patient_gender: this.model.patient_gender,
        title: 'Update Patient Info'
      }
    });
    dialogRef.afterClosed().subscribe(async (data) => {
      console.log(data)
      if (data) {
        this.model.patient_first_name = data.patient_first_name;
        this.model.patient_last_name = data.patient_last_name;
        this.model.patient_dob = data.patient_dob;
        this.model.patient_gender = data.patient_gender;
        if (this.currentCompany.portal_type === global.PORTAL_TYPE.NON_WC) {
          this.checkIfMultipleCPTSAllowed();
        } else {
          await this.addCharge();
          this.saveOriginalChargeState();
        }
      }
    })
  }
  removeICDsUsingModifer(modifiers) {
    this.noteSidebarCPTs.removeICDsUsingModifer(modifiers);
  }
}
