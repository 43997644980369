import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../../../includes/global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {API_URL} from "../../../../environments/api-url";

@Injectable({
  providedIn: 'root'
})
export class IpaReportService {

  currentUser: any;
  constructor(
    private authService: AuthService,
    private httpclient: HttpClient,
  ) { 
    this.currentUser = this.authService.currentUser;
  }


  getIpaReport(filter = {}){
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type, 
      filter: filter
    };
    return this.httpclient.post(global.url + API_URL.CHARGE.getIpaReport, {details: details});
  }

  downloadIpaReport(data) {
    // console.log("updated patients data", dataSource);
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');
    // console.log("updated patients data", patients)
    return this.httpclient.post(global.url + API_URL.CHARGE.downloadIpaReport, { reportData: data }, {
      responseType: "blob",
      reportProgress: true,
      observe: 'events',
      headers
    });
  }
}
