<div class="wound-item flex flex-col gap-1" [class.wound-item-amputated]="actualParentWound.state === 'amputated'"
    [class.wound-item-completed]="actualParentWound.state === 'remission'"
    *ngIf="wound && actualParentWound">
    <div class="wound-item-main flex flex-col gap-1">
        <div class="flex justify-between items-start">
            <div class="flex items-center justify-center gap-1" style="margin-right: 10px;">
                <div class="flex flex-col items-center justify-center">
                    <strong *ngIf="wound.created_by_user_type === '1'">PR</strong>
                    <strong *ngIf="wound.created_by_user_type === 'snf_wc_nurse'">NS</strong>
                    <strong *ngIf="wound.created_by_user_type === 'wound_nurse'">WN</strong>
                    <strong *ngIf="wound.created_by_user_type === global.USER_TYPE.MEDICAL_ASSISTANT">MA</strong>
                    <div class="woundNo flex items-center justify-center">
                        <strong>{{actualParentWound.woundNo}}</strong>
                    </div>
                </div>
                <div class="wound-image-wrapper flex items-center justify-center">
                    <div class="wound-image cursor-pointer" (click)="showWoundImagePreviewDialog(wound, 0, true)"
                    [style.backgroundImage]="mainImage ? 'url(' + mainImage + ')' : 'none'">
                        <div class="wound-image-caption top"><strong>{{wound.created_At}}</strong>
                        </div>
                        <div class="wound-image-caption bottom" style="font-size: x-small;font-weight: bold;">Last
                            Update: {{actualParentWound.updated_At}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="wound-details flex-grow">
                <div class="flex justify-between items-start gap-2">
                <div>
                    <div class="text cursor-pointer" [matTooltip]="!readOnlyMode ? 'Edit Details' : null"
                        (click)="!readOnlyMode && handleWoundClick($event, wound)">
                        <div>{{actualParentWound.body_side}} {{actualParentWound.direction || ''}}
                            {{actualParentWound.body_part}}</div>
                        <div>{{actualParentWound.wound_type}}</div>
                        <div>{{actualParentWound.etiolgy}}</div>
                        <div *ngIf="actualParentWound.wound_recognition_date_type">
                            {{getRecognitionString(actualParentWound.wound_recognition_date_type,actualParentWound.wound_recognition_date,actualParentWound.wound_recognition_undetermined)}}
                        </div>
                        <div>
                            {{wound.stage}}
                        </div>
                    </div>
                    <div *ngIf="currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE" class="cursor-pointer" (click)="viewScores()"><strong>Push Score: {{calculateAreaSubscore(actualParentWound)+calculateExudateAmount(actualParentWound)+calculateTissueType(actualParentWound)}}</strong>
                        
                    </div>
                </div>
                    <div class="area-volume-wrapper flex gap-2 w-2/5">
                        <div class="flex flex-col items-center justify-center area-box flex-grow"
                            [class.negative]="percentageArea > 0" [class.positive]="percentageArea < 0">
                            <div style="text-align: center;">{{time_difference}}</div>
                            <div *ngIf="percentageArea == 0" style="font-size: 30px; text-align: center;"><strong>0%</strong></div>
                            <div *ngIf="percentageArea != 0" style="font-size: 30px; text-align: center;"><strong>{{percentageArea}}%</strong></div>
                            <div style="text-align: center;">Area</div>
                        </div>
                        <div class="flex flex-col items-center justify-center area-box"
                            [class.negative]="actualVolume > 0" [class.positive]="actualVolume < 0">
                            <div style="text-align: center;"><br> </div>
                            <div style="font-size: 30px; text-align: center;">
                                <strong>{{percentageVolume}}%</strong>
                            </div>
                            <div style="text-align: center;">Volume</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="flex items-center justify-start">
            <div class="flex" >
                <button mat-icon-button class="text" (click)="toggleChildWounds()" aria-label="Show All Wounds"
                    *ngIf="hasChildWounds()" id="clickable">
                    <mat-icon *ngIf="!(expanded && expandedView === 'child_wounds')">expand_more</mat-icon>
                    <mat-icon *ngIf="expanded && expandedView === 'child_wounds'">expand_less</mat-icon>
                </button>
                <button *ngIf="!readOnlyMode && !isPreview" mat-icon-button class="text" aria-label="Add Image"
                    [matMenuTriggerFor]="addImageContextMenu">
                    <mat-icon>add_a_photo</mat-icon>
                </button>

                <mat-menu #addImageContextMenu="matMenu">
                    <button mat-menu-item (click)="showWoundImageSnapshotDialog()">Open Camera</button>
                    <button mat-menu-item (click)="showWoundImageUploadDialog()">Upload From Computer</button>
                </mat-menu>
                <button *ngIf="otherImages.length > 0 && !isPreview" mat-icon-button class="text"
                    (click)="toggleShowAllImages()" aria-label="Show All Images">
                    <mat-icon>photo_library</mat-icon>
                </button>

            </div>
            <div class="flex-grow me-auto cursor-pointer">
                <span class="wound-note" *ngIf="!isPreview" (click)="!readOnlyMode && !isPreview && showWoundNoteDialog()">
                    {{wound.extra_note || '...'}}
                </span>
                <button [ngClass]="wound.totalProcedureNotes > 0 ? 'green-background': ''" *ngIf="!isAdminSide && isNoteEditor && should_show_procedure_note" mat-raised-button class="dn-button-primary" (click)="showProcedureNoteDialog()" style="color: white;">
                    Add Procedure ({{wound.totalProcedureNotes}})
                </button>
                    
            </div>
            <div class="flex items-end justify-baseline gap-1" style="align-items: baseline;">
                    <span>
                        <a *ngIf="currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER 
                        || isTeleHealthReport 
                        ||((currentUser.admin_type == 'full_admin'|| currentUser.admin_type == 'system_admin')
                        && isAdminSide)" (click)="downloadSingleImage()" style="color: #23406D;cursor: pointer;"><mat-icon matTooltip="Download Wound Image">image</mat-icon></a><br><br>
                    </span>
                    <span *ngIf="(((currentUser.admin_type == 'full_admin'|| 
                    currentUser.admin_type == 'system_admin') && isAdminSide) || isTeleHealthReport) 
                    && showDownloadButton(actualParentWound)" style="float: right;" class="material-symbols-outlined icon-navy report-icon"
                        (click)="downloadImage(actualParentWound)" matTooltip="Download Grouped Images">
                        folder
                    </span>
                    <span *ngIf="!hasChildWounds() && (currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER && currentUser.user_type != global.USER_TYPE.WOUND_NURSE) && !isTeleHealthReport" style="float: right;" class="material-symbols-outlined icon-navy report-icon"
                        (click)="moveWoundToCensus(actualParentWound)" matTooltip="Move Wound">
                        swap_horiz
                    </span>
                    <span *ngIf="canUploadReport() && !hasChildWounds() && (currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER && currentUser.user_type != global.USER_TYPE.BUSINESS_OFFICER && currentUser.user_type != global.USER_TYPE.WOUND_NURSE) && !isTeleHealthReport" style="float: right;" class="material-symbols-outlined icon-navy report-icon" (click)="generateSingleReportandUploadtoPCC(actualParentWound)" matTooltip="Upload Wound Assessment Report to PCC">file_upload</span>
                    <span *ngIf="!hasChildWounds() && currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER" style="float: right;" class="material-symbols-outlined icon-navy report-icon" (click)="downloadParentWoundPDF(actualParentWound)" matTooltip="Download Wound Assessment Report"> file_download </span>
                    <span *ngIf="skinSubRefferal && !loader.hasRunningTask(true, childWoundsLoaderId) && !actualParentWound.child_wounds?.length" class="material-symbols-outlined" [ngClass]="{'activeColor': actualParentWound.skin_relation == true}"  style="cursor: pointer;" (click)="updatePatientSkinSubRefferal()"
                      matTooltip="Skin Sub Refferal" matTooltipPosition="above">
                      add_alert
                    </span>
                    <span *ngIf="isPreview && !isTeleHealthReport" style="float: right;" class="material-symbols-outlined icon-navy report-icon" [ngStyle]="actualParentWound?.isSelected && {'background-color': '#23406D', 'color': '#ffffff', 'display': 'flex', 'justify-content': 'center', 'width': '42px'}" (click)="showWoundReport(actualParentWound, wound.updated_At,false)" matTooltip="View Wound Assessment Report">remove_red_eye</span>
                    <i *ngIf="patient.recentSignedNotes" style="float: right;" class="icon icon-file" (click)="showNote(patient)"
                        matTooltip="View Note"></i>
                <mat-form-field class="wound-state text" *ngIf="!isPreview">
                    <mat-select [(ngModel)]="last_n_weeks" (ngModelChange)="initChildWounds(true)">
                        <mat-option value="all">All</mat-option>
                        <mat-option value="4">Last 4 Weeks</mat-option>
                        <mat-option value="2">Last 2 Weeks</mat-option>
                        <mat-option value="1">Last 1 Week</mat-option>
                    </mat-select>
                </mat-form-field>
                <button *ngIf="!isPreview" mat-icon-button class="text" (click)="handleGraphClick()" aria-label="Graph">
                    <mat-icon>bar_chart</mat-icon>
                </button>
                <button *ngIf="!isAdminSide && isNoteEditor && should_show_wound_assessment && (currentUser.user_type == global.USER_TYPE.DOCTOR || currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT)" class="dn-button-primary" style="margin-left: 7px; color: white;" mat-raised-button (click)="addFollowUpAssessment(this.wound)">Add F/U Assessment</button>
                <ngx-ui-loader [loaderId]="woundsLoaderId"></ngx-ui-loader>
            </div>
        </div>
    </div>
    <mat-expansion-panel [expanded]="expanded">
        <div class="wound-item-expansion" style="position: relative; min-height: 100px;">
            <div style="margin-left: 50px;">
                <ng-container *ngIf="expandedView === 'child_wounds'">
                    <div *ngIf="!loader.hasRunningTask(true, childWoundsLoaderId) && !actualParentWound.child_wounds?.length">
                        No wounds
                    </div>
                    <ng-container *ngIf="actualParentWound.child_wounds">
                        <mat-tab-group [(selectedIndex)]="tabIndex" (selectedTabChange)="onChildWoundTabChange($event);">
                            <mat-tab label="ACTIVE">
                                <ng-container *ngIf="tabIndex === 0">
                                    <ng-container *ngFor="let childWound of actualParentWound.child_wounds">
                                        <app-wound-care-widget-child-item *ngIf="childWound.state === 'active'" [childWoundsLoaderId]="childWoundsLoaderId"
                                            [configuration]="configuration" [companyPermissions]="companyPermissions" (WoundStatechange)="WoundStatechange($event)" (onICDsSave)="onICDsSave.emit($event)"
                                            [actualParentWound]="actualParentWound" [mainWound]="wound" [childWound]="childWound" [provider]="provider"
                                            [readOnlyMode]="readOnlyMode" [isPreview]="isPreview" [should_show_view_icon]="should_show_view_icon" [skinSubRefferal]="skinSubRefferal"
                                            (showChildWoundReport)="showChildWoundReport($event,childWound)" (refreshWounds)="handleRefreshAllWounds($event)" [patient]="patient" [note]="note"
                                            (parentWoundDetailsChange)="handleWoundDetailsChange($event)" (archiveWound)="archiveWound($event)" (procedureNoteDetailsChange)="handleProcedureNoteDetailsChange($event)">
                                        </app-wound-care-widget-child-item>
                                    </ng-container>
                                    <app-wound-care-widget-child-item [configuration]="configuration" [companyPermissions]="companyPermissions" [childWoundsLoaderId]="childWoundsLoaderId"
                                        (WoundStatechange)="WoundStatechange($event)" (onICDsSave)="onICDsSave.emit($event)"
                                        *ngIf="isWoundInDateRange(actualParentWound) && actualParentWound.child_wounds?.length"
                                        [actualParentWound]="actualParentWound" [childWound]="actualParentWound" [should_show_view_icon]="should_show_view_icon" [skinSubRefferal]="skinSubRefferal"
                                        (showChildWoundReport)="showChildWoundReport($event,actualParentWound, true)" [changeWoundStateEvent]="changeWoundState" [provider]="provider"
                                        [readOnlyMode]="readOnlyMode" [patient]="patient" [note]="note" (procedureNoteDetailsChange)="handleProcedureNoteDetailsChange($event)">
                                    </app-wound-care-widget-child-item>
                                </ng-container>
                            </mat-tab>
                            <mat-tab label="ARCHIVE">
                                <ng-container *ngIf="tabIndex === 1">
                                    <ng-container *ngFor="let childWound of actualParentWound.child_wounds">
                                        <app-wound-care-widget-child-item *ngIf="childWound.state === 'archived'" [childWoundsLoaderId]="childWoundsLoaderId"
                                            [configuration]="configuration" [companyPermissions]="companyPermissions" [mainWound]="wound" (WoundStatechange)="WoundStatechange($event)" (onICDsSave)="onICDsSave.emit($event)"
                                            [actualParentWound]="actualParentWound" [childWound]="childWound" [provider]="provider"
                                            [readOnlyMode]="readOnlyMode" [isPreview]="isPreview" [should_show_view_icon]="should_show_view_icon"
                                            (showChildWoundReport)="showChildWoundReport($event,childWound)" [patient]="patient"
                                            (parentWoundDetailsChange)="handleWoundDetailsChange($event)" (refreshWounds)="handleRefreshAllWounds($event)" (archiveWound)="archiveWound($event)">
                                        </app-wound-care-widget-child-item>
                                    </ng-container>
                                </ng-container>
                            </mat-tab>
                        </mat-tab-group>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="expandedView === 'graph'">
                    <app-wound-care-widget-item-graph [readOnlyMode]="readOnlyMode" [note]="note"
                        [parentWoundId]="actualParentWound._id">
                    </app-wound-care-widget-item-graph>
                </ng-container>

                <ng-container *ngIf="expandedView === 'wound_images'">
                    <div class="flex flex-wrap gap-1">
                        <div *ngFor="let imgUrl of otherImages; let i = index;" class="wound-image wound-image-sm"
                            [style.backgroundImage]="imgUrl ? 'url(' + imgUrl + ')' : 'none'">
                            <div class="wound-image-sm-buttons">
                                <button mat-icon-button style="color: #1f98d7;" aria-label="Delete" (click)="showWoundImagePreviewDialog(wound, i+1)">
                                    <mat-icon style="font-size: 19px;"
                                        matTooltip="View Image">
                                        remove_red_eye</mat-icon>
                                </button>
                                <button mat-icon-button style="color: red;" aria-label="Delete" (click)="deleteWoundImage(wound, i)">
                                    <mat-icon style="font-size: 19px;"
                                        matTooltip="Delete Image">delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <ngx-ui-loader [loaderId]="childWoundsLoaderId"></ngx-ui-loader>
        </div>
    </mat-expansion-panel>
</div>