import { Component, ChangeDetectorRef, Input, OnInit, Output, EventEmitter, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { set } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { ESignService } from 'src/app/components/e-sign/e-sign.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router'
import { CommunicationService } from './communication.service';
import { Subscription } from 'rxjs';
import * as global from '../../includes/global';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CommunicationComponent implements OnInit {
  mentions: any = [];
  mentions_reply: any = [];

  href: string = "";
  subscription: Subscription;
  route_url: string;
  isMentionSelected: any;
  isMentionSelected_reply: any;
  dataLoaded: boolean = false;

  constructor(
    private communicationService: CommunicationService,
    private esignService: ESignService,
    private _authService: AuthService,
    private route: ActivatedRoute,
    private toaster: ToastrService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.currentUser = this._authService.currentUser;

  }
  active: Boolean = false;
  currentUser: any;
  items: string[] = ["Noah", "Liam", "Mason", "Jacob"];
  @Output() closeSideNav = new EventEmitter();
  @ViewChild('commentSection') commentSection: ElementRef<HTMLInputElement>
  @ViewChild('repliedSection') repliedSection: ElementRef<HTMLInputElement>
  @Input() patient;
  @Input() parent_;

  comment: any = "";
  comment_reply: any = "";

  repliedCommentCount: any;
  file: any;

  main_height = "99%";
  inside_height = "100%";
  inside_height_reply = "90%";

  main_height_note = "83vh";
  inside_height_note = "83vh";
  inside_height_note_reply = "71vh";

  comments: any = [];
  repliedComments: any = [];
  updatedCommentCount: any;
  userComment: any;
  replyCommentView: boolean = false;
  isEdit: boolean = false;
  editComment: any;
  isEdit_reply: boolean = false;
  editComment_reply: any;
  providers: any;
  providersAll: any;
  parent_route: boolean = false;
  mentionConfig = {
    items: [{ last_name: "Noah", first_name: "bhai", _id: 1 }, { last_name: "bhai", first_name: "Noah", _id: 2 }, { last_name: "hi", first_name: "bro", _id: 3 }],
    triggerChar: '@',
    labelKey: 'last_name'
  }
  editCommentText = '';
  editCommentText_reply = '';
  // styleObject = {
  //   "height": !this.getParent()? '100%':'97%', 
  //   "background-color": this.comments.length > 0 ? '#d6d6d6 !important'
  // }
  async ngOnInit(): Promise<void> {
    if (this.patient) {
      //console.log("data", this.patient);

    }
    if (this.parent_) {
      this.parent_route = false;
      this.href = global.base_url + this.router.url;
      this.route_url = this.router.url;
      //console.log("this.parent_ : ", this.parent_);
      //console.log("href : ", this.href);

    } else {
      this.parent_route = true;
      this.href = global.base_url + '/patient-detail/' + this.patient?._id?.toString();
      this.route_url = '/patient-detail/' + this.patient?._id?.toString();

      //console.log("href : ", this.href);

    }
    this.isMentionSelected = false;

    this.getAllProviders();
    this.getComments();
    //    this.scrollToBottom_reply();

  }
  async getAllProviders() {
    await this.esignService.getProvidersNames(null).subscribe(async (res: any) => {
      // this.providers = [...res.data];
      this.providersAll = [...res.data];
      //  this.mentionConfig.items=[...res.data];
      //console.log("data providers : ", this.mentionConfig);
      //this.providers = ["adil", "is"];
    })
  }
  ngAfterViewInit() {
    if( this.commentSection){
      this.commentSection.nativeElement.focus();
    }
    this.changeDetectorRef.detectChanges();
  }

  sameUser(comment_object) {
    ////console.log("comment_object : ", this.currentUser._id, comment_object?.created_by?._id);
    if (this.currentUser._id == comment_object?.created_by?._id) return true; else return false;

  }

  async scrollToBottom() {

    setTimeout(() => {
      var elem = document.getElementById('mainDiv');
      //console.log("elem : ", elem)
      elem.scrollTop = elem.scrollHeight;

    }, 300);


  }
  async scrollToBottom_reply() {

    setTimeout(() => {
      var elem = document.getElementById('mainDiv_reply');
      //console.log("elem : ", elem)
      elem.scrollTop = elem.scrollHeight;

    }, 300);


  }
  getComments(scrollToDow = true) {
    this.editCommentText = '';

    this.communicationService.getCommunicationComment(this.patient?._id).subscribe(async (response: any) => {
      if (response.status === 200) {
        this.comments = await this.getMentionedCommentFormat(response.data.array);


        await this.comments.forEach(async (element) => {
          if(this.userComment){
            if(this.userComment._id == element._id){
              element['selected'] = true;
            }else{
              element['selected'] = false;
            }
          }else{
            element['selected'] = false;
          }
        });

        //console.log("thi. comments ", this.comments);
        if(scrollToDow){
          this.scrollToBottom();
        }
        this.dataLoaded = true;
        //   var elem = document.getElementById('mainDiv');
        //  elem.scrollTop = elem.scrollHeight;



        // this.repliedCommentCount = response.data.repliedCommentCount
      }
    });
  }
  getParent() {
    return this.parent_route;
  }
  async getMentionedCommentFormat(comment) {
    let comments = [];
    for (let item of comment) {
      item.comment = this.getcommentStyle(item?.comment);
      comments.push(item);
    }
    return comments;
  }

  async serchWithTerm(term) {

    if (term == '' || term == null) {
      this.providers = [];
      return;
    }
    if (term) {
      this.providers = [];
    }
    //console.log("termm : ", term);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = await this.esignService.getProvidersNames(term).subscribe(async (res: any) => {
      this.providers = [...res.data];
      this.mentionConfig.items = [...res.data];
      //console.log("data providers : ", this.mentionConfig);
      //this.providers = ["adil", "is"];
    })
  }

  async addMentions_reply(e) {
    let index = -1;
    //console.log("event : addMentions", e);
    this.isMentionSelected_reply = true;
    if (this.mentions_reply.length)
      index = this.mentions_reply.findIndex((element) => element?._id?.toString() == e?._id?.toString());

    if (index < 0) {
      this.mentions_reply.push(e);
      //console.log("ee : ", e);

    }

    setTimeout(() => {
      this.isMentionSelected_reply = false;
    }, 1100);
    this.providers = [];
    // this.getAllProviders();
  }

  async addMentions(e) {
    let index = -1;
    //console.log("event : addMentions", e);
    this.isMentionSelected = true;
    if (this.mentions.length)
      index = this.mentions.findIndex((element) => element?._id?.toString() == e?._id?.toString());

    if (index < 0) {
      this.mentions.push(e);
      //console.log("ee : ", e);

    }

    setTimeout(() => {
      this.isMentionSelected = false;
    }, 1100);
    this.providers = [];

    //  this.getAllProviders();
  }

  onEnterPress_reply(event) {
    this.comment_reply = event;

    //console.log("event : onEnterPress", event);
    if (this.isMentionSelected_reply) return;

    setTimeout(() => {

      if (!this.isEdit_reply) {
        this.addComment_reply();
      } else {
        this.updateCommunicationComment_reply();
      }
      this.comment_reply = "";
      (<HTMLInputElement>document.getElementById('repliedSection_input')).value = "";
    }, 600)
  }


  onSaveButton_reply() {
    let value=(<HTMLInputElement>document.getElementById('repliedSection_input')).value;

    this.comment_reply = value;

    //console.log("event : onSaveButtonreply", value,this.comment_reply);
    // if (this.isMentionSelected_reply) return;

      if (!this.isEdit_reply) {
        this.addComment_reply();
      } else {
        this.updateCommunicationComment_reply();
      }
      this.comment_reply = "";
      (<HTMLInputElement>document.getElementById('repliedSection_input')).value = "";
  }

  onSaveButton(){
    let value=(<HTMLInputElement>document.getElementById('commentSection_input')).value;
    this.comment = value;

    //console.log("event : onSaveButton", this.comment, value);
    // return;
    // if (this.isMentionSelected) return;

      if (!this.isEdit) {
        this.addComment();
      } else {
        this.updateCommunicationComment();
      }
      this.comment = "";
      (<HTMLInputElement>document.getElementById('commentSection_input')).value = "";
    

  }

  onEnterPress(event) {
    return;
    this.comment = event;

    //console.log("event : onEnterPress", event);
    if (this.isMentionSelected) return;

    setTimeout(() => {

      if (!this.isEdit) {
        this.addComment();
      } else {
        this.updateCommunicationComment();
      }
      this.comment = "";
      (<HTMLInputElement>document.getElementById('commentSection_input')).value = "";
    }, 600)
  }

  format(item: any) {
    return `<span >${item['first_name'] + ' ' + item['last_name']}</span>`;
  }

  onSelectFile(e) {
    this.file = e.target.files[0];
  }

  clear() {
    this.file = undefined;
  }

  getRepliedComments(parentCommentId) {
    this.editCommentText_reply = '';

    this.communicationService.getCommunicationComment(this.patient?._id, parentCommentId).subscribe(async (response: any) => {
      // //console.log("sjdaklfjl",response);
      if (response.status === 200) {
        this.repliedComments = await this.getMentionedCommentFormat(response.data.array);

      }
    });
  }

  removeCommentStyle(comment) {
    if (!comment) return '';
    let matching = ['<span ', 'class=', '\"mark\">', '"mark">', '<\/span>', '</span>'];
    let result = comment;


    for (let item of matching) {
      //console.log("matching item: ", item);

      result = result.replaceAll(item, '');

    }
    //console.log("final result : ", result);

    result = result.replaceAll('<br>', '\n');
    return result

  }

  getcommentStyle(comment) {
    // //console.log("comment : commeTooooo", comment);
    if (!comment) return '';
    let matching = comment.match(new RegExp(/@\w+/gm));
    let result = comment;

    if (matching?.length) {
      matching = matching.filter((c, index) => {
        return matching.indexOf(c) === index;
      });

      ////console.log("matching : ", matching);

      for (let item of matching) {
        // var tag = new RegExp(item, "g");
        var tag = item;

        let color = `<span class="mark">${item}</span>`;

        //let color=`<mark>${item}</mark>`;


        result = result.replaceAll(tag, color);


      }
      // //console.log("item, tag : ", result);
    }
    result = result.replaceAll('\n', '<br>');

    return result
  }

  async checkMentions(comment_string, mentions_array) {
    let finalTagArray = [];
    if (comment_string) {
      ///   split   include    secondName[0]   // remove duplicates
      let matching = comment_string.match(new RegExp(/@\w+/gm));

      //console.log("matching : ", matching, mentions_array);
      if (!matching) return [];
      if (!mentions_array.length) return [];

      for (let item of matching) {
        let tag = item.slice(1);
        let index = this.providersAll.findIndex((element) => element.last_name?.toLowerCase().includes(tag));
        //console.log("indexxx : ", index);
        if (index >= 0) {
          const providerFound = this.providersAll[index];
          let index_2 = -1;
          //console.log("providerFound : ", providerFound);

          if (finalTagArray.length) {
            index_2 = finalTagArray.findIndex((element) => element?._id.toString() == providerFound._id.toString());
          }
          //console.log("ind 2", index_2);
          if (index_2 < 0) {

            let index_3 = mentions_array.findIndex((element: any) => element._id.toString() == providerFound._id.toString());

            //console.log("ind 3", index_3);

            if (index_3 >= 0) {

              finalTagArray.push(providerFound);
            }
          }

        }
      }
      //console.log("array comparisons : ", mentions_array, finalTagArray);

    }
    return finalTagArray;
  }
  async checkMentions_reverse(comment_string, mentions_array) {
    let finalTagArray = [];
    if (comment_string) {

      let matching = comment_string.match(new RegExp(/@\w+/gm));

      //console.log("matching : ", matching, mentions_array);
      if (!matching) return [];
      if (!mentions_array.length) return [];


      for (let mention_object of mentions_array) {
        let index_2 = -1;

        let index = matching.findIndex((element: any) => element?.slice(1)?.toLowerCase() == mention_object.first_name?.split(' ')[0]?.toLowerCase());

        if (index >= 0) {
          if (finalTagArray.length) {
            index_2 = finalTagArray.findIndex((element) => element?._id.toString() == mention_object._id?.toString());
          }

          if (index_2 < 0) {

            finalTagArray.push(mention_object);

          }

        }
        //console.log("providerFound : index", index);


      }
      //console.log("array comparisons : ", mentions_array, finalTagArray);

    }
    return finalTagArray;
  }



  async addComment() {
    if (this.comment !== "" || this.file !== undefined) {
      let details: any = {};

      details.patient_id = this.patient._id;
      details.patient_name = this.patient.first_name + ' ' + this.patient.last_name;
      details.patient_facility = this.patient.facility_id_ref?.title;
      details.creator_name = this.currentUser.first_name + ' ' + this.currentUser.last_name;
      details.link = this.href;
      details.route_link = this.route_url;

      details.comment = this.comment;
      details.created_by = this.currentUser._id;

      //this.mentions=await this.checkMentions(this.comment,this.mentions );
      this.mentions = await this.checkMentions_reverse(this.comment, this.mentions);


      if (this.mentions.length) {
        details.mentions = this.mentions;
      }


      this.communicationService.addCommunicationComment(details).subscribe((response: any) => {
        // //console.log(response);
        this.isMentionSelected = false;

        if (response.status === 200) {
          this.comment = "";
          this.file = undefined;
          this.mentions = [];
        }
        // this.getComments();
        //console.log("res", response.data);
        if (response.data?.parentCommentId) {
          this.repliedComments.push(response.data);
          this.getRepliedComments(response.data.parentCommentId);
          this.getComments();
        }
        else {

          this.getComments();
        }
      });
    }
  }

  async addComment_reply() {
    if (this.comment_reply !== "" || this.file !== undefined) {
      let details: any = {};

      details.patient_id = this.patient._id;
      details.patient_name = this.patient.first_name + ' ' + this.patient.last_name;
      details.patient_facility = this.patient.facility_id_ref?.title;
      details.creator_name = this.currentUser.first_name + ' ' + this.currentUser.last_name;
      details.link = this.href;
      details.route_link = this.route_url;

      details.comment = this.comment_reply;
      details.created_by = this.currentUser._id;

      //this.mentions=await this.checkMentions(this.comment,this.mentions );
      this.mentions_reply = await this.checkMentions_reverse(this.comment_reply, this.mentions_reply);


      if (this.mentions_reply.length) {
        details.mentions = this.mentions_reply;
      }
      if (this.userComment) {
        details.parentCommentId = this.userComment._id;
      }

      this.communicationService.addCommunicationComment(details).subscribe((response: any) => {
        // //console.log(response);
        this.isMentionSelected_reply = false;

        if (response.status === 200) {
          this.comment_reply = "";
          this.file = undefined;
          this.mentions_reply = [];
        }
        // this.getComments();
        //console.log("res", response.data);
        this.repliedComments.push(response.data);
        this.getRepliedComments(response.data.parentCommentId);
        this.getComments(false);
      });
    }
  }

  deleteCommunicationComment_reply(commentId, index) {
    // //console.log(commentId);
    this.communicationService.deleteCommunicationComment(commentId).subscribe((response: any) => {
      if (response.status === 200) {
        // //console.log(response.data);
        this.toaster.success("Comment deleted successfully");
        let ind = this.comments.findIndex(com=> com._id === this.userComment._id);
        this.comments[ind].count = this.comments[ind].count - 1; 
        this.repliedComments.splice(index, 1);
      }
    });
  }

  deleteCommunicationComment(commentId, index) {
    // //console.log(commentId);
    this.communicationService.deleteCommunicationComment(commentId).subscribe((response: any) => {
      if (response.status === 200) {
        // //console.log(response.data);
        this.toaster.success("Comment deleted successfully");

        this.comments.splice(index, 1);
      }
    });
  }

  async editCommunicationComment(comment) {

    this.comment = await this.removeCommentStyle(comment?.comment);
    this.editComment = comment;
    this.editCommentText = this.comment;
    this.mentions = [];
    for (let item of comment.mentions) {

      let index = this.providersAll.findIndex((element) => element.id?.toString == item?.toString());
      if (index >= 0)
        this.mentions.push(this.providersAll[index])

    }
    this.isEdit = true;

  }

  async editCommunicationComment_reply(comment) {

    this.comment_reply = await this.removeCommentStyle(comment?.comment);
    this.editComment_reply = comment;
    this.editCommentText_reply = this.comment_reply;
    this.mentions_reply = [];
    for (let item of comment.mentions) {

      let index = this.providersAll.findIndex((element) => element.id?.toString == item?.toString());
      if (index >= 0)
        this.mentions_reply.push(this.providersAll[index])

    }
    this.isEdit_reply = true;

  }
  async updateCommunicationComment() {
    //console.log("comm", this.editComment);
    this.editComment.comment = this.comment;
    this.mentions = await this.checkMentions_reverse(this.comment, this.mentions);
    if (this.mentions.length) {
      this.editComment.mentions = this.mentions;
    }

    this.editComment.patient_name = this.patient.first_name + ' ' + this.patient.last_name;
    this.editComment.patient_facility = this.patient.facility_id_ref?.title;
    this.editComment.creator_name = this.currentUser.first_name + ' ' + this.currentUser.last_name;
    this.editComment.link = this.href;
    this.editComment.route_link = this.route_url;
    this.editCommentText = "";

    this.communicationService.editCommunicationComment(this.editComment, this.mentions).subscribe((response: any) => {
      if (response.status === 200) {

        this.getComments();

        this.comment = "";
        this.editCommentText = '';

        this.mentions = [];
        this.isEdit = false;
      }
    });
  }



  async updateCommunicationComment_reply(status?) {
    //console.log("comm", this.editComment);
    this.editComment_reply.comment = this.comment_reply;
    this.mentions_reply = await this.checkMentions_reverse(this.comment_reply, this.mentions_reply);
    if (this.mentions_reply.length) {
      this.editComment_reply.mentions = this.mentions_reply;
    }

    this.editComment_reply.patient_name = this.patient.first_name + ' ' + this.patient.last_name;
    this.editComment_reply.patient_facility = this.patient.facility_id_ref?.title;
    this.editComment_reply.creator_name = this.currentUser.first_name + ' ' + this.currentUser.last_name;
    this.editComment_reply.link = this.href;
    this.editComment_reply.route_link = this.route_url;
    this.editCommentText_reply = "";

    this.communicationService.editCommunicationComment(this.editComment_reply, this.mentions_reply).subscribe((response: any) => {
      if (response.status === 200) {
        // //console.log(response.data);

        this.getRepliedComments(this.editComment_reply?._id);

        this.comment_reply = "";
        this.editCommentText_reply = "";

        this.mentions_reply = [];
        this.isEdit_reply = false;
      }
    });
  }


  async replyToComment(usercomment) {
    this.repliedComments = [];
    if (!usercomment.selected) {
      this.comments.forEach(element => {
        element.selected = false;
      });
      this.comment_reply = "";
      this.isEdit_reply = false;
      await this.getRepliedComments(usercomment._id);
      this.userComment = usercomment;
      this.replyCommentView = true;
      //console.log(usercomment);
      usercomment.selected = true;
      //console.log(this.repliedSection)
      //if (this.repliedSection)
        //this.repliedSection.nativeElement.focus();
    } else {
      usercomment.selected = false;
      this.replyCommentView = false;
      this.userComment = "";
    }
  }
  addReplyComment(value) {
    //console.log(value)
  }
  getCommentsLength() {
    return this.comments.length > 0;
  }
  clearReply(){
    this.editComment_reply = null;
    this.editCommentText_reply = "";
    this.isEdit_reply = false;
  }
  clearComment(){
    this.editComment = null;
    this.editCommentText = "";
    this.isEdit = false;
  }
}
