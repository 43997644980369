import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as global from '../global';
import { API_URL } from "../../../environments/api-url";



@Injectable({
    providedIn: 'root',
})
export class ResetPasswordService {

    public constructor(private httpclient: HttpClient) {

    }

    updatePassword(details) {
        return this.httpclient
            .post(global.url + API_URL.AUTH.updatePassword, { details: details }).pipe();
    }
    updateCognitoPassword(details) {
        return this.httpclient.post(global.url + API_URL.AUTH.changeCognitoPassword,
            { details: details }).pipe();
    }
    checkCognitoStatus(details) {
        
        return this.httpclient.post(global.url + API_URL.AUTH.getCognitoStatus, { details: details }).pipe();
    }
}
