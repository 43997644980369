<div class="flex gap-4 justify-start items-center">
    <mat-form-field>
        <input matInput [matDatepicker]="dospicker" [max]="currentDate"
               (focus)="dospicker.open()"
               placeholder="Date of Service"
               [(ngModel)]="filters.dos">
        <mat-datepicker-toggle matSuffix [for]="dospicker"></mat-datepicker-toggle>
        <mat-datepicker #dospicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="flex-grow"
                    *ngIf="(currentUser.user_type == global.USER_TYPE.TRANSCRIBER  && category !== 'patient') || (currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT  && category !== 'patient')">
        <mat-label>Provider</mat-label>
        <input matInput type="text" [(ngModel)]="filters.provider"
               [formControl]="providerControl"
               [matAutocomplete]="providerAutoComplete">
        <mat-autocomplete #providerAutoComplete="matAutocomplete"
                          [displayWith]="displayProviderFn">
            <mat-option
                    *ngFor="let provider of filteredProviders | async" [value]="provider">
                {{provider.name}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="flex-grow" *ngIf="category !== 'dict'">
        <mat-label>Patient</mat-label>
        <input matInput type="text"
               [(ngModel)]="filters.patient"
               [formControl]="patientControl"
               [matAutocomplete]="patientAutoComplete">
        <mat-autocomplete #patientAutoComplete="matAutocomplete"
                          [displayWith]="displayPatientFn">
            <mat-option
                    *ngFor="let patient of filteredPatients | async" [value]="patient">
                <app-patient-name [patient]="patient"></app-patient-name>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Select Facility</mat-label>
        <mat-select [(ngModel)]="filters.facility">
            <mat-option *ngFor="let facility of facilities"
                        [value]="facility._id" placeholder="Facility">
                <app-facility-title [facility]="facility"></app-facility-title>
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="category !== 'tran' && currentUser.user_type == global.USER_TYPE.TRANSCRIBER  && category !== 'patient'">
        <mat-label>Progress Status</mat-label>
        <mat-select [(ngModel)]="filters.progress_status" placeholder="Progress Status">
            <mat-option value="19">
                In Progress
            </mat-option>
            <mat-option value="20">
                Completed
            </mat-option>
        </mat-select>
    </mat-form-field>
    <button mat-raised-button class="dn-button-secondary"
            (click)="resetFilters()">
        Reset
    </button>
    <button mat-raised-button class="dn-button-primary"
            (click)="handleSearch()">
        Search
    </button>
</div>
