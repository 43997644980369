import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {AddAppointmentComponent, AddAttributeComponent, AddPayorComponent} from '../..';
import {FacilityService} from '../../../../services/facility.service';
import {AppointmentDataService} from '../../service/data.service';
import {ToastrService} from 'ngx-toastr';
import {PatientListService} from '../../../../includes/patient-list/patient-list-service';
import {AuthService} from '../../../../services/auth.service';
import {TranscriptionCreateService} from '../../../../includes/transcription-create/transcription-create.service';
import { connect } from 'twilio-video';
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'app-transcriber-appointment-filter',
    templateUrl: './filter.template.html'
})
export class TranscriberFilterComponent implements OnInit {
    providers = [];
    facilities = [];
    patients = [];
    payors = [];
    filters = {
        facility_id: undefined,
        patient_id: undefined,
        payor_id: undefined,
        provider_id: undefined
    };
    selectedProvider = undefined;
    selectedFacility = undefined;

    calenderSelectedValue: Date = new Date();

    @Output() dateSelection = new EventEmitter();
    constructor(private dialog: MatDialog,
                private facilityService: FacilityService,
                private service: AppointmentDataService,
                private toastr: ToastrService,
                private authService: AuthService,
                private transcriptionService: TranscriptionCreateService,
                private patientService: PatientListService) {
    }

    facilityChange(facility_id) {
        
        this.filters.facility_id = facility_id.value;
        this.service.setFilter(this.filters);
    }

    patientChange(patient_id) {
        
        if (patient_id.value === 'All') {
            this.filters.patient_id = undefined;
        } else {
            this.filters.patient_id = patient_id.value;
        }
        this.service.setFilter(this.filters);
    }

    payorChange(payor_id) {
        
        if (payor_id.value === 'All') {
            this.filters.payor_id = undefined;
        } else {
            this.filters.payor_id = payor_id.value;
        }
        this.service.setFilter(this.filters);
    }

    joinRoom() {
        this.service.getTwilioToken({}).subscribe( data => {
            //
            connect(data.data, {name: 'DailyMeeting'}).then((room) => {
                //
                room.on('participantConnected', participant => {
                    //
                    participant.tracks.forEach(publication => {
                        if (publication.isSubscribed) {
                            const track:any = publication.track;
                            document.getElementById('remote-video').appendChild(track.attach());
                        }
                    });
                    participant.on('trackSubscribed', (track:any) => {
                        document.getElementById('remote-video').appendChild(track.attach());
                    });

                })
            }).catch((error)  => {
                //
            });
        }, error => {
            //
        });
    }

    async ngOnInit() {
        try {
            await this.getDoctors();
            this.loadData();
        } catch (e) {
            this.toastr.error('something went wrong in filters ', 'Error');
        }
    }
    loadData() {
        this.facilityService.getMyFacilities(this.filters.provider_id).subscribe(data => {
            // @ts-ignore
            this.facilities = data.data.array;
            this.selectedFacility = this.facilities[0]._id;
            this.filters.facility_id = this.facilities[0]._id;
        });
        this.service.getPayors().subscribe(data => {
            this.payors = data.data.array;
        }, error => {
        });
        this.service.getProviderPatients({provider_id: this.filters.provider_id}).subscribe(data => {
            
            // @ts-ignore
            this.patients = data.data.array;
        }, error => {
        });
    }

    getDoctors() {
        return new Promise((resolve, reject) => {
            this.transcriptionService.getAssociatedDoctorsList(this.authService.currentUser.company_id).subscribe(data => {
                
                // @ts-ignore
                this.providers = data.data;
                this.selectedProvider = this.providers[0]._id;
                this.filters.provider_id = this.providers[0]._id;
                resolve(null);
            }, error => {
                reject();
            });
        });
    }
}
