<div [ngClass]="{'container': (companySide || isUserDashboard) && !(nursingSide)}">

    <h3 class="dark-blue-heading flex justify-between items-center">
        <ng-container *ngIf="!isUserDashboard && !nursingSide">
            <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
                Back
            </button>
        </ng-container>

        <div [ngClass]="{'label-margin': !isUserDashboard}">
            <label style="white-space: nowrap;">
                Wound Exceptions Report
            </label>
        </div>

        <div class="flex justify-center items-end" *ngIf="isUserDashboard">
            <input type="text" ngxDaterangepickerMd [(ngModel)]="filter.updationDate"
                    (datesUpdated)="changeUpdationDate($event)" class="mat-like-input" [ranges]="preDateRanges"
                    [alwaysShowCalendars]="true" [maxDate]="currentDate" [linkedCalendars]="true" />
        </div>  

        <div class="flex gap-2">
            <mat-icon style="cursor: pointer;" (click)="exportWoundExceptionReportAsXLSX();" matTooltip="Export As XLSX"
                matTooltipPosition="above"> download</mat-icon>
        </div>
    </h3>

    <div class="search-block theme-form filter-bar flex justify-start items-center"
        style="background-color: white; border-radius: 4px; padding-top: 8px; padding-bottom: 5px; padding-left: 8px;padding-right: 8px;">

        <div class="flex flex-grow justify-start items-center gap-2">
            <mat-form-field appearance="fill" class="date-width">
                <mat-label>Select Date Type</mat-label>
                <mat-select (selectionChange)="filter.dateType = $event.value;" [value]="filter.dateType">
                    <mat-option value="updatedAt">Updated On</mat-option>
                    <mat-option value="last_assessment_date_obj">Date Of Service</mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-raised-button class="dn-button-primary btn-width" (click)="handleApplyFilter();">
                Apply
            </button>

            <button mat-raised-button class="dn-button-secondary btn-width" (click)="resetFilter();">
                Reset
            </button>
        </div>
    </div>

    <mat-card class="vertical-height" *ngIf="!isSummaryReport">
        <div class="table-height">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                <ng-container matColumnDef="Facility">
                    <th mat-header-cell *matHeaderCellDef> Facility </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.title}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Active Census">
                    <th mat-header-cell *matHeaderCellDef> Active Census </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.patientCount}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Wound Patients">
                    <th mat-header-cell *matHeaderCellDef> Wound Patients </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.woundPatientCount}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Wounds">
                    <th mat-header-cell *matHeaderCellDef> Wounds </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.woundCount}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Wounds Not Assessed">
                    <th mat-header-cell *matHeaderCellDef> Wounds Not Assessed </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count.notAssesssedCount}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="New FA">
                    <th mat-header-cell *matHeaderCellDef> New FA </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count.facilityAcquiredCount}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="New FA Stage 1">
                    <th mat-header-cell *matHeaderCellDef> New FA Stage 1 </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count["facilityAcquired&Stage1Count"]}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="New FA Stage 2">
                    <th mat-header-cell *matHeaderCellDef> New FA Stage 2 </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count["facilityAcquired&Stage2Count"]}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="New FA Stage 3">
                    <th mat-header-cell *matHeaderCellDef> New FA Stage 3 </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count["facilityAcquired&Stage3Count"]}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="New FA Stage 4">
                    <th mat-header-cell *matHeaderCellDef> New FA Stage 4 </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count["facilityAcquired&Stage4Count"]}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="New FA Unstageable">
                    <th mat-header-cell *matHeaderCellDef> New FA Unstageable </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count["facilityAcquired&UnstageableCount"]}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="New FA DTPI">
                    <th mat-header-cell *matHeaderCellDef> New FA DTPI </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count["facilityAcquired&DTPICount"]}}
                    </td>
                </ng-container>


                <ng-container matColumnDef="New FA MMI">
                    <th mat-header-cell *matHeaderCellDef> New FA MMI </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count["facilityAcquired&MMICount"]}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="New POA">
                    <th mat-header-cell *matHeaderCellDef> New POA </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count.presentOnAdmissionCount}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="New POA Stage 1">
                    <th mat-header-cell *matHeaderCellDef> New POA Stage 1 </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count["presentOnAdmission&Stage1Count"]}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="New POA Stage 2">
                    <th mat-header-cell *matHeaderCellDef> New POA Stage 2 </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count["presentOnAdmission&Stage2Count"]}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="New POA Stage 3">
                    <th mat-header-cell *matHeaderCellDef> New POA Stage 3 </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count["presentOnAdmission&Stage3Count"]}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="New POA Stage 4">
                    <th mat-header-cell *matHeaderCellDef> New POA Stage 4 </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count["presentOnAdmission&Stage4Count"]}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="New POA Unstageable">
                    <th mat-header-cell *matHeaderCellDef> New POA Unstageable </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count["presentOnAdmission&UnstageableCount"]}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="New POA DTPI">
                    <th mat-header-cell *matHeaderCellDef> New POA DTPI </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count["presentOnAdmission&DTPICount"]}}
                    </td>
                </ng-container>


                <ng-container matColumnDef="New POA MMI">
                    <th mat-header-cell *matHeaderCellDef> New POA MMI </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count["presentOnAdmission&MMICount"]}}
                    </td>
                </ng-container>


                <ng-container matColumnDef="Worsening Stage FA">
                    <th mat-header-cell *matHeaderCellDef> Worsening Stage FA </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count.worseningStageFACount}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Worsening Stage POA">
                    <th mat-header-cell *matHeaderCellDef> Worsening Stage POA </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count.worseningStagePOACount}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Worsening Push FA">
                    <th mat-header-cell *matHeaderCellDef> Worsening Push FA </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count.worseningPushFACount}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Worsening Push POA">
                    <th mat-header-cell *matHeaderCellDef> Worsening Push POA </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count.worseningPushPOACount}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Push Unchanged">
                    <th mat-header-cell *matHeaderCellDef> Push Unchanged </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count.pushUnchangedCount}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Push Improving">
                    <th mat-header-cell *matHeaderCellDef> Push Improving </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.count.pushImprovingCount}}
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div class="flex justify-end items-center gap-1 bottom-row">
                <button mat-raised-button class="dn-button-primary" (click)="goToPreviousPage()"
                    [disabled]="!pagination.hasPrevPage"> Prev</button>

                <span>
                    {{pagination.skip + report.length === 0 ? 0 : pagination.skip + 1 }} - {{pagination.skip +
                    report.length}} out of {{pagination.totalRecords}}
                </span>
                <button mat-raised-button class="dn-button-primary" (click)="goToNextPage()"
                    [disabled]="!pagination.hasNextPage">Next</button>
            </div>
        </div>
        <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
    </mat-card>
</div>