<ng-container *ngIf="view === ''">    
    <table mat-table [dataSource]="dataSource" matSort>
    
        <ng-container matColumnDef="mdsQuestionKey">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                mdsQuestionKey
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.mdsQuestionKey}}
            </td>
        </ng-container>
    
        <ng-container matColumnDef="questionDescription">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                questionDescription
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.questionDescription}}
            </td>
        </ng-container>
        
        <ng-container matColumnDef="response">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                response
            </th>
            <td mat-cell *matCellDef="let element">
                <a href="#" (click)="$event.preventDefault(); changeView('activities-of-daily-living-response', element.response)">View</a>
            </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <br />
    <div *ngIf="apiData" class="flex gap-2 items-center">
        <button mat-raised-button class="dn-primary-button" [disabled]="apiData.paging.page === 1"
            (click)="navigate(-1)">Prev</button>
        <span style="font-size: 12px;">Page {{apiData.paging.page}}</span>
        <button mat-raised-button class="dn-primary-button" [disabled]="!apiData.paging.hasMore"
            (click)="navigate(1)">Next</button>
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</ng-container>

<ng-container *ngIf="view === 'activities-of-daily-living-response'">    
        <button mat-raised-button class="dn-button-secondary" (click)="changeView('', null)">Go Back to Activities Of Daily Living</button>
        <app-activities-of-daily-living-response [data]="viewData"></app-activities-of-daily-living-response>
</ng-container>