import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { API_URL } from "../../../environments/api-url";
import * as global from '../../includes/global';
import { ElasticSeachService } from '../elasticSearch.service';
import moment from 'moment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IcdCodesService {

  constructor(private _httpclient: HttpClient, private _elasticSearch: ElasticSeachService) { }

  fetchAllICDcodes(payload: any, isShowProvAssocIcds = false, provider_ids_ref?, company_id?){
    return this._elasticSearch.searchICDCode(payload, isShowProvAssocIcds, provider_ids_ref, company_id);
  }

  searchICDcode(searchKey: any, size: any, from: any, isShowProvAssocIcds = false, provider_ids_ref?, company_id?) {
    return this._elasticSearch.searchICDCodewithPagination(searchKey, size, from);
  }

updateICD( icdItem: any){
  return this._httpclient.post(global.url + API_URL.ICD.updateIcd, icdItem)
}

addIcdCode (icdItem: any){
  return this._httpclient.post(global.url + API_URL.CHARGE.addICD, {details: icdItem});
}

createIcdProviderAssociation (details: any){
  return this._httpclient.post(global.url + API_URL.ICD.icdProviderAssociation, details)
}

removeIcdProviderAssociation (details: any){
  return this._httpclient.post(global.url + API_URL.ICD.removeIcdProviderAssociation, details)
}

getSuperAdminICDcodes(payload: any, provider_ids_ref, company_id, shouldShowActiveIcds = true){
  return this._httpclient.post(global.url + API_URL.ICD.getSuperAdminAssociatedIcds, { payload, provider_ids_ref, company_id }).pipe(
    map((response: any) => {
      if (response.status === 200) {
        if (response.data) {
          response.data.map(icd => {
            if (!icd.end_date)
              icd.end_date = "";
            const [month, day, year] = icd.end_date.split("/");
            if (icd.end_date != "" && moment(`${year}-${month}-${day}`).isBefore(moment()))
              icd.status = 'Expired';
            else
              icd.status = 'Active';

            return icd;
          });
          if (shouldShowActiveIcds)
            response.data  = response.data.filter(icd => icd.status == 'Active')
          if (payload != "")
            response.data = response.data.filter(icd => icd.code.toLowerCase().includes(payload.toLowerCase()) || icd.description.toLowerCase().includes(payload.toLowerCase()) || icd.mediumDescription.toLowerCase().includes(payload.toLowerCase()))
          return response;        
        }
      }
    })
  );
}

  fetchProviderAssociatedIcds(filter: any, projection: any, shouldShowActiveIcds = true) {
    return this._httpclient.post(global.url + API_URL.ICD.findProviderAssociatedIcds, { filter: filter, projection: projection }).pipe(
      map((response: any) => {
        if (response.status === 200) {
          if (response.data) {
            response.data.map(icd => {
              if (!icd.end_date)
                icd.end_date = "";
              const [month, day, year] = icd.end_date.split("/");
              if (icd.end_date != "" && moment(`${year}-${month}-${day}`).isBefore(moment()))
                icd.status = 'Expired';
              else
                icd.status = 'Active';

              return icd;
            });
            if (shouldShowActiveIcds)
              response.data = response.data.filter(icd => icd.status == 'Active')
            return response;
          }
        }
      })
    );
  }

}
