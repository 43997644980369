import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Toast, ToastrService } from 'ngx-toastr';
import { firstValueFrom, forkJoin, zip } from 'rxjs';
import { CensusPatientListService } from 'src/app/includes/census-patient-list/census-patient-list.service';
import { PatientRoundingSheetService } from 'src/app/includes/patient-rounding-sheets/patient-rounding-sheet.service';
import { AuthService } from 'src/app/services/auth.service';
import { MipsService } from 'src/app/services/Modules/mips.service';

@Component({
  selector: 'app-sidebar-mips',
  templateUrl: './sidebar-mips.component.html',
  styleUrls: ['./sidebar-mips.component.css'],
})
export class SidebarMipsComponent implements OnInit, OnDestroy {
  @Input() note:any;
  @Input() charge:any;
  @Input() showMipsToaster:any;
  @Output() availableMipsEvent = new EventEmitter<any>();
  @Output() denominatorCalulationInProgress = new EventEmitter<boolean>();
  @Output() showMipsToasterOnce = new EventEmitter<boolean>();
  @Output() getRecentMipsEvent = new EventEmitter<boolean>();

  currentUser: any;
  icds: any[];
  cpts: any[];
  cpt_modifiers: any[];
  cpt_pos: any[];
  reportMeasure: boolean;

  measures:any[] = [];

  selectedMeasure;
  measureAnswered : any[] = [];
  hideMips:boolean = false;
  subscriptions:any[] = [];
  recentMips: any;
  draftMips: any;
  submittedMips: any[]=[];
  allRecentMips: any[]=[];
  showMipsDialog: boolean = false;
  mipsLoaded: boolean = false;
  submitClicked: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private authService: AuthService,
    private censusPatientListService: CensusPatientListService,
    private mipsService:MipsService,
    private service: PatientRoundingSheetService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.currentUser = this.authService.currentUser;
  }
  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }


  refreshMeasuresonInit(){
    this.ngOnInit();
  }

  async ngOnInit(): Promise<void> {
    await this.getrecentMip();
    this.selectedMeasure = null;

    console.log("this.charge : ", this.charge);

    if(this.charge ){
      this.reportMeasure = this.charge.measure || false;

      this.icds = Array.isArray(this.charge.icd_id)? this.charge.icd_id : [];
      this.cpts = Array.isArray(this.charge.cpt_id)? this.charge.cpt_id : [];
      this.cpt_modifiers = Array.isArray(this.charge.cpt_modifiers)? this.charge.cpt_modifiers : [];
      this.cpt_pos = Array.isArray(this.charge.cpt_pos) ? this.charge.cpt_pos : [];
      // console.log("this.charge : ", this.charge);
      this.initMeasures();
    }
    else{
      this.subscriptions.push(zip(
        this.censusPatientListService.castReportMeasure,
        this.censusPatientListService.castIcdsForCharge,
        this.censusPatientListService.castCptsForCharge,
        this.censusPatientListService.castCptModifiersForCharge,
      ).subscribe((response) => {
        const [report, icds, cpts, cpt_modifiers] = response;
  
        this.reportMeasure = report || false;
  
        this.icds = icds || [];
        this.cpts = cpts || [];
        this.cpt_modifiers = cpt_modifiers || [];
       this.initMeasures();
      }));
    }

  }

  getMipsValueForButton(){
    let resp=true;
    this.mipsService.castQuestionnaireResults.subscribe(res=>{
      // console.log("questionnaireResults : ",!(Array.isArray(res) && res.length >0));
      if (Array.isArray(res) && res.length >0) {
        resp= false;
      } 
      else 
        resp= true;
    });
    return resp;
  }

  async saveMipsasDraft(){
    const questionnaireResults = await firstValueFrom(this.mipsService.castQuestionnaireResults);
    // console.log("questionnaireResults : ",questionnaireResults);

    if (Array.isArray(questionnaireResults) && questionnaireResults.length > 0&&this.charge) {
      this.mipsService.draftQuestionnareResults(questionnaireResults.map(r => ({ measure_id: r.measure._id, answer: r.answer, matched: r.measure.matched, reasons:r.reasons, chads_scrore:r.chads_scrore, question_id:r.question_id })), this.charge.charge_id||this.charge._id, this.currentUser.company_id)
        .toPromise();
        this.showMipsDialog = false;
        this.toastr.info('MIPS saved as draft');
      // this.mipsService.setQuestionnairesResult([]);
    }
  }

  initMeasures() {
    const recentMipthisYear=this.mipsInThisYear();
    this.mipsLoaded = false;
    console.log("recentMipthisYear : ", recentMipthisYear);

    this.denominatorCalulationInProgress.emit(true);
    this.mipsService.calculateDenominator(this.note.patient._id, this.cpts, this.icds, this.note.dates.service, this.note.provider._id, this.currentUser.company_id, this.charge.charge_id)
    .subscribe(async (response:any) => {
    this.denominatorCalulationInProgress.emit(false);


      if(response.status === 200) {
        this.availableMipsEvent.emit([]);
        this.mipsService.setQuestionnairesResult([]);

        this.measures = response.data.array;

        if(recentMipthisYear) {
          let measureIds = this.recentMips.recentMip.flatMap((item:any) => item.measure_id.map((measure:any) => (measure._id).toString()));

          let _idForMip130 = this.recentMips.recentMip.flatMap((item:any) => item.measure_id.filter((measure:any) => measure.quality_number=='130'));
          if(_idForMip130.length){
            const chargeID = this.recentMips.recentMip.filter((item:any) => item.measure_id[0]?._id?.toString()==_idForMip130[0]._id?.toString());

            if(chargeID[0].charge_id?.toString() != (this.charge.charge_id||this.charge._id)?.toString()){
              _idForMip130 = _idForMip130[0]._id;
            }else{
              _idForMip130 = '';
            }
          }else{
            _idForMip130 = '';
          }

          // measureIds = measureIds.filter((item:any) => item != _idForMip130.toString());
          this.measures = this.measures.filter((measure:any)=>{
            return !measureIds.includes(measure._id.toString());
          });
        };

        if(this.measures&&this.measures.length){
          let msg='';
          for (let mips of this.measures){
            msg=mips.quality_number+', '+msg;
          }
          let lastCommaIndex = msg.lastIndexOf(",");
          if (lastCommaIndex !== -1) {
            msg = msg.slice(0, lastCommaIndex) + "." + msg.slice(lastCommaIndex + 1);
          }
          if(this.showMipsToaster) {
            msg='MIPS measure(s) available for this encounter: ' + msg;
            this.toastr.info(msg, 'Mips Info');
            this.showMipsToasterOnce.emit(false);
          }
          this.mipsLoaded = true;
          this.availableMipsEvent.emit(this.measures);
        }else{
          this.availableMipsEvent.emit([]);
          this.mipsService.setQuestionnairesResult([]);
        }
        this.submitClicked=false;
        if(this.measures&&this.measures.length){
          await this.initDraftQuestionare("");
        }
      }
    })

  }

  async submitMips(){
    this.submitClicked=true;
    const questionnaireResults = await firstValueFrom(this.mipsService.castQuestionnaireResults);
    // console.log("questionnaire Results for submit mips in dialog : ",questionnaireResults);

    if (Array.isArray(questionnaireResults) && questionnaireResults.length > 0 && this.charge) {
      
      // await this.mipsService.draftQuestionnareResults(questionnaireResults.map(r => ({ measure_id: r.measure._id, answer: r.answer, matched: r.measure.matched, reasons:r.reasons, chads_scrore:r.chads_scrore, question_id:r.question_id })), this.charge.charge_id||this.charge._id, this.currentUser.company_id)
      //     .toPromise();

      this.mipsService.saveQuestionnareResults(questionnaireResults.map(r => ({ measure_id: r.measure._id, answer: r.answer, matched: r.measure.matched, reasons:r.reasons, chads_scrore:r.chads_scrore, question_id:r.question_id })), this.charge.charge_id||this.charge._id, this.currentUser.company_id)
      .subscribe(async (response:any) => {
        if(response.status === 200) {
          this.getRecentMipsEvent.emit(true);
          await this.getrecentMip();

          this.submittedMips = response.data;
          this.toastr.success('MIPS submitted successfully');
          this.initMeasures();
          this.showMipsDialog = !this.showMipsDialog;
        }else{
          this.submitClicked=false;
        }
      })
    }
  }

  getMipsSubmitValueForButton(){
    let resp=true;
    this.mipsService.castQuestionnaireResults.subscribe(res=>{
      // console.log("questionnaireResults : ",!(Array.isArray(res) && res.length >0));
      if (Array.isArray(res) && res.length >0) {
        resp= true;
      } 
      else 
        resp= false;
    });
    return resp;
  }

  async initDraftQuestionare(event) {
    this.mipsService.getDraftQuestionnareResults( this.charge.charge_id||this.charge._id, this.currentUser.company_id)
    .subscribe(async (response:any) => {
      if(response.status === 200) {
        this.draftMips = response.data;
        if(this.draftMips&&this.draftMips.length){
          this.mipsService.setQuestionnairesResult([]);
          this.measureAnswered = [];
          for (let mips of this.measures){
            const index = this.draftMips.findIndex((e:any)=>{return mips._id?.toString()==e.measure_id?.toString()});
            if(index>=0){
              // console.log("index : ", index);
              const answer = this.draftMips[index].answer;
              const reasons = this.draftMips[index].reasons;
              const chads_scrore = this.draftMips[index].chads_scrore;
              const question_id = this.draftMips[index].question_id;
              this.loadDraftMips(mips, answer, reasons, chads_scrore, question_id);
            }
          }
        }
        if (event == 'showMipsDialog') {
          this.showMipsDialog = true;
        }
      }
    })
  }


  loadDraftMips(measure, answer, reasons, chads_scrore, question_id){

    let data ={
      measure:measure,
      answer:answer, 
      reasons:reasons,
      chads_scrore:chads_scrore,
      question_id:question_id,
      goToNext:false
    };

    this.handleAnswerCalculate(data);
  }

  handleMeasureSelect(measure) {
    this.selectedMeasure = measure;
    this.hideMips = false;  
    const wrapperElement = this.elementRef.nativeElement.querySelector('.wrapper');
    if (wrapperElement) {
      this.renderer.setProperty(wrapperElement, 'scrollTop', 0);
    } 
  }

  handleAnswerCalculate(data) {

    const {measure, answer, reasons, chads_scrore, question_id,goToNext = true} = data;
    if(answer||answer=='') {
      // this.mipsService.saveQuestionnareResult(measure._id, this.note.patient._id, this.note.rounding_sheet_id, answer)
      // .subscribe((resposne:any) => {
      //   console.log(resposne);

      // })
      // this.mipsService.setQuestionnaireResult(data);
      
      let measureAnswered = this.isMeasureAnswered(measure);
      if(!measureAnswered) {
        this.measureAnswered.push(data);
      } else {
        measureAnswered.answer = answer;
        measureAnswered.reasons = reasons;
        measureAnswered.chads_scrore = chads_scrore;
        measureAnswered.question_id = question_id;
      }
      // console.log("questionnaireResults : ",this.measureAnswered);
      
      if(goToNext) {
        this.goToNextMeasure(measure);
      }
      this.mipsService.setQuestionnairesResult(this.measureAnswered);

    }
  }
  isMeasureAnswered(measure) {
    return this.measureAnswered.find(answered => answered.measure._id === measure._id)
  }
  getrecentMip() {
    return new Promise((resolve, reject) => {
      const details = {
        patient_id: this.note.patient._id
      }
      this.service.getrecentMip(details).subscribe((res: any) => {
        if (res.data.allMips?.length > 0) {
          let year = (new Date()).getFullYear();
          let recentOneMip;
          if(res.data.recentOneMip){
            recentOneMip = res.data.recentOneMip;
          }
          let allRecentMips = res.data.allMips.filter((mip: any) => mip.date_obj?.year == year);
          if(allRecentMips.length >= 0){
            allRecentMips = allRecentMips.map((mip: any) => {return mip.recentMip;});
            allRecentMips = [].concat(...allRecentMips);
            allRecentMips = allRecentMips.filter((obj, index, self) => index === self.findIndex(q => q.measure_id[0]?._id?.toString() == obj.measure_id[0]?._id?.toString()));
            recentOneMip.recentMip = allRecentMips;
          }
          this.recentMips = recentOneMip;
  
          if (this.recentMips) {
            this.submittedMips = this.recentMips.recentMip;
          }
        }
        resolve(1)
      })
    });
  }
  mipsInThisYear(){
    if(this.recentMips){
      let year=(new Date()).getFullYear();
      let mipYear = this.recentMips?.date_obj?.year;
      if(mipYear==year) return true;
      mipYear=(new Date(this.recentMips.createdAt)).getFullYear();
      if(mipYear==year) return true;
      else return false;
    }
    return false;
  }

  goToNextMeasure(measure) {
    const index = this.measures.findIndex(m => m._id === measure._id);
    const nextIndex = index >= 0 ? (index+1) : -1;

    if(nextIndex >= 0 && nextIndex < this.measures.length) {
      this.selectedMeasure = this.measures[nextIndex];
    } else if(nextIndex === this.measures.length) {
      this.hideMips = true;
      this.selectedMeasure = null;
    }
    // this.mipsService.setQuestionnairesResult(this.measureAnswered);
  }

  mipsDialog() {
    this.saveMipsasDraft();
    this.showMipsDialog = !this.showMipsDialog;
  }

}
