<app-header></app-header>
<div style=" flex-direction: row; box-sizing: border-box; display: inherit !important;">

    <div class="container  grid-container" [loaderId]="loaderId" ngxUiLoaderBlurred>
     <div class="grid-item" style="overflow-y: auto;flex-direction: column;box-sizing: border-box; display: flex; ">
        <div class="flex gap-2">
            <app-input-with-dropdown class="flex-grow" [noDropdown]="true" placeholder="Filter by Patient Name"
                                         (inputChange)="applyFilterPatientNameNew($event.target.value)">
                </app-input-with-dropdown>
                <ng-multiselect-dropdown class="flex items-baseline"
                                         style="margin-left: 5px; width:300px ;background-color:white; border-radius: 5px; "
                                         name="facility"
                                         placeholder="Filter by Facility" [data]="facilities"
                                         [settings]="dropdownSettings" [(ngModel)]="Modelfacility"
                                         (onSelect)="onItemSelectNew($event)" (onDeSelect)="onItemDeSelectNew($event)"
                                         [disabled]="disabled">
                </ng-multiselect-dropdown>

                <div class="flex gap-2 justify-between items-baseline" style="width: 14%;padding-right: 10px;
                padding-left: 5px;">
                    <mat-form-field class="patient-date"
                                    style="background: white; height: 39px; width: -webkit-fill-available; padding-top: 3px; border-radius: 5px;">
                        <mat-label>Filter by DOS</mat-label>
                        <input matInput [matDatepicker]="pick" name="selectedDOV"
                               (dateChange)="dateChangeDOVNew($event)"
                               [value]="selectedDOV" [ngModel]="selectedDOV"/>
                        <mat-datepicker-toggle matSuffix [for]="pick"></mat-datepicker-toggle>
                        <mat-datepicker #pick></mat-datepicker>
                    </mat-form-field>
                </div>

                <button class="dn-button-primary" mat-raised-button (click)="filterData()">Fliter</button>
                <button class="dn-button-primary" mat-raised-button (click)="clearfilterData()"
                        style="margin-left: 5px">Clear Fliter
                </button>
                <button mat-raised-button class="dn-button-primary" style="margin-left: 5px"
                        (click)="captureCharges()">Generate Report
                </button>
            </div>
            <div style="height: 3px;"></div>
            <div class="flex gap-2" style="background: white; border-radius: 5px; padding-left: 5px;"
                 *ngIf="filter">
                Number of Patients - {{selectedpatients.length}}
            </div>
            <div style="height: 3px;"></div>
            <div class="flex gap-2" style="overflow: auto; background: white;">
                <table mat-table [dataSource]="dataSource" matSort
                       (matSortChange)="sortData($event)">

                    <ng-container matColumnDef="checkBox">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Completed</th>
                        <td mat-cell *matCellDef="let element"
                            style="width: 30%; padding-left: 10px; text-align: center;">
                            <mat-checkbox style="width: 25%;" (change)="changeCheckBox(element, $event);"
                                          [checked]="checkBoxValue(element)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="visit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date of Service</th>
                        <td mat-cell *matCellDef="let element" style="width: 25%">{{ getVisit(element)}}</td>
                    </ng-container>

                    <ng-container matColumnDef="time">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Time</th>
                        <td mat-cell *matCellDef="let element" style="width: 15%">{{ getTime(element)}}</td>
                    </ng-container>

                    <ng-container matColumnDef="patient_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                        <td mat-cell *matCellDef="let element" style="width: 25%">
                            {{getPatientName(element)}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="location">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Facility</th>
                        <td mat-cell *matCellDef="let element" style="width: 30%"> {{getFacility(element)}} </td>
                    </ng-container>

                    <ng-container matColumnDef="operation">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let element" style="width: 15%; text-align: center;">
                            <button mat-raised-button class="dn-button-primary" *ngIf="element.patient_id.pcc_patientId"
                                    [routerLink]="['/patient-detail/' + element.patient_id._id + '/facesheet']">
                                Facesheet
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                        (click)="patientClicked(row)"></tr>
                </table>
            </div>
            <mat-paginator #paginator [pageSizeOptions]="[10, 30, 100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <ngx-ui-loader [hasProgressBar]="true" [loaderId]="loaderId"></ngx-ui-loader>

</div>
