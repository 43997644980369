<div class="wrapper">
    <div class="flex flex-col items-start justify-start">
        <span style="padding: 8px; margin-left: 5px; font-weight: 600; font-size: 12px;">Recent Searches</span>
        <div class="row flex flex-row" (click)="onRowClick(history)" *ngFor="let history of noteTextSearchHistory">
            <span style="margin-left: 5px;">{{history.keyword}}</span>
        </div>
    </div>

    <div class="flex flex-row justify-end items-center gap-1">
        <button mat-raised-button class="dn-button-primary" (click)="handlePageNavigation(true);"
            [disabled]="!pagination.hasPrevPage"> &lt;</button>

        <span class="no-wrap" style="font-size: 12px;">
            {{pagination.skip + noteTextSearchHistory.length === 0 ? 0 : pagination.skip + 1 }} -
            {{pagination.skip +
            noteTextSearchHistory.length}} out of {{pagination.totalRecords}}
        </span>
        <button mat-raised-button class="dn-button-primary" (click)="handlePageNavigation(false);"
            [disabled]="!pagination.hasNextPage">&gt;</button>
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>