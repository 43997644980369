import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as global from 'src/app/includes/global';


@Injectable({
  providedIn: 'root',
})
export class DoctorProfileScriptsureService {
    constructor(
        private httpclient: HttpClient
    ) {}

    sendInvite( userId ) {
        return this.httpclient
            .post( `${ global.url }/scriptsure/send-invite`, { userId })
            .toPromise()
            .then(( response: any ) => {
                if ( response.status !== 200 ) {
                    throw new Error( response.message );
                }

                return response;
            });
    }
}
