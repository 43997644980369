import {Component, OnInit} from '@angular/core';
import {AppointmentDataService} from '../service/data.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-video-call',
    templateUrl: './video-call.template.html'
})
export class VideoCallComponent implements OnInit {
    constructor(public service: AppointmentDataService,
                private toastr: ToastrService) {
    }

    ngOnInit(): void {
    }
}
