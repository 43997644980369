import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, Inject, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { WoundService } from 'src/app/services/wound.service';
import { WoundCareWidgetService } from '../wound-care-widget.service';
import { WoundDiagnosis, WoundICD10DialogComponent } from '../wound-icd10-dialog/wound-icd10-dialog.component';
import { calculateArea, getFieldName } from '../wound-utils';
import * as global from "../../../global";
import { PatientAddService } from 'src/app/includes/patient-add/patient-add-service';

@Component({
  selector: 'app-create-wound-dialog',
  templateUrl: './create-wound-dialog.component.html',
  styleUrls: ['./create-wound-dialog.component.css']
})
export class CreateWoundDialogComponent implements OnInit {
  global = global;
  loaderId = 'create-wound-dialog-loader';
  provider;
  patient;
  wound;
  childWound;
  dataset: any[] = [];
  allDataSet: any[] = [];
  todayDate: Date | moment.Moment;
  freeTextFieldData = {};
  selectedDataset = {
    etiolgy: '',
    body_part: '',
    stage: '',
    body_side: '',
    state: '',
    direction: '',
    directions: '',
    exposed_tissues: '',
    periwound: '',
    eschar: '',
    granulation: '',
    slough: '',
    epithelial: '',
    infection_signs: '',
    exudate_type: '',
    exudate: '',
    exposed_structure: '',
    odor: '',
    wound_recognition_undetermined: false,
    wound_recognition_date: null,
    on_set_date: "",
    wound_recognition_date_type: '',
    pain: '',
    primary_treatment: '',
    secondary_dressing: '',
    change_frequency: '',
    'pressure_reduction/off_loading': '',
    dressings: '',
    cleanse_wound_with: '',
    wound_status: '',
    length: null,
    width: null,
    depth: null,
    area: null,
    volume: null,
    tunneling: false,
    tunneling_distance: '',
    tunneling_direction: '',
    under_mining: false,
    undermining_to: '',
    undermining_from: '',
    undermining_distance: '',
    extended_wound_model: []
    }
  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: null,
    PAGE_LIMIT: 8 ,
    skip: 0,
    dataSet: '1'
  };
  selectedDatasetInLatestChildWound = {
    stage: '',
    length: null,
    width: null,
    depth: null,
    area: null,
    volume: null,
    tunneling: false,
    tunneling_distance: '',
    tunneling_direction: '',
    under_mining: false,
    undermining_to: '',
    undermining_from: '',
    undermining_distance: ''
  }
  selectedState: string = "";
  selectedStage: string = "";
  laterality = [
    'R',
    'L',
    'BL'
  ]
  disabledDataset: any = {};
  disabledDatasetInLatestChildWound: any = {};
  woundLocked = false;
  direction:any;
  isDirectionFound: boolean = false;
  isAswcPortal: boolean = false;
  onICDsSave: EventEmitter<any> = new EventEmitter();

  woundDiagnosis: WoundDiagnosis;
  oldselectedDataset: any;
  oldselectedDatasetInLatestChildWound: any;
  avatar: Boolean = false;
  avatarMsg: String = "Show Avatar";
  selectedNext: boolean = false;
  previousValue: String = '';
  separatorKeysCodes: number[] = [ENTER, COMMA];
  selectedRecognitionDate: any;
  datasetSettings: any;
  allDataLoaded: Boolean = false;
  hideICDOption: Boolean = true;
  lastAdmitDate: moment.Moment;
  dateInCorrectError: boolean = false;
  etiologySelectionError: boolean = false;
  stageSelectionError: boolean = false;
  dateNullError: boolean = false;
  @ViewChild("presentOnAdmissionDateInput", {static: false}) presentOnAdmissionDateInput: ElementRef;
  @ViewChild("facilityAcquiredInput", {static: false}) facilityAcquiredInput: ElementRef;
  checkParentWoundUpdate: any;
  updatePhrasesData: any;
  should_show_wound_location: boolean = false;
  constructor(
    private loader: NgxUiLoaderService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateWoundDialogComponent>,
    private woundCareWidgetService: WoundCareWidgetService,
    private woundService: WoundService,
    private _company: CompanyService,
    private _currentUser: AuthService,
    private _commonService: CommonService,
    private _patientAddService: PatientAddService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (this.data) {
      this.provider = data.provider;
      this.patient = data.patient;
      this.wound = data.wound;
      this.selectedState = data.wound.state;
      this.childWound = data.childWound;
      this.should_show_wound_location = data.should_show_wound_location;
      this.onICDsSave = data.onICDsSave;
      if(data.checkParentWoundUpdate !== undefined &&  data.checkParentWoundUpdate !== null){
        this.checkParentWoundUpdate = data.checkParentWoundUpdate
      }else{
        this.checkParentWoundUpdate = false;
      }
      if(data.updatePhrasesData !== undefined &&  data.updatePhrasesData !== null){
        this.updatePhrasesData = data.updatePhrasesData
      }else{
        this.updatePhrasesData = true;
      }
      for (const key in this.wound) {
        if (Object.prototype.hasOwnProperty.call(this.selectedDataset, key)) {
          if(key === 'width' || key === 'length' || key === 'depth'){
            if(this.wound[key] === null || this.wound[key] === ""){
              this.selectedDataset[key] = 0;  
            }else{
              this.selectedDataset[key] = this.wound[key];
            }
          }else{
            this.selectedDataset[key] = this.wound[key];
          }
          if (this.isArchivedWound() && key === 'state') {
            this.disabledDataset[key] = false;
          } else {
            this.disabledDataset[key] = this.isArchivedWound() || !!this.wound[key];
          }
        } else {
        }
      }
      if (this.hasLatestChildWound()) {
        for (const key in this.childWound) {
          if (Object.prototype.hasOwnProperty.call(this.selectedDatasetInLatestChildWound, key)) {
            if(key === 'width' || key === 'length' || key === 'depth'){
              if(this.childWound[key] === null || this.childWound[key] === ""){
                this.selectedDatasetInLatestChildWound[key] = 0;  
              }else{
                this.selectedDatasetInLatestChildWound[key] = this.childWound[key];
              }
            }else{
              this.selectedDatasetInLatestChildWound[key] = this.childWound[key];
            }
            if (this.isArchivedWound() && key === 'state') {
              this.disabledDatasetInLatestChildWound[key] = false;
            } else {
              this.disabledDatasetInLatestChildWound[key] = this.isArchivedWound() || !!this.childWound[key];
            }
          } else {
          }
        }
      }
      if (!this.selectedDataset.wound_recognition_date && this.selectedDataset.wound_recognition_date_type === 'facility_acquired') {
        if (this.patient.pcc_payload  && !this.selectedDataset.wound_recognition_undetermined) {
          this.selectedDataset['wound_recognition_date'] = this.patient.pcc_payload.admissionDate;
        } else if(this.patient.last_admit_date_obj  && !this.selectedDataset.wound_recognition_undetermined){
          const { year, month, day } = this.patient.last_admit_date_obj;
          let d = (day < 10) ? ('0' + (day).toString()) : (day).toString();
          let m = ((month + 1)  < 10) ? ('0' + month) : (month + 1).toString();
          let y = year.toString();
          this.selectedDataset['wound_recognition_date'] = `${y}-${m}-${d}`;
        }else if(this.selectedDataset.wound_recognition_undetermined) {
          this.selectedDataset['wound_recognition_date'] = null
        }
        else{
          const[year, month, day] =  this._commonService.convertDateToStringforMoment(moment()).split("-");
          this.selectedDataset.wound_recognition_date = year + "-" + month + "-" + day;
        }
      }else if(!this.selectedDataset.wound_recognition_date && this.selectedDataset.wound_recognition_date_type === 'present_on_admission'){
        if (this.patient.pcc_payload) {
          this.selectedDataset['wound_recognition_date'] = this.patient.pcc_payload.admissionDate;
        } else if(this.patient.last_admit_date_obj){
          const { year, month, day } = this.patient.last_admit_date_obj;
          let d = (day < 10) ? ('0' + (day).toString()) : (day).toString();
          let m = ((month + 1)  < 10) ? ('0' + month) : (month + 1).toString();
          let y = year.toString();
          this.selectedDataset['wound_recognition_date'] = `${y}-${m}-${d}`;
        }else{
          const[year, month, day] =  this._commonService.convertDateToStringforMoment(moment()).split("-");
          this.selectedDataset.wound_recognition_date = year + "-" + month + "-" + day;
        }
      }
      this.oldselectedDataset = JSON.parse(JSON.stringify({ ...this.selectedDataset }));
      this.oldselectedDatasetInLatestChildWound = { ...this.selectedDatasetInLatestChildWound };
    }
    this.todayDate = this.wound.wound_recognition_date ? this.wound.wound_recognition_date : new Date();
    if (this._currentUser.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      this.hideICDOption = false;      
    }
    // if(this.patient.last_admit_date_obj){
    //   const { year, month, day } = this.patient.last_admit_date_obj;
    //   this.lastAdmitDate = moment(`${year}-${month}-${day}`);
    //   if(this.selectedDataset.wound_recognition_date_type === 'present_on_admission'){
    //     let d = (day < 10) ? ('0' + (day).toString()) : (day).toString();
    //     let m = ((month + 1)  < 10) ? ('0' + month) : (month + 1).toString();
    //     let y = year.toString();
    //     this.selectedDataset['wound_recognition_date'] = `${y}-${m}-${d}T00:00:00.000Z`;
    //   }
    // }
  }

  async ngOnInit() {
    if (global.base_url == 'https://aswc.doctornow.io' || global.base_url == 'https://app3.doctornow.io')
      this.isAswcPortal = true;
    this.loader.startLoader(this.loaderId);
    this.todayDate = new Date();
    this.dialogRef.beforeClosed().subscribe(async () => {
      if(this.dateInCorrectError === true || (this.dateNullError === true && this.selectedDataset['wound_recognition_date_type'] === 'facility_aquired' && !this.selectedDataset.wound_recognition_undetermined) || (this.dateNullError === true && this.selectedDataset['wound_recognition_date_type'] === 'present_on_admission') || this.etiologySelectionError){
        return
      };
      if (!(JSON.stringify(this.selectedDataset) === JSON.stringify(this.oldselectedDataset)) || !(JSON.stringify(this.oldselectedDatasetInLatestChildWound) === JSON.stringify(this.selectedDatasetInLatestChildWound))) {
        this.updateWoundData();
      }
      // if(this.isLastAdmitDateChanged()) await this.editPatient();
    });
    await this.getDataSetsColumns();
    // const loaderInstance = this.loader.getLoader(this.loaderId);
    if (this.isAswcPortal) {
      let response = await lastValueFrom(this.woundCareWidgetService.getAllDataset(this.provider.company_id, {}, "2", null, null, this.hasLatestChildWound() ? this.childWound.created_by_user_type : this.wound.created_by_user_type));
      if (response.status == 200) {
        for (let data of response.data) {
          if (data.header == 'DIRECTION') {
            this.direction = data;
            this.isDirectionFound = true;
          }
        }
      }
    }
    let userType = this.hasLatestChildWound() ? this.childWound.created_by_user_type : this.wound.created_by_user_type;
    userType = userType === global.USER_TYPE.MEDICAL_ASSISTANT ? global.USER_TYPE.DOCTOR : userType;
    const { status, data } = await lastValueFrom(this.woundCareWidgetService.getAllDataset(this.provider.company_id, {}, "1", null,null, userType));
    if (status == 200) {
      this.allDataSet = data;
      // this.dataset = data;
      if (this.isDirectionFound && this.isAswcPortal){
        this.allDataSet.push(this.direction);
      }
      this.reArrangeDataSet(this.datasetSettings.dataSet1);
      this.allDataLoaded = true
    }
    let location_id: any;
    this.allDataSet.forEach((set)=>{
      if(set.multiselect_enabled === false){
        let fieldName = this.getFieldName(set.header);
        if (fieldName === 'wound_edges') {
          fieldName = 'exposed_structure';
        } else if (fieldName === 'exudate_amount') {
          fieldName = 'exudate';
        } else if (fieldName === 'wound_odor') {
          fieldName = 'odor';
        }
        this.freeTextFieldData[fieldName] = this.selectedDataset[fieldName];
      }

      if (set.header == 'LOCATION') {
        location_id = set._id;
      }
    });
   
    if (this.isDirectionFound && this.isAswcPortal) {
      let ids = [];
      for (let index = 0, ind = 0; index < this.datasetSettings.dataSet1.length; index++) {
        ids[ind] = this.datasetSettings.dataSet1[index];
        if (this.datasetSettings.dataSet1[index] == location_id) {
          ind++;
          ids[ind] = this.direction._id;
        }
        ind++;
      }

      this.datasetSettings.dataSet1 = [...ids];
    }
    await this.initWoundDiagnosis();
    this.loader.stopLoader(this.loaderId);

  }
  async editPatient() {
    const { year, month, day } = this._commonService.splitMomentToObj(this.lastAdmitDate);
    const last_admit_date_obj = { year, month, day };
    const response: any = await lastValueFrom(this._patientAddService.editPatient({ _id: this.patient._id }, { $set: { last_admit_date_obj } }));
    if (response.status === 200) {
      this.patient.last_admit_date_obj = response.data.last_admit_date_obj;
    }
  }
  isLastAdmitDateChanged() {
    if(this.lastAdmitDate){
      const { year: y, month: m, day: d } = this._commonService.splitMomentToObj(this.lastAdmitDate);
      if(this.patient.last_admit_date){
        const { year, month, day } = this.patient.last_admit_date_obj;
        if (year != y || month != m || day != d) return true;
        return false;
      }else{
        return true;
      }
    }else{
      return false;
    }
  }
  async getDataSetsColumns() {
    let userType = this.hasLatestChildWound() ? this.childWound.created_by_user_type : this.wound.created_by_user_type;
    userType = userType === global.USER_TYPE.MEDICAL_ASSISTANT ? global.USER_TYPE.DOCTOR : userType;
    let response: any = await this._company.getDataSetColumnsSetting(null, this._currentUser.currentUser.company_id, userType).toPromise();
    if (response.status == 200) {
      this.datasetSettings = response.data;
    } else {
      this.datasetSettings = []
    }
  }
  async initDataset(dataSet, page) {
    let colDataSetIds = [];
    if (this.pagination.dataSet === '2') {
      if(this.pagination.pageNo == 1){
        colDataSetIds = this.datasetSettings.dataSet2.slice(this.pagination.skip, this.pagination.PAGE_LIMIT);
      }else{
        colDataSetIds = this.datasetSettings.dataSet2.slice(this.pagination.skip, this.pagination.PAGE_LIMIT * this.pagination.pageNo);
      }
    } else {
      colDataSetIds = this.datasetSettings.dataSet1.slice(this.pagination.skip, this.pagination.PAGE_LIMIT)
    }
    if (colDataSetIds.length > 0) {
      let userType = this.hasLatestChildWound() ? this.childWound.created_by_user_type : this.wound.created_by_user_type;
      userType = userType === global.USER_TYPE.MEDICAL_ASSISTANT ? global.USER_TYPE.DOCTOR : userType;
      this.loader.startLoader(this.loaderId);
      let { status, data, totalRecords } = await lastValueFrom(this.woundCareWidgetService.getAllDataset(this.provider.company_id, {}, dataSet, page, colDataSetIds, userType));
      if (status == 200) {
        this.allDataSet = data;
        // this.dataset = data;
        if (dataSet === "2") {
          if(dataSet == '2' && page == 2 && this.isAswcPortal){
            for (let index = 0; index < this.allDataSet.length; index++) {
              if (this.allDataSet[index].header == 'DIRECTION') {
                this.allDataSet.splice(index, 1);
                totalRecords = totalRecords - 1;
              }
            }
          }
          this.pagination.totalRecords = totalRecords;
          this.reArrangeDataSet(colDataSetIds);
        }else{
          if (this.isDirectionFound && this.isAswcPortal){
            this.allDataSet.push(this.direction);
          }
          this.reArrangeDataSet(this.datasetSettings.dataSet1);
        }
        let location_id: any;
        this.allDataSet.forEach((set)=>{
          if(set.multiselect_enabled === false){
            let fieldName = this.getFieldName(set.header);
            if (fieldName === 'wound_edges') {
              fieldName = 'exposed_structure';
            } else if (fieldName === 'exudate_amount') {
              fieldName = 'exudate';
            } else if (fieldName === 'wound_odor') {
              fieldName = 'odor';
            }
            this.freeTextFieldData[fieldName] = this.selectedDataset[fieldName];
          }
          if (set.header == 'LOCATION') {
            location_id = set._id;
          }
        });

        if (this.isDirectionFound && this.isAswcPortal) {
          let ids = [];
          for (let index = 0; index < this.datasetSettings.dataSet1.length; index++) {
            ids[index] = this.datasetSettings.dataSet1[index];
            if (this.datasetSettings.dataSet1[index] == location_id) {
              index++;
              ids[index] = this.direction._id;
            }
          }
          this.datasetSettings.dataSet1 = [...ids];
        }
        this.loader.stopLoader(this.loaderId);
      }
    } else {
      this.loader.startLoader(this.loaderId);
      let userType = this.hasLatestChildWound() ? this.childWound.created_by_user_type : this.wound.created_by_user_type;
      userType = userType === global.USER_TYPE.MEDICAL_ASSISTANT ? global.USER_TYPE.DOCTOR : userType;
      let { status, data, totalRecords } = await lastValueFrom(this.woundCareWidgetService.getAllDataset(this.provider.company_id, {}, dataSet, page, userType));
      if (status == 200) {
        this.allDataSet = data;
        this.dataset = data;
        if (dataSet == "2") {
          if (dataSet == '2' && page == 2 && this.isAswcPortal) {
            for (let index = 0; index < this.allDataSet.length; index++) {
              if (this.allDataSet[index].header == 'DIRECTION') {
                this.allDataSet.splice(index, 1);
                totalRecords = totalRecords - 1;
              }
            }
          }
          this.pagination.totalRecords = totalRecords;
        }
        // this.reArrangeDataSet();
        this.loader.stopLoader(this.loaderId);
      }
    }
  }
  async getNextDataSet() {
    if (this.pagination.dataSet == "2") {
      this.pagination.pageNo++;
    } else {
      this.pagination.dataSet = "2"
    }
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    await this.initDataset(this.pagination.dataSet, this.pagination.pageNo);
  }
  async getPrevDataSet() {
    if (this.pagination.dataSet == "2") {
      this.pagination.pageNo--;
      if (this.pagination.pageNo == 0) {
        this.pagination.pageNo = 1;
        this.pagination.dataSet = "1"
        this.pagination.totalRecords = null;
      }
    }
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    await this.initDataset(this.pagination.dataSet, this.pagination.pageNo);
  }
  reArrangeDataSet(colIDs) {
    if (colIDs.length > 0) {
      this.dataset = this.allDataSet.sort(function (a, b) {
        return colIDs.indexOf(a._id.toString()) - colIDs.indexOf(b._id.toString());
      });
    }else{
      this.dataset = this.allDataSet;
    }
  }
  showNextButton() {
    if (this.pagination.totalRecords == null) {
      return true;
    } else {
      if (this.pagination.skip + this.dataset.length < this.pagination.totalRecords) {
        return true;
      } else {
        return false;
      }
    }
  }
  async initWoundDiagnosis() {
    const { data, status } = await lastValueFrom(this.woundService.getWoundDiagnosis(this.wound._id)) as any;

    if (status == 200 && data) {
      this.woundDiagnosis = data;
    }
  }
  displayItem(item) {
    switch (item.data_type) {
      case 'Text':
        return item.value;

      case 'Dropdown':
        return
    }
  }
  isFieldValid(header) {
    const fieldName = this.getFieldName(header);
    return !!this.selectedDataset[fieldName];
  }
  isFieldValidInLatestChildWound(header) {
    const fieldName = this.getFieldName(header);
    return !!this.selectedDatasetInLatestChildWound[fieldName];
  }

  isFieldValueSelected(value, header, multiselect_enabled, header_id = null) {
    const extendedModelIndex = header_id === null ? -1 : this.checkExtendedWoundModelField(header_id)
    if (typeof value === 'string') value = value.trim();
    let selectedValue = "";
    let fieldName = "";
    if(extendedModelIndex > -1){
      selectedValue = this.selectedDataset.extended_wound_model[extendedModelIndex].value;
    }else{
      fieldName = this.getFieldName(header);
      if (fieldName === 'wound_edges') {
        fieldName = 'exposed_structure';
      } else if (fieldName === 'exudate_amount') {
        fieldName = 'exudate';
      } else if (fieldName === 'wound_odor') {
        fieldName = 'odor';
      }
      selectedValue = this.selectedDataset[fieldName];
    }
    if (typeof selectedValue === 'string') {
      selectedValue = selectedValue.trim();
      if(['granulation','slough','eschar','epithelial'].includes(fieldName)){
        if(!value.includes(fieldName)){
          value = value +  ` ${fieldName}`;
        }
      }
    };
    if (multiselect_enabled) {
      let values = selectedValue?.split(', ') || [];
      const valueIndex = values.indexOf(value);
      return valueIndex >= 0;
    }
    if (fieldName === 'wound_diagnosis') {
      return !!this.wound[fieldName];
    } else {
      if (typeof (value) === 'object') {
        return value.includes(this.selectedDataset[fieldName])
      }
      return selectedValue === value;
    }
  }
  isFieldValueSelectedInLatestChildWound(value, header, multiselect_enabled) {
    if (typeof value === 'string') value = value.trim();
    const fieldName = this.getFieldName(header);
    if (multiselect_enabled) {
      let values = this.selectedDatasetInLatestChildWound[fieldName]?.split(', ') || [];
      const valueIndex = values.indexOf(value);
      return valueIndex >= 0;
    } if (fieldName === 'wound_diagnosis') {
      return !!this.childWound[fieldName];
    } else {
      return this.selectedDatasetInLatestChildWound[fieldName] === value;
    }
  }
  selectFieldValueInLatestChildWound(value, header, multiselect_enabled) {
    let fieldName = this.getFieldName(header);
    if (fieldName === 'wound_edges') {
      fieldName = 'exposed_structure';
    } else if (fieldName === 'exudate_amount') {
      fieldName = 'exudate';
    } else if (fieldName === 'wound_odor') {
      fieldName = 'odor';
    }
    if (typeof value === 'string') value = value.trim()
    // if (this.disabledDatasetInLatestChildWound[fieldName]) return;
    if (this.isEditingLocked()) return;

    if (multiselect_enabled) {
      let values = this.selectedDatasetInLatestChildWound[fieldName]?.split(', ') || [];
      const valueIndex = values.indexOf(value);
      if (valueIndex >= 0) {
        values.splice(valueIndex, 1);
      } else {
        values.push(value);
      }
      let commaSeperated: any = Array.from(new Set(values.filter(e => !!e)));
      commaSeperated = commaSeperated.length === 1 ? commaSeperated[0] : commaSeperated.join(', ');
      this.selectedDatasetInLatestChildWound[fieldName] = commaSeperated
    } else {
      this.selectedDatasetInLatestChildWound[fieldName] = value;
      if (fieldName === 'stage') {
        this.selectedStage = value
      }
      // if(fieldName === 'wound_recognition_date_type') {
      //   if(this.selectedDataset[fieldName] === 'present_on_admission') {
      //   this.selectedDatasetInLatestChildWound['wound_recognition_date'] = this.patient?.pcc_payload?.admissionDate;
      //   } else if(this.selectedDataset[fieldName] !== 'facility_aquired') {
      //     this.selectedDatasetInLatestChildWound['wound_recognition_date'] = null;
      //   }
      // } else {
      //   this.selectedDatasetInLatestChildWound['wound_recognition_date'] = null;
      // }
      if (fieldName === 'wound_recognition_date_type' && this.selectedDataset[fieldName] === 'present_on_admission') {
        let admitDateObj = null;
        if (this.patient.last_admit_date_obj) {
          let year = this.patient.last_admit_date_obj.year;
          let month = this.patient.last_admit_date_obj.month;
          let day = this.patient.last_admit_date_obj.day;
          if (month < 10) { month = '0' + month; }
          if (day < 10) { day = '0' + day; }
          admitDateObj = (month + '/' + day + '/' + year);
        } else if (this.patient.last_admit_date) {
          admitDateObj = moment.tz(this.patient.last_admit_date, "UTC").format('MM/DD/YYYY');
        } else if (this.patient.pcc_payload?.admissionDate) {
          admitDateObj = this.patient.pcc_payload?.admissionDate;
        }
        this.selectedDatasetInLatestChildWound['wound_recognition_date'] = new Date(admitDateObj);
      } else if (fieldName === 'wound_recognition_date_type' && this.selectedDataset[fieldName] === 'facility_aquired') {
        this.selectedDatasetInLatestChildWound['wound_recognition_date'] = this.todayDate;
      }
    }
  }
  selectFieldValue(value, header, multiselect_enabled, column = null) {
    const { selectedDataset, patient, _commonService, todayDate } = this;
    const extendedModelIndex = column === null ? -1 : this.checkExtendedWoundModelField(column._id);
    let fieldName = this.getFieldName(header);
  
    // Map field names to their standardized versions
    const fieldMappings = {
      wound_edges: 'exposed_structure',
      exudate_amount: 'exudate',
      wound_odor: 'odor'
    };
    fieldName = fieldMappings[fieldName] || fieldName;
  
    // Trim and modify value for specific fields
    if (typeof value === 'string') {
      value = value.trim();
      if (['granulation', 'slough', 'eschar', 'epithelial'].includes(fieldName)) {
        if (!value.includes(fieldName)) {
          value = `${value} ${fieldName}`;
        }
      }
    }
  
    // Early return conditions
    if (fieldName !== 'state' && this.isEditingLocked()) return;
    if (fieldName === 'body_side') {
      selectedDataset[fieldName] = (selectedDataset[fieldName] === value) ? null : value;
      return;
    }
  
    // Update selectedDataset based on multiselect_enabled flag
    if (multiselect_enabled) {
      let values = extendedModelIndex > -1 ?
        (selectedDataset.extended_wound_model[extendedModelIndex]?.value?.split(', ') || []) :
        (selectedDataset[fieldName]?.split(', ') || []);
  
      const valueIndex = values.indexOf(value);
      if (valueIndex >= 0) {
        values.splice(valueIndex, 1);
      } else {
        values.push(value);
      }
      const commaSeparated = Array.from(new Set(values.filter(e => !!e))).join(', ');
      this.updateValueinSelectedModel(fieldName, commaSeparated, extendedModelIndex, column);
    } else {
      // Single value updates
      if (fieldName === 'stage') {
        this.selectedStage = value;
      }
  
      const isSameValue = extendedModelIndex > -1 ? selectedDataset.extended_wound_model[extendedModelIndex].value === value : selectedDataset[fieldName] === value && !['state', 'stage', 'etiolgy'].includes(fieldName);
      if (isSameValue) {
        if (extendedModelIndex > -1) {
          selectedDataset.extended_wound_model[extendedModelIndex].value = "";
        } else {
          selectedDataset[fieldName] = "";
        }
        if (fieldName === 'wound_recognition_date_type') {
          selectedDataset['wound_recognition_date'] = null;
        }
      } else {
        this.updateValueinSelectedModel(fieldName, value, extendedModelIndex, column);
      }
  
      // Handle specific logic for wound_recognition_date_type
      if (fieldName === 'wound_recognition_date_type' && this.selectedDataset[fieldName] === 'present_on_admission') {
        this.dateInCorrectError = false;
        this.dateNullError = false;
        let admitDateObj = null;
        this.selectedDataset.wound_recognition_undetermined = false;
        if (this.patient.pcc_payload?.admissionDate) {
          admitDateObj = this.patient.pcc_payload?.admissionDate;
          this.selectedDataset['wound_recognition_date'] = admitDateObj;
        } else if (this.patient.last_admit_date) {
          const lastAdtDate = this.patient.last_admit_date;
          this.selectedDataset['wound_recognition_date'] = lastAdtDate;
        }else if (this.patient.last_admit_date_obj) {
          let { year, month, day } = this.patient.last_admit_date_obj;
          if (month < 10) { month = '0' + month; }
          if (day < 10) { day = '0' + day; }
          this.selectedDataset['wound_recognition_date'] = `${year}-${month}-${day}`;
        }
      } else if (fieldName === 'wound_recognition_date_type' && this.selectedDataset[fieldName] === 'facility_aquired') {
        this.dateInCorrectError = false;
        this.dateNullError = false;
        const[year, month, day] =  this._commonService.convertDateToStringforMoment(moment(this.todayDate)).split("-");
        this.selectedDataset['wound_recognition_date'] = year + "-" + month + "-" + day;
      }
    }
  }
  
  getFacilityAquiredDate(onset_date = false) {
    if (onset_date) {
      if (this.selectedDataset.wound_recognition_date_type == "present_on_admission") {
        if (this.selectedDataset.on_set_date !== null && this.selectedDataset.on_set_date !== "") {
          return this.selectedDataset.on_set_date
        } else {
          // let m = "";
          // let d = "";
          // let month=this.todayDate.getMonth() + 1;
          // let day = this.todayDate.getDate();
          // if (month < 10) {m = '0' + month;}
          // if (day < 10) {d = '0' + day;}
          // this.selectedDataset.on_set_date = m +"/"+ d + "/" + this.todayDate.getFullYear();
          this.selectedDataset.on_set_date = ""
          return this.selectedDataset.on_set_date;
        }
      }
    } else {
      if (this.selectedDataset.wound_recognition_date_type == "facility_aquired") {
        return this.selectedDataset.wound_recognition_date;
      } else {
        return this.todayDate;
      }
    }
  }
  handleCustomTextChangeinParent(column, value, multiselect_enabled) {
    let fieldName = this.getFieldName(column.header);
    const extendedModelIndex = column === null ? -1 : this.checkExtendedWoundModelField(column._id);
    const headerRowsValues = column.rows.map((row)=> row.value);
    if (fieldName === 'wound_edges') {
      fieldName = 'exposed_structure';
    } else if (fieldName === 'exudate_amount') {
      fieldName = 'exudate';
    } else if (fieldName === 'wound_odor') {
      fieldName = 'odor';
    }
    if (multiselect_enabled) {
      if (value == '') {
        const prevFieldValues = this.previousValue.split(', ').map(e => e.trim()).filter(e => !!e);
        let prevValues = extendedModelIndex > -1 ? this.selectedDataset.extended_wound_model[extendedModelIndex].value?.split(', ').map(e => e.trim()).filter(e => !!e) : this.selectedDataset[fieldName].split(', ').map(e => e.trim()).filter(e => !!e);
        prevValues.forEach((v, index) => {
          if (prevFieldValues.includes(v)) {
            prevValues.splice(index, 1);
          }
        });
        this.updateValueinSelectedModel(fieldName, prevValues.map(e => e.trim()).filter(e => !!e).join(', '), extendedModelIndex, column);
      } else {
        if (value[value.length - 1] === ',') {
          const fieldValues = value.split(', ').map(e => e.trim()).filter(e => !!e);
          const prevValues = extendedModelIndex > -1 ? this.selectedDataset.extended_wound_model[extendedModelIndex].value?.split(', ').map(e => e.trim()).filter(e => !!e) : this.selectedDataset[fieldName].split(', ').map(e => e.trim()).filter(e => !!e);
          for (const value of fieldValues) {
            if (prevValues.indexOf(value) === -1) {
              prevValues.push(value.substring(0, value.length - 1));
            }
          }
          this.updateValueinSelectedModel(fieldName, prevValues.map(e => e.trim()).filter(e => !!e).join(', '), extendedModelIndex, column);
        }
      }
    } else {
      if (fieldName === 'stage') {
        this.selectedStage = value
      }
      let headerRowValueIndex = headerRowsValues.findIndex((rvalue)=> rvalue.trim().toLowerCase() === value.trim().toLowerCase())
      if(headerRowValueIndex === -1){
        this.freeTextFieldData[fieldName] = value;
        this.updateValueinSelectedModel(fieldName, value, extendedModelIndex, column);
      }else{
        let hRValue = headerRowsValues[headerRowValueIndex];
        if(['granulation','slough','eschar','epithelial'].includes(fieldName)){
          if(!hRValue.trim().includes(fieldName)){
            hRValue = hRValue + ` ${fieldName}`;
          }
        }
        this.updateValueinSelectedModel(fieldName, hRValue, extendedModelIndex, column);
        this.freeTextFieldData[fieldName] = ""
      }
    }
  }
  handleCustomTextChangeinlatestChild(header, value, multiselect_enabled) {
    let fieldName = this.getFieldName(header);
    if (fieldName === 'wound_edges') {
      fieldName = 'exposed_structure';
    } else if (fieldName === 'exudate_amount') {
      fieldName = 'exudate';
    } else if (fieldName === 'wound_odor') {
      fieldName = 'odor';
    }
    if (multiselect_enabled) {
      if (value == '') {
        const prevFieldValues = this.previousValue.split(', ').map(e => e.trim()).filter(e => !!e);
        let prevValues = this.selectedDatasetInLatestChildWound[fieldName].split(', ').map(e => e.trim()).filter(e => !!e);
        prevValues.forEach((v, index) => {
          if (prevFieldValues.includes(v)) {
            prevValues.splice(index, 1);
          }
        });
        this.selectedDatasetInLatestChildWound[fieldName] = prevValues.map(e => e.trim()).filter(e => !!e).join(', ');
      } else {
        if (value[value.length - 1] === ',') {
          const fieldValues = value.split(', ').map(e => e.trim()).filter(e => !!e);
          const prevValues = this.selectedDatasetInLatestChildWound[fieldName].split(', ').map(e => e.trim()).filter(e => !!e);
          for (const value of fieldValues) {
            if (prevValues.indexOf(value) === -1) {
              prevValues.push(value.substring(0, value.length - 1));
            }
          }
          this.selectedDatasetInLatestChildWound[fieldName] = prevValues.map(e => e.trim()).filter(e => !!e).join(', ');
        }
      }
    } else {
      if (fieldName === 'stage') {
        this.selectedStage = value
      }
      this.selectedDatasetInLatestChildWound[fieldName] = value;
    }
  }
  getHeaderName(header) {
    const headerName = this.getFieldName(header, true)
    if (headerName == "Wound_status") {
      return 'STATUS'
    } else if(headerName == "Directions"){
      return "DIRECTION";
    }else if (headerName == "Stage") {
      return 'STAGE/SEVERITY';
    } else if (headerName == "Body_part") {
      return 'LOCATION'
    } else if (headerName == 'Odor') {
      return 'ODOR POST CLEANSING';
    } else if (headerName == 'Exudate_type') {
      return 'EXUDATE DESCRIPTION';
    } else if(headerName == 'Etiolgy'){
      return 'ETIOLOGY'
    }else if(headerName === 'Exposed_structure'){
      return 'WOUND EDGES'
    }else{
      let $nameWithout_ = headerName.split('_');
      let fullHeaderName = '';
      $nameWithout_.forEach((n, index) => {
        if (index == 0) {
          fullHeaderName = n
        } else {
          fullHeaderName += ' ' + n;
        }
      });
      return fullHeaderName.toUpperCase();
    }
  }
  changeAdmitDate(dateValue){
    if (this.presentOnAdmissionDateInput.nativeElement.value.match(/^\s*\d{4}\s*$/)) {
      this.selectedDataset.wound_recognition_date = new Date(this.presentOnAdmissionDateInput.nativeElement.value, 0);
    }
    this.dateNullError = false;
    this.dateInCorrectError = false;
    if(typeof(dateValue?._i) === 'string'){
      if(Number(dateValue?._i.split("/").length < 3 || Number(dateValue?._i.split("/")[2]) < 1900 )){
        this.dateInCorrectError = true;
        this.lastAdmitDate = null;
        this.selectedDataset.wound_recognition_date = null;
      }
    }else if(dateValue === null){
      this.dateNullError = true;
    }
    if(!this.dateNullError && !this.dateInCorrectError && dateValue){
        this.lastAdmitDate = dateValue;
        if(typeof(dateValue) === 'object' && dateValue?.getDate()){
          let d = (dateValue.getDate() < 10) ? ('0' + (dateValue.getDate()).toString()) : (dateValue.getDate()).toString();
          let m = ((dateValue.getMonth() + 1)  < 10) ? ('0' + (dateValue.getMonth() + 1).toString()) : (dateValue.getMonth() + 1).toString();
          let y = dateValue.getFullYear().toString();
          this.selectedDataset['wound_recognition_date'] = `${y}-${m}-${d}T00:00:00.000Z`;
        }else{
          const[year, month, day] =  this._commonService.convertDateToStringforMoment(dateValue).split("-");
          this.selectedDataset.wound_recognition_date = year + "-" + month + "-" + day;
        }
        // this.selectedDataset.wound_recognition_date = dateValue;
    }
  }
  getFieldName(header, firstLetterUppercase = false) {
    if (firstLetterUppercase) {
      let name = getFieldName(header).substr(0, 18)
      return name.charAt(0).toUpperCase() + name.slice(1)
    } else {
      return getFieldName(header)
    }
  }

  getCustomTextValue(header,multiselect_enabled, checkFreeText = true) {
    const fieldName = this.getFieldName(header.header);
    const extendedModelIndex = header === null ? -1 : this.checkExtendedWoundModelField(header._id)

    let value = '';
    if(extendedModelIndex === -1 && !Object.prototype.hasOwnProperty.call(this.selectedDataset, fieldName)){
      return '';
    } else if(extendedModelIndex > -1){
      value = this.selectedDataset.extended_wound_model[extendedModelIndex].value;
    }else{
      if (fieldName === 'wound_edges') {
        value = this.selectedDataset['exposed_structure'];
      } else if (fieldName === 'exudate_amount') {
        value = this.selectedDataset['exudate'];
      } else if (fieldName === 'wound_odor') {
        value = this.selectedDataset['odor'];
      } else {
        value = this.selectedDataset[fieldName]
      }
    }
    if(checkFreeText && this.freeTextFieldData[fieldName]){
      value = this.freeTextFieldData[fieldName];
    }
    const column = this.dataset.find(column => column.header === header.header);
    if (multiselect_enabled) {
      const newValues = value.split(', ');
      for (const data of column.rows) {
        const index = newValues.findIndex(v => v === data.value.trim());
        if (index >= 0) {
          newValues.splice(index, 1);
        }
      }
      return newValues.filter(v => !!v).join(', ');
    } else {
      if (typeof value === 'string') {
        for (const data of column.rows) {
          if (typeof data.value === 'string') {
            if (value.trim().includes(data.value.trim())) {
              return '';
            }
          } else {
            if (data.value.includes(value)) {
              return '';
            } else {
              return value;
            }
          }
        }
      }
    }
    return value;
  }
  handleSelection(header, $event) {
    const fieldName = this.getFieldName(header);
    this.selectedDataset[fieldName] = $event.value;
  }
  getSelectedValue(header) {
    const fieldName = this.getFieldName(header);
    return this.selectedDataset[fieldName];
  }
  getCustomTextValueInLatestChildWound(header, multiselect_enabled) {
    const fieldName = this.getFieldName(header);
    let value = '';
    if (fieldName === 'wound_edges') {
      value = this.selectedDatasetInLatestChildWound['exposed_structure'];
    } else if (fieldName === 'exudate_amount') {
      value = this.selectedDatasetInLatestChildWound['exudate'];
    } else if (fieldName === 'wound_odor') {
      value = this.selectedDatasetInLatestChildWound['odor'];
    } else {
      value = this.selectedDatasetInLatestChildWound[fieldName]
    }
    const column = this.dataset.find(column => column.header === header);
    if (multiselect_enabled) {
      const newValues = value.split(', ');
      for (const data of column.rows) {
        const index = newValues.findIndex(v => v === data.value.trim());
        if (index >= 0) {
          newValues.splice(index, 1);
        }
      }
      return newValues.filter(v => !!v).join(', ');
    } else {
      if (typeof value === 'string') {
        for (const data of column.rows) {
          if (typeof data.value === 'string') {
            if (data.value.trim() === value.trim()) {
              return '';
            }
          } else {
            if (data.value.includes(value)) {
              return '';
            } else {
              return value;
            }
          }
        }
      }
    }
    return value;
  }
  allFieldsValid() {
    return [this.selectedDataset['wound_recognition_date_type'], ...Object.values(this.selectedDataset)].every(field => !!field)
  }
  async updateWoundData() {
    if (this.isEditingLocked()) {
      // if(this.selectedDataset.state === 'archived' || this.selectedDataset.state === 'active'){
      //   const { status, data } = await lastValueFrom(this.woundCareWidgetService.updateWoundData(this.wound._id, {
      //     state: this.selectedDataset.state
      //   }));
      //   if (status == 200) {
      //     this.updateWoundObj(data);
      //     this.wound['state'] = this.selectedDataset['state'];
      //   }
      //   return;
      // }else{
        if(this.selectedDataset.state !== this.data.actualWoundState){
          const parent_wound_id = this.wound.parent_wound === undefined ? this.wound.wound_id : this.wound.parent_wound !== null ? this.wound.parent_wound : this.childWound.parent_wound !== null ? this.childWound.parent_wound : this.wound._id;
          const { status, data } = await lastValueFrom(this.woundCareWidgetService.updateWoundData(parent_wound_id, {
            state: this.selectedDataset.state
          }));
          if (status == 200) {
            this.updateWoundObj(data);
            this.wound['state'] = this.selectedDataset['state'];
          }
        }
        return;
      // }
    };
    let stateChanged = this.selectedDataset.state !== this.oldselectedDataset.state;
    if(this.selectedDataset.state === this.data.actualWoundState && stateChanged){
      stateChanged = false
    }

    if(stateChanged){
      const parent_wound_id = this.wound.parent_wound === undefined ? this.wound.wound_id : this.wound.parent_wound !== null ? this.wound.parent_wound : this.childWound.parent_wound !== null ? this.childWound.parent_wound : this.wound._id;
      const { status, data } = await lastValueFrom(this.woundCareWidgetService.updateWoundData(parent_wound_id, {state: this.selectedDataset.state}, this.checkParentWoundUpdate ? this.checkParentWoundUpdate : this.hasLatestChildWound()));
      if (status == 200) {
        this.updateWoundObj(data);
          this.wound['state'] = this.selectedDataset['state'];
      }
    }
    let {state: newState, ...newPayload} = this.selectedDataset;
    const {state: oldState, ...oldPayload} = this.oldselectedDataset;
    if(JSON.stringify(newPayload) !== JSON.stringify(oldPayload)){
      const { status, data } = await lastValueFrom(this.woundCareWidgetService.updateWoundData(this.wound._id, newPayload, this.checkParentWoundUpdate ? this.checkParentWoundUpdate : this.hasLatestChildWound()));
      if (status == 200) {
        this.updateWoundObj(data);
        for (const key in this.selectedDataset) {
          if (Object.prototype.hasOwnProperty.call(this.selectedDataset, key)) {
            if(key === 'wound_status' && this.selectedDataset[key] === 'Resolved'){
              // this.wound['is_wound_locked'] = true;
            }
            this.wound[key] = this.selectedDataset[key];
          }
        }
      } 
    }
    if (this.hasLatestChildWound()) {
      let updateData:any = {};
      for (const key in this.selectedDataset) {
        if (Object.prototype.hasOwnProperty.call(this.selectedDataset, key)) {
          const value = this.selectedDataset[key];
          if (this.oldselectedDataset[key] !== value) {
              updateData[key] = value
          }
        }
      }
      for (const key in this.selectedDatasetInLatestChildWound) {
        if (Object.prototype.hasOwnProperty.call(this.selectedDatasetInLatestChildWound, key)) {
          const value = this.selectedDatasetInLatestChildWound[key];
          if (key !== 'stage' && this.oldselectedDatasetInLatestChildWound[key] !== value) {
              updateData[key] = value
          }
        }
      }
      for (const key in updateData) {
        if((key === 'volume' || key === 'area') && updateData[key] === null){
          delete updateData[key];
        }
      }
      if (this.selectedStage != "") {
        updateData['stage'] = this.selectedStage;
      }
      if(updateData['wound_status'] === 'Resolved' || updateData['state'] === 'remission'){
        const [ year, month, day ] = moment().format('YYYY-MM-DD').split('-');
        const date_obj = { year, month, day};
        const currentDate = new Date();
        updateData['wound_healed_date'] = date_obj;
        updateData['wound_healed_date'].hour = currentDate.getHours();
        updateData['wound_healed_date'].minute = currentDate.getMinutes();
      }else if(this.selectedDataset.wound_status !== 'Resolved' && this.selectedDataset.state !== 'remission'){
        updateData['wound_healed_date'] = null;
      }
      const {state: oldState, ...childPayloadData} = updateData;
      const { status, data } = await lastValueFrom(this.woundCareWidgetService.updateWoundData(this.childWound._id, childPayloadData));
      if (status == 200) {
        for (const key in updateData) {
          if (Object.prototype.hasOwnProperty.call(updateData, key)) {
            this.childWound[key] = updateData[key];
          }
        }
      }
    }

    if(this.checkParentWoundUpdate){
      let updateData = {};
      for (const key in this.selectedDataset) {
        if (Object.prototype.hasOwnProperty.call(this.selectedDataset, key)) {
          const value = this.selectedDataset[key];
          if (!['stage','length','width','depth','area','volume','tunneling','tunneling_distance','tunneling_direction','under_mining','undermining_to','undermining_from','undermining_distance'].includes(key) && this.oldselectedDataset[key] !== value) {
              updateData[key] = value
          }
        }
      }
      if(this.wound._most_recent_wound && this.wound.parent_wound && Object.keys(updateData).length > 1){
        await lastValueFrom(this.woundCareWidgetService.updateWoundData(this.wound.parent_wound, updateData));
      }
    }
  }
  updateWoundObj(wound) {
    const { updatedAt, wound_recognition_date_type, wound_recognition_date } = wound;
    const [year, month, day] = this._commonService.convertDateToStringforMoment(moment(updatedAt)).split("-");
    this.wound.updated_At = `${month}/${day}/${year}`;
    this.wound.wound_recognition_date_type = wound_recognition_date_type;
    if (wound_recognition_date){
      const[year, month, day] =  this._commonService.convertDateToStringforMoment(moment(wound_recognition_date)).split("-");
      this.wound.wound_recognition_date = year + "-" + month + "-" + day;
    }
  }
  async updateWoundDataAndClose() {
    const {
        dateInCorrectError,
        dateNullError,
        selectedDataset,
        selectedDatasetInLatestChildWound,
        oldselectedDataset,
        oldselectedDatasetInLatestChildWound,
        selectedStage,
        wound
    } = this;
    if ( dateInCorrectError === true || (dateNullError === true && selectedDataset["wound_recognition_date_type"] === "facility_aquired" && !selectedDataset.wound_recognition_undetermined) || (dateNullError === true && selectedDataset["wound_recognition_date_type"] === "present_on_admission") || (this.selectedDataset['etiolgy'] === '')) {
      if(this.selectedDataset['etiolgy'] === ''){
        this.etiologySelectionError = true;
        this.selectedDataset['etiolgy'] = this.oldselectedDataset['etiolgy'];
      }
      return;
    }else{
      this.etiologySelectionError = false;
    }
    if(this.selectedDataset['stage'] === '' && this.oldselectedDataset['stage'] !== ''){
      ///If user remove the selected value of stage then retain previous value
      this.selectedDataset['stage'] = this.oldselectedDataset['stage'];
    }

    const loc = selectedDataset["body_side"] === "R" ? "Right " : selectedDataset["body_side"] === "L" ? "Left " : selectedDataset["body_side"] === "BL" ? "Bilateral ": "";

    const updateData = this.getExtendedWoundModelObj();
    for (const key of Object.keys(selectedDataset)) {
        const value = selectedDataset[key];
        if ( ![ "direction", "body_part", "body_side", "stage", "state" ].includes(key) && oldselectedDataset[key] !== value
        ) {
            updateData[key === "etiolgy" ? "etiology" : key] = value;
            if((key.includes("tunneling") && selectedDataset['tunneling'] ) || (key.includes("mining") && selectedDataset['under_mining'])){
              updateData[key.includes("tunneling") ? 'tunneling' : 'under_mining'] = selectedDataset[key.includes("tunneling") ? 'tunneling' : 'under_mining'];
              updateData[key.includes("tunneling") ? 'tunneling_distance' : 'undermining_distance'] = selectedDataset[key.includes("tunneling") ? 'tunneling_distance' : 'undermining_distance'];
              updateData[key.includes("tunneling") ? 'tunneling_direction' : 'undermining_to'] = selectedDataset[key.includes("tunneling") ? 'tunneling_direction' : 'undermining_to'];
              if(key.includes('mining')){
                updateData['undermining_from'] = selectedDataset['undermining_from']
              }
            }
            if(key === 'wound_recognition_undetermined' && value === false && oldselectedDataset[key] !== value){
              updateData['wound_recognition_date'] = selectedDataset['wound_recognition_date'] 
            }
        }
    }

    if (this.hasLatestChildWound()) {
        for (const key of Object.keys(selectedDatasetInLatestChildWound)) {
            const value = selectedDatasetInLatestChildWound[key];
            if ( key !== "stage" && oldselectedDatasetInLatestChildWound[key] !== value ) {
              updateData[key] = value;
              if((key.includes("tunneling") && selectedDatasetInLatestChildWound['tunneling'] ) || (key.includes("mining") && selectedDatasetInLatestChildWound['under_mining'])){
                updateData[key.includes("tunneling") ? 'tunneling' : 'under_mining'] = selectedDatasetInLatestChildWound[key.includes("tunneling") ? 'tunneling' : 'under_mining'];
                updateData[key.includes("tunneling") ? 'tunneling_distance' : 'undermining_distance'] = selectedDatasetInLatestChildWound[key.includes("tunneling") ? 'tunneling_distance' : 'undermining_distance'];
                updateData[key.includes("tunneling") ? 'tunneling_direction' : 'undermining_to'] = selectedDatasetInLatestChildWound[key.includes("tunneling") ? 'tunneling_direction' : 'undermining_to'];
                if(key.includes('mining')){
                  updateData['undermining_from'] = selectedDatasetInLatestChildWound['undermining_from']
                }
              }
            }
        }
    }
    const woundLocation = loc + (selectedDataset["direction"] || "") + " " + (selectedDataset["body_part"] || "");
    if(this.should_show_wound_location){
      updateData["woundlocation"] = woundLocation.trim();
      if (selectedDataset["body_part"] !== oldselectedDataset["body_part"]) {
        updateData["body_part"] = selectedDataset["body_part"];
      }
    }else{
      if (selectedDataset["body_part"] !== oldselectedDataset["body_part"]) {
        updateData["woundlocation"] = woundLocation.trim();
        updateData["body_part"] = woundLocation.trim();
      }
    }
    if (selectedStage != "") {
      updateData['severity'] = selectedStage;
      updateData['stage'] = selectedStage;
    }
    if(this.checkParentWoundUpdate){
      updateData["woundNo"] = wound.woundNo;
      updateData["_id"] = wound._most_recent_wound ? wound.parent_wound :  wound._id;
    }else{
      updateData["woundNo"] = wound.woundNo;
      updateData["_id"] = wound._id;
    }
    if(Object.prototype.hasOwnProperty.call(updateData, 'etiology')){
      updateData['etiolgy'] = updateData['etiology']
    }

    if(Object.prototype.hasOwnProperty.call(updateData, 'wound_recognition_date_type')){
      updateData['wound_recognition_date'] = selectedDataset['wound_recognition_date']
    }
    updateData['populated_extended_model'] = wound.populated_extended_model;

    const hasUpdates = Object.keys(updateData).length > 4 && this.updatePhrasesData;

    if(Object.keys(updateData).includes('area')){
      if(!Object.keys(updateData).includes('length')){
        updateData['length'] = this.hasLatestChildWound() ? this.selectedDatasetInLatestChildWound['length'] : this.selectedDataset['length'];
      }
      if(!Object.keys(updateData).includes('width')){
        updateData['width'] = this.hasLatestChildWound() ? this.selectedDatasetInLatestChildWound['width'] : this.selectedDataset['width'];
      }
    }
    console.log(updateData);
    this.dialogRef.close({
        wound,
        childWound: this.childWound,
        state: this.selectedState,
        stage: this.selectedStage,
        ...(hasUpdates && { updateData })
    });
  }

  showWoundICD10Dialog() {
    const dialogRef = this.dialog.open(WoundICD10DialogComponent, {
      maxWidth: '100vw',
      maxHeight: '90vh',
      height: '90%',
      width: '98%',
      panelClass: 'full-screen-modal',
      data: {
        patient: this.patient,
        provider: this.provider,
        wound: this.wound,
        etilogyGroup: this.dataset.find(column => this.getFieldName(column.header) === 'etiolgy'),
        selectedDataset: this.selectedDataset
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // https://doctornow.atlassian.net/browse/DUP-152
      this.initWoundDiagnosis();
      if (result) {
        this.wound.wound_diagnosis = result.woundDiagnosis._id;
        if (result.selectedWoundGroupDetailsICD) {
          const selectedICDS = (Object.values(result.selectedWoundGroupDetailsICD).filter(e => !!e));
          const previousICDS = (Object.values(result.previousSelectedWoundGroupDetailsICD).filter(e => !!e));
          if(JSON.stringify(selectedICDS) === JSON.stringify(previousICDS)){
            const data = {
              woundNo: this.wound.woundNo,
              wound_id: this.wound._id,
              icds_data: selectedICDS,
            }
            this.onICDsSave.emit(data);
          }else{
            const data = {
              woundNo: this.wound.woundNo,
              wound_id: this.wound._id,
              icds_data: selectedICDS,
              previous_icds: previousICDS
            }
            this.onICDsSave.emit(data);
          }
        }
      }
    });
  }
  changeDate(event , onset_date = false) {
    if (this.facilityAcquiredInput.nativeElement.value.match(/^\s*\d{4}\s*$/)) {
      this.selectedDataset.wound_recognition_date = new Date(this.facilityAcquiredInput.nativeElement.value, 0);
    }
    this.dateInCorrectError = false;
    this.dateNullError = false;
    if(typeof(event.value?._i) === 'string'){
      if(Number(event.value?._i.split("/").length < 3 || Number(event.value?._i.split("/")[2]) < 1900 )){
        this.dateInCorrectError = true;
        this.selectedDataset.wound_recognition_date = null;
      }
    }else if(event.value === null){
      this.dateNullError = true;
    }
    if(!this.dateNullError && !this.dateInCorrectError && event.value){
      if(typeof(event.value) === 'object' && event.value?.getDate()){
        let d = (event.value.getDate() < 10) ? ('0' + (event.value.getDate()).toString()) : (event.value.getDate()).toString();
        let m = ((event.value.getMonth() + 1)  < 10) ? ('0' + (event.value.getMonth() + 1).toString()) : (event.value.getMonth() + 1).toString();
        let y = event.value.getFullYear().toString();
        this.selectedDataset['wound_recognition_date'] = `${y}-${m}-${d}T00:00:00.000Z`;
      }else{
        this.selectedDataset.wound_recognition_date = event.value;
      }      
      const[year, month, day] =  this._commonService.convertDateToStringforMoment(event.value).split("-");
      this.selectedDataset.wound_recognition_date = year + "-" + month + "-" + day;
      // this.selectedDataset.wound_recognition_date = event.value;
    }
  }

  onCheckUndermined($event) {
    if ($event.checked) {
      this.selectedRecognitionDate = this.selectedDataset.wound_recognition_date;
      this.selectedDataset.wound_recognition_date = null;
    } else {
      this.dateNullError = false;
      this.dateInCorrectError = false;
      if (this.selectedRecognitionDate) {
        this.selectedDataset.wound_recognition_date = this.selectedRecognitionDate;
      } else {
        const[year, month, day] =  this._commonService.convertDateToStringforMoment(moment()).split("-");
        this.selectedDataset.wound_recognition_date = `${year}-${month}-${day}`;
      }
    }
    this.selectedDataset.wound_recognition_undetermined = $event.checked;
  }

  hasLatestChildWound() {
    return this.childWound._id !== this.wound._id
  }

  shouldChildWoundColumn(header) {
    if (!this.hasLatestChildWound()) return false;

    const fieldName = this.getFieldName(header);
    const fields = Object.keys(this.selectedDatasetInLatestChildWound);
    return fields.includes(fieldName);
  }

  isArchivedWound() {
    return this.wound.state === 'archived'
  }
  isEditingLocked() {
    // const isCreatedToday = moment(this.childWound.createdAt).startOf('day')
    // .isSame(moment().startOf('day'));
    // if(isCreatedToday) return false;
    if(this.wound?.is_clone === true && this.wound.is_wound_locked === false) return false;
    if(this.wound?.is_wound_locked === true) return true;
    if(this._currentUser.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) return false;
    if(this._currentUser.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE){
      if(this.hasLatestChildWound()){
        if(this._currentUser.currentUser.user_type !== this.childWound.created_by_user_type) return true;
      }else{
        if(this._currentUser.currentUser.user_type !== this.wound.created_by_user_type) return true;
      }
      
    }
    if (this.childWound._most_recent_wound) return false;

    return true;
    // return false;
  }
  showAvatar() {
    this.avatar = !this.avatar;
    if (this.avatar) {
      this.avatarMsg = "Hide Avatar";
    } else {
      this.avatarMsg = "Show Avatar";
    }
  }
  getSelectedWoundICDs() {
    if (this.woundDiagnosis?.selected_data?.length > 0) {
      const icds = this.woundDiagnosis.selected_data.filter(data => data.icd_id).map(data => data.icd_id);
      return icds;
    }
    return [];
  }
  addNewFreeTextField(event: MatChipInputEvent, column, getValueUsingId = false, childData = false): void {
    let value = (event.value || '').trim();
    const extendedModelIndex = column === null ? -1 : this.checkExtendedWoundModelField(column._id);
    const headerRowsValues = column.rows.map((row)=> row.value);
    if(getValueUsingId){
      value = jQuery(`#${column._id}`).val().toString().trim();
    }
    let fieldName = this.getFieldName(column.header);
    if (fieldName === 'wound_edges') {
      fieldName = 'exposed_structure';
    } else if (fieldName === 'exudate_amount') {
      fieldName = 'exudate';
    } else if (fieldName === 'wound_odor') {
      fieldName = 'odor';
    }
    if(childData){
      let rawValue = this.selectedDatasetInLatestChildWound[fieldName].trim();
      if (rawValue == "" || rawValue == " ") {
        this.selectedDatasetInLatestChildWound[fieldName] = value;
      } else {
        const rawAllValues = rawValue.split(", ");
        if(rawAllValues.findIndex((raw)=> raw.toLowerCase() === value.toLowerCase()) === -1){
          const headerRowValueIndex = headerRowsValues.findIndex((rvalue)=> rvalue.toLowerCase() === value.toLowerCase())
          if(headerRowValueIndex === -1){
            this.selectedDatasetInLatestChildWound[fieldName] = rawValue + ", " + value;
          }else{
            this.selectedDatasetInLatestChildWound[fieldName] = rawValue + ", " + headerRowsValues[headerRowValueIndex];
          }
        }
      }
    }else{
      let rawValue = extendedModelIndex > -1 ? this.selectedDataset.extended_wound_model[extendedModelIndex].value.trim() : this.selectedDataset[fieldName].trim();
      if (rawValue == "" || rawValue == " ") {
        this.updateValueinSelectedModel(fieldName, value, extendedModelIndex, column);
      } else {
        const rawAllValues = rawValue.split(", ");
        if(rawAllValues.findIndex((raw)=> raw.toLowerCase() === value.toLowerCase()) === -1){
          const headerRowValueIndex = headerRowsValues.findIndex((rvalue)=> rvalue.toLowerCase() === value.toLowerCase())
          if(headerRowValueIndex === -1){
            this.updateValueinSelectedModel(fieldName, rawValue + ", " + value, extendedModelIndex, column);
          }else{
            this.updateValueinSelectedModel(fieldName, rawValue + ", " + headerRowsValues[headerRowValueIndex], extendedModelIndex, column);
          }
        }
      }
    }
    if(getValueUsingId){
      jQuery(`#${column._id}`).val("")
    }else{
      event.input.value = '';
    }
  }
  checkExtendedWoundModelField(header_id){
    return this.selectedDataset.extended_wound_model.findIndex((em) => em.header_id.toString() === header_id);
  }
  removeFreeTextField(valueRemove, column, childData = false) {
    let fieldName = this.getFieldName(column.header);
    const extendedModelIndex = column === null ? -1 : this.checkExtendedWoundModelField(column._id);
    if (fieldName === 'wound_edges') {
      fieldName = 'exposed_structure';
    } else if (fieldName === 'exudate_amount') {
      fieldName = 'exudate';
    } else if (fieldName === 'wound_odor') {
      fieldName = 'odor';
    }
    if(childData){
      let rawValue = this.selectedDatasetInLatestChildWound[fieldName];
      const allValues = rawValue ? rawValue.split(", ").join(",").split(",") : [];
      allValues.forEach((value, index) => {
        if (value.trim() === valueRemove.trim()) {
          allValues.splice(index, 1);
        }
      });
      this.selectedDatasetInLatestChildWound[fieldName] = allValues.join(", ");
    }else{
      let rawValue = extendedModelIndex > -1  ? this.selectedDataset.extended_wound_model[extendedModelIndex].value : this.selectedDataset[fieldName];
      const allValues = rawValue ? rawValue.split(", ").join(",").split(",") : [];
      allValues.forEach((value, index) => {
        if (value.trim() === valueRemove.trim()) {
          allValues.splice(index, 1);
        }
      });
      this.updateValueinSelectedModel(fieldName, allValues.join(", "), extendedModelIndex, column);
    }
  }
  extractMultiselectCustomValues(column, childData = false) {
    let fieldName = this.getFieldName(column.header);
    const extendedModelIndex = column === null ? -1 : this.checkExtendedWoundModelField(column._id);
    if (fieldName === 'wound_edges') {
      fieldName = 'exposed_structure';
    } else if (fieldName === 'exudate_amount') {
      fieldName = 'exudate';
    } else if (fieldName === 'wound_odor') {
      fieldName = 'odor';
    }
    if(childData){
      let rawValue = this.selectedDatasetInLatestChildWound[fieldName];
      const allValues = rawValue ? rawValue.split(", ").join(",").split(",") : [];
      const valuesToRemove = column.rows.map(row => row.value);
      // remove all occurances of each item in valuesToRemove from allValues
      for (let i = 0; i < valuesToRemove.length; i++) {
        const valueToRemove = valuesToRemove[i];
        allValues.forEach((value, index) => {
          if (typeof (valueToRemove) === "string" && value.trim() === valueToRemove.trim()) {
            allValues.splice(index, 1);
          } else if (value === '') {
            allValues.splice(index, 1);
          }
        })
      }
      return allValues;
    }else{
      let rawValue = extendedModelIndex > -1 ? this.selectedDataset.extended_wound_model[extendedModelIndex].value : this.selectedDataset[fieldName];
      const allValues = rawValue ? rawValue.split(", ").join(",").split(",") : [];
      const valuesToRemove = column.rows.map(row => row.value);
      // remove all occurances of each item in valuesToRemove from allValues
      for (let i = 0; i < valuesToRemove.length; i++) {
        const valueToRemove = valuesToRemove[i];
        allValues.forEach((value, index) => {
          if (typeof (valueToRemove) === "string" && value.trim() === valueToRemove.trim()) {
            allValues.splice(index, 1);
          } else if (value === '') {
            allValues.splice(index, 1);
          }
        })
      }
      return allValues;
    }
  }
  showButtons(header){
    let inputField = jQuery(`#${header}`).val();
    if(inputField && inputField.toString().length > 0){
      return true
    }else{
      return false;
    }
  }
  removeFreeTextValue(header){
    jQuery(`#${header}`).val("");
  }
  getTunnelingUnderminingValue(key){
    if(this.hasLatestChildWound()){
      return this.selectedDatasetInLatestChildWound[key];
    }else{
      return this.selectedDataset[key];
    }
  }
  selectTunnelingUnderminingSelected(key) {
    if(this.hasLatestChildWound()){
      this.selectedDatasetInLatestChildWound[key] = !this.selectedDatasetInLatestChildWound[key];
    }else{
      this.selectedDataset[key] = !this.selectedDataset[key];
    }
  }
  updateTunnelingUnderminingValue(key, value){
    if(this.hasLatestChildWound()){
      this.selectedDatasetInLatestChildWound[key] = value;
    }else{
      this.selectedDataset[key] = value;
    }
  }
  updateArea($event,attr){
  if(this.hasLatestChildWound()){
    this.selectedDatasetInLatestChildWound[attr] = $event;
    if(attr === 'length'){
      this.selectedDatasetInLatestChildWound.length = Number(this.selectedDatasetInLatestChildWound.length);
    }else if(attr === 'width'){
      this.selectedDatasetInLatestChildWound.width = Number(this.selectedDatasetInLatestChildWound.width);
    }else if(attr === 'depth'){
      this.selectedDatasetInLatestChildWound.depth = Number(this.selectedDatasetInLatestChildWound.depth);
    }
    this.selectedDatasetInLatestChildWound.area = Number((this.selectedDatasetInLatestChildWound.length * this.selectedDatasetInLatestChildWound.width).toFixed(4));
    this.selectedDatasetInLatestChildWound.volume = Number((this.selectedDatasetInLatestChildWound.length * this.selectedDatasetInLatestChildWound.width * this.selectedDatasetInLatestChildWound.depth).toFixed(4));
  }else{
      this.selectedDataset[attr] = $event;
      if(attr === 'length'){
        this.selectedDataset.length = Number(this.selectedDataset.length);
      }else if(attr === 'width'){
        this.selectedDataset.width = Number(this.selectedDataset.width);
      }else if(attr === 'depth'){
        this.selectedDataset.depth = Number(this.selectedDataset.depth);
      }
      this.selectedDataset.area = Number((this.selectedDataset.length * this.selectedDataset.width).toFixed(4));
      this.selectedDataset.volume = Number((this.selectedDataset.length * this.selectedDataset.width * this.selectedDataset.depth).toFixed(4));
    }
  }
  isReportUploaded(){
    if(this.wound?.wound_report_uploaded === true){
      return true;
    }else{
      return false;
    }
  }
  keyPressNumbers(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    let inputValue = event.target.value;
    let isDotPresent = inputValue.includes('.');
    let caretPos = event.target.selectionStart;

    // Allow digits (0-9), backspace (8), and decimal point (46)
    if ((charCode >= 48 && charCode <= 57) || charCode == 8 || charCode == 46) {
      // Prevent entering more than 3 digits before the decimal point
      if (!isDotPresent && inputValue.length >= 3 && charCode != 8) {
        event.preventDefault();
        return false;
      }

      // Allow decimal point if not already present
      if (charCode == 46) {
        if (isDotPresent || inputValue.length == 0 || caretPos === 0) {
          event.preventDefault();
          return false;
        }
        return true;
      }

      // Allow only one digit after the decimal point
      if (isDotPresent) {
        let parts = inputValue.split('.');
        if (parts[1].length >= 1 && caretPos > inputValue.indexOf('.')) {
          event.preventDefault();
          return false;
        }
      }

      // Prevent entering numbers greater than 99.9
      if (!isDotPresent && inputValue.length == 2 && charCode != 8) {
        let newValue = parseInt(inputValue + String.fromCharCode(charCode));
        if (newValue > 99) {
          event.preventDefault();
          return false;
        }
      }

      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  getExtendedWoundModelObj() {
    const obj = {};
    this.selectedDataset.extended_wound_model.forEach((exModel) => {
      const header = this.allDataSet.find((h) => h._id.toString().includes(exModel.header_id.toString()));
      if(header){
        obj[`${header.header.toLowerCase().replaceAll(' ','_')}`] = exModel.value
      }
    });
    return obj;
  }
  updateValueinSelectedModel(key, value, modelIndex, column, updateChild = false){
    if(!updateChild){
      if(modelIndex > -1){
        this.selectedDataset.extended_wound_model[modelIndex].value = value;
        if(!this.wound.populated_extended_model){
          this.wound.populated_extended_model = [];
          this.wound.populated_extended_model.push(column);
        }else {
          const colIndex = this.wound.populated_extended_model.findIndex((col) => col._id === column._id);
          if(colIndex === -1){
            this.wound.populated_extended_model.push(column);
          }
        }
      }else{
        if(Object.prototype.hasOwnProperty.call(this.selectedDataset, key)){
          this.selectedDataset[key] = value
        }else{
          this.selectedDataset.extended_wound_model.push({
            header_id: column._id,
            value: value
          });
          if(this.wound.populated_extended_model && this.wound.populated_extended_model.length > 0){
            this.wound.populated_extended_model.push(column);
          }else{
            this.wound.populated_extended_model = [];
            this.wound.populated_extended_model.push(column);
          }
        }
      }
    }else{
    }
  }
  resetValueBasedOnType(obj, key) {
    const type = typeof obj[key];
    if (type === 'string') obj[key] = '';
    else if (type === 'boolean') obj[key] = false;
    else if (type === 'number') obj[key] = 0;
    else obj[key] = null;
  }
  resolveWound() {
    const keysToSkip = ["wound_status", "state", "body_part", "stage", "etiolgy", "body_side", "direction", "extended_wound_model", "wound_recognition_date_type","wound_recognition_undetermined", "wound_recognition_date"];
    
    for (const key of Object.keys(this.selectedDataset)) {
      if (!keysToSkip.includes(key) && !this.selectedDatasetInLatestChildWound.hasOwnProperty(key)) {
        this.resetValueBasedOnType(this.selectedDataset, key);
      } else {
        if (key === "extended_wound_model") {
          this.selectedDataset[key].forEach(exModel => exModel.value = "");
        } else if (this.selectedDatasetInLatestChildWound.hasOwnProperty(key) && key !== 'stage') {
          this.resetValueBasedOnType(this.selectedDatasetInLatestChildWound, key);
        }
      }
    }
    
    this.selectedDataset['wound_status'] = 'Resolved';
    this.selectedDataset['epithelial'] = "100% epithelial";
  }
}