<mat-card class="h-full">
    <mat-card-content>
        <mat-tab-group (selectedTabChange)="onTabClick($event)" class="h-full">
            <mat-tab label="Data Set">
                <ng-container *ngIf="tabIndex == 0">
                    <app-derm-dataset [company_id]="company_id"></app-derm-dataset>
                </ng-container>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>