import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-template-confirmation-dialog',
  templateUrl: './template-confirmation-dialog.component.html',
  styleUrls: ['./template-confirmation-dialog.component.css']
})
export class TemplateConfirmationDialogComponent implements OnInit {

  // constructor() { }
  title: string='';
  deleteRecurrenceEvents: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<TemplateConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  ngOnInit(): void {
    if(this.data){
      this.title = this.data.message
    }
  }
  onNoClick(): void {
    this.dialogRef.close({ confirmed: false, deleteRecurrenceEvents: this.deleteRecurrenceEvents });
  }

  onYesClick(): void {
    this.dialogRef.close({ confirmed: true, deleteRecurrenceEvents: this.deleteRecurrenceEvents });
  }
  

}
