<div class="flex flex-col" style="height: calc(100vh - 130px);overflow-y: auto;">
    <div class="flex items-center"style="margin-bottom: 25px;padding: 20px;">
        <button mat-raised-button class="dn-button-secondary" type="button" (click)="goBack();">Back</button>
    </div>
    <div class="flex flex-col items-center justify-center">
        <h2 mat-dialog-title style="justify-content: center;">Census Columns To View</h2>
    </div>
    <div class="flex flex-col justify-around items-center">
        <h3>Select Columns to Display</h3>
        <div class="flex items-center justify-center gap-9">
            <div class="flex flex-col justify-between items-start">
                <mat-checkbox value="comments_count" [checked]="checkExistence('comments_count')"
                    (change)="handlecensus_columnsCheckboxes($event)">
                    Comments
                </mat-checkbox>
                <mat-checkbox value="admission_date" [checked]="checkExistence('admission_date')"
                    (change)="handlecensus_columnsCheckboxes($event)">
                    A/D
                </mat-checkbox>
                <mat-checkbox value="date_of_birth" [checked]="checkExistence('date_of_birth')"
                    (change)="handlecensus_columnsCheckboxes($event)">
                    DOB
                </mat-checkbox>
                <mat-checkbox value="mrn" [checked]="checkExistence('mrn')"
                    (change)="handlecensus_columnsCheckboxes($event)">
                    MRN
                </mat-checkbox>
            </div>
            <div class="flex flex-col justify-between items-start">
                <mat-checkbox value="discharge" [checked]="checkExistence('discharge')"
                    (change)="handlecensus_columnsCheckboxes($event)">
                    D/C
                </mat-checkbox>
                <mat-checkbox value="location" [checked]="checkExistence('location')"
                    (change)="handlecensus_columnsCheckboxes($event)">
                    Room No
                </mat-checkbox>
                <mat-checkbox value="payer" [checked]="checkExistence('payer')"
                    (change)="handlecensus_columnsCheckboxes($event)">
                    Payor
                </mat-checkbox>
            </div>
            <div class="flex flex-col justify-between items-start">
                <mat-checkbox value="last_seen" [checked]="checkExistence('last_seen')"
                    (change)="handlecensus_columnsCheckboxes($event)">
                    Last Seen
                </mat-checkbox>
                <mat-checkbox value="pcc_patient_diagnosis" [checked]="checkExistence('pcc_patient_diagnosis')"
                    (change)="handlecensus_columnsCheckboxes($event)">
                    PDX
                </mat-checkbox>
                <mat-checkbox value="pcp" [checked]="checkExistence('pcp')"
                    (change)="handlecensus_columnsCheckboxes($event)">
                    PCP
                </mat-checkbox>
            </div>
            <div class="flex flex-col justify-between items-start">
                <mat-checkbox value="operations" [checked]="true" [disabled]="true">
                    Actions
                </mat-checkbox>
                <mat-checkbox value="name" [checked]="true" [disabled]="true">
                    Name
                </mat-checkbox>
                <mat-checkbox value="note" [checked]="true" [disabled]="true">
                    Note
                </mat-checkbox>
            </div>
        </div>
    </div>
    <br>
    <br>
    <div class="flex items-center justify-center">
        <button class="dn-button-primary" mat-raised-button type="button" (click)="setcensus_columns()">Save</button>
    </div>
</div>