import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import * as global from '../global';
import { API_URL } from "src/environments/api-url";
@Injectable({
    providedIn: 'root',
})
export class LoginAuthRedirectService {
    constructor(
        private httpClient: HttpClient
    ) { }

    getLoginInfo(payload) {
      
        // return this.httpClient
        //     .post(global.url + API_URL.AUTH.getLoginInfo, {details: item}).pipe();

        //  create a new post request with authorization header and cookie header
        const headers = new HttpHeaders({
            // Authorization: `Bearer ${token}`
        });
        return this.httpClient.post(global.url + API_URL.AUTH.getUserLoginInfo, payload, { headers }).pipe()
    }
}