import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MatTableDataSource} from "@angular/material/table";
import {PatientRoundingSheetService} from "../../includes/patient-rounding-sheets/patient-rounding-sheet.service";
import {AuthService} from "../../services/auth.service";
import * as global from "../../includes/global"
@Component({
    selector: 'app-patient-charge',
    templateUrl: './patient-charge.component.html',
    styleUrls: ['./patient-charge.component.css']
})
export class PatientChargeComponent implements OnInit {
    displayedColumns: string[] = ['code', 'description'];
    dataSource :MatTableDataSource<any>;
    chargeDataSource: MatTableDataSource<any>;
    diagnoseDataSource: MatTableDataSource<any>;
    loaderId = ['loader-01', 'loader-02'];
    addView = true;
    params: any;
    charge: any;
    currentUser: any;
    global = global;
    isSuperAdmin: boolean = false;
    constructor(
                private route: ActivatedRoute,
                private service: PatientRoundingSheetService,
                private authService: AuthService,
                private router: Router) {
                    
        route.queryParams.subscribe(params => {
            console.log("params",params)
            this.params = params; 
        });
        this.currentUser = this.authService.currentUser;
    }

    ngOnInit(): void {
        this.isSuperAdmin = window.location.pathname.includes('company');
        
        console.log("this.params :", this.params );
        
        this.getCharge();
    }

    getCharge() {
        let filters = {
            patient_id: this.params.patient_id,
            status: 'submitted',
            rounding_sheet_id: this.params.rounding_sheet_id,
            owner_id: this.authService.currentUser._id
        };
        this.service.getCharges(filters)
            .subscribe(res => {
                
                // @ts-ignore
                if (res.data.array.length !== 0) {
                    // @ts-ignore
                    this.charge = res.data.array[0];
                    this.chargeDataSource = new MatTableDataSource(this.charge.cpt_id);
                    this.diagnoseDataSource = new MatTableDataSource(this.charge.icd_id);
                }
            });
    }

    closeAddView() {
        this.addView = false;
        this.getCharge();
    }

}
