<div class="flex justify-between items-center" style="background-color: #44597d; height: 38px; color: white;">
    <h2 style="position: absolute; top: -7px; left: 10px;">Activation</h2>
    <div class="top-right-close-btn">
        <button mat-mini-fab mat-dialog-close>
            <mat-icon>cancel</mat-icon>
        </button>
    </div>
</div>
<div style="padding-left: 18px; padding-right: 18px; padding-top: 5px; padding-bottom: 10px;">

    <form (ngSubmit)="submitActivationForm()" #form2="ngForm" class="flex flex-col items-center justify-start">
        <mat-form-field appearance="fill" style="width: 100%;">
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <mat-label>Subject</mat-label>
            <input matInput [readonly]="facilityActivationId" type="text" name="regarding" #regarding="ngModel" [(ngModel)]="activationModel.subject">

        </mat-form-field>

        <mat-form-field style="width: 100%">
            <mat-label>To (name): </mat-label>
            <input matInput [readonly]="facilityActivationId" type="text" name="recipient" #recipient="ngModel" [(ngModel)]="activationModel.recipient">

        </mat-form-field>
        <mat-form-field style="width: 100%">
            <mat-label>Re (Facility Name): </mat-label>
            <input matInput [readonly]="facilityActivationId" type="text" name="regarding" #regarding="ngModel" [(ngModel)]="activationModel.regarding">

        </mat-form-field>

        <mat-form-field style="width: 100%;">
            <mat-label>Select Provider</mat-label>
            <input type="text" placeholder="Select Provider" aria-label="provider" matInput
                [formControl]="providerControl" [matAutocomplete]="providerAutoControl">
            <mat-autocomplete #providerAutoControl="matAutocomplete">
                <mat-option *ngFor="let provider of filteredProviders | async" [value]="provider.full_name"
                    (onSelectionChange)="onSelectProvider($event, provider);">
                    {{provider.full_name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <div style="height: 400px;">
            <ckeditor [(ngModel)]="emailObject.email_body" name="email_body" class="h100"
                editorUrl="../../../../../assets/ckeditor/ckeditor.js" [readOnly]="!cke4Config.editable"
                [config]="cke4Config" type="divarea">
            </ckeditor>
        </div>
        <br>
        <div *ngIf="!facilityActivationId" class="flex items-center justify-between" style="width: 100%;">
            <div style="font-size: small;">
                <small *ngIf="!file">Add attachment
                </small>
            </div>
            <div>
                <input name="customer_approval_proof" (change)="onSelectFile($event)" hidden accept=".pdf" type="file"
                    id="customer_approval_proof" #customer_approval_proof />
                <small>{{fileName}}</small>
                <button *ngIf="!showRemoveFileButton" #file_uploader_btn class="dn-button-primary" mat-raised-button
                    type="button" (click)="customer_approval_proof.click()">Add
                    File</button>
                <button *ngIf="showRemoveFileButton" #file_remove_btn class="dn-button-primary"
                    style="background-color: red;" mat-raised-button type="button"
                    (click)="onDeselectFile(customer_approval_proof)">Remove
                    File</button>
            </div>
        </div>
        <br>
        <div *ngIf="!facilityActivationId" class="flex items-center justify-center gap-8" style="margin: auto;">
            <button mat-raised-button class="dn-button-secondary" mat-dialog-close type="button">Cancel</button>
            <button mat-raised-button [disabled]="!form2.form.valid || emailIds.length === 0" type="submit"
                class="dn-button-primary">Send</button>
            <button mat-raised-button type="button" class="dn-button-primary"
                (click)="savePccFacilityActivationNote()">Save Note</button>
        </div>

    </form>
</div>