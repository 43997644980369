<div >
    <mat-card class="icds">
        <div class="flex justify-between items-center">

            <mat-form-field style="color: black;max-width: 180px; margin-right: 10px;">
                <mat-label>Select Status</mat-label>
                <mat-select [(ngModel)]="selectedFilter" (selectionChange)="onChangeFilter($event);">
                    <mat-option value="All">
                        All
                    </mat-option>
                    <mat-option value="Active">
                        Active
                    </mat-option>
                    <mat-option value="Expired">
                        Expired
                    </mat-option>
                </mat-select>
            </mat-form-field>
    
            <app-input-with-dropdown class="flex-grow" [noDropdown]="true" placeholder="Type Code/Description And Press Enter"
                (inputChange)="setValueToBeSearched($event.target.value)" (keyup.enter)="searchValue()" [value]="valueToBeSearched"
                (keyup)="searchValue()">
            </app-input-with-dropdown>
            <mat-spinner *ngIf="isIcdLoaded" matSuffix [diameter]="18"
                style="float: right; margin-left: 10px"></mat-spinner>
            <button mat-raised-button class="dn-button-primary" (click)="searchValue()" style="margin-left: 2rem;"> Search
            </button>
            <button mat-raised-button class="dn-button-primary" (click)="addIcd()" style="margin-left: 0.5rem;" *ngIf="isHide != true"> Add ICD
            </button>
        </div>

        <div style="margin-top: 1rem;height: 77vh;overflow: auto;" class="flex justify-start items-start" >
            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="Code">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;">Code</th>
                    <td mat-cell *matCellDef="let element">
                        <div  [ngClass]="{'activeColor': element.pdpm_status == true}">
                            {{element.code}}
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;">Description</th>
                    <td mat-cell *matCellDef="let element">
                        <div  [ngClass]="{'activeColor': element.pdpm_status == true}">
                            {{element.description}}
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Medium Description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 30%;">Medium Description</th>
                    <td mat-cell *matCellDef="let element">
                            <div *ngIf="element.shouldUpdate; else elseBlock" [ngClass]="{'activeColor': element.pdpm_status == true}"></div>
                                <ng-template #elseBlock>
                                    <div [innerHTML]="element.mediumDescription" [ngClass]="{'activeColor': element.pdpm_status == true}"></div>
                                </ng-template>                        
                    </td>
                </ng-container> 
                <ng-container matColumnDef="Status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;">Status</th> 
                    <td mat-cell *matCellDef="let element">
                        <div [ngClass]="{'activeColor': element.status == 'Active', 'expiredColor': element.status == 'Expired'  }">
                            {{element.status}}
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Operation">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header-container></th>
                    <td mat-cell *matCellDef="let element; let i= index">
                        <a style="cursor: pointer" (click)="updateIcd(i, element)" *ngIf="!element.shouldUpdate">
                            <i class="icon icon-edit" matTooltip="Edit" matTooltipPosition="above" id="edit-icon"></i>
                        </a>
                        <a style="cursor: pointer; margin-left: 1rem;" (click)="deleteIcdAssociation(i, element);" *ngIf="isHide == true && !element.shouldDelete">
                            <i class="icon icon-delete" matTooltip="Remove Association" matTooltipPosition="above" id="delete-icon"></i>
                        </a>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-card>
</div>