import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-mips-questionnaire-tree-item',
  templateUrl: './mips-questionnaire-tree-item.component.html',
  styleUrls: ['./mips-questionnaire-tree-item.component.css']
})
export class MipsQuestionaireTreeItemComponent implements OnInit {
  @Input() questions:any[];
  @Output() onYesClick = new EventEmitter<any>()
  @Output() onNoClick = new EventEmitter<any>()

  questionsDone:any[] = [];
  constructor() { }

  ngOnInit(): void {
  }

  handleYesClick(question) {
    this.onYesClick.emit(question);
    this.questionsDone.push(question._id);
  }
  handleNoClick(question) {
    this.onNoClick.emit(question);
    this.questionsDone.push(question._id);
  }

  showControls(question) {
    if(question) {
      return this.questionsDone.indexOf(question._id) === -1
    }
  }

}
