<div [ngClass]="{'container': (companySide || user_dashboard) && !(nursingSide)}">

    <h3 class="dark-blue-heading">
        <ng-container *ngIf="!user_dashboard">
            <button type="button" style="height: min-content;" mat-raised-button class="dn-button-secondary"
                (click)="goBack()">
                Back
            </button>
        </ng-container>

        <label [ngClass]="{'label-margin': !user_dashboard}"> Discharged Patient Report </label>

        <button style="margin-left: auto;" mat-raised-button class="dn-button-primary" (click)="exportReportAsXLSX();">Export
            As XLSX
        </button>
    </h3>

    <div class="search-block theme-form flex justify-start items-center" 
        style="background-color: white; border-radius: 4px; padding-top: 8px; padding-bottom: 5px; padding-left: 8px;padding-right: 8px;">
        
        <div class="flex flex-grow justify-between items-center">
            <div class="flex justify-start items-center gap-2">
                <mat-label style="margin-right:20px;color:#999">Date of Creation </mat-label>
                <input style="width: 180px;" type="text" ngxDaterangepickerMd [(ngModel)]="filter.creationDate"
                    (datesUpdated)="changeCreationDate($event)" class="mat-like-input" [ranges]="preDateRanges"
                    [alwaysShowCalendars]="true" [maxDate]="currentDate" [linkedCalendars]="true"
                    [showClearButton]="true" />
                <mat-form-field appearance="fill" style="width: 100px;">
                    <mat-label>Select Facility Type</mat-label>
                    <mat-select [(ngModel)]="selectedFacilityType">
                        <mat-option *ngFor="let facility of facilityTypes" [value]="facility.value">
                            {{facility.type}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-raised-button class="dn-button-primary" (click)="handleApplyFilter();">Apply Filter
                </button>

                <button mat-raised-button class="dn-button-secondary" (click)="resetFilter();">Reset Filter
                </button>
            </div>

            <div>
                <button mat-raised-button class="dn-button-primary" (click)="goToPreviousPage()"
                    [disabled]="!pagination.hasPrevPage"> Prev</button>
                    
                <span>
                    {{pagination.skip + dischargePatientReport.length === 0 ? 0 : pagination.skip + 1 }} -
                    {{pagination.skip + dischargePatientReport.length}} out of {{pagination.totalRecords}}
                </span>

                <button mat-raised-button class="dn-button-primary" (click)="goToNextPage()"
                    [disabled]="!pagination.hasNextPage">Next</button>
            </div>
        </div>
    </div>

    <mat-card class="vertical-height">

        <div class="table-height">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{getFullName(element.first_name,element.last_name)}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="facility">
                    <th mat-header-cell *matHeaderCellDef> Facility </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.facility.title}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="dob">
                    <th mat-header-cell *matHeaderCellDef> DOB </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.date_of_birth | date }}
                    </td>
                </ng-container>


                <ng-container matColumnDef="mrn">
                    <th mat-header-cell *matHeaderCellDef> MRN </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.mrNo}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="active">
                    <th mat-header-cell *matHeaderCellDef> Active </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.activeDate | date}}
                    </td>
                </ng-container>


                <ng-container matColumnDef="inactive">
                    <th mat-header-cell *matHeaderCellDef> inActive </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.inActiveDate | date}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="days_diff">
                    <th mat-header-cell *matHeaderCellDef> Days Since Inactive </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.daysDiff}}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <ngx-ui-loader [loaderId]="loader_id"></ngx-ui-loader>
        </div>
    </mat-card>
</div>