<div class="container">
    <div class="flex flex-col gap-2">
        <mat-card style="height: 57px; margin-bottom: 10px; padding: 10px;">
    <mat-form-field style="width: 100%;" class="flex-grow">
        <input matInput type="text" name="phrase_key" #phrase_key="ngModel" [(ngModel)]="phrase.key"
           (blur)="checkError(phrase.key)"
            placeholder="Shortcut" required pattern="[a-z]+" onkeypress="return /[a-z]/.test(event.key)">
        <mat-error *ngIf="phrase_key.invalid">Please enter valid shortcut, it should not contains spaces or special
            characters</mat-error>
        </mat-form-field>
    </mat-card>
        <small *ngIf="uniqueKeyError"style="color: red; font-size: 11px">Please enter unique shortcut, that does not already exist.</small>
    <div style="height: 400px;">
        <ckeditor [(ngModel)]="phrase.value" name="notes_value" class="h100"
            editorUrl="../../../../assets/ckeditor/ckeditor.js" [readOnly]="!cke4Config.editable" [config]="cke4Config"
            type="divarea" (ready)="onReadyCkeditor($event)"></ckeditor>
    </div>

    <div class="flex justify-center items-center gap-2">
        <button class="dn-button-primary" mat-raised-button
            [disabled]="uniqueKeyError || !(phrase.value && phrase.key)" (click)="editPhrase(phrase.key, phrase.value)">Save</button>
    </div>
</div>
</div>