export class WoundChart {
    day: any
    area: any;
    volume: any;
    constructor(day,
        area = null,
        volume = null) {
        this.area = area;
        this.day = day;
        this.volume = volume;
    }
}
