import {Injectable} from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest,
  HttpEvent,
  HttpEventType,
  HttpErrorResponse
} from '@angular/common/http';
import * as global from '../global';
import moment from 'moment'
import {AuthService} from 'src/app/services/auth.service';
import {API_URL} from "../../../environments/api-url";
import { DashboardService } from '../dashboard/dashboard.service';

@Injectable({
  providedIn: 'root',
})
export class NoteEditorService {
  data: any; 
  public constructor(
      private httpclient: HttpClient,
      private _authService: AuthService,
      private dashboardService: DashboardService
  ) {
    this.data = this._authService.currentUser;
  }

  create(transcribt, category) {
    return this.httpclient
        .post(global.url + API_URL.NOTE.addTranscription, {transcribtion: transcribt}).pipe();
  }
  saveNote(note, censusIdForPatientSeen?, is_active?) {
    const _note = {...note};
    if(_note.audio) {
      _note.audio_id = _note.audio._id;
      delete _note.audio;
    }
    _note.provider = {
      _id: _note.provider._id
    };
    _note.patient = {
      _id: _note.patient._id
    };
    const details = {
      ...this._authService.authObject,
      note: _note,
      censusIdForPatientSeen,
      is_active
    }
    return this.httpclient
        .post(global.url + API_URL.NOTE.saveNote, {details: details}).pipe();
  }
  saveDraftNote(note, censusIdForPatientSeen?) {
    const _note = {...note};
    if(_note.audio) {
      _note.audio_id = _note.audio._id;
      delete _note.audio;
    }
    const details = {
      ...this._authService.authObject,
      note: _note,
      censusIdForPatientSeen
    }
    return this.httpclient
        .post(global.url + API_URL.NOTE.saveDraftNote, {details: details}).pipe();
  }
  saveMobileNote(note) {
    const _note = {...note};
    if(_note.audio) {
      _note.audio_id = _note.audio._id;
      delete _note.audio;
    }
    const details = {
      note: _note
    }
    return this.httpclient
        .post(global.url + API_URL.NOTE.saveMobileNote, {details: details}).pipe();
  }
  submitForReviewNote(noteId) {
    const details = {
      ...this._authService.authObject,
      noteId
    }
    return this.httpclient
        .post(global.url + API_URL.NOTE.submitForReviewNote, {details: details}).pipe();
  }
  coSignNote(note, cosigned_to = null, cosigned_by = null, status=false) {
    const details = {
      ...this._authService.authObject,
      note, 
      cosigned_to,
      cosigned_by,
      status
    }
    return this.httpclient
        .post(global.url + API_URL.NOTE.coSignNote, {details: details}).pipe();
  }

  revertNote(noteId) {
    const details = {
      ...this._authService.authObject,
      noteId
    }
    return this.httpclient
        .post(global.url + API_URL.NOTE.revertNote, {details: details}).pipe();
  }

  // signNote(noteId, hasPCCPatientAndLoggedInToPCC) {
  signNote(noteId, hasLoggedInToPCC) {
    const details = {
      ...this._authService.authObject,
      noteId,
      hasLoggedInToPCC
      // hasPCCPatientAndLoggedInToPCC
    }
    return this.httpclient
        .post(global.url + API_URL.NOTE.signNote, {details: details}).pipe();
  }

  uploadNote(noteId) {
    const details = {
      ...this._authService.authObject,
      noteId
    }
    return this.httpclient
        .post(global.url + API_URL.NOTE.uploadNote, {details: details}).pipe();
  }

  updateExisting(transcribt, category) {
    const details = {
      ...this._authService.authObject,

      ...transcribt
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.updateTranscriptionText, {transcribtion: details}).pipe();
  }

  signTranscription(transcribt, category) {
    const details = {
      id: transcribt.id,
      auth_key: transcribt.auth_key,
      usertype: transcribt.usertype,

      transcribtion_id: transcribt.transcribtion_id,
      is_signed: transcribt.is_signed
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.updateTranscriptionStatus, {transcribtion: details}).pipe();
  }
  signAndUploadTranscription(transcribt, category) {
    const details = {
      id: transcribt.id,
      auth_key: transcribt.auth_key,
      usertype: transcribt.usertype,

      transcribtion_id: transcribt.transcribtion_id,
      is_signed: transcribt.is_signed,
      is_completed: transcribt.is_completed,
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.updateTranscriptionStatus, {transcribtion: details}).pipe();
  }

  unSignTranscription(transcribt, category) {
    transcribt.is_signed = 'false';

    const details = {
      id: transcribt.id,
      auth_key: transcribt.auth_key,
      usertype: transcribt.usertype,

      transcribtion_id: transcribt.transcribtion_id,
      is_signed: transcribt.is_signed
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.updateTranscriptionStatus, {transcribtion: details}).pipe();
  }

  completeTranscription(transcribt) {

    const details = {
      id: transcribt.id,
      auth_key: transcribt.auth_key,
      usertype: transcribt.usertype,

      transcribtion_id: transcribt.transcribtion_id,
      is_completed: transcribt.is_completed
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.updateTranscriptionStatus, {transcribtion: details}).pipe();
  }

  publishTranscription(transcribt, category) {
    
    const details = {
      ...this._authService.authObject,

      ...transcribt
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.updateTranscriptionStatus, {transcribtion: details}).pipe();

  }

  /*
  * Publishing Transcription in database directly #mohsin-dev
  */
  unpublishTranscription(transcribt, category) {
    console.log('Unublishing transcription', category, transcribt);
    transcribt.is_published = 'false';
    const details = {
      id: transcribt.id,
      auth_key: transcribt.auth_key,
      usertype: transcribt.usertype,

      transcribtion_id: transcribt.transcribtion_id,
      is_published: transcribt.is_published
    };
    return this.httpclient.post(global.url + '/updateTranscriptionStatus', {transcribtion: details}).pipe();
  }


  deleteTranscription(transcribt, category) {
    transcribt.is_signed = 'false';

    const details = {
      id: transcribt.id,
      auth_key: transcribt.auth_key,
      usertype: transcribt.usertype,

      transcribtion_id: transcribt.transcribtion_id,
      is_active: transcribt.is_active
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.deleteTranscription, {transcribtion: details}).pipe();
  }


  createAndUploadDocs(detail) {
    var user = {
      id: this.data._id,
      auth_key: this.data.auth_key,
      usertype: this.data.user_type
    }
    return this.httpclient.post(global.url + '/createAndUploadDocs', {details: detail, user: user}).pipe();
  }

  getDocFileContent(path) {
    var user = {
      id: this.data._id,
      auth_key: this.data.auth_key,
      usertype: this.data.user_type
    }
    return this.httpclient.post(global.url + '/getDocFileContent', {path: path, user: user}).pipe();
  }

  getDoctorsList() {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null
    };
    item.id = this.data._id;
    item.auth_key = this.data.auth_key;
    item.usertype = this.data.user_type;
    return this.httpclient
        .post(global.url + API_URL.USER.getDoctorsList, {details: item}).pipe();
  }

  getAllProviders() {
    let item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null
    };
    item.id = this.data._id;
    item.auth_key = this.data.auth_key;
    item.usertype = this.data.user_type;
    return this.httpclient.post(global.url + API_URL.USER.getAllProviders, {details: item});
  }

  getAssociatedDoctorsList(company = null) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      company_id: <string>null
    };
    item.id = this.data._id;
    item.auth_key = this.data.auth_key;
    item.usertype = this.data.user_type;
    item.company_id = this.data.company_id;
    return this.httpclient
        .post(global.url + API_URL.USER.getAssociatedDoctorsList, {details: item}).pipe();
  }

  getPatients(item) {
    return this.httpclient
        .post(global.url + API_URL.PATIENT.getPatients, {patient: item}).pipe();
  }

  getPatient(item) {
    return this.httpclient
        .post(global.url + API_URL.PATIENT.getPatient, {patient: item}).pipe();
  }

  /*
  * Used to initialize facility select input #mohsin-dev
  */
  getFacilities(doctor_id = null) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      doctor_id: <string>null,
      company_id: <string>null
    };
    item.id = this.data._id;
    item.auth_key = this.data.auth_key;
    item.usertype = this.data.user_type;
    item.doctor_id = doctor_id;
    item.company_id = this.data.company_id
    return this.httpclient
        .post(global.url + API_URL.FACILITY.getMyAddress, {address: item}).pipe();
  }

  addendumNote(data) {

    const details = {
      ...this._authService.authObject,

      ...data
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.addendumNote, {details}).pipe();
  }

  getNoteAddendums(data) {

    const details = {
      ...this._authService.authObject,

      ...data
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.getNoteAddendums, {details}).pipe();
  }
  previewNoteAsPCC(data) {

    const details = {
      ...this._authService.authObject,

      ...data
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.previewNoteAsPCC, {details}).pipe();
  }
  getFacilityPatients(facility_id, patientName=null) {
      const patient = {
        ...this._authService.authObject,
          facility_id,
          patientName
      }

      return this.httpclient
          .post(global.url + API_URL.PATIENT.getFacilityPatients, {patient}).pipe();
  }
  getFacilityPatientsByProviderId(provider_id, facility_id) {
      const patient = {
        ...this._authService.authObject,
          provider_id,
          facility_id,
      }

      return this.httpclient
          .post(global.url + API_URL.PATIENT.getFacilityPatientsByProviderId, {patient}).pipe();
  }
  getNoteById(_id) {
    const details = {
      ...this._authService.authObject,
        _id: _id
    };
    return this.httpclient.post(global.url + API_URL.NOTE.getNoteById, {details: details}).pipe();
  }
  setNoteLock(_id,userId) {
    const details = {
      ...this._authService.authObject,
        id: _id,
        userId: userId
    };
    return this.httpclient.post(global.url + API_URL.NOTE.setNoteLock, {details: details}).pipe();
  }
  generatePDFAndDownload(transcriptionData){
    var details = { 
      ...this._authService.authObject,
      transText: transcriptionData.toString()
    };
    return this.dashboardService.downloadFile(global.url + API_URL.NOTE.generatePDFAndDownload,  details ).pipe();
  }
  generatePDFsAndEmail(emails, noteIDs, provider, facility){
    var details = { 
      // ...this._authService.authObject,
      ...this._authService.authObject,
      emails,
      noteIDs,
      provider,
      facility,
      // transText: transcriptionData.toString()
    };
    return this.httpclient.post(global.url + API_URL.NOTE.generatePDFsAndEmail,   {details: details} ).pipe();
  }
  getMobileTranscription(note_id) {
    const details = {
      note_id: note_id
    };
    return this.httpclient.post(global.url + API_URL.NOTE.getMobileTranscription, {details: details}).pipe();
  }
  getDraftNoteByUserId() {
    const details = {
      ...this._authService.authObject
    };
    return this.httpclient.post(global.url + API_URL.NOTE.getDraftNoteByUserId, {details: details}).pipe();
  }

  getCC(ccName) {
    const data = {
      'cc1': {
        "@assessment": ['Assessment 1','Assessment 2'],
        "@hpi": ['Hpi 1', 'Hpi 2'],
        "@pe": ['Pe 1', 'Pe 2'],
        "@ros": ['Ros 1', 'Ros 2'],
      },
      'cc2': {
        "@assessment": ['Assessment 2.1','Assessment 2.2'],
        "@hpi": ['Hpi 2.1', 'Hpi 2.2'],
        "@pe": ['Pe 2.1', 'Pe 2.2'],
        "@ros": ['Ros 2.1', 'Ros 2.2'],
      }
    };
    return data[ccName];
  }

  applyDateTimezone(note, date) {
      return moment(new Date(date).toLocaleString('en-US', { timeZone:  note.dates.timezone })).toDate() //.format('MMM DD, YYYY h:mm a');
  }
  
  getFollowUpNoteAsJson(patientId, providerId) {
    const details = {
      ...this._authService.authObject,
      patientId, providerId
    };
    return this.httpclient.post(global.url + API_URL.NOTE.getFollowUpNoteAsJson, {details: details}).pipe();
  }
  updateNoteEditLock(note_id) {
    const details = {
      ...this._authService.authObject,
      note_id
    };
    return this.httpclient.post(global.url + API_URL.NOTE.updateNoteEditLock, {details: details}).pipe();
  }
}

