import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { lastValueFrom, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { WoundService } from 'src/app/services/wound.service';
import { ToastrService } from 'ngx-toastr';
import { HttpResponse } from '@angular/common/http';
import moment from 'moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PatientListService } from 'src/app/includes/patient-list/patient-list-service';


@Component({
  selector: 'app-tissue-analytic-pdf-report',
  templateUrl: './tissue-analytic-report.component.html',
  styleUrls: ['./tissue-analytic-report.component.css']
})
export class TissueAnalyticPDFReportComponent implements OnInit {
  loader_id = "app-patient-discharge-report";
  @Input() wound: any;
  @Input() patient: any;

  constructor(
    // private _facilityService: FacilityService,
    private loader: NgxUiLoaderService,
    private _location: Location,
  ) {

  }
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////All Comment Code is for facility filter///////////////// 
  //////////////////////////////////////////////////////////////////////////////
  ngOnInit() {
    if (!this.wound.width) {
      this.wound.width = 0;
    }
    if (!this.wound.length) {
      this.wound.length = 0;
    }
    if (!this.wound.depth) {
      this.wound.depth = 0;
    }
    // this.patient.date_of_birth = new Date(this.patient.date_of_birth)
    // console.log('selected patient', this.patient.date_of_birth);
  }
  
  getUndermining(wound) {
    if(wound.under_mining) {
        return `from ${wound.undermining_from} o'clock to ${wound.undermining_to} o'clock, ${wound.undermining_distance} cm`
    }
    else {
        return '';
    }
  }

  getTunneling(wound) {
    if(wound.tunneling) {
        return `${wound.tunneling_direction} o'clock, ${wound.tunneling_distance} cm`
    }
    else {
        return '';
    }
  }

  getFormattedDate(date) {
    if (!date) {
      return '--';
    }
    let date1: any = date.toString();
    if (date1.includes('Z')) {
      let date2 = date1.split('T');
      
      let date3 = date2[0].split('-');
      
      return date3[1] + '/' + date3[2] + '/' + date3[0];

    }
    else {
      return moment(date).utc().tz(this.patient.timeZone).format('MM/DD/YYYY')

    }
    // return new Date(date).toLocaleString('en-US', {timeZone: this.patient.timeZone});
  }
}
