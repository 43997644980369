import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import * as global from '../../global';
import { Subscription, Observable, Subject, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from "../../../../environments/api-url";
import { CommonService } from 'src/app/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class MergeFacilityService {
  currentUser: any;

  public constructor(
    private httpclient: HttpClient,
    private commonService: CommonService,
    private _authService: AuthService) {
    this.currentUser = this._authService.currentUser;
  }

  searchTheFacilityById(facility_id) {
    let company_id
    this.commonService.castCompany.subscribe(company => { company_id = company?._id })
    if (!company_id) company_id = this.currentUser.company_id;
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      company_id: company_id,
      facility_id: facility_id
    };
    return this.httpclient.post(global.url + API_URL.MERGE_FACILITIES.searchTheFacilityById, { details: item }).pipe();
  }

  getMergingAudit(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };
    return this.httpclient.post(global.url + API_URL.MERGE_FACILITY.getMergingAudit, { details: item }).pipe();
  }

  mergeTheFacilityData(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };
    return this.httpclient.post(global.url + API_URL.MERGE_FACILITIES.mergeTheFacilityData, { details: item }).pipe();
  }

  revertTheFacilityData(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };
    return this.httpclient.post(global.url + API_URL.MERGE_FACILITIES.revertTheFacilityData, { details: item }).pipe();
  }

}