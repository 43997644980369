import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse,} from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../../../../includes/global';
import { API_URL } from 'src/environments/api-url';

@Injectable({
    providedIn: 'root'
})
export class DetailReportService {
    private _currentUser;

    constructor(private httpclient: HttpClient,private _authService:AuthService) {
        this._currentUser = this._authService.currentUser;
    }

    updateDocumentPage(documentPage) {
        const details = {
            id: this._currentUser._id,
            auth_key: this._currentUser.auth_key,
            usertype: this._currentUser.user_type,

            ...documentPage
        };
        // return this.httpclient.post(global.rrgen + '/updateDocumentPage', {details: details}).pipe();
        return this.httpclient.post(global.url + API_URL.RRGEN.updateDocumentPage, {details: details}).pipe();
    }
    // generateReport(reportDocument)
    // {
    //     const details = {
    //         id: this._currentUser._id,
    //         auth_key: this._currentUser.auth_key,
    //         usertype: this._currentUser.user_type,
    //          ... reportDocument
    //     }
    //     return this.httpclient.post(global.rrgen + '/generateReport', {details: details}).pipe();
    //     // console.log("Details for report Generate: ", details);
        
    // }

   
}
 