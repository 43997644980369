import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader'; 
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { ChargesCatagoryService } from './charges-catagory.service'; 
@Component({
  selector: 'app-charges-catagories-dialog',
  templateUrl: './charges-catagories-dialog.component.html',
  styleUrls: ['./charges-catagories-dialog.component.css']
})
export class ChargesCatagoriesDialogComponent implements OnInit {

  displayedColumns = ['title','type', 'assoc_pro', 'providers', 'operations']
  dataSource: any;
  filteredProvider: Observable<string[]>;
  providerControl = new FormControl();
  // comment and uncomment if to use both categories
  // categoryTypes = ['CPT', 'ICD']
  categoryTypes = ['CPT' ]
  selectedCategoryType:any;
  categories: any;
  allProviders:any;
  selectedProvider:any;
  loading:Boolean = true;
  update: Boolean = false;
  create: Boolean = true;
  updatingCategory:any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true } as any) paginator: MatPaginator;
  @ViewChild('providerAutoComplete') matAutocomplete: MatAutocomplete;
  category: String; 
  visible = true;
  selectable = true;
  removable = true;
  loaderId = 'loader_id';
  separatorKeysCodes: number[] = [ENTER, COMMA]; 
  currentUser:any; 
  company_id:any;
    displayProviderFn(pro): string {
        return pro;
    }
    companyPath:Boolean = false;

  constructor(
    private chargesCatagoryService: ChargesCatagoryService,
    public loader: NgxUiLoaderService,
    private router: Router,
    private route: ActivatedRoute,
    private authServise: AuthService,
    public dialogRef: MatDialogRef<ChargesCatagoriesDialogComponent>,
    public services: UserTypesRelationshipService,
    private _commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public company: any
  ) { 
    this.currentUser = this.authServise.currentUser;
  } 
  async ngOnInit() { 
    if(this.company){
      this.company_id = this.company.company_id
      // 
    }
    else{
      await this._commonService.castCompany.subscribe(comapny=>{
        this.company_id = comapny._id;
        
      })
    }
    
    // this.company_id = this.currentUser.company_id;
    this.selectedCategoryType = this.categoryTypes['0'];
    // await this.initCategories();
    await this.initProviders();
    this.filteredProvider = this.providerControl.valueChanges.pipe(
      startWith(''),
      map((value:any) => this.filterProvider(value)));
    this.companyPath = window.location.pathname.includes('company'); 
  }
  private filterProvider(value: string): string[] {
    if (value) {
        const filterValue = value.toLowerCase();
        return this.allProviders.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    else {
        return this.allProviders;
    }
}

  async initCategories() {
    this.loader.startLoader('loader_id'); 
    let created_by = this.currentUser._id;
    const data = await this.chargesCatagoryService.getCategoryRealtion(created_by, this.company_id).subscribe((response: any) => {
      if (response.status == 200) {
        this.categories = response.data;
        this.dataSource = new MatTableDataSource(this.categories);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.selectedProvider = null;
        this.loader.stopLoader('loader_id');
      }
    })

  }
  async initProviders(){
    this.loading = true;  
    let result = await this.services.getDoctorsList('true',this.company_id ).subscribe(async (response:any)=>{
      if(response.status == 200){
        this.allProviders = response.data;
        this.allProviders = [...this.allProviders.map(pro => ({...pro, name: `${pro.first_name} ${pro.last_name}, ${pro.title}`}))]
        this.loading = false;
        await this.initCategories();
      }
    })
  }

  applyFilter(filterValue: string) {
    if (filterValue) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      this.dataSource.filterPredicate = function (data, filter: string): boolean {
        return data.title.toLowerCase().includes(filter) ;
      };
    }
    else {
      this.initCategories();
    }
  }
  async createCategory() {
    const result = await this.chargesCatagoryService.createCategory(this.category, this.selectedCategoryType, this.company_id).subscribe((response: any) => {
      if (response.status == 200) {
        this.category = '';
        this.selectedCategoryType = null
        this.initCategories();
      }
    })
  }
  async onDeleteClick(element) {
    let cr_id = element._id; //cr=>category realtion 
    let result = await this.chargesCatagoryService.deleteCategoryRealtion(cr_id).subscribe((res: any)=>{
      if(res.status == 200){
        this.initCategories()
      }
    })
  }
  async updateCategory(){
    let cr_id = this.updatingCategory._id; //cr=>category realtion 
    const result = await this.chargesCatagoryService.updateCategory(this.category, this.selectedCategoryType, cr_id).subscribe((response: any) => {
      if (response.status == 200) {
        this.category = '';
        this.selectedCategoryType = null
        this.update = false;
        this.create = true;
        this.initCategories();
      }
    })
  }
  onEditClick(element) { 
    this.category = element.title;
    this.selectedCategoryType = element.type;
    this.updatingCategory = element;
    this.update = true;
    this.create = false;
  } 
  async updateRealtion(provider, element){ 
    let cr_id = element._id; //cr=>category realtion 
    let add = true;
    let result = await this.chargesCatagoryService.updateCategoryRealtion(provider._id, cr_id, add).subscribe((response:any)=>{
      if(response.status == 200){
        this.initCategories();
        
      }
    })
  }  

  async removeProvider(provider , element) { 
    let cr_id = element._id; //cr=>category realtion
    let add = false;
    let result = await this.chargesCatagoryService.updateCategoryRealtion(provider._id, cr_id, add).subscribe((response:any)=>{
      this.initCategories();
    })
  } 
  manageCategories(){
    this.dialogRef.close();
    if(this.companyPath){
      this._commonService.setCompanySelected(this.company_id)
      this.router.navigateByUrl(`/company/${this.company_id}/manage-category`);
    }
    else{
      this.router.navigateByUrl(`/admin/manage-category`);
    }
  }
  getProviderName(provider){
    if(provider){
      return provider.title + ' ' + provider.first_name + ' ' + provider.last_name
    }
    else{
      return ''
    }
  }
  allowOnlyText(event): boolean{
    const charCode = (event.which) ? event.which : event.keyCode;
       if (charCode > 90 && charCode < 97 ) {
         return false;
       }
    
    if(charCode < 65 || charCode > 122){
    return false;
    } 
       return true;
   }

}
