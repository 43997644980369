<div class="wound-care-widget flex flex-col">
    <div class="flex-col gap-2" *ngIf="patient">
        <mat-tab-group *ngIf="currentUser.user_type === this.global.USER_TYPE.DOCTOR" [(selectedIndex)]="tabIndex" (selectedTabChange)="onTabChanged($event);">
            <mat-tab label="PROVIDER({{totalProviderWounds}})">
                <app-wound-care-widget *ngIf="tabIndex === 0 && allDataLoaded" [configuration]="configuration" [woundStateCounts]="providerWoundStateCounts"
                    [note]="note" [patient]="note.patient" [provider]="note.provider" [created_userType]="'1'"
                    (onICDsSave)="handleICDsSave($event)" (woundDetailsChange)="handleWoundDetailsChange($event)"
                    (checkallWoundAssessmentPhrase)="handlecheckallWoundAssessmentPhrase($event)"></app-wound-care-widget>
            </mat-tab>
            <mat-tab label="SNF WOUND NURSE({{totalNurserWounds}})">
                <app-wound-care-widget *ngIf="tabIndex === 1 && allDataLoaded" [configuration]="configuration" [woundStateCounts]="nurseWoundStateCounts"
                    [note]="note" [patient]="note.patient" [provider]="note.provider" [created_userType]="'snf_wc_nurse'"
                    (onICDsSave)="handleICDsSave($event)" (woundDetailsChange)="handleWoundDetailsChange($event)"
                    (checkallWoundAssessmentPhrase)="handlecheckallWoundAssessmentPhrase($event)"></app-wound-care-widget>
            </mat-tab>
        </mat-tab-group>
        <mat-tab-group *ngIf="currentUser.user_type === this.global.USER_TYPE.SNF_WC_NURSE" (selectedTabChange)="onTabChanged($event);">
            <mat-tab label="SNF WOUND NURSE({{totalNurserWounds}})">
                <app-wound-care-widget *ngIf="tabIndex === 0 && allDataLoaded" [configuration]="configuration" [woundStateCounts]="nurseWoundStateCounts"
                    [note]="note" [patient]="note.patient" [provider]="note.provider" [created_userType]="'snf_wc_nurse'"
                    (onICDsSave)="handleICDsSave($event)" (woundDetailsChange)="handleWoundDetailsChange($event)"
                    (checkallWoundAssessmentPhrase)="handlecheckallWoundAssessmentPhrase($event)"></app-wound-care-widget>
            </mat-tab>
            <mat-tab label="PROVIDER({{totalProviderWounds}})">
                <app-wound-care-widget *ngIf="tabIndex === 1 && allDataLoaded" [configuration]="configuration" [woundStateCounts]="providerWoundStateCounts"
                    [note]="note" [patient]="note.patient" [provider]="note.provider" [created_userType]="'1'"
                    (onICDsSave)="handleICDsSave($event)" (woundDetailsChange)="handleWoundDetailsChange($event)"
                    (checkallWoundAssessmentPhrase)="handlecheckallWoundAssessmentPhrase($event)"></app-wound-care-widget>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>