import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-manage-my-companies',
  templateUrl: './manage-my-companies.component.html',
  styleUrls: ['./manage-my-companies.component.css']
})
export class ManageMyCompaniesComponent implements OnInit {

  dataForMyCompanies: any;
  currentUser: any;
  constructor(
    private _authService: AuthService,
  ) {
    this.currentUser = this._authService.currentUser;

    this.dataForMyCompanies = {
      assosiation: true,
      adminId: this.currentUser._id,
      parentCompanyId: this.currentUser.company_id
    }
  }

  ngOnInit(): void {

  }
}
