import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment'
import { ToastrService } from 'ngx-toastr';
import { PatientDetailService } from '../../patient-detail.service';


@Component({
  selector: 'app-create-non-pcc-allergy-intolerances-dialog',
  templateUrl: './create-non-pcc-allergy-intolerances-dialog.component.html',
  styleUrls: ['./create-non-pcc-allergy-intolerances-dialog.component.css']
})
export class CreateNonPccAllergyIntolerancesDialogComponent implements OnInit {

  model: any = {
    allergen: null,
    onsetDate: moment().toDate(),
    type: "allergy",
    reactionType: null,
    patientId: null
  };
  constructor(
    private dialogRef: MatDialogRef<CreateNonPccAllergyIntolerancesDialogComponent>,
    private _toastr: ToastrService,
    private dialog: MatDialog,
    private _patientDetailService: PatientDetailService,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) {
    this.model.patientId = this.data.patientId;
    this.model.patientAllergyId = this.data.patientAllergyId;
  }

  ngOnInit(): void {
    if (this.model.patientAllergyId) {
      this.initPatientAllergies();
    }
  }
  initPatientAllergies() {
    const filter = {
      patientAllergyId: this.model.patientAllergyId
    }
    this._patientDetailService.getPatientAllergies(filter).subscribe((response: any) => {
      if (response.status === 200) {
        this.model.allergen = response.data.allergen;
        this.model.onsetDate = response.data.onset_date_string ? moment(response.data.onset_date_string) : response.data.onset_date;
        this.model.reactionType = response.data.reaction_type;
        this.model.type = response.data.type;
        // this.allergyIntolerances = response.data;
      } else {
        this._toastr.error('Something went wrong while fetching Allergy Intolerances data');
      }
    }, (error) => {
      this._toastr.error(error, 'Something went wrong while fetching Allergy Intolerances data');
    }, () => {
      // this._loader.stopLoader(this.loaderId);
    });
  }
  onSetDateChange($event) {  
    // const onsetDateString = `${this.model.onsetDate.format('YYYY/MM/DD ')}`;
    this.model.onsetDateString = `${this.model.onsetDate.format('YYYY/MM/DD')}`;
  }
  submitForm() {
    // this.model.timezone = moment.tz.guess();
    if (this.model.patientAllergyId) {
      delete this.model.patientId;
      this._patientDetailService.updatePatientAllergies(this.model).subscribe((response: any) => {
        if (response.status === 200) {
          this.dialogRef.close(response.data);
          this._toastr.success("Saved", "Success");
        } else {
          this._toastr.error('Something went wrong while fetching Allergy Intolerances data');
        }
      }, (error) => {
        this._toastr.error(error, 'Something went wrong while fetching Allergy Intolerances data');
      }, () => {
        // this._loader.stopLoader(this.loaderId);
      });
    }
    else {
      this._patientDetailService.addPatientAllergies(this.model).subscribe((response: any) => {
        if (response.status === 200) {
          this._patientDetailService.onChangePatientAllergy(response.data);
          this._toastr.success("Saved", "Success");
          this.dialogRef.close();
        } else {
          this._toastr.error('Something went wrong while fetching Allergy Intolerances data');
        }
      }, (error) => {
        this._toastr.error(error, 'Something went wrong while fetching Allergy Intolerances data');
      }, () => {
        // this._loader.stopLoader(this.loaderId);
      });
    }
  }

}
