<div class="flex">
    <app-input-with-dropdown class="flex-grow" [noDropdown]="true" placeholder="Search Provider"
        (inputChange)="applyFilter($event.target.value)">
    </app-input-with-dropdown>
</div>
<div style="height: 10px;"></div>
<div style="height: 69vh;overflow: auto;">
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="providers">
            <th mat-header-cell *matHeaderCellDef style="width: 20%;">Provider</th>
            <td mat-cell *matCellDef="let element">{{element.full_name}}</td>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </ng-container>
    
        <ng-container matColumnDef="business_officer">
            <th mat-header-cell *matHeaderCellDef> Business Officers </th>
            <td mat-cell *matCellDef="let element" style="width: 60%">
                <app-mat-multi-select-chips [element]="element" [ngModelData]="element.assoc_bo_ids || []" [isProvider]="true" [isMulti]="true" [allData]="businessOfficers" [providerKey]="'assoc_bo_ids'" [selectAll]="true" (onDataSelection)="onDataSelection($event)" (onDataRemove)="onDataRemove($event)"></app-mat-multi-select-chips> 
            </td>
        </ng-container>
    
    </table>
</div>