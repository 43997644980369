<div class="flex flex-col">
    <div class="flex items-center gap-2" style="padding-left: 5px;">
        <mat-icon style="cursor: pointer;" (click)="goBack()">close</mat-icon>
        <div class="flex justify-between items-center">
            <h1 style="display: inline-block;font-size: 22px; margin: 0px;">
                {{rowData.title}}
            </h1>
        </div>
    </div>
    <div class="flex justify-between items-center" style="padding-right: 10px;padding-left: 10px;">
        <span style="font-size: 13px;">{{rowData.updatedAt | date}}</span>
        <span style="font-size: 13px;"
            [ngClass]="{'lowPriority':rowData.priority === 'Low','mediumPriority':rowData.priority === 'Medium','highPriority':rowData.priority === 'High','criticalPriority':rowData.priority === 'Critical'}">{{rowData.priority}}</span>
        <div class="patients-page" style="margin-top: 10px;">
            <mat-form-field style="width: 110px;">
                <mat-select [(ngModel)]="rowData.status" (selectionChange)="changeStatus($event)">
                    <mat-option disabled value="">Ticket Status</mat-option>
                    <mat-option value="pending">Pending</mat-option>
                    <mat-option value="In progress">In progress</mat-option>
                    <mat-option value="Completed">Completed</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="flex flex-row">
        <div style="margin-top: 20px;">
            <span>
                <b style="padding-left: 10px; font-size: 12px;">Patient Name: </b>
                <span style="font-size: 12px; padding-left: 5px;" [innerHtml]="rowData.patient_name"></span>
            </span>
        </div>
    </div>
    <div class="flex flex-row">
        <div style="margin-top: 20px;word-break: break-all;" class="flex flex-col">
            <span style="display: inline-block; margin: 0px;font-size: 12px;font-weight: bold;">
                <i style="padding-left: 10px;">Description</i>
            </span>
            <span style="font-size: 12px;padding-right: 10px;padding-left: 10px;" [innerHtml]="rowData.description">
            </span>
        </div>
    </div>
    <div
        style="background-color: #44597d;width: 100.7%;color: #fff;padding: 0px 0px 4px 10px;margin: 0px 0px 0px -10px;">
        <span style="font-size: 11px;margin-left: 10px;">Comments</span>
    </div>
    
            <app-ticket-comments style="display: inline-block;" (edit)="comments=$event.comment; editComment=$event" [rowData]="rowData">
            </app-ticket-comments>
    
    <div class="flex flex-row" style="position: fixed;bottom: 0px;">
        <form (ngSubmit)="postComments()" #commentForm="ngForm" style="width: 100%;">
            <div style="background-color: white;width: 382px;padding: 10px;" class="flex flex-col">
                <div class="flex flex-row">
                    <mat-form-field class="example-full-width flex-grow" style="color: black !important;"
                        appearance="fill">
                        <input matInput type="text" name="comment" [(ngModel)]="comments" placeholder="Add a comment">
                    </mat-form-field>
                </div>
                <div class="flex justify-end items-center">
                    <button class="dn-button-primary" type="submit" style="width: 70px;margin-bottom: 5px;"
                        [disabled]="comments=='' || comments==undefined || isPostEnabled" mat-raised-button>Post</button>
                </div>
            </div>
        </form>
    </div>
</div>