import { filter } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { ToastrService } from 'ngx-toastr';
import { ProviderProviderAssociationService } from '../provider-provider-association.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/Modules/user.service';
import { USER_TYPE } from '../../global';
import { lastValueFrom } from 'rxjs';



@Component({
  selector: 'app-provider-provider-association',
  templateUrl: './provider-provider-association.component.html',
  styleUrls: ['./provider-provider-association.component.css']
})
export class ProviderProviderAssociationComponent implements OnInit {
  public response: any;
  public relationData: any;
  public dataSource: any;
  public providers: any = [];
  public associated_providers: any = [];
  providersName: any = [];
  selectedProviders: any = [];
  providerTranscriber: Boolean = true;
  providerProvider: Boolean = true;
  providerDropdownSettings: any = {};
  assigned: Boolean = false;
  company_id: any;
  public counter = 0;
  public dropDProviders: any = [];
  displayedColumns2: string[] = ['provider_name', 'asso_provider_name'];
  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    PAGE_LIMIT: 10,
    skip: 0
  };
  userNameRegExp: any = "";
  isProviderLoaded: boolean = false;
  timeoutId: any;
  constructor(
    public services: ProviderProviderAssociationService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private userService: UserService,
    ) {
    this.providerDropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'full_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: true,
    };
  }

  async ngOnInit() {
    if (window.location.pathname.includes('company')) {
      this.company_id = this.route.parent.snapshot.params.id
    }
    else {
      this.company_id = '';
    }
    await this.loadDataForProvider();
  }
  getProvidersNameDropDown(element) {
    let array = [];
    this.providers.forEach(pro => {
      if (pro._id !== element) {
        array.push(pro);
      }
    });
    return array;
  }

  getEmailId(element) {
    return element?.email ? element.email : '';
  }

  applyFilter(filterValue: string) {
    let filter = filterValue.trim().toLowerCase();
    if (filter) {
      let filterVal = [];
      this.providers.forEach(provider => {
        if (provider.full_name.trim().toLowerCase().includes(filter)) {
          filterVal.push(provider)
          this.dataSource = filterVal;
        }
      });
    }
    else {
      this.dataSource = this.providers;
    }
  }

  searchProviders() {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.isProviderLoaded = true;
      this.loadDataForProvider();
    }, 1000);
  }

  handlePagination() {
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    if (this.pagination.skip + this.providers.length < this.pagination.totalRecords) {
      this.pagination.hasNextPage = true;
    }
    else {
      this.pagination.hasNextPage = false;
    }
    if (this.pagination.skip > 0) {
      this.pagination.hasPrevPage = true;
    }
    else {
      this.pagination.hasPrevPage = false;
    }
  }

  goToPreviousPage() {
    this.pagination.pageNo--;
    this.pagination.hasPrevPage = false;
    this.loadDataForProvider();
  }
  goToNextPage() {
    this.pagination.pageNo++;
    this.pagination.hasNextPage = false;
    this.loadDataForProvider();
  }


  async loadDataForProvider() {
    let pageNo = this.pagination.pageNo;
    if (this.userNameRegExp !== '') {
      pageNo = null;
    }
    const filter: any = {
      company_id: this.company_id,
      pageNo,
      userNameRegExp: this.userNameRegExp,
      pageLimit: this.pagination.PAGE_LIMIT
    };
    const projection = {
      'title': 1,
      'first_name': 1,
      'last_name': 1,
      'full_name': 1,
      'company_id':1
    }
    await this.services.getAllSameCompanyProviders(filter, projection).subscribe((res: any) => {
      this.getAllProviders();
      this.isProviderLoaded = false;
      this.providers = res.data;
      this.pagination.totalRecords = res.totalRecords;
      if (this.userNameRegExp !== "") {
        this.pagination.totalRecords = res.totalRecords;
        this.pagination.hasNextPage = false;
        this.pagination.hasPrevPage = false;
        this.pagination.skip = 0;
      }
      if (this.userNameRegExp === "") {
        this.handlePagination();
      }
      this.assigned = true;
    });
  }
  async getAllProviders() {
    const company_id = this.company_id;
    const filter = {
      is_activated: 'true',
      user_type: USER_TYPE.DOCTOR,
      company_id,
    }
    const projection = {
      'account_type': 1,
      'first_name': 1,
      'last_name': 1,
      'title': 1,
      'company_id':1
    }
    const companyUsersResponse: any = await lastValueFrom(this.userService.getUsers(filter, projection));
    if (companyUsersResponse.status === 200) {
      await this.getP2PRelations();
      this.associated_providers = companyUsersResponse.data;
      this.associated_providers.forEach(element => {
        element['full_name'] = element['first_name'] + ' ' + element['last_name'] + ' ' + element['title'];
      });
      this.providers.forEach(provider => {
        provider.dropdown = this.associated_providers;
      });
      this.dataSource = new MatTableDataSource(this.providers);
    }
    else {
      this.toastr.error(companyUsersResponse.message)
    }
  }
  async getP2PRelations() {
    await this.services.getProviderRelations(this.company_id).subscribe((res: any) => {
      if (res.status == 200) {
        this.relationData = res.data;
        this.providers.forEach(provider => {
          this.relationData.forEach(relation => {
            if (provider?._id == relation?.provider_id?._id) {
              provider.assos_providers = relation.assoc_provider_id;
            }
          });
        });
      }
      else {
        this.toastr.error(res.message)
      }
    })
  }
  onDataSelection($event) {
    // let providers = element.associated_provider_ids.map(a => a._id);
    // let idx = this.users.findIndex(user => user._id.toString == element._id.toString());
    // this.users[idx] = element;
    // this.dataSource.data = this.users;
    if($event.data == 'selectAll') {
      this.onSelectAllProvider($event.element);
  
    }
    else if($event.data == 'unSelect') {
      this.onDeSelectAllProvider($event.element);
    }
    else {
      if(!$event.status) {
        this.onProviderDeSelect($event.data, $event.element);
      }
      else {
        this.onProviderSelect($event.data, $event.element);
      }
    }
  }
  
  onDataRemove($event) {
    this.onProviderDeSelect($event.data, $event.element);
  }

  onSelectAllProvider(provider) {
    const details = {
      providerID: provider._id,
      providerIDs: provider.dropdown.map(user => user._id).filter(user => user !== provider._id),
      isRelationCreating : true,
      company_id : provider.company_id
    }
    this.updateRelationWithAllProviders(details);
  }

  onDeSelectAllProvider(provider) {
    const details = {
      providerID: provider._id,
      providerIDs: provider.dropdown.map(user => user._id).filter(user => user !== provider._id),
      isRelationCreating : false,
      company_id : provider.company_id
    }
    this.updateRelationWithAllProviders(details);
  }

  updateRelationWithAllProviders(details) {
    this.services.updateRelationWithAllProviders(details).subscribe((response: any) => {
      if (response.status == 200) {
        this.loadDataForProvider();
        this.toastr.success("Relationship updated");
      }
    })
  }
  onProviderDeSelect(selectedProvider, provider) {
    const details = {
      providerID: provider._id,
      providerIDs: selectedProvider._id,
      company_id: provider.company_id
    }
    provider.assos_providers = provider.assos_providers.filter(pro => pro._id != selectedProvider._id);
    this.deleteRelationProvider2Provider(details);
  }

  showToast() {
    this.toastr.error("Self Association issue")
  }
  onProviderSelect(selectedProvider, provider) {
    if (selectedProvider._id == provider._id) {
      this.toastr.error("Self Association issue")
      return;
    }
    const details = {
      providerID: provider._id,
      providerIDs: selectedProvider._id,
      company_id: provider.company_id
    }
    console.log('details', details);
    this.createRelationProvider2Provider(details);
  }
  createRelationProvider2Provider(details) {
    this.services.createRelationProvider2Provider(details).subscribe((response: any) => {
      if (response.status == 200) {
        // this.loadDataForProvider();
        const providerToInsert = this.providers.find(provider => provider._id === details.providerID);
        if (providerToInsert) {
          this.providers.forEach(element => {
            if (element._id == details.providerIDs) {
              let obj = { _id: providerToInsert._id, full_name: providerToInsert.full_name, first_name: providerToInsert.first_name, last_name: providerToInsert.last_name, email: providerToInsert.email, title: providerToInsert.title };
              element.assos_providers = [...element.assos_providers, obj];
            }
          });
        }
        this.toastr.success("Relationship updated");
      } else {
        this.toastr.error("Error updating relationship");
      }
    })
  }
  deleteRelationProvider2Provider(details) {
    this.services.deleteRelationProvider2Provider(details).subscribe((response: any) => {
      if (response.status == 200) {
        // this.providers.forEach(element => {
        //   if (element._id == details.providerIDs) {
        //     console.log('idd', element);

        //     element.assos_providers.filter(provider => {
        //       provider._id != details.providerID
        //     })
        //   }
        // });
        this.loadDataForProvider(); 
        this.toastr.success("Relationship updated")
      }
    })
  }

  getProvidersName(providers) {
    let pro: any = []
    providers?.assos_providers?.forEach(provider => {
      pro.push(provider.name);
    })
    return pro;
  }

}
