import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import * as global from '../includes/global';
import { API_URL } from "../../environments/api-url";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import * as _ from 'lodash';
import { CommonService } from './common.service';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProcedureNotesService {
  currentUser: any;

  constructor(
    private httpclient: HttpClient,
    private _authService: AuthService,
    private _commonService: CommonService,
  ) {
    this.currentUser = this._authService.currentUser;
  }
  getProcedureNoteDataset(user_type = null, procedure_type_id = null, company_id = this.currentUser.company_id, header_ids = null) {
    var data = {
      owner_id: this.currentUser._id,
      company_id,
      user_type,
      procedure_type_id,
      header_ids
    }
    return this.httpclient.post(global.url + API_URL.PROCEDURE_NOTES.getProcedureNoteDataset, { data }).pipe();

  }

  addProcedureNoteDataSet(data) {
    data['owner_id'] = this.currentUser._id;
    if (!data['company_id']) data['company_id'] = this.currentUser.company_id;
    return this.httpclient.post(global.url + API_URL.PROCEDURE_NOTES.addProcedureNoteDataset, { data }).pipe();
  }


  addProcedureNotesData(data: any) {
    return this.httpclient.post(global.url + API_URL.PROCEDURE_NOTES.addProcedureNotesData, { data }).pipe();
  }

  getProcedureNotesData(wound_id) {
    var data = {
      wound_id,
    }
    return this.httpclient.post(global.url + API_URL.PROCEDURE_NOTES.getProcedureNotesData, { data }).pipe();
  }

  updateProcedureNoteDataSet(id, data) {
    data['owner_id'] = this.currentUser._id;
    if (!data['company_id']) data['company_id'] = this.currentUser.company_id;
    return this.httpclient.post(global.url + API_URL.PROCEDURE_NOTES.updateProcedureNoteDataset, { id, data }).pipe();
  }


  updateProcedureNotesData(id, data) {
    return this.httpclient.post(global.url + API_URL.PROCEDURE_NOTES.updateProcedureNotesData, { id, data }).pipe();
  }

  removeProcedureNoteDataSet(id) {
    return this.httpclient.post(global.url + API_URL.PROCEDURE_NOTES.deleteProcedureNoteDataset, { id }).pipe();
  }
  getCalculatedProcedureCPTs(date_obj, company_id, patient_id){
    var data = {
      date_obj,
      company_id,
      patient_id
    }
    return this.httpclient.post(global.url + API_URL.PROCEDURE_NOTES.getCalculatedProcedureCPTs, { data }).pipe();
  }
  deleteProcedureNotesData(procedure_data_id) {
    var data = {
      procedure_data_id
    }
    return this.httpclient.post(global.url + API_URL.PROCEDURE_NOTES.deleteProcedureNotesData, { data }).pipe();
  }
}
