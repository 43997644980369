    <div [ngClass]="{'container': (companySide || user_dashboard) && !(nursingSide)}">
    <mat-card class="vertical-height">
        <div class="flex justify-between items-center">
            <div class="flex gap-2">
                <div>
                    <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
                        Back
                    </button>
                </div>
                <h2>Weeks In Treatment Report</h2>
            </div>
            <div class="gap-2">
                <mat-label style="margin-right:20px;color:#999">Date of Creation </mat-label>
                <input style="width: 180px;" *ngIf="iscreationDateFilterSelected" type="text" ngxDaterangepickerMd
                    [(ngModel)]="filter.creationDate" (datesUpdated)="changeCreationDate($event)" class="mat-like-input"
                    [ranges]="preDateRanges" [alwaysShowCalendars]="true" [maxDate]="currentDate"
                    [linkedCalendars]="true" [showClearButton]="true" />
                <mat-form-field appearance="fill">
                    <mat-label>Select Facility</mat-label>
                    <input type="text" placeholder="Select Facility" aria-label="facility" matInput
                        [formControl]="facilityControl" [matAutocomplete]="facilityAutoControl">
                    <mat-autocomplete #facilityAutoControl="matAutocomplete" autoActiveFirstOption>
                        <mat-option (onSelectionChange)="onSelectFacility($event, facility);"
                            *ngFor="let facility of filteredFacilities | async" [value]="facility.title">
                            {{facility.title}}
                        </mat-option>
                    </mat-autocomplete>

                </mat-form-field>

                <button mat-raised-button class="dn-button-primary" (click)="handleApplyFilter();">Apply Filter
                </button>

                <button mat-raised-button class="dn-button-secondary" (click)="resetFilter();">Reset Filter
                </button>

                <button mat-raised-button class="dn-button-primary"
                    (click)="exportWoundPeriodicTreatmentReportAsXLSX();">Export
                    As XLSX
                </button>
            </div>
        </div>
        <div style="overflow: auto; height: 70vh;">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.patient_id?.last_name}}, {{element?.patient_id?.first_name}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="admission date">
                    <th mat-header-cell *matHeaderCellDef> Admission Date </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.patient_id?.last_admit_date | date }}
                    </td>
                </ng-container>


                <ng-container matColumnDef="weeks in tx">
                    <th mat-header-cell *matHeaderCellDef> Weeks In Tx </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{dateDifference(element?.patient_id?.last_admit_date)}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef> Location </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.body_part}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef> Type </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.etiolgy}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="length">
                    <th mat-header-cell *matHeaderCellDef> Length </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.length}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="width">
                    <th mat-header-cell *matHeaderCellDef> Width </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.width}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="depth">
                    <th mat-header-cell *matHeaderCellDef> Depth </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.depth}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="area change">
                    <th mat-header-cell *matHeaderCellDef> Area Change </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{calculateArea(element) | number : '1.0-2'}} %
                    </td>
                </ng-container>

                <ng-container matColumnDef="volume change">
                    <th mat-header-cell *matHeaderCellDef> Volume Change </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{calculateVolume(element) | number : '1.0-2'}} %
                    </td>
                </ng-container>

                <ng-container matColumnDef="insurance">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-card>
</div>