<div class="list-container">
    <ul>
    <li *ngFor="let mip of mipsQuestions; let i = index" class="flex items-center justify-between">
        <mat-checkbox name="mip" [checked]="mip.is_active_for_company" [disabled]="disableMips"
            (change)="saveMipsSetting($event,currentCompany,mip)">
            {{mip.quality_number+'&emsp;' + mip.measure_title}}
        </mat-checkbox>
        
    </li>
</ul>
</div>