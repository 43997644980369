<i class="icon icon-file" matTooltip="{{getNoteStatus() | titlecase}}" matTooltipPosition="above">
        <ng-container 
        *ngIf="!note.coSign_status?.status &&  note.is_signed == 'false' && note.is_completed == 'false' && !getnoteQAStatus()"
        >
                <mat-icon
                class="sub-icon"
                style="color: #c73434; font-weight: bold; transform: translateX(10%);">done</mat-icon>
        </ng-container>

        <ng-container 
        *ngIf="!note.coSign_status?.status &&  note.is_signed == 'false' && note.is_completed == 'false' && getnoteQAStatus()"
        >
                <p *ngIf="getNoteStatus()=='in progress'||getNoteStatus()=='assigned transcriber manually'"
                class="sub-icon"
                style="color: #c73434; font-weight: bold; transform: translateX(10%);">Tr/MA</p>

                <p *ngIf="getNoteStatus()=='submitted to external QA'||getNoteStatus()=='assigned to external QA manually'"
                class="sub-icon"
                style="color: #c73434; font-weight: bold; transform: translateX(10%);">QAE</p>

                <p *ngIf="getNoteStatus()=='assigned to QA manually'||getNoteStatus()=='submitted to internal QA'"
                class="sub-icon"
                style="color: #c73434; font-weight: bold; transform: translateX(10%);">QAI</p>

        </ng-container>

        <ng-container 
        *ngIf="note.coSign_status?.status && note.is_signed == 'false' && note.is_completed == 'false'"
        >
                <span 
                class="sub-icon check-icons"
                >
                        <mat-icon
                        style="color: #c73434; font-weight: bold; transform: translateX(10%);">done</mat-icon>
                        <mat-icon
                        style="color: #c73434; font-weight: bold; transform: translateX(10%);">done</mat-icon>
                </span>
        </ng-container>

        <ng-container
        *ngIf="!note.coSign_status?.status && note.is_signed == 'true' && note.is_completed == 'false'"
        >
                <mat-icon
                class="sub-icon"
                style="color: #34c759; font-weight: bold; transform: translateX(10%);">done</mat-icon>
        </ng-container>
        <ng-container
        *ngIf="note.coSign_status?.status && note.is_signed == 'true' && note.is_completed == 'false'"
        >
                <span 
                class="sub-icon check-icons"
                >
                        <mat-icon
                        style="color: #34c759; font-weight: bold; transform: translateX(10%);">done</mat-icon>
                        <mat-icon
                        style="color: #34c759; font-weight: bold; transform: translateX(10%);">done</mat-icon>
                </span>
        </ng-container>

        <ng-container 
        *ngIf="note.is_signed == 'true' && note.is_completed == 'true' && note.pcc_progressNoteId  && census?.facility?.source != 'MatrixCare'"
        >
                <img
                class="sub-icon pcc-icon" 
                src="../../../../assets/icons/pcc-icon.svg" 
                />
        </ng-container>

        <ng-container 
        *ngIf="formattedOperation == 'uploaded' && !note.pcc_progressNoteId && census?.facility?.source != 'MatrixCare'"
        >
                <span 
                class="sub-icon check-icons"
                > 
                        <mat-icon
                        style="color: #34c759; font-weight: bold; transform: translateX(10%);">done</mat-icon>
                        <mat-icon
                        style="color: #34c759; font-weight: bold; transform: translateX(10%);">done</mat-icon>
                </span>
        </ng-container>
        <ng-container 
        *ngIf="formattedOperation == 'uploaded'&& !note.pcc_progressNoteId && census?.facility?.source == 'MatrixCare'"
        >
                <span *ngIf="(patient?.source!='MatrixCare')"
                class="sub-icon check-icons" style="bottom: 8px !important;"
                > 
                        <mat-icon
                        style="color: #34c759; font-weight: bold; transform: translateX(10%);">done</mat-icon>
                        <mat-icon
                        style="color: #34c759; font-weight: bold; transform: translateX(10%);">done</mat-icon>
                </span>
                <span *ngIf="(patient?.source=='MatrixCare')"
                class="sub-icon check-icons" style="bottom: 8px !important;"
                > 
                        <img src="../../../../assets/icons/matrix.png" alt=""
                        style="height: 10px;
                        width: 20px;
                        margin-right: 5px; 
                        vertical-align: middle;
                        transform: translateX(10%);">
                </span>
        </ng-container>

</i>