<div class="h100 flex xs:flex-col items-center justify-center">
    <mat-card class="w-2/5 xs:w-full">

        <mat-card-title class="flex flex-col justify-center items-center">
            <img [src]="companylogoPath ? companylogoPath : defaultImg">
            <ng-container>
                <h2>Reset Your Password</h2>
                <p>Six digit Verification code has been sent to your email or phone number</p>
            </ng-container>
        </mat-card-title>

        <mat-card-content class="flex flex-col">

            <form #codeVerificationForm="ngForm">
                <mat-form-field>
                    <input matInput [type]="verifyHide ? 'password' : 'text'" name="verificationcode" placeholder="Verification code"
                        #verificationcode="ngModel" ngModel required [disabled]="display === '00:00'">
                        <mat-icon matSuffix style="cursor: pointer;" (click)="verifyHide = !verifyHide">{{verifyHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-form-field>
            </form>

            <form (submit)="onResetPassword(resetPasswordForm,codeVerificationForm)" #resetPasswordForm="ngForm">
                <ng-container>
                    <p style="text-align: center;"><b>Enter your new password</b></p>
                </ng-container>
                <div class="flex gap-5">
                    <div class="w-1/2">
                        <mat-form-field>
                            <input matInput [type]="hide ? 'password' : 'text'" name="password" [(ngModel)]="password" (click)="clearErrors()" (input)="isPasswordValid()"
                                placeholder="Password" required [disabled]="display === '00:00'">
                                <mat-icon matSuffix style="cursor: pointer;" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                <mat-error *ngIf="!resetPasswordForm.value.password || passwordError">Please enter password
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="w-1/2">
                        <mat-form-field>
                            <input matInput [type]="hide ? 'password' : 'text'" name="confirm_password" [(ngModel)]="confirm_password"
                                placeholder="Confirm Password" (input)="isPasswordValid()" [disabled]="display === '00:00'" required>
                                <mat-icon matSuffix style="cursor: pointer;" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                <mat-error *ngIf="!resetPasswordForm.value.confirm_password || passwordError">Please enter
                                confirm password</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div  style="text-align: left; font-size: 11px;line-height: 7px;">
                    <h1 style="margin-bottom: 14px; font-size: 14px; color: grey;"><b>Password Policy</b></h1>
                    <p [ngStyle]="{'color': policyUpperCase ? '#00FF00':'	#FF0000'}">1. Require at least 1 Upper case</p>
                    <p [ngStyle]="{'color': policyLowerCase ? '#00FF00':'	#FF0000'}">2. Require at least 1 Lower case </p>
                    <p [ngStyle]="{'color': policyNumber ? '#00FF00':'	#FF0000'}">3. Require at least 1 number</p>
                    <p [ngStyle]="{'color': policySpecialCharacter ? '#00FF00':'	#FF0000'}">4. Require at least 1 special character</p>
                    <p [ngStyle]="{'color': policyLength ? '#00FF00':'	#FF0000'}">5. Min Length: 10</p>
                </div>


                <div class="flex justify-center items-center">
                    <button class="theme-btn w-1/2" mat-stroked-button type="submit" [disabled]="display === '00:00'">Reset Password</button>
                </div>
                <div class="member" class="flex flex-col justify-center items-center">
                </div>

            </form>

            <div class="resend-code">
                If you have not received your temporary authentication code 2 minutes, click 
                <button class="resend-code-link" type="button" mat-button (click)="resendConfirmationCode()" [disabled]="display != '00:00' || disableButton">
                    resend
                </button>
                to receive a new code. ({{display}})
            </div>

        </mat-card-content>

    </mat-card>
</div>