<mat-card class="flex h-full">
    <mat-card-content *ngIf="!admin&&!company">


        <form [formGroup]="filterFormGroup" class="flex flex-row items-baseline gap-2">
            <div class="flex items-baseline gap-2" style="width: 23%;">
                <mat-form-field class="flex-1">
                    <mat-select formControlName="provider">
                        <mat-option *ngIf="currentUser.user_type == 'snf_wc_nurse'" value="all">All SNF Nurses</mat-option>

                        <div style="display:flex; flex-direction:row">
                            <input placeholder="Search" [(ngModel)]="searchTextP"
                                [ngModelOptions]="{standalone: true}" style="color:#000;height: 40px;padding-left: 7px; padding-right: 7px; border: none; width: 100%;"
                                (keydown)="$event.stopPropagation()">

                            <button *ngIf="searchTextP" mat-button mat-icon-button matSuffix (click)="clearInputP()">
                                <mat-icon>close</mat-icon>
                            </button>

                        </div>


                        <mat-option *ngFor="let provider of providers | searchProvider : searchTextP"
                            [value]="provider._id">
                            {{provider.first_name+' '+provider.last_name+', '+provider.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="flex items-baseline gap-2" style="width: 23%;">
                <mat-form-field class="flex-1">
                    <mat-select formControlName="facility" [(ngModel)]="facility_id">
                        <mat-option value="all">All Facilities</mat-option>

                        <div style="display:flex; flex-direction:row ; position: sticky; top: 0; z-index:1000; background-color: lightgray;">
                            <input placeholder="Search" [(ngModel)]="searchText"
                                [ngModelOptions]="{standalone: true}" style="color:#000;height: 40px;padding-left: 7px; padding-right: 7px; border: none; width: 100%;"
                                (keydown)="$event.stopPropagation()">

                            <button *ngIf="searchText" mat-button mat-icon-button matSuffix (click)="clearInput()">
                                <mat-icon>close</mat-icon>
                            </button>

                        </div>


                        <mat-option *ngFor="let facility of facilities | searchFacility : searchText"
                            [value]="facility">
                            <app-facility-title [facility]="facility" [defaultSource]="PointClickCare">
                            </app-facility-title>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="flex items-baseline gap-2"></div>
        </form>
    </mat-card-content>
            <div style="padding: 1rem;">
                <full-calendar  id="calender" *ngIf='calendarVisible()' [options]='calendarOptions()'>
                </full-calendar>
            </div>
    <ngx-ui-loader loaderId="loader-01"></ngx-ui-loader>

</mat-card>