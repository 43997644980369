<div class="flex jusify-between items-center gap-2"
    style="background: white; padding-left: 10px;padding-right: 10px; border-radius: 10px;"> 
    <div class="flex justify-start items-center gap-2">
        <mat-form-field appearance="fill" style="align-self: flex-end;">
            <mat-label>Search by Name</mat-label>
            <input matInput (ngModelChange)="applyFilter()" [(ngModel)]="filter.patientName">
        </mat-form-field>

        <mat-form-field appearance="fill" style="align-self: flex-end;">
            <mat-label>Search by DOB</mat-label>
            <input matInput [matDatepicker]="dospicker" (focus)="dospicker.open()"
                placeholder="Date of Birth" [value]="dob" (dateChange)="onDateChange($event)"> 
            <mat-datepicker-toggle matSuffix [for]="dospicker"></mat-datepicker-toggle>
            <mat-datepicker #dospicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="fill" style="align-self: flex-end; width: 350px;" >
            <mat-label>Select by Facility</mat-label>
            <div class="flex justify-start items-center">
                <img src="../../../assets/icons/pcc-icon.svg" *ngIf="isPCCFacility(filter.facility)" width="20px" height="20px"
                    alt="">
                <img src="../../../../assets/icons/matrix.png" *ngIf="isMatrixFacility(filter.facility)" 
                    style="height: 12px;
                    width: 20px;  
                    margin-right: 5px;
                    vertical-align: middle;"
                    alt="">
                <mat-select [disabled]="selectable" [(ngModel)]="filter.facility"
                    (ngModelChange)="handleFacilityChange($event)">
                    <input class="select-input-class" placeholder="Search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
                         />
                    <mat-option *ngFor="let facility of facilities | searchFacility: searchText" [value]="facility._id"
                        placeholder="Facility">
                        <app-facility-title [facility]="facility"></app-facility-title>
                    </mat-option>
                </mat-select>
            </div>
        </mat-form-field> 


        <mat-form-field appearance="fill" style="align-self: flex-end; width: 250px;" >
            <mat-label>Patient Status</mat-label>
               
                <mat-select [(ngModel)]="filter.patientStatus"
                    (ngModelChange)="handlePatientStatusChange($event)">
                    <mat-option disabled value="">Patient Status</mat-option>
                    <mat-option *ngFor="let status of patientStatuses" [value]="status">{{status}}</mat-option>
                </mat-select>
        </mat-form-field> 


    </div>
    <div class="flex justify-start items-center gap-2">
        <button *ngIf="!selectable" class="dn-button-secondary" style="flex: 10%; min-width: auto;" mat-raised-button
            (click)="clearFilter()">Clear Filter
        </button> 
    </div>

</div>

<div style="height: 5px;"></div>

<app-alphabetic-index-bar [activeIndex]="activeAlphabeticBarIndex"
    (onIndexButtonClick)="handleAphabeticIndexButtonClick($event)"></app-alphabetic-index-bar>
<div style="height: 5px;"></div>
<div class="flex justify-start items-center gap-2" style="width: 75%">
    <form *ngIf="selectedPatients.length > 0" class="flex justify-start items-baseline gap-2"
        style="position: relative; width: 45%"
        (submit)="$event.preventDefault(); handleAddToCensus()">
        <div style="color: #FFF;  min-width: fit-content;">Selected Patients: {{selectedPatients.length}}</div>
        <button mat-raised-button class="dn-button-primary" type="submit">
            Import and Add to Census
        </button>
    </form>



    <div class="flex flex-grow justify-center items-center gap-2" 
    style="flex-direction: row;
    box-sizing: border-box;
    display: flex;
    align-items: center; 
    width: 45%;" >
        <button mat-raised-button class="dn-button-primary"   [disabled]="!pagination.hasPrevPage"
            (click)="handlePrevClick()"> Prev</button>
            <span style="background: white; border-radius: 2px; font-size: 12px;padding: 5px;"> {{getcountofPatients()}}</span>
        <button mat-raised-button class="dn-button-primary" [disabled]="!pagination.hasNextPage"
            (click)="handleNextClick()">Next</button>
    </div>
</div>

<div style="height: 5px;"></div>

<div style="position: relative;overflow-y: auto;max-height: 100vh;margin-bottom: 15px;min-height: 100px;" [class.scrollable]="selectable"> 
      <table class="table-row-hover patient-list-table" mat-table [dataSource]="dataSource"  matSort (matSortChange)="sortData($event);">

        <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let element"> 
                <mat-checkbox [checked]="isPatientSelected(element)" (change)="handleSelectPatient($event, element)" ></mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
            <td mat-cell *matCellDef="let element" (click)="onRowClick($event,element)">
                <app-patient-name [patient]="element"></app-patient-name>
            </td>
        </ng-container>

        <ng-container matColumnDef="date_of_birth">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>DOB</th>
            <td mat-cell *matCellDef="let element">
                {{ getDOB(element) }}
            </td>
        </ng-container>
        <ng-container matColumnDef="last_seen">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="last_seen">Last Seen</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="getLastSeen(element)">
            </td>
          </ng-container>
        <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
            <td mat-cell *matCellDef="let element"> {{ getPatientLocation(element)}}</td>
        </ng-container>
        <ng-container matColumnDef="admission_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Admission Date</th>
            <td mat-cell *matCellDef="let element"> {{getAdmissionDate(element)}} </td>
        </ng-container>


        <ng-container matColumnDef="payor">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Payor</th>
            <td mat-cell *matCellDef="let element"> {{ getMatrixPayor(element)}}</td>
        </ng-container>
        <ng-container matColumnDef="operations">
            <th mat-header-cell *matHeaderCellDef></th>
            <ng-container *matCellDef="let element">
                <td mat-cell>
                    <div class="operation-buttons flex flex-inline justify-start items-center gap-5">
                           <i class="icon icon-import" matTooltip="Import" matTooltipPosition="above" *ngIf = "element.doctor_id_ref?._id !== currentUser._id"
                            (click)="handleSingleAddToCensus(element)" title ="Import Patient"></i>
                    </div>
                </td>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="!selectable">
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true" class="hover-row"></tr>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{selectable: selectable, selected: currentPatient && currentPatient.patient_id == row.patient_id}"
            (dblclick)="selectable && onSelect.emit(row)"></tr>
    </table>
    <ngx-ui-loader loaderId="patient-list-loader"></ngx-ui-loader>
</div>