<ng-container *ngIf="view === ''">    
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Medications
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.description}}
            </td>
        </ng-container>
        <ng-container matColumnDef="directions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Directions
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.directions}}
            </td>
        </ng-container>
        
        <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Start Date
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.startDate | date: 'MM/dd/yyyy'}}
            </td>
        </ng-container>
        
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Status
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.status}}
            </td>
        </ng-container>
        <ng-container matColumnDef="schedules">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Schedules
            </th>
            <td mat-cell *matCellDef="let element">
                <a href="#" (click)="$event.preventDefault(); changeView('schedules', element.schedules)">View</a>
            </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <br />
    <div *ngIf="apiData" class="flex justify-start items-center gap-2">
        <button mat-raised-button class="dn-primary-button" [disabled]="apiData.paging.page === 1"
            (click)="navigate(-1)">Prev</button>
        <span style="font-size: 12px;">Page {{apiData.paging.page}}</span>
        <button mat-raised-button class="dn-primary-button" [disabled]="!apiData.paging.hasMore"
            (click)="navigate(1)">Next</button>
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</ng-container>

<ng-container *ngIf="view === 'administration'">    
        <button mat-raised-button class="dn-button-secondary" (click)="changeView('', null)">Go Back to Medications</button>
        <app-administration [data]="viewData"></app-administration>
</ng-container>
<ng-container *ngIf="view === 'schedules'">    
        <button mat-raised-button class="dn-button-secondary" (click)="changeView('', null)">Go Back to Medications</button>
        <app-schedules [data]="viewData"></app-schedules>
</ng-container>