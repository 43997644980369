import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/services/auth.service';
import { MipsService } from 'src/app/services/Modules/mips.service';

@Component({
  selector: 'app-mips-questionnaire-results',
  templateUrl: './mips-questionnaire-results.component.html',
  styleUrls: ['./mips-questionnaire-results.component.css']
})
export class MipsQuestionnaireResultsComponent implements OnInit {
  @Input() charge;
  @Input() questionnaireResults;
  currentUser;
  constructor(
    private authService:AuthService,
    private mipsService:MipsService
    ) { 
      this.currentUser = this.authService.currentUser;
    }
  displayedColumns: any = ['measure_number', 'answer'];
  dataSource: any;

  ngOnInit(): void {
    if(this.charge._id) {
      if(!this.questionnaireResults) {
        this.initQuestionnaireResults();
      }
    }
  }

  initQuestionnaireResults() {
    this.mipsService.getQuestionnareResults(this.charge._id, this.currentUser.company_id).subscribe((response:any) => {
      if(response.status === 200) {
        this.questionnaireResults = response.data.array;
        this.dataSource = new MatTableDataSource(this.questionnaireResults);      
      }
    })
  }

}
