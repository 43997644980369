import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { TicketService } from '../../ticket.service';

@Component({
  selector: 'app-ticket-comments',
  templateUrl: './ticket-comments.component.html',
  styleUrls: ['./ticket-comments.component.css']
})

export class TicketCommentsComponent implements OnInit {

  @Input() rowData;
  @Output() edit = new EventEmitter<any>();
  comments: any[];
  currentUser: any;
  constructor(private ticketService: TicketService,private authService: AuthService
  ) {}

  ngOnInit() {
    // this.currentUser = localStorage.getItem('currentUserAuth');
    this.currentUser = this.authService.currentUser;
    this.getComments();
  }

  getComments() {
    this.ticketService.getComments(this.rowData._id).subscribe((Response: any) => {
      if (Response.status == 200) {
        console.log('Response', Response);
        this.comments = Response.data;
      } else {
        console.log('Response failed');
      }
    });
  }

  editComment(comment) {
    this.edit.emit(comment);
  }

  deleteComment(id) {
    this.ticketService.deleteComment(id).subscribe((Response: any) => {
      if (Response.status == 200) {
        console.log('Response', Response);
        this.getComments();
      } else {
        console.log('Response failed');
      }
    });
  }
}
