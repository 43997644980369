<form class="h100" (ngSubmit)="submitForm()">

    <div class="mat-dialog-header flex justify-between items-center">
        <h2 mat-dialog-title>Consent Form</h2>
        <span class="material-symbols-outlined mat-dialog-close-icon" mat-dialog-close>cancel</span>
    </div>

    <div mat-dialog-content>

        <mat-form-field appearance="outline" style="width: 50%;">
            <mat-select [value]="selectedConsentForm._id">
                <mat-option *ngFor="let consent of consents" [value]="consent._id"
                    (onSelectionChange)="onSelectOption($event, consent)">
                    {{consent.title}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="description">
            <label>Description</label>
            <div class="description-content" [innerHTML]="selectedConsentForm.description"></div>
        </div>

        <div class="signature">
            <div class="flex justify-between items-center">
                <label>Signature</label>
                <span *ngIf="(selectedConsentForm.description && selectedConsentForm._id && !signaturePad.isEmpty())" (click)="signaturePad.clear();">Clear Signature</span>
            </div>

            <signature-pad #signaturePadContainer class="canvas" [options]="signaturePadOptions" (onEndEvent)="drawComplete()"></signature-pad>
        </div>

    </div>

    <div mat-dialog-actions>
        <div class="flex flex-row gap-2 justify-end items-center" style="margin-left: auto;">
            <button mat-raised-button class="dn-button-primary" [disabled]="!(selectedConsentForm.description && selectedConsentForm._id && !signaturePad.isEmpty())" type="submit"> Save </button>
            <button mat-raised-button class="dn-button-secondary" type="button" mat-dialog-close> Cancel </button>
        </div>
    </div>

    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

</form>