<div class="wound-wrapper" mat-dialog-content style="padding: 0 26px"> 
<h1 mat-dialog-title style="text-align: center;">{{wound.createdAt | date: 'MM/dd/yyyy'}}</h1>
<h2  mat-dialog-title style="font-size: 16px;">Wound Note</h2>

    <mat-form-field class="full-width">
        <textarea matInput [(ngModel)]="woundNote"></textarea>
    </mat-form-field>

    <div class="flex justify-end">
        <button mat-button (click)="updateWoundDataAndClose()">
            Close
        </button>
    </div>
   
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>
<div class="top-right-close-btn">
    <button mat-mini-fab (click)="updateWoundDataAndClose()">
        <mat-icon>cancel</mat-icon>
    </button>
</div>
