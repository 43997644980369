<table mat-table [dataSource]="dataSource" *ngIf="facility && provider">
    <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Templates
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.title | uppercase}}
        </td>
    </ng-container>
    <ng-container matColumnDef="operations">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
        </th>
        <td mat-cell *matCellDef="let element">
            <button mat-button (click)="handleSelectTempalte(element)">Select</button>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<ngx-ui-loader [loaderId]="loaderId" bgsPosition= "center-center"></ngx-ui-loader>
<p style="color: red; font-size: 12px;padding:0 4px" *ngIf="!facility || !provider">Please select facility and provider</p>