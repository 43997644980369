import {Component, OnInit, ViewChild} from '@angular/core';
import {AppointmentDataService} from '../../service/data.service';
import {ToastrService} from 'ngx-toastr';
import * as _ from 'lodash';
import {FacilityService} from '../../../../services/facility.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {TranscriptionCreateService} from '../../../../includes/transcription-create/transcription-create.service';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import {EventInput} from "@fullcalendar/core";
import {Appointment} from "../../../../classes/appointment";
import {NotifyType} from "../../../../classes/notify-type";
import {AuthService} from "../../../../services/auth.service";
import {PatientListService} from "../../../../includes/patient-list/patient-list-service";
import { FullCalendarComponent } from '@fullcalendar/angular';
import moment from "moment";
import {NoteTypeDialog} from '../../../../shared/popup-dialogs/note-type-dialog/note-type-dialog.component';
import {PatientAddDialog} from '../../../../includes/transcription-create/patient-add-dialog/patient-add-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TitleService } from 'src/app/services/title.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators'

@Component({
    selector: 'app-user-appointment',
    templateUrl: './user-appointment.template.html',
    styleUrls: ['./user-appointment.css']
})
export class UserAppointmentComponent implements OnInit {
    displayedColumns: string[] = ['id', 'payor', 'facility', 'patient', 'date', 'time'];
    fixedColumns: string[] = ['id', 'payor', 'facility', 'patient', 'date', 'time'];
    dataSource: MatTableDataSource<any>;
    provider = '';
    facility = '';
    appointments = [];
    facility_attributes = [];
    providers = [];
    attribute_values = [{key: '', value: ''}];
    content_editable = false;
    property_testing = '';
    rowId = 0;
    loading = false;
    selectedProvider = undefined;
    selectedFacility = '';
    editing = false;
    filters = {
        facility_id: undefined,
        patient_id: undefined,
        payor_id: undefined,
        provider_id: undefined
    };
    loaderId = 'loader-01';

    // full calendar work
    @ViewChild('calendar') calendarComponent: FullCalendarComponent;
    calendarPlugins = [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin];
    header = {
        left: 'today prev,next',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    };
    titleFormat = {year: 'numeric', month: 'long'};
    calendarWeekends = true;
    calendarEvents: EventInput[];
    views = {
        dayGridMonth: { // name of view
            titleFormat: {year: 'numeric', month: 'long'}
            // other view-specific options here
        }
    };

        // add appointment form
        model = new Appointment();
    facilities = [];
    titles = [];
    patients = [];
    payors = [];
    doctor = undefined;

    is_add_view = false;
    is_detail_view = false;

    appointmentId = '';

    test_time = '12:00 AM';
    minTime = moment().format('LT');
    currentDate = new Date();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    
    patientControl = new FormControl();
    filteredPatients: Observable<any[]>;
    inprogress = false;

    constructor(private service: AppointmentDataService,
                private toastr: ToastrService,
                private facilityService: FacilityService,
                private titleService: TitleService,
                private authService: AuthService,
                private transcriptionService: TranscriptionCreateService,
                private patientService: PatientListService,
                private loader: NgxUiLoaderService,
                private dialog: MatDialog) {
    }

    async ngOnInit() {
        // default values
        this.model.is_video = true;
        // @ts-ignore
        this.model.notify_time = "30";
        // @ts-ignore
        this.model.notify_type = ["email"];
        try {
            this.service.routerObs.subscribe( data => {

                // @ts-ignore
                if (data.provider === true) {
                    this.is_add_view = false;
                    this.is_detail_view = false;
                }
            });
            await this.loadData();
            this.service.updates.subscribe(async data => {
                await this.loadData();
            }, error => {
                this.toastr.error('something went wrong in detecting updates', 'Error');
            });
            this.service.filters.subscribe(async data => {
                //
                this.filters = data;
                await this.getAppointments(this.filters);
            }, error => {
                this.toastr.error('something went wrong in filters', 'Error');
            });
        } catch (e) {
            this.toastr.error('something went wrong in reading data');
        }
        
        this.service.scheduleBtn.subscribe(data => {
            if(data.is_add_view) {
               this.is_add_view = true;
            }
        })
        this.filteredPatients = this.patientControl.valueChanges
        .pipe(
            startWith(''),
            map((value:any) => typeof value === 'string' ? value : value.name),
            map((name:any) => name ? this._filterPatient(name) : this.patients.slice())
        );
    }
    displayPatientFn(patient): string {
        return patient && patient.name ? patient.name : '';
    }

    private _filterPatient(name): any[] {
        const filterValue = name.toLowerCase();

        return this.patients.filter(patient => patient.name.toLowerCase().indexOf(filterValue) === 0);
    }
    handleDateClick(arg) {
        if (arg.date) {
            let clickedDate = moment(arg.date).format('L');
            let momentDate = moment().format('L');
            if (moment(clickedDate).isSame(momentDate)) {
                this.minTime = moment().format('LT');
                this.test_time = this.minTime;
            } else if (moment(clickedDate).isBefore(momentDate)) {
                return;
            } else if (moment(clickedDate).isAfter(momentDate)) {
                this.minTime = '0:00 AM';
                this.test_time = this.minTime;
            }
            this.model.dos = arg.date;
            this.test_time = arg.date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
            this.is_add_view = true;
        } else {
            this.is_add_view = true;
        }
    }

    handleEventClick(arg) {
        this.appointmentId = arg.event.extendedProps._id;
        this.is_detail_view = true;
    }

    /**
     * add appointment related methods
     */
    async addAppointment(appointment) {
        //
        await this.processNotifyType(appointment);
        await this.processDateTime(appointment);
        this.model.admin_id = this.authService.authObject.id;
        this.inprogress = true;
        const infoToast = this.toastr.info('Adding appointment...', 'Please wait', {disableTimeOut: true});
        this.service.addAppointment({...this.model, patient_id: this.model.patient_id._id}).subscribe(data => {
            this.toastr.success('Appointment added successfully', 'Success');
            this.service.updateData = true;
            this.is_add_view = false;
            this.model = new Appointment();
            this.getAppointments(this.filters);
        }, error => {

            this.toastr.error('something went wrong', 'Error');
        }, () => {
            this.inprogress = false;
            this.toastr.clear(infoToast.toastId)
        });
    }

    async processNotifyType(appointment) {
        //
        let notify_obj = new NotifyType();
        for (let i = 0; i < appointment.notify_type.length; i++) {
            notify_obj[appointment.notify_type[i]] = true;
        }
        this.model.notify_type = notify_obj;
    }

    async processDateTime(appointment) {

        this.model.startTime = appointment.tos;
        let time = appointment.tos.split(' ');
        const meredium = time[1];
        time = time[0].split(':');
        if (meredium === 'PM' && time[0] !== '12') {
            // tslint:disable-next-line:radix
            time[0] = parseInt(time[0]) + 12;
            // tslint:disable-next-line:radix
            time[1] = parseInt(time[1]);
        }
        if (meredium === 'AM' && time[0] === '12') {
            // tslint:disable-next-line:radix
            time[0] = parseInt(time[0]) + 12;
            // tslint:disable-next-line:radix
            time[1] = parseInt(time[1]);
        }
        // tslint:disable-next-line:radix
        time[0] = parseInt(time[0]);
        // tslint:disable-next-line:radix
        time[1] = parseInt(time[1]);
        const dos = new Date(appointment.dos);
        dos.setHours(time[0]);
        dos.setMinutes(time[1]);
        this.model.start = dos;
        this.model.dos = dos;
        this.model.tos = new Date();
        this.model.tos.setHours(time[0]);
        this.model.tos.setMinutes(time[1]);
    }
    dateChange($event) {
        //
        let changedDate = moment($event.value).format('L');
        let momentDate = moment().format('L');
        if (moment(changedDate).isSame(momentDate)) {
            this.minTime = moment().format('LT');
            this.test_time = this.minTime;
        } else if (moment(changedDate).isBefore(momentDate)) {
            return;
        } else if (moment(changedDate).isAfter(momentDate)) {
            this.minTime = '0:00 AM';
            this.test_time = this.minTime;
        }
    }

    async loadData() {
        try {
            this.loader.startLoader('loader-01');
            this.service.getPayors().subscribe(data => {
                this.payors = data.data.array;
            }, error => {
                this.toastr.error('something went wrong', 'Error');
            });
            this.transcriptionService.getAllProviders().subscribe(data => {
                // @ts-ignore
                this.providers = data.data.array;
            }, error => {
                this.toastr.error('something went wrong', 'Error');
            });

            // this.facilityService.getFacilities().subscribe(data => {
            //     // @ts-ignore
            //     this.facilities = data.data.array;
            // }, error => {
            //     this.toastr.error('something went wrong', 'Error');
            // });
            this.titleService.getMyTitles().subscribe(data => {
                // @ts-ignore
                this.titles = data.data.array;
            }, error => {
                this.toastr.error('something went wrong', 'Error');
            });
            this.patientService.getAllPatients(this.authService.authObject).subscribe(data => {
                // @ts-ignore
                this.patients = data.data.array;
            }, error => {
                this.toastr.error('something went wrong', 'Error');
            });
            await this.getAppointments(this.filters);
            this.loader.stopLoader('loader-01');
        } catch (e) {
            this.toastr.error('something went wrong in loading data', 'Error');
        }
    }

    getFacilities() {
        return new Promise((resolve, reject) => {
            this.facilityService.getMyFacilities(this.filters.provider_id).subscribe(data => {

                // @ts-ignore
                const facilities = data.data.array;
                this.selectedFacility = facilities[0]._id;
                this.filters.facility_id = facilities[0]._id;
                resolve(null);
            }, error => {
                reject('error => ' + error.toString());
            });
        });
    }

    getAppointments(filters = {}) {
        //
        this.loader.startLoader('loader-01');
        return new Promise((resolve, reject) => {
            // @ts-ignore
            this.service.getAppointmentsByAdminId(filters).subscribe(data => {
                this.loader.stopLoader('loader-01');
                //
                this.appointments = data.data.array;
                this.calendarEvents = data.data.array.map(e => {
                    e.title += ` (${e.patient_id.name})`;
                    return e;
                })
                
                resolve(null);
            }, error => {
                reject('error => ' + error.toString());
            });
        });
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }
    
    navigateCalenderToDate(date) {
        const calendarApi = this.calendarComponent.getApi();
        calendarApi.changeView('dayGridMonth', date);

    }
    showPatientAddDialog() {
        if (!this.model.provider_id || !this.model.facility_id) {
            return this.toastr.error('Please select provider and faciltiy first', 'Failed');
        }
        const dialogRef = this.dialog.open(PatientAddDialog, {
            width: '80%',
            data: {
                selectedFacilityId: this.model.facility_id,
                selectedDoctorId: this.model.provider_id,
                showPhoneField:true,
                showEmailField:true
            }
        });
        dialogRef.beforeClosed().subscribe(async (patient: any) => {
            if (patient && patient._id) {
                // patient.name = this.commonService.getPatientFullNameFormat2(patient);
                this.model.patient_id = patient;
                this.patients = [...this.patients, patient];
            }
        });
    }
    showTitleAddDialog() {
        const dialogRef = this.dialog.open(NoteTypeDialog, {
            width: '80%'
        });
        dialogRef.beforeClosed().subscribe(async (noteType: any) => {
            if(noteType && noteType._id) {
                this.titles = [...this.titles, noteType];
                this.model.title_id = noteType._id;
            }
        });
    }
    

    hasSelectedPatientEmail(selectedPatient) {
        if(selectedPatient) {
            const patient = this.patients.find(p => p._id === selectedPatient._id)
            return patient && patient.email;
        }
        return false;
    }
    handleExit() {
        this.is_detail_view=false;
        this.loadData();
    }
}
