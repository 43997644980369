import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CKEditorService } from 'src/app/includes/note-editor/ckeditor.service';
import { AuthService } from 'src/app/services/auth.service';
import { PCCService } from 'src/app/services/pcc.service';
import { pccFacilityEmailBody } from './model/pccFacilityEmailBody';

@Component({
  selector: 'app-facility-deactivation-dialog',
  templateUrl: './facility-deactivation-dialog.component.html',
  styleUrls: ['./facility-deactivation-dialog.component.css']
})
export class FacilityDeactivationDialogComponent implements OnInit {

  emailObject = new pccFacilityEmailBody();

  deactivationModel: any = {
    // receipient: "",
    customer_organization_name: "",
    customer_organization_code: "",
    facility_names: "",
    facility_ids: "",
    customer_info: "",
    production_application_name: "",
    partner_info: "",
    deactivation_reason: "",
  };

  cke4Config;
  separatorKeysCodes: number[] = [ENTER, COMMA];


  @ViewChild('emailIdInput') emailIdInput: ElementRef<HTMLInputElement>;

  emailIds: any = [];
  emailIdCtrl = new FormControl();
  ccEmailIdCtrl = new FormControl();
  currentUser: any;
  ccEmailIds: any = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ckeditorService: CKEditorService,
    private pccService: PCCService,
    private _toastr: ToastrService,
    private _authService: AuthService,
    public dialogRef: MatDialogRef<FacilityDeactivationDialogComponent>,

  ) {
    this.cke4Config = {
      ...this.ckeditorService.getDefaultConfig(),
      editable: true
    };
    this.currentUser = this._authService.currentUser;
  }
  ngOnInit(): void {
  }

  addEmailIdAsChip(event: MatChipInputEvent): void {
    const input = event.input;
    const value: any = event.value;

    this.emailIds.push(value);

    if (input) {
      input.value = '';
    }

    this.ccEmailIdCtrl.setValue(null);
  }

  addCCEmailIdAsChip(event: MatChipInputEvent): void {
    const input = event.input;
    const value: any = event.value;
    console.log("vale", value);
    this.ccEmailIds.push(value);

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.emailIdCtrl.setValue(null);
  }

  removeEmailIdAsChip(emailId: any): void {
    const index = this.emailIds.indexOf(emailId);
    if (index >= 0) {
      this.emailIds.splice(index, 1);
    }
  }

  removeCCEmailIdAsChip(ccEmailId: any): void {
    const index = this.ccEmailIds.indexOf(ccEmailId);
    if (index >= 0) {
      this.ccEmailIds.splice(index, 1);
    }
  }

  handleEmailIdInputFocus(event) {
    console.log("handle", event);
    this.emailIdCtrl.setValue(event.target.value);
  }

  handleCCEmailIdInputFocus(event) {
    console.log("handle", event);
    this.ccEmailIdCtrl.setValue(event.target.value);
  }

  selectedEmailIdAsChip(event: MatAutocompleteSelectedEvent): void {
    // this.emailIds.push(event.option.value);
    console.log("auto", this.emailIds);

    this.emailIdInput.nativeElement.value = '';
    this.emailIdCtrl.setValue(null);
  }

  submitDeactivationForm() {
    this.deactivationModel.emailIds = this.emailIds;
    this.deactivationModel.ccEmailIds = this.ccEmailIds;
    const formData = new FormData();
    formData.append('id', this.currentUser.id);
    formData.append('auth_key', this.currentUser.auth_key);
    formData.append('usertype', this.currentUser.user_type);

    for (let key in this.deactivationModel) {
      formData.append(key, this.deactivationModel[key]);
    }
    this.pccService.generateFacilityDeactivationPDFsAndEmail(formData).subscribe((response: any) => {
      if (response.status === 200) {
        this._toastr.success("Email Sent", "Success");
        this.dialogRef.close();
      }
      else {
        this._toastr.error('something went wrong', 'Error');
      }
    });

  }
}
