import { Component, OnInit, ViewChild, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
// import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatSort, MatDialog, MatPaginator } from '@angular/material';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Title } from '@angular/platform-browser';
import { ReportGenerateService } from '../report-generate.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PopupReportViewComponent } from '../popup-report-view/popup-report-view.component';
import { AppointmentDataService } from '../../appointment/service/data.service';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment'
// import { PreviewDocumentComponent } from '../preview-document/preview-document/preview-document.component';

interface IFilter {
  active: boolean,  
  date_of_service: {
    startDate: moment.Moment,
    endDate: moment.Moment
  },  
  provider: string,
  company: string,
  document: string
}

@Component({
  selector: 'app-report-generate',
  templateUrl: './report-generate.component.html',
  styleUrls: ['./report-generate.component.css']
})
export class ReportGenerateComponent implements OnInit, AfterViewInit {
  popupReportRef;
  providerModel: any;
  documentModel: any;
  selectedDOS: any;
  providers: any = [];
  uniqueProviders:any = [];
  documentFilter: any = '';
  providerFilter: any = '';
  dateFilter: any = '';
  comapnyFilter: any = '';
  selectedProvider: any;
  displayedColumns: string[] = [];
  dataSource: any;
  loading: boolean = false;
  loadingPage: boolean = false;
  currentUser: any;
  documents: any;
  selectedCompany: any = '';
  fileCountError: String = '';
  loaderId = 'loader-01';
  companies: any = ['none'];
  company: Boolean = false;
  winHeight: any;
  tableHeight: any;
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.winHeight = window.innerHeight;
    this.tableHeight = (this.winHeight - 182) + "px"; 
  }
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  @ViewChild('document_uploader') document_uploader: ElementRef;
  @ViewChild('cover_uploader') cover_uploader: ElementRef;
  // @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatPaginator, { static: true } as any) paginator: MatPaginator;
  // @ViewChild(MatSort, { static: true } as any) sort: MatSort;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true } as any) paginator: MatPaginator;
  MatDialogRef: MatDialogRef<PopupReportViewComponent, any>;
  filterDropdownActive = false;
  dropdownSettings: any = {};
  dropdownSettings2: any = {};
  closeDropdownSelection = true;
  disabled = false;

  public pagination: {
    pageNo: number,
    hasNextPage: boolean,
    hasPrevPage: boolean,
    totalCount: number,
    totalPages: number
  };

  filter: IFilter;
  appliedFilter: IFilter;

  constructor(
    private _reportGenerateService: ReportGenerateService,
    public _authservice: AuthService,
    public router: Router,
    private loader: NgxUiLoaderService,
    private dialog: MatDialog,
    public appointmentDataService: AppointmentDataService,
    private toastr: ToastrService,
    // private previewReport: PreviewDocumentComponent,
  ) {
    this.onResize();
    this.currentUser = _authservice.currentUser;
    this.pagination = {
      pageNo: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalCount: 0,
      totalPages: 0
    };

    this.filter = this.filterInitialState();
  }
  ngAfterViewInit(): void {
    // throw new Error('Method not implemented.');
    this.getCountOfDocuments();
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: true,
      allowSearchFilter: true,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.dropdownSettings2 = {
      singleSelection: true,
      allowSearchFilter: true,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    //omitting 'dos','lock'
    this.displayedColumns = ['sr', 'document_title', 'insurance_company',  'provider', 'uploading_date', 'pages', 'processing_date', 'status', 'operations'];
    // this.dataSource = this.documents;
    this.initDocuments(1);
    this.getInsuranceCompanies();
    // this.dataSource.sort = this.sort;
  }
  // toggleCloseDropdownSelection() { 
  //   this.closeDropdownSelection = !this.closeDropdownSelection;
  //   this.dropdownSettings = Object.assign({}, this.dropdownSettings, { closeDropDownOnSelection: this.closeDropdownSelection });
  // }

  applyFilter(event: Event) {
    if (event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }
  filterInitialState(): IFilter {
    return {
      active: false,
      date_of_service : null,
      provider: '',
      company: '',
      document: ''
    };
  }


  // getDOS(element) {
  //   if (element && element.drive_folder_id && element.drive_folder_id.rrgen_metadata && element.drive_folder_id.rrgen_metadata.date) {
  //     return new Date(element.drive_folder_id.rrgen_metadata.date).toLocaleDateString();
  //   }
  //   else {
  //     return '';
  //   }
  // }

  getDOS(element) {
    if (element && element.dos) {
      return new Date(element.dos).toLocaleDateString();
    }
    else {
      return '';
    }
  }

  // getProvider(element) {
  //   if (element && element.drive_folder_id && element.drive_folder_id.rrgen_metadata && element.drive_folder_id.rrgen_metadata.provider) {
  //     return element.drive_folder_id.rrgen_metadata.provider.first_name + ' ' + element.drive_folder_id.rrgen_metadata.provider.last_name;
  //   }
  //   else {
  //     return '';
  //   }
  // }

  getProvider(element) {
    if (element && element.provider_name) {
      return element.provider_name;
    }
    else {
      return '';
    }
  }
  getFolderTitle(element) {
    if (element && element.folderTitle) {
      return element.folderTitle;
    }
    else {
      return '';
    }
  }

  getInsuranceCompanies() {
    this.appointmentDataService.getPayors().subscribe((response: any) => {
      if (response.status === 200) { 
        this.companies = response.data.array.map(company => {
          return company;
        })
      }
      this.loading = false;
    });
  }

  uploadFiles(documentUploader, coverUploader) {
    this.toastr.info('Uploading Document', 'Please wait...');
    const [documentFile] = documentUploader.files;
    const [coverFile] = coverUploader.files;
    let comapny = this.selectedCompany.toLowerCase().trim();
    this.document_uploader.nativeElement.value = '';
    this.cover_uploader.nativeElement.value = '';
    this._reportGenerateService.uploadFiles(documentFile, coverFile, comapny).then((response: any) => {
      if (response.status === 200) {
        this.document_uploader.nativeElement.value = '';
        this.cover_uploader.nativeElement.value = '';
        // console.log("Uploaded");
        this.toastr.info('Document Uploaded');
        this.initDocuments(1);
      }
    });

    // TODO: upload files
  }


  onDetailClick(item) {
    if (!item.locked || item.lockedBy == this.currentUser._id) {
      const routerMapParams: any = { document_id: item._id, insurance_company: item.insurance_comapny };
      // localStorage.setItem('document_json', item.json);
      this.lockAndUnlockDocument(item._id);
      this.router.navigate(['/report-detail', routerMapParams])
    }
    else {
      this.toastr.info('Document is Locked');
    }
  }
  async lockAndUnlockDocument(document) {
    let locked = true;
    await this._reportGenerateService.lockAndUnlockDocument(locked, document).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.info('Document Locked');
      }
    })
  }
  // previewDocument(item){
  //   const routerMapParams: any = { document_id: item._id, insurance_company:item.insurance_comapny };
  //   localStorage.setItem('document_json', item.json);
  //   this.router.navigate(['/preview-document', routerMapParams])
  // }

  initDocuments(page) {
    this.loading = true;
    this.loader.startLoader('loader-01');
    this._reportGenerateService.paginteDocument(page, this.filter.active ? this.filter : null).subscribe((response: any) => {
      if (response.status == 200) {
        const { docs, totalCount } = response.data;
        this.pagination.totalCount = totalCount;
        this.pagination.pageNo = page;
        this.documents = docs; 
        this.dataSource = new MatTableDataSource(this.documents);
        this.dataSource.sort = this.sort;
        setTimeout(() => this.dataSource.paginator = this.paginator);
        this.loading = false;
        this.dataSource.sort = this.sort;
        this.initPagination();
        // this.loader.stopLoader('loader-01');
      }
    })

    // this._reportGenerateService.getAllDocuments().subscribe((response: any) => { 
    //   if (response.status === 200) {
    //     this.documents = response.data.array;  
    //     this.dataSource = new MatTableDataSource(this.documents);
    //     this.dataSource.sort = this.sort;
    //     setTimeout(() => this.dataSource.paginator = this.paginator);
    //     this.loading = false;
    //     this.loader.stopLoader('loader-01');
    //     this.dataSource.sort = this.sort;
    //   }
    // })
  }
  initPagination() {
    this.pagination.totalPages = Math.ceil(this.pagination.totalCount / 20);
    this.pagination.hasNextPage = this.documents.length > 0 && this.pagination.pageNo < this.pagination.totalPages;
    this.pagination.hasPrevPage = this.pagination.pageNo > 1;
    this.loader.stopLoader('loader-01');
  }
  handleNextClick() {
    this.initDocuments(this.pagination.pageNo + 1);

  }

  handlePrevClick() {
    this.initDocuments(this.pagination.pageNo - 1);
  }
  getCountOfDocuments(){ 
      return  ((this.pagination.pageNo * 20) - 19) + ' - ' + (((this.pagination.pageNo * 20)-20) + Number(this.documents?.length)) +" out of " + (this.pagination.totalCount);
 }

  onViewReportClick(element) {
    this.popupReportRef = this.dialog.open(PopupReportViewComponent, { width: '650px', data: { element: element } })
  }

  
 
onItemSelect(event: any) {
  this.company = true
  this.selectedCompany = event;
  if (event) {
    // this.dataSource = new MatTableDataSource(this.documents);
    // this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator; 
    this.dataSource.filter = event.trim().toLowerCase();
    this.dataSource.filterPredicate = function (data, filter: string): boolean {
      return (data.insurance_company.toLowerCase().includes(filter));
    };
  }
  else {
    this.dataSource = new MatTableDataSource(this.documents);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

}

applyFilterProvider(event: any) {
  let filterData: any = [];
  if (event) {
    this.documents.forEach(async document => {
      if (document && document.drive_folder_id && document.drive_folder_id.rrgen_metadata && document.drive_folder_id.rrgen_metadata.provider) {
        if (document.drive_folder_id.rrgen_metadata.provider.first_name.trim().toLowerCase().includes(event.trim().toLowerCase())) {
          await filterData.push(document);
        }
      }
    });
    this.dataSource = new MatTableDataSource(filterData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  else {
    this.dataSource = new MatTableDataSource(this.documents);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator
  }

}

onItemSelectProvider(event: any) {
  this.selectedProvider = event;
  if (event) {
    this.dataSource.filter = event.trim().toLowerCase();
    this.dataSource.filterPredicate = function (document, filter: string): boolean {
      if (document && document.drive_folder_id && document.drive_folder_id.rrgen_metadata && document.drive_folder_id.rrgen_metadata.provider) {
        return (document.drive_folder_id.rrgen_metadata.provider.first_name.toLowerCase().includes(filter));
      }
      else {
        this.dataSource = new MatTableDataSource(this.documents);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    };
  }
  else {
    this.dataSource = new MatTableDataSource(this.documents);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}

onItemDeSelect(event: any) {
  event = '';
  this.dataSource.filter = event.trim().toLowerCase();
  this.dataSource.filterPredicate = function (data, filter: string): boolean {
    return (data.insurance_company.toLowerCase().includes(filter));
  };
}

dateChangeDOS(e) {
  let filterData: any = [];
  if (e.value) {
    this.documents.forEach(async document => {
      if (document && document.drive_folder_id && document.drive_folder_id.rrgen_metadata && document.drive_folder_id.rrgen_metadata.provider) {
        if (new Date(document.drive_folder_id.rrgen_metadata.date).toLocaleDateString() == new Date(e.value).toLocaleDateString()) {
          await filterData.push(document);
        }
      }
    });
    this.dataSource = new MatTableDataSource(filterData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  else {
    this.dataSource = new MatTableDataSource(this.documents);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator
  }
}

getStatus(element) { 
  if (element.locked == false && element.summary) {
    return 'Completed'
  }
  else {
    return '';
  }
}

applyFilterNew(event: any) {
  if (event) {
    this.documentFilter = event.trim().toLowerCase();
  }
  else {
    this.documentFilter = '';
  }
}
applyFilterProviderNew(event: any) {
  if (event) {
    this.providerFilter = event.trim().toLowerCase();
  }
  else {
    this.providerFilter = '';
  }
}
dateChangeDOSNew(e) { 
  if (e.value) {
    this.dateFilter = new Date(e.value).toLocaleDateString()
    if (this.dateFilter == "1/1/1970") {
      this.dateFilter = '';
    }
  }
  else {
    this.dateFilter = ''
  }
}
onItemSelectNew(event: any) {
  if (event) {
    this.comapnyFilter = event.trim().toLowerCase()
  }
  else {
    this.comapnyFilter = '';
  }
}
onItemDeSelectNew(event: any) {
  this.comapnyFilter = '';
}

onChangeCompany(company = null){
  if(company){
    this.filter.company = company;
    return company
  }
  else{
    this.filter.company = '';
    return '';
  }
}
onSelectProvider(provider=null){
  if(provider){
    this.filter.provider = provider;
    return provider;
  }
  else {
    this.filter.provider = '';
    return '';
  }
}
onChangeVendor(vendor = null){
  if(vendor){
    return vendor
  }
  else{
    this.comapnyFilter = '';
    return ''
  }
}


filterData() {
  this.dataSource = new MatTableDataSource(this.documents);
  this.dataSource.sort = this.sort;
  this.dataSource.paginator = this.paginator
  let filterDataDocument = [];
  let filterDataprovider = [];
  let filterDataDate = [];
  let filterDataCompany = [];
  let finalData = []; 
  if (this.documentFilter != '') {
    this.documents.forEach(async document => {
      if (document && document.folderTitle ) {
        if (document.folderTitle.trim().toLowerCase().includes(this.documentFilter)) {
          await filterDataDocument.push(document)
        }
      }
    }); 
    finalData = filterDataDocument;
  }
  else {
    finalData = this.documents; 
  }
  if (this.providerFilter != '') {
    finalData.forEach(async document => {
      if (document && document.provider_name  ) {
        if (document.provider_name.trim().toLowerCase().includes(this.providerFilter) ) {
          await filterDataprovider.push(document);
        }
      }
    }); 
    finalData = filterDataprovider;
  }
  else {
    finalData = finalData; 
  }
  if (this.dateFilter != '') {
    finalData.forEach(async document => {
      if (document && document.dos ) {
        if (new Date(document.dos).toLocaleDateString() == this.dateFilter) {
          await filterDataDate.push(document);
        }
      }
    }); 
    finalData = filterDataDate;
  }
  else {
    finalData = finalData; 
  }
  if (this.comapnyFilter != '') {
    finalData.forEach(async document => {
      if (document) {
        if (document.insurance_comapny.trim().toLowerCase().includes(this.comapnyFilter)) {
          await filterDataCompany.push(document)
        }
      }
    }); 
    finalData = filterDataCompany;
  }
  else {
    finalData = finalData; 
  }
  this.dataSource = new MatTableDataSource(finalData);
  this.dataSource.sort = this.sort;
  this.dataSource.paginator = this.paginator;

}

clearfilterData() {
  this.selectedDOS = '';
  this.selectedCompany = '';
  this.documentModel = '';
  this.providerModel = '';
  this.dataSource = new MatTableDataSource(this.documents);
  this.dataSource.sort = this.sort;
  this.dataSource.paginator = this.paginator;
}


}
