import { Component, ViewChild, DoCheck, OnInit, ElementRef, AfterViewInit, OnDestroy, NgZone, ChangeDetectorRef, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { Location, DatePipe } from '@angular/common';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {
    TranscriptionDetailService,
    FilesAdd,
    CreatePdfService
} from '../transcription-detail/transcription-detail.service';
import { NoteEditorService } from './note-editor.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranscriptionTemplateDialog } from '../transcription-create/transcription-template-dialog/transcription-template-dialog.component';
import { PatientsDialog } from '../transcription-create/patients-dialog/patients-dialog.component';

import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, ParamMap, RoutesRecognized } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { Observable } from 'rxjs';
import { map, startWith, filter, pairwise } from 'rxjs/operators'
import { Template } from '../templates/templates.component';
import * as global from '../global';
import { SocketService } from 'src/app/services/socket.service';
import { PatientDetailService } from '../patient-detail/patient-detail.service';
import { PatientAddDialog } from '../transcription-create/patient-add-dialog/patient-add-dialog.component';
import { CommonService } from 'src/app/services/common.service';
import { TranscriptionService } from 'src/app/services/transcription.service';
import { AuthService } from 'src/app/services/auth.service';
import * as _ from 'lodash';
import swal from 'sweetalert';
import { PCCService } from 'src/app/services/pcc.service';
import { FormControl } from '@angular/forms';
import { NoteBuilderService } from "../../services/note-builder.service";
import { TemplatesService } from "../templates/templates.service";
import { DictionaryDialogComponent } from "../../shared/popup-dialogs/dictionary-dialog/dictionary-dialog.component";
import moment from 'moment-timezone';
import { AddendumNoteDialog } from '../transcription-create/addendum-note-dialog/addendum-note-dialog.component';
import { UploadNoteConfirmationDialog } from '../transcription-create/upload-note-confirmation-dialog/upload-note-confirmation-dialog.component';
import { AddendumService } from '../transcription-create/note-addendums/addendum-service.service';
import { UserService } from 'src/app/services/Modules/user.service';
import { Note } from './model/note'
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NoteAuditoryHistoryService } from '../note-auditory-history/note-auditory-history.service';
import { CKEditorComponent } from 'ckeditor4-angular';
import { PhrasesService, systemDefinedPhrases } from '../phrases/phrases.service';
import { DictionaryEditorDialogComponent } from '../phrases/phrase-editor-dialog/phrase-editor-dialog.component';
import { CKEditorService } from './ckeditor.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { RouterExtService } from 'src/app/services/router-ext.service';
import { GoBackUrlService } from 'src/app/services/goBackUrl.service';
import { GlobalPubSub } from 'src/app/services/global-pub-sub.service';
import { CensusListService } from '../census-list/census-list..service';
import { CensusPatientListService } from '../census-patient-list/census-patient-list.service';
import { TranscriptionCreateService } from '../transcription-create/transcription-create.service';
import { PccWidgetService } from '../pcc-widget/pcc-widget.service';
// import * as cheerio from 'cheerio'
@Component({
    selector: 'app-note-editor-new-version',
    templateUrl: './note-editor-new-version.component.html',
    styleUrls: ['./note-editor-new-version.component.css'],
    providers: [DatePipe]
})


export class NoteEditorNewVersionComponent implements OnInit, OnDestroy, DoCheck, AfterViewInit {
    global = global;
    id: {
        note?: string,
        audio?: string,
    };
    htmlContent: string = '';
    timeUp: Boolean = false;
    originaNoteText: string = '';
    template_attached = false;
    data: any;
    currentaudio: any;
    currentaudioFacilityTitle: any;

    startTime: any;
    pausedTime: any;
    resumeTime = 0;
    totalTime = 0;
    tempTime: any;
    lastStrockedTime: any;
    currentStrockTime: any;
    timeElapsedFlag = false;

    currentUser: any;
    superAdmin: any;
    transcription: any;
    pathfile: any;
    selectedFile: any;
    selectedFacilityTitle: any;
    transcriptionFiles: Array<any> = [];
    dictationFiles: Array<any> = [];
    nextPrev = 'tran';
    currentFileIndex: number = -1;
    currentFileIndexDict: number = -1;
    // response:any;
    docFileResult: any;
    title: string = '';
    public backURL: any;
    private fromSuperAdmin: any;
    public hide: any;
    public backURLString = '/transcription-detail';
    public loading = false;
    public isNew: boolean = false; // determine if creating new transcription or editing existed one
    public category: string = '';
    public readonly: any;
    public paramEditable: any;
    public doctors: any;
    public facilities: any;
    public facility_title: string = '';
    public patients: any;
    // public filteredPatients: any;
    public patientSource: string = 'portal';

    public selectedDoctorId: string = '';
    public selectedFacilityId: string = '';
    public selectedDoctorName: string = '';
    public currentDate: Date = new Date();
    public date_of_service: Date | string = moment.utc().toDate();
    // public date:string = null;
    public selectedPatientId: string = '';
    public selectedPatient: any = '';

    currentTemplate: Template = null;

    public formatted_date_of_service: String; // displayed on create transcription against audio #mohsin-dev
    public currentDoctor: any; // for dict create page only #mohsin-dev
    isPrevDisabled: boolean = false;
    isNextDisabled: boolean = false;

    initialState: string = null;
    isTouched: boolean = false;

    isAddMore = false;
    permission = {
        is_qa: false,
        is_editable: true,
        is_locked: false
    };

    recentNotes: Array<any> = [];

    sidebarVisible: boolean = true;
    sidebarVisiblepatients: boolean = false;
    videoSidebarVisible: Boolean = false;


    patientControl = new FormControl();
    filteredPatients: Observable<any[]>;
    patient_audio = {
        is_patient: false,
        name: undefined
    };


    transcriptionCreateLoader = 'transcription-create-loader';
    transcriptionCreateLoader_inprogress = false;

    @ViewChild('angularEditor') angularEditor: CKEditorComponent;
    audioId = '';

    @ViewChild('editor') editorComponent: CKEditorComponent;
    cke4Config;
    editorInstance: any;
    configg = {
        placeholder: 'Type the content here!',
        isReadOnly: false
    };
    count: number = 0;
    backSlider = 3;
    speedSlider = 1.0;

    ckEditorReady = false;
    eventCheck: any;
    phrases = [];
    save_submit = false;

    isCreatingAddendum = false;

    existInNoteText = {
        facility: false,
        provider: false,
        patient: false,
        date_of_service: false
    }

    isMatchAudioDOSWithNoteDOS = false;

    // selected from sidebar
    addendum: any = {
        text: ''
    };

    // NEW STUFF
    loaderId = 'note-editor-loader';
    note: Note; // all selected values
    addendum_counts: {
        saved: number,
        total: number,
        uploaded: number
    }
    buttonsState = {
        save: false,
        submitForReview: false,
        revert: false,
        sign: false,
        upload: false,
        addendum: false,
        uploadAddendum: false
    }

    originalNoteState;
    originalAddendumState;
    recentAudit?: {
        user_id: {
            _id: string,
            first_name: string,
            last_name: string
        },
        doctor_id: string,
        facility_id: string,
        note_id: string,
        audio_id: string,
        operation: String,
        note_text: String,
        timestamp: Date
    }
    relationship: {
        assoc_trancriber_id: string[],
        qa_id: string,
        qa_e_id: string,
        assoc_ma_ids: string[];
    }

    isActiveTimeout = null;
    isActiveAutoSaveDraftNoteTimeout = null;
    editLockTime = null;
    editLockTimeInterval = 10; // seconds

    censusId: string;

    dateOfService: Date = new Date();
    @ViewChild('patientAutoComplete', { read: MatAutocompleteTrigger }) patientAutoComplete: MatAutocompleteTrigger;

    @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

    dashboardData = {
        notes: [],
        audios: [],
        note: {
            next: null,
            prev: null,
        },
        audio: {
            next: null,
            prev: null,
        },
    }

    autoSavedOn: Date;
    telemedicine: any = false;
    associatedProvidersDropDown: boolean = false;
    associatedProviders: any = [];
    hasAssociatedProviders: boolean = false;
    coSignedProvider: any = {};
    coSignedProviderReserve: any = {};
    timer: any;
    saveError: boolean = false;
    charges: any;
    hiddenFacilites: any = [];
    patientListData: any = {};
    patientListSubscription: any;
    nextPatientNote: boolean = false;

    // facility_id;
    public constructor(
        public TranscriptionDetail: TranscriptionDetailService,
        private noteEditorService: NoteEditorService,
        private addendumService: AddendumService,
        private toastr: ToastrService,
        private createpdfservice: CreatePdfService,
        private router: Router,
        private dashboardService: DashboardService,
        private _location: Location,
        private _route: ActivatedRoute,
        private _router: Router,
        private encryptDecryptService: EncryptDecryptService,
        private dialog: MatDialog,
        public _date_pipe: DatePipe,
        private templateService: TemplatesService,
        private _socketService: SocketService,
        private commonService: CommonService,
        private builderService: NoteBuilderService,
        private transcriptionService: TranscriptionService,
        private _authService: AuthService,
        private _pccService: PCCService,
        private userService: UserService,
        private loader: NgxUiLoaderService,
        private noteAuditoryHistoryService: NoteAuditoryHistoryService,
        private phrasesService: PhrasesService,
        private ckeditorService: CKEditorService,
        private goBackUrlService: GoBackUrlService,
        private _censusPatientListService: CensusPatientListService,
        private censusListService: CensusListService,
        private _changeDetectorRefs: ChangeDetectorRef,
        private _transcriptionCreateService: TranscriptionCreateService,
        private ngZone: NgZone,
        private pccWidgetService: PccWidgetService
    ) {
        this.currentUser = this._authService.currentUser;
        this.telemedicine = this._route.snapshot.paramMap.get('telemedicine');
        if (!this.telemedicine || this.telemedicine == false || this.telemedicine == 'false') {
            this.telemedicine = false;
            this.videoSidebarVisible = false
        }
        else {
            this.telemedicine = true;
            this.videoSidebarVisible = true
        }
        // @ts-ignore
        // window.customplugin_oninsertAbbr = this.initNewMentions.bind(this);

        this.cke4Config = this.ckeditorService.getDefaultConfig();
        // if([global.DEV, global.LOCAL].indexOf(global.ENVIRONMENT) >= 0) {
        const extraPlugins = this.cke4Config.extraPlugins.split(',');
        this.cke4Config.extraPlugins = [...extraPlugins, 'exportpdf', 'phrasesautocomplete', 'icdautocomplete', 'asteriskjump'].join(',')
        this.cke4Config.disallowedContent = 'div';
        this.cke4Config.extraAllowedContent = 'p span[id][style](prevnote-phrase,phrase,pcc-phrase,phrase-error,asteriskjump,text-align-center,text-align-right,icd10-code)'
        // }

        this.phrasesService.resetPhrasesData();
        // this.startTime =  moment(new Date());
    }

    myEditor;
    async ckeditorReady(e) {


        const { editor } = e;
        this.myEditor = editor;



        editor.widgets.on('instanceCreated', (evt) => {
            // console.log(evt);

            // var widget = evt.data;
            // widget.on('data', (evt) => {
            //     // console.log(evt);

            //     if(evt.sender.name === 'customplugin' ) {
            //         this.initSimpelboxWidget(evt);
            //     }
            // });
        });
    }

    initSimpelboxWidget = (evt) => {
        evt.sender.editables.content.$.addEventListener('input', this.handleInputChange)
    }
    handleInputChange = (e) => {
        const ccName = e.target.innerText;

        this.initNewMentions(ccName);

    }
    closeSidebar(event) {
        this.sidebarVisible = false;
    }
    closeVideoSidebar(event) {
        this.videoSidebarVisible = false;
    }

    initNewMentions(ccName) {
        const ccData = this.noteEditorService.getCC(ccName);

        if (ccData) {

            // let cke = _.cloneDeep(this.cke4Config);
            // let mentions = cke.mentions ? [ ...cke.mentions ] : [];

            // mentions = [
            //     ...mentions,
            //     {
            //         feed: ccData['@pe'],
            //         marker: '$',
            //         minChars: 0
            //     },

            //     {
            //         feed: ccData['@ros'],
            //         marker: '%',
            //         minChars: 0
            //     }
            // ];
            // cke.mentions = mentions;

            // this.cke4Config = cke;
            this.cke4Config.mentions = this.cke4Config.mentions.concat([
                {
                    feed: ccData['@pe'],
                    marker: '$',
                    minChars: 0
                },

                {
                    feed: ccData['@ros'],
                    marker: '%',
                    minChars: 0
                }
            ])

            this.reloadCKEditor(1000);

        }

    }
    setLoading(isLoading, isBlockUI) {
        if (!isBlockUI && isLoading) {
            this.loader.startBackgroundLoader(this.loaderId);
        } else if (!isBlockUI && !isLoading) {
            this.loader.stopBackgroundLoader(this.loaderId);
        } else if (isBlockUI && isLoading) {
            this.loader.startLoader(this.loaderId);
        } else if (isBlockUI && !isLoading) {
            this.loader.stopLoader(this.loaderId);
        }
    }
    initGlobalScope() {
        window['angularComponentReference'] = {
            component: this, zone: this.ngZone,
            componentDataMembers: {
                componentName: 'note-editor',
                phrases: this.phrasesService.castPhrases
            },
            componentFunctions: {
                applyDblClickToSelectPhrases: (editor) => this.applyDblClickToSelectPhrases(editor),
                resolveSystemDefinedPhraseValue: (key) => this.phrasesService.resolveSystemDefinedPhraseValue(key, this.note),
                hasPCCPatient: () => !!(this.note.patient?.pcc_patientId),
                getICDs: (data) => this.censusListService.getICDs(data),
                applyAsteriskJump: (htmlText) => this.applyAsteriskJump(htmlText),
                onResolvePhraseStart: (phrase) => {
                    if (this.phrasesService.isAPIDependentPhrase(phrase)) {
                        if (this.phrasesService.phrasesData[this.note.patient._id]) {
                            const value = this.phrasesService.phrasesData[this.note.patient._id][phrase];
                            if (value === undefined) {
                                this.loader.startLoader("phrase_loader");
                            }
                        }
                    }
                    // this._changeDetectorRefs.detectChanges();
                },
                onResolvePhraseEnd: (phrase, error) => {
                    if (this.phrasesService.isAPIDependentPhrase(phrase)) {
                        this.loader.stopLoader("phrase_loader");
                        setTimeout(() => {
                            this._changeDetectorRefs.detectChanges();
                        }, 1000)
                    }
                }
            }
        };
    }
    async ngOnInit() {
        this.initGlobalScope();
        this._censusPatientListService.castAddCharge.subscribe(charge => {
            this.charges = charge || []
        })
        // console.log("this.charges in note editor: ", this.charges);

        this.dashboardService.castNotes.subscribe(notes => {
            this.dashboardData.notes = notes || [];
        });
        this.dashboardService.castAudios.subscribe(audios => {
            this.dashboardData.audios = audios || [];
        });
        // if (this._route.snapshot.paramMap.get('nextPatientNote')) {
        // this.nextPatientNote = JSON.parse(this._route.snapshot.paramMap.get('nextPatientNote'));
        // console.log("this.nextPatientNote", this.nextPatientNote);
        // }

        this._route.paramMap.subscribe(async (params: ParamMap) => {
            this.loader.startBackgroundLoader(this.loaderId);
            // console.log("patientList", this.patientList);
            // console.log("patientIndex", this.patientIndex);
            this.paramEditable = !!params.get('editable');
            const noteId = params.get('note_id');
            if (noteId) {
                await this.existingOnInit(noteId);
            } else {
                await this.newOnInit();
            }
            this.doCheckButtonStates();
            this.loader.stopBackgroundLoader(this.loaderId);


            // census > create note
            const paramCensusId = this._route.snapshot.paramMap.get('census_id');
            if (paramCensusId) {
                this.censusId = paramCensusId;
                this.note.rounding_sheet_id = paramCensusId;
            }
            if (this.note?.note_status?.status == 'published' &&
                (this.currentUser.user_type == '1' || this.currentUser.user_type == 'provider')
                // && this.currentUser._id == this.note.provider._id
                &&
                this.currentUser.title != 'M.D.' && this.currentUser.title != 'M.D' && this.currentUser.title != 'MD' &&
                this.currentUser.title != 'D.O.' && this.currentUser.title != 'D.O' && this.currentUser.title != 'DO'
            ) {
                
                await this.dashboardService.assocProviders(this.note.provider._id, this.note.facility._id).subscribe((response: any) => {
                    if (response.status == 200) {
                        
                        if (this.note?.coSign_status?.status) {
                            this.associatedProvidersDropDown = true
                        }
                        // console.log("response,data: ", response.data);
                        if (response.data.length > 0) {
                            this.hasAssociatedProviders = true;
                            this.associatedProviders = response.data;
                            
                            // this.coSignedProvider = this.note.note_status.id

                            this.coSignedProvider = this.associatedProviders.filter(a => {
                                if (a._id == this.note?.coSign_status?.coSigned_to) {
                                    return a
                                }
                            })
                            this.coSignedProvider = this.coSignedProvider[0];
                            
                        }
                    }
                })
            }

            this.initNextPrev();
        })
        await this.getPhrases();
        this.ckEditorReady = true;
    }

    async onChangeCoSignCheckBox(checked) {
        if (this.note?.coSign_status?.status == true) {
            this.coSignNote(false)
        }
        else {
            // this.coSignNote(true)
            // coSignSelect.open()
        }
    }

    isProvider() {
        if (this.currentUser.user_type == '1') {
            return true;
        }
        else
            return false;
    }

    async newOnInit() {
        this.note = new Note();
        this.isNew = true;

        this.audioId = this._route.snapshot.paramMap.get('audio_id');
        if (this.audioId) {
            await this.populateAudioPlayer();
        }
        await this.populateProviderList();
        await this.populateFacilityList();
        await this.populatePatientsList();
        this.populateDOS();
        this.config.editable = true;


        // census > create note
        const paramCensusId = this._route.snapshot.paramMap.get('census_id');
        if (paramCensusId) {
            this.showTemplateDialog()
        }

        this.patientControl.valueChanges.subscribe(this.handlePatientSelection.bind(this));
        // await this.initDraftNote();

    }

    async initDraftNote() {
        const draftNoteResponse: any = await this.noteEditorService.getDraftNoteByUserId().toPromise()
        if (draftNoteResponse.status === 200) {
            const draftNote = draftNoteResponse.data;
            if (draftNote) {
                await this.restoreDraftNote(draftNote);
            }
        }
        this.angularEditor.change.subscribe(() => {
            this.autoSaveDraftNote();
        })
    }

    async restoreDraftNote(draftNote) {
        if (!draftNote) return;

        const { note, recentAudit } = draftNote;
        

        this.note = new Note(note);
        if (draftNote.note.facility) {
            this.note.facility = this.facilities.find(f => f._id === draftNote.note.facility._id);
        }
        if (draftNote.note.provider) {
            this.note.provider = this.doctors.find(d => d._id === draftNote.note.provider._id);
        }
        if (draftNote.note.patient) {
            this.note.patient = this.patients.find(p => p._id === draftNote.note.patient._id);
        }

        this.recentAudit = recentAudit;

        this.dateOfService = new Date(new Date(this.note.dates.service).toLocaleString('en-US', { timeZone: this.note.dates.timezone }))

    }
    async handlePatientSelection(patient) {
        if (!patient || typeof patient !== 'object' || !Object.prototype.hasOwnProperty.call(patient, '_id')) return;
        const response: any = await this.noteEditorService.getFollowUpNoteAsJson(patient._id, this.note.provider._id).toPromise();

        // console.log('getFollowUpNoteIdByPatientId', response);
        if (response.status === 200) {
            this.note.followUpNote = response.data

            if (this.note.followUpNote.transcribtion_title?.match(/init/i)) {

                await this.initFollowUpTemplate();

            }
        }
        this.checkAndShowOnceTemplateDialog();

        this.phrasesService.prefetchSystemDefinedPhraseValues({ ...this.note, patient: patient });


    }
    async initFollowUpTemplate() {
        // getFollowUpTemplateByFacilityId
        const templateResponse: any = await this.templateService.getFollowUpTemplateByFacilityId(this.note.facility._id, this.currentUser._id).toPromise();
        this.showOnceTemplateDialog = true;

        // if (this.note.htmlText.length > 0 && confirm('Would you like to discard current note text and use Follow Up template?')) {
        // if (this.note.htmlText.length > 0 ) {
        //     this.handleTemplateSelect(templateResponse.data);
        // }

    }
    async existingOnInit(noteId: string, draftNote?) {
        let noteResponseData;
        if (draftNote) {
            noteResponseData = draftNote;
        } else {
            const noteResponse: any = await this.noteEditorService.getNoteById(noteId).toPromise();
            if (noteResponse.status === 200) {
                noteResponseData = noteResponse.data;
                if (global.ENVIRONMENT === "LOCAL") {
                    // console.log("noteResponseData: ", noteResponseData);
                }
            }
            else if (noteResponse.status === 403) {
                // this.toastr.error(noteResponse.message, 'ERROR');
                this.router.navigate(['/403']);
            }
        }
        if (!noteResponseData) return;

        const { note, addendum_counts, recentAudit, relationship } = noteResponseData;
        this.note = new Note(note);
        // Note Lock
        //    this.timer = setInterval(async()=>{
        //     console.log(this.count++);
        //        if(this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type === global.USER_TYPE.DOCTOR){
        //         if(!this.note.isEditLocked(this.currentUser._id) || this.note.lockedBy == undefined){
        //             console.log("not locked")
        //             const lockResponse: any = await this.noteEditorService.setNoteLock(noteId,this.currentUser._id).toPromise();
        //             console.log("if",lockResponse.data);
        //             if(lockResponse.status == 200) {
        //                 this.note.lockedBy = this.currentUser._id;
        //             }
        //         }
        //         }
        //     },30000);

        this.addendum_counts = addendum_counts;
        this.recentAudit = recentAudit;
        this.relationship = relationship;
        console.log("relationship", this.relationship);

        this.initPhrases();
        await this.populateFacilityList();

        await this.populatePatientsList();
        this.saveOriginalNoteState();

        // if(this.note.isOwner(this.currentUser._id)) {
        //     this.config.editable = true;
        // }

        this.dateOfService = new Date(new Date(this.note.dates.service).toLocaleString('en-US', { timeZone: this.note.dates.timezone }))
        const disabledSidebarParam = this._route.snapshot.paramMap.get('disable-sidebar');
        if (disabledSidebarParam) {
            this.sidebarVisible = false;
        }

        if (!this.note.isSigned() && !this.note.isUploaded()) {
            this.patientControl.valueChanges.subscribe(value => {
                if (!Object.prototype.hasOwnProperty.call(value, '_id')) return;
                this.phrasesService.prefetchSystemDefinedPhraseValues({ ...this.note, patient: value });
            })
        }

        this.autoUpdateNoteEditLock();
        //to disbale functionalities for co-sign initiator
        if (this.note?.coSign_status?.status && this.note?.coSign_status.coSigned_by == this.currentUser._id) {
            this.config.editable = false;
            this.configg.isReadOnly = true
            
        }
    }

    populateDOS() {
        // populate from census > create note
        const paramDOS = this._route.snapshot.paramMap.get('dos');
        if (paramDOS) {
            const dos = new Date(paramDOS);

            const timeStr = moment().format('HH:mm');

            const newDate = moment(dos);
            const time = moment(timeStr, 'HH:mm');

            newDate.set({
                hour: time.get('hour'),
                minute: time.get('minute'),
                second: time.get('second')
            });
            this.note.dates.service = newDate.toDate();

            this.dateOfService = new Date(new Date(this.note.dates.service).toLocaleString('en-US', { timeZone: this.note.dates.timezone }))

        } else if (this.note.hasAudio()) {
            //mohsin
            this.note.dates.service = this.note.audio.filterServiceDate;
            //ali
            this.dateOfService = new Date(new Date(this.note.audio.filterServiceDate).toLocaleString('en-US', { timeZone: this.note.audio.timezone }))
        }
    }
    saveOriginalNoteState() {
        const { provider, facility, patient, title, dates, htmlText } = this.note;
        this.originalNoteState = JSON.stringify({ provider, facility, patient, title, dates, htmlText });
    }
    compareOriginalNoteState() {
        const { provider, facility, patient, title, dates, htmlText } = this.note;
        return JSON.stringify({ provider, facility, patient, title, dates, htmlText }) === this.originalNoteState;
    }
    saveOriginalAddendumState() {
        const { text } = this.addendum;
        this.originalAddendumState = text;
    }
    compareOriginalAddendumState() {
        const { text } = this.addendum;
        return text === this.originalAddendumState;
    }

    async populatePatientsList() {
        if (this.note.facility) {
            // await this.getFacilityPatientsByProviderId(this.note.provider._id, this.note.facility._id);
            await this.getFacilityPatients(this.note.facility._id);
        }
        this.filteredPatients = this.patientControl.valueChanges
            .pipe(
                startWith(''),
                map((value:any) => {
                    if (value) {
                        return typeof value === 'string' ? value : value.name
                    }
                    return '';
                }),
                map(name => name ? this._filterPatient(name) : this.getFilteredPatients()),
            );
        if (this.isNew) {
            if (Array.isArray(this.patients)) {
                if (this.note.hasAudio() && this.note.audio.patient_id_ref) {
                    let patient_id;
                    if (typeof this.note.audio.patient_id_ref !== 'string') {
                        patient_id = this.note.audio.patient_id_ref._id;
                    } else {
                        patient_id = this.note.audio.patient_id_ref;
                    }

                    this.note.patient = this.patients.find(p => p._id === patient_id);
                } else {

                    // populate from census > create note
                    const paramPatientId = this._route.snapshot.paramMap.get('patient_id');
                    if (paramPatientId) {
                        const patientId = paramPatientId;
                        this.note.patient = this.patients.find(p => p._id === patientId);
                    }
                }
            }
        } else {
            const patient_id = this.note.patient?._id || this.note.patient_id
            this.note.patient = this.patients.find(p => p._id === patient_id);
        }
    }
    showCoSign() {
        if (!this.note?.coSign_status?.status) {
            if (this.note?.note_status?.status == 'published'
                && (this.currentUser.user_type == '1' || this.currentUser.user_type == 'provider')
                && this.currentUser._id == this.note.provider._id
                && (this.note?.provider?.title?.trim() != 'M.D.'.trim() && this.note?.provider?.title?.trim() != 'M.D'.trim() && this.note?.provider?.title?.trim() != 'MD'.trim())
                && (this.note?.provider?.title?.trim() != 'D.O.'.trim() && this.note?.provider?.title?.trim() != 'D.O'.trim() && this.note?.provider?.title?.trim() != 'DO'.trim())
                && (this.associatedProviders.length > 0)
            ) {
                return true;
            }
            else {
                return false
            }
        }
        else {
            if ((this.currentUser.user_type == '1' || this.currentUser.user_type == 'provider')
                // && this.currentUser._id == note.provider._id
                // && (note?.provider?.title?.trim() != 'M.D.'.trim() && note?.provider?.title?.trim() != 'M.D'.trim() && note?.provider?.title?.trim() != 'MD'.trim() )&&
                //     note?.provider?.title?.trim() != 'D.O.'.trim() && note?.provider?.title?.trim() != 'D.O'.trim() && note?.provider?.title?.trim() != 'DO'.trim()
            ) {
                this.associatedProvidersDropDown = false;
                this.coSignedProvider = {}
                return true;
            }
            else {
                this.associatedProvidersDropDown = false;
                this.coSignedProvider = {}
                return false
            }
        }
    }
    isNoteCoSigned(note) {
        if (this.note?.coSign_status?.status && (this.note?.coSign_status?.coSigned_to == this.currentUser._id)) {
            return true
        }
        else {
            return false
        }
    }
    async populateProviderList() {
        if (this.note.hasAudio()) {
            // for audio note
            this.doctors = [this.note.audio.uploaded_by_id_ref];
            this.note.provider = this.note.audio.uploaded_by_id_ref;

        } else {
            // for simple note
            if (this.currentUser.user_type == global.USER_TYPE.TRANSCRIBER) {
                const doctorsResponse: any = await this.noteEditorService.getAssociatedDoctorsList(this.currentUser.company_id).toPromise();
                if (doctorsResponse.status == 200) {
                    this.doctors = doctorsResponse.data;
                }

                try {
                    const paramProviderId = this._route.snapshot.paramMap.get('provider_id');
                    let providerId;
                    if (paramProviderId) {
                        providerId = paramProviderId;
                    } else {
                        let tranFilters: any = localStorage.tranFilters;
                        if (tranFilters) {
                            tranFilters = JSON.parse(tranFilters);
                            if (tranFilters.provider) {
                                providerId = tranFilters.provider._id;
                            }
                        }
                    }
                    this.note.provider = this.doctors.find(d => d._id === providerId);
                } catch (error) { }
            }
            else if (this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
                const doctorsResponse: any = await this._transcriptionCreateService.getAssociatedDoctorsListForMA().toPromise();
                if (doctorsResponse.status == 200) {
                    this.doctors = doctorsResponse.data;
                }

                try {
                    const paramProviderId = this._route.snapshot.paramMap.get('provider_id');
                    let providerId;
                    if (paramProviderId) {
                        providerId = paramProviderId;
                    } else {
                        let tranFilters: any = localStorage.tranFilters;
                        if (tranFilters) {
                            tranFilters = JSON.parse(tranFilters);
                            if (tranFilters.provider) {
                                providerId = tranFilters.provider._id;
                            }
                        }
                    }
                    this.note.provider = this.doctors.find(d => d._id === providerId);
                } catch (error) { }
            }
            else {
                this.doctors = [this.currentUser];
                this.note.provider = this.currentUser;
            }
        }
        this.handleProviderChange();
    }
    async populateFacilityList() {
        if (this.note.provider) {
            await this.getFacilities(this.note.provider._id);
        }

        if (this.isNew) {
            try {
                // populate from audio
                if (this.note.hasAudio() && this.note.audio.facility_id_ref) {
                    let facility_id_ref;
                    if (typeof this.note.audio.facility_id_ref !== 'string') {
                        facility_id_ref = this.note.audio.facility_id_ref._id;
                    } else {
                        facility_id_ref = this.note.audio.facility_id_ref;
                    }

                    this.note.facility = this.facilities.find(f => f._id === facility_id_ref);
                    // return await this.getFacilityPatientsByProviderId(this.note.provider._id, this.note.facility._id);
                    return await this.getFacilityPatients(this.note.facility._id);
                }


                // populate from census > create note
                const paramFacilityId = this._route.snapshot.paramMap.get('facility_id');
                if (paramFacilityId) {
                    const facilityId = paramFacilityId;
                    this.note.facility = this.facilities.find(f => f._id === facilityId);
                    return await this.handleFacilityChange();
                }

                // popualte from dashboard > filters
                let tranFilters: any = localStorage.tranFilters;
                if (tranFilters) {
                    tranFilters = JSON.parse(tranFilters);
                    const facilityId = tranFilters.facility._id;
                    this.note.facility = this.facilities.find(f => f._id === facilityId);
                    return await this.handleFacilityChange();
                }
            } catch (error) { }
        } else {
            this.note.facility = this.facilities.find(f => f._id === this.note.facility._id);
        }
    }
    async populateAudioPlayer() {
        this.note.audio = await this.getAudioDetailById(this.audioId);
    }
    useAudioDataInNote() {
        const { uploaded_by_id_ref, facility_id_ref } = this.note.audio;

        this.note.provider = uploaded_by_id_ref;
        this.note.facility = facility_id_ref;
        this.note.title = this.note.audio.audio_title;
    }
    async saveNote() {

        this.clearAutoSaveDraftNote();
        this.loader.startBackgroundLoader(this.loaderId);
        const response: any = await this.noteEditorService.saveNote(this.note, this.censusIdForPatientSeen).toPromise();
        let noteId;
        if (response.status === 200) {
            if (!this.note._id || this.note.is_draft === 'true') {
                // new note created
                noteId = response.data.noteId;

                this.toastr.success(`Note has been created successfully`);
                
                // @ts-ignore
                if (this.nextPatientNote) {
                    // removed 'disable-sidebar': 1
                    await this.router.navigate([`/note-editor/${noteId}`, { ...this._route.snapshot.paramMap, nextPatientNote: this.nextPatientNote }], { replaceUrl: true });
                }
                else {
                    // removed 'disable-sidebar': 1
                    await this.router.navigate([`/note-editor/${noteId}`, { ...this._route.snapshot.paramMap }], { replaceUrl: true });
                }
            } else {
                this.saveOriginalNoteState();
            }
            if (this.sidebarVisible) {
                this.sidebarVisible = false;
                setTimeout(() => {
                    this.sidebarVisible = true;
                })
            }
        }
        else if (response.status !== 200) {
            this.saveError = true;
        }
        this.loader.stopBackgroundLoader(this.loaderId);
        return noteId;
    }
    async saveDraftNote() {
        this.noteEditorService.saveDraftNote(this.note, this.censusIdForPatientSeen).subscribe((response: any) => {
            if (response.status === 200) {
                const { noteId, recentAudit } = response.data;
                this.recentAudit = recentAudit;

                this.note._id = noteId;
                this.toastr.success(`Note has been auto saved`);
            }

        }, () => { }, () => { })
    }

    get censusIdForPatientSeen() {
        const paramCensusId = this._route.snapshot.paramMap.get('census_id');
        if (paramCensusId && paramCensusId !== 'undefined') return paramCensusId;
    }

    async submitForReviewNote() {
        this.loader.startBackgroundLoader(this.loaderId);
        this.noteEditorService.submitForReviewNote(this.note._id).subscribe((response: any) => {
            if (response.status === 200) {
                this.note = Object.assign(this.note, response.data.note);
                this.recentAudit = response.data.recentAudit;

                this.toastr.success(`Note has been ${this.formatNoteAuditOperation(this.recentAudit.operation)} successfully`);

                // this._router.navigateByUrl('/dashboard;category=dict');
                this.goBack()
            }
        }, () => { }, () => this.loader.stopBackgroundLoader(this.loaderId))
    }

    async coSignNote(status) {
        // this.loader.startBackgroundLoader(this.loaderId);
        this.coSignedProviderReserve = this.coSignedProvider;
        this.noteEditorService.coSignNote(this.note, this.coSignedProvider._id, this.note.provider._id, status).subscribe((response: any) => {
            if (response.status === 200) {
                this.note = Object.assign(this.note, response.data.note);
                this.note.coSign_status = response.data.note.coSign_status
                this.config.editable = false;
                this.configg.isReadOnly = true;
                // if(this.note.provider._id)
                // console.log("=====================");

                // this.note = Object.assign(this.note, response.data.note);
                // this.recentAudit = response.data.recentAudit;

                this.toastr.success(`Note's Co-Signed status changed`);

                // this._router.navigateByUrl('/dashboard;category=dict');
            }
        },
            () => { },
            () => this.loader.stopBackgroundLoader(this.loaderId))
    }

    async revertNote() {

        this.loader.startBackgroundLoader(this.loaderId);
        this.noteEditorService.revertNote(this.note._id).subscribe((response: any) => {
            if (response.status === 200) {
                this.note = Object.assign(this.note, response.data.note);
                this.recentAudit = response.data.recentAudit;
                this.config.editable = false;
                this.toastr.success(`Note has been ${this.formatNoteAuditOperation(this.recentAudit.operation)} successfully`);
            }

        }, () => { }, () => this.loader.stopBackgroundLoader(this.loaderId))
    }
    // gotoNextPatientInCensus() {
    //     if (this.patientListData?.patientList?.length > 0 && this.patientListData?.patientIndex < this.patientListData?.patientList?.length - 1 && this.nextPatientNote) {
    //         console.log(this.patientListData, "this.patientListData");
    //         let index = this.patientListData.patientIndex;
    //         this.patientListData.patient_id = this.patientListData.patientList[++index]._id;
    //         ++this.patientListData.patientIndex;
    //         this._censusPatientListService.setPatientListData(this.patientListData);
    //         let obj = JSON.parse(JSON.stringify(this.patientListData));
    //         delete obj.patientList;
    //         delete obj.patientIndex;
    //         console.log("obj", obj);
    //         this._router.navigate(['/note-editor/new', obj]);
    //         return;
    //     }
    // }

    async signNote() {
        // if(this.note.hasPCCPatient()) {
        //     if(!this._pccService?.pccUserinfo) {
        //         // return this.toastr.error("You need to login to PCC first", "Error");
        //     }
        // }
        let noteId = this.note._id;
        if (!this.compareOriginalNoteState()) {
            const _noteId = await this.saveNote();
            if (!noteId) {
                noteId = _noteId;
            }
        }
        // this.coSignNote(true)
        // setTimeout(() => {
        // this.loader.startBackgroundLoader(this.loaderId);
        // if (this.nextPatientNote) {
        //     this.patientListSubscription = this._censusPatientListService.castPatientListData.subscribe(patientListData => {
        //         this.patientListData = patientListData;
        //     });
        // }

        if (this.currentUser?.other_settings?.note_sign_confirmation === false) {
            if ((this.note?.provider?._id.toString() != this.currentUser._id.toString()) || !this.coSignedProvider?._id || (this.note?.coSign_status?.status && this.note?.coSign_status?.coSigned_by != this.note?.provider?._id)) {
                this.doSignNote(noteId);
            }
            else {
                this.coSignNote(true)
            }
        } else {
            this.showUploadNoteConfirmationDialog({ type: 'note', confirmButtonLabel: 'Confirm & Sign', noteId }, async () => {
                if ((this.note?.provider?._id.toString() != this.currentUser._id.toString()) || !this.coSignedProvider?._id || (this.note?.coSign_status?.status && this.note?.coSign_status?.coSigned_by != this.note?.provider?._id)) {
                    if (await this.doSignNote(noteId)) {

                    } else if (noteId) {

                        //For opening next patient's note
                        // console.log("this.patientListData.patientList?.length", this.patientListData.patientList?.length);
                        // console.log("this.patientListData?.patientIndex < this.patientListData?.patientList.length - 1", this.patientListData?.patientIndex < this.patientListData?.patientList.length - 1);
                        if (Object.values(this.patientListData).length > 0 && this.nextPatientNote) {
                            // this.gotoNextPatientInCensus();
                        }
                        else {
                            this.navigate(`/note-editor/${noteId}`)
                        }
                    }
                }
                else {
                    this.coSignNote(true)
                }

            });
        }
        // })


    }

    async doSignNote(noteId?) {
        // let hasPCCPatient = this.note.hasPCCPatient();
        let hasLoggedInToPCC = this.hasLoggedInToPCC();
        // const response: any = await this.noteEditorService.signNote(noteId || this.note._id, hasPCCPatient && hasLoggedInToPCC).toPromise()
        const response: any = await this.noteEditorService.signNote(noteId || this.note._id, hasLoggedInToPCC).toPromise()
            .catch(() => this.toastr.error(`Something went wrong while signing note, please try again`, "Failed"));
        if (response.status === 200) {
            // if(this.associatedProvidersDropDown && this.coSignedProvider && !this.note?.coSign_status?.status){
            //     this.coSignNote(true);
            // }
            this.note = Object.assign(this.note, response.data.note);
            this.recentAudit = response.data.recentAudit;

            this.toastr.success(`Note has been ${this.formatNoteAuditOperation(this.recentAudit.operation)} successfully`);
            // this.gotoNextPatientInCensus();
            // this.toastr.success(`Note has been signed successfully`);

            // census > create note
            // if (hasPCCPatient && hasLoggedInToPCC) {
            if (hasLoggedInToPCC) {
                return this.navigateBackToCensus();
            }
        } else {
            if (hasLoggedInToPCC && this.note.patient.pcc_patientId) {
                if (response.message.toLowerCase().indexOf('token') >= 0) {
                    this._pccService.invalidateToken();
                    this.toastr.error(`${response.message}, Please sign in to PCC and try again`, "Failed");
                } else {
                    this.toastr.error(response.message, "Failed");
                }
            } else {
                this.toastr.error(response.message, "Failed");
            }
        }
        this.loader.stopBackgroundLoader(this.loaderId);
    }

    navigateBackToCensus() {
        // census > create note
        const paramCensusId = this._route.snapshot.paramMap.get('census_id') || this.note.rounding_sheet_id;
        
        if (paramCensusId) {
            this.router.navigate([`/census/${paramCensusId}`], { replaceUrl: true });
            return true;
        }
    }

    hasLoggedInToPCC() {
        return !!this._pccService?.pccUserinfo;
    }

    async uploadNote() {
        if (this.note.hasPCCPatient()) {

            if (this.currentUser?.other_settings?.note_upload_confirmation === false) {
                this.doUploadNote();
            } else {
                this.showUploadNoteConfirmationDialog({ type: 'note', confirmButtonLabel: 'Confirm & Upload' }, async () => {
                    this.doUploadNote();
                })
            }


        } else {
            this.doUploadNote();
        }
    }

    async doUploadNote() {
        this.noteEditorService.uploadNote(this.note._id).subscribe((response: any) => {
            if (response.status === 200) {
                this.note = Object.assign(this.note, response.data.note);
                this.recentAudit = response.data.recentAudit;

                this.toastr.success(`Note has been ${this.formatNoteAuditOperation(this.recentAudit.operation)} successfully`);
                // census > create note
                this.navigateBackToCensus();
            } else {
                this.toastr.error(response.message, "Failed");
            }
        },
            () => this.toastr.error(`Something went wrong while uploading note, please try again`, "Failed")
        );
    }

    componentLoaded() {
        return this.ckEditorReady && this.note;
    }
    async getPhrases() {
        return new Promise<void>((resolve, reject) => {
            this.templateService.getPhrases().subscribe((res) => {
                // @ts-ignore
                this.phrases = res.data.phrases;
                let mentions = [
                    {
                        feed: this.phrases,
                        marker: '##',
                        minChars: 0
                    }
                ];
                let cke = _.cloneDeep(this.cke4Config);
                cke.mentions = mentions;
                this.cke4Config = cke;
                this.reloadCKEditor();
                resolve(null);
            }, error => {
                reject();
            });
        })
    }

    reloadCKEditor(ms = 1) {
        // console.log({
        //     myEditor: this.myEditor
        // });
        if (!this.myEditor) return;
        // const editorName = this.myEditor.name;
        // this.myEditor.destroy();
        // console.log(this.angularEditor);

        // setTimeout(() => {
        //     // this.angularEditor = CKEDITOR.replace(this.angularEditor.elementRef.nativeElement.querySelector('textarea'), this.cke4Config)
        //     // this.myEditor.setData(this.note.htmlText)
        // }, 1)

        this.ckEditorReady = false;
        setTimeout(() => this.ckEditorReady = true, ms);
    }

    async applyNoteBuilder() {
        if (this.note.hasAudio() && this.patient_audio.is_patient) {
            const data = {
                patient: this.note.audio.patient_id_ref.name,
                provider: `${this.note.provider.first_name} ${this.note.provider.last_name}, ${this.note.provider.title}`,
                facility: this.showFacility(),
                dos: this.note.audio ? this.note.audio.filterServiceDate : (!this.isNew && !this.note.audio && this.note ? this.note.dates.service : '')
            };
            this.templateService.applyNoteBuilder(data).subscribe(res => {

                // @ts-ignore
                let data = res.data;
                Object.keys(data).forEach((key, index) => {
                    let editor = this.note.htmlText
                        .replace(new RegExp(key, 'g'), data[key]);
                    this.note.htmlText = editor;
                });
            })
        }
    }

    displayPatientFn(patient): string {
        return patient && patient.name ? patient.name : '';
    }

    private _filterPatient(name): any[] {
        const filterValue = name.toLowerCase();

        return this.getFilteredPatients().filter(patient => patient.name.toLowerCase().indexOf(filterValue) === 0);
    }
    chunkPlay() {
        this.TranscriptionDetail.chunk.subscribe((x) => {
            let audio = document.getElementById('transcription');
            // @ts-ignore
            audio.currentTime = x.chunk;
            // @ts-ignore
            audio.play();
            // @ts-ignore
            let limit = parseFloat(x.duration) + parseFloat(x.chunk);
            let timer = setInterval(function () {
                // @ts-ignore
                if ((parseFloat(audio.currentTime)) > limit) {
                    // @ts-ignore
                    audio.pause();
                    clearInterval(timer);
                }
            }, 1000);
        })
    }

    ngAfterViewInit() {
        // console.log("button state", this.buttonsState.save);
        this.chunkPlay();
        document.addEventListener('keydown', this.handlePedal);
        return;
        // @ts-ignore
        document.addEventListener('keydown', async (event) => {
            if (event.code === 'PageUp' || event.code === 'PageDown') {



                event.preventDefault();
                let audio = document.getElementById('transcription');
                if (event.code === 'PageDown') {
                    try {
                        // @ts-ignore
                        if (audio.paused) {

                            // @ts-ignore
                            audio.playbackRate = this.speedSlider;
                            // @ts-ignore
                            await audio.play();

                        } else {

                            // @ts-ignore
                            audio.currentTime = (parseInt(audio.currentTime) - 2).toString();
                            // @ts-ignore
                            await audio.pause();

                        }
                    } catch (e) {

                    }
                } else {
                    try {

                        // @ts-ignore
                        audio.playbackRate = this.speedSlider;
                        // @ts-ignore
                        audio.currentTime = (parseInt(audio.currentTime) - this.backSlider).toString();
                        // @ts-ignore
                        await audio.play();
                    } catch (e) {

                    }
                }
            }
        });
    }

    ngOnDestroy() {

        document.removeEventListener('keydown', this.handlePedal);
        delete window['angularComponentReference'];
        if (this.isNew && this.isActiveAutoSaveDraftNoteTimeout !== null && this.isNew && this.note && this.note.is_draft !== 'false') {
            this.saveDraftNote();
        }
        this.clearAutoSaveDraftNote();
        // this.clearNoteEditLock();
        this.phrasesService.resetPhrasesData();
        localStorage.removeItem("censusPatientListData");
        if (this.patientListSubscription) {
            this.patientListSubscription.unsubscribe();
        }
        // if(!this.note.isEditLocked(this.currentUser._id)){
        //     this.noteEditorService.setNoteLock(this._route.snapshot.params.note_id,null).toPromise();
        // }
        // if(this.timer){
        // clearInterval(this.timer);
        // }

    }

    handlePedal = async (event) => {
        if (event.code === 'PageUp' || event.code === 'PageDown') {

            event.preventDefault();

            let audio = document.getElementById('transcription');
            if (event.code === 'PageDown') {
                try {
                    // @ts-ignore
                    if (audio.paused) {

                        // @ts-ignore
                        audio.playbackRate = this.speedSlider;
                        // @ts-ignore
                        await audio.play();

                    } else {

                        // @ts-ignore
                        audio.currentTime = (parseInt(audio.currentTime) - 2).toString();
                        // @ts-ignore
                        await audio.pause();

                    }
                } catch (e) {

                }
            } else {
                try {

                    // @ts-ignore
                    audio.playbackRate = this.speedSlider;
                    // @ts-ignore
                    audio.currentTime = (parseInt(audio.currentTime) - this.backSlider).toString();
                    // @ts-ignore
                    await audio.play();
                } catch (e) {

                }
            }
        }
    }

    editorScrollTop(offset = 0) {
        if (this.angularEditor && this.angularEditor['textArea'] && this.angularEditor['textArea'].nativeElement) {
            this.angularEditor['textArea'].nativeElement.scrollTop = offset;
        }
    }

    duplicateNote(note) {
        localStorage.setItem('duplicateNote', JSON.stringify(note));
        const params: any = { new: 1 };
        if (note.audio_id) {
            params.audio_id = note.audio_id;
            params.category = 'dict';
        } else {
            params.category = 'tran';
        }
        this.reloadScreen(params);
    }

    showFacility() {
        let title = 'None';
        if (this.isNew && this.currentaudio) {
            title = this.currentaudio.facility_id_ref.title;
        } else if (this.selectedFile) {
            title = this.selectedFile.facility_id_ref.title;
        }

        return title;
    }

    /* #khaleeq
    checkNoteQA */
    checkNoteQA(doctor_id, transcriber_id) {
        const params = {
            doctor_id: doctor_id,
            transcriber_id: transcriber_id
        };
        return new Promise<void>((resolve, reject) => {
            this.transcriptionService.checkNoteQA(params).subscribe(
                data => {

                    if (data.success === true) {
                        this.permission.is_qa = true;
                    }
                    resolve(null);
                },
                error => {

                    resolve(null);
                }
            );
        });
    }

    toggleNoteLock(lock = true) {

        const params = {
            note_id: this.selectedFile._id,
            is_locked: lock
        };
        this.transcriptionService.toggleNoteLock(params).subscribe(
            async data => {

                this.toastr.success('Note lock status has been changed', 'Updated');
                // await this.initializeData();
            },
            error => {

            }
        );
    }

    /* template conditions here */
    isLocked(): Boolean {
        if (this.permission.is_qa === false &&
            this.selectedFile.is_locked === true &&
            // tslint:disable-next-line:triple-equals
            this.currentUser.user_type != '1') {
            return true;
        } else if (this.permission.is_qa === true && this.selectedFile.is_published === 'true') {
            return true;
        } else if ((this.permission.is_qa === false
            && this.currentUser.user_type == '2'
            && this.selectedFile.is_published === 'true') || (this.permission.is_qa === false
                && this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT
                && this.selectedFile.is_published === 'true')) {
            return true;
        } else {
            return false;
        }
    }

    isSubmit(): Boolean {
        if (this.permission.is_qa === false
            && this.selectedFile.is_published == 'false'
            && this.selectedFile.is_locked === false) {
            return true;
        } else if (this.permission.is_qa === true
            && this.selectedFile.is_published == 'false'
            && this.selectedFile.is_locked === false) {
            return true;
        } else {
            return false;
        }
    }

    async getAudioDetailById(audio_id: string) {
        const response: any = await this.dashboardService.getAudioDetailById(audio_id, this.currentUser._id, this.currentUser.auth_key, this.currentUser.user_type).toPromise();

        if (response.status === 200) {
            return response.data;
        }

        this.toastr.error('Audio does not exist', 'Error');
        // setTimeout(() => this.router.navigate(['/dashboard']));
    }

    async getTranscriptionById(transcription_id) {
        const response: any = await this.transcriptionService.get_transcription_by_id(transcription_id).toPromise();
        if (response.status === 200) {
            if (response.data.is_active === 'true') {
                return response.data;
            }
        }
        //this.toastr.error('Note does not exist', 'Error');
        //setTimeout(() => this.router.navigate(['/dashboard']));
    }

    isEditorEditable() {
        if (this.isNew) {
            this.config.editable = true;
            this.configg.isReadOnly = false
        } else if (this.currentUser && this.selectedFile) {
            if (this.currentUser.user_type == 1) {
                // Provider
                // tslint:disable-next-line:triple-equals
                this.config.editable = this.selectedFile.is_signed != 'true';
            } else {
                // Transcriber
                if (this.selectedFile.is_locked && !this.permission.is_qa) {
                    this.config.editable = false;
                } else {
                    // tslint:disable-next-line:triple-equals
                    this.config.editable = this.selectedFile.is_published != 'true';
                }
            }
            if (this.readonly === 'true') {
                this.config.editable = false;
            }
            if (this.paramEditable === 'true' && this.note.hasAudio() && this.recentAudit?.operation.includes('revert')) {
                this.config.editable = true;
            }
        }
    }

    config: AngularEditorConfig = {
        editable: false,
        spellcheck: true,
        height: '100%',
        placeholder: 'Enter text here...',
        translate: 'no',
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ]
    };


    async getPatientsForDoctor(item) {
        // DOCTOR 1
        const patientsResponse: any = await this.noteEditorService.getPatients(item).toPromise();

        if (patientsResponse.status === 200) {
            this.patients = patientsResponse.data.array
                .filter(patient => patient.is_active === 'true').map(patient => ({
                    ...patient,
                    name: this.commonService.getPatientFullNameFormat2(patient),
                }));
            this.patients.sort((a, b) => {
                if (a.name > b.name) return 1;
                if (b.name > a.name) return -1;

                return 0;
            });
            // this.filteredPatients = this.patients.slice()
            this.patientControl.setValue('');

        }
    }

    async getPateintsForTranscriber(item) {
        // TANSCRIBER 2
        const associatedDoctorsListResponse: any = await this.noteEditorService.getAssociatedDoctorsList(this.currentUser.company_id).toPromise();
        if (associatedDoctorsListResponse.status === 200) {
            // const associatedDoctorsList = associatedDoctorsListResponse.data.array; // array
            const associatedDoctorsList = associatedDoctorsListResponse.data; // array
            const associatedDoctorsIds = associatedDoctorsList.map(d => d._id);
            item.associated_doctors_ids = associatedDoctorsIds; // additional parameter

            const patientsResponse: any = await this.noteEditorService.getPatients(item).toPromise();
            if (patientsResponse.status === 200) {
                this.patients = patientsResponse.data.array
                    .filter(patient => patient.is_active === 'true').map(patient => ({
                        ...patient,
                        name: this.commonService.getPatientFullNameFormat2(patient),
                    }));

                this.patients.sort((a, b) => {
                    if (a.name > b.name) return 1;
                    if (b.name > a.name) return -1;

                    return 0;
                });
                // this.filteredPatients = this.patients.slice()
                this.patientControl.setValue('');
            }
        }
    }

    async getFacilityPatients(facility_id) {
        const response: any = await this.noteEditorService.getFacilityPatients(facility_id).toPromise();

        if (response.status === 200) {
            this.patients = response.data.array;
        }
    }
    async getFacilityPatientsByProviderId(provider_id, facility_id) {
        const response: any = await this.noteEditorService.getFacilityPatientsByProviderId(provider_id, facility_id).toPromise();

        if (response.status === 200) {
            this.patients = response.data.array;
        }
    }


    isHTMLContentValid() {
        const tmpElem = document.createElement('div');
        tmpElem.innerHTML = this.htmlContent;
        return tmpElem.innerText.trim().length > 0;
    }


    validateTranscription() {
        let hasError = !this.isHTMLContentValid() || !this.selectedPatientId || !this.title;
        if (this.category === 'tran') {
            hasError = hasError || !this.selectedDoctorId || !this.selectedFacilityId;
        }
        if (this.selectedPatient) {
            this.selectedPatientId = this.selectedPatient._id;
        }
        if (hasError) {
            if (!this.title) {
                this.toastr.error('Please enter title and try again', 'Failed');
                return false;
            }
            if (!this.isHTMLContentValid()) {
                this.toastr.error('Please enter text and try again', 'Failed');
                return false;
            }
            if (!this.selectedPatientId) {
                this.toastr.error('Please select patient and try again', 'Failed');
                return false;
            }
            if (this.category === 'tran') {
                if (!this.selectedDoctorId) {
                    this.toastr.error('Please select provider and try again', 'Failed');
                    return false;
                }
                if (!this.selectedFacilityId) {
                    this.toastr.error('Please select facility and try again', 'Failed');
                    return false;
                }
            }
        }

        return true;
    }

    async saveTranscription(options?) {
        if (!this.validateTranscription()) {
            return;
        }

        const transcription = this.initTranscription(this.selectedFile);
        if (this.isNew) {
            // ACTIVITY LOG
            this._socketService.addActivityLog({
                id: this.currentUser._id,
                screen: 'Create Note',
                operation: 'Create Note API Call',
                datetime: this._date_pipe.transform(new Date(), global.date_time_format)
            });
            return await this.createNewTranscription(transcription, options);
        } else {
            // ACTIVITY LOG
            this._socketService.addActivityLog({
                id: this.currentUser._id,
                screen: 'Update Note',
                operation: 'Update Note API Call',
                datetime: this._date_pipe.transform(new Date(), global.date_time_format)
            });
            return await this.saveExistingTranscription(transcription, undefined, options);
        }

    }

    async saveAndSubmit() {
        this.save_submit = true;
        return await this.saveTranscription();
    }

    resetData() {
        if (this.category === 'dict') {
            this.htmlContent = '';
            this.title = '';
            if (!this.patient_audio.is_patient) {
                this.selectedPatient = '';
                this.selectedPatientId = '';
            }
        }
        if (this.category === 'tran' && this.isNew) {
            this.htmlContent = '';
            this.title = '';
            this.selectedPatient = '';
            this.selectedPatientId = '';
            this.selectedFacilityId = '';
        }
    }


    async createNewTranscription(transcription, options?) {
        return new Promise((resolve, reject) => {


            // Creating new text transcription #mohsin-dev
            this.loading = true;
            transcription.is_active = 'true';
            transcription.is_locked = this.save_submit;
            transcription.is_signed = transcription.is_signed || 'false';
            transcription.is_completed = transcription.is_completed || 'false';
            transcription.uploaded_by_id = this.currentUser._id;
            transcription.date_of_service = this.date_of_service;
            transcription.creation_date = this._date_pipe.transform(this.currentDate, global.date_time_format);

            if (this.category === 'dict') {
                transcription.doctor_id = this.currentDoctor._id;
                transcription.doctor_name = this.commonService.getUserFullName(this.currentDoctor);
                transcription.facility_title = this.currentaudio.facility_id_ref.title;
                transcription.facility_id = this.currentaudio.facility_id;
                transcription.audio_id = this.currentaudio._id;
                // transcription.date =  this._date_pipe.transform(this.currentaudio.date_time, global.date_time_format);
            } else {
                transcription.doctor_id = this.selectedDoctorId;
                const selectedDoctor = this.note.provider;
                if (selectedDoctor) {
                    transcription.doctor_name = this.commonService.getUserFullName(selectedDoctor);
                }
                const selectedFacility = this.facilities.find(f => f._id === this.selectedFacilityId);
                if (selectedFacility) {
                    transcription.facility_id = selectedFacility._id;
                    transcription.facility_title = selectedFacility.title;
                }

            }

            const selectedPatient = this.patients.find(p => p._id === this.selectedPatientId);
            if (selectedPatient) {
                transcription.patient_id = selectedPatient._id;
                transcription.patient_name = this.commonService.getPatientFullName(selectedPatient);
            }

            if (this.currentUser.user_type == 1) {
                transcription.is_published = 'true';
                transcription.is_locked = true;
            } else {
                transcription.is_published = 'false';
                transcription.is_locked = this.save_submit;
            }

            // transcribt.transcribtion_text =this.encryptDecryptService.encrypt(this.htmlContent); // decrypt transaction_text // removed client side encryption #mohsin-dev
            // this.encryptDecryptService.encrypt(this.htmlContent).subscribe(data => {

            // encrypt transcription_text with server #mohsin-dev
            // transcription.transcribtion_text = String(data);
            // });
            this.noteEditorService.create(transcription, this.category).subscribe((response: any) => {
                // this.response = response;
                if (response.status == 200) {
                    this.toastr.success('Success', response.message);
                    this.isNew = false;
                    // this.goBack();
                    this.loading = false;
                    this.selectedFile = response.data;
                    // localStorage.setItem('selectedFile', JSON.stringify(this.response.data));
                    // localStorage.setItem('transcriptionFiles', JSON.stringify([this.response.data, ...this.transcriptionFiles]));

                    // this._router.navigate(['/transcription-create', {category: this.category}])

                    if (this.isAddMore) {
                        const routeMapParams: any = { category: this.category, new: 1 };
                        const audio_id_param = this._route.snapshot.paramMap.get('audio_id');
                        if (audio_id_param) {
                            routeMapParams.audio_id = audio_id_param;
                        }
                        if (!options?.preventReload) {
                            this.reloadScreen(routeMapParams);
                        }
                        // this.reloadScreen({category: this.category, new: 1, addMore: 1});
                    } else {
                        const id = response.data._id
                        const routeMapParams: any = { category: this.category, id };
                        if (this.category == 'dict' && response.data.audio_id) {
                            routeMapParams.audio_id = response.data.audio_id
                        }
                        if (!options?.preventReload) {
                            if (this.save_submit) {
                                this.goBack();
                            } else {
                                this.reloadScreen(routeMapParams);
                            }
                        }

                        // this.goBack();
                    }
                    resolve(response.data);
                } else {
                    this.toastr.error('Failed', response.message);
                }
            });


        })

    }

    saveExistingTranscription = async (transcription, callback = null, options?) => {
        return new Promise<void>(async (resolve, reject) => {
            this.loading = true;

            const {
                _id: transcribtion_id,
                transcribtion_text
            } = transcription;
            const data = {
                transcribtion_id,
                transcribtion_title: this.generateTranscriptionTitle(),
                transcribtion_text
            }

            this.noteEditorService.updateExisting(data, this.category).subscribe((response: any) => {
                // this.response = response;
                if (response.status == 200) {
                    this.saveInitialState();
                    if (typeof callback === 'function') {
                        callback();
                    } else {
                        this.toastr.success('Success', response.message);
                        // this.goBack();
                        // this.reloadScreen();
                        if (this.transcriptionFiles) {
                            this.transcriptionFiles = this.transcriptionFiles.map((t: any) => {
                                if (t._id === this.selectedFile._id) {
                                    return { ...t, ...transcription };
                                }
                                return t;
                            });
                            this.loading = false;
                        }
                    }
                    resolve(null);
                } else {
                    this.toastr.error('Failed', response.message);
                }
            });

        })

    };


    changeCurrentAudioStatus(statusId) {
        let tempPath = this.note.audio.aws_path;
        this.createpdfservice.changeCurrentAudioStatus(this.note.audio, statusId).subscribe((response: any) => {
            if (response.status === 200) {
                this.note.audio = response.data;
                // if (this.note.audio.aws_path.indexOf('s3.amazonaws.com') === -1) {
                //     this.encryptDecryptService.decrypt(this.note.audio.aws_path, 'd7XBUv]Ms<[U{4KH+YyJ<ep5+-mnaE').subscribe(
                //         data => {
                //             // this.note.audio.aws_path = data;
                //         }, error => {
                //         }
                //     );
                // }
                this.note.audio.aws_path = tempPath;
                // this.note.audio.progress_status = (this.note.audio.progress_status === '19') ? 'IN PROGRESS' : 'COMPLETED';
            }

        });
    }

    /*
    Sign Transcription sets is_signed flag to true in the database #mohsin-dev
    */
    async signTranscription(is_signed) {
        this.loading = true;
        const transcription = this.initTranscription(this.selectedFile);
        transcription.is_signed = is_signed;
        transcription.is_completed = 'false';
        transcription.transcribtion_id = this.selectedFile._id;

        if (is_signed == 'true') {
            await this.saveExistingTranscription(transcription, () => {
            });
            this.config.editable = false;
        } else {
            this.config.editable = true;
            
        }

        // ACTIVITY LOG
        this._socketService.addActivityLog({
            id: this.currentUser._id,
            screen: 'Update Note',
            operation: 'Sign Note Status Change API Call',
            datetime: this._date_pipe.transform(new Date(), global.date_time_format)
        });

        //START signTranscription
        this.noteEditorService.signTranscription(transcription, this.category).subscribe((response: any) => {
            if (response.status == 200) {
                this.toastr.success('Success', response.message);
                // this.goBack()
                // if (is_signed == 'true') {
                //     // this.navigateEditTranscription(1);
                //     // this.initializeData();
                //     this.reloadScreen();
                // } else {
                //     this.reloadScreen();
                // }
            } else {
                this.toastr.error('Failed', response.message);
            }
            this.loading = false;
        }, (error) => {

            console.error(error);
            this.toastr.error('Something went wrong, please try again', 'Error');
            this.loading = false;
        });
        //END signTranscription
    }

    async completeTranscription(is_completed) {
        this.loading = true;
        const transcription = this.initTranscription(this.selectedFile);
        transcription.is_completed = is_completed;
        transcription.transcribtion_id = this.selectedFile._id;
        const toastInfo = this.toastr.info('Processing...', 'Please wait', { disableTimeOut: true });

        // ACTIVITY LOG
        this._socketService.addActivityLog({
            id: this.currentUser._id,
            screen: 'Update Note',
            operation: 'Upload Note Status Change API Call',
            datetime: this._date_pipe.transform(new Date(), global.date_time_format)
        });

        const response: any = await this.noteEditorService.completeTranscription(transcription).toPromise();
        if (response.status === 200) {
            this.toastr.success('Success', response.message);
            this.saveInitialState();
            // this.reloadScreen();
        } else {
            this.toastr.error(response.message, 'Failed');
        }
        this.loading = false;
        this.toastr.clear(toastInfo.toastId);
    }

    isSignAndUploadVisibleForNewNote() {
        if (this.isNew && this.currentUser.user_type == global.USER_TYPE.DOCTOR) {
            const patient = this.note.patient;

            if (patient?.pcc_patientId || patient?.source === 'PointClickCare') {
                return true;
            }
        }
        return false;
    }
    async signAndUploadTranscription() {

        if (!this._pccService.pccUserinfo) {
            this.toastr.error('Please login with PCC first', 'Error')
            return false;
        }

        let selectedPatient;
        if (Array.isArray(this.patients) && this.selectedPatientId) {
            selectedPatient = this.patients.find(p => p._id === this.selectedPatientId);
        } else {
            selectedPatient = this.selectedPatient
        }

        if (!selectedPatient.pcc_patientId) {
            this.toastr.error('Selected patient is not imported from pcc', 'Error')
            return false;
        }

        if (!this.validateTranscription()) {
            return false;
        }


        if (!this.selectedFile) {
            this.loading = true;

            this.selectedFile = {};
            this.selectedFile = await this.saveTranscription({ preventReload: true })
            this.selectedFile.facility_id_ref = this.note.facility;
            const routeMapParams: any = { category: this.category, id: this.selectedFile._id };
            if (this.category == 'dict' && this.selectedFile.audio_id) {
                routeMapParams.audio_id = this.selectedFile.audio_id
            }
            let url = `/transcription-create;category=${routeMapParams.category};id=${this.selectedFile._id}`;
            if (routeMapParams.audio_id) {
                url += `;audio_id=${routeMapParams.audio_id}`
            }
            this._location.go(url)
            this.config.editable = false;

        }

        this.showUploadNoteConfirmationDialog({ type: 'note' }, async () => {


            const transcription = this.initTranscription(this.selectedFile);
            transcription.is_signed = 'true';
            transcription.is_completed = 'true';
            transcription.transcribtion_id = this.selectedFile._id;

            await this.saveExistingTranscription(transcription, () => {
            }, { preventReload: true });

            // ACTIVITY LOG
            this._socketService.addActivityLog({
                id: this.currentUser._id,
                screen: 'Update Note',
                operation: 'Sign Note Status Change API Call',
                datetime: this._date_pipe.transform(new Date(), global.date_time_format)
            });

            //START signTranscription
            this.noteEditorService.signAndUploadTranscription(transcription, this.category).subscribe((response: any) => {
                if (response.status == 200) {
                    this.toastr.success('Success', response.message);

                    this.selectedFile.pcc_progressNoteId = response.data.pcc_progressNoteId;
                    this.selectedFile.pcc_uploaded_date = response.data.pcc_uploaded_date;
                    // this.navigateEditTranscription(1);
                    this.saveInitialState();
                    this.config.editable = false;

                } else {
                    this.toastr.error('Failed', response.message);
                }
                this.loading = false;

            }, (error) => {

                console.error(error);
                this.toastr.error('Something went wrong, please try again', 'Error');
                this.loading = false;
            });

        });
    }

    isLoggedInPCC() {
        return !!this._pccService.pccUserinfo;
    }
    // fileNameGenarator() {
    //   var user = this.currentUser;
    //   var date = new Date();
    //   var monthList = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Oct','Nov','Dec'];
    //   var hours = date.getHours();
    //   var minutes = date.getMinutes();
    //   var ampm = hours >= 12 ? 'pm' : 'am';
    //   hours = hours % 12;
    //   hours = hours ? hours : 12; // the hour '0' should be '12'
    //   var minutesCon = minutes < 10 ? '0'+minutes : minutes;
    //   var time = hours + '_' + minutesCon + '_' + ampm;
    //   var name = user.first_name.replace(' ','_')+'_'+user.last_name.replace(' ','_')+'_'+monthList[date.getMonth()] + "_" + date.getDate() + "_" + date.getFullYear() + "_" + time;
    //   // Removed file extension .docx from title string #mohsin-dev
    //   return date.getTime() + '_' +name; // Added timestamp #mohsin-dev
    // }
    generateTranscriptionTitle() {
        // const white_space = '_';
        // const datetime = this._date_pipe.transform(this.currentDate, 'MMM dd yyyy mm ss aaaaa\'m\'');
        // const timestamp = this.currentDate.getTime();
        // const selectedPatientName = this.selectedPatient ? `${this.selectedPatient.first_name.trim()} ${this.selectedPatient.middle_name && this.selectedPatient.middle_name + ' '}${this.selectedPatient.last_name.trim()}` : "";
        // const generatedTitle = `${timestamp} ${selectedPatientName.trim()} ${datetime}`;
        // return generatedTitle.replace(/\s/g,white_space);

        return this.currentDate.getTime() + '_' + this.title ? this.title.trim().replace(/\s/g, '_') : '';
    }

    formattedDataGenarator() {
        var date = new Date();
        var monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Oct', 'Nov', 'Dec'];
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        var minutesCon = minutes < 10 ? '0' + minutes : minutes;
        var time = hours + ':' + minutesCon + ' ' + ampm;
        var formatted = monthList[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear() + ' ' + time;
        return formatted;
    }

    checkAudioIdExists() {
        return !!this.currentaudio;
    }
    /*
    * Fixed go back button #mohsin-dev
    *
    */
    goBack() {
        if (!this.compareOriginalNoteState()) {
            if (!confirm('If you leave before saving, your changes will be lost.')) {
                return;
            }
        }
        const backUrl = this.goBackUrlService.getBackUrl({
            // '/transcription-detail': '/dashboard;category=dict;status=All_Dictations',
        });
        if (backUrl) {
            this.router.navigateByUrl(backUrl);
        } else {
            this._location.back();
        }
        // this.router.navigateByUrl(`/census/${census_id};telemedicine=${telemedicine}`);
        // this.goBack();
        // if(this.category === 'dict') {
        //   this.router.navigate(['/transcription-detail', {category: this.category }])
        // } else {
        //   this.router.navigate(['/dashboard', {category: this.category }])
        // }
        //
        // this.router.navigate(['/dashboard', {category: 'tran', status: 'Un_Signed'}]);
    }

    /**
     * Created iniTranscription to reuse function #mohsin-dev
     */
    initTranscription(transcription?) {
        try {
            if (!transcription) {
                transcription = {};
            }
            transcription.id = this.currentUser._id;
            transcription.usertype = this.currentUser.user_type;
            transcription.auth_key = this.currentUser.auth_key;

            // transcription.transcribtion_id = this.selectedFile ? this.selectedFile._id : null; // added missing transcribtion_id field #mohsin-dev
            transcription.transcribtion_title = this.generateTranscriptionTitle(); // added transcribtion_title field #mohsin-dev
            transcription.transcribtion_text = this.htmlContent; // added transcribtion_text field #mohsin-dev


            transcription.doctor_name = this.selectedDoctorName;
            transcription.patient_id = this.selectedPatientId;
            if (this.selectedPatient) {
                transcription.patient_name = this.commonService.getPatientFullName(this.selectedPatient);
            }
            transcription.date_of_service = this.date_of_service;
            // transcription.date = this.date;

            return transcription;
        } catch (error) {

            this.toastr.error('Something went wrong, please try again', 'Error');
            this.router.navigate(['/dashboard'])
        }
    }

    /**
     * Get Facilities by doctor
     */
    async getFacilities(doctorId) {

        // this.loading = true;
        const response: any = await this.noteEditorService.getFacilities(doctorId).toPromise();
        if (response.status === 200) {
            this.facilities = response.data.array;
        }
        if (this.isNew) {
            this.selectedFacilityId = '';
        }
        // this.loading = false;
    }

    reviseNote(is_published) {
        // @ts-ignore
        // @ts-ignore
        swal({
            title: "Are you sure?",
            text: "Once revised, you will not be able to undo this!",
            icon: "warning",
            buttons: [
                true, true
            ],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    this.publishTranscription(is_published);
                } else {
                }
            });
    }
    /*
    Publish Transcription sets is_published flag to true|false in the database #mohsin-dev
    */
    publishTranscription(is_published) {
        if (this.htmlContent.trim().length === 0) {
            this.toastr.error('Please enter text and try again', 'Failed');
            return;
        }
        this.loading = true;

        const transcription = this.initTranscription(this.selectedFile);
        transcription.is_published = is_published;
        transcription.is_signed = 'false';
        const infoToast = this.toastr.info('Processsing...', 'Please wait', { disableTimeOut: true });

        const {
            _id: transcribtion_id,
            transcribtion_text
        } = transcription;
        const data = {
            transcribtion_id,
            transcribtion_title: this.generateTranscriptionTitle(),
            transcribtion_text,
            is_published: is_published,
            is_signed: 'false'
        }

        this.saveExistingTranscription(data, async () => {

            // ACTIVITY LOG
            this._socketService.addActivityLog({
                id: this.currentUser._id,
                screen: 'Update Note',
                operation: 'Publish Note Status Change API Call',
                datetime: this._date_pipe.transform(new Date(), global.date_time_format)
            });
            const {
                _id: transcribtion_id,
            } = transcription;
            const _data = {
                transcribtion_id,
                is_published: is_published,
                is_signed: 'false'
            }
            const publishTranscriptionResponse: any = await this.noteEditorService.publishTranscription(_data, this.category).toPromise();

            if (publishTranscriptionResponse.status == 200) {
                if (is_published == 'true') {
                }
                // this.goBack();
                this.reloadScreen();

            } else {
                this.toastr.error('Failed', publishTranscriptionResponse.message);
            }
            this.toastr.clear(infoToast.toastId);
            this.loading = false;
        });
    }


    getCurrentNoteIndex() {
        return this.dashboardData.notes.findIndex(note => note._id === this.note._id);
    }
    getNextPrevNoteUrl(nextPrev: 1 | -1) {
        const currentIndex = this.getCurrentNoteIndex();
        if (currentIndex !== -1) {
            const nextIndex = currentIndex + nextPrev;
            if (nextIndex >= 0 && nextIndex < this.dashboardData.notes.length) {
                const noteId = this.dashboardData.notes[nextIndex]._id;
                if (noteId) {
                    return `/note-editor/${noteId}`;
                }
            }
        }
    }

    getCurrentAudioIndex() {
        return this.dashboardData.audios.findIndex(audio => audio._id === this.note?.audio?._id);
    }
    async getNextPrevAudioUrl(nextPrev: 1 | -1) {
        const currentIndex = this.getCurrentAudioIndex();
        if (currentIndex !== -1) {
            const nextIndex = currentIndex + nextPrev;
            if (nextIndex >= 0 && nextIndex < this.dashboardData.audios.length) {
                const audioId = this.dashboardData.audios[nextIndex]._id;
                if (audioId) {
                    const audioNoteResponse: any = this.dashboardService.checkAudioNote(audioId).toPromise();
                    if (audioNoteResponse.status === 200) {
                        return `/note-editor/${audioNoteResponse.data._id}`;
                    } else {
                        return `/note-editor/new;audio_id=${audioId}`;
                    }
                }
            }
        }
    }
    async initNextPrev() {
        this.dashboardData.note.prev = this.getNextPrevNoteUrl(-1);
        this.dashboardData.note.next = this.getNextPrevNoteUrl(1);

        this.dashboardData.audio.prev = await this.getNextPrevAudioUrl(-1);
        this.dashboardData.audio.next = await this.getNextPrevAudioUrl(1);
    }

    navigate(url) {
        if(this.buttonsState.save){
            this.saveNote()
            // this._router.navigateByUrl(url);
            this.router.navigateByUrl('/note-editor', { skipLocationChange: true }).then(() => {
                this.router.navigateByUrl(url + ';disable-sidebar=1');
            })
        }
        else{
            this.router.navigateByUrl('/note-editor', { skipLocationChange: true }).then(() => {
                this.router.navigateByUrl(url + ';disable-sidebar=1');
            })
        }
    }

    isChooseTemplateButtonVisible() {
        if (!this.isNew && this.currentUser.user_type == 1 && this.selectedFile && this.currentUser._id !== this.selectedFile.uploaded_by_id) {
            return false;
        }

        if (this.selectedFile && this.selectedFile.is_published == 'true') {
            return false;
        }

        return true;
    }

    showTemplateDialog() {
        if (!this.note.facility) {
            return this.toastr.error('Please select facility first');
        }
        if (!this.note.patient && this.note.patient?._id) {
            return this.toastr.error('Please select patient first');
        }

        this.showOnceTemplateDialog = true;
        const dialogRef = this.dialog.open(TranscriptionTemplateDialog, {
            width: '600px',
            data: {
                currentTemplate: this.currentTemplate,
                currentUser: this.currentUser,
                facilityId: this.note.facility._id,
                patientId: this.note.patient?._id || this.note.patient_id,
                providerId: this.note.provider?._id
            }
        });
        dialogRef.afterClosed().subscribe(async (data) => {
            try {
                this.autocomplete.closePanel()
            } catch (e) { }
            if (!data) {
                return;
            }
            const { blank = null, template = null, note = null } = data;

            if (note) {
                this.handleNoteSelect(note);
            } else if (blank) {
                this.note.title = '';
                this.note.htmlText = '';
            } else if (template) {
                this.handleTemplateSelect(template)
            }
        });
    }
    showPatientDialog() {
        if (!this.note.facility) {
            return this.toastr.error('Please select facility first');
        }

        const dialogRef = this.dialog.open(PatientsDialog, {
            width: '80%',
            data: {
                provider: this.note.provider,
                facility: this.note.facility,
                patient: this.note.patient
            }
        });
        dialogRef.afterClosed().subscribe(async (patient) => {
            if (patient) {
                const patientIndex = this.patients.findIndex(p => p._id === patient._id);
                if (patientIndex >= 0) {
                    this.patients[patientIndex] = patient;
                } else {
                    this.patients.push(patient);
                }
                this.note.patient = patient;
            }
        });
    }

    showPhraseDialog() {
        const dialogRef = this.dialog.open(DictionaryDialogComponent, {
            width: '400px'
        });

        dialogRef.afterClosed().subscribe(async (data) => {

            this.loader.startBackgroundLoader(this.loaderId);
            await this.getPhrases();
            this.loader.stopBackgroundLoader(this.loaderId);

        });

    }

    showDictionaryEditorDialogComponent() {
        const dialogRef = this.dialog.open(DictionaryEditorDialogComponent, {
            width: '800px'
        });

        dialogRef.afterClosed().subscribe(async (data) => {

            // this.loader.startBackgroundLoader(this.loaderId);
            // await this.getPhrases();
            // this.loader.stopBackgroundLoader(this.loaderId);

        });

    }

    showPatientAddDialog() {
        const activeElement = document.activeElement as HTMLInputElement;
        if (activeElement) {
            activeElement.blur();
        }
        const dialogRef = this.dialog.open(PatientAddDialog, {
            width: '80%',
            data: {
                selectedFacilityId: (this.category === 'dict' && this.currentaudio) ? this.currentaudio.facility_id : this.selectedFacilityId,
                selectedDoctorId: this.selectedDoctorId
            }
        });
        dialogRef.beforeClosed().subscribe(async (patient: any) => {
            if (patient && patient._id) {
                patient.name = this.commonService.getPatientFullNameFormat2(patient);

                this.patients.push(patient);
                this.note.patient = patient;
            }
        });
    }
    showAddendumNoteDialog() {
        const dialogRef = this.dialog.open(AddendumNoteDialog, {
            width: '50%',
            data: {
                noteId: this.selectedFile._id
            }
        });
        dialogRef.beforeClosed().subscribe(async (data: any) => {

        });
    }
    showUploadNoteConfirmationDialog(options, onConfirmation?: Function) {
        const { type, confirmButtonLabel, noteId } = options;
        console.log("ran");
        const dialogRef = this.dialog.open(UploadNoteConfirmationDialog, {
            width: '80%',
            data: {
                type,
                noteId: this.note._id || noteId,
                rawText: !this.isCreatingAddendum ? this.note.htmlText : this.addendum.text,
                payload: this.addendum,
                confirmButtonLabel
            }
        });
        dialogRef.beforeClosed().subscribe(async (confirm: any) => {
            if (confirm) {
                if (typeof onConfirmation === 'function') {
                    onConfirmation(confirm);
                }
            }
        });
    }


    doCheckIsTouched() {
        if (this.isNew) {
            const patient = this.selectedPatientId || this.selectedPatient;
            this.isTouched = this.htmlContent.length > 0 && patient && !!this.selectedFacilityId && !!this.title;
        } else if (this.selectedFile && this.htmlContent) {
            this.isTouched = JSON.stringify([this.selectedFile,
            this.htmlContent, this.title]) !== this.initialState;
        }
        return this.isTouched;
    }

    doCheckExistInTextNote() {
        if (this.note?.htmlText) {
            const e = document.createElement('div');
            e.innerHTML = this.note.htmlText;
            const noteText = e.innerText.toLowerCase();

            const selectedFacility = this.note.facility;
            const selectedProvider = this.note.provider;
            const selectedPatient = this.note.patient;
            const dateOfService = moment(this.getNoteDOS());

            // if(selectedFacility && typeof selectedFacility !== 'string') {
            //     this.existInNoteText.facility = noteText.includes(selectedFacility.title.toLowerCase().trim())
            // }
            // if(selectedProvider && typeof selectedFacility !== 'string') {
            //     this.existInNoteText.provider = new RegExp(`${selectedProvider.first_name}.+?${selectedProvider.last_name}`, 'i').test(noteText);
            // }
            // if(selectedPatient && typeof selectedFacility !== 'string') {
            //     this.existInNoteText.patient = noteText.includes(`${selectedPatient.name}`.toLowerCase().trim())
            // }
            if (dateOfService.isValid()) {
                this.existInNoteText.date_of_service = noteText.includes(dateOfService.format('M/D/YYYY')) || noteText.includes(dateOfService.format('MM/DD/YYYY'));
            }

        }
    }

    doCheckDateOfServiceMatch() {
        if (this.note) {
            if (this.note.hasAudio()) {
                const exactMatch = this.note.audio.filterServiceDate === this.note.dates.service;
                //deep clone start
                let sDateAudio = JSON.parse(JSON.stringify(this.note.audio.filterServiceDate));
                let sDateNote = JSON.parse(JSON.stringify(this.note.dates.service));
                //deep clone end
                const dateAudio = moment(new Date(sDateAudio).toLocaleString('en-US', { timeZone: this.note.audio.timezone })).format('MM/DD/YYYY')
                const dateNote = moment(new Date(sDateNote).toLocaleString('en-US', { timeZone: this.note.dates.timezone })).format('MM/DD/YYYY')

                const matchDateOnly = dateAudio === dateNote;
                //mohsin
                // const matchDateOnly = moment(this.note.audio.filterServiceDate).format('MM/DD/YYYY') === moment(this.note.dates.service).format('MM/DD/YYYY');
                if (exactMatch) {
                    this.isMatchAudioDOSWithNoteDOS = true;
                } else if (matchDateOnly) {
                    // this.note.dates.service = this.note.audio.filterServiceDate;
                    this.isMatchAudioDOSWithNoteDOS = true;
                } else {
                    this.isMatchAudioDOSWithNoteDOS = false;
                }
            }
        }
    }

    pccInProgress() {
        return this._pccService.inprogress;
    }

    showOnceTemplateDialog = false;

    checkAndShowOnceTemplateDialog() {
        if (this.isNew && !this.showOnceTemplateDialog) {
            if (this.note.facility && this.note.patient && this.note.patient._id) {
                this.showTemplateDialog();
            }
        }
    }

    doCheckButtonStates() {
        if (!this.note) return;

        if (this.isNew && !this.note.isInvalid()) {
            this.buttonsState.save = true;
            if (this.isUserType(global.USER_TYPE.DOCTOR)) {
                // this.buttonsState.sign = true; // Before false, Now True: Beacuse now on sign note save api calls fist
            }
            if (this.note.htmlText.length > 0 && this.buttonsState.save === true) {
                // console.log("now true")
                this.pccWidgetService.changeSaveNoteStatus(true);
                this.pccWidgetService.changeNote(this.note);
            }
            return;
        }
        if (!this.isNew && this.note.isInvalid()) {
            this.buttonsState.save = false;
            // this.buttonsState.sign = false;

            if (!this.note?.coSign_status?.status && this.note?.provider?._id == this.currentUser._id && !this.note.isSigned()) {
                this.buttonsState.sign = true;
            }
            else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && !this.note.isSigned()) {
                this.buttonsState.sign = true;
            }
            else {
                this.buttonsState.sign = false;
            }
            return
        }

        // Note Buttons
        if (this.note.isInProgress(this.currentUser._id, this.relationship)) {
            // console.log('isOwner');
            this.config.editable = true;

            if (!this.compareOriginalNoteState()) {
                this.buttonsState.save = true;
                this.buttonsState.submitForReview = false;

                if (this.note.htmlText.length > 0 && this.buttonsState.save === true) {
                    // console.log("now true")
                    this.pccWidgetService.changeSaveNoteStatus(true);
                    this.pccWidgetService.changeNote(this.note);
                }

            } else {
                this.buttonsState.save = false;
                this.buttonsState.submitForReview = true;
            }
        }
        else if (this.note.isInMAReview(this.currentUser._id, this.relationship)) {
            // console.log('isMAI');
            this.config.editable = true;

            if (!this.compareOriginalNoteState()) {
                this.buttonsState.save = true;
                this.buttonsState.submitForReview = false;
                this.buttonsState.revert = false;

                if (this.note.htmlText.length > 0 && this.buttonsState.save === true) {
                    // console.log("now true")
                    this.pccWidgetService.changeSaveNoteStatus(true);
                    this.pccWidgetService.changeNote(this.note);
                }

            } else {
                this.buttonsState.save = false;
                this.buttonsState.submitForReview = true;
                this.buttonsState.revert = false;
            }
        }
        else if (this.note.isInQAIReview(this.currentUser._id, this.relationship)) {
            // console.log('isQAI');
            this.config.editable = true;

            if (!this.compareOriginalNoteState()) {
                this.buttonsState.save = true;
                this.buttonsState.submitForReview = false;
                this.buttonsState.revert = false;
                if (this.note.htmlText.length > 0 && this.buttonsState.save === true) {
                    // console.log("now true")
                    this.pccWidgetService.changeSaveNoteStatus(true);
                    this.pccWidgetService.changeNote(this.note);
                }
            } else {
                this.buttonsState.save = false;
                this.buttonsState.submitForReview = true;
                this.buttonsState.revert = true;
            }
        } else if (this.note.isInQAEReview(this.currentUser._id, this.relationship)) {
            // console.log('isQAE');
            this.config.editable = true;

            if (!this.compareOriginalNoteState()) {
                this.buttonsState.save = true;
                this.buttonsState.submitForReview = false;
                this.buttonsState.revert = false;

                if (this.note.htmlText.length > 0 && this.buttonsState.save === true) {
                    // console.log("now true")
                    this.pccWidgetService.changeSaveNoteStatus(true);
                    this.pccWidgetService.changeNote(this.note);
                }

            } else {
                this.buttonsState.save = false;
                this.buttonsState.submitForReview = true;
                this.buttonsState.revert = true;
            }
        } else if (this.note.isPublished(this.currentUser._id, this.relationship)) {
            this.config.editable = true;
            this.buttonsState.submitForReview = false;
            if (!this.compareOriginalNoteState()) {
                this.buttonsState.save = true;
                if (this.note.htmlText.length > 0 && this.buttonsState.save === true) {
                    // console.log("now true")
                    this.pccWidgetService.changeSaveNoteStatus(true);
                    this.pccWidgetService.changeNote(this.note);
                }
                if (!this.note.containsAsterisks()) {
                    // 
                    // Before false, Now True: Beacuse now on sign note save api calls fist
                    if (!this.note?.coSign_status?.status && this.note?.provider?._id == this.currentUser._id && !this.note.isSigned()) {
                        this.buttonsState.sign = true;
                    }
                    else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && !this.note.isSigned()) {
                        this.buttonsState.sign = true;
                    }
                    else {
                        this.buttonsState.sign = false;
                    }
                }
                this.buttonsState.revert = false;
            } else {
                this.buttonsState.save = false;
                if (!this.note.containsAsterisks()) {
                    // 
                    // Before false, Now True: Beacuse now on sign note save api calls fist
                    if (!this.note?.coSign_status?.status && this.note?.provider?._id == this.currentUser._id && !this.note.isSigned()) {
                        this.buttonsState.sign = true;
                    }
                    else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && !this.note.isSigned()) {
                        this.buttonsState.sign = true;
                    }
                    else {
                        this.buttonsState.sign = false;
                    }
                }
                if ((this.note?.coSign_status?.coSigned_to == this.currentUser._id)) {
                    this.buttonsState.revert = false
                }
                else if (this.note.uploadedBy?._id !== this.currentUser._id) {
                    if (this.relationship?.assoc_ma_ids?.includes(this.note.uploadedBy?._id)) {
                        this.buttonsState.revert = false;
                    }
                    else {
                        this.buttonsState.revert = true;
                    }
                } else {
                    this.buttonsState.revert = false;
                }
            }
        } else if (this.note.hasPCCPatient()) {
            if (this.note.isUploaded()) {
                this.buttonsState.save = false;
                this.buttonsState.submitForReview = false;
                this.buttonsState.revert = false;
                if (!this.note?.coSign_status?.status && this.note?.provider?._id == this.currentUser._id && !this.note.isSigned()) {
                    this.buttonsState.sign = true;
                }
                else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && !this.note.isSigned()) {
                    this.buttonsState.sign = true;
                }
                else {
                    this.buttonsState.sign = false;
                }
                this.buttonsState.upload = false;
                if (!this.isCreatingAddendum) {
                    this.config.editable = false;
                }
            } else if (this.note.isSigned()) {
                // this.buttonsState.sign = false;
                if (!this.note?.coSign_status?.status && this.note?.provider?._id == this.currentUser._id && !this.note.isSigned()) {
                    this.buttonsState.sign = true;
                }
                else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && !this.note.isSigned()) {
                    this.buttonsState.sign = true;
                }
                else {
                    this.buttonsState.sign = false;
                }
                this.buttonsState.save = false;
                this.buttonsState.submitForReview = false;
                this.buttonsState.revert = false;
                this.config.editable = false;

                if (this.hasLoggedInToPCC()) {
                    if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && this.note.isSigned()) {
                        this.buttonsState.upload = true;
                    }
                    else if (this.currentUser.user_type === global.USER_TYPE.DOCTOR && !this.note.isRelatedProvider(this.currentUser._id)) {
                        this.buttonsState.upload = false;
                    }
                    // else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && this.note.isSigned()){
                    //     this.buttonsState.upload = true;
                    // }
                    else {
                        this.buttonsState.upload = true;
                    }
                } else {
                    this.buttonsState.upload = false;
                }
                // this.buttonsState.upload = true;

                // if(this.note.isSigned(this.currentUser._id)) {
                //     this.buttonsState.revert = true;
                // }
            } else {
                if (this.note.provider._id === this.currentUser._id && this.note.note_status.status === 'published') {
                    this.buttonsState.sign = true;
                } else {
                    this.buttonsState.sign = false;
                }
                if (!this.note?.coSign_status?.status && this.note?.provider?._id == this.currentUser._id && !this.note.isSigned()) {
                    this.buttonsState.sign = true;
                }
                else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && !this.note.isSigned()) {
                    this.buttonsState.sign = true;
                }
                else {
                    this.buttonsState.sign = false;
                }
                this.buttonsState.save = false;
                this.buttonsState.submitForReview = false;
                this.buttonsState.revert = false;
                this.buttonsState.upload = false;
                if (this.relationship?.assoc_ma_ids?.includes(this.currentUser._id)) {
                    this.config.editable = true;
                    if (!this.compareOriginalNoteState()) {
                        this.buttonsState.save = true;
                        if (this.note.htmlText.length > 0 && this.buttonsState.save === true) {
                            // console.log("now true")
                            this.pccWidgetService.changeSaveNoteStatus(true);
                            this.pccWidgetService.changeNote(this.note);
                        }
                    } else {
                        this.buttonsState.save = false;
                    }

                }
            }
        } else if (!this.note.hasPCCPatient()) {
            if (this.note.isUploaded()) {
                this.buttonsState.save = false;
                this.buttonsState.submitForReview = false;
                this.buttonsState.revert = false;
                // this.buttonsState.sign = false;
                if (!this.note?.coSign_status?.status && this.note?.provider?._id == this.currentUser._id && !this.note.isSigned()) {
                    this.buttonsState.sign = true;
                }
                else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && !this.note.isSigned()) {
                    this.buttonsState.sign = true;
                }
                else {
                    this.buttonsState.sign = false;
                }
                this.buttonsState.upload = false;
                this.buttonsState.revert = false;
                if (!this.isCreatingAddendum) {
                    this.config.editable = false;
                }

            } else if (this.note.isSigned()) {
                this.buttonsState.save = false;
                this.buttonsState.submitForReview = false;
                this.buttonsState.revert = false;
                if (!this.note?.coSign_status?.status && this.note?.provider?._id == this.currentUser._id && !this.note.isSigned()) {
                    this.buttonsState.sign = true;
                }
                else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && !this.note.isSigned()) {
                    this.buttonsState.sign = true;
                }
                else {
                    this.buttonsState.sign = false;
                }
                // below if-else changed so the initator of co-sign after co-sigining the note will not be able to perform other tasks
                // this.buttonsState.upload = true;

                if (!this.note?.coSign_status?.status && this.note?.provider?._id == this.currentUser._id) {
                    this.buttonsState.upload = true;
                }
                else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to) {
                    this.buttonsState.upload = true;
                }
                else if (this.currentUser.user_type == global.USER_TYPE.TRANSCRIBER || this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
                    this.buttonsState.upload = true;
                } {
                    this.buttonsState.sign = false;
                }
                // this.buttonsState.revert = true;
                if (!this.isCreatingAddendum) {
                    this.config.editable = false;
                }
            } else {
                this.buttonsState.save = false;
                this.buttonsState.submitForReview = false;
                this.buttonsState.revert = false;
                if (!this.note?.coSign_status?.status && this.note?.provider?._id == this.currentUser._id && !this.note.isSigned() && !this.isNew) {
                    this.buttonsState.sign = true;
                }
                else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && !this.note.isSigned() && !this.isNew) {
                    this.buttonsState.sign = true;
                }
                else {
                    this.buttonsState.sign = false;
                }
                this.buttonsState.upload = false;
                this.buttonsState.revert = false;
                if (this.relationship?.assoc_ma_ids?.includes(this.currentUser._id)) {
                    this.config.editable = true;
                    if (!this.compareOriginalNoteState()) {
                        this.buttonsState.save = true;
                        if (this.note.htmlText.length > 0 && this.buttonsState.save === true) {
                            // console.log("now true")
                            this.pccWidgetService.changeSaveNoteStatus(true);
                            this.pccWidgetService.changeNote(this.note);
                        }
                    } else {
                        this.buttonsState.save = false;
                    }

                }


            }

        } else {

            this.buttonsState.save = false;
            this.buttonsState.submitForReview = false;
            this.buttonsState.revert = false;
            // this.buttonsState.sign = false;
            if (!this.note?.coSign_status?.status && this.note?.provider?._id == this.currentUser._id && !this.note.isSigned()) {
                this.buttonsState.sign = true;
            }
            else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && !this.note.isSigned()) {
                this.buttonsState.sign = true;
            }
            else {
                this.buttonsState.sign = false;
            }
            this.buttonsState.upload = false;
            this.buttonsState.revert = false;
            

            if (this.isNew) {
                this.config.editable = true;
            }
            else {
                this.config.editable = false;
            }
        }


        if (this.isCreatingAddendum && this.addendum && this.isLoggedInPCC()) {
            this.buttonsState.uploadAddendum = true;
        } else {
            this.buttonsState.uploadAddendum = false;
        }

        // extra filter applied as pipeline
        if (this.note.containsAsterisks()) {
            // // this.buttonsState.sign = false;
            // if (!this.note?.coSign_status?.status && this.note?.provider?._id == this.currentUser._id && !this.note.isSigned()) {
            //     this.buttonsState.sign = true;
            // }
            // else if (this.note?.coSign_status?.status && this.currentUser._id == this.note?.coSign_status?.coSigned_to && !this.note.isSigned()) {
            //     this.buttonsState.sign = true;
            // }
            // // }
            // else {
            //     this.buttonsState.sign = false;
            // }
            this.buttonsState.sign = false;

        }
        //to disbale functionalities for co-sign initiator
        if (this.note?.coSign_status?.status && this.note?.coSign_status?.coSigned_by == this.currentUser._id) {
            this.config.editable = false;
            this.configg.isReadOnly = true
        }
        //to disable edit functionality
        // console.log("result here",this.note.isEditLocked(this.currentUser._id));
        // if(!this.isNew && this.note.isEditLocked(this.currentUser._id)) {
        //     this.configg.isReadOnly = true;
        //     this.config.editable = false;
        // }

    }
    ngDoCheck() {
        this.doCheckIsTouched();
        this.doCheckExistInTextNote();
        this.doCheckDateOfServiceMatch();
        this.doCheckButtonStates();
        // this.doCheckShowOnceTemplateDialog(); 

    }
    isCoSigned() {
        if (this.note.coSign_status.coSigned_to == this.currentUser._id) {
            return true
        }
        else {
            return false
        }
    }
    readyToCosigned() {
        if (this.note?.note_status?.status.toLowerCase() == 'published') {
            return 'Ready to Co-sign'
        }
        else {
            return "Co-signed"
        }
    }
    coSigner() {
        if ((this.note?.coSign_status?.coSigned_by == this.currentUser._id)) {
            if (this.note.coSigned_provider?.first_name) {
                return `Co-Signer ${this.note.coSigned_provider.title},${this.note.coSigned_provider.first_name} ${this.note.coSigned_provider.last_name}`
            }
            else {
                return `Co-Signer ${this.coSignedProviderReserve?.title},${this.coSignedProviderReserve?.first_name} ${this.coSignedProviderReserve?.last_name}`
            }
        }
        else {
            return "Co-Signer"
        }
    }

    getSelectedFacility() {
        if (this.isNew) {
            if (this.currentaudio) {
                return this.currentaudio.facility_id_ref;
            } else {
                return this.facilities.find(f => f._id === this.selectedFacilityId);
            }
        } else {
            return this.selectedFile.facility_id_ref;
        }
    }
    getSelectedProvider() {
        return this.currentDoctor;
    }
    getSelectedPatient() {
        return this.selectedPatient;
    }
    getSelectedDateOfService() {
        return this.date_of_service;
    }
    async reloadScreen(routeMapParams?) {
        this.updateCurrentTranscriptionState();
        // const audio_id_param = this._route.snapshot.paramMap.get('audio_id');
        // const id_param = this._route.snapshot.paramMap.get('id');
        if (!routeMapParams) {
            routeMapParams = {
                category: this.category,
            };
            const audio_id_param = this._route.snapshot.paramMap.get('audio_id');
            const id_param = this._route.snapshot.paramMap.get('id');

            if (id_param) {
                routeMapParams.id = id_param;
            }
            if (audio_id_param) {
                routeMapParams.audio_id = audio_id_param;
            }

        }
        await this.router.navigateByUrl(`/dashboard;category=${this.category}`, { skipLocationChange: true })
        // .then(() => {
        //     // const routeMapParams:any = { category: this.category };
        //     // if(id_param) routeMapParams.id = id_param;
        //     // if(audio_id_param) routeMapParams.audio_id = audio_id_param;
        // });
        await this.router.navigate(['/transcription-create', routeMapParams]);
    }

    updateCurrentTranscriptionState() {
        if (!this.selectedFile) {
            return;
        }
        // localStorage.setItem('selectedFile', JSON.stringify(this.selectedFile));

        let transcriptionFiles = this.transcriptionFiles;
        if (transcriptionFiles) {
            const prevTranscription = this.transcriptionFiles.find(t => t._id === this.selectedFile._id);
            if (prevTranscription) {
                transcriptionFiles = this.transcriptionFiles.map(t => {
                    if (t._id === this.selectedFile._id) {
                        return this.selectedFile;
                    }
                    return t;
                });
            } else {
                transcriptionFiles = [this.selectedFile, ...this.transcriptionFiles];
            }
            localStorage.setItem('transcriptionFiles', JSON.stringify(transcriptionFiles));
        }

        this.saveInitialState();
    }

    saveInitialState() {
        this.initialState = JSON.stringify([{ ...this.selectedFile },
        this.htmlContent, this.title]);
    }

    getFilteredPatients() {

        if (!Array.isArray(this.patients)) return [];

        const facility_id = this.note.facility?._id;

        const portalPatients = [];
        const pccPatients = [];

        let resultPatients = [];
        resultPatients = _.cloneDeep(this.patients);

        if (facility_id) {
            resultPatients = resultPatients.filter(p => p.facility_id === facility_id);
        } else {
            resultPatients = [];
        }
        // Do not allow selecting Non PCC Patient in PCC Facility in Note Editor
        if (this.note.facility && this.note.facility.pcc_facId) {
            resultPatients = resultPatients.filter(p => p.pcc_patientId);
        }
        return resultPatients;
    }

    handlePatientSelect($event) {
        this.selectedPatientId = $event._id

    }

    async handleFacilityChange() {
        // console.log('handleFacilityChange');
        // this._censusPatientListService.setFacilityForCharge(this.note.facility._id);
        await this.getFacilityPatients(this.note.facility._id);
        this.patientControl.setValue('');
    }
    handlePatientChange() {
        // this._censusPatientListService.setPatientForCharge(this.note.patient)
    }
    async handleProviderChange() {
        // console.log('handleProviderChange');
        this.note.facility = null;
        this.note.patient = null;
        this.patients = [];
        this.getFacilities(this.note.provider._id);

        this.initPhrases();
        this.initCoSign()
    }
    initCoSign() {

    }
    initPhrases() {
        this.phrasesService.getNoteBuilderDictionary(this.note.provider._id).subscribe();
    }
    isPCCNote() {
        return this.note.patient?.pcc_patientId
    }
    createAddendum() {
        this.config.editable = true;
        this.isCreatingAddendum = true;
        this.addendum.text = ''; //this.note.htmlText;
        this.saveOriginalAddendumState();
    }
    uploadAddendum() {
        if (this.addendum) {
            this.addendum.action = 'upload';
        }
        this.showUploadNoteConfirmationDialog({ type: 'addendum' }, async (confirm) => {
            if (!confirm) {

                this.config.editable = false;
                this.isCreatingAddendum = false;
                this.isCreatingAddendum = false;
                this.note.htmlText = this.originaNoteText;
                // this.saveInitialState();
                return;
            }

            // this.loader.startBackgroundLoader(this.loaderId);

            this.addendumService.uploadAddendumNote({
                addendumId: this.addendum._id,
                includeNoteText: false
            }).subscribe((response: any) => {
                if (response.status === 200) {
                    this.toastr.success('Addendum uploaded successfully', 'Success');

                    this.sidebarVisible = true;

                    const { addendum_counts } = response.data;

                    this.addendum_counts = addendum_counts;

                    this.discardAddendum();
                }


            }, (error) => {
                this.toastr.error('Something went wrong, please try again', 'Failed');

            }, () => {
                // this.loader.stopBackgroundLoader(this.loaderId);
            })

        })
    }
    async saveAddendum() {

        if (this.note.hasPCCPatient()) {

            if (this.addendum) {
                this.addendum.action = 'save';
            }

            if (!this.hasLoggedInToPCC()) {
                this.showUploadNoteConfirmationDialog({ type: 'addendum' }, async (confirm) => {


                    if (!confirm) {

                        this.config.editable = false;
                        this.isCreatingAddendum = false;
                        this.isCreatingAddendum = false;
                        return;
                    }

                    this.doSaveAddendum();
                });
            } else {
                await this.doSaveAddendum();
                this.uploadAddendum();
            }

        } else {
            this.doSaveAddendum();
        }
    }

    doSaveAddendum() {

        this.loader.startBackgroundLoader(this.loaderId);

        this.addendumService.saveAddendumNote({
            addendumId: this.addendum?._id,
            noteId: this.note._id,
            addendumText: this.addendum.text,
            includeNoteText: false
        }).subscribe((response: any) => {
            if (response.status === 200) {
                this.toastr.success('Addendum saved successfully', 'Success');

                this.sidebarVisible = true;

                const { addendum, addendum_counts } = response.data;



                this.addendum_counts = addendum_counts;

                if (this.note.hasPCCPatient() && !addendum.uploadedAt) {
                    this.addendum = addendum;
                    this.saveOriginalAddendumState();
                } else {
                    this.discardAddendum();
                }

            }

        }, (error) => {
            this.toastr.error('Something went wrong, please try again', 'Failed');

        }, () => {
            this.loader.stopBackgroundLoader(this.loaderId);
        })

    }
    discardAddendum() {

        this.config.editable = false;
        this.isCreatingAddendum = false;
        this.addendum = {
            text: ''
        }
        // this.saveInitialState();
    }

    handleAddendumClick(addendum) {
        // if (this.note.hasPCCPatient() && this.note.canCreateAddendum(this.currentUser._id)) {
        if (this.note.hasPCCPatient()) {
            this.loadAddendum(addendum);
        }
    }

    async handleNoteSelect(note) {
        this.note.title = note.transcribtion_title;
        
        this.note.htmlText = this.resetPhrasesFromNoteText(note.transcribtion_text);
        // this.note.htmlText = note.transcribtion_text;

        setTimeout(() => {

            // this.applyAesterikJump();
            this.applyWhileHasPhrasesReplacement().then(() => {
                this.toastr.success('All phrases are updated')
            })
        }, 100)
    }

    resetPhrasesFromNoteText(noteText) {
        const e = document.createElement('div');
        e.innerHTML = noteText;
        const phraseNodes = Array.from(e.querySelectorAll('.phrase'));
        for (const phraseNode of phraseNodes) {
            const attrId = phraseNode.getAttribute('id');
            let phraseKey;
            if (attrId && attrId.indexOf('-') >= 0 && attrId === "dos-dn") {
                phraseKey = `.${attrId.replace('-', '@')}`;
                const replacementNode = document.createTextNode(phraseKey);
                const parentNode = phraseNode.parentNode;
                parentNode.insertBefore(replacementNode, phraseNode);
                parentNode.removeChild(phraseNode);
            }

        }
        return e.innerHTML;
    }

    async handleTemplateSelect(template) {
        this.note.title = template.title;
        this.note.htmlText = this.applyAsteriskJump(template.template_text);
        if (template.note_builder_id) {
            await this.applyNoteBuilder();
            // const { instance } = this.angularEditor;
            // await this.applyPhrasesReplacement(instance);

        }

        setTimeout(() => {
            // this.applyAesterikJump();
            this.applyWhileHasPhrasesReplacement();

            setTimeout(() => {
                const { instance } = this.angularEditor;
                const editor = instance;
                this.applyDblClickToSelectPhrases(editor);
            })
        }, 100)
    }
    applyAsteriskJump(template) {
        // const $ = cheerio.load(template);
        // $('p').each((i, $p) => {
        //     
        //     const content = $($p).html().split(/\*{3}/).map(e => `<span>${e}</span>`).join('<span class="asterisk">***</span>')
        //     $($p).html(content)
        // })
        // const updatedTemplate = $('body').html();
        // return updatedTemplate;
        return template.replace(/\*{3}/g, '<span class="asteriskjump">***</span>')
    }
    applyDblClickToSelectPhrases(editor) {
        const allElements: any = editor.container.$.querySelectorAll(
            '.phrase, .asteriskjump'
        );

        const handleDblClick = (e) => {
            e.preventDefault();
            // console.log('dblclick on asterisk')
            const sel = editor.getSelection();
            const element = sel.getStartElement();
            sel.selectElement(element);
        }
        for (const element of Array.from(allElements) as any) {
            element.addEventListener('dblclick', handleDblClick);
        }
    }
    loadAddendum(addendum) {

        this.createAddendum();
        this.addendum = addendum;
        this.saveOriginalAddendumState();
    }
    isUserType(user_type) {
        return this.currentUser.user_type == user_type;
    }

    formatNoteAuditOperation(operation) {
        //   const labels = {
        //       create: 'created',
        //       save: 'saved',
        //       delete: 'deleted',
        //       revise: 'revised',
        //       'un-sign': 'un-signed',
        //       sign: 'signed',
        //       upload: 'uploaded',
        //       publish: 'ready for review',
        //       published: 'ready for review',
        //       not_uploaded: 'not uploaded',
        //       internal_qa: 'submitted to internal QA',
        //       external_qa: 'submitted to external QA',
        //       manual_upload: 'manually uploaded',
        //       addendum_save: 'addendum saved',
        //       addendum_upload: 'addendum uploaded',
        //       addendum_uploaded: 'addendum uploaded',
        //       revert_qai: 'returned by internal QA',
        //       revert_qae: 'returned by external QA',
        //       revert_provider: 'returned by provider',
        //   }
        //   return labels[operation] || operation;
        return this.noteAuditoryHistoryService.formatOperation(operation)
    }

    getRecentAuditText() {
        if (!this.recentAudit) return '';
        const operation = this.formatNoteAuditOperation(this.recentAudit.operation);
        // if(operation.includes('addendum')) {
        //     return `An ${operation} against this note on ${moment(this.recentAudit.timestamp).format(global.moment_date_time_format)}`

        // } else {
        //     return `Note has been ${operation} on ${moment(this.recentAudit.timestamp).format(global.moment_date_time_format)}`
        // }

        const titleCaseOperation = `${operation.slice(0, 1).toUpperCase()}${operation.slice(1, operation.length)}`;
        // const dateFormatted = `${moment(this.recentAudit.timestamp).format(global.moment_date_time_format)}`;
        const dateFormatted = `${moment(new Date(this.recentAudit.timestamp).toLocaleString('en-US', { timeZone: this.note.dates.timezone })).format(global.moment_date_time_format)}`;

        if (this.note.hasPCCPatient() && this.note.isUploadedToPCC() && (operation === 'uploaded' || operation === 'addendum uploaded')) {
            return `${titleCaseOperation} into <img src="../../../assets/icons/pcc-icon.svg" /> PCC on ${dateFormatted}`;
        } else {
            if (this.isNew && operation === 'draft_save') {
                return `${titleCaseOperation} on ${dateFormatted}`;
            }
        }
    }
    diff_hour(pk, la) {

        var diff = (pk.getTime() - la.getTime()) / 1000;
        diff /= 60;
        diff /= 60;
        return diff;//Math.abs(Math.round(diff));
    }

    onDOSChange() {
        this.attachTimeToDOS();
    }

    attachTimeToDOS() {
        // if (browser.tz != note.tz){
        //     diff = 13hr
        //     dos = add diff in saving dos
        // }

        if (this.note.hasAudio()) {
            let diff = this.getDOSDiff();
            if (diff !== 0) {


                const newDate = moment(this.note.audio.filterServiceDate).subtract('days', diff).toDate();

                this.note.dates.service = newDate;
                // this.note.dates.service = this.commonService.attachTimeToDate(this.note.dates.service, this.note.audio.filterServiceDate);
            } else {
                this.note.dates.service = this.note.audio.filterServiceDate;
            }
        } else {
            this.note.dates.service = this.commonService.attachTimeToDate(this.dateOfService);
        }
    }

    getDOSDiff() {

        const audioDOS = new Date(new Date(this.note.audio.filterServiceDate).toLocaleString('en-US', { timeZone: this.note.audio.timezone }))

        return moment(audioDOS).endOf('day').diff(moment(this.dateOfService).endOf('day'), 'days');
    }
    getServiceDate() {
        // if(this.note.hasAudio()){
        //     // return moment(new Date(this.note.audio.filterServiceDate).toLocaleString('en-US', { timeZone:  this.note.audio.timezone })).format('MMM DD, YYYY h:mm a');
        //     return moment(new Date(this.note.audio.filterServiceDate)).format('MMM DD, YYYY h:mm a');
        // } else {
        //     // return moment(new Date(this.note.dates.service).toLocaleString('en-US', { timeZone:  this.note.dates.timezone })).format('MMM DD, YYYY h:mm a');
        //     return moment(new Date(this.note.dates.service)).format('MMM DD, YYYY h:mm a');

        // }
        if (this.note.hasAudio()) {
            return moment(new Date(this.note.audio.filterServiceDate).toLocaleString('en-US', { timeZone: this.note.audio.timezone })).format('MMM DD, YYYY h:mm a');
        } else if (this.note.dates) {
            return moment(new Date(this.note.dates.service).toLocaleString('en-US', { timeZone: this.note.dates.timezone })).format('MMM DD, YYYY h:mm a');
        }
        return '';

    }
    getNoteDOS() {
        return moment(new Date(this.note.dates.service).toLocaleString('en-US', { timeZone: this.note.dates.timezone })).format('MMM DD, YYYY h:mm a');
    }
    getAudioDOS() {
        return moment(new Date(this.note.audio.filterServiceDate).toLocaleString('en-US', { timeZone: this.note.audio.timezone })).format('MMM DD, YYYY h:mm a');
    }
    getAudioDOC() {
        return moment(new Date(this.note.audio.filterUploadingDate).toLocaleString('en-US', { timeZone: this.note.audio.timezone })).format('MMM DD, YYYY h:mm a');
    }
    getEndTimeForTimer() {
        let time = moment(new Date(this.note.audio.filterUploadingDate).toLocaleString('en-US', { timeZone: this.note.audio.timezone })).add(1, 'days');
        return time.toString()

    }
    getStratTimeForTimer() {
        let time = moment(new Date(this.note.audio.filterUploadingDate).toLocaleString('en-US', { timeZone: this.note.audio.timezone }));
        return time.toString()
    }

    triggerFunction() {
        this.timeUp = true;
    }
    // applyDateTimezone(date) {
    //         return this.noteEditorService.applyDateTimezone(this.note, date);
    // }
    autoSaveDraftNote() {

        this.clearAutoSaveDraftNote();
        
        if (this.isActiveAutoSaveDraftNoteTimeout === null && this.note && this.note.is_draft !== 'false') {
            this.isActiveAutoSaveDraftNoteTimeout = setTimeout(() => {
                this.isActiveAutoSaveDraftNoteTimeout = false;
                this.saveDraftNote();
            }, 10000);

            // console.log('Note AutoSave ON ', this.isActiveAutoSaveDraftNoteTimeout)
        }

    }
    autoUpdateNoteEditLock() {

        // this.updateNoteEditLock()

        // this.clearNoteEditLock();
        // if(!this.isNoteEditLockedForMe()) {
        //     this.updateNoteEditLock()

        //     if (this.note && this.note._id && !this.note.isPublished() && !this.note.isSigned() && !this.note.isUploaded()) {
        //         this.editLockTime = setTimeout(() => {
        //             this.autoUpdateNoteEditLock()
        //             console.log('Note Edit Lock ON ', this.editLockTime)
        //         }, 1000 * this.editLockTimeInterval);

        //     }
        // }

    }
    clearNoteEditLock() {

        if (this.editLockTime !== null) {
            clearTimeout(this.editLockTime);
            this.isActiveTimeout = null;
            // console.log('Note Edit Lock OFF')
        }
    }
    clearAutoSaveDraftNote() {

        if (this.isActiveAutoSaveDraftNoteTimeout !== null) {
            clearTimeout(this.isActiveAutoSaveDraftNoteTimeout);
            this.isActiveAutoSaveDraftNoteTimeout = null;
            // console.log('Note AutoSave OFF')
        }
    }
    timeCalculation() {
        // this.lastStrockedTime = new Date()
        this.totalTime = this.totalTime + this.resumeTime;
        console.log("total time: ", this.totalTime);
        this.currentStrockTime = new Date()
        // if (this.isNew && !this.startTime && this.note.htmlText) {
        if (this.isNew && !this.startTime) {
            this.lastStrockedTime = this.currentStrockTime;
            // console.log("new note but start time doesn't exist");
            //start time for a new note on key press
            this.startTime = new Date();
        }
        if (!this.isNew && !this.startTime) {
            this.startTime = new Date();
            this.lastStrockedTime = this.currentStrockTime;
            // this.totalTime = 
        }
        if (this.startTime && this.lastStrockedTime) {
            setInterval(() => {
                let timeElpased = Math.abs(this.lastStrockedTime.getTime() - (new Date()).getTime()) / 1000;
                console.log("seconds between last key stroke and current time: ", timeElpased);
                if (Number(timeElpased) > 10) {
                    // if(this.timeElapsedFlag){

                    // this.totalTime = this.startTime.setSeconds(this.startTime.getSeconds() + 10)
                    this.timeElapsedFlag = true;
                    // }
                }
                else {
                    this.resumeTime = timeElpased
                    // console.log("total time: ", this.totalTime);
                    // if(this.timeElapsedFlag){
                    // }
                    // else{
                    //     this.totalTime = timeElpased;
                    // } 

                }
                // }
            }, 1000)
            this.lastStrockedTime = new Date();
        }
    }

    handleEditorChange(e) {
        // this.timeCalculation() 

        // this.autoSaveNote();

        const { instance } = this.angularEditor;

        if (!instance) return;

    }


    replacePhraseWithOriginalKeys(noteText) {
        const tmpElement = document.createElement('div');
        tmpElement.innerHTML = noteText;
        tmpElement.querySelectorAll('span[data-phrase]').forEach((phraseElement) => {
            const phraseKey = phraseElement.getAttribute('data-phrase');
            phraseElement.outerHTML = `.${phraseKey}`;
        });
        return tmpElement.innerHTML;
    }

    async refreshPhrases() {
        const { instance } = this.angularEditor;

        // let noteText = this.note.htmlText;
        let noteText = this.note.htmlText;
        const noteTextWithPhraseKeys = this.replacePhraseWithOriginalKeys(noteText);

        this.note.htmlText = noteTextWithPhraseKeys;
        // instance.setData(noteTextWithPhraseKeys);
        setTimeout(() => {
            this.applyWhileHasPhrasesReplacement();
        });
    }
    async applyWhileHasPhrasesReplacement() {
        let phraseInfo;

        const { instance } = this.angularEditor;

        // let noteText = this.note.htmlText;
        let noteText = instance.getData();

        // ignore pcc phrase for non pcc patients
        let ignorePharses = [];
        if (!this.note.patient.pcc_patientId) {
            ignorePharses = systemDefinedPhrases.filter(phrase => phrase.key.includes('@pcc')).map(phrase => phrase.key)
        }

        while ((phraseInfo = this.phrasesService.hasPhrase(noteText, ignorePharses))) {
            let { match, key, value } = phraseInfo;
            if (value === undefined) {
                this.loader.startBackgroundLoader(this.loaderId);
                value = await this.phrasesService.resolveSystemDefinedPhraseValue(
                    key,
                    this.note
                );
                this.loader.stopBackgroundLoader(this.loaderId);
            }

            const phraseElementClass = ['phrase'];
            const isPCCPhrase = key.indexOf('@pcc') >= 0;
            if (isPCCPhrase) phraseElementClass.push('pcc-phrase');
            const isPrevNotePhrase = key.indexOf('prevnote:') >= 0;
            if (isPrevNotePhrase) phraseElementClass.push('prevnote-phrase');

            
            if (typeof value === 'string') {
                noteText = noteText.replace(new RegExp(_.escapeRegExp(key), 'g'), `<span data-phrase="${key.substring(1)}" class="${phraseElementClass.join(' ')}" id="${key.slice(1).replace('@', '-')}">${value}</span>`);
            } else if (typeof value === 'object' && value !== null && Object.prototype.hasOwnProperty.call(value, 'error')) {
                const error = (value as any).error;
                const defaultText = `<span data-phrase="${key.substring(1)}" class="${[...phraseElementClass, 'phrase-error'].join(' ')}">${error}</span> <span class="asteriskjump">***</span>`;
                noteText = noteText.replace(key, defaultText);
            }
            instance.setData(noteText);
        }
        // this.note.htmlText = noteText;
    }
    onReadyCkeditor(e) {
        const { editor } = e;
        this.ckeditorService.ckeditorRemoveTitle(editor);

        editor.on('paste', (e) => {
            const { data, editor } = e;
            if (data.type === 'text') {
                const modifiedhtml = e.data.dataValue
                    .split('<br />')
                    .join('<p><br /></p>');
                // @ts-ignore
                var filter = new CKEDITOR.filter('p b br'),
                    // Parse the HTML string to a pseudo-DOM structure.
                    // @ts-ignore
                    fragment = CKEDITOR.htmlParser.fragment.fromHtml(modifiedhtml),
                    // @ts-ignore
                    writer = new CKEDITOR.htmlParser.basicWriter();

                filter.applyTo(fragment);
                fragment.writeHtml(writer);
                const html = writer.getHtml();
                e.data.dataValue = html;
            }
        });

        // const handleClickOnAnchor = (e) => {
        //     if(typeof e.target.href != 'undefined' && e.ctrlKey == true) {
        //         e.preventDefault();
        //         window.open(e.target.href, 'new' + e.screenX);
        //     }
        // }

        // editor.on('change', (e) => {

        //     editor.container.querySelectorAll('a').forEach(a => {
        //         a.addEventListener('click', handleClickOnAnchor)
        //     })
        // })
    }

    showNextPrevButtons() {
        let next, prev;

        if (this.loader.hasRunningTask(false, this.loaderId)) return false;

        if (this.note && this.note.hasAudio() && this.dashboardData.audios.length) {
            next = this.dashboardData.audio.next;
            prev = this.dashboardData.audio.prev;
        } else {
            next = this.dashboardData.note.next;
            prev = this.dashboardData.note.prev;
        }

        if (this.isNew && !next && !prev) {
            return false;
        }
        return true;
    }

    noteStateCssClasses() {
        if (this.note && this.note.isUploaded()) {
            return 'note-uploaded'
        } else if (this.note && this.note.isSigned()) {
            return 'note-signed';
        } else if (this.note && this.note._id) {
            return 'note-unsigned';
        } else {
            return 'note-new';
        }
    }


    isNoteEditLocked() {
        if (!this.note.edit_lock) return false;
        const lockedOn = moment(new Date(this.note.edit_lock.lockedOn));
        const currentTime = moment();
        const diff = currentTime.diff(lockedOn, 'seconds');
        return diff <= this.editLockTimeInterval;
    }
    isNoteEditLockedForMe() {
        return this.isNoteEditLocked() && this.note.edit_lock.userId !== this.currentUser._id
    }

    updateNoteEditLock() {
        this.noteEditorService.updateNoteEditLock(this.note._id).subscribe((response: any) => {
            if (response.status === 200) {
                this.note.edit_lock = response.data;
            }
        })
    }
}
