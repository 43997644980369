<div> 

    <div class="gap-5"  style="display: flex; place-content: space-between;">
        <div class="gap-12" style="margin-right: 20px; display: flex; align-items: baseline; width: 100%; margin-left: 50px;">
            <div class="flex items-baseline input-with-cross">
                <mat-label style="margin-right:20px;color:#999">Date of Creation</mat-label>
                <input *ngIf="this.submissionDateFilter" type="text" ngxDaterangepickerMd
                    [locale]="{applyLabel: 'ok', format: 'MM/DD/YYYY'}"
                    [(ngModel)]="filter.date_of_creation" (datesUpdated)="changeSubmissionDate($event)"
                    class="mat-like-input" [ranges]="preDateRanges" [alwaysShowCalendars]="true"
                    [maxDate]="this.c_date" [linkedCalendars]="true" [showClearButton]="true" />
            </div>
            <mat-form-field>
                <mat-label>Select Facility</mat-label>
                <div class="flex items-start justify-center">
                    <img *ngIf="isPCCFacility() && filter.facility_id" src="../../../../assets/icons/pcc-icon.svg" alt=""
                        style="width: 35px;height: 20px;margin-left: -5px;;vertical-align: middle;">
                    <img *ngIf="isMatrixFacility() && filter.facility_id" src="../../../../assets/icons/matrix.png" alt=""
                        style="height: 12px;width: 20px;vertical-align: middle;margin-right: 5px;margin-left: 5px;">
                    <img *ngIf="isDNFacility() && filter.facility_id" class="dn-icon" src="../../../../assets/img/DN.png" alt="">
                    <mat-select [ngModel]="filter.facility_id" (selectionChange)="applyFacilityChange($event);">
                        <input placeholder="Search" [(ngModel)]="searchFacilityText" [ngModelOptions]="{standalone: true}"
                            class="select-input-class" />
                        <mat-option value="" style=" margin-left: 40px;">All</mat-option>
                        <mat-option *ngFor="let facility of facilities | searchFacility : searchFacilityText"
                            value="{{facility._id}}">
                            <img *ngIf="(facility?.source === 'PointClickCare' || facility?.pcc_facId)"
                                src="../../../assets/icons/pcc-icon.svg" alt=""
                                style="width: 35px;height: 20px;margin-left: -5px;;vertical-align: middle;">
                            <img *ngIf="facility?.source === 'MatrixCare' " src="../../../../assets/icons/matrix.png" alt=""
                                style="height: 12px;width: 20px;vertical-align: middle;margin-right: 5px;margin-left: 5px;">
                            <img *ngIf="facility?.source != 'PointClickCare' && !facility?.pcc_facId &&facility?.source != 'MatrixCare'"
                                class="dn-icon" src="../../../../assets/img/DN.png" alt=""> {{facility.title ?facility.title : ""}}
                        </mat-option>
                    </mat-select>
                </div>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Select Provider</mat-label>
                <mat-select (selectionChange)="applyProviderChange($event);" [ngModel]='filter.provider_id' >
                    <input placeholder="Search" [(ngModel)]="searchProviderText" [ngModelOptions]="{standalone: true}"
                    class="select-input-class" />
                    <mat-option value="" >All</mat-option>
                    <mat-option *ngFor="let pro of providers | searchProvider : searchProviderText" value="{{pro._id}}">{{pro.full_name ? pro.full_name : ""}}</mat-option>
                </mat-select> 
            </mat-form-field>
        </div>
        <div>          
        </div>
        <div>
            <button mat-raised-button class="dn-button-primary" (click)="getPatientOpportunitiesReport()"> Apply Filter</button>
        </div>
    </div>

    <div style="height: 10px;"></div>

    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="provider">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Provider</th>
            <td mat-cell *matCellDef="let element"> 
                {{getProviderName(element.doctor_id_ref[0])}}
            </td>
        </ng-container> 
        
        <ng-container matColumnDef="facility">
            <th mat-header-cell *matHeaderCellDef> Facility </th>
            <td mat-cell *matCellDef="let element ;">
                <div>{{element.facility_id_ref? element.facility_id_ref.title : '--'}}</div>  
            </td>
        </ng-container>
        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
            <td mat-cell *matCellDef="let element"> 
                {{element.first_name? element.first_name : '--'}} 
            </td>
        </ng-container> 
        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef> Last Name </th>
            <td mat-cell *matCellDef="let element ;">
                {{element.last_name? element.last_name : '--'}} 
                   
            </td>
        </ng-container>
        <ng-container matColumnDef="contact">
            <th mat-header-cell *matHeaderCellDef> Contact </th>
            <td mat-cell *matCellDef="let element ; ">
                <div>{{element.phone? element.phone : "--"}}</div>  
                   
            </td>
        </ng-container>
        <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef> Address </th>
            <td mat-cell *matCellDef="let element ;">
                <div>{{element.address? element.address : "--"}}</div>  
                   
            </td>
        </ng-container>
        <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef> City </th>
            <td mat-cell *matCellDef="let element ;">
                <div>{{element.city? element.city : "--"}}</div>  
                   
            </td>
        </ng-container>
        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef> State </th>
            <td mat-cell *matCellDef="let element ;">
                <div>{{element.state? element.state : "--"}}</div>  
                   
            </td>
        </ng-container>
        <ng-container matColumnDef="zipCode">
            <th mat-header-cell *matHeaderCellDef> Zip Code </th>
            <td mat-cell *matCellDef="let element">
                <div>{{element.zip_code ? element.zip_code : "--"}}</div>       
            </td>
        </ng-container>
        <ng-container matColumnDef="primaryIns">
            <th mat-header-cell *matHeaderCellDef> Primary Insurance </th>
            <td mat-cell *matCellDef="let element">
                <div>{{getPatientInsurance(element,'primary')}}</div>       
            </td>
        </ng-container>
        <ng-container matColumnDef="SecondaryIns">
            <th mat-header-cell *matHeaderCellDef> Secondary Insurance </th>
            <td mat-cell *matCellDef="let element;">
                <div>{{getPatientInsurance(element,'secondary')}}</div>       
            </td>
        </ng-container>
        <ng-container matColumnDef="TertiaryIns">
            <th mat-header-cell *matHeaderCellDef> Tertiary Insurance </th>
            <td mat-cell *matCellDef="let element ;">
                <div>{{getPatientInsurance(element,'tertiary')}}</div>       
            </td>
        </ng-container>
        <ng-container matColumnDef="admissionDate">
            <th mat-header-cell *matHeaderCellDef> Admission Date </th>
            <td mat-cell *matCellDef="let element;">
                <div>{{element.last_admit_date_string? getLastAdmitDate(element) : "--"}}</div>       
            </td>
        </ng-container>
        <ng-container matColumnDef="initialAdmissionDate">
            <th mat-header-cell *matHeaderCellDef>Initial Admission Date </th>
            <td mat-cell *matCellDef="let element;">
                <div>{{element.inTake_date? getPatientInitialAdmitDate(element) : "--"}}</div>       
            </td>
        </ng-container>
        <ng-container matColumnDef="pccDiagnosesList">
            <th mat-header-cell *matHeaderCellDef>Point Click Care Diagnoses List</th>
            <td mat-cell *matCellDef="let element;">
                <a style="cursor: pointer;" (click)="showPatientDiagnosis(element,'diagnosis')">Show</a>
            </td>
        </ng-container>
        <ng-container matColumnDef="medicationList">
            <th mat-header-cell *matHeaderCellDef> Medication List  </th>
            <td mat-cell *matCellDef="let element ;">
                <a style="cursor: pointer;" (click)="showPatientDiagnosis(element,'medication')">Show</a>
            </td>
        </ng-container>
        <ng-container matColumnDef="attendingPhysician">
            <th mat-header-cell *matHeaderCellDef> Attending Physician </th>
            <td mat-cell *matCellDef="let element ;">
                <div>{{ getAttendentName(element) }}</div>       
            </td>
        </ng-container>
        <ng-container matColumnDef="atleasProvider">
            <th mat-header-cell *matHeaderCellDef> Alteas Provider </th>
            <td mat-cell *matCellDef="let element ; ">
                <div>{{element.primary_doctor[0]? getProviderName(element.primary_doctor[0]) : "--"}}</div>       
            </td>
        </ng-container>
        <ng-container matColumnDef="lastSeen">
            <th mat-header-cell *matHeaderCellDef>Last Seen </th>
            <td mat-cell *matCellDef="let element ; let i = index;">
                <div>{{getLastSeen(element)}}</div>       
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

</div>