import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import { FacilityService } from 'src/app/services/facility.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { ChargesReportService } from './charges-report.service';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';

@Component({
  selector: 'app-charges-report',
  templateUrl: './charges-report.component.html',
  styleUrls: ['./charges-report.component.css']
})
export class ChargesReportComponent implements OnInit {

  currentUser: any;
  companyID: '';
  companies = [];
  facilities = [];
  providers = [];
  selectedCompany: any;
  selectedFacility: any;
  selectedProvider: any;
  companyName = "";
  filter = {
    company_id : '',
    facility_id :'',
    provider_id :'',
    // status : 'submit',
    reportDateFilter : {
      startDate: moment().subtract(7, 'days'),
      endDate: moment(),
    }
  }
  projection = {};
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  displayedColumns = ['provider', 'submit', 'draft']
  dataSource: any;
  reportData = [];
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private facilityService: FacilityService,
    private chargesReportService: ChargesReportService,
  ) {
    this.currentUser =  this.authService.currentUser;
    this.companyID =  this.route.parent.snapshot.params.id
   }

  async ngOnInit() {
    if(this.currentUser.product_owner){
      let res:any = await this.companyService.getAllCompanies().toPromise();
      if(res.status == 200 ){
        this.companies = res.data
        this.filter.company_id = this.companies[0]._id;
        // this.model.company_id = this.companies[0]._id;
        this.selectedCompany = this.companies[0];
      }
    }
    else{
      let res:any = await this.companyService.getCompanyName(this.companyID).toPromise();
      if(res.status ==  200){
        this.companyName = res.data.name;
        this.selectedCompany = res.data;
        this.filter.company_id = this.companyID
      }
    }
    this.loadData();
  }

  async loadData(){
    let filter = {
      assoc_company_ids: this.filter.company_id,
      is_active: true
    }
    this.projection = {
      'title': 1,
      'address': 1,
      'city': 1,
      'state': 1,
      'zip_code': 1,
      'is_active': 1,
      'source': 1,
      'provider_ids_ref':1
    }

    let providerPopulation: facilityPopulationInterface = {
      collection: 'users',
      field: ["provider_ids_ref"] ,
      filter: { $expr: { $and: [{ $eq: ["true", '$is_activated'] }, { $eq: [true, { $in: ['$_id', '$$provider_ids_ref'] }] }] } },
      project: {
        first_name: 1, last_name: 1, title: 1, recentLogin: 1, trueRCMID: 1,
        full_name: { $concat: ["$first_name", "$last_name", ", ", "$title"] }
      },
    };
    
    let facilitiesResponse:any = await this.facilityService.getFacilities(filter, this.projection, [providerPopulation]).toPromise();
    if(facilitiesResponse.status == 200){
      this.facilities = facilitiesResponse.data.array
      this.selectedFacility = this.facilities[0]; 
      this.filter.facility_id = this.selectedFacility._id;
      this.applyFacilityChange({value: this.filter.facility_id})
    // this.applyFilter();

    }
  }

  applyProviderChange(event){
    this.filter.provider_id = event.value;
    this.applyFilter();
  }

  applyFacilityChange(event){ 
    this.filter.facility_id = event.value;
    if(event.value){
      this.selectedFacility = this.facilities.map(fac => {
        if(fac._id == this.filter.facility_id){
          return fac;
        }
      }).filter(a => a)
      this.selectedFacility = this.selectedFacility[0]
      this.providers = this.selectedFacility.provider_ids_ref;
      // this.selectedProvider = this.providers[0];
      // this.filter.provider_id = this.selectedProvider._id;
    }
    this.applyFilter()
  }

  applyDateFilterReport(dates) {
    this.filter.reportDateFilter = { ...dates }
    this.applyFilter(); 
  }

  applyCompanyFilter(event){ 
    this.filter.company_id = event.value;
    let com = this.companies.map(c => {
      if(c._id == this.filter.company_id){
        return c;
      }
    }).filter(a=> a)
    this.selectedCompany = com[0];
    this.filter.company_id = this.selectedCompany._id
    // console.log("this.selectedCompany----------- ", this.selectedCompany);
    this.loadData()
  }

  async applyFilter(){
    // console.log("Apply filter called------: ", this.filter);
    let res:any = await this.chargesReportService.getChargesReport(this.filter).toPromise();
    if(res.status == 200){
      this.reportData = res.reportData;
      this.dataSource = new MatTableDataSource(this.reportData)
    }
  }

  searchProvider(filterValue: string) {
    let filter = filterValue.trim().toLowerCase();
    if (filter) {
      let filterVal = []; 
      this.reportData.forEach(report=> { 
      if (report[0].provider.first_name.trim().toLowerCase().includes(filter) || (report[0].provider.last_name.trim().toLowerCase().includes(filter))) {
          filterVal.push(report)  
          this.dataSource = new MatTableDataSource(filterVal);
          // this.dataSource = filterVal;
        }
    });
    }
    else {
      this.dataSource = new MatTableDataSource(this.reportData);
       
    }
  }

  getProviderName(element){
    if(element[0]?.provider.title){
      return `${element[0].provider.first_name} ${element[0].provider.last_name},${element[0].provider.title}`
    }
    else if(element[0].provider){
      return `${element[0].provider.first_name} ${element[0].provider.last_name}`
    }
    else{
      return ""
    }
  }

  getSubmitted(element){ 
    if(element[0]._id.status == 'submit'){
      return element[0].total;
    }
    else if(element.length > 1 && element[1]._id.status == 'submit'){
      return element[1].total;
    }
    else {
      return 0;
    }
  }

  getDrafted(element){
    if(element[0]._id.status == 'draft'){
      return element[0].total;
    }
    else if(element.length > 1 && element[1]._id.status == 'draft'){
      return element[1].total;
    }
    else {
      return 0;
    }
  }


}
