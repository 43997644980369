<app-header></app-header>

<main>
  <div class="container">
    <mat-card>
      <mat-card-content class="flex flex-col">

        <div class="flex justify-between items-center">
          <div class="flex justify-start items-center gap-5">
            <h3>Patient Census</h3>
            <mat-form-field>
              <mat-label>Facility</mat-label>
              <mat-select name="facility" #facility="ngModel" [(ngModel)]="filter_facility_id"
                (ngModelChange)="handleFilterFacilityChange()">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let facility of facilities" value="{{facility._id}}">
                  <app-facility-title [facility]="facility"></app-facility-title>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <button mat-raised-button class="dn-button-primary" style="margin-left: auto !important;"
            (click)="showPatientRoundingSheetDialog()">Create Patient Census</button>
        </div>
        <div style="position: relative;">

          <mat-table class="table-row-hover" [dataSource]="dataSource"
                     *ngIf="dataSource" matSort
            (matSortChange)="sortData($event)">
            <ng-container matColumnDef="name" >
              <mat-header-cell *matHeaderCellDef mat-sort-header="name">Name</mat-header-cell>
              <mat-cell *matCellDef="let patientRoundingSheet">{{patientRoundingSheet.name}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="facility">
              <mat-header-cell *matHeaderCellDef mat-sort-header="facility">Facility</mat-header-cell>
              <mat-cell *matCellDef="let patientRoundingSheet">
                <app-facility-title [facility]="patientRoundingSheet?.facility"></app-facility-title>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="date">
              <mat-header-cell *matHeaderCellDef mat-sort-header="date">Visit Date</mat-header-cell>
              <mat-cell *matCellDef="let patientRoundingSheet">{{patientRoundingSheet.date | date: global.date_format}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="patients_count_total">
              <mat-header-cell *matHeaderCellDef mat-sort-header="patient_count">Total Patients</mat-header-cell>
              <mat-cell *matCellDef="let patientRoundingSheet">
                {{patientRoundingSheet.patientsCounts.total}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="patients_count_new">
              <mat-header-cell *matHeaderCellDef mat-sort-header="patients_count_new">I/E</mat-header-cell>
              <mat-cell *matCellDef="let patientRoundingSheet">
                {{patientRoundingSheet.patientsCounts.new}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="patients_count_followUp">
              <mat-header-cell *matHeaderCellDef mat-sort-header="patients_count_followUp">F/U</mat-header-cell>
              <mat-cell *matCellDef="let patientRoundingSheet">
                {{patientRoundingSheet.patientsCounts.followUp}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="patients_count_discharge">
              <mat-header-cell *matHeaderCellDef mat-sort-header="patients_count_discharge">D/C</mat-header-cell>
              <mat-cell *matCellDef="let patientRoundingSheet">
                {{patientRoundingSheet.patientsCounts.discharge}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="operations">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let patientRoundingSheet">
                <div class="flex justify-start items-center gap-5">

                  <i class="icon icon-view" matTooltip="View Patients"
                     matTooltipPosition="above"
                    [routerLink]="[patientRoundingSheet._id]"></i>

                  <i class="icon icon-delete" matTooltip="Delete Census" matTooltipPosition="above"
                    (click)="deletePatientRoundingSheet(patientRoundingSheet._id)"></i>
                </div>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

          </mat-table>
          <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</main>
