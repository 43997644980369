import { Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MipsService } from 'src/app/services/Modules/mips.service';
import _ from 'lodash'
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-mips-questionnaire-tree',
  templateUrl: './mips-questionnaire-tree.component.html',
  styleUrls: ['./mips-questionnaire-tree.component.css']
})
export class MipsQuestionnaireTreeComponent implements OnInit, OnChanges {

  @Input() measure;
  @Output() onAnswerCalculation = new EventEmitter<any>();
  questions: any[] = [];
  questionsToShow: any[] = [];
  questionsStack: any[] = [];

  currentQuestionIndex = 0;
  answer;
  currentUser:any;
  constructor(
    private authService:AuthService, 
    private mipsService: MipsService
    ) { 
      this.currentUser = this.authService.currentUser;
    }

  ngOnInit(): void {
    // this.initQuestions();
  }

  ngOnChanges(changes: SimpleChanges) {
    const measureChanges = changes['measure'];
    let curMeasure  = JSON.stringify(measureChanges.currentValue);
    let prevMeasure = JSON.stringify(measureChanges.previousValue);
    if(curMeasure !== prevMeasure) {
      this.initQuestions();
    }
  }
  initQuestions() {
    this.mipsService.getQuestions(this.measure.quality_number, null, this.currentUser.company_id).subscribe((response:any) => {
      if(response.status === 200) {
        this.questions = response.data.array;
        this.answer = null;
        this.questionsStack = [];
        this.questionsToShow = [ this.questions[0] ];
      }
    })
  }
  async nextQuestion(currentQuestion) {
    
    if(currentQuestion.sub_questions_count > 0) {
      this.questionsStack.push(currentQuestion);
      const subQuestionsResposne:any = await this.mipsService.getQuestions(this.measure.quality_number, currentQuestion._id, this.currentUser.company_id).toPromise();
      if(subQuestionsResposne.status === 200) {
        currentQuestion.subQuestions = subQuestionsResposne.data.array;
      }
    } else {
      while(this.questionsStack.length > 0) {
        const prevQuestion = this.questionsStack.pop();
        if(prevQuestion) {
          const parentQuestion = this.questionsStack[this.questionsStack.length-1];
          
          if(parentQuestion) {
           
            const parentQuestionInQuestionsToShow : any = _(this.questionsToShow)
            .thru(function (question) {
              return _.union(
                question,
                _.map(question, 'subQuestions') || []
              );
            })
            .flatten()
            .find({ _id: parentQuestion._id });
            console.log(parentQuestionInQuestionsToShow)
            const nextQuestionIndex = parentQuestion.subQuestions.findIndex(q => q._id === prevQuestion._id);
            const nextQuestion = parentQuestion.subQuestions[nextQuestionIndex+1];
            if(nextQuestion) {
              parentQuestionInQuestionsToShow.subQuestions.push(nextQuestion);
              return;
            }
          } 
        }
      }

      const i = this.questionsToShow.length;
      if(i < this.questions.length) {
        this.questionsToShow.push(this.questions[i]);
      } else if(i === this.questions.length) {
        this.answer = false;
      } 
    }
  }
  notEligible() {
    this.answer = false;
  }
  calculateAnswer(question) {
    console.log('Data Completeness Met + Denominator Exception')
    this.answer = question.answer;
    this.onAnswerCalculation.emit({measure: this.measure, answer: question.answer});
  }
  showAnswer() {

    return this.answer
  }

}
