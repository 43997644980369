<br />
<table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="facility">
        <th
                class="header-data"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header-container>
            Facility
        </th>
        <td mat-cell *matCellDef="let element">
            <app-facility-title [facility]="element"></app-facility-title>
        </td>
    </ng-container>
    <ng-container matColumnDef="address">
        <th
                class="header-data"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header-container>
            Address
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.address }}
        </td>
    </ng-container>

    <ng-container matColumnDef="city">
        <th
                class="header-data"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header-container>
            City
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.city }}
        </td>
    </ng-container>
    <ng-container matColumnDef="state">
        <th
                class="header-data"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header-container>
            State
        </th>
        <td mat-cell *matCellDef="let element">
            {{
            element.state >= 0 && usa_states[element.state] ? usa_states[element.state].abbreviation : ''
            }}
        </td>
    </ng-container>
    <ng-container matColumnDef="zip_code">
        <th
                class="header-data"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header-container>
            Zip Code
        </th>
        <td mat-cell *matCellDef="let element">{{ element.zip_code }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
