import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { DictionaryEditorDialogComponent } from '../phrase-editor-dialog/phrase-editor-dialog.component';
import { PhrasesService } from '../phrases.service';
import * as global from '../../../includes/global';
import { MatTableDataSource } from '@angular/material/table';
import { HttpResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DashboardService } from '../../dashboard/dashboard.service';
import { ToastrService } from 'ngx-toastr';


export type DictionaryElement = {
  key: string,
  value: string
}

@Component({
  selector: 'app-user-defined-phrases',
  templateUrl: './user-defined-phrases.component.html',
  styleUrls: ['./user-defined-phrases.component.css']
})
export class UserDefinedPhrasesComponent implements OnInit {
  dictionary: Array<DictionaryElement>;
  newDictionaryElement:DictionaryElement;

  isNew:boolean = false;
  global = global;
  searchText: String = '';
  dataSource: MatTableDataSource<any>;
  loaderId = 'user-phrase-loader';

  @Input() note:any;
  loggedInUser: any;
  constructor(private phrasesService:PhrasesService,
    private loader: NgxUiLoaderService,
    private dialog: MatDialog,
     private authService:AuthService,
     private dashboardService: DashboardService,
     private toastr: ToastrService,
    
     ) { 
       this.loggedInUser = this.authService.currentUser;
  }

  ngOnInit(): void {
    // 
    // console.log("user-defined called");
    this.phrasesService.castUserDefinedPhrases.subscribe(dictionary => {
      this.dictionary = dictionary;
      this.dataSource = new MatTableDataSource(this.dictionary);
      console.log("this.dataSource : ", this.dataSource);
    });
    if(this.dictionary.length > 0) {
      this.sortPhrases(this.dictionary);
    }
  }
  addDictionaryElement() {
    // this.phrasesService.addDictionaryElement();
    this.newDictionaryElement = {
      key: '',
      value: ''
    }
    this.isNew = true;
  }
  saveDictionaryElement(newDictionaryElement) {
    newDictionaryElement.user_id = this.authService.currentUser._id;
    this.phrasesService.saveNoteBuilderDictionary(newDictionaryElement).subscribe((response:any) => {
      if(response.status===200) {
        console.log('Added');
        this.isNew = false;
        this.sortPhrases(this.dictionary);
      }
    })
  }
  applyFilter() {

    let filterValue = this.searchText || '';
    // console.log("filterValue : ", filterValue);
    if(filterValue.length<2){
      this.dataSource.filter='';
      this.dataSource.filteredData = this.dataSource.data;
      return;
    }

    if (filterValue == '') {
      this.dataSource.filteredData = this.dataSource.data;
    }


    this.dataSource.filter = filterValue.trim().toLowerCase();


  }

  updateNoteBuilderDictionary(newDictionaryElement) {
    this.phrasesService.updateNoteBuilderDictionary(newDictionaryElement).subscribe((response:any) => {
      if(response.status===200) {
        console.log('Updated');
        this.isNew = false;
        this.sortPhrases(this.dictionary);
      }
    })
  }
 async deleteFromNoteBuilderDictionary(dictionaryElement) {
    const message = 'Are you sure you want to delete this phrase?'
    const dialogBoxValue = await this.dashboardService.DeleteConfirmationDialog(message);
    if (dialogBoxValue === "true") {
    this.phrasesService.deleteFromNoteBuilderDictionary(dictionaryElement,this.loggedInUser._id).subscribe((response:any) => {
      if(response.status===200) {
        console.log('Deleted');
        this.toastr.success("Deleted SuccessFully","Success")
        this.isNew = false;
        this.sortPhrases(this.dictionary);
      }
    })
  }
  }
  getMarker() {
    return this.phrasesService.marker
  }
  showDictionaryEditorDialog(dictionaryElement?) {
    let element: any;
    if(dictionaryElement) {
      element = JSON.parse(JSON.stringify(dictionaryElement));
    }
      const dialogRef = this.dialog.open(DictionaryEditorDialogComponent, {
          width: '800px',
          data: element
          
      });

      dialogRef.afterClosed().subscribe(async (_dictionaryElement) => {
        if(_dictionaryElement) {
          if(element) {
            this.updateNoteBuilderDictionary(_dictionaryElement);
          } else {
            this.saveDictionaryElement(_dictionaryElement);
          }
        }
        
          // this.loader.start(this.loaderId);
          // await this.getPhrases();
          // this.loader.stop(this.loaderId);

      });
  }
  isOwner(dictionaryElement) {
    // console.log({
    //   "dictionaryElement.user_id": dictionaryElement.user_id,
    //   "this.authService.currentUser._id": this.authService.currentUser._id
    // })
    // 
    return dictionaryElement.user_id === this.authService.currentUser._id
  }

  sortPhrases(arr: any[]) {
    arr.sort((a, b) => a.key.toLowerCase().localeCompare(b.key.toLowerCase()));
  }
}
