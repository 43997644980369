import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
import { ResetPasswordService } from './reset-password.service';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../global'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})

export class ResetPasswordComponent {
  enablecognitoStatus = false;
  cognitoStatus = false;
  status: string = null;
  accessToken: string = null;
  currentUser: any = null;
  oldPassword: string = "";
  newPassword: string = "";
  confirmNewPassword: string = "";
  passwordError: string = "";
  passwordStrength: number = 0;
  global = global;
  error: string;

  constructor(
    private _toastr: ToastrService,
    private _router: Router,
    private _resetPasswordService: ResetPasswordService,
    private _authService: AuthService,
  ) {
    // this.currentUser = localStorage.getItem('currentUser');
    this.currentUser = this._authService.currentUser;
    if (!this.currentUser) {
      // if not login, stop and redirect to login #mohsin-dev
      localStorage.setItem('currentUser', 'null');
      this._router.navigate(['/login']);
      return;
    }
    this.getCognitoStatus();
    // this.currentUser = JSON.parse(this.currentUser); 
  }
  checkPassword(myString) {
    let minLength = true;
    var specials = /[^A-Za-z 0-9]/g;
    let hasSpecial = specials.test(myString);
    let hasNumber = /\d/.test(myString);
    let hasUpper = /[A-Z]/.test(myString);
    let hasLower = /[a-z]/.test(myString);
    let passwordLength = myString.length;
    if (passwordLength < 10) {
      minLength = false;
    }
    const valid = hasNumber && hasUpper && hasLower && hasSpecial && minLength;
    if (!valid) {
      this.error = '\n';
      if (!hasUpper) {
        this.error += 'Password should have atleast one Uppercase Letter\n';
      }
      if (!hasLower) {
        this.error += 'Password should have atleast one Lowercase Letter\n';
      }
      if (!hasNumber) {
        this.error += 'Password should have atleast one number\n';
      }
      if (!hasSpecial) {
        this.error += 'Password should have atleast one Special character\n';
      }
      if (!minLength) {
        this.error += 'Password Lenght should be greater than 10\n';
      }
      return false;
    }
    return true;
  }

  async onResetPassword(resetPasswordForm) {
    let check = this.checkPassword(this.newPassword);
    if (check) {
      this.error = '';
      // if (this.passwordStrength < 3) resetPasswordForm.controls['newPassword'].setErrors({ 'incorrect': true })
      if (this.newPassword !== this.confirmNewPassword) {
        this.error = "\nConfirm Password does not match\n";
        resetPasswordForm.controls['confirmNewPassword'].setErrors({ 'incorrect': true })
      }
      if (!resetPasswordForm.valid || !resetPasswordForm.touched) return;
      else {
        this.error = '';
        const infoToastr = this._toastr.info('Reseting password...', 'Please wait', { disableTimeOut: true });
        var details = {
          id: this.currentUser['_id'],
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        }
        if (this.enablecognitoStatus == true && this.cognitoStatus == true) {
          this._resetPasswordService.updateCognitoPassword(details).toPromise().then((Cognitoresponse: any) => {
            if (Cognitoresponse.status == 200) {
              this._resetPasswordService.updatePassword({ email: this.currentUser['email'], oldpassword: this.oldPassword, newpassword: this.newPassword }).toPromise().then((res) => {
                if (res['status'] == 200) {
                  this._toastr.clear(infoToastr.toastId);
                  this._toastr.success("Password Reset Successfully", "Success");
                  this._router.navigate(['/login']);
                  // window.history.back();
                } else {
                  this._toastr.clear(infoToastr.toastId);
                  this._toastr.error(res['message'], "Failed");
                }
              })
            }
            else {
              this._toastr.clear(infoToastr.toastId);
              this._toastr.error("Old Password is not Correct", "Failed");
            }
          });
        } else {
          this._resetPasswordService.updatePassword({ email: this.currentUser['email'], oldpassword: this.oldPassword, newpassword: this.newPassword }).toPromise().then((res) => {
            if (res['status'] == 200) {
              this._toastr.clear(infoToastr.toastId);
              this._toastr.success("Password Reset Successfully", "Success");
              this._router.navigate(['/login']);
              // window.history.back();
            } else {
              this._toastr.clear(infoToastr.toastId);
              this._toastr.error(res['message'], "Failed");
            }
          });
        }
      }
    }
  }

  strengthChange(passwordStrength) {
    this.passwordStrength = passwordStrength;
  }

  isPasswordValid() {
    if (this.newPassword != this.confirmNewPassword) {
      this.passwordError = "Confirm Password does not match";
    } else if (this.passwordStrength < 3) {
      const strengthMsg: any = ["Password strength is low", "Password strength low", "Password should not contain common words"];
      this.passwordError = strengthMsg[String(this.passwordStrength)];
    } else {
      this.passwordError = "";
    }
  }
  async getCognitoStatus() {
    let email = this.currentUser['email'];
    await this._resetPasswordService.checkCognitoStatus({ email }).toPromise().then((res) => {
      if (res['status'] == 200) {
        this.enablecognitoStatus = res['data'].enableCognito;
        this.cognitoStatus = res['data'].cognito;
      }
    });
  }
}
