type DaySchedule = {
    availability: boolean,
    start_time: string,
    end_time: string
}
export class TimeSchedule {
    user_id_ref:string|object;
    facility_id_ref: string | object;
    user_type:string;
    Monday: DaySchedule = {
        availability: false,
        start_time: null,
        end_time: null
    };
    Tuesday: DaySchedule = {
        availability: false,
        start_time: null,
        end_time: null
    };
    Wednesday: DaySchedule = {
        availability: false,
        start_time: null,
        end_time: null
    };
    Thursday: DaySchedule = {
        availability: false,
        start_time: null,
        end_time: null
    };
    Friday: DaySchedule = {
        availability: false,
        start_time: null,
        end_time: null
    };
}
