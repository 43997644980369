import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { AddTagsDialogueComponent } from './add-tags-dialogue/add-tags-dialogue.component';
import { EditTagsDialogueComponent } from './edit-tags-dialogue/edit-tags-dialogue.component';
import { TagsService } from 'src/app/services/tags.service';
import { lastValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DialogConfirmationComponent } from '../../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { DeleteTagDialogueComponent } from './delete-tag-dialogue/delete-tag-dialogue.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css']
})
export class TagsComponent implements OnInit {
  displayedColumns = ['name', 'color', 'phrase', 'phrase_text', 'operation'];
  dataSource: any = new MatTableDataSource([]);
  tags = [];
  enableEditIndex = null;
  phrase_key: any;
  patients = [];
  loaderId = 'deleting-tag-loader';

  constructor(private dialog: MatDialog, private tagsService: TagsService, private toastr: ToastrService, private loader: NgxUiLoaderService) { }

  ngOnInit(): void {
    this.displayTags();
  }

  async displayTags() {
    let res: any = await lastValueFrom(this.tagsService.getAllTags({ is_deleted: false }));
    if (res.status == 200) {
      this.tags = res.data;
      this.dataSource.data = this.tags;
    } else
      this.toastr.error('Something went wrong', 'Error');


  }

  addNewTag() {
    const dialogRef = this.dialog.open(AddTagsDialogueComponent, {
      height: '600px',
      width: '700px',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.displayTags();
    });
  }

  updateTag(index: any, data: any) {
    this.enableEditIndex = index;
    this.dataSource.data[index].shouldUpdate = true;
    const dialogRef = this.dialog.open(EditTagsDialogueComponent, {
      height: '600px',
      width: '700px',
      data: data
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        data.extracted_phrase_text = this.tagsService.extractContentFromHtmlTag(data.phrase_text);
        this.dataSource.data = [];
        this.tags[index] = data;
        this.dataSource.data = this.tags;
      }
    });
    this.enableEditIndex = index;
    this.dataSource.data[index].shouldUpdate = false;
  }

  async deleteTag(index: any, data: any) {
    this.loader.startLoader(this.loaderId);
    let filter = { is_active: "true", tag_ids_ref: { $in: [data._id] } };
    let projection = { _id: 1, first_name: 1, tag_ids_ref: 1, last_name: 1 };
    let dialogRef, isDialogConfirmationComponent = false;
    let res: any = await lastValueFrom(this.tagsService.fetchTagAssociatedPatients(filter, projection));
    if (res.status == 200) {
      this.patients = res.data;
      this.loader.stopLoader(this.loaderId);
      if (this.patients.length > 0) {
        dialogRef = this.dialog.open(DeleteTagDialogueComponent, {
          height: '600px',
          width: '700px',
          data: { patients: this.patients, tagId: data._id }
        });

      }
      else {
        dialogRef = this.dialog.open(DialogConfirmationComponent, {
          data: {
            message: "Are you sure you want to delete this Tag?"
          }
        });
        isDialogConfirmationComponent = true;
      }
      dialogRef.afterClosed().subscribe(async result => {
        if (result === "true") {
          if (isDialogConfirmationComponent) {
            let res: any = await lastValueFrom(this.tagsService.deleteTag({ _id: data._id }));
            if (res.status == 200)
              this.toastr.success(res.message, "Success");
            else
              this.toastr.error('Something went wrong', 'Error');
          }
        }
        this.displayTags();
      });
    }
  }
}
