import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TherapyService } from '../therapy.service';

@Component({
  selector: 'app-tens-report',
  templateUrl: './tens-report.component.html',
  styleUrls: ['./tens-report.component.css']
})
export class TensReportComponent implements OnInit {

  @Input('patient') patient: any;
  displayedColumnsPT: string[] = ['dos', 'name','value'];
  displayedColumnsOT: string[] = ['dos', 'name','value'];
  displayedColumnsST: string[] = ['dos', 'name','value'];
  patientTensReport: any;
  dataSourceOT: any = new MatTableDataSource([])
  dataSourcePT: any = new MatTableDataSource([])
  dataSourceST: any = new MatTableDataSource([])
  isLoading = true;

  constructor(private therapyService : TherapyService) { }

  async ngOnInit() {
    await this.getTensReport();
  }

  async getTensReport() {
    let patientName = this.patient.last_name + ', ' + this.patient.first_name.trim();
    this.therapyService.getTensReport(patientName).subscribe(async (res: any) => {
      if (res.status == 200) {
        this.patientTensReport = res.data;
        console.log('this.patientTensReport',this.patientTensReport);
        this.dataSourcePT.data = this.patientTensReport.PhysicalTherapyTable;
        this.dataSourceOT.data = this.patientTensReport.OccupationalTherapyTable;
        this.dataSourceST.data = this.patientTensReport.SuspisiousTherapyTable;
        this.isLoading = false;
      } else {
        console.log('something went wrong');
        this.isLoading = false;
      }
    });
  }

  getName(element) {
    return element.name;
  }

  getValue(element) {
    return element.value;
  }
}
