import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { PatientListService } from '../patient-list/patient-list-service';
import { Patient, insurance_order, insured_patient_relation } from 'src/app/classes/patient';
import moment from 'moment';
import * as usaStates from 'src/app/shared/popup-dialogs/facility-add-dialog/usa_states';
import { PatientAddService } from '../patient-add/patient-add-service';
import { SocketService } from 'src/app/services/socket.service';
import { DatePipe } from '@angular/common';
import * as global from '../global';
import { ToastrService } from 'ngx-toastr';
import { FacilityService } from 'src/app/services/facility.service';
import { Location } from '@angular/common';
import { FaceSheetService } from '../patient-detail/facesheet/face-sheet.service';
import { PdfViewerPopComponent } from '../patient-documents-list/pdf-viewer-pop/pdf-viewer-pop.component';
import { MatDialog } from '@angular/material/dialog';
import { IPatientCoverage, setCoverageValues } from 'src/app/shared/interfaces/patient.coverages.interface';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-admin-side-patient-edit',
  templateUrl: './admin-side-patient-edit.component.html',
  styleUrls: ['./admin-side-patient-edit.component.css']
})
export class AdminSidePatientEditComponent implements OnInit {
  currentUser: any;
  patient: any;
  searchFacilityText: any = "";
  searchState: any;
  loading: boolean = false;
  // data members for add pateint form #mohsin-dev
  patient_id: string = "";
  full_name: string = "";
  first_name: string = "";
  race: string = "";
  patient_ssn: string = "";
  mrn: string = "";
  middle_name: string = "";
  last_name: string = "";
  email: string = "";
  phone: string = "";
  date_of_birth: any = null;
  last_admit_date: any = moment().toDate();
  inTake_date: any = moment().toDate();
  p_insurance_carrier: string = "";
  p_insurance_holder_name: string = "";
  p_insurancePolicyNumber: string = "";
  p_insuranceGroupNumber: string = "";

  s_insurance_carrier: string = "";
  s_insurance_holder_name: string = "";
  s_insurancePolicyNumber: string = "";
  s_insuranceGroupNumber: string = "";
  coverages: IPatientCoverage[] = []; 
  coveragesList = new MatTableDataSource([]) ;
  displayedColumnsInurance: string[] = ['ins_order', 'insurance_type', 'tin','insurance_plan_id','insured_group_employer_id','remove_ins'];
  newCoverage: IPatientCoverage ;
  addInsuranceLabel = 'Add Insurance';
  plan_effective_date = null;
  plan_expiration_date = null;
  insured_patient_relation = insured_patient_relation;
  insurance_order = insurance_order; 
  city: string = "";
  zip_code: string = "";
  address: string = "";
  state: string = "";
  responsible_party: string = "";
  responsible_party_contact: string = "";
  census_id: string = "";
  hospice_notes: string = "";
  VA_notes: string = "";

  refer_provider_first_name = '';
  refer_provider_last_name = '';
  refer_provider_phone_number = '';
  refer_provider_fax_number = '';
  refer_provider_location = '';
  refer_provider_address = '';

  isHospice: Boolean = false;
  isVA: Boolean = false;
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  startDate: Date = new Date(1940, 0, 1);
  gender: string = "";
  facilities: Array<any>;
  doctors: Array<any>;
  @Input() selectedDoctorId: string = "";
  @Input() selectedFacilityId: string = "";
  @Input() showPhoneField: boolean = false;
  @Input() showEmailField: boolean = false;

  @Output() patientAdded = new EventEmitter<any>();


  isNew: boolean = true;
  patientToAdd: any = [];
  minDate = new Date(1900, 0, 1);
  maxDate = new Date();
  selectDate: Boolean = false;
  @Output() onSaveClick: EventEmitter<any> = new EventEmitter();
  @Output() onCancelClick: EventEmitter<any> = new EventEmitter();

  phoneCleaned = '';
  usaStates = usaStates.default;
  patientRoundingSheets: any[] = [];
  races = [
    { value: 'American Indian/Alaska Native' },
    { value: 'Asian' },
    { value: 'Black/African American' },
    { value: 'Native Hawaiian' },
    { value: 'White' },
    // { value: 'Patient Refused' },
    // { value: 'White, not of Hispanic Origin' },

  ]
  fileName: string = "";
  file: any;
  showRemoveFileButton: boolean = false;
  isEditing: boolean = false;
  userCompany: any;
  faceSheet: any;
  date_of_birth_d: any;
  dataFetched: Boolean = false;
  companyId: any;
  uploadedFacesheetTitle: any;
  facesheetUrl: any;
  isDisabledEditing = false;
  isDisabledFacility = false;
  facility_patient_mrn: string = '';

  constructor(
    private route: ActivatedRoute,
    private _authService: AuthService,
    private _patientService: PatientListService,
    private _patientAddServie: PatientAddService,
    private _socketService: SocketService,
    // private _date_pipe: DatePipe,
    private _toastr: ToastrService,
    private router: Router,
    private location: Location,
    private facilityService: FacilityService,
    private _faceSheetService: FaceSheetService,
    private dialog: MatDialog,
    private commonService :CommonService

  ) { }

  async ngOnInit() {
    this.loading = true;
    console.log('route', this.route);
    this.currentUser = this._authService.currentUser;
    const patient_id = this.route.snapshot.params.id;
    this.companyId = this.route.snapshot.parent.params.id;
    console.log('companyId', this.companyId);

    const userInfo = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      patient_id
    };
    this.newCoverage = {} as IPatientCoverage;
    this.newCoverage = setCoverageValues(this.newCoverage)
    const patientResponse: any = await this._patientService.getPatient(userInfo).toPromise();
    if (patientResponse.status === 200) {

      this.patient = new Patient(patientResponse.data.patient);
      console.log('...patient', this.patient);
      if (this.patient) {
        this.patient_id = this.patient._id;
        this.selectedDoctorId = this.patient.primary_doctor_id;
        this.selectedFacilityId = this.patient.facility_id;
        // this.initializeFacilitiesDropdown(this.selectedDoctorId);

        const filter: any = {
          
          is_active: 'true',
          company_id: this.companyId,
        };
        
        const projection = {
          'title': 1,
          'address': 1,
          'city': 1,
          'state': 1,
          'zip_code': 1,
          'provider_ids_ref': 1,
          'template_ids_ref': 1
        }
        let result: any = await this.facilityService.getAllFacilitiesProviderTemplateAssoc(filter, projection).toPromise();
        if (result.status == 200) {
          console.log('result',result);
          this.facilities = result.data.facilities;
        }
      }
      this.loadPCCApiData();
    }
    await this.initPatient({ name: `${this.patient.first_name} ${this.patient.middle_name} ${this.patient.last_name}`, ...this.patient })
    await this.getPatientFacesheet();
  }

  async loadPCCApiData() {
    const pccPatientID = this.patient.pcc_patientId;
    if (!pccPatientID) return;
  }

  // initializeFacilitiesDropdown(doctor_id) {
  //   var item = {
  //     id: <string>null,
  //     auth_key: <string>null,
  //     usertype: <string>null,
  //     doctor_id: <string>null
  //   };
  //   item.id = this.currentUser._id;
  //   item.auth_key = this.currentUser.auth_key;
  //   item.usertype = this.currentUser.user_type;

  //   item.doctor_id = doctor_id;
  //   this.loading = true;
  //   this._patientAddServie.getFacilities(item).subscribe((response: any) => {
  //     if (response.status === 200) {
  //       this.facilities = response.data.array.filter(facility => !facility.pcc_facId);
  //     }
  //     this.loading = false;
  //   });
  // }

  dateOfBirth($event) {
    this.date_of_birth = $event;
    console.log('this.date_of', this.date_of_birth);
  }

  async initPatient(patient_info) {
    let isUpdated = false;
    const { name: full_name = "",
      first_name = "",
      race = "",
      middle_name = "",
      last_name = "",
      email,
      phone,
      patient_ssn,
      mrn,
      insurance,
      city,
      zip_code,
      address,
      state,
      responsible_party,
      responsible_party_contact,
      hospice_notes,
      isHospice,
      last_admit_date_string,
      census_id,
      isVA,
      VA_notes,
      date_of_birth,
      date_of_birth_d,
      inTake_date,
      last_admit_date,
      gender,
      refer_provider_first_name ,
      refer_provider_last_name ,
      refer_provider_phone_number,
      refer_provider_fax_number ,
      refer_provider_location ,
      refer_provider_address ,
      facility_patient_mrn
    } = patient_info;
    if (patient_info.source == "MatrixCare" || patient_info.source == "PointClickCare") this.isDisabledEditing = true;
    else this.isDisabledEditing = false;
    if (patient_info.source == "PointClickCare") this.isDisabledFacility = true;
    else this.isDisabledFacility = false;

    this.full_name = full_name;

    if (first_name && last_name) {
      console.log('patient info', patient_info);

      this.first_name = first_name;
      this.middle_name = middle_name;
      this.last_name = last_name;
      isUpdated = true;
    } else if (full_name) {
      const nameSplit = full_name.split(' ');
      if (nameSplit.length === 1) {
        this.first_name = full_name;
        isUpdated = true;
      } else if (nameSplit.length === 2) {
        this.first_name = nameSplit[0];
        this.last_name = nameSplit[1];
        isUpdated = true;
      } else if (nameSplit.length >= 3) {
        this.first_name = nameSplit[0];
        this.middle_name = nameSplit[1];
        this.last_name = nameSplit[2];
        isUpdated = true;
      }
    }
    if (race) {
      this.race = race;
    }

    if (patient_ssn) {
      this.patient_ssn = patient_ssn;
    }
    if (mrn) {
      this.mrn = mrn;
    }
    if (patient_info.pcc_payload) {
      this.mrn = patient_info.pcc_payload.medicalRecordNumber;
    }
    if (email) {
      this.email = email;
    }

    if (phone) {
      this.phone = phone;
    }
    if(refer_provider_first_name){
      this.refer_provider_first_name = refer_provider_first_name
    }
    if(refer_provider_last_name){
      this.refer_provider_last_name = refer_provider_last_name
    }
    if(refer_provider_phone_number){
      this.refer_provider_phone_number = refer_provider_phone_number
    }
    if(refer_provider_fax_number){
      this.refer_provider_fax_number = refer_provider_fax_number
    }
    if(refer_provider_location){
      this.refer_provider_location = refer_provider_location
    }
    if(refer_provider_address){
      this.refer_provider_address = refer_provider_address
    }
    
    if (facility_patient_mrn) {
      this.facility_patient_mrn = facility_patient_mrn;
    }

    if (isUpdated) {
      this.address = address;
      isUpdated = true;
    }
    if (date_of_birth) {
      if (patient_info.pcc_payload) {
        // console.log('date_of_birth PCC', moment(date_of_birth).format('YYYY-MM-DD'));
        this.date_of_birth = moment(date_of_birth).format('YYYY-MM-DD');
        isUpdated = true;
      }
      else {
        this.date_of_birth = date_of_birth;
        console.log('date_of_birth', date_of_birth);
        this.date_of_birth = moment(date_of_birth).format('YYYY-MM-DD');

      }
    }
    if (inTake_date) {
      // this.inTake_date = new Date(inTake_date);
      this.inTake_date = moment(inTake_date).format('YYYY-MM-DD');
      isUpdated = true;
    }
    if (last_admit_date_string) {
      this.last_admit_date = moment(last_admit_date_string).format('YYYY-MM-DD');
    }
    else if (last_admit_date) {
      console.log('last_admit_date', moment(last_admit_date).format('YYYY-MM-DD'));
      this.last_admit_date = last_admit_date;
      isUpdated = true;
      this.isEditing = true;
    }

    if (gender) {
      this.gender = gender;
      isUpdated = true;
    }
    if (insurance) {
      this.p_insurance_carrier = insurance.p_insurance_carrier;
      this.p_insurance_holder_name = insurance.p_insurance_holder_name;
      this.p_insurancePolicyNumber = insurance.p_insurancePolicyNumber;
      this.p_insuranceGroupNumber = insurance.p_insuranceGroupNumber;

      this.s_insurance_carrier = insurance.s_insurance_carrier;
      this.s_insurance_holder_name = insurance.s_insurance_holder_name;
      this.s_insurancePolicyNumber = insurance.s_insurancePolicyNumber;
      this.s_insuranceGroupNumber = insurance.s_insuranceGroupNumber;
      this.coverages = insurance.coverages ? insurance.coverages : []
      this.coveragesList.data = this.coverages;
      this.newCoverage.ins_order = this.coverages.length+1;
    }
    if (city) {
      this.city = city;
      isUpdated = true;
    }
    if (zip_code) {
      this.zip_code = zip_code;
      isUpdated = true;
    }
    if (email) {
      this.email = email;
      isUpdated = true;
    }
    if (address) {
      this.address = address;
      isUpdated = true;
    }
    if (state) {
      this.state = state;
      isUpdated = true;
    }
    if (responsible_party) {
      this.responsible_party = responsible_party;
      isUpdated = true;
    }
    if (responsible_party_contact) {
      this.responsible_party_contact = responsible_party_contact;
      isUpdated = true;
    }
    if (hospice_notes) {
      this.hospice_notes = hospice_notes;
      isUpdated = true;
    }
    if (census_id) {
      this.census_id = census_id;
      isUpdated = true;
    }
    if (isHospice) {
      this.isHospice = isHospice;
      isUpdated = true;
    }
    if (isVA) {
      this.isVA = isVA;
      isUpdated = true;
    }
    if (VA_notes) {
      this.VA_notes = VA_notes;
      isUpdated = true;
    }
    this.loading = false;
    this.dataFetched = true;
    return isUpdated;
  }

  onSavePatient(event) {
    let formatted_date_of_birth;
    let formatted_inTake_date;
    let formatted_last_admit_date;

    if (this.inTake_date) {
      let dateString = "";
      let year = new Date(this.inTake_date).getFullYear();
      let month: any = new Date(this.inTake_date).getMonth();
      let day = new Date(this.inTake_date).getDate();
      month = this.months[month];
      dateString = `${month} ${day}, ${year}`
      formatted_inTake_date = dateString;
    }
    if (this.last_admit_date) {
      let dateString = "";
      let year = new Date(this.last_admit_date).getFullYear();
      let month: any = new Date(this.last_admit_date).getMonth();
      let day = new Date(this.last_admit_date).getDate();
      month = this.months[month];
      dateString = `${month} ${day}, ${year}`
      formatted_last_admit_date = dateString;
    }

    const patient: any = {
      // userInfo
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,

      first_name: this.first_name,
      middle_name: this.middle_name,
      last_name: this.last_name,
      race: this.race,
      patient_ssn: this.patient_ssn,
      mrn: this.mrn,
      email: this.email,
      phone: this.phone,
      // date_of_birth: formatted_date_of_birth,
      date_of_birth: this.date_of_birth,
      inTake_date: formatted_inTake_date,
      // last_admit_date: this.last_admit_date,
      last_admit_date_string: formatted_last_admit_date,
      p_insurance_carrier: this.p_insurance_carrier,
      p_insurance_holder_name: this.p_insurance_holder_name,
      p_insurancePolicyNumber: this.p_insurancePolicyNumber,
      p_insuranceGroupNumber: this.p_insuranceGroupNumber,
      s_insurance_carrier: this.s_insurance_carrier,
      s_insurance_holder_name: this.s_insurance_holder_name,
      s_insurancePolicyNumber: this.s_insurancePolicyNumber,
      s_insuranceGroupNumber: this.s_insuranceGroupNumber,
      coverages : this.coverages,
      city: this.city,
      zip_code: this.zip_code,
      address: this.address,
      state: this.state,
      responsible_party: this.responsible_party,
      responsible_party_contact: this.responsible_party_contact,
      hospice_notes: this.hospice_notes,
      census_id: this.census_id,
      isHospice: this.isHospice,
      isVA: this.isVA,
      VA_notes: this.VA_notes,
      gender: this.gender,
      facility_id: this.selectedFacilityId,
      facility_id_ref: this.selectedFacilityId,
      primary_doctor_id: this.selectedDoctorId,
      is_active: 'true',
      date_of_birth_d: this.date_of_birth_d,
      refer_provider_first_name : this.refer_provider_first_name,
      refer_provider_last_name : this.refer_provider_last_name,
      refer_provider_phone_number : this.refer_provider_phone_number,
      refer_provider_fax_number : this.refer_provider_fax_number,
      refer_provider_location : this.refer_provider_location,
      refer_provider_address : this.refer_provider_address,
      facility_patient_mrn: this.facility_patient_mrn
    }
    console.log("Patient-----: ", patient);
    patient.patient_id = this.patient_id;
    patient.company_id = this.currentUser.company_id;
    this.updateExistingPatient(patient);
  }

  updateExistingPatient(patient, onSuccess?) {
    // ACTIVITY LOG
    this._socketService.addActivityLog({
      id: this.currentUser._id,
      screen: 'Patient Add',
      operation: `Update Patient API Call`,
      datetime: this.date_of_birth
    })
    const infoToastr = this._toastr.info('Updating patient...', "Please wait");
    this._patientAddServie.updatePatient(patient).subscribe((response: any) => {
      if (response.status === 200) {
        this._toastr.success(response.message, "Success");
        if (onSuccess) onSuccess.emit(patient);
        //this.router.navigate([`/company/${this.currentUser.company_id}/patients`]);
        this.location.back();
      } else {
        this._toastr.error(response.message, "Failed");
      }
    }, console.error, () => this._toastr.clear(infoToastr.toastId))
  }

  onCancel() {
    this.location.back();
    //this.router.navigate([`/company/${this.currentUser.company_id}/patients`]);
  }
  allowOnlyText(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (event.charCode == 45) {
      return true;
    }
    if (charCode > 90 && charCode < 97) {
      return false;
    }

    if (charCode < 65 || charCode > 122) {
      return false;
    }
    return true;
  }

  handlePhoneModelChange(value,field) {
    let cleaned = value?.replace(/\D/g, '');
    console.log(cleaned);

    if (cleaned?.length === 0) {
      cleaned = '';
    } else if (cleaned?.length <= 3) {
      cleaned = cleaned?.replace(/^(\d{0,3})/, '($1)');
    } else if (cleaned?.length <= 6) {
      cleaned = cleaned?.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else if (cleaned?.length <= 10) {
      cleaned = cleaned?.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    } else {
      cleaned = cleaned?.substring(0, 10);
      cleaned = cleaned?.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    }
    setTimeout(() => {

      this.phone = cleaned;
      this.phoneCleaned = cleaned;
      if(field == 'phone') {
        this.phone = cleaned;
        this.phoneCleaned = cleaned;
      } else if(field == 'refer_provider_phone_number') {
        this.refer_provider_phone_number = cleaned;
        this.phoneCleaned = cleaned;
      } 
    })
  }

  dataChanged(e){
    console.log("ee", e);
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    else {
      return true;
    }
  }

  keyPressAlphanumeric(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (/^[a-zA-Z0-9]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  checkPCCPatient() {
    return this.patient.source === 'PointClickCare' || this.patient.pcc_patientId;
  }

  facilityDisable() {
    if(this.currentUser._id.toString() == '656f1c292b4428f2158ed5c2' || this.currentUser._id.toString() == '664c89bb0f7524f50debe97d' 
    || this.currentUser._id.toString() == '65f0a7aaf9f8b77822983b78' || this.currentUser._id.toString() == '66980476ba8e8d5fca3eb113') {
      return false;
    }
    else {
      return this.isDisabledFacility;
    }
  }

  async getPatientFacesheet() {
    console.log('patientIDDD In function',this.patient_id);
    this._faceSheetService.getFacesheet(this.patient_id).subscribe((res:any)=>{
      if(res.status == 200) {
        this.facesheetUrl = res.data.facesheetURL;
        this.uploadedFacesheetTitle = res.data.title;
      }
    });
  }

  openFacesheet() {
    this.dialog.open(PdfViewerPopComponent, {
      width: '50vw',
      height: '95vh',
      data: {
        file_path: this.facesheetUrl
      }
    })
  }
  setDateObjCoverage(field:string){
    if(field == 'plan_effective_date') {
      const selectedDate = moment(this.plan_effective_date); 
       this.newCoverage.plan_effective_date = this.commonService.splitMomentToObj(selectedDate);
    }
    if(field == 'plan_expiration_date') {
      const selectedDate = moment(this.plan_expiration_date); 
      this.newCoverage.plan_expiration_date = this.commonService.splitMomentToObj(selectedDate);
   }
  }
  addCoverage() {
    const existingCoverageIndex = this.coverages.findIndex(coverage => coverage.ins_order === this.newCoverage.ins_order);
    if (existingCoverageIndex !== -1) {
      this.coverages[existingCoverageIndex] = { ...this.newCoverage };
    } else {
      this.coverages.push({ ...this.newCoverage });
    }
    this.addInsuranceLabel = 'Add Insurance'
    this.clearNewCoverage();
    this.coveragesList.data = this.coverages;
    this.newCoverage.ins_order = this.coverages.length+1;
  }
  removeCoverage(index: number) {
    if (index >= 0 && index < this.coverages.length) {
      this.coverages.splice(index, 1); 
      this.coverages.forEach((coverage, idx) => {
        coverage.ins_order = idx + 1;
    });
    this.coveragesList.data = [...this.coverages]; 
    this.newCoverage.ins_order = this.coverages.length+1;
    }
  }
  
  // Function to clear newCoverage object
  clearNewCoverage() {
    this.newCoverage = setCoverageValues(this.newCoverage);
    this.plan_effective_date = null;
    this.plan_expiration_date = null;
    this.newCoverage.ins_order = this.coverages.length +1;
   }
  selectCoverage(insOrder: number) {
  const selectedCoverage :any = this.coverages.find(coverage => coverage.ins_order === insOrder);
  if (selectedCoverage) {
    if (selectedCoverage.plan_effective_date) {
      let year = selectedCoverage.plan_effective_date.year;
      let month = selectedCoverage.plan_effective_date.month;
      let day = selectedCoverage.plan_effective_date.day;
      if (month < 10) { month = '0' + month; }
      if (day < 10) { day = '0' + day; }
      this.plan_effective_date = (year + '-' + month + '-' + day);
    }
    if (selectedCoverage.plan_expiration_date) {
      let year = selectedCoverage.plan_expiration_date.year;
      let month = selectedCoverage.plan_expiration_date.month;
      let day = selectedCoverage.plan_expiration_date.day;
      if (month < 10) { month = '0' + month; }
      if (day < 10) { day = '0' + day; }
      this.plan_expiration_date = (year + '-' + month + '-' + day);
    }
    this.newCoverage = { ...selectedCoverage };
    this.addInsuranceLabel = 'Edit Insurance'
  }
  }
  getInsuranceType(insOrder: number): string {
    switch (insOrder) {
      case 1: return 'Primary';
      case 2: return 'Secondary';
      case 3: return 'Tertiary';
      default: return 'Other';
    }
  }
  
  insuredDateOfBirth (value) {
    this.newCoverage.insured_dob = value;
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.coveragesList.data, event.previousIndex, event.currentIndex);
    this.coverages.forEach((coverage, index) => {
      coverage.ins_order = index + 1;
    });
    this.coveragesList.data = this.coverages;
  } 
}
