<mat-card>
    <mat-card-content>
        <mat-tab-group class="child-tabs" (selectedTabChange)="onTabChange($event)">
            <mat-tab label="SNF WC Nurse-SNF WC Nurse">
                <div class="mat-tab-content" *ngIf="tabIndex === 0">
                    <app-snf_wc_nurse-snf_wc_nurse-association></app-snf_wc_nurse-snf_wc_nurse-association>
                </div>
            </mat-tab>
            <mat-tab label="SNF WC Nurse-Facility">
                <div class="mat-tab-content" *ngIf="tabIndex === 1">
                    <app-snf-wc-nurse-facility-association></app-snf-wc-nurse-facility-association>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>