import {HttpClient} from "@angular/common/http";
import * as global from '../global';
import {AuthService} from "src/app/services/auth.service";
import {API_URL} from "../../../environments/api-url";
import * as _ from 'lodash'
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })

export class ReleaseService {
    currentUser: any = null;
    userInfo: any = {};
 
    
    constructor(private httpclient: HttpClient,
                private _authService: AuthService,
                ) {
                    this.currentUser = this._authService.currentUser;
    }

    getNotes(release_type?) {
        console.log('ReleseService/getNotes');
        const details = {
            ...this._authService.authObject,
            createdBy : this.currentUser._id,
            releaseType: release_type
        }
        return this.httpclient
            .post(global.url + API_URL.RELEASE.getNotes, {details: details}).pipe();
    };
    addNotes(note,noteType?) {
        // console.log('addReleasenote/add');
        const details = {
            ...this._authService.authObject,
            createdBy : this._authService.currentUser._id,
            note,
            noteType
        }
        return this.httpclient
            .post(global.url + API_URL.RELEASE.addNotes, {details: details}).pipe();
      }

      editNote(note,noteId) {
        // console.log('addReleasenote/add');
        const details = {
            ...this._authService.authObject,
            createdBy : this._authService.currentUser._id,
            note,
            noteId 
        }
        return this.httpclient
            .post(global.url + API_URL.RELEASE.editNote, {details: details}).pipe();
      }
      deleteNote(noteId) {
        // console.log('addReleasenote/add');
        const details = {
            ...this._authService.authObject,
            noteId 
        }
        return this.httpclient
            .post(global.url + API_URL.RELEASE.deleteNote, {details: details}).pipe();
      }

}