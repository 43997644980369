<app-header></app-header>

<main> 
  <div class="container flex flex-col">
    
    <div class="transcription-audio flex flex-col">

      <div class="flex gap-2" *ngIf="currentaudio">
        <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

        <div class="flex w-1/4 justify-start items-center">
          <div class="flex-grow-0">
            <span class="dp"></span>
          </div>

          <div class="justify-start items-center">
            <div class="flex flex-col">
              <div class="title">{{currentaudio.audio_title}}</div>
              <div>
                <span class="date">DOS: {{getServiceDate(currentaudio)}}</span>
              </div>
              <div>
                <span class="date">DOC: {{getCreationDate(currentaudio)}}</span>
              </div>
              <div>
                <span  *ngIf="!isProvider()">
                  <span *ngIf="!timeUp" class="date" >
                      TAT:
                       <countdown-timer  class="date" 
                      [end]="getEndTimeForTimer(currentaudio)" (zeroTrigger)="triggerFunction()">
                  </countdown-timer> 
                  </span>
                  <span *ngIf="timeUp" style="color: red;" class="date" >
                      TAT: -
                      <countdown-timer  class="date" 
                     [start]="getStratTimeForTimer(currentaudio)" >
                 </countdown-timer> 
                  </span>
              </span>
              </div>
            </div>
          </div>

        </div>


        <div class="flex flex-grow justify-center items-center">
          <div class="transcription-player">
            <audio id="dictation" *ngIf="currentaudio.aws_path" [src]="currentaudio.aws_path" controls controlsList="nodownload"></audio>
          </div>
        </div>

        <div class="flex justify-end items-center">
          <div class="flex flex-col">
            <div class="duration flex justify-end items-center">{{currentaudio.audio_length}}</div>
            <div class="status-col">
              <div class="flex flex-grow justify-end items-center gap-2">
                <mat-menu #downloadAudioMenu="matMenu">
                  <button mat-menu-item (click)="download('mp4',currentaudio)">MP4</button>
                  <button mat-menu-item (click)="download('mp3',currentaudio)">MP3</button>
                </mat-menu>
                <button *ngIf="this.datas.user_type == global.USER_TYPE.DOCTOR || this.datas.user_type == global.USER_TYPE.SNF_WC_NURSE" class="dn-button-primary"
                  mat-raised-button mat-button>{{currentaudio.status}}</button>

                <app-switch-button labelOff="In Progress"
                  labelOn="Completed" [thirdForm]="false"
                                   (handleOff)="changeCurrentAudioStatus(19)"
                  (handleOn)="changeCurrentAudioStatus(20)" [checked]="currentaudio.status==='COMPLETED'">
                </app-switch-button>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!loading && !currentaudio">No Audio Found</div>
    </div>
    <div class="transcription-button flex jusitfy-between items-center" 
     style="background-color: white; margin-bottom: 20px;"
     *ngIf="currentaudio">
      <div style="margin-right: 20px; width: 40%;">
        <button class="dn-button-secondary" mat-raised-button (click)="goBack()">Back</button>
      </div>
      <div class="flex jusitfy-between items-center" style="margin-right: 50px;">
        <div style="margin-right: 50px; width: 40%;">
          <mat-form-field>
              <mat-label>Patient</mat-label>                        
              <img matPrefix class="pcc-icon" src="../../../assets/icons/pcc-icon.svg" *ngIf="patient?.source == 'PointClickCare' || patient?.pcc_patientId" />                                            
              <img matPrefix src="../../../assets/icons/matrix.png" *ngIf="patient?.source == 'MatrixCare'" alt = "" 
              style="height: 12px;
              width: 20px;  
              margin-right: 5px;
              vertical-align: middle;"/>                                            
              <input matInput type="text" [(ngModel)]="patient" name="patient" [formControl]="patientControl"
                  [matAutocomplete]="patientAutoComplete"
                  >
              <mat-autocomplete #patientAutoComplete="matAutocomplete"
                  [displayWith]="displayPatientFn">
                  <mat-option *ngFor="let patient of filteredPatients | async" [value]="patient" (click)="patientAutoComplete.closePant()">
                      <app-patient-name [patient]="patient"></app-patient-name>
                  </mat-option>
              </mat-autocomplete>
          </mat-form-field>
        </div>
        <div  style="width: 40%; display: flex; margin-right: 20px;">
          <button class="dn-button-primary" mat-raised-button  style="margin-right: 20px;">
            <label class="custom-upload-button" for="uploadTranscription">
              <input type="file" id="uploadTranscription" (change)="uploadFileAndCreateTranscription($event)"
                accept=".docx">
              Upload Note
            </label>
          </button>
            <button class="dn-button-primary" mat-raised-button (click)="noteEditor()" [disabled]="!patient">Create Note </button>
        </div>
      </div>
      
    </div> 
    <div class="flex gap-2 flex-grow">
      <div class="flex flex-col flex-grow">
        <h3 class="dark-blue-heading flex gap-2" style="width: 100%;">
          <span>Dictation Notes</span>
        </h3>
        <div class = "flex-grow flex flex-col">
          <app-audio-note-list *ngIf="currentaudio" [audio]="currentaudio" [audio_id]="currentaudio._id"></app-audio-note-list>
        </div>
      </div>
      <div class = "flex flex-col">
        <h3 class="dark-blue-heading flex gap-2">
          <mat-icon>comment</mat-icon>
          <span style="cursor: pointer" (click)="chunk_view = false">Comments</span>
        </h3>
        <div class = "flex-grow flex flex-col">
          <div class="transcription-comment-tab flex-grow" *ngIf="chunk_view">
            <app-audio-chunk ></app-audio-chunk>
          </div>
          <ul  class="transcription-comment-tab flex-grow" *ngIf="!chunk_view">
            <li *ngFor="let tc of transcriptionComments"> 
              <div class="flex"> 
                <div class="flex-grow-0"> 
                  <img class="dp" [src]="tc.replying_picture">
                </div>
    
                <div class="flex-grow justify-start items-center">
                  <div class = "flex flex-col gap-1">
                    <div class="title" class="text">{{tc.users[0].first_name}} {{tc.users[0].last_name}}</div>
                    <div class="comment">
                      <span *ngIf="tc.type === '15'">{{tc.text_comment}}</span>
                      <img class="comment-img" *ngIf="tc.type !== '15'" [src]="tc.path" (click)="openImage(tc)" />
                    </div>
                  </div>
                </div>
    
                <div class="flex-grow-0 justify-end items-end">
                  <span class="date">{{tc.date_time | date: global.date_time_format}}</span> 
                </div>
    
              </div>
            </li>
          </ul>
    
    
          <div class = "comment-section flex justify-center items-center gap-2">
            <mat-form-field color='warn' class="flex">
              <textarea matInput [(ngModel)]="editor" placeholder="Add your comments ..."></textarea>
            </mat-form-field>
            <button (click)="addComment(editor)" class="dn-button-primary comment-button flex" mat-raised-button >Comment</button>  
        </div>
        </div>
      </div>
    </div>

    <ngx-loading [show]="loading"></ngx-loading>
  </div>
</main>
