import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { SocketService } from 'src/app/services/socket.service';
import { LoginAuthRedirectService } from './login-auth-redirect.service';
import * as global from '../global'
import { LoginService } from '../login/login.service';
import moment from 'moment'
@Component({
  selector: 'app-login-auth-redirect',
  templateUrl: './login-auth-redirect.component.html',
  styleUrls: ['./login-auth-redirect.component.css']
})
export class LoginAuthRedirectComponent implements OnInit, OnDestroy {

  private token:string;
//   private cookie:string;
  private creds:string;
  private iOReQA:boolean = false;
  verificationResponse: any;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _loginAuthRedirectService: LoginAuthRedirectService,
    private _authService: AuthService,
    private _socketService: SocketService,
    private _toastr: ToastrService,
    public loginService: LoginService,

    ) { }

  ngOnInit(): void {
    const creds = this._route.snapshot.queryParams['creds'];
    if(creds) {
        return this.initLoginFromCreds(creds);
    }

    // Obtain token from SSO code
    const code = this._route.snapshot.queryParams['code'];
    if(code) {
        return this.initLoginFromSSO(code);
    }
  }

  initLoginFromCreds(creds: string): void { 
    const decodedCredsParam = decodeURIComponent(creds);
    localStorage.clear();
    this._authService.loginSource = 'login.doctornow.io';
    this._loginAuthRedirectService
        .getLoginInfo({creds: decodedCredsParam})
        .subscribe(this.handleLoginResponse.bind(this));
  }

  initLoginFromSSO(code: string): void {
    localStorage.clear();
    this._authService.loginSource = 'sso';
    this._loginAuthRedirectService
        .getLoginInfo({code})
        .subscribe(this.handleLoginResponse.bind(this));
  }

  async handleLoginResponse(loginInfoResponse: any) {
    {
        const infoToast = this._toastr.info(
            "Logging in...",
            "Please wait",
            { disableTimeOut: true }
        );
        if(loginInfoResponse.status !== 200) {
            let message = 'Invalid grant';
            if(loginInfoResponse.message) {
                message = loginInfoResponse.message.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
            }
            this._toastr.error(message, 'Failed');
            return this._router.navigate(['/login']);
        } 
        const { user } = loginInfoResponse.data;
        if (
            (user.user_type == "2" &&
                loginInfoResponse.data.array &&
                loginInfoResponse.data.array.length > 0) ||
            (user.user_type == global.USER_TYPE.MEDICAL_ASSISTANT &&
                loginInfoResponse.data.array &&
                loginInfoResponse.data.array.length > 0)
        ) {
            loginInfoResponse.data.array.some((element) => {
                if (
                    element.qa_e_id == user._id ||
                    element.qa_id == user._id
                ) {
                    this.iOReQA = true;
                    localStorage.setItem(
                        "iOReQA",
                        JSON.stringify(this.iOReQA)
                    );
                }
            });
        }

        const auth_key = user.web_auth_key + ".WEB";
        user.auth_key = auth_key;
        // ACTIVITY LOG
        this._socketService.addActivityLog({
            id: user._id,
            screen: "Login",
            operation: "Login API Call",
            datetime: moment().format(global.moment_date_time_format)
        });
        localStorage.setItem("currentUserAuth", JSON.stringify(user));
        if (user.cognito) {
            this._toastr.clear(infoToast.toastId);
            // if (user.two_factor_auth) {
            //     const verficationCodeInfoToast = this._toastr.info(
            //         "Sending verification code...",
            //         "Please wait",
            //         { disableTimeOut: true }
            //     );

            //     this._router.navigate(["/auth"]);

            //     const respones = await this.loginService
            //         .send2FactorVerificationCode(user)
            //         .toPromise();
            //     this._toastr.clear(verficationCodeInfoToast.toastId);

            //     this.verificationResponse = respones;
            //     if (this.verificationResponse["status"] === 200) {
            //         // this._toastr.success(this.verificationResponse['message'], 'Success');
            //     } else {
            //         this._toastr.error(
            //             this.verificationResponse["message"],
            //             "Failed"
            //         );
            //     }
            //     this._toastr.clear(verficationCodeInfoToast.toastId);
            // } else {
            //     this._authService.currentUser = user;
            //     await this._authService
            //         .LoginActivity({ operation: "login", platform: "web" })
            //         .toPromise();
            //     this._authService.redirectToHome();
            // }
            
            this._authService.currentUser = user;
            await this._authService
                .LoginActivity({ operation: "login", platform: "web" })
                .toPromise();
            this._authService.redirectToHome();
        } else {
            this._toastr.warning(
                "This account is not verified, please verify first",
                "Warning"
            );
            localStorage.setItem("register_auth", JSON.stringify(user));
            this._router.navigate([`/company/${user.company_id}/user-create-password`],{queryParams: {user_id: user._id, email: user.email}})
        }
        this._authService.redirectToHome();
    }
  }

  ngOnDestroy():void {
  }
}
