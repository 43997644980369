import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Location} from '@angular/common';
import {AppointmentDataService} from 'src/app/components/appointment/service/data.service';
import {Payor} from 'src/app/classes/payor';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ActivatedRoute} from '@angular/router';
import * as usa_states from '../../../../shared/popup-dialogs/facility-add-dialog/usa_states';
import {DictionaryDialogComponent} from "../../../../shared/popup-dialogs/dictionary-dialog/dictionary-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {PayorContactDialogComponent} from "../../../../shared/popup-dialogs/payor-contact-dialog/payor-contact-dialog.component";
import {MatTableDataSource} from "@angular/material/table";

@Component({
    selector: 'app-company-add-ime',
    templateUrl: './company-add-ime.component.html',
    styleUrls: ['./company-add-ime.component.css']
})
export class CompanyAddImeComponent implements OnInit {
    usa_states = usa_states.default.slice(1)
    public model = new Payor();
    contacts = [new Payor()];

    payor_id: string;
    loaderId = 'company-ime-add-form-loader';
    public dataSource: any;
    displayedColumns: string[] = ['name', 'position', 'email', 'phone', 'operations'];

    constructor(
        private _location: Location,
        private loader: NgxUiLoaderService,
        public service: AppointmentDataService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private toastr: ToastrService,
    ) {
        this.model.contact = {
            first_name: '',
            last_name: '',
            phone: '',
            extension: '',
            position: '',
            email: ''
        }
        this.model.company_id = this.route.parent.parent.snapshot.params.id;
        this.payor_id = this.route.snapshot.params.id;

    }

    ngOnInit(): void {
        if (this.payor_id) {
            this.loader.startLoader(this.loaderId);

            this.service.getPayor(this.payor_id).subscribe((response: any) => {
                if (response.status === 200) {
                    this.model = response.data;
                }
                this.loader.stopLoader(this.loaderId);

            });
            this.service.getPayorContacts({payor: this.payor_id})
                .subscribe( res => {
                    let data = res.data.array;
                    this.dataSource = new MatTableDataSource(data);
                });
        }

    }

    handleSubmit(e) {
        try {
            this.loader.startLoader(this.loaderId);
            if (this.payor_id) {
                this.service.updatePayors(this.model).subscribe((response: any) => {
                    this.loader.stopLoader(this.loaderId);
                    this.toastr.success('IME Vendor updated succesfully', 'Success')
                })
            } else {
                this.service.addPayor(this.model).subscribe(
                    (data) => {
                        this.toastr.success('IME Vendor added successfully', 'Success');
                        this.goBack();
                    }
                );
            }
        } catch (error) {
            this.loader.stopLoader(this.loaderId);
            this.toastr.error('Something went wrong', 'Error');
        }
    }

    editPayorContact(contact) {
        
        const dialogRef = this.dialog.open(PayorContactDialogComponent, {
            width: '600px',
            data: {update: true, contact: contact}
        });

        dialogRef.afterClosed().subscribe(async (data) => {
            // @ts-ignore
            if (contact._id === this.model.contact._id) {
                this.service.getPayor(this.payor_id).subscribe((response: any) => {
                    if (response.status === 200) {
                        this.model = response.data;
                        
                    }
                });
            }
            this.service.getPayorContacts({payor: this.payor_id})
                .subscribe( res => {
                    this.dataSource = new MatTableDataSource(res.data.array);
                });
        });
    }

    deletePayorContact(contact) {
        let params = {
            _id: contact._id,
            is_active: false
        };
        this.service.updatePayorContact(params)
            .subscribe(res => {
                this.toastr.success('IME Contact Deleted', 'Success');
                this.service.getPayorContacts({payor: this.payor_id})
                    .subscribe( res => {
                        this.dataSource = new MatTableDataSource(res.data.array);
                    });
            });
    }

    openDialog() {
        if (!this.payor_id) {
            this.toastr.error('Please add IME vendor first', 'Error');
            return;
        }
        const dialogRef = this.dialog.open(PayorContactDialogComponent, {
            width: '600px',
            data: {payor_id: this.payor_id}
        });

        dialogRef.afterClosed().subscribe(async (data) => {
            this.service.getPayorContacts({payor: this.payor_id})
                .subscribe( res => {
                    this.dataSource = new MatTableDataSource(res.data.array);
                });
        });
    }

    goBack() {
        this._location.back();
    }

    numberToString(number) {
        return String(number);
    }
}
