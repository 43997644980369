import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import {API_URL} from "../../../environments/api-url";
import * as global from '../../includes/global';

@Injectable({
  providedIn: 'root'
})
export class BillingCompanyAssociationService {
  currentUser: any;
  constructor(
    private authService: AuthService,
    private httpclient: HttpClient,
  ) { 
    this.currentUser = this.authService.currentUser
  }

  getProviders(company_id = '', facility_id = ""){
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      company_id: company_id, 
      facility_id: facility_id
    };
    return this.httpclient.post(global.url + API_URL.FACILITY.getAllProvidersAssociatedToFacility, {details: details});
  }
  getBillingCompanies(){
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type, 
    };
    return this.httpclient.post(global.url + API_URL.BILLING.getBillingCompanies, {details: details});
  }
  getDNBillingAssociation(page, search, company_id = "", model){
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type, 
      company_id: company_id,
      page: page,
      search: search,
      model: model,
    };
    return this.httpclient.post(global.url + API_URL.BILLING.getDNBillingAssociation, {details: details});
  }
  createBillingCompanyAssociation(model = {}){
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type, 
      model: model
    };
    return this.httpclient.post(global.url + API_URL.BILLING.createBillingCompanyAssociation, {details: details});
  }
  deleteRelation(relation_id = ""){
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type, 
      relation_id: relation_id
    };
    return this.httpclient.post(global.url + API_URL.BILLING.deleteRelation, {details: details});
  }

  updateProviderExternalId(relation_id, updatedProviderExternalId) {
    const details = {
      id: this.currentUser._id,
      relation_id: relation_id,
      updatedProviderExternalId : updatedProviderExternalId
    };
    return this.httpclient.post(global.url + API_URL.BILLING.updateProviderExternalId, {details: details});
  }

  updateFacilityExternalId(relation_id, updatedFacilityExternalId) {
    const details = {
      id: this.currentUser._id,
      relation_id: relation_id,
      updatedFacilityExternalId : updatedFacilityExternalId
    };
    return this.httpclient.post(global.url + API_URL.BILLING.updateFacilityExternalId, {details: details});
  }

}
