<form (submit)="fileInput.value ? dialogRef.close(fileInput) : emptyError=true">
    <h1 mat-dialog-title>Upload File</h1>
    <div mat-dialog-content>
        <input placeholder="Select file" type="file" #fileInput required (change)="emptyError = fileInput.value ? false : true" multiple>
        <p style="color:red" *ngIf="emptyError">You must select the file to upload.</p>
    </div>
    <div mat-dialog-actions class="flex gap-5" style="justify-content: space-between;">
      <button mat-raised-button class="dn-button-primary">Upload</button>
      <button mat-raised-button class="dn-button-secondary" mat-dialog-close type="reset">Cancel</button>
    </div>
</form>