import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import * as global from "../../../../global";
import { WoundService } from "src/app/services/wound.service";
import { HttpResponse } from "@angular/common/http";
import moment from "moment";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { PatientListService } from "src/app/includes/patient-list/patient-list-service";
import { WoundCareWidgetService } from "src/app/includes/note-sidebar/wound-care-widget/wound-care-widget.service";
import { MergePatientsService } from "src/app/includes/merge-patients/merge-patients.service";
import { AuthService } from "src/app/services/auth.service";
import { DashboardService } from "src/app/includes/dashboard/dashboard.service";
import { TranscriptionCreateService } from "src/app/includes/transcription-create/transcription-create.service";
import { ToastrService } from "ngx-toastr";
import { Observable, lastValueFrom } from "rxjs";
import swal from "sweetalert";
import { WoundFilenamePopupComponent } from "./wound-filename-popup/wound-filename-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { FacilitySettingsService } from "src/app/components/company/company-facilities/facility-settings/facility-settings.service";
import { map, startWith } from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { FacilityService } from "src/app/services/facility.service";
import { UserService } from "src/app/services/Modules/user.service";
import { CommonService } from "src/app/services/common.service";
import { WoundNurseRelationService } from "src/app/services/Modules/woundNurse.service";
import { P } from "@angular/cdk/keycodes";
import { facilityPopulationInterface } from "src/app/facilityPopulationInterface";
import { SnfWcNurseAssociationService } from "src/app/includes/snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service";

interface IFilter {
    active: boolean;
    patientName: string;
    dob: String;
    facility?: String;
    provider?: any;
    dn_mrnNo?: String;
    pcc_mrnNo?: String;
    // index: String,
}
@Component({
    selector: "app-tissue-analytic-report",
    templateUrl: "./tissue-analytic.component.html",
    styleUrls: ["./tissue-analytic.component.css"]
})
export class TissueAnalyticReportComponent implements OnInit {
    loader_id = "app-wound-report";
    global = global;
    currentDate = moment(new Date());
    companyId: any;
    patients: any = [];
    selectedWounds: any = [];
    patient: any;
    searchTextProvider: any;
    readOnlyMode = true;
    assocProviders: Array<any> = [];
    filter: IFilter;
    currentUser: any;
    woundState: string = "active";
    nursingSide: boolean;
    user_dashboard: Boolean = false;
    companySide: boolean;
    allIdArray: any;
    loadWounds: boolean = false;
    allRecentNotes: any;
    selectedNote: any;
    childWoundList: any = [];
    childWoundDetails: boolean;
    selectedChild: any;
    singleReportSelected: boolean = false;
    allDataforGroupReport: any = [];
    prevousSelectedNote: any;
    configuration: any = {
        wound_assessment_report_category: "",
        progress_note_submission_type: "",
        wound_assessment_report_submission_type: "",
        wound_assessment_report_name: ""
    };
    updationDate = {
        startDate: moment().subtract(6, "days"),
        endDate: moment()
    };
    preDateRanges: any = {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 1 Week": [moment().subtract(6, "days"), moment()],
        "Last 2 Weeks": [moment().subtract(13, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, 'days'), moment()]
    };
    facilityControl = new FormControl();
    providerControl = new FormControl();
    dateOfBirthControl = new FormControl("");
    patientControl = new FormControl();
    facilities: Array<any> = [];
    providers: Array<any> = [];
    filteredProviders: Observable<string[]>;
    filteredFacilities: Observable<string[]>;
    facility_id: any;
    provider_id: any;
    dateOfBirth: string = "";
    should_show_view_icon:boolean = true;
    searchText: string = "";
    constructor(
        // private _facilityService: FacilityService,
        private router: Router,
        private loader: NgxUiLoaderService,
        private _location: Location,
        private patinetService: PatientListService,
        private woundCareWidgetService: WoundCareWidgetService,
        private _route: ActivatedRoute,
        private woundService: WoundService,
        private mergePatientService: MergePatientsService,
        private authService: AuthService,
        private _dashboardService: DashboardService,
        private _transcriptionCreateService: TranscriptionCreateService,
        private toastr: ToastrService,
        private dialogRef: MatDialog,
        private _facilitySettingsService: FacilitySettingsService,
        private _facilityService: FacilityService,
        private _userService: UserService,
        private _commonService: CommonService,
        private _woundNurseRelationService: WoundNurseRelationService,
        private _snfWcNurseAssociationService: SnfWcNurseAssociationService
    ) {
        this.companyId = this._route.parent.snapshot.params.id;
        this.currentUser = this.authService.currentUser;
        if (this._route.parent.snapshot.params.id) {
            this.companySide = true;
        } else {
            this.companyId = this.currentUser.company_id;
        }
        if (window.location.pathname.includes("dashboard")) {
            this.user_dashboard = true;
        }
        if (window.location.pathname.includes("nursing")) {
            this.nursingSide = true;
        }
    }
    //////////////////////////////////////////////////////////////////////////////
    //////////////////////All Comment Code is for facility filter/////////////////
    //////////////////////////////////////////////////////////////////////////////
    async ngOnInit(): Promise<void> {
        // this.filter.provider = ""
        this.allIdArray = [];
        // this._transcriptionCreateService.getFacilitiesByCompanyID(this.companyId).subscribe((data: any) => {
        //   if (data.status == 200) {
        //     this.facilities = this.sortTitleVise(data.facilities)
        //   }
        //   else {

        //   }

        // })
        // await this.initAssocProviders();
        // console.log("associate provider", this.assocProviders);
        // this.getPatients(this.companyId);
    }

    async initWoundNurseFacilityAssociations() {
        const filter = { associated_wound_nurse_ids: this.currentUser._id };
        const nurseProjection = {
            first_name: 1,
            last_name: 1,
            title: 1,
            full_name: 1
        };
        const facilityProjection = {
            title: 1,
            pcc_facId: 1,
            source: 1,
            pcc_2_legged_authentication: 1
        };
        const nurseFilter: any = {
            company_id: this.companyId,
            is_activated: "true",
            user_type: global.USER_TYPE.WOUND_NURSE
        };
        const response: any = await lastValueFrom(
            this._woundNurseRelationService.getWoundNurseFacilityAssociations(
                filter,
                nurseProjection,
                facilityProjection,
                nurseFilter
            )
        );
        if (response.status === 200) {
            if (Array.isArray(response.data) && response.data.length > 0) {
                this.facilities = response.data.map(
                    (association) => association.facility_id
                );
                // this.onSelectFacility(this.facilities[0]._id);
                this.showFacilityDropdown();
            }
        }
    }

    async initSnfWcNurseFacilityAssociation() {
        const filter = {
            associated_snf_wc_nurse_ids: this.currentUser._id,
            company_id: this.currentUser.company_id
        };
        const nurseProjection = {
            first_name: 1,
            last_name: 1,
            title: 1
        };
        const facilityProjection = {
            title: 1,
            pcc_facId: 1,
            pcc_2_legged_authentication: 1,
            source: 1,
            pcc_orgUuid: 1
        };
        const response: any = await lastValueFrom(
            this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(
                filter,
                nurseProjection,
                facilityProjection
            )
        );
        if (response.status === 200 && response.data.length > 0) {
            this.facilities = response.data;
        }
    }

    async getSnfWcNurseByFacility(facility_id) {
        const filter = {
            facility_id
        };
        const nurseProjection = {
            first_name: 1,
            last_name: 1,
            full_name: 1,
            title: 1
        };
        const facilityProjection = {
            title: 1,
            pcc_facId: 1,
            pcc_2_legged_authentication: 1,
            source: 1,
            pcc_orgUuid: 1
        };
        const response: any = await lastValueFrom(
            this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(
                filter,
                nurseProjection,
                facilityProjection
            )
        );
        if (response.status === 200 && response.data.length > 0) {
            this.providers = response.data[0].associated_snf_wc_nurse_ids || [];
            this.showProviderDropdown();
        }
    }

    async initProviderFacilityAssociation() {
        const response: any = await lastValueFrom(
            this._facilityService.getMyFacilities(null, this.currentUser._id)
        );
        if (response.status == 200) this.facilities = response.data.array;
    }

    showProviderDropdown(): void {
        this.filteredProviders = this.providerControl.valueChanges.pipe(
            startWith(""),
            map((value: any) => this.filterProvider(value))
        );
    }

    showFacilityDropdown(): void {
        this.filteredFacilities = this.facilityControl.valueChanges.pipe(
            startWith(""),
            map((value: any) => this.filterFacilities(value))
        );
    }

    showPatientDropdown(): void {
        if (this.patients.length > 0) {
            this.patients = this.dateOfBirthControl.valueChanges.pipe(
                startWith(""),
                map((value: any) => this.filterPatients(value))
            );
        }
    }

    private filterPatients(value: string): string[] {
        if (value && this.patients.length > 0) {
            const filterValue = value.toLowerCase();
            // return this.patients.filter(option => (option.middle_name && option.middle_name.toLowerCase().indexOf(filterValue) !== -1) || option.last_name.toLowerCase().indexOf(filterValue) !== -1 || option.first_name.toLowerCase().indexOf(filterValue) !== -1);
            const patients = this.patients.filter(
                (option) =>
                    option.first_name.toLowerCase().match(filterValue) ||
                    option.last_name.toLowerCase().match(filterValue)
            );
            console.log("this.patients", patients);
            return patients;
        } else {
            return this.patients;
        }
    }

    private filterFacilities(value: string): string[] {
        if (value) {
            const filterValue = value.toLowerCase();
            return this.facilities.filter(
                (option) =>
                    option.title.toLowerCase().indexOf(filterValue) !== -1
            );
        } else {
            return this.facilities;
        }
    }
    private filterProvider(value: string): string[] {
        if (value && this.providers.length > 0) {
            const filterValue = value.toLowerCase();
            // return this.providers.filter(option => (option.middle_name && option.middle_name.toLowerCase().indexOf(filterValue) !== -1) || option.last_name.toLowerCase().indexOf(filterValue) !== -1 || option.first_name.toLowerCase().indexOf(filterValue) !== -1);
            return this.providers.filter((option) =>
                option.full_name.toLowerCase().match(filterValue)
            );
        } else {
            return this.providers;
        }
    }

    // handleDateOfBirthChange(value: string) {
    //     let formattedDateOfBirth = value.replace(/\D/g, "");
    //     if (formattedDateOfBirth.length <= 4) {
    //         formattedDateOfBirth = formattedDateOfBirth.replace(
    //             /^(\d{0,4})/,
    //             "$1"
    //         );
    //     } else if (formattedDateOfBirth.length <= 6) {
    //         formattedDateOfBirth = formattedDateOfBirth.replace(
    //             /^(\d{0,4})(\d{0,2})/,
    //             "$1-$2"
    //         );
    //     } else {
    //         formattedDateOfBirth = formattedDateOfBirth.substring(0, 10);
    //         formattedDateOfBirth = formattedDateOfBirth.replace(
    //             /^(\d{0,4})(\d{0,2})(\d{0,2})/,
    //             "$1-$2-$3"
    //         );
    //     }
    //     this.dateOfBirth = formattedDateOfBirth;
    //     this.dateOfBirthControl.setValue(this.dateOfBirth);
    //     if (this.dateOfBirthControl.value.length === 10) {
    //         this.searchPatients("", this.dateOfBirth);
    //     }
    // }

    handleDateOfBirthChange(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        let inputValue = inputElement.value.replace(/\D/g, '');
        
        if (inputValue.length > 8) {
            inputValue = inputValue.substring(0, 8);
        }
    
        let formattedDate = '';
        for (let i = 0; i < inputValue.length; i++) {
            if (i === 2 || i === 4) {
                formattedDate += '/';
            }
            formattedDate += inputValue[i];
        }
    
        inputElement.value = formattedDate;
    
        this.dateOfBirth = inputElement.value;
        this.dateOfBirthControl.setValue(this.dateOfBirth);
    
        if (inputElement.value.length === 10) {
            let splittedDate : any;
            splittedDate = this.dateOfBirth.split('/');
            let day = splittedDate[0];
            let month = splittedDate[1];
            let year = splittedDate[2];
            let formattedDOB = `${year}-${month}-${day}`
            this.dateOfBirth = formattedDOB;
            this.searchPatients('', formattedDOB);
        }
    }
    

    async initNurseFacilities() {
        const filter = {
            assoc_company_ids: this.companyId,
            nurse_ids_ref: this.currentUser._id,
            is_active: true
        };
        const projection = {
            title: 1,
            pcc_facId: 1,
            source: 1,
            pcc_2_legged_authentication: 1,
            assoc_company_ids: 1
        };

        let companyAssocPopulation: facilityPopulationInterface = {
            collection: "companies",
            field: ["assoc_company_ids"],
            filter: {
                $expr: {
                    $and: [
                        { $eq: [true, "$is_active"] },
                        {
                            $eq: [
                                true,
                                { $in: ["$_id", "$$assoc_company_ids"] }
                            ]
                        }
                    ]
                }
            },
            project: { name: 1 }
        };

        const facilitiesResponse: any = await lastValueFrom(
            this._facilityService.getFacilities(filter, projection, [
                companyAssocPopulation
            ])
        );
        if (facilitiesResponse.status == 200) {
            this.facilities = facilitiesResponse.data.array;
            // this.onSelectFacility(this.facilities[0]._id);
            // this.facilities.unshift({ _id: 'all', title: 'All' });
            this.showFacilityDropdown();
        }
    }
    async onSelectFacility($event, facilityId) {
        this.facility_id = $event;
        if(this.patient){
            if (this.currentUser.user_type === global.USER_TYPE.DOCTOR)
                await this.getProvidersByFacility(this.facility_id);
            else if (
                this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE
            )
                await this.getSnfWcNurseByFacility(this.facility_id);
            await this.selectPatient(this.patient);
        }
    }

    async onSelectProvider($event, providerId) {
        this.provider_id = providerId;
        if ($event.isUserInput && this.patient) {
            await this.selectPatient(this.patient);
        }
    }

    async getProvidersByFacility(facilityId) {
        const response: any = await lastValueFrom(
            this._userService.getUsersByFacility(facilityId)
        );
        if (response.status === 200) {
            this.providers = response.data;
            // this.providers.unshift({ _id: 'all', full_name: 'All' });
            // this.selectDefaultProvider();
            this.showProviderDropdown();
        }
    }
    selectDefaultProvider() {
        if (this.providers.length > 0) {
            this.provider_id = null;
            this.providerControl.setValue(this.providers[0].full_name);
        }
    }
    selectDefaultFacility() {
        if (this.facilities.length > 0) {
            this.facility_id = this.facilities[0]._id;
            this.facilityControl.setValue(this.facilities[0].title);
        }
    }

    async clearFacilityDropdown() {
        this.facilityControl.setValue("");
        this.facility_id = "";
        await this.selectPatient(this.patient);
    }
    async clearProviderDropdown() {
        this.providerControl.setValue("");
        this.provider_id = "";
        await this.selectPatient(this.patient);
    }

    clearDateOfBirthDropdown() {
        this.dateOfBirthControl.setValue("");
        this.dateOfBirth = "";
    }

    sortFirstNameVise(arrayTosort) {
        let users = arrayTosort;
        let result = users.sort(function (a, b) {
            if (a.first_name?.toLowerCase() < b.first_name?.toLowerCase()) {
                return -1;
            }
            if (a.first_name?.toLowerCase() > b.first_name?.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        return result;
    }

    getPatients(companyId, facilityId) {
        this.loader.startLoader(this.loader_id);
        this.patinetService
            .getPatientsV2(companyId, facilityId)
            .subscribe((data: any) => {
                this.patients = data.data.array;
                console.log("patients", data.data.array);
                // this.patients = data.data.array.filter(patient => patient.first_name === 'Burt');
                this.loader.stopLoader(this.loader_id);
                // this.patients = data.data.array;
            });
    }

    async handleProviderChange(id) {
        console.log("id", id);
        this.woundService.getProviderWounds(id).subscribe((data: any) => {
            this.selectedWounds = data.wounds;
            console.log("wounds", data);
        });
    }

    handleFacilityChange(event) {
        console.log("facility id", event);
        this._transcriptionCreateService
            .getAssocProviders(event)
            .subscribe((data: any) => {
                console.log("facility data", data);
                this.assocProviders = this.sortFirstNameVise(
                    data.facility.provider_ids_ref
                );
            });
        // this.getPatients(this.companyId, this.facilityID);
    }

    sortTitleVise(arrayTosort) {
        let users = arrayTosort;
        let result = users.sort(function (a, b) {
            if (a.title?.toLowerCase() < b.title?.toLowerCase()) {
                return -1;
            }
            if (a.title?.toLowerCase() > b.title?.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        return result;
    }

    isPCCFacility(facility_id) {
        for (const facility of this.facilities) {
            if (facility_id === facility._id) {
                if (facility.pcc_facId) {
                    return true;
                }
            }
        }
    }
    isMatrixFacility(facility_id) {
        for (const facility of this.facilities) {
            if (facility_id === facility._id) {
                if (facility.source == "MatrixCare") {
                    return true;
                }
            }
        }
    }

    async selectPatient(data) {
        console.log("comming patient", data);

        this.loadWounds = false;
        this.selectedWounds = [];
        this.allIdArray = [];
        this.patient = data;
        if (this.patient) {
            this.patientControl.setValue(this.patient);
            this.dateOfBirthControl.setValue(this.patient.date_of_birth);
        }
        if (this.patient.facility_id_ref.timeZone) {
            this.patient["timeZone"] = this.patient.facility_id_ref.timeZone;
        } else {
            this.patient["timeZone"] = "America/New_York";
        }
        await this.getRecentNotes(data._id);
        await this.initFacilitySettings();
        // console.log('this.patient["timeZone"] ', this.patient['timeZone'] )
        this.loadWounds = true;
        // this.initWounds(this.patient._id);
    }
    async getRecentNotes(patient_id) {
        let creationDate = JSON.parse(JSON.stringify(this.updationDate));
        const filter: any = {
            is_active: "true",
            is_trashed: "false",
            patient_id_ref: patient_id,
            creationDate: {
                startDate: moment(creationDate.startDate),
                endDate: moment(creationDate.endDate) 
            }
        };
        if (this.provider_id) filter.doctor_id_ref = this.provider_id;
        if (this.facility_id) filter.facility_id_ref = this.facility_id;
        let response: any = await this._transcriptionCreateService
            .getFiveRecentNotes(filter)
            .toPromise();
        if (response.status == 200) {
            this.allRecentNotes = response.data;
            this.selectedNote = this.allRecentNotes[0];
            // this.prevousSelectedNote = this.selectedNote;
            await this.getAllWoundDataforGroupReport(true);
        }
    }
    getNoteDate(note) {
        if (note.date_obj) {
            let { year, month, day } = note.date_obj;
            if (month < 10) {
                month = "0" + month;
            }
            if (day < 10) {
                day = "0" + day;
            }
            return `${day}/${month}/${year}`;
        } else {
            return moment(note.filterServiceDate).format("DD/MM/YYYY");
        }
    }
    goBack() {
        this._location.back();
    }
    wound: any;
    woundDetails: boolean = false;

    async woundAnalyticReport(event) {
        this.singleReportSelected = true;
        this.wound = event;
        this.woundDetails = true;
        this.childWoundList = [];
        this.selectedChild = null;

        ///
        const { status, data } = await lastValueFrom(
            this.woundCareWidgetService.getChildWounds(
                this.patient._id,
                this.wound._id
            )
        );

        if (data && data.length) {
            this.childWoundList = [this.wound, ...data];
            this.childWoundDetails = true;
            // this.selectedChild=this.childWoundList[0];
            // this.wound=this.selectedChild;
        } else {
            this.childWoundList = [];
            this.selectedChild = null;
            this.childWoundDetails = false;
        }
    }

    switchChild(child_wound) {
        this.woundDetails = true;
        this.childWoundDetails = true;
        this.selectedChild = child_wound;
        this.wound = this.selectedChild;
        console.log("selectedChild : ", this.selectedChild);
    }

    async downloadPDF() {
        let latest_child_wound_id = this.wound.latest_child_wound_id
            ? this.wound.latest_child_wound_id
            : null;
        this.woundService
            .exportWoundAsPdf(
                this.wound._id,
                latest_child_wound_id,
                this.patient
            )
            .subscribe((data: any) => {
                // this.wound.child_wounds = child_wounds;
                if (data instanceof HttpResponse) {
                    console.log("in httpresponse");
                    var url = window.URL.createObjectURL(data.body);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.setAttribute("style", "display: none");
                    a.href = url;
                    let date = "no_date";
                    if (this.wound.wound_updation_date) {
                        date = moment(this.wound.wound_updation_date).format(
                            "MM-DD-YYYY"
                        );
                    } else if (this.wound.updatedAt) {
                        date = moment(this.wound.updatedAt).format(
                            "MM-DD-YYYY"
                        );
                    }
                    a.download = `${
                        this.wound.body_part || "no_body_part"
                    }_${date}`;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove(); // remove the element
                } else {
                    console.log("in else");
                    if (data.type === 0) {
                    } else if (data.type === 3) {
                        const { loaded, total } = data;
                        const progress = Math.round((loaded / total) * 100);
                        if (progress > 0) {
                        }
                    }
                }
                if (data.status == 200) {
                    console.log("in status");
                    return true;
                }
            });
    }

    searchPatients(name?, dob?) {
        this.loadWounds = false;
        this.woundDetails = false;
        if (name && name.trim().length < 4) {
            this.loadWounds = false;
            return;
        }
        if (name !== null && typeof name === "object") {
            console.log("namee :is object: ", name);
            this.loadWounds = false;
            this.patient = name;
            console.log("selected patient", this.patient);
            let patient_one = name;
            // let filters["patient_id"]: any = this.patient?._id;
            // this.getPatientCharges(1, null);
            return;
        }
        name = name?.replace(",", "");
        if (name && name.length < 4) {
            return;
        }
        // this.toastr.info('Fetching Patients', 'Wait....');

        let filter1: any = {
            first_name: "",
            last_name: ""
        };
        filter1.first_name = name?.trim().split(" ")[0]?.trim();
        filter1.last_name = name?.trim().split(" ")[1]?.trim();
        if (dob) {
            filter1 = {};
            filter1.date_of_birth = this.dateOfBirth;
        }
        this.mergePatientService
            .searchThePatientOnly(filter1)
            .subscribe(async (patientsResponse: any) => {
                this.patients = patientsResponse.data;
                console.log('this.patients',this.patients);
                
                if (
                    this.currentUser.user_type === global.USER_TYPE.NURSE &&
                    !this.patient
                )
                    await this.initNurseFacilities();
                else if (
                    this.currentUser.user_type ===
                        global.USER_TYPE.WOUND_NURSE &&
                    !this.patient
                )
                    await this.initWoundNurseFacilityAssociations();
                else if (
                    this.currentUser.user_type ===
                        global.USER_TYPE.WOUND_NURSE &&
                    !this.patient
                )
                    await this.initWoundNurseFacilityAssociations();
                else if (
                    this.currentUser.user_type ===
                        global.USER_TYPE.SNF_WC_NURSE &&
                    !this.patient
                )
                    await this.initSnfWcNurseFacilityAssociation();
                else if (
                    this.currentUser.user_type === global.USER_TYPE.DOCTOR &&
                    !this.patient
                )
                    await this.initProviderFacilityAssociation();
                this.selectDefaultFacility();
            });
    }
    displayPatientFn(patient): string {
        this.patient = patient;
        console.log("selected patient", this.patient);
        return patient && patient.first_name
            ? patient.first_name + " " + patient.last_name
            : "";
    }

    allWoundIds(event) {
        // console.log("all wound IDS : ", event);
        this.allIdArray = event;
    }

    generateZipReportForAllWounds() {
        if (!(this.allIdArray && this.allIdArray.length)) {
            this.toastr.warning(
                "All wounds Have not fetched yet.",
                "Please Wait"
            );
            return;
        }

        const idArray = this.allIdArray; //["638995ff03f594e4a5d3ec6a", "636e3003fd3951052f8721bb", "62de3bf513491a30479b2c9c", "62821cc2a182d062ab1c6ecf"];
        this.toastr.info("Generating Zip for all Wounds", "Please Wait");

        this.woundService
            .generateZipReportForAllWounds(idArray, this.patient._id)
            .subscribe((data: any) => {
                // this.wound.child_wounds = child_wounds;
                console.log(data);
                if (data instanceof HttpResponse) {
                    console.log("in httpresponse");
                    var url = window.URL.createObjectURL(data.body);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.setAttribute("style", "display: none");
                    a.href = url;
                    a.download = `${this.configuration.wound_assessment_report_name}.zip`;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove(); // remove the element
                } else {
                    console.log("in else");
                    if (data.type === 0) {
                    } else if (data.type === 3) {
                        const { loaded, total } = data;
                        const progress = Math.round((loaded / total) * 100);
                        if (progress > 0) {
                        }
                    }
                }
                if (data.status == 200) {
                    console.log("in status");
                    return true;
                }
            });
    }

    generateReportForAllWounds() {
        console.log("selected patient", this.patient, this.selectedNote);
        let dateAssessment = "";
        if (this.selectedNote.date_obj) {
            let day =
                this.selectedNote.date_obj.day < 10
                    ? "0" + this.selectedNote.date_obj.day.toString()
                    : this.selectedNote.date_obj.day.toString();
            let month =
                this.selectedNote.date_obj.month < 10
                    ? "0" + this.selectedNote.date_obj.month.toString()
                    : this.selectedNote.date_obj.month.toString();
            let year = this.selectedNote.date_obj.year;
            dateAssessment = month + "/" + day + "/" + year;
        }
        this.toastr.info(`Generating PDF of Wound Report`, "Please Wait");
        this.woundCareWidgetService
            .generateReportForAllWounds(
                this.patient,
                "active",
                this.selectedNote?.date_obj || null
            )
            .subscribe((data: any) => {
                console.log("in else", data);
                if (data.type === 0) {
                } else if (data.type === 3) {
                    const { loaded, total } = data;
                    const progress = Math.round((loaded / total) * 100);
                    if (progress > 0) {
                    }
                }
                if (data.status == 500) {
                    console.log("in no wound");
                    this.toastr.warning(
                        `No wound found assessed on ${dateAssessment}`,
                        ""
                    );
                    return false;
                }
                if (data.status == 500) {
                    console.log("in no wound");
                    this.toastr.warning(
                        `No wound found assessed on ${dateAssessment}`,
                        ""
                    );
                    return false;
                } else if (data.status == 200) {
                    console.log("in status");
                    this._commonService.convertToBlobAndDownload(data.data, `${this.configuration.wound_assessment_report_name}_${dateAssessment}`, "pdf");
                    return true;
                }
            });
    }
    async generateReportandUploadtoPCC() {
        let dialogRef = this.dialogRef.open(WoundFilenamePopupComponent, {
            data: {
                filename: this.configuration.wound_assessment_report_name
            }
        });
        dialogRef.afterClosed().subscribe(async (result) => {
            if (result.filename) {
                let response: any;
                if (this.patient.source === "PointClickCare") {
                    response = await this.woundCareWidgetService
                        .generateReportandUploadtoPCC(
                            this.patient,
                            this.selectedNote._id,
                            result.filename
                        )
                        .toPromise();
                } else if (this.patient.source === "MatrixCare") {
                    const payload = {
                        noteId: this.selectedNote._id
                    };
                    response = await lastValueFrom(
                        this.woundService.generateWoundReportUploadToMatrix(
                            payload
                        )
                    );
                }
                if (response.status === 200) {
                    this.toastr.success(response.message);
                } else {
                    this.toastr.error(response.message);
                }
            }
        });
    }
    getFormattedDate(date) {
        if (!date) {
            return "--";
        }
        let date1: any = date.toString();
        if (date1.includes("Z")) {
            let date2 = date1.split("T");

            let date3 = date2[0].split("-");

            return date3[1] + "/" + date3[2] + "/" + date3[0];
        } else {
            return moment(date)
                .utc()
                .tz(this.patient.timeZone)
                .format("MM/DD/YYYY");
        }
        // return new Date(date).toLocaleString('en-US', {timeZone: this.patient.timeZone});
    }
    async showGroupedWoundReport() {
        this.singleReportSelected = false;
        await this.getAllWoundDataforGroupReport();
    }
    async getAllWoundDataforGroupReport(runWithoutCondtion = false) {
        if (runWithoutCondtion) {
            this.prevousSelectedNote = this.selectedNote;
            let response: any = await this.woundCareWidgetService
                .getAllWoundDataforGroupReport(
                    this.patient,
                    this.selectedNote?._id,
                    "active",
                    this.selectedNote?.date_obj,
                    this.facility_id,
                    this.provider_id
                )
                .toPromise();
            if (response.status === 200) {
                this.allDataforGroupReport = response.data;
            } else {
                this.allDataforGroupReport = response.data;
            }
        } else {
            if (this.prevousSelectedNote._id !== this.selectedNote._id) {
                this.prevousSelectedNote = this.selectedNote;
                let response: any = await this.woundCareWidgetService
                    .getAllWoundDataforGroupReport(
                        this.patient,
                        this.selectedNote?._id,
                        "active",
                        this.selectedNote?.date_obj,
                        this.facility_id,
                        this.provider_id
                    )
                    .toPromise();
                if (response.status === 200) {
                    this.allDataforGroupReport = response.data;
                } else {
                    this.allDataforGroupReport = response.data;
                }
            }
        }
    }
    async initFacilitySettings() {
        let response: any = await this._facilitySettingsService
            .getFacilitySettings(
                this.patient.facility_id_ref._id,
                this.currentUser.company_id
            )
            .toPromise();
        if (response.status === 200) {
            this.configuration = response.data.configuration;
            if (
                !this.configuration.wound_assessment_report_name ||
                this.configuration.wound_assessment_report_name === ""
            ) {
                this.configuration.wound_assessment_report_name =
                    "Wound Assessment Report";
            }
        }
    }
    getNoteLikeObject() {
        if (!this.patient) {
            return {};
        }
        return {
            facility: this.patient.facility_id_ref,
            patient: this.patient
        };
    }
    async changeUpdationDate(event) {
        this.loadWounds = false;
        if (event.startDate && event.endDate) {
            this.updationDate = {
                startDate: null,
                endDate: null
            };
            const noOfDays = this._commonService.getDifferenceOfDates(event.startDate,event.endDate,'days');
            if (noOfDays > 29) {
                this.updationDate.startDate = moment(event.startDate);
                this.updationDate.endDate = moment(this.updationDate.startDate)
                    .add(29, "days")
                    .endOf("day");
                this.toastr.error(
                    "Can not select more than 30 days",
                    "Date Error"
                );
            } else {
                this.updationDate.startDate = event.startDate;
                this.updationDate.endDate = event.endDate;
            }
        }
        if(this.patient){
          await this.getRecentNotes(this.patient._id);
          this.loadWounds = true;
        }
    }
}
