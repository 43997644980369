import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CKEditorService } from 'src/app/includes/note-editor/ckeditor.service';
import { Consent } from '../consent.model';
import { ConsentService } from '../service/consent.service';

@Component({
  selector: 'app-dialog-consent-form',
  templateUrl: './dialog-consent-form.component.html',
  styleUrls: ['./dialog-consent-form.component.css']
})
export class DialogConsentFormComponent implements OnInit, OnDestroy {
  consentForm: Consent = {
    title: "",
    description: "",
  };
  cke4Config: any;
  dialogSubscription: any;
  loaderId = 'app-dialog-consent-form';
  constructor(
    private _ckeditorService: CKEditorService,
    private _toastr: ToastrService,
    private loader: NgxUiLoaderService,
    private _consentService: ConsentService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DialogConsentFormComponent>,
  ) {
    if (this.data) {
      this.consentForm = this.data;
    }
    this.cke4Config = {
      ...this._ckeditorService.getDefaultConfig(),
      editable: true
    };
  }

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    if (this.dialogSubscription) {
      this.dialogSubscription.unsubscribe();
    }
  }
  submitForm() {
    this.loader.startLoader(this.loaderId);
    if (this.data) {
      this.dialogSubscription = this._consentService.updateConsentForm(this.consentForm).subscribe({
        next: (response: any) => {
          if (response.status === 200) {
            this._toastr.success("Consent Form Updated", "Success");
            this.dialogRef.close(response.data);
          }
          else if (response.status === 500) {
            this._toastr.error(response.message, "ERROR");
            this.loader.stopLoader(this.loaderId);
          }
        }, error: error => {
          this._toastr.error(error, "ERROR");
          this.loader.stopLoader(this.loaderId);
        }
      });
      this.loader.stopLoader(this.loaderId);
    }
    else {
      this.dialogSubscription = this._consentService.createConsentForm(this.consentForm).subscribe({
        next: (response: any) => {
          if (response.status === 200) {
            this._toastr.success("Consent Form Created", "Success");
            this.dialogRef.close(response.data);
          }
          else if (response.status === 500) {
            this._toastr.error(response.message, "ERROR");
          }
        }, error: error => {
          this._toastr.error(error, "ERROR");
          this.loader.stopLoader(this.loaderId);
        }
      });
      this.loader.stopLoader(this.loaderId);
    }
  }

}
