import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/Modules/user.service';
import { DashboardService } from '../includes/dashboard/dashboard.service';

@Component({
  selector: 'app-hippa-agreement',
  templateUrl: './hippa-agreement.component.html',
  styleUrls: ['./hippa-agreement.component.css']
})
export class HippaAgreementComponent implements OnInit {
  currentUser: any;
  data :any = {
    hipaa_agreement_accepted:""
  }

  constructor(private dialogRef: MatDialogRef<HippaAgreementComponent>, private authService: AuthService,
    private toastr: ToastrService,
    private dashboardService: DashboardService,
    private userService:UserService,  ) { 
    dialogRef.disableClose = true;
    this.currentUser = this.authService.currentUser;
  }

  ngOnInit(): void {
    this.data.hipaa_agreement_accepted = this.currentUser?.hipaa_agreement_accepted;

  }
 accept(){
   this.dashboardService.updateAgreement(true).subscribe((res:any)=>{
     if(res.status === 200){
       console.log(res)
     }
   })
 }
 reject(){
  this.dashboardService.updateAgreement(false).subscribe((res:any)=>{
    if(res.status === 200){
      console.log(res)
    }
  })
}
}


