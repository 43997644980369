import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FacilityService} from '../../../../services/facility.service';
import {AppointmentDataService} from '../../service/data.service';
import {ToastrService} from 'ngx-toastr';
import {PatientListService} from '../../../../includes/patient-list/patient-list-service';
import {AuthService} from '../../../../services/auth.service';
import {Appointment} from "../../../../classes/appointment";
import {Router} from "@angular/router";
import * as global from '../../../../includes/global';
import {Clipboard} from "@angular/cdk/clipboard";
import {MatSnackBar} from "@angular/material/snack-bar";


@Component({
    selector: 'app-user-appointment-detail',
    templateUrl: './user-detail.template.html',
    styleUrls: ['./user-detail.css']

})
export class UserDetailComponent implements OnInit {

    goBack = false;
    @Input() appointmentId = '';
    @Output() exit = new EventEmitter<boolean>();
    didExit = false;
    notify_type = 'loading...';

    model = new Appointment();
    loaded = false;
    editMode = false;
    meetingLink = '';
    constructor(private facilityService: FacilityService,
                private service: AppointmentDataService,
                private toastr: ToastrService,
                private router: Router,
                private clipboard: Clipboard,
                private snackBar: MatSnackBar,
                private authService: AuthService,
                private patientService: PatientListService) {
    }
    edited() {
        this.getAppointmentById(this.appointmentId);
        this.editMode = false;
    }
    async ngOnInit(){
        this.meetingLink = `${global.base_url}/meeting/quick-call?room_id=${this.appointmentId}`;
        this.getAppointmentById(this.appointmentId);
    }
    copyLink(link) {
        //
        this.clipboard.copy(link);
        this.snackBar.open('Link copied', 'Copy', {
            duration: 2000
        });
    }
    getAppointmentById(id) {
        this.service.getAppointmentById({_id: id}).subscribe( data => {
            this.model = data.data;
            this.processNotifyType();
            this.loaded = true;
        }, error => {
            this.toastr.error('something went wrong', 'Error');
        });
    }
    processNotifyType() {
        if (this.model.notify_type.email === true) {
            this.notify_type = 'Email';
        }
        if (this.model.notify_type.mobile === true) {
            this.notify_type = this.notify_type + ', ' + 'Mobile';
        }
    }
    exitToParent() {
        this.didExit = true;
        this.exit.emit(this.didExit);
    }
   async goToMeeting() {
        window.open(`${global.base_url}/meeting/user-call?room_id=${this.appointmentId}`, '_blank');
    }
    printNotifyTypes(notify_type) {
        return Object.keys(notify_type).filter(key => notify_type[key]).join(', ')
    }

    updatePatientStatus(patient_status) {
        if(this.model.patient_id) {
            // @ts-ignore
            this.model.patient_id.appointment_status = patient_status;
            const payload = {
                ...this.authService.authObject,
                // @ts-ignore
                patient_id: this.model.patient_id._id,
                // @ts-ignore
                ...this.model.patient_id
            }
            this.patientService.updatePatient(payload).subscribe((response) => {
                console.log(response);

                this.toastr.success('Patient status updated successfully')
            })
        }
    }
    deleteAppointment() {
        if(!confirm('Are you sure you want to delete?')) return;
        this.service.updateAppointment({
            _id: this.appointmentId,
            is_active: false
        }).subscribe((response:any) => {
            this.toastr.success('Appointment Deleted Successfully')
            this.exitToParent();
        })
    }
}
