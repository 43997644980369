<table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="nutritionOrderId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Nutrition Order Id
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.nutritionOrderId}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Status
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.status}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="oralDiet">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Oral Diet
        </th>
        <td mat-cell *matCellDef="let element">
            <div *ngIf="element.oralDiet">
                <div>Type: {{element.oralDiet.type}}</div>
                <div *ngIf="element.oralDiet.texture">Texture Modifier: {{element.oralDiet.texture.modifier}}</div>
                <div>Fluid Consistency Type: {{element.oralDiet.fluidConsistencyType}}</div>
                <div>Start Date Time: {{element.oralDiet.startDateTime |date: 'MM/dd/yyyy'}}</div>
                <div>End Date Time: {{element.oralDiet.endDateTime |date: 'MM/dd/yyyy'}}</div>
                <div>Additional Directions: {{element.oralDiet.additionalDirections}}</div>
            </div>
        </td>
    </ng-container>
    
    <ng-container matColumnDef="orderedBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Ordered By
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.orderedBy}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="orderedById">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Ordered By Id
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.orderedById}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="orderDateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Order Date Time
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.orderDateTime |date: 'MM/dd/yyyy'}}
        </td>
    </ng-container>

    <ng-container matColumnDef="patientId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Patient Id
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.patientId}}
        </td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>