import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from 'src/environments/api-url';
import * as global from '../../../includes/global';

@Injectable({
  providedIn: 'root'
})
export class CompanyLogsService {

  currentUser: any;

  constructor(
    private http: HttpClient, 
    private authService: AuthService
  ) {
    this.currentUser = this.authService.currentUser
   }

   getAllErrorLogs() {
    return this.http.post(global.url + API_URL.ERROR_LOGS.getAllErrorLogs, {});
  }
  getSelectedLogs(error_code) {
    
    return this.http.post(global.url + API_URL.ERROR_LOGS.getSelectedLogs, {details:error_code });
  }
}
 