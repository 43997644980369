import {Component, OnInit, ViewChild} from '@angular/core';
import {BillerDashboardService} from '../biller-dashboard/biller-dashboard.service';
import {BillerProviderAssociationService} from '../biller-provider-association/biller-provider-association.service';

import {MatSort, Sort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table"
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import moment from "moment";
import {FormGroup, FormControl} from '@angular/forms';
import {ChargesPopupComponent} from '../biller-dashboard/charges-popUp/charges-popup/charges-popup.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-provider-charge',
    templateUrl: './provider-charge.component.html',
    styleUrls: ['./provider-charge.component.css']
})
export class ProviderChargeComponent implements OnInit {

    displayedColumns = ['visit', 'time', 'patient_name', 'location', 'operation', 'checkBox']
    displayedColumns2 = ['provider']
    // @ViewChild(MatPaginator, { static: true } as any) paginator: MatPaginator;
    // @ViewChild(MatPaginator, { static: true } as any) paginator2: MatPaginator;
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('paginator2') paginator2: MatPaginator;
    // @ViewChild(MatSort, { static: true }) sort: MatSort;
    // @ViewChild('TableOneSort', {static: true}) tableOneSort: MatSort;
    // @ViewChild('TableTwoSort', {static: true}) tableTwoSort: MatSort;
    @ViewChild(MatSort) sort: MatSort;
    // @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    dataSource: any;
    dataSource2: any;
    loading: Boolean = false;
    firstDoctor: any;
    loaderId = 'loader';
    // loaderId1 = 'loader-01';
    currentUser: any;
    // range = new FormGroup({
    //   start: new FormControl(),
    //   end: new FormControl()
    // });
    picker: any;
    filterDropdownActive = false;
    dropdownSettings: any = {};
    closeDropdownSelection = true;
    disabled = false;
    facilities: any = [];
    allFacilities: any;
    selectedFacility: any;
    dataToPass: any;
    selectedProvider: any;
    selectedpatients: any;
    allPatients: any;
    providers: any;
    selectedDOV: any;
    patientNameModel: any = '';
    DOSModel: any = '';
    facilityModel: any = '';
    Modelfacility: any;
    selectedProviderRow: any;
    chargesPopup: any;
    filter: Boolean = false;

    constructor(
        public billerDashboardService: BillerDashboardService,
        public billerProviderAssociationService: BillerProviderAssociationService,
        private _router: Router,
        public loader: NgxUiLoaderService,
        private dialog: MatDialog,
        // public loader1: NgxUiLoaderService,
        private _authService: AuthService) {
        this.currentUser = this._authService.currentUser;

    }

    toggleCloseDropdownSelection() {
        this.closeDropdownSelection = !this.closeDropdownSelection;
        this.dropdownSettings = Object.assign({}, this.dropdownSettings, {closeDropDownOnSelection: this.closeDropdownSelection});
    }

    ngOnInit() {
        this.loading = true
        this.dropdownSettings = {
            singleSelection: true,
            allowSearchFilter: true,
            closeDropDownOnSelection: this.closeDropdownSelection
        };
        this.firstDoctor = this._authService.currentUser._id;
        this.selectedProvider = this.currentUser;
        this.loader.startLoader('loader');
        if (this.firstDoctor) {
            this.billerDashboardService.getPatientsCharges(this.firstDoctor).subscribe((response: any) => {
                if (response.status == 200) {
                  
                    this.selectedpatients = response.data;
                    this.allPatients = response.data;
                    // this.dataSource = response.data;
                    if (response.data.length <= 0) {
                        this.loader.stopLoader('loader')
                    }
                    this.dataSource = new MatTableDataSource(response.data);
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator
                    this.loader.stopLoader('loader')
                }
            })
        }
        this.billerDashboardService.getFacilities(this.firstDoctor).subscribe((response: any) => {
            this.loader.stopLoader('loader');
            if (response.status == 200) {
                this.allFacilities = response.data;
                this.facilities = this.allFacilities.map(facility => {
                    return facility.title;
                })
            }
        });
        this.loading = false;
    }

    changeCheckBox(element, event) {
        let chargesID = element._id;
        element.biller_status = event.checked;
        const updateStatus = this.billerDashboardService.updatePatientCharges(chargesID, event.checked).subscribe((response: any) => {
            // console.log("response: ", response);

        })
    }

    checkBoxValue(element) {
        if (element && element.biller_status && element.biller_status == 'completed') {
            return true;
        } else {
            return false
        }
    }

    isSlected(element) {
        if (element.provider_id == this.selectedProviderRow) {
            return true
        } else {
            return false;
        }
    }

    patientClicked(patient) {
        this.chargesPopup = this.dialog.open(ChargesPopupComponent, {data: patient, width: '80%'})
    }

    //filters
    applyFilterPatientName(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
        this.dataSource.filterPredicate = function (data, filter: string): boolean {
            return (data.patient_id.first_name.toLowerCase().includes(filter) || data.patient_id.last_name.toLowerCase().includes(filter));
        };
    }

    applyFilterFacility(filterValue: string) {
        this.dataSource.filter = filterValue;
        this.dataSource.filterPredicate = function (data, filter: string): boolean {
            return (data.facilityName.title.includes(filter));
        };
    }

    applyFilter2(filterValue: string) {
        this.dataSource2.filter = filterValue.trim().toLowerCase();
        this.dataSource2.filterPredicate = function (data, filter: string): boolean {
            return (data.owner_id.first_name.toLowerCase().includes(filter) || data.owner_id.last_name.toLowerCase().includes(filter));
        };
    }

    //filter for facility
    onItemSelect(event: any) {
        if (event) {
            this.dataSource.filter = event;
            this.dataSource.filterPredicate = function (data, filter: string): boolean {
                return (data.facilityName.title.includes(filter));
            };
        }
    }

    onItemDeSelect(event: any) {
        event = '';
        this.dataSource.filter = event.trim().toLowerCase();
        this.dataSource.filterPredicate = function (data, filter: string): boolean {
            return (data.patient_id.first_name.toLowerCase().includes(filter));
        };
        // this.dataSource = new MatTableDataSource(this.dataSource);
        // this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator
    }

    getVisit(element) {
        let visit = element.owner_id.first_name.slice(0, 1) + element.owner_id.last_name.slice(0, 2);
        if (element.visit_date) {
            let newDate = new Date(element.visit_date).toLocaleDateString();
            visit = visit + ' ' + newDate;
            // visit = visit + '  ' + moment(element.visit_date).format("hh:mm:ss a");
        }
        return visit;
    }

    prividerClicked(element) {
        this.selectedProvider = element.provider_id;
        this.selectedProviderRow = element.provider_id;
        this.loader.startLoader('loader');
        this.getpatientCharges(element.provider_id)
        this.getFacilities(element.provider_id)
    }

    getpatientCharges(provider) {
        this.billerDashboardService.getPatientsCharges(provider).subscribe((response: any) => {
            if (response.status == 200) {
                this.dataSource = [];
                this.selectedpatients = response.data;

                // this.dataSource = response.data;
                this.dataSource = new MatTableDataSource(response.data);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator
                this.loader.stopLoader('loader')
            }
        })
    }

    getProviderName(element) {
        if (element && element.provider_name) {
            return element.provider_name;
        } else {
            return '';
        }
    }

    getPatientName(element) {
        if (element && element.patient_id && element.patient_id.first_name) {
            return element.patient_id.first_name + ' ' + element.patient_id.last_name;
        } else {
            return '';
        }
    }

    getFacilities(provider) {
        this.billerDashboardService.getFacilities(provider).subscribe((response: any) => {
            if (response.status == 200) {
                this.allFacilities = response.data;
                this.facilities = this.allFacilities.map(facility => {
                    return facility.title;
                })
            }
        })
    }

    getFacility(element) {
        if (element && element.facilityName && element.facilityName.title) {
            return element.facilityName.title
        } else {
            return '';
        }
    }

    captureCharges() {
        this._router.navigate(['/provider-charge-capture'], {
            state: {
                provider: this.selectedProvider,
                patients: this.selectedpatients,
                facilities: this.allFacilities
            }
        });
    }

    getTime(element) {
        return (moment(element.visit_date).format("hh:mm:ss a"))
    }

    sortData(sort: Sort) {
        const data = this.selectedpatients.slice();
        this.selectedpatients = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'patient_name':
                    if (a.patient_id && a.patient_id.first_name && b.patient_id && b.patient_id.first_name) {
                        return compare(a.patient_id.first_name, b.patient_id.first_name, isAsc);
                    } else {
                        return;
                    }
                case 'location':
                    if (a.facilityName && a.facilityName.title && b.facilityName && b.facilityName.title) {
                        return compare(a.facilityName.title, b.facilityName.title, isAsc);
                    } else {
                        return;
                    }
                // case 'visit': return compare(a.name, b.name, isAsc);
                case 'visit':
                    if (a.visit_date && b.visit_date) {
                        return compare(new Date(a.visit_date).getTime(), new Date(b.visit_date).getTime(), isAsc);
                    } else {
                        return;
                    }
                case 'time':
                    if (a.visit_date && b.visit_date) {
                        return compare(new Date(a.visit_date).getTime(), new Date(b.visit_date).getTime(), isAsc);
                    } else {
                        return;
                    }

                default:
                    return 0;
            }
        });
        // this.dataSource = this.selectedpatients;
      
        this.dataSource = new MatTableDataSource(this.selectedpatients);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator
    }

    dateChangeDOV(e) {
        let filterData: any = [];
        if (e.value) {
            this.selectedpatients.forEach(async patient => {
                if (new Date(patient.visit_date).toLocaleDateString() == new Date(e.value).toLocaleDateString()) {
                    await filterData.push(patient);
                }
            });
            this.dataSource = new MatTableDataSource(filterData);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator

            // this.selectedDOV = e.value;
            // this.dataSource.filter = new Date(e.value).toLocaleDateString();
            // this.dataSource.filterPredicate = function (data, filter: string): boolean {
            //   return (new Date(data.visit_date).toLocaleDateString().includes(filter));
            // };
        } else {
            this.dataSource = new MatTableDataSource(this.selectedpatients);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator
        }
    }

    filterData() {
        let patientFilterData = [];
        let facilityFilterData = [];
        let dateFilterData = [];
        let finalData = [];
        if (this.patientNameModel != '') {
            this.selectedpatients.forEach(async patient => {
                if (patient.patient_id.first_name.toLowerCase().includes(this.patientNameModel) || patient.patient_id.last_name.toLowerCase().includes(this.patientNameModel)) {
                    await patientFilterData.push(patient);
                }
            });
            finalData = patientFilterData;
        } else {
            finalData = this.selectedpatients;
        }
        if (this.facilityModel != '') {
            finalData.forEach(async patient => {
                if (patient && patient.facilityName && patient.facilityName.title) {
                    if (patient.facilityName.title.toLowerCase().includes(this.facilityModel)) {
                        await facilityFilterData.push(patient);
                    }
                }
            });
            finalData = facilityFilterData;
        } else {
            finalData = finalData;
        }

        if (this.DOSModel != '') {
            finalData.forEach(async patient => {
                if (new Date(patient.visit_date).toLocaleDateString() == this.DOSModel) {
                    await dateFilterData.push(patient);
                }
            });
            finalData = dateFilterData;
        } else {
            finalData = finalData;
        }
        this.selectedpatients = finalData;
        this.filter = true;
        this.dataSource = new MatTableDataSource(finalData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator
    }

    onItemDeSelectNew(event: any) {
        this.facilityModel = '';
    }

    clearfilterData() {
        this.selectedDOV = '';
        this.Modelfacility = '';
        this.filter = false;
        this.selectedpatients = this.allPatients;
        this.dataSource = new MatTableDataSource(this.selectedpatients);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator
    }

    applyFilterPatientNameNew(event: any) {
        if (event) {
            this.patientNameModel = event.trim().toLowerCase()
        } else {
            this.patientNameModel = '';
        }
    }

    onItemSelectNew(event: any) {
        if (event) {
            this.facilityModel = event.trim().toLowerCase()
        } else {
            this.facilityModel = '';
        }
    }

    dateChangeDOVNew(event) {
        if (event.value) {
            this.DOSModel = new Date(event.value).toLocaleDateString()
            if (this.DOSModel == "1/1/1970") {
                this.DOSModel = '';
            }
        } else {
            this.DOSModel = '';
        }
    }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
