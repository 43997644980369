import {Component, OnInit, Inject} from "@angular/core";
import {FormControl} from "@angular/forms";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {Observable} from "rxjs";
import {CommonService} from "../services/common.service";
import {PCCService} from "../services/pcc.service";
import {UserService} from "../services/Modules/user.service";
import {FacilityService} from "../services/facility.service";
import {AuthService} from "../services/auth.service";
import moment from "moment";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  tap,
} from "rxjs/operators";
import * as global from "src/app/includes/global";
import {RRule} from "rrule";
import { CompanyService } from "../services/Modules/company.service";
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-add-block-appointment-dialog",
  templateUrl: "./add-block-appointment-dialog.component.html",
  styleUrls: ["./add-block-appointment-dialog.component.css"],
})
export class AddBlockAppointmentDialogComponent implements OnInit {
  blockData: any = {}; // Object to hold form data
  provideModel: any = "";
  appointmentBtn: any = "Add";
  providerControl = new FormControl();
  facilityControl = new FormControl();
  patientsByProviderControl = new FormControl();
  filteredProvider: Observable<string[]>;
  filteredFacilities: Observable<string[]>;
  currentUser: any;
  users: Array<any> = [];
  resourceFilterValue: string = "";
  uniqueProviders: any = [];
  locationSearchLoaderId = "location-loader";
  addressSubscription: any;
  facilities = [];
  reasons = [];
  repeatings = [
    {value: "Weekly"},
    {value: "Biweekly"},
    {value: "Monthly by Day"},
    {value: "Monthly by Date"},
  ];
  durationOptions = [
    { label: '15 minutes', value: 15 },
    { label: '30 minutes', value: 30 },
    { label: '45 minutes', value: 45 },
    { label: '1 hour', value: 60 },
    { label: '1 hour 15 minutes', value: 75 },
    { label: '1 hour 30 minutes', value: 90 },
    { label: '1 hour 45 minutes', value: 105 },
    { label: '2 hours', value: 120 },
    { label: 'Custom', value: 'custom' }
  ];
  daysOfMonth: number[] = Array.from({length: 31}, (_, i) => i + 1);
  weekdays: string[] = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  weeksOfMonth: string[] = ["1st", "2nd", "3rd", "4th", "Last"];
  selectedDays: number[] = [];
  selectedWeekdays: string[] = [];
  selectedWeeks: string[] = [];
  searchTextProvider:any;
  selectedCategory: "days" | "weekdays" | "weeks" = "days";
  isDisabledProvider:boolean=false;
	isDisabledFacility:boolean=false;
  isDisabledStartDate:boolean=false;
  isDisabledEndDate:boolean=false;
  isDisabledReason:boolean=false;
  isDisabledTime:boolean=false;
  company_id:any = '';
  constructor(
    public dialogRef: MatDialogRef<AddBlockAppointmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private loader: NgxUiLoaderService,
    public commonService: CommonService,
    private pccService: PCCService,
    private userService: UserService,
    private facilityService: FacilityService,
    private companyService: CompanyService,
    public route: ActivatedRoute,
      ) {
    this.currentUser = this.authService.currentUser;
    const currentCompany = this.commonService.getLocalStorageItem('admin-Dashboard');
      if(currentCompany) {
        this.company_id = currentCompany.currentCompanyId;
      }else {
        this.company_id = this.route.snapshot.firstChild.params.id;
      }
  }

  ngOnInit(): void {
    this.getSettings()
    this.getResources();
    this.initFilteredProvider();

    if (this.data) {
      this.initializeFormData();
      this.getFacilities();
    } else {
      this.blockData.start_time = moment().format(" HH:mm");
      this.blockData.end_time = moment().format(" HH:mm");
      this.blockData.start_date = moment().format("YYYY-MM-DD");
      this.blockData.end_date = moment().format("YYYY-MM-DD");
    }
  }
  private initializeFormData() {
    this.isDisabledProvider  = true;
    this.isDisabledFacility =  this.data.facility_id ? true : false;
    this.isDisabledStartDate = true;
    this.isDisabledTime = true;
    this.blockData.title = this.data.title || '';
    this.appointmentBtn = this.data.appointment_id ? "Update" : "Add";
    this.blockData.type = this.data.appointment_id ? "update" : "add";
    this.blockData.start_time =
      this.data.startTime ?? moment(this.data.start).format(" HH:mm");
    this.blockData.start_date = this.data.start_date_obj
      ? moment({
          year: this.data.start_date_obj.year,
          month: this.data.start_date_obj.month - 1, // Adjust month to 0-indexed
          day: this.data.start_date_obj.day,
        }).format("YYYY-MM-DD")
      : moment(this.data.start).format("YYYY-MM-DD");
    this.blockData.end_time =
      this.data.endTime ?? moment(this.data.end).format(" HH:mm");
    this.blockData.end_date = this.data.end_date_obj
      ? moment({
          year: this.data.end_date_obj.year,
          month: this.data.end_date_obj.month - 1, // Adjust month to 0-indexed
          day: this.data.end_date_obj.day,
        }).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");
    this.blockData.duration = this.data.duration === 'custom' ? this.data.duration : Number(this.data.duration );
    this.blockData.customDuration = this.data.customDuration || '',
    this.blockData.providerType = this.data.provider_id ?? this.data.providerId;
    this.blockData.facilityType = this.data.facility_id;
    this.selectedDays = this.data.selectedDays;
    this.selectedWeeks = this.data.selectedWeeks;
    this.selectedWeekdays = this.data.selectedWeekdays;
    this.provideModel = this.data.providerName || "";
    this.blockData.reason = this.data.reasons || "";
    this.blockData.repeating = this.data.repeating;
    this.blockData._id = this.data.appointment_id;
    this.blockData.type = "update";
  }
  getSettings(){
    this.companyService.getCompanyAppointmentTypes(this.company_id).subscribe((response: any) => {
        this.reasons = response.data;
    })
  }
  getResources() {
    const filter = {
      is_activated: "true",
      company_id:  this.company_id,
      user_type: global.USER_TYPE.DOCTOR,
    };
    const projection = {
      first_name: 1,
      last_name: 1,
      title: 1,
      full_name: 1,
      company_id: 1,
    };
    this.userService.getUsers(filter, projection).subscribe((response: any) => {
      if (response.status == 200) {
        this.users = response.data.map((u) => {
          u.name = `${u.first_name} ${u.last_name}`;
          if (u.user_type == "1") {
            u.name += `, ${u.title}`;
          }
          return u;
        });

        const map = new Map();
        for (const item of this.users) {
          if (!map.has(item._id) && item.first_name) {
            map.set(item._id, true); // set any value to Map
            this.uniqueProviders.push(item);
          }
        }
        this.uniqueProviders = this.sortFirstNameVise(this.uniqueProviders);
      }
    });
  }
  private initFilteredProvider() {
		this.filteredProvider = this.providerControl.valueChanges.pipe(
			startWith(''),
			map(value => this.data.providerName ? this.filterProvider(this.data.providerName) : this.filterProvider(value))
		);
	}
  private filterProvider(value: any): string[] {
		if (value) {
			const filterValue = value.toLowerCase();
			let users = this.uniqueProviders.filter(option => option.name.toLowerCase().indexOf(filterValue) > -1);

      return this.sortFirstNameVise(users);
    } else {
      return this.sortFirstNameVise(this.uniqueProviders);
    }
  }
  clearProviderFilter() {
    this.provideModel = "";
    this.blockData.providerType = "";
    this.blockData.facilityType = "";
    this.filteredProvider = this.providerControl.valueChanges.pipe(
      startWith(""),
      map((value: any) => this.filterProvider(value)),
    );
  }
  onSelectProvider() {
      this.loader.startLoader(this.locationSearchLoaderId);
      this.getFacilities();
  }
  getFacilities() {
    this.addressSubscription = this.facilityService
      .getMyFacilities(null, this.blockData.providerType,false ,this.company_id)
      .subscribe((response: any) => {
        if (response.status == 200) {
          this.facilities = response.data.array;
          this.facilities.sort((a, b) =>
            a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
          );
          this.loader.stopLoader(this.locationSearchLoaderId);
        } else {
          this.loader.stopLoader(this.locationSearchLoaderId);
        }
      });
  }
  sortFirstNameVise(arrayTosort) {
    let users = arrayTosort;
    let result = users.sort(function (a, b) {
      if (a.first_name?.toLowerCase() < b.first_name?.toLowerCase()) {
        return -1;
      }
      if (a.first_name?.toLowerCase() > b.first_name?.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    return result;
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  submitForm() {
    this.blockData.selectedDays = this.selectedDays;
    this.blockData.selectedWeekdays = this.selectedWeekdays;
    this.blockData.selectedWeeks = this.selectedWeeks;
    const {
			providerType,
			facilityType,
			repeating,
			reason,
      duration,
      start_date,
      end_date,
      customDuration,
      start_time
    } = this.blockData;
    if(providerType && facilityType  && reason && ((duration !== 'custom' && !customDuration) || (duration === 'custom' && customDuration))  && start_date && end_date) {
      this.blockData.end_time = this.addDurationToTime(start_time, duration === 'custom' ? customDuration :duration);
      this.blockData.isUpdateAppointmentType = this.isUpdateAppointmentType(reason, duration === 'custom' ? customDuration :duration)
      if(!this.blockData.title){
        this.onSelectAppointmentType()
      }
      if(!repeating){
        this.blockData.end_date = start_date
      }
      this.dialogRef.close(this.blockData);
    }
  }
  isUpdateAppointmentType(id,duration){
    const selectedAppointment = this.reasons.find(appointment=>appointment._id === id)
    return selectedAppointment.duration === duration ? false : true;

  }
  resetCustomDuration() {
    if (this.blockData.duration !== 'custom') {
      this.blockData.customDuration = '';
    }
  }
  
  generateOccurrences({startMonth, endMonth, weekdays, weekNumbers}) {
    const occurrences = [];

    for (let month = startMonth; month <= endMonth; month++) {
      for (const weekday of weekdays) {
        for (const weekNumber of weekNumbers) {
          const rule = new RRule({
            freq: RRule.MONTHLY,
            dtstart: new Date(Date.UTC(2023, month - 1, 1, 12, 13, 0)), // Adjust month to 0-indexed
            until: new Date(Date.UTC(2023, month, 0, 23, 59, 59)), // Adjust month to 0-indexed
            wkst: RRule.TU,
            byweekday: [weekday],
            bysetpos: [weekNumber],
          });

          occurrences.push(...rule.all());
        }
      }
    }

    return occurrences;
  }

  setDOS(dateEvent: any, field: "start_date" | "end_date"): void {
    this.blockData[field] = moment(dateEvent.value);
  }
  onchangeTime(event: {hour: string; min: string}, type: string) {
    const {hour, min} = event;

    const startDate = moment(this.blockData.start_date, "YYYY-MM-DD");
    const endDate = moment(this.blockData.end_date, "YYYY-MM-DD");

    if (type === "start") {
      const momentStartDateTime = startDate
        .hour(Number(hour))
        .minute(Number(min));
      this.blockData.start_date = momentStartDateTime.format("YYYY-MM-DD");
      this.blockData.start_time = momentStartDateTime.format(" HH:mm");
    } else if (type === "end") {
      const momentEndDateTime = endDate.hour(Number(hour)).minute(Number(min));
      this.blockData.end_date = momentEndDateTime.format("YYYY-MM-DD");
      this.blockData.end_time = momentEndDateTime.format(" HH:mm");
    }

    // this.formData.time = momentStartDateTime.format(' HH:mm');
  }
  toggleSelection(
    item: number | string,
    category: "days" | "weekdays" | "weeks",
  ): void {
    if (category === "weekdays" && typeof item === "string") {
      // Convert weekday string to its index
      item = this.getWeekdayIndex(item);
    } else if (category === "weeks" && typeof item === "string") {
      // Convert week of month string to its index
      item = this.getWeekOfMonthIndex(item);
    }
    // }
    const categoryArray = this.getCategoryArray(category);

    const index = categoryArray.indexOf(item);
    if (index === -1) {
      categoryArray.push(item);
    } else {
      categoryArray.splice(index, 1);
    }
  }

  isSelected(
    item: number | string,
    category: "days" | "weekdays" | "weeks",
  ): boolean {
    const categoryArray = this.getCategoryArray(category);

    // Add logic to handle special cases based on the category
    if (category === "weekdays" && typeof item === "string") {
      // Convert weekday string to its index
      item = this.getWeekdayIndex(item);
    } else if (category === "weeks" && typeof item === "string") {
      // Convert week of month string to its index
      item = this.getWeekOfMonthIndex(item);
    } else if (category === "weekdays" && typeof item === "number") {
      item = this.isValueAtIndexMatch(item);
    }
    return categoryArray.includes(item);
  }

  private getCategoryArray(
    category: "days" | "weekdays" | "weeks",
  ): (number | string)[] {
    switch (category) {
      case "days":
        return this.selectedDays;
      case "weekdays":
        return this.selectedWeekdays;
      case "weeks":
        return this.selectedWeeks;
      default:
        return [];
    }
  }
  private resetArrays(): void {
    this.selectedDays = [];
    this.selectedWeekdays = [];
    this.selectedWeeks = [];
  }
  getWeekdayIndex(weekday: string): number {
    return this.weekdays.indexOf(weekday);
  }

  getWeekOfMonthIndex(weekOfMonth: string): number {
    const index = this.weeksOfMonth.indexOf(weekOfMonth) + 1; // Adding 1 to make it 1-indexed
    return index == 5 ? -1 : index;
  }
  isValueAtIndexMatch(targetIndex: number): string {
    // Check if the array has the target index and if the value at that index matches the provided value
    return this.weekdays[targetIndex];
  }
  addDurationToTime(startTime: string, duration: number): string {
    const [hours, minutes] = startTime.split(':').map(Number);
  
    // Convert both start time and duration to minutes
    const totalMinutes = hours * 60 + minutes + duration;
  
    // Calculate the new hours and minutes
    const newHours = Math.floor(totalMinutes / 60);
    const newMinutes = totalMinutes % 60;
  
    // Format the result as 'HH:mm'
    const formattedTime = ` ${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;
  
    return formattedTime;
  }
  onSelectAppointmentType(){
		const selectedAppointment = this.reasons.find(appointment=>appointment._id === this.blockData.reason)
    const selectDuration = this.durationOptions.find(duration => duration.value === selectedAppointment.duration)
    if(selectDuration){
      this.blockData.duration = selectedAppointment.duration
      this.blockData.title = selectedAppointment.name
      this.blockData.customDuration = ''
    }else{
      this.blockData.duration = 'custom'
      this.blockData.title = selectedAppointment.name
      this.blockData.customDuration = selectedAppointment.duration
    }
	}  
}
