import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../dashboard/dashboard.service';
import { ProfileService } from './profile.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Location } from '@angular/common';
import { Utils } from './utils';
import { FormControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import * as global from '../global';
import { GoogleAuthenticatorComponent } from './google-authenticator/google-authenticator.component';
import { MatDialog } from '@angular/material/dialog';
import TextSignature from 'text-signature';

import { GoogleAuthenticator2FAService } from 'src/app/services/googleAuthenticator2FA.service';
import { lastValueFrom } from 'rxjs';
import { UserService } from 'src/app/services/Modules/user.service';

declare var Dropbox: any;



@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

    // CLIENT_ID: string = '9467hnxspq27vrw';// App key from Dropbox;
    // FILE_NAME: string = "/BookList.txt"; // Or whatever you want the file to be named where the data is stored
    // authUrl: string;
    // dropboxToken: string
    // isAuthenticated: boolean
    // static isLoading: boolean;
    global = global;
    currentUser: any = null;
    facilities: any[] = [];
    screen = 0;
    phone = '';
    phoneCleaned = '';
    verificationCode = '';
    email_code: any;
    twoFactor = false;
    titles = ["M.D.", "D.O.", "PA-C", "ARNP", "R.N.", "N.P.", "P.A.", "L.Ac", "DNP", "CWS", "CWSP", "WOCN", "ANP-BC", "CWCN"];
    data: any = {
        fontColor : "Black",
        fontSize:  "16",
        image_height: "",
        image_width: "",
        image_padding_x:"",
        image_padding_y:"",
        mendatory:false
        }
    fontSizes = [ '12','14', '16', '18', '20', '22', '24', '26']
    constructor(
        private _dashboardService: DashboardService,
        private _profileService: ProfileService,
        private _toastr: ToastrService,
        private _router: Router,
        private _authService: AuthService,
        private _location: Location,
        private dialog: MatDialog,
        private commonService: CommonService,
        private userService: UserService,
        private _google2FA: GoogleAuthenticator2FAService) {
        // this.isAuthenticated = false;
        // this.newBook = new BookEntity;
        // AppComponent.books = [];
        // ProfileComponent.isLoading = false;

        // var dbx = new  Dropbox.Dropbox({ clientId: this.CLIENT_ID });
        // console.log("dropbox",dbx);
        // this.authUrl = dbx.auth.getAuthenticationUrl('http://localhost:4200/feedback'); 
        // console.log("url :",this.authUrl);
        // this.dropboxToken = Utils.getAccessTokenFromUrl();
        // console.log("dropbox token : ",this.dropboxToken);
        // this.isAuthenticated = this.dropboxToken !== undefined;
        this.currentUser = this._authService.currentUser
    }


    ngOnInit() {
        // initialize profile fields
        this.getTwoFactorSettingAllow()
        this.initProfileFields();

    }

    getTwoFactorSettingAllow() {
        let obj = {
            id: this.currentUser._id,
            usertype: this.currentUser.user_type,
            auth_key: this.currentUser.auth_key
        }
        this._profileService.getTwoFactorSettingAllow(obj).subscribe((res: any) => {
            if (res.status == 200) {
                // console.log("+++++++++++++: ", res);
                this.twoFactor = res.data.allow_two_factor_change_by_admin;
            }
            else {
                console.log("error");

            }
        })
    }

    swapScreen(value) {
        this.screen = value;
    }

    initProfileFields() {
        // const currentUser = localStorage.getItem('currentUser');
        this.currentUser = this._authService.currentUser;
        if (!this.currentUser) {
            // if not login, stop and redirect to login #mohsin-dev

            localStorage.removeItem('currentUser');
            this._router.navigate(['/login']);
            return;
        }


        // this._dashboardService.getFacilities(this.currentUser._id).subscribe((response:any) => {
        //     if(response.status === 200) {
        //         this.facilities = response.data.array;
        //     }
        // })
    }

    getNotePreview() {
        if (this.currentUser?.other_settings) {
            return this.currentUser.other_settings.note_sign_confirmation
        }
        else {
            return false
        }
    }
    getUploadPreview() {
        if (this.currentUser?.other_settings) {
            return this.currentUser.other_settings.note_upload_confirmation
        }
        else {
            return false
        }
    }
    changeSignPreviewStatus(value) {
        let oldOtherSettings = this.currentUser.other_settings || {};
        if (oldOtherSettings && oldOtherSettings != null) {
            this.currentUser.other_settings.note_sign_confirmation = value;
        }
        else {
            let newOtherSettings = {
                other_settings: {
                    note_sign_confirmation: value
                }
            }
            oldOtherSettings = Object.assign(oldOtherSettings, newOtherSettings);
        }
        this._profileService.updateOtherSettings(oldOtherSettings).subscribe((response: any) => {
            if (response.status == 200) {
            }
        })
    }
    changeUploadPreviewStatus(value) {
        let oldOtherSettings = this.currentUser.other_settings || {};
        if (oldOtherSettings && oldOtherSettings != null) {
            this.currentUser.other_settings.note_upload_confirmation = value;
        }
        else {
            let newOtherSettings = {
                other_settings: {
                    note_upload_confirmation: value
                }
            }
            oldOtherSettings = Object.assign(oldOtherSettings, newOtherSettings);
        }
        this._profileService.updateOtherSettings(oldOtherSettings).subscribe((response: any) => {
            if (response.status == 200) {
            }
        })
    }
    updateProfile(profileForm) {
        if (!profileForm.valid) {
            return;
        }

        const item = {
            email: <string>null,
            scriptsure_email:<string>null,
            usertype: <string>null,
            auth_key: <string>null,
            id: <string>null,
            title: <string>null,
            firstname: <string>null,
            lastname: <string>null,
            pcc_name:<string>null,
            mobile: <string>null,
            profile_pic: <string>null,
            email_notification: <string>null,
            push_notification: <string>null,
            email_auth_notification: <string>null,
            sms_auth_notification: <string>null,
            two_factor_auth: <boolean>null,
            note_sign_confirmation: <boolean>null,
            note_upload_confirmation: <boolean>null,
        };


        item.email = this.currentUser.email;
        item.scriptsure_email = this.currentUser.scriptsure_email;
        item.auth_key = this.currentUser.auth_key;
        item.usertype = this.currentUser.user_type;
        item.id = this.currentUser._id;
        item.title = this.currentUser.title;
        item.firstname = this.currentUser.first_name;
        item.lastname = this.currentUser.last_name;
        item.pcc_name = this.currentUser.pcc_name;
        item.email = this.currentUser.email;
        item.mobile = this.currentUser.mobile;
        item.profile_pic = this.currentUser.profile_pic;
        item.email_notification = this.currentUser.email_notification;
        item.push_notification = this.currentUser.push_notification;
        item.email_auth_notification = this.currentUser.email_auth_notification;
        item.sms_auth_notification = this.currentUser.sms_auth_notification;
        item.two_factor_auth = this.currentUser.two_factor_auth;
        item.note_sign_confirmation = this.currentUser.other_settings?.note_sign_confirmation ? this.currentUser.other_settings.note_sign_confirmation : true;
        item.note_upload_confirmation = this.currentUser.other_settings?.note_upload_confirmation ? this.currentUser.other_settings.note_upload_confirmation : true;

        const infoToast = this._toastr.info('Updating profile...', 'Please wait', { disableTimeOut: true });
        this._profileService.updateProfile(item).subscribe((response: any) => {
            if (response.status === 200) {
                this.commonService.setUpdatedUsername({ title: this.currentUser.title, first_name: this.currentUser.first_name, last_name: this.currentUser.last_name,scriptsure_email:this.currentUser.scriptsure_email,pcc_name:this.currentUser.pcc_name, profile_pic: this.currentUser.profile_pic });
                // update localStorage
                this.createSignature(response.data)
                let currentUser = JSON.parse(localStorage.getItem("currentUser"));
                let currentUserAuth = JSON.parse(localStorage.getItem("currentUserAuth"));
                // response.data.auth_key = response.data.auth_key + '.WEB';
                if (currentUser) {
                    currentUser.title = response.data.title;
                    currentUser.first_name = response.data.first_name;
                    currentUser.last_name = response.data.last_name;
                    currentUser.pcc_name = response.data.pcc_name;
                    currentUser.profile_pic = response.data.profile_pic;
                    localStorage.setItem("currentUser", JSON.stringify(currentUser));
                    currentUser.scriptsure_email = response.data.scriptsure_email;
                }
                if (currentUserAuth) {
                    currentUserAuth.title = response.data.title;
                    currentUserAuth.first_name = response.data.first_name;
                    currentUserAuth.last_name = response.data.last_name;
                    currentUserAuth.pcc_name = response.data.pcc_name;
                    currentUserAuth.profile_pic = response.data.profile_pic;
                    currentUser.scriptsure_email = response.data.scriptsure_email;
                    localStorage.setItem("currentUserAuth", JSON.stringify(currentUserAuth));
                }
                this._toastr.success(response.message, 'Success');
            } else {
                this._toastr.error(response.message, 'Failed');
            }
        }, (error) => {
            this._toastr.error('Something went wrong, pelase try again', 'Failed');
        }, () => this._toastr.clear(infoToast.toastId));

    }

    updateUser(user) { 
        const data = Object();
        data.auth_key = this.currentUser.auth_key;
        data.usertype = this.currentUser.user_type;
        data.id = this.currentUser._id;
        data.mobile = user.mobile;
        data.email = this.currentUser.email;
        data.title = this.currentUser.title;
        data.firstname = this.currentUser.first_name;
        data.lastname = this.currentUser.last_name;
        data.pcc_name = this.currentUser.pcc_name;
        data.profile_pic = this.currentUser.profile_pic;
        data.scriptsure_email = this.currentUser.scriptsure_email

        data.email_auth_notification = this.currentUser.email_auth_notification;
        data.sms_auth_notification = this.currentUser.sms_auth_notification;
        this._toastr.info('Profile update is in progress', 'Updating...', {
            timeOut: 0
        });
        this._profileService.updateProfile(data).subscribe(
            async (res) => {
                if (res.status === 200) {

                    this._toastr.success('Profile updated successfully',
                        'Success', {
                        timeOut: 3000
                    });
                    res.data.auth_key = res.data.auth_key + '.WEB';
                    localStorage.setItem('currentUser', JSON.stringify(res.data));
                    this.currentUser = this._authService.currentUser = res.data;
                    this.initProfileFields();
                    this.screen = 0;
                } else {
                    this._toastr.error('Something went wrong in updating profile',
                        'Error', {
                        timeOut: 5000
                    });
                }
            },
            (error) => {
                this._toastr.error('Something went wrong in updating profile',
                    'Error', {
                    timeOut: 5000
                });
            }
        );
    }

    sendVerificationCode(obj) {

        this._toastr.info('SMS sending is in progress', 'Sending...', {
            timeOut: 0
        });
        const data = Object();
        data.auth_key = this.currentUser.auth_key;
        data.usertype = this.currentUser.user_type;
        data.id = this.currentUser._id;
        data.mobile = obj.mobile;
        this._profileService.sendMobileVerificationCode(data).subscribe(
            response => {

                if (response.status === 200) {
                    this._toastr.success('Verification SMS sent successfully',
                        'Sent', {
                        timeOut: 3000
                    });
                    this.screen = 2;
                } else {
                    this._toastr.error('You are trying to add invalid number',
                        'SMS not Sent', {
                        timeOut: 5000
                    });

                }
            },
            error => {
                this._toastr.error('You are trying to add invalid number',
                    'SMS not Sent', {
                    timeOut: 5000
                });
            }
        );
    }

    checkVerificationCode(obj) {

        this._toastr.info('Code Verification is in progress', 'Verifying...', {
            timeOut: 0
        });
        const data = Object();
        data.auth_key = this.currentUser.auth_key;
        data.usertype = this.currentUser.user_type;
        data.id = this.currentUser._id;
        data.verification_code = obj.code;
        this._profileService.checkVerificationCode(data).subscribe(
            (response: any) => {

                if (response.status === 200) {
                    this._toastr.success('Code Verified successfully',
                        'Verified', {
                        timeOut: 3000
                    });
                    this.updateUser({ mobile: this.phoneCleaned });
                } else {
                    this._toastr.error('Code not verified',
                        'Error', {
                        timeOut: 5000
                    });
                }
            },
            (error) => {

                this._toastr.error('Code not verified',
                    'Error', {
                    timeOut: 5000
                });
            }
        );
    }

    handlePhoneModelChange(value) {
        const cleaned = value.replace(/-/g, '');
        if (cleaned.length < 9) {
            this.phone = cleaned.match(/.{1,3}/g).join('-');
        }
        this.phoneCleaned = cleaned;
    }

    toggleTwoFactorAuth() {
        this.currentUser.two_factor_auth = !(!!this.currentUser.two_factor_auth);
    }
    goBack() {
        this._location.back();
    }

    verifyEmail($event:any){
        $event.preventDefault();
        let email_code = Math.floor(100000 + Math.random() * 900000);
        let details = {
        email: this.currentUser.email,
        verification_code: email_code
        }
        const infoToast = this._toastr.info('Sending verification code...', 'Please wait', { disableTimeOut: true });
        this._profileService.sendEmailVerificationCode(details).subscribe(
            response => {

                if (response.status === 200) {
                    this._toastr.success('Verification Code sent successfully',
                        'Sent', {
                        timeOut: 3000
                    });
                    this._toastr.clear(infoToast.toastId);
                    this.screen = 3;
                } else {
                    this._toastr.clear(infoToast.toastId);
                    this._toastr.error('Something went Wrong',
                        'Code not Sent', {
                        timeOut: 5000
                    });
                }
            },
            error => {
                this._toastr.clear(infoToast.toastId);
                this._toastr.error('Something went Wrong',
                    'Code not Sent', {
                    timeOut: 5000
                });
            }
        );

    }
    resetPasswordAllowed() {
        if (this.currentUser.portal_type === this.global.PORTAL_TYPE.NON_WC && this.currentUser.cognito === true){
            return false;
        }else{
            if(this.currentUser.cognito == false){
                return false;
            }else if(this.currentUser.cognito == true && this.currentUser.enableCognito == true){
                return false;
            }else{
                return true;
            }
        }
       
    }
    checkEmailVerificationCode(obj) {
        console.log(obj.code);
    }

    onSelectFile(event) {
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = (event) => {
                this.currentUser.profile_pic = event.target.result;
            }
        }
    }
    async enableGoogle2FA(){
        const response: any = await lastValueFrom(this._google2FA.enableGoogle2Fa())
        if(response.status === 200){
            const dialogRef = this.dialog.open(GoogleAuthenticatorComponent, {
                disableClose: true,
                data: {
                    dataURL: response.data.dataURL
                }
              });
              dialogRef.afterClosed().subscribe(async (data) => {
                if (data.google_auth_enabled !== null && data.google_auth_enabled !== undefined) {
                    this.currentUser.google_auth_enabled = data.google_auth_enabled
                    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
                    let currentUserAuth = JSON.parse(localStorage.getItem("currentUserAuth"));
                    if(currentUser){
                        currentUser.google_auth_enabled = data.google_auth_enabled;
                        localStorage.setItem("currentUser", JSON.stringify(currentUser));
                    }
                    if(currentUserAuth){
                        currentUserAuth.google_auth_enabled = data.google_auth_enabled;
                        localStorage.setItem("currentUserAuth", JSON.stringify(currentUserAuth));
                    }
                }
            });
        }
    }
    async disableGoogle2FA(){
        const response: any = await lastValueFrom(this._google2FA.disableGoogle2FA());
        if(response.status === 200){
            this.currentUser.google_auth_enabled = false;
            let currentUser = JSON.parse(localStorage.getItem("currentUser"));
            let currentUserAuth = JSON.parse(localStorage.getItem("currentUserAuth"));
            if(currentUser){
                currentUser.google_auth_enabled = false;
                localStorage.setItem("currentUser", JSON.stringify(currentUser));
            }
            if(currentUserAuth){
                currentUserAuth.google_auth_enabled = false;
                localStorage.setItem("currentUserAuth", JSON.stringify(currentUserAuth));
            }
            this._toastr.success(response.message, 'Success');
        }
    }
    checkEnvironment() {
        if (global.ENVIRONMENT == 'LOCAL' || global.ENVIRONMENT == 'WOUNDCARE_DEV') {
          return true;
        }
        else {
          return false;
        }
      }
      async createSignature(data:any){
        let optionsParameter = {
            width: this.calculateWidth(data),
            height: this.calculateHeight(),
            paddingX: "3",
            paddingY: this.data.fontSize,
            canvasTargetDom: "#can",
            font: [this.data.fontSize+"px", "'Homemade Apple'"],   
            color: "black",
            textString: "",
            customFont: {
              name: "'Homemade Apple'",
              url: "https://fonts.googleapis.com/css?family=Homemade+Apple"
            }
          };
          optionsParameter.textString=`${data.first_name} ${data.last_name}, ${data.title}`
          let textSignature = new TextSignature(optionsParameter);
          let can = textSignature.generateImage(optionsParameter);
          let imgData = textSignature.getImageData();
          console.log("imageData",imgData)
          let body ={
              id: data._id,
              auth_key: this._authService.currentUser.auth_key,
              fontColor:'Black',
              fontSize: '16px',
              image_height:'75px',
              mendatory:true,
              image:imgData, 
            }
        let response : any = await this.userService.saveSignatureSettings(body).toPromise()
    }
    calculateWidth(data:any){
        let index =  this.fontSizes.indexOf(this.data.fontSize+"");
        var myString = this.data.first_name+""+this.data.last_name+""+this.data.title+"";
        var stringLength = myString.length;
        let width  = 50+((index + 1)*14)+(stringLength*12)
        return width 
     }
 
     calculateHeight(){
         let index =  this.fontSizes.indexOf(this.data.fontSize+"");
         let height  = 18+((index * 1.3))
         return height
     } 
}
