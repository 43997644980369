import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as global from '../global';
import { API_URL } from "../../../environments/api-url";
import { AuthService } from 'src/app/services/auth.service';

// implemented #mohsin-dev

@Injectable({
    providedIn: 'root',
})
export class RegisterService {
    currentUser: any;

    public constructor(private httpclient: HttpClient,
        private _authService: AuthService

    ) {
        this.currentUser = this._authService.currentUser;

    }

    sendVerificationCode(details) {
        return this.httpclient
            .post(global.url + API_URL.AUTH.sendVerificationCode, { details: details }).pipe();
    }

    checkVerificationCode(details) {
        return this.httpclient
            .post(global.url + API_URL.AUTH.checkVerificationCode, { details: details }).pipe();
    }
    doRegister(details) {
        let item: any = { ...details };
        item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type, item.company_id = this.currentUser.company_id;

        return this.httpclient
            .post(global.url + API_URL.AUTH.register, { details: item }).pipe();
    }

}
