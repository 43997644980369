import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminDictationsService } from './admin-dictations.service';
import moment from 'moment'
import * as global from '../global';
import { TranscriptionCreateService } from '../transcription-create/transcription-create.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { CommonService } from 'src/app/services/common.service';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { KeyValue, DatePipe } from '@angular/common';
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import {NgxUiLoaderService} from "ngx-ui-loader";
import {start} from "repl";
import { ActivatedRoute, Router } from '@angular/router';

interface IFilter {
    active: boolean,
    date_of_submission: {
        startDate: any | Date | moment.Moment,
        endDate: any | Date | moment.Moment,
    },
    date_of_service: {
        startDate: any | Date | moment.Moment,
        endDate: any | Date | moment.Moment
    },
    provider: string, // _id
    note_type: string,
    facility: string,
    progress_status: string, // progress id number
}

@Component({
    selector: 'app-admin-dictations',
    templateUrl: './admin-dictations.component.html',
    styleUrls: ['./admin-dictations.component.css'],
    providers: [DatePipe]
})
export class AdminDictationsComponent implements OnInit {
    filteredFacilities: Observable<string[]>;
    facilityControl = new FormControl();
    filteredProvider: Observable<string[]>;
    providerControl = new FormControl();
    global = global;
    company_id: any;
    public isVisibleTable: boolean = true;
    public dataSource: any;
    public sortedData: Array<any>;
    public dictations: Array<any>;
    public dictationsGroup;
    public c_date= moment(new Date());
    public submissionDateFilter= new Boolean(true);
    public serviceDateFilter= new Boolean(true);
    displayedColumns: string[] = ['audio_title', 'uploader_name','patient_name', 'date_time', 'facility_title', 'progress_status', 'audio_length', 'notes_count','operations'];
    @ViewChild(MatSort) sort: MatSort;

    provideModel:any = '';
    facilityMOdel: any = "";

    filter: IFilter;
    appliedFilter: IFilter;
    providers: Array<any> = [];
    note_types: Array<any> = [];
    facilities: Array<any> = [];
    status = [
        { title: 'Pending', value: '18' },
        { title: 'In Progress', value: '19' },
        { title: 'Completed', value: '20' },
        //  {title: 'Draft', value: '21'},
        //  {title: 'Trashed', value: '23'},
    ];


    preDateRanges: any = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };

    currentDate = new Date();

    downloadProgress = {
        show: false,
        progress: 0,
        mode: 'indeterminate',
        id: ''
    };
    multiDownloadDictationsInprogress: boolean = false;

    multiSelectedTranscriptions: Array<any> = [];

    showingDetailsId: string = null;
    dictationsToShow = null;
    uniqueProviders: any = [];
    uniqueFacilities: any = [];

    constructor(
        private _dictationService: AdminDictationsService,
        private _transcriptionCreateService: TranscriptionCreateService,
        private _commonService: CommonService,
        private dashboardService: DashboardService,
        private toastr: ToastrService,
        private loader: NgxUiLoaderService,
        private route: ActivatedRoute,
        private _router: Router
    ) {
        this.filter = this.filterInitialState();
    }

    ngOnInit() {
        if(window.location.pathname.includes('company') && !window.location.pathname.includes('manage-companies')){  
            this.company_id = this.route.parent.parent.snapshot.params.id; 
          } 
          else if (window.location.pathname.includes('manage-companies')){ 
            this.company_id = this.route.parent.parent.snapshot.params.id; 
          }
        
        this.filter = this.filterInitialState();

        this.appliedFilter = { ...this.filter };
        this.initDictations();
        this.initProviders();
        this.initFacilities();
        /*this.filteredFacilities = this.facilityControl.valueChanges.pipe(
            startWith(''),
            map((value:any) => this.filterFacilities(value)));*/
        this.filteredProvider = this.providerControl.valueChanges.pipe(
            startWith(''),
            map((value:any) => this.filterProvider(value)));
    }

    initDictations() {
        this.loader.startLoader('dictation-loader');
        
        var cloned = JSON.parse(JSON.stringify(this.filter));
        this._dictationService.getDictations(cloned, this.company_id).subscribe((response: any) => {
            if (response.status == 200) {
                
                this.dictations = response.data;
                //console.log("Dictations::: ",this.dictations);
                this.sortedData = this.dictations;
                this.dictationsGroup= this.sortDictationsBySubmissionDate(this.sortedData);
                //this.dictationsGroup = this.groupDictationsByDateOfSubmission(this.sortedData);
                this.dataSource = new MatTableDataSource(this.sortedData);
                this.loader.stopLoader('dictation-loader');
                
                // this.resetFilter();
                console.log('audio',this.dictations);
            }
        });
    }
    sortDictationsBySubmissionDate(dictations)
    {
        var byDate = dictations.slice(0);
        byDate.sort(function(a,b) {
            return a._id < b._id ? 1 : a._id > b._id ? -1 : 0;
        });
        return byDate;
    }
    getDate(date)
    {
        return moment(new Date(date)).format('MMM DD, YYYY');
    }
    getdictStatus(audio){
        if(audio.dic_status == "19"){
            return "IN PROGRESS"
        }
        else if(audio.dic_status == "20"){
            return "COMPLETED"
        }
        else if(audio.dic_status == "18"){
            return "PENDING"
        }
        else if(audio.dic_status == "21"){
            return "DRAFT"
        }
        else if(audio.dic_status == "23"){
            return "TRASHED"
        }
    }
    private filterFacilities(value: string): string[] {
        if (value) { 
          const filterValue = value.toLowerCase();
          return this.uniqueFacilities.filter(option => option.title.toLowerCase().indexOf(filterValue) === 0);
        }
        else {
          return this.uniqueFacilities;
        }
      }
    private filterProvider(value: string): string[] {
        if (value) { 
          const filterValue = value.toLowerCase();
          return this.uniqueProviders.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
        }
        else {
          return this.uniqueProviders;
        }
      }

    onSelectFacility(item = null) {
        if (item) {
            this.facilityMOdel = item.title;
            this.filter.facility = item._id;
        }
        else {
            this.filter.facility = null;
        }
    } 
    onSelectProvider(item = null) {
        if (item) {
            this.provideModel = item.name;
            this.filter.provider = item._id;
            this.initFacilities(item);
        }
        else {
            this.filter.provider = null;
            this.initFacilities();
        }
    } 
    clearProviderFilter(){
        this.provideModel = '';
    }

    resetFilter() {
        this.facilityMOdel = '';
        this.provideModel = '';
        this.filter = this.filterInitialState();
        this.appliedFilter = { ...this.filter };
        this.filter.date_of_submission = {
            startDate: moment().subtract(5, 'days'),
            endDate: moment(),
        };
        this.applyFilter();
    }

    initProviders() {
        this._dictationService.getProviders(this.company_id).subscribe((response: any) => {
            if (response.status == 200) {
                
                this.providers = [...response.data];
                this.providers = response.data.map(u => {
                    u.name = `${u.first_name} ${u.last_name}`;
                    if (u.user_type == '1') {
                        u.name += `, ${u.title}`
                    }
                    return u;
                });

                const map = new Map();
                for (const item of this.providers) {
                    if (!map.has(item._id) && item.first_name) {
                        map.set(item._id, true);    // set any value to Map
                        this.uniqueProviders.push(item);
                    }
                }
                this.uniqueProviders.sort(this.sortProviders)
            }
        });
    }
    sortProviders(a, b) {
        if (a.first_name < b.last_name) {
            return -1;
        }
        if (a.first_name > b.last_name) {
            return 1;
        }
        return 0;
    }



    initNoteTypes(doctor_id) {
        this._dictationService.getNoteTypes(doctor_id).subscribe((response: any) => {
            if (response.status == 200) {
                this.note_types = response.data.array;
            }
        });
    }

    async initFacilities(provider = null) {
        // this.loading = true; 
        let response: any ;
        if(provider ) //&& provider != null
        {
            response = await this._transcriptionCreateService.getFacilities(provider._id).toPromise()
            if(response.status === 200) {
                this.facilities = response.data.array;
                this.uniqueFacilities = response.data.array;
                // this.filterFacilities(null);
                this.filteredFacilities = this.facilityControl.valueChanges.pipe(
                    startWith(''),
                    map((value:any) => this.filterFacilities(value))
                );
            }
        }
        // else{
        //     response = await this._transcriptionCreateService.getFacilities().toPromise()
        // }
        // by me
       /* if(response.status === 200){
            this.facilities = response.data.array;  
            this.uniqueFacilities = response.data.array;
            // this.filterFacilities(null);
            this.filteredFacilities = this.facilityControl.valueChanges.pipe(
                startWith(''),
                map((value:any) => this.filterFacilities(value))
                ); */
            // this.facilities = [...this.facilities];
            // const map = new Map();
            // for (const item of this.facilities) {
            //     if (!map.has(item._id) && item.title) {
            //         map.set(item._id, true);    // set any value to Map
            //         this.uniqueFacilities.push(item);
            //     }
            // }
            // this.uniqueFacilities.sort(this.sortFacilities)
      // }
    }
    sortFacilities(a, b) {
        if (a.title < b.title) {
            return -1;
        }
        if (a.title > b.title) {
            return 1;
        }
        return 0;
    }

    filterInitialState(): IFilter {
        return {
            active: false,
            date_of_service: null,
            date_of_submission: {
                startDate: moment().subtract(5, 'days'),
                endDate: moment(),
            },
            provider: '',
            note_type: '',
            facility: '',
            progress_status: '',
        };
    }

    handleApplyFilterClick() {
        this.applyFilter();
        //
        this.appliedFilter = { ...this.filter };
        //
    }

    applyFilter() {
        this.initDictations();
        this.sortedData = this.dictations;
        //this.dictationsGroup = this.groupDictationsByDateOfSubmission(this.sortedData);
        //return;
        const consoleTable = [];
        let sortedData = this.dictations.filter(audio => {
            // Filter by Date of Submission
            if (this.filter.date_of_submission && (this.filter.date_of_submission.startDate && this.filter.date_of_submission.endDate)) {
                const date = moment(new Date(audio.uploading_date ? audio.uploading_date : audio.date_time)).startOf('day');
                var startDate = moment(this.filter.date_of_submission.startDate).startOf('day');
                var endDate = moment(this.filter.date_of_submission.endDate).startOf('day');
                const dateResult = date.isBetween(startDate, endDate) || date.isSame(startDate) || date.isSame(endDate);
                return dateResult;
            }
            return true;
        });
        sortedData = sortedData.filter(audio => {
            // Filter by Date of Service
            if (this.filter.date_of_service && (this.filter.date_of_service.startDate && this.filter.date_of_service.endDate)) {
                const date = moment(new Date(audio.date_time)).startOf('day');
                const startDate = moment(this.filter.date_of_service.startDate).startOf('day');
                const endDate = moment(this.filter.date_of_service.endDate).startOf('day');

                const dateResult = date.isBetween(startDate, endDate) || date.isSame(startDate) || date.isSame(endDate);
                consoleTable.push({
                    audio_id: audio._id,
                    date: date.format('DD MM YYYY'),
                    startDate: startDate.format('DD MM YYYY'),
                    endDate: endDate.format('DD MM YYYY'),
                    dateResult
                });

                return dateResult;
            }
            return true;
        });

        sortedData = sortedData.filter(audio => {
            // Filter by Provider
            if (this.filter.provider) {
                return audio.uploaded_by_id == this.filter.provider;
            }
            return true;
        });
        sortedData = sortedData.filter(audio => {
            if (this.filter.note_type) {
                return audio.audio_title.indexOf(this.filter.note_type) >= 0;
            }
            return true;
        });
        sortedData = sortedData.filter(audio => {
            if (this.filter.facility) {
                return audio.facility_id === this.filter.facility;
            }
            return true;
        });
        sortedData = sortedData.filter(audio => {
            if (this.filter.progress_status) {
                return audio.progress_status === this.filter.progress_status;
            }
            return true;
        });
        this.sortedData = sortedData;
        // this.dictationsGroup = this.groupDictationsByDateOfSubmission(sortedData);

        // this.dataSource = new MatTableDataSource(this.sortedData);
    }

    sortData(sort: Sort) {
        const data = this.dictations.slice();
        if (!sort.active || sort.direction === '') {
            this.sortedData = data;
            this.filter = this.filterInitialState();
            return;
        }


        this.sortedData = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'audio_title':
                    return compare(a.audio_title, b.audio_title, isAsc);
                case 'uploader_name':
                    return compare(a.uploader_name, b.uploader_name, isAsc);
                case 'date_time':
                    return compare(new Date(a.date_time).getTime(), new Date(b.date_time).getTime(), isAsc);
                case 'facility_title':
                    return compare(a.facility_title, b.facility_title, isAsc);
                case 'progress_status':
                    return compare(a.progress_status, b.progress_status, isAsc);
                case 'audio_length':
                    return compare(a.audio_length, b.audio_length, isAsc);
                case 'notes_count':
                    return compare(a.notes_count, b.notes_count, isAsc);
                default:
                    return 0;
            }
        });
        this.dataSource = new MatTableDataSource(this.sortedData);
    }

    filterData() {
        const date_of_service = (this.appliedFilter.date_of_service && (this._commonService.dateRangeToString(moment(this.appliedFilter.date_of_service.startDate), moment(this.appliedFilter.date_of_service.endDate))) || '-');
        const date_of_submission = (this.appliedFilter.date_of_submission && (this._commonService.dateRangeToString(moment(this.appliedFilter.date_of_submission.startDate), moment(this.appliedFilter.date_of_submission.endDate))) || '-');
        const provider = this.appliedFilter.provider ? this._commonService.getUserFullName(this.uniqueProviders.find(p => p._id === this.appliedFilter.provider)) : '-';
        const note_type = this.appliedFilter.note_type ? this.appliedFilter.note_type : '-';
        const facility = this.appliedFilter.facility ? this._commonService.getFacilityTitle(this.uniqueFacilities.find(f => f._id === this.appliedFilter.facility)) : '-';
        const progress_status = this.appliedFilter.progress_status ? this.status.find(s => s.value === this.appliedFilter.progress_status).title : '-';
        const total = this.sortedData ? this.sortedData.length : 0;
        var i=0,j=0;
        var total1=0;
        for (i =0; i< this.dictations?.length;i++)
            for(j=0;j<this.dictations[i].groupedData.length;j++)
                total1= total1 + this.dictations[i].groupedData[j].audiodata.total;

        return [
            { label: 'Date of Service', value: date_of_service },
            { label: 'Date of Submission', value: date_of_submission },
            { label: 'Provider', value: provider },
            { label: 'Note Type', value: note_type },
            { label: 'Facility', value: facility },
            { label: 'Progress Status', value: progress_status },
            { label: 'Total', value: total1 },
        ];
    }


    startMultiDownloadDictations(downloadType, dictations) {

        this.multiDownloadDictationsInprogress = true;
        this.downloadProgress.show = true;
        this.downloadProgress.progress = 0;
        const { uploaded_by_id, facility_id, date_time } = dictations[0];
        this.downloadProgress.id = `${moment(new Date(date_time)).format('MMM DD, YYYY')},${uploaded_by_id},${facility_id}`;
        const fileName = `${dictations.length} dictation(s).zip`;
        this.dashboardService.multiDownloadDictationsByIds(downloadType, dictations).subscribe((res: any) => {

            if (res instanceof HttpResponse) {
                this.multiDownloadDictationsInprogress = false;
                this.downloadProgress.show = false;
                // saveAs(res, fileName);
                var url = window.URL.createObjectURL(res.body);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
            } else {
                if (res.type === 0) {
                } else if (res.type === 3) {
                    const { loaded, total } = res;
                    const progress = Math.round(loaded / total * 100);
                    if (progress > 0) {
                        this.downloadProgress.mode = 'determinate';
                        this.downloadProgress.progress = progress;
                    }
                }
            }
            // if(data) {

            //   saveAs(data, fileName);
            // }
        }, () => {
            this.toastr.error('Something went wrong while downloading dictations', 'Failed');
        },
            () => {
                this.multiDownloadDictationsInprogress = false;
                this.downloadProgress.show = false;
                this.downloadProgress.progress = 0;
                this.downloadProgress.id = '';
            });
    }

    /**
     * Group dictations by Date of Service
     */
    // groupDictations(dictations:Array<any>) {
    //   const groups = {};
    //   dictations.forEach(dictation => {
    //     const dateOfService = moment(dictation.date_time).format('MMM DD, YYYY');
    //     if(!Array.isArray(groups[dateOfService])) {
    //       groups[dateOfService] = [dictation];
    //     } else {
    //       groups[dateOfService].push(dictation);
    //     }
    //   });

    //   // mat datasoruces

    //   // Object.keys(groups).forEach(key =>
    //   //   groups[key] = new MatTableDataSource(groups[key])
    //   // );
    //   return groups;
    // }

    groupDictationsByDateOfService(dictations) {
        const dictationsByDateOfSericeGroups = {};
        for (let dictation of dictations) {
            const dateOfService = moment(new Date(dictation.date_time)).format('MMM DD, YYYY');

            if (!dictationsByDateOfSericeGroups[dateOfService]) {
                dictationsByDateOfSericeGroups[dateOfService] = {};
            }

            if (!dictationsByDateOfSericeGroups[dateOfService][dictation.uploaded_by_id]) {
                dictationsByDateOfSericeGroups[dateOfService][dictation.uploaded_by_id] = {};
            }

            if (!Array.isArray(dictationsByDateOfSericeGroups[dateOfService][dictation.uploaded_by_id][dictation.facility_id])) {
                dictationsByDateOfSericeGroups[dateOfService][dictation.uploaded_by_id][dictation.facility_id] = [];
            }
            dictationsByDateOfSericeGroups[dateOfService][dictation.uploaded_by_id][dictation.facility_id].push(dictation);

        }
        return dictationsByDateOfSericeGroups;
    }

    groupDictationsByDateOfSubmission(dictations) {
        const dictationsByDateOfSericeGroups = {};
        for (let dictation of dictations) {
            // const dateOfSubmission = moment((dictation.filterUploadingDate ? dictation.filterUploadingDate : dictation.date_time)).format('MMM DD, YYYY');
            const dateOfSubmission = moment((dictation.filterUploadingDate )).format('MMM DD, YYYY');

            if (!dictationsByDateOfSericeGroups[dateOfSubmission]) {
                dictationsByDateOfSericeGroups[dateOfSubmission] = {};
            }

            if (!dictationsByDateOfSericeGroups[dateOfSubmission][dictation.uploaded_by_id]) {
                dictationsByDateOfSericeGroups[dateOfSubmission][dictation.uploaded_by_id] = {};
            }

            if (!Array.isArray(dictationsByDateOfSericeGroups[dateOfSubmission][dictation.uploaded_by_id][dictation.facility_id])) {
                dictationsByDateOfSericeGroups[dateOfSubmission][dictation.uploaded_by_id][dictation.facility_id] = [];
            }
            dictationsByDateOfSericeGroups[dateOfSubmission][dictation.uploaded_by_id][dictation.facility_id].push(dictation);

        }
        return dictationsByDateOfSericeGroups;
    }

    getFacilityTitle(facility_id) {
        let dict = this.dictations.find(d => d.facility_id === facility_id);
        if (dict.facility_id_ref) {
            return dict.facility_id_ref.title
        } else {
            return 'Nan'
        }
    }

    getServiceDate(audio){
        if(audio && audio.DOS){
            return moment(new Date(audio.DOS).toLocaleString('en-US', { timeZone:  audio.timezone })).format('MMM DD, YYYY h:mm a');
            // return moment(new Date(audio.filterServiceDate)).format('MMM DD, YYYY h:mm a');
        }
        else{
            return '';
        }
    } 
    
    getUploaderName(uploaded_by_id) {
        let dict = this.dictations.find(d => d.uploaded_by_id === uploaded_by_id);
        let uploader_name;
        if (dict.uploaded_by_id_ref) {
            uploader_name = dict.uploaded_by_id_ref.first_name + ' ' + dict.uploaded_by_id_ref.last_name;
        } else {
            uploader_name = 'None';
        }

        return uploader_name;
    }

    setDictationsToShow(dictations: any) {
        console.log("Dictations : ",dictations);
        this.dictationsToShow = Array.isArray(dictations?.audioData) ? new MatTableDataSource(dictations.audioData.map((dictation:any) =>{
            const dictations_data = {

                    ...dictation,
                    facility_id_ref: dictations.facility,
                    uploaded_by_id_ref : dictations.user,
                    patient_id_ref : dictations?.patient,
                }
                
                return dictations_data;
            }
        )) : null;
        // this.dictationsToShow = Array.isArray(dictations?.audioData) ? new MatTableDataSource(dictations.audioData.map((dictation:any) =>
        //     {
        //
        //     }
        // ) : null;
    }

    async selectedAudio($event, recording, audiosLength) {
        console.log('audio',recording);
        //

        //block click

        // if(this.isMultiDownloadDictationsOn) {

        //   const excludeClasses = ['mat-checkbox-inner-container', 'mat-icon']
        //   for(let cls of excludeClasses) {
        //    if($event.target.classList.value.indexOf(cls) >= 0) return;
        //   }

        //    this.toggleDictationMultiDownloads.emit(recording);
        //    return;
        //  }

        /* commented by mohsin */
        // if (this.currentUser) {

        //     let res = await this._dashboardService
        //         .checkAudioNote(recording._id).toPromise();


        //     // @ts-ignore
        //     if (res.status === 400) {
        //         localStorage.setItem('category', 'dict');
        //         // localStorage.setItem('audiosLength', audiosLength);
        //         localStorage.setItem('dictationFiles', JSON.stringify(this.audios));
        //         this._router.navigate(['/transcription-detail', { audio_id: recording._id }]);
        //         localStorage.setItem('CurrentRecording', JSON.stringify(recording));
        //     } else { // @ts-ignore
        //         // @ts-ignore
        //         if (res.status === 200) {
        //             // let emptyAudios=[];
        //             // localStorage.setItem('dictationFiles', JSON.stringify(emptyAudios));
        //             // localStorage.setItem('dictationFiles', JSON.stringify(this.audios));
        //             // @ts-ignore
        //             const routerMapParams: any = { category: 'tran', id: res.data._id, readonly: false };
        //             routerMapParams.audio_id = recording._id;
        //             routerMapParams.category = 'dict';

        //             // this._router.navigate(['/transcription-create', routerMapParams]);
        //             // @ts-ignore
        //             this._router.navigate(['/note-editor/' + res.data._id]);
        //         }
        //     }
        // }
 
        this._router.navigate([`company/${this.company_id}/transcription-detail`, { audio_id: recording.audio_Id }]);

    }

    isDictationsVisible() {
        return this.dictationsToShow !== null;
    }

    // setShowingDetailsId(value:string) {
    //   this.showingDetailsId = value;
    // }

    getResult(gDictations)
    {
        console.log("gDictatios:  ",gDictations)

    }
    changeSubmissionDate(event)
    {
        var startDate= moment(event.startDate);
        var endDate=moment(event.endDate);
        var noOfDays= endDate.diff(startDate,'days');
        //console.log("noOfDays",noOfDays);
        // var cur_date= moment(new Date());
        // if(endDate>cur_date)
        // {
        //     this.submissionDateFilter = false;
        //     this.filter.date_of_submission.endDate=cur_date;
        //     setTimeout(()=>{
        //         this.submissionDateFilter = true;
        //     },300)
        // }
        if(noOfDays>30)
        {
            this.submissionDateFilter = false;
            this.filter.date_of_submission.endDate=moment(this.filter.date_of_submission?.startDate).add(29,"days").startOf('day');
            endDate=this.filter.date_of_submission.endDate;
            this.toastr.error('Can not select more than 30 days', 'ERROR');

            setTimeout(()=>{
                this.submissionDateFilter = true;
            },300)
        }
    }
    changeServiceDate(event)
    {
        var startDate= moment(event.startDate);
        var endDate=moment(event.endDate);
        var noOfDays= endDate.diff(startDate,'days');
        //console.log("noOfDays",noOfDays);
        // var cur_date= moment(new Date());
        // if(endDate>cur_date)
        // {
        //     this.serviceDateFilter = false;
        //     this.filter.date_of_service.endDate=cur_date;
        //     setTimeout(()=>{
        //         this.serviceDateFilter = true;
        //     },300)
        // }
        if(noOfDays>30)
        {
            this.serviceDateFilter = false;
            this.filter.date_of_service.endDate=moment(this.filter.date_of_service?.startDate).add(29,"days").startOf('day');
            endDate=this.filter.date_of_service.endDate;
            this.toastr.error('Can not select more than 30 days', 'ERROR');

            setTimeout(()=>{
                this.serviceDateFilter = true;
            },300)
        }
    }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

