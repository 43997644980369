import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import moment from 'moment'
import { AuthService } from 'src/app/services/auth.service';
import { FacilityService } from 'src/app/services/facility.service';
import { ReportModuleService } from '../report-module.service'; 
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MipReportAuditPopupComponent } from '../mip-report-audit-popup/mip-report-audit-popup.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { TranscriptionCreateService } from '../../transcription-create/transcription-create.service';

@Component({
  selector: 'app-mips-reports',
  templateUrl: './mips-reports.component.html',
  styleUrls: ['./mips-reports.component.css']
})
export class MipsReportsComponent implements OnInit {

  currentUser: any;
  company_id: any = '';
  facilities: any = [];
  selectedFacility : any;
  selectedFacilityId: any;
  providers: any = [];
  selectedProvider : any;
  selectedProviderId : any;
  projection = {};
  loaderId = 'loader-01';
  filter = {
    is_active: true,
    company_id: '',
    facility_id: '',
    provider_id: '',
    date_of_submission: {
      startDate: moment(),
        endDate: moment()
    }
  };
  searchFacilityText: any;
  searchProviderText: any;
  public submissionDateFilter: Boolean = true;
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month'), moment()],
    'Last 6 Months': [moment().subtract(6, 'month'), moment()],
    'Last 1 Year': [moment().subtract(1, 'year'), moment()],
  };
  
  public c_date = moment(new Date());
  public dataSource: any; 
  public dataSource2: any; 
  public dataSource3: any; 
  public mipsGroupedData: any; 
  public mipsProviderGroupedData: any; 
  public expandedData: any; 
  displayedColumns: string[] = ['mip', 'successfull','un-successfull', 'total'];
  displayedColumns2: string[] = ['provider', 'facility', 'mip', 'status', 'count' , 'operation']; 
  displayedColumns3: string[] = ['provider', 'facility', 'mip', 'status' , 'operation'];
  mipGroupedScreen = true;
  providerGroupedMipScreen = false;
  expandedDataScreen = false;
  selectedgroup;
  mipTypeStatus;
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private facilityService: FacilityService,
    private reportModuleService: ReportModuleService,
    private dialog: MatDialog,
    private loader: NgxUiLoaderService,
    private userTypesRelationshipService: UserTypesRelationshipService,
    private _transcriptionCreateService: TranscriptionCreateService,
    private mipReportAuditPopupComponent : MipReportAuditPopupComponent
  ) { 
    this.currentUser = this.authService.currentUser;
    if (window.location.pathname.includes('company')) {
      this.company_id = this.route.parent.snapshot.params.id
    }
    else {
        this.company_id = '';
    }
    this.projection = {
      'title': 1,
      'address': 1,
      'city': 1,
      'state': 1,
      'zip_code': 1,
      'is_active': 1
    }
    this.filter = { 
      is_active: true,
      company_id: this.company_id,
      facility_id: '',
      provider_id: '',
      date_of_submission: {
        startDate: moment(),
        endDate: moment(),
      }
    }
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId); 
    // await this.getFacilities();
    await this.getProviders();
    
  }

  async getProviders() {
    let population = 'true';
    const doctorsResponse: any = await this.userTypesRelationshipService.getDoctorsList('true', this.company_id).toPromise();
    if (doctorsResponse.status === 200) {
      this.providers = doctorsResponse.data;
      if (this.providers.length > 0) {
        this.providers = this.sortFirstNameVise(this.providers);
      }
      await this.applyFilter()
    }
  }

  sortFirstNameVise(arrayTosort) {
    let users = arrayTosort;
    let result = users.sort(function (a, b) {
      if (a.first_name?.toLowerCase() < b.first_name?.toLowerCase()) { return -1; }
      if (a.first_name?.toLowerCase() > b.first_name?.toLowerCase()) { return 1; }
      return 0;
    })
    return result;
  }


  async getFacilities(){
    let population = 'true';
    let res = await lastValueFrom(this.facilityService.getAllFacilities(this.filter, this.projection, population)) as any;
    // console.log("res---- ", res)
    if(res.status == 200){
      this.facilities = res.data.array;
      await this.applyFilter()
    }
  }

  async getMips(){
    
  }

  applyFacilityChange(event){
    if(event.value && event.value !== '') {
      this.selectedFacilityId = event.value;
      this.filter.facility_id = this.selectedFacilityId;
      this.selectedFacility = this.facilities.find((f) => f._id == event.value);
    } else if(!event.value) {
      this.selectedFacilityId = event.value;
      this.selectedFacility = '';
      this.filter.facility_id = this.selectedFacilityId;
    } 
  }

  async applyProviderChange(event = null) {
    this.facilities = [];
    this.selectedFacility = '';
    this.filter.facility_id = '';
    this.selectedFacilityId = event.value;
    if(event.value && event.value !== '') {
      this.selectedProviderId = event.value;
      await this.initFacilities(this.selectedProviderId, null, this.company_id)
      this.filter.provider_id = this.selectedProviderId;
      let selectedProvider = this.providers.find((p) => p._id == event.value);
    } else if (!event.value) {
      this.selectedProviderId = event.value;
      this.filter.provider_id = this.selectedProviderId;
      this.selectedProviderId = '';
      this.filter.provider_id = '';
    }
  }

  async initFacilities(providerID = null, source = null, company_id = null) {
    let response: any = {};
    if (providerID) {
      response = await this._transcriptionCreateService.getFacilities(providerID, source, company_id).toPromise();
    }
    if (response.status === 200) {
      this.facilities = response.data.array;
    }
  }
 

  async getProviderGroupedMip(element, mipType){
    this.loader.startLoader(this.loaderId)
    // console.log('Element ===== ', element)
    this.providerGroupedMipScreen = true;
    this.mipGroupedScreen = false;
    this.expandedDataScreen = false;
    this.mipTypeStatus = mipType;
    let measure_id = element._id.measure_id
    let res = await lastValueFrom(this.reportModuleService.getProviderGroupedMip(this.filter, measure_id, mipType ))as any;
    if(res.status == 200){
      this.loader.stopLoader(this.loaderId)
      this.mipsProviderGroupedData = res.data;
      this.dataSource2 = new MatTableDataSource(this.mipsProviderGroupedData); 
    }
    this.loader.stopLoader(this.loaderId);
  }

  async applyFilter(){
    this.loader.startLoader(this.loaderId)
    this.mipGroupedScreen = true;
    this.providerGroupedMipScreen = false;
    this.expandedDataScreen = false;
    // console.log('Filter---------', this.filter)
    let res = await lastValueFrom(this.reportModuleService.getMipReportMipGrouped(this.filter)) as any;
    console.log('response from mip report',res)
    if(res.status == 200){
      this.loader.stopLoader(this.loaderId)
      this.mipsGroupedData = res.data;
      this.dataSource = new MatTableDataSource(this.mipsGroupedData); 
    }
    this.loader.stopLoader(this.loaderId);
  }


  isPCCFacility() {
    return this.selectedFacility != undefined && this.selectedFacility != 'all' && (this.selectedFacility?.source === 'PointClickCare' || this.selectedFacility?.pcc_facId)
  }
  isMatrixFacility() {
    return this.selectedFacility != undefined && this.selectedFacility != 'all' && this.selectedFacility?.source === 'MatrixCare'
  }
  isDNFacility() {
    return this.selectedFacility != undefined && this.selectedFacility != 'all' && this.selectedFacility?.source != 'PointClickCare' && !this.selectedFacility?.pcc_facId && this.selectedFacility?.source != 'MatrixCare'
  }
  
  changeSubmissionDate(event){
    // console.log('----event-----', event)
    // console.log('----event startDate-----', moment(moment(event.startDate).toLocaleString()).format('MM/DD/YYYY'))
    // let endDate = moment(moment(event.endDate).toLocaleString()).format('MM/DD/YYYY')
    // let startDate = moment(moment(event.startDate).toLocaleString()).format('MM/DD/YYYY')
    this.filter.date_of_submission = {
      startDate: event.startDate,
      endDate: event.endDate,
    }

  }


  getProviderName(element){
    if(element.creatorInfo){
      return `${element.creatorInfo.last_name} ${element.creatorInfo.first_name}`
    }
    else{
      return '--'
    }
  }
  getProviderNameExpanded(){
    if(this.expandedData.creatorInfo){
      return `${this.expandedData.creatorInfo.last_name} ${this.expandedData.creatorInfo.first_name}`
    }
    else{
      return '--'
    }
  }

  async goToDetailScreen(element){ 
    this.mipGroupedScreen = false;
    this.providerGroupedMipScreen = false;
    this.expandedDataScreen = true;
    this.expandedData = element
    this.dataSource3 = new MatTableDataSource(this.expandedData.data); 
  }

  async goToAudit(element){
    // console.log("goToAudit clicked:  ", element)
    let answer_id = element._id;
    let res = await lastValueFrom(this.reportModuleService.getP360Response(answer_id, element.p360Status)) as any;
    if(res.status == 200){
      let auditPopup = this.dialog.open(MipReportAuditPopupComponent, { data: res.data, width: '40%', maxHeight: "40%", autoFocus: false })
      // auditPopup.afterClosed().subscribe(async result => { 
      // });
    }
  }

  goBack(){ 
    if(this.providerGroupedMipScreen){
      this.mipGroupedScreen = true;
      this.providerGroupedMipScreen = false;
      this.expandedDataScreen = false;
    }
    else if(this.expandedDataScreen){
      this.mipGroupedScreen = false;
      this.providerGroupedMipScreen = true;
      this.expandedDataScreen = false;
    }
  }

}
