export class DoctorGeneralDetails {
    user_id_ref:string|object;
    user_type:'Personal'|'Business'|'';
    name:string;
    date:Date;
    is_active:string;
    type:string;
    tax_id:string;
    practice_group_name:string;
    primary_practice_address:string;
    phone:string;
    fax:string;
    email:string;
    additional_practice_address:string;
    additional_phone:string;
    additional_fax:string;
    additional_email:string;
    speciality:string;
    american_speciality_board_status:string;
    secondary_board_status:string;
    // all_current_and_past_state_medical_licenses:string;
    // current_hospital_admitting_privileges:string[] = [];
    office_contact_person_name:string;
    office_contact_phone:string;
    office_contact_position_title:string;
    office_contact_email:string;
}