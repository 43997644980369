import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { DialogueManageCptCodesComponent } from './dialogue-manage-cpt-codes/dialogue-manage-cpt-codes.component';
import { TemplateExplorerService } from 'src/app/includes/templates/template-explorer/template-explorer.service';
import { DialogueAddCptCodeComponent } from './dialogue-add-cpt-code/dialogue-add-cpt-code.component';
import { ActivatedRoute } from '@angular/router';
import { PatientRoundingSheetService } from 'src/app/includes/patient-rounding-sheets/patient-rounding-sheet.service';

@Component({
  selector: 'app-manage-cpts',
  templateUrl: './manage-cpts.component.html',
  styleUrls: ['./manage-cpts.component.css']
})
export class ManageCptsComponent implements OnInit {

  dataSource: any = new MatTableDataSource([]);
  cpts = [];
  enableEditIndex = null;
  valueToBeSearched: string = "";
  displayedColumns: string[] = ['Code', 'Description', 'Status', 'Operation'];
  previousDescription: String = "";
  timeoutId: any;
  isIcdLoaded = false;
  company_id: any;

  constructor(private templateExplorerService: TemplateExplorerService, private toastr: ToastrService, private dialog: MatDialog,private route: ActivatedRoute, private service: PatientRoundingSheetService) {
    this.company_id = this.route.parent.snapshot.params.id;
  }

  ngOnInit(): void {
    this.displayAllCodes();

  }

  setValueToBeSearched(filterValue: any) {
    this.valueToBeSearched = filterValue;
    if (this.valueToBeSearched === "")
      this.displayAllCodes(this.valueToBeSearched)
  }

  searchValue() {
    this.isIcdLoaded = true;
    if (this.valueToBeSearched.trim().length != 0) {
      let array = this.cpts.filter(value => { if (value.codeDescription.toLowerCase().includes(this.valueToBeSearched.toLowerCase())) return value });
      this.dataSource.data = array;
    }
    this.isIcdLoaded = false;
  }

  async updateCpt(index: any, data: any) {
    data.company_id = this.company_id;
    this.enableEditIndex = index;
    this.dataSource.data[index].shouldUpdate = true;
    const dialogRef = this.dialog.open(DialogueManageCptCodesComponent, {
      height: '600px',
      width: '700px',
      data: data
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.dataSource.data = [];
        this.cpts[index] = data;
        this.dataSource.data = this.cpts;
      }
    });
    this.enableEditIndex = index;
    this.dataSource.data[index].shouldUpdate = false;
  }

  displayAllCodes(search = "") {
    this.templateExplorerService.getAllCPTs()
      .subscribe((res: any) => {
        if (res.status === 200) {
          res.data = this.service.addCptExpiryStatus(res.data, false);
          this.cpts = res.data;
          this.dataSource.data = this.cpts;
        }
        else if (res.status === 500)
          this.toastr.warning(res.message)

      })

  }

  addCpt(){
    const dialogRef = this.dialog.open(DialogueAddCptCodeComponent, {
      height: '600px',
      width: '700px',
      data : this.company_id
  });
    dialogRef.afterClosed().subscribe();

  }

}
