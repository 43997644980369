import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../../global';
import { BillerDashboardService } from '../biller-dashboard.service';
import { lastValueFrom } from 'rxjs';
import { SnfWcFacilityAssociationService } from '../../snf-wc-nurse-facility-association/snf-wc-facility-association.service';
import { FacilityService } from 'src/app/services/facility.service';


@Component({
  selector: 'app-charge-time-period-report',
  templateUrl: './charge-time-period-report.component.html',
  styleUrls: ['./charge-time-period-report.component.css']
})
export class ChargeTimePeriodReportComponent implements OnInit {
  companyId: any;
  currentUser: any;
  charges: any = [];
  dataSource: any = new MatTableDataSource([]);
  global = global;
  displayedColumns = ['provider', '0-1 days', '2 days', '3 days', '4-7 days', '8-10 days', '11-15 days', '16-20 days', '21+ days', 'total'];
  filter_facility_id: any = '';
  searchText: any;
  facilities: any = [];
  selectedFacility: any = '';

  constructor(
    private _route: ActivatedRoute,
    private _authService: AuthService,
    private _toastr: ToastrService,
    private _billerDashboardService: BillerDashboardService,
    private _snfWcNurseAssociationService: SnfWcFacilityAssociationService,
    private facilityService: FacilityService

  ) {
    this.currentUser = this._authService.currentUser;
  }

  async ngOnInit() {
    if (window.location.pathname.includes('company') && !window.location.pathname.includes('manage-companies')) {
      this.companyId = this._route.parent.parent.snapshot.params.id;
    }
    else if (window.location.pathname.includes('manage-companies')) {
      this.companyId = this._route.parent.parent.snapshot.params.id;
    }
    else if (this.currentUser.user_type === global.USER_TYPE.BILLER) {
      this.companyId = this.currentUser.company_id;
    }
    await this.initFacilities(this.companyId)

  }

  initChargesByTimePeriodGap() {
    this._billerDashboardService.getChargesByTimePeriodGap(this.companyId, this.filter_facility_id).subscribe((response: any) => {
      if (response.status == 200) {
        this.charges = response.data;
        for (const list of this.charges) {
          list.days = {
            '0-1': 0,
            '2': 0,
            '3': 0,
            '4-7': 0,
            '8-10': 0,
            '11-15': 0,
            '16-20': 0,
            '21+': 0,
          };
          for (const charge of list?.groupedByOwner) {
            if (charge?._id?.differenceInDays >= 0 && charge?._id?.differenceInDays <= 1) {
              list.days['0-1'] += charge?.count;
            }
            else if (charge?._id?.differenceInDays === 2) {
              list.days['2'] += charge?.count;
            }
            else if (charge?._id?.differenceInDays === 3) {
              list.days['3'] += charge?.count;
            }
            else if (charge?._id?.differenceInDays >= 4 && charge?._id?.differenceInDays <= 7) {
              list.days['4-7'] += charge?.count;
            }
            else if (charge?._id?.differenceInDays >= 8 && charge?._id?.differenceInDays <= 10) {
              list.days['8-10'] += charge?.count;
            }
            else if (charge?._id?.differenceInDays >= 11 && charge?._id?.differenceInDays <= 15) {
              list.days['11-15'] += charge?.count;
            }
            else if (charge?._id?.differenceInDays >= 16 && charge?._id?.differenceInDays <= 20) {
              list.days['16-20'] += charge?.count;
            }
            else if (charge?._id?.differenceInDays >= 21) {
              list.days['21+'] += charge?.count;
            }
          }
        }
        this.dataSource.data = this.charges;
        this.dataSource.filterPredicate = function (record, filter) {
          return record?.groupedByOwner[0]?.groupedByOwnerAndDays[0]?.owner_id?.last_name.toLowerCase().includes(filter) || record?.groupedByOwner[0]?.groupedByOwnerAndDays[0]?.owner_id?.first_name.toLowerCase().includes(filter);
          // return record.id.indexOf(filter) != -1;
        }

      }
    }, err => {
      this._toastr.error(err, "ERROR");
    }
    );
  }
  handleFilterFacilityChange() {
    this.dataSource.data = [];
    this.selectedFacility = this.facilities.find(facility => facility._id === this.filter_facility_id);
    this.initChargesByTimePeriodGap();
  }

  getTotalChargeInDays(element) {
    let total = 0;
    for (const charge of element?.groupedByOwner) {
      total += charge?.count;
    }
    return total;
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async initFacilities(company_id) {
    this.facilities = []
    const filter = {
      company_id: company_id
    };
    const nurseProjection = {
      first_name: 1,
      last_name: 1,
      title: 1,
    };
    const facilityProjection = {
      title: 1,
      provider_ids_ref: 1
    };
      this.facilityService.getCompanyFacilities(this.companyId).subscribe((response: any) => {
        if (response.status == 200) {
          this.facilities = response.data.array;
          this.facilities.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
          this.filter_facility_id = this.facilities[0]._id
          this.selectedFacility = this.facilities.find(facility => facility._id === this.filter_facility_id);
          this.initChargesByTimePeriodGap();
        }
      });
    
  }
  isPCCFacility(facility_id) {
    for (const facility of this.facilities) {
      if (facility_id === facility._id) {
        if (facility.pcc_facId) {
          return true;
        }
      }
    }
  }
  isMatrixFacility(facility_id){
    for(const facility of this.facilities) {
      if(facility_id === facility._id) {
        if(facility.source == 'MatrixCare') {
          return true;
        }
      }
    }
  }
  
}
