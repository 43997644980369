<div class="col-content">

    <table mat-table [dataSource]="dataSource" matSort style="padding-bottom: 50px;">
        <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef>Category </th>
            <td mat-cell *matCellDef="let element;let i=index">
                <!-- text input -->
                <mat-form-field [class.mat-form-field-invalid]="showErrorMsgs && !element.category" style="width: 100%;" class="text-field">
                    <input matInput (click)="toggletoUpdate(element,i)" *ngIf="element._id && !element.selected" [(ngModel)]="element.category" style="color:#000;height: 40px;padding-left: 5px;" [readonly]="true" />
                    <input matInput *ngIf="(element._id && element.selected) || (!element._id && !element.selected)" [(ngModel)]="element.category" style="color:#000;height: 40px;padding-left: 5px;" required/>
                    <mat-hint style="color: red" *ngIf="showErrorMsgs && !element.category">Enter Category Name</mat-hint>
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="size">
            <th mat-header-cell *matHeaderCellDef>Size </th>
            <td mat-cell *matCellDef="let element;let i=index">
                <mat-form-field [class.mat-form-field-invalid]="showErrorMsgs && !element.size" style="width: 100%;" class="text-field">
                    <input matInput type="number" [(ngModel)]="element.size" style="color:#000;height: 40px;padding-left: 5px;" [readonly]="element._id && !element.selected" [required]="companyPermissions.should_allow_auto_procedure_coding"/>
                    <mat-hint style="color: red" *ngIf="showErrorMsgs && !element.size && companyPermissions.should_allow_auto_procedure_coding">Enter Category Size</mat-hint>
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="code_1">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Code 1</th>
            <td mat-cell *matCellDef="let element;let i=index"> 
                <mat-form-field style="width:100%" class="text-field" *ngIf="element._id && !element.selected">
                    <input matInput value="{{cptCodeDisplay(element.code_1)}}" style="color:#000;height: 40px;padding-left: 5px;" readonly />
                </mat-form-field>
                <mat-form-field [class.mat-form-field-invalid]="showErrorMsgs && !element.code_1" style="width: 100%;" *ngIf="(element._id && element.selected) || (!element._id && !element.selected)">
                    <mat-select [value]="element.code_1" (selectionChange)="handleCode1Change($event, element)">
                        <input matInput placeholder="Search" (keyup)="searchCPT($event.target.value)"
                            style="color:#000;height: 40px;padding-left: 5px;" autofocus [required]="companyPermissions.should_allow_auto_procedure_coding"/>
                        <mat-option  *ngFor="let cptCode of cptCodeList"
                        [value]="cptCode"    
                            >{{cptCodeDisplay(cptCode)}}</mat-option>
                    </mat-select>
                    <mat-hint style="color: red" *ngIf="showErrorMsgs && !element.code_1 && companyPermissions.should_allow_auto_procedure_coding">Enter Category Code 1</mat-hint>
                </mat-form-field>
            </td>
        </ng-container>
        
        <ng-container matColumnDef="code_2">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Code 2</th>
            <td mat-cell *matCellDef="let element;let i=index"> 
                <mat-form-field style="width:100%" class="text-field" *ngIf="element._id && !element.selected">
                    <input matInput value="{{cptCodeDisplay(element.code_2)}}" style="color:#000;height: 40px;padding-left: 5px;" readonly />
                </mat-form-field>
                <mat-form-field style="width: 100%;" *ngIf="(element._id && element.selected) || (!element._id && !element.selected)">
                    <mat-select [value]="element.code_2" (selectionChange)="handleCode2Change($event, element)">
                        <input matInput placeholder="Search" (keyup)="searchCPT($event.target.value)"
                            style="color:#000;height: 40px;padding-left: 5px;" autofocus />
                        <mat-option  *ngFor="let cptCode of cptCodeList"
                        [value]="cptCode"    
                            >{{cptCodeDisplay(cptCode)}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>
        
        <ng-container matColumnDef="code_3">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Code 3</th>
            <td mat-cell *matCellDef="let element;let i=index"> 
                <mat-form-field style="width:100%" class="text-field" *ngIf="element._id && !element.selected">
                    <input matInput value="{{cptCodeDisplay(element.code_3)}}" style="color:#000;height: 40px;padding-left: 5px;" readonly />
                </mat-form-field>
                <mat-form-field style="width: 100%;" *ngIf="(element._id && element.selected) || (!element._id && !element.selected)">
                    <mat-select [value]="element.code_3" (selectionChange)="handleCode3Change($event, element)">
                        <input matInput placeholder="Search" (keyup)="searchCPT($event.target.value)"
                            style="color:#000;height: 40px;padding-left: 5px;" autofocus />
                        <mat-option *ngFor="let cptCode of cptCodeList"
                        [value]="cptCode"    
                            >{{cptCodeDisplay(cptCode)}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>
        
        <ng-container matColumnDef="operation">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element;let i=index">
            <button class="close-image" *ngIf="element._id && !element.selected && currentUser.admin_type == 'system_admin'"  
                (click)="deleteNewProcedureDataset(element, i)">
                <img src="../../../../../assets/icons/minus.png" />
            </button>

            <button class="save-image" *ngIf="((element._id && element.selected) || (!element._id && !element.selected)) && currentUser.admin_type == 'system_admin'"  
                (click)="handleProcedureTypeCategory(element, i)" [disabled]="disableAddButton">
                <img src="../../../../../assets/icons/save-white.png" />
            </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>

<ng-container *ngIf="!isAddingNewProcedureDataset() && currentUser.admin_type == 'system_admin'">
    <button class="add-image" (click)="handleAddNewProcedureDataset()">
        <img src="assets\icons\plus.png" />
    </button>
</ng-container>

<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
