import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from "../../../environments/api-url";
import * as global from '../global';




@Injectable({
  providedIn: 'root'
})
export class DrivePhraseService {

  constructor(
    private httpclient: HttpClient,
    private _authService: AuthService,
  ) { }


  getNoteBuilderDictionary(provider_id?) {
    const details = {
      ...this._authService.authObject,
      provider_id
    }
    return this.httpclient
      .post(global.url + API_URL.NOTE.getNoteBuilderDictionary, { details: details });

  };

  getNoteBuilderDictionaryAdmin(provider_id?,filter?) {
    const details = {
      ...this._authService.authObject,
      provider_id,
      filter,
    }
    return this.httpclient
      .post(global.url + API_URL.NOTE.getNoteBuilderDictionaryAdmin, { details: details });

  };

  getAllPhrasesByProviderId(provider_id) {
    const details = {
      ...this._authService.authObject,
      provider_id,
    }
    return this.httpclient
      .post(global.url + API_URL.NOTE.getAllPhrasesByProviderId, { details: details });

  };

  saveNoteBuilderDictionary(dictionaryElement) {
    const details = {
      ...this._authService.authObject,
      dictionaryElement: dictionaryElement
    }
    return this.httpclient
      .post(global.url + API_URL.NOTE.saveNoteBuilderDictionary, { details: details });

  };

  saveNoteBuilderDictionaryAdmin(provider_id, phraseArray, providerIds = null) {
    console.log('phrases.service/saveNoteBuilderDictionaryAdmin');
    console.log('dataa in service', provider_id, phraseArray);
    const details: any = {
      ...this._authService.authObject,
      provider_id: provider_id,
      phraseArray: phraseArray
    }
    if (providerIds) {
      details.providerIds = providerIds;
      delete details.provider_id;
    }
    return this.httpclient
      .post(global.url + API_URL.NOTE.saveNoteBuilderDictionaryAdmin, { details: details });

  };

  updateNoteBuilderDictionary(dictionaryElement) {
    console.log('phrases.service/updateNoteBuilderDictionary');
    const details = {
      ...this._authService.authObject,
      dictionaryElement: dictionaryElement
    }
    return this.httpclient
      .post(global.url + API_URL.NOTE.updateNoteBuilderDictionary, { details: details });

  };

  deleteFromNoteBuilderDictionary(dictionaryElement, provider_id?) {
    console.log('phrases.service/saveNoteBuilderDictionary');
    const details = {
      ...this._authService.authObject,
      dictionaryElement: dictionaryElement,
      provider_id
    }
    return this.httpclient
      .post(global.url + API_URL.NOTE.deleteFromNoteBuilderDictionary, { details: details });
  };

  getAssociatedPhrases(provider_id) {
    const details = {
      ...this._authService.authObject,
      provider_id: provider_id
    }
    return this.httpclient
      .post(global.url + API_URL.PHRASE.getAssociatedPhrases, { details: details }).pipe();
  };

  EditPhrase(data) {
    const details = {
      ...this._authService.authObject,
      dictionaryElement: data
    }
    return this.httpclient
      .post(global.url + API_URL.PHRASE.EditPhrase, { details: details }).pipe();
  };

  getPhrasesById(phrase_id) {
    const details = {
      ...this._authService.authObject,
      phrase_id: phrase_id

    }
    return this.httpclient
      .post(global.url + API_URL.PHRASE.getPhrasesById, { details: details }).pipe();
  };


  getPhraseByIdAndKey(phrase_id, phrase_key) {
    const details = {
      ...this._authService.authObject,
      dictionaryElement: {
        phrase_id: phrase_id,
        phrase_key: phrase_key
      }
    }
    return this.httpclient
      .post(global.url + API_URL.PHRASE.getPhraseByIdAndKey, { details: details }).pipe();
  };

  copyPhrase(phrases) {
    console.log("phrases", phrases);
    const details = {
      ...this._authService.authObject,
      phrases
    }
    return this.httpclient
      .post(global.url + API_URL.PHRASE.copyPhrase, { details: details }).pipe();
  };

}
