<div class="container flex flex-col gap-2">
  <div class="flex gap-2 items-center justify-end" style="position: relative;"
    *ngIf="!showFacilityActivationEmailComponent&&showEmailButton">
    <button mat-raised-button class="dn-button-primary activation-email-btn"
      (click)="showFacilityActivationEmailComponent=true">Activation Email</button>
  </div>
<mat-card style="height: calc(100vh - 100px)">
    <mat-tab-group *ngIf="!showFacilityActivationEmailComponent" (selectedTabChange)="hideActivationEmail($event)" [selectedIndex]="defaultTabIndex">
      <mat-tab label="DN Facilities">
        <mat-card-content>
          <br>
          <div class="flex gap-5">
            <div class="flex items-center justify-center w-full">
              <mat-card class="flex-grow search-card">
                <input type="text" class="search-field" placeholder="Search Facilties" name="title"
                  [(ngModel)]="facilityTitleRegExp" (ngModelChange)="searchFacilities()" />
                <mat-spinner *ngIf="hasFacilitiesLoaded" style="margin-left: auto;" matSuffix [diameter]="18">
                </mat-spinner>
              </mat-card>
            </div>
            <div class="patients-page">
              <mat-form-field>
                <mat-select (selectionChange)="applySourceFilter($event)" [(ngModel)]="filterStatus">

                  <mat-option selected value="true">Active</mat-option>
                  <mat-option value="false">Inactive</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="patients-page">
              <mat-form-field>
                <mat-select (selectionChange)="applySourceFilter($event)" [(ngModel)]="selectedSource">
                  <mat-option selected value="all">All</mat-option>
                  <mat-option value="dn">DN</mat-option>
                  <mat-option value="pcc">PCC</mat-option>
                  <mat-option value="matrix">MatrixCare</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
                
            <div class="patients-page" style="align-self: center; white-space: nowrap;">
              <mat-checkbox (change)="applySourceFilter($event)" [(ngModel)]="merged">
                <label class="font-sizes">Merged Only </label>
              </mat-checkbox>
            </div>
            <div class="flex items-center gap-2">
              <div class="flex-grow flex gap-2 justify-center items-center table-nav">

                <button mat-raised-button class="dn-button-primary" (click)="goToPreviousPage()"
                  [disabled]="!pagination.hasPrevPage">
                  Prev</button>
                <span style="width: max-content;">
                  {{pagination.skip + facilities.length === 0 ? 0 : pagination.skip + 1 }} - {{pagination.skip +
                  facilities.length}} out
                  of {{pagination.totalRecords}}
                </span>
                <button mat-raised-button class="dn-button-primary" (click)="goToNextPage()"
                  [disabled]="!pagination.hasNextPage">Next</button>

                <mat-spinner *ngIf="!recordsLoaded" style="margin-left: auto;" matSuffix [diameter]="18">
                </mat-spinner>

              </div>
            </div>

            <div class="flex items-center gap-1">
              <button mat-raised-button class="dn-button-primary" (click)="downloadAsXLSX()">
                Export As XLSX
              </button>
              <button mat-raised-button class="dn-button-primary" [routerLink]="['../add']">
                Add Facility
              </button>
            </div>
          </div>


          <app-alphabetic-index-bar [activeIndex]="activeAlphabeticBarIndex"
            (onIndexButtonClick)="handleAphabeticIndexButtonClick($event)"></app-alphabetic-index-bar>

          <div style="height: 70vh;overflow: auto;">
            <table #table2excel mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="facility">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  Facility
                </th>
                <td mat-cell *matCellDef="let element">
                  <app-facility-title [facility]="element" [loginStatus]="false"></app-facility-title>
                </td>
              </ng-container>
             
              <ng-container matColumnDef="provider">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  Assoc Provider
                </th>
                <td mat-cell *matCellDef="let element">
                  <span style="cursor: pointer; color: blue;"
                    (click)="associatedProvidersDetail(element?.provider_ids_ref)">Details</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="fac_id">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  Fac Id
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>
                    {{getFacAndOrganizationID(element)}}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="is_active">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  Status
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>
                    <mat-slide-toggle *ngIf="filterStatus == 'true'" (change)="onChangeStatus(element,$event)"
                      [checked]="true">
                    </mat-slide-toggle>
                    <mat-slide-toggle *ngIf="filterStatus == 'false'" (change)="onChangeStatus(element,$event)"
                      [checked]="false">
                    </mat-slide-toggle>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="address">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  Address
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.address }}
                </td>
              </ng-container>

              <ng-container matColumnDef="city">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  City
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.city }}
                </td>
              </ng-container>
              <ng-container matColumnDef="state">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  State
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.state >= 0 && usa_states[element.state] ? usa_states[element.state].abbreviation : '' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="zip_code">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  Zip Code
                </th>
                <td mat-cell *matCellDef="let element">{{ element.zip_code }}</td>
              </ng-container>
              <ng-container matColumnDef="pcc_2_legged_authentication">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  2-Legged Auth
                </th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element?.pcc_facId">
                    <mat-slide-toggle (change)="onChangePcc2LeggedAuthentication(element,$event)"
                      [checked]="element?.pcc_2_legged_authentication"></mat-slide-toggle>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="charge_capture">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  Charge Capture
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>
                    <mat-slide-toggle (change)="onChargeCaptureStatus(element,$event)"
                      [checked]="element.enabled_charge_capture">
                    </mat-slide-toggle>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="note_email">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  Notes Email
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>
                    <mat-slide-toggle (change)="onNoteEmailStatus(element,$event)"
                      [checked]="element.enabled_note_email">
                    </mat-slide-toggle>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="association_date">

                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  Association Date
                </th>

                <td mat-cell *matCellDef="let element">
                  <span>
                    {{getAssociationDate(element)}}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="truercm">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  Unique ID
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>{{element?.trueRCMID}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="operations">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                </th>
                <td mat-cell *matCellDef="let element">
                  <div class="buttons-div flex items-center justify-center gap-5">
                    <i class="icon icon-edit" matTooltip="Edit" matTooltipPosition="above"
                      (click)="RedirectToEditFacility(element._id);"></i>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="companies">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>Associated Companies
                </th>
                <td mat-cell *matCellDef="let element">
                  <span style="cursor: pointer; color: blue;"
                    (click)="associatedCompaniesDialog(element?.assoc_company_ids)">Details</span>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
          <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
        </mat-card-content>
      </mat-tab>

      <mat-tab *ngIf="currentUser.product_owner" label="PCC Facilities">
        <br>
        <app-admin-pcc-facilities></app-admin-pcc-facilities>
      </mat-tab>
      <mat-tab label="Matrix Facilities">
        <mat-card-content>
          <br>
          <div class="flex gap-5">
            <div class="flex items-center justify-center w-full">
              <mat-card class="flex-grow search-card">
                <input type="text" class="search-field" placeholder="Search Facilties" name="title"
                  [(ngModel)]="facilityTitleRegExp" (ngModelChange)="searchFacilities()" />
                <mat-spinner *ngIf="hasFacilitiesLoaded" style="margin-left: auto;" matSuffix [diameter]="18">
                </mat-spinner>
              </mat-card>
            </div>
            <div class="patients-page">
              <mat-form-field>
                <mat-select (selectionChange)="applySourceFilter($event)" [(ngModel)]="filterStatus">
                  <mat-option selected value="true">Active</mat-option>
                  <mat-option value="false">Inactive</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="patients-page">
              <mat-form-field>
                <mat-select (selectionChange)="applySourceFilter($event)" [(ngModel)]="trueRcmStatus">
                  <mat-option selected value="all">All</mat-option>
                  <mat-option value="true">True Rcm</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="flex items-center gap-2">
              <div class="flex-grow flex gap-2 justify-center items-center table-nav">
                <button mat-raised-button class="dn-button-primary" (click)="goToPreviousPage()"
                  [disabled]="!pagination.hasPrevPage">
                  Prev</button>
                <span style="width: max-content;">
                  {{pagination.skip + facilities.length === 0 ? 0 : pagination.skip + 1 }} - {{pagination.skip +
                  facilities.length}} out
                  of {{pagination.totalRecords}}
                </span>
                <button mat-raised-button class="dn-button-primary" (click)="goToNextPage()"
                  [disabled]="!pagination.hasNextPage">Next</button>
                <mat-spinner *ngIf="!recordsLoaded" style="margin-left: auto;" matSuffix [diameter]="18">
                </mat-spinner>
              </div>
            </div>
            <div class="flex items-center gap-1">
              <button mat-raised-button class="dn-button-primary" (click)="downloadAsXLSX()">
                Export As XLSX
              </button>
              <button mat-raised-button class="dn-button-primary" [routerLink]="['../add']">
                Add Facility
              </button>
            </div>
          </div>
          <app-alphabetic-index-bar [activeIndex]="activeAlphabeticBarIndex"
            (onIndexButtonClick)="handleAphabeticIndexButtonClick($event)"></app-alphabetic-index-bar>
          <div style="height: 60vh;overflow: auto;">
            <table #table2excel mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="facility">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  Facility
                </th>
                <td mat-cell *matCellDef="let element">
                  <app-facility-title [facility]="element" [loginStatus]="false"></app-facility-title>
              </ng-container>
              <ng-container matColumnDef="fac_id">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  Fac Id
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>
                   {{getFacAndOrganizationID(element)}}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="provider">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  Assoc Provider
                </th>
                <td mat-cell *matCellDef="let element">
                  <span style="cursor: pointer; color: blue;"
                    (click)="associatedProvidersDetail(element?.provider_ids_ref)">Details</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="is_active">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  Status
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>
                    <mat-slide-toggle *ngIf="filterStatus == 'true'" (change)="onChangeStatus(element,$event)"
                      [checked]="true">
                    </mat-slide-toggle>
                    <mat-slide-toggle *ngIf="filterStatus == 'false'" (change)="onChangeStatus(element,$event)"
                      [checked]="false">
                    </mat-slide-toggle>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="address">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  Address
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.address }}
                </td>
              </ng-container>
              <ng-container matColumnDef="city">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  City
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.city }}
                </td>
              </ng-container>
              <ng-container matColumnDef="state">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  State
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.state >= 0 && usa_states[element.state] ? usa_states[element.state].abbreviation : ''
                  }}
                </td>
              </ng-container>
              <ng-container matColumnDef="zip_code">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  Zip Code
                </th>
                <td mat-cell *matCellDef="let element">{{ element.zip_code }}</td>
              </ng-container>
              <ng-container matColumnDef="pcc_2_legged_authentication">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  2-Legged Auth
                </th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element?.pcc_facId">
                    <mat-slide-toggle (change)="onChangePcc2LeggedAuthentication(element,$event)"
                      [checked]="element?.pcc_2_legged_authentication"></mat-slide-toggle>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="charge_capture">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  Charge Capture
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>
                    <mat-slide-toggle (change)="onChargeCaptureStatus(element,$event)"
                      [checked]="element.enabled_charge_capture">
                    </mat-slide-toggle>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="note_email">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  Notes Email
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>
                    <mat-slide-toggle (change)="onNoteEmailStatus(element,$event)"
                      [checked]="element.enabled_note_email">
                    </mat-slide-toggle>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="association_date">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  Association Date
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>
                    {{getAssociationDate(element)}}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="truercm">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                  Unique ID
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>{{element?.trueRCMID}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="operations">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                </th>
                <td mat-cell *matCellDef="let element">
                  <div class="buttons-div flex items-center justify-center gap-5">
                    <i class="icon icon-edit" matTooltip="Edit" matTooltipPosition="above"
                      (click)="RedirectToEditFacility(element._id);"></i>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="companies">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>Associated Companies
                </th>
                <td mat-cell *matCellDef="let element">
                  <span style="cursor: pointer; color: blue;"
                    (click)="associatedCompaniesDialog(element?.assoc_company_ids)">Details</span>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
          <ngx-ui-loader [loaderId]="matrixLoaderId"></ngx-ui-loader>
        </mat-card-content>
      </mat-tab>
    </mat-tab-group>


    <app-facility-activation-email [currentCompany]="currentCompany" *ngIf="showFacilityActivationEmailComponent"
      (back)="showFacilityActivationEmailComponent=$event"></app-facility-activation-email>
    <ngx-ui-loader loaderId="loader-01"></ngx-ui-loader>
  </mat-card>
</div>