<app-header *ngIf="!isSuperAdmin && currentUser.user_type !== global.USER_TYPE.BILLER"></app-header>
<app-biller-header *ngIf="currentUser.user_type === global.USER_TYPE.BILLER"></app-biller-header>

<main>
    <div [ngClass]="{'container': !isSuperAdmin}">
        <span *ngIf="addView">
            <app-add-charge [params]="params" style="width: 100%;" (closeView)="addView = false">
            </app-add-charge>
        </span>
    </div>
</main>