import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import * as global from '../global';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from "../../../environments/api-url";
import moment from 'moment'
import * as _ from 'lodash';
import { CommonService } from 'src/app/services/common.service';

@Injectable({
    providedIn: 'root',
})
export class AdminNotesService {

    currentUser: any;
    constructor(private httpclient: HttpClient,
        private _commonService: CommonService,
        private _authService: AuthService) {
        this.currentUser = this._authService.currentUser;
    }

    fixDate(v: Date): Date {
        // d.setHours(d.getHours() - d.getTimezoneOffset() / 60);
        // return d;
        const d = new Date();
        d.setFullYear(v.getFullYear());
        d.setMonth(v.getMonth());
        d.setDate(v.getDate());
        return d
    }
    getNotes(uploaded_by_id = null, filter: any = {}, company_id = null) {
        const clonedFilter = _.cloneDeep(filter);
        ['startDate', 'endDate'].forEach(dateProp => {
            const date = clonedFilter.date_of_service[dateProp];
            if (moment.isMoment(date)) {
                const manipulatedDate = date.clone().endOf('day');
                const dateString = this._commonService.convertDateToStringforMoment(manipulatedDate, true);
                clonedFilter.date_of_service[dateProp] = dateString;
            }
        });
        const item = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            uploaded_by_id,
            filter: clonedFilter,
            company_id: company_id
        };

        return this.httpclient.post(global.url + API_URL.NOTE.getNotes, { details: item }).pipe(map((response: any) => {
            
            if (response.status === 200) {

                response.data = response.data.map(note => {
                    let progress_status;
                    if (note.is_published === 'true' && note.is_signed === 'true' && note.is_completed === 'true') {
                        progress_status = 'Uploaded'
                    } else if (note.is_published === 'true' && note.is_signed === 'true' && note.is_completed === 'false') {
                        progress_status = 'Signed'
                    } else if (note.is_published === 'true' && note.is_signed === 'false' && note.is_completed === 'false') {
                        progress_status = 'Unsigned'
                    } else if (note.is_published === 'false' && note.is_signed === 'false' && note.is_completed === 'false') {
                        progress_status = 'Unpublished';
                    }
                    return { ...note, progress_status };
                });
            }
            return response;
        }));
    }
    getNotes_v1(uploaded_by_id = null, filter: any = {}, company_id = null) {
        const clonedFilter = _.cloneDeep(filter);
        clonedFilter.date_of_service.startDate = this._commonService.convertDateToStringforMoment((clonedFilter.date_of_service.startDate).startOf('day'), true);
        clonedFilter.date_of_service.endDate = this._commonService.convertDateToStringforMoment((clonedFilter.date_of_service.endDate).endOf('day'), true);
        const item = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            uploaded_by_id,
            filter: clonedFilter,
            company_id: company_id
        };

        return this.httpclient.post(global.url + API_URL.NOTE.getNotes_v1, { details: item }).pipe(map((response: any) => {
            
            if (response.status === 200) {

                response.data = response.data.map(note => {
                    let progress_status;
                    if (note.is_published === 'true' && note.is_signed === 'true' && note.is_completed === 'true') {
                        progress_status = 'Uploaded'
                    } else if (note.is_published === 'true' && note.is_signed === 'true' && note.is_completed === 'false') {
                        progress_status = 'Signed'
                    } else if (note.is_published === 'true' && note.is_signed === 'false' && note.is_completed === 'false') {
                        progress_status = 'Unsigned'
                    } else if (note.is_published === 'false' && note.is_signed === 'false' && note.is_completed === 'false') {
                        progress_status = 'Unpublished';
                    }
                    return { ...note, progress_status };
                });
            }
            return response;
        }));
    }
    getNotes_single(IDs){
        const item = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            IDs
        };
        return this.httpclient.post(global.url + API_URL.NOTE.getNotes_single, { details: item }).pipe();
    }

    getNotesFullReport(uploaded_by_id = null, filter: any = {}, company_id = null) {
        const clonedFilter = _.cloneDeep(filter);
        clonedFilter.date_of_service.startDate = this._commonService.convertDateToStringforMoment(clonedFilter.date_of_service.startDate.startOf('day'), true);
        clonedFilter.date_of_service.endDate = this._commonService.convertDateToStringforMoment(clonedFilter.date_of_service.endDate.endOf('day'), true);
        const item = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            uploaded_by_id,
            filter: clonedFilter,
            company_id: company_id
        };
        
        return this.httpclient.post(global.url + API_URL.NOTE.getNotesFullReport, { details: item });
    }

    getUserNotes(filter = {}) {
        let item = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            filter: filter
        };
        return this.httpclient.post(global.url + API_URL.NOTE.getUserNotes, { details: item });
    }

    getTranscribersAndProviders() {
        var item = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type
        };

        return this.httpclient
            .post(global.url + API_URL.USER.getTranscribersAndProviders, { details: item }).pipe();
    }

    assignNoteManually(noteId, note_status) {
        const details = {
            ...this._authService.authObject,
            noteId,
            note_status: note_status.status
        }
        return this.httpclient
            .post(global.url + API_URL.NOTE.assignNoteManually, { details: details }).pipe();
    }
    exportGroupedReportAsXLSX(data, company_type, company_id) {
        const headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Headers', 'Content-Length');
        headers.append('Access-Control-Expose-Headers', 'Content-Length');
        headers.append('Content-Type', 'application/octet-stream');

        return this.httpclient.post(global.url + API_URL.NOTE.exportGroupedReportAsXLSX, { data, company_type, company_id },
            {
                responseType: "blob",
                reportProgress: true,
                observe: 'events',
                headers
            });
    }
    exportFullReportAsXLSX(data, company_type, company_id) {
        const headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Headers', 'Content-Length');
        headers.append('Access-Control-Expose-Headers', 'Content-Length');
        headers.append('Content-Type', 'application/octet-stream');

        return this.httpclient.post(global.url + API_URL.NOTE.exportFullReportAsXLSX, { data, company_type, company_id },
            {
                reportProgress: true,
                observe: 'events',
                headers
            });
    }
    generateBatchNotesPDF(data) {
        const headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Headers', 'Content-Length');
        headers.append('Access-Control-Expose-Headers', 'Content-Length');
        headers.append('Content-Type', 'application/octet-stream');
        return this.httpclient.post(global.url + API_URL.NOTE.generateBatchNotesPDF,{details: data}, {
            responseType: "blob",
            reportProgress: true,
            observe: 'events',
            headers
        });
    }
}
