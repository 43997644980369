import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {IActivitiesOfDailyLivingResponse} from '../../../../services/pccAPIInterfaces'

@Component({
  selector: 'app-activities-of-daily-living-response',
  templateUrl: './activities-of-daily-living-response.component.html',
  styleUrls: ['./activities-of-daily-living-response.component.css']
})
export class ActivitiesOfDailyLivingResponseComponent implements OnInit {

  @Input('data') data: IActivitiesOfDailyLivingResponse[];
  dataSource;
  displayedColumns = [
      "description",
      "recordedBy",
      "recordedDate",
      "value",
    
  ];

  // view: ''|'lastAdministration' = '';
  // viewData;

  constructor() { }

  ngOnInit(): void {

    this.dataSource = new MatTableDataSource(this.data);
  }

  // changeView(view, viewData) {
  //   this.view = view,
  //   this.viewData = viewData;
  // }
}
