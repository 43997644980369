import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TherapyService } from '../therapy.service';

@Component({
  selector: 'app-goal-status-report',
  templateUrl: './goal-status-report.component.html',
  styleUrls: ['./goal-status-report.component.css'],
})
export class GoalStatusReportComponent implements OnInit {
  @Input('patient') patient: any;
  patientGoalStatusReport: any;
  displayedColumnsPT: string[] = ['dos', 'primary_med', 'treatment_dx', 'transfers', 'bed_mobility','gait'];
  displayedColumnsOT: string[] = ['dos', 'primary_med', 'treatment_dx', 'lb_dressing', 'ub_dressing', 'toileting'];
  dataSource: any = new MatTableDataSource([])
  dataSourcePT: any = new MatTableDataSource([])
  isLoading = true;
  constructor(private therapyService: TherapyService) { }

  async ngOnInit() {
    await this.getGoalStatusReport();
  }

  async getGoalStatusReport() {
    let patientName = this.patient.last_name + ', ' + this.patient.first_name.trim();
    this.therapyService.getGoalStatusReport(patientName).subscribe(async (res: any) => {
      if (res.status == 200) {
        this.patientGoalStatusReport = res.data.records;
        await this.normalizeGoalStatusReport(this.patientGoalStatusReport);
      } else {
        console.log('something went wrong');
        this.isLoading = false;
      }
    });
  }

  async normalizeGoalStatusReport(data: any) {
    let OccupationalTherapyTable = [];
    let PhysicalTherapyTable = [];
    for (let recordsIndex = 0; recordsIndex < data.length; recordsIndex++) {
      const element = data[recordsIndex];

      //occupational table

      for (let ot = 0; ot < element.ot.length; ot++) {
        const ots = element.ot[ot];
        for (let otPairs = 0; otPairs < ots.pairs.length; otPairs++) {
          const otPair = ots.pairs[otPairs];
          if (OccupationalTherapyTable.find(
              t => t.dos == ots.data &&
              (t.primary_med == ots.primary_med || t.primary_med == '') &&
              (t.primary_med_description == ots.primary_med_description || t.primary_med_description == '') &&
              (t.treatment == ots.treatment || t.treatment == '') &&
              (t.treatment_description == ots.treatment_description || t.treatment_description == '') &&
              (t['ub_dressing'] == otPair.value ? otPair.key == 'ub_dressing' : false || t['ub_dressing'] == '') &&
              (t['lb_dressing'] == otPair.value ? otPair.key == 'lb_dressing' : false || t['lb_dressing'] == '') &&
              (t['toileting'] == otPair.value ? otPair.key == 'toileting' : false || t['toileting'] == ''))) {
            console.log('existed');
          } else {
            OccupationalTherapyTable.push({
              dos: ots.data,
              primary_med: ots.primary_med ? ots.primary_med : '',
              primary_med_description: ots.primary_med_description ? ots.primary_med_description : '',
              treatment: ots.treatment ? ots.treatment : '',
              treatment_description: ots.treatment_description ? ots.treatment_description : '',
              'ub_dressing': otPair.key == 'ub_dressing' ? otPair.value : '',
              'lb_dressing': otPair.key == 'lb_dressing' ? otPair.value : '',
              'toileting': otPair.key == 'toileting' ? otPair.value : ''
            });
          }
        }
      }

      //physical table
      for (let pt = 0; pt < element.pt.length; pt++) {
        const pts = element.pt[pt];
        for (let ptPairs = 0; ptPairs < pts.pairs.length; ptPairs++) {
          const ptPair = pts.pairs[ptPairs];
          if (PhysicalTherapyTable.find(
            t => t.dos == pts.data &&
              (t.primary_med == pts.primary_med || t.primary_med == '') &&
              (t.primary_med_description == pts.primary_med_description || t.primary_med_description == '') &&
              (t.treatment_description == pts.treatment_description || t.treatment_description == '') &&
              (t.treatment == pts.treatment || t.treatment == '') &&
              (t.transfers == ptPair.value ? ptPair.key == 'transfers' : false || t.transfers == '') &&
              (t.bed_mobility == ptPair.value ? ptPair.key == 'bed_mobility' : false || t.bed_mobility == '') &&
              (t.gait == ptPair.value ? ptPair.key == 'gait' : false || t.gait == ''))) {
            console.log('existed');
          } else {
            PhysicalTherapyTable.push({
              dos: pts.data,
              primary_med: pts.primary_med ? pts.primary_med : '',
              primary_med_description: pts.primary_med_description ? pts.primary_med_description : '',
              treatment: pts.treatment ? pts.treatment : '',
              treatment_description: pts.treatment_description ? pts.treatment_description : '',
              'transfers': ptPair.key == 'transfers' ? ptPair.value : '',
              'bed_mobility': ptPair.key == 'bed_mobility' ? ptPair.value : '',
              'gait': ptPair.key == 'gait' ? ptPair.value : '',
            });
          }
        }
      }
    }
    this.dataSource.data = this.mergeDuplicatesOT(OccupationalTherapyTable);
    this.dataSourcePT.data = this.mergeDuplicatesPT(PhysicalTherapyTable);
    this.isLoading = false;
  }

  getTreatmentDescription(element) {
    return element.treatment_description;
  }

  getPrimaryMedDescription(element) {
    return element.primary_med_description;
  }

  getTransfers(element) {
    return element.transfers;
  }
  
  getBedMobility(element) {
    return element.bed_mobility;
  }

  getGait(element) {
    return element.gait;
  }

  getlbDressing(element) {
    return element.lb_dressing;
  }

  getToileting(element) {
    return element.toileting;
  }

  getUbDressing(element) {
    return element.ub_dressing;
  }

  mergeDuplicatesOT(data) {
    let Unique = [];
    for (let object of data) {
      let index = Unique.findIndex(e => e.dos == object.dos);
      if (index < 0) {
        Unique.push(object);
      } else {
        let obj = {
          dos: object.dos, physician: object.physician, room_number: object.room_number,
          primary_med: object.primary_med.trim(),
          primary_med_description: object.primary_med_description.trim(),
          treatment: object.treatment.trim(),
          treatment_description: object.treatment_description.trim(),
          ub_dressing: (object.ub_dressing + ' ' + Unique[index].ub_dressing).trim(),
          lb_dressing: (object.lb_dressing + ' ' + Unique[index].lb_dressing).trim(),
          toileting: (object.toileting + ' ' + Unique[index].toileting).trim()
        };
        Unique[index] = obj;
      }
    }
    return Unique;
  }

  mergeDuplicatesPT(data) {
    let Unique = [];
    for (let object of data) {
      let index = Unique.findIndex(e => e.dos == object.dos);
      if (index < 0) {
        Unique.push(object);
      } else {
        let obj = {
          dos: object.dos, physician: object.physician, room_number: object.room_number,
          transfers: (object.transfers + ' ' + Unique[index].transfers).trim(),
          primary_med: object.primary_med.trim(),
          primary_med_description: object.primary_med_description.trim(),
          treatment: object.treatment.trim(),
          treatment_description: object.treatment_description.trim(),
          bed_mobility: (object.bed_mobility + ' ' + Unique[index].bed_mobility).trim(),
          gait: (object.gait + ' ' + Unique[index].gait).trim()
        };
        Unique[index] = obj;
      }
    }
    return Unique;
  }
}
