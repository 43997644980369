import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { WoundService } from "src/app/services/wound.service";

@Component({
    selector: "app-add-new-cpt-code",
    templateUrl: "./add-new-cpt-code.component.html",
    styleUrls: ["./add-new-cpt-code.component.css"]
})
export class AddNewCptCodeComponent implements OnInit {
    @Input() company_id: string;
    @Output() addNewCptandUWHCode = new EventEmitter<any>();
    showForm = true;
    newCPTmodel = {
        conceptId: "",
        code: "",
        clinicianDescriptorId: "",
        title: "",
        description: "",
        company_id: null
    };
    loaderId = 'app-add-new-cpt-code';
    constructor(
      private toastr: ToastrService,
      private loader: NgxUiLoaderService,
      private _woundService: WoundService
    ) {}

    ngOnInit(): void {}
    async handleSubmit(e) {
      this.loader.startLoader(this.loaderId);
      let toastrId = this.toastr.info("Adding New Code", "Please Wait...",{disableTimeOut: true});
      try {
        this.newCPTmodel.company_id = this.company_id;
        let response:any = await this._woundService.addNewUWHCodewithCPTCode(this.newCPTmodel).toPromise();
        if(response.status == 200){
          this.toastr.clear(toastrId.toastId);
          this.toastr.success("Code Added Succesfully", "Success");
          this.loader.stopLoader(this.loaderId);
          this.addNewCptandUWHCode.emit({cptCode: response.cptcodeData, uwhCode: response.uwhcodeData})
        }else{
          this.toastr.clear(toastrId.toastId);
          this.loader.stopLoader(this.loaderId);
          this.toastr.error(response.message, 'Error');
        }
      }catch(err){
        this.toastr.clear(toastrId.toastId);
        this.loader.stopLoader(this.loaderId);
        this.toastr.error('Something went wrong', 'Error');
      }
    }
    keyPressAlphanumeric(event) {
        var inp = String.fromCharCode(event.keyCode);

        if (/[a-zA-Z0-9\s/-_-.-]/.test(inp)) {
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }
    keyPressNumbers(event) {
        var charCode = event.which ? event.which : event.keyCode;
        // Only Numbers 0-9
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
            return false;
        } else {
            return true;
        }
    }
    clearDetails() {
        this.newCPTmodel = {
            conceptId: "",
            code: "",
            clinicianDescriptorId: "",
            title: "",
            description: "",
            company_id: null
        };
    }
}
