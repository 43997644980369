import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Consent } from '../consent.model';
import { ConsentService } from '../service/consent.service';
import { SignaturePad } from 'angular-signature-pad-v2';
@Component({
  selector: 'app-aggrement-dialog',
  templateUrl: './aggrement-dialog.component.html',
  styleUrls: ['./aggrement-dialog.component.css']
})
export class AggrementDialogComponent implements OnInit {
  loaderId = 'app-aggrement-dialog';
  consents: Consent[];
  selectedConsentForm: Consent = {
    _id: "",
    description: '',
    title: '',
  };
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  signaturePadOptions: any = {
    // minWidth: 0.5,
    maxWidth: 2,
    canvasWidth: 768,
    canvasHeight: 203,
    penColor: "blue"
  };
  dialogSubscription: any;
  censusData: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _toastr: ToastrService,
    private loader: NgxUiLoaderService,
    private dialogRef: MatDialogRef<AggrementDialogComponent>,
    private _consentService: ConsentService,

  ) { 
    if(data) {
      this.censusData = {
        ...data
      };
    }
  }

  ngOnInit(): void {
    console.log("this.signaturePad", this.signaturePad);
    this.getConsents();
  }

  ngOnDestroy(): void {
    if (this.dialogSubscription) {
      this.dialogSubscription.unsubscribe();
    }
  }

  // ngAfterViewInit() {
  //   this.signaturePad.clear();
  // }

  drawComplete() {
    console.log(this.signaturePad.toDataURL());
  }

  getConsents() {
    this.loader.startLoader(this.loaderId);
    const filter = {
      is_deleted: false
    };
    const project = {
      title: 1,
      description: 1,
    };
    this._consentService.getConsentForms(filter, project).subscribe({
      next: (response: any) => {
        if (response.status === 200) {
          this.consents = response.data;
          this.selectDefault();
        }
        else if (response.status === 500) {
          this._toastr.error(response.message, "ERROR");
          this.loader.stopLoader(this.loaderId);
        }
      }, error: error => {
        this._toastr.error(error, "ERROR");
        this.loader.stopLoader(this.loaderId);
      }
    });
    this.loader.stopLoader(this.loaderId);
  }
  onSelectOption($event, consent) {
    if ($event.isUserInput) {
      this.selectedConsentForm = consent;
    }
  }

  selectDefault() {
    if (Array.isArray(this.consents) && this.consents.length > 0) {
      this.selectedConsentForm = this.consents[0];
    }
  }
  submitForm() {
    this.loader.startLoader(this.loaderId);
    const payload = {
      ...this.selectedConsentForm,
      ...this.censusData,
      signatureAsDataURL: this.signaturePad.toDataURL()
    }
    this.dialogSubscription = this._consentService.createConsentFormAuditAndUpload(payload).subscribe({
      next: (response: any) => {
        if (response.status === 200) {
          this._toastr.success("Consent form signed", "Success");
          this.dialogRef.close(response.data);
        }
        else if (response.status === 500) {
          this._toastr.error(response.message, "ERROR");
        }
      }, error: error => {
        this._toastr.error(error, "ERROR");
        this.loader.stopLoader(this.loaderId);
      }
    });
    this.loader.stopLoader(this.loaderId);
  }
}
