<div class="wrapper" >

  <div *ngIf="mipNumberSix" style="display: flex; align-items: center; margin-bottom: 10px; width: 100%;">
    <input
      type="text"
      placeholder="CHA2DS2-VASc Score : "
      [(ngModel)]="chads_scrore"
      (keyup.enter)="scoreChange()"
      style="padding: 8px; border: 1px solid #ccc; border-radius: 4px; margin-right: 8px; width: 100%;"
    />
    <button
      (click)="scoreChange()"
      style="background-color: #4CAF50; color: white; border: none; padding: 5px 10px; border-radius: 4px; cursor: pointer;"
    >
      Enter
    </button>
  </div>
  
  
    <ul *ngIf="questions.length" class="questions-list">
      <li
        class="questions-list-item"
        *ngFor="let question of questions; let index = index"
        (click)="handleQuestionSelect({question: question})"
      >
      <span
      [class.negative-answer]="isNegativeCode(question.answer)"
      [class.answer_selected]="isSelectedQuestion(question)" >
          {{ question.question }} ({{question.answer}})
      </span>
      </li>
      <li>
        <div *ngIf="otherClicked" style="display: flex; align-items: center; margin-bottom: 10px;">
          <input
            type="text"
            placeholder="Other Reason"
            [(ngModel)]="questionInput"
            (keyup.enter)="handleQuestionInput()"
            style="padding: 8px; border: 1px solid #ccc; border-radius: 4px; margin-right: 8px; flex-grow: 1;"
          />
          <button
            (click)="handleQuestionInput()"
            style="background-color: #4CAF50; color: white; border: none; padding: 4px 8px; border-radius: 4px; cursor: pointer;"
          >
            <mat-icon style="vertical-align: middle;">add</mat-icon>
          </button>
        </div>
      </li>
  
    </ul>
  
  
  
    <!-- <div *ngIf="answer">Answer: {{ showAnswer() }}</div> -->
  </div>
  
  <!-- <app-mips-questionnaire-126
      [ngStyle]="{
        display: measure.quality_number == 126 ? 'block' : 'none'
      }"
      [measure]="measure"
      (onAnswerCalculation)="handleQuestionSelect($event)"></app-mips-questionnaire-126>
  <app-mips-questionnaire-181
      [ngStyle]="{
        display: measure.quality_number == 181 ? 'block' : 'none'
      }"
      [measure]="measure"
      (onAnswerCalculation)="handleQuestionSelect($event)"></app-mips-questionnaire-181> -->
  
