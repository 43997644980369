<form class="patient-rounding-sheet-form flex flex-col gap-4" style="margin:auto" 
(submit)="handleSubmit($event)">
  <h3>Create Patient Census</h3>

  <div *ngIf="date_obj" class="flex flex-row gap-1">
    <mat-form-field style="width: 33%; color: black !important;">
      <mat-label>Select Year *</mat-label>
      <input disabled matInput placeholder="Search" name="year" [(ngModel)]="date_obj.year" 
      style="height: 20px;padding-left: 5px;">
    </mat-form-field>

    <mat-form-field style="width: 33%; color: black !important;">
      <mat-label>Select Month *</mat-label>
      <input disabled matInput placeholder="Search" name="month" [(ngModel)]="date_obj.month" 
      style="height: 20px;padding-left: 5px;">
    </mat-form-field>

    <mat-form-field style="width: 33%; color: black !important;">
      <mat-label>Select Day *</mat-label>
      <input disabled matInput placeholder="Search" name="day" [(ngModel)]="date_obj.day" 
      style="height: 20px;padding-left: 5px;">
    </mat-form-field>
  </div>




  <mat-form-field class="flex">
    <input matInput type="text" name="name" placeholder="Name" [(ngModel)]="name" required>
    <mat-error *ngIf="!name">Please enter name</mat-error>
  </mat-form-field>
  <mat-form-field>


    <mat-select name="facility" required [(ngModel)]="facility" (ngModelChange)="fetchCensusList($event)" 
    placeholder="Facility">
      <mat-option *ngFor="let facility of facilities" value="{{facility._id}}">
        <app-facility-title [facility]="facility"></app-facility-title>
      </mat-option>

    </mat-select>
    <mat-error *ngIf="!facility">Please select facility </mat-error>
  </mat-form-field>

  <div class="flex items-baseline gap-2">
    <mat-checkbox name="video" #video="ngModel" [(ngModel)]="isCopyPatients">
      Copy patients from existing Census
    </mat-checkbox>
    &nbsp;&nbsp;
    <div class="flex flex-col">
      <mat-form-field class="flex" [ngStyle]="{display: isCopyPatients ? 'block' : 'none'}">
        <mat-label *ngIf="currentUser.user_type !== global.USER_TYPE.SNF_WC_NURSE">Provider</mat-label>
        <mat-label *ngIf="currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE">SNF WC Nurse</mat-label>
        <mat-select name="provider" required [(ngModel)]="selected" #provider 
        (ngModelChange)="handleCopyPatients($event)">
          <mat-option *ngIf="currentUser.user_type===global.USER_TYPE.DOCTOR && !companySide" [value]="currentUser._id">
            {{currentUser.first_name}} {{currentUser.last_name}}
          </mat-option>
          <mat-option 
          *ngIf="(currentUser.user_type===global.USER_TYPE.MEDICAL_ASSISTANT || currentUser.user_type===global.USER_TYPE.TRANSCRIBER || companySide) && currentProvider" 
          [value]="currentProvider._id">
            {{currentProvider?.first_name}} {{currentProvider?.last_name}}
          </mat-option>
          <mat-option *ngFor="let provider of providers" [value]="provider._id">
            {{provider.first_name}} {{provider.last_name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="!provider">Please select Provider </mat-error>
      </mat-form-field>
    </div>


    <div class="flex flex-col">
      <mat-form-field class="flex-grow" [ngStyle]="{display: isCopyPatients ? 'block' : 'none'}">
        <mat-select name="patientRoundingSheet" [(ngModel)]="patientRoundingSheet" placeholder="Patient Census" 
        #censusSelect>
        <mat-option *ngFor="let patientRoundingSheet of filteredRoundingSheets()" [value]="patientRoundingSheet">
            {{patientRoundingSheet.name}} {{getCensusDate(patientRoundingSheet)}}
          </mat-option>

        </mat-select>
        <mat-error *ngIf="!facility">Please select facility </mat-error>
        <mat-error *ngIf="filteredRoundingSheets().length === 0">No Census in this facility</mat-error>
      </mat-form-field>
    </div>

  </div>
  <div *ngIf="isCopyPatients" class="flex items-baseline gap-2">
    <div class="flex" style="width: 40%;"></div>
    <div class="flex">
      <mat-checkbox name="voidcheck" #voidcheck="ngModel" [(ngModel)]="isvoidcheck">
        Void Census
      </mat-checkbox>
    </div>
    <div class="flex" *ngIf="carryChargeStatus && currentUser.user_type != global.USER_TYPE.SNF_WC_NURSE">
      <mat-checkbox name="chargecheck" #chargecheck="ngModel" [(ngModel)]="ischargecheck">
        Copy Charges
      </mat-checkbox>
    </div>
  </div>

  <div class="flex justify-baseline gap-2">
    <mat-checkbox *ngIf="currentUser.portal_type === global.PORTAL_TYPE.NON_WC" class="flex" name="Recurrence" #Recurrence="ngModel" [(ngModel)]="isRecurrentCensus" (change)="showDialog($event)">
      Census Recurrence
    </mat-checkbox>
  </div>

  <div class="flex justify-end items-center">
    <button mat-raised-button class="dn-button-primary" type="submit">Create</button>

  </div>
</form>
<div class="top-right-close-btn">
  <button mat-mini-fab mat-dialog-close>
    <mat-icon>cancel</mat-icon>
  </button>
</div>