import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as global from '../global';
import { API_URL } from "../../../environments/api-url";
import { AuthService } from "../../services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class CptIcdAssociationService {
  currentUser: any;
  constructor(private httpclient: HttpClient, private _authService: AuthService) {
    this.currentUser = this._authService.currentUser;
  }

  getAllCPTs() {
    return this.httpclient.post(global.url + API_URL.CPT.getAllCPTs, {}).pipe();
  }
  getICDs(details) {
    return this.httpclient.post(global.url + API_URL.CPT.getICDs, {details: details}).pipe();
  }
  createRelationship(cpt_id,icd_code) {
    var details = {
      cptID: cpt_id,
      icdCode: icd_code,
      company_id: this.currentUser.company_id
    }
    return this.httpclient.post(global.url + API_URL.CPT.addRelation, {details: details}).pipe();
  }
  removeRelation(cpt_id,icd_id){
    var details = {
      cptID: cpt_id,
      icdID: icd_id,
      company_id: this.currentUser.company_id
    };
    return this.httpclient.post(global.url + API_URL.CPT.removeRelation, {details: details}).pipe();
  }
}
