import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-wound-filename-popup',
  templateUrl: './wound-filename-popup.component.html',
  styleUrls: ['./wound-filename-popup.component.css']
})
export class WoundFilenamePopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<WoundFilenamePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  filename = "Wound Assessment Report";
  ngOnInit(): void {
    if (this.data) {
      this.filename = this.data.filename;
    }
  }
  checkDisable() {
    if(this.filename === ""){
      return true;
    }else{
      return false;
    }
  }
  uploadFiletoPCC(){
    this.dialogRef.close({
      filename: this.filename
    });
  }
}
