<h2 mat-dialog-title>Add Appointment Type</h2>
<div mat-dialog-content class="dialog-content">
  <mat-form-field appearance="fill" class="dialog-input">
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="appointmentTypeData.name" placeholder="Enter name">
  </mat-form-field>
  <mat-form-field appearance="fill" class="dialog-input">
    <mat-label>Duration (minutes)</mat-label>
    <input matInput type="number" [(ngModel)]="appointmentTypeData.duration" placeholder="Enter duration" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  min="0">
  </mat-form-field>
  <div>
    <span>Select color: </span>
    <input
          style="width: 35px; border: 1px solid transparent; border-radius: 52px; height: 34px; cursor: pointer; margin-left: 5px;"
          [ngStyle]="{ 'border-color': color }"
          [(colorPicker)]="color"   [cpCancelButton]="true"
          [cpCancelButtonClass]= "'dn-button-secondary btn-xs'"  [cpOKButton]="true"
          [cpSaveClickOutside]="false"
          [cpOKButtonClass]= "'dn-button-primary btn-xs'" [style.background]="color" [cpToggle]='false' (colorPickerChange)='onColorChange(color)'
           />
      <color-picker #colorPicker [cpOutputFormat]="'hex'" 
          (colorPickerCancel)="closeColorPicker()"></color-picker>
  </div>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-button (click)="onCancelClick()">Cancel</button>
  <button mat-button color="primary" (click)="onSaveClick()">Save</button>
</div>