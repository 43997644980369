<h2 mat-dialog-title>Add Visit Status</h2>
<div mat-dialog-content class="dialog-content">
  <mat-form-field appearance="fill" class="dialog-input">
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="visitStatusData.name" placeholder="Enter name">
  </mat-form-field>
  <div>
    <span>Select color: </span>
    <input
          style="width: 35px; border: 1px solid transparent; border-radius: 52px; height: 34px; cursor: pointer; margin-left: 5px;"
          [ngStyle]="{ 'border-color': visitStatusData.color }"
          [(colorPicker)]="visitStatusData.color"   [cpCancelButton]="true"
          [cpCancelButtonClass]= "'dn-button-secondary btn-xs'"  [cpOKButton]="true"
          [cpSaveClickOutside]="false"
          [cpOKButtonClass]= "'dn-button-primary btn-xs'" [style.background]="visitStatusData.color" [cpToggle]='false' (colorPickerChange)='onColorChange(visitStatusData.color)'
           />
      <color-picker #colorPicker [cpOutputFormat]="'hex'" 
          (colorPickerCancel)="closeColorPicker()"></color-picker>
  </div>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-button (click)="onCancelClick()">Cancel</button>
  <button mat-button color="primary" (click)="onSaveClick()">Save</button>
</div>