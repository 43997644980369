<div class="container flex flex-col gap-2">

    <div class="filter-form white-rounded-rect">
        <div class="flex justify-between items-center">

            <div class="flex justify-center gap-2">
                <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
                    Back
                </button>

                <h2 style="color:#000;margin-top:0;margin-bottom:0">IPA Report</h2>
            </div>

            <div class="filter-controls flex gap-2">
                <button mat-raised-button class="dn-button-primary" (click)="downloadIpaReport()">Export
                    As XLSX
                </button>
            </div>
        </div>




        <div class="flex flex-row justify-start items-center gap-2">

            <div class="flex justify-start items-baseline">
                
                <mat-form-field appearance="fill" style="z-index: 10000;">
                    <input matInput type="text" ngxDaterangepickerMd matTooltip="Date of Creation"
                        [(ngModel)]="filter.reportDateFilter" (datesUpdated)="applyDateFilterReport($event)"
                        class="mat-like-input" [ranges]="preDateRanges" [alwaysShowCalendars]="true"
                        [linkedCalendars]="true" [showClearButton]="false" />
                </mat-form-field>
            </div>

            <div class="flex justify-start items-baseline">
                <mat-form-field *ngIf="currentUser.product_owner" appearance="fill">
                    <mat-label>Practices</mat-label>
                    <mat-select value="true" [ngModel]="filter.company_id" (selectionChange)="applyCompanyFilter($event)">
                        <mat-option *ngFor="let company of companies" value="{{company._id}}">{{company.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <div class="flex justify-start items-baseline">
                <mat-form-field>
                    <mat-label>Select Facility</mat-label>
        
                    <mat-select [(value)]="selected" name="facility_id" [(ngModel)]="filter.facility_id"
                        (selectionChange)="applyFacilityChange($event);">
                        <input matInput placeholder="Search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
                            style="color:#000;height: 40px;padding-left: 5px;" />
                        <mat-option *ngFor="let facility of facilities | searchFacility: searchText" value="{{facility._id}}">
                            <app-facility-title [facility]="facility"></app-facility-title>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <div class="flex justify-start items-baseline">
                <mat-form-field>
                    <mat-label>Select Provider</mat-label>
                    <mat-select (selectionChange)="applyProviderChange($event);" [(ngModel)]="filter.provider_id">
                        <mat-option *ngFor="let pro of providers" value="{{pro._id}}">{{pro.full_name ?
                            pro.full_name : getFullName(pro)}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </div>

    </div>
    <div class="br-spacing"></div>

    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="ipa">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>IPA/Insurance</th>
            <td mat-cell *matCellDef="let element">
                {{element.insurance}}
            </td>
        </ng-container>

        <ng-container matColumnDef="cpt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CPT Code</th>
            <td mat-cell *matCellDef="let element">
                {{element.code}}
            </td>
        </ng-container>

        <ng-container matColumnDef="encounter">
            <th mat-header-cell *matHeaderCellDef> Total for Selected Timeframe </th>
            <td mat-cell *matCellDef="let element ; let i = index;">
                {{element.encounter}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

</div>