<mat-card class="pcc-event-notifications-panel ">
  <mat-card-content>
    <div style="text-align: center; font-size: 11px">{{ title }}</div>
    <ul class="notification-list flex flex-col gap-2">
    <ng-container *ngFor="let notification of pccEventNotifications">
      <li
        class="notification-item"
        style="min-width: 100px"
        [ngStyle]="applyColorOnPccEventTypes(notification.eventType)"
      >
        <div>
          <p style="line-height: 0.5; font-weight: bold;text-align: center;text-transform: capitalize;">
            {{notification.eventType.split(".")[1]}}</p>
          {{ notification.patient.lastName }},
          {{ notification.patient.firstName }}
        </div>
        <div class="flex justify-between items-center">
          <div>
            <div>{{ notification.patient.admissionDate | date: global.date_format }} |
              {{ notification.patient.gender | slice: 0:1 }}</div>
            <div  *ngIf="notification.adtRecord">
              {{ notification.adtRecord.payerName }}
            </div>
          </div>
          <i
            *ngIf="!notification.isImported"
            class="icon icon-import"
            matTooltip="Import"
            matTooltipPosition="above"
            (click)="importPCCPatient(notification)"
            style="width: 15px;height: 15px;"
          ></i>
        </div>
      </li>
    </ng-container>
    </ul>
  </mat-card-content>
</mat-card>
