<form (ngSubmit)="submitForm()">

    <mat-form-field style="width: 100%">
        <mat-label>Title</mat-label>
        <input matInput type="text" name="title" #title="ngModel" [(ngModel)]="model.title">
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Select Query Type</mat-label>
        <mat-select name="queryType" [(ngModel)]="model.queryType" #queryType>
            <mat-option value="find">Find
            </mat-option>
            <mat-option value="aggregate">Aggregate
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Select Display Type</mat-label>
        <mat-select name="displayType" [(ngModel)]="model.displayType" #displayType>
            <mat-option value="table">Table
            </mat-option>
            <mat-option value="graph">Graph
            </mat-option>
            <mat-option value="chart">Chart
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field style="width: 100%">
        <mat-label>Query</mat-label>
        <textarea matInput name="query" placeholder="Query" #query="ngModel" rows="5" style="width: 100%;"
            [(ngModel)]="model.query"></textarea>
    </mat-form-field>


    <div class="flex items-center justify-end gap-7">
        <button mat-raised-button class="dn-button-secondary" mat-dialog-close type="button">Cancel</button>
        <button mat-raised-button type="button" class="dn-button-primary" type="submit">Save</button>
    </div>
</form>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>