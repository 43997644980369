import {Injectable} from '@angular/core';
import * as global from '../includes/global';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {API_URL} from "../../environments/api-url";

@Injectable({
    providedIn: 'root'
})
export class RolesService {
    private currentUser: any;
    private userInfo: any;

    constructor(private httpclient: HttpClient,
                private _authService: AuthService) {
        this.currentUser = this._authService.currentUser;
        this.userInfo = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type
        }
    }

    getRoles() {
        return this.httpclient.post(global.url + API_URL.USER.getRoles, {details: this.userInfo}).pipe();
    }

    assignRole(role_id, user_id) {
        const details = {
            ...this.userInfo,
            role_id,
            user_id
        };
        return this.httpclient.post(global.url + API_URL.USER.assignRole, {details: details}).pipe();
    }

    unassignRole(role_id, user_id) {
        const details = {
            ...this.userInfo,
            role_id,
            user_id
        };
        return this.httpclient.post(global.url + API_URL.USER.unassignRole, {details: details}).pipe();
    }
}
