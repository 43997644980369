<h3>
    Note
  </h3>
  
  <div class="flex flex-col gap-2" style="font-size: 12px;" *ngIf="note">
  
  
    <div class="flex flex-col">
      <div>
        <u><strong>Facility: </strong></u>
        <span>{{note?.facility_id_ref?.title}}</span>
      </div>
      <div>
        <u><strong>Provider: </strong></u>
        <span>{{note?.doctor_id_ref?.first_name}} {{note?.doctor_id_ref?.last_name}}, {{note?.doctor_id_ref?.title}}</span>
      </div>
      <div>
        <u><strong>Patient: </strong></u>
        <span>{{note?.patient_id_ref?.name}}</span>
      </div>
      <div>
        <u><strong>Date of Service: </strong></u>
        <span *ngIf="!note.date_obj">{{applyTimezone(note.filterServiceDate) | date: 'MM/dd/yyyy hh:mm a'}}</span>
        <span *ngIf="note.date_obj">{{getDOS()}}</span>
      </div>
      <div>
        <u><strong>Title: </strong></u>
        <span>{{note?.transcribtion_title | uppercase}}</span>
      </div>
    
    <div>
      <u><strong>Note Details: </strong></u>
      <div style="max-height: 40vh;overflow: auto;" [innerHTML]="note.transcribtion_text"></div>
    </div>
  
    <div *ngIf="addendums?.length" style="max-height: 13vh;overflow: auto;" >
      
      <div>Addendums:</div>
      <ul class="addendum-list" >
        <li class="addendum-list-item" *ngFor="let addendum of addendums">
          <div class="addendum-date">Date: {{getAddendumDate(addendum)}}</div>
          <pre class="addendum-text" [innerHTML]="addendum.text"></pre>
        </li>
      </ul>
  
    </div>
  
    </div>
  
    <div class="flex justify-between items-center">
      <div>
        <button mat-icon-button (click)="generatePDFNote()" *ngIf="note.is_signed === 'true'">
          <img src="../../../../assets/icons/pdf.svg" alt="PDF Icon" style="width: 24px;" />
        </button>
      </div>
  
      <div *ngIf="currentUser.user_type != 'nurse' && currentUser.user_type != global.USER_TYPE.WOUND_NURSE" class="flex gap-5">
        <button mat-raised-button class="dn-button-primary" [mat-dialog-close]="{status:true,id:note._id}" [routerLink]="routerLink" *ngIf="isSameCompany">
          <ng-container>
            View Note
          </ng-container>
        </button>
        <button mat-raised-button class="dn-button-secondary" [mat-dialog-close]="{status:false,id:note._id}">Close</button>
      </div>
    </div>
    <div class="top-right-close-btn">
        <button mat-mini-fab [mat-dialog-close]="{status:false,id:note_id}" ><mat-icon >cancel</mat-icon></button>
      </div>
  </div>