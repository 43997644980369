import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment'
import { Hl7ChargesService } from './hl7-charges.service';
import { lastValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import  { CommonService } from 'src/app/services/common.service';

interface PracticeId {
  id: string;
  viewId: string;
}

@Component({
  selector: 'app-hl7-charges',
  templateUrl: './hl7-charges.component.html',
  styleUrls: ['./hl7-charges.component.css']
})


export class Hl7ChargesComponent implements OnInit {
  selectedId: any;
  date: any;
  @Input() company;
  current_date = moment(new Date());
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
};

  constructor( 
    private hl7ChargesService: Hl7ChargesService,
    private toastr: ToastrService,
    private _commonService: CommonService
    ) { }

  ids: PracticeId[] = [
    {id: '0001', viewId: '0001'},
    {id: '0002', viewId: '0002'},
    {id: '0003', viewId: '0003'},
    {id: '0004', viewId: '0004'},
    {id: '0005', viewId: '0005'}
  ];

  ngOnInit(): void {

  }


  async onSubmit(){
    const startDate = moment(this.date.startDate).format('MM/DD/YYYY');
    const endDate = moment(this.date.endDate).format('MM/DD/YYYY');
    this.date.startDate = startDate;
    this.date.endDate = endDate;
    // console.log("aaaaaaaaaaaaaaaaaa: ", this.selectedId, this.date)
    let infoToast =  this.toastr.info('Uploading Charges data in HL7 format...', 'Please wait', {
      disableTimeOut: true,
    })
    let res: any = await lastValueFrom(this.hl7ChargesService.saveFomValues(this.date, this.selectedId) as any)
    // .subscribe((res)=>{
      if(res.status == 200) {
        console.log("ressssssssss ; ", res)
      }else {
        console.log('something went wrong');
      }
      this.toastr.clear(infoToast.toastId)
    // }
    // );
  }



  changeServiceDate(event) {

    if (event.startDate && event.endDate) {
      this.date = {
          startDate: null,
          endDate: null
      }
      this.date.startDate = event.startDate;
      this.date.endDate = event.endDate;
      let changeDate= {
          startDate: event.startDate,
          endDate: event.endDate
        }
        localStorage.setItem('hl7Charge:date', JSON.stringify(changeDate));
      const noOfDays = this._commonService.getDifferenceOfDates(event.startDate, event.endDate,'days');
      if (noOfDays > 30) {
          this.date.endDate = moment(this.date?.startDate).add(29, "days").startOf('day');

          setTimeout(() => {
          }, 300)
      }
  }




}

}
