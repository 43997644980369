import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CptIcdAssociationService } from './cpt-icd-association.service';
import { ElasticSeachService } from 'src/app/services/elasticSearch.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as global from "src/app/includes/global";
@Component({
  selector: 'app-cpt-icd-association',
  templateUrl: './cpt-icd-association.component.html',
  styleUrls: ['./cpt-icd-association.component.css']
})
export class CptIcdAssociationComponent implements OnInit {
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['cpt-code', 'icd-code', 'search'];
  loaderId = "app-cpt-icd-association";
  icdCodeList: any = [];
  cptCodes: any;
  searchText: any;
  diagnoseFilter = '';
  filter: any;
  icdLoaded = false;
  // filterFormGroup: FormGroup;
  timeoutId: any;
  constructor(
    private _cpticd: CptIcdAssociationService,
    private _search: ElasticSeachService,
    private loader: NgxUiLoaderService,
    private _toaster: ToastrService
  ) { }

  ngOnInit(): void {
    // this.filterFormGroup = new FormGroup({
    //   icdCodeSearch: new FormControl(''),
    // });
    this.loader.startLoader(this.loaderId);
    this.initCPTCodes();
    this.getAllICDs();
    // this.loader.stopLoader(this.loaderId);
  }
  applyFilter(filterValue: string) {
    this.filter = filterValue.trim().toLowerCase();
    if (this.filter) {
      let filterVal = [];
      this.cptCodes.forEach(code => {
        if (code.code.includes(this.filter)) {
          filterVal.push(code)
          this.dataSource.data = filterVal;
        }
      });
      console.log(filterVal);
    } else {
      this.dataSource.data = this.cptCodes;
    }
  }
  initCPTCodes() {
    this._cpticd.getAllCPTs().toPromise().then((res) => {
      if (res['status'] == 200) {
        this.cptCodes = res['data'];
        this.dataSource.data = res['data'];
        this.loader.stopLoader(this.loaderId);
      } else {
        this._toaster.error("Somethimg Went Wrong", "Failed");
      }
    });
  }

  addRelation(icdcode, element, index) {
    var condition = false;
    if (this.filter) {
      index = this.cptCodes.findIndex(x => x._id === element);
    }
    if (this.cptCodes[index].cptrelation.length > 0) {
      this.cptCodes[index].cptrelation[0].icd_code_ids.forEach(element => {
        if (element.code === icdcode.code) {
          condition = true;
        }
      });
    }
    if (!condition) {
      this.loader.startLoader(this.loaderId);
      const infoToastr = this._toaster.info('Adding Relation...', 'Please wait', { disableTimeOut: true });
      this._cpticd.createRelationship(element, icdcode.code).toPromise().then((res: any) => {
        if (res.status == 200) {
          if (this.cptCodes[index].cptrelation.length == 0) {
            this._cpticd.getAllCPTs().toPromise().then((res) => {
              if (res['status'] == 200) {
                this.cptCodes = res['data'];
                this.dataSource.data = res['data'];
                this.loader.stopLoader(this.loaderId);
                this._toaster.clear(infoToastr.toastId);
                this._toaster.success("Relation Added Successfully", "Success");
              } else {
                this.loader.stopLoader(this.loaderId);
                this._toaster.clear(infoToastr.toastId);
                this._toaster.error("Somethimg Went Wrong", "Failed");
              }
            });
          } else {
            this.cptCodes[index].cptrelation[0].icd_code_ids.push(icdcode);
            this.loader.stopLoader(this.loaderId);
            this._toaster.clear(infoToastr.toastId);
            this._toaster.success("Relation Added Successfully", "Success");
          }
        } else {
          this.loader.stopLoader(this.loaderId);
          this._toaster.clear(infoToastr.toastId);
          this._toaster.error("Somethimg Went Wrong", "Failed");
        }
      });
    }
  }
  searchDiagnose(value) {
    if (global.ELASTIC_SEARCH_METHOD == -1) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.getAllICDs(value);
      }, 1000);
    } else {
      if (value.length == global.ELASTIC_SEARCH_METHOD) {
        this.getAllICDs(value);
      }
      if (value.length == 0) {
        this.getAllICDs();
      }
    }
  }
  removeRelation(cpt_id, icd_id, index, index2) {
    this.loader.startLoader(this.loaderId);
    if (this.filter) {
      index = this.cptCodes.findIndex(x => x._id === cpt_id);
    }
    const infoToastr = this._toaster.info('Removing Relation...', 'Please wait', { disableTimeOut: true });
    this._cpticd.removeRelation(cpt_id, icd_id).toPromise().then((res: any) => {
      if (res.status == 200) {
        this.cptCodes[index].cptrelation[0].icd_code_ids.splice(index2, 1);
        this.loader.stopLoader(this.loaderId);
        this._toaster.clear(infoToastr.toastId);
        this._toaster.success("Relation Removed Successfully", "Success");
      } else {
        this.loader.stopLoader(this.loaderId);
        this._toaster.clear(infoToastr.toastId);
        this._toaster.error("Somethimg Went Wrong", "Failed");
      }
    });
  }
  getAllICDs(search = "") {
    this.icdLoaded = true;
    this._search.searchICDCode(search).toPromise().then((res: any) => {
      if (res.status == 200) {
        this.icdCodeList = res.data
        this.icdLoaded = false;
      } else {
        this._toaster.error("Somethimg Went Wrong", "Failed");
      }
    });
  }
}

