declare const Buffer;
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { pcmEncode, downsampleBuffer } from './helpers/audioUtils';
import * as createHash from 'create-hash';
import { createPresignedURL } from './helpers/aws-signature-v4'; // to generate our pre-signed URL
import { EventStreamCodec } from '@smithy/eventstream-codec'; // for converting binary event stream messages to and from JSON
import { toUtf8, fromUtf8 } from '@smithy/util-utf8'; // utilities for encoding and decoding UTF8
import MicrophoneStream from 'microphone-stream'; // collect microphone input as a stream of raw bytes
// import { AmazonMedicalTranscribeService } from 'src/app/components/amazon-medical-transcribe/amazon-medical-transcribe.service';
import { AwsTranscribeService } from './aws-transcribe.service';
// const amazonMedicalTranscribeService :any = require('../../../app/components/amazon-medical-transcribe/amazon-medical-transcribe.service')
// private amazonMedicalTranscribeService: AmazonMedicalTranscribeService,
// our converter between binary event streams messages and JSON
const eventStreamMarshaller = new EventStreamCodec(
  toUtf8,
  fromUtf8
);

@Component({
  selector: 'app-aws-transcribe',
  templateUrl: './aws-transcribe.component.html',
  styleUrls: ['./aws-transcribe.component.css']
})
export class AwsTranscribeComponent {
  isRecording = false;
  title = 'AngularTranscribe';
  languageCode = 'en-US';
  region = 'us-east-1';
  sampleRate = 44100;
  transcription = '';
  socket;
  micStream;
  socketError = false;
  transcribeException = false;
  errorText: '';


  constructor(
    // private amazonMedicalTranscribeService: AmazonMedicalTranscribeService
    private amazonMedicalTranscribeService: AwsTranscribeService
  ){
  }

  startRecording() {
    console.log('recording');
    this.isRecording = true;
    window.navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true
  })

  // ...then we convert the mic stream to binary event stream messages when the promise resolves
  .then(this.streamAudioToWebSocket)
  .catch(function (error) {
      console.log('There was an error streaming your audio to Amazon Transcribe. Please try again.', error);
  });
  }
  streamAudioToWebSocket = async (userMediaStream) => {
    //let's get the mic input from the browser, via the microphone-stream module
    console.log('start streamAudioToWebSocket');
    this.micStream = new MicrophoneStream();
    this.micStream.setStream(userMediaStream);
    console.log('start streamAudioToWebSocket22222');
    // Pre-signed URLs are a way to authenticate a request (or WebSocket connection, in this case)
    // via Query Parameters. Learn more: https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html
    let url = await this.createPresignedUrlNew();
    console.log('start streamAudioToWebSocket333333');
    //open up our WebSocket connection
    this.socket = new WebSocket(url);
    this.socket.binaryType = 'arraybuffer';
    console.log('start streamAudioToWebSocket44444');
    // when we get audio data from the mic, send it to the WebSocket if possible
    this.socket.onopen = () => {
      this.micStream.on('data', rawAudioChunk => {
        // the audio stream is raw audio bytes. Transcribe expects PCM with additional metadata, encoded as binary
        let binary = this.convertAudioToBinaryMessage(rawAudioChunk);

        if (this.socket.OPEN) this.socket.send(binary);
      });
    };
    console.log('start streamAudioToWebSocket5555');
    // handle messages, errors, and close events
    this.wireSocketEvents();
  }
  async createPresignedUrlNew () {
    // let uurl = '';
    let res:any = await this.amazonMedicalTranscribeService.getAwsSignedUrl().toPromise()
    if(res.status == 200){
      return res.data;
    }

  };
  getAudioEventMessage = (buffer) => {
    // wrap the audio data in a JSON envelope
    return {
      'headers': {
        ':message-type': {
          type: 'string',
          value: 'event'
        },
        ':event-type': {
          type: 'string',
          value: 'AudioEvent'
        }
      },
      body: buffer
    };
  }
  convertAudioToBinaryMessage = (audioChunk) => {
    let raw = MicrophoneStream.toRaw(audioChunk);

    if (raw == null) return;

    // downsample and convert the raw audio bytes to PCM
    let downsampledBuffer = downsampleBuffer(raw, this.sampleRate);
    let pcmEncodedBuffer = pcmEncode(downsampledBuffer);

    // add the right JSON headers and structure to the message
    let audioEventMessage = this.getAudioEventMessage(
      Buffer.from(pcmEncodedBuffer)
    );

    //convert the JSON object + headers into a binary event stream message
    // @ts-ignore
    let binary = eventStreamMarshaller.encode(audioEventMessage);

    return binary;
  }
  closeSocket = () => {
    if (this.socket.OPEN) {
      this.micStream.stop();

      // Send an empty frame so that Transcribe initiates a closure of the WebSocket after submitting all transcripts
      let emptyMessage = this.getAudioEventMessage(Buffer.from(new Buffer([])));
      // @ts-ignore
      let emptyBuffer = eventStreamMarshaller.decode(emptyMessage);
      this.socket.send(emptyBuffer);
    }
    this.isRecording = false;
  }
  handleEventStreamMessage = (messageJson) => {
    let results = messageJson.Transcript.Results;
    console.log(results)

    if (results.length > 0) {
      if (results[0].Alternatives.length > 0) {
        let transcript = results[0].Alternatives[0].Transcript;

        // fix encoding for accented characters
        transcript = decodeURIComponent(escape(transcript));

        // update the textarea with the latest result
        console.log('transcript', transcript);

        // if this transcript segment is final, add it to the overall transcription
        if (!results[0].IsPartial) {
          //scroll the textarea down

          this.transcription += transcript + '\n';
        }
      }
    }
  }
  wireSocketEvents = () => {
    // handle inbound messages from Amazon Transcribe
    this.socket.onmessage = message => {
      //convert the binary event stream message to JSON
      let messageWrapper = eventStreamMarshaller.decode(
        Buffer(message.data)
      );
      let messageBody = JSON.parse(
        String.fromCharCode.apply(String, messageWrapper.body)
      );
      if (messageWrapper.headers[':message-type'].value === 'event') {
        this.handleEventStreamMessage(messageBody);
      } else {
        this.transcribeException = true;
        console.log(messageBody.Message);
        // toggleStartStop();
      }
    };

    this.socket.onerror = function() {
      this.socketError = true;
      console.log('WebSocket connection error. Try again.');
      // toggleStartStop();
    };

    this.socket.onclose = closeEvent => {
      this.micStream.stop();

      // the close event immediately follows the error event; only handle one.
      if (!this.socketError && !this.transcribeException) {
        if (closeEvent.code != 1000) {
          console.log('error' + closeEvent.reason);
        }
        // toggleStartStop();
      }
    };
  }
}
