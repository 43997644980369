<div class="flex justify-start items-start gap-2" style="overflow: auto; height: 100%;">
    <mat-card style="min-width: 22%; height: 100%;">
        <mat-card-content>
            <div class="col-header"> All CPT Codes </div>

            <mat-form-field class="search" appearance="fill">
                <mat-label>Search</mat-label>
                <input matInput placeholder="Search charges" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="chargeFilter" style="width: 100%;">
            </mat-form-field>

            <div class="col-content-2">
                <div *ngFor="let cptCpde of allCPTs | searchCPTCode: chargeFilter">
                    <div class="item"
                        [class.active-item]="cptCpde.selected === true">
                        <p (click)="cptCodeToUWH(cptCpde)">
                            {{cptCpde.code}} <br />
                            <span>{{getCPTCodeShortDescription(cptCpde.description)}}</span>
                        </p>
                    </div>
                </div>
            </div>

            <ngx-ui-loader [loaderId]="masterCptLoader"></ngx-ui-loader>

        </mat-card-content>
    </mat-card>

    <mat-card style="min-width: 22%; height: 100%;">
        <mat-card-content>
            <div class="flex flex-col" style="width: 100%;height: 100%;">
                <div class="col-header"> Selected Codes </div>

                <mat-form-field class="search" appearance="fill">
                    <mat-label>Search</mat-label>
                    <input matInput placeholder="Search HP charges" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="uwhChargeFilter" style="width: 100%;">
                </mat-form-field>

                <div class="col-content-2">
                    <div *ngFor="let uwhCpde of allUWHCPTs | searchCPTCode: uwhChargeFilter; let i= index">
                        <div class="item flex justify-between items-center">
                            <p>
                                {{uwhCpde.code}} <br />
                                <span>{{getCPTCodeShortDescription(uwhCpde.description)}}</span>
                            </p>

                            <ng-container *ngIf="currentUser.admin_type == 'system_admin'" style="width: 100%;">
                                <button class="close-image" (click)="removeUWHCode(uwhCpde, i)">
                                    <img src="assets\icons\minus.png" />
                                </button>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="currentUser.admin_type == 'system_admin'">
                    <button class="add-image" (click)="showAddForm = true">
                        <img src="assets\icons\plus.png" />
                    </button>
                </ng-container>
            </div>
            <ngx-ui-loader [loaderId]="uwhCptLoader"></ngx-ui-loader>
        </mat-card-content>
    </mat-card>

    <mat-card style="flex:1; min-width: 22%; height: 100%;" *ngIf="showAddForm">
        <mat-card-content>
            <div class="flex flex-col">
                <div class="col-header"> Add New CPT Code </div>

                <div class="col-content">
                    <app-add-new-cpt-code [company_id]="company_id" (addNewCptandUWHCode)="addNewCptandUWHCode($event)"></app-add-new-cpt-code>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>