<div class="flex flex-col">

    <b style="font-size: 16px; margin-bottom: 13px;">{{title}}</b>

    <div class="flex flex-col items-start" style="width: 90%;">
        <div class="w-4/5 flex flex-col justify-start items-start" *ngFor="let mail of emails">
            <mat-checkbox style="font-size: 13px; margin-bottom: 4px;" name="email" [checked]="mail.check" (change)="mail.check = $event.checked">
                {{mail.email}}
            </mat-checkbox>
        </div>
        <div style="margin-top: 10px;" class="flex flex-row">
            <label class="field field_v1 flex justify-between" style="width: 82%;">
                <input class="field__input" ngModel matInput type="text" #mail="ngModel"
                    placeholder="Enter Email Manually">
            </label>
            
            <button class="dn-button-primary w-1/6"
                style="margin-left: 15px; min-width: auto; height: 34px;"
                (click)="addEmail(mail.value); " mat-raised-button>Add
            </button>

        </div>
    </div>
    <mat-dialog-actions style="justify-content: center; margin-top: 10px;">
        <button mat-raised-button color="warn" (click)="handleClose(false)">Cancel</button> &nbsp;&nbsp;
        <button mat-raised-button color="primary" (click)="handleClose(true)">Send Email</button>
    </mat-dialog-actions>

    <ngx-ui-loader [loaderId]="mail_loader" bgsPosition= "center-center"></ngx-ui-loader>

</div>