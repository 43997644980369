import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-patient-diagnostic-reports-test-set',
  templateUrl: './patient-diagnostic-reports-test-set.component.html',
  styleUrls: ['./patient-diagnostic-reports-test-set.component.css']
})
export class PatientDiagnosticReportsTestSetComponent implements OnInit {

  @Input('data') data;
  dataSources:MatTableDataSource<any>[];
  displayedColumns = [
    "resultStatus",
    "comment",
    "code",
    "codeSystem",
    "codeDescription",
    "valueQuantity",
    "procedureId",
    "procedureName"
  ];
  constructor() { }

  ngOnInit(): void {
    // this.dataSource = new MatTableDataSource(this.data);
    
    this.dataSources = [];
    for (const result of this.data) {
      this.dataSources.push(new MatTableDataSource(result.results)) 
    }
  }

}
