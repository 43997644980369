<div>
    <div style="height: 10px;"></div>

    <div class="flex gap-5" style="align-items: flex-end !important; place-content: space-between;">

        <div style="display: contents;">

            <span *ngIf="!currentUser.product_owner" style="align-self: center;font-weight: bolder;">
                {{companyName}}
            </span>
            <mat-form-field *ngIf="currentUser.product_owner">
                <mat-label>Companies</mat-label>
                <mat-select value="true" [ngModel]="filter.company_id" (selectionChange)="applyCompanyFilter($event)">
                    <input  placeholder="Search" [(ngModel)]="searchCompanyText" [ngModelOptions]="{standalone: true}"
                    style="color:#000;height: 40px;padding-left: 5px; border: none; width: 100%;" />
                    <mat-option *ngFor="let company of companies | searchCompany : searchCompanyText" value="{{company._id}}">{{company.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Select billing company</mat-label>
                <mat-select (selectionChange)="applyBillingCompnayChange($event); billId = true" [(ngModel)]="model.billingCompanyId">
                    <input  placeholder="Search" [(ngModel)]="searchBillingCompanyText" [ngModelOptions]="{standalone: true}"
                    style="color:#000;height: 40px;padding-left: 5px; border: none; width: 100%;" />
                    <mat-option *ngFor="let bilCom of billingCompanies | searchCompany : searchBillingCompanyText" value="{{bilCom._id}}">
                        {{bilCom.name ? bilCom.name : ""}}
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="!billId" style="color: red;">Please enter billing company</mat-hint>
            </mat-form-field>
    
            <mat-form-field appearance="fill" style="align-self: center;">
                <mat-label>Select Method type</mat-label>
                <mat-select (selectionChange)="isMethod = true" [(ngModel)]="model.method">
                    <mat-option *ngFor="let method of methodTypes" value="{{method}}">
                        {{method}}
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="!isMethod" style="color: red;">Please select method type</mat-hint>
            </mat-form-field>
    
            <mat-form-field appearance="fill" style="align-self: center;">
                <mat-label>Select Protocol</mat-label>
                <mat-select (selectionChange)="isProtocl = true" [(ngModel)]="model.protocol">
                    
                    <mat-option *ngFor="let protocol of protocols" value="{{protocol}}">
                        {{protocol}}
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="!isProtocl" style="color: red;">Please select Protocol</mat-hint>
            </mat-form-field>
    
    
            <mat-form-field appearance="fill" style="align-self: center;">
                <mat-label>Enter URL</mat-label>
                <input matInput required [(ngModel)]="model.endURL">
                <mat-hint *ngIf="!isURL && model.endURL.length <= 0" style="color: red;">Please enter URL</mat-hint>
            </mat-form-field>
        </div>
        
        <div style="align-self: center;">
            <span style="align-self: center">
                <button *ngIf="!updateData" mat-raised-button class="dn-button-primary" (click)="createConfigration()"> Create Configration</button>
            </span>
            <span style="align-self: center">
                <button *ngIf="updateData" mat-raised-button class="dn-button-secondary" (click)="cancel()" style="margin-right: 20px;"> Cancel</button>
                <button *ngIf="updateData" mat-raised-button class="dn-button-primary" (click)="updateConfigration()"> Update Configration</button>
            </span>
        </div>

    </div>

    <div style="height: 10px;"></div>

    <table mat-table [dataSource]="dataSource" matSort> 
        <ng-container matColumnDef="company_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Company</th>
            <td mat-cell *matCellDef="let element"> 
                {{element.companyId? element.companyId.name : ''}} 
            </td>
        </ng-container>
        <ng-container matColumnDef="billing_company">
            <th mat-header-cell *matHeaderCellDef> Billing Company </th>
            <td mat-cell *matCellDef="let element ; let i = index;">
                {{element.billingCompanyId? element.billingCompanyId.name : ''}}  
            </td>
        </ng-container>

        <ng-container matColumnDef="method_type">
            <th mat-header-cell *matHeaderCellDef> Method Type </th>
            <td mat-cell *matCellDef="let element ; let i = index;">
                {{element.method? element.method : ''}}  
            </td>
        </ng-container>

        <ng-container matColumnDef="protocol">
            <th mat-header-cell *matHeaderCellDef> Protocol </th>
            <td mat-cell *matCellDef="let element ; let i = index;">
                {{element.protocol? element.protocol : ''}}  
            </td>
        </ng-container>

        <ng-container matColumnDef="url">
            <th mat-header-cell *matHeaderCellDef> URL </th>
            <td mat-cell *matCellDef="let element ; let i = index;">
                {{element.url? element.url : ''}}  
            </td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element ; let i = index;">
                <i style= "margin-right: 20px;" class="icon icon-delete" matTooltip="Delete" matTooltipPosition="above"
                                (click)="onDeleteClick(element)"></i>
                <i class="icon icon-edit" matTooltip="Edit" matTooltipPosition="above"
                                (click)="onEditClick(element)"></i>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

</div>