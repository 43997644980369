import {Component, OnInit, Input, AfterViewInit, EventEmitter, Output} from '@angular/core';
import {NoteCommentsService} from './note-comments.service';
import {CommonService} from 'src/app/services/common.service';
import {DatePipe} from '@angular/common';
import * as global from '../../global';
import {AuthService} from 'src/app/services/auth.service';
import {AudioService} from "../../../services/Modules/audio.service";
import {TranscriptionDetailService} from "../../transcription-detail/transcription-detail.service";

@Component({
    selector: 'app-note-comments',
    templateUrl: './note-comments.component.html',
    styleUrls: ['./note-comments.component.css'],
    providers: [DatePipe]
})
export class NoteCommentsComponent implements OnInit, AfterViewInit {
    @Input() noteId: String;
    @Input() audioId: string;
    transcript = '';
    commentsLoaded = false;
    currentUser: any;
    comments: Array<any> = [];
    editor = {
        comment: <string>''
    }
    audioComments = [];
    global: any = global;
    currentTabIndex = 0;
    constructor(
        private noteCommentsService: NoteCommentsService,
        private _date_pipe: DatePipe,
        commonService: CommonService,
        private _authService: AuthService,
        private audioService: AudioService,
        private ts_detail_service: TranscriptionDetailService
    ) {
        const currentUser = this._authService.currentUser;
        if (currentUser) {
            // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
            this.currentUser = currentUser;
        }
    }

    ngOnInit() {
        this.noteCommentsService.get_note_comments(this.noteId).subscribe((response: any) => {
            //
            if (response.status === 200) {
                this.comments = response.data.array;
            }
        }, console.error, () => this.commentsLoaded = true);
        if (this.audioId) {

            this.ts_detail_service.getTranscriptionComments(this.currentUser._id, this.currentUser.auth_key, this.currentUser.user_type,
                this.audioId).subscribe(data => {
                // @ts-ignore
                this.audioComments = data.data.array;
            });
        }
    }

    ngAfterViewInit() {
        this.ts_detail_service.chunk_update.subscribe((x) => {
            this.getAudioChunks();
        });
        this.getAudioChunks();
    }

    getAudioChunks() {
        this.audioService.getAudioChunks({
            audio_id: this.audioId
        }).subscribe( (data:any) => {
            if(data.status === 200) {
                // @ts-ignore
                let chunks = data.data.array;
                let transcript = '';
                for (let c of chunks) {
                    transcript = transcript + ' ' + c.transcript;
                }
                this.transcript = transcript;
            }
        });
    }

  addComment() {
        const createdAt = this._date_pipe.transform(new Date(), global.date_time_format);
        this.editor.comment = this.editor.comment.trim();
        if (this.editor.comment.length === 0) return;
        this.noteCommentsService.add_note_comment(
            this.editor.comment,
            this.noteId,
            createdAt
        ).subscribe((response: any) => {
            if (response.status === 200) {
                const comment = {
                    _id: response.data,
                    text: this.editor.comment,
                    noteId: this.noteId,
                    user: {
                        _id: this.currentUser._id,
                        first_name: this.currentUser.first_name,
                        last_name: this.currentUser.last_name,
                    },
                    createdAt
                }
                this.comments.push(comment);
                this.editor.comment = '';
                this.scrollToBottom();
            }
            // this.comments.push({

            // })
        }, console.error, () => this.commentsLoaded = true)
    }

    scrollToBottom() {
        const commentContainer = document.querySelector('.mat-list');
        if (commentContainer) {
            commentContainer.scrollTop = commentContainer.scrollHeight
        }
    }
}
