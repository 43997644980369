import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { lastValueFrom } from 'rxjs';
import { WoundCareWidgetService } from '../wound-care-widget.service';

@Component({
  selector: 'app-wound-note-dialog',
  templateUrl: './wound-note-dialog.component.html',
  styleUrls: ['./wound-note-dialog.component.css']
})
export class WoundNoteDialogComponent implements OnInit {
    loaderId = 'wound-note-dialog-loader';

    wound;
    woundNote:string = '';

    constructor(
        private loader: NgxUiLoaderService, 
        public dialogRef: MatDialogRef<WoundNoteDialogComponent>, 
        @Inject(MAT_DIALOG_DATA)public data: any, private woundCareWidgetService: WoundCareWidgetService,
      ) {
      if(this.data) {
        this.wound = data.wound;
        this.woundNote = data.wound.extra_note;
      }
   }

  async ngOnInit() {
    // this.dialogRef.beforeClosed().subscribe(async () => {
    //   this.updateWoundData();
    // });
  }
  async updateWoundData() {
    const woundData = {
      extra_note: this.woundNote
    }
    // loader start
    this.loader.startLoader(this.loaderId);
    const {status, data} = await lastValueFrom(this.woundCareWidgetService.updateWoundData(this.wound._id, woundData));
    // loader stop
    this.loader.stopLoader(this.loaderId);
  }
  async updateWoundDataAndClose() {
    if(this.wound.extra_note !== this.woundNote) {
      await this.updateWoundData();
      this.dialogRef.close(this.woundNote);
    } else {
      this.dialogRef.close();
    }
  }
}

function union_arrays (x, y) {
  var obj = {};
  for (var i = x.length-1; i >= 0; -- i)
     obj[x[i]] = x[i];
  for (var i = y.length-1; i >= 0; -- i)
     obj[y[i]] = y[i];
  var res = []
  for (var k in obj) {
    if (obj.hasOwnProperty(k))  // <-- optional
      res.push(obj[k]);
  }
  return res;
}
