import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { DrivePhraseService } from '../../drive-phrases/drive-phrase.service';
import { CommonService } from 'src/app/services/common.service';
import * as global from '../../../includes/global';


@Component({
  selector: 'app-add-user-phrase-to-provider',
  templateUrl: './add-user-phrase-to-provider.component.html',
  styleUrls: ['./add-user-phrase-to-provider.component.css']
})

export class AddUserPhraseToProviderComponent implements OnInit {
  providers: any = [];
  selectedProvider;
  phraseArray: any = [];
  providertoShare: any;
  currentUser: any;
  providerDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'fullName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 10,
    allowSearchFilter: true,
    enableCheckAll: true,
  };
  sharedProviders: any = [];
  global = global;
  placeHolder : string = 'Select provider(s)';
  company_type: any = 'Wound';
  constructor(public dialogRef: MatDialogRef<AddUserPhraseToProviderComponent>,
    private drivePhraseService: DrivePhraseService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService
    ) {
    this.currentUser = this.authService.currentUser._id;
    if (data) {
      this.providers = data.providers.filter((ele) => ele._id != this.data.selectedProviderId);
      this.phraseArray = data.phrase;
    }
    this.providers.forEach(element => {
      element['fullName'] = element['first_name'] + ' ' + element['last_name'];
    });
    this.selectedProvider = this.providers[0];
  }

  ngOnInit(): void {
    const currentCompany = this.commonService.getLocalStorageItem('admin-Dashboard');
    if(currentCompany) {
        this.company_type = currentCompany.currentCompanyType;
    }
    console.log('this.phraseArray = data.phrase;',this.phraseArray);
    
    this.company_type === global.COMPANY_TYPE.SNF ? this.placeHolder = 'Select SNF WC NURSE(S)' : this.placeHolder = 'Select provider(s)'
  }

  onProviderClick(providerId) {
    this.providertoShare = providerId;
  }

  importPhrases(sharedProviderId, sharedProviderIds = null) {
    if (sharedProviderIds) {
      sharedProviderId = null;
    }
    this.drivePhraseService.saveNoteBuilderDictionaryAdmin(sharedProviderId, this.phraseArray, sharedProviderIds).subscribe((response: any) => {
      console.log(response);
      if (response.status === 200) {
        this.toastr.success('Shared successfully!');
      }
    })
  }

  onProviderSelect($event) {
    if (this.sharedProviders.indexOf($event._id) === -1) {
      this.sharedProviders.push($event._id);
    }
    // this.importPhrases($event._id);
  }
  onProviderDeselect($event) {
    const index = this.sharedProviders.findIndex(providerId => providerId === $event._id);
    if (index !== -1) {
      this.sharedProviders.splice(index, 1);
    }
  }
  sharePharses() {
    this.importPhrases(null, this.sharedProviders);
  }
  onAllProviderSelect(providers) {
    const allProviders = providers.map(provider => provider._id);
    for (const id of allProviders) {
      if (!this.sharedProviders.includes(id)) {
        this.sharedProviders.push(id);
      }
    }
  }
  onAllProviderDeSelect($event) {
    this.sharedProviders = $event;
  }
}
