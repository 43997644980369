import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-operations-summary-report',
  templateUrl: './operations-summary-report.component.html',
  styleUrls: ['./operations-summary-report.component.css']
})
export class OperationsSummaryReportComponent implements OnInit {
  @Input() operationsSummaryReport = [];
  @Input() facilityTitle = "";
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = [
    'faciltiy', 'company', 'totalReferrals', 'totalWounds', 'residentsWithWounds', 'facilityAcquired',
    'facilityAcquiredPI/PU'];

  constructor() { }

  ngOnInit(): void {
    this.dataSource.data = this.operationsSummaryReport;
  }
}
