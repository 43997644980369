import { Component, OnInit,Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CKEditorService } from '../note-editor/ckeditor.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { AdminNotificationsService } from '../admin-notifications-settings/admin-notification.service';
import * as global from '../../includes/global';

export interface Notes {
  notes: String;
}

@Component({
  selector: 'app-open-post-dialog',
  templateUrl: './open-post-dialog.component.html',
  styleUrls: ['./open-post-dialog.component.css']
})
export class OpenPostDialogComponent implements OnInit {
  cke4Config;
  companies: any;
  currentUser: any;
  isButtonDisable = true;
  releaseData: any = {
    addNotes: '',
    user_types: [],
    company: ''

  }
  isShareReleaseNotes = false;
  user_types = [
    { name: 'All', type: 'all' },
    { name: 'Provider', type: global.USER_TYPE.DOCTOR }, 
    { name: 'Test Provider', type: 'test_provider' }, { name: 'CNP - Clinical Nursing Practitioner', type: global.USER_TYPE.CNP },
    { name: 'Nurse', type: global.USER_TYPE.NURSE }, { name: 'Biller', type: global.USER_TYPE.BILLER }, 
    { name: 'Medical Assistant', type: global.USER_TYPE.MEDICAL_ASSISTANT }, { name: 'Business Officer', type: global.USER_TYPE.BUSINESS_OFFICER },
    { name: 'WOUND NURSE', type: global.USER_TYPE.WOUND_NURSE }, { name: 'SNF WC NURSE', type: global.USER_TYPE.SNF_WC_NURSE }
  ];
  
  constructor(public dialogRef: MatDialogRef<OpenPostDialogComponent>, private companyService: CompanyService, private toast: ToastrService, private _adminNotificationsService:AdminNotificationsService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private ckeditorService: CKEditorService) {

    this.cke4Config = {
      ...this.ckeditorService.getDefaultConfig(),
      editable: true
    };
    if (data) {
      console.log('note data',data);
      this.releaseData.version = data.version;
      this.releaseData.addNotes = data.note ? data.note : data
      this.currentUser = data.currentUser;
      if (data.isShareReleaseNotes)
        this.isShareReleaseNotes = data.isShareReleaseNotes;
      if (data.noteType)
        this.releaseData['noteType'] = data.noteType
    }

    this.currentUser = this.authService.currentUser;
  }

  ngOnInit(): void {
    this.getCompanies();
  }

  onReadyCkeditor(e) {
    const {editor} = e;
    
    this.ckeditorService.ckeditorRemoveTitle(editor)
}

  async getCompanies() {
    const response: any = await this.companyService.getAllCompanies().toPromise();
    if (response.status == 200) {
      this.companies = response.data;
    }
    else {
      this.toast.error(response.message, "ERROR");
    }
  }

  selectDeselectAll(){
    let find = this.releaseData.user_types.find((element) => element == 'all')
    if (find)  // to select all
      this.releaseData.user_types = ['all', global.USER_TYPE.DOCTOR, 'test_provider', global.USER_TYPE.CNP, global.USER_TYPE.NURSE, global.USER_TYPE.BILLER, global.USER_TYPE.MEDICAL_ASSISTANT, global.USER_TYPE.BUSINESS_OFFICER, global.USER_TYPE.WOUND_NURSE, global.USER_TYPE.SNF_WC_NURSE];
    if (!find && this.releaseData.user_types.length == this.user_types.length - 1 )
      this.releaseData.user_types = [];   // to deselect all
    this.setButtonEnabled();
  }

  setButtonEnabled() {
    if (this.currentUser.product_owner) {
      if (this.releaseData.company != '' && this.releaseData.user_types.length > 0)
        this.isButtonDisable = false;
      else
        this.isButtonDisable = true;
    }
    else if (this.releaseData.user_types.length > 0)
      this.isButtonDisable = false;
    else
      this.isButtonDisable = true;
  }

  shareReleaseNotes() {
    this._adminNotificationsService.addNotes(this.releaseData).subscribe((response: any) => {
      if (response.status == 200) {
        this.toast.success('Shared!');
      }
      else {
        this.toast.error(response.message, 'error');
      }
    });

  }

}
