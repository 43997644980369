import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmailTemplateService } from '../email-template/service/email-template.service';
import { SendEmailTemplateDialogComponent } from '../send-email-template-dialog/send-email-template-dialog.component';

@Component({
  selector: 'app-company-email',
  templateUrl: './company-email.component.html',
  styleUrls: ['./company-email.component.css']
})
export class CompanyEmailComponent implements OnInit {
  tabIndex: Number = 1;
  isAutomaticEmailReceived: Boolean = true;
  isOneTimeEmailReceived: Boolean = true;
  @Output() exit = new EventEmitter<boolean>();

  constructor(
    private dialog: MatDialog,
    private _emailTemplateService: EmailTemplateService,
  ) { }

  ngOnInit(): void {
  }

  openSendEmailTemplateDialog(): void {
    let options: any = {
      width: '70%',
      height: '70vh'
    };
    // options.data = emailTemplateId;
    this.dialog.open(SendEmailTemplateDialogComponent, {
      ...options
    }).afterClosed().subscribe((emailResponse) => {
      if (emailResponse) {
        if (!emailResponse.is_automatic) {
          this.isOneTimeEmailReceived = false;
          setTimeout(()=> this.isOneTimeEmailReceived = true, 10);
        }
        else if (emailResponse.is_automatic) {
          this.isAutomaticEmailReceived = false;
          setTimeout(()=> this.isAutomaticEmailReceived = true, 10);
        }
      }
    });
  }

  goBack() {
    // this.showEmailTemplate.emit(false);
    // this._emailTemplateService.changeIsCompanyEmailVisible(false);
    this.exit.emit(false);
  }

  onTabChange(event) {
    this.tabIndex = event.index;
  }

}
