<form (ngSubmit)="submitForm()">
    <div class="flex gap-1">
        <mat-form-field style="width: 100%">
            <mat-label>Title</mat-label>
            <input matInput type="text" name="title" #ttile="ngModel" [(ngModel)]="model.title">
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <mat-label>Subject</mat-label>
            <input matInput type="text" name="subject" #subject="ngModel" [(ngModel)]="model.subject">
        </mat-form-field>
    </div>

    <div style="height: 400px;">
        <ckeditor [(ngModel)]="model.emailBody" name="emailBody" class="h100"
            editorUrl="../../../../../assets/ckeditor/ckeditor.js" [readOnly]="!cke4Config.editable"
            [config]="cke4Config" type="divarea">
        </ckeditor>
    </div>
    <br>
    <div class="flex items-center justify-end gap-8">
        <button mat-raised-button class="dn-button-secondary" mat-dialog-close type="button">Cancel</button>
        <button mat-raised-button type="button" class="dn-button-primary" type="submit">Save</button>
    </div>
</form>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>