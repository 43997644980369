import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { FacilityService } from 'src/app/services/facility.service';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { ViewNoteDialog } from '../transcription-create/view-note-dialog/view-note-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConnectionPositionPair, OverlayModule } from '@angular/cdk/overlay';
import { AuthService } from 'src/app/services/auth.service';
import { AdvancedNoteTextSearchService } from '../advanced-note-text-search/advanced-note-text-search.service';


@Component({
  selector: 'app-note-text-search-history',
  templateUrl: './note-text-search-history.component.html',
  styleUrls: ['./note-text-search-history.component.css']
})
export class NoteTextSearchHistoryComponent implements OnInit {
  displayedColumns: string[] = ['keyword'];
  dataSource: any = new MatTableDataSource([]);
  @Output() searchNoteText = new EventEmitter<boolean>();
  filter: any = {
    user_id: {},
    is_deleted: false,
  };
  loaderId = 'app-note-text-search-history';
  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    PAGE_LIMIT: 5,
    skip: 0
  };
  noteTextSearchHistory: any = [];

  constructor(
    private _advancedNoteTextSearchService: AdvancedNoteTextSearchService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _toastr: ToastrService,
    private loader: NgxUiLoaderService,
    private _facilityService: FacilityService,
    private _matDialog: MatDialog,
    private _authService: AuthService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.initNoteTextSearchHistory();
    this.filter.user_id = this._authService.currentUser._id;
  }

  // ngOnDestroy() {
  //   if (this.dialogSubscription) {
  //     this.dialogSubscription.unsubscribe();
  //   }
  // }
  async initNoteTextSearchHistory() {
    this.loader.startLoader(this.loaderId);
    this.filter.pageNo = this.pagination.pageNo;
    const response: any = await lastValueFrom(this._advancedNoteTextSearchService.getNoteTextSearchHistory(this.filter, { keyword: 1 }));
    if (response.status === 200) {
      this.noteTextSearchHistory = response.data.noteTextSearchHistory;
      this.pagination.totalRecords = response.data.totalRecords;
      this.handlePagination();
      this.dataSource.data = this.noteTextSearchHistory;
    }
    else {
      this._toastr.error(response.data.message, 'Error');
    }
    this.loader.stopLoader(this.loaderId);
  }

  handlePagination() {
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    if (this.pagination.skip + this.noteTextSearchHistory.length < this.pagination.totalRecords) {
      this.pagination.hasNextPage = true;
    }
    else {
      this.pagination.hasNextPage = false;
    }
    if (this.pagination.skip > 0) {
      this.pagination.hasPrevPage = true;
    }
    else {
      this.pagination.hasPrevPage = false;
    }
  }

  async handlePageNavigation(shouldgoBack: boolean) {
    shouldgoBack ? this.pagination.pageNo-- : this.pagination.pageNo++;
    await this.initNoteTextSearchHistory();
  }

  onRowClick(history) {
    this.searchNoteText.emit(history.keyword);
  }

}
