import {Component, Input, OnInit} from '@angular/core';
import * as usa_states from '../../../../shared/popup-dialogs/facility-add-dialog/usa_states';
import {CompanyService} from "../../../../services/Modules/company.service";
import {MatTableDataSource} from "@angular/material/table";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-user-facility',
    templateUrl: './user-facility.component.html',
    styleUrls: ['./user-facility.component.css']
})
export class UserFacilityComponent implements OnInit {
    usa_states = usa_states.default;
    facilities: any[] = [];
    displayedColumns: any = ['facility', 'address', 'city',
        'state', 'zip_code'];
    dataSource: any;
    companyId;
    providerId;

    constructor(private companyService: CompanyService,
                private route: ActivatedRoute) {
      this.companyId = this.route.parent.parent.snapshot.params.id;
      this.providerId = this.route.snapshot.params.id;
    }

    ngOnInit(): void { 
      this.getCompanyFacilities();
    }

    getCompanyFacilities() {
        this.companyService
            .getCompanyFacilities(this.companyId, this.providerId)
            .subscribe(res => {
                this.facilities = res.data.array;
                this.dataSource = new MatTableDataSource(res.data.array);
            });
    }

}
