<div [ngClass]="{'container': (companySide || user_dashboard) && !(nursingSide)}">

    <h3 class="dark-blue-heading">
        <ng-container *ngIf="!user_dashboard">
            <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
                Back
            </button>
        </ng-container>

        <label [ngClass]="{'label-margin': !user_dashboard}"> Missed Facility Report </label>

        <button style="margin-left: auto;" mat-raised-button class="dn-button-primary" (click)="exportMissedFacilityReportAsXLSX();">Export
            As XLSX
        </button>
    </h3>

    <div class="search-block theme-form flex justify-start items-center" 
        style="background-color: white; border-radius: 4px; padding-top: 8px; padding-bottom: 5px; padding-left: 8px;padding-right: 8px;">
        
        <div class="flex w-4/5 gap-4">
            <ngx-slider [(value)]="filter.weekStartNo" [(highValue)]="filter.weekEndNo" [options]="options"
                (userChange)="onChangeSlider($event)"></ngx-slider>
            <div class="flex justify-between items-center gap-1">
                <button mat-raised-button class="dn-button-primary" (click)="handleApplyFilter();">Apply Filter
                </button>

                <button mat-raised-button class="dn-button-secondary" (click)="resetFilter();">Reset Filter
                </button>
                
            </div>
        </div>

    </div>

    <mat-card class="vertical-height">
        <div class="table-height">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
                    <th mat-header-cell *matHeaderCellDef> {{column}} </th>
                    <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
        </div>
    </mat-card>
</div>