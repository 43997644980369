<div class="flex gap-2 items-center justify-between">
    <mat-form-field style="color: black; max-width: 180px;margin-right: 5px;">
        <mat-label>Select Practice</mat-label>
        <mat-select name="type" [(ngModel)]="selectedCompany" (selectionChange)="onCompanyChange($event)">
            <input matInput placeholder="Search" [(ngModel)]="searchCompanyText" [ngModelOptions]="{standalone: true}"
                style="color:#000;height: 40px;padding-left: 5px;" (keydown)="$event.stopPropagation()" />
            <mat-option *ngFor="let company of companies" [value]="company._id">
                {{ company.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <ng-multiselect-dropdown name="provider" style="width: 100%;" appearance="fill"
        [settings]="providerDropdownSettings" [placeholder]="'Select Provider(s)'" [data]="providers"
        (onSelect)="onProviderSelect($event)" (onDeSelect)="onProviderDeselect($event);"
        (onSelectAll)="onAllProviderSelect($event)" (onDeSelectAll)="onAllProviderDeSelect($event)">
    </ng-multiselect-dropdown>

    <div>
        <button mat-flat-button mat-button color="primary" mat-dialog-close style="background-color: #43adff;"
            [disabled]="sharedProviders.length === 0" (click)="shareIcds();">
            Share
        </button>
    </div>
</div>
