import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-mips',
  templateUrl: './add-mips.component.html',
  styleUrls: ['./add-mips.component.css']
})
export class AddMipsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  addMip(){

  }

}
