<div *ngIf="patient" class="flex flex-row items-center gap-1">
    <img *ngIf="(patient.source?.includes('pcc') || patient.pcc_patientId) && facility?.source == 'PointClickCare'" src="../../../assets/icons/pcc-icon.svg" class="pcc-icon" alt="" />    
    
    <span class="title">
        <ng-container *ngIf="patient.patient_name">
            {{ patient.patient_name | titlecase}}
        </ng-container>
        <ng-container *ngIf="!patient.patient_name">
            {{ patient.last_name | titlecase }}, {{ patient.first_name | titlecase }} {{ (patient.middle_name ? patient.middle_name  + '.' : '') | titlecase}}
        </ng-container>
    </span>
    
    <ng-container *ngIf="patient.therapies">
        <div class="therapy-icon" *ngIf="patient.therapies.isPT" (mouseover)="getPTSoc(patient)" [matTooltip]="object?.maxPT">PT</div>
        <div class="therapy-icon" *ngIf="patient.therapies.isOT" (mouseover)="getPTSoc(patient)" [matTooltip]="object?.maxOT">OT</div>
        <div class="therapy-icon" *ngIf="patient.therapies.isST" (mouseover)="getPTSoc(patient)" [matTooltip]="object?.maxST">ST</div>
    </ng-container>
</div>