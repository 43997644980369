import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from 'src/app/services/Modules/company.service';
import * as usa_states from 'src/app/shared/popup-dialogs/facility-add-dialog/usa_states';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FacilityService } from 'src/app/services/facility.service';
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { FacilityContactDialogComponent } from "src/app/shared/popup-dialogs/facility-contact-dialog/facility-contact-dialog.component";
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-company-facility-contact',
  templateUrl: './company-facility-contact.component.html',
  styleUrls: ['./company-facility-contact.component.css']
})
export class CompanyFacilityContactComponent implements OnInit {
  @Output() newFacilityOutput = new EventEmitter();
  usa_states = usa_states.default.slice(1)
  loaderId = 'app-company-facility-contact';
  facility_id: string;
  pods = [
    "E. WA",
    "S. WA",
    "N. WA",
    "Utah",
    "W. TX",
    "ID/AZ",
    "Oregon"
  ];
  model = {
    title: '',
    address: '',
    street: '',
    city: '',
    state: '',
    zip_code: '',
    trueRCMID: '',
    pod: '',
    company_id: null,
    source: null,
    external_id: null,
    organization_id: null,
    npi: null,
    place_of_service: ''
  }
  public dataSource: any;
  displayedColumns: string[] = ['name', 'position', 'email', 'phone', 'operations', 'association'];
  isRedirectedFromParentRoute: boolean = false;
  isProductOwner: boolean = false;
  currentUser: any;
  isPccFacililty: boolean = false;

  constructor(private _location: Location,
    private route: ActivatedRoute,
    private loader: NgxUiLoaderService,
    private companyService: CompanyService,
    private facilityService: FacilityService,
    private dialog: MatDialog,
    private _router: Router,
    private _authService: AuthService,
    private toastr: ToastrService) {
    this.model.company_id = this.route.parent.parent.snapshot.params.id;
    this.facility_id = this.route.snapshot.params.id;
    this.currentUser = this._authService.currentUser;
    this.route.queryParams.subscribe((params: any) => {
      if (params) {
        this.isRedirectedFromParentRoute = params.isRedirectedFromParentRoute;
      }
    });
  }

  ngOnInit(): void {
    if (this.facility_id) {

      this.loader.startLoader(this.loaderId);

      this.facilityService.getFacility(this.facility_id).subscribe((response: any) => {
        if (response.status === 200) {
          if (response.data.trueRCMID) {
            response.data.trueRCMID = "";
          }

          if (response.data && (response.data.source?.includes('PointClickCare') || (response.data.pcc_facId))) {
            this.isPccFacililty = true;
          }
          this.model = response.data;
          console.log(response.data);
        }
        this.loader.stopLoader(this.loaderId);
      });
      this.companyService.getFacilityContacts({ facility: this.facility_id }, 'all')
        .subscribe(res => {
          console.log(res.data.array);
          this.dataSource = new MatTableDataSource(res.data.array);
        });

    }
    if (this.currentUser.product_owner == true) {
      this.isProductOwner = true;
    }
  }


  goBack() {
    if (this.isRedirectedFromParentRoute) {
      const companyId = this.route.parent.parent.snapshot.params.id;
      return this._router.navigate([`/company/${companyId}/facilities/list`]);
    }
    this._location.back();
  }

  editFacilityContact(contact) {
    const dialogRef = this.dialog.open(FacilityContactDialogComponent, {
      width: '600px',
      data: { update: true, contact: contact }
    });

    dialogRef.afterClosed().subscribe(async (data) => {
      // @ts-ignore
      if (contact._id === this.model.contact._id) {
        this.loader.startLoader(this.loaderId);

        this.facilityService.getFacility(this.facility_id).subscribe((response: any) => {
          if (response.status === 200) {
            this.model = response.data;
          }
          this.loader.stopLoader(this.loaderId);
        });
      }
      this.companyService.getFacilityContacts({ facility: this.facility_id }, 'all')
        .subscribe(res => {
          this.dataSource = new MatTableDataSource(res.data.array);
        });
    });
  }

  deleteFacilityContact(contact) {
    let params = {
      _id: contact._id,
      is_active: false
    };
    this.companyService.updateFacilityContact(params)
      .subscribe(res => {
        this.toastr.success('Facility Contact Deleted', 'Success');
        this.companyService.getFacilityContacts({ facility: this.facility_id }, 'all')
          .subscribe(res => {
            this.dataSource = new MatTableDataSource(res.data.array);
          });
      });
  }

  openDialog() {
    if (!this.facility_id) {
      this.toastr.error('Please add facility first', 'Error');
      return;
    }
    const dialogRef = this.dialog.open(FacilityContactDialogComponent, {
      width: '600px',
      data: { facility_id: this.facility_id }
    });

    dialogRef.afterClosed().subscribe(async (data) => {
      this.companyService.getFacilityContacts({ facility: this.facility_id }, 'all')
        .subscribe(res => {
          this.dataSource = new MatTableDataSource(res.data.array);
        });
    });
  }

  toggle(contact, $event) {
    let params = {
      _id: contact._id,
      is_associated: $event.checked,
    };
    this.companyService.updateFacilityContact(params)
      .subscribe(res => {
        this.toastr.success('Facility Contact Association Updated', 'Success');
        this.companyService.getFacilityContacts({ facility: this.facility_id }, 'all')
          .subscribe(res => {
            this.dataSource = new MatTableDataSource(res.data.array);
          });
      });
  }
}
