import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import * as global from '../global';
import { Subscription, Observable, Subject, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from "../../../environments/api-url";
import { CommonService } from 'src/app/services/common.service';

@Injectable({
  providedIn: 'root',
})
export class MergePatientsService {
  currentUser: any;

  public constructor(
    private httpclient: HttpClient,
    private _router: Router,
    private commonService: CommonService,
    private _authService: AuthService) {
    this.currentUser = this._authService.currentUser;
  }

  searchThePatient(filter) {
    let company_id
    this.commonService.castCompany.subscribe(company => {
      company_id = company?._id
    })

    if(!company_id){
      company_id=this.currentUser.company_id;
    }

    console.log("company_id : ", company_id);

    
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      company_id: company_id,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_PATIENTS.searchThePatient, { details: item }).pipe();
  }
  searchThePatientOnly(filter) {
    let company_id
    this.commonService.castCompany.subscribe(company => {
      company_id = company?._id
    })

    if(!company_id){
      company_id=this.currentUser.company_id;
    }

    console.log("company_id : ", company_id);

    
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      company_id: company_id,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_PATIENTS.searchThePatientOnly, { details: item }).pipe();
  }
  searchThePatientById(patient_id) {
    let company_id
    this.commonService.castCompany.subscribe(company => {
      company_id = company?._id
    })

    if(!company_id){
      company_id=this.currentUser.company_id;
    }
    console.log("company_id : ", company_id);


    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      company_id: company_id,
      patient_id: patient_id
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_PATIENTS.searchThePatientById, { details: item }).pipe();
  }
  getMergingAudit(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_PATIENTS.getMergingAudit, { details: item }).pipe();
  }
  mergeThePatient(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_PATIENTS.mergeThePatient, { details: item }).pipe();
  }
  mergeThePatientData(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_PATIENTS.mergeThePatientData, { details: item }).pipe();
  }
  revertThePatient(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_PATIENTS.revertThePatient, { details: item }).pipe();
  }
}