<div class="flex gap-5 justify-center items-center" style="background: #cedbdf; padding: 5px; border-radius: 4px;">
  <app-input-with-dropdown class="flex-grow" [noDropdown]="true" placeholder="Search Biller by Name"
    (inputChange)="applyFilter($event.target.value)">
  </app-input-with-dropdown>
  <button mat-raised-button class="dn-button-primary" (click)="exportUsersAsXLSX()">
    Export As XLSX
  </button>
</div>
<div style="height: 3px;"></div>
<div style="overflow-x: auto; " class="vertical-height">
  <table mat-table matSort [dataSource]="dataSource">

    <ng-container matColumnDef="first_name">
      <th mat-header-cell *matHeaderCellDef> First Name </th>
      <td mat-cell *matCellDef="let element"> {{element.first_name | titlecase}} </td>
    </ng-container>

    <ng-container matColumnDef="last_name">
      <th mat-header-cell *matHeaderCellDef> Last Name </th>
      <td mat-cell *matCellDef="let element"> {{element.last_name | titlecase}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
      <td mat-cell *matCellDef="let element" style="width: 20%; overflow-wrap: break-word;"> {{element.email}}
        <br>
        <small *ngIf="element.recentLogin==undefined && element._id !== currentCompany?.user_id?._id"
          style="color: gray; cursor: pointer;" (click)="ResendEmail(element._id)"><a>Resend Registration Email</a></small>
        <b *ngIf="element._id === currentCompany?.user_id?._id">
          {{element.email | titlecase}}
        </b>
        <br>
        <small *ngIf="element.recentLogin==undefined && element._id === currentCompany?.user_id?._id"
          style="color: gray; cursor: pointer;" (click)="ResendEmail(element._id)"><a>Resend Registration Email</a></small>
      </td>
    </ng-container>
    <ng-container matColumnDef="last_login">
      <th mat-header-cell *matHeaderCellDef style="width: 10%;"> Last Login</th>
      <td mat-cell *matCellDef="let element">
        {{element.recentLogin ?(element.recentLogin):null}}
      </td>
    </ng-container>
    <ng-container matColumnDef="timezone">
      <th mat-header-cell *matHeaderCellDef style="width: 5%;"> Tz</th>
      <td mat-cell *matCellDef="let element">
        {{element.recentLogin ? 'Los Angeles':null}}
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Active</th>
      <td mat-cell *matCellDef="let element">
          <mat-checkbox class="top-margin" [checked]="element.is_activated == 'true'" [disabled]="disabled"
              (change)="changeUserActiveStatus(element, $event.checked)">
          </mat-checkbox>
      </td>
  </ng-container>
  <ng-container matColumnDef="telemed">
      <th mat-header-cell *matHeaderCellDef>Telemed</th>
      <td mat-cell *matCellDef="let element">
          <mat-checkbox class="top-margin" [checked]="element.other_settings.show_telemedicine == true"
              [disabled]="disabled" (change)="changeTelemedicineStatus(element, $event.checked)">
          </mat-checkbox>
      </td>
  </ng-container>
  <ng-container matColumnDef="pcc">
      <th mat-header-cell *matHeaderCellDef>PCC</th>
      <td mat-cell *matCellDef="let element">
          <mat-checkbox class="top-margin" [checked]="element.has_pcc_account == 'true'" [disabled]="disabled"
              (change)="updateUserOptionStatus('has_pcc_account',element, $event.checked)">
          </mat-checkbox>
      </td>
  </ng-container>
  <ng-container matColumnDef="enable_dictation">
    <th mat-header-cell *matHeaderCellDef>Enable Dictation</th>
    <td mat-cell *matCellDef="let element" style="text-align-last: center;">
      <mat-checkbox class="tp-margin" [checked]="element.is_dictation_enabled"
        (change)="changeDictationStatus(element, $event.checked)">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="admin_">
    <th mat-header-cell *matHeaderCellDef>Admin</th>
    <td mat-cell *matCellDef="let element" style="text-align-last: center;">
      <mat-checkbox class="tp-margin" [checked]="element.admin_type == 'full_admin'"
        [disabled]="element._id === currentCompany?.user_id?._id"
        (change)="changeAdminStatus(element, $event.checked)">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="cognito">
      <th mat-header-cell *matHeaderCellDef>Cognito</th>
      <td mat-cell *matCellDef="let element" style="text-align-last: center;">
          <mat-checkbox class="tp-margin" [checked]="element.enableCognito == true" [disabled]="disabled"
              (change)="updateUserOptionStatus('enableCognito',element, $event.checked)">
          </mat-checkbox>
      </td>
  </ng-container>
  <ng-container matColumnDef="user-permissions">
    <th mat-header-cell *matHeaderCellDef>Permissions</th>
    <td mat-cell *matCellDef="let element">
      <mat-form-field appearance="fill" [ngClass]="{
                      'user-permissions-form-field': true,
                      'permissions-selected': element.permissions.length > 0
                    }">
        <mat-label>Select permissions...</mat-label>
        <mat-select multiple disableRipple [ngModel]="element.permissions"
          (selectionChange)="changeUserPermissions( element, $event )">
          <mat-select-trigger>
            {{ resolvePermission(element.permissions[0]) }}
            <span *ngIf="element.permissions.length > 1" class="example-additional-selection">
              (+{{ element.permissions.length - 1 }} {{ element.permissions.length === 2 ? 'other' : 'others' }})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let perm of permissionsList" [value]="perm.key">{{ perm.value }}</mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>
    <ng-container matColumnDef="admin">
      <th mat-header-cell *matHeaderCellDef>Admin</th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field style="width: 90px;">
          <mat-select (selectionChange)="handleChangeAdminType(element._id,$event)" [ngModel]="element.admin"
            [disabled]="currentUser._id == element._id || element.admin_type == 'system_admin'">
            <mat-option value="none">
              None
            </mat-option>
            <mat-option value="full_admin">
              Full Admin
            </mat-option>
            <mat-option *ngIf="company_type?.toLowerCase()!='wound'" value="transcriber_admin">
              Transcriber Admin
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="admin-access">
      <th mat-header-cell *matHeaderCellDef>Admin</th>
      <td mat-cell *matCellDef="let element" style="text-align-last: center;">
        <mat-checkbox class="tp-margin" [checked]="element.admin_type == 'full_admin'"
          [disabled]="element._id === currentCompany?.user_id?._id"
          (change)="changeAdminStatus(element, $event.checked)">
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="user-type">
      <th mat-header-cell *matHeaderCellDef>User Type</th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field>
          <mat-label>User Type</mat-label>
          <mat-select (selectionChange)="handleChangeUserType(element._id,$event)" [ngModel]="usersFilter.user_type">
            <mat-option value="1">
              Provider
            </mat-option>
            <mat-option value="test_provider">
              Test Provider
            </mat-option>
            <mat-option *ngIf="company_type?.toLowerCase()!='wound'" value="2">
              Transcriber
            </mat-option>
            <mat-option value="nurse">
              Nurse
            </mat-option>
            <mat-option value="6">
              Biller
            </mat-option>
            <mat-option value="medical_assistant">
              Medical Assistant
            </mat-option>
            <mat-option value="view_only_user">
              View Only User
            </mat-option>
            <mat-option value="business_officer">
              Business Officer
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="companies">
      <th mat-header-cell *matHeaderCellDef>Company</th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field style="color: black !important;" class="flex-grow">
          <input type="text" placeholder="Select Company" aria-label="company" matInput [formControl]="companyControl"
            [matAutocomplete]="companyAutoControl">
          <mat-autocomplete #companyAutoControl="matAutocomplete">
            <mat-option *ngFor="let company of filteredCompanies | async" [value]="company.name ?company.name:''"
              (click)="onCompanyChange(element , company._id);">
              {{company.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>