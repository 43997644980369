import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as global from '../../global';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from "../../../../environments/api-url";

@Injectable({
    providedIn: 'root',
})
export class CensusCommentsService {
    currentUser:any = {};
    userInfo:any = {};
    public constructor(private httpclient: HttpClient,
        private _authService: AuthService) {
        const currentUser = this._authService.currentUser;
        if (currentUser) {
            // this.currentUser = JSON.parse(currentUser);
            this.currentUser = currentUser;
            this.userInfo = {
                usertype: this.currentUser.user_type,
                auth_key: this.currentUser.auth_key,
                id: this.currentUser._id
            }
        }
    }
    get_census_comments(patient_id, census_id) {
        var details = {
            ...this.userInfo,
            patient_id: patient_id,
            rounding_sheet_id: census_id
        };
        return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getPatientRoundingSheetComments, { details: details }).pipe();
    }
}