import { Component, OnInit, ViewChild, EventEmitter, ViewEncapsulation } from '@angular/core';
import {Location} from '@angular/common';
import { Router } from '@angular/router';
import {PatientAddFormComponent} from '../patient-add-form/patient-add-form.component'
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../global';
@Component({
  selector: 'app-patient-add',
  templateUrl: './patient-add.component.html',
  styleUrls: ['./patient-add.component.css'],
  encapsulation:ViewEncapsulation.None
})

export class PatientAddComponent implements OnInit{
  currentUser:any;
  global = global;
  admin:boolean=false
  company:boolean=false
  @ViewChild(PatientAddFormComponent) patientAddFormComponent:PatientAddFormComponent;
  isEditing: boolean = false;

  constructor( 
      private _router:Router,
      private _location:Location, 
      private _authService: AuthService,

    ) {
      // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.currentUser = this._authService.currentUser;
    }
    ngOnInit() {
      if (window.location.pathname.includes('admin')) {
        this.admin = true;
      }
      else if (window.location.pathname.includes('company')) {
        this.company = true;
      }
      else {
        this.admin = false;
      }
  
      if(!this.currentUser) {
        // if not login, stop and redirect to login #mohsin-dev
        localStorage.setItem('currentUser', 'null');
        this._router.navigate(['/login']);
        return;
      }
    }

    onSaveClick($event) {
      const onSuccess = new EventEmitter();
      onSuccess.subscribe(() => {
  //      this._router.navigate(['/patients/list']);
        this._location.back(); 

      })
      this.patientAddFormComponent.savePatient($event, onSuccess);
    }
    onCancelClick() {
      this._location.back();
      //this._router.navigate(['/patients/list']);
    }
 
    isEditingPatient($event){
      console.log('$event',$event);
      this.isEditing = true;
    }

  goBack() {
    this._location.back(); 
    // history.back();
  } 
}

  