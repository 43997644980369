import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FacilityService } from 'src/app/services/facility.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { DoctorProfileService } from 'src/app/services/Modules/doctorProfile.service';
import * as usa_states from '../../../../shared/popup-dialogs/facility-add-dialog/usa_states';
import { Location } from '@angular/common';
import * as global from '../../../../includes/global'
import { TranscriptionCreateService } from 'src/app/includes/transcription-create/transcription-create.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/Modules/user.service';
import { lastValueFrom } from 'rxjs';
import { DashboardService } from 'src/app/includes/dashboard/dashboard.service';
import { SnfWcNurseAssociationService } from 'src/app/includes/snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';

interface IPCCNoteUploadingMethod {
    facility_id: string;
    note_uploading_method: 'progress_note' | 'pdf_document';
}
interface IMatrixNoteUploadingMethod {
    facility_id: string;
    note_uploading_method: 'progress_note' | 'pdf_document';
}
@Component({
  selector: 'app-doctor-profile-facilities',
  templateUrl: './doctor-profile-facilities.component.html',
  styleUrls: ['./doctor-profile-facilities.component.css']
})
export class DoctorProfileFacilitiesComponent implements OnInit {
  usa_states = usa_states.default;
  facilities: any[] = [];
  // displayedColumns: any = ['facility', 'address', 'city', 'state', 'zip_code', 'operations'];
  // displayedColumns: any = ['facility', 'is_active', 'address', 'TrueRCM ID', 'note_uploading_method_per_facility', 'city', 'state', 'zip_code', 'pcc_2_legged_authentication', 'operations'];
  displayedColumns: any = ['facility', 'address', 'note_uploading_method_per_facility', 'city', 'state', 'zip_code', 'pcc_2_legged_authentication', 'operations'];
  dataSource: any;

  loaderId = 'company-facilities-loader';
  params: {
    id: string
  }
  hasDestinationSaved: boolean = false;
  timeoutId: any;
  provider_id: any;
  currentUser: any;
  noteUploadingMethods = [
    {
      value: 'progress_note',
      label: 'Progress Note'
    },
    {
      value: 'encounter_note',
      label: 'Encounter Note'
    }, 
    {
      value: 'pdf_document',
      label: 'PDF Document'
    }
  ];
  pcc_note_uploading_method_per_facility = [] as IPCCNoteUploadingMethod[]
  matrix_note_uploading_method_per_facility = [] as IMatrixNoteUploadingMethod[]
  matrix_pdf_destination = []
  currentProfileUser: any = {};
  constructor(
    private doctorProfileService: DoctorProfileService,
    private companyService: CompanyService,
    private facilityService: FacilityService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private loader: NgxUiLoaderService,
    private _location: Location,
    private _authService: AuthService,
    private _transcriptionCreateService: TranscriptionCreateService,
    private userService: UserService,
    private _dashboardService: DashboardService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
  ) {
    this.params = {
      id: this.route.parent.parent.snapshot.params.id
    };
    this.currentUser = this._authService.currentUser;
  }
  async ngOnInit() {  
    await this.getCurrentProfileUser();
    if(this.currentUser.product_owner == true){
      this.displayedColumns = ['facility', 'address', 'note_uploading_method_per_facility',
      // 'matrix_pdf_destination', 
      'city', 'state', 'zip_code', 'pcc_2_legged_authentication', 'operations'];
    }
    // console.log("provider_id: ", this.route.parent.snapshot.params.provider_id);

    await this.initUserFacilities();
    this.initNoteUploadingMethodAndDestPerFacility();
  }
  async getCurrentProfileUser() {
    const response: any = await lastValueFrom(this._dashboardService.getUserDetail(this.route.snapshot.params.provider_id));
    if (response.status === 200)
      this.currentProfileUser = response.data.array;
  }

  initUserFacilities = async () => {
    this.loader.startLoader(this.loaderId);
    const company_id = this.params.id;
    let path = window.location.pathname.split('provider_id');

    path = path[1].split('=')
    if (path.length >= 2) {
      if (path[1].includes(';')) {
        path = path[1].split(';')
        this.provider_id = path[0]
      }
      else {
        this.provider_id = path[1]
      }
    }
    else if (path.length < 2) {
      if (path[0].includes(';')) {
        path = path[0].split(';')
        this.provider_id = path[0]
      }
      else {
        this.provider_id = path[0]
      }
    }
    if (this.currentProfileUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      await this.getAssociatedFacilitiesForSnfWcNurse();
    }
    else {
      const filter = {
        is_active: true,
        assoc_company_ids: company_id,
        provider_ids_ref: this.provider_id
      }
      const projection = {
        'title': 1,
        'address': 1,
        'city': 1,
        'state': 1,
        'zip_code': 1,
        'is_active': 1,
        'source': 1,
        'assoc_company_ids': 1
      }

      let companyAssocPopulation: facilityPopulationInterface = {
        collection: 'companies',
        field: ["assoc_company_ids"],
        filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: [true, { $in: ['$_id', '$$assoc_company_ids'] }] }] } },
        project: { name: 1 },
      };

      const response: any = await this.facilityService.getFacilities(filter, projection, [companyAssocPopulation]).toPromise();
      if (response.status === 200) {
        this.facilities = response.data.array;
      }
    }
    this.loader.stopLoader(this.loaderId);
    this.dataSource = new MatTableDataSource(this.facilities);
  }
  async getAssociatedFacilitiesForSnfWcNurse() {
    const filter = {
      associated_snf_wc_nurse_ids: this.currentProfileUser._id,
      company_id: this._authService.currentUser.company_id
    };
    const nurseProjection = {
      first_name: 1,
      last_name: 1,
      title: 1,
    };
    const facilityProjection = {
      title: 1,
      pcc_facId: 1,
      pcc_2_legged_authentication: 1,
      source: 1,
      pcc_orgUuid: 1
    };
    const response: any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
    if (response.status === 200 && response.data.length > 0) {
      this.facilities = response.data;
    }
  }
  savepdfDestination(facility, value) {  
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.hasDestinationSaved = true;
        this.saveMatrixPDFDesination(facility, value);
      }, 1000); 
  }
  async saveMatrixPDFDesination(facility, dest){
    dest = dest.trim();
    let res:any = await lastValueFrom(this.userService.saveMatrixPDFDesination(this.provider_id, facility._id, dest.toString())) as any;
    if(res.status == 200){
      this.hasDestinationSaved = true;
      this.toastr.success('Destination updated')
    }
    else{
      this.toastr.error('Something went wrong')
    }
  }
  getPDFDestValue(facility){
    const obj = this.matrix_pdf_destination.map((item) => { 
      if (item.facility_id == facility._id) { 
        return item.destination;
      }
    }).filter(a => a);  
    if(obj && obj[0]){
      return obj[0]
    }
    else{
      return "Resident Notes"
    }
  }
  onChangeStatus(ele, $event) {
    // console.log(ele);
    // console.log($event);
    delete ele.companies;
    ele.is_active = $event.checked;
    const facilityData = {
      ...ele
    }
    this.companyService.updateFacility(facilityData).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("Status changed");
        console.log(response);
      }
    });
  }

  onDeleteClick(facility) {
    if (!confirm('Are you sure you want to delete?')) {
      return;
    }

    this.companyService.deleteFacility(facility._id).subscribe((response: any) => {
      if (response.status === 200) {
        this.initUserFacilities();
      }
    })
  }

  onChangePcc2LeggedAuthentication(ele, $event) {
    // console.log(ele);
    // console.log($event);
    delete ele.companies;
    ele.pcc_2_legged_authentication = $event.checked;
    const facilityData = {
      ...ele
    }
    this.companyService.updateFacility(facilityData).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("2-Legged Auth changed");
        // console.log(response);
      }
    });
  }

  goBack() {
    this._location.back()
  }
  checkEnvironment() {
    if (global.ENVIRONMENT == 'LOCAL' || global.ENVIRONMENT == 'DEV') {
      return true;
    }
    else {
      return false;
    }
  }
  async initNoteUploadingMethodAndDestPerFacility() {
    if(!this.provider_id) return;
    const {status, data, matrixData, matrixPDFDest} = await lastValueFrom(this.userService.getNoteUploadingAndDestMethod(this.provider_id)) as any;
    if (status === 200) {
      this.pcc_note_uploading_method_per_facility = data;
      this.matrix_note_uploading_method_per_facility = matrixData;
      // this.matrix_pdf_destination = matrixPDFDest;
    }
  }
  async onNoteUploadingMethodChange(facility, $event) {
    if(!this.provider_id) return; 
    if(facility.source == 'MatrixCare'){
      const obj = this.matrix_note_uploading_method_per_facility.find((item) => {
        if (item.facility_id == facility._id) {
          return item.note_uploading_method;
        }
      });
      if(obj) {
        obj.note_uploading_method = $event.value.value;
      } else {
        this.matrix_note_uploading_method_per_facility.push({
          facility_id: facility._id,
          note_uploading_method: $event.value.value
        });
      }
      const {status, matrixData} = await lastValueFrom(this.userService.updateMatrixNoteUploadingMethod(this.provider_id, this.matrix_note_uploading_method_per_facility)) as any;
      if (status === 200) {
        this.toastr.success('Note Uploading Method Updated');
      }
    }
    else {
      const obj = this.pcc_note_uploading_method_per_facility.find((item) => {
        if (item.facility_id == facility._id) {
          return item.note_uploading_method;
        }
      });
      if(obj) {
        obj.note_uploading_method = $event.value.value;
      } else {
        this.pcc_note_uploading_method_per_facility.push({
          facility_id: facility._id,
          note_uploading_method: $event.value.value
        });
      }
      const {status, data} = await lastValueFrom(this.userService.updatePccNoteUploadingMethod(this.provider_id, this.pcc_note_uploading_method_per_facility)) as any;
      if (status === 200) {
        this.toastr.success('Note Uploading Method Updated');
      }
    }
  }
  getNoteUploadingMethodValue(facility) {
    if(facility.source == 'MatrixCare'){
      const obj = this.matrix_note_uploading_method_per_facility.find((item) => {
        if (item.facility_id == facility._id) {
          return item;
        }
      }); 
      if(obj) {
        const option = this.noteUploadingMethods.find((item) => {
          if (item.value == obj.note_uploading_method) {
            return item;
          }
        }); 
        
        if(option) {
          return option
        }
      }
      return this.noteUploadingMethods[0];
    }
    else{
      const obj = this.pcc_note_uploading_method_per_facility.find((item) => {
        if (item.facility_id == facility._id) {
          return item;
        }
      });
      if(obj) {
        const option = this.noteUploadingMethods.find((item) => {
          if (item.value == obj.note_uploading_method) {
            return item;
          }
        });
        if(option) {
          return option
        }
      }
      return this.noteUploadingMethods[0];
    }
  }
  
  // getPCCNoteUploadingMethod(facility) {
  //   const obj = this.pcc_note_uploading_method_per_facility.find((item) => {
  //     if (item.facility_id == facility._id) {
  //       return item.note_uploading_method;
  //     }
  //   });
  //   if(obj) {
  //     const option = this.noteUploadingMethods.find((item) => {
  //       if (item.value == obj.note_uploading_method) {
  //         return item;
  //       }
  //     });
  //     if(option) {
  //       return option
  //     }
  //   }
  //   return this.noteUploadingMethods[0];
  // }
}

