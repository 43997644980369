<app-header></app-header>

<main>
  <div class="container flex flex-col">
    <div
      class="editor-sidebar-wrapper flex flex-grow justify-start items-stretch"
      style="position: relative"
    >
      <div class="flex flex-col flex-grow">
        <div class="editor-top flex flex-col gap-2">
          <div
            class="editor-top-buttons flex justify-center items-center gap-2">
            <button class="dn-button-primary" mat-raised-button   
            *ngIf="template_type =='system_defined' && !companySide"
            id="exportTemplate"
                  (click)="exportTemplate(templateId)"
                  [disabled]="template.is_locked">
                Export Template
            </button>
            <button
            *ngIf="(template_type =='user_defined') && template.owner_id === currentUser._id"
              class="dn-button-primary"
              mat-raised-button
              (click)="showPreviewTemplateDialog()"
            >
              Preview
            </button>
            
            <button
            *ngIf="
            (template_type =='user_defined' && iscompanySameAndAdmin) || (template_type =='user_defined' && currentUser._id === template.owner_id) || (template_type =='user_defined' && currentUser.product_owner) ||
            ( currentUser.user_type == 'medical_assistant' ) || 
            companySide || 
            (!templateIdFromUrl && (currentUser.admin_type === 'full_admin' || currentUser.admin_type === 'system_admin'))"
              class="dn-button-primary"
              mat-raised-button
              (click)="saveTemplate()"
            >
              Save
            </button>
            <button
              class="dn-button-secondary"
              (click)="goBack()"
              mat-raised-button
            >
              Cancel
            </button>
            
            <mat-checkbox
            *ngIf="company.portal_type === global.PORTAL_TYPE.NON_WC"
              (change)="onDragonStyleChange( $event )"
              [checked]="useDragonStyle"
              class="dragon-style-checkbox"
            >
              Use Dragon style?
            </mat-checkbox>
          </div>
          <div
            class="editor-top-left flex justify-between items-end gap-4">
            <mat-form-field class="template-title" style="width: 40%">
              <input
                matInput
                class="uppercase"
                onkeypress="return /[A-z/^\d+$/ ]/.test(event.key)"
                placeholder="TITLE"
                [(ngModel)]="template.title"
                required
              />
            </mat-form-field>

            <mat-form-field appearance="fill" class="facility-source-form-field" style="width: 24%;">
              <mat-label>{{ selectedForNoteType === null ? 'Select Type' : 'Template Type' }}</mat-label>
              <mat-select [(value)]="selectedForNoteType" (selectionChange)="onNoteTypeChange()">
                <mat-option
                  [value]="nType.value"
                  [ngStyle]="{ display : ( nType.value === null && nType.value === selectedForNoteType ) ? 'none' : null }"
                  *ngFor="let nType of forNoteTypes"
                >
                  {{ nType.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="facility-source-form-field" style="width: 23%;">
              <mat-label>{{ selectedFacilitySource === null ? 'Select Facility Source *' : 'Facility Source' }}</mat-label>
              <mat-select [(value)]="selectedFacilitySource" [disabled]="selectedFacilitySource !== null" (selectionChange)="onFacilitySourceChange()">
                <mat-option
                  [value]="source.value"
                  [ngStyle]="{ display : ( source.value === null && source.value === selectedFacilitySource ) ? 'none' : null }"
                  *ngFor="let source of facilitySources"
                >
                  {{ source.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="template-title" style="margin-top: -19px !important;width: 40%" appearance="fill">
              <mat-label>SELECT TEMPLATES</mat-label>
              <mat-select [(ngModel)]="template.title">
                <mat-option [value]="option.value" *ngFor="let option of templateOptions">
                  {{option.value}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <ng-multiselect-dropdown name="cpts" class="cpt-codes" appearance="fill"
            [settings]="CPTDropdownSettings"
            [placeholder]="'Select CPTs'"
            [(ngModel)]="template.assoc_cpt_ids"
            [data]="cpts"
            (onSelect)="onCPTSelect($event)" 
            (onDeSelect)="onCPTDeSelect($event);"
            >
            </ng-multiselect-dropdown>
            <mat-form-field class="facility-fonts">
              <mat-chip-grid #facilityChipList aria-label="Select Facility">
                <mat-chip-row
                  *ngFor="let facility of facilities.slice(0, 4)"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="removeFacilityAsChip(facility)"
                >
                  <app-facility-title
                    [facility]="facility"
                  ></app-facility-title>

                  <mat-icon matChipRemove >cancel</mat-icon>
                </mat-chip-row>
                <div *ngIf="facilities.length > 4">
                  <button mat-icon-button [matMenuTriggerFor]="selectedFacilitiesMenu" style="font-size: small; white-space: nowrap;">{{ facilities.length - 4 }} more</button>
                </div>
                <input
                  (focus)="handleFacilityInputFocus($event)"
                  placeholder="{{facilities.length == 0 ? 'Select Facility *' : '' }}" 
                  #facilityInput
                  required
                  [formControl]="facilityCtrl"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="facilityChipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="addFacilityAsChip($event)"
                />
              </mat-chip-grid>
              <mat-menu #selectedFacilitiesMenu="matMenu">
                <ng-container *ngFor="let facility of facilities.slice(4)">
                  <div>
                    <app-facility-title [facility]="facility" style="width: 10rem; white-space: nowrap;"></app-facility-title>
                    <mat-icon style="float: right; color: gray; font-size: 1.2rem; cursor: pointer;" (click)="removeFacilityAsChip(facility)">cancel</mat-icon>
                  </div>
                </ng-container>
              </mat-menu>
              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="selectedFacilityAsChip($event)"
              >
                <mat-option
                  *ngFor="let facility of filteredFacilities | async"
                  [value]="facility"
                >
                  <app-facility-title
                    [facility]="facility"
                  ></app-facility-title>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

          </div>
        </div>
        <div class="template-editor flex-grow">
          <ckeditor
            [config]="cke4Config"
            editorUrl="../../../assets/ckeditor/ckeditor.js"
            [(ngModel)]="template.template_text"
            (ready)="onReadyCkeditor($event)"
          ></ckeditor>
        </div>
      </div>

      <div class="sidebar-wrapper flex justify-center items-stretch">
        <button class="sidebar-toggle-btn" (click)="sidebarVisible=!sidebarVisible">
            <mat-icon *ngIf="sidebarVisible">keyboard_arrow_right</mat-icon>
            <mat-icon *ngIf="!sidebarVisible">keyboard_arrow_left</mat-icon>
        </button>
        <app-template-create-sidebar
          *ngIf="sidebarVisible"
            [selectedSource]="selectedFacilitySource"
            [forNoteType]="selectedForNoteType"
            [useDragonStyle]="useDragonStyle"
            [updateTabGroup]="updateTabGroup"
        ></app-template-create-sidebar>
      </div>
    </div>
  </div>
</main>