<span> <strong>Physician</strong> : {{physician}}</span>
<br>
<span><strong>Room Number</strong> : {{roomNumber}}</span>
<h1>PT</h1>

<table mat-table [dataSource]="dataSourcePT">
    <ng-container matColumnDef="dos">
        <th mat-header-cell *matHeaderCellDef>DOS</th>
        <td mat-cell *matCellDef="let element" style="cursor: pointer;">
            <span>{{element.dos | date}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="transfers">
        <th mat-header-cell *matHeaderCellDef>Transfers</th>
        <td mat-cell *matCellDef="let element" style="cursor: pointer;">
            <span>{{element.transfers}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="bed_mobility">
        <th mat-header-cell *matHeaderCellDef >Bed Mobility</th>
        <td mat-cell *matCellDef="let element" style="cursor: pointer;">
            <span>{{element.bed_mobility}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="gait">
        <th mat-header-cell *matHeaderCellDef>Gait</th>
        <td mat-cell *matCellDef="let element" style="cursor: pointer;">
            <span>{{element.gait}}</span>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumnsPT; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsPT;"></tr>
</table>

<h1>OT</h1>
<table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="dos">
        <th mat-header-cell *matHeaderCellDef>DOS</th>
        <td mat-cell *matCellDef="let element" style="cursor: pointer;">
            <span>{{element.dos | date}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="lb_dressing">
        <th mat-header-cell *matHeaderCellDef >LB Dressing</th>
        <td mat-cell *matCellDef="let element" style="cursor: pointer;">
            <span>{{element.lb_dressing}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="ub_dressing">
        <th mat-header-cell *matHeaderCellDef>UB Dressing</th>
        <td mat-cell *matCellDef="let element" style="cursor: pointer;">
            <span>{{element.ub_dressing}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="toileting">
        <th mat-header-cell *matHeaderCellDef>Toileting</th>
        <td mat-cell *matCellDef="let element" style="cursor: pointer;">
            <span>{{element.toileting}}</span>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumnsOT; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsOT;"></tr>
</table>