<div class="wound-image-upload-dialog">
    <h1 mat-dialog-title style="text-align: center;">{{wound.createdAt | date: 'MM/dd/yyyy'}}</h1>
    <h2  mat-dialog-title style="font-size: 16px;">Upload Wound Image</h2>

    <div class="flex flex-col">
        <div class="flex items-center justify-center gap-5">
            <input
                type="file"
                id="wound-image-upload"
                accept="image/*"
                (change)="onFileChange($event)"
                multiple
                #woundImageUpload
            />
            <button mat-button *ngIf="selectedImages.length > 0" (click)="uploadWoundImages()">
                <mat-icon>cloud_upload</mat-icon>
                Upload {{ selectedImages.length }} {{ selectedImages.length > 1 ? 'Images' : 'Image'}} 
            </button>
    </div>

    <div class="vertical-scrollable" >

        <div class="flex flex-wrap gap-1">
            <ng-container *ngFor="let image of selectedImages;let i=index">
                <div id="image-card"  style="position: relative;">
                        <img (click)="imageEdit(image,i)" mat-card-image [src]="image.src" alt="{{image.name}}" class="image" />
                        <button mat-icon-button class="remove-image-btn" (click)="removeImage(image)">
                            <mat-icon>cancel</mat-icon>
                        </button>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>
