import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-clinical-summary-report',
  templateUrl: './clinical-summary-report.component.html',
  styleUrls: ['./clinical-summary-report.component.css']
})
export class ClinicalSummaryReportComponent implements OnInit {
  @Input() clinicalSummaryReport = [];
  @Input() facilityTitle = "";
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = [
    'faciltiy', 'company', 'totalWounds', 'improving', 'stalled', 'deteriorating',
    'inRemission', 'amputations', 'healingSuccess', 'avgDaysToRemission', 'skinTear', 'pressure',
    'diabetic', 'venous', 'arterial', 'masd', 'other'];

  constructor() { }

  ngOnInit(): void {
    this.dataSource.data = this.clinicalSummaryReport;
  }
  calculateHealingSuccess(inRemissionWounds, activeWounds) {
    let successRatio = 0;
    if (inRemissionWounds) {
      successRatio = inRemissionWounds / (inRemissionWounds + activeWounds);
    }
    return successRatio.toFixed(2);
  }
  calculatePercentage(single, total) {
    let percentage = 0;
    if (single) {
      percentage = (single / total) * 100;
    }
    return `${percentage.toFixed(2)} %`;
  }

}
