import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
// import * as _ from 'lodash';
// import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AuthService } from 'src/app/services/auth.service';
import { PatientRoundingSheetService } from 'src/app/includes/patient-rounding-sheets/patient-rounding-sheet.service';
import { ChargesCatagoryService } from '../charges-catagory.service';
import { ChargesCatagoriesDialogComponent } from '../charges-catagories-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-manage-categories',
  templateUrl: './manage-categories.component.html',
  styleUrls: ['./manage-categories.component.css']
})
export class ManageCategoriesComponent implements OnInit {
  displayedColumns: string[] = ['code', 'description', 'favourite'];
  dataSource: MatTableDataSource<any>;
  // favouriteDataSource: MatTableDataSource<any>;
  categorisedChargesDataSource: MatTableDataSource<any>;
  loaderId = ['loader-01', 'loader-02'];
  filter = 'cpt_id';
  rearrangeFavorite: any = [];
  company_id = ''; 
  favouriteData = {
    cpts: [],
    icds: []
  };
  searchFilter = {
    all: '',
    favourite: ''
  };
  favourite = {
    cpt_id: [],
    icd_id: []
  }
  icds = [];
  cpts = [];
  allData = [];
  public pagination: {
    pageNo: number,
    hasNextPage: boolean,
    hasPrevPage: boolean,
    totalCount: number,
    totalPages: number
  };
  lastValue = '';
  categories: any = [];
  selectedCategory: any;
  categorisedChargesData: any;
  currentUser:any;
  chargesCatagoriesDialogComponent:any;
  constructor( 
    private service: PatientRoundingSheetService,
    private toastr: ToastrService,
    // private router: Router,
    private route: ActivatedRoute,
    private loader: NgxUiLoaderService,
    private authService: AuthService,
    private chargesCatagoryService: ChargesCatagoryService,
    private dialog: MatDialog, 
    private _commonService: CommonService,
    // private _location: Location,
    ) {
      this.currentUser = this.authService.currentUser;
    this.pagination = {
      pageNo: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalCount: 0,
      totalPages: 0
    };
  }

  async ngOnInit() {
    this.company_id = this.route.parent.snapshot.params.id;
    await this.getCategories(this.filter); 
  }

  async refreshData(event) { 
    await this.getCategories(this.filter);
  }
  categoryChanged(event) {
    this.getCategorisedCharges()
  }

  getCategories(type = null) {
    // let type;
    if(type){
      type = this.filter =='cpt_id'? 'CPT': 'ICD';
    }
    
    let user = this.currentUser._id;
    //we will pass user to get categories only cretaed by that admin logged-in
      let result = this.chargesCatagoryService.getCategories(type, null, this.company_id ).subscribe((response: any) => {
      if (response.status == 200) {
        this.categories = response.data; 
        if(this.categories.length > 0){
          this.selectedCategory = this.categories[0];
          this.getCategorisedCharges()
        }
      }
    })
  }
  addtoCategorisedCharge(element) {
    let cr_id = this.selectedCategory._id;
    if (this.filter == 'cpt_id') {
      let result = this.chargesCatagoryService.setCPTCategorisedCharge(element, cr_id).subscribe((response: any) => {
        if (response.status == 200) {
          this.getCategorisedCharges();
          this.loadData(this.pagination.pageNo);
        }
      })
    }
    else if (this.filter == 'icd_id') {
      let result = this.chargesCatagoryService.setICDCategorisedCharge(element, cr_id).subscribe((response: any) => {
        if (response.status == 200) {
          this.getCategorisedCharges();
          this.loadData(this.pagination.pageNo);
        }
      })
    }
  }
  removeCategorisedCharge(charge, type = null) {
    let chargeID;
    let cr_id = this.selectedCategory?._id;
    if (this.filter == 'cpt_id') {
      if (type) {
        chargeID = charge.cpt_id_ref
      }
      else chargeID = charge._id;
      let result = this.chargesCatagoryService.removeCPTCategorisedCharge(chargeID, cr_id).subscribe((response: any) => {
        if (response.status == 200) {
          this.getCategorisedCharges();
          this.loadData(this.pagination.pageNo);
        }
      })
    }
    else if (this.filter == 'icd_id') {
      if (type) {
        chargeID = charge.icd_id_ref
      }
      else chargeID = charge._id;
      let result = this.chargesCatagoryService.removeICDCategorisedCharge(chargeID, cr_id).subscribe((response: any) => {
        if (response.status == 200) {
          this.getCategorisedCharges();
          this.loadData(this.pagination.pageNo);
        }
      })
    }
  }
  getCategorisedCharges() {
    this.loader.startLoader('loader-02');
    let cr_id = this.selectedCategory?._id;
    if (this.filter == 'cpt_id') {
      let result = this.chargesCatagoryService.getCPTCategorisedCharges(cr_id).subscribe((response: any) => {
        if (response.status == 200) { 
          this.loader.stopLoader('loader-02');
          this.categorisedChargesData = response.data.array; 
          this.categorisedChargesDataSource = new MatTableDataSource<any>(response.data.array);
          this.loadData()
        }
      })
    }
    else if (this.filter == 'icd_id') {
      let result = this.chargesCatagoryService.getICDCategorisedCharges(cr_id).subscribe((response: any) => {
        if (response.status == 200) {
          this.loader.stopLoader('loader-02');
          this.categorisedChargesData = response.data.array;
          this.categorisedChargesDataSource = new MatTableDataSource<any>(response.data.array);
          this.loadData()
        }
      })
    }

  }
  getCode(element){
    return element.code
  }
 


  searchCharges(value) {
    if (this.lastValue !== '' && value === '') {
      this.loadData(1, '');
      return;
    }
    this.lastValue = value;
    if (value !== '') {
      // setTimeout(function(){    
      //     console.log("calling....");

      this.loadData(1, value);
      // }, 500)
      return;
    }
  }
 

  loadData(page = 1, search = '') {
    if (this.filter === 'cpt_id') {
      if (!search && search == '') {
        this.loader.startLoader('loader-01');
      }
      this.service.getCPTs({ page: page, search: search })
        .subscribe(res => {
          //@ts-ignore
          for (let i = 0; i < res.data.array.length; i++) {
            for (let j = 0; j < this.categorisedChargesData.length; j++) {
              //@ts-ignore
              if (this.categorisedChargesData[j].cpt_id_ref == res.data.array[i]._id) {
                //@ts-ignore
                res.data.array[i].isFavourite = true
                break;
              } else {
                //@ts-ignore
                res.data.array[i].isFavourite = false
              }
            }
          }
          // @ts-ignore
          this.cpts = res.data.array;
          this.allData = this.cpts;
          // @ts-ignore
          const totalCount = res.data.totalCount;
          this.pagination.totalCount = totalCount;
          this.pagination.pageNo = page;
          this.initPagination();
          this.dataSource = new MatTableDataSource(this.cpts);
          this.loader.stopLoader('loader-01');
        });
    } else {
      if (!search && search == '') {
        this.loader.startLoader('loader-01');
      }
      this.service.getICDs({ page: page, search: search })
        .subscribe(res => {
          //@ts-ignore
          for (let i = 0; i < res.data.array.length; i++) {
            for (let j = 0; j < this.categorisedChargesData.length; j++) {
              //@ts-ignore 
              if (this.categorisedChargesData[j].icd_id_ref == res.data.array[i]._id) {
                //@ts-ignore
                res.data.array[i].isFavourite = true
                break;
              } else {
                //@ts-ignore
                res.data.array[i].isFavourite = false
              }
            }
          }
          // @ts-ignore
          this.icds = res.data.array;
          this.allData = this.icds;
          // @ts-ignore
          const totalCount = res.data.totalCount;
          this.pagination.totalCount = totalCount;
          this.pagination.pageNo = page;
          this.initPagination();
          this.dataSource = new MatTableDataSource(this.icds);
          this.loader.stopLoader('loader-01');
        });
    }
  }


  initPagination() {
    this.pagination.totalPages = Math.ceil(this.pagination.totalCount / 10);
    if (this.allData) {
      this.pagination.hasNextPage = this.allData.length > 0 && this.pagination.pageNo < this.pagination.totalPages;
    }
    this.pagination.hasPrevPage = this.pagination.pageNo > 1;
  }

  handleNextClick() {
    this.loadData(this.pagination.pageNo + 1, this.searchFilter.all);
  }

  handlePrevClick() {
    this.loadData(this.pagination.pageNo - 1, this.searchFilter.all);
  }
  getCountofCodes() {
    return ((this.pagination.pageNo * 10) - 9) + ' - ' + (((this.pagination.pageNo * 10) - 10) + Number(this.allData.length)) + " out of " + (this.pagination.totalCount);
  }
  // goBack() {
  //   this._location.back();
  // }
  openCategoryDialoge(){
    this._commonService.setCompanySelected(this.company_id);
    this.chargesCatagoriesDialogComponent = this.dialog.open(ChargesCatagoriesDialogComponent, {width: '80%'})
  }

}

