import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FacilityService } from 'src/app/services/facility.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { UserService } from 'src/app/services/Modules/user.service';
import { USER_TYPE } from '../global';
import swal from 'sweetalert';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';
import { P } from '@angular/cdk/keycodes';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-provider-facility-association',
    templateUrl: './provider-facility-association.component.html',
    styleUrls: ['./provider-facility-association.component.css']
})
export class ProviderFacilityAssociationComponent implements OnInit {
    public response: any;
    public dataSource: any;
    public facilities: any = [];
    providers: any = [];
    providersName: any = [];
    selectedProviders: any = [];
    providerTranscriber: Boolean = true;
    providerBiller: Boolean = false;
    providerDropdownSettings: any = {};
    templateDropdownSettings: any = {};
    companySide: Boolean = false;

    displayedColumns: string[] = ['facility', 'provider', 'template'];
    @ViewChild(MatSort) sort: MatSort;
    searchProviderText: any = [];
    searchTemplateText: any = [];
    params: {
        id: string
    }
    filterStatus = true;
    loaderId = 'facility-provider-association-loader';

    pagination = {
        pageNo: 1,
        hasNextPage: false,
        hasPrevPage: false,
        totalFacilities: 0,
        totalPages: 0,
        currentRecords: 0
    };
    facilityTitleRegExp = "";
    providerTitleRegExp = "";
    PAGE_LIMIT = 20;
    timeoutId: any;
    hasFacilitiesLoaded: boolean = false;
    hasProvidersLoaded: boolean = false;
    showProviderPaginations: boolean = false;
    orignalData: any = [];

    constructor(
        public companyService: CompanyService,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private loader: NgxUiLoaderService,
        private facilityService: FacilityService,
        private userService: UserService,
        private router: Router
    ) {
        this.providerDropdownSettings = {
            singleSelection: false,
            idField: '_id',
            textField: 'full_name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 10,
            allowSearchFilter: true,
            enableCheckAll: true,
        };
        this.templateDropdownSettings = {
            singleSelection: false,
            idField: '_id',
            textField: 'title',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 10,
            allowSearchFilter: true,
            enableCheckAll: true,
        };
        if (window.location.pathname.includes('company')) {
            this.companySide = true
            this.params = {
                id: this.route.parent.snapshot.params.id
            };
        }
        else {
            this.companySide = false
            this.params = {
                id: ''
            };
        }
    }

    async ngOnInit() {
        this.searchProviderText = Array(this.PAGE_LIMIT).fill('');
        this.searchTemplateText = Array(this.PAGE_LIMIT).fill('');
        this.loader.startLoader(this.loaderId);
        await this.initProviders();
        //   await this.initFacilities();
        await this.initAssociationData()
        this.loader.stopLoader(this.loaderId);
    }
    searchFacilities() {
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
            this.hasFacilitiesLoaded = true;
            this.initAssociationData();
        }, 1000);
    }
    searchProviders() {
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
            this.hasProvidersLoaded = true;
            this.initAssociationData();
        }, 1000);
    }
    applyActiveFilter(event) {
        this.filterStatus = event.value;
        this.initAssociationData();
    }
    async initAssociationData() {
        this.loader.startLoader(this.loaderId);
        const company_id = this.params.id;

        const filter: any = {
            //is_active: null,
            is_active: this.filterStatus,
            company_id,
            pageNo: this.pagination.pageNo
        };
        if (this.facilityTitleRegExp !== "") {
            filter.title = this.facilityTitleRegExp;
            delete filter.pageNo;
        }
        if (this.providerTitleRegExp !== "") {
            filter.providerTitle = this.providerTitleRegExp;
            delete filter.pageNo;
        }
        const projection = {
            'title': 1,
            'address': 1,
            'city': 1,
            'state': 1,
            'zip_code': 1,
            'provider_ids_ref': 1,
            'template_ids_ref': 1
        }
        let result = await lastValueFrom(this.facilityService.getAllFacilitiesProviderTemplateAssoc(filter, projection));
        if (result['status'] == 200) {
            this.pagination.totalFacilities = result['data'].totalFacilities;
            result['data'] = result['data'].facilities.map(ele => {
                ele.allTemp.map(temp => {
                    temp.title = temp.title.toUpperCase();
                    return temp;
                });
                return ele;
            });
            this.facilities = result['data'];
            // console.log("fac",this.facilities);
            this.facilities.map(facility => {
                facility.providersDropDown = this.providers;
            })
            this.updatePageCount(filter);
            this.dataSource = new MatTableDataSource(this.facilities);
            this.orignalData = JSON.parse(JSON.stringify(this.facilities));
            this.loader.stopLoader(this.loaderId);
            this.hasFacilitiesLoaded = false;
            this.hasProvidersLoaded = false;
        }
        else if (result['status'] == 403) {
            this.toastr.error(result['message'], "ERROR");
            this.router.navigate(['/403']);
        }
    }


    updatePageCount(filter) {
        if (filter.title || filter.providerTitle) {
            this.pagination.currentRecords = this.pagination.totalFacilities;
            this.pagination.hasNextPage = false;
            this.pagination.hasPrevPage = false;
            if (filter.providerTitle) {
                this.showProviderPaginations = true;
            }
        } else if (filter.pageNo) {
            this.pagination.currentRecords = (this.pagination.pageNo - 1) * this.PAGE_LIMIT + this.facilities.length;
            this.pagination.hasNextPage = this.pagination.currentRecords < this.pagination.totalFacilities;
            this.pagination.hasPrevPage = this.pagination.pageNo > 1;
        }
    }

    initFacilities = async () => {
        const company_id = this.params.id;
        const filter = {
            is_active: this.filterStatus,
            assoc_company_ids: company_id
        }
        const projection = {
            'title': 1,
            'address': 1,
            'city': 1,
            'state': 1,
            'zip_code': 1,
            'source': 1,
            'provider_ids_ref': 1,
            'assoc_company_ids': 1
        }

        let companyAssocPopulation: facilityPopulationInterface = {
            collection: 'companies',
            field: ["assoc_company_ids"],
            filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: [true, { $in: ['$_id', '$$assoc_company_ids'] }] }] } },
            project: { name: 1 },
        };
          
        const facilitiesResponse: any = await this.facilityService.getFacilities(filter, projection, [companyAssocPopulation]).toPromise();
        if (facilitiesResponse.status === 200) {
            this.facilities = facilitiesResponse.data.array;
            for (const facility of this.facilities) {
                facility.provider_ids_ref = facility.provider_ids_ref
                    .filter((facilityProvider) => {
                        if (typeof facilityProvider === 'string') {
                            return this.providers.find(provider => provider._id === facilityProvider);
                        }
                        return true;
                    }).map((facilityProvider) => {
                        if (typeof facilityProvider === 'string') {
                            return this.providers.find(provider => provider._id === facilityProvider);
                        } else {
                            return facilityProvider;
                        }
                    })

            }
            this.dataSource = new MatTableDataSource(this.facilities);
            this.orignalData = JSON.parse(JSON.stringify(this.facilities))

        }
    }

    initProviders = async () => {
        const company_id = this.params.id;

        const filter = {
            is_activated: 'true',
            user_type: USER_TYPE.DOCTOR,
            company_id,
        }

        const projection = {
            'account_type': 1,
            'first_name': 1,
            'last_name': 1,
            'title': 1,
        }

        const companyUsersResponse: any = await this.userService.getUsers(filter, projection).toPromise();
        if (companyUsersResponse.status === 200) {
            this.providers = companyUsersResponse.data.map(p => Object.assign(p, {
                full_name: p.account_type === 'company' ?
                    `${p.first_name} ${p.last_name}, ${p.title} (company owner)` :
                    `${p.first_name} ${p.last_name}, ${p.title}`
            }));
        }
    }

    applyFilter(filterValue: string) {
        let filter = filterValue.trim().toLowerCase();
        if (filter) {
            let filterVal = [];
            this.facilities.forEach(facility => {
                if (facility.title.trim().toLowerCase().includes(filter)) {
                    filterVal.push(facility)
                    this.dataSource = new MatTableDataSource(filterVal);
                }
            });
        } else {
            this.dataSource = this.facilities;
            this.orignalData = JSON.parse(JSON.stringify(this.facilities))

        }
    }

    // biller-provider
    onProviderSelect(provider, facility) {
        // this.updateFacilityProviders(facility);
    }

    onSelectAllProvider(facility) {
        let isRelationCreating : boolean = true;
        this.updateFacilityProviders(facility,isRelationCreating);
    }

    onDeSelectAllProvider(facility) {
        let isRelationCreating : boolean = false;
        this.updateFacilityProviders(facility,isRelationCreating);
    }

    onProviderDeSelect(provider, facility) {
        // this.updateFacilityProviders(facility);
    }

    updateFacilityProviders(facility,isRelationCreating) {
        const { providersDropDown } = facility;
        this.facilityService.updateFacilityProviders(facility._id, providersDropDown.map(p => p._id),isRelationCreating).subscribe((data) => {
            this.toastr.info("Relation Updated")
        })
        
    }

    async onDataSelection($event) {
        // let providers = element.associated_provider_ids.map(a => a._id);
        // let idx = this.users.findIndex(user => user._id.toString == element._id.toString());
        // this.users[idx] = element;
        // this.dataSource.data = this.users;
        let template = $event.data;
        let facility = $event.element;
        let provider_id;
        if($event.type == 'provider') {
            if(template === 'selectAll') {
                facility.provider_ids_ref = facility.providersDropDown;
                this.onSelectAllProvider(facility);
                return;
            }
            else if(template === 'unSelect') {
                facility.provider_ids_ref = [];
                this.onDeSelectAllProvider(facility);
                return;
            }
        }
        else {
            if(template === 'selectAll') {
                facility.template_ids_ref = facility.allTemp;
                this.onSelectAllTemplate(facility);
            }
            else if(template === 'unSelect') {
                facility.template_ids_ref = [];
                this.onDeSelectAllTemplate(facility);
                return;
                
            }
        }
        const company_id = this.params.id;
            let result = await this.companyService.getCompanyName(company_id).toPromise();
            if (result.status === 200) {
                let companyType = result.data.company_type.toLowerCase();
                if (companyType != 'wound') {
                    if (facility.trueRCMID == '' || !facility.trueRCMID) {
                        swal("No Unique ID", "Enter unique facility ID under settings!", "warning");
                    }
                }
            }
            if(!$event.status) {
                this.onTemplateDeSelect(template, facility);
                return;
            }
            else {
                let providers = [];
                let templates = [];
                if(facility.provider_ids_ref.length > 0) {
                    providers = facility.provider_ids_ref.map(a => a._id);
                }
                if(facility.template_ids_ref.length > 0) {
                    templates = facility.template_ids_ref.map(a => a._id);
                }
                this.updateFacilityProviderTempalteAssoc(facility._id, providers, templates, null, provider_id) 
            }
      }
      
    onDataRemove($event) {
        this.onTemplateDeSelect($event.data, $event.element);
    }

    async onTemplateSelect(event, facility, type, index) {
        if(event.isUserInput) {
            let template = event.source.value;
            let provider_id;
            if(template === 'selectAll') {
                facility.provider_ids_ref = facility.providersDropDown;
                this.onSelectAllProvider(facility);
                return;
            }
            else if(template === 'unSelect') {
                facility.provider_ids_ref = [];
                this.onDeSelectAllProvider(facility);
                return;
            }
            if(template === 'selectAllT') {
                facility.template_ids_ref = facility.allTemp;
                this.onSelectAllTemplate(facility);
            }
            else if(template === 'unSelectT') {
                facility.template_ids_ref = [];
                this.onDeSelectAllTemplate(facility);
                return;
                
            }
            const company_id = this.params.id;
            let result = await this.companyService.getCompanyName(company_id).toPromise();
            if (result.status === 200) {
                let companyType = result.data.company_type.toLowerCase();
                if (companyType != 'wound') {
                    if (facility.trueRCMID == '' || !facility.trueRCMID) {
                        swal("No Unique ID", "Enter unique facility ID under settings!", "warning");
                    }
                }
            }
            let idx = this.orignalData.findIndex(fac => fac._id == facility._id)
            let temp = this.orignalData[idx];
            if(type == 'provider' ) {
                if(!event.source._selected) {
                    this.onTemplateDeSelect(template, facility);
                    facility.provider_ids_ref = temp.provider_ids_ref.filter(pro => pro._id != template._id);
                    temp.provider_ids_ref = temp.provider_ids_ref.filter(pro => pro._id != template._id);
                    return;
                }
                else {
                    facility.provider_ids_ref = [...facility.provider_ids_ref, template];
                    temp.provider_ids_ref = [...temp.provider_ids_ref, template];
                    
                }
            }
            else if(type == 'template') {
                if(!event.source._selected) {
                    this.onTemplateDeSelect(template, facility);
                    facility.template_ids_ref = temp.template_ids_ref.filter(pro => pro._id != template._id);
                    temp.template_ids_ref = temp.template_ids_ref.filter(pro => pro._id != template._id);
                    return;
                }
                else {
                    
                    facility.template_ids_ref = [...facility.template_ids_ref || [], template];
                    temp.template_ids_ref = [...temp.template_ids_ref || [], template];
                    
                }
            }
            let providers = [];
            let templates = [];
            if(facility.provider_ids_ref.length > 0) {
                providers = facility.provider_ids_ref.map(a => a._id);
            }
            if(facility.template_ids_ref.length > 0) {
                templates = facility.template_ids_ref.map(a => a._id);
            }
            this.updateFacilityProviderTempalteAssoc(facility._id, providers, templates, null, provider_id)
        }
        
    }
    onTemplateDeSelect(template, facility) {
        let temp = this.orignalData[this.orignalData.findIndex(fac => fac._id == facility._id)];
        if (template.full_name) {
            facility.provider_ids_ref = facility.provider_ids_ref.filter(provider => provider._id != template._id);
            temp.provider_ids_ref = facility.provider_ids_ref;
            let provider = template;
            let providers = facility.provider_ids_ref.map(a => a._id)
            let templates = facility.template_ids_ref.map(a => a._id)
            this.updateFacilityProviderTempalteAssoc(facility._id, providers, templates, null, provider._id)
        }else {
            facility.template_ids_ref = facility.template_ids_ref.filter(temp => temp._id != template._id);
            temp.template_ids_ref = facility.template_ids_ref
            let providers = facility.provider_ids_ref.map(a => a._id)
            let templates = facility.template_ids_ref.map(a => a._id)
            this.updateFacilityProviderTempalteAssoc(facility._id, providers, templates,template._id, null)
        }
    }
    onSelectAllTemplate(facility) {
        let isRelationCreating : boolean = true;
        this.updateFacilityTemplates(facility,isRelationCreating)
    }

    onDeSelectAllTemplate(facility) {
        let isRelationCreating : boolean = false;
        this.updateFacilityTemplates(facility,isRelationCreating)
    }

    updateFacilityTemplates(facility, isRelationCreating) {
        const { allTemp } = facility;
        this.facilityService.updateFacilityTemplates(facility._id, allTemp.map(p => p._id),isRelationCreating).subscribe((data) => {
            this.toastr.info("Relation Updated")
        });
    }
    async updateFacilityProviderTempalteAssoc(facility, providers, templates, template_id?, provider_id?) {
        let uniqueproviders = providers.filter((element, index) => {
            return providers.indexOf(element) === index;
        });
        this.facilityService.updateFacilityProviderTempalteAssoc(facility, uniqueproviders, templates, template_id, provider_id).subscribe((response: any) => {
            if (response.status == 200) {
                this.toastr.info("Relation Updated")
            }
        })
    }
    async goToPreviousPage() {
      this.pagination.pageNo--;
      await this.initAssociationData();
    }
  
    async goToNextPage() {
      this.pagination.pageNo++;
      await this.initAssociationData();
    }

    compareProviders(provider1: any, provider2: any): boolean {
        return provider1 && provider2 ? provider1._id === provider2._id : provider1 === provider2;
    }
}
