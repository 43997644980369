
  <mat-card class="pcc-notification-panel h100">

    <mat-card-content>
      <div style="text-align: center;">PointClickCare Notifications</div>
      <p *ngIf="notifications.length === 0">No notifications</p>
      <ul class="flex flex-col notification-list gap-2">
        <li class="notification-item" *ngFor="let notification of notifications"
        >
         <small class="eventType">{{notification?.eventPayload?.messageDate | date: global.date_time_format}} - {{formatEventType(notification.eventType)}}</small>
         <div *ngIf="notification.patient"  class="flex justify-between items-center">
          <span>
            {{notification.patient.lastName}}, {{notification.patient.firstName}} 
            <br />
            {{notification.patient.admissionDate | date: global.date_format}} | {{notification.patient.gender | slice:0:1}}
          </span>
          <i class="icon icon-import"
          matTooltip="Import" matTooltipPosition="above"
          *ngIf="isImportVisible(notification)" (click)="importPCCPatient(notification, notification.facilityId)"></i>
         </div>
        </li>
      </ul>
      
    </mat-card-content>
  </mat-card>
  