<mat-card class="h100" style="padding-top:0">
    <mat-card-content>
        <div class="filter-form white-rounded-rect" style="padding: 0 !important;">
            <mat-calendar [selected]="calenderSelectedValue" (selectedChange)="calenderSelectedValue=$event;dateSelection.emit($event)"></mat-calendar>
            <div class="flex flex-col items-start justify-start gap-5">
                <div class="input-with-cross flex flex-col items-start items-baseline">
                        <mat-form-field>
                            <mat-label>Select Provider</mat-label>
                            <mat-select [value]="selectedProvider" (selectionChange)="facilityChange($event)">
                                <mat-option *ngFor="let pro of providers"
                                            [value]="pro._id">{{pro.first_name + ' ' + pro.last_name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="input-with-cross flex items-baseline">
                        <mat-form-field>
                            <mat-label>Select Facility</mat-label>
                            <mat-select [value]="selectedFacility" (selectionChange)="facilityChange($event)">
                                <mat-option *ngFor="let f of facilities"
                                            [value]="f._id">{{f.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="input-with-cross flex items-baseline">
                        <mat-form-field>
                            <mat-label>Select Patient</mat-label>
                            <mat-select (selectionChange)="patientChange($event)">
                                <mat-option value="All">All</mat-option>
                                <mat-option [value]="p._id"
                                            *ngFor="let p of patients">
                                    {{p.first_name + ' ' + p.last_name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="input-with-cross flex items-baseline">
                        <mat-form-field>
                            <mat-label>Select Payor</mat-label>
                            <mat-select (selectionChange)="payorChange($event)">
                                <mat-option value="All">All</mat-option>
                                <mat-option [value]="pay._id"
                                            *ngFor="let pay of payors">{{pay.abbreviation}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
        </div>
    </mat-card-content>
</mat-card>
