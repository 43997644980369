<div> 

    <div class="gap-5"  style="display: flex; place-content: space-between;">
        <div *ngIf="!auditGroupedScreen">
            <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
                Go Back
            </button>
        </div>

        <div class="gap-12" style="margin-right: 20px; display: flex; align-items: baseline; width: 100%; margin-left: 50px;">
            <div class="flex items-baseline input-with-cross">
                <mat-label style="margin-right:20px;color:#999">Date of Submission</mat-label>
                <input *ngIf="this.submissionDateFilter" type="text" ngxDaterangepickerMd
                    [locale]="{applyLabel: 'ok', format: 'MM/DD/YYYY'}"
                    [(ngModel)]="filter.date_of_submission" (datesUpdated)="changeSubmissionDate($event)"
                    class="mat-like-input" [ranges]="preDateRanges" [alwaysShowCalendars]="true"
                    [maxDate]="this.c_date" [linkedCalendars]="true" [showClearButton]="true" />
            </div>
            <mat-form-field>
                <mat-label>Select Facility</mat-label>
                <div class="flex items-start justify-center">
                    <img *ngIf="isPCCFacility() && filter.facility_id" src="../../../../assets/icons/pcc-icon.svg" alt=""
                        style="width: 35px;height: 20px;margin-left: -5px;;vertical-align: middle;">
                    <img *ngIf="isMatrixFacility() && filter.facility_id" src="../../../../assets/icons/matrix.png" alt=""
                        style="height: 12px;width: 20px;vertical-align: middle;margin-right: 5px;margin-left: 5px;">
                    <img *ngIf="isDNFacility() && filter.facility_id" class="dn-icon" src="../../../../assets/img/DN.png" alt="">
                    <mat-select [ngModel]="filter.facility_id" (selectionChange)="applyFacilityChange($event);">
                        <input placeholder="Search" [(ngModel)]="searchFacilityText" [ngModelOptions]="{standalone: true}"
                            class="select-input-class" />
                        <mat-option value="" style=" margin-left: 40px;">All</mat-option>
                        <mat-option *ngFor="let facility of facilities | searchFacility : searchFacilityText"
                            value="{{facility._id}}">
                            <img *ngIf="(facility?.source === 'PointClickCare' || facility?.pcc_facId)"
                                src="../../../assets/icons/pcc-icon.svg" alt=""
                                style="width: 35px;height: 20px;margin-left: -5px;;vertical-align: middle;">
                            <img *ngIf="facility?.source === 'MatrixCare' " src="../../../../assets/icons/matrix.png" alt=""
                                style="height: 12px;width: 20px;vertical-align: middle;margin-right: 5px;margin-left: 5px;">
                            <img *ngIf="facility?.source != 'PointClickCare' && !facility?.pcc_facId &&facility?.source != 'MatrixCare'"
                                class="dn-icon" src="../../../../assets/img/DN.png" alt=""> {{facility.title ?facility.title : ""}}
                        </mat-option>
                    </mat-select>
                </div>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Select Provider</mat-label>
                <mat-select (selectionChange)="applyProviderChange($event);" [ngModel]='filter.provider_id' >
                    <input placeholder="Search" [(ngModel)]="searchProviderText" [ngModelOptions]="{standalone: true}"
                    class="select-input-class" />
                    <mat-option value="" >All</mat-option>
                    <mat-option *ngFor="let pro of providers | searchProvider : searchProviderText" value="{{pro._id}}">{{pro.full_name ? pro.full_name : ""}}</mat-option>
                </mat-select> 
            </mat-form-field>
        </div>
        <div>
            <span>Total Submit Charge: {{auditGroupedScreen? chargeCount:  chargeCount2}}</span>
            
        </div>
        <div>
            <button mat-raised-button class="dn-button-primary" (click)="applyFilter()"> Apply Filter</button>
        </div>
    </div>

    <div style="height: 10px;"></div>


    <table mat-table [dataSource]="dataSource" matSort *ngIf="auditGroupedScreen && !providerGroupedAuditScreen">
        <ng-container matColumnDef="operation">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Operation</th>
            <td mat-cell *matCellDef="let element"> 
                {{element._id}}
            </td>
        </ng-container> 

        <ng-container matColumnDef="count">
            <th mat-header-cell *matHeaderCellDef> Count </th>
            <td mat-cell *matCellDef="let element ; let i = index;">
                <div style="color: blue; text-decoration: underline; cursor: pointer" (click)="getProviderGroupedAudit(element)">{{element.count}}</div>  
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>


    <table mat-table [dataSource]="dataSource2" matSort *ngIf="providerGroupedAuditScreen && !auditGroupedScreen">
        <ng-container matColumnDef="provider">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Provider</th>
            <td mat-cell *matCellDef="let element"> 
                {{getProviderName(element)}}
            </td>
        </ng-container> 
        
        <ng-container matColumnDef="facility">
            <th mat-header-cell *matHeaderCellDef> Facility </th>
            <td mat-cell *matCellDef="let element ; let i = index;">
                <div>{{element.facility? element.facility.title : '--'}}</div>  
            </td>
        </ng-container>
        <ng-container matColumnDef="operation">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
            <td mat-cell *matCellDef="let element"> 
                {{element.operations? element.operations.operation : '--'}} 
            </td>
        </ng-container> 
        <ng-container matColumnDef="count">
            <th mat-header-cell *matHeaderCellDef> Count </th>
            <td mat-cell *matCellDef="let element ; let i = index;">
                <div>{{element.operations? element.operations.count : "--"}}</div>  
                   
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
    </table>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

</div>