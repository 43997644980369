import {Injectable} from '@angular/core';
import * as global from '../includes/global';
import {HttpClient} from '@angular/common/http';
// import {AuthService} from './auth.service';
import {API_URL} from "../../environments/api-url";
import { map } from 'rxjs/operators';
import { CompanyService } from './Modules/company.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class TagsService {
    // private currentUser: any;
    private userInfo: any;

    constructor(private httpclient: HttpClient,
        private companyService: CompanyService,
        private toastr: ToastrService

                // private _authService: AuthService
                ) {
        // this.currentUser = this._authService.currentUser;
        // this.userInfo = {
        //     id: this.currentUser._id,
        //     auth_key: this.currentUser.auth_key,
        //     usertype: this.currentUser.user_type
        // }
    }

    createTag(filter: any, query: any) {
        return this.httpclient.post(global.url + API_URL.PATIENT.createTag, { filter: filter, query: query }).pipe();
    }

    getAllTags(filter = {}, projection = {}, isTagsSelected = false, patientTags = []) {
        return this.httpclient.post(global.url + API_URL.PATIENT.getAllTags, {filter: filter, projection: projection}).pipe(
            map((response: any) => {
                if (response.status === 200) {
                    if (response.data) {
                        if (isTagsSelected) {
                            patientTags.map(patient_tag => {
                                let find = response.data.find(tag => { return tag._id == patient_tag })
                                if (find)
                                    find['is_selected'] = true;

                                else
                                    find['is_selected'] = false;

                                return patient_tag;
                            }); 
                        }
                        response.data.map(tag => { 
                            tag['extracted_phrase_text'] = this.extractContentFromHtmlTag(tag.phrase_text);
                            return tag['is_active'] = true 
                        })
                        return response;

                    }
                }
            })
        );

    }

    updateTag(filter: any, query: any) {
        return this.httpclient.post(global.url + API_URL.PATIENT.updateTag, { filter: filter, query: query }).pipe();
    }

    deleteTag(filter: any) {
        return this.httpclient.post(global.url + API_URL.PATIENT.deleteTag, { filter: filter }).pipe();
    }

    fetchTagAssociatedPatients(filter: any, projection: any) {
        return this.httpclient.post(global.url + API_URL.PATIENT.fetchTagAssociatedPatients, { filter: filter, projection: projection }).pipe();
    }

    extractContentFromHtmlTag(data: any) {
        let span = document.createElement('span');
        span.innerHTML = data;
        return span.textContent || span.innerText;
    };
    async getUserTagPermissions(permissionsList, element, company_id) {

        let isCompanyTagPermissionsEnabled = false;
        let userPermissions = [...element.permissions]
        let index = userPermissions.indexOf('allow_tag_to_patient');
        if (index > -1) {
            isCompanyTagPermissionsEnabled = await this.companyService.checkCompanyPermission('should_show_tag', company_id); // check if tag is enable from company permissions
            if (!isCompanyTagPermissionsEnabled) {
                userPermissions.splice(index, 1);
                this.toastr.info("Enable Tag Permissions From Company Settings First");
            }

        }

        let permissions = permissionsList.filter((permission) => userPermissions.includes(permission.key));
        return { filterArray: permissions, elementPermissions: userPermissions };
    }
}
