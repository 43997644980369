<h1 mat-dialog-title class="heading">Drug History Download Consent</h1>
<div mat-dialog-content>
    <div>
        Patient Consents to Download Medication History?
    </div>
    <mat-slide-toggle [(ngModel)]="consentGiven"></mat-slide-toggle>
</div>
<mat-dialog-actions align="end">
    <button mat-raised-button class="dn-button-secondary" mat-dialog-close>Cancel</button>
    <button mat-raised-button class="dn-button-primary" [mat-dialog-close]="{ saveClick: true, consentGiven: consentGiven }">Save</button>
</mat-dialog-actions>