<div>
    <mat-card class="cpts">
        <div class="flex justify-between items-center">
            <app-input-with-dropdown class="flex-grow" [noDropdown]="true" placeholder="Type Code/Description And Press Enter"
                (inputChange)="setValueToBeSearched($event.target.value)" (keyup.enter)="searchValue()" (keyup)="searchValue()">
            </app-input-with-dropdown>
            <mat-spinner *ngIf="isIcdLoaded" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
            <button mat-raised-button class="dn-button-primary" (click)="searchValue()" style="margin-left: 2rem;"> Search </button>
            <button mat-raised-button class="dn-button-primary" (click)="addCpt()" style="margin-left: 0.5rem;"> Add CPT </button>
        </div>
        <div style="margin-top: 1rem;height: 77vh;overflow: auto;" class="flex justify-start items-start" >
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="Code">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;">Code</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.code}}
                    </td>
                </ng-container>
               
                <ng-container matColumnDef="Description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 40%;">Description</th>
                    <td mat-cell *matCellDef="let element">
                            <div *ngIf="element.shouldUpdate; else elseBlock"></div>
                                <ng-template #elseBlock>
                                    <div [innerHTML]="element.description"></div>
                                </ng-template>
                            
                    </td>
                </ng-container> 

                <ng-container matColumnDef="Status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;">Status</th> 
                    <td mat-cell *matCellDef="let element">
                        <div [ngClass]="{'activeColor': element.status == 'Active', 'expiredColor': element.status == 'Expired'  }">
                            {{element.status}}
                        </div>
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="Operation">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header-container></th>
                    <td mat-cell *matCellDef="let element; let i= index">
                        <a style="cursor: pointer" (click)="updateCpt(i, element)" *ngIf="!element.shouldUpdate">
                            <i class="icon icon-edit" matTooltip="Edit" matTooltipPosition="above" id="edit-icon"></i>
                        </a>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-card>
</div>