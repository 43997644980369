<mat-card style="height: calc(100vh - 130px); overflow-y: auto;" class="vertical-height">
  <div *ngIf="!showIframe">
    <div class="white-rounded-rect" *ngIf="showAddBtn">
      <div class="flex justify-end items-baseline">
        <button mat-raised-button class="dn-button-primary" (click)="addNewReport()">Add New Report</button>
      </div>
    </div>
    <div style="height: 95%; overflow: auto;">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
        <ng-container matColumnDef="sr">
          <th mat-header-cell *matHeaderCellDef>Sr</th>
          <td mat-cell *matCellDef="let element; let i = index;">{{ i + 1 }}</td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>Title</th>
          <td mat-cell *matCellDef="let element; let i = index;">{{ element.title }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            <div class="flex" style="gap: 1rem;">
              <i class="icon icon-preview" matTooltip="Preview Report"
                [ngStyle]="{ 'margin-left': !showEditBtn ? '0.8rem' : '0' }" matTooltipPosition="above"
                (click)="previwReport(element)"></i>
              <a *ngIf="showEditBtn" style="cursor: pointer" (click)="updateTag(i, element)">
                <i class="icon icon-edit" matTooltip="Edit" matTooltipPosition="above" id="edit-icon"></i>
              </a>
              <a *ngIf="showDeleteBtn" style="cursor: pointer;" (click)="deleteWoundEnterpriseReport(i, element)">
                <i class="icon icon-delete" matTooltip="Delete Tag" matTooltipPosition="above" id="delete-icon"></i>
              </a>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
  <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
  <div class="iframe-container" *ngIf="showIframe">
    <iframe role="presentation" 
    width="800" height="600" [src]="srcValue" frameborder="0" allowFullScreen="true"></iframe>
    <button mat-raised-button class="dn-button-secondary close-button" (click)="closeIframe()">Close</button>
  </div>
</mat-card>