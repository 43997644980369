<div class="flex justify-start items-baseline gap-2">
  
  <mat-form-field style="margin-top: auto;">
    <mat-label>Search facilities by</mat-label>
    <mat-select [(ngModel)]="searchFacilityBy" (selectionChange)="handleSearchFacilitiesByChange($event.value)">
      <mat-option value="title">Default</mat-option>
      <mat-option value="facId_orgUuid">Facility ID & OrgUUID</mat-option>
    </mat-select>
  </mat-form-field>
  <ng-container *ngIf="searchFacilityBy === 'facId_orgUuid'">
    <form 
    (submit)="searchFacilityByFacIdAndOrgUuid()" #searchFacilityByFacIdAndOrgUuidForm="ngForm"
    class="flex-grow flex gap-2 justify-start items-baseline">
      <mat-form-field>
        <mat-label>Facility ID</mat-label>
        <input matInput placeholder="Facility ID" required name="facilityId" ngModel />
      </mat-form-field>
      <mat-form-field class="flex-grow" style="max-width: 500px;">
        <mat-label>OrgUUID</mat-label>
        <input matInput placeholder="OrgUUID" required name="orgUUID" ngModel />
      </mat-form-field>
      
      <button mat-raised-button class="dn-button-primary" type="submit">
        Search
      </button>
    </form>

  </ng-container>
  <ng-container *ngIf="searchFacilityBy === 'title'">
    <app-input-with-dropdown class="flex-grow" [noDropdown]="true" placeholder="Search Facilties"
      (inputChange)="applyFilter($event.target.value);">
    </app-input-with-dropdown>
    </ng-container>
    <button mat-raised-button class="dn-button-primary" (click)="downloadAsXLSX()">
      Export As XLSX
    </button>
    <mat-form-field style="margin-top: auto;">
      <mat-label>Filter by Company</mat-label>
      <mat-select>
        <mat-option value="--All--" (click)="initActivations(pageNo ? pageNo : 1); selectedCompanyId=''">--All--</mat-option>
        <mat-option (click)="applyCompanyFilter(company._id)" *ngFor="let company of companies" [value]="company._id">{{company.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field style="margin-top: auto;">
      <mat-label>Sort</mat-label>
      <mat-select (selectionChange)="applysort($event.value)">
        <mat-option value="assoc_date">Association Date</mat-option>
        <mat-option value="activation_date">Activation Date</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field style="margin-top: auto;">
      <mat-label>Status</mat-label>
      <mat-select [(ngModel)]="activeFilter" value="all" (selectionChange)="applyActiveFilter($event.value)">
        <mat-option value="all">All</mat-option>
        <mat-option value="true">Active</mat-option>
        <mat-option value="false">Inactive</mat-option>
      </mat-select>
    </mat-form-field>
    <button *ngIf="selectedActivations.length > 0" mat-raised-button class="dn-button-primary"
      (click)="importActivationsFacilities()">
      Import {{selectedActivations.length}}
    </button> &nbsp;&nbsp;
    <mat-form-field *ngIf="showCompanies">
      <mat-select value="default">
        <mat-option value="default">
          Select Company
        </mat-option>
        <mat-option *ngFor="let company of companies" [value]="company._id"
          (onSelectionChange)="onSelectCompany($event, company._id)">
          {{company.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  <ng-container *ngIf="searchFacilityBy === 'title'">
    <button mat-raised-button class="dn-button-primary" (click)="navigatePrevNext(-1)" [disabled]="paging.page <= 1">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <div style="color: black;margin-right: 5px;font-size: 12px;min-width: 45px;text-align: center;">Page
      {{paging.page}}</div>
    <button mat-raised-button class="dn-button-primary" (click)="navigatePrevNext(1)" [disabled]="!paging.hasMore">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </ng-container>
</div>
<div style="height: 70vh;overflow: auto;">

  <table mat-table [dataSource]="dataSource">


    <ng-container matColumnDef="Fac ID">
      <th mat-header-cell *matHeaderCellDef style="width: 5%; text-align: center;"> Fac ID </th>
      <td mat-cell *matCellDef="let element; let i = index;" style="width: 5%; text-align: center;">{{getFacAndOrganizationID(element)}}
      </td>
    </ng-container>


    <ng-container matColumnDef="Facility-Title">
      <th mat-header-cell *matHeaderCellDef style="width: 25%; text-align: start;"> Facility Title </th>
      <td mat-cell *matCellDef="let element" style="width: 25%; text-align: start;">
        <img src="../../../assets/icons/pcc-icon.svg" 
        style="height: 20px;
        width: 30px;  
        vertical-align: middle;"
        alt=""> 
        {{element.facilityName}} </td>
    </ng-container>

    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef style="width: 20%; text-align: start;"> Address </th>
      <td mat-cell *matCellDef="let element" style="width: 20%; text-align: start;"> {{element.address}} </td>
    </ng-container>

    <ng-container matColumnDef="Company">
      <th mat-header-cell *matHeaderCellDef style="width: 25%; text-align: start;"> Company</th>
      <td mat-cell *matCellDef="let element" style="width: 25%; text-align: start;">
        <ng-container *ngIf="element?.is_active">
          {{element?.associatedCompanyNames}}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="Active">
      <th mat-header-cell *matHeaderCellDef style="width: 5%; text-align: start;"> Active</th>
      <td mat-cell *matCellDef="let element" style="width: 5%; text-align: start;">
        <ng-container>
          {{element?.is_active ? 'yes' : 'no'}}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="De-activation Date">
      <th mat-header-cell *matHeaderCellDef style="width: 10%; text-align: start;"> De-activation Date
      </th>
      <td mat-cell *matCellDef="let element" style="width: 10%; text-align: start;"> {{element?.deactivation_date | date
        : 'longDate'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="company_assoc_date">
      <th mat-header-cell *matHeaderCellDef > Association Date
      </th>
      <td mat-cell *matCellDef="let element" style="width: 10%; text-align: start;"> {{getAssociationDate(element)}}
      </td>
    </ng-container>

    <ng-container matColumnDef="Activation Date">
      <th mat-header-cell *matHeaderCellDef style="width: 10%; text-align: start;"> Activation Date
      </th>
      <td mat-cell *matCellDef="let element" style="width: 10%; text-align: start;"> {{element?.activationDate}}
      </td>
    </ng-container>

    <ng-container matColumnDef="Activation Actions">
      <th mat-header-cell *matHeaderCellDef style="width: 10%; text-align: start;"> Activation Actions
      </th>
      <td mat-cell *matCellDef="let element" style="width: 10%; text-align: start;">
        <ng-container *ngIf="isActivationImported(element)">
          <a href="#" (click)="$event.preventDefault(); showEmailTemplateDialog('De-activation', element)">De-active</a>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef style="width: 5%; text-align: center;"> Actions </th>
      <td mat-cell *matCellDef="let element" style="width: 5%; text-align: center;"
        [class.green-checkbox]="isActivationImported(element)">
        <mat-checkbox [checked]="isActivationImported(element) || isActivationChecked(element)"
          [disabled]="isActivationImported(element)" (change)="toggleActivationCheck(element, $event.checked)">
        </mat-checkbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>