import { Component, OnInit, Input, DoCheck, EventEmitter, Output, OnDestroy } from '@angular/core';
import { PCCService } from 'src/app/services/pcc.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatTableDataSource } from '@angular/material/table';
import { ConditionsService } from './conditions.service';
import { CensusListService } from '../../census-list/census-list..service';
import { SidebarDiagnosisService } from 'src/app/components/sidebar-addCharges/sidebarDiagnonses/sidebar-diagnoses/sidebar-diagnosis.service';
import { PatientRoundingSheetService } from '../../patient-rounding-sheets/patient-rounding-sheet.service';
import { AuthService } from 'src/app/services/auth.service';
import * as global from "../../../includes/global"

@Component({
  selector: 'app-conditions',
  templateUrl: './conditions.component.html',
  styleUrls: ['./conditions.component.css']
})
export class ConditionsComponent implements OnInit, DoCheck, OnDestroy {


  @Input('showCheckboxes') showCheckboxes = false;
  @Input('patient') patient;
  @Input('note') note;
  @Input('selectedDiagnoses') selectedDiagnoses;
  @Output() pccDiagnosesChange = new EventEmitter<any>();
  // @Input('pccOrgUuid') pccOrgUuid;
  constructor(
    private _pccService: PCCService,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService,
    private sidebarDiagnosisService: SidebarDiagnosisService,
    private service: PatientRoundingSheetService,
    private censusListService: CensusListService,
    private authService: AuthService,) { }
  ngOnDestroy(): void {
    this.sidebarDiagnosisService.setSelectedICDs([]);
  }
  pccOrgUuid: string;
  apiData;
  page = 1;
  dataSource;
  pccSelectedDiagnosis: any = [];
  displayedColumns = [
    // "onsetDate",
    // "icd10_checkbox",
    "icd10",
    "icd10Description",
    // "rankDescription",
    // "classificationDescription",
    // "clinicalStatus",
    // "therapy",
    "date",
    // "principalDiagnosis",
    // "conditionId"
  ];

  loaderId = 'conditions-loader';
  inprogress = false;
  selectedSidebarICDs: any[] = [];
  currentUser : any;
  global = global;
  ngDoCheck() {
    if (this.selectedDiagnoses) {
      // console.log("selectedDiagnoses=======", this.selectedDiagnoses);
      // console.log("selectedSidebarICDs=======", this.selectedSidebarICDs);
      this.selectedSidebarICDs = this.selectedDiagnoses;
    }
    // this.pccSelectedDiagnosis = this.selectedDiagnoses.filter(icd => icd.pcc) ;
    // if(this.selectedDiagnoses){
    //   console.log("DOcheck=======", this.selectedDiagnoses);
    //   // this.pccSelectedDiagnosis = this.selectedDiagnoses.filter(icd => icd.pcc) ;
    //   this.selectedSidebarICDs = this.pccSelectedDiagnosis;
    // }
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    // if(this.selectedDiagnoses){
    //   console.log("selectedDiagnoses=======", this.selectedDiagnoses);
    //   console.log("selectedSidebarICDs=======", this.selectedSidebarICDs);

    //   // this.pccSelectedDiagnosis = this.selectedDiagnoses.filter(icd => icd.pcc) ;
    //   this.selectedSidebarICDs = this.selectedDiagnoses;
    // }
    this.pccOrgUuid = this.patient.facility_id_ref.pcc_orgUuid;
    // console.log("this.pccOrgUuid: ", this.pccOrgUuid);
    // console.log("patient: ", this.patient);

    this.loadData(this.page);

    this.sidebarDiagnosisService.castSelectedICDs.subscribe((icds => {
      
      this.selectedSidebarICDs = icds;
    }));
  }

  loadData(page) {
    this.loader.startLoader(this.loaderId);
    let authMode;
    if (!this.patient.facility_id_ref.pcc_2_legged_authentication) {
      authMode = 'APP'
    }

    this._pccService.getPatientConditions({
      pccOrgUuid: this.pccOrgUuid,
      pccPatientId: this.patient.pcc_patientId,
      pccPage: String(page),
      authMode
    }).subscribe((response: any) => {

      if (response.status === 200) {
        this.apiData = response.data;
        let icds = this.apiData.data.map(a => a.icd10)
        this.service.compareICDs(icds).subscribe((res: any) => {
          if (res.status == 200) {
            let i = 0;
            this.apiData.data.forEach(p => {
              if (res.data.some(a => a.code == p.icd10)) {
                p.pdpm_status = true;
              }
              else {
                p.pdpm_status = false;
              }
            });
            this.apiData.data = this.apiData.data;
            this.dataSource = new MatTableDataSource(this.apiData.data);
            console.log(this.apiData);
            this.page = page;
          }
        })
      } else {
        this.toastr.error('Something went wrong while fetching Conditions data');
      }
    }, (error) => {
      this.toastr.error('Something went wrong while fetching Conditions data');
    }, () => {
      this.loader.stopLoader(this.loaderId);
    })
  }
  navigate(prevNext) {
    const newPageNo = this.page + prevNext;
    this.loadData(newPageNo);
  }

  print() {
    return JSON.stringify(this.apiData);
  }
  applyFilterICD(value) {
    if (this.apiData) {
      if (value) {
        let filteredData = [];
        this.apiData.data.map(a => {
          if (a.icd10.toLowerCase().includes(value) || a.icd10Description.toLowerCase().includes(value)) {
            filteredData.push(a)
          }
        })
        this.dataSource.data = filteredData;
      }
      else {
        this.dataSource.data = this.apiData.data;
      }
    }
  }
  indexOfSelectedICDCode(code) {
    if (Array.isArray(this.selectedSidebarICDs)) {
      const index = this.selectedSidebarICDs.map((icd: any) => icd.code.toLowerCase().trim()).indexOf(code.toLowerCase().trim());
      return index;
    }
    return -1;
  }
  isICDCodeSelected(code) {
    return this.indexOfSelectedICDCode(code) >= 0
  }
  
  async toggleSelectICDCode(code: string, description: string) {
    
    const selectedICDCodes = this.selectedSidebarICDs.slice();
    const index = this.indexOfSelectedICDCode(code);
    // icd.flag = 'pcc';
    if (index === -1) {
      const details = {
        icdCode: code,
        description: description,
        pcc: true
      }
      const icdResponse: any = await this.censusListService.addICD(details).toPromise();
      if (icdResponse.status === 200) {
        const icd = icdResponse.data;
        icd.pcc = true;
        if(Array.isArray(icd) && icd.length > 0) {
          this.selectedSidebarICDs.push(...icd);
        } else {
          this.selectedSidebarICDs.push(icd);
        }
      }
    } else {
      this.selectedSidebarICDs.splice(index, 1);
    }
    this.sidebarDiagnosisService.setSelectedICDs(this.selectedSidebarICDs);
    this.pccDiagnosesChange.emit(this.selectedSidebarICDs)
  }

  shouldDisableCheckbox() {
    const isSameProvider = this.note.provider._id === this.currentUser._id && this.currentUser.user_type === global.USER_TYPE.DOCTOR;
    const isAssociatedProvider = this.note.provider._id != this.currentUser._id && this.currentUser.user_type === global.USER_TYPE.DOCTOR;
    const isMedicalAssistant = this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT;
    if(isSameProvider || isMedicalAssistant) {
      return false;
    }
    if(isAssociatedProvider) {
      return true;
    }
  }
}
