  <div class="container">
      <div class="filter-form white-rounded-rect flex flex-col">
          <h2 style="color:#000;margin-top:0;margin-bottom:0">Filter</h2>
          <div class="flex justify-evenly items-center gap-2">
              <div class="form-row flex justify-start items-center gap-2">

                  <div>
                      <mat-label>Date</mat-label>
                  </div>

                      <div style="display: inline-block;" class="date-input-wrapper">
                          <mat-form-field floatLabel="never" *ngIf="filter.dateType === 'single'">
                              <input
                              matInput [matDatepicker]="picker" [(ngModel)]="filter.date" [max]="currentDate" (focus)="picker.open()">
                              <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field>

                          <input
                              *ngIf="filter.dateType === 'range'"
                              type="text"
                              ngxDaterangepickerMd
                              [(ngModel)]="filter.dateRange"
                              class="mat-like-input"
                              [ranges]="preDateRanges"
                              [alwaysShowCalendars]="true"
                              [linkedCalendars]="true"
                              [showClearButton]="true"
                           />
                      </div>
              </div>

              <div class="form-row">
                      <div class="flex justify-start items-baseline gap-2">
                          <mat-label style="min-width: 58px !important;">By User</mat-label>
                          <mat-select style="min-width: 134px !important;"
                                      [(value)]="filter.userId"
                                      (selectionChange)="filter.userId = $event.value">
                              <mat-option *ngFor="let u of users" [value]="u._id">{{u.name}}</mat-option>
                          </mat-select>
                      </div>
              </div>

              <div class="form-row">
                      <div class="flex justify-start items-center gap-2">
                              <mat-label style="min-width: 91px !important;">By Platform</mat-label>
                          <mat-select (selectionChange)="filter.platform = $event.value"
                                      [(value)]="filter.platform"
                                      style="margin-left: 20px; min-width: 66px !important;">
                              <mat-option value="Web">Web</mat-option>
                              <mat-option value="iOS" selected>iOS</mat-option>
                              <mat-option value="Android">Android</mat-option>
                          </mat-select>
                          <button mat-icon-button *ngIf="filter.platforms.length" (click)="filter.platforms = []"><mat-icon>close</mat-icon></button>
                      </div>
              </div>
              <div class="flex justify-center gap-2">
                  <button mat-raised-button class="dn-button-primary" (click)="applyFilter()"
                  >Apply Filter</button>
                  <button mat-raised-button class="dn-button-secondary" (click)="resetFilter()"
                  >Reset Filter</button>
              </div>
          </div>
      </div>
      <div class="br-spacing"></div>
      <div>
          <div class="flex justify-end items-center gap-2 table-nav" *ngIf="pagination.totalPages">
            <button mat-raised-button class="dn-button-primary" *ngIf="pagination.hasPrevPage" (click)="handlePrevClick()">Prev</button>
            <button mat-raised-button class="dn-button-primary" *ngIf="pagination.hasNextPage" (click)="handleNextClick()">Next</button>
          </div>
          <div class="br-spacing"></div>

          <div class="flex justify-center items-center" *ngIf="!dataSource">
              <mat-progress-spinner diameter="50" mode="indeterminate">
              </mat-progress-spinner>
          </div>

          <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" *ngIf="dataSource">

              <ng-container matColumnDef="userName">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>User Name</mat-header-cell>
                  <mat-cell *matCellDef="let activity">{{getUserInfo(activity.userId)?.name}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="screen">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Screen</mat-header-cell>
                  <mat-cell *matCellDef="let activity">{{activity.screen}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="operation">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Operation</mat-header-cell>
                  <mat-cell *matCellDef="let activity">{{activity.operation}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="datetime">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Date Time</mat-header-cell>
                  <mat-cell *matCellDef="let activity">{{activity.datetime}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="platform">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Platform</mat-header-cell>
                  <mat-cell *matCellDef="let activity">{{activity.platform}}</mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

              <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

          </mat-table>
        </div>
    </div>
