<h2>Edit Recurring Event</h2>

<div style="display: flex;
flex-direction: column;">
  <label>
    <input type="radio" [(ngModel)]="selectedOption" value="single"> This event
  </label>
  <label>
    <input type="radio" [(ngModel)]="selectedOption" value="following"> This and following events
  </label>
  <label>
    <input type="radio" [(ngModel)]="selectedOption" value="all"> All events
  </label>
</div>

<div class="button-container">
  <button mat-raised-button class="dn-button-secondary" (click)="cancel()">Cancel</button>
  <button mat-raised-button class="dn-button-primary" (click)="ok()">OK</button>
</div>