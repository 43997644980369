import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface GeneralConfirmationDialogData {
  message: String,
  title: String
}
@Component({
  selector: 'app-general-confirmation-dialog',
  templateUrl: './general-confirmation-dialog.component.html',
  styleUrls: ['./general-confirmation-dialog.component.css']
})
export class GeneralConfirmationDialogComponent implements OnInit {
  census : boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GeneralConfirmationDialogData,
    public dialogRef: MatDialogRef<GeneralConfirmationDialogData>,
  ) { }

  ngOnInit(): void {
    console.log('data',this.data);
    this.census = this.data.title.indexOf("Census") !=-1? true: false;
  }

  close(value){
    console.log('value',value);
    this.dialogRef.close(value);
  }
}
