<div class="flex flex-col gap-2">
  <div class="flex gap-2">
    <mat-form-field style="width: 750px;color:blue" *ngIf="currentUser.product_owner">
      <mat-label>Company</mat-label>
      <mat-select  [(ngModel)]="releaseData.company" multiple (selectionChange)="setButtonEnabled()">
        <mat-option *ngFor="let company of companies" value="{{company._id}}">
          {{company.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field style="width: 750px;color:blue">
      <mat-label>User Type</mat-label>
        <mat-select [(ngModel)]="releaseData.user_types"  multiple (selectionChange)="selectDeselectAll()">
          <mat-option *ngFor="let user of user_types" [value]="user.type">
            {{ user.name }}
          </mat-option>
        </mat-select>

    </mat-form-field>
  </div>
    <div style="height: 400px;" *ngIf="!isShareReleaseNotes">
        <ckeditor
            [(ngModel)]="releaseData.addNotes"
            name="notes_value" class="h100"
            editorUrl="../../../../assets/ckeditor/ckeditor.js" 
            [readOnly]="!cke4Config.editable" 
            [config]="cke4Config"
            type="divarea" (ready)="onReadyCkeditor($event)"></ckeditor>
    </div>

    <div class="flex items-center justify-center gap-2" *ngIf="!isShareReleaseNotes">
        <button class="dn-button-primary" mat-raised-button [mat-dialog-close]="releaseData" [disabled]="isButtonDisable">Save</button>
        <button class="dn-button-secondary" mat-raised-button type="button" mat-dialog-close>Cancel</button>
    </div>

    <div class="flex items-center justify-center gap-2" *ngIf="isShareReleaseNotes">
      <button class="dn-button-primary" mat-raised-button type="button" mat-dialog-close (click)="shareReleaseNotes()">Share</button>
  </div>
</div>

<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>
