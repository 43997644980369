import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { IcdCodesService } from 'src/app/services/Modules/icd-codes.service';
import { MatTableDataSource } from '@angular/material/table';
import { lastValueFrom } from 'rxjs';
import moment from 'moment';
@Component({
  selector: 'app-dialogue-add-icd-code',
  templateUrl: './dialogue-add-icd-code.component.html',
  styleUrls: ['./dialogue-add-icd-code.component.css']
})
export class DialogueAddIcdCodeComponent implements OnInit {
  newICDmodel = {
    icdCode: "",
    title: "",
    description: "",
    shortDescription: "",
    mediumDescription: "",
    end_date: "",
    start_date: "",
    order: "",
    pdpm_status: false,
    isDuplicateAllowed: false  // used in backend api "addICD"
  };

  isCloseDialogue = false;
  isButtonDisable = true;
  expiry_date = null; // just initialize date object
  public current_date = new Date();
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = ['Code', 'Description', 'Medium Description'];
  icds = [];
  isShowTable = false;

  constructor(private toastr: ToastrService, private _ICDcodesService: IcdCodesService,
    private dialogRef: MatDialogRef<DialogueAddIcdCodeComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogueAddIcdCodeComponent) { }

  ngOnInit(): void {
  }


  async handleSubmit(e) {
    let res: any, toastrId;
    this.checkIsEmpty();
    try {
        if (!this.isCloseDialogue) {
        toastrId = this.toastr.info("Adding New Code", "Please Wait...", { disableTimeOut: true });

        if (this.expiry_date != null)
          this.newICDmodel.end_date = moment(this.expiry_date).format('MM/DD/YYYY');

        res = await lastValueFrom(this._ICDcodesService.addIcdCode(this.newICDmodel));
        if (res.status == 200 && !res.isAlreadyExist) {
          this.toastr.clear(toastrId.toastId);
          this.toastr.success("Code Added Succesfully", "Success");
          this.dialogRef.close({ data: res });
        } else if (res.status == 200 && res.isAlreadyExist) {
          this.toastr.clear(toastrId.toastId);
          this.icds = res.data;
          this.dataSource.data = this.icds;
          this.isShowTable = true;
          this.checkIsEmpty();
        }
        else {
          this.toastr.error(res.message, 'Error');
        }
      }
      else
        this.dialogRef.close({ data: res });


    } catch (err) {
      this.toastr.clear(toastrId.toastId);
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  addDuplicateCode(event: any){
    this.newICDmodel.isDuplicateAllowed = true;
    this.handleSubmit(event);
  }

  closeDialogue(event: any){
    this.isCloseDialogue = true;
    this.handleSubmit(event);
  }

  checkIsEmpty(){ 
    if (this.newICDmodel.icdCode.trim().length === 0)
      this.isButtonDisable = true;
    else if (this.newICDmodel.description.trim().length === 0)
      this.isButtonDisable = true;      
    else
      this.isButtonDisable = false;

  }

  setPdpm(value: any){
    this.newICDmodel.pdpm_status = value;
  }

  clearDetails() {
    this.newICDmodel = {
      icdCode: "",
      title: "",
      description: "",
      shortDescription: "",
      mediumDescription: "",
      end_date: "",
      start_date: "",
      order: "",
      pdpm_status: false,
      isDuplicateAllowed: false  // used in backend api "addICD"
    };
    this.expiry_date = null;
    this.isButtonDisable = true;
    this.isShowTable = false;
  }

}
