import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../../../app/includes/global'

@Component({
  selector: 'app-reports-module',
  templateUrl: './reports-module.component.html',
  styleUrls: ['./reports-module.component.css']
})
export class ReportsModuleComponent implements OnInit {
  currentUser: any;
  global=global;
  
  constructor(
    private authService: AuthService,
  ) {
    this.currentUser = this.authService.currentUser;
   }

  ngOnInit(): void {
  }

}
