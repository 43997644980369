<form #appointmentForm="ngForm">
  <div class="mat-dialog-header flex justify-between items-center">
    <h2 mat-dialog-title>{{appointmentBtn}} Appointment</h2>
    <span class="material-symbols-outlined mat-dialog-close-icon" mat-dialog-close>cancel</span>
  </div>

  <div mat-dialog-content>
    <div class="form-row flex gap-4">
      <mat-form-field appearance="fill" class="w-1/2"><mat-label>Select Provider</mat-label>
        <mat-select name="provider" #provider="ngModel" [(ngModel)]="formData.providerType"
        (ngModelChange)="onSelectProvider()" [disabled]="isDisabledProvider">
        <input matInput placeholder="Search" [(ngModel)]="searchTextProvider"
          [ngModelOptions]="{standalone: true}" style="color:#000;height: 40px;padding-left: 5px;" />
        <mat-option *ngFor="let provider of uniqueProviders | searchProvider : searchTextProvider"
          value="{{provider._id}}">
          {{provider.name}}
        </mat-option>
      </mat-select>
      </mat-form-field>

      <div class="w-1/2" style="padding-top: 10px; position: relative;">
        <mat-form-field class="w-full">
          <mat-label>Select Location</mat-label>
          <mat-select name="facilityType" [disabled]="isDisabledFacility" [(ngModel)]="formData.facilityType" required>
            <mat-option *ngFor="let facility of facilities" [value]="facility._id">
              <app-facility-title [facility]="facility"></app-facility-title>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <ngx-ui-loader [loaderId]="locationSearchLoaderId"></ngx-ui-loader>
      </div>
    </div>
    <div class="form-row" fxLayout="column">
        <mat-form-field appearance="standard" fxFlex="50%">
            <mat-label>Treatment Team</mat-label>
            <mat-select multiple name="treatmentTeam" [(ngModel)]="treatmentTeam">
                <input matInput placeholder="Search" [(ngModel)]="searchTreatmentTeam" [ngModelOptions]="{standalone: true}" style="color:#000;height: 40px;padding-left: 5px;" />
                <mat-option *ngFor="let provider of uniqueProviders | searchProvider : searchTreatmentTeam" [value]="provider._id">{{provider.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="form-row" fxLayout fxLayoutGap="16px" style="align-items: baseline;">
    
      <mat-form-field fxFlex="50%" appearance="standard">
        <mat-label>DOS</mat-label>
        <input matInput [matDatepicker]="dospicker" (focus)="dospicker.open()"
            placeholder="DOS" [value]="formData.dos"
            (dateChange)="setDateOfService($event)" [disabled]="isDisabledStartDate">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="dospicker">
            <span matDatepickerToggleIcon [class.disabled] = "isDisabledStartDate === true" class="material-symbols-outlined icon-navy">
                calendar_month
            </span>
        </mat-datepicker-toggle>
        <mat-datepicker #dospicker></mat-datepicker>
     </mat-form-field>
        <mat-form-field>
            <mat-label>Start time</mat-label>
            <input matInput
                   [format]="12"
                   [disabled]="isDisabledTime"
                   name="start_time"
                   [(ngModel)]="formData.start_time"
                   [ngxMatTimepicker]="pickerD"
                   placeholder="12:00"
                   readonly />
            <mat-icon matSuffix [class.disabled]="isDisabledTime" (click)="!isDisabledTime && pickerD.open()">schedule</mat-icon>
        </mat-form-field>
        <ngx-mat-timepicker color="primary" #pickerD></ngx-mat-timepicker>
    </div>

    <div class="form-row flex gap-4">
      <mat-form-field class="flex-grow">
        <mat-label>Appointment Type</mat-label>
        <mat-select  (selectionChange)="onSelectAppointmentType()"  name="appointmentType" [disabled]="isDisabledAppointmentType" [(ngModel)]="formData.appointmentType" required>
          <mat-option disabled>--select--</mat-option>
          <mat-option *ngFor="let appointmentType of appointmentTypes" [value]="appointmentType._id">
            {{appointmentType.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

   
    <div class="form-row">
      <div class="form-field">
        <mat-form-field class="flex">
          <mat-label>Repeating</mat-label>
          <mat-select  (selectionChange)="onSelectrecurrenceRule()" name="repeating" [(ngModel)]="formData.repeating">
            <mat-option (click)="resetArrays()" *ngFor="let rpt of repeatings" [value]="rpt.value">
              {{rpt.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <ng-container *ngIf="formData.repeating">
      <div class="form-row flex gap-4">
        <mat-form-field class="w-1/2" appearance="fill" style="align-self: flex-end;">
          <mat-label>Start Date</mat-label>
          <input matInput [matDatepicker]="dospicker" (focus)="dospicker.open()"
              placeholder="Start Date" [value]="formData.start_date"
              (dateChange)="setDOS($event, 'start_date')" [disabled]="isDisabledStartDate">
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="dospicker">
              <span matDatepickerToggleIcon [class.disabled] = "isDisabledStartDate === true" class="material-symbols-outlined icon-navy">
                  calendar_month
              </span>
          </mat-datepicker-toggle>
          <mat-datepicker #dospicker></mat-datepicker>
       </mat-form-field>

       <mat-form-field class="w-1/2" appearance="fill" style="align-self: flex-end;">
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="end_datepicker" (focus)="end_datepicker.open()"
            placeholder="End Date" [value]="formData.end_date"
            (dateChange)="setDOS($event, 'end_date')"  [disabled]="isDisabledEndDate">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="end_datepicker">
            <span matDatepickerToggleIcon [class.disabled] = "isDisabledEndDate === true" class="material-symbols-outlined icon-navy">
                calendar_month
            </span>
        </mat-datepicker-toggle>
         <mat-datepicker #end_datepicker></mat-datepicker>
      </mat-form-field>
      </div>
    </ng-container>

    <div class="circular-list" *ngIf="formData.repeating">
      <div *ngIf="formData.repeating === 'Monthly by Date'" class="circle days">
        <div
          *ngFor="let day of daysOfMonth"
          class="item"
          [class.selected]="isSelected(day,'days')"
          (click)="toggleSelection(day,'days')"
        >
          {{ day }}
        </div>
      </div>
      <div *ngIf="formData.repeating === 'Monthly by Day'" class="circle weeks">
        <div
          *ngFor="let week of weeksOfMonth"
          class="item"
          [class.selected]="isSelected(week,'weeks')"
          (click)="toggleSelection(week,'weeks')"
        >
          {{ week }}
        </div>
      </div>

      <div *ngIf="formData.repeating === 'Monthly by Day' || formData.repeating === 'Weekly' || formData.repeating === 'Biweekly'" class="circle weekdays">
        <div
          *ngFor="let weekday of weekdays"
          class="item"
          [class.selected]="isSelected(weekday,'weekdays')"
          (click)="toggleSelection(weekday,'weekdays')"
        >
          {{ weekday }}
        </div>
      </div>
    </div>
    <div class="form-row flex gap-4">
      <div class="w-1/2" style="position: relative;">
        <mat-form-field style="width: 100%">
          <mat-label>Search for existing Patients</mat-label>
          <input matInput type="text" name="patientsByProvider" [formControl]="patientsByProviderControl"
            [matAutocomplete]="patientAutoComplete" [readonly]="!formData.providerType || !formData.facilityType" />
            <mat-autocomplete #patientAutoComplete="matAutocomplete" (optionSelected)="
              setFormValue($event.option.value)" [displayWith]="displayPatientFn">
              <mat-option *ngFor="let patient of filteredPatientsByProvider$ | async" [value]="patient">
                {{ patient.name }}
              </mat-option>
          </mat-autocomplete>
          <mat-hint *ngIf="patientNotFound && !selectedPatient" style="color: #F44336;">
            No patient found with the matched result.
          </mat-hint>
        </mat-form-field>
        <ngx-ui-loader [loaderId]="patientsSearchLoaderId"></ngx-ui-loader>
      </div>

      <div class="w-1/2" style="text-align: right;">
        <button mat-button [disabled]="!initialPatientSearchDone || !formData.providerType || !formData.facilityType"
          class="dn-button-primary" (click)="openAddPatientDialog()">
            Add New Patient
        </button>
      </div>
    </div>
    <div class="form-row" *ngIf="selectedPatient" style="margin-top: -25px; font-size: 12px;"> 
      <strong>{{selectedPatient}}</strong> selected. 
    </div>
    <div class="form-row">
      <div class="form-field">
        <mat-form-field class="flex">
          <mat-label>Comments</mat-label>
          <textarea matInput name="comments" [(ngModel)]="formData.comments" cdkTextareaAutosize></textarea>
        </mat-form-field>
      </div>

    </div>
  </div>

  <div mat-dialog-actions>
    <div class="flex justify-end items-center" style="margin-left: auto;">
      <button mat-raised-button class="dn-button-primary" *ngIf="appointmentBtn == 'Update'"
        (click)="deleteAppointment()">Delete</button>
      <button mat-raised-button class="dn-button-primary" (click)="submitForm()" color="primary"
        type="submit">{{appointmentBtn}}</button>
      <button mat-raised-button class="dn-button-secondary" (click)="closeDialog()">Cancel</button>
    </div>
  </div>
</form>