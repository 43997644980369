import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {CompanyService} from 'src/app/services/Modules/company.service';
import * as usa_states from 'src/app/shared/popup-dialogs/facility-add-dialog/usa_states';
import {ToastrService} from 'ngx-toastr';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {FacilityService} from 'src/app/services/facility.service';
import {PayorContactDialogComponent} from "src/app/shared/popup-dialogs/payor-contact-dialog/payor-contact-dialog.component";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {FacilityContactDialogComponent} from "src/app/shared/popup-dialogs/facility-contact-dialog/facility-contact-dialog.component";

@Component({
    selector: 'app-company-facility-form',
    templateUrl: './company-facility-form.component.html',
    styleUrls: ['./company-facility-form.component.css']
})
export class CompanyFacilityFormComponent implements OnInit {
    usa_states = usa_states.default.slice(1)
    loaderId = 'company-user-management-form-loader';
    facility_id: string;
    model = {
        title: '',
        address: '',
        street: '',
        city: '',
        state: '',
        zip_code: '',
        company_id: null,
        contact: {
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            position: ''
        }
    }
    public dataSource: any;
    displayedColumns: string[] = ['name', 'position', 'email', 'phone', 'operations'];

    constructor(private _location: Location,
                private route: ActivatedRoute,
                private loader: NgxUiLoaderService,
                private companyService: CompanyService,
                private facilityService: FacilityService,
                private dialog: MatDialog,
                private toastr: ToastrService) {
        this.model.company_id = this.route.parent.parent.snapshot.params.id;
        this.facility_id = this.route.snapshot.params.id;
    }

    ngOnInit(): void {
        if (this.facility_id) {
            this.loader.startLoader(this.loaderId);

            this.facilityService.getFacility(this.facility_id).subscribe((response: any) => {
                if (response.status === 200) {
                    this.model = response.data;
                }
                this.loader.stopLoader(this.loaderId);
            });
            this.companyService.getFacilityContacts({facility: this.facility_id})
                .subscribe( res => {
                    this.dataSource = new MatTableDataSource(res.data.array);
                });

        }
    }


    goBack() {
        this._location.back();
    }

    handleSubmit(e) {
        try {
            this.loader.startLoader(this.loaderId);
            if (this.facility_id) {
                this.companyService.updateFacility(this.model).subscribe((response: any) => {
                    this.loader.stopLoader(this.loaderId);
                    this.toastr.success('Facility updated succesfully', 'Success')
                })
            } else {
                this.companyService.addFacility(this.model).subscribe(
                    (data) => {
                        this.toastr.success('Facility added successfully', 'Success');
                        this.goBack();
                    }
                );
            }
        } catch (error) {
            this.loader.stopLoader(this.loaderId);
            this.toastr.error('Something went wrong', 'Error');
        }
    }

    editFacilityContact(contact) {
        const dialogRef = this.dialog.open(FacilityContactDialogComponent, {
            width: '600px',
            data: {update: true, contact: contact}
        });

        dialogRef.afterClosed().subscribe(async (data) => {
            // @ts-ignore
            if (contact._id === this.model.contact._id) {
                this.loader.startLoader(this.loaderId);

                this.facilityService.getFacility(this.facility_id).subscribe((response: any) => {
                    if (response.status === 200) {
                        this.model = response.data;
                    }
                    this.loader.stopLoader(this.loaderId);
                });
            }
            this.companyService.getFacilityContacts({facility: this.facility_id})
                .subscribe( res => {
                    this.dataSource = new MatTableDataSource(res.data.array);
                });
        });
    }

    deleteFacilityContact(contact) {
        let params = {
            _id: contact._id,
            is_active: false
        };
        this.companyService.updateFacilityContact(params)
            .subscribe(res => {
                this.toastr.success('Facility Contact Deleted', 'Success');
                this.companyService.getFacilityContacts({facility: this.facility_id})
                    .subscribe( res => {
                        this.dataSource = new MatTableDataSource(res.data.array);
                    });
            });
    }

    openDialog() {
        if (!this.facility_id) {
            this.toastr.error('Please add facility first', 'Error');
            return;
        }
        const dialogRef = this.dialog.open(FacilityContactDialogComponent, {
            width: '600px',
            data: {facility_id: this.facility_id}
        });

        dialogRef.afterClosed().subscribe(async (data) => {
            this.companyService.getFacilityContacts({facility: this.facility_id})
                .subscribe( res => {
                    this.dataSource = new MatTableDataSource(res.data.array);
                });
        });
    }

    numberToString(number) {
        return String(number);
    }
}
