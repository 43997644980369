import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment'
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FacilityService } from 'src/app/services/facility.service';
import { Location } from '@angular/common';
import { WoundService } from 'src/app/services/wound.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-dns-report-v2',
  templateUrl: './dns-report-v2.component.html',
  styleUrls: ['./dns-report-v2.component.css']
})
export class DnsReportV2Component implements OnInit {

  currentDate = moment(new Date());
  minDate = moment(new Date()).subtract(6, 'days');
  loaderId = 'app-dns-report';
  filter: any = {
    updationDate: {
      startDate: moment().subtract(6, 'days'),
      endDate: moment()
    },
    facilityId: null,
    companyId: null,
    state: 'all'
  };
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 1 Week': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  };
  filteredFacilities: Observable<string[]>;
  displayedColumns: string[] = [
    'name', 'wound_recognition_date', 'wound_recognition_date_type', 'woundNo', 'body_part', 'etiolgy', 'stage', 'length', 'width', 'depth', 'state', 'diffInWeek', 'area_change', 'icds'];
  facilityControl = new FormControl();
  facilities: Array<any> = [];
  dnsReport: any = [];
  dataSource: any = new MatTableDataSource([]);
  poaCount: number = 0;
  facilityAcquiredCount: number = 0;
  openWoundsCount: number = 0;
  inRemissionWoundsCount: number = 0;
  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    // totalPages: 0,
    // currentRecords: 0
    PAGE_LIMIT: 50,
    skip: 0
  };
  companySide: boolean = false;
  currentUser: any;

  constructor(
    private _toastr: ToastrService,
    private _facilityService: FacilityService,
    private _route: ActivatedRoute,
    private _location: Location,
    private _wound: WoundService,
    private loader: NgxUiLoaderService,
    private _authService: AuthService,
    private _commonService: CommonService
  ) {
    this.currentUser = this._authService.currentUser;
    this.filter.companyId = this._route.parent.snapshot.params.id;
    this._route.queryParams.subscribe((params: any) => {
      if (params) {
        console.log("params", params);
      }
    });
    if (this._route.parent.snapshot.params.id) {
      this.companySide = true;
    } else {
      this.filter.companyId = this.currentUser.company_id;
    }
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId);
    await this.initFacilities();
    this.selectDefaultFacility();
    this.initDnsReport();
    this.filteredFacilities = this.facilityControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => this.filterFacilities(value)));
    this.loader.stopLoader(this.loaderId);
  }
  private filterFacilities(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) > -1);
    }
    else {
      return this.facilities;
    }
  }
  selectDefaultFacility() {
    if (this.facilities.length > 0) {
      this.filter.facilityId = this.facilities[0]._id;
    }
    this.facilityControl.setValue(this.facilities[0].title);
  }
  initDnsReport() {
    this.loader.startLoader(this.loaderId);
    this.filter.pageNo = this.pagination.pageNo;
    this._wound.getDnsReportV2(this.filter).subscribe({
      next: (response: any) => {
        if (response.status == 200) {
          this.dnsReport = response.data.dnsReport;
          this.pagination.totalRecords = response.data.totalRecords;
          this.handlePagination();
          [this.inRemissionWoundsCount, this.openWoundsCount, this.poaCount, this.facilityAcquiredCount] = [0, 0, 0, 0];
          for (const report of this.dnsReport) {
            report.createdAt = moment(report.createdAt).format("MMM DD, YYYY");
            if (report.wound_recognition_date_type === "yes") {
              this.poaCount++;
            }
            else if (report.wound_recognition_date_type === "no") {
              this.facilityAcquiredCount++;
            }
            if (report.state === "active") {
              this.openWoundsCount++;
            }
            else if (report.state === "remission") {
              this.inRemissionWoundsCount++;
            }
          }
          this.dataSource.data = this.dnsReport;
          this.loader.stopLoader(this.loaderId);
        }
      },
      error: (err) => {
        this.loader.stopLoader(this.loaderId);
        this._toastr.error(err, "ERROR");
      },
    });


  }
  handlePagination() {
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    if (this.pagination.skip + this.dnsReport.length < this.pagination.totalRecords) {
      this.pagination.hasNextPage = true;
    }
    else {
      this.pagination.hasNextPage = false;
    }
    if (this.pagination.skip > 0) {
      this.pagination.hasPrevPage = true;
    }
    else {
      this.pagination.hasPrevPage = false;
    }
  }

  async initFacilities() {
    const filter = {
      assoc_company_ids: this.filter.companyId,
      is_active: true
    };
    const projection = {
      title: 1,
      source: 1,
      assoc_company_ids: 1,
    };

    let companyAssocPopulation: facilityPopulationInterface = {
      collection: 'companies',
      field: ["assoc_company_ids"],
      filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: [true, { $in: ['$_id', '$$assoc_company_ids'] }] }] } },
      project: { name: 1 },
    };
    
    const facilitiesResponse: any = await this._facilityService.getFacilities(filter, projection, [companyAssocPopulation]).toPromise();
    if (facilitiesResponse.status == 200) {
      this.facilities = facilitiesResponse.data.array;
    }
  }
  onSelectFacility($event, facility) {
    if ($event.isUserInput) {
      this.filter.facilityId = facility._id;
    }
  }
  changeUpdationDate(event) {
    if (event.startDate && event.endDate) {
      this.filter.updationDate = {
        startDate: null,
        endDate: null
      };
      const noOfDays = this._commonService.getDifferenceOfDates(event.startDate,event.endDate,'days');
      if (noOfDays > 29) {
        this.filter.updationDate = {};
        this.filter.updationDate.startDate = moment(event.startDate);
        this.filter.updationDate.endDate = moment(this.filter.updationDate.startDate).add(29, "days").endOf('day');
        this._toastr.error('Can not select more than 30 days', 'Date Error');
      }
      else {
        this.filter.updationDate.startDate = event.startDate;
        this.filter.updationDate.endDate = event.endDate;
      }
    }
  }

  goBack() {
    this._location.back();
  }
  handleApplyFilter() {
    this.pagination = {
      pageNo: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalRecords: 0,
      PAGE_LIMIT: 50,
      skip: 0
    };
    this.initDnsReport();
  }
  onStateChange(value) {
    this.filter.state = value;
  }
  resetFilter() {
    delete this.filter.updationDate;
    const defaultDate = {
      startDate: moment().subtract(6, 'days').toDate(),
      endDate: moment().toDate()
    };
    this.filter.updationDate = defaultDate;
    this.filter.state = "all";
    this.selectDefaultFacility();
    this.initDnsReport();
  }
  exportDnsReportAsXLSX() {
    delete this.filter.pageNo; //To export all
    const infoToastr = this._toastr.info('Exporting as XLSX...', 'Please wait', { disableTimeOut: true });
    this._wound.exportDnsReportV2AsXLSX(this.filter).subscribe({
      next: (response: any) => {
        if (response instanceof HttpResponse) {
          const url = window.URL.createObjectURL(response.body);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = `Dns Report`;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
          this._toastr.clear(infoToastr.toastId);
        }
      },
      error: error => {
        console.log("err", error);
        this._toastr.clear(infoToastr.toastId);
      }
    });
  }
  goToPreviousPage() {
    this.pagination.pageNo--;
    this.initDnsReport();
  }
  goToNextPage() {
    this.pagination.pageNo++;
    this.initDnsReport();
  }
}
