<table  mat-table [dataSource]="dataSource" matSort> 

    <ng-container matColumnDef="payer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Payer
        </th>
        <td mat-cell *matCellDef="let element">
            <ng-container>
                {{element.payer_type}}
            </ng-container>
        </td>
    </ng-container>
    

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Name
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.primary_payer_name}}
        </td>
    </ng-container>
    

    <ng-container matColumnDef="medicare_no">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Medicare No.
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.medicare_no}}
        </td>
    </ng-container> 

    <ng-container matColumnDef="insurance_company">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Insurance Company
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.insurance_company}}
        </td>
    </ng-container> 
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>