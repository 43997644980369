<app-header></app-header>

<main>

  <div class="pdf-view flex flex-col">
    <div class="pdf-view-header flex-row">
      <div class="flex items-center justify-center">
          
        <div class="flex-grow-0">
          <img *ngIf="selectedFile.is_signed == 'false'" src="../../../assets/img/file.png">
            <img *ngIf="selectedFile.is_signed == 'true' &&  selectedFile.is_completed != 'true'" src="../../../assets/img/signed_file.png">            
            <img *ngIf="selectedFile.is_signed == 'true' &&  selectedFile.is_completed == 'true'" src="../../../assets/img/completed_file.png">            
          </div>
          <div class="flex flex-col">
            <div class="title">{{title}}</div>
            <div>
              <span class="date" *ngIf="category=='tran'">{{selectedFile.date_of_service | date :'MMM dd, yyyy, h:mm a'}}</span>
              <span class="date" *ngIf="category=='dict'">{{selectedFile.date | date :'MMM dd, yyyy, h:mm a'}}</span>
            </div>
          </div>
        </div>
        <div class="editor-top-right flex items-center justify-center gap-2">
          
          <button class="dn-button-primary" mat-raised-button (click)="signTranscription()" *ngIf="user.user_type == 1 && selectedFile.is_signed != 'true'"><i class="material-icons">assignment_turned_in</i> Sign</button>        
          <button class="dn-button-primary" mat-raised-button (click)="unsignTranscription()" *ngIf="user.user_type == 1 && selectedFile.is_signed == 'true' && selectedFile.is_completed != 'true'"><i class="material-icons">close</i> Unsign</button>        
          <button class="dn-button-primary" mat-raised-button (click)="publishTranscription()" *ngIf="(user.user_type == 2 && user._id == selectedFile.uploaded_by_id && selectedFile.is_published == 'false') || (user.user_type == 'medical_assistant' && user._id == selectedFile.uploaded_by_id && selectedFile.is_published == 'false')"><i class="material-icons">assignment_turned_in</i> Publish</button>        
          <button class="dn-button-primary" mat-raised-button [disabled]="isPrevDisabled" (click)="navigateEditTranscription(-1)"><i class="material-icons">arrow_back_ios</i>Prev</button>        
          <button class="dn-button-primary" mat-raised-button [disabled]="isNextDisabled" (click)="navigateEditTranscription(1)">Next<i class="material-icons">arrow_forward_ios</i></button>        

        </div>   
      </div>
      <div class="pdf-file">   
        <div class="transcription-text" [innerHTML]="decrypted_transcription_text"></div>
      </div>
    </div>

</main>
