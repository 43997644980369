import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {AppointmentDataService} from "../../../components/appointment/service/data.service";
import {CompanyService} from "../../../services/Modules/company.service";

class Contact {
    facility: any;
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    position: string;
}

@Component({
    selector: 'app-facility-contact-dialog',
    templateUrl: './facility-contact-dialog.component.html',
    styleUrls: ['./facility-contact-dialog.component.css']
})
export class FacilityContactDialogComponent implements OnInit {

    facility_id: any;
    public model = new Contact();
    update = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private toastr: ToastrService,
                private dialogRef: MatDialogRef<FacilityContactDialogComponent>,
                private companyService: CompanyService,
                private dataService: AppointmentDataService) {
        if (data.facility_id) {
            this.facility_id = data.facility_id;
        } else if (data.update === true) {
            this.update = true;
            this.model = data.contact;
        }
    }

    ngOnInit(): void {
    }

    addContact(obj) {
        if (this.update === true) {
            this.updateContact(this.model);
            return;
        }
        this.model.facility = this.facility_id;
        this.companyService.addFacilityContact(this.model)
            .subscribe(res => {

                this.toastr.success('Facility Contact added', 'Success');
                this.dialogRef.close();
                this.model = new Contact();
            });
    }

    updateContact(contact) {
        this.companyService.updateFacilityContact(this.model)
            .subscribe(res => {
                this.toastr.success('Facility Contact updated', 'Success');
                this.dialogRef.close();
                this.model = new Contact();
            });
    }

    keyPressNumbers(event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        // Only Numbers 0-9
        if ((charCode < 48 || charCode > 57)) {
          event.preventDefault();
          return false;
        }
         else {
          return true;
        }
}

}
