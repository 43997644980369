import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse,} from '@angular/common/http';
import {IFacility} from '../shared/popup-dialogs/facility-add-dialog/facility-add.dialog';
import * as global from '../includes/global';
import {AuthService} from './auth.service';
import {API_URL} from "../../environments/api-url";
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class RehabReportService {
    currentUser: any;
    constructor(private httpclient: HttpClient,
                private _authService: AuthService) {
        this.currentUser = this._authService.currentUser;
    }

  
    getGoalStatusReport(patientName:string, date:Date, type:'ot'|'pt') {
        const details = {
          ...this._authService.authObject,
          patientName,
          date,
          type
        };
        return this.httpclient.post(global.url + API_URL.REHAB_REPORT.getGoalStatusReport, {details: details}).pipe();
      }
}
