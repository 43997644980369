import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { SnfWcNurseAssociationService } from 'src/app/includes/snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { WoundNurseRelationService } from 'src/app/services/Modules/woundNurse.service';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { FacilityService } from 'src/app/services/facility.service';
import * as global from "src/app/includes/global";
import { FormControl } from '@angular/forms';
import moment from 'moment';
import { map, startWith } from 'rxjs/operators';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';
import { PatientListService } from 'src/app/includes/patient-list/patient-list-service';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PatientRoundingSheetService } from 'src/app/includes/patient-rounding-sheets/patient-rounding-sheet.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { WoundCareWidgetService } from 'src/app/includes/note-sidebar/wound-care-widget/wound-care-widget.service';
import { async } from '@angular/core/testing';
import { ActivatedRoute } from '@angular/router';
import { NursingService } from 'src/app/includes/nursing-module/nursing.service';
import { CommonService } from 'src/app/services/common.service';
import { Sort } from '@angular/material/sort';
import _ from 'lodash';

@Component({
  selector: 'app-telehealth-wound-assessment-report',
  templateUrl: './telehealth-wound-assessment-report.component.html',
  styleUrls: ['./telehealth-wound-assessment-report.component.css'],
  animations: [
    trigger('accordionTrigger', [
      state('collapsed', style({ height: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TelehealthWoundAssessmentReportComponent implements OnInit, OnDestroy {
  global = global;
  facilityControl = new FormControl();
  facilities: Array<any> = [];
  filteredFacilities: Observable<string[]>;
  dataSource: any = new MatTableDataSource([]);
  updationDate = {
    // startDate: moment().subtract(6, 'days'),
    // endDate: moment(new Date()).add(1,'days')
    startDate: moment(),
    endDate: moment()
  };
  currentDate = moment();
  filter: any = {
    facility_id: "",
    company_id: "",
    is_active: true
  };
  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    PAGE_LIMIT: 50,
    skip: 0
  };
  selectedFacility: any;
  companyFacilityPatients: any = [];
  displayedColumns: string[] = ["Expansion Icon", "Checkbox", "Name", "Date Of Birth", "Facility", "MRN", "Last Seen"];
  expandedElement: null;
  censuses: any = [];
  selectedPatients: any = [];
  loaderId = 'app-wound-exceptions-report';
  isCensusFetchedOnce: boolean = false;
  tabIndex: number = 0;
  expandedRows: any = [];
  isDataLoaded: boolean = false;
  timeoutId: any;
  nurseAssociatedFacilities: Array<any> = [];
  isCompanySide: boolean = false;
  user_dashboard: boolean = false;
  nursingSide: boolean;
  currentUser: any;
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 1 Week': [moment().subtract(6, 'days'), moment()],
    // 'Last 2 Week': [moment().subtract(13, 'days'), moment()],
    // 'Last 3 Week': [moment().subtract(20, 'days'), moment()],
    // 'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    // 'This Month': [moment().startOf('month'), moment().endOf('month')],
    // 'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };

  constructor(
    private _woundNurseRelationService: WoundNurseRelationService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
    private _facilityService: FacilityService,
    private _authService: AuthService,
    private _location: Location,
    private _patientListService: PatientListService,
    private _patientRoundingSheetService: PatientRoundingSheetService,
    private loader: NgxUiLoaderService,
    private _toastr: ToastrService,
    private _woundCareWidgetService: WoundCareWidgetService,
    private woundNurseService: WoundNurseRelationService,
    private authService: AuthService,
    public commonService: CommonService,
    private _nurseService: NursingService,
    private _route: ActivatedRoute,
  ) {
    if (this._route.parent.snapshot.params.id) {
      this.isCompanySide = true;
    }
    if (window.location.pathname.includes('dashboard')) {
      this.user_dashboard = true;
    }
    if (window.location.pathname.includes('nursing')) {
      this.nursingSide = true;
    }
    this.currentUser = this.authService.currentUser;
    this.filter.company_id = this._authService.currentUser.company_id;
  }

  async ngOnInit(): Promise<void> {
    this.loader.startLoader(this.loaderId);
    this._woundCareWidgetService.setIsTeleHealthReport(true);
    if (this._authService.currentUser.user_type === global.USER_TYPE.DOCTOR)
      await this.initFacilities();
    else if (this._authService.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE)
      await this.initSnfWcNurseFacilityAssociation();
    else if(this._authService.currentUser.user_type === global.USER_TYPE.WOUND_NURSE)
      await this.initWoundNurseFacilityAssociation();
      else if(this._authService.currentUser.user_type === global.USER_TYPE.NURSE)
      await this.initNurseFacilities();
    this.loader.stopLoader(this.loaderId);
  }

  ngOnDestroy() {
    this._woundCareWidgetService.setIsTeleHealthReport(false);
  }

  async initPatients() {
    const response: any = await lastValueFrom(this._patientListService.getPatientFromCompanyFacilityPatient(this.filter, {}, this.pagination.pageNo, this.updationDate));
    if (response.status === 200) {
      this.companyFacilityPatients = response.data.companyFacilityPatients;
      this.pagination.totalRecords = response.data.totalRecords;
      this.handlePagination();
      this.dataSource.data = this.companyFacilityPatients;
    }
    else
      this._toastr.error(response.data.message, 'Error');
  }

  async initPatientRoundingSheets() {
    const response: any = await lastValueFrom(this._patientRoundingSheetService.getCensusList(this.selectedFacility._id, this._authService.currentUser._id));
    if (response.status === 200) {
      this.censuses = response.data.array;
      this.isCensusFetchedOnce = true;
    }
    else {
      this._toastr.error(response.data.message, 'Error');
    }
  }

  async handleAddToCensus(censusId) {
    if (censusId) {
      this.loader.startLoader(this.loaderId);
      const response: any = await lastValueFrom(this._patientRoundingSheetService.addPatientsToPatientRoundingSheet(censusId, this.selectedPatients));
      if (response.status === 200) {
        this._toastr.success("Patient(s) added", 'Success');
      }
      else {
        this._toastr.error(response.message, 'Error');
      }
      this.loader.stopLoader(this.loaderId);
    }
  }

  async onSelectPatient(event, companyFacilityPatient) {
    const patient = companyFacilityPatient.patient_id;
    if (event.checked && !this.selectedPatients.includes(patient._id))
      this.selectedPatients.push(patient._id)
    else
      this.selectedPatients.splice(this.selectedPatients.indexOf(patient._id), 1);
  }

  goBack() {
    this._location.back();
  }

  private filterFacilities(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) !== -1);
  }

  async onSelectFacility($event, facility) {
    if ($event.isUserInput) {
      this.loader.startLoader(this.loaderId);
      this.selectedFacility = facility;
      this.filter.facility_id = facility._id;
      delete this.filter.patientName;
      this.selectedPatients.length = 0;
      await this.initPatients();
      await this.initPatientRoundingSheets();
      this.loader.stopLoader(this.loaderId);
    }
  }

  searchPatient() {
    if (this.selectedFacility) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        this.isDataLoaded = true;
        await this.initPatients();
        this.isDataLoaded = false;
      }, 1000);
    }
  }



  onChangeFacilityText(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    if (inputValue.trim() === '')
      this.selectedFacility = null;
  }

  async initFacilities() {
    const filter = {
      provider_ids_ref: this._authService.currentUser._id,
      is_active: true,
      assoc_company_ids: this._authService.currentUser.company_id
    };
    const projection = {
      'title': 1,
      'source': 1,
      'assoc_company_ids': 1
    };
    const response: any = await lastValueFrom(this._facilityService.getFacilities(filter, projection));
    if (response.status === 200) {
      this.facilities = response.data.array;
      this.showFacilityDropdown();
    }
    else {
      this._toastr.error(response.data.message, 'Error');
    }
  }

  async initNurseFacilities() {
    const response: any = await lastValueFrom(this._nurseService.getAssociatedFacilities());
      if (response.status === 200 && response.data.array.length > 0) {
        this.nurseAssociatedFacilities = response.data.array;
        this.nurseAssociatedFacilities.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
        this.facilities = this.nurseAssociatedFacilities;
        this.showFacilityDropdown();
      }
  } 

  selectDefaultFacility() {
    if (this.facilities.length > 0) {
      // this.facility_id = null;
      this.facilityControl.setValue(this.facilities[0].title);
    }
  }

  getLastSeen(patient) {
    let company_id = this.currentUser?.company_id?.toString()
    if (window.location.pathname.includes('company')) {
      this.commonService.castCompany.subscribe(company => {
        if (company) {
          company_id = company._id.toString()
        }
      })
    }

    let last_seen = '';
    if (patient.patient_id?.last_seen) {
      if (Array.isArray(patient.patient_id?.last_seen) && patient.patient_id?.last_seen?.length) {

        for (let element of patient.patient_id?.last_seen) {
          if (element.company_id?.toString().includes(company_id)) {
            if (element.user_type && element.user_type === global.USER_TYPE.WOUND_NURSE) {
              last_seen = `<strong>(WN)</strong> ${element.last_seen || ''}`;
              break;
            } else {
              last_seen = element.last_seen || '';
              break;
            }
          }
        };

      } else {
        last_seen = '';
      }
    }
    return last_seen;
  }

  async initWoundNurseFacilityAssociations() {
    const filter = { associated_wound_nurse_ids: this._authService.currentUser._id };
    const nurseProjection = {
      first_name: 1,
      last_name: 1,
      title: 1,
      full_name: 1
    };
    const facilityProjection = {
      title: 1,
      pcc_facId: 1,
      source: 1,
      pcc_2_legged_authentication: 1
    };
    const nurseFilter: any = {
      company_id: this._authService.currentUser.company_id,
      is_activated: "true",
      user_type: global.USER_TYPE.WOUND_NURSE
    };
    const response: any = await lastValueFrom(this._woundNurseRelationService.getWoundNurseFacilityAssociations(filter, nurseProjection, facilityProjection, nurseFilter));
    if (response.status === 200) {
      if (Array.isArray(response.data) && response.data.length > 0) {
        this.facilities = response.data.map(association => association.facility_id);
        // this.onSelectFacility(this.facilities[0]._id);
        this.showFacilityDropdown();
      }
    }
  }

  async initSnfWcNurseFacilityAssociation() {
    const filter = {
      associated_snf_wc_nurse_ids: this._authService.currentUser._id,
      company_id: this._authService.currentUser.company_id
    };
    const nurseProjection = {
      first_name: 1,
      last_name: 1,
      title: 1,
    };
    const facilityProjection = {
      title: 1,
      pcc_facId: 1,
      pcc_2_legged_authentication: 1,
      source: 1,
      pcc_orgUuid: 1
    };
    const response: any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
    if (response.status === 200 && response.data.length > 0) {
      this.facilities = response.data;
      this.showFacilityDropdown();
    }
  }

  async initWoundNurseFacilityAssociation() {
    const filter = {
      company_id_ref: this.filter.companyId,
      associated_wound_nurse_ids: this._authService.currentUser._id
    };
    const nurseProjection = {
      first_name: 1,
      last_name: 1,
      title: 1,
    };
    const facilityProjection = {
      title: 1,
      pcc_facId: 1,
      pcc_2_legged_authentication: 1,
      source: 1
    };
    const facilitiesResponse: any = await lastValueFrom(this.woundNurseService.getWoundNurseFacilities(filter, nurseProjection, facilityProjection));
    if (facilitiesResponse.status === 200) {
      this.facilities = facilitiesResponse.data;
      this.showFacilityDropdown();
    }
  }

  showFacilityDropdown(): void {
    this.filteredFacilities = this.facilityControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => this.filterFacilities(value)));
  }
  onTabChange(event) {
    this.tabIndex = event.index;
  }
  toggleRow(id) {
    this.expandedRows.splice(this.expandedRows.indexOf(id), 1);
    this.expandedRows.push(id);
  }

  handlePagination() {
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    if (this.pagination.skip + this.companyFacilityPatients.length < this.pagination.totalRecords) {
      this.pagination.hasNextPage = true;
    }
    else {
      this.pagination.hasNextPage = false;
    }
    if (this.pagination.skip > 0) {
      this.pagination.hasPrevPage = true;
    }
    else {
      this.pagination.hasPrevPage = false;
    }
  }

  async handlePageNavigation(shouldgoBack: boolean) {
    this.loader.startLoader(this.loaderId);
    shouldgoBack ? this.pagination.pageNo-- : this.pagination.pageNo++;
    await this.initPatients();
    this.loader.stopLoader(this.loaderId);
  }
  async changeUpdationDate(event) {
    if (event.startDate && event.endDate) {
      this.updationDate = {
        startDate: null,
        endDate: null
      };
      const noOfDays = this.commonService.getDifferenceOfDates(event.startDate,event.endDate,'days');
      if (noOfDays > 6) {
        this.updationDate.startDate = moment(event.startDate);
        this.updationDate.endDate = moment(this.updationDate.startDate).add(6, "days").endOf('day');
        this._toastr.error('Can not select more than 7 days', 'Date Error');
      }
      else {
        this.updationDate.startDate = event.startDate;
        this.updationDate.endDate = event.endDate;
      }
    }
    if(this.selectedFacility){
      this.loader.startLoader(this.loaderId);
      await this.initPatients();
      this.loader.stopLoader(this.loaderId);
    }
  }
  sortData(sort: Sort) {
    const isAsc = sort.direction === 'asc';
    // const oldAssessmentData = _.clone(this.companyFacilityPatients);
    let assessmentData = _.clone(this.companyFacilityPatients);
    if (this.companyFacilityPatients.length > 0) {
      assessmentData.sort((patientA, patientB) =>{
        switch (sort.active) {
          case 'last_seen':
            try {
              const lsa= this.getLastSeen(patientA);
              const lsb= this.getLastSeen(patientB);
              const normalizeA=lsa!=''? lsa : 'Max-12/12/2099';
              const normalizeB=lsb!=''? lsb : 'Max-12/12/2099';
              
              if (normalizeA && normalizeB) {
                
                let dateA= (normalizeA?.split('-')[1])?.trim();
                let dateB= (normalizeB?.split('-')[1])?.trim();

                return compare(new Date(dateA).getTime(), new Date(dateB).getTime(), isAsc);

              } else if (normalizeA) {
                  return (isAsc ? 1 : -1);
              } else if (normalizeB) {
                  return (isAsc ? 1 : -1);
              }
               else {
                  return 0;
              }

            } catch (error) {
              console.log("Error while sorting", error);
            }
        }
      });

      this.dataSource.data = assessmentData;
    }
  }
}

function compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
  if (typeof a === 'string' && typeof b === 'string') {
      return isAsc ? a.localeCompare(b) : b.localeCompare(a);
  }
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
