import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { PatientRoundingSheetCommentsService } from 'src/app/services/patient-roundingsheet-comments.service';
import { DialogEmailSendingComponentComponent } from '../../dialog-email-sending-component/dialog-email-sending-component.component';

@Component({
  selector: 'app-reason-dialog',
  templateUrl: './reason-dialog.component.html',
  styleUrls: ['./reason-dialog.component.css']
})
export class ReasonDialogComponent implements OnInit {

  title:String;
  reason:String='Please Review Again.';
  facility_id:any;
  mail_loader = 'mail_loader';
  currentUser: any;
  censusId: any;
  patientId: any;

  constructor(public dialogRef: MatDialogRef<DialogEmailSendingComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService,
    private patientRoundingSheetCommentsService: PatientRoundingSheetCommentsService,
    private loader: NgxUiLoaderService,
    private companyService: CompanyService,
    private _authService: AuthService
    ) { 
      this.currentUser = this._authService.currentUser;

    }
  output:any;

  ngOnInit(): void {

    console.log("this.data", this.data);
    this.title=this.data.title;
    this.censusId=this.data.censusId;
    this.patientId=this.data.patientId;
  }

  async handleClose(result){
    
    if(result){
      this.loader.startLoader(this.mail_loader);
      
      //  drop reasonn    
      if (result !== "") {
  
        let details: any = {};
  
        details.patient_id = this.patientId,
        details.comment = result;
        details.created_by = this.currentUser._id;
        details.rounding_sheet_id = this.censusId;
  
        this.patientRoundingSheetCommentsService.addCensusComment(details).subscribe((response: any) => {
          this.loader.stopLoader(this.mail_loader);
          this.dialogRef.close(result);
  
        });
      }

      
 
    }else{
      this.dialogRef.close(null);
    }
  }

}
