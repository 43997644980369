import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PatientRoundingSheetService } from "../../../includes/patient-rounding-sheets/patient-rounding-sheet.service";
import * as global from '../../../includes/global';
import { AuthService } from "../../../services/auth.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { MatTableDataSource } from '@angular/material/table';
import { ChargesCatagoryService } from 'src/app/shared/popup-dialogs/charges-catagories/charges-catagories-dialog/charges-catagory.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/services/common.service';
import { BillerDashboardService } from 'src/app/includes/biller-dashboard/biller-dashboard.service';
import { FormControl } from '@angular/forms';
import { Observable, lastValueFrom } from 'rxjs';
import { map, startWith } from 'rxjs/operators'
import * as _ from 'lodash';
import { CensusPatientListService } from 'src/app/includes/census-patient-list/census-patient-list.service';
import { MipsService } from 'src/app/services/Modules/mips.service';
import { CensusListService } from 'src/app/includes/census-list/census-list..service';
import { ModifierService } from 'src/app/services/modifier.service';
import { NoteEditorService } from 'src/app/includes/note-editor/note-editor.service';
import { SidebarDiagnosisService } from '../../sidebar-addCharges/sidebarDiagnonses/sidebar-diagnoses/sidebar-diagnosis.service';
import moment from 'moment'
import { ElasticSeachService } from 'src/app/services/elasticSearch.service';
import { Location } from '@angular/common';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { IcdCodesService } from 'src/app/services/Modules/icd-codes.service';

@Component({
    selector: 'app-add-charge',
    templateUrl: './add-charge.component.html',
    styleUrls: ['./add-charge.component.css']
})
export class AddChargeComponent implements OnInit, OnDestroy {
    loaderId = 'loader-01';
    loaderId2 = 'loader-02';
    global = global;
    @Input() patientId;
    @Input() ownerId;
    @Input() visitDate;
    @Input() patientName;
    @Input() params: any;
    genderOptions: string[] = ['Male', 'Female'];
    @Output() closeView = new EventEmitter<boolean>();
    icds = [];
    cpts = [];
    canEditPatientInfo:boolean=false
    patientInfo={
        first_name:'',
        last_name:'',
        gender:'',
        dob:'',
        source:''
    }
    reserved_icds = [];
    reserved_cpts = [];
    timeZone = '';
    model = {
        owner_id: '',
        patient_id: '',
        rounding_sheet_id: '',
        icd_id: [],
        cpt_id: [],
        cpt_modifiers: [],
        billing_note: '',
        note: '',
        status: '',
        visit_date: '',
        facility_id: '',
        measure: false,
        charge_id: '',
        is_approved: 'false',
        note_id: '',
        patient_first_name:'',
        patient_last_name:'',
        patient_dob:'',
        patient_gender:'',
        source:'',
        // modifier: undefined
    };
    searchFilter = {
        cpt: '',
        icd: ''
    };
    public paginationChargesAll: {
        pageNo: number,
        hasNextPage: boolean,
        hasPrevPage: boolean,
        totalCount: number,
        totalPages: number
    };
    public paginationDiagnosesAll: {
        pageNo: number,
        hasNextPage: boolean,
        hasPrevPage: boolean,
        totalCount: number,
        totalPages: number
    };
    allDiagnoses: any = [];
    allCharges: any = [];
    selectedCharges: any = []
    selectedDiagnoses: any = []
    visible = true;
    selectable = true;
    removable = true;
    displayedColumns = ['fvrt', 'code', 'checkbox']
    selectedDisplayedColumns = ['code', 'checkbox']
    selectedDisplayedColumnsCharges = ['code', 'checkbox', 'modifier']
    dataSourceAllICDs: any;
    dataSourceFvrtICDs: any;
    dataSourceAllCPTs: any;
    dataSourceFvrtCPTs: any;
    dataSourceSelectedCPTs: any;
    dataSourceSelectedICDs: any = new MatTableDataSource([]);
    searchICD: Boolean = true;
    searchCPT: Boolean = true;
    chargeFilter = '';
    diagnoseFilter = '';
    fvrtCPTTab = true;
    allCPTTab = false;
    fvrtICDTab = true;
    allICDTab = false;
    categories = [];
    selectedCategory: any;
    draftedData: any;
    note: any;
    noteData: any;
    pccIcds = true;
    firstStart = true;
    showBillerAddNote = false;
    selectedModifier: any;
    disableButtons: Boolean = false;
    noChange: Boolean = true;
    // modifiers: any[] = [];
    modifierControl = new FormControl();
    filteredModifiers: Observable<any[]>;
    subscriptions: any[] = [];
    currentUser: any;
    charge: any;
    patient: any = {};
    flag: Boolean = false;
    icdSelectedLoading = true;
    modifiers: {
        code: string,
        description: string
    }[];
    showMipsQuestionaireResults = false;
    signedNotesStatus = false;
    timeoutId: any;
    isDataLoading: boolean;
    associatedTemplateCPTs: any;

    constructor(
        private service: PatientRoundingSheetService,
        private toastr: ToastrService,
        private router: Router,
        private chargesCatagoryService: ChargesCatagoryService,
        private loader: NgxUiLoaderService,
        private authService: AuthService,
        private commonService: CommonService,
        private billerDashboardService: BillerDashboardService,
        private censusPatientListService: CensusPatientListService,
        private mipsService: MipsService,
        private censusListService: CensusListService,
        private modifierService: ModifierService,
        private noteEditorService: NoteEditorService,
        private sidebarDiagnosisService: SidebarDiagnosisService,
        private _route: ActivatedRoute,
        private elasticSearch: ElasticSeachService,
        private _location: Location,
        private _company: CompanyService,
        private _ICDcodesService: IcdCodesService
    ) {
        this.paginationChargesAll = {
            pageNo: 1,
            hasNextPage: false,
            hasPrevPage: false,
            totalCount: 0,
            totalPages: 0
        };
        this.paginationDiagnosesAll = {
            pageNo: 1,
            hasNextPage: false,
            hasPrevPage: false,
            totalCount: 0,
            totalPages: 0
        };
        this.currentUser = this.authService.currentUser;
        this.modifiers = this.modifierService.getModifiers();
        // this.patient.pcc_patientId = null;
    }
    onListDropICD(event: CdkDragDrop<string[]>) {
        // Swap the elements around
        moveItemInArray(this.selectedDiagnoses, event.previousIndex, event.currentIndex);
        // this.censusPatientListService.setICDsForCharge(this.model.icd_id);
        this.model.icd_id = this.selectedDiagnoses.map(a => a._id)
        // this.dataSourceSelectedICDs = new MatTableDataSource(this.selectedDiagnoses);
        this.dataSourceSelectedICDs.data = this.selectedDiagnoses;
    }
    onListDropCPT(event: CdkDragDrop<string[]>) {
        // Swap the elements around
        moveItemInArray(this.selectedCharges, event.previousIndex, event.currentIndex);
        // this.censusPatientListService.setICDsForCharge(this.model.icd_id);
        this.model.cpt_id = this.selectedCharges.map(a => a._id)
        this.dataSourceSelectedCPTs = new MatTableDataSource(this.selectedCharges);
    }

    async ngOnInit() {
        this.loader.startLoader(this.loaderId);
        await this._company.getCompanyChargeandSignNoteStatus(this.currentUser.company_id).subscribe((res: any) => {
            if (res.status == 200) {
                this.canEditPatientInfo= res.data[0].can_edit_patient_while_submitting_charges
                if(res.data[0].should_sign_note != undefined && res.data[0].should_sign_note != null){
                    this.signedNotesStatus = res.data[0].should_sign_note;
                    this.canEditPatientInfo= res.data[0].can_edit_patient_while_submitting_charges
                }
            }
        });
        console.log("params:===============", this.params)
        if (this.params && this.params.timeZone) {
            this.timeZone = this.params.timeZone
        }
        else {
            this.timeZone = 'America/Los_Angeles'
        }
        // let patient;
        let data = {
            ...this.currentUser,
            patient_id: this.params.patient_id
        }
        let pat: any = await this.noteEditorService.getPatient(data).toPromise()
        if (pat.status == 200) {
            this.patient = pat.data.patient;
        }
        else {
            this.patient = {
                _id: this.params.patient_id
            }
        }
        let dates = {
            service: this.params.visit_date
        }
        await this.billerDashboardService.getPatientsChargesAdmin(null, null, this.currentUser.company_id, {}, this.params.charge_id).subscribe((response: any) => {
            if (response.status == 200) {
                if (Array.isArray(response.data) && response.data.length === 1) {
                    let patientCharge = response.data[0];
                    console.log("Edit Charge Clicked: ", patientCharge)
                    let date_obj;
                    this.associatedTemplateCPTs = response.associatedTemplateCPTS;
                    if (patientCharge.date_obj) {
                    // console.log("patientCharge.date_obj: ", patientCharge.date_obj);
                    let { year, month, day, hour, minute } = patientCharge.date_obj;
                    if (month < 10) {
                        month = '0' + month;
                    }
                    if (day < 10) {
                        day = '0' + day;
                    }
                    let str = `${month}-${day}-${year}`;
                    if (hour && minute) {
                        if (minute < 10) {
                        minute = `0${minute}`
                        }
                        if (hour < 10) {
                        hour = `0${hour}`
                        }
                        str += ` ${hour}:${minute}`
                    }
                    date_obj = str;
                }
                    this.commonService.setDraftedCharge(patientCharge);
            }
            }
        })
        
        // await this.initCharge();

        this.subscriptions.push(this.commonService.castDraftedCharge.subscribe(res => {
            console.log('ressssss',res);
            if (res) {
                
                this.draftedData = res;
                if (this.draftedData) {
                    this.showMipsQuestionaireResults = true;

                    this.note = {
                        patient: this.patient,
                        provider: this.draftedData.owner_id,
                        dates,
                        rounding_sheet_id: this.params.rs_id
                    }
                    console.log(this.note);
                }
                
                this.model.measure = res.measure;
                this.model.note = res.note;
            }
        }))
        if (this.draftedData) {
            // console.log("drafted data exists-------------");
            if (this.draftedData.icds) {
                this.draftedData.icd_id = this.draftedData.icds;
            }
            else if (this.draftedData.icd_id) {
                this.draftedData.icd_id = this.draftedData.icd_id;
            }
            else {
                this.draftedData.icd_id = [];
            }
            if (this.draftedData.cpts) {
                this.draftedData.cpt_id = this.draftedData.cpts;
            }
            else if (this.draftedData.cpt_id) {
                this.draftedData.cpt_id = this.draftedData.cpt_id;
            }
            else {
                this.draftedData.cpt_id = [];
            }
            if(this.draftedData?.note_id){
                this.noteData = this.draftedData.note_id;
            }
        }
        if (!this.draftedData) {
            // console.log("NO drafted data-------------");
            await this.getRecentNote();
        }
        if (this.draftedData?.icd_id) {
             
            this.draftedData?.icd_id.forEach(element => {
                this.addToModel(element, null, 'icd')
            });

            // this.selectedDiagnoses = this.draftedData?.icd_id;
            // this.model.icd_id = this.draftedData?.icd_id.map(icd => icd._id);
            // this.dataSourceSelectedICDs = new MatTableDataSource(this.selectedDiagnoses);
            // }
            this.draftedData?.cpt_id.forEach(element => {
                this.addToModel(element, null, 'cpt')
            });
            if (this.draftedData && Array.isArray(this.draftedData.cpt_modifiers)) {
                this.model.cpt_modifiers = this.draftedData.cpt_modifiers;
            }
        }
        // if(this.draftedData){
        //     this.sidebarDiagnosisService.setSelectedICDs(this.selectedDiagnoses);
        // }

        this.sidebarDiagnosisService.castSelectedICDs.subscribe((icds => {
            if (icds) {
                const dnIcds = this.selectedDiagnoses.filter((icd) => {
                    return !icd.pcc
                })
                let mergedArray = [...dnIcds, ...icds]
                let uniqueMergedArray = mergedArray.reduce((acc, icd) => {
                    let alreadyExist = acc.find(a => a.code === icd.code)
                    if (!alreadyExist) {
                        return [...acc, icd]
                    }
                    return acc;
                }, [])
                this.selectedDiagnoses = uniqueMergedArray;
                this.model.icd_id = uniqueMergedArray.map(icd => icd._id);
                this.dataSourceSelectedICDs.data = this.selectedDiagnoses;
                // }
            }
            this.firstStart = false;
        }))
        this.flag = true;
        this.getFvrtCPTs();
        this.getFvrtICDs();
        window.scroll(0, 0);
        if (this.currentUser.user_type == 6 || this.currentUser.user_type == global.USER_TYPE.BILLER) {
            this.showBillerAddNote = true;
        } else {
            this.showBillerAddNote = false;
        }
       await this.getCharge(this.params.charge_id)

    }
    async getCharge(id:any)
    {
        const details = {
            patient_id: this.params.patient_id,
            charge_id:id
        }
        let response: any = await this.censusListService.getCharge(details).toPromise()
                this.patientInfo.first_name=response.data.patient_first_name
                this.patientInfo.last_name=response.data.patient_last_name
                this.patientInfo.gender=response.data.patient_gender
                this.patientInfo.source=response.data.source
                this.patientInfo.dob=response.data.patient_dob

        if (response.status === 200) {
            this.loader.stopLoader(this.loaderId);
            this.charge = response.data;
            this.model.cpt_modifiers = response.data.cpt_modifiers;
        }
    }
    getVisitDate(charge) {
        if (charge.date_obj) {
            let dos = charge.date_obj.split(' ');

            return dos[0];
        }
        else {
            // return moment(new Date(date).toLocaleString('en-US', { timeZone:  this.timeZone })).format('MMM DD, YYYY');
            return moment(new Date(charge.visit_date).toLocaleString('en-US')).format('MM-DD-YYYY');
        }
    }

    ngOnDestroy(): void {
        // this.sidebarDiagnosisService.setSelectedICDs(this.selectedDiagnoses);
        // this.sidebarDiagnosisService.setSelectedICDs([]);
    }
    getRecentNote() {
        return new Promise<void>((resolve, reject) => {
            let recentcharge = this.service.getRecentCharge(this.params).subscribe((res: any) => {
                if (res.status == 200) {
                    this.draftedData = res.data;
                    console.log("Draft=============", this.draftedData);
                    if (this.draftedData) {
                        this.noteData = this.draftedData.note;
                        delete this.draftedData.status;
                    }
                    resolve(null);
                } else {
                    reject();
                }
            })
        });
    };
    async initCharge() {
        const details = {
            patient_id: this.note.patient._id,
            rounding_sheet_id: this.note.rounding_sheet_id,
            visit_date: this.note.dates.service,
            status: 'draft'
        }
        let response: any = await this.censusListService.getCharge(details).toPromise()
        // await this.censusListService.getCharge(details).subscribe((response:any) => {
        if (response.status === 200) {
            this.charge = response.data;
            // console.log("init charge",this.charge)
        }
        // })
    }

    // async initModifiers() {
    //     const modifiersResponse: any = await this.billerDashboardService.getModifiers().toPromise();
    //     
    //     if (modifiersResponse.status === 200) {
    //         this.modifiers = modifiersResponse.data.array
    //     }

    //     this.filteredModifiers = this.modifierControl.valueChanges
    //         .pipe(
    //             startWith(''),
    //             map((value:any) => {
    //                 console.log(value)
    //                 return value ? this._filterModifier(value) : this.getFilteredModifiers()
    //             }),
    //         );

    // }
    // getFilteredModifiers() {

    //     if (!Array.isArray(this.modifiers)) return [];
    //     let resultPatients = [];
    //     resultPatients = _.cloneDeep(this.modifiers);
    //     console.log(resultPatients)
    //     return resultPatients;
    // }
    // displayModifierFn(modifier): string {
    //     if (modifier) {
    //         return `${modifier.code} ${modifier.description}`;
    //     }
    //     return '';
    // }
    // private _filterModifier(value): any[] {
    //     const filterValue = value.toLowerCase();
    //     console.log({
    //         filterValue
    //     })

    //     return this.getFilteredModifiers().filter(modifier =>
    //         `${modifier.code} ${modifier.description}`.toLowerCase().indexOf(filterValue) >= 0
    //     );
    // }

    async getFvrtCPTs() {
        const res: any = await this.service.getFavoriteCPTs().toPromise();
        if (res.status === 200) {

            this.getAllCPTs();
            // @ts-ignore
            this.cpts = res.data.array;
            // if(this.draftedData?.parent == 'biller-dashboard'){
            for (let k = 0; k < this.selectedCharges.length; k++) {
                for (let i = 0; i < this.cpts.length; i++) {
                    if (this.cpts[i]._id == this.selectedCharges[k]._id) {
                        this.cpts[i].selected = true;
                    }
                }
            }
            // }
            // @ts-ignore
            this.reserved_cpts = res.data.array;
            this.dataSourceFvrtCPTs = new MatTableDataSource(this.cpts);
        }
    }
    async getFvrtICDs() {
        const res = await this.service.getFavoriteICDs().toPromise();

        this.getAllICDs();
        // @ts-ignore
        this.icds = res.data.array;
        // if(this.draftedData?.parent == 'biller-dashboard'){
        for (let k = 0; k < this.selectedDiagnoses.length; k++) {
            for (let i = 0; i < this.icds.length; i++) {
                if (this.icds[i]._id == this.selectedDiagnoses[k]._id) {
                    this.icds[i].selected = true;
                }
            }
        }
        // }
        // @ts-ignore
        this.reserved_icds = res.data.array;
        this.dataSourceFvrtICDs = new MatTableDataSource(this.icds);

    }
    async getAllCPTs(page = 1, search = "") {
        const response: any = await this.service.getCPTs({ page: page, search: search }).toPromise();

        if (response.status == 200) {
            if (search.trim() == '') {
                this.allCharges.push(...response.data.array);
            }
            else {
                this.allCharges = response.data.array;
            }
            // this.allCharges = response.data.array;
            for (let i = 0; i < this.allCharges.length; i++) {
                for (let j = 0; j < this.cpts.length; j++) {
                    if (this.allCharges[i]._id.toString() == this.cpts[j]._id.toString()) {
                        this.allCharges[i].fvrt = true;
                    }
                }
                // uncomment if want to show selected area
                // for (let k = 0; k < this.selectedCharges.length; k++) {
                //     if (this.allCharges[i]._id == this.selectedCharges[k]._id) {
                //         this.allCharges[i].selected = true;
                //     }
                // }
            }
            // if(this.draftedData?.parent == 'biller-dashboard'){
            for (let k = 0; k < this.selectedCharges.length; k++) {
                for (let i = 0; i < this.allCharges.length; i++) {
                    if (this.allCharges[i]._id == this.selectedCharges[k]._id) {
                        this.allCharges[i].selected = true;
                    }
                }
            }
            // }
            const totalCount = response.data.totalCount;
            this.paginationChargesAll.totalCount = totalCount;
            this.paginationChargesAll.pageNo = page;
            this.initPaginationChargesAll();
            this.dataSourceAllCPTs = new MatTableDataSource(this.allCharges);

        }

    }
    async getAllICDs(page = 1, search = "") {
        this.loader.startLoader(this.loaderId);
        this.isDataLoading = true;
        let response: any = await lastValueFrom (this.elasticSearch.searchICDCodewithPagination(search, (page + 1) * 10, 0));

        if (response.status == 200) {
            this.allDiagnoses = response.data;
            // for (let i = 0; i < this.allDiagnoses.length; i++) {
            //     this.allDiagnoses[i]._id = this.allDiagnoses[i]['mongo_id'];
            //     delete this.allDiagnoses[i].mongo_id;
            // }
            for (let i = 0; i < this.allDiagnoses.length; i++) {
                for (let j = 0; j < this.icds.length; j++) {
                    if (this.allDiagnoses[i]._id.toString() == this.icds[j]._id.toString()) {
                        this.allDiagnoses[i].fvrt = true;
                    }
                }
            }
            // uncomment if want to show selected area
            // for (let i = 0; i < this.allDiagnoses.length; i++) {
            //     for (let k = 0; k < this.selectedDiagnoses.length; k++) {
            //         if (this.allDiagnoses[i]._id == this.selectedDiagnoses[k]._id) {
            //             this.allDiagnoses[i].selected = true;
            //         }
            //     }
            // }
            // if(this.draftedData?.parent == 'biller-dashboard'){
            for (let k = 0; k < this.selectedDiagnoses.length; k++) {
                for (let i = 0; i < this.allDiagnoses.length; i++) {
                    if (this.allDiagnoses[i]._id == this.selectedDiagnoses[k]._id) {
                        this.allDiagnoses[i].selected = true;
                    }
                }
            }
            // }
            const totalCount = response.data.totalRecords;
            this.paginationDiagnosesAll.totalCount = totalCount;
            this.paginationDiagnosesAll.pageNo = page;
            this.initPaginationDiagnosesAll();
            this.dataSourceAllICDs = new MatTableDataSource(this.allDiagnoses);
            this.loader.stopLoader(this.loaderId);
            this.isDataLoading = false;
        }
    }
    isFavourite(element, type, tab) {

        if (type == 'icd') {
            if (tab == 'fvrts') {
                return true
            }
        }
        else if (type == 'cpt') {
            if (tab == 'fvrts') {
                return true
            }
        }
    }
    async addToFavouriteDiagnoses(diagnose) {
        // if(this.currentUser.user_type == 6 || this.currentUser.user_type == global.USER_TYPE.BILLER){
        //     return
        // }
        this.loader.startLoader('loader-01');
        let data = {
            ids: diagnose._id,
            isRemove: false,
            filter: 'icd_id'
        };
        const response: any = await this.service.setFavoriteICDs(data).toPromise();
        if (response.status == 200) {
            this.icds.push(diagnose);
            this.allDiagnoses.map(diagnos => {
                if (diagnos._id == diagnose._id) {
                    diagnos.fvrt = true;
                    this.dataSourceAllICDs = new MatTableDataSource(this.allDiagnoses)
                }
            })
            this.dataSourceFvrtICDs = new MatTableDataSource(this.icds)
            this.loader.stopLoader('loader-01');
        }
    }

    removeFavouriteDiagnoses(diagnose) {
        // if(this.currentUser.user_type == 6 || this.currentUser.user_type == global.USER_TYPE.BILLER){
        //     return
        // }
        let index = -1;
        for (let i = 0; i < this.icds.length; i++) {
            if (diagnose._id == this.icds[i]._id) {
                index = i;
            }
        }
        if (index > -1) {
            this.icds.splice(index, 1)
            this.dataSourceFvrtICDs = new MatTableDataSource(this.icds)
        }
        this.allDiagnoses.map(diagnos => {
            if (diagnos._id == diagnose._id) {
                diagnos.fvrt = false;
                this.dataSourceAllICDs = new MatTableDataSource(this.allDiagnoses)
            }
        })
        let data = {
            ids: diagnose._id,
            isRemove: true,
            filter: 'icd_id'
        };
        this.service.setFavoriteICDs(data).toPromise()

    }

    async addFavouriteCharges(charge) {
        // if(this.currentUser.user_type == 6 || this.currentUser.user_type == global.USER_TYPE.BILLER){
        //     return
        // }
        this.loader.startLoader('loader-02');
        let data = {
            ids: charge._id,
            isRemove: false,
            filter: 'cpt_id'
        };
        const response: any = await this.service.setFavoriteCPTs(data).toPromise()
        if (response.status == 200) {
            this.cpts.push(charge);
            this.allCharges.map(charg => {
                if (charg._id == charge._id) {
                    charg.fvrt = true;
                    this.dataSourceAllCPTs = new MatTableDataSource(this.allCharges)
                }
            })
            this.dataSourceFvrtCPTs = new MatTableDataSource(this.cpts)
            this.loader.stopLoader('loader-02');
        }
    }

    removeFavouriteCharges(charge) {
        // if(this.currentUser.user_type == 6 || this.currentUser.user_type == global.USER_TYPE.BILLER){
        //     return
        // }
        let index = -1;
        for (let i = 0; i < this.cpts.length; i++) {
            if (charge._id == this.cpts[i]._id) {
                index = i;
            }
        }
        if (index > -1) {
            this.cpts.splice(index, 1)
            this.dataSourceFvrtCPTs = new MatTableDataSource(this.cpts);
        }
        this.allCharges.map(char => {
            if (char._id == charge._id) {
                char.fvrt = false;
                this.dataSourceAllCPTs = new MatTableDataSource(this.allCharges)
            }
        })

        let data = {
            ids: charge._id,
            isRemove: true,
            filter: 'cpt_id'
        };
        this.service.setFavoriteCPTs(data).toPromise();
    }
    onListDropfvrtICDs(event: CdkDragDrop<any[]>) {
        let filter = 'icd_id'
        moveItemInArray(this.icds, event.previousIndex, event.currentIndex);
        this.dataSourceFvrtICDs = new MatTableDataSource(this.icds);
        this.service.rearrangeFavoriteCharges(this.icds.map(c => c._id), filter)
            .toPromise()
    }
    onListDropfvrtCPTs(event: CdkDragDrop<any[]>) {
        let filter = 'cpt_id';
        moveItemInArray(this.cpts, event.previousIndex, event.currentIndex);
        this.dataSourceFvrtCPTs = new MatTableDataSource(this.cpts);
        this.service.rearrangeFavoriteCharges(this.cpts.map(c => c._id), filter)
            .toPromise()
    }
    addToModel(element, event, type) {
        // this.noChange = true;
        // if(this.currentUser.user_type == 6 || this.currentUser.user_type == global.USER_TYPE.BILLER){
        //     return
        // }
        if (type == 'cpt') {
            if (element.selected) {
                let index = this.model.cpt_id.indexOf(element._id);
                if (index > -1) {
                    element.selected = false;
                    this.selectedCharges.splice(index, 1)
                    this.model.cpt_id.splice(index, 1)
                    this.allCharges.map(d => {
                        if (d._id == element._id) {
                            d.selected = false;
                        }
                    })
                    this.cpts.map(d => {
                        if (d._id == element._id) {
                            d.selected = false;
                        }
                    })
                }
                else {
                    element.selected = true;
                    this.selectedCharges.push(element)
                    this.model.cpt_id.push(element._id);
                    this.allCharges.map(d => {
                        if (d._id == element._id) {
                            d.selected = true;
                        }
                    })
                    this.cpts.map(d => {
                        if (d._id == element._id) {
                            d.selected = true;
                        }
                    })
                }
            }
            else if (!element.selected) {
                element.selected = true;
                this.selectedCharges.push(element)
                this.model.cpt_id.push(element._id);
                this.allCharges.map(d => {
                    if (d._id == element._id) {
                        d.selected = true;
                    }
                })
                this.cpts.map(d => {
                    if (d._id == element._id) {
                        d.selected = true;
                    }
                })
            }
            this.dataSourceFvrtCPTs = new MatTableDataSource(this.cpts);
            this.dataSourceSelectedCPTs = new MatTableDataSource(this.selectedCharges);
            this.censusPatientListService.setCPTsForCharge(this.model.cpt_id);
            this.censusPatientListService.setCPTModifiersForCharge(this.model.cpt_modifiers);
        }
        else if (type == 'icd') {
            // this.icdSelectedLoading = false;
            if (element.selected) {
                let index = this.model.icd_id.indexOf(element._id);

                if (index > -1) {
                    // if(element.selected == true ){

                    // }
                    // else{
                    element.selected = false;
                    this.selectedDiagnoses.splice(index, 1)
                    this.model.icd_id.splice(index, 1)
                    this.allDiagnoses.map(d => {
                        if (d._id == element._id) {
                            d.selected = false;
                        }
                    })
                    this.icds.map(d => {
                        if (d._id == element._id) {
                            d.selected = false;
                        }
                    })
                    // }
                }
                else {
                    element.selected = true;
                    this.selectedDiagnoses.push(element)
                    this.model.icd_id.push(element._id);
                    this.allDiagnoses.map(d => {
                        if (d._id == element._id) {
                            d.selected = true;
                        }
                    })
                    this.icds.map(d => {
                        if (d._id == element._id) {
                            d.selected = true;
                        }
                    })
                }

            }
            //1 //1 2 3
            else if (!element.selected) {
                element.selected = true;
                this.selectedDiagnoses.push(element)
                this.model.icd_id.push(element._id);
                this.allDiagnoses.map(d => {
                    if (d._id == element._id) {
                        d.selected = true;
                    }
                })
                this.icds.map(d => {
                    if (d._id == element._id) {
                        d.selected = true;
                    }
                })
            }
            //@ts-ignore
            const set: any = new Set(this.selectedDiagnoses.map(JSON.stringify));
            const hasDuplicates = set.size < this.selectedDiagnoses.length;

            // console.log("Set: ", JSON.parse(set));
            if (hasDuplicates) {
                this.selectedDiagnoses = Array.from(new Set(this.selectedDiagnoses.map(a => a._id)))
                    .map(_id => {
                        return this.selectedDiagnoses.find(a => a._id === _id)
                    })
            }
            this.dataSourceFvrtICDs = new MatTableDataSource(this.icds);
            // this.dataSourceSelectedICDs = new MatTableDataSource(this.selectedDiagnoses);
            this.dataSourceSelectedICDs.data = this.selectedDiagnoses;

            this.censusPatientListService.setICDsForCharge(this.model.icd_id);
            // if(!this.firstStart && this.note.patient?.pcc_patientId){
            //     this.sidebarDiagnosisService.setSelectedICDs(this.selectedDiagnoses);
            // }
        }
    }
    removeDiagnose(diagnose) {
        let index = this.model.icd_id.indexOf(diagnose._id);
        if (index > -1) {
            this.selectedDiagnoses.splice(index, 1)
            this.model.icd_id.splice(index, 1)
        }
        // this.dataSourceSelectedICDs = new MatTableDataSource(this.selectedDiagnoses);
        this.dataSourceSelectedICDs.data = this.selectedDiagnoses;
        this.allDiagnoses.map(a => {
            if (a._id == diagnose._id) {
                a.selected = false;
            }
        })
        this.dataSourceAllICDs = new MatTableDataSource(this.allDiagnoses);
        this.icds.map(a => {
            if (a._id == diagnose._id) {
                a.selected = false;
            }
        })
        this.dataSourceFvrtICDs = new MatTableDataSource(this.icds);
    }
    removeCharge(charge) {
        if(!this.isElementProcedureType(charge)){
            let index = this.model.cpt_id.indexOf(charge._id);
            if (index > -1) {
                this.selectedCharges.splice(index, 1)
                this.model.cpt_id.splice(index, 1)
            }
            this.dataSourceSelectedCPTs = new MatTableDataSource(this.selectedCharges);
            this.allCharges.map(a => {
                if (a._id == charge._id) {
                    a.selected = false;
                }
            })
            this.dataSourceAllCPTs = new MatTableDataSource(this.allCharges);
            this.cpts.map(a => {
                if (a._id == charge._id) {
                    a.selected = false;
                }
            })
            this.dataSourceFvrtCPTs = new MatTableDataSource(this.cpts);
        }else{
            this.toastr.info("This code is associated with a procedure. You cannot remove it");
        }
        return false;
    }

    searchDiagnosesWithDelay(value) {
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
            this.searchDiagnose(value);
        }, 1000);
    }
      
    searchDiagnose(value) {
        this.searchFilter.icd = value?.trim()?.toLowerCase();
        // if (!value || value == '') {
        //     return;
        // }
        // else {
        if (this.allICDTab) {
            this.getAllICDs(1, value)
        }
        else if (this.fvrtICDTab) {
            this.applyFilterICD(this.searchFilter.icd);
        }
        // }
    }
    searchCharge(value) {
        // this.searchFilter.cpt = value?.trim()?.toLowerCase();
        // if (!value || value == '') {
        //     return;
        // }
        // else {
        if (this.allCPTTab) {
            this.getAllCPTs(1, value)
        }
        else if (this.fvrtCPTTab) {
            this.applyFilterCPT(value)
        }
        // }
    }
    applyFilterICD(value) {
        if (value) {
            let filteredData = [];
            value = value.trim().toLowerCase();
            this.reserved_icds.map(a => {
                if (a.code.trim().toLowerCase().includes(value) || a.description.trim().toLowerCase().includes(value)) {
                    filteredData.push(a)
                }
            })
            this.dataSourceFvrtICDs = new MatTableDataSource(filteredData)
        }
        else {
            this.dataSourceFvrtICDs = new MatTableDataSource(this.reserved_icds)
        }
    }
    applyFilterCPT(value) {
        if (value) {
            let filteredData = [];
            value = value.trim().toLowerCase();
            this.reserved_cpts.map(a => {
                if (a.code?.trim().toLowerCase().includes(value) || a.description?.trim().toLowerCase().includes(value)) {
                    filteredData.push(a)
                }
            })
            this.dataSourceFvrtCPTs = new MatTableDataSource(filteredData)
        }
        else {
            this.dataSourceFvrtCPTs = new MatTableDataSource(this.reserved_cpts)
        }
    }
    clearChargeFilter() {
        this.chargeFilter = " ";
        this.getAllCPTs(1, '')
    }
    clearDiagnoseFilter() {
        this.diagnoseFilter = " ";
        this.getAllICDs(1, '')
    }
    async billedCharge(status= 'billed', approved = 'true'){
        let chargeIDS = [this.draftedData._id];
        let response: any = await this.billerDashboardService.SubmitAllandApprove(chargeIDS, status).toPromise();
        if (response.status == 200) {
            this.disableButtons = false;
            this.toastr.success('Charge Updated', 'Success');
            if (this.params.backCtrl == 'true') {
                this._location.back();
            }
            else {
                this._location.back();

                // this.router.navigate([`/biller-dashboard`]);
            }
        }
    }
    async addCharge(statuss = 'submit', approved = 'true') {
        this.model.patient_first_name = this.patientInfo.first_name;
        this.model.patient_last_name = this.patientInfo.last_name;
        this.model.patient_gender = this.patientInfo.gender;
        this.model.source = this.patientInfo.source;
        let resubmitted_by = ''
        if(statuss == 'resubmit'){
            resubmitted_by = this.currentUser._id;
        }
        this.disableButtons = true;
        if(this.authService.currentUser.user_type == '6' || this.authService.currentUser.user_type == this.global.USER_TYPE.BILLER){
            if (this.draftedData && this.draftedData._id) {
                // console.log("this.draftedData: ", this.draftedData);
                this.model.owner_id = this.draftedData.owner_id._id;
            }
        }
        else{
            this.model.owner_id = this.authService.currentUser._id
        }
        console.log("type",typeof(this.patientInfo.dob))
        this.model.patient_id = this.params.patient_id;
        this.model.visit_date = this.params.visit_date;
        this.model.rounding_sheet_id = this.params.rs_id;
        this.model.patient_dob = this.patientInfo.dob;
        this.model.facility_id = this.params.facility_id;
        this.model.status = statuss;
        this.model.is_approved = approved;
        if(this.noteData?._id){
            this.model.note_id = this.noteData?._id;
        }else{
            this.model.note_id = this.noteData;
        }
        if (this.model.icd_id.length <= 0 && this.model.cpt_id.length <= 0) {
            return;
        }
        if (this.draftedData && this.draftedData._id) {
            this.model.charge_id = this.draftedData._id;
        }
        else if (this.params.charge_id) {
            this.model.charge_id = this.params.charge_id;
        }
        // if (this.draftedData?.parent == 'biller-dashboard') {
        //     this.model.charge_id = this.draftedData._id;
        // }
        // if (this.model.status === "submit")
        // this.model.status = 'submit';
        // console.log("this,model: ", this.model);
        this.toastr.info('Saving Charge ');
        console.log("model",this.model)
        const res: any = await this.service.addCharge(this.model, resubmitted_by)
            .toPromise();
        if (res.status == 200) {
            // if (this.draftedData?.parent == 'biller-dashboard') {
            //     this.router.navigate([`/biller-dashboard`]);
            // }
            // else {
            this.disableButtons = false;
            const message = statuss == 'resubmit' ? "Charge Resubmitted" : "Charge Updated";
            this.toastr.success(message, 'Success');
            if (this.params.backCtrl == 'true') {
                this._location.back();
            }
            else {
                this._location.back();

                // this.router.navigate([`/biller-dashboard`]);
            }
            // this.router.navigate([`/biller-dashboard`])
            // if (this.params.url == "census") {
            //     this.toastr.success('Charge Created', 'Success');
            //     this.router.navigate([`/census/${this.params.rs_id}`]);
            // } else {
            //     this.toastr.success('Charge Updated', 'Success');
            //     this.router.navigate([`/biller-dashboard`]);
            // }
            const [charge] = res.data.array;
            this.subscriptions.push(this.mipsService.castQuestionnaireResults.subscribe((questionnaireResults: any) => {
                // const {measure, answer} = data;
                
                if (Array.isArray(questionnaireResults) && questionnaireResults.length > 0) {
                    this.mipsService.saveQuestionnareResults(questionnaireResults.map(r => ({ measure_id: r.measure._id, answer: r.answer, matched: r.measure.matched })), charge._id, this.currentUser.company_id)
                        .subscribe((resposne: any) => {
                            // console.log(resposne);
                        })
                }
            }))
            // }
            // this.closeView.emit(true);

        }
        // this.sidebarDiagnosisService.setSelectedICDs(null);
    }
    disableButton(model, button = 'draft') {
        if ((model?.status == 'submit' || this.draftedData?.status == "submit") && (this.currentUser.user_type != 6 || this.currentUser.user_type != this.global.USER_TYPE.BILLER) && !this.params.backCtrl ) {
            return true;
        }
        else if ((model?.status == 'submit' || this.draftedData?.status == "submit") && (this.currentUser.user_type == 6 || this.currentUser.user_type == this.global.USER_TYPE.BILLER)  && this.params.backCtrl  ) {
            return false;
        }
        else if ((model?.status == 'submit' || this.draftedData?.status == "submit") && this.params.backCtrl  ) {
            return false;
        }
        else if ((model.icd_id.length <= 0 || model.cpt_id.length <= 0 || this.draftedData?.icd_id.length <= 0 || this.draftedData?.cpt_id.length <= 0) && (model?.status == 'draft' || this.draftedData?.status == "draft")) {
            if(button == 'draft'){
                if(model.icd_id.length <= 0 && model.cpt_id.length <= 0){
                    return true;
                }else{
                    return false
                }
            }else{
                return true;
            }
        }
        else {
            return false;
        }
    }
    dragable() {
        if (this.model?.status == 'submit' || this.draftedData?.status == "submit") {
            return true;
        }
    }
    cancel() {
        this._location.back();
        // if (this.params.backCtrl == 'true') {
        //     this._location.back();
        // }
        // else {
        //     this.router.navigate([`/biller-dashboard`]);
        // }
        // if (this.draftedData?.parent == 'biller-dashboard') {
        //     this.router.navigate([`/biller-dashboard`]);
        // }
        // else {
        //     this.router.navigate([`/census/${this.params.rs_id}`]);
        // }
        // this.closeView.emit(true);
    }
    saveData() {
        // if (this.draftedData?.status === "submit") {
        //     this.model.status = 'submit';
        // }
        // else {
        //     this.model.status = 'draft'
        // }
        console.log("draft model",this.model)
        this.model.status = 'draft'
        this.addCharge(this.model.status, 'false')
    }

    resubmit(){
        console.log("draft model",this.model)
        // this.model.status = 'draft'
        this.addCharge('resubmit', 'true')
    }

    handlePrevClick(type) {
        if (type == 'chargesAll') {
            this.getAllCPTs(this.paginationChargesAll.pageNo - 1, this.searchFilter.cpt);
        }
        else if (type == 'diagnoseAll') {
            this.getAllICDs(this.paginationDiagnosesAll.pageNo - 1, this.searchFilter.icd);
        }
    }
    handleNextClick(type) {
        if (type == 'chargesAll') {
            this.getAllCPTs(this.paginationChargesAll.pageNo + 1, this.searchFilter.cpt);
        }
        else if (type == 'diagnoseAll') {
            this.getAllICDs(this.paginationDiagnosesAll.pageNo + 1, this.searchFilter.icd);
        }
    }

    getCountofCodes(type) {
        if (type == 'chargesAll') {
            // return ((this.paginationChargesAll.pageNo * 10) - 9) + ' - ' + (((this.paginationChargesAll.pageNo * 10) - 10) + Number(this.allCharges.length)) + " out of " + (this.paginationChargesAll.totalCount);
            return '1 - ' + (Number(this.allCharges.length)) + " out of " + (this.paginationChargesAll.totalCount);
        }
        else if (type == 'diagnoseAll') {
            // return ((this.paginationDiagnosesAll.pageNo * 10) - 9) + ' - ' + (((this.paginationDiagnosesAll.pageNo * 10) - 10) + Number(this.allDiagnoses.length)) + " out of " + (this.paginationDiagnosesAll.totalCount);
            return '1 - ' + (Number(this.allDiagnoses.length)) + " out of " + (this.paginationDiagnosesAll.totalCount);
        }
    }

    initPaginationDiagnosesAll() {
        this.paginationDiagnosesAll.totalPages = Math.ceil(this.paginationDiagnosesAll.totalCount / 10);
        if (this.allDiagnoses) {
            this.paginationDiagnosesAll.hasNextPage = this.allDiagnoses.length > 0 && this.paginationDiagnosesAll.pageNo < this.paginationDiagnosesAll.totalPages;
        }
        this.paginationDiagnosesAll.hasPrevPage = this.paginationDiagnosesAll.pageNo > 1;
    }
    initPaginationChargesAll() {
        this.paginationChargesAll.totalPages = Math.ceil(this.paginationChargesAll.totalCount / 10);
        if (this.allCharges) {
            this.paginationChargesAll.hasNextPage = this.allCharges.length > 0 && this.paginationChargesAll.pageNo < this.paginationChargesAll.totalPages;
        }
        this.paginationChargesAll.hasPrevPage = this.paginationChargesAll.pageNo > 1;
    }
    onTabClickCharges(event) {
        if (event.tab.textLabel.trim().toLowerCase() == 'all') {
            this.searchCPT = true;
            this.allCPTTab = true;
            this.fvrtCPTTab = false;
        }
        else {
            this.searchCPT = true;
            this.fvrtCPTTab = true;
            this.allCPTTab = false;
        }
    }
    onTabClickDiagnose(event) {
        if (event.tab.textLabel.trim().toLowerCase() == 'my icds') {
            this.searchICD = true;
            this.allICDTab = true;
            this.fvrtICDTab = false;
        }
        else {
            this.searchICD = true;
            this.fvrtICDTab = true;
            this.allICDTab = false;
        }
    }

    changeReportMeasure(event) {
        // console.log("Event: ", event);
        this.censusPatientListService.setICDsForCharge(this.model.icd_id);
        this.censusPatientListService.setCPTsForCharge(this.model.cpt_id);
        // this.note.cpts = this.model.cpt_id;
        // this.note.icds = this.model.cpt_id;
        // console.log("This.note in add-charge: ", this.note);
        // this.censusPatientListService.setReportMeasure(event);
    }

    getCptModifier(cpt) {
        const cpt_modifier = this.model.cpt_modifiers?.find(cpt_modifier => cpt_modifier.cpt === cpt._id);
        if (cpt_modifier) {
            return cpt_modifier.modifier
        }
    }
    handleModifierChange(cpt, modifier) {
        // this.noChange = true;
        const cpt_modifier = this.model.cpt_modifiers?.find(cpt_modifier => cpt_modifier.cpt === cpt._id);
        if (cpt_modifier) {
            // console.log("in cpt_modifier: ", cpt_modifier);
            cpt_modifier.modifier = modifier
        } else {
            let obj = {
                cpt: cpt._id,
                modifier
            }
            this.model.cpt_modifiers.push(obj);
        }
        this.censusPatientListService.setCPTModifiersForCharge(this.model.cpt_modifiers);
    }
    handleModifierUnitChange(cpt, unit) {
        let res = unit;
        if (unit === '') {
            res = "1";
        }
        const cpt_modifier = this.model.cpt_modifiers.find(cpt_modifier => cpt_modifier.cpt === cpt._id);
        if (cpt_modifier) {
            cpt_modifier.unit = res
        } else {
            this.model.cpt_modifiers.push({
                cpt: cpt._id,
                unit: res
            });
        }
        this.censusPatientListService.setCPTModifiersForCharge(this.model.cpt_modifiers);
    }
    getCptModifierUnit(cpt) {
        const cpt_modifier = this.model.cpt_modifiers?.find(cpt_modifier => cpt_modifier.cpt === cpt._id);
        if (cpt_modifier) {
            return cpt_modifier.unit
        } else {
            return "1";
        }
    }

    isMipReportingEnabled() {
        return !!this.note?.provider?.mip_reporting;
    }
    async submitCharge() {
        if (this.signedNotesStatus) {
            if (this.noteData?.is_signed === "true") {
                await this.addCharge();
            } else {
                if(this.noteData == undefined || this.noteData == null){
                    this.toastr.info("Create a note first before submitting the charge");
                }else{
                    this.toastr.info("Note must be signed first before submitting the charge");
                }
            }
        } else {
            await this.addCharge();
        }
    }
    isElementProcedureType(element) {
        if (this.model.cpt_modifiers.findIndex(mod => mod.cpt == element._id && mod.modifier?.length > 0) > -1) {
          return true;
        } else {
          return false;
        }
      }
      getSelectedChargesCopy() {
        return JSON.parse(JSON.stringify(this.selectedCharges));
      }
      toggleFvrtCPTsinHistory(data) {
        if (data.action === "remove") {
          this.removeFavouriteCharges(data.cpts);
        } else {
          this.addFavouriteCharges(data.cpts);
        }
      }
      toggleCPTsinChange(cpt) {
        console.log("Toggle");
        this.addToModel(cpt, null, 'cpt');
        return false;
      }
      getSelectedDiagonoseCopy() {
        return JSON.parse(JSON.stringify(this.selectedDiagnoses));
      }
      toggleICDsinHistory(icd) {
        this.addToModel(icd, null, 'icd');
      }
}
