<form #form="ngForm" (submit)="handleSubmit(form)">
    <div class="fields-sections flex flex-col">
        <div class="fields-section">

            <mat-form-field class="field-item">
                <mat-label>Title</mat-label>
                <input [disabled]="isPccFacililty" matInput placeholder="Title" [(ngModel)]="model.title" name="title" #facility_title="ngModel"
                    required
                    onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32) || (event.charCode==45)" />
                <mat-error *ngIf="facility_title.invalid" class="fonts">
                    Please enter title
                </mat-error>
            </mat-form-field>

            <mat-form-field class="field-item">
                <mat-label>Address</mat-label>
                <input [disabled]="isPccFacililty" matInput placeholder="Address" [(ngModel)]="model.address" name="address" required
                    (keypress)="keyPressAlphanumeric($event)" />
            </mat-form-field>

            <mat-form-field class="field-item">
                <mat-label>Street</mat-label>
                <input matInput  [formControl]="streetControl" placeholder="Street" [(ngModel)]="model.street" name="street"
                    (keypress)="keyPressAlphanumeric($event)" />
            </mat-form-field>
            <mat-form-field class="field-item">
                <mat-label>City</mat-label>
                <input [disabled]="isPccFacililty" matInput placeholder="City" [(ngModel)]="model.city" name="city"
                required  (keypress)="allowOnlyText($event)" />
            </mat-form-field>
            <mat-form-field class="field-item">
                <mat-label>State</mat-label>
                <mat-select [disabled]="isPccFacililty"  [(ngModel)]="model.state" name="state" required>
                    <mat-option *ngFor="let state of usa_states; let i = index" [value]="numberToString(i+1)">
                        {{state.abbreviation}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="field-item">
                <mat-label>Zip Code</mat-label>
                <input matInput  [formControl]="zipControl" placeholder="Zip Code" [(ngModel)]="model.zip_code" name="zip_code" required
                    (keypress)="keyPressNumbers($event)" />
            </mat-form-field>

            <mat-form-field class="field-item">
                <mat-label>Unique ID</mat-label>
                <input [disabled]="isPccFacililty" matInput placeholder="Unique ID" type="text" [(ngModel)]="model.trueRCMID" name="trueRCMID"
                    required />
            </mat-form-field>

            <mat-form-field class="field-item">
                <mat-label>POD</mat-label>
                <mat-select [disabled]="isPccFacililty" [(ngModel)]="model.pod" name="pod" class="fonts">
                    <mat-option *ngFor="let pod of pods; let i = index" [value]="pod">
                        {{pod}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="field-item">
                <mat-label>NPI</mat-label>
                <input matInput  [formControl]="npiControl" placeholder="NPI" [(ngModel)]="model.npi" name="npi" 
                onkeypress="return (event.charCode > 47 && event.charCode < 58) || (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)"/>
            </mat-form-field>
        </div>
        <div class="fields-section">
            <mat-form-field class="field-item">
                <mat-label>Place of Service</mat-label>
                <mat-select [disabled]="isPccFacililty"  [(ngModel)]="model.place_of_service" name="place_of_service" class="fonts">
                    <mat-option value=""></mat-option>
                    <mat-option value="32"> SNF - Skilled Nursing Facility: 32 </mat-option>
                    <mat-option value="21"> Inpatient Hospital: 21 </mat-option>
                </mat-select>
            </mat-form-field>
            
            <mat-form-field class="field-item" *ngIf="isProductOwner">
                <mat-select placeholder="Source" [(ngModel)]="model.source" name="source">
                    <mat-option value=""></mat-option>
                    <mat-option value="MatrixCare"> Matrix Care </mat-option>
                    <mat-option value="SigmaCare"> Sigma Care </mat-option>
                    <mat-option value="Certifications"> Certification </mat-option>
                    <mat-option value="PointClickCare"> PointClickCare </mat-option>
                    <mat-option value="">--DN-- </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="field-item"
                *ngIf="(model.source == 'MatrixCare' || model.source == 'SigmaCare')&&isProductOwner">
                <mat-label>External ID</mat-label>
                <input matInput  [formControl]="externalIdControl" placeholder="External ID" type="text" [(ngModel)]="model.external_id" name="external_id"
                     />
            </mat-form-field>
            <mat-form-field class="field-item" >
                <mat-label>Organization ID</mat-label>
                <input matInput  [formControl]="orgIdControl" placeholder="Organization ID" type="text" [(ngModel)]="model.organization_id" name="organization_id"
                     />
            </mat-form-field>
        </div>
        <div class="fields-section">
            <mat-form-field class="field-item" *ngIf="isPccFacililty">
                <mat-label>PCC Facility ID</mat-label>
                <input matInput  [formControl]="facIdControl" placeholder="PCC Facility ID" type="text" [(ngModel)]="model.pcc_facId" name="pcc_facId" />
            </mat-form-field>
            <mat-form-field class="field-item" *ngIf="isPccFacililty">
                <mat-label>PCC Org UUID</mat-label>
                <input matInput  [formControl]="pccOrgIdControl" placeholder="PCC Org UUID" type="text" [(ngModel)]="model.pcc_orgUuid" name="pcc_orgUuid" />
            </mat-form-field>
            <mat-form-field>
                <mat-label>Select Timezone</mat-label>
                <mat-select placeholder="State" [(ngModel)]="model.timeZone" name="timeZone" required>
                    <mat-option *ngFor="let timezone of timezones" value="{{timezone.value}}">{{timezone.text}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

    <br />

    <div class="flex items-center justify-end gap-5">
        <div style="display: flex; flex-direction: column;">
            <div style="display: flex; justify-content: flex-end;">
                <button mat-raised-button class="dn-button-primary" style="width: max-content;" type="button" *ngIf="this.model.source == 'MatrixCare' && facility_id" [disabled]="isInitialLoadBtnDisable" (click)="matrixPatientExtract()">
                    Initial Load
                </button>
            </div>
            <span style="font-size:14px; color: #dd5858;" *ngIf="this.model.source == 'MatrixCare' && facility_id && model.initial_load">* Already fetched Initial Data</span>
        </div>
        <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
            Go Back
        </button>
        <button mat-raised-button class="dn-button-primary" type="submit" [disabled]="form.invalid">
            Save
        </button>
    </div>
    <div *ngIf="facility_id" style="display:flex; flex-direction: column; align-items: flex-start;"> 
    </div>
</form>