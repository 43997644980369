import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TherapyService } from '../therapy.service';

@Component({
  selector: 'app-census-detail-report',
  templateUrl: './census-detail-report.component.html',
  styleUrls: ['./census-detail-report.component.css']
})
export class CensusDetailReportComponent implements OnInit {
  @Input('patient') patient: any;
  // displayedColumns: string[] = ['therapies', 'start_of_care', 'end_of_care','payer'];
  displayedColumns: string[] = ['payer','pt_soc','pt_eoc','ot_soc','ot_eoc','st_soc','st_eoc'];
  dataSource: any = new MatTableDataSource([])
  patientCensusReport: any;
  constructor(private therapyService: TherapyService) { }

  async ngOnInit() {
    await this.getCensusDetailsReport();
  }

  async getCensusDetailsReport(patient = null) {
    let patientName = '';
    if(patient) {
      patientName = patient.last_name + ', ' + patient.first_name.trim();
    }else {
      patientName = this.patient.last_name + ', ' + this.patient.first_name.trim();
    }
    console.log('asdasdas',patientName);
    this.therapyService.getCensusDetailsReport(patientName).subscribe(async (res: any) => {
      if (res.status == 200) {
        this.patientCensusReport = res.data.records;
        // await this.normalizeCensusDetailsReport(this.patientCensusReport);
        await this.normalizeCensusDetailsReport(this.patientCensusReport);
      } else {
        console.log('something went wrong');
      }
    });
  }

  async normalizeCensusDetailsReport(data: any) {
    let table = [];
    for (let recordsIndex = 0; recordsIndex < data.length; recordsIndex++) {
      const element = data[recordsIndex];
      for (let i = 0; i < element.pt[0].soc.length; i++) {
        const ptSoc = element.pt[0].soc[i];
        const ptEoc = element.pt[0].eoc[i];
        const otSoc = element.ot[0].soc[i];
        const otEoc = element.ot[0].eoc[i];
        const stSoc = element.st[0].soc[i];
        const stEoc = element.st[0].eoc[i];
        const payerType = element.payor_type[i];
        if (table.find(
          t => t.payor_type == payerType 
          && (t.pt_soc == ptSoc || t.pt_soc == '-') 
          && (t.pt_eoc == ptEoc || t.pt_eoc == '-')
          && (t.ot_soc == otSoc || t.ot_soc == '-') 
          && (t.ot_eoc == otEoc || t.ot_eoc == '-')
          && (t.st_soc == stSoc || t.st_soc == '-') 
          && (t.st_eoc == stEoc || t.st_eoc == '-'))) {
          console.log('existed');
        } else {
          if (
            (ptSoc != '' || ptEoc != '') || 
            (otSoc != '' || otEoc != '') || 
            (stSoc != '' || stEoc != '')) {
            table.push({ 
              payor_type: payerType ? payerType : element.payor_type[0], 
              pt_soc: ptSoc ? ptSoc : '-', pt_eoc: ptEoc ? ptEoc : '-' , 
              st_soc: stSoc ? stSoc : '-', st_eoc: stEoc ? stEoc : '-' , 
              ot_soc: otSoc ? otSoc : '-', ot_eoc: otEoc ? otEoc : '-' });
          }
        }
      }
    }
    this.dataSource.data = table;
    console.log('table,table', table);
  }

  //old implementation
  // async normalizeCensusDetailsReport(data: any) {
  //   let table = [];
  //   for (let recordsIndex = 0; recordsIndex < data.length; recordsIndex++) {
  //     const element = data[recordsIndex];
  //     for (let i = 0; i < element.pt[0].soc.length; i++) {
  //       const newElement = element.pt[0].soc[i];
  //       const newElement2 = element.pt[0].eoc[i];
  //       if(table.find(t => t.type == 'Physical' && (t.soc == newElement || t.soc == '-') && (t.eoc == newElement2 || t.eoc == '-'))) {
  //         console.log('existed');
  //       }else{
  //         if(newElement != '' || newElement2 != '') {
  //           table.push({ type : 'Physical' , soc : newElement ? newElement : '-' , eoc : newElement2 ? newElement2 : '-' , payer : element.payor_type[i] ? element.payor_type[i] : element.payor_type[0]});
  //         }
  //       }
  //     }

  //     for (let i = 0; i < element.ot[0].soc.length; i++) {
  //       const newElement = element.ot[0].soc[i];
  //       const newElement2 = element.ot[0].eoc[i];
  //       if(table.find(t => t.type == 'Occupational' && (t.soc == newElement || t.soc == '-') && (t.eoc == newElement2 || t.eoc == '-'))) {
  //         console.log('existed');
  //       }else{
  //         if(newElement != '' || newElement2 != '') {
  //           table.push({ type : 'Occupational' , soc : newElement ? newElement : '-' , eoc : newElement2 ? newElement2 : '-' , payer : element.payor_type[i] ? element.payor_type[i] : element.payor_type[0]});
  //         }
  //       }
  //     }

  //     for (let i = 0; i < element.st[0].soc.length; i++) {
  //       const newElement = element.st[0].soc[i];
  //       const newElement2 = element.st[0].eoc[i];
  //       if(table.find(t => t.type == 'Speech' && (t.soc == newElement || t.soc == '-') && (t.eoc == newElement2 || t.eoc == '-'))) {
  //         console.log('existed');
  //       }else{
  //         if(newElement != '' || newElement2 != '') {
  //           table.push({ type : 'Speech' , soc : newElement ? newElement : '-', eoc : newElement2 ? newElement2 : '-', payer : element.payor_type[i] ? element.payor_type[i] : element.payor_type[0]});
  //         }
  //       }
  //     }

  //   }
  //   this.dataSource.data = table;
  //   console.log('table,table',table);
  // }
}
