import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AudioTranscription, DoctorTranscription} from '../includes/transcription-create/transcriptionModel';
import * as global from '../includes/global';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {API_URL} from "../../environments/api-url";

@Injectable({
    providedIn: 'root'
})
export class AppointmentService {

    currentUser: any;

    constructor(private httpclient: HttpClient,
                private _authService: AuthService) {
        this.currentUser = this._authService.currentUser;
    }

    getAppointments(filter) {

        //  can select multiple or all or just one

        // filter={
        //     company_id,
        //     provider_id
        //     patient_id
        //     facility_id
        // }

        const details = {
            id: this.currentUser._id,
            usertype: this.currentUser.user_type,
            auth_key: this.currentUser.auth_key,
            filter: filter
        };
        return this.httpclient.post(global.url + API_URL.PATIENTAPPOINTMENT.getAppointments, {details});
    }
    updateAppointment(filter) {
        // filter={
        //     _id          /// MENDATORY feild to update

        //     patient_id
        //     provider_id
        //     facility_id
        //     company_id

        //     is_active     // boolean default true
        //     "date_obj.day",
        //     "date_obj.month",
        //     "date_obj.year",
        //     startTime     // string
        //     endTime       // string
        //     duration      // string
        //     title
        //     dayOff        // boolean default false
        //     instructions
        //     comments
        //     episode
        //     reason
        //     appointment_status
        //     appointment_type
        //     created_by
        //     attributes
        // }
        const details = {
            id: this.currentUser._id,
            usertype: this.currentUser.user_type,
            auth_key: this.currentUser.auth_key,
            ...filter
        };
        return this.httpclient.post(global.url + API_URL.PATIENTAPPOINTMENT.updateAppointment, {details: details}).pipe();
    }
    updateAppointmentStatus(filter) {
        // filter={
        //     _id          /// MENDATORY feild to update
        //     appointment_status
        // }
        const details = {
            id: this.currentUser._id,
            usertype: this.currentUser.user_type,
            auth_key: this.currentUser.auth_key,
            ...filter
        };
        return this.httpclient.post(global.url + API_URL.PATIENTAPPOINTMENT.updateAppointmentStatus, {details: details}).pipe();
    }
    addNewPatientAppointment(filter) {
        //  
        // filter={

        //     patient_id
        //     provider_id
        //     facility_id
        //     company_id

        //     is_active     // boolean default true
        //     "date_obj.day",
        //     "date_obj.month",
        //     "date_obj.year",
        //     startTime     // string
        //     endTime       // string
        //     duration      // string
        //     title
        //     dayOff        // boolean default false
        //     instructions
        //     comments
        //     episode
        //     reason
        //     appointment_status
        //     appointment_type
        //     created_by
        //     attributes
        // }
        
        const details = {
            id: this.currentUser._id,
            usertype: this.currentUser.user_type,
            auth_key: this.currentUser.auth_key,
            ...filter
        };
        return this.httpclient.post(global.url + API_URL.PATIENTAPPOINTMENT.addNewPatientAppointment, {details: details}).pipe();
    }
    getOneAppointment(appointment_id) {
        // appointment_id
        const details = {
            id: this.currentUser._id,
            usertype: this.currentUser.user_type,
            auth_key: this.currentUser.auth_key,
            appointment_id: appointment_id
        };
        return this.httpclient.post(global.url + API_URL.PATIENTAPPOINTMENT.getOneAppointment, {details: details}).pipe();
    }
    deleteAppointment(data) {
        // appointment_id
        const {_id, recurrenceRule, exDate,occurrences,deleteRecurrenceEvents} = data
        const details = {
            _id,
            recurrenceRule,
            occurrences,
            exDate,
            deleteRecurrenceEvents
        };
        return this.httpclient.post(global.url + API_URL.PATIENTAPPOINTMENT.deleteAppointments, {details: details}).pipe();
    }

    //////////////////////////////////////////

    getAppointmentTemplates(filter) {

        //  can select multiple or all or just one

        // filter={
        //     company_id,
        //     provider_id
        //     patient_id
        //     facility_id
        // }

        const details = {
            id: this.currentUser._id,
            usertype: this.currentUser.user_type,
            auth_key: this.currentUser.auth_key,
            filter: filter
        };
        return this.httpclient.post(global.url + API_URL.PATIENTAPPOINTMENT.getAppointmentTemplates, {details});
    }
    updateAppointmentTemplate(filter) {
        // filter={
        //     _id          /// MENDATORY feild to update

            // title
            // dayOff
            // "start_date_obj.day".day
            // "start_date_obj.month".month
            // "start_date_obj.year".year
            // "end_date_obj.day".day
            // "end_date_obj.month".month
            // "end_date_obj.year".year
            // startTime
            // endTime
            // duration
            // reasons
            // note
            // repeating
            // provider_id
            // facility_id
            // company_id
            // selectedDays
            // selectedWeekdays
            // selectedWeeks
            // is_active
        // }
        
        const details = {
            id: this.currentUser._id,
            usertype: this.currentUser.user_type,
            auth_key: this.currentUser.auth_key,
            ...filter
        };
        return this.httpclient.post(global.url + API_URL.PATIENTAPPOINTMENT.updateAppointmentTemplate, {details: details}).pipe();
    }
    addNewProviderTemplate(filter) {
        //  
        // filter={
            // title
            // dayOff
            // "start_date_obj.day".day
            // "start_date_obj.month".month
            // "start_date_obj.year".year
            // "end_date_obj.day".day
            // "end_date_obj.month".month
            // "end_date_obj.year".year
            // startTime
            // endTime
            // duration
            // reasons
            // note
            // repeating
            // provider_id
            // facility_id
            // company_id
            // selectedDays
            // selectedWeekdays
            // selectedWeeks
            // is_active
        // }
        
        const details = {
            id: this.currentUser._id,
            usertype: this.currentUser.user_type,
            auth_key: this.currentUser.auth_key,
            ...filter
        };
        return this.httpclient.post(global.url + API_URL.PATIENTAPPOINTMENT.addNewProviderTemplate, {details: details}).pipe();
    }
    getOneAppointmentTemplate(appointment_id) {
        // appointment_id
        const details = {
            id: this.currentUser._id,
            usertype: this.currentUser.user_type,
            auth_key: this.currentUser.auth_key,
            appointment_id: appointment_id
        };
        return this.httpclient.post(global.url + API_URL.PATIENTAPPOINTMENT.getOneAppointmentTemplate, {details: details}).pipe();
    }
    deleteAppointmentTemplate(data) {
        // appointment_id
        const {_id, exDate,deleteRecurrenceEvents} =data
        const details:any = {
            _id,
            exDate,
            deleteRecurrenceEvents
        };
        return this.httpclient.post(global.url + API_URL.PATIENTAPPOINTMENT.deleteAppointmentTemplate, {details: details}).pipe();
    }
    getUserBusinesshour(data) {
        // appointment_id
        const { provider_id, company_id } =data
        const details:any = {
            provider_id,
            company_id,
        };
        return this.httpclient.post(global.url + API_URL.PATIENTAPPOINTMENT.getUserBusinesshour, {details: details}).pipe();
    }
    saveUserBusinesshour(data) {
        // appointment_id
        const { provider_id, company_id, businessHours } =data
        const details:any = {
            provider_id,
            company_id,
            businessHours
        };
        return this.httpclient.post(global.url + API_URL.PATIENTAPPOINTMENT.saveUserBusinesshour, {details: details}).pipe();
    }
    saveSelectedUserInBusinesshour(data) {
        // appointment_id
        const { provider_ids, company_id } =data
        const details:any = {
            provider_ids,
            company_id,
            user_id: this.currentUser._id,
        };
        return this.httpclient.post(global.url + API_URL.PATIENTAPPOINTMENT.saveSelectedUserInBusinesshour, {details: details}).pipe();
    }

}
