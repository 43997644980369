import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { Consent } from './consent.model';
import { MatDialog } from "@angular/material/dialog";
import { DialogConsentFormComponent } from './dialog-consent-form/dialog-consent-form.component';
import { ConsentService } from './service/consent.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { DeleteConfirmationDialog } from '../../drive/delete-confirmation-dialog.component';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.css']
})
export class ConsentComponent implements OnInit {

  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = ['sr', 'title', 'description', 'actions'];
  consents: Consent[] = [];
  companyId: any;
  loaderId = 'app-consent';
  constructor(
    private loader: NgxUiLoaderService,
    private _authService: AuthService,
    private dialog: MatDialog,
    private _consentService: ConsentService,
    private _toastr: ToastrService,
    private _route: ActivatedRoute,
  ) {
    this.companyId = this._route.parent.snapshot.params.id;
  }

  ngOnInit(): void {
    this.getConsents();
  }
  getConsents() {
    this.loader.startLoader(this.loaderId);
    const filter = {
      // company_id: this.companyId
      is_deleted: false
    };
    const project = {
      title: 1,
      description: 1,
    };
    this._consentService.getConsentForms(filter, project).subscribe({
      next: (response: any) => {
        if (response.status === 200) {
          this.consents = response.data;
          this.dataSource.data = this.consents;
        }
        else if (response.status === 500) {
          this._toastr.error(response.message, "ERROR");
          this.loader.stopLoader(this.loaderId);
        }
      }, error: error => {
        this._toastr.error(error, "ERROR");
        this.loader.stopLoader(this.loaderId);
      }
    });
    this.loader.stopLoader(this.loaderId);
  }
  openConsentFormDialog(consent = null, index = -1): void {
    const options: any = {
      width: '70%',
      height: '70vh'
    };
    options.data = JSON.parse(JSON.stringify(consent));
    this.dialog.open(DialogConsentFormComponent, {
      ...options,
    }).afterClosed().subscribe((consent) => {
      if (consent) {
        if (index > -1)
          this.consents[index] = consent;
        else
          this.consents.push(consent);
        this.dataSource.data = [...this.consents];
      }
    });
  }

  onDeleteClick(id, index) {
    const dialogRef = this.dialog.open(DeleteConfirmationDialog, {
      width: '31rem',
      data: { title: "this consent form" }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteConsentForm(id, index);
      }
    });
  }

  deleteConsentForm(id, index) {
    this.loader.startLoader(this.loaderId);
    this._consentService.deleteConsentForm(id).subscribe({
      next: (response: any) => {
        if (response.status == 200) {
          this._toastr.success("Consent Form Deleted", "Success");
          this.dataSource.data = [];
          this.consents.splice(index, 1);
          this.dataSource.data = this.consents;
        }
        else if (response.status === 500) {
          this._toastr.error(response.message, "ERROR");
          this.loader.stopLoader(this.loaderId);
        }
      }, error: error => {
        this._toastr.error(error, "ERROR");
        this.loader.stopLoader(this.loaderId);
      }
    });
    this.loader.stopLoader(this.loaderId);
  }

}
