import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { DrivePhraseService } from '../drive-phrases/drive-phrase.service';
import { CKEditorService } from '../note-editor/ckeditor.service';

@Component({
  selector: 'app-phrase-mobile-editor',
  templateUrl: './phrase-mobile-editor.component.html',
  styleUrls: ['./phrase-mobile-editor.component.css']
})
export class PhraseMobileEditorComponent implements OnInit {
  phrase_key: any;
  phrase_id: any;

  isNew = false;
  phrase: any = { key: "", value: "" };
  cke4Config;
  currentUser: any;
  dictionary: any = [];
  uniqueKeyError: boolean = false;
  oldPhrase: any = { key: "", value: "" };
  

  constructor(
    private _route: ActivatedRoute,
    private ckeditorService: CKEditorService,
    private drivephraseService: DrivePhraseService,
    private _router: Router,
    private authService: AuthService,
    private toastr: ToastrService,


  ) {
    this.currentUser = this.authService.currentUser;

    this.cke4Config = {
      ...this.ckeditorService.getDefaultConfig(),
      editable: true
    };
    this.phrase.key = "";
    this.phrase.value = "";
  }

  ngOnInit(): void {
    this.phrase_key = this._route.snapshot.paramMap.get('phrase_key');
    this.phrase_id = this._route.snapshot.paramMap.get('phrase_id');
    // console.log(this.phrase_id, this.phrase_key);
    this.getPhrasesById();
  }
  getPhrasesById() {
    this.drivephraseService.getPhrasesById(this.phrase_id).subscribe((response: any) => {
      if (response.status === 200) {
        // console.log("response", response.data);
        this.dictionary = response.data;
      }
    });

    this.getPhraseByIdAndKey();
  }

  getPhraseByIdAndKey() {
    this.drivephraseService.getPhraseByIdAndKey(this.phrase_id, this.phrase_key).subscribe((response: any) => {
      if (response.status === 200 && response.data[0]) {
        this.phrase = JSON.parse(JSON.stringify(response.data[0]));
        this.oldPhrase = JSON.parse(JSON.stringify(response.data[0]));
        this.dictionary = this.dictionary.filter(ele => ele.key !== this.phrase.key && ele.value !== this.phrase.value );
        // console.log("response", this.dictionary);
      }
    });
  }

  checkError(key) {
    console.log("key", key);
    for (let i = 0; i < this.dictionary.length; i++) {
      if (this.dictionary[i].key === key) {
        this.uniqueKeyError = true;
        break;
      }
      else {
        this.uniqueKeyError = false;
      }
    }
    
  }

  editPhrase(phrase_key, phrase_value) {
    let newDictionaryElement:any = {
      key: phrase_key,
      value: phrase_value
    }
    if (this.oldPhrase) {
      if (this.oldPhrase.key === newDictionaryElement.key && this.oldPhrase.value === newDictionaryElement.value) {
        return;
      }
      newDictionaryElement.oldKey = this.oldPhrase.key;
    }
    // console.log("newDictionaryElement", newDictionaryElement);
    this.drivephraseService.EditPhrase(newDictionaryElement).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.success("Phrase updated successfully");
      }
    });
  }

  onReadyCkeditor(e) {
    const { editor } = e;
    this.ckeditorService.ckeditorRemoveTitle(editor)
  }


}
