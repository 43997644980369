<table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="first_name">
        <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
            First Name
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.first_name}}
        </td>
    </ng-container>

    <ng-container matColumnDef="last_name">
        <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Last Name
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.last_name}}
        </td>
    </ng-container>

    <ng-container matColumnDef="email">
        <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Email
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.email}}
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>