import { HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Injectable, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import moment from 'moment'
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription, lastValueFrom } from 'rxjs';
import { DialogConfirmationComponent } from 'src/app/includes/census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { NoteEditorService } from 'src/app/includes/note-editor/note-editor.service';
import { CommonService } from 'src/app/services/common.service';
import { PCCService } from 'src/app/services/pcc.service';
import { WoundService } from 'src/app/services/wound.service';
import { CreateWoundDialogComponent } from '../create-wound-dialog/create-wound-dialog.component';
import { SetWoundStageDialogComponent } from '../set-wound-stage-dialog/set-wound-stage-dialog.component';
import { WoundCareWidgetService } from '../wound-care-widget.service';
import { WoundImagePreviewDialogComponent } from '../wound-image-preview-dialog/wound-image-preview-dialog.component';
import { WoundImageSnapshotDialogComponent } from '../wound-image-snapshot-dialog/wound-image-snapshot-dialog.component';
import { WoundImageUploadDialogComponent } from '../wound-image-upload-dialog/wound-image-upload-dialog.component';
import { WoundNoteDialogComponent } from '../wound-note-dialog/wound-note-dialog.component';
import { calculateTimeDifference, calculateArea, calculateVolume, calculateAreaforBanner, calculateVolumeforBanner, calculateAreaSubscore, calculateExudateAmount, calculateTissueType } from '../wound-utils'
import { AuthService } from 'src/app/services/auth.service';
import swal from 'sweetalert';
import * as global from "../../../global"
import { TranscriptionCreateService } from 'src/app/includes/transcription-create/transcription-create.service';
import { UploadNoteConfirmationDialog } from '../../../transcription-create/upload-note-confirmation-dialog/upload-note-confirmation-dialog.component';
import { MoveWoundToCensusComponent } from './move-wound-to-census/move-wound-to-census.component'
import { Router } from '@angular/router';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { ProcedureNoteDialogComponent } from 'src/app/includes/procedure-notes/procedure-note-dialog/procedure-note-dialog.component';
@Component({
  selector: 'app-wound-care-widget-item',
  templateUrl: './wound-care-widget-item.component.html',
  styleUrls: ['./wound-care-widget-item.component.css']
})

export class WoundCareWidgetItemComponent implements OnInit, OnDestroy {
  childWoundsLoaderId = 'childWoundsLoaderId' + Math.random();
  randomValue = [1 + Math.random() * 10, 1 + Math.random() * 10]
  @Input() provider;
  @Input() readOnlyMode: any;
  @Input() patient;
  @Input() wound;
  @Input() isPreview;
  @Input() note;
  @Input() configuration;
  @Input() companyPermissions;
  @Input() created_userType;
  @Input() should_show_view_icon;
  @Output() changeWoundState = new EventEmitter<any>();
  @Output() onICDsSave = new EventEmitter<any>();
  @Output() woundAnalyticReport = new EventEmitter<any>();
  @Output() woundDetailsChange = new EventEmitter<any>();
  @Output() procedureNoteDetailsChange = new EventEmitter<any>();
  @Output() refreshAllWounds = new EventEmitter<any>();
  woundsLoaderId = 'woundsLoaderId' + Math.random()
  @Output() checkallWoundAssessmentPhrase: EventEmitter<any> = new EventEmitter();
  @Output() allWoundIds: EventEmitter<any> = new EventEmitter();
  @Input() woundState: string;
  @Input() created_by;
  @Input() facility_id;
  @Input() creationDate;
  @Input() skinSubRefferal;
  wounds: any[] = [];
  hasWoundsLoaded: Boolean = false;
  // [expanded]="step === 0" (opened)="setStep(0)"
  expanded = false;
  showAllImages = false;
  last_n_weeks: string = 'all';
  should_show_wound_assessment: Boolean = false;
  should_show_procedure_note: Boolean = false;

  expandedView: 'child_wounds' | 'graph' | 'wound_images' | '' = '';
  actualArea: any;
  actualVolume: any;
  negativeArea: any;
  negativeVolume: any;
  mainImage: string;
  otherImages: string[] = [];
  actualParentWound: any;
  previous_state: any;
  time_difference: any;
  percentageArea: number;
  percentageVolume: number;
  currentUser: any;
  latestWoundCopy: any;
  islatestWoundCopied: Boolean = false;
  global = global;
  tabIndex: number = 0;
  isAdminSide: boolean = false;
  isTeleHealthReport: boolean = false;
  telehealthReportSubscription: Subscription;
  isNoteEditor: boolean = false;
  should_show_wound_location: boolean = false;

  constructor(
    private _toaster: ToastrService,
    private loader: NgxUiLoaderService,
    private dialog: MatDialog,
    private woundCareWidgetService: WoundCareWidgetService,
    private commonService: CommonService,
    private woundService: WoundService,
    private _pccService: PCCService,
    private noteEditorService: NoteEditorService,
    private _authService: AuthService,
    private _transcriptionCreateService: TranscriptionCreateService,
    private _router: Router,
    private companyService: CompanyService
  ) {
    if (window.location.pathname.includes('company')) {
      this.isAdminSide = true;
    }
    if (_router.url.includes('note-editor')) {
      this.isNoteEditor = true;
    }
  }

  async ngOnInit(): Promise<void> {
    this.currentUser = this._authService.currentUser;
    if (this.wound) {
      this.telehealthReportSubscription = this.woundCareWidgetService.isTeleHealthReport$.subscribe(value => {
        this.isTeleHealthReport = value;
      });
      if (!this.wound.parent_wound) {
        this.actualParentWound = this.wound;
        this.actualParentWound._most_recent_wound = true;
        this.latestWoundCopy = JSON.parse(JSON.stringify(this.actualParentWound));
        if (this.actualParentWound.is_copied_assessment !== null && this.actualParentWound.is_copied_assessment === true) {
          this.islatestWoundCopied = true;
        }
      } else {
        this.actualParentWound = this.wound.latest_child_wound.find(wound => !wound.parent_wound);
        this.wound._most_recent_wound = true;
        this.latestWoundCopy = JSON.parse(JSON.stringify(this.wound));
        if (this.wound.is_copied_assessment !== null && this.wound.is_copied_assessment === true) {
          this.islatestWoundCopied = true;
        }
      }
      this.previous_state = this.actualParentWound.state;
      this.wound['child_wounds'] = this.actualParentWound.child_wounds;
      await this.initChildWounds();
      if (this.wound.wound_images.length > 1) {
        this.otherImages = this.wound.wound_images.slice(1)
      }
    }
    this.getCompany();
  }

  ngOnDestroy(): void {
    this.telehealthReportSubscription.unsubscribe();
  }

  getRecognitionString(type, date: string, undetermined) {
    if (date && date.includes('T')) {
      date = date.split('T')[0]
    }
    const formatDate = this.getFormattedDate(date);
    if (type === "present_on_admission") {
      if (date !== null || date !== "") {
        return `Present on Admission: ${formatDate}`
      }
    } else if (type === 'facility_aquired') {
      if (undetermined) {
        return 'Facility Aquired: Undetermined'
      } else {
        return `Facility Aquired: ${formatDate}`
      }
    }
  }
  getFormattedDate(date) {
    if (!date) return '';
    const [year, month, day] = date.split("-");
    return month + "/" + day + "/" + year;
    // let date1: any = date.toString();
    // if (date1.includes('Z')) {
    //   let date2 = date1.split('T');
    //   let date3 = date2[0].split('-');
    //   return date3[1] + '/' + date3[2] + '/' + date3[0];
    // }
    // else {
    //   if(date?._i){
    //     if(typeof(date?._i) === "string" && date?._i.includes("-")){
    //       const mainDateArray = date?._i.split('-');
    //       let day = (Number(mainDateArray[2]) < 10) ? ('0' + (mainDateArray[2][mainDateArray[2].length - 1]).toString()) : (mainDateArray[2]).toString();
    //       let month = (Number(mainDateArray[1])  < 10) ? ('0' + (mainDateArray[1][mainDateArray[1].length - 1]).toString()) : (Number(mainDateArray[1])).toString();
    //       let year = (mainDateArray[0]);
    //       return month + "/" + day + "/" + year; 
    //     }else if(typeof(date?._i) === "string" && date?._i.includes("/")){
    //       const mainDateArray = date?._i.split('/');
    //       let day = (Number(mainDateArray[1]) < 10) ? ('0' + (mainDateArray[1][mainDateArray[1].length - 1]).toString()) : (mainDateArray[1]).toString();
    //       let month = (Number(mainDateArray[0]) < 10) ? ('0' + (mainDateArray[0][mainDateArray[0].length - 1]).toString()) : (Number(mainDateArray[0])).toString();
    //       let year = (mainDateArray[2]);
    //       return month + "/" + day + "/" + year; 
    //     }else{
    //       let day = (date._i.date < 10) ? ('0' + (date._i.date).toString()) : (date._i.date).toString();
    //       let month = (date._i.month + 1  < 10) ? ('0' + (date._i.month + 1).toString()) : (date._i.month + 1).toString();
    //       let year = (date._i.year);
    //       return month + "/" + day + "/" + year;
    //     }
    //   }else{
    //     let timeZone = "America/New_York";
    //     if(this.note.facility && this.note.facility.timeZone){
    //       timeZone = this.note.facility.timeZone;
    //     }else if( this.note.timezone ) {
    //       timeZone = this.note.timeZone;
    //     }
    //     return moment(date).tz(timeZone).format('MM/DD/YYYY')
    //   }
    // }
  }

  async getChildWoundsFromApi(initChildWound = true) {
    let state = [this.actualParentWound.state];
    if (!state.includes('active')) {
      state.push('active')
    }
    if (!state.includes('archived')) {
      state.push('archived')
    }
    const lastNWeeks = this.last_n_weeks === 'all' ? undefined : parseInt(this.last_n_weeks);
    let dateFrom, dateTo;
    if (lastNWeeks) {
      dateFrom = moment().startOf('day').subtract(lastNWeeks, 'weeks').toDate(); // last x week
      dateTo = moment().endOf('day').toDate(); // today
      this.time_difference = lastNWeeks == 1 ? lastNWeeks + " week" : lastNWeeks + " weeks";
    } else {
      this.time_difference = this.calculateTimeDifference(this.wound);
    }
    if (initChildWound) {
      const { status, data } = await lastValueFrom(this.woundCareWidgetService.getChildWounds(
        this.actualParentWound.patient_id,
        this.actualParentWound._id,
        dateFrom, dateTo,
        state
      ));
      if (status == 200) {
        // const {child_wounds, ...wound} = this.wound;
        if (data) {
          for (const child_wound of data) {
            if (this.wound._id === child_wound._id) {
              this.wound = child_wound;
              break;
            }
          }
          this.wound['child_wounds'] = data;
          this.actualParentWound['child_wounds'] = data;
        } else {
          this.wound['child_wounds'] = [];
          this.actualParentWound['child_wounds'] = [];
          this.mainImage = this.wound.wound_images[0];
        }
      } else {
        this.wound['child_wounds'] = [];
        this.actualParentWound['child_wounds'] = [];
        this.mainImage = this.wound.wound_images[0];
      }
      return data;
    }
  }

  async initChildWounds(initChildWound = false) {
    this.loader.startLoader(this.childWoundsLoaderId);
    await this.getChildWoundsFromApi(initChildWound);
    if (this.wound?.child_wounds?.length > 0) {
      const allActiveWounds = this.wound.child_wounds.filter(w => w.state === 'active');
      if (allActiveWounds.length > 0) {
        this.mainImage = allActiveWounds[0].wound_images[0];
        this.wound.child_wounds[0]._most_recent_wound = true;
      } else {
        this.mainImage = this.wound.child_wounds[0].wound_images[0];
        this.wound.child_wounds[0]._most_recent_wound = true;
      }
      // this.latestWoundCopy = JSON.parse(JSON.stringify(this.wound.child_wounds[0]));
      // if(this.wound.child_wounds[0].is_copied_assessment !== null && this.wound.child_wounds[0].is_copied_assessment === true){
      //   this.islatestWoundCopied = true;
      // }
      if (this.last_n_weeks === 'all' || this.isWoundInDateRange(this.actualParentWound)) {
        this.percentageArea = ((calculateArea(this.wound?.child_wounds[0]) - calculateArea(this.actualParentWound)) / calculateArea(this.actualParentWound)) * 100;
        this.percentageVolume = ((calculateVolume(this.wound?.child_wounds[0]) - calculateVolume(this.actualParentWound)) / calculateVolume(this.actualParentWound)) * 100;
      } else {
        this.percentageArea = ((calculateArea(this.wound?.child_wounds[0]) - calculateArea(this.wound?.child_wounds[this.wound?.child_wounds?.length - 1])) / calculateArea(this.wound?.child_wounds[this.wound?.child_wounds?.length - 1])) * 100;
        this.percentageVolume = ((calculateVolume(this.wound?.child_wounds[0]) - calculateVolume(this.wound?.child_wounds[this.wound?.child_wounds?.length - 1])) / calculateVolume(this.wound?.child_wounds[this.wound?.child_wounds?.length - 1])) * 100;
      }
      if (Number.isNaN(this.percentageArea) || !Number.isFinite(this.percentageArea)) {
        // this.actualArea = 0;
        this.percentageArea = 0;
      }
      if (Number.isNaN(this.percentageVolume) || !Number.isFinite(this.percentageVolume)) {
        this.actualVolume = 0;
        this.percentageVolume = 0;
      }
      this.percentageArea = Math.round(this.percentageArea);
      if (this.percentageVolume < 0) {
        this.actualVolume = this.percentageVolume;
        this.percentageVolume = Math.round(Math.abs(this.percentageVolume));
      } else {
        this.actualVolume = this.percentageVolume;
        this.percentageVolume = Math.round(this.percentageVolume);
      }
    } else {
      this.actualArea = 0;
      this.actualVolume = 0;
      this.percentageArea = 0;
      this.percentageVolume = 0;
      this.mainImage = this.wound.wound_images[0];
    }
    this.loader.stopLoader(this.childWoundsLoaderId);
  }
  handleWoundClick($event, latest_child_wound) {
    console.log('handleWoundClick')
    // this.
    if (!this.isClickAllowed($event.target)) return;
    this.showWoundCreateDialog(latest_child_wound);
  }
  toggleExpand() {
    this.expanded = !this.expanded
  }
  expand() {
    this.expanded = true;
  }
  collapse($event) {
    if (!this.isClickAllowed($event.target)) return;

    this.expanded = false;
    this.expandedView = '';
  }
  handleMainItemClick($event) {
    if (!this.isClickAllowed($event.target)) return;
    if (!this.expandedView) return;
    this.toggleExpand();
  }
  toggleChildWounds() {
    if (this.expandedView === 'child_wounds') {
      this.toggleExpand();
    } else {
      this.expand();
      this.expandedView = 'child_wounds';
      this.initChildWounds();
    }
  }
  handleGraphClick() {
    if (this.expandedView === 'graph') {
      this.toggleExpand();
    } else {
      this.expand();
      this.expandedView = 'graph';
    }
  }

  toggleShowAllImages() {
    if (this.expandedView === 'wound_images') {
      this.toggleExpand();
    } else {
      this.expand();
      this.expandedView = 'wound_images';
    }
    // this.showAllImages = !this.showAllImages
  }
  calculateTimeDifference(wound) {
    return calculateTimeDifference(wound, this.actualParentWound);
  }
  calculateArea(wound) {
    return calculateAreaforBanner(wound);
  }
  calculateVolume(wound) {
    return calculateVolumeforBanner(wound);
  }




  showProcedureNoteDialog() {
    let procedureDialogRef = this.dialog.open(ProcedureNoteDialogComponent, {
      height: '95vh',
      data: {
        wound_id: this.wound._id,
        company_id: this.currentUser.company_id,
        patient_id: this.note.patient._id,
        facility_id: this.note.facility._id,
        parent_wound: this.actualParentWound._id,
        created_by: this.note.provider._id,
        census_id: this.note.rounding_sheet_id,
        location: this.actualParentWound.body_part,
        date_obj: this.wound.last_assessment_date_obj
      }
    });

    procedureDialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        this.wound.totalProcedureNotes = data.totalProcedureNotes;
        this.procedureNoteDetailsChange.emit(data);
      }
    });
  }
  handleProcedureNoteDetailsChange($event){
    this.procedureNoteDetailsChange.emit($event);
  }
  async showWoundCreateDialog(childWound, refreshWounds = false) {
    console.log("this.childWound", childWound, this.actualParentWound);
    const dialogRef = this.dialog.open(CreateWoundDialogComponent, {
      maxWidth: '98vw',
      maxHeight: '90vh',
      height: '85%',
      width: '98%',
      panelClass: 'full-screen-modal',
      data: {
        provider: this.provider,
        patient: this.patient,
        wound: this.actualParentWound,
        childWound,
        onICDsSave: this.onICDsSave,
        should_show_wound_location: this.should_show_wound_location
      }
    });
    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        this.actualParentWound = data.wound;
        if (this.actualParentWound?.latest_child_wound && this.actualParentWound?.latest_child_wound.findIndex((wound) => wound._id === data.childWound._id) > -1) {
          let index = this.actualParentWound.child_wounds.findIndex((wound) => wound._id === data.childWound._id);
          if (index > -1 && data.stage != "") {
            this.actualParentWound.child_wounds[index].stage = data.stage
          }
        }
        if (data.wound.state != data.state) {
          this.changeWoundState.emit(data.wound);
          this.woundCareWidgetService.setState(true);
        }
      }
      if (data.updateData) {
        if (childWound.is_clone) {
          data.updateData['_id'] = childWound.parent_wound;
          data.updateData['addendum_id'] = childWound._id;
        }
        const parentWoundData = {};
        // for (const key in data.updateData) {
        //   if (Object.prototype.hasOwnProperty.call(data.updateData, key)) {
        //     const value = data.updateData[key];
        //     if (!['stage','length','width','depth','area','volume','tunneling','tunneling_distance','tunneling_direction','under_mining','undermining_to','undermining_from','undermining_distance'].includes(key)) {
        //       parentWoundData[key] = value
        //     }
        //   }
        // }
        if (!refreshWounds) {
          this.updateDetailsfromNote(childWound._id, data.updateData)
          this.woundDetailsChange.emit(data.updateData)
        } else {
          setTimeout(() => {
            this.checkallWoundAssessmentPhrase.emit({ openPopMenu: true });
          }, 5000);
        }
      }
    });
  }
  showWoundImagePreviewDialog(wound, imageIndex = 0, showMainImage = false) {
    const dialogRef = this.dialog.open(WoundImagePreviewDialogComponent, {
      width: 'auto',
      data: { wound: wound, imageIndex: imageIndex, mainImage: showMainImage ? this.mainImage : null }
    });
  }
  deleteWoundImage(wound, imageIndex) {
    let imageList = this.otherImages[imageIndex].split("?")[0].split('/');
    let imageName = imageList[imageList.length - 1];
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: 'Are you sure you want to delete image?'
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result === "true") {
        this.woundCareWidgetService.deleteWoundOtherImages(wound._id, [imageName]).toPromise().then((res: any) => {
          if (res.status == 200) {
            this._toaster.success("Image Deleted Successfully", "Success");
            this.wound.wound_images.splice(this.wound.wound_images.indexOf(this.otherImages[imageIndex]), 1);
            this.otherImages.splice(imageIndex, 1);
          }
        });
      }
    });
  }

  async handleChangeWoundState(value) {
    const { status, data } = await lastValueFrom(this.woundCareWidgetService.updateWoundData(this.actualParentWound._id, { state: value }))
    if (status == 200) {
      console.log(data);
    }
  }

  showEdit() {
    return !(this.actualParentWound.body_side &&
      this.actualParentWound.body_part &&
      this.actualParentWound.wound_type &&
      this.actualParentWound.stage)
  }

  hasChildWounds() {
    return this.wound?.child_wounds?.length > 0
    // return this.wound.latest_child_wound.length
    // return this.wound !== this.actualParentWound;
  }

  showWoundImageUploadDialog() {
    const dialogRef = this.dialog.open(WoundImageUploadDialogComponent, {
      width: 'auto',
      data: { wound: this.wound }
    });
    dialogRef.afterClosed().subscribe(async (uploadedImages: string[]) => {
      if (Array.isArray(uploadedImages)) {
        this.wound.wound_images = this.wound.wound_images.concat(uploadedImages);
        this.otherImages = this.otherImages.concat(uploadedImages);
      }
    });
  }
  showWoundImageSnapshotDialog() {
    const dialogRef = this.dialog.open(WoundImageSnapshotDialogComponent, {
      width: 'auto',
      data: { wound: this.wound }
    });
    dialogRef.afterClosed().subscribe(async (uploadedImages: string[]) => {
      if (Array.isArray(uploadedImages)) {
        this.otherImages = this.otherImages.concat(uploadedImages);
        this.wound.wound_images = this.wound.wound_images.concat(uploadedImages);
      }
    });
  }

  showWoundNoteDialog() {
    const dialogRef = this.dialog.open(WoundNoteDialogComponent, {
      width: 'auto',
      data: { wound: this.wound }
    });
    dialogRef.afterClosed().subscribe(async (data) => {
      if (typeof data === 'string') {
        this.wound.extra_note = data;
      }
    });
  }
  isArchivedWound() {
    return this.actualParentWound.state === 'archived'
  }

  isClickAllowed($element) {
    const { classList, tagName } = $element;
    console.log({ classList, tagName, $element })
    const tagNames = ['button', 'mat-icon'];
    const classNames = ['mat-button-wrapper'];
    if (tagNames.includes(tagName.toLowerCase())) return false;
    if (classNames.some(className => classList.contains(className))) return false;
    return true;

  }
  showSetStageDialog(wound) {
    const dialogRef = this.dialog.open(SetWoundStageDialogComponent, {
      width: 'auto',
      data: { wound: wound, patient: this.patient, provider: this.provider }
    });
    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        // this.wound = Object.assign(this.wound, data);
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            const value = data[key];
            this.wound[key] = value;
          }
        }
      }
    });
  }
  isWoundInDateRange(wound) {
    const weeks = parseInt(this.last_n_weeks);
    if (isNaN(weeks)) return true;
    const dateNWeek = moment().subtract(weeks, 'weeks');
    return moment(wound.createdAt).isAfter(dateNWeek);
  }
  WoundStatechange(data: any) {
    this.changeWoundState.emit(this.actualParentWound);
  }

  showWoundReport(wound, date, isDownload) {
    let parentWound = JSON.parse(JSON.stringify(wound));
    if (this.wound._id.toString() !== wound._id.toString()) {
      parentWound.length = this.wound.length;
      parentWound.width = this.wound.width;
      parentWound.depth = this.wound.depth;
      parentWound.area = this.wound.area;
      parentWound.volume = this.wound.volume;
      parentWound.stage = this.wound.stage;
      parentWound.latest_child_wound_id = this.wound._id;
      parentWound.updated_At = this.wound.updated_At;
    }
    // console.log(this.actualParentWound, this.wound);
    parentWound.upated_wound_date = date
    this.woundAnalyticReport.emit(parentWound);
  }
  async showNote(patient) {
    if (patient.recentSignedNotes) {
      let noteID = patient.recentSignedNotes._id;
      let htmlText = patient.recentSignedNotes.transcribtion_text;
      const dialogRef = this.dialog.open(UploadNoteConfirmationDialog, {
        width: '50vw',
        data: {
          type: 'note',
          noteId: noteID,
          rawText: htmlText,
          hideDetails: true
        }
      });
      dialogRef.beforeClosed().subscribe(async (confirm: any) => {
      });
    }
  }
  async showChildWoundReport($event, childWound, parentWound = false) {
    if ($event.downloadReport) {
      this.generateSingleReportandUploadtoPCC(childWound);
    } else {
      if (parentWound === false) {
        this.woundAnalyticReport.emit(childWound)
      } else {
        const response: any = await this.woundService.getWoundfromParentSchema(this.actualParentWound._id);
        if (response.status === 200 && response.data !== null) {
          this.woundAnalyticReport.emit(response.data)
        } else {
          this.woundAnalyticReport.emit(childWound)
        }
      }
    }
  }

  async downloadParentWoundPDF(wound) {
    let latest_child_wound_id = null;
    if (this.wound._id.toString() !== wound._id.toString()) {
      latest_child_wound_id = this.wound._id;
    }
    this.woundService.exportWoundAsPdf(this.actualParentWound._id, latest_child_wound_id, this.patient).
      subscribe((data: any) => {
        // this.wound.child_wounds = child_wounds;
        if (data instanceof HttpResponse) {
          console.log("in httpresponse")
          var url = window.URL.createObjectURL(data.body);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          let date = 'no_date';
          console.log('this.configuration', this.configuration)
          let reportName = 'Wound Assessment Report';
          if (this.configuration != undefined) {
            reportName = this.configuration.wound_assessment_report_name
          }

          if (this.wound.created_At) {
            date = moment(this.wound.created_At).format('YYYY-MM-DD');
          } else if (this.wound.createdAt) {
            date = moment(this.wound.createdAt).format('YYYY-MM-DD');
          }
          a.download = `${reportName}_${date}`;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
        }
        else {
          console.log("in else")
          if (data.type === 0) {
          } else if (data.type === 3) {
            const { loaded, total } = data;
            const progress = Math.round(loaded / total * 100);
            if (progress > 0) {
            }
          }
        }
        if (data.status == 200) {
          console.log("in status")
          return true;
        }

      }
      )
  }
  async generateSingleReportandUploadtoPCC(wound) {
    if (this.hasLoggedInToPCC) {
      let date = 'no_date';
      if (this.wound.created_At) {
        date = moment(this.wound.created_At).format('YYYY-MM-DD');
      } else if (this.wound.createdAt) {
        date = moment(this.wound.createdAt).format('YYYY-MM-DD');
      }
      let reportName = 'Wound Assement Report';
      if (this.configuration != undefined) {
        reportName = this.configuration.wound_assessment_report_name;
      }
      let filename = reportName + "_" + date;
      let response: any = await this.woundCareWidgetService.generateSingleReportandUploadtoPCC(wound._id, this.patient, filename, this.note._id).toPromise();
      if (response.status === 200) {
        this._toaster.success(response.message);
      } else {
        this._toaster.error(response.message);
      }
    } else {
      this._toaster.error("Please sign in to PCC to upload the report.")
    }
  }
  hasLoggedInToPCC() {
    return !!this._pccService?.pccUserinfo;
  }

  async createNewAssessment() {
    const infoToast = this._toaster.info('Adding New Assessment...', 'Please wait', { disableTimeOut: true });
    let response: any = await this.woundCareWidgetService.addNewWoundAssessment(this.latestWoundCopy._id, this.note.rounding_sheet_id).toPromise();
    this._toaster.clear(infoToast.toastId)
    if (response.status === 200) {
      this._toaster.success(response.message, "Success");
      this.islatestWoundCopied = true;
      this.refreshAllWounds.emit(true);
    } else {
      this._toaster.error(response.message, "Error");
    }
  }
  handleRefreshAllWounds($event) {
    console.log("Resfrsssss");
    // this.refreshAllWounds.emit(true);
  }
  showWoundDialog() {
    console.log("Openeing Dialog");
    this.showWoundCreateDialog(this.latestWoundCopy);
  }
  calculateAreaSubscore(actualParentWound) {
    return calculateAreaSubscore(actualParentWound);
  }
  updateAddendumWoundFromNote(id, data) {
    this.woundCareWidgetService.updateWoundData(id, data);
  }
  calculateExudateAmount(actualParentWound) {
    return calculateExudateAmount(actualParentWound);
  }
  calculateTissueType(actualParentWound) {
    return calculateTissueType(actualParentWound);
  }
  viewScores() {
    swal({
      title: "Sub-Scores",
      text: `Area-SubScore : ${this.calculateAreaSubscore(this.actualParentWound)} 
      Exudate-SubScore: ${this.calculateExudateAmount(this.actualParentWound)}
      Tissue-SubScore: ${this.calculateTissueType(this.actualParentWound)}`,
      dangerMode: true,
      buttons: [, 'Close'],
    })
      .then(() => {
      });
  }
  handleWoundDetailsChange($event) {
    this.woundDetailsChange.emit($event);
    if (this.actualParentWound._id === $event._id) {
      for (const key in $event) {
        if (Object.prototype.hasOwnProperty.call($event, key)) {
          if (!['woundNo', '_id', 'stage', 'length', 'width', 'depth', 'area', 'volume', 'tunneling', 'tunneling_distance', 'tunneling_direction', 'under_mining', 'undermining_to', 'undermining_from', 'undermining_distance'].includes(key)) {
            this.actualParentWound[key === 'woundlocation' ? 'body_part' : key === 'etiology' ? 'etiolgy' : key] = $event[key];
          }
        }
      }
    }
  }
  showDownloadButton(woundobj) {
    if (woundobj.latest_child_wound && woundobj.latest_child_wound?.length > 0) {
      return true;
    }
    if (woundobj.latest_child_wound && woundobj.latest_child_wound?.length == 0) {
      return false;
    }
    if (!woundobj.latest_child_wound) {
      return true;
    }
  }

  async downloadImage(woundobj) {

    console.log("latest_child_wound : ", woundobj, woundobj.latest_child_wound, woundobj.latest_child_wound?.length)

    let allWounds = [], wound_no = woundobj.woundNo;
    this._toaster.info('Downloading Images...', 'Please wait');
    const data = await this.getChildWoundsFromApi();
    if (this.wound.child_wounds) {
      for (const child_wound of this.wound.child_wounds) {
        let filename_child = child_wound.wound_images[0]?.split('/');
        console.log('filename_child', filename_child);
        if (filename_child)
          filename_child = filename_child[filename_child?.length - 1]?.split('?')[0];
        console.log("filename_child : ", filename_child);

        if (!filename_child) continue;

        const wound = {
          patient_id: this.patient._id,
          filename: filename_child,
          woundNo: wound_no,
          wound_id: child_wound._id,
          patient_name: `${this.patient.last_name}, ${this.patient.first_name}`,
          dob: this.patient.date_of_birth,
          dos: child_wound.date_of_service,
          created_at: child_wound.created_At,
          wound_location: child_wound.parentWound ? child_wound.parentWound.body_part : child_wound.body_part,
          facility_name: this.note ? this.note.facility.title : this.actualParentWound.facility_id_ref.title
        };
        allWounds.push(wound);
      }

    }

    console.log('image in downloadImage', woundobj);
    let filename = woundobj.wound_images[0]?.split('/') ? woundobj.wound_images[0]?.split('/') : this.wound.wound_images[0]?.split('/');
    if (filename)
      filename = filename[filename?.length - 1]?.split('?')[0];
    if (!filename) {
      this._toaster.warning('No Wound Image Found');
      return;
    }

    const wound_parent = {
      patient_id: this.patient._id,
      filename: filename,
      woundNo: wound_no,
      wound_id: woundobj._id,
      patient_name: `${this.patient.last_name}, ${this.patient.first_name}`,
      dob: this.patient.date_of_birth,
      dos: woundobj.date_of_service,
      created_at: woundobj.created_At,
      wound_location: woundobj.parentWound ? woundobj.parentWound.body_part : woundobj.body_part,
      facility_name: this.note ? this.note.facility.title : woundobj.facility_id_ref.title
    }
    allWounds.push(wound_parent);

    this.woundCareWidgetService.downloadWoundImagesZip(allWounds).subscribe((response: any) => {
      if (response instanceof HttpResponse) {
        var url = window.URL.createObjectURL(response.body);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `Grouped Wound Images.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      } else {
      }

    });

  }
  downloadSingleImage() {
    let filename = this.wound.wound_images[0]?.split('/');
    if (filename)
      filename = filename[filename?.length - 1]?.split('?')[0];
    if (!filename) {
      this._toaster.warning('No Wound Image Found');
      return;
    }
    const details = [{
      patient_id: this.actualParentWound.patient_id,
      // filename: this.actualParentWound?.wound_images_time[0]?.split('_')[0],
      filename: filename,
      woundNo: this.actualParentWound.woundNo,
      wound_id: this.actualParentWound._id,
      patient_name: `${this.patient.last_name}, ${this.patient.first_name}`,
      dob: this.patient.date_of_birth,
      dos: this.actualParentWound.date_of_service,
      created_at: this.wound.created_At,
      wound_location: this.actualParentWound.body_part,
      facility_name: this.note ? this.note.facility.title : this.actualParentWound.facility_id_ref.title
    }]

    this.woundCareWidgetService.downloadWoundImagesZip(details).subscribe((response: any) => {
      if (response instanceof HttpResponse) {
        var url = window.URL.createObjectURL(response.body);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `Single Wound Image.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      } else {
      }
    });
    console.log(details);
  }
  onChildWoundTabChange(event) {
    this.tabIndex = event.index;
  }
  moveWoundToCensus(actualParentWound) {
    actualParentWound['provider_obj'] = this.note.provider;
    const dialogRef = this.dialog.open(MoveWoundToCensusComponent, {
      width: '500px',
      data: actualParentWound
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  canUploadReport() {
    if (this.note?.facility?.source === 'PointClickCare') {
      return this._pccService.isLoggedInFacility(this.note.facility) || this._authService.canUploadReportToPCCUsingTwoLegged();
    } else if (this.note?.facility?.source == 'MatrixCare') {
      return true;
    } else {
      return false;
    }
  }
  updateDetailsfromNote(wound_id, data) {
    this.wound.child_wounds.forEach((childWound) => {
      if (childWound._id === wound_id) {
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key) && key !== "_id") {
            if (key === 'extended_wound_model' && !Array.isArray(data[key])) {
              childWound[key].forEach((ewObj) => {
                if (ewObj.header_id.toString() === data[key].header_id.toString()) {
                  ewObj.value = data[key].value;
                  return;
                }
              });
            } else {
              const value = data[key];
              childWound[key] = value;
            }
          }
        }
      }
    });
    if (this.wound._id === wound_id) {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key) && key !== "_id") {
          if (key === 'extended_wound_model' && !Array.isArray(data[key])) {
            this.wound[key].forEach((ewObj) => {
              if (ewObj.header_id.toString() === data[key].header_id.toString()) {
                ewObj.value = data[key].value;
                return;
              }
            });
          }else{
            const value = data[key];
            this.wound[key] = value;
          }
        }
      }
    }
    // this.wound.child_wounds
  }

  async addFollowUpAssessment(wound) {
    const mappedWound = wound.latest_child_wound.length > 0 ? this.actualParentWound.child_wounds[0] : wound;
    if (!mappedWound.latest_child_wound) {
      mappedWound.latest_child_wound = [];
    }
    const newRevisionData = JSON.parse(JSON.stringify(this.mapDataforwoundPhrases([mappedWound])[0]));
    newRevisionData.parent_wound = this.actualParentWound._id;
    newRevisionData.created_by = this.note.provider._id;
    if (this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT)
      newRevisionData.created_by = this.currentUser._id;
    newRevisionData.census_id = this.note.rounding_sheet_id;
    newRevisionData.patient_id = this.note.patient._id;
    newRevisionData.facility_id = this.note.facility._id;
    newRevisionData['is_wound_locked'] = false;
    // newRevisionData.length = "0.0"; newRevisionData.width = "0.0"; newRevisionData.depth = "0.0";
    delete newRevisionData._id;
    delete newRevisionData.woundNo;
    const infoToast = this._toaster.info('Adding New Revision...', 'Please wait', { disableTimeOut: true });
    let response: any = await lastValueFrom(this.woundService.addWound(newRevisionData));
    if (response.status == 200) {
      let latestWound = JSON.parse((JSON.stringify(response.data)));
      latestWound._most_recent_wound = true;
      this.actualParentWound.is_wound_locked = false;
      this._toaster.clear(infoToast.toastId);
      await this.showWoundCreateDialog(latestWound, true);
      this._toaster.success("Wound Revision Added Successfully", "Success");
    }
  }

  async getCompany() {
    if(!this.companyPermissions){
      const response: any = await lastValueFrom(this.companyService.getCompany({ _id: this.currentUser.company_id }, { should_show_wound_assessment: 1, should_show_procedure_note: 1, should_show_wound_location: 1 }));
      if (response.status == 200) {
        if (response.data.should_show_wound_assessment) {
          this.should_show_wound_assessment = true;
        }
        if (response.data.should_show_procedure_note) {
          this.should_show_procedure_note = true;
        }
        if(response.data.should_show_wound_location){
          this.should_show_wound_location = true
        }
      }
    }else{
      if (this.companyPermissions.should_show_wound_assessment) {
        this.should_show_wound_assessment = true;
      }
      if (this.companyPermissions.should_show_procedure_note) {
        this.should_show_procedure_note = true;
      }
      if(this.companyPermissions.should_show_wound_location){
        this.should_show_wound_location = true
      }
    }
  }

  refreshWoundsandCheckWoundsinNoteEditor(openPopMenu = false) {
    if (!openPopMenu) {
      this.initWounds(true, openPopMenu);
    } else {
      this.checkallWoundAssessmentPhrase.emit({ length: this.wounds.length, callCountApi: true, openPopMenu: openPopMenu });
    }
  }

  async initWounds(emitwoundPhraseOutput = false, openCreateWoundMenu = false) {
    this.loader.startLoader(this.woundsLoaderId);
    if (!this.woundState)
      this.woundState = 'active';
    const { status, data } = await lastValueFrom(this.woundCareWidgetService.getWounds(this.patient._id, this.woundState, this.created_userType, this.facility_id, this.created_by, this.creationDate));
    console.log("data + status", data, status)
    if (status == 200) {
      this.sendAllWoundIds(data);
      this.wounds = data
        .map(this._sortParentWithChildWounds)
      if (emitwoundPhraseOutput) {
        this.checkallWoundAssessmentPhrase.emit({ wounds: this.mapDataforwoundPhrases(data), callCountApi: true, openPopMenu: openCreateWoundMenu, checkAllPhraseKey: true });
      } else {
        this.checkallWoundAssessmentPhrase.emit({ wounds: this.mapDataforwoundPhrases(data), callCountApi: false, openPopMenu: openCreateWoundMenu, checkAllPhraseKey: false });
      }
      // .map(this._identifyMostRecentWound)
    }
    this.loader.stopLoader(this.woundsLoaderId);
  }

  sendAllWoundIds(data) {
    const IDS = data.map((e) => { return e._id });
    this.allWoundIds.emit(IDS);
  }

  private _sortParentWithChildWounds = (parentWithChildWounds) => {
    const { latest_child_wound, ...parentWound } = parentWithChildWounds;
    // flatten
    const [newParentWound, ...latestChildWound] = this._sortWounds([parentWound, ...parentWithChildWounds.latest_child_wound], -1);
    newParentWound.latest_child_wound = latestChildWound;
    // maintain parent wound state
    newParentWound.state = parentWound.state;
    return newParentWound;
  }

  private _sortWounds = (wounds, order = -1) => {
    return wounds.slice().sort((a, b) => {
      // normalize
      let aCreatedAt, bCreatedAt;
      if (a.createdAt.match(/^\d+\/\d+\/\d+$/m)) {
        // prase MM/DD/YYYY using moment
        aCreatedAt = moment(a.createdAt, 'MM/DD/YYYY').toDate()
      } else {
        aCreatedAt = new Date(a.createdAt)
      }
      if (b.createdAt.match(/^\d+\/\d+\/\d+$/m)) {
        bCreatedAt = moment(b.createdAt, 'MM/DD/YYYY').toDate()
      } else {
        bCreatedAt = new Date(b.createdAt)
      }

      if (order < 0) {
        return new Date(bCreatedAt).getTime() - new Date(aCreatedAt).getTime()
      } else {
        return new Date(aCreatedAt).getTime() - new Date(bCreatedAt).getTime()
      }
    });
  }

  mapDataforwoundPhrases(data) {
    const specificKeys = ['etiolgy', 'body_part', 'stage', 'direction', 'exposed_tissues', 'periwound', 'eschar', 'granulation', 'slough',
      'epithelial', 'infection_signs', 'exudate_type', 'exudate', 'exposed_structure', 'odor', 'pain', 'primary_treatment', 'secondary_dressing', 'change_frequency', 'pressure_reduction/off_loading', 'dressings', 'cleanse_wound_with', 'wound_status', 'length', 'width', 'depth', 'area',
      'volume', 'tunneling', 'tunneling_distance', 'tunneling_direction', 'under_mining', 'undermining_to', 'undermining_from', 'undermining_distance', 'extended_wound_model',
      'latest_child_wound', '_id', 'woundNo', 'extended_wound_model', 'populated_extended_model'
    ];
    let mappedData = [];
    data.forEach((wound) => {
      const specificKeysObject = specificKeys.reduce((acc, key) => {
        if (wound.hasOwnProperty(key)) {
          if (['stage', 'tunneling', 'tunneling_distance', 'tunneling_direction', 'under_mining', 'undermining_to', 'undermining_from', 'undermining_distance', 'length', 'width', 'depth', 'area'].includes(key)) {
            acc[key] = wound.latest_child_wound && wound.latest_child_wound.length > 0 ? wound.latest_child_wound[0][key] : wound[key]
          } else {
            acc[key] = wound[key];
          }
        }
        return acc;
      }, {});
      mappedData.push(specificKeysObject);
    });
    return mappedData;
  }

  updatePatientSkinSubRefferal() {
    if(!this.actualParentWound.skin_relation) {
      swal({
        title: "Skin Sub Referral",
        text: 'A Skin Sub Notification has been sent to your admin.',
        // icon: "success",
        // dangerMode: false,
        buttons: [
          "No", "Yes"
        ],
      })
      .then(async (res) => {
            
        if (res) {
          let data = {
            wound_id: this.actualParentWound._id,
            is_parent_wound: true,
          }
          let response: any = lastValueFrom(this.woundCareWidgetService.updateWoundData(this.actualParentWound._id,{skin_relation: true}, this.hasChildWounds()));
          this.actualParentWound.skin_relation = true;
        }
              
      })
  }
        
}
  
}
