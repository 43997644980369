<div [ngClass]="{'container': (isCompanySide || user_dashboard) && !(nursingSide)}">

<h3 class="dark-blue-heading flex justify-between items-center">
        <ng-container>
            <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
                Back
            </button>
        </ng-container>
            <div class="label-margin">
            <label style="white-space: nowrap;"> Post Rounds Report V2 </label>
        </div>

        <div class="flex items-center justify-center w-full" style="color: black !important;">
            <input matInput type="text" ngxDaterangepickerMd [(ngModel)]="filter.updationDate" class="mat-like-input"
                [ranges]="preDateRanges" [alwaysShowCalendars]="true" [maxDate]="currentDate" [linkedCalendars]="true"
                [showClearButton]="false" (datesUpdated)="onChangeDate($event)" class="date-range-picker" />
        </div>

        <div class="flex gap-2">
            <mat-icon matTooltip="Download Report" style="cursor: pointer;"
                [matMenuTriggerFor]="sendMenu">download</mat-icon>

            <mat-menu #sendMenu="matMenu" xPosition="before">
                <button mat-menu-item (click)="initPostRoundsReport(true);">Export
                    As XLSX</button>

                <button mat-menu-item (click)="exportPostRoundsReport('xlsx');">Send via
                    email</button>
                <button mat-menu-item (click)="exportPostRoundsReport('pdf');">Export
                    As PDF</button>
            </mat-menu>
        </div>

    </h3>

    <div class="search-block theme-form items-start justify-center"
        style="background-color: white; border-radius: 4px; padding-top: 8px; padding-bottom: 5px; padding-left: 8px;padding-right: 8px;">

        <div class="flex flex-grow items-center justify-start gap-2">
            <mat-form-field appearance="fill" class="date-width">
                <mat-label>Select Date Type</mat-label>
                <mat-select (selectionChange)="filter.dateType = $event.value;" [value]="filter.dateType">
                    <mat-option value="createdAt">Created On</mat-option>
                    <mat-option value="updatedAt">Updated On</mat-option>
                    <mat-option value="last_assessment_date_obj">Date Of Service</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="sm:w-1/2 md:w-full">
                <mat-label>Facility</mat-label>
                <div class="flex items-start justify-center">
                    <ng-container *ngIf="selectedFacility">
                        <img src="../../../assets/icons/pcc-icon.svg"
                            *ngIf="selectedFacility.source === 'PointClickCare'" width="20px" alt="">
                        <img src="../../../assets/icons/matrix.png" *ngIf="selectedFacility.source === 'MatrixCare'"
                            alt="" width="20px" style="margin-right: 5px;">

                        <img src="../../../../../../assets/img/DN.png"
                            *ngIf="selectedFacility.source !== 'PointClickCare' && selectedFacility.source !== 'MatrixCare'"
                            width="20px" style="margin-right: 7px;" alt="">
                    </ng-container>
                    <mat-select name="facility" #facility="ngModel" [(ngModel)]="filter.facilityId">
                        <input  placeholder="Search" [(ngModel)]="searchText"
                            [ngModelOptions]="{standalone: true}"
                            class="report-search"
                            (keydown)="$event.stopPropagation()" />
                        <mat-option *ngFor="let facility of facilities | searchFacility: searchText"
                            (onSelectionChange)="onSelectFacility($event, facility);" value="{{facility._id}}">
                            <app-facility-title [facility]="facility"></app-facility-title>
                        </mat-option>
                    </mat-select>
                </div>
            </mat-form-field>

            <mat-form-field appearance="fill" class="state-width">
                <mat-label>Select State</mat-label>
                <mat-select [value]="filter.state" (selectionChange)="onStateChange($event.value)">
                    <mat-option value="all">All</mat-option>
                    <mat-option value="active">Active</mat-option>
                    <mat-option value="remission">Remission</mat-option>
                    <mat-option value="amputated">Amputated</mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-raised-button class="dn-button-primary" (click)="initPostRoundsReport();">View
            </button>

            <button mat-raised-button class="dn-button-secondary" (click)="resetFilter();">Reset
            </button>

        </div>

    </div>

    <mat-card class="vertical-height">
        <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
        <div id="postRoundsReport" class="table-height" *ngIf="shouldShowTable">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                <ng-container matColumnDef="room_number">
                    <th mat-header-cell *matHeaderCellDef> Room # </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.patient_id.last_room_num}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Last Name, First Name </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.patient_id.full_name}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="body_part">
                    <th mat-header-cell *matHeaderCellDef> Wound Location </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.body_part}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="etiolgy">
                    <th mat-header-cell *matHeaderCellDef> Etiology </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.etiolgy}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="stage">
                    <th mat-header-cell *matHeaderCellDef> Stage / Severity </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.stage}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="last_assessment_date">
                    <th mat-header-cell *matHeaderCellDef> Latest Evaluation Date </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.last_assessment_date}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="last_admit_date_obj">
                    <th mat-header-cell *matHeaderCellDef> Facility Admission Date </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.patient_id.last_admit_date_obj}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="length">
                    <th mat-header-cell *matHeaderCellDef> Length </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.length}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="width">
                    <th mat-header-cell *matHeaderCellDef> Width </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.width}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="depth">
                    <th mat-header-cell *matHeaderCellDef> Depth </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.depth}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="wound_status">
                    <th mat-header-cell *matHeaderCellDef> Wound Status </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.wound_status}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="wound_recognition_date">
                    <th mat-header-cell *matHeaderCellDef> Date Wound Acquired </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.wound_recognition_date}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="wound_recognition_date_type">
                    <th mat-header-cell *matHeaderCellDef> Acquired in House? </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.wound_recognition_date_type}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="primary_treatment">
                    <th mat-header-cell *matHeaderCellDef> Primary Treatment </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.primary_treatment}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="change_frequency">
                    <th mat-header-cell *matHeaderCellDef> Dressing Change Frequency </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.change_frequency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="cleanse_wound_with">
                    <th mat-header-cell *matHeaderCellDef> Cleanse Wound With </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.cleanse_wound_with}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="secondary_dressing">
                    <th mat-header-cell *matHeaderCellDef> Secondary Dressings </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.secondary_dressing}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div class="flex justify-between items-center bottom-row">
                <b>POA: {{poaCount}}</b>
                <b>Facility Acquired: {{facilityAcquiredCount}}</b>
                <b>Open: {{openWoundsCount}}</b>
                <b>In Remission: {{inRemissionWoundsCount}}</b>
                <div>
                    <button mat-raised-button class="dn-button-primary" (click)="handlePageNavigation(true)"
                        [disabled]="!pagination.hasPrevPage"> Prev</button>
                    <span>
                        {{pagination.skip + slicedReport.length === 0 ? 0 : pagination.skip + 1 }} -
                        {{pagination.skip
                        + slicedReport.length}} out of {{pagination.totalRecords}}
                    </span>
                    <button mat-raised-button class="dn-button-primary" (click)="handlePageNavigation(false)"
                        [disabled]="!pagination.hasNextPage">Next</button>
                </div>
            </div>
        </div>
    </mat-card>
</div>