import {Component, Input, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {AuthService} from '../../services/auth.service';
import * as global from '../../../app/includes/global'
@Component({
    selector: 'app-appointment',
    templateUrl: './appointment.template.html',
    providers: []
})
export class AppointmentComponent implements OnInit {
  @Input() patient;
    currentUser: any;
    global=global
    constructor(public authService: AuthService,
        private route: ActivatedRoute,
        private toaster: ToastrService,
        private router: Router,) {
    this.currentUser = this.authService.currentUser;

    }

    ngOnInit(): void {
    }
}
