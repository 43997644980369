import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { CreateWoundDialogComponent } from './create-wound-dialog/create-wound-dialog.component';
import { WoundCareWidgetWoundListComponent } from './wound-care-widget-wound-list/wound-care-widget-wound-list.component';
import { WoundCareWidgetService } from './wound-care-widget.service';
import { calculateTimeDifference, calculateArea, calculateVolume, calculateAreaforBanner, calculateVolumeforBanner, calculateAreaSubscore, calculateExudateAmount, calculateTissueType  } from './wound-utils'
import * as global from "../../global";
import moment from 'moment';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { WoundService } from 'src/app/services/wound.service';
import { CompanyService } from 'src/app/services/Modules/company.service';

function getRandom(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
export interface WoundCareWidgetData {
  patient :any,
  provider :any,
  note : any
}
@Component({
  selector: 'app-wound-care-widget',
  templateUrl: './wound-care-widget.component.html',
  styleUrls: ['./wound-care-widget.component.css'],
})
export class WoundCareWidgetComponent implements OnInit {

  @Input() provider;
  @Input() note;
  @Input() patient;
  @Input() woundStateCounts: any;
  @Input() readOnlyMode: any;
  @Input() configuration: any;
  @Input() companyPermissions: any;
  @Input() created_userType: any;
  @Input() facility_id;
  @Input() should_show_view_icon;
  activeCount = 0;
  remissionCount = 0;
  amputatedCount = 0;
  archiveCount = 0;
  stateSubscription: any;
  @Output() onICDsSave: EventEmitter<any> = new EventEmitter();
  @Output() woundDetailsChange: EventEmitter<any> = new EventEmitter();
  @Output() procedureNoteDetailsChange: EventEmitter<any> = new EventEmitter();
  @Output() checkallWoundAssessmentPhrase: EventEmitter<any> = new EventEmitter()
  @ViewChild(WoundCareWidgetWoundListComponent) woundCareWidgetWoundListComponent: WoundCareWidgetWoundListComponent;
  archiveWoundCountSubscription: any;
  last_n_weeks: string = 'all';
  currentUser: any;
  isAdminSide: boolean = false;
  isNoteEditor: boolean = false;
  should_show_wound_assessment: Boolean = false;
  should_show_wound_location: Boolean = false;
  global = global;

  constructor(
    private dialog: MatDialog,
    private woundCareWidgetService: WoundCareWidgetService,
    private _toaster: ToastrService,
    private _authService : AuthService,
    @Optional()@Inject(MAT_DIALOG_DATA) public dataFromCensus: WoundCareWidgetData,
    public dialogRef: MatDialogRef<WoundCareWidgetComponent>,
    private _router: Router,
    private _woundService : WoundService,
    private companyService: CompanyService
  ) { 
    if(Object.keys(this.dataFromCensus).length != 0 ) {
      this.patient = this.dataFromCensus.patient;
      this.provider = this.dataFromCensus.provider;
      this.note = this.dataFromCensus.note;
      
    }
    this.currentUser = this._authService.currentUser;
    if (window.location.pathname.includes('company')) {
      this.isAdminSide = true;
    }
    if (_router.url.includes('note-editor')) {
      this.isNoteEditor = true;
    }
  }

  async ngOnInit(): Promise<void> {
    if(this.readOnlyMode == null){
      this.readOnlyMode = false;
    }
    console.log("Wound Counts", this.woundStateCounts);
    this.getCompany();
    if(this.woundStateCounts){
      this.activeCount = this.woundStateCounts.activeCount;
      this.remissionCount = this.woundStateCounts.remissionCount;
      this.amputatedCount = this.woundStateCounts.amputatedCount;
      this.archiveCount = this.woundStateCounts.archiveCount;
    }else{
      await this.woundCareWidgetService.getAllWoundsParentStatesCount(this.patient._id, this.created_userType).toPromise().then((res: any) => {
        if (res.status == 200) {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i]._id == 'active') {
              this.activeCount = res.data[i].count;
            }
            if (res.data[i]._id == 'remission') {
              this.remissionCount = res.data[i].count;
            }
            if (res.data[i]._id == 'amputated') {
              this.amputatedCount = res.data[i].count;
            }
            if (res.data[i]._id == 'archived') {
              this.archiveCount = res.data[i].count;
            }
          }
        }
      });
    }
    if (this.patient) {
      this.stateSubscription = this.woundCareWidgetService.castState.subscribe((res) => {
        if (res) {
          this.woundCareWidgetService.getAllWoundsParentStatesCount(this.patient._id, this.created_userType).toPromise().then((res: any) => {
            if (res.status == 200) {
              this.activeCount = 0;
              this.amputatedCount = 0;
              this.archiveCount = 0;
              this.remissionCount = 0;
              for (let i = 0; i < res.data.length; i++) {
                if (res.data[i]._id == 'active') {
                  this.activeCount = res.data[i].count;
                }
                if (res.data[i]._id == 'remission') {
                  this.remissionCount = res.data[i].count;
                }
                if (res.data[i]._id == 'amputated') {
                  this.amputatedCount = res.data[i].count;
                }
                if (res.data[i]._id == 'archived') {
                  this.archiveCount = res.data[i].count;
                }
              }
              this.woundCareWidgetService.setState(false);
            }
          });
        }
      });
    }
  }
  ngOnDestroy(): void {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
  }
  async showWoundCreateDialog() {
    const wounDataset = await lastValueFrom(this.woundCareWidgetService.getAllDataset(this.provider.company_id));
    console.log(wounDataset);
    const dialogRef = this.dialog.open(CreateWoundDialogComponent, {
      width: 'auto',
      data: wounDataset.data
    });
    dialogRef.afterClosed().subscribe(async (data) => {
    });
  }
  handleICDsSave(icds) {
    console.log('wound-care-widget > handleICDsSave')
    this.onICDsSave.emit(icds);
  }
  handleWoundDetailsChange(data) {
    this.woundDetailsChange.emit(data);
  }
  handleProcedureNoteDetailsChange(data) {
    this.procedureNoteDetailsChange.emit(data);
  }
  updateWoundfromNote(id, data, justUpdateObject = false){
    this.woundCareWidgetWoundListComponent.updateWoundfromNote(id,data, justUpdateObject);
  }
  checkChildWoundExists(id){
    return this.woundCareWidgetWoundListComponent.checkChildWoundExists(id);
  }
  isWoundLocked(id){
    return this.woundCareWidgetWoundListComponent.isWoundLocked(id);
  }
  onTabChanged($event: any) {
    this.woundCareWidgetService.getAllWoundsParentStatesCount(this.patient.patient_id,this.created_userType).toPromise().then((res: any) => {
      if (res.status == 200) {
        this.woundCareWidgetService.setState(false);
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i]._id == 'active') {
            this.activeCount = res.data[i].count;
          }
          if (res.data[i]._id == 'remission') {
            this.remissionCount = res.data[i].count;
          }
          if (res.data[i]._id == 'amputated') {
            this.amputatedCount = res.data[i].count;
          }
          if (res.data[i]._id == 'archived') {
            this.archiveCount = res.data[i].count;
          }
        }
      }
    });
  }
  refreshWounds(){
    this.woundCareWidgetWoundListComponent.refreshWoundsandCheckWoundsinNoteEditor();
  }
  handlecheckallWoundAssessmentPhrase(event){
    this.checkallWoundAssessmentPhrase.emit(event);
  }

  openMenuofLatestWound(){
    this.woundCareWidgetWoundListComponent.openMenuofLatestWound();
  }

  async downloadImage(allWounds) {
    this.woundCareWidgetService.downloadWoundImagesZip(allWounds).subscribe((response: any) => {
    if (response instanceof HttpResponse) {
        var url = window.URL.createObjectURL(response.body);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `Grouped Wound Images.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
    }else{
    }

  });

  }
  fetchAllWoundImgsData(filterWounds = false){
    const allWounds = this.woundCareWidgetWoundListComponent.getAllWounds();
    console.log(allWounds);
    const allImageIds = [];
    let filteredWounds = [];
    for (let parentWound of allWounds) {
      const childWounds = [];
      for (let childwound of parentWound.child_wounds) {
        if(childwound.wound_images.length > 0){
          let filename= childwound.wound_images[0]?.split('/');
          filename=filename[filename.length-1]?.split('?')[0];

        const wound_child={
          patient_id: this.patient._id,
          filename: filename,
          woundNo: parentWound.woundNo,
          wound_id: childwound._id,
          patient_name: `${this.patient.last_name}, ${this.patient.first_name}`,
          dob: this.patient.date_of_birth,
          dos: childwound.date_of_service,
          created_at: childwound.created_At,
          wound_location: childwound.parentWound ? childwound.parentWound.body_part : childwound.body_part,
          facility_name: this.note ? this.note.facility.title : parentWound.facility_id_ref.title,
          facility_id_ref: this.note ? this.note.facility._id : parentWound.facility_id_ref._id,
          last_assessment_date: childwound.last_assessment_date_obj,
          company_id: parentWound.company_id,
        };
        childWounds.push(wound_child);
        }
      }
      if (parentWound.wound_images.length > 0) {
        let filename = parentWound.wound_images[0]?.split('/');
        filename = filename[filename.length - 1]?.split('?')[0];

        const wound_parent = {
          patient_id: this.patient._id,
          filename: filename,
          woundNo: parentWound.woundNo,
          wound_id: parentWound._id,
          patient_name: `${this.patient.last_name}, ${this.patient.first_name}`,
          dob: this.patient.date_of_birth,
          dos: parentWound.date_of_service,
          created_at: parentWound.created_At,
          wound_location: parentWound.parentWound ? parentWound.parentWound.body_part : parentWound.body_part,
          facility_name: this.note ? this.note.facility.title : parentWound.facility_id_ref.title,
          facility_id_ref: this.note ? this.note.facility._id : parentWound.facility_id_ref._id,
          company_id: parentWound.company_id,
          last_assessment_date: parentWound.last_assessment_date_obj
        }
        allImageIds.push(wound_parent, ...childWounds);
      } else allImageIds.push(...childWounds);
    }
    if(filterWounds){
      filteredWounds = allImageIds.filter((wound) => {
        if(this.note && this.note.date_obj && wound.last_assessment_date.year === this.note.date_obj.year && wound.last_assessment_date.month === this.note.date_obj.month && wound.last_assessment_date.day === this.note.date_obj.day)
        {
          return wound
        }
      });
    }else{
      filteredWounds = allImageIds;
    }
    return filteredWounds;
  }
  async addInitAssessment() {
    this._toaster.info("Adding Wound...", 'Please wait');
    let details: any = {
      created_by: this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT ? this.currentUser._id : this.note.provider._id, census_id: this.note.rounding_sheet_id, patient_id: this.note.patient._id, facility_id: this.note.facility._id,
      wound_location_image: '', on_set_date: '', area: '', volume: '', etiolgy: '', body_part: '', wound_type: '', stage: '', body_side: '', direction: '',
      length: 0.0, width: 0.0, depth: 0.0, area_change: 0.0, volume_change: 0.0, push_score: 0.0,
      exposed_tissues: '', undermining_to: '', undermining_from: '', undermining_distance: '', tunneling_distance: '', tunneling_direction: '', exudate: '',
      color: '', odor: '', pain: '', sto2: '', extra_note: '', category_name: '', size: '', exudate_type: '', exposed_structure: '', slough: '', stage_trend: '',
      granulation: '', eschar: '', epithelial: '', infection_signs: '', periwound: '', change_frequency: '', cleanse_wound_with: '', dressings: '', push_trend: '',
      'pressure_reduction/ off_loading': '', secondary_dressing: '', primary_treatment: '', wound_status: '', wound_recognition_date_type: '', archive_reason: '',
      remission_date: null, date_of_service: null, wound_recognition_date: null
    };
    this._woundService.addWound(details).subscribe(async (response: any) => {
      if (response.status == 200) {
        this._toaster.success('Wound Added Successfully!');
        response.data._most_recent_wound = true;
        await this.showWoundDialog(response.data);
        this.refreshWounds();
      }
    });
  }
  async showWoundDialog(childWound) {
    let actualParentWound;
    if (!childWound.parent_wound)
      actualParentWound = childWound
    console.log("this.childWound", childWound, actualParentWound);
    const dialogRef = this.dialog.open(CreateWoundDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '90vh',
      height: '90%',
      width: '98%',
      panelClass: 'full-screen-modal',
      data: {
        provider: this.note.provider,
        patient: this.note.patient,
        wound: actualParentWound,
        childWound,
        onICDsSave: this.onICDsSave,
        should_show_wound_location: this.should_show_wound_location
      }
    });
    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        actualParentWound = data.wound;
        if (actualParentWound?.latest_child_wound && actualParentWound?.latest_child_wound.findIndex((wound) => wound._id === data.childWound._id) > -1) {
          let index = actualParentWound.child_wounds.findIndex((wound) => wound._id === data.childWound._id);
          if (index > -1 && data.stage != "") {
            actualParentWound.child_wounds[index].stage = data.stage
          }
        }
        if (data.wound.state != data.state) {
          // this.changeWoundState.emit(data.wound);
          this.woundCareWidgetService.setState(true);
        }
      }
      if (data.updateData) {
        this.woundDetailsChange.emit(data.updateData)
      }
      this.refreshWounds();
    });

  }
  async getCompany() {
    const response: any = await lastValueFrom(this.companyService.getCompany({ _id: this.currentUser.company_id }, { should_show_wound_assessment: 1 ,should_show_wound_location: 1}));
    if (response.status == 200) {
      if (response.data.should_show_wound_assessment) {
        this.should_show_wound_assessment = true;
      }
      if (response.data.should_show_wound_location) {
        this.should_show_wound_location = true;
      }
    }
  }
  
  async groupWoundImgs(uploadToPCC = false) {
    const allImageIds = this.fetchAllWoundImgsData(uploadToPCC);
    if (allImageIds.length && !uploadToPCC) {
      this._toaster.info("Downloading Wound Group Images...", "Please Wait");
      await this.downloadImage(allImageIds);
    }else if(allImageIds.length && uploadToPCC){
      this._toaster.info("Uploading Wound Group Images to PCC...", "Please Wait");
      const respone: any = await this.woundCareWidgetService.downloadWoundImagesZip(allImageIds, true).toPromise();
      console.log(respone);
      if(respone.status == 200){
        this._toaster.success("Wound Report Uploaded Successfully...", "Success");
      }else{
        this._toaster.error(respone.message);
      }
    }else{
      this._toaster.info("No Wound Images Found");
    }
  }
}
