import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment'
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FacilityService } from 'src/app/services/facility.service';
import { Location } from '@angular/common';
import { WoundService } from 'src/app/services/wound.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpResponse } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';

@Component({
  selector: 'app-wound-periodic-treatment-report',
  templateUrl: './wound-periodic-treatment-report.component.html',
  styleUrls: ['./wound-periodic-treatment-report.component.css']
})
export class WoundPeriodicTreatmentReportComponent implements OnInit {

  currentDate = moment(new Date());
  filter: any = {
    creationDate: {
      startDate: moment().subtract(6, 'days').toDate(),
      endDate: moment().toDate()
    },
    facilityId: null
  };
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 1 Week': [moment().subtract(6, 'days'), moment()],
    'Last 2 Week': [moment().subtract(13, 'days'), moment()],
    'Last 3 Week': [moment().subtract(20, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  iscreationDateFilterSelected: boolean = true;
  filteredFacilities: Observable<string[]>;
  displayedColumns: string[] = [
    'name', 'admission date', 'weeks in tx', 'location', 'type', 'length', 'width', 'depth', 'area change', 'volume change', 'insurance'];
  facilityControl = new FormControl();
  facilities: Array<any> = [];
  companyId: any;
  woundPeriodicReport: any = [];
  dataSource: any = new MatTableDataSource([]);
  currentUser: any;
  companySide: boolean;
  nursingSide: boolean;
  user_dashboard: Boolean = false;
  constructor(
    private _toastr: ToastrService,
    private _facilityService: FacilityService,
    private _route: ActivatedRoute,
    private _location: Location,
    private _wound: WoundService,
    private _authService: AuthService

  ) {
    this.companyId = this._route.parent.snapshot.params.id;
    this.currentUser = this._authService.currentUser;
    
    if(this._route.parent.snapshot.params.id){
      this.companySide=true;
    }else{
      this.companyId = this.currentUser.company_id;
    }
    if(window.location.pathname.includes('dashboard')){
      this.user_dashboard = true;
    }
    if(window.location.pathname.includes('nursing')){
      this.nursingSide=true;
    }
  }

  async ngOnInit() {
    await this.initFacilities();
    this.selectDefaultFacility();
    this.initWoundPeriodicTreatmentReport();
    this.filteredFacilities = this.facilityControl.valueChanges.pipe(
      startWith(''),
      map((value:any) => this.filterFacilities(value)));
  }
  private filterFacilities(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) === 0);
    }
    else {
      return this.facilities;
    }
  }
  selectDefaultFacility() {
    if (this.facilities.length > 0) {
      this.filter.facilityId = this.facilities[0]._id;
    }
    this.facilityControl.setValue(this.facilities[0].title);
  }
  initWoundPeriodicTreatmentReport() {
    this._wound.getPeriodicWoundReport(this.filter).subscribe((response: any) => {
      if (response.status == 200) {
        this.woundPeriodicReport = response.data;
        this.dataSource.data = this.woundPeriodicReport;
      }
    }, err => {
      this._toastr.error(err, "ERROR");
    }
    );
  }

  async initFacilities() {
    const filter = {
      assoc_company_ids: this.companyId,
      is_active: true
    };
    const projection = {
      title: 1,
      source: 1,
      assoc_company_ids: 1
    };

    let companyAssocPopulation: facilityPopulationInterface = {
      collection: 'companies',
      field: ["assoc_company_ids"],
      filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: [true, { $in: ['$_id', '$$assoc_company_ids'] }] }] } },
      project: { name: 1 },
    };
    
    const facilitiesResponse: any = await this._facilityService.getFacilities(filter, projection, [companyAssocPopulation]).toPromise();
    if (facilitiesResponse.status == 200) {
      this.facilities = facilitiesResponse.data.array;
    }
  }
  onSelectFacility($event, facility) {
    if ($event.isUserInput) {
      this.filter.facilityId = facility._id;
    }
  }
  changeCreationDate(event) {
    if (event.startDate && event.endDate) {
      this.filter.creationDate.startDate = moment(event.startDate).toDate();
      this.filter.creationDate.endDate = moment(event.endDate).toDate();
    }
    // var noOfDays = endDate.diff(startDate, 'days');
    // if (noOfDays > 30) {
    //   this.iscreationDateFilterSelected = false;
    //   this.filter.creation_date.endDate = moment(this.filter.creation_date.startDate).add(29, "days").startOf('day').toDate();
    //   endDate = this.filter.creation_date.endDate;
    //   this._toastr.error('Can not select more than 30 days', 'ERROR');

    //   setTimeout(() => {
    //     this.iscreationDateFilterSelected = true;
    //   }, 300)
    // }
  }

  goBack() {
    this._location.back();
  }
  handleApplyFilter() {
    this.initWoundPeriodicTreatmentReport();
  }

  calculateArea(ele) {
    if (ele.earliestRevisionArea && ele.recentWound) {
      return 100 - ((ele.recentWound.recentRevisionArea / ele.earliestRevisionArea) / 100)
    }
    return ele.earliestRevisionArea;
  }
  calculateVolume(ele) {
    if (ele.earliestRevisionVolume && ele.recentWound) {
      return 100 - ((ele.recentWound.recentRevisionVolume / ele.earliestRevisionVolume) / 100)
    }
    return ele.earliestRevisionVolume;
  }
  dateDifference(lastAdmitDate) {
    if (lastAdmitDate) {
      const dateDifference = Math.abs(new Date().getTime() - new Date(lastAdmitDate).getTime());
      const daysDifference = dateDifference / (1000 * 60 * 60 * 24);
      const weeksInTx = Math.round(daysDifference / 7);
      return weeksInTx;
    }
  }
  resetFilter() {
    delete this.filter.creationDate;
    const defaultDate = {
      startDate: moment().subtract(6, 'days').toDate(),
      endDate: moment().toDate()
    };
    this.filter.creationDate = defaultDate;
    this.selectDefaultFacility();
    this.initWoundPeriodicTreatmentReport();
  }
  exportWoundPeriodicTreatmentReportAsXLSX() {
    this._wound.exportWoundPeriodicTreatmentReportAsXLSX(this.dataSource.data).subscribe((response: any) => {
      if (response instanceof HttpResponse) {
        var url = window.URL.createObjectURL(response.body);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `Reports`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }
    });
  }

}
