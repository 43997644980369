import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-view-cpt-codes',
  templateUrl: './view-cpt-codes.component.html',
  styleUrls: ['./view-cpt-codes.component.css']
})
export class ViewCptCodesComponent implements OnInit {
  relations: any;
  cptCodes:  any;
  @Input() company;
  @Output() exit = new EventEmitter<any>();
  @Output() updateCPTS = new EventEmitter<any>();
  company_id: any;
  currentUser: any;
    
    constructor(
    private authService : AuthService,

    ) { }

  ngOnInit() {
    this.currentUser = this.authService.currentUser;
    this.cptCodes = this.company.cpt_ids;
  }

  closeSideBar() {
    this.exit.emit(true);
  }

  removeCPT(cpt) {
    // console.log('cpt',cpt);
    
    this.cptCodes = this.cptCodes.filter(c => c._id != cpt._id);
    let obj : any = {};
    obj.cpt = cpt;
    obj.company = this.currentUser.product_owner ? this.company : this.company.company_id ;
    this.updateCPTS.emit(obj);
  }
}