import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../../../includes/global';
import { HttpClient } from '@angular/common/http';
import {API_URL} from "../../../../environments/api-url";

@Injectable({
  providedIn: 'root'
})
export class ChargesReportService {

  currentUser: any;
  constructor(
    private authService: AuthService,
    private httpclient: HttpClient,
  ) { 
    this.currentUser = this.authService.currentUser;
  }


  getChargesReport(filter = {}){
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type, 
      filter: filter
    };
    return this.httpclient.post(global.url + API_URL.CHARGE.getChargesReport, {details: details});
  }
}
