import { Component, OnInit, Input } from '@angular/core';

import { PhrasesService } from '../phrases.service';
import { CKEditorService } from '../../note-editor/ckeditor.service';

function upper( text ) {
  return text.toUpperCase();
}

@Component({
  selector: 'app-template-headings',
  templateUrl: './template-headings.component.html',
  styleUrls: [ './template-headings.component.scss' ],
})
export class TemplateHeadingsComponent implements OnInit {
  searchText: string = '';
  displayHeadings: any[] = [];
  @Input() forNoteType: any = null;
  @Input() useDragonStyle: any = false;
  private allHeadings: any[] = [];

  constructor(
    private phrasesService: PhrasesService,
    private ckeditorService: CKEditorService
  ) {}

  ngOnInit(): void {
    const templateHeadings = [ ...this.phrasesService.getTemplateHeadings() ];

    this.allHeadings = [ ...this.phrasesService.getFlatTemplateHeadings() ]
      .map( headingText => {
        let headingTextToFind = headingText
          .endsWith( ':' ) ?
            headingText.slice( 0, -1 ) :
            headingText;

        headingTextToFind = headingTextToFind.toUpperCase();

        const found = templateHeadings.find( headingObj => {
          const currHeading = headingObj.heading;
  
          if ( typeof currHeading === 'string' && upper( currHeading ) === headingTextToFind ) {
            return true;
          }
  
          if ( Array.isArray( currHeading ) && currHeading.map( upper ).includes( headingTextToFind )) {
            return true;
          }
  
          return false;
        }) as any;

        let
          relevantPhrase = null,
          isHardCodedPhrase = false;

        if ( found != null ) {
          relevantPhrase = found.phrases?.[0] ?? null;
          isHardCodedPhrase = found.isHardCodedPhrase === true;

          if ( relevantPhrase != null ) {
            relevantPhrase = `${ this.phrasesService.marker }${ relevantPhrase }`;
          }
        }

        return {
          headingText,
          relevantPhrase,
          isHardCodedPhrase
        };
      });

    // this.sortHeadings();
    this.applyFilter();
  }

  // sortHeadings() {
  //   this.allHeadings.sort(( a, b ) => a.headingText.toLowerCase().localeCompare(b.headingText.toLowerCase()));
  // }

  applyFilter() {
    const filterValue = ( this.searchText || '' ).trim().toLowerCase();

    this.displayHeadings = this.allHeadings.filter( headingObj => {
      if ( filterValue === '' ) {
        return true;
      }

      return headingObj.headingText.toLowerCase().includes( filterValue );
    });
  }

  showRelevantPhrase( headingObj ) {
    const { relevantPhrase, isHardCodedPhrase } = headingObj;

    if ( isHardCodedPhrase === true && relevantPhrase != null ) {
      return true;
    }

    if ( this.forNoteType === 'FU' && relevantPhrase != null ) {
      return true;
    }

    return false;
  }

  createHeadingToInsert( headingObj ) {
    const { headingText, relevantPhrase, isHardCodedPhrase } = headingObj;
    let headingToInsert = `<strong>${ headingText }</strong> `;

    if ( isHardCodedPhrase === true && relevantPhrase != null ) {
      headingToInsert += relevantPhrase;
    } else if ( this.forNoteType === 'IE' ) { // IE = Initial Evaluation
      headingToInsert += this.useDragonStyle === true ? this.phrasesService.dragonSymbol : '***';
    } else if ( this.forNoteType === 'FU' && relevantPhrase != null ) { // FU = Follow Up
      headingToInsert += relevantPhrase;
    }

    return headingToInsert;
  }

  applyHeading( headingObj ) {
    const editorInstance = this.ckeditorService.getEditorInstance();

    if ( editorInstance == null || headingObj.headingText === '' ) {
      return;
    }

    if ( this.ckeditorService.isCursorOnEmptyLine() === true ) {
      const pElem = this.ckeditorService.getCursorRootParent();
      if ( pElem?.getName() === 'p' ) {
        pElem.setHtml( '' );
      }
    } else if (
      this.ckeditorService.isCursorAtStartOfLine() === true &&
      this.ckeditorService.getCursorDirectParent()?.getName() !== 'strong'
    ) {
      // do nothing...
    } else {
      this.ckeditorService.moveNextForNewHeading();
    }

    editorInstance.insertHtml( this.createHeadingToInsert( headingObj ));
  }
}
