import {Component, OnInit, AfterViewInit, ViewEncapsulation} from '@angular/core';
import {FileExplorerComponent} from '../file-explorer/file-explorer.component';
import {ISharedFile, ISharedFolder} from '../models';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-shared-file-explorer',
    templateUrl: '../file-explorer/file-explorer.component.html',
    styleUrls: ['../file-explorer/file-explorer.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [DatePipe],
})
export class SharedDriveComponent extends FileExplorerComponent implements AfterViewInit {
    isSharedFileExplorer: boolean = true;
    header_title: string = 'Shared with me';

    ngAfterViewInit() {
        setTimeout(() => {
            this.fetchSharedFilesAndFolders();
        });
    }

    async fetchSharedFilesAndFolders(item: ISharedFolder = null) {
        return new Promise(async (resolve, reject) => {
            this.loading = true;
            try {
                this.navigating = true;
                if (item) {
                    this.historyStack.push(item);
                }

                const sharedFilesAndfoldersResponse: any = await this.fileExplorerService.getSharedFilesAndFolders(this.currentUser.email).toPromise();

                if (sharedFilesAndfoldersResponse.status === 200) {
                    this.initializeFilesAndFolders(sharedFilesAndfoldersResponse.data.array);
                }

                resolve(null);
            } catch (err) {
                reject(err);
            } finally {
                this.navigating = false;
                this.loading = false;
            }
            ;
        });
    }


    fetchFilesAndFoldersByParentFolderId(item: ISharedFolder) {
        return new Promise(async (resolve, reject) => {
            this.loading = true;
            try {
                this.navigating = true;
                if (item) {
                    this.historyStack.push(item);
                }

                const sharedFilesAndfoldersResponse: any = await this.fileExplorerService.getFilesAndFoldersByParentFolderId(item ? item._id : '').toPromise();

                if (sharedFilesAndfoldersResponse.status === 200) {
                    this.initializeFilesAndFolders(sharedFilesAndfoldersResponse.data.array);
                }

                resolve(null);
            } catch (err) {
                reject(err);
            } finally {
                this.navigating = false;
                this.loading = false;
            }
            ;
        });
    }

    onFolderClick(folder: ISharedFolder) {
        if (this.navigating) {
            return;
        }
        this.toggleFileSelection(folder);
        // this.fetchFilesAndFoldersByParentFolderId(folder);
    }

    onFolderDoubleClick(folder: ISharedFolder) {
        this.fetchFilesAndFoldersByParentFolderId(folder);
    }

    upOneLevel() {
        if (this.navigating) {
            return;
        }
        this.historyStack.pop(); // discard current folder
        const folder = this.historyStack.pop(); // pop the previous folder
        if (folder) {
            this.fetchFilesAndFoldersByParentFolderId(folder)
        } else {
            this.fetchSharedFilesAndFolders(null);
        }
    }

}
