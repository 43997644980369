import { Injectable } from '@angular/core';
import * as global from '../global';
import { API_URL } from "../../../environments/api-url";
import { AuthService } from 'src/app/services/auth.service';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import moment from 'moment'
import { map } from 'rxjs/operators';
import { PusherService } from 'src/app/services/pusher-service';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root',
})
export class CensusPatientListV2Service {
  private filterSort = new BehaviorSubject<'seen' | 'not_seen' | 'room_no' | 'new_patient' |
    'status' | 'name' | 'dob' | 'room_no' | 'admission_date' | 'admission_date' | 'discharge_date' | 'payor' | 'user_sort'>('user_sort');
  castFilterSort = this.filterSort.asObservable();
  private filterSearch = new BehaviorSubject<string>("");
  castFilterSearch = this.filterSearch.asObservable();

  private addChargeData = new BehaviorSubject(null);
  public chargeData = this.addChargeData.asObservable();

  private facilityForCharge = new BehaviorSubject(null);
  public castfacilityForCharge = this.facilityForCharge.asObservable();

  private patientForCharge = new BehaviorSubject(null);
  public castpatientForCharge = this.patientForCharge.asObservable();

  private icdsForCharge = new BehaviorSubject(null);
  public castIcdsForCharge = this.icdsForCharge.asObservable();

  private cptsForCharge = new BehaviorSubject(null);
  public castCptsForCharge = this.cptsForCharge.asObservable();

  private cptModifiersForCharge = new BehaviorSubject(null);
  public castCptModifiersForCharge = this.cptModifiersForCharge.asObservable();

  private reportMeasure = new BehaviorSubject<boolean>(null);
  public castReportMeasure = this.reportMeasure.asObservable();

  private billerComments = new BehaviorSubject(null);
  public castBillerComments = this.billerComments.asObservable();

  private addCharge = new BehaviorSubject(null);
  public castAddCharge = this.addCharge.asObservable();

  private patientListData: any = new BehaviorSubject(null);
  castPatientListData = this.patientListData.asObservable();

  private censusPatientSort = new BehaviorSubject(null);
  castCensusPatientSort = this.censusPatientSort.asObservable();

  constructor(
    private httpclient: HttpClient,
    private _pusherService:PusherService,
    private authService: AuthService,
    private toastrService: ToastrService) {
    if (localStorage.getItem("censusPatientSort") !== undefined) {
      if (localStorage.getItem("censusPatientSort") !== null) {
        this.setcensusPatientSort(JSON.parse(localStorage.getItem("censusPatientSort")));
        // console.log("local", this.censusPatientSort);

      }
    }
  }

  subscribeRehabReportsResult(doc_id) {
    const eventNames = ['REHAB_REPORTS_RESULT_ARRIVED'];
    this._pusherService.subscribeToRehabReportsNotificationChannel(doc_id, eventNames)

    this._pusherService.new_rehab_reports_notify.subscribe((pusherData:any) => {      
      if(pusherData) {
        this.toastrService.success(pusherData.message);
      }
    });
  }
  // setPatientListData(data) {
  //   console.log("data", data);
  //   this.patientListData.next(data);
  //   localStorage.setItem("censusPatientListData", JSON.stringify(data));
  // }

  setcensusPatientSort(data) {
    // console.log("data", data);
    this.censusPatientSort.next(data);
    localStorage.setItem("censusPatientSort", JSON.stringify(data));
  }

  setChargeData(data) {
    this.addChargeData.next(data);
  }
  setCharge(data) {
    this.addCharge.next(data);
  }
  setFacilityForCharge(data) {
    this.facilityForCharge.next(data);
  }
  setPatientForCharge(data) {
    this.patientForCharge.next(data);
  }
  setICDsForCharge(data) {
    // console.log('setICDsForCharge', data)
    this.icdsForCharge.next(data);
  }
  setCPTsForCharge(data) {
    this.cptsForCharge.next(data);
  }
  setCPTModifiersForCharge(data) {
    this.cptModifiersForCharge.next(data);
  }
  setReportMeasure(data) {
    this.reportMeasure.next(data);
  }
  setBillerComments(data) {
    this.billerComments.next(data);
  }

  changeFilterSort(value: 'seen' | 'not_seen' | 'room_no' | 'new_patient' |
    'status' | 'name' | 'dob' | 'room_no' | 'admission_date' | 'admission_date' | 'discharge_date' | 'payor') {
    this.filterSort.next(value);
  }
  changeFilterSearch(value) {
    if (value !== this.filterSearch.getValue()) {
      this.filterSearch.next(value);
    }
  }
  getFacilityPatientsByProviderId(provider_id, facility_id) {
    const patient = {
      ...this.authService.authObject,
      provider_id,
      facility_id,
    }

    return this.httpclient
      .post(global.url + API_URL.PATIENT.getFacilityPatientsByProviderId, { patient }).pipe();
  }
  getPatients(facilityId, patientName) {
    const patient = {
      ...this.authService.authObject,
      filter: {
        facility: facilityId,
        patientName
      }
    }

    return this.httpclient
      .post(global.url + API_URL.PATIENT.getPatients, { patient }).pipe();
  }
  isPatientSeen(census, patient) {
    return patient.patient_seen_status === 'seen'
    // const patientsSeen = Array.from(census.patientsSeen).map(id => id.toString());
    // const patientId = patient._id.toString();
    // return Array.isArray(patientsSeen) ? patientsSeen.indexOf(patientId) >= 0 : false;
  }
  isPatientSeenInCensus(census, patient) {
    return patient.current_patient_seen_status === 'seen'
    // return Array.isArray(census.currentPatientsSeen) ? census.currentPatientsSeen.includes(patient._id) : false;
  }
  hasPatientChargeInCensus(census, patient) {
    return Array.isArray(patient.charges) ? patient.charges.find(c =>
      {
        if(c.is_active){
          if(c.rounding_sheet_id === census._id){
            return true
          }
        } 
        
      } ) : false;
  }
  hasPatientDictationInCensus(census, patient) {
    return Array.isArray(patient.audios) ? patient.audios.find(a => a.rounding_sheet_id === census._id) : false;
  }

  isPatientFollowUp(census, patient) {
    const patientCharge = this.hasPatientChargeInCensus(census, patient);
    const patientDictation = this.hasPatientDictationInCensus(census, patient);

    if (this.isPatientSeen(census, patient)) {
      return true // OLD Patient
    }
    else if (!patientCharge && !patientDictation) {
      return false // NEW Patient
    }
    else if (patientDictation) {
      let dos = moment(patientDictation.filterServiceDate);
      let censusDate = moment(census.date);

      if (dos.isValid() && dos.startOf('day').isSame(censusDate.startOf('day'))) {
        return false // NEW Patient
      }
      else {
        return true // OLD Patient
      }
    }
    else if (patientCharge) {
      let visitDate = moment(patientCharge.visit_date);
      let censusDate = moment(census.date);
      if (visitDate.isValid() && visitDate.startOf('day').isSame(censusDate.startOf('day'))) {
        return false // NEW Patient
      }
      else {
        return true // OLD Patient
      }
    }
    else {
      return true // OLD Patient
    }
  }
  getCensusDetail(census_id) {
    const details = {
      ...this.authService.authObject,
      census_id,
    }

    return this.httpclient
      .post(global.url + API_URL.CENSUS.getCensusDetail, { details }).pipe(
        //   map((response:any) => {
        //   if(response.status === 200) {
        //     for (const patient of response.data.patientList) {
        //       if(Array.isArray(patient.census_comment)) {
        //         if(patient.census_comment.length > 0) {
        //           if(patient.census_comment.length > 1) {
        //             patient.census_comment.sort((a: any, b: any) => {
        //               return new Date(b.updatedAt).getMilliseconds() - new Date(a.updatedAt).getMilliseconds();
        //             });
        //           }
        //           patient.census_comment = patient.census_comment[0];
        //         } else {
        //           patient.census_comment = {};
        //         }
        //       }
        //     }
        //   }
        //   return response;
        // })
      );
  }
  getCensus(censusId) {
    const details = {
      ...this.authService.authObject,
      censusId,
    }

    return this.httpclient
      .post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getCensus, { details }).pipe(
        //   map((response:any) => {
        //   if(response.status === 200) {
        //     for (const patient of response.data.patientList) {
        //       if(Array.isArray(patient.census_comment)) {
        //         if(patient.census_comment.length > 0) {
        //           if(patient.census_comment.length > 1) {
        //             patient.census_comment.sort((a: any, b: any) => {
        //               return new Date(b.updatedAt).getMilliseconds() - new Date(a.updatedAt).getMilliseconds();
        //             });
        //           }
        //           patient.census_comment = patient.census_comment[0];
        //         } else {
        //           patient.census_comment = {};
        //         }
        //       }
        //     }
        //   }
        //   return response;
        // })
      );
  }
  getActiveCensus(facility_id) {
    const details = {
      ...this.authService.authObject,
      facility_id,
    }

    return this.httpclient
      .post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getActiveCensus, { details }).pipe();
  }

  initializeActiveCensus(facility_id) {
    const details = {
      ...this.authService.authObject,
      facility_id,
    }

    return this.httpclient
      .post(global.url + API_URL.PATIENT_ROUNDING_SHEET.initializeActiveCensus, { details }).pipe();
  }

  uploadCensusFile(census_id, provider, facility, fileType, file) {
    console.log("file: ", file);

    // const details = {
    //   ...this.authService.authObject,
    //   census_id,
    //   provider,
    //   facility,
    //   fileType,
    //   file
    // }
    // return this.httpclient
    //     .post(global.url + API_URL.PATIENT_ROUNDING_SHEET.uploadCensusFile, {details}).pipe();


    // return new Promise((resolve, reject) => {
      const formData: any = new FormData();
      // const xhr = new XMLHttpRequest();
      formData.append('census_id', census_id);
      formData.append('provider', provider);
      formData.append('facility', facility);
      formData.append('fileType', fileType);

      // for (let i = 0; i < file.length; i++) {
      //   console.log("in loop");

      //   // const {type, sign} = file[i].fileData;
      //   // const signUnsign = sign ? 'sign' : 'unsign';

      //   // if(!counts[signUnsign]) counts[signUnsign] = 1;

      //   formData.append(`file${i}`, file[i].file);
      //   // counts[signUnsign]++;
      // }
      formData.append('file', file);
      // formData.append('formType', formType)
      // xhr.onreadystatechange = function () {
      //   if (xhr.readyState === 4) {
      //     if (xhr.status === 200) {
      //       resolve(JSON.parse(xhr.response));
      //     } else {
      //       reject(xhr.response);
      //     }
      //   }
      // };
      // xhr.open('POST', global.url + API_URL.PATIENT_ROUNDING_SHEET.uploadCensusFile, true);
      // xhr.send(formData);
      return this.httpclient.post(global.url + API_URL.PATIENT_ROUNDING_SHEET.uploadCensusFile, formData);

    // });
  }

  updatePatientListSortOrder(censusId, patientListIds) {
    const details = {
      ...this.authService.authObject,
      censusId,
      patientListIds
    };
    return this.httpclient
      .post(global.url + API_URL.PATIENT_ROUNDING_SHEET.updatePatientListSortOrder,
        { details: details });
  }

  getRecentNoteByCensusId(censusId) {
    const details = {
      ...this.authService.authObject,
      censusId,
    };
    return this.httpclient
      .post(global.url + API_URL.PATIENT_ROUNDING_SHEET.getRecentNoteByCensusId,
        { details: details });
  }

}
