import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import * as global from '../global';
import { Subscription, Observable, Subject, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from "../../../environments/api-url";

@Injectable({
  providedIn: 'root',
})
export class MergeFacilityService {
  currentUser: any;

  public constructor(
    private httpclient: HttpClient,
    private _router: Router,
    private _authService: AuthService) {
    this.currentUser = this._authService.currentUser;
  }

  searchTheFacility(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_FACILITY.searchTheFacility, { details: item }).pipe();
  }
  
  getMergingAudit(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_FACILITY.getMergingAudit, { details: item }).pipe();
  }
  mergeTheFacility(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_FACILITY.mergeTheFacility, { details: item }).pipe();
  }
  revertTheFacility(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_FACILITY.revertTheFacility, { details: item }).pipe();
  }
  mergeTheFacilitySingle(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_FACILITY.mergeTheFacilitySingle, { details: item }).pipe();
  }
  revertTheFacilitySingle(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_FACILITY.revertTheFacilitySingle, { details: item }).pipe();
  }
  mergeTheFacilitySingle_v2(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_FACILITY.mergeTheFacilitySingle_v2, { details: item }).pipe();
  }
  revertTheFacilitySingle_v2(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_FACILITY.revertTheFacilitySingle_v2, { details: item }).pipe();
  }
  mergeTheFacilitySingle_v2_PCC2Matrix(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_FACILITY.mergeTheFacilitySingle_v2_PCC2Matrix, { details: item }).pipe();
  }
  revertTheFacilitySingle_v2_PCC2Matrix(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_FACILITY.revertTheFacilitySingle_v2_PCC2Matrix, { details: item }).pipe();
  }
  mergeTheFacilitySingle_v2_pcc2dn(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_FACILITY.mergeTheFacilitySingle_v2_pcc2dn, { details: item }).pipe();
  }

  mergeTheFacilitySingle_v2_Dn2Matrix(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_FACILITY.mergeTheFacilitySingle_v2_Dn2Matrix, { details: item }).pipe();
  }
  revertTheFacilitySingle_v2_Dn2Matrix(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_FACILITY.revertTheFacilitySingle_v2_Dn2Matrix, { details: item }).pipe();
  }

  revertTheFacilitySingle_v2_pcc2dn(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_FACILITY.revertTheFacilitySingle_v2_pcc2dn, { details: item }).pipe();
  }
  mergeTheFacilitySingle_v2_pcc2pcc(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_FACILITY.mergeTheFacilitySingle_v2_pcc2pcc, { details: item }).pipe();
  }
  revertTheFacilitySingle_v2_pcc2pcc(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_FACILITY.revertTheFacilitySingle_v2_pcc2pcc, { details: item }).pipe();
  }

  mergePaientAndInactive(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_FACILITY.mergePaientAndInactive, { details: item }).pipe();
  }
  getExistingPatientFromPcc(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_FACILITY.getExistingPatientFromPcc, { details: item }).pipe();
  }

  mergePatientsDataForNameChange(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };
    return this.httpclient
      .post(global.url + API_URL.MERGE_FACILITY.mergePatientsDataForNameChange, { details: item }).pipe();
  }

  getExistingDuplicateWithInitialNote(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_FACILITY.getExistingDuplicateWithInitialNote, { details: item }).pipe();
  }
  getDuplicateNamePatients(filter) {
    let item = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      filter: filter
    };

    return this.httpclient
      .post(global.url + API_URL.MERGE_FACILITY.getDuplicateNamePatients, { details: item }).pipe();
  } 

}