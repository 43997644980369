import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientRoundingSheetService } from 'src/app/includes/patient-rounding-sheets/patient-rounding-sheet.service';
import { ToastrComponentlessModule, ToastrService } from 'ngx-toastr';
import moment from 'moment'
import { WoundService } from 'src/app/services/wound.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CensusPatientListService } from 'src/app/includes/census-patient-list/census-patient-list.service';
import swal from 'sweetalert';
import * as global from '../../../../global';
import { AuthService } from 'src/app/services/auth.service';
import { IUser } from 'src/app/classes/user.model';
@Component({
  selector: 'app-move-wound-to-census',
  templateUrl: './move-wound-to-census.component.html',
  styleUrls: ['./move-wound-to-census.component.css']
})
export class MoveWoundToCensusComponent implements OnInit {
  patientRoundingSheets: any = [];
  patientRoundingSheet: any;
  provider_id: string;
  facility_id: string;
  woundId: any;
  disableCreateButton: boolean = true;
  woundMoveLoaderId = 'move-wound-to-census';
  move_to: string = 'census';
  censusPatients: any = [];
  patient: any;
  parentWoundId: any;
  global = global;
  currentUser: IUser;
  constructor(
    public dialogRef: MatDialogRef<MoveWoundToCensusComponent>,
    private _patientRoundingSheetService: PatientRoundingSheetService,
    private toastr: ToastrService,
    private woundService: WoundService,
    private loader: NgxUiLoaderService,
    private censusPatientListService: CensusPatientListService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.currentUser = this.authService.currentUser;
    }

  ngOnInit(): void {
    if (this.data) {
      this.facility_id = this.data.facility_id;
      this.woundId = this.data._id;
      if (this.data.provider_obj && this.data.provider_obj._id) {
        this.provider_id = this.data.provider_obj._id;
      }
      this.initPatientRoundingSheets(this.provider_id)
    }
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  initPatientRoundingSheets(provider_id) {
    if (provider_id) {
      this._patientRoundingSheetService.getCensusList(this.facility_id, provider_id).subscribe((response: any) => {
        if (response.status === 200) {
          this.patientRoundingSheets = response.data.array;
          if (this.patientRoundingSheets.length > 0) {
            this.patientRoundingSheet = this.patientRoundingSheets[0];
            this.disableCreateButton = false;
          }
        }
        else if (response.status === 403) {
          this.patientRoundingSheets = [];
        }
      },
        () => this.toastr.error('Something went wrong', 'Failed'))
    }
  }
  filteredRoundingSheets() {
    return this.patientRoundingSheets.filter(sheet => {
      if (sheet.facility) {
        return sheet.facility._id == this.facility_id;
      }
      return false;
    })
  }
  getCensusDate(census) {
    if (census.date_obj) {
      let { year, month, day } = census.date_obj;
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      return `${month}/${day}/${year}`;
    } else {
      return moment(census.date).format('MM/DD/YYYY');
    }
  }
  async changeMoveTo(census) {
    if (census) {
      this.disableCreateButton = true;
      const censusId = census._id;
      if (this.move_to == 'patient') {
        this.censusPatients = [];
        const censusAllPatients: any = await this.censusPatientListService.getCensusAllPatients(censusId).toPromise();
        if (censusAllPatients && censusAllPatients.status == 200) {
          this.censusPatients = censusAllPatients.data.patientList;
          if (this.censusPatients && this.censusPatients.length > 0) {
            this.patient = this.censusPatients[0];
            this.disableCreateButton = false;
          }
        }
      } else {
        if (this.patientRoundingSheets && this.patientRoundingSheets.length > 0) {
          this.disableCreateButton = false;
        }
      }
    }
  }
  async handleSubmit() {
    if (this.data && this.data.parent_wound && this.data.parent_wound != null && this.data.parent_wound != undefined) {
      this.parentWoundId = this.data.parent_wound;
      swal({
        title: "Move Wound",
        text: "Are you Sure?",
        icon: "warning",
        buttons: [
          "No", "Yes"
        ],
        dangerMode: true,
      })
        .then((unlock) => {
          if (unlock) {
            this.moveWoundToCensus();
          } else {
            this.dialogRef.close();
            return;
          }
        })
    } else if (this.data && (!this.data.parent_wound || this.data.parent_wound != null || this.data.parent_wound != undefined)) {
      this.moveWoundToCensus();
    }
  }
  async moveWoundToCensus() {
    this.dialogRef.close();
    this.disableCreateButton = true;
    let censusDetail = { _id: this.patientRoundingSheet._id, date_obj: this.patientRoundingSheet.date_obj };
    let details: any = { census: censusDetail, wound_id: this.woundId, wound_patient_id: this.data?.patient_id }
    if (this.parentWoundId && this.parentWoundId != undefined && this.parentWoundId != null) { details.parent_woundId = this.parentWoundId, details.isChildWound = true }
    if (this.patient) details.patient_id = this.patient._id;
    const infoToast = this.toastr.info('Moving Wound...', 'Please wait', { disableTimeOut: true });
    this.loader.startLoader(this.woundMoveLoaderId);
    this.woundService.moveWoundToCensus(details).subscribe((res: any) => {
      if (res.status === 200) {
        this.loader.stopLoader(this.woundMoveLoaderId);
        this.toastr.success('Wound Moved Successfully', 'Success');
        this.toastr.clear(infoToast.toastId)
      }
      else {
        this.loader.stopLoader(this.woundMoveLoaderId);
        this.toastr.error("Something Went Wrong", "ERROR");
        this.toastr.clear(infoToast.toastId)
      }
      this.disableCreateButton = false;
    });
  }
}
