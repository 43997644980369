<mat-tab-group style="background-color: white" (selectedTabChange)="hideSelectProvider($event)" class="phrases-tabs">
  <mat-tab label="My Phrases">
    <ng-template matTabContent>
      <div style="margin: 10px" class="flex flex-col gap-2">
        <br />
        <div class="flex">
          <app-input-with-dropdown class="flex-grow" [noDropdown]="true" placeholder="Search Phrases"
            style="margin-left: 10px;margin-right: 10px" (inputChange)="applyFilter($event.target.value)">
          </app-input-with-dropdown>
          <button
            style="margin-left: auto"
            mat-raised-button
            class="dn-button-primary"
            (click)="showDictionaryEditorDialog()"
          >
            Add
          </button>
          &nbsp;
          <button
            style="margin-left:auto"
            mat-raised-button
            class="dn-button-primary"
            (click)="exportPhrasesAsXlxs()"
          >Export as Xlsx</button>
        </div>

        <table mat-table [dataSource]="dataSourceMyPhrases" matSort >
          <ng-container matColumnDef="shortcut">
            <th
              class="header-data"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header-container
            >
              Shortcut Phrase
            </th>
            <td mat-cell *matCellDef="let element; let i = index">
              {{ element?.key }}
            </td>
          </ng-container>
          <ng-container matColumnDef="preview">
            <th
              class="header-data"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header-container>
              Preview
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = index"
              [innerHTML]="displayTextInCard(element.value)">
            </td>
          </ng-container>
          <ng-container matColumnDef="import">
            <th
              class="header-data"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header-container>
              Action
            </th>
            <td mat-cell *matCellDef="let element; let i = index">
              <mat-icon
                style="padding-right: 15px; cursor: pointer"
                matTooltip="View" matTooltipPosition="below"
                (click)="showDictionaryEditorDialog(element, authService.currentUser)"
                >remove_red_eye</mat-icon
              >
              <mat-icon
                style="padding-left: 15px; cursor: pointer"
                matTooltip="Delete" matTooltipPosition="below"
                color="warn"
                (click)="deleteFromNoteBuilderDictionary(element)"
                >delete</mat-icon
              >
            </td>
          </ng-container>
          
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumnsMyPhrases"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsMyPhrases"
          ></tr>
        </table>
  <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

      </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="Company Phrases">
    <ng-template matTabContent>
      <div style="padding: 10px !important">

        <div class="flex justify-between items-center" style="margin-top: 10px;margin-bottom:10px ;">
        <mat-form-field style="color: black !important; max-width: 180px;margin-top: 20px;">
          <mat-label *ngIf="currentUser.user_type === this.USER_TYPE.SNF_WC_NURSE">Select SNF WC Nurse</mat-label>
          <mat-label *ngIf="currentUser.user_type != this.USER_TYPE.SNF_WC_NURSE">Select Provider</mat-label>
          <mat-select [(ngModel)]="selectedProvider._id" name="type">
            <input matInput placeholder="Search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
              style="color:#000;height: 40px;padding-left: 5px;" (keydown)="$event.stopPropagation()" />
            <mat-option *ngFor="let provider of providers | searchProvider : searchText" [value]="selectedProvider._id"
              (onSelectionChange)="onProviderChange($event, provider._id)">
              {{ provider.first_name }} {{ provider.last_name }}, {{provider.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        
        <app-input-with-dropdown class="flex-grow" [noDropdown]="true" placeholder="Search Phrases"
          style="margin-left: 10px;margin-right: 10px" (inputChange)="companyPhraseFilter($event.target.value)">
        </app-input-with-dropdown>
        <button
            style="margin-left: auto"
            mat-raised-button
            class="dn-button-primary"
            (click)="copyPhrase();"
          >
            Import All
          </button>
        </div>
        
        <table mat-table [dataSource]="dataSourceSystemPhrases" matSort>
          <ng-container matColumnDef="sr">
            <th
              class="header-data"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header-container
            >
              Sr.
            </th>
            <td mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </td>
          </ng-container>
          <ng-container matColumnDef="shortcut">
            <th
              class="header-data"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header-container
            >
              Shortcut
            </th>
            <td mat-cell *matCellDef="let element; let i = index">
              {{ element?.key }}
            </td>
          </ng-container>
          <ng-container matColumnDef="preview">
            <th
              class="header-data"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header-container
            >
              Value
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = index"
              [innerHTML]="displayTextInCard(element.value)"
            >
            </td>
          </ng-container>
          <ng-container matColumnDef="import">
            <th
              class="header-data"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header-container
            >
              Action
            </th>
            <td mat-cell *matCellDef="let element; let i = index">
              <mat-icon
                style="cursor: pointer; padding-right: 15px"
                matTooltip="View" matTooltipPosition="below"
                (click)="showDictionaryEditorDialog(element, selectedProvider, false)"
                >remove_red_eye</mat-icon
              >
              <mat-icon
                style="cursor: pointer; padding-left: 15px"
                matTooltip="Import" matTooltipPosition="below"
                (click)="saveDictionaryElement(element)"
              >
                system_update_alt
              </mat-icon>
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumnsCompanyPhrases"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsCompanyPhrases"
          ></tr>
        </table>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
        <button
          mat-mini-fab
          matTooltip="Watch System Phrases"
          aria-label="icon-button"
          style="transition: none !important;box-shadow: none !important; background:none !important; color: inherit; margin-right: 10px;"
          (click)="watchHelpVideo('system-phrases')"
        >
          <mat-icon>help</mat-icon>
        </button>
    </ng-template>
  </mat-tab>
</mat-tab-group>
