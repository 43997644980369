import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { UserManagementService } from '../user-management/user-management.service';
import { ProfileService } from '../profile/profile.service';
import * as global from '../../includes/global';
import { RolesDialog } from 'src/app/shared/popup-dialogs/roles-dialog/roles.dialog';
import { HttpResponse } from '@angular/common/http';
import moment from 'moment'
import { CommonService } from 'src/app/services/common.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DialogConfirmationComponent } from '../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { TagsService } from 'src/app/services/tags.service';
import { UserService } from 'src/app/services/Modules/user.service';

@Component({
  selector: 'app-medical-assistant-list',
  templateUrl: './medical-assistant-list.component.html',
  styleUrls: ['./medical-assistant-list.component.css']
})
export class MedicalAssistantListComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['first_name', 'last_name', 'email', 'last_login', 'status', 'user-permissions', 'admin'];
  @ViewChild(MatSort) sort: MatSort;
  loading = false;
  public currentUser: any;
  public response: any;
  public dataSource: any;
  company_id: any;
  companies: any = [];
  public roleDialogRef;
  localOrDevEnvironment: Boolean = false;
  filteredCompanies: Observable<string[]>;
  companyControl = new FormControl();
  userNameRegExp: any = '';
  usersFilter = {
    user_type: 'medical_assistant'
  };
  currentCompany: any;
  medicalAssistants: any;
  userRoleTypeSubscription: any;
  selectedUserTypeSubscription: any;
  selectedUserType: any;
  is_activated = 'true';
  loaderId = 'medical-assistant-list-loader';
  timeoutId: NodeJS.Timeout;
  hasMALoaded: boolean;
  company_type: any;
  global = global;
  iscurrentCompany: any = {};
  selectedRoleType: string;
  selectedPermissions: string[] = [];
  permissionsList: any;
  company: any;
  constructor(
    private loader: NgxUiLoaderService,
    public services: UserTypesRelationshipService,
    private _router: Router,
    private userManagementService: UserManagementService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private _authService: AuthService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private _profileService: ProfileService,
    private commonService: CommonService,
    private tagsService: TagsService,
    private userService: UserService,


  ) {
    this.currentUser = this._authService.currentUser;
    this.displayedColumns = ['first_name', 'last_name', 'email', 'last_login', 'timezone', 'status', 'cognito', 'user-permissions', 'charge_status', 'simulate', 'admin'];

    if (this.currentUser.admin_type === "full_admin" || this.currentUser.admin_type === "system_admin") {
      this.displayedColumns.push('user-type');
    }
    if (this.currentUser.product_owner) {
      this.displayedColumns = ['first_name', 'last_name', 'email', 'last_login', 'timezone', 'status', 'cognito','admin_', 'charge_status','simulate'];
      this.displayedColumns.push('companies');
    }
    this.displayedColumns.push('operations');
    if (!this.currentUser.product_owner) {
      this.displayedColumns = this.displayedColumns.filter(f => f !== 'two-factor');
    }
    if (!this.currentUser) {
      // if not login, stop and redirect to login #mohsin-dev
      localStorage.setItem('currentUser', 'null');
      this._router.navigate(['/login']);
      return;
    }
    this.loading = true;
    // this.company_id = this.currentUser.company_id;
    // if(this.currentUser.account_type == 'company'){
    //   this.company_id = this.currentUser.company_id;
    // }

    // if(this.currentUser.account_type == 'company' && !window.location.pathname.includes('manage-companies')){  

    if (window.location.pathname.includes('company') && !window.location.pathname.includes('manage-companies')) {
      this.company_id = this.route.parent.parent.parent.snapshot.params.id;
    }
    else if (window.location.pathname.includes('manage-companies')) {
      this.company_id = this.route.parent.parent.parent.snapshot.params.id;
    }
    this.company = this.commonService.getLocalStorageItem('admin-Dashboard'); 

    if (this.commonService.getLocalStorageItem('admin-Dashboard'))
      this.iscurrentCompany.company_type = this.commonService.getLocalStorageItem('admin-Dashboard').currentCompanyType;


  }
  ngOnDestroy(): void {
    if (this.userRoleTypeSubscription) {
      this.companyService.changeUserRoleType(null);
      this.userRoleTypeSubscription.unsubscribe();
    }
    if (this.selectedUserTypeSubscription) {
      this.selectedUserTypeSubscription.unsubscribe();
    }

    if (!window.location.pathname.includes('doctor-profile')) {
      localStorage.removeItem("medical-provider-name")
    }
  }

  async ngOnInit() {
    this.userService.getUserPermission(this.company_id).subscribe((response: any) => {
      if (response.status !== 200) return;
      this.permissionsList = response.data.filter((permission) => {
        if (permission.key == "add_patients_to_census" || permission.key == "has_pcc_account" || permission.key == "charge_status" || permission.key == "show_matrixCare" || permission.key == "is_dictation_enabled" || permission.key == "eprescription" || permission.key == "allow_tag_to_patient") {
          return true;
        }
        return false;
      });
    });
    try {
      if (this.currentUser.product_owner) {
        await this.getCompanies();
      }
      this.getCompanyType();
      this.selectedUserTypeSubscription = this.commonService.castselectedUserType.subscribe(userType => {
        if (userType) {
          this.selectedUserType = userType;
        }
      });
      this.userNameRegExp = localStorage.getItem("medical-provider-name")

      this.getmedicalAssistants();

      this.filteredCompanies = this.companyControl.valueChanges.pipe(
        startWith(''),
        map((value: any) => this.filterCompany(value)));
      this.userRoleTypeSubscription = this.companyService.castUserRoleType.subscribe(role => {
        if (role) {
          this.selectedRoleType = role;
        }
      });
    } catch (err) {
      console.log("err", err);
    }
    if (this.company.portal_type !== global.PORTAL_TYPE.NON_WC) {
      this.displayedColumns = this.displayedColumns.filter(column => column != 'user-permissions' && column != 'admin');
    }
    else
      this.displayedColumns = this.displayedColumns.filter(column => column != 'charge_status');
  }
  private filterCompany(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.companies.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    else {
      return this.companies;
    }
  }

  async getCompanyType() {
    const company_id = this.company_id;

    await this.companyService.getCompanyName(company_id).subscribe((res: any) => {
      let company = res.data;
      this.company_type = company.company_type
    })
  }

  searchDoctors() {
    clearTimeout(this.timeoutId);
    localStorage.setItem("medical-provider-name", (this.userNameRegExp));
    this.timeoutId = setTimeout(() => {
      this.hasMALoaded = true;
      this.getmedicalAssistants();
    }, 1000);
  }
  applyUserFilter($event) {

    this.userNameRegExp = null;
    this.userNameRegExp = localStorage.removeItem("medical-provider-name")

    setTimeout(() => {
      this.userNameRegExp = '';
      this.dataSource.filter = '';
      this.is_activated = $event.value == "true" ? "true" : "false";
      this.getmedicalAssistants(true);
    }, 10);

    // this.services.getMAList($event.value, $event.value ? this.company_id : null).subscribe(data => {
    //   this.response = data;
    //   this.medicalAssistants = this.response.data;
    //   this.dataSource = new MatTableDataSource(this.medicalAssistants);
    // });
  }
  getCompanies() {
    return new Promise<void>((resolve, reject) => {
      this.companyService.getAllCompanies({ company_type: { $ne: global.COMPANY_TYPE.SNF } }).subscribe((response: any) => {
        if (response.status == 200) {
          this.companies = response.data;
          this.currentCompany = this.route.parent.parent.parent.snapshot.params.id
          var index = this.companies.findIndex(company => this.currentCompany === company._id);
          this.currentCompany = this.companies[index];
          this.companies.splice(index, 1);
          this.companyControl.setValue(this.currentCompany?.name);
          resolve(null);
        }
        else if (response.status == 403) {
          this.toastr.error(response.message, "ERROR");
        }
      }, err => {
        reject();
        this.toastr.error(err, "ERROR");
      })
    });
  }

  deleteUser(id: string) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: "Are you sure you want to delete this User ?"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === "true") {
        this._profileService.deleteUser(id).subscribe((res: any) => {
          // this.doctors = this.doctors.filter(user => user._id !== id)
          // this.dataSource.data = [];
          // this.dataSource.data = this.doctors;
          if (res.status == 200)
            this.toastr.success("User Deleted Successfully");
          else
            this.toastr.warning(res.message);
          this.getmedicalAssistants();
        });
      } else {
        return;
      }
    })
  }

  getmedicalAssistants(showLoader: boolean = false) {
    showLoader && this.loader.startLoader(this.loaderId);
    this.services.getMAList(this.is_activated, this.company_id, this.userNameRegExp).subscribe((data: any) => {
      showLoader && this.loader.stopLoader(this.loaderId);
      this.response = data;
      this.medicalAssistants = this.response.data;
      this.hasMALoaded = false;
      this.sortUsers(this.medicalAssistants);
      // this.medicalAssistants.sort(function (a, b) { return a.first_name > b.first_name ? 1 : -1; });
      let perListKeys = this.permissionsList.map(per => per.key)
      this.medicalAssistants.forEach(medicalAssistant => {
        medicalAssistant.companies = this.companies
        if (medicalAssistant.recentLogin) {
          medicalAssistant.recentLogin = moment(medicalAssistant.recentLogin).tz('America/Los_Angeles').format('YYYY-MMM-DD h:mm A');
        }
        this.companies.forEach(company => {
          if (medicalAssistant.company_id == company._id) {
            medicalAssistant.selectedCompany = company._id;
          }
        });
        medicalAssistant.permissions = medicalAssistant.permissions.map(per => per.key);
        medicalAssistant.permissions = medicalAssistant.permissions.filter(per => perListKeys.includes(per))
      });
      this.dataSource = new MatTableDataSource(this.medicalAssistants);
      this.userRoleTypeSubscription = this.companyService.castUserRoleType.subscribe(role => {
        if (role) {
          this.dataSource.data = this.medicalAssistants;
          if (role === "all") {
            this.dataSource.data = this.medicalAssistants;
          }
          else {
            this.dataSource.data = this.dataSource.data.filter(medicalAssistant => medicalAssistant.admin_type === role);
          }
        }
      });
    }, console.error, () => this.loading = false);
  }
  changeCognitoStatus(medicalAssistant, status) {
    let updateObject = {
      _id: medicalAssistant._id,
      enableCognito: status
    };

    this.userManagementService.updateUser(updateObject, this.company_id).subscribe((response: any) => {

      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  changeChargeStatus(medicalAssistant, status) {
    let updateObject = {
      _id: medicalAssistant._id,
      charge_status: status
    };

    this.userManagementService.updateUser(updateObject).subscribe((response: any) => {

      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  onCompanyChange(element, company) {
    this.companyService.companyChangeForUser(element._id, company).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("Company Changed")
        this.dataSource.data = this.dataSource.data.filter(user => user._id !== element._id);
        // this.getCompanies();
      }
    })
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // localStorage.setItem("medical-provider-name", (this.dataSource.filter));
  }


  showRolesDialog(user) {
    this.roleDialogRef = this.dialog.open(RolesDialog, { width: '80%', data: { user } });
  }

  changeUserActiveStatus(userId: string, status) {
    this.userManagementService.changeUserActiveStatus(userId, status).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.success(response.message, 'Success');
      } else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }

  changeAdminStatus(medicalAssistant, status) {
    this.userManagementService.changeAdminStatus(medicalAssistant._id, this.company_id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  changeTATStatus(medicalAssistant, status) {
    this.userManagementService.changeTATStatus(medicalAssistant._id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  changeRRGenStatus(medicalAssistant, status) {
    this.userManagementService.changeRRGenStatus(medicalAssistant._id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  changeTwoFactorAllowance(provider, status) {
    let updateObject = {
      _id: provider._id,
      allow_two_factor_change_by_admin: status
    };

    this.userManagementService.updateUser(updateObject).subscribe((response: any) => {

      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  changeMatrixStatus(provider, status) {
    this.userManagementService.changeMatrixStatus(provider._id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  handleChangeUserType(userId, event) {
    let additionalRole: string = '';
    var update = {
      user_type: event.value,
      user_id: userId
    }
    if (event.value == 'test_provider') {
      additionalRole = 'demo_provider';
    } else if (event.value == 'cnp') {
      additionalRole = 'cnp';
    }
    this._profileService.updateUserType(update, additionalRole).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("User type changed");
        this.getmedicalAssistants();
      }
    });
  }
  ResendEmail(userId) {
    const infoToast = this.toastr.info('Sending Email', 'Please wait', { disableTimeOut: true });
    this.companyService.ResendEmail(userId).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.clear(infoToast.toastId);
        this.toastr.success("Email sent successfully");
      }
    });
  }
  exportUsersAsXLSX() {
    this.loader.startLoader(this.loaderId);
    this.companyService.exportUsersAsXLSX(this.company_id, this.selectedUserType, this.is_activated, null, this.selectedRoleType).subscribe((response: any) => {
      if (response instanceof HttpResponse) {
        this.loader.stopLoader(this.loaderId);
        // this.toastr.success("Status changed");
        var url = window.URL.createObjectURL(response.body);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `Users`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }
    });
  }
  changeDictationStatus(provider, status) {
    let updateObject = {
      _id: provider._id,
      is_dictation_enabled: status
    };

    this.userManagementService.updateUser(updateObject).subscribe((response: any) => {

      if (response.status == 200) {
        if (this.currentUser._id === response.data._id) {
          const currentUser = JSON.parse(localStorage.getItem("currentUser"));
          currentUser.is_dictation_enabled = response.data.is_dictation_enabled;
          localStorage.setItem("currentUser", JSON.stringify(currentUser));
        }
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  sortUsers(arr: any[]) {
    arr.sort((a, b) => a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase()));
  }

  async changeUserPermissions(element, event) {
    this.selectedPermissions = event.value;
    element.permissions = event.value;
    let filterArray = this.permissionsList.filter((per) => element.permissions.findIndex((selectedPer) => selectedPer === per.key) > -1);
    let details = {
      userId: element._id,
      company_id: this.company_id,
      filterArray: filterArray,
    }
    const infoToast = this.toastr.info('Updating user permissions...', 'Please wait', { disableTimeOut: true });
    await this.userService.setUserPermission(details).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.success(response.message, 'Success');
      } else {
        this.toastr.error('something went wrong', 'Error');
      }
      this.toastr.clear(infoToast.toastId);
    });
  }
  resolvePermission(key) {
    if (key) {
      let index = this.permissionsList.findIndex(permissions => permissions.key == key);
      return this.permissionsList[index].value;
    }
  }
  getCheckedPerm(element, perm) {
    if (element.findIndex((permission) => permission.key === perm) > -1) {
      return true;
    } else {
      return false;
    }

  }
}
