import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FacilityService } from 'src/app/services/facility.service';
import { WoundService } from 'src/app/services/wound.service';
import { ToastrService } from 'ngx-toastr';
import { HttpResponse } from '@angular/common/http';
import moment from 'moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-patient-discharge-report',
  templateUrl: './discharged-patient-report.component.html',
  styleUrls: ['./discharged-patient-report.component.css']
})
export class PatientDischargeReportComponent implements OnInit {
  filter: any = {
    creationDate: {
      startDate: moment().subtract(6, 'days').toDate(),
      endDate: moment().toDate()
    },
    company_id: null
    // facilityId: null
  };
  loader_id = "app-patient-discharge-report";
  currentDate = moment(new Date());
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 1 Week': [moment().subtract(6, 'days'), moment()],
    'Last 2 Week': [moment().subtract(13, 'days'), moment()],
    'Last 3 Week': [moment().subtract(20, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  // filteredFacilities: Observable<string[]>;
  displayedColumns: string[] = [
    'name', 'facility', 'dob', 'mrn', 'active', 'inactive', 'days_diff'];
  // facilityControl = new FormControl();
  // facilities: Array<any> = [];
  // facility_name: "";
  companyId: any;
  dischargePatientReport: any = [];
  dataSource: any = new MatTableDataSource([]);
  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    // totalPages: 0,
    // currentRecords: 0
    PAGE_LIMIT: 50,
    skip: 0
  };
  selectedFacilityType = "pcc";
  facilityTypes: any
  currentUser: any;
  companySide: boolean;
  nursingSide: boolean;
  user_dashboard: Boolean = false;
  constructor(
    private router: Router,
    // private _facilityService: FacilityService,
    private _location: Location,
    private _wound: WoundService,
    private _toastr: ToastrService,
    private loader: NgxUiLoaderService,
    private _route: ActivatedRoute,
    private _authService: AuthService
    ) {
    this.companyId = this._route.parent.snapshot.params.id;
    this.currentUser = this._authService.currentUser;
    
    if(this._route.parent.snapshot.params.id){
      this.companySide=true;
    }else{
      this.companyId = this.currentUser.company_id;
    }
    if(window.location.pathname.includes('dashboard')){
      this.user_dashboard = true;
    }
    if(window.location.pathname.includes('nursing')){
      this.nursingSide=true;
    }
    this.facilityTypes = [
      { type: "PCC", value: "pcc" },
      { type: "Non PCC", value: "non-pcc" }
    ];
  }
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////All Comment Code is for facility filter///////////////// 
  //////////////////////////////////////////////////////////////////////////////
  async ngOnInit(): Promise<void> {
    // await this.initFacilities();
    // this.selectDefaultFacility();
    this.initDischargePatientReport();
    // this.filteredFacilities = this.facilityControl.valueChanges.pipe(
    //   startWith(''),
    //   map((value:any) => this.filterFacilities(value)));
  }

  // private filterFacilities(value: string): string[] {
  //   if (value) {
  //     const filterValue = value.toLowerCase();
  //     return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) === 0);
  //   }
  //   else {
  //     return this.facilities;
  //   }
  // }
  // async initFacilities() {
  //   const filter = {
  //     company_id: this.companyId
  //   };
  //   const projection = {
  //     title: 1
  //   };
  //   const facilitiesResponse: any = await this._facilityService.getAllFacilities(filter, projection).toPromise();
  //   if (facilitiesResponse.status == 200) {
  //     this.facilities = facilitiesResponse.data.array;
  //   }
  // }
  // selectDefaultFacility() {
  //   if (this.facilities.length > 0) {
  //     this.filter.facilityId = this.facilities[0]._id;
  //     this.facility_name = this.facilities[0].title;
  //   }
  //   this.facilityControl.setValue(this.facilities[0].title);
  // }
  // onSelectFacility($event) {
  //   console.log($event._id);
  //   this.filter.facilityId = $event._id;
  //   this.facility_name = $event.title;
  // }
  handleApplyFilter() {
    this.initDischargePatientReport();
  }
  initDischargePatientReport() {
    this.loader.startLoader(this.loader_id);
    this.filter.pageNo = this.pagination.pageNo;
    this.filter.facility = this.selectedFacilityType;
    this._wound.getDischargedPatientReport(this.filter).subscribe((response: any) => {
      if (response.status == 200) {
        console.log(response.data.report);
        this.dischargePatientReport = response.data.report;
        this.pagination.totalRecords = response.data.totalRecords;
        this.handlePagination();
        this.dataSource.data = this.dischargePatientReport;
        this.loader.stopLoader(this.loader_id);
      }
    }, err => {
      this._toastr.error(err, "ERROR");
    }
    );
  }
  getFullName(first_name, last_name) {
    return last_name + ", " + first_name;
  }
  resetFilter() {
    // this.selectDefaultFacility();
    delete this.filter.creationDate;
    const defaultDate = {
      startDate: moment().subtract(6, 'days').toDate(),
      endDate: moment().toDate()
    };
    this.filter.creationDate = defaultDate;
    this.initDischargePatientReport();
  }
  goBack() {
    this._location.back();
  }
  exportReportAsXLSX() {
    this._toastr.info("Downloading Report");
    let exportFilter = {
      creationDate: this.filter.creationDate,
      facility: this.filter.facility,
      company_id: null
    }
    this._wound.exportDischargePatientReportAsXLSX(exportFilter).subscribe((response: any) => {
      if (response instanceof HttpResponse) {
        var url = window.URL.createObjectURL(response.body);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `Reports`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
        this._toastr.success("Report Downloaded", "Success");
      }
    });
  }
  changeCreationDate(event) {
    if (event.startDate && event.endDate) {
      this.filter.creationDate.startDate = moment(event.startDate).toDate();
      this.filter.creationDate.endDate = moment(event.endDate).toDate();
    }
  }
  handlePagination() {
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    if (this.pagination.skip + this.dischargePatientReport.length < this.pagination.totalRecords) {
      this.pagination.hasNextPage = true;
    }
    else {
      this.pagination.hasNextPage = false;
    }
    if (this.pagination.skip > 0) {
      this.pagination.hasPrevPage = true;
    }
    else {
      this.pagination.hasPrevPage = false;
    }
  }
  goToPreviousPage() {
    this.pagination.pageNo--;
    this.initDischargePatientReport();
  }
  goToNextPage() {
    this.pagination.pageNo++;
    this.initDischargePatientReport();
  }
}
