import { Component, ElementRef, Inject, OnDestroy, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { AuthService } from "src/app/services/auth.service";
import { WoundCareWidgetService } from "../wound-care-widget.service";

export interface WoundImageSnapshotDialogData {
    wound: any;
    uploadType: "camera" | "computer";
}
@Component({
    selector: "wound-image-snapshot-dialog",
    templateUrl: "./wound-image-snapshot-dialog.component.html",
    styleUrls: ["./wound-image-snapshot-dialog.css"]
})
export class WoundImageSnapshotDialogComponent implements OnDestroy{
    wound: any;
    image: any;
    uploadType: string;
    selectedImages: any[] = [];

    WIDTH = 640;
    HEIGHT = 480;

    @ViewChild("video")
    public video: ElementRef;

    @ViewChild("canvas")
    public canvas: ElementRef;

    captures: string[] = [];
    error: any;
    isCaptured: boolean;

    constructor(
        public dialogRef: MatDialogRef<WoundImageSnapshotDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: WoundImageSnapshotDialogData,
        private sanitizer: DomSanitizer,
        private authService: AuthService,
        private woundCareWidgetService: WoundCareWidgetService,
        private toastr: ToastrService
    ) {
        if (data) {
            this.wound = data.wound;
            this.uploadType = data.uploadType;
        }
    }

    async ngAfterViewInit() {
        await this.setupDevices();
    }
    ngOnDestroy(): void {
        if (this.video.nativeElement.srcObject) {
            this.video.nativeElement.srcObject.getTracks().forEach(track => {
                track.stop();
            });
        }
    }
    async setupDevices() {
        const isIpad = /iPad|Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
        const videoConstraints = {
            video: {
                facingMode: isIpad ? { exact: 'environment' } : 'user'
            }
        };
    
        const errorMessages = {
            NotFoundError: "No camera found",
            DevicesNotFoundError: "No camera found",
            NotAllowedError: "Permissions have not been granted to use your camera",
            PermissionDeniedError: "Permissions have not been granted to use your camera",
            OverconstrainedError: "The constraints you requested are not supported",
            ConstraintNotSatisfiedError: "The constraints you requested are not supported"
        };
    
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia(videoConstraints);
                if (stream) {
                    this.video.nativeElement.srcObject = stream;
                    this.video.nativeElement.play();
                    this.error = null;
                } else {
                    this.error = "You have no output video device";
                }
            } catch (e) {
                this.error = errorMessages[e.name] || "An unknown error occurred: " + e.message;
            }
        } else {
            this.error = "getUserMedia is not supported in this browser";
        }
    }

    capture() {
        this.drawImageToCanvas(this.video.nativeElement);
        this.captures.push(this.canvas.nativeElement.toDataURL("image/png"));
        this.isCaptured = true;

        this.canvas.nativeElement.toBlob((image) => {
            const file = new File([image], `image-${Date.now()}.png`, {type: 'image/png'});
            this.selectedImages.push(file);
            console.log(this.selectedImages)
        },'image/png');
    }

    drawImageToCanvas(image: any) {
        this.canvas.nativeElement
            .getContext("2d")
            .drawImage(image, 0, 0, this.WIDTH, this.HEIGHT);
    }

    removeImage(image) {
        const index = this.captures.indexOf(image);
        if (index > -1) {
          this.captures.splice(index, 1);
          this.selectedImages.splice(index, 1);
        }
    }

    imagePreviewUrl(image) {
        const src = URL.createObjectURL(image);
        return this.sanitizer.bypassSecurityTrustUrl(src);
    }
    async uploadWoundImages() {
        const infoToast = this.toastr.info("Uploading images...", "", {
            disableTimeOut: true
        });
        let uploadedImages = [];
        for (const image of this.selectedImages) {
            const { status, message, data } = (await lastValueFrom(
                this.woundCareWidgetService.uploadWoundImage(image, this.wound)
            )) as any;
            if (status == 200) {
                uploadedImages.push(data);
                // if(Array.isArray(this.wound.wound_images)){
                //   this.wound.wound_images.push(data);
                // } else {
                //   this.wound.wound_images = [data];
                // }
                // const index = this.selectedImages.indexOf(image);
                // if (index > -1) {
                //   this.selectedImages.splice(index, 1);
                // }
            }
        }

        if (uploadedImages.length === 0) {
            this.toastr.error(
                `Somthing went wrong while uploading image.`,
                "Error"
            );
        } else {
            this.toastr.success(
                `${uploadedImages.length} Images uploaded successfully.`,
                "Success"
            );
        }
        this.dialogRef.close(uploadedImages);
    }
}
