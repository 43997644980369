import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import { CKEditorService } from '../../note-editor/ckeditor.service';


export interface DictionaryEditorDialogData {
  key: String;
}

@Component({
  selector: 'app-phrase-editor-dialog-system',
  templateUrl: './phrase-editor-dialog-system.component.html',
  styleUrls: ['./phrase-editor-dialog-system.component.css']
})
export class PhraseEditorDialogSystemComponent implements OnInit {
  addPhraseKey = {
    key: null
  };
  isNew = false;
  cke4Config;

  constructor(
    public dialogRef: MatDialogRef<PhraseEditorDialogSystemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DictionaryEditorDialogData,
    private ckeditorService: CKEditorService
  ) {
    this.cke4Config = {
      ...this.ckeditorService.getDefaultConfig(),
      editable: true
    };

    if(data) {
      this.addPhraseKey = data;
      console.log('data', data, 'after dialog');
  }
  this.isNew = data ? false : true;
  }

  ngOnInit(): void {
    
  }

  onReadyCkeditor(e) {
    const {editor} = e;
    this.ckeditorService.ckeditorRemoveTitle(editor)
}

}
