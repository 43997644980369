import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranscriptionCreateService } from '../transcription-create.service';
import * as global from '../../global';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import moment from 'moment'
import { NoteEditorService } from '../../note-editor/note-editor.service';
import { Note } from '../../note-editor/model/note';
import { TranscriptionService } from 'src/app/services/transcription.service';
import { AuthService } from 'src/app/services/auth.service';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

export interface ViewSharedFacilityNoteComponent {
  note
  timezone: any,
  void_census: any,
  is_same_company: any
}


@Component({
  selector: 'app-view-shared-facility-note',
  templateUrl: './view-shared-facility-note.component.html',
  styleUrls: ['./view-shared-facility-note.component.css']
})
export class ViewSharedFacilityNoteComponent implements OnInit {
  global = global;

  note;
  note_id: string;
  height: number;
  loaderId = 'view-note-loader';
  addendums: any[];
  timezone;
  currentUser: any;
  voidCensus: Boolean = true;
  isSameCompany: boolean = false;
  routerLink: string;

  constructor(
    public dialogRef: MatDialogRef<ViewSharedFacilityNoteComponent>,
    // private loader: NgxUiLoaderService,
    private noteEditorService: NoteEditorService,
    private transcriptionService: TranscriptionService,
    private transcriptionCreateService: TranscriptionCreateService,
    private _auth: AuthService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: ViewSharedFacilityNoteComponent
  ) {
    if (data) {
      this.note = data.note;
      this.note_id = data.note_id;
      this.timezone = data.timezone;
    }
    if(data.is_same_company) {
      this.isSameCompany = true;
    }
  }

  async ngOnInit() {
    if (this.note_id) {
      const noteResponse: any = await this.transcriptionService.get_transcription_by_id(this.note_id).toPromise();
      if (noteResponse.status === 200) {
        this.note = noteResponse.data;
      }
    }
    this.currentUser = this._auth.currentUser;
    this.initAddendums();
    if (this.router.url.includes("/company"))
      this.routerLink = `company/${this.currentUser.company_id}/note-editor/${this.note_id}`;
    else
      this.routerLink = `/note-editor/${this.note_id}`;
  }

  initAddendums() {
    this.transcriptionCreateService.getNoteAddendums({ noteId: this.note._id }).subscribe((response: any) => {
      if (response.status === 200) {
        this.addendums = response.data.array;
        this.height = 80;
      }
    });
  }


  applyTimezone(date) {

    let serviceDate = new Date(new Date(date).toLocaleString('en-US', { timeZone: this.timezone }))

    return serviceDate;
    // return moment(new Date(date)).format('MMM DD, YYYY h:mm a');

    // return moment(new Date(date).toLocaleString('en-US', { timeZone:  this.note.filterServiceDate.timezone })).format('MMM DD, YYYY h:mm a');
  }

  generatePDFNote() {
    this.noteEditorService.generatePDFNote(this.note._id).subscribe((res: any) => {
      if (res instanceof HttpResponse) {
        var url = window.URL.createObjectURL(res.body);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        let day = (this.note.date_obj.day < 10) ? ('0' + (this.note.date_obj.day).toString()) : (this.note.date_obj.day).toString();
        let month = (this.note.date_obj.month < 10) ? ('0' + (this.note.date_obj.month).toString()) : (this.note.date_obj.month).toString();
        let year = (this.note.date_obj.year);
        let dateobj = month + '-' + day + '-' + year;
        a.download = `${(this.note.patient_id_ref?.first_name[0] || '-') + '_' + (this.note.patient_id_ref?.last_name || '-')}_${dateobj}_Wound Progress Note.pdf`;
        // a.download = `${this.note.transcribtion_title}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }
    });
  }

  getDOS() {
    let { year, month, day, hour, minute } = this.note.date_obj;
    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
    let str = `${month}/${day}/${year}`;
    if (hour && minute) {
      str += ` ${hour}:${minute}`
    }
    return str;
  }

  getAddendumDate(addendum) {
    if (addendum.created_date_obj) {
      let { year, month, day, hour, minute } = addendum.created_date_obj;
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      let str = `${month}/${day}/${year}`;
      if (hour && minute) {
        str += ` ${hour}:${minute}`
      }
      return str;
    } else {
      return moment(this.applyTimezone(addendum.createdAt)).format(global.moment_date_format2)
    }
  }

}
