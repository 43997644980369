<div class="container merging-block">
    <mat-card>
        <mat-card-content>
            <mat-tab-group (selectedTabChange)="onTabClick($event)">

                <mat-tab label="DN to PCC Facility">
                    <div class="mat-tab-content">
                        <app-merge-facilities></app-merge-facilities>
                    </div>
                </mat-tab>
                <mat-tab label="PCC to Matrix Facility">
                    <div class="mat-tab-content">
                        <app-merging-facility-pcc-to-matrix> </app-merging-facility-pcc-to-matrix>
                    </div>
                </mat-tab>

                 <mat-tab *ngIf="global.ENVIRONMENT === this.global.PRE_PROD||global.ENVIRONMENT === this.global.LOCAL || global.ENVIRONMENT === this.global.DEV" label="Patient">
                            <div class="mat-tab-content">
                                <app-merge-patients> </app-merge-patients>
                            </div>
                </mat-tab>
                
                <mat-tab label="DN to Matrix Facility">
                    <div class="mat-tab-content">
                        <app-mergingfacility-matrixto-dn> </app-mergingfacility-matrixto-dn>
                    </div>
                </mat-tab>

                <mat-tab label="Merge Duplicate Name Patients">
                    <div class="mat-tab-content">
                        <app-merge-duplicate-name-patient></app-merge-duplicate-name-patient>
                    </div>
                </mat-tab>
                

                <mat-tab *ngIf="inSpecificIds()" label="Patient's Data Moving">
                    <div class="mat-tab-content">
                        <app-merge-patients-data> </app-merge-patients-data>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="inSpecificIds()" label="Facility's Data Moving">
                    <div class="mat-tab-content">
                        <app-merge-facilities-data> </app-merge-facilities-data>
                    </div>
                </mat-tab>
                
                <mat-tab *ngIf="inSpecificIds()" label="Wound">
                    <div class="mat-tab-content">
                        <app-move-wound> </app-move-wound>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </mat-card>
</div>