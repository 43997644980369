import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { USER_TYPE } from '../../../includes/global'
import { MatTableDataSource } from '@angular/material/table';
import { FacilityService } from 'src/app/services/facility.service';
import { UserService } from 'src/app/services/Modules/user.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-company-imes',
  templateUrl: './company-imes.component.html',
  styleUrls: ['./company-imes.component.css']
})
export class CompanyImesComponent implements OnInit {
  public dataSource: any;
  public imes: any = [];

  displayedColumns: string[] = ['name','abbreviation', 'email',
    'description', 'address', 'operations'];
  // 'operations'

  @ViewChild(MatSort) sort: MatSort;

  params: {
    id: string
  }
  loaderId = 'company-imes-loader';

  constructor(
    private _location: Location,
    private loader: NgxUiLoaderService,
    private route: ActivatedRoute,

    public companyService: CompanyService,
  ) { 
    this.params = {
      id: this.route.parent.parent.snapshot.params.id
    };
  }

  async ngOnInit() {
    await this.initIMEs();
  }
  initIMEs = async () => {
    this.loader.startLoader(this.loaderId);

    const company_id = this.params.id;

    const filter = {
      is_active: true,
      company_id
    }
    const projection = {
    }

    const imesResponse: any = await this.companyService.getPayors(filter, projection).toPromise();


    if (imesResponse.status === 200) {
      this.imes = imesResponse.data.array;
      this.dataSource = new MatTableDataSource(this.imes);
    }
    this.loader.stopLoader(this.loaderId);
    
  }
  goBack() {
    this._location.back();
  }

  onDeleteClick(payor) {
    if (!confirm('Are you sure you want to delete?')) {
        return;
    }

    this.companyService.deletePayor(payor._id).subscribe((response: any) => {
        if (response.status === 200) {
            this.initIMEs();
        }
    })
  }
}
