import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyLogsService } from './company-logs-module.service';

@Component({
  selector: 'app-company-logs-module',
  templateUrl: './company-logs-module.component.html',
  styleUrls: ['./company-logs-module.component.css']
})
export class CompanyLogsModuleComponent implements OnInit {
  currentUser: any;
  displayedColumns: string[] = ['error_code', 'error_msg','support_msg', 'action'];
  dataSource: any;
  selectedErrorCode: any = []; 
  errorCode: any = '';
  showLogs: boolean = false;
  constructor(
    private authService: AuthService,
    private companyLogsService: CompanyLogsService
  ) {
    this.currentUser = this.authService.currentUser;
   }

  ngOnInit(): void {
    this.companyLogsService.getAllErrorLogs().subscribe((data: any) => {
      this.dataSource = data.error_codes;
    })
  }

  onSelectErrorLog(error_code) {
    if(this.errorCode != error_code) {
      this.errorCode = error_code
      this.companyLogsService.getSelectedLogs(error_code).subscribe((data: any) => {
          this.selectedErrorCode = data.error_logs;
          this.showLogs = true;      
      })
    }
    else {
      this.showLogs = true;
    }

    
  }

  closeSideBar() {
    this.showLogs = false;
  }

}
