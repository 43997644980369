import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment'
import * as global from '../../../../global';
import { ToastrService } from 'ngx-toastr';
import { Observable, lastValueFrom } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FacilityService } from 'src/app/services/facility.service';
import { Location } from '@angular/common';
import { WoundService } from 'src/app/services/wound.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { SnfWcNurseAssociationService } from 'src/app/includes/snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { NursingService } from 'src/app/includes/nursing-module/nursing.service';
import { WoundNurseRelationService } from 'src/app/services/Modules/woundNurse.service';
import { CommonService } from 'src/app/services/common.service';
// import * as jsPDF from 'jspdf';
// import autoTable from 'jspdf-autotable';

// import html2canvas from 'html2canvas';

@Component({
  selector: 'app-dns-healing-report',
  templateUrl: './dns-healing-report.component.html',
  styleUrls: ['./dns-healing-report.component.css']
})
export class DnsHealingReportComponent implements OnInit {

  currentDate = moment(new Date()).add(1, 'days');
  minDate = moment(new Date()).subtract(6, 'days');
  loaderId = 'app-dns-report';
  filter: any = {
    updationDate: {
      // startDate: moment().subtract(6, 'days'),
      // endDate: moment(new Date()).add(1,'days')
      startDate: moment(),
      endDate: moment()
    },
    facilityId: null,
    companyId: null,
    state: 'all',
    dateType: 'last_assessment_date_obj',
    orientation: 'landscape'
  };
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 1 Week': [moment().subtract(6, 'days'), moment()],
    // 'Last 2 Week': [moment().subtract(13, 'days'), moment()],
    // 'Last 3 Week': [moment().subtract(20, 'days'), moment()],
    // 'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    // 'This Month': [moment().startOf('month'), moment().endOf('month')],
    // 'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  filteredFacilities: Observable<string[]>;
  displayedColumns: string[] = [
    'room_number', // Room #
    'name', // Last Name, First Name
    'body_part', // Wound Location
    // 'woundNo', // #
    'etiolgy', // Etiology
    'stage', // Severity
    'last_admit_date', // Facility Admission Date
    // 'first_evaluation_date', // First Evaluation Date
    'latest_evaluation_date', // Latest Evaluation Date
    // 'state', // Status
    // 'diffInWeek', // Days in Tx
    // 'area_change', // Area Percent Change
    'length', // Lenth
    'width', // Width
    'depth', // Depth
    // 'icds' // DX10 Codes
    'wound_status',
    'wound_recognition_date_type', // Present on Admission
    // 'on_set_date',
    'wound_recognition_date', // Date Acquired

    // 'pressure_reduction/offloading', // Pressure Reduction/Offloading
    'dressing_change_frequency', // Dressing Change Frequency
    'cleanse_wound_with', // Cleanse Wound With
    // 'dressings', // Dressings,
    'primary_treatment', //Primary Treatment
    'secondary_dressings', // Secondary Dressings
  ];

  facilityControl = new FormControl();
  facilities: Array<any> = [];
  dnsReport: any = [];
  dataSource: any = new MatTableDataSource([]);
  poaCount: number = 0;
  facilityAcquiredCount: number = 0;
  openWoundsCount: number = 0;
  inRemissionWoundsCount: number = 0;
  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    // totalPages: 0,
    // currentRecords: 0
    PAGE_LIMIT: 25,
    skip: 0
  };
  currentUser: any;
  companySide: boolean;
  nursingSide: boolean;
  user_dashboard: Boolean = false;
  title: string = 'DNS Healing Report';
  isSnfNurse: boolean = false;
  isNurse: boolean = false;
  isWoundNurse: boolean = false;
  nurseAssociatedFacilities: Array<any> = [];
  currentCompnay : any = {};
  facilityInputText: string = '';
  timeoutId: any;

  constructor(
    private router: Router,
    private _toastr: ToastrService,
    private _facilityService: FacilityService,
    private _route: ActivatedRoute,
    private _location: Location,
    private _wound: WoundService,
    private loader: NgxUiLoaderService,
    private _authService: AuthService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
    private _nurseService: NursingService,
    private woundNurseService: WoundNurseRelationService,
    private commonService: CommonService

  ) {
    this.currentUser = this._authService.currentUser;
    this.filter.companyId = this._route.parent.snapshot.params.id;
    if (this._route.parent.snapshot.params.id) {
      this.companySide = true;
    } else {
      this.filter.companyId = this.currentUser.company_id;
    }
    if (window.location.pathname.includes('dashboard')) {
      this.user_dashboard = true;
    }
    if (window.location.pathname.includes('nursing')) {
      this.nursingSide = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      this.isSnfNurse = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.NURSE) {
      this.isNurse = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
      this.isWoundNurse = true;
    }
    
  }

  async ngOnInit() {
    const currentCompany = this.commonService.getLocalStorageItem('admin-Dashboard');
      if(currentCompany) {
        this.currentCompnay = currentCompany;
      }
    this.loader.startLoader(this.loaderId);
    if (this.currentUser.company_type.toLowerCase() == "wound" || this.currentCompnay.currentCompanyType === global.COMPANY_TYPE.SNF) {
      this.title = 'Post Rounds Report'
    }
    await this.initFacilities();
    this.selectDefaultFacility();
    this.initDnsHealingReport();
    this.filteredFacilities = this.facilityControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => this.filterFacilities(value)));
    this.loader.stopLoader(this.loaderId);
  }
  private filterFacilities(value: string): string[] {
    if (this.isSnfNurse || this.isNurse || this.isWoundNurse) {
      if (value) {
        const filterValue = value.toLowerCase();
        return this.nurseAssociatedFacilities.filter(option => option.title.toLowerCase().indexOf(filterValue) > -1);
      }
      else {
        return this.nurseAssociatedFacilities;
      }
    } else {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) !== -1);
    }
    else {
      return this.facilities;
    }
    }
  }
  selectDefaultFacility() {
    if (this.isSnfNurse || this.isNurse || this.isWoundNurse) {
      if (this.nurseAssociatedFacilities.length > 0) {
        this.facilityControl.setValue(this.nurseAssociatedFacilities[0].title);
        this.filter.facilityId = this.nurseAssociatedFacilities[0]._id
        this.facilityControl.setValue(this.nurseAssociatedFacilities[0].title);
      }
    } else{
    if (this.facilities.length > 0) {
      this.filter.facilityId = this.facilities[0]._id;
    }
    this.facilityControl.setValue(this.facilities[0].title);
    }
  }
  initDnsHealingReport() {
    this.loader.startLoader(this.loaderId);
    this.filter.pageNo = this.pagination.pageNo;
    if(this.filter.updationDate) {
      let dateObj = JSON.parse(JSON.stringify(this.filter.updationDate));
      dateObj.startDate = moment(dateObj.startDate);
      dateObj.endDate = moment(dateObj.endDate);
      this.filter.updationDate = dateObj;
    }
    this._wound.getDnsHealingReport(this.filter).subscribe((response: any) => {
      if (response.status == 200) {
        this.dnsReport = response.data.dnsReport;
        this.pagination.totalRecords = response.data.totalRecords;
        this.handlePagination();
        [this.inRemissionWoundsCount, this.openWoundsCount,
        this.poaCount, this.facilityAcquiredCount] = [0, 0, 0, 0];
        if (this.dnsReport.length > 0) {
          for (const report of this.dnsReport) {
            report.createdAt = moment(report.createdAt).format("MMM DD, YYYY");
            if (report.wound_recognition_date_type === "yes") {
              this.facilityAcquiredCount++;
            }
            else {
              this.poaCount++;
            }
            if (report.state === "active") {
              this.openWoundsCount++;
            }
            else if (report.state === "remission") {
              this.inRemissionWoundsCount++;
            }
          }
        }
        this.dataSource.data = this.dnsReport;
        this.loader.stopLoader(this.loaderId);
      }
      else {
        this.loader.stopLoader(this.loaderId);

      }
    }, err => {
      this.loader.stopLoader(this.loaderId);
      this._toastr.error(err, "ERROR");
    }
    );
  }
  handlePagination() {
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    if (this.pagination.skip + this.dnsReport.length < this.pagination.totalRecords) {
      this.pagination.hasNextPage = true;
    }
    else {
      this.pagination.hasNextPage = false;
    }
    if (this.pagination.skip > 0) {
      this.pagination.hasPrevPage = true;
    }
    else {
      this.pagination.hasPrevPage = false;
    }
  }

  async initFacilities() {
    if (this.isSnfNurse) {
      const filter = {
        associated_snf_wc_nurse_ids: this.currentUser._id,
        company_id: this._authService.currentUser.company_id
      };
      const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      const facilityProjection = {
        title: 1,
        pcc_facId: 1,
        pcc_2_legged_authentication: 1,
        source: 1
      };
      const response: any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
      if (response.status === 200 && response.data.length > 0) {
        this.nurseAssociatedFacilities = response.data;
        this.facilities = this.nurseAssociatedFacilities.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
      }
    }
    else if(this.isNurse) {
      const response: any = await lastValueFrom(this._nurseService.getAssociatedFacilities());
      if (response.status === 200 && response.data.array.length > 0) {
        this.nurseAssociatedFacilities = response.data.array;
        console.log("this.nurseAssociatedFacilities", this.nurseAssociatedFacilities)
        this.nurseAssociatedFacilities.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
        this.facilities = this.nurseAssociatedFacilities;
      }
    } else if (this.isWoundNurse) {
      const filter = {
        company_id_ref: this.filter.companyId,
        associated_wound_nurse_ids: this._authService.currentUser._id
      };
      const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      const facilityProjection = {
        title: 1,
        pcc_facId: 1,
        pcc_2_legged_authentication: 1,
        source: 1
      };
      const facilitiesResponse: any = await lastValueFrom(this.woundNurseService.getWoundNurseFacilities(filter, nurseProjection, facilityProjection));
      if (facilitiesResponse.status === 200) {
        this.nurseAssociatedFacilities = facilitiesResponse.data;
        this.facilities = this.nurseAssociatedFacilities.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
      }
    }
    else{
      const filter = {
        company_id: this.filter.companyId,
        is_active: true
      };
      const projection = {
        title: 1
      };
      const facilitiesResponse: any = await this._facilityService.getMyFacilities(this.currentUser._id).toPromise();
      if (facilitiesResponse.status == 200) {
        this.facilities = this.sortTitleVise(facilitiesResponse.data.array);
      }
    }
  }
  sortTitleVise(arrayTosort) {
    let facilities = arrayTosort;
    let result = facilities.sort(function (a, b) {
      if (a.title?.toLowerCase() < b.title?.toLowerCase()) { return -1; }
      if (a.title?.toLowerCase() > b.title?.toLowerCase()) { return 1; }
      return 0;
    })
    return result;
  }
  onSelectFacility($event, facility) {
    if ($event.isUserInput) {
      this.filter.facilityId = facility._id;
    }
  }
  changeUpdationDate(event) {
    if (event.startDate && event.endDate) {
      this.filter.updationDate = {
        startDate: null,
        endDate: null
      };
      const noOfDays = this.commonService.getDifferenceOfDates(event.startDate,event.endDate,'days');
      if (noOfDays > 6) {
        this.filter.updationDate = {};
        this.filter.updationDate.startDate = moment(event.startDate);
        this.filter.updationDate.endDate = moment(this.filter.updationDate.startDate).add(6, "days").endOf('day');
        this._toastr.error('Can not select more than 7 days', 'Date Error');
      }
      else {
        this.filter.updationDate.startDate = event.startDate;
        this.filter.updationDate.endDate = event.endDate;
      }
    }
  }

  goBack() {
    this._location.back();
  }
  handleApplyFilter() {
    this.facilityInputText = (<HTMLInputElement>document.getElementById('facilityControlInput')).value;
    console.log("this.facili tyInput Text : ",this.facilityInputText);

    if(this.facilityInputText==''){
      this._toastr.warning('Please select facility from list', 'Warning');
      return;
    }else{
      if (this.isSnfNurse) {
        const exists = this.nurseAssociatedFacilities.filter(option => option.title.toLowerCase().trim()==this.facilityInputText.toLowerCase().trim());
        if(!exists.length){
          this._toastr.warning('Please select facility from list', 'Warning');
          return;
        }
      }else{
        const exists = this.facilities.filter(option => option.title.toLowerCase().trim()==this.facilityInputText.toLowerCase().trim());
        if(!exists.length){
          this._toastr.warning('Please select facility from list', 'Warning');
          return;
        }
      }
    }

    this.pagination = {
      pageNo: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalRecords: 0,
      PAGE_LIMIT: 25,
      skip: 0
    };
    this.initDnsHealingReport();
  }

  calculateArea(ele) {
    if (ele.earliestRevisionArea && ele.recentWound) {
      return 100 - ((ele.recentWound.recentRevisionArea / ele.earliestRevisionArea) / 100)
    }
    return ele.earliestRevisionArea;
  }
  onStateChange(value) {
    this.filter.state = value;
  }
  resetFilter() {
    delete this.filter.updationDate;
    this.filter.updationDate = {
      startDate: moment(),
      endDate: moment()
    };
    this.filter.orientation = 'landscape';
    this.filter.dateType = 'last_assessment_date_obj';
    this.filter.state = "all";
    this.selectDefaultFacility();
    this.initDnsHealingReport();
  }

  onOrientationChange(value) {
    this.filter.orientation = value;
  }
  async exportDnsHealingReportAsXLSX(value, exportOption: string) {
    this.filter.orientation = value;
    let selectedFacilityName: any;
    if (this.isSnfNurse || this.isNurse || this.isWoundNurse) {
      selectedFacilityName = this.nurseAssociatedFacilities.find((f) => f._id.toString() === this.filter.facilityId.toString());
    }
    else {
      selectedFacilityName = this.facilities.find((f) => f._id.toString() === this.filter.facilityId.toString());
    }
    delete this.filter.pageNo;
    const infoToastr = this._toastr.info(exportOption === 'download' ? 'Exporting as XLSX...' : 'Sending via email...',
      'Please wait', { disableTimeOut: true, });
    this.filter.facilityName = selectedFacilityName.title
    const response: any = await lastValueFrom(this._wound.exportDnsHealingReportAsXLSX({ ...this.filter, exportOption }));
    /**********Amazon API Gateway Timeouts in 30s ********/
    clearTimeout(this.timeoutId);
    if (exportOption === 'email') {
      this.timeoutId = setTimeout(() => {
        this._toastr.clear(infoToastr.toastId);
        this._toastr.success("Post Rounds Report has been sent to your email");
      }, 1500);
    }
    if (exportOption === 'download') {
      if (response.body instanceof Blob) {
        this._toastr.clear(infoToastr.toastId);
        const url = window.URL.createObjectURL(response.body);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = this.title;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this._toastr.clear(infoToastr.toastId);
      }
      else if (response.status === 504) {
        this._toastr.clear(infoToastr.toastId);
        this._toastr.error("Maximum limit reached, send via email.");
      }
    }
  }

  exportDnsHealingReportAsPDF(value) {
    this.filter.orientation = value;
    let selectedFacilityName: any;
    if (this.isSnfNurse || this.isNurse || this.isWoundNurse) {
      selectedFacilityName = this.nurseAssociatedFacilities.find((f) => f._id.toString() === this.filter.facilityId.toString());
    }
    else {
      selectedFacilityName = this.facilities.find((f) => f._id.toString() === this.filter.facilityId.toString());
    }
    console.log(selectedFacilityName);
    const dateRangeString = moment(this.filter.updationDate.startDate).tz(this._authService.currentUser.timezone).format('MM/DD/YYYY')
    + "-" + moment(this.filter.updationDate.endDate).tz(this._authService.currentUser.timezone).format('MM/DD/YYYY');
    console.log(dateRangeString, typeof(dateRangeString))
    delete this.filter.pageNo; //To export all
    let reportName = selectedFacilityName.title + "_" + dateRangeString + "_Wound Report";
    console.log(reportName)
    const infoToastr = this._toastr.info('Exporting as PDF...', 'Please wait', { disableTimeOut: true });
    this.filter.facilityName = selectedFacilityName.title
    this._wound.exportDnsHealingReportAsPDF(this.filter).subscribe((response: any) => {
      if (response instanceof HttpResponse) {
        const url = window.URL.createObjectURL(response.body);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = this.title + '.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
        this._toastr.clear(infoToastr.toastId);
      }
    }, error => {
      console.log("err", error);
      this._toastr.clear(infoToastr.toastId);
    });
  }
  goToPreviousPage() {
    this.pagination.pageNo--;
    this.initDnsHealingReport();
  }
  goToNextPage() {
    this.pagination.pageNo++;
    this.initDnsHealingReport();
  }
  getLastAdmitDate(element) {
    if (element.patient_id && element.patient_id.pcc_payload) {
      let dateArray = element.patient_id.pcc_payload.admissionDate.split('-');
      return `${dateArray[1]}-${dateArray[2]}-${dateArray[0]}`;
    } else if (element.patient_id && element.patient_id.matrix_payload && this.commonService.isValidDateObj(element.patient_id.matrix_payload)) {
      let {
        year,
        month,
        day
      } = element.patient_id.matrix_payload.last_admit_date_obj;
      if (month < 10) month = `0${month}`;
      if (day < 10) day = `0${day}`;
      return `${month}-${day}-${year}`;
    } else {
      if (element.patient_id && element.patient_id.last_admit_date_obj && this.commonService.isValidDateObj(element.patient_id.last_admit_date_obj)) {
        let {
          year,
          month,
          day
        } = element.patient_id.last_admit_date_obj;
        if (month < 10) month = `0${month}`;
        if (day < 10) day = `0${day}`;
        return `${month}-${day}-${year}`;
      } else {
        return '';
      }
    }
  }
  getFirstEvaluationDate(element) {
    if (element?.initialNote?.date_obj && this.commonService.isValidDateObj(element?.initialNote?.date_obj)) {
      let {
        year,
        month,
        day
      } = element.initialNote.date_obj;
      if (month < 10) month = `0${month}`;
      if (day < 10) day = `0${day}`;
      return `${month}-${day}-${year}`;
    }
  }
  getWoundRecognition(date, check) {
    if (check) {
      return 'Undetermined';
    } else {
      return date;
    }
  }
  getLatestEvaluationDate(element) {
    
    if (element?.recentNote?.date_obj && element?.last_assessment_date_obj) {
      let { year, month, day } = element.recentNote.date_obj;
      let assessmentYear = element.last_assessment_date_obj.year;
      let assessmentMonth = element.last_assessment_date_obj.month;
      let assessmentDay = element.last_assessment_date_obj.day;
      if (month < 10) month = `0${month}`;
      if (day < 10) day = `0${day}`;
      if (assessmentMonth < 10) assessmentMonth = `0${assessmentMonth}`;
      if (assessmentDay < 10) assessmentDay = `0${assessmentDay}`;
      const dateObject1 = new Date(year, month - 1, day);
      const dateObject2 = new Date(assessmentYear, assessmentMonth - 1, assessmentDay);     
      if (dateObject1 > dateObject2) {
        return `${month}-${day}-${year}`;
      } else {
        return `${assessmentMonth}-${assessmentDay}-${assessmentYear}`;
      }
    }else if(element.last_assessment_date_obj || element?.recentNote?.date_obj){
      let {
        year,
        month,
        day
      } = element.last_assessment_date_obj ? element.last_assessment_date_obj : element?.recentNote?.date_obj;
      if (month < 10) month = `0${month}`;
      if (day < 10) day = `0${day}`;
      return `${month}-${day}-${year}`;
    }
  }
}

