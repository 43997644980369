import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from "../../../../../environments/api-url";
import * as global from '../../../global';


@Injectable({
  providedIn: 'root'
})
export class ConsentService {

  constructor(
    private httpclient: HttpClient,
    private _authService: AuthService,
  ) { }


  // isCompanyEmailVisible$ = new BehaviorSubject<any>(false);
  // castIsCompanyVisible = this.isCompanyEmailVisible$.asObservable();

  // changeIsCompanyEmailVisible(isVisible) {
  //   this.isCompanyEmailVisible$.next(isVisible);
  // }

  updateConsentForm(consentForm) {
    return this.httpclient.post(global.url + API_URL.CONSENT.updateConsentForm, consentForm);
  };
  getConsentForms(filter, project) {
    return this.httpclient.post(global.url + API_URL.CONSENT.getConsentForms, { filter, project });
  };
  createConsentForm(payload) {
    return this.httpclient.post(global.url + API_URL.CONSENT.createConsentForm, payload);
  };
  deleteConsentForm(consentFormId) {
    return this.httpclient.post(global.url + API_URL.CONSENT.deleteConsentForm, { consentFormId });
  };
  createConsentFormAuditAndUpload(payload) {
    return this.httpclient.post(global.url + API_URL.CONSENT.createConsentFormAuditAndUpload, payload);
  };
  getConsentAudit(filter, project) {
    return this.httpclient.post(global.url + API_URL.CONSENT.getConsentAudit, { filter, project });
  };
}
