import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import swal from 'sweetalert';

export interface EligibilityDialogData {
  eligibilityIcon: string;
  eligibilityStatus: string;
  eligibilityData: any;
}


@Component({
  selector: 'app-eligibility-dialog',
  templateUrl: './eligibility-dialog.component.html',
  styleUrls: [ './eligibility-dialog.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class EligibilityDialog implements OnInit {
  public eligibilityIcon: string = '';
  public eligibilityStatus: string = '';
  public eligibilityData: any = null;
  public eligibilityAlerts: string[] = [];
  public selectedServiceType: object = null;
  public showDetailedBenefits: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EligibilityDialog>,
    @Inject(MAT_DIALOG_DATA) public data: EligibilityDialogData
) {}

  ngOnInit(): void {
    const {
      eligibilityIcon,
      eligibilityStatus,
      eligibilityData
    } = this.data;

    this.eligibilityIcon = eligibilityIcon;
    this.eligibilityStatus = eligibilityStatus;
    this.eligibilityData = eligibilityData;
    this.selectedServiceType = eligibilityData.ServiceTypes?.find( serviceType => ( serviceType.ServiceTypeCode === eligibilityData.extraProps.selectedServiceTypeCode )) ?? null;

    this.eligibilityAlerts = ( eligibilityData.Alerts ?? [])
      .map(({ Alert: text }) => text )
      .filter( text => ( text != null && text.trim() !== '' ));
  }

  showAlerts() {
    if ( this.eligibilityAlerts.length === 0 ) {
      return;
    }

    swal( this.eligibilityAlerts.join( '\n\n' ));
  }

  resolveGender( gender ) {
    const genderMap = {
      'M': 'Male',
      'F': 'Female'
    };

    return genderMap[ gender ] ?? gender;
  }

  toggleDetailedBenefits() {
    this.showDetailedBenefits = !this.showDetailedBenefits;
  }
}
