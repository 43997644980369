<div style="text-align: center;">
<h2 mat-dialog-title>Feedback Detail</h2>
<div style="width: 100%; height: 100%;">
    <div *ngIf="imageType">
        <img [src]="imageUrl" style="width: 60%;height: auto;" alt="feedback-image">
    </div>
    <div>
        <video [src]="videoUrl" *ngIf="videoType" style="width: 100%;height: auto;" controls></video>
    </div>
<br>
    <div>
        <b>Comment</b> <br>
        {{data.text}}
    </div>
</div>
<mat-dialog-action>
    <br>
    <button mat-raised-button color="primary" mat-dialog-close (click)="approve(true)">Approve</button>&nbsp;&nbsp;&nbsp;&nbsp;
    <button mat-raised-button color="warn" mat-dialog-close (click)="approve(false)">Reject</button>
</mat-dialog-action>
</div>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close ><mat-icon >cancel</mat-icon></button>
  </div>