<div class="h100 flex xs:flex-col justify-center items-center">
  <mat-card class="w-2/5 xs:w-full">

    <mat-card-title class="flex flex-col justify-center items-center">
      <img src="assets/img/DNlogo-new.png">
      <h2>Email verification</h2>
    </mat-card-title>

    <mat-card-content class="flex flex-col">

      <form (submit)="checkVerificationCode(verifyForm)" #verifyForm="ngForm">
        
        <div class="flex flex-col justify-center">
        <mat-error *ngIf="status != 200 && status!= null">Invalid Code</mat-error>

        <div class="passcode-row">Six digit verification code has been sent to your email or phone number</div>

          <div class="passcode-row">
            <mat-form-field>
              <input autofocus matInput type="password" name="code" placeholder="Verification code" maxlength="4" #password="ngModel" ngModel
                required>
              <mat-error *ngIf="password.invalid">Please enter verification code</mat-error>
            </mat-form-field>
          </div>
          
        </div>

        <div class="flex justify-center items-center">
          <button class="theme-btn w-1/2" mat-stroked-button type="submit">Verify</button>
        </div>

      </form>

    </mat-card-content>

  </mat-card>
</div>