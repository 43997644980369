import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { MipsService } from 'src/app/services/Modules/mips.service';
import { AddMipsQuestionComponent } from '../add-mips-question/add-mips-question.component';
import { ToastrService } from 'ngx-toastr';
import Chart from 'chart.js/auto'
export interface MeasureIDQuestionsDialogData {
  measureElement:any,
  company_id:string
}


@Component({
  selector: 'app-measure-number-questions',
  templateUrl: './measure-number-questions.component.html',
  styleUrls: ['./measure-number-questions.component.css']
})
export class MeasureNumberQuestionsDialog implements OnInit {

  measureElement:any;
  company_id:string;
  questions:string[];
  currentUser:any;

  // displayedColumns: any = ['question', 'answer','modifier','condition','performance_type','data_source' ,'sub-questions', 'operations'];
  displayedColumns: any = ['question', 'answer','condition','performance_type','data_source' ,'sub-questions', 'operations'];
  

  @ViewChild(MeasureNumberQuestionsDialog) measureIDQuestions:MeasureNumberQuestionsDialog;

  dataSource:MatTableDataSource<any>;
  loaderId = 'measure-number-questions-loader';
  navigationStack: any[] = [];
  mipsGraphData: any = [];
  canvas: any;
  ctx: any;
  @ViewChild('barChart') barChart: any;
  chart: Chart;

  constructor(
    private toast: ToastrService,
    private mipsService: MipsService,
    private loader: NgxUiLoaderService, 
    private dialog: MatDialog,
    public authService: AuthService,
    public dialogRef: MatDialogRef<MeasureNumberQuestionsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      console.log('data....', data)
      if(data.type !== 'detail') {
        this.measureElement = data.measureElement;
        this.company_id = data.company_id;
      }
      this.currentUser = this.authService.currentUser;
  }

  ngOnInit() {
    if(this.data.type !== 'detail'){
      this.initQuestions();
    }
    else {
      this.initMipsDetailGraph();
    }
    
  }

  initMipsDetailGraph() {
    this.mipsService.getMipsDetails(this.data.company_id, this.data.mipsData).subscribe((res: any) => {
      console.log('resres', res)
      if(res.status == 200) {
        this.mipsGraphData = res.mipsCount;
        this.createGraph();
      }
    })
  }

  createGraph() {
    this.canvas = this.barChart.nativeElement;
    this.ctx = this.canvas.getContext('2d');
    if (this.chart) {
      this.chart.destroy();
    }
    const colors: string[] = [];
    for(let data of this.mipsGraphData) {
      const red = Math.floor(Math.random() * 256);
      const green = Math.floor(Math.random() * 256);
      const blue = Math.floor(Math.random() * 256);
      const alpha = Math.random().toFixed(2); // Random alpha value between 0 and 1

      const rgbaColor = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
      colors.push(rgbaColor);
    }
    this.chart = new Chart(this.ctx, {
      type: 'bar',
      data: {
        datasets: [{
          label: 'MIPS Count',
          data: this.mipsGraphData.map(form => form.Count),
          backgroundColor: colors,
          borderColor: 'rgba(0, 0, 0, 1)',
          borderWidth: 1
        },
        ],
        labels: this.mipsGraphData.map(form => `${form.last_name}, ${form.first_name}`)
      },
    });
  }
  
  initQuestions() {
    this.loader.startLoader(this.loaderId);

    this.mipsService.getQuestions(this.measureElement.measure_number, this.measureElement._id, this.company_id).subscribe((response:any) => {
      if(response.status === 200) {
        this.questions = response.data.array;
        this.initTableDataSource()
      }
      this.loader.stopLoader(this.loaderId);
    })
  }
  initTableDataSource() {
    for (let i = 0; i < this.questions.length; i++) {
      const question:any = this.questions[i];
      question.sortOrder = i;
    }
    this.dataSource = new MatTableDataSource(this.questions);
  }

  editQuestion(mipsQuestionObj){
    const dialogRef:MatDialogRef<AddMipsQuestionComponent> = this.dialog.open(AddMipsQuestionComponent, {width: '80%', 
      data: {
        measure_number: this.measureElement.measure_number,
        company_id:this.company_id,
        mipsQuestionObj:mipsQuestionObj
      }}
    )

    dialogRef.afterClosed().subscribe(async (mipsQuestion) => {
      if (!mipsQuestion || (mipsQuestion && (mipsQuestion.measure_number.length === 0 || mipsQuestion.question.length === 0) )) {
          return;
      }
      console.log(mipsQuestion)
      
      const response:any = await this.mipsService.updateMIPSQuestion(this.company_id, mipsQuestionObj._id, mipsQuestion).toPromise();

      if(response.status === 200) {
        this.toast.success('Question updated successfully');
        this.initQuestions();
      }
    });
  }

  deleteQuestion(mipsQuestionId) {
    this.mipsService.deleteQuestion(mipsQuestionId, this.company_id).subscribe((response:any) => {
      if(response.status === 200) {
        this.initQuestions();
        this.measureElement.questions_count--;
      }
    })
  }
  onListDrop(event: CdkDragDrop<any[]>) {
    // Swap the elements around
    this.questions = this.dataSource.data
    moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
 
    this.initTableDataSource();
    this.mipsService.updateQuestionsSortOrder(this.questions.map((q:any) => ({_id: q._id, sortOrder: q.sortOrder })),this.company_id)
    .subscribe((response:any) => {
      // this.filterSort = 'user_sort';
    })
    console.log(event)
  }
  showMeasureNumberSubQuestions(question) {
    // this.dialogRef.afterClosed()
    this.navigationStack.push({...this.measureElement, question: question.question});
    this.measureElement = question;
    this.initQuestions();
  }
  goToParentQuestion() {
    if(this.navigationStack.length > 0) {
      const question = this.navigationStack.pop();
      

      this.measureElement = question;
      this.initQuestions();
    }
  }
  getParentQuestion() {
    const question = this.navigationStack[this.navigationStack.length-1];
    console.log(question)
    return question;
  }
  handleAddQuestions(){ 
    const dialogRef:MatDialogRef<AddMipsQuestionComponent> = this.dialog.open(AddMipsQuestionComponent, {width: '80%', data: {measure_number: this.measureElement.measure_number,company_id:this.company_id}})
    dialogRef.afterClosed().subscribe(async (mipsQuestion) => {
      if (!mipsQuestion || (mipsQuestion && (mipsQuestion.measure_number.length === 0 || mipsQuestion.question.length === 0) )) {
          return;
      }
      console.log(mipsQuestion)
      
      const response:any = await this.mipsService.addQuestion(mipsQuestion.measure_number, this.measureElement._id, mipsQuestion.question, mipsQuestion.answer, this.company_id, mipsQuestion.condition, mipsQuestion.data_source, mipsQuestion.performance_type, mipsQuestion.modifier).toPromise();

      if(response.status === 200) {
        this.measureElement.questions_count++;
        this.initQuestions();
      }
    });
  }
}


