<div class="table-height">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

        <ng-container matColumnDef="totalWounds">
            <th mat-header-cell *matHeaderCellDef>Total Wounds</th>
            <td mat-cell *matCellDef="let element; let i = index;">
                {{element.active}}
            </td>
        </ng-container>


        <ng-container matColumnDef="improving">
            <th mat-header-cell *matHeaderCellDef>Improving</th>
            <td mat-cell *matCellDef="let element; let i = index;">
                {{element.improving}}
            </td>
        </ng-container>

        <ng-container matColumnDef="stalled">
            <th mat-header-cell *matHeaderCellDef> Stalled </th>
            <td mat-cell *matCellDef="let element; let i = index;">
                {{element.stalled}}
            </td>
        </ng-container>

        <ng-container matColumnDef="deteriorating">
            <th mat-header-cell *matHeaderCellDef> Deteriorating </th>
            <td mat-cell *matCellDef="let element; let i = index;">
                {{element.deteriorating}}
            </td>
        </ng-container>

        <ng-container matColumnDef="inRemission">
            <th mat-header-cell *matHeaderCellDef> In Remission </th>
            <td mat-cell *matCellDef="let element; let i = index;">
                {{element.inRemission}}
            </td>
        </ng-container>

        <ng-container matColumnDef="amputations">
            <th mat-header-cell *matHeaderCellDef> Amputations </th>
            <td mat-cell *matCellDef="let element; let i = index;">
                {{element.amputated}}
            </td>
        </ng-container>

        <ng-container matColumnDef="healingSuccess">
            <th mat-header-cell *matHeaderCellDef> Healing Success </th>
            <td mat-cell *matCellDef="let element; let i = index;">
                {{element.healingSuccessRate}}
            </td>
        </ng-container>

        <ng-container matColumnDef="avgDaysToRemission">
            <th mat-header-cell *matHeaderCellDef> Avg Days To Remission </th>
            <td mat-cell *matCellDef="let element; let i = index;">
                {{(element?.wounds?.avgDaysToRemission ? element?.wounds?.avgDaysToRemission : 0) | number : '1.0-2'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="skinTear">
            <th mat-header-cell *matHeaderCellDef> Skin Tear </th>
            <td mat-cell *matCellDef="let element; let i = index;">
                {{element.skinTearRate}}
            </td>
        </ng-container>

        <ng-container matColumnDef="pressure">
            <th mat-header-cell *matHeaderCellDef> Pressure </th>
            <td mat-cell *matCellDef="let element; let i = index;">
                {{element.pressureRate}}
            </td>
        </ng-container>

        <ng-container matColumnDef="diabetic">
            <th mat-header-cell *matHeaderCellDef> Diabetic </th>
            <td mat-cell *matCellDef="let element; let i = index;">
                {{element.diabetesRate}}
            </td>
        </ng-container>

        <ng-container matColumnDef="venous">
            <th mat-header-cell *matHeaderCellDef> Venous </th>
            <td mat-cell *matCellDef="let element; let i = index;">
                {{element.venousRate}}
            </td>
        </ng-container>

        <ng-container matColumnDef="arterial">
            <th mat-header-cell *matHeaderCellDef> Arterial </th>
            <td mat-cell *matCellDef="let element; let i = index;">
                {{element.arterialRate}}
            </td>
        </ng-container>

        <ng-container matColumnDef="masd">
            <th mat-header-cell *matHeaderCellDef> MASD </th>
            <td mat-cell *matCellDef="let element; let i = index;">
                {{element.masdRate}}
            </td>
        </ng-container>

        <ng-container matColumnDef="other">
            <th mat-header-cell *matHeaderCellDef> Other </th>
            <td mat-cell *matCellDef="let element; let i = index;">
                {{element.othersRate}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>