import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment'
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from 'src/environments/api-url';
import * as global from '../../includes/global';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  currentUser: any;

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private httpclient: HttpClient,
    private toastr: ToastrService,
  ) {
    this.currentUser = this._authService.currentUser;
   }

   getreportData(filter, noteStatus, company_id = null){
    var clonedFilter = JSON.parse(JSON.stringify(filter)); 
    if(clonedFilter && clonedFilter.startDate && clonedFilter.endDate){
      clonedFilter.startDate = moment(clonedFilter.startDate).toLocaleString();
      clonedFilter.endDate = moment(clonedFilter.endDate).toLocaleString();
  }
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      dateFilter: <any>null,
      noteStatus: <string>null,
      company_id: <string>null,
    };
    item.id = this.currentUser._id, 
    item.auth_key = this.currentUser.auth_key, 
    item.usertype = this.currentUser.user_type, 
    item.dateFilter = clonedFilter;
    item.noteStatus = noteStatus;
    item.company_id = company_id;
    return this.httpclient.post(global.url + API_URL.REPORT.getreportData, { details: item }).pipe();
  }
  getReportDataDict(filter, company_id = null){
    var clonedFilter = JSON.parse(JSON.stringify(filter)); 
    if(clonedFilter && clonedFilter.startDate && clonedFilter.endDate){
      clonedFilter.startDate = moment(clonedFilter.startDate).toLocaleString();
      clonedFilter.endDate = moment(clonedFilter.endDate).toLocaleString();
  }
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      dateFilter: <any>null,
      company_id: <any>null,
    };
    item.id = this.currentUser._id, 
    item.auth_key = this.currentUser.auth_key, 
    item.usertype = this.currentUser.user_type, 
    item.dateFilter = clonedFilter;
    item.company_id = company_id;
    return this.httpclient.post(global.url + API_URL.REPORT.getReportDataDict, { details: item }).pipe();
  }
  getSpecificNoteData(filter, provider, notetype, noteStatus){
    var clonedFilter = JSON.parse(JSON.stringify(filter)); 
    if(clonedFilter && clonedFilter.startDate && clonedFilter.endDate){
      clonedFilter.startDate = moment(clonedFilter.startDate).toLocaleString();
      clonedFilter.endDate = moment(clonedFilter.endDate).toLocaleString();
  }
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      dateFilter: <any>null,
      provider: <string>null,
      notetype: <string>null,
      noteStatus: <string>null,
    };
    item.id = this.currentUser._id,
    item.auth_key = this.currentUser.auth_key, 
    item.usertype = this.currentUser.user_type, 
    item.dateFilter = clonedFilter,
    item.provider = provider,
    item.notetype = notetype,
    item.noteStatus = noteStatus
    return this.httpclient.post(global.url + API_URL.REPORT.getSpecificNoteData, { details: item }).pipe();
  }

  downlaodCSV(csvData){ 
    this.toastr.info('Downloading CSV...');
    const filename = "Notes Report"
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
  }
}
