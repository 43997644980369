<div class="container flex flex-col">
    <h3 class="top-bar flex justify-between items-center">
        <button *ngIf="isCompanySide" type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
            Back
        </button>
        <div class="font">{{filter.reportType === 'avg' ? 'Average Days to Remission'
            : 'Median Days to Remission' }}
        </div>

        <div class="flex justify-center items-end" style="color: black !important;">
            <input matInput type="text" ngxDaterangepickerMd [(ngModel)]="filter.last_assessment_date_obj"
                class="mat-like-input" [ranges]="preDateRanges" [alwaysShowCalendars]="true" [maxDate]="currentDate"
                [linkedCalendars]="true" [showClearButton]="false" (datesUpdated)="handleDateOfServiceChange($event)"
                class="date-range-picker" />
        </div>

        <div class="flex gap-2">
            <mat-icon matTooltip="Download Report" style="cursor: pointer;"
                [matMenuTriggerFor]="sendMenu">download</mat-icon>

            <mat-menu #sendMenu="matMenu" xPosition="before">
                <button mat-menu-item
                    (click)="convertToXLSX(filter.reportType === 'avg' ? avgReport : medianReport);">Export
                    As XLSX</button>

                <button mat-menu-item (click)="convertToPDF();">Export
                    As PDF</button>
            </mat-menu>
        </div>

    </h3>
    <mat-card class="flex flex-col" style="height: calc(99% - 45px);">

        <div class="flex items-center gap-2">

            <mat-form-field appearance="fill" class="w-1/6">
                <mat-label>Days To Remission</mat-label>
                <mat-select (selectionChange)="onChangeReportType($event);" [value]="filter.reportType">
                    <mat-option value="avg">Average Days to Remission</mat-option>
                    <mat-option value="median">Median Days to Remission</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="w-1/2">
                <mat-label>Select Facility</mat-label>
                <mat-select multiple [value]="filter.facilityIds" (closed)="searchText='';">
                    <mat-select-trigger>
                        <div class="flex items-center">
                            <span>{{selectedFacility ? selectedFacility.title : ''}}</span>
                            <span *ngIf="filter.facilityIds.length > 1">
                                (+{{ filter.facilityIds.length - 1 }} {{ filter.facilityIds.length === 2 ? 'other' :
                                'others' }})
                            </span>
                        </div>
                    </mat-select-trigger>
                    <input #searchFacilitiesInput matInput placeholder="Search" [(ngModel)]="searchText"
                        [ngModelOptions]="{standalone: true}"
                        style="color:#000;height: 40px;padding-left: 5px; ; position: sticky; top: 0; z-index:1000; background-color: lightgray; "
                        (keydown)="$event.stopPropagation()" />
                    <mat-option value="all" disabled> All </mat-option>
                    <mat-option (onSelectionChange)="onSelectFacility($event, facility);"
                        *ngFor="let facility of facilities | searchFacility: searchText" [value]="facility._id"
                        style="color: rgb(43, 41, 41);">
                        {{facility.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-raised-button class="dn-button-primary" (click)="initDaysToRemissionReport();"
                [disabled]="filter.facilityIds.length === 0">Apply</button>
        </div>

        <div style="overflow-x: auto;">
            <table id="avgTable" *ngIf="filter.reportType === 'avg'" mat-table [dataSource]="avgReportDataSource"
                class="mat-elevation-z8" matSort>

                <ng-container matColumnDef="etiolgy">
                    <th mat-header-cell *matHeaderCellDef> Primary Etiology </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element._id}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="facilityAvgDaysToRemission">
                    <th mat-header-cell *matHeaderCellDef> Facility Average Days to Remission </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        <a class="hyperlink" matTooltip="Show Patients" (click)="launchPatientNameDialog(element._id);"
                            *ngIf="element.facilityAvgDaysToRemission; else elseBlock">
                            {{element.facilityAvgDaysToRemission}}
                        </a>
                        <ng-template #elseBlock>
                            {{element.facilityAvgDaysToRemission}}
                        </ng-template>
                    </td>
                </ng-container>

                <ng-container matColumnDef="avgDaysToRemission">
                    <th mat-header-cell *matHeaderCellDef> DocNow Average Days to Remission </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.avgDaysToRemission}}
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="avgColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: avgColumns;"></tr>
            </table>

            <table id="medianTable" *ngIf="filter.reportType === 'median'" mat-table
                [dataSource]="medianReportDataSource" class="mat-elevation-z8" matSort>

                <ng-container matColumnDef="etiolgy">
                    <th mat-header-cell *matHeaderCellDef> Primary Etiology </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element._id}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="facilityMedianDaysToRemission">
                    <th mat-header-cell *matHeaderCellDef> Facility Median Days to Remission </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        <a class="hyperlink" matTooltip="Show Patients" (click)="launchPatientNameDialog(element._id);"
                        *ngIf="element.facilityMedianDaysToRemission; else elseBlock">
                        {{element.facilityMedianDaysToRemission}}
                    </a>
                    <ng-template #elseBlock>
                        {{element.facilityMedianDaysToRemission}}
                    </ng-template>
                    </td>
                </ng-container>

                <ng-container matColumnDef="medianDaysToRemission">
                    <th mat-header-cell *matHeaderCellDef> DocNow Median Days to Remission </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.medianDaysToRemission}}
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="medainColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: medainColumns;"></tr>
            </table>
        </div>
        <div class="flex">
            <div class="flex flex-col items-start justify-start gap-4 bottom-col-width">
                <span> {{filter.reportType === 'avg' ? 'Total Average Days to Remission' : 'Total Median Days to
                    Remission'}}
                </span>
                <span> Total Wounds Included</span>
                <span> Total Facilities Included</span>
            </div>
            <div class="flex flex-col items-start justify-center gap-1 bottom-col-width">
                <span> {{ filter.reportType === 'avg' ? totalFacilityAvgDaysToRemission :
                    totalFacilityMedianDaysToRemission}}
                </span>
                <span> {{totalNumberOfWoundsForFacility}}</span>
                <span> {{daysToRemissionFacilitiesCount}}</span>
            </div>
            <div class="flex flex-col items-start justify-end gap-1 bottom-col-width">
                <span> {{ filter.reportType === 'avg' ? totalAvgDaysToRemission : totalMedianDaysToRemission}}</span>
                <span> {{totalNumberOfWounds}}</span>
                <span> {{docNowFacilitiesCount}}</span>
            </div>
        </div>
    </mat-card>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>