<ng-container *ngIf="view === ''"> 
<table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="reportId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Report Id
        </th>
        <td mat-cell *matCellDef="let element">
            {{reportId}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="reportName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Report Name
        </th>
        <td mat-cell *matCellDef="let element">
            {{reportName}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="reportingLaboratory">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Reporting Laboratory
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.reportingLaboratory}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="reportType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            ReportType
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.reportType}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="performingLaboratory">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Performing Laboratory
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.performingLaboratory}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="effectiveDateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Effective Date Time
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.issuereffectiveDateTime |date: 'MM/dd/yyyy'}}
        </td>
    </ng-container>

    <ng-container matColumnDef="issuedDateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Issued Date Time
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.issuedDateTime |date: 'MM/dd/yyyy'}}
        </td>
    </ng-container>
    <ng-container matColumnDef="reportFile">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Report File
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.reportFile}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="reportStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Report Status
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.reportStatus}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="testSet">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Test Set
        </th>
        <td mat-cell *matCellDef="let element">
            <a href="#" (click)="$event.preventDefault(); changeView('testSet', element.testSet)">View</a>
        </td>
    </ng-container>
    <ng-container matColumnDef="comment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Comment
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.testSet[0].results[0].comment}}
        </td>
    </ng-container>
    <ng-container matColumnDef="codeDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Code  Description
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.testSet[0].results[0].codeDescription}}
        </td>
    </ng-container>
    <ng-container matColumnDef="orderingPractitioner">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Ordering Practitioner
        </th>
        <td mat-cell *matCellDef="let element">
            <div *ngIf="element.orderingPractitioner">
                <div>Practitioner Id: {{element.orderingPractitioner.practitionerId}}</div>
                <div>First Name: {{element.orderingPractitioner.firstName}}</div>
                <div>Middle Name: {{element.orderingPractitioner.middleName}}</div>
                <div>Last Name: {{element.orderingPractitioner.lastName}}</div>
                <div>NPI: {{element.orderingPractitioner.npi}}</div>
            </div>
        </td>
    </ng-container>
    
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
    <br />
    <div *ngIf="apiData" class="flex justify-start items-center gap-2">
        <button mat-raised-button class="dn-primary-button" [disabled]="apiData.paging.page === 1" (click)="navigate(-1)">Prev</button>
        <span style="font-size: 12px;">Page {{apiData.paging.page}}</span>
        <button mat-raised-button class="dn-primary-button" [disabled]="!apiData.paging.hasMore" (click)="navigate(1)">Next</button>
    </div>
</ng-container>
<ng-container *ngIf="view === 'testSet'">    
    <button mat-raised-button class="dn-button-secondary" (click)="changeView('', null)">Go Back to Imaging</button>
    <app-patient-diagnostic-reports-test-set  [data]="viewData"></app-patient-diagnostic-reports-test-set>
</ng-container>
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>