import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../../../../includes/global';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import { ReportGenerateService } from '../../report-generate.service';
import { DetailReportService } from './detail-repor.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { API_URL } from 'src/environments/api-url';
import { MatSort, Sort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { threadId } from 'worker_threads';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
// import { elementAt } from 'rxjs/dist/types/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditorComponent } from "@ckeditor/ckeditor5-angular";
import swal from 'sweetalert';

// import { ReportViewComponent } from '../../report-view/report-view.component';
// import { PageEditFormComponent } from '../../../report_generate/page-edit-form/page-edit-form.component';
// @HostListener('scroll', ['$event'])

@Component({
  selector: 'app-detail-repor',
  templateUrl: './detail-repor.component.html',
  styleUrls: ['./detail-repor.component.css'],
  animations: [
    trigger('flipState', [
      state('active', style({
        transform: 'rotateY(179deg)'
      })),
      state('inactive', style({
        transform: 'rotateY(0)'
      })),
      transition('active => inactive', animate('500ms ease-out')),
      transition('inactive => active', animate('500ms ease-in'))
    ])
  ]
})



export class DetailReporComponent implements OnInit {
  Editor = ClassicEditor;
  config = {
    placeholder: 'Type the content here!',
    toolbar: ['heading', '|', 'bold', 'italic', '|', 'NumberedList', 'BulletedList', 'fontSize', 'blockQuote',
      'undo', 'redo'],
  };
  unlocked: Boolean = false;
  addedPage: any = 0;
  document_id: any;
  paramMap: ParamMap;
  displayedColumns: string[] = [];
  dataSource: any;
  loading: boolean = false;
  loadingPage: boolean = false;
  currentUser: any;
  documentJSON: any;
  fileCountError: String = '';
  showImage: Boolean = true;
  row: any = 1;
  selectedRow: any = 1;
  thumbnail: Number = 1;
  table: any = true;
  fullScreen: Boolean = false;
  reportView = false;
  providerList: any = [];
  cover_detail = { person: "", claim_id: "", date_of_incident: "" }
  demarco_note_types = ["Physician Progress Note", "Initial Evaluation Note", "Procedure Note",
    "Physical Therapy Note", "Chiropractic Note", "Acupuncture Note", "X-ray Report",
    "CT-scan Report", "MRI Report", "Independent Medical Examination (IME)",
    "Medical Director Review (MDR)", "Police Report", "Emergency Room Visit"];
  default_exams = [" ", "abdomen", "ankle", "brain", "blood vessels", "cervical spine", "chest", "calf",
    "carpal", "coccyx", "elbow", "femur", "fibula", "foot", "forearm", "finger",
    "heart", "hip", "knee", "lumbar", "liver", "lumbosacral", "lungs", "metatarsals",
    "maxillofacial", "mandible", "metacarpal", "pelvis", "ribs", "sinus routine"
    , "sacroiliac", "sacrum", "shoulder", "sinuses", "scollosis", "thigh", "tibia",
    "tarsal", "head", "hand", "humerus", "neck", "ulna", "wrist"]
  custom_value = "Add New";
  cmc_note_types = ["Acupuncture note", "Anesthesia report",
    "Chiropractic note", "CT scan", "C-3 form", "C-3.3 form", "C-4 form", "C-4.1 form", "C-4.2 form", "C-4.3 form", "C-4 AUTH form", "C-4 AMR form", "C-8.1 form", "Consultation note",
    "EC-4NARR form", "ED note", "Emergency department note", "Evidence based functional evaluation report",
    "Follow-up note",
    "Health insurance claim form",
    "Initial evaluation note", "IME report",
    "Lab report", "Letter of medical necessity", "NIOSH static strength testing report",
    "MRI", "MRA", "MG-2 form", "MG-2.1 form", "Massage therapy note",
    "NeuroMuscular diagnostic", "NCS/EMG note",
    "Occupational therapy", "Outcome assessment testing summary report",
    "Peer review note", "Procedure note", "Progress note", "Physical therapy note", "Physician’s medical order form", "Psychological evaluation note", "Psychiatric evaluation note", "Physiatric evaluation note", "Police accident report",
    "ROM/MMT report", "ROM/CMT report",
    "SOAP note", "Subsequent report of injury", "Sonography",
    "The first report of injury", "The subsequent report of injury",
    "Ultrasound",
    "Varience coordination form",
    "W-9 form", "W-8 form",
    "X-rays"]
  insurance_company: any = ""
  date_type: any = [];
  report: any = [];
  savedReport: any = [];
  // savedReport2:any = [];
  perPageReport: String = "";
  updated: Boolean = true;
  saved: Boolean = false;
  addNewDate: boolean = false;
  addNewProvider: boolean = false;
  doc: any;
  // page:any = 1;
  edit: boolean = false;
  isChecked: boolean = false;
  flip: string = 'inactive';
  isNavigationInProgress = false;
  loaderId = 'loader-01';
  loaderId1 = 'loader-02';
  imageSrc: any;
  changed: Boolean = false;
  showImageDiv: Boolean = true;
  showSaveButton = true;
  showDownloadButton: Boolean = true;
  selectedClaimentName: any;
  selectedClaimentId: any;
  selectedClaimentDOI: any;
  selectedClaimentDOB: any;
  preloadImage: any = [];
  loadImageLength: any;
  pageChanged: Boolean = false;
  save: Boolean = false;
  preLoadFlag: Boolean = true;
  selectedIndex: any;
  documentPages: Array<any> = [];
  loaded = false;
  availheight: any;
  winHeight: any;
  winWidth: any;
  thumbsDiv: any = 1;
  thumbControls: any = 1;
  editorHeight: any;
  current_rotation = 0;
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.winHeight = window.innerHeight;
    // console.log("Screen size",  this.winHeight );
    if (!this.fullScreen) {
      const space = this.winHeight - 74 - 46 - 36;
      this.editorHeight = this.winHeight - 74 - 46 + "px";

      this.thumbsDiv = Math.floor(space / 110);
      // this.thumbControls = Math.floor(this.thumbsDiv / 2)
      if (this.thumbsDiv % 2 == 0) {
        this.thumbControls = Math.floor(this.thumbsDiv / 2) - 1;
      }
      else {
        this.thumbControls = Math.floor(this.thumbsDiv / 2) - 1;
      }
    }
    else {
      const space = this.winHeight - 46 - 36;
      this.editorHeight = this.winHeight - 46 + "px";
      this.thumbsDiv = Math.floor(space / 110);
      // this.thumbControls = Math.floor(this.thumbsDiv / 2)
      if (this.thumbsDiv % 2 == 0) {
        this.thumbControls = Math.floor(this.thumbsDiv / 2) - 1;
      }
      else {
        this.thumbControls = Math.floor(this.thumbsDiv / 2) - 1;
      }
    }
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true } as any) paginator: MatPaginator;
  @ViewChild(DetailReporComponent) child;
  // @ViewChild("imageID") imageID: ElementRef;
  // myDiv = document.getElementById('imageID');

  //to scroll image on top uncomment 167-175
  // @HostListener('scroll', ['$event.target'])
  // onScroll(event: any) {
  //   if (event.target.scrollTop > 0) {
  //     setTimeout(() => {
  //       event.target.scrollTo({ top: 0, behavior: 'smooth' });
  //     }, 4000) 
  //     this.pageChanged = false;
  //   }
  // }

  // onScroll(event:any){ 
  //    // visible height + pixel scrolled >= total height 
  //   //  if (event.target.offsetHeight + event.target.scrollTop === event.target.scrollHeight) { 
  //   //   // this.navigateButton(1);
  //   //   event.target.scrollTo({ top: 10, behavior: 'smooth' });
  //   // }
  //   // if (event.target.scrollTop === 0) { 
  //   //   if(this.row >= 2){
  //   //     this.navigateButton(-1);
  //   //     event.target.scrollTo({ top: 10, behavior: 'smooth' });
  //   //   }
  //   // }
  // }
  constructor(
    public _authservice: AuthService,
    public router: ActivatedRoute,
    public route: Router,
    private httpclient: HttpClient,
    private reportGenerateService: ReportGenerateService,
    // private reportViewComponent: ReportViewComponent,
    private detailReportService: DetailReportService,
    public loader: NgxUiLoaderService,
    public loader1: NgxUiLoaderService,
    public loader2: NgxUiLoaderService,
    private toastr: ToastrService,
    private scrollDispatcher: ScrollDispatcher,
  ) {
    this.onResize();
    this.currentUser = _authservice.currentUser;
    // this.scrollDispatcher.scrolled().subscribe(x => console.log('I am scrolling'));
  }

  ngOnInit() {
    // this.imageID.scrollTop = 0;
    this.document_id = this.router.snapshot.paramMap.get('document_id');
    this.insurance_company = this.router.snapshot.paramMap.get('insurance_company');
    this.displayedColumns = ['checkbox', 'date', 'impression', 'note_type', 'provider', 'page_no'];
    // this.populateJson();
    this.initDocumentPages(false);

    // this.loader.startLoader('loader-01'); 
    // const sortState: Sort = {active: 'page_no', direction: 'desc'};
    // this.sort.active = sortState.active;
    // this.sort.direction = sortState.direction;
    // this.sort.sortChange.emit(sortState);
  }
  addNewPage(event) {
    this.addedPage = event.page_no;
    window.localStorage.setItem('pageNo', this.addedPage);
    this.toastr.info('Adding new page...', 'In-Progress');
    this.loader1.startLoader('loader-02');
    const details = {
      row: this.row,
      document_id: event.document_id,
      providers: event.provider,
      dates: event.date,
      date_types: event.date_type,
      exams: event.exam,
      note_types: [""],
      impression: event.impression,
      findings: event.findings,
      page_no: event.page_no + 1,
      page_image_url: event.page_image_url,
      text: event.text,
      checked: false,
      // selected
      date: event.selected.date,
      note_type: event.selected.note_type,
      provider: event.selected.provider,
      exam: event.selected.exam,
    };
    this.reportGenerateService.addNewPage(details).subscribe((response: any) => {
      if (response.status == 200) {
        // console.log("response: ", response);
        // this.documentPages.splice((this.row + 1), 0, response.data);
        // this.dataSource = new MatTableDataSource(this.documentPages);
        // this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;

        // this.ngOnInit();
        window.location.reload();
        // this.initDocumentPages(true); 
      }
    })
  }
  toggleScreen() {
    this.fullScreen = !this.fullScreen;
    this.onResize();
  }
  thumbnailArray(n: any): any[] {
    n = Number(n)
    if (!isNaN(n) && n > 0) {
      return Array.from({ length: n });
    }
    else {
      return [];
    }
  }
  imageClick(event, element) {
    this.onSaveClick(element);
    // this.loader.startLoader('loader-01');
    var [protocol, blank, server, api, rrgen, image, docID, page] = event.srcElement.src.split("/");
    var [pageNum, type] = page.split(".")
    this.row = "";
    pageNum = Number(pageNum)
    this.selectedIndex = pageNum;
    this.row = Number(pageNum);
    this.selectedRow = this.row;
    // this.jumpToPage(pageNum);
  }

  showImageSide() {
    // this.reportView = true;
    this.showImageDiv = true
  }

  rotatePage() {
    this.current_rotation += 90;
    document.getElementById("image").style.transform = 'rotate(' + this.current_rotation + 'deg)';
  }

  pageNumClick(page, element) {
    this.onSaveClick(element);
    if (page && page < this.paginator.length) {
      this.row = "";
      this.row = Number(page);
      this.selectedRow = this.row;
    }
  }
  changeThumbnails(num, flag) {
    if (num) {
      num = Number(num);
      let temp = num + this.thumbnail;
      if (temp >= 1 && temp <= this.documentPages.length) {
        if (num < this.thumbnail) {
          // if (flag) { 
          //   this.thumbnail = 1; +
          // }
          this.thumbnail = num + this.thumbnail;
          if (!flag) {
            this.row = this.thumbnail;
          }
          this.selectedRow = this.row;
        }

        else if (temp >= (Number(this.thumbnail) + Number(this.thumbsDiv))) {
          if (flag) {
            this.thumbnail = 1;
          }
          this.thumbnail = num + this.thumbnail;
          if (!flag) {
            this.row = this.thumbnail;
          }
          this.selectedRow = this.row;
        }
      }
    }
  }
  jumptoThumbnail(num) {
    // num = Math.floor(num / this.thumbsDiv);
    if (num > this.thumbnail + this.thumbsDiv) {
      this.thumbnail = num + this.thumbnail - 1;
    }
    else if (num > this.thumbnail && num < (this.thumbnail + this.thumbsDiv)) {
      this.thumbnail = this.thumbnail;
    }
    else if (num < this.thumbnail) {
      this.thumbnail = num;
    }

  }

  async preloadImages() {
    this.preLoadFlag = false;
    if (!this.preloadImage.cache) {
      this.preloadImage.cache = [];
    }
    let img;
    this.loadImageLength = this.row + 15;
    for (let i = this.row; i <= this.loadImageLength; i++) {
      if (i <= this.documentPages.length && (this.preloadImage.cache.map(d => {
        d.src.indexOf(this.document_id + "/" + (i) + ".jpg") === -1
      }))) {
        img = new Image();
        // if (i <= this.documentPages.length) {
        img.src = await `${global.url}${API_URL.RRGEN.images}/${this.document_id}/${i}.jpg`;
        this.preloadImage.cache.push(img);

        if (i == this.loadImageLength - 3) {
          this.preLoadFlag = true;
        }
        // }
      }
    }
  }


  transformResponseToDocuments(document_detail) {
    return document_detail.map((document_page, i) => {
      const page_no = i + 1;
      const provider = [];
      provider.push(this.custom_value);
      Object.keys(document_page.provider.key_value).forEach(key => {
        const obj = document_page.provider.key_value[key];
        Object.keys(obj).forEach(objKey => {
          const str = obj[objKey];
          if (provider.indexOf(str) === -1) {
            provider.push(str);
          }
        })
      })
      document_page.provider.undefined.forEach(str => {
        if (provider.indexOf(str) === -1) {
          provider.push(str)
        }
      })
      this.providerList.forEach(p => {
        if (provider.indexOf(p) === -1) {
          provider.push(p);
        }
      });

      const date = [];
      const date_type = [];
      Object.keys(document_page.date.key_value).forEach(key => {
        const obj = document_page.date.key_value[key];
        Object.keys(obj).forEach(objKey => {
          const str = obj[objKey];
          if (date.indexOf(str) === -1) {
            date.push(str);
            date_type.push(objKey)
          }
        })
      })
      document_page.date.undefined.forEach(str => {
        if (date.indexOf(str) === -1) {
          date.push(str);
          date_type.push('');
        }
      })
      date.push(this.custom_value);
      date_type.push('');

      let note_type = [];
      if (document_page.note_type === 'undefined') {
        if (this.insurance_company === "demarco" || this.insurance_company === "" || this.insurance_company === "undefined") {
          note_type = this.demarco_note_types;
        }
        else {
          note_type = this.cmc_note_types;
        }
      } else {
        if (this.insurance_company === "demarco" || this.insurance_company === "" || this.insurance_company === "undefined") {
          note_type.push(document_page.note_type);
          this.demarco_note_types.forEach(np => {
            if (note_type.indexOf(np) === -1) {
              note_type.push(np);
            }
          })
        } else {
          note_type.push(document_page.note_type);
          this.cmc_note_types.forEach(np => {
            if (note_type.indexOf(np) === -1) {
              note_type.push(np);
            }
          })
        }
      }
      const impression = document_page.note_detail.Impression ? document_page.note_detail.Impression.split("\n").filter(s => s.trim()).join("") : '';
      // const impression = document_page.note_detail.Impression.split("\n").filter(s => s.trim()).join("");
      const url = document_page.url;
      const text = document_page.text;
      // const findings = document_page.note_detail.Finding.split("\n").filter(s => s.trim()).join(""); 
      const findings = document_page.note_detail.Finding ? document_page.note_detail.Finding.split("\n").filter(s => s.trim()).join("") : '';
      let exams = [];
      if (document_page.note_detail.Exam && document_page.note_detail.Exam !== '') {
        exams.push(document_page.note_detail.Exam);
        this.default_exams.forEach(np => {
          if (exams.indexOf(np) === -1) {
            exams.push(np);
          }
        })
      }
      else {
        exams = this.default_exams;
      }

      return {
        provider: provider.map(e => e === 'undefined' || '' ? '-' : e),
        date: date.map(e => e === 'undefined' || '' ? '-' : e),
        date_type,
        note_type: note_type.map(e => e === 'undefined' || '' ? '-' : e),
        impression: impression || '',
        text: text || 'Not Found',
        findings: findings || '',
        page_no: page_no || '-',
        url,
        checked: false,
        exams: exams || '-',
      }
    });
  }

  populateJson() {
    const document_json_str = localStorage.getItem('document_json');
    if (document_json_str) {
      const document_json = JSON.parse(document_json_str);
      this.selectedClaimentName = document_json.cover_detail.person;
      this.selectedClaimentId = document_json.cover_detail.claim_id;
      this.selectedClaimentDOI = document_json.cover_detail.date_of_incident;
      this.selectedClaimentDOB = document_json.cover_detail.birthdate;

      document_json.document_detail = this.transformResponseToDocuments(document_json.document_detail).map(d => {
        // d.selected.provider = d.provider[0];
        // d.selected.date = new Date(d.date[0]);
        d.selected.note_type = d.note_type[0];
        d.impression = d.impression;
        d.selectedFindings = d.findings;
        d.selectedpage_no = d.page_no;
        d.selected.exam = d.exams[0];
        d.checked = this.isChecked;
        if (['C-3 form'.toUpperCase().trim(), 'C-4 form'.toUpperCase().trim(),
        'C-4.2 formn'.toUpperCase().trim(), 'C-4.3 form'.toUpperCase().trim(),
        'MG-2 form'.toUpperCase().trim(), 'MG-2.1 form'.toUpperCase().trim(),
        'Form'.toUpperCase().trim(), 'EC-4NARR form'.toUpperCase().trim(),
        'First report of injury'.toUpperCase().trim()].indexOf(d.note_type[0].toUpperCase()) >= 0) {
          d.selected.date = "";
          d.selected.provider = "";
        }
        else {
          d.selected.provider = d.provider[0];
          if (d.date) {
            d.selected.date = new Date(d.date[0]);
          }
          else {
            d.selected.date = "";
          }
        }

        // if (['Insurance Form'.toUpperCase().trim(), 'Unidentified'.toUpperCase().trim(),
        // 'Treatment Plan'.toUpperCase().trim(), 'Orders'.toUpperCase().trim(),
        // 'Police Report'.toUpperCase().trim()].indexOf(d.note_type[0].toUpperCase()) >= 0) {
        //   d.checked = false;
        // }
        // else {
        //   d.checked = this.isChecked
        // }
        return d;
      });


      this.documentJSON = document_json;
      this.dataSource = new MatTableDataSource(this.documentPages);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      // selectedClaimentName = document_json.document_detail.claiment_name;
    }
  }

  onSelectNote(item: any, element) {
    element.addNewNoteType = false;
    // this.addNewExam = true; 
    element.selected.note_type = item;
    if (item == "Add New") {
      element.selected.note_type = "";
      // setTimeout(()=>{
      //   this.NoteTypeInput.nativeElement.focus();
      // }, 200 )
    }
  }


  initDocumentPages(newPage) {
    this.loader1.startLoader('loader-02');
    this.reportGenerateService.getAllDocumentPages(this.document_id).subscribe((response: any) => {
      if (response.status === 200) {
        this.documentPages = response.data.array;
        this.preloadImages();
        if (response.data.coverInfo.insurance_comapny) {
          this.insurance_company = response.data.coverInfo.insurance_comapny.toLowerCase().trim();
        }
        this.selectedClaimentName = response.data.coverInfo.claiment_name;
        this.selectedClaimentId = response.data.coverInfo.claiment_id;
        this.selectedClaimentDOI = response.data.coverInfo.claiment_doi;
        this.selectedClaimentDOB = response.data.coverInfo.claiment_dob;
        // this.documentPages.forEach(selectedValueObj => {
        //   this.documentPages.forEach((document_page, i) => {

        //     if (document_page.page_no == selectedValueObj.page_no) {
        //       this.documentPages[i]
        //       this.documentPages[i] = {
        //         ...this.documentPages[i],
        //         document_page_id: selectedValueObj._id,
        //         selected.provider: selectedValueObj.provider,
        //         selected.date: selectedValueObj.date,
        //         selected.note_type: selectedValueObj.note_type,
        //         impression: selectedValueObj.impression,
        //         selectedFindings: selectedValueObj.findings,
        //         selectedpage_no: selectedValueObj.page_no,
        //         checked: selectedValueObj.is_checked,
        //         selected.exam: selectedValueObj.exam,
        //       }
        //     }
        //   });
        // }); 
        this.emitDateAndProvider();
        this.attachNoteTypesToDocumentPages();
        this.attachExamsToDocumentPages();
        this.attachProvider();
        this.changecaseOfImpression();
        // this.trimDate();
        this.dataSource = new MatTableDataSource(this.documentPages);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        if (window.localStorage.getItem('pageNo')) {
          this.onEditClick(this.documentPages[window.localStorage.getItem('pageNo')]);
          window.localStorage.removeItem('pageNo');
        }
        // if(newPage){
        //   this.onEditClick(this.documentPages[this.addedPage]);
        // }
        else {
          this.onEditClick(this.documentPages[0]);
        }
      }
    });
  }

  unlockDocument() {
    this.unlocked = true;
    let locked = false;
    this.reportGenerateService.lockAndUnlockDocument(locked, this.document_id).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.info("Document Unlocked");
      }
    })
  }
  // emitProviderAndDate(element) {
  //   if (element) { 
  //       if (['C-3 form'.toUpperCase().trim(), 'C-4 form'.toUpperCase().trim(),
  //       'C-4.2 formn'.toUpperCase().trim(), 'C-4.3 form'.toUpperCase().trim(),
  //       'C-4 AUTH form'.toUpperCase().trim(), 'MG-2 form'.toUpperCase().trim(), 'C-4 AMR'.toUpperCase().trim(),
  //       'MG-2.1 form'.toUpperCase().trim(), 'Form'.toUpperCase().trim(), 'EC-4NARR form'.toUpperCase().trim(),
  //       'First report of injury'.toUpperCase().trim()].indexOf(element.selected.note_type.toUpperCase()) >= 0) {
  //         element.selected.date = "";
  //         element.selected.provider = "";
  //       }
  //       else {
  //         element.selected.provider = element.provider[0];
  //         if (element.date) {
  //           element.selected.date = new Date(element.date[0]);
  //         }
  //         else {
  //           element.selected.date = "";
  //         }
  //       } 
  //   }
  // }
  changecaseOfImpression() {
    this.documentPages.forEach(async page => {
      if (page && page.impression) {
        console.log("page.impression ------: ", page.impression);
        page.impression = await page.impression.toLowerCase().replace(/(^\s*\w|[\.\!\?\:]\s*\w)/g, function (c) { return c.toUpperCase() });
        console.log("page.impression =====: ", page.impression);
      }
    });
  }

  emitDateAndProvider() {
    for (let page of this.documentPages) {
      // if (['C-3 form'.toUpperCase().trim(), 'C-4 form'.toUpperCase().trim(),'C-8.1 form'.toUpperCase().trim(),
      // 'C-4.2 form'.toUpperCase().trim(), 'C-4.3 form'.toUpperCase().trim(), 'C-4.1 form'.toUpperCase().trim(),
      // 'C-4 AUTH form'.toUpperCase().trim(), 'MG-2 form'.toUpperCase().trim(), 'C-4 AMR'.toUpperCase().trim(),
      // 'MG-2.1 form'.toUpperCase().trim(), 'Form'.toUpperCase().trim(), 'EC-4NARR form'.toUpperCase().trim(),
      // 'First report of injury'.toUpperCase().trim()].indexOf(page.selected.note_type.toUpperCase()) >= 0) {
      //   page.selected.date = "";
      //   page.selected.provider = "";
      // }
      if ((page.selected.note_type.trim().toLowerCase()).includes('form') ||
        (page.selected.note_type.trim().toLowerCase()).includes('First report of injury'.trim().toLowerCase()) ||
        (page.selected.note_type.trim().toLowerCase()).includes('The subsequent report of injury'.trim().toLowerCase())) 
        {
          page.selected.date =page.selected.provider;;
          page.selected.provider =new Date(page.selected.date);
      }
      else {
        page.selected.provider = page.selected.provider;
        if (page.date) { 
          page.selected.date = new Date(page.selected.date);
        }
        else {
          page.selected.date = "";
        }
      }
    }
  }
  attachProvider() {
    for (let documentPage of this.documentPages) {
      if (!Array.isArray(documentPage.provider)) {
        documentPage.provider = [];
      }
      documentPage.provider.forEach(np => {
        if (documentPage.provider.indexOf(np) === -1) {
          documentPage.provider.push(np);
        }
      })
    }
  }
  trimDate() {
    for (let documentPage of this.documentPages) {
      if (!Array.isArray(documentPage.date)) {
        documentPage.date = [];
      }
      documentPage.date.forEach(d => {
        documentPage.provider.push(new Date(d.trim()));
      })
    }
  }
  attachExamsToDocumentPages() {
    for (let documentPage of this.documentPages) {
      // this.default_exams 
      if (!Array.isArray(documentPage.exam)) {
        documentPage.exam = [];
      }
      if (documentPage.exam[0] && documentPage.exam[0] != "") {
        // documentPage.exam = [];
        documentPage.exam[0] = documentPage.exam[0].toLowerCase().replace(/(^\s*\w|[\.\!\?\:]\s*\w)/g, function (c) { return c.toUpperCase() });
      }

      //-------------to Title case-----------
      // str.toLowerCase()
      // .split(' ')
      // .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      // .join(' ');  

      //------------------to sentence case--------------------
      //       convertToSentenceCase(str) {
      //   return str.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g,function(c){return c.toUpperCase()});
      // } 
      this.default_exams.forEach(np => {
        if (documentPage.exam.indexOf(np) === -1) {
          documentPage.exam.push(np);
        }
      })
      // documentPage.exam = {
      //   ...this.default_exams,
      //   ...documentPage.exam
      // } 

    }
  }
  attachNoteTypesToDocumentPages() {
    for (let documentPage of this.documentPages) {
      let note_type = [];
      if (documentPage.note_type === 'undefined') {
        if (this.insurance_company === "demarco" || this.insurance_company === "" || this.insurance_company === "undefined") {
          note_type = this.demarco_note_types;
        }
        else {
          note_type = this.cmc_note_types;
        }
      } else {
        if (this.insurance_company === "demarco" || this.insurance_company === "" || this.insurance_company === "undefined") {
          note_type.push(documentPage.note_type[0]);
          this.demarco_note_types.forEach(np => {
            if (note_type.indexOf(np) === -1) {
              note_type.push(np);
            }
          })
        } else {
          note_type.push(documentPage.note_type[0]);
          this.cmc_note_types.forEach(np => {
            if (note_type.indexOf(np) === -1) {
              note_type.push(np);
            }
          })
        }
      }
      documentPage.note_type = note_type;
    }
  }
  getImageUrl(row) {
    if (row && row > 0 && row <= this.paginator.length) {
      // row = Number(row); 
      return `${global.url}${API_URL.RRGEN.images}/${this.document_id}/${row}.jpg`
    }
    // const doc = this.documentPages.find((d, i) => i + 1 === this.row);
    // if (doc) {
    //   return `${global.app_base_url}routes/report-images/${doc.url}`;
    //   // return require(`${doc.url}`);
    // }

    // return  `${global.url}${ API_URL.RRGEN.images}/${this.document_id}/52.jpg`
  }

  handleImageLoad() {
    this.loader.stopLoader('loader-01')
    this.loader1.stopLoader('loader-02')
    if (this.preLoadFlag && this.row == this.loadImageLength - 3) {
      this.preloadImages();
    }
  }
  getImageText() {
    const doc = this.documentPages.find((d, i) => i + 1 === this.row);
    if (doc) {
      // doc.getElementById('provider').style.background : black;
      return doc.text.split("\n").filter(s => s.trim()).join("\n").replace(/id/g, "class");
    }
  }

  onEditClick(item) {
    this.row = "";
    this.row = item.page_no;
    this.selectedRow = this.row;
    this.changeThumbnails((Math.floor(this.row / this.thumbsDiv) * this.thumbsDiv), true);
    this.isChecked = item.is_checked
    this.showImage = true;
    this.reportView = false;
    const details = {
      document_id: this.document_id
    }
    this.reportGenerateService.previewReport(details).subscribe((response: any) => {
      if (response.status == 200) {
        if (!response.data.summary || response.data.summary == 'undefined' || !response.data) {
          this.savedReport.summary = `<div style="padding: 15px; line-height:20px; font-family: serif;font-size: 13px;">  
          <div><ul> 
          </ul> </div>`
          //     <p >
          //     <strong>Claim Type: </strong>
          // <br>
          //     <strong>Carrier: </strong>  
          //     <br>
          //     <strong>Claimant: </strong>  
          //     <br>
          //     <strong>DOB: </strong> 
          //     <br>
          //     <strong>Client File#: </strong> 
          //     <br>
          //     <strong>UniMex File#: </strong> 
          //     <br>
          //     <strong>DOA: </strong> 
          //     <br>
          //     <strong>Employer: </strong>  
          //     <br>
          //     <strong>WCB File#: </strong> 
          // </p> 
        }
        else {
          this.savedReport = response.data;
        }
        this.edit = true;
      }
    })
    this.table = false;
    if (this.row !== item.page_no) {
      this.loader.startLoader('loader-01');
    }
    // this.row = "";
    // this.row = item.page_no; 
    // this.changeThumbnails((Math.floor(this.row / 5) * 5), true);
    this.dataSource = new MatTableDataSource(this.documentPages);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }

  onSaveClick(element) {
    if (element) {
      this.doc = this.documentPages.find((d, i) => d.page_image_url == element.page_image_url)
      element.is_checked = element.checked;
      this.isChecked = element.checked;
    }
    else {
      this.doc = this.documentPages.find((d, i) => i + 1 === this.row);
      this.isChecked = this.doc.is_checked;
    }
    this.showImage = false;
    // this.row = ''; //to hide image after clicking on save button
    this.row = this.row;
    this.selectedRow = this.row;
    // this.addNewDate = false;
    // this.addNewProvider = false;

    const details = {
      document_page_id: this.doc._id,
      document_id: this.document_id,
      impression: this.doc.impression,
      page_no: this.doc.page_no,
      page_image_url: this.doc.page_image_url,
      checked: this.isChecked,
      // selected
      date: this.doc.selected.date,
      note_type: this.doc.selected.note_type,
      provider: this.doc.selected.provider,
      exam: this.doc.selected.exam,
    };
    if (this.doc.selected.provider) {
      // console.log("this.documentPages.provider: ", this.doc.provider);   
      if (this.providerList.indexOf(this.doc.selected.provider) === -1) {
        this.providerList.push(this.doc.selected.provider)
        // this.populateJson();
      }
    }
    this.detailReportService.updateDocumentPage(details).subscribe((response: any) => {
      if (response.status === 200) {
        // this.toastr.success('Saved successfully', 'Success');
        this.documentPages.forEach((e, i) => {
          if (e.page_no == response.data.page_no) {
            this.documentPages[i] = {
              document_page_id: response.data._id,
              ...this.documentPages[i]
            }
          }
        })
        // this.perPageGenerateReport();
        // this.reportGenerateService.
        // this.save = true;
        // this.populateJson();
        // this.initDocumentPages();
        // this.perPageGenerateReport();
      } else {
        this.toastr.error('something went wrong', 'Error');
      }
    });
  }
  pageReport(element) {
    this.perPageGenerateReport();
  }

  navigateButton(val, element) {
    this.onSaveClick(element);
    if (isNaN(Number(val)) || this.isNavigationInProgress) {
      this.paginator.page.emit({
        pageIndex: (this.paginator.pageIndex),
        pageSize: this.paginator.pageSize,
        length: this.paginator.length
      });
      return;
    }

    if (val > 0 && this.row < this.documentPages.length) {
      // this.loader.startLoader('loader-01');
      this.row = (this.row + val) % (this.documentPages.length + 1);
      this.selectedRow = this.row;
      if (this.selectedRow >= (Number(this.thumbnail) + Number(this.thumbsDiv))) {
        this.changeThumbnails(this.thumbsDiv, true)
      }
      if (this.row > ((this.paginator.pageIndex + 1) * this.paginator.pageSize)) {
        this.paginator.pageIndex = this.paginator.pageIndex + 1;
        this.paginator.page.emit({
          pageIndex: (this.paginator.pageIndex),
          pageSize: this.paginator.pageSize,
          length: this.paginator.length
        });
      }

    }
    else if (val < 0 && this.row >= 2) {
      // this.onSaveClick(element);
      // this.loader.startLoader('loader-01');
      this.row = (this.row + val) % (this.documentPages.length + 1);
      this.selectedRow = this.row;
      if (this.selectedRow < (Number(this.thumbnail))) {
        this.changeThumbnails(-(this.thumbsDiv), true)
      }
      if (this.row == ((this.paginator.pageIndex + 1) * this.paginator.pageSize) - this.paginator.pageSize) {
        this.paginator.pageIndex = this.paginator.pageIndex - 1;
        this.paginator.page.emit({
          pageIndex: (this.paginator.pageIndex),
          pageSize: this.paginator.pageSize,
          length: this.paginator.length
        });
      }
    }
    else if (this.row >= this.documentPages.length) {
      this.generateReport()
      // this.perPageGenerateReport();
    }

    if (this.row === 0) {
      this.row = this.documentPages.length;
      this.selectedRow = this.row;
    }
    this.tableScrollTop();

  }

  jumpToPage(page, element) {
    this.onSaveClick(element);
    const nextIndex = (Math.ceil(this.row / this.paginator.pageSize) - 1);
    if (isNaN(Number(page)) || this.isNavigationInProgress || !page) {
      this.row = 1;
      this.selectedRow = this.row;
      this.paginator.pageIndex = nextIndex;
      this.paginator.page.emit({
        pageIndex: (this.paginator.pageIndex),
        pageSize: this.paginator.pageSize,
        length: this.paginator.length
      });
      return;
    }
    if (page <= this.documentPages.length && page >= 1) {
      this.loader.startLoader('loader-01');
      this.isNavigationInProgress = true;
      this.row = "";
      this.row = page;

      this.jumptoThumbnail(this.row);
      // this.changeThumbnails((Math.floor(this.row / this.thumbsDiv) * this.thumbsDiv), true);
      this.paginator.pageIndex = nextIndex;
      this.paginator.page.emit({
        pageIndex: (this.paginator.pageIndex),
        pageSize: this.paginator.pageSize,
        length: this.paginator.length
      });
    }
    // let element = document.getElementById("matTable"); 
    this.tableScrollTop();
    this.isNavigationInProgress = false;
  }
  tableScrollTop() {
    // const y = 58 * (this.row-2);
    // document.querySelector('.table-wrapper').scrollTop = y < 0 ? 0 : y;
  }
  onSelect() {
    // item.addNewDate = true;
    // item.addNewProvider = true;
    this.edit = false;
    this.reportView = false;

  }
  onSelectDate(element, date) {
    if (date == "Add New") {
      element.selected.exam = "";
      element.addNewDate = false;
    }
    element.selected.date = new Date(date);
    element.addNewDate = false;
  }

  onSelectProvider(element, provider) {
    // this.changeAddNew(true) 
    element.addNewProvider = false;
    element.selected.provider = provider;
    if (provider == "Add New") {
      element.selected.provider = "";
      //   setTimeout(()=>{
      //       this.providerInput.nativeElement.focus();
      //     }, 200 )
    }
  }

  toggleFlip() {
    this.flip = (this.flip == 'inactive') ? 'active' : 'inactive';
  }
  convert_date(element, date) {
    if (date) {
      // return new Date(date).toLocaleDateString();
      return element.selected.date = date;
    }
    else {
      return element.selected.date = date;
      // return date;
    }
  }

  getDateType(element) {
    const index = element.date.findIndex((date, i) => {
      const dateA = (new Date(date)).toString();
      const dateB = (new Date(element.selected.date)).toString();
      return dateA === dateB;
    });
    if (element.selected.date === '') {
      return '';
    }
    if (element.selected.date && index >= 0) {
      return element.date_type[index]
    }
    return '';
  }

  goBack() {

    if (this.edit && !this.showImageDiv) {
      this.showImageDiv = true;
      this.edit = true;
      this.showSaveButton = true;
      this.showDownloadButton = true;
    }
    else {
      this.showImageDiv = true;
      this.showSaveButton = true;
      this.showDownloadButton = true;
      this.edit = false;
      this.reportView = false
      this.table = true;
      this.dataSource = new MatTableDataSource(this.documentPages);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }
  goBackToRrgen() {
    if (!this.unlocked) {
      swal({
        title: "Unlock Document",
        text: "Do you want to Unlock the Document?",
        icon: "warning",
        buttons: [
          "No", "Yes"
        ],
        dangerMode: true,
      })
        .then((unlock) => {
          if (unlock) {
            this.unlockDocument();
            this.route.navigateByUrl('/report-generate')
          }
          else {
            this.route.navigateByUrl('/report-generate')
          }
        })
    }
    else {
      this.route.navigateByUrl('/report-generate')
    }
  }
  changeCheckBox(element, e) {
    element.is_checked = e.checked;
    this.isChecked = e.checked
    this.onSaveClick(element)
    // this.addNewDate = true;
    // this.addNewProvider = true;
  }
  getHeight() {
    return this.editorHeight;
  }
  async perPageGenerateReport() {
    const details = {
      document_id: this.document_id,
      claiment_id: this.selectedClaimentId,
      claiment_name: this.selectedClaimentName,
      claiment_doi: this.selectedClaimentDOI,
      claiment_dob: this.selectedClaimentDOB,
      page_no: this.selectedRow,
    }
    await this.reportGenerateService.perPageGenerateReport(details).subscribe((response: any) => {
      if (response.status == 200) {
        const summaryData = this.reportGenerateService.summaryReportCMC(response.data.array);
        if (summaryData) {
          this.perPageReport = this.reportGenerateService.perPageGenerateSummaryCMCFormat(summaryData);
          // if(this.updated){
          // if(this.savedReport == 'undefined' || !this.savedReport){
          // const details = {
          //   document_id: this.document_id,
          //   claiment_id: this.selectedClaimentId,
          //   claiment_name: this.selectedClaimentName,
          //   claiment_doi: this.selectedClaimentDOI,
          //   claiment_dob: this.selectedClaimentDOB,
          // }
          // this.reportGenerateService.generateReport(details).subscribe((response: any) => {

          // })
          // this.generateReport();
          // }
          let res = this.savedReport.summary.slice(0, -11);
          res = res.concat(this.perPageReport);
          this.savedReport.summary = res;
        }
      }
    })
  }
  // onSummaryChange(event){
  //   console.log("onSummaryChange called");

  //   this.reportGenerateService.saveOrUpadateSummary(event, this.document_id).subscribe((response:any)=>{
  //     if(response.status == 200){
  //       console.log("summary updated: ");

  //     }
  //   });
  // }

  generateReport() {
    swal({
      title: "Are you sure?",
      text: "This will overwrite your saved Report. If there is any.",
      icon: "warning",
      buttons: [
        true, true
      ],
      dangerMode: true,
    })
      .then((overwrite) => {
        if (overwrite) {
          this.toastr.info('Generating Report. . .', 'Info');
          const details = {
            document_id: this.document_id,
            claiment_id: this.selectedClaimentId,
            claiment_name: this.selectedClaimentName,
            claiment_doi: this.selectedClaimentDOI,
            claiment_dob: this.selectedClaimentDOB,
          }
          // this.detailReportService.updateDocumentPage(details).subscribe((response: any) => {

          this.reportGenerateService.generateReport(details).subscribe((response: any) => {
            if (response.status === 200) {
              this.toastr.success('Report Generated. . .', 'Success');
              if (this.edit) {
                this.showDownloadButton = true;
              }
              else {
                this.showDownloadButton = true;
                this.reportView = true;
                // this.edit = false;
                this.table = true;
                this.changed = false;
              }
              this.saved = false;
              this.showImageDiv = false;
              this.showSaveButton = false;
              this.report = response.data;
              this.report.cover = response.data.cover;
              this.report.showDownloadButton = this.showDownloadButton;
              // document.getElementById('reportView').style.height = this.editorHeight;

            }
            else {
              this.toastr.error('Something went Wrong. . .', 'Error');
            }
          })
        }
        // else {
        //     swal("Your note is not revised!");
        // }
      });

  }
  async downloadSummary() {
    const details = {
      document_id: this.document_id
    }
    let downloaded: any = await this.reportGenerateService.generatePDFAndDownload(details);
    if (downloaded) {
      this.goBack();
    }
  }
  async previeweReport() {
    // document.getElementById('reportView').style.height = this.editorHeight;
    const details = {
      document_id: this.document_id
    }
    let previewSummary = await this.reportGenerateService.previewReport(details).subscribe((response: any) => {
      if (response.status == 200) {
        this.savedReport = response.data;
        // this.toastr.success('Report Generated. . .', 'Success');
        if (this.edit) {
          this.showDownloadButton = true;
        }
        else {
          this.showDownloadButton = true;
          this.reportView = true;
          // this.edit = false;
          this.table = true;
          this.changed = false;
        }
        this.showImageDiv = false;
        this.showSaveButton = false;
        this.saved = true;
        this.report.showDownloadButton = this.showDownloadButton;
      }
    })
  }

  updateCoverInfo() {
    const details = {
      document_id: this.document_id,
      claiment_id: this.selectedClaimentId,
      claiment_name: this.selectedClaimentName,
      claiment_doi: this.selectedClaimentDOI,
      claiment_dob: this.selectedClaimentDOB,
    }
    this.reportGenerateService.updateCoverInfo(details).subscribe((response: any) => {
      if (response.status === 200) {
        this.changed = false;
      }
    })
  }
  changedValue() {
    this.changed = true;
    this.updateCoverInfo();
  }

  dateChangeDOI(e) {
    this.changed = true;

    if (e.value) {
      this.selectedClaimentDOI = new Date(e.value);
    }
    this.changedValue();
  }
  dateChangeDOB(e) {
    this.changed = true;

    if (e.value) {
      this.selectedClaimentDOB = new Date(e.value);
      // e.value.toString();
    }
    this.changedValue();
  }
  isTherapy(element) {
    if (element.selected.note_type) {
      if (element.selected.note_type.trim().toUpperCase() == "Chiropractic therapy".toUpperCase() ||
        element.selected.note_type.trim().toUpperCase() == "Occupational therapy".toUpperCase() ||
        element.selected.note_type.trim().toUpperCase() == "Acupuncture therapy".toUpperCase() ||
        element.selected.note_type.trim().toUpperCase() == "Physical therapy".toUpperCase() ||
        element.selected.note_type.trim().toUpperCase() == "Physical therapy note".toUpperCase()) {
        element.selected.provider = "";
        return true;
      }
      else {
        return false;
      }
    }
  }

}
