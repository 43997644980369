import { Component, Inject } from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/services/auth.service";
import * as global from '../../../../../app/includes/global'


export interface WoundImagePreviewDialogData {
    wound:any,
    imageIndex:number,
    mainImage: any
  }
  @Component({
    selector: 'wound-image-preview-dialog',
    templateUrl: './wound-image-preview-dialog.component.html',
    styleUrls: ['./wound-image-preview-dialog.css']
  })
  export class WoundImagePreviewDialogComponent {
    global=global;
    wound:any;
    imageIndex:number;
    mainImage: any = null;
    
    constructor(
      public dialogRef: MatDialogRef<WoundImagePreviewDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: WoundImagePreviewDialogData,
      private authService:AuthService,
      private toastr:ToastrService) {
        if(data && data.wound) {
          this.wound = data.wound;          
          this.imageIndex = data.imageIndex;         
        }
        if(data && data.mainImage){
          this.mainImage = data.mainImage
        }
      }

      getImageUrl(marker = 0) {
        if(this.imageIndex === 0){
          return this.mainImage;
        }else{
          return this.wound.wound_images[this.imageIndex + marker];
        }
      }
      navigateImage(i) {
        this.imageIndex += i;
        if(this.imageIndex < 0) {
          this.imageIndex = 0;
        }
        if(this.imageIndex >= this.wound.wound_images.length) {
          this.imageIndex = this.wound.wound_images.length - 1;
        }
      }
      hasNavigateImage(i) {
        return this.imageIndex + i >= 0 && this.imageIndex + i < this.wound.wound_images.length;
      }
  }
