<div class="mat-dialog-header flex justify-end items-start">

    <div class="flex justify-center items-center gap-4">
        <div class="pagination flex justify-end items-center">
            <button mat-raised-button class="dn-button-primary" [disabled]="isEditingLocked()" style="margin-right:6px;" (click)="resolveWound()">Resolve Wound</button>
            <button mat-raised-button class="dn-button-primary page-buttons" [disabled]="pagination.totalRecords == null" (click)="getPrevDataSet()">Prev</button>
            <button mat-raised-button class="dn-button-primary page-buttons" [disabled]="!showNextButton()"
                (click)="getNextDataSet()">Next</button>
        </div>
        <span class="material-symbols-outlined mat-dialog-close-icon" (click)="updateWoundDataAndClose()">cancel</span>
    </div>
</div>

<div class="wound-wrapper">
    <div *ngIf="allDataLoaded" class="columns-main" style="height: 100%;">
        <div class="columns flex" style="height: 100%;">
            <div *ngIf="((isAswcPortal && (this.pagination.dataSet == global.USER_TYPE.DOCTOR || this.pagination.dataSet == global.USER_TYPE.SNF_WC_NURSE)) || should_show_wound_location) && pagination.dataSet == 1" class="main-column column-laterality flex-grow">
                <div class="row flex flex-col" style="height: 100%;" [class.disable-selection]="isEditingLocked()">
                    <div class="flex flex-col flex-grow justify-center items-center item" style="position: relative;"
                        *ngIf="avatar">
                        <img style="height: 130px" [src]="wound.wound_location_image">
                    </div>
                    <div style="font-size:24px" class="flex flex-col flex-grow justify-center items-center item"
                        style="position: relative;font-size: 28px;">
                        {{wound.woundNo}}
                        <span class="avatar" (click)="showAvatar()">{{avatarMsg}}</span>
                    </div>
                    <div style="font-size:24px" class="flex flex-grow justify-center items-center item"
                        *ngFor="let item of laterality; let i = index"
                        style="position: relative;font-size: 28px;"
                        [class.selected]="isFieldValueSelected(item, 'laterality', false)"
                        (click)="selectFieldValue(item, 'laterality', false)">
                        {{ item }}
                    </div>
                </div>
            </div>
            <div *ngIf="pagination.dataSet == 1" class="column column-last flex flex-col flex-grow">
                <div class="row">
                    <div class="column-header">
                        <span>Measurements</span>
                    </div>
                    <div class="item">
                        <span *ngIf="hasLatestChildWound() && (selectedDatasetInLatestChildWound.length === '' || selectedDatasetInLatestChildWound.width === '' || selectedDatasetInLatestChildWound.depth === '')" 
                        style="font-size: smaller;color: red;">* All Measurements are Required</span>
                        <span *ngIf="!hasLatestChildWound() && (selectedDataset.length === '' || selectedDataset.width === '' || selectedDataset.depth === '')" 
                        style="font-size: smaller;color: red;">* All Measurements are Required</span>
                        <mat-form-field *ngIf="shouldChildWoundColumn('length')" style="width: 100%;" appearance="fill" floatLabel="never">
                            <mat-label style="display: flex;font-size: 15px;">Length</mat-label>
                            <input matInput type="text" maxlength="5" [disabled]="isEditingLocked()" [(ngModel)]="selectedDatasetInLatestChildWound.length" (keypress)="keyPressNumbers($event)" (ngModelChange)="updateArea($event,'length')">
                        </mat-form-field>
                        <mat-form-field *ngIf="shouldChildWoundColumn('width')" style="width: 100%;" appearance="fill" floatLabel="never">
                            <mat-label style="display: flex;font-size: 15px;">Width</mat-label>
                            <input matInput type="text" maxlength="5" [disabled]="isEditingLocked()" [(ngModel)]="selectedDatasetInLatestChildWound.width" (keypress)="keyPressNumbers($event)" (ngModelChange)="updateArea($event,'width')">
                        </mat-form-field>
                        <mat-form-field *ngIf="shouldChildWoundColumn('depth')" style="width: 100%;" appearance="fill" floatLabel="never">
                            <mat-label style="display: flex;font-size: 15px;">Depth</mat-label>
                            <input matInput type="text" maxlength="5" [disabled]="isEditingLocked()" [(ngModel)]="selectedDatasetInLatestChildWound.depth" (keypress)="keyPressNumbers($event)" (ngModelChange)="updateArea($event,'depth')">
                        </mat-form-field>
                        <mat-form-field *ngIf="!shouldChildWoundColumn('length')" style="width: 100%;" appearance="fill" floatLabel="never">
                            <mat-label style="display: flex;font-size: 15px;">Length</mat-label>
                            <input matInput type="text" maxlength="5" [disabled]="isEditingLocked()" [(ngModel)]="selectedDataset.length" (keypress)="keyPressNumbers($event)" (ngModelChange)="updateArea($event,'length')">
                        </mat-form-field>
                        <mat-form-field *ngIf="!shouldChildWoundColumn('width')" style="width: 100%;" appearance="fill" floatLabel="never">
                            <mat-label style="display: flex;font-size: 15px;">Width</mat-label>
                            <input matInput type="text" maxlength="5" [disabled]="isEditingLocked()" [(ngModel)]="selectedDataset.width" (keypress)="keyPressNumbers($event)" (ngModelChange)="updateArea($event,'width')">
                        </mat-form-field>
                        <mat-form-field *ngIf="!shouldChildWoundColumn('depth')" style="width: 100%;" appearance="fill" floatLabel="never">
                            <mat-label style="display: flex;font-size: 15px;">Depth</mat-label>
                            <input matInput type="text" maxlength="5" [disabled]="isEditingLocked()" [(ngModel)]="selectedDataset.depth" (keypress)="keyPressNumbers($event)" (ngModelChange)="updateArea($event,'depth')">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="this.pagination.dataSet == global.USER_TYPE.DOCTOR || this.pagination.dataSet == global.USER_TYPE.SNF_WC_NURSE" class="column column-last flex flex-col flex-grow">
                        <div class="column-header flex flex-col">
                            <span>Tunneling/Undermining</span>
                        </div>
                        <div class="rows flex flex-col">
                            <div class="row flex flex-col" [class.disable-selection]="isEditingLocked()">
                                <div class="item" [class.selected]="getTunnelingUnderminingValue('tunneling')"
                                    (click)="!isEditingLocked() && selectTunnelingUnderminingSelected('tunneling')">
                                    Tunneling
                                </div>
                                <div class="item" *ngIf="getTunnelingUnderminingValue('tunneling')">
                                    <mat-form-field class="w-full" appearance="outline" floatLabel="never">
                                        <mat-label class="flex text-[15px]">Direction</mat-label>
                                        <input matInput [disabled]="isEditingLocked()" [value]="getTunnelingUnderminingValue('tunneling_direction')" (input)="updateTunnelingUnderminingValue('tunneling_direction', $event.target.value)">
                                    </mat-form-field>
                                    <mat-form-field class="w-full" appearance="outline" floatLabel="never">
                                        <mat-label class="flex text-[15px]">Distance</mat-label>
                                        <input matInput type="number" min="0" step="0.1" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  [disabled]="isEditingLocked()" [value]="getTunnelingUnderminingValue('tunneling_distance')" (input)="updateTunnelingUnderminingValue('tunneling_distance', $event.target.value)">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row flex flex-col" [class.disable-selection]="isEditingLocked()">
                                <div class="item" [class.selected]="getTunnelingUnderminingValue('under_mining')"
                                    (click)="!isEditingLocked() && selectTunnelingUnderminingSelected('under_mining')">
                                    Undermining
                                </div>
                                <div class="item" *ngIf="getTunnelingUnderminingValue('under_mining')">
                                    <mat-form-field class="w-full" appearance="outline" floatLabel="never">
                                        <mat-label class="flex text-[15px]">From</mat-label>
                                        <input matInput [disabled]="isEditingLocked()" [value]="getTunnelingUnderminingValue('undermining_from')" (input)="updateTunnelingUnderminingValue('undermining_from', $event.target.value)">
                                    </mat-form-field>
                                    <mat-form-field class="w-full" appearance="outline" floatLabel="never">
                                        <mat-label class="flex text-[15px]">To</mat-label>
                                        <input matInput [disabled]="isEditingLocked()" [value]="getTunnelingUnderminingValue('undermining_to')" (input)="updateTunnelingUnderminingValue('undermining_to', $event.target.value)">
                                    </mat-form-field>
                                    <mat-form-field class="w-full" appearance="outline" floatLabel="never">
                                        <mat-label class="flex text-[15px]">Distance</mat-label>
                                        <input matInput type="number" min="0" step="0.1" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" [disabled]="isEditingLocked()" [value]="getTunnelingUnderminingValue('undermining_distance')" (input)="updateTunnelingUnderminingValue('undermining_distance', $event.target.value)">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="(getFieldName(column.header,true) == 'Eschar' || getFieldName(column.header,true) == 'Pain' || getFieldName(column.header,true) == 'Epithelial' || getFieldName(column.header,true) == 'Granulation' || getFieldName(column.header,true) == 'Slough')?'percentage-column': (getFieldName(column.header,true) == 'Infection_signs' || getFieldName(column.header,true) == 'Change_frequency' || getFieldName(column.header,true) == 'Cleanse_wound_with' || getFieldName(column.header,true) == 'Pressure_reduction' || getFieldName(column.header,true) == 'Secondary_dressing')?'column-2':'column'"
                class="column-{{getFieldName(column.header)}} column-{{shouldChildWoundColumn(column.header) ? 'child':'parent' }}-wound flex-grow"
                *ngFor="let column of dataset">

                <div class="column-header">
                    <span>{{getHeaderName(column.header)}}</span>
                    <span *ngIf="getHeaderName(column.header) === 'ETIOLOGY' && etiologySelectionError" 
                    style="font-size: smaller;color: red;">* Etiology must be selected</span>
                    <span *ngIf="getHeaderName(column.header) === 'STAGE/SEVERITY' && stageSelectionError" 
                    style="font-size: smaller;color: red;">* Stage/Severity must be selected</span>
                </div>

                <div class="rows flex flex-col">
                    <ng-container *ngIf="shouldChildWoundColumn(column.header)">
                        <div class="row" *ngFor="let data of column.rows" [class.disable-selection]="isEditingLocked()">
                            <div class="item"
                                [class.selected]="isFieldValueSelectedInLatestChildWound(data.value, column.header, column.multiselect_enabled)"
                                (click)="selectFieldValueInLatestChildWound(data.value, column.header, column.multiselect_enabled)">
                                <ng-container *ngIf="data.data_type === 'Text'">
                                    {{ data.value }}
                                </ng-container>
                                <ng-container *ngIf="data.data_type === 'Dropdown'">
                                    <mat-form-field appearance="fill" style="width: 100%;">
                                        <mat-label style="display: flex">{{data.dropdownTitle || 'Select
                                            Value'}}</mat-label>
                                        <mat-select>
                                            <mat-option [value]="getSelectedValue(column.header)"
                                                *ngFor="let item of data.value"
                                                (onSelectionChange)="handleSelection(column.header,item)">{{ item
                                                }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="data.data_type === 'Date'">
                                    {{ data.value | date: 'MM/mm/yyyy' }}
                                </ng-container>
                            </div>
                        </div>
                        <div class="row"  [class.disable-selection]="isEditingLocked()" *ngIf="getFieldName(column.header) !== 'wound_status'">
                            <div class="item"
                                [class.selected]="getCustomTextValueInLatestChildWound(column.header, column.multiselect_enabled)">
                                <ng-container *ngIf="column.multiselect_enabled == true">
                                    <span>
                                        <mat-chip-list #chipList aria-label="Data selection">
                                            <mat-chip class="mat-chip-style" *ngFor="let value of extractMultiselectCustomValues(column,true)"
                                                (removed)="removeFreeTextField(value,column,true)">
                                                {{value}}
                                                <button matChipRemove style="display: contents;">
                                                    <mat-icon>cancel</mat-icon>
                                                </button>
                                            </mat-chip>
                                            <mat-form-field style="width: 100%;" appearance="outline"
                                                floatLabel="never">
                                                <input style="width: 96%" id="{{column._id}}" matInput
                                                    [matChipInputFor]="chipList"
                                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                    (matChipInputTokenEnd)="addNewFreeTextField($event,column,false,true)">
                                                <div style="display: flex;justify-content: flex-end;"
                                                    *ngIf="showButtons(column.header)">
                                                    <span class="icon-button"
                                                        class="material-symbols-outlined icon-confirm report-icon"
                                                        (click)="addNewFreeTextField($event,column,true,true)"
                                                        matTooltip="Confirm"> check_circle </span>
                                                    <span style="margin-left: 2%;" class="icon-button"
                                                        class="material-symbols-outlined icon-navy report-icon"
                                                        (click)="removeFreeTextValue(column._id)" matTooltip="Cancel">
                                                        cancel </span>
                                                </div>
                                            </mat-form-field>
                                        </mat-chip-list>
                                    </span>
                                </ng-container>
                                <mat-form-field style="width: 100%;" appearance="outline" floatLabel="never"
                                    *ngIf="column.multiselect_enabled == false || column.multiselect_enabled == null">
                                    <input matInput #customChildtText [disabled]="isEditingLocked()"
                                        [value]="getCustomTextValueInLatestChildWound(column.header, column.multiselect_enabled)"
                                        (focus)="previousValue = customChildtText.value"
                                        (input)="handleCustomTextChangeinlatestChild(column.header, $event.target.value, column.multiselect_enabled)" />
                                </mat-form-field>
                            </div>
                        </div>

                    </ng-container>

                    <ng-container *ngIf="!shouldChildWoundColumn(column.header)">
                        <div class="row" *ngFor="let data of column.rows" [class.disable-selection]="isEditingLocked()">
                            <div class="item"
                                [class.selected]="isFieldValueSelected(data.value, column.header, column.multiselect_enabled, column._id)"
                                (click)="data.data_type !== 'Dropdown' && selectFieldValue(data.value, column.header, column.multiselect_enabled, column)">
                                <ng-container *ngIf="data.data_type === 'Text'">
                                    {{ data.value }}
                                </ng-container>
                                <ng-container *ngIf="data.data_type === 'Dropdown'">
                                    <mat-form-field appearance="fill" style="width: 100%;">
                                        <mat-label style="display: flex">{{data.dropdownTitle || 'Select
                                            Value'}}</mat-label>
                                        <mat-select [(value)]="selectedDataset[getFieldName(column.header)]"
                                            (selectionChange)="handleSelection(column.header,$event)">
                                            <mat-option [value]="item" *ngFor="let item of data.value">{{ item
                                                }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="data.data_type === 'Date'">
                                    {{ data.value | date: 'MM/mm/yyyy' }}
                                </ng-container>
                            </div>
                        </div>
                        <div class="row"  [class.disable-selection]="isEditingLocked()" *ngIf="getFieldName(column.header) !== 'wound_status'">
                            <div class="item"
                                [class.selected]="getCustomTextValue(column, column.multiselect_enabled, false)">
                                <ng-container *ngIf="column.multiselect_enabled == true">
                                    <span>
                                        <mat-chip-list #chipList aria-label="Data selection">
                                            <mat-chip class="mat-chip-style" *ngFor="let value of extractMultiselectCustomValues(column)"
                                                (removed)="removeFreeTextField(value,column)">
                                                {{value}}
                                                <button [disabled]="isEditingLocked()" matChipRemove style="display: contents;">
                                                    <mat-icon>cancel</mat-icon>
                                                </button>
                                            </mat-chip>
                                            <mat-form-field style="width: 100%;" appearance="outline"
                                                floatLabel="never">
                                                <input style="width: 96%;" id="{{column._id}}" matInput
                                                [disabled]="isEditingLocked()" 
                                                    [matChipInputFor]="chipList"
                                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                    (matChipInputTokenEnd)="addNewFreeTextField($event,column)">
                                                <div style="display: flex;justify-content: flex-end;"
                                                    *ngIf="showButtons(column._id)">
                                                    <span class="icon-button"
                                                        class="material-symbols-outlined icon-confirm report-icon"
                                                        (click)="addNewFreeTextField($event,column,true)"
                                                        matTooltip="Confirm"> check_circle </span>
                                                    <span style="margin-left: 2%;" class="icon-button"
                                                        class="material-symbols-outlined icon-navy report-icon"
                                                        (click)="removeFreeTextValue(column._id)" matTooltip="Remove">
                                                        cancel </span>
                                                </div>
                                            </mat-form-field>
                                        </mat-chip-list>
                                    </span>
                                </ng-container>
                                <mat-form-field style="width: 100%;" appearance="outline" floatLabel="never"
                                    *ngIf="column.multiselect_enabled == false || column.multiselect_enabled == null">
                                    <input matInput #customParentText [disabled]="isEditingLocked()" 
                                        [value]="getCustomTextValue(column, column.multiselect_enabled, true)"
                                        (input)="handleCustomTextChangeinParent(column, $event.target.value, column.multiselect_enabled)" />
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-container>

                </div>

            </div>
            <div *ngIf="this.pagination.dataSet == global.USER_TYPE.DOCTOR || this.pagination.dataSet == global.USER_TYPE.SNF_WC_NURSE" class="column column-last flex flex-col flex-grow">
                <div class="rows flex flex-col">
                    <div class="row">
                        <div class="column-header">
                            <span>POA/FA</span>
                            <span *ngIf="dateInCorrectError" style="font-size: smaller;color: red;">*Selected Date is not correct</span>
                            <span *ngIf="dateNullError && ((this.selectedDataset['wound_recognition_date_type'] === 'facility_aquired' && !this.selectedDataset['wound_recognition_undetermined']) || (this.selectedDataset['wound_recognition_date_type'] === 'present_on_admission'))" style="font-size: smaller;color: red;">*Please enter the Date</span>
                        </div>
                        <div class="rows flex flex-col">
                            <div class="row flex flex-col" [class.disable-selection]="isEditingLocked()">
                                <div class="item"
                                    [class.selected]="isFieldValueSelected('present_on_admission', 'wound_recognition_date_type', false)"
                                    (click)="selectFieldValue('present_on_admission', 'wound_recognition_date_type', false)">
                                    Present on Admission
                                </div>
                                <div class="item" *ngIf="selectedDataset.wound_recognition_date_type === 'present_on_admission'">
                                    <mat-form-field style="width: 100%;" appearance="outline" floatLabel="never">
                                        <input matInput [disabled]="isEditingLocked()"  #presentOnAdmissionDateInput [(ngModel)]="selectedDataset.wound_recognition_date" (dateChange)="changeAdmitDate($event.value);" [matDatepicker]="lastAdmitDatePicker"
                                            placeholder="Choose a date" [timezone]="'none'">
                                        <mat-datepicker-toggle matSuffix [disabled]="isEditingLocked()" [for]="lastAdmitDatePicker"></mat-datepicker-toggle>
                                        <mat-datepicker #lastAdmitDatePicker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="item"
                                    [class.selected]="isFieldValueSelected('facility_aquired', 'wound_recognition_date_type', false)"
                                    (click)="selectFieldValue('facility_aquired', 'wound_recognition_date_type' , false)">
                                    Facility Acquired
                                </div>
                                <div class="item" *ngIf="selectedDataset.wound_recognition_date_type === 'facility_aquired'">
                                    <mat-form-field style="width: 100%;" appearance="outline" floatLabel="never">
                                        <input matInput [disabled]="isEditingLocked() || selectedDataset.wound_recognition_undetermined" #facilityAcquiredInput
                                            [(ngModel)]="selectedDataset.wound_recognition_date" (dateChange)="changeDate($event)"
                                            [matDatepicker]="picker" placeholder="Choose a date">
                                        <mat-datepicker-toggle [disabled]="isEditingLocked()" matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                    <div>
                                        <mat-checkbox name="video" [disabled]="isEditingLocked()" [checked]="selectedDataset.wound_recognition_undetermined"
                                            (change)=" onCheckUndermined($event)"
                                            [(ngModel)]="selectedDataset.wound_recognition_undetermined">Undetermined
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="hideICDOption">
                        <div class="column-header">
                            <span>ICD10 Selector</span>
                        </div>
                        <div (click)="!isEditingLocked() && showWoundICD10Dialog()"
                            [class.disable-selection]="isEditingLocked()" class="item"
                            [class.selected]="isFieldValueSelected(null, 'wound_diagnosis', false)">
                            ICD 10
                        </div>
                        <ul *ngIf="isFieldValueSelected(null, 'wound_diagnosis', false)"
                            style="font-size: 11px; padding: 0 0 0 20px; max-height: 100px; overflow-y: auto;">
                            <li *ngFor="let icd of getSelectedWoundICDs()" [class.pdpm_highlight]="icd.pdpm_status">
                                {{icd.code + ' (' + icd.mediumDescription +')'}}
                            </li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="column-header">
                            <span>Wound Status</span>
                        </div>
                        <div class="item" [class.selected]="isFieldValueSelected('active', 'state', false)"
                            (click)="selectFieldValue('active', 'state', false)">
                            Active
                        </div>
                        <div class="item" [class.selected]="isFieldValueSelected('remission', 'state', false)"
                            (click)="selectFieldValue('remission', 'state', false)">
                            In Remission
                        </div>
                        <div class="item" [class.selected]="isFieldValueSelected('amputated', 'state', false)"
                            (click)="selectFieldValue('amputated', 'state', false)">
                            Amputated
                        </div>
                        <div class="item" [class.selected]="isFieldValueSelected('archived', 'state', false)"
                            (click)="selectFieldValue('archived', 'state', false)">
                            Archive
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>