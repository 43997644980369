<div class="flex flex-col">
    <p style="font-size: 18px;">{{title}}</p>


    <label class="field field_v1">
        <input class="field__input" [(ngModel)]="texttoSend" matInput type="text" placeholder="Patient Full Name">
        <span class="field__label-wrap">

        </span>
    </label>
    <p style="font-size: 13px; margin-top: 7px; margin-bottom: 8px;">{{instruction}}</p>

    <div *ngIf="pccGlobalPatients&&facility_two?.source=='MatrixCare'" style="margin-top: 7px; margin-bottom: 13px;">
        <ngx-ui-loader loaderId="loader-0101"></ngx-ui-loader>

        <mat-radio-group [(ngModel)]="reason" (ngModelChange)="handleChangeReason($event)" class="flex flex-col gap-2 items-start" style="font-size: 12px;">
            <mat-radio-button *ngFor="let item of pccGlobalPatients" [value]="item"> {{item.first_name+'
                '+item.last_name+' - '+item.date_of_birth}}</mat-radio-button>

        </mat-radio-group>

    </div>

    <div *ngIf="pccGlobalPatients&&facility_two?.source=='PointClickCare'" style="margin-top: 7px; margin-bottom: 13px;">
        <ngx-ui-loader loaderId="loader-0101"></ngx-ui-loader>

        <mat-radio-group [(ngModel)]="reason" (ngModelChange)="handleChangeReason($event)" class="flex flex-col gap-2 items-start" style="font-size: 12px;">
            <mat-radio-button *ngFor="let item of pccGlobalPatients" [value]="item"> {{item.firstName+'
                '+item.lastName+' - '+item.birthDate}}</mat-radio-button>

        </mat-radio-group>

    </div>

    <mat-dialog-actions style="justify-content: center; margin-top: 10px;">
        <button mat-raised-button color="primary" (click)="select(true)">Search</button>
        <button mat-raised-button color="primary" [disabled]="disableStatus" (click)="close(true)">Submit</button>
        <button mat-raised-button color="warn" (click)="close(false)">Cancel</button> &nbsp;&nbsp;
    </mat-dialog-actions>
</div>