import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import * as global from '../global';
import { API_URL } from "../../../environments/api-url";

@Injectable({
  providedIn: 'root',
})
export class AdvancedNoteTextSearchService {
  currentUser: any;
  company_id: any;

  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthService,
    private commonService: CommonService
  ) {
    this.currentUser = this._authService.currentUser;
  }

  getExtractedNoteText(filter, project) {
    return this._httpClient.post(global.url + API_URL.EXTRACTED_NOTE_TEXT.getExtractedNoteText, { filter, project });
  }
  addNoteTextSearchHistory(payload) {
    return this._httpClient.post(global.url + API_URL.EXTRACTED_NOTE_TEXT.addNoteTextSearchHistory, payload);
  }
  getNoteTextSearchHistory(filter, project) {
    return this._httpClient.post(global.url + API_URL.EXTRACTED_NOTE_TEXT.getNoteTextSearchHistory, { filter, project });
  }
}