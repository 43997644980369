import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as global from '../includes/global';
import {API_URL} from "../../environments/api-url";
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AWSTranscribeService {
    currentUser: any;

    constructor(private httpclient: HttpClient, private authService:AuthService) {
        this.currentUser =this.authService.currentUser;
    }

    getAwsSignedUrl() {
        const details = {
            ...this.authService.authObject
        }
        return this.httpclient
            .post(global.url + API_URL.AWS.getAwsSignedUrl, {details: details}).pipe();
    }

}
