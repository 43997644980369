<div class="flex justify-center items-center gap-2" style="margin-top: 20px;">

    <ngx-ui-loader [loaderId]="woundSearchLoaderId"></ngx-ui-loader>

    <div class="w-1/3">
        <mat-form-field style="width: 100%">
            <mat-label>Search Wound (by _id) </mat-label>
            <input matInput type="text" name="wound" [(ngModel)]="wound_id">
        </mat-form-field>
        <button class="dn-button-primary" style="min-width: auto; margin-top: 15px;" (click)="fetchWound()"
            mat-raised-button>Fetch</button>
    </div>

    <div class="w-1/3 flex flex-col gap-2" *ngIf="wound && showDetails">
        <div class="flex-grow">{{"Patient ID : "+ wound.patient_id?._id}}</div>
        <div class="flex-grow">{{"Patient Name : "+ wound.patient_id?.last_name +" "+ wound.patient_id?.first_name}}</div>
        <div class="flex-grow">{{"Census ID : "+ wound.census_id?._id}}</div>
        <div class="flex-grow">{{"Census Name : "+ wound.census_id?.name}}</div>
    </div>

    <div class="w-1/3 flex flex-col gap-2" *ngIf="wound && showDetails">
        <div class="flex-grow">{{"Facility ID : "+ wound.facility_id?._id}}</div>
        <div class="flex-grow">{{"Facility Title : "+ wound.facility_id?.title}}</div>
        <div class="flex-grow">{{"Created By : "+ wound.census_id?.createdBy?.first_name + " "+
            wound.census_id?.createdBy?.last_name}}</div>
        <div class="flex-grow">{{"Last Assessment Date : "+ wound.last_assessment_date_obj?.day +"/"+
            wound.last_assessment_date_obj?.month +"/"+ wound.last_assessment_date_obj?.year}}</div>
    </div>

</div>

<div class="flex" style="margin-top: 50px">

    <div *ngIf="showCensusTable" class="w-1/2">
        <mat-card style="background:white; padding: 15px; border-radius: 10px;">
            <mat-card-title>Census Table</mat-card-title>
            <mat-table [dataSource]="censusTableDataSource">

                <ng-container matColumnDef="title">
                    <mat-header-cell *matHeaderCellDef> Title </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.date_obj?.day + "/" + element.date_obj?.month + "/" +
                        element.date_obj?.year}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="patient_count">
                    <mat-header-cell *matHeaderCellDef> Patient Count </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.patientList?.length }} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="censusTableColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: censusTableColumns;" (click)="onRowClick(row)"></mat-row>
            </mat-table>
        </mat-card>
    </div>

    <div *ngIf="showPatientTable" class="w-1/2">
        <mat-card style="background: white; padding: 15px; border-radius: 10px; margin-left: 10px;">
            <mat-card-title>Patient Table</mat-card-title>
            <mat-table [dataSource]="patientTableDataSource">

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.last_name + " " + element.first_name }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="gender">
                    <mat-header-cell *matHeaderCellDef> Gender </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.gender }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="dob">
                    <mat-header-cell *matHeaderCellDef> DOB </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.date_of_birth }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="move">
                    <mat-header-cell *matHeaderCellDef> Move </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button class="dn-button-primary" mat-raised-button (click)="moveWound(element)">Move
                            Wound</button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="patientTableColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: patientTableColumns;"></mat-row>

            </mat-table>
        </mat-card>
    </div>

</div>