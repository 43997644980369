<form #form="ngForm" (submit)="handleSubmit(form)">
    <div class="flex flex-col">

          <mat-form-field class="flex" appearance="fill">
            <mat-label>Code</mat-label>
            <input matInput placeholder="Enter Code" [(ngModel)]="newCPTmodel.code"
                #newcpt_code="ngModel" name="code" (change)="checkIsEmpty()" required />
            <mat-error *ngIf="newcpt_code.invalid">
                Please Enter Code
            </mat-error>
        </mat-form-field>

        <mat-form-field class="flex" appearance="fill">
            <mat-label>Description</mat-label>
            <input matInput placeholder="Enter Description" [(ngModel)]="newCPTmodel.description"
                #newcpt_description="ngModel" name="description" (change)="checkIsEmpty()" required />
            <mat-error *ngIf="newcpt_description.invalid">
                Please Enter Description
            </mat-error>
        </mat-form-field>

        <mat-form-field class="flex" appearance="fill">
            <mat-label>Place of Service</mat-label>
            <mat-select multiple placeholder="Place of Service" [(ngModel)]="pos_ids" (openedChange)="triggerEvent($event.value)"
                name="place_of_service">
                <mat-select-trigger>
                    <span *ngIf="place_of_service && place_of_service.length > 1" class="example-additional-selection">
                        {{place_of_service[0].name}}
                        (+{{ place_of_service.length - 1 }} {{ place_of_service.length === 2 ? 'other' :
                        'others' }})
                    </span>
                    <span *ngIf="place_of_service && place_of_service.length == 1">
                        {{place_of_service[0].name}}
                    </span>
                </mat-select-trigger>
                <mat-option *ngFor="let element of pos" [value]="element._id">
                    {{ element.name }}
                </mat-option>
            </mat-select>
        </mat-form-field> 

        <div class="flex flex-row items-baseline justify-start" style="gap: 200px">
        
            <mat-form-field class="unit">
                <mat-label>Unit</mat-label>
                <input matInput placeholder="Enter Unit" [(ngModel)]="newCPTmodel.default_unit" #newcpt_unit="ngModel" name="unit" type="number" min="1" (keypress)="keyPressNumbers($event)"/>
            </mat-form-field>

            <mat-checkbox (change)="setOncePerAdmit($event)" [checked]="newCPTmodel.use_once_per_admit" name="cpt">
                Use once per admit
            </mat-checkbox>
        
        </div>

        <div class="flex flex-row items-baseline justify-start" style="gap:200px">
            <mat-form-field>
                <input matInput [matDatepicker]="picker" [min]="this.current_date" placeholder="Choose Expiry Date"
                    [(ngModel)]="expiry_date" #newicd_end_date="ngModel" name="end_date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="flex justify-end gap-5" style="margin-top: 20px;">
            <button type="button" mat-raised-button class="dn-button-secondary" (click)="clearDetails()">
                Clear Details
            </button>
            <button mat-raised-button class="dn-button-primary" type="submit" [disabled]="isEmptyCodeDescription">
                Add New Code
            </button>
        </div>
    </div>
</form>


<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>

<div *ngIf="isShowTable">
    <mat-card>
        <div style="margin-top: 1rem;height: 18vh;overflow: auto;" class="flex flex-row justify-start items-start" > 
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="Code">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;">Code</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.code}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;">Description</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.description}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-card>

    <div style="margin-top: 1rem;" class="flex flex-row items-baseline gap-5">
        <mat-label>Do you want to add CPT Code {{newCPTmodel.code}} again? </mat-label>
        <button type="button" mat-raised-button class="dn-button-primary" type="submit" (click)="addDuplicateCode(event)" [disabled]="isButtonDisable">
            YES
        </button>
        <button mat-raised-button class="dn-button-secondary" [disabled]="isButtonDisable" (click)="closeDialogue(event)">
            NO
        </button>
    </div>
</div>