import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {Payor} from '../../../../../classes/payor';
import {AppointmentDataService} from '../../../service/data.service';
import {ToastrService} from 'ngx-toastr';
import {Appointment} from '../../../../../classes/appointment';
import {FacilityService} from '../../../../../services/facility.service';
import {PatientListService} from '../../../../../includes/patient-list/patient-list-service';
import {AuthService} from '../../../../../services/auth.service';
import moment from 'moment'
import {MatDialogRef, MatDialog} from "@angular/material/dialog";
import {NotifyType} from "../../../../../classes/notify-type";
import {PatientAddDialog} from '../../../../../includes/transcription-create/patient-add-dialog/patient-add-dialog.component';
import {NoteTypeDialog} from '../../../../../shared/popup-dialogs/note-type-dialog/note-type-dialog.component';
import { TitleService } from 'src/app/services/title.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators'
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-edit-provider-appointment-detail',
    templateUrl: './edit-provider-appointment-detail.template.html',
})
export class EditProviderAppointmentDetailComponent implements OnInit {
    @Input() appointmentId:string;
    @Output() didExit = new EventEmitter<boolean>();
    model = new Appointment();
    facilities = [];
    titles = [];
    patients = [];
    payors = [];
    provider = undefined;
    doctor = undefined;
    currentDate = new Date();
    test_time = '12:00 AM';
    minTime = moment().format('LT');

    patientControl = new FormControl();
    filteredPatients: Observable<any[]>;
    constructor(
                public service: AppointmentDataService,
                public facilityService: FacilityService,
                private titleService: TitleService,
                public patientService: PatientListService,
                public authService: AuthService,
                private toastr: ToastrService,
                private dialog: MatDialog) {
                
    }

    dateChange($event) {
        //
        let changedDate = moment($event.value).format('L');
        let momentDate = moment().format('L');
        if (moment(changedDate).isSame(momentDate)) {
            this.minTime = moment().format('LT');
            this.test_time = this.minTime;
        } else if (moment(changedDate).isBefore(momentDate)) {
            return;
        } else if (moment(changedDate).isAfter(momentDate)) {
            this.minTime = '0:00 AM';
            this.test_time = this.minTime;
        }
    }

    async updateAppointment(appointment) {
        //
        await this.processNotifyType(appointment);
        await this.processDateTime(appointment);
        // this.dialogRef.close();
        if(this.model.notify_type) {
            this.model.notify_type = new NotifyType()
        }
        this.model.provider_id = this.authService.authObject.id;
        this.service.updateAppointment({...this.model, patient_id: this.model.patient_id._id}).subscribe(data => {
                this.toastr.success('Appointment saved successfully', 'Success');
                this.service.updateData = true;
                this.didExit.emit(true)
            }, error => {
                
                this.toastr.error('something went wrong', 'Error');
            });
    }

    async processNotifyType(appointment) {
        //
        let notify_obj = new NotifyType();
        for (let i = 0; i < appointment.notify_type.length; i++) {
            notify_obj[appointment.notify_type[i]] = true;
        }
        this.model.notify_type = notify_obj;
    }

    async processDateTime(appointment) {
        //
        this.model.startTime = appointment.tos;
        let time = appointment.tos.split(' ');
        const meredium = time[1];
        time = time[0].split(':');
        if (meredium === 'PM' && time[0] !== '12') {
            // tslint:disable-next-line:radix
            time[0] = parseInt(time[0]) + 12;
            // tslint:disable-next-line:radix
            time[1] = parseInt(time[1]);
        }
        if (meredium === 'AM' && time[0] === '12') {
            // tslint:disable-next-line:radix
            time[0] = parseInt(time[0]) + 12;
            // tslint:disable-next-line:radix
            time[1] = parseInt(time[1]);
        }
        // tslint:disable-next-line:radix
        time[0] = parseInt(time[0]);
        // tslint:disable-next-line:radix
        time[1] = parseInt(time[1]);
        const dos = new Date(appointment.dos);
        dos.setHours(time[0]);
        dos.setMinutes(time[1]);
        this.model.start = dos;
        this.model.dos = dos;
        this.model.tos = new Date();
        this.model.tos.setHours(time[0]);
        this.model.tos.setMinutes(time[1]);
    }

    ngOnInit() {
        this.doctor = this.authService.currentUser.first_name + ' ' +
            this.authService.currentUser.last_name + ', ' + this.authService.currentUser.title;
        // this.provider = this.authService.currentUser.first_name + ' ' +
        //     this.authService.currentUser.last_name + ', ' + this.authService.currentUser.title;
        this.loadData();
    }

    loadData() {
        console.log('loading data');
        
        this.service.getAppointmentById({_id: this.appointmentId}).subscribe( data => {
            this.model = data.data;
            
            // @ts-ignore
            this.provider = this.model.provider_id;
            // @ts-ignore
            this.selectedPatient = [this.model.patient_id];
            this.model = {
                ...this.model,
                // @ts-ignore
                facility_id: this.model.facility_id._id,   
                // // @ts-ignore
                // patient_id: this.model.patient_id._id,   
                
                // @ts-ignore
                payor_id: this.model.payor_id._id,   
                // @ts-ignore
                provider_id: this.model.provider_id._id,   
                // @ts-ignore
                notify_type: this.model.notify_type ? Object.keys(this.model.notify_type).filter(key => this.model.notify_type[key]):[],
                // @ts-ignore
                title_id: this.model.title_id._id,   
                
                // @ts-ignore
                notify_time: this.model.notify_time.toString(),

                // @ts-ignore
                is_video: this.model.is_video.toString(),
            };
            console.log({
                data: data.data,
                model: this.model
            });
            
            
            // this.processNotifyType(this.model);
            // this.processDateTime(this.model);
            // this.loaded = true;
        }, error => {
            this.toastr.error('something went wrong', 'Error');
        });
        this.service.getPayors().subscribe(data => {
            this.payors = data.data.array;
        }, error => {
            this.toastr.error('something went wrong', 'Error');
        });
        this.facilityService.getMyFacilities().subscribe(data => {
            
            // @ts-ignore
            this.facilities = data.data.array;
        }, error => {
            this.toastr.error('something went wrong', 'Error');
        });
        this.titleService.getMyTitles().subscribe(data => {
            // @ts-ignore
            this.titles = data.data.array;
        }, error => {
            this.toastr.error('something went wrong', 'Error');
        });
        this.patientService.getPatients(this.authService.authObject).subscribe(data => {
            
            // @ts-ignore
            this.patients = data.data.array;
        }, error => {
            this.toastr.error('something went wrong', 'Error');
        });

        this.filteredPatients = this.patientControl.valueChanges
        .pipe(
            startWith(''),
            map((value:any) => typeof value === 'string' ? value : value.name),
            map((name:any) => name ? this._filterPatient(name) : this.patients.slice())
        );
    }
    displayPatientFn(patient): string {
        return patient && patient.name ? patient.name : '';
    }

    private _filterPatient(name: string): any[] {
        const filterValue = name.toLowerCase();

        return this.patients.filter(patient => patient.name.toLowerCase().indexOf(filterValue) === 0);
    }
    
    showPatientAddDialog() {
        if (!this.model.facility_id) {
            return this.toastr.error('Please select faciltiy first', 'Failed');
        }
        const dialogRef = this.dialog.open(PatientAddDialog, {
            width: '80%',
            data: {
                selectedFacilityId: this.model.facility_id,
                selectedDoctorId: this.model.provider_id
            }
        });
        dialogRef.beforeClosed().subscribe(async (patient: any) => {
            if (patient && patient._id) {
                // patient.name = this.commonService.getPatientFullNameFormat2(patient);
                this.model.patient_id = patient;
                this.patients = [...this.patients, patient];
            }
        });
    }
    showTitleAddDialog() {
        const dialogRef = this.dialog.open(NoteTypeDialog, {
            width: '80%'
        });
        dialogRef.beforeClosed().subscribe(async (noteType: any) => {
            if(noteType && noteType._id) {
                this.titles = [...this.titles, noteType];
                this.model.title_id = noteType._id;
            }
        });
    }
    

    hasSelectedPatientEmail(selectedPatient) {
        if(selectedPatient) {
            const patient = this.patients.find(p => p._id === selectedPatient._id)
            return patient && patient.email;
        }
        return false;
    }
}
