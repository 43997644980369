<mat-sidenav-container>
    <main>
        <div class="container">
            <div class="white-rounded-rect-new flex flex-col" style="height: 87vh;position: relative;">
                <div class="flex justify-between items-center">
                    <div class="patients-page flex items-center">
                        <mat-form-field style="width: 155px;text-align-last:left; margin-right: 10px;">
                            <mat-select [(ngModel)]="selectedStatus" (selectionChange)="filterStatus($event)">
                                <mat-option disabled value="">Filter by status</mat-option>
                                <mat-option value="">All</mat-option>
                                <mat-option value="pending">Pending</mat-option>
                                <mat-option value="In progress">In progress</mat-option>
                                <mat-option value="Completed">Completed</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field style="width: 155px;text-align-last:left; margin-right: 10px;">
                            <mat-select [(ngModel)]="selectedPriority" (selectionChange)="changePriority($event)">
                                <mat-option disabled value="">Filter by priority</mat-option>
                                <mat-option value="">All</mat-option>
                                <mat-option value="Low">Low</mat-option>
                                <mat-option value="Medium">Medium</mat-option>
                                <mat-option value="High">High</mat-option>
                                <mat-option value="Critical">Critical</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field floatLabel="never">
                            <mat-label style="margin-left: 5px;">Search Username</mat-label>
                            <input type="text" aria-label="user" matInput
                                [formControl]="userControl" [matAutocomplete]="providerAutoControl">
                            <mat-autocomplete #providerAutoControl="matAutocomplete">
                                <mat-option (click)="filterUserName()" value="">All</mat-option>
                                <mat-option *ngFor="let user of filteredUsers | async" [value]="user.full_name"
                                    (click)="filterUserName(user);">
                                    {{user.full_name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <mat-form-field style="width: 200px;text-align-last:left; margin-left: 10px;" *ngIf="currentUser.product_owner">
                            <mat-select [(ngModel)]="selectedCompanyName">
                                <mat-option disabled>Filter by Company</mat-option>
                                <mat-option (click)="filterCompany();" value="All">All</mat-option>
                                <mat-option *ngFor="let company of allCompanies" value="{{company.name}}" (click)="filterCompany(company);">
                                    {{company.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <button mat-raised-button class="dn-button-primary" style="height: 35px;margin-bottom: 11px;"
                            (click)="clearFilters()">
                            Clear filter
                        </button>
                    </div>
                </div>

                <div style="position: relative;
                overflow: auto;height: 70vh;">
                    <table mat-table [dataSource]="dataSource">

                        <ng-container matColumnDef="Ticket No.">
                            <th mat-header-cell *matHeaderCellDef style="width: 6%;"> # </th>
                            <td mat-cell *matCellDef="let element;let i = index;" (click)="showDetails(element);"
                                style="cursor: pointer;">
                                <ng-container *ngIf="pageNo == 1">
                                    {{ i + 1 }} 
                                </ng-container>

                                <ng-container *ngIf="pageNo > 1">
                                    {{ i + (pageNo - 1) * 50 + 1}}
                                </ng-container>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Title">
                            <th mat-header-cell *matHeaderCellDef> Title </th>
                            <td mat-cell *matCellDef="let element" (click)="showDetails(element);"
                                style="cursor: pointer;">
                                {{element.title}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Updation Date">
                            <th mat-header-cell *matHeaderCellDef style="width: 10%;">Date </th>
                            <td mat-cell *matCellDef="let element" (click)="showDetails(element);" style="cursor: pointer;">
                                {{element.updatedAt | date}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Priority">
                            <th mat-header-cell *matHeaderCellDef style="width: 10%;">Priority</th>
                            <td mat-cell *matCellDef="let element"
                                [ngClass]="{'lowPriority':element.priority === 'Low','mediumPriority':element.priority === 'Medium','highPriority':element.priority === 'High','criticalPriority':element.priority === 'Critical'}"
                                style="cursor: pointer;"
                                (click)="showDetails(element);">
                                {{element.priority}}</td>
                        </ng-container>

                        <ng-container matColumnDef="CreatorName">
                            <th mat-header-cell *matHeaderCellDef style="width: 10%;">Username</th>
                            <td mat-cell *matCellDef="let element" style="cursor: pointer;" (click)="showDetails(element);">
                                <ng-container *ngIf="element.creator_name">
                                    {{element.creator_name.first_name}} {{element.creator_name.last_name}} {{element.creator_name.title}} 
                                </ng-container>
                                <ng-container *ngIf="element.creator_id">
                                    {{element.creator_id.first_name}} {{element.creator_id.last_name}} {{element.creator_id.title}}
                                </ng-container>
                                <ng-container *ngIf="element.first_name">
                                    {{element.first_name}} {{element.last_name}}
                                </ng-container>
                            </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="patientName">
                            <th mat-header-cell *matHeaderCellDef style="width: 10%;">Patient Name</th>
                            <td mat-cell *matCellDef="let element" style="cursor: pointer;" (click)="showDetails(element);">
                                <ng-container *ngIf="element.patient_name">
                                    {{element.patient_name}}
                                </ng-container>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="company">
                            <th mat-header-cell *matHeaderCellDef style="width: 10%;">Company</th>
                            <td mat-cell *matCellDef="let element" style="cursor: pointer;" (click)="showDetails(element);">
                                <ng-container *ngIf="element.creator_name">
                                    {{element.creator_name.company_name}}
                                </ng-container>
                                <ng-container *ngIf="element.creator_id">
                                    {{element.creator_id.company_name}}
                                </ng-container>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Status">
                            <th mat-header-cell *matHeaderCellDef style="width: 10%;">Status</th>
                            <td mat-cell *matCellDef="let element">
                                <div style="margin-top: 15px;" class="patients-page flex flex-col flex-grow">
                                    <mat-form-field style="width: 115px;">
                                        <mat-select [(ngModel)]="element.status"
                                            (selectionChange)="changeStatus($event,element)">
                                            <mat-option disabled value="">Ticket Status</mat-option>
                                            <mat-option value="pending">Pending</mat-option>
                                            <mat-option value="In progress">In progress</mat-option>
                                            <mat-option value="Completed">Completed</mat-option>
                                        </mat-select>
                                    </mat-form-field>


                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>

                <div style="position: absolute;bottom: 0px;left:42%" class="table-nav flex justify-center items-center gap-2">
                    
                        <button mat-raised-button class="dn-button-primary" (click)="goToPreviousPage()"
                            [disabled]="!pagination.hasPrevPage">
                            Prev</button>
                        <span style="background-color: #fff; padding: 5px; font-size:12px;">
                            {{pagination.skip + ticketsData.length === 0 ? 0 : pagination.skip + 1 }} - {{pagination.skip + ticketsData.length}}
                            out of {{pagination.totalRecords}}
                        </span>
                        <button mat-raised-button class="dn-button-primary" (click)="goToNextPage()"
                            [disabled]="!pagination.hasNextPage">Next</button>
                    
                </div>
            </div>
        </div>
    </main>
    <mat-sidenav mode="side" [(opened)]="showDetail" *ngIf="showDetail" position="end">
        <div
            style="background-color: #fff;padding: 65px 0px 110px 0px !important;overflow-y: hidden;overflow-x: hidden; padding-bottom: 33px;">
            <app-ticket-details *ngIf="sideBar" (showDetail)="showDetail=$event" (updateStatus)="updateStatus($event)" (filterStatus)="filterStatusParent($event)" [rowData]="rowData"></app-ticket-details>
        </div>
    </mat-sidenav>
</mat-sidenav-container>