import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import * as global from '../global';
import {AuthService} from 'src/app/services/auth.service';
import {API_URL} from "../../../environments/api-url";

@Injectable({
    providedIn: 'root',
})
export class ActivityLogService {

    currentUser: any;
    constructor(private httpclient: HttpClient,
        private _authService: AuthService) {    
        this.currentUser = this._authService.currentUser;
    }

    getActivityLog(pageNo, filter=null) {
        var item ={
          id: this.currentUser._id,
          auth_key: this.currentUser.auth_key,
          usertype: this.currentUser.user_type,

          pageNo,
          filter
        };
            
        return this.httpclient.post(global.url + API_URL.ROUTES.getActivityLogs, {details: item}).pipe();
    }
}
