import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FacilityService } from 'src/app/services/facility.service';

@Component({
  selector: 'app-company-facility-permissions',
  templateUrl: './company-facility-permissions.component.html',
  styleUrls: ['./company-facility-permissions.component.css']
})
export class CompanyFacilityPermissionsComponent implements OnInit {
  companyId: any;
  facilityId: any;
  facility: any;

  permissionsInfo = {
    'pcc_inactiveDate': {
      label: 'Inactive Date',
      description: "Represents the date at which a facility became inactive.",
    },
    'pcc_isUploadEnabled': {
      label: 'Document Upload',
      description: "Indicates if the facility has the option to upload document enabled or not.",
    },
    'pcc_isOmpiEnabled': {
      label: 'Ompi',
      description: "Indicates if the facility has the option to use organization level patient identifiers enabled or not.",
    },
    'pcc_isTrackingHiePrivacyConsent': {
      label: 'Tracking Hie Privacy Consent',
      description: "Indicates if the facility is tracking patients' consent to allow staff involved in their care to see and get access to all their medical records available through the Health Information Exchange. The ability to track patient consent for the aforementioned case is only available to the facility if they have a specific type of integration enabled.",
    }
  }
  permissionKeys = Object.keys(this.permissionsInfo);
  displayedColumns: string[] = ['label', 'value'];

  loaderId = 'app-company-facility-permissions';

  constructor(
      private _loader: NgxUiLoaderService,
      private _route: ActivatedRoute,
      private facilityService: FacilityService,
    ) {


    this.companyId = this._route.parent.parent.snapshot.params.id;
    this.facilityId = this._route.snapshot.params.id;
   }

  ngOnInit(): void {
    this.initFacility();
  }
  initFacility() {
    
    this._loader.startLoader(this.loaderId);

    this.facilityService.getFacility(this.facilityId).subscribe((response: any) => {
        if (response.status === 200) {
            this.facility = response.data;
        }
        this._loader.stopLoader(this.loaderId);
    });

  }

  permissionsRecorded() {
    const keys = this.permissionKeys;
    // if any of key exist in facility object, then return true
    return keys.some(key => this.facility[key]);
  }
  

  getPermissionLabel(key) {
    return this.permissionsInfo[key].label;
  }
  getPermissionValue(key) {
    return this.facility[key];
  }

  getPermissionDescription(key) {
    return this.permissionsInfo[key].description;
  }

  typeof(value) {
    return typeof value;
  }
    


}
