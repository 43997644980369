import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/services/auth.service';
import { ProcedureDataset, ProcedureType } from '../procedure-datasets.component';
import { ProcedurePhraseEditorComponent } from '../procedure-phrase-editor/procedure-phrase-editor.component';

@Component({
  selector: 'app-procedure-category-templates',
  templateUrl: './procedure-category-templates.component.html',
  styleUrls: ['./procedure-category-templates.component.css']
})
export class ProcedureCategoryTemplatesComponent implements OnInit {
  @Input() company_id: string;
  @Input() procedureDatasets: ProcedureDataset[] = [];
  @Input() activeProcedureType: ProcedureType;
  @Input() procedureNotePhrases: {}[] = [];
  @Input() companyPermissions: { should_show_procedure_note: false };
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['category', 'phrase', 'template'];
  currentUser: any;
  constructor(private dialog: MatDialog, private _authService: AuthService) {
    this.currentUser = this._authService.currentUser;
    if (this.currentUser.admin_type == 'system_admin') {
      this.displayedColumns.push('operation');
    }
  }

  ngOnInit(): void {
    this.dataSource.data = this.procedureDatasets;
  }
  showEditorDialog(element, index) {
    var data = {
      procedureDatasets: element,
      procedureNotePhrases: this.procedureNotePhrases,
      should_show_procedure_note: this.companyPermissions.should_show_procedure_note,
      phrase_key: this.getPhraseKey(element.category),
      company_id: this.company_id
    }
    this.openPhraseEditor(data, index);
  }
  openPhraseEditor(data, index) {
    const dialogRef = this.dialog.open(ProcedurePhraseEditorComponent, {
      width: '800px',
      data: data
    });
  }
  getPhraseKey(category){
    let phrase_key = "." + this.activeProcedureType.title.toLowerCase().replace(' ','_') + "_" + category.toLowerCase().replace(' ','_');
    return phrase_key;
  }
  convertHtmltotext(string){
    let strippedHtml = string == undefined ? '' : string?.replace(/<[^>]+>/g, '');
    return strippedHtml.length < 102 ? strippedHtml : strippedHtml.substr(0,102) + '...';
  }
  deletetemplate(element){

  }

}
