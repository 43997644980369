import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { FacilityService } from 'src/app/services/facility.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { BillingCompanyAssociationService } from '../billing-company-association/billing-company-association.service';
import { CompaniesConfigrationService } from './companies-configration.service';

@Component({
  selector: 'app-companies-configration',
  templateUrl: './companies-configration.component.html',
  styleUrls: ['./companies-configration.component.css']
})
export class CompaniesConfigrationComponent implements OnInit {
  currentUser: any;
  companyName = "";
  companyID: '';
  selectedCompany: any;
  // selectedFacilityId: any;
  // selectedFacility: any;
  // selectedProviderId: any;
  selectedBillingCompanyId: any;
  searchCompanyText: any;
  // searchFacilityText: any;
  // searchProviderText: any;
  searchBillingCompanyText: any;
  // facilities = [];
  companies = [];
  // providers = [];
  billingCompanies = [];
  filter = {
    company_id: "",
    is_active: true
  };
  model = {
    company_id:"",
    billingCompanyId:"",
    method: '',
    protocol: '',                                  
    endURL: '',                                  
    // facility_id:"",
    // provider_id: "",
    // external_provider_id:"",
    // external_facility_id:""
  };
  projection = {};
  methodTypes = ['PUSH', 'PULL']
  protocols = ['SFTP', 'HTTPS', 'HTTP'];
  billId = true;
  isMethod = true;
  isProtocl = true;
  isURL = true;
  updateData = false;
  companiesConfigration = [];
  public dataSource: any;
  updateDataID = null
  displayedColumns: string[] = ['company_name',  'billing_company', 'method_type', 'protocol', "url","delete"];

  constructor(
    private authService: AuthService,
    private companyService: CompanyService,
    private billingCompanyAssociationService: BillingCompanyAssociationService,
    private facilityService: FacilityService,
    private companiesConfigrationService: CompaniesConfigrationService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) {
    this.currentUser = this.authService.currentUser;
    this.companyID =  this.route.parent.snapshot.params.id
   }

  async ngOnInit(){
    this.filter = { 
      is_active: true,
      company_id: this.companyID,
    }
    this.projection = {
      'title': 1,
      'address': 1,
      'city': 1,
      'state': 1,
      'zip_code': 1,
      'is_active': 1
    }
    if(this.currentUser.product_owner){
      let res:any = await this.companyService.getAllCompanies().toPromise();
      if(res.status == 200 ){
        this.companies = res.data
        this.filter.company_id = this.companies[0]._id;
        this.model.company_id = this.companies[0]._id;
        this.selectedCompany = this.companies[0];
      }
    }
    else{
      let res:any = await this.companyService.getCompanyName(this.companyID).toPromise();
      if(res.status ==  200){
        this.companyName = res.data.name;
        this.selectedCompany = res.data;
        this.model.company_id = this.selectedCompany._id
      }
    }
    await this.getCompaniesConfigration();
    await this.getBillingCompanies();
    // this.loadData()
  }

  async getBillingCompanies(){
    let res:any = await this.billingCompanyAssociationService.getBillingCompanies().toPromise();
    if(res.status == 200){
      this.billingCompanies = res.data;
    }
  }
  

  applyFilter(filterValue: string){

  }

  applyCompanyFilter(event){ 
    this.filter.company_id = event.value;
    let com = this.companies.map(c => {
      if(c._id == this.filter.company_id){
        return c;
      }
    }).filter(a=> a)
    this.selectedCompany = com[0];
    this.model.company_id = this.selectedCompany._id
    // console.log("this.selectedCompany----------- ", this.selectedCompany);
    // this.loadData()
    this.getCompaniesConfigration();
  }


  applyBillingCompnayChange(event){
    this.selectedBillingCompanyId = event.value;
    // this.getProviders()
  }
  
  async getCompaniesConfigration(){
    let response:any = await this.companiesConfigrationService.getCompaniesConfigration(this.model.company_id).toPromise();
    if(response.status == 200){
      this.companiesConfigration = response.data;
      this.dataSource = new MatTableDataSource(this.companiesConfigration); 
      
    }
  }

  async createConfigration(configID = null){  
    if(!this.model.billingCompanyId){
      return this.billId = false
    }
    else if(!this.model.method){
      return this.isMethod = false
    }
    else if(!this.model.protocol){
      return this.isProtocl = false
    }   
    else {
      let res:any = await this.companiesConfigrationService.setCompaniesConfigration(this.model, configID).toPromise();
      if(res.status == 200){
        this.billId = true;
        this.isMethod = true;
        this.isProtocl = true;
        this.isURL = true;
        this.model = {
          company_id: this.selectedCompany._id,
          billingCompanyId:"",
          method: '',
          protocol: '',                                  
          endURL: '', 
        }
        this.updateDataID = null;
        this.updateData = false;
        this.getCompaniesConfigration()
        if(res.status == 200){
          this.toastr.success(res.message)
        }
        else{
          this.toastr.error(res.message)
        }
      }
      else{
        this.toastr.error(res.message)
      }
    }
  }

  onEditClick(element){
    this.updateDataID = element._id;
    this.updateData = true;
    this.model.billingCompanyId = element.billingCompanyId._id;
    this.model.method = element.method;
    this.model.protocol = element.protocol;
    this.model.endURL = element.url;
  }
  onDeleteClick(element){
    let response: any = this.companiesConfigrationService.deleteConfigration(element._id).toPromise();
    if(response.status == 200){ 
      let arr = this.companiesConfigration.filter(a => a._id != element._id )
      this.dataSource = new MatTableDataSource(arr); 

      this.toastr.success("Configration deleted")
    }
  }
  updateConfigration(){
    this.createConfigration(this.updateDataID)
  }
  cancel(){
    this.updateData = false
    this.model = {
      company_id: this.selectedCompany._id,
      billingCompanyId:"",
      method: '',
      protocol: '',                                  
      endURL: '', 
    }
  }

}
