import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppointmentUtilityService } from 'src/app/services/appointment-utility.service';

@Component({
  selector: 'app-edit-recurring-event-dialog',
  templateUrl: './edit-recurring-event-dialog.component.html',
  styleUrls: ['./edit-recurring-event-dialog.component.css']
})
export class EditRecurringEventDialogComponent implements OnInit {


  selectedOption: string = 'single';
  
  constructor(public dialogRef: MatDialogRef<EditRecurringEventDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: any,private appointmentUtility: AppointmentUtilityService) {}
  ngOnInit(): void {
  }

  ok(): void {
    const excludedDate = this.removeDateFromArray(this.data.occurrences,this.data)
    this.addUniqueDates(excludedDate,this.data.exDate)
    this.data.start_time =this.data.startTime
    this.data.end_time =this.data.endTime
    const {rules, occurrences} = this.appointmentUtility.generateRecurringRule(this.data.repeating,this.data);
		this.data.recurrenceRule = rules;
    this.data.occurrences = occurrences
    this.data.deleteRecurrenceEvents = this.selectedOption === 'all' || this.data.repeating === 'Does not Repeat';
    this.dialogRef.close(this.data);
  }

  cancel(): void {
    this.dialogRef.close();
  }
  removeDateFromArray(dateArray, specificDate) {
    switch (this.selectedOption) {
        case "single":
            // Remove the specific date object from the array
            dateArray = dateArray.filter(dateObj => {
                return this.isSameDate(dateObj.date, this.appointmentUtility.convertToDateObj(specificDate.start));
            });
            break;
        case "following":
            // Remove all date objects equal to or greater than the specific date
            dateArray = dateArray.filter(dateObj => {
                return this.isDateGreaterThanOrEqualTo(dateObj.date, this.appointmentUtility.convertToDateObj(specificDate.start));
            });
            break;
        default:
            // Default case: do nothing
            break;
    }
    return dateArray;
  }

// Helper function to check if two dates are the same
 isSameDate(date1, date2) {
    return date1.day === date2.day &&
        date1.month === date2.month &&
        date1.year === date2.year;
 }

// Helper function to check if one date is greater than another
isDateGreaterThanOrEqualTo(date1, date2) {
    if (date1.year > date2.year) {
        return true;
    } else if (date1.year === date2.year) {
        if (date1.month > date2.month) {
            return true;
        } else if (date1.month === date2.month) {
            return date1.day >=  date2.day;
        }
    }
    return false;
 }
  addUniqueDates(array1: any[], array2: any[]): void {
    for (const date1 of array1) {
        let isUnique = true;
        for (const date2 of array2) {
            if (this.isEqualDate(date1.date, date2.date)) {
                isUnique = false;
                break;
            }
        }
        if (isUnique) {
            array2.push(date1);
        }
    }
  }
  isEqualDate(date1: { day: number, month: number, year: number }, date2: { day: number, month: number, year: number }): boolean {
    return date1.day === date2.day && date1.month === date2.month && date1.year === date2.year;
  }

}
