<div class="container">
  <div style="display: flex;margin: 8px;place-content: space-between;">
    <div style="display: flex">
      <button class="theme-btn" mat-raised-button class="dn-button-secondary" style="margin-right: 20px;"
        (click)="goBack()">Go Back</button>
    </div>
    <div style="display: flex; ">
      <mat-radio-group [(ngModel)]="activatedRoute" (ngModelChange)="changeRoute()" class="flex gap-5" *ngIf="!addUser">
        <mat-radio-button value="doctor-list">Providers</mat-radio-button>
        <mat-radio-button value="transcriber-list">Transcribers</mat-radio-button>
        <mat-radio-button value="biller-list">Billers</mat-radio-button>
        <mat-radio-button value="medical_assistant-list">Medical Assistant</mat-radio-button>
      </mat-radio-group>
    </div>
    <div style="display: flexks;">
      <button mat-raised-button class="dn-button-primary" (click)="addUserClicked()">
        Add User
      </button>
    </div>
  </div>
  <span  *ngIf="!addUser">
    <router-outlet ></router-outlet>
  </span>
  <div *ngIf="addUser" style="background: #eeeeee !important; padding: 16px !important;" >
    <app-company-user-management-form (cancelClicked)="cancelClicked()" ></app-company-user-management-form>
  </div>

</div>