<mat-card *ngIf="!editMode">

    <mat-card-content>
        <div 
        style="max-width: 700px;">
        <div class="flex justify-between items-center">
            <h2 style="color: black;margin:0">Appointment Detail</h2>
            <div class="flex justify-end items-center gap-2">
                <i class="icon icon-edit"
                    *ngIf="editMode === false"
                    (click)="editMode = true"
                    matTooltip="Edit Appointment" matTooltipPosition="above"></i>
                    <i class="icon icon-delete"
                    (click)="deleteAppointment()"
                    matTooltip="Delete Appointment" matTooltipPosition="above"></i>
                    <button mat-raised-button
                            class="dn-button-primary"
                            (click)="exitToParent()"
                            type="button">
                        <mat-icon>keyboard_backspace</mat-icon>
                        Go to Calendar
                    </button>
                    <button mat-raised-button
                            type="button"
                            (click)="goToMeeting()"
                            [disabled]="!model.is_video"
                            class="dn-button-primary">
                        <mat-icon>call</mat-icon>
                        Go to Meeting
                    </button>
                </div>
            </div>
            <form
                *ngIf="loaded"
                class="flex flex-col" (ngSubmit)="addAppointment(form.value)" #form="ngForm">
    
                <mat-form-field>
                    <mat-label>Title</mat-label>
                    <input matInput
                            readonly
                            name="title"
                            [(ngModel)]="model.title_id.title">
                </mat-form-field>
    
                <mat-form-field>
                    <mat-label>Facility</mat-label>
                    <input matInput
                            readonly
                            name="facility_id"
                            [(ngModel)]="model.facility_id.title">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Patient Email</mat-label>
                    <input matInput
                            readonly
                            name="patient_email"
                            [(ngModel)]="model.patient_email">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>IME Vendor</mat-label>
                    <input matInput
                            readonly
                            name="payor_id"
                            [(ngModel)]="model.payor_id.abbreviation">
                </mat-form-field>
                <mat-card class="meetinginfo-card" style="margin-bottom: 15px;">
                    <mat-card-content>
                        <div class="flex flex-col gap-5">
                            <div class="meetinginfo-card-label">Meeting Link</div>
                            <div class="meetinginfo-card-value">{{meetingLink}}</div>
                            <div class="meetinginfo-card-label">Meeting ID</div>
                            <div class="meetinginfo-card-value">{{appointmentId}}</div>  
                        </div>
                        <mat-icon class="meetinginfo-card-icon"
                        (click)="copyLink(meetingLink)"
                        color="primary">file_copy</mat-icon>
                    </mat-card-content>
                </mat-card>
                
                <div class="flex flex-col gap-2">
                    <div class="flex items-baseline gap-2">
                        <mat-label>Reminder</mat-label>
                        <mat-form-field>
                            <mat-label>Type</mat-label>
                            <input matInput
                                    readonly
                                    name="notify_type"
                                    [value]="printNotifyTypes(model.notify_type)">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Time</mat-label>
                            <input matInput
                                    readonly
                                    name="notify_time"
                                    [value]="model.notify_time + ' minutes earlier'">
                        </mat-form-field>
                    </div>
                    
                    <div>
                        <mat-checkbox
                        name="video"
                        [checked]="model.is_video">
                            Video Conference
                        </mat-checkbox>
                    </div>
                </div>
                <mat-form-field>
                    <input matInput
                            [matDatepicker]="picker"
                            name="dos"
                            #dos="ngModel"
                            [(ngModel)]="model.dos"
                            readonly
                            placeholder="Appointment date">
                    <mat-datepicker-toggle disabled matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker disabled #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Appointment start time</mat-label>
                    <input matInput
                            readonly
                            name="tos"
                            [(ngModel)]="model.startTime">
                </mat-form-field>
    
                <div class="flex justify-between items-center">
                    <mat-label>Patient Status</mat-label>
                    <div class="flex gap-2">
                        <span class="patient_status" [class.patient_status_no_show]="model.patient_id.appointment_status === 'no_show'" (click)="updatePatientStatus('no_show')">No Show</span>
                        <span class="patient_status" [class.patient_status_seen]="model.patient_id.appointment_status === 'seen'" (click)="updatePatientStatus('seen')">Seen</span>
                        <span class="patient_status" [class.patient_status_cancel]="model.patient_id.appointment_status === 'cancel'" (click)="updatePatientStatus('cancel')">Cancel</span>
                    </div>
                </div>
            </form>
        </div>
    </mat-card-content>
</mat-card>
<app-edit-user-appointment-detail [appointmentId]="model._id"
                                  (didExit)="edited()"
                                  *ngIf="editMode"></app-edit-user-appointment-detail>
