<div style='background-color: #fff;padding-bottom: 10px;' *ngIf="data.type == 'diagnosis'">
  <h2>Diagnosis List</h2>
    <app-conditions *ngIf="patient.source == 'PointClickCare'" [patient]="patient"></app-conditions>
    <app-matrix-conditions *ngIf="patient.source == 'MatrixCare'" [patient]="patient"></app-matrix-conditions>
    <app-patient-diagnosis *ngIf="patient.source != 'MatrixCare' && patient.source != 'PointClickCare'" [patient]="patient"></app-patient-diagnosis>
  </div>
  <div style='background-color: #fff;padding-bottom: 15px;' *ngIf="data.type == 'medication'">
    <h2>Medication List</h2>
    <app-medications *ngIf="patient.source == 'PointClickCare'" [patient]="patient">
    </app-medications>
    <div *ngIf="patient.source !== 'PointClickCare'" >Nothing to show</div>
  </div>