<app-header *ngIf="!admin && !company"></app-header>

<main>

  <div class="container">

    <h3 class="dark-blue-heading">
      {{ isEditing? 'Edit Patient' : 'Add Patient'}}
    </h3>

    <div class="patient-add" style="background: #f3f3f3 !important;padding: 20px;">
      <app-patient-add-form
      (onSaveClick)="onSaveClick($event)"
      (onCancelClick)="onCancelClick()"
      (isEditingPatient)="isEditingPatient($event)"
      ></app-patient-add-form>
    </div>

  </div>
</main>
