
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { FacilityService } from 'src/app/services/facility.service';
import { PCCService } from 'src/app/services/pcc.service';
import { PatientListService } from '../../patient-list/patient-list-service';


@Component({
  selector: 'app-testinput-dialogue',
  templateUrl: './testinput-dialogue.component.html',
  styleUrls: ['./testinput-dialogue.component.css']
})
export class TestinputDialogueComponent implements OnInit {

  title:String;
  input:{};
  texttoSend:'';
  instruction:String;
  facility_two: any;
  item: any;
  pccGlobalPatients: any;
  disableStatus:any;
  selectedPatient:any;
  constructor( @Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<TestinputDialogueComponent>,
  private _authService: AuthService,
  private loader: NgxUiLoaderService,
  private pccService: PCCService,
  private facilityService: FacilityService,
  private _patientListService: PatientListService,
  private toastr: ToastrService
  ) { 
  }

  ngOnInit(): void {
    this.title = this.data.title;
    this.input=this.data.input;
    this.instruction=this.data.instruction;
    this.facility_two=this.data.input.facility;
    this.item=this.data.input.item;
    this.disableStatus=true;
  }

  async select(choice){
    let textdata=this.texttoSend;
    this.disableStatus=true;
    let first_name_only= textdata?.split(" ", 2)[0];
    let last_name_only =textdata?.split(" ", 2)[1];
    
    console.log("last_name_only : ", last_name_only);

    if(this.facility_two?.source=="PointClickCare"){
      if(last_name_only)
      await this.initPccPatientSingleManual(this.facility_two, this.item?.dn, first_name_only?.trim(), last_name_only?.trim(), false );
      else
      await this.initPccPatientSingleManual(this.facility_two, this.item?.dn, first_name_only?.trim(), null, false );
    }else if(this.facility_two?.source=="MatrixCare"){
      // console.log('Patient is Matrix---')
      if(last_name_only)
      await this.initPccPatientSingleManual_matrix(this.facility_two, this.item?.pcc, first_name_only?.trim(), last_name_only?.trim(), false );
      else
      await this.initPccPatientSingleManual_matrix(this.facility_two, this.item?.pcc, first_name_only?.trim(), null, false );
    }
  }

  handleChangeReason(item){
    console.log("handleChangeReason itm:", item);
    this.selectedPatient=item;
    this.disableStatus=false;

  }

  async initPccPatientSingleManual(facility, dn_pat, first_name, last_name, all=false) {
    if (!facility) return;
    const { pcc_facId: pccFacId, pcc_orgUuid } = facility;
    console.log("pcc_facId pcc_facId", pccFacId);
    if (!pccFacId) {
      return;
    }

    const args: any = {
      pccOrgUuid: pcc_orgUuid,
      pccFacID: pccFacId,
      pccPatientStatus: undefined,
      pccPatientName: last_name?first_name+' '+last_name:first_name,
      // pageSize: 200,
      noPagination: true
    }

    this.loader.startLoader('loader-0101');

    await this.pccService
      .getPatientsByFacilityID(args)
      .subscribe(
        async (response: any) => {
          this.loader.stopLoader('loader-0101');
          
          if (response.status === 200) {
            
            this.pccGlobalPatients = response.data.data;
            if (this.pccGlobalPatients[0] != null) {

              if(!all)
              this.toastr.success('Patients Fetched from PCC Successfully.', 'Success');
              console.log("this.pccGlobalPatients : :", this.pccGlobalPatients);
             

            }else{
              if(!all){

                this.toastr.warning('No patient found with same name in PCC.', 'Warning');
              }

            }

          } else {
            this.toastr.error(response.message, 'Error');
          }
        }
      );


  }

  async initPccPatientSingleManual_matrix(facility, pcc_pat, first_name, last_name, all=false) {
    if (!facility) return;
    const facility_id = facility._id;
    if (!facility_id) {
      return;
    }
    let args: any = {
      facility: facility_id,
      first_name: first_name,
    }
    if(last_name){
      args.last_name=last_name;
    }

    this.loader.startLoader('loader-0101');

    await this._patientListService
      .getMatrixPatientForMerging(args)
      .subscribe(
        async (response: any) => {
          this.loader.stopLoader('loader-0101');
          
          if (response.status === 200) {
            
            this.pccGlobalPatients = response.data.data;
            if (this.pccGlobalPatients[0] != null) {

              if(!all)
              this.toastr.success('Patients Fetched from Matrix Successfully.', 'Success');
              console.log("this.pccGlobalPatients : :", this.pccGlobalPatients);
             

            }else{
              if(!all){

                this.toastr.warning('No patient found with same name in PCC.', 'Warning');
              }

            }

          } else {
            this.toastr.error(response.message, 'Error');
          }
        }
      );


  }

  close(choice){
    if(choice){
      this.dialogRef.close(this.selectedPatient);
    }else{
      this.dialogRef.close(false);

    }
  }


}
