import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import * as global from '../global';
import { map } from 'rxjs/operators';
import {AuthService} from 'src/app/services/auth.service';
import {API_URL} from "../../../environments/api-url";
import moment from 'moment'

@Injectable({
    providedIn: 'root',
})
export class AdminDictationsService {

    currentUser: any;
    constructor(private httpclient: HttpClient,
                private _authService: AuthService) {
        this.currentUser = this._authService.currentUser;
    }

    getDictations(filter, company_id = null) {
        var clonedFilter = JSON.parse(JSON.stringify(filter)); 
        if(clonedFilter && clonedFilter.date_of_submission && clonedFilter.date_of_submission.startDate){
            clonedFilter.date_of_submission.startDate = moment(clonedFilter.date_of_submission.startDate).toDate();
            clonedFilter.date_of_submission.endDate = moment(clonedFilter.date_of_submission.endDate).toDate();

        }
        if(clonedFilter && clonedFilter.date_of_service && clonedFilter.date_of_service.startDate){
            clonedFilter.date_of_service.startDate = moment(clonedFilter.date_of_service.startDate).toDate();
            clonedFilter.date_of_service.endDate = moment(clonedFilter.date_of_service.endDate).toDate();
        } 
        
        var item ={ 
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            filter: clonedFilter,
            company_id: company_id,
        };

        return this.httpclient
            .post(global.url + API_URL.AUDIO.getDictations, {details: item}).pipe(
                map((response:any) => {
                    if(response.status === 200) {
                        //console.log("response: ", response)
                        // response.data = response.data.map( audio => {
                        //     if (audio.groupedData[0].audiodata.audioData[0].dic_status === '18') {
                        //         audio.status = 'PENDING';
                        //     }
                        //     if (audio.groupedData[0].audiodata.audioData[0].dic_status === '19') {
                        //         audio.status = 'IN PROGRESS';
                        //     }
                        //     if (audio.groupedData[0].audiodata.audioData[0].dic_status === '20') {
                        //         audio.status = 'COMPLETED';
                        //     }
                        //     if (audio.groupedData[0].audiodata.audioData[0].dic_status === '21') {
                        //         audio.status = 'DRAFT';
                        //     }
                        //     if (audio.groupedData[0].audiodata.audioData[0].dic_status === '23') {
                        //         audio.status = 'TRASHED';
                        //     }
                        //     return {...audio};
                        // });
                    }
                    //console.log(response);
                    return response;
                })
            );
    }


    getProviders(company_id = null) {
        var item ={
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            company_id: company_id
        };

        return this.httpclient
            .post(global.url + API_URL.USER.getDoctorsList, {details: item}).pipe();
    }

    getNoteTypes(doctor_id) {
        var item ={
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,

            doctor_id
        };

        return this.httpclient
            .post(global.url + API_URL.AUDIO.getMyTitles, {title: item}).pipe();
    }
}
