<div *ngIf="audios && audios.length > 0" class="audios-group">

    <div class="flex justify-center items-center w-full">
        <div class="w-full flex">
            <span class="group-index-title-audios" style="margin-left: 44%;">
                <span>{{group_index !== 'undefined' ? group_index : 'N/A'}}</span>
            </span>
        </div>
    </div>

    <ul>
        <ng-container *ngFor="let audio of audios">
            <li>
                <div class="flex">

                    <ng-container *ngIf="audio.empty">
                        No Dictations
                    </ng-container>
                    <ng-container *ngIf="!audio.empty">
                        <div class="flex items-center gap-5">
                            <img class="audio-icon" src="../../../assets/icons/audio.png">
                        </div>
                        <div class="column-title flex items-center"
                            (click)="selectedAudio($event, audio)"> 
                            <div style="width: 100%;">
                                <div class="title">
                                    {{audio.audio_title}}
                                </div>
                                <div class="duration" >
                                    <span style="display: inline-flex;width: 15%;">{{audio.audio_length}}</span>
                                    <span style="padding-left: 10px; padding-right: 10px;">|</span>
                                    <span style="display: inline-flex;width: 30%;place-content: center;">
                                    {{getUploadingDate(audio)}}
                                    </span>
                                    <span *ngIf="!isProvider() && currentUser.other_settings.show_tat">
                                        <span style="padding-left: 10px;padding-right: 10px;">|</span>
                                        <span *ngIf="!timeUp" style="display: inline-flex;width: 25%;place-content: center;">
                                            TAT:
                                             <countdown-timer  class="duration" 
                                            [end]="getEndTimeForTimer(audio)" (zeroTrigger)="triggerFunction()">
                                        </countdown-timer> 
                                        </span>
                                        <span *ngIf="timeUp" style="color: red;display: inline-flex;width: 25%;place-content: center;">
                                            TAT: -
                                            <countdown-timer  class="duration" 
                                           [start]="getStratTimeForTimer(audio)" (zeroTrigger)="triggerFunction()">
                                       </countdown-timer> 
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div class="mat-progress-spinner-progress"
                                *ngIf="downloadProgress.show && downloadProgress.audio_id === audio._id">
                                <mat-progress-spinner diameter="40" [mode]="downloadProgress.mode"
                                    [value]="downloadProgress.progress">
                                </mat-progress-spinner>
                                <span>
                                    {{this.downloadProgress.progress}}%
                                </span>
                            </div>
                        </div>

                        <div class="flex justify-end items-center">
                            <div class="flex justify-end items-center gap-4">
                                <div class="status-buttons flex gap-4">
                                    <mat-menu #downloadAudioMenu="matMenu">
                                        <button mat-menu-item (click)="download('mp4',audio)">MP4</button>
                                        <button mat-menu-item (click)="download('mp3',audio)">MP3</button>
                                    </mat-menu>
                                    <i matTooltip="Delete" matTooltipPosition="above" (click)="deleteAudio(audio)"
                                        *ngIf="currentUser.user_type == '1' && audio.is_trashed === 'false'"
                                        class="icon icon-delete"></i>
                                    <i matTooltip="Restore" matTooltipPosition="above"
                                        (click)="deleteAudio(audio,false)"
                                        *ngIf="currentUser.user_type == '1' && audio.is_trashed === 'true'"
                                        class="icon icon-refresh"></i>

                                </div>

                                <div class="flex justify-start items-center gap-4">
                                    <div style="font-size: 12px; min-width: 128px;">
                                        {{ audio.patient_id_ref ?  audio.patient_id_ref.name : '-'}}
                                    </div>
                                    <div class="flex gap-4"> 
                                        <span style="font-size:12px;width: 175px;" > 
                                            {{getServiceDate(audio)}}</span>
                                    </div>

                                    <div class="status-col flex">
                                        <button class="dn-button-primary"
                                            style="font-size: 12px; min-width: 108px;" mat-raised-button
                                            [class.completed]="audio.progress_status==='20'">
                                            {{audio.progress_status=='19'?'In Progress' : 'Completed'}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-container>
                </div>
            </li>
        </ng-container>
    </ul>
</div>