<app-header></app-header>
<div class="container" style="width: 100%;">
    <div class="h100 flex flex-row sm:flex-col"  style="background: #f3f3f3;">
        <mat-card class="w-full" style="height: inherit;width: 100%;">

            <mat-card-title class="flex flex-col items-center justify-center text-center" style="margin-right: 150px; margin-top:10px;">
                <h2 class="height-margin">Register</h2>
            </mat-card-title>

            <mat-card-content class="flex flex-col">


                <form (submit)="submitted=true; onRegisterCompany(registerForm)" #registerForm="ngForm" 
                    *ngIf="accountType === 'company'">

                    <div class="flex gap-5">

                        <div class="w-1/2">
                            <mat-form-field>
                                <input matInput type="text" name="company_name" ngModel placeholder="Company"
                                    required>
                                <mat-error *ngIf="!registerForm.value.company_name" class="font-styling">Please enter company name
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="w-1/2">
                        <mat-form-field>
                            <mat-label>Select Company Type</mat-label>
                            <mat-select name="company_type" ngModel required>
                                <mat-option selected value="nursing">Nursing</mat-option>
                                <mat-option value="Wound">Wound Care</mat-option>
                                <mat-option value="snf">SNF</mat-option>
                            </mat-select>
                            <mat-error *ngIf="!registerForm.value.company_type" class="font-styling">Please select company type
                            </mat-error>
                        </mat-form-field>
                    </div>
                    </div>

                    <div class="flex gap-4">
                        <div class="w-1/2">
                            <mat-form-field >
                                <input id="reg-first-name" matInput type="text" required
                                    onkeypress="return /[a-z]/i.test(event.key)" name="firstname" ngModel
                                    placeholder="Owner First Name">
                            </mat-form-field>
                        </div>

                        <div class="w-1/2">
                            <mat-form-field>
                                <input id="reg-last-name" matInput type="text" required
                                    onkeypress="return /[a-z]/i.test(event.key)" name="lastname" ngModel
                                    placeholder="Owner Last Name">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="flex gap-4">
                        <div class="w-1/2">
                        <mat-form-field>
                            <mat-label>Select Country</mat-label>
                            <mat-select (selectionChange)="countryFilter($event.value)" required>
                               <mat-option *ngFor="let t of Country"
                                value="{{t}}">{{t}}
                            </mat-option>
                            </mat-select>
                        </mat-form-field>
                        </div>
                        <div class="w-1/2">
                            <mat-form-field>
                                <input matInput type="text" id="mobile" name="mobile" [(ngModel)]="mobile"
                                (ngModelChange)="handlePhoneModelChange($event)" (paste)="onPaste($event)" [maxlength]="maxMobileLength"
                                placeholder="Mobile Number" required (keypress)="keyPressNumbers($event)" [disabled]="!selectedCountry">
                            <mat-error *ngIf="mobile.invalid && (mobile.dirty || mobile.touched)">Please enter valid mobile
                            </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="flex gap-4">
                        
                        <div class="w-1/2">
                            <mat-form-field>
                                <input matInput type="text" name="email" required #email="ngModel" ngModel
                                    placeholder="Email address" >
                            </mat-form-field>
                        </div>

                        <div class="w-1/2">
                            <mat-form-field>
                                <textarea matInput name="company_address" ngModel placeholder="Company Address"
                                    ></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="flex gap-4">
                        <div class="w-1/2">
                            <mat-form-field>
                                <textarea matInput name="company_description" ngModel placeholder="Company Description"
                                    ></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="flex gap-5">
                        <div class="w-1/2">
                            <mat-form-field class="field-item">
                                <input matInput placeholder="TIN" [(ngModel)]="tin" name="tin">
                            </mat-form-field>
                        </div>
                    </div>
                    <br />

                    <div class="flex items-end justify-center gap-4">
                        <button class="dn-button-secondary" mat-raised-button (click)="goBack()"
                            type="button">Cancel</button>
                        <button class="theme-btn" mat-stroked-button type="submit" [disabled]="registerForm.invalid">Register</button>
                    </div>
                </form>

            </mat-card-content>

        </mat-card>
    </div>
</div>