<div class="container calender-container">
    <div class="h100 flex flex-row gap-2 justify-between items-stretch" *ngIf="!is_add_view && is_detail_view === false">
        <app-user-appointment-filter (dateSelection)="navigateCalenderToDate($event)"></app-user-appointment-filter>
        <mat-card class="h100 flex">
            <mat-card-content  class="h100 flex flex-col gap-2">
                <div class="flex flex-row justify-end">
                </div>
                <div class="h100-scroll-auto flex gap-7">
                    <full-calendar
                        #calendar
                        defaultView="timeGridWeek"
                        (dateClick)="handleDateClick($event)"
                        (eventClick)="handleEventClick($event)"
                        [header]="header"
                        [weekends]="calendarWeekends"
                        [events]="calendarEvents"
                        [views]="views"
                        [plugins]="calendarPlugins"></full-calendar>
                </div>
            </mat-card-content>
            <ngx-ui-loader loaderId="loader-01"></ngx-ui-loader>
        </mat-card>
    </div>
    <div *ngIf="is_add_view && is_detail_view === false" class="flex flex-col gap-2"
         style="margin-top: 15px">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    <h2 style="color: black;margin:0">Add New Appointment </h2>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <form style="max-width: 700px;"
                class="flex flex-col"
                (ngSubmit)="!inprogress && addAppointment(form.value)" #form="ngForm">
                    <mat-form-field>
                        <mat-label>Title</mat-label>
                        <mat-select name="title"
                                    required
                                    #title="ngModel"
                                    [(ngModel)]="model.title_id">
                            <mat-option (click)="showTitleAddDialog()">
                                <strong>+ Add New Note Type</strong>
                            </mat-option>
                            <mat-option *ngFor="let t of titles"
                                        value="{{t._id}}">{{t.title}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="title.invalid  && (title.dirty || title.touched)">
                            Please select title
                        </mat-error>
                    </mat-form-field>
                    
                    <mat-form-field>
                        <mat-label>Facility</mat-label>
                        <mat-select name="facility"
                                    required
                                    #facility="ngModel"
                                    [(ngModel)]="model.facility_id">
                            <mat-option *ngFor="let f of facilities"
                                        value="{{f._id}}">{{f.title}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="facility.invalid  && (facility.dirty || facility.touched)">
                            Please select facility
                        </mat-error>
                    </mat-form-field>
              
                    <mat-form-field>
                        <mat-label>Provider</mat-label>
                        <mat-select name="provider"
                                    required
                                    #provider="ngModel"
                                    [(ngModel)]="model.provider_id">
                            <mat-option *ngFor="let p of providers"
                                        value="{{p._id}}">{{p.first_name + ' ' + p.last_name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="provider.invalid  && (provider.dirty || provider.touched)">
                            Please select provider
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="flex-grow">
                        <mat-label>Patient</mat-label>
                            <input matInput type="text" [(ngModel)]="model.patient_id" [formControl]="patientControl"
                                [matAutocomplete]="patientAutoComplete">
                            <mat-autocomplete #patientAutoComplete="matAutocomplete" [displayWith]="displayPatientFn">
                                <mat-option value="" (click)="showPatientAddDialog()">
                                                        <strong>+ Add New Patient</strong>
                                                    </mat-option>
                                <mat-option *ngFor="let patient of filteredPatients | async" [value]="patient">
                                    <app-patient-name [patient]="patient"></app-patient-name>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    <mat-form-field>
                        <mat-label>IME Vendor</mat-label>
                        <mat-select name="payor"
                                    required
                                    #payor="ngModel"
                                    [(ngModel)]="model.payor_id">
                            <mat-option *ngFor="let pay of payors"
                                        value="{{pay._id}}">{{pay.abbreviation}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="payor.invalid  && (payor.dirty || payor.touched)">
                            Please select IME Vendor
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="!hasSelectedPatientEmail(model.patient_id)">
                        <mat-label>Patient Email</mat-label>
                        <input matInput
                                name="patient_email"
                                required
                                #patient_email="ngModel"
                                [(ngModel)]="model.patient_email">
                                <mat-error *ngIf="patient_email.invalid  && (patient_email.dirty || patient_email.touched)">
                                    Please enter patient email
                                </mat-error>
                    </mat-form-field>


                    <div class="flex flex-col gap-2">
                        <div class="flex items-baseline gap-2">
                            <mat-label>Reminder</mat-label>
                            <mat-form-field style="width:auto">
                                <mat-label>Type</mat-label>
                                <mat-select name="notify_type"
                                            required
                                            #notify_type="ngModel"
                                            multiple
                                            [(ngModel)]="model.notify_type">
                                    <mat-option value="email">Email</mat-option>
                                    <mat-option value="phone">Phone</mat-option>
                                </mat-select>
                                <mat-error
                                        *ngIf="notify_type.invalid  && (notify_type.dirty || notify_type.touched)">
                                    Please select one option
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field style="width:auto">
                                <mat-label>Time</mat-label>
                                <mat-select name="notify_time"
                                            required
                                            #notify_time="ngModel"
                                            [(ngModel)]="model.notify_time">
                                    <mat-option value="30">30 minutes earlier</mat-option>
                                    <mat-option value="15">15 minutes earlier</mat-option>
                                    <mat-option value="5">5 minutes earlier</mat-option>
                                </mat-select>
                                <mat-error
                                        *ngIf="notify_time.invalid  && (notify_time.dirty || notify_time.touched)">
                                    Please select one option
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-checkbox
                            name="video"
                            [checked]="model.is_video"
                            (change)="model.is_video = $event.checked">
                                Video Conference
                            </mat-checkbox>
                        </div>
                        <div>
                            <mat-checkbox
                            name="video"
                            (change)="model.email_patient_intake_form = $event.checked">
                                Email patient intake form link to patient
                            </mat-checkbox>
                        </div>
                        <div>
                            <mat-form-field style="width: auto;">
                                <input matInput
                                        [matDatepicker]="picker"
                                        [min]="currentDate"
                                        (dateChange)="dateChange($event)"
                                        name="dos"
                                        #dos="ngModel"
                                        [(ngModel)]="model.dos"
                                        placeholder="Choose a date">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error *ngIf="dos.invalid  && (dos.dirty || dos.touched)">
                                    Please select date of service
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="flex flex-row justify-end items-center gap-2">
                        <button mat-raised-button
                                class="dn-button-secondary"
                                (click)="is_add_view=!is_add_view"
                                type="button">Cancel
                        </button>
                        <button 
                                class="dn-button-primary"
                                mat-raised-button
                                [disabled]="!form.form.valid || inprogress"
                                type="submit">Add
                        </button>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div *ngIf="is_detail_view" style="margin-top: 15px" class="flex-col gap-2">
        <app-user-appointment-detail
                (exit)="handleExit()"
                [appointmentId]="appointmentId"></app-user-appointment-detail>
    </div>
</div>
