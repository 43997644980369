import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import * as global from '../../global';
import { AuthService } from 'src/app/services/auth.service';
import {API_URL} from "../../../../environments/api-url";

@Injectable({
    providedIn: 'root',
  })
export class NoteCommentsService {
    currentUser:any = {};
    userInfo:any = {};
    public constructor(
        private httpclient: HttpClient,
        private _authService: AuthService) {
        // const currentUser = localStorage.getItem('currentUser');
        const currentUser = this._authService.currentUser;
        if(currentUser) {
            // this.currentUser = JSON.parse(currentUser);
            this.currentUser =  currentUser;
            this.userInfo = {
                usertype: this.currentUser.user_type,
                auth_key: this.currentUser.auth_key,
                id: this.currentUser._id,
                company_id: this.currentUser.company_id
            }
        }
    }

    add_note_comment(text, noteId, createdAt) {
        const details = {
            ...this.userInfo,
            text,
            noteId,
            createdAt
        }
        return this.httpclient.post(global.url + API_URL.NOTE.add_note_comment, {details: details}).pipe();
    }
    get_note_comments(noteId) {
        
        const details = {
            ...this.userInfo,
            noteId
        }
        return this.httpclient
            .post(global.url + API_URL.NOTE.get_note_comments, {details: details}).pipe();
    }
    addTabSettings(tabSettings){
        const details = {
            ...this.userInfo,
            tabSettings
        }
        return this.httpclient.post(global.url + API_URL.USER.setTabSettings, {details: details}).pipe();
    }
    getTabSettings(){
        const details = {
            ...this.userInfo,
        }
        return this.httpclient.post(global.url + API_URL.USER.getTabSettings, {details: details}).pipe();
    }
    updateTabsOrder(updatedTabs, tabName) {
        const details = {
            ...this.userInfo,
            updatedTabs,
            tabName
        }
        return this.httpclient.post(global.url + API_URL.USER.updateTabsOrder, { details: details }).pipe();
    }
    getTabsOrder(isCustomizationAllowed) {
        const details = {
            ...this.userInfo,
            isCustomizationAllowed
        }
        return this.httpclient.post(global.url + API_URL.USER.getTabsOrder, { details: details }).pipe();
    }
}
