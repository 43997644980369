import { Component, OnInit, Input } from '@angular/core';
import { NoteAuditoryHistoryService } from './note-auditory-history.service';
import * as global from '../global'
import { ViewNoteAuditDialog } from './view-note-audit-dialog/view-note-audit-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { NoteEditorService } from '../note-editor/note-editor.service';
import moment from 'moment'
@Component({
  selector: 'app-note-auditory-history',
  templateUrl: './note-auditory-history.component.html',
  styleUrls: ['./note-auditory-history.component.css']
})
export class NoteAuditoryHistoryComponent implements OnInit {
  global=global;
  @Input() noteId: String;
  @Input() note: any;

  audits:any = [];

  constructor(
    private noteAuditoryHistory:NoteAuditoryHistoryService,
    private dialog: MatDialog,
    private authService: AuthService,
    private noteEditorService:NoteEditorService
    ) { }

  ngOnInit(): void {

    this.initAuditoryHistory();
    console.log(this.audits);
    
  }
  initAuditoryHistory() {   
     this.noteAuditoryHistory.getNoteAudit(this.noteId).subscribe((response:any) => {
      if(response.status === 200) {
        this.audits = response.data.array
      }
      
    });
  }
  showViewNoteAuditDialog(audit) {
    console.log(audit);
    
    const dialogRef = this.dialog.open(ViewNoteAuditDialog, {
        width: '80%',
        data: {
            audit,
            timezone: this.note?.timezone
        }
    });
  }
  
  formatOperation(operation, source = null) {
      // const labels = {
      //     delete: 'deleted',
      //     create: 'created',
      //     publish: 'published',     
      //     internal_qa: 'submitted to internal QA',     
      //     external_qa: 'submitted to external QA',     
      //     sign: 'signed',     
      //     upload: 'uploaded',     
      //     addendum_save: 'addendum saved',
      //     addendum_uploaded: 'addendum uploaded',
      // }

      // return labels[operation] || operation;
      return this.noteAuditoryHistory.formatOperation(operation, source);
  }

  isProvider() {
    const {currentUser} = this.authService;
    return currentUser.user_type === global.USER_TYPE.DOCTOR;
  }

  isOwner(user) {
    if(typeof user === 'object') {
      const user_id = user._id;
      
      // return user_id === this.note.uploadedBy._id
      const {currentUser} = this.authService;
      return user_id === currentUser._id
    }
  }

  applyDateTimezone(date) {
    return this.noteEditorService.applyDateTimezone(this.note, date);
  }

  getAuditDate(audit) {
    if(audit.date_obj&&audit?.operation!='wound_report') {
      if(audit.user_date_string){
        return audit.user_date_string;
      }else{
        let {year, month, day, hour, minute} = audit.date_obj;
        if(month < 10){
            month = '0' + month;
        }
        if(day < 10){
            day = '0' + day;
        }
        let str = `${month}/${day}/${year}`;
        if(hour && minute) {
            str += ` ${hour}:${minute}`
        }
        return str;
      }
    } else {
      if(audit.user_date_string){
          return audit.user_date_string;
        }else if(audit.timestamp){
          return moment(audit.timestamp).format('MM/DD/YYYY h:mm A')
        }
    }
  }
}
