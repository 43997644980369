<div class="col-content">
    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="icd-code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ICD 10 </th>
            <td mat-cell *matCellDef="let element;let i=index" style="width: 30%"> 
                <mat-form-field style="width: 100%;">
                    <mat-label>Select ICD 10</mat-label>
                    <mat-select [value]="selectedDetailICD[element._id]" (selectionChange)="handleICDChange($event)" [disabled]="disableEditing">
                        <input matInput placeholder="Search" (keyup)="searchDiagnose($event.target.value, element.icd_id)"
                            style="color:#000;height: 40px;padding-left: 5px;" />
                        <mat-option id="{{icdcode.code}}" *ngFor="let icdcode of icdCodeList"
                        [value]="icdcode"    
                            >{{icdcode.code  + ' (' + icdcode.mediumDescription +')'}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>
        <ng-container matColumnDef="tags">
            <th mat-header-cell *matHeaderCellDef>Tags </th>
            <td mat-cell *matCellDef="let element;let i=index" style="width: 100%">
                <ng-multiselect-dropdown name="tags_{{i}}" 
                    [(ngModel)]="element.tags"
                    [placeholder]="'Select Tag'" 
                    [settings]="tagsDropdownSettings"
                    [data] = 'tags'
                    [disabled]="disableEditing"
                    (onSelect)="onTagSelect($event, element); "
                    (onDeSelect)="onTagDeSelect($event, element);">
                </ng-multiselect-dropdown>
            </td>
        </ng-container>
        <ng-container matColumnDef="operation">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element;let i=index">
            <button class="close-image" (click)="deleteNewWoundEtiologyGroupDetails(element, i)">
                <img src="assets\icons\minus.png" />
            </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div *ngIf="showNewhandleAddNewWoundEtiologyGroupDetailsInputValue" class="flex justify-center items-baseline gap-3" style="width: 97%;margin:0 auto;">
        <div class="w-27">
            <mat-form-field style="width: 100%;">
                <mat-select [value]="newSelectedDetailICDInputValue" (selectionChange)="handleNewICDChange($event)">
                    <input matInput placeholder="Search" (keyup)="searchNewDiagnose($event.target.value)"
                        style="color:#000;height: 40px;padding-left: 5px;" />
                    <mat-option id="{{icdcode.code}}" *ngFor="let icdcode of icdCodeNewList"
                    [value]="icdcode"    
                        >{{icdcode.code  + ' (' + icdcode.mediumDescription +')'}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="flex-grow">
            <ng-multiselect-dropdown class="flex-grow" name="tags" 
                [(ngModel)]="newTagsInputValue"
                [placeholder]="'Select Tag'" 
                [settings]="newWoundEtiologyGroupDetailsDropdownSettings"
                [data] = 'tags'>
            </ng-multiselect-dropdown>
        </div>

        <ng-container *ngIf="currentUser.admin_type == 'system_admin'">
            <button class="save-image" (click)="addNewWoundEtiologyGroupDetails()">
                <img src="assets\icons\save-white.png" />
            </button>
        </ng-container>
    </div>
</div>

<ng-container *ngIf="currentUser.admin_type == 'system_admin'">
    <button class="add-image" (click)="handleAddNewWoundEtiologyGroupDetails()">
        <img src="../../../../../assets/icons/plus.png" />
    </button>
</ng-container>

<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
