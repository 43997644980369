<div class="container">
  <mat-card>
      <mat-card-content>
          <mat-tab-group class="parent-tabs">
              <mat-tab label="Mips Reports" *ngIf="currentUser.product_owner">
                  <div class="mat-tab-content" *ngIf="currentUser.product_owner">
                      <app-mips-reports></app-mips-reports>
                  </div>
              </mat-tab>
              <mat-tab label="Company Audit Reports" *ngIf="currentUser.company_type == global.COMPANY_TYPE.NURSING">
                <div class="mat-tab-content" *ngIf="currentUser.company_type == global.COMPANY_TYPE.NURSING">
                    <app-company-audit-reports></app-company-audit-reports>
                </div>
            </mat-tab>
            <mat-tab label="Patient Opportunities Report" *ngIf="currentUser.company_type == global.COMPANY_TYPE.NURSING">
                <div class="mat-tab-content" *ngIf="currentUser.company_type == global.COMPANY_TYPE.NURSING">
                    <app-patient-opportunities-report></app-patient-opportunities-report>
                </div>
            </mat-tab>
          </mat-tab-group>
      </mat-card-content>
  </mat-card>
  
</div>
