import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '../notifications/notifications.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import * as global from "../../includes/global";

@Component({
  selector: 'app-notifications-page',
  templateUrl: './notifications-page.component.html',
  styleUrls: ['../notifications/notifications.component.css', './notifications-page.component.css']
})
export class NotificationsPageComponent implements OnInit {
  currentUser: any;
  notifications = [];
  notificationsLoaded=false;
  constructor(
    private _notificationsService:NotificationsService,
    private _toastr:ToastrService, 
    private _router:Router,
    private _authService: AuthService) { 
    // const currentUser = localStorage.getItem('currentUser');
    const currentUser = this._authService.currentUser;
    if(currentUser) {
      // this.currentUser = JSON.parse(currentUser);
      this.currentUser = currentUser;
    }
  }

  ngOnInit() {
    this.initNotifications();
  }

  initNotifications() {
    this._notificationsService.getAllNotifications().subscribe((response:any) => {
      if(response.status === 200) {
        this.notifications = response.notification;  
        this.makeUnseenNotificationsSeen();
      } else {
        this._toastr.error(response.message,'Failed')
      }
    }, console.error, () => {this.notificationsLoaded = true})
  }

    
  makeUnseenNotificationsSeen() {
    const unseenNotifications = this.notifications.filter(n => n.seenBy.indexOf(this.currentUser._id) === -1);

    
    if(unseenNotifications.length > 0) {
      this._notificationsService.makeNotificationsSeen(unseenNotifications.map(n => n._id)).subscribe((seenResponse:any) => {
        if(seenResponse.status === 200) {
          unseenNotifications.forEach(notification => {
            notification.seenBy.push(this.currentUser._id);
          });
        }
      });
    }
  }

  openURL(notification) {
    console.log("notification : ", notification);
    if (notification.routeUrl && notification.recipient_type !== 'version') {
      const url = global.base_url + notification.routeUrl;
  
      setTimeout(() => {
        window.open(url, '_blank');
      }, 0);
    } else if (notification.recipient_type == 'notification') {
      const URL = global.base_url + `/notifications-settings`;
      window.open(URL, '_blank');
    } else {
      const URL = global.base_url + '/app-release';
      window.open(URL, '_blank');
    }
  }
  
  
  handleNotificationClick(notification) {
    this._router.navigateByUrl(notification.routeUrl);
  }
  
}
