import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import moment from 'moment'
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { CensusRecurrentService } from 'src/app/services/censusRecurrent.service';
import { CommonService } from 'src/app/services/common.service';
import { PatientAddService } from '../patient-add/patient-add-service';
import { ProviderProviderAssociationService } from '../provider-provider-association/provider-provider-association.service';
import { RecurrentOptionDialogComponent } from '../recurrent-option-dialog/recurrent-option-dialog.component';
import { ShedulerPatientDialogComponent } from '../sheduler-patient-dialog/sheduler-patient-dialog.component';
import * as global from '../global';

@Component({
  selector: 'app-admin-scheduler',
  templateUrl: './admin-scheduler.component.html',
  styleUrls: ['./admin-scheduler.component.css']
})
export class AdminSchedulerComponent implements OnInit {
  filterFormGroup: FormGroup;
  searchText: any;
  searchTextP: any;
  data: any;
  company_id: any;
  providers: any;
  selectedProvider: any = null;
  currentUser: any;
  currentUserDetails: any;
  facilities: any;
  selectedFacility: any = null;
  loadPageEventEmitterSub: any;
  loadPageEventEmitter: EventEmitter<void> = new EventEmitter();
  afterstart: Boolean = false;
  selectedPatients: any;

  selectedProviderFromHisotry: any;
  selectedFacilityFromHisotry: any;

  constructor(
    public services: ProviderProviderAssociationService,
    private _authService: AuthService,
    private _patientAddService: PatientAddService,
    private commonService: CommonService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private _CensusRecurrentService: CensusRecurrentService,
  ) {
    this.currentUser = this._authService.currentUser;
    this.currentUserDetails = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type
    }


  }

  async ngOnInit() {
    this.commonService.castCompany.subscribe(company => {
      this.company_id = company?._id
    })

    //console.log("company_id : ", this.company_id);

    if (!this.company_id) {
      this.company_id = this.currentUser.company_id
    }

    this.selectedFacilityFromHisotry=localStorage.getItem('selectedFacilityFromHisotry');
    this.selectedProviderFromHisotry=localStorage.getItem('selectedProviderFromHisotry');

    console.log("selectedFacilityFromHisotry : ", this.selectedFacilityFromHisotry);
    console.log("selectedProviderFromHisotry : ", this.selectedProviderFromHisotry);


    this.data = {
      provider_id: this.selectedProvider,
      facility_id: this.selectedFacility
    };
    //console.log("dataa in adminscheduler", this.data);
    this.filterFormGroup = new FormGroup({
      facility: new FormControl(null),
      patientSearchInput: new FormControl(''),
      providerId: new FormControl(''),
      patientStatus: new FormControl('Current'),
      pageSize: new FormControl(200),
    });

    await this.loadProvider();


    this.filterFormGroup.get('facility').valueChanges.subscribe((val) => {

      if (val == 'all'){
        this.selectedFacility = null;
        localStorage.removeItem('selectedFacilityFromHisotry');
      }
      else{
        this.selectedFacility = val;
        localStorage.setItem('selectedFacilityFromHisotry',val?._id?.toString() );
      }

        ///  save selectedFacilityFromHisotry in local history


      if (this.selectedProvider && this.selectedProvider != 'none') {
        this.loadCensusCalender();
      }

    });

    this.filterFormGroup.get('providerId').valueChanges.subscribe((val) => {
      //console.log("val providerId: ", val);
      if (val && val != 'none') {
        
        ///  save selectedProviderFromHisotry in local history 
        localStorage.setItem('selectedProviderFromHisotry',val?._id?.toString() );

        this.selectedProvider = val;
        this.loadFacilities(this.selectedProvider._id);
      }
      else if (val && val == 'none') {
        localStorage.removeItem('selectedProviderFromHisotry');
        localStorage.removeItem('selectedProviderFromHisotry');

        this.selectedProvider = 'none';
        this.selectedFacility = 'all';
        this.facilities = [];
        this.clearCensusCalender();
      }
    });

  }

  showButton() {
    if (this.selectedFacility && this.selectedProvider) {
      if (this.selectedFacility != 'all' && this.selectedProvider != 'none') return true;
    }
    return false;
  }

  loadCensusCalender() {
    if (this.selectedProvider != 'none') {
      if (this.selectedFacility) {
        this.data = {
          provider_id: this.selectedProvider._id,
          facility_id: this.selectedFacility._id
        }
      } else {
        this.data = {
          provider_id: this.selectedProvider._id,
          facility_id: null
        }
      }
    }
    //console.log("dataa : ", this.data);
  }
  clearCensusCalender() {
    this.data = {
      provider_id: 'none',
      facility_id: 'none'
    }
  }
  async loadProvider() {
    const filter: any = {
      company_id: this.company_id,
    };
    const projection = {
      'title': 1,
      'first_name': 1,
      'last_name': 1,
      'full_name': 1
    }
    let res = await this.services.getAllSameCompanyProviders(filter,projection).subscribe(async data => {
      this.providers = data;
      this.providers = this.providers.data;
      this.selectedProvider = 'none';
      this.filterFormGroup.get('providerId').setValue(this.selectedProvider);

      if(this.selectedProviderFromHisotry){       
        const index=this.providers?.findIndex((e)=>{return e?._id?.toString()==this.selectedProviderFromHisotry}); 
        if(index>=0){
          this.loadFacilities(this.selectedProviderFromHisotry);

          this.selectedProvider = this.providers[index];
          this.filterFormGroup.get('providerId').setValue(this.selectedProvider);
        }else{
          localStorage.removeItem('selectedProviderFromHisotry');
        }

      }else{
        this.selectedProvider = 'none';
        this.filterFormGroup.get('providerId').setValue(this.selectedProvider);  
      }

    });
  }

  async loadFacilities(provider_id) {
    //console.log("loadFacilities(provider_id) : ", provider_id);
    if (!provider_id || provider_id == 'none') return;

    await this._patientAddService.getFacilities({ doctor_id: provider_id }).subscribe((res: any) => {
      let response = res.data.array;
      this.facilities = response;
      // this.selectedFacility=this.facilities[0];
      this.selectedFacility = 'all';

      this.filterFormGroup.get('facility').setValue(this.selectedFacility);
      this.afterstart = true;

      if(this.selectedFacilityFromHisotry&&this.selectedProviderFromHisotry){
        const index=this.facilities?.findIndex((e)=>{return e?._id?.toString()==this.selectedFacilityFromHisotry});
        if(index>=0){
          this.selectedFacility = this.facilities[index]
  
          this.filterFormGroup.get('facility').setValue(this.selectedFacility);
          this.afterstart = true;

        }else{
          localStorage.removeItem('selectedFacilityFromHisotry');
        }

      }else{
        this.selectedFacility = 'all';
        this.filterFormGroup.get('facility').setValue(this.selectedFacility);
        this.afterstart = true;
      }

    })
  }

  addMultiplePatients(status = false) {
    ////console.log("statusss : ", status);
    const obj = {
      facility_id: this.selectedFacility,
      provider_id: this.selectedProvider,
      pcc_source: this.selectedFacility.pcc_facId ? true : false
    }

    let dialogRef = this.dialog.open(ShedulerPatientDialogComponent, {
      data: obj,
      minWidth: '60%'
    });

    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        ////console.log("data of dialog", data);
        this.selectedPatients = data.selectedPatients;
        this.toastr.info('Adding Patients to census');

        ///show week recurrent view
        ///return date objects
        /// add rcurrent census
        ///refresh the calender
        this.handleAddToRecurrentCensus(data);
      } else {
        ////console.log("canceled");
      }
    });

  }



  async handleAddToRecurrentCensus(data) {
    if (!data) {
      await this.showDialog(true);
    } else {
      let recurrenceMap = data.recurrenceMap;
      let date_objects = data.date_objects;
      let census_name = data.census_name;
      await this.addRecurrentPatientRoundingSheets(null, date_objects, recurrenceMap, census_name);
    }

  }


  async showDialog(e) {
    ////console.log("e : ", e);
    let date_obj = moment().format('YYYY-MM-DD').split('-');
    let date_obj_toPass;
    const [year, month, day] = date_obj;
    date_obj_toPass = {
      year, month, day
    }

    const obj = {
      //...this.data,
      date_obj: date_obj_toPass,
      from_patient: true
    }
    const dialogRefr = this.dialog.open(RecurrentOptionDialogComponent, {
      data: obj,
      width: '40%'
    });

    dialogRefr.afterClosed().subscribe(async (data) => {
      if (data) {
        ////console.log("closedd true", data);
        let recurrenceMap = data.recurrenceMap;
        let date_objects = data.date_objects;
        let census_name = data.census_name;
        this.toastr.info('Adding Patients to census');

        await this.addRecurrentPatientRoundingSheets(null, date_objects, recurrenceMap, census_name);
      } else {
        ////console.log("closedd false", data);
      }
    });

  }


  async addRecurrentPatientRoundingSheets(previous_census_id, date_objects, recurrence_map, census_name) {

    let date_obj = moment().format('YYYY-MM-DD').split('-');
    let date_obj_toPass;
    const [year, month, day] = date_obj;
    date_obj_toPass = {
      year, month, day
    }


    this.selectedPatients = this.selectedPatients.map((patient) => patient._id);

    const currentDate = new Date();

    date_obj_toPass.hour = currentDate.getHours();
    date_obj_toPass.minute = currentDate.getMinutes();

    const details = {
      id: this.selectedProvider?._id,
      doctor_id: this.selectedProvider?._id,
      facilityId: this.selectedFacility?._id,
      name: census_name,
      ischargecheck: false,
      isnotecheck: false,
      parent_census_id: previous_census_id,
      date_objs: date_objects,
      provider_id: this.selectedProvider._id,
      company_id: this.selectedProvider.company_id,
      recurrence_map: recurrence_map,
      patientList: this.selectedPatients,
      date: currentDate,
      date_obj: date_obj_toPass
    }



    if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER) {
      details.doctor_id = this.currentUser?._id;
    }
    //console.log("details : :", details);


    //  return;

    await this._CensusRecurrentService.addNewRecurrentCensusesWithParent(details).subscribe(async (response: any) => {
      if (response.status === 200) {
        this.selectedPatients = [];
        this.toastr.success('Patient Added Successfully');
        // this.dialogRef.close(response.data);
        this.loadCensusCalender();
        ////console.log("res", response);

      }
    });
  }


}
