import { Component, ChangeDetectorRef, Input, OnInit, Output, EventEmitter, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ESignService } from 'src/app/components/e-sign/e-sign.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router'
import { Subscription, lastValueFrom } from 'rxjs';
import * as global from '../../includes/global';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { CompanyService } from 'src/app/services/Modules/company.service';
 
@Component({
  selector: 'app-patient-appointments',
  templateUrl: './patient-appointments.component.html',
  styleUrls: ['./patient-appointments.component.css']
})
export class PatientAppointmentsComponent implements OnInit {
  active: Boolean = false;
  currentUser: any;
  @Input() patient;
  dataSource = new MatTableDataSource();
  displayedColumns = [
    "date",
    "facility",
    "provider",
  ];

  loaderId ='patient-appointments-loader';
  inprogress = false;

  Alldata: [];
  
  view:any= '';
  viewData;
  currentDate;
  shouldShowEncounter : boolean = false;

  constructor(
    private _authService: AuthService,
    private toaster: ToastrService,
    private router: Router,
    private loader: NgxUiLoaderService,
    private esignService: ESignService,
    private companyService: CompanyService


  ) {
    this.currentUser = this._authService.currentUser;
    this.currentDate=new Date();
   }

  async ngOnInit() {
    await this.getCompanyPermissions({ _id: this.currentUser.company_id }, { should_show_encounter: 1 });

    if(this.patient){
      console.log("appointments loaded : ",this.patient);
      this.loadAppointments(this.patient);
    }

    if (this.shouldShowEncounter) {
      this.displayedColumns = [...this.displayedColumns.slice(0, -2), 'encounters', ...this.displayedColumns.slice(-2)];
    }

  }

  getDate(date_obj){
    if(!date_obj) return '';

    let { year, month, day} = date_obj;
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        let str = `${month}/${day}/${year}`;
        return str;
  }

  isToday(date_obj){
    if(moment(this.currentDate).format('YYY-MM-DD')==moment(this.getDate(date_obj)).format('YYY-MM-DD'))return true; else return false;
  }

  getRowColor(date_obj){
    let dateString=this.getDate(date_obj);
    date_obj=moment(dateString).format('YYY-MM-DD');
    if(date_obj>moment(this.currentDate).format('YYY-MM-DD')) return true;
    else if(date_obj<moment(this.currentDate).format('YYY-MM-DD')) return false;
    else return false;
  }

  async loadAppointments(patient_data){
    this.loader.startLoader(this.loaderId);

    let data= await this.esignService.getAppointmentsOfPatient({patient_id:patient_data._id}).subscribe(async (res:any)=>{
      if(res.status === 200) {
        this.Alldata=await this.sorting(res.data);
        this.dataSource = new MatTableDataSource(this.Alldata);
        
        //this.dataSource = new MatTableDataSource(res.data);
        console.log("rseponseee : ", res.data);
      }
      this.loader.stopLoader(this.loaderId);
    })

  }

  async sorting(array){
    return array.sort((a,b) => Date.parse(this.getDate(b.date_obj)) - Date.parse(this.getDate(a.date_obj)))
     
  }

  async getCompanyPermissions(filter, projection) {
    const response = await lastValueFrom(this.companyService.getCompany(filter, projection));
    if (response.status == 200) {
      this.shouldShowEncounter = response.data.should_show_encounter;
    }
  }
}
 