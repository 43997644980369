import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-derm',
  templateUrl: './derm.component.html',
  styleUrls: ['./derm.component.css']
})
export class DermComponent implements OnInit {
  tabIndex = 0;
  activatedRouter: string = 'data-set'
  company_id: string;
  constructor(
        private _route: ActivatedRoute,
  ) { 
        this.company_id = this._route.parent.snapshot.params.id;
  }

  ngOnInit(): void {
  }

  onTabClick(event) {
    if (event?.index == 0) {
      this.tabIndex = 0;
      this.activatedRouter = 'data-set'
    }
  }
}
