<h3>Add Facility Contact</h3>
<form (submit)="addContact(facilityForm.value)" #facilityForm="ngForm">

    <div class="flex flex-col gap-5">
        <div class="flex flex-row gap-2">
            <mat-form-field>
                <input
                        matInput
                        type="text"
                        name="first_name"
                        #first_name="ngModel"
                        [(ngModel)]="model.first_name"
                        placeholder="First Name"
                        onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"
                        required/>
            </mat-form-field>
            <mat-form-field>
                <input
                        matInput
                        type="text"
                        name="last_name"
                        #last_name="ngModel"
                        [(ngModel)]="model.last_name"
                        placeholder="Last Name"
                        onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"
                        required/>
            </mat-form-field>
        </div>
        <div class="flex flex-row gap-2">
            <mat-form-field>
                <input
                        matInput
                        type="text"
                        name="position"
                        #email="ngModel"
                        [(ngModel)]="model.position"
                        placeholder="Position"
                        onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"
                        required
                />
            </mat-form-field>
            <mat-form-field>
                <input
                        matInput
                        type="text"
                        name="email"
                        #email="ngModel"
                        [(ngModel)]="model.email"
                        placeholder="Email"
                        pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"
                        required
                />
            </mat-form-field>
            <mat-form-field>
                <input
                        matInput
                        type="text"
                        name="contact_phone"
                        #contact_phone="ngModel"
                        [(ngModel)]="model.phone"
                        placeholder="Phone"
                        (keypress)="keyPressNumbers($event)"
                        required/>
            </mat-form-field>
        </div>
    </div>
    <div class="flex flex-row items-center justify-center gap-2">
        <button class="theme-btn" mat-stroked-button
                [disabled]="!facilityForm.valid"
                type="submit">Save</button>
        <button class="theme-btn" mat-stroked-button
                type="button" mat-dialog-close>Cancel</button>
    </div>

</form>

<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>
