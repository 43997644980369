<div class="flex flex-col gap-2">
    <mat-form-field>
        <input matInput type="text" name="phrase_key" #phrase_key="ngModel" [(ngModel)]="addPhraseKey.key"
            placeholder="Shortcut" [disabled]="!isNew" required pattern="[a-z]+" onkeypress="return /[a-z]/.test(event.key)">
        <mat-error *ngIf="phrase_key.invalid">Please enter valid shortcut, it should not contains spaces or special characters</mat-error>
    </mat-form-field>
    <div class="flex justify-center items-center gap-2">
        <button class="dn-button-primary" mat-raised-button [mat-dialog-close]="addPhraseKey" [disabled]="!(addPhraseKey.key)">Save</button>
        <button class="dn-button-secondary" mat-raised-button type="button" mat-dialog-close>Cancel</button>
    </div>
</div>

<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>

