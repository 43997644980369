import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {ActivityLogService} from './activity-log.service';
import * as global from '../global';
import moment from 'moment'
import {UserTypesRelationshipService} from 'src/app/services/user-types-relationship.service';
import {AuthService} from 'src/app/services/auth.service';
import {MatSort, Sort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";

interface IFilter {
    active: boolean,
    dateType: string,
    dateRange: { startDate: moment.Moment, endDate: moment.Moment },
    date: Date,
    users: Array<any>,
    platforms: Array<any>,
    userId: string,
    platform: string
}


@Component({
    selector: 'app-activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.css'],
})
export class ActivityComponent implements OnInit {
    global = global;
    loading = false;
    public activities: any;

    public pagination: {
        pageNo: number,
        hasNextPage: boolean,
        hasPrevPage: boolean,
        totalCount: number,
        totalPages: number
    };


    public currentUser: any;
    // public response:any;
    public dataSource: any;

    displayedColumns: string[] = ['userName', 'screen', 'operation', 'datetime', 'platform'];
    @ViewChild(MatSort) sort: MatSort;
    sortedData: Array<any>;

    sortBy: string = 'datetime';
    sortOrder: string = 'desc';

    preDateRanges: any = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };
    filter: IFilter;
    appliedFilter: IFilter;
    users = [];
    platforms = [{label: 'Web'}, {label: 'iOS'}, {label: 'Android'}];

    currentDate = new Date();

    constructor(
        private _router: Router,
        private _activityLogService: ActivityLogService,
        private _userTypesRelationshipService: UserTypesRelationshipService,
        private _authService: AuthService
    ) {
        this.currentUser = this._authService.currentUser;
        if (!this.currentUser) {
            // if not login, stop and redirect to login #mohsin-dev
            localStorage.setItem('currentUser', 'null');
            this._router.navigate(['/login']);
            return;
        }
        // this.services.getDoctorsList().subscribe( data => {
        //   this.response = data;
        //   this.doctors = this.response.data;
        //   this.dataSource = new MatTableDataSource(this.doctors);
        // });
        this.pagination = {
            pageNo: 1,
            hasNextPage: false,
            hasPrevPage: false,
            totalCount: 0,
            totalPages: 0
        };
        this.filter = this.filterInitialState();

    }

    ngOnInit() {
        this.reloadTable(1);
        this.initFilterUsers();
        // this.initFilter();
        /*this.dataSource.sort = this.sort;*/
    }

    reloadTable(pageNo) {
        this.loading = true;
        this._activityLogService.getActivityLog(pageNo, this.filter.active ? this.filter : null).subscribe((response: any) => {
            if (response.status === 200) {

                const {docs, totalCount} = response.data;
                this.activities = docs;
                this.pagination.totalCount = totalCount;
                this.sortedData = this.activities.slice();
                this.dataSource = new MatTableDataSource(this.activities);

                this.pagination.pageNo = pageNo;
                this.initPagination();
            }
        }, console.error, () => this.loading = false);

    }

    resetFilter() {
        this.filter = this.filterInitialState();
        this.appliedFilter = {...this.filter};
        this.filter.dateRange = {
            startDate: moment().subtract(2, 'days'),
            endDate: moment(),
        };
        this.applyFilter();
    }

    filterInitialState() {
        return {
            active: false,
            dateType: 'range',
            dateRange: {
                startDate: moment().subtract(2, 'days'),
                endDate: moment(),
            },
            date: null,
            users: [],
            platforms: [],
            userId: null,
            platform: null
        };
    }

    initFilter() {
        this.filter = this.filterInitialState();
        this.initFilterUsers();
    }

    initFilterUsers() {

        Promise.all([this._userTypesRelationshipService.getDoctorsList().toPromise(), this._userTypesRelationshipService.getTranscriberList().toPromise()])
            .then(([doctors, transcribers]) => {

                if ((<any>doctors).status === 200) {
                    this.users = [...this.users, ...(<Array<any>>(<any>doctors).data)];
                }
                if ((<any>transcribers).status === 200) {
                    this.users = [...this.users, ...(<Array<any>>(<any>transcribers).data)];
                }
                this.users = this.users.map(u => {
                    u.name = `${u.first_name} ${u.last_name}`;
                    if(u.user_type == '1') {
                        u.name += `, ${u.title}`
                    }
                    return u;
                });
            });
    }

    applyFilter(filter?: IFilter) {

        if (filter) {
            this.filter = filter;
        } else {
            this.pagination.pageNo = 1;
            this.filter.active = true;
        }

        this._activityLogService.getActivityLog(this.pagination.pageNo, this.filter.active ? this.filter : null).subscribe((response: any) => {
            if (response.status === 200) {

                const {docs, totalCount} = response.data;
                this.activities = docs;
                this.pagination.totalCount = totalCount;
                this.sortedData = this.activities.slice();
                this.dataSource = new MatTableDataSource(this.sortedData);

                this.initPagination();
            }
        });
    }

    initPagination() {
        this.pagination.totalPages = Math.ceil(this.pagination.totalCount / 50);
        this.pagination.hasNextPage = this.activities.length > 0 && this.pagination.pageNo < this.pagination.totalPages;
        this.pagination.hasPrevPage = this.pagination.pageNo > 1;
    }

    handleNextClick() {
        this.reloadTable(this.pagination.pageNo + 1);

    }

    handlePrevClick() {
        this.reloadTable(this.pagination.pageNo - 1);
    }

    getUserInfo(userId) {
        const user = this.users.find(u => u._id === userId);

        return user;
    }

    sortData(sort: Sort) {
        const data = this.activities.slice();
        if (!sort.active || sort.direction === '') {
            this.sortedData = data;
            return;
        }
        this.sortBy = sort.active;
        this.sortedData = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'userName':
                    return compare(a.name, b.name, isAsc);
                case 'screen':
                    return compare(a.screen, b.screen, isAsc);
                case 'operation':
                    return compare(a.operation, b.operation, isAsc);
                case 'datetime':
                    return compare(new Date(a.datetime).getTime(), new Date(b.datetime).getTime(), isAsc);
                case 'platform':
                    return compare(a.platform, b.platform, isAsc);
                default:
                    return 0;
            }
        });
        this.dataSource = new MatTableDataSource(this.sortedData);
    }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
