import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-appointment-type-dialog',
  templateUrl: './appointment-type-dialog.component.html',
  styleUrls: ['./appointment-type-dialog.component.css']
})
export class AppointmentTypeDialogComponent implements OnInit {
 appointmentTypeData:any = {}
 color = '#ff0000';
 colorPickerOpen: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<AppointmentTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit(): void {
    if(this.data){
    this.appointmentTypeData = this.data;
      this.color = this.data.color;
    }
  }

  onSaveClick() {
    if(this.appointmentTypeData.name && this.appointmentTypeData.duration){
      this.appointmentTypeData.color = this.color
      const data = {
        action :  this.appointmentTypeData._id ? 'update' : 'add',
        appointmentType: this.appointmentTypeData
      }
      this.dialogRef.close(data);
    }
  }

  onCancelClick() {
    this.dialogRef.close();
  }
  onColorChange(color: string) {
  }

  closeColorPicker() {
    this.colorPickerOpen = false;
  }
}
