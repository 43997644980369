<mat-form-field appearance="fill" style="margin-left: 10px; max-width: 20%;">
    <mat-label *ngIf="currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE">Filter SNF WC Nurse</mat-label>
    <mat-label *ngIf="currentUser.user_type === global.USER_TYPE.DOCTOR">Filter by Provider</mat-label>
    <mat-select [(ngModel)]="provider" (ngModelChange)="handleProviderChange()">
        <input placeholder="Search" [(ngModel)]="searchTextProvider" [ngModelOptions]="{standalone: true}"
            style="color:#000; height: 40px; padding-left: 5px; background-color: #e0e0e0; border: none; width: 100%;" />
        <mat-option value="">--All--</mat-option>
        <mat-option *ngFor="let provider of providers | searchProvider: searchTextProvider" [value]="provider._id"
            placeholder="Provider">
            {{provider.first_name}} {{provider.last_name}}, {{provider.title}}
        </mat-option>
    </mat-select>
</mat-form-field>
<table mat-table [dataSource]="dataSource">
    <ng-container  matColumnDef="icon">
        <th  mat-header-cell *matHeaderCellDef mat-sort-header-container>
        </th>
        <td  mat-cell *matCellDef="let element">
                <img class="transcription-icon" *ngIf="element.is_signed == 'false'"
                     src="../../../assets/img/file.png">
                <img class="transcription-icon"
                     *ngIf="element.is_signed == 'true' && element.is_completed != 'true'"
                     src="../../../assets/img/signed_file.png">
                <img class="transcription-icon"
                     *ngIf="element.is_signed == 'true' && element.is_completed == 'true'"
                     src="../../../assets/img/completed_file.png">
        </td>
    </ng-container>
    <ng-container  matColumnDef="title">
        
        <th  mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Title
        </th>
        <td  mat-cell *matCellDef="let element">
            <div style="display: flex; align-self: center; margin-top: 10px;">
                <div >
                    <i class="icon icon-view" style="margin-top: 5px; margin-left: 10px; margin-right: 10px;" matTooltip="View Note" matTooltipPosition="above"
                        (click)="showViewNoteDialog(element)">
                    </i>
                </div>
                <div style="align-self: center;" [ngStyle]="{textDecoration: element.pcc_progressNoteStrikeOut ? 'line-through;' : 'auto'}">

                    {{element.transcribtion_title | uppercase}}
                    
                </div>
            </div>
        </td>
    </ng-container>
    <ng-container  matColumnDef="dos">
        <th  mat-header-cell *matHeaderCellDef mat-sort-header-container>
            DOS
        </th>
        <td  mat-cell *matCellDef="let element">
            {{getServiceDate(element)}}
        </td>
    </ng-container>
    <ng-container  matColumnDef="note_status">
        <th  mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Note Status
        </th>
        <td  mat-cell *matCellDef="let element">
            {{(element.note_status ? getNoteStatus(element.note_status.status) : '') | uppercase}}
        </td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<ngx-ui-loader [loaderId]="loaderId" bgsPosition= "center-center"></ngx-ui-loader>
