import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import * as global from '../global';
import { Subscription, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from "../../../environments/api-url";

@Injectable({
  providedIn: 'root'
})
export class NurseProviderAssociationService {
  currentUser: any;
  constructor(
    private httpclient: HttpClient,
    private _router: Router,
    private _authService: AuthService) {
    this.currentUser = this._authService.currentUser;
  }

  //active Billers
  getAllNurses(company_id) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      company_id: <string>null
    };
    item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type, item.company_id = company_id;
    return this.httpclient.post(global.url + API_URL.NURSE.getAllNurses, { details: item }).pipe();
  }
  getDoctorsList(data = {}) {
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      ...data
    };
    item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type;
    return this.httpclient.post(global.url + API_URL.USER.getDoctorsList, { details: item }).pipe();
  }
  createRelationNurseProvider(relationship) { 
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      ...relationship
    }
    item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type; 
    return this.httpclient.post(global.url + API_URL.NURSE.createRelationNurseProvider, {details: item}).pipe();
  }
  removeRelationNurseProvider(relationship) { 
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      ...relationship
    }
    item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type; 
    return this.httpclient.post(global.url + API_URL.NURSE.removeRelationNurseProvider, {details: item}).pipe();
  }
  getNurseRelations(company_id = null) { 
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null, 
      company_id: <string>null, 
    }
    item.id = this.currentUser._id, 
    item.auth_key = this.currentUser.auth_key, 
    item.usertype = this.currentUser.user_type;
    item.company_id = company_id;
    return this.httpclient.post(global.url + API_URL.NURSE.getNurseRelations, {details: item}).pipe();
  }
}
