<app-header *ngIf="currentUser.user_type !== global.USER_TYPE.BILLER"></app-header>
<app-biller-header *ngIf="currentUser.user_type === global.USER_TYPE.BILLER"></app-biller-header>

<main>

  <div class="container flex flex-col gap-2">
    <h3 class="dark-blue-heading">
      <button class="theme-btn" mat-raised-button class="dn-button-secondary" style="margin-right: 20px;"
        (click)="goBack()">Go Back</button>
      Profile
    </h3>
    <mat-card class="h100 flex-grow">
      <mat-card-content class="flex flex-col gap-2 h100">
        <div class="flex justify-center">
          <label class="hoverable" for="fileInput">
            <img [src]="currentUser.profile_pic ? currentUser.profile_pic : 'https://www.w3schools.com/howto/img_avatar.png'">
            <div class="hover-text">Choose file</div>
            <div class="background"></div>
          </label>
          <br />
          <input id="fileInput" type='file' (change)="onSelectFile($event)">

        </div>
        <form *ngIf="screen===0" class="flex justify-center items-top" #profileForm="ngForm">

          <div class="theme-form profile flex flex-col w-1/2 gap-2">

            <mat-form-field *ngIf="currentUser.user_type === global.USER_TYPE.DOCTOR || currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE">
              <mat-label>Title</mat-label>
              <mat-select [(ngModel)]="currentUser.title" matInput type="text" name="title" placeholder="Title"
                required>
                <mat-option *ngFor="let t of titles" value="{{t}}">{{t}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="!currentUser.title">Please enter title</mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>First Name</mat-label>
              <input matInput type="text" onkeypress="return /[a-z]/i.test(event.key)" name="firstname"
                placeholder="First Name" [(ngModel)]="currentUser.first_name" required>
              <mat-error *ngIf="!currentUser.firstname">Please enter first name</mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Last Name</mat-label>
              <input matInput type="text" onkeypress="return /[a-z]/i.test(event.key)" name="lastname"
                placeholder="Last Name" [(ngModel)]="currentUser.last_name" required>
              <mat-error *ngIf="!currentUser.lastname">Please enter last name</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Email</mat-label>
              <input matInput type="text" name="email" placeholder="Email Address" [value]="currentUser.email" disabled
                required>
              <mat-error *ngIf="!currentUser.email">Please enter email</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="currentUser.eprescription === true && currentUser.portal_type === global.PORTAL_TYPE.NON_WC">
              <input matInput type="text" name="scriptsure_email" placeholder="ScriptSure Email Address" [(ngModel)]="currentUser.scriptsure_email"
                required>
              <mat-error *ngIf="!currentUser.scriptsure_email">Please enter scriptsure email</mat-error>
            </mat-form-field>

            <div class="flex gap-2">

              <mat-form-field *ngIf="currentUser.mobile">
                <mat-label>Mobile</mat-label>
                <input matInput type="text" name="mobile" style="display: inline-block; width: inherit !important;"
                  placeholder="Mobile Number" [value]="currentUser.mobile" disabled required>
                <mat-error *ngIf="!currentUser.mobile">Please enter mobile</mat-error>
              </mat-form-field>
              <div *ngIf="!currentUser.mobile">
                <div><label for="addButton">Phone number</label></div>
                <div><button mat-icon-button color="warn" type="button" (click)="swapScreen(1)" id="addButton">
                    <mat-icon>add</mat-icon>
                  </button></div>
              </div>
            </div>
            <div class="profile-action">
              <button class="theme-btn" *ngIf="currentUser.portal_type === global.PORTAL_TYPE.WC && !currentUser?.google_auth_enabled" mat-raised-button (click)="enableGoogle2FA()" class="dn-button-primary">Enable Google 2FA</button>
              <button class="theme-btn" *ngIf="currentUser?.google_auth_enabled" mat-raised-button color="warn" (click)="disableGoogle2FA()" class="dn-button-primary">Disable Google 2FA</button>
              <button class="theme-btn" mat-raised-button (click)="updateProfile(profileForm)" class="dn-button-primary">Update profile</button>
              <button class="theme-btn" mat-raised-button class="dn-button-primary" 
                [routerLink]="['/reset-password']">Reset password</button>
            </div>

          </div>
        </form>
        <form *ngIf="screen===1" class="flex justify-center items-top" (submit)="sendVerificationCode(phoneForm.value)"
          #phoneForm="ngForm">

          <div class="theme-form profile flex flex-col w-1/2 gap-2">

            <mat-form-field>
              <input matInput type="text" name="mobile" #mobile="ngModel" [(ngModel)]="phone"
                (ngModelChange)="handlePhoneModelChange($event)" pattern="\d{3}-\d{3}-\d{4}" placeholder="Mobile Number"
                [value]="" required>
              <mat-error *ngIf="mobile.invalid && (mobile.dirty || mobile.touched)">Please enter mobile</mat-error>
            </mat-form-field>

            <div class="profile-action">
              <button type="button" (click)="swapScreen(0)" class="dn-button-primary" mat-raised-button>Back</button>
              <button type="submit" [disabled]="!phoneForm.form.valid" class="dn-button-primary" mat-raised-button>Send
                Verification Code</button>
            </div>

          </div>
        </form>
        <form *ngIf="screen===2" class="flex justify-center items-top"
          (submit)="checkVerificationCode(verificationForm.value)" #verificationForm="ngForm">

          <div class="theme-form profile flex flex-col w-1/2 gap-2">

            <mat-form-field>
              <input matInput type="text" name="code" #code="ngModel" [(ngModel)]="verificationCode"
                placeholder="Verification Code" [value]="" required>
              <mat-error *ngIf="code.invalid && (code.dirty || code.touched)">Please enter code</mat-error>
            </mat-form-field>

            <div class="profile-action">
              <button type="button" (click)="swapScreen(1)" class="dn-button-primary" mat-raised-button>Back</button>
              <button type="submit" [disabled]="!verificationForm.form.valid" class="dn-button-primary"
                mat-raised-button>Check Verification Code</button>
            </div>

          </div>
        </form>
      </mat-card-content>
    </mat-card>

  </div>
</main>