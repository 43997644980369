import { Component, OnInit,Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { WoundEnterpriseReportService } from 'src/app/services/wound-enterprise-report.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { AddWoundEnterpriseReportDialogueComponent } from './add-wound-enterprise-report-dialogue/add-wound-enterprise-report-dialogue.component';
import { DialogConfirmationComponent } from '../../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { lastValueFrom } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-wound-enterprise-reports',
  templateUrl: './wound-enterprise-reports.component.html',
  styleUrls: ['./wound-enterprise-reports.component.css']
})
export class WoundEnterpriseReportsComponent implements OnInit {

  @Input() showAddBtn: boolean = false;
  @Input() showEditBtn: boolean = false;
  @Input() showDeleteBtn: boolean = false;
  displayedColumns: string[] = ['sr', 'title', 'actions'];
  dataSource: any = new MatTableDataSource([]);
  loaderId = 'app-wound-enterprise-report';
  showIframe:boolean=false;
  srcValue:any= '';
  woundReports: any = [];
  constructor(
    private _toastr: ToastrService,
    private _woundEnterpriseReportService: WoundEnterpriseReportService,
    private loader: NgxUiLoaderService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer

  ) {}

  ngOnInit(): void {
    this.loader.startLoader(this.loaderId);
    this.getWoundEnterpriseReports()
  }
  async getWoundEnterpriseReports(){
    let res: any = await lastValueFrom(this._woundEnterpriseReportService.getWoundEnterpriseReports());
    if (res.status == 200) {
      this.woundReports = res.data;
      this.dataSource.data = this.woundReports;
      this.loader.stopLoader(this.loaderId);
    } else{ 
      this._toastr.error('Something went wrong', 'Error');
    }
  }
  updateTag(i, element){
    const dialogRef = this.dialog.open(AddWoundEnterpriseReportDialogueComponent, {
      height: 'auto',
      width: '700px',
      data:element,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if(data){
        this.loader.startLoader(this.loaderId);
        this.getWoundEnterpriseReports();
      }
    });
  }
  async deleteWoundEnterpriseReport(i, element){
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: 'Are you sure you want to delete this report?'
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result === "true") {
        this.loader.startLoader(this.loaderId);
          let res: any = await lastValueFrom(this._woundEnterpriseReportService.deleteWoundEnterpriseReport({ _id: element._id }));
          if (res.status == 200){
            this._toastr.success(res.message, "Success");
            this.getWoundEnterpriseReports();
          }
          else{
            this._toastr.error('Something went wrong', 'Error');
          }
      }
    }), () => this._toastr.error('Something went wrong removing Patient Census', 'Failed')
  }
  addNewReport(){
    const dialogRef = this.dialog.open(AddWoundEnterpriseReportDialogueComponent, {
      height: 'auto',
      width: '700px',
    });
    dialogRef.afterClosed().subscribe((data) => {
      if(data){
        this.loader.startLoader(this.loaderId);
        this.getWoundEnterpriseReports();
      }
    });
  }
  previwReport(element) {
    this.loader.startLoader(this.loaderId);
    this.showIframe = true
    this.srcValue = this.sanitizer.bypassSecurityTrustResourceUrl(element.iframe_value) as SafeResourceUrl;
    this.loader.stopLoader(this.loaderId);
  }
  closeIframe(){
    this.showIframe = false
  }

}
