import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as global from '../global';
import { MatTableDataSource } from '@angular/material/table';
import { FacilityService } from 'src/app/services/facility.service';
import { NoteAgingReportService } from './note-aging-report.service';
import { AuthService } from '../../services/auth.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';

@Component({
  selector: 'app-note-aging-report',
  templateUrl: './note-aging-report.component.html',
  styleUrls: ['./note-aging-report.component.css']
})
export class NoteAgingReportComponent implements OnInit {
  filteredFacilities: Observable<string[]>;
  public isVisibleTable: boolean = true;
  isVisibleDetailTable: boolean = false;
  fitlerButtonClicked: boolean = false;
  facility: any;
  facilityControl = new FormControl();
  showPccLogo: boolean = false;
  facilityMOdel: any = "";
  companyId: any;
  currentUser: any;
  facilities: any = [];
  providers: any = [];
  notes: any = [];
  displayedColumnsProviders = ['provider', '0-6 hrs', '7-13 hrs', '14-20 hrs', '21-27 hrs', '28-34 hrs', '35-41 hrs', '42-48 hrs', '48+ hrs', 'total'];
  displayedColumnsSnfWcNurse = ['snf_wc_nurse', '0-6 hrs', '7-13 hrs', '14-20 hrs', '21-27 hrs', '28-34 hrs', '35-41 hrs', '42-48 hrs', '48+ hrs', 'total'];
  displayedColumnsFacilities = ['facility', '0-6 hrs', '7-13 hrs', '14-20 hrs', '21-27 hrs', '28-34 hrs', '35-41 hrs', '42-48 hrs', '48+ hrs', 'total'];
  displayedColumns =[];
  dataSource: any = new MatTableDataSource([]);
  radioButtonValue = 'provider';
  index: any;

  constructor(private _route: ActivatedRoute, private facilityService: FacilityService, private _authService:AuthService,
    private _noteAgingReportService: NoteAgingReportService) {
      this.currentUser=this._authService.currentUser
     }
  ngOnInit(): void {
    console.log("current user: ", this.currentUser)
    let event = {value: 'provider'}
    if (this.currentUser.user_type == 'snf_wc_nurse') {
      event.value = 'snf_wc_nurse';
      this.radioButtonValue = 'snf_wc_nurse';
    }
    this.onSelectOption(event);

    if (window.location.pathname.includes('company') && !window.location.pathname.includes('manage-companies')) {
      this.companyId = this._route.parent.parent.snapshot.params.id;
    }
    else if (window.location.pathname.includes('manage-companies')) {
      this.companyId = this._route.parent.parent.snapshot.params.id;
    }
    else if (this.currentUser.user_type === global.USER_TYPE.BILLER) {
      this.companyId = this.currentUser.company_id;
    }
    if (this.index == 0)
      this.initCompanyFacilities();

    this.filteredFacilities = this.facilityControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => this.filterFacilities(value))
    );

    this.setDisplayedColumns();  
  }

  private filterFacilities(value: string): string[] {
    if (value) {
        const filterValue = value.toLowerCase();
        if (value.length == 1) {
            this.showPccLogo = false;
        }
        return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) === 0);
    }
    else {
        return this.facilities;
    }
}

  setDisplayedColumns(){
    if (this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE && this.index == 0)
    this.displayedColumns = this.displayedColumnsSnfWcNurse
    else if (this.index == 0)
      this.displayedColumns = this.displayedColumnsProviders
    else
      this.displayedColumns = this.displayedColumnsFacilities
  }

  onSelectOption(event: any) {
    if (event.value == 'provider' || event.value == 'snf_wc_nurse') {
      this.index = 0;
    }
    else if (event.value == 'facility') {
      this.index = 1;
    }
    this.setDisplayedColumns(); 
    if (this.facility != undefined)     // undefined when loaded for first time
      this.initSignedNotesDifferenceCount();
  }

  async initSignedNotesDifferenceCount() {
    this.providers = [];
    let filter: any;
    if (this.index == 0) {
      if (this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE) {
        filter = {                                      // filter for snf
          facility_id: this.facility._id,
          is_snf_nurse: true,
          $expr: { $gt: [{ $size: "$associated_snf_wc_nurse_ids" }, 0] }
        };
      }
      else {
        filter = {                                      // filter for providers
          _id: this.facility._id,
          is_active: true,
          $expr: { $gt: [{ $size: "$provider_ids_ref" }, 0] }
        };
      }
      
    }
    else {
      filter = {                                     // filter for facilities
        assoc_company_ids: this.companyId,
        is_active: true,
      };
    }

    const response: any = await this._noteAgingReportService.getFacilityAssocProviderNotes(filter).toPromise();
    if (response.status === 200) {
        this.providers = response.data;
        this.dataSource.data = this.providers;
    }
}
  async initCompanyFacilities() {
    const filter = { assoc_company_ids: this.companyId, is_active: true };
    const projection = {
      'title': 1,
      'is_active': 1,
      'source': 1,
      'assoc_company_ids': 1,
    }

    let companyAssocPopulation: facilityPopulationInterface = {
      collection: 'companies',
      field: ["assoc_company_ids"],
      filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: [true, { $in: ['$_id', '$$assoc_company_ids'] }] }] } },
      project: { name: 1 },
    };

    const response: any = await this.facilityService.getFacilities(filter, projection, [companyAssocPopulation]).toPromise();
    if (response.status === 200) {
      this.facilities = response.data.array;
      this.facility = this.facilities[0];
      this.facilityMOdel = this.facility.title;

      this.filteredFacilities = this.facilityControl.valueChanges.pipe(
        startWith(''),
        map((value: any) => this.filterFacilities(value))
      );

      this.initSignedNotesDifferenceCount();
    }
  }

  handleApplyFilterClick() {
    this.isVisibleDetailTable = false;
    this.isVisibleTable = true;
    this.fitlerButtonClicked = true;
  }

  clearFacilityFilter(){
    this.facilityMOdel = '';
    this.facility = '';
    this.showPccLogo = false;
}

  resetFilter() {
    this.fitlerButtonClicked = false;
    this.isVisibleDetailTable = false;
    this.isVisibleTable = true;
    this.showPccLogo = false;
    this.facilityControl.setValue(this.facilities[0].title);
    this.facility._id = this.facilities[0]._id;
    this.initSignedNotesDifferenceCount();
  }
  onSelectFacility(item = null) {
    if (item) {
      this.facility = item;
      if (item.pcc_facId || item.source === 'PointClickCare') {
        this.showPccLogo = true;
      } else {
        this.showPccLogo = false;
      }

      this.filteredFacilities = this.facilityControl.valueChanges.pipe(
        startWith(''),
        map((value: any) => this.filterFacilities(value))
      );

      this.initSignedNotesDifferenceCount();
    }
  }

}
