import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-time-picker-widget',
  templateUrl: './time-picker-widget.component.html',
  styleUrls: ['./time-picker-widget.component.css']
})
export class TimePickerWidgetComponent implements OnInit {
  @Input() label: any = 'DOS';
  @Input() readonly: any;
  @Input() value: any;
  @Input() format: any;
  @Input() isDisabledWatch: boolean = false;
  @Output() onchangeTime: EventEmitter<any> = new EventEmitter()
  hourlist: any;
  minlist: any;
  meridiemlist: any;
  selectedHour = "";
  selectedMin = "";
  selectedMed = "";
  showdropdown: any;
  dos_date : any;
  previousHour = "";
  previousMin = "";
  previousMed = "";
  constructor() {
    this.minlist = [];
    for(let i = 0;i <= 60; i++){
      if(i< 10){
        this.minlist.push("0"+i.toString());
      }else{
        this.minlist.push(i.toString());
      }
    }
    this.meridiemlist = ["AM", "PM"];
  }

  ngOnInit(): void {
    if(this.format == "24"){
      this.hourlist = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
    }else{
      this.format = "12";
      this.hourlist = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    }
    if(this.value){
      let date = this.value.split(" ");
      this.dos_date = date[0];
      let timeData = date[1].split(":");
      if(this.format == "12"){
        this.selectedMed = date[2];
        this.previousMed = this.selectedMed;
      }
      this.selectedHour = timeData[0];
      this.selectedMin = timeData[1];
      this.previousHour = this.selectedHour;
      this.previousMin = this.selectedMin;
    }
  }
  selectHour(h: any){
    this.selectedHour = h;
    this.value = this.dos_date + " " + this.selectedHour + ":" + this.selectedMin;
  }
  selectMin(min: any){
    this.selectedMin = min;
    this.value = this.dos_date + " " + this.selectedHour + ":" + this.selectedMin;
  }
  selectMed(med: any){
    this.selectedMed = med;
  }
  showDropDown(value){
    if(!value){
      this.value = this.dos_date + " " + this.previousHour + ":" + this.previousMin;
      this.selectedHour = this.previousHour;
      this.selectedMin = this.previousMin;
    }
    this.showdropdown = value;
  }
  confirmTime() {
    this.previousHour = this.selectedHour;
    this.previousMin = this.selectedMin;
    this.showdropdown = false;
    this.onchangeTime.emit({
      hour: this.selectedHour,
      min: this.selectedMin
    });
  }
}
