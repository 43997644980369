import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { NurseProviderAssociationService } from '../nurse-provider-association.service';

@Component({
  selector: 'app-nurse-provider-association',
  templateUrl: './nurse-provider-association.component.html',
  styleUrls: ['./nurse-provider-association.component.css']
})
export class NurseProviderAssociationComponent implements OnInit {
  public response: any;
  public relations: any;
  public dataSource: any;
  public nurses: any = [];
  providers: any = [];
  providersName: any = [];
  selectedProviders: any = [];
  providerDropdownSettings: any = {};
  currentUser: any;
  company_id: any;
  loaderId = "app-nurse-provider-association";
  displayedColumns2: string[] = ['nurse_name', 'provider_name'];
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private loader: NgxUiLoaderService,
    public services: NurseProviderAssociationService,
    public userTypesRelationshipService: UserTypesRelationshipService,
    private toastr: ToastrService,
    private authService: AuthService,
    private route: ActivatedRoute,
  ) {
    this.currentUser = this.authService.currentUser;
    this.providerDropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'full_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: false,
    };
  }

  async ngOnInit(): Promise<void> {
    if (window.location.pathname.includes('company')) {
      this.company_id = this.route.parent.snapshot.params.id
    }
    else {
      this.company_id = '';
    }
    this.loader.startLoader(this.loaderId);
    await this.loadDataForNurses();
  }
  async loadDataForNurses() {
    let res = await this.services.getAllNurses(this.company_id).subscribe(async data => {
      this.nurses = data;
      this.nurses = this.nurses.data;
      await this.userTypesRelationshipService.getDoctorsList('true', this.company_id).subscribe(async data => {
        this.providers = data;
        this.providers = this.providers.data;
        await this.services.getNurseRelations(this.company_id).subscribe(async data => {
          this.relations = data;
          if (this.relations.status === 200) {
            this.nurses.forEach(async nurse => {
              this.relations.data.forEach(async relation => {
                if (nurse._id == relation.nurse_id._id) {
                  nurse.assos_providers = relation.assoc_provider_id;
                }
              });
              nurse.providers = this.providers
            });

            this.dataSource = new MatTableDataSource(this.nurses);
            this.loader.stopLoader(this.loaderId);
          }
        })
      })
    });
  }
  applyFilter(filterValue: string) {
    let filter = filterValue.trim().toLowerCase();
    if (filter) {
      let filterVal = [];
      this.nurses.forEach(nurse => {
        if (nurse.full_name.trim().toLowerCase().includes(filter)) {
          filterVal.push(nurse)
          this.dataSource = filterVal;
        }
      });
    }
    else {
      this.dataSource = this.nurses;
    }
  }
  onProviderSelect(provider, nurse) {
    const details = {
      providerID: provider._id,
      nurseID: nurse._id,
      company_id: this.company_id,
    }
    this.createRelationBillerProvider(details);
  }
  onSelectAllProvider(event, nurse) {
    this.selectedProviders = event;
    const idsOfAllProviders = [];
    this.providers.forEach(element => {
      idsOfAllProviders.push(element._id);
    });

    const details = {
      nurseID: nurse,
      providerID: idsOfAllProviders,
      company_id: this.company_id,
    }
    this.createRelationBillerProvider(details);
  }
  onProviderDeSelect(provider, nurse) {
    const details = {
      nurseID: nurse._id,
      providerID: provider._id
    }
    this.removeRelationBillerProvider(details);
  }
  createRelationBillerProvider(details) {
    this.loader.startLoader(this.loaderId);
    this.services.createRelationNurseProvider(details).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("Relationship updated");
        this.loader.stopLoader(this.loaderId);
      }else{
        this.toastr.error("Something Went Wrong!");
      }
    });
  }
  removeRelationBillerProvider(details) {
    this.loader.startLoader(this.loaderId);
    this.services.removeRelationNurseProvider(details).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("Relationship updated")
        this.loader.stopLoader(this.loaderId);
      }else{
        this.toastr.error("Something Went Wrong!");
      }
    });
  }
  getEmailId(element) {
    return element?.email ? element.email : '';
  }
}
