<div class="wound-wrapper" mat-dialog-content style="padding: 0 26px"> 
    <div class="columns-main flex gap-2 justify-center items-center">

        <div class="columns flex gap-2">
            
            <div class="column flex-grow" *ngFor="let column of dataset">
                <div class="rows flex flex-col">
                    <div class="row" *ngFor="let data of column.rows">
                        <div class="item" [class.selected]="isFieldValueSelected(data.value, column.header)" (click)="selectFieldValue(data.value, column.header)">
                            <ng-container *ngIf="data.data_type === 'Text'">
                                {{ data.value }}
                            </ng-container>
                            <ng-container *ngIf="data.data_type === 'Dropdown'">
                                <mat-select>
                                    <mat-option *ngFor="let item of data.value">{{ item }}</mat-option>
                                </mat-select>
                            </ng-container>
                            <ng-container *ngIf="data.data_type === 'Date'">
                                {{ data.value | date: 'MM/mm/yyyy' }}
                            </ng-container>
                        </div>
                    </div>
                    <div class="row">
                        <div class="item" [class.selected]="getCustomTextValue(column.header)">
                            <mat-form-field>
                                <input matInput #customText [value]="getCustomTextValue(column.header)" (input)="selectedDataset[getFieldName(column.header)] = customText.value" />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br />
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>
<div class="top-right-close-btn">
    <button mat-mini-fab (click)="updateWoundDataAndClose()">
        <mat-icon>cancel</mat-icon>
    </button>
</div>
