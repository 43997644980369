import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-amputation-report-v2',
  templateUrl: './amputation-report-v2.component.html',
  styleUrls: ['./amputation-report-v2.component.css']
})
export class AmputationReportV2Component implements OnInit {
  @Input() amputationReport = [];
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = [
    // 'organization', 'faciltiy',
     'first_name', 'last_name'];
  constructor() { }

  ngOnInit(): void {
    this.dataSource.data = this.amputationReport;
  }

}
