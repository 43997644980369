<div class="wrapper" *ngIf="questionnaireResults && questionnaireResults.length">
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="measure_number">
            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Measure Number
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.measure_id.quality_number}}
            </td>
        </ng-container>
        <ng-container matColumnDef="answer">
            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Response
            </th>
            <td mat-cell *matCellDef="let element">
               {{ element.answer }}
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>