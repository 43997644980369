<app-header></app-header>

<main>
  <div class="container flex flex-col">
    <div *ngIf="deviceNotSupported">
        We support the latest versions of Chrome, Firefox, Safari, and Edge. Update your browser and try your request again.
      </div>
      
      <button>Start</button>
  </div>
</main>
