import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-payer-information',
  templateUrl: './payer-information.component.html',
  styleUrls: ['./payer-information.component.css']
})
export class PayerInformationComponent implements OnInit {
  @Input('patient') patient;
  displayedColumns = [
    "payer",
    "name",
    "medicare_no",
    "insurance_company"
  ];  
  dataSource: any;
  table = [];
  constructor() { }

  async ngOnInit() {
    await this.normalizeData(this.patient.payer_information);
  }

  async normalizeData(data) {
    data.forEach(element => {
      this.table.push(
        {
          payer_type: 'Primary Payer',
          primary_payer_name: element.primary_payer.name ? element.primary_payer.name : '',
          medicare_no : element.primary_payer.medicare_no ? element.primary_payer.medicare_no : '',
          insurance_company : element.primary_payer.insurance_company ? element.primary_payer.insurance_company : ''
        },
        {
          payer_type: 'Secondary Payer',
          primary_payer_name: element.secondary_payer.name ? element.secondary_payer.name : '',
          medicare_no : element.secondary_payer.medicare_no ? element.secondary_payer.medicare_no : '',
          insurance_company : element.secondary_payer.insurance_company ? element.secondary_payer.insurance_company : ''
        },
        {
          payer_type: 'Third Payer',
          primary_payer_name: element.third_payer.name ? element.third_payer.name : '',
          medicare_no : element.third_payer.medicare_no ? element.third_payer.medicare_no : '',
          insurance_company : element.third_payer.insurance_company ? element.third_payer.insurance_company : ''
        },
        {
          payer_type: 'Fourth Payer',
          primary_payer_name: element.fourth_payer.name ? element.fourth_payer.name : '',
          medicare_no : element.fourth_payer.medicare_no ? element.fourth_payer.medicare_no : '',
          insurance_company : element.fourth_payer.insurance_company ? element.fourth_payer.insurance_company : ''
        }
        );
    });
    return this.dataSource = this.table;
  }
}
