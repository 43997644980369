import { Component,Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as global from '../../global';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import moment from 'moment'
// UPLOAD FILE DIALOG
export interface ViewNoteAuditDialog {
  type;
  noteId;
  rawText:string;
}

@Component({
  selector: 'app-view-note-audit-dialog',
  templateUrl: './view-note-audit-dialog.component.html',
  styleUrls: ['./view-note-audit-dialog.component.css']
})
export class ViewNoteAuditDialog implements OnInit {
  global = global;
  
  audit;
  timezone;

  loaderId = 'view-note-audit-loader';
  
  constructor(
    public dialogRef: MatDialogRef<ViewNoteAuditDialog>,
    // private loader: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: ViewNoteAuditDialog) { 
      if(data) {
        this.audit = data.audit;
        this.timezone = data.timezone;
         
      }
    }
    
    ngOnInit(): void {
    }
    getAuditDateWithTime(audit){
        if(audit.user_date_string){
          return audit.user_date_string;
        }else if(audit.timestamp){
          return this.applyTimezone(audit.timestamp)
        }
    }
    applyTimezone(date) {
        return moment(new Date(date).toLocaleString('en-US', { timeZone:  this.timezone })).format('MMM DD, YYYY h:mm a');
    }
  
}
