<app-header></app-header>

<main>
    <div class="container">
        <mat-card *ngIf="categories.length > 0">
            <mat-card-content class="flex flex-col gap-4">
                <div class="flex justify-between items-center">
                    <div class="flex items-center gap-5"
                        style="margin-bottom: 0px !important; height: 40px;">
                        <mat-form-field>
                            <mat-label>Filter</mat-label>
                            <mat-select name="filter" #filters="ngModel" (selectionChange)="refreshData($event)"
                                [(ngModel)]="filter">
                                <mat-option value="cpt_id">
                                    CPTs
                                </mat-option>
                               
                            </mat-select>
                        </mat-form-field>
                    </div>
                    
                </div>
                <div class="flex flex-row gap-7">
                    <div style="position: relative; width: 100%">
                        <div class="flex justify-between items-center" style="height: 50px !important;">
                            <div class="flex items-center">
                                <h2>Medical Codes</h2>
                            </div>
                            <div class="flex justify-end items-center">
                                <mat-form-field appearance="fill">
                                    <mat-label>Search</mat-label>
                                    <input matInput placeholder="search charges"
                                        (keyup)="searchCharges($event.target.value)" [(ngModel)]="searchFilter.all">
                                </mat-form-field>
                            </div>
                        </div>
                        <div>
                            <mat-table class="table-row-hover" style="max-height: 400px; min-height: 400px;width: 100%"
                                *ngIf="filter === 'cpt_id'" [dataSource]="dataSource">
                                <ng-container matColumnDef="code">
                                    <mat-header-cell style="width: 20%; max-width: 20% !important;" *matHeaderCellDef>
                                        Code</mat-header-cell>
                                    <mat-cell style="width: 20%; max-width: 20% !important;" *matCellDef="let element">
                                        {{element.code}}</mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="description">
                                    <mat-header-cell style="width: 70%; max-width: 70% !important;" *matHeaderCellDef>
                                        Description</mat-header-cell>
                                    <mat-cell style="width: 70%; max-width: 70% !important;" *matCellDef="let element">
                                        {{element.description}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="favourite">
                                    <mat-header-cell style="width: 10%; max-width: 10% !important;" *matHeaderCellDef>
                                    </mat-header-cell>
                                    <mat-cell style="width: 10%; max-width: 10% !important;" *matCellDef="let element">
                                        <span *ngIf="element.isFavourite === true"
                                            (click)="removeCategorisedCharge(element)" style="cursor: pointer"
                                            class="material-icons">
                                            star
                                        </span>
                                        <span class="material-icons" *ngIf="element.isFavourite !== true"
                                            (click)="addtoCategorisedCharge(element)"
                                            style="cursor: pointer">star_outline</span>
                                    </mat-cell>
                                </ng-container>
                                <mat-header-row class="header-row" *matHeaderRowDef="displayedColumns"></mat-header-row>

                                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

                            </mat-table>
                            <mat-table class="table-row-hover" style="max-height: 400px; min-height: 400px;
                                   width: 100%" *ngIf="filter === 'icd_id'" [dataSource]="dataSource">
                                <ng-container matColumnDef="code">
                                    <mat-header-cell style="width: 20%; max-width: 20% !important;" *matHeaderCellDef>
                                        Code</mat-header-cell>
                                    <mat-cell style="width: 20%; max-width: 20% !important;" *matCellDef="let element">
                                        {{element.code}}</mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="description">
                                    <mat-header-cell style="width: 70%; max-width: 70% !important;" *matHeaderCellDef>
                                        Description
                                    </mat-header-cell>
                                    <mat-cell style="width: 70%; max-width: 70% !important;" *matCellDef="let element">
                                        {{element.shortDescription}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="favourite">
                                    <mat-header-cell style="width: 10%; max-width: 10% !important;" *matHeaderCellDef>
                                    </mat-header-cell>
                                    <mat-cell style="width: 10%; max-width: 10% !important;" *matCellDef="let element">
                                        <span *ngIf="element.isFavourite === true"
                                            (click)="removeCategorisedCharge(element)" style="cursor: pointer"
                                            class="material-icons">
                                            star
                                        </span>
                                        <span class="material-icons" *ngIf="element.isFavourite !== true"
                                            (click)="addtoCategorisedCharge(element)"
                                            style="cursor: pointer">star_outline</span>
                                    </mat-cell>
                                </ng-container>
                                <mat-header-row class="header-row" *matHeaderRowDef="displayedColumns"></mat-header-row>

                                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

                            </mat-table>
                            <ngx-ui-loader [loaderId]="loaderId[0]"></ngx-ui-loader>
                        </div>
                        <div style="height: 5px;"></div>
                        <div class="flex flex-row gap-2 justify-center items-center table-nav">
                            <button mat-raised-button class="dn-button-primary" [disabled]="!pagination.hasPrevPage"
                                (click)="handlePrevClick()"> Prev</button>

                            <span style="background: white; border-radius: 2px; font-size: 12px;padding: 5px;">
                                {{getCountofCodes()}}</span>

                            <button mat-raised-button class="dn-button-primary" [disabled]="!pagination.hasNextPage"
                                (click)="handleNextClick()">Next</button>
                        </div>
                        <div style="height: 5px;"></div>
                    </div>
                    <div style="position: relative; width: 100%;">
                        <mat-form-field>
                            <mat-label>Select Category</mat-label>
                            <mat-select name="filter" #filters="ngModel" (selectionChange)="categoryChanged($event)"
                                [(ngModel)]="selectedCategory">
                                <mat-option value="cpt_id" *ngFor="let category of categories" [value]="category">
                                    {{category.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-table class="table-row-hover" style="max-height: 400px; min-height: 400px;"
                            [dataSource]="categorisedChargesDataSource">
                           
                            <ng-container matColumnDef="code">
                                <mat-header-cell style="width: 20%; max-width: 20% !important;" *matHeaderCellDef>Code
                                </mat-header-cell>
                                <mat-cell style="width: 20%; max-width: 20% !important;" *matCellDef="let element">
                                    {{element.code}}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="description">
                                <mat-header-cell style="width: 70%; max-width: 70% !important;" *matHeaderCellDef>
                                    Description</mat-header-cell>
                                <mat-cell style="width: 70%; max-width: 70% !important;" *matCellDef="let element">
                                    {{element.description}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="favourite">
                                <mat-header-cell style="width: 10%; max-width: 10% !important;" *matHeaderCellDef>
                                </mat-header-cell>
                                <mat-cell style="width: 10%; max-width: 10% !important;" *matCellDef="let element">
                                    <span class="material-icons"
                                        (click)="removeCategorisedCharge(element, 'categorised')"
                                        style="cursor: pointer">star</span>
                                </mat-cell>
                            </ng-container>
                            <mat-header-row class="header-row" *matHeaderRowDef="displayedColumns"></mat-header-row>

                            <mat-row *matRowDef="let row; columns: displayedColumns" cdkDragLockAxis="y" cdkDrag
                                [cdkDragData]="row"></mat-row>

                        </mat-table>
                        <ngx-ui-loader [loaderId]="loaderId[1]"></ngx-ui-loader>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card *ngIf="categories.length <= 0">
            <mat-card-content>
                <div style="display: flex; place-content: space-between; align-items: center;">
                    <div>
                        No Charges Category is created.
                    </div>
                    <div>
                        <button class="dn-button-primary"  mat-raised-button (click)="openCategoryDialoge()" >Create Category</button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</main>