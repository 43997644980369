<div class="container">

    <div class="flex items-center gap-5">
        <app-input-with-dropdown class="flex justify start" [noDropdown]="true" placeholder="Search Provider"
            (inputChange)="applyFilter($event.target.value)">
        </app-input-with-dropdown>

        <button mat-raised-button class="dn-button-secondary" [routerLink]="['../']">
            Go Back
        </button>
    </div>
    <div style="height: 10px;"></div>
    <mat-card>
        <mat-card-content>
            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="doctor_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Providers Name</th>
                    <td mat-cell *matCellDef="let element" style="width: 20%"> {{element.first_name}}
                        {{element.last_name}} </td>
                </ng-container>

                <ng-container matColumnDef="transcriber_name">
                    <th mat-header-cell *matHeaderCellDef> Transcribers</th>
                    <td mat-cell *matCellDef="let element" style="width: 60%">
                        <ng-multiselect-dropdown name="transcribers" [(ngModel)]="element.transcribers"
                            [placeholder]="'Select Transcriber(s)'" [data]="transcribers"
                            [settings]="transcriberDropdownSettings"
                            (onSelect)="onTranscriberSelect($event,element._id);createRelationship(element);"
                            (onSelectAll)="onSelectAllTranscriber($event,element._id);createRelationship(element);"
                            (onDeSelect)="onTranscriberDeSelect($event,element._id);createRelationship(element);">
                        </ng-multiselect-dropdown>
                    </td>

                </ng-container>
                <ng-container matColumnDef="qa">
                    <th mat-header-cell *matHeaderCellDef> QA</th>
                    <td mat-cell *matCellDef="let element" style="width: 20%">
                        <ng-multiselect-dropdown name="transcribers" [(ngModel)]="element.qaElement"
                            [placeholder]="'Select QA'" [data]="element.transcribers" [settings]="qaDropdownSettings"
                            (onDeSelect)="removeQA($event, element)" (onSelect)="selectQA($event, element)">
                        </ng-multiselect-dropdown>
                    </td>

                </ng-container>
                <ng-container matColumnDef="qa_select">
                    <th mat-header-cell *matHeaderCellDef> Select QA</th>
                    <td mat-cell *matCellDef="let element" style="">
                        <div class="">
                            <select class="select-style" style="padding: 8px 8px; width: 158px"
                                (change)="changeQA($event.target.value, element)">
                                <option value="0">Select QA.</option>
                                <option *ngFor="let item of element.transcribers" [value]="item._id">
                                    {{item.first_name + ' ' + item.last_name}}
                                </option>
                            </select>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="qa_name">
                    <th mat-header-cell *matHeaderCellDef>
                        Current QA
                    </th>
                    <td mat-cell *matCellDef="let element" style="">
                        <h3 *ngIf="element.qa" style="color: black;">{{element.qa.first_name + ' ' +
                            element.qa.last_name}}</h3>
                        <h3 *ngIf="element.qa === undefined || element.qa === null" style="color: black;">
                            None
                        </h3>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

        </mat-card-content>
    </mat-card>
</div>