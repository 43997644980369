import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { PatientRoundingSheetService } from 'src/app/includes/patient-rounding-sheets/patient-rounding-sheet.service';
@Component({
  selector: 'app-cpt-pos',
  templateUrl: './cpt-pos.component.html',
  styleUrls: ['./cpt-pos.component.css']
})
export class CptPosComponent implements OnInit {

  dataSource: any = new MatTableDataSource([]);
  associatedCPTs: any;
  allPos: any;
  relations: any;
  posDropdownSettings: any = {};
  displayedColumns: string[] = ['cpt', 'pos', 'defaultPOS'];
  loaderId = 'cpt-pos-loader';
  company_id: any;
  currentUser: any;
  constructor(
    private companyService: CompanyService,
    private loader: NgxUiLoaderService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private service: PatientRoundingSheetService
  ) {
    this.posDropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'codeName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 4,
      allowSearchFilter: true,
      enableCheckAll: true,
    };
  }

  async ngOnInit() {
    this.currentUser = this.authService.currentUser;
    this.company_id = this.route.parent.snapshot.params.id;
    this.loader.startLoader(this.loaderId);
    await this.getAllPOS();
    await this.initCompanyCPTs();
  }

  async initCompanyCPTs() {
      this.service.getCompanyCPTs({companyId: this.company_id, page: 0, search: ""}).subscribe((res: any) => {
      if (res.status == 200) {
        this.associatedCPTs = res.data.array;
        this.initRelations();
        this.dataSource = new MatTableDataSource(this.associatedCPTs);
      } else {
        this.loader.stopLoader(this.loaderId);
        console.log('something went wrong');
      }
    });
  }
  async getAllPOS() {
    this.companyService.getAllPOS({}, {}).subscribe((res: any) => {
      if (res.status == 200) {
        this.allPos = res.data;
      } else {
        console.log('something went wrong');
      }
    });
  }

  async initRelations() {
    this.companyService.getCPTPOSRelations({ company_id: this.company_id }).subscribe((res: any) => {
      if (res.status == 200) {
        this.relations = res.data;
        const get31POS = this.allPos.find(pos => pos.code == '31');
        this.associatedCPTs.forEach(associatedCPT => {
          associatedCPT.pos_ids = [];
          if (!get31POS)
            associatedCPT.arrayOfDefaultPOS = [];
          else
            associatedCPT.arrayOfDefaultPOS = [get31POS];
          this.relations.forEach(relation => {
            if (associatedCPT._id == relation.cpt_id) {
              associatedCPT.pos_ids = relation.pos_ids;
              associatedCPT.default_pos = relation.default_pos?.code.toString();
              const cptAssociatedPOS = associatedCPT.pos_ids;
              cptAssociatedPOS.forEach(pos => {
                if (pos.code != 31) associatedCPT.arrayOfDefaultPOS.push(pos);
              })
            }
          });
        });
        this.loader.stopLoader(this.loaderId);
      } else {
        console.log('something went wrong');
      }
    });
  }
  onDefaultPosChange(element, selectedValue: any) {
    let filter = { company_id: this.company_id, cpt_id: element._id };
    this.companyService.updateDefaultPOS(selectedValue.value, filter).subscribe((res: any) => {
      if (res.status == 200) {
        this.toastr.success('Relation Updated!', 'Success');
      } else {
        console.log('something went wrong');
      }
    });
  }

  async onPOSSelect(element, $event) {
    let isCreating = true;
    let newPos = [];
    newPos.push($event._id);
    const getPOS = this.allPos.find(pos => pos._id == $event._id);
    if (getPOS.code != '31') element.arrayOfDefaultPOS.push(getPOS);
    await this.updatePOSRelation(this.company_id, element._id, newPos, isCreating);
  }

  async onPOSDeSelect(element, $event) {
    let isCreating = false;
    let newPos = [];
    newPos.push($event._id);
    const getPOS = this.allPos.find(pos => pos._id == $event._id);
    if (element.default_pos == getPOS.code) {
      const get31POS = this.allPos.find(pos => pos.code == '31');
      element.default_pos = get31POS.code.toString()
    }
    const indexToRemove = element.arrayOfDefaultPOS.findIndex(obj => obj._id === getPOS._id);
    const POSToRemove = element.arrayOfDefaultPOS[indexToRemove];
    if (indexToRemove !== -1 && POSToRemove.code != '31') element.arrayOfDefaultPOS.splice(indexToRemove, 1);
    await this.updatePOSRelation(this.company_id, element._id, newPos, isCreating);
  }

  async onSelectAllPOS(element, $event) {
    let isCreating = true;
    let newPos = $event;
    element.arrayOfDefaultPOS = this.allPos;
    await this.updatePOSRelation(this.company_id, element._id, newPos, isCreating);
  }

  async onDeSelectAllPOS(element, $event) {
    let isCreating = false;
    let newPos = $event;
    const get31POS = this.allPos.find(pos => pos.code == '31');
    element.default_pos = get31POS.code.toString();
    element.arrayOfDefaultPOS = [get31POS];
    await this.updatePOSRelation(this.company_id, element._id, newPos, isCreating);
  }

  async updatePOSRelation(companyId, cptId, posID, isCreating: boolean) {
    this.companyService.updatePOSRelation(companyId, cptId, posID, isCreating).subscribe((res: any) => {
      if (res.status == 200) {
        this.toastr.success('Relation Updated!', 'Success');
      } else {
        console.log('something went wrong');
      }
    });
  }

  applyFilter(filterValue: any) {
    this.dataSource.filter = filterValue;
  }
}
