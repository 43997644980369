
<div class="flex flex-col note-sidebar" *ngIf="!(currentUser.charge_status && note?.rounding_sheet_id) && allDataLoaded">
  <ng-container>
    <div cdkDropList cdkDropListOrientation="horizontal" class="example-list flex justify-start"
      (cdkDropListDropped)="drop($event)">
      <ng-container *ngFor="let tab of updatedArray; let index = index">
        <div class="example-box example-ripple-container" cdkDrag matRipple (click)="tabChange(tab,index)"
          [ngStyle]="{'border-bottom':tab.is_active ? '2px solid #3f51b5' : '' }">
          {{tab.name}}
        </div>
      </ng-container>
      <mat-icon
        matTooltip="Watch Charge Capture Video"
        style="cursor: pointer; margin: 12px 10px 0;"
        (click)="watchHelpVideo('charge-capture-video')"
      >help</mat-icon>
    </div>
    <div *ngIf="tab == 'Therapy'">
      <app-therapy-tab [patient]="note.patient"></app-therapy-tab>
    </div>
    <div *ngIf="tab == 'Phrases'">
      <app-phrases [note]="note" (insertPhraseText)="insertPhraseText.emit($event)"></app-phrases>
    </div>
    <div *ngIf="tab == 'Census Comments'">
      <app-census-comments [censusId]="censusId" [patientId]="patientId"></app-census-comments>
    </div>
    <div [style.display]="tab == 'Skin/Wound' || tab == 'Skin & Wound' ? 'block' : 'none'">
      <app-wound-care-widget [configuration]="configuration" [companyPermissions]="companyPermissions" [patient]="note.patient" [provider]="note.provider" [note]="note" (woundDetailsChange)="handleWoundDetailsChange($event)"
      (procedureNoteDetailsChange)="handleProcedureNoteDetailsChange($event)" 
      (checkallWoundAssessmentPhrase)="handlecheckallWoundAssessmentPhrase($event)"></app-wound-care-widget>
    </div>
    <div [style.display]="tab == 'Charge Capture' ? 'block' : 'none'">
      <app-note-add-charge [note]="note" [noteEditorIcds]="noteEditorIcds" [signedNotesStatus]="signedNotesStatus"
        [companySubmitMipsStatus]="companySubmitMipsStatus" [carryChargeStatusIcds]="carryChargeStatusIcds"
        [carryChargeStatusCpts]="carryChargeStatusCpts" (noteEditorIcdsChange)="handleNoteEditorIcdsChange($event)"
        (noteEditorCptsChange)="handleNoteEditorCptsChange($event)" [emCodeEditorCpts]="emCodeEditorCpts"
        [noteEditorCpts]="noteEditorCpts" [telemedPhrase]="telemedPhrase" (sidebarClose)="sidebarClose($event)"
        (onChargeAndICDChange)="handleChargeandICDChange($event)" (onPhrasesChange)="handlePhrasesChange($event)"
        (onChargeChange)="handleChargeChange($event)"> </app-note-add-charge>
    </div>
    <div *ngIf="tab == 'Patient Details'">
      <app-note-patient-details [patient]="note.patient" [note]="note"></app-note-patient-details>
    </div>
    <div *ngIf="tab == 'Note Time Logs'">
      <app-note-time-logs [note]="note"></app-note-time-logs>
    </div>
    <div *ngIf="tab == 'History'">
      <app-note-auditory-history [note]="note" [noteId]="noteId"></app-note-auditory-history>
    </div>
    <div *ngIf="tab == 'Communication'">
        <app-communication style="display: inline-block;"
          [patient]="patient_object" [parent_]="true">
        </app-communication>
    </div>


    <div *ngIf="tab == 'Addendums'">
      <app-note-addendums [note]="note" (onAddendumClick)="handleAddendumClick($event)"></app-note-addendums>
    </div>
  </ng-container>
</div>


<div class="flex flex-col note-sidebar" *ngIf="currentUser.charge_status && note?.rounding_sheet_id && allDataLoaded">
  <ng-container>
    <div cdkDropList cdkDropListOrientation="horizontal" class="example-list flex justify-start"
      (cdkDropListDropped)="drop($event)">
      <ng-container *ngFor="let tab of updatedArray; let index = index">
        <div class="example-box example-ripple-container" cdkDrag matRipple (click)="tabChange(tab,index)"
          [ngStyle]="{'border-bottom':tab.is_active ? '2px solid #3f51b5' : '' }">
          {{tab.name}}
        </div>
      </ng-container>
    </div>
    <div *ngIf="tab == 'Therapy'">
      <app-therapy-tab [patient]="note.patient"></app-therapy-tab>
    </div>
    <div *ngIf="tab == 'Note Time Logs'">
      <app-note-time-logs [note]="note"></app-note-time-logs>
    </div>
    <div *ngIf="tab == 'Census Comments'">
      <app-census-comments [censusId]="censusId" [patientId]="patientId"></app-census-comments>
    </div>
    <div [style.display]="tab == 'Skin/Wound' || tab == 'Skin & Wound' ? 'block' : 'none'">
      <app-wound-care-widget [configuration]="configuration" [companyPermissions]="companyPermissions" [note]="note" [patient]="note.patient" [provider]="note.provider"
      (onICDsSave)="handleICDsSave($event)"
      (woundDetailsChange)="handleWoundDetailsChange($event)"
      (procedureNoteDetailsChange)="handleProcedureNoteDetailsChange($event)"
      (checkallWoundAssessmentPhrase)="handlecheckallWoundAssessmentPhrase($event)"
      ></app-wound-care-widget>
    </div>
    <div [style.display]="tab == 'Charge Capture' ? 'block' : 'none'">
      <app-note-add-charge [note]="note" [noteEditorIcds]="noteEditorIcds" [signedNotesStatus]="signedNotesStatus" [carryChargeStatusIcds]="carryChargeStatusIcds" [carryChargeStatusCpts]="carryChargeStatusCpts"  (noteEditorIcdsChange)="handleNoteEditorIcdsChange($event)" (noteEditorCptsChange)="handleNoteEditorCptsChange($event)" [emCodeEditorCpts]="emCodeEditorCpts" [noteEditorCpts]="noteEditorCpts" [telemedPhrase]="telemedPhrase" (sidebarClose)="sidebarClose($event)"
      (onChargeAndICDChange)="handleChargeandICDChange($event)" (onPhrasesChange)="handlePhrasesChange($event)" (onChargeChange)="handleChargeChange($event)"> </app-note-add-charge>
    </div>
    <div *ngIf="tab == 'Telemedicine'">
      <app-zoom [patient]="note.patient" [note]="note"></app-zoom>
    </div>
    <div *ngIf="tab == 'Patient Details'">
      <app-note-patient-details [patient]="note.patient" [note]="note"></app-note-patient-details>
    </div>
    <div *ngIf="tab == 'Phrases'">
      <app-phrases [note]="note" (insertPhraseText)="insertPhraseText.emit($event)"></app-phrases>
    </div>
    <div *ngIf="tab == 'History'">
      <app-note-auditory-history [note]="note" [noteId]="noteId"></app-note-auditory-history>
    </div>
    <div *ngIf="tab == 'Communication'">
          <app-communication
            [patient]="patient_object" [parent_]="true">
          </app-communication>
    </div>




    <div *ngIf="tab == 'Addendums'">
      <app-note-addendums [note]="note" (onAddendumClick)="handleAddendumClick($event)"></app-note-addendums>
    </div>
  </ng-container>
  <ng-container
    *ngIf="currentUser.user_type == global.USER_TYPE.TRANSCRIBER || currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT">
  </ng-container>
</div>