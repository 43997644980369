import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CKEditorService } from 'src/app/includes/note-editor/ckeditor.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WoundService } from 'src/app/services/wound.service';
import { lastValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PhrasesService } from 'src/app/includes/phrases/phrases.service';
import { AuthService } from 'src/app/services/auth.service';
import { WoundCareWidgetService } from 'src/app/includes/note-sidebar/wound-care-widget/wound-care-widget.service';

@Component({
  selector: 'app-phrase-template-editor',
  templateUrl: './phrase-template-editor.component.html',
  styleUrls: ['./phrase-template-editor.component.css']
})
export class PhraseTemplateEditorComponent implements OnInit {
  template: string = '';
  phrase_key: string;
  sub_phrase_keys: [string];
  cke4Config;
  marker = '.';
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  constructor(
    public dialogRef: MatDialogRef<PhraseTemplateEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PhraseTemplateEditorComponent,
    private ckeditorService: CKEditorService,
    private _woundService: WoundService,
    private _toaster: ToastrService,
    private ngZone: NgZone,
    private phrasesService: PhrasesService,
    private authService: AuthService,
    private woundCareWidgetService: WoundCareWidgetService

  ) {
    if (data) {
      this.template = data.template;
      this.phrase_key = data.phrase_key;
      this.sub_phrase_keys = data.sub_phrase_keys;
      console.log(data);
    }
    this.cke4Config = {
      ...this.ckeditorService.getDefaultConfig(),
      editable: true
    };

    const extraPlugins = this.cke4Config.extraPlugins.split(',');
    this.cke4Config.extraPlugins = [...extraPlugins, 'selectdropdown', 'woundphrasesautocomplete'].join(',')
    // allow data attribute
    this.cke4Config.extraAllowedContent = 'p span[id][data-*](*)'
  }

  ngOnInit(): void {
    this.initPhrases();
    this.dialogRef.afterClosed().subscribe(() => {
      this.phrasesService.setWoundTemplatePhrases([]) //.subscribe();
    });
    this.initGlobalScope();
  }
  initGlobalScope() {
    window['angularComponentReference'] = {
        component: this, zone: this.ngZone,
        componentDataMembers: {
            componentName: 'phrase-template-editor',
            phrases: this.phrasesService.castWoundTemplatePhrases
        },
        componentFunctions: {
            resolveSystemDefinedPhraseValue: (key, extra) => this.resolveSystemDefinedPhraseValue(key),
            
            onResolvePhraseStart: (phrase) => {
                // this._changeDetectorRefs.detectChanges();
            },
            onResolvePhraseEnd: (phrase, error) => {
                // this._changeDetectorRefs.detectChanges();
            }
        }
    };
}
async initPhrases() {
  let sub_phrase_keys = this.sub_phrase_keys.map(item => ({key: item}))
  const { data, status } = await lastValueFrom(this.woundCareWidgetService.getAllDataset(this.authService.currentUser.company_id, {header: 1})) as any;
  if(status === 200) {
    const headers = data.map(item => ({key: item.header.toLowerCase().trim().replace(/\s/g, '_'), dataSet: item.dataSet}));
    // this.phrasesService.setWoundTemplatePhrases([...sub_phrase_keys, ...headers]) //.subscribe();
    sub_phrase_keys = [...sub_phrase_keys, ...headers].reduce((acc, item) => {
      if(!acc.find(accItem => accItem.key === item.key)) {
        acc.push(item);
      }
      return acc;
    }, []);
    
  } 
  this.phrasesService.setWoundTemplatePhrases(sub_phrase_keys) //.subscribe();
  
}
 async resolveSystemDefinedPhraseValue(key) {
        return key;
        const keyWithoutMarker = key.substring(this.marker.length);
        // switch(keyWithoutMarker) {
        //     case 'phrase@dn':
        //         return '';
        //     default: 
        //         return {error: ''}
        // }
    }
  async saveTemplate() {
    const infoToast = this._toaster.info('Updating Template', 'Please wait', { disableTimeOut: true });

    var details = {
      ...this.authService.authObject,
      phrase_key: this.phrase_key,
      template: this.template
    }
    const {status, data} = await lastValueFrom(this._woundService.saveSystemDefinedPhraseTemplate(details)) as any;
    if(status == 200) {
      this._toaster.clear(infoToast.toastId);
      this._toaster.success("Updated Template Successfully", "Success");
      this.dialogRef.close(data);
    }else{
      this._toaster.clear(infoToast.toastId);
      this._toaster.error("Something Went Wrong", "Failed");
    }
  }

  onReadyCkeditor(e) {
    const { editor } = e;
    this.ckeditorService.ckeditorRemoveTitle(editor);
  }
}
