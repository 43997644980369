<div class="container flex flex-col gap-2">

    <h3 class="date-div" >
        <button style="height: 40px; align-self: center; margin-left: 5px;"
            mat-raised-button
            class="dn-button-secondary"
            [routerLink]="['../reports']" >
            Go Back
        </button>

        <div class="flex flex-1" style="width: 75%; place-content: center;height: 51px;" >
            <input matInput type="text" ngxDaterangepickerMd [(ngModel)]="filter.reportDateFilter"
                matTooltip="Date of Creation" matTooltipPosition="above" class="mat-like-input" [ranges]="preDateRanges"
                [alwaysShowCalendars]="true" [linkedCalendars]="true" [showClearButton]="false"
                (datesUpdated)="applyDateFilterReport($event)" style="color: white; cursor: pointer;
                        width: 225px;
                        height: 50px;
                        font-size: 16px !important;
                        background: #2d3e5b !important;" />
        </div>

        <span *ngIf="!currentUser.product_owner" style="align-self: center;font-weight: bolder; color: white;margin-right: 5px;">
            {{companyName}}
        </span>
    </h3>

    <div class="flex justify-start items-center gap-5" style="padding: 5px; background: white">
       
        <app-input-with-dropdown class="flex" [noDropdown]="true" placeholder="Search Provider"
            (inputChange)="searchProvider($event.target.value)" style="align-self: center !important;">
        </app-input-with-dropdown>

        <mat-form-field *ngIf="currentUser.product_owner">
            <mat-label>Companies</mat-label>
            <mat-select value="true" [ngModel]="filter.company_id" (selectionChange)="applyCompanyFilter($event)">
                <mat-option *ngFor="let company of companies" value="{{company._id}}">{{company.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field  >
            <mat-label>Select Facility</mat-label>
            <mat-select [(ngModel)]="filter.facility_id" (selectionChange)="applyFacilityChange($event);" >
                <mat-option *ngFor="let facility of facilities" value="{{facility._id}}">{{facility.title ?
                    facility.title : ""}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Select Provider</mat-label>
            <mat-select (selectionChange)="applyProviderChange($event);" [(ngModel)]="filter.provider_id">
                <mat-option value="">--All--</mat-option>
                <mat-option *ngFor="let pro of providers" value="{{pro._id}}">{{pro.full_name ?
                    pro.full_name : ""}}</mat-option>
            </mat-select> 
        </mat-form-field>
    </div> 

    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="provider">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Provider</th>
            <td mat-cell *matCellDef="let element"> 
                {{getProviderName(element)}} 
            </td>
        </ng-container>

        <ng-container matColumnDef="submit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Submitted</th>
            <td mat-cell *matCellDef="let element">
                {{getSubmitted(element)}}  
            </td>
        </ng-container>

        <ng-container matColumnDef="draft">
            <th mat-header-cell *matHeaderCellDef> Drafted </th>
            <td mat-cell *matCellDef="let element ; let i = index;">
                {{getDrafted(element)}}  
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

</div>
