import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TherapyService } from 'src/app/includes/therapy-tab/therapy.service';
import { CensusDetailReportComponent } from '../../includes/therapy-tab/census-detail-report/census-detail-report.component'
@Component({
  selector: 'app-patient-title',
  templateUrl: './patient-title.component.html',
  styleUrls: ['./patient-title.component.css']
})
export class PatientTitleComponent implements OnInit {

  @Input() patient;
  @Input() facility;
  object: any={
    maxPT:'',
    maxOT:'',
    maxST:''
  };
  

  constructor(private therapyService: TherapyService) { }

  ngOnInit(): void {
  }

  async getPTSoc(patient) {
    this.object.maxPT='loading...';
    this.object.maxOT='loading...';
    this.object.maxST='loading...';

   
    let res = this.therapyService.castobject.subscribe((data: any) => {
      console.log("data  : ", data);
      this.object = data;
    });
    this.therapyService.getPTSoc(patient);
    
  }
}
