<div class="flex items-start justify-between gap-5">
    <div class="flex justify-start items-center flex-grow">
        <mat-card class="search-card flex-grow">
            <input type="text" class="search-field" placeholder="Search Facilties" name="title"
                [(ngModel)]="facilityTitle" (ngModelChange)="searchWithPagination(true);" />
            <mat-spinner *ngIf="isSearchingFacilities" style="margin-left: auto;" matSuffix
                [diameter]="18"></mat-spinner>
        </mat-card>
    </div>
    <div class="flex justify-start items-center flex-grow">
        <mat-card class="search-card flex-grow">
            <input type="text" class="search-field" placeholder="Search Templates" name="providerTitle"
                [(ngModel)]="templateTitle" (ngModelChange)="searchWithPagination(false);" />
            <mat-spinner *ngIf="isSearchingTemplates" style="margin-left: auto;" matSuffix
                [diameter]="18"></mat-spinner>
        </mat-card>
    </div>
    <div class="flex justify-start items-center gap-2">
        <div class="table-nav flex flex-grow gap-2 items-center justify-center">
            <button mat-raised-button class="dn-button-primary" (click)="goToPreviousPage()"
                [disabled]="!pagination.hasPrevPage">
                Prev</button>
            <span style="width: 120px;">
                {{pagination.skip + templates.length === 0 ? 0 : pagination.skip + 1 }} - {{pagination.skip +
                templates.length}} out of {{pagination.totalRecords}}
            </span>
            <button mat-raised-button class="dn-button-primary" (click)="goToNextPage()"
                [disabled]="!pagination.hasNextPage">Next</button>
        </div>
    </div>
</div>
<br>
<table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="template">
        <th mat-header-cell *matHeaderCellDef> Template </th>
        <td mat-cell *matCellDef="let element" style="width: 30%">
            {{element.title}}
        </td>
    </ng-container>

    <ng-container matColumnDef="facility">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Facility</th>
        <td mat-cell *matCellDef="let element; let index = index;">

            <app-mat-multi-select-chips [element]="element" [ngModelData]="element.associatedFacilities || []" [isMulti]="true" [isFacility]="true" [allData]="element.facilities | orderBy:'title'"  [selectAll]="true" [providerKey]="'associatedFacilities'" (onDataSelection)="onDataSelection($event, index)" (onDataRemove)="onDataRemove($event, index)"></app-mat-multi-select-chips> 
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>