import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PCCService } from 'src/app/services/pcc.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-pcc-auth-redirect',
  templateUrl: './pcc-auth-redirect.component.html',
  styleUrls: ['./pcc-auth-redirect.component.css']
})
export class PccAuthRedirectComponent implements OnInit, OnDestroy {

  inprogressChangeSubscription:Subscription;
  constructor(
    private _pccService:PCCService,
    private _route: ActivatedRoute,
    private commonService: CommonService,
    private _router: Router,
    ) { }

  ngOnInit(): void {
    this.inprogressChangeSubscription = this._pccService.inprogress$.subscribe(async () => {
      // await this._pccService.importPCCFacilities().toPromise();

      const redirectUrl = this._pccService.getRedirectUrl() || '/dashboard';
      this._pccService.setRedirectUrl('');
      const validateUrl = this.commonService.isValidPath(redirectUrl);
      this._router.navigateByUrl(validateUrl, { replaceUrl: true });
    })
  }

  ngOnDestroy():void {
    if(this.inprogressChangeSubscription) {
      this.inprogressChangeSubscription.unsubscribe();
    }
  }
}
