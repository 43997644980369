import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { UserService } from 'src/app/services/Modules/user.service';
import { WoundNurseRelationService } from 'src/app/services/Modules/woundNurse.service';
import * as global from '../../includes/global';
import { MatTableDataSource } from '@angular/material/table';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-wound-nurse-provider-association',
  templateUrl: './wound-nurse-provider-association.component.html',
  styleUrls: ['./wound-nurse-provider-association.component.css']
})
export class WoundNurseProviderAssociationComponent implements OnInit {
  
  providers: any = [];
  nurses: any = [];
  companyId = '';
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = ['nurses', 'providers'];
  providerDropdownSettings: any = {};
  relations: any = [];
  loaderId: string = "app-wound-nurse-provider-association";

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private woundNurseRelationService : WoundNurseRelationService,
    private loader: NgxUiLoaderService

  ) {
    this.providerDropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'full_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: true,
    };
  }

  async ngOnInit() {
    this.companyId = this.route.parent.snapshot.params.id;
    this.loader.startLoader(this.loaderId);
    await this.initProviders();
    await this.initAllWoundNurses();
    await this.loadRelations();
    this.loader.stopLoader(this.loaderId);

  }

  async initProviders() {
    let filter = { company_id : this.companyId, user_type : global.USER_TYPE.DOCTOR, is_activated : 'true' };
    let projection = { first_name : 1, last_name : 1, title : 1 , user_type : 1 };
    const usersResponse : any = await lastValueFrom(this.userService.getUsers(filter,projection));
    if(usersResponse.status == 200) {
      this.providers = usersResponse.data;
      this.providers.forEach(provider => {
        provider.full_name = provider.first_name + ' ' + provider.last_name + ' ' + provider.title; 
      });
    }else {
      this.toastr.warning(usersResponse.message);
    }
  }

  async initAllWoundNurses() {
    let filter = { company_id : this.companyId, user_type : global.USER_TYPE.WOUND_NURSE, is_activated : 'true' };
    let projection = { first_name : 1, last_name : 1, title : 1 , user_type : 1 };
    const usersResponse : any = await lastValueFrom(this.userService.getUsers(filter,projection));
    if(usersResponse.status == 200) {
      this.nurses = usersResponse.data;
      this.dataSource.data = this.nurses;
    }else {
      this.toastr.warning(usersResponse.message);
    }
  }

  async loadRelations() {
    let filter = { company_id : this.companyId };
    const relations : any = await lastValueFrom(this.woundNurseRelationService.getWoundNurseProviderRelations(filter));
    if(relations.status == 200) {
      this.relations = relations.data;
      this.nurses.forEach(nurse => {
        this.relations.forEach(relation => {
          if(nurse._id === relation.wound_nurse_id) {
            nurse.associated_provider_ids = relation.associated_provider_ids;
          }
        });
      });
    }
  }

  async onProviderSelect($event, element) {
    const filter = { wound_nurse_id : element._id, company_id : this.companyId };
    const data = { associated_provider_ids: $event._id };
    
    const relationResponse : any = await lastValueFrom(this.woundNurseRelationService.updateWoundNurseProviderRelation(filter, data));
      if (relationResponse.status == 200) {
        this.toastr.success("Relation Updated");
      }
      else {
        this.toastr.error(relationResponse.message);
      }
  }

  async onProviderDeSelect($event, element) {
    const filter = { wound_nurse_id : element._id, company_id : this.companyId };
    const data = { associated_provider_ids: $event._id };
    let isRemoving: boolean = true;
    
    const relationResponse : any = await lastValueFrom(this.woundNurseRelationService.updateWoundNurseProviderRelation(filter, data, isRemoving));
      if (relationResponse.status == 200) {
        element.associated_provider_ids = element.associated_provider_ids.filter(provider => provider._id.toString() != $event._id.toString())
        this.toastr.success("Relation Updated");
      }
      else {
        this.toastr.error(relationResponse.message);
      }
  }

  onDataRemove($event) {
    this.onProviderDeSelect($event.data, $event.element);
  }

  onDataSelection($event) {
    // let providers = element.associated_provider_ids.map(a => a._id);
    // let idx = this.users.findIndex(user => user._id.toString == element._id.toString());
    // this.users[idx] = element;
    // this.dataSource.data = this.users;
    if($event.data == 'selectAll') {
      this.onSelectAllProviders($event.element.assos_providers, $event.element);

    }
    else if($event.data == 'unSelect') {
      this.onDeSelectAllProviders([],$event.element);
    }
    else {
      if($event.status) {
        this.onProviderSelect($event.data, $event.element);
      }
      else {
        this.onProviderDeSelect($event.data, $event.element);
      }
    }
  }

  async onSelectAllProviders($event, element) {
    element.associated_provider_ids = this.providers;
    $event = element.associated_provider_ids.map(a => a._id);
    const filter = { wound_nurse_id : element._id, company_id : this.companyId };
    const data = { associated_provider_ids: $event };
    const relationResponse : any = await lastValueFrom(this.woundNurseRelationService.updateWoundNurseProviderRelation(filter, data, false));
      if (relationResponse.status == 200) {
        this.toastr.success("Relations Updated");
      }
      else {
        this.toastr.error(relationResponse.message);
      }
  }

  async onDeSelectAllProviders($event, element) {
    const filter = { wound_nurse_id : element._id, company_id : this.companyId };
    const data = { associated_provider_ids: $event };
    let isRemoving: boolean = true;
    
    const relationResponse : any = await lastValueFrom(this.woundNurseRelationService.updateWoundNurseProviderRelation(filter, data, isRemoving));
      if (relationResponse.status == 200) {
        this.toastr.success("Relations Updated");
      }
      else {
        this.toastr.error(relationResponse.message);
      }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
