<div class="wound-image-snapshot-dialog">
    <h1 mat-dialog-title style="text-align: center;">{{wound.createdAt | date: 'MM/dd/yyyy'}}</h1>
    <h2  mat-dialog-title style="font-size: 16px;">Capture & Upload Wound Image</h2>

    <div class="flex main-container">
        <div class="video-container">
            <div>
                <video
                    #video
                    id="video"
                    width="100%"
                    height="auto"
                    [style.min-width]="WIDTH"
                    [style.min-height]="HEIGHT"
                    autoplay
                ></video>
                <canvas
                    style="display: none"
                    #canvas
                    id="canvas"
                    [width]="WIDTH"
                    [height]="HEIGHT"
                ></canvas>
            </div>

            <div class="flex items-start justify-baseline gap-1">
                <button mat-button class="capture-btn" (click)="capture()">
                    <mat-icon>camera_alt</mat-icon> Capture
                </button>
                <button
                    mat-button
                    class="capture-btn"
                    (click)="uploadWoundImages()"
                    *ngIf="captures.length > 0"
                >
                    <mat-icon>cloud_upload</mat-icon> Upload {{ captures.length }} {{ captures.length > 1 ? 'Images' : 'Image'}} 
                </button>
            </div>
        </div>
        <div class="sidebar-container">
            <ng-container *ngFor="let image of captures; let i = index">
                <div style="position: relative">
                    <img
                        src="{{ image }}"
                        alt="Wound Image {{ i + 1 }}"
                        style="max-width: 100%"
                    />
                    <button
                        mat-icon-button
                        class="remove-image-btn"
                        (click)="removeImage(image)"
                    >
                        <mat-icon>cancel</mat-icon>
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>
