import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as global from '../../../includes/global';
import { API_URL } from "../../../../environments/api-url";
import { AuthService } from 'src/app/services/auth.service';
import { HttpService } from 'src/app/services/http.service';

@Injectable({
  providedIn: 'root',
})
export class DoctorDocumentService {
  currentUser: any;
  constructor(private httpclient: HttpClient, private http:HttpService, private authService: AuthService) {
    this.currentUser = authService.currentUser
  }

  saveDoctorDocumentFile(file, provider_id_ref) {

    const payload = {
      auth: JSON.stringify(this.authService.authObject),
      data: JSON.stringify({
        title: file.name,
        provider_id_ref,
        uploaded_by_id: this.currentUser._id
      })
    };

    const formData: any = new FormData();
    formData.append('file', file);
    
    for (let key of Object.keys(payload)) {
      formData.append(key, payload[key]);
    }
    return this.httpclient.post(global.url + API_URL.DOCTOR_PROFILE.saveDoctorDocumentFile,formData).pipe();
  }


  updateDoctorDocumentFile(element) {
    const details = {
      ...element
    }
    return this.http
      .post(global.url + API_URL.DOCTOR_PROFILE.updateDoctorDocumentFile, details);
  }
  getDoctorDocumentFile(filter, projection?) {
    const details = {
      filter,
      projection
    }
    return this.http
      .post(global.url + API_URL.DOCTOR_PROFILE.getDoctorDocumentFile, details);
  }

  getDoctorDocumentFiles(filter, projection?) {
    const details = {
      filter,
      projection
    }
    return this.http
      .post(global.url + API_URL.DOCTOR_PROFILE.getDoctorDocumentFiles, details);
  }
}
