import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PatientRoundingSheetService } from '../../patient-rounding-sheets/patient-rounding-sheet.service';
import { CensusListService } from '../../census-list/census-list..service';
import { SidebarDiagnosisService } from 'src/app/components/sidebar-addCharges/sidebarDiagnonses/sidebar-diagnoses/sidebar-diagnosis.service';

@Component({
  selector: 'app-matrix-diagnosis',
  templateUrl: './matrix-diagnosis.component.html',
  styleUrls: ['./matrix-diagnosis.component.css']
})
export class MatrixDiagnosisComponent implements OnInit {

  @Input('patient') patient;

  @Input('showCheckboxes') showCheckboxes = false;
  @Input('selectedDiagnoses') selectedDiagnoses;
  @Output() pccDiagnosesChange = new EventEmitter<any>();
  constructor(
    private loader: NgxUiLoaderService,
    private service: PatientRoundingSheetService,
    private censusListService: CensusListService,
    private sidebarDiagnosisService: SidebarDiagnosisService,

  ) { }
  apiData;
  page = 1;
  dataSource;
  pccSelectedDiagnosis: any = [];
  displayedColumns = [
    "icd10",
    "icd10Description",
    "date",
  ];

  loaderId = 'conditions-loader';
  inprogress = false;
  selectedSidebarICDs: any[] = [];


  ngOnInit(): void {
    console.log("patient in matrix diagnosis-------: ", this.patient);

    this.loadData();  

    this.sidebarDiagnosisService.castSelectedICDs.subscribe((icds => {
      
      this.selectedSidebarICDs = icds;
    }));
  }

  ngDoCheck() {
    if (this.selectedDiagnoses) {
      this.selectedSidebarICDs = this.selectedDiagnoses;
    }
  }

  async loadData() {
    this.loader.startLoader(this.loaderId);
    this.apiData = this.patient.matrix_diagnosis;
    let icds = this.apiData.map(a => a.icd10)
    this.service.compareICDs(icds).subscribe((res: any) => {
      if (res.status == 200) {
        let i = 0;
        this.apiData.forEach(p => {
          if (res.data.some(a => a.code == p.icd10)) {
            p.pdpm_status = true;
          }
          else {
            p.pdpm_status = false;
          }
        });
        this.apiData = this.apiData;
        this.dataSource = new MatTableDataSource(this.apiData);
      }
    })
    this.loader.stopLoader(this.loaderId);
  }

  applyFilterICD(value) {
    if (this.apiData) {
      if (value) {
        let filteredData = [];
        this.apiData.data.map(a => {
          if (a.icd10.toLowerCase().includes(value) || a.icd10Description.toLowerCase().includes(value)) {
            filteredData.push(a)
          }
        })
        this.dataSource = filteredData;
      }
      else {
        this.dataSource = this.apiData.data;
      }
    }
  }
  indexOfSelectedICDCode(code) {
    if (Array.isArray(this.selectedSidebarICDs) && this.selectedSidebarICDs.length > 0) {
      const index = this.selectedSidebarICDs.map((icd: any) => icd.code.toLowerCase().trim()).indexOf(code.toLowerCase().trim());
      return index;
    }
    return -1;
  }

  isICDCodeSelected(code) {
    return this.indexOfSelectedICDCode(code) >= 0
  }

  async toggleSelectICDCode(code: string, description: string) {
    this.selectedSidebarICDs.slice();
    const index = this.indexOfSelectedICDCode(code);
    if (index === -1) {
      const details = {
        icdCode: code,
        description: description
      }
      const icdResponse: any = await this.censusListService.addICD(details).toPromise();
      if (icdResponse.status === 200 || icdResponse.isAlreadyExists) {
        let icd;
        if(Array.isArray(icdResponse.data) && icdResponse.data.length > 0) {
          icd = icdResponse.data[0];  
        } else {
          icd = icdResponse.data;
        }
        icd.selected = false;
        this.selectedSidebarICDs.push(icd);
        this.pccDiagnosesChange.emit(icd)
      }
    } else {
      let icd = this.selectedSidebarICDs[index];
      icd.selected = true;
      this.selectedSidebarICDs.splice(index, 1);
      this.pccDiagnosesChange.emit(icd)
    }
    this.sidebarDiagnosisService.setSelectedICDs(this.selectedSidebarICDs);
  }
}
