import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EmailTemplateService } from '../email-template/service/email-template.service';
import { SendEmailTemplateDialogComponent } from '../send-email-template-dialog/send-email-template-dialog.component';

@Component({
  selector: 'app-automated-email',
  templateUrl: './automated-email.component.html',
  styleUrls: ['./automated-email.component.css']
})
export class AutomatedEmailComponent implements OnInit, OnDestroy {
  loaderId = 'automated-email';
  displayedColumns: string[] = [
    'sr', 'subject', 'emailBody', 'duration', 'status', 'actions'];
  automatedEmails = [];
  dialogSubscription;
  dataSource: any = new MatTableDataSource([]);
  constructor(
    private _emailTemplateService: EmailTemplateService,
    private _toastr: ToastrService,
    private dialog: MatDialog,
    private loader: NgxUiLoaderService,
  ) { }

  ngOnInit(): void {
    this.getEmail();
  }
  ngOnDestroy() {
    if(this.dialogSubscription) {
      this.dialogSubscription.unsubscribe();
    }
  }
  handleDurationChange(duration: string, element) {
    element.duration = duration;
    this.updateEmail(element);
  }

  handleStatusChange(status: string, element) {
    element.status = status;
    this.updateEmail(element);
  }

  deleteEmail(emailId, index) {
    let status = "inactive";
    this._emailTemplateService.deleteEmail(emailId, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.automatedEmails.splice(index, 1);
        this.dataSource.data = [];
        this.dataSource.data = this.automatedEmails;
      }
    }, err => {
      this._toastr.error(err, "ERROR");
    }
    );
  }

  updateEmail(element) {
    this._emailTemplateService.updateEmail(element).subscribe((response: any) => {
      if (response.status == 200) {
        this._toastr.success("Email Updated", "Success");
      }
    }, err => {
      this._toastr.error(err, "ERROR");
    }
    );
  }
  openSendEmailTemplateDialog(emailId, index): void {
    let options: any = {
      width: '70%',
      height: '70vh'
    }
    options.data = emailId;
    this.dialogSubscription = this.dialog.open(SendEmailTemplateDialogComponent, options).afterClosed()
      .subscribe((automatedEmail) => {
        if (automatedEmail) {
          this.dataSource.data = [];
          this.automatedEmails[index] = automatedEmail;
          this.dataSource.data = this.automatedEmails;
        }
      });
  }
  getEmail() {
    this.loader.startLoader(this.loaderId);
    const project = {
      subject: 1,
      body: 1,
      duration: 1,
      status: 1
    };
    const filter = {
      is_automatic: true
    };
    this._emailTemplateService.getEmail(filter, project).subscribe((response: any) => {
      if (response.status == 200) {
        this.automatedEmails = response.data;
        this.dataSource.data = this.automatedEmails;
        this.loader.stopLoader(this.loaderId);
      }
    }, err => {
      this._toastr.error(err, "ERROR");
    }
    );
  }

}
