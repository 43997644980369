<app-header></app-header>
<main>

    <div class="h100 flex">
        <div class="left-col container"
            [ngClass]="{'left-col-close': leftColClose === true, 'col-close-width': leftColWidth === true}">

            <div class="flex-grow" style="display: flex; flex-direction: column; height: 100%;">
                <div *ngIf="datas.user_type === global.USER_TYPE.DOCTOR || datas.user_type === global.USER_TYPE.SNF_WC_NURSE || datas.user_type === global.USER_TYPE.WOUND_NURSE || datas.user_type === global.USER_TYPE.VIEW_ONLY_USER || datas.user_type === global.USER_TYPE.BUSINESS_OFFICER"
                    style="z-index: 9999;height: calc(100% - 185px);overflow: auto;">
                    <mat-expansion-panel 
                        [expanded]="true" *ngIf="datas.company_type !== global.COMPANY_TYPE.WOUND && datas.company_type !== global.COMPANY_TYPE.SNF">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Dictations
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <ul>
                            <li [ngClass]="{'active': status === 'All_Dictations'}"
                                (click)="router.navigate(['/dashboard', { category: 'dict', status: 'All_Dictations' }]); category='dict'; disableChargesContainer()"
                                [matTooltip]="leftColClose ? 'All Dictations' : null" matTooltipPosition="right">
                                <img src="../../../assets/img/icons/all_icon.png">
                                <span>All Dictations</span>
                            </li>

                        </ul>

                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="isDocumentDropdownExpanded">
                        <mat-expansion-panel-header
                            (click)="isDocumentDropdownExpanded=!isDocumentDropdownExpanded;isChargeDropdownExpanded = false;isSkinWoundDropdownExpanded = false;">
                            <mat-panel-title>
                                My Documents
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <ul>
                            <li (click)="onChangeStatusTrans('Un_signed')">
                                <img src="../../../assets/img/icons/all_icon.png">
                                <span>Unsigned({{transCountData?.unsignedNotesCount}})</span>
                            </li>
                            <li (click)="onChangeStatusTrans('Signed')">
                                <img src="../../../assets/img/icons/all_icon.png">
                                <span>Signed({{transCountData?.signedNotesCount}})</span>
                            </li>
                            <li (click)="onChangeStatusTrans('Co-Signed')">
                                <img src="../../../assets/img/icons/all_icon.png">
                                <span>Co-Signed({{transCountData?.coSignedNotesCount}})</span>
                            </li>
                            <li (click)="onChangeStatusTrans('Uploaded')">
                                <img src="../../../assets/img/icons/all_icon.png">
                                <span>Uploaded</span>
                            </li>
                            <li (click)="onChangeStatusTrans('Trashed')">
                                <img src="../../../assets/img/icons/all_icon.png">
                                <span>Trashed</span>
                            </li>
                        </ul>

                    </mat-expansion-panel>
                    <mat-expansion-panel
                        [expanded]="!(company_check && _authService.currentUser.is_dictation_enabled)&&isChargeDropdownExpanded"
                        *ngIf="datas?.additional_user_role != 'cnp' && _authService.currentUser?.charge_status && _authService.currentUser.user_type !== global.USER_TYPE.SNF_WC_NURSE && _authService.currentUser.user_type !== global.USER_TYPE.WOUND_NURSE && _authService.currentUser.user_type !== global.USER_TYPE.VIEW_ONLY_USER">
                        <mat-expansion-panel-header
                            (click)="isDocumentDropdownExpanded=false;isChargeDropdownExpanded = !isChargeDropdownExpanded;isSkinWoundDropdownExpanded = false;">
                            <mat-panel-title>
                                My Charges
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <ul>
                            <li [matTooltip]="Charges" (click)="onChargesClick('draft','all')">
                                <img src="../../../assets/img/icons/all_icon.png">
                                <span>Drafted ({{chargesResponse?.draftCount}})</span>
                            </li>
                            <li [matTooltip]="Charges" (click)="onChargesClick('submit','all')">
                                <img src="../../../assets/img/icons/all_icon.png">
                                <span>Submitted</span>
                            </li>
                            <li [matTooltip]="Charges" (click)="onChargesClick('billed','all')">
                                <img src="../../../assets/img/icons/all_icon.png">
                                <span>Billed</span>
                            </li>
                            <li [matTooltip]="Charges" (click)="onChargesClick('billed','all','addendum_req')">
                                <img src="../../../assets/img/icons/all_icon.png">
                                <span>Addendum REQ ({{addendumReqCount ? addendumReqCount : 0}})</span>
                            </li>
                            <li [matTooltip]="Charges" (click)="onChargesClick('submit','upload')">
                                <img src="../../../assets/img/icons/all_icon.png">
                                <span>Missing Facesheets ({{missingFaceSheetsCount}})</span>
                            </li>
                        </ul>

                    </mat-expansion-panel>
                    <mat-expansion-panel [expanded]="isMipsDropdownExpanded" *ngIf="datas.user_type==global.USER_TYPE.DOCTOR && currentCompany.enable_company_mips">
                        <mat-expansion-panel-header (click)="isMipsDropdownExpanded = !isMipsDropdownExpanded;">
                            <mat-panel-title>
                                My MIPS
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <ul>

                            <li *ngFor="let obj of quarterlyMonths; let index=index" [value]="index"
                                (click)="onMipsClick(index)">
                                <span>Q{{index + 1}} Results</span>
                            </li>
                        </ul>

                    </mat-expansion-panel>
                    <mat-expansion-panel [expanded]="false"
                        *ngIf="(datas.user_type==global.USER_TYPE.DOCTOR || datas.user_type==global.USER_TYPE.SNF_WC_NURSE || datas.user_type==global.USER_TYPE.WOUND_NURSE || datas.user_type==global.USER_TYPE.VIEW_ONLY_USER || datas.user_type === global.USER_TYPE.BUSINESS_OFFICER) && datas.company_type !== global.COMPANY_TYPE.NURSING">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Wound Reports
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul>
                            <li *ngFor="let report of reportsNames" [ngClass]="{'active': true}"
                                matTooltipPosition="right" (click)="onReportClick(report.url)">
                                <img src="../../../assets/img/icons/all_icon.png">
                                <span class="reportTitle">{{report.title}}</span>
                            </li>
                        </ul>
                    </mat-expansion-panel>
                </div>


                <div *ngIf="datas.user_type === '2' || datas.user_type === 'medical_assistant'">

                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Dictations
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <ul>
                            <li [ngClass]="{'active': status === 'All_Dictations'}"
                                (click)="router.navigate(['/dashboard', { category: 'dict', status: 'All_Dictations' }]); category='dict'"
                                [matTooltip]="leftColClose ? 'All Dictations' : null" matTooltipPosition="right">
                                <img src="../../../assets/img/icons/all_icon.png">
                                <span>All Dictations</span>
                            </li>
                        </ul>

                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Documents
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <ul>
                            <li [ngClass]="{'active': status === 'Un_Signed'}"
                                (click)="router.navigate(['/dashboard', { category: 'tran', status: 'Un_Signed' }]); category='tran'"
                                [matTooltip]="leftColClose ? 'Uploaded notes' : null" matTooltipPosition="right">
                                <img src="../../../assets/img/icons/all_icon.png">
                                <span>All Documents</span>
                            </li>
                        </ul>
                    </mat-expansion-panel>
                </div>
                <div style="position:absolute; min-width: 94%; margin-top: auto;bottom: 75px;"
                    *ngIf="datas.user_type != global.USER_TYPE.VIEW_ONLY_USER">
                    <div style="display: flex; background: #2d3e5b;color: white ;justify-content: center; align-items: center; cursor: pointer; border-radius: 4px; height: 40px; font-size: 15px;margin-top: 78%;"
                        (click)="addNewTicket()">
                        Submit a Ticket
                    </div>
                    <div
                        style="display: flex; background: #2d3e5b;color: white ;justify-content: center; align-items: center; cursor: pointer; border-radius: 4px; height: 40px; font-size: 15px;margin-top: 5%;">
                        <a style="color: #ffff;" routerLink="/app-release">Release Update </a>
                    </div>

                </div>
            </div>

        </div>

        <div class="right-col container flex-grow" id="bar"
            [ngClass]="{'left-col-close': leftColClose === true, 'col-close-width': leftColWidth === true}"
            *ngIf="category === 'dict' && !showChargesContainer && !showWoundContainer && !showReportsContainer  && !showMipsContainer">

            <div class="sidebar-content" style="flex-direction:column">

                <div class="right-col-heading dark-blue-heading">

                    <div class="flex flex-grow"> Dictations</div>

                    <div class="flex justify-center items-center">
                        <input matInput *ngIf="update" type="text" #dictDateFilter id="dateFilter" ngxDaterangepickerMd
                            [(ngModel)]="dateFilter" matTooltip="Date of submission" matTooltipPosition="above"
                            class="mat-like-input" [ranges]="preDateRanges" [alwaysShowCalendars]="true"
                            [linkedCalendars]="true" [showClearButton]="false"
                            (datesUpdated)="applyDateFilterDict($event)" style="cursor: pointer;
                                            width: 225px;
                                            height: 44px;
                                            font-size: 16px !important;" />
                    </div>


                    <div class="flex flex-grow gap-2" style="place-content: flex-end;">
                        <div style="font-size: 16px; cursor: pointer;" (click)="refreshDashboard()">
                            Refresh
                        </div>
                        <i class="icon icon-refresh-white" matTooltip="Refresh" (click)="refreshDashboard()"></i>
                        <div style="font-size: 16px; cursor: pointer;" (click)="countFilter = !countFilter"
                            [matMenuTriggerFor]="belowMenu">
                            Filter
                        </div>
                        <span mat-button [matMenuTriggerFor]="belowMenu" matTooltip="Filter"
                            style="margin-left: 10px !important;cursor:pointer;" (click)="countFilter = !countFilter"
                            #matManuTrigger>
                            <img src="../../../assets/img/filter.png" style="width: 20px; opacity: .8;" />
                        </span>

                        <mat-menu (closed)="countFilter = !countFilter" id="matManuFocus" #belowMenu="matMenu"
                            yPosition="below" class="filtercount">
                            <div *ngIf="countFilter">
                                <app-count-filter style="width: 70vw;" [dateFilter]="dateFilter" [dictations]="true"
                                    (onApplyFilter)="applyCounterFilter($event)">
                                </app-count-filter>
                            </div>
                        </mat-menu>

                    </div>

                </div>


                <div class="search-block theme-form flex gap-2 justify-between items-stretch" style="background-color: white; border-radius: 4px;
                     padding-top: 8px;
                     padding-bottom: 5px;
                     padding-left: 8px;padding-right: 8px;">


                    <div style="width: 100%">
                        <div class="flex justify-start items-center gap-4">
                            <mat-form-field class="flex-grow" style="color: black !important;"
                                *ngIf="(datas.user_type == global.USER_TYPE.TRANSCRIBER  && category !== 'patient') || (datas.user_type == global.USER_TYPE.MEDICAL_ASSISTANT  && category !== 'patient')">
                                <mat-label>Provider</mat-label>
                                <input matInput type="text" [(ngModel)]="dictFilters.provider" id="inputIDProvider"
                                    [formControl]="providerControlDict" [matAutocomplete]="providerAutoComplete"
                                    style="cursor: pointer;" class="font-styles">
                                <mat-icon matSuffix style="cursor: pointer;">keyboard_arrow_down</mat-icon>
                                <mat-autocomplete [displayWith]="displayProviderFn"
                                    (optionSelected)="refreshFacilities($event, 'dict')"
                                    #providerAutoComplete="matAutocomplete">
                                    <mat-option *ngFor="let provider of fData.providers.dict" [value]="provider">
                                        {{provider.name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <mat-form-field style="color: black !important;">
                                <mat-label>Select Facility</mat-label>
                                <input matInput type="text" [(ngModel)]="dictFilters.facility" id="inputIDFac"
                                    (keydown)="filterKeydownEvent=$event" [formControl]="facilityControlDict"
                                    [matAutocomplete]="facilityAutoComplete" style="cursor: pointer;" class="font-styles">
                                <mat-icon matSuffix style="cursor: pointer;">keyboard_arrow_down</mat-icon>
                                <mat-autocomplete [displayWith]="displayFacilityFn"
                                    (optionSelected)="applyDictationFilters()" #facilityAutoComplete="matAutocomplete">

                                    <mat-option *ngFor="let facility of fData.facilities.dict" [value]="facility">
                                        <app-facility-title [facility]="facility"></app-facility-title>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>


                            <mat-form-field style="color: black !important;">
                                <mat-label>Status</mat-label>
                                <mat-select [(ngModel)]="dictFilters.progress_status" name="status">
                                    <mat-option *ngFor="let status of statusOption" [value]="status.code"
                                        (click)="onChangeStatus(status);">
                                        {{status.status}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div
                                *ngIf="(datas.user_type == global.USER_TYPE.TRANSCRIBER  && category !== 'patient' && datas.other_settings.show_tat == true) || (datas.user_type == global.USER_TYPE.MEDICAL_ASSISTANT  && category !== 'patient' && datas.other_settings.show_tat == true)">
                                <mat-checkbox class="tp-margin" [checked]="surpassedTAT" [(ngModel)]="surpassedTAT"
                                    labelPosition="before" style="color: black !important;" [disabled]="disabled"
                                    (change)="filterSurpassedTAT($event.checked)">
                                    Surpass TAT
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>



                    <div class="multiDownloadDictations flex justify-start items-stretch gap-2"
                        *ngIf="multiSelectedDictations.length > 0 && isMultiDownloadDictationsOn">
                        <button matTooltipPosition="above" mat-raised-button class="dn-button-primary"
                            [matMenuTriggerFor]="multiDownloadAudioMenu"
                            *ngIf="multiSelectedDictations.length > 0 && isMultiDownloadDictationsOn && !multiDownloadDictationsInprogress">
                            Download ({{multiSelectedDictations.length}})
                        </button>
                        <mat-menu #multiDownloadAudioMenu="matMenu">
                            <button mat-menu-item (click)="startMultiDownloadDictations('mp4')">MP4</button>
                            <button mat-menu-item (click)="startMultiDownloadDictations('mp3')">MP3</button>
                        </mat-menu>

                        <div class="mat-progress-spinner-progress" style="margin:auto"
                            *ngIf="multiDownloadDictationsInprogress">
                            <mat-progress-spinner diameter="40" *ngIf="downloadProgress.show"
                                [mode]="downloadProgress.mode" [value]="downloadProgress.progress">
                            </mat-progress-spinner>
                            <span style="color:#FFF">
                                {{this.downloadProgress.progress}}%
                            </span>
                        </div>
                    </div>
                    <div class="segmented-control" style="width: 275px; height:40px; color: #FFF"
                        *ngIf="filterData && filterData.filter === 'progress_status' && multiSelectedDictations.length > 0">
                        <input type="radio" name="sc-2-1" id="sc-2-1-1" [checked]="filterData.payload == '19'">
                        <input type="radio" name="sc-2-1" id="sc-2-1-2" [checked]="filterData.payload == '20'">
                        <label for="sc-2-1-1" data-value="In Progress"
                            (click)="filterData.payload != '19' && multiSelectChangeDictationStatus(19)">In
                            Progress</label>
                        <label for="sc-2-1-2" data-value="Completed"
                            (click)="filterData.payload != '20' && multiSelectChangeDictationStatus(20)">Completed</label>
                    </div>
                </div>
                <div class="flex justify-between items-center">
                </div>


                <div style="height: 5px;"></div>
                <div class="white-box flex flex-grow gap-2" style="margin-bottom: 5px;height: 52px;">
                    <div style="width: 58%;text-align: start; display: flex;align-items: center;">
                        <span style="width: 175px;">
                            In Progress: {{dictCountData?.inProgressDictations}}
                        </span>
                        <span style="width: 175px;">
                            Completed: {{dictCountData?.completedDictations}}
                        </span>

                        <mat-form-field style="color: black !important; margin-left: 100px; width: 29%;height: 4rem; margin-top: 1rem; box-sizing: border-box; ">
                            <mat-select [ngModel]='dictGroup.group' [value]="transGroup.group" name="group">
                                <mat-option *ngFor="let type of dictGrouping" [value]="type.group"
                                    (click)="groupRecordingsBy(type.value);">
                                    {{type.group}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div
                        style="width: 43%;display: flex; flex-direction: row-reverse; text-align: start;align-items: center;">
                        <span style="width: 109px;">Note Status</span>
                        <span style="width: 197px;">DOS</span>
                        <span style="width: 145px;">Patient</span>
                    </div>
                </div>

                <div>
                    <app-audio-list *ngFor="let item of groupedSortedRecordings | keyvalue: _commonService.keyAscOrder"
                        [audios]="item.value" [group_index]="item.key" [surpassedTAT]="surpassedTAT"
                        [multiSelectedDictations]="multiSelectedDictations"
                        [isMultiDownloadDictationsOn]="isMultiDownloadDictationsOn"
                        (initDictationsAndTranscriptions)="initDictationsAndTranscriptions($event)"
                        (toggleDictationMultiDownloads)="toggleDictationMultiDownloads($event)">
                    </app-audio-list>

                </div>

                <div class="flex justify-end items-center">
                    <div *ngIf="sortedRecordings" class="flex-grow">
                        <div class="white-box" *ngIf="!dictationsLoaded">Refreshing List...</div>
                        <div class="white-box" *ngIf="sortedRecordings.length === 0 && dictationsLoaded">No items to
                            view
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="right-col container flex-grow"
            *ngIf="category === 'tran' && !showChargesContainer && !showWoundContainer && !showReportsContainer  && !showMipsContainer"
            id="bar" [ngClass]="{'left-col-close': leftColClose === true, 'col-close-width': leftColWidth === true} ">

            <div class="sidebar-content" style="flex-direction:column">

                <h3 class="right-col-heading dark-blue-heading">
                    <div class="flex flex-grow"> Documents </div>

                    <div class="flex justify-center items-center">
                        <input matInput type="text" ngxDaterangepickerMd [(ngModel)]="noteDateFilter" *ngIf="update"
                            matTooltip="Date of submission" matTooltipPosition="above" class="mat-like-input"
                            [ranges]="preDateRanges" [alwaysShowCalendars]="true" [linkedCalendars]="true"
                            [showClearButton]="false" (datesUpdated)="applyDateFilterTrans($event)" style="cursor: pointer;
                                    width: 225px;
                                    height: 35px;
                                    font-size: 16px !important;" />
                    </div>

                    <div class="flex flex-grow gap-2" style="place-content: flex-end;">


                        <div style="font-size: 16px; cursor: pointer;" (click)="refreshDashboard()">
                            Refresh
                        </div>
                        <i class="icon icon-refresh-white" matTooltip="Refresh" (click)="refreshDashboard()"></i>
                        <div style="font-size: 16px; cursor: pointer;" (click)="countFilter = !countFilter;"
                            [matMenuTriggerFor]="belowMenu">
                            Filter
                        </div>
                        <span mat-button [matMenuTriggerFor]="belowMenu" matTooltip="Filter"
                            style="margin-left: 10px !important; cursor:pointer;" (click)="countFilter = !countFilter; "
                            #matManuTrigger>
                            <img src="../../../assets/img/filter.png" style="width: 20px; opacity: .8;" />
                        </span>
                        <mat-menu (closed)="countFilter = !countFilter" id="matManuFocus" #belowMenu="matMenu"
                            yPosition="below" class="filtercount">
                            <div *ngIf="countFilter">
                                <app-count-filter style="width: 70vw;" [noteDateFilter]="noteDateFilter"
                                    [dictations]="false" (onApplyFilterTrans)="applyCounterFilterTrans($event)">

                                </app-count-filter>
                            </div>
                        </mat-menu>

                    </div>
                </h3>


                <div class="search-block theme-form flex gap-2 justify-start items-center"
                    style="background-color: white; border-radius: 4px; padding-top: 8px; padding-bottom: 5px; padding-left: 8px;padding-right: 8px;">
                    <div style="width: 100%">
                        <div class="flex justify-start items-center gap-4">
                            <mat-form-field class="flex-grow" style="color: black !important;"
                                *ngIf="(datas.user_type == global.USER_TYPE.TRANSCRIBER  && category !== 'patient') || (datas.user_type == global.USER_TYPE.MEDICAL_ASSISTANT  && category !== 'patient') || (datas.user_type == global.USER_TYPE.VIEW_ONLY_USER  && category !== 'patient')">
                                <mat-label>{{ (datas.company_type === global.COMPANY_TYPE.SNF && datas.user_type ===
                                    global.USER_TYPE.VIEW_ONLY_USER) ? 'SNF WC Nurse' : 'Provider' }}</mat-label>
                                <input matInput type="text" [(ngModel)]="tranFilters.provider" id="inputIDProvider"
                                    [formControl]="providerControl" [matAutocomplete]="providerAutoComplete"
                                    style="cursor: pointer;" class="font-styles">
                                <mat-icon matSuffix style="cursor: pointer;">keyboard_arrow_down</mat-icon>
                                <mat-autocomplete [displayWith]="displayProviderFn"
                                    (optionSelected)="refreshFacilities($event , 'tran')"
                                    #providerAutoComplete="matAutocomplete">
                                    <mat-option *ngFor="let provider of fData.providers.tran" [value]="provider">
                                        {{provider.name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field class="dropdown flex-grow" style="color: black !important;"
                                *ngIf="datas.user_type == global.USER_TYPE.DOCTOR || datas.user_type == global.USER_TYPE.SNF_WC_NURSE && category !== 'patient'">
                                <mat-label
                                    *ngIf="datas.user_type !== global.USER_TYPE.SNF_WC_NURSE">Provider</mat-label>
                                <mat-label *ngIf="datas.user_type === global.USER_TYPE.SNF_WC_NURSE">SNF WC
                                    Nurse</mat-label>
                                <input matInput type="text" [(ngModel)]="tranFilters.provider" id="inputIDProvider"
                                    [formControl]="providerControl" [matAutocomplete]="providerAutoComplete"
                                    style="cursor: pointer;" class="font-styles">
                                <mat-icon matSuffix style="cursor: pointer;" (click)="showProvidersDropDown()">
                                    keyboard_arrow_down</mat-icon>
                                <mat-autocomplete [displayWith]="displayProviderFn"
                                    (optionSelected)="refreshFacilities($event , 'tran')"
                                    #providerAutoComplete="matAutocomplete">
                                    <mat-option *ngFor="let provider of filteredProviders | async" [value]="provider">
                                        {{provider.name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field class="dropdown" style="color: black !important;">
                                <mat-label>Select Facility</mat-label>
                                <div style="display: flex;">
                                    <img *ngIf="tranFilters.facility.source === 'PointClickCare' && inputvalue.value!='' || tranFilters.facility.pcc_facId && inputvalue.value!=''"
                                        src="../../../assets/icons/pcc-icon.svg" alt="" width="20px" height="20px">
                                    <input matInput #inputvalue onfocus="this.value ='';placeholder='Search'"
                                        type="text" [(ngModel)]="tranFilters.facility"
                                        (keydown)="filterKeydownEvent=$event" id='inputIDFac'
                                        [formControl]="facilityControl" [matAutocomplete]="facilityAutoComplete"
                                        style="cursor: pointer;" class="font-styles">
                                </div>
                                <mat-icon matSuffix style="cursor: pointer;" (click)="showDropdown()">
                                    keyboard_arrow_down </mat-icon>

                                <mat-autocomplete [displayWith]="displayFacilityFn"
                                    (optionSelected)="applyNoteFilters();" #facilityAutoComplete="matAutocomplete">
                                    <mat-option *ngIf="showAllFacilities" (click)="inputvalue.value='All'"
                                        value='All'>All</mat-option>

                                    <mat-option *ngFor="let facility of filteredFacilities | async" [value]="facility"
                                        (click)="getPatientByFacility(facility._id);inputvalue.value=tranFilters.facility.title">
                                        <app-facility-title [facility]="facility"></app-facility-title>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <mat-form-field class="flex-grow" style="color: black !important;"
                                *ngIf="category !== 'patient'&& tranFilters.facility?._id">
                                <mat-label>Patient</mat-label>
                                <input matInput type="text" [(ngModel)]="tranFilters.patient" id="inputIDPatient"
                                    [formControl]="patientControl" [matAutocomplete]="patientAutoComplete"
                                    style="cursor: pointer;" class="font-styles">
                                <mat-icon matSuffix style="cursor: pointer;" (click)="showPatientsDropDown()">
                                    keyboard_arrow_down</mat-icon>
                                <mat-autocomplete [displayWith]="displayPatientFn"
                                    (optionSelected)="applyNoteFiltersByPatient($event);"
                                    #patientAutoComplete="matAutocomplete">
                                    <mat-option *ngFor="let patient of filteredOptions  | async" [value]="patient">
                                        {{patient.name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <mat-form-field class="short-dropdown" style="color: black !important;">
                                <mat-label>Signed Status</mat-label>
                                <mat-select [(ngModel)]="tranFilters.progress_status" name="status">
                                    <mat-option *ngFor="let status of statusOptionTrans" [value]="status"
                                        (click)="onChangeStatusTrans(status);">
                                        {{status}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div>
                        <ng-container *ngIf="category === 'tran'">
                            <div class="flex gap-2">
                                <button mat-raised-button class="dn-button-primary" style="width: 100px;"
                                    *ngIf="category === 'tran' && multiSelectedTranscriptions.length > 0 && (tranFilters.progress_status == 'Signed' || tranFilters.progress_status == 'Uploaded') "
                                    [matMenuTriggerFor]="downloadNoteBtn">
                                    Export Notes
                                </button>
                                <mat-menu #downloadNoteBtn="matMenu">
                                    <button mat-menu-item (click)="exportNote('download', row)">Download</button>
                                    <button mat-menu-item (click)="exportNote('send_to_email', row)">Send to
                                        Email</button>
                                </mat-menu>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="flex flex-grow gap-5" style="justify-content: flex-end;margin-top: 5px"
                    *ngIf="category === 'tran' && multiSelectedTranscriptions.length > 0 ">
                    <button *ngIf="showSignButton()" mat-raised-button class="dn-button-primary"
                        (click)="signMultipleNotes()">
                        <ng-container *ngIf="tranFilters.facility?.source === 'PointClickCare'">
                            Sign Note(s)
                        </ng-container>
                        <ng-container *ngIf="tranFilters.facility?.source  !== 'PointClickCare'">
                            Sign & Upload Note(s)
                        </ng-container>
                    </button>
                    <button
                        *ngIf="tranFilters.progress_status == 'Unsigned' && datas.user_type != global.USER_TYPE.VIEW_ONLY_USER"
                        mat-raised-button class="dn-button-primary" (click)="deleteMultipleUnsignedNotes()">
                        Delete Note(s)
                    </button>
                    <button *ngIf="tranFilters.progress_status == 'Trashed'" mat-raised-button
                     class="dn-button-primary" (click)="deleteMultipleTrashedNotes()">
                     Delete Notes
                    </button>
                    <button *ngIf="showUploadButton()" mat-raised-button class="dn-button-primary"
                        (click)="uploadMultipleNotes()">
                        Upload Note(s)
                    </button>
                </div>
                <div style="height: 5px;"></div>
                <div class="white-box flex flex-grow gap-2" style="margin-bottom: 5px;height: 52px;">
                    <div style="width: 58%;text-align: start; display: flex;align-items: center;">
                        <span style="width: 17%;">
                            Unsigned: {{transCountData?.unsignedNotesCount}}
                        </span>
                        <span style="width: 19%;">
                            Co-Signed: {{transCountData?.coSignedNotesCount}}
                        </span>
                        <span style="width: 15%;">
                            Signed: {{transCountData?.signedNotesCount}}
                        </span>
                        <span style="width: 17%;">
                            Uploaded: {{transCountData?.uploadedNotesCount}}
                        </span>

                        <mat-form-field *ngIf="!showAllFacilities"
                            style="color: black !important; margin-left: 20px; height: 4rem; margin-top: 1rem; box-sizing: border-box;">
                            <mat-select [ngModel]='transGroup.group' [value]="transGroup.group" name="group">
                                <mat-option *ngFor="let type of transGrouping" [value]="type.group"
                                    (click)="groupTranscriptionsBy(type.value);">
                                    {{type.group}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field *ngIf="showAllFacilities"
                            style="color: black !important; margin-left: 20px; height: 4rem; margin-top: 1rem; box-sizing: border-box;">
                            <mat-select [ngModel]='transGroup.group' [value]="transGroup.group" name="group">
                                <mat-option *ngFor="let type of transGroupingAllFacilities" [value]="type.group"
                                    (click)="groupTranscriptionsBy(type.value);">
                                    {{type.group}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div
                        style="width: 54%;display: flex; flex-direction: row-reverse; text-align: start;align-items: center;">
                        <span style="width: 120px;">Note Status</span>
                        <span style="width: 166px;">DOS</span>
                        <span style="width: 197px;" *ngIf="shouldShowEncounter">Encounter Type</span>
                        <span style="width: 156px;">Patient</span>
                    </div>
                </div>
                <div style="margin-top: 5px;" class="white-box" *ngIf="isNotesLoading()">Refreshing List...</div>

                <ng-container style="margin-top: 5px;"
                    *ngFor="let item of groupedSortedTranscriptions | keyvalue: _commonService.keyAscOrder">
                    <app-transcription-list [selectedDoctor]="filters.provider" [transcriptions]="item.value"
                        [excludeInfo]="['provider']" [group_index]="item.key"
                        (initDictationsAndTranscriptions)="applyCounterFilterTrans();"
                        [transcriptionFiles]="sortedTransriptions"
                        (toggleTranscriptionMultiSelect)="toggleTranscriptionMultiSelect($event)"
                        (updateTranscriptionsArray)="updateTranscriptionsArray($event);"
                        (resetTranscriptionMultiSelect)="resetTranscriptionMultiSelect()"
                        [multiSelectedTranscriptions]="multiSelectedTranscriptions"
                        [isVisibleSelectAll]="(datas.user_type == global.USER_TYPE.DOCTOR || datas.user_type == global.USER_TYPE.SNF_WC_NURSE || datas.user_type == global.USER_TYPE.WOUND_NURSE) && status !== 'Completed' && sortedTransriptions?.length"
                        [noteErrors]="noteErrors" [tranFilters]="tranFilters">
                    </app-transcription-list>
                </ng-container>


                <div style="margin-top: 5px;" class="flex justify-end items-center">
                    <div *ngIf="sortedTransriptions" class="flex-grow">
                        <div class="white-box"
                            *ngIf="!isNotesLoading() && sortedTransriptions.length === 0 transcriptionsLoaded">No items
                            to view
                        </div>
                    </div>
                </div>


            </div>
        </div>

        <div class="right-col flex-grow" *ngIf="showChargesContainer" style="min-height: 100%;
        box-sizing: border-box;
        background: #44597d;
        padding: 0.5rem;
        padding-top: 0px !important;">
            <app-biller-dashboard [chargeStatus]="chargeStatus" [faceSheetStatus]="faceSheetStatus"
                [billStatus]="billStatus" [providerFromCensus]="providerFromCensus"></app-biller-dashboard>
        </div>

        <div class="right-col flex-grow" *ngIf="showWoundContainer" style="min-height: 100%;
        width: 80%;
        box-sizing: border-box;
        background: #44597d;
        padding: 0.5rem;
        padding-top: 0px !important;">
            <app-qapi-report [reportFilter]="qapiReportFilter" (countChanges)="handleCountChanges($event)"
                [selectedFacility]="tranFilters.facility"></app-qapi-report>
        </div>

        <div class="right-col flex-grow" style="width: 80%;" *ngIf="showReportsContainer" id="bar"
            [ngClass]="{'left-col-close': leftColClose === true, 'col-close-width': leftColWidth === true}">

            <div class="sidebar-content" style="flex-direction:column">
                <app-post-rounds-report *ngIf="reportView=='post-rounds-report'"></app-post-rounds-report>
                <app-telehealth-wound-assessment-report
                    *ngIf="reportView=='telehealth-wound-assessment-report'"></app-telehealth-wound-assessment-report>
                <app-hp-operational-report *ngIf="reportView=='hp-operational-report'"></app-hp-operational-report>
                <app-dns-report *ngIf="reportView=='dns-report'"></app-dns-report>
                <app-qapi-report *ngIf="reportView=='qapi-report'"></app-qapi-report>
                <app-wound-periodic-treatment-report
                    *ngIf="reportView=='weeks-in-treatment-report'"></app-wound-periodic-treatment-report>
                <app-wound-healing-rate-report
                    *ngIf="reportView=='healing-success-rate-report'"></app-wound-healing-rate-report>
                <app-uwh-type-tabular-report
                    *ngIf="reportView=='uwh-type-tabular-report'"></app-uwh-type-tabular-report>
                <app-patient-discharge-report
                    *ngIf="reportView=='discharged-patient-report'"></app-patient-discharge-report>
                <app-dns-healing-report *ngIf="reportView=='dns-healing-report'"></app-dns-healing-report>
                <app-visit-report *ngIf="reportView=='visit-report'"></app-visit-report>
                <app-kent-visit-report *ngIf="reportView=='kent-visit-report'"></app-kent-visit-report>
                <app-missed-facility-report *ngIf="reportView=='missed-facility-report'"></app-missed-facility-report>
                <app-debridement-report *ngIf="reportView=='debridement-report'"></app-debridement-report>
                <app-tissue-analytic-report
                    *ngIf="reportView=='dns-tissue-analytic-report'"></app-tissue-analytic-report>
                <app-admin-charges-report *ngIf="reportView=='admin-charges-report'"></app-admin-charges-report>
                <app-los-report *ngIf="reportView=='los-report'"></app-los-report>
                <app-ipa-report *ngIf="reportView=='ipa-report'"></app-ipa-report>
                <app-pressure-wound-report *ngIf="reportView=='pressure-wound-report'"></app-pressure-wound-report>
                <app-custom-wound-report *ngIf="reportView=='custom-wound-report'"></app-custom-wound-report>
                <app-wound-exceptions-report *ngIf="reportView=='wound-exceptions-report'"></app-wound-exceptions-report>
                <app-days-to-remission-report *ngIf="reportView=='days-to-remission-report'"></app-days-to-remission-report>
                <app-wound-outcome-report *ngIf="reportView=='wound-outcome-report'"></app-wound-outcome-report>

            </div>


        </div>
        
        <div class="right-col flex-grow" style="width: 80%;" *ngIf="showMipsContainer" id="mips"
        [ngClass]="{'left-col-close': leftColClose === true, 'col-close-width': leftColWidth === true}">
            <app-my-mips [prevMipsValue]="prevMipsValue"></app-my-mips>
        </div>

    </div>
</main>