<div class="mips-questions-wrap">
    <div class="mips-add-button" *ngIf="currentUser.product_owner || currentUser.admin_type == 'system_admin' || currentUser.admin_type == 'full_admin'">
        <button *ngIf="currentUser.product_owner || currentUser.admin_type == 'system_admin'" mat-raised-button class="dn-button-primary" (click)="handleAddQuestions()" matTooltip="Add Question">
            +
        </button>
    </div>

    <div class="mips-questions-data">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="measure_number">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                    Measure Number
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.measure_number}}
                </td>
            </ng-container>
            <ng-container matColumnDef="measure_title">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                    Measure Name
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element?.mips?.measure_title}}
                </td>
            </ng-container>
            <ng-container matColumnDef="questions">
                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                    Questions
                </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-button (click)="showMeasureNumberQuestionsDialog(element)">
                        {{ element.questions_count }}
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
    
</div>
