import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { WoundService } from 'src/app/services/wound.service';
import { PatientRoundingSheetService } from '../../patient-rounding-sheets/patient-rounding-sheet.service';
import { PhraseTemplateEditorComponent } from './phrase-template-editor/phrase-template-editor.component';

@Component({
  selector: 'app-phrase-templates',
  templateUrl: './phrase-templates.component.html',
  styleUrls: ['./phrase-templates.component.css']
})
export class PhraseTemplatesComponent implements OnInit {
  @Input() company_id:string;
  loaderId = 'app-phrase-templates';
  phraseTemplates:any;

  displayedColumns: string[] = ['phrase_key', 'template'];
  dataSource = new MatTableDataSource();
  currentUser: any;

  constructor(
    private dialog: MatDialog,
    private loader: NgxUiLoaderService,
    private _authService: AuthService,
    private _woundService: WoundService
  ) {
    this.currentUser = this._authService.currentUser;
    if (this.currentUser.admin_type == 'system_admin') {
      this.displayedColumns.push('operation');
    }
  }

  async ngOnInit(): Promise<void> {
    this.loader.startLoader(this.loaderId);
    await this.initPhraseTemplates();
    this.dataSource.data = this.phraseTemplates;

    this.loader.stopLoader(this.loaderId);
  }

  async initPhraseTemplates() {
    const {status, data} = await lastValueFrom(await this._woundService.getSystemDefinedPhraseTemplates(this.company_id)) as any;
    if (status === 200) {
      this.phraseTemplates = data.array;
    }

  }

  
  convertHtmltotext(string){
    let strippedHtml = string == undefined ? '' : string?.replace(/<[^>]+>/g, '');
    return strippedHtml.length < 102 ? strippedHtml : strippedHtml.substr(0,102) + '...';
  }

  showEditorDialog(element, index) {
    var data = element;
    this.openPhraseEditor(data, index);
  }
  openPhraseEditor(data, index) {
    const dialogRef = this.dialog.open(PhraseTemplateEditorComponent, {
      width: '800px',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        for (const item of this.dataSource.data as any[]) {
          if(item.phrase_key === result.phrase_key) {
            item.template = result.template;
            break;
          }
        }
      }
    });
  }
}
