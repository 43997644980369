import { Component, Inject } from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { AudioListDialogService } from "./audio-list-dialog.service";
import * as global from '../../../includes/global'
import { AuthService } from "src/app/services/auth.service";
// CREATE NEW / RENAME FOLDER DIALOG
export interface AudioListDialogData {
    // action:String // create, rename 
    patient:any,
    census:any
  }
  @Component({
    selector: 'audio-list-dialog',
    templateUrl: './audio-list-dialog.component.html',
    styleUrls: ['./audio-list-dialog.css']
  })
  export class AudioListDialog {
    global=global;
    patient:any;
    census:any;
    audios = [];
    constructor(
      public dialogRef: MatDialogRef<AudioListDialog>,
      @Inject(MAT_DIALOG_DATA) public data: AudioListDialogData,
      private audioListDialogService:AudioListDialogService,
      private authService:AuthService,
      private toastr:ToastrService) {
        if(data && data.patient) {
          this.patient = data.patient;
          this.census = data.census;
          
          this.initAudios();
        }      
      }

      initAudios() {
        this.audioListDialogService.getAudiosByPatientId(this.patient._id).subscribe((response:any) => {
          this.audios = response.data.array;
        })
      }

      isOwner(audio) {
        this.authService.currentUser._id === audio?.uploaded_by_id_ref?._id
      }
  }
