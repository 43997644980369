<div class="flex">
    <app-input-with-dropdown class="flex-grow" [noDropdown]="true" placeholder="Search Nurse" (inputChange)="applyFilter($event)">
    </app-input-with-dropdown>
</div>
<div style="height: 10px;"></div>
<div style="height: 70vh;overflow: auto;">
    <table mat-table [dataSource]="dataSource" matSort>
    
        <ng-container matColumnDef="nurses">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Wound Nurses </th>
            <td mat-cell *matCellDef="let element" style="width: 20%">
                {{element.first_name}} {{element.last_name}}
                <div style="font-size: 11px;">{{element.email? element.email : ''}}</div>
            </td>
        </ng-container>
    
        <ng-container matColumnDef="providers">
            <th mat-header-cell *matHeaderCellDef> Associated Providers </th>
            <td mat-cell *matCellDef="let element ; let i = index;" style="width: 60%">
                <app-mat-multi-select-chips [element]="element" [ngModelData]="element.associated_provider_ids || []" [isMulti]="true" [isProvider]="true" [allData]="providers" [selectAll]="true" [providerKey]="'associated_provider_ids'" (onDataSelection)="onDataSelection($event)" (onDataRemove)="onDataRemove($event)"></app-mat-multi-select-chips>
            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
    </table>
</div>
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

