import { Component, OnInit, Input, Inject } from '@angular/core';
import { ReportGenerateService } from '../report-generate.service';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-popup-report-view',
  templateUrl: './popup-report-view.component.html',
  styleUrls: ['./popup-report-view.component.css']
})
export class PopupReportViewComponent implements OnInit {
  @Input() summary;
  document: any;
  report:any = []; 
  reportView:Boolean = false;
  constructor(
    public dialogRef: MatDialogRef<PopupReportViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private reportGenerateService: ReportGenerateService,
  ) {  
    // this.document = JSON.parse(this.data.element.json) 
    this.document = this.data.element.json; 
  }
  
  ngOnInit() {   
    this.generateReport(); 
   }

  generateReport(){
    const details = {
      document_id : this.data.element._id, 
      // claiment_id: this.document.cover_detail.claim_id,
      // claiment_name: this.document.cover_detail.person,
      // claiment_doi: this.document.cover_detail.date_of_incident,
      // claiment_dob:this.document.cover_detail.birthdate, 
    }   
    this.reportGenerateService.generateReport(details).subscribe((response:any )=>{
      if(response.status === 200) {      
        // this.edit = false; 
        // this.table = false;
        this.report = response.data;
        this.report.cover = response.data.cover;
        this.reportView = true;
      }
    })

  }
}
