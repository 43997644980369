import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-operations-summary-report-v2',
  templateUrl: './operations-summary-report-v2.component.html',
  styleUrls: ['./operations-summary-report-v2.component.css']
})
export class OperationsSummaryReportV2Component implements OnInit {
  @Input() operationsSummaryReport = [];
  @Input() facilityTitle = "";
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = [
    // 'faciltiy', 'company', 
    'totalReferrals', 'totalWounds', 'residentsWithWounds', 'facilityAcquired',
    'facilityAcquiredPI/PU'];

  constructor() { }

  ngOnInit(): void {
    this.dataSource.data = this.operationsSummaryReport;
  }
}
