    <table mat-table [dataSource]="dataSource" matSort>
    
        <ng-container matColumnDef="adlScore">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                adlScore
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.adlScore}}
            </td>
        </ng-container>
    
        <ng-container matColumnDef="adlScoreDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                adlScoreDate
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.adlScoreDate}}
            </td>
        </ng-container>
    
        <ng-container matColumnDef="modifiedBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                modifiedBy
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.modifiedBy}}
            </td>
        </ng-container>
    
        <ng-container matColumnDef="modifiedDateTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                modifiedDateTime
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.modifiedDateTime}}
            </td>
        </ng-container>
        
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <br />
    <div *ngIf="apiData" class="flex justify-start items-center gap-2">
        <button mat-raised-button class="dn-primary-button" [disabled]="apiData.paging.page === 1"
            (click)="navigate(-1)">Prev</button>
        <span style="font-size: 12px;">Page {{apiData.paging.page}}</span>
        <button mat-raised-button class="dn-primary-button" [disabled]="!apiData.paging.hasMore"
            (click)="navigate(1)">Next</button>
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
