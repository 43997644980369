import { Injectable } from '@angular/core';
import { RRule, RRuleSet } from "rrule";
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AppointmentUtilityService {

  constructor() { }
  generateRecurringRule(selectedRepeating, event): any {
    // Generate recurring rule based on the selected option
    switch (selectedRepeating) {
      case "Does not Repeat":
       return this.generateRecurringEvent(event, RRule.DAILY, 1)
      case "Daily":
        this.generateRecurringEvent(event, RRule.DAILY, 1)
      case "Weekly":
        return  this.generateRecurringEvent(
          event,
          RRule.WEEKLY,
          1,
          event.selectedWeekdays
        )
      case "Biweekly":
        return this.generateRecurringEvent(
          event,
          RRule.WEEKLY,
          2,
          event.selectedWeekdays
        )
      case "Monthly by Day":
        return  this.generateMonthlyByDayEvent(event);
      case "Monthly by Date":
        return  this.generateRecurringEvent(
          event,
          RRule.MONTHLY,
          1,
          undefined,
          event.selectedDays
        )
      default:
        return  this.generateRecurringEvent(event, RRule.DAILY, 1)
    }
  }
  generateRecurringEvent(
    event,
    freq,
    interval,
    byweekday?,
    bymonthday?,
  ): any {
    // Generate recurring event
    const dtstart = this.convertToDate(
      event.start_date_obj,
      event.start_time
    );
    const until = this.convertToDate(event.end_date_obj, event.end_time);
    const rruleSet = new RRuleSet();
    rruleSet.rrule(
      new RRule({
        freq,
        interval,
        dtstart, // Adjust as needed
        until, // Adjust as needed
        ...(byweekday && { byweekday }),
        ...(bymonthday && { bymonthday }),
        ...(freq === RRule.DAILY && { count: 1 })
      })
    );

    if (event.exDate && Array.isArray(event.exDate)) {
      for (const exDate of event.exDate) {
        rruleSet.exdate(
          this.convertToDate(exDate.date, event.start_time)
        );
      }
    }
    const datesArray = rruleSet.all()
    const datesObjects = datesArray.map(dateString => {
      const date = new Date(dateString);
      return {
        date: {
          day: date.getDate(),
          month: date.getMonth() + 1, // Adding 1 because getMonth() returns zero-based index
          year: date.getFullYear()
        }
      };
    });
    return { rules: rruleSet.toString(), occurrences: datesObjects}
  }
  generateMonthlyByDayEvent(event): any {
    let monthlyEventArray: any[] = [];
    for (const weekday of event.selectedWeekdays) {
      for (const weekNumber of event.selectedWeeks) {
        const rruleSet = new RRuleSet();
        rruleSet.rrule(
          new RRule({
            freq: RRule.MONTHLY,
            dtstart: this.convertToDate(
              event.start_date_obj,
              event.start_time
            ),
            until: this.convertToDate(
              event.end_date_obj,
              event.end_time
            ),
            byweekday: [weekday],
            bysetpos: [weekNumber]
          })
        );

        if (event.exDate && Array.isArray(event.exDate)) {
          for (const exDate of event.exDate) {
            rruleSet.exdate(
              this.convertToDate(exDate.date, event.start_time)
            );
          }
        }
        const datesArray = rruleSet.all()
        const datesObjects = datesArray.map(dateString => {
          const date = new Date(dateString);
          return {
            date: {
              day: date.getDate(),
              month: date.getMonth() + 1, // Adding 1 because getMonth() returns zero-based index
              year: date.getFullYear()
            }
          };
        });
        if(datesObjects && datesObjects[0]){
          monthlyEventArray.push(datesObjects[0])
        }
      }
    }
    const uniqueDates = this.removeDuplicateDates(monthlyEventArray);
    return { rules: '', occurrences: uniqueDates}
  }
  convertToDateObj(dateString) {
    const momentDate = moment.utc(dateString);

    return {
      day: momentDate.date(),
      month: momentDate.month() + 1,
      year: momentDate.year()
    };
  }
  convertToDate(dateObj, time) {
    const { day, month, year } = dateObj;
    const [hours, minutes] = time.split(":");

    return new Date(Date.UTC(year, month - 1, day, hours, minutes, 0));
  }
  removeDuplicateDates(arrayOfDates: { date: { day: number; month: number; year: number } }[]): { date: { day: number; month: number; year: number } }[] {
    return arrayOfDates.filter((value, index, self) =>
        index === self.findIndex((obj) =>
            obj.date.day === value.date.day &&
            obj.date.month === value.date.month &&
            obj.date.year === value.date.year
        )
    );
  }
}
