import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-associated-companies-dialog',
  templateUrl: './associated-companies-dialog.component.html',
  styleUrls: ['./associated-companies-dialog.component.css']
})
export class AssociatedCompaniesDialogComponent implements OnInit {

  dataSource: any;
  displayedColumns: any = ['assocCompanies'];

  constructor(@Inject(MAT_DIALOG_DATA)public data: any) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data);
  }

}
