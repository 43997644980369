import { Component, Input, OnInit } from '@angular/core';
import { NoteAuditoryHistoryService } from '../../note-auditory-history/note-auditory-history.service';

@Component({
  selector: 'app-note-status-icon',
  templateUrl: './note-status-icon.component.html',
  styleUrls: ['./note-status-icon.component.css']
})
export class NoteStatusIconComponent implements OnInit {
  
  formattedOperation:String='';
  @Input() note;
  @Input() census;
  @Input() patient;
  constructor(private noteAuditoryHistoryService:NoteAuditoryHistoryService) { }
  ngOnInit(): void {
    // console.log("note in note status icon....",this.note);
    // console.log("census in note status icon....",this.census);
    this.formattedOperation=this.noteAuditoryHistoryService.formatOperation(this.note.note_status.status);
  }

  getNoteStatus() {
    return this.note.note_status.status === 'not_uploaded' ? 'signed' : this.noteAuditoryHistoryService.formatOperation(this.note.note_status.status);
  }

  getnoteAudit(){
    return this.noteAuditoryHistoryService.formatOperation(this.note.note_status.status);
  }

  getnoteQAStatus(){
    let audit=this.noteAuditoryHistoryService.formatOperation(this.note.note_status.status);
    // console.log("audit : ", audit);
    if(audit=='submitted to external QA'||audit=='submitted to internal QA'||audit=='in progress'||audit=='assigned to external QA manually'||audit=='assigned to QA manually'||audit=='assigned transcriber manually'){
      return true;
    }else return false;
  }

}
