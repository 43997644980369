
import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoginService } from '../login/login.service';
import { ToastrService } from 'ngx-toastr';
import { SocketService } from 'src/app/services/socket.service';
import { DatePipe } from '@angular/common';
import * as global from '../global'
import { AuthService } from 'src/app/services/auth.service';
import { PCCService } from "../../services/pcc.service";
import { CompanyLogoAndMongogramService } from 'src/app/services/company-logo-and-mongogram.service';
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css'],
  providers: [DatePipe]
})

export class AuthComponent {
  public currentUserAuth: any;
  public currentUser: any;
  public response: any;
  public status: any;
  public mfaSession;
  private email;
  companyCode: any;
  defaultImg: string;
  companylogoPath: any;
  companylogoPathSubscription: any;
  browserFingerprint: any;
  timezone: any;
  display: any;
  public timerInterval: any;
  hide: boolean =  true;
  disableButton: boolean = false
  authMethod: string = "cognito";
  public constructor(
    public loginService: LoginService,
    private _toastr: ToastrService,
    private _socketService: SocketService,
    public datePipe: DatePipe,
    private pccService: PCCService,
    private _authService: AuthService,
    private _route: ActivatedRoute,
    private companyLogoAndMonogramService: CompanyLogoAndMongogramService

  ) {
    try {
      this.currentUserAuth = JSON.parse(localStorage.getItem('currentUserAuth'));
    } catch (error) {

    }
  }
  async ngOnInit() {
    // localStorage.removeItem('currentUser');
    this._route.paramMap.subscribe(async (params: ParamMap) => {
      this.mfaSession = params.get('mfaSession');
      this.email = params.get('email') || this.currentUserAuth.email;
      this.authMethod = params.get('authMethod')
    });
    if (this._route.snapshot.params.company_code) {
      this.companyCode = this._route.snapshot.params.company_code;
      console.log('this.companyCode', this.companyCode);
      await this.getCompanyLogo();
    } else {
      this.defaultImg = "../../../assets/img/DNlogo-new.png";
    }
    this._authService.onUserAuthChange.subscribe((user) => {
      if (user) {
        this._authService.redirectToHome();
      }
    });

    this.timer(2)

  }

  async getCompanyLogo() {
    this.companylogoPathSubscription = this.companyLogoAndMonogramService.castCompanyMonogram.subscribe((res: any) => {
      if (res) {
        this.companylogoPath = res;
      } else {
        this.defaultImg = "../../../assets/img/DNlogo-new.png";
      }
    });
  }

  ngOnDestroy(): void {
    if (this.companylogoPathSubscription) {
      this.companylogoPathSubscription.unsubscribe();
    }
  }

  onCheckPasscode(form: NgForm) {
    if (form.invalid) {
      return;
    }
    const infoToast = this._toastr.info('Authenticating...', "Please wait", { disableTimeOut: true });
    // this.currentUser = this.currentUserAuth;

    // ACTIVITY LOG
    this._socketService.addActivityLog({
      id: this.currentUser?._id,
      screen: 'Auth',
      operation: 'Auth Verification API Call',
      datetime: this.datePipe.transform(new Date(), global.date_time_format)
    })
    console.log({
      'form.value': form.value
    })
    this.loginService.check2FactorVerificationCode(this.currentUserAuth, form.value.passcode, this.email, this.mfaSession, form.value.remember_device_check, this.authMethod).subscribe(response => {
      this.response = response;
      this.status = this.response['status'];
      if (this.response['status'] === 200) {
        const { user, token } = this.response.data || {};
        console.log("checking 2 factor verification code");
        console.log("user: ", user);
        console.log("token: ", token);

        this._toastr.success("Authentication Successful", "Success");
        if (user) {
          this.currentUser = this.response.data.user;
          const auth_key = user.web_auth_key + ".WEB";
          user.auth_key = auth_key;
          this._authService.setToken(token);
        } else {
          this.currentUser = this.currentUserAuth;
        }
        this._authService.currentUser = this.currentUser;

        // setTimeout(() => {
        //   this.logoutUser();
        // }, 1000 * 60 * 60 * 24);
      }

    }, console.error, () => {
      this._toastr.clear(infoToast.toastId);
      //setTimeout(() =>  window.location.href = '/login', 3000)
      // this._authService.redirectToHome()
    }
    );


  }
  async logoutUser() {

    this._toastr.info('Session expired', 'Logging out');
    if (this._authService.currentUser.pcc_auth) {
      try {
        await this.pccService.revokeAuth().toPromise();
      } catch (error) {

        console.log(error.toString());
      }
    }
    this._authService.currentUser = null;
    this._authService.onUserAuthChange.next(null);
    const keysToRemoveExcludes = ['remembered_email'];
    let i = 0;
    let key = localStorage.key(0);
    while (key !== null) {
      if (keysToRemoveExcludes.indexOf(key) === -1) {
        localStorage.removeItem(key);
      }
      i++;
      key = localStorage.key(i);
    }


    i = 0;
    key = sessionStorage.key(0);
    while (key !== null) {
      sessionStorage.removeItem(key);
      i++;
      key = sessionStorage.key(i);
    }

    setTimeout(() => {
      window.location.href = '/login';
    });
  }

  resendAuthCode() {
    this.disableButton = true;
    if (!this.mfaSession) {
      this.loginService.send2FactorVerificationCode(this.currentUserAuth, this.currentUserAuth.auth_key).subscribe((res: any) => {
        if (res.status == 200) {
          this.timer(2)
          this._toastr.success('Verification code has been sent', 'Success');
        }
        else if (res.status == 500) {
          this._toastr.error('User not found', 'Error');
        }
      });

    }

    else {
      this.loginService.resendAuthCode(this.email).subscribe((res: any) => {
        if (res.status == 200) {
          this.timer(2)
          this._toastr.success('Verification code has been sent', 'Success');
        }
        else if (res.status == 500) {
          this._toastr.error('User not found', 'Error');
        }
      })
    }

  }

  getTwoFactorAuthMethodLabel() {
    const labels = {
      sms: 'phone number',
      email: 'email'
    }
    return labels[this.currentUserAuth.two_factor_auth_method];
  }

  timer(minute) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
      if(this.display.includes('-')){
        this.display = '00:00';
        clearInterval(this.timerInterval);
      }
      if (seconds <= 0) {
        if(this.display !== '00:00'){
          this.display = '00:00';
        }
        // console.log('finished');
        clearInterval(this.timerInterval);
      }
      this.disableButton = false;
    }, 1000);
  }
}
