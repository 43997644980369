import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-file-from-pc',
  templateUrl: './upload-file-from-pc.component.html',
  styleUrls: ['./upload-file-from-pc.component.css']
})
export class UploadFileFromPcComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UploadFileFromPcComponent>
  ) { }

  ngOnInit(): void {
  }
  onFileSelected(event) {
    const file = event.target.files;
    this.dialogRef.close(file);
  }

}
