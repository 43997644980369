<br/>
<table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="name">
        <th

                mat-header-cell
                *matHeaderCellDef
                mat-sort-header-container
        >
            Name
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.title | slice: 0:40
            }}{{ element.title && element.title.length > 40 ? ' . . .' : '' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="abbreviation">
        <th

                mat-header-cell
                *matHeaderCellDef
                mat-sort-header-container
        >
            Abbreviation
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.abbreviation | slice: 0:8
            }}{{
            element.abbreviation && element.abbreviation.length > 8
                ? ' . . .'
                : ''
            }}
        </td>
    </ng-container>
    <ng-container matColumnDef="email">
        <th

                mat-header-cell
                *matHeaderCellDef
                mat-sort-header-container
        >
            Emails
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.email | slice: 0:30
            }}{{ element.email && element.email.length > 30 ? ' . . .' : '' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="description">
        <th

                mat-header-cell
                *matHeaderCellDef
                mat-sort-header-container
        >
            Description
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.description | slice: 0:60
            }}{{
            element.description && element.description.length > 60
                ? ' . . .'
                : ''
            }}
        </td>
    </ng-container>
    <ng-container matColumnDef="address">
        <th

                mat-header-cell
                *matHeaderCellDef
                mat-sort-header-container
        >
            Address
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.address | slice: 0:60
            }}{{
            element.address && element.address.length > 60 ? ' . . .' : ''
            }}
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
