import {NgModule} from '@angular/core';
import {
    AddAppointmentComponent, AddAttributeComponent,
    AddPayorComponent,
    ProviderAppointmentComponent,
    TranscriberAppointmentComponent
} from './index';
import {CommonModule} from '@angular/common';
import {AppointmentComponent} from './appointment.component';
import {LayoutModule} from '../../layout/layout.module';
import {ProviderFilterComponent} from './provider/filter/filter.component';
import {FormsModule} from '@angular/forms';
import {MaterialModule} from '../material.module';
// import {FlexLayoutModule} from '@angular/flex-layout';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {TranscriberFilterComponent} from './transcriber/filter/filter.component';
import {VideoCallComponent} from "./video-call/video-call.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {FullCalendarModule} from "@fullcalendar/angular";
import {MatRadioModule} from "@angular/material/radio";
import {AppointmentDetailComponent} from "./provider/appointment-detail/appointment-detail.component";
import {EditProviderAppointmentDetailComponent} from "./provider/appointment-detail/edit-provider-appointment-detail/edit-provider-appointment-detail.component";
import {EditUserAppointmentDetailComponent} from "./user/appointment-detail/edit-user-appointment-detail/edit-user-appointment-detail.component";
import {TranscriberDetailComponent} from "./transcriber/transcriber-detail/transcriber-detail.component";
import {UserAppointmentComponent} from "./user/appointment/user-appointment.component";
import {UserDetailComponent} from "./user/appointment-detail/user-detail.component";
import {UserFilterComponent} from "./user/filter/filter.component";
import { CensusCalenderComponent } from 'src/app/includes/census-calender/census-calender.component';

@NgModule({
    declarations: [AppointmentComponent, ProviderAppointmentComponent, ProviderFilterComponent,
        AddAttributeComponent,AppointmentDetailComponent,EditProviderAppointmentDetailComponent,EditUserAppointmentDetailComponent, TranscriberDetailComponent, TranscriberFilterComponent, VideoCallComponent,
        TranscriberAppointmentComponent, UserAppointmentComponent, UserDetailComponent, UserFilterComponent,
        AddPayorComponent, AddAppointmentComponent,CensusCalenderComponent],
    imports: [
        CommonModule,
        LayoutModule,
        MaterialModule,
        // FlexLayoutModule,
        FormsModule,
        MatSlideToggleModule,
        MatTooltipModule,
        NgxUiLoaderModule,
        FullCalendarModule,
        MatRadioModule
    ],
    exports: [AppointmentComponent, AddPayorComponent,
        TranscriberDetailComponent, TranscriberFilterComponent,
        AddAttributeComponent, AppointmentDetailComponent, EditProviderAppointmentDetailComponent,EditUserAppointmentDetailComponent,
        UserAppointmentComponent, UserDetailComponent, UserFilterComponent,CensusCalenderComponent,
        AddAppointmentComponent, TranscriberAppointmentComponent,
        ProviderAppointmentComponent, ProviderFilterComponent],
    providers: []
})
export class AppointmentModule {
}
