<div [ngClass]="{'container': (companySide || user_dashboard) && !(nursingSide)}">

    <h3 class="dark-blue-heading flex justify-between items-center">
        <ng-container *ngIf="!user_dashboard">
            <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
                Back
            </button>
        </ng-container>

        <label [ngClass]="{'label-margin': !user_dashboard}"> Wound Assessment Report </label>
        <div class="flex justify-center items-end" style="color: black !important;">
            <input matInput type="text" ngxDaterangepickerMd [(ngModel)]="updationDate" class="mat-like-input"
                [ranges]="preDateRanges" [alwaysShowCalendars]="true" [maxDate]="currentDate" [linkedCalendars]="true"
                [showClearButton]="false" (datesUpdated)="changeUpdationDate($event)" class="date-range-picker"/>
        </div>
        <div class="flex gap-2"></div>
    </h3>

    <div class="search-block theme-form flex flex-wrap justify-start items-center gap-1"
        style="background-color: white; border-radius: 4px; padding-top: 8px; padding-bottom: 5px; padding-left: 8px;padding-right: 8px;">

                <mat-form-field appearance="fill">
                    <mat-label>Search Patient by Full Name (min 4 letters)</mat-label>

                    <input matInput type="text" name="patientsByProviderone" [formControl]="patientControl" [matAutocomplete]="patientAutoCompleteone"
                        (keyup)="searchPatients($event.target.value)" />
                    <mat-autocomplete #patientAutoCompleteone="matAutocomplete" [displayWith]="displayPatientFn">

                        <mat-option *ngFor="let patient of patients" [value]="patient" (click)="selectPatient(patient)">

                            {{ patient.first_name+" "+ patient.last_name+" / "+ patient.date_of_birth}}

                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <ng-container>
                    <div class="flex justify-center items-center">
                        <mat-form-field appearance="fill"> <mat-label>Date Of Birth (DD/MM/YYYY)</mat-label> 
                            <input type="text" name="dateOfBirth" aria-label="dateOfBirth" matInput maxlength="10" 
                            (input)="handleDateOfBirthChange($event)" [formControl]="dateOfBirthControl" 
                            [matAutocomplete]="dateOfBirthAutoControl"> 
                            <mat-autocomplete #dateOfBirthAutoControl="matAutocomplete"> 
                                <mat-option
                                    *ngFor="let patient of patients" [value]="patient ? patient.date_of_birth : ''"
                                    (click)="selectPatient(patient);"> 
                                    {{ patient.first_name + ' ' + patient.last_name + ' / ' + patient.date_of_birth }} 
                                </mat-option> 
                            </mat-autocomplete> 
                        </mat-form-field>
                   
                    </div>
                    <div class="flex justify-center items-center">
                        <mat-form-field style="width: 100%;">
                            <mat-label>Facility</mat-label>
                            <div class="flex justify-start items-center">
                                <mat-select name="facility" #facility="ngModel" [(ngModel)]="facility_id"
                                    (ngModelChange)="onSelectFacility($event, null)">
                                    <input placeholder="Search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
                                        style="color:#000;height: 40px;padding-left: 5px; ; position: sticky; top: 0; z-index:1000; background-color: lightgray; border: none; width: 100%;"
                                        (keydown)="$event.stopPropagation()" />
                                    <mat-option *ngFor="let facility of facilities | searchFacility: searchText" value="{{facility._id}}">
                                        <app-facility-title [facility]="facility"></app-facility-title>
                                    </mat-option>
                                </mat-select>
                            </div>
                        </mat-form-field>
                        <mat-icon matSuffix class="clear-icon" *ngIf="facility_id" (click)="clearFacilityDropdown();">
                            close</mat-icon>
                    </div>
                    <div class="flex justify-center items-center">
                        <mat-form-field appearance="fill">
                            <mat-label>{{currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE ? 'Select SNF WC Nurse' : 'Select Provider'}}</mat-label>
                            <input type="text" placeholder="{{currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE ? 'Select SNF WC Nurse' : 'Select Provider'}}" aria-label="provider" matInput
                                [formControl]="providerControl" #trigger="matAutocompleteTrigger"
                                [matAutocomplete]="providerAutoControl">
                            <mat-icon matSuffix style="cursor: pointer;" (click)="showProviderDropdown()">
                                keyboard_arrow_down</mat-icon>
                            <mat-autocomplete #providerAutoControl="matAutocomplete">
                                <mat-option *ngFor="let provider of filteredProviders | async" [value]="provider.full_name"
                                    (onSelectionChange)="onSelectProvider($event, provider._id);">
                                    {{provider.full_name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <mat-icon matSuffix class="clear-icon" *ngIf="provider_id" (click)="clearProviderDropdown();">
                            close</mat-icon>
                    </div>
                </ng-container>

                <mat-form-field appearance="fill" *ngIf="loadWounds">
                    <mat-label>Note DOS</mat-label>
                    <mat-select name="recentNotes" [(ngModel)]="selectedNote" placeholder="Recent Notes" #censusSelect>
                        <mat-option *ngFor="let recentNote of allRecentNotes" [value]="recentNote">
                            {{getNoteDate(recentNote)}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="flex flex-grow justify-between items-center" *ngIf="patient && loadWounds && selectedNote && currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER">
                    <div class="flex w-1/2">
                        <span class="material-symbols-outlined icon-navy report-icon" (click)="generateReportandUploadtoPCC()" matTooltip="Upload Grouped Wound Assessment Report">file_upload</span>
                        <span class="material-symbols-outlined icon-navy report-icon" matTooltip="Download Grouped Wound Assessment Report" [matMenuTriggerFor]="menu"> file_download </span>
                        <mat-menu #menu="matMenu" [class]="'menus-panel'">
                            <button *ngIf="patient" class="flex justify-start items-center gap-2" mat-menu-item (click)="generateReportForAllWounds()">
                                <span class="tag-style">Group Report</span>
                            </button>
                            <button *ngIf="patient" class="flex justify-start items-center gap-2" mat-menu-item (click)="generateZipReportForAllWounds()">
                                <span class="tag-style">Grouped Zip Report</span>
                            </button>
                        </mat-menu> 
                        <span  class="material-symbols-outlined icon-navy report-icon" (click)="showGroupedWoundReport()" matTooltip="View Wound Assessment Report">remove_red_eye</span>
                    </div>
                </div>
    </div>

    <mat-card class="vertical-height">
        <div class="flex gap-7" style="height: 100%;" >
            <div class="flex flex-col w-1/2">

                <div *ngIf="patient" class="wound-height">
                    <div style="position: relative;min-height: 100px;" class="wound-list flex flex-col gap-1">
                        <mat-tab-group>
                            <mat-tab label="ACTIVE">
                                <ng-template matTabContent>
                                    <app-wound-care-widget-wound-list *ngIf="loadWounds" [readOnlyMode]="readOnlyMode" [should_show_view_icon]="should_show_view_icon"
                                    [patient]="patient" [note]="getNoteLikeObject()" [configuration]="configuration" [isPreview]="true" (woundAnalyticReport)="woundAnalyticReport($event)"
                                    (allWoundIds)="allWoundIds($event)" [creationDate]="updationDate" woundState="active" [created_by]="provider_id" [facility_id]="facility_id">
                                </app-wound-care-widget-wound-list>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="REMISSION"> 
                                <ng-template matTabContent>
                                    <app-wound-care-widget-wound-list *ngIf="loadWounds" [readOnlyMode]="readOnlyMode" [should_show_view_icon]="should_show_view_icon"
                                    [patient]="patient" [note]="getNoteLikeObject()" [configuration]="configuration" [isPreview]="true" (woundAnalyticReport)="woundAnalyticReport($event)"
                                    (allWoundIds)="allWoundIds($event)" [creationDate]="updationDate" woundState="remission" [created_by]="provider_id" [facility_id]="facility_id">
                                </app-wound-care-widget-wound-list>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="AMPUTATED"> 
                                <ng-template matTabContent>
                                    <app-wound-care-widget-wound-list *ngIf="loadWounds" [readOnlyMode]="readOnlyMode" [should_show_view_icon]="should_show_view_icon"
                                    [patient]="patient" [note]="getNoteLikeObject()" [configuration]="configuration" [isPreview]="true" (woundAnalyticReport)="woundAnalyticReport($event)"
                                    (allWoundIds)="allWoundIds($event)" [creationDate]="updationDate" woundState="amputated" [created_by]="provider_id" [facility_id]="facility_id">
                                </app-wound-care-widget-wound-list>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="ARCHIVE"> 
                                <ng-template matTabContent>
                                    <app-wound-care-widget-wound-list *ngIf="loadWounds" [readOnlyMode]="readOnlyMode" [should_show_view_icon]="should_show_view_icon"
                                    [patient]="patient" [note]="getNoteLikeObject()" [configuration]="configuration" [isPreview]="true" (woundAnalyticReport)="woundAnalyticReport($event)"
                                    (allWoundIds)="allWoundIds($event)" [creationDate]="updationDate" woundState="archived" [created_by]="provider_id" [facility_id]="facility_id">
                                </app-wound-care-widget-wound-list>
                                </ng-template>
                            </mat-tab>
                        </mat-tab-group>

                    </div>
                    <div style="top: 25%;position: relative;left: 31%;font-size: large;color: #23406D;font-weight: 500;" *ngIf="patient && allIdArray.length === 0">
                        No Wounds Found on Selected Date Range
                    </div>
                </div>
            </div>

            <div style="overflow-y: auto;" *ngIf="selectedNote" class="w-1/2" id="pdfPreview" #pdfPreview>
                <div *ngIf="singleReportSelected"  class="wound-height">
                    <app-tissue-analytic-pdf-report [wound]="wound" [patient]="patient"
                        *ngIf="woundDetails"></app-tissue-analytic-pdf-report>
                </div>
                <div *ngIf="!singleReportSelected && allDataforGroupReport.length > 0">
                    <div *ngFor="let wounds of allDataforGroupReport">
                        <app-tissue-analytic-pdf-report [wound]="wounds" [patient]="patient"></app-tissue-analytic-pdf-report>
                        <div style="padding: 2px 0px 2px 0px" class="divider"></div>
                    </div>
                </div>
                <div style="top: 50%;position: relative;left: 31%;font-size: large;color: #23406D;font-weight: 500;" *ngIf="!singleReportSelected && allDataforGroupReport.length === 0">
                    No wound found assessed on {{getNoteDate(selectedNote)}}
                </div>
            </div>
        </div>
        <ngx-ui-loader [loaderId]="loader_id"></ngx-ui-loader>
    </mat-card>

</div>