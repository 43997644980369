<div class="container" id="releaseContainer">
    <mat-card>
        <mat-card-content>
            <button class="dn-button-secondary" mat-raised-button (click)="goBack()">Back</button>
            <div class="release-table-cards">
                <mat-tab-group [selectedIndex]='selectedTabIndex' (selectedTabChange)="tabChanged($event)">
                    <mat-tab label="Web Releases">
                        <div *ngIf="admin" style="text-align: right;margin-top: 10px;margin-bottom: 10px;">
                            <button mat-raised-button class="dn-button-primary" (click)="openReleaseDialog()">New
                                Release Note</button>
                        </div>
                        <div *ngIf="!admin" style="height: 10px;"></div>
                        <div *ngFor="let release of webReleases;index as index">
                            <ng-container>
                                <div class="gray-box flex justify-between items-center">
                                    {{release.createdAt | date:'MM/dd/yyyy'}}
                                    <span>
                                        <button mat-raised-button class="dn-button-primary" *ngIf="admin"
                                            (click)="openReleaseDialogForEdit(release.note,release)">Edit</button>
                                        &nbsp;&nbsp;
                                        <button mat-raised-button color="warn" *ngIf="admin"
                                            (click)="deleteNote(release._id,index)">Delete</button>
                                        <button mat-raised-button class="dn-button-primary" *ngIf="admin" style="margin-left: 1rem;"
                                            (click)="openReleaseNotesDialog(release, release)">Share
                                        </button>    
                                    </span>
                                </div>
                                <div class="white-box" style="font-weight: 400;" [innerHTML]="release.note">
                                </div>
                            </ng-container>
                        </div>
                    </mat-tab>
                        <mat-tab label="Mobile Releases">
                        <div *ngIf="admin" style="text-align: right;margin-top: 10px;margin-bottom: 10px;">
                            <button mat-raised-button class="dn-button-primary" (click)="openReleaseDialog()">New
                                Release Note</button>
                        </div>
                        <div *ngFor="let release of mobileReleases;index as index">
                            <ng-container>
                                <div class="gray-box flex justify-between items-center">
                                    {{release.createdAt | date}}
                                    <span>
                                        <button mat-raised-button class="dn-button-primary" *ngIf="admin"
                                            (click)="openReleaseDialogForEdit(release.note,release)">Edit</button>
                                        &nbsp;&nbsp;
                                        <button mat-raised-button color="warn" *ngIf="admin"
                                            (click)="deleteNote(release._id,index)">Delete</button>
                                        <button mat-raised-button class="dn-button-primary" *ngIf="admin" style="margin-left: 1rem;"
                                            (click)="openReleaseNotesDialog(release, release)">Share
                                        </button>
                                    </span>
                                </div>
                                <div class="white-box" style="font-weight: 400;" [innerHTML]="release.note">
                                </div>
                            </ng-container>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-card-content>
        <ngx-ui-loader loaderId="releaseLoader"></ngx-ui-loader>
    </mat-card>
</div>