import { ViewEncapsulation, Component, OnInit, DoCheck, AfterViewInit, Inject, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileExplorerService } from './file-explorer.service';
import { ToastrService } from 'ngx-toastr';
import { CreateNewFolderDialog } from '../create-new-folder-dialog.component';
import { DeleteConfirmationDialog } from '../delete-confirmation-dialog.component';
import { ShareItemDialog } from '../share-item-dialog.component';
import { UploadFileDialog } from '../upload-file-dialog.component';
import { WatchHelpVideoDialog } from '../watch-help-video-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { IFile, IFolder } from '../models';
import { DashboardService } from '../../dashboard/dashboard.service';
import * as global from '../../global';
import moment from 'moment'
// import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
// import {DragulaService} from 'ng2-dragula';
import { AuthService } from 'src/app/services/auth.service';
import { MatSort } from "@angular/material/sort";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatTableDataSource } from "@angular/material/table";

// import { DROPZONE_CONFIG, DropzoneComponent } from 'ngx-dropzone-wrapper';
// import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

// const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
//  // Change this to your upload POST address:
//   url: global.url_documents + '/uploadFile',
//   maxFilesize: 50,
//   clickable:false
// };

@Component({
    selector: 'app-file-explorer',
    templateUrl: './file-explorer.component.html',
    styleUrls: ['./file-explorer.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [DatePipe],
    // styleUrls: ['./file-explorer.component.css', '../../../../../node_modules/dropzone/dist/min/dropzone.min.css'],
    // providers: [

    //   {
    //     provide: DROPZONE_CONFIG,
    //     useValue: DEFAULT_DROPZONE_CONFIG
    //   }
    // ]
})
export class FileExplorerComponent implements OnInit, DoCheck, AfterViewInit, OnDestroy {
    isSharedFileExplorer: boolean = false;
    global: any = global;
    displayedColumns: Array<string> = ['title', 'owner_name', 'creation_date', 'updation_date'];
    @ViewChild(MatSort) sort: MatSort;
    dataSource: MatTableDataSource<IFile | IFolder>;
    allFilesAndFolders: Array<IFile | IFolder> = [];
    folders: Array<IFolder> = [];
    files: Array<IFile> = [];
    filesLoaded: Boolean = false;
    navigating: Boolean = false;

    historyStack: Array<IFolder> = [];
    historyStackTop: IFolder = null;
    currentUser: any;
    loading: boolean = false;
    header_title: string = 'My Drive';

    selectedItem: IFile | IFolder = null;
    view_type: string = 'LIST'; // GRID LIST

    showFileSidebar: boolean = false;
    isEditingDescription: boolean = false;

    // @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
    @ViewChild('contextMenuTrigger') contextMenu: MatMenuTrigger;
    contextMenuPosition = { x: '0px', y: '0px' };

    DRAGULA_FILES_GROUP = 'DRAGULA_FILES_GROUP';

    public droppedFiles: any[] = [];
    file_path: any;

    constructor(
        protected dialog: MatDialog,
        protected fileExplorerService: FileExplorerService,
        protected toastr: ToastrService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected dashboardService: DashboardService,
        protected _changeDetectorRefs: ChangeDetectorRef,
        protected _date_pipe: DatePipe,
        // private dragulaService: DragulaService,
        private _authService: AuthService
    ) {
    }

    ngOnInit() {

        const currentUser = this._authService.currentUser;
        if (currentUser) {
            this.currentUser = currentUser;
        }
    }

    ngDoCheck() {
        const historyStackLength = this.historyStack.length;
        if (historyStackLength > 0) {
            this.historyStackTop = this.historyStack[historyStackLength - 1];
        } else {
            this.historyStackTop = null;
        }
    }

    ngAfterViewInit() {
        // if (!this.isSharedFileExplorer && !this.dragulaService.find(this.DRAGULA_FILES_GROUP)) {

        //     this.dragulaService.createGroup(this.DRAGULA_FILES_GROUP, {
        //         revertOnSpill: true,
        //         accepts: (el, target, source, sibling) => {
        //             const targetItem = this.folders.find(f => f._id === target.id);

        //             return source.id !== target.id && (!!targetItem || target.classList.contains('empty'));
        //         }
        //     });
        //     this.dragulaService.drag(this.DRAGULA_FILES_GROUP).subscribe(e => {
        //         e.el.classList.add('on-drag');
        //     });
        //     this.dragulaService.dragend(this.DRAGULA_FILES_GROUP).subscribe(e => {
        //         e.el.classList.remove('on-drag');
        //     });
        //     this.dragulaService.drop(this.DRAGULA_FILES_GROUP).subscribe((e: any) => {
        //         this.navigating = true;

        //         const {id: targetId} = e.target;
        //         const {id: sourceId} = e.source;


        //         const item = this.allFilesAndFolders.find(f => f._id === sourceId);

        //         this.folders = this.folders.filter(f => f._id !== sourceId);
        //         this.files = this.files.filter(f => f._id !== sourceId);
        //         this.allFilesAndFolders = [...this.folders, ...this.files];

        //         e.el.style.display = 'none';
        //         this.changeParentFolderId(item, targetId);

        //     });
        // }
        setTimeout(() => {
            this.fetchFilesAndFolders();

            const dropZoneDiv: HTMLElement = document.querySelector('.ngx-file-drop__drop-zone');
            dropZoneDiv.classList.add('dismis-file-selection');
        });
    }

    ngOnDestroy(): void {
        this.toastr.clear();
    }

    ownedItem(item: IFile | IFolder) {
        return item.owner_id === this.currentUser._id;
    }

    initializeFilesAndFolders(filesAndFolders) {

        let folders: Array<IFolder> = [];
        let files: Array<IFile> = [];

        folders = filesAndFolders.folders;
        folders.sort((a, b) => a.title > b.title ? 1 : -1);

        files = filesAndFolders.files;
        files.sort((a, b) => a.title > b.title ? 1 : -1);


        this.folders = folders;
        this.files = files;
        this.allFilesAndFolders = [...folders, ...files];
        setTimeout(() => {
            this.dataSource = new MatTableDataSource(this.allFilesAndFolders);
            this.dataSource.sort = this.sort;
            this._changeDetectorRefs.detectChanges();
        });
        // this._changeDetectorRefs.detectChanges();
        this.filesLoaded = true;
    }

    async fetchFilesAndFolders(item: IFolder = null) {
        //  this.loading = true;
        return new Promise(async (resolve, reject) => {
            try {
                this.navigating = true;
                if (item) {
                    this.historyStack.push(item);
                }

                const filesAndfoldersResponse: any = await this.fileExplorerService.getMyFilesAndFolders(item ? item._id : '').toPromise();

                if (filesAndfoldersResponse.status === 200) {
                    this.initializeFilesAndFolders(filesAndfoldersResponse.data.array);
                    console.log('files and folders', filesAndfoldersResponse);
                }

            } catch (err) {
                reject(err);
            } finally {
                // this.loading = false;
                this.navigating = false;
            }
            ;
        });
    }


    isEmpty() {
        return this.allFilesAndFolders.length === 0;
    }

    upOneLevel() {
        if (this.navigating) {
            return;
        }
        this.historyStack.pop(); // discard current folder
        this.fetchFilesAndFolders(this.historyStack.pop()); // pop the previous folder
    }

    onFolderClick(folder: IFolder) {
        if (this.navigating) {
            return;
        }
        // uncomment
        this.toggleFileSelection(folder);
        console.log('selected Item', this.selectedItem);
        // this.fetchFilesAndFolders(folder);
    }

    onFileClick(file: IFile) {
        if (this.navigating) {
            return;
        }
        // uncomment
        this.toggleFileSelection(file);
        console.log('file click', file);
        this.file_path = file.file_path;
        // this.downloadFile(file);

    }

    // uncomment
    onFileDoubleClick(file: IFile) {
        this.downloadFile(file);
    }

    onFolderDoubleClick(folder: IFolder) {
        this.fetchFilesAndFolders(folder);
    }

    toggleFileSelection(thisItem: IFile | IFolder) {
        // const index = this.selectedFiles.indexOf(item);
        // if(index === -1) {
        //   item.selected = true;
        //   this.selectedFiles.push(item);
        // } else {
        //   item.selected = false;
        //   this.selectedFiles.splice(index,1);
        // }
        this.selectedItem = thisItem;
        if (this.selectedItem.selected) {
            this.selectedItem.selected = false;
            this.selectedItem = null;

        } else {
            this.allFilesAndFolders.forEach((item: IFile | IFolder) => {
                item.selected = (item === this.selectedItem);
            });
            this.getDriveActivity(this.selectedItem);
        }
    }

    dismisFileSelection() {
        if (this.selectedItem) {
            this.selectedItem.selected = false;
            this.selectedItem = null;
            this.showFileSidebar = false;
        }
        // this.allFilesAndFolders.filter((item:IFile|IFolder) => {
        //   item.selected = false;
        // });
    }

    onFileContainerClick(e) {
        const classList = Array.from(e.target.classList);
        if (classList.includes('dismis-file-selection') || classList.includes('mat-figure')) {
            this.dismisFileSelection();
        }
    }

    toggleFilesView() {
        this.dismisFileSelection();
        if (this.view_type === 'GRID') {
            this.view_type = 'LIST';
        } else {
            this.view_type = 'GRID';
        }
    }

    watchHelpVideo( videoId ) {
        const dialogRef = this.dialog.open(WatchHelpVideoDialog, {
            // width: '500px',
            data: { videoId }
        });

        dialogRef.afterClosed().subscribe( console.log );
    }

    onItemContextMenu(event: MouseEvent, item: IFile | IFolder) {
        event.preventDefault();
        this.contextMenuPosition.x = event.clientX + 'px';
        this.contextMenuPosition.y = event.clientY + 'px';
        this.contextMenu.menuData = { 'item': item };
        this.contextMenu.openMenu();
        // uncomment
        if (item !== this.selectedItem) {
            this.toggleFileSelection(item);
        }
    }

    onRenameItemClick(contextMenuData: IFolder) {
        const dialogRef = this.dialog.open(CreateNewFolderDialog, { width: '500px', data: { action: 'rename', title: contextMenuData.title } });
        dialogRef.afterClosed().subscribe((title) => {
            if (!title) {
                return;
            }
            this.renameFolder(contextMenuData, title);
        });
    }

    onShareItemClick(contextMenuData: IFile | IFolder) {
        const dialogRef = this.dialog.open(ShareItemDialog, { width: '500px', data: { item: contextMenuData } });
        dialogRef.afterClosed().subscribe((emails: String[]) => {
            if (!emails) {
                return;
            }
            this.shareItem(contextMenuData, emails);
        });
    }

    async onDeleteItemClick(contextMenuData: IFile | IFolder) {
        const message = 'Are you sure you want to delete?'
        const dialogBoxValue = await this.dashboardService.DeleteConfirmationDialog(message);
            if (dialogBoxValue === "true") {
            // if((<IFile>contextMenuData).file_path) {
            //   this.deleteFile(<IFile>contextMenuData);
            // } else {
            //   this.deleteFolder(<IFolder>contextMenuData);
            // }
            this.deleteItem(contextMenuData);
            }
            else{
                () => this.toastr.error('Something went wrong while removing Folder', 'Failed')
            }
    }

    onDownloadFileClick(contextMenuData: IFile) {
        this.downloadFile(contextMenuData);
    }

    async shareItem(item: IFile | IFolder, emails: String[]) {
        const infoToast = this.toastr.info('Processing...', 'Please wait', { disableTimeOut: true });
        let item_type: String;
        if ((<IFile>item).file_path) {
            item_type = 'file';
        } else {
            item_type = 'folder';
        }

        const shareItemResponse: any = await this.fileExplorerService.shareItem(item._id, emails, item_type).toPromise();

        if (shareItemResponse.status === 200) {
            // // sending emails to newly added emails
            // const emailSubject = `${this.currentUser.first_name} ${this.currentUser.last_name} shared ${item.title}`;
            // const emailBody = `<strong>${this.currentUser.first_name} ${this.currentUser.last_name}</strong> has shared ${item_type} <strong>${item.title}</strong> with you. <a href="https://doctornow.io">Click Here</a> to view.`;
            // for(let email of emails) {
            //   if(!item.shared_by_email.includes(email)) {
            //     var emailDetails = {
            //       email: email,
            //       text:emailBody,
            //       subject:emailSubject
            //     };
            //     await this.dashboardService.sendNewDictaionRequestEmail(emailDetails).toPromise();
            //   }
            // }

            await this.reload();
        } else {
            this.toastr.error(shareItemResponse.message, 'Failed');
        }
        this.toastr.clear(infoToast.toastId);
        this.toastr.success("Shared Successfully", "Success")
    }

    async downloadFile(file: IFile) {
        const infoToast = this.toastr.info('Preparing file to download...', 'Please wait', { disableTimeOut: true });
        try {
            const downloadFileResponse: any = await this.fileExplorerService.downloadFile(file._id).toPromise();
            var url = window.URL.createObjectURL(downloadFileResponse);
            var a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = <string>file.title;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element

        } catch (error) {
            this.toastr.error('Unable to download file.', 'Failed');
        } finally {
            this.toastr.clear(infoToast.toastId);
            this.toastr.success("Downloaded Successfully")
        }
    }

    async createNewFolder(folder: any) {
        return new Promise(async (resolve, reject) => {
            const infoToast = this.toastr.info('Creating New Folder...', 'Please wait', {
                disableTimeOut: true,
                positionClass: 'toast-bottom-right'
            });
            const createFolderResponse: any = await this.fileExplorerService.createNewFolder(folder).toPromise();
            if (createFolderResponse.status === 200) {
                await this.reload();
                resolve(createFolderResponse.data);
            } else {
                this.toastr.error(createFolderResponse.message, 'Failed');
                reject(createFolderResponse.message);
            }
            this.toastr.clear(infoToast.toastId);
            this.toastr.success("Created Successfully","Success")
        });
    }

    // async deleteFolder(folder:IFolder) {
    //   const infoToast = this.toastr.info('Deleting Folder...', 'Please wait', {disableTimeOut:true});
    //   const deleteFolderResponse:any = await this.fileExplorerService.deleteFolder(folder._id).toPromise();
    //   if(deleteFolderResponse.status === 200) {
    //     await this.reload();
    //   } else {
    //     this.toastr.error(deleteFolderResponse.message, 'Failed');
    //   }
    //   this.toastr.clear(infoToast.toastId);
    // }
    // async deleteFile(file:IFile) {
    //   const infoToast = this.toastr.info('Deleting File...', 'Please wait', {disableTimeOut:true});
    //   const deleteFileResponse:any = await this.fileExplorerService.deleteFile(file._id).toPromise();
    //   if(deleteFileResponse.status === 200) {
    //     await this.reload();
    //   } else {
    //     this.toastr.error(deleteFileResponse.message, 'Failed');
    //   }
    //   this.toastr.clear(infoToast.toastId);
    // }

    async deleteItem(item: IFile | IFolder) {
        const infoToast = this.toastr.info('Deleting...', 'Please wait', { disableTimeOut: true });
        const item_type = (<IFile>item).file_path ? 'file' : 'folder';
        const deleteItemResponse: any = await this.fileExplorerService.deleteItem(item._id, item_type).toPromise();
        if (deleteItemResponse.status === 200) {
            await this.reload();
        } else {
            this.toastr.error(deleteItemResponse.message, 'Failed');
        }
        this.toastr.clear(infoToast.toastId);
        this.toastr.success("Deleted Successfully","Success")
    }

    async renameFolder(folder: IFolder, new_folder_title) {
        const infoToast = this.toastr.info('Renaming Folder', 'Please wait', { disableTimeOut: true });
        const renameFolderResponse: any = await this.fileExplorerService.renameFolder(folder._id, new_folder_title).toPromise();
        if (renameFolderResponse.status === 200) {
            await this.reload();
        } else {
            this.toastr.error(renameFolderResponse.message, 'Failed');
        }
        this.toastr.clear(infoToast.toastId);
        this.toastr.success("Renamed Successfully","Success")
    }

    async changeParentFolderId(item: IFolder | IFile, parent_folder_id) {
        const item_type = (<IFile>item).file_path ? 'file' : 'folder';
        const changeParentFolderIdResponse: any = await this.fileExplorerService.changeParentFolderId(item._id, parent_folder_id, item_type).toPromise();
        if (changeParentFolderIdResponse.status === 200) {
            await this.reload();
        } else {
            this.toastr.error(changeParentFolderIdResponse.message, 'Failed');
        }
    }

    async uploadFile(fileBlob: File, fileData: IFile = null) {
        const parent_folder_id = this.historyStackTop === null ? '' : this.historyStackTop._id;

        const file: IFile = fileData ? fileData : {
            _id: '',
            parent_folder_id: parent_folder_id,
            title: fileBlob.name,
            creation_date: moment(new Date()).format(global.moment_date_time_format),//this._date_pipe.transform(new Date().toString(), global.date_time_format),
            updation_date: moment(new Date()).format(global.moment_date_time_format),//this._date_pipe.transform(new Date().toString(), global.date_time_format),
            owner_id: this.currentUser._id,
            owner_name: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
            mime_type: fileBlob.type,
            shared_by_email: [],
            is_deleted: 'false',
            file_path: null // will be replaced with aws file path
        };

        const infoToast = this.toastr.info(fileBlob.name, 'Uploading File', {
            tapToDismiss: false,
            disableTimeOut: true,
            positionClass: 'toast-bottom-right'
        });
        const uploadFileResponse: any = await this.fileExplorerService.uploadFile(file, fileBlob).toPromise();
        if (uploadFileResponse.status === 200) {
            await this.reload();
        } else {
            this.toastr.error(uploadFileResponse.message, 'Failed');
        }
        this.toastr.clear(infoToast.toastId);
        this.toastr.success("Uploaded Successfully","Success")

    }


    async reload() {
        new Promise(async (resolve, reject) => {
            try {
                await this.fetchFilesAndFolders(this.historyStack.pop());
                resolve(null);
            } catch (error) {
                reject(error);
            }
        });
    }

    onCreateNewFolderClick() {
        const dialogRef = this.dialog.open(CreateNewFolderDialog, { width: '500px', data: { action: 'create' } });
        dialogRef.afterClosed().subscribe((title) => {
            if (!title) {
                return;
            }
            const parent_folder_id = this.historyStackTop === null ? '' : this.historyStackTop._id;
            const folder: IFolder = {
                _id: '',
                parent_folder_id: parent_folder_id,
                title: title,
                creation_date: moment(new Date()).format(global.moment_date_time_format),
                updation_date: moment(new Date()).format(global.moment_date_time_format),
                owner_id: this.currentUser._id,
                owner_name: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
                shared_by_email: [],
                is_deleted: 'false'
            };
            this.createNewFolder(folder);
        });
    }

    onUploadFileClick() {
        const dialogRef = this.dialog.open(UploadFileDialog, { width: '500px' });
        dialogRef.afterClosed().subscribe((fileInput) => {
            if (!fileInput || (fileInput && fileInput.files.length === 0)) {
                return;
            }
            const fileBlob: File = fileInput.files[0];
            this.uploadFile(fileBlob);
        });
    }

    onSharedByMeClick() {
        this.router.navigate(['shared-with-me'], { relativeTo: this.route });
    }

    getItemIcon(item) {
        if (item.file_path) {
            const { title } = item;
            const extension = title.substr(title.lastIndexOf('.') + 1);
            const filetypeIconsPath = '../../../assets/img/filetypes';

            return (`${filetypeIconsPath}/${extension}.svg`);
        } else {
            // folder
            return (`../../../assets/img/folder-icon.png`);
        }
    }

    async updateItemDescription(item, description) {
        this.isEditingDescription = false;
        // this.loading = true;
        item.updation_date = moment(new Date()).format(global.moment_date_time_format);
        try {
            let item_type;
            if (item.file_path) {
                item_type = 'file';
            } else {
                item_type = 'folder';
            }
            await this.fileExplorerService.updateItemDescription(item._id, description, item_type).toPromise();
        } catch (err) {
            console.error(err);
        } finally {
            // this.loading = false;
        }
    }

    async getDriveActivity(item) {
        try {
            const driveActivityResponse: any = await this.fileExplorerService.getDriveActivity(item._id).toPromise();
            if (driveActivityResponse.status === 200) {
                item.drive_activity = driveActivityResponse.data.array.map(activity => {
                    activity.activity_description = activity.activity_description.replace(`${this.currentUser.first_name} ${this.currentUser.last_name}`, 'You').replace(this.currentUser.email, 'You');
                    return activity;
                });
            }
        } catch (err) {
            console.error(err);
        } finally {
            // this.loading = false;
        }
    }

    /*
    Dropzone events:
     */
    // onUploadError(e) {
    // }
    // onUploadSuccess(e){
    // }
    // addedfile(e) {
    // }
    // onSending(e) {
    // }

    public arrangeIntoTree(paths) {
        // Adapted from http://brandonclapp.com/arranging-an-array-of-flat-paths-into-a-json-tree-like-structure/

        const findWhere = (array, key, value) => {
            // Adapted from https://stackoverflow.com/questions/32932994/findwhere-from-underscorejs-to-jquery
            let t = 0; // t is used as a counter
            while (t < array.length && array[t][key] !== value) {
                t++;
            }
            ; // find the index where the id is the as the aValue

            if (t < array.length) {
                return array[t];
            } else {
                return false;
            }
        };
        var tree = [];

        for (var i = 0; i < paths.length; i++) {
            var path = paths[i];
            var currentLevel = tree;
            for (var j = 0; j < path.length; j++) {
                var part = path[j];

                var existingPath = findWhere(currentLevel, 'name', part);

                if (existingPath) {
                    currentLevel = existingPath.children;
                } else {
                    var newPart = {
                        name: part,
                        children: [],
                    };

                    currentLevel.push(newPart);
                    currentLevel = newPart.children;
                }
            }
        }
        return tree;
    }

    public dropped(files: any[]) {
        this.droppedFiles = files;

        // first create directories
        const paths = [];
        for (const droppedFile of files) {

            if (droppedFile.fileEntry.isFile) {
                const pathSplit = droppedFile.fileEntry['fullPath'].split('/');
                paths.push([...pathSplit.slice(1, pathSplit.length - 1), droppedFile.fileEntry]); // skip first empty item and last actual item
            } else {
                // empty directory
            }
        }

        const pathsTree = this.arrangeIntoTree(paths);
        this.recusiveTreeUpload(pathsTree, this.historyStackTop ? this.historyStackTop._id : '');

        // for (const droppedFile of files) {

        //   // Is it a file?
        //   if (droppedFile.fileEntry.isFile) {
        //     const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        //     fileEntry.file((file: File) => {

        //       return;


        //       // Here you can access the real file
        //       // this.uploadFile(file);
        //       /**
        //       // You could upload it like this:
        //       const formData = new FormData()
        //       formData.append('logo', file, relativePath)

        //       // Headers
        //       const headers = new HttpHeaders({
        //         'security-token': 'mytoken'
        //       })

        //       this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
        //       .subscribe(data => {
        //         // Sanitized logo returned from backend
        //       })
        //       **/

        //     });
        //   } else {
        //     if(droppedFile.fileEntry.isDirectory) {
        //       const newFolder:IFolder = {
        //         parent_folder_id: this.historyStackTop ? this.historyStackTop._id : '',
        //         title: droppedFile.fileEntry.name,
        //         creation_date: this._date_pipe.transform(new Date().toString(), global.date_time_format),
        //         updation_date: this._date_pipe.transform(new Date().toString(), global.date_time_format),
        //         owner_id:this.currentUser._id,
        //         owner_name: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
        //         shared_by_email:[],
        //         is_deleted:'false'
        //       }
        //       this.createNewFolder(newFolder);
        //     }

        //     // It was a directory (empty directories are added, otherwise only files)
        //     // const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        //   }
        // }
    }

    public fileOver(event) {
    }

    public fileLeave(event) {
    }

    async recusiveTreeUpload(pathsTree, parent_folder_id = '') {
        for (let pathsSubTree of pathsTree) {
            if (typeof pathsSubTree.name === 'object' && pathsSubTree.name.isFile) {
                // have files

                // const fileEntry = pathsSubTree.name as FileSystemFileEntry;
                const fileEntry = pathsSubTree.name;
                fileEntry.file((file: File) => {
                    if (file.size === 0) {
                        return;
                    } //skip if empty file
                    const fileData: IFile = {
                        _id: '',
                        parent_folder_id: parent_folder_id,
                        title: file.name,
                        creation_date: moment(new Date()).format(global.moment_date_time_format),
                        updation_date: moment(new Date()).format(global.moment_date_time_format),
                        owner_id: this.currentUser._id,
                        owner_name: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
                        mime_type: file.type,
                        shared_by_email: [],
                        is_deleted: 'false',
                        file_path: null // will be replaced with aws file path
                    };
                    this.uploadFile(file, fileData);
                });

            }

            // is directory
            if (typeof pathsSubTree.name === 'string') {

                // contains children and is a directory
                const newFolder: IFolder = {
                    parent_folder_id: parent_folder_id,
                    title: pathsSubTree.name,
                    creation_date: moment(new Date()).format(global.moment_date_time_format),
                    updation_date: moment(new Date()).format(global.moment_date_time_format),
                    owner_id: this.currentUser._id,
                    owner_name: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
                    shared_by_email: [],
                    is_deleted: 'false'
                }
                const newFolderResponse: any = await this.createNewFolder(newFolder);
                this.recusiveTreeUpload(pathsSubTree.children, newFolderResponse._id)

            }
        }
    }
    formatDate(date) {
        let momentDate = moment(date);
        let finalDate = '';
        if (momentDate.isValid()) {
            finalDate = momentDate.format(global.moment_date_time_format)
        }
        return finalDate;
    }
}

