<div class="flex gap-5 justify-center items-center" style="background: #cedbdf; padding: 5px; border-radius: 4px;">

  <mat-card style="padding: 0px;" class="flex-grow" style="display: flex; height: 45px;">
    <input type="text" class="search-field" placeholder="Search Medical Assistant by Name" name="title"
      [(ngModel)]="userNameRegExp" (ngModelChange)="searchDoctors()" />
    <mat-spinner *ngIf="hasMALoaded" style="margin-left: auto;" matSuffix [diameter]="18">
    </mat-spinner>
  </mat-card>

  <button mat-raised-button class="dn-button-primary" (click)="exportUsersAsXLSX()">
    Export As XLSX
  </button>
  <mat-form-field>
    <mat-label>User Status</mat-label>
    <mat-select value="true" (selectionChange)="applyUserFilter($event)">
      <mat-option value="true">Active</mat-option>
      <mat-option value="false">Inactive</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div style="height: 3px;"></div>

<div style="overflow-x: auto; " class="vertical-height">

  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="first_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%;"> First Name </th>
      <td mat-cell *matCellDef="let element" [routerLink]="['../../doctor-profile', {provider_id: element._id}]"
        style="cursor: pointer;">
        <a>{{element._id !== currentCompany?.user_id?._id ?(element.first_name | titlecase):null}}</a>
        <b *ngIf="element._id === currentCompany?.user_id?._id">
          <a>{{element.first_name | titlecase}}</a>
        </b>
      </td>
    </ng-container>
    <ng-container matColumnDef="last_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%;"> Last Name </th>
      <td mat-cell *matCellDef="let element" [routerLink]="['../../doctor-profile', {provider_id: element._id}]"
        style="cursor: pointer;">
        <a>{{element._id !== currentCompany?.user_id?._id ?(element.last_name | titlecase):null}}</a>
        <b *ngIf="element._id === currentCompany?.user_id?._id">
          <a>{{element.last_name | titlecase}}</a>
        </b>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element" style="width: 20%; overflow-wrap: break-word;">
        <span *ngIf="element._id !== currentCompany?.user_id?._id">
          {{element.email | titlecase}}
        </span>
        <br>
        <small *ngIf="element.recentLogin==undefined && element._id !== currentCompany?.user_id?._id"
          style="color: gray; cursor: pointer;" (click)="ResendEmail(element._id)"><a>Resend Registration Email</a></small>
        <b *ngIf="element._id === currentCompany?.user_id?._id">
          {{element.email | titlecase}}
        </b>
        <br>
        <small *ngIf="element.recentLogin==undefined && element._id === currentCompany?.user_id?._id"
          style="color: gray; cursor: pointer;" (click)="ResendEmail(element._id)"><a>Resend Registration Email</a></small>
      </td>
    </ng-container>

    <ng-container matColumnDef="last_login">
      <th mat-header-cell *matHeaderCellDef style="width: 10%;"> Last Login</th>
      <td mat-cell *matCellDef="let element">
        {{element.recentLogin ?(element.recentLogin):null}}
      </td>
    </ng-container>
    <ng-container matColumnDef="timezone">
      <th mat-header-cell *matHeaderCellDef style="width: 5%;"> Tz</th>
      <td mat-cell *matCellDef="let element">
        {{element.recentLogin ? 'Los Angeles':null}}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox class="top-margin" [checked]="element.is_activated == 'true'" [disabled]="disabled"
          (change)="changeUserActiveStatus(element, $event.checked)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="cognito">
      <th mat-header-cell *matHeaderCellDef>Cognito</th>
      <td mat-cell *matCellDef="let element" style="text-align-last: center;">
        <mat-checkbox class="tp-margin" [checked]="element.enableCognito == true" [disabled]="disabled"
          (change)="changeCognitoStatus(element, $event.checked)">
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="user-permissions">
      <th mat-header-cell *matHeaderCellDef>Permissions</th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="fill" [ngClass]="{
                        'user-permissions-form-field': true,
                        'permissions-selected': element.permissions.length > 0
                      }">
          <mat-label>Select permissions...</mat-label>
          <mat-select multiple disableRipple [ngModel]="element.permissions"
            (selectionChange)="changeUserPermissions( element, $event )">
            <mat-select-trigger>
              {{ resolvePermission(element.permissions[0]) }}
              <span *ngIf="element.permissions.length > 1" class="example-additional-selection">
                (+{{ element.permissions.length - 1 }} {{ element.permissions.length === 2 ? 'other' : 'others' }})
              </span>
            </mat-select-trigger>
            <mat-option *ngFor="let perm of permissionsList" [value]="perm.key">{{ perm.value }}</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="charge_status">
      <th mat-header-cell *matHeaderCellDef>Charge Capture</th>
      <td mat-cell *matCellDef="let element" style="text-align-last: center;">
        <mat-checkbox class="tp-margin" [checked]="element.charge_status == true" [disabled]="disabled"
          (change)="changeChargeStatus(element, $event.checked)">
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="admin_">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Admin</th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox class="tp-margin" [checked]="element.admin_type == 'full_admin'"
          [disabled]="currentUser._id == element._id || element.admin_type == 'system_admin'"
          (change)="changeAdminStatus(element, $event.checked)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="simulate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>View Dashboard</th>
      <td mat-cell *matCellDef="let element">
        <a [routerLink]="['../../simulate/' + element._id]">View Dashboard</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="user-type">
      <th mat-header-cell *matHeaderCellDef>User Type</th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field style="width: 100%;">
          <mat-label>User Type</mat-label>
          <mat-select (selectionChange)="handleChangeUserType(element._id,$event)" [ngModel]="usersFilter.user_type">
            <mat-option value="1" *ngIf="iscurrentCompany.company_type !== global.COMPANY_TYPE.SNF">
              Provider
            </mat-option>
            <mat-option value="test_provider" *ngIf="iscurrentCompany.company_type !== global.COMPANY_TYPE.SNF">
              Test Provider
            </mat-option>
            <mat-option value="nurse" *ngIf="iscurrentCompany.company_type !== global.COMPANY_TYPE.SNF">
              Nurse
            </mat-option>
            <mat-option value="6" *ngIf="iscurrentCompany.company_type !== global.COMPANY_TYPE.SNF">
              Biller
            </mat-option>
            <mat-option value="medical_assistant">
              Medical Assistant
            </mat-option>
            <mat-option value="view_only_user">
              View Only User
            </mat-option>
            <mat-option value="business_officer" *ngIf="iscurrentCompany.company_type !== global.COMPANY_TYPE.SNF">
              Business Officer
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="companies">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field style="color: black !important;width: 100% !important;" class="flex-grow">
          <input type="text" placeholder="Select Company" aria-label="company" matInput [formControl]="companyControl"
            [matAutocomplete]="companyAutoControl">
          <mat-autocomplete #companyAutoControl="matAutocomplete">
            <mat-option *ngFor="let company of filteredCompanies | async" [value]="company.name ?company.name:''"
              (click)="onCompanyChange(element , company._id);">
              {{company.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="operations">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

</div>