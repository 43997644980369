import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import * as global from "src/app/includes/global";

@Component({
  selector: 'app-wound-nurse-associations',
  templateUrl: './wound-nurse-associations.component.html',
  styleUrls: ['./wound-nurse-associations.component.css']
})
export class WoundNurseAssociationsComponent implements OnInit {
  tabIndex = 0;
  global = global;
  currentUser: any;
  woundNurseFacilityAssociationLabel = ""
  constructor(
    private _authService: AuthService,
  ) {
    this.currentUser = this._authService.currentUser;
    // if (this.currentUser.admin_type === global.ADMIN_TYPE.WOUND_NURSE_ADMIN)
      this.woundNurseFacilityAssociationLabel = "Wound Nurse-Facility"
    // else this.woundNurseFacilityAssociationLabel = "Wound Admin Nurse-Facility"
  }

  ngOnInit(): void {
  }

  onTabChange($event) {
    this.tabIndex = $event.index;
  }

}
