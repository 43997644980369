import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { MipsService } from 'src/app/services/Modules/mips.service';
import { AddMipsQuestionComponent } from './add-mips-question/add-mips-question.component';
import { MeasureNumberQuestionsDialog } from './measure-number-questions/measure-number-questions.component';
import Chart, { ChartEvent } from 'chart.js/auto';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { lastValueFrom } from 'rxjs';
import  * as global from '../../../../../includes/global'
@Component({
  selector: 'app-mips-questions',
  templateUrl: './mips-questions.component.html',
  styleUrls: ['./mips-questions.component.css']
})
export class MipsQuestionComponent implements OnInit, OnDestroy {

  dummyData = [
    {
      measure_number: "047",
      questions: "10",
    },
    {
      measure_number: "110",
      questions: "20"
    }
  ]
  displayedColumns: any = ['measure_number', 'measure_title', 'questions'];
  dataSource: any;
  addMips:any;
  currentUser: any;

  mipsQuestions:any[] = [];

  loaderId = 'mips-questions-loader';
  params: {
    id: string
  }
  mipsGraphData: any = [];
  canvas: any;
  ctx: any;
  @ViewChild('barChart', { static: false}) barChart: ElementRef<HTMLCanvasElement>;
  chart: Chart;
  currentCompany: any;
  enable_company_mips: any = false;
  showMipsCount: boolean = true;
  company_id: any ='';
  constructor(
    private route: ActivatedRoute,
    // private addMipsComponent: AddMipsComponent,
    private mipsService:MipsService,
    private dialog: MatDialog, 
    private loader: NgxUiLoaderService, 
    public authService: AuthService,
    private companyService: CompanyService
  ) {
    this.params = {
      id: this.route.parent.snapshot.params.id
    };
    this.currentUser = this.authService.currentUser;
   }

  async ngOnInit() {
    if(this.currentUser.admin_type != global.ADMIN_TYPE.SYSTEM_ADMIN && this.currentUser.admin_type != global.ADMIN_TYPE.FULL_ADMIN) {

    }
    window.location.pathname.includes('company') ? this.company_id = this.route.parent.snapshot.params.id : this.company_id = this.currentUser.company_id
    const filter = {
      _id: this.company_id
    };
    const project = {
        name: 1,
        company_type: 1,
        enable_company_mips: 1
    }
    const response: any = await lastValueFrom(this.companyService.getCompany(filter, project));
    // console.log("responseresponse", response)
    if(response.status === 200) {
      this.currentCompany = response.data;
      this.enable_company_mips = this.currentCompany.enable_company_mips;
    }
    this.initMipsQuestions();
  }

  initMipsQuestions() {
    this.loader.startLoader(this.loaderId);
    this.mipsService.getQuestionCounts(this.params.id).subscribe((response:any) => {
      if(response.status === 200){
        this.mipsQuestions = response.data.array;
        
        this.dataSource = new MatTableDataSource(this.mipsQuestions);  
        if(this.enable_company_mips) {
          this.mipsGraphData = response.mipsCount;
          this.createGraph();

        }
    }
    this.loader.stopLoader(this.loaderId);
    })
  }

  showMeasureNumberQuestionsDialog(measureElement) {
    const dialogRef:MatDialogRef<MeasureNumberQuestionsDialog> = this.dialog.open(MeasureNumberQuestionsDialog, {width: '80%', data: {measureElement, company_id: this.params.id}})
  }
  
  createGraph() {
    if(this.enable_company_mips) {
      this.canvas = this.barChart.nativeElement;
    this.ctx = this.canvas.getContext('2d');
    if (this.chart) {
      this.chart.destroy();
    }
    const colors: string[] = [];
    for(let data of this.mipsGraphData) {
      const red = Math.floor(Math.random() * 256);
      const green = Math.floor(Math.random() * 256);
      const blue = Math.floor(Math.random() * 256);
      const alpha = Math.random().toFixed(2); // Random alpha value between 0 and 1

      const rgbaColor = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
      colors.push(rgbaColor);
    }
    this.chart = new Chart(this.ctx, {
      type: 'bar',
      data: {
        datasets: [{
          label: 'MIPS Count',
          data: this.mipsGraphData.map(form => form.Count),
          backgroundColor: colors,
          borderColor: 'rgba(0, 0, 0, 1)',
          borderWidth: 1
        },
        ],
        labels: this.mipsGraphData.map(form => form.mips_code)
      },
      options: {
        onClick: (event: ChartEvent, activeElements: any[]) => {
          if (activeElements.length > 0) {
            const clickedElement = activeElements[0];
            const datasetIndex = clickedElement.datasetIndex;
            const index = clickedElement.index;
            const dialogRef:MatDialogRef<MeasureNumberQuestionsDialog> = this.dialog.open(MeasureNumberQuestionsDialog, {width: '80%', data: {mipsData: this.mipsGraphData[index], type:'detail', company_id: this.params.id}});
          }
        }
      }
    });
    }
    
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }
  handleAddQuestions(){ 
    const dialogRef:MatDialogRef<AddMipsQuestionComponent> = this.dialog.open(AddMipsQuestionComponent, {width: '80%',data: { company_id: this.params.id}})
    dialogRef.afterClosed().subscribe(async (mipsQuestion) => {
      if (!mipsQuestion || (mipsQuestion && (mipsQuestion.measure_number.length === 0 || mipsQuestion.question.length === 0) )) {
          return;
      }
      const response:any = await this.mipsService.addQuestion(mipsQuestion.measure_number, null,  mipsQuestion.question, mipsQuestion.answer, this.params.id, mipsQuestion.condition, mipsQuestion.data_source, mipsQuestion.performance_type, mipsQuestion.modifier).toPromise();

      if(response.status === 200) {
        this.initMipsQuestions()
      }
  });
}

}
