<div class="container">
    <mat-card class="container-height flex flex-col gap-1">
        <div class="flex justify-start items-center gap-1">
            <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">Back</button>

            <mat-card class="search-box">
                <input matInput type="text" class="search-field" [(ngModel)]="filter.note_text"
                    (keyup.enter)="searchNoteText();" placeholder="Search Note..." name="note_text" />
                <mat-icon [class.hidden]="isOverlayOpen" class="pointer" (click)="toggleOverlay();" type="button"
                    cdkOverlayOrigin #trigger="cdkOverlayOrigin">
                    history
                </mat-icon>
                <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger"
                    [cdkConnectedOverlayOpen]="isOverlayOpen" [cdkConnectedOverlayHasBackdrop]="true"
                    [cdkConnectedOverlayPositions]="positionPairs"
                    cdkConnectedOverlayBackdropClass="no-style-class-to-hide-backdrop"
                    (backdropClick)="isOverlayOpen=false;">
                    <app-note-text-search-history *ngIf="isOverlayOpen"
                        (searchNoteText)="filter.note_text=$event;searchNoteText();"></app-note-text-search-history>
                </ng-template>
            </mat-card>


            <mat-form-field appearance="fill" class="flex-grow settings">
                <mat-label>Select Facility</mat-label>
                <div class="flex justify-start items-center gap-1">
                    <img *ngIf="selectedFacility" class="facility-icon"
                        [src]="selectedFacility.source === 'PointClickCare' ? '../../../../../../assets/icons/pcc-icon.svg'
            : (selectedFacility.source === 'MatrixCare' ? '../../../../../../assets/icons/matrix.png' : '../../../../../../assets/img/DN.png')" alt="">
                    <input type="text" placeholder="Select Facility" aria-label="facility" matInput
                        [formControl]="facilityControl" (input)="onChangeFacilityText($event);"
                        [matAutocomplete]="facilityAutoControl">
                    <mat-icon class="pointer" matDatepickerToggleIcon
                        (click)="clearFacilityField($event);">clear</mat-icon>
                </div>
                <mat-autocomplete #facilityAutoControl="matAutocomplete" autoActiveFirstOption>
                    <mat-option (onSelectionChange)="onSelectFacility($event, facility);"
                        *ngFor="let facility of filteredFacilities | async" [value]="facility.title">
                        <div class="flex justify-start items-center gap-1">
                            <img [src]="facility.source === 'PointClickCare' ? '../../../../../../assets/icons/pcc-icon.svg'
                        : (facility.source === 'MatrixCare' ? '../../../../../../assets/icons/matrix.png' : '../../../../../../assets/img/DN.png')"
                                class="facility-icon" alt="">
                            <span>{{facility.title}}</span>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <button mat-raised-button class="dn-button-primary" [disabled]="!filter.note_text"
                (click)="searchNoteText();">Search</button>

            <div class="flex justify-end items-center gap-1">
                <button mat-raised-button class="dn-button-primary" (click)="handlePageNavigation(false)"
                    [disabled]="!pagination.hasPrevPage"> Prev</button>

                <span class="no-wrap">
                    {{pagination.skip + extractedNoteTextArray.length === 0 ? 0 :
                    pagination.skip + 1 }} -
                    {{pagination.skip +
                    extractedNoteTextArray.length}} out of {{pagination.totalRecords}}
                </span>
                <button mat-raised-button class="dn-button-primary" (click)="handlePageNavigation(false)"
                    [disabled]="!pagination.hasNextPage">Next</button>
            </div>
        </div>


        <div class="table-height">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                <ng-container matColumnDef="first_name">
                    <th mat-header-cell *matHeaderCellDef> First Name </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.patient_id.first_name}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="last_name">
                    <th mat-header-cell *matHeaderCellDef> Last Name </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.patient_id.last_name}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="facility_id">
                    <th mat-header-cell *matHeaderCellDef> Facility </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.facility_id.title}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="gender">
                    <th mat-header-cell *matHeaderCellDef> Gender </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.patient_id.gender}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="date_of_birth">
                    <th mat-header-cell *matHeaderCellDef> Date Of Birth </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.patient_id.date_of_birth}}
                    </td>
                </ng-container>


                <ng-container matColumnDef="date_of_service">
                    <th mat-header-cell *matHeaderCellDef> Date Of Service </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.note_id.date_obj}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        <div class="flex gap-1">
                            <mat-icon style="cursor: pointer" matTooltip="Details" matTooltipPosition="above"
                                (click)="goToPatientDetailScreen(element.patient_id._id);">remove_red_eye</mat-icon>
                            <i class="icon icon-file" matTooltip="Note" matTooltipPosition="above"
                                (click)=" viewNoteDialog(element.note_id._id);"></i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
        </div>

    </mat-card>
</div>