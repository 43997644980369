import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment'
import * as global from '../../../../global';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FacilityService } from 'src/app/services/facility.service';
import { Location } from '@angular/common';
import { WoundService } from 'src/app/services/wound.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { SnfWcNurseAssociationService } from 'src/app/includes/snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { NursingService } from 'src/app/includes/nursing-module/nursing.service';
import { WoundNurseRelationService } from 'src/app/services/Modules/woundNurse.service';
import { CommonService } from 'src/app/services/common.service';
import { WoundCareWidgetService } from 'src/app/includes/note-sidebar/wound-care-widget/wound-care-widget.service';
import { MatDialog } from '@angular/material/dialog';
import { SaveReportDialogComponent } from './save-report-dialog/save-report-dialog.component'
import { CustomWoundReportService } from './custom-wound-report.service';
import { DialogConfirmationComponent } from 'src/app/includes/census-patient-list/dialog-confirmation/dialog-confirmation.component';

@Component({
  selector: 'app-custom-wound-report',
  templateUrl: './custom-wound-report.component.html',
  styleUrls: ['./custom-wound-report.component.css']
})
export class CustomWoundReportComponent implements OnInit, OnDestroy {
  loaderId = 'app-custom-wound-report';
  filter: any = {
    is_deleted: false
  };
  displayedColumns: string[] = [];
  facilityControl = new FormControl();
  facilities: Array<any> = [];
  currentUser: any;
  companySide: boolean;
  nursingSide: boolean;
  isUserDashboard: Boolean = false;
  isSnfNurse: boolean = false;
  isNurse: boolean = false;
  isWoundNurse: boolean = false;
  dialogSubscription: Subscription;
  customWoundReports: any = [];
  columns: any = [];
  project: any = {};
  companyId: any;
  shouldShowDataOptions: boolean = false;
  dataOption: any;

  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  reports: any = [];

  constructor(
    private router: Router,
    private _toastr: ToastrService,
    private _route: ActivatedRoute,
    private _location: Location,
    private _woundService: WoundService,
    private loader: NgxUiLoaderService,
    private _authService: AuthService,
    private dialog: MatDialog,
    private _customWoundReportService: CustomWoundReportService

  ) {
    this.currentUser = this._authService.currentUser;
    this.companyId = this._route.parent.snapshot.params.id;
    if (this._route.parent.snapshot.params.id) {
      this.companySide = true;
    } else {
      this.companyId = this.currentUser.company_id;
    }
    if (window.location.pathname.includes('dashboard')) {
      this.isUserDashboard = true;
    }
    if (window.location.pathname.includes('nursing')) {
      this.nursingSide = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      this.isSnfNurse = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.NURSE) {
      this.isNurse = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
      this.isWoundNurse = true;
    }

  }

  async ngOnInit(): Promise<void> {
    this.loader.startLoader(this.loaderId);
    await this.initCustomWoundReport();
    this.loader.stopLoader(this.loaderId);
  }
  ngOnDestroy(): void {
    if (this.dialogSubscription)
      this.dialogSubscription.unsubscribe();
  }

  goBack() {
    this._location.back();
  }

  async initCustomWoundReport() {
    const filter = {
      is_deleted: false,
      user_id: this.currentUser._id
    };
    const response: any = await lastValueFrom(this._customWoundReportService.getCustomWoundReport(filter, {}));
    if (response.status === 200) {
      this.customWoundReports = response.data;
      this.reports = this.customWoundReports;
      // this.handlePagination();
    }
    else {
      this._toastr.error(response.message, 'Error');
    }
  }

  updateData(customWoundReport) {
    customWoundReport.filter = JSON.parse(customWoundReport.filter);
    customWoundReport.project = JSON.parse(customWoundReport.project);
    this.customWoundReports.push(customWoundReport);
    this.reports = this.customWoundReports;
    this.dataOption = null;
  }

  async updateCustomWoundReport(index: number, customWoundReportId, is_favourite: boolean, is_deleted = false) {
    this.loader.startLoader(this.loaderId);
    const filter = {
      _id: customWoundReportId
    };

    const updateQuery = {
      $set: {
        is_favourite,
        is_deleted
      }
    };
    const response: any = await lastValueFrom(this._customWoundReportService.updateCustomWoundReport(filter, updateQuery));
    if (response.status === 200) {
      const customWoundReport = response.data;
      if (customWoundReport.is_deleted)
        this.customWoundReports.splice(index, 1);
      else
        this.customWoundReports[index] = customWoundReport;
      this.reports = this.customWoundReports;
      this._toastr.success(response.message, 'Success');
    }
    else {
      this._toastr.error(response.message, 'Error');
    }
    this.loader.stopLoader(this.loaderId);
  }

  showDataOptions(customWoundReport) {
    this.dataOption = {
      filter: typeof customWoundReport.filter === "string" ? JSON.parse(customWoundReport.filter) : customWoundReport.filter,
      project: typeof customWoundReport.project === "string" ? JSON.parse(customWoundReport.project) : customWoundReport.project
    };
    this.shouldShowDataOptions = true;
  }

  showSavedReports(value: boolean) {
    this.shouldShowDataOptions = value;
    this.dataOption = null;
  }

  filterReports(value) {
    if (this.reports.length > 0) {
      if (value === "favourite") {
        this.customWoundReports = this.reports.filter(customWoundReport => customWoundReport.is_favourite);
      }
      else {
        this.customWoundReports = this.reports;
      }
    }
  }

  searchReports(value) {
    if (!value) {
      this.customWoundReports = this.reports;
    }
    else {
      const regex = new RegExp(value, "i");
      this.customWoundReports = this.customWoundReports.filter(customWoundReport => regex.test(customWoundReport.title));
    }
  }
  openDeleteDialog(index: number, customWoundReportId, is_favourite: boolean, is_deleted = false) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent,{
      data: {
        message: "Are you sure you want to delete this custom wound report?"
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result === "true") {
        this.updateCustomWoundReport(index, customWoundReportId, is_favourite, is_deleted )
      }
  });
  }
}
