import { Component, Input, Output, EventEmitter, HostListener, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-procedure-note-dropdown',
  templateUrl: './procedure-note-dropdown.component.html',
  styleUrls: ['./procedure-note-dropdown.component.css']
})
export class ProcedureNoteDropdownComponent {
  @Input() options: { value: string }[] = [];
  @Input() label: string = '';
  @Input() multiselect: boolean = false;
  @Input() default_value: string = "";
  @Input() selectedOptions: string[] = [];
  @Input() categorySelected: boolean;

  @Output() optionSelected: EventEmitter<string | string[]> = new EventEmitter<string | string[]>();

  userInteracted: boolean = false;

  showDropdown: boolean = false;
  initialRender: boolean = false;

  static openDropdowns: ProcedureNoteDropdownComponent[] = [];

  ngOnInit(): void {
    this.setDefaultValue();
  }
  ngOnChanges(): void {
    this.setDefaultValue();
  }

  setDefaultValue() {
    if (!this.userInteracted && this.categorySelected && this.selectedOptions.length === 0 && this.default_value) {
      this.selectedOptions.push(this.default_value);
      this.optionSelected.emit(this.selectedOptions);
    }
  }

  toggleDropdown(): void {
    this.showDropdown = !this.showDropdown;

    if (this.showDropdown) {
      ProcedureNoteDropdownComponent.closeOtherDropdowns(this);
      ProcedureNoteDropdownComponent.openDropdowns.push(this);
    } else {
      ProcedureNoteDropdownComponent.openDropdowns = ProcedureNoteDropdownComponent.openDropdowns.filter(
        dropdown => dropdown !== this
      );
    }
  }

  selectOption(option: string): void {
    this.userInteracted = true;
    if (!this.multiselect) {
      if (this.selectedOptions.length === 1 && this.selectedOptions[0] === option) {
        this.selectedOptions = [];
      } else {
        this.selectedOptions = [option];
      }
      this.showDropdown = false;
    } else {
      const index = this.selectedOptions.indexOf(option);
      if (index === -1) {
        this.selectedOptions.push(option);
      } else {
        this.selectedOptions.splice(index, 1);
      }
    }
    this.optionSelected.emit(this.selectedOptions);
  }

  static closeOtherDropdowns(currentDropdown: ProcedureNoteDropdownComponent): void {
    ProcedureNoteDropdownComponent.openDropdowns
      .filter(dropdown => dropdown !== currentDropdown)
      .forEach(dropdown => (dropdown.showDropdown = false));
  }
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.showDropdown) return;
    const targetElement = event.target as HTMLElement;
    if (!targetElement.closest('.custom-dropdown')) {
      this.showDropdown = false;
    }
  }
}
