import { Payor } from "./payor";

export class DoctorFeeStructure {
    user_id_ref:string|object;
    user_type:'Personal'|'Business'|'';

    ime_company_ref: string|Payor;
    doctor_rate: string;
    ime_rate: string;
    negotiated_rate: string;
    page_size: string;
    doctor_cut: string;
    pkpr_cut: string;
    service_addendum: string;
    service_no_show: string;
    service_rush_report: string;
    excessive_record_fee_100_250: string;
    excessive_record_fee_250_500: string;
    excessive_record_fee_500_plus: string;
    excessive_record_deposition_fee_phone: string;
    deposition_fee_court_half_day: string;
    deposition_fee_court_full_day: string;
    deposition_fee_court_peer_review: string;
    deposition_fee_court_file_review: string;
}