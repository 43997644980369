

  import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
  import { ToastrService } from 'ngx-toastr';
  import { AuthService } from 'src/app/services/auth.service';
  import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
  import { PatientRoundingSheetService } from '../patient-rounding-sheet.service';
  import { PatientRoundingSheetDialog } from '../create-patient-rounding-sheet-dialog/create-patient-rounding-sheet-dialog';
  import * as global from '../../global'
  import { NgxUiLoaderService } from 'ngx-ui-loader';
  import moment from 'moment';
  import { PatientListService } from '../../patient-list/patient-list-service';
  import { RecurrentOptionDialogComponent } from '../../recurrent-option-dialog/recurrent-option-dialog.component';
  import { CensusRecurrentService } from 'src/app/services/censusRecurrent.service';
  
  export interface PatientImporterRecurrentComponentData {
    patient: any;
    patients: any;
    action: string;
    only_pcc: boolean;
    obj: any,
    oldCensusId: any,
    date_obj:any,
    from_pcc_patient:any
  }
  
  @Component({
    selector: 'app-patient-importer-recurrent',
    templateUrl: './patient-importer-recurrent.component.html',
    styleUrls: ['./patient-importer-recurrent.component.css']
  })

  export class PatientImporterRecurrentComponent implements OnInit {
  
    global = global;
    currentUser: any;
    currentUserDetails: any;
    patient: any;
    patients: any;
    patientRoundingSheets: any = [];
    total: number = 0;
    selectedPatientRoundingSheet: any;
  
    action: string;
    only_pcc: boolean;
    loaderId = 'patient-importer-loader';
  
    showLimit = 5;
    oldCensusId: any;
    recurrenceMap: any[];
    date_objects: any[];
    date_obj: any;
    obj_toPass: any;
    constructor(
      @Inject(MAT_DIALOG_DATA) public data: PatientImporterRecurrentComponentData,
      private _authService: AuthService,
      private _patientRoundingSheets: PatientRoundingSheetService,
      private toastr: ToastrService,
      private dialog: MatDialog,
      private _CensusRecurrentService:CensusRecurrentService,
      private patientListService: PatientListService,
      public dialogRef: MatDialogRef<PatientImporterRecurrentComponent>,
      private loader: NgxUiLoaderService,
    ) {
      this.currentUser = this._authService.currentUser;
      this.currentUserDetails = {
        id: this.currentUser._id,
        auth_key: this.currentUser.auth_key,
        usertype: this.currentUser.user_type
      }

        const [year, month, day] = moment().format('YYYY-MM-DD').split('-');
        this.date_obj = {
          year, month, day
        }
      
      this.obj_toPass = {
        date_obj: this.date_obj,
        from_pcc_patient:true
      }
      data['date_obj']=this.date_obj;
      data['from_pcc_patient']=true;

      if (data) {
        this.patient = data.patient;
        this.patients = data.patients;
        
        
        this.getfacilitiesfromMaping(this.patient, 1);
        this.getfacilitiesfromMaping(this.patients, 2);
  
        console.log('this.patients',this.patients, data);
  
        this.action = data.action;
        this.only_pcc = !!data.only_pcc;
      }
      if (data?.obj?.oldCensusId) {
        this.oldCensusId = data.obj.oldCensusId;
      }
    }
    async getfacilitiesfromMaping(patient, type){
      let newPatients=[];
      if(!patient) return;
  
      if(type==1){
  
        await this.patientListService.getPatient_Maping_population({ patient_id: patient._id?patient._id:patient[0]._id, company_id: this.currentUser.company_id }).subscribe((res:any)=>{
          let result=res?.data;
          let pat=JSON.parse(JSON.stringify(patient));
  
          if(pat.facility_id_ref){
            pat.facility_id_ref=result[0].facility_id;
          }else{
            pat[0].facility_id_ref=result[0].facility_id;
          }
  
          this.patient=pat;
          console.log("resultttn 1", this.patient);
  
        })
      }
  
      if (type==2){
  
        for(let p of patient){
          await this.patientListService.getPatient_Maping_population({ patient_id: p._id?p._id:p[0]._id, company_id: this.currentUser.company_id }).subscribe((res:any)=>{
              let result=res?.data;
              let pat=JSON.parse(JSON.stringify(p));
    
              if(pat.facility_id_ref){
                pat.facility_id_ref=result[0].facility_id;
              }else{
                pat[0].facility_id_ref=result[0].facility_id;
              }
              newPatients.push(pat);
              this.patients=newPatients;
              console.log("resultttn 2", this.patients);
    
            })
          }
          
      }
  
    }
  
  
    ngOnInit() {
    }

    async handleAddToRecurrentCensus(){
     // await this.showDialog(true);
  
    }
  
  
  
    async handleSaveClick(data){

          if(data){
            console.log("closedd true", data);
            let recurrenceMap=data.recurrenceMap;
            let date_objects=data.date_objects;
            let census_name=data.census_name;
            let facility_id=this.data.obj.filter_facility_id;
            // return;
            await this.addRecurrentPatientRoundingSheets(facility_id,date_objects,recurrenceMap,census_name);
          }else{
            console.log("closedd false", data);
            this.recurrenceMap=[];
            this.date_objects=[];
          }
        
  
    }
  
  

    // async showDialog(e){
    //   console.log("e : ", e);
    //     let date_obj=moment().format('YYYY-MM-DD').split('-');
    //     let date_obj_toPass;
    //       const [year, month, day] = date_obj;
    //       date_obj_toPass = {
    //         year, month, day
    //       }
        
    //     const obj = {
    //       //...this.data,
    //       date_obj: date_obj_toPass,
    //       from_pcc_patient:true
    //     }
    //     const dialogRef = this.dialog.open(RecurrentOptionDialogComponent, {
    //       data: obj,
    //       width: '40%'
    //     });
  
    //     dialogRef.afterClosed().subscribe(async (data) => {
    //       if(data){
    //         console.log("closedd true", data);
    //         let recurrenceMap=data.recurrenceMap;
    //         let date_objects=data.date_objects;
    //         let census_name=data.census_name;
    //         let facility_id=this.data.obj.filter_facility_id;
  
    //         await this.addRecurrentPatientRoundingSheets(facility_id,date_objects,recurrenceMap,census_name);
    //       }else{
    //         console.log("closedd false", data);
    //         this.recurrenceMap=[];
    //         this.date_objects=[];
    //       }
    //     });
  
    // }
  
  
    async addRecurrentPatientRoundingSheets(facility_id, date_objects, recurrence_map,census_name) {
  
      let date_obj=moment().format('YYYY-MM-DD').split('-');
      let date_obj_toPass;
        const [year, month, day] = date_obj;
        date_obj_toPass = {
          year, month, day
        }
        let patientList;
        let not_validPatients;
        if (this.patient) {
          let validPatient=this.patient?this.patient._id:this.patient[0]._id;
          if(validPatient.facility_id_ref._id === this.data?.obj?.filter_facility_id){
          }
          patientList=validPatient;
        } else if (this.patients) {
  
          // patientList=this.patients.map((p:any) => { return p?p._id:p[0]._id});
          let validPatients = this.patients.filter(p => p.facility_id_ref?(p.facility_id_ref._id === this.data?.obj?.filter_facility_id):(p[0].facility_id_ref._id === this.data?.obj?.filter_facility_id));
          not_validPatients = this.patients.filter(p => p.facility_id_ref?(p.facility_id_ref._id != this.data?.obj?.filter_facility_id):(p[0].facility_id_ref._id != this.data?.obj?.filter_facility_id));
          patientList = validPatients.map(p => p._id ? p._id : p[0]._id);
  
        }
        console.log("patientList : ", patientList, this.patients);
        const currentDate = new Date();
    
        date_obj_toPass.hour = currentDate.getHours();
        date_obj_toPass.minute = currentDate.getMinutes();
  
        
      const details = {
        id:this.currentUser?._id,
        doctor_id:this.currentUser?._id,
        facilityId: facility_id,
        name: census_name,
        ischargecheck: false,
        isnotecheck: false,
        parent_census_id:null,
        date_objs:date_objects,
        provider_id:this.currentUser._id,
        company_id:this.currentUser.company_id,
        recurrence_map:recurrence_map,
        patientList:patientList,
        date:currentDate,
        date_obj:date_obj_toPass
      }
   
      if(!(patientList.length)){
        this.toastr.info('All Patients are moved to another facilities.');
        this.dialogRef.close([]);
        return;
      }
      
      if((not_validPatients.length)){
        this.toastr.info(`${not_validPatients.length} Patient(s) are moved to another facilities.`);
      }
  
      if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER) {
        details.doctor_id = this.currentUser?._id;
      }
      console.log("details : :", details);
  
  
        // return;
  
      await this._CensusRecurrentService.addNewRecurrentCensusesWithParent(details).subscribe(async (response: any) => {
        if (response.status === 200) {
          // this.toastr.clear(infoToast.toastId);
          this.toastr.success(`${patientList.length} Patient(s) Added Successfully`);
           this.dialogRef.close(response.data);
          console.log("res", response);
    
        }
      });
    }
  
  
  
  
  }
  