
<ng-container *ngIf="!is_company_side" >
  <app-header></app-header>
</ng-container>

<div class="flex parent_class">

  <div *ngIf="false" [hidden]="!show_card_view" class="child_one_class ">
    <div class="close_button">
      <mat-icon style="    margin-right: 10px;margin-top: 10px;cursor: pointer;"
        (click)="show_card_view=!show_card_view">
        close
      </mat-icon>
    </div>
    <app-census-list [detect_refresh_child]="detect_refresh_child"></app-census-list>
  </div>
  <div *ngIf="false" (click)="show_card_view=!show_card_view" [ngClass]="{'Mid_child1': show_card_view, 
  'Mid_child': !show_card_view}">
    <mat-icon *ngIf="!show_card_view" class="arrow_icon_class">
      menu
    </mat-icon>
  </div>
  <div *ngIf="false" class="child_two_class">
    <ng-container *ngIf="!error2LeggedAuthFlag">
      <div *ngIf="params.id=='null'">
        <p class="no_census_available">Please create a new Census on the left to view details.</p>
      </div>
    </ng-container>
    <div *ngIf="error2LeggedAuthFlag">
      <p class="no_census_available">{{error2LeggedAuth}}</p>
    </div>

  </div>
  <mat-sidenav-container>

    <mat-sidenav mode="side" *ngIf="displayCommentSidenav && checkVoidCensus()" [(opened)]="displayCommentSidenav" position="end">
      <app-census-comment (recentComment)="getCensusRecentComment($event)" (closeSideNav)="displayCommentSidenav=$event"
        [patient]="currentPatient">
      </app-census-comment>
    </mat-sidenav>

    <main>

      <div class="flex-grow-1 flex-shrink-1 gap-2">

        <div class="wrapper flex flex-col gap-2">

          <div style="width: calc(100vw - 35px)" class="census-header flex justify-between items-start gap-2">

            <button class="dn-button-cenus" mat-raised-button (click)="goBack()">
              Go Back
            </button>
            <div class="flex flex-grow gap-2">
              <ng-container *ngIf="census">
                <mat-card style="padding-top: 0; padding-bottom: 0; height: 40px;" *ngIf="currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER">
                  <mat-card-content style="padding-top: 0; padding-bottom: 0; overflow: hidden;">
                  <mat-form-field style="width: 90px; height: 68px; margin-top: -8px;">
                    <mat-label>Source</mat-label>
                    <mat-select [value]="patientsImportSource"  (selectionChange)="sourceChange($event.value)">
                      <mat-option  *ngFor="let source of sources" [value]="source.value" >
                        {{source.label}}
                      </mat-option> 
                    </mat-select>
                  </mat-form-field>
                  </mat-card-content>
                </mat-card>

                <ng-container *ngIf="patientsImportSource === 'dn' && currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER">
                  <mat-card style="padding-top: 0; height: 40px; padding-bottom: 0;" class="flex-grow">
                    <mat-card-content style="padding-top: 0; padding-bottom: 0; overflow: hidden;">
                      <mat-form-field style="width: 100%; height: 68px; margin-top: -8px; font-size: 10px;">
                        <mat-label>Search and add Patients</mat-label>
                        <input matInput type="text" name="patientsByProvider" [formControl]="patientsByProviderControl"
                          [matAutocomplete]="patientAutoComplete" />
                        <mat-autocomplete #patientAutoComplete="matAutocomplete" (optionSelected)="
                    handleAddPatient($event.option.value)
                    " [displayWith]="displayPatientFn">
                              <mat-option  class="census_dropdown_search" *ngFor="let patient of filteredPatientsByProvider | async" [value]="patient">
                                <div class="flex items-start justify-center gap-2">
                                  <div>
                                    <app-patient-name [patient]="patient"></app-patient-name>
                                  </div>
                                  <div class="flex-grow"></div>
                                  <div class="items-end justify-center">
                                    <button mat-raised-button class="dn-button-primary add_to_census_btn"
                                            style="background-color: #FFF; color: #43adff;"
                                            (click)="$event.preventDefault();"
                                            *ngIf="!selectedPatientForImport(patient) && checkVoidCensus()">
                                      Add to Census
                                    </button>
                                  </div>
                                </div>
                              </mat-option>    
                        </mat-autocomplete>
                      </mat-form-field>
                      <ngx-ui-loader [loaderId]="patientsSearchLoaderId"></ngx-ui-loader>
                    </mat-card-content>
                  </mat-card>
                </ng-container>
                <ng-container *ngIf="patientsImportSource ===  'MatrixCare' ">
                  <mat-card style="padding-top: 0; height: 40px; padding-bottom: 0;" class="flex-grow">
                    <mat-card-content style="padding-top: 0; padding-bottom: 0; overflow: hidden;">
                      <mat-form-field style="width: 100%; height: 68px; margin-top: -8px; font-size: 10px;">
                        <mat-label>Search and add Patients</mat-label>
                        <input matInput type="text" name="patientsByProvider" [formControl]="patientsByProviderControl"
                          [matAutocomplete]="patientAutoComplete" />
                        <mat-autocomplete #patientAutoComplete="matAutocomplete" (optionSelected)="
                        handleMatrixAddToCensus($event.option.value)
                    " [displayWith]="displayPatientFn">
                              <mat-option  class="census_dropdown_search" *ngFor="let patient of filteredPatientsByProvider | async" [value]="patient">
                                <div class="flex justify-start items-center gap-2">
                                  <div>
                                    <app-patient-name [patient]="patient"></app-patient-name>
                                  </div>
                                  <div class="flex-grow"></div>
                                  <div class="items-end justify-center">
                                    <button mat-raised-button class="dn-button-primary add_to_census_btn"
                                            style="background-color: #FFF; color: #43adff;"
                                            (click)="$event.preventDefault();"
                                            *ngIf="!selectedPatientForImport(patient) && checkVoidCensus()">
                                      Add to Census
                                    </button>
                                  </div>
                                </div>
                              </mat-option>    
                        </mat-autocomplete>
                      </mat-form-field>
                      <ngx-ui-loader [loaderId]="patientsSearchLoaderId"></ngx-ui-loader>
                    </mat-card-content>
                  </mat-card>
                </ng-container>
                <ng-container class="87786" *ngIf="patientsImportSource === 'pcc'">
                  <mat-card style="
                flex:auto;
                padding-top: 0;
                padding-bottom: 0;
                height: 40px;
                position: relative;
              "
              *ngIf="currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER">
                    <mat-card-content style="padding-top: 0; padding-bottom: 0; overflow: hidden;">
                      <mat-form-field style="width: 100%; height: 68px; margin-top: -8px; font-size: 10px;">
                        <mat-label>Search and add PCC Patients</mat-label>
                        <input matInput type="text" name="pccPatients" [formControl]="pccPatientsControl"
                          [matAutocomplete]="pccPatientAutoComplete" #pccPatientSearch />
                        <mat-autocomplete #pccPatientAutoComplete="matAutocomplete" (optionSelected)="
                      handleImportPatient($event.option.value, true)
                    " [displayWith]="displayPatientFn">
                                <mat-option  class="census_dropdown_search" *ngFor="let patient of filteredPccPatients | async" [value]="patient">
                                  <div class="flex justify-between items-center">
                                    <app-patient-name [patient]="patient"></app-patient-name>
                                    <div class="flex justify-end gap-2">
                                      <button mat-raised-button class="dn-button-primary add_to_census_btn" style="background-color: #FFF; color: #43adff;"
                                        (click)="$event.preventDefault();" *ngIf="!selectedPatientForImport(patient) && checkVoidCensus()">
                                        Add to Census
                                      </button>
                                      <button mat-raised-button class="dn-button-primary"
                                        (click)="$event.stopPropagation();togglePatientFromQueueForImport($event, patient)"
                                        *ngIf="!selectedPatientForImport(patient) && checkVoidCensus()">
                                        Add to Queue (multiple patients)
                                      </button>
                                      <button mat-raised-button class="dn-button-secondary"
                                        (click)="$event.stopPropagation();togglePatientFromQueueForImport($event, patient)"
                                        *ngIf="selectedPatientForImport(patient) && checkVoidCensus()">
                                        Remove from Queue
                                      </button>
                                    </div>
                                  </div>
                                </mat-option>
                                
                        </mat-autocomplete>
                      </mat-form-field>
                      <ngx-ui-loader [loaderId]="patientsSearchLoaderId"></ngx-ui-loader>
                    </mat-card-content>
                  </mat-card>
                  <button mat-raised-button class="dn-button-primary" style="margin-bottom: 10px;"
                    *ngIf="selectedPatients.length > 0 && checkVoidCensus()" (click)="importMultiplePatients()">Import
                    {{selectedPatients.length}}
                    Patients</button>
                  <mat-card style="padding-top: 0; padding-bottom: 0; height: 40px;" *ngIf="currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER">
                    <mat-card-content style="padding-top: 0; padding-bottom: 0; overflow: hidden;">
                      <mat-form-field style="width: 120px; height: 68px; margin-top: -8px;">
                        <mat-select [(ngModel)]="searchPatientStatus"
                          (ngModelChange)="doInitPccPatients(pccPatientSearch.value)">
                          <mat-option disabled value="">Patient Status</mat-option>
                          <mat-option *ngFor="let status of patientStatuses" [value]="status">{{status}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </mat-card-content>
                  </mat-card>
                </ng-container>

              </ng-container>
            </div> 
            <button *ngIf="patientsImportSource == 'dn' && checkVoidCensus() && currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER" class="dn-button-primary" mat-raised-button (click)="addPatientForm()">
              Add Patient
            </button>

            <button *ngIf="isOwner(census) && checkVoidCensus()" class="dn-button-censusupload" mat-raised-button (click)="uploadFileDialoge()">
              Upload File
            </button>

            <span *ngIf="census && currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER" class="heading-margin">(<span style="cursor: pointer;"
                (click)="$event.preventDefault(); viewRehabDocs(census._id)">{{census.rehab_docs_count}}</span>)</span>
          </div>

          <div class="census-content flex gap-2">
            <div class="flex flex-col gap-2 flex-grow">
              <div style="color: #fff" class="flex justify-between">
                <div *ngIf="census">DOS: {{ getDOS(census) }}</div>
                <div>{{ census?.name }}</div>
                <div>Active Patients: {{ census?.patientList?.length }}</div>
                <div>I/E: {{ getPatientsCounts('new') }}</div>
                <div>F/U: {{ getPatientsCounts('followUp') }}</div>
                <div class="flex justify-center gap-1">
                
                  <app-facility-title [facility]="census?.facility"></app-facility-title>
                </div>

                <div *ngIf="false" class="flex justify-start items-center">

                  <span matTooltip="Download" style="cursor: pointer;" [ngClass]="{'disable': !isMedications}">
                    <img src="../../../assets/img/down-arrow.png" style="width: 20px; opacity: .8;padding-right: 10px;"
                      (click)="printCensusPatientList()" />
                  </span>
                </div>

                <span style="display: inline-flex;">
                  
                  <mat-icon
                  *ngIf="currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER"
                    matTooltip="Download"
                    style="cursor: pointer; margin-right: 15px;"
                    [matMenuTriggerFor]="helpMenud"
                  >download</mat-icon>
                  <mat-menu #helpMenud="matMenu" xPosition="before">
                    <button mat-menu-item (click)="printCensusPatientList()">Download Census Report</button>
                    <button mat-menu-item (click)="printCensusWoundPatientList()"  *ngIf="this.currentUser.company_type != global.COMPANY_TYPE.NURSING">Download Wound Report</button>
                  </mat-menu>
                  <mat-icon *ngIf="!is_company_side && currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER" matTooltip="PDF Settings" style="cursor: pointer; margin-right: 15px;"
                    [routerLink]="['/user-settings',{census: census?._id},'census']">settings</mat-icon>
                  <mat-icon *ngIf="is_company_side" matTooltip="PDF Settings" style="cursor: pointer; margin-right: 15px;"
                    [routerLink]="['/company', current_company_id, 'company-notifications-settings', { census: census?._id },'census']">settings</mat-icon>
                  <ng-container *ngIf="isOwner(census)">
                    <mat-icon *ngIf="!reloadingCensus" (click)="reloadCensus()"
                      matTooltip="Reload Census & Update PCC Info" style="margin-right: 15px;">
                      refresh</mat-icon>
                    <mat-progress-spinner matTooltip="Reloading Census" diameter="20" *ngIf="reloadingCensus"
                      mode="indeterminate"></mat-progress-spinner>
                  </ng-container>
                  <mat-icon
                    matTooltip="Help"
                    style="cursor: pointer;"
                    [matMenuTriggerFor]="helpMenu"
                  >help</mat-icon>
                  <mat-menu #helpMenu="matMenu" xPosition="before">
                    <button mat-menu-item (click)="watchHelpVideo('census-view-sample-initial-eval-video')">Watch Sample Initial Eval Video</button>
                    <button mat-menu-item (click)="watchHelpVideo('census-view-followup-video')">Watch Follow Up Video</button>
                  </mat-menu>
                </span>
              </div>
              <div>
                <div class="flex gap-2 flex-grow">
                    <app-input-with-dropdown style="width: 100%;" placeholder="Search Patient by Name"
                      (inputChange)="applyFilter($event.target.value)" [dropdownActive]="filterDropdownActive"
                      (onDropdownChange)="filterDropdownActive = $event" [value]="filterSearch" [noDropdown]="true">
                    </app-input-with-dropdown>
                    <button *ngIf="checkVoidCensus() && this.currentUser.company_type != global.COMPANY_TYPE.NURSING" class="dn-button-primary" mat-raised-button (click)="addPatientswithActiveWounds()" [disabled]="currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER"> Add Patients with Active Wounds </button>                    
                    <button *ngIf="checkVoidCensus()" class="dn-button-primary" mat-raised-button (click)="deleteAllPatients()" [disabled]="currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER"> Delete
                      Discharged
                      Patients
                    </button>
                    <button *ngIf="checkVoidCensus()" class="dn-button-primary" mat-raised-button (click)="deleteMovedPatients()" [disabled]="currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER"> Delete
                      Moved
                      Patients
                    </button>
                    <button *ngIf="selectedPatientsToImport.length > 0 && checkVoidCensus()" class="dn-button-primary" mat-raised-button
                      (click)="importPatientDialoge()">
                      Add to Census
                    </button>
                    <button *ngIf="selectedPatientsToImport.length > 0 && isUserLoggedIntoPCC" class="dn-button-primary" mat-raised-button
                      (click)="uploadWoundReportsinBulk()">
                      Bulk Upload Wound Reports
                    </button>
                </div>
              </div>
              <div>
                <div *ngIf="census" class="flex gap-2" style="height: calc(100vh - 220px);" class="roundingsheet-patient-list-table-wrapper">
                  <table *ngIf="isActivePatientList" class="table-row-hover roundingsheet-patient-list-table w-4/5" mat-table
                    [dataSource]="dataSource" cdkDropList class="census-header-color"
                    (cdkDropListDropped)="onListDrop($event)" (matSortChange)="sortData($event)" matSort matSortDisableClear 
                  >                  
                    <ng-container matColumnDef="switch-status">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="switch-status">
                        <span class="material-symbols-outlined sort-icon"> sort </span>
                        Status
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <mat-slide-toggle 
                          (change)="markPatientSeen(element, !isPatientSeenInCensus(element))" 
                          [checked]="element.isSeen" 
                          [disabled]="!checkVoidCensus() || (CensusCopiedorNot() && checkPatienMovedorNot(element)) || (currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER)" 
                          [class.isSeen]="element.isSeen"
                          [class.isNotSeen]="!element.isSeen">
                      </mat-slide-toggle>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="patient-consent-status">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="patient-consent-status">
                        CCM Consent
                      </th>
                      <td mat-cell *matCellDef="let element; let i = index">
                        <div [ngClass]="{'activeColor': element.is_patient_consent == true}">
                          <span class="material-symbols-outlined" (click)="changePatientConsent(element, $event, i)"
                            style="cursor: pointer;" matTooltip="{{element.patientConsentToolTip}}" matTooltipPosition="above">
                            inventory
                          </span>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="is_patient_pcm_consent">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="is_patient_pcm_consent">
                        PCM Consent
                      </th>
                      <td mat-cell *matCellDef="let element; let i = index">
                        <div [ngClass]="{'activeColor': element.is_patient_pcm_consent == true}">
                          <span class="material-symbols-outlined" (click)="changePatientPCMConsent(element, $event, i)"
                            style="cursor: pointer;" matTooltip="{{element.patientPCMConsentToolTip}}" matTooltipPosition="above">
                            inventory
                          </span>
                        </div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="name">
                        <span class="material-symbols-outlined sort-icon"> sort </span>
                        Name
                      </th>
                      <td mat-cell *matCellDef="let element; let i = index" cdkDrag>
                        <img  *ngIf = "isMatrixFacilitytable(element)" src="../../../assets/icons/matrix.png" 
                          style="height: 12px;
                          width: 20px;  
                          margin-right: 5px;
                          vertical-align: middle;"
                          alt="">
                          <div class="flex flex-col gap-2">
                            <app-patient-title [patient]="element" [facility]="element.facility_id_ref"
                              (click)="onRowClick($event, element._id)"></app-patient-title>
                            <div *ngIf="isTagsDisplayAllowed">
                              <div class="flex gap-1">
                          
                                <ng-container *ngIf="element.tag_ids_ref.length >= 1">
                                  <span (click)="showTagsToaster();addNewTag(i, element)" class="addTags">
                                    <mat-select [(ngModel)]="element.selectedTags" (selectionChange)="handleSelectChange(element)" multiple class="tag-dropdown"
                                      (openedChange)="triggerEvent($event, element)" [style.background]="element.first_tag_color"
                                      matTooltip="{{element.first_tag_name}}" matTooltipPosition="above">
                                      <input type="text" placeholder="Search" style="padding: 15px;width: 100%;border: none;" [value]="tagSearch"
                                        (keyup)="searchTag($event)" (keydown)="$event.stopPropagation()">
                                      <mat-select-trigger>
                                        <div class="tag_name">
                                          {{element.first_tag_name}}
                                        </div>
                                      </mat-select-trigger>
                                      <mat-option class="word-container" *ngFor="let tags of tags; let i = index" [value]="tags._id"
                                        [disabled]="!tags.is_active" matTooltip="{{tags.name}}" matTooltipPosition="above">
                                        <span class="text-block" [ngStyle]="{ 'background-color': tags.color }">{{ tags.name }}</span>
                                      </mat-option>
                                    </mat-select>
                                  </span>
                                  <ng-container *ngIf="element.tag_ids_ref.length == 2">
                          
                                    <span (click)="showTagsToaster();addNewTag(i, element)" class="addTags">
                                      <mat-select [(ngModel)]="element.selectedTags" (selectionChange)="handleSelectChange(element)" multiple class="tag-dropdown"
                                        (openedChange)="triggerEvent($event, element)" [style.background]="element.second_tag_color"
                                        matTooltip="{{element.second_tag_name}}" matTooltipPosition="above">
                                        <input type="text" placeholder="Search" style="padding: 15px;width: 100%;border: none;"
                                          [value]="tagSearch" (keyup)="searchTag($event)" (keydown)="$event.stopPropagation()">
                                        <mat-select-trigger>
                                          <div class="tag_name">
                                            {{element.second_tag_name}}
                                          </div>
                                        </mat-select-trigger>
                                        <mat-option class="word-container" *ngFor="let tags of tags; let i = index" [value]="tags._id"
                                          [disabled]="!tags.is_active" matTooltip="{{tags.name}}" matTooltipPosition="above">
                                          <span class="text-block" [ngStyle]="{ 'background-color': tags.color }">{{ tags.name }}</span>
                                        </mat-option>
                                      </mat-select>
                                    </span>
                                  </ng-container>
                                </ng-container>
                          
                                <ng-container *ngIf="element.tag_ids_ref.length == 0 || element.tag_ids_ref.length == 1">
                                  <span (click)="showTagsToaster();addNewTag(i, element)" class="addTags">
                                    <mat-select [(ngModel)]="element.selectedTags" (selectionChange)="handleSelectChange(element)" multiple class="tag-dropdown"
                                      (openedChange)="triggerEvent($event, element)" placeholder="+ Tag">
                                      <input type="text" placeholder="Search" style="padding: 15px;width: 100%;border: none;" [value]="tagSearch"
                                        (keyup)="searchTag($event)" (keydown)="$event.stopPropagation()">
                                      <mat-select-trigger>
                                        <div class="tag_name">
                                          + Tag
                                        </div>
                                      </mat-select-trigger>
                                      <mat-option class="word-container" *ngFor="let tags of tags; let i = index" [value]="tags._id"
                                        [disabled]="!tags.is_active" matTooltip="{{tags.name}}" matTooltipPosition="above">
                                        <span class="text-block" [ngStyle]="{ 'background-color': tags.color }">{{ tags.name }}</span>
                                      </mat-option>
                                    </mat-select>
                                  </span>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="deleteNote">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="getRecentNoteInCensus(element)">

                          <app-note-delete-icon style="margin-left: 2px;
                      margin-right: -20px;" *ngIf="getRecentNoteInCensus(element) && checkVoidCensus()"
                            [note]="getRecentNoteInCensus(element)"
                            (click)="handleRemoveCensusNote(getRecentNoteInCensus(element),element)"></app-note-delete-icon>
                        </ng-container>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="note">
                      <th mat-header-cell *matHeaderCellDef>Note</th>
                      <td mat-cell *matCellDef="let element">

                        <ng-container *ngIf="getRecentNoteInCensus(element)">
 
                          <app-note-status-icon [note]="getRecentNoteInCensus(element)" [census] = 'census' [patient] = 'element'
                            (click)="showViewNoteDialog(getRecentNoteInCensus(element))">
                          </app-note-status-icon>
                        </ng-container>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="discharge">
                      <th mat-header-cell *matHeaderCellDef class="no-wrap-text">DD / RD</th>
                      <td mat-cell *matCellDef="let element">
                        
                        <div *ngIf="element.isPatientDischarged && (element.source == 'PointClickCare' || element.source == 'MatrixCare'); else readmittedBlock" class="flex items-center justify-center">
                          <p class="discharge-date-row">{{element.last_discharge_date_obj ? (getDischargeDate(element)) : ''}}</p>
                          <span class="circled-letter-icon" style="color:#d22225;border-color:#d22225">D</span>
                        </div>

                        <ng-template #readmittedBlock>
                          <div *ngIf="element.isPatientReadmitted" class="no-wrap-text flex flex-row items-center justify-center gap-1">
                            <p>{{element.last_admit_date_obj.month}}/{{element.last_admit_date_obj.day}}/{{element.last_admit_date_obj.year}}</p>
                            <span class="circled-letter-icon" style="color:green;border-color:green">R</span>
                          </div>
                        </ng-template>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="skin_sub_referral">
                      <th mat-header-cell *matHeaderCellDef>Skin Sub Referral</th>
                      <td mat-cell *matCellDef="let element; let i = index">
                        <div [ngClass]="{'activeColor': element.skin_relation ==  true}" style="width: 52px;">
                          <span class="material-symbols-outlined" (click)="updatePatientSkinSubRefferal(element, $event, i)" style="cursor: pointer;"
                            matTooltip="Skin Sub Refferal" matTooltipPosition="above">
                            add_alert
                          </span>
                        </div>
                      </td>
                    </ng-container>
            
                    <ng-container matColumnDef="date_of_birth">
                      <th mat-header-cell *matHeaderCellDef>DOB</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.date_of_birth | date: 'M/d/yyyy' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="pcc_patient_diagnosis">
                      <th mat-header-cell *matHeaderCellDef>PDX</th>
                      <td matTooltip={{element.pcc_primaryDiagnosis?.icd10Description}} mat-cell
                        *matCellDef="let element">
                        <span *ngIf="element.source !== 'MatrixCare'">
                          {{element.pcc_primaryDiagnosis?.icd10Description?element.pcc_primaryDiagnosis?.icd10Description.substr(0,20)+'...':''}}
                        </span>
                        <span *ngIf="element.source == 'MatrixCare'">
                          {{element.matrix_diagnosis?(element.matrix_diagnosis[0] && element.matrix_diagnosis[0].diagnosis_code_text? element.matrix_diagnosis[0].diagnosis_code_text.substr(0,20)+'...':''):''}}
                        </span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="primary_secondary_payor">
                      <th mat-header-cell *matHeaderCellDef>Primary and Secondary Payor</th>
                      <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.pcc_patientId">
                          {{ element.getPayor() }} , {{ element.getPayor('secondary') }}
                        </ng-container>
                        <ng-container *ngIf="!element.pcc_patientId && element.source !== 'MatrixCare'">
                          {{ element.payorInformation?.p_insurance_carrier? element.payorInformation?.p_insurance_carrier:''}} , {{
                          element.payorInformation?.s_insurance_carrier? element.payorInformation?.s_insurance_carrier:''}}
                        </ng-container>
                        <ng-container *ngIf="!element.pcc_patientId && element.source == 'MatrixCare'">
                          {{ element.primary_matrix_payor }} , {{ element.secondary_matrix_payor }}
                        </ng-container>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="gender">
                      <th mat-header-cell *matHeaderCellDef>Gender</th>
                      <td mat-cell *matCellDef="let element">{{ element.gender }}</td>
                    </ng-container>

                    <ng-container matColumnDef="facility">
                      <th mat-header-cell *matHeaderCellDef>Facility</th>
                      <td mat-cell *matCellDef="let element">
                        <app-facility-title [facility]="element.facility_id_ref.title"></app-facility-title>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="location">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="location">
                        <span class="material-symbols-outlined sort-icon"> sort </span>
                        Room No
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{ getPatientLocation(element) }} 
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="admission_date">
                      <th mat-header-cell *matHeaderCellDef>A/D</th>
                      <td mat-cell *matCellDef="let element">                     
                        <div *ngIf="!(element.isPatientDischarged || element.isPatientReadmitted) && element.isPatientAdmitted" class="flex items-center justify-center">
                          <p class="admission-column">{{getAdmissionDate(element)}}</p>
                          <span *ngIf="['PointClickCare','MatrixCare'].includes(element.source)" class="circled-letter-icon" style="color:blue;border-color:blue">A</span>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="payer">
                      <th mat-header-cell *matHeaderCellDef>Payor</th>
                      <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.pcc_patientId">
                        {{ element.getPayor() }}
                      </ng-container>
                      <ng-container *ngIf="!element.pcc_patientId && element.source !== 'MatrixCare'">
                        {{ element?.non_pcc_payerName }}
                      </ng-container>
                      <ng-container *ngIf="!element.pcc_patientId && element.source == 'MatrixCare'">
                        {{ element.primary_matrix_payor }}
                      </ng-container>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="pcp">
                      <th mat-header-cell *matHeaderCellDef>PCP</th>
                      <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.pcc_patientId">
                        {{ element.pcc_primaryPractitioner?.firstName }} {{ element.pcc_primaryPractitioner?.lastName }}
                        </ng-container>
                         <ng-container *ngIf="!element.pcc_patientId && element.source !== 'MatrixCare'">
                          {{element?.primary_practitioner_first_name}} {{element?.primary_practitioner_last_name}}
                        </ng-container>
                        <ng-container *ngIf="element.source == 'MatrixCare'"> 
                          {{element?.primary_practitioner_first_name}} {{element?.primary_practitioner_last_name}}
                        </ng-container>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="mrn">
                      <th mat-header-cell *matHeaderCellDef>MRN</th>
                      <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.pcc_patientId">
                        {{ element?.pcc_payload?.medicalRecordNumber||element?.mrn }}
                        </ng-container>

                         <ng-container *ngIf="!element.pcc_patientId && element.source !== 'MatrixCare'">
                          {{element?.mrn}}
                        </ng-container>

                        <ng-container *ngIf="element.source == 'MatrixCare'"> 
                          {{element?.mrn}}
                        </ng-container>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="comments_count">
                      <th mat-header-cell *matHeaderCellDef>Comments</th>
                      <td mat-cell *matCellDef="let element" style="padding-right: 20px">
                        <a *ngIf="(element.patient_roundingsheet_comments_count) > 0" href="#"
                          (click)="$event.preventDefault(); showPatientCommentsDialog(element)">View
                          ({{element.patient_roundingsheet_comments_count}})</a>

                        <a href="#" 
                          style="
                            max-width: 200px;
                            display: block;
                            word-break: break-word;"
                            [ngStyle]="{ 'pointer-events': currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER ? 'none' : '', 'color': currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER ? 'grey' : '' }"
                          (click)="
                            $event.preventDefault(); 
                            showCommentSidenav(element); 
                            displayCommentSidenav=!displayCommentSidenav;"
                          matTooltip="{{getCommentstoShow(element).full_message}}" 
                          matTooltipPosition="above"
                          matTooltipClass="word-break"
                        >
                          {{getCommentstoShow(element).short_message}}
                        </a>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="face_sheet">
                      <th mat-header-cell *matHeaderCellDef>Face Sheet</th>
                      <td mat-cell *matCellDef="let element; let index=index;" style="cursor: pointer;">
                        <div class="flex gap-1">
                          <button mat-icon-button matTooltip="Upload Face Sheet" (click)="handleUploadFacesheetClick(element, index)">
                            <mat-icon>file_upload</mat-icon>
                          </button>
                          <button mat-icon-button matTooltip="Downlaod Face Sheet" [disabled]="downloadFaceSheetActive(element, index)"
                            (click)="handleDownloadFacesheetClick(element, index)">
                            <mat-icon>file_download</mat-icon>
                          </button>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="last_seen">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="last_seen">
                        <span class="material-symbols-outlined sort-icon"> sort </span>
                        Last Seen
                      </th>
                      <td mat-cell *matCellDef="let element" [innerHTML]="getLastSeen(element)">
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="last_cpt">
                      <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Last CPT</th>
                      <td mat-cell *matCellDef="let element">
                        {{element.lastCPT ? element.lastCPT : '' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="edit_patient">
                      <th mat-header-cell *matHeaderCellDef>
                      </th>
                      <td mat-cell *matCellDef="let row; let i=index">
                        <div class="flex justify-between items-center">
                          <span *ngIf="!row.pcc_patientId && checkVoidCensus()" class="material-icons" matTooltip="Edit" matTooltipPosition="above"
                            [ngStyle]="{ 'pointer-events': currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER ? 'none' : '', 'color': currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER ? 'grey' : '' }"
                            style="cursor: pointer;" (click)="openEditPatientDialog(row, i);" >
                          edit_note
                          </span>
                          <span></span>
                          <span class="material-icons" matTooltip="Submit a Ticket" matTooltipPosition="above"
                            [ngStyle]="{ 'pointer-events': currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER ? 'none' : '', 'color': currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER ? 'grey' : '' }"
                            style="cursor: pointer;" (click)="addNewTicket(row);" >
                          receipt
                          </span>
                        </div>
                      </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="operations">
                      <th mat-header-cell *matHeaderCellDef>Actions</th>
                      <ng-container *matCellDef="let element; let i=index">
                        <td mat-cell *ngIf="element.is_active == 'true'">
                          <div *ngIf="!CensusCopiedorNot() || !checkPatienMovedorNot(element)" class="operation-buttons nowrap inline-flex items-start justify-start gap-1">

                          <ng-container *ngIf="!isActivePatientList && checkVoidCensus()">
                            <mat-icon matTooltip="Restore Patient" matTooltipPosition="above" style="cursor: pointer;" (click)="restoreInactivePatient(element._id)">
                              restore
                            </mat-icon>
                          </ng-container>
                          <ng-container *ngIf="isActivePatientList">
                            <a *ngIf="census && checkVoidCensus() && isOwner(census) ||census && this.currentUser.user_type===global.USER_TYPE.MEDICAL_ASSISTANT || census && this.currentUser.user_type===global.USER_TYPE.TRANSCRIBER "
                              (click)="noteEditor(element, false, i)" class="inline-flex items-center justify-center edit-notes">
                              <i class="icon icon-add" matTooltip="Create Note" matTooltipPosition="above"></i>
                            </a>
                            <a *ngIf="(currentUser.charge_status && this.currentUser.user_type === global.USER_TYPE.DOCTOR && isOwner(census) && currentUser?.additional_user_role != global.USER_TYPE.CNP) || is_company_side"
                              href="#" class="dollar-sign"
                              (click)="$event.preventDefault(); " matTooltipPosition="above">
                              <div class="badge-container" (mouseover)="changeStyle($event, element)" (mouseout)="changeStyle($event, element)" *ngIf="hasChargeAndStatus(element, 'draft')">
                                <mat-icon (click)="$event.preventDefault(); noteEditor(element, false, i);" matTooltip="Drafted Charge"
                                class="dollar-icon-row" [ngClass]="{'not-clickable': is_company_side && !isOwner(census)}">attach_money</mat-icon>
                                <div *ngIf="!is_company_side || isOwner(census)" id={{element._id}} class="badge-icon"><mat-icon style="font-size:medium" matTooltip="Delete Charge" (click)="deleteDraftedCharge(element)">remove_circle_outline</mat-icon></div>
                              </div>
                              <mat-icon matTooltip="Submitted Charge" *ngIf="hasChargeAndStatus(element, 'submit')"
                                style="color:green;" (click)="$event.preventDefault(); noteEditor(element, false, i);" [ngClass]="{'not-clickable': is_company_side && !isOwner(census)}">attach_money</mat-icon>
                              <mat-icon (click)="$event.preventDefault(); noteEditor(element, false, i);" matTooltip="Create Charge"
                                *ngIf="!(hasChargeAndStatus(element, 'submit') || hasChargeAndStatus(element, 'draft'))"
                                style="color: #7f7e7e80;" [ngClass]="{'not-clickable': is_company_side && !isOwner(census)}">
                                attach_money</mat-icon>
                            </a>
                            <div *ngIf="should_show_consent_form && company.portal_type === global.PORTAL_TYPE.NON_WC" class="relative" (click)="openDialog(element, i);">
                              <mat-icon *ngIf="element.consentFormAWSPath" class="check-mark">done</mat-icon>
                              <img matTooltipPosition="above" matTooltip="Consent Form"
                                src="../../../assets/aggrement" width="20px" class="aggrement-img" alt="Consent Form">
                            </div>

                            <i *ngIf="telemedicine === 'true' && (!company.portal_type === global.PORTAL_TYPE.NON_WC) " class="icon icon-call" matTooltip="Video Call"
                              matTooltipPosition="above" (click)="noteEditor(element, true)"></i>

                            <img
                              matTooltip="ePrescription"
                              matTooltipPosition="above"
                              style="cursor: pointer; width: 22px;"
                              src="../../../assets/icons/rx.png"
                              alt="ePrescription"
                              (click)="openScriptSureModule( element )"
                              *ngIf="isPermittedFor( 'eprescription' ) && !element.isScriptSureLoading && company.portal_type === global.PORTAL_TYPE.NON_WC"
                            />
                            <mat-progress-spinner
                              matTooltip="Loading..."
                              matTooltipPosition="above"
                              diameter="22"
                              mode="indeterminate"
                              *ngIf="element.isScriptSureLoading"
                            ></mat-progress-spinner>
                            </ng-container>
                            <i *ngIf="census && checkVoidCensus() && isOwner(census) || currentUser.user_type===global.USER_TYPE.MEDICAL_ASSISTANT || currentUser.user_type===global.USER_TYPE.BUSINESS_OFFICER" class="icon icon-delete delete-row" matTooltip="Delete"
                              matTooltipPosition="above" (click)="handleRemovePatientClick(element._id)"></i>
                            <span
                              *ngIf="getRecentNoteInCensus(element) && checkWoundReportUploaded(getRecentNoteInCensus(element)) && currentUser.user_type != global.USER_TYPE.WOUND_NURSE  && currentUser.user_type != global.USER_TYPE.SNF_WC_NURSE && currentUser.user_type != global.USER_TYPE.BUSINESS_OFFICER"
                              [ngClass]="{'Wound_Report_Uploaded': checkWoundReportUploaded(getRecentNoteInCensus(element))}"
                              style="font-size:27px;" class="material-symbols-outlined icon-navy report-icon"
                              matTooltip="Group Wound Assessment Report">
                              lab_profile
                              <ng-container
                                *ngIf="checkWoundReportUploaded(getRecentNoteInCensus(element)) && element.source=='PointClickCare' && element.facility_id_ref.source=='PointClickCare'">
                                <img class="sub-icon pcc-icon" src="../../../../assets/icons/pcc-icon.svg" />
                              </ng-container>
                              <ng-container *ngIf="checkWoundReportUploaded(getRecentNoteInCensus(element)) && element?.source=='MatrixCare'">
                                <img class="matrix-icon-wound-report" src="../../../../assets/icons/matrix.png" alt="">
                              </ng-container>
                            </span>
                            <span
                              *ngIf="getRecentNoteInCensus(element) && !checkWoundReportUploaded(getRecentNoteInCensus(element)) && currentUser.user_type != global.USER_TYPE.WOUND_NURSE  && currentUser.user_type != global.USER_TYPE.SNF_WC_NURSE && currentUser.user_type != global.USER_TYPE.BUSINESS_OFFICER && currentUser.company_type !== global.COMPANY_TYPE.NURSING"
                              [matMenuTriggerFor]="menu" style="font-size:27px;" class="material-symbols-outlined icon-navy report-icon"
                              matTooltip="Group Wound Assessment Report"
                              [ngStyle]="{ 'pointer-events': currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER ? 'none' : '', 'color': currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER ? 'grey' : '' }">
                              lab_profile
                            </span>
                            <mat-icon *ngIf="currentUser.user_type === global.USER_TYPE.WOUND_NURSE" style="cursor: pointer;"
                              (click)="openWoundDialog(element)">healing</mat-icon>
                            <mat-menu #menu="matMenu" [class]="'menus-panel'">
                              <button class="flex justify-start items-center gap-2" mat-menu-item
                                (click)="generateWoundsReportPreview(getRecentNoteInCensus(element),false)">
                                <span class="tag-style">Preview Report</span>
                              </button>
                              <button class="flex justify-start items-center gap-2" mat-menu-item
                                (click)="generateWoundsReportPreview(getRecentNoteInCensus(element),true)">
                                <span class="tag-style">Download Report</span>
                              </button>
                              <button *ngIf="showUploadReportToPCC(element)" class="flex justify-start items-center gap-2" mat-menu-item
                                (click)="uploadWoundReportToPCC(getRecentNoteInCensus(element))">
                                <span class="tag-style">Upload Report to PCC</span>
                              </button>
                            </mat-menu>
                            <ng-container *ngIf="element.eligibilityStatus != null && view_eligibility_status">
                              <mat-progress-spinner matTooltip="Loading" diameter="20" *ngIf="element.eligibilityStatus === 'loading'" mode="indeterminate"></mat-progress-spinner>
                              <img
                                [matTooltip]="element.eligibilityStatus"
                                matTooltipPosition="above"
                                style="cursor: pointer; width: 20px;"
                                [src]="element.eligibilityIcon"
                                [alt]="element.eligibilityStatus"
                                (click)="openEligibilityDialog( element )"
                                *ngIf="element.eligibilityIcon != null && element.eligibilityStatus !== 'loading'"
                              />
                            </ng-container>
                          </div>
                          <div *ngIf="CensusCopiedorNot() && checkPatienMovedorNot(element)" style="font-weight: bold;">Moved to {{getMovedPatientFacility(element)}}</div>
                        </td>
                      </ng-container>
                    </ng-container>

                    <ng-container matColumnDef="checkbox">


                      <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox [disabled]="!checkVoidCensus() || currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER" (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                      </th>
                      <td mat-cell *matCellDef="let row">
                        <mat-checkbox [disabled]="!checkVoidCensus() || (CensusCopiedorNot() && checkPatienMovedorNot(row)) ||  (currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER)"
                          (click)="$event.stopPropagation()" (change)=" patientSlected(row, $event.checked)"
                          [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                      </td>
                      </ng-container>

                    <ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                    </ng-container>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                      [class.darkBlueBg]="getRowClass(row) === 'PRIMARY_DARK'"
                      [class.darkGrayBg]="getRowClass(row) === 'DARK_GRAY'"></tr>
                  </table>




                  <table *ngIf="!isActivePatientList" class="table-row-hover roundingsheet-patient-list-table flex-grow-1" mat-table matSort
                    [dataSource]="dataSource" cdkDropList style="height: fit-content;"
                    (cdkDropListDropped)="onListDrop($event)"
                    (matSortChange)="sortData($event)">  

                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef (click)="filterSort = 'name'">Name
                      </th>
                      <td mat-cell *matCellDef="let element" (click)="onRowClick($event, element._id)" cdkDrag>
                        <app-patient-title [patient]="element" [facility]="element.facility_id_ref"></app-patient-title>

                      </td>
                    </ng-container>

                    <ng-container matColumnDef="date_of_birth">
                      <th mat-header-cell *matHeaderCellDef>DOB</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.date_of_birth | date: 'M/d/yyyy' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="comments_count">
                      <th mat-header-cell *matHeaderCellDef>Comments</th>
                      <td mat-cell *matCellDef="let element" style="padding-right: 20px">
                        <a *ngIf="(element.patient_roundingsheet_comments_count) > 0" href="#"
                          (click)="$event.preventDefault(); showPatientCommentsDialog(element)">View
                          ({{element.patient_roundingsheet_comments_count}})</a>

                        <a href="#" 
                          style="
                            max-width: 200px;
                            display: block;
                            word-break: break-all;" 
                          (click)="
                            $event.preventDefault(); 
                            showCommentSidenav(element); 
                            displayCommentSidenav=!displayCommentSidenav;"
                          matTooltip="{{getCommentstoShow(element).full_message}}" 
                          matTooltipPosition="above"
                          matTooltipClass="word-break"
                        >
                          {{getCommentstoShow(element).short_message}}
                        </a>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="face_sheet">
                      <th mat-header-cell *matHeaderCellDef>Face Sheet</th>
                      <td mat-cell *matCellDef="let element; let index=index;" style="cursor: pointer;">
                        <div class="flex gap-1">
                          <button mat-icon-button matTooltip="Upload Face Sheet" (click)="handleUploadFacesheetClick(element, index)">
                            <mat-icon>file_upload</mat-icon>
                          </button>
                          <button mat-icon-button matTooltip="Downlaod Face Sheet" [disabled]="downloadFaceSheetActive(element, index)"
                            (click)="handleDownloadFacesheetClick(element, index)">
                            <mat-icon>file_download</mat-icon>
                          </button>
                        </div>
                      </td>
                    </ng-container>


                    <ng-container matColumnDef="in_active_reason">
                      <th mat-header-cell *matHeaderCellDef>In Active Reason</th>
                      <td mat-cell *matCellDef="let element" style="padding-right: 20px">
                        {{getInActiveReasontoShow(element)}}
                      </td>
                    </ng-container>

                    
                    
                    <ng-container matColumnDef="operations">
                      <th mat-header-cell *matHeaderCellDef>Actions</th>
                      <ng-container *matCellDef="let element; let i=index">
                        <td mat-cell *ngIf="element.is_active == 'true'">
                          <div class="operation-buttons" class="inline-flex items-start justify-start gap-1">

                          <ng-container *ngIf="!isActivePatientList && checkVoidCensus()">
                            <mat-icon matTooltip="Restore Patient" matTooltipPosition="above" style="cursor: pointer;" (click)="restoreInactivePatient(element._id)">
                              restore
                            </mat-icon>
                          </ng-container>

                            <i *ngIf="census && checkVoidCensus() && isOwner(census) || currentUser.user_type===global.USER_TYPE.MEDICAL_ASSISTANT || currentUser.user_type===global.USER_TYPE.BUSINESS_OFFICER" class="icon icon-delete" matTooltip="Delete"
                              matTooltipPosition="above" (click)="handleRemovePatientClick(element._id)"></i>
                          </div>
                        </td>
                      </ng-container>
                    </ng-container>



                    <ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumnsInactive;sticky: true"></tr>
                    </ng-container>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsInactive;"
                      [class.darkBlueBg]="getRowClass(row) === 'PRIMARY_DARK'"
                      [class.darkGrayBg]="getRowClass(row) === 'DARK_GRAY'"></tr>
                  </table>

                </div>
                <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
              </div>
            </div>

          </div>
        </div>
      </div>
    </main>
  </mat-sidenav-container>

  <div style="margin-right: -5px;" *ngIf="show_adt_view && census && isLoggedIntoPCC()"
    class="flex flex-col child_one_class ">
    <div class="close_button">
      <mat-icon style="    margin-right: 10px;margin-top: 10px;cursor: pointer;" (click)="show_adt_view=!show_adt_view">
        close
      </mat-icon>
    </div>
    <div class="census-sidebar">
      <app-pcc-event-notifications *ngIf="census" title="PCC Patients Admit/Readmit"
        [eventTypes]="[pccEventTypes.PATIENT_ADMIT, pccEventTypes.PATIENT_READMIT]"
        [facilityIds]="[census.facility?._id]" (onImportPatient)="handleImportPatient($event)">
      </app-pcc-event-notifications>

    </div>
  </div>
  <div *ngIf="patientsImportSource == 'pcc' && isUserLoggedIntoPCC" (click)="show_adt_view=!show_adt_view" [ngClass]="{'Mid_child1': show_adt_view, 
'Mid_child': !show_adt_view}">
    <mat-icon *ngIf="!show_adt_view" class="arrow_icon_class">
      menu
    </mat-icon>
  </div>
</div>