<div class="flex flex-col">
    <div class="selected-items" *ngIf="selectedDiagnoses.length">
        <div cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListDisabled] = "charge?.status == 'submit'"  >
            <div cdkDrag *ngFor="let element of selectedDiagnoses; let i = index" style="cursor: move;border: groove;border-width: 1px;padding: 7px;" class="flex gap-2 justify-between items-baseline"
                [class.pdpm_highlight]="element.pdpm_status" [ngStyle]="{ background: i % 2 == 0? '#f4f5f8': 'white'}">
                <mat-checkbox (change)=" removeDiagnose(element, $event, 'icd');" [checked]="true">
                    {{element.code}} <span>({{element.description}})</span>
                </mat-checkbox>
            </div>
        </div>
    </div>
    <div style="margin: 10px;">
        <mat-form-field style="width: 100%;height: 44px;margin-top: -10px;" appearance="fill">
            <mat-label>Search </mat-label>
            <input matInput placeholder="Search Diagnosis/ICD10" (keyup)="searchDiagnose($event.target.value)"
                [(ngModel)]="diagnoseFilter" name="diagnoseFilter">
            <button mat-button mat-icon-button matSuffix (click)="clearSearchField();searchCharge('');"
                *ngIf="diagnoseFilter">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>

    <div class="flex-grow flex flex-col gap-5">
        <mat-tab-group style="height: calc(100vh - 338px);" class="flex flex-grow"
            (selectedTabChange)="onTabClickDiagnose($event)">
            <mat-tab label="Favorites">
                <ng-template matTabContent>
                    <span style=" width: 100%; height: 100%;overflow: auto;">
                        <table mat-table [dataSource]="dataSourceFvrtICDs" matSort cdkDropList
                            (cdkDropListDropped)="onListDropfvrtICDs($event)">
                            <ng-container matColumnDef="checkbox">
                                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                                </th>
                                <td mat-cell *matCellDef="let element" style="width: 50px;">
                                    <mat-checkbox (change)="addToModel(element, $event, 'icd');"
                                        [checked]="isICDChecked(element)" name="icd_fvrt">
                                    </mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="fvrt">
                                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                                </th>
                                <td mat-cell *matCellDef="let element" style="width: 50px;">
                                    <span
                                        (click)="$event.preventDefault();$event.stopPropagation();removeFavouriteDiagnoses(element)"
                                        style="cursor: pointer" class="material-icons">
                                        star
                                    </span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="code">
                                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                                </th>
                                <td mat-cell *matCellDef="let element" (click)="addToModel(element, $event, 'icd');"
                                    style="cursor: pointer;">
                                    <div [class.pdpm_highlight]="element.pdpm_status">
                                        {{element.code}}
                                        <span>({{element.description}})</span>
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag></tr>
                        </table>
                    </span>
                </ng-template>
            </mat-tab>
            <mat-tab label="All">
                <ng-template matTabContent>
                    <span style=" width: 100%; height: 100%;overflow: auto;">
                        <table mat-table [dataSource]="dataSourceAllICDs" matSort>
                            <ng-container matColumnDef="checkbox">
                                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                                </th>
                                <td mat-cell *matCellDef="let element" style="width: 50px;">
                                    <mat-checkbox (change)="addToModel(element, $event, 'icd')"
                                        [checked]="isICDChecked(element)" name="icd_all">
                                    </mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="fvrt">
                                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                                </th>
                                <td mat-cell *matCellDef="let element" style="width: 50px;">
                                    <span *ngIf="element.fvrt === true"
                                        (click)="$event.preventDefault();$event.stopPropagation();removeFavouriteDiagnoses(element)"
                                        style="cursor: pointer" class="material-icons">
                                        star
                                    </span>
                                    <span class="material-icons" *ngIf="element.fvrt !== true"
                                        (click)="$event.preventDefault();$event.stopPropagation();addToFavouriteDiagnoses(element)"
                                        style="cursor: pointer">star_outline</span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="code">
                                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                                </th>
                                <td mat-cell *matCellDef="let element" (click)="addToModel(element, 'row', 'icd');"
                                    style="cursor: pointer;">
                                    <div [class.pdpm_highlight]="element.pdpm_status">
                                        {{element.code}}
                                        <span>({{element.description}})</span>
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
                    </span>
                    <div style="height: 5px;"></div>
                    <div class="flex flex-row gap-2 justify-center items-center table-nav"
                        style="margin-bottom: 8px;">

                        <span style="background: white; border-radius: 2px; font-size: 12px;padding: 5px;">
                            {{getCountofCodes('diagnoseAll')}}</span>

                        <button mat-raised-button class="dn-button-primary"
                            [disabled]="!paginationDiagnosesAll.hasNextPage" type="button"
                            (click)="handleNextClick('diagnoseAll')"> Next </button>
                    </div>
                </ng-template>
            </mat-tab>
            <mat-tab label="PCC Diagnosis" *ngIf="note.patient.pcc_patientId">
                <ng-template matTabContent>
                    <app-conditions *ngIf="note.patient.pcc_patientId" [patient]="note.patient" [showCheckboxes]="true" [selectedDiagnoses]="selectedDiagnoses">
                    </app-conditions>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>


</div>