import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import * as global from '../../includes/global';
import { AuthService } from '../../services/auth.service';
import { API_URL } from '../../../environments/api-url';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  currentUser: any;

  constructor(
    private httpclient: HttpClient,
    private _authService: AuthService
  ) {
    this.currentUser = this._authService.currentUser;
  }


  addCommunicationComment(data) {

    return this.httpclient
      .post(global.url + API_URL.COMMUNICATION.addCommunicationComment, {details: data})
      .pipe();
  }
  
  getCommunicationComment(patient_id, parentCommentId?) {
    let details: any = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,

      patient_id
    };
    if(parentCommentId) {
      details.parentCommentId = parentCommentId;
    }

    return this.httpclient
      .post(global.url + API_URL.COMMUNICATION.getCommunicationComment, { details })
      .pipe();
  }

  deleteCommunicationComment(commentId) {
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,

      commentId
    };
    return this.httpclient
      .post(global.url + API_URL.COMMUNICATION.deleteCommunicationComment, { details })
      .pipe();
  }

  editCommunicationComment(comment, mentions) {
    const details = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type,
      mentions:mentions,
      comment
    };

    return this.httpclient
      .post(global.url + API_URL.COMMUNICATION.editCommunicationComment, { details })
      .pipe();
  }

  
}
