import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { MipsService } from 'src/app/services/Modules/mips.service';
import { AddMipsQuestionComponent } from '../mips-questions/add-mips-question/add-mips-question.component';
import { AddMipsComponent } from './add-mips/add-mips/add-mips.component';

@Component({
  selector: 'app-mips-dashboard',
  templateUrl: './mips-dashboard.component.html',
  styleUrls: ['./mips-dashboard.component.css']
})
export class MipsDashboardComponent implements OnInit {

  dummyData = [
    {
      measure: "Measure 1",
      description: "Description 1",
      cpt: "cpt 1",
      age_criteria: "Age Criteria 1",
      main_encounter_cpt: "main_encounter_cpt 1",
      dx_codes: "dx_codes 1",
    },
    {
      measure: "Measure 2",
      description: "Description 2",
      cpt: "cpt 2",
      age_criteria: "Age Criteria 2",
      main_encounter_cpt: "main_encounter_cpt 2",
      dx_codes: "dx_codes 2",
    },
    {
      measure: "Measure 3",
      description: "Description 3",
      cpt: "cpt 3",
      age_criteria: "Age Criteria 3",
      main_encounter_cpt: "main_encounter_cpt 3",
      dx_codes: "dx_codes 3",
    },
  ]
  displayedColumns: any = ['measure', 'description', 'cpt', 'age_criteria', 'main_encounter_cpt', 'dx_codes'];
  dataSource: any;
  addMips:any;

  constructor(
    private route: ActivatedRoute,
    // private addMipsComponent: AddMipsComponent,
    private dialog: MatDialog, 
    private mipsService:MipsService
  ) { }

  ngOnInit() {

    this.dataSource = new MatTableDataSource(this.dummyData);

  }
}
