import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-wound',
  templateUrl: './wound.component.html',
  styleUrls: ['./wound.component.css']
})
export class WoundComponent implements OnInit {
  tabIndex = 0;
  activatedRouter: string = 'procedure-datasets'
  company_id: string;
  constructor(
        private _route: ActivatedRoute,
  ) { 
        this.company_id = this._route.parent.snapshot.params.id;
  }

  ngOnInit(): void {
  }

  onTabClick(event) {
    if (event?.index == 0) {
      this.tabIndex = 0;
      this.activatedRouter = 'procedure-datasets'
    }
    if (event?.index == 1) {
      this.tabIndex = 1;
      this.activatedRouter = 'wound-groups'
    }
    if (event?.index == 2) {
      this.tabIndex = 2;
      this.activatedRouter = 'data-sets'
    }
    if (event?.index == 3) {
      this.tabIndex = 3;
      this.activatedRouter = 'data-sets-2'
    }
    if (event?.index == 4) {
      this.tabIndex = 4;
      this.activatedRouter = 'tags'
    }
    if (event?.index == 5) {
      this.tabIndex = 5;
      this.activatedRouter = 'e_m_codes';
    }
    if (event?.index == 6) {
      this.tabIndex = 6;
      this.activatedRouter = 'procedure_codes';
    }
    if (event?.index == 7) {
      this.tabIndex = 7;
      this.activatedRouter = 'phrases_templates';
    }
    if (event?.index == 8) {
      this.tabIndex = 8;
      this.activatedRouter = 'procedure-note-dataset'
    }
    if (event?.index == 8) {
      this.tabIndex = 8;
      this.activatedRouter = 'procedure-note-dataset'
    }
  }
}
