<h3>
  Addendum
</h3>

<div class="flex flex-col gap-2" style="font-size: 12px;">


  <div class="flex flex-col">
    
  <div>
    <u><strong>Addendum Date: </strong></u>
    <span>{{addendum.createdAt | date: 'MM/dd/yyyy hh:mm a'}}</span>
  </div>
  
  <div>
    <u><strong>Addendum Details: </strong></u>
    <div style="max-height: 65vh;overflow: auto;" [innerHTML]="addendum.text"></div>
  </div>

  </div>


  <div class="flex gap-5 justify-end">
    <button mat-raised-button class="dn-button-secondary" mat-dialog-close>Close</button>
  </div>
  <div class="top-right-close-btn">
      <button mat-mini-fab mat-dialog-close ><mat-icon >cancel</mat-icon></button>
    </div>

</div>