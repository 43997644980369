<div class="h100 flex flex-col md:flex-row items-center justify-center">
  <mat-card class="w-2/5 md:w-full">

    <mat-card-title class="flex flex-col items-center justify-center">
      <img src="assets/img/DNlogo-new.png">
      <h2>Register</h2>
    </mat-card-title>

    <mat-card-content class="flex flex-col">

      <form (submit)="submitted=true; onRegister(registerForm)" #registerForm="ngForm" *ngIf="accountType === 'user'">

              <mat-form-field>
                <mat-label>User type</mat-label>
                <mat-select id="reg-user-type" name="user_type" [(ngModel)]="user_type" required>
                  <mat-option value="provider" id ="reg-user-provider">
                    Provider
                  </mat-option>
                  <mat-option value="transcriber" id = "reg-user-transcriber">
                    Transcriber
                  </mat-option>
                  <mat-option value="biller">
                    Biller
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!registerForm.value.user_type">Please select user type</mat-error>
              </mat-form-field>

              <div class="flex gap-5">
          
          <div class="flex-1/4" *ngIf="user_type === 'provider'">
            <mat-form-field>
              <mat-label>Title</mat-label>
              <input matInput type="text" [(ngModel)]="title"  name="title" [matAutocomplete]="titleAutoComplete"
                    style="cursor: pointer;" [formControl]="titleControl">
                    <mat-icon matSuffix style="cursor: pointer;">keyboard_arrow_down</mat-icon>
                    <mat-autocomplete [displayWith]="displayProviderFn"
                                    #titleAutoComplete="matAutocomplete">
                                    <mat-option *ngFor="let title of titles" [value]="title">
                                        {{title}}
                                    </mat-option>
                                </mat-autocomplete> 
              <mat-error *ngIf="!registerForm.value.title">Please select title</mat-error>
            </mat-form-field> 
          </div>

          <div class="{{ user_type === 'transcriber' ? 'w-42.5' : 'w-50' }}">
            <mat-form-field>
              <input id="reg-first-name" matInput type="text" onkeypress="return /[a-z]/i.test(event.key)" name="firstname" ngModel placeholder="First Name" required>
              <mat-error *ngIf="!registerForm.value.firstname">Please enter first name</mat-error>
            </mat-form-field>
          </div>

          <div class="{{ user_type === 'transcriber' ? 'w-42.5' : 'w-50' }}">
            <mat-form-field>
              <input id="reg-last-name" matInput type="text" onkeypress="return /[a-z]/i.test(event.key)" name="lastname" ngModel placeholder="Last Name" required>
              <mat-error *ngIf="!registerForm.value.lastname">Please enter last name</mat-error>
            </mat-form-field>
          </div>
        </div>

        <mat-form-field>
          <input id="reg-email" matInput type="text" name="email" #email="ngModel" ngModel placeholder="Email address" required>
          <mat-error *ngIf="email.invalid">Please enter email</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input id="reg-email" matInput type="text" name="mobile" #mobile="ngModel" ngModel placeholder="Phone Number" required>
          <mat-error *ngIf="!registerForm.value.mobile">Please enter phone number</mat-error>
        </mat-form-field>

        <div class="flex gap-5">
          <div class="w-1/2">
            <mat-form-field>
              <input id="reg-password" matInput type="password" name="password" [(ngModel)]="password" placeholder="Password" (change)="isPasswordValid()" required>
              <mat-error *ngIf="!registerForm.value.password">Please enter password</mat-error>
            </mat-form-field>
          </div>
          
          <div class="w-1/2">
            <mat-form-field>
              <input id="reg-confirm-password" matInput type="password" name="confirm_password" [(ngModel)]="confirm_password" placeholder="Confirm Password" (input)="isPasswordValid()" required>
              <mat-error *ngIf="!registerForm.value.confirm_password  ">Please re-enter password to confirm</mat-error>
              <mat-error *ngIf="passError && registerForm.value.confirm_password">Confirm password does not match</mat-error>
            </mat-form-field>
          </div>
        </div>
        <mat-error *ngIf="passwordError"><small>{{passwordError}}</small></mat-error>
        <password-strength-meter [password]="password" (strengthChange)="strengthChange($event)"></password-strength-meter>

        <br />

        <div class="flex items-center justify-center">
          <button id="register-button" class="theme-btn w-1/2" mat-stroked-button type="submit">Register</button>
        </div>

        <div class="member flex flex-col justify-center items-center">
          <a id="login-button" href="/login">Login</a>
        </div>

      </form>

      <form (submit)="submitted=true; onRegisterCompany(registerForm)" #registerForm="ngForm" *ngIf="accountType === 'company'">

     <div class="flex flex-row gap-5">

          <div class="w-1/2">
            <mat-form-field>
              <input matInput type="text" name="company_name" ngModel placeholder="Company Name" required>
              <mat-error *ngIf="!registerForm.value.company_name">Please enter company name</mat-error>
            </mat-form-field>
          </div>

          <div class="w-1/2">
            <mat-form-field>
              <input matInput type="text" name="email" #email="ngModel" ngModel placeholder="Email address" required>
              <mat-error *ngIf="email.invalid">Please enter company email</mat-error>
            </mat-form-field>
          </div>
        </div>

     <div class="flex flex-row gap-5">
          <div class="w-1/2">
            <mat-form-field>
              <input id="reg-first-name" matInput type="text" onkeypress="return /[a-z]/i.test(event.key)" name="firstname" ngModel placeholder="Owner First Name" required>
              <mat-error *ngIf="!registerForm.value.firstname">Please enter first name</mat-error>
            </mat-form-field>
          </div>

          <div class="w-1/2">
            <mat-form-field>
              <input id="reg-last-name" matInput type="text" onkeypress="return /[a-z]/i.test(event.key)" name="lastname" ngModel placeholder="Owner Last Name" required>
              <mat-error *ngIf="!registerForm.value.lastname">Please enter last name</mat-error>
            </mat-form-field>
          </div>
        </div>

        <mat-form-field>
          <input matInput name="mobile" ngModel placeholder="Phone Number" required> 
          <mat-error *ngIf="!registerForm.value.mobile">Please enter phone number</mat-error>
        </mat-form-field>

        <mat-form-field>
          <textarea matInput name="company_description" ngModel placeholder="Company Description" required></textarea>
          <mat-error *ngIf="!registerForm.value.company_description">Please enter company description</mat-error>
        </mat-form-field>

        <mat-form-field>
          <textarea matInput name="company_address" ngModel placeholder="Company Address" required></textarea>
          <mat-error *ngIf="!registerForm.value.company_address">Please enter company address</mat-error>
        </mat-form-field>

     <div class="flex flex-row gap-5">
          <div class="w-1/2">
            <mat-form-field>
              <input matInput type="password" name="password" [(ngModel)]="password" placeholder="Password" (change)="isPasswordValid()" required>
              <mat-error *ngIf="!registerForm.value.password">Please enter password</mat-error>
            </mat-form-field>
          </div>
          
          <div class="w-1/2">
            <mat-form-field>
              <input matInput type="password" name="confirm_password" [(ngModel)]="confirm_password" placeholder="Confirm Password" (input)="isPasswordValid()" required>
              <mat-error *ngIf="!registerForm.value.confirm_password  ">Please re-enter password to confirm</mat-error>
              <mat-error *ngIf="passError && registerForm.value.confirm_password">Confirm password does not match</mat-error>
            </mat-form-field>
          </div>
        </div>
        <mat-error *ngIf="passwordError"><small>{{passwordError}}</small></mat-error>
        <password-strength-meter [password]="password" (strengthChange)="strengthChange($event)"></password-strength-meter>

        <br />

        <div class="flex justify-center items-center">
          <button class="theme-btn w-1/2" mat-stroked-button type="submit">Register</button>
        </div>

        <div class="member flex flex-col justify-center items-center">
          <a href="/login">Login</a>
        </div>

      </form>

    </mat-card-content>

  </mat-card>
</div>
