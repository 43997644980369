import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PCCService } from 'src/app/services/pcc.service';

@Component({
  selector: 'app-patient-opportunities-popup',
  templateUrl: './patient-opportunities-popup.component.html',
  styleUrls: ['./patient-opportunities-popup.component.css']
})
export class PatientOpportunitiesPopupComponent implements OnInit {
  patient:any
  constructor(
  @Inject(MAT_DIALOG_DATA) public data: any ,    
  private _pccService: PCCService,
  ) {    
    this.patient =data.patient;
    console.log("data",data)}
  ngOnInit(): void {
  }
}
