<app-header></app-header>

<div class="container">
    <h1>
        Real-time Audio Transcription
    </h1>

    <textarea id="transcript" 
        placeholder="Press Start and speak into your mic" 
        rows="5" 
        style="width: 100%;"
        readonly="readonly"
        [(ngModel)]="transcript"></textarea>
        <div>
            <div style="display: flex; justify-content: center;">
                <button id="start-button"  title="Start Transcription" [disabled]="!enableStart" (click)="startButtonClick()">
                    Start
                </button>
                <button id="stop-button"  title="Stop Transcription" [disabled]="enableStart" (click)="stopButtonClick()"> 
                     Stop
                </button>
                <button id="reset-button" title="Clear Transcript" (click)="transcript='' "> 
                    Clear Transcript
                </button>
            </div> 
        </div>
</div>
