import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { DictionaryEditorDialogComponent } from '../phrases/phrase-editor-dialog/phrase-editor-dialog.component';
import { DrivePhraseService } from './drive-phrase.service';
import { USER_TYPE } from '../global';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/Modules/user.service';
import { MatTableDataSource } from '@angular/material/table';
import { DeleteConfirmationDialog } from '../drive/delete-confirmation-dialog.component';
import { WatchHelpVideoDialog } from '../drive/watch-help-video-dialog.component';
import { DrivePhraseEditorComponent } from './drive-phrase-editor/drive-phrase-editor.component';
import { PhrasesService } from '../phrases/phrases.service';
import { HttpResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DashboardService } from '../dashboard/dashboard.service';

@Component({
  selector: 'app-drive-phrases',
  templateUrl: './drive-phrases.component.html',
  styleUrls: ['./drive-phrases.component.css'],
})
export class DrivePhrasesComponent implements OnInit {
  loaderId = 'drive-user-phrase-loader';

  dictionary: any = [];
  systemDictionary: any = [];
  newDictionaryElement: any;
  USER_TYPE = USER_TYPE;
  isNew: boolean = false;
  userDefinedPhrases: any;
  selectedProvider: any = {};
  providers: any = [];
  company_id: any;
  dataSourceSystemPhrases = new MatTableDataSource([]);
  dataSourceMyPhrases: any = new MatTableDataSource([]);
  phrasesToFilter;
  displayedColumnsMyPhrases: string[] = ['shortcut', 'preview', 'import'];
  displayedColumnsCompanyPhrases: string[] = ['sr', 'shortcut', 'preview', 'import'];
  providerDictionary: any;
  oldDictionaryElement: any = {};
  currentUser: any;
  searchText: any;
  showSelectProviderFilter: boolean = false;


  constructor(
    private drivephraseService: DrivePhraseService,
    private dialog: MatDialog,
    private authService: AuthService,
    private route: ActivatedRoute,
    private userService: UserService,
    private _authService: AuthService,
    private toastr: ToastrService,
    private phrasesService:PhrasesService,
    private loader: NgxUiLoaderService,
    private dashboardService: DashboardService,
  ) {
    this.currentUser = this._authService.currentUser;
  }

  ngOnInit() {
    if (window.location.pathname.includes('company')) {
      this.company_id = this.route.parent.snapshot.params.id;
    }
    else {
      this.company_id = this.authService.currentUser.company_id;
    }
    this.getProviders();
  }

  async getProviders() {
    // const company_id = this.params.id;
    const filter = {
      is_activated: 'true',
      user_type: this.currentUser.user_type === USER_TYPE.SNF_WC_NURSE ? USER_TYPE.SNF_WC_NURSE : USER_TYPE.DOCTOR,
      company_id: this.company_id
    }

    const projection = {
      'account_type': 1,
      'first_name': 1,
      'last_name': 1,
      'title': 1,
      // 'company_id': 1
    }

    const companyUsersResponse: any = await this.userService.getUsers(filter, projection).toPromise();
    if (companyUsersResponse.status === 200) {
      this.providers = companyUsersResponse.data.map(p => Object.assign(p, {
        full_name: p.account_type === 'company' ?
          `${p.first_name} ${p.last_name}, ${p.title} (company owner)` :
          `${p.first_name} ${p.last_name}, ${p.title}`
      }));
      this.providers.sort((a, b) => a.full_name.localeCompare(b.full_name));
      const index = this.providers.findIndex(ele => ele._id === this.currentUser._id);
      // console.log("index",index);
      this.providers.splice(index, 1);
      this.selectedProvider = this.providers[0];
      // console.log("providers", this.providers);
      this.getNoteBuilderDictionary();
      this.getNoteBuilderDictionaryForProivders(this.selectedProvider._id);
      this.getSystemDefinedPhrases();
    }
  }

  onProviderChange($event, providerId) {
    if ($event.isUserInput) {
      this.getNoteBuilderDictionaryForProivders(providerId);
    }
  }

  addDictionaryElement() {
    // this.phrasesService.addDictionaryElement();
    this.newDictionaryElement = {
      key: '',
      value: ''
    }
  }

  saveDictionaryElement(newDictionaryElement) {
    let dic = JSON.parse(JSON.stringify(this.dictionary[0].dictionary))
    let uniqueKeyError = false;
    for (let i = 0; i < this.dictionary.length; i++) {
      if (dic[i]?.key === newDictionaryElement.key) {
        uniqueKeyError = true;
        break;
      }
      else {
        uniqueKeyError = false;
      }
    }
    if (uniqueKeyError) {
      this.toastr.error("Shortcut already exists", "Error");
      return;
    }
    this.drivephraseService.saveNoteBuilderDictionary(newDictionaryElement).subscribe((response: any) => {
      if (response.status === 200) {
        this.getNoteBuilderDictionary();
        this.toastr.success("Phrase saved successfully");
      }
    });
  }

  getSystemDefinedPhrases() {
    this.drivephraseService.getAssociatedPhrases(this.authService.currentUser._id).subscribe((dictionary: any) => {
      if (dictionary.status == 200 && dictionary.data) {
        this.systemDictionary = dictionary.data.assoc_phrase_ids;
        // console.log('system defined phrases', this.dictionary);
      }
    });
  }
  getNoteBuilderDictionary() {
    this.drivephraseService.getNoteBuilderDictionary().subscribe((response: any) => {
      if (response.status === 200 && response.data) {
        console.log("response.data", response.data);
        this.dictionary = [];
        response.data.map(data => {
          this.dictionary.push(data);
        });
      }
      if (this.dictionary[0]?.dictionary.length > 0) {
        this.sortPhrases(this.dictionary[0].dictionary);
      }
      if (!this.dictionary) {
        this.dictionary = [];
      }
      if (this.dictionary.length === 0) {
        this.dictionary[0] = {
          dictionary: []
        }
      }
      this.dataSourceMyPhrases.data = this.dictionary[0]?.dictionary;
    });
  }
  // [innerHTML]="displayTextInCard(element.value)"

  displayTextInCard(value): string {
    var span = document.createElement('span');
    span.innerHTML = value;
    const text = span.textContent || span.innerText;
    if (value?.length > 44) {
      return value.substr(0, 44) + "..."
    }
    else {
      return value
    }
  }
  getNoteBuilderDictionaryForProivders(id) {
    this.drivephraseService.getNoteBuilderDictionary(id).subscribe((response: any) => {
      // console.log(response.data);
      if (response.status === 200 && response.data) {

        const selectedProviderDictionary = response.data.find(ele => ele.user_id === id);

        if (selectedProviderDictionary) {
          if (selectedProviderDictionary === undefined) {
            response.data.push({ dictionary: [] });
          }
          if (selectedProviderDictionary.user_id != id) {
            selectedProviderDictionary.dictionary = [];
            //console.log(selectedProviderDictionary.user_id)
            //console.log(id)
          }
          this.providerDictionary = selectedProviderDictionary.dictionary;
          if (response?.data.length > 0 && selectedProviderDictionary?.dictionary) {
            this.sortPhrases(selectedProviderDictionary.dictionary);
          }
          this.dataSourceSystemPhrases.data = selectedProviderDictionary.dictionary;
          this.phrasesToFilter = this.dataSourceSystemPhrases.data;
          // this.dataSourceSystemPhrases = selectedProviderDictionary.dictionary;
        } else {
          this.dataSourceSystemPhrases.data = [];
        }

      }
    });
  }

  updateNoteBuilderDictionary(newDictionaryElement) {
    if (this.oldDictionaryElement) {
      if (this.oldDictionaryElement.key === newDictionaryElement.key && this.oldDictionaryElement.value === newDictionaryElement.value) {
        return;
      }
      newDictionaryElement.oldKey = this.oldDictionaryElement.key;
    }
    this.drivephraseService.EditPhrase(newDictionaryElement).subscribe((response: any) => {
      if (response.status === 200) {
        this.getNoteBuilderDictionary();
        this.toastr.success("Phrase updated successfully");
      }
    });
  }
  async deleteFromNoteBuilderDictionary(dictionaryElement) {
    const message = 'Are you sure you want to delete this phrase?'
    const dialogBoxValue = await this.dashboardService.DeleteConfirmationDialog(message);
    if (dialogBoxValue === "true") {
    this.drivephraseService.deleteFromNoteBuilderDictionary(dictionaryElement, this.currentUser._id).subscribe((response: any) => {
        // console.log(response);
        if (response.status === 200) {
          console.log('Deleted', response.data);
          this.toastr.success("Deleted SuccessFully");
          this.getNoteBuilderDictionary();
          // this.dictionary[0] = response.data;
          // this.dataSourceMyPhrases.data = this.dictionary[0]?.dictionary;
        }
    });
  }else{
    () => this.toastr.error('Something went wrong while removing phrase', 'Failed')
   } 
}

  showDictionaryEditorDialog(dictionaryElement?, selectedProvider?, editable = true) {
    if (dictionaryElement) {
      this.oldDictionaryElement = JSON.parse(JSON.stringify(dictionaryElement));
    }
    if (selectedProvider !== undefined) {
      dictionaryElement.user_id = selectedProvider._id;
    }
    let obj: any = {
      // dictionaryElement: dictionaryElement,
      dictionary: JSON.parse(JSON.stringify(this.dictionary[0].dictionary)),
      editable: editable
    }
    if (dictionaryElement) {
      obj.dictionaryElement = JSON.parse(JSON.stringify(dictionaryElement));
    }
    const dialogRef = this.dialog.open(DrivePhraseEditorComponent, {
      width: '800px',
      data: obj
    });

    dialogRef.afterClosed().subscribe((_dictionaryElement) => {
      if (_dictionaryElement) {
        if (dictionaryElement) {
          // if(this.dictionaryElement) {
          // _dictionaryElement.oldKey = this.dictionaryElement.key;
          // }
          this.updateNoteBuilderDictionary(_dictionaryElement);
        } else {
          this.saveDictionaryElement(_dictionaryElement);
        }
      }

      // this.loader.start(this.loaderId);
      // await this.getPhrases();
      // this.loader.stop(this.loaderId);

    });
  }

  copyPhrase() {
    this.drivephraseService.copyPhrase(this.dataSourceSystemPhrases.data).subscribe((response: any) => {
      if (response.status == 200) {
        this.dictionary[0].dictionary = response.data.dictionary;
        this.dataSourceMyPhrases.data = [];
        this.dataSourceMyPhrases.data = this.dictionary[0]?.dictionary;
        this.toastr.success("All Phrases Imported", "Success");
      }
    }, err => {
      this.toastr.error(err, "ERROR");
    }
    );
  }

  isOwner(dictionaryElement) {
    return dictionaryElement.user_id === this.authService.currentUser._id
  }
  sortPhrases(arr: any[]) {
    console.log('arr',arr);
    arr.sort((a, b) => a.key.toLowerCase().localeCompare(b.key.toLowerCase()));
  }

  watchHelpVideo( videoId ) {
    const dialogRef = this.dialog.open(WatchHelpVideoDialog, {
      data: { videoId }
    });

    dialogRef.afterClosed().subscribe( console.log );
  }
  applyFilter(filterValue: string) {
    let filter = filterValue.trim().toLowerCase();
    if (filter) {
      let filterVal = [];
      this.dictionary[0]?.dictionary.forEach(dic => {
        if (dic.key?.toLowerCase().includes(filter)) {
          filterVal.push(dic)
        }
      });
      this.dataSourceMyPhrases = filterVal;
    } else if (filter == '') {
      this.dataSourceMyPhrases = this.dictionary[0]?.dictionary;
    }
  }
  hideSelectProvider($event) {
    if ($event.tab.textLabel === 'Company Phrases') {
      this.showSelectProviderFilter = true;
    } else {
      this.showSelectProviderFilter = false;
    }
  }
  companyPhraseFilter(filterValue: string) {
    let filter = filterValue.trim().toLowerCase();
    if (filter) {
      let filterVal = [];
      this.phrasesToFilter.forEach(phrase => {
        if (phrase.key?.toLowerCase().includes(filter)) {
          filterVal.push(phrase)
        }
      });
      this.dataSourceSystemPhrases.data = filterVal;
    } else if (filter == '') {
      this.dataSourceSystemPhrases.data = this.phrasesToFilter;
    }
  }

  exportPhrasesAsXlxs() {
    this.loader.startLoader( this.loaderId );
    this.phrasesService.exportPhrasesAsXlxs( this.authService.currentUser._id ).subscribe(async ( res: any ) => {
        if ( res instanceof HttpResponse ) {
          this.loader.stopLoader( this.loaderId );

          let textPromise = Promise.resolve({ status: 200 });
          if ( res.body.type === 'application/json' ) {
            textPromise = res.body.text();
          }

          let resTextAsJson = await textPromise;
          if ( typeof resTextAsJson === 'string' ) {
            try {
              resTextAsJson = JSON.parse( resTextAsJson );  
            } catch ( error ) {
              console.log( error );
            }
          }

          if ( resTextAsJson?.status === 500 ) {
            this.toastr.error( 'Something went wrong', 'Error' );
            return;
          }

          let
            url = window.URL.createObjectURL( res.body ),
            a = document.createElement( 'a' );

          document.body.appendChild( a );

          a.setAttribute( 'style', 'display: none' );
          a.href = url;
          a.download = `Phrases`;
          a.click();

          window.URL.revokeObjectURL( url );
          a.remove(); // remove the element
        }
    });
  }
}
