import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { DashboardService } from 'src/app/includes/dashboard/dashboard.service';
import { SnfWcNurseAssociationService } from 'src/app/includes/snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import * as global from 'src/app/includes/global';
@Component({
  selector: 'app-associated-provider-information',
  templateUrl: './associated-provider-information.component.html',
  styleUrls: ['./associated-provider-information.component.css']
})
export class AssociatedProviderInformationComponent implements OnInit {
  providerId: any;
  associatedProviders: any = [];
  dataSource = new MatTableDataSource([]);
  displayedColumns: any = ['first_name', 'last_name', 'email'];
  currentProfileUser: any = {};


  constructor(
    private _dashboardService: DashboardService,
    private _activatedRoute: ActivatedRoute,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
  ) {
    this.providerId = this._activatedRoute.snapshot.paramMap.get('provider_id');
  }

  async ngOnInit() {
    await this.getCurrentProfileUser();
    if (this.currentProfileUser.user_type === global.USER_TYPE.SNF_WC_NURSE)
      await this.initAssociatedSnfWcNurseList();
    else
      this.getAssociatedProviders();
  }

  async getCurrentProfileUser() {
    const response: any = await lastValueFrom(this._dashboardService.getUserDetail(this.providerId));
    if (response.status === 200)
      this.currentProfileUser = response.data.array;
  }

  async initAssociatedSnfWcNurseList() {
    const filter = {
      snf_wc_nurse_id: this.currentProfileUser._id,
      company_id: this.currentProfileUser.company_id
    };
    const project = {
      first_name: 1,
      last_name: 1,
      title: 1,
      email: 1
    };
    const response: any = await lastValueFrom(this._snfWcNurseAssociationService.getAssociatedSnfWcNurses(filter, project));
    if (response.status === 200) {
      this.associatedProviders = response.data.associated_snf_wc_nurse_ids || [];
      this.dataSource.data = this.associatedProviders;
    }
  }

  getAssociatedProviders() {
    this._dashboardService.getP2PRelation(null, this.providerId).subscribe((response: any) => {
      if (response.data.assoc_provider_id) {
        this.associatedProviders = response.data.assoc_provider_id;
        this.dataSource.data = this.associatedProviders;
      }
      console.log("res", response.data);
    });
  }

}
