import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoginService } from '../login/login.service';
import { ToastrService } from 'ngx-toastr';
import { RegisterService } from '../register/register.service';





@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.css']
})

export class EmailVerificationComponent {
  public currentUser:any;
  public response:any;
  public status:any;
  public register_auth:any;

  public constructor(private router: Router,
    public route: ActivatedRoute, 
    private _toastr:ToastrService, 
    private registerService:RegisterService
    ) {
  }
  ngOnInit() {
    // localStorage.removeItem('currentUser');
    this.register_auth = JSON.parse(localStorage.getItem('register_auth')); // {_id, user_type, auth_key, email}
    this.registerService.sendVerificationCode(this.register_auth).subscribe((sendVerificationCodeResponse:any) => {
      if(sendVerificationCodeResponse.status === 200) {
        // localStorage.setItem('register_auth', JSON.stringify(register_auth));
      }
    })
  }
  checkVerificationCode(form: NgForm){
    if(form.invalid) {
      return;
    }
    const infoToast = this._toastr.info('Verifying...', "Please wait", {disableTimeOut:true});
    const details = {
      id:this.register_auth._id,
      ...this.register_auth,
      verification_code: form.value.code
    }
    this.registerService.checkVerificationCode(details).subscribe(response => {
      console.log("Response: ", response);
      
      this.response = response;
      this.status = this.response['status'];
      if(this.response['status'] === 200){ 
        localStorage.setItem('currentUser', JSON.stringify({_id:this.register_auth.id, ...this.register_auth}));
        localStorage.removeItem('register_auth');
        this.router.navigate(['/dashboard'])
      } else {
        this._toastr.error(this.response.message, "Failed");
      }
      
    }, console.error, () => this._toastr.clear(infoToast.toastId));
    
  }
}
