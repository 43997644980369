import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-mat-multi-select-chips',
    templateUrl: './mat-multi-select-chips.component.html',
    styleUrls: ['./mat-multi-select-chips.component.css']
})

export class MatMultiSelectChipsComponent implements OnInit {
    
    @Input() element: any;     
    @Input() allData: any;
    @Input() isProvider: boolean;
    @Input() isCompany: boolean;
    @Input() isFacility: boolean;
    @Input() selectAll: boolean;
    @Input() shouldShowSelectAll: boolean;
    @Input() isPhrase: boolean;
    @Input() isTemplate: boolean;
    @Input() ngModelData: any;
    @Output() onDataSelection = new EventEmitter();
    @Output() onDataRemove = new EventEmitter();
    @Input() providerKey: any;
    @Input() isMulti: boolean;
    searchProviderText: any;
    searchTemplateText: any;
    searchFacilityText: any;
    defaultSource: any;
    typesObject: any = {
        assos_providers: 'provider',
        assoc_bo_ids: 'provider',
        assoc_ma_ids: 'provider',
        assoc_company_ids: 'company',
        nurse_ids_ref: 'provider',
        assoc_phrase_ids: 'phrase',
        transcribers: 'provider',
        associated_snf_wc_nurse_ids: 'provider',
        template_ids_ref: 'template',
        associated_provider_ids: 'provider',
        associated_wound_nurse_ids: 'provider',
        provider_ids_ref: 'provider',
        associatedFacilities: 'facility'
    }

    public constructor() {
        
    }

    ngOnInit() {
        if(this.providerKey) {
            this.allData = this.allData.filter(provider => provider._id != this.element._id)
        }
    }

    onSelectData($event, element) {
        if($event.isUserInput) {
            let data = $event.source.value;
            this.searchProviderText = '';
            this.searchFacilityText = '';
            this.searchTemplateText = ''
            if($event.source.value == 'selectAll') {
                element[this.providerKey] = this.allData;    
            }
            else if($event.source.value == 'unSelect') {
                element[this.providerKey] = [];
            }
            else {
                if(!$event.source._selected) {
                    element[this.providerKey] = element[this.providerKey].filter(pro => pro._id !== data._id)
                }
               else {
                    element[this.providerKey] = [...element[this.providerKey] || [], data]
                }
            }
            this.onDataSelection.emit({element, data, status: $event.source._selected, type: this.typesObject[this.providerKey]});

        }
        
    }

    onRemoveData(data, element) {
        this.searchProviderText = '';
        this.searchFacilityText = '';
        this.searchTemplateText = ''
        element[this.providerKey] = element[this.providerKey].filter(pro => pro._id !== data._id)
        
        this.onDataRemove.emit({data, element, type: this.typesObject[this.providerKey]});
    }    

    compareData(data1: any, data2: any): boolean {
        return data1 && data2 ? data1._id === data2._id : data1 === data2;
    }

    isPCCFacility(facility) {
        return facility.source === 'PointClickCare' || facility.pcc_facId || this.defaultSource === 'PointClickCare';
    }
    isMatrixFacility(facility) {
        return facility.source === 'MatrixCare';
    }
    isDNFacility(facility) {
      return facility.source != 'PointClickCare' && facility.source != 'MatrixCare' && !facility.pcc_facId && this.defaultSource != 'PointClickCare'&& facility.source != 'All';
    }
}

