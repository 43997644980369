import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as global from '../global';
import { AuthService } from 'src/app/services/auth.service';
import {API_URL} from "../../../environments/api-url";
import { BehaviorSubject } from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
    currentUser:any=null;
  public currentTab: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private httpclient: HttpClient,
    private _authService: AuthService) {
    // const currentUser = localStorage.getItem('currentUser');
    const currentUser = this._authService.currentUser;
    if(currentUser) {
        // this.currentUser = JSON.parse(currentUser)
        this.currentUser =  currentUser;
    }
   }
   getUserDetail(user_id) {
    const payload = {
      id: this.currentUser._id,
      usertype: this.currentUser.user_type,
      auth_key: this.currentUser.auth_key,


      user_id
    }
    return this.httpclient
        .post(global.url + API_URL.USER.getUserDetail, {details: payload}).pipe();
   }
   changeUserActiveStatus(userId:string, status) {
      const payload = {
        id: this.currentUser._id,
        usertype: this.currentUser.user_type,
        auth_key: this.currentUser.auth_key,
        userId,
        is_activated: status
      }
      return this.httpclient
          .post(global.url + API_URL.AUTH.changeUserActiveStatus, {details: payload}).pipe();
   }
   changeChargeStatus(userId:string, status) {
      const payload = {
        id: this.currentUser._id,
        usertype: this.currentUser.user_type,
        auth_key: this.currentUser.auth_key,
        userId,
        charge_status: status
      }
      return this.httpclient
          .post(global.url + API_URL.AUTH.changeChargeStatus, {details: payload}).pipe();
   }

   changeRRGenStatus(userId:string, status) {
      const payload = {
        id: this.currentUser._id,
        usertype: this.currentUser.user_type,
        auth_key: this.currentUser.auth_key,
        userId,
        charge_status: status
      }
      return this.httpclient
          .post(global.url + API_URL.AUTH.changeRRGenStatus, {details: payload}).pipe();
   }
   changeTelemedicineStatus(userId:string, status) {
      const payload = {
        id: this.currentUser._id,
        usertype: this.currentUser.user_type,
        auth_key: this.currentUser.auth_key,
        userId,
        status: status
      }
      return this.httpclient
          .post(global.url + API_URL.AUTH.changeTelemedicineStatus, {details: payload}).pipe();
   }
  
   emailNotificationStatus(data = {}) {
    data = {
      ...data,
      userId: this._authService.currentUser._id
    }
    const payload = {
      id: this.currentUser._id,
      usertype: this.currentUser.user_type,
      auth_key: this.currentUser.auth_key,
      data: data
    }
    return this.httpclient
      .post(global.url + API_URL.USER.emailNotificationStatus, { details: payload, auth: payload }).pipe();
  }
  
  changeMatrixPermission(userId: string, status) {
    const payload = {
      id: this.currentUser._id,
      usertype: this.currentUser.user_type,
      auth_key: this.currentUser.auth_key,
      userId,
      status: status
    }
    return this.httpclient.post(global.url + API_URL.AUTH.changeMatrixPermission, { details: payload }).pipe();
  }
   changeTATStatus(userId:string, status) {
      const payload = {
        id: this.currentUser._id,
        usertype: this.currentUser.user_type,
        auth_key: this.currentUser.auth_key,
        userId,
        tat_status: status
      }
      return this.httpclient
          .post(global.url + API_URL.AUTH.changeTATStatus, {details: payload}).pipe();
   }
   changeAdminStatus(userId:string,company_id, status) {
    
      const payload = {
        id: this.currentUser._id,
        usertype: this.currentUser.user_type,
        auth_key: this.currentUser.auth_key,
        userId,
        admin_status: status,
        company_id
      }
      return this.httpclient
          .post(global.url + API_URL.AUTH.changeAdminStatus, {details: payload}).pipe();
   }
  updateUser(data = {}, company_id?) {
    data = {
      ...data,
      userId: this._authService.currentUser._id
    }
    const payload = {
      id: this.currentUser._id,
      usertype: this.currentUser.user_type,
      auth_key: this.currentUser.auth_key,
      data: data,
      company_id : company_id ? company_id : this.currentUser.company_id
    }
    return this.httpclient
      .post(global.url + API_URL.USER.updateUser, { details: payload, auth: payload }).pipe();
  }
    changeMatrixStatus(userId:string, status) {
      const payload = {
        id: this.currentUser._id,
        usertype: this.currentUser.user_type,
        auth_key: this.currentUser.auth_key,
        userId,
        status: status
      }
      return this.httpclient
          .post(global.url + API_URL.AUTH.changeMatrixStatus, {details: payload}).pipe();
   }
  setCurrentTab(currentTab: string) {
    this.currentTab.next(currentTab);
  }

}
