<div class="container" style="display: flex; flex-direction: column !important;min-height: unset !important;height: 100% !important;">
    <mat-card>
        <mat-card-content class="header">
            <mat-tab-group (selectedTabChange)="onTabClick($event)" style="height: 100% !important;">
                <mat-tab label="ICDs" >
                    <div class="mat-tab-content" *ngIf="tabIndex == 0">
                        <app-manage-icd-codes></app-manage-icd-codes>
                    </div>
                </mat-tab>
                <mat-tab label="IE FU Mapping">
                    <div class="mat-tab-content" *ngIf="tabIndex == 1">
                        <app-ie-fu-mapping></app-ie-fu-mapping>
                    </div>
                </mat-tab>
                <mat-tab label="Company CPT Association">
                    <div class="mat-tab-content" *ngIf="tabIndex == 2"> 
                        <app-manage-cpt-codes></app-manage-cpt-codes>
                    </div>
                </mat-tab>
                <mat-tab label="CPT POS">
                    <div class="mat-tab-content" *ngIf="tabIndex == 3">
                        <app-cpt-pos></app-cpt-pos>
                    </div>
                </mat-tab>
                <mat-tab label="CPTs">
                    <div class="mat-tab-content" *ngIf="tabIndex == 4">
                        <app-manage-cpts></app-manage-cpts>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </mat-card>
</div>