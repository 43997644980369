<i *ngIf="currentuser._id == note.doctor_id || showDeleteIcon"
matTooltip="delete note"
matTooltipPosition="above"
>
        <ng-container 
        *ngIf="!note.coSign_status?.status &&  note.is_signed == 'false' && note.is_completed == 'false'"
        >
        <mat-icon style="cursor: pointer;">delete</mat-icon>
        </ng-container>
        <ng-container 
        *ngIf="note.coSign_status?.status && note.is_signed == 'false' && note.is_completed == 'false'"
        >
         <mat-icon style="cursor: pointer;">delete</mat-icon>
        </ng-container>

</i>