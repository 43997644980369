import { Component, OnInit } from '@angular/core';
import { PhrasesService } from '../../phrases/phrases.service';
import { ActivatedRoute } from '@angular/router';
import { ProviderProviderAssociationService } from '../../provider-provider-association/provider-provider-association.service';
import { forEach } from 'lodash';
import { ProviderPhraseAssociationService } from './phrases-association.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-phrases-association',
  templateUrl: './phrases-association.component.html',
  styleUrls: ['./phrases-association.component.css']
})
export class PhrasesAssociationComponent implements OnInit {

  
  displayedColumns: string[] = ['providers', 'phrases'];
  public dataSource = [];
  public providers: any =[];
  public relationData: any;
  phrasesDropdownSettings = {};
  company_id:any;
  providerPhrase: Boolean = true;

  selectedItems = [];
  dropdownList = [];
  addPhraseKey: {};
  phrases :any = [];
  selectedProvider: any;

  constructor(
              private phrasesService: PhrasesService,
              private route: ActivatedRoute,
              private phraseAssociateService: ProviderPhraseAssociationService,
              private toastr: ToastrService
  ) { }

  
  
  async ngOnInit() {
    
    this.dropdownList = [];
    this.selectedItems = [];
    
    this.phrasesDropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'key',
      selectAllText: 'Select All',
      enableCheckAll: false,
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 'All',
      allowSearchFilter: true
    };


    if (window.location.pathname.includes('company')) {
      this.company_id = this.route.parent.snapshot.params.id
  }
  else {
      this.company_id = '';
  }
    await this.loadDataForProvider();
  }

  async loadDataForProvider() { 
    const filter: any = {
      company_id: this.company_id,
    };
    const projection = {
      'title': 1,
      'first_name': 1,
      'last_name': 1,
      'full_name': 1
    }
    let res = await this.phraseAssociateService.getAllSameCompanyProviders(filter,projection).subscribe(async (data:any)  => {
      if(data.status == 200){
        this.providers = data;
        this.providers = this.providers.data;
        await this.loadDataForPhrases();
        this.dataSource = this.providers;
      }
    });
  }

  onDataSelection($event) {
    if($event.data == 'selectAll') {
      this.onSelectAll($event.element.assoc_phrase_ids, $event.element);
  
    }
    else if($event.data == 'unSelect') {
    }
    else {
      if(!$event.status) {
        this.onPhraseDeSelect($event.data, $event.element);
      }
      else {
        this.onPhraseSelect($event.data, $event.element);
      }
    }
  }
  
  onDataRemove($event) {
    this.onPhraseDeSelect($event.data, $event.element);
  }

  async loadDataForPhrases() {
    this.phrasesService.getUserDefinedCompanyPhrases({ company_id: this.company_id, is_activated: "true" }).subscribe(async (res: any) => {
      if (res.status == 200) {
        await this.loadRelations();
        this.phrases = res.data[0].user_phrases;
        this.sortPhrases(this.phrases);
        this.providers.forEach(provider => {
          provider.phrases = this.phrases;
        });
      }
    });
  }

  async loadRelations() {
    this.phraseAssociateService.getPhraseAssociations({ company_id: this.company_id }).subscribe((res: any) => {
      if (res.status == 200) {
        let associatedPhrases = res.data;
        this.providers.forEach(provider => {
          provider.assoc_phrase_ids = [];
          associatedPhrases.forEach(phrase => {
            if (phrase.provider_id == provider._id) {
              provider.assoc_phrase_ids = phrase.assoc_phrase_ids;
            }
          });
        });
      }
    });
  }

  getMarker() {
    return this.phrasesService.marker
  }
  
  onSelectAll(event, provider) { 
    const details = {
      providerID: provider._id,
      PhraseID : this.phrases.map(a=> {a._id})
    } 
    this.createRelationProvider2Phrase(details);
  }

  onPhraseSelect(phrase, selectedProvider) {  
 
    const details = {
      ProviderID : selectedProvider._id, 
      PhraseID : phrase._id,
      company_id: selectedProvider.company_id
    } 
    this.createRelationProvider2Phrase(details);
  }
  createRelationProvider2Phrase(details) { 
    this.phraseAssociateService.createRelationProvider2Phrase(details).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("Relationship updated");
      }
    })
  }

  onPhraseDeSelect(phrase, selectedProvider) {  
    const details = {
      ProviderID : selectedProvider._id, 
      PhraseID : phrase._id,
      company_id: selectedProvider.company_id
    }
    this.deleteRelationProvider2Provider(details);
  }

  deleteRelationProvider2Provider(details) { 
    this.phraseAssociateService.deleteRelationProvider2Phrase(details).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("Relationship updated");
      }
    })
  }


  applyFilter(filterValue: string) {
    let filter = filterValue.trim().toLowerCase();
    if (filter) {
      let filterVal = [];
      this.providers.forEach(provider=> {
        if (provider.full_name.trim().toLowerCase().includes(filter)) {
          filterVal.push(provider)
          this.dataSource = filterVal;
        }
      });
    }
    else {
      this.dataSource = this.providers;
    }
  }
  sortPhrases(arr: any[]) {
    arr.sort((a, b) => a.key.toLowerCase().localeCompare(b.key.toLowerCase()));
  }

}
