<div>
    <div class="flex items-start justify-between gap-5">
        <app-input-with-dropdown class="flex-grow" [noDropdown]="true" placeholder="Search CPT Code"
            (inputChange)="applyFilter($event.target.value)">
        </app-input-with-dropdown>
    </div>
    <div style="height: 10px;"></div>
    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="cpt-code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CPT Code </th>
            <td mat-cell *matCellDef="let element" style="width: 30%"> {{element.code + ' (' +
                element.description.substr(0,45) +
                ")"}}
            </td>
        </ng-container>
        <ng-container matColumnDef="icd-code">
            <th mat-header-cell *matHeaderCellDef>ICD Code </th>
            <td mat-cell *matCellDef="let element;let i=index" style="width: 40%">
                    <ng-container *ngIf="element.cptrelation.length > 0">
                        <ng-container *ngFor="let relation of element.cptrelation">
                            <ng-container *ngIf=" relation.icd_code_ids != undefined && relation.icd_code_ids.length > 0">
                                <mat-chip-list #chipGrid aria-label="" [ngModelOptions]="{standalone: true}">
                                    <mat-chip *ngFor="let code of relation.icd_code_ids;let code_index=index"
                                        (removed)="removeRelation(element._id,code._id,i,code_index)"  style="margin-right: 5px;">
                                        {{code.code}}
                                        <button style="background: none;
                                        display: flex;
                                        color: white;
                                        align-content: center;
                                        border: none;" matChipRemove>
                                            <mat-icon style="font-size: 14px !important;">close</mat-icon>
                                        </button>
                                    </mat-chip>
                                </mat-chip-list>
                            </ng-container>
                        </ng-container>
                    </ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="search">
            <th mat-header-cell *matHeaderCellDef> Search ICD Code </th>
            <td mat-cell *matCellDef="let element;let i=index" style="width: 30%">
                <mat-form-field style="width: 100%;">
                    <mat-label>Select ICD Codes</mat-label>
                    <mat-select>
                        <mat-form-field style="width: 97%;margin-left: 5px;margin-right: 5px;">
                            <input matInput placeholder="Search" (keyup)="searchDiagnose($event.target.value)" [ngModelOptions]="{standalone: true}"
                                style="color:#000;height: 20px;padding-left: 5px;"
                                (keydown)="$event.stopPropagation()" />
                            <mat-spinner *ngIf="icdLoaded" matSuffix [diameter]="18"
                                style="float: right; margin-left: 8px"></mat-spinner>
                        </mat-form-field>
                        <mat-option id="{{icdcode.code}}" *ngFor="let icdcode of icdCodeList"
                            (click)="addRelation(icdcode,element._id,i)">{{icdcode.code + ' (' +
                            icdcode.mediumDescription +')'}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>