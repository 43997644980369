import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { ProviderPhraseAssociationService } from '../phrases/phrases-association/phrases-association.service';

@Component({
  selector: 'app-business-officer-association',
  templateUrl: './business-officer-association.component.html',
  styleUrls: ['./business-officer-association.component.css']
})
export class BusinessOfficerAssociationComponent implements OnInit {


  displayedColumns: string[] = ['providers', 'business_officer'];
  dataSource: any = new MatTableDataSource([]);
  public providers: any = [];
  public relationData: any;
  businessOfficeDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'full_name',
    selectAllText: 'Select All',
    enableCheckAll: true,
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 'All',
    allowSearchFilter: true
  };
  company_id: any;
  providerPhrase: Boolean = true;

  selectedItems = [];
  dropdownList = [];
  businessOfficers: any = [];
  selectedProvider: any;

  constructor(
    public services: UserTypesRelationshipService,
    private route: ActivatedRoute,
    private phraseAssociateService: ProviderPhraseAssociationService,
    private toastr: ToastrService
  ) { }



  async ngOnInit() {
    if (window.location.pathname.includes('company')) {
      this.company_id = this.route.parent.snapshot.params.id
    }
    else {
      this.company_id = '';
    }
    await this.loadDataForProvider();
  }

  async loadDataForProvider() {
    const filter: any = {
      company_id: this.company_id,
    };
    const projection = {
      'title': 1,
      'first_name': 1,
      'last_name': 1,
      'full_name': 1
    }
    let res = await this.phraseAssociateService.getAllSameCompanyProviders(filter,projection).subscribe((data: any) => {
      if (data.status == 200) {
        this.loadDataForBOs();
        this.providers = data;
        this.providers = this.providers.data;
        this.dataSource = this.providers;
      }
    });
  }

  async loadDataForBOs() {
    this.services.getBOList('true', this.company_id).subscribe((res: any) => {
      // console.log("response",res.data);
      if (res.status == 200) {
        this.loadRelations();
        this.businessOfficers = res.data;
        this.businessOfficers.forEach(element => {
          element['full_name'] = element['first_name'] + ' ' + element['last_name'];
        });
        // console.log('medical assistants', this.businessOfficers);
        this.providers.forEach(provider => {
          provider.businessOfficers = this.businessOfficers;
        });
      }
    });
  }

  async loadRelations() {
    this.services.getBOAssociations(this.company_id).subscribe((res: any) => {
      if (res.status == 200) {
        let associatedBOs = res.data;
        console.log("this.providers", this.providers);
        console.log("this.associatedBOs", associatedBOs);
        this.providers.forEach(provider => {
          associatedBOs.forEach(bo => {
            if (bo.provider_id == provider._id) {
              if (bo?.assoc_bo_ids) {
                bo.assoc_bo_ids = bo.assoc_bo_ids.map(data => {
                  data.full_name = data.first_name + ' ' + data.last_name
                  return data;
                }
                );

                provider.assoc_bo_ids = bo.assoc_bo_ids;
              }
              else {
                provider.assoc_bo_ids = [];
              }
            }
          });
        });
      }
    });
  }

  onDataSelection($event) {
    // let providers = element.associated_provider_ids.map(a => a._id);
    // let idx = this.users.findIndex(user => user._id.toString == element._id.toString());
    // this.users[idx] = element;
    // this.dataSource.data = this.users;
    if($event.data == 'selectAll') {
      this.onBOSelectAll($event.element.assos_providers, $event.element);

    }
    else if($event.data == 'unSelect') {
      this.onBODeSelectAll([], $event.element);
    }
    else {
      if(!$event.status) {
        this.onBODeSelect($event.data, $event.element);
      }
      else {
        this.onBOSelect($event.data, $event.element);
      }
    }
  }

  onDataRemove($event) {
    this.onBODeSelect($event.data, $event.element);
  }

  onBOSelectAll(event, provider) {
    const details = {
      providerID: provider._id,
      BO_ID: this.businessOfficers.map(a =>  a._id ),
      // company_id: provider.company_id
      company_id: this.company_id
    }
    this.createRelationProvider2BO(details);
  }

  onBODeSelectAll(event, provider) {
    const details = {
      providerID: provider._id,
      BO_ID: [],
      // company_id: provider.company_id
      company_id: this.company_id
    }
    this.createRelationProvider2BO(details);
  }

  onBOSelect(businessOffice, selectedProvider) {
    const details = {
      providerID: selectedProvider._id,
      BO_ID: [businessOffice._id],
      // company_id: selectedProvider.company_id
      company_id: this.company_id
    }
    this.createRelationProvider2BO(details);
  }
  createRelationProvider2BO(details) {
    this.services.createRelationProvider2BO(details).subscribe((response: any) => {
      if (response.status == 200) {
        // console.log('success');
        this.toastr.success("Relationship updated");
      }
    })
  }

  onBODeSelect(businessOffice, selectedProvider) {
    const details = {
      ProviderID: selectedProvider._id,
      BO_ID: businessOffice._id,
      // company_id: selectedProvider.company_id
      company_id: this.company_id
    }
    this.deleteRelationProvider2BO(details);
    selectedProvider.assoc_bo_ids = selectedProvider.assoc_bo_ids.filter(pro => pro._id !== businessOffice._id);
  }

  deleteRelationProvider2BO(details) {
    this.services.deleteRelationProvider2BO(details).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("Relationship updated");
      }
    })
  }


  applyFilter(filterValue: string) {
    let filter = filterValue.trim().toLowerCase();
    if (filter) {
      let filterVal = [];
      this.providers.forEach(provider => {
        if (provider.full_name.trim().toLowerCase().includes(filter)) {
          filterVal.push(provider)
          this.dataSource = filterVal;
        }
      });
    }
    else {
      this.dataSource = this.providers;
    }
  }

}
