<app-header></app-header>
<main>
    <div class="h100 flex">
        <div class="left-col container"
            [ngClass]="{'left-col-close': leftColClose === true, 'col-close-width': leftColWidth === true}">
            <div class="flex-grow" style="display: flex; flex-direction: column; height: 100%;">
                <div>
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Documents
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul>
                            <li [ngClass]="{'active': true}" matTooltipPosition="right" (click)="refreshDashboard()">
                                <img src="../../../assets/img/icons/all_icon.png">
                                <span>All Documents</span>
                            </li>
                        </ul>
                    </mat-expansion-panel>
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Wound Reports
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul >
                            <li *ngFor="let report of reportsNames" [ngClass]="{'active': true}" matTooltipPosition="right" (click)="openReport(report.url)">
                                <img src="../../../assets/img/icons/all_icon.png">
                                <span class="reportTitle" matTooltip="{{report.title}}" matTooltipPosition="above">{{report.title}}</span>
                            </li>
                        </ul>
                    </mat-expansion-panel>
                </div>
            </div>
        </div>
        <div class="right-col container flex-grow" id="bar"
            [ngClass]="{'left-col-close': leftColClose === true, 'col-close-width': leftColWidth === true}">
            <div *ngIf="dashboardView" class="sidebar-content" style="flex-direction:column">
                <h3 class="dark-blue-heading flex flex-grow" style="width: 100%;padding: 0px !important; ">
                    <div class="flex flex-grow ml-2.5"> Documents </div>
                    <div class="flex justify-center items-center">
                        <input matInput type="text" ngxDaterangepickerMd [(ngModel)]="noteDateFilter"
                            matTooltip="Date of submission" matTooltipPosition="above" class="mat-like-input"
                            [ranges]="preDateRanges" [alwaysShowCalendars]="true" [linkedCalendars]="true"
                            [showClearButton]="false" (datesUpdated)="applyDateFilterTrans($event)" style="color: white; cursor: pointer;
                                    width: 225px;
                                    height: 50px;
                                    font-size: 16px !important;" />
                    </div>
                    <div class="flex flex-grow gap-2" style="place-content: flex-end;">
                        <div style="font-size: 16px; cursor: pointer;" (click)="refreshDashboard()">
                            Refresh
                        </div>
                        <i class="icon icon-refresh-white" matTooltip="Refresh" (click)="refreshDashboard()"></i>
                    </div>
                </h3>
                <div class="search-block theme-form flex gap-2 justify-start items-center"
                    style="background-color: white; border-radius: 4px; padding-top: 8px; padding-bottom: 5px; padding-left: 8px;padding-right: 8px;">
                    <div style="width: 100%">
                        <div class="flex justify-start items-center gap-4">
                            
                            <mat-form-field style="width: 100%;">
                                <mat-label>Facility</mat-label>
                                <mat-select name="facility" #facility="ngModel" [(ngModel)]="selectedfacility">
                                  <input matInput placeholder="Search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
                                    style="color:#000;height: 40px;padding-left: 5px;" (keydown)="$event.stopPropagation()" />
                                  <mat-option *ngFor="let facility of assoc_Facilities | searchFacility: searchText; let i=index"
                                  [value]="facility" (click)="getProvidersByFacility(i)">
                                    <app-facility-title [facility]="facility"></app-facility-title>
                                  </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="flex-grow" style="color: black !important;">
                                <mat-label>Providers</mat-label>
                                <mat-select name="provider" #provider="ngModel" [(ngModel)]="selectedProvider"
                                    (ngModelChange)="getAllProviderNotes()">
                                    <input matInput placeholder="Search" [(ngModel)]="searchTextProvider"
                                        [ngModelOptions]="{standalone: true}"
                                        style="color:#000;height: 40px;padding-left: 5px;" />
                                    <mat-option
                                        *ngFor="let provider of assoc_providersList | searchProvider : searchTextProvider"
                                        [value]="provider">
                                        {{displayProviderFn(provider)}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="short-dropdown" style="color: black !important;">
                                <mat-label>Signed Status</mat-label>
                                <mat-select [(ngModel)]="tranFilters.progress_status" name="status">
                                    <mat-option *ngFor="let status of statusOptionTrans" [value]="status"
                                        (click)="onChangeStatusTrans(status);">
                                        {{status}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div>
                        <ng-container>
                            <div class="flex gap-2">
                                <button *ngIf="multiSelectedTranscriptions.length > 0" mat-raised-button
                                    class="dn-button-primary" style="width: 100px;"
                                    [matMenuTriggerFor]="downloadNoteBtn">
                                    Export Notes
                                </button>
                                <mat-menu #downloadNoteBtn="matMenu">
                                    <button mat-menu-item (click)="exportNote('download')">Download</button>
                                    <button mat-menu-item (click)="exportNote('send_to_email')">Send to
                                        Email</button>
                                </mat-menu>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div style="height: 5px;"></div>
                <div class="white-box flex flex-grow gap-2" style="margin-bottom: 5px; width: 100%;height: 52px;">
                    <div style="width: 58%;text-align: start; display: flex;align-items: center;">
                        <span style="width: 73%;">
                            Title
                        </span>
                        <mat-form-field style="color: black !important; margin-left: 10px; width: 25%;">
                            <mat-select [ngModel]='transGroup.group' [value]="transGroup.group" name="group">
                                <mat-option *ngFor="let type of transGrouping" [value]="type.group"
                                    (click)="groupTranscriptionsBy(type.value);">
                                    {{type.group}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div
                        style="width: 54%;display: flex; flex-direction: row-reverse; text-align: start;align-items: center;">
                        <span style="width: 120px;">Note Status</span>
                        <span style="width: 166px;">DOS</span>
                        <span style="width: 156px;">Patient</span>
                    </div>
                </div>
                <div style="margin-top: 5px;" class="white-box" *ngIf="isNotesLoading()">Refreshing List...</div>

                <ng-container style="margin-top: 5px;"
                    *ngFor="let item of groupedSortedTranscriptions | keyvalue: _commonService.keyAscOrder">
                    <app-transcription-list [transcriptions]="item.value" [group_index]="item.key" [tranFilters]="tranFilters"
                        [transcriptionFiles]="sortedTransriptions"
                        [multiSelectedTranscriptions]="multiSelectedTranscriptions"
                        (toggleTranscriptionMultiSelect)="toggleTranscriptionMultiSelect($event)">
                    </app-transcription-list>
                </ng-container>
            </div>

            <div *ngIf="!dashboardView" class="sidebar-content" style="flex-direction:column">
                <app-dns-report *ngIf="reportView=='dns-report'"></app-dns-report>
                <app-qapi-report *ngIf="reportView=='qapi-report'"></app-qapi-report>
                <app-wound-periodic-treatment-report *ngIf="reportView=='weeks-in-treatment-report'"></app-wound-periodic-treatment-report>
                <app-wound-healing-rate-report *ngIf="reportView=='healing-success-rate-report'"></app-wound-healing-rate-report>
                <app-uwh-type-tabular-report *ngIf="reportView=='uwh-type-tabular-report'"></app-uwh-type-tabular-report>
                <app-patient-discharge-report *ngIf="reportView=='discharged-patient-report'"></app-patient-discharge-report>
                <app-dns-healing-report *ngIf="reportView=='dns-healing-report'"></app-dns-healing-report>
                <app-visit-report *ngIf="reportView=='visit-report'"></app-visit-report>
                <app-kent-visit-report *ngIf="reportView=='kent-visit-report'"></app-kent-visit-report>
                <app-missed-facility-report *ngIf="reportView=='missed-facility-report'"></app-missed-facility-report>
                <app-debridement-report *ngIf="reportView=='debridement-report'"></app-debridement-report>
                <app-tissue-analytic-report *ngIf="reportView=='dns-tissue-analytic-report'"></app-tissue-analytic-report>
                 <app-admin-charges-report *ngIf="reportView=='admin-charges-report'"></app-admin-charges-report>
                <app-los-report *ngIf="reportView=='los-report'"></app-los-report>
                <app-ipa-report *ngIf="reportView=='ipa-report'"></app-ipa-report>
                <app-pressure-wound-report *ngIf="reportView=='pressure-wound-report'"></app-pressure-wound-report>
                <app-custom-wound-report *ngIf="reportView=='custom-wound-report'"></app-custom-wound-report>
                <app-wound-exceptions-report *ngIf="reportView=='wound-exceptions-report'"></app-wound-exceptions-report>
                <app-post-rounds-report *ngIf="reportView=='post-rounds-report'"></app-post-rounds-report>
                <app-telehealth-wound-assessment-report *ngIf="reportView=='telehealth-wound-assessment-report'"></app-telehealth-wound-assessment-report>
                <app-days-to-remission-report *ngIf="reportView=='days-to-remission-report'"></app-days-to-remission-report>

            </div>
            
        </div>
    </div>
</main>