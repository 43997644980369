import { Injectable } from '@angular/core';
import * as global from '../includes/global';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {API_URL} from "../../environments/api-url";
import {HttpClient,HttpHeaders} from '@angular/common/http';
// import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  currentUser: any;

  constructor(private httpclient: HttpClient,
              private _authService: AuthService) {
      this.currentUser = this._authService.currentUser;
  }
   // Add new order set
   addNewOrderSet(data: any): Observable<any> {
    const {  company_id, order_set } = data
    const details = {
      created_by: this.currentUser._id,
      company_id,
      order_set
  };
    return this.httpclient.post(global.url + API_URL.ORDER.addNewOrderSet, details);
  }

  // Update order set
  updateOrderSet(data): Observable<any> {
    const {updateOrderSet, company_id} = data;
    const details={
      created_by: this.currentUser._id,
      company_id,
      updateOrderSet
    }
    return this.httpclient.post(global.url + API_URL.ORDER.updateOrderSet, details);
  }

  // Add new order
  addNewOrder(data: any): Observable<any> {
    const {orderSetID, company_id, order} = data;
    const details={
      created_by: this.currentUser._id,
      company_id,
      orderSetId:orderSetID,
      order
    }
    return this.httpclient.post(global.url + API_URL.ORDER.addNewOrder, details);
  }

  // Update order
  updateOrder(orderId: string, updatedData: any): Observable<any> {
    return this.httpclient.post(global.url + API_URL.ORDER.updateOrder, { orderId, updatedData });
  }

  // Get orders
  getOrders(data:any): Observable<any[]> {
    const { company_id} = data;
    const details={
      created_by: this.currentUser._id,
      company_id,
    }
    return this.httpclient.post<any[]>(global.url + API_URL.ORDER.getOrders, details);
  }

  // Delete order set
  deleteOrderSet(data:any): Observable<any> {
    const { company_id,orderSetID} = data;
    const details={
      created_by: this.currentUser._id,
      company_id,
      orderSetID
    }
    return this.httpclient.post(global.url + API_URL.ORDER.deleteOrderSet, details);
  }

  // Delete order
  deleteOrder(data:any): Observable<any> {
    const { company_id,orderSetID,order} = data;
    const details={
      created_by: this.currentUser._id,
      company_id,
      orderSetID,
      order
    }
    return this.httpclient.post(global.url + API_URL.ORDER.deleteOrder, details);
  }
  savePatientOrder(data:any): Observable<any> {
    const { orderIds, company_id,patient_id } = data;
    const details={
      user_id: this.currentUser._id,
      company_id,
      orderIds,
      patient_id
    }
    return this.httpclient.post(global.url + API_URL.ORDER.savePatientOrder, {details});
  }
  getPatientOrders(data:any): Observable<any> {
    const { company_id,patient_id } = data;
    const details={
      user_id: this.currentUser._id,
      company_id,
      patient_id
    }
    return this.httpclient.post(global.url + API_URL.ORDER.getPatientOrders, {details});
  }
  generateOrderPDF(data:any): Observable<any> {
    const { company_id,patient_id } = data;
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');
    const details={
      user_id: this.currentUser._id,
      company_id,
      patient_id
    }
    return this.httpclient.post(global.url + API_URL.ORDER.generateOrderPDF, {details},{
      responseType: "blob",
      reportProgress: true,
      observe: 'events',
      headers
    });
  }
}
