import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/includes/login/login.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { CompanyService } from 'src/app/services/Modules/company.service';
import { CompanyLogoAndMongogramService } from 'src/app/services/company-logo-and-mongogram.service';
import { DashboardService } from 'src/app/includes/dashboard/dashboard.service';
import * as global from '../../../../app/includes/global';

@Component({
  selector: 'app-company-user-create-password',
  templateUrl: './company-user-create-password.component.html',
  styleUrls: ['./company-user-create-password.component.css'],
})
export class CompanyUserCreatePasswordComponent implements OnInit {

  verifyCodeView = false;
  code: string = '';
  first_name: string = '';
  last_name: string = '';
  newPassword: string = '';
  confirmPassword: string = '';
  hide = true;
  showScreen: boolean = false;
  params: {
    user_id: string;
    verification_code: string;
    email: string;
  } = {
      user_id: null,
      verification_code: null,
      email: null,
    };
  error: string;

  loaderId = 'user-create-password';

  passwordStrength: number = 0;
  companyCode: any;
  defaultImg: string;
  companylogoPathSubscription: any;
  companylogoPath: any;
  companylogoPathAfterLogin: any;
  policyUpperCase: boolean = false;
  policyLowerCase: boolean = false;
  policyNumber: boolean = false;
  policySpecialCharacter: boolean = false;
  policyLength: boolean = false;
  enableConfirmPassword: boolean = false;
  enableSaveButton: boolean = false;
  verifyExpiry: boolean = false;
  user: any;
  display: any;
  public timerInterval: any;
  hideVerify = true;
  disableButton: boolean = false;

  public constructor(
    private router: Router,
    public loginService: LoginService,
    private _toastr: ToastrService,
    private _router: Router,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService,
    private companyLogoAndMonogramService: CompanyLogoAndMongogramService,
    private dashboardService: DashboardService
  ) { }

  async ngOnInit() {
    this.companyCode = this.route.snapshot.queryParamMap.get('companyCode');
    if (this.companyCode) {
      await this.getCompanyLogo();
    }
    if (!this.companyCode) {
      this.defaultImg = "../../../assets/img/DNlogo-new.png";
    }

    // if (createdDate) {
    //   if (diff_in_days >= 1) {
    //     this.showScreen = true;
    //     this.verifyExpiry = true;
    //   }
    //   else {
    //     await this.loadData()
    //   }
    // }

    // else {
    await this.loadData()
    // }


  }

  async loadData() {
    console.log('this.route.snapshot.queryParamMap', this.route.snapshot.queryParamMap);
    this.params.user_id = this.route.snapshot.queryParamMap.get('user_id');
    this.params.email = this.route.snapshot.queryParamMap.get('email');
    this.params.verification_code = this.route.snapshot.queryParamMap.get('verification_code');
    await this.getUser();
  }
  async getUser() {
    const userDetail: any = await this.dashboardService.isUserVerified(this.params.user_id).toPromise();
    if (userDetail.status == 200) {
      if (userDetail.data.array == 'true') {
        this._router.navigate(['/login']);
      } else {
        this.user = userDetail.user;
        console.log('user...........',this.user);
        
        let createdDate: any;
        if(this.user.registraionSteps.linkSendDate) {
          createdDate = new Date(this.user.registraionSteps.linkSendDate);
        }
        let currentDate: any;
        let diff_in_days: any;
        if(createdDate){
          currentDate = new Date();
          let diff = currentDate.getTime() - createdDate.getTime();
          diff_in_days = Math.floor(diff / (1000 * 3600 * 24));
        }
        this.showScreen = true;
        if (diff_in_days >= 1 && createdDate) {
          this.verifyExpiry = true;
        }
        else {
          if(!this.user.cognito || !this.user.registraionSteps.passwordCreated){
            this.showScreen = true;
            this.verifyExpiry = false;
            this.verifyCodeView = false;
            return;
          }
          else if (this.user.registraionSteps.passwordCreated && !this.user.registraionSteps.twoFacAuth) {
            this.verifyCodeView = true;
            this.resendConfirmationCode();
          }

        }

      }
    }
  }

  async getCompanyLogo() {
    this.loginService.getCompanyLogo(this.companyCode, null).subscribe((res: any) => {
      if (res.status = 200) {
        if (res.data[0].monogram_aws_path) {
          this.companylogoPath = res.data[0].monogram_aws_path;
          this.companyLogoAndMonogramService.setCompanyMonoGram(this.companylogoPath);
        }
        if (res.data[0].logo_aws_path) {
          this.companylogoPathAfterLogin = res.data[0].logo_aws_path;
          this.companyLogoAndMonogramService.setCompanyLogo(this.companylogoPathAfterLogin);
        }
        if (!res.data[0].monogram_aws_path) {
          console.log('../../../assets/img/DNlogo-new.png');
          this.defaultImg = "../../../assets/img/DNlogo-new.png";
        }
      } else {
        this.defaultImg = "../../../assets/img/DNlogo-new.png";
      }
    });
  }

  async onSubmit(form: NgForm) {

    if (!form.valid || !form.touched) return;

    if (this.newPassword !== this.confirmPassword) {
      this.error = 'Confirm password does not match';
      return;
    } else if (this.passwordStrength < 3) {
      form.controls['password'].setErrors({ 'incorrect': true })
      return;
    } else {
      this.error = '';
    }

    this.loader.startLoader(this.loaderId);

    try {
      const encrypted: any = await this.loginService
        .getEncryptPassword(form.value.newPassword)
        .toPromise();
      // encrypted.encreptedPass
      const data = {
        user_id: this.params.user_id,
        email: this.params.email,
        verification_code: this.params.verification_code,
        password: encrypted.encreptedPass,
      };
      const response: any = await this.companyService
        .userCreatePassword(data)
        .toPromise();
      if (response.status === 200) {
        this._toastr.success('Your password has been created. Now Sign In.', 'Success');
        this._router.navigate(['/login']);
      } else {
        this._toastr.error(response.message, 'Success');
      }

      this.loader.stopLoader(this.loaderId);
    } catch (error) {
      console.error(error);
      this._toastr.clear();
    }
  }
  clearErrors() {
    this.error = '';
  }
  checkPassword(myString) {
    let minLength = true;
    var specials = /[^A-Za-z 0-9]/g;
    let hasSpecial = specials.test(myString);
    let hasNumber = /\d/.test(myString);
    let hasUpper = /[A-Z]/.test(myString);
    let hasLower = /[a-z]/.test(myString);
    let passwordLength = myString.length;
    if (passwordLength < 10) {
      minLength = false;
    }
    const valid = hasNumber && hasUpper && hasLower && hasSpecial && minLength;
    if (!valid) {
      this.error = '\n';
      if (!hasUpper) {
        this.error += 'Password should have atleast one Uppercase Letter\n';
        this.policyUpperCase = false;
      } else if (hasUpper) {
        this.policyUpperCase = true;
      }
      if (!hasLower) {
        this.error += 'Password should have atleast one Lowercase Letter\n';
        this.policyLowerCase = false;
      } else if (hasLower) {
        this.policyLowerCase = true;
      }
      if (!hasNumber) {
        this.error += 'Password should have atleast one number\n';
        this.policyNumber = false;
      } else if (hasNumber) {
        this.policyNumber = true;
      }
      if (!hasSpecial) {
        this.error += 'Password should have atleast one Special character\n';
        this.policySpecialCharacter = false
      } else if (hasSpecial) {
        this.policySpecialCharacter = true
      }
      if (!minLength) {
        this.error += 'Password Lenght should be greater than 10\n';
        this.policyLength = false;
      } else if (minLength) {
        this.policyLength = true;
      }
      this.enableConfirmPassword = false;
      this.enableSaveButton = false;
      return false;
    }
    this.policyUpperCase = true;
    this.policyLowerCase = true;
    this.policyNumber = true;
    this.policySpecialCharacter = true
    this.policyLength = true;
    this.enableConfirmPassword = true
    return true;
  }


  async onSubmitWithCognito(form: NgForm) {
    // if (!form.valid || !form.touched) return;
    let check = this.checkPassword(this.newPassword);
    if (check) {
      if (this.newPassword !== this.confirmPassword) {
        this.error = '\nConfirm password does not match';
        return;
      } else {
        this.error = '';
        this.loader.startLoader(this.loaderId);
        try {
          // const encrypted: any = await this.loginService
          //   .getEncryptPassword(form.value.newPassword)
          //   .toPromise();
          // encrypted.encreptedPass
          const data = {
            user_id: this.params.user_id,
            email: this.params.email,
            verification_code: this.params.verification_code,
            password: form.value.newPassword,
          };
          const response: any = await this.companyService
            .userCreatePassword(data)
            .toPromise();
          if (response.status === 200) {
            this.verifyCodeView = true;
            this.user.registraionSteps.twoFacExpire = response.expireDate;
            this.timer(2);
            // this._toastr.success('Your password has been created. Now Sign In.', 'Success');
            // this._router.navigate(['/login']);
          } else {
            this._toastr.error(response.message, 'Success');
          }
          this.loader.stopLoader(this.loaderId);
        } catch (error) {
          console.error(error);
          this._toastr.clear();
        }
      }
    }
  }
  async verifyCode(form: NgForm) {

    if (!form.valid || !form.touched) return;
    if (this.display =='00:00') {
      this._toastr.error('Verification Code Expire, Please Click on Resend', 'Error');
      return;
    }

    if (!this.code) {
      this.error = 'Please enter verification code';
      return;
    } else {
      this.error = '';
    }

    this.loader.startLoader(this.loaderId);

    try {
      const userData = {
        user_id: this.params.user_id,
        email: this.params.email,
        code: form.value.code
      };
      const passwordResponse: any = await this.companyService
        .verifyCode(userData)
        .toPromise();
        debugger
      if (passwordResponse.status === 200) {
        //  send email to company admin to approve the user (is_activated)
        if(global.ENVIRONMENT === global.LOCAL || global.ENVIRONMENT === global.WOUNDCARE_DEV) {
          if(!this.user.is_phone_number_changed) {
            const passwordResponse: any = await this.companyService.approveFromAdmin(userData).subscribe((res:any)=>{
              this.verifyCodeView = true;
              this._toastr.success('Account successfully registered, Wait for the Admin to verify the account', 'Success');  
              if (this.companyCode) {
                const code = this.companyCode;
                this._router.navigate([`/login/${code}`]);
              } else {
                this._router.navigate(['/login']);
              }
            });
          }else {
            this._router.navigate(['/login']);
          }
        }
        else {
          this.verifyCodeView = true;
          if (this.companyCode) {
            const code = this.companyCode;
            this._router.navigate([`/login/${code}`]);
          } else {
            this._router.navigate(['/login']);
          }
        }

      }
      else {
        this._toastr.error(passwordResponse.message, 'Success');
      }

      this.loader.stopLoader(this.loaderId);
    } catch (error) {
      console.error(error);
      this._toastr.clear();
    }
  }

  async resendConfirmationCode() {
    this.disableButton = true;
    const data = {
      user_id: this.params.user_id,
      email: this.params.email,
    };
    const response: any = await this.companyService
      .resendConfirmationCode(data)
      .toPromise();
    if (response.status === 200) {
      this.verifyCodeView = true;
      this.timer(2);
      this.user.registraionSteps.twoFacExpire = response.expireDate;
      this._toastr.success('Verification code has been sent', 'Success');
    } else {
      this._toastr.error(response.message, 'Success');
      // this.timer(2);
    }
  }
  isPasswordValid() {
    this.checkPassword(this.newPassword);
    if (this.newPassword != '') {
      if (this.newPassword != this.confirmPassword) {
        this.enableSaveButton = false;
      } else {
        this.enableSaveButton = true;
      }
    }
  }
  strengthChange(passwordStrength) {
    this.passwordStrength = passwordStrength;
  }

  timer(minute) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
      if(this.display.includes('-')){
        this.display = '00:00';
        clearInterval(this.timerInterval);
      }
      if (seconds <= 0) {
        if(this.display !== '00:00'){
          this.display = '00:00';
        }
        // console.log('finished');
        clearInterval(this.timerInterval);
      }
      this.disableButton = false;
    }, 1000);
  }
}
