<div flexLayout class="h100">
  <div class="left-sidebar">
    <div>
      
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Documents
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ul>
          <li class="flex justify-between items-center" 
          [routerLink]="['.', {view:'notes', status: 'unsigned'}]"
          [class.active]="params.status === 'unsigned'"
          >
            <img src="../../../assets/img/icons/all_icon.png">
            <span class="flex-grow">Unsigned Notes</span> 
            <span class="small-badge" style="margin-left: auto;" *ngIf="transcriptions && transcriptionsLoaded">{{_commonService.showCount(countTranscriptions('unsigned'))}}</span>
          </li>
          <li class="flex justify-between items-center" 
          [routerLink]="['.', {view:'notes', status: 'signed'}]"
          [class.active]="params.status === 'signed'"
          >
            <img src="../../../assets/img/signed.png">
            <span class="flex-grow">Signed Notes</span>
            <span class="small-badge" style="margin-left: auto;" *ngIf="transcriptions && transcriptionsLoaded">{{_commonService.showCount(countTranscriptions('signed'))}}</span>
          </li>
          <li class="flex justify-between items-center" 
          [routerLink]="['.', {view:'notes', status: 'uploaded'}]"
          [class.active]="params.status === 'uploaded'"
          >
            <img src="../../../assets/img/completed.png" />
            <span class="flex-grow">Uploaded Notes</span>
            <span class="small-badge" style="margin-left: auto;" *ngIf="transcriptions && transcriptionsLoaded">{{_commonService.showCount(countTranscriptions('uploaded'))}}</span>
          </li>
        </ul>

      </mat-expansion-panel>
    </div>
  </div>
  <div class="main-content container flex-grow">
    <div class="dark-blue-heading flex justify-between items-center">
      <h3>Viewing <strong>{{_commonService.getUserFullName(simulatedUser)}}</strong>'s Dashboard > {{params && (params.status | titlecase)}} {{params && (params.view | titlecase)}} ({{filteredTranscriptions.length}})</h3>
    </div>
      <div class="no-items" *ngIf="transcriptionsLoaded && filteredTranscriptions.length === 0">
        No items to view
      </div>
      <app-transcription-list *ngIf="params.view === 'notes' && filteredTranscriptions.length > 0"
        [excludeInfo]="['provider']"
        [transcriptions]="filteredTranscriptions"
        [isVisibleSelectAll]="false"
        [isVisibleControls]="false" 
        [readonly]="true"
        ></app-transcription-list> 
  </div>
</div>
