import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-submitted-mips',
  templateUrl: './submitted-mips.component.html',
  styleUrls: ['./submitted-mips.component.css']
})
export class SubmittedMipsComponent implements OnInit {

  @Input() submittedMips:any;
  formattedMips=[];
  showMipsDialog: boolean = false;
  
  constructor() { }

  ngOnInit(): void {
    this.initSubmittedMips();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['submittedMips']){
      this.initSubmittedMips();
    }
  }

  initSubmittedMips(){
    // console.log("this.submittedMips :: ",  this.submittedMips);
    this.formattedMips=[];
    for (let mip of this.submittedMips){
      let reponse={
        description:mip.measure_id[0]?.measure_description,
        number:mip.measure_id[0]?.quality_number,
        title:mip.measure_id[0]?.measure_title,
        code:mip.answer,
        question:mip.question,
        reasons:mip.reasons,
      };
      this.formattedMips.push(reponse);
    }
  }

  mipsDialog() {
    this.showMipsDialog = !this.showMipsDialog;
  }

  getQuestion(mip: any) {
    let question = mip.question || '';
    if (question?.trim().endsWith("Other") && mip.reasons?.length > 0) {
      const questionForOther = mip.reasons.filter((item: any) => item._id != null);
      if (questionForOther.length > 0) {
        question = questionForOther[questionForOther.length - 1].question;
      }
    }
    return question;
  }
  getCode(mip: any) {
    let code = mip.code || '';
    if (code == null || code == '' || code == '00') {
      code = mip.reasons?.filter((item: any) => (item.answer != null)&&(item.answer != '00')&&(item.answer != '')).length > 0 ? mip.reasons.filter((item: any) => (item.answer != null)&&(item.answer != '00')&&(item.answer != ''))[0].answer : '';
    }
    return code;
  }

}
