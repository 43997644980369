<div class="flex flex-col gap-2">
    <mat-form-field>
        <input matInput type="text" name="phrase_key" #phrase_key="ngModel" [(ngModel)]="key.key"
            [disabled]="phraseUserId !==undefined && phraseUserId !== currentUser._id"
           (blur)="checkError(key.key)"
            placeholder="Shortcut" required pattern="[a-z]+" onkeypress="return /[a-z]/.test(event.key)">
        <mat-error *ngIf="phrase_key.invalid">Please enter valid shortcut, it should not contains spaces or special
            characters</mat-error>
        </mat-form-field>
        <small *ngIf="uniqueKeyError"style="color: red; font-size: 11px">Please enter unique shortcut, that does not already exist.</small>
    <div style="height: 400px;">
        <ckeditor [(ngModel)]="key.value" name="notes_value" class="h100"
            editorUrl="../../../../assets/ckeditor/ckeditor.js" [readOnly]="!cke4Config.editable" [config]="cke4Config"
            type="divarea" (ready)="onReadyCkeditor($event)"></ckeditor>
    </div>

    <div class="flex justify-center items-center gap-2">
        <button class="dn-button-primary" mat-raised-button
            [disabled]="uniqueKeyError || !(key.value && key.key) || (phraseUserId !==undefined && phraseUserId !== currentUser._id)" (click)="onSave()">Save</button>
        <button class="dn-button-secondary" mat-raised-button type="button" mat-dialog-close>Cancel</button>
    </div>
</div>

<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>