import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PatientDetailService } from '../../../patient-detail-list/patient-detail-list.service';
import { AuthService } from '../../../../services/auth.service';
import * as global from '../../../global';
import moment from 'moment'
import { NoteAuditoryHistoryService } from '../../../note-auditory-history/note-auditory-history.service';
import { Router } from '@angular/router';
import { PCCViewNoteDialog } from 'src/app/includes/transcription-create/view-pcc-note-dialog/view-pcc-note-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PCCService } from 'src/app/services/pcc.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { IProgressNoteTypesResponse } from 'src/app/services/pccAPIInterfaces';


@Component({
  selector: 'app-pcc-note-types-list',
  templateUrl: './pcc-note-types-list.component.html',
  styleUrls: ['./pcc-note-types-list.component.css']
})
export class PCCNoteTypesListComponent implements OnInit {
  global = global;
  @Input() note: any;
  @Input() patient: any;
  @Input() excludeNoteId: string;
  apiData: IProgressNoteTypesResponse;

  displayedColumns: string[];
  dataSource: any;
  page = 1;

  loaderId = 'pcc-note-types-list-loader';
  

  constructor(
    private patientDetailService: PatientDetailService,
    private auditoryService: NoteAuditoryHistoryService,
    private authService: AuthService,
    private _router: Router,
    private dialog: MatDialog,
    private pccService:PCCService,
    private loader: NgxUiLoaderService,
    ) {
    this.displayedColumns = ['facId', 'highPriority', 'orgUuid', 'progressNoteTypeId', 'sections', 'showOn24HourReport', 'showOnShiftReport'];

  }

  ngOnInit(): void {
    console.log('PCC Note list ', this.note)

    if (this.patient) {
      this.loadData(this.page);
    }
  }

  async initNoteTypes(pccFacId, page?) {
    const pcc_orgUuid = this.note?.facility?.pcc_orgUuid;
    const company_id = this.authService.currentUser.company_id;
    const notesResponse:any = await this.pccService.getNoteTypes(pcc_orgUuid, pccFacId, page).toPromise();
    if(notesResponse.status === 200) {
      this.apiData = notesResponse.data;
      this.dataSource = new MatTableDataSource(this.apiData.data);
      this.page = page;
    }

  }
  async loadData(page) {
    this.loader.startLoader(this.loaderId);
    await this.initNoteTypes(this.note.facility.pcc_facId, page)
    this.loader.stopLoader(this.loaderId);
  }
  navigate(prevNext) {
    const newPageNo = this.page + prevNext;
    this.loadData(newPageNo);
  }


}
