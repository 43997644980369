<div class="flex flex-col gap-2">
    <div>
        <mat-form-field class="w-full">
            <mat-select  name="data-set-type" placeholder="Select Label">
                <mat-option *ngFor="let label of labels" value="{{label}}" (click)="insertLabel(label)">
                    {{label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <textarea class="w-full" [(ngModel)]="formula" placeholder="Build your formula here">
        </textarea>
        <mat-icon *ngIf="error === null && formula !== ''" class="green-check-icon">check_circle</mat-icon>
    </div>
    <div class="flex flex-row gap-2 justify-center" >
        <button mat-raised-button class="dn-button-primary" *ngFor="let operation of operations" (click)="insertOperation(operation.symbol)">
            <mat-icon *ngIf="operation.displayName === 'Add'">add</mat-icon>
            <mat-icon *ngIf="operation.displayName === 'Multiply'">clear</mat-icon>
            <mat-icon style="margin-bottom: 25%" *ngIf="operation.displayName === 'Divide'">/</mat-icon>
            <mat-icon style="margin-bottom: 50%" *ngIf="operation.displayName === 'Subtract'">minimize</mat-icon>
        </button>
    </div>
    <div class="flex flex-row gap-2">
        <button mat-raised-button class="dn-button-primary w-1/2" (click)="evaluate()">Evaluate</button>
        <button mat-raised-button class="dn-button-primary w-1/2" (click)="clear()">Clear</button>
    </div>
    <p style="color: red" *ngIf="error !== null">{{ error }}</p>
</div>