import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CensusPatientListService } from 'src/app/includes/census-patient-list/census-patient-list.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})

export class CommentsComponent implements OnInit {
  billerNote: any = '';
  currentUser: any;
  model = {
    owner_id: '',
    patient_id: '',
    rounding_sheet_id: '',
    icd_id: [],
    cpt_id: [],
    billing_note: '',
    note: '',
    status: 'submit',
    visit_date: '',
    facility_id: '',
    measure: false
  };
  @Output() comments = new EventEmitter<any>();
  @Input() charge: any;

  // @Input() restComm;
  constructor(
    private censusPatientListService: CensusPatientListService,
  ) { }

  ngOnInit(): void {
    if(typeof this.charge.note === 'string') {
      this.model.note = this.charge.note;
    }
  }

   changeBillerNote(){ 
    //  
    // this.censusPatientListService.setBillerComments(this.model.note)
    this.comments.emit(this.model.note);
  }

}
