import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CKEditorService } from 'src/app/includes/note-editor/ckeditor.service';

export interface DictionaryEditorDialogData {
}
@Component({
  selector: 'app-systemphraseeditor',
  templateUrl: './systemphraseeditor.component.html',
  styleUrls: ['./systemphraseeditor.component.css']
})
export class SystemphraseeditorComponent implements OnInit {
  addPhraseKey:any = {};
  isNew = false;
  key:any = {};
  cke4Config;

  constructor(
    public dialogRef: MatDialogRef<SystemphraseeditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DictionaryEditorDialogData,
    private ckeditorService: CKEditorService
  ) { 
    this.cke4Config = {
      ...this.ckeditorService.getDefaultConfig(),
      editable: true
    };
    if(data) {
      this.key = data;
      console.log('data', data, 'after dialog');
  }
  this.isNew = data ? false : true;
  }

  ngOnInit(): void {
  }

  onReadyCkeditor(e) {
    const {editor} = e;
    this.ckeditorService.ckeditorRemoveTitle(editor)
}

}
