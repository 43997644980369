import { Component, OnInit, Input } from '@angular/core';
import { PCCService } from 'src/app/services/pcc.service';
import { MatTableDataSource } from '@angular/material/table';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
@Component({
  selector: 'app-observation',
  templateUrl: './observation.component.html',
  styleUrls: ['./observation.component.css']
})
export class ObservationComponent implements OnInit {

  @Input('patient') patient;
  // @Input('pccOrgUuid') pccOrgUuid;
  constructor(private _pccService: PCCService,
    private toastr:ToastrService,
    private loader: NgxUiLoaderService) { }
  pccOrgUuid:string;
  apiData;
  page = 1;
  dataSource;
  displayedColumns = [
    // "observationId",
    // "patientId",
    "recordedDate",
    "type",
    "value",
    // "method",
    // "recordedBy"
  ];

  loaderId ='observation-loader';
  inprogress = false;

  ngOnInit(): void {
    this.pccOrgUuid = this.patient.facility_id_ref.pcc_orgUuid;
    this.loadData(this.page);
  }

  loadData(page) {
    this.loader.startLoader(this.loaderId);
    let authMode;
    if(!this.patient.facility_id_ref.pcc_2_legged_authentication) {
      authMode = 'APP'
    }
    this._pccService.getObservations({
      pccOrgUuid: this.pccOrgUuid,
      pccPatientID: this.patient.pcc_patientId,
      pccPage: String(page),
      authMode
      }).subscribe((response: any) => {

      if (response.status === 200) {
        this.apiData = response.data;
        this.dataSource = new MatTableDataSource(this.apiData.data);
        this.page = page;
      } else {
        this.toastr.error('Something went wrong while fetching Observations data');
      }
    }, (error) => {
      this.toastr.error('Something went wrong while fetching Observations data');
    }, () => {
      this.loader.stopLoader(this.loaderId);
    })
  }
  navigate(prevNext) {
    const newPageNo = this.page + prevNext;
    this.loadData(newPageNo);
  }

  startCase(str) {
    return this.mapLabel(str) || _.startCase(str);
  }

  getValue(element) {
    if(element.type === 'bloodPressure') {
      return `${element.systolicValue}/${element.diastolicValue} (Systolic/Diastolic)`;
    }
    return `${element.value} ${element.unit || ''}`;
  }

  mapLabel(str) {
    const map = {
      oxygenSaturation: 'O2 sat',
      respirations: 'RR',
      bloodPressrue: 'BP',
      temperature: 'Temp',
      heartrate: 'HR',
      bmi: 'BMI',
    }
    return map[str];
  }
}
