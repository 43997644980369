import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from "../../../../environments/api-url";
import * as global from '../../global';




@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {

  constructor(
    private httpclient: HttpClient,
    private _authService: AuthService,
  ) { }


  isCompanyEmailVisible$ = new BehaviorSubject<any>(false);
  castIsCompanyVisible = this.isCompanyEmailVisible$.asObservable();

  changeIsCompanyEmailVisible(isVisible) {
    this.isCompanyEmailVisible$.next(isVisible);
  }

  createEmailTemplate(payload) {
    const details = {
      ...this._authService.authObject,
      ...payload
    }
    return this.httpclient
      .post(global.url + API_URL.EMAIL_TEMPLATE.createEmailTemplate, { details: details });

  };

  updateEmailTemplate(payload) {
    const details = {
      ...this._authService.authObject,
      ...payload
    }
    return this.httpclient
      .post(global.url + API_URL.EMAIL_TEMPLATE.updateEmailTemplate, { details: details });

  };

  deleteEmailTemplate(id) {
    const details = {
      ...this._authService.authObject,
      id
    }
    return this.httpclient
      .post(global.url + API_URL.EMAIL_TEMPLATE.deleteEmailTemplate, { details: details });

  };

  getEmailTemplate(filter, project) {
    const details = {
      ...this._authService.authObject,
      filter,
      project
    }
    return this.httpclient
      .post(global.url + API_URL.EMAIL_TEMPLATE.getEmailTemplate, { details: details });

  };

  sendEmailTemplate(payload) {
    const details = {
      // auth_key: this._authService.authObject.auth_key,
      // id: this._authService.authObject.id,
      ...payload
    }
    return this.httpclient
      .post(global.url + API_URL.EMAIL_TEMPLATE.sendEmailTemplate, { details: details });

  };

  getEmail(filter, project) {
    const details = {
      ...this._authService.authObject,
      filter,
      project
    }
    return this.httpclient
      .post(global.url + API_URL.EMAIL_TEMPLATE.getEmail, { details: details });

  };

  updateEmail(payload) {
    const details = {
      ...this._authService.authObject,
      ...payload
    }
    return this.httpclient
      .post(global.url + API_URL.EMAIL_TEMPLATE.updateEmail, { details: details });

  };
  deleteEmail(emailId, status = null) {
    const details = {
      ...this._authService.authObject,
      emailId,
      status
    };
    return this.httpclient
      .post(global.url + API_URL.EMAIL_TEMPLATE.deleteEmail, { details: details });

  };

}
