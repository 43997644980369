<div class='container' *ngIf="!isAdvancedTextSearchComponentVisible">
    <div class="flex justify-between items-center gap-2"
        style="background: white; padding-left: 10px;padding-right: 10px; border-radius: 10px;">
        <div class="flex justify-start items-center gap-2">
            <mat-form-field appearance="fill" style="align-self: flex-end;">
                <mat-label>Search by Name</mat-label>
                <input matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="filter.patientName">
            </mat-form-field>

            <mat-form-field appearance="fill" style="align-self: flex-end; width: 15%;">
                <mat-label>Search by DOB</mat-label>
                <input matInput [matDatepicker]="dospicker" (focus)="dospicker.open()" placeholder="Date of Birth"
                    [value]="dob" (dateChange)="onDateChange($event)">
                <mat-datepicker-toggle matSuffix [for]="dospicker"></mat-datepicker-toggle>
                <mat-datepicker #dospicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" style="align-self: flex-end;">
                <mat-label *ngIf="company_type != global.COMPANY_TYPE.SNF">Select by Provider</mat-label>
                <mat-label *ngIf="company_type === global.COMPANY_TYPE.SNF">Select by SNF WC NURSE</mat-label>
                <mat-select [(ngModel)]="filter.provider" (ngModelChange)="handleProviderChange($event)"
                    [disabled]="searchCompanyBase">
                    <input placeholder="Search" [(ngModel)]="searchProviderText"
                        [ngModelOptions]="{standalone: true}" class="select-input-class"
                        />
                    <mat-option *ngFor="let provider of assocProviders | searchProvider : searchProviderText | orderBy:'first_name'"
                        [value]="provider._id" placeholder="Provider">
                        {{provider.first_name}} {{provider.last_name}}, {{provider.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" style="align-self: flex-end; width: 350px;">
                <mat-label>Select by Facility</mat-label>
                <div class="flex justify-start items-center">
                    <img src="../../../assets/icons/pcc-icon.svg" *ngIf="isPCCFacility(filter.facility)" width="20px"
                        height="20px" alt="">
                    <img src="../../../../assets/icons/matrix.png" *ngIf="isMatrixFacility(filter.facility)" style="height: 12px;
                        width: 20px;  
                        margin-right: 5px;
                        vertical-align: middle;" alt="">
                    <mat-select [(ngModel)]="filter.facility" (ngModelChange)="handleFacilityChange($event)"
                        [disabled]="searchCompanyBase">
                        <input placeholder="Search" [(ngModel)]="searchFacilityText"
                            [ngModelOptions]="{standalone: true}" class="select-input-class" />
                        <mat-option *ngFor="let facility of facilities | searchFacility : searchFacilityText"
                            [value]="facility._id" placeholder="Facility">
                            <app-facility-title [facility]="facility"></app-facility-title>
                        </mat-option>
                    </mat-select>
                </div>
            </mat-form-field>
            <mat-checkbox class="example-margin" (change)="searchByCompany($event)" [checked]="searchCompanyBase">Search
                All
                Patients</mat-checkbox>
        </div>
        <div class="flex justify-start items-center gap-2">
            <button class="dn-button-secondary" style="flex: 10%; min-width: auto; white-space: nowrap;" mat-raised-button
                (click)="clearFilter()">Clear Filter
            </button>
            <button class="dn-button-primary" style="flex: 10%; min-width: auto;" mat-raised-button
                routerLink="patient-medical-history">Configure Medical History
            </button>
            <button class="dn-button-primary" style="flex: 10%; min-width: auto;" mat-raised-button
                (click)="isAdvancedTextSearchComponentVisible=!isAdvancedTextSearchComponentVisible;">Advanced Text
                Search</button>
        </div>
    </div>

    <div style="height: 5px;"></div>

    <app-alphabetic-index-bar [activeIndex]="activeAlphabeticBarIndex"
        (onIndexButtonClick)="handleAphabeticIndexButtonClick($event)"></app-alphabetic-index-bar>

    <div style="height: 5px;"></div>
    <div class="flex items-start justify-center gap-2">
        <div class="flex items-center justify-center gap-2 flex-grow table-nav">

            <button mat-raised-button class="dn-button-primary" [disabled]="!pagination.hasPrevPage"
                (click)="handlePrevClick()"> Prev</button>

            <span style="background: white; border-radius: 2px; font-size: 12px;padding: 5px;">
                {{getcountofPatients()}}</span>

            <button mat-raised-button class="dn-button-primary" [disabled]="!pagination.hasNextPage"
                (click)="handleNextClick()">Next</button>
        </div>
    </div>

    <div style="height: 5px;"></div>

    <div style="position: relative;">
        <table class="table-row-hover patient-list-table" mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
                <td mat-cell *matCellDef="let element">
                   
                    <app-patient-name [patient]="element"></app-patient-name>
                </td>
            </ng-container>

            <ng-container matColumnDef="date_of_birth">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date of Birth</th>
                <td mat-cell *matCellDef="let element"> {{element.date_of_birth | date: global.date_format2}} </td>
            </ng-container>

            <ng-container matColumnDef="gender">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Gender</th>
                <td mat-cell *matCellDef="let element"> {{element.gender}} </td>
            </ng-container>
            <ng-container matColumnDef="last_seen">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="last_seen">Last Seen</th>
                <td mat-cell *matCellDef="let element">
                    {{getLastSeen(element)}}
                </td>
            </ng-container>
            <ng-container matColumnDef="facility">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Facility</th>
                <td mat-cell *matCellDef="let element">
                    <app-facility-title [facility]="element?.facility_id_ref"></app-facility-title>
                </td>
            </ng-container>
            <ng-container matColumnDef="location">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Location</th>
                <td mat-cell *matCellDef="let element"> {{getRecentPatientADTRecord(element)?.floorDesc}}
                    {{getRecentPatientADTRecord(element)?.roomDesc}} - {{getRecentPatientADTRecord(element)?.bedDesc}}
                </td>
            </ng-container>
            <ng-container matColumnDef="admission_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Admission Date</th>
                <td mat-cell *matCellDef="let element"> {{element?.pcc_payload?.admissionDate | date: 'M/d/yyyy'}} </td>
            </ng-container>
            <ng-container matColumnDef="discharge_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Discharge Date</th>
                <td mat-cell *matCellDef="let element">
                    {{getRecentPatientADTRecord(element)?.actionCode === 'DD' ||
                    getRecentPatientADTRecord(element)?.actionCode === 'DHOME' ?
                    (getRecentPatientADTRecord(element)?.updatedAt | date: 'M/d/yyyy') : ''}}
                </td>
            </ng-container>
            <ng-container matColumnDef="payer">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Payor</th>
                <td mat-cell *matCellDef="let element"> {{getRecentPatientADTRecord(element)?.payerName}} </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
                <td mat-cell *matCellDef="let element"> {{element?.pcc_payload?.patientStatus}} </td>
            </ng-container>
            <ng-container matColumnDef="source">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Source</th>
                <td mat-cell *matCellDef="let element"> {{element.source || 'portal'}} </td>
            </ng-container>

            <ng-container matColumnDef="operations">
                <th mat-header-cell *matHeaderCellDef>Operations</th>
                <ng-container *matCellDef="let element">
                    <td mat-cell *ngIf="element.is_active == 'true'">
                        <div class="operation-buttons flex flex-inline justify-start items-center gap-5">
                            <i class="icon icon-view" matTooltip="view" matTooltipPosition="above"
                                (click)="goToPatientDetailScreen(element)"></i>

                            <i class="icon icon-edit" matTooltip="Edit" matTooltipPosition="above"
                                (click)="onEditPatient(element.patient_id)"></i>
                        </div>
                    </td>
                </ng-container>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <ngx-ui-loader loaderId="patient-list-loader"></ngx-ui-loader>


    </div>
</div>
<app-advanced-note-text-search *ngIf="isAdvancedTextSearchComponentVisible" (hideAdvancedTextSearchComponent)="isAdvancedTextSearchComponentVisible=$event;"></app-advanced-note-text-search>