<div *ngIf="duplicates" class="flex flex-col justify-between">

    <div class="w-full flex items-center justify-between"
        style="background-color: #44597d; height: 35px; color: white;">
        <h1 style="padding-left: 15px;">Duplicate Patient Found</h1>
    </div>

    <div class="flex flex-col gap-1" style="padding: 10px; border-radius: 10px; margin-bottom: 7px; width: 99%;">
        <h1>Incoming Patient</h1>
        <span>{{incoming.first_name+' '+incoming.last_name+ ' ( '+ incoming.date_of_birth+' ) '+' ( '+ incoming.gender+' ) '}} {{' MRN: '+incoming.mrn||''}} {{' ID: '+getId(incoming)}}</span>
    </div>
    <div class="flex items-center"
        style="width: 100%;height: 22px; background-color: rgb(95 174 255 / 69%); margin-bottom: 7px;"></div>
    <div *ngFor="let item of duplicates" class="flex justify-between gap-2">
        <div class="flex flex-row gap-1 w-full" style="margin-bottom: 3px;"
            [ngStyle]="{background: firstNameMatch(item) ? 'rgb(216 237 224)' : 'rgb(251 191 191)', border:(selectedOne(item)&&!onlyOne)?'solid':'none'}">


            <div class="w-2/5 flex flex-col gap-1"
                style="padding: 10px;border-radius: 10px; cursor: pointer;">
                <h1>Existing Patient</h1>
                <span>{{item.first_name+' '+item.last_name+ ' ( '+ getFormatedDate(item.date_of_birth)+' ) '+' ( '+ item.gender+' )'}}</span>
                <span>{{'MRN: '+(item.mrn||'')+'  ,  '+ ' ID: '+(getId(item)||'')}}</span>
                <span>{{item.facility_id_ref?.title||''}}</span>
                <span>{{'Source : '+(item.source||'DN')}}</span>
            </div>
            <div class="flex flex-col gap-1"
                style="padding: 10px;border-radius: 10px; cursor: pointer; flex: 15%;">
                <h1>Data</h1>
                <span>{{'Charges: ( '+ item.chargeCount+' ) '}}</span>
                <span>{{'Notes: ( '+ item.notesCount+' ) '}}</span>
            </div>
            <div class="flex flex-col gap-1 w-1/5" style="padding: 10px;border-radius: 10px;">
                <h1>Recent Note</h1>
                <h2 *ngIf="item.recent_Note" style="color:blue; cursor: pointer;" (click)="viewNote(item.recent_Note)">
                    {{item.recent_Note?.transcribtion_title||''}}
                </h2>
            </div>
            <div *ngIf="(!existing)" class="flex flex-col gap-1 w-1/4" style="padding: 25px;">
                <button [disabled]="!(item.selected)" style="width: 147px;" mat-raised-button color="primary"
                    (click)="selectExisting(item)">Primary Patient</button>
                <mat-checkbox name="source" [checked]="item.selected" (change)="item.selected = $event.checked">
                    Select for Merge
                </mat-checkbox>
            </div>
        </div>

    </div>
    <div class="flex flex-row w-98 gap-2 justify-end"
        style="align-items: center; margin-top:10px;">
        <button *ngIf="existing&&!onlyOne"  mat-raised-button style="background-color: #e1eeff; height: 43px;" (click)="selectAgain()">Change
            Selection</button> &nbsp;&nbsp;
        <button *ngIf="!(incoming.source=='PointClickCare')" mat-raised-button color="warn" style="height: 43px;" (click)="shouldAddNew(true)">Create New</button>
        &nbsp;&nbsp;
        <button *ngIf="existing"  mat-raised-button color="primary" style="height: 43px;" (click)="shouldAddNew(false)">Merge
            Existing</button>
    </div>
</div>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>
