import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PatientDetailService } from '../../../patient-detail-list/patient-detail-list.service';
import { AuthService } from '../../../../services/auth.service';
import * as global from '../../../global';
import moment from 'moment'
import { NoteAuditoryHistoryService } from '../../../note-auditory-history/note-auditory-history.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewNoteDialog } from 'src/app/includes/transcription-create/view-note-dialog/view-note-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserService } from 'src/app/services/Modules/user.service';
import { ViewSharedFacilityNoteComponent } from 'src/app/includes/transcription-create/view-shared-facility-note/view-shared-facility-note.component';


@Component({
  selector: 'app-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.css']
})
export class NotesListComponent implements OnInit {
  global = global;
  @Input() patientId: string;
  @Input() excludeNoteId: string;
  @Input() providerId: any;
  notes: any[] = [];
  allnotes: any[] = [];
  displayedColumns: string[];
  dataSource: MatTableDataSource<any>;
  loaderId = 'note-list-loader';
  noteId: any;
  currentUser: any;
  searchTextProvider:any
  providers: Array<any> = [];
  provider : any = '';
  constructor(
    private patientDetailService: PatientDetailService,
    private auditoryService: NoteAuditoryHistoryService,
    private authService: AuthService,
    private _router: Router,
    private dialog: MatDialog,
    private loader: NgxUiLoaderService,
    private _activatedRoute:ActivatedRoute,
    private _userService: UserService,
    private _authService: AuthService,

  ) {
    this.displayedColumns = ['icon', 'title', 'dos', 'note_status'];
    this.currentUser = this._authService.currentUser;
  }

  async ngOnInit() {

    if (this.patientId) { //For url change detection in case of params
      this._activatedRoute.params.subscribe((params: any) => {
          console.log("params", params);
          this.noteId = params.note_id;
          this.initNotes();
        })
      await this.getUsers();
    }
  }

  async getUsers() {
    const filter: any = {
        company_id: this.currentUser.company_id,
        user_type: this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE ? global.USER_TYPE.SNF_WC_NURSE : global.USER_TYPE.DOCTOR
      };
      const projection = {
        first_name: 1,
        last_name: 1,
        full_name: 1,
        title: 1
      };
      return new Promise<void>((resolve, reject) => {
        this._userService.getUsers(filter, projection).subscribe((response: any) => {
          if (response.status === 200) {
            this.providers = response.data;
            resolve(null);
          }
        }, error => {
          reject('error => ' + error.toString());
        });
    });
  }

  handleProviderChange() {
    this.initNotes();
  }

  async initNotes() {
    const details: any = {
      ...this.authService.authObject,
      
      patient_id: this.patientId,
      company_id: this.authService.currentUser.company_id
    };
    // if(this.providerId) {
    //   details.providerId = this.providerId;
    // }
    if(this.provider) {
      details.providerId = this.provider;
    }
    this.loader.startLoader(this.loaderId);

    const transcriptionsByPatientResponse: any = await this.patientDetailService.getTranscriptionsByPatientId(details).toPromise();
    if (transcriptionsByPatientResponse.status === 200) {
      // const signedNotes = transcriptionsByPatientResponse.data.array.filter(note => note.is_signed === 'true');
      // this.allnotes = signedNotes;
      // if (this.excludeNoteId) {
      //   this.notes = this.allnotes.filter(note => note._id !== this.excludeNoteId)
      // }
      this.notes = transcriptionsByPatientResponse.data.array;
      //  sort
      if(this.notes.length > 0) {
        this.notes.sort((a, b) => {
          const Adate = a.date_of_service;
          const Bdate = b.date_of_service;
          return new Date(Bdate).getTime() - new Date(Adate).getTime();
        });
      }
      this.notes=this.sortNotesByDOS(this.notes);
      this.dataSource = new MatTableDataSource(this.notes);
      this.loader.stopLoader(this.loaderId);

    }
  }

  getServiceDate(trans) {
    // if(trans?.audio_id_ref){
    if(trans.date_obj) {
      let {year, month, day, hour, minute} = trans.date_obj;
      if(month < 10){
          month = '0' + month;
      }
      if(day < 10){
          day = '0' + day;
      }
      let str = `${month}-${day}-${year}`;
      if(hour && minute) {
          if(minute < 10){
              minute = `0${minute}`
          }
          if(hour < 10){
              hour = `0${hour}`
          }
          str += ` ${hour}:${minute}`
          // return moment(str).format('MMM DD, YYYY h:mm A');
      } 
      return moment(str).format(global.moment_date_format2);
    } else if (trans?.filterServiceDate) {
      // return moment(new Date(trans.audio_id_ref.filterServiceDate).toLocaleString('en-US', { timeZone:  trans.audio_id_ref.timezone })).format('MMM DD, YYYY');
      //mohsin
      // return moment(new Date(trans.audio_id_ref.filterServiceDate)).format('MMM DD, YYYY');
      // }

      // else{ 
      // ali
      // return moment(new Date(trans.filterServiceDate).toLocaleString('en-US', { timeZone:  trans.timezone })).format('MMM DD, YYYY');
      return moment(new Date(trans.filterServiceDate)).format('MMM DD, YYYY');
      // mohsin
      // return moment(new Date(trans.filterServiceDate)).format('MMM DD, YYYY');
    }
    else {
      return '';
    }

  }

  sortNotesByDOS(notes:any){
    
      const resp= notes.sort((a, b) => {
        return Date.parse(moment(this.getServiceDate(a)).format('L')) < Date.parse(moment(this.getServiceDate(b)).format('L')) ? 1 : Date.parse(moment(this.getServiceDate(a)).format('L')) > Date.parse(moment(this.getServiceDate(b)).format('L')) ? -1 : 0;

      });
      return resp;
    }

  getNoteStatus(status) {
    return this.auditoryService.formatOperation(status);
  }
  goToNoteEditor(note) {
    this._router.navigate([`/note-editor/${note._id}`]);

  }
  showViewNoteDialog(note) {
    if(this.noteId === note._id) {
      return;
    }
    if(this.currentUser.company_type === global.PORTAL_TYPE.NON_WC) {
      if (!this.currentUser?.other_settings?.preview_note) {
          if (this._router.url.includes("/company"))
            return this._router.navigate([`company/${this.currentUser.company_id}/note-editor/${note._id}`]);
          else
            return this._router.navigate(['/note-editor/', note._id]);
      }
  }
    if (this.currentUser.company_id === '646b3074c114f6b0a3cd4eaa' || this.currentUser.company_id === '61fd23fe85650433b5ba4280') {
      let isSameCompany : boolean = false;
      if (this.currentUser.company_id === note.company_id) {
        isSameCompany = true;
      }
      console.log('note.company_id', note.company_id);
      console.log('this.currentUser.company_id', this.currentUser.company_id);
      const dialogRef = this.dialog.open(ViewSharedFacilityNoteComponent, {
        width: '80%',
        data: {
          note_id: note._id,
          is_same_company : isSameCompany
        }
      });
    } else {
      const dialogRef = this.dialog.open(ViewNoteDialog, {
        width: '80%',
        //height:'80%',
        data: {
          note,
          void_census: this.checkVoidCensus(note?.rounding_sheet_id),
        }
      });
      dialogRef.beforeClosed().subscribe(async (confirm: any) => {
        if(confirm.status){
          this.notes = this.allnotes.filter(note => note._id !== confirm.id);
          this.notes.sort((a, b) => {
            const Adate = a.date_of_service;
            const Bdate = b.date_of_service;
            return new Date(Bdate).getTime() - new Date(Adate).getTime();
          });
  
          // sort
        this.notes=this.sortNotesByDOS(this.notes);
          this.dataSource = new MatTableDataSource(this.notes);
        }
      });
    }
    
  }
  checkVoidCensus(patientRoundingSheet){
    if(patientRoundingSheet){
      if(patientRoundingSheet?.is_void){
        if(patientRoundingSheet.is_void === true) return false;
        else return true;
      }else{
        return true
      }
    }else{
      return true;
    }
  }
}
