import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {DashboardService} from 'src/app/includes/dashboard/dashboard.service';
import {TranscriptionCreateService} from 'src/app/includes/transcription-create/transcription-create.service';
import {PatientListService} from 'src/app/includes/patient-list/patient-list-service';
import * as global from '../../includes/global';
import {AuthService} from 'src/app/services/auth.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators'
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-filter-form',
    templateUrl: './filter-form.component.html',
    styleUrls: ['./filter-form.component.css']
})
export class FilterFormComponent implements OnInit {

    constructor(
        private _dashboardService: DashboardService,
        private _transcriptionCreateService: TranscriptionCreateService,
        private _patientListService: PatientListService,
        private _authService: AuthService
    ) {
    this.currentUser = this._authService.currentUser;
        if (this.filterData) {
            this.filter = this.filterData.filter;
            this.payload = this.filterData.payload;
        }
    }
    currentUser;
    global = global;
    filter: string = '';
    payload: any = null;
    filters = {
        dob: '',
        dos: '',
        facility: '',
        provider: '',
        patient: '',
        progress_status: ''
    };

    @Input() filterData: any = null;
    @Input() category: string;

    @Output() onFilter = new EventEmitter();
    @Output() filterChange = new EventEmitter();
    patients: Array<any> = [];
    providers: Array<any> = [];
    selectedPatients: Array<string> = [];
    // filteredPatients: Array<any> = [];

    facilities: Array<any> = [];

    // currentUser:any = JSON.parse(localStorage.getItem('currentUser'));
    loading: boolean = false;

    currentDate = new Date();


    // dropdownSettings = {
    //     singleSelection: true,
    //     idField: '_id',
    //     textField: 'name',
    //     allowSearchFilter: true,
    // };

    patientControl = new FormControl();
    filteredPatients: Observable<any[]>;

    providerControl = new FormControl();
    filteredProviders: Observable<any[]>;


    async ngOnInit() {
        // this.loading = true;

        await this.initFacilities();
        await this.initPatients();
        if (this.currentUser.user_type == global.USER_TYPE.TRANSCRIBER || this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
            await this.initProviders();
        }

        // if(this.filter === 'patient') {
        //   if(this.payload) {
        //     setTimeout(() =>
        //     this.payload = this.patients.find(p => p._id === this.payload).name)
        //   }
        // }

        // this.loading = false;

        this.filteredPatients = this.patientControl.valueChanges
            .pipe(
                startWith(''),
                map((value:any) => typeof value === 'string' ? value : value.name),
                map(name => name ? this._filterPatient(name) : this.patients.slice())
            );

        this.filteredProviders = this.providerControl.valueChanges
            .pipe(
                startWith(''),
                map((value:any) => typeof value === 'string' ? value : value.name),
                map(name => name ? this._filterProvider(name) : this.providers.slice())
            );
    }

    ngDoCheck() {
        if (this.filter === 'date_of_service' && (!this.payload || (this.payload && !this.payload.getTime))) {
            this.payload = new Date(); // default/initial value for date of service in dialog
        }
    }

    displayPatientFn(patient): string {
        return patient && patient.name ? patient.name : '';
    }

    private _filterPatient(name): any[] {
        const filterValue = name.toLowerCase();

        return this.patients.filter(patient => patient.name.toLowerCase().indexOf(filterValue) === 0);
    }

    displayProviderFn(provider): string {
        return provider && provider.name ? provider.name : '';
    }

    private _filterProvider(name): any[] {
        const filterValue = name.toLowerCase();

        return this.providers.filter(provider => provider.name.toLowerCase().indexOf(filterValue) === 0);
    }

    async initPatients() {
        const item: any = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type
        };
        if (this.currentUser.user_type == 1) {
            await this.getPatientsForDoctor(item);
        } else if (this.currentUser.user_type == 2 || this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
            await this.getPateintsForTranscriber(item);
        }

    }

    async initProviders() {
        const doctorsResponse: any = await this._transcriptionCreateService.getAssociatedDoctorsList(this.currentUser.company_id).toPromise();
        this.providers = doctorsResponse.data.map(d => ({...d, name: `${d.first_name} ${d.last_name}, ${d.title}`}));
    }

    async initFacilities() {
        // const item:any = {
        //   id: this.currentUser._id,
        //   auth_key: this.currentUser.auth_key,
        //   usertype: this.currentUser.user_type
        // }
        if (this.currentUser.user_type == 1) {
            const facilitiesResponse: any = await this._transcriptionCreateService.getFacilities(this.currentUser._id).toPromise();
            if (facilitiesResponse.status === 200) {
                this.facilities = facilitiesResponse.data.array;
            }
        } else if (this.currentUser.user_type == 2 || this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
            const associatedFacilityOfTranscriberResponse: any = await this._dashboardService.getAssociatedFacilityOfTranscriber().toPromise();
            if (associatedFacilityOfTranscriberResponse.status === 200) {
                this.facilities = associatedFacilityOfTranscriberResponse.data.array;
            }
        }

    }


    async getPatientsForDoctor(item) {
        return new Promise((resolve, reject) => {
            // DOCTOR 1
            // const infoToast = this._toastr.info("Fetching patients...", "Please wait");
            this._patientListService.getPatients(item).subscribe((response: any) => {
                    if (response.status === 200) {
                        this.patients = response.data.array
                            .filter(patient => patient.is_active === 'true')
                            .map(patient => ({
                                    patient_id: patient._id,
                                    name: `${patient.first_name} ${patient.middle_name} ${patient.last_name}`,
                                    ...patient
                                })
                            );
                        // this.filteredPatients = this.patients;
                        resolve(this.patients);
                    }
                }, (error) => {
                    // console.error(error);
                    reject(error);
                    // this._toastr.clear(infoToast.toastId);
                },
                () => {
                    // this._toastr.clear(infoToast.toastId);
                }
            );

        });
    }

    async getPateintsForTranscriber(item) {
        return new Promise((resolve, reject) => {

            // TANSCRIBER 2
            // const infoToast = this._toastr.info("Fetching patients...", "Please wait");
            this._transcriptionCreateService.getAssociatedDoctorsList(this.currentUser.company_id).subscribe((associatedDoctorsListResponse: any) => {
                if (associatedDoctorsListResponse.status === 200) {
                    // const associatedDoctorsList = associatedDoctorsListResponse.data.array; // array
                    const associatedDoctorsList = associatedDoctorsListResponse.data; // array
                    const associatedDoctorsIds = associatedDoctorsList.map(d => d._id);
                    item.associated_doctors_ids = associatedDoctorsIds; // additional parameter
                    this._patientListService.getPatients(item).subscribe((patientsResponse: any) => {
                            this.patients = patientsResponse.data.array
                                .filter(patient => patient.is_active === 'true')
                                .map(patient => ({
                                        patient_id: patient._id,
                                        name: `${patient.first_name} ${patient.middle_name} ${patient.last_name}`,
                                        ...patient
                                    })
                                );
                            // this.filteredPatients = this.patients;
                            resolve(this.patients);
                        }, (error) => {
                            // console.error(error);
                            reject(error);
                            // this._toastr.clear(infoToast.toastId);

                        },
                        // () => this._toastr.clear(infoToast.toastId)

                    );
                }
            });
        });
    }

    onPatientSelectionChange(value) {
        console.log(value);

        this.payload = value._id;
        this.handleSearch()
    }

    onProviderSelectionChange(value) {
        console.log(value);

        this.payload = value._id;
        this.handleSearch()

    }

    resetFilters() {
        this.filters = {
            dob: '',
            dos: '',
            facility: '',
            provider: '',
            patient: '',
            progress_status: ''
        };
        this.filterChange.emit({...this.filters, reset: true});
        // this.onFilter.emit({filter:'reset'});
        // this.filterData=null;
        // this.filter='';
        // this.payload=null
    }

    handleSearch() {
        // if (!this.payload) return;
        // this.onFilter.emit({filter: this.filter, payload: this.payload});
        this.filterChange.emit({...this.filters, reset: false});
    }
}
