import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminNotesService} from '../admin-notes/admin-notes.service';
import {CommonService} from 'src/app/services/common.service';
import {UserManagementService} from '../user-management/user-management.service';

@Component({
    selector: 'app-simulate-user',
    templateUrl: './simulate-user.component.html',
    styleUrls: ['./simulate-user.component.css']
})
export class SimulateUserComponent implements OnInit, OnDestroy {
    routeSub;
    params: { id: string, view: string, status: string };
    companyPath:Boolean = false;
    transcriptionsLoaded = false;
    transcriptions: any[] = [];

    filteredTranscriptions: any[] = [];

    dictationsLoaded = false;
    dictations: any[] = [];

    filteredDictations: any[] = [];

    simulatedUser: any;

    signedNotes = [];
    unsignedNotes = [];

    constructor(private route: ActivatedRoute, private router: Router, private _adminNotesService: AdminNotesService, private _userManagementService: UserManagementService, public _commonService: CommonService) {
        const defaultRoute = 'notes';
        const defaultRouteParams = {
            notes: {
                status: 'pending'
            },
            dictations: {
                status: 'all'
            }
        }
        this.companyPath = window.location.pathname.includes('company') 
        
        this.routeSub = this.route.params.subscribe(params => {
            this.params = <any>params;
            // if (!this.params.view) {
            //     if(this.companyPath){
            //         return this.router.navigateByUrl(`../../simulate/${params.id};view=${defaultRoute};status=${defaultRouteParams[defaultRoute].status}`, {replaceUrl: true});
            //     }
            //     else{
            //         return this.router.navigateByUrl(`/admin/simulate/${params.id};view=${defaultRoute};status=${defaultRouteParams[defaultRoute].status}`, {replaceUrl: true});
            //     }
            // } else if (!params.status) {
            //     if(this.companyPath){
            //         return this.router.navigateByUrl(`../../simulate/${params.id};view=${params.view};status=${defaultRouteParams[params.view].status}`, {replaceUrl: true});
            //     }
            //     else{
            //         return this.router.navigateByUrl(`/admin/simulate/${params.id};view=${params.view};status=${defaultRouteParams[params.view].status}`, {replaceUrl: true});
            //     }
            // }

            if (params.view === 'notes') {
                this.filterTranscriptions();
            }
        });
    }

    ngOnInit() {
        this._userManagementService.getUserDetail(this.params.id).subscribe((response: any) => {
            if (response.status === 200) {
                this.simulatedUser = response.data.array;
            }

        })
        this.initTranscriptions();
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }

    getUserNotes() {
      this._adminNotesService.getUserNotes({uploaded_by_id: this.params.id})
          .subscribe((data) => {

          }, error => {

          });
    }


    initTranscriptions() {
        this._adminNotesService.getNotes(this.params.id).subscribe((response: any) => {
            if (response.status === 200) {
                this.transcriptions = response.data;
                this.transcriptionsLoaded = true;
                this.filterTranscriptions(response.data);
            }
        });
    }

    filterTranscriptions(transcriptions = this.transcriptions) {
        switch (this.params.status) {
            case 'uploaded':
                this.filteredTranscriptions = transcriptions.filter(t => t.is_completed === 'true');
                break;
            case 'signed':
                this.filteredTranscriptions = transcriptions.filter(t => t.is_signed === 'true' && t.is_completed === 'false');
                break;
            case 'unsigned':
                this.filteredTranscriptions = transcriptions.filter(t => t.is_published === 'true' && t.is_signed === 'false'); // unsigned
                break;
            case 'pending':
                this.filteredTranscriptions = transcriptions.filter(t => t.is_published === 'false'); // pending
                break;
        }
    }

    countTranscriptions(status) {
        switch (status) {
            case 'uploaded':
                return this.transcriptions.filter(t => t.is_completed === 'true').length;
            case 'signed':
                return this.transcriptions.filter(t => t.is_signed === 'true' && t.is_completed === 'false').length;
            case 'unsigned':
                return this.transcriptions.filter(t => t.is_published === 'true' && t.is_signed === 'false').length; // unsigned
            case 'pending':
                return this.transcriptions.filter(t => t.is_published === 'false').length; // unsigned
        }
    }
}
