<div class="row" style="height:400px;">
  <ng-container *ngIf="!replyCommentView">
    <div style="position: absolute;top: 68px;background-color: white; width: 367px; margin-top: auto; height: 0;">
      <h2>Patient: {{this.patient?.last_name}}, {{this.patient?.first_name}}</h2>
    </div>
    <br> <br>
  </ng-container>
  <ng-container *ngIf="replyCommentView">
    <div style="position: absolute;top: 60px;background-color: white; width: 361px; margin-top: auto;">
      <div style="display: flex;margin-top: 10px;align-items: center;"><span>
          <mat-icon style="cursor: pointer;" (click)="replyCommentView=false; repliedComments= []">arrow_back</mat-icon>
        </span> <b style="margin-left: 135px; margin-bottom: 4px;">Thread</b></div>
    </div>

    <div class="media" *ngIf="replyCommentView"
      style="position: absolute;top: 100px; width: 363px; background-color: #A9DDF8 !important;">
      <div class="media-body">
        <div class="flex flex-row items-start justify-center comments-spacing">
          <span class="media-heading">{{userComment?.created_by?.first_name}} {{userComment?.created_by?.last_name}}
          </span>
          &nbsp;&nbsp;
          <li>{{userComment?.createdAt | date:'medium'}}</li>
        </div>
        <img *ngIf=userComment?.attachment_link style="height: 500px; width: 350px" [src]="userComment?.attachment_link"
          alt="">
        <p *ngIf="userComment?.comment!=='undefined'"
          class="new-roman-font">{{userComment?.comment}}</p>
      </div>
      <mat-divider></mat-divider>
    </div>
  </ng-container>
  <ng-container *ngIf="!replyCommentView">
    <div>
      <div class="media" *ngFor="let comment of comments; let i=index">
        <div class="media-body">
          <div class="flex flex-row items-start justify-center comments-spacing">
            <span class="media-heading">{{comment?.created_by?.first_name}} {{comment?.created_by?.last_name}}
            </span>
            &nbsp;&nbsp;
            <li>{{comment?.updatedAt | date:'medium'}}</li>
          </div>
          <img *ngIf=comment?.attachment_link style="height: 500px; width: 350px" [src]="comment?.attachment_link"
            alt="">
          <p *ngIf="comment?.comment!=='undefined'"
            class="new-roman-font">{{comment?.comment}}</p>
          <div style="margin-top: -12px;">
            <small (click)="editCensusComment(comment)" style="color:blue; cursor: pointer;" class="comment-action">Edit</small>
            <small (click)="replyToComment(comment)" style="cursor: pointer; color: blue; margin-left: 8px;"
              *ngIf="comment?.count" class="comment-action">Replies({{comment?.count}})</small>
            <small (click)="replyToComment(comment)" style="cursor: pointer; color: blue; margin-left: 8px;"
              *ngIf="comment?.count==undefined" class="comment-action">Reply</small>
            <small (click)="deleteCensusComment(comment._id,i)"
              style="color:red; cursor: pointer; margin-left: 8px;" class="comment-action">Delete</small>
          </div>

        </div>
        <mat-divider></mat-divider>
      </div>
      <br> <br> <br> <br> <br> <br>
    </div>
  </ng-container>
  <ng-container *ngIf="replyCommentView">
    <div style="margin-top: 117px;">
      <div class="media" *ngFor="let comment of repliedComments; let i=index">
        <div class="media-body">
          <div class="flex flex-row items-start justify-center comments-spacing">
            <span class="media-heading">{{comment?.created_by?.first_name}} {{comment?.created_by?.last_name}}
            </span>
            &nbsp;&nbsp;
            <li>{{comment?.updatedAt | date:'medium'}}</li>
          </div>
          <img *ngIf=comment?.attachment_link style="height: 500px; width: 350px" [src]="comment?.attachment_link"
            alt="">
          <p *ngIf="comment?.comment!=='undefined'"
            class="new-roman-font">{{comment?.comment}}</p>
        </div>
        <mat-divider></mat-divider>
      </div>
      <br> <br> <br> <br> <br> <br>
    </div>
  </ng-container>

  <br>

  <div style="position: absolute; width: 361px; bottom: 11px; background-color: whitesmoke;">
    <div>
      <mat-form-field style="width: 100%;">
        <mat-label>Leave a comment</mat-label>
        <textarea matInput #commentSection style="height: 7vh;" name="comment" [(ngModel)]="comment"
          placeholder="Ex. It makes me feel..."></textarea>
      </mat-form-field>

      <div class="flex flex-row justify-end items-center" style="padding-top: 8px;">
        <span *ngIf="file" style="margin-right: auto;">
          <small>{{file.name}}</small>
          <small style="text-decoration: underline; margin-left: 10px; color: red; cursor: pointer;"
            (click)="clear()"><u>delete</u></small>
        </span>
        <button *ngIf="isEdit" mat-raised-button color="warn" style="margin-right: auto;"
          (click)="comment=''; isEdit=false">Cancel Edit</button>
        <button *ngIf="!isEdit" [disabled]="comment === ''"  mat-raised-button color="primary" (click)="addComment()">Submit</button>
        <button *ngIf="isEdit" mat-raised-button color="primary" (click)="updateCensusComment()"
          [disabled]="comment === ''">Save</button>
      </div>
    </div>
  </div>
  <mat-icon style="cursor: pointer;" class="census-cancel" (click)="cancelsideNav()">close</mat-icon>
</div>