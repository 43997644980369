<p style="padding: 0 10px;">
    Administration > Schedules > Last Admission
</p>
<table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="directions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            directions
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.directions}}
        </td>
    </ng-container>
   

    <ng-container matColumnDef="dose">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Dose
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.dose}}
        </td>
    </ng-container>
   

    <ng-container matColumnDef="doseUOM">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Dose UOM
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.doseUOM}}
        </td>
    </ng-container>
   

    <ng-container matColumnDef="frequency">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Frequency
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.frequency}}
        </td>
    </ng-container>
   

    <ng-container matColumnDef="indicationsForUse">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Indications For Use
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.indicationsForUse}}
        </td>
    </ng-container>
   

    <ng-container matColumnDef="scheduleType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Schedule Type
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.scheduleType}}
        </td>
    </ng-container>
   

    <ng-container matColumnDef="startDateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Start Date Time
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.startDateTime}}
        </td>
    </ng-container>
   
    
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>