
<p>Would you like to add 
  <ng-container *ngIf="patients">
    {{patients.length}} patients
  </ng-container>
  <ng-container *ngIf="patient">
    <strong>{{patient.name}}</strong> patient 
  </ng-container>
  to the census?</p>
<div class="flex justify-start items-baseline gap-2" style="padding:10px">
  <mat-form-field class="flex-grow"
    *ngIf="currentUser.user_type === global.USER_TYPE.DOCTOR || currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE || currentUser.user_type === global.USER_TYPE.WOUND_NURSE">
    <mat-select [class.user-select-menu]="selectedUser && selectedUser.is_activated !== 'true'" name="provider" [placeholder]="userDropdownPlaceHolder" [ngModel]="selectedProvider ? selectedProvider : currentUser._id">
      <mat-option [class.text-gray]="provider.is_activated !== 'true'" (onSelectionChange)="initPatientRoundingSheets($event, provider, showLimit);" *ngFor="let provider of providers" [value]="provider._id">
        {{provider.first_name}} {{provider.last_name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="flex-grow">
    <mat-label>Select Patient Census</mat-label>
    <mat-select name="patientRoundingSheet" required [(ngModel)]="selectedPatientRoundingSheet">
      <mat-option *ngFor="let patientRoundingSheet of patientRoundingSheets"
        value="{{patientRoundingSheet._id}}">
        {{getDOS(patientRoundingSheet)}} - 
        {{patientRoundingSheet.name}} -
        {{patientRoundingSheet.facility?.title | uppercase}}
      </mat-option>
    </mat-select>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
  </mat-form-field>

  <button mat-raised-button class="dn-button-primary" *ngIf="patientRoundingSheets.length < total"
    (click)="initPatientRoundingSheets(null, selectedUser);">Show all</button>

</div>
<div class="flex justify-between items-baseline" style="margin-bottom: 1px;">
  <button mat-raised-button class="dn-button-secondary" mat-dialog-close>Cancel</button>
  <div class="flex gap-2">

      <button *ngIf="currentUser.portal_type === global.PORTAL_TYPE.NON_WC" mat-raised-button class="dn-button-primary" type="button" (click)="handleAddToRecurrentCensus()">
        Create Recurrent Census
      </button>


    <button mat-raised-button class="dn-button-primary" *ngIf="selectedUser && selectedUser._id === currentUser._id" (click)="showCreateRoundingSheetDialog()">Create New
      Census
    </button>
  
    <button mat-raised-button class="dn-button-primary" (click)="handleAddClick()">Add {{patients ? 'Patients' : 'Patient'}}</button>
  </div>
</div>
<div class="top-right-close-btn">
  <button mat-mini-fab mat-dialog-close>
    <mat-icon>cancel</mat-icon>
  </button>
</div>