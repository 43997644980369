<div class="container flex flex-col gap-2">
    <h3 class="date-div">
        <div class="flex flex-grow" style="margin-left: 10px;cursor: pointer;width: 5%;" *ngIf="reportDetail">
            <span (click)="onClickBack()"> Back </span>
        </div>
        <div *ngIf="reportDetail && transData">
            <mat-form-field style="color: black !important;background: white;border-radius: 6px;
            padding-left: 10px; padding-right: 10px;">
                <mat-label>Select Facility</mat-label>
                <mat-select name="facility" [(ngModel)]="facilityFilter">
                    <mat-option (click)="facilityChaged()">--All--</mat-option>
                    <mat-option *ngFor="let f of facilities" (click)="facilityChaged(f)" value="{{f._id}}">{{f.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="flex flex-grow" style="margin-left: 10px;" *ngIf="!reportDetail">
            <mat-form-field  
            *ngIf="currentCompany && this.currentUser.product_owner" style="color: black !important;background: white;border-radius: 6px;height: 41px;
            width: 120px;
            padding-left: 10px; padding-right: 10px; margin-right: 4px;"
           >
                <mat-select [value]="type" (selectionChange)="radioChange($event)">
                  <mat-option value="trans">Notes</mat-option>
                  <mat-option value="dict">Dictations</mat-option>
                  <mat-option value="charges">Charges</mat-option>
                </mat-select>
              </mat-form-field>
        </div>

        <div class="flex flex-grow" style="width: 75%; place-content: center;height: 51px;" *ngIf="!reportDetail">
            <input matInput type="text" ngxDaterangepickerMd [(ngModel)]="reportDateFilter"
                matTooltip="Date of Creation" matTooltipPosition="above" class="mat-like-input" [ranges]="preDateRanges"
                [alwaysShowCalendars]="true" [linkedCalendars]="true" [showClearButton]="false"
                (datesUpdated)="applyDateFilterReport($event)" style="color: white; cursor: pointer;
                        width: 225px;
                        height: 50px;
                        font-size: 16px !important;
                        background: #2d3e5b !important;" />
        </div>

        <div 
        style="width: 15%;justify-content: flex-end; padding-right: 15px;height: 51px; padding-top: 5px;"></div>

        <div class="flex justify-end items-center" 
            style="width: 20%; padding-right: 15px;height: 51px; padding-top: 5px;">
            <mat-form-field  
            *ngIf="currentCompany && this.currentUser.product_owner" style="color: black !important;background: white;border-radius: 6px;height: 41px;
            width: 120px;
            padding-left: 10px; padding-right: 10px; margin-right: 4px;"
           >
                <mat-select [value]="currentCompany">
                  <mat-option  *ngFor="let company of companies" [value]="company._id"
                  (onSelectionChange)="navigate($event, company._id)">
                  {{company.name}}
                </mat-option>
                </mat-select>
              </mat-form-field>

            <mat-form-field style="color: black !important;background: white;border-radius: 6px;height: 41px;
            width: 120px;
            padding-left: 10px; padding-right: 10px;" *ngIf="!reportDetail && transData">
                <mat-select [(ngModel)]="noteStatusFilter" name="status">
                    <mat-option *ngFor="let status of statusOption" [value]="status" (click)="onChangeStatus(status);">
                        {{status}} 
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <span style="color: white !important; align-self: center; cursor: pointer;" *ngIf="reportDetail">
                <span (click)="downloadReport()"> Downlaod </span>
            </span>
        </div>
    </h3>
    <div class="flex gap-2" style="overflow: auto; background: white;"
        *ngIf="!reportDetail && transData">
        <table mat-table [dataSource]="dataSource" matSort [loaderId]="reportData" ngxUiLoaderBlurred>
            <ng-container matColumnDef="provider">
                <th mat-header-cell *matHeaderCellDef>Provider</th>
                <td mat-cell *matCellDef="let element" style="width: 20%;cursor: pointer;">
                    {{getProviderName(element)}}
                </td>
            </ng-container>
            <ng-container matColumnDef="initial" style="text-align: center;">
                <th mat-header-cell *matHeaderCellDef>Initial</th>
                <td mat-cell *matCellDef="let element" style="width: 10%;cursor: pointer;">
                    {{getInitialCount(element)}}
                </td>
            </ng-container>
            <ng-container matColumnDef="followUp" style="text-align: center;">
                <th mat-header-cell *matHeaderCellDef>Follow Up</th>
                <td mat-cell *matCellDef="let element" style="width: 10%;cursor: pointer;">
                    {{getFollowUpCount(element)}}
                </td>
            </ng-container>
            <ng-container matColumnDef="others" style="text-align: center;">
                <th mat-header-cell *matHeaderCellDef>Others</th>
                <td mat-cell *matCellDef="let element" style="width: 10%;cursor: pointer;">
                    {{getOthersCount(element)}}
                </td>
            </ng-container>
            <ng-container matColumnDef="total" style="text-align: center;">
                <th mat-header-cell *matHeaderCellDef>Total</th>
                <td mat-cell *matCellDef="let element" style="width: 10%;cursor: pointer;">
                    {{getTotalCount(element)}}
                </td>
            </ng-container>
            <ng-container matColumnDef="byProvider" style="text-align: center;">
                <th mat-header-cell *matHeaderCellDef>By Provider</th>
                <td mat-cell *matCellDef="let element" style="width: 10%;cursor: pointer;">
                    {{getCountTransByPro(element)}}
                </td>
            </ng-container>
            <ng-container matColumnDef="byTranscriber" style="text-align: center;">
                <th mat-header-cell *matHeaderCellDef>By Scriber</th>
                <td mat-cell *matCellDef="let element" style="width: 10%;cursor: pointer;">
                    {{getCountTransByTran(element)}}
                </td>
            </ng-container>
            <ng-container matColumnDef="withAudio" style="text-align: center;">
                <th mat-header-cell *matHeaderCellDef>Have Audio</th>
                <td mat-cell *matCellDef="let element" style="width: 10%;cursor: pointer;">
                    {{getCountTransWithAudio(element)}}
                </td>
            </ng-container>
            <ng-container matColumnDef="withoutAudio" style="text-align: center;">
                <th mat-header-cell *matHeaderCellDef>Without Audio</th>
                <td mat-cell *matCellDef="let element" style="width: 10%;cursor: pointer;">
                    {{getCountTransWithoutAudio(element)}}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                (click)="$event.preventDefault();getSpecificNoteData(row, 'all')">
            </tr>
        </table>
        <ngx-ui-loader loaderId="reportData"></ngx-ui-loader>
    </div>

    <div *ngIf="reportDetail && transData"> 
        <app-report-detail [detailData]="detailData" ></app-report-detail>
    </div>
    <div *ngIf="!transData && dictData">
        <app-trans-report [transReportData]="transReportData"></app-trans-report>
    </div>
</div>