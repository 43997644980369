import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { AppointmentService } from 'src/app/services/appointment.service';
import { AuthService } from 'src/app/services/auth.service';
import { lastValueFrom, Observable, of, Subject } from 'rxjs';
import { NgxUiLoaderService, Tasks } from 'ngx-ui-loader';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import * as global from 'src/app/includes/global';
import { FormControl } from '@angular/forms';
import {
    map,
    debounceTime,
    distinctUntilChanged,
    tap,
    takeUntil, finalize
} from "rxjs/operators";
import { FacilityService } from 'src/app/services/facility.service';
import { TranscriptionCreateService } from '../transcription-create/transcription-create.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '../dashboard/dashboard.service';
import {
    SnfWcNurseAssociationService
} from '../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { ViewOnlyUsersService } from 'src/app/services/Modules/view-only-users.service';
import { CommonService } from 'src/app/services/common.service';
import { PatientListService } from '../patient-list/patient-list-service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { PusherService } from '../../services/pusher-service';

interface IFilter {
    provider_id: string;
    facility_id: string;
    date_of_service: {
        startDate: {
            day: any;
            month: any;
            year: any;
        };
        endDate: {
            day: any;
            month: any;
            year: any;
        };
    };
}

interface searchFilter {
    patientName: string,
    dob?: String | any,
}

@Component({
    selector: 'app-appointment-calender',
    templateUrl: './appointment-calender.component.html',
    styleUrls: ['./appointment-calender.component.css']
})
export class AppointmentCalenderComponent implements OnInit, OnDestroy {
    @ViewChild(MatSort) sort: MatSort;

    loaderId = 'app-wound-enterprise-report';
    dataSource = new MatTableDataSource([]);
    currentUser = null;
    providers = [];
    searchTextProvider: string = '';
    searchText: string = '';
    facilities: Array<any> = [];
    date_of_service: any = '';
    searchFilter: searchFilter;
    patientsSearchLoaderId = 'appointment-patients-loader';
    patientsByProviderControl = new FormControl();
    filteredPatientsByProvider: Observable<any[]>;
    patientNotFound = false;
    providerCanChangeAppointmentStatus = false;
    visitStatuses = [];
    onDestroy$ = new Subject();
    filter: IFilter = {
        provider_id: '',
        facility_id: '',
        date_of_service: {
            startDate: {
                day: '',
                month: '',
                year: ''
            },
            endDate: {
                day: '',
                month: '',
                year: ''
            }
        }
    };
    displayedColumns: string[] = [
        'time',
        'name',
        'dob',
        'gender',
        'appointment',
        'provider',
        'charge',
        'status'
    ];

    constructor(
        private _authService: AuthService,
        private appointmentService: AppointmentService,
        private loader: NgxUiLoaderService,
        private _router: Router,
        private facilityService: FacilityService,
        private _transcriptionCreateService: TranscriptionCreateService,
        private toastr: ToastrService,
        private dashboardService: DashboardService,
        private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
        private viewOnlyUsersService: ViewOnlyUsersService,
        private commonService: CommonService,
        private companyService: CompanyService,
        private _patientListService: PatientListService,
        private pusherService: PusherService
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.filter.provider_id = this.commonService.getLastProviderFromMenuArray() || this._authService.currentUser._id;
        this.searchFilter = this.searchFilterInitialState();
    }

    ngOnInit(): void {
        this.companyService
            .getCompanyAppointmentVisitStatus(this._authService.currentUser.company_id)
            .subscribe((statuses) => this.visitStatuses = statuses?.data ?? []);

        this.companyService
            .getCompanyName(this._authService.currentUser.company_id)
            .subscribe((response) => {
                if ( response.status === 200 ) {
                    this.providerCanChangeAppointmentStatus = response.data?.provider_can_change_status;
                }
            });

        this.pusherService.appointmentUpdates()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(({ event, data }) => {
                switch (event) {
                    case 'appointment_status_updated':
                        const { _id, appointment_status_visit } = data;
                        for (const item of this.dataSource.data) {
                            if (item?.id === _id && appointment_status_visit) {
                                item.appointment_status = appointment_status_visit?.name ?? 'none';
                                item.appointment_visit_status = appointment_status_visit;
                                break;
                            }
                        }
                        break;

                }
            });

        let date_obj: any = {};
        if (this.commonService.getLastDOSFromMenuArray()) {
            date_obj = this.commonService.getLastDOSFromMenuArray();
        } else {
            const [year, month, day] = this.commonService.convertDateToStringforMoment(moment()).split('-');
            date_obj = {
                year: Number(year),
                month: Number(month),
                day: Number(day)
            };
        }
        this.date_of_service = this.commonService.dateObjToString_format2(date_obj, false);
        this.filter.facility_id = this.commonService.getLastFacilityFromMenuArray();
        this.filter.date_of_service = {
            startDate: {
                day: date_obj.day,
                month: date_obj.month,
                year: date_obj.year
            },
            endDate: {
                day: date_obj.day,
                month: date_obj.month,
                year: date_obj.year
            }
        };
        this.getAppointmentSettings();
        this.getAllAppointments(this.filter);
        this.initProviders();
        this.initFacilities(null, this.filter.provider_id);
        this.initPatientsByProvider();
        this.dataSource.sort = this.sort;
    }

    convertToPdf(saveMode: 'print' | 'download'): void {
        const doc = new jsPDF();

        // Local mapping of display values with actual keys in data object
        const columnAndValueMapping: { [key in string]: { key: string, column: string } } = {
            'time': { key: 'start', column: 'Time' },
            'name': { key: 'name', column: 'Name' },
            'dob': { key: 'dob', column: 'DOB' },
            'gender': { key: 'gender', column: 'Gender' },
            'appointment': { key: 'appointment_type', column: 'Appointment' },
            'provider': { key: 'providerName', column: 'Provider' },
            'charge': { key: 'Done', column: 'Charge' },
            'status': { key: 'appointment_status', column: 'Status' }
        };

        // Display header values
        const columns = this.displayedColumns.map(c => columnAndValueMapping[c].column);
        // Row values
        const rows = this.dataSource.data.map(item => this.displayedColumns.map(dColumn => item[columnAndValueMapping[dColumn].key]));

        // Set document title
        const title = 'Appointments list';
        const titleMarginLeft = 15; // Adjust the margin as needed
        const titleMarginTop = 20; // Adjust the margin as needed
        doc.setFontSize(18); // You can adjust the font size as well
        doc.text(title, titleMarginLeft, titleMarginTop);

        // Add content to the PDF
        autoTable(doc, { startY: titleMarginTop + 10, head: [columns], body: rows });

        const provider = this.providers.find(p => p._id === this.filter.provider_id);

        // Save/Print PDF
        if (saveMode === 'download') {
            doc.save(`${provider?.name ?? ''} Appointments Table.pdf`);
        } else {
            // Convert the PDF to a Blob
            const pdfBlob = doc.output('blob');

            // Create a URL for the Blob
            const url = URL.createObjectURL(pdfBlob);

            // Open PDF in a new window
            const win = window.open(url, '_blank');

            // Open print view
            if (win) {
                win.onload = () => {
                    win.print(); // Trigger print functionality
                };
            } else {
                console.error('Failed to open the PDF in a new window.');
            }

            window.URL.revokeObjectURL(url);
        }


    }

    getAllAppointments(filter = {}) {
        this.loader.startLoader(this.loaderId);

        this.appointmentService
            .getAppointments({
                company_id: this._authService.currentUser.company_id,
                ...filter
            })
            .pipe(
                finalize(() => this.loader.stopLoader(this.loaderId))
            )
            .subscribe((res: any) => {
                if (res.status === 200) {
                    const appointmentList = res.data.map((item) => {
                        const {
                            patient_id,
                            provider_id,
                            appointment_type,
                            createdAt
                        } = item;
                        const { first_name, last_name, gender, date_of_birth } =
                            patient_id;

                        return {
                            id: item._id,
                            name: `${last_name}, ${first_name}`,
                            start: this.convertToAmPmTime(item.startTime),
                            gender,
                            dob: moment(date_of_birth).format("MM/DD/YYYY"),
                            providerName: `${provider_id.first_name} ${provider_id.last_name}`,
                            appointment_type: item?.appointment_type_id?.name || "",
                            appointmentTypeColor: item?.appointment_type_id?.color || "",
                            appointment_status: item?.appointment_visit_status?.name || "Scheduled",
                            appointment_visit_status: item?.appointment_visit_status,
                            census_id: item.census_id || "",
                            createdAt,
                            patient_id: patient_id._id
                        };
                    });

                    this.dataSource.data = this.customSort(
                        appointmentList,
                        "start",
                        "asc"
                    ); // default sort ascending (time)
                }
            });
    }

    getAppointmentSettings() {
        this.companyService
            .getAppointmentColumnSetting(this._authService.currentUser.company_id)
            .subscribe((response: any) => {
                this.displayedColumns = response.data
                    .filter(column => this.displayedColumns.includes(column)) // Filter out any columns not in displayedColumns
                    .sort((a, b) => this.displayedColumns.indexOf(a) - this.displayedColumns.indexOf(b)); // Sort based on the order in displayedColumns
            });
    }

    initProviders() {
        switch (this.currentUser.user_type) {
            case global.USER_TYPE.DOCTOR:
                this.dashboardService.getP2PRelation(null, this.currentUser._id)
                    .subscribe((res: any) => {
                        if (res.status == 200) {
                            this.populateProviders(res.data.assoc_provider_id);
                        }
                    });
                break;
            case global.USER_TYPE.MEDICAL_ASSISTANT:
                this.initAssociatedProvidersListForMA();
                break;
            case global.USER_TYPE.BUSINESS_OFFICER:
                this.initAssociatedProvidersListForBO();
                break;
            case global.USER_TYPE.SNF_WC_NURSE:
                this.initAssociatedNurseList();
                break;
            case global.USER_TYPE.WOUND_NURSE:
                this.initAssociatedWoundNurseList();
                break;
            case global.USER_TYPE.VIEW_ONLY_USER:
                this.getAssociatedProvidersListForViewOnlUsers();
                break;
            default:
                this.initAssociatedProvidersList();
                break;
        }
    }

    initAssociatedProvidersList() {
        this.dashboardService
            .getAssociatedDoctorsList(this.currentUser.company_id)
            .subscribe((response: any) => {
                if (response.status === 200) {
                    this.providers = response.data;
                    if (this.filter.provider_id) {
                        this.initFacilities(null, this.filter.provider_id);
                    } else {
                        this.initFacilities();
                    }
                    this.providers = this.providers.map((d) => ({
                        ...d,
                        name: `${d.first_name} ${d.last_name}, ${d.title}`
                    }));
                    if (!this.filter.provider_id) {
                        this.filter.provider_id = this.providers[0]._id;
                    }
                }
            });
    }

    getAssociatedProvidersListForViewOnlUsers() {
        this.viewOnlyUsersService.getAssociatedProvidersListForViewOnlUsers(
            {
                user_id: this.currentUser._id,
                company_id: this.currentUser.company_id
            }
        ).subscribe((doctorsResponse: any) => {
            this.populateProviders(doctorsResponse.data || []);
            this.initFacilities(null, this.filter.provider_id);
        })
    }

    populateProviders(assoc_provider_id) {
        if (
            this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE ||
            this.currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER
        ) {
            this.providers = assoc_provider_id;
        } else {
            this.providers = [this.currentUser].concat(assoc_provider_id);
        }

        this.providers = this.providers.map((d) => ({
            ...d,
            name: `${d.first_name} ${d.last_name}, ${d.title}`
        }));
        if (this.filter.provider_id) {
            let filtered_provider = this.providers.filter(
                (item) => item._id == this.filter.provider_id
            );
            if (filtered_provider.length > 0) {
                this.filter.provider_id = filtered_provider[0]._id;
            }
        }
        if (!this.filter.provider_id) {
            this.filter.provider_id = this.providers[0]._id;
        }
    }

    initAssociatedProvidersListForMA() {
        this._transcriptionCreateService
            .getAssociatedDoctorsListForMA()
            .subscribe((response: any) => {
                if (response.status === 200) {
                    this.providers = response.data;
                    if (this.providers?.length > 0) {
                        const providerId =
                            this.filter.provider_id || this.providers[0]._id;
                        this.initFacilities(null, providerId);
                    }
                    if (this.providers?.length > 0) {
                        this.providers = this.providers.map((d) => ({
                            ...d,
                            name: `${d.first_name} ${d.last_name}, ${d.title}`
                        }));
                        if (!this.filter.provider_id) {
                            this.filter.provider_id = this.providers[0]._id;
                        }
                    }
                }
            });
    }

    initAssociatedProvidersListForBO() {
        this._transcriptionCreateService
            .getAssociatedDoctorsListForBO(
                this.currentUser._id,
                this.currentUser.company_id,
                this.currentUser.user_type
            )
            .subscribe((response: any) => {
                if (response.status === 200) {
                    this.providers = response.data;
                    if (this.providers?.length > 0) {
                        const providerId =
                            this.filter.provider_id || this.providers[0]._id;
                        this.initFacilities(null, providerId);
                    }
                    if (this.providers?.length > 0) {
                        this.providers = this.providers.map((d) => ({
                            ...d,
                            name: `${d.first_name} ${d.last_name}, ${d.title}`
                        }));
                        if (!this.filter.provider_id) {
                            this.filter.provider_id = this.providers[0]._id;
                        }
                    }
                }
            });
    }

    initAssociatedNurseList() {
        const filter = {
            snf_wc_nurse_id: this.currentUser._id,
            company_id: this.currentUser.company_id
        };
        const project = {
            first_name: 1,
            last_name: 1,
            title: 1
        };
        this._snfWcNurseAssociationService
            .getAssociatedSnfWcNurses(filter, project)
            .subscribe((response: any) => {
                if (response.status === 200) {
                    this.populateProviders(
                        response.data.associated_snf_wc_nurse_ids || []
                    );
                    this.initFacilities(null, this.filter.provider_id);
                }
            });
    }

    async initAssociatedWoundNurseList() {
        const associatedProviders: any = await lastValueFrom(
            this._transcriptionCreateService.getAssociatedDoctorsListForWoundNurse(
                this.currentUser._id,
                this.currentUser.company_id
            )
        );
        if (associatedProviders.status == 200) {
            this.populateProviders(associatedProviders.data || []);
            this.initFacilities(null, this.filter.provider_id);
        }
    }

    handleProviderChange() {
        if (
            this.currentUser.user_type !== global.USER_TYPE.SNF_WC_NURSE &&
            this.currentUser.user_type !== global.USER_TYPE.WOUND_NURSE
        ) {
        }
        this.initFacilities(null, this.filter.provider_id);
        this.filter.facility_id = '';
        this.applyFilter();
    }

    handleFilterFacilityChange() {
        this.applyFilter();
    }

    initFacilities(selectedProvider = null, provider_id?) {
        if (
            (!this.filter.facility_id &&
                this.currentUser.user_type ===
                global.USER_TYPE.MEDICAL_ASSISTANT) ||
            this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER ||
            this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE
        ) {
            if (this.providers?.length > 0) {
                selectedProvider = this.providers[0]._id;
            } else return;
        }
        if (provider_id) selectedProvider = provider_id;
        this.facilities = [];
        const filter = {
            associated_snf_wc_nurse_ids: selectedProvider,
            company_id: this._authService.currentUser.company_id
        };
        const nurseProjection = {
            first_name: 1,
            last_name: 1,
            title: 1
        };
        const facilityProjection = {
            title: 1,
            pcc_facId: 1,
            pcc_2_legged_authentication: 1,
            source: 1,
            pcc_orgUuid: 1
        };
        if (
            this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE ||
            this.currentUser.company_type === global.COMPANY_TYPE.SNF
        ) {
            this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(
                filter,
                nurseProjection,
                facilityProjection
            ).subscribe((response: any) => {
                if (response.status === 200 && response.data.length > 0) {
                    this.facilities = response.data;
                    this.facilities.sort((a, b) =>
                        a.title.toLowerCase().localeCompare(b.title.toLowerCase())
                    );
                }
            });
        } else {
            this.facilityService
                .getMyFacilities(null, selectedProvider)
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        this.facilities = response.data.array;
                        this.facilities.sort((a, b) =>
                            a.title
                                .toLowerCase()
                                .localeCompare(b.title.toLowerCase())
                        );
                    }
                });
        }
    }

    changeServiceDate(event) {
        if (event.value) {
            this.filter.date_of_service = {
                startDate: {
                    day: moment(event.value).date(),
                    month: moment(event.value).month() + 1,
                    year: moment(event.value).year()
                },
                endDate: {
                    day: moment(event.value).date(),
                    month: moment(event.value).month() + 1,
                    year: moment(event.value).year()
                }
            };
            this.date_of_service = moment(event.value).toDate();
        } else {
            this.date_of_service = moment();
        }
        this.applyFilter();
    }

    onRowClick(patient) {
        const [year, month, day] = this.commonService.convertDateToStringforMoment(moment(this.date_of_service)).split('-');
        const date_obj = {
            year: Number(year),
            month: Number(month),
            day: Number(day)
        };
        const pt = {
            _id: patient.patient_id,
            name: patient.name,
            appointment_id:patient.id,
            date_of_service: this.date_of_service,
            date_obj: date_obj,
            provider_id: this.filter.provider_id,
            facility_id: this.filter.facility_id,
            company_id: this._authService.currentUser.company_id,
            census_id: patient.census_id
        };
        this.commonService.addObjectIfNotExists(pt);
        this._router.navigate([`/patient-detail/${patient.patient_id}`]);
    }

    applyFilter() {
        if (this.filter) {
            this.getAllAppointments(this.filter);
        }
    }

    onSortChange(event: Sort) {
        const sortEvent = event as Sort;
        const sortColumn = sortEvent.active;
        const sortDirection = sortEvent.direction;
        this.dataSource.data = this.customSort(
            this.dataSource.data,
            sortColumn,
            sortDirection
        );
    }

    customSort(data: any[], column: string, direction: string): any[] {
        return data.sort((a, b) => {
            const valueA = a[column];
            const valueB = b[column];

            if (typeof valueA === 'string' && this.isValidTimeFormat(valueA)) {
                const sortableA = this.convertTimeToSortableFormat(valueA);
                const sortableB = this.convertTimeToSortableFormat(valueB);
                return direction === 'asc' ? sortableA - sortableB : sortableB - sortableA;
            } else {
                return direction === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
            }
        });
    }

    isValidTimeFormat(timeString: string): boolean {
        return /^(\d{1,2}):(\d{2})\s*(AM|PM)$/.test(timeString);
    }

    convertTimeToSortableFormat(timeString: string): number | null {
        if (!timeString) return null;

        const [time, period] = timeString.split(' ');
        const [hours, minutes] = time.split(':');
        let hoursValue = parseInt(hours, 10);
        const minutesValue = parseInt(minutes, 10);

        if (period === 'PM' && hoursValue < 12) {
            hoursValue += 12;
        } else if (period === 'AM' && hoursValue === 12) {
            hoursValue = 0;
        }

        return hoursValue * 60 + minutesValue;
    }

    displayPatientFn(patient): string {
        return patient && patient.name ? patient.name : '';
    }

    setFormValue(patient: any) {
        const pt = {
            _id: patient._id,
            name: patient.name,
            date_of_service: ''
        };
        this.commonService.addObjectIfNotExists(pt);
        this._router.navigate([`/patient-detail/${patient._id}`]);
    }

    initPatientsByProvider() {
        this.patientsByProviderControl.valueChanges
            .pipe(takeUntil(this.onDestroy$), debounceTime(1000), distinctUntilChanged())
            .subscribe((name) => this.doInitPatients(name));
    }

    doInitPatients(name: string): void {
        if (!name || typeof name !== 'string' || name.length < 4) {
            this.filteredPatientsByProvider = of([]);
            return;
        }
        const regex = /[0-9]+.*[a-zA-Z]+|[a-zA-Z]+.*[0-9]+/;
        if (regex.test(name)) {
            this.toastr.error('Input contains a mixture of numbers and characters.');
            return;
        }
        if (this.isDateInput(name.trim())) {
            const formattedDate = this.parseDate(name.trim());
            if (formattedDate) {
                this.searchFilter.patientName = '';
                this.searchFilter.dob = formattedDate;
            }
        } else {
            this.searchFilter.patientName = name;
            this.searchFilter.dob = undefined;
        }
        this.loader.startLoader(this.patientsSearchLoaderId);

        this.filteredPatientsByProvider = this._patientListService
            .getPatientsV2(
                this._authService.currentUser.company_id,
                undefined,
                undefined,
                this.searchFilter.patientName,
                this.searchFilter.dob,
                undefined,
                undefined,
                undefined,
                undefined,
                true
            )
            .pipe(
                tap(() => {
                    this.loader.stopLoader(this.patientsSearchLoaderId);
                }),
                map((patientsResponse: any) => {
                    return patientsResponse.data && patientsResponse.data.array
                        .map((p) => ({
                            ...p,
                            name: this.commonService.getPatientFullNameFormat2(
                                p
                            )
                        }));
                }),
                tap((filteredPatients: any[]) => {
                    if (Array.isArray(filteredPatients) && filteredPatients.length === 0) {
                        this.patientNotFound = true;
                    } else {
                        this.patientNotFound = false;
                    }
                })
            );
    }

    searchFilterInitialState(): searchFilter {
        return {
            patientName: '',
            dob: ''
        };
    }

    convertToAmPmTime(time: string): string {
        let [hours, minutes] = time.split(':').map(Number);
        let period = 'AM';

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                hours -= 12;
            }
        }

        return `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
    }

    isDateInput(input: string): boolean {
        const regex = /^(\d{1,2})[-.,\/](\d{1,2})[-.,\/](\d{4})$/;
        return regex.test(input);
    }

    parseDate(input: string): string {
        const parsedDate = moment(input, ['MM.DD.YYYY', 'MM/DD/YYYY', 'MM-DD-YYYY', 'MM,DD,YYYY'], true);
        return parsedDate.isValid() ? parsedDate.format(global.moment_date_format2) : null;
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(null);
        this.onDestroy$.complete();
    }

    updateVisitStatus(element, status): void {
        this.loader.startLoader(element.id);
        this.appointmentService.updateAppointmentStatus({ _id: element.id, appointment_status: status?._id })
            .pipe(
                finalize(() => {
                    this.loader.stopLoader(element.id);
                })
            )
            .subscribe({
                next: (res: any) => {
                    element.appointment_visit_status = status;
                    this.toastr.success(res.message, 'Success');
                },
                error: () => {
                    this.toastr.error('Failed to update appointment status', 'Error')
                }
            });
    }

    disableStatusDropdown(loaderId: string): boolean {
        return !!Object.keys(this.loader.getLoader(loaderId).tasks).length || !this.providerCanChangeAppointmentStatus;
    }
}
