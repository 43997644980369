<table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="allergen">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Allergen
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.allergen}}
        </td>
    </ng-container>

    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Type
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.type}}
        </td>
    </ng-container>

    <ng-container matColumnDef="onsetDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Onset Date
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.onset_date_string ? element.onset_date_string : (element.onset_date | date: 'MM/dd/yyyy')}} 
        </td>
    </ng-container>

    <ng-container matColumnDef="reactionType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Reaction Type
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.reaction_type}}
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Actions
        </th>
        <td mat-cell *matCellDef="let element; let i=index">
            <div class="operation-buttons buttons-div flex flex-inline justify-start items-center gap-5">
                <span class="material-icons" matTooltip="Edit" matTooltipPosition="above" style="cursor: pointer;"
                    (click)="openUpdatePaitentAllergyDialog(element._id, i);">
                    edit_note
                </span>
                <span class="material-icons" matTooltip="Delete" matTooltipPosition="above" style="cursor: pointer;"
                    (click)="deletePatientAllergy(element._id, i)">
                    delete_outline
                </span>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<br />
<div *ngIf="allergyIntolerances" class="flex justify-start items-center">
    <button mat-raised-button class="dn-primary-button" (click)="goToPreviousPage();" [disabled]="!pagination.hasPrevPage">Prev</button>
    <button mat-raised-button class="dn-primary-button" (click)="goToNextPage();" [disabled]="!pagination.hasNextPage">Next</button>
</div>
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>