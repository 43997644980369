<div style="position: relative;">
    <button mat-raised-button class="dn-button-primary" (click)="showDoctorDocumentFileUploadDialog()" style="position: absolute;top:10px;right:10px">Upload</button>
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="document_title">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container> Document Title </th>
            <td mat-cell *matCellDef="let element">
                <div class="flex items-start justify-center gap-2">
                    <img [src]="getItemIcon(element.title)" style="width: 18px;" onerror="this.src='../../../../assets/img/filetypes/unknown.svg'"  />
                    <span>
                        {{element.title | titlecase}}
                    </span>
                </div>
            </td>
        </ng-container>
    
        <ng-container matColumnDef="uploaded_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container> Uploaded At </th>
            <td mat-cell *matCellDef="let element">
                <span>
                    {{element.createdAt | date: global.date_format}}
                </span>
            </td>
        </ng-container>
    
        <ng-container matColumnDef="operations">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container></th>
            <td mat-cell *matCellDef="let element">
                    
                <div class="flex gap-10 justify-center items-center">
                    <i class="icon icon-download" (click)="downloadFile(element)" matTooltip="Download"
                            matTooltipPosition="above"></i>
                            <i class="icon icon-delete" (click)="deleteDocumentFile(element)" matTooltip="Delete"
                            matTooltipPosition="above"></i>
                        </div>
    
    
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
    </table>
</div>