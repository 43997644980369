<div [class.container]="companySide">
    <div class="flex items-center gap-5">
        <button *ngIf="companySide"
            mat-raised-button
            class="dn-button-secondary"
            [routerLink]="['../']" >
            Go Back
        </button>
        <app-input-with-dropdown class="flex flex-row" [noDropdown]="true" placeholder="Search Facility"
            (inputChange)="applyFilter($event.target.value)">
        </app-input-with-dropdown>

    </div>
    <div style="height: 10px;"></div>
    <mat-card>
        <mat-card-content>

            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="facility">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Facility</th>
                    <td mat-cell *matCellDef="let element" style="width: 20%"> {{element.title}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="provider">
                    <th mat-header-cell *matHeaderCellDef> Provider </th>
                    <td mat-cell *matCellDef="let element" style="width: 60%">
                        <ng-multiselect-dropdown 
                        name="providers"
                                                 [(ngModel)]="element.provider_ids_ref"
                            [placeholder]="'Select Provider'" [data]="providers"
                                                 [settings]="providerDropdownSettings"
                            (onSelect)="onProviderSelect($event, element); "
                            (onSelectAll)="onSelectAllProvider($event, element); "
                            (onDeSelect)="onProviderDeSelect($event, element);">
                        </ng-multiselect-dropdown>
                    </td>

                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

        </mat-card-content>
    </mat-card>

</div>
