<app-header *ngIf="currentUser.user_type !== global.USER_TYPE.BILLER"></app-header>
<app-biller-header *ngIf="currentUser.user_type === global.USER_TYPE.BILLER"></app-biller-header>

<div *ngIf="showLowMemoryBanner" class="banner show">
    Warning: The document size has exceeded the recommended limit. This may lead to slower performance and potential browser crashes.   
</div>

<div [class]="bannerOnTop()">
    <main [class]="noteStateCssClasses()">
        <div class="container flex flex-col" style="height: 90vh;">
            <div class="editor-sidebar-wrapper flex items-start justify-stretch" style="position: relative;">
                <ng-container *ngIf="note">
                    <div *ngIf="telemedicine" class="sidebar-wrapper flex items-start justify-stretch">
                        
                        <app-sidebar-video-call class="flex" *ngIf="videoSidebarVisible" >
                        </app-sidebar-video-call>

                    <button  class="sidebar-toggle-btn" (click)="videoSidebarVisible=!videoSidebarVisible">
                        <mat-icon *ngIf="videoSidebarVisible">keyboard_arrow_left</mat-icon>
                        <mat-icon *ngIf="!videoSidebarVisible">keyboard_arrow_right</mat-icon>
                    </button>
                    </div>

                    <div class="flex flex-col" style="width:-webkit-fill-available">
                        <div class="transcription-audio flex flex-col" *ngIf="note.hasAudio()">
                            <div class="flex gap-2">
                                <div class="w-1/4 flex items-center justify-start">
                
                                    <div class="flex-grow-0">
                                        <span class="dp"></span>
                                    </div>
                
                                    <div class="items-start justify-center">
                                        <div class="flex flex-col">
                                            <div class="title">{{note.audio.audio_title}}</div>
                                            <div>
                                                <span class="date">DOS: {{getAudioDOS() | date: global.date_time_format}}</span>
                                            </div>
                                            <div>
                                                <span class="date">DOC: {{getAudioDOC() | date: global.date_time_format}}</span>
                                            </div>
                                        </div>
                                    </div>
                
                                </div>
                
                                <div class="flex items-center justify-center">
                                    <div class="transcription-player" style="flex:1">
                                        <audio id="transcription" [src]="note.audio.aws_path" controls controlsList="nodownload"
                                            style="width:100%"></audio>
                                    </div>
                                </div>
                
                
                                <div class="items-end justify-center">
                                    <div class="flex flex-col gap-2">
                                        <div class="duration flex justify-between items-end">
                
                                            <div class="flex flex-col" style="margin-right: 30px">
                                                <label style="margin-top: -19px" for="back-slider">Back</label>
                                                <mat-slider id="back-slider"
                                                            min="1"
                                                            max="5"
                                                            step="1"
                                                            thumbLabel="true"
                                                            [(ngModel)]="backSlider"
                                                            style="height: 14px; top: -13px; right: 5px">
                                                </mat-slider>
                                            </div>
                
                                            <div class="flex flex-col">
                                                <label style="margin-top: -19px" for="speed-slider">Speed</label>
                                                <mat-slider id="speed-slider"
                                                            min="0.5"
                                                            max="2.0"
                                                            step="0.5"
                                                            thumbLabel="true"
                                                            [(ngModel)]="speedSlider"
                                                            style="height: 14px; top: -13px; right: 5px"></mat-slider>
                                            </div>
                                            {{note.audio.audio_length}}
                                        </div>
                                        <div class="status-col flex justify-between items-center">
                
                                            <button
                                                    *ngIf="currentUser.user_type == global.USER_TYPE.DOCTOR || currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE"
                                                    class="theme-btn status"
                                                    style="top:0 !important; right: 7px !important;"
                                                    class="dn-button-primary"
                                                    [class.completed]="note.audio.progress_status=='20'"
                                                    mat-raised-button>{{note.audio.progress_status=='20' ? 'COMPLETED' : 'IN PROGRESS'}}</button>
                                            <div style="width: 236px;">
                                                
                                            <app-switch-button
                                            labelOff="In Progress"
                                            labelOn="Completed"
                                            [thirdForm]="false"
                                            (handleOff)="changeCurrentAudioStatus(19)"
                                            (handleOn)="changeCurrentAudioStatus(20)"
        
                                            [checked]="note.audio.progress_status=='20'"
                                    >
                                    </app-switch-button>
                                            </div>
                
                                        </div>
                                    </div>
                                </div>
                            </div>
                
                        </div>
                        <div class="editor-top flex flex-col gap-2">
                            <div class="editor-buttons flex items-start justify-center gap-2">
                                <div><button class="dn-button-secondary" mat-raised-button (click)="goBack()">Back</button></div>
                                <div class="flex flex-grow items-center justify-center gap-2">
                                    <button class="dn-button-primary" style="min-width: 140px !important" mat-raised-button (click)="saveNoteWithCharge()" [disabled]="checkEnabledState()">Save</button>
                                    <button class="dn-button-primary" style="min-width: 140px !important" mat-raised-button (click)="submitForReviewNote()" [disabled]="!buttonsState.submitForReview">Submit for Review</button>
                                    <button *ngIf="!note.isSigned() && !note.isUploaded()" class="dn-button-primary" style="min-width: 140px !important" mat-raised-button (click)="revertNote()" [disabled]="!buttonsState.revert">Return</button>
                                    <button class="dn-button-primary" style="min-width: 140px !important" mat-raised-button (click)="signNote()" [disabled]="checkSignedEnableButton()">Sign</button>

                                    <button *ngIf="!hideUploadButton()" class="dn-button-primary" style="min-width: 140px !important" mat-raised-button (click)="uploadNote()" [disabled]="!buttonsState.upload">Upload</button>

                                    <ng-container *ngIf="note.isUploaded() || (this.note.isSigned() && !this.note.isUploaded() && !is_uploadPcc_permission_enabled)">
                                            <ng-container *ngIf="note.hasPCCPatient()">
                                                <button class="dn-button-primary" style="min-width: 140px !important" mat-raised-button (click)="createAddendum()" *ngIf="!isCreatingAddendum && !hide_addendum_button && currentUser.user_type !== global.USER_TYPE.BILLER && !note.pcc_progressNoteStrikeOut && currentUser._id == note.provider._id && !is_uploadPcc_permission_enabled">Create Addendum</button>
                                                <button class="dn-button-primary" style="min-width: 140px !important" mat-raised-button (click)="saveAddendum()" *ngIf="isCreatingAddendum && !compareOriginalAddendumState()" [disabled]="isCreatingAddendum && (note.containsAsterisks(addendum.text) || this.addendumContainsUnselectedDropdowns)">Save Addendum</button>
                                                <button class="dn-button-primary" style="min-width: 140px !important" mat-raised-button (click)="uploadAddendum()" [disabled]="!buttonsState.uploadAddendum" *ngIf="isCreatingAddendum && compareOriginalAddendumState() && addendum?._id && hide_upload_addendum_button">Upload Addendum</button>
                                                <button class="dn-button-secondary" style="min-width: 140px !important" mat-raised-button (click)="discardAddendum()" *ngIf="isCreatingAddendum">Discard</button>
                                                <mat-icon *ngIf="!hideStrikeOutButton() && currentUser.user_type !== global.USER_TYPE.BILLER" matTooltip="Strike Out Note" style="cursor: pointer;" (click)="strikeOutNote()">block</mat-icon>
                                                <mat-icon *ngIf="note.pcc_progressNoteStrikeOut" matTooltip="Striken Out Note" style="cursor: normal;color:red;">block</mat-icon>
                                                
                                            </ng-container>

                                            <ng-container *ngIf="!note.hasPCCPatient()">
                                                <button class="dn-button-primary" style="min-width: 140px !important" mat-raised-button (click)="createAddendum()" *ngIf="!isCreatingAddendum && currentUser.user_type !== global.USER_TYPE.BILLER  && currentUser._id == note.provider._id">Create Addendum</button>
                                                <button class="dn-button-primary" style="min-width: 140px !important" mat-raised-button (click)="saveAddendum()" *ngIf="isCreatingAddendum && !compareOriginalAddendumState()" [disabled]="isCreatingAddendum && (this.addendumContainsAsterisks || this.addendumContainsUnselectedDropdowns)">Save Addendum</button>
                                                <button class="dn-button-primary" style="min-width: 140px !important" mat-raised-button (click)="uploadAddendum()" *ngIf="isCreatingAddendum && compareOriginalAddendumState() && addendum?._id">Upload Addendum</button>
                                                <button class="dn-button-secondary" style="min-width: 140px !important" mat-raised-button (click)="discardAddendum()" *ngIf="isCreatingAddendum">Discard</button>
                                            </ng-container>

                                    </ng-container>
                                    <span *ngIf="showCoSign()">
                                        <mat-checkbox class="tp-margin"
                                        
                                            [checked]="note?.coSign_status?.status == true"
                                            [disabled]="note?.coSign_status?.status"
                                            (change)="associatedProvidersDropDown = !associatedProvidersDropDown; onChangeCoSignCheckBox($event.checked)">{{isCoSigned()? readyToCosigned() : coSigner()}}
                                        </mat-checkbox>
                                    </span>
                                    <mat-icon *ngIf="charge?.status === 'submit' || charge?.status === 'draft'" matTooltip="Charge" style="cursor: pointer;" (click)="sidebarVisible=true">attach_money</mat-icon>
                                    
                                    <mat-form-field *ngIf = "associatedProvidersDropDown" [disabled]="note?.coSign_status?.status">
                                        <mat-label>Provider</mat-label>
                                        <mat-select  aria-required="true" 
                                                    #coSignSelect
                                                    [disabled]="note?.coSign_status?.status"
                                                    class="uppercase"
                                                    [(ngModel)]="coSignedProvider"
                                                    >
                                            <mat-option *ngFor="let doctor of associatedProviders" [value]="doctor">
                                                <ng-container *ngIf="doctor">
                                                    {{doctor.first_name}} {{doctor.last_name}}, {{doctor.title}}
                                                </ng-container>
                                            </mat-option>
            
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="note.isSigned()">
                                    <button mat-icon-button (click)="generatePDFNote()">
                                        <img src="../../../assets/icons/pdf.svg" alt="PDF Icon" style="width: 24px;" />
                                    </button>
                                </div>
                                <div *ngIf="currentUser.user_type!=global.USER_TYPE.SNF_WC_NURSE && currentUser.user_type!=global.USER_TYPE.BUSINESS_OFFICER">
                                    <span [matMenuTriggerFor]="menu" class="material-symbols-outlined icon-navy report-icon" matTooltip="Group Wound Assessment Report">
                                        lab_profile
                                    </span>
                                    <mat-menu #menu="matMenu" [class]="'menus-panel'">
                                        <button class="flex items-center gap-2" mat-menu-item (click)="generateWoundsReportPreview()">
                                            <span class="tag-style">Preview Report</span>
                                        </button>
                                        <button class="flex items-center gap-2" mat-menu-item (click)="generateWoundsReportPreview(true)">
                                            <span class="tag-style">Download Report</span>
                                        </button>
                                        <button *ngIf="note.patient.pcc_patientId && note.facility.pcc_facId" class="flex items-center gap-2" mat-menu-item (click)="uploadWoundReportToPCC()">
                                            <span class="tag-style">Upload Report to PCC</span>
                                        </button>
                                        <button *ngIf="note.facility.source === 'MatrixCare'" class="flex items-center gap-2" mat-menu-item (click)="uploadWoundReportToMatrix()">
                                            <span class="tag-style">Upload Report to MatrixCare</span>
                                        </button>
                                        <button *ngIf="note.patient.pcc_patientId && note.facility.pcc_facId" class="flex items-center gap-2" mat-menu-item (click)="uploadWoundImagesReportToPCC()">
                                            <span class="tag-style">Upload Wound Images Pdf to PCC</span>
                                        </button>
                                    </mat-menu> 
                                </div>
                                <ng-container *ngIf="showNextPrevButtons()">
                                    <ng-container *ngIf="dashboardData.audios.length === 0">
                                        <div class="flex gap-2">
                                            <button class="dn-button-primary" mat-raised-button [disabled]="!dashboardData.note.prev" (click)="navigate(dashboardData.note.prev)" matTooltip="Previous Note"><</button>
                                            <button class="dn-button-primary" mat-raised-button [disabled]="!dashboardData.note.next" (click)="navigate(dashboardData.note.next)" matTooltip="Next Note">></button>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="dashboardData.audios.length > 0">
                                        <div class="flex gap-2">
                                            <button class="dn-button-primary" mat-raised-button [disabled]="!dashboardData.audio.prev" (click)="navigate(dashboardData.audio.prev)" matTooltip="Previous Dictation"><</button>
                                            <button class="dn-button-primary" mat-raised-button [disabled]="!dashboardData.audio.next" (click)="navigate(dashboardData.audio.next)" matTooltip="Next Dictation">></button>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div class="flex items-start justify-baseline gap-2">
                                <div class="editor-fields flex flex-grow items-start justify-baseline gap-2">
                                    <ng-container *ngIf="isNew">

                                        <mat-form-field>
                                            <mat-label>Provider</mat-label>
                                            <mat-select [(ngModel)]="note.provider" aria-required="true"
                                                        class="uppercase"
                                                        (ngModelChange)="handleProviderChange()">
                                                <mat-option *ngFor="let doctor of doctors" [value]="doctor">
                                                    <ng-container *ngIf="doctor">
                                                        {{doctor.first_name}} {{doctor.last_name}}, {{doctor.title}}
                                                    </ng-container>
                                                </mat-option>
                
                                            </mat-select>
                                        </mat-form-field>
                
                                        <ng-container *ngIf="!note?.rounding_sheet_id">
                                            <mat-form-field>
                                                <mat-label>Facility</mat-label>
                                                <img matPrefix class="pcc-icon" src="../../../assets/icons/pcc-icon.svg" *ngIf="note.facility?.pcc_facId" />
                                                <img matPrefix src="../../../assets/icons/matrix.png" *ngIf="note.facility?.source == 'MatrixCare'" alt = ""
                                                style="height: 12px;
                                                width: 20px;  
                                                margin-right: 5px;
                                                vertical-align: middle;" />
                                                <mat-select [(ngModel)]="note.facility" name="facility" (ngModelChange)="handleFacilityChange()"
                                                    aria-required="true" class="uppercase">
                                                    <mat-option *ngIf="!note.provider">
                                                        <mat-error>Select Provider First</mat-error>
                                                    </mat-option>
                                                    <mat-option *ngFor="let facility of facilities" [value]="facility">
                                                        <app-facility-title [facility]="facility"></app-facility-title>
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </ng-container>
                                        
                                        <ng-container *ngIf="note?.rounding_sheet_id">
                                            <mat-form-field>
                                                <mat-label>Facility</mat-label>
                                                <img matPrefix class="pcc-icon" src="../../../assets/icons/pcc-icon.svg" *ngIf="note.facility?.pcc_facId" />
                                                <img matPrefix src="../../../assets/icons/matrix.png" *ngIf="note.facility?.source == 'MatrixCare'" alt = ""
                                                style="height: 12px;
                                                width: 20px;  
                                                margin-right: 5px;
                                                vertical-align: middle;"/>
                                                <input type="text" readonly matInput value="{{note.facility.title}}">
                                            </mat-form-field>
                                        </ng-container>
                                        <ng-container *ngIf="!note?.rounding_sheet_id">
                                            <mat-form-field>
                                                <mat-label>Patient</mat-label>                        
                                                <img matPrefix class="pcc-icon" src="../../../assets/icons/pcc-icon.svg" *ngIf="note.patient?.pcc_patientId && note.facility?.pcc_facId" />                                            
                                                <img matPrefix src="../../../assets/icons/matrix.png" *ngIf="note.patient?.source == 'MatrixCare'" alt = "" 
                                                style="height: 12px;
                                                width: 20px;  
                                                margin-right: 5px;
                                                vertical-align: middle;"/>                                            
                                                <input matInput type="text" [(ngModel)]="note.patient" name="patient" [formControl]="patientControl"
                                                    [matAutocomplete]="patientAutoComplete"
                                                    >
                                                <mat-autocomplete #patientAutoComplete="matAutocomplete"
                                                    (optionSelected)="handlePatientChange($event)" [displayWith]="displayPatientFn">
                                                    <mat-option *ngIf="!note.facility">
                                                        <mat-error>Select Facility First</mat-error>
                                                    </mat-option>
                                                    <mat-option value="" (click)="showPatientAddDialog()">
                                                        <strong>+ Add New Patient</strong>
                                                    </mat-option>
                                                    <mat-option *ngFor="let patient of filteredPatients | async" [value]="patient" (click)="patientAutoComplete.closePant()">
                                                        <app-patient-name [patient]="patient"></app-patient-name>
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                            <button class="advanced-patient-search-btn" mat-icon-button (click)="showPatientDialog()">
                                                <mat-icon  matTooltip="Advanced Patient Search" matTooltipPosition="top">search</mat-icon>
                                            </button>
                                        </ng-container>

                                        <ng-container *ngIf="note?.rounding_sheet_id || note?.patient">
                                            <mat-form-field>
                                                <mat-label>Patient</mat-label>
                                                <img matPrefix class="pcc-icon" src="../../../assets/icons/pcc-icon.svg" *ngIf="note.patient?.pcc_patientId && note.facility?.pcc_facId" />
                                                <img matPrefix src="../../../assets/icons/matrix.png" *ngIf="note.patient?.source == 'MatrixCare'" alt = "" 
                                                style="height: 12px;
                                                width: 20px;  
                                                margin-right: 5px;
                                                vertical-align: middle;"/>
                                                <input type="text" readonly matInput value="{{note.patient?.last_name}}, {{note.patient?.first_name}} {{note.patient?.middle_name ? note.patient?.middle_name : ''}}">
                                            </mat-form-field>
                                        </ng-container>

                                        <ng-container *ngIf="config.editable">
                                            <app-time-picker-widget *ngIf="censusId || !isNew" [format]="'24'" [readonly]="true" [value]="isValidDateObj() ? getDOS() : getNoteDOS()" style="width: 130px;" (onchangeTime)="onchangeTime($event)"></app-time-picker-widget>
                                            <app-time-picker-widget *ngIf="note.hasAudio()" [format]="'24'" [readonly]="true" [value]="getAudioDateObj()" style="width: 130px;" (onchangeTime)="onchangeTime($event)"></app-time-picker-widget>

                                        </ng-container>

                                        <ng-container *ngIf="!config.editable">

                                            <ng-container  *ngIf="!note.hasAudio()">
                                                <mat-form-field style="width: 130px;">
                                                    <mat-label>DOS</mat-label>
                                                    <input type="text" readonly matInput value="{{isValidDateObj() ? getDOS() : (getNoteDOS() | date: global.date_time_format)}}">
                                                </mat-form-field>
                                            </ng-container>
    
                                            <ng-container  *ngIf="note.hasAudio()">
                                                <mat-form-field style="width: 150px;"> {{BB}}
                                                    <mat-label>Date of Service</mat-label>
                                                    <input type="text" readonly matInput value="{{note?.date_obj ? getDOS() : (getNoteDOS() | date: global.date_time_format)}}">
                                                </mat-form-field>
                                            </ng-container>
                                        </ng-container>

    
                                        <mat-form-field  class="flex-grow">
                                            <input class="uppercase" id="note-title" matInput placeholder="Note Title" [(ngModel)]="note.title" (ngModelChange)="handleNoteTitleChange()" class="uppercase" onkeypress="return /[0-9a-z\s\.]/i.test(event.key)"  required />
                                        </mat-form-field>
                                
                                
                                    </ng-container>
                                    <ng-container *ngIf="!isNew">
                                        
                                        <mat-form-field>
                                            <mat-label *ngIf="currentUser.user_type!==global.USER_TYPE.SNF_WC_NURSE">Provider</mat-label>
                                            <mat-label *ngIf="currentUser.user_type===global.USER_TYPE.SNF_WC_NURSE">SNF WC Nurse</mat-label>
                                            <input type="text" readonly matInput value="{{note.provider.first_name}} {{note.provider.last_name}}, {{note.provider.title}}">
                                        </mat-form-field>
            
                                        <ng-container *ngIf="!config.editable">
                                            <mat-form-field>
                                                <mat-label>Facility</mat-label>
                                                <img matPrefix class="pcc-icon" src="../../../assets/icons/pcc-icon.svg" *ngIf="note.facility?.pcc_facId" />
                                                <img matPrefix src="../../../assets/icons/matrix.png" *ngIf="note.facility?.source == 'MatrixCare'" alt = ""
                                                style="height: 12px;
                                                width: 20px;  
                                                margin-right: 5px;
                                                vertical-align: middle;"/>
                                                <input type="text" readonly matInput value="{{note.facility.title}}">
                                            </mat-form-field>
                                        </ng-container>
                                        
                                        <ng-container *ngIf="config.editable">
                                            <ng-container *ngIf="!note?.rounding_sheet_id">
                                                <mat-form-field>
                                                    <mat-label>Facility</mat-label>
                                                    <img matPrefix class="pcc-icon" src="../../../assets/icons/pcc-icon.svg" *ngIf="note.facility?.pcc_facId" />
                                                    <img matPrefix src="../../../assets/icons/matrix.png" *ngIf="note.facility?.source == 'MatrixCare'" alt = "" 
                                                    style="height: 12px;
                                                    width: 20px;  
                                                    margin-right: 5px;
                                                    vertical-align: middle;"/>
                                                    <mat-select [(ngModel)]="note.facility" name="facility" (ngModelChange)="handleFacilityChange()"
                                                        aria-required="true" class="uppercase">
                                                        <mat-option *ngIf="!note.provider">
                                                            <mat-error>Select Provider First</mat-error>
                                                        </mat-option>
                                                        <mat-option *ngFor="let facility of facilities" [value]="facility">
                                                            <app-facility-title [facility]="facility"></app-facility-title>
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </ng-container>

                                            <ng-container *ngIf="note?.rounding_sheet_id">
                                                <mat-form-field>
                                                    <mat-label>Facility</mat-label>
                                                    <img matPrefix class="pcc-icon" src="../../../assets/icons/pcc-icon.svg" *ngIf="note.facility?.pcc_facId" />
                                                    <img matPrefix  src="../../../assets/icons/matrix.png" *ngIf="note.facility?.source == 'MatrixCare'" alt = "" 
                                                    style="height: 12px;
                                                    width: 20px;  
                                                    margin-right: 5px;
                                                    vertical-align: middle;"/>
                                                    <input type="text" readonly matInput value="{{note.facility.title}}">
                                                </mat-form-field>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="!config.editable">
                                            <mat-form-field>
                                                <mat-label>Patient</mat-label>
                                                <img matPrefix class="pcc-icon" src="../../../assets/icons/pcc-icon.svg" *ngIf="note.patient?.pcc_patientId && note.facility?.pcc_facId" />
                                                <img matPrefix src="../../../assets/icons/matrix.png" *ngIf="note.patient?.source == 'MatrixCare' " alt = "" 
                                                style="height: 12px;
                                                width: 20px;  
                                                margin-right: 5px;
                                                vertical-align: middle;"/>
                                                <input type="text" readonly matInput value="{{note.patient?.last_name}}, {{note.patient?.first_name}} {{note.patient?.middle_name ? note.patient?.middle_name : ''}}">
                                            </mat-form-field>
                                        </ng-container>
                                        
                                        <ng-container *ngIf="config.editable">
                                            <ng-container *ngIf="!note?.rounding_sheet_id && !note?.patient">
                                                <mat-form-field>
                                                    <mat-label>Patient</mat-label>
                                                    <img matPrefix class="pcc-icon" src="../../../assets/icons/pcc-icon.svg" *ngIf="note.patient?.pcc_patientId && note.facility?.pcc_facId" />                                            
                                                    <img matPrefix src="../../../assets/icons/matrix.png" *ngIf="note.patient?.source == 'MatrixCare' " alt = ""
                                                    style="height: 12px;
                                                    width: 20px;  
                                                    margin-right: 5px;
                                                    vertical-align: middle;"/>                                            
                                                    <input matInput type="text" [(ngModel)]="note.patient" name="patient" [formControl]="patientControl"
                                                        [matAutocomplete]="patientAutoComplete">
                                                    <mat-autocomplete #patientAutoComplete="matAutocomplete"
                                                        [displayWith]="displayPatientFn">
                                                        <mat-option *ngIf="!note.facility">
                                                            <mat-error>Select Facility First</mat-error>
                                                        </mat-option>
                                                        <mat-option value="" (click)="showPatientAddDialog()">
                                                            <strong>+ Add New Patient</strong>
                                                        </mat-option>
                                                        <mat-option *ngFor="let patient of filteredPatients | async" [value]="patient" (click)="patientAutoComplete.closePant()">
                                                            <app-patient-name [patient]="patient"></app-patient-name>
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>   
                                                <button class="advanced-patient-search-btn" mat-icon-button (click)="showPatientDialog()">
                                                    <mat-icon matTooltip="Advanced Patient Search" matTooltipPosition="top">search</mat-icon>
                                                </button> 
                                            </ng-container>
                                            <ng-container *ngIf="note?.rounding_sheet_id || note?.patient">
                                                <mat-form-field>
                                                    <mat-label>Patient</mat-label>
                                                    <img matPrefix class="pcc-icon" src="../../../assets/icons/pcc-icon.svg" *ngIf="note.patient?.pcc_patientId && note.facility?.pcc_facId" />
                                                    <img matPrefix src="../../../assets/icons/matrix.png" *ngIf="note.patient?.source == 'MatrixCare'" alt = ""
                                                    style="height: 12px;
                                                    width: 20px;  
                                                    margin-right: 5px;
                                                    vertical-align: middle;"/>
                                                    <input type="text" readonly matInput value="{{note.patient?.last_name}}, {{note.patient?.first_name}} {{note.patient?.middle_name ? note.patient?.middle_name : ''}}">
                                                </mat-form-field>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="config.editable">
                                            <app-time-picker-widget [format]="'24'" [readonly]="true" [value]="isValidDateObj() ? getDOS() : getNoteDOS()" style="width: 160px;" (onchangeTime)="onchangeTime($event)"></app-time-picker-widget>
                                        </ng-container>

                                        <ng-container *ngIf="!config.editable">
                                            <mat-form-field style="width: 150px;">{{AA}}
                                                <mat-label>DOS</mat-label>
                                                <input type="text" readonly matInput value="{{isValidDateObj() ? getDOS() : (getNoteDOS() | date: global.date_time_format)}}">
                                                </mat-form-field>
                                        </ng-container>

                                        <ng-container *ngIf="!config.editable">
                                            <mat-form-field style="width: 200px;">
                                                <mat-label>Note Title</mat-label>
                                                <input id="note-title" [style.textDecoration]="note.pcc_progressNoteStrikeOut ? 'line-through' : 'auto'" class="uppercase" matInput placeholder="Note Title" [value]="note.title" readonly class="uppercase" required />
                                            </mat-form-field>
                                        </ng-container>

                                        <ng-container *ngIf="config.editable">
                                            <mat-form-field   style="width: 200px;">
                                                <mat-label>Note Title</mat-label>
                                                <input id="note-title" class="uppercase" matInput [formControl]="noteTitleControl" placeholder="Note Title" [(ngModel)]="note.title" class="uppercase" onkeypress="return /[0-9a-z\s\.]/i.test(event.key)" required />
                                                <mat-error *ngIf="noteTitleControl.invalid">Note title is not present</mat-error>
                                            </mat-form-field>
                                        </ng-container>
                                
                                
                                    </ng-container>
                                </div>
                            </div>

                            <div class="flex flex-col">                                
                                <span *ngIf="saveError" class="flex items-end justify-start">
                                    <mat-error>*Unable to save note</mat-error>
                                </span>
                                <span *ngIf="note.hasAudio() && !isProvider() && !isSnfWcNurse()">
                                    <span *ngIf="!timeUp" style="font-size: 11px;">
                                        TAT:
                                        <countdown-timer style="font-size: 11px;" 
                                        [end]="getEndTimeForTimer(audio)" (zeroTrigger)="triggerFunction()">
                                    </countdown-timer> 
                                    </span>
                                    <span *ngIf="timeUp" style="color: red; font-size: 11px;">
                                        TAT: -
                                        <countdown-timer style="font-size: 11px;" 
                                    [start]="getStratTimeForTimer(audio)" (zeroTrigger)="triggerFunction()">
                                </countdown-timer> 
                                    </span>
                                </span>
                                
                                <span *ngIf="(note.hasAudio() && !isMatchAudioDOSWithNoteDOS) && 
                                (isNew || !note.isSigned())" 
                                class="warning-msg">
                                    WARNING: Audio Date of Service does not match the Note Date of Service.
                                </span>

                                <span *ngIf="(!note.hasAudio() && !existInNoteText.date_of_service) && 
                                (isNew || !note.isSigned())"
                                class="warning-msg"
                                >
                                    WARNING: Note Date of Service does not match the Date of Service specified with the note.
                                </span>
                                <span *ngIf="!isNew && note.hasPCCPatient() && note.isSigned() && !note.isUploaded() && (!hasLoggedInToPCC() && !canUploadNoteToPCCUsingTwoLegged()) && note.isRelatedProvider(currentUser._id) && isPccFacility()"
                                class="warning-msg"
                                >
                                    This note was NOT uploaded into PCC. Please sign in to PCC to upload the note.
                                </span>
                                <span *ngIf="!isNew && note.hasPCCPatient()  && !note.isUploaded() && (hasLoggedInToPCC() || canUploadNoteToPCCUsingTwoLegged()) && note.isRelatedProvider(currentUser._id) && (!hasLoggedInToPCCFacility() && !canUploadNoteToPCCUsingTwoLegged())"
                                class="warning-msg"
                                >
                                    WARNING: You are not logged into the correct PCC facility. Please log into the correct PCC facility to upload the note.
                                </span>
                                <span *ngIf="!isNew && (this.containsAsterisks || this.containsUnselectedDropdowns) && note.isPublished() && !note.isSigned()"
                                class="warning-msg"
                                >
                                    WARNING: The *** or dropdowns must be filled or removed to sign the note.
                                </span>
                                <span *ngIf="dictionary && dictionary.length > 0 && (!isNew && (this.containsUnresolvedPhrases) && note.isPublished() && !note.isSigned())"
                                class="warning-msg"
                                >
                                    WARNING: The note contains unresolved phrases.
                                </span>
                                <span *ngIf="isCreatingAddendum && (this.addendumContainsAsterisks || addendumContainsUnselectedDropdowns)"
                                class="warning-msg"
                                >
                                    WARNING: The *** or dropdowns must be filled or removed to save addendum.
                                </span>
                                <span *ngIf="hasNoteError()"
                                class="warning-msg"
                                >
                                    ERROR: {{hasNoteError()}}
                                </span>
                                <span *ngIf="woundsDocumentedError"
                                class="warning-msg">WARNING: Some Wounds have not been documented.</span>
                                <span  *ngIf="woundsDateErrorforSignNote" class="warning-msg">WARNING: Facility Acquired or Prior to Admission dates must be completed ({{getAllWoundNoWithoutDates()}}) prior to signing a note.</span>
                                <ng-container *ngIf="note && note.isSigned() && note.isUploaded() && (note.facility.source === 'PointClickCare' || note.facility.source === 'MatrixCare')">
                                    <span class="warning-msg" *ngIf="!note.wound_report_uploaded && (note.patient.source === 'PointClickCare' || note.patient.source === 'MatrixCare')">
                                        WARNING: Wound Report has not been uploaded to {{note.facility.source}}. 
                                        <a style="color:inherit; font-weight: bold;" href="javascript:void(0)" (click)="uploadWoundReport()">Click here</a> to try again.
                                    </span>
                                </ng-container>
                                <div class="flex items-start justify-between gap-2" *ngIf="!note.isSigned() && !note.isUploaded()">
                                    <div class="flex items-start justify-center gap-2">
                                        <button class="dn-button-primary" mat-raised-button (click)="jumpToAsterisk(-1)">
                                            Previous ***
                                        </button>
                                        <button class="dn-button-primary" mat-raised-button (click)="jumpToAsterisk(1)">
                                            Next ***
                                        </button>
                                    </div>
                                    <div class="flex flex-wrap justify-end">
                                        <div class="audit-text">
                                            <div>{{drafteNoteState}}</div>
                                        </div>
                                        <div class="audit-text" *ngIf="getRecentAuditText()" [innerHTML]="getRecentAuditText()" style="white-space:nowrap;"></div>
                                    </div>
                                    <div class="flex"></div> 
                                    <button
                                        *ngIf="isNew || (!note.isSigned() && !note.isUploaded()) && !(currentUser.user_type === global.USER_TYPE.BILLER)"
                                        class="dn-button-primary" mat-raised-button (click)="showTemplateDialog()">
                                        Select Template
                                    </button>
                                    <div class="flex justify-between items-center" *ngIf="shouldShowNoteTimeLogs">
                                        <button mat-raised-button class="dn-button-primary" (click)="toggleTimer()">{{ timerRunning ? 'Stop Timer' : 'Start Timer' }}</button>
                                        <div *ngIf="shouldShowTimer">{{ displayTime }}</div>
                                    </div>
                                </div>
                                <div cdkDrag (cdkDragDropped)="saveBarPosition($event)" id="dragonBar" style="position: absolute;bottom: 4px;right: 4px;" class="dragon-div flex justify-between items-center">
                                    <div>
                                        
                                    </div>
                                    <div id="speechBarContainer"></div>
                                </div>
                            </div>
                        </div>

                        <div id="notEditor" [ngClass]="(note.is_signed == 'true')?'  transcription-editor-submit':'transcription-editor'" aria-readonly="true">
                            <ng-container *ngIf="ckEditorReady">
                                <ckeditor 
                                    *ngIf="!isCreatingAddendum"
                                    #mainEditor                               
                                    id="CkEditor"
                                    [(ngModel)]="note.htmlText" 
                                    name="htmlText" 
                                    class="h100" 
                                    editorUrl="../../../assets/ckeditor/ckeditor.js"
                                    [readOnly]="!config.editable"
                                    [config]="cke4Config" 
                                    type="divarea" 
                                    (change)="handleEditorChange($event)" 
                                    (ready)="onReadyCkeditor('main', $event)">
                                </ckeditor>
                                
                                <ckeditor 
                                    *ngIf="isCreatingAddendum"
                                    #addendumEditor 
                                    [(ngModel)]="addendum.text" 
                                    name="htmlText" 
                                    class="h100" 
                                    editorUrl="../../../assets/ckeditor/ckeditor.js"
                                    [readOnly]="!config.editable"
                                    [config]="cke4Config" 
                                    type="divarea" 
                                    (change)="handleEditorChange($event)" 
                                    (ready)="onReadyCkeditor('addendum', $event)">
                                </ckeditor>
                            </ng-container>
                        </div>

                    </div>
        
                    <div *ngIf="currentUser.user_type !== global.USER_TYPE.BILLER" class="sidebar-wrapper flex">
                        <button class="sidebar-toggle-btn" (click)="sidebarVisible=!sidebarVisible">
                            <mat-icon *ngIf="sidebarVisible">keyboard_arrow_right</mat-icon>
                            <mat-icon *ngIf="!sidebarVisible">keyboard_arrow_left</mat-icon>
                        </button>
                        <app-note-sidebar class="flex" *ngIf="sidebarVisible && note"
                            [audioId]="audioId" 
                            [note]="note" 
                            [addendum_counts]="addendum_counts" 
                            [noteId]="note._id"
                            [censusId]="censusId"
                            [patientId]="patientId"
                            [noteEditorIcds]="noteEditorIcds"
                            [noteEditorCpts]="noteEditorCpts"
                            [emCodeEditorCpts]="emCodeEditorCpts"
                            [telemedPhrase]="telemedPhrase"
                            [signedNotesStatus]="mustSignNote"
                            [configuration]="configuration"
                            [companyPermissions]="companyPermissions"
                            [carryChargeStatusIcds]="carryChargeStatusIcds"
                            [carryChargeStatusCpts]="carryChargeStatusCpts"
                            [shouldShowNoteTimeLogs]="shouldShowNoteTimeLogs"
                            (onAddendumClick)="handleAddendumClick($event)" 
                            (onTemplateSelect)="handleTemplateSelect($event)"
                            (closeSidebar)="closeSidebar($event)"
                            (onChargeChange)="handleChargeChange($event)"
                            (onChargeAndICDChange)="handleChargeICDChange($event)"
                            (onICDsSave)="handleICDsSave($event)"
                            [companySubmitMipsStatus]="companySubmitMipsStatus"
                            (onHtmlTextChanges)="handleHtmlTextChange($event)"
                            (onNoteEdiorCptsChange)="onNoteEdiorCptsChange($event)"
                            (onNoteEdiorIcdsChange)="onNoteEdiorIcdsChange($event)"
                            (woundDetailsChange)="handleChangeWoundDetails($event)"
                            (procedureNoteDetailsChange)="handleProcedureNoteDetailsChange($event)"
                            (checkallWoundAssessmentPhrase)="handlecheckallWoundAssessmentPhrase($event)"
                            (insertPhraseText)="handleinsertPhraseText($event)"
                            >
                        </app-note-sidebar>
                    </div>
                </ng-container>
                <ngx-ui-loader [loaderId]="loaderId" bgsPosition= "center-center"></ngx-ui-loader>
            </div>
        </div>
    </main>
</div>