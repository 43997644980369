import { Component, ViewChild, ChangeDetectorRef, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '../dashboard/dashboard.service';
import * as global from '../global';
import { TranscriptionCreateService } from '../transcription-create/transcription-create.service';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatSort, Sort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { PatientRoundingSheetService } from '../patient-rounding-sheets/patient-rounding-sheet.service';
import { MatDialog } from '@angular/material/dialog';
import { PatientRoundingSheetCommentsDialog } from '../../shared/popup-dialogs/patient-roundingsheet-comments-dialog/patient-roundingsheet-comments.component';
import { AudioListDialog } from '../../shared/popup-dialogs/audio-list-dialog/audio-list-dialog.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import moment from 'moment'
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { Patient } from 'src/app/classes/patient';
import { CensusPatientListV2Service } from './census-patient-list-v2.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators'
import { PCCService } from 'src/app/services/pcc.service';
import { CensusFileUploadDialogeComponent } from 'src/app/shared/popup-dialogs/census-file-upload/census-file-upload-dialoge/census-file-upload-dialoge.component';
import { eventTypes as pccEventTypes } from 'src/app/constants/pcc_webhook_constants';
import { GoBackUrlService } from 'src/app/services/goBackUrl.service';
import { API_URL } from '../../../environments/api-url'
import { HttpResponse } from '@angular/common/http';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PatientImporterDialog } from '../patient-rounding-sheets/patient-importer-dialog/patient-importer-dialog';
import { RehabDocsPopupComponent } from 'src/app/shared/popup-dialogs/rehab-docs-popup/rehab-docs-popup/rehab-docs-popup.component';
import { ViewNoteDialog } from '../transcription-create/view-note-dialog/view-note-dialog.component';
import { DialogConfirmationComponent } from '../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { CensusListService } from '../census-list/census-list..service';
import { PatientRoundingSheetCommentsService } from 'src/app/services/patient-roundingsheet-comments.service';
import { CustomPdfDialogComponent } from '../census-patient-list/custom-pdf-dialog/custom-pdf-dialog.component';
import { PatientDiagnosisDialogComponent } from '../patient-diagnosis-dialog/patient-diagnosis-dialog.component';
import { PatientAddFormDialogComponent } from '../patient-add-form/patient-add-form-dialog/patient-add-form-dialog.component';
import { PatientEditDialogComponent } from '../census-patient-list/patient-edit-dialog/patient-edit-dialog.component';
@Component({
  selector: 'app-census-patient-list-v2',
  templateUrl: './census-patient-list-v2.component.html',
  styleUrls: ['./census-patient-list-v2.component.css']
})


export class CensusPatientListV2Component implements OnInit, OnDestroy {
  loading: boolean = false;
  global = global;
  pccEventTypes = pccEventTypes;
  census: any;
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any>;
  currentUser: any;
  filterDropdownActive = false;
  dateOfBirth: any;
  location_sort_order: string = 'desc'

  params: {
    id: string
  }
  recentComments: any = [];

  loaderId = 'episodes-of-care-loader';
  patientsSearchLoaderId = 'pcc-patients-loader';

  filterSeen: 'seen' | 'not_seen' | 'all' = 'all';
  filterSort: 'seen' | 'not_seen' | 'room_no' | 'new_patient' |
    'status' | 'name' | 'dob' | 'room_no' | 'admission_date' | 'admission_date' | 'discharge_date' | 'payor' |
    'user_sort';

  filterSearch = "";

  patientsByProvider: Patient[] = [];

  patientsByProviderControl = new FormControl();
  filteredPatientsByProvider: Observable<any[]>;


  pccPatients: Patient[] = [];

  pccPatientsControl = new FormControl();
  filteredPccPatients: Observable<any[]>;
  censusFileUploadDialogeComponent: any;

  patientStatuses = ['All', 'Current', 'New', 'Discharged'];
  searchPatientStatus = 'Current';
  selectedPatients: any[] = [];
  telemedicine: string = 'false';
  reloadingCensus = false;
  selectedPatientsToImport = [];
  defaultPotraitFontSize: string;
  defaultLandscapeFontSize: string;
  currentPatient: any;
  patientsImportSource: 'dn' | 'pcc';
  displayCommentSidenav: boolean = false;
  commentCount: any;
  recentNotes: any = [];
  show_card_view: Boolean = true;
  show_adt_view: Boolean = false;
  censusPatientSortSubscription: any;
  admin_flag: Boolean = false;
  detect_refresh_child: Boolean = false;
  error2LeggedAuth: String = 'Please login to PCC to view data.';
  error2LeggedAuthFlag: Boolean = false;
  census_id = "";
  isActivePatientList: boolean = true;

  constructor(
    private changeDetectorRefs: ChangeDetectorRef,
    private _patientRoungingSheetService: PatientRoundingSheetService,
    private _router: Router,
    private censusListService: CensusListService,
    private _toastr: ToastrService,
    private transcriptionCreateService: TranscriptionCreateService,
    public commonService: CommonService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private loader: NgxUiLoaderService,
    private patientRoundingSheetService: PatientRoundingSheetService,
    private _location: Location,
    private censusPatientListService: CensusPatientListV2Service,
    private pccService: PCCService,
    private goBackUrlService: GoBackUrlService,
    private patientRoundingSheetCommentsService: PatientRoundingSheetCommentsService,
    private dashboardService: DashboardService,
  ) {
    this.currentUser = this.authService.currentUser;
    this.authService.onUserAuthChange.subscribe(currentUser => this.currentUser = currentUser);
    this.params = {
      id: this.route.snapshot.params.id
    };
    this.telemedicine = this.route.snapshot.paramMap.get('telemedicine');

    this.route.params.subscribe(
      async (params: Params) => {
        this.params = {
          id: params['id'],
        };
        this.admin_flag = params['admin']
        if (params['error2LeggedAuthFlag'] == 'true') {
          this.error2LeggedAuthFlag = true
        }
        else {
          this.error2LeggedAuthFlag = false
        }
        if (localStorage.getItem('hide_census') == 'true') {
          this.show_card_view = false
        }
        if (this.params.id != 'null')
          this.ngOnInit();

      })

  }
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;



  ngOnDestroy(): void {
    if (this.censusPatientSortSubscription) {
      this.censusPatientSortSubscription.unsubscribe();
    }
  }

  async ngOnInit() {
    this.selection.clear()
    this.selectedPatientsToImport = []
    // JSON.parse removed causing parse errors for patients i.e JSON.parse(this.currentUser)
    this.currentUser = this.currentUser;
    if (!this.currentUser) {
      // if not login, stop and redirect to login #mohsin-dev
      localStorage.removeItem('currentUser');
      this._router.navigate(['/login']);
      return;
    }
    await this.getRecentNoteByCensusId();
    // this.defaultPotraitFontSize = this.currentUser.other_settings?.census_potrait_font_size;
    // this.defaultLandscapeFontSize = this.currentUser.other_settings?.census_landscape_font_size;
    // show operations column only for doctor
    // if(this.currentUser.user_type == 1) {
    this.displayedColumns = ['switch-status', 'name', 'deleteNote', 'note', 'operations', 'comments_count', 'pcc_patient_diagnosis', 'discharge',
      // 'charge',
      //  'charge-status',
      'date_of_birth', 'location', 'admission_date',
      // 'discharge_date', 
      'payer', 'pcp', 'last_seen', 'edit_patient'];
    // remove // 'status',  'source',
    // } else {
    // this.displayedColumns = ['name', 'date_of_birth', 'gender'];
    // }
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <any>null,
      primary_doctor_id: <string>null
    };
    item.id = this.currentUser._id;
    item.auth_key = this.currentUser.auth_key;
    item.usertype = this.currentUser.user_type;
    if (this.params.id)
      await this.initCensus();

    // if(this.isOwner(this.census)){
    //   this.displayedColumns = ['switch-status', 'name', 'note', 'discharge',
    //   // 'charge',
    //   // 'charge-status',
    //    'date_of_birth', 'location', 'admission_date', 'discharge_date', 'payer', 'comments_count', 'last_seen', 'operations'];
    // }
    // else{
    //   this.displayedColumns = ['switch-status', 'name', 'note', 'discharge',
    //   // 'charge',
    //   // 'charge-status',
    //   'date_of_birth', 'location', 'admission_date', 'discharge_date', 'payer', 'comments_count', 'last_seen', 'operations', 'checkbox'];
    // }

    this.displayedColumns = ['checkbox', 'switch-status', 'name', 'deleteNote', 'note', 'operations', 'comments_count', 'pcc_patient_diagnosis', 'discharge',
      'date_of_birth', 'location', 'admission_date',
      //  'discharge_date',
      'payer', 'pcp', 'last_seen', 'edit_patient'];

    this.censusPatientListService.castFilterSort.subscribe(value => {
      this.filterSort = value;
      this.doSortPatients();
    })
    this.censusPatientListService.castFilterSearch.subscribe(value => {
      this.filterSearch = value;
      this.applyFilter(value);
    })

    this.initPatientsByProvider();
    this.initPccPatients();
    // this.getPatientCommentCount(this.params.id);
    this.getCensusRecentComment(this.params.id);
    this.handleActivationFilter("active");
  }

  // getPatientCommentCount(census_id) {
  //   // console.log(census_id)
  //   this.patientRoundingSheetCommentsService.getPatientCommentCount(census_id).subscribe((response: any)=>{
  //     if(response.status === 200) {
  //       // console.log("count",response);
  //       this.commentCount = response.data.array;
  //     }
  //   });
  // }

  showPatientDiagnosisDialog(patient_id) {
    let patientDiagnosisDialog = this.dialog.open(PatientDiagnosisDialogComponent, {
      width: '40%',
      height: '70vh',
      data: patient_id
    });
  }
  getCensusRecentComment(census_id) {
    // console.log(census_id)
    this.patientRoundingSheetCommentsService.getCensusRecentComment(census_id).subscribe((response: any) => {
      if (response.status === 200) {
        this.recentComments = response.data;
        if (this.census?.patientList) {
          this.census.patientList.map(data => {
            delete data?.census_comment; //To remove census comment fetched from census pipeline
            this.recentComments.map(ele => {

              if (data._id == ele.patient_id) {
                data["census_comment"] = ele;
              }
            });
          });
        }
      }
    });
  }
  uploadFileDialoge() {
    this.censusFileUploadDialogeComponent = this.dialog.open(CensusFileUploadDialogeComponent, {
      width: '40%',
      data: {
        provider: this.currentUser._id,
        facility: this.census.facility,
        census_id: this.params.id
      }
    })
    this.censusFileUploadDialogeComponent.afterClosed().subscribe(data => {
      if (data) {
        // console.log(data.doc_id)
        this.censusPatientListService.subscribeRehabReportsResult(data.doc_id)
        this.censusListService.getRehabDocsCount(this.params.id).subscribe((response: any) => {
          if (response.status == 200) {
            this.census.rehab_docs_count = response.data;
          }
        })
      }
    });
  }
  patientSlected(patient, event) {
    if (event) {
      this.selection.toggle(patient)
      this.selectedPatientsToImport.push(patient)
    }
    else {
      let index = this.selectedPatientsToImport.map(function (e) {
        return e._id;
      }).indexOf(patient._id);
      // let index = this.selectedPatientsToImport.indexOf(patient);
      if (index > -1) {
        this.selectedPatientsToImport.splice(index, 1)
        this.selection.clear()
        this.selection.select(...this.selectedPatientsToImport)
      }
    }
  }
  async importPatientDialoge() {
    let keyName = 'patients';
    if (Array.isArray(this.selectedPatientsToImport.length > 1)) {
      keyName = 'patients';
    }
    let only_pcc = !!this.selectedPatientsToImport.find(p => !!p.pcc_patientId);
    const obj = {
      filter_facility_id: this.census.facility._id,
      filter_provider_id: this.census.createdBy,
      oldCensusId: this.params.id
    }
    const dialogRef = await this.dialog.open(PatientImporterDialog, {
      width: '50%',
      data: { [keyName]: this.selectedPatientsToImport, action: 'import', only_pcc: only_pcc, obj: obj },
    });
    dialogRef.afterClosed().subscribe(async (data) => {

      this.selectedPatientsToImport = [];
      this.selection.clear()
      if (data) {
        this.detect_refresh_child = this.detect_refresh_child ? false : true
      }
      else {
        this.initPatientsByProvider();
      }
    });

    // this.initPatientsByProvider();
  }
  showAddChargeIcon(patient) {
    if (patient.charges && patient.charges.length > 0) {
      return true
    }
    else {
      return false
    }
  }
  async openCharges(patient) {
    // if(!this.currentUser.charge_status) {
    //   this._toastr.error("Charge already created")
    //   return;
    // }  
    if (this.census && this.isOwner(this.census) && (!this.hasChargeAndStatus(patient, 'submit') && !this.hasChargeAndStatus(patient, 'draft'))) {
      // if (this.census  && (!this.hasChargeAndStatus(patient, 'submit') && !this.hasChargeAndStatus(patient, 'draft'))) {
      // return true;
      this.commonService.setDraftedCharge(null)
      let patient_id = patient._id;
      let rs_id = this.params.id;
      localStorage.setItem('hide_census', "true");
      this._router.navigate(['/patient-charge'], {
        queryParams: {
          patient_id: patient_id,
          rs_id: rs_id,
          visit_date: this.census.date.toString(),
          timeZone: this.census.facility.pcc_timeZone || this.census.facility.timeZone,
          patient_name: patient.first_name + ' ' + patient.last_name,
          dob: patient.date_of_birth,
          // facility_id: patient.facility_id
          facility_id: this.census.facility._id,
          url: "census"
        }
      });
    }
    else if (this.census && this.isOwner(this.census) && (this.hasChargeAndStatus(patient, 'draft') || this.hasChargeAndStatus(patient, 'submit'))) {
      // else if (this.census && (this.hasChargeAndStatus(patient, 'draft') || this.hasChargeAndStatus(patient, 'submit'))) {
      const chargeResponse: any = await this.censusListService
        .getCharge({
          patient_id: patient._id,
          rounding_sheet_id: this.census._id,
        })
        .toPromise();
      this.commonService.setDraftedCharge(chargeResponse.data);
      let patient_id = patient._id;
      let rs_id = this.params.id;
      localStorage.setItem('hide_census', "true");
      this._router.navigate(['/patient-charge'], {
        queryParams: {
          patient_id: patient_id,
          rs_id: rs_id,
          visit_date: this.census.date.toString(),
          timeZone: this.census.facility.pcc_timeZone || this.census.facility.timeZone,
          patient_name: patient.first_name + ' ' + patient.last_name,
          dob: patient.date_of_birth,
          // facility_id: patient.facility_id
          facility_id: this.census.facility._id,
          url: "census"
        }
      });
    }
    if (!this.isOwner(this.census)) {
      this._toastr.error("Not accessible")
      return;
    }
    else {
      // this._toastr.error("Charge already created")
      return;
    }

    // return false;


  }
  facilityAuthorization(facility) {
    if (!facility.pcc_2_legged_authentication) {
      if (
        // User is Not logged in so cannot access via 2-legged 
        !this.isLoggedIntoPCC() ||
        // User is logged but facility is not logged in so cannot access via 2-legged 
        (this.isLoggedIntoPCC() && !this.pccService.isLoggedInFacility(facility))
      ) {
        this.error2LeggedAuth = 'Please login to PCC to view data.';
        this.error2LeggedAuthFlag = true
        return;
      }
    }
    else {
      this.error2LeggedAuthFlag = false
      this.error2LeggedAuth = '';
    }
  }
  noteEditor(element, telemedicine, index) {
    let rs_id = this.params.id;
    let routerMapParams: any = {
      patient_id: element._id,
      facility_id: this.census.facility._id,
      dos: this.census.date,
      census_id: this.census._id,
      telemedicine: telemedicine,
      provider_id: this.census.createdBy,
      nextPatientNote: true
    }

    let routerParams = {
      patient_id: element._id,
      facility_id: this.census.facility._id,
      dos: this.census.date,
      rs_id: this.census._id,
      visit_date: this.census.date.toString(),
      patient_name: element.first_name + ' ' + element.last_name,
      dob: element.date_of_birth,
    }
    this.censusPatientListService.setChargeData(routerMapParams)
    if (element.charges) {
      this.censusPatientListService.setCharge(element.charges)
    }
    // let obj: any = JSON.parse(JSON.stringify(routerMapParams));
    // obj.patientList = this.census.patientList;
    // obj.patientIndex = index;
    // this.censusPatientListService.setPatientListData(obj);
    localStorage.setItem('hide_census', "true");
    this._router.navigate(['/note-editor/new', routerMapParams])
  }
  RedirectToChargeCaptureInNote(element) {
    console.log("e", element);
    let routerMapParams: any = {
      patient_id: element._id,
      facility_id: this.census.facility._id,
      dos: this.census.date,
      census_id: this.census._id,
      provider_id: this.census.createdBy,
      isChargeTabActive: true
    }
    this._router.navigate(['/note-editor/new', routerMapParams])
  }
  async getRecentNoteByCensusId() {
    const response: any = await this.censusPatientListService.getRecentNoteByCensusId(this.params.id).toPromise();
    if (response.status === 200) {
      this.recentNotes = response.data;
    }
  }
  async initCensus() {
    this.loader.startLoader(this.loaderId);
    const censusResponse: any = await this.censusPatientListService.getCensusDetail(this.params.id).toPromise();

    if (censusResponse.status === 200) {
      this.loader.stopLoader(this.loaderId);
      console.log('init cens', censusResponse.data);
      if (censusResponse?.data?.patientList) {
        this.census_id = censusResponse.data['_id'];
        this.census = censusResponse.data;
      }
      if (!this.census?.patientList) return;
      // if(this.census.facility.pcc_facId) {
      //   this.facilityAuthorization(this.census.facility)
      // }
      this.patientsImportSource = this.census.facility.pcc_facId ? 'pcc' : 'dn';
      this.initTableDataSource();
      if (this.isActivePatientList) {
        this.filterTableForActiveOrInactivePatients(true);
      }
      else {
        this.filterTableForActiveOrInactivePatients(false);
      }
      
    }
  }

  initTableDataSource() {
    if (this.census) {
      if (this.dataSource) {
        this.dataSource.data = this.census.patientList;
      } else {
        this.dataSource = new MatTableDataSource(this.census.patientList);
        this.dataSource.sort = this.sort;
      }
      
      // this.censusPatientSortSubscription = this.censusPatientListService.castCensusPatientSort.subscribe(sort => {
      //   // console.log("sort", sort);
      //   if (sort) {
      //     this.sortData(sort);
      //   }
      // });
    }
  }

  async initPatientsByProvider() {
    // const patientsByProviderResponse:any = await this.censusPatientListService.getFacilityPatientsByProviderId(this.currentUser._id, this.census.facility._id).toPromise();
    // if(patientsByProviderResponse.status !== 200) return;
    // this.patientsByProvider = patientsByProviderResponse.data.array;

    // this.filteredPatientsByProvider = this.patientsByProviderControl.valueChanges
    // .pipe(
    //     startWith(''),
    //     map((value:any) => typeof value === 'string' ? value : value.name),
    //     map(name => name ? this._filterPatientsByProvider(name) : this.getFilteredPatients(this.patientsByProvider))
    // );

    this.patientsByProviderControl.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(async (name) => this.doInitPatients(name));
  }
  doInitPatients(name) {
    if (!name) return;
    this.loader.startLoader(this.patientsSearchLoaderId);

    this.filteredPatientsByProvider = this.censusPatientListService
      .getPatients(
        this.census.facility._id,
        name)
      .pipe(
        tap((patientsResponse: any) => {
          this.patientsByProvider = patientsResponse.data.array.map(p => new Patient(p));
          this.loader.stopLoader(this.patientsSearchLoaderId);
        }),
        map((patientsResponse: any) => {
          return patientsResponse.data.array
            ?.filter(p => !!p)
            .map((p) => ({
              ...p,
              name: this.commonService.getPatientFullNameFormat2(p)
            }));
        })
      );
  }
  async initPccPatients() {
    this.pccPatientsControl.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(async (name) => this.doInitPccPatients(name));
  }
  async doInitPccPatients(name) {
    console.log("name", name)
    if (!name) return;
    // if(!this.pccService.hasPCCOrgUUID()) {
    //   return this._toastr.warning('You must logged into PCC atleast once')
    // }

    if (this.searchPatientStatus === 'All') {
      this.searchPatientStatus = ''
    }

    this.loader.startLoader(this.patientsSearchLoaderId);


    const args: any = {
      pccOrgUuid: this.census.facility.pcc_orgUuid,
      pccFacID: this.census.facility.pcc_facId,
      pccPatientStatus: this.searchPatientStatus,
      pccPatientName: name,
      page: 1,
      pageSize: 200
    }

    if (this.isLoggedIntoPCC()) {
      if (this.pccService.isLoggedInFacility(this.census.facility)) {
        args.authMode = 'USER'
      } else {
        args.authMode = 'APP'
      }
    }

    this.filteredPccPatients = this.pccService
      .getPatientsByFacilityID(
        args
      )
      .pipe(
        tap((pccPatientsResponse: any) => {
          this.pccPatients = pccPatientsResponse.data.data;
          this.loader.stopLoader(this.patientsSearchLoaderId);
        }),
        map((pccPatientsResponse: any) => {
          return pccPatientsResponse.data.data
            ?.filter(p => !!p)
            .map((p) => ({
              ...p,
              name: `${p.lastName}, ${p.firstName}`,
              pcc_patientId: String(p.patientId),
            }));
        })
      );

  }
  private _filterPatientsByProvider(name): any[] {
    const filterValue = name.toLowerCase();

    return this.getFilteredPatients(this.patientsByProvider).filter(patient => patient.name.toLowerCase().indexOf(filterValue) === 0);
  }
  private _filterPccPatients(name): any[] {
    const filterValue = name?.toLowerCase();
    return this.getFilteredPatients(this.pccPatients)?.filter(patient => patient.name.toLowerCase().indexOf(filterValue) === 0);
  }
  getFilteredPatients(patients) {
    if (!Array.isArray(patients)) return [];

    let resultPatients = [];
    // resultPatients = _.cloneDeep(this.patientsByProvider);
    for (const patient of patients) {
      let alreadyExistsInRoundingSheet;
      if (patient.patientId) {
        alreadyExistsInRoundingSheet = this.census.patientList.find(p => p.pcc_patientId === String(patient.patientId));
      } else {
        alreadyExistsInRoundingSheet = this.census.patientList.find(p => p._id === patient._id);
      }
      if (!alreadyExistsInRoundingSheet) {
        resultPatients.push(patient)
      }
    }
    return resultPatients;
  }
  getFilteredPccPatients(patients) {
    if (!Array.isArray(patients)) return [];

    // patients = patients.map(p => ({
    //   ...p,
    //   name: `${p.lastName}, ${p.firstName}`,
    //   pcc_patientId: p.patientId
    // }) )
    let resultPatients = [];
    // resultPatients = _.cloneDeep(this.patientsByProvider);
    for (const patient of patients) {
      const alreadyExistsInRoundingSheet = this.census.patientList.find(p => String(p.pcc_patientId) === String(patient.patientId));
      if (!alreadyExistsInRoundingSheet) {
        resultPatients.push(patient)
      }
    }
    return resultPatients;
  }
  displayPatientFn(patient): string {
    return patient && patient.name ? patient.name : '';
  }
  async isPatientAlreadyExistsInCensus(patient) {

    const patientExistsInCurrentCensus = this.census.patientList.find(p => {
      if (patient._id) {
        if (p._id === patient._id) {
          return 'current';
        }
      } else {
        if (String(p.pcc_patientId) === String(patient.patientId)) {
          return 'current';
        }
      }
    });

    if (patientExistsInCurrentCensus) return 'current';

    const details = {
      ...this.authService.authObject,
      patient_id: patient._id,
      pcc_patientId: patient.pcc_patientId
    }
    const response: any = await this._patientRoungingSheetService.checkIfPatientExistInAssociatedProviderCensus(details).toPromise();
    if (response.status === 200) {
      if (response.data.length > 0) return response.data;
    }
    return false;
  }
  addPatientToCurrrentRoundingSheet(patient, notification = true) {
    // const patient = event.option.value;

    let infoToast;
    const details = {
      ...this.authService.authObject,
      patientRoundingSheetId: this.census._id,
      patientId: patient._id
    }
    if (notification) {
      infoToast = this._toastr.info('Adding Patient to Census', "Please wait", { disableTimeOut: true });
    }
    this._patientRoungingSheetService.addPatientToPatientRoundingSheet(details).subscribe((response: any) => {
      if (response.status === 200) {
        // console.log(response);
        const { chargesOwner, ...patient } = response.data;
        const isPatientExistInCurrentCensus = this.census.patientList.find(p => {
          if (p.pcc_patientId) {
            return p.pcc_patientId === patient.pcc_patientId;
          } else if (p._id) {
            return p._id === patient._id;
          }
        });
        if (!isPatientExistInCurrentCensus) {
          this.census.patientList.push(new Patient(patient));
          if (this.isActivePatientList) {
            this.filterTableForActiveOrInactivePatients(true);
          }
          else {
            this.filterTableForActiveOrInactivePatients(false);
          }
          this.census.chargesOwnerList.push(chargesOwner);

          this.doSortPatients();

        }
        // this.table.renderRows();
        // this.initCensus();
        // this.patientsByProviderControl.setValue('');

        // Do not show Success toast message on patient import in Census
        // if(notification) {
        //   this._toastr.success('Patient added to Census successfully');
        // }
        if (infoToast) {
          this._toastr.clear(infoToast.toastId)
        }
      } else {

        if(response.data){
          let patient=response.data;

          if(this.census.patientsInactive.includes(patient._id)){
            this.census.patientsInactive=this.census.patientsInactive.filter(function (element){
              return element!=patient._id;
            })
          }
          if (this.isActivePatientList) {
            this.filterTableForActiveOrInactivePatients(true);
          }
          else {
            this.filterTableForActiveOrInactivePatients(false);
          }
          this.doSortPatients();
          console.log("reloaddd 33 ", this.census);
          this._toastr.success("Patient Added Successfully");


        }else{
    
            if (infoToast) {
              this._toastr.clear(infoToast.toastId)
            }
            this._toastr.warning(response.message);

        }

      }
    }, () => { }, () => () => {
      if (infoToast) {
        this._toastr.clear(infoToast.toastId)
      }
    })

  }

  importAndAddPatientToCurrrentRoundingSheet(pccPatient, sync) {
    // if(!this.pccService.hasPCCOrgUUID()) {
    //   return this._toastr.warning('You must logged into PCC atleast once')
    // }
    this.pccPatientsControl.reset();
    this.pccPatientsControl.markAsDirty();
    this.pccPatientsControl.markAsTouched();
    this.pccPatientsControl.updateValueAndValidity();

    // const pccPatient = event.option.value;
    const pccPatientId = pccPatient.patientId;
    const primaryProviderId = this.currentUser._id;
    const facilityId = this.census.facility._id;
    const pccOrgUuid = this.census.facility.pcc_orgUuid;
    this.loader.startLoader(this.loaderId);
    const infoToast = this._toastr.info('Importing Patient', 'Please wait', {
      disableTimeOut: true,
    });
    this.pccService
      .importPCCPatient(
        pccOrgUuid,
        pccPatientId,
        primaryProviderId,
        facilityId,
        sync
      )
      .subscribe(
        (response: any) => {
          // console.log(response);

          if (response.status === 200) {
            // const event = {
            //   option: {
            //     value: response.data
            //   }
            // }
            this.loader.stopLoader(this.loaderId);

            this.addPatientToCurrrentRoundingSheet(response.data);
          }
        },
        () =>
          this._toastr.error(
            'Patient could not be imported at this time.',
            'Failed'
          ),
        () => this._toastr.clear(infoToast.toastId)
      );
  }
  handleSeenFilter(e) {
    let filteredPatients = this.census.patientList;
    if (this.filterSeen === 'seen') {
      filteredPatients = filteredPatients?.filter(p => this.isPatientSeenInCensus(p));
    } else if (this.filterSeen === 'not_seen') {
      filteredPatients = filteredPatients?.filter(p => !this.isPatientSeenInCensus(p));
    }
    this.dataSource = new MatTableDataSource(filteredPatients);
  }
  handleSortFilter(e) {
    this.censusPatientListService.changeFilterSort(e);
    if (e == "room_no") {
      this.location_sort_order = this.location_sort_order == "asc" ? "desc" : "asc"
      let sort = {
        active: 'location',
        direction: this.location_sort_order
      }
      this.censusPatientListService.setcensusPatientSort(sort);
    }
  }

  doSortPatients() {
    if (this.filterSort === 'user_sort') {
      this.initTableDataSource();
      if (this.isActivePatientList) {
        this.filterTableForActiveOrInactivePatients(true);
      }
      else {
        this.filterTableForActiveOrInactivePatients(false);
      }
      return;
    }
    const patientList = _.clone(this.census.patientList);
    patientList.sort((a, b) => {
      const adtA = a.adtRecord;
      const adtB = b.adtRecord;
      switch (this.filterSort) {

        case 'status':
          return compare(this.isPatientSeenInCensus(a) ? 1 : -1, this.isPatientSeenInCensus(b) ? 1 : -1, true)


        case 'seen':
          return compare(this.isPatientSeenInCensus(a) ? 1 : -1, this.isPatientSeenInCensus(b) ? 1 : -1, false)

        case 'not_seen':
          return compare(this.isPatientSeenInCensus(a) ? 1 : -1, this.isPatientSeenInCensus(b) ? 1 : -1, true)

        case 'name':
          const aName = this.commonService.getPatientFullNameFormat2(a);
          const bName = this.commonService.getPatientFullNameFormat2(b);
          return compare(aName, bName, true);

        case 'dob':
          return compare(new Date(a.date_of_birth).getTime(), new Date(b.date_of_birth).getTime(), true);

        case 'room_no':
          if (adtA && adtB) {
            let roomNoA = adtA.roomDesc;
            let roomNoB = adtB.roomDesc;
            try {
              roomNoA = parseInt(adtA.roomDesc);
              roomNoB = parseInt(adtB.roomDesc);
              if (isNaN(roomNoA)) {
                return -1;
              } else if (isNaN(roomNoB)) {
                return 1;
              } else {
                return compare(roomNoA, roomNoB, true);
              }
            } catch (error) { }
          }
          return -1;

        case 'new_patient':
          if (this.getRowClass(a) === 'PRIMARY_DARK' && this.getRowClass(b) !== 'PRIMARY_DARK') {
            return 1;
          } else if (this.getRowClass(a) !== 'PRIMARY_DARK' && this.getRowClass(b) === 'PRIMARY_DARK') {
            return -1;
          } else {
            return 0;
          }

        case 'admission_date':
          if (a.getAdmissionDate() && b.getAdmissionDate()) {
            const dateA = new Date(a.getAdmissionDate()).getTime();
            const dateB = new Date(b.getAdmissionDate()).getTime();
            return compare(dateA, dateB, true);
          }
          return -1;
        case 'discharge_date':
          const dischargeA = a.getDischargeDate();
          const dischargeB = b.getDischargeDate();
          if (dischargeA && dischargeB) {
            const dateA = new Date(dischargeA).getTime();
            const dateB = new Date(dischargeB).getTime();
            return compare(dateA, dateB, true);
          }
          return -1;


        case 'payor':
          if (adtA && adtB) {
            return compare(adtA.payerName?.toLowerCase(), adtB.payerName?.toLowerCase(), true);
          }
          return -1;

        default: return 0;
      }
    })
    this.census.patientList = patientList;
    // this.dataSource = new MatTableDataSource(patientList);
    this.initTableDataSource();
    if (this.isActivePatientList) {
      this.filterTableForActiveOrInactivePatients(true);
    }
    else {
      this.filterTableForActiveOrInactivePatients(false);
    }
    this.filterSort = 'user_sort';
    this.censusPatientListService.updatePatientListSortOrder(this.census._id, this.census.patientList.map(p => p._id))
      .subscribe((response: any) => {
      })
  }

  getSeenPatientsCount() {
    return this.census?.patientList.reduce((count, patient) => count + (this.isPatientSeenInCensus(patient) ? 1 : 0), 0)
  }

  getNotSeenPatientsCount() {
    return this.census?.patientList.reduce((count, patient) => count + (!this.isPatientSeenInCensus(patient) ? 1 : 0), 0)
  }

  applyFilter(filterValue: string) {
    if (this.dataSource) {
      this.censusPatientListService.changeFilterSearch(filterValue);

      this.dataSource.filter = filterValue.trim().toLowerCase();
      this.dataSource.filterPredicate = function (data, filter: string): boolean {
        const patientName = `${data.last_name.toLowerCase()}, ${data.first_name.toLowerCase()}${data.middle_name ? ' ' + data.middle_name.toLowerCase() + '.' : ''}`
        return patientName.includes(filter);
      };
    }
  }

  handleFilter(result) {
    this.filterDropdownActive = false;
    if (!result) {
      return;
    } else if (result.filter === 'cancel') {
      return;
    } else if (result.filter === 'reset') {
      result = null;
      this.initCensus();
      return;
    } else if (!result.payload) {
      return;
    }
    else {
      this.dateOfBirth = result.payload;
    }
  }


  filterDictationsByDateOfService(date_of_birth) {
    this.filterItemsByDate(date_of_birth, 'date_of_birth', this.census);
  }
  filterItemsByDate(date, dateKeyInObject, itemList) {
    const filterDateTime = new Date(date);
    const filterDate = filterDateTime.getDate();
    const filterMonth = filterDateTime.getMonth() + 1;
    const filterYear = filterDateTime.getFullYear();

    itemList = itemList?.filter(t => {
      const dateTime = new Date(t[dateKeyInObject]);
      const date = dateTime.getDate();
      const month = dateTime.getMonth() + 1;
      const year = dateTime.getFullYear();

      return date === filterDate && month === filterMonth && year === filterYear;
    });
    this.dataSource = itemList;
  }

  getRecentPatientADTRecord(patient) {
    // const sortedADTRecords = _.clone(patient.adtRecords);
    // sortedADTRecords.sort((a,b) => new Date(b.effectiveDateTime).getTime() -  new Date(a.effectiveDateTime).getTime());
    // console.log({
    //   adtRecords: patient.adtRecords,
    //   sortedADTRecords,
    // });

    // return sortedADTRecords.adtRecords[0];

    return patient.adtRecords[0];
  }
  getDischargeDate(patient: Patient) {
    // return patient.adtRecords.find(adt => adt.actionCode === 'DD' || adt.actionCode === 'DHOME')?.effectiveDateTime;
    return patient.getDischargeDate()
  }
  getPatientLocation(patient) {
    if (patient.last_room_num) return patient.last_room_num;
    const adt = patient.adtRecord;
    let location = '';
    if (adt) {
      if (adt.floorDesc) {
        location += adt.floorDesc;
      }
      if (adt.roomDesc) {
        location += ' ' + adt.roomDesc;
      }
      if (adt.bedDesc) {
        location += ' - ' + adt.bedDesc;
      }
    }
    return location;
  }
  handleRemovePatientClick(patientId) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: this.isActivePatientList ? "Are you sure you want to remove this patient from the census?" : "Are you sure you want to delete this patient from census?"
      }
    });
    let confirmed = "false";
    dialogRef.afterClosed().subscribe(result => {
      if (result === "true") {
        const details: any = {
          id: this.currentUser._id,
          auth_key: this.currentUser.auth_key,
          usertype: this.currentUser.user_type,

          patientRoundingSheetId: this.params.id,
          patientId
        }
        let isInactive = false;
        if (Array.isArray(this.census.patientsInactive) && this.census.patientsInactive.length > 0 && this.census.patientsInactive.includes(patientId)) {
          isInactive = true;
          details.isDeletePermanentaly = true;
        }
        else {
          details.isDeletePermanentaly = false;
        }

        this._patientRoungingSheetService.removePatientFromPatientRoundingSheet(details).subscribe((response: any) => {
          if (response.status === 200) {
            if (details.isDeletePermanentaly) {
              const index = this.census.patientList.findIndex(patient => patient._id === patientId);
              const i = this.census.patientsInactive.indexOf(patientId);
              if (index !== -1) {
                this.census.patientList.splice(index, 1);
              }
              if (i !== -1) {
                this.census.patientsInactive.splice(i, 1);
              }
              this.filterTableForActiveOrInactivePatients(false);
            }
            else {
              this.census.patientsInactive.push(patientId);
              this.filterTableForActiveOrInactivePatients(true);
            }
            // if (isInactive) {
            //   this.census.patientList = this.census.patientList?.filter(p => p._id !== patientId);
            //   this.dataSource.data = this.census.patientList;
            //   this.handleActivationFilter("inactive");
            // }
            // else {
            //   Array.isArray(this.census.patientsInactive) ?
            //     this.census.patientsInactive.push(patientId) : this.census.patientsInactive = [patientId];
            //   this.dataSource.data = this.census.patientList;
            //   this.handleActivationFilter("active");
            // }
            console.log("this.", this.census);
            // this.initTableDataSource();
            // this.doSortPatients();
          }
        })
      }
    });
  }

  // showPatientCommentsDialog(patient) {
  //   const dialogRef = this.dialog.open(PatientRoundingSheetCommentsDialog, {
  //     width: 'auto',
  //     data: {
  //       patient,
  //       census: this.census
  //     }
  //   });
  //   dialogRef.beforeClosed().subscribe(async () => {
  //     this.initCensus()
  //   });
  // }

  showCommentSidenav(patient) {
    this.currentPatient = patient;
  }

  showAudioListDialog(patient) {
    const dialogRef = this.dialog.open(AudioListDialog, {
      width: 'auto',
      data: {
        patient,
        census: this.census
      }
    });
    // dialogRef.beforeClosed().subscribe(async () => {
    //   this.initPatientRoundingSheet()
    // });
  }

  getOwnerInfo(owner_id) {
    return this.census.chargesOwnerList.find(u => u._id === owner_id)
  }
  formatProviderDateTag(patient) {
    let str = '';
    const charge = this.hasPatientChargeInCensus(patient);
    if (charge) {
      const provider = this.getOwnerInfo(charge.owner_id);
      if (provider) {
        const { first_name, last_name } = provider;

        str += `${first_name[0]}${last_name[0]}`.toUpperCase();
      }
      if (charge) {
        const visit_date = new Date(charge.visit_date);

        str += ` ${visit_date.getMonth() + 1}/${visit_date.getDate()}`;
      }
    }
    return str;
  }
  chargeStatusClass(patient) {

    let cssClass = '';
    const { patient_charges_count, patient_dictations_count } = patient;

    if (this.isPatientSeenInCensus(patient)) {
      //cssClass = 'darkBlueBg';
    } else if (!patient_charges_count && patient_dictations_count === 0) {
      cssClass = 'darkGrayBg';
    } else if (patient_dictations_count > 0) {
      const dos = moment(patient.patient_dictation_obj?.date_time);
      const censusDate = moment(this.census.date);
      if (dos.isValid() && dos.startOf('day').isSame(censusDate.startOf('day'))) {
        cssClass = 'darkGrayBg'
      } else {
        //cssClass = 'darkBlueBg'
      }
    } else if (!patient_charges_count) {
      let visitDate = moment(patient_charges_count?.visit_date);
      const censusDate = moment(this.census.date);
      if (visitDate.isValid() && visitDate.startOf('day').isSame(censusDate.startOf('day'))) {
        cssClass = 'darkGrayBg';
      } else {
        //cssClass = 'darkBlueBg';
      }
    } else {
      //cssClass = 'darkBlueBg';
    }
    return cssClass;
  }

  getRowClass(patient) {
    // const patientCharge = this.hasPatientChargeInCensus(patient);
    // const patientDictation = this.hasPatientDictationInCensus(patient);

    // if (this.isPatientSeen(patient))
    // {
    //   return 'PRIMARY_DARK'; // OLD Patient
    // }
    // else if (!patientCharge && !patientDictation)
    // {
    //   return 'DARK_GRAY'; // NEW Patient
    // }
    // else if (patientDictation)
    // {
    //   let dos = moment(patientDictation.filterServiceDate);
    //   let censusDate = moment(this.census.date);

    //   if (dos.isValid() && dos.startOf('day').isSame(censusDate.startOf('day')))
    //   {
    //     return 'DARK_GRAY'; // NEW Patient
    //   }
    //   else
    //   {
    //     return 'PRIMARY_DARK'; // OLD Patient
    //   }
    // }
    // else if (patientCharge)
    // {
    //   let visitDate = moment(patientCharge.visit_date);
    //   let censusDate = moment(this.census.date);
    //   if (visitDate.isValid() && visitDate.startOf('day').isSame(censusDate.startOf('day')))
    //   {
    //     return 'DARK_GRAY'; // NEW Patient
    //   }
    //   else
    //   {
    //     return 'PRIMARY_DARK'; // OLD Patient
    //   }
    // }
    // else
    // {
    //   return 'PRIMARY_DARK'; // OLD Patient
    // }

    if (this.censusPatientListService.isPatientFollowUp(this.census, patient)) {
      return 'PRIMARY_DARK'
    } else {
      return 'DARK_GRAY';
    }
  }

  isPrimaryDarkRow(patient) {

  }
  isDarkGrayRow(patient) {
    return
  }
  isPatientSeen(patient) {
    return this.censusPatientListService.isPatientSeen(this.census, patient);
  }
  isPatientSeenInCensus(patient) {
    return this.censusPatientListService.isPatientSeenInCensus(this.census, patient);
  }
  hasPatientChargeInCensus(patient) {
    return this.censusPatientListService.hasPatientChargeInCensus(this.census, patient);
  }
  hasPatientDictationInCensus(patient) {
    return this.censusPatientListService.hasPatientDictationInCensus(this.census, patient);
  }
  markPatientSeen(patient, isSeen) {

    if (isSeen) {
      this.census.currentPatientsSeen.push(patient._id);
    } else {
      const index = this.census.currentPatientsSeen.indexOf(patient._id);
      this.census.currentPatientsSeen.splice(index, 1);
    }
    const details = {
      ...this.authService.authObject,
      patientRoundingSheetId: this.census._id,
      patientsSeen: this.census.currentPatientsSeen
    }
    this.patientRoundingSheetService.updatePatientRoundingSheetSeen(details).subscribe((response: any) => {
      // console.log(response);

    })
  }
  hasNoChargeAndDictation(patient) {
    // return patient.patient_charges_count === null && patient.patient_dictations_count === 0
    return patient.charges.length === 0 && patient.audios.length === 0;

  }
  hasChargeStatusEmpty(patient) {
    // if(patient.patient_charges_count) {
    //   return !patient.patient_charges_count.status || patient.patient_charges_count.status === '';
    // }
    // return false;
    const patientCharge = this.hasPatientChargeInCensus(patient)
    if (patientCharge) {
      return patientCharge.status ? false : true;
    }
    return false;
  }
  hasChargeAndStatus(patient, status) {
    const patientCharge = this.hasPatientChargeInCensus(patient)
    if (patientCharge) {
      return patientCharge.status === status;
    }
    return false;
  }
  onRowClick($event, patient_id) {
    if ($event.target.classList.value.indexOf('icon') >= 0 || $event.target.tagName.toLowerCase() === 'a') return;
    // localStorage.setItem('patient_id', patient_id);
    localStorage.setItem('hide_census', "true");
    this._router.navigate([`patient-detail/${patient_id}`]);
  }

  goBack() {
    // const backUrl = this.goBackUrlService.getBackUrl({
    //   '/patient-charge': '/census'
    // });
    // if (backUrl) {
    //   this._router.navigateByUrl(backUrl);
    // } else {
    this._location.back();
    // }
  }
  getPatientsCounts(type) {
    if (!this.census) return 0;
    const { patientList = [] } = this.census;
    switch (type) {
      case 'total':
        return patientList.length
      case 'new':
        {
          let count = 0;
          for (const patient of patientList) {
            if (!this.censusPatientListService.isPatientFollowUp(this.census, patient)) {
              count++;
            }
          }
          return count;
        }

      case 'followUp':
        {
          let count = 0;
          for (const patient of patientList) {
            // console.log({
            //   'patient': `${patient.first_name} ${patient.last_name}`,
            //   isPatientFollowUp: this.censusPatientListService.isPatientFollowUp(this.census, patient)
            // })
            if (this.censusPatientListService.isPatientFollowUp(this.census, patient)) {
              count++;
            }
          }
          return count;
        }
      case 'discharge':
        {
          let count = 0;
          for (const patient of patientList) {
            if (patient.pcc_payload?.dischargeDate) {
              count++;
            }
          }
          return count;
        }
    }
  }

  togglePatientFromQueueForImport($event, patient) {
    $event.preventDefault();
    $event.stopPropagation();
    const index = this.selectedPatients.findIndex(p => {
      if (Object.prototype.hasOwnProperty.call(p, '_id')) {
        return (p._id === patient._id)
      } else {
        return (p.patientId === patient.patientId)
      }
    });
    if (index >= 0) {
      this.selectedPatients.splice(index, 1);
    } else {
      this.selectedPatients.push(patient);
    }
  }
  selectedPatientForImport(patient) {
    return this.selectedPatients.findIndex(p => {
      if (Object.prototype.hasOwnProperty.call(p, '_id')) {
        return (p._id === patient._id)
      } else {
        return (p.patientId === patient.patientId)
      }
    }) >= 0;

  }

  async importMultiplePatients() {
    const primaryProviderId = this.currentUser._id;


    if (!primaryProviderId) {
      return this._toastr.warning(
        'Please select primary Provider to import patient.',
        'Warning'
      );
    }


    const selectedFacilityId = this.census.facility._id;
    const pccOrgUuid = this.census.facility.pcc_orgUuid;

    const importedPatients = [];
    const pccPatients = this.selectedPatients?.filter(p => Object.prototype.hasOwnProperty.call(p, 'patientId'));

    const totalSelectedPatients = pccPatients.length;
    for (const pccPatientId of pccPatients.map(p => p.patientId)) {
      const infoToast = this._toastr.info(`Importing Patients (${importedPatients.length + 1} of ${totalSelectedPatients})`, 'Please wait', {
        disableTimeOut: true,
      });

      const response: any = await this.pccService
        .importPCCPatient(pccOrgUuid, pccPatientId, primaryProviderId, selectedFacilityId)
        .toPromise()
        .catch((e) => {
          this._toastr.error(
            'Patient could not be imported at this time.',
            'Failed'
          );
        });

      this._toastr.clear(infoToast.toastId);
      if (response.status === 200) {
        const importedPatient = response.data;
        this.addPatientToCurrrentRoundingSheet(importedPatient, false);
        importedPatients.push(response.data);
        this.selectedPatients = [];
      } else {
        this._toastr.error(
          'Patient could not be imported at this time.',
          'Failed'
        );
      }


    }
    this._toastr.success(`${importedPatients.length} patients imported successfully`, 'Success');
  }

  async handleImportPatient(pccPatient, sync) {
    const checkPatient = await this.isPatientAlreadyExistsInCensus(pccPatient);
    if (checkPatient) {
      const first_name = pccPatient.firstName;
      const last_name = pccPatient.lastName;
      if (checkPatient === 'current') {
        //return this._toastr.warning(`${last_name}, ${first_name} already exits in current census`)
      } else {
        if (Array.isArray(checkPatient) && checkPatient.length > 0) {
          if (checkPatient[0].provider_ids === this.currentUser._id) {
            // return this._toastr.warning(`${last_name}, ${first_name} already exits in your other census`)
          } else {
            // return this._toastr.warning(`${last_name}, ${first_name} already exists in other provder's census`)
          }
        }
      }
    }

    this.importAndAddPatientToCurrrentRoundingSheet(pccPatient, sync)
  }

  async handleAddPatient(patient) {
    const checkPatient = await this.isPatientAlreadyExistsInCensus(patient);
    if (checkPatient) {
      const patientName = this.commonService.getPatientFullNameFormat2(patient);
      if (checkPatient === 'current') {
        //return this._toastr.warning(`${patientName} already exits in current census`)
      } else {
        if (Array.isArray(checkPatient) && checkPatient.length > 0) {
          if (checkPatient[0].provider_ids === this.currentUser._id) {
            // return this._toastr.warning(`${patientName} already exits in your other census`)
          } else {
            // return this._toastr.warning(`${last_name}, ${first_name} already exists in other provder's census`)
          }
        }
      }
    }

    this.addPatientToCurrrentRoundingSheet(patient)
  }

  getLastSeen(patient) {
    let lastSeen = '';
    const { recentNote } = patient;
    if (recentNote && recentNote.doctor_id_ref) {

      const { first_name, last_name } = recentNote.doctor_id_ref;
      lastSeen += `${first_name[0]}${last_name[0]}`.toUpperCase();
      // console.log("recentNote", recentNote);
      lastSeen += ` - ${moment(new Date(recentNote.filterServiceDate).toLocaleString('en-US', { timeZone: recentNote.timezone })).format('M/D/YYYY')}`;
    }
    return lastSeen;
  }
  getRecentNoteInCensus(patient) {
    // console.log("patient",patient);
    // this.recentNotes.map(ele => {
    //   if (patient._id == ele.patient_id_ref) {
    //     delete patient.recentNoteInCensus;
    //     patient.recentNoteInCensus = ele;
    //   }
    // });

    // const { recentNoteInCensus } = patient;
    // if (recentNoteInCensus && recentNoteInCensus.rounding_sheet_id === this.census._id) {
    //   
    //   return recentNoteInCensus;
    // }
    // return recentNoteInCensus;
    // return false;
    return this.recentNotes.find(note => note.patient_id_ref === patient._id);
  }
  isOwner(census) {
    return this.currentUser._id == census?.createdBy
  }
  isPccSearchPatientEnabled(census) {
    // if(this.route.snapshot.paramMap.get('showPCCSearchPatient')) {
    //   return true;
    // }
    let isAssociatedMT = this.currentUser.user_type == global.USER_TYPE.TRANSCRIBER;
    let isAssociated_MA = this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT;
    let isAssociated_BO = this.currentUser.user_type === global.USER_TYPE.BUSINESS_OFFICER;
    if (isAssociatedMT || isAssociated_MA || isAssociated_BO) {
      return true;
    }
    return this.isOwner(census);
    // if(census && census.facility && census.facility.pcc_facId) {
    // }
    // return false;
  }

  reloadCensus() {
    // if(!this.pccService.hasPCCOrgUUID()) {
    //   return this._toastr.warning('You must logged into PCC atleast once')
    // }
    
    const pccPatients = this.census.patientList?.filter(p => p.pcc_patientId).map(p => ({
        _id: p._id,
        pcc_patientId: p.pcc_patientId,
        pcc_orgUuid: p.pcc_payload?.orgUuid
    }));
    this.reloadingCensus = true;
    const facilityId = this.census.facility._id;
    this.pccService.syncPatientsData(facilityId, pccPatients).subscribe((response: any) => {
      if (response.status === 200) {
        this.initCensus()
      }
    }, () => {
    }, () => this.reloadingCensus = false)
  }

  formatCommentHTML(census_comment) {
    // console.log(census_comment);
    if (!census_comment?.note_detail) return '';
    return census_comment.note_detail.trim().replace(/\n/g, '<br />');
  }

  showAddCharge(element) {
    if (!this.currentUser.charge_status) {
      return false;
    }

    if (this.census && this.isOwner(this.census) && (!this.hasChargeAndStatus(element, 'submit') && !this.hasChargeAndStatus(element, 'draft'))) {
      return true;
    }

    return false;
  }
  setFontSizeForPotrait(value, e) {
    if (e.checked === true) {
      this.defaultPotraitFontSize = value;
      this.currentUser.other_settings.census_potrait_font_size = value;
      this.authService.setOtherUserSettings(this.currentUser.other_settings).subscribe(res => {
        // console.log(res.data);
        if (res.data) {
          this._toastr.success("PDF potrait default font size changed");
        }
      });
    }
  }

  setFontSizeForLandscape(value, e) {
    // console.log(e);
    if (e.checked === true) {
      this.defaultLandscapeFontSize = value;
      this.currentUser.other_settings.census_landscape_font_size = value;
      this.authService.setOtherUserSettings(this.currentUser.other_settings).subscribe(res => {
        // console.log(res.data);
        if (res.data) {
          this._toastr.success("PDF landscape default font size changed");
        }
      });
    }
  }
  printCensusPatientList() {
    // console.log( this.currentUser.other_settings?.census_pdf_orientation);
    //  console.log( this.currentUser.other_settings?.census_pdf_fontSize);
    //  console.log(this.currentUser.other_settings?.census_pdf_column);
    //  let user = JSON.parse(localStorage.getItem('currentUserAuth'));
    //  console.log(typeof user);
    //  console.log(user);

    if (this.currentUser.other_settings.census_pdf_orientation &&
      this.currentUser.other_settings.census_pdf_fontSize &&
      this.currentUser.other_settings.census_pdf_column) {
      let censusData = JSON.parse(JSON.stringify(this.census));
      if (this.selectedPatientsToImport.length > 0) {
        censusData.patientList = this.selectedPatientsToImport;
      }
      try {
        let params: any = {
          // censusId: this.census._id,
          census: censusData,
          orientation: this.currentUser.other_settings.census_pdf_orientation,
          fontSize: this.currentUser.other_settings.census_pdf_fontSize,
          column: this.currentUser.other_settings.census_pdf_column
        }
        const infoToastr: any = this._toastr.info('Generating PDF', 'Please wait...');

        return this.dashboardService.downloadFile(global.url + API_URL.PATIENT_ROUNDING_SHEET.generateCensusPatientListAndDownloadAsPDF, params).subscribe((res: any) => {
          if (res instanceof HttpResponse) {

            var url = window.URL.createObjectURL(res.body);
            var a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = `Census ${this.census.name} - ${this.census.facility.title}`;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
            this._toastr.clear(infoToastr.toastId);
          } else {
            if (res.type === 0) {
            } else if (res.type === 3) {
              const { loaded, total } = res;
              const progress = Math.round(loaded / total * 100);
              if (progress > 0) {
              }
            }
          }
          if (res.status == 200) {
            return true;
          }
        })
      }
      catch (error) {
        const { message = 'Something went wrong, please try again' } = error;
        this._toastr.error(message);
      }
    }
    else {
      this._toastr.error("Change PDF download settings in settings page under census tab");
    }
  }
  generateCensusPatientListAndDownloadAsPDF() {
    // console.log(font);
    try {
      let params: any = {
        // censusId: this.census._id,
        census: this.census,
      }
      let data;
      let customPdfDialogRef = this.dialog.open(CustomPdfDialogComponent, {
        width: '40%',
        height: '70vh'
      });
      customPdfDialogRef.afterClosed().subscribe(result => {
        // console.log("Dialog Result",result);
        params = { ...params, ...result };
        if (params.fontSize && Object.values(params.column).length > 0 && params.orientation) {
          // console.log("params",params);
          const infoToastr: any = this._toastr.info('Generating PDF', 'Please wait...');
          return this.dashboardService.downloadFile(global.url + API_URL.PATIENT_ROUNDING_SHEET.generateCensusPatientListAndDownloadAsPDF, params).subscribe((res: any) => {
            if (res instanceof HttpResponse) {

              var url = window.URL.createObjectURL(res.body);
              var a = document.createElement('a');
              document.body.appendChild(a);
              a.setAttribute('style', 'display: none');
              a.href = url;
              a.download = `Census ${this.census.name} - ${this.census.facility.title}`;
              a.click();
              window.URL.revokeObjectURL(url);
              a.remove(); // remove the element
              this._toastr.clear(infoToastr.toastId);
            } else {
              if (res.type === 0) {
              } else if (res.type === 3) {
                const { loaded, total } = res;
                const progress = Math.round(loaded / total * 100);
                if (progress > 0) {
                }
              }
            }
            if (res.status == 200) {
              return true;
            }
          })
        }
      });
    } catch (error) {
      const { message = 'Something went wrong, please try again' } = error;
      this._toastr.error(message);
      // } finally {
      //   this._toastr.clear(infoToastr.toastId);
      // }
    }
  }

  onListDrop(event: CdkDragDrop<any[]>) {
    // Swap the elements around
    const patients = this.dataSource.data.map(patient => patient._id);
    const missingPatients = this.census.patientList.map(patient => {
      if (!patients.includes(patient._id)) {
        return patient;
      }
    }).filter(patient => patient);
    this.census.patientList = [...this.dataSource.data, ...missingPatients];
    // this.census.patientList = this.dataSource.data;
    moveItemInArray(this.census.patientList, event.previousIndex, event.currentIndex);
    this.initTableDataSource();
    this.censusPatientListService.updatePatientListSortOrder(this.census._id, this.census.patientList.map(p => p._id))
      .subscribe((response: any) => {
        this.filterSort = 'user_sort';
      })
    if (this.isActivePatientList) {
      this.filterTableForActiveOrInactivePatients(true);
    }
    else {
      this.filterTableForActiveOrInactivePatients(false);
    }
  }
  viewRehabDocs(census_id) {
    this.dialog.open(RehabDocsPopupComponent, { data: census_id, width: '80%', height: '100vh' })

  }
  showViewNoteDialog(note_id) {
    let time_zone = 'America/New_York'
    if (this.census && this.census.facility) {
      time_zone = this.census.facility.pcc_timeZone ? this.census.facility.pcc_timeZone : this.census.facility.timeZone
    }
    const dialogRef = this.dialog.open(ViewNoteDialog, {
      width: '80%',
      //height:'80%',
      data: {
        note_id: note_id,
        timezone: time_zone
      }
    });
    // dialogRef.beforeClosed().subscribe(async (confirm: any) => {
    // });
  }

  sortData(sort: Sort) {
    const isAsc = sort.direction === 'asc';

    const patientList = _.clone(this.census.patientList);
    patientList.sort((a, b) => {
      let result;
      const adtA = a.adtRecord;
      const adtB = b.adtRecord;

      switch (sort.active) {

        case 'switch-status':
          return compare(this.isPatientSeenInCensus(a) ? 1 : -1, this.isPatientSeenInCensus(b) ? 1 : -1, isAsc)

        case 'name':
          const aName = this.commonService.getPatientFullNameFormat2(a);
          const bName = this.commonService.getPatientFullNameFormat2(b);
          return compare(aName, bName, isAsc);
        case 'note':
          const noteA = this.getRecentNoteInCensus(a);
          const noteB = this.getRecentNoteInCensus(b);
          if (noteA) {
            return 1;
          } else if (noteB) {
            return -1;
          } else {
            return 0;
          }
        case 'discharge':
          return 1;
        case 'charge':
          const patientChargeA = this.hasPatientChargeInCensus(a);
          const patientChargeB = this.hasPatientChargeInCensus(b);
          if (patientChargeA) {
            return 1;
          } else if (patientChargeB) {
            return -1;
          } else {
            return 0;
          }
          return 1;
        // case 'charge-status':
        // return 1;
        case 'date_of_birth':
          return compare(new Date(a.date_of_birth).getTime(), new Date(b.date_of_birth).getTime(), isAsc);
        case 'gender':
          return compare(a.gender, b.gender, isAsc);
        // case 'facility':
        //   return 1;
        case 'location':
          try {
            let roomNoA, roomNoB;
            if (a.last_room_num) {
              roomNoA = parseInt(a.last_room_num.replace(/\D+/g, ''))
            } else if (adtA) {
              roomNoA = parseInt(adtA.roomDesc);
            }
            if (b.last_room_num) {
              roomNoB = parseInt(b.last_room_num.replace(/\D+/g, ''))
            } else if (adtB) {
              roomNoB = parseInt(adtB.roomDesc);
            }
            if (roomNoA && roomNoB) {
              if (isNaN(roomNoA)) {
                return -1;
              } else if (isNaN(roomNoB)) {
                return 1;
              } else {
                return compare(roomNoA, roomNoB, isAsc);
              }
            }
          } catch (error) { }
          return -1;
        case 'admission_date':
          try {
            const admissionDateA = a.getAdmissionDate();
            const admissionDateB = b.getAdmissionDate();

            return compare(new Date(admissionDateA).getTime(), new Date(admissionDateB).getTime(), isAsc);

          } catch (error) {
            return -1;
          }
        case 'discharge_date':
          try {
            const dischargeDateA = a.getDischargeDate();
            const dischargeDateB = b.getDischargeDate();

            return compare(new Date(dischargeDateA).getTime(), new Date(dischargeDateB).getTime(), isAsc);

          } catch (error) {
            return -1;
          }
        case 'payer':
          try {
            const payerA = adtA.payerName;
            const payerB = adtB.payerName;
            if (payerA && payerB) {
              return compare(payerA, payerB, isAsc);
            } else if (payerA) {
              return 1;
            } else if (payerB) {
              return -1;
            } else {
              return 0;
            }
          } catch (error) {
            return -1;
          }
        case 'comments_count':
          try {
            const commentA = adtA.census_comment;
            const commentB = adtB.census_comment;
            if (commentA && commentB) {
              return compare(commentA, commentB, isAsc);
            } else if (commentA) {
              return 1;
            } else if (commentB) {
              return -1;
            } else {
              return 0;
            }
          } catch (error) {
            return -1;
          }
        case 'last_seen':
          try {
            const { recentNote: recentNoteA } = a;
            const { recentNote: recentNoteB } = b;
            const recentNoteDOSA = new Date(recentNoteA.filterServiceDate).getTime();
            const recentNoteDOSB = new Date(recentNoteB.filterServiceDate).getTime();
            return compare(recentNoteDOSA, recentNoteDOSB, isAsc);
          } catch (error) {

          }
      }
    });
    this.dataSource.data = patientList;
    this.census.patientList = patientList;
  }
  castCensusPaitentSort(sort) {
    if (sort.active == "location" && sort.direction != '')
      this.location_sort_order = sort.direction == "asc" ? "desc" : 'asc'
    this.censusPatientListService.setcensusPatientSort(sort);
  }

  isLoggedIntoPCC() {
    return !!this.pccService.pccUserinfo;
  }
  async refreshScreen() {
    this.currentUser = this.currentUser;
    if (!this.currentUser) {
      // if not login, stop and redirect to login #mohsin-dev
      localStorage.removeItem('currentUser');
      this._router.navigate(['/login']);
      return;
    }
    this.getRecentNoteByCensusId();
    // this.defaultPotraitFontSize = this.currentUser.other_settings?.census_potrait_font_size;
    // this.defaultLandscapeFontSize = this.currentUser.other_settings?.census_landscape_font_size;
    // show operations column only for doctor
    // if(this.currentUser.user_type == 1) {
    this.displayedColumns = ['switch-status', 'name', 'deleteNote', 'note', 'operations', 'comments_count', 'discharge',
      // 'charge',
      //  'charge-status',
      'date_of_birth', 'location', 'admission_date',
      // 'discharge_date', 
      'payer', 'pcp', 'last_seen', 'edit_patient'];
    // remove // 'status',  'source',
    // } else {
    // this.displayedColumns = ['name', 'date_of_birth', 'gender'];
    // }
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <any>null,
      primary_doctor_id: <string>null
    };
    item.id = this.currentUser._id;
    item.auth_key = this.currentUser.auth_key;
    item.usertype = this.currentUser.user_type;

    await this.initCensus();

    // if(this.isOwner(this.census)){
    //   this.displayedColumns = ['switch-status', 'name', 'note', 'discharge',
    //   // 'charge',
    //   // 'charge-status',
    //    'date_of_birth', 'location', 'admission_date', 'discharge_date', 'payer', 'comments_count', 'last_seen', 'operations'];
    // }
    // else{
    //   this.displayedColumns = ['switch-status', 'name', 'note', 'discharge',
    //   // 'charge',
    //   // 'charge-status',
    //   'date_of_birth', 'location', 'admission_date', 'discharge_date', 'payer', 'comments_count', 'last_seen', 'operations', 'checkbox'];
    // }

    this.displayedColumns = ['checkbox', 'switch-status', 'name', 'deleteNote', 'note', 'operations', 'comments_count', 'discharge',
      'date_of_birth', 'location', 'admission_date',
      //  'discharge_date',
      'payer', 'pcp', 'last_seen', 'edit_patient'];

    this.censusPatientListService.castFilterSort.subscribe(value => {
      this.filterSort = value;
      this.doSortPatients();
    })
    this.censusPatientListService.castFilterSearch.subscribe(value => {
      this.filterSearch = value;
      this.applyFilter(value);
    })

    this.initPatientsByProvider();
    this.initPccPatients();
    // this.getPatientCommentCount(this.params.id);
    this.getCensusRecentComment(this.params.id);
    // console.log("cens", this.census);
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.selectedPatientsToImport = []
      return;
    }

    this.selection.select(...this.dataSource.data);
    this.selectedPatientsToImport = [...this.dataSource.data];
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  handleRemoveCensusNote(note_id) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: "Are you sure you want to delete this note?"
      }
    });
    let confirmed = "false";
    dialogRef.afterClosed().subscribe(result => {
      if (result === "true") {
        const details = {
          id: this.currentUser._id,
          auth_key: this.currentUser.auth_key,
          usertype: this.currentUser.user_type,
          note_id: note_id
        }



        this._patientRoungingSheetService.removeNoteFromPatientRoundingSheet(details).subscribe((response: any) => {
          if (response.status === 200) {
            this._toastr.success('Patient note deleted successfully');
            const noteIndex = this.recentNotes.findIndex(note => note._id == note_id);
            if (noteIndex > -1) {
              this.recentNotes.splice(noteIndex, 1);
            }
            // this.dataSource.data.map(item => {
            //   if (item.recentNoteInCensus._id === note_id) {
            //     delete item.recentNoteInCensus
            //     return
            //   }
            // })

          }
          else {
            this._toastr.error(response.error);
          }
        })
      }
    });
  }
  async deleteAllPatients() {
    const not_dischargePatientList = this.census.patientList.filter(patient => !patient.last_discharge_date).map(patient => patient._id);
    if (not_dischargePatientList.length == this.census.patientList.length) {
      this._toastr.info("No discharged patients!");
    } else {
      let dialogRef = this.dialog.open(DialogConfirmationComponent, {
        data: {
          message: "Are you sure you want to delete all discharged patients?"
        }
      });
      let confirmed = "false";
      dialogRef.afterClosed().subscribe(async result => {
        if (result === "true") {
          var details = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <any>null,
            census_id: <string>null,
            patientList: []
          };
          details.id = this.currentUser._id;
          details.auth_key = this.currentUser.auth_key;
          details.usertype = this.currentUser.user_type;
          details.census_id = this.census_id;
          details.patientList = not_dischargePatientList;
          await this._patientRoungingSheetService.updatePatientListinRoundingSheet(details).subscribe(async (response: any) => {
            if (response.status == 200) {
              this.census.patientList = this.census.patientList.filter(patient => !patient.last_discharge_date);
              this.dataSource.data = this.census.patientList;
              this._toastr.success(response.message);
            } else {
              this._toastr.error(response.error);
            }
          });
        }
      });
    }
  }
  getDate(date) {
    // let dateObj = new Date(date);
    // console.log(dateObj);
    return moment(new Date(date).toLocaleString('en-US')).format('MM/DD/YYYY');
  }
  getAdmissionDate() {

  }

  addPatientForm() {
    let obj: any = {};
    obj.census_id = this.census._id;
    obj.facility_id = this.census.facility._id;
    console.log('obj', obj);

    const dialogRef = this.dialog.open(PatientAddFormDialogComponent, {
      height: '95%',
      width: '55%',
      data: obj
    });
    dialogRef.afterClosed().subscribe(async (data) => {
      console.log('daataaa', data);
      setTimeout(() => {
        if (data) {
          this.initCensus();
        }
      }, 500);
    });
  }

  openEditPatientDialog(patient, index) {
    let data = { ...patient, censusIdRef: this.census._id };
    this.dialog.open(PatientEditDialogComponent, {
      width: '40%',
      height: '65vh',
      panelClass: 'pcc-email-template-dialog-container-class',
      data
    }).afterClosed().subscribe((patient: any) => {
      if (patient) {
        console.log("patient", patient);
        this.dataSource.data = [];
        if (Array.isArray(patient.non_pcc_payload)) {
          for (const ele of patient.non_pcc_payload) {
            if (ele.census_id_ref == this.census._id) {
              patient.non_pcc_payload = ele;
            }
          }
          this.census.patientList[index].non_pcc_payload = patient.non_pcc_payload;
        }
        this.census.patientList[index].primary_practitioner_last_name = patient.primary_practitioner_last_name;
        this.census.patientList[index].primary_practitioner_first_name = patient.primary_practitioner_first_name;
        this.census.patientList[index].last_admit_date = patient.last_admit_date;
        this.census.patientList[index].last_admit_date_obj = patient.last_admit_date_obj;
        this.census.patientList[index].non_pcc_payerName = patient.non_pcc_payerName;

        this.dataSource.data = this.census.patientList;
      }
    });
  }

  handleActivationFilter($event) {
    if (Array.isArray(this.census.patientsInactive)) {
      if ($event === "active") {
        this.isActivePatientList = true;
        this.filterTableForActiveOrInactivePatients(true);
        if (this.displayedColumns.indexOf('checkbox') === -1) {
          // this.displayedColumns.splice(4, 0, 'operations');
          this.displayedColumns.splice(0, 0, 'checkbox');
          this.displayedColumns.splice(1, 0, 'switch-status');
          this.displayedColumns.splice(this.displayedColumns.length, 0, 'edit_patient');
        }
      }
      else {
        this.isActivePatientList = false;
        this.filterTableForActiveOrInactivePatients(false);
        // this.displayedColumns.splice(this.displayedColumns.indexOf('operations'), 1);

        const i = this.displayedColumns.indexOf('checkbox');
        if (i !== -1)
          this.displayedColumns.splice(i, 1);
        const j = this.displayedColumns.indexOf('switch-status');
        if (j !== -1)
          this.displayedColumns.splice(j, 1);
        const k = this.displayedColumns.indexOf('edit_patient');
        if (k !== -1)
          this.displayedColumns.splice(k, 1);
      }
    }
    else {
      this.census.patientsInactive = [];
    }
  }
  restoreInactivePatient(patientId) {
    this.census.patientsInactive = this.census.patientsInactive.filter(id => id !== patientId);
    const payload = {
      patientRoundingSheetId: this.census._id,
      patientsInactive: this.census.patientsInactive
    };
    this._patientRoungingSheetService.updatePatientRoundingSheetInactive(payload).subscribe((response: any) => {
      if (response.status === 200) {
        // this.census.patientList = response.data.patientList;
        this.filterTableForActiveOrInactivePatients(false);
      }
    });
  }
  filterTableForActiveOrInactivePatients(isActive) {
    if (isActive) {
      this.dataSource.data = this.census.patientList.filter(({patient}) => patient.patient_active_status !== 'inactive');
    }
    else {
      this.dataSource.data = this.census.patientList.filter(({patient}) => patient.patient_active_status === 'active');
    }
  }
}


function compare(a: number | string, b: number | string, isAsc: boolean) {
  if (typeof a === 'string' && typeof b === 'string') {
    return isAsc ? a.localeCompare(b) : b.localeCompare(a);
  }
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
