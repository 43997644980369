import { Component,Inject, OnInit, ViewChild, EventEmitter} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PatientAddFormComponent} from '../../patient-add-form/patient-add-form.component'

// UPLOAD FILE DIALOG
export interface PatientDetailDialogData {
  currentUser:any,
  patient_id:any
}

@Component({
  selector: 'app-patient-detail-dialog',
  templateUrl: './patient-detail-dialog.component.html',
  styleUrls: ['./patient-detail-dialog.component.css']
})
export class PatientDetailDialog implements OnInit {
  @ViewChild(PatientAddFormComponent) patientAddFormComponent:PatientAddFormComponent;

  constructor(
    public dialogRef: MatDialogRef<PatientDetailDialog>,
    @Inject(MAT_DIALOG_DATA) public data: PatientDetailDialogData) { 
    }
    
    ngOnInit(): void {
    }
}
