import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { TicketService } from '../ticket.service';
import { TicketCommentsComponent } from './ticket-comments/ticket-comments.component';

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.css']
})
export class TicketDetailsComponent implements OnInit {

  @ViewChild(TicketCommentsComponent) child:TicketCommentsComponent;
  @Input() rowData;
  @Output() showDetail = new EventEmitter<any>();
  @Output() filterStatus = new EventEmitter<any>();
  @Output() updateStatus = new EventEmitter<any>();
  comments: string;
  currentUser: any;
  editComment : any = {};
  isPostEnabled: boolean = false;

  constructor(private ticketService: TicketService,private authService : AuthService) { }

  ngOnInit() {
        this.currentUser = this.authService.currentUser;
    console.log('this.comments',this.comments);
  }

  ngOnChanges(){
    console.log('yes ng on changes');
  }

  changeStatus($event) {
    this.ticketService.changeStatus(this.rowData._id, $event.value).subscribe((Response: any) => {
      if (Response.status == 200) {
        console.log('Reponse', Response);
        this.filterStatus.emit($event);
      }
    });
  }

  postComments() {
    this.isPostEnabled = true;
    if(Object.values(this.editComment).length > 0){
      this.ticketService.editComments(this.editComment._id,this.comments).subscribe((Response:any)=>{
        if(Response.status == 200){
          this.isPostEnabled = false;
          this.child.getComments();
          this.editComment = {};
          this.comments = '';
        }else{
          console.log('Response failed');
        }
      });
    }else{
      this.ticketService.postComments(this.rowData._id,this.comments,this.currentUser.first_name+' '+this.currentUser.last_name).subscribe((Response:any)=>{
        if(Response.status == 200){
          this.isPostEnabled = false;
          console.log('Comment posted',Response);
          if(Response.statusUpdated == true){
            this.rowData.status = 'In progress';
            this.updateStatus.emit(true);
          }
          this.child.getComments();
          this.comments = '';
        }else{
          console.log('Response failed');
        }
      });
    }
  }

  goBack() {
    this.showDetail.emit(false);
  }

}
