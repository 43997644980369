<div class="flex flex-col">
    <mat-list  style="height: 380px; overflow-y: scroll;">
        <h3 mat-subheader style="margin-bottom:0">Comments,
            <ng-container *ngIf="commentsLoaded">
                ({{comments.length}})
            </ng-container>
        </h3>
        <div class="flex items-center justify-center">
            <mat-progress-spinner *ngIf="comments.length === 0 && !commentsLoaded" diameter="40" mode="indeterminate">
            </mat-progress-spinner>
        </div>
        <div class="media" *ngFor="let comment of comments; let i=index">
            <div class="media-body">
                <div class="flex items-start">
                    <span class="media-heading">{{comment?.created_by?.first_name}} {{comment?.created_by?.last_name}}
                    </span>
                    &nbsp;&nbsp;
                    <li>{{comment?.updatedAt | date:'medium'}}</li>
                </div>
                <img *ngIf=comment?.attachment_link style="height: 500px; width: 350px" [src]="comment?.attachment_link"
                    alt="">
                <p *ngIf="comment?.comment!=='undefined'"
                    style="font-family:'Times New Roman', Times, serif ;font-size: 11 !important;">{{comment?.comment}}
                </p>
            </div>
            <mat-divider></mat-divider>
        </div>
    </mat-list>

    <div class="flex-grow" style=" bottom: 2px; background-color: whitesmoke;">
        <div style="margin-top: 15rem;">
          <mat-form-field style="width: 100%;">
            <mat-label>Leave a comment</mat-label>
            <textarea matInput style="height: 7vh;" autofocus name="comment" [(ngModel)]="comment"
              placeholder="Ex. It makes me feel..."></textarea>
          </mat-form-field>
    
          <div class="flex justify-end" style="padding-top: 8px;">

            <button [disabled]="buttonState" mat-raised-button color="primary" (click)="addComment()">Submit</button>
          </div>
        </div>
      </div>

</div>