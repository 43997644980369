<div class="div-wrapper">
    <div class="flex flex-col gap-2" style="height: 100%;">
        <div class="flex flex-col items-center justify-center">
            <div *ngIf="importPatients" class="heading-size">Import Patients with Active Wounds</div>
            <div *ngIf="!importPatients">Do you want to add following Patients to your Census?</div>
        </div>
        <div style="height: 100%;">
            <table mat-table [dataSource]="dataSource" style="overflow: auto;display: block;height: 99%">
                                    <ng-container matColumnDef="checkbox">
                        <th mat-header-cell *matHeaderCellDef style="width: 1%; background-color: #cedbdf;">
                            <mat-checkbox [checked]="isAllSelected()"
                                (change)="$event ? masterToggle() : null"></mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let element; let i = index;" style="width: 1%;">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)=" patientSelected(element.patient_id, $event.checked)"
                                [checked]="rowSelected(element.patient_id._id)">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef style="width: 5%; background-color: #cedbdf;"> Patient Name </th>
                        <td mat-cell *matCellDef="let element; let i = index;" style="width: 5%;">
                            <ng-container>
                                {{ element.patient_id.last_name | titlecase }}, {{ element.patient_id.first_name | titlecase
                                }}
                                {{ (element.patient_id.middle_name ? element.patient_id.middle_name + '.' : '') |
                                titlecase}}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="dob">
                        <th mat-header-cell *matHeaderCellDef style="width: 5%; text-align: center; background-color: #cedbdf;"> DOB </th>
                        <td mat-cell *matCellDef="let element; let i = index;" style="width: 5%; text-align: center;">
                            {{element.patient_id.date_of_birth}}</td>
                    </ng-container>
                    <ng-container matColumnDef="dos">
                        <th mat-header-cell *matHeaderCellDef style="width: 5%; text-align: center; background-color: #cedbdf;"> Wound Last Assessment
                        Date
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index;" style="width: 5%; text-align: center;">
                        {{getWoundAssessmentDate(element.wounds)}}</td>
                </ng-container>
                <ng-container matColumnDef="last_seen">
                    <th mat-header-cell *matHeaderCellDef style="width: 5%; text-align: center;"> Last Seen </th>
                    <td mat-cell *matCellDef="let element; let i = index;" style="width: 5%; text-align: center;" [innerHTML]="getLastSeen(element.patient_id)">
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
        </div>
                <div class="flex flex-col items-center justify-center flex-grow">
            <div *ngIf="importPatients" class="flex-grow">
                <button class="dn-button-primary" mat-raised-button [disabled]="selectedPatientsToImport.length === 0"
                    (click)="importClick()"> Import Patients </button>
            </div>
            <div *ngIf="!importPatients" class="flex flex-grow gap-1">
                <button class="dn-button-primary" mat-raised-button (click)="importMultiplePatients()"> Confirm
                </button>
                <button mat-raised-button color="warn" class="dn-button-primary" (click)="cancel()"> Cancel </button>
            </div>
        </div>
    </div>
</div>