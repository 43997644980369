<div class="flex flex-col">
    <div class="selected-items" *ngIf="selectedCharges.length">
        <div cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListDisabled] = "charge?.status == 'submit'" >
            <div cdkDrag style="cursor: move;border: groove;border-width: 1px;" *ngFor="let element of selectedCharges; let i = index" class="flex gap-4 justify-between items-baseline" [ngStyle]="{ background: i % 2 == 0? '#f4f5f8': 'white'}">
                <mat-checkbox (change)="removeCharge(element, $event, 'cpt');" [checked]="true">
                    {{element.code}} <span>({{element.description}})</span>
                </mat-checkbox>
                <mat-form-field style="width:70px">
                    <mat-label>Modifier</mat-label>
                    <mat-select [name]="'modifier_' + element.code" #modifierRef [value]="getCptModifier(element)"
                        (selectionChange)="handleModifierChange(element, modifierRef.value)">
                        <mat-select-trigger>
                            {{modifierRef.value}}
                        </mat-select-trigger>
                        <mat-option *ngFor="let modifier of modifiers" [value]="modifier.code">({{modifier.code}})
                            {{modifier.description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div style="margin: 10px;" *ngIf="!model.measure">
        <mat-form-field style="width: 100%;height: 44px;margin-top: -10px;" appearance="fill">
            <mat-label>Search</mat-label>
            <input matInput placeholder="search charges" (keyup)="searchCharge($event.target.value)"
                [(ngModel)]="chargeFilter" name="chargeFilter" style="width: 100%;">
            <button mat-button mat-icon-button matSuffix (click)="clearSearchField();searchCharge('');"
                *ngIf="chargeFilter">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>

    <div class="flex-grow flex flex-col gap-5" *ngIf="!model.measure">
        <mat-tab-group (selectedTabChange)="onTabClickCharges($event)">
            <mat-tab label="Favorites">
                <ng-template matTabContent>
                    <span style=" width: 100%; height: 100%; background-color: rgba(81, 99, 99, 0.8);overflow: auto;">
                        <table mat-table [dataSource]="dataSourceFvrtCPTs" matSort cdkDropList
                            (cdkDropListDropped)="onListDropfvrtCPTs($event)">
                            <ng-container matColumnDef="checkbox">
                                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                                </th>
                                <td mat-cell *matCellDef="let element" style="width: 50px;">
                                    <mat-checkbox (change)="addToModel(element, $event, 'cpt');"
                                        [checked]="element.selected" name="cpt_fvrt">
                                    </mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="fvrt">
                                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                                </th>
                                <td mat-cell *matCellDef="let element" style="width: 50px;">
                                    <span
                                        (click)="$event.preventDefault();$event.stopPropagation();removeFavouriteCharges(element)"
                                        style="cursor: pointer" class="material-icons">
                                        star
                                    </span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="code">
                                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                                </th>
                                <td mat-cell *matCellDef="let element" (click)="addToModel(element, $event, 'cpt');"
                                    style="cursor: pointer;">
                                    {{element.code}}
                                    <span>({{element.description}})</span>
                                </td>
                            </ng-container>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag></tr>
                        </table>
                    </span>
                </ng-template>
            </mat-tab>
            <mat-tab label="All">
                <ng-template matTabContent>
                    <span style=" width: 100%; height: 100%; background-color: rgba(81, 99, 99, 0.8);overflow: auto;">
                        <table mat-table [dataSource]="dataSourceAllCPTs" matSort>
                            <ng-container matColumnDef="checkbox">
                                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                                </th>
                                <td mat-cell *matCellDef="let element" style="width: 50px;">
                                    <mat-checkbox (change)="addToModel(element, $event, 'cpt')"
                                        [checked]="element.selected" name="cpt_all">
                                    </mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="fvrt">
                                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                                </th>
                                <td mat-cell *matCellDef="let element" style="width: 50px;">
                                    <span *ngIf="element.fvrt === true"
                                        (click)="$event.preventDefault();$event.stopPropagation();removeFavouriteCharges(element)"
                                        style="cursor: pointer" class="material-icons">
                                        star
                                    </span>
                                    <span *ngIf="element.fvrt !== true" class="material-icons"
                                        (click)="$event.preventDefault();$event.stopPropagation();addFavouriteCharges(element)"
                                        style="cursor: pointer">star_outline</span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="code">
                                <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                                </th>
                                <td mat-cell *matCellDef="let element" (click)="addToModel(element, $event, 'cpt')"
                                    style="cursor: pointer;">
                                    {{element.code}}
                                    <span>({{element.description}})</span>
                                </td>
                            </ng-container>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
                    </span>
                    <div style="height: 5px;"></div>
                    <div class="flex gap-4 justify-center items-center table-nav">
                        <span style="background: white; border-radius: 2px; font-size: 12px;padding: 5px;">
                            {{getCountofCodes('chargesAll')}}</span>

                        <button mat-raised-button class="dn-button-primary"
                            [disabled]="!paginationChargesAll.hasNextPage" type="button"
                            (click)="handleNextClick('chargesAll')"> Next </button>
                    </div>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div style="margin: 0 10px">
        <mat-checkbox [(ngModel)]="model.measure" name="measure" (change)="changeReportMeasure($event.checked)">Report MIPS Measures</mat-checkbox>
    </div>
</div>