import { Component } from '@angular/core';
import {PusherService} from './services/pusher-service';
import {AppointmentDataService} from './components/appointment/service/data.service';
import { PCCService } from './services/pcc.service';
import {Router, Event, NavigationStart, NavigationEnd, NavigationError} from "@angular/router";
import { USER_TYPE } from './includes/global';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: []
})
export class AppComponent {
  title = 'angular-matrial-design';
  currentUser:any
  constructor(private pusherService: PusherService,
              private appointmentService: AppointmentDataService,
              private router: Router,
              private _pccService:PCCService,
              private _authService:AuthService) {
                 this.pusherService.subscribeVersionChannels();
                 this.currentUser=this._authService.currentUser || '';
   const eventsName = [`user_type_${this.currentUser.user_type}`];
    this.pusherService.subscribeNewReleaseChannel(eventsName);
    this.pusherService.user_type_notify.subscribe((pusherData) => {
      if (pusherData) {
        this.pusherService.newReleaseNotficationReceive.next(pusherData);
      }
    })
    this.pusherService.testPusher();
    this.appointmentService.testService();
    router.events.subscribe( (event: Event) => {

      if (event instanceof NavigationStart) {
        // Show loading indicator
        if (event.url === '/appointment') {
          this.appointmentService.goToParent(true, true);
        }
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });
    localStorage.setItem('eventStack', '0');
    if (localStorage.getItem('play') === null) {
      localStorage.setItem('play', 'false');
    }
    if (localStorage.getItem('pause') === null) {
      localStorage.setItem('pause', 'false');
    }
  }
  
}
