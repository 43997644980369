import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Observable, lastValueFrom } from "rxjs";
import { AuthService } from "src/app/services/auth.service";
import { FacilityService } from "src/app/services/facility.service";
import { WoundService } from "src/app/services/wound.service";
import { Location } from "@angular/common";
import { facilityPopulationInterface } from "src/app/facilityPopulationInterface";
import { SnfWcNurseAssociationService } from "src/app/includes/snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service";
import * as global from "../../../../global";
import { map, startWith } from "rxjs/operators";
import { NursingService } from "src/app/includes/nursing-module/nursing.service";
import { WoundNurseRelationService } from "src/app/services/Modules/woundNurse.service";
import { HttpResponse } from "@angular/common/http";
import { CommonService } from "src/app/services/common.service";

@Component({
    selector: "app-hp-operational-report",
    templateUrl: "./hp-operational-report.component.html",
    styleUrls: ["./hp-operational-report.component.css"]
})
export class HpOperationalReportComponent implements OnInit {
    currentDate = moment(new Date());
    minDate = moment(new Date()).subtract(6, "days");
    loaderId = "app-hp-operational-report";
    operationalReport: any = [];
    filter: any = {
        updationDate: {
            startDate: moment(),
            endDate: moment()
        },
        facilityId: null,
        companyId: null,
        state: "all",
        dateType: "last_assessment_date_obj",
        orientation: "landscape",
    };
    preDateRanges: any = {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 1 Week": [moment().subtract(6, "days"), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    };
    filteredFacilities: Observable<string[]>;
    displayedColumns: string[] = [
        "provider", // Last Name, First Name
        "facility", // Facility name
        "patient", //Patient Name
        "wound_no", // Wound No
        "photo_taken_date",
        "last_assement_date",
        "note_created_date",
        "note_signed_date",
        "charge_created_date",
        "charge_submitted_date"
    ];
    facilityControl = new FormControl();
    facilities: Array<any> = [];
    hpOperationalReport: any = [];
    dataSource: any = new MatTableDataSource([]);
    pagination = {
        pageNo: 1,
        hasNextPage: false,
        hasPrevPage: false,
        totalRecords: 0,
        PAGE_LIMIT: 50,
        skip: 0
    };
    companySide: boolean;
    currentUser: any;
    reportName = "Operational Report";
    base_url: string;
    nursingSide: boolean;
    user_dashboard: Boolean = false;
    isSnfNurse: boolean = false;
    isNurse: boolean = false;
    isWoundNurse: boolean = false;
    nurseAssociatedFacilities: Array<any> = [];
    facilityInputText: string = '';
    noDataMessage: string = '';
  
    

    constructor(
        private _toastr: ToastrService,
        private _facilityService: FacilityService,
        private _route: ActivatedRoute,
        private _location: Location,
        private _wound: WoundService,
        private loader: NgxUiLoaderService,
        private _authService: AuthService,
        private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
        private _nurseService: NursingService,
        private woundNurseService: WoundNurseRelationService,
        private commonService: CommonService
    ) {
        this.currentUser = this._authService.currentUser;
        this.filter.companyId = this._route.parent.snapshot.params.id;
        if (this._route.parent.snapshot.params.id) {
            this.companySide = true;
        } else {
            this.filter.companyId = this.currentUser.company_id;
        }
        if (window.location.pathname.includes("dashboard")) {
            this.user_dashboard = true;
        }
        if (window.location.pathname.includes("nursing")) {
            this.nursingSide = true;
        }
        if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
            this.isSnfNurse = true;
          }
          if (this.currentUser.user_type === global.USER_TYPE.NURSE) {
            this.isNurse = true;
          }
          if (this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
            this.isWoundNurse = true;
          }
    }

    async ngOnInit(): Promise<void> {
        await this.initFacilities();
        this.selectDefaultFacility();
        this.initHpOperationalReport();
        this.filteredFacilities = this.facilityControl.valueChanges.pipe(
            startWith(""),
            map((value: any) => this.filterFacilities(value))
        );
        this.loader.stopLoader(this.loaderId);
    }

    changeUpdationDate(event) {
        if (event.startDate && event.endDate) {
            this.filter.updationDate = {
                startDate: null,
                endDate: null
            };
            const noOfDays = this.commonService.getDifferenceOfDates(event.startDate,event.endDate,'days');
            if (noOfDays > 29) {
                this.filter.updationDate = {};
                this.filter.updationDate.startDate = moment(event.startDate);
                this.filter.updationDate.endDate = moment(
                    this.filter.updationDate.startDate
                )
                    .add(29, "days")
                    .endOf("day");
                this._toastr.error(
                    "Can not select more than 30 days",
                    "Date Error"
                );
            } else {
                this.filter.updationDate.startDate = event.startDate;
                this.filter.updationDate.endDate = event.endDate;
            }
        }
    }
    private filterFacilities(value: string): string[] {
        if (this.isSnfNurse || this.isNurse || this.isWoundNurse) {
            if (value) {
                const filterValue = value.toLowerCase();
                return this.nurseAssociatedFacilities.filter(
                    (option) =>
                        option.title.toLowerCase().indexOf(filterValue) > -1
                );
            } else {
                return this.nurseAssociatedFacilities;
            }
        } else {
            if (value) {
                const filterValue = value.toLowerCase();
                return this.facilities.filter(
                    (option) =>
                        option.title.toLowerCase().indexOf(filterValue) !== -1
                );
            } else {
                return this.facilities;
            }
        }
    }
    selectDefaultFacility() {
        if (this.isSnfNurse || this.isNurse || this.isWoundNurse) {
            if (this.nurseAssociatedFacilities.length > 0) {
                this.facilityControl.setValue(
                    this.nurseAssociatedFacilities[0].title
                );
                this.filter.facilityId = this.nurseAssociatedFacilities[0]._id;
                this.facilityControl.setValue(
                    this.nurseAssociatedFacilities[0].title
                );
            }
        } else {
            if (this.facilities.length > 0) {
                this.filter.facilityId = this.facilities[0]._id;
            }
            this.facilityControl.setValue(this.facilities[0].title);
        }
    }
    async initFacilities() {
        if (this.isSnfNurse) {
            const filter = {
                associated_snf_wc_nurse_ids: this.currentUser._id,
                company_id: this._authService.currentUser.company_id
            };
            const nurseProjection = {
                first_name: 1,
                last_name: 1,
                title: 1
            };
            const facilityProjection = {
                title: 1,
                pcc_facId: 1,
                pcc_2_legged_authentication: 1,
                source: 1
            };
            const response: any = await lastValueFrom(
                this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(
                    filter,
                    nurseProjection,
                    facilityProjection
                )
            );
            if (response.status === 200 && response.data.length > 0) {
                this.nurseAssociatedFacilities = response.data;
                this.nurseAssociatedFacilities.sort((a, b) =>
                    a.title.toLowerCase().localeCompare(b.title.toLowerCase())
                );
                this.facilities = this.nurseAssociatedFacilities;
            }
        } else if (this.isNurse) {
            const response: any = await lastValueFrom(
                this._nurseService.getAssociatedFacilities()
            );
            if (response.status === 200 && response.data.array.length > 0) {
                this.nurseAssociatedFacilities = response.data.array;
                console.log(
                    "this.nurseAssociatedFacilities",
                    this.nurseAssociatedFacilities
                );
                this.nurseAssociatedFacilities.sort((a, b) =>
                    a.title.toLowerCase().localeCompare(b.title.toLowerCase())
                );
                this.facilities = this.nurseAssociatedFacilities;
            }
        } else if (this.isWoundNurse) {
            const filter = {
                company_id_ref: this.filter.companyId,
                associated_wound_nurse_ids: this._authService.currentUser._id
            };
            const nurseProjection = {
                first_name: 1,
                last_name: 1,
                title: 1
            };
            const facilityProjection = {
                title: 1,
                pcc_facId: 1,
                pcc_2_legged_authentication: 1,
                source: 1
            };
            const facilitiesResponse: any = await lastValueFrom(
                this.woundNurseService.getWoundNurseFacilities(
                    filter,
                    nurseProjection,
                    facilityProjection
                )
            );
            if (facilitiesResponse.status === 200) {
                this.nurseAssociatedFacilities = facilitiesResponse.data;
                this.nurseAssociatedFacilities.sort((a, b) =>
                    a.title.toLowerCase().localeCompare(b.title.toLowerCase())
                );
                this.facilities = this.nurseAssociatedFacilities;
            }
        } else {
            const filter = {
                company_id: this.filter.companyId,
                is_active: true
            };
            const projection = {
                title: 1
            };
            const facilitiesResponse: any = await this._facilityService
                .getMyFacilities(this.currentUser._id)
                .toPromise();
            if (facilitiesResponse.status == 200) {
                this.facilities = this.sortTitleVise(
                    facilitiesResponse.data.array
                );
            }
        }
    }
    sortTitleVise(arrayTosort) {
        let facilities = arrayTosort;
        let result = facilities.sort(function (a, b) {
            if (a.title?.toLowerCase() < b.title?.toLowerCase()) {
                return -1;
            }
            if (a.title?.toLowerCase() > b.title?.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        return result;
    }
    onSelectFacility($event, facility) {
        if ($event.isUserInput) {
            this.filter.facilityId = facility._id;
        }
    }
    goBack() {
        this._location.back();
    }
    resetFilter() {
        delete this.filter.updationDate;
        this.filter.updationDate = {
            startDate: moment(),
            endDate: moment()
        };
        this.filter.orientation = "landscape";
        this.filter.dateType = "last_assessment_date_obj";
        this.filter.state = "all";
        this.selectDefaultFacility();
        this.initHpOperationalReport();
        this.resetPagination();
    }

    resetPagination() {
        this.pagination.hasNextPage = false;
        this.pagination.hasPrevPage = false;
        this.pagination.pageNo = 1;
        this.pagination.skip = 0;
        this.pagination.totalRecords = 0;
    }

    onStateChange(value) {
        this.filter.state = value;
    }
    handleApplyFilter() {

        this.facilityInputText = (<HTMLInputElement>document.getElementById('facilityControlInput')).value;

        if(this.facilityInputText==''){
            this._toastr.warning('Please select facility from list', 'Warning');
            return;
        }else{
          if (this.isSnfNurse) {
            const exists = this.nurseAssociatedFacilities.filter(option => option.title.toLowerCase().trim()==this.facilityInputText.toLowerCase().trim());
            if(!exists.length){
              this._toastr.warning('Please select facility from list', 'Warning');
              return;
            }
          }else{
            const exists = this.facilities.filter(option => option.title.toLowerCase().trim()==this.facilityInputText.toLowerCase().trim());
            if(!exists.length){
              this._toastr.warning('Please select facility from list', 'Warning');
              return;
            }
          }
        }
        this.pagination = {
            pageNo: 1,
            hasNextPage: false,
            hasPrevPage: false,
            totalRecords: 0,
            PAGE_LIMIT: 50,
            skip: 0
        };
        this.initHpOperationalReport();
    }
    exportDnsHealingReportAsXLSX(value) {}
    
    initHpOperationalReport() {
        this.loader.startLoader(this.loaderId);
        this.filter.pageNo = this.pagination.pageNo;
        
        this._wound
            .getHpOperationalReport(this.filter)
            .subscribe((response: any) => {
                if (response.status == 200) {
                        this.operationalReport = response.data.operationalReport;
                        this.dataSource.data = this.operationalReport; 
                        this.pagination.totalRecords = response.data.totalRecords;
                        this.handlePagination();
                        if(response.data.operationalReport.length == 0){
                            this._toastr.info("No data available on selected DOS");
                        }
                    
                }
                this.loader.stopLoader(this.loaderId);
            });
    }
    handlePagination() {
      this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
      if (this.pagination.skip + this.operationalReport.length < this.pagination.totalRecords) {
        this.pagination.hasNextPage = true;
      }
      else {
        this.pagination.hasNextPage = false;
      }
      if (this.pagination.skip > 0) {
        this.pagination.hasPrevPage = true;
      }
      else {
        this.pagination.hasPrevPage = false;
      }
    }
    goToPreviousPage() {
        this.pagination.pageNo--;
        this.initHpOperationalReport();
    }
    goToNextPage() {
        this.pagination.pageNo++;
        this.initHpOperationalReport();
    }
    getProviderName(element){
      return `${element.created_by.first_name}, ${element.created_by.last_name}`; 
    }
    getAnyDate(date){
      if(date){
        return moment(date).format("MM/DD/YYYY");
      }else{
        return '';
      }
    }
    getSignedDate(dateType, element){
      if(dateType === 'note'){
        if(element?.recentNote && element?.recentNote.is_signed === "true"){
          return moment(element?.recentNote.signed_date).format("MM/DD/YYYY");
        }
      }else{
        if(element?.recentCharge && element?.recentCharge.status === "submit"){
          return moment(element?.recentCharge.submit_date).format("MM/DD/YYYY");
        }
      }
    }
    getPhotoTakenDate(imageDates){
      if(Array.isArray(imageDates) && imageDates.length > 0){
        const imageDate = imageDates[0].split("_")[1];
        return imageDate
      }
    }
    getWoundAssessmentDate(element){
      if(element?.last_assessment_date_obj){
        let {year,month,day} = element.last_assessment_date_obj
        if (month < 10) month = `0${month}`;
        if (day < 10) day = `0${day}`;
        return `${month}/${day}/${year}`;
      }
    }

    async exportHpOperationalReportAsXLSX(){

        if(this.filter){
            if(this.operationalReport.length > 0){
                delete this.filter.pageNo
                const infoToastr = this._toastr.info('Exporting as CSV...', 'Please wait', { disableTimeOut: true });
                const response: any = await lastValueFrom(this._wound.exportHpOperationalReportAsXLSX(this.filter));
                    if (response instanceof HttpResponse) {
                    try {
                        const decodedData = String.fromCharCode.apply(null, new Uint8Array(response.body.data));
                        const blob = new Blob([decodedData], { type: 'text/csv' });
                        const url = window.URL.createObjectURL(blob);
            
                        const a = document.createElement('a');
                        document.body.appendChild(a);
                        a.setAttribute('style', 'display: none');
                        a.href = url;
                        a.download = `Operational Report`;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        a.remove();
                        this._toastr.success("Report Downloaded", "Success");
                    } catch (error) {
                        this._toastr.error("Error exporting report as CSV", "Error");
                    }
                    }
                        else {
                            console.log("err", response);
                            this._toastr.clear(infoToastr.toastId);
                        }
            }
            else{
                this._toastr.info("No data available on selected DOS");    
            }
    
          
        }
        else{
          this._toastr.error('Please select filter and project', 'Error');
        }
    
      }
}
