import { Component, Input, SimpleChanges, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { PhrasesService } from '../phrases.service';
import { DictionaryElement } from '../user-defined-phrases/user-defined-phrases.component';
import { CKEditorService } from '../../note-editor/ckeditor.service';

@Component({
  selector: 'app-system-defined-phrases',
  templateUrl: './system-defined-phrases.component.html',
  styleUrls: [ './system-defined-phrases.component.css' ],
})
export class SystemDefinedPhrasesComponent implements OnInit, OnChanges {
  dictionary: Array<DictionaryElement>;
  company_id: any;
  @Input() note: any;
  @Input() selectedSource: any = null;

  @Output() insertPhraseText: EventEmitter<any> = new EventEmitter();
  currentUser: any;
  searchText: String = '';
  keypressWait: any;
  displayPhrases = [];
  private allPhrases = [];

  constructor(
    private phrasesService: PhrasesService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private ckeditorService: CKEditorService
  ) {
    this.currentUser = this.authService.currentUser;
  }

  ngOnInit(): void {
    if ( window.location.pathname.includes( 'company' )) {
      this.company_id = this.route.snapshot.params.companyId;
    } else {
      this.company_id = this.currentUser.company_id;
    }

    if ( this.company_id )  {
      
      this.phrasesService.getPhrases( this.company_id ).subscribe(( dictionary: any ) => {
        this.allPhrases = Array.isArray( dictionary.data ) ? [ ...dictionary.data ] : [];

        this.sortPhrases();
        this.applyFilter();
      });
    } else if ( this.note?.provider?._id ) {
      this.phrasesService.getAssociatedPhrases( this.note.provider._id ).subscribe(( dictionary: any ) => {
        if ( dictionary.status == 200 ) {
          this.dictionary = dictionary.data.assoc_phrase_ids;
        }
      })
    }

    if ( this.dictionary?.length > 0 ) {
      this.sortDictionary( this.dictionary );
    }
  }

  applyFilter( sourceAsProp? ) {
    const
      source = sourceAsProp || this.selectedSource || null,
      allPhrases = ( source == null || source === '' ) ?
        this.allPhrases.slice() :
        this.phrasesService.filterPhrasesBySource( this.allPhrases, source ),
      filterValue = ( this.searchText || '' ).trim().toLowerCase();

    this.displayPhrases = allPhrases.filter( phraseObj => {
      if ( filterValue === '' ) {
        return true;
      }

      return phraseObj.key.toLowerCase().includes( filterValue );
    });
  }

  getMarker() {
    return this.phrasesService.marker;
  }

  sortPhrases() {
    this.allPhrases.sort(( a, b ) => a.key.toLowerCase().localeCompare(b.key.toLowerCase()));
  }

  sortDictionary( arr = [] ) {
    arr.sort((a, b) => a.key.toLowerCase().localeCompare(b.key.toLowerCase()));
  }

  applyPhrase( phraseObj ) {
    const
    editorInstance = this.ckeditorService.getEditorInstance(),
    phraseText = phraseObj.key ? `${ this.getMarker() }${ phraseObj.key }` : null;

  if ( editorInstance == null || phraseText == null ) {
      const phrase = {
      phrase: {
        id: phraseObj.key,
        key: phraseObj.key,
        type: "system-defined",
        value: undefined
      }
    }
    this.insertPhraseText.emit(phrase);
  }else{
    editorInstance.insertText( phraseText );
  }
}

  ngOnChanges( changes: SimpleChanges ) {
    if ( changes.selectedSource?.firstChange === false ) {
      this.applyFilter( changes.selectedSource.currentValue );
    }
  }
}
