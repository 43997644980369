<div class="container" style="display: flex; flex-direction: column !important">
    <mat-card style="margin-bottom: 7px;">
        <mat-card-content>
          <div class="pcc-patient-list flex-grow">
            <form [formGroup]="filterFormGroup" class="flex flex-row items-baseline justify-between gap-2"
                  style="height: 36px;">


                  <div class="patients-page flex gap-2 items-baseline" style="flex: 20%;">
                    <mat-form-field class="flex">
                      <mat-select  formControlName="providerId">
                          <input matInput placeholder="Search" [(ngModel)]="searchTextP" [ngModelOptions]="{standalone: true}"
                        style="color:#000;height: 40px;padding-left: 5px;" (keydown)="$event.stopPropagation()">
                        <mat-option value="none">--none--</mat-option>
                        <mat-option *ngFor="let provider of providers | searchProvider : searchTextP" [value]="provider">{{provider.first_name}}
                          {{provider.last_name}}, {{provider.title}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>


                <div class="patients-page flex gap-2 items-baseline" style="width: 20%;">
                  <mat-form-field class="flex">
                      <mat-select formControlName="facility"  >
                        <mat-option value="all">All Facilities</mat-option>
                        <input matInput placeholder="Search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
                          style="color:#000;height: 40px;padding-left: 5px;" (keydown)="$event.stopPropagation()">
                        <mat-option *ngFor="let facility of facilities | searchFacility : searchText" [value]="facility">
                          <app-facility-title [facility]="facility" [defaultSource]="PointClickCare"></app-facility-title>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>


                  <div class="flex flex-row items-baseline gap-2 justify-start" style="flex: 0 0 15%;">
                    <button *ngIf="showButton()" mat-raised-button class="dn-button-primary" style="margin-bottom: 12px; margin-left: 10px;"
                    (click)="addMultiplePatients(true)">Add Recurrent Patients to Census</button>
                
                    </div>


                    <div class="flex flex-row gap-2 items-baseline flex-1" style="flex: 45%;">                
                </div>

                </form>     
              </div>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-content>
            <app-census-calender  [data]="data" ></app-census-calender>
        </mat-card-content>
    </mat-card>
</div>


