import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from 'src/environments/api-url';
import * as global from '../../global';

@Injectable({
  providedIn: 'root'
})
export class PatientMedicalHistoryService {


  currentUser: any;
  constructor(private httpclient: HttpClient,
    private _authService: AuthService) {
    this.currentUser = this._authService.currentUser;
  }
  addNewHeaderinWoundDataSet(header_name: any) {
    var data = {
      owner_id: this.currentUser._id,
      company_id: this.currentUser.company_id,
      header: header_name
    }
    return this.httpclient.post(global.url + API_URL.PATIENT.addNewHeader, { data: data }).pipe();
  }
  removeHeaderfromWoundDataSet(_id) {
    return this.httpclient.post(global.url + API_URL.PATIENT.deleteHeader, { data: _id }).pipe();
  }
  addNewRowinWoundDataSet(id: any, row_item: any) {
    var data = {
      _id: id,
      item: row_item
    };
    return this.httpclient.post(global.url + API_URL.PATIENT.addNewRow, { data: data }).pipe();
  }
  removeRowfromWoundDataSet(header_id, row_id, isRemovingFreeText = false) {
    var data = {
      header_id: header_id,
      row_id: row_id,
      isRemovingFreeText: isRemovingFreeText
    };
    return this.httpclient.post(global.url + API_URL.PATIENT.deleteRow, { data: data }).pipe();
  }
  getAllDatasetinCompany() {
    var data = {
      owner_id: this.currentUser._id,
      company_id: this.currentUser.company_id
    }
    return this.httpclient.post(global.url + API_URL.PATIENT.getAllDataset, { data: data }).pipe();

  }
  updateHeader(new_header, header_id) {
    var data = {
      header_id: header_id,
      owner_id: this.currentUser._id,
      company_id: this.currentUser.company_id,
      new_header: new_header
    }
    return this.httpclient.post(global.url + API_URL.PATIENT.updateHeader, { data: data }).pipe();
  }
  updateRowinDataset(header_id, row_id, data_type, value) {
    var data = {
      header_id: header_id,
      owner_id: this.currentUser._id,
      company_id: this.currentUser.company_id,
      row_id: row_id,
      type: data_type,
      value: value
    }
    return this.httpclient.post(global.url + API_URL.PATIENT.updateRow, { data: data }).pipe();
  }


  getPatientMedicalHistory(patient_id) {
    var data = {
      patient_id
    }
    return this.httpclient.post(global.url + API_URL.PATIENT.getPatientMedicalHistory, { data: data }).pipe();
  }

  updatePatientMedicalHistory(patient_id, selected_data, is_deleted?) {
    var data = {
      patient_id,
      selected_data,
      is_deleted
    }
    return this.httpclient.post(global.url + API_URL.PATIENT.updatePatientMedicalHistory, { data: data }).pipe();
  }

}
