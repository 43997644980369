import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PCCService } from 'src/app/services/pcc.service';
import { PatientDetailService } from '../patient-detail.service';
import { CreateNonPccAllergyIntolerancesDialogComponent } from './create-non-pcc-allergy-intolerances-dialog/create-non-pcc-allergy-intolerances-dialog.component';

@Component({
  selector: 'app-non-pcc-allergy-intolerances',
  templateUrl: './non-pcc-allergy-intolerances.component.html',
  styleUrls: ['./non-pcc-allergy-intolerances.component.css']
})
export class NonPccAllergyIntolerancesComponent implements OnInit,OnDestroy {

  @Input('patient') patient;
  allergyIntolerances: any = [];
  dataSource: any = new MatTableDataSource([]);
  dialogSubscription: any;
  // @Input('pccOrgUuid') pccOrgUuid;
  constructor(private _pccService: PCCService,
    private _toastr: ToastrService,
    private loader: NgxUiLoaderService,
    private _patientDetailService: PatientDetailService,
    private dialog: MatDialog,
  ) { }
  pccOrgUuid: string;
  page = 1;
  displayedColumns = [
    "onsetDate",
    "allergen",
    "type",
    "reactionType",
    "actions"
  ];
  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalallergies: 0,
    totalPages: 0,
    currentRecords: 0
  };
  facilityTitleRegExp = "";
  PAGE_LIMIT = 1;

  loaderId = 'non-pcc-allergy-intolerances-loader';
  inprogress = false;

  ngOnInit(): void {
    this.initPatientAllergies();
    this._patientDetailService.castpatientAllergy.subscribe((patientAllergy: any) => {
      if (patientAllergy) {
        this.dataSource.data = [];
        this.allergyIntolerances.push(patientAllergy);
        this.dataSource.data = this.allergyIntolerances;
      }
    });
  }
  ngOnDestroy() {
    if (this.dialogSubscription) {
      this._patientDetailService.onChangePatientAllergy(null);
      this.dialogSubscription.unsubscribe();
    }
  }
  sortNotesByCreationDate(allergies) {
    //console.log("notes:",notes);
    var byDate = allergies.slice(0);
    //console.log("byDate:",byDate);
    byDate.sort(function (a, b) {
         return Date.parse(a.onset_date)  < Date.parse(b.onset_date)  ? 1 : Date.parse(a.onset_date)  > Date.parse(b.onset_date)  ? -1 : 0;
    });
    return byDate;
}
  initPatientAllergies() {
    this.loader.startLoader(this.loaderId);
    const payload = {
      pageNo: this.pagination.pageNo,
      patientId: this.patient._id
    };
    this._patientDetailService.getPatientAllergies(payload).subscribe((response: any) => {
      if (response.status === 200) {
        this.loader.stopLoader(this.loaderId);
        this.allergyIntolerances = response.data;
        this.allergyIntolerances = this.sortNotesByCreationDate(this.allergyIntolerances);
        this.pagination.totalallergies = response.totalCount;
        const skip = (this.pagination.pageNo - 1) * this.PAGE_LIMIT;
        if ((this.pagination.totalallergies === skip + this.allergyIntolerances.length) || this.allergyIntolerances.length === 0) {
          this.pagination.hasNextPage = false;
        }
        else {
          this.pagination.hasNextPage = true;
        }
        this.dataSource.data = this.allergyIntolerances;
      } else {
        this.loader.stopLoader(this.loaderId)
        this._toastr.error('Something went wrong while fetching Allergy Intolerances data');
      }
    }, (error) => {
      this._toastr.error(error, 'Something went wrong while fetching Allergy Intolerances data');
      this.loader.stopLoader(this.loaderId);
    }, () => {
      // this._loader.stopLoader(this.loaderId);
    });
  }

  deletePatientAllergy(id, index) {
    this._patientDetailService.deletePatientAllergies({ id }).subscribe((response: any) => {
      if (response.status == 200) {
        this._toastr.success("Patient Allergy Deleted", "Success");
        this.dataSource.data = [];
        this.allergyIntolerances.splice(index, 1);
        this.dataSource.data = this.allergyIntolerances;
      }
    }, err => {
      this._toastr.error(err, "ERROR");
    }
    );
  }

  openUpdatePaitentAllergyDialog(patientAllergyId, index) {
    let options: any = {
      width: '50%',
      height: '45vh',
      data: {
        patientId: this.patient._id,
        patientAllergyId: patientAllergyId
      }
    }
    this.dialogSubscription = this.dialog.open(CreateNonPccAllergyIntolerancesDialogComponent, {
      ...options,
    }).afterClosed().subscribe((allergy) => {
      if (allergy) {
        this.dataSource.data = [];
        this.allergyIntolerances[index] = allergy;
        this.dataSource.data = this.allergyIntolerances;
      }
    });
  }


  goToPreviousPage() {
    if (this.pagination.pageNo > 1) {
      this.pagination.pageNo = this.pagination.pageNo - 1;
      this.pagination.currentRecords = (this.pagination.pageNo - 1) * this.PAGE_LIMIT + this.allergyIntolerances.length;
      this.initPatientAllergies();
    }
    if (this.pagination.pageNo === 1) {
      this.pagination.hasPrevPage = false;
    }
    this.pagination.hasNextPage = true;
  }

  goToNextPage() {
    this.pagination.pageNo = this.pagination.pageNo + 1;
    this.pagination.currentRecords = (this.pagination.pageNo - 1) * this.PAGE_LIMIT + this.allergyIntolerances.length;
    if (this.pagination.currentRecords > this.pagination.totalallergies) {  //Page count should not exceed total limit
      this.pagination.currentRecords = this.pagination.currentRecords - (this.pagination.currentRecords - this.pagination.totalallergies);
    }
    if (this.pagination.currentRecords === this.pagination.totalallergies) {
      this.pagination.hasNextPage = false;
    }
    this.initPatientAllergies();
    this.pagination.hasPrevPage = true;
  }

}
