import { filter } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FacilityService } from 'src/app/services/facility.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { UserService } from 'src/app/services/Modules/user.service';
import { USER_TYPE } from '../global';
import * as usa_states from '../../shared/popup-dialogs/facility-add-dialog/usa_states';
import { CommonService } from 'src/app/services/common.service';
import moment from 'moment';
import { HttpResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-company-facility-association',
  templateUrl: './company-facility-association.component.html',
  styleUrls: ['./company-facility-association.component.css']
})
export class CompanyFacilityAssociationComponent implements OnInit {
  public response: any;
  public dataSource: any;
  public facilities: any = [];
  providers: any = [];
  providersName: any = [];
  selectedProviders: any = [];
  companyDropdownSettings: any = {};
  companySide: Boolean = false;
  companies: any = [];
  usa_states = usa_states.default;
  displayedColumns: string[] = ['facility', 'companies'];
  @ViewChild(MatSort) sort: MatSort;

  params: {
    id: string
  }
  filterStatus = true;

  loaderId = 'facility-company-association-loader';
  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalFacilities: 0,
    totalPages: 0,
    currentRecords: 0
  };
  facilityTitleRegExp = "";
  companyTitleRegExp = "";
  PAGE_LIMIT = 20;
  timeoutId: any;
  hasFacilitiesLoaded: boolean = false;
  hasCompaniesLoaded: boolean = false;
  companiesControl = new FormControl();
  previousData: any;
  sourceFilter: any = "all";
  constructor(
    public companyService: CompanyService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService,
    private facilityService: FacilityService,
    private userService: UserService,
    private router: Router,
    private commonService: CommonService
  ) {
    this.companyDropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: false,
    };
    if (window.location.pathname.includes('company')) {
      this.companySide = true
      this.params = {
        id: this.route.parent.snapshot.params.id
      };
    }
    else {
      this.companySide = false
      this.params = {
        id: ''
      };
    }
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId);
    await this.initCompanies();
    if (this.facilities.length > 1) {
      console.log('runnnn');
      this.updatePageCountForFirstPage();
    }
    this.loader.stopLoader(this.loaderId);
  }

  searchFacilities() {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.hasFacilitiesLoaded = true;
      this.initCompanies();
    }, 1000);
  }
  searchCompanies() {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.hasCompaniesLoaded = true;
      this.initCompanies();
    }, 1000);
  }

  updatePageCountForFirstPage() {
    console.log("this.pagination.totalFacilities", this.pagination.totalFacilities, this.pagination.currentRecords);
    if (this.pagination.currentRecords < this.pagination.totalFacilities) {
      this.pagination.hasNextPage = true;
      this.pagination.currentRecords = (this.pagination.pageNo - 1) * this.PAGE_LIMIT + this.facilities.length;
    }
  }

  async goToPreviousPage() {
    this.loader.startLoader(this.loaderId);
    this.pagination.totalFacilities = 0;
    this.pagination.currentRecords = 0;
    if (this.pagination.pageNo > 1) {
      this.pagination.pageNo = this.pagination.pageNo - 1;
      this.pagination.currentRecords = (this.pagination.pageNo - 1) * this.PAGE_LIMIT + this.facilities.length;
      await this.initCompanies(this.sourceFilter);
    }
    if (this.pagination.pageNo === 1) {
      this.pagination.hasPrevPage = false;
    }
    this.pagination.hasNextPage = true;
    this.loader.stopLoader(this.loaderId);
  }

  async goToNextPage() {
    this.loader.startLoader(this.loaderId);
    this.pagination.totalFacilities = 0;
    this.pagination.currentRecords = 0;
    this.pagination.pageNo = this.pagination.pageNo + 1;
    this.pagination.currentRecords = (this.pagination.pageNo - 1) * this.PAGE_LIMIT + this.facilities.length;
    if (this.pagination.currentRecords > this.pagination.totalFacilities) {  //Page count should not exceed total limit
      this.pagination.currentRecords = this.pagination.currentRecords - (this.pagination.currentRecords - this.pagination.totalFacilities);
    }
    if (this.pagination.currentRecords === this.pagination.totalFacilities) {
      this.pagination.hasNextPage = false;
    }
    await this.initCompanies(this.sourceFilter);
    this.pagination.hasPrevPage = true;
    this.loader.stopLoader(this.loaderId);
  }

  async initCompanies(source = "all") {
    const filter: any = {
      is_active: this.filterStatus,
      is_deleted: false,
      source,
      pageNo: this.pagination.pageNo
    }
    if (this.facilityTitleRegExp !== "") {
      filter.title = this.facilityTitleRegExp;
      delete filter.pageNo;
    }
    if (this.companyTitleRegExp !== "") {
      filter.companyTitle = this.companyTitleRegExp;
      delete filter.pageNo;
    }
    let response: any = await this.companyService.getCompaniesFacilityAssociation(filter).toPromise();
    if (response.status == 200) {
      this.pagination.totalFacilities = response.totalFacilities;
      this.facilities = response.data;
      this.previousData = JSON.parse(JSON.stringify(this.facilities));
      this.pagination.currentRecords = this.facilities.length;
      console.log("this.facilities : ", this.facilities);
      this.companies = response.companies;
      this.dataSource = new MatTableDataSource(this.facilities);
      this.hasFacilitiesLoaded = false;
      this.hasCompaniesLoaded = false;
      if (filter.title) { //update page count for title search
        this.pagination.currentRecords = this.facilities.length;
        this.pagination.hasNextPage = false;
        this.pagination.hasPrevPage = false;
      }
      else if (filter.pageNo) {
        console.log('updatePageCountForFirstPage');
        this.updatePageCountForFirstPage();
      }
    }
    else if (response.status == 403) {
      this.toastr.error(response.message, "ERROR");
    }
  }
  
  onCompanySelect(selectedCompanies: any, element: any): void {
    console.log('selectedCompanies', selectedCompanies);
    for(let fac of this.previousData) {
      if(fac._id == element._id) {
          fac.assoc_company_ids.push(selectedCompanies);
          let facility = element;
          let isCreatingRelation: boolean = true;
          let facilityAssociationDate: string = this.commonService.convertDateToStringforMoment(moment(), true);
          let dateObj: any = {};
          dateObj.company_id = selectedCompanies._id;
          dateObj.assoc_date = facilityAssociationDate;
          this.updateFacilityCompany(selectedCompanies, facility, isCreatingRelation, dateObj);
          this.toastr.success('Relation Updated!', 'Success');        
        // fac.assoc_company_ids = selectedCompanies;
      }
    }
    
    // const currentCompany = selectedCompanies.length > 0 ? selectedCompanies[0] : null;
    // console.log('Current Company:', currentCompany);
    // console.log('Element:', element);
    return;

    
  }

  onDataSelection($event) {
    // let providers = element.associated_provider_ids.map(a => a._id);
    // let idx = this.users.findIndex(user => user._id.toString == element._id.toString());
    // this.users[idx] = element;
    // this.dataSource.data = this.users;
    // if($event.data == 'selectAll') {
    //   this.onBOSelectAll($event.element.assos_providers, $event.element);

    // }
    // else if($event.data == 'unSelect') {
    //   this.onBODeSelectAll([], $event.element);
    // }
    // else {
      if(!$event.status) {
        this.onCompanyDeSelect($event.element, { company: this.params.id});
      }
      else {
        this.onCompanySelect($event.data, $event.element);
      }
    // }
  }

  onDataRemove($event) { 
    this.onCompanyDeSelect($event.element, { company: this.params.id});
  }

  onCompanyDeSelect($event, company) {
    let facility = $event;
    
    let isCreatingRelation: boolean = false;
    this.updateFacilityCompany(company, facility, isCreatingRelation);
    for(let fac of this.previousData) {
      if(fac._id == $event._id) {
        fac.assoc_company_ids = fac.assoc_company_ids.filter(comp => company._id != comp._id);
      }
    }
    this.toastr.success('Relation Updated!', 'Success');
  }

  updateFacilityCompany(company, facility, isCreatingRelation?, dateObj?) {
    const { assoc_company_ids } = facility;
    if (assoc_company_ids)
      return lastValueFrom(this.facilityService.updateFacilityCompany(facility._id, assoc_company_ids.map(p => p._id),  this.params.id, isCreatingRelation, dateObj));
    else
      return lastValueFrom(this.facilityService.updateFacilityCompany(facility._id, company.assoc_company_ids.map(p => p._id), this.params.id, isCreatingRelation, dateObj));
      

  }

  getCompanyNameById(companyId: any): string {
    const company = this.companies.find(c => c._id.toString() === companyId.toString());
    return company ? company.name : '';
  }

  compareCompanies(company1: any, company2: any): boolean {
    return company1 && company2 ? company1._id === company2._id : company1 === company2;
  }


  async applySourceFilter($event) {
    this.loader.startLoader(this.loaderId);
    this.sourceFilter = $event.value;
    this.facilities = [];
    this.pagination.currentRecords = 0;
    this.pagination.pageNo = 1;
    this.pagination.hasNextPage = false;
    this.pagination.hasPrevPage = false;
    await this.initCompanies(this.sourceFilter);
    // this.updatePageCountForFirstPage();
    this.loader.stopLoader(this.loaderId);
  }
  downloadAsXLSX() {
    this.loader.startLoader(this.loaderId);
    const filter: any = {
      // is_active: this.filterStatus,
      source: this.sourceFilter,
      // is_deleted: false 
    }
    if (this.facilityTitleRegExp !== "") {
      filter.title = this.facilityTitleRegExp;
    }
    if (this.companyTitleRegExp !== "") {
      filter.companyTitle = this.companyTitleRegExp;
    }
    this.companyService.exportCompaniesFacilityAssociationAsXLSX(filter).subscribe((response: any) => {
      if (response instanceof HttpResponse) {
        this.loader.stopLoader(this.loaderId);
        var url = window.URL.createObjectURL(response.body);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `Facility-Company Association`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }
    });
  }
}
