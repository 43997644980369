import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit {
  activatedRoute:string = 'dictations'

  currentUser:any;
  constructor(
    private _router:Router, 
    private _route:ActivatedRoute, 
    private router: Router) { 
    this.activatedRoute = this._route.snapshot.firstChild.routeConfig.path;
    const currentUser = localStorage.getItem('currentUser');
    if(currentUser) {
        this.currentUser = JSON.parse(currentUser);
    }
  }

  ngOnInit() {
    if(!this.currentUser || this.currentUser && (this.currentUser.admin_type !== 'full_admin' || this.currentUser.admin_type !== 'system_admin')) {
      this.router.navigate(['/dashboard']);
      return;
    } else if(!this.currentUser) {
      localStorage.removeItem('currentUser');
      this.router.navigate(['/login']);
      return;
    }
    this.router.events.subscribe(e => {  
      if(e instanceof NavigationEnd){
        if(!this.currentUser || this.currentUser && (this.currentUser.admin_type !== 'full_admin' || this.currentUser.admin_type !== 'system_admin')) {
          this.router.navigate(['/dashboard']);
          return;
        } else if(!this.currentUser) {
          localStorage.removeItem('currentUser');
          this.router.navigate(['/login']);
          return;
        }
      }
    });
  }

  changeRoute() {
    this._router.navigate([`${this.activatedRoute}`], { relativeTo: this._route })
  }
}
