<div class="flex flex-col">
    <div class="flex flex-row items-center"  style="padding-left: 5px;margin-top: 9px !important;display: flex; align-items: center;">
        <mat-icon style="cursor: pointer;margin-right: 11px !important;" (click)="goBack()">close</mat-icon>
        <h1 style="display: inline-block;font-size: 22px; margin: 0px;" class="family-style">
            {{rowData.title}}
        </h1>
    </div>
    <div class="flex items-center justify-between" style="padding-right: 10px;padding-left: 10px;margin-top: 20px !important;">
        <span style="font-size: 13px;margin-right: 79px !important;">{{rowData.updatedAt | date}}</span>
        <span style="font-size: 13px;margin-right: 57px !important;">{{rowData.status}}</span>
        <span style="font-size: 13px;margin-left: 30px !important;"
            [ngClass]="{'lowPriority':rowData.priority === 'Low','mediumPriority':rowData.priority === 'Medium','highPriority':rowData.priority === 'High','criticalPriority':rowData.priority === 'Critical'}">{{rowData.priority}}</span>
    </div>

    <div class="flex flex-row">
        <div style="margin-top: 20px;">
            <span>
                <b style="padding-left: 10px; font-size: 12px;">Patient Name: </b>
                <span style="font-size: 12px; padding-left: 5px;" [innerHtml]="rowData.patient_name"></span>
            </span>
        </div>
    </div>

    <div style="margin-top: 20px;word-break: break-all;" class="flex flex-col">
        <span style="display: inline-block; margin: 0px;font-size: 12px;font-weight: bold;">
            <i style="padding-left: 10px;">Description</i>
        </span>
        <span style="font-size: 12px;padding-right: 10px;padding-left: 10px;" [innerHtml]="rowData.description">
        </span>
    </div>

    <div
        style="background-color: #44597d;width: 100.7%;color: #fff;padding: 0px 0px 4px 10px;margin: 0px 0px 10px -10px;">
        <span style="font-size: 11px;margin-left: 10px;">Comments</span>
    </div>
            <app-ticket-comments (edit)="comments=$event.comment; editComment=$event" [rowData]="rowData">
            </app-ticket-comments>
    <div class="flex flex-row" style="position: fixed;bottom: 0px;">
        <form (ngSubmit)="postComments()" #commentForm="ngForm" style="width: 100%;">
            <div style="background-color: white;width: 382px; padding: 5px;" class="flex flex-col">
                <div class="flex flex-row">
                    <mat-form-field class="example-full-width flex-grow" style="color: black !important;" 
                        appearance="fill">
                        <input matInput type="text" name="comment" [(ngModel)]="comments" placeholder="Add a comment">
                    </mat-form-field>
                </div>
                <div class="flex flex-row justify-end items-center set-margins">
                    <button class="dn-button-primary" type="submit" style="width: 70px;margin-bottom: 5px;" [disabled]="comments=='' || comments==undefined"
                        mat-raised-button>Post</button>
                </div>
            </div>
        </form>
    </div>
</div>