import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from 'src/environments/api-url';
import * as global from '../../includes/global';
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ReportModuleService {

  currentUser: any;

  constructor(
    private http: HttpClient, 
    private authService: AuthService
  ) {
    this.currentUser = this.authService.currentUser
   }

   getMipReportMipGrouped(filter) {
    const details = {
      ...this.authService.authObject,
      filter,
    };
    return this.http.post(global.url + API_URL.MIPS.getMipReportMipGrouped, {details});
  }
  getProviderGroupedMip(filter, measure_id, mipType) {
    const details = {
      ...this.authService.authObject,
      filter,
      measure_id,
      mipType
    };
    return this.http.post(global.url + API_URL.MIPS.getProviderGroupedMip, {details});
  }
  getP360Response(answer_id, p360Status) {
    const details = {
      ...this.authService.authObject,
      answer_id, 
      p360Status
    }
    return this.http.post(global.url + API_URL.MIPS.getP360Response, {details});
  }
  getPatientOpportunities(filter){
    return this.http.post(global.url + API_URL.PATIENT.getPatientOpportunities, {filter});
  }
  getCompanyAuditReport(filter) {
    return this.http.post(global.url + API_URL.CHARGE.getCompanyAuditReport, {filter});
  }

}
 