<div class="h100 flex flex-col justify-between">
    <table class="flex-grow" mat-table
           [dataSource]="chunks">
        <ng-container matColumnDef="text">
            <th mat-header-cell *matHeaderCellDef> Text </th>
            <td mat-cell *matCellDef="let element"
                (blur)="updateChunk(element,$event)"
                contenteditable="true">
                {{element.transcript}}
            </td>
        </ng-container>

        <ng-container matColumnDef="start">
            <th mat-header-cell *matHeaderCellDef> Start </th>
            <td mat-cell *matCellDef="let element">
                {{element.start_time | number: '1.0-0'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef> Audio </th>
            <td mat-cell *matCellDef="let element">
                <audio src="{{element.chunk_path}}" controlsList="nodownload" controls></audio>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th  mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
                <div [id]="element._id" class="flex gap-5 items-center">
                    <i class="icon icon-play"
                       (click)="playChunk(element.start_time, element.duration, element)"
                       matTooltip="Play" matTooltipPosition="above"></i>
                    <i class="icon icon-pause" style="display: none; cursor: none"
                       matTooltip="Pause" matTooltipPosition="above"></i>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
