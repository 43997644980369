
<div class="flex flex-col gap-2">

    
    <div *ngIf="!isEdit" class="flex justify-center items-center">
        <h1 mat-dialog-title style="color: black">Add Question </h1>
    </div>
    <div *ngIf="isEdit" class="flex justify-center items-center">
        <h1 mat-dialog-title style="color: black">Edit Question </h1>
    </div>

    <div class="flex gap-4">
        <div class="flex justify-between items-baseline flex-grow gap-2" style="height: 35px;"> 
            <span style="font-size: 12px"> 
                Question:
            </span>
            <input matInput class="flex-grow" style="border: none; border-bottom: ridge;" 
            type="text" name="question" [(ngModel)]="model.question" required   /> 
        </div> 
        
        <div class="flex justify-between items-baseline gap-2" style="height: 35px;"> 
            <span style="font-size: 12px"> 
                Answer/Code:
            </span>
            <input matInput class="w-full">
        </div> 
        <div class="flex justify-between items-baseline gap-2" style="height: 35px;"> 
            <span style="font-size: 12px; flex: 1 0 106px;"> 
                Measure Number:
            </span>
                <mat-select *ngIf="!measure_number" [(ngModel)]="model.measure_number" required style="width: 104px;border: none; border-bottom: ridge;" >
                    <mat-option *ngFor="let measure_number of getMeasureNumbers()" [value]="measure_number">{{measure_number}}</mat-option>
                </mat-select>
                <input style="width: 104px;border: none; border-bottom: ridge;" *ngIf="measure_number" matInput type="text" readonly [value]="model.measure_number">
        </div> 
        
    </div>


    <div class="flex gap-4">
        <div class="flex justify-between items-baseline gap-2 flex-grow" style="height: 35px;"> 
            <span style="font-size: 12px"> 
                Data Source:
            </span>
            <input matInput class="flex-grow" style="border: none; border-bottom: ridge;" 
            type="text" name="data_source" [(ngModel)]="model.data_source" placeholder="optional"  /> 
        </div> 
        <div class="flex justify-between items-baseline gap-2 flex-grow"  style="height: 35px;"> 
            <span style="font-size: 12px"> 
                Condition:
            </span>
            <input matInput class="flex-grow" style="border: none; border-bottom: ridge;" 
            type="text" name="condition" [(ngModel)]="model.condition" placeholder="optional"  /> 
        </div> 
        <div class="flex justify-between items-baseline gap-4 flex-grow"  style="height: 35px;"> 
            <span style="font-size: 12px; flex: 1 0 106px;"> 
                Performance Type:
            </span>
                <mat-select [(ngModel)]="model.performance_type" class="flex-grow" 
                    style="width: 104px;border: none; border-bottom: ridge;" >
                    <mat-option value="">{{"---"}}</mat-option>
                    <mat-option value="Performance Met">{{"Performance Met"}}</mat-option>
                    <mat-option value="Performance Not Met">{{"Performance Not Met"}}</mat-option>
                    <mat-option value="Denominator Exclusion">{{"Denominator Exclusion"}}</mat-option>
                    <mat-option value="Denominator Exception">{{"Denominator Exception"}}</mat-option>
                </mat-select>
        </div> 
    </div>

    <div  class="flex gap-5" style="width: 100%; height: 35px; text-align: right;"> 
        <button mat-raised-button class="dn-button-secondary" mat-dialog-close>Cancel</button>
       <button mat-raised-button class="dn-button-primary" [mat-dialog-close]="model" [disabled]="!isValid()">Save</button>
    </div> 
 
</div>

<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>