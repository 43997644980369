import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as global from '../global';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from "../../../environments/api-url";

@Injectable({
  providedIn: 'root'
})
export class NoteAgingReportService {
  currentUser: any;

  public constructor(
    private httpclient: HttpClient,
    private _authService: AuthService,
  ) {
    this.currentUser = this._authService.currentUser;
  }

  getFacilityAssocProviderNotes(filter: any) {  
    return this.httpclient.post(global.url + API_URL.FACILITY.getFacilitySignedNotes, filter).pipe();
  }

}
