<form #form="ngForm" (submit)="handleSubmit(form)">
    <div class="flex flex-col">
        <mat-form-field class="flex-1">
            <mat-label>Code</mat-label>
            <mat-select [(ngModel)]="newCPTmodel.code" #newcpt_code="ngModel" name="code" required>
            <input  placeholder="Search" [(ngModel)]="searchTextCode" [ngModelOptions]="{standalone: true}"
                style="color:#000;height: 40px;padding-left: 5px;border: none;width: 100%;"/>
            <mat-option value="" [disabled]="true"> --Select-- </mat-option>
              <mat-option *ngFor="let cpt of cpts | searchCPTCode: searchTextCode" [value]="cpt.code">{{cpt.code}}  ({{cpt.description}})</mat-option>
            </mat-select>
            <mat-error *ngIf="newcpt_code.invalid">
                Please Enter Code
            </mat-error>
          </mat-form-field>
          <mat-form-field class="flex-grow" appearance="fill">
            <mat-label>Description</mat-label>
            <input matInput placeholder="Enter Description" [(ngModel)]="newCPTmodel.description"
                #newcpt_description="ngModel" name="description" (keypress)="keyPressAlphanumeric($event)" required />
            <mat-error *ngIf="newcpt_description.invalid">
                Please Enter Description
            </mat-error>
        </mat-form-field>
        <mat-form-field class="flex-grow" appearance="fill">
            <mat-label>Place of Service</mat-label>
            <input matInput placeholder="Enter Place of Service" [(ngModel)]="newCPTmodel.place_of_service"
                #newcpt_place_of_service="ngModel" name="place_of_service" (keypress)="keyPressAlphanumeric($event)"  />
            <mat-error *ngIf="newcpt_place_of_service.invalid">
                Please Enter Place of Service
            </mat-error>
        </mat-form-field>

        <div class="flex justify-end gap-5" style="margin-top: 25px;">
            <button type="button" mat-raised-button class="dn-button-secondary" (click)="clearDetails()">
                Clear Details
            </button>
            <button mat-raised-button class="dn-button-primary" type="submit" [disabled]="form.invalid">
                Add New Code
            </button>
        </div>
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</form>


<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>