<form #form="ngForm" (ngSubmit)="handleSubmit(form)">
        <div class="flex gap-12">
            <div class="flex flex-col" style="width:30%;">
                <mat-form-field class="field-item">
                    <mat-label>Select Company</mat-label>
                    <mat-select [(ngModel)]="model.company_id" name='company' disabled
                        (selectionChange)="updateFacilities($event.value)">
                        <mat-option *ngFor="let company of companies" [value]='company._id'>{{company.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                
                <mat-form-field class="field-item">
                    <mat-label>User type</mat-label>
                    <mat-select (selectionChange)="changeUserType($event.value)" [(ngModel)]="model.user_type" name="user_type" required>
                        <mat-option value="provider" *ngIf="currentCompany.company_type !== global.COMPANY_TYPE.SNF">
                            Provider
                        </mat-option>
                        <mat-option value="transcriber" *ngIf="company_type === global.COMPANY_TYPE.NURSING">
                            Transcriber
                        </mat-option>
                        <mat-option value="nurse" *ngIf="currentCompany.company_type !== global.COMPANY_TYPE.SNF">
                            Nurse
                        </mat-option>
                        <mat-option value="business_officer" *ngIf="isOptionVisibleForCompany('business_officer')">
                            Business Officer
                        </mat-option>
                        <mat-option value="biller" *ngIf="currentCompany.company_type !== global.COMPANY_TYPE.SNF">
                            Biller
                        </mat-option>
                        <mat-option value="medical_assistant">
                            Medical Assistant
                        </mat-option>
                        <mat-option [value]="USER_TYPE.SNF_WC_NURSE" *ngIf="currentCompany.company_type === global.COMPANY_TYPE.SNF">
                            SNF WC Nurse
                        </mat-option>
                        <mat-option [value]="USER_TYPE.WOUND_NURSE" *ngIf="currentCompany.company_type !== global.COMPANY_TYPE.SNF">
                            Wound Nurse
                        </mat-option>
                        <mat-option [value]="USER_TYPE.VIEW_ONLY_USER">
                            View Only User
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="!form.value.user_type">Please select user type</mat-error>
                </mat-form-field>

                <mat-form-field class="field-item" *ngIf="model.user_type === 'provider'">
                    <mat-label>Sub Provider type</mat-label>
                    <mat-select (selectionChange)="changeUserType($event.value)" [(ngModel)]="model.user_type" name="sub_provider_type">
                        <mat-option value="test_provider">
                            Test Provider
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="!form.value.sub_provider_type">Please select sub provider type</mat-error>
                </mat-form-field>

                <mat-form-field class="field-item">
                    <mat-label>Title</mat-label>
                    <input matInput type="text" [(ngModel)]="model.title" name="title" required
                        [matAutocomplete]="titleAutoComplete" style="cursor: pointer;">
                    <mat-icon matSuffix style="cursor: pointer;">keyboard_arrow_down</mat-icon>
                    <mat-autocomplete [displayWith]="displayProviderFn" #titleAutoComplete="matAutocomplete">
                        <mat-option *ngFor="let title of titles" [value]="title">
                            {{title}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="!form.value.title">Please select title</mat-error>
                </mat-form-field>

                <mat-form-field class="field-item">
                    <input matInput type="email" placeholder="Email address" [(ngModel)]="model.email" name="email" required pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"/>
                    <mat-error *ngIf="!form.value.email">Please enter valid email address</mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="isIRCCompany && model.user_type == 'provider'" class="field-item">
                    <input matInput placeholder="Unique ID" [(ngModel)]="model.trueRCMID" name="trueRCMID" required/>
                    <mat-error *ngIf="!form.value.trueRCMID">Please enter Unique Id</mat-error>
                </mat-form-field>
                <mat-form-field class="field-item">
                    <input matInput placeholder="NPI" [(ngModel)]="model.npi" name="npi" [required]="isMandatoryNPI"
                        onkeypress="return (event.charCode > 47 && event.charCode < 58) || (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)" />
                    <mat-error *ngIf="!form.value.npi">Please enter NPI</mat-error>
                </mat-form-field>
                <mat-form-field class="field-item">
                    <input matInput placeholder="TIN" [(ngModel)]="model.tin" name="tin" [readonly]="true" />
                </mat-form-field>
                <br>

            </div>

            <div class="flex flex-col" style="width:30%;">

                <mat-form-field class="field-item">
                    <input matInput placeholder="First Name" [(ngModel)]="model.first_name" name="first_name"
                        required  onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"/>
                    <mat-error *ngIf="!form.value.first_name">Please enter first name</mat-error>
                </mat-form-field>

                <mat-form-field class="field-item">
                    <input matInput placeholder="Last Name" [(ngModel)]="model.last_name" name="last_name" required onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"/>
                    <mat-error *ngIf="!form.value.last_name">Please enter last name</mat-error>
                </mat-form-field>

                <mat-form-field class="field-item">
                    <input matInput #cc_email="ngModel" type="email" placeholder=" Cc Email address"
                        [(ngModel)]="model.cc_email" name="cc_email"
                        pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$" [required]="model.cc_email.length>0" />
                    <mat-error *ngIf="cc_email.invalid && model.cc_email.length>0">Please enter valid cc email address
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="field-item">
                    <mat-label>Select Country</mat-label>
                    <mat-select (selectionChange)="countryFilter($event.value)" required [(ngModel)]="model.country" name="country">
                        <mat-option *ngFor="let t of Country" 
                        value="{{t}}">{{t}}
                    </mat-option>
                    </mat-select>
                    <mat-error *ngIf="!form.value.country">Please select Country</mat-error>
                </mat-form-field>
                <mat-form-field class="field-item">
                    <input matInput type="text" id="mobile" name="mobile" #mobile="ngModel" [(ngModel)]="model.mobile"
                    (ngModelChange)="handlePhoneModelChange($event)" (paste)="onPaste($event)" [maxlength]="maxMobileLength"
                    placeholder="Mobile Number" required (keypress)="keyPressNumbers($event)" [disabled]="!selectedCountry">
                <mat-error *ngIf="mobile.invalid && (mobile.dirty || mobile.touched)">Please enter valid mobile
                </mat-error>
                </mat-form-field>
                <br>
                <br> <br>
                
            </div>
        </div>
        
        <div class="flex justify-center gap-5">
            <div style="width:30%;">
                <mat-tab-group *ngIf="user_id === undefined && (model.user_type === 'provider' || model.user_type === 'nurse' || model.user_type === 'snf_wc_nurse' || model.user_type === 'wound_nurse')" style="margin-top: 13px!important;">
                    <mat-tab label="All Facilities">
                        <ng-template matTabContent>
                            <span style=" width: 100%; height: 100%">
                                <div class="flex justify-center items-center gap-2" style="padding: 0px 0px 0px 15px;">
                                    <mat-checkbox (change)="selectAllFacilities($event)" name="allFacilites"
                                        [(ngModel)]="selectAllFacility" [value]="-1"> Select All
                                    </mat-checkbox>
                                    <mat-form-field appearance="fill" style="width: 100%;">
                                        <mat-label>Search Facilities</mat-label>
                                        <input matInput (input)="applyFilter2($event.target.value);searchValue=$event.target.value">
                                    </mat-form-field>
                                </div>
                                <table mat-table [dataSource]="facilities" matSort>
                                    <ng-container matColumnDef="checkbox">
                                        <th class="header-data" mat-header-cell *matHeaderCellDef
                                            mat-sort-header-container> </th>
                                        <td mat-cell *matCellDef="let element" style="width: 50px;">
                                            <mat-checkbox (change)="addFacilities(element)"
                                                [checked]="element.selected ? element.selected : false"
                                                name="facilites">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="facility">
                                        <th class="header-data" mat-header-cell *matHeaderCellDef
                                            mat-sort-header-container> </th>
                                        <td mat-cell *matCellDef="let element" (click)="addFacilities(element)"
                                            style="cursor: pointer;">
                                            <app-facility-title [facility]="element"></app-facility-title>
                                        </td>
                                    </ng-container>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>
                            </span>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <div style="width:30%;">
                <mat-tab-group style="margin-top: 13px" *ngIf="user_id === undefined && (model.user_type === 'provider' || model.user_type === 'nurse' || model.user_type === 'snf_wc_nurse' || model.user_type === 'wound_nurse')">
                    <mat-tab label="Selected Facilities" style="justify-content: center;">
                        <ng-template matTabContent>
                            <span style=" width: 100%; height: 100%;overflow: auto;">
                                <table mat-table [dataSource]="dataSource2" matSort>
                                    <ng-container matColumnDef="checkbox">
                                        <th class="header-data" mat-header-cell *matHeaderCellDef
                                            mat-sort-header-container> </th>
                                        <td mat-cell *matCellDef="let element" style="width: 50px;">
                                            <mat-checkbox (change)="removeFromSelectedFacilities(element)"
                                                [checked]="element.selected" name="selectedfacilites">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="facility">
                                        <th class="header-data" mat-header-cell *matHeaderCellDef
                                            mat-sort-header-container> </th>
                                        <td mat-cell *matCellDef="let element"
                                            (click)="removeFromSelectedFacilities(element)" style="cursor: pointer;">
                                            {{element.title}}
                                        </td>
                                    </ng-container>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>
                            </span>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <div style="width:30%;">
                <mat-tab-group style="margin-top: 13px" *ngIf="model.user_type != ''" [(selectedIndex)]="selectedTab">
                    <mat-tab label="Company Phrases" style="justify-content: center;">
                        <ng-template matTabContent>
                            <span style=" width: 100%; height: 100%;overflow: auto;">
                                <div class="flex justify-center items-center gap-2" style="padding: 0px 0px 0px 15px;">
                                    <mat-checkbox (change)="selectAllPhrases($event)" name="allPhrases"
                                        [(ngModel)]="masterSelected" [value]="-1"> Select All
                                    </mat-checkbox>
                                    <mat-form-field appearance="fill" style="width: 100%;">
                                        <mat-label>Search Phrases</mat-label>
                                        <input matInput (input)="filterPhrases($event.target.value);searchValue=$event.target.value">
                                    </mat-form-field>
                                    <mat-form-field style="color: black !important;width :100%;">
                                        <mat-label *ngIf="company_type != global.COMPANY_TYPE.SNF">Select Provider</mat-label>
                                        <mat-label *ngIf="company_type === global.COMPANY_TYPE.SNF">Select SNF WC NURSE</mat-label>
                                        <mat-select [value]="selectedProvider?._id" name="type">
                                            <input matInput placeholder="Search" [(ngModel)]="searchText"
                                                [ngModelOptions]="{standalone: true}" style="color:#000;height: 50px;padding-left: 5px;"
                                                (keydown)="$event.stopPropagation()">
                                            <mat-option *ngFor="let provider of providers | searchProvider : searchText"
                                                [value]="provider._id" (onSelectionChange)="onProviderClick($event, provider);">
                                                {{provider.first_name}} {{provider.last_name}}, {{provider.title}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <table mat-table [dataSource]="dataSourcePhrases" matSort>
                                    <ng-container matColumnDef="checkbox">
                                        <th class="header-data" mat-header-cell *matHeaderCellDef
                                            mat-sort-header-container> </th>
                                        <td mat-cell *matCellDef="let element" style="width: 50px;">
                                            <mat-checkbox (change)="addToPhraseArray(element,$event)"
                                                [checked]="element.select" name="selectedphrases">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="phrase">
                                        <th class="header-data" mat-header-cell *matHeaderCellDef
                                            mat-sort-header-container> </th>
                                        <td mat-cell *matCellDef="let element"
                                            style="cursor: pointer;">
                                            {{ element?.key ? getMarker() + element.key : '' }} 
                                        </td>
                                    </ng-container>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsForPhrases;"></tr>
                                </table>
                            </span>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
    <br />
    <div class="flex justify-end gap-5">
        <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
            Go Back
        </button>
        <button mat-raised-button class="dn-button-primary"
            [disabled]="!form.valid" type="submit">
            Save
        </button>
    </div>
</form>