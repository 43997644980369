import { Component, OnInit } from '@angular/core';
import { FacilityService } from 'src/app/services/facility.service';
import { MatTableDataSource } from '@angular/material/table';
import * as usa_states from '../../../shared/popup-dialogs/facility-add-dialog/usa_states';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as global from '../../../includes/global'
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AssociatedProviderDialogComponent } from '../associated-provider-dialog/associated-provider-dialog.component';
import { HttpResponse } from '@angular/common/http';
import { AssociatedCompaniesDialogComponent } from '../associated-companies-dialog/associated-companies-dialog.component';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';

@Component({
  selector: 'app-company-facilities',
  templateUrl: './company-facilities.component.html',
  styleUrls: ['./company-facilities.component.css'],
})

export class CompanyFacilitiesComponent implements OnInit {
  currentUser: any;
  usa_states = usa_states.default;
  facilities: any[] = [];
  activeAlphabeticBarIndex = 'All';
  // displayedColumns: any = ['facility','fac_id','is_active','address', 'city', 'state', 'zip_code', 'provider', 'pcc_2_legged_authentication', 'charge_capture', 'note_email', 'association_date', 'truercm', 'operations'];
  displayedColumns: any = ['facility', 'fac_id', 'is_active', 'address', 'city', 'state', 'zip_code', 'provider', 'pcc_2_legged_authentication', 'charge_capture', 'note_email', 'association_date', 'operations'];
  dataSource: any;
  companies: any = [];
  filteredCompanies: Observable<string[]>;
  companyControl = new FormControl();
  loaderId = 'company-facilities-loader';
  params: {
    id: string
  }
  filterStatus: any = "true";
  currentCompany: any;
  showFacilityActivationEmailComponent: boolean = false;
  facilityTitleRegExp = "";
  PAGE_LIMIT = 20;
  timeoutId: any;
  hasFacilitiesLoaded: boolean = false;
  selectedSource: string = 'all';
  selected: string = 'all';
  recordsLoaded: Boolean = true;
  merged: Boolean = false;
  showEmailButton: boolean = true;
  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    PAGE_LIMIT: 20,
    skip: 0
  };
  trueRcmStatus: any = "all";
  defaultTabIndex: Number = 0;

  constructor(
    private companyService: CompanyService,
    private facilityService: FacilityService,
    private route: ActivatedRoute,
    private loader: NgxUiLoaderService,
    private toastr: ToastrService,
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.currentUser = this.authService.currentUser;
    this.params = {
      id: this.route.parent.parent.snapshot.params.id
    };
    if (this.currentUser.product_owner) {
      this.displayedColumns.push('companies');
    }

    const callView = localStorage.getItem( 'company-facilities:callView' );

    if ( callView == null || callView === 'dn_facility' ) { 
      this.defaultTabIndex = 0;
    } else if ( callView === 'pcc_facility' ) {
      this.defaultTabIndex = 1;
      this.selectedSource = 'PointClickCare';
    } else if ( callView === 'matrix_facility' ) {
      this.defaultTabIndex = 2;
      this.selectedSource = 'matrix';
    }
  }

  async ngOnInit() {
    // this.initCompanyFacilities();
    if (this.currentUser.product_owner) {
      this.getCompanies();
    }
    if (localStorage.getItem('searchedFacility')) {
      this.facilityTitleRegExp = localStorage.getItem('searchedFacility');
    }
    if (localStorage.getItem('facilityActiveStatus')) {
      this.filterStatus = localStorage.getItem('facilityActiveStatus');
    }
    await this.initAssociationData();

    this.filteredCompanies = this.companyControl.valueChanges.pipe(
      startWith(''),
      map((value:any) => this.filterCompany(value)));
  }

  private filterCompany(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.companies.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    else {
      return this.companies;
    }
  }

  getAssociationDate(facility) {
    if(facility?.company_assoc_date != undefined || facility?.company_assoc_date != null){
      for (const facAssocDate of facility?.company_assoc_date) {
        if (facAssocDate.company_id === this.params.id) {
          const assocDate = facAssocDate.assoc_date;
          const [date, time] = assocDate.split(' ')
          let splittedDate = date.split('-');
          let year = splittedDate[0];
          let month = splittedDate[1];
          let day = splittedDate[2];
          let formattedDate = `${month}/${day}/${year}`;
          return formattedDate;
        }
      }
    }else{
      return '';
    }
  }
  getFacAndOrganizationID(element){
    let value = ''
    if( element && element.organization_id){
      value = element.organization_id
    }
    if(element && element.pcc_facId){
      value = value + element.pcc_facId
    }
    return value
  }
  applyActiveFilter(event) {
    this.filterStatus = event.value;
    this.initCompanyFacilities();
  }
  getCompanies() {
    this.companyService.getAllCompanies().subscribe((response: any) => {
      if (response.status == 200) {
        this.companies = response.data;
        // this.getDoctorList();
        this.currentCompany = this.route.parent.parent.snapshot.params.id
        var index = this.companies.findIndex(company => this.currentCompany === company._id);
        this.currentCompany = this.companies[index];
        this.companies.splice(index, 1);
        this.companyControl.setValue(this.currentCompany?.name);
        // this.initCompanyFacilities();
      }
      else if (response.status == 403) {
        this.toastr.error(response.message, "ERROR");
      }
    })
  }

  searchFacilities() {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.hasFacilitiesLoaded = true;
      localStorage.setItem('searchedFacility', this.facilityTitleRegExp);
      this.initAssociationData();
    }, 1000);
  }

  async initAssociationData() {
    const company_id = this.params.id;

    const filter: any = {
      //is_active: null,
      is_active: this.filterStatus,
      company_id,
      pageNo: this.pagination.pageNo,
      source: this.selectedSource,
      trueRcmStatus: this.trueRcmStatus
    };
    if (this.facilityTitleRegExp !== "") {
      filter.title = this.facilityTitleRegExp;
      delete filter.pageNo;
    }
    if(this.merged){
      filter.merged=true;
    }
    const projection = {
      'title': 1,
      'address': 1,
      'city': 1,
      'state': 1,
      'zip_code': 1,
      'provider_ids_ref': 1,
      'template_ids_ref': 1
    }
    let result: any = await this.facilityService.getAllFacilitiesProviderTemplateAssoc(filter, projection).toPromise();
    if (result.status == 200) {
      this.hasFacilitiesLoaded = false;
      // result.data = result.data.map(ele => {
      //     ele.allTemp.map(temp => {
      //       temp.title = temp.title.toUpperCase();
      //       return temp;
      //     });
      //     return ele;
      // });
      this.facilities = result.data.facilities;
      this.pagination.totalRecords = result.data.totalFacilities;
      if (this.facilityTitleRegExp !== "") { //update page count for title search
        this.pagination.totalRecords = result.data.totalFacilities;
        this.pagination.hasNextPage = false;
        this.pagination.hasPrevPage = false;
        this.pagination.skip = 0;
      }
      if (this.facilityTitleRegExp == "") {
        this.handlePagination();
      }
      // this.facilities.map(facility=>{
      //     facility.providersDropDown = this.providers;
      // })
      this.dataSource = new MatTableDataSource(this.facilities);
    }
    else if (result.status == 403) {
      this.toastr.error(result.message, "ERROR");
      this.router.navigate(['/403']);
    }
    this.recordsLoaded = true;
    if (result.status) {
      this.loader.stopLoader('loader-01');
    }
  }

  ngOnDestroy(): void {
    if (!(window.location.href.indexOf("isRedirectedFromParentRoute") > -1)) {
      //localStorage.removeItem('pageNoSaved');
      localStorage.removeItem('searchedFacility');
      localStorage.removeItem('facilityActiveStatus');
      localStorage.removeItem("company-facilities:callView"); 
    }
  }

  handlePagination() {
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    if (this.pagination.skip + this.facilities.length < this.pagination.totalRecords) {
      this.pagination.hasNextPage = true;
    }
    else {
      this.pagination.hasNextPage = false;
    }
    if (this.pagination.skip > 0) {
      this.pagination.hasPrevPage = true;
    }
    else {
      this.pagination.hasPrevPage = false;
    }
  }

  handleAphabeticIndexButtonClick(index) {

    this.initFacilityAlpha(index);
  }

  async initFacilityAlpha(index) {
    const company_id = this.params.id;

    const filter: any = {
      is_active: this.filterStatus,
      company_id,
      source: this.selectedSource,
      title: index,
      pageNo: this.pagination.pageNo,
      trueRcmStatus: this.trueRcmStatus,
      alpha: true
    };

    if (index == 'All') {
      delete filter.title;
      delete filter.alpha;

    } else {
      delete filter.pageNo;
    }
    if(this.merged){
      filter.merged=true;
    }
    const projection = {
      'title': 1,
      'address': 1,
      'city': 1,
      'state': 1,
      'zip_code': 1,
      'provider_ids_ref': 1,
      'template_ids_ref': 1
    }
    this.loader.startLoader(this.loaderId);
    let result: any = await this.facilityService.getAllFacilitiesProviderTemplateAssoc(filter, projection).toPromise();
    if (result.status == 200) {
      this.loader.stopLoader(this.loaderId);
      this.hasFacilitiesLoaded = false;
      this.facilities = result.data.facilities;
      this.pagination.totalRecords = result.data.totalFacilities;
      this.dataSource = new MatTableDataSource(this.facilities);

      if (index != 'All') {
        this.pagination.totalRecords = result.data.totalFacilities;
        this.pagination.hasNextPage = false;
        this.pagination.hasPrevPage = false;
        this.pagination.skip = 0;
      }
      if (index == 'All') {
        this.handlePagination();
      }

    }
    this.recordsLoaded = true;
  }

  initCompanyFacilities = async () => {
    // this.loader.startLoader(this.loaderId);
    const company_id = this.params.id;
    const filter:any = {
      is_active: this.filterStatus,
      // is_active: null,
      // source: this.selectedSource,
      assoc_company_ids: company_id
    }
    const projection = {
      'title': 1,
      'address': 1,
      'city': 1,
      'state': 1,
      'zip_code': 1,
      'is_active': 1,
      'source': 1,
      'assoc_company_ids': 1

    }
    if(this.merged){
      filter.merged=true;
    }
    let companyAssocPopulation: facilityPopulationInterface = {
      collection: 'companies',
      field: ["assoc_company_ids"],
      filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: [true, { $in: ['$_id', '$$assoc_company_ids'] }] }] } },
      project: { name: 1 }, 
    };

    const response: any = await this.facilityService.getFacilities(filter, projection, [companyAssocPopulation]).toPromise();
    if (response.status === 200) {
      this.facilities = response.data.array;
      this.facilities.forEach(facility => {
        facility.companies = this.companies
        this.companies.forEach(company => {
          if (facility.company_id == company._id) {
            facility.selectedCompany = company._id;
          }
        });
      });
    }
    // this.loader.stopLoader(this.loaderId);
    this.initAssociationData();
    this.dataSource = new MatTableDataSource(this.facilities);
  }

  onDeleteClick(facility) {
    if (!confirm('Are you sure you want to delete?')) {
      return;
    }
    this.companyService.deleteFacility(facility._id).subscribe((response: any) => {
      if (response.status === 200) {
        this.initCompanyFacilities();
        //this.getCompanies()
      }
    })
  }
  checkEnvironment() {
    if (global.ENVIRONMENT == 'LOCAL' || global.ENVIRONMENT == 'DEV') {
      return true;
    }
    else {
      return false;
    }
  }
  onCompanyChange(element, company) {
    if (company != this.params.id) {
      this.companyService.companyChangeForFacility(element._id, company).subscribe((response: any) => {
        if (response.status == 200) {
          this.toastr.success("Company Changed");
          this.dataSource.data = this.dataSource.data.filter(facility => facility._id !== element._id);
        }
      });
    }
  }
  associatedProvidersDetail(providers) {
    this.dialog.open(AssociatedProviderDialogComponent, {
      width: '800px',
      data: providers
    });
  }

  associatedCompaniesDialog(companies) {
    this.dialog.open(AssociatedCompaniesDialogComponent, {
      width: '800px',
      data: companies
    });
  }

  onChangePcc2LeggedAuthentication(ele, $event) {
    delete ele.companies;
    ele.pcc_2_legged_authentication = $event.checked;
    const facilityData = {
      ...ele
    }
    this.companyService.updateFacility(facilityData).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("2-Legged Auth changed");
      }
    });
  }

  async onChangeStatus(ele, $event) {
    let details = {
      facility_id: ele._id,
      company_id: this.currentUser.company_id,
      user_id: this.currentUser._id,
      checked: $event.checked
    }
    let response:any = await this.companyService.updateInActiveFacility(details).toPromise();
    if(response.status == 200){
      this.toastr.success(response.message, "Success");
      this.applyFilters(this.selectedSource, this.filterStatus);
    }else{
      this.toastr.error(response.message, "Error");
    }
  }
  getTrueRcm(e) {
    return e.trueRCMID;
  }
  onChargeCaptureStatus(ele, $event) {
    delete ele.companies;
    ele.enabled_charge_capture = $event.checked;
    const facilityData = {
      ...ele
    }
    this.companyService.updateFacility(facilityData).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(" Charge Capture Status changed");
      }
    });
  }
  onNoteEmailStatus(ele, $event) {
    delete ele.companies;
    ele.enabled_note_email = $event.checked;
    const facilityData = {
      ...ele
    }
    this.companyService.updateFacility(facilityData).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(" Note Email Status changed");
      }
    });

  }

  downloadAsXLSX() {
    const payload: any = {
      companyId: this.params.id,
      source: this.selectedSource,
      ...(this.trueRcmStatus === "true" && { trueRcmStatus: this.trueRcmStatus })
    };
    if (this.filterStatus === "true") {
      payload.is_deleted = { '$ne': true };
      payload.is_active = { '$ne': false };
    }
    else {
      payload.is_deleted = { '$ne': false };
      payload.is_active = { '$ne': true };
    }
    
    this.companyService.downloadAsXLSX(payload).subscribe((response: any) => {
      if (response instanceof HttpResponse) {
        // this.toastr.success("Status changed");
        var url = window.URL.createObjectURL(response.body);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `Facilities`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applySourceFilter($event) {
    this.recordsLoaded = false;
    this.applyFilters(this.selectedSource, this.filterStatus);
    localStorage.setItem("facilityActiveStatus",this.filterStatus);
  }
  hideActivationEmail($event) {
    this.pagination.pageNo = 1;
    this.facilityTitleRegExp = '';
    localStorage.removeItem('searchedFacility');

    if ( $event.index === 0 ) {
      localStorage.setItem('company-facilities:callView', 'dn_facility');
    } else if ( $event.index === 1 ) {
      localStorage.setItem('company-facilities:callView', 'pcc_facility');
    } else if ( $event.index === 2 ) {
      localStorage.setItem('company-facilities:callView', 'matrix_facility');
    } else {
      localStorage.removeItem('company-facilities:callView');
    }

    if ($event.tab.textLabel === 'PCC Facilities') {
      this.showEmailButton = false;
    } else {
      this.showEmailButton = true;
    }
    if ($event.tab.textLabel === 'Matrix Facilities') {
      this.selectedSource = 'matrix';
      this.recordsLoaded = false;
      this.showEmailButton = false;
      this.loader.startLoader('loader-01');
      this.applyFilters(this.selectedSource, this.filterStatus);
      localStorage.setItem("facilityActiveStatus", this.filterStatus);
    } else if ($event.tab.textLabel === 'PCC Facilities') {
      this.selectedSource = 'all';
      this.recordsLoaded = false;
      this.applyFilters(this.selectedSource, this.filterStatus);
      localStorage.setItem("facilityActiveStatus", this.filterStatus);
    } else if ($event.tab.textLabel === 'DN Facilities') {
      this.selectedSource = 'all';
      this.recordsLoaded = false;
      this.loader.startLoader('loader-01');
      this.applyFilters(this.selectedSource, this.filterStatus);
      localStorage.setItem("facilityActiveStatus",this.filterStatus);
    }
  }

  applyFilters(source, activeFilter) {
    this.selectedSource = source;
    this.filterStatus = activeFilter;
    this.recordsLoaded = false;
    this.initAssociationData();
  }

  goToPreviousPage() {
    this.pagination.pageNo--;
    this.pagination.hasPrevPage = false;
    this.initAssociationData();
  }
  goToNextPage() {
    this.pagination.pageNo++;
    this.pagination.hasNextPage = false;
    this.initAssociationData();
  }

  RedirectToEditFacility(facilityId) {
    return this.router.navigate([`company/${this.params.id}/facilities/edit/${facilityId}`], {
      queryParams: {
        isRedirectedFromParentRoute: true
      }
    });
  }
}
