<div class="flex flex-col">
    <mat-card>
        <mat-card-content>
            <div class="flex flex-col gap-2" style="background: white; padding:1px 5px 10px 5px; border-radius: 10px; 
            justify-content: space-evenly !important; align-content: center;">

                <div class="flex flex-row gap-5" >
                    <button class="dn-button-primary"
                        style=" flex: 10%; margin-left: 5px; min-width: auto;"
                        (click)="initWrongPatients(); " mat-raised-button>Refresh
                    </button>
                    <button class="dn-button-primary"
                        style=" flex: 10%; margin-left: 5px; min-width: auto;"
                        (click)="fetchAll(); " mat-raised-button>Fetch All
                    </button>
                </div>

                <div class="flex-col justify-around gap-2 flex-1">

                        <div class="flex flex-col justify-start items-baseline gap-2 w-95" 
                            style="overflow-y: auto;">
                            <table mat-table [dataSource]="dataSource">

                                <ng-container matColumnDef="orignal">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Orignal Patient
                                    </th>
                                    <td mat-cell *matCellDef="let item">
                                        <div class="flex justify-center items-center gap-5" style="color: rgb(109, 168, 91);">
                                                <i style="width: 50px;" class="icon icon-file" *ngIf="item?.firstTranscription" matTooltip="First Note" matTooltipPosition="above"
                                                    (click)=" viewNoteDialog(item?.firstTranscription?._id);"></i>
                                                <i style="width: 50px;" class="icon icon-file" *ngIf="item?.latestTranscription" matTooltip="Last Note" matTooltipPosition="above"
                                                    (click)=" viewNoteDialog(item?.latestTranscription?._id);"></i>
                                                <span *ngIf="item?.correctCount" class="flex"> style="color: rgb(146, 13, 13);" > {{'('+item?.correctCount+')'}} </span>

                                                    <span class="flex">>
                                                        {{item?._id?.first_name+' '+item?._id?.last_name+' (
                                                        '+item?._id?.date_of_birth+'
                                                        ) ' + item?._id?.patient_id}}
                                                    </span>
                                            </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="sign">
                                    <th mat-header-cell *matHeaderCellDef>
                                        To
                                    </th>
                                    <td mat-cell *matCellDef="let item">
                                        {{'->'}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="wrong">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Wrong Patient
                                    </th>
                                    <td mat-cell *matCellDef="let item">
                                        <div style="color: rgb(141, 95, 45);">
                                            {{item?.result?.first_name+' '+item.result?.last_name+' (
                                            '+item.result?.date_of_birth+'
                                            ) ' + item?.result?._id}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="duplicate">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Duplicate of Orignal
                                    </th>
                                    <td mat-cell *matCellDef="let item">
                                        <div *ngIf="(item?.duplicate)&&(item.pcc)" class="flex items-center justify-center gap-5" style="color: rgb(16, 107, 121);">
                                            <i style="width: 50px;" class="icon icon-file" *ngIf="item?.dupTranscription" matTooltip="First Note" matTooltipPosition="above"
                                                (click)=" viewNoteDialog(item?.dupTranscription?._id);"></i>

                                            <span class="flex">>
                                                {{item?.duplicate?.first_name+' '+item.duplicate?.last_name+' (
                                                    '+item?.duplicate?.date_of_birth+' ) '+(showPccDetailsInText(item))}}
                                            </span>
                                        </div>
                                        <div *ngIf="(item?.duplicate)&&!(item.pcc)" class="flex items-center justify-center gap-5">
                                            <i style="width: 50px;" class="icon icon-file" *ngIf="item?.dupTranscription" matTooltip="First Note" matTooltipPosition="above"
                                                (click)=" viewNoteDialog(item?.dupTranscription?._id);"></i>
                                            <i style="width: 50px;" class="icon icon-file" *ngIf="item?.latestDuplicate" matTooltip="Last Note" matTooltipPosition="above"
                                                (click)=" viewNoteDialog(item?.latestDuplicate?._id);"></i>
                                            <span *ngIf="item?.dupCount" style="color: rgb(146, 13, 13);" class="flex"> {{'('+item?.dupCount+')'}} </span>
                                            <span class="flex">
                                                {{item?.duplicate?.first_name+' '+item.duplicate?.last_name+' (
                                                    '+item?.duplicate?.date_of_birth+' ) '+(item.duplicate?._id||'')}}
                                            </span>
                                        </div>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="wrongDuplicate">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Duplicate of Wrong
                                    </th>
                                    <td mat-cell *matCellDef="let item">
                                        <div *ngIf="(item?.wrongduplicate)" class="flex items-center justify-center gap-5">
                                            <i style="width: 50px;" class="icon icon-file" *ngIf="item?.wrongdupTranscription" matTooltip="First Note" matTooltipPosition="above"
                                                (click)=" viewNoteDialog(item?.wrongdupTranscription?._id);"></i>
                                            <i style="width: 50px;" class="icon icon-file" *ngIf="item?.wronglatestDuplicate" matTooltip="Last Note" matTooltipPosition="above"
                                                (click)=" viewNoteDialog(item?.wronglatestDuplicate?._id);"></i>
                                            <span *ngIf="item?.wrongdupCount" style="color: rgb(146, 13, 13);" class="flex"> {{'('+item?.wrongdupCount+')'}} </span>
                                            <span class="flex">
                                                {{item?.wrongduplicate?.first_name+' '+item.wrongduplicate?.last_name+' (
                                                    '+item?.wrongduplicate?.date_of_birth+' ) '+(item.wrongduplicate?._id||'')}}
                                            </span>
                                        </div>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="merge">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Actions
                                    </th>
                                    <td mat-cell *matCellDef="let item">

                                        <button [disabled]="item?.duplicate" class="dn-button-primary"
                                            style=" flex: 10%; margin-left: 5px; min-width: auto;"
                                            (click)="findDuplicate(item)" mat-raised-button>Find
                                        </button>

                                        <button *ngIf="item?.duplicate" [disabled]="item?.merged" class="dn-button-primary"
                                            style=" flex: 10%; margin-left: 5px; min-width: auto;"
                                            (click)="mergePatient(item); " mat-raised-button>Merge
                                        </button>

                                        <button *ngIf="(item?.duplicate)&&!(item.pcc)" [disabled]="item?.datamerged" class="dn-button-primary"
                                            style=" flex: 10%; margin-left: 5px; min-width: auto;"
                                            (click)="mergePatientsData(item); " mat-raised-button>Data
                                        </button>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell *matHeaderCellDef>
                                        M
                                    </th>
                                    <td mat-cell *matCellDef="let item">
                                        <img style="width: 14px;" src="../../../assets/img/green_dot.png" *ngIf="item?.merged"
                                            alt="">
                                        <img style="width: 14px;" src="../../../assets/img/red_dot.png" *ngIf="!item?.merged"
                                            alt="">
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>

                        </div>
                        <mat-paginator [pageSizeOptions]="[15, 25, 50]" showFirstLastButtons></mat-paginator>

                    </div>

            </div>
        </mat-card-content>
    </mat-card>
</div>