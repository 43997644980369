import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { TemplateAssociationService } from '../template-association.service';

@Component({
  selector: 'app-template-association',
  templateUrl: './template-association.component.html',
  styleUrls: ['./template-association.component.css']
})
export class TemplateAssociationComponent implements OnInit {
  company_id: any = ''
  dataSource:any
  displayedColumns: string[] = ['facility', 'provider', 'template', ];
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private templateAssociationService: TemplateAssociationService,
  ) { 
  }

  ngOnInit(): void {
    if(window.location.pathname.includes('company')){
      this.company_id = this.route.parent.snapshot.params.id
    }
    else{
      this.company_id = ''
    }
    this.loadData();
  }

  async loadData(){
    
    await this.templateAssociationService.getTempAssociationData(this.company_id).subscribe((response:any)=>{
      if(response.status == 200){
        console.log("loadData reponse:", response);
        
      }
    })
  }

  applyFilter(filterValue: string) {
    console.log("apply filter called");
  }

}
