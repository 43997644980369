<div style="padding: 10px 10px 0px 10px;height:max-content;background-color: rgb(233, 231, 231);"
    *ngFor="let comment of comments">
        <div class="flex flex-row items-center">
            <h1 style="margin:0px;font-size: 12px;font-family: 'Poppins', sans-serif !important;">{{comment.creator_name}}</h1> <small
                style="font-size: 12px;color: grey;margin-left: auto;">{{comment.updatedAt | date}}</small>
        </div>
        <div>
            <span style="font-size: 12px;margin-top: 10px;">
                {{comment.comment}}
            </span>
        </div>
        <div *ngIf="comment.creator_id==currentUser._id" style="margin-bottom: 3px;">
            <small style="color:rgb(0, 0, 136); cursor: pointer;font-size: 10px;"
                (click)="editComment(comment)">Edit</small><span>&nbsp;&nbsp;&bull;</span>
            <small style="color:rgb(146, 0, 19); cursor: pointer;margin-left: 10px;font-size: 10px;"
                (click)="deleteComment(comment._id)">Delete</small>
        </div>
        <mat-divider style="width: 98%;"></mat-divider>
</div>