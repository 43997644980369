<mat-form-field style="width: 100%">
  <mat-select *ngIf="isProvider" [ngModel]="ngModelData" [multiple]="isMulti" [compareWith]="compareData">
      <mat-select-trigger>
          <mat-chip-list>
            <mat-chip *ngFor="let data of ngModelData" style="margin-right: 5px;" [removable]="true" (removed)=" onRemoveData(data, element);">
              <ng-container>{{ data.first_name }}&nbsp;{{data.last_name}},&nbsp;{{data.title}}</ng-container> 
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </mat-select-trigger>
        <ng-container *ngIf="selectAll && allData.length">
          <mat-option value="selectAll" *ngIf="allData.length != ngModelData.length" (onSelectionChange)="onSelectData($event, element);">Select All</mat-option>
          <mat-option value="unSelect" *ngIf="allData.length == ngModelData.length" (onSelectionChange)="onSelectData($event, element);">Un-Select All</mat-option>
          <input class="custom-search-input"  placeholder="Search data" [(ngModel)]="searchProviderText"
            [ngModelOptions]="{standalone: true}"/>
        </ng-container>

      <ng-cotainer *ngIf="allData.length == 0">
        <mat-option [disabled]="true">No Data Found</mat-option>
      </ng-cotainer>
        
    <mat-option *ngFor="let data of allData | searchProvider : searchProviderText" [value]="data" (onSelectionChange)="onSelectData($event, element);"><ng-container *ngIf="isProvider">{{ data.first_name }}&nbsp;{{data.last_name}},&nbsp;{{data.title}}</ng-container></mat-option>
  </mat-select>
  <mat-select *ngIf="isTemplate" [ngModel]="ngModelData" [multiple]="true" [compareWith]="compareData">
    <mat-select-trigger>
        <mat-chip-list>
          <mat-chip *ngFor="let data of ngModelData" style="margin-right: 5px;" [removable]="true" (removed)=" onRemoveData(data, element);">
            <ng-container>{{ data.title}}</ng-container> 
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </mat-select-trigger>
      <ng-container *ngIf="selectAll && allData.length">
        <mat-option value="selectAll" *ngIf="allData.length != ngModelData.length" (onSelectionChange)="onSelectData($event, element);">Select All</mat-option>
        <mat-option value="unSelect" *ngIf="allData.length == ngModelData.length" (onSelectionChange)="onSelectData($event, element);">Un-Select All</mat-option>
        <input class="custom-search-input"  placeholder="Search data" [(ngModel)]="searchTemplateText"
          [ngModelOptions]="{standalone: true}" />
      </ng-container>
      <ng-cotainer *ngIf="allData.length == 0">
        <mat-option [disabled]="true">No Data Found</mat-option>
      </ng-cotainer>
      
  <mat-option *ngFor="let data of allData | searchTemplate : searchTemplateText" [value]="data" (onSelectionChange)="onSelectData($event, element);">{{ data.title }}</mat-option>
</mat-select>
  <mat-select *ngIf="isCompany" [ngModel]="ngModelData" [multiple]="true" [compareWith]="compareData">
      <mat-select-trigger>
          <mat-chip-list>
            <mat-chip *ngFor="let data of ngModelData " style="margin-right: 5px;" [removable]="true" (removed)=" onRemoveData(data, element);">
              <ng-container>{{ data.name }}</ng-container> 
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
      </mat-select-trigger>
      <ng-container *ngIf="selectAll && allData.length">
        <mat-option value="selectAll" *ngIf="allData.length != ngModelData.length && shouldShowSelectAll">Select All</mat-option>
          <mat-option value="unSelect" *ngIf="allData.length == ngModelData.length && shouldShowSelectAll">Un-Select All</mat-option>
          <input class="custom-search-input"  placeholder="Search data" [(ngModel)]="searchProviderText"
            [ngModelOptions]="{standalone: true}" />
      </ng-container>

      <ng-cotainer *ngIf="allData.length == 0">
        <mat-option [disabled]="true">No Data Found</mat-option>
      </ng-cotainer>
    <mat-option *ngFor="let data of allData | searchCompany : searchProviderText" [value]="data" (onSelectionChange)="onSelectData($event, element);"><ng-container>{{ data.name}}</ng-container></mat-option>
  </mat-select>

  <mat-select *ngIf="isPhrase" [ngModel]="ngModelData" [multiple]="true" [compareWith]="compareData">
    <mat-select-trigger>
        <mat-chip-list>
          <mat-chip *ngFor="let data of ngModelData" style="margin-right: 5px;" [removable]="true" (removed)="onRemoveData(data, element);">
            <ng-container>{{ data.key }}</ng-container> 
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
    </mat-select-trigger>
    <ng-container *ngIf="selectAll && allData.length">
      <mat-option value="selectAll" *ngIf="allData.length != ngModelData.length">Select All</mat-option>
        <mat-option value="unSelect" *ngIf="allData.length == ngModelData.length">Un-Select All</mat-option>
        <input class="custom-search-input"  placeholder="Search data" [(ngModel)]="searchProviderText"
          [ngModelOptions]="{standalone: true}" />
    </ng-container>

    <ng-cotainer *ngIf="allData.length == 0">
      <mat-option [disabled]="true">No Data Found</mat-option>
    </ng-cotainer>
  <mat-option *ngFor="let data of allData" [value]="data" (onSelectionChange)="onSelectData($event, element);"><ng-container>{{ data.key}}</ng-container></mat-option>
</mat-select>

<mat-select *ngIf="isFacility" [ngModel]="ngModelData"  [multiple]="isMulti" [compareWith]="compareData">
  <mat-select-trigger>
      <mat-chip-list>
        <mat-chip *ngFor="let facility of ngModelData" style="margin-right: 5px;" [removable]="true" (removed)="onRemoveData(facility, element)">
          <div class="flex flex-inline justify-start items-center gap-2">
              <img src="../../../assets/icons/pcc-icon.svg" *ngIf="isPCCFacility(facility)" class="pcc-icon" style="width: 10px !important; height: 10px !important; margin-right: 15px !important;" alt="">
              <img src="../../../assets/icons/matrix.png" *ngIf="isMatrixFacility(facility)" 
              style="height: 10px;
                  width: 20px;  
                  margin-right: 15px !important;
                  vertical-align: middle;" alt="">
              <img src="../../../assets/img/DN.png" *ngIf="isDNFacility(facility)" class="dn-icon" style="width: 10px !important; height: 10px !important; margin-right: 15px !important;" alt="">
              <span class="title">{{facility.title}}</span>
          </div>
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </mat-select-trigger>
    <mat-option value="selectAll" *ngIf="allData.length != ngModelData.length" (onSelectionChange)="onSelectData($event, element)">Select All</mat-option>
    <mat-option value="unSelect" *ngIf="allData.length == ngModelData.length" (onSelectionChange)="onSelectData($event, element)">Un-Select All</mat-option>
    <input class="custom-search-input"  placeholder="Search Facility" [(ngModel)]="searchFacilityText"
      [ngModelOptions]="{standalone: true}" />
  <mat-option *ngFor="let facility of allData | searchFacility : searchFacilityText" [value]="facility" (onSelectionChange)="onSelectData($event, element)">
      <div class="flex flex-inline justify-start items-center gap-2">
          <img src="../../../assets/icons/pcc-icon.svg" *ngIf="isPCCFacility(facility)" class="pcc-icon" style="width: 10px !important; height: 10px !important; margin-right: 15px !important;" alt="">
          <img src="../../../assets/icons/matrix.png" *ngIf="isMatrixFacility(facility)" 
          style="height: 10px;
              width: 10px;  
              margin-right: 15px;
              vertical-align: middle;" alt="">
          <img src="../../../assets/img/DN.png" *ngIf="isDNFacility(facility)" class="dn-icon" style="width: 10px !important; height: 10px !important; margin-right: 15px !important;" alt="">
          <span class="title">{{facility.title}}</span>
      </div>
  </mat-option>
</mat-select>
</mat-form-field>