import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { PCCService } from 'src/app/services/pcc.service';
import { SocketService } from 'src/app/services/socket.service';
import { LoginService } from '../login/login.service';
import * as global from '../global';


@Component({
  selector: 'app-admin-auth',
  templateUrl: './admin-auth.component.html',
  styleUrls: ['./admin-auth.component.css'],
  providers: [DatePipe]
})
export class AdminAuthComponent implements OnInit {
  public currentUserAuth: any;
  public currentUser: any;
  public response: any;
  public status: any;
  public mfaSession;
  private email;
  currentURL: string;
  params: any;
  display: any;
  public timerInterval: any;
  hide: boolean =  true;
  disableButton: boolean = false;
  public constructor(
    public loginService: LoginService,
    private _toastr: ToastrService,
    private _socketService: SocketService,
    public datePipe: DatePipe,
    private pccService: PCCService,
    private _authService: AuthService,
    private _route: ActivatedRoute,
    private _router: Router,
  ) {
    try {
      this.currentUserAuth = JSON.parse(localStorage.getItem('currentUserAuth'));
    } catch (error) {

    }
  }
  ngOnInit() {
    // localStorage.removeItem('currentUser');
    this._route.paramMap.subscribe(async (params: ParamMap) => {
      this.mfaSession = params.get('mfaSession');
      this.email = params.get('email');
      // if (params.get('params') !== 'null') {
        this.params = params.get('params');
      // }
    });
    // this.currentURL = window.location.href;
    // if(this.currentURL.includes('=')) {
    //   this.params = this.currentURL.slice(this.currentURL.indexOf('=') + 1);
    //   }

    this._authService.onUserAuthChange.subscribe((user) => {
      if (user) {
        console.log("this.params", this.params);
        if (this.params) {
          this._router.navigate(['/company/' + user.company_id + '/' + this.params]);
        }
        else {
          this._router.navigate(['/company/' + user.company_id]);
        }
      }
    });
    this.timer(2)
  }
  onCheckPasscode(form: NgForm) {
    if (form.invalid) {
      return;
    }
    const infoToast = this._toastr.info('Authenticating...', "Please wait", { disableTimeOut: true });
    // this.currentUser = this.currentUserAuth;

    // ACTIVITY LOG
    this._socketService.addActivityLog({
      id: this.currentUser?._id,
      screen: 'Auth',
      operation: 'Auth Verification API Call',
      datetime: this.datePipe.transform(new Date(), global.date_time_format)
    })

    this.loginService.check2FactorVerificationCode(this.currentUserAuth, form.value.passcode, this.email, this.mfaSession, form.value.remember_device_check).subscribe(response => {
      this.response = response;
      this.status = this.response['status'];
      if (this.response['status'] === 200) {
        const { user, token } = this.response.data || {};

        this._toastr.success("Authentication Successful", "Success");
        if (user) {
          this.currentUser = this.response.data.user;
          const auth_key = user.web_auth_key + ".WEB";
          user.auth_key = auth_key;
          this._authService.setToken(token);
        } else {
          this.currentUser = this.currentUserAuth;
        }
        this._authService.currentUser = this.currentUser;
      }

    }, console.error, () => {
      this._toastr.clear(infoToast.toastId);
    }
    );


  }

  resendAuthCode() {
    this.disableButton = true;
    if (!this.mfaSession) {
      this.loginService.send2FactorVerificationCode(this.currentUserAuth, this.currentUserAuth.auth_key).subscribe((res: any) => {
        if (res.status == 200) {
          this.timer(2)
          this._toastr.success('Verification code has been sent', 'Success');
        }
        else if (res.status == 500) {
          this._toastr.error('User not found', 'Error');
        }
      });

    }

    else {
      this.loginService.resendAuthCode(this.email).subscribe((res: any) => {
        if (res.status == 200) {
          this.timer(2)
          this._toastr.success('Verification code has been sent', 'Success');
        }
        else if (res.status == 500) {
          this._toastr.error('User not found', 'Error');
        }
      })
    }

  }

  timer(minute) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
      if(this.display.includes('-')){
        this.display = '00:00';
        clearInterval(this.timerInterval);
      }
      if (seconds <= 0) {
        if(this.display !== '00:00'){
          this.display = '00:00';
        }
        // console.log('finished');
        clearInterval(this.timerInterval);
      }
      this.disableButton = false;
    }, 1000);
  }
}
