import { Component, OnInit } from '@angular/core';
import { ForgetPasswordService } from './forgot-password.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../login/login.service';
import * as global from '../global';
import { CompanyLogoAndMongogramService } from 'src/app/services/company-logo-and-mongogram.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent implements OnInit {
  companyCode: any;
  defaultImg: string;
  companylogoPath: any;
  companylogoPathSubscription: any;
  // implemented #mohsin-dev
  constructor(private _forgetPasswordService: ForgetPasswordService, private _toastr: ToastrService, private _router: Router, private route: ActivatedRoute, public loginService: LoginService,private companyLogoAndMonogramService: CompanyLogoAndMongogramService) { }
  status: any = null;
  enableCognito = false;
  cognito = false;
  async ngOnInit() {
    if (this.route.snapshot.params.company_code) {
      this.companyCode = this.route.snapshot.params.company_code;
      console.log('this.companyCode', this.companyCode);
      await this.getCompanyLogo();
    } else {
      this.defaultImg = "../../../assets/img/DNlogo-new.png";
    }
  }

  async getCompanyLogo() {
    this.companylogoPathSubscription = this.companyLogoAndMonogramService.castCompanyMonogram.subscribe((res:any)=>{
      if(res){
        this.companylogoPath = res;
      }else{
        this.defaultImg = "../../../assets/img/DNlogo-new.png";
      }
    });
  }

  ngOnDestroy(): void {
    if(this.companylogoPathSubscription){
      this.companylogoPathSubscription.unsubscribe();
    }
  }
  onForgetPassword(forgetPasswordForm) {

    if (!forgetPasswordForm.valid || !forgetPasswordForm.touched) return;

    const { email } = forgetPasswordForm.value;
    const details = {
      email
    }

    this._forgetPasswordService.checkCognitoStatus(details).toPromise().then((response: any) => {
      this.status = response.status;
      this.enableCognito = response.data?.enableCognito;
      this.cognito = response.data?.cognito;
      if (response.status === 200) {
        let forgetPasswordCredientials = {
          email: email,
          status: this.enableCognito
        }
        localStorage.setItem('forgetPasswordCredientials', JSON.stringify(forgetPasswordCredientials));
        if(!this.cognito){
          this._router.navigate([`/company/${response.user.company_id}/user-create-password`],{queryParams: {user_id: response.user._id, email: response.user.email}});
        }
        // else if (this.cognito === true && this.enableCognito === false) {
        //   const infoToastr = this._toastr.info('Sending password reset code...', 'Please wait', { disableTimeOut: true })
        //   this._forgetPasswordService.sendPasswordResetCode(details).subscribe((res: any) => {
        //     if (res.status === 200) {
        //       this._toastr.clear(infoToastr.toastId);
        //       this._toastr.success("Reset Code Sent", "Success");
        //       if (this.companyCode) {
        //         const code = this.companyCode;
        //         this._router.navigate([`/code-verification/${code}`]);
        //       } else {
        //         this._router.navigate([`/code-verification`]);
        //       }
        //     } else {
        //       this._toastr.clear(infoToastr.toastId);
        //       this._toastr.error(res.message, 'Failed');
        //     }
        //   });
        // }
        else if(this.cognito) {
          const infoToastr = this._toastr.info('Sending password reset code...', 'Please wait', { disableTimeOut: true })
          this._forgetPasswordService.sendCognitoResetCode(details).subscribe((res: any) => {
            if (res.status === 200) {
              this._toastr.clear(infoToastr.toastId);
              this._toastr.success("Reset Code Sent", "Success");
              if (this.companyCode) {
                const code = this.companyCode;
                this._router.navigate([`/forget-code-verification/${code}`]);
              } else {
                this._router.navigate([`/forget-code-verification`]);
              }
            } else {
              this._toastr.clear(infoToastr.toastId);
              this._toastr.error(res.message, 'Failed');
            }
          });
        }else{
          this._toastr.error("You are not allowed to change Password!", 'Error');
        }
      } else {
        console.log('statyssss : ',response.status )
        this._toastr.error(response.message, 'Failed');
      }
    });
  }
}