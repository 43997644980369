
<div class="{{ selectedCompany.portal_type !== global.PORTAL_TYPE.NON_WC ? 'container' : '' }}" *ngIf="isManageCompaniesVisible">
    <div class="flex items-center justify-center gap-5" style="background: #cedbdf; padding: 5px; border-radius: 4px;">

        <button class="dn-button-secondary" mat-raised-button (click)="goBack()">Back</button>

        <app-input-with-dropdown class="flex-grow" [noDropdown]="true" placeholder="Search Company by Name"
            (inputChange)="applyFilter($event.target.value)">
        </app-input-with-dropdown>
        <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select value="true" (selectionChange)="applyCompanyFilter($event.value)">
                <mat-option value="all">All</mat-option>
                <mat-option value="true">Active</mat-option>
                <mat-option value="false">Inactive</mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-raised-button class="dn-button-primary activation-email-btn"
            (click)="loadCompanyEmail();">Email</button>

        <button class="dn-button-primary" mat-raised-button (click)="addCompany()">Add Company</button>
    </div>
    <div style="height: 3px;"></div>

    <div class="example-container">

        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef>Active</th>
                <td mat-cell *matCellDef="let element">
                    <mat-checkbox class="top-margin" [checked]="element?.is_active === true" [disabled]="disabled"
                        (change)="changeCompanyActiveStatus(element, $event.checked)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.name | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Created </th>
                <td mat-cell *matCellDef="let element"> {{element.createdAt | date}} </td>
            </ng-container>

            <ng-container matColumnDef="providerCount">
                <th mat-header-cell *matHeaderCellDef> Providers ({{getProviderTotalCount()}})</th>
                <td mat-cell *matCellDef="let element"> {{element?.providers?.providerCount ?
                    element?.providers?.providerCount : 0}} </td>
            </ng-container>

            <ng-container matColumnDef="facilityCount">
                <th mat-header-cell *matHeaderCellDef> Total Facilities ({{getFacilitiesTotalCount()}})</th>
                <td mat-cell *matCellDef="let element"> {{element?.facilities?.facilityCount ?
                    element?.facilities?.facilityCount : 0 }} </td>
            </ng-container>
            <ng-container matColumnDef="pccFacilityCount">
                <th mat-header-cell *matHeaderCellDef>Total Pcc Facilities ({{getPccFacilitiesTotalCount()}})</th>
                <td mat-cell *matCellDef="let element"> {{element?.pccFacilties?.PCCfacilityCount ?
                    element?.pccFacilties?.PCCfacilityCount : 0 }} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> Email </th>
                <td mat-cell *matCellDef="let element"> {{element.owner?.email}} </td>
            </ng-container>
            <ng-container matColumnDef="carry_charges">
                <th mat-header-cell *matHeaderCellDef>Carry Forward Charges</th>
                <td mat-cell *matCellDef="let element">
                    <mat-checkbox class="top-margin" [checked]="element?.charges_carry_forward === true"
                        (change)="changeCarryForwardStatus(element, $event.checked)">
                    </mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="permissions">
                <th mat-header-cell *matHeaderCellDef>Permissions</th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field appearance="fill">
                    <mat-label>Add/Remove Permissions</mat-label>
                        <mat-select>
                            <mat-option *ngFor="let option of checkboxOptions" [style.color]="'rgb(43, 41, 41)'" >
                                <mat-checkbox class="top-margin" [checked]="element?.[option.property] === true"
                                              (click)="$event.stopPropagation()"
                                              (change)="updateComapny(element, option.property, $event.checked)">
                                    {{ option.label }}
                                </mat-checkbox>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="operations">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <div class="flex justify-evenly items-center">
                        <i matTooltip="View" matTooltipPosition="above" (click)="companyClicked(element)"
                            class="icon icon-view"></i>
                        <mat-icon matTooltip="Settings" style="cursor: pointer;"
                            (click)=" isManageCompaniesVisible = false; currentCompany = element; isCompanySettingsVisible = true;refreshSettings(); getSettings(); getChargeSetting(); getAllMips(element);getCompanyMipsEnabledStatus(element);getCompanyNurseSettings(element)">
                            settings</mat-icon>
                    </div>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

    </div>

</div>
<div *ngIf="isShowCompanyEmail">
    <app-company-email (exit)="isShowCompanyEmail=$event; isManageCompaniesVisible=true;"></app-company-email>
</div>
<div *ngIf="isCompanySettingsVisible">
    <div class="{{ selectedCompany.portal_type !== global.PORTAL_TYPE.NON_WC ? 'container' : '' }}">
        <mat-tab-group (selectedTabChange)="companyTabClick($event, $event.tab)" style="background-color: white;">
            <mat-tab label="Profile">
                <app-company-settings [company]="currentCompany"
                    (exit)="isCompanySettingsVisible=$event; isManageCompaniesVisible=true;"
                    (updateCurrentCompany)="updateCurrentCompany($event)">
                </app-company-settings>
            </mat-tab>

            <mat-tab label="HL7 Charges" *ngIf="currentCompany.company_type != global.COMPANY_TYPE.SNF && selectedCompany.portal_type !== global.PORTAL_TYPE.NON_WC">
                <app-hl7-charges [company]="currentCompany">
                </app-hl7-charges>
            </mat-tab>

            <mat-tab label="Census">
                <app-census-column [company]="currentCompany"
                    (exit)="isCompanySettingsVisible=$event; isManageCompaniesVisible=true;"
                    (updateCurrentCompany)="updateCurrentCompany($event)">
                </app-census-column>
            </mat-tab>
            <mat-tab label="Biller Co-sign feature" style="height: calc(100vh - 130px);overflow-y: auto;" *ngIf="currentCompany.company_type != global.COMPANY_TYPE.SNF">
                <app-co-signer [company]="currentCompany"
                    (exit)="isCompanySettingsVisible=$event; isManageCompaniesVisible=true;"
                    (updateCurrentCompany)="updateCurrentCompany($event)">
                </app-co-signer>
            </mat-tab>
       
            <mat-tab label="Settings"
                *ngIf="currentCompany.company_type != global.COMPANY_TYPE.SNF && selectedCompany.portal_type !== global.PORTAL_TYPE.NON_WC">
                <mat-card class="practice-settings"> 
                    <div class="flex items-center" style="margin-bottom: 15px;">
                        <button mat-raised-button class="dn-button-secondary" type="button"
                            (click)="isManageCompaniesVisible = true; isCompanySettingsVisible = false;">Back</button>
                    </div>
                    <div style="display: flex; flex-direction: column;width: 100%;">
                        <div style="align-self: center;padding-top: 8px">
                            <mat-label style="align-self: center;" >Days to Edit Submitted Charges :</mat-label>
                            <mat-form-field style="margin-left: 40px; margin-top: 10px;" class="field-item">
                                <input matInput placeholder="Duration to Edit Charge" [(ngModel)]="charge_edit_duration" name="edit_duration"/>
                            </mat-form-field>
                        </div>
                        <div  style="display: flex;padding-top: 15px; justify-content: center;">
                            <button style="margin-left: 10px;" mat-raised-button (click)="saveChargeSetting()" type="button" class="dn-button-primary">
                                Save
                            </button>
                        </div>
                    </div>
                </mat-card>
            </mat-tab>

            <mat-tab *ngIf="selectedCompany.portal_type === global.PORTAL_TYPE.NON_WC" label="Settings">
                <app-company-setting [company]="currentCompany"
                    (updateFacesheetSettinginList)="updateFacesheetSettinginList($event)"
                    (updateIPAReportSetting)="updateIPAReportSetting($event)"
                    (exit)="isCompanySettingsVisible=$event; isManageCompaniesVisible=true;">
                </app-company-setting>
            </mat-tab>

            <mat-tab label="Reports">
                <mat-tab-group  #woundTab style="background-color: white;margin-top: 0.5rem;" selectedIndex="0">
                    <mat-tab label="Wound System Reports">
                        <mat-card class="practice-settings">
                            <div class="flex items-center" style="margin-bottom: 15px;">
                                <button mat-raised-button class="dn-button-secondary" type="button"
                                    (click)="isManageCompaniesVisible = true; isCompanySettingsVisible = false;">Back</button>
                            </div>
                            <div *ngFor="let report of reportsNames">
                                <mat-checkbox
                                    name="report"
                                    [checked]="report.selected"
                                    (change)="report.selected = $event.checked; saveSettings()">
                                    {{report.title}}
                                </mat-checkbox>
                            </div>
                        </mat-card>
                    </mat-tab>
                    <mat-tab label="Wound Enterprise Reports">
                        <app-wound-enterprise-reports [showAddBtn]="true" [showEditBtn]="true" [showDeleteBtn]="true"></app-wound-enterprise-reports>
                    </mat-tab>
                </mat-tab-group>
               
            </mat-tab>
            <mat-tab label="MIPS" *ngIf="currentCompany.company_type != global.COMPANY_TYPE.SNF">
                <mat-card class="mips-settings">
                    <div class="flex items-center" style="margin-bottom: 15px;">
                        <button mat-raised-button class="dn-button-secondary" type="button"
                            (click)="isManageCompaniesVisible = true; isCompanySettingsVisible = false;">Back</button>
                    </div>
                    <div>
                        <mat-checkbox (change)="toggleCompanyMips($event,currentCompany)" [checked]="showMips" [disabled]="disableMips"></mat-checkbox>
                        <div class="flex items-center flex-grow-0 w-1/2" style="margin-left: 10px;">
                            <b>Enable Measure Reporting</b>
                        </div>
                    </div>
                    <div *ngIf="showMips" style="margin-top: 20px;">
                        <app-mips-admin-list [mipsQuestions]="mipsQuestions" [currentCompany]="currentCompany" ></app-mips-admin-list>
                    </div>
                </mat-card>
            </mat-tab>
            <mat-tab label="Nurse Settings">
                <mat-card class="nurse-settings">
                    <div class="flex items-center" style="margin-bottom: 15px;">
                        <button mat-raised-button class="dn-button-secondary" type="button"
                            (click)="isManageCompaniesVisible = true; isCompanySettingsVisible = false;">Back</button>
                    </div>
                    <div>
                        <mat-checkbox (change)="updateNurseCensusSettings($event,currentCompany)" [checked]="showNurseCensus"><b>Enable Census For
                                Nurse</b></mat-checkbox><br />
                        <mat-checkbox (change)="updateNursePatientsSettings($event,currentCompany)" [checked]="showNursePatients"><b>Enable Patients
                                For Nurse</b></mat-checkbox>
                    </div>
                </mat-card>
            </mat-tab>

            <mat-tab label="Tags" *ngIf="currentCompany.company_type != global.COMPANY_TYPE.SNF">
                <ng-template matTabContent>
                    <app-tags [company]="currentCompany"></app-tags>
                </ng-template>
            </mat-tab>

            <mat-tab label="Organizations" *ngIf="currentCompany.company_type != global.COMPANY_TYPE.SNF">
                <ng-template matTabContent>
                    <app-company-organizations [currentCompany]="currentCompany"></app-company-organizations>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>