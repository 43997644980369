<div style="min-height: 100px;">
    <ng-container *ngIf="facility">
    
        <div *ngIf="!permissionsRecorded()">Permissions have not been recorded for this facility.</div>
        
        <table mat-table [dataSource]="permissionKeys">
            <ng-container matColumnDef="label">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    style="width: 5%; text-align: center"
                >
                    Permission
                </th>
                <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    style="width: 5%; text-align: center"
                >
                <div style="max-width: 550px;"  class="flex flex-col items-start justify-start gap-2" style="text-align: left;">
                    <p>
                        <span style="font-weight: bold;">{{ getPermissionLabel(element) }}</span>
                        <br />
                        <small >{{ getPermissionDescription(element) }}</small>
                    </p>
                </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="value">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    style="width: 5%; text-align: center"
                >
                    
                </th>
                <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    style="width: 5%; text-align: center"
                >
                    <ng-container *ngIf="typeof(getPermissionValue(element)) === 'string'"> 
                        {{ getPermissionValue(element) }}
                    </ng-container>
                    <ng-container *ngIf="typeof(getPermissionValue(element)) === 'boolean'">
                        {{ getPermissionValue(element) ? '✅' : '❌' }}
                    </ng-container>
                </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </ng-container>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>