import {Component, ElementRef, ViewChild, Inject, OnInit, NgZone, OnDestroy} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {TemplatesService} from "../../../includes/templates/templates.service";
import {ToastrService} from "ngx-toastr";
import { PhrasesService } from '../phrases.service';
import { DictionaryElement } from '../user-defined-phrases/user-defined-phrases.component';
import { CKEditorService } from '../../note-editor/ckeditor.service';

import {COMMA, ENTER} from '@angular/cdk/keycodes';

import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipEditInput, MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

export interface DictionaryEditorDialogData {
    key:String;
    value:String;
    phrase_type:String
  }

  export interface Fruit {
    name: string;
  }

@Component({
    selector: 'phrase-editor-dialog',
    templateUrl: './phrase-editor-dialog.component.html',
    styleUrls: ['./phrase-editor-dialog.component.css']
})
export class DictionaryEditorDialogComponent implements OnInit, OnDestroy {
    dictionaryElement:any = {key: '', value: '', phrase_type: 'text_based'};
    isNew = false;
    cke4Config;
    
    dictionary: Array<DictionaryElement>;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    currentUserPhrases: any = [];
    uniqueKeyError: boolean = false;
    

    constructor(public dialogRef: MatDialogRef<DictionaryEditorDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DictionaryEditorDialogData,
                private toastr: ToastrService,
                private phrasesService: PhrasesService,
                private ngZone: NgZone,
                private ckeditorService:CKEditorService) {
                    console.log('dataaaaaaa......',this.data);
                    if(data && !Array.isArray(data)) {
                        this.dictionaryElement = data;
                        console.log({data})
                    }
                    this.isNew = data ? false : true;
                    if(data && Array.isArray(data)) {
                        this.currentUserPhrases = this.data;
                        this.isNew = true;
                        console.log("this.currentUserPhrases", this.currentUserPhrases);
                    }
                    
                    this.cke4Config = {
                        ...this.ckeditorService.getDefaultConfig(),
                        editable: true
                    };
                    const extraPlugins = this.cke4Config.extraPlugins.split(',');
                    this.cke4Config.extraPlugins = [...extraPlugins, 'selectdropdown'].join(',')
    }

    ngOnInit(): void {
        this.phrasesService.castPhrases.subscribe(dictionary => {
            this.dictionary = dictionary;      
          })
          this.initGlobalScope()
    }

    initGlobalScope() {
        window['angularComponentReference'] = {
            component: this, zone: this.ngZone,
            componentDataMembers: {
                componentName: 'phrase-editor-dialog',
                selectdropdown: {
                    disableOptionSelection: true
                }
            },

            componentFunctions: {
            }
        };
    }
    isShortcutAlreadyExists(key) {
        return this.isNew && !!this.dictionary?.find(e => e.key === key);
    }

    onReadyCkeditor( e ) {
        const { editor } = e;
        
        this.ckeditorService.setEditorInstance( editor );
        this.ckeditorService.ckeditorRemoveTitle( editor );
    }

    ngOnDestroy() {
        this.ckeditorService.destroyEditorInstance();
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if(!this.dictionaryElement.value || !Array.isArray(this.dictionaryElement.value)) this.dictionaryElement.value = [];
        // Add our fruit
        if ((value || '').trim()) {
            this.dictionaryElement.value.push(value.trim());
        }

        // Reset the input value
        if (input) {
        input.value = '';
        }
    }

    remove(fruit: Fruit): void {
        const index = this.dictionaryElement.value.indexOf(fruit);

        if (index >= 0) {
            this.dictionaryElement.value.splice(index, 1);
        }
    }
    checkDuplicate(key){
        if(this.currentUserPhrases.length > 0) {
            const hasKey = this.currentUserPhrases.find(phrase=> phrase.key === key);
            if(hasKey) {
                this.uniqueKeyError = true;
            }
            else{
                this.uniqueKeyError = false;
            }
        }
    }

    onSave() {
        if ( this.dictionaryElement.phrase_type === 'text_based' ) {
            this.dictionaryElement.value = this.ckeditorService.getEditorInstance().getData();
        }
        this.dialogRef.close( this.dictionaryElement );
    }

    edit(item: any, event: any) {
        const value = event.value.trim();
    
        // Remove fruit if it no longer has a name
        if (!value) {
          this.remove(item);
          return;
        }
    
        // Edit existing fruit
        const index = this.dictionaryElement.value.indexOf(item);
        if (index >= 0) {
          this.dictionaryElement.value[index] = value;
        }
      }
}
