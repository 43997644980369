import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { PatientAddService } from 'src/app/includes/patient-add/patient-add-service';
import { TagsService } from 'src/app/services/tags.service';

@Component({
  selector: 'app-delete-tag-dialogue',
  templateUrl: './delete-tag-dialogue.component.html',
  styleUrls: ['./delete-tag-dialogue.component.css']
})
export class DeleteTagDialogueComponent implements OnInit {
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = ['name'];
  tagId: any;
  patients = [];
  isShowTable = false;
  isCloseDialogue = false;

  constructor(private dialogRef: MatDialogRef<DeleteTagDialogueComponent>, private toastr: ToastrService, private tagsService: TagsService,
    private editPatientService: PatientAddService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.patients = data.patients;
    this.tagId = data.tagId;
  }

  ngOnInit(): void {
    if (this.patients.length > 0)
      this.isShowTable = true;
    this.dataSource.data = this.patients;
  }

  closeDialog() {
    this.isCloseDialogue = true;
    this.dialogRef.close();
  }

  async deleteTag() {
    let selectedTags = [];
    let operations = [];
    this.patients.map(patient => {
      patient.tag_ids_ref.map(tagId => {
        if (tagId != this.tagId) {
          let findTag = selectedTags.includes(tagId);
          if (!findTag)
            selectedTags.push(tagId);
        }
      })
      operations.push({
        updateOne: {
          filter: { _id: patient._id },
          update: { $set: { tag_ids_ref: selectedTags } },
          new: true
        }
      })
      selectedTags = [];

    })
    let res: any = await lastValueFrom(this.editPatientService.editPatient({}, {}, operations));
    if (res.status == 200) {
      let res: any = await lastValueFrom(this.tagsService.deleteTag({ _id: this.tagId }));
      if (res.status == 200)
        this.toastr.success("Tag Disaccociated with patients & Deleted Successfully", "Success");
      else
        this.toastr.error('Something went wrong', 'Error');
    } else
      this.toastr.warning(res.message);

    this.dialogRef.close(res.data);
  }
}
