import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { Observable, lastValueFrom } from 'rxjs';
import * as global from '../../../includes/global';
import { AuthService } from 'src/app/services/auth.service';
import { FacilityService } from 'src/app/services/facility.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { IpaReportService } from './ipa-report.service';
import { SnfWcNurseAssociationService } from '../../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { NursingService } from '../../nursing-module/nursing.service';
import { WoundNurseRelationService } from 'src/app/services/Modules/woundNurse.service';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';

@Component({
  selector: 'app-ipa-report',
  templateUrl: './ipa-report.component.html',
  styleUrls: ['./ipa-report.component.css']
})
export class IpaReportComponent implements OnInit {

  currentUser: any;
  companyID: '';
  companies = [];
  facilities = [];
  providers = [];
  selectedCompany: any;
  selectedFacility: any;
  selectedProvider: any;
  companyName = "";
  filter = {
    company_id: '',
    facility_id: '',
    provider_id: '',
    // status : 'submit',
    reportDateFilter: {
      startDate: moment().subtract(7, 'days'),
      endDate: moment(),
    }
  }
  projection = {};
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  displayedColumns = ['ipa', 'cpt', 'encounter']
  dataSource: any;
  reportData = [];
  selected = 'none';
  searchText: any;
  isSnfNurse: boolean = false;
  isNurse: boolean = false;
  global = global;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private facilityService: FacilityService,
    private ipaReportService: IpaReportService,
    private _location: Location,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
    private _nurseService: NursingService,
    private woundNurseService: WoundNurseRelationService,

  ) {
    this.currentUser = this.authService.currentUser;
    this.companyID = this.route.parent.snapshot.params.id || this.currentUser.company_id
    if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      this.isSnfNurse = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.NURSE) {
      this.isNurse = true;
    }
  }

  async ngOnInit() {
    if (this.currentUser.product_owner) {
      let res: any = await this.companyService.getAllCompanies().toPromise();
      if (res.status == 200) {
        this.companies = res.data
        this.filter.company_id = this.companies[0]._id;
        // this.model.company_id = this.companies[0]._id;
        this.selectedCompany = this.companies[0];
      }
    }
    else {
      let res: any = await this.companyService.getCompanyName(this.companyID).toPromise();
      if (res.status == 200) {
        this.companyName = res.data.name;
        this.selectedCompany = res.data;
        this.filter.company_id = this.companyID
      }
    }
    this.loadData();
  }

  async loadData() {
    if (this.isSnfNurse) {
      const filter = {
        associated_snf_wc_nurse_ids: this.currentUser._id,
      };
      const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      const facilityProjection = {
        title: 1,
        pcc_facId: 1,
        pcc_2_legged_authentication: 1,
        source: 1
      };
      const response: any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
      if (response.status === 200 && response.data.length > 0) {
         this.facilities = response.data;
        this.selectedFacility = this.facilities[0];
        this.filter.facility_id = this.selectedFacility._id;
        this.applyFacilityChange({ value: this.filter.facility_id })
      }
    }
    else if(this.isNurse) {
      const response: any = await lastValueFrom(this._nurseService.getAssociatedFacilities());
      if (response.status === 200 && response.data.array.length > 0) {
        this.facilities = response.data.array
        this.selectedFacility = this.facilities[0];
        this.filter.facility_id = this.selectedFacility._id;
        this.applyFacilityChange({ value: this.filter.facility_id })
      }
    } else if (this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
      const filter = {
        company_id_ref: this.companyID,
      };
      const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      const facilityProjection = {
        title: 1,
        source: 1,
        address : 1,
        zip_code : 1,
        state : 1,
        city : 1 
      };
      const facilitiesResponse: any = await lastValueFrom(this.woundNurseService.getWoundNurseFacilities(filter, nurseProjection, facilityProjection));
      if (facilitiesResponse.status === 200) {
        facilitiesResponse.data.array = facilitiesResponse.data;
        this.facilities = facilitiesResponse.data;
        this.selectedFacility = this.facilities[0];
        this.filter.facility_id = this.selectedFacility._id;
        this.applyFacilityChange({ value: this.filter.facility_id })

      }
    }
    else {
      this.projection = {
        'title': 1,
        'address': 1,
        'city': 1,
        'state': 1,
        'zip_code': 1,
        'is_active': 1,
        'source': 1,
        'provider_ids_ref': 1
      }

      let filter = {
        assoc_company_ids: this.filter.company_id,
        is_active: true
      }

      let providerPopulation: facilityPopulationInterface = {
        collection: 'users',
        field: ["provider_ids_ref"],
        filter: { $expr: { $and: [{ $eq: ["true", '$is_activated'] }, { $eq: [true, { $in: ['$_id', '$$provider_ids_ref'] }] }] } },
        project: {
          first_name: 1, last_name: 1, title: 1, recentLogin: 1, trueRCMID: 1,
          full_name: { $concat: ["$first_name", " ", "$last_name", ", ", "$title"] }
        },
      };
      
      let facilitiesResponse: any = await this.facilityService.getFacilities(filter, this.projection, [providerPopulation]).toPromise();
      if (facilitiesResponse.status == 200) {
        this.facilities = facilitiesResponse.data.array
        this.selectedFacility = this.facilities[0];
        this.filter.facility_id = this.selectedFacility._id;
        this.applyFacilityChange({ value: this.filter.facility_id })
        // this.applyFilter();

      }
    }
    
  }

  applyProviderChange(event) {
    this.filter.provider_id = event.value;
    this.applyFilter();
  }

  async applyFacilityChange(event) {
    this.filter.facility_id = event.value;
    if (event.value) {
      this.selectedFacility = this.facilities.map(fac => {
        if (fac._id == this.filter.facility_id) {
          return fac;
        }
      }).filter(a => a)
      this.selectedFacility = this.selectedFacility[0];
      if(this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
        const filter = { wound_nurse_id : this.currentUser._id, company_id : this.companyID };
        const providersResponse : any = await lastValueFrom(this.woundNurseService.getWoundNurseProviderRelations(filter));
        if (providersResponse.status == 200) {
          this.providers = providersResponse.data[0].associated_provider_ids;
        }
      } else {
        this.providers = this.selectedFacility.provider_ids_ref;
      }
      console.log(this.providers);
      if (this.providers.length > 0) {
        this.selectedProvider = this.providers[0];
        this.filter.provider_id = this.selectedProvider._id;
      }
      else {
        this.selectedProvider = "";
        this.filter.provider_id = "";
      }
    }
    if (this.filter.provider_id) {
      this.applyFilter()
    }
    
  }

  applyDateFilterReport(dates) {
    this.filter.reportDateFilter = { ...dates }
    this.applyFilter();
  }

  getFullName(pro) {
    return pro.first_name + " " +pro.last_name + ", " + pro.title; 
  }

  applyCompanyFilter(event) {
    this.filter.company_id = event.value;
    let com = this.companies.map(c => {
      if (c._id == this.filter.company_id) {
        return c;
      }
    }).filter(a => a)
    this.selectedCompany = com[0];
    this.filter.company_id = this.selectedCompany._id
    // console.log("this.selectedCompany----------- ", this.selectedCompany);
    this.loadData()
  }

  async applyFilter() {
    // console.log("Apply filter called------: ", this.filter);
    this.reportData = [];
    let res: any = await this.ipaReportService.getIpaReport(this.filter).toPromise();
    if (res.status == 200) {
      let data = res.reportData
      let keys = Object.keys(data);
      if (keys.length > 0) {
        for (let key of keys) {
          // console.log(data[key]);
          if (data[key].length > 1) {
            for (let i = 1; i < data[key].length; i++) {
              data[key][i] = JSON.parse(JSON.stringify(data[key][i]));
              data[key][i].insurance = "";
            }
          }
          this.reportData = this.reportData.concat(data[key]);
        }
      }
      // console.log("updated data", this.reportData);
      // this.reportData = res.reportData;
      this.dataSource = new MatTableDataSource(this.reportData)
    }
  }

  downloadIpaReport() {
    this.ipaReportService.downloadIpaReport(this.reportData).subscribe((data: any) => {
      if (data instanceof HttpResponse) {
        // console.log("in httpresponse")
        var url = window.URL.createObjectURL(data.body);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = 'IPA-Report';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }
      else {
        // console.log("in else")
        if (data.type === 0) {
        } else if (data.type === 3) {
          const { loaded, total } = data;
          const progress = Math.round(loaded / total * 100);
          if (progress > 0) {
          }
        }
      }
      if (data.status == 200) {
        // console.log("in status")
        return true;
      }
    })
  }

  searchProvider(filterValue: string) {
    let filter = filterValue.trim().toLowerCase();
    if (filter) {
      let filterVal = [];
      this.reportData.forEach(report => {
        if (report[0].provider.first_name.trim().toLowerCase().includes(filter) || (report[0].provider.last_name.trim().toLowerCase().includes(filter))) {
          filterVal.push(report)
          this.dataSource = new MatTableDataSource(filterVal);
          // this.dataSource = filterVal;
        }
      });
    }
    else {
      this.dataSource = new MatTableDataSource(this.reportData);

    }
  }

  getProviderName(element) {
    if (element[0]?.provider.title) {
      return `${element[0].provider.first_name} ${element[0].provider.last_name},${element[0].provider.title}`
    }
    else if (element[0].provider) {
      return `${element[0].provider.first_name} ${element[0].provider.last_name}`
    }
    else {
      return ""
    }
  }

  getSubmitted(element) {
    if (element[0]._id.status == 'submit') {
      return element[0].total;
    }
    else if (element.length > 1 && element[1]._id.status == 'submit') {
      return element[1].total;
    }
    else {
      return 0;
    }
  }

  getDrafted(element) {
    if (element[0]._id.status == 'draft') {
      return element[0].total;
    }
    else if (element.length > 1 && element[1]._id.status == 'draft') {
      return element[1].total;
    }
    else {
      return 0;
    }
  }

  goBack(){
    this._location.back();
  }


}
