import { Component, OnInit, ViewChild } from '@angular/core';
import { WoundChart } from './model/wound-chart.model';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-wound-chart',
  templateUrl: './wound-chart.component.html',
  styleUrls: ['./wound-chart.component.css']
})
export class WoundChartComponent implements OnInit {
  woundChart: WoundChart;
  forms: WoundChart[] = [];
  canvas: any;
  ctx: any;
  @ViewChild('lineChart') lineChart: any;
  chart: Chart<"line", any[], string>;

  constructor() {
    this.woundChart = new WoundChart(1);
  }

  ngOnInit(): void {
    this.forms.push(this.woundChart);
  }
  addForm() {
    const day = this.forms.length + 1;
    const woundChart = new WoundChart(day);
    this.forms.push(woundChart);
  }
  removeForm(index) {
    this.forms.splice(index, 1);
  }
  showGraph() {
    console.log("showGraph()", this.forms);
    this.createGraph();
  }
  createGraph() {
    this.canvas = this.lineChart.nativeElement;
    this.ctx = this.canvas.getContext('2d');
    if (this.chart) {
      this.chart.destroy();
    }
    this.chart = new Chart(this.ctx, {
      type: 'line',
      data: {
        datasets: [{
          label: 'Area',
          data: this.forms.map(form => form.area),
          backgroundColor: "rgb(115 185 243 / 65%)",
          borderColor: "green",
          // fill: true,
        },
        {
          label: 'Volume',
          data: this.forms.map(form => form.volume),
          backgroundColor: "#47a0e8",
          borderColor: "blue",
          // fill: true,
        }],
        labels: this.forms.map(form => `Day ${form.day}`)
      },
    });

    // new Chart(this.ctx, {
    //   type: 'line',
    //   data: {
    //     datasets: [{
    //       label: 'Current Vallue',
    //       data: [0, 20, 40, 50],
    //       backgroundColor: "rgb(115 185 243 / 65%)",
    //       borderColor: "#007ee7",
    //       fill: true,
    //     },
    //     {
    //       label: 'Invested Amount',
    //       data: [0, 20, 40, 60, 80],
    //       backgroundColor: "#47a0e8",
    //       borderColor: "#007ee7",
    //       fill: true,
    //     }],
    //     labels: ['January 2019', 'February 2019', 'March 2019', 'April 2019']
    //   },
    // });
  }
}
