<table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="allergen">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Allergen
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.allergen}}
        </td>
    </ng-container>
    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Type
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.type}}
        </td>
    </ng-container>
    <ng-container matColumnDef="onsetDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Onset Date
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.onsetDate | date: 'MM/dd/yyyy'}}
        </td>
    </ng-container>
    <ng-container matColumnDef="reactionType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Reaction Type
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.reactionType}}
        </td>
    </ng-container> 
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<p style="padding: 0 10px;" [style.display]="!dataSource || apiData.data?.length > 0 ? 'none' : 'block'">No Known Allergies</p>
<br />
<div *ngIf="apiData" class="flex justify-start items-center gap-2">
    <button mat-raised-button class="dn-primary-button" [disabled]="apiData.paging.page === 1"
        (click)="navigate(-1)">Prev</button>
    <span style="font-size: 12px;">Page {{apiData.paging.page}}</span>
    <button mat-raised-button class="dn-primary-button" [disabled]="!apiData.paging.hasMore"
        (click)="navigate(1)">Next</button>
</div>
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>