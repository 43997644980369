import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TemplateExplorerService } from 'src/app/includes/templates/template-explorer/template-explorer.service';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { AddNewCptCodeComponent } from './add-new-cpt-code/add-new-cpt-code.component';
import { ViewCptCodesComponent } from './view-cpt-codes/view-cpt-codes.component';

@Component({
  selector: 'app-manage-cpt-codes',
  templateUrl: './manage-cpt-codes.component.html',
  styleUrls: ['./manage-cpt-codes.component.css']
})
export class ManageCptCodesComponent implements OnInit {
  public AddNewCptCodeComponent;
  cptCodesDropdownSettings: any = {};
  displayedColumns: string[] = ['company', 'codes','operation'];
  dataSource: any = new MatTableDataSource([]);
  companies: any;
  cpts: any;
  relations: any;
  loaderId = 'company-cpt-association-loader';
  company_id: any;
  isViewEnabled : boolean = false;
  company: any;
  currentUser : any;
  constructor(
    private dialog: MatDialog,
    private companyService: CompanyService,
    private templateExplorerService: TemplateExplorerService,
    private loader: NgxUiLoaderService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private authService : AuthService,
    

  ) {
    this.cptCodesDropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'codeDescription',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 4,
      allowSearchFilter: true,
      enableCheckAll: true,
    };
  }

  async ngOnInit() {
    this.currentUser = this.authService.currentUser;
    this.company_id = this.route.parent.snapshot.params.id;
    this.loader.startLoader(this.loaderId);
    if(this.currentUser.product_owner) {
      await this.initCompanies();
      await this.initcptCodes();
      await this.initRelations();
    }else {
      await this.initcptCodes();
      await this.getCompanyAssociation();
    }

  }

  async initCompanies() {
    return new Promise<void>((resolve, reject) => {
      this.companyService.getAllCompanies().subscribe((res: any) => {
        if (res.status == 200) {
          this.companies = res.data;
          // console.log('this.companies',this.companies);
          this.dataSource = new MatTableDataSource(this.companies);
          resolve(null);
        } else {
          console.log('something went wrong');
        }
      }, err => {
        reject();
        console.log('something went wrong', err);
      });
    })
  }

  async getCompanyAssociation() {
    this.companyService.getCompanyAssociation(this.currentUser.company_id).subscribe((res)=>{
      if(res.status == 200) {
        this.companies = res.data;
        this.dataSource = new MatTableDataSource(this.companies);
        console.log('this.companies',this.companies);
        this.loader.stopLoader(this.loaderId);
      }else 
      console.log('something went wrong');
    });
  }

  async initcptCodes() {
    return new Promise<void>((resolve, reject) => {
      this.templateExplorerService.getAllCPTs().subscribe((response: any) => {
        if (response.status == 200) {
          this.cpts = response.data;
          resolve(null);
        } else {
          console.log('something went wrong');
        }
      }, err => {
        reject();
        console.log('something went wrong', err);
      });
    })
  }

  async initRelations() {
    this.companyService.getCompanyCPTRelations().subscribe((res: any) => {
      if (res.status == 200) {
        this.relations = res.data;
        this.companies.forEach(company => {
          company.cpt_ids = [];
          this.relations.forEach(relation => {
            if (company._id == relation.company_id) {
              company.cpt_ids = relation.cpt_ids;
            }
          });
        });
      this.loader.stopLoader(this.loaderId);
      console.log('companies',this.companies);

      } else {
        console.log('something went wrong');
      }
    });
  }

  handleAddNewCode() {
    this.AddNewCptCodeComponent = this.dialog.open(AddNewCptCodeComponent,
      {
        width: '40%',
        data : this.company_id
      }).afterClosed().subscribe(async(data: any) => {
        if(data) {
          this.loader.startLoader(this.loaderId);
          await this.initcptCodes();
          await this.initRelations();
          this.loader.stopLoader(this.loaderId);
          this.toastr.success('CPT Code Added!', 'Success');
        }
      })
  }

  async onCodeSelect(element, $event) {
    let isCreating = true;
    let cpts = [];
    cpts.push($event._id);
    await this.updateCptRelation(this.currentUser.product_owner ? element._id : element.company_id._id, cpts, isCreating);
  }

  async onCodeDeSelect(element, $event) {
    let isCreating = false;
    let cpts = [];
    cpts.push($event._id);
    await this.updateCptRelation(this.currentUser.product_owner ? element._id : element.company_id._id, cpts, isCreating);
  }

  async onSelectAllCodes(element,$event) {
    let isCreating = true;
    await this.updateCptRelation(this.currentUser.product_owner ? element._id : element.company_id._id, $event, isCreating);
  }

  async onDeSelectAllCodes(element,$event) {
    let isCreating = false;
    await this.updateCptRelation(this.currentUser.product_owner ? element._id : element.company_id._id, $event, isCreating);
  }

  async updateCptRelation(companyId, cptId, isCreating: boolean) {
    this.companyService.updateCptRelation(companyId, cptId, isCreating).subscribe((res: any) => {
      if (res.status == 200) {
        this.toastr.success('Relation Updated!', 'Success');
      } else {
        console.log('something went wrong');
      }
    });
  }

  applyFilter(filterValue: any) {
    this.dataSource.filter = filterValue;
  }

  showDetails(element) {
    this.isViewEnabled = false;
    setTimeout(() => {
        this.isViewEnabled = true;
      }, 5);
    this.company = element;
  }

  exit($event) {
    console.log($event);
    
    this.isViewEnabled = false;
  }

  async updateCPTS($event) {
    console.log('$event',$event);
    if(this.currentUser.product_owner) {
      this.companies.forEach(element => {
        console.log(element);
        if(element._id === $event.company._id) {
          element.cpt_ids = element.cpt_ids.filter(c=> c._id != $event.cpt._id);
        }
      });
      await this.updateCptRelation($event.company._id,$event.cpt._id,false);
    }else {
      this.companies.forEach(element => {
        console.log(element);
        if(element.company_id._id == $event.company._id) {
          element.cpt_ids = element.cpt_ids.filter(c=> c._id != $event.cpt._id);
        }
      });
      await this.updateCptRelation($event.company._id,$event.cpt._id,false);
    }
  }
}