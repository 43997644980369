import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { DialogConfirmationComponent } from '../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { ProfileService } from '../profile/profile.service';
import { UserManagementService } from '../user-management/user-management.service';
import * as global from '../../includes/global'
import { TagsService } from 'src/app/services/tags.service';
import { UserService } from 'src/app/services/Modules/user.service';

@Component({
  selector: 'app-nurse-list',
  templateUrl: './nurse-list.component.html',
  styleUrls: ['./nurse-list.component.css']
})
export class NurseListComponent implements OnInit {

  displayedColumns: string[] = ['first_name', 'last_name', 'email', 'last_login', 'timezone', 'status', 'telemed', 'pcc', 'cognito', 'enable_dictation','user-permissions', 'admin-access'];
  usersFilter = {
    user_type: 'nurse'
  };
  @ViewChild(MatSort) sort: MatSort;
  loading = false;
  public nurses: any = [];
  public currentUser: any;
  public response: any;
  public dataSource: any;
  company_id: any;
  companies: any = [];
  filteredCompanies: Observable<string[]>;
  companyControl = new FormControl();
  currentCompany: any;
  userRoleTypeSubscription: any;
  userNameRegExp: any = "";
  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    PAGE_LIMIT: 50,
    skip: 0
  };
  timeoutId: any;
  hasNursesLoaded: boolean = false;
  selectedRoleType: string;
  selectedUserTypeSubscription: any;
  selectedUserType: any;
  loaderId = 'nurse-list-loader';
  company_type: any;
  global = global;
  selectedPermissions: string[] = [];
  permissionsList: any;
  company: any;
  constructor(
    private loader: NgxUiLoaderService,
    public services: UserTypesRelationshipService,
    private _router: Router,
    private userManagementService: UserManagementService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private _authService: AuthService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private _profileService: ProfileService,
    private commonService: CommonService,
    private tagsService: TagsService,
    private userService: UserService,

  ) {
    this.currentUser = this._authService.currentUser;
    if (this.currentUser.admin_type === "full_admin"  || this.currentUser.admin_type === "system_admin") {
      this.displayedColumns.push('user-type');
    }
    if (this.currentUser.product_owner) {
      this.displayedColumns = ['first_name', 'last_name', 'email', 'last_login', 'timezone', 'status', 'telemed', 'pcc', 'cognito','admin_', 'enable_dictation'];
      this.displayedColumns.push('companies');
    }
    this.displayedColumns.push('operations');
    if (!this.currentUser.product_owner) {
      this.displayedColumns = this.displayedColumns.filter(f => f !== 'two-factor');
    }
    if (!this.currentUser) {
      // if not login, stop and redirect to login #mohsin-dev
      localStorage.setItem('currentUser', 'null');
      this._router.navigate(['/login']);
      return;
    }
    this.loading = true;
    if (window.location.pathname.includes('company') && !window.location.pathname.includes('manage-companies')) {
      this.company_id = this.route.parent.parent.parent.snapshot.params.id;
    }
    else if (window.location.pathname.includes('manage-companies')) {
      this.company_id = this.route.parent.parent.parent.snapshot.params.id;
    }
    if (this.commonService.getLocalStorageItem('admin-Dashboard'))
        this.company = this.commonService.getLocalStorageItem('admin-Dashboard');
    
  }
  ngOnDestroy(): void {
    if (this.userRoleTypeSubscription) {
      this.companyService.changeUserRoleType(null);
      this.userRoleTypeSubscription.unsubscribe();
    }
    if (this.selectedUserTypeSubscription) {
      this.selectedUserTypeSubscription.unsubscribe();
    }
    if (!window.location.pathname.includes('doctor-profile')) {
      localStorage.removeItem("nurse-provider-name")
    }
  }
  async ngOnInit(): Promise<void> {
    this.userService.getUserPermission(this.company_id).subscribe((response: any) => {
      if (response.status !== 200) return;
      this.permissionsList = response.data.filter((permission) => {
        if (permission.key == "is_dictation_enabled" || permission.key == "has_pcc_account" || permission.key == "show_telemedicine" || permission.key == "allow_tag_to_patient") {
          return true;
        }
        return false;
      });
    });
    this.getCompanyType();
    try {
      if (this.currentUser.product_owner) {
        await this.getCompanies();
      }
      this.getCompanyType();
      this.userNameRegExp = localStorage.getItem("nurse-provider-name")
      this.getNursesList();
      this.filteredCompanies = this.companyControl.valueChanges.pipe(
        startWith(''),
        map((value: any) => this.filterCompany(value)));

      this.selectedUserTypeSubscription = this.commonService.castselectedUserType.subscribe(userType => {
        if (userType) {
          this.selectedUserType = userType;
        }
      });

      this.userRoleTypeSubscription = this.companyService.castUserRoleType.subscribe(role => {
        if (role) {
          this.selectedRoleType = role;
          this.getNursesList();
        }
      });
    } catch (err) {
      console.error("err", err);
    }
    if (this.company.portal_type === global.PORTAL_TYPE.NON_WC) {
      this.displayedColumns = this.displayedColumns.filter(column => column != 'telemed' && column != 'pcc' && column != 'enable_dictation');
    }
    else {
      this.displayedColumns = this.displayedColumns.filter(column => column != 'user-permissions' && column != 'admin-access');
      
    }
  }

  async getCompanyType() {
    const company_id = this.company_id;
    await this.companyService.getCompanyName(company_id).subscribe((res: any) => {
      let company = res.data;
      this.company_type = company.company_type
    })
  }

  deleteUser(id: string) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: "Are you sure you want to delete this User ?"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === "true") {
        this._profileService.deleteUser(id).subscribe((res: any) => {
          // this.doctors = this.doctors.filter(user => user._id !== id)
          // this.dataSource.data = [];
          // this.dataSource.data = this.doctors;
          if (res.status == 200)
            this.toastr.success("User Deleted Successfully");
          else
            this.toastr.warning(res.message);
          this.getNursesList();
        });
      } else {
        return;
      }
    })
  }

  private filterCompany(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.companies.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    else {
      return this.companies;
    }
  }

  getCompanies() {

    return new Promise<void>((resolve, reject) => {
      this.companyService.getAllCompanies({ company_type : { $ne : global.COMPANY_TYPE.SNF } }).subscribe(async (response: any) => {
        if (response.status == 200) {
          this.companies = response.data;
          this.currentCompany = this.route.parent.parent.parent.snapshot.params.id
          var index = this.companies.findIndex(company => this.currentCompany === company._id);
          this.currentCompany = this.companies[index];
          this.companies.splice(index, 1);
          this.companyControl.setValue(this.currentCompany?.name);
          resolve(null);
        }
        else if (response.status == 403) {
          this.toastr.error(response.message, "ERROR");
        }
      }, err => {
        reject();
        this.toastr.error(err, "ERROR");
      }
      );
    });
  }

  searchNurses() {
    clearTimeout(this.timeoutId);
    localStorage.setItem("nurse-provider-name", (this.userNameRegExp));
    this.timeoutId = setTimeout(() => {
      this.hasNursesLoaded = true;
      this.getNursesList();
    }, 1000);
  }

  getNursesList() {
    let pageNo = this.pagination.pageNo;
    if (this.userNameRegExp !== '') {
      pageNo = null;
    }
    this.services.getNursesList(this.company_id, pageNo, this.userNameRegExp, this.selectedRoleType).subscribe((data: any) => {
      this.hasNursesLoaded = false;
      this.pagination.totalRecords = data.totalRecords;
      this.response = data;
      this.nurses = this.response.data;
      if (this.userNameRegExp !== "") { //update page count for title search
        this.pagination.totalRecords = data.totalRecords;
        this.pagination.hasNextPage = false;
        this.pagination.hasPrevPage = false;
        this.pagination.skip = 0;
      }
      this.sortUsers(this.nurses);
      if (this.userNameRegExp == "") {
        this.handlePagination();
      }
      // this.nurses.sort(function (a, b) { return a.first_name > b.first_name ? 1 : -1; });
      let perListKeys = this.permissionsList.map(per => per.key)
      this.nurses.forEach(nurse => {
        if (nurse.recentLogin) {
          nurse.recentLogin = moment(nurse.recentLogin).tz('America/Los_Angeles').format('YYYY-MMM-DD h:mm A');
        }
        nurse.companies = this.companies
        this.companies.forEach(company => {
          if (nurse.company_id == company._id) {
            nurse.selectedCompany = company._id;
          }
        });
        if (nurse.admin_type == "full_admin" || nurse.admin_type == "system_admin") {
          nurse.admin = 'full_admin'
        }
        else if (nurse.admin_type == "transcriber_admin") {
          nurse.admin = 'transcriber_admin'
        }
        else if (nurse.admin_type == "none") {
          nurse.admin = 'none'
        }
        nurse.permissions = nurse.permissions.map(per => per.key);
        nurse.permissions = nurse.permissions.filter(per => perListKeys.includes(per))
      });

      this.dataSource = new MatTableDataSource(this.nurses);
    }, console.error, () => this.loading = false);
  }

  handlePagination() {
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    if (this.pagination.skip + this.nurses.length < this.pagination.totalRecords) {
      this.pagination.hasNextPage = true;
    }
    else {
      this.pagination.hasNextPage = false;
    }
    if (this.pagination.skip > 0) {
      this.pagination.hasPrevPage = true;
    }
    else {
      this.pagination.hasPrevPage = false;
    }
  }

  goToPreviousPage() {
    this.pagination.pageNo--;
    this.getNursesList();
  }
  goToNextPage() {
    this.pagination.pageNo++;
    this.getNursesList();
  }

  changeUserActiveStatus(userId: string, status) {
    this.userManagementService.changeUserActiveStatus(userId, status).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.success(response.message, 'Success');
      } else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  updateUserOptionStatus(key, user, status) {
    let updateObject: any = {
      _id: user._id,
    };
    updateObject[key] = status;
    this.userManagementService.updateUser(updateObject, this.company_id).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    });
  }
  changeTelemedicineStatus(provider, status) {
    this.userManagementService.changeTelemedicineStatus(provider._id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  onCompanyChange(element, company) {
    this.companyService.companyChangeForUser(element._id, company).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("Company Changed")
        this.dataSource.data = this.dataSource.data.filter(user => user._id !== element._id);
        // this.getCompanies();
      }
    })
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  sortUsers(arr: any[]) {
    arr.sort((a, b) => a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase()));
  }
  exportUsersAsXLSX() {
    this.loader.startLoader(this.loaderId);
    this.companyService.exportUsersAsXLSX(this.company_id, this.selectedUserType,null,null,this.selectedRoleType).subscribe((response: any) => {
      if (response instanceof HttpResponse) {
        this.loader.stopLoader(this.loaderId);
        // this.toastr.success("Status changed");
        var url = window.URL.createObjectURL(response.body);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `Users`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }
    });
  }
  handleChangeUserType(userId, event) {
    let additionalRole: string = '';
    var update = {
      user_type: event.value,
      user_id: userId
    }
    if (event.value == 'test_provider') {
      additionalRole = 'demo_provider';
    } else if (event.value == 'cnp') {
      additionalRole = 'cnp';
    }
    this._profileService.updateUserType(update, additionalRole).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("User type changed");
        this.getNursesList();
      }
    });
  }
  handleChangeAdminType(userId, event) {
    var update = {
      admin_type: event.value,
      user_id: userId
    }
    this._profileService.updateAdminType(update).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("Admin type changed");
      }
    });
  }
  ResendEmail(userId) {
    const infoToast = this.toastr.info('Sending Email', 'Please wait', { disableTimeOut: true });
    this.companyService.ResendEmail(userId).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.clear(infoToast.toastId);
        this.toastr.success("Email sent successfully");
      }
    });
  }

  changeDictationStatus(provider, status) {
    let updateObject = {
      _id: provider._id,
      is_dictation_enabled: status
    };
    this.userManagementService.updateUser(updateObject).subscribe((response: any) => {

      if (response.status == 200) {
        if (this.currentUser._id === response.data._id) {
          const currentUser = JSON.parse(localStorage.getItem("currentUser"));
          currentUser.is_dictation_enabled = response.data.is_dictation_enabled;
          localStorage.setItem("currentUser", JSON.stringify(currentUser));
        }
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }

  adminType(element) {
    return 'full Admin'
  }
  async changeUserPermissions(element, event) {
    this.selectedPermissions = event.value;
    element.permissions = event.value;

    let response: any = await this.tagsService.getUserTagPermissions(this.permissionsList, element, this.company_id);
    element.permissions = response.elementPermissions;

    let details = {
      userId: element._id,
      company_id: this.company_id,
      filterArray: response.filterArray,
    }

    await this.userService.setUserPermission(details).subscribe((response: any) => {
      if (response.status === 200) {
        console.log("permissions set successfully");
        this.toastr.success("Permission Updated successfully");
      }
    });

  }
  resolvePermission(key) {
    if (key) {
      let index = this.permissionsList.findIndex(permissions => permissions.key == key);
      return this.permissionsList[index].value;
    }
  }
  changeAdminStatus(provider, status) {
    this.userManagementService.changeAdminStatus(provider._id, this.company_id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
}
