<div class="flex flex-row justify-center items-center h100">
    <mat-card class="flex flex-col" style="width: 40%;">
  
      <mat-card-title class="flex flex-col justify-center items-center">
    
        <img [src]="companylogoPath ? companylogoPath : defaultImg">
      </mat-card-title>
   
      <mat-card-content  class="flex flex-col w-full" style="margin-top: 40px;">
  
        <form (submit)="onContactUs(contactUsForm)" class="flex flex-col w-full" #contactUsForm="ngForm">
          <mat-error *ngIf="status != 200 && status!= null">Invalid Email!</mat-error>
          
          <div class="flex flex-row w-full">
            <mat-form-field class="flex-grow" style="margin-right: 30px;">
                <input matInput type="text" name="fname" placeholder="First Name" #fname="ngModel" ngModel>
              </mat-form-field>
              <mat-form-field class="flex-grow">
                <input matInput type="text" name="lname" placeholder="Last Name" #lname="ngModel" ngModel>
            </mat-form-field>
          </div>

          <mat-form-field class="flex-grow">
            <input matInput type="text" name="phone" placeholder="Phone Number" #phone="ngModel" ngModel required>
          </mat-form-field>

          <mat-form-field class="flex-grow">
            <input matInput type="text" name="email" placeholder="Email address" #email="ngModel" ngModel required>
            <mat-error *ngIf="email.invalid">Please enter email</mat-error>
          </mat-form-field>
  
          <mat-form-field class="flex-grow">
            <textarea cols="50" rows="5" matInput type="text" name="msg" placeholder="Type Message" #msg="ngModel" ngModel required>
            </textarea>
            <mat-error *ngIf="msg.invalid">Please enter message</mat-error>
        </mat-form-field>
            <div class="flex flex-row justify-center items-center">
            <button class="theme-btn" style="width: 50%" mat-stroked-button type="submit">Send</button>
          </div>

  
        </form>
  
      </mat-card-content>
  
    </mat-card>
  </div>