import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { AuthService } from 'src/app/services/auth.service';
import * as global from "src/app/includes/global";

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  global = global;
  params: {
    id:string
  }
  constructor(
    private route:ActivatedRoute,
    public _authService: AuthService,
    private companyService:CompanyService,
    public router: Router) { 
      this.params = {
        id: this.route.snapshot.params.id
      };
    }

  ngOnInit(): void {
    if(!this.params.id) {
      const user_id = this._authService.authObject.id;
      this.companyService.getAssociatedCompanyId(user_id, null).subscribe((response:any) => {
        if(response.status === 200) {
          localStorage.setItem('company_id', JSON.stringify(response.data._id))
          this.router.navigate([`./${response.data._id}`], { relativeTo: this.route });
        }
        
      })
    }
    
  }

  isRoute(route) {
    return this.router.url.startsWith(`/company/${this.params.id}/${route}`);
  }

}
