<div class="h-100 flex flex-row xs:flex-col justify-center items-center">
  <mat-card class="w-40 xs:w-full">  
    <mat-card-title class="flex flex-col justify-center items-center">
      <img [src]="companylogoPath ? companylogoPath : defaultImg">

  
        <h2>
          <ng-container  *ngIf="!verifyCodeView">
              Update Password   
          </ng-container>
          <ng-container  *ngIf="verifyCodeView">
              Code Verification   
          </ng-container>
        </h2>
      </mat-card-title>
  
      <mat-card-content class="flex-col">  
        <ng-container  *ngIf="!verifyCodeView">
          <form (submit)="onSubmitWithCognito(loginForm)" #loginForm="ngForm">
            <mat-form-field>
              <input matInput type="text"
                     name="email"
                     placeholder="Email address"
                     #emailField="ngModel" [(ngModel)]="currentUser.email" required readonly>
              <mat-error *ngIf="emailField.invalid">Please enter email</mat-error>
            </mat-form-field>
    
            <mat-form-field>
              <input matInput type="password"
                     name="newPassword"
                     placeholder="New Password"
                     #newPasswordField="ngModel" [(ngModel)]="newPassword" ngModel required [autocomplete]="false" 
                     [type]="hide ? 'password' : 'text'" >
                     <mat-icon matSuffix style="cursor: pointer;" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              <mat-error *ngIf="newPasswordField.invalid">Please enter new password</mat-error>
            </mat-form-field>
          
            
    
            <mat-form-field>
              <input matInput type="password"
                     name="confirmPassword"
                     placeholder="Confirm Password"
                     #confirmPasswordField="ngModel" [(ngModel)]="confirmPassword" ngModel required [autocomplete]="false"
                     [type]="hide ? 'password' : 'text'">
                     <mat-icon matSuffix style="cursor: pointer;" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              <mat-error *ngIf="confirmPasswordField.invalid">Please enter confirm password</mat-error>
            </mat-form-field>
          
          
            
            <mat-error *ngIf="error">{{error}}</mat-error>
  
    
            <div class="flex flex-row justify-center items-center">
              <button mat-raised-button class="dn-button-primary flex w-1/2" mat-stroked-button type="submit">Save</button>
            </div>
            <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
    
          </form>
          <div style="font-size: 12px;">Already have code? <button style="font-size: 12px;" mat-button type="button" (click)="verifyCodeView = !verifyCodeView">Click Here</button></div>
           </ng-container>
        <ng-container *ngIf="verifyCodeView">
          <div style="font-size: 12px;">A verification code has been sent to your email.</div>
          <form (submit)="verifyCode(verifyCodeForm)" #verifyCodeForm="ngForm">
            <mat-form-field>
              <input matInput type="text"
                     name="code"
                     placeholder="Verification Code"
                     #codeField="ngModel" [(ngModel)]="code" required>
              <mat-error *ngIf="codeField.invalid">Please enter verification code</mat-error>
            </mat-form-field>
            <mat-error *ngIf="error">{{error}}</mat-error>
  
    
            <div class="flex items-center justify-center">
              <button mat-raised-button class="dn-button-primary flex w-1/2" mat-stroked-button type="submit">Verify</button>
            </div>
            <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
    
          </form>

          <div class="resend-code">
            If you have not received your temporary authentication code 2 minutes, click 
            <button class="resend-code-link" type="button" mat-button (click)="resendConfirmationCode()" [disabled]="display != '00:00' || disableButton">
                resend
            </button>
            to receive a new code. ({{display}})
          </div>

        </ng-container>

  
      </mat-card-content>
  
    </mat-card>
  </div>
  