<div class="flex justify-between items-start gap-2">
  <div class="pcc-patient-list flex-grow">
    <form [formGroup]="filterFormGroup" class="flex justify-between items-baseline gap-2"
      style="height: 46px;">
      <div class="flex flex-grow justify-start items-baseline gap-2 flex-grow">
        <mat-form-field class="flex-grow">
          <mat-select formControlName="facility">
            <input class="select-input-class" placeholder="Search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
              (keydown)="$event.stopPropagation()">
            <mat-option *ngFor="let facility of facilities | searchFacility : searchText" [value]="facility">
              <app-facility-title [facility]="facility" [defaultSource]="PointClickCare"></app-facility-title>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="flex justify-start items-baseline gap-2 flex-grow">
        <mat-card class="flex-grow" style="padding: 2px;">
          <mat-card-content>
            <input formControlName="patientSearchInput" matInput type="text" style="color:#000; border: none;"
              placeholder="Search Patient">
          </mat-card-content>
        </mat-card>
      </div>
      <div class="flex justify-start items-center gap-5">


        <div class="flex justify-start items-baseline gap-2">
          <mat-form-field style="width: 120px;">
            <mat-select formControlName="patientStatus">
              <mat-option disabled value="">Patient Status</mat-option>
              <mat-option *ngFor="let status of patientStatuses" [value]="status">{{status}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field style="width: 120px;">
            <mat-select formControlName="pageSize">
              <mat-option disabled value="">Page Size</mat-option>
              <mat-option [value]="10">10</mat-option>
              <mat-option [value]="20">20</mat-option>
              <mat-option [value]="50">50</mat-option>
              <mat-option [value]="100">100</mat-option>
              <mat-option [value]="200">200</mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <div class="flex justify-start items-baseline gap-2"
          *ngIf="currentUser.user_type === '2' || currentUser.user_type === 'medical_assistant' || currentUser.user_type === 'business_officer' || currentUser.user_type === global.USER_TYPE.WOUND_NURSE">
          <mat-form-field>
            <mat-select formControlName="providerId">
              <mat-option disabled value="">Provider</mat-option>
              <mat-option *ngFor="let provider of associatedProviders" [value]="provider._id">{{provider.first_name}}
                {{provider.last_name}}, {{provider.title}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="flex justify-end items-center gap-1 pagination" *ngIf="isPaginationVisible()">
        <button mat-raised-button class="dn-button-primary" (click)="navigatePrevNext(-1)"
          [disabled]="pagination.page <= 1 || isLoading">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <div style="color: white;margin-right: 5px;font-size: 12px;min-width: 45px;text-align: center;">Page
          {{pagination.page}}</div>
        <button mat-raised-button class="dn-button-primary" (click)="navigatePrevNext(1)"
          [disabled]="patients.length < pagination.pageSize || isLoading">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </form>



    <button mat-raised-button class="dn-button-primary" style="margin-top: 25px;" *ngIf="selectedPatients.length > 0"
      (click)="importMultiplePatients()">Import {{selectedPatients.length}} Patients</button>

    <div style="position: relative;overflow-y: scroll;max-height: 100vh; margin-top: 25px;">
      <div *ngIf="dataSource && !error2LeggedAuth">
        <table mat-table class="table-row-hover" [dataSource]="dataSource" matSort (matSortChange)="sortData($event);">

          <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef >
              <mat-checkbox [(ngModel)]="isSelected" *ngIf="currentUser.user_type != global.USER_TYPE.WOUND_NURSE" (change)="onSelectAllPatients($event.checked);"
                [checked]="selectedPatients.length === dataSource.data && dataSource.data.length">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox *ngIf="currentUser.user_type != global.USER_TYPE.WOUND_NURSE" [checked]="isPatientSelected(element)" (change)="handleSelectPatient($event, element)">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element">{{element.lastName}}, {{element.firstName}}
              ({{element.medicalRecordNumber}})</td>
          </ng-container>

          <ng-container matColumnDef="date_of_birth" sticky>
            <th mat-header-cell *matHeaderCellDef mat-sort-header>DOB</th>
            <td mat-cell *matCellDef="let element"> {{element.birthDate | date: 'M/d/yyyy'}} </td>
          </ng-container>

          <ng-container matColumnDef="gender">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Gender </th>
            <td mat-cell *matCellDef="let element"> {{element.gender}} </td>
          </ng-container>

          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Location </th>
            <td mat-cell *matCellDef="let element"> {{element.floorDesc}} {{element.roomDesc}} - {{element.bedDesc}}
            </td>
          </ng-container>
          <ng-container matColumnDef="admission_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Admission Date </th>
            <td mat-cell *matCellDef="let element"> {{element.admissionDate | date: 'M/d/yyyy'}} </td>
          </ng-container>
          <ng-container matColumnDef="discharge_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Discharge Date </th>
            <td mat-cell *matCellDef="let element"> {{element.dischargeDate | date: 'M/d/yyyy'}} </td>
          </ng-container>
          <ng-container matColumnDef="payer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Payor </th>
            <td mat-cell *matCellDef="let element"> {{getPayor(element?.payor)}} </td>
          </ng-container> -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
            <td mat-cell *matCellDef="let element"> {{element.patientStatus}} </td>
          </ng-container>
          <ng-container matColumnDef="last_seen">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Seen </th>
            <td mat-cell *matCellDef="let element" [innerHTML]="getLastSeen(element)"></td>
          </ng-container>
          <ng-container matColumnDef="operations">
            <th mat-header-cell *matHeaderCellDef></th>
            <ng-container *matCellDef="let element">
              <td mat-cell>
                <div class="operation-buttons flex flex-inline items-center justify-start gap-4">
                  <i class="icon icon-import" matTooltip="Import" matTooltipPosition="above"
                    (click)="importPCCPatient(element.patientId)"></i>
                </div>

              </td>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="!selectable">
            <tr mat-header-row class="header-row" *matHeaderRowDef="displayedColumns; sticky:true"></tr>
          </ng-container>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>

      <p style="font-size: 12px;padding:8px;color: #FFF;" *ngIf="patientsLoaded && patients.length === 0">
        No patient found
      </p>
      <p style="font-size: 12px;padding:8px;color: #FFF;" *ngIf="error2LeggedAuth">
        {{error2LeggedAuth}}
      </p>
      <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

    </div>

    <div style="color:#FFF" *ngIf="!dataSource && isLoggedIntoPCC()">
      <button *ngIf="facilities.length === 0" mat-raised-button class="dn-button-primary"
        (click)="importPCCFacilities()">Import Facilities</button>
    </div>

  </div>
  <div class="pcc-notifications-wrapper">
    <app-pcc-event-notifications *ngIf="getSelectedFacility()" title="PCC Event Notifications"
      [facilityIds]="[getSelectedFacility()._id]" (onImportPatient)="handleImportPatient($event)">
    </app-pcc-event-notifications>
  </div>
</div>