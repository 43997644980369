<div class="flex justify-start items-start" style="overflow: auto;height: 97%;">
    <mat-card style="min-width: 30%;height: 99%;margin-right: 10px;">
        <mat-card-content style="height: 50rem;" class="col-header">
            <div class="flex flex-col" style="width: 100%;height: 100%;">
                <div class="flex flex-col flex-grow justify-center items-center" style="flex:10% ;">
                    <strong>All CPT Codes</strong>
                    <mat-form-field style="width: 100%;height: 100px;" appearance="fill">
                        <mat-label>Search</mat-label>
                        <input matInput placeholder="Search charges" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="chargeFilter" style="width: 100%;">
                    </mat-form-field>
                </div>
                <div style="overflow: auto; flex: 90%;">
                    <div class="flex flex-col flex-grow">
                        <div *ngFor="let cptCpde of allCPTs | searchCPTCode: chargeFilter;let i=index">
                            <div style="align-items: center;" class="item flex"
                                [class.active-item]="cptCpde.selected === true">
                                <div style="flex: 65%;">
                                    <span style="width: 100%">
                                        <p style="margin-bottom: 0px;font-weight: bold;">{{cptCpde.code}}</p>
                                        <p style="margin-top: 0px;font-size: smaller;">
                                            {{getCPTCodeShortDescription(cptCpde.description)}} </p>
                                    </span>
                                </div>
                                <div style="flex: 30%;" class="flex gap-1">
                                    <button style="min-width: 85px;" *ngIf="cptCpde.code_map !== 'ie'" mat-raised-button (click)="addCodeinMapping(cptCpde._id,'ie')">IE Code</button>
                                    <button style="min-width: 85px;" *ngIf="cptCpde.code_map !== 'fu'" mat-raised-button (click)="addCodeinMapping(cptCpde._id,'fu')">FU Code</button>
                                </div>
                            </div>
                            <div style="padding: 2px 0px 2px 0px" class="divider">
                            </div>
                        </div>
                    </div>
                </div>
                <ngx-ui-loader [loaderId]="masterCptLoader"></ngx-ui-loader>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card style="min-width: 22%;height: 99%;margin-right: 10px;">
        <mat-card-content style="height: 50rem;" class="col-header">
            <div class="flex flex-col" style="width: 100%;height: 100%;">
                <div class="flex flex-col justify-center items-center" style="flex: 10%;">
                    <strong>All Selected IE Codes</strong>
                </div>
                <div style="padding: 2px 0px 2px 0px" class="divider"></div>
                <div style="overflow: auto; flex: 90%;">
                    <div class="flex flex-col">
                        <div *ngFor="let cptCode of allIECodes; let i= index">
                            <div class="flex gap-1 item-uwhCode" style="align-items: center;">
                                <div style="flex:90%">
                                    <span style="width: 100%">
                                        <p style="margin-bottom: 0px;font-weight: bold;">{{cptCode.code}}</p>
                                        <p style="margin-top: 0px;font-size: smaller;">
                                            {{getCPTCodeShortDescription(cptCode.description)}} </p>
                                    </span>
                                </div>
                                <div style="width: 100%; flex:10%;">
                                    <button style="float: right;height: min-content;background: none;border: none;" id="close-image"
                                        (click)="removeCodeMapping(cptCode._id,'ie',i)"><img class="img-sign"
                                            src="assets\icons\minus.png" /></button>
                                </div>
                            </div>
                            <div style="padding: 2px 0px 2px 0px" class="divider">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card style="min-width: 22%;height: 99%;margin-right: 10px;">
        <mat-card-content style="height: 50rem;" class="col-header">
            <div class="flex flex-col" style="width: 100%;height: 100%;">
                <div class="flex flex-col justify-center items-center" style="flex: 10%;">
                    <strong>All Selected FU Codes</strong>
                </div>
                <div style="padding: 2px 0px 2px 0px" class="divider"></div>
                <div style="overflow: auto;flex: 90%;">
                    <div class="flex flex-col flex-grow">
                        <div *ngFor="let cptCode of allFUCodes; let i= index">
                            <div class="flex gap-1 item-uwhCode" style="align-items: center;">
                                <div style="flex: 90%;">
                                    <span style="width: 100%">
                                        <p style="margin-bottom: 0px;font-weight: bold;">{{cptCode.code}}</p>
                                        <p style="margin-top: 0px;font-size: smaller;">
                                            {{getCPTCodeShortDescription(cptCode.description)}} </p>
                                    </span>
                                </div>
                                <div style="width: 100%;flex:10%;">
                                    <button style="float: right;height: min-content;background: none;border: none;" id="close-image"
                                        (click)="removeCodeMapping(cptCode._id,'fu',i)"><img class="img-sign"
                                            src="assets\icons\minus.png" /></button>
                                </div>
                            </div>
                            <div style="padding: 2px 0px 2px 0px" class="divider">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>