import { Injectable } from '@angular/core';import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/includes/dashboard/dashboard.service';
import * as global from '../../includes/global';
import { API_URL } from 'src/environments/api-url';

@Injectable({
  providedIn: 'root'
})
export class TrainingSystemService {
  private _currentUser;
  constructor( 
    private httpclient: HttpClient,
    // private http: HttpClient,
    private _authService: AuthService,
    private toastr: ToastrService,
    private dashboardService: DashboardService) {
    this._currentUser = this._authService.currentUser;
  }

  getAudioChunks(page, filter=null){
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type,
      page,
      filter,
    };
    return this.httpclient.post(global.url + API_URL.TRAINING.getAudioChunks, { details: details }).pipe();
  }

  updateChunkStatus(status, chunkID){
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type, 
      status: status,
      chunkID: chunkID,
    };
    return this.httpclient.post(global.url + API_URL.TRAINING.updateChunkStatus, { details: details }).pipe();
  }

  generateCSV(filter){
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type,  
      filter,
    };
    return this.httpclient.post(global.url + API_URL.TRAINING.generateCSV, {details: details}).pipe()
  }
  getPhrasesCSV(filter){
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type,  
      filter,
    };
    return this.httpclient.post(global.url + API_URL.TRAINING.getPhrasesCSV, {details: details}).pipe()
  }

  getProviders(){
    const details = {
      id: this._currentUser._id,
      auth_key: this._currentUser.auth_key,
      usertype: this._currentUser.user_type,   
    };
    return this.httpclient.post(global.url + API_URL.TRAINING.getProviders, {details: details}).pipe()
  }

  downlaodCSV(csvData){ 
    this.toastr.info('Downloading CSV...');
    const filename = "Training_System"
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
  }

}
