<div>
    <div class="flex justify-start items-between gap-5">
        <div class="flex justify-start items-center w-full">
            <mat-card class="search-card flex-grow">
                <input type="text" class="search-field" placeholder="Search Facilties" name="title"
                    [(ngModel)]="facilityTitleRegExp" (ngModelChange)="searchFacilities()" />
                <mat-spinner *ngIf="hasFacilitiesLoaded" style="margin-left: auto;" matSuffix [diameter]="18">
                </mat-spinner>
            </mat-card>
        </div>
        <div class="flex justify-start items-end patients-page">
            <mat-form-field style="margin-top: 6px !important;">
              <mat-select value="all" (selectionChange)="applySourceFilter($event)">
                <mat-option selected value="all">All</mat-option>
                <mat-option value="dn">DN</mat-option>
                <mat-option value="pcc">PCC</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        <div class="flex justify-start items-center">
            <button mat-raised-button class="dn-button-primary" (click)="downloadAsXLSX()">
                Export As XLSX
            </button>
        </div>
        <div class="flex justify-start items-center gap-2">
            <div class="flex justify-center items-center gap-2 table-nav">
                <button mat-raised-button class="dn-button-primary" (click)="goToPreviousPage()"
                    [disabled]="!pagination.hasPrevPage"> Prev</button>
                <span style="width: 120px">
                    {{facilities.length > 0 ? (pagination.currentRecords - facilities.length + 1) : 0}} -
                    {{pagination.currentRecords}} out of {{pagination.totalFacilities}}</span>

                <button mat-raised-button class="dn-button-primary" (click)="goToNextPage()"
                    [disabled]="!pagination.hasNextPage">Next</button>
            </div>
        </div>
    </div>
    <div style="height: 10px;"></div>
    <div style="height: 70vh;overflow: auto;">
        <table mat-table [dataSource]="dataSource" matSort>
    
            <ng-container matColumnDef="facility">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Facility</th>
                <td mat-cell *matCellDef="let element" style="width: 15%">
                    <img *ngIf="element.source === 'PointClickCare' || element.pcc_facId" src="../../../assets/icons/pcc-icon.svg" 
                                     alt="" style="width: 34px;
                                     height: 17px;
                                     margin-top: -4px;">
                    <img *ngIf="element.source === 'MatrixCare' " src="../../../assets/icons/matrix.png" 
                            alt="" 
                            style="height: 12px;
                            width: 20px;  
                            margin-right: 5px;
                            vertical-align: middle;">
                    <img *ngIf="element.source != 'PointClickCare' && !element.pcc_facId &&element.source != 'MatrixCare'" class="dn-icon"
                        src="../../../assets/img/DN.png" alt="">
                    {{element.title}}
                    <div style="font-size: 11px;">
                        {{element.city? element.city : ''}} <span *ngIf="element.city !== '' && element.city && element.state !== '' && element.state !=='-1'">,</span> 
                        {{element.state == '-1' ? '' : element.state}}               
                    </div> 
                </td>
            </ng-container>
    
            <ng-container matColumnDef="companies">
                <th mat-header-cell *matHeaderCellDef> Companies </th>
                <td mat-cell *matCellDef="let element" style="width: 42%">
                    <app-mat-multi-select-chips [element]="element" [ngModelData]="element.assoc_company_ids || []" [isMulti]="true" [selectAll]="true" [isCompany]="true" [allData]="companies" [providerKey]="'assoc_company_ids'" (onDataSelection)="onDataSelection($event)" (onDataRemove)="onDataRemove($event)"></app-mat-multi-select-chips>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>