import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FacilityService} from '../../../../services/facility.service';
import {AppointmentDataService} from '../../service/data.service';
import {ToastrService} from 'ngx-toastr';
import {PatientListService} from '../../../../includes/patient-list/patient-list-service';
import {AuthService} from '../../../../services/auth.service';
import {Appointment} from "../../../../classes/appointment";

@Component({
    selector: 'app-transcriber-appointment-detail',
    templateUrl: './transcriber-detail.template.html'
})
export class TranscriberDetailComponent implements OnInit {

    goBack = false;
    @Input() appointmentId = '';
    @Output() exit = new EventEmitter<boolean>();
    didExit = false;
    notify_type = 'loading...';

    model = new Appointment();
    loaded = false;

    constructor(private facilityService: FacilityService,
                private service: AppointmentDataService,
                private toastr: ToastrService,
                private authService: AuthService,
                private patientService: PatientListService) {
    }
    async ngOnInit(){
        this.getAppointmentById(this.appointmentId);

    }
    getAppointmentById(id) {
        this.service.getAppointmentById({_id: id}).subscribe( data => {
            this.model = data.data;
            this.processNotifyType();
            this.loaded = true;
        }, error => {
            this.toastr.error('something went wrong', 'Error');
        });
    }
    processNotifyType() {
        if (this.model.notify_type.email === true) {
            this.notify_type = 'Email';
        }
        if (this.model.notify_type.mobile === true) {
            this.notify_type = this.notify_type + ', ' + 'Mobile';
        }
    }
    exitToParent() {
        this.didExit = true;
        this.exit.emit(this.didExit);
    }
    deleteAppointment() {
        this.service.updateAppointment({
            _id: this.appointmentId,
            is_active: false
        }).subscribe((response:any) => {
            this.toastr.success('Appointment Deleted Successfully')
            this.exitToParent();
        })
    }
}
