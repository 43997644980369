import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as global from '../global';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from "../../../environments/api-url";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TherapyService {
  currentUser: any;
  userInfo: { usertype: any; auth_key: any; id: any; };
  maxPT: string;
  maxOT: string;
  maxST: string;
  patientCensusReport: any;
  public object = new Subject();
  public castobject = this.object.asObservable();
  constructor(private httpclient: HttpClient,
    private _authService: AuthService) {
    const currentUser = this._authService.currentUser;
    if (currentUser) {
      // this.currentUser = JSON.parse(currentUser);
      this.currentUser = currentUser;
      this.userInfo = {
        usertype: this.currentUser.user_type,
        auth_key: this.currentUser.auth_key,
        id: this.currentUser._id
      }
    }
  }
  async getPTSoc(patient) {
    this.maxPT = 'loading...';
    this.maxOT = 'loading...';
    this.maxST = 'loading...';
    let patientName = patient.last_name + ', ' + patient.first_name.trim();
    this.getCensusDetailsReport(patientName).subscribe(async (res: any) => {
      if (res.status == 200) {
        this.patientCensusReport = res.data.records;
        await this.normalizeCensusDetailsReport(this.patientCensusReport);
        
      } else {
        console.log('something went wrong');
      }
    });
  }

  async normalizeCensusDetailsReport(data: any) {
    let pts = [];
    let ots = [];
    let sts = [];
    for (let recordsIndex = 0; recordsIndex < data.length; recordsIndex++) {
      const element = data[recordsIndex];
      for (let i = 0; i < element.pt[0].soc.length; i++) {
        const ptSoc = element.pt[0].soc[i];
        const otSoc = element.ot[0].soc[i];
        const stSoc = element.st[0].soc[i];
        const payerType = element.payor_type[i];
        if (!pts.includes(ptSoc)) {
          pts.push(ptSoc);
          this.maxPT = pts[0];
          for (var j = 1; j < pts.length; j++) {
            if (this.fDate(pts[j]) > this.fDate(this.maxPT))
              this.maxPT = pts[j];
          }
        }
        if (!ots.includes(otSoc)) {
          ots.push(otSoc);
          this.maxOT = ots[0];
          for (var j = 1; j < ots.length; j++) {
            if (this.fDate(ots[j]) > this.fDate(this.maxOT))
              this.maxOT = ots[j];
          }
        }
        if (!sts.includes(stSoc)) {
          sts.push(stSoc);
          this.maxST = sts[0];
          for (var j = 1; j < sts.length; j++) {
            if (this.fDate(sts[j]) > this.fDate(this.maxST))
              this.maxST = sts[j];
          }
        }
      }
    }
    let obj : any= {};
    obj.maxPT = this.maxPT;
    obj.maxOT = this.maxOT;
    obj.maxST = this.maxST;
    this.object.next(obj);

    return obj;
  }

  fDate(s) {
    var d = new Date();
    s = s.split('/');
    d.setFullYear(s[2]);
    d.setDate(s[1]);
    d.setMonth(s[0]);
    return d;
  }

  getCensusDetailsReport(patient_name) {
    const details = {
      patient_name
    }
    return this.httpclient.post(global.url + API_URL.THERAPY.getCensusDetailsReport, { details: details }).pipe();
  }

  getGoalStatusReport(patient_name, phrase?) {
    const details = {
      patient_name,
      phrase
    }
    return this.httpclient.post(global.url + API_URL.THERAPY.getGoalStatusReport, { details: details }).pipe();
  }
  getPatientTensReport(patient_name,phrase?) {
    const details = {
      patient_name,
      phrase : phrase
    }
    return this.httpclient.post(global.url + API_URL.THERAPY.getPatientTensReport, { details: details }).pipe();
  }
  getPatientEvalReport(patient_name,phrase?) {
    const details = {
      patient_name,
      phrase : phrase
    }
    return this.httpclient.post(global.url + API_URL.THERAPY.getPatientEvalReport, { details: details }).pipe();
  }
  getWeeklyFunctionalStatusReport(patient_name,phrase?) {
    const details = {
      patient_name,
      phrase : phrase
    }
    return this.httpclient.post(global.url + API_URL.THERAPY.getWeeklyFunctionalStatusReport, { details: details }).pipe();
  }

  getTensReport(patient_name) {
    const details = {
      patient_name
    }
    return this.httpclient.post(global.url + API_URL.THERAPY.getTensReport, { details: details }).pipe();
  }
}
