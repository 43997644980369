<div style="padding: 20px;">
    <div cdkDropList cdkDropListOrientation="horizontal" class="example-list flex flex-row items-start" (cdkDropListDropped)="drop($event)">
        <ng-container *ngFor="let period of timePeriods; let index = index">
            <div class="example-box example-ripple-container" cdkDrag matRipple (click)="tabChange(period,index)"
                [ngStyle]="{'border-bottom':period.is_active ? '3px solid blue' : '' }">
                {{period.name}}
            </div>
        </ng-container>
    </div>
    <div *ngIf="period == 'phrases'">
        <app-phrases></app-phrases>
    </div>
    <div *ngIf="period == 'census-comments'">
        <app-census-comments></app-census-comments>
    </div>
    <div *ngIf="period == 'wound-care-widget'">
        <app-wound-care-widget></app-wound-care-widget>
    </div>
    <div *ngIf="period == 'note-patient-details'">
        <app-note-patient-details></app-note-patient-details>
    </div>
    <div *ngIf="period == 'note-auditory-history'">
        <app-note-auditory-history></app-note-auditory-history>
    </div>
</div>