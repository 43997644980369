import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-all-companies',
  templateUrl: './all-companies.component.html',
  styleUrls: ['./all-companies.component.css']
})
export class AllCompaniesComponent implements OnInit {
  currentUser: any;

  constructor(
    private _authService: AuthService,

  ) {
    this.currentUser = this._authService.currentUser;

  }

  ngOnInit(): void {
  }

  isProductOwner() {
    if (this.currentUser.product_owner == true) return true; else return false;
  }
  isFullAdmin() {
    if (this.currentUser.admin_type == 'full_admin' || this.currentUser.admin_type == 'system_admin') return true; else return false;
  }
  isNormalUser() {
    if (this.currentUser.product_owner == false && this.currentUser.admin_type == 'none' && this.currentUser.is_superadmin == 'false') return true; else return false;
  }
}
