import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { WoundService } from 'src/app/services/wound.service';
import { PatientRoundingSheetService } from '../../patient-rounding-sheets/patient-rounding-sheet.service';

@Component({
  selector: 'app-em-codes',
  templateUrl: './em-codes.component.html',
  styleUrls: ['./em-codes.component.css']
})
export class EmCodesComponent implements OnInit {
  @Input() company_id:string;
  allCPTs: any = [];
  allUWHCPTs: any = [];
  chargeFilter = '';
  uwhChargeFilter = '';
  loaderId = 'app-em-codes';
  masterCptLoader = 'master-cpt-code-loader';
  uwhCptLoader = 'uwh-cpt-code-loader';
  showAddForm = false;
  currentUser: any;
  disableEditing: boolean = false;
  constructor(
    private toastr: ToastrService,
    private loader: NgxUiLoaderService,
    private _authService: AuthService,
    private _patientRoundingSheetservice: PatientRoundingSheetService,
    private _woundService: WoundService
  ) {
    this.currentUser = this._authService.currentUser;
    if (this.currentUser.admin_type != 'system_admin') {
      this.disableEditing = true;
    }
  }

  async ngOnInit(): Promise<void> {
    this.loader.startLoader(this.loaderId);
    await this.getAllCPTs();
    await this.getAllUWHCodes();
    this.loader.stopLoader(this.loaderId);
  }
  async getAllCPTs(search = "") {
    let response: any = await this._patientRoundingSheetservice.getCPTs({ search: search }).toPromise()
    if (response.status == 200) {
      if (search.trim() == '') {
        this.allCPTs.push(...response.data.array);
      }
      else {
        this.allCPTs = response.data.array;
      }
    }
  }
  getCPTCodeShortDescription(description) {
    return (description.length > 38 ? (description.substring(0,35) + '...') : description );
  }
  clearSearchField() {
    this.chargeFilter = '';
  }
  async searchCharge(value) {
    this.loader.startLoader(this.masterCptLoader);
    await this.getAllCPTs(value?.trim()?.toLowerCase());
    this.loader.stopLoader(this.masterCptLoader);
  }
  addNewCode(element) {
    element['company_id'] = this.company_id;
  }
  async getAllUWHCodes(search= ""){
    let response: any = await this._woundService.getAllUWHCode({ search: search, company_id: this.company_id }).toPromise();
    if (response.status == 200) {
      if (search.trim() == '') {
        this.allUWHCPTs.push(...response.data.array);
      }
      else {
        this.allUWHCPTs = response.data.array;
      }
      this.allCPTs.forEach((cpt)=>{
        let index = this.allUWHCPTs.findIndex((uwhcpt) => (uwhcpt?.cpt_id_ref === cpt._id && uwhcpt.description === cpt.description));
        if(index > -1){
          cpt['selected'] = true;
        }else{
          cpt['selected'] = false;
        }
      })
    }
  }
  async cptCodeToUWH(element){
    if (this.currentUser.admin_type != 'system_admin') {
      return
    }

    if(element.selected){

    }else{
      this.loader.startLoader(this.masterCptLoader);
      this.loader.startLoader(this.uwhCptLoader);
      const toastrId = this.toastr.info("Adding New Code","Please Wait...",{disableTimeOut: true});
      let copyDetails = element;
      copyDetails['cpt_id_ref'] = element._id;
      copyDetails['company_id'] = this.company_id;
      delete copyDetails._id;
      let response: any = await this._woundService.addNewUWHCode(copyDetails).toPromise();
      if (response.status == 200) {
        this.allUWHCPTs.push(response.data);
        element.selected = true;
        this.toastr.clear(toastrId.toastId);
        this.loader.stopLoader(this.masterCptLoader);
        this.loader.stopLoader(this.uwhCptLoader);
        this.toastr.success("New Code Added Successfully", "Success");
      }
    }
  }
  async removeUWHCode(element, index){
    if (this.currentUser.admin_type != 'system_admin') {
      return
    }
    if(element.cpt_id_ref){
      this.loader.startLoader(this.masterCptLoader);
    }
    this.loader.startLoader(this.uwhCptLoader);
    const toastrId = this.toastr.info("Removing Code","Please Wait...",{disableTimeOut: true});
    let details = {
      code_id: element._id,
      company_id:this.company_id
    }
    let response: any = await this._woundService.deleteUWHCode(details).toPromise();
    if (response.status == 200) {
      if(element.cpt_id_ref){
        let cptindex = this.allCPTs.findIndex((cpt)=> cpt._id === element.cpt_id_ref);
        if(cptindex > -1){
          this.allCPTs[cptindex].selected = false;
        }
        this.loader.stopLoader(this.uwhCptLoader);
      }
      this.allUWHCPTs.splice(index, 1);
      this.toastr.clear(toastrId.toastId);
      this.loader.stopLoader(this.masterCptLoader);
      this.toastr.success("Code Removed Successfully", "Success");
    }else{
      if(element.cpt_id_ref){
        this.loader.stopLoader(this.uwhCptLoader);
      }
      this.toastr.clear(toastrId.toastId);
      this.loader.stopLoader(this.masterCptLoader);
      this.toastr.error("Something Went Wrong", "Error");
    }
  }
  addNewCptandUWHCode(data){
    data.cptCode['selected'] = true;
    this.allCPTs.push(data.cptCode);
    this.allUWHCPTs.push(data.uwhCode);
    this.showAddForm = false;
  }
}
