<mat-card>
    <mat-card-content class="mat-card-content-custom">
        <mat-tab-group (selectedTabChange)="onTabClick($event)" class="wound-mat-tab-group">
            <mat-tab label="Procedure Types">
                <div class="mat-tab-content" *ngIf="tabIndex == 0">
                    <app-procedure-datasets [company_id]="company_id"></app-procedure-datasets>
                </div>
            </mat-tab>
            <mat-tab label="Groups">
                <div class="mat-tab-content" *ngIf="tabIndex == 1">
                    <app-wound-groups [company_id]="company_id"></app-wound-groups>
                </div>
            </mat-tab>

            <mat-tab label="Data Set 1">
                <div class="mat-tab-content" *ngIf="tabIndex == 2">
                    <app-data-sets [set]="1"></app-data-sets>
                </div>
            </mat-tab>
            <mat-tab label="Data Set 2">
                <div class="mat-tab-content" *ngIf="tabIndex == 3">
                    <app-data-sets [set]="2"></app-data-sets>
                </div>
            </mat-tab>
            <mat-tab label="Tags">
                <div class="mat-tab-content" *ngIf="tabIndex == 4">
                    <app-wound-tags [company_id]="company_id"></app-wound-tags>
                </div>
            </mat-tab>
            <mat-tab label="E&M Codes">
                <div class="mat-tab-content" *ngIf="tabIndex == 5">
                    <app-em-codes [company_id]="company_id"></app-em-codes>
                </div>
            </mat-tab>
            <mat-tab label="Procedure Codes">
                <div class="mat-tab-content" *ngIf="tabIndex == 6">
                    <app-procedure-codes [company_id]="company_id"></app-procedure-codes>
                </div>
            </mat-tab>
            <mat-tab label="Phrase Templates">
                <div class="mat-tab-content" *ngIf="tabIndex == 7">
                    <app-phrase-templates [company_id]="company_id"></app-phrase-templates>
                </div>
            </mat-tab>
            <mat-tab label="Procedure Note Dataset">
                <div class="mat-tab-content" *ngIf="tabIndex == 8">
                    <app-procedure-note-dataset></app-procedure-note-dataset>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>