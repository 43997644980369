import { Component, ViewChild, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { PatientListService } from '../../patient-list/patient-list-service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '../../dashboard/dashboard.service';
import * as global from '../../global';
import { TranscriptionCreateService } from '../../transcription-create/transcription-create.service';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from '@angular/material/dialog';
import { PatientImporterDialog } from '../../patient-rounding-sheets/patient-importer-dialog/patient-importer-dialog';
import * as _ from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import moment from 'moment'
import { Patient } from 'src/app/classes/patient';
import { PatientRoundingSheetService } from '../../patient-rounding-sheets/patient-rounding-sheet.service';
import { lastValueFrom } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { MatrixService } from 'src/app/services/matrix.service';
import { DuplicatePatientDialogComponent } from '../duplicate-patient-dialog/duplicate-patient-dialog.component';
import { resolve } from 'path';
import { PatientImporterRecurrentComponent } from '../../patient-rounding-sheets/patient-importer-recurrent/patient-importer-recurrent.component';
import { SnfWcNurseAssociationService } from '../../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';


interface IFilter {
  active: boolean,
  patientName: string,
  dob: String,
  facility: String,
  provider: any,
  patientStatus: String
  // index: String,
}

export interface OnInit {
  ngOnInit(): void;
}


@Component({
  selector: 'app-matrix-patients',
  templateUrl: './matrix-patients.component.html',
  styleUrls: ['./matrix-patients.component.css']
})

export class MatrixPatientsComponent implements OnInit {
  currentUser: any;
  assocPatient = false; 
  loading: boolean = false;
  global = global;
  allPatients: Patient[] = [];
  patients: Patient[] = [];
  facilities: Array<any> = [];
  assocProviders: Array<any> = [];
  displayedColumns: string[] = [];
  dataSource: any; 
  filterDropdownActive = false;
  dateOfBirth: any;
  dob: any;
  filter: IFilter;
  appliedFilter: IFilter;
  public pagination: {
    pageNo: number,
    hasNextPage: boolean,
    hasPrevPage: boolean,
    totalCount: number,
    totalPages: number
  };
  filterFormGroup: FormGroup;
  loaderId = 'patient-list-loader';
  inprogress = false; 
  activeAlphabeticBarIndex = 'All';
  patientRoundingSheets: any[] = [];
  filterpatientRoundingSheets: any[] = [];
  selectedPatients: any[] = [];
  selectedPatientRoundingSheet: any;

  @Input() selectable;
  @Input() provider: any;
  @Input() facility: any;
  @Input() patient: any;

  @Output() onAddNewClick: EventEmitter<any> = new EventEmitter();
  @Output() onEditClick: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteClick: EventEmitter<any> = new EventEmitter();
  @Output() onDetailClick: EventEmitter<any> = new EventEmitter();

  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  searchTextProvider: any;
  searchText: any;
  timeoutId: any;
  source = 'MatrixCare';
  patientStatuses = ['All', 'Current', 'Discharged', 'New']; 
  newImportedList: any;

  constructor(
    private _patientListService: PatientListService,
    private _router: Router,
    private _toastr: ToastrService,
    private _changeDetectorRefs: ChangeDetectorRef,
    private transcriptionCreateService: TranscriptionCreateService,
    private _dashboardService: DashboardService,
    public commonService: CommonService,
    private authService: AuthService,
    private dialog: MatDialog,
    private loader: NgxUiLoaderService,
    private _patientRoundingSheets: PatientRoundingSheetService,
    private _transcriptionCreateService: TranscriptionCreateService,
    private matrixService: MatrixService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService
  ) {
    this.currentUser = this.authService.currentUser;
    this.pagination = {
      pageNo: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalCount: 0,
      totalPages: 0
    };
    this.filter = this.filterInitialState();
  }
  @ViewChild(MatSort) sort: MatSort;


  async ngOnInit() {  
    // JSON.parse removed causing parse errors for patients i.e JSON.parse(this.currentUser)
    this.currentUser = this.currentUser;
    if (!this.currentUser) {
      // if not login, stop and redirect to login #mohsin-dev
      localStorage.removeItem('currentUser');
      this._router.navigate(['/login']);
      return;
    }

    // show operations column only for doctor
    // if(this.currentUser.user_type == 1) {
       
      this.displayedColumns = ['checkbox','name', 'date_of_birth','location', 'admission_date',  'payor', 'operations']//'last_seen',    //  'facility',
      // 'operations'];
    if (this.provider) {
      this.filter.provider = this.provider._id;
      console.log("ngOninit: provider: ", this.provider)
    }
    if (this.facility) {     
      this.filter.facility = this.facility._id;
      console.log("ngOninit: facility: ", this.facility)
    }
    if (this.selectable) {
      this.displayedColumns = ['checkbox','name', 'date_of_birth','location', 'admission_date',  'payor']//'last_seen',    //  'facility',
      // 'operations']; 
      
    }
    await this.initFacilities(this.currentUser._id);
    // await this.initAssocProviders();
    // await this.initProvoders();

    // if (this.assocProviders?.length > 0) {
    //   if (localStorage.getItem("patient_list_provider") !== null) {
    //     await this.initFacilities(localStorage.getItem("patient_list_provider"));
    //   }
    //   else {
    //     // console.log("cond")
    //     await this.initFacilities(this.currentUser._id);
    //   }
    // }
    if (this.facilities?.length > 0) {
      this.initPatients(1);
      if (this.currentUser.user_type === global.USER_TYPE.DOCTOR || this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
        console.log("ran")
        this.initPatientRoundingSheets(this.currentUser._id, this.filter.facility);
      }
      // else if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER || this.currentUser.user_type === global.USER_TYPE.BUSINESS_OFFICER) {
      //   this.initPatientRoundingSheets(localStorage.getItem("patient_list_provider"),localStorage.getItem("patient_list_facility"));
      // }
      // else if(this.currentUser.user_type === global.USER_TYPE.BUSINESS_OFFICER){
      // }
    }
  }
  async initProvoders(){
    let response:any = await this._patientListService.getProviders(this.currentUser.company_id, this.filter.facility).toPromise();
    if(response.status == 200){
      this.assocProviders = [];
      this.assocProviders = response.data.provider_ids_ref;
        // this.assocProviders = [this.currentUser, ...assocProvidersResponse.data.assoc_provider_id];
        // this.assocProviders.unshift(this.currentUser);
        this.assocProviders = this.assocProviders.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));;
        console.log("this.assocProviders: ", this.assocProviders)
    }
    // if (!this.filter.provider) {
    //   this.filter.provider = this.currentUser._id
    //   if (localStorage.getItem("patient_list_provider") !== null) {
    //     this.filter.provider = localStorage.getItem("patient_list_provider");
    //   }
    // }
  }
    // getProviders(company_id = '', facility_id = ""){
    //   const details = {
    //     id: this.currentUser._id,
    //     auth_key: this.currentUser.auth_key,
    //     usertype: this.currentUser.user_type,
    //     company_id: company_id, 
    //     facility_id: facility_id
    //   };
    //   return this.httpclient.post(global.url + API_URL.FACILITY.getAllProvidersAssociatedToFacility, {details: details});
    // }
  // }

  handlePatientStatusChange(e){
      this.filter.patientStatus=e;
      console.log("this.filter.patientStatus=e : ", this.filter.patientStatus, e);
      this.selectedPatients = [];
      this.filterOnPatiens();
  } 


  async importMatrixPatient(matrixPatientId) {

    let primaryProviderId;
    if (this.currentUser.user_type == global.USER_TYPE.TRANSCRIBER || this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type == global.USER_TYPE.BUSINESS_OFFICER) {
      primaryProviderId = null; //this.selectedProviderId;
    } else if (this.currentUser.user_type == global.USER_TYPE.DOCTOR || this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      primaryProviderId = this.currentUser._id;
    }

    if (!primaryProviderId) {
      return this._toastr.warning(
        'Please select primary Provider to import patient.',
        'Warning'
      );
    }
    const infoToast = this._toastr.info('Importing Patient', 'Please wait', {
      disableTimeOut: true,
    });
    const selectedFacilityId = this.filter.facility;

      return new Promise((resolve, reject) => {
  
      const Matrix_patient_id=matrixPatientId?._id?matrixPatientId?._id:matrixPatientId;

      this.matrixService.importMatrixPatient(Matrix_patient_id, primaryProviderId, selectedFacilityId)
      .subscribe(
        (response: any) => {
          this._toastr.clear(infoToast.toastId)
          if (response.status === 200) {
            // this.toastr.success('Imported Successfully', 'Success');
            const patient = response.data;
            console.log('patient', patient);
            if (response.message == 'Patient already exists') {
              // this._toastr.warning(
              //   'Patient already exists.',
              //   'Warning'
              // );
              if(patient?.length>1){
                
                  const dialogRef = this.dialog.open(DuplicatePatientDialogComponent, {
                    width: '70%',
                    height: '50%',
                    data: {
                        incoming: matrixPatientId,
                        existing: patient,
                        source: 'MatrixCare'
                    }
                  });
                  dialogRef.afterClosed().subscribe(async (data: any) => {
                    console.log("add existing data : ", data);
                      if (data&&data.addnew==true) {
                        this.matrixService.importMatrixPatient(Matrix_patient_id, primaryProviderId, selectedFacilityId, true).subscribe((res:any)=>{
                          if (res.status === 200) {
                              console.log("response.dataaaa : ", res.data);
                              if(res.data){
                                this.newImportedList.push(res.data);
                              }
                              resolve(1);
                          }
                        })
                      }else if (data&&data.addnew==false&&data.patient){
                        const source_patients=data.source_patients;
                        this._toastr.info('Merging in progress...');
                        this.matrixService.mergeMatrixPatient(Matrix_patient_id, data.patient._id, source_patients,primaryProviderId, selectedFacilityId).subscribe((res:any)=>{
                          if (res.status === 200) {
                              console.log("response.dataaaa : ", res.data);
                              if(res.data){
                                this.newImportedList.push(res.data);
                              }
                              resolve(1);
                          }
                        })
                      }else{
                        resolve(1)
                      }
                  });

              }else if(patient?.length==1){
                if(patient[0]){
                  this.newImportedList.push(patient[0]);
                }
                resolve(1);
              }

            }else{
              this.newImportedList.push(patient);
              resolve(1);
            }
          }
        }
      );
    });
    // }


  }

  getAdmissionDate(patient){
    if(patient.last_admit_date_obj){
      let year=patient.last_admit_date_obj.year;
      let month=patient.last_admit_date_obj.month;
      let day=patient.last_admit_date_obj.day;
      if (month < 10) {month = '0' + month;}
      if (day < 10) {day = '0' + day;}
      return (month+'/'+day+'/'+year);
    }else if(patient.last_admit_date) {
      return moment.tz(patient.last_admit_date, "UTC").format('MM/DD/YYYY');
    } else {
      return '';
    }
  }

  getMatrixPayor(patient){
    if(patient && patient.matrix_payload?.coverages?.length > 0){
      let payor = ''
      for(let i =0 ; i < patient.matrix_payload.coverages.length; i++){
        if(patient.matrix_payload.coverages[i].insurance_type == 'primary'){
          payor =  patient.matrix_payload.coverages[i].insurance_company_name;
        }
        if (payor) return payor;
      }
      if(!payor){
        return patient.matrix_payload.coverages[0].insurance_company_name
      }
    }
    else if(patient && patient.insurances){
      return patient.insurances.p_insurance_carrier
    }
    else{
      return '';
    }
  }


  getPatientLocation(element){
    if(element?.matrix_payload){
      // return element.matrix_payload.facility_id_pointOfCare + "-" + element.matrix_payload.bed_number
      
      let location = ''
      if(element?.last_room_num){
        location = element?.matrix_payload?.facility_id_pointOfCare + '-' + (element?.last_room_num||'');
      }
       else{
         location = element?.matrix_payload?.facility_id_pointOfCare
       }
      return location;
    }
  }
  initPatientRoundingSheets(provider_id?, facility_id?) {
    // const details = {
    //   ...this.currentUserDetails
    // }
    console.log("initPatientRoundingSheets", facility_id);

    this._patientRoundingSheets.getCensusList(facility_id, provider_id).subscribe((response: any) => {
      if (response.status === 200) {
        this.patientRoundingSheets = response.data.array;
        this.filterpatientRoundingSheets = response.data.array;
        console.log("this patientRoundingSheets", this.patientRoundingSheets);
      }
    })
  }
  filterInitialState(): IFilter {
    return {
      active: true,
      patientName: '',
      dob: '',
      facility: '',
      provider: '',
      patientStatus:'All'
      // index:'All',
    };
  }


  async initPatients(page, index = null) {
    var details = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <any>null,
      primary_doctor_id: <string>null,
      index: <string>null,
      assocPatient:null
    };
    details.id = this.currentUser._id;
    details.auth_key = this.currentUser.auth_key;
    details.usertype = this.currentUser.user_type;
    details.index = index;
    // details.assocPatient = this.assocPatient;
    this.loader.startLoader(this.loaderId);

    // if(!this.assocPatient){
      // if (this.filter.facility) {
      // this.filter.facility = "";
      // this.filter.provider = this.currentUser._id;
      details.assocPatient = "matrixP"
      await this.getPatientsByFacilityId(details, page);
      // await this.getMatrixPatients(details, page);
      
      // } 
      // else {
      //   if (details.usertype == 1) {
      //     await this.getPatientsForDoctor(details, page);
      //   } else {
      //     await this.getPateintsForTranscriber(details, page);
      //   }
      // }
    // }
    // else{
    //   this.getPatientsV2(index, page);
    // }
    this.loader.stopLoader(this.loaderId);

    // this.applyFilterDate();
  }
  async getMatrixPatients(item, page){
    const response: any = await this._patientListService.getMatrixPatients(item, page, this.filter).toPromise();
    if (response.status === 200) {
      this.allPatients = response.data.array
        .filter(patient => patient.is_active === 'true');
      this.allPatients.forEach(element => {
        if (element.date_of_birth_d) {
          // console.log('element.date_of_birth_d', element.date_of_birth_d);
          element.date_of_birth_d = element.date_of_birth_d.substr(0, 10)
        }
      });
      const totalCount = response.data.totalCount;
      this.pagination.totalCount = totalCount;
      this.pagination.pageNo = page;
      this.initPagination();
      this.initTableDataSource(this.allPatients);
      this.dataSource.sort = this.sort;
    }
  }

  addPatient(){
    console.log('yess medical assistant');
  }
  getLastSeen(patient) {
    let last_seen='-';
    if(patient?.last_seen){
        if(Array.isArray(patient?.last_seen)&&patient?.last_seen?.length){

            for(let element of patient?.last_seen) {
                if(element.company_id?.toString().includes(this.currentUser?.company_id?.toString())){
                  if (element.user_type && element.user_type === global.USER_TYPE.WOUND_NURSE) {
                    last_seen = `<strong>(WN)</strong> ${element.last_seen || ''}`;
                    break;
                  } else {
                    last_seen = element.last_seen || '';
                    break;
                  }
                }
            };

        }else{
            last_seen= patient?.last_seen||'';
        }
    }
    return last_seen;
    // let lastSeen = '';
    // const { recentNote } = patient;
    // if (recentNote && recentNote.doctor_id_ref) {

    //   const { first_name, last_name } = recentNote.doctor_id_ref;
    //   lastSeen += `${first_name[0]}${last_name[0]}`.toUpperCase();
    //   // console.log("recentNote", recentNote);
    //   // lastSeen += ` - ${moment(new Date(recentNote.filterServiceDate).toLocaleString('en-US', { timeZone: recentNote.timezone })).format('M/D/YYYY')}`;
    //   if (recentNote.date_obj) {
    //     lastSeen += ` - ${this.commonService.dateObjToString(recentNote.date_obj, false)}`;
    //   }
    //   else {
    //     const [year, month, day] = this.commonService.convertDateToStringforMoment(moment(recentNote.filterServiceDate)).split("-");
    //     lastSeen += ` -${month}/${day}/${year}`;
    //   }
    // }
    // return lastSeen;
  }
  initTableDataSource(patients) {
    this.dataSource = new MatTableDataSource(patients);
  }
  async getPatientsV2(index, page) {
    const provider = this.assocProviders.find(provider => provider._id === this.filter.provider);
    const company_id = provider.company_id;
    let facility_id = this.filter.facility;
    const searchText = this.filter.patientName;
    const dob = this.filter.dob;
    let provider_id;
    if(!facility_id || facility_id === 'undefined') {
      provider_id = provider._id;
      facility_id = undefined;
    }
    const { data, status } = await lastValueFrom(this._patientListService.getPatientsV2(company_id, facility_id, provider_id, searchText, dob, page, index)) as any;
    if (status === 200) {
      this.allPatients = data.array.filter(patient => patient.is_active === 'true');
      const totalCount = data.totalCount;
      this.pagination.totalCount = totalCount;
      this.pagination.pageNo = page;
    } else {
      this.allPatients = [];
      this.pagination.totalCount = 0;
      this.pagination.pageNo = 1;
    }
    this.initPagination();
    this.initTableDataSource(this.allPatients);
    this.dataSource.sort = this.sort;
  }

  async getPatientsByFacilityId(item, page) {
    const response: any = await this._patientListService.getMatrixPatientByFacilityId(item, page, this.filter).toPromise();
    
    // const response: any = await this._patientListService.getPatientsByFacilityId(item, page, this.filter).toPromise();
    if (response.status === 200) {
      this.allPatients = response.data.array
        .filter(patient => patient.is_active === 'true');
      this.allPatients.forEach(element => {
        if (element.date_of_birth_d) {
          // console.log('element.date_of_birth_d', element.date_of_birth_d);
          element.date_of_birth_d = element.date_of_birth_d.substr(0, 10)
        }
      });
      const totalCount = response.data.totalCount;
      this.pagination.totalCount = totalCount;
      this.pagination.pageNo = page;
      this.initPagination();
      this.initTableDataSource(this.allPatients);
      this.dataSource.sort = this.sort;
    }
  }
  async getPatientsForDoctor(item, page) {

    // DOCTOR 1
    // const infoToast = this._toastr.info("Fetching patients...", "Please wait");
    const response: any = await this._patientListService.getPatientList(item, page, this.filter.active ? this.filter : null).toPromise();
    if (response.status === 200) {
      this.allPatients = response.data.array
        .filter(patient => patient.is_active === 'true');

      const totalCount = response.data.totalCount;
      this.pagination.totalCount = totalCount;
      this.pagination.pageNo = page;
      this.initPagination();
      this.initTableDataSource(this.allPatients);
      this.dataSource.sort = this.sort;
    }
  }
  async getPateintsForTranscriber(item, page) {
    // TANSCRIBER 2
    // const infoToast = this._toastr.info("Fetching patients...", "Please wait");
    // if (this.associated_doctors_ids) {
    //   item.associated_doctors_ids = this.associated_doctors_ids; // use prop ids to get patients
    //   await this.getPatients(item, page);
    // } else {
    // }

    const associatedDoctorsListResponse: any = await this.transcriptionCreateService.getAssociatedDoctorsList(this.currentUser.company_id).toPromise();

    if (associatedDoctorsListResponse.status === 200) {
      // const associatedDoctorsList = associatedDoctorsListResponse.data.array; // array
      const associatedDoctorsList = associatedDoctorsListResponse.data; // array
      const associatedDoctorsIds = associatedDoctorsList.map(d => d._id);
      item.associated_doctors_ids = associatedDoctorsIds; // additional parameter
      await this.getPatients(item, page);
    }
  }
  isPCCFacility(facility_id) {
    for(const facility of this.facilities) {
      if(facility_id === facility._id) {
        if(facility.pcc_facId) {
          return true;
        }
      }
    }
  }
  isMatrixFacility(facility_id) {
    for(const facility of this.facilities) {
      if(facility_id === facility._id) {
        if(facility.source == "MatrixCare") {
          return true;
        }
      }
    }
  }
  getDOB(patient) {
    if (patient.date_of_birth) {
      let splitDate = patient.date_of_birth.split('-');
      let month = splitDate[1];
      let day = splitDate[2];
      let year = splitDate[0];
      let newDate = month + '-' + day + '-' + year;
      return newDate;
    } else if (patient.date_of_birth_d) {
      return patient.date_of_birth_d;
    } else {
      return 'Jan 1, 1955';
    }
  }

  async getPatients(item, page) {
    const patientsResponse: any = await this._patientListService.getPatientList(item, page, this.filter.active ? this.filter : null).toPromise();
    if (patientsResponse.status == 200) {
      this.allPatients = patientsResponse.data.array
        .filter(patient => patient.is_active === 'true')
      const totalCount = patientsResponse.data.totalCount;
      this.pagination.totalCount = totalCount;
      this.pagination.pageNo = page;
      this.initPagination();
      this.initTableDataSource(this.allPatients);
      this.dataSource.sort = this.sort;
    }
  }

  initPagination() {
    this.pagination.totalPages = Math.ceil(this.pagination.totalCount / 20);
    if (this.allPatients) {
      this.pagination.hasNextPage = this.allPatients.length > 0 && this.pagination.pageNo < this.pagination.totalPages;
    }
    this.pagination.hasPrevPage = this.pagination.pageNo > 1;
    // this.loader.stopLoader('loader-01');
  }
  handleNextClick() {
    this.initPatients(this.pagination.pageNo + 1);
  }

  handlePrevClick() {
    this.initPatients(this.pagination.pageNo - 1);
  }
  getcountofPatients() {
    return ( this.allPatients.length > 0 ? (this.pagination.pageNo * 20) - 19 : 0 ) + ' - ' + (((this.pagination.pageNo * 20) - 20) + Number(this.allPatients.length)) + " out of " + (this.pagination.totalCount);
  }
  
  editPatient(patient_id, onSuccess?) {

    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      patient_id: <string>null,
    };
    item.id = this.currentUser._id;
    item.auth_key = this.currentUser.auth_key;
    item.usertype = this.currentUser.user_type;
    item.patient_id = patient_id;

    // const infoToastr = this._toastr.info('Fetching patient info...', "Please wait");
    this._patientListService.getPatient(item).subscribe((response: any) => {
      if (response.status === 200) {
        const { patient } = response.data;
        patient.gender = patient.gender?.charAt(0).toUpperCase() + patient.gender.substring(1).toLowerCase();
        localStorage.setItem('editPatient', JSON.stringify(patient));
        if (onSuccess) {
          onSuccess.emit();
        }
      } else {
        this._toastr.error(response.message, "Failed");
      }
    }, console.error, () => {
      // this._toastr.clear(infoToastr.toastId)
    })
  }

  deletePatient(patient_id, onSuccess?) {
    if (!confirm("Are you sure you want to delete?")) return;
    var item = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <string>null,
      patient_id: <string>null,
      is_active: <string>null,
    };
    item.id = this.currentUser._id;
    item.auth_key = this.currentUser.auth_key;
    item.usertype = this.currentUser.user_type;
    item.patient_id = patient_id;
    item.is_active = "false";

    const infoToastr = this._toastr.info('Deleting patient...', "Please wait");
    this._patientListService.deletePatient(item).subscribe((response: any) => {
      if (response.status === 200) {
        this._toastr.success(response.message, "Success");

        this.allPatients = this.allPatients.filter((patient: any) => patient.patient_id !== patient_id);
        this.initTableDataSource(this.allPatients);
        this._changeDetectorRefs.detectChanges();
        if (onSuccess) {
          onSuccess.emit();
        }
      } else {
        this._toastr.error(response.message, "Failed");
      }
    }, console.error, () => { this._toastr.clear(infoToastr.toastId) })
  }

  applyFilter() {
    // this.filter.patientName = filterValue.trim();
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      // this.hasFacilitiesLoaded = true;
      this.initPatients(1)
    }, 1000);
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    // this.dataSource.filterPredicate = function (data, filter: string): boolean {
    //   return data.name.toLowerCase().includes(filter);
    // };
  }
  clearFilter() {
    this.filter.active = true;
    this.filter.dob = '';
    this.filter.patientStatus = 'All';
    this.filter.facility = Array.isArray(this.facilities) && this.facilities.length > 0 ? this.facilities[0]._id : ''
    this.filter.patientName = '';
    this.dob = null;
    this.initPatients(1)
  }

  filterOnPatiens() {
    this.filter.active = true;
    // this.initProvoders();
    this.initPatients(1);
    // if ((this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER || this.currentUser.user_type === global.USER_TYPE.BUSINESS_OFFICER) && this.filter?.provider !== undefined && this.filter?.facility !== undefined) {
    //   console.log('this.filter.provider',this.filter);
    //   this.initPatientRoundingSheets(this.filter.provider, this.filter.facility);
    // }

    // else if (this.currentUser.user_type === global.USER_TYPE.DOCTOR) {
    //   this.initPatientRoundingSheets(this.currentUser._id, this.filter.facility);
    // }

  }
  sortFirstNameVise(arrayTosort) {
    let users = arrayTosort;
    let result = users.sort(function (a, b) {
      if (a.first_name?.toLowerCase() < b.first_name?.toLowerCase()) { return -1; }
      if (a.first_name?.toLowerCase() > b.first_name?.toLowerCase()) { return 1; }
      return 0;
    })
    return result;
  }
  sortTitleVise(arrayTosort) {
    let users = arrayTosort;
    let result = users.sort(function (a, b) {
      if (a.title?.toLowerCase() < b.title?.toLowerCase()) { return -1; }
      if (a.title?.toLowerCase() > b.title?.toLowerCase()) { return 1; }
      return 0;
    })
    return result;
  }
  onDateChange(event) {
    // let date 
    this.dob = '';
    // this.dob = new Date(event.value).toLocaleDateString()
    this.dob = event.value;
    this.filter.dob = moment(event.value).format(global.moment_date_format2);
    this.filterOnPatiens()
  }

  patientDetail(patient_id) {
    localStorage.setItem('patient_id', patient_id);
  }
  handleFilter(result) {
    this.filterDropdownActive = false;
    if (!result) {
      return;
    } else if (result.filter === 'cancel') {
      return;
    } else if (result.filter === 'reset') {
      result = null;

      var details = {
        id: <string>null,
        auth_key: <string>null,
        usertype: <any>null,
        primary_doctor_id: <string>null,
        index: <string>null
      };
      details.id = this.currentUser._id;
      details.auth_key = this.currentUser.auth_key;
      details.usertype = this.currentUser.user_type;
      details.index = this.activeAlphabeticBarIndex;

      // this.initPatients(details);
      return;
    } else if (!result.payload) {
      return;
    }
    else {

      if (result.filter === 'facility') {
        let items = _.cloneDeep(this.allPatients);
        items = items.filter(t => {
          return t.facility_id === result.payload;
        });
        this.dataSource = items;
        return;
      }
      if (result.filter === 'patient') {
        let items = _.cloneDeep(this.allPatients);
        items = items.filter(t => {
          return t.patient_id === result.payload._id;
        });
        this.dataSource = items;
        return;
      }

      this.dateOfBirth = result.payload;
      this.applyFilterDate();
    }

    // reset initialization first

    // this.patients = result;


    // this.init();

  }
  applyFilterDate() {
    if (!this.allPatients) {
      return;
    }
    else {
      this.filterDictationsByDateOfService(this.dateOfBirth);
    }
  }

  filterDictationsByDateOfService(date_of_birth) {
    this.filterItemsByDate(date_of_birth, 'date_of_birth', this.allPatients);
  }
  filterItemsByDate(date, dateKeyInObject, itemList) {
    const filterDateTime = new Date(date);
    const filterDate = filterDateTime.getDate();
    const filterMonth = filterDateTime.getMonth() + 1;
    const filterYear = filterDateTime.getFullYear();

    itemList = itemList.filter(t => {
      const dateTime = new Date(t[dateKeyInObject]);
      const date = dateTime.getDate();
      const month = dateTime.getMonth() + 1;
      const year = dateTime.getFullYear();

      return date === filterDate && month === filterMonth && year === filterYear;
    });
    this.dataSource = itemList;
  }

  onRowClick($event, patient) {
    return;
    if ($event.target.classList.value.indexOf('icon') >= 0) return;
    if (this.selectable) {
      this.onSelect.emit(patient);
    } else {
      this.onDetailClick.emit(patient._id);
    }
  }

  getRecentPatientADTRecord(patient) {
    patient.adtRecords.sort((a, b) => new Date(b.effectiveDateTime).getTime() - new Date(a.effectiveDateTime).getTime())

    return patient.adtRecords[0];
  }

  handleAddPatientToRoundingSheetClick(patient) {
    this.showPatientImporterDialog(patient)

  }
  showPatientImporterDialog(patient) {
    this.dialog.open(PatientImporterDialog, { width: '50%', data: { patient, action: 'import' } });
  }
  isAddToRoundingSheetButtonVisible() {
    return this.currentUser.user_type == global.USER_TYPE.DOCTOR || this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE && this.currentUser.has_pcc_account == 'true'
  }

  handleAphabeticIndexButtonClick(index) {

    // var details = {
    //   id: <string>null,
    //   auth_key: <string>null,
    //   usertype: <any>null,
    //   primary_doctor_id: <string>null,
    //   index:<string>null
    // };
    // details.id = this.currentUser._id;
    // details.auth_key = this.currentUser.auth_key;
    // details.usertype = this.currentUser.user_type;
    // details.index = index; 
    this.initPatients(1, index);
  }

  async initFacilities(provider_id) {
    // const item:any = {
    //   id: this.currentUser._id,
    //   auth_key: this.currentUser.auth_key,
    //   usertype: this.currentUser.user_type
    // }
    if (this.currentUser.user_type == 1) {
      console.log('provider');
      const facilitiesResponse: any = await this.transcriptionCreateService.getFacilities(provider_id, this.source).toPromise();
      if (facilitiesResponse.status === 200) {
        this.facilities = facilitiesResponse.data.array;
        console.log("this.facilities: ", this.facilities)
        this.facilities.sort(function (a, b) { return a.title > b.title ? 1 : -1; });
      }
    } else if (this.currentUser.user_type == 2 || this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type == global.USER_TYPE.BUSINESS_OFFICER) {
      console.log('transcriber or MA or BO');
      // const associatedFacilityOfTranscriberResponse: any = await this._dashboardService.getAssociatedFacilityOfTranscriber().toPromise();
      const associatedFacilityOfTranscriberResponse: any = await this.transcriptionCreateService.getFacilities(provider_id, this.source).toPromise();
      if (associatedFacilityOfTranscriberResponse.status === 200) {
        console.log('facilities', this.facilities);
        this.facilities = associatedFacilityOfTranscriberResponse.data.array;
        localStorage.setItem("patient_list_facility",this.facilities[0]._id);
        if(localStorage.getItem("patient_list_provider") && localStorage.getItem("patient_list_facility")){
          this.initPatientRoundingSheets(localStorage.getItem("patient_list_provider"),localStorage.getItem("patient_list_facility"));
        }
      }
    }
    else if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      const filter = {
        associated_snf_wc_nurse_ids: provider_id,
        source: 'MatrixCare',
      };
      const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      const facilityProjection = {
        title: 1,
        pcc_facId: 1,
        source: 1,
        pcc_2_legged_authentication: 1
      };
      const facilitiesResponse: any =await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
      if (facilitiesResponse.status === 200) {
        this.facilities = facilitiesResponse.data;
        this.facilities.sort(function (a, b) { return a.title > b.title ? 1 : -1; });
      }
    }
    if (!this.filter.facility && Array.isArray(this.facilities) && this.facilities.length > 0) {
      this.filter.facility = this.facilities[0]._id;
      if (localStorage.getItem("patient_list_facility") !== null) {
        this.filter.facility = localStorage.getItem("patient_list_facility");
      }
    }
    // this.initPatients(1);
  }

  async initAssocProviders() { 
    if (this.currentUser.user_type == 2) {
      // console.log('transcriber');
      const assocProvidersResponse: any = await this._dashboardService.getAssociatedDoctorsList(this.currentUser.company_id).toPromise();
      if (assocProvidersResponse.status === 200) {
        // this.assocProviders = assocProvidersResponse.data;
        console.log('assoc providers', assocProvidersResponse.data);
        this.assocProviders = assocProvidersResponse.data;
        // this.assocProviders = [this.currentUser, ...assocProvidersResponse.data.assoc_provider_id];
      }
      if (!this.filter.provider) {
        this.filter.provider = this.currentUser._id
        console.log('filter', this.assocProviders);
        if (localStorage.getItem("patient_list_provider") !== null) {
          this.filter.provider = localStorage.getItem("patient_list_provider");
        }
      }
    }
    if (this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
      // console.log('MA');
      const assocProvidersResponse: any = await this._transcriptionCreateService.getAssociatedDoctorsListForMA(this.currentUser._id).toPromise();
      if (assocProvidersResponse.status === 200) {
        // this.assocProviders = assocProvidersResponse.data;
        console.log('assoc providers', assocProvidersResponse.data);
        this.assocProviders = assocProvidersResponse.data;
        this.filter.provider = assocProvidersResponse.data[0]._id;
        localStorage.setItem("patient_list_provider",this.assocProviders[0]._id);
        this.initFacilities(this.filter.provider);
        // this.assocProviders = [this.currentUser, ...assocProvidersResponse.data.assoc_provider_id];
      }
      if (!this.filter.provider) {
        this.filter.provider = this.currentUser._id
        console.log('filter', this.assocProviders);
        if (localStorage.getItem("patient_list_provider") !== null) {
          this.filter.provider = localStorage.getItem("patient_list_provider");
        }
      }
    }
    else if (this.currentUser.user_type == 1) {
      const assocProvidersResponse: any = await this._dashboardService.getP2PRelation().toPromise();
      if (assocProvidersResponse.status === 200) {
        this.assocProviders = assocProvidersResponse.data.assoc_provider_id;
        // this.assocProviders = [this.currentUser, ...assocProvidersResponse.data.assoc_provider_id];
        this.assocProviders.unshift(this.currentUser);
        this.assocProviders = this.assocProviders.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));;
        console.log("this.assocProviders: ", this.assocProviders)
      }
      if (!this.filter.provider) {
        this.filter.provider = this.currentUser._id
        if (localStorage.getItem("patient_list_provider") !== null) {
          this.filter.provider = localStorage.getItem("patient_list_provider");
        }
      }
    }
    if(this.currentUser.user_type === global.USER_TYPE.BUSINESS_OFFICER){
      this._transcriptionCreateService
      .getAssociatedDoctorsListForBO(this.currentUser._id,this.currentUser.company_id,this.currentUser.user_type)
      .subscribe((response: any) => {
        if (response.status === 200) {
          this.assocProviders = response.data;
          this.filter.provider = response.data[0]._id;
          localStorage.setItem("patient_list_provider",this.filter.provider);
          console.log('this.assocProviders',this.assocProviders);
          if (this.assocProviders?.length > 0) {
            const providerId = this.filter.provider._id || this.assocProviders[0]._id;
            this.initFacilities(providerId);
          }
          if (this.assocProviders?.length > 0) {
            this.assocProviders = this.assocProviders.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));
            if (!this.filter.provider._id) {
              this.filter.provider = this.assocProviders[0]._id;
            }
          }
        }
      });
    }
  }

  handleProviderChange(e) {
    console.log("provider change called:", e);
    console.log("event", this.filter);
    this.filter.provider = e;
    localStorage.setItem("patient_list_provider", e);
    // this.initFacilities(this.filter.provider);
  }
  handleFacilityChange(e) {
    console.log("facility Change called", e);
    this.filter.facility = e;
    localStorage.setItem("patient_list_facility", e);
    this.selectedPatients = [];
    this.filterOnPatiens()
  } 

  isPatientSelected(patient) {
    return this.selectedPatients.findIndex((p) => p._id === patient._id) >= 0;
    // return this.selectedPatients.indexOf(patient._id) >= 0;
  }
  handleSelectPatient(e, patient) {
    const index = this.selectedPatients.findIndex((p) => p._id === patient._id);
    if (e.checked) {
      if (index === -1) {
        if (this.filter.facility === '') {
          if (this.selectedPatients.length <= 0) {
            this.filterpatientRoundingSheets = [];
          }
          for( const census of this.patientRoundingSheets){
              if (census.facility?._id === patient.facility_id_ref?._id) {
                this.filterpatientRoundingSheets = Array.from(new Set([...this.filterpatientRoundingSheets,census]));
              }
          }
        }
        this.selectedPatients.push(patient);
      }
    } else {
      if (index >= 0) {
        this.selectedPatients.splice(index, 1);
        if (this.filter.facility === '') {
          if(this.selectedPatients.findIndex((selectedPatient)=> selectedPatient.facility_id_ref?._id === patient.facility_id_ref?._id) === -1){
            this.filterpatientRoundingSheets = this.filterpatientRoundingSheets.filter(census=>census.facility?._id !== patient.facility_id_ref?._id)
          }
        }
      }
    }
  }

  async doAddToCensus(selectedPatientRoundingSheet, patientList){

    let validPatients = [];
    let not_validPatients = [];
    if (this.filter.facility === "") {
      let selectedCensus = this.patientRoundingSheets.filter((facility) => facility._id === selectedPatientRoundingSheet);
      validPatients = [...patientList];
      not_validPatients = validPatients.filter((validPatient) => validPatient.facility_id !== selectedCensus[0].facility._id);
      validPatients = validPatients.filter((validPatient) => validPatient.facility_id === selectedCensus[0].facility._id);
      validPatients = validPatients.map((patient) => patient._id);
    } else {
      validPatients = patientList.map((patient) => patient._id);
    }
    // await this.handleAddMatrixPatient()
    this._patientRoundingSheets.addPatientsToPatientRoundingSheet(selectedPatientRoundingSheet, validPatients)
      .subscribe((response: any) => {
        if (response.status === 200) {
          if (patientList.length > validPatients.length) {
            let message = ((patientList.length - validPatients.length).toString()) + " Patients are Moved to ";
            not_validPatients.forEach((notValid)=>{
              message += notValid.facility_id_ref.title + " ";
            });
            this._toastr.warning(message);
          } else {
            this._toastr.success(response.message);
          }
          this.selectedPatients = [];
        } else {
          this._toastr.error(response.message);
        }
      })
  }

  async handleAddToCensus() {
     
    // if (!selectedPatientRoundingSheet) {
    //   return this._toastr.error('Please select census', 'Error');
    // }
    this.newImportedList=[];
    for await (let patient of this.selectedPatients){
      await this.importMatrixPatient(patient);
    }
    console.log("this.newImportedList : ", this.newImportedList, this.filter);
    if(this.newImportedList?.length){
      this._toastr.info('Adding Patients to Census');
      const recurrent=false;
      if(recurrent){
        this.showRecurrentPatientImporterDialogV1(this.newImportedList);
      }else{
        this.showPatientImporterDialogV1(this.newImportedList);
      }
      this.newImportedList=[];
    }
    this.selectedPatients = [];
    return;
  }


  showPatientImporterDialogV1(patient) {
    console.log('showPatientImporter Dialog v1', patient);

    const obj = {
      filter_facility_id: this.filter?.facility,
      filter_provider_id: this.currentUser?._id //this.selectedProviderId
    }

    let keyName = 'patient';
    if (Array.isArray(patient)) {
      keyName = 'patients';
    }

    this.dialog.open(PatientImporterDialog, {
      width: '50%',
      data: { [keyName]: patient, action: 'import', only_pcc: false, obj: obj },
    });
  }

  showRecurrentPatientImporterDialogV1(patient) {
    console.log('showRecurrentPatientImporterDialog', patient);

    const obj = {
      filter_facility_id: this.filter?.facility,
      filter_provider_id: this.currentUser?._id //this.selectedProviderId
    }

    let keyName = 'patient';
    if (Array.isArray(patient)) {
      keyName = 'patients';
    }

    this.dialog.open(PatientImporterRecurrentComponent, {
      width: '50%',
      data: { [keyName]: patient, action: 'import', only_pcc: true, obj: obj },
    });
  }

  getDOS(patientRoundingSheet) {
    if (patientRoundingSheet.date_obj) {
      let { year, month, day } = patientRoundingSheet.date_obj;
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      return `${month}/${day}/${year}`;
    }

    // console.log("getdos", patientRoundingSheet)
    if (!('pcc_timeZone' in patientRoundingSheet.facility || 'timeZone' in patientRoundingSheet.facility)) {
      patientRoundingSheet.facility.timeZone = 'America/New_York';
    }
    // console.log('pcc_timeZone' in patientRoundingSheet.facility || 'timeZone' in patientRoundingSheet.facility)
    let timezone = patientRoundingSheet.facility.pcc_timeZone ? patientRoundingSheet.facility.pcc_timeZone : patientRoundingSheet.facility.timeZone;
    let serviceDate = moment(patientRoundingSheet.date).utc().tz(timezone).format('MM/DD/YYYY')
    return serviceDate;
  }


  async handleAddMatrixPatient(patient = null){ 
    // console.log("provider: ", provider)
    if(patient){
      this.selectedPatients = [];
      this.selectedPatients.push(patient)
    }
    if(!patient){
      this.selectedPatients = this.selectedPatients.map(p => p._id).filter(a => a)
    }
    console.log("patient: ", this.selectedPatients) 
    let response:any = await this._patientListService.associatePatients(this.currentUser._id, this.filter.facility , this.selectedPatients, this.currentUser.company_id).toPromise();
    console.log("response------------: ", response)
    if(response.status == 200){
      // this._toastr.success("Patient Associated", "Success");
      // this.selectedPatients = [];
      // this.showPatientImporterDialog(importedPatients);
      // this.initPatients(1);
    }
    else{
      this.selectedPatients = [];
      this._toastr.error(response.message);
    }
  }

  async handleSingleAddToCensus(patient) {
    this.newImportedList=[];
    await this.importMatrixPatient(patient);
    console.log("this.newImportedList : ", this.newImportedList, this.filter);
    if(this.newImportedList?.length){
      this._toastr.info('Adding Patients to Census');
      const recurrent=false;
      if(recurrent){
        this.showRecurrentPatientImporterDialogV1(this.newImportedList);
      }else{
        this.showPatientImporterDialogV1(this.newImportedList);
      }
      this.newImportedList=[];
    }
    return;
  }


 
  changePatientList(event){
    // console.log("event----------", event)
    this.selectedPatients = [];
    this.assocPatient = !this.assocPatient; 
    this.initPatients(1);
  }
  
  sortData(sort: Sort) {
    // console.log("this.allPatients", this.allPatients, sort.active);

    this.allPatients = this.allPatients.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return compare(a.last_name, b.last_name, isAsc);

        case 'date_of_birth': return compare(new Date(a.date_of_birth).getTime(), new Date(b.date_of_birth).getTime(), isAsc);

        case 'gender': return compare(a.gender, b.gender, isAsc);

        case 'location': return compare(a.matrix_payload.facility_id_pointOfCare, b.matrix_payload.facility_id_pointOfCare, isAsc);

        case 'admission_date': return compare(new Date(a.last_admit_date).getTime(), new Date(b.last_admit_date).getTime(), isAsc);

        case 'discharge_date': return compare(new Date(a.last_discharge_date).getTime(), new Date(b.last_discharge_date).getTime(), isAsc);

        case 'payor': return compare(this.getMatrixPayor(a), this.getMatrixPayor(b), isAsc);

        // case 'status': return compare(new Date(a.updation_date.toString()).getTime(), new Date(b.updation_date.toString()).getTime(), isAsc);

        default: return 0;
      }
    });
    this.dataSource = new MatTableDataSource(this.allPatients);

  }


}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

