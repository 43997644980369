import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { lastValueFrom } from 'rxjs';
import { PatientRoundingSheetService } from 'src/app/includes/patient-rounding-sheets/patient-rounding-sheet.service';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/Modules/company.service';

@Component({
  selector: 'app-ie-fu-mapping',
  templateUrl: './ie-fu-mapping.component.html',
  styleUrls: ['./ie-fu-mapping.component.css']
})
export class IeFuMappingComponent implements OnInit {
  allCPTs: any = [];
  allIECodes: any = [];
  allFUCodes: any = [];
  masterCptLoader = 'master-cpt-code-loader';
  loaderId = 'ie-fu-mapping-loader';
  company_id: any;
  codesMapping: any;
  timoutId: NodeJS.Timeout;
  chargeFilter: "";
  constructor(
    private _patientRoundingSheetservice: PatientRoundingSheetService,
    private _companyService: CompanyService,
    private _loader: NgxUiLoaderService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private authService : AuthService,
  ) { 
    this.company_id = this.route.parent.snapshot.params.id;
    console.log(this.company_id);
  }

  async ngOnInit(): Promise<void> {
    this._loader.startLoader(this.loaderId);
    await this.getAllCPTs();
    await this.getIEFUCodesMapping();
    this._loader.stopLoader(this.loaderId)
  }
  async getAllCPTs(search = "") {
    let response: any = await lastValueFrom(this._patientRoundingSheetservice.getCPTs({ search: search }))
    if (response.status == 200) {
      if (search.trim() == '') {
        this.allCPTs.push(...response.data.array);
      }
      else {
        this.allCPTs = response.data.array;
      }
    }
  }
  getCPTCodeShortDescription(description) {
    return (description.length > 38 ? (description.substring(0,35) + '...') : description );
  }
  async getIEFUCodesMapping(){
    let response: any = await lastValueFrom (this._companyService.getIEFUCodesMapping({ company_id: this.company_id }));
    if (response.status == 200) {
      if(response.data){
        this.codesMapping = response.data;
        console.log(this.codesMapping);
        this.allCPTs.forEach((cpt)=>{
          let ie_index = this.codesMapping.ie_codes.findIndex((code)=> code.toString() === cpt._id.toString());
          let fu_index = this.codesMapping.fu_codes.findIndex((code)=> code.toString() === cpt._id.toString());
          if(ie_index > -1 || fu_index > -1){
            if(ie_index > -1){
              cpt['code_map'] = "ie";
              this.allIECodes.push(cpt);
            }else{
              cpt['code_map'] = "fu";
              this.allFUCodes.push(cpt);
            }
            cpt['selected'] = true;
          }else{
            cpt['code_map'] = "none";
            cpt['selected'] = false;
          }
        });
        this.codesMapping['mapping_id'] = this.codesMapping._id;
        delete this.codesMapping._id;
      }else{
        this.codesMapping = {
          company_id: this.company_id,
          ie_codes: [],
          fu_codes: [],
          created_by: this.authService.currentUser._id,
          updated_by: this.authService.currentUser._id
        }
        this.allCPTs.forEach((cpt)=>{
          cpt['code_map'] = "none";
          cpt['selected'] = false;
        });
      }
    }
  }
  async updateIEFUCodesMapping(){
    const toastrId = this.toastr.info("Updating Codes Mapping","Please Wait...",{disableTimeOut: true});
    this.codesMapping.updated_by = this.authService.currentUser._id;
    let response: any = await lastValueFrom(this._companyService.updateIEFUCodesMapping({ _id: this.codesMapping.mapping_id }, { $set: { ie_codes: this.codesMapping.ie_codes, fu_codes: this.codesMapping.fu_codes, created_by: this.codesMapping.created_by, company_id: this.company_id } }));
    if (response.status == 200) {
      this.toastr.clear(toastrId.toastId);
      this.toastr.success(response.message, "Success");
    }
  }
  addCodeinMapping(cpt_id, code_map){
    clearTimeout(this.timoutId);
    let cpt_index = this.allCPTs.findIndex((code)=> code._id.toString() === cpt_id.toString());
    if(code_map === 'ie'){
      let fu_index = this.codesMapping.fu_codes.findIndex((code)=> code.toString() === cpt_id.toString());
      if(fu_index > -1){
        let allFUCodeIndex = this.allFUCodes.findIndex((code)=> code._id.toString() === cpt_id);
        this.allFUCodes.splice(allFUCodeIndex,1);
        this.codesMapping.fu_codes.splice(fu_index, 1);
      }
      this.codesMapping.ie_codes.push(cpt_id);
      if(cpt_index > -1){
        this.allIECodes.push(this.allCPTs[cpt_index])
      }
    }else{
      let ie_index = this.codesMapping.ie_codes.findIndex((code)=> code.toString() === cpt_id.toString());
      if(ie_index > -1){
        let allIECodeIndex = this.allIECodes.findIndex((code)=> code._id.toString() === cpt_id);
        this.allIECodes.splice(allIECodeIndex,1);
        this.codesMapping.ie_codes.splice(ie_index, 1);
      }
      this.codesMapping.fu_codes.push(cpt_id);
      if(cpt_index > -1){
        this.allFUCodes.push(this.allCPTs[cpt_index])
      }
    }
    if(cpt_index > -1){
      this.allCPTs[cpt_index].code_map =  code_map;
      this.allCPTs[cpt_index].selected = true;
    }
    this.timoutId = setTimeout(()=>{
      this.updateIEFUCodesMapping();
    },2000)
  }
  removeCodeMapping(cpt_id,code_map, index){
    clearTimeout(this.timoutId);
    let cpt_index = this.allCPTs.findIndex((code)=> code._id.toString() === cpt_id.toString());
    if(code_map === 'ie'){
      let ie_index = this.codesMapping.ie_codes.findIndex((code)=> code.toString() === cpt_id.toString());
      console.log(ie_index);
      if(ie_index > -1){
        this.allIECodes.splice(index,1);
        this.codesMapping.ie_codes.splice(ie_index, 1);
        this.allCPTs[cpt_index].selected = false;
        this.allCPTs[cpt_index].code_map = 'none';
      }
    }else{
      let fu_index = this.codesMapping.fu_codes.findIndex((code)=> code.toString() === cpt_id.toString());
      if(fu_index > -1){
        this.allFUCodes.splice(index,1);
        this.codesMapping.fu_codes.splice(fu_index, 1);
        this.allCPTs[cpt_index].selected = false;
        this.allCPTs[cpt_index].code_map = 'none';
      }
    }
    this.timoutId = setTimeout(()=>{
      this.updateIEFUCodesMapping();
    },2000)
  }
}
