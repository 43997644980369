<mat-card class="vertical-height">
    <div style="height: 95%; overflow: auto;">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
            <ng-container matColumnDef="nurse">
                
                <th mat-header-cell *matHeaderCellDef style="background-color: #cedbdf;">Nurse</th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    <mat-checkbox
                    name="nurse"
                    (change)="changeUserAccess(i, $event.checked,'nurse')"
                    [checked]="checkNurseAccess(element)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="view-only-user">
                
                <th mat-header-cell *matHeaderCellDef style="background-color: #cedbdf;">View Only User</th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    <mat-checkbox
                    name="nurse"
                    (change)="changeUserAccess(i, $event.checked, global.USER_TYPE.VIEW_ONLY_USER)"
                    [checked]="checkViewOnlyUserAccess(element)"
                    >
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="provider">
                
                <th mat-header-cell *matHeaderCellDef style="background-color: #cedbdf;">{{currentCompany.company_type === global.COMPANY_TYPE.SNF ? 'SNF WC NURSE' :'Provider'}}</th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    <mat-checkbox
                    name="provider"
                    (change)="changeUserAccess(i, $event.checked, 'provider')"
                    [checked]="checkProviderAccess(element)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="permissions">
                <th mat-header-cell *matHeaderCellDef style="background-color: #cedbdf;">User Permissions</th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    <mat-form-field>
                        <mat-label>Select Users</mat-label>
                        <mat-select [(ngModel)]="element.user_type" multiple>
                            <mat-select-trigger>
                                {{ getLabel(element.user_type) }}
                            </mat-select-trigger>
                            <mat-option *ngFor="let user of users" [value]="user.value" (click)="onChangeOption( i, element.user_type , user.value )">{{user.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </ng-container>

            
            <ng-container matColumnDef="sr">
                <th mat-header-cell *matHeaderCellDef style="background-color: #cedbdf;"> Sr </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{i + 1}}
                </td>
            </ng-container>

            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef style="background-color: #cedbdf;"> Title </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element.title}}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef style="background-color: #cedbdf;"> Actions </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    <div class="flex justify-between items-center">
                        <a style="cursor: pointer" *ngIf="element.navigationPath"
                            (click)="navigateToReportPage(element);">Show Report</a>
                        <div *ngIf="element.navigationPath==='dns-report'">
                            <button *ngIf="!isDnsWeeklyReportRunning" type="button" mat-raised-button
                                class="dn-button-primary" (click)="generateDnsWeeklyExcelReport()">
                                Auto Report Generation Start
                            </button>
                            <button *ngIf="isDnsWeeklyReportRunning" type="button" mat-raised-button
                                class="dn-button-secondary" (click)="deleteDnsWeeklyReportJob()">
                                Auto Report Generation Stop
                            </button>
                        </div>
                        <div class="flex justify-between items-center gap-12" *ngIf="element.navigationPath === 'qapi-report'">
                            <mat-form-field style="width: 70px;">
                                <mat-select [value]="filter.quarter" (selectionChange)="onQuarterlyMenuChange($event.value)">
                                    <mat-option value="Q1">Q 1</mat-option>
                                    <mat-option value="Q2">Q 2</mat-option>
                                    <mat-option value="Q3">Q 3</mat-option>
                                    <mat-option value="Q4">Q 4</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button type="button" mat-raised-button class="dn-button-primary"
                                (click)="generateQuarterlyQapiReport();">
                                Generate
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</mat-card>