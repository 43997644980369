import { Component, Inject } from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

// DELETE CONFIRMATION DIALOG
export interface ItemDetailDialogData {
    title:String
  }
  @Component({
    selector: 'item-detail-dialog',
    templateUrl: './item-detail-dialog.component.html',
    styleUrls: ['./drive.component.css']
  })
  export class ItemDetailDialog {
    constructor(
      public dialogRef: MatDialogRef<ItemDetailDialog>,
      @Inject(MAT_DIALOG_DATA) public data: ItemDetailDialogData
      ) {      
      }
  }
