import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { lastValueFrom } from 'rxjs';
import { CptIcdAssociationService } from 'src/app/includes/cpt-icd-association/cpt-icd-association.service';
import { PatientRoundingSheetService } from 'src/app/includes/patient-rounding-sheets/patient-rounding-sheet.service';
import { AuthService } from 'src/app/services/auth.service';
import { WoundService } from 'src/app/services/wound.service';
import { ProcedureType, ProcedureDataset } from '../procedure-datasets.component';
import { CompanyService } from 'src/app/services/Modules/company.service';

@Component({
  selector: 'app-procedure-category-data',
  templateUrl: './procedure-category-data.component.html',
  styleUrls: ['./procedure-category-data.component.css']
})
export class ProcedureCategoryDataComponent implements OnInit, OnChanges {
  @Input() company_id: string;
  @Input() procedureDatasets: ProcedureDataset[] = [];
  @Input() activeProcedureType: ProcedureType;
  dataSource = new MatTableDataSource();
  selectedCode = new FormControl();
  displayedColumns: string[] = ['category', 'size', 'code_1', 'code_2', 'code_3', 'operation'];
  loaderId = "app-cpt-icd-association";
  cptCodeList: any = [];
  cptCodeNewList: any = [];


  searchText: any;
  diagnoseFilter = '';
  filter: any;

  showNewProcedureDatasetInput: boolean = false;
  disableAddButton = false;
  currentUser: any;
  @Input() companyPermissions: { should_allow_auto_procedure_coding: false };
  showErrorMsgs: boolean = false;

  constructor(
    private _cpticd: CptIcdAssociationService,
    private loader: NgxUiLoaderService,
    private _toaster: ToastrService,
    private _wound: WoundService,
    private _authService: AuthService,
    private patientRoundingSheetService: PatientRoundingSheetService,
    private _companyService: CompanyService,
    private _changeRef: ChangeDetectorRef
  ) {
    this.currentUser = this._authService.currentUser;
  }
  ngAfterContentChecked(){
    this._changeRef.detectChanges();
  }
  async ngOnInit() {
    this.init();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.procedureDatasets?.firstChange) {
      this.init();
    }
  }
  async init() {
    if(localStorage.getItem("procedureDatasets")){
      this.procedureDatasets = JSON.parse(localStorage.getItem("procedureDatasets"));
    }else{
      this.procedureDatasets.forEach(ele => {
        ele["selected"] = false;
      });
      localStorage.setItem("procedureDatasets",JSON.stringify(this.procedureDatasets));
    }
    this.dataSource.data = this.procedureDatasets;
    if (this.procedureDatasets.length == 0 || this.procedureDatasets.filter((p_DataSet) => !p_DataSet.code_1).length === this.procedureDatasets.length) {
      await this.searchCPT("");
    } else {
      this.populateCPTsDropdownValues();
    }
  }
  async initProcedureDatasets() {
    
    let { status, data } = await lastValueFrom(this._wound.getProcedureDatasets(this.activeProcedureType._id)) as any;
    if (status == 200) {
      this.procedureDatasets = data.array as ProcedureDataset[];
      // this.selectedICDCode = this.icdCodeList.find(icdcode => icdcode.code == this.woundEtiologyGroupDetails.icd_id.code);
      // 

    }
  }
  handleCode1Change($event, element) {
    console.log('handleCode1Change')
    element.code_1 = $event.value;
  }
  handleCode2Change($event, element) {
    console.log('handleCode2Change')
    element.code_2 = $event.value;
  }
  handleCode3Change($event, element) {
    console.log('handleCode3Change')
    element.code_3 = $event.value;
  }
  handleNewCode1Change($event, element?) {
    // if(element) {
    //   this.selectedCode1[element._id] = $event.value;
    //   element.icd_code = $event.value;
    //   console.log('handleICDChange');
    //   this.updateProcedureDataset(
    //     element
    //   )
    // } else {
    //   this.newSelectedDetailICDInputValue = $event.value  
    // }
  }
  async searchCPT(value) {
    await this.getAllCPTs(value);
    this.populateCPTsDropdownValues();
  }
  populateCPTsDropdownValues() {
    const _cptCodeList = [...this.cptCodeList];
    for (const dataset of this.procedureDatasets) {

      if (dataset?.code_1 && !_cptCodeList.find(x => x && x._id === dataset.code_1._id)) {
        this.cptCodeList.push(dataset.code_1);
      }
      if (dataset?.code_2 && !_cptCodeList.find(x => x && x._id === dataset.code_2._id)) {
        this.cptCodeList.push(dataset.code_2);
      }
      if (dataset?.code_3 && !_cptCodeList.find(x => x && x._id === dataset.code_3._id)) {
        this.cptCodeList.push(dataset.code_3);
      }
    }
    // this.cptCodeList = _cptCodeList
  }

  async updateProcedureDataset(procedureDataset) {
    let { _id: procedureDatasetId, category, size, code_1, code_2, code_3, template, procedure_note_template, is_deleted } = procedureDataset;

    const response = await lastValueFrom(this._wound.updateProcedureDataset(
      procedureDatasetId, {category, size, code_1, code_2, code_3, template, procedure_note_template, is_deleted}));
  }

  async deleteProcedureDataset(procedureDataset, i) {
    const response = await lastValueFrom(this._wound.updateProcedureDataset(procedureDataset._id, {is_deleted: true}));
    if (response['status'] == 200) {
      this.procedureDatasets.splice(i, 1);
    }
  }
  onTagSelect(tag, element) {
    this.updateProcedureDataset(element)


  }
  onTagDeSelect(tag, element) {
    this.updateProcedureDataset(element)
  }

  handleAddNewProcedureDataset() {
    this.procedureDatasets.push({
      _id: null,
      procedure_type_id: this.activeProcedureType._id,
      category: '',
      size: '',
      code_1: null,
      code_2: null,
      code_3: null,
      selected: false
    } as any);
    this.dataSource.data = this.procedureDatasets;
  }
  /** 
* Handle Procedure Type Category Function
* This function is used to update and 
* @param procedureDataset
* @type {category: string, size: string, code_1: ObjectId, code_2: ObjectId, code_3: ObjectId} 
* @param index
* @type {integer}
* @returns It will handle to update and add New Procedure DataSet Category
*/
  async handleProcedureTypeCategory(procedureDataset, index) {
    if (procedureDataset.selected) {
      if (this.companyPermissions.should_allow_auto_procedure_coding && (!procedureDataset.category || !procedureDataset.size || !procedureDataset?.code_1)) {
        this.showErrorMsgs = true;
        return;
      }else if(!this.companyPermissions.should_allow_auto_procedure_coding && !procedureDataset.category){
        this.showErrorMsgs = true;
        return;
      } else {
        this.showErrorMsgs = false;
        const dataObj = {
          category: procedureDataset.category,
          size: procedureDataset.size,
          code_1: (procedureDataset.code_1 && procedureDataset.code_1._id) ? procedureDataset.code_1._id : null,
          code_2: (procedureDataset.code_2 && procedureDataset.code_2._id) ? procedureDataset.code_2._id : null,
          code_3: (procedureDataset.code_3 && procedureDataset.code_3._id) ? procedureDataset.code_3._id : null
        }
        const infoToast = this._toaster.info('Updating Category', 'Please wait', { disableTimeOut: true });
        const response = await lastValueFrom(this._wound.updateProcedureDataset(procedureDataset._id, dataObj));
        if (response['status'] == 200) {
          this._toaster.clear(infoToast.toastId);
          this._toaster.success("Updated Successfully", "Success");
          this.procedureDatasets[index]["selected"] = false;
          localStorage.setItem("procedureDatasets",JSON.stringify(this.procedureDatasets));
        }else{
          this._toaster.clear(infoToast.toastId);
          this._toaster.error("Something went wrong", "Failed");
        }
      }
    } else {
      this.addProcedureTypeCategory(procedureDataset);
    }
  }
  /** 
 * Add Procedure Category Function
 * This function is used to add a new Procedure type category
 * @param {{category: string, size: string, code_1: ObjectId, code_2: ObjectId, code_3: ObjectId}} 
 * @returns It will add a new Procedure DataSet Category
 */
  async addProcedureTypeCategory(procedureDataset) {
    if (this.companyPermissions.should_allow_auto_procedure_coding && (!procedureDataset.category || !procedureDataset.size || !procedureDataset?.code_1)) {
      this.showErrorMsgs = true;
      return;
    }else if(!this.companyPermissions.should_allow_auto_procedure_coding && !procedureDataset.category){
      this.showErrorMsgs = true;
      return;
    } else {
      this.showErrorMsgs = false;
      const infoToast = this._toaster.info('Adding Category', 'Please wait', { disableTimeOut: true });
      this.disableAddButton = true;
      const newRowIndex = this.procedureDatasets.findIndex((x: any) => x._id === null);
      const { data, status } = await lastValueFrom(this._wound.addProcedureDataset(procedureDataset.procedure_type_id, procedureDataset.category, procedureDataset.size, procedureDataset?.code_1?._id, procedureDataset?.code_2?._id, procedureDataset?.code_3?._id)) as any;
      if (status == 200) {
        this.dataSource.data[newRowIndex]['_id'] = this.procedureDatasets[newRowIndex]._id = data._id;
        this._toaster.clear(infoToast.toastId);
        this._toaster.success("Added Successfully", "Success");
        localStorage.setItem("procedureDatasets",JSON.stringify(this.procedureDatasets));
      }else{
        this._toaster.clear(infoToast.toastId);
        this._toaster.error("Something went wrong", "Failed");
      }
      this.disableAddButton = false;
    }
  }

  async deleteNewProcedureDataset(procedureDataset, newRowIndex) {
    const infoToast = this._toaster.info('Deleting Category', 'Please wait', { disableTimeOut: true });
    const response = await lastValueFrom(this._wound.updateProcedureDataset(procedureDataset._id, {is_deleted: true}));
    
    if (response['status'] == 200) {
      this.procedureDatasets.splice(newRowIndex, 1);
      this.dataSource.data = this.procedureDatasets;
      this._toaster.clear(infoToast.toastId);
      this._toaster.success("Deleted Successfully", "Success");
      localStorage.setItem("procedureDatasets",JSON.stringify(this.procedureDatasets));
    }else{
      this._toaster.clear(infoToast.toastId);
      this._toaster.error("Something Went Wrong", "Failed");
    }
  }

  async getAllCPTs(search = "") {
    const { status, data } = await lastValueFrom(this.patientRoundingSheetService.getCPTs({ page: 1, search: search })) as any
    if (status == 200) {
      this.cptCodeList = data.array;
    } else {
      this.cptCodeList = []
    }
  }

  isAddingNewProcedureDataset() {
    return !!this.procedureDatasets.find(e => e._id == null)
  }
  clearCode3(element) {
    element.code_3 = null;
  }

  cptCodeDisplay(cpt) {
    let label = '';
    if (cpt) {
      if (cpt.code) {
        label += cpt.code;
      }
      if (cpt.description) {
        label += ' (' + cpt.description + ')';
      }
    }
    return label.trim();
  }
  toggletoUpdate(element, index) {
    this.procedureDatasets[index]["selected"] = true;
  }
}
