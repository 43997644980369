import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../global';

@Component({
  selector: 'app-merging',
  templateUrl: './merging.component.html',
  styleUrls: ['./merging.component.css']
})
export class MergingComponent implements OnInit {
  tabIndex: any;
  global=global;
  currentUser: any = null;
  Ids=[];
  constructor(
    private _authService: AuthService,
  ) {
    this.currentUser = this._authService.currentUser;
    this.tabIndex =3;
   }

  ngOnInit(): void {
    this.Ids=[
        '656f1c292b4428f2158ed5c2',
        '664c89bb0f7524f50debe97d',
        '65f0a7aaf9f8b77822983b78',
        '66980476ba8e8d5fca3eb113',
      ]
  }

  onTabClick(event) {
    this.tabIndex = event.index;

    return;
  }
  inSpecificIds(){
    return this.Ids.includes(this.currentUser?._id?.toString());
  }
  // onTabClick(event) {
  //   this.tabIndex = event.index;

  //   return;
  // }
}
