<div class="flex flex-col">
    <mat-tab-group class="flex-grow" #tabGroup (selectedTabChange)="tabChange($event)" [(selectedIndex)]="tabIndex">
        <mat-tab label="Diagnosis">
            <app-note-sidebar-icds *ngIf="showICDs && chargesLoader" 
                [note]="note" 
                [charge]="charge" 
                [noteEditorIcds]="noteEditorIcds"
                [currentCompany]="currentCompany"
                [tabsToBeDisplayed]="customizedTabs.diagnosis"
                (icdsChange)="icdsChange($event)">
            </app-note-sidebar-icds>
        </mat-tab>
        <mat-tab label="Charges">
            <div style="height: calc(100vh - 302px)">
    
                <app-note-sidebar-cpts *ngIf="showCPTs && chargesLoader" 
                    [company]="currentCompany"
                    [charge]="charge" 
                    [note]="note"
                    [noteEditorCpts]="noteEditorCpts"
                    [emCodeEditorCpts]="emCodeEditorCpts"
                    [telemedPhrase]="telemedPhrase"
                    [icd_id]= "model.icd_id"
                    [questionnaireResults]="questionnaireResults"
                    [hideMipsReportMeasure]="hideMipsReportMeasure"
                    [currentCompany]="currentCompany"
                    [tabsToBeDisplayed]="customizedTabs.charges"
                    (cptsChange)="cptsChange($event)"
                    (modifiersChange)="modifiersChange($event)"
                    (mipReportMeasure)="mipReportMeasure($event)"
                    (handleNoteEditorCpts)="handleNoteEditorCpts($event)"
                    (removePhrasesfromHtmlText)="handleRemovePhrasesfromHtmlText($event)">
                </app-note-sidebar-cpts>
                
            </div>
            <div class="flex items-center justify-center gap-5" style="margin-top: 3px;">
                <div *ngIf="chargesLoader">
                    <app-sidebar-mips *ngIf="reportMeasure && isMipReportingEnabled()" [note]="note"
                        [charge]="model" [showMipsToaster]="showMipsToaster" (availableMipsEvent)="availableMipsEvent($event)"
                        (denominatorCalulationInProgress)="denominatorCalulationInProgress($event)" (getRecentMipsEvent)="getRecentMipsEvent($event)"
                        (showMipsToasterOnce)="showMipsToasterOnce($event)">
                    </app-sidebar-mips>
                </div>
                <!-- </div> -->
                <div *ngIf="(mipsExists()||mipsInThisYear()) && submittedMips">
                    <app-submitted-mips [submittedMips]="submittedMips"></app-submitted-mips>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Comments">
            <app-comments *ngIf="chargesLoader"
                (comments)="comments($event)"   
                [charge]="charge">
            </app-comments>
        </mat-tab>
        <mat-tab disabled="true" style="font-size: 10px;" label={{autoSaveMessage}}>
        </mat-tab>
    </mat-tab-group>

    <div style="margin: 10px;">
    <div class="flex items-center justify-center gap-2">
       
        <button class="dn-button-primary" mat-raised-button type="button" (click)="saveData()" [disabled]="!buttonStates.saveAsDraft" >Save as Draft</button>
        <button class="dn-button-primary" mat-raised-button type="button" (click)="saveMipsasDraft()" *ngIf="tabIndex == 2" [disabled]="getMipsValueForButton()" >Save Mips as Draft</button>

        <button class="dn-button-primary" mat-raised-button type="button" (click) = "navigate()" *ngIf="tabIndex == 0">Next</button>
        <button class="dn-button-primary" mat-raised-button type="button" (click) = "submitCharge()" *ngIf="tabIndex == 1" [disabled]="disableButton() || disableClick">{{submitButtonText}}</button>
    </div>
    <div>
        <p style="font-size: x-small;margin-bottom: 0px;">*Save as Draft: Charges will be saved but not submitted. Charges can be edited. </p>
        <p style="font-size: x-small;margin-bottom: 0px;">*Submit: Charges are submitted & approved. Charges will not be editable.</p>
    </div>

    </div> 
</div>