import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TherapyService } from './therapy.service';

@Component({
  selector: 'app-therapy-tab',
  templateUrl: './therapy-tab.component.html',
  styleUrls: ['./therapy-tab.component.css']
})
export class TherapyTabComponent implements OnInit {
  @Input('patient') patient;
  sourceTypeFilter: any;

  constructor(private therapyService: TherapyService) {
    this.sourceTypeFilter = 'goal';
  }

  async ngOnInit() {
  }
  async applySourceTypeFilter(value) {
    this.sourceTypeFilter = value;
  }
}
