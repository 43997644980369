import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { MdePopoverModule } from '@material-extended/mde';
// import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
// import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import * as global from './includes/global';
import { LightboxModule } from 'ngx-lightbox';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#dd0031';
const SecondaryBlue = '#006ddd';
import { MatListModule } from '@angular/material/list';
import { MentionModule } from 'angular-mentions';
import { NoteTimeLogsComponent } from './includes/note-sidebar/note-time-logs/note-time-logs.component';

import { AppComponent } from './app.component';
import { HeaderComponent } from './includes/header/header.component';
import { LoginComponent } from './includes/login/login.component';
import { AuthComponent } from './includes/auth/auth.component';
import { EmailVerificationComponent } from './includes/email-verification/email-verification.component';
import { RegisterComponent } from './includes/register/register.component';
import { FacilityComponent } from './includes/facility/facility.component';
import { ForgotPasswordComponent } from './includes/forgot-password/forgot-password.component';
import { CodeVerificationComponent } from './includes/code-verification/code-verification.component';
import {
  DashboardComponent,
  FilterDialog,
} from './includes/dashboard/dashboard.component'; // removed UploadFileBottomSheet #mohsin-dev
import { PatientListComponent } from './includes/patient-list/patient-list.component';
import { PatientAddComponent } from './includes/patient-add/patient-add.component';
import { TranscriptionDetailComponent } from './includes/transcription-detail/transcription-detail.component';
import { ProfileComponent } from './includes/profile/profile.component';
import { ResetPasswordComponent } from './includes/reset-password/reset-password.component';
import { PdfViewComponent } from './includes/pdf-view/pdf-view.component';
import { DoctorListComponent } from './includes/doctor-list/doctor-list.component';
import { TranscriberListComponent } from './includes/transcriber-list/transcriber-list.component';
import { RelationshipComponent } from './includes/relationship/relationship.component';
import { TranscriptionCreateComponent } from './includes/transcription-create/transcription-create.component';
import { NoteEditorComponent } from './includes/note-editor/note-editor.component';
import { TranscriptionViewComponent } from './includes/transcription-view/transcription-view.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// import { DropzoneModule } from 'ngx-dropzone-wrapper';
// import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
// import {DragulaModule} from 'ng2-dragula';
// import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { routing } from './app.routing';
import {
  RecordingFilterPipe,
  RecordingStatusFilterPipe,
  SafePipe,
  DoctorNameFilterPipe,
} from './filter.pipe';
import { DriveComponent } from './includes/drive/drive.component';
import { AudioListDialog } from './shared/popup-dialogs/audio-list-dialog/audio-list-dialog.component';
import { PatientRoundingSheetCommentsDialog } from './shared/popup-dialogs/patient-roundingsheet-comments-dialog/patient-roundingsheet-comments.component';
import { NoteTypeDialog } from './shared/popup-dialogs/note-type-dialog/note-type-dialog.component';
import { CreateNewFolderDialog } from './includes/drive/create-new-folder-dialog.component';
import { DeleteConfirmationDialog } from './includes/drive/delete-confirmation-dialog.component';
import { UploadFileDialog } from './includes/drive/upload-file-dialog.component';
import { ShareItemDialog } from './includes/drive/share-item-dialog.component';
import { WatchHelpVideoDialog } from './includes/drive/watch-help-video-dialog.component';
import { SharedDriveComponent } from './includes/drive/shared-drive/shared-drive.component';
import { AuthInterceptor } from './auth.interceptor';
import { TemplatesComponent } from './includes/templates/templates.component';
import { TemplateCreateComponent } from './includes/template-create/template-create.component';
import { TranscriptionTemplateDialog } from './includes/transcription-create/transcription-template-dialog/transcription-template-dialog.component';
import { PatientDocumentsUploadDialog } from './includes/patient-documents-list/patient-documents-upload-dialog/patient-documents-upload-dialog.component';
import { PatientsDialog } from './includes/transcription-create/patients-dialog/patients-dialog.component';
import { PatientAddDialog } from './includes/transcription-create/patient-add-dialog/patient-add-dialog.component';
import { AddendumNoteDialog } from './includes/transcription-create/addendum-note-dialog/addendum-note-dialog.component';
import { UploadNoteConfirmationDialog } from './includes/transcription-create/upload-note-confirmation-dialog/upload-note-confirmation-dialog.component';
import { ViewNoteDialog } from './includes/transcription-create/view-note-dialog/view-note-dialog.component';
import { PreviewTemplateDialog } from './includes/template-create/preview-template-dialog/preview-template-dialog.component';
import { PatientDetailDialog } from './includes/transcription-create/patient-detail-dialog/patient-detail-dialog.component';
import { PatientDetailComponent } from './includes/patient-detail/patient-detail.component';
import { PatientScriptSureComponent } from './includes/scriptsure/patient-scriptsure.component';
import { PatientDetailListComponent } from './includes/patient-detail-list/patient-detail-list.component';
import { PatientsComponent } from './includes/patients/patients.component';
import { FileExplorerComponent } from './includes/drive/file-explorer/file-explorer.component';
import { RRGenExplorerComponent } from './includes/rrgen-explorer/rrgen-explorer.component';
import { RRgenExplorerFilesUploadDialog } from './includes/rrgen-explorer/rrgen-explorer-files-upload-dialog/rrgen-explorer-files-upload-dialog.component';
import { FacilityAddDialog } from './shared/popup-dialogs/facility-add-dialog/facility-add.dialog';
import { RolesDialog } from './shared/popup-dialogs/roles-dialog/roles.dialog';
import { PatientImporterDialog } from './includes/patient-rounding-sheets/patient-importer-dialog/patient-importer-dialog';
import { PatientRoundingSheetDialog } from './includes/patient-rounding-sheets/create-patient-rounding-sheet-dialog/create-patient-rounding-sheet-dialog';
import { PatientRoundingSheetPatientListComponent } from './includes/patient-rounding-sheet-patient-list/patient-rounding-sheet-patient-list.component';
import { CensusPatientListComponent } from './includes/census-patient-list/census-patient-list.component';
import { DrugHistoryConsentDialog } from './includes/census-patient-list/drug-history-consent-dialog/drug-history-consent-dialog.component';
import { EligibilityDialog } from './includes/census-patient-list/eligibility-dialog/eligibility-dialog.component';
import { TranscriptionListComponent } from './shared/transcription-list/transcription-list.component';
import { AudioListComponent } from './shared/audio-list/audio-list.component';
import { PrivacyPolicyComponent } from './includes/privacy-policy/privacy-policy.component';
import { PublicHeaderComponent } from './includes/public-header/public-header.component';
import { SwitchButtonComponent } from './shared/switch-button/switch-button.component';
import { FilterFormComponent } from './shared/filter-form/filter-form.component';
import { UserManagementComponent } from './includes/user-management/user-management.component';
import { ActivityComponent } from './includes/activity/activity.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { AdminDictationsComponent } from './includes/admin-dictations/admin-dictations.component';
import { AdminNotesComponent } from './includes/admin-notes/admin-notes.component';
import { AdminComponent } from './includes/admin/admin.component';
import { AdminDashboardComponent } from './includes/admin-dashboard/admin-dashboard.component';
import { ItemDetailDialog } from './includes/drive/item-detail-dialog.component';
import 'hammerjs';
import { PaginationComponent } from './shared/pagination/pagination.component';
import { NoteCommentsComponent } from './includes/note-sidebar/note-comments/note-comments.component';
import { DictationsGroupComponent } from './includes/dictations-group/dictations-group.component';
import { NotificationsPageComponent } from './includes/notifications-page/notifications-page.component';
import { SimulateUserComponent } from './includes/simulate-user/simulate-user.component';
import { AdminNotificationsSettingsComponent } from './includes/admin-notifications-settings/admin-notifications-settings.component';
import { AppointmentModule } from './components/appointment/appointment.module';
import { DoctorProfileModule } from './components/doctor_profile/doctor_profile.module';
import { LayoutModule } from './layout/layout.module';
import { ReportGenerateComponent } from './components/report_generate/report-generate/report-generate.component';
import { DetailReporComponent } from './components/report_generate/detail-report/detail-repor/detail-repor.component';
import { TrainingSystemComponent } from './components/training_system/training-system/training-system.component';
import { PreviewDocumentComponent } from './components/report_generate/preview-document/preview-document/preview-document.component';
// import { PinchZoomModule } from 'ngx-pinch-zoom';
import {
  AddAppointmentComponent,
  AddAttributeComponent,
  AddPayorComponent,
} from './components/appointment';
// import { MatMomentDateModule, MomentDateAdapter, MAT_MOMENT_DATE_FORMATS, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { AppointmentDataService } from './components/appointment/service/data.service';
import { PopupReportViewComponent } from './components/report_generate/popup-report-view/popup-report-view.component';
import { PccPatientListComponent } from './includes/pcc-patient-list/pcc-patient-list.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MatBadgeModule } from '@angular/material/badge';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateAdapter, MatRippleModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { InsuranceCompanyComponent } from './components/insurance-company/insurance-company.component';
import { PccNotificationPanelComponent } from './includes/pcc-notification-panel/pcc-notification-panel.component';
import { PatientRoundingSheetsComponent } from './includes/patient-rounding-sheets/patient-rounding-sheets.component';
import { CompanyModule } from './components/company/company.module';
import { BillerListComponent } from './includes/biller-list/biller-list/biller-list.component';
import { BillerProviderAssociationComponent } from './includes/biller-provider-association/biller-provider-association/biller-provider-association.component';
import { AudioChunkComponent } from './includes/audio-chunk/audio-chunk.component';
import { FacesheetComponent } from './includes/patient-detail/facesheet/facesheet.component';
import { ObservationComponent } from './includes/patient-detail/observation/observation.component';
import { EpisodesOfCareComponent } from './includes/patient-detail/episodes-of-care/episodes-of-care.component';
import { MedicationsComponent } from './includes/patient-detail/medications/medications.component';
import { PatientCoveragesComponent } from './includes/patient-detail/patient-coverages/patient-coverages.component';
import { PatientDiagnosticReportsComponent } from './includes/patient-detail/patient-diagnostic-reports/patient-diagnostic-reports.component';
import { ConditionsComponent } from './includes/patient-detail/conditions/conditions.component';
import { ActivitiesOfDailyLivingComponent } from './includes/patient-detail/activities-of-daily-living/activities-of-daily-living.component';
import { ActivitiesOfDailyLivingResponseComponent } from './includes/patient-detail/activities-of-daily-living/activities-of-daily-living-response/activities-of-daily-living-response.component';
import { ActivitiesOfDailyLivingScoreComponent } from './includes/patient-detail/activities-of-daily-living-score/activities-of-daily-living-score.component';
import { ChargeCaptureComponent } from './includes/charge-capture/charge-capture/charge-capture.component';
import { AllergyIntolerancesComponent } from './includes/patient-detail/allergy-intolerances/allergy-intolerances.component';
import { MultiplePatientsAddDialog } from './includes/patient-rounding-sheets/multiple-patients-add-dialog/multiple-patients-add-dialog';
import { ESignComponent } from './components/e-sign/e-sign/e-sign.component';
import { AddSignatureComponent } from './components/e-sign/add-signature/add-signature/add-signature.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { ChargesPopupComponent } from './includes/biller-dashboard/charges-popUp/charges-popup/charges-popup.component';
import { AudioDetailPipe } from './shared/pipes/audio-detail.pipe';
import { AdministrationComponent } from './includes/patient-detail/medications/administration/administration.component';
import { SchedulesComponent } from './includes/patient-detail/medications/schedules/schedules.component';
import { LastAdmissionComponent } from './includes/patient-detail/medications/schedules/last-admission/last-admission.component';
import { ImmunizationsComponent } from './includes/patient-detail/immunizations/immunizations.component';
import { DictionaryDialogComponent } from './shared/popup-dialogs/dictionary-dialog/dictionary-dialog.component';
import { NoteAuditoryHistoryComponent } from './includes/note-auditory-history/note-auditory-history.component';
import { CountFilterComponent } from './includes/count-filter/count-filter/count-filter.component';
import { NoteAddendumsComponent } from './includes/transcription-create/note-addendums/note-addendums.component';
import { ViewNoteAddendumDialog } from './includes/transcription-create/view-note-addendum-dialog/view-note-addendum-dialog.component';
import { ViewNoteAuditDialog } from './includes/note-auditory-history/view-note-audit-dialog/view-note-audit-dialog.component';
import { PccAuthRedirectComponent } from './includes/pcc-auth-redirect/pcc-auth-redirect.component';
import { PatientChargeComponent } from './components/patient-charge/patient-charge.component';
import { AddChargeComponent } from './components/patient-charge/add-charge/add-charge.component';
import { ProviderChargeComponent } from './includes/provider-charge/provider-charge.component';
import { ProviderChargeCaptureComponent } from './includes/charge-capture/provider-charge-capture/provider-charge-capture.component';
import { ManageChargeComponent } from './includes/charge-capture/manage-charge/manage-charge.component';
import { PhrasesComponent } from './includes/phrases/phrases.component';
import { DictionaryEditorDialogComponent } from './includes/phrases/phrase-editor-dialog/phrase-editor-dialog.component';
import { NotePatientDetailsComponent } from './includes/note-sidebar/patient-details-widget/note-patient-details.component';
import { SignaturePadModule } from 'angular-signature-pad-v2';
// import {
// NgxMatDatetimePickerModule,
// NgxMatNativeDateModule,
// NgxMatTimepickerModule,
// } from '@angular-material-components/datetime-picker';
import { ReportsComponent } from './includes/report/reports/reports.component';
import { ReportDetailComponent } from './includes/report/report-detail/report-detail/report-detail.component';
import { NotesListComponent } from './includes/note-sidebar/patient-details-widget/notes-list/notes-list.component';
import { PCCNotesListComponent } from './includes/note-sidebar/patient-details-widget/pcc-notes-list/pcc-notes-list.component';
import { PCCNoteTypesListComponent } from './includes/note-sidebar/patient-details-widget/pcc-note-types-list/pcc-note-types-list.component';
import { TransReportComponent } from './includes/report/transReports/trans-report/trans-report.component';
import { AddMipsComponent } from './components/company/mips/mips-dashboard/mips-dashboard/add-mips/add-mips/add-mips.component';
import { FacilityContactDialogComponent } from './shared/popup-dialogs/facility-contact-dialog/facility-contact-dialog.component';
import { PayorContactDialogComponent } from './shared/popup-dialogs/payor-contact-dialog/payor-contact-dialog.component';
import { CompanyUserManagementAddUserComponent } from './components/company/company-user-management-add-user/company-user-management-add-user.component';
import { CompanyFacilityFormComponent } from './components/company/company-facilities/company-facility-form/company-facility-form.component';
import { MobileEditorComponent } from './includes/note-editor/mobile-editor/mobile-editor.component';
import { MobileEditorNewVersionComponent } from './includes/note-editor-new-version/mobile-editor/mobile-editor.component';

import { ProviderProviderAssociationComponent } from './includes/provider-provider-association/provider-provider-association/provider-provider-association.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MaterialModule } from './components/material.module';
import { PatientNutritionOrdersComponent } from './includes/patient-detail/patient-nutrition-orders/patient-nutrition-orders.component';
import { RouterExtService } from './services/router-ext.service';
import { NoteSidebarComponent } from './includes/note-sidebar/note-sidebar.component';
import { UserDefinedPhrasesComponent } from './includes/phrases/user-defined-phrases/user-defined-phrases.component';
import { SystemDefinedPhrasesComponent } from './includes/phrases/system-defined-phrases/system-defined-phrases.component';
import { TemplateHeadingsComponent } from './includes/phrases/template-headings/template-headings.component';
import { CompanyUserManagementFormComponent } from './components/company/company-user-management/company-user-management-form/company-user-management-form.component';
import { ChargesCatagoriesDialogComponent } from './shared/popup-dialogs/charges-catagories/charges-catagories-dialog/charges-catagories-dialog.component';
import { PatientDiagnosticReportsTestSetComponent } from './includes/patient-detail/patient-diagnostic-reports/patient-diagnostic-reports-test-set/patient-diagnostic-reports-test-set.component';
import { ManageCategoriesComponent } from './shared/popup-dialogs/charges-catagories/charges-catagories-dialog/manage-categories/manage-categories.component';
import { NotesWidgetComponent } from './includes/note-sidebar/patient-details-widget/notes-widget/notes-widget.component';
import { PCCViewNoteDialog } from './includes/transcription-create/view-pcc-note-dialog/view-pcc-note-dialog.component';
import { TemplateCreateSidebarComponent } from './includes/template-create/template-create-sidebar/template-create-sidebar.component';
import { CensusFileUploadDialogeComponent } from './shared/popup-dialogs/census-file-upload/census-file-upload-dialoge/census-file-upload-dialoge.component';
import { PccEventNotificationsComponent } from './includes/census-patient-list/pcc-event-notifications/pcc-event-notifications.component';
import { ProviderFacilityAssociationComponent } from './includes/provider-facility-association/provider-facility-association.component';
import { ManageCompaniesComponent } from './includes/manage-companies/manage-companies.component';
import { RegisterCompanyComponent } from './includes/manage-companies/register-company/register-company/register-company.component';
import { NoteTemplatesComponent } from './includes/note-sidebar/note-templates/note-templates.component';
import { TemplateMobileEditorComponent } from './includes/note-editor/template-mobile-editor/template-mobile-editor.component';
import { TemplateMobileEditorNewVersionComponent } from './includes/note-editor-new-version/template-mobile-editor/template-mobile-editor.component';
import { ExportNotesDialogComponent } from './shared/popup-dialogs/export-notes-dialog/export-notes-dialog.component';
import { AudioNoteListComponent } from './includes/transcription-detail/audio-note-list/audio-note-list.component';
import { PhraseComponent } from './includes/phrase-component/phrase-component';
import { PhraseEditorDialogSystemComponent } from './includes/phrases/phrase-editor-dialog-system/phrase-editor-dialog-system.component';
import { TemplateAssociationComponent } from './includes/template-association/template-association/template-association.component';
import { NoteStatusIconComponent } from './includes/census-patient-list/note-status-icon/note-status-icon.component';
import { PhrasesAssociationComponent } from './includes/phrases/phrases-association/phrases-association.component';
import { AdminSidePatientsComponent } from './includes/adminside-patients/admin-side-patients/admin-side-patients.component';
import { AudioComponent } from './includes/audio/audio.component';
// import { NgAudioRecorderModule } from 'ng-audio-recorder';

import { SidebarChargesComponent } from './components/sidebar-addCharges/sidebar-charges/sidebar-charges.component';
import { SidebarCptsComponent } from './components/sidebar-addCharges/sidebar-cpts/sidebar-cpts/sidebar-cpts.component';
import { SidebarDiagnosesComponent } from './components/sidebar-addCharges/sidebarDiagnonses/sidebar-diagnoses/sidebar-diagnoses.component';
import { BillerNoteComponent } from './components/sidebar-addCharges/biller-note/biller-note/biller-note.component';
import { SystemphraseeditorComponent } from './includes/phrases/system-phrase-editor/systemphraseeditor/systemphraseeditor.component';
import { NotificationBarComponent } from './includes/Notification-bar/notification-bar/notification-bar.component';
import { AddNewsComponent } from './includes/Notification-bar/notification-bar/add-news/add-news/add-news.component';
import { OpenReleaseDialogComponent } from './includes/open-release-dialog/open-release-dialog.component';
import { ReleaseComponent } from './includes/release/release.component';
import { CommentsComponent } from './components/sidebar-addCharges/comments/comments/comments.component';
import { AddMipsQuestionComponent } from './components/company/mips/mips-dashboard/mips-questions/add-mips-question/add-mips-question.component';
import { MeasureNumberQuestionsDialog } from './components/company/mips/mips-dashboard/mips-questions/measure-number-questions/measure-number-questions.component';
import { SidebarMipsComponent } from './components/sidebar-addCharges/sidebar-mips/sidebar-mips.component';
import { MipsQuestionnaireComponent } from './components/sidebar-addCharges/sidebar-mips/mips-questionnaire/mips-questionnaire.component';
import { MipsQuestionnaireTreeComponent } from './components/sidebar-addCharges/sidebar-mips/mips-questionnaire-tree/mips-questionnaire-tree.component';
import { MipsQuestionaireTreeItemComponent } from './components/sidebar-addCharges/sidebar-mips/mips-questionnaire-tree/mips-questionnaire-tree-item/mips-questionnaire-tree-item.component';
import { AdminFeedbackComponent } from './includes/admin-feedback/admin-feedback.component';
import { DialogFeedbackComponent } from './includes/admin-feedback/dialog-feedback/dialog-feedback.component';
import { FeedbackComponent } from './includes/feedback/feedback/feedback.component';
import { MipsQuestionnaireResultsComponent } from './components/sidebar-addCharges/sidebar-mips/mips-questionnaire-results/mips-questionnaire-results.component';
import { RehabDocsPopupComponent } from './shared/popup-dialogs/rehab-docs-popup/rehab-docs-popup/rehab-docs-popup.component';
import { ActiveCensusPatientListComponent } from './includes/active-census-patient-list/active-census-patient-list.component';
import { PatientTitleComponent } from './shared/patient-title/patient-title.component';
import { MipsQuestionnaire181Component } from './components/sidebar-addCharges/sidebar-mips/mips-questionnaire-181/mips-questionnaire-181.component';
import { DialogConfirmationComponent } from './includes/census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { GoogleDriveComponent } from './includes/drive/google-drive/google-drive.component';
import { MedicalAssistantAssociationComponent } from './includes/medical-assistant-association/medical-assistant-association.component';
import { MedicalAssistantListComponent } from './includes/medical-assistant-list/medical-assistant-list.component';
import { TranscribeComponent } from './includes/transcribe/transcribe.component';
import { DrivePhrasesComponent } from './includes/drive-phrases/drive-phrases.component';
import { CensusCommentComponent } from './includes/census-patient-list/census-comment/census-comment.component';
import { MipsQuestionnaire126Component } from './components/sidebar-addCharges/sidebar-mips/mips-questionnaire-126/mips-questionnaire-126.component';
import { AddUserPhraseToProviderComponent } from './includes/phrase-component/add-user-phrase-to-provider/add-user-phrase-to-provider.component';
import { AmazonMedicalTranscribeComponent } from './components/amazon-medical-transcribe/amazon-medical-transcribe/amazon-medical-transcribe.component';
import { AwsTranscribeComponent } from './includes/aws-transcribe/aws-transcribe.component';
import { DrivePhraseEditorComponent } from './includes/drive-phrases/drive-phrase-editor/drive-phrase-editor.component';
import { PhraseMobileEditorComponent } from './includes/phrase-mobile-editor/phrase-mobile-editor.component';
import { AssociatedProviderDialogComponent } from './components/company/associated-provider-dialog/associated-provider-dialog.component';
import { PatientDiagnosisDialogComponent } from './includes/patient-diagnosis-dialog/patient-diagnosis-dialog.component';
import { GeneralConfirmationDialogComponent } from './includes/general-confirmation-dialog/general-confirmation-dialog.component';
import { ForbiddenPageComponent } from './forbidden-page/forbidden-page.component';
import { CookieInterceptor } from './cookie.interceptor';
import { AdminLoginComponent } from './includes/admin-login/admin-login.component';
import { AdminAuthComponent } from './includes/admin-auth/admin-auth.component';
import { PatientListSiderbarComponent } from './includes/patient-list-siderbar/patient-list-siderbar.component';
import { NoteEditorNewVersionComponent } from './includes/note-editor-new-version/note-editor-new-version.component';
import { NoteDeleteIconComponent } from './includes/census-patient-list/note-delete-icon/note-delete-icon.component';
import { UpdatePatientRoundingSheetDialog } from './includes/patient-rounding-sheets/update-patient-rounding-sheet-dialog/update-patient-rounding-sheet-dialog';
import { HippaAgreementComponent } from './hippa-agreement/hippa-agreement.component';
import { BillingCompanyAssociationComponent } from './includes/billing-company-association/billing-company-association/billing-company-association.component';
import { AuthenticationGuard } from './includes/auth-guard/authentication/authentication.guard';
import { AdminListComponent } from './includes/admin-list/admin-list.component';
import { AuthorizationGuard } from './includes/auth-guard/authorization/authorization.guard';
import { ProductOwnerGuard } from './includes/auth-guard/authorization/product-owner.guard';
import { ChargesReportComponent } from './includes/report/charges-report/charges-report.component';
import { ManagerListComponent } from './includes/manager-list/manager-list.component';
import { AdminChargeCaptureComponent } from './includes/biller-dashboard/admin-charge-capture/admin-charge-capture.component';
import { CensusNotesComponent } from './includes/census-notes/census-notes/census-notes.component';
import { NoteAddChargeComponent } from './includes/note-sidebar/note-addCharge/note-add-charge/note-add-charge.component';
import { NoteSidebarCptsComponent } from './includes/note-sidebar/note-addCharge/note-add-charge/note-sidebar-cpts/note-sidebar-cpts/note-sidebar-cpts.component';
import { NoteSidebarIcdsComponent } from './includes/note-sidebar/note-addCharge/note-add-charge/note-sidebar-icds/note-sidebar-icds/note-sidebar-icds.component';
import { TicketsComponent } from './includes/tickets/tickets.component';
import { TicketDetailsComponent } from './includes/tickets/ticket-details/ticket-details.component';
import { TicketCommentsComponent } from './includes/tickets/ticket-details/ticket-comments/ticket-comments.component';
import { UserTicketsComponent } from './includes/tickets/user-tickets/user-tickets.component';
import { UserTicketDetailsComponent } from './includes/tickets/user-ticket-details/user-ticket-details.component';
import { AddTicketsComponent } from './includes/tickets/add-tickets/add-tickets.component';
import { WoundComponent } from './includes/wound/wound.component';
import { DataSetsComponent } from './includes/wound/data-sets/data-sets.component';
import { EmailTemplateComponent } from './includes/email-template/email-template.component';
import { CreateEmailTemplateDialogComponent } from './includes/create-email-template-dialog/create-email-template-dialog.component';
import { SendEmailTemplateDialogComponent } from './includes/send-email-template-dialog/send-email-template-dialog.component';
import { WoundCareWidgetComponent } from './includes/note-sidebar/wound-care-widget/wound-care-widget.component';
import { WoundCareWidgetItemComponent } from './includes/note-sidebar/wound-care-widget/wound-care-widget-item/wound-care-widget-item.component';
import { WoundCareWidgetChildItemComponent } from './includes/note-sidebar/wound-care-widget/wound-care-widget-item/wound-care-widget-child-item/wound-care-widget-child-item.component';
import { CreateWoundDialogComponent } from './includes/note-sidebar/wound-care-widget/create-wound-dialog/create-wound-dialog.component';
import { CptIcdAssociationComponent } from './includes/cpt-icd-association/cpt-icd-association.component';
import { ForgotPasswordCodeVerificationComponent } from './includes/forgot-password-code-verification/forgot-password-code-verification.component';
import { WoundCareWidgetWoundListComponent } from './includes/note-sidebar/wound-care-widget/wound-care-widget-wound-list/wound-care-widget-wound-list.component';

import { GeneralReportComponent } from './includes/general-report/general-report.component';
import { QueryReportComponent } from './includes/query-report/query-report.component';
import { CreateGeneralReportDialogComponent } from './includes/general-report/create-general-report-dialog/create-general-report-dialog.component';
import { CreateQueryReportDialogComponent } from './includes/query-report/create-query-report-dialog/create-query-report-dialog.component';
import { AutomatedEmailComponent } from './includes/automated-email/automated-email.component';
import { CompanyEmailComponent } from './includes/company-email/company-email.component';
import { WoundImagePreviewDialogComponent } from './includes/note-sidebar/wound-care-widget/wound-image-preview-dialog/wound-image-preview-dialog.component';
import { WoundImageUploadDialogComponent } from './includes/note-sidebar/wound-care-widget/wound-image-upload-dialog/wound-image-upload-dialog.component';
import { EmailComponent } from './includes/email/email.component';
import { CensusCommentsComponent } from './includes/note-sidebar/census-comments/census-comments.component';
import { WoundImageSnapshotDialogComponent } from './includes/note-sidebar/wound-care-widget/wound-image-snapshot-dialog/wound-image-snapshot-dialog.component';
import { WoundReportsComponent } from './includes/wound/wound-reports/wound-reports.component';
import { CompaniesConfigrationComponent } from './includes/companies-configration/companies-configration.component';
import { WoundNoteDialogComponent } from './includes/note-sidebar/wound-care-widget/wound-note-dialog/wound-note-dialog.component';
import { SetWoundStageDialogComponent } from './includes/note-sidebar/wound-care-widget/set-wound-stage-dialog/set-wound-stage-dialog.component';
import { WoundICD10DialogComponent } from './includes/note-sidebar/wound-care-widget/wound-icd10-dialog/wound-icd10-dialog.component';
import { ImageEditorComponent } from './includes/note-sidebar/wound-care-widget/image-editor/image-editor.component';
import { ChargeTimePeriodReportComponent } from './includes/biller-dashboard/charge-time-period-report/charge-time-period-report.component';

import { WoundTagsComponent } from './includes/wound/wound-tags/wound-tags.component';
import { WoundGroupsComponent } from './includes/wound/wound-groups/wound-groups.component';
import { WoundGroupsICDTagsMappingComponent } from './includes/wound/wound-groups/wound-groups-icdtags-mapping/wound-groups-icdtags-mapping.component';
import { PatientAddFormDialogComponent } from './includes/patient-add-form/patient-add-form-dialog/patient-add-form-dialog.component';
import { WoundChartComponent } from './includes/wound-chart/wound-chart.component';
import { WoundCareWidgetItemGraphComponent } from './includes/note-sidebar/wound-care-widget/wound-care-widget-item/wound-care-widget-item-graph/wound-care-widget-item-graph.component';
import { CompanyFacilityAssociationComponent } from './includes/company-facility-association/company-facility-association.component';
import { ProcedureDatasetsComponent } from './includes/wound/procedure-datasets/procedure-datasets.component';
import { ProcedureCategoryDataComponent } from './includes/wound/procedure-datasets/procedure-category-data/procedure-category-data.component';
import { PatientEditDialogComponent } from './includes/census-patient-list/patient-edit-dialog/patient-edit-dialog.component';
import { ProcedureCategoryTemplatesComponent } from './includes/wound/procedure-datasets/procedure-category-templates/procedure-category-templates.component';
import { CompanySettingsComponent } from './components/company/company-settings/company-settings.component';
import { ProcedurePhraseEditorComponent } from './includes/wound/procedure-datasets/procedure-phrase-editor/procedure-phrase-editor.component';
import { AssociatedCompaniesDialogComponent } from './components/company/associated-companies-dialog/associated-companies-dialog.component';
import { WoundPeriodicTreatmentReportComponent } from './includes/wound/wound-reports/reports/wound-periodic-treatment-report/wound-periodic-treatment-report.component';
import { WoundHealingRateReportComponent } from './includes/wound/wound-reports/reports/wound-healing-rate-report/wound-healing-rate-report.component';
import { UwhTypeTabularReportComponent } from './includes/wound/wound-reports/reports/uwh-type-tabular-report/uwh-type-tabular-report.component';
import { NonPccAllergyIntolerancesComponent } from './includes/patient-detail/non-pcc-allergy-intolerances/non-pcc-allergy-intolerances.component';
import { CreateNonPccAllergyIntolerancesDialogComponent } from './includes/patient-detail/non-pcc-allergy-intolerances/create-non-pcc-allergy-intolerances-dialog/create-non-pcc-allergy-intolerances-dialog.component';
import { AdminPatientMedicalHistoryComponent } from './includes/adminside-patients/admin-patient-medical-history/admin-patient-medical-history.component';
import { PatientMedicalHistoryComponent } from './includes/patient-detail/patient-medical-history/patient-medical-history.component';
import { PatientDischargeReportComponent } from './includes/wound/wound-reports/reports/discharged-patient-report/discharged-patient-report.component';
import { DnsReportComponent } from './includes/wound/wound-reports/reports/dns-report/dns-report.component';
import { QapiReportComponent } from './includes/wound/wound-reports/reports/qapi-report/qapi-report.component';
import { VisitReportComponent } from './includes/wound/wound-reports/reports/visit-report/visit-report.component';
import { KentVisitReportComponent } from './includes/wound/wound-reports/reports/kent-visit-report/kent-visit-report.component';
import { NurseProviderAssociationComponent } from './includes/nurse-provider-association/nurse-provider-association/nurse-provider-association.component';
import { NurseListComponent } from './includes/nurse-list/nurse-list.component';
import { NurseFacilityAssociationComponent } from './includes/nurse-facility-association/nurse-facility-association.component';
import { NursingDashboardComponent } from './includes/nursing-module/nursing-dashboard/nursing-dashboard.component';
import { ClinicalSummaryReportComponent } from './includes/wound/wound-reports/reports/qapi-report/clinical-summary-report/clinical-summary-report.component';
import { OperationsSummaryReportComponent } from './includes/wound/wound-reports/reports/qapi-report/operations-summary-report/operations-summary-report.component';
import { FooterComponent } from './includes/footer/footer.component';
import { DebridementReportComponent } from './includes/wound/wound-reports/reports/debridement-report/debridement-report.component';
import { BusinessOfficerAssociationComponent } from './includes/business-officer-association/business-officer-association.component';
import { AdminSidePatientEditComponent } from './includes/admin-side-patient-edit/admin-side-patient-edit.component';
import { CensusPatientListV2Component } from './includes/census-patient-list-v2/census-patient-list-v2.component';
import { AmputationReportComponent } from './includes/wound/wound-reports/reports/qapi-report/amputation-report/amputation-report.component';
import { BusinessOfficerListComponent } from './includes/business-officer-list/business-officer-list.component';
import { MergePatientsComponent } from './includes/merge-patients/merge-patients.component';
import { MergingComponent } from './includes/merging/merging.component';
import { MergeFacilitiesComponent } from './includes/merge-facilities/merge-facilities.component';
import { SendEmailDialogComponent } from './includes/send-email-dialog/send-email-dialog.component';
import { MissedFacilityReportComponent } from './includes/wound/wound-reports/reports/missed-facility-report/missed-facility-report.component';
import { TimePickerWidgetComponent } from './includes/time-picker-widget/time-picker-widget.component';
import { DialogWithChoiceComponent } from './includes/census-patient-list/dialog-with-choice/dialog-with-choice.component';
import { MatrixPatientsComponent } from './includes/matrix-patients/matrix-patients/matrix-patients.component';
import { NoteIcdSuggestionsComponent } from './includes/note-sidebar/note-icd-suggestions/note-icd-suggestions.component';
import { TestinputDialogueComponent } from './includes/merge-facilities/testinput-dialogue/testinput-dialogue.component';
import { NoteIcdHistoryComponent } from './includes/note-sidebar/note-icd-history/note-icd-history.component';
import { NoteCptHistoryComponent } from './includes/note-sidebar/note-cpt-history/note-cpt-history.component';
import { PatientAdmitDischargeHistory } from './includes/patient-detail/patient-admit-discharge-history/patient-admit-discharge-history.component';
import { PdfViewerPopComponent } from './includes/patient-documents-list/pdf-viewer-pop/pdf-viewer-pop.component';
import { DialogEmailSendingComponentComponent } from './includes/dialog-email-sending-component/dialog-email-sending-component.component';
import { WoundNotesComponent } from './includes/wound/wound-notes/wound-notes.component';
import { TestProviderListComponent } from './includes/test-provider-list/test-provider-list.component';
import { ContactUsComponent } from './includes/contact-us/contact-us.component';
import { DialogInformationComponent } from './includes/dialog-information/dialog-information.component';
import { MergefacilitypcctodnComponent } from './includes/mergefacilitypcctodn/mergefacilitypcctodn.component';
import { MergefacilitypcctopccComponent } from './includes/mergefacilitypcctopcc/mergefacilitypcctopcc.component';
import { MergingfacilityDntoMatrixComponent } from './includes/mergingfacility-dnto-matrix/mergingfacility-dnto-matrix.component';
import { MergingfacilityMatrixtoDnComponent } from './includes/mergingfacility-matrixto-dn/mergingfacility-matrixto-dn.component';
import { RehospitalizationReportComponent } from './includes/wound/wound-reports/reports/qapi-report/rehospitalization-report/rehospitalization-report.component';
import { TherapyTabComponent } from './includes/therapy-tab/therapy-tab.component';
import { CertificationComponent } from './includes/certification/certification.component';
import { CommunicationComponent } from './includes/communication/communication.component';
import { CnpListComponent } from './includes/cnp-list/cnp-list.component';
import { EmCodesComponent } from './includes/wound/em-codes/em-codes.component';
import { ProcedureCodesComponent } from './includes/wound/procedure-codes/procedure-codes.component';
import { AddNewCptCodeComponent } from './includes/wound/em-codes/add-new-cpt-code/add-new-cpt-code.component';
import { WoundPharasesComponent } from './includes/phrases/wound-pharases/wound-pharases.component';
import { NoteCptProcedureCodesComponent } from './includes/note-sidebar/note-cpt-procedure-codes/note-cpt-procedure-codes.component';
import { WoundphrasebothComponent } from './includes/phrases/woundphraseboth/woundphraseboth.component';
import { ProcedurephraseComponent } from './includes/phrases/procedurephrase/procedurephrase.component';
import { CensusBothComponent } from './includes/census-both/census-both.component';
import { CreateCensusCalenderComponent } from './includes/create-census-calender/create-census-calender.component';
import { CensusDetailReportComponent } from './includes/therapy-tab/census-detail-report/census-detail-report.component';
import { RecurrentOptionDialogComponent } from './includes/recurrent-option-dialog/recurrent-option-dialog.component';
import { MatrixConditionsComponent } from './includes/patient-detail/matrix-conditions/matrix-conditions.component';
import { MatrixPatientCoveragesComponent } from './includes/patient-detail/matrix-patient-coverages/matrix-patient-coverages.component';
import { GoalStatusReportComponent } from './includes/therapy-tab/goal-status-report/goal-status-report.component';
import { PatientImporterRecurrentComponent } from './includes/patient-rounding-sheets/patient-importer-recurrent/patient-importer-recurrent.component';
import { PatientAppointmentsComponent } from './includes/patient-appointments/patient-appointments.component';
import { AdminSchedulerComponent } from './includes/admin-scheduler/admin-scheduler.component';
import { ShedulerPatientDialogComponent } from './includes/sheduler-patient-dialog/sheduler-patient-dialog.component';
import { AuditPopupComponent } from './includes/biller-dashboard/audit-popup/audit-popup/audit-popup.component';
import { WeeklyFunctionalStatusReportComponent } from './includes/therapy-tab/weekly-functional-status-report/weekly-functional-status-report.component';
import { ReasonDialogComponent } from './includes/note-editor/reason-dialog/reason-dialog.component';
import { TensReportComponent } from './includes/therapy-tab/tens-report/tens-report.component';
import { CompanySpecificCptsComponent } from './includes/note-sidebar/company-specific-cpts/company-specific-cpts.component';
import { DnsHealingReportComponent } from './includes/wound/wound-reports/reports/dns-healing-report/dns-healing-report.component';
import { BillerChargesAdminComponent } from './includes/biller-dashboard/biller-charges-admin/biller-charges-admin.component';
import { TissueAnalyticReportComponent } from './includes/wound/wound-reports/reports/tissue-analytic/tissue-analytic.component';
import { TissueAnalyticPDFReportComponent } from './includes/wound/wound-reports/reports/tissue-analytic/tissue-analytic-report/tissue-analytic-report.component';
import { DialogCensusColumnAdminComponent } from './includes/dialog-census-column-admin/dialog-census-column-admin.component';
import { PhraseTemplatesComponent } from './includes/wound/phrase-templates/phrase-templates.component';
import { PhraseTemplateEditorComponent } from './includes/wound/phrase-templates/phrase-template-editor/phrase-template-editor.component';
import { MergePatientsDataComponent } from './includes/manage-companies/merge-patients-data/merge-patients-data.component';
import { MergeFacilitiesDataComponent } from './includes/manage-companies/merge-facilities-data/merge-facilities-data.component';
import { PatientDiagnosisComponent } from './includes/patient-detail/patient-diagnosis/patient-diagnosis.component';
import { CoSignerComponent } from './includes/manage-companies/co-signer/co-signer.component';
import { CensusColumnComponent } from './includes/manage-companies/census-column/census-column.component';
import { PayerInformationComponent } from './includes/patient-detail/payer-information/payer-information.component';
import { WounndGraphDialogComponent } from './includes/wound/wound-reports/reports/qapi-report/wounnd-graph-dialog/wounnd-graph-dialog.component';
import { AllCompaniesComponent } from './includes/manage-companies/all-companies/all-companies.component';
import { ManageMyCompaniesComponent } from './includes/manage-companies/manage-my-companies/manage-my-companies.component';
import { CompanyAssosiationComponent } from './includes/manage-companies/company-assosiation/company-assosiation.component';
import { AssosiationDialogComponent } from './includes/manage-companies/assosiation-dialog/assosiation-dialog.component';
import { CompanySettingComponent } from './includes/manage-companies/company-setting/company-setting.component';
import { LosReportComponent } from './includes/report/los-report/los-report.component';
import { IpaReportComponent } from './includes/report/ipa-report/ipa-report.component';
import { AdminChargesReportComponent } from './includes/wound/wound-reports/reports/charges-report/admin-charges-report.component';
import { LoginAuthRedirectComponent } from './includes/login-auth-redirect/login-auth-redirect.component';
import { ClinicalSummaryReportV2Component } from './includes/wound/wounds-v2/wound-reports-v2/reports-v2/qapi-report-v2/clinical-summary-report-v2/clinical-summary-report-v2.component';
import { AmputationReportV2Component } from './includes/wound/wounds-v2/wound-reports-v2/reports-v2/qapi-report-v2/amputation-report-v2/amputation-report-v2.component';
import { OperationsSummaryReportV2Component } from './includes/wound/wounds-v2/wound-reports-v2/reports-v2/qapi-report-v2/operations-summary-report-v2/operations-summary-report-v2.component';
import { RehospitalizationReportV2Component } from './includes/wound/wounds-v2/wound-reports-v2/reports-v2/qapi-report-v2/rehospitalization-report-v2/rehospitalization-report-v2.component';
import { QapiReportV2Component } from './includes/wound/wounds-v2/wound-reports-v2/reports-v2/qapi-report-v2/qapi-report-v2.component';
import { DnsReportV2Component } from './includes/wound/wounds-v2/wound-reports-v2/reports-v2/dns-report-v2/dns-report-v2.component';
import { UwhTypeTabularReportV2Component } from './includes/wound/wounds-v2/wound-reports-v2/reports-v2/uwh-type-tabular-report-v2/uwh-type-tabular-report-v2.component';
import { WoundReportsV2Component } from './includes/wound/wounds-v2/wound-reports-v2/wound-reports-v2.component';
import { DnsHealingReportV2Component } from './includes/wound/wounds-v2/wound-reports-v2/reports-v2/dns-healing-report-v2/dns-healing-report-v2.component';
import { PostRoundsReportComponent } from './includes/wound/wounds-v2/wound-reports-v2/reports-v2/post-rounds-report/post-rounds-report.component';
import { WoundFilenamePopupComponent } from './includes/wound/wound-reports/reports/tissue-analytic/wound-filename-popup/wound-filename-popup.component';
import { ChargeCommentDialogComponent } from './includes/biller-dashboard/charge-comment-dialog/charge-comment-dialog.component';
import { SnfWcNurseListComponent } from './includes/snf-wc-nurse-list/snf-wc-nurse-list.component';
import { SnfWcNurseFacilityAssociationComponent } from './includes/snf-wc-nurse-facility-association/snf-wc-nurse-facility-association.component';
import { SnfWcNurseAssociationComponent } from './includes/snf-wc-nurse-association/snf-wc-nurse-association.component';
import { SnfWcNurseNurseAssociationComponent } from './includes/snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse-snf_wc_nurse-association.component';
import { ShowChargesTableComponent } from './includes/biller-dashboard/show-charges-table/show-charges-table.component';
import { WoundCareMainWidgetComponent } from './includes/note-sidebar/wound-care-main-widget/wound-care-main-widget.component';
import { Hl7ChargesComponent } from './includes/manage-companies/hl7-charges/hl7-charges.component';
import { MatrixDiagnosisComponent } from './includes/note-sidebar/matrix-diagnosis/matrix-diagnosis.component';
import { AdminCensusWoundReportComponent } from './includes/wound/admin-census-wound-report/admin-census-wound-report.component';
import { NoteAgingReportComponent } from './includes/note-aging-report/note-aging-report.component';
import { WoundNurseListComponent } from './includes/wound-nurse-list/wound-nurse-list.component';
import { WoundNurseAssociationsComponent } from './includes/wound-nurse-associations/wound-nurse-associations.component';
import { WoundNurseProviderAssociationComponent } from './includes/wound-nurse-provider-association/wound-nurse-provider-association.component';
import { WoundNurseFacilityAssociationComponent } from './includes/wound-nurse-facility-association/wound-nurse-facility-association.component';
import { MoveWoundToCensusComponent } from './includes/note-sidebar/wound-care-widget/wound-care-widget-item/move-wound-to-census/move-wound-to-census.component';
import { PressureWoundReportComponent } from './includes/wound/wound-reports/reports/pressure-wound-report/pressure-wound-report.component';
import { OpenPostDialogComponent } from './includes/open-post-dialog/open-post-dialog.component';
import { ViewSharedFacilityNoteComponent } from './includes/transcription-create/view-shared-facility-note/view-shared-facility-note.component';
import { ViewOnlyUsersListComponent } from './includes/view-only-users-list/view-only-users-list.component';
import { ViewOnlyUsersProviderAssociationComponent } from './includes/view-only-users-provider-association/view-only-users-provider-association.component';
import { DataExportComponent } from './includes/data-export/data-export.component';
import { HpOperationalReportComponent } from './includes/wound/wound-reports/reports/hp-operational-report/hp-operational-report.component';
import { NoteEditorModule } from './modules/note-editor/note-editor.module';
import { CustomWoundReportComponent } from './includes/wound/wound-reports/reports/custom-wound-report/custom-wound-report.component';
import { TagsComponent } from './includes/manage-companies/tags/tags.component';
import { AddTagsDialogueComponent } from './includes/manage-companies/tags/add-tags-dialogue/add-tags-dialogue.component';
import { AddWoundEnterpriseReportDialogueComponent } from './includes/wound/wound-enterprise-reports/add-wound-enterprise-report-dialogue/add-wound-enterprise-report-dialogue.component';
import { EditTagsDialogueComponent } from './includes/manage-companies/tags/edit-tags-dialogue/edit-tags-dialogue.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { GoogleAuthenticatorComponent } from './includes/profile/google-authenticator/google-authenticator.component';
import { DeleteTagDialogueComponent } from './includes/manage-companies/tags/delete-tag-dialogue/delete-tag-dialogue.component';
import { WoundExceptionsReportComponent } from './includes/wound/wound-reports/reports/wound-exceptions-report/wound-exceptions-report.component';
import { UpdatePatientInfoDialogComponent } from './includes/note-sidebar/update-patient-info-dialog/update-patient-info-dialog.component';
import { WoundDermComponent } from './includes/wound-derm/wound-derm.component';
import { DermComponent } from './includes/derm/derm.component';
import { DermDatasetComponent } from './includes/derm/derm-dataset/derm-dataset.component';
import { TelehealthWoundAssessmentReportComponent } from './includes/wound/wound-reports/reports/telehealth-wound-assessment-report/telehealth-wound-assessment-report.component';
import { PatientsWithActiveWoundsComponent } from './includes/census-patient-list/patients-with-active-wounds/patients-with-active-wounds.component';
import { SaveReportDialogComponent } from './includes/wound/wound-reports/reports/custom-wound-report/save-report-dialog/save-report-dialog.component';
import { DataOptionComponent } from './includes/wound/wound-reports/reports/custom-wound-report/data-option/data-option.component';
import { ReportsModuleComponent } from './includes/reports-module/reports-module.component';
import { DuplicatePatientDialogComponent } from './includes/matrix-patients/duplicate-patient-dialog/duplicate-patient-dialog.component';
import { MipsReportsComponent } from './includes/reports-module/mips-reports/mips-reports.component';
import { MipReportAuditPopupComponent } from './includes/reports-module/mip-report-audit-popup/mip-report-audit-popup.component';
import { MyMipsComponent } from './includes/my-mips/my-mips.component';
import { TemplateFacilityAssociationComponent } from './includes/template-facility-association/template-facility-association.component';
import { BillerPracticeAssociationComponent } from './includes/biller-practice-association/biller-practice-association.component';
import { CompanyUsersComponent } from './includes/company-users/company-users.component';
import { ViewFavouriteCptsDialogComponent } from './includes/note-sidebar/view-favourite-cpts-dialog/view-favourite-cpts-dialog.component';
import { MipsAdminListComponent } from './includes/manage-companies/mips-admin-list/mips-admin-list.component';
import { UploadFileFromPcComponent } from './includes/upload-file-from-pc/upload-file-from-pc.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { WoundEnterpriseReportsComponent } from './includes/wound/wound-enterprise-reports/wound-enterprise-reports.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
// import { CalendarComponent } from './calandar/calendar.component';
import { ScheduleAppointmentComponent } from './includes/schedule-appointment/schedule-appointment.component';
import { AppointmentCalenderComponent } from './includes/appointment-calender/appointment-calender.component';
import { AppointmentPatientDetailComponent } from './includes/appointment-patient-detail/appointment-patient-detail.component';
import { AddNewAppointmentComponent } from './includes/add-new-appointment/add-new-appointment.component';
import { DailyAppointmentListComponent } from './includes/daily-appointment-list/daily-appointment-list.component';
import { AddAppointmentDialogComponent } from './add-appointment-dialog/add-appointment-dialog.component';
import { AppointmentAddPatientDialogComponent } from './appointment-add-patient-dialog/appointment-add-patient-dialog.component';
import { AddBlockAppointmentDialogComponent } from './add-block-appointment-dialog/add-block-appointment-dialog.component';
import { TemplateConfirmationDialogComponent } from './includes/template-confirmation-dialog/template-confirmation-dialog.component';
import { SchedulerSettingComponent } from './includes/scheduler-setting/scheduler-setting.component';
import { AppointmentTypeDialogComponent } from './includes/appointment-type-dialog/appointment-type-dialog.component';
import { AdvancedNoteTextSearchComponent } from './includes/advanced-note-text-search/advanced-note-text-search.component';
import { CompanySettingsDialogComponent } from './includes/company-settings-dialog/company-settings-dialog.component';
import { DialogueManageIcdCodesComponent } from './components/company/manage-icd-codes/dialogue-manage-icd-codes/dialogue-manage-icd-codes.component';
import { DialogueAddIcdCodeComponent } from './components/company/manage-icd-codes/dialogue-add-icd-code/dialogue-add-icd-code.component';
import { DialogueAddCptCodeComponent } from './components/company/manage-cpts/dialogue-add-cpt-code/dialogue-add-cpt-code.component';
import { DialogueManageCptCodesComponent } from './components/company/manage-cpts/dialogue-manage-cpt-codes/dialogue-manage-cpt-codes.component';
import { AddIcdToProviderComponent } from './components/company/manage-icd-codes/add-icd-to-provider/add-icd-to-provider.component';
import { CompanyLogsModuleComponent } from './components/company/company-logs/company-logs-module.component';
import { NoteTextSearchHistoryComponent } from './includes/note-text-search-history/note-text-search-history.component';
import { MoveWoundComponent } from './includes/manage-companies/move-wound/move-wound.component';
import { OrderSettingComponent } from './includes/order-setting/order-setting.component';
import { PatientOrdersComponent } from './includes/patient-orders/patient-orders.component';
import { MatMultiSelectChipsComponent } from './includes/relationship/mat-multi-select-chips/mat-multi-select-chips.component';
import { PatientOpportunitiesReportComponent } from './includes/reports-module/patient-opportunities-report/patient-opportunities-report.component';
import { PatientOpportunitiesPopupComponent } from './includes/reports-module/patient-opportunities-report/patient-opportunities-popup/patient-opportunities-popup.component';
import { CompanyAuditReportsComponent } from './includes/reports-module/company-audit-reports/company-audit-reports.component';

import { DatePipe } from '@angular/common';
import { ConsentComponent } from './includes/manage-companies/consent/consent.component';
import { DialogConsentFormComponent } from './includes/manage-companies/consent/dialog-consent-form/dialog-consent-form.component';
import { AggrementDialogComponent } from './includes/manage-companies/consent/aggrement-dialog/aggrement-dialog.component';
import { DnsLogsComponent } from './includes/dns-logs/dns-logs.component';



import { MergeDuplicateNamePatientComponent } from './includes/merge-duplicate-name-patient/merge-duplicate-name-patient.component';
import { MergingFacilityPCCToMatrixComponent } from './includes/merging-facility-pcc-to-matrix/merging-facility-pcc-to-matrix.component';
import { VisitStatusDialogComponent } from './includes/visit-status-dialog/visit-status-dialog.component';
import { ChangeAppointmentStatusDialogComponent } from './includes/change-appointment-status-dialog/change-appointment-status-dialog.component';
import { DaysToRemissionReportComponent } from './includes/wound/wound-reports/reports/days-to-remission-report/days-to-remission-report.component';
import { PatientNameDialogComponent } from './includes/wound/wound-reports/reports/days-to-remission-report/patient-name-dialog/patient-name-dialog.component';
import { ZoomComponent } from './zoom/zoom.component';
import { ProcedureNoteDatasetComponent } from './includes/procedure-notes/procedure-note-dataset/procedure-note-dataset.component';
import { WoundOutcomeReportComponent } from './includes/wound/wound-reports/reports/wound-outcome-report/wound-outcome-report.component';
import { ProcedureNoteDialogComponent } from './includes/procedure-notes/procedure-note-dialog/procedure-note-dialog.component';
import { ProcedureNoteDropdownComponent } from './includes/procedure-notes/procedure-note-dialog/procedure-note-dropdown/procedure-note-dropdown.component';
import { ProcedureNoteCheckboxComponent } from './includes/procedure-notes/procedure-note-dialog/procedure-note-checkbox/procedure-note-checkbox.component';
import { EditRecurringEventDialogComponent } from './includes/edit-recurring-event-dialog/edit-recurring-event-dialog.component';
import { CompanyOrganizationsComponent } from './includes/company-organizations/company-organizations.component';
import { OrganizationDialogComponent } from './includes/organization-dialog/organization-dialog.component';
import { NgxMatTimepickerModule as MatTimePicker } from "ngx-mat-timepicker";
import { SubmittedMipsComponent } from './includes/note-sidebar/note-addCharge/submitted-mips/submitted-mips.component';
import { OpenSettingDialogComponent } from './includes/open-setting-dialog/open-setting-dialog.component';
import { CameraCaptureComponent } from './shared/camera-capture/camera-capture.component';
import { ImageCarouselComponent } from './shared/image-carousel/image-carousel.component';
import { FormulaBuilderComponent } from './includes/procedure-notes/formula-builder/formula-builder.component';
@NgModule({
  declarations: [
    /**
     * pipes
     */
    ProcedureNoteDialogComponent,
    ProcedureNoteDropdownComponent,
    ProcedureNoteCheckboxComponent,
    AudioDetailPipe,
    AppComponent,
    AudioComponent,
    LoginComponent,
    AuthComponent,
    EmailVerificationComponent,
    RegisterComponent,
    FacilityComponent,
    ForgotPasswordComponent,
    CodeVerificationComponent,
    AudioChunkComponent,
    // HeaderComponent,
    DashboardComponent,
    // UploadFileBottomSheet,
    FilterDialog,
    AudioListDialog,
    PatientRoundingSheetCommentsDialog,
    NoteTypeDialog,
    CreateNewFolderDialog,
    DeleteConfirmationDialog,
    UploadFileDialog,
    ShareItemDialog,
    WatchHelpVideoDialog,
    TranscriptionTemplateDialog,
    PatientDocumentsUploadDialog,
    PatientsDialog,
    PatientAddDialog,
    AddendumNoteDialog,
    UploadNoteConfirmationDialog,
    ViewNoteDialog,
    PCCViewNoteDialog,
    PreviewTemplateDialog,
    ViewNoteAddendumDialog,
    ViewNoteAuditDialog,
    PatientDetailDialog,
    PatientListComponent,
    PatientAddComponent,
    // PatientAddFormComponent,
    PatientChargeComponent,
    AddChargeComponent,
    TranscriptionDetailComponent,
    ProfileComponent,
    ResetPasswordComponent,
    PdfViewComponent,
    MatMultiSelectChipsComponent,
    DoctorListComponent,
    TranscriberListComponent,
    RelationshipComponent,
    TranscriptionCreateComponent,
    NoteEditorComponent,
    NoteEditorNewVersionComponent,
    TranscriptionViewComponent,
    RecordingFilterPipe,
    RecordingStatusFilterPipe,
    SafePipe,
    DoctorNameFilterPipe,
    DriveComponent,
    SharedDriveComponent,
    TemplatesComponent,
    TemplateCreateComponent,
    PatientDetailComponent,
    PatientScriptSureComponent,
    PatientDetailListComponent,
    PatientsComponent,
    FileExplorerComponent,
    // TemplateExplorerComponent,
    RRGenExplorerComponent,
    RRgenExplorerFilesUploadDialog,
    // FacilityDialog,
    FacilityAddDialog,
    RolesDialog,
    PatientImporterDialog,
    MultiplePatientsAddDialog,
    PatientRoundingSheetDialog,
    UpdatePatientRoundingSheetDialog,
    PatientRoundingSheetPatientListComponent,
    CensusPatientListComponent,
    DrugHistoryConsentDialog,
    EligibilityDialog,
    CensusPatientListV2Component,
    TranscriptionListComponent,
    AudioListComponent,
    PrivacyPolicyComponent,
    PublicHeaderComponent,
    SwitchButtonComponent,
    FilterFormComponent,
    UserManagementComponent,
    ActivityComponent,
    AdminDictationsComponent,
    AdminNotesComponent,
    AdminComponent,
    AdminDashboardComponent,
    ItemDetailDialog,
    PaginationComponent,
    NoteCommentsComponent,
    DictationsGroupComponent,
    // NotificationsComponent,
    NotificationsPageComponent,
    SimulateUserComponent,
    // NotificationsSettingsComponent,
    AdminNotificationsSettingsComponent,
    // PccWidgetComponent,
    PccPatientListComponent,
    ReportGenerateComponent,
    DetailReporComponent,
    TrainingSystemComponent,
    PreviewDocumentComponent,
    PopupReportViewComponent,
    InsuranceCompanyComponent,
    PccNotificationPanelComponent,
    PatientRoundingSheetsComponent,
    // CensusListComponent,
    BillerListComponent,
    BillerProviderAssociationComponent,
    ProviderProviderAssociationComponent,
    ProviderFacilityAssociationComponent,
    // BillerDashboardComponent,
    FacesheetComponent,
    ObservationComponent,
    EpisodesOfCareComponent,
    MedicationsComponent,
    PatientCoveragesComponent,
    PatientDiagnosticReportsComponent,
    PatientNutritionOrdersComponent,
    ConditionsComponent,
    ActivitiesOfDailyLivingComponent,
    ActivitiesOfDailyLivingResponseComponent,
    ActivitiesOfDailyLivingScoreComponent,
    PatientAdmitDischargeHistory,
    ChargeCaptureComponent,
    // BillerHeaderComponent,
    AllergyIntolerancesComponent,
    ESignComponent,
    AddMipsComponent,
    AddMipsQuestionComponent,
    MeasureNumberQuestionsDialog,
    AddSignatureComponent,
    ChargesPopupComponent,
    AdministrationComponent,
    SchedulesComponent,
    ActivitiesOfDailyLivingResponseComponent,
    LastAdmissionComponent,
    ImmunizationsComponent,
    DictionaryDialogComponent,
    DictionaryEditorDialogComponent,
    NoteAuditoryHistoryComponent,
    NotePatientDetailsComponent,
    CountFilterComponent,
    NoteAddendumsComponent,
    PccAuthRedirectComponent,
    LoginAuthRedirectComponent,
    ProviderChargeComponent,
    ProviderChargeCaptureComponent,
    ManageChargeComponent,
    PhrasesComponent,
    SidebarChargesComponent,
    SidebarMipsComponent,
    MipsQuestionnaireResultsComponent,
    MipsQuestionnaireComponent,
    MipsQuestionnaire181Component,
    MipsQuestionnaire126Component,
    MipsQuestionnaireTreeComponent,
    MipsQuestionaireTreeItemComponent,
    FeedbackComponent,
    AdminFeedbackComponent,
    DialogFeedbackComponent,
    SidebarCptsComponent,
    SidebarDiagnosesComponent,
    BillerNoteComponent,
    CommentsComponent,
    // SidebarVideoCallComponent,
    ReportsComponent,
    ReportDetailComponent,
    NotesListComponent,
    PCCNotesListComponent,
    PCCNoteTypesListComponent,
    NoteSidebarComponent,
    NoteTemplatesComponent,
    TransReportComponent,
    FacilityContactDialogComponent,
    PayorContactDialogComponent,
    CompanyFacilityFormComponent,
    // CompanyFacilityFormComponent,
    CompanyUserManagementFormComponent,
    CompanyUserManagementAddUserComponent,
    MobileEditorComponent,
    MobileEditorNewVersionComponent,
    ProviderProviderAssociationComponent,
    UserDefinedPhrasesComponent,
    SystemDefinedPhrasesComponent,
    // CalendarComponent,
    TemplateHeadingsComponent,
    ChargesCatagoriesDialogComponent,
    PatientDiagnosticReportsTestSetComponent,
    NotesWidgetComponent,
    ManageCategoriesComponent,
    TemplateCreateSidebarComponent,
    CensusFileUploadDialogeComponent,
    PccEventNotificationsComponent,
    ManageCompaniesComponent,
    RegisterCompanyComponent,
    TemplateMobileEditorComponent,
    TemplateMobileEditorNewVersionComponent,
    ExportNotesDialogComponent,
    AudioNoteListComponent,
    PhraseComponent,
    PhraseEditorDialogSystemComponent,
    // AdminSideTemplatesComponent,
    TemplateAssociationComponent,
    PhraseComponent,
    PhraseEditorDialogSystemComponent,
    NoteStatusIconComponent,
    NoteDeleteIconComponent,
    PhrasesAssociationComponent,
    AdminSidePatientsComponent,
    SystemphraseeditorComponent,
    AddNewsComponent,
    // NotificationBarComponent,
    OpenReleaseDialogComponent,
    ReleaseComponent,
    RehabDocsPopupComponent,
    ActiveCensusPatientListComponent,
    PatientTitleComponent,
    DialogConfirmationComponent,
    GoogleDriveComponent,
    MedicalAssistantAssociationComponent,
    MedicalAssistantListComponent,
    TranscribeComponent,
    DrivePhrasesComponent,
    CensusCommentComponent,
    AddUserPhraseToProviderComponent,
    // CustomPdfDialogComponent,
    // UserSignatureComponent,
    AmazonMedicalTranscribeComponent,
    // ManageMipsComponent,
    AwsTranscribeComponent,
    DrivePhraseEditorComponent,
    PhraseMobileEditorComponent,
    PatientDiagnosisDialogComponent,
    GeneralConfirmationDialogComponent,
    ForbiddenPageComponent,
    // CapitalizeFirstDirective,
    AdminLoginComponent,
    AdminAuthComponent,
    PatientListSiderbarComponent,
    HippaAgreementComponent,
    AdminListComponent,
    BillingCompanyAssociationComponent,
    CompanyLogsModuleComponent,
    ChargesReportComponent,
    ManagerListComponent,
    AdminChargeCaptureComponent,
    CensusNotesComponent,
    NoteAddChargeComponent,
    NoteSidebarCptsComponent,
    NoteSidebarIcdsComponent,
    TicketsComponent,
    TicketDetailsComponent,
    TicketCommentsComponent,
    UserTicketsComponent,
    UserTicketDetailsComponent,
    AddTicketsComponent,
    WoundComponent,
    WoundTagsComponent,
    WoundGroupsComponent,
    DataSetsComponent,
    EmailTemplateComponent,
    CreateEmailTemplateDialogComponent,
    SendEmailTemplateDialogComponent,
    WoundCareWidgetComponent,
    WoundCareWidgetItemComponent,
    WoundCareWidgetChildItemComponent,
    CreateWoundDialogComponent,
    MoveWoundToCensusComponent,
    SetWoundStageDialogComponent,
    WoundICD10DialogComponent,
    WoundImagePreviewDialogComponent,
    WoundImageUploadDialogComponent,
    WoundImageSnapshotDialogComponent,
    WoundNoteDialogComponent,
    CptIcdAssociationComponent,
    ForgotPasswordCodeVerificationComponent,
    WoundCareWidgetWoundListComponent,
    GeneralReportComponent,
    QueryReportComponent,
    CreateGeneralReportDialogComponent,
    CreateQueryReportDialogComponent,
    AutomatedEmailComponent,
    CompanyEmailComponent,
    EmailComponent,
    CensusCommentsComponent,
    WoundReportsComponent,
    CompaniesConfigrationComponent,
    ImageEditorComponent,
    ChargeTimePeriodReportComponent,
    // PatientAddFormDialogComponent,
    WoundGroupsICDTagsMappingComponent,
    WoundChartComponent,
    WoundCareWidgetItemGraphComponent,
    CompanyFacilityAssociationComponent,
    ProcedureDatasetsComponent,
    ProcedureCategoryDataComponent,
    PatientEditDialogComponent,
    ProcedureCategoryTemplatesComponent,
    CompanySettingsComponent,
    ProcedurePhraseEditorComponent,
    PhraseTemplateEditorComponent,
    WoundPeriodicTreatmentReportComponent,
    WoundHealingRateReportComponent,
    UwhTypeTabularReportComponent,
    NonPccAllergyIntolerancesComponent,
    CreateNonPccAllergyIntolerancesDialogComponent,
    AdminPatientMedicalHistoryComponent,
    PatientMedicalHistoryComponent,
    PatientDischargeReportComponent,
    DnsReportComponent,
    DnsHealingReportComponent,
    TissueAnalyticReportComponent,
    TissueAnalyticPDFReportComponent,
    QapiReportComponent,
    VisitReportComponent,
    KentVisitReportComponent,
    AdminChargesReportComponent,
    LosReportComponent,
    IpaReportComponent,
    NurseProviderAssociationComponent,
    NurseListComponent,
    NurseFacilityAssociationComponent,
    NursingDashboardComponent,
    ClinicalSummaryReportComponent,
    OperationsSummaryReportComponent,
    FooterComponent,
    DebridementReportComponent,
    BusinessOfficerAssociationComponent,
    AdminSidePatientEditComponent,
    AmputationReportComponent,
    BusinessOfficerListComponent,
    MergePatientsComponent,
    MergingComponent,
    MergeFacilitiesComponent,
    SendEmailDialogComponent,
    MissedFacilityReportComponent,
    TimePickerWidgetComponent,
    DialogWithChoiceComponent,
    MatrixPatientsComponent,
    NoteIcdSuggestionsComponent,
    TestinputDialogueComponent,
    NoteIcdHistoryComponent,
    NoteCptHistoryComponent,
    PdfViewerPopComponent,
    DialogEmailSendingComponentComponent,
    WoundNotesComponent,
    TestProviderListComponent,
    ContactUsComponent,
    DialogInformationComponent,
    // NotesSettingsComponent,
    MergefacilitypcctodnComponent,
    MergefacilitypcctopccComponent,
    MergingfacilityDntoMatrixComponent,
    MergingfacilityMatrixtoDnComponent,
    RehospitalizationReportComponent,
    TherapyTabComponent,
    CertificationComponent,
    CommunicationComponent,
    EmCodesComponent,
    ProcedureCodesComponent,
    AddNewCptCodeComponent,
    CnpListComponent,
    CnpListComponent,
    EmCodesComponent,
    ProcedureCodesComponent,
    AddNewCptCodeComponent,
    WoundPharasesComponent,
    NoteCptProcedureCodesComponent,
    WoundphrasebothComponent,
    ProcedurephraseComponent,
    CensusBothComponent,
    CreateCensusCalenderComponent,
    // CensusCalenderComponent,
    CensusDetailReportComponent,
    RecurrentOptionDialogComponent,
    MatrixConditionsComponent,
    MatrixPatientCoveragesComponent,
    GoalStatusReportComponent,
    PatientImporterRecurrentComponent,
    PatientAppointmentsComponent,
    SubmittedMipsComponent,
    AdminSchedulerComponent,
    ShedulerPatientDialogComponent,
    AuditPopupComponent,
    WeeklyFunctionalStatusReportComponent,
    ReasonDialogComponent,
    TensReportComponent,
    CompanySpecificCptsComponent,
    BillerChargesAdminComponent,
    DialogCensusColumnAdminComponent,
    // PatientAddFormDialogComponent,
    PhraseTemplatesComponent,
    MergePatientsDataComponent,
    MergeFacilitiesDataComponent,
    PatientDiagnosisComponent,
    CoSignerComponent,
    CensusColumnComponent,
    PayerInformationComponent,
    WounndGraphDialogComponent,
    AllCompaniesComponent,
    ManageMyCompaniesComponent,
    CompanyAssosiationComponent,
    AssosiationDialogComponent,
    CompanySettingComponent,
    WoundReportsV2Component,
    UwhTypeTabularReportV2Component,
    DnsReportV2Component,
    QapiReportV2Component,
    RehospitalizationReportV2Component,
    OperationsSummaryReportV2Component,
    AmputationReportV2Component,
    ClinicalSummaryReportV2Component,
    DnsHealingReportV2Component,
    PostRoundsReportComponent,
    WoundFilenamePopupComponent,
    ChargeCommentDialogComponent,
    SnfWcNurseListComponent,
    DuplicatePatientDialogComponent,
    SnfWcNurseAssociationComponent,
    SnfWcNurseNurseAssociationComponent,
    ReportsModuleComponent,
    MipsReportsComponent,
    MipReportAuditPopupComponent,
    ShowChargesTableComponent,
    SnfWcNurseFacilityAssociationComponent, WoundCareMainWidgetComponent, Hl7ChargesComponent, MatrixDiagnosisComponent, WoundNurseListComponent, AdminCensusWoundReportComponent, WoundNurseAssociationsComponent, WoundNurseProviderAssociationComponent, WoundNurseFacilityAssociationComponent, MoveWoundToCensusComponent, PressureWoundReportComponent, OpenPostDialogComponent, ViewSharedFacilityNoteComponent, ViewOnlyUsersListComponent, ViewOnlyUsersProviderAssociationComponent, DataExportComponent,
    NoteAgingReportComponent, UpdatePatientInfoDialogComponent,
    TagsComponent, AddTagsDialogueComponent, EditTagsDialogueComponent, DeleteTagDialogueComponent,
    WoundCareMainWidgetComponent,
    MipsAdminListComponent,
    UploadFileFromPcComponent,
    WoundCareMainWidgetComponent,
    TemplateFacilityAssociationComponent,
    BillerPracticeAssociationComponent,
    DnsLogsComponent,
    CompanyUsersComponent,
    Hl7ChargesComponent,
    MatrixDiagnosisComponent,
    WoundNurseListComponent,
    AdminCensusWoundReportComponent,
    WoundNurseAssociationsComponent,
    WoundNurseProviderAssociationComponent,
    WoundNurseFacilityAssociationComponent,
    MoveWoundToCensusComponent,
    PressureWoundReportComponent,
    OpenPostDialogComponent,
    ViewSharedFacilityNoteComponent,
    ViewOnlyUsersListComponent,
    ViewOnlyUsersProviderAssociationComponent,
    CustomWoundReportComponent,
    HpOperationalReportComponent,
    WoundExceptionsReportComponent,
    WoundDermComponent,
    DermComponent,
    DermDatasetComponent,
    TelehealthWoundAssessmentReportComponent,
    PatientsWithActiveWoundsComponent,
    SaveReportDialogComponent,
    DataOptionComponent,
    MyMipsComponent,
    ViewFavouriteCptsDialogComponent,
    WoundEnterpriseReportsComponent,
    AddWoundEnterpriseReportDialogueComponent,
    ScheduleAppointmentComponent,
    AppointmentCalenderComponent,
    AppointmentPatientDetailComponent,
    AddNewAppointmentComponent,
    DailyAppointmentListComponent,
    AddAppointmentDialogComponent,
    AppointmentAddPatientDialogComponent,
    AddBlockAppointmentDialogComponent,
    TemplateConfirmationDialogComponent,
    SchedulerSettingComponent,
    AppointmentTypeDialogComponent,
    AdvancedNoteTextSearchComponent,
    Hl7ChargesComponent, MatrixDiagnosisComponent, WoundNurseListComponent, AdminCensusWoundReportComponent, WoundNurseAssociationsComponent, WoundNurseProviderAssociationComponent, WoundNurseFacilityAssociationComponent, MoveWoundToCensusComponent, PressureWoundReportComponent, OpenPostDialogComponent, ViewSharedFacilityNoteComponent, ViewOnlyUsersListComponent, ViewOnlyUsersProviderAssociationComponent, CustomWoundReportComponent, HpOperationalReportComponent, WoundExceptionsReportComponent, WoundDermComponent, DermComponent, DermDatasetComponent, TelehealthWoundAssessmentReportComponent, PatientsWithActiveWoundsComponent, SaveReportDialogComponent, DataOptionComponent, WoundEnterpriseReportsComponent, AddWoundEnterpriseReportDialogueComponent, ScheduleAppointmentComponent, AppointmentCalenderComponent, AppointmentPatientDetailComponent, AddNewAppointmentComponent, DailyAppointmentListComponent, AddAppointmentDialogComponent, AppointmentAddPatientDialogComponent, AddBlockAppointmentDialogComponent, TemplateConfirmationDialogComponent, SchedulerSettingComponent, AppointmentTypeDialogComponent, CompanySettingsDialogComponent, NoteTextSearchHistoryComponent, MoveWoundComponent, OrderSettingComponent, PatientOrdersComponent,
    PatientOpportunitiesReportComponent,
    PatientOpportunitiesPopupComponent,
    CompanyAuditReportsComponent,
    ConsentComponent,
    DialogConsentFormComponent,
    AggrementDialogComponent,
    NoteTimeLogsComponent,
    MergeDuplicateNamePatientComponent, 
    VisitStatusDialogComponent, 
    MergingFacilityPCCToMatrixComponent, 
    ChangeAppointmentStatusDialogComponent, 
    DaysToRemissionReportComponent, 
    PatientNameDialogComponent, 
    ZoomComponent, 
    ProcedureNoteDatasetComponent,
    EditRecurringEventDialogComponent,
    CompanyOrganizationsComponent, OrganizationDialogComponent, WoundOutcomeReportComponent,
    OpenSettingDialogComponent,
    CameraCaptureComponent,
    ImageCarouselComponent,
    FormulaBuilderComponent
  ],
  imports: [
    // LibraryModule
    MatTimePicker,
    FullCalendarModule,
    NoteEditorModule,
    ColorPickerModule,
    MentionModule,
    MatRippleModule,
    // CountdownTimerModule.forRoot(),
    MaterialModule,
    DragDropModule,
    CKEditorModule,
    // PinchZoomModule,
    BrowserModule,
    routing,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    // FlexLayoutModule,
    HttpClientModule,
    BrowserModule,
    AngularEditorModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      autoDismiss: true,
      positionClass: 'toast-bottom-right',
    }),
    NgMultiSelectDropDownModule.forRoot(),
    NgxDocViewerModule,
    // PasswordStrengthMeterModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatSliderModule,
    MatBadgeModule,
    MatListModule,
    // DropzoneModule,
    // NgxFileDropModule,
    // DragulaModule.forRoot(),
    NgxDaterangepickerMd.forRoot({
      format: 'MMM DD, YYYY',
    }),
    // VirtualScrollerModule,
    LightboxModule,
    /* application modules */
    LayoutModule,
    AppointmentModule,
    // TwilioModule,
    CompanyModule,
    // PinchZoomModule,
    DoctorProfileModule,
    CKEditorModule,
    // NgxMatDatetimePickerModule,
    // NgxMatTimepickerModule,
    // NgxMatNativeDateModule,
    // NgAudioRecorderModule,
    // SocialLoginModule,
    NgxSliderModule,
    PdfViewerModule,
    SignaturePadModule
  ],
  // entryComponents: [
  //   AddTagsDialogueComponent,
  //   AddWoundEnterpriseReportDialogueComponent,
  //   EditTagsDialogueComponent,
  //   UpdatePatientInfoDialogComponent,
  //   DeleteTagDialogueComponent,
  //   // UploadFileBottomSheet,
  //   WoundCareWidgetComponent,
  //   MipReportAuditPopupComponent,
  //   ViewSharedFacilityNoteComponent,
  //   PatientAddFormDialogComponent,
  //   CountFilterComponent, // for dashboard
  //   FilterDialog, // Added filter dialog #mohsin-dev
  //   AudioListDialog,
  //   DictionaryDialogComponent,
  //   DictionaryEditorDialogComponent,
  //   PatientRoundingSheetCommentsDialog,
  //   NoteTypeDialog,
  //   CreateNewFolderDialog,
  //   DeleteConfirmationDialog,
  //   DuplicatePatientDialogComponent,
  //   UploadFileDialog,
  //   ShareItemDialog,
  //   WatchHelpVideoDialog,
  //   TranscriptionTemplateDialog,
  //   PatientDocumentsUploadDialog,
  //   PatientsDialog,
  //   PatientAddDialog,
  //   AddendumNoteDialog,
  //   UploadNoteConfirmationDialog,
  //   ViewNoteDialog,
  //   PCCViewNoteDialog,
  //   PreviewTemplateDialog,
  //   ViewNoteAddendumDialog,
  //   WounndGraphDialogComponent,
  //   ViewNoteAuditDialog,
  //   PatientDetailDialog,
  //   FacilityDialog,
    // FacilityAddDialog,
  //   RolesDialog,
  //   PatientImporterDialog,
  //   MultiplePatientsAddDialog,
  //   PatientRoundingSheetDialog,
  //   UpdatePatientRoundingSheetDialog,
  //   DrugHistoryConsentDialog,
  //   EligibilityDialog,
  //   AddPayorComponent,
  //   AddAppointmentComponent,
  //   AddAttributeComponent,
  //   PopupReportViewComponent,
  //   InsuranceCompanyComponent,
  //   ESignComponent,
  //   AddMipsComponent,
  //   AddMipsQuestionComponent,
  //   MeasureNumberQuestionsDialog,
  //   AddSignatureComponent,
  //   ChargesPopupComponent,
  //   UploadFileFromPcComponent,
  //   RRgenExplorerFilesUploadDialog,
  //   FacilityContactDialogComponent,
  //   PayorContactDialogComponent,
  //   DialogCensusColumnAdminComponent,
  //   // CompanyFacilityFormComponent,
  //   // CompanyUserManagementFormComponent,
  //   CompanyUserManagementAddUserComponent,
  //   ChargesCatagoriesDialogComponent,
  //   CensusFileUploadDialogeComponent,
  //   ExportNotesDialogComponent,
  //   PhraseComponent,
  //   PhraseEditorDialogSystemComponent,
  //   SystemphraseeditorComponent,
  //   AddNewsComponent,
  //   OpenReleaseDialogComponent,
  //   DialogFeedbackComponent,
  //   FeedbackComponent,
  //   RehabDocsPopupComponent,
  //   DialogConfirmationComponent,
  //   DialogWithChoiceComponent,
  //   DialogInformationComponent,
  //   DialogEmailSendingComponentComponent,
  //   AddUserPhraseToProviderComponent,
  //   CustomPdfDialogComponent,
  //   DrivePhraseEditorComponent,
  //   AssociatedProviderDialogComponent,
  //   PatientDiagnosisDialogComponent,
  //   GeneralConfirmationDialogComponent,
  //   HippaAgreementComponent,
  //   AddTicketsComponent,
  //   CreateWoundDialogComponent,
  //   MoveWoundToCensusComponent,
  //   SetWoundStageDialogComponent,
  //   WoundICD10DialogComponent,
  //   WoundImagePreviewDialogComponent,
  //   WoundImageUploadDialogComponent,
  //   WoundImageSnapshotDialogComponent,
  //   WoundNoteDialogComponent,
  //   CreateEmailTemplateDialogComponent,
  //   SendEmailTemplateDialogComponent,
  //   CreateGeneralReportDialogComponent,
  //   CreateQueryReportDialogComponent,
  //   ImageEditorComponent,
  //   PatientEditDialogComponent,
  //   ProcedurePhraseEditorComponent,
  //   PhraseTemplateEditorComponent,
  //   AssociatedCompaniesDialogComponent,
  //   CreateNonPccAllergyIntolerancesDialogComponent,
  //   SendEmailDialogComponent,
  //   TestinputDialogueComponent,
  //   PdfViewerPopComponent,
  //   CertificationComponent,
  //   CreateCensusCalenderComponent,
  //   RecurrentOptionDialogComponent,
  //   PatientImporterRecurrentComponent,
  //   ShedulerPatientDialogComponent,
  //   AuditPopupComponent,
  //   ReasonDialogComponent,
  //   WoundFilenamePopupComponent,
  //   ChargeCommentDialogComponent,
  //   ViewFavouriteCptsDialogComponent,
  //   OpenPostDialogComponent,
  //   SaveReportDialogComponent,
  //   GoogleAuthenticatorComponent,
  //   PatientsWithActiveWoundsComponent,
  //   MyMipsComponent,
  //   AddAppointmentDialogComponent,
  //   AppointmentAddPatientDialogComponent,
  //   AddBlockAppointmentDialogComponent
  // ],
  providers: [
    ChargesPopupComponent,
    AuditPopupComponent,
    // PatientOpportunitiesPopupComponent,
    MipReportAuditPopupComponent,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: HTTP_INTERCEPTORS, useClass: CookieInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    // { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    // {
    //   provide: DateAdapter,
    //   useClass: MomentDateAdapter,
    //   deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    // },
    // { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    RouterExtService,
    // {
    //   provide: 'SocialAuthServiceConfig',
    //   useValue: {
    //     autoLogin: false,
    //     providers: [
    //       {
    //         id: GoogleLoginProvider.PROVIDER_ID,
    //         provider: new GoogleLoginProvider(
    //           '466640582368-hb1vlnod1pl17hn52v0bk8oq48e2jg2u.apps.googleusercontent.com')
    //       }
    //     ]
    //   } as SocialAuthServiceConfig
    // },
    AuthenticationGuard,
    AuthorizationGuard,
    ProductOwnerGuard,
    DatePipe,
  ],
  exports: [HeaderComponent, NotificationBarComponent, LayoutModule, FooterComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
