import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from "@angular/material/table";
import { SnfWcNurseAssociationService } from '../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { SnfWcFacilityAssociationService } from '../snf-wc-nurse-facility-association/snf-wc-facility-association.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/Modules/user.service';
import { lastValueFrom } from 'rxjs';
import * as global from '../../includes/global';
import { AuthService } from 'src/app/services/auth.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FacilityService } from 'src/app/services/facility.service';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';
import { CompanyService } from 'src/app/services/Modules/company.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-snf-wc-nurse-facility-association',
  templateUrl: './snf-wc-nurse-facility-association.component.html',
  styleUrls: ['./snf-wc-nurse-facility-association.component.css']
})
export class SnfWcNurseFacilityAssociationComponent implements OnInit {
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = ['facility', 'associated_nurses_name', 'template'];
  nurseDropdownSettings: any = {};
  nurses: any = [];
  facilities: any = [];
  associatedNurses: any = [];
  associatedFacilities: any = [];
  companySide: Boolean = false;
  companyId: any;
  templateDropdownSettings: any = {};
  serachTemplate: any;
  searchProviderText: any;
  relationData: any;
  providers: any = [];
  loaderId: string = "app-snf_wc_nurse-facility-association";

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private snfWcNurseAssociationService: SnfWcNurseAssociationService,
    private SnfWcFacilityAssociationService: SnfWcFacilityAssociationService,
    private userService: UserService,
    private _authService: AuthService,
    private loader: NgxUiLoaderService,
    private facilityService: FacilityService,
    public companyService: CompanyService


  ) {
    this.nurseDropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'full_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: true,
    };
    this.templateDropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'title',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: true,
    };

    if (window.location.pathname.includes('company')) {
      this.companySide = true
      this.companyId = this.route.parent.snapshot.params.id

    }
    else {
      this.companySide = false
      this.companyId = ''
    }

  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId);
    await this.initSnfWcNurses();
    await this.initCompanyFacilities();
    await this.initFacilityAssociatedSnfWcNurses();
    this.populateTable();
    this.loader.stopLoader(this.loaderId);
  }

  populateTable() {
    for (const facility of this.facilities) {
      facility['allNurses'] = this.nurses
      if(facility.associated_snf_wc_nurse_ids === undefined) {
        facility.associated_snf_wc_nurse_ids = [];
      }
      for (const associatedFacility of this.associatedFacilities) {
        if (associatedFacility.facility_id && associatedFacility.facility_id?._id === facility._id) {
          facility.associated_snf_wc_nurse_ids = associatedFacility.associated_snf_wc_nurse_ids;
        }
      }
    }
    this.dataSource.data = this.facilities;
  }

  async initCompanyFacilities(){
    const filter = { assoc_company_ids: this.companyId, is_active: true };
    const projection = {
      'title': 1,
      'is_active': 1,
      'source': 1,
      'assoc_company_ids': 1,
      'provider_ids_ref': 1,
      'template_ids_ref': 1
    }

    let companyAssocPopulation: facilityPopulationInterface = {
      collection: 'companies',
      field: ["assoc_company_ids"],
      filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: [true, { $in: ['$_id', '$$assoc_company_ids'] }] }] } },
      project: { name: 1 },
    };

    let templatePopulation: facilityPopulationInterface = {
      collection: 'templates',
      field: ["template_ids_ref"],
      filter: { $expr: { $and: [{ $eq: ["false", '$is_deleted'] }, { $eq: [{$toObjectId: this.companyId}, '$company_id'] }, 
      { $eq: [true, { $in: [ '$_id', '$$template_ids_ref'] }] }] } },
      project: { created_by_ref: 1, template_type: 1, title: 1, company_id: 1 },
    };

    let systemDefinedtemplatePopulation: facilityPopulationInterface = {
      collection: 'templates',
      field: ["allTemp"],
      filter: { $expr: { $and: [{ $eq: ["false", '$is_deleted'] }, { $eq: [{$toObjectId: this.companyId}, '$company_id'] }, { $eq: ["system_defined", '$template_type'] }] } },
      project: { _id: 1, created_by_ref: 1, template_type: 1, title: 1, facility_id: 1, company_id: 1 },
    };

    const response: any = await this.facilityService.getFacilities(filter, projection, [ companyAssocPopulation, templatePopulation, systemDefinedtemplatePopulation]).toPromise();
    if (response.status === 200) {
      response.data.array = response.data.array.map(ele => {
        ele.allTemp.map(temp => {
          temp.title = temp.title.toUpperCase();
          return temp;
        });
        return ele;
      });
      this.facilities = response.data.array;
    }
  }

  async initFacilityAssociatedSnfWcNurses() {
    const filter = { company_id: this.companyId };
    const nurseProjection = {
      first_name: 1,
      last_name: 1,
      title: 1,
      full_name: 1
    };
    const facilityProjection = {
      title: 1,
      pcc_facId: 1,
      source: 1,
      pcc_2_legged_authentication: 1
    };
    const response: any = await lastValueFrom(this.SnfWcFacilityAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
    if (response.status === 200) {
      response.data.array = response.data;
      this.associatedFacilities = response.data;
    }
  }

  async initSnfWcNurses() {
    const nurseFilter = { company_id: this.companyId, user_type: global.USER_TYPE.SNF_WC_NURSE };
    const projection = { first_name: 1, last_name: 1, title: 1, email: 1, full_name: 1 };
    const response: any = await lastValueFrom(this.userService.getUsers(nurseFilter, projection));
    if (response.status === 200) {
      this.nurses = response.data;
    }
    else
      this.toastr.warning(response.message);
  }
  async onTemplateSelect($event, facility) {
    if($event.isUserInput) {
      let template = $event.source.value;
      const company_id = this.companyId;
      let result = await this.companyService.getCompanyName(company_id).toPromise();
      if (result.status === 200) {
        let companyType = result.data.company_type.toLowerCase();
        if (companyType != 'wound') {
          if (facility.trueRCMID == '' || !facility.trueRCMID) {
            swal("No Unique ID", "Enter unique facility ID under settings!", "warning");
          }
        }
      }
      if(template == 'selectAll') {
        facility.template_ids_ref = facility.allTemp;
        this.onSelectAllTemplate(facility);
        return;
      }
      else if(template == 'unSelect') {
        facility.template_ids_ref = []
        this.onDeSelectAllTemplate(facility);
        return;
      }
      else {
        if(!$event.source._selected) {
          this.onTemplateDeSelect(template, facility);
          return;
        }
        else {
          let providers = facility.provider_ids_ref;
          facility.template_ids_ref = [...facility.template_ids_ref, template];
          let templates = facility.template_ids_ref.map(a => a._id);
          this.updateFacilityProviderTempalteAssoc(facility._id, providers, templates)
        }
      }
      
    }
  }
  onTemplateDeSelect(template, facility) {
    if (template.full_name) {
      let provider = template;
      let providers = facility.provider_ids_ref;
      facility.template_ids_ref = facility.template_ids_ref.filter(temp => template._id !== temp._id)
      let templates = facility.template_ids_ref.map(a => a._id)
      this.updateFacilityProviderTempalteAssoc(facility._id, providers, templates, null, provider._id)
    } else {
      let providers = facility.provider_ids_ref;
      facility.template_ids_ref = facility.template_ids_ref.filter(temp => template._id !== temp._id)
      let templates = facility.template_ids_ref.map(a => a._id)
      this.updateFacilityProviderTempalteAssoc(facility._id, providers, templates, template._id, null)
    }
  }
  onSelectAllTemplate(facility) {
    let isRelationCreating: boolean = true;
    this.updateFacilityTemplates(facility, isRelationCreating)
  }
  onDeSelectAllTemplate(facility) {
    let isRelationCreating: boolean = false;
    this.updateFacilityTemplates(facility, isRelationCreating)
  }
  async updateFacilityProviderTempalteAssoc(facility, providers, templates, template_id?, provider_id?) {
    let uniqueproviders = providers.filter((element, index) => {
      return providers.indexOf(element) === index;
    });
    this.facilityService.updateFacilityProviderTempalteAssoc(facility, uniqueproviders, templates, template_id, provider_id).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.info("Relation Updated")
      }
    })
  }
  updateFacilityTemplates(facility, isRelationCreating) {
    const { allTemp } = facility;
    return this.facilityService.updateFacilityTemplates(facility._id, allTemp.map(p => p._id), isRelationCreating).toPromise();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    let filterFacilities = this.facilities.filter(facility => facility.title.toLowerCase().includes(filterValue.toLowerCase()));
    if (filterValue) this.dataSource.data = filterFacilities;
    else this.dataSource.data = this.facilities;
  }

  async onDataSelection($event) {
    // let providers = element.associated_provider_ids.map(a => a._id);
    // let idx = this.users.findIndex(user => user._id.toString == element._id.toString());
    // this.users[idx] = element;
    // this.dataSource.data = this.users;
    if($event.type == 'provider') {
      if($event.data == 'selectAll') {
        this.onSelectAllNurse($event.element.assos_providers, $event.element);
  
      }
      else if($event.data == 'unSelect') {
        this.onDeSelectAllNurse([],$event.element);
      }
      else {
        if(!$event.status) {
          this.onNurseDeSelect($event.data, $event.element);
        }
        else {
          this.onNurseSelect($event.data, $event.element);
        }
      }
    }
    else {
      const company_id = this.companyId;
      let result = await this.companyService.getCompanyName(company_id).toPromise();
      if (result.status === 200) {
        let companyType = result.data.company_type.toLowerCase();
        if (companyType != 'wound') {
          if ($event.element.trueRCMID == '' || !$event.element.trueRCMID) {
            swal("No Unique ID", "Enter unique facility ID under settings!", "warning");
          }
        }
      }
      if($event.data == 'selectAll') {
        $event.element.template_ids_ref = $event.element.allTemp;
        this.onSelectAllTemplate($event.element);
        return;
      }
      else if($event.data == 'unSelect') {
        $event.element.template_ids_ref = []
        this.onDeSelectAllTemplate($event.element);
        return;
      }
      else {
        if(!$event.status) {
          this.onTemplateDeSelect($event.data, $event.element);
          return;
        }
        else {
          let providers = $event.element.provider_ids_ref;
          // facility.template_ids_ref = [...facility.template_ids_ref, template];
          let templates = $event.element.template_ids_ref.map(a => a._id);
          this.updateFacilityProviderTempalteAssoc($event.element._id, providers, templates)
        }
      }
    }
    
  }

  onDataRemove($event) {
    if($event.type == 'provider') {
      this.onNurseDeSelect($event.data, $event.element);
    }
    else {
      this.onTemplateDeSelect($event.data, $event.element)
    }
    
  }

  onNurseSelect(event, element) {
    this.loader.startLoader(this.loaderId);
    const filter = { facility_id: element._id };
    const projection = { associated_snf_wc_nurse_ids: event._id };
    this.SnfWcFacilityAssociationService.createSnfWcNurseFacilitiesRelation(filter, projection).subscribe((res: any) => {
      if (res.status == 200) {
        const index = this.facilities.findIndex(facility => facility._id === element._id);
        this.facilities[index].associated_snf_wc_nurse_ids = [...this.facilities[index].associated_snf_wc_nurse_ids];
        this.dataSource.data = [];
        this.dataSource.data = this.facilities;
        this.toastr.success("Snf Wc Nurse Added Successfully");
        this.loader.stopLoader(this.loaderId);
      }
      else {
        this.toastr.warning(res.message);
        this.loader.stopLoader(this.loaderId);
      }
    });
  }

  onSelectAllNurse(event, element) {
    this.loader.startLoader(this.loaderId);
    const filter = { facility_id: element._id };
    for (let eventItem of event) {
      let projection = { associated_snf_wc_nurse_ids: eventItem._id };
      this.SnfWcFacilityAssociationService.createSnfWcNurseFacilitiesRelation(filter, projection).subscribe((res: any) => {
        if (res.status == 200) {
          this.toastr.success("Snf Wc Nurses Added Successfully");
          this.loader.stopLoader(this.loaderId);
        } else {
          this.toastr.warning(res.message);
          this.loader.stopLoader(this.loaderId);
        }
      })
    }
  }

  onNurseDeSelect(event, element) {
    this.loader.startLoader(this.loaderId);
    const filter = { facility_id: element._id };
    const projection = { associated_snf_wc_nurse_ids: event._id };
    this.SnfWcFacilityAssociationService.deleteSnfWcNurseFacilitiesRelation(filter, projection).subscribe((res: any) => {
      if (res.status == 200) {
        const index = this.facilities.findIndex(facility => facility._id === element._id);
        element.associated_snf_wc_nurse_ids= element.associated_snf_wc_nurse_ids
        .filter(associated_snf_wc_nurse => associated_snf_wc_nurse._id !== event._id)
        // this.facilities[index].associated_snf_wc_nurse_ids = this.facilities[index].associated_snf_wc_nurse_ids
        //   .filter(associated_snf_wc_nurse => associated_snf_wc_nurse._id !== event._id);
        this.dataSource.data = [];
        this.dataSource.data = this.facilities;
        this.toastr.success("Snf Wc Nurse Removed Successfully");
        this.loader.stopLoader(this.loaderId);
      }
      else {
        this.toastr.warning(res.message);
        this.loader.stopLoader(this.loaderId);
      }
    });
  }

  onDeSelectAllNurse(event, element) {
    this.loader.startLoader(this.loaderId);
    const filter = { facility_id: element._id };
    this.SnfWcFacilityAssociationService.deleteSnfWcNurseFacilitiesRelation(filter).subscribe((res: any) => {
      if (res.status == 200) {
        this.toastr.success("Snf Wc Nurses Removed Successfully");
        this.loader.stopLoader(this.loaderId);
      }
      else {
        this.toastr.warning(res.message);
        this.loader.stopLoader(this.loaderId);
      }
    });
  }

  compareProviders(provider1: any, provider2: any): boolean {
    return provider1 && provider2 ? provider1._id === provider2._id : provider1 === provider2;
  }

  compareTemplates(template1: any, template2: any): boolean {
    return template1 && template2 ? template1._id === template2._id : template1 === template2;
  }
}
