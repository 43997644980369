import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MaterialModule } from 'src/app/components/material.module';
import { CensusPatientListService } from 'src/app/includes/census-patient-list/census-patient-list.service';
import { DialogConfirmationComponent } from 'src/app/includes/census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { PatientRoundingSheetService } from 'src/app/includes/patient-rounding-sheets/patient-rounding-sheet.service';
import { AuthService } from 'src/app/services/auth.service';
import { ModifierService } from 'src/app/services/modifier.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { NoteAddChargeService } from '../../../note-add-charge.service';
import { ChargeAction } from '../../note-add-charge.component';
import { ViewFavouriteCptsDialogComponent } from 'src/app/includes/note-sidebar/view-favourite-cpts-dialog/view-favourite-cpts-dialog.component';
import { lastValueFrom } from 'rxjs';
import swal from 'sweetalert';
import * as global from '../../../../../global';
import { NoteCommentsService } from '../../../../note-comments/note-comments.service';
import _ from 'lodash';

@Component({
  selector: 'app-note-sidebar-cpts',
  templateUrl: './note-sidebar-cpts.component.html',
  styleUrls: ['./note-sidebar-cpts.component.css']
})
export class NoteSidebarCptsComponent implements OnInit, OnChanges {
  loaderId = 'loader-02';
  loaderId2 = 'loader-04';
  // @ViewChild('tabGroup') tabGroup;
  // @Input() note: any; 
  displayedColumns = ['fvrt', 'code', 'checkbox']
  selectedDisplayedColumns = ['code', 'checkbox']
  @Input() charge: any;
  @Input() note: any;
  @Input() tabsToBeDisplayed;
  @Input() currentCompany;
  @Input() noteEditorCpts: any[] = [];
  @Input() emCodeEditorCpts: any[] = [];
  @Input() telemedPhrase: any = false;
  @Input() icd_id: any;
  @Input() questionnaireResults: any;
  @Input() hideMipsReportMeasure: any;
  @Input() company: any;
  @Output() cptsChange = new EventEmitter<any>();
  @Output() modifiersChange = new EventEmitter<any>();
  @Output() posChange = new EventEmitter<any>();
  @Output() mipReportMeasure = new EventEmitter<Boolean>();
  @Output() removePhrasesfromHtmlText = new EventEmitter<any>();
  @Output() handleNoteEditorCpts = new EventEmitter<any>();
  global = global;
  fvrtCPTs = [];
  allCPTs: any = [];
  companyCPTs: any = [];
  reserved_cpts: any = [];
  selectedCharges: any = []
  searchCPT: Boolean = false;
  chargeFilter = '';
  cpt_modifiers_key: any;
  public paginationChargesAll: {
    pageNo: number,
    hasNextPage: boolean,
    hasPrevPage: boolean,
    totalCount: number,
    totalPages: number
  };
  
  // public paginationChargesCompany: {
  //   pageNo: number,
  //   hasNextPage: boolean,
  //   hasPrevPage: boolean,
  //   totalCount: number,
  //   totalPages: number
  // };
  activeTab = '';
  paginationChargesCompany = {
    pageNo : 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalCount: 0,
    totalPages: 0
  };
  model = {
    owner_id: '',
    patient_id: '',
    rounding_sheet_id: '',
    cpt_id: [],
    cpt_modifiers: [],
    cpt_pos: [],
    billing_note: '',
    note: '',
    status: '',
    visit_date: '',
    facility_id: '',
    measure: false
  };
  searchFilter = {
    cpt: '',
  };
  fvrtCPTTab = true;
  allCPTTab = false;
  companyCPTTab = false;
  fvrtCPTTabData = false;
  allCPTTabData = false;
  companyCPTTabData: boolean = false;
  loading: Boolean = true
  starter: Boolean = true;
  dataSourceAllCPTs: any = new MatTableDataSource([]);
  dataSourceCompanyCPTs: any = new MatTableDataSource([]);
  dataSourceFvrtCPTs: any = new MatTableDataSource([]);
  modifiers: {
    code: string,
    description: string
  }[];
  undo: ChargeAction[] = [];
  redo: ChargeAction[] = [];
  allcptsLoaded: boolean;
  loggedInUser: any;
  woundCompany = false;
  isMipReportEnabled: boolean = false;
  currentUser: any;
  recentMips: any;
  cpt_modifiers_wound: any;
  relations: any;
  initialEvalCodes = ['99304','99305','99306','99221','99222','99223','99201','99202','99203','99204','99205']
  initialFollowUpCodes = ['99308','99309','99310','99231','99232','99233','99211','99212','99213','99214','99215']
  constructor(
    private noteCommentsService: NoteCommentsService,
    private _addChargeService: NoteAddChargeService,
    private service: PatientRoundingSheetService,
    private modifierService: ModifierService,
    private loader: NgxUiLoaderService,
    private censusPatientListService: CensusPatientListService,
    public _authService: AuthService,
    private dialog: MatDialog,
    private _toaster: ToastrService,
    private companyService: CompanyService,
  ) {
    this.paginationChargesAll = {
      pageNo: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalCount: 0,
      totalPages: 0
    };
    this.loggedInUser = this._authService.currentUser;
    if(this.loggedInUser.company_type.toLowerCase().includes("wound")){
      this.woundCompany = true;
    }
this.modifiers = this.modifierService.getModifiers();

  }
  removePreviousProcedureCPTs(currentCPTs, removeWithoutCheck = false) {
    return new Promise<void>((resolve, reject) => {
      if ((currentCPTs.length > 0 && currentCPTs[currentCPTs.length - 1].updated) || removeWithoutCheck) {
        const prevSelectedCPTs = this.model.cpt_modifiers.filter((cpt) => cpt.key && cpt.key.some(key => key.includes("calculatedCPT"))).map((mod) => mod.cpt);
        prevSelectedCPTs.forEach((prevCPT) => {
          const selectedIndex = this.selectedCharges.findIndex((selectedCPT) => selectedCPT._id.toString() === prevCPT.toString());
          if (selectedIndex > -1) {
            this.removeElementFromlist(this.selectedCharges[selectedIndex], selectedIndex)
          }
        });
      }
      resolve();
    });
  }
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if(changes.noteEditorCpts && changes.noteEditorCpts.currentValue.length > 0 && changes.noteEditorCpts.currentValue[0].updated && this.selectedCharges.length > 0 && 
      (JSON.stringify(changes.noteEditorCpts.currentValue) !== JSON.stringify(changes.noteEditorCpts.previousValue))) {
      await this.removePreviousProcedureCPTs(changes.noteEditorCpts.currentValue);
    }
    for (let i = 0; i < this.noteEditorCpts.length; i++) {
      const _cpt = this.noteEditorCpts[i];
      const exists = this.selectedCharges.find(cpt => cpt._id == _cpt._id);
      let unit = parseInt(_cpt.unit);
      if (isNaN(unit)) unit = 1;
      if (!exists) {
        await this.addToModel(_cpt, null, 'cpt');
        this.handleModifierUnitChange(_cpt, unit);
      }
      else {
        if (_cpt.key) {
          this.replaceInModel(_cpt, null, 'cpt');
          this.handleModifierUnitChange(_cpt, unit);
        } else {
          const modifier = this.model.cpt_modifiers.find(mod => mod.cpt == _cpt._id);
          if (modifier) {
            this.handleModifierUnitChange(_cpt, modifier.unit);
          }
        }
      }
    }
    if(changes.telemedPhrase != undefined){
      if(changes.telemedPhrase.currentValue == true || changes.telemedPhrase.previousValue == true){
        const chargesStartWith99 = this.selectedCharges.filter(cpt => cpt.code.startsWith('99'));
        for (let cpt of chargesStartWith99) {
          this.handleModifierChange(cpt, '95');
        }
      }
    }
    if (changes.hideMipsReportMeasure) {
      this.hideMipsReportMeasure = changes.hideMipsReportMeasure.currentValue
    }
    if (this.selectedCharges && this.relations) {
      this.selectedCharges.forEach(charge => {
        charge.pos_ids = [];
        this.relations.forEach(relation => {
          if (charge._id == relation.cpt_id) {
            charge.selected_pos = relation.default_pos.code.toString();
            charge.pos_ids.push(relation.default_pos);
            const cptAssociatedPOS = relation.pos_ids;
            cptAssociatedPOS.forEach(pos => {
              if (pos.code != relation.default_pos.code) charge.pos_ids.push(pos);
            })
            const cpt_pos = this.model.cpt_pos?.find(cptPOS => cptPOS.cpt_id === charge._id);
            if (cpt_pos) charge.selected_pos = cpt_pos.pos;
          }
        });
        this.handlePOSChange(charge, charge.selected_pos);
      });
    }
  }
  async ngOnInit() {
    this.currentUser = this._authService.currentUser;
    this.check_conditions()
    this.tabChange(this.tabsToBeDisplayed[0])
    this.getrecentMip();
    this.undo = [];
    this.redo = [];
    await this.initRelations();
    if (this.charge && Array.isArray(this.charge.cpt_id)) {
      if (this.noteEditorCpts.length > 0) {
        for (let i = 0; i < this.noteEditorCpts.length; i++) {
          const _cpt = this.noteEditorCpts[i];
          const exists = this.selectedCharges.find(cpt => cpt._id == _cpt._id);

          if (!exists) {
            this.addToModel(_cpt, null, 'cpt');
          }
        }
      }
      else {
        this.charge.cpt_id.map(cpt => {
          this.addToModel(cpt, null, 'cpt')
        })
      }
    }
    if (this.charge && this.charge.cpt_modifiers) {
      this.model.cpt_modifiers = this.charge.cpt_modifiers;
      this.model.measure = this.charge.measure || this.model.measure;
      this.changeReportMeasure(this.model.measure)
    }
    if (this.charge && this.charge.cpt_pos) {
      this.model.cpt_pos = this.charge.cpt_pos;
    }
    await this.getFvrtCPTs();
    // await this.getAllCPTs();
    this.allcptsLoaded = true;
    this._addChargeService.setisCPT(true);
    this.isMipReportEnabled = this.company.enable_company_mips;
    this.model.measure = this.isMipReportEnabled;
    this.mipReportMeasure.emit(this.isMipReportEnabled);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedCharges, event.previousIndex, event.currentIndex);
    // this.censusPatientListService.setCPTsForCharge(this.selectedCharges);
    this.model.cpt_id = this.selectedCharges.map(cpt => cpt._id);
    this.cptsChange.emit(this.selectedCharges)
  }
  async initRelations() {
    if (this.charge && this.charge.cpt_pos) {
      this.model.cpt_pos = this.charge.cpt_pos;
    }
    this.companyService.getCPTPOSRelations({ company_id: this.currentUser.company_id }).subscribe((res: any) => {
      if (res.status == 200) {
        this.relations = res.data;
        this.selectedCharges.forEach(charge => {
          charge.pos_ids = [];
          this.relations.forEach(relation => {
            if (charge._id == relation.cpt_id) {
              charge.selected_pos = relation.default_pos.code.toString();
              charge.pos_ids.push(relation.default_pos);
              const cptAssociatedPOS = relation.pos_ids;
              cptAssociatedPOS.forEach(pos => {
                if (pos.code != relation.default_pos.code) charge.pos_ids.push(pos);
              })
              const cpt_pos = this.model.cpt_pos?.find(cptPOS => cptPOS.cpt_id === charge._id);
              if (cpt_pos) charge.selected_pos = cpt_pos.pos;
            }
          });
          this.handlePOSChange(charge, charge.selected_pos);
        });
        this.loader.stopLoader(this.loaderId);
      } else {
        console.log('something went wrong');
      }
    });
  }
  async getIEFUCodesMapping(){
    let response:any = await this.companyService.getIEFUCodesMapping(this.currentUser.company_id,true).toPromise();
    if(response.status == 200 && response.data){
      this.initialEvalCodes = response.data.ie_codes.map((cpt)=> cpt.code);
      this.initialFollowUpCodes = response.data.fu_codes.map((cpt)=> cpt.code);
    }
  }
  drop_(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.tabsToBeDisplayed, event.previousIndex, event.currentIndex);
    this.noteCommentsService.updateTabsOrder(this.tabsToBeDisplayed, 'charges').subscribe((Response: any) => {
      if (Response.status == 200) {
        this._toaster.success("Setting Updated")
      }
      else {
        this._toaster.error("Something went wrong while updating setting.")
      }
    });
    this.check_conditions()
  }
  tabChange(tab) {
    this.activeTab = tab;
    this.onTabClickCharges(tab)
  }

  async getFvrtCPTs() {
    this.fvrtCPTTabData = false;
    let res: any = await this.service.getFavoriteCPTs().toPromise()
        // @ts-ignore
    this.fvrtCPTs = res.data.array;
    // @ts-ignore 
    this.reserved_cpts = res.data.array;
    //marking checkboxes true for the cpts of favrts table based on selected CPTs
    for (let k = 0; k < this.selectedCharges.length; k++) {
      for (let i = 0; i < this.fvrtCPTs.length; i++) {
        if (this.fvrtCPTs[i]._id == this.selectedCharges[k]._id) {
          this.fvrtCPTs[i].selected = true;
        }
      }
    }
    this.dataSourceFvrtCPTs.data = this.fvrtCPTs;
    // this.dataSourceFvrtCPTs = new MatTableDataSource(this.fvrtCPTs);
    this.fvrtCPTTabData = true;
  }

  async getAllCPTs(page = 1, search = "", pushinprev = false) {
    this.allCPTTabData = false;
    let response: any = await this.service.getCPTs({ page: page, search: search }).toPromise()
    if (response.status == 200) {
      response.data.array = await this.service.addCptExpiryStatus(response.data.array);
      if (search.trim() == '') {
if(page == 1){
          this.allCPTs = response.data.array;
        }else{
        this.allCPTs.push(...response.data.array);
}
      }
      else {
if(pushinprev){
          this.allCPTs.push(...response.data.array);
        }else{
        this.allCPTs = response.data.array;
}
      }
      //setting fvrts flag true
      for (let i = 0; i < this.allCPTs.length; i++) {
        for (let j = 0; j < this.fvrtCPTs.length; j++) {
          if (this.allCPTs[i]._id.toString() == this.fvrtCPTs[j]._id.toString()) {
            this.allCPTs[i].fvrt = true;
          }
        }
        //setting selected flag true for all cpts table
        for (let k = 0; k < this.selectedCharges.length; k++) {
          if (this.allCPTs[i]._id == this.selectedCharges[k]._id) {
            this.allCPTs[i].selected = true;
          }
        }
      }
      const totalCount = response.data.totalCount;
      this.paginationChargesAll.totalCount = totalCount;
      this.paginationChargesAll.pageNo = page;
      this.initPaginationChargesAll();
      this.dataSourceAllCPTs.data = this.allCPTs;
      this.allCPTTabData = true;
    }
  }

  async getAllCPTsCompany(page = 1, search = "") {
    this.companyCPTTabData = false;
    let response: any = await this.service.getCompanyCPTs({ companyId: this.currentUser.company_id ,page: page, search: search }).toPromise()
    if (response.status == 200) {
      if (search.trim() == '') {
          this.companyCPTs.push(...response.data.array);        
      }
      else {
        this.companyCPTs = response.data.array;
      }
      //setting fvrts flag true
      for (let i = 0; i < this.companyCPTs.length; i++) {
        for (let j = 0; j < this.fvrtCPTs.length; j++) {
          if (this.companyCPTs[i]._id.toString() == this.fvrtCPTs[j]._id.toString()) {
            this.companyCPTs[i].fvrt = true;
          }
        }
        //setting selected flag true for all cpts table
        for (let k = 0; k < this.selectedCharges.length; k++) {
          if (this.companyCPTs[i]._id == this.selectedCharges[k]._id) {
            this.companyCPTs[i].selected = true;
          }
        }
      }
      const totalCount = response.data.totalCount;
      this.paginationChargesCompany.totalCount = totalCount;
      this.paginationChargesCompany.pageNo = page;
      await this.initPaginationChargesCompany();
      this.dataSourceCompanyCPTs.data = this.companyCPTs;
      this.companyCPTTabData = true;
    }
  }
  getCompany() {
    const response: any = lastValueFrom(this.companyService.getCompany(
      {
        _id: this.loggedInUser.company_id
      },
      {
        isMipReportEnabled: 1,
        name: 1,
        portal_type: 1,
        company_type: 1,
      }
      ));
    if (response.status == 200) {
      this.company = response.data;
      this.isMipReportEnabled = response.data.enable_company_mips;
      this.model.measure = this.isMipReportEnabled;
      this.mipReportMeasure.emit(this.isMipReportEnabled);
    }
  }

  initPaginationChargesAll() {
    this.paginationChargesAll.totalPages = Math.ceil(this.paginationChargesAll.totalCount / 10);
    if (this.allCPTs) {
      this.paginationChargesAll.hasNextPage = this.allCPTs.length > 0 && this.paginationChargesAll.pageNo < this.paginationChargesAll.totalPages;
    }
    this.paginationChargesAll.hasPrevPage = this.paginationChargesAll.pageNo > 1;
  }

  async initPaginationChargesCompany() {
    this.paginationChargesCompany.totalPages = Math.ceil(this.paginationChargesCompany.totalCount / 10);
    if (this.companyCPTs) {
      this.paginationChargesCompany.hasNextPage = this.companyCPTs.length > 0 && this.paginationChargesCompany.pageNo < this.paginationChargesCompany.totalPages;
    }
    this.paginationChargesCompany.hasPrevPage = this.paginationChargesCompany.pageNo > 1;
  }

  removeCharge(charge, $event?) {
      if (this.isElementProcedureType(charge)) {
        if (this.note.is_signed === "true") {
          this._toaster.info("This code is associated with a procedure. You cannot remove it because note is signed", "Info");
        } else {
          let dialogRef = this.dialog.open(DialogConfirmationComponent, {
            data: {
              message: "This code is associated with a procedure.<br>Do you want to remove it?"
            }
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result === "true") {
              let cpt_modifiers_index = this.model.cpt_modifiers.findIndex(item => item.cpt === charge._id);
              if (cpt_modifiers_index > -1) {
                this.cpt_modifiers_key = this.model.cpt_modifiers[cpt_modifiers_index].key;
                if(this.model.cpt_modifiers[cpt_modifiers_index].wound_id){
                  this.cpt_modifiers_wound = this.model.cpt_modifiers[cpt_modifiers_index].wound_id;
                }
                this.removeKeyElementsfromList(this.cpt_modifiers_key);
              }
            }
          });
        }
      } else {
        let index = this.selectedCharges.findIndex(icd => icd._id === charge._id);
        if (index > -1) {
          this.selectedCharges.splice(index, 1)
          this.model.cpt_id.splice(index, 1)
          let cpt_modifiers_index = this.model.cpt_modifiers.findIndex(item => item.cpt === charge._id);
          if (cpt_modifiers_index > -1) {
            this.model.cpt_modifiers = this.model.cpt_modifiers.filter(mod => mod.cpt != charge._id)
            this.modifiersChange.emit(this.model.cpt_modifiers);
          }
          let cptPOS_index = this.model.cpt_pos.findIndex(item => item.cpt_id === charge._id);
          if (cptPOS_index > -1) {
            this.model.cpt_pos = this.model.cpt_pos.filter(item => item.cpt_id != charge._id);
            this.posChange.emit(this.model.cpt_pos);
          }
        }

        let noteEditorCpts_index = this.noteEditorCpts.findIndex(item => item._id === charge._id);
        if (noteEditorCpts_index > -1) {
          this.noteEditorCpts.splice(noteEditorCpts_index, 1)
        }

        this.allCPTs.map(a => {
          if (a._id == charge._id) {
            a.selected = false;
          }
        })
        this.dataSourceAllCPTs.data = this.allCPTs;
        this.fvrtCPTs.map(a => {
          if (a._id == charge._id) {
            a.selected = false;
          }
        });
        if (this.allcptsLoaded) {
          if (this.undo.length == 4) {
            this.undo.shift();
          }
          this.undo.push({
            action: "remove",
            data: charge
          });
        }
        this.dataSourceFvrtCPTs.data = this.fvrtCPTs;
        this.dataSourceAllCPTs.data = this.allCPTs;
        this.cptsChange.emit(this.selectedCharges);
      }
    return false;
  }

  getCptModifier(cpt) {
    const cpt_modifier = this.model.cpt_modifiers?.find(cpt_modifier => cpt_modifier.cpt === cpt._id);
    if (cpt_modifier) {
      return cpt_modifier.modifier
    }
  }
  getCptPOS(cpt) {
    const cpt_pos = this.model.cpt_pos?.find(cptPOS => cptPOS.cpt_id === cpt._id);
    if (cpt_pos) {
      return cpt_pos.pos
    }
  }
  getCptModifierUnit(cpt) {
    const cpt_modifier = this.model.cpt_modifiers?.find(cpt_modifier => cpt_modifier.cpt === cpt._id);
    if (cpt_modifier) {
      return cpt_modifier.unit
    } else {
      if (cpt.default_unit) {
        this.handleModifierUnitChange(cpt, cpt.default_unit);
        return cpt.default_unit;
      } else {
        return "1";
      }
    }
  }
  handleModifierChange(cpt, modifier) {
    const cpt_modifier = this.model.cpt_modifiers.find(cpt_modifier => cpt_modifier.cpt === cpt._id);
    if (cpt_modifier) {
      cpt_modifier.modifier = modifier
    } else {
      this.model.cpt_modifiers.push({
        cpt: cpt._id,
        modifier: modifier
      });
    }
    this.modifiersChange.emit(this.model.cpt_modifiers);
  }
  handlePOSChange(cpt, posCode) {
    const cpt_pos = this.model.cpt_pos.find(cptPOS => cptPOS.cpt_id === cpt._id);
    if (cpt_pos) {
      cpt_pos.pos = posCode
    } else {
      this.model.cpt_pos.push({
        cpt_id: cpt._id,
        pos: posCode
      });
    }
    this.posChange.emit(this.model.cpt_pos);
  }
  handleModifierUnitChange(cpt, unit) {
    let res = unit;
    if (unit === '') {
      res = "1";
    }
    const cpt_modifier = this.model.cpt_modifiers.find(cpt_modifier => cpt_modifier.cpt === cpt._id);
    let index = this.model.cpt_modifiers.findIndex(cpt_mod => cpt_mod.cpt.toString() === cpt._id.toString());
    if (cpt_modifier) {
      cpt_modifier.unit = res;
      cpt_modifier.wound_id = cpt.wound_id
      if (cpt.key != undefined) {
        if (!cpt_modifier.key.find(ky => ky === cpt.key)) {
          cpt_modifier.key.push(cpt.key);
        }
      }
      this.model.cpt_modifiers[index] = cpt_modifier
    } else {
      if (cpt.key) {
        this.model.cpt_modifiers = JSON.parse(JSON.stringify([...this.model.cpt_modifiers, {
          cpt: cpt._id,
          unit: res,
          key: [cpt.key],
          wound_id: cpt.wound_id
        }]));
      } else {
        this.model.cpt_modifiers = JSON.parse(JSON.stringify([...this.model.cpt_modifiers, { cpt: cpt._id, unit: res }]));
      }
    }
    this.modifiersChange.emit(this.model.cpt_modifiers);
  }
  getrecentMip(){
    const details = {
      patient_id: this.note.patient._id
    }
    this.service.getrecentMip(details).subscribe((res:any)=>{
        this.recentMips=res.data;
    })
  }
  mipsInThisYear(){
    if(this.recentMips){
      let year=(new Date()).getFullYear();
      let mipYear=(new Date(this.recentMips.createdAt)).getFullYear();
      if(mipYear==year) return true;
      else return false;
    }
    return false;
  }
  searchCharge(value) {
    this.chargeFilter = value;
    this.searchFilter.cpt = value?.trim()?.toLowerCase();
    if (this.allCPTTab) {
      this.getAllCPTs(1, value)
    }
    if (this.companyCPTTab) {
      this.getAllCPTsCompany(1, value)
    }
    else if (this.fvrtCPTTab) {
      this.applyFilterCPT(this.searchFilter.cpt)
    }
  }
  applyFilterCPT(value) {
    if (value) {
      let filteredData = [];
      this.reserved_cpts.map(a => {
        if (a.code.trim().toLowerCase().includes(value) || a.description.trim().toLowerCase().includes(value)) {
          filteredData.push(a)
        }
      })
      this.dataSourceFvrtCPTs.data = filteredData;
    }
    else {
      this.dataSourceFvrtCPTs.data = this.reserved_cpts;
    }
  }
  onTabClickCharges(tab) {
    this.starter = false;
    if (tab == 'All') {
      this.searchCPT = true;
      this.allCPTTab = true;
      this.fvrtCPTTab = false;
      this.allCPTs = [];
    }
    else {
      this.searchCPT = false;
      this.fvrtCPTTab = true;
      this.allCPTTab = false;
    }
    if (tab == 'Company') {
      this.searchCPT = true;
      this.companyCPTTab = true;
      this.fvrtCPTTab = false;
      this.companyCPTs = [];
    }
    else {
      this.searchCPT = false;
      this.fvrtCPTTab = true;
      this.companyCPTTab = false;
    }
    this.clearSearchField();
    this.searchCharge(this.chargeFilter)
  }
  onListDropfvrtCPTs(event: CdkDragDrop<any[]>) {
    let filter = 'cpt_id';
    moveItemInArray(this.fvrtCPTs, event.previousIndex, event.currentIndex);
    this.dataSourceFvrtCPTs.data = this.fvrtCPTs;
    this.service.rearrangeFavoriteCharges(this.fvrtCPTs.map(c => c._id), filter)
      .subscribe((response: any) => {
      })
  }
  clearSearchField() {
    this.chargeFilter = '';
  }
  check_conditions(){
    if(!this.woundCompany){
      this.tabsToBeDisplayed = this.tabsToBeDisplayed.filter(r => r !== 'HP E&M Codes' && r !== 'Procedure Codes');
    }
  }
  replaceInModel(element, event, type) {
    if (type == 'cpt') {
      let index = this.selectedCharges.findIndex((cpt) => cpt._id === element._id);
      element.selected = true;
      this.selectedCharges[index] = element;
      this.model.cpt_id = this.selectedCharges.map(cpt => cpt._id);
      this.cptsChange.emit(this.selectedCharges)
    }
  }
  isKeyExistsinArray(modifierArray, keyArray) {
    for (let i = 0; i < modifierArray?.length; i++) {
      if (keyArray.find(ky => ky.toString() === modifierArray[i].toString())) {
        if(modifierArray?.length > keyArray?.length){
          this.cpt_modifiers_key = modifierArray;
        }
        return true;
      }
    }
    return false;
  }
  removeKeyElementsfromList(key) {
    const moodifiers = _.cloneDeep(this.model.cpt_modifiers);
    const filterObj = _.cloneDeep([...(this.selectedCharges.filter(charg => moodifiers.findIndex(mod => mod.cpt === charg._id && this.isKeyExistsinArray(mod.key, key)) > -1))]);
    filterObj.forEach((obj) => {
      let index = this.selectedCharges.findIndex(cpt => cpt._id === obj._id);
      this.removeElementFromlist(obj, index);
    });
    this.emitTriggerValues();
    let detail = {
      key: this.cpt_modifiers_key,
      wound_id: this.cpt_modifiers_wound
    }
    this.removePhrasesfromHtmlText.emit(detail)
  }
  removeElementFromlist(element, index) {
    let cpt_modifiers_index = this.model.cpt_modifiers.findIndex(item => item.cpt === element._id);
    if (cpt_modifiers_index > -1) {
      this.model.cpt_modifiers = this.model.cpt_modifiers.filter(mod => mod.cpt != element._id)
      this.modifiersChange.emit(this.model.cpt_modifiers);
    }
    if (this.allcptsLoaded) {
      this.undo.push({
        action: "remove",
        data: element
      });
    }
    element.selected = false;
    this.selectedCharges.splice(index, 1)
    this.allCPTs.map(d => {
      if (d._id == element._id) {
        d.selected = false;
      }
    })
    this.fvrtCPTs.map(d => {
      if (d._id == element._id) {
        d.selected = false;
      }
    });
  }
  addElementinList(element) {
    if (this.allcptsLoaded) {
      this.undo.push({
        action: "add",
        data: element
      });
    }
    element.selected = true;
    this.selectedCharges.push(element)
    this.allCPTs.map(d => {
      if (d._id == element._id) {
        d.selected = true;
      }
    })
    this.fvrtCPTs.map(d => {
      if (d._id == element._id) {
        d.selected = true;
      }
    });
  }
  emitTriggerValues() {
    this.model.cpt_id = this.selectedCharges.map(cpt => cpt._id);
    this.dataSourceFvrtCPTs.data = this.fvrtCPTs;
    this.cptsChange.emit(this.selectedCharges);
  }
  addToModel(element, event, type) {
    this.clearSearchField()
    if (type == 'cpt') {
      if (this.undo.length == 4) {
        this.undo.shift();
      }
      if (element.selected) {
        let index = this.selectedCharges.findIndex(cpt => cpt._id === element._id);
        if (index > -1) {
          if (this.isElementProcedureType(element)) {
            if (this.note.is_signed === "true") {
              this._toaster.info("This code is associated with a procedure. You cannot remove it because note is signed", "Info");
            } else {
              let dialogRef = this.dialog.open(DialogConfirmationComponent, {
                data: {
                  message: "This code is associated with a procedure.<br>Do you want to remove it?"
                }
              });
              dialogRef.afterClosed().toPromise().then((result) => {
                if (result === "true") {
                    let cpt_modifiers_index = this.model.cpt_modifiers.findIndex(item => item.cpt === element._id);
                    if (cpt_modifiers_index > -1) {
                      this.cpt_modifiers_key = this.model.cpt_modifiers[cpt_modifiers_index].key;
                      if(this.model.cpt_modifiers[cpt_modifiers_index].wound_id){
                        this.cpt_modifiers_wound = this.cpt_modifiers_key = this.model.cpt_modifiers[cpt_modifiers_index].wound_id;
                      }
                      this.removeKeyElementsfromList(this.cpt_modifiers_key);
                    }
                }
              });
            }
          } else {
            // if(this.selectedCharges.length > 1){
              this.removeElementFromlist(element, index);
              this.emitTriggerValues();
          }
        }
      }
      else {
        // let todayDate = new Date();
        // let previousDate = new Date("28/11/2022");
        // if((this.initialEvalCodes.find(c => c === element.code) || this.initialFollowUpCodes.find(c => c === element.code))){
          //   let filterInitalCode = this.initialEvalCodes.filter(c=> c != element.code);
          //   let filterFollowCode = this.initialFollowUpCodes.filter(c=> c != element.code);
          //   let initialEvalFound = false;
          //   let initialFollowFound = false;
          //   let isInitialCode = false;
          //   let isFollowCode = false;
          //   if(this.initialEvalCodes.includes(element.code)){
            //     isInitialCode = true
          //   }
        //   if(this.initialFollowUpCodes.includes(element.code)){
            //     isFollowCode = true
          //   }
        //   this.selectedCharges.forEach((selectedCharge)=>{
            //     if(filterInitalCode.includes(selectedCharge.code)){
              //       initialEvalFound = true;
            //     }
        //     if(filterFollowCode.includes(selectedCharge.code)){
              //       initialFollowFound = true;
            //     }
        //   });
          //   if(!initialEvalFound && isInitialCode){
            //     if(!initialFollowFound){
//       this.addElementinList(element);
        //       this.emitTriggerValues();
        //     }else{
        //       this._toaster.error("There can never be an IE code with a FU code","Error");
        //     }
        //   }
        //   if(!initialFollowFound  && isFollowCode){
        //     if(initialEvalFound){
        //       this._toaster.error("There can never be an IE code with a FU code","Error");
        //     }else{
        //       this.addElementinList(element);
        //       this.emitTriggerValues();
        //     }
        //   }
        // }else{
              this.addElementinList(element);
              this.emitTriggerValues();
            // }
      }
    }
  }
  removeFavouriteCharges(charge) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
          message: "Are you sure you want to remove this from the favourites?"
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === "true") {
        let noteEditorCptsIndex = this.noteEditorCpts.findIndex(icd => icd._id === charge._id);
        if (noteEditorCptsIndex > -1) {
          this.noteEditorCpts.splice(noteEditorCptsIndex, 1)
        }
        let index = -1;
        for (let i = 0; i < this.fvrtCPTs.length; i++) {
          if (charge._id == this.fvrtCPTs[i]._id) {
            index = i;
          }
        }
        if (index > -1) {
          this.fvrtCPTs.splice(index, 1)
          this.dataSourceFvrtCPTs = new MatTableDataSource(this.fvrtCPTs);
        }
        this.allCPTs.map(char => {
          if (char._id == charge._id) {
            char.fvrt = false;
            this.dataSourceAllCPTs.data = this.allCPTs
          }
        })

        let data = {
          ids: charge._id,
          isRemove: true,
          filter: 'cpt_id'
        };
        this.service.setFavoriteCPTs(data).subscribe((response: any) => {
          if (response.status == 200) {
            // this.toastr.success('Charge removed from favorites', 'Success');
          }
        })
      }
    });
  }
  addFavouriteCharges(charge) {
    this.loader.startLoader('loader-02');
    let data = {
      ids: charge._id,
      isRemove: false,
      filter: 'cpt_id'
    };
    let cpts = this.service.setFavoriteCPTs(data).subscribe((response: any) => {
      if (response.status == 200) {
        this.fvrtCPTs.push(charge)
        this.allCPTs.map(char => {
          if (char._id == charge._id) {
            char.fvrt = true;
            this.dataSourceAllCPTs.data = this.allCPTs
          }
        })
        this.dataSourceFvrtCPTs = new MatTableDataSource(this.fvrtCPTs);
        this.loader.stopLoader('loader-02');
      }
    })
  }
  getCountofCodes() {
    // return ((this.paginationChargesAll.pageNo * 10) - 9) + ' - ' + (((this.paginationChargesAll.pageNo * 10) - 10) + Number(this.allCharges.length)) + " out of " + (this.paginationChargesAll.totalCount);
    // return '1 - ' + (Number(this.allCPTs.length)) + " out of " + (this.paginationChargesAll.totalCount);
    return ( this.allCPTs.length > 0 ? (this.paginationChargesAll.pageNo * 10) - 9 : 0 ) + ' - ' + (((this.paginationChargesAll.pageNo * 10) - 10) + (this.allCPTs.length)) + " out of " + (this.paginationChargesAll.totalCount);

  }
  getCountofCompanyCodes() {
    // return ((this.paginationChargesAll.pageNo * 10) - 9) + ' - ' + (((this.paginationChargesAll.pageNo * 10) - 10) + Number(this.allCharges.length)) + " out of " + (this.paginationChargesAll.totalCount);
    return '1 - ' + (Number(this.companyCPTs.length)) + " out of " + (this.paginationChargesCompany.totalCount);
  }
  handlePrevClick() {
    this.getAllCPTs(this.paginationChargesAll.pageNo - 1, this.searchFilter.cpt);
  }
  handleNextClick() {
    this.getAllCPTs(this.paginationChargesAll.pageNo + 1, this.searchFilter.cpt, true);
  }
  handlePrevClickCompany() {
    this.getAllCPTsCompany(this.paginationChargesAll.pageNo - 1, this.searchFilter.cpt);
  }
  handleNextClickCompany() {
    this.getAllCPTsCompany(this.paginationChargesCompany.pageNo + 1, this.searchFilter.cpt);
  }
  changeReportMeasure(event) {
    if(this.mipsExists()) return;
    // this.censusPatientListService.setReportMeasure(event);
    this.mipReportMeasure.emit(event)
  }

  isMipReportingEnabled() {
    // console.log('this.charge', this.charge);
    // console.log('this.model', this.icd_id, this.model.cpt_id);
    if (this.model.cpt_id.length >= 1 && this.icd_id.length >= 1) {
      return true;
    }
    // return !!this.charge?.owner_id?.mip_reporting;
  }
  undoAction() {
    if (this.undo.length > 0) {
      this.allcptsLoaded = false;
      let ele = this.undo.pop();
      if (ele.action == "add") {
        this.removeCharge(ele.data);
      } else {
        this.addToModel(ele.data, null, 'cpt');
      }
      if (this.redo.length == 4) {
        this.redo.shift();
      }
      this.redo.push(ele);
      this.allcptsLoaded = true;
    }
  }
  redoAction() {
    if (this.redo.length > 0) {
      this.allcptsLoaded = false;
      let ele = this.redo.pop();
      if (ele.action == "add") {
        this.addToModel(ele.data, null, 'cpt');
      } else {
        this.removeCharge(ele.data);
      }
      if (this.undo.length == 4) {
        this.undo.shift();
      }
      this.undo.push(ele);
      this.allcptsLoaded = true;
    }
  }
  checkSigneNote() {
    if (this.charge == null || this.charge.status == "draft") {
      return false;
    } else {
      return true;
    }
  }
  toggleFvrtCPTsinHistory(data) {
    if (data.action === "remove") {
      this.removeFavouriteCharges(data.cpts);
    } else {
      this.addFavouriteCharges(data.cpts);
    }
  }
toggleCPTsinChange(cpt) {
    console.log("Toggle");
          this.addToModel(cpt, null, 'cpt');
          return false;
                  }
            getSelectedChargesCopy() {
    return JSON.parse(JSON.stringify(this.selectedCharges));
  }
  getCheckedStatus(element) {
    if (element.selected) return true;
    else{
      if (this.noteEditorCpts.findIndex(icd => icd.code == element.code) >= 0) {
        element.selected = true;
        return true;
      }
      return false;
    }
  }
  isElementProcedureType(element) {
    if (this.model.cpt_modifiers.findIndex(mod => mod.cpt == element._id && mod?.key?.length > 0) > -1) {
      return true;
    } else {
      return false;
    }
  }
  mipsExists() {
    return Array.isArray(this.questionnaireResults) && this.questionnaireResults.length > 0;
  }

  openFavoriteCptsDialog() {
    const dialogRef = this.dialog.open(ViewFavouriteCptsDialogComponent, {
      width: '50%',
      data: this.fvrtCPTs
    });
    dialogRef.afterClosed().subscribe(async (data: any) => {
      if (data) {
        this.getFvrtCPTs();
      }
  });
  }

  getcolor(row) {
    return row.color;
  }

  shouldDisableCheckbox() {
    const isSameProvider = this.note.provider._id === this.currentUser._id && this.currentUser.user_type === global.USER_TYPE.DOCTOR;
    const isAssociatedProvider = this.note.provider._id != this.currentUser._id && this.currentUser.user_type === global.USER_TYPE.DOCTOR;
    const isMedicalAssistant = this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT;
    if(isSameProvider || isMedicalAssistant) {
      return false;
    }
    if(isAssociatedProvider) {
      return true;
    }
  }
  removeICDsUsingModifer(modifiers) {
    modifiers.forEach((modifierKey) => {
      const selectedCPTsModifiers = this.model.cpt_modifiers.filter(modifier => modifier.key.includes(modifierKey));
      selectedCPTsModifiers.forEach((modifier) => {
        if (modifier.key.length === 1) {
          const cptIndex = this.selectedCharges.findIndex(c => c._id === modifier.cpt);
          if (cptIndex > -1) {
            this.removeElementFromlist(this.selectedCharges[cptIndex], cptIndex);
            this.emitTriggerValues();
          }
        } else {
          const cptIndex = this.model.cpt_modifiers.findIndex(mod => mod.cpt === modifier.cpt);
          if (cptIndex > -1) {
            const keyIndex = this.model.cpt_modifiers[cptIndex]['key'].findIndex(key => key === modifierKey);
            if (keyIndex > -1) {
              this.model.cpt_modifiers[cptIndex]['key'].splice(keyIndex, 1);
              this.modifiersChange.emit(this.model.cpt_modifiers);
              this.emitTriggerValues();
            }
          }
        }
        this.removePhrasesfromHtmlText.emit({ key: [modifierKey] })
      });
    });
  }
}
