import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import * as global from '../global';
import {AuthService} from "src/app/services/auth.service";
import {API_URL} from "../../../environments/api-url";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import * as _ from 'lodash'
import { concatMap, filter, map, tap } from 'rxjs/operators';
import moment from 'moment';
import { PCCService } from "src/app/services/pcc.service";
import { CommonService } from "src/app/services/common.service";
import { RehabReportService } from "src/app/services/rehab-report.service";
import { CensusListService } from "../census-list/census-list..service";
import { MipsService } from "src/app/services/Modules/mips.service";
import { SidebarDiagnosisService } from "src/app/components/sidebar-addCharges/sidebarDiagnonses/sidebar-diagnoses/sidebar-diagnosis.service";
import { calculateArea, calculateTimeDifference, calculateVolume } from "../note-sidebar/wound-care-widget/wound-utils";
import { PatientListService } from "../patient-list/patient-list-service";
import { PatientDetailService } from "../patient-detail/patient-detail.service";
import { Patient } from "src/app/classes/patient";
import { WoundService } from "src/app/services/wound.service";
import { WoundCareWidgetService } from "../note-sidebar/wound-care-widget/wound-care-widget.service";
import { NoteEditorService } from "../note-editor/note-editor.service";
import { Router } from "@angular/router";
import { TagsService } from "src/app/services/tags.service";
import { PatientDetailService as PatientDetailListService } from"../patient-detail-list/patient-detail-list.service";
import { PatientAddService } from "../patient-add/patient-add-service";
import { TherapyService } from "../therapy-tab/therapy.service";
import Table from 'cli-table3';
import swal from "sweetalert";
import { ToastrService } from "ngx-toastr";
import { ProcedureNotesService } from "src/app/services/procedure-notes.service";

export const systemDefinedPhrases = [
    { key: 'notetype@dn' },
    { key: 'facility@dn' },
    { key: 'patient@dn' },
    { key: 'namegender@dn' },
    { key: 'dos@dn' },
    { key: 'provider@dn' },
    { key: 'signature@dn' },
    { key: 'icd10@dn' },
    { key: 'icd10list@dn' },
    { key: 'icd10wound@dn' },
    { key: 'mipsmeasures@dn' },
    { key: 'kentimaging@dn' },
    { key: 'allergies@dn'},
    { key: 'medicalhistory@dn' },
    { key: 'dob@dn' },
    { key: 'nameagesex@dn' },
    { key: 'admitdate@dn' },
    { key: 'pcp@dn' },

    { key: 'rehabupdate:ot@dn' },
    { key: 'rehabupdate:pt@dn' },
    { key: 'telemedconsent@dn' },
    { key: 'prevnote:cc@dn' }, // CHIEF COMPLAINT/CC
    { key: 'prevnote:hpi@dn' }, // HPI
    { key: 'prevnote:plof@dn' }, // PLOF
    { key: 'prevnote:ros@dn' }, // REVIEW OF SYSTEMS (ROS)
    { key: 'prevnote:pmh@dn' }, // PAST MEDICAL HISTORY
    { key: 'prevnote:psh@dn' }, // PAST SURGICAL HISTORY 
    { key: 'prevnote:pmsh@dn' }, // PAST MEDICAL/SURGICAL HISTORY
    { key: 'prevnote:fh@dn' }, // FAMILY HISTORY
    { key: 'prevnote:sh@dn' }, // SOCIAL HISTORY
    { key: 'prevnote:cm@dn' }, // CURRENT MEDICATIONS
    { key: 'prevnote:allergies@dn' }, // ALLERGIES
    { key: 'prevnote:pe@dn' }, // PHYSICAL EXAMINATION
    { key: 'prevnote:data@dn' }, // DATA / IMAGING / LABS
    { key: 'prevnote:assessment@dn' }, // ASSESSMENT / IMPRESSION
    { key: 'prevnote:plan@dn' }, // PLAN/ASSESSMENT / PLAN/IMPRESSION/PLAN
    { key: 'prevnote:precautions@dn' }, // PRECAUTIONS
    { key: 'prevnote:hc@dn' }, // HOSPITAL COURSE
    { key: 'prevnote:ih@dn' }, // INTERVAL HISTORY
    { key: 'prevnote:clof@dn' }, // CURRENT LEVEL OF FUNCTION
    { key: 'prevnote:rehabdiagnosis@dn' }, // REHABILITATION DIAGNOSIS
    { key: 'prevnote:rehabassessment@dn' }, // REHABILITATION ASSESSMENT
    { key: 'prevnote:rehabplan@dn' }, // REHABILITATION ASSESSMENT/PLAN / REHABILITATION PLAN)

    { key: 'prevnote:medicaldecisionmaking@dn' }, // MEDICAL DECISION MAKING
    { key: 'prevnote:patienteducation@dn' }, // PATIENT EDUCATION
    { key: 'prevnote:cardiologyrecommenations@dn' }, // CARDIOLOGY RECOMMENDATIONS
    {key: 'prevnote:goalsofcare@dn'}, // Goals of care
    {key: 'prevnote:medications@dn'}, // Medications

    { key: 'dx@pcc' }, 
    { key: 'meds@pcc' },
    { key: 'meds_long@pcc' },
    { key: 'allergies_list@pcc' },
    { key: 'allergies_csl@pcc' },
    { key: 'vitals@pcc' },
    { key: 'dob@pcc' },
    { key: 'room_no@pcc' },
    { key: 'nameagesex@pcc' },
    { key: 'namegender@pcc' },
    { key: 'admitdate@pcc' },
    { key: 'diet@pcc' },
    { key: 'codestatus@pcc' },
    { key: 'imaging@pcc' },
    // { key: 'imaging_findings_impression@pcc' },
    { key: 'labs@pcc' }, // YES
    { key: 'labstable@pcc' }, // YES
    { key: 'pcp@pcc' }, // YES


    { key: 'pcp@matrix' }, 
    { key: 'dob@matrix' },
    { key: 'admitdate@matrix' },
    { key: 'namegender@matrix' },
    { key: 'nameagesex@matrix' },
    { key: 'allergies_list@matrix' },
    { key: 'allergies_csl@matrix' },

    { key: 'dx@matrix' },
    { key: 'room_no@dn' },
    { key: 'mrn@pcc' },
    { key: 'mrn@matrix' },
    { key: 'mrn@dn' }
];

const woundcareTemplateHeadings = [
  {
    heading: [ 'CHIEF COMPLAINT', 'CC' ],
    phrases: [ 'prevnote:chiefcomplaint@dn', 'prevnote:cc@dn' ]
  },
  {
    heading: 'HOSPITAL COURSE',
    phrases: [ 'prevnote:hospitalcourse@dn', 'prevnote:hc@dn' ]
  },
  {
    heading: 'INTERVAL HISTORY',
    phrases: [ 'prevnote:intervalhistory@dn', 'prevnote:ih@dn' ]
  },
  {
    heading: 'HPI',
    phrases: [ 'prevnote:hpi@dn' ]
  },
  {
    heading: 'PRIOR LEVEL OF FUNCTION (PLOF)',
    phrases: [ 'prevnote:plof@dn' ]
  },
  {
    heading: 'CURRENT LEVEL OF FUNCTION (CLOF)',
    phrases: [ 'prevnote:clof@dn' ]
  },
  {
    heading: 'REVIEW OF SYSTEMS (ROS)',
    phrases: [ 'prevnote:ros@dn' ]
  },
  {
    heading: [ 'PAST MEDICAL HISTORY', 'PMH', 'PMHX' ],
    phrases: [ 'prevnote:pastmedicalhistory@dn', 'prevnote:pmh@dn', 'prevnote:pmhx@dn' ]
  },
  {
    heading: [ 'PAST SURGICAL HISTORY', 'PSH' ],
    phrases: [ 'prevnote:pastsurgicalhistory@dn', 'prevnote:psh@dn' ]
  },
  {
    heading: 'PAST MEDICAL/SURGICAL HISTORY',
    phrases: [ 'prevnote:pastmedicalsurgicalhistory@dn', 'prevnote:pmsh@dn' ]
  },
  {
    heading: 'FAMILY HISTORY',
    phrases: [ 'prevnote:familyhistory@dn', 'prevnote:fh@dn' ]
  },
  {
    heading: 'SOCIAL HISTORY',
    phrases: [ 'prevnote:socialhistory@dn', 'prevnote:sh@dn' ]
  },
  {
    heading: 'MEDICATIONS',
    phrases: [ 'prevnote:medications@dn' ]
  },
  {
    heading: 'CURRENT MEDICATIONS',
    phrases: [ 'prevnote:currentmedications@dn', 'prevnote:cm@dn' ]
  },
  {
    heading: 'ALLERGIES',
    phrases: [ 'prevnote:allergies@dn' ]
  },
  {
    heading: 'PHYSICAL EXAMINATION',
    phrases: [ 'prevnote:physicalexamination@dn', 'prevnote:pe@dn' ]
  },
  {
    heading: 'DATA',
    phrases: [ 'prevnote:data@dn' ]
  },
  {
    heading: 'IMAGING',
    phrases: [ 'prevnote:imaging@dn', 'prevnote:data@dn' ]
  },
  {
    heading: 'LABS',
    phrases: [ 'prevnote:labs@dn', 'prevnote:data@dn' ]
  },
  {
    heading: 'ASSESSMENT',
    phrases: [ 'prevnote:assessment@dn' ]
  },
  {
    heading: 'IMPRESSION',
    phrases: [ 'prevnote:impression@dn', 'prevnote:assessment@dn' ]
  },
  {
    heading: 'PLAN',
    phrases: [ 'prevnote:plan@dn' ]
  },
  {
    heading: 'ASSESSMENT/PLAN',
    phrases: [ 'prevnote:assessmentplan@dn', 'prevnote:plan@dn' ]
  },
  {
    heading: 'IMPRESSION/PLAN',
    phrases: [ 'prevnote:impressionplan@dn', 'prevnote:plan@dn' ]
  },
  {
    heading: 'REHABILITATION ASSESSMENT/PLAN',
    phrases: [ 'prevnote:rehabilitationassmentplan@dn', 'prevnote:rehabplan@dn' ]
  },
  {
    heading: 'REHABILITATION PLAN',
    phrases: [ 'prevnote:rehabilitationplan@dn', 'prevnote:rehabplan@dn' ]
  },
  {
    heading: 'REHABILITATION DIAGNOSIS',
    phrases: [ 'prevnote:rehabilitationdiagnosis@dn', 'prevnote:rehabdiagnosis@dn' ]
  },
  {
    heading: 'PRECAUTIONS',
    phrases: [ 'prevnote:precautions@dn' ]
  },
  {
    heading: 'MEDICAL DECISION MAKING',
    phrases: [ 'prevnote:mdm@dn', 'prevnote:medicaldecisionmaking@dn' ]
  },
  {
    heading: 'PATIENT EDUCATION',
    phrases: [ 'prevnote:patienteducation@dn' ]
  },
  {
    heading: 'GOAL OF CARE',
    phrases: [ 'prevnote:goalsofcare@dn' ]
  },
  {
    heading: 'Wound Assessment',
    phrases: [ 'prevnote:woundassessment@dn' ]
  },
  {
    heading: 'Preventative Measures',
    phrases: [ 'prevnote:preventative_measures@dn' ]
  },
  {
    heading: 'New Recommendations',
    phrases: [ 'prevnote:new_recommendations@dn' ]
  },
  {
    heading: 'Wound HPI',
    phrases: [ 'prevnote:woundhpi@dn' ]
  },
  {
    heading: 'Recommendations',
    phrases: [ 'prevnote:recommendations@dn' ]
  },
  {
    heading : "PLAN OF CARE",
    phrases : [ "prevnote:planofcare@dn" ]
  },
  {
    heading : "RISK FACTORS",
    phrases : [ "prevnote:riskfactors@dn" ]
  }

];

const nursingTemplateHeadgins = [
  {
      heading: 'NOTE TITLE',
      phrases: ['notetype@dn'],
      isHardCodedPhrase: true
  },
  {
      heading: 'DATE OF SERVICE',
      phrases: ['dos@dn'],
      isHardCodedPhrase: true
  },
  {
      heading: 'PATIENT NAME',
      phrases: ['patient@dn'],
      isHardCodedPhrase: true
  },
  {
      heading: 'FACILITY',
      phrases: ['facility@dn'],
      isHardCodedPhrase: true
  },
  {
      heading: 'PROVIDER',
      phrases: ['provider@dn'],
      isHardCodedPhrase: true
  },
  {
      heading: ['CHIEF COMPLAINT', 'CC'],
      phrases: ['prevnote:cc@dn', 'prevnote:chiefcomplaint@dn']
  },
  {
      heading: 'HOSPITAL COURSE',
      phrases: ['prevnote:hc@dn', 'prevnote:hospitalcourse@dn']
  },
  {
      heading: 'INTERVAL HISTORY',
      phrases: ['prevnote:intervalhistory@dn', 'prevnote:ih@dn']
  },
  {
      heading: 'HPI',
      phrases: ['prevnote:hpi@dn']
  },
  {
      heading: 'SUBJECTIVE',
      phrases: ['prevnote:subjective@dn']
  },
  {
      heading: 'PRIOR LEVEL OF FUNCTION (PLOF)',
      phrases: ['prevnote:plof@dn']
  },
  {
      heading: 'CURRENT LEVEL OF FUNCTION (CLOF)',
      phrases: ['prevnote:clof@dn']
  },
  {
      heading: 'REVIEW OF SYSTEMS (ROS)',
      phrases: ['prevnote:ros@dn']
  },
  {
      heading: ['PAST MEDICAL HISTORY', 'PMH', 'PMHX'],
      phrases: ['prevnote:pmh@dn', 'prevnote:pastmedicalhistory@dn', 'prevnote:pmhx@dn']
  },
  {
      heading: ['PAST SURGICAL HISTORY', 'PSH'],
      phrases: ['prevnote:pastsurgicalhistory@dn', 'prevnote:psh@dn']
  },
  {
      heading: 'PAST MEDICAL/SURGICAL HISTORY',
      phrases: ['prevnote:pastmedicalsurgicalhistory@dn', 'prevnote:pmsh@dn']
  },
  {
      heading: 'FAMILY HISTORY',
      phrases: ['prevnote:fh@dn', 'prevnote:familyhistory@dn']
  },
  {
      heading: 'SOCIAL HISTORY',
      phrases: ['prevnote:sh@dn', 'prevnote:socialhistory@dn']
  },
  {
      heading: 'MEDICATIONS',
      phrases: ['prevnote:medications@dn']
  },
  {
      heading: 'CURRENT MEDICATIONS',
      phrases: ['prevnote:cm@dn', 'prevnote:currentmedications@dn']
  },
  {
      heading: 'ALLERGIES',
      phrases: ['prevnote:allergies@dn']
  },
  {
      heading: 'PHYSICAL EXAMINATION',
      phrases: ['prevnote:physicalexamination@dn', 'prevnote:pe@dn']
  },
  {
      heading: 'DATA',
      phrases: ['prevnote:data@dn']
  },
  {
      heading: 'IMAGING',
      phrases: ['prevnote:imaging@dn', 'prevnote:data@dn']
  },
  {
      heading: 'LABS',
      phrases: ['prevnote:labs@dn', 'prevnote:data@dn']
  },
  {
      heading: 'ASSESSMENT',
      phrases: ['prevnote:assessment@dn']
  },
  {
      heading: 'IMPRESSION',
      phrases: ['prevnote:impression@dn', 'prevnote:assessment@dn']
  },
  {
      heading: 'PLAN',
      phrases: ['prevnote:plan@dn']
  },
  {
      heading: 'ASSESSMENT/PLAN',
      phrases: ['prevnote:assessmentplan@dn', 'prevnote:plan@dn']
  },
  {
      heading: 'IMPRESSION/PLAN',
      phrases: ['prevnote:impressionplan@dn', 'prevnote:plan@dn']
  },
  {
      heading: 'REHABILITATION ASSESSMENT/PLAN',
      phrases: ['prevnote:rehabilitationassmentplan@dn', 'prevnote:rehabplan@dn']
  },
  {
      heading: 'REHABILITATION PLAN',
      phrases: ['prevnote:rehabilitationplan@dn', 'prevnote:rehabplan@dn']
  },
  {
      heading: 'REHABILITATION DIAGNOSIS',
      phrases: ['prevnote:rehabilitationdiagnosis@dn', 'prevnote:rehabdiagnosis@dn']
  },
  {
      heading: 'PRECAUTIONS',
      phrases: ['prevnote:precautions@dn']
  },
  {
      heading: 'MEDICAL DECISION MAKING',
      phrases: ['prevnote:mdm@dn', 'prevnote:medicaldecisionmaking@dn']
  },
  {
      heading: 'PATIENT EDUCATION',
      phrases: ['prevnote:patienteducation@dn']
  },
  {
      heading: 'GOAL OF CARE',
      phrases: ['prevnote:goalsofcare@dn']
  },
  {
      heading: 'Review of Records',
      phrases: ['prevnote:reviewofrecords@dn']
  },
  {
      heading: 'Barriers to Discharge',
      phrases: ['prevnote:barrierstodischarge@dn']
  },
  {
      heading: 'Rehab Potential',
      phrases: ['prevnote:rehabpotential@dn']
  },
  {
      heading: 'Discharge Disposition',
      phrases: ['prevnote:dischargedisposition@dn']
  },
  {
      heading: 'Smoking Cessation',
      phrases: ['prevnote:smokingcessation@dn']
  },
  {
      heading: 'Code Status',
      phrases: ['prevnote:codestatus@dn']
  }
];

const templateHeadings =  woundcareTemplateHeadings;

// These are the phrases that should NOT be filtered out regardless of whatever facility source is selected.
// const phrasesShouldNotFilteredOut = [ 'patient@dn', 'dos@dn', 'provider@dn', 'facility@dn', 'notetype@dn', 'signature@dn' ];

export enum SubPhrase {
  woundno = 'woundno',
  woundlocation = 'woundlocation',
  etiology = 'etiology',
  severity = 'severity',
  length = 'length',
  width = 'width',
  depth = 'depth',
  area = 'area',
  volume = 'volume',
  under_mining = 'under_mining',
  exudate = 'exudate',
  exudate_type = 'exudate_type',
  tunneling = 'tunneling',
  exposed_tissues = 'exposed_tissues',
  pain = 'pain',
  sto2 = 'sto2',
  total_weeks = 'total_weeks',
  change_in_area = 'change_in_area',
  change_in_volume = 'change_in_volume',
  exposed_structure = 'exposed_structure',
  infection_signs = 'infection_signs'
}
export const multiSelect_attributes = [];
export const woundPhraseTemplateResponse = [];
function isTextEmpty( text: string ): boolean {
  return ( text == null || text === '' ) ? true : false;
}

function stripAllTags( html ) {
  if ( isTextEmpty( html )) {
    return '';
  }

  const tmp = document.createElement( 'div' );
  tmp.innerHTML = html;

  return ( tmp.textContent || tmp.innerText || '' ).trim();
}

function stripSpecificTag( html, tagName ) {
  if ( isTextEmpty( html ) || isTextEmpty( tagName )) {
    return '';
  }

  const
    regex = new RegExp( `(<${ tagName }[^>]+?>|<${ tagName }>|</${ tagName }>)`, 'img' ),
    result = html.replace( regex, '' );

  return result.trim();
}

function extractTextFromTag( html, tagName ) {
  if ( isTextEmpty( html )) {
    return '';
  }

  const
    regex = new RegExp( `<${ tagName }[^>]*?>(.*?)</${ tagName }>`, 'g' ),
    result = regex.exec( html );

  if ( result == null || !Array.isArray( result )) {
    return '';
  }

  const extracted = result[ 1 ];

  return extracted == null ? '' : extracted.trim();
}

function removeHtmlSpacesAround( text ) {
  if ( isTextEmpty( text )) {
    return '';
  }

  const find = '&nbsp;';

  let
    beginPos = 0,
    endPos = text.length;

  while ( text.substring( beginPos, ( beginPos + find.length )) === find ) {
    beginPos += find.length;
  }

  while ( text.substring(( endPos - find.length ), endPos ) === find ) {
    endPos -= find.length;
  }

  return text.substring( beginPos, endPos );
}

function extractPhraseSource( phraseText ) {
  if ( phraseText.indexOf( '@dn' ) >= 0 ) {
    return 'dn';
  }

  if ( phraseText.indexOf( '@pcc' ) >= 0 ) {
    return 'pcc';
  }

  if ( phraseText.indexOf( '@matrix' ) >= 0 ) {
    return 'matrix';
  }

  return '';
}

const asteriskJumpSpanHTML = '<span class="asteriskjump">***</span>';

@Injectable({
    providedIn: 'root',
})
export class PhrasesService {
    currentUser: any = null;
    userInfo: any = {};
    marker = '.';
    endingPhrases = [ 'signature@dn', 'provider@dn' ];
    preferredEndingPhrase = 'provider@dn';
    lastSectionSeparator = '---';
    dragonSymbol = '[ ]';

    phrasesData = {};
    
    private dictionary = new BehaviorSubject<Array<{
        user_id: string,
        key: string,
        value: string
      }>>(null);

    castPhrases = this.dictionary.asObservable();

    private woundPhrases = new BehaviorSubject<Array<{
      key: string,
      value?: string
    }>>(null);
    
    private woundTemplatePhrases = new BehaviorSubject<Array<{
      key: string,
      value?: string
    }>>(null);

    castWoundPhrases = this.woundPhrases.asObservable();
    castWoundTemplatePhrases = this.woundTemplatePhrases.asObservable();

    castUserDefinedPhrases = this.dictionary.asObservable()
                            .pipe(map((phrases:any) => phrases?.filter(phrase => phrase.value)));
    castSystemDefinedPhrases = this.dictionary.asObservable()
                            .pipe(map((phrases:any) => phrases?.filter(phrase => !phrase.value)));
    woundDropdownTemplates: any;
  procedureNotesDropdownTemplates = [];
  activeProcedureTypeId: string;

    constructor(private httpclient: HttpClient,
                private _authService: AuthService,
                private _pccService:PCCService,
                private _rehabReportService:RehabReportService,
                private _commonService:CommonService,
                private censusListService:CensusListService,
                private mipsService:MipsService,
                private sidebarDiagnosisService:SidebarDiagnosisService,
                private patientListService:PatientListService,
                private patientDetailService:PatientDetailService,
                private woundService:WoundService,
                private woundCareWidgetService: WoundCareWidgetService,
                private noteEditorService: NoteEditorService,
                private router: Router,
                private tagsService: TagsService,
                private _patientDetailListService: PatientDetailListService,
                private PatientService: PatientAddService,
                private _therapyService: TherapyService,

                private _toastr: ToastrService,
                private procedureNotesService: ProcedureNotesService,
                ) {
                    this.currentUser = this._authService.currentUser;
                    this._authService.onUserAuthChange.subscribe(user => {
                      this.currentUser = user
                    })
    }

    async initWoundDropdownTemplates() {
      if(this.woundDropdownTemplates) return;
      this.woundDropdownTemplates = {};
      const { data, status } = await lastValueFrom(this.woundCareWidgetService.getAllDataset(this._authService.currentUser.company_id, {header: 1, rows: 1})) as any;
      if(status === 200) {
        for (const item of data) {
            const header = item.header;
            const sub_phrase_key = item.header.toLowerCase().trim().replace(/\s/g, '_');
            const options = item.rows.filter(row => row.data_type === 'Text').map(row => row.value);
            const columnsAndFieldsMapping = {
              wound_edges : 'exposed_structure',
              exudate_amount : 'exudate',
              wound_infection : 'infection_signs',
              location: "body_part"
            }
            let woundField = sub_phrase_key;
            if (Object.prototype.hasOwnProperty.call(columnsAndFieldsMapping, sub_phrase_key)) {
              woundField = columnsAndFieldsMapping[sub_phrase_key];
            }
            if(item.multiselect_enabled){
              if(!multiSelect_attributes.includes(woundField)){
                multiSelect_attributes.push(woundField);
              }
              this.woundDropdownTemplates[sub_phrase_key] = `
              <span data-wound-field="${woundField}"><span class="select-dropdown" data-multiselect="true" data-options="${options.join('|')}" data-label="${header}" contenteditable="false">${header}</span></span>
            `;
            }else if(sub_phrase_key.includes("loc")){
              this.woundDropdownTemplates[woundField] = `
              <span data-wound-field="${woundField}"><span class="text-field-dropdown" data-label="${woundField}" contenteditable="false">${header}</span></span>
            `;
            }else{
              this.woundDropdownTemplates[sub_phrase_key] = `
              <span data-wound-field="${woundField}"><span class="select-dropdown" data-options="${options.join('|')}" data-label="${header}" contenteditable="false">${header}</span></span>
            `;
            }
        }
      }
    }

    async initProcedureNotesDropdownTemplates(activeProcedureTypeId) {
      if (this.procedureNotesDropdownTemplates[`procedure_${activeProcedureTypeId}`]) return;
      const procedureNotesDropdownTemplates = [];
      const isMedicalAssistant = this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT;
      const response = await lastValueFrom(this.procedureNotesService.getProcedureNoteDataset(isMedicalAssistant ? global.USER_TYPE.DOCTOR : this.currentUser.user_type, activeProcedureTypeId, this.currentUser.company_id));
      if (response['status'] === 200) {
        for (const item of response['data']) {
          const header = item.header;
          const sub_phrase_key = item.header.toLowerCase().trim().replace(/\s/g, '_');
          const options = item.rows;
          if (item.header_type === 'Input') {
            const inputTemplates = [];
            options.forEach(option => {
              const label_phrase = option.value.toLowerCase().trim().replace(/\s/g, '_');
              inputTemplates.push({
                dropDownTemplate: `<span data-procedure-field="${sub_phrase_key}_${label_phrase}" data-header="${item._id}"><span class="text-field-dropdown" data-label="${option.value}" contenteditable="false">${option.value}</span></span>`,
                label: option.value,
                unit: option.unit,
                phraseKey: `${sub_phrase_key}_${label_phrase}`
              });
            });
            procedureNotesDropdownTemplates.push({
              inputTemplates,
              resultTemplate: `<span data-procedure-field="${sub_phrase_key}_result" data-header="${item._id}"><span class="text-field-result" data-label="${header}">${header}</span>${item.unit ? `${item.unit}${options.length > 1 ? `<sup>${options.length}</sup>` : ``}` : ``}</span>`,
              resultPhraseKey: `${sub_phrase_key}_result`,
              dataSet: item,
              phraseKey: `${sub_phrase_key}`
            });
          } else if (item.multiselect_enabled) {
            if (!multiSelect_attributes.includes(sub_phrase_key)) {
              multiSelect_attributes.push(sub_phrase_key);
            }
            procedureNotesDropdownTemplates.push({
              dropDownTemplate: `<span data-procedure-field="${sub_phrase_key}" data-header="${item._id}"><span class="select-dropdown" data-multiselect="true" data-options="${options.map(option => option.value).join('|')}" data-label="${header}" contenteditable="false">${header}</span></span>`,
              dataSet: item,
              phraseKey: `${sub_phrase_key}`
            });
          } else {
            procedureNotesDropdownTemplates.push({
              dropDownTemplate: `<span data-procedure-field="${sub_phrase_key}" data-header="${item._id}"><span class="select-dropdown" data-options="${options.map(option => option.value).join('|')}" data-label="${header}" contenteditable="false">${header}</span></span>`,
              dataSet: item,
              phraseKey: `${sub_phrase_key}`
            });
          }
        }
        const key = `procedure_${activeProcedureTypeId}`;
        procedureNotesDropdownTemplates.sort((a, b) => b.phraseKey.length - a.phraseKey.length);
        this.procedureNotesDropdownTemplates = JSON.parse(JSON.stringify({ [key]: procedureNotesDropdownTemplates, ...this.procedureNotesDropdownTemplates }));
        this.procedureNotesDropdownTemplates[`procedure_${activeProcedureTypeId}`] = procedureNotesDropdownTemplates;
      }
    }

    resetPhrasesData() {
      this.phrasesData = {};
    }

    getNoteBuilderDictionary(provider_id?) {
        const details = {
            ...this._authService.authObject,
            provider_id
        };

        return this.httpclient
            .post(global.url + API_URL.NOTE.getNoteBuilderDictionary, {details: details}).pipe(
              concatMap((userDefinedPhrasesResponse:any) => {
                return this.httpclient.post(global.url + API_URL.PHRASE.getPhrases, {details: details}).pipe(
                  
                map((systemDefinedPhrasesResponse:any)=> {
                  let systemDefinedPhrases = [];
                  if(systemDefinedPhrasesResponse.status == 200) {
                    systemDefinedPhrases = systemDefinedPhrasesResponse.data;
                  }

                  // check if env is production
                  if(this.router.url.startsWith('/template-create') && global.ENVIRONMENT === global.PROD) {
                    const excludePhrases = ['woundassessment_all@dn', 'woundplan_all@dn'];
                    systemDefinedPhrases = systemDefinedPhrases.filter(phrase => !excludePhrases.includes(phrase.key));
                  }

                  if(userDefinedPhrasesResponse.status === 200 && Array.isArray(userDefinedPhrasesResponse.data)) {
                    userDefinedPhrasesResponse.data = {
                      dictionary: userDefinedPhrasesResponse.data.reduce((acc, next) => {
                        next.dictionary = next.dictionary.map(d => ({...d, user_id: next.user_id}))
                        return acc.concat(next.dictionary)
                      }, [])
                    }
                  }

                  let userDefinedPhrases = userDefinedPhrasesResponse.data?.dictionary || [];
                  const dictionary = [...systemDefinedPhrases, ...(userDefinedPhrases)];
                  this.dictionary.next(dictionary);

                  return userDefinedPhrasesResponse;
                }),
                // tap((response:any)=> {
                //     if(response.status === 200) {
                //       let userDefinedPhrases = response.data?.dictionary || [];
                //         const dictionary = [...systemDefinedPhrases, ...(userDefinedPhrases)];
                //         this.dictionary.next(dictionary);
                //     }
                // })
                );
              })
            );

    };
    getWoundPhrases() {
      const woundPhrases = [
        { key: 'woundlocation' },
        { key: 'woundno' },
        { key: 'woundmeasurement' },
        { key: 'sto2' },
        { key: 'area' }

      ];
      this.woundPhrases.next(woundPhrases);
    }
    setWoundTemplatePhrases(phrases= []) {
      // const woundTemplatePhrases = [
      //   { key: SubPhrase.woundno },
      //   { key: SubPhrase.woundlocation },
      //   { key: SubPhrase.etiology },
      //   { key: SubPhrase.severity },
      //   { key: SubPhrase.length },
      //   { key: SubPhrase.width },
      //   { key: SubPhrase.depth },
      //   { key: SubPhrase.area },
      //   { key: SubPhrase.volume },
      //   { key: SubPhrase.under_mining },
      //   { key: SubPhrase.exudate },
      //   { key: SubPhrase.exudate_type },
      //   { key: SubPhrase.tunneling },
      //   { key: SubPhrase.exposed_tissues },
      //   { key: SubPhrase.pain },
      //   // woundhistory
      //   { key: SubPhrase.sto2 },
      //   { key: SubPhrase.total_weeks },
      //   { key: SubPhrase.change_in_area },
      //   { key: SubPhrase.change_in_volume },
      //   ...phrases
      // ];pac
      this.woundTemplatePhrases.next(phrases);
    }
    addPhrase(key) {
        console.log('phrases.service/addPhrase');
        const details = {
            ...this._authService.authObject,
            key,
        }
        return this.httpclient
            .post(global.url + API_URL.PHRASE.addPhrase, {details: details}).pipe();
    };
    exportPhrasesAsXlxs(user_id) {
      const details = {
        ...this._authService.authObject,
        user_id
      }
      const headers = new HttpHeaders();
      headers.append('Access-Control-Allow-Headers', 'Content-Length');
      headers.append('Access-Control-Expose-Headers', 'Content-Length');
      headers.append('Content-Type', 'application/octet-stream');
      return this.httpclient
        .post(global.url + API_URL.PHRASE.exportPhrasesAsXlxs, { details: details }, {
          responseType: "blob",
          reportProgress: true,
          observe: 'events',
          headers
        }).pipe();
    }
    updatePhrase(key, _id) {
        console.log('phrases.service/updatePhrase');
        const details = {
            ...this._authService.authObject,
            key,
            phrase_id : _id,
           
        }
        return this.httpclient
            .post(global.url + API_URL.PHRASE.updatePhrase, {details: details}).pipe();
    };
    getPhrases(company_id?,only_admin?) { 
        const details = {
            ...this._authService.authObject,
            company_id,
            only_admin
        }
        return this.httpclient
            .post(global.url + API_URL.PHRASE.getPhrases, {details: details}).pipe();
    };
    getUserDefinedCompanyPhrases(filter) { 
      return this.httpclient
          .post(global.url + API_URL.PHRASE.getUserDefinedCompanyPhrases, {filter: filter}).pipe();
  };
    getAssociatedPhrases(provider_id = null){
      const details = {
        ...this._authService.authObject,
        provider_id: provider_id
      }
      return this.httpclient
      .post(global.url + API_URL.PHRASE.getAssociatedPhrases, {details: details}).pipe();
    }
    deletePhrase(_id) {
        console.log('phrases.service/deletePhrase');
        const details = {
            ...this._authService.authObject,
            _id
        }
        return this.httpclient
            .post(global.url + API_URL.PHRASE.deletePhrase, {details: details}).pipe();
    };
    
    saveNoteBuilderDictionary(dictionaryElement) {
        console.log('phrases.service/saveNoteBuilderDictionary');
        const details = {
            ...this._authService.authObject,
            dictionaryElement: dictionaryElement
        }
        return this.httpclient
            .post(global.url + API_URL.NOTE.saveNoteBuilderDictionary, {details: details}).pipe(
                tap((response:any)=> {
                    if(response.status === 200) {
                        this.dictionary.next([...(this.dictionary.getValue() || []), dictionaryElement]);
                    }
                })
            );

    };
    updateNoteBuilderDictionary(dictionaryElement) {
        console.log('phrases.service/updateNoteBuilderDictionary');
        const details = {
            ...this._authService.authObject,
            dictionaryElement: dictionaryElement
        }
        return this.httpclient
            .post(global.url + API_URL.NOTE.updateNoteBuilderDictionary, {details: details}).pipe(
                tap((response:any)=> {
                    if(response.status === 200) {
                        this.dictionary.next((this.dictionary.getValue() || []).map(e => e.key === dictionaryElement.key ? dictionaryElement : e));
                    }
                })
            );

    };
    deleteFromNoteBuilderDictionary(dictionaryElement,provider_id?) {
        console.log('phrases.service/saveNoteBuilderDictionary');
        const details = {
            ...this._authService.authObject,
            dictionaryElement: dictionaryElement,
            provider_id
        }
        return this.httpclient
            .post(global.url + API_URL.NOTE.deleteFromNoteBuilderDictionary, {details: details}).pipe(
                tap((response:any)=> {
                    if(response.status === 200) {
                        this.dictionary.next(this.dictionary.getValue().filter(e => e.key !== dictionaryElement.key));
                    }
                })
            );
    };

    hasPhrase(text, ignorePhrases = []) {        
        const dictionary = this.dictionary.getValue() || [];
        if (typeof text !== 'string' || text.length === 0) return;

        for (const element of dictionary) {
        const { key, value } = element;
        if(ignorePhrases.indexOf(key) >= 0) {
            continue;
        }
        if (key.length >= 3) {
            const match = this.getRegex(key).exec(text);
            if (match) {
                return {
                    match,
                    key: `${this.marker}${key}`,
                    value,
                };
            }
        }
        }
    }

    getRegex(keyWithoutMarker, flags="") {
      return new RegExp(`\\${this.marker}${keyWithoutMarker}`, flags)
    }

    getNonEmptyLines( editorText ) {
      const text = ( editorText ?? '' ).trim();

      if ( text === '' ) {
        return [];
      }

      const
        lines = text.split( '\n' ),
        nonEmptyLines = lines.filter( line => {
          const pureLine = stripAllTags( line.trim());

          return pureLine !== '';
        });
      
      return nonEmptyLines;
    }

    getAllHeadings( editorText ) {
      const nonEmptyLines = this.getNonEmptyLines( editorText );

      if ( nonEmptyLines.length === 0 ) {
        return [];
      }

      const
        linesWithoutPTag = nonEmptyLines.map( line => stripSpecificTag( line, 'p' )),
        linesStartsWithHeadings = linesWithoutPTag.filter( line => line.startsWith( '<strong')),
        rawHeadings = linesStartsWithHeadings.map( line => extractTextFromTag( line, 'strong' )),
        headings = rawHeadings.map( heading => removeHtmlSpacesAround( heading.trim()));

      return headings;
    }

    isHeadingValid( heading ) {
      if ( heading == null || heading === '' ) {
        return false;
      }

      return this.getFlatTemplateHeadings().includes( heading.toUpperCase());
    }

    doesEditorContainLastSectionSeparator( editorText ) {
      const nonEmptyLines = this.getNonEmptyLines( editorText );

      if ( nonEmptyLines.length === 0 ) {
        return false;
      }

      return nonEmptyLines
        .map( line => stripSpecificTag( line, 'p' ))
        .some( line => line === '---' );
    }

    doesEditorContainSignaturePhrase( editorText ) {
      if ( editorText == null ) {
        return false;
      }

      return editorText.includes( '.signature@dn' );
    }

    getTemplateHeadings() {
      return templateHeadings;
    }

    getFlatTemplateHeadings() {
      return templateHeadings
        .reduce(( accumulator, current ) => {
          const
            currHeading = current.heading,
            newArray = typeof currHeading === 'string' ?
              [ currHeading ] :
              [ ...currHeading ];

          return [ ...accumulator, ...newArray ];
        }, [])
        .map( heading => `${ heading.toUpperCase() }:` )
        .filter(( value, index, array ) => array.indexOf( value ) === index );
    }

    async resolveSystemDefinedPhraseValue(key, note, extra:any = {}) {
        const keyWithoutMarker = key.substring(this.marker.length);
        const isPCCPhrase = key.indexOf('@pcc') >= 0;
        const hasPCCPatient = !!(note.patient && note.patient.pcc_patientId);
        if(isPCCPhrase && !hasPCCPatient) {
            return key;
        }

        if(note.patient && this.phrasesData[note.patient._id] && this.phrasesData[note.patient._id][keyWithoutMarker]) {
            if (this.phrasesData[note.patient._id][keyWithoutMarker] && 
                !Object.prototype.hasOwnProperty.call(this.phrasesData[note.patient._id][keyWithoutMarker], 'error')) {      
                return this.phrasesData[note.patient._id][keyWithoutMarker]
            }
            
        }

        let authMode;
        if(!note.facility?.pcc_2_legged_authentication) {
          authMode = 'APP'
        }
        if(keyWithoutMarker.match(/^((wound(assessment|history|plan|procedure)|woundassessmentshort|kentimaging|nursewoundassessment|woundhpi)|prevnote:(wound(assessment|history|plan)))/m)) {
          await this.initWoundDropdownTemplates()
        }
        if(keyWithoutMarker.match(/^wound_\d+\@dn$/m)) {
          const woundPhrase:any = extra.phrase;
          const wound_id = woundPhrase._id;

          // call to get wound data
          const {data: wound, status} = await lastValueFrom(this.resolveWoundPhrase(wound_id, undefined, true)) as any;
          if(status == 200) {
            const parentWound = wound;
            const [latestChildWoundWound, ...restChildWounds] = wound.child_wounds;
            const latestWound = latestChildWoundWound || wound;
            return woundPhraseTemplate(parentWound, latestWound, restChildWounds);
          }
        } else if(keyWithoutMarker === 'wounds_all@dn') {
          const {data: wounds, status} = await lastValueFrom(this.resolveWoundPhrase(undefined, note.patient._id, true)) as any;
          if(status == 200) {
            return wounds.map(wound => {
              const parentWound = wound;
              const [latestChildWoundWound, ...restChildWounds] = wound.child_wounds;
              const latestWound = latestChildWoundWound || wound;
              return woundPhraseTemplate(parentWound, latestWound, restChildWounds);
            }).join('<br /><br />')
          }
        } else if(keyWithoutMarker.match(/^woundplan_\d+\@dn$/m)) {
          const woundPhrase:any = extra.phrase;
          const wound_id = woundPhrase._id;
          let addendum_status = false;
          if(note?.facility?.source === "PointClickCare" || note?.facility?.source ==="MatrixCare"){
            if(note.note_status?.status === 'uploaded') addendum_status = true;
          }else{
            if(note.is_signed === 'true'|| note.note_status?.status === 'uploaded') addendum_status = true;
          }
          // call to get wound data
          const {data: wound, status} = await lastValueFrom(this.resolveWoundPhrase(wound_id, undefined, true, undefined, note.date_obj, addendum_status)) as any;
          if(status == 200) {
            const parentWound = wound;
            const [latestChildWoundWound, ...restChildWounds] = wound.child_wounds;
            const latestWound = latestChildWoundWound || wound;
            let phraseTemplate;
            const woundPhraseTemplateResponse = await lastValueFrom(this.woundService.getSystemDefinedPhraseTemplateByPhraseKey('woundplan')) as any;
            if(woundPhraseTemplateResponse.status === 200) {
              const woundPhraseTemplate = woundPhraseTemplateResponse.data;
              phraseTemplate = woundPhraseTemplate.template;            
            }
            if(addendum_status){
              if (note.date_obj.day == latestWound.last_assessment_date_obj.day && note.date_obj.month == latestWound.last_assessment_date_obj.month && note.date_obj.year == latestWound.last_assessment_date_obj.year) {
                if(latestWound.is_clone){
                  return woundPlanPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, addendum_id: latestWound._id, should_show_wound_location: extra.should_show_wound_location});
                }else{
                  return woundPlanPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, should_show_wound_location: extra.should_show_wound_location});
                }
              }else{
                ///Check child wounds
                ///Find latest child of note date obj and show popup
                const childFilterWounds = wound.child_wounds.filter((rev) => note.date_obj.day == rev.last_assessment_date_obj.day && note.date_obj.month == rev.last_assessment_date_obj.month && note.date_obj.year == rev.last_assessment_date_obj.year);
                if(childFilterWounds.length > 0){
                  if(childFilterWounds[0].is_clone){
                    return woundPlanPhraseTemplate(parentWound, childFilterWounds[0], restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, addendum_id: childFilterWounds[0]._id, should_show_wound_location: extra.should_show_wound_location});
                  }else{
                    return woundPlanPhraseTemplate(parentWound, childFilterWounds[0], restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, should_show_wound_location: extra.should_show_wound_location});
                  }
                }else{
                  if (note.date_obj.day == wound.last_assessment_date_obj.day && note.date_obj.month == wound.last_assessment_date_obj.month && note.date_obj.year == wound.last_assessment_date_obj.year) {
                    
                    ///Check parent wound
                    return woundPlanPhraseTemplate(parentWound, wound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, should_show_wound_location: extra.should_show_wound_location});
                  }else{
                    this._toastr.error("No Wounds DOS match with Addendum DOS")
                  }
                }
              }
            }else{
              return woundPlanPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, should_show_wound_location: extra.should_show_wound_location});
            }
          }
        } else if(keyWithoutMarker === 'woundplan_all@dn') {
          const {data: wounds, status} = await lastValueFrom(this.resolveWoundPhrase(undefined, note.patient._id, true, undefined)) as any;
          if(status == 200) {
            let phraseTemplate;
            const woundPhraseTemplateResponse = await lastValueFrom(this.woundService.getSystemDefinedPhraseTemplateByPhraseKey('woundplan')) as any;
            if(woundPhraseTemplateResponse.status === 200) {
              const woundPhraseTemplate = woundPhraseTemplateResponse.data;
              phraseTemplate = woundPhraseTemplate.template;            
            }
            return wounds.map((wound, index) => {
              const parentWound = wound;
              const [latestChildWoundWound, ...restChildWounds] = wound.child_wounds;
              const latestWound = latestChildWoundWound || wound;
              return woundPlanPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, should_show_wound_location: extra.should_show_wound_location}, index === 0 ? true : false);
            }).join('<br /><br />')
          }
        } else if(keyWithoutMarker.match(/^woundhistory_\d+\@dn$/m)) {
          const woundPhrase:any = extra.phrase;
          const wound_id = woundPhrase._id;

          // call to get wound data
          const {data: wound, status} = await lastValueFrom(this.resolveWoundPhrase(wound_id, undefined, true)) as any;
          if(status == 200) {
            const parentWound = wound;
            const [latestChildWoundWound, ...restChildWounds] = wound.child_wounds;
            const latestWound = latestChildWoundWound || wound;
            let phraseTemplate;
            const woundPhraseTemplateResponse = await lastValueFrom(this.woundService.getSystemDefinedPhraseTemplateByPhraseKey('woundhistory')) as any;
            if(woundPhraseTemplateResponse.status === 200) {
              const woundPhraseTemplate = woundPhraseTemplateResponse.data;
              phraseTemplate = woundPhraseTemplate.template;            
            }
            return woundHistoryPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates});
          }
        } else if(keyWithoutMarker === 'woundhistory_all@dn') {
          const {data: wounds, status} = await lastValueFrom(this.resolveWoundPhrase(undefined, note.patient._id, true)) as any;
          if(status == 200) {
            let phraseTemplate;
            const woundPhraseTemplateResponse = await lastValueFrom(this.woundService.getSystemDefinedPhraseTemplateByPhraseKey('woundhistory')) as any;
            if(woundPhraseTemplateResponse.status === 200) {
              const woundPhraseTemplate = woundPhraseTemplateResponse.data;
              phraseTemplate = woundPhraseTemplate.template;            
            }
            return wounds.map(wound => {
              const parentWound = wound;
              const [latestChildWoundWound, ...restChildWounds] = wound.child_wounds;
              const latestWound = latestChildWoundWound || wound;
              return woundHistoryPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates});
            }).join('<br /><br />')
          }
        } else if(keyWithoutMarker.match(/^woundhpi_\d+\@dn$/m)) {
          const woundPhrase:any = extra.phrase;
          const wound_id = woundPhrase._id;

          // call to get wound data
          const {data: wound, status} = await lastValueFrom(this.resolveWoundPhrase(wound_id, undefined, true)) as any;
          if(status == 200) {
            const parentWound = wound;
            const [latestChildWoundWound, ...restChildWounds] = wound.child_wounds;
            const latestWound = latestChildWoundWound || wound;
            let phraseTemplate;
            const woundPhraseTemplateResponse = await lastValueFrom(this.woundService.getSystemDefinedPhraseTemplateByPhraseKey('woundhpi')) as any;
            if(woundPhraseTemplateResponse.status === 200) {
              const woundPhraseTemplate = woundPhraseTemplateResponse.data;
              phraseTemplate = woundPhraseTemplate.template;            
            }
            return woundAssessmentPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, should_show_wound_location: extra.should_show_wound_location}, "woundhpi");
          }
        } else if(keyWithoutMarker === 'woundhpi_all@dn') {
          const {data: wounds, status} = await lastValueFrom(this.resolveWoundPhrase(undefined, note.patient._id, undefined, undefined)) as any;
          if(status == 200) { 
            let phraseTemplate;
            const woundPhraseTemplateResponse = await lastValueFrom(this.woundService.getSystemDefinedPhraseTemplateByPhraseKey('woundhpi')) as any;
            if(woundPhraseTemplateResponse.status === 200) {
              const woundPhraseTemplate = woundPhraseTemplateResponse.data;
              phraseTemplate = woundPhraseTemplate.template;            
            }
            return wounds.map(wound => {
              const parentWound = wound;
              const [latestChildWoundWound, ...restChildWounds] = wound.child_wounds;
              const latestWound = latestChildWoundWound || wound;
              return woundAssessmentPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, should_show_wound_location: extra.should_show_wound_location}, "woundhpi");
            }).join('<br /><br />')
          }
        } else if(keyWithoutMarker.match(/^woundassessment_\d+\@dn$/m)) {
          const woundPhrase:any = extra.phrase;
          const wound_id = woundPhrase._id;
          let addendum_status=false;
          if(note?.facility?.source === "PointClickCare"|| note?.facility?.source === "MatrixCare"){
            if(note.note_status?.status === 'uploaded') addendum_status = true;
          }else{
            if(note.is_signed == 'true'||note.note_status?.status == 'uploaded') addendum_status = true;
          }

          // call to get wound data
          const {data: wound, status} = await lastValueFrom(this.resolveWoundPhrase(wound_id,null,null,null,note.date_obj,addendum_status)) as any;
          if(status == 200) {
            const parentWound = wound;
            const [latestChildWoundWound, ...restChildWounds] = wound.child_wounds;
            const latestWound = latestChildWoundWound || wound;
            let phraseTemplate;
            const woundPhraseTemplateResponse = await lastValueFrom(this.woundService.getSystemDefinedPhraseTemplateByPhraseKey('woundassessment')) as any;
            if(woundPhraseTemplateResponse.status === 200) {
              const woundPhraseTemplate = woundPhraseTemplateResponse.data;
              phraseTemplate = woundPhraseTemplate.template;            
            }
            if(addendum_status){
              if (note.date_obj.day == latestWound.last_assessment_date_obj.day && note.date_obj.month == latestWound.last_assessment_date_obj.month && note.date_obj.year == latestWound.last_assessment_date_obj.year) {
                if(latestWound.is_wound_locked){
                  ///Show Popup
                  console.log("Show Popup");
                  const phraseResolve = await this.cloneAddendumPopup(latestWound,wound_id,phraseTemplate);
                  return { refreshWounds: true , phraseResolve}
                }else{
                  ///Resolve
                  console.log("Resolve");
                  if(latestWound.is_clone){
                    return woundAssessmentPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, addendum_id: latestWound._id, should_show_wound_location: extra.should_show_wound_location});
                  }else{
                    if(wound.latestWoundId !== null && wound.latestWoundId._id === latestWound._id){
                      return woundAssessmentPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, should_show_wound_location: extra.should_show_wound_location});
                    }else{
                      const phraseResolve = await this.cloneAddendumPopup(latestWound,wound_id,phraseTemplate);
                      return { refreshWounds: true , phraseResolve}
                    }
                  }
                }
              }else{
                ///Check child wounds
                ///Find latest child of note date obj and show popup
                const childFilterWounds = wound.child_wounds.filter((rev) => note.date_obj.day == rev.last_assessment_date_obj.day && note.date_obj.month == rev.last_assessment_date_obj.month && note.date_obj.year == rev.last_assessment_date_obj.year && rev.state === 'active');
                if(childFilterWounds.length > 0){
                  if(childFilterWounds[0].is_wound_locked){
                    const phraseResolve = await this.cloneAddendumPopup(childFilterWounds[0], wound_id,phraseTemplate);
                    return { refreshWounds: true , phraseResolve}
                  }else{
                    if(childFilterWounds[0].is_clone){
                      return woundAssessmentPhraseTemplate(parentWound, childFilterWounds[0], restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, addendum_id: childFilterWounds[0]._id, should_show_wound_location: extra.should_show_wound_location});
                    }else{
                      console.log("Check Child Wounds")
                      return woundAssessmentPhraseTemplate(parentWound, childFilterWounds[0], restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, should_show_wound_location: extra.should_show_wound_location});
                    }
                  }
                }else{
                  if (note.date_obj.day == wound.last_assessment_date_obj.day && note.date_obj.month == wound.last_assessment_date_obj.month && note.date_obj.year == wound.last_assessment_date_obj.year) {
                    ///Check parent wound
                    const phraseResolve = await this.cloneAddendumPopup(wound, wound_id,phraseTemplate);
                    return { refreshWounds: true , phraseResolve}
                  }else{
                    this._toastr.error("No Wounds DOS match with Addendum DOS")
                    // return woundAssessmentPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates});
                  }
                }
              }
            }else{
              return woundAssessmentPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, should_show_wound_location: extra.should_show_wound_location});
            }
          }
        } else if(keyWithoutMarker === 'woundassessment_all@dn') {
          const {data: wounds, status} = await lastValueFrom(this.resolveWoundPhrase(undefined, note.patient._id, undefined, undefined)) as any;
          if(status == 200) { 
            let phraseTemplate;
            const woundPhraseTemplateResponse = await lastValueFrom(this.woundService.getSystemDefinedPhraseTemplateByPhraseKey('woundassessment')) as any;
            if(woundPhraseTemplateResponse.status === 200) {
              const woundPhraseTemplate = woundPhraseTemplateResponse.data;
              phraseTemplate = woundPhraseTemplate.template;            
            }
            return wounds.map(wound => {
              const parentWound = wound;
              const [latestChildWoundWound, ...restChildWounds] = wound.child_wounds;
              const latestWound = latestChildWoundWound || wound;
              return woundAssessmentPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, should_show_wound_location: extra.should_show_wound_location});
            }).join('<br /><br />')
          }
        } else if(keyWithoutMarker.match(/^kentimaging_\d+\@dn$/m)) {
          const woundPhrase:any = extra.phrase;
          const wound_id = woundPhrase._id;

          // call to get wound data
          const {data: wound, status} = await lastValueFrom(this.resolveWoundPhrase(wound_id)) as any;
          if(status == 200) {
            const parentWound = wound;
            const [latestChildWoundWound, ...restChildWounds] = wound.child_wounds;
            const latestWound = latestChildWoundWound || wound;
            let phraseTemplate;
            const woundPhraseTemplateResponse = await lastValueFrom(this.woundService.getSystemDefinedPhraseTemplateByPhraseKey('kentimaging')) as any;
            if(woundPhraseTemplateResponse.status === 200) {
              const woundPhraseTemplate = woundPhraseTemplateResponse.data;
              phraseTemplate = woundPhraseTemplate.template;            
            }
            return woundKentImagingPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates});
          }
        } else if(keyWithoutMarker === 'kentimaging_all@dn') {
          const {data: wounds, status} = await lastValueFrom(this.resolveWoundPhrase(undefined, note.patient._id)) as any;
          if(status == 200) {
            let phraseTemplate;
            const woundPhraseTemplateResponse = await lastValueFrom(this.woundService.getSystemDefinedPhraseTemplateByPhraseKey('kentimaging')) as any;
            if(woundPhraseTemplateResponse.status === 200) {
              const woundPhraseTemplate = woundPhraseTemplateResponse.data;
              phraseTemplate = woundPhraseTemplate.template;            
            }
            return wounds.map(wound => {
              const parentWound = wound;
              const [latestChildWoundWound, ...restChildWounds] = wound.child_wounds;
              const latestWound = latestChildWoundWound || wound;
              return woundKentImagingPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates});
            }).join('<br /><br />')
          }
        } else if(keyWithoutMarker.match(/^woundassessmentshort_\d+\@dn$/m)){
          const woundPhrase:any = extra.phrase;
          const wound_id = woundPhrase._id;
          let addendum_status = false;
          console.log("WoundPlan", note);
          if(note?.facility?.source === "PointClickCare" || note?.facility?.source ==="MatrixCare"){
            if(note.note_status?.status === 'uploaded') addendum_status = true;
          }else{
            if(note.is_signed === 'true'|| note.note_status?.status === 'uploaded') addendum_status = true;
          }
          // call to get wound data
          const {data: wound, status} = await lastValueFrom(this.resolveWoundPhrase(wound_id, undefined, false, undefined, note.date_obj, addendum_status)) as any;
          if(status == 200) {
            const parentWound = wound;
            const [latestChildWoundWound, ...restChildWounds] = wound.child_wounds;
            const latestWound = latestChildWoundWound || wound;
            let phraseTemplate;
            const woundPhraseTemplateResponse = await lastValueFrom(this.woundService.getSystemDefinedPhraseTemplateByPhraseKey('woundassessmentshort')) as any;
            if(woundPhraseTemplateResponse.status === 200) {
              const woundPhraseTemplate = woundPhraseTemplateResponse.data;
              phraseTemplate = woundPhraseTemplate.template;            
            }
            if(addendum_status){
              if (note.date_obj.day == latestWound.last_assessment_date_obj.day && note.date_obj.month == latestWound.last_assessment_date_obj.month && note.date_obj.year == latestWound.last_assessment_date_obj.year) {
                if(latestWound.is_clone){
                  return woundassessmentshortPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, addendum_id: latestWound._id, should_show_wound_location: extra.should_show_wound_location});
                }else{
                  return woundassessmentshortPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, should_show_wound_location: extra.should_show_wound_location});
                }
              }else{
                ///Check child wounds
                ///Find latest child of note date obj and show popup
                const childFilterWounds = wound.child_wounds.filter((rev) => note.date_obj.day == rev.last_assessment_date_obj.day && note.date_obj.month == rev.last_assessment_date_obj.month && note.date_obj.year == rev.last_assessment_date_obj.year);
                if(childFilterWounds.length > 0){
                  if(childFilterWounds[0].is_clone){
                    return woundassessmentshortPhraseTemplate(parentWound, childFilterWounds[0], restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, addendum_id: childFilterWounds[0]._id, should_show_wound_location: extra.should_show_wound_location});
                  }else{
                    return woundassessmentshortPhraseTemplate(parentWound, childFilterWounds[0], restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, should_show_wound_location: extra.should_show_wound_location});
                  }
                }else{
                  if (note.date_obj.day == wound.last_assessment_date_obj.day && note.date_obj.month == wound.last_assessment_date_obj.month && note.date_obj.year == wound.last_assessment_date_obj.year) {
                    
                    ///Check parent wound
                    return woundassessmentshortPhraseTemplate(parentWound, wound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, should_show_wound_location: extra.should_show_wound_location});
                  }else{
                    this._toastr.error("No Wounds DOS match with Addendum DOS")
                  }
                }
              }
            }else{
              return woundassessmentshortPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, should_show_wound_location: extra.should_show_wound_location});
            }
          }
        }else if(keyWithoutMarker === 'woundassessmentshort_all@dn'){
          const {data: wounds, status} = await lastValueFrom(this.resolveWoundPhrase(undefined, note.patient._id)) as any;
          if(status == 200) { 
            let phraseTemplate;
            const woundPhraseTemplateResponse = await lastValueFrom(this.woundService.getSystemDefinedPhraseTemplateByPhraseKey('woundassessmentshort')) as any;
            if(woundPhraseTemplateResponse.status === 200) {
              const woundPhraseTemplate = woundPhraseTemplateResponse.data;
              phraseTemplate = woundPhraseTemplate.template;            
            }
            return wounds.map(wound => {
              const parentWound = wound;
              const [latestChildWoundWound, ...restChildWounds] = wound.child_wounds;
              const latestWound = latestChildWoundWound || wound;
              return woundassessmentshortPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, should_show_wound_location: extra.should_show_wound_location});
            }).join('<br /><br />')
          }
          
          
        }else if(keyWithoutMarker.match(/^nursewoundassessment_\d+\@dn$/m)) {
          const woundPhrase:any = extra.phrase;
          const wound_id = woundPhrase._id;

          // call to get wound data
          const {data: wound, status} = await lastValueFrom(this.resolveWoundPhrase(wound_id)) as any;
          if(status == 200) {
            const parentWound = wound;
            const [latestChildWoundWound, ...restChildWounds] = wound.child_wounds;
            const latestWound = latestChildWoundWound || wound;
            let phraseTemplate;
            const woundPhraseTemplateResponse = await lastValueFrom(this.woundService.getSystemDefinedPhraseTemplateByPhraseKey('nursewoundassessment')) as any;
            if(woundPhraseTemplateResponse.status === 200) {
              const woundPhraseTemplate = woundPhraseTemplateResponse.data;
              phraseTemplate = woundPhraseTemplate.template;            
            }
            return nursewoundassessmentPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, should_show_wound_location: extra.should_show_wound_location});
          }
        } else if(keyWithoutMarker === 'nursewoundassessment_all@dn') {
          const {data: wounds, status} = await lastValueFrom(this.resolveWoundPhrase(undefined, note.patient._id, false, global.USER_TYPE.SNF_WC_NURSE )) as any;
          if(status == 200) { 
            let phraseTemplate;
            const woundPhraseTemplateResponse = await lastValueFrom(this.woundService.getSystemDefinedPhraseTemplateByPhraseKey('nursewoundassessment')) as any;
            if(woundPhraseTemplateResponse.status === 200) {
              const woundPhraseTemplate = woundPhraseTemplateResponse.data;
              phraseTemplate = woundPhraseTemplate.template;            
            }
            return wounds.map(wound => {
              const parentWound = wound;
              const [latestChildWoundWound, ...restChildWounds] = wound.child_wounds;
              const latestWound = latestChildWoundWound || wound;
              return nursewoundassessmentPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates, should_show_wound_location: extra.should_show_wound_location});
            }).join('<br /><br />')
          }
        } else if (keyWithoutMarker.match(/^woundprocedure_\d+\@dn$/m) || keyWithoutMarker === 'woundprocedure_all@dn') {
          const { should_show_procedure_note, should_show_wound_location } = extra;
          const populatedTemplates = [];
          if(extra.phrase && extra.phrase._id) {
            await this.addDotphraseDate("woundprocedure",note.date_obj,extra.phrase._id).toPromise();
          }
          for await (const data of extra['resolvedProcedureNote']) {
            const result = await this.resolveProcedurePhraseTemplate(data, { should_show_procedure_note, should_show_wound_location });
            populatedTemplates.push(result);
          }
          return populatedTemplates.join('<br /><br />');
        } 
        if(extra?.phrase?.procedure_type) {
          const { template = '' } = extra.phrase;
            if(!extra.phrase.wound_id) {
              return template;
            }
            const {data: woundData, status} = await lastValueFrom(this.resolveWoundPhrase(extra.phrase.wound_id, undefined)) as any;
            if(status == 200) {
              if(extra.phrase.procedure_type.toLowerCase().includes("deb") || extra.phrase.procedure_type.toLowerCase().includes("biopsy") || extra.phrase.procedure_type.toLowerCase().includes("skin")){
                let enterPhraseResponse = await this.addDotphraseDate(extra.phrase.procedure_type,note.date_obj,extra.phrase.wound_id).toPromise();
              }
              return await applyWoundPhrases(template, woundData, this.woundPhrases.getValue());
            }
          return '';
        }

      if (keyWithoutMarker.startsWith('tag_')) {
        return `<span data-tag-id="${extra.phrase._id}">${extra.phrase.phrase_text}</span>`
      }
        switch ( keyWithoutMarker ) {
          case 'dateofie@dn':
              const payload = {
                  company_id: this.currentUser.company_id,
                  patient_id: note.patient._id
              };
              const response: any = await lastValueFrom(this._patientDetailListService.getTranscriptionsByPatientId(payload, true));
              if (response.status == 200)
                  return response.dateOfServiceOfInitialNote;
          case 'dcdate@dn': 
          case 'dcdate@matrix': 
          case 'dcdate@pcc':
              if (note.patient.last_discharge_date)
                  return moment(note.patient.last_discharge_date).format(('MM/DD/YYYY'));
              else
                  return { error: "No discharge date available" } 
          case 'notetype@dn':
                return note.title?.toUpperCase() ||  {error: "No note type available"};
            case 'facility@dn':
                return note.facility?.title.toUpperCase() || {error: "No facility selected"};
            case 'patient@dn':
                return note.patient?.name ||  {error: "No patient selected"};
          case 'ccmpatientconsent@dn':
            let res: any;
            if (!note.patient.is_patient_consent) {
              res = await lastValueFrom(this.PatientService.editPatient({ _id: note.patient._id }, { $set: { is_patient_consent: true } }));
              if (res.status == 200)
                note.patient.is_patient_consent = true;
            }
            return "Patient has multiple chronic conditions and is at a high risk for readmission. Patients will be started on care coordination post discharge. Reviewed benefits and possible cost-sharing responsibilities. Informed patient of the right to stop services. Obtained verbal consent.";
          case 'pcmpatientconsent@dn':
            let resp: any;
            if (!note.patient.is_patient_pcm_consent) {
              res = await lastValueFrom(this.PatientService.editPatient({ _id: note.patient._id }, { $set: { is_patient_pcm_consent: true } }));
              if (res.status == 200)
                note.patient.is_patient_pcm_consent = true;
            }
            return "Patient has multiple chronic conditions and is at a high risk for readmission. Patients will be started on care coordination post discharge. Reviewed benefits and possible cost-sharing responsibilities. Informed patient of the right to stop services. Obtained verbal consent.";
              
              let name = '';
              if(note.patient?.name) {
                name = note.patient?.name;
              }
              else {
                name = note.patient?.last_name + ', ' + note.patient?.first_name;
              }
              return  name || { error: "No patient selected" };
            case 'namegender@dn':
              if(note.patient) {
                const years = moment().diff(note.patient.date_of_birth, 'years');
                return  `${years} years old ${note.patient.gender.toLowerCase()}`;
              }
              return {error: "No patient selected"};

            case 'dos@dn':
                if(note.date_obj) {
                  let {year, month, day} = note.date_obj;
                  if(month < 10){
                      month = '0' + month;
                  }
                  if(day < 10){
                      day = '0' + day;
                  }
                  let str = `${month}/${day}/${year}`;
                  return str;
                } else {
                  let time_zone = 'America/Los_Angeles';
                  if (note.facility.pcc_timeZone) {
                      time_zone = note.facility.pcc_timeZone
                  }
                  else if (note.facility.timeZone) {
                      time_zone = note.facility.timeZone
                  }
                  const dos = new Date(new Date(note.dates.service).toLocaleString('en-US', { timeZone: time_zone }))
                  return moment(dos).format('M/D/YYYY');
                }
            case 'daysincedischarge@dn': {
                // days since discharge
                let dischargedDate;
                if (note.patient.last_discharge_date_obj) {
                    dischargedDate = this._commonService.dateObjToDate(note.patient.last_discharge_date_obj)
                } else if (note.patient.last_discharge_date) {
                    dischargedDate = new Date(note.patient.last_discharge_date);
                } else {
                    return {
                        error: "No discharge date available"
                    }
                }
                const days = moment().diff(dischargedDate, 'days');
                return days.toString();
            }
            case 'provider@dn':
                return `${note.provider.first_name} ${note.provider.last_name}, ${note.provider.title}`;
            case 'signature@dn':
                console.log({
                  'this.currentUser.signature_settings.image': this.currentUser.signature_settings.image
                })
            if (this.currentUser.signature_settings.mendatory) {
              if (this.currentUser.user_type == global.USER_TYPE.DOCTOR)
                return this.currentUser.signature_settings.image ? `<img src="${this.currentUser.signature_settings.image}">` : { error: "No signature available" };
              else
                return note.provider.signature_settings.image ? `<img src="${note.provider.signature_settings.image}">` : { error: "No signature available" };
            }
            else
              return "No signature available";
                
            case 'telemedconsent@dn': 
              return "<i>Consent for today's telehealth visit was obtained and visit was conducted by live video.</i>";
                case 'prevnote:cc@dn':
                case 'prevnote:chiefcomplaint@dn':
                    try {
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title.toUpperCase().match(/^(CHIEF COMPLAINT|CC)$/i)?.length > 0
                        );
                        let result = usePhraseValueOrText(section);
                        return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for chief complaint in previous note',
                      };
                    }
                
                  case 'woundhpi@dn': 
                    try {
                      // Fetch all active wounds using getWounds api 
                      const {data, status} = await lastValueFrom(this.resolveWoundPhrase(undefined, note.patient._id)) as any;
                      console.log(data)
                      if(status === 200) {
                        const activeWounds = data.filter(wound => wound.state === 'active');
                        // sort by woundNo
                        activeWounds.sort((a, b) => a.woundNo - b.woundNo);

                        return activeWounds.map((wound) => {
                          const parentWound = wound;
                          const [latestChildWoundWound, ...restChildWounds] = wound.child_wounds;
                          const latestWound = latestChildWoundWound || wound;
                          return `Wound ${wound.woundNo} ${woundLocation(wound)}, ${wound.etiolgy}${latestWound.stage.length > 0 ? `, ${latestWound.stage}` : ''}`
                        }).join('<br>')
                      }
                    } catch (error) {
                      return {
                        error: 'Something went wrong while fetching wound hpi data',
                      };
                    }
                  case 'prevnote:woundhpi@dn':
                    try {
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) => section.title.match(/^(Wound HPI)$/i) !== null
                        );
                        let result = usePhraseValueOrText(section);
                        return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for wound hpi in previous note',
                      };
                    }
                    case 'prevnote:recommendations@dn':
                      try {
                        if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                          const followUpNoteSections = note.followUpNote.data;
                          const section = followUpNoteSections.find(
                            (section) => section.title.match(/^(Recommendations)$/i) !== null
                          );
                          let result = usePhraseValueOrText(section);
                          return result;
                        }
                        throw new Error();
                      } catch (error) {
                        return {
                          error: 'No data available for wound hpi in previous note',
                        };
                      }
                    case 'prevnote:planofcare@dn':
                      try {
                        if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                          const followUpNoteSections = note.followUpNote.data;
                          const section = followUpNoteSections.find(
                            (section) => section.title.match(/^(PLAN OF CARE)$/i) !== null
                          );
                          let result = usePhraseValueOrText(section);
                          return result;
                        }
                        throw new Error();
                      } catch (error) {
                        return {
                          error: 'No data available for wound hpi in previous note',
                        };
                      }
                    case 'prevnote:riskfactors@dn':
                      try {
                        if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                          const followUpNoteSections = note.followUpNote.data;
                          const section = followUpNoteSections.find(
                            (section) => section.title.match(/^(RISK FACTORS)$/i) !== null
                          );
                          let result = usePhraseValueOrText(section);
                          return result;
                        }
                        throw new Error();
                      } catch (error) {
                        return {
                          error: 'No data available for wound hpi in previous note',
                        };
                      }
                    case 'prevnote:hpi@dn':
                      try {
                        if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                          const followUpNoteSections = note.followUpNote.data;
                          const section = followUpNoteSections.find(
                            (section) => section.title.match(/^(HPI)$/i) !== null
                          );
                          let result = usePhraseValueOrText(section);
                          return result;
                        }
                        throw new Error();
                      } catch (error) {
                        return {
                          error: 'No data available for hpi in previous note',
                        };
                      }
                  
                  case 'prevnote:plof@dn':
                    try {
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title
                              .toUpperCase()
                              .indexOf('PLOF') >= 0
                        );
                        let result = usePhraseValueOrText(section);
                        return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error:
                          'No data available for prior level of function (plof) in previous note',
                      };
                    }
                
                  case 'prevnote:ros@dn':
                    try {
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) => section.title.match(/^(REVIEW\sOF\sSYSTEMS\s\(ROS\))$/i) !== null
                        );
                        
                                let result = usePhraseValueOrText(section);
                                return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for review of systems (ros) in previous note',
                      };
                    }
                
                  case 'prevnote:pmh@dn':
                  case 'prevnote:pmhx@dn':
                  case 'prevnote:pastmedicalhistory@dn':
                    try {
                      // PAST MEDICAL HISTORY
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title.match(/^(PAST\sMEDICAL\sHISTORY|PMH|PMHX)$/i) !== null
                        );
                        
                                let result = usePhraseValueOrText(section);
                                return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for past medical history in previous note',
                      };
                    }
                
                  case 'prevnote:psh@dn':
                  case 'prevnote:pastsurgicalhistory@dn':
                    try {
                      // PAST SURGICAL HISTORY
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title.match(/^(PAST\sSURGICAL\sHISTORY|PSH)$/i) !== null
                        );
                        
                                let result = usePhraseValueOrText(section);
                                return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for past surgical history in previous note',
                      };
                    }
                  case 'prevnote:pmsh@dn':
                  case 'prevnote:pastmedicalsurgicalhistory@dn':
                    try {
                      // PAST MEDICAL/SURGICAL HISTORY
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) => {
                            const res = section.title
                            .match(/^(PAST\sMEDICAL\/SURGICAL\sHISTORY)$/i) !== null
                            if(section.title.includes('PAST')) 
                            return res;
                          }
                              // .toUpperCase()
                              // .indexOf('PAST MEDICAL/SURGICAL HISTORY') >= 0
                        );
                        
                                let result = usePhraseValueOrText(section);
                                return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error:
                          'No data available for past medical/surgical history in previous note',
                      };
                    }
                  case 'prevnote:fh@dn':
                  case 'prevnote:familyhistory@dn':
                    try {
                      // FAMILY HISTORY
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title.match(/^(FAMILY\sHISTORY)$/i) !== null
                        );
                        
                                let result = usePhraseValueOrText(section);
                                return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for family history in previous note',
                      };
                    }
                  case 'prevnote:sh@dn':
                  case 'prevnote:socialhistory@dn':
                    try {
                      // SOCIAL HISTORY
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title.match(/^(SOCIAL\sHISTORY)$/i) !== null
                        );
                        
                                let result = usePhraseValueOrText(section);
                                return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for social history in previous note',
                      };
                    }
                  case 'prevnote:cm@dn':
                  case 'prevnote:currentmedications@dn':
                    try {
                      
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            {
                              console.log(section)
                              return section.title.match(/^(CURRENT\sMEDICATIONS)$/i) !== null
                            }
                        );
                        
                                let result = usePhraseValueOrText(section);
                                return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for current medications in previous note',
                      };
                    }
                  case 'prevnote:allergies@dn':
                    try {
                      // ALLERGIES
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) => section.title.match(/^(ALLERGIES)$/i) !== null
                        );
                        
                                let result = usePhraseValueOrText(section);
                                return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for allergies in previous note',
                      };
                    }
                
                  case 'prevnote:pe@dn':
                  case 'prevnote:physicalexamination@dn':
                    try {
                      // PHYSICAL EXAMINATION
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) => section.title.match(/^(PHYSICAL\sEXAMINATION)$/i) !== null
                        );
                        
                                let result = usePhraseValueOrText(section);
                                return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for physical examinations in previous note',
                      };
                    }
                  case 'prevnote:data@dn':
                    try {
                      // DATA/IMAGING/LABS
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title.toUpperCase().match(/^(DATA|IMAGING|LABS)$/)?.length > 0
                        );
                        
                                let result = usePhraseValueOrText(section);
                                return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for Data/Imaging/Labs in previous note',
                      };
                    }
                  case 'prevnote:imaging@dn':
                    try {
                      // IMAGING
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title.toUpperCase().match(/^(IMAGING)$/)?.length > 0
                        );
                        
                                let result = usePhraseValueOrText(section);
                                return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for Imaging in previous note',
                      };
                    }
                  case 'prevnote:labs@dn':
                    try {
                      // LABS
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title.toUpperCase().match(/^(LABS)$/)?.length > 0
                        );
                        
                                let result = usePhraseValueOrText(section);
                                return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for Labs in previous note',
                      };
                    }
                  case 'prevnote:assessment@dn':
                    try {
                      // ASSESSMENT/IMPRESSION
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title.toUpperCase().match(/^(ASSESSMENT|IMPRESSION)$/)?.length >
                            0
                        );
                        
                                let result = usePhraseValueOrText(section);
                                return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for assessment in previous note',
                      };
                    }
                  case 'prevnote:impression@dn':
                    try {
                      // IMPRESSION
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title.toUpperCase().match(/^(IMPRESSION)$/)?.length >
                            0
                        );
                        
                                let result = usePhraseValueOrText(section);
                                return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for impression in previous note',
                      };
                    }
                  case 'prevnote:plan@dn':
                    try {
                      // PLAN/ASSESSMENT/PLAN/IMPRESSION/PLAN
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title.trim()
                              .toUpperCase()
                              .match(/^(ASSESSMENT\/PLAN|IMPRESSION\/PLAN|PLAN\/IMPRESSION|PLAN)$/)?.length > 0
                        );
                        let result = usePhraseValueOrText(section);
                        return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for plan in previous note',
                      };
                    }
                  case 'prevnote:assessmentplan@dn':
                    try {
                      // ASSESSMENT/PLAN
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title.trim()
                              .toUpperCase()
                              .match(/^(ASSESSMENT\/PLAN)$/)?.length > 0
                        );
                        let result = usePhraseValueOrText(section);
                        return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for assessment plan in previous note',
                      };
                    }
                  case 'prevnote:impressionplan@dn':
                    try {
                      // IMPRESSION/PLAN
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title.trim()
                              .toUpperCase()
                              .match(/^(IMPRESSION\/PLAN|PLAN\/IMPRESSION)$/)?.length > 0
                        );
                        let result = usePhraseValueOrText(section);
                        return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for impression plan in previous note',
                      };
                    }
                  case 'prevnote:precautions@dn':
                    try {
                      // PRECAUTIONS
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title
                              .toUpperCase()
                              .match('PRECAUTIONS')?.length > 0
                        );
                        let result = usePhraseValueOrText(section);
                        return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for precautions in previous note',
                      };
                    }
                  case 'prevnote:hc@dn':
                  case 'prevnote:hospitalcourse@dn':
                    try {
                       // HOSPITAL COURSE
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title
                              .toUpperCase()
                              .match('HOSPITAL COURSE')?.length > 0
                        );
                        
                                let result = usePhraseValueOrText(section);
                                return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for hospital course in previous note',
                      };
                    }
                  case 'prevnote:ih@dn':
                  case 'prevnote:intervalhistory@dn':
                    try {
                          // INTERVAL HISTORY
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title
                              .toUpperCase()
                              .match('INTERVAL HISTORY')?.length > 0
                        );
                        
                                let result = usePhraseValueOrText(section);
                                return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for interval history in previous note',
                      };
                    }
                  case 'prevnote:clof@dn':
                    try {
                          // CURRENT LEVEL OF FUNCTION
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title
                              .toUpperCase()
                              .match('CURRENT LEVEL OF FUNCTION')?.length > 0
                        );
                        
                                let result = usePhraseValueOrText(section);
                                return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for current level of function in previous note',
                      };
                    }
                  case 'prevnote:rehabdiagnosis@dn':
                  case 'prevnote:rehabilitationdiagnosis@dn':
                    try {
                      // REHABILITATION DIAGNOSIS
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title
                              .toUpperCase()
                              .match('REHABILITATION DIAGNOSIS')?.length > 0
                        );
                        
                                let result = usePhraseValueOrText(section);
                                return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for rehabilitation diagnosis in previous note',
                      };
                    }
                  case 'prevnote:rehabassessment@dn':
                    try {
                          // REHABILITATION ASSESSMENT
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title
                              .toUpperCase()
                              .match('REHABILITATION ASSESSMENT')?.length > 0
                        );
                        
                                let result = usePhraseValueOrText(section);
                                return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for rehabilitation assessment in previous note',
                      };
                    }
                  case 'prevnote:rehabplan@dn':
                    try {
                          // REHABILITATION ASSESSMENT/PLAN
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title
                              .toUpperCase()
                              .match(/^(REHABILITATION (ASSESSMENT\/PLAN|PLAN))$/)?.length > 0
                        );
                        
                                let result = usePhraseValueOrText(section);
                                return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for rehabilitation plan in previous note',
                      };
                    }
                  case 'prevnote:rehabilitationassmentplan@dn':
                    try {
                      // REHABILITATION ASSESSMENT/PLAN
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title
                              .toUpperCase()
                              .match(/^(REHABILITATION ASSESSMENT\/PLAN)$/)?.length > 0
                        );
                        
                                let result = usePhraseValueOrText(section);
                                return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for rehabilitation assessment plan in previous note',
                      };
                    }
                  case 'prevnote:rehabilitationplan@dn':
                    try {
                      // REHABILITATION PLAN
                      if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                        const followUpNoteSections = note.followUpNote.data;
                        const section = followUpNoteSections.find(
                          (section) =>
                            section.title
                              .toUpperCase()
                              .match(/^(REHABILITATION PLAN)$/)?.length > 0
                        );
                        
                                let result = usePhraseValueOrText(section);
                                return result;
                      }
                      throw new Error();
                    } catch (error) {
                      return {
                        error: 'No data available for rehabilitation plan in previous note',
                      };
                    }
                    case 'prevnote:medicaldecisionmaking@dn':
                    case 'prevnote:mdm@dn':
                      try {
                        if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                          const followUpNoteSections = note.followUpNote.data;
                          const section = followUpNoteSections.find(
                            (section) =>
                              section.title
                                .toUpperCase()
                                .match(/^(MEDICAL\sDECISION\sMAKING)$/)?.length > 0
                          );
                          
                                let result = usePhraseValueOrText(section);
                                return result;
                        }
                        throw new Error();
                      } catch (error) {
                        return {
                          error: 'No data available for medical decision making in previous note',
                        };
                      }
                      case 'prevnote:patienteducation@dn':
                        try {
                          if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                            const followUpNoteSections = note.followUpNote.data;
                            const section = followUpNoteSections.find(
                              (section) =>
                                section.title
                                  .toUpperCase()
                                  .match(/^(PATIENT\sEDUCATION)$/i)?.length > 0
                            );
                            
                                let result = usePhraseValueOrText(section);
                                return result;
                          }
                          throw new Error();
                        } catch (error) {
                          return {
                            error: 'No data available for patient education in previous note',
                          };
                        }
                        case 'prevnote:cardiologyrecommenations@dn':
                          try {
                            if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                              const followUpNoteSections = note.followUpNote.data;
                              const section = followUpNoteSections.find(
                                (section) =>
                                  section.title
                                    .toUpperCase()
                                    .match(/^(CARDIOLOGY\sRECOMMENDATIONS)$/)?.length > 0
                              );
                              
                                let result = usePhraseValueOrText(section);
                                return result;
                            }
                            throw new Error();
                          } catch (error) {
                            return {
                              error: 'No data available for cardiology recommendations in previous note',
                            };
                          }
                          case 'prevnote:goalsofcare@dn':
                            try {
                              if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                                const followUpNoteSections = note.followUpNote.data;
                                const section = followUpNoteSections.find(
                                  (section) =>
                                    section.title
                                      .toUpperCase()
                                      .match(/^(GOALS?\sOF\sCARE)$/)?.length > 0
                                );
                                
                                let result = usePhraseValueOrText(section);
                                return result;
                              }
                              throw new Error();
                            } catch (error) {
                              return {
                                error: 'No data available for goal of care in previous note',
                              };
                            }
                          
                            case 'prevnote:preventative_measures@dn':
                              try {
                                if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                                  const followUpNoteSections = note.followUpNote.data;
                                  const section = followUpNoteSections.find(
                                    (section) =>
                                      section.title
                                        .toUpperCase()
                                        .match(/^(PREVENTATIVE\sMEASURES)$/)?.length > 0
                                  );
                                  
                                  let result = usePhraseValueOrText(section);
                                  return result;
                                }
                                throw new Error();
                              } catch (error) {
                                return {
                                  error: 'No data available for preventative measures in previous note',
                                };
                              }
                          
                            case 'prevnote:new_recommendations@dn':
                              try {
                                if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                                  const followUpNoteSections = note.followUpNote.data;
                                  const section = followUpNoteSections.find(
                                    (section) =>
                                      section.title
                                        .toUpperCase()
                                        .match(/^(NEW\sRECOMMENDATIONS)$/)?.length > 0
                                  );
                                  
                                  let result = usePhraseValueOrText(section);
                                  return result;
                                }
                                throw new Error();
                              } catch (error) {
                                return {
                                  error: 'No data available for prevententive measures in previous note',
                                };
                              }
                            
                          case 'prevnote:medications@dn':
                            try {
                              if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                                const followUpNoteSections = note.followUpNote.data;
                                const section = followUpNoteSections.find(
                                  (section) =>
                                    section.title
                                      .toUpperCase()
                                      .match(/^(MEDICATIONS)$/)?.length > 0
                                );
                                
                                let result = usePhraseValueOrText(section);
                                return result;
                              }
                              throw new Error();
                            } catch (error) {
                              return {
                                error: 'No data available for medications in previous note',
                              };
                            }
                          
                          case 'prevnote:additionalrecommendations@dn':
                            try {
                              if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                                const followUpNoteSections = note.followUpNote.data;
                                const section = followUpNoteSections.find(
                                  (section) =>
                                    section.title
                                      .toUpperCase()
                                      .match(/^(ADDITIONAL\sRECOMMENDATIONS)$/)?.length > 0
                                );
                                
                                let result = usePhraseValueOrText(section);
                                return result;
                              }
                              throw new Error();
                            } catch (error) {
                              return {
                                error: 'No data available for additional recommendations in previous note',
                              };
                            }
                            case 'prevnote:dischargeplanning@dn':
                              try {
                                if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                                  const followUpNoteSections = note.followUpNote.data;
                                  const section = followUpNoteSections.find(
                                    (section) =>
                                      section.title
                                        .toUpperCase()
                                        .match(/^(DISCHARGE\sPLANNING)$/)?.length > 0
                                  );
                                  
                                let result = usePhraseValueOrText(section);
                                return result;
                                }
                                throw new Error();
                              } catch (error) {
                                return {
                                  error: 'No data available for discharge planning in previous note',
                                };
                              }
                            case 'prevnote:woundassessment@dn':
                              try {
                                
                                const followUpWoundsResponse = await lastValueFrom(this.noteEditorService.getFollowUpWoundsAsJson(note.patient._id, note.provider._id, note._id)) as any;
                                if(followUpWoundsResponse.status !== 200) throw new Error('Error getting follow up wounds');

                                  const section: {
                                    title: string;
                                    wounds: {[key: string]: any}[];
                                  } = followUpWoundsResponse.data.sections.find(
                                    (section) =>
                                      section.title
                                        .toUpperCase()
                                        === '{WOUND_ASSESSMENT}'
                                  );

                                  const prevWoundIds = [];
                                  const activeWoundIds = [];
                                  for (const woundId in section.wounds) {
                                    if (Object.prototype.hasOwnProperty.call(section.wounds, woundId)) {
                                      const woundHTML = section.wounds[woundId].html;
                                      if(woundHTML) {
                                        prevWoundIds.push(woundId);
                                      } else {
                                        activeWoundIds.push(woundId);
                                      }
                                    }
                                  }
                                  
                                    if(activeWoundIds.length > 0) {
                                      let phraseTemplate;
                                      const woundPhraseTemplateResponse = await lastValueFrom(this.woundService.getSystemDefinedPhraseTemplateByPhraseKey('woundassessment')) as any;
                                      if(woundPhraseTemplateResponse.status === 200) {
                                        const woundPhraseTemplate = woundPhraseTemplateResponse.data;
                                        phraseTemplate = woundPhraseTemplate.template;            
                                      }
                                      // call to get wound data
                                      const woundPhraseResponse = await lastValueFrom(this.resolveWoundPhrase(undefined, note.patient._id)) as any;
                                      if(woundPhraseResponse.status == 200) {
                                        const activeWounds = woundPhraseResponse.data.filter(wound => activeWoundIds.includes(wound._id) && wound.state === 'active');
                                        for (const activeWoundId of activeWoundIds) {
                                          const wound = activeWounds.find(wound => wound._id === activeWoundId);
                                          if(wound && section.wounds[wound._id]) {
                                            const parentWound = wound;
                                            const [latestChildWoundWound, ...restChildWounds] = wound.child_wounds;
                                            const latestWound = latestChildWoundWound || wound;
                                            const woundHTML = woundAssessmentPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates});
                                            section.wounds[wound._id].html = woundHTML;
                                          }
                                        }
                                      }
                                    }

                                    debugger;
                                    console.log(section.wounds)
                                    const woundsHTML = Object.values(section.wounds).filter(e => e?.html)
                                    .sort((a, b) => {
                                      let aWoundNo, bWoundNo;
                                      if(a.html) {
                                        const tmp = document.createElement('div');
                                        tmp.innerHTML = a.html;
                                        aWoundNo = parseInt(tmp.querySelector('[data-wound-field="woundno"]')?.textContent);
                                        if(isNaN(aWoundNo)) aWoundNo = 0;
                                      } else {
                                        aWoundNo = a.wound.woundNo;
                                      }
                                      if(b.html) {
                                        const tmp = document.createElement('div');
                                        tmp.innerHTML = b.html;
                                        bWoundNo = parseInt(tmp.querySelector('[data-wound-field="woundno"]')?.textContent);
                                        if(isNaN(bWoundNo)) bWoundNo = 0;
                                      } else {
                                        bWoundNo = b.wound.woundNo;
                                      }
                                      return aWoundNo - bWoundNo
                                    }
                                    )
                                    .map((wound:any) => wound.html);

                                    let result = woundsHTML.join('<br><br>')

                                    return result;
                              } catch (error) {
                                console.log(error)
                                return {
                                  error: 'No data available for wound assessment in previous note',
                                };
                              }
                            case 'prevnote:woundplan@dn':
                              try {
                                
                                const followUpWoundsResponse = await lastValueFrom(this.noteEditorService.getFollowUpWoundsAsJson(note.patient._id, note.provider._id, note._id)) as any;
                                if(followUpWoundsResponse.status !== 200) throw new Error('Error getting follow up wounds');

                                  const section: {
                                    title: string;
                                    wounds: {[key: string]: any}[];
                                  } = followUpWoundsResponse.data.sections.find(
                                    (section) =>
                                      section.title
                                        .toUpperCase()
                                        === '{WOUND_PLAN}'
                                  );

                                  const prevWoundIds = [];
                                  const activeWoundIds = [];
                                  for (const woundId in section.wounds) {
                                    if (Object.prototype.hasOwnProperty.call(section.wounds, woundId)) {
                                      const woundHTML = section.wounds[woundId].html;
                                      if(woundHTML) {
                                        prevWoundIds.push(woundId);
                                      } else {
                                        activeWoundIds.push(woundId);
                                      }
                                    }
                                  }
                                  
                                    if(activeWoundIds.length > 0) {
                                      let phraseTemplate;
                                      const woundPhraseTemplateResponse = await lastValueFrom(this.woundService.getSystemDefinedPhraseTemplateByPhraseKey('woundplan')) as any;
                                      if(woundPhraseTemplateResponse.status === 200) {
                                        const woundPhraseTemplate = woundPhraseTemplateResponse.data;
                                        phraseTemplate = woundPhraseTemplate.template;            
                                      }
                                      // call to get wound data
                                      const woundPhraseResponse = await lastValueFrom(this.resolveWoundPhrase(undefined, note.patient._id)) as any;
                                      if(woundPhraseResponse.status == 200) {
                                        const activeWounds = woundPhraseResponse.data.filter(wound => activeWoundIds.includes(wound._id) && wound.state === 'active');
                                        for (const activeWoundId of activeWoundIds) {
                                          const wound = activeWounds.find(wound => wound._id === activeWoundId);
                                          if(wound && section.wounds[wound._id]) {
                                            const parentWound = wound;
                                            const [latestChildWoundWound, ...restChildWounds] = wound.child_wounds;
                                            const latestWound = latestChildWoundWound || wound;
                                            const woundHTML = woundPlanPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate, {woundDropdownTemplates: this.woundDropdownTemplates});
                                            section.wounds[wound._id].html = woundHTML;
                                          }
                                        }
                                      }
                                    }

                                    debugger;
                                    console.log(section.wounds)
                                    const woundsHTML = Object.values(section.wounds).filter(e => e?.html)
                                    .sort((a, b) => {
                                      let aWoundNo, bWoundNo;
                                      if(a.html) {
                                        const tmp = document.createElement('div');
                                        tmp.innerHTML = a.html;
                                        aWoundNo = parseInt(tmp.querySelector('[data-wound-field="woundno"]')?.textContent);
                                        if(isNaN(aWoundNo)) aWoundNo = 0;
                                      } else {
                                        aWoundNo = a.wound.woundNo;
                                      }
                                      if(b.html) {
                                        const tmp = document.createElement('div');
                                        tmp.innerHTML = b.html;
                                        bWoundNo = parseInt(tmp.querySelector('[data-wound-field="woundno"]')?.textContent);
                                        if(isNaN(bWoundNo)) bWoundNo = 0;
                                      } else {
                                        bWoundNo = b.wound.woundNo;
                                      }
                                      return aWoundNo - bWoundNo
                                    }
                                    )
                                    .map((wound:any) => wound.html);

                                    let result = woundsHTML.join('<br><br>')

                                    return result;
                              } catch (error) {
                                console.log(error)
                                return {
                                  error: 'No data available for wound plan in previous note',
                                };
                              }
                    case 'prevnote:reviewofrecords@dn':
                      try {
                        if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                          const followUpNoteSections = note.followUpNote.data;
                          const section = followUpNoteSections.find(
                            (section) =>
                              section.title
                                .toUpperCase()
                                .match(/^(REVIEW\sOF\sRECORDS)$/)?.length > 0
                          );
                          
                          let result = usePhraseValueOrText(section);
                          return result;
                        }
                        throw new Error();
                      } catch (error) {
                        return {
                          error: 'No data available for review of records in previous note',
                        };
                      }
                    case 'prevnote:barrierstodischarge@dn':
                      try {
                        if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                          const followUpNoteSections = note.followUpNote.data;
                          const section = followUpNoteSections.find(
                            (section) =>
                              section.title
                                .toUpperCase()
                                .match(/^(BARRIERS\sTO\sDISCHARGE)$/)?.length > 0
                          );
                          
                          let result = usePhraseValueOrText(section);
                          return result;
                        }
                        throw new Error();
                      } catch (error) {
                        return {
                          error: 'No data available for barriers of discharge in previous note',
                        };
                      }
                    case 'prevnote:rehabpotential@dn':
                      try {
                        if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                          const followUpNoteSections = note.followUpNote.data;
                          const section = followUpNoteSections.find(
                            (section) =>
                              section.title
                                .toUpperCase()
                                .match(/^(REHAB\sPOTENTIAL)$/)?.length > 0
                          );
                          
                          let result = usePhraseValueOrText(section);
                          return result;
                        }
                        throw new Error();
                      } catch (error) {
                        return {
                          error: 'No data available for rehab potential in previous note',
                        };
                      }
                    case 'prevnote:dischargedisposition@dn':
                      try {
                        if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                          const followUpNoteSections = note.followUpNote.data;
                          const section = followUpNoteSections.find(
                            (section) =>
                              section.title
                                .toUpperCase()
                                .match(/^(DISCHARGE\sDISPOSITION)$/)?.length > 0
                          );
                          
                          let result = usePhraseValueOrText(section);
                          return result;
                        }
                        throw new Error();
                      } catch (error) {
                        return {
                          error: 'No data available for discharge disposition in previous note',
                        };
                      }
                    case 'prevnote:smokingcessation@dn':
                      try {
                        if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                          const followUpNoteSections = note.followUpNote.data;
                          const section = followUpNoteSections.find(
                            (section) =>
                              section.title
                                .toUpperCase()
                                .match(/^(SMOKING\sCESSATION)$/)?.length > 0
                          );
                          
                          let result = usePhraseValueOrText(section);
                          return result;
                        }
                        throw new Error();
                      } catch (error) {
                        return {
                          error: 'No data available for smoking cessation in previous note',
                        };
                      }
                    case 'prevnote:codestatus@dn':
                      try {
                        if (note.followUpNote && Array.isArray(note.followUpNote.data)) {
                          const followUpNoteSections = note.followUpNote.data;
                          const section = followUpNoteSections.find(
                            (section) =>
                              section.title
                                .toUpperCase()
                                .match(/^(CODE\sSTATUS)$/)?.length > 0
                          );
                          
                          let result = usePhraseValueOrText(section);
                          return result;
                        }
                        throw new Error();
                      } catch (error) {
                        return {
                          error: 'No data available for code status in previous note',
                        };
                      }
                  case 'rehabupdate:ot@dn':
                    try {
                      const patientName = this._commonService.getPatientFullNameFormat2(note.patient);
                      const rehabOtResponse: any = await this._rehabReportService.getGoalStatusReport(patientName, note.dates.service, 'ot').toPromise();
                      if(rehabOtResponse.status === 200 && rehabOtResponse.data) {
                        const data = rehabOtResponse.data;
                        const otPairs = [];
                        for (const ot of data.ot) {
                            for (const otPair of ot.pairs) {
                                let {key, value} =  otPair;
                                key = key.split('_').reduce((acc, curr) => {
                                    if(curr.length === 2) {
                                        curr= curr.toUpperCase();
                                    } else {
                                        curr = curr[0].toUpperCase() + curr.slice(1)
                                    }
                                    return `${acc} ${curr}`.trim()
                                }, ''); 
                                otPairs.push(`${moment(ot.data).format('M/D/YYYY')}: ${key} x ${value}`)
                            }
                        }
                        return otPairs.join('<br />');
                      }
                      return {
                        error: 'No data available for rehab update ot',
                      };
                    } catch (error) {
                      return {
                        error: 'Error while fetching data for rehab update ot',
                      };
                    }
                  case 'rehabupdate:pt@dn':
                    try {
                      const patientName = this._commonService.getPatientFullNameFormat2(note.patient);
                      const rehabPtResponse: any = await this._rehabReportService.getGoalStatusReport(patientName, note.dates.service, 'pt').toPromise();
                      if(rehabPtResponse.status === 200 && rehabPtResponse.data) {
                        const data = rehabPtResponse.data;
                        const ptPairs = [];
                        for (const pt of data.pt) {
                            for (const ptPair of pt.pairs) {
                                let {key, value} =  ptPair;
                                key = key.split('_').reduce((acc, curr) => {
                                    if(curr.length === 2) {
                                        curr= curr.toUpperCase();
                                    } else {
                                        curr = curr[0].toUpperCase() + curr.slice(1)
                                    }
                                    return `${acc} ${curr}`.trim()
                                }, ''); 
                                ptPairs.push(`${moment(pt.data).format('M/D/YYYY')} ${key} x ${value}`)
                            }
                        }
                        return ptPairs.join('<br />');
                      }
                      return {
                        error: 'No data available for rehab update pt',
                      };
                    } catch (error) {
                      return {
                        error: 'Error while fetching data for rehab update pt',
                      };
                    }
            case 'daily_pt_precautions@dn':
                try {
                    const patientName = this._commonService.getPatientFullNameFormat2(note.patient);
                    const patientTensReport: any = await this._therapyService.getPatientTensReport(patientName, 'ptPrecautions').toPromise();
                    if (patientTensReport.status === 200) {
                        const data = patientTensReport.data;
                        return data ? data : 'No data available for pt precautions';
                    }
                    return {
                        error: 'No data available for pt precautions',
                    };
                } catch (error) {
                    return {
                        error: 'Error while fetching data for pt precautions',
                    };
                }

            case 'daily_pt_updates@dn':
                try {
                    const patientName = this._commonService.getPatientFullNameFormat2(note.patient);
                    const patientTensReport: any = await this._therapyService.getPatientTensReport(patientName, 'ptUpdates').toPromise();
                    if (patientTensReport.status === 200) {
                        const data = patientTensReport.data;
                        return data ? data : 'No data available for pt updates';
                    }
                    return {
                        error: 'No data available for pt updates',
                    };
                } catch (error) {
                    return {
                        error: 'Error while fetching data for pt updates',
                    };
                }

                
            case 'daily_ot_precautions@dn':
              try {
                  const patientName = this._commonService.getPatientFullNameFormat2(note.patient);
                  const patientTensReport: any = await this._therapyService.getPatientTensReport(patientName, 'otPrecautions').toPromise();
                  if (patientTensReport.status === 200) {
                      const data = patientTensReport.data;
                      return data ? data : 'No data available for ot precautions';
                  }
                  return {
                      error: 'No data available for pt precautions',
                  };
              } catch (error) {
                  return {
                      error: 'Error while fetching data for ot precautions',
                  };
              }
              case 'daily_ot_updates@dn':
                  try {
                      const patientName = this._commonService.getPatientFullNameFormat2(note.patient);
                      const patientTensReport: any = await this._therapyService.getPatientTensReport(patientName, 'otUpdates').toPromise();
                      if (patientTensReport.status === 200) {
                          const data = patientTensReport.data;
                          return data ? data : 'No data available for ot updates';
                      }
                      return {
                          error: 'No data available for ot updates',
                      };
                  } catch (error) {
                      return {
                          error: 'Error while fetching data for pt updates',
                      };
                  }
                  
            case 'daily_pt_clof@dn':
              try {
                  const patientName = this._commonService.getPatientFullNameFormat2(note.patient);
                  const patientTensReport: any = await this._therapyService.getPatientTensReport(patientName, 'ptFunction').toPromise();
                  if (patientTensReport.status === 200) {
                      const data = patientTensReport.data;
                      return data ? data : 'No data available for pt updates';
                  }
                  return {
                      error: 'No data available for pt updates',
                  };
              } catch (error) {
                  return {
                      error: 'Error while fetching data for pt updates',
                  };
              }
              
            case 'daily_ot_clof@dn':
              try {
                  const patientName = this._commonService.getPatientFullNameFormat2(note.patient);
                  const patientTensReport: any = await this._therapyService.getPatientTensReport(patientName, 'otFunction').toPromise();
                  if (patientTensReport.status === 200) {
                      const data = patientTensReport.data;
                      return data ? data : 'No data available for pt updates';
                  }
                  return {
                      error: 'No data available for pt updates',
                  };
              } catch (error) {
                  return {
                      error: 'Error while fetching data for pt updates',
                  };
              }
              
            case 'goalstatus_therapy_update_pt@dn':
              try {
                  const patientName = this._commonService.getPatientFullNameFormat2(note.patient);
                  const patientTensReport: any = await this._therapyService.getGoalStatusReport(patientName, 'therapy_update_pt_goalstatus').toPromise();
                  if (patientTensReport.status === 200) {
                      const data = patientTensReport.data;
                      return data ? data : 'No data available for pt updates';
                  }
                  return {
                      error: 'No data available for pt updates',
                  };
              } catch (error) {
                  return {
                      error: 'Error while fetching data for pt updates',
                  };
              }
              
            case 'goalstatus_therapy_update_ot@dn':
                try {
                    const patientName = this._commonService.getPatientFullNameFormat2(note.patient);
                    const patientTensReport: any = await this._therapyService.getGoalStatusReport(patientName, 'therapy_update_ot_goalstatus').toPromise();
                    if (patientTensReport.status === 200) {
                        const data = patientTensReport.data;
                        return data ? data : 'No data available for pt updates';
                    }
                    return {
                        error: 'No data available for pt updates',
                    };
                } catch (error) {
                    return {
                        error: 'Error while fetching data for pt updates',
                    };
                }

            case 'weekly_therapy_update_pt@dn':
                try {
                    const patientName = this._commonService.getPatientFullNameFormat2(note.patient);
                    const patientTensReport: any = await this._therapyService.getWeeklyFunctionalStatusReport(patientName, 'therapy_update_pt_weekly').toPromise();
                    if (patientTensReport.status === 200) {
                        const data = patientTensReport.data;
                        return data ? data : 'No data available for pt updates';
                    }
                    return {
                        error: 'No data available for pt updates',
                    };
                } catch (error) {
                    return {
                        error: 'Error while fetching data for pt updates',
                    };
                }
            case 'weekly_therapy_update_ot@dn':
                try {
                    const patientName = this._commonService.getPatientFullNameFormat2(note.patient);
                    const patientTensReport: any = await this._therapyService.getWeeklyFunctionalStatusReport(patientName, 'therapy_update_ot_weekly').toPromise();
                    if (patientTensReport.status === 200) {
                        const data = patientTensReport.data;
                        return data ? data : 'No data available for pt updates';
                    }
                    return {
                        error: 'No data available for pt updates',
                    };
                } catch (error) {
                    return {
                        error: 'Error while fetching data for pt updates',
                    };
                }


            case 'eval_ot_plof@dn':
                try {
                    const patientName = this._commonService.getPatientFullNameFormat2(note.patient);
                    const patientTensReport: any = await this._therapyService.getPatientEvalReport(patientName, 'ot_plof_eval').toPromise();
                    if (patientTensReport.status === 200) {
                        const data = patientTensReport.data;
                        return data ? data : 'No data available for ot plof';
                    }
                    return {
                        error: 'No data available for ot plof',
                    };
                } catch (error) {
                    return {
                        error: 'Error while fetching data for ot plof',
                    };
                }
            case 'eval_ot_hpi@dn':
                try {
                    const patientName = this._commonService.getPatientFullNameFormat2(note.patient);
                    const patientTensReport: any = await this._therapyService.getPatientEvalReport(patientName, 'ot_hpi_eval').toPromise();
                    if (patientTensReport.status === 200) {
                        const data = patientTensReport.data;
                        return data ? data : 'No data available for ot hpi';
                    }
                    return {
                        error: 'No data available for ot hpi',
                    };
                } catch (error) {
                    return {
                        error: 'Error while fetching data for ot hpi',
                    };
                }
            case 'eval_pt_hpi@dn':
                try {
                    const patientName = this._commonService.getPatientFullNameFormat2(note.patient);
                    const patientTensReport: any = await this._therapyService.getPatientEvalReport(patientName, 'pt_hpi_eval').toPromise();
                    if (patientTensReport.status === 200) {
                        const data = patientTensReport.data;
                        return data ? data : 'No data available for pt hpi';
                    }
                    return {
                        error: 'No data available for pt hpi',
                    };
                } catch (error) {
                    return {
                        error: 'Error while fetching data for pt hpi',
                    };
                }
            case 'eval_ot_clof@dn':
                try {
                    const patientName = this._commonService.getPatientFullNameFormat2(note.patient);
                    const patientTensReport: any = await this._therapyService.getPatientEvalReport(patientName, 'ot_clof_eval').toPromise();
                    if (patientTensReport.status === 200) {
                        const data = patientTensReport.data;
                        return data ? data : 'No data available for ot clof';
                    }
                    return {
                        error: 'No data available for ot clof',
                    };
                } catch (error) {
                    return {
                        error: 'Error while fetching data for ot clof',
                    };
                }
            case 'eval_pt_clof@dn':
                try {
                    const patientName = this._commonService.getPatientFullNameFormat2(note.patient);
                    const patientTensReport: any = await this._therapyService.getPatientEvalReport(patientName, 'pt_clof_eval').toPromise();
                    if (patientTensReport.status === 200) {
                        const data = patientTensReport.data;
                        return data ? data : 'No data available for ot clof';
                    }
                    return {
                        error: 'No data available for ot clof',
                    };
                } catch (error) {
                    return {
                        error: 'Error while fetching data for ot clof',
                    };
                }
            case 'eval_pt_plof@dn':
                try {
                    const patientName = this._commonService.getPatientFullNameFormat2(note.patient);
                    const patientTensReport: any = await this._therapyService.getPatientEvalReport(patientName, 'pt_plof_eval').toPromise();
                    if (patientTensReport.status === 200) {
                        const data = patientTensReport.data;
                        return data ? data : 'No data available for ot plof';
                    }
                    return {
                        error: 'No data available for ot plof',
                    };
                } catch (error) {
                    return {
                        error: 'Error while fetching data for ot plof',
                    };
                }
            case 'eval_pt_priorliving@dn':
                try {
                    const patientName = this._commonService.getPatientFullNameFormat2(note.patient);
                    const patientTensReport: any = await this._therapyService.getPatientEvalReport(patientName, 'pt_priorliving_eval').toPromise();
                    if (patientTensReport.status === 200) {
                        const data = patientTensReport.data;
                        return data ? data : 'No data available for ot plof';
                    }
                    return {
                        error: 'No data available for ot plof',
                    };
                } catch (error) {
                    return {
                        error: 'Error while fetching data for ot plof',
                    };
                }
            case 'eval_ot_priorliving@dn':
                try {
                    const patientName = this._commonService.getPatientFullNameFormat2(note.patient);
                    const patientTensReport: any = await this._therapyService.getPatientEvalReport(patientName, 'ot_priorliving_eval').toPromise();
                    if (patientTensReport.status === 200) {
                        const data = patientTensReport.data;
                        return data ? data : 'No data available for ot plof';
                    }
                    return {
                        error: 'No data available for ot plof',
                    };
                } catch (error) {
                    return {
                        error: 'Error while fetching data for ot plof',
                    };
                }
            case 'eval_pt_pmh@dn':
                try {
                    const patientName = this._commonService.getPatientFullNameFormat2(note.patient);
                    const patientTensReport: any = await this._therapyService.getPatientEvalReport(patientName, 'pt_pmh_eval').toPromise();
                    if (patientTensReport.status === 200) {
                        const data = patientTensReport.data;
                        return data ? data : 'No data available for pt pmh';
                    }
                    return {
                        error: 'No data available for pt pmh',
                    };
                } catch (error) {
                    return {
                        error: 'Error while fetching data for pt pmh',
                    };
                }
            case 'eval_ot_pmh@dn':
                try {
                    const patientName = this._commonService.getPatientFullNameFormat2(note.patient);
                    const patientTensReport: any = await this._therapyService.getPatientEvalReport(patientName, 'ot_pmh_eval').toPromise();
                    if (patientTensReport.status === 200) {
                        const data = patientTensReport.data;
                        return data ? data : 'No data available for ot pmh';
                    }
                    return {
                        error: 'No data available for ot pmh',
                    };
                } catch (error) {
                    return {
                        error: 'Error while fetching data for ot pmh',
                    };
                }
            case 'mipsmeasures@dn':
              {
                try {
                    let questionnaireResults = [];

                    const sidebarSelectedMips =
                        this.mipsService.questionnaireResults.getValue();
                    if (sidebarSelectedMips.length > 0 && false) {
                        questionnaireResults = sidebarSelectedMips;
                    } else {
                        const chargeResponse: any = await this.censusListService
                            .getCharge({
                                patient_id: note.patient._id,
                                rounding_sheet_id: note.rounding_sheet_id,
                                visit_date: note.dates.service,
                            })
                            .toPromise();

                        if (chargeResponse.status === 200) {
                            const charge_id = chargeResponse.data?._id;
                            if (charge_id) {
                                const questionnaireResultsResponse: any =
                                    await this.mipsService
                                        .getQuestionnareResultsForPhrases(
                                            charge_id,
                                            this.currentUser.company_id
                                        )
                                        .toPromise();
                                if (questionnaireResultsResponse.status === 200) {
                                    questionnaireResults =
                                        questionnaireResultsResponse.data.array;
                                }
                            }
                        }
                    }

                    const resolvedStr = questionnaireResults
                        .reduce((acc, next) => {
                            const measure = next.measure || next.measure_id;
                            let reasons='', question = next.question, chads_scrore='';
                            if((next.question?.trim().endsWith("Other"))){
                                question = next.question.slice(0, -"Other".length).trim();
                            }
                            if(next.reasons&&next.reasons.length){


                                const customSort = (a, b) => {
                                    if (a._id === null || a._id === undefined) {
                                      return 1; 
                                    } else if (b._id === null || b._id === undefined) {
                                      return -1; 
                                    }

                                    return a._id.localeCompare(b._id);
                                  };

                                let modifiedArray = next.reasons.map(obj => {
                                    if ((obj.question?.trim().endsWith("Other")||obj._id==null||obj._id==undefined)) {
                                        const otherAnswer = obj.question;
                                        const questionForOther = next.reasons.filter((item:any) => item._id != null);
                                        if(questionForOther.length>0){
                                            obj.question = questionForOther[questionForOther.length-1].question;
                                        }
                                        obj.question = obj.question + ': ' + otherAnswer;
                                    }
                                    return obj;
                                  });
                                  // modifiedArray = modifiedArray.sort(customSort);
                                modifiedArray = modifiedArray.filter(item => item._id == null || item._id == undefined)
                                const reasonsStringfromArray = modifiedArray.map(obj => obj.question).join(', ');
                                reasons=reasonsStringfromArray;
                            }
                            if(next.chads_scrore){
                                chads_scrore='CHA2DS2-VASc Score is: '+ next.chads_scrore+'. ';
                            }
                            return [
                                ...acc,
                                `MIPS ${measure.quality_number} ${measure.measure_title+' - '}${chads_scrore}${question} ${reasons||''}`,
                            ];
                        }, [])
                        .join('<br />');
                    return resolvedStr;
                } catch (error) {
                    return { error: 'Error while fetching mips measures data' };
                }
            }
            case 'kentimaging@dn': {
              // return `<b>Non-Invasive Near-Infrared Wound Imaging</b>

              // <b>Indication(s):</b> Assessment of micro-circulation, oxygenation, perfusion, and wound tissue viability to determine trends of healing and/or need for change to the plan of care.  Also, assessment for medical necessity of wound debridement and adequacy of debridement to take a chronic wound from a chronic state back to the acute phases of healing. 
              
              // <b>Findings:</b>  Images were obtained, and analysis of the wound bed demonstrates <span class="asteriskjump">***</span> microcirculation with <span class="asteriskjump">***</span> devitalized tissue.  The average wound bed StO2 is (from KI)%, and the periwound has an average StO2of (from KI)%.  These findings suggest the micro-circulation, oxygenation, and perfusion of this wound are <span class="asteriskjump">***</span>.  Repeat imaging following wound debridement demonstrates <span class="asteriskjump">***</span> tissue perfusion, and average StO2 of (from KI) %.
              
              // <b>Recommendations: </b> Based on the on these findings, the current care plan <span class="asteriskjump">***</span> `.split('\n').join('<br />')
            const highlightColor = '#ff5630'
              
              return `<b>Non-Invasive Near-Infrared Wound Imaging</b>
             
              <b>Location:</b> <span class="asteriskjump">***</span>
              <b>Indication(s):</b> Assessment of micro-circulation, oxygenation, perfusion and wound tissue viability to determine trends of healing and/or need for change to the plan of care. 
              <b>Findings:</b> There is <span class="asteriskjump">***</span> signal at the periwound, and <span class="asteriskjump">***</span> signal at the wound bed suggesting the wound is in the <span class="asteriskjump">***</span> phase of healing.  The average wound bed StO2 is <span class="asteriskjump">***</span>%.  These findings suggest the microcirculation, oxygenation, and perfusion of this wound are <span class="asteriskjump">***</span> for healing. 
              <b>Recommendations:</b> Please see updated assessment and plan section below. `.split('\n').join('<br />');
            }
            
            case 'lace@dn': {
                const lengthofstayData = { 1: '1', 2: '2', 3: '3', '4-6': '4', '7-13': '5', '14 or more': '7' }
                const admissiondata = { YES: '3', NO: '0' }
                const comorbiditiesData = {
                    'Previous myocardial infarction': '1', 'Cerebrovascular disease ': '1',
                    'Peripheral vascular disease': '1', 'Diabetes without complications': '1',
                    'Congestive heart failure': '2', 'Diabetes with end organ damage': '2',
                    'Chronic pulmonary disease': '2', 'Mild liver or renal disease': '2',
                    'Any tumor (including lymphoma or leukemia) ': '2', 'Dementia': '3',
                    'Connective tissue disease': '3', 'AIDS': '4',
                    'Moderate or severe liver or renal disease': '4',
                    'Metastatic solid tumor': '6'
                }
                const emergencyData = { 1: '1', 2: '2', 3: '3', '4 or more': '4' }

                return `
              <p><strong>Was the patient hospitalized prior to admission to SNF?</strong> <span class="select-dropdown lace-patient-hospitalized-snf-dropdown" data-label="---" data-multiselect="false" data-options="yes|no">---</span></p><br><br>
              <p>Step 1. Length of Stay including day of admission and discharge.</p><br>
              <span class="select-dropdown lace-length-of-stay-dropdown" data-multiselect="false" data-options="${Object.keys(lengthofstayData).join("|")}" data-label="Length of Stay (Days)">Length of Stay (Days)</span><br><br>
              <p>Step 2. Acuity of Admission</p><br>
              <p>Was the patient admitted to hospital via the emergency department?&nbsp;<span class="select-dropdown lace-patient-admitted-dropdown" data-label="---" data-multiselect="false" data-options="${Object.keys(admissiondata).join("|")}">---</span></p><br>
              <p>Step 3. Comorbidities</p><br>
              <p><span class="select-dropdown lace-condition-dropdown" data-label="Condition" data-multiselect="true" data-options="${Object.keys(comorbiditiesData).join("|")}">Condition</span></p><br><br>
              <p><strong>TOTAL SCORE FOR STEP 3:</strong> <span class="lace-total-score-for-step-3">***</span></p><br>
              <p>Step 4. Emergency department visits.</p><br>
              <p>How many times has the patient visited an emergency department in the six months prior to admission (not including the emergency department visit immediately preceding the current admission) <span class="select-dropdown lace-times-patient-visited-dropdown" data-label="* *" data-multiselect="false" data-options="${Object.keys(emergencyData).join("|")}">---</span></p><br>
              <p>LACE Score Risk of Readmission greater or equal 10 High Risk&nbsp;</p><br>
              <p><strong>TOTAL&nbsp;: <span class="lace-total-score">***</span></strong></p>`
            }

            case 'allergies@dn': {
                // resolveAllergiesPhrase
                const response: any = await lastValueFrom(this.resolveAllergiesPhrase(note.patient._id,note.dates.service));

              if (response.status === 200) {
                if(response.data.length === 0) {
                    return "No Known Drug Allergies"
                }
                const allergies = response.data.reduce((acc, next) => {
                  return [...acc, this._commonService.toTitleCase(next.allergen)];
                }, []).join('<br />');
                return allergies;
              } else {
                  return {error: 'Error while fetching allergies data'}
              }
            }
            case 'medicalhistory@dn': {
                // resolveAllergiesPhrase
                const response: any = await lastValueFrom(this.resolvePatientMedicalHistoryPhrase(note.patient._id));

              if (response.status === 200) {
                /**Data
                 *  {
                    "is_deleted": null,
                    "_id": "62299ebbded43c5104402699",
                    "patient_id": "5f6845a0c527227600616eb3",
                    "selected_data": [
                        {
                            "items": [
                                "item 2.3"
                            ],
                            "_id": "6229a64fe2a10252636a67a4",
                            "group_id": {
                                "_id": "6228a5dab8d7220ffb9d21e8",
                                "header": "Group 2"
                            }
                        }
                    ],
                    "createdAt": "2022-03-10T06:46:19.078Z",
                    "updatedAt": "2022-03-11T09:20:25.210Z",
                    "__v": 0
                }
              */
                if(!response.data?.selected_data || response.data.selected_data.length === 0) {
                    return "No medical history data"
                }
                // response.data.selected_data.sort((a,b) => {
				        // 	return a.group_id.header.localeCompare(b.group_id.header, undefined, {numeric: true, sensitivity: 'base'})
                // })
                return response.data.selected_data.reduce((acc, next) => {
                  if(next.items.length > 0) {
                    const liItems = next.items.map(item => `${item}`)
                    return [...acc, `${liItems.join('<br />')}`];
                  }
                  return acc;
                }, []).join('<br />');
              } else {
                  return {error: 'Error while fetching medical history data'}
              }
            }
            case 'dob@dn': {
            
            if (note.patient.date_of_birth) {
              // parse Feb 02, 1955
              const [year, month, date] = note.patient.date_of_birth.split('-');
              return `${month}/${date}/${year}`;
            } else {
                return {error: 'No date of birth data'}
            }
          }

          case 'nameagesex@dn':
            {
                    const age = moment().diff(moment(note.patient.date_of_birth), 'years');
                    const name = this._commonService.getPatientFullName(note.patient)
                    const gender = note.patient.gender.toLowerCase();
                    return `${name} is ${age <= 80 ? 'a' : 'an'} ${age} year old ${gender}`
            }
            case 'age@dn':
              {
                      const age = moment().diff(moment(note.patient.date_of_birth), 'years');
                      // const name = this._commonService.getPatientFullName(note.patient)
                      // const gender = note.patient.gender.toLowerCase();
                      return `${age <= 80 ? 'a' : 'an'} ${age} year old`
              }
            case 'sex@dn':
              {
                      // const age = moment().diff(moment(note.patient.date_of_birth), 'years');
                      // const name = this._commonService.getPatientFullName(note.patient)
                      const gender = note.patient.gender.toLowerCase();
                      return `${gender}`
              }

            case 'admitdate@dn':
              {
                
                let {patient} = note;
                if(!(patient instanceof Patient)) {
                  patient = new Patient(patient);
                }
                if (note.patient.last_admit_date||note.patient.last_admit_date_obj) {
                  return patient.getAdmissionDate();
                } else {
                    return {error: 'No admit date data'}
                }
              }

              
            case 'pcp@dn':
              {
                const pcpName = [note.patient.primary_practitioner_first_name, note.patient.primary_practitioner_last_name]
                .map(s => typeof s === 'string' ? s.trim() : '')
                .filter(s => !!s.trim())
                .join(' ').trim();
                if (pcpName) {
                  return pcpName;
                } else {
                    return {error: 'No pcp data'}
                }
              }

              
            case 'icd10@dn':
              {
                try {
                  let icds = [];
                  const sidebarSelectedICDs = this.sidebarDiagnosisService.selectICDs.getValue();
                  if (sidebarSelectedICDs.length > 0) {
                    icds = sidebarSelectedICDs;
                  } else {
                    const chargeResponse: any = await this.censusListService
                      .getCharge({
                        patient_id: note.patient._id,
                        rounding_sheet_id: note.rounding_sheet_id,
                        visit_date: note.dates.service,
                      })
                      .toPromise();
                    if (chargeResponse.status === 200) {
                      icds = chargeResponse.data?.icd_id || [];
                    }
                  }

                  const resolvedStr = icds
                    .reduce((acc, next) => {
                      return [
                        ...acc,
                        `<span class="icd10-code" data-icd10-phrase="${next._id}">${next.description} (<span>${next.code}</span>)</span>`,
                      ];
                    }, [])
                    .join('<br />');
                  return resolvedStr;
                } catch (error) {
                  return { error: 'Error while fetching icd10 data' };
                }
            }

            case 'icd10detailedplan@dn': {
                try {
                    let icds = [];
                    const sidebarSelectedICDs = this.sidebarDiagnosisService.selectICDs.getValue();
                    if (sidebarSelectedICDs.length > 0) {
                        icds = sidebarSelectedICDs;
                    } else {
                        const chargeResponse: any = await this.censusListService
                            .getCharge({
                                patient_id: note.patient._id,
                                rounding_sheet_id: note.rounding_sheet_id,
                                visit_date: note.dates.service,
                            })
                            .toPromise();
                        if (chargeResponse.status === 200) {
                            icds = chargeResponse.data?.icd_id || [];
                        }
                    }

                    const resolvedStr = icds
                        .reduce((acc, next) => {
                            if (!next.mediumDescription)
                                next.mediumDescription = "***";
                            return [
                                ...acc,
                                `<span class="icd10-code">${next.code} (<span>${next.description}</span>)- ${next.mediumDescription} </span>`,
                            ];
                        }, [])
                        .join('<br />');
                    return resolvedStr;
                } catch (error) {
                    return { error: 'Error while fetching icd10 data' };
                }

            }

            case 'icd10list@dn':
              {
                try {
                  let icds = [];
                  const sidebarSelectedICDs = this.sidebarDiagnosisService.selectICDs.getValue();
                  if (sidebarSelectedICDs.length > 0) {
                    icds = sidebarSelectedICDs;
                  } else {
                    const chargeResponse: any = await this.censusListService
                      .getCharge({
                        patient_id: note.patient._id,
                        rounding_sheet_id: note.rounding_sheet_id,
                        visit_date: note.dates.service,
                      })
                      .toPromise();
                    if (chargeResponse.status === 200) {
                      icds = chargeResponse.data?.icd_id || [];
                    }
                  }

                  const resolvedStr = icds
                    .reduce((acc, next) => {
                      return [
                        ...acc,
                        `<span class="icd10-code">${next.description} (<span>${next.code}</span>) - <span class="asteriskjump">***</span></span>`,
                      ];
                    }, [])
                    .join('<br />');
                  return resolvedStr;
                } catch (error) {
                  return { error: 'Error while fetching icd10 data' };
                }
            }
            case 'icd10wound@dn':
              {
                try {
                  // call to get wound data
                  const {data: wounds, status} = await lastValueFrom(this.resolveWoundPhrase(undefined, note.patient._id, true)) as any;
                  if(status == 200) {

                    return wounds.map(wound => {
                      const parentWound = wound;
                      const [latestChildWoundWound, ...restChildWounds] = wound.child_wounds;
                      const latestWound = latestChildWoundWound || wound;
                      return `<span data-wound="${parentWound._id}">Wound # ${parentWound.woundNo || ''} - ${parentWound.wound_diagnosis?.icd_id.map( icd => `<span data-icd10-phrase="${icd._id}">` + icd.code  + ` (` + icd.mediumDescription +')</span>').join(', ') || ''} - <span class="asteriskjump">***</span></span>`
                    }).join('<br />')
                    
                  } 
                  throw new Error();
                } catch (error) {
                  return { error: 'Error while fetching icd10 wound data' };
                }
            }
            case 'meds@pcc':
                {
                    const medicationsResponse: any = await this._pccService
                      .getPatientMedications({
                        pccOrgUuid: note.facility.pcc_orgUuid,
                        pccPatientId: note.patient.pcc_patientId,
                        pccFacID: note.facility.pcc_facId,
                        pccStatus: 'active',
                        authMode
                      })
                      .toPromise();
                      if (medicationsResponse.status === 200) {
                        if(medicationsResponse.data.data.length === 0) {
                          return {
                            error: 'No medications available',
                          };
                        }
                        let filteredMedications = medicationsResponse.data.data.filter(
                          (meds) => {
                            const mDate = moment(meds.startDate).startOf('day');
                            const dos = this._commonService.dateObjToDate(note.date_obj);
                            const mDOS = moment(dos).startOf(
                              'day'
                            );
                            return meds.status === 'ACTIVE' //&& mDate.isSame(mDOS);
                            // return mDate.isSame(mDOS);
                              // return meds.status === 'ACTIVE'
                          }
                        );
                      // if (filteredMedications.length === 0) {
                      //   // get the most recent medication
                      //   const mostRecentDate = medicationsResponse.data.data.reduce((acc, curr) => {
                      //     if (moment(curr.startDate).isAfter(acc)) {
                      //         return curr.startDate
                      //     }
                      //     return acc
                      // }, new Date(0));
                      //   filteredMedications = medicationsResponse.data.data.filter(meds => moment(meds.startDate).isSame(mostRecentDate));
                      // }
                      const medications = filteredMedications
                        .reduce((acc, next) => {
                          return [
                            ...acc,
                            // `${next.description} ${next.strength} ${next.strengthUOM} ${next.schedules[0].frequency}`,
                            // `${next.description} ${next.strengthUOM} ${next.schedules[0].frequency}`,
                            `${next.description} ${next.schedules[0].frequency}`,
                          ];
                        }, [])
                        .join('<br />');
                      return medications;
                    } else {
                        return {error: 'Error while fetching medications data'}
                    }
                }
            case 'meds_long@pcc':
                {
                    const medicationsResponse: any = await this._pccService
                      .getPatientMedications({
                        pccOrgUuid: note.facility.pcc_orgUuid,
                        pccPatientId: note.patient.pcc_patientId,
                        pccFacID: note.facility.pcc_facId,
                        pccStatus: 'active',
                        authMode
                      })
                      .toPromise();
                    if (medicationsResponse.status === 200) {
                      const filteredMedications = medicationsResponse.data.data.filter(
                        (meds) => {
                        //   const mDate = moment(meds.startDate).startOf('day');
                        //   const mDOS = moment(note.dates.service).startOf(
                        //     'day'
                        //   );
                        //   return mDate.isSame(mDOS);
                            return meds.status === 'ACTIVE'
                        }
                      );
                      if (filteredMedications.length === 0) {
                        return {
                          error: 'No medications available',
                        };
                      }
                      const medications = filteredMedications
                        .reduce((acc, next) => {
                          return [
                            ...acc,
                            // `${next.description} ${next.directions} ${next.strength} ${next.strengthUOM} ${next.schedules[0].frequency}`,
                            `${next.description} ${next.directions} ${next.strengthUOM} ${next.schedules[0].frequency}`,
                          ];
                        }, [])
                        .join('<br />');
                      return medications;
                    } else {
                        return {error: 'Error while fetching medications data'}
                    }
                }
            case 'vitals@pcc':
                // current time in facility timezone
                // const dos = this._commonService.dateObjToDate(note.date_obj);
                // const startDate = moment(dos).startOf('day').toDate();
                // const endDate = moment(dos).endOf('day').toDate();
                const mStartDate = moment.tz(moment({
                  year: note.date_obj.year,
                  month: note.date_obj.month - 1,
                  day: note.date_obj.day,
                }), note.facility.pcc_timeZone).startOf('day')
                .subtract('2', 'weeks');
                
                let mEndDate = moment.tz(moment({
                  year: note.date_obj.year,
                  month: note.date_obj.month - 1,
                  day: note.date_obj.day,
                }), note.facility.pcc_timeZone).endOf('day');
                
                                
                // get current time in Los_Angeles timezone using moment-timezone
                const currentTimeInFacilityTimezone = moment.tz(moment(), note.facility.pcc_timeZone);
                if(mEndDate.isAfter(currentTimeInFacilityTimezone)) {
                  mEndDate = currentTimeInFacilityTimezone;
                }

                const startDate = mStartDate.toDate();
                const endDate = mEndDate.toDate();
                const vitalsResponse:any = await this._pccService.getObservations({
                  pccOrgUuid: note.facility.pcc_orgUuid, 
                  pccPatientID: note.patient.pcc_patientId,
                  pccStartDate: startDate,
                  pccEndDate: endDate,
                  authMode
                }).toPromise();
                    if(vitalsResponse.status === 200) {
                        if(vitalsResponse.data.data.length === 0) {
                          return {
                            error: 'No vitals available for current DOS',
                          };
                        }

                        let filteredVitals = vitalsResponse.data.data;
                        let sortedFilteredVitals = [];
                        [
                            "temperature",
                            "bloodPressure",
                            "heartrate",
                            "respirations",
                            "oxygenSaturation",
                            "bmi",
                            // "height",
                            // "weight",
                            // "painLevel",
                          ].forEach(type => {
                            sortedFilteredVitals.push(filteredVitals.find(v => v.type === type))
                          })
                          
                        // sorting desc order
                        sortedFilteredVitals.sort((a,b) => {
                          return new Date(b.recordedDate).getTime() - new Date(a.recordedDate).getTime()
                        });
                        // remove duplicates by type and method
                        sortedFilteredVitals = sortedFilteredVitals.reduce((acc, curr) => {
                          if(acc.length === 0) {
                            return [curr]
                          }
                          const found = acc.find(vital => vital && curr && vital.type === curr.type);
                          if(!found) {
                            return [...acc, curr]
                          }
                          return acc;
                        }, []);
                        
                          if(sortedFilteredVitals.length === 0) {
                            return {
                                error: 'No vitals available for current DOS',
                              };
                            }

                        const vitals = sortedFilteredVitals
                          .reduce((acc, next) => {
                              
                            if(next) {
                                let valueWithUnit = `${next.value}`;
                                if (next.type === 'bloodPressure') {
                                  valueWithUnit = `${next.systolicValue}/${next.diastolicValue}`;
                                }
                                if(next.type === 'oxygenSaturation') {
                                    valueWithUnit += next.unit;
                                }
                                let type = next.type;
                                  switch (type) {
                                    case 'temperature':
                                      type = 'T';
                                      break;
                                    case 'bloodPressure':
                                      type = 'BP';
                                      break;
                                    case 'heartrate':
                                      type = 'HR';
                                      break;
                                    case 'respirations':
                                      type = 'R';
                                      break;
                                    case 'oxygenSaturation':
                                      type = 'O2 SAT';
                                      break;
                                    case 'bmi':
                                      type = 'BMI';
                                      break;
                                    default:
                                      var result = type.replace(/([A-Z])/g, ' $1');
                                      type =
                                        result.charAt(0).toUpperCase() +
                                        result.slice(1);
                                      break;
                                  }
    
                                return [...acc, `${type} ${valueWithUnit}`];
                            } 
                            return acc;
                          }, [])
                          .join(', ');
                          if(vitals) {
                            return `Vitals - ${vitals}`;
                          } else {
                            return {
                                error: 'No vitals available for current DOS',
                              };
                          }
                    } else {
                        return {error: 'Error while fetching vitals data'}
                    }
                    
            case 'vitals_date@pcc': {
                // Check if hour and minute are missing in the note.date_obj and set them to the current time
                if (isNaN(parseInt(note.date_obj.hour)) && isNaN(parseInt(note.date_obj.minute))) {
                    var today = new Date();
                    note.date_obj.hour = today.getHours();
                    note.date_obj.minute = today.getMinutes();
                }

                const mSelectedDate = moment.tz(
                    {
                        year: note.date_obj.year,
                        month: note.date_obj.month - 1, // Adjust month to be 0-based
                        date: note.date_obj.day,
                        hour: note.date_obj.hour || 0,
                        minute: note.date_obj.minute || 0,
                        second: note.date_obj.second || 0,
                    },
                    note.facility.pcc_timeZone
                );

                // Calculate the end date (selected date)
                let mEndDate = mSelectedDate.clone();


                // Get the current time in the facility's timezone using moment-timezone
                const currentTimeInFacilityTimezone = moment.tz(moment(), note.facility.pcc_timeZone);

                // If the end date is in the future according to facility's timezone, adjust it to the current time
                console.log('mEndDate.format()', mEndDate.format('MM/DD/YYYY HH:mm:ss'))
                console.log('currentTimeInFacilityTimezone.format()', currentTimeInFacilityTimezone.format('MM/DD/YYYY HH:mm:ss'))
                if (mEndDate.isAfter(currentTimeInFacilityTimezone)) {
                    console.log('mEndDate is in the future according to facility\'s timezone current datetime')
                    mEndDate = currentTimeInFacilityTimezone;
                } else if (mEndDate.isBefore(currentTimeInFacilityTimezone.clone().subtract(2, 'weeks'))) {
                    console.log('mEndDate is in the past according to facility\'s timezone current datetime')
                } else {
                    console.log('mEndDate is in the same day according to facility\'s timezone current datetime')
                }


                // Calculate the start date (2 weeks before the selected date)
                let mStartDate = mEndDate.clone().subtract(2, 'weeks');

                // Convert dates to UTC format in the required string format
                const startDateUTC = mStartDate.toISOString(); // "YYYY-MM-DDTHH:MM:SS.sssZ"
                const endDateUTC = mEndDate.toISOString(); // "YYYY-MM-DDTHH:MM:SS.sssZ"

                const requiredTypesMappings = {
                    'temperature': 'T',
                    'bloodPressure': 'BP',
                    'heartrate': 'HR',
                    'respirations': 'R',
                    'oxygenSaturation': 'O2 SAT',
                    'bmi': 'BMI',
                }

                const requiredTypes = Object.keys(requiredTypesMappings)
                const vitalsResponse: any = await this._pccService.getObservations({
                    pccOrgUuid: note.facility.pcc_orgUuid,
                    pccPatientID: note.patient.pcc_patientId,
                    pccStartDate: startDateUTC,
                    pccEndDate: endDateUTC,
                    authMode
                }).toPromise();
                if (vitalsResponse.status === 200) {
                    if (vitalsResponse.data.data.length === 0) {
                        return {
                            error: 'No vitals available for current DOS',
                        };
                    }

                    let filteredVitals = vitalsResponse.data.data;
                    let sortedFilteredVitals = [];
                    requiredTypes.forEach(type => {
                        sortedFilteredVitals.push(filteredVitals.find(v => v.type === type))
                    })

                    // sorting desc order
                    sortedFilteredVitals.sort((a, b) => {
                        return new Date(b.recordedDate).getTime() - new Date(a.recordedDate).getTime()
                    });
                    // remove duplicates by type and method
                    sortedFilteredVitals = sortedFilteredVitals.reduce((acc, curr) => {
                        if (acc.length === 0) {
                            return [curr]
                        }
                        const found = acc.find(vital => vital && curr && vital.type === curr.type);
                        if (!found) {
                            return [...acc, curr]
                        }
                        return acc;
                    }, []);

                    if (sortedFilteredVitals.length === 0) {
                        return {
                            error: 'No vitals available for current DOS',
                        };
                    }

                    const vitals = sortedFilteredVitals
                        .reduce((acc, next) => {

                            if (next) {
                                const date = moment.tz(next.recordedDate, note.facility.pcc_timeZone).format('MM/DD/YYYY');
                                // const time = moment.tz(next.recordedDate, note.facility.pcc_timeZone).format('HH:mm');
                                debugger
                                let valueWithUnit = `${next.value}`;
                                if (next.type === 'bloodPressure') {
                                    valueWithUnit = `${next.systolicValue}/${next.diastolicValue}`;
                                }
                                if (next.type === 'oxygenSaturation') {
                                    valueWithUnit += next.unit;
                                }
                                let type;
                                if (requiredTypesMappings[next.type]) {
                                    type = requiredTypesMappings[next.type];
                                } else {
                                    var result = type.replace(/([A-Z])/g, ' $1');
                                    type =
                                        result.charAt(0).toUpperCase() +
                                        result.slice(1);
                                }

                                return [...acc, `${type} ${valueWithUnit}, Date: ${date}`];
                            }
                            return acc;
                        }, [])
                        .join(', ');
                    if (vitals) {
                        return `Vitals - ${vitals}`;
                    } else {
                        return {
                            error: 'No vitals available for current DOS',
                        };
                    }
                } else {
                    return { error: 'Error while fetching vitals data' }
                }
            }
            case 'allergies_list@pcc':
                {
                    const allergiesResponse:any = await this._pccService.getAllergyIntolerances({
                      pccOrgUuid:note.facility.pcc_orgUuid, 
                      pccPatientId:note.patient.pcc_patientId,
                      authMode
                  }).toPromise();
                    if(allergiesResponse.status === 200) {
                        if(allergiesResponse.data.data.length === 0) {
                            return "No Known Drug Allergies"
                        }
                        const allergies = allergiesResponse.data.data
                        .filter(allergen => allergen.clinicalStatus === 'active')
                        .reduce((acc, next) => {
                            return [...acc, this._commonService.toTitleCase(next.allergen)];
                        }, []).join('<br />')
                        return allergies;
                    } else {
                        return {error: 'Error while fetching allergies data'}
                    }
                }
            case 'allergies_csl@pcc':
                {
                    const allergiesResponse:any = await this._pccService.getAllergyIntolerances({
                      pccOrgUuid:note.facility.pcc_orgUuid, 
                      pccPatientId:note.patient.pcc_patientId,
                      authMode
                  }).toPromise();
                    if(allergiesResponse.status === 200) {
                        if(allergiesResponse.data.data.length === 0) {
                            return "No Known Drug Allergies"
                        }
                        const allergies = allergiesResponse.data.data
                        .filter(allergen => allergen.clinicalStatus === 'active')
                        .reduce((acc, next) => {
                            return [...acc, this._commonService.toTitleCase(next.allergen)];
                        }, []).join(', ')
                        return allergies;
                    } else {
                        return {error: 'Error while fetching allergies data'}
                    }
                }
            case 'dx@pcc':
                const diagnosislistResponse:any = await this._pccService.getPatientConditions({
                  pccOrgUuid:note.facility.pcc_orgUuid, 
                  pccPatientId:note.patient.pcc_patientId,
                  authMode
                }).toPromise(); 
                if(diagnosislistResponse.status === 200) {
                    if(diagnosislistResponse.data.data.length === 0) {
                        return {error: "No diagnosis data available"}
                    }
                    const diagnosislist = diagnosislistResponse.data.data.reduce((acc, next) => {
                        const icd10Description = this._commonService.toTitleCase(next.icd10Description);
                        return [...acc, icd10Description];
                    }, []).join('<br />')
                    return diagnosislist;
                } else {
                    return {error: 'Error while fetching diagnosis data'}
                }
            case 'dob@pcc':
                const patientResponse:any = await this._pccService.getPatient({
                  pccOrgUuid: note.facility.pcc_orgUuid, 
                  pccPatientID: note.patient.pcc_patientId,
                  authMode
                }).toPromise();
                if(patientResponse.status === 200) {
                  const birthDate = patientResponse.data.birthDate;
                  const mBirthDate = moment(birthDate);
                  if(birthDate && mBirthDate.isValid()) {
                    return mBirthDate.format('MM/DD/YYYY');
                  }
                } 
               return {error: 'Error while fetching dob data'}
                  
            case 'nameagesex@pcc':
                {
                    const patientResponse:any = await this._pccService.getPatient({
                      pccOrgUuid: note.facility.pcc_orgUuid, 
                      pccPatientID: note.patient.pcc_patientId,
                      authMode
                    }).toPromise();
                    if(patientResponse.status === 200) {
                        const age = moment().diff(patientResponse.data.birthDate, 'years');
                        const name = this._commonService.getPatientFullName(note.patient)
                        const gender = note.patient.gender.toLowerCase();
                        return `${name} is ${age <= 80 ? 'a' : 'an'} ${age} year old ${gender}`
                    } else {
                        return {error: 'Error while fetching patient data'}
                    }
                }

            case 'room_no@pcc': {
              const patientResponse: any = await this._pccService.getPatient({
                pccOrgUuid: note.facility.pcc_orgUuid, 
                pccPatientID: note.patient.pcc_patientId,
                authMode
              }).toPromise();
              if(patientResponse.status === 200){
                if(!patientResponse.data.floorDesc || !patientResponse.data.roomDesc || !patientResponse.data.bedDesc) {
                  return 'No room data available'
                }
                return `${patientResponse.data.floorDesc} ${patientResponse.data.roomDesc} - ${patientResponse.data.bedDesc} `
              }
              else {
                return {error: 'Error while fetching patient data'}
              }
            }
            case 'namegender@pcc':
                {
                    const patientResponse:any = await this._pccService.getPatient({
                      pccOrgUuid:note.facility.pcc_orgUuid, 
                      pccPatientID:note.patient.pcc_patientId,
                      authMode
                    }).toPromise();
                    if(patientResponse.status === 200) {
                        const age = moment().diff(patientResponse.data.birthDate, 'years');
                        // const name = this._commonService.getPatientFullName(note.patient)
                        const gender = note.patient.gender.toLowerCase();
                        return `${age} years old ${gender}`
                    } else {
                        return {error: 'Error while fetching patient data'}
                    }
                }
            case 'admitdate@pcc':
                {
                    // return moment(note.patient.pcc_payload.admissionDate).format('MM/DD/YYYY');
                    // return moment.tz(note.patient.last_admit_date, "UTC").format('MMM DD, YYYY');
                    
                    
                    // check if note.patient not instance of Patient class
                    let {patient} = note;
                    if(!(patient instanceof Patient)) {
                      patient = new Patient(patient);
                    }
                    return patient.getAdmissionDate();
                }
            case 'diet@pcc':
                {
                    const nutritionOrdersResponse:any = await this._pccService.getPatientNutritionOrders({
                      pccOrgUuid:note.facility.pcc_orgUuid, 
                      pccPatientId:note.patient.pcc_patientId,
                      authMode
                  }).toPromise();
                    if(nutritionOrdersResponse.status === 200) {
                        if(nutritionOrdersResponse.data.data.length === 0) {
                            return {error: "No nutrition orders data available"}
                        }
                        const nutritionOrders = nutritionOrdersResponse.data.data.reduce((acc, next) => {
                            return [...acc, `${next.oralDiet.type} diet, ${next.oralDiet.texture.modifier} texture, ${next.oralDiet.fluidConsistencyType} consistency`];
                        }, []).join('<br />')
                        return nutritionOrders;
                    } else {
                        return {error: 'Error while fetching nutrition orders data'}
                    }
                }

            case 'codestatus@pcc':
                {
                    const advanceDirectivesConsentsResponse:any = await this._pccService.getAdvanceDirectivesConsents({
                      pccOrgUuid: note.facility.pcc_orgUuid,
                      pccPatientId: note.patient.pcc_patientId,
                      authMode
                    }).toPromise();
                    if(advanceDirectivesConsentsResponse.status === 200) {
                        if(advanceDirectivesConsentsResponse.data.data.length === 0) {
                            return "No code status data available"
                        }
                        const advanceDirectivesConsents = advanceDirectivesConsentsResponse.data.data.reduce((acc, next) => {
                            return [...acc, `${next.description}`];
                        }, []).join(',')
                        return advanceDirectivesConsents;
                    } else {
                        return {error: 'Error while fetching advance directives consents data'}
                    }
                }
            case 'imaging@pcc':
                {
                    const patientDiagnosticReportsResponse:any = await this._pccService.getPatientDiagnosticReports({
                      pccOrgUuid:note.facility.pcc_orgUuid,
                      pccPatientId:note.patient.pcc_patientId, 
                      pccReportType:'radiology',
                      authMode
                    }).toPromise();
                    if(patientDiagnosticReportsResponse.status === 200) {
                        if(patientDiagnosticReportsResponse.data.data.length === 0) {
                            return "No imaging data available"
                        }
                        const results = patientDiagnosticReportsResponse.data.data.reduce((acc, next) => {
                          let _acc = [...acc];
                          for(let testSet of next.testSet) {
                              for(let result of testSet.results) {
                                  _acc.push({...result, issuedDateTime: next.issuedDateTime});
                              }
                          }
                          return _acc;
                      }, []);
                      
                        const output = results.map((result) => `${result.codeDescription} on ${moment(result.issuedDateTime).format('M/D/YYYY')}<br />${result.comment}`).join('<br /><br />')
                        return output;
                    } else {
                        return {error: 'Error while fetching imaging data'}
                    }
                }
            case 'imaging_1week@pcc':
                {
                    const mDOS = moment.tz(moment({
                        year: note.date_obj.year,
                        month: note.date_obj.month - 1,
                        day: note.date_obj.day,
                    }), note.facility.pcc_timeZone).startOf('day');

                    const patientDiagnosticReportsResponse: any = await this._pccService.getPatientDiagnosticReports({
                        pccOrgUuid: note.facility.pcc_orgUuid,
                        pccPatientId: note.patient.pcc_patientId,
                        pccReportType: 'radiology',
                        authMode,
                        pccIssuedDateTime: `ge${mDOS.subtract(1, 'weeks').toDate().toISOString()}`,
                    }).toPromise();

                    if (patientDiagnosticReportsResponse.status === 200) {
                        if (patientDiagnosticReportsResponse.data.data.length === 0) {
                            return "No imaging data available"
                        }
                        let output = imagingPhraseTemplate(patientDiagnosticReportsResponse.data.data);
                        return output;
                    } else {
                        return { error: 'Error while fetching imaging data' }
                    }
                }
            case 'imaging_2week@pcc':
                {
                    const mDOS = moment.tz(moment({
                        year: note.date_obj.year,
                        month: note.date_obj.month - 1,
                        day: note.date_obj.day,
                    }), note.facility.pcc_timeZone).startOf('day');

                    const patientDiagnosticReportsResponse: any = await this._pccService.getPatientDiagnosticReports({
                        pccOrgUuid: note.facility.pcc_orgUuid,
                        pccPatientId: note.patient.pcc_patientId,
                        pccReportType: 'radiology',
                        authMode,
                        pccIssuedDateTime: `ge${mDOS.subtract(2, 'weeks').toDate().toISOString()}`,
                    }).toPromise();

                    if (patientDiagnosticReportsResponse.status === 200) {
                        if (patientDiagnosticReportsResponse.data.data.length === 0) {
                            return "No imaging data available"
                        }
                        let output = imagingPhraseTemplate(patientDiagnosticReportsResponse.data.data);
                        return output;
                    } else {
                        return { error: 'Error while fetching imaging data' }
                    }
                }
            case 'imaging_4week@pcc':
                {
                    const mDOS = moment.tz(moment({
                        year: note.date_obj.year,
                        month: note.date_obj.month - 1,
                        day: note.date_obj.day,
                    }), note.facility.pcc_timeZone).startOf('day');

                    const patientDiagnosticReportsResponse: any = await this._pccService.getPatientDiagnosticReports({
                        pccOrgUuid: note.facility.pcc_orgUuid,
                        pccPatientId: note.patient.pcc_patientId,
                        pccReportType: 'radiology',
                        authMode,
                        pccIssuedDateTime: `ge${mDOS.subtract(4, 'weeks').toDate().toISOString()}`,
                    }).toPromise();

                    if (patientDiagnosticReportsResponse.status === 200) {
                        if (patientDiagnosticReportsResponse.data.data.length === 0) {
                            return "No imaging data available"
                        }
                        let output = imagingPhraseTemplate(patientDiagnosticReportsResponse.data.data);
                        return output;
                    } else {
                        return { error: 'Error while fetching imaging data' }
                    }
                }
            case 'labs@pcc':
                {
                    const patientDiagnosticReportsResponse:any = await this._pccService.getPatientDiagnosticReports({
                      pccOrgUuid:note.facility.pcc_orgUuid,
                      pccPatientId:note.patient.pcc_patientId, 
                      pccReportType:'laboratory',
                      authMode
                    }).toPromise();

                    if(patientDiagnosticReportsResponse.status === 200) {
                        if(patientDiagnosticReportsResponse.data.data.length === 0) {
                            return "No labs data available"
                        }
                        const results = patientDiagnosticReportsResponse.data.data.reduce((acc, next) => {
                            let _acc = [...acc];
                            for(let testSet of next.testSet) {
                                for(let result of testSet.results) {
                                  _acc.push({...result, issuedDateTime: next.issuedDateTime});
                                }
                            }
                            return _acc;
                        }, []);
                        
                        const output = results.map(result => `${result.codeDescription} ${result.valueQuantity?.unitText || ''} on ${moment(result.issuedDateTime).tz(moment.tz.guess()).format('M/D/YYYY hh:mm z')}`).join('<br />');
                        return output;
                    } else {
                        return {error: 'Error while fetching labs data'}
                    }
                }
                
            case 'labs_1week@pcc':
                {
                    const mDOS = moment.tz(moment({
                        year: note.date_obj.year,
                        month: note.date_obj.month - 1,
                        day: note.date_obj.day,
                    }), note.facility.pcc_timeZone).startOf('day');

                    const patientDiagnosticReportsResponse: any = await this._pccService.getPatientDiagnosticReports({
                        pccOrgUuid: note.facility.pcc_orgUuid,
                        pccPatientId: note.patient.pcc_patientId,
                        pccReportType: 'laboratory',
                        authMode,
                        pccIssuedDateTime: `ge${mDOS.subtract(1, 'week').toDate().toISOString()}`,
                    }).toPromise();

                    if (patientDiagnosticReportsResponse.status === 200) {
                        if (patientDiagnosticReportsResponse.data.data.length === 0) {
                            return "No labs data available"
                        }
                        let output = labsPhraseTemplate(patientDiagnosticReportsResponse.data.data);
                        return output;
                    } else {
                        return { error: 'Error while fetching labs data' }
                    }
                }
            case 'labs_2week@pcc':
                {
                    const mDOS = moment.tz(moment({
                        year: note.date_obj.year,
                        month: note.date_obj.month - 1,
                        day: note.date_obj.day,
                    }), note.facility.pcc_timeZone).startOf('day');

                    const patientDiagnosticReportsResponse: any = await this._pccService.getPatientDiagnosticReports({
                        pccOrgUuid: note.facility.pcc_orgUuid,
                        pccPatientId: note.patient.pcc_patientId,
                        pccReportType: 'laboratory',
                        authMode,
                        pccIssuedDateTime: `ge${mDOS.subtract(2, 'weeks').toDate().toISOString()}`,
                    }).toPromise();

                    if (patientDiagnosticReportsResponse.status === 200) {
                        if (patientDiagnosticReportsResponse.data.data.length === 0) {
                            return "No labs data available"
                        }
                        let output = labsPhraseTemplate(patientDiagnosticReportsResponse.data.data);
                        return output;
                    } else {
                        return { error: 'Error while fetching labs data' }
                    }
                }
            case 'labs_4week@pcc':
                {
                    const mDOS = moment.tz(moment({
                        year: note.date_obj.year,
                        month: note.date_obj.month - 1,
                        day: note.date_obj.day,
                    }), note.facility.pcc_timeZone).startOf('day');

                    const patientDiagnosticReportsResponse: any = await this._pccService.getPatientDiagnosticReports({
                        pccOrgUuid: note.facility.pcc_orgUuid,
                        pccPatientId: note.patient.pcc_patientId,
                        pccReportType: 'laboratory',
                        authMode,
                        pccIssuedDateTime: `ge${mDOS.subtract(4, 'weeks').toDate().toISOString()}`,
                    }).toPromise();

                    if (patientDiagnosticReportsResponse.status === 200) {
                        if (patientDiagnosticReportsResponse.data.data.length === 0) {
                            return "No labs data available"
                        }
                        let output = labsPhraseTemplate(patientDiagnosticReportsResponse.data.data);
                        return output;
                    } else {
                        return { error: 'Error while fetching labs data' }
                    }
                }
            case 'labstable@pcc':
                {
                    const patientDiagnosticReportsResponse:any = await this._pccService.getPatientDiagnosticReports({
                      pccOrgUuid:note.facility.pcc_orgUuid,
                      pccPatientId:note.patient.pcc_patientId, 
                      pccReportType:'laboratory',
                      authMode
                    }).toPromise();

                    if(patientDiagnosticReportsResponse.status === 200) {
                        if(patientDiagnosticReportsResponse.data.data.length === 0) {
                            return "No labs data available"
                        }
                        const results = patientDiagnosticReportsResponse.data.data.reduce((acc, next) => {
                            let _acc = [...acc];
                            for(let testSet of next.testSet) {
                                for(let result of testSet.results) {
                                  _acc.push({...result, issuedDateTime: next.issuedDateTime});
                                }
                            }
                            return _acc;
                        }, []);
                        
                        const output = `<table style="max-width:600px">
                        <thead>
                        <tr>
                          <th style="width:33.33%;text-align: left;">Test Name</th>
                          <th style="width:33.33%;text-align: left;">Test Result</th>
                          <th style="width:33.33%;text-align: left;">Date/Time</th>
                        </tr>
                        </thead>
                        <tbody>
                          ${
                            results.map(result => `
                            <tr>
                              <td>${result.codeDescription}</td>
                              <td>${result.valueQuantity?.unitText || ''}</td> 
                              <td>${moment(result.issuedDateTime).tz(moment.tz.guess()).format('M/D/YYYY hh:mm z')}</td>
                            </tr>  
                            `).join('')
                          }
                          </tbody>
                        </table>`;
                        return output;
                    } else {
                        return {error: 'Error while fetching labs data'}
                    }
                }
            case 'imaging_findings_impression@pcc':
                {
                    const patientDiagnosticReportsResponse:any = await this._pccService.getPatientDiagnosticReports({
                      pccOrgUuid:note.facility.pcc_orgUuid,
                      pccPatientId:note.patient.pcc_patientId, 
                      pccReportType:'radiology',
                      authMode
                    }).toPromise();

                    if(patientDiagnosticReportsResponse.status === 200) {
                        if(patientDiagnosticReportsResponse.data.data.length === 0) {
                            return "No labs data available"
                        }
                        const results = patientDiagnosticReportsResponse.data.data.reduce((acc, next) => {
                            let _acc = [...acc];
                            for(let testSet of next.testSet) {
                                for(let result of testSet.results) {
                                  _acc.push({...result, issuedDateTime: next.issuedDateTime});
                                }
                            }
                            return _acc;
                        }, []);
                        
                        const output = `${
                            results.map(result => `${result.comment}`).join('<br />')
                          }`;
                        return output;
                    } else {
                        return {error: 'Error while fetching imaging findings impression data'}
                    }
                }
            case 'pcp@pcc':
                {
                    const primaryCarePractitionerResponse:any = await this._pccService.getPrimaryCarePractitioner({
                      pccOrgUuid:note.facility.pcc_orgUuid,
                      pccPatientID:note.patient.pcc_patientId, 
                    }).toPromise();

                    if(primaryCarePractitionerResponse.status === 200 && primaryCarePractitionerResponse.data) {
                      const pcpName = [primaryCarePractitionerResponse.data.firstName, primaryCarePractitionerResponse.data.lastName]
                      .map(s => typeof s === 'string' ? s.trim() : '')
                      .filter(s => !!s.trim())
                      .join(' ');
                        return pcpName;
                    } else {
                        return {error: 'Error while fetching pcp data'}
                    }
                }

            case 'pcp@matrix':
              { 
                {
                  const pcpName = [note.patient.primary_practitioner_first_name, note.patient.primary_practitioner_last_name]
                  .map(s => typeof s === 'string' ? s.trim() : '')
                  .filter(s => !!s.trim())
                  .join(' ').trim();
                  if (pcpName) {
                    return pcpName;
                  } else {
                      return {error: 'No pcp data'}
                  }
                }
              }
              case 'dob@matrix': {
                if (note.patient.date_of_birth) {
                  // parse Feb 02, 1955
                  const [year, month, date] = note.patient.date_of_birth.split('-');
                  return `${month}/${date}/${year}`;
                } else {
                    return {error: 'No date of birth data'}
                }
              }
              case 'admitdate@matrix':
              {
                if(note.patient.last_admit_date_obj){
                  let year=note.patient.last_admit_date_obj.year;
                  let month=note.patient.last_admit_date_obj.month;
                  let day=note.patient.last_admit_date_obj.day;
                  if (month < 10) {month = '0' + month;}
                  if (day < 10) {day = '0' + day;}
                  return (month+'/'+day+'/'+year);
                }else 
                if (note.patient.last_admit_date) {
                  return moment(note.patient.last_admit_date).format('MM/DD/YYYY');
                } else {
                    return {error: 'No admit date data'}
                }
              }
              case 'namegender@matrix':
              if(note.patient) {
                const years = moment().diff(note.patient.date_of_birth, 'years');
                return  `${years} years old ${note.patient.gender.toLowerCase()}`;
              }
              case 'nameagesex@matrix':
              {
                const age = moment().diff(moment(note.patient.date_of_birth), 'years');
                const name = this._commonService.getPatientFullName(note.patient)
                let gender = note.patient.gender.toLowerCase();
                if(gender == 'm'){
                  gender = 'male'
                }
                else if(gender == 'f'){
                  gender = 'female'
                }
                return `${name} is ${age <= 80 ? 'a' : 'an'} ${age} year old ${gender}`
              }
              case 'allergies_list@matrix':
              {
                const allergiesResponse:any = await this.patientDetailService.getAllergyIntolerances({
                  patient_id: note.patient._id
                }).toPromise();
                if(allergiesResponse.status === 200) {
                    if(allergiesResponse.data.length === 0) {
                        return "No Known Drug Allergies"
                    }
                    const allergies = allergiesResponse.data
                    .filter(allergen => allergen.is_deleted === false)
                    .reduce((acc, next) => {
                        return [...acc, this._commonService.toTitleCase(next.allergen)];
                    }, []).join('<br />')
                    return allergies;
                } else {
                    return {error: 'Error while fetching allergies data'}
                }
              }
              case 'allergies_csl@matrix':
              {
                const allergiesResponse:any = await this.patientDetailService.getAllergyIntolerances({
                  patient_id: note.patient._id
                }).toPromise();
                if(allergiesResponse.status === 200) {
                    if(allergiesResponse.data.length === 0) {
                        return "No Known Drug Allergies"
                    }
                    const allergies = allergiesResponse.data
                    .filter(allergen => allergen.is_deleted === false)
                    .reduce((acc, next) => {
                        return [...acc, this._commonService.toTitleCase(next.allergen)];
                    }, []).join(', ')
                    return allergies;
                } else {
                    return {error: 'Error while fetching allergies data'}
                }
              }
              case 'dx@matrix':
              {
                const diagnosislistResponse:any = await this.patientDetailService.getPatientConditions({
                  patient_id: note.patient._id
                }).toPromise();
                if(diagnosislistResponse.status === 200) {
                    if(diagnosislistResponse.data.length === 0) {
                      return {error: "No diagnosis data available"}
                    } 
                    const diagnosislist = diagnosislistResponse.data.reduce((acc, next) => {
                      const icd10Description = this._commonService.toTitleCase(next.diagnosis_code_text);
                      return [...acc, icd10Description];
                    }, []).join('<br />')
                    return diagnosislist;
                } else {
                    return {error: 'Error while fetching diagnosis data'}
                }
              }
              // case 'room_no@dn':
              //     return note.patient?.last_room_num ||  {error: "Room No not available"};
              case 'painscore@pcc':
                {
                  const ObservationsResponse:any = await this._pccService.getObservations({
                    pccOrgUuid: note.facility.pcc_orgUuid,
                    pccPatientID: note.patient.pcc_patientId,
                    // pccPage: String(1),
                    authMode
                    }).toPromise();
                  if(ObservationsResponse.status === 200) {
                      if(ObservationsResponse.data.data.length === 0) {
                        return "No Known Pain Level recorded";
                      }
                      let observations = ObservationsResponse.data.data
                      .filter(obsv => (obsv.type == "painLevel"));
                      observations=observations.map((obs)=>{return `${'Pain Level: '}${obs.value}/10`});
                      if(observations.length === 0) {
                        return "No Known Pain Level recorded";
                      }
                      console.log("observations : ", observations);
                      return observations[0];
                  } else {
                      return {error: 'Error while fetching allergies data'}
                  }
                };
              case 'weight_height_bmi@pcc':
                  {
                    const ObservationsResponse:any = await this._pccService.getObservations({
                      pccOrgUuid: note.facility.pcc_orgUuid,
                      pccPatientID: note.patient.pcc_patientId,
                      // pccPage: String(1),
                      authMode
                      }).toPromise();
                    if(ObservationsResponse.status === 200) {
                        if(ObservationsResponse.data.data.length === 0) {
                          return "No Known Weight Height BMI recorded";
                        }
                        const observations = ObservationsResponse.data.data
                        .filter(obsv => (obsv.type == "weight")||(obsv.type == "height")||(obsv.type == "bmi"));
                        let phraseData='';
                        observations.forEach(obs => {
                          if(obs.type=='weight'){
                            phraseData+=`${'Weight: '}${obs.value} lbs, `;
                          }else if(obs.type=='height'){
                            phraseData+=`${'Height: '}${obs.value} inches, `;
                          }else if(obs.type=='bmi'){
                            phraseData+=`${'BMI: '}${obs.value}, `;
                          }
                        });
                        console.log("observations : ", phraseData);
                        phraseData=phraseData.slice(0, -2);
                        return phraseData;
                    } else {
                        return {error: 'Error while fetching weight_height_bmi data'}
                    }
                       
                  };
                  
            case 'medications@pcc':
                {
                    const { status, data }: any = await lastValueFrom(this._pccService.getPatientMedications({
                        pccOrgUuid: note.facility.pcc_orgUuid,
                        pccPatientId: note.patient.pcc_patientId,
                        pccFacID: note.facility.pcc_facId,
                        authMode
                    }));

                    if (status === 200) {
                        if (data.data.length > 0) {
                            return data.data.map(medication => medication.description).join("<br/>");
                        }
                    }
                    else {
                        return { error: 'Error while fetching getPatientMedications' }
                    }
                };
            case 'medications_long@pcc':
                {
                    const { status, data }: any = await lastValueFrom(this._pccService.getPatientMedications({
                        pccOrgUuid: note.facility.pcc_orgUuid,
                        pccPatientId: note.patient.pcc_patientId,
                        pccFacID: note.facility.pcc_facId,
                        authMode
                    }));

                    if (status === 200) {
                        if (data.data.length > 0) {
                            return data.data.map(medication =>
                                `${medication.description} ${medication.directions}`).join("<br/>");
                        }
                    }
                    else {
                        return { error: 'Error while fetching getPatientMedications' }
                    }
                };
                  case 'mrn@pcc':
                  {
                    if (note.patient && note.patient.pcc_payload && note.patient.pcc_payload.medicalRecordNumber) {
                      let mrn = '';
                      mrn = note.patient.pcc_payload.medicalRecordNumber;
                      return mrn;
                    } else {
                      return { error: 'Cant find pcc mrn' }
                    }
                  };
                  case 'mrn@matrix':
                  {
                    if (note.patient && note.patient.matrix_payload && note.patient.matrix_payload.matrix_mrn) {
                      let mrn = '';
                      mrn = note.patient.matrix_payload.matrix_mrn;
                      return mrn;
                    } else {
                      return { error: 'Cant find matrix mrn' }
                    }
                  };
                  case 'mrn@dn':
                  {
                    if (note.patient && note.patient.mrn) {
                      let mrn = '';
                      mrn = note.patient.mrn;
                      return mrn;
                    } else {
                      return { error: 'Cant find dn mrn' }
                    }
                  };
                  case 'facilityPatientMrn@dn':
                  {
                    if (note.patient && note.patient.facility_patient_mrn) {
                      let mrn = '';
                      mrn = note.patient.facility_patient_mrn;
                      return mrn;
                    } else {
                      return { error: 'Cant find facility_patient_mrn' }
                    }
                  };
            default: 
                return {error: ''}
        
              };
              
    }

    async prefetchSystemDefinedPhraseValues(note) {
        if(!note.patient) return;

        let resolvePhrases = [];
        console.log('Setup phrases for prefetching')
        for (const phrase of systemDefinedPhrases) {
            // save fetched data
            if(!this.phrasesData[note.patient._id]) {
                this.phrasesData[note.patient._id] = {}
            }
            // console.log(phrase, this.isAPIDependentPhrase(phrase) || (String(phrase.key).indexOf('prevnote:') >= 0))
            if(this.isAPIDependentPhrase(phrase) || (String(phrase.key).indexOf('prevnote:') >= 0)) {
              // console.log(phrase)
              resolvePhrases.push({
                phrase: phrase.key,
                value: this.resolveSystemDefinedPhraseValue(`${this.marker}${phrase.key}`, note)
              });
            }
            // const value = await this.resolveSystemDefinedPhraseValue(`${this.marker}${phrase.key}`, note);
            // if(this.phrasesData && typeof this.phrasesData[note.patient._id] === 'object' && this.phrasesData[note.patient._id] !== null) {
            //   this.phrasesData[note.patient._id][phrase.key] = value
            // }
        }
        const values = await Promise.all(resolvePhrases.map(e => e.value));
        for (const i in values) {
          if (Object.prototype.hasOwnProperty.call(values, i)) {
            const value = values[i];
            const phrase = resolvePhrases[i].phrase;
            if(this.phrasesData && typeof this.phrasesData[note.patient._id] === 'object' && this.phrasesData[note.patient._id] !== null) {
              this.phrasesData[note.patient._id][phrase.key] = value
            }
          }
        }
        return this.phrasesData[note.patient._id];
    }
    isAPIDependentPhrase(phrase) {
        return String(phrase.key).indexOf('@pcc') >= 0;
    }

    getPrevNoteSectionData(note, sectionTitle) {
        if(note.followUpNote && Array.isArray(note.followUpNote.data)) {
            const followUpNoteSections = note.followUpNote.data;
            const section = followUpNoteSections.find(section => section.title.toUpperCase().indexOf(sectionTitle.toUpperCase())>=0)
            return section.text.replace(/\n/g, '<br />') //+ ` <strong>${note.followUpNote._id}</strong>`;
        }
        return {
            error: `No data available for ${sectionTitle.toLowerCase()} in previous note`,
        };

    }
    getDynamicPhrases_old(phraseQuery, payload){
      const details = {
        payload,
        phraseQuery
      }
      return this.httpclient
          .post(global.url + API_URL.PHRASE.getDynamicPhrases_old, {data: details}).pipe();
    }
    getDynamicPhrases(phraseQuery, payload) {
        const details = {
          payload,
          phraseQuery
        }
        return this.httpclient
            .post(global.url + API_URL.PHRASE.getDynamicPhrases, {data: details}).pipe();
    };
    getWoundPhrases_old(patient_id) {
        const details = {
            patient_id
        }
        return this.httpclient
            .post(global.url + API_URL.PHRASE.getWoundPhrases_old, {data: details}).pipe();
    };
    getProcedurePhrases_old(details: { procedure_type_query, patient_id, company_id }) {
      return this.httpclient
          .post(global.url + API_URL.PHRASE.getProcedurePhrases_old, {data: details}).pipe();
    };
    getProcedurePhrases(procedure_type_query, patient_id, company_id) {
      const details = {
        procedure_type_query, patient_id, company_id
      }
      return this.httpclient
          .post(global.url + API_URL.PHRASE.getProcedurePhrases, {data: details}).pipe();
  };
    resolveWoundPhrase(wound_id?, patient_id?, include_icds?, created_by_user_type?, note_date_obj?, checkAddendums = false, checkICD10 = false) {
        const details : any = {
            wound_id,
            patient_id,
            include_icds,
            note_date_obj,
            checkAddendums,
            checkICD10,
            company_id: this.currentUser.company_id
        }
        if(created_by_user_type) {
          details.created_by_user_type = created_by_user_type;
        }
        return this.httpclient
            .post(global.url + API_URL.PHRASE.resolveWoundPhrase, {data: details}).pipe();
    };
    addDotphraseDate(key,enter_date,wound_id){
      const details = {
        key,
        enter_date,
        wound_id,
        user_id: this.currentUser._id
      }
      return this.httpclient.post(global.url + API_URL.WOUND.addDotphraseDate, {data: details}).pipe();
    }
    removeDotphraseDate(wound_id,enter_date){
      const details = {
        enter_date,
        wound_id,
      }
      return this.httpclient.post(global.url + API_URL.WOUND.removeDotphraseDate, {data: details}).pipe();
    }
    resolveProcedurePhrase(data: {procedure_type_id?, dataset_id?, patient_id?, wound_id?}) {
        return this.httpclient
            .post(global.url + API_URL.PHRASE.resolveProcedurePhrase, {data}).pipe();
    }
  resolveWoundProcedurePhrase(data: { procedure_type_id?, dataset_id?, patient_id?, wound_id?}) {
    return this.httpclient
      .post(global.url + API_URL.PHRASE.resolveWoundProcedurePhrase, { data }).pipe();
  }
    resolveAllergiesPhrase(patient_id, date_of_service?) {
      const details = {
        patient_id,
        date_of_service
      }
      return this.httpclient
          .post(global.url + API_URL.PHRASE.resolveAllergiesPhrase, {data: details}).pipe();
  }

  resolvePatientMedicalHistoryPhrase(patient_id) {
      const details = {
        patient_id,
      }
      return this.httpclient
          .post(global.url + API_URL.PHRASE.resolvePatientMedicalHistoryPhrase, {data: details}).pipe();
  }

  filterPhrasesBySource( phrasesArray, source, phraseKeyToMatch = 'key' ) {
    return phrasesArray.filter( phrase => {
      if ( !phrase || source == null || source === '' ) {
        return true;
      }

      let phraseText = phrase[ phraseKeyToMatch ];

      if ( phraseText == null ) {
        return true;
      }

      phraseText = String( phraseText ).toLowerCase();

      // if ( phrasesShouldNotFilteredOut.includes( phraseText ) === true ) {
      //   return true;
      // }

      const currentPhraseSource = extractPhraseSource( phraseText );

      if ( source === 'dn' ) {
        return [ 'pcc', 'matrix' ].includes( currentPhraseSource ) === false;
      }

      if ( source === 'pcc' ) {
        return currentPhraseSource !== 'matrix';
      }

      if ( source === 'matrix' ) {
        return currentPhraseSource !== 'pcc';
      }

      return currentPhraseSource === source;
    });
  }
  async addremovedFieldsinPhrase(phraseKey,sub_phrase_key, phraseText, value, extended_header_id = null){
    await this.initWoundDropdownTemplates();
    const columnsAndFieldsMapping = {
      exudate : 'exudate_amount',
      infection_signs: 'wound_infection',
      exposed_structure: 'wound_edges'
    }
    let phraseTemplate;
    const phraseIndex = woundPhraseTemplateResponse.findIndex((ele)=> ele.key === phraseKey);
    if(phraseIndex > -1){
      phraseTemplate = woundPhraseTemplateResponse[phraseIndex].woundPhraseTemplate;
    }else{
      const response = await lastValueFrom(this.woundService.getSystemDefinedPhraseTemplateByPhraseKey(phraseKey)) as any;
      if(response.status === 200) {
        woundPhraseTemplateResponse.push({
          key: phraseKey,
          woundPhraseTemplate: response.data.template
        });
        phraseTemplate = response.data.template;
      }
    }
      const $tmpphraseText = document.createElement('div');
      $tmpphraseText.innerHTML = phraseText.join('<br>')
      // const woundPhraseTemplate = woundPhraseTemplateResponse.data;
      // phraseTemplate = woundPhraseTemplate.template;
      const $tmp = document.createElement('div');
      $tmp.innerHTML = phraseTemplate;
      let paragraphs = [];
      const $tags:any[] = Array.from($tmp.childNodes).filter($e => $e.nodeType === Node.ELEMENT_NODE);
      for (const $tag of $tags) {
        if($tag.tagName === 'P') {
          paragraphs.push($tag.innerHTML);
        } else {
          paragraphs.push($tag.outerHTML);
        }
      }
      paragraphs = paragraphs.join('<br>').split('<br>')
      const subphraseIndex = paragraphs.findIndex((phrase)=> phrase.includes(sub_phrase_key));
      if (subphraseIndex < 0) return $tmpphraseText.innerHTML;
        const headingofSubphraseKey = paragraphs[subphraseIndex].split(":")[0];
        const allKeysinParagraphsubStr = this.findPhraseKeysinStrings(paragraphs[subphraseIndex]);
        if ($tmpphraseText.innerText.includes(headingofSubphraseKey)) {
          const allSelectorElements = Array.from($tmpphraseText.querySelectorAll("*"));
          const isWoundObjMapping = Object.prototype.hasOwnProperty.call(columnsAndFieldsMapping, sub_phrase_key);
          const key = isWoundObjMapping ? columnsAndFieldsMapping[sub_phrase_key] : sub_phrase_key
          for (const element of allSelectorElements) {
            if (element.innerHTML.toString().includes(headingofSubphraseKey.trim())) {
              let subphrasetext = this.woundDropdownTemplates[key];
              const $tmpsubphrase = document.createElement('div');
              $tmpsubphrase.innerHTML = subphrasetext;
              subphrasetext = this.addValuesinDropdown($tmpsubphrase, key, sub_phrase_key, value, extended_header_id);
              if (headingofSubphraseKey.trim() === "Wound Base") {
                element.append(",");
              }
              if (sub_phrase_key === 'exudate') {
                element.innerHTML = this.addSubstringAfterCharacter(element.innerHTML, ":", subphrasetext);
              } else {
                element.innerHTML = element.innerHTML + subphrasetext;
              }
              return $tmpphraseText.innerHTML;
            }
          }
        } else {
          allKeysinParagraphsubStr.forEach((pKey) => {
            const isWoundObjMapping = Object.prototype.hasOwnProperty.call(columnsAndFieldsMapping, pKey);
            const key = isWoundObjMapping ? columnsAndFieldsMapping[pKey] : pKey
            if (this.woundDropdownTemplates) {
              let subphrasetext = this.woundDropdownTemplates[key];
              const $tmpsubphrase = document.createElement('div');
              $tmpsubphrase.innerHTML = subphrasetext;
              if(pKey === sub_phrase_key && (pKey === 'tunneling' || pKey === 'under_mining')){
                subphrasetext = `<span data-label="${pKey}" data-wound-field="${pKey}" class="sub-phrase sub-phrase-${pKey}">${value}</span>`;
              }else if (pKey === sub_phrase_key && value) {
                subphrasetext = this.addValuesinDropdown($tmpsubphrase, key, sub_phrase_key, value, extended_header_id);
              }
              paragraphs[subphraseIndex] = paragraphs[subphraseIndex].replace(new RegExp(`\\.${pKey}\\b`, 'g'), subphrasetext);
            }
          });
          if (phraseText.length < subphraseIndex) {
            phraseText.push(`<span data-sub-key="optional">${paragraphs[subphraseIndex]}</span>`)
          } else {
            // sub_phrase_key === 'tunneling' || sub_phrase_key === 'under_mining' ? subphraseIndex - 1 : 
            phraseText.splice(subphraseIndex, 0, `<span data-sub-key="optional">${paragraphs[subphraseIndex]}</span>`);
          }
          phraseText = removeUnSelectedFields(phraseKey, phraseText.join('<br>'), false).split('<br>').filter((ele) => ele.trim() !== "").join('<br>')
          return phraseText;
        }
      // }
  }
  addValuesinDropdown($tmpsubphrase, key, sub_phrase_key,value, extended_header_id){
    const $dropdown = $tmpsubphrase.querySelector('.select-dropdown');
    if (key === 'epithelial' || key === 'slough' || key === 'granulation' || key === 'eschar') {
      if (!value.includes(key.toString())) {
        value = value.trim() + ` ${key}`;
      }
    }
    if (multiSelect_attributes.includes(key) || (key === 'wound_edges' && multiSelect_attributes.includes('exposed_structure'))) {
      $dropdown.setAttribute('data-options-selected', value.split(', ').join('|'));
    } else {
      $dropdown.setAttribute('data-options-selected', value);
    }
    $dropdown.innerHTML = value;
    return extended_header_id ? `<span data-extended-header="${extended_header_id}" data-wound-field="${sub_phrase_key}">${$tmpsubphrase.innerHTML}</span>` : `<span data-wound-field="${sub_phrase_key}">${$tmpsubphrase.innerHTML}</span>`;
  }
  addSubstringAfterCharacter(str, character, substring) {
    const index = str.indexOf(character);
    if (index !== -1) {
      const newStr = str.slice(0, index + 1) + substring + str.slice(index + 1);
      return newStr;
    }
    return str;
  }
  findPhraseKeysinStrings(inputString: string): string[] {
    const regex = /\.(\w+)/g;
    const matches = inputString.match(regex);
    if (matches) {
      return matches.map(match => match.slice(1)); // Remove the leading period
    } else {
      return [];
    }
  }
  cloneAddendumPopup(wound, phrase_id, phraseTemplate){
    return new Promise((resolve,reject) => {
      swal({
        title: "Create Addendum",
        text: "Wound is assessed on same DOS and is locked. Do you want to create the Addendum?",
        icon: "warning",
        buttons: [
          "No", "Yes"
        ],
        dangerMode: true,
      })
        .then(async (create_addendum) => {
          if (create_addendum) {
  
            console.log("must create addendum after cloning the wound object");
            const clonedWoundResult = await lastValueFrom(this.woundService.cloneWound(wound._id)) as any;
            const clonedWound = JSON.parse(JSON.stringify(clonedWoundResult.data));
            this._toastr.success("Wound Cloned Successfully");
  
            const { data: wound2, status } = await lastValueFrom(this.resolveWoundPhrase(phrase_id)) as any;
            if (status == 200) {
              const parentWound2 = wound2;
              // wound._id= parentWound2._id;
              const [latestChildWoundWound2, ...restChildWounds2] = wound2.child_wounds;
              clonedWound._id = wound2._id;
              clonedWound.woundNo = parentWound2.woundNo;
              const respns = woundAssessmentPhraseTemplate(clonedWound, clonedWound, restChildWounds2, phraseTemplate, { woundDropdownTemplates: this.woundDropdownTemplates, addendum_id: clonedWoundResult.data._id });
              console.log("respns respns : ", respns);
              resolve(respns);
            }
  
          }
          else {
            resolve({});
            console.log("not create addendum");
          }
        })
    })
  }
  
  async resolveProcedurePhraseTemplate(data, extra: { should_show_procedure_note: boolean, should_show_wound_location: boolean }) {
    const template = extra.should_show_procedure_note ? data.dataset.procedure_note_template : data.dataset.template;
    if (!template) {
      return `<span class="content-hidden" data-sub-phrase-key="woundprocedure" data-procedure="${data.procedureNoteData._id}" data-wound="${data.procedureNoteData.wound_id}">&nbsp;</span>`;
    }
    const parentWound = data['wound'];
    const [latestChildWoundWound, ...restChildWounds] = Array.isArray(parentWound.child_wounds) ? parentWound.child_wounds : [];
    const latestWound = latestChildWoundWound || parentWound;
    if (extra.should_show_procedure_note) {
      await this.initProcedureNotesDropdownTemplates(data.procedure_type._id);
    }
    const procedureTemplate = await procedureNotesPhraseTemplate(data.procedureNoteData, template, this.procedureNotesDropdownTemplates[`procedure_${data.procedure_type._id}`], 'woundprocedure');
    return await woundAssessmentPhraseTemplate(parentWound, latestWound, restChildWounds, procedureTemplate, { woundDropdownTemplates: this.woundDropdownTemplates, should_show_wound_location: extra.should_show_wound_location }, 'woundprocedure', false);
  }
}

function woundPhraseTemplate(parentWound, latestWound, restChildWounds) {
  // return `<u>Wound # ${parentWound.woundNo || ''} ${parentWound.body_part || ''} ${parentWound.etiolgy || ''}</u> <br />
  // Size: ${calculateArea(latestWound)} (Area), ${calculateVolume(latestWound)} (Volume). <br />
  // Severity: ${latestWound.stage || ''} <br />
  // Wound Base: ***% epithelial, ***% granulation, ***% slough, ***% eschar. <br />
  // Exposed Structures: *** muscle, *** tendon/ligament, *** bone. <br />
  // Wound Edges: *** Attached, *** epibole, *** tunneling, *** undermining. <br />
  // Periwound: *** texture, *** maceration, *** color, *** temperature. <br />
  // Exudate: *** amount of *** drainage. ${latestWound.odor || ''} odor present after cleansing. <br />
  // Infection/Biofilm: *** evidence of *** infection today. <br />
  // Pain: ${latestWound.pain || ''}/10  reported at rest.`
  // return `<b>Wound:</b> ${parentWound.woundNo || ''}
  // Location: ${parentWound.body_part || ''} 
  // Primary Etiology: ${parentWound.etiolgy || ''}
  // Severity: ${latestWound.stage || ''}
  // Size: ${latestWound.length} x ${latestWound.width} x ${latestWound.depth} cm.  Actual area ${calculateArea(latestWound)} cm2.  Actual volume is ${calculateVolume(latestWound)} cm3.
  // Undermining: from __ o’clock to __ o’clock, __cm from mobile or kent.  
  // Tunneling: ___ o’clock, __cm from mobile or kent **only display if data is entered 
  // Wound Base: <span class="asteriskjump">***</span>% epithelialization, <span class="asteriskjump">***</span>% granulation, <span class="asteriskjump">***</span>% slough, <span class="asteriskjump">***</span>% eschar.
  // Wound Edges: <span class="asteriskjump">***</span> 
  // Periwound: <span class="asteriskjump">***</span> callus, <span class="asteriskjump">***</span> maceration, <span class="asteriskjump">***</span> no hemosiderin staining. 
  // Exudate: <span class="asteriskjump">***</span> amount of <span class="asteriskjump">***</span> exudate.  ${latestWound.odor || ''} odor.
  // Signs of Wound Infection: <span class="asteriskjump">***</span> delayed wound closure, <span class="asteriskjump">***</span> increasing debris, exudate is <span class="asteriskjump">***</span> increasing, <span class="asteriskjump">***</span> friable hypergranulation tissue, <span class="asteriskjump">***</span> odor after cleansing.  Size is <span class="asteriskjump">***</span> getting larger, temperature is <span class="asteriskjump">***</span> increasing, <span class="asteriskjump">***</span> bone palpated, <span class="asteriskjump">***</span> new periwound breakdown, <span class="asteriskjump">***</span> erythema <span class="asteriskjump">***</span> edema.
  // Biofluorescent Imaging: Porphyrin <span class="asteriskjump">***</span>, cyan <span class="asteriskjump">***</span> 
  // Wound Pain at Rest: <span class="asteriskjump">***</span>/10`;
  const highlightColor = '#ff5630'
  let icds = [];
  if(parentWound.wound_diagnosis) {
    icds = parentWound.wound_diagnosis.icd_id.map(icd => `${icd.code} (${icd.description})`)
  }
  const bodySideLabel = {
    'R': 'Right',
    'L': 'Left',
    'BL': 'Bilateral'
  }
  const bodySide = bodySideLabel[parentWound.body_side] || ''
  const under_mining = (latestWound.undermining_to && latestWound.undermining_from && latestWound.undermining_distance) ? `<b>Undermining:</b> from ${latestWound.undermining_from} o’clock to ${latestWound.undermining_to} o’clock, ${latestWound.undermining_distance} cm.` : '';
  const tunneling = (latestWound.tunneling_direction && latestWound.tunneling_distance) ? `<b>Tunneling:</b> ${latestWound.tunneling_direction} o’clock, ${latestWound.tunneling_distance} cm.` : '';
  const exudate = (latestWound.exudate && latestWound.color) ? `<b>Exudate:</b> ${latestWound.exudate} amount of ${latestWound.color} exudate.` : '';
  const odor = (latestWound.odor) ? `${(latestWound.odor === 'None' ? 'No' : latestWound.odor) || ''} odor.` : '';
  return `
  <u>Wound <span style="color:${highlightColor}">#${parentWound.woundNo || ''}</span> <span style="color:${highlightColor}">${bodySide}</span> <span style="color:${highlightColor}">${parentWound.direction || ''}</span> <span style="color:${highlightColor}">${parentWound.direction || ''}</span> <span style="color:${highlightColor}">${parentWound.body_part || ''}</span> <span style="color:${highlightColor}">${parentWound.etiolgy || ''}</span> <span style="color:${highlightColor}">${latestWound.stage || ''}</span>: <span class="asteriskjump">***</span></u>
  Size: ${latestWound.length} x ${latestWound.width} x ${latestWound.depth} cm.  Actual area ${calculateArea(latestWound)} cm2.  Actual volume is ${calculateVolume(latestWound)} cm3.
  ${under_mining}
  ${tunneling} 
  Wound Base: <span class="asteriskjump">***</span>% epithelialization, <span class="asteriskjump">***</span>% granulation, <span class="asteriskjump">***</span>% slough, <span class="asteriskjump">***</span>% eschar.
  Wound Edges: <span class="asteriskjump">***</span> 
  Periwound: <span class="asteriskjump">***</span> callus, <span class="asteriskjump">***</span> maceration, <span class="asteriskjump">***</span> no hemosiderin staining. 
  ${exudate}
  ${odor}
  Signs of Wound Infection: <span class="asteriskjump">***</span> delayed wound closure, <span class="asteriskjump">***</span> increasing debris, exudate is <span class="asteriskjump">***</span> increasing, <span class="asteriskjump">***</span> friable hypergranulation tissue, <span class="asteriskjump">***</span> odor after cleansing.  Size is <span class="asteriskjump">***</span> getting larger, temperature is <span class="asteriskjump">***</span> increasing, <span class="asteriskjump">***</span> bone palpated, <span class="asteriskjump">***</span> new periwound breakdown, <span class="asteriskjump">***</span> erythema <span class="asteriskjump">***</span> edema.
  Biofluorescent Imaging: Porphyrin <span class="asteriskjump">***</span>, cyan <span class="asteriskjump">***</span> 
  Wound Pain at Rest: ${latestWound.pain || ''}/10`.split('\n').filter(s => s.trim()).join('<br />')
  +
 `
 <b>Diagnosis</b>
  ${icds.join('\n')}

  Treatment Recommendations:
  Pain control: prior to all wound treatments and dressing changes, please provide PRN pain medication and/or topical anesthetic.
  Cleansing: scrub wound bed, and 10-20cm of periwound, with wound cleanser for 30-60 seconds.
  Periwound: apply skin prep to periwound and allow to dry.
  Wound Bed: apply <span class="asteriskjump">***</span> to base of the wound. 
  Cover Dressing: secure with <span class="asteriskjump">***</span>.
  Frequency: change <span class="asteriskjump">***</span> and as needed for accidental removal, saturation and/or soiling.
  `.split('\n').join('<br />')
}

function woundPlanPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate?, extras: any = {}, firstPhrase = false) {
  let icds = [];
  if(parentWound.wound_diagnosis && parentWound.wound_diagnosis.icd_id) {
    icds = parentWound.wound_diagnosis.icd_id.map(icd => `${icd.code} (${icd.description})`)
  }
  
  if(phraseTemplate) {
    const subPhraseValuesMap = {
      [SubPhrase.woundno]: parentWound.woundNo?? '',
      // [SubPhrase.woundlocation]: woundLocation(parentWound),
      [SubPhrase.etiology]: parentWound.etiolgy?? '',
      [SubPhrase.severity]: latestWound.stage?? ''      
    }
    if(extras.should_show_wound_location){
      subPhraseValuesMap["woundlocation"] = woundLocation(parentWound);
    }
    return resolveSubPhraseValues(subPhraseValuesMap, phraseTemplate, {...extras, sub_phrase_key:'woundplan', parentWound}, firstPhrase);
  }
  return `<u>Wound # ${parentWound.woundNo?? ''} ${woundLocation(parentWound)} ${parentWound.etiolgy || ''} <span class="select-dropdown" data-options="New problem|Improving|Deteriorating|Stable" data-label="* * *">* * *</span>

  Treatment Recommendations:
  1: clean with wound cleanser.
  2: apply skin prep to periwound and allow to dry.
  3: apply <span class="asteriskjump">***</span> to base of the wound. 
  4: secure with <span class="asteriskjump">***</span>.
  5: change <span class="asteriskjump">***</span> and as needed for accidental removal, saturation and/or soiling.
  `.split('\n').join('<br />')
  
}

function woundHistoryPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate?, extras: any = {}) {
  const woundNo = parentWound.woundNo ?? asteriskJumpSpanHTML;
  const severity = latestWound.stage ?? asteriskJumpSpanHTML;
  const etiology = parentWound.etiolgy ?? asteriskJumpSpanHTML;
  const location = woundLocation(parentWound) ?? asteriskJumpSpanHTML;
  const totalWeeks = calculateTimeDifference(latestWound, parentWound) ?? asteriskJumpSpanHTML;
  

  const changeInArea = (100 - ((calculateArea(latestWound) / calculateArea(parentWound)) * 100)).toFixed(2) ?? asteriskJumpSpanHTML;
  const changeInVolume = (100 - ((calculateVolume(latestWound) / calculateVolume(parentWound)) * 100)).toFixed(2) ?? asteriskJumpSpanHTML;

  if(phraseTemplate) {
    const internalPhraseValuesMap = {
      [SubPhrase.woundno]: parentWound.woundNo || '',
      [SubPhrase.woundlocation]: woundLocation(parentWound),
      [SubPhrase.etiology]: parentWound.etiolgy || '',
      [SubPhrase.severity]: latestWound.stage || '',
      [SubPhrase.area]: calculateArea(latestWound) || '',
      [SubPhrase.volume]: calculateVolume(latestWound) || '',
      [SubPhrase.total_weeks]: totalWeeks,
      [SubPhrase.change_in_area]: changeInArea,
      [SubPhrase.change_in_volume]: changeInVolume,
    }
    return resolveSubPhraseValues(internalPhraseValuesMap, phraseTemplate, {...extras, sub_phrase_key:'woundhistory', parentWound});
  }

  return `Wound #${woundNo} is a ${severity} ${etiology} located at the ${location}. It has been treated for ${totalWeeks}, resulting in a ${changeInArea}% change in actual wound area, and ${changeInVolume}% change in wound volume. The current treatment plan has included <span class="select-dropdown" data-multiselect="true" data-options="moist wound healing|wound hygiene|antibiofilm dressings|collagen dressings to promote granulation|offloading and pressure reduction|excisional debridement|dietary interventions|Kerecis tissue grafting|compounded lipogel Rx" data-label="* * *">* * *</span>. Compliance with this treatment plan has been <span class="select-dropdown" data-options="adequate|inadequate" data-label="* * *">* * *</span>. Since the last encounter, nursing and/or the resident have noted <span class="select-dropdown" data-options="no change in|less|increased" data-label="* * *">* * *</span> drainage and <span class="select-dropdown" data-options="no|less|increased" data-label="* * *">* * *</span> wound pain <span class="select-dropdown" data-options="with wound care and dressing changes only|while resting and no wound care|both at rest and with wound care and dressing changes" data-label="* * *">* * *</span>.
  `.split('\n').join('<br />')
}

function procedureNotesPhraseTemplate(procedureData, phraseTemplate, dropdownTemplates, sub_phrase_key) {
  return resolveProcedureNotePhraseValues(phraseTemplate, dropdownTemplates, { phrase_key: 'procedure', sub_phrase_key, data_key_attr: 'procedure_note_data', selectedData: procedureData })
}

function woundAssessmentPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate?, extras: any = {}, sub_phrase_key = "woundassessment", appendDataSubPhraseKey = true) {
  let under_mining = "";
  let tunneling = "";
  if(latestWound.under_mining === true && latestWound.undermining_to != "" && latestWound.undermining_from != ""){
    under_mining = `from ${latestWound.undermining_from} o’clock to ${latestWound.undermining_to} o’clock`;
  }else if(latestWound.under_mining === true && (latestWound.undermining_to !== "" || latestWound.undermining_from !== "")){
    if(latestWound.undermining_to == ""){
      under_mining = `${latestWound.undermining_from} o’clock`;
    }else{
      under_mining = `${latestWound.undermining_to} o’clock`;
    }
  }
  if(latestWound.under_mining === true && latestWound.undermining_distance != ""){
    under_mining = under_mining != "" ? under_mining + `, ${latestWound.undermining_distance} cm.` : `${latestWound.undermining_distance} cm.`;
  }
  if(latestWound.tunneling === true && latestWound.tunneling_direction != ""){
    tunneling = `${latestWound.tunneling_direction} o’clock`;
  }
  if(latestWound.tunneling === true && latestWound.tunneling_distance != ""){
    tunneling = tunneling != "" ? tunneling + `, ${latestWound.tunneling_distance} cm.` : `${latestWound.tunneling_distance} cm.`;
  }
  let odor = '';
  if(latestWound.odor && latestWound.odor.length > 0 && latestWound.odor !== 'None' && latestWound.odor.length !== 'No') {
    odor = latestWound.odor;
  }

  let exudate = (latestWound.exudate) ? `Exudate: ${latestWound.exudate} amount of ${latestWound.color ? latestWound.color : ''} exudate${odor ? `, ${odor} odor.` : '.'}` : '';
  let exudate_type = (latestWound.exudate_type) ? `Exudate Type: ${latestWound.exudate_type}` : '';
  // let odor = (latestWound.odor) ? `<b>Odor:</b> ${(latestWound.odor === 'None' ? 'No' : latestWound.odor) || ''}` : '';
  if(phraseTemplate) {
    const internalPhraseValuesMap = {
      [SubPhrase.woundno]: parentWound.woundNo ?? '',
      [SubPhrase.etiology]: parentWound.etiolgy ?? '',
      [SubPhrase.severity]: latestWound.stage ?? '',
      [SubPhrase.length]: latestWound.length ?? '',
      [SubPhrase.width]: latestWound.width ?? '',
      [SubPhrase.depth]: latestWound.depth ?? '',
      [SubPhrase.area]: calculateArea(latestWound) ?? '',
      [SubPhrase.volume]: calculateVolume(latestWound) ?? '',
      [SubPhrase.under_mining]: under_mining,
      [SubPhrase.tunneling]: tunneling,
    }
    if(extras.should_show_wound_location){
      internalPhraseValuesMap["woundlocation"] = woundLocation(parentWound);
    }
    return resolveSubPhraseValues(internalPhraseValuesMap, phraseTemplate, {...extras, sub_phrase_key: sub_phrase_key, parentWound, appendDataSubPhraseKey: appendDataSubPhraseKey});
  }
  let under_mining_with_label = (latestWound.under_mining && latestWound.undermining_to && latestWound.undermining_from && latestWound.undermining_distance) ? `Undermining: ${under_mining}` : ''; 
  let tunneling_with_label = (latestWound.tunneling && latestWound.tunneling_direction && latestWound.tunneling_distance) ? `Tunneling: ${tunneling}` : '';
  
  return `Wound: ${parentWound.woundNo || ''}
  Location: ${woundLocation(parentWound)} 
  Primary Etiology: ${parentWound.etiolgy || ''}
  Severity: ${latestWound.stage || ''}
  Size: ${latestWound.length} x ${latestWound.width} x ${latestWound.depth} cm.  Actual area ${calculateArea(latestWound)} <var>cm<sup>2</sup></var>.  Actual volume is ${calculateVolume(latestWound)} <var>cm<sup>3</sup></var>.
  ${under_mining_with_label}
  ${tunneling_with_label} 
  Wound Base: <span class="select-dropdown" data-options="no|1-10%|11-25%|26-50%|51-75%|76-100%" data-label="%" data-multiselect="false" contenteditable="false">%</span> epithelialization, <span class="select-dropdown" data-options="no|1-10%|11-25%|26-50%|51-75%|76-100%" data-label="%" data-multiselect="false" contenteditable="false">%</span> granulation, <span class="select-dropdown" data-options="no|1-10%|11-25%|26-50%|51-75%|76-100%" data-label="%" data-multiselect="false" contenteditable="false">%</span> slough, <span class="select-dropdown" data-options="no|1-10%|11-25%|26-50%|51-75%|76-100%" data-label="%" data-multiselect="false" contenteditable="false">%</span> eschar.
  ${parentWound.exposed_tissues ? 'Exposed Tissues: ' + parentWound.exposed_tissues : ''}
  Wound Edges: <span class="select-dropdown" data-options="attached|flat|epiboly|unattached" data-label="Edges" data-multiselect="false" contenteditable="false">Edges</span> 
  Periwound: <span class="select-dropdown" data-options="normal temperature|macerated|dry|callus|rash|no erythema|erythema|fluctuant|increased temperature|denuded|scar|edema|venous congestion" data-label="Periwound" data-multiselect="true" contenteditable="false">Periwound</span> 
  ${exudate}
  ${exudate_type}
  Signs of Wound Infection: <span class="select-dropdown" data-options="none|non-healing wound|friable tissue|increased necrotic tissue|wound breakdown|satellite lesions|increased exudate|increased odor|palpable bone" data-label="Infection SSx" data-multiselect="true" contenteditable="false">Infection SSx</span> 
  Biofluorescent Imaging: <span class="select-dropdown" data-options="Porphyrin -|Cyan -|Porphyrin +|Cyan +" data-label="woods lamp" data-multiselect="true" contenteditable="false">woods lamp</span> 
  Wound Pain at Rest: ${latestWound.pain || ''}/10
  `.split('\n').filter(s => s.trim()).join('<br />')
}
function woundassessmentshortPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate?, extras: any = {}){
  if(phraseTemplate) {
    const internalPhraseValuesMap = {
      [SubPhrase.woundno]: parentWound.woundNo ?? '',
      // [SubPhrase.woundlocation]: woundLocation(parentWound),
      [SubPhrase.etiology]: parentWound.etiolgy ?? '',
      [SubPhrase.severity]: latestWound.stage ?? ''
    }
    if(extras.should_show_wound_location){
      internalPhraseValuesMap["woundlocation"] = woundLocation(parentWound);
    }
    return resolveSubPhraseValues(internalPhraseValuesMap, phraseTemplate, {...extras, sub_phrase_key:'woundassessmentshort', parentWound});

  }
}
function nursewoundassessmentPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate?, extras: any = {}) {
  let under_mining = (latestWound.under_mining && latestWound.undermining_to && latestWound.undermining_from && latestWound.undermining_distance) ? `from ${latestWound.undermining_from} o’clock to ${latestWound.undermining_to} o’clock, ${latestWound.undermining_distance} cm.` : ''; 
  let tunneling = (latestWound.tunneling && latestWound.tunneling_direction && latestWound.tunneling_distance) ? `${latestWound.tunneling_direction} o’clock, ${latestWound.tunneling_distance} cm.` : '';
  

  let odor = '';
  if(latestWound.odor && latestWound.odor.length > 0 && latestWound.odor !== 'None' && latestWound.odor.length !== 'No') {
    odor = latestWound.odor;
  }

  let exudate = (latestWound.exudate) ? `Exudate: ${latestWound.exudate} amount of ${latestWound.color ? latestWound.color : ''} exudate${odor ? `, ${odor} odor.` : '.'}` : '';
  let exudate_type = (latestWound.exudate_type) ? `Exudate Type: ${latestWound.exudate_type}` : '';
  // let odor = (latestWound.odor) ? `<b>Odor:</b> ${(latestWound.odor === 'None' ? 'No' : latestWound.odor) || ''}` : '';
  console.log(phraseTemplate)
  if(phraseTemplate) {
    const internalPhraseValuesMap = {
      [SubPhrase.woundno]: parentWound.woundNo ?? '',
      [SubPhrase.etiology]: parentWound.etiolgy ?? '',
      [SubPhrase.severity]: latestWound.stage ?? '',
      [SubPhrase.length]: latestWound.length ?? '',
      [SubPhrase.width]: latestWound.width ?? '',
      [SubPhrase.depth]: latestWound.depth ?? '',
      [SubPhrase.area]: calculateArea(latestWound) ?? '',
      [SubPhrase.volume]: calculateVolume(latestWound) ?? '',
      [SubPhrase.under_mining]: under_mining,
      [SubPhrase.tunneling]: tunneling,
    }
    if(extras.should_show_wound_location){
      internalPhraseValuesMap["woundlocation"] = woundLocation(parentWound);
    }
    return resolveSubPhraseValues(internalPhraseValuesMap, phraseTemplate, {...extras, sub_phrase_key:'nursewoundassessment', parentWound});
  }
  let under_mining_with_label = (latestWound.under_mining && latestWound.undermining_to && latestWound.undermining_from && latestWound.undermining_distance) ? `Undermining: ${under_mining}` : ''; 
  let tunneling_with_label = (latestWound.tunneling && latestWound.tunneling_direction && latestWound.tunneling_distance) ? `Tunneling: ${tunneling}` : '';
  
  return `Wound: ${parentWound.woundNo || ''}
  Location: ${woundLocation(parentWound)} 
  Primary Etiology: ${parentWound.etiolgy || ''}
  Severity: ${latestWound.stage || ''}
  Size: ${latestWound.length} x ${latestWound.width} x ${latestWound.depth} cm.  Actual area ${calculateArea(latestWound)} <var>cm<sup>2</sup></var>.  Actual volume is ${calculateVolume(latestWound)} <var>cm<sup>3</sup></var>.
  ${under_mining_with_label}
  ${tunneling_with_label} 
  Wound Base: <span class="select-dropdown" data-options="no|1-10%|11-25%|26-50%|51-75%|76-100%" data-label="%" data-multiselect="false" contenteditable="false">%</span> epithelialization, <span class="select-dropdown" data-options="no|1-10%|11-25%|26-50%|51-75%|76-100%" data-label="%" data-multiselect="false" contenteditable="false">%</span> granulation, <span class="select-dropdown" data-options="no|1-10%|11-25%|26-50%|51-75%|76-100%" data-label="%" data-multiselect="false" contenteditable="false">%</span> slough, <span class="select-dropdown" data-options="no|1-10%|11-25%|26-50%|51-75%|76-100%" data-label="%" data-multiselect="false" contenteditable="false">%</span> eschar.
  ${parentWound.exposed_tissues ? 'Exposed Tissues: ' + parentWound.exposed_tissues : ''}
  Wound Edges: <span class="select-dropdown" data-options="attached|flat|epiboly|unattached" data-label="Edges" data-multiselect="false" contenteditable="false">Edges</span> 
  Periwound: <span class="select-dropdown" data-options="normal temperature|macerated|dry|callus|rash|no erythema|erythema|fluctuant|increased temperature|denuded|scar|edema|venous congestion" data-label="Periwound" data-multiselect="true" contenteditable="false">Periwound</span> 
  ${exudate}
  ${exudate_type}
  Signs of Wound Infection: <span class="select-dropdown" data-options="none|non-healing wound|friable tissue|increased necrotic tissue|wound breakdown|satellite lesions|increased exudate|increased odor|palpable bone" data-label="Infection SSx" data-multiselect="true" contenteditable="false">Infection SSx</span> 
  Biofluorescent Imaging: <span class="select-dropdown" data-options="Porphyrin -|Cyan -|Porphyrin +|Cyan +" data-label="woods lamp" data-multiselect="true" contenteditable="false">woods lamp</span> 
  Wound Pain at Rest: ${latestWound.pain || ''}/10
  `.split('\n').filter(s => s.trim()).join('<br />')
}

function resolveProcedureNotePhraseValues(phraseTemplate, dropdownTemplates, extras: { phrase_key: string, sub_phrase_key: string, data_key_attr: string, selectedData: any }) {
  let populatedPhraseTemplate = phraseTemplate;
  const textElement = document.createElement('div');
  textElement.innerHTML = phraseTemplate;
  const paragraphs = [];
  const $tags: any[] = Array.from(textElement.childNodes).filter($e => $e.nodeType === Node.ELEMENT_NODE);
  for (const $tag of $tags) {
    if ($tag.tagName === 'P') {
      paragraphs.push($tag.innerHTML);
    } else {
      paragraphs.push($tag.outerHTML);
    }
  }
  const { selectedData } = extras;
  populatedPhraseTemplate = `<span data-sub-phrase-key="${extras?.sub_phrase_key}" data-${extras.phrase_key}="${extras?.selectedData._id}" data-wound="${selectedData.wound_id}">${paragraphs.join('<br>')}</span>`;
  dropdownTemplates.forEach((template) => {
    if (populatedPhraseTemplate.includes(`.${template.phraseKey}`)) {
      const procedureData = selectedData[`${extras.data_key_attr}`].find((data) => data.header_id.header.toLowerCase().trim().replace(/\s/g, '_') === template.phraseKey);
      if (procedureData) {
        const value = procedureData.value;
        if (template.dataSet.header_type === 'Input') {
          let dropdownHtml = '';
          template.inputTemplates.forEach((inputTemplate, i) => {
            const inputData = procedureData.inputs.find(input => input.label === inputTemplate.label);
            if (inputData) {
              const $inputTmp = document.createElement('div');
              $inputTmp.innerHTML = inputTemplate.dropDownTemplate;
              const $dropdown = $inputTmp.querySelector('.text-field-dropdown');
              if (inputData.value !== '') {
                $dropdown.innerHTML = inputData.value;
                $dropdown.setAttribute('data-label', inputData.label);
                $dropdown.setAttribute('data-location-value', inputData.value);
              }
              dropdownHtml += `<span data-${extras.phrase_key}-field="${inputTemplate.phraseKey}">${$inputTmp.innerHTML}</span>${inputTemplate.unit}`;
            } else {
              dropdownHtml += `<span data-${extras.phrase_key}-field="${inputTemplate.phraseKey}">${inputTemplate.dropDownTemplate}</span>${inputTemplate.unit}`;
            }
            if (i < template.inputTemplates.length - 1) {
              dropdownHtml += ` x `;
            }
          });
          const $res = document.createElement('div');
          $res.innerHTML = template.resultTemplate;
          const $result = $res.querySelector('.text-field-result');
          $result.innerHTML = value;
          $result.setAttribute('data-location-value', value);
          const resultHtml = `<span data-${extras.phrase_key}-field="${template.resultPhraseKey}">${$res.innerHTML}</span>`;
          populatedPhraseTemplate = populatedPhraseTemplate.replaceAll(`.${template.resultPhraseKey}`, resultHtml);
          populatedPhraseTemplate = populatedPhraseTemplate.replaceAll(`.${template.phraseKey}`, dropdownHtml);
        } else {
          const $tmp = document.createElement('div');
          $tmp.innerHTML = template.dropDownTemplate;
          const $dropdown = $tmp.querySelector('.select-dropdown');
          if (value !== '') {
            $dropdown.innerHTML = value;
            $dropdown.setAttribute('data-options-selected', value.split(', ').join('|'));
          }
          const dropdownHtml = `<span data-${extras.phrase_key}-field="${template.phraseKey}">${$tmp.innerHTML}</span>`;
          populatedPhraseTemplate = populatedPhraseTemplate.replaceAll(`.${template.phraseKey}`, dropdownHtml);
        }
      } else {
        if (template.dataSet.header_type === 'Input') {
          let populatedInputTemplate = '';
          template.inputTemplates.forEach((inputTemplate, i) => {
            populatedInputTemplate += `<span data-${extras.phrase_key}-field="${inputTemplate.phraseKey}">${inputTemplate.dropDownTemplate}</span>${inputTemplate.unit}`;
            if (i < template.inputTemplates.length - 1) {
              populatedInputTemplate += ` x `;
            }
          });
          populatedPhraseTemplate = populatedPhraseTemplate.replaceAll(`.${template.resultPhraseKey}`, template.resultTemplate);
          populatedPhraseTemplate = populatedPhraseTemplate.replaceAll(`.${template.phraseKey}`, populatedInputTemplate);
        } else {
          populatedPhraseTemplate = populatedPhraseTemplate.replaceAll(`.${template.phraseKey}`, `<span data-${extras.phrase_key}-field="${template.phraseKey}">${template.dropDownTemplate}</span>`);
        }
      }
    }
  });
  return populatedPhraseTemplate;
}

function resolveSubPhraseValues(internalPhraseValuesMap, phraseTemplate, extras?:{sub_phrase_key:string, parentWound:any, woundDropdownTemplates:any, addendum_id: any, appendDataSubPhraseKey: boolean}, firstPhrase = false) {

  const itemsToRemove = [];
  extras.appendDataSubPhraseKey = (typeof extras.appendDataSubPhraseKey) === 'boolean' ? extras.appendDataSubPhraseKey : true;
  for (const key in internalPhraseValuesMap) {
    if (Object.prototype.hasOwnProperty.call(internalPhraseValuesMap, key)) {
      const value = internalPhraseValuesMap[key].toString().trim();
      if(value.length > 0) {
        internalPhraseValuesMap[key] = value;
      } else {
        const keyMatchWithItemsToRemove = itemsToRemove.find(item => item.key === key);
        if(keyMatchWithItemsToRemove) {
          internalPhraseValuesMap[keyMatchWithItemsToRemove.key] = '';
          const $tmp = document.createElement('div');
          $tmp.innerHTML = phraseTemplate;
          const $tags:any[] = Array.from($tmp.childNodes).filter($e => $e.nodeType === Node.ELEMENT_NODE);
          for (const $tag of $tags) {
            if($tag.tagName === 'P' && $tag.innerHTML.includes(keyMatchWithItemsToRemove.label)) {
              $tag.remove();
            }
          }
          phraseTemplate = $tmp.innerHTML;
        } else {
          internalPhraseValuesMap[key] = asteriskJumpSpanHTML;
        }
      }
    }
  }
  let populatedPhraseTemplate = phraseTemplate;
  
  // join all consective <p> tags
  const $tmp = document.createElement('div');
  $tmp.innerHTML = phraseTemplate;
  const paragraphs = [];
  const $tags:any[] = Array.from($tmp.childNodes).filter($e => $e.nodeType === Node.ELEMENT_NODE);
  for (const $tag of $tags) {
    if($tag.tagName === 'P') {
      paragraphs.push($tag.innerHTML);
    } else {
      paragraphs.push($tag.outerHTML);
    }
  }
  if(extras.appendDataSubPhraseKey){
    populatedPhraseTemplate = extras?.addendum_id ? `<span data-sub-phrase-key="${extras?.sub_phrase_key}" data-addendum="${extras?.addendum_id}" data-wound="${extras?.parentWound._id}">${paragraphs.join('<br>')}</span>` : `<span data-sub-phrase-key="${extras?.sub_phrase_key}" data-wound="${extras?.parentWound._id}">${paragraphs.join('<br>')}</span>`;
  }

  for (let key in internalPhraseValuesMap) {
    if (Object.prototype.hasOwnProperty.call(internalPhraseValuesMap, key)) {
      const value = internalPhraseValuesMap[key];
      populatedPhraseTemplate = populatedPhraseTemplate.replace(new RegExp(`\\.${key}\\b`, 'g'), `<span data-label="${key}" data-wound-field="${key}" class="sub-phrase sub-phrase-${key}">${value}</span>`);
    }
  }
  // resolve wound sub phrases
  if(extras) {
    const columnsAndFieldsMapping = {
      wound_edges : 'exposed_structure',
      exudate_amount : 'exudate',
      wound_infection : 'infection_signs'
    }
    const {parentWound, woundDropdownTemplates} = extras;
    let allAtrObj = {}
    if(parentWound.extended_wound_model && parentWound.extended_wound_model.length > 0){
      allAtrObj = addExtendedWoundAttrinParent(parentWound.extended_wound_model, parentWound.populated_extended_model);
    }
    const parentWoundWithExtendedModel = {...parentWound, ...allAtrObj};
      for (const key in woundDropdownTemplates) {
        if(Object.prototype.hasOwnProperty.call(woundDropdownTemplates, key)) {
          let dropdownHtml = woundDropdownTemplates[key];
          const isWoundObjField = Object.prototype.hasOwnProperty.call(parentWoundWithExtendedModel, key);
          const isWoundObjMapping = Object.prototype.hasOwnProperty.call(columnsAndFieldsMapping, key);
          const isUnmappedDropdown = !isWoundObjField && !isWoundObjMapping && !!dropdownHtml;
          let woundFieldKey = isWoundObjField ? key : columnsAndFieldsMapping[key];
          if(isUnmappedDropdown) {
            woundFieldKey = key;
          }
          if((isWoundObjField || isWoundObjMapping || isUnmappedDropdown) && woundFieldKey.includes("body_part") ){
            let value = parentWoundWithExtendedModel[woundFieldKey];
            const $tmp = document.createElement('div');
            $tmp.innerHTML = dropdownHtml;
            const $dropdown = $tmp.querySelector('.text-field-dropdown');
            if(value !== ''){
              $dropdown.innerHTML = value;
              $dropdown.setAttribute('data-location-value', value);
            }
            dropdownHtml = `<span data-wound-field="${woundFieldKey}">${$tmp.innerHTML}</span>`;
            populatedPhraseTemplate = populatedPhraseTemplate.replace(new RegExp(`\\.${woundFieldKey === "body_part" ? "woundlocation" : woundFieldKey}\\b`, 'g'), dropdownHtml);
          }else{
            if ((isWoundObjField || isWoundObjMapping || isUnmappedDropdown)) {
              let extendenObj = null;
              if(parentWound.extended_wound_model && parentWound.extended_wound_model.length > 0){
                extendenObj = parentWound.populated_extended_model.find((pEM) => pEM.header.toUpperCase() === woundFieldKey.toUpperCase().replaceAll("_"," "));
              }
              let value = parentWoundWithExtendedModel[woundFieldKey];
              const $tmp = document.createElement('div');
              $tmp.innerHTML = dropdownHtml;
              const $dropdown = $tmp.querySelector('.select-dropdown');
              if (value && value.trim() !== '') {
                if (woundFieldKey === 'epithelial' || woundFieldKey === 'slough' || woundFieldKey === 'granulation' || woundFieldKey === 'eschar') {
                  if (!value.includes(key.toString())) {
                    value = value.trim() + ` ${key}`;
                  }
                }
                multiSelect_attributes.includes(woundFieldKey) ? $dropdown.setAttribute('data-options-selected', value.split(', ').join('|')) : $dropdown.setAttribute('data-options-selected', value)
                $dropdown.innerHTML = value;
              }
              if(extras?.sub_phrase_key === "woundplan" && firstPhrase){ ////This check is only for WoundPlan and First Sub phrase
                dropdownHtml = extras?.addendum_id ? `<span data-sub-phrase-key="${extras?.sub_phrase_key}" data-addendum="${extras?.addendum_id}" data-wound="${extras?.parentWound._id}">${`<span data-wound-field="${woundFieldKey}">${$tmp.innerHTML}</span>`}</span>`: 
                `<span data-sub-phrase-key="${extras?.sub_phrase_key}"  data-wound="${extras?.parentWound._id}">${`<span data-wound-field="${woundFieldKey}">${$tmp.innerHTML}</span>`}</span>`;
              }else{
                dropdownHtml = extendenObj ? `<span data-extended-header="${extendenObj._id}" data-wound-field="${woundFieldKey}">${$tmp.innerHTML}</span>` : `<span data-wound-field="${woundFieldKey}">${$tmp.innerHTML}</span>`;
              }
              populatedPhraseTemplate = populatedPhraseTemplate.replace(new RegExp(`\\.${woundFieldKey}\\b`, 'g'), dropdownHtml);
            }else{
              console.log("UnResolved Key", key);
            }
          }
        }
      }
    if(extras.sub_phrase_key.includes("woundassessment")){
      populatedPhraseTemplate = removeUnSelectedFields(extras.sub_phrase_key,populatedPhraseTemplate);
    }
  }
  
  return populatedPhraseTemplate;
}

export function addExtendedWoundAttrinParent(model = [], populate_extended_model = []){
  const extended_model_attr = {};
  model.forEach((extended_m) => {
    const populatedObj = populate_extended_model.find((p_obj) => p_obj._id.toString() === extended_m.header_id.toString());
    if(populatedObj){
      extended_model_attr[`${populatedObj.header.toLowerCase().replaceAll(" ","_")}`] = extended_m.value;
    }
  });
  return extended_model_attr;
}

export function removeUnSelectedFields(sub_phrase_key, phraseTemplate, addOptionalKey = true){
  const requiredFields = ['woundno', 'body_part', 'severity', 'length', 'etiology']
  const $tmpDiv = document.createElement('div');
  $tmpDiv.innerHTML = phraseTemplate;
  const $tmpSelectorDiv = $tmpDiv.querySelector(`[data-sub-phrase-key="${sub_phrase_key}"]`);
  if ($tmpSelectorDiv || !addOptionalKey) {
    if (addOptionalKey) {
      $tmpSelectorDiv.innerHTML = $tmpSelectorDiv.innerHTML.split("<br>").map((ele) => {
        if(!requiredFields.some(field => ele.includes(field))){
          return `<span data-sub-key="optional">${ele}</span>`
        }else return ele
      }).join("<br>");
    }
    const $allOptionalKeys = addOptionalKey ? Array.from($tmpSelectorDiv.querySelectorAll(`[data-sub-key="optional"]`)) : Array.from($tmpDiv.querySelectorAll(`[data-sub-key="optional"]`))
    $allOptionalKeys.forEach((optionalSpan) => {
      const $dropdowns = Array.from(optionalSpan.querySelectorAll(".select-dropdown"));
      const totalLength = $dropdowns.length;
      if (totalLength > 0) {
        for (const $dropdown of $dropdowns) {
          const value = $dropdown.getAttribute("data-options-selected");
          if (!value) {
            if($dropdown.parentElement.parentElement.getAttribute('data-wound-field')){
              const $parent = $dropdown.parentElement.parentElement;
              $parent.remove();
            }else{
              const $parent = $dropdown.parentElement;
              $parent.remove();
            }
          }
        }
        optionalSpan.innerHTML = optionalSpan.innerHTML.split(",").filter((ele) => ele.trim() !== "").join(",");
        if (Array.from(optionalSpan.querySelectorAll(".select-dropdown")).length === 0) {
          optionalSpan.remove();
        }
      }
    });
    const $tunnelingField = addOptionalKey ? $tmpSelectorDiv.querySelector(`[data-wound-field="tunneling"]`) : $tmpDiv.querySelector(`[data-wound-field="tunneling"]`);
    if($tunnelingField && Array.from($tunnelingField.querySelectorAll(".asteriskjump")).length > 0){
      $tunnelingField.parentElement.remove();
    }
    const $under_miningField = addOptionalKey ? $tmpSelectorDiv.querySelector(`[data-wound-field="under_mining"]`) : $tmpDiv.querySelector(`[data-wound-field="under_mining"]`);
    if($under_miningField && Array.from($under_miningField.querySelectorAll(".asteriskjump")).length > 0){
      $under_miningField.parentElement.remove();
    }
    if (addOptionalKey) {
      $tmpSelectorDiv.innerHTML = $tmpSelectorDiv.innerHTML.split('<br>').filter((phr) => phr !== "").join('<br>')
    }

  }
  return $tmpDiv.innerHTML;
}

function woundKentImagingPhraseTemplate(parentWound, latestWound, restChildWounds, phraseTemplate?, extras: any = {}) {
  
  if(phraseTemplate) {
    const internalPhraseValuesMap = {
      [SubPhrase.woundno]: parentWound.woundNo ?? '',
      // [SubPhrase.woundlocation]: woundLocation(parentWound),
      [SubPhrase.etiology]: parentWound.etiolgy ?? '',
      [SubPhrase.severity]: latestWound.stage ?? '',
      [SubPhrase.length]: latestWound.length ?? '',
      [SubPhrase.width]: latestWound.width ?? '',
      [SubPhrase.depth]: latestWound.depth ?? '',
      [SubPhrase.area]: calculateArea(latestWound) ?? '',
      [SubPhrase.volume]: calculateVolume(latestWound) ?? '',
      // [SubPhrase.under_mining]: under_mining,
      // [SubPhrase.exudate]: exudate,
      // [SubPhrase.exudate_type]: exudate_type,
      // [SubPhrase.tunneling]: tunneling,
      [SubPhrase.exposed_tissues]: parentWound.exposed_tissues,

      
      [SubPhrase.sto2]: latestWound.sto2 ?? asteriskJumpSpanHTML,
      // [SubPhrase.total_weeks]: totalWeeks,
      // [SubPhrase.change_in_area]: changeInArea,
      // [SubPhrase.change_in_volume]: changeInVolume,
    }
    return resolveSubPhraseValues(internalPhraseValuesMap, phraseTemplate, {...extras, sub_phrase_key:'kentimaging', parentWound});
  }
  return `Non-Invasive Near-Infrared Wound Imaging
  Location: ${woundLocation(parentWound)} 
  Indication(s): Assessment of micro-circulation, oxygenation, perfusion and wound tissue viability to determine trends of healing and/or need for change to the plan of care. 
  Findings: There is <span class="select-dropdown" data-options="increased|decreased" data-label="Increased/Decreased" data-multiselect="false" contenteditable="false">Increased/Decreased</span> signal at the periwound, and <span class="select-dropdown" data-options="increased|decreased" data-label="Increased/Decreased" data-multiselect="false" contenteditable="false">Increased/Decreased</span> signal at the wound bed suggesting the wound is in the <span class="select-dropdown" data-options="inflammatory|proliferative|maturation" data-label="healing phase" data-multiselect="false" contenteditable="false">healing phase</span> of healing.  The average wound bed StO2 is ${latestWound.sto2 || '<span class="asteriskjump">***</span>'}%.  These findings suggest the microcirculation, oxygenation, and perfusion of this wound are <span class="select-dropdown" data-options="adequate|diminished|inadequate" data-label="adequate/inadeuate" data-multiselect="false" contenteditable="false">adequate/inadeuate</span> for healing. 
  Recommendations: Please see updated assessment and plan section below.
  `.split('\n').filter(s => s.trim()).join('<br />')
}
function woundLocation(wound) {

  const bodySideLabel = {
    'R': 'Right',
    'L': 'Left',
    'BL': 'Bilateral'
  }
  const bodySide = bodySideLabel[wound.body_side] || ''
  return `${bodySide || ''} ${wound.direction || ''} ${wound.body_part || ''}`.trim()
}

async function applyWoundPhrases(template, woundData, woundPhrases) {
const parentWound = woundData;
const [latestChildWoundWound, ...restChildWounds] = woundData.child_wounds;
const latestWound = latestChildWoundWound || woundData;

 for (const woundPhrase of woundPhrases) {
   const _phrase = `.${woundPhrase.key}`
      switch (_phrase) {
        case '.woundlocation': {
          const location = woundLocation(woundData)
          template = template.replace(_phrase, location)
        }
        break;
        case '.woundno': {
          const woundNo = `${parentWound.woundNo}`
          template = template.replace(_phrase, woundNo)
        }
        case '.woundmeasurement': {
          const measurement = `${latestWound.length} x ${latestWound.width} x ${latestWound.depth} cm`
          template = template.replace(_phrase, measurement)
        }
        case '.sto2': {
          const sto2 = latestWound.sto2 || asteriskJumpSpanHTML;
          template = template.replace(_phrase, sto2)
        }
        case '.area': {
          const area = calculateArea(latestWound) ?? '';
          template = template.replace(_phrase, area)
        }
        break;
      }
    }
    return template;
}

function labsPhraseTemplate(data) {
  const cliTableConfig = {
      // head: ['Name', 'Value'],
      colWidths: [20, undefined],
      wordWrap: true,
      chars: { 'top': '' , 'top-mid': '' , 'top-left': '' , 'top-right': '',
      'bottom': '' , 'bottom-mid': '' , 'bottom-left': '' , 'bottom-right': '',
      'left': '' , 'left-mid': '' , 'mid': '' , 'mid-mid': '', 
      'right': '' , 'right-mid': '' , 'middle': ' ' },
      style: { 'padding-left': 0, 'padding-right': 0 }
    }     
    const outputArr = [];
    for (const dataItem of data) {
        const { testSet } = dataItem;
        const tableRows = makeRowsAndColumnsFromLabsTestSetData(testSet);

        const table = new Table(cliTableConfig);

        tableRows.forEach((row) => {
            table.push(row);
        });
        const testSetTableStr = table
            .toString()
            .split("\n")
            .map(
                (s) => s.trim()
                .replace(/\s/g, "&nbsp;")
            )
            .join("<br />\n");
        outputArr.push(testSetTableStr);
    }
    return outputArr.join('\n<br />\n');
}

function imagingPhraseTemplate(data) {
  let output = '';
  for (const dataItem of data) {
      //   const output = results.map((result) => `${result.codeDescription} on ${moment(result.issuedDateTime).format('M/D/YYYY')}<br />${result.comment}`).join('<br /><br />')
      const mIssuedDateTime = moment(dataItem.issuedDateTime).tz(moment.tz.guess())
      const resultDate = mIssuedDateTime.format('M/D/YYYY');
      const interpretedBy = `${dataItem.orderingPractitioner.firstName} ${dataItem.orderingPractitioner.lastName}`
      if (output) output += '<br /><br />';
      output += `Result Date ${resultDate}`;
      for (const testSet of dataItem.testSet) {
          const testSetItems = testSet.results.map((result) => `
    <br />Exam: ${result.codeDescription}
    <br />Finding: ${result.comment}`);
          output += testSetItems.join('<br /><br />');
      }
      output += `<br />Interpreted by: ${interpretedBy}`;
      return output;
  }
}

// usePhraseValueOrText 
function usePhraseValueOrText(section) {
  let result = section.text;
  if(Array.isArray(section.phrases) && section.phrases.length > 0) {
    result = section.phrases.reduce((acc, phrase) => {
      if(!acc) {
        return phrase.value;
      }
      return `${acc}\n${phrase.value}`
    }, '');
  }
  return result;
}

function makeRowsAndColumnsFromLabsTestSetData(data) {
  const tableRows = [];
  if(Array.isArray(data)) {
      for (const testSet of data) {
          const testSetItems = testSet.results.map((result) => [`${result.codeDescription}:`, `${result.valueQuantity?.value || ''}`])
              .reduce((acc, cur) => {
                  // uniquify the rows by the first column
                  const existingRow = acc.find((row) => row[0].trim() === cur[0].trim());
                  if (existingRow) {
                      // existingRow[1] += `, ${cur[1]}`;
                  } else {
                      acc.push(cur);
                  }
                  return acc;
              }, []);
          tableRows.push(...testSetItems);
      }
  }
  return tableRows;
}