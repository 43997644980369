<ng-container *ngIf="!user.pcc_account || getPrivilegeScopes().length === 0">
    <div>Permissions have not been recorded for this user yet. Please ensure that the user has logged in to PCC in order to record permissions.</div>
</ng-container> 
<ng-container *ngIf="user.pcc_account && getPrivilegeScopes().length > 0">

    <div  class="flex items-start" *ngIf="facilities.length > 0">
        <div>
            <ng-container *ngIf="facilities.length === 1">
                Facility:
            </ng-container>
            <ng-container *ngIf="facilities.length > 1">
                Facilities: 
            </ng-container>
        </div>
        <ng-container *ngFor="let facility of facilities">
            <app-facility-title [facility]="facility"></app-facility-title>
        </ng-container>
        
    </div>
    <table mat-table [dataSource]="getPrivilegeScopesObjects()">
        <ng-container matColumnDef="Scope">
            <th
                mat-header-cell
                *matHeaderCellDef
                style="width: 5%; text-align: center"
            >
                User Scope
            </th>
            <td
                mat-cell
                *matCellDef="let element; let i = index"
                style="width: 5%; text-align: center"
            >
                {{ formatScopeLabel(element.scopeLabel) }}
            </td>
        </ng-container>
        <ng-container *ngFor="let key of getKeys()">

            <ng-container [matColumnDef]="key">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    style="width: 5%; text-align: center"
                >
                    {{key | titlecase}}
                </th>
                <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    style="width: 5%; text-align: center"
                >
                    {{ element.operations.includes(key) ? '✅' : '❌' }}
                </td>
            </ng-container>
            
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</ng-container>
