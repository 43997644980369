<p style="padding: 0 10px;">
    Imaging > Test Set
</p>
<ng-container *ngFor="let dataSource of dataSources">
    <table mat-table [dataSource]="dataSource" matSort>
        
        <ng-container matColumnDef="resultStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Result Status
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.resultStatus}}
            </td>
        </ng-container>
        
    
        <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Comment
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.comment}}
            </td>
        </ng-container>
        
    
        <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Code
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.code}}
            </td>
        </ng-container>
        
    
        <ng-container matColumnDef="codeSystem">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Code System
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.codeSystem}}
            </td>
        </ng-container>
        
    
        <ng-container matColumnDef="codeDescription">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Code  Description
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.codeDescription}}
            </td>
        </ng-container>
        
    
        <ng-container matColumnDef="valueQuantity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Value Quantity
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.valueQuantity.value}} {{element.valueQuantity.unitText}}
            </td>
        </ng-container>
        
    
        <ng-container matColumnDef="procedureId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Procedure Id
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.procedureId}}
            </td>
        </ng-container>
        
    
        <ng-container matColumnDef="procedureName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Procedure Name
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.procedureName}}
            </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</ng-container>