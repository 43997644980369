import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PatientMatrixCareGuard implements CanActivate {
  constructor(
    private _authService: AuthService,
    private _router: Router,
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._authService.currentUser.other_settings.show_matrixCare) {
      console.log("ran")
      return true;
    }
    else {
      this._router.navigate(['patients/list']);
      return false;
    }
  }

  
}
