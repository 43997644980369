import {
    Component,
    OnInit,
    PipeTransform,
    Inject,
    Output,
    EventEmitter,
    DoCheck,
    AfterViewInit,
    OnDestroy,
    ViewChild,
    ElementRef,
    HostListener
} from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
// import { LoginService } from './login.service';
import { Subscription, Observable, lastValueFrom } from 'rxjs';
import { DashboardService } from './dashboard.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { catchError, map, tap, startWith } from 'rxjs/operators';
/*import { LoginComponent } from '../login/login.component';
import { identifierModuleUrl } from '@angular/compiler';
import { stringify } from '@angular/compiler/src/util';
import { getLocaleDateTimeFormat } from '@angular/common';
import { Transform } from 'stream';*/
import { isNullOrUndefined, isArray, isObject } from 'util';
import * as global from '../global';
import { ToastrService } from 'ngx-toastr';
import { FileConversionService } from 'src/app/services/file-conversion.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranscriptionCreateService } from '../transcription-create/transcription-create.service';
import { PatientListService } from '../patient-list/patient-list-service';
import { DatePipe, KeyValue } from '@angular/common';
import moment from 'moment'
import { SocketService } from 'src/app/services/socket.service';

import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/services/auth.service';
import { PCCService } from 'src/app/services/pcc.service';
import { API_URL } from "../../../environments/api-url";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { DaterangepickerDirective } from "ngx-daterangepicker-material";
import { FormControl } from "@angular/forms";
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { ExportNotesDialogComponent } from 'src/app/shared/popup-dialogs/export-notes-dialog/export-notes-dialog.component';
import { NoteEditorService } from '../note-editor/note-editor.service';
import { timeStamp } from 'console';
import { FeedbackComponent } from '../feedback/feedback/feedback.component';
import { FacilityContactDialogComponent } from 'src/app/shared/popup-dialogs/facility-contact-dialog/facility-contact-dialog.component';
import { HippaAgreementComponent } from 'src/app/hippa-agreement/hippa-agreement.component';
import { AddTicketsComponent } from '../tickets/add-tickets/add-tickets.component';
import { BillerDashboardService } from '../biller-dashboard/biller-dashboard.service';
import { WoundService } from 'src/app/services/wound.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { DialogConfirmationComponent } from '../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { SnfWcNurseAssociationService } from '../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { WoundNurseRelationService } from 'src/app/services/Modules/woundNurse.service';
import { ViewOnlyUsersService } from 'src/app/services/Modules/view-only-users.service';
import { NotificationsService } from '../notifications/notifications.service';
import { ICompanyPermissionResponse } from 'src/app/classes/company';

export interface Recording {
    // string, number;
    _id?: string;
    audio_title: string;
    date: string
    time: string;
    date_time: string;
    audio_length: string;
    status: string;
    is_trashed: string,
    uploaded_by_id?: any,
    uploaded_by_full_name?: any,
    progress_status?: any,
    empty?: boolean,
    uploading_date?: string
}

// export interface OnInit {
//   ngOnInit(): void;
// }

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
    providers: [
        DatePipe
    ]
})


export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
    iOReQA: Boolean;
    isIQAofProvider: Boolean;
    isEQAofProvider: Boolean;
    isMipsDropdownExpanded: boolean = false;

    showPrimaryFilter: Boolean = false;
    countFilter: Boolean = false;
    barWidth: any;
    global = global;
    // moved all datamembers to the top of contuctor #mohsin-dev
    public refreshDashboardIntervalId;
    public refreshDashboardEvent: EventEmitter<void>;

    public loading = false;
    public data: any;
    public datas: any;
    public dates: any;
    public datasource: any;
    public transcriptionResponse: any;
    // public selectedAudio: any;

    recordingSearchQuery: string;
    // recordingStatusQuery = '';
    category = 'dict';
    status = 'All_Dictations';
    leftColClose = false;
    leftColWidth = false;
    recordings: any = [];
    recordingGroupDates: Array<string> = [];
    filteredOptions: Observable<string[]>;
    public transcriptions: any;

    public sortedTransriptions: any;
    groupedSortedTranscriptions: any;
    transcriptionsGroupingKey: string = 'date_of_service'; //by default group by service date
    // transcriptionsGroupingKey: string = 'creation_date'; //by default group by uploading date
    transcriptionsFetchDate = new Date();

    public sortedRecordings: any;
    groupedSortedRecordings: any;
    // recordingsGroupingKey: string = 'uploading_date'; //by default group by uploading date
    recordingsGroupingKey: string = 'date_time'; //by default group by service date
    recordingsFetchDate = new Date();

    paginationDaysRange = 5;

    public responseUpdateDone: boolean = false;
    public loadMoreInprogress = false;
    // public dictTranscription:any;

    multiSelectedDictations: Array<any> = [];
    isMultiDownloadDictationsOn: boolean = true;
    downloadProgress = {
        show: false,
        progress: 0,
        // mode: 'query'
        mode: 'indeterminate'
    };
    multiDownloadDictationsInprogress: boolean = false;

    multiSelectedTranscriptions: Array<any> = [];

    filterDropdownActive = false;

    public globalDateFormat: string = global.date_format;
    filterData: any; // added filterData to show UI changes if filter is active #mohsin-dev
    filters = {
        dob: '',
        dos: '',
        facility: '',
        provider: '',
        patient: '',
        progress_status: ''
    };
    dictFilters = {
        dob: '',
        dos: '',
        facility: '',
        provider: '',
        patient: '',
        progress_status: ''
    };
    //active
    tranFilters: any = {
        dob: '',
        dos: '',
        facility: '',
        provider: '',
        patient: '',
        progress_status: 'Unsigned',
        note_status: '',
    };
    fData = {
        facilities: {
            dict: null,
            tran: null
        },
        providers: {
            dict: null,
            tran: null
        },
        patients: []
    }
    isFilterVisible: boolean = false;

    // @ViewChild(VirtualScrollerComponent)
    // private virtualScroller: VirtualScrollerComponent;

    //
    pagination = {
        current_page_no: global.cached.get('dashboard:dictation:current_page_no', 1),
        per_page: 100,
        last_page_no: null
    };

    transcriptionsLoaded = false;
    dictationsLoaded = false;

    countData = {
        completedNotesCount: 0,
        pendingNotesCount: 0,
        signedNotesCount: 0,
        unsignedNotesCount: 0,
        inProgressDictations: 0
    };
    dictCountData = {
        completedDictations: 0,
        inProgressDictations: 0,
    }

    transCountData = {
        unsignedNotesCount: 0,
        signedNotesCount: 0,
        uploadedNotesCount: 0,
        coSignedNotesCount: 0
    }

    preDateRanges: any = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };
    dateFilter = {
        startDate: moment().subtract(7, 'days'),
        endDate: moment()
    };
    noteDateFilter = {
        startDate: moment().subtract(7, 'days'),
        endDate: moment()
    };
    dictDateFilter = {
        startDate: moment().subtract(7, 'days'),
        endDate: moment()
    };
    // statusOption = ['Completed', 'Pending', 'Exported'];
    statusOption = [
        {
            status: "--ALL--",
            code: ''
        },
        {
            status: "Completed",
            code: '20'
        },
        {
            status: "In Progress",
            code: '19'
        }
    ]
    transGroup = {
        group: 'Group by Date of Service',
        value: 'date_of_service'
    }

    transGrouping = [
        {
            group: 'Group by Date of Service',
            value: 'date_of_service'
        },
        {
            group: 'Group by Date of Creation',
            value: 'creation_date'
        },
        {
            group: 'Group by Patient',
            value: 'patient_id_ref'
        }
    ]
    transGroupingAllFacilities = [
        {
            group: 'Group by Date of Service',
            value: 'date_of_service'
        },
        {
            group: 'Group by Date of Creation',
            value: 'creation_date'
        },
        {
            group: 'Group by Facility',
            value: 'facility_id_ref'
        },
        {
            group: 'Group by Patient',
            value: 'patient_id_ref'
        }
    ]
    dictGroup = {
        group: 'Group by Date of Service',
        value: 'date_time'
    }
    dictGrouping = [
        {
            group: 'Group by Date of Service',
            value: 'date_time'
        },
        {
            group: 'Group by Date of Submission',
            value: 'uploading_date'
        }
    ];
    news: Boolean = true;
    update: Boolean = true
    surpassedTAT: Boolean = true;
    disabled = false;
    statusOptionTrans = ['Unsigned', 'Signed', 'Uploaded', 'Trashed', 'Co-Signed']
    // @ViewChild('matManuFocus') matManuFocus: MatMenuTrigger;
    // @ViewChild('matManuTrigger') belowMenu: MatMenuTrigger;
    @ViewChild('matManuTrigger') belowMenu: ElementRef;
    facilityPatients: any = [];

    filteredFacilities: Observable<string[]>;
    filteredProviders: Observable<string[]>;

    facilityControl = new FormControl();
    providerControl = new FormControl();
    company_check = true;
    public pccUserinfo;
    // searchTextProvider : any;
    // @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    // @HostListener('window:resize', ['$event'])
    // onResize(event?) {
    //     this.barWidth = document.getElementById('bar').offsetWidth;
    // }
    filterKeydownEvent;
    chargesResponse: any;
    submitCount: any;
    draftCount: any;
    chargeFilters = {
        patient: '',
        dos: null,
        facility: '',
        provider: '',
        // chargeType: 'submit'
        chargeType: 'draft'
    }
    showChargesContainer: boolean = false;
    prevMipsValue: any = '';
    showMipsContainer: boolean = false;
    chargeStatus: any = '';
    missingFaceSheetsCount: any;
    faceSheetStatus: any;
    isChargeDropdownExpanded: boolean = false;
    isDocumentDropdownExpanded: boolean = true;
    isMAofProvider: boolean;
    isSkinWoundDropdownExpanded: Boolean = false
    showWoundContainer: boolean;
    showReportsContainer: Boolean = false;
    showAllFacilities: Boolean = false;
    qapiReportFilter: any;
    improvingCount = 0;
    stalledCount = 0;
    deterioratingCount = 0;
    remissionCount = 0;
    amputationCount = 0;
    providerFromCensus: any = '';
    dashboardView: boolean = true;
    reportView: any;
    noteErrors: {
        [key: string]: {
            message: string,
            reason: 'pcc_error' | 'asterisks'
        }
    }
    facilities: any;
    reportsNames: any[];
    billStatus: any = '';
    addendumReqCount: any;
    isChargeCaptureNotificationData: boolean = false;
    chargeCaptureNotificationData: any = {};
    notification_response: any;
    quarterlyMonths: any = [];
    currentCompany: any = {};
    allfacilities: any = [];
    shouldShowEncounter: boolean = false;

    public constructor(
        private router: Router,
        public dashboard: DashboardService,
        private http: HttpClient,
        private bottomSheet: MatBottomSheet,
        private _route: ActivatedRoute,
        private fileConversionService: FileConversionService,
        private toastr: ToastrService,
        private EncryptDecryptService: EncryptDecryptService,
        private dialog: MatDialog,
        private _transcriptionCreateService: TranscriptionCreateService,
        private patientListService: PatientListService,
        private datepipe: DatePipe,
        private _socketService: SocketService,
        private _commonService: CommonService,
        private _authService: AuthService,
        private pccService: PCCService,
        private noteEditorService: NoteEditorService,
        public billerDashboardService: BillerDashboardService,
        private woundService: WoundService,
        private woundNurseService: WoundNurseRelationService,
        private companyService: CompanyService,
        private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
        private viewOnlyUsersService: ViewOnlyUsersService,
        private _notificationsService: NotificationsService
        // private _pccService: PCCService,
    ) {
        this.refreshDashboardEvent = new EventEmitter<void>();
        this.datas = this._authService.currentUser;
        // if(this.matManuFocus){
        //     this.matManuFocus.openMenu();
        // }
        if (!this.datas.other_settings.show_tat) {
            this.surpassedTAT = false;
        }
        if (!this.datas) {
            localStorage.removeItem('currentUser');
            window.location.href = '/login';
            return;
        }
        if (this.datas.company_type.toLowerCase() == "wound") {
            this.company_check = false;
        }
        this.pccUserinfo = this.pccService.pccUserinfo;
        this.pccService.pccUserinfo$.subscribe((pccUserinfo: any) => {
            this.pccUserinfo = pccUserinfo;
        });
        //  const categoryParam = this._route.snapshot.paramMap.get('category');
        //  this.category = categoryParam || 'dict';
        //  const statusParam = this._route.snapshot.paramMap.get('status');
        //  if(statusParam) {
        //   this.status = statusParam;
        //  } else {
        //   this.status = this.category === 'dict' ? 'All_Dictations' : 'Un_Signed';
        //  }
        this.getCompany();
        if (this.datas.user_type == global.USER_TYPE.DOCTOR || this.datas.user_type === global.USER_TYPE.SNF_WC_NURSE || this.datas.user_type === global.USER_TYPE.WOUND_NURSE || this.datas.user_type === global.USER_TYPE.VIEW_ONLY_USER || this.datas.user_type === global.USER_TYPE.BUSINESS_OFFICER) {
            this.category = 'tran';
            this.status = 'Un_Signed';
            this.showAllFacilities = true;
            this.getSettings(this.datas.user_type);
        } else {
            this.iOReQA = JSON.parse(localStorage.getItem('iOReQA'))
            if (this.iOReQA) {
                this.category = 'tran';
                this.status = 'Un_Signed';
            }
            else {
                this.category = 'dict';
                this.status = 'All_Dictations';
            }
        }

        this._route.paramMap.subscribe((params: ParamMap) => {
            this.recordingsFetchDate = new Date();
            this.multiSelectedTranscriptions = [];
        });
    }

    openFeedback() {
        this.dialog.open(FeedbackComponent, {
            height: '78%',
            width: '55%'
        });
    }
    // openAgreement() {
    //     this.dialog.open(HippaAgreementComponent, {
    //         height: '78%',
    //         width: '65%',
    //     });
    // }
    getSettings(userType : string) {
        userType === '1' ? userType = 'provider' : userType = userType;
        this.companyService.getCompanyReportSettings(this.datas.company_id).subscribe((response: any) => {
            if (Array.isArray(response.data) && response.data.length > 0) {
                this.reportsNames = [];
                for (const reportSetting of response.data) {
                    if (reportSetting.selected && reportSetting.user_type.indexOf(userType) > -1) {
                        if (this.datas.company_type === this.global.COMPANY_TYPE.WOUND && reportSetting.title == 'DNS Healing Report') {
                            reportSetting.title = 'Post Rounds Report'
                        }
                        if (this.datas.company_type === this.global.COMPANY_TYPE.WOUND && reportSetting.title == 'Post-Rounds Wound Report') {
                            reportSetting.title = 'Wound Assessments'
                        }
                        if (reportSetting.url === "pressure-wound-report" &&
                            this.datas.company_type !== this.global.COMPANY_TYPE.WOUND &&
                            this.datas.company_type !== this.global.COMPANY_TYPE.SNF) continue;
                        this.reportsNames.push(reportSetting);
                    }
                }
                // if (global.ENVIRONMENT != global.WOUNDCARE_PRELIVE
                //     && global.ENVIRONMENT != global.WOUNDCARE_LIVE) {
                    // this.reportsNames.unshift({
                    //     title: "Post Rounds Report V2",
                    //     url: "post-rounds-report"
                    // });
                // }
                // if (this._authService.currentUser.user_type === global.USER_TYPE.DOCTOR
                //     || this._authService.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE
                //     || this._authService.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
                //     this.reportsNames.unshift({
                //         title: "Wound Assessment Dashboard",
                //         url: "telehealth-wound-assessment-report"
                //     });
                // }
            }
        });
    }
    getPatientByFacility(facility_id) {
        const provider = this.dictFilters.provider as any;
        const company_id = provider?.company_id;
        if (facility_id && company_id) {
            this.patientListService.getPatientsV2(company_id, facility_id)
                .subscribe((response: any) => {
                    if (response.status === 200) {
                        // this.fData.patients = response.data.array;

                        response.data.array.unshift({ name: "--All--" });
                        this.tranFilters.patient = response.data.array[0];
                        this.facilityPatients = response.data.array;
                        this.filteredOptions = this.patientControl.valueChanges.pipe(
                            startWith(''),
                            map((value: any) => this._filter(value))
                        );
                        // this.applyNoteFiltersByPatient();
                        // console.log(this.facilityPatients)
                    }
                }, (error) => {
                },
                    () => {
                    }
                );
        }
        else {
            this.tranFilters.facility = "";
            this.fData.facilities.tran = [];
            this.applyNoteFilters();
        }
    }

    showPatientsDropDown() {
        this.filteredOptions = this.patientControl.valueChanges.pipe(
            startWith(''),
            map((value: any) => this._filter(value))
        );
    }

    ngAfterViewInit() {
        // throw new Error("Method not implemented.");
        // this.category == 'tran' &&
        // let pFilter = JSON.parse(localStorage.getItem('showPrimaryFilter'))
        // if (!this.countFilter && !pFilter && !(this.datas.user_type == '1' || this.datas.user_type == 'medical_assistant' || this.datas.user_type == '2' || this.datas.user_type == global.USER_TYPE.DOCTOR)) {
        //     setTimeout(() => {
        //         this.belowMenu.nativeElement.click();
        //     });
        //     localStorage.setItem('showPrimaryFilter', JSON.stringify(true));
        // }
    }

    notesCounts() {
        if (!this.datas) {
            return;
        }
        this.dashboard.notesCount().subscribe(data => {
            // @ts-ignore
            this.countData = data.data;
            global.cached.set('dashboard:countData', this.countData);

        }, error => {
            //
        })
    }
    dictationCount() {
        if (!this.datas) {
            return;
        }
        this.dashboard.dictationCount().subscribe(data => {
            // @ts-ignore
            this.dictCountData = data.data;
            global.cached.set('dashboard:dictCountData', this.dictCountData);
        })
    }
    filterSurpassedTAT(value) {
        this.surpassedTAT = value;
        this.applyCounterFilter();
    }

    private _filter(value: string): string[] {
        if (value === null) {
            this.facilityPatients = [];
            return;
        }
        if (typeof value === "string") {
            const filterValue = value.toLowerCase();
            return this.facilityPatients.filter(option => option.name.toLowerCase().indexOf(filterValue) > -1);
            // return this.facilityPatients.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
        }
    }

    async ngOnInit() {
        await this.getCompanyPermissions({ _id: this.datas.company_id }, { should_show_encounter: 1 });
        this.reportView = '';
        this.createMyMipsQuaters();
        if (this._route.snapshot.queryParams.prop && this._route.snapshot.queryParams.prop != 'missingFacesheet') {
            this.onChargesClick(this._route.snapshot.queryParams.prop, 'all');
            if (this._route.snapshot.queryParams.providerId) {
                this.providerFromCensus = this._route.snapshot.queryParams.providerId;
            }
        }
        if (this._route.snapshot.queryParams.prop === 'missingFacesheet') {
            if (this._route.snapshot.queryParams.providerId) {
                this.providerFromCensus = this._route.snapshot.queryParams.providerId;
            }
            this.onChargesClick('submit', 'upload');
        }
        if (this._route.snapshot.queryParams.prop === 'addendumReq') {
            if (this._route.snapshot.queryParams.providerId) {
                this.providerFromCensus = this._route.snapshot.queryParams.providerId;
            }
            this.onChargesClick('billed', 'all', 'addendum_req');
        }
        // if ((this.datas.user_type === '1' || this.datas.user_type === global.USER_TYPE.DOCTOR))
        // {
        //     this.dashboard.getAgreement().subscribe((res:any)=>{
        //         if(res.status ==200){
        //             if ((res.data.hipaa_agreement_accepted==false))
        //             {
        //              this.openAgreement();
        //             }
        //     }
        //     })
        // }
        // console.log("mmmm",this.datas)
        this._route.paramMap.subscribe(async (params: ParamMap) => {
            const categoryParam = params.get('category');

            if (categoryParam) {
                this.category = categoryParam;
            }
            const statusParam = params.get('status');
            if (statusParam) {
                this.status = statusParam;
            }

            this.notification_response = this._notificationsService.castChargeNotificationData.subscribe((data) => {   // if provider is already on dashboard screen, "chargeCaptureNotificationData" helps to navigates to addendum screen of dashboard when click on charge capture notifictaion which is sent by biller
                this.chargeCaptureNotificationData = data;
                if (this.chargeCaptureNotificationData.data) {
                    this.isChargeCaptureNotificationData = true;
                    this.onChargesClick('draft', 'all', 'addendum_req');
                }
            });

            /**
             * date fetching from localStorage if exists
             */
            this.iOReQA = JSON.parse(localStorage.getItem('iOReQA')); //internal or external QA

            let tDate = JSON.parse(localStorage.getItem('noteDateFilter'));
            if (tDate) {
                this.noteDateFilter.startDate = moment(tDate.startDate);
                this.noteDateFilter.endDate = moment(tDate.endDate);
            }
            let dDate = JSON.parse(localStorage.getItem('dateFilter'));

            if (dDate) {
                this.dateFilter.startDate = moment(dDate.startDate);
                this.dateFilter.endDate = moment(dDate.endDate);
            }
            let tFilters = JSON.parse(localStorage.getItem('tranFilters'));
            if (tFilters) {
                this.tranFilters = tFilters;
            }
            let dFitlers = JSON.parse(localStorage.getItem('dictFilters'));
            if (dFitlers) {
                this.dictFilters = dFitlers;
            }
            console.log("this.datas.user_type : ", this.datas.user_type);
            if (this.datas.user_type === '2' || this.datas.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.datas.user_type === global.USER_TYPE.BUSINESS_OFFICER || (this.datas.user_type === global.USER_TYPE.VIEW_ONLY_USER && this.datas.company_type != global.COMPANY_TYPE.SNF)) {
                await this.initProviders();
                if (this.tranFilters.facility && this.tranFilters.facility._id) {
                    this.getPatientByFacility(this.tranFilters.facility._id);
                }
            }
            if (this.datas.user_type === global.USER_TYPE.DOCTOR || this.datas.user_type === global.USER_TYPE.SNF_WC_NURSE || (this.datas.user_type === global.USER_TYPE.VIEW_ONLY_USER && this.datas.company_type === global.COMPANY_TYPE.SNF)) {
                await this.initProvidersForProvider()

                if (this.tranFilters.facility?._id)

                    this.getPatientByFacility(this.tranFilters.facility._id);

                this.initFacilities();
                if (this._authService.currentUser.user_type !== global.USER_TYPE.SNF_WC_NURSE && this._authService.currentUser.user_type !== global.USER_TYPE.WOUND_NURSE && this._authService.currentUser.user_type !== global.USER_TYPE.VIEW_ONLY_USER) {
                    await this.getCount();
                    await this.getMissingFacesheets();
                    await this.getAddendumReqCount();
                }
                // this.getQapiReportCount(this.tranFilters.facility._id);
            }
            if (this.datas.user_type === global.USER_TYPE.WOUND_NURSE) {
                this.initProvidersForProvider();

            }
            if (categoryParam) {
                this.category = categoryParam;
            }
            if (statusParam) {
                this.status = statusParam;

            }

            this.refreshDashboardEvent.emit();
        })
        this.refreshDashboardEvent.subscribe(async () => {
            try {
                let uploading_date;
                let end_uploading_date;

                console.log("params, this.iOReQA : ", this.category, this.iOReQA);
                // await this.refreshRelation();

                if (this.category == 'dict') {
                    this.recordings = [];
                    let dDate = JSON.parse(localStorage.getItem('dateFilter'));
                    if (dDate) {
                        this.dateFilter.startDate = moment(dDate.startDate);
                        this.dateFilter.endDate = moment(dDate.endDate);
                    }
                    // let dictFitler = JSON.parse(localStorage.getItem('dictFilters'));
                    uploading_date = this.dateFilter.startDate.toLocaleString();
                    end_uploading_date = this.dateFilter.endDate.toLocaleString();
                    // const uploading_date = moment(this.recordingsFetchDate).startOf('day');
                    // const end_uploading_date = moment(uploading_date).startOf('day').subtract(this.paginationDaysRange, 'days');

                    this.dictationsLoaded = false;
                    this.getAudiosinfoByDate(
                        end_uploading_date,
                        uploading_date
                    ).then((recordings) => {
                        this.dictationsLoaded = true;
                        this.pagination.per_page = Infinity;
                        if (recordings) {
                            // update same
                            const oldRecordings = [];
                            const newRecordings = [];

                            for (let recording of recordings) {
                                if (this.recordings.find(r => r._id === recording._id)) {
                                    oldRecordings.push(recording);
                                } else {
                                    newRecordings.push(recording);
                                }
                            }
                            // update old recordings
                            for (let recording of oldRecordings) {
                                const i = this.recordings.findIndex(r => r._id === recording._id);
                                this.recordings[i] = recording;
                            }
                            this.recordings = this.sortAudioArrayDateDescending(newRecordings);
                            this.initDictationsAndTranscriptions();
                        }

                    });
                }

                //  const [transcriptions, dictTranscription] = await Promise.all([this.dashboard.getDTranscribtions().toPromise(), this.dashboard.getTranscribtions().toPromise()]);
                // combined audio & doctor transcriptions
                // setTimeout(() => this.transcriptions = global.cached.get('dashboard:transcriptions', {}), 0);



                let userCheck = this._authService.currentUser;
                // // userCheck = JSON.parse(userCheck);
                // // @ts-ignore
                // if (userCheck.user_type === '1') {
                //     const creation_date = moment(this.transcriptionsFetchDate).startOf('day');
                //     const end_creation_date = moment(creation_date).subtract(this.paginationDaysRange, 'days');
                //     this.dashboard.getAllAssociatedTranscriptions(creation_date.toString(), end_creation_date.toString()).subscribe(transcriptions => {
                //         this.transcriptions = transcriptions;
                //         global.cached.set('dashboard:transcriptions', transcriptions);
                //         this.initDictationsAndTranscriptions();
                //     }, error => {
                //         console.error(error);
                //         this.loading = false;
                //     }, () => this.transcriptionsLoaded = true);
                // } else {
                //     // @ts-ignore
                //     if (userCheck.user_type === '2') {
                //         this.dashboard.getTeamNotes().subscribe(
                //             data => {

                //                 this.transcriptions = data;
                //                 global.cached.set('dashboard:transcription', data);
                //                 this.initDictationsAndTranscriptions();
                //             },
                //             error => {

                //                 this.loading = false;
                //             }
                //         );
                //     }
                // }
                // this.tranFilters.dos = tFilters.dos;
                // let start = moment(this.noteDateFilter.startDate);
                // let end = moment(this.noteDateFilter.endDate);
                let start = this.noteDateFilter.startDate;
                let end = this.noteDateFilter.endDate;
                // if (this.category == 'tran' && !this.countFilter) {
                if (this.category == 'tran') {
                    this.transcriptions = [];
                    this.transcriptionsLoaded = false;
                    console.log("2nd ", this.isIQAofProvider, this.isEQAofProvider);
                    // if(!this.tranFilters){
                    if (this.isIQAofProvider) {
                        console.log("this is IQA");
                        this.dashboard.getTeamNotesForTranscribers(
                            this.tranFilters,
                            'IQA',
                            // end.format(global.moment_date_time_format),
                            // start.format(global.moment_date_time_format),
                            end,
                            start
                        )
                            .subscribe(
                                (response: any) => {
                                    this.transcriptions = response;
                                    this.transCountData = response.countData;
                                    global.cached.set('dashboard:transcription', response);
                                    this.initDictationsAndTranscriptions();
                                },
                                error => {

                                },
                                () => {
                                    this.loading = false;
                                    this.transcriptionsLoaded = true;
                                }
                            );
                    } else if (this.isEQAofProvider) {
                        console.log("this is EQA");

                        this.dashboard.getTeamNotesForTranscribers(
                            this.tranFilters,
                            'EQA',
                            // end.format(global.moment_date_time_format),
                            // start.format(global.moment_date_time_format),
                            end,
                            start
                        )
                            .subscribe(
                                (response: any) => {
                                    this.transcriptions = response;
                                    this.transCountData = response.countData;
                                    global.cached.set('dashboard:transcription', response);
                                    this.initDictationsAndTranscriptions();
                                },
                                error => {

                                },
                                () => {
                                    this.loading = false;
                                    this.transcriptionsLoaded = true;
                                }
                            );
                    } else {
                        this.dashboard.getTeamNotes(
                            this.tranFilters,
                            // end.format(global.moment_date_time_format),
                            // start.format(global.moment_date_time_format),
                            end,
                            start
                        )
                            .subscribe(
                                (response: any) => {
                                    this.transcriptions = response;
                                    this.transCountData = response.countData;
                                    global.cached.set('dashboard:transcription', response);
                                    this.initDictationsAndTranscriptions();
                                },
                                error => {

                                },
                                () => {
                                    this.loading = false;
                                    this.transcriptionsLoaded = true;
                                }
                            );

                    }


                    // }
                }
                // else if(this.category == 'dict'){
                //     this.dashboard.getau(
                //         end.format(global.moment_date_time_format),
                //         start.format(global.moment_date_time_format),
                //         this.tranFilters)
                //         .subscribe(
                //         (response: any) => {

                //             this.transcriptions = response;
                //             global.cached.set('dashboard:transcription', response);
                //             this.initDictationsAndTranscriptions();
                //         },
                //         error => {

                //         },
                //         () => {
                //             this.loading = false;
                //             this.transcriptionsLoaded = true;
                //         }
                //     );
                // }

                //  this.dictTranscription = dictTranscription;

            } catch (error) {

                this.loading = false;
                let { message = 'Something went wrong, please try again' } = error;
                setTimeout(() => this.toastr.error(message, 'Error'));
                // },100)
            } finally {
                this.loading = false;
            }
        });
    }

    createMyMipsQuaters() {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const currentQuarter = Math.floor(currentMonth / 3) + 1;
        const startMonth = (currentQuarter - 1) * 3;
        for (let quarter = 1; quarter <= currentQuarter; quarter++) {
            const startMonthOfQuarter = (quarter - 1) * 3;
            const endMonthOfQuarter = startMonthOfQuarter + 2;
            if (startMonthOfQuarter > currentMonth) {
                break;
            }
            const startDate = new Date(currentYear, startMonthOfQuarter, 1);
            const endDate = new Date(currentYear, endMonthOfQuarter + 1, 0);
            this.quarterlyMonths.push({
                quarter: quarter,
                startDate: startDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }),
                endDate: endDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
            });
        }

        console.log('quarterlyMonths', this.quarterlyMonths)
    }
    async getCompany(){
        const filter = {
            _id: this._authService.currentUser.company_id
        };
        const project = {
            name: 1,
            company_type: 1,
            enable_company_mips: 1
        }
        const response: any = await lastValueFrom(this.companyService.getCompany(filter, project));
        if(response.status === 200) {
            this.currentCompany = response.data;
            if(this.currentCompany.company_type.toLowerCase() === 'wound'){
                this.currentCompany.isWoundCompany = true;
            }
        }
    }
    onMipsClick(value) {
        console.log('valuevaluevalue', value)
        this.showWoundContainer = false;
        this.showChargesContainer = false;
        this.showReportsContainer = false;
        if (this.prevMipsValue !== value) {
            this.showMipsContainer = false;
            this.prevMipsValue = value;
            setTimeout(() => {
                this.showMipsContainer = true;
            }, 10);
        }
    }

    /**
     * filters functions
     */
    async initProvidersForProvider() {
        let tFilters = JSON.parse(localStorage.getItem('tranFilters'));
        let dFitlers = JSON.parse(localStorage.getItem('dictFilters'));
        let doctorsResponse: any;
        if (this._authService.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
            const filter = {
                snf_wc_nurse_id: this._authService.currentUser._id,
                company_id: this._authService.currentUser.company_id
            };
            const project = {
                first_name: 1,
                last_name: 1,
                title: 1,
                company_id: 1
            };
            doctorsResponse = await lastValueFrom(this._snfWcNurseAssociationService.getAssociatedSnfWcNurses(filter, project));
            if (doctorsResponse.status === 200) {
                doctorsResponse.data.assoc_provider_id = doctorsResponse.data.associated_snf_wc_nurse_ids || [];
            }
        } else if (this.datas.user_type === global.USER_TYPE.VIEW_ONLY_USER && this.datas.company_type === global.COMPANY_TYPE.SNF) {
            doctorsResponse = await lastValueFrom(this.viewOnlyUsersService.getAssociatedProvidersListForViewOnlUsers({ user_id: this.datas._id, company_id: this.datas.company_id }));
        }
        else {
            doctorsResponse = await this.dashboard.getP2PRelation().toPromise();
        }
        if (doctorsResponse.status == 200) {
            if (!this.datas.title) {
                this.datas.title = ''
            }
            if (this.datas.user_type === global.USER_TYPE.VIEW_ONLY_USER && this.datas.company_type === global.COMPANY_TYPE.SNF) {
                doctorsResponse = doctorsResponse.data;
                this.fData.providers.tran = doctorsResponse

            } else {
                doctorsResponse = doctorsResponse.data.assoc_provider_id;
                this.fData.providers.tran = [this.datas].concat(doctorsResponse)
            }
            //searching
            this.filteredProviders = this.providerControl.valueChanges.pipe(
                startWith(''),
                map((value: any) => this.filterProviers(value)));

            this.fData.providers.tran = this.fData.providers.tran.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));
            // this.fData.providers.tran[0].name = 'My Work'; 
            this.fData.providers.dict = [this.datas].concat(doctorsResponse)
            this.fData.providers.dict = this.fData.providers.dict.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));

            // this.fData.providers.dict[0].name = 'My Work'; 
            if (this.fData.providers.tran[0]) {
                if (tFilters?.provider) {
                    this.tranFilters.provider = tFilters.provider;
                } else {
                    this.tranFilters.provider = this.fData.providers.tran[0];
                    localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
                }
                if (tFilters?.dos) {
                    this.dictFilters.dos = tFilters.dos;
                } else {
                    this.tranFilters.dos = '';
                    localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
                }
                if (dFitlers?.provider) {
                    this.dictFilters.provider = dFitlers.provider;
                } else {
                    this.dictFilters.provider = this.fData.providers.dict[0];
                    localStorage.setItem('dictFilters', JSON.stringify(this.dictFilters));
                }
                if (dFitlers?.dos) {
                    this.dictFilters.dos = dFitlers.dos;
                } else {
                    this.dictFilters.dos = '';
                    localStorage.setItem('dictFilters', JSON.stringify(this.dictFilters));
                }
            }
            let pro;
            if (this.category === 'tran') {
                pro = this.tranFilters.provider;
            }
            else if (this.category == 'dict') {
                pro = this.dictFilters.provider;
            }
            // }
            if (this.fData.facilities.dict == null || this.fData.facilities.tran == null) {
                this.facilityControl.disable();
                this.facilityControlDict.disable();
                let facilitiesResponse: any;
                if (this._authService.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE || this.datas.company_type === global.COMPANY_TYPE.SNF) {
                    const filter = {
                        associated_snf_wc_nurse_ids: pro._id,
                        company_id: this._authService.currentUser.company_id
                    };
                    const nurseProjection = {
                        first_name: 1,
                        last_name: 1,
                        title: 1,
                    };
                    const facilityProjection = {
                        title: 1,
                        pcc_facId: 1,
                        pcc_2_legged_authentication: 1,
                        source: 1,
                        pcc_orgUuid: 1
                    };
                    facilitiesResponse = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
                    if (facilitiesResponse.status === 200) {
                        // facilitiesResponse.data.array = facilitiesResponse.data;
                    }
                }
                else if (this._authService.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
                    const filter = {
                        company_id_ref: this.datas.company_id,
                        associated_wound_nurse_ids: this._authService.currentUser._id
                    };
                    const nurseProjection = {
                        first_name: 1,
                        last_name: 1,
                        title: 1,
                    };
                    const facilityProjection = {
                        title: 1,
                        pcc_facId: 1,
                        pcc_2_legged_authentication: 1,
                        source: 1
                    };
                    facilitiesResponse = await lastValueFrom(this.woundNurseService.getWoundNurseFacilities(filter, nurseProjection, facilityProjection));
                    if (facilitiesResponse.status === 200) {
                        facilitiesResponse.data.array = facilitiesResponse.data;
                        console.log('facilitiesResponse', facilitiesResponse);

                    }
                }
                else {
                    if (this.datas.company_type != global.COMPANY_TYPE.SNF)
                        facilitiesResponse = await this._transcriptionCreateService.getFacilities(pro._id).toPromise();
                }
                // .getFacilities(this.datas._id).toPromise();
                if (facilitiesResponse.status === 200) {
                    this.filteredFacilities = this.facilityControl.valueChanges.pipe(
                        startWith(''),
                        map((value: any) => this.filterFacilities(value)));
                    if (this.datas.user_type === global.USER_TYPE.SNF_WC_NURSE || this.datas.company_type === global.COMPANY_TYPE.SNF) {
                        this.fData.facilities.dict = facilitiesResponse.data;
                    } else {
                        this.fData.facilities.dict = facilitiesResponse.data.array;
                    }
                    this.fData.facilities.dict.sort((a, b) => a.title.localeCompare(b.title));

                    if (this.datas.user_type === global.USER_TYPE.SNF_WC_NURSE || this.datas.company_type === global.COMPANY_TYPE.SNF) {
                        this.fData.facilities.tran = facilitiesResponse.data;
                    } else {
                        this.fData.facilities.tran = facilitiesResponse.data.array;
                    }
                    this.fData.facilities.tran.sort((a, b) => a.title.localeCompare(b.title));

                    console.log("this.fData.facilities.tran[0] : ", this.fData.facilities.tran[0], tFilters?.facility, this.tranFilters.facility)
                    if (this.fData.facilities.dict[0] || this.fData.facilities.tran[0]) {

                        if (tFilters?.facility) {
                            this.tranFilters.facility = tFilters.facility;
                            localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
                        } else {
                            this.tranFilters.facility = { title: 'All', source: 'dn' };
                            localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));

                        }
                        if (dFitlers?.facility) {
                            this.dictFilters.facility = dFitlers.facility;
                            localStorage.setItem('dictFilters', JSON.stringify(this.dictFilters));
                        } else {
                            this.dictFilters.facility = this.fData.facilities.dict[0];
                            localStorage.setItem('dictFilters', JSON.stringify(this.dictFilters));

                        }

                        this.pccService.pccUserinfo$.subscribe(() => {

                        });
                        this.fData.facilities.tran.forEach(element => {
                            if (element.source == "PointClickCare" && this.pccUserinfo) {
                                this.tranFilters.facility = element;
                                localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
                            }
                        });
                    }
                    this.facilityControl.enable();
                    this.facilityControlDict.enable();
                }
            }
        }
        if (this.datas.user_type === global.USER_TYPE.WOUND_NURSE) {
            this.applyNoteFilters();
        }
    }

    showDropdown(): void {
        this.filteredFacilities = this.facilityControl.valueChanges.pipe(
            startWith(''),
            map((value: any) => this.filterFacilities(value)));
    }

    showProvidersDropDown(): void {
        this.filteredProviders = this.providerControl.valueChanges.pipe(
            startWith(''),
            map((value: any) => this.filterProviers(value)));
    }

    newsExist(event) {
        if (event) {
            this.news = true;
        }
        else {
            this.news = false;
        }
    }
    hasLoggedInToPCC() {
        return !!this.pccService?.pccUserinfo;
    }
    isLoggedInPCCFacility(dnFacility) {
        return this.pccService.isLoggedInFacility(dnFacility);
    }
    showUploadButton(note) {
        // if not pcc note
        if (this.tranFilters.facility?.source !== 'PointClickCare') {
            return false;
        }
        if (!this.tranFilters.facility?._id) {
            return false;
        }
        if (this.tranFilters.progress_status == "Unsigned" && this.tranFilters.facility.source != 'PointClickCare') {
            return true;
        }
        else if (this.tranFilters.progress_status == "Unsigned" && this.tranFilters.facility.source == 'PointClickCare') {
            return false
        }
        else if (this.tranFilters.progress_status == "Signed" && this.tranFilters.facility.source == 'PointClickCare' && this.hasLoggedInToPCC() && this.isLoggedInPCCFacility(this.tranFilters.facility)) {
            return true
        }
        else if (this.tranFilters.progress_status == "Signed" && this.tranFilters.facility.source != 'PointClickCare') {
            return true
        }
        else
            return false
    }
    showSignButton() {
        if (!this.tranFilters.facility?._id) {
            return false;
        }
        if (this.tranFilters.progress_status == 'Unsigned') {
            if (this.tranFilters.progress_status == "Signed" && this.tranFilters.facility.source == 'PointClickCare') {
                return this.hasLoggedInToPCC() && this.isLoggedInPCCFacility(this.tranFilters.facility);
            }
            return true;
        }
        return false;
    }
    async uploadMultipleNotes() {
        if (!this.hasLoggedInToPCC() && this.tranFilters.facility.source == 'PointClickCare') {
            this.toastr.error("You've to login to PCC to upload notes")
            return
        }
        else {
            this.toastr.info(`Uploading Note/s...`);
            let hasLoggedInToPCC = this.hasLoggedInToPCC();
            const notesIdsToBeUploaded = this.multiSelectedTranscriptions.map(a => a._id);
            const promises = notesIdsToBeUploaded.map((noteId) => lastValueFrom(this.noteEditorService.uploadNoteV2(noteId, hasLoggedInToPCC)));
            const responses = await Promise.all(promises);
            const failedNotes = responses.filter((response: any) => response.status !== 200);
            const successNotes = responses.filter((response: any) => response.status === 200);
            if (successNotes.length && failedNotes.length) {
                const message = `${successNotes.length} Note/s uploaded successfully, ${failedNotes.length} Note/s failed to upload`;
                this.toastr.warning(`${message}`);
            } else if (successNotes.length && !failedNotes.length) {
                this.toastr.success(`${successNotes.length} Note/s uploaded successfully`);
            } else if (!successNotes.length && failedNotes.length) {
                this.toastr.error(`${failedNotes.length} Note/s failed to sign`);
            } else {
                this.toastr.error(`Something went wrong while uploading note, please try again`, "Failed");
            }
            this.multiSelectedTranscriptions = []
            this.multiSelectedTranscriptions.length = 0;
            this.refreshDashboard();
        }
    }
    async signMultipleNotes() {
        if (this.hasLoggedInToPCC() && this.tranFilters.facility.source == 'PointClickCare') {
            this.toastr.info(`Uploading Note/s...`);
        }
        else {
            this.toastr.info(`Signing Note/s...`);
        }

        // this.toastr.info(`Note/s uploaded successfully`);
        let hasLoggedInToPCC = this.hasLoggedInToPCC();

        const titledNotes = this.multiSelectedTranscriptions.filter((e) => { return !(e?.transcribtion_title?.toLowerCase() == 'no title') });
        const untitledNotes = this.multiSelectedTranscriptions.filter((e) => { return (e?.transcribtion_title?.toLowerCase() == 'no title') });
        if (untitledNotes && untitledNotes.length) {
            this.toastr.warning(`${untitledNotes.length} Note(s) exists without Title.`);
        }

        let notesIdsToBeSigned = titledNotes.map(a => a._id);
        const woundsDataToSignNote: any = await this.woundService.checkWoundDatesToSignNote(titledNotes.map(a => a.patient_id)).toPromise();
        if (woundsDataToSignNote.data) {
            const allPatientsWithoutPriorDateWounds = Array.from(new Set(woundsDataToSignNote.data.map((w) => w.patient_id)));
            this.toastr.warning(`${allPatientsWithoutPriorDateWounds?.length} Note(s) exist without Wounds Facility Acquired or Prior to Admission dates.`);
            notesIdsToBeSigned = titledNotes.filter((note) => !allPatientsWithoutPriorDateWounds.includes(note.patient_id)).map(a => a._id);
            if (notesIdsToBeSigned?.length == 0) return;
        }
        const promises = notesIdsToBeSigned.map((noteId) => lastValueFrom(this.noteEditorService.signNoteV2(noteId, hasLoggedInToPCC)));
        const responses = await Promise.all(promises);
        const failedNotes = responses.filter((response: any) => response.status !== 200);
        const successNotes = responses.filter((response: any) => response.status === 200);
        if (successNotes.length && failedNotes.length) {
            const message = `${successNotes.length} Note/s signed successfully, ${failedNotes.length} Note/s failed to sign`;
            this.toastr.warning(`${message}`);
        } else if (successNotes.length && !failedNotes.length) {
            this.toastr.success(`${successNotes.length} Note/s signed successfully`);
        } else if (!successNotes.length && failedNotes.length) {
            this.toastr.error(`${failedNotes.length} Note/s failed to sign`);
        } else {
            this.toastr.error(`Something went wrong while signing note, please try again`, "Failed");
        }
        this.multiSelectedTranscriptions = []
        this.multiSelectedTranscriptions.length = 0;
        this.refreshDashboard();

    }

    updateTranscriptionsArray($event) {
        let transcriptionToRemove = $event;
        this.multiSelectedTranscriptions = this.multiSelectedTranscriptions.filter(t => t._id !== transcriptionToRemove._id);
    }

    async exportNote(operation, row) {
        if (operation === 'download') {
            if (this.multiSelectedTranscriptions.length > 0) {
                this.toastr.info('Downloading Notes', 'Downloading...');
                this.multiSelectedTranscriptions = this.multiSelectedTranscriptions.map(d => d?._id)
            }
            const fileName = `${this.multiSelectedTranscriptions.length} note(s).zip`;
            await this.dashboard.downloadFile(global.url + API_URL.NOTE.generateMultiplePDF, this.multiSelectedTranscriptions).subscribe((res: any) => {
                if (res instanceof HttpResponse) {

                    var url = window.URL.createObjectURL(res.body);
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.setAttribute('style', 'display: none');
                    a.href = url;
                    a.download = fileName;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove(); // remove the element
                } else {

                }
            }, (err) => {
                this.toastr.error('Something went wrong while downloading notes', 'Failed');
            },
                () => {
                });
            this.multiSelectedTranscriptions = []
            this.multiSelectedTranscriptions.length = 0;
        }
        else if (operation === 'send_to_email') {
            const dialogRef = this.dialog.open(ExportNotesDialogComponent, { width: '40%' });
            dialogRef.afterClosed().subscribe(async result => {
                if (result.emails.length > 0) {
                    this.toastr.info('Sending emails', 'Sending...');
                    if (this.multiSelectedTranscriptions.length > 0) {
                        this.multiSelectedTranscriptions = this.multiSelectedTranscriptions.map(d => d?._id)
                    }
                    // @ts-ignore    
                    this.noteEditorService.generatePDFsLinksAndEmail(result.emails, this.multiSelectedTranscriptions, this.tranFilters.provider?.name, this.tranFilters.facility?.title)

                        //                    this.noteEditorService.generatePDFsAndEmail(result.emails, this.multiSelectedTranscriptions, this.tranFilters.provider?.name, this.tranFilters.facility?.title)

                        .subscribe((response: any) => {
                            if (response.status == 200) {
                                this.toastr.info('Emails sent');
                            }
                        }, () => {
                        }, () => {
                            this.multiSelectedTranscriptions = []
                            this.multiSelectedTranscriptions.length = 0;
                        })
                }
            })
        }
    }
    async refreshRelation(provider?) {
        console.log("this.tranFilters.provider : ", this.tranFilters.provider?.first_name);
        let provider_id = provider || this.tranFilters.provider?._id;
        await this._transcriptionCreateService.getRelations(provider_id).subscribe((res: any) => {
            this.isEQAofProvider = false;
            this.isIQAofProvider = false;
            this.isMAofProvider = false;

            if (res?.data && res.data[0]) {
                let response = res.data?.trans[0];
                let responsema = res.data?.ma[0];

                console.log("response : ", response, this._authService.currentUser._id?.toString());

                if (this._authService.currentUser._id?.toString() == response.qa_id?.toString()) {
                    this.isIQAofProvider = true;
                }
                if (this._authService.currentUser._id?.toString() == response.qa_e_id?.toString()) {
                    this.isEQAofProvider = true;
                }
                if ((responsema.assoc_ma_ids.findIndex((e) => { return e?.toString() == this._authService.currentUser._id?.toString() })) >= 0) {
                    this.isMAofProvider = true;
                    console.log("user is ma : ", this.isMAofProvider);
                }
                console.log("1st ", this.isIQAofProvider, this.isEQAofProvider, this.isMAofProvider);
            }

            // if(this._authService.currentUser._id?.toString()==response.qa_id?.toString()){
            //     this.isIQAofProvider=true;
            // }else{
            //     this.isIQAofProvider=false;
            // }
            // if(this._authService.currentUser._id?.toString()==response.qa_e_id?.toString()){
            //     this.isEQAofProvider=true;
            // }else{
            //     this.isEQAofProvider=false;
            // }

        })
    }
    async initProviders() {
        let tFilters = JSON.parse(localStorage.getItem('tranFilters'));
        let dFitlers = JSON.parse(localStorage.getItem('dictFilters'));
        let doctorsResponse: any;
        if (JSON.parse(localStorage.getItem('currentUser')).user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
            doctorsResponse = await this._transcriptionCreateService.getAssociatedDoctorsListForMA(this._authService.currentUser.company_id).toPromise();
        }
        else if (JSON.parse(localStorage.getItem('currentUser')).user_type == global.USER_TYPE.BUSINESS_OFFICER) {
            let currentUser = this._authService.currentUser
            doctorsResponse = await this._transcriptionCreateService.getAssociatedDoctorsListForBO(currentUser._id, currentUser.company_id, currentUser.user_type).toPromise()
        }
        // else if( JSON.parse(localStorage.getItem('currentUser')).user_type == global.USER_TYPE.WOUND_NURSE ) {
        //     doctorsResponse = await lastValueFrom(this._transcriptionCreateService.getAssociatedDoctorsListForWoundNurse(this.datas._id,this.datas.company_id));           
        // }
        else {
            doctorsResponse = await this._transcriptionCreateService.getAssociatedDoctorsList(this._authService.currentUser.company_id).toPromise();
        }
        if (this.datas.user_type === global.USER_TYPE.VIEW_ONLY_USER) {
            doctorsResponse = await lastValueFrom(this.viewOnlyUsersService.getAssociatedProvidersListForViewOnlUsers({ user_id: this.datas._id, company_id: this.datas.company_id }));
        }
        this.fData.providers.tran = doctorsResponse.data.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));
        this.fData.providers.dict = doctorsResponse.data.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));
        if (this.fData.providers.tran[0]) {



            if (tFilters?.provider) {
                this.tranFilters.provider = tFilters.provider;
            } else {
                this.tranFilters.provider = this.fData.providers.tran[0];
                localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
            }
            await this.refreshRelation();
            if (tFilters?.dos) {
                this.dictFilters.dos = tFilters.dos;
            } else {
                this.tranFilters.dos = '';
                localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
            }
            if (dFitlers?.provider) {
                this.dictFilters.provider = dFitlers.provider;
            } else {
                this.dictFilters.provider = this.fData.providers.dict[0];
                localStorage.setItem('dictFilters', JSON.stringify(this.dictFilters));
            }
            if (dFitlers?.dos) {
                this.dictFilters.dos = dFitlers.dos;
            } else {
                this.dictFilters.dos = '';
                localStorage.setItem('dictFilters', JSON.stringify(this.dictFilters));
            }
        }
        // if ((this.fData.facilities.dict === null || this.fData.facilities.tran === null) && this.fData.providers && this.fData.providers[0]) {
        let pro;
        if (this.category === 'tran') {
            pro = this.tranFilters.provider;
        }
        else if (this.category == 'dict') {
            pro = this.dictFilters.provider;
        }
        this.facilityControl.disable();
        this.facilityControlDict.disable();
        const facilitiesResponse: any = await this._transcriptionCreateService
            .getFacilities(pro._id).toPromise();
        if (facilitiesResponse.status === 200 && this.fData?.providers?.tran?.length > 0) {
            // console.log("this is done",this.fData.providers.tran);
            this.filteredFacilities = this.facilityControl.valueChanges.pipe(
                startWith(''),
                map((value: any) => this.filterFacilities(value)));
            this.fData.facilities.dict = facilitiesResponse.data.array;
            this.fData.facilities.tran = facilitiesResponse.data.array;
            if (this.fData.facilities && this.fData.facilities.dict[0] || this.fData.facilities.tran[0]) {
                if (tFilters?.facility) {
                    this.tranFilters.facility = tFilters.facility;
                    // this.tranFilters.facility = { title: 'All', source: 'dn' }; //if need of All facility option for other user types
                    localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters))
                } else {
                    this.tranFilters.facility = this.fData.facilities.tran[0];
                    localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
                }
                if (dFitlers?.facility) {
                    this.dictFilters.facility = dFitlers.facility;
                    localStorage.setItem('dictFilters', JSON.stringify(this.dictFilters));
                } else {
                    this.dictFilters.facility = this.fData.facilities.dict[0];
                    localStorage.setItem('dictFilters', JSON.stringify(this.dictFilters));
                }
            }
            this.facilityControl.enable();
            this.facilityControlDict.enable();
        }
        // }

    }
    providerControlDict = new FormControl();
    facilityControlDict = new FormControl();
    patientControl = new FormControl();
    displayProviderFn(provider): string {
        return provider && provider.name ? provider.name : '';
    }

    displayFacilityFn(facility): string {
        return facility?.title ? facility.title : '';
    }
    displayPatientFn(patient): string {
        if (patient?.name === '--All--') return patient?.name;
        if (patient?.middle_name && patient?.middle_name !== null)
            return patient ? `${patient.first_name} ${patient.middle_name} ${patient.last_name}` : '';

        return patient ? `${patient.first_name} ${patient.last_name}` : '';

    }
    async refreshFacilities(event = null, type = null) {
        this.facilityControl.disable();
        this.facilityControlDict.disable();
        this.providerControl.disable();
        this.providerControlDict.disable();
        console.log('event', event);
        console.log('type', type);
        let elem = document.getElementById('inputIDProvider');
        elem.blur();
        this.tranFilters.patient = null;
        this.fData.patients = [];
        localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
        localStorage.setItem('dictFilters', JSON.stringify(this.dictFilters));
        let provider_id;
        provider_id = event.option.value._id;
        await this.refreshRelation(provider_id);
        let facilitiesResponse: any;
        if (this.datas.user_type === global.USER_TYPE.SNF_WC_NURSE || this.datas.company_type === global.COMPANY_TYPE.SNF) {
            const filter = {
                associated_snf_wc_nurse_ids: provider_id,
                company_id: this._authService.currentUser.company_id
            };
            const nurseProjection = {
                first_name: 1,
                last_name: 1,
                title: 1,
            };
            const facilityProjection = {
                title: 1,
                pcc_facId: 1,
                pcc_2_legged_authentication: 1,
                source: 1,
                pcc_orgUuid: 1
            };
            facilitiesResponse = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
            if (facilitiesResponse.status === 200) {
                facilitiesResponse.data.array = facilitiesResponse.data;
            }
        } else if (this.datas.user_type === global.USER_TYPE.WOUND_NURSE) {
            if (this._authService.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
                const filter = {
                    company_id_ref: this.datas.company_id,
                };
                const nurseProjection = {
                    first_name: 1,
                    last_name: 1,
                    title: 1,
                };
                const facilityProjection = {
                    title: 1,
                    pcc_facId: 1,
                    pcc_2_legged_authentication: 1,
                    source: 1
                };
                facilitiesResponse = await lastValueFrom(this.woundNurseService.getWoundNurseFacilities(filter, nurseProjection, facilityProjection));
                if (facilitiesResponse.status === 200) {
                    facilitiesResponse.data.array = facilitiesResponse.data;
                    console.log('facilitiesResponse', facilitiesResponse);

                }
            }
        }
        else {
            facilitiesResponse = await this._transcriptionCreateService
                .getFacilities(provider_id).toPromise();
        }

        if (facilitiesResponse.data.array.length == 0) {
            this.facilityControl.enable();
            this.facilityControlDict.enable();
            this.providerControl.enable();
            this.providerControlDict.enable();
            return;
        }
        if (facilitiesResponse.status === 200) {
            if (type == 'tran') {
                this.fData.facilities.tran = facilitiesResponse.data.array;
            }
            if (type == 'dict') {
                this.fData.facilities.dict = facilitiesResponse.data.array;
            }

            if (this.fData.facilities.dict[0] || this.fData.facilities.tran[0]) {
                if (type && type == 'tran') {

                    if (this.datas.user_type == global.USER_TYPE.DOCTOR || this.datas.user_type == global.USER_TYPE.SNF_WC_NURSE || this.datas.user_type === global.USER_TYPE.WOUND_NURSE || this.datas.user_type === global.USER_TYPE.VIEW_ONLY_USER) {
                        this.tranFilters.facility = { title: 'All', source: 'dn' };
                        console.log('yes...........', this.tranFilters.facility);

                    } else {
                        this.getPatientByFacility(this.fData.facilities.tran[0]._id);
                        this.tranFilters.facility = this.fData.facilities.tran[0];
                    }
                    localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
                }
                else if (type && type == 'dict') {
                    this.dictFilters.facility = this.fData.facilities.dict[0];
                    localStorage.setItem('dictFilters', JSON.stringify(this.dictFilters));
                }
                if (this.category === 'dict') {
                    this.applyDictationFilters();
                }
                else {
                    if (this.isEQAofProvider) {
                        console.log("is EQA another");
                        this.applyNoteFiltersforQAs('EQA');
                    } else if (this.isIQAofProvider) {
                        console.log("is IQA another");
                        this.applyNoteFiltersforQAs('IQA');
                    } else {
                        this.applyNoteFilters();
                    }
                }
            }
            this.facilityControl.enable();
            this.facilityControlDict.enable();
            this.providerControl.enable();
            this.providerControlDict.enable();
        }
        this.showProvidersDropDown();
        if (this._authService.currentUser.user_type !== global.USER_TYPE.SNF_WC_NURSE && this._authService.currentUser.user_type !== global.USER_TYPE.WOUND_NURSE) {
            await this.getCount();
            await this.getAddendumReqCount();
        }

    }

    private filterFacilities(value: any): string[] {
        const filterKeydownEvent = this.filterKeydownEvent;
        this.filterKeydownEvent = undefined;
        if (typeof value === 'object') {
            if (filterKeydownEvent && value && this.fData.facilities.tran.length > 0) {
                const filterValue = value.title.toLowerCase();
                return this.fData.facilities.tran.filter(option => option.title.toLowerCase().indexOf(filterValue) > -1);
            }
            else {
                return this.fData.facilities.tran;
            }
        }
        else if (filterKeydownEvent && value && this.fData.facilities.tran.length > 0) {
            const filterValue = value.toLowerCase();
            return this.fData.facilities.tran.filter(option => option.title.toLowerCase().indexOf(filterValue) > -1);
        }
        else {
            return this.fData.facilities.tran;
        }
    }

    private filterProviers(value: any): string[] {
        if (typeof value === 'object') {
            if (value && this.fData.providers.tran.length > 0) {
                const filterValue = value.title.toLowerCase();
                //   return this.fData.providers.tran.filter(option => (option.middle_name && option.middle_name.toLowerCase().indexOf(filterValue) === 0) || option.last_name.toLowerCase().indexOf(filterValue) === 0 || option.first_name.toLowerCase().indexOf(filterValue) === 0);
                return this.fData.providers.tran.filter(option => option.name.toLowerCase().indexOf(filterValue) > -1);
            }
            else {
                return this.fData.providers.tran;
            }
        }
        else if (value && this.fData.providers.tran.length > 0) {
            const filterValue = value.toLowerCase();
            // return this.users.filter(option => (option.middle_name && option.middle_name.toLowerCase().indexOf(filterValue) === 0) || option.last_name.toLowerCase().indexOf(filterValue) === 0 || option.first_name.toLowerCase().indexOf(filterValue) === 0);
            return this.fData.providers.tran.filter(option => option.name.toLowerCase().indexOf(filterValue) > -1);
        }
        else {
            return this.fData.providers.tran;
        }
    }
    async refreshNotes() {
        this.applyNoteFilters();
    }

    // focusOut(){

    // }
    async refreshPatients(event) {
        this.tranFilters.patient = null;
        this.fData.patients = [];
        let facility_id = event.option.value._id;
        this.patientListService.getFacilityPatients(facility_id)
            .subscribe((response: any) => {
                if (response.status === 200) {
                    this.fData.patients = response.data.array;
                }
            }, (error) => {
            },
                () => {
                }
            );
    }



    onChangeStatus(status = null) {
        if (status) {
            this.dictFilters.progress_status = status.code;
            localStorage.setItem('dictFilters', JSON.stringify(this.dictFilters));
            this.applyCounterFilter()
        }
        else {
            this.dictFilters.progress_status = '';
            localStorage.setItem('dictFilters', JSON.stringify(this.dictFilters));
            this.applyCounterFilter()
        }
    }
    onChangeStatusTrans(status = null) {
        if(this._route.snapshot.params.category == 'dict') {
            this.category = 'tran';
        }
        this.showChargesContainer = false;
        this.prevMipsValue = '';
        this.showWoundContainer = false;
        this.showReportsContainer = false;
        this.showMipsContainer = false;
        this.multiSelectedTranscriptions = []
        this.multiSelectedTranscriptions.length = 0;
        // if (status) {

        // this.tranFilters.progress_status =  status.value;
        if (status.trim().toLocaleLowerCase() == "Un_Signed".toLocaleLowerCase() || status.trim().toLocaleLowerCase() == "Un Signed".trim().toLocaleLowerCase()) {
            this.tranFilters.progress_status = this.statusOptionTrans[0];
        }
        else if (status.trim().toLocaleLowerCase() == "Signed".trim().toLocaleLowerCase()) {
            this.tranFilters.progress_status = this.statusOptionTrans[1];
        }
        else if (status.trim().toLocaleLowerCase() == "Uploaded".trim().toLocaleLowerCase()) {
            this.tranFilters.progress_status = this.statusOptionTrans[2];
        }
        else if (status.trim().toLocaleLowerCase() == "Trashed".trim().toLocaleLowerCase()) {
            this.tranFilters.progress_status = this.statusOptionTrans[3];
        }
        else if (status.trim().toLocaleLowerCase() == "Co-Signed".trim().toLocaleLowerCase()) {
            this.tranFilters.progress_status = this.statusOptionTrans[4];
        }
        this.tranFilters.note_status = '';
        localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
        this.applyCounterFilterTrans()
        // }
        // else {
        //     this.dictFilters.progress_status = '';
        //     localStorage.setItem('dictFilters', JSON.stringify(this.dictFilters));
        //     this.applyCounterFilter()
        // }
    }

    async applyCounterFilter(event = null) {
        let end;
        let start;
        if (event) {
            this.update = false;
            this.dateFilter.endDate = moment(new Date(event.date).toLocaleString('en-US', { timeZone: event.timezone }));
            this.dateFilter.startDate = moment(new Date(event.date).toLocaleString('en-US', { timeZone: event.timezone }));

            localStorage.setItem('dateFilter', JSON.stringify(this.dateFilter));
            // this.dateFilter.startDate = moment(new Date(event.date).toLocaleString('en-US', { timeZone: event.timezone }));
            // this.dateFilter.endDate = moment(new Date(event.date).toLocaleString('en-US', { timeZone: event.timezone }));
            setTimeout(() => {
                this.update = true;
            }, 500)
            let providerIndex, pro;
            // let pro;
            if (this.category == 'tran') {
                providerIndex = this.fData.providers.tran.findIndex(i => i._id == event.provider)
                this.tranFilters.provider = this.fData.providers.tran[providerIndex];
                localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
                //@ts-ignore
                pro = this.tranFilters.provider._id;
            }
            if (this.category == 'dict') {
                providerIndex = this.fData.providers.dict.findIndex(i => i._id == event.provider)
                this.dictFilters.provider = this.fData.providers.dict[providerIndex];
                localStorage.setItem('dictFilters', JSON.stringify(this.dictFilters));
                //@ts-ignore
                pro = this.dictFilters.provider._id;
            }
            this.facilityControl.disable();
            this.facilityControlDict.disable();
            const facilitiesResponse: any = await this._transcriptionCreateService
                .getFacilities(pro).toPromise();
            if (facilitiesResponse.status === 200) {
                this.fData.facilities.dict = facilitiesResponse.data.array;
                this.facilityControl.enable();
                this.facilityControlDict.enable();
            }
            let facilityIndex = this.fData.facilities.dict.findIndex(i => i._id == event.facility)

            this.dictFilters.facility = this.fData.facilities.dict[facilityIndex];
            localStorage.setItem('dictFilters', JSON.stringify(this.dictFilters));
            this.dictFilters.progress_status = event.status;
            localStorage.setItem('dictFilters', JSON.stringify(this.dictFilters))
            if (event.date) {
                // end = moment(event.date);
                end = event.date;
            }
            this.dictFilters.dos = end;
            localStorage.setItem('dictFilters', JSON.stringify(this.dictFilters))
            start = this.dateFilter.startDate.toLocaleString();
            end = this.dateFilter.endDate.toLocaleString();
        }
        else {
            // end = this.dictFilters.dos;
            start = this.dateFilter.startDate.toLocaleString();
            end = this.dateFilter.endDate.toLocaleString();
        }

        this.loading = true;
        this.dictationsLoaded = false;
        if (!this.countFilter) {
            this.dashboard.getAudiosinfo(
                // event.status,
                this.surpassedTAT,
                this.dictFilters,
                this.datas._id,
                this.datas.auth_key,
                this.datas.user_type,
                end,
                start

            )
                .subscribe(
                    (response: any) => {
                        this.recordings = response.data.array;
                        if (this.dictFilters.dos && this.dictFilters.dos != '') {
                            // response.data.array = []
                            // response.data.array.map(row=>{
                            for (let i in response.data.array) {
                                if (moment(new Date(response.data.array[i].filterUploadingDate).toLocaleString('en-US', { timeZone: response.data.array[i].timezone })).format('MMM DD, YYYY') != moment(new Date(this.dictFilters.dos).toLocaleString('en-US', { timeZone: response.data.array[i].timezone })).format('MMM DD, YYYY')) {
                                    response.data.array[i] = {}
                                }
                            }
                        }
                        this.dictCountData = response.data.countData;
                        global.cached.set('dashboard:dictations', response);

                        this.initDictationsAndTranscriptions();
                    },
                    error => {
                    },
                    () => {
                        this.loading = false;
                        this.dictationsLoaded = true;
                    }
                );
        }
    }

    async applyCounterFilterTrans(event = null) {
        let end, endDate, startDate;
        this.refreshDashboard();
        if (event) {
            this.update = false;
            this.noteDateFilter.endDate = moment(new Date(event.date).toLocaleString('en-US', { timeZone: event.timezone }));
            this.noteDateFilter.startDate = moment(new Date(event.date).toLocaleString('en-US', { timeZone: event.timezone }));

            localStorage.setItem('noteDateFilter', JSON.stringify(this.noteDateFilter));

            // this.noteDateFilter.startDate = moment(new Date(event.date).toLocaleString('en-US', { timeZone: event.timezone }));
            // this.noteDateFilter.endDate = moment(new Date(event.date).toLocaleString('en-US', { timeZone: event.timezone }));
            setTimeout(() => {
                this.update = true;
            }, 500)

            if (event.status.trim().toLocaleLowerCase() == "Un_Signed".trim().toLocaleLowerCase() || event.status.trim().toLocaleLowerCase() == "Un Signed".trim().toLocaleLowerCase()) {
                this.tranFilters.progress_status = this.statusOptionTrans[0];
            }
            else if (event.status.trim().toLocaleLowerCase() == "Signed".trim().toLocaleLowerCase()) {
                this.tranFilters.progress_status = this.statusOptionTrans[1];
            }
            else if (event.status.trim().toLocaleLowerCase() == "Uploaded".trim().toLocaleLowerCase()) {
                this.tranFilters.progress_status = this.statusOptionTrans[2];
            }
            else if (event.status.trim().toLocaleLowerCase() == "Trashed".trim().toLocaleLowerCase()) {
                this.tranFilters.progress_status = this.statusOptionTrans[3];
            }
            else if (event.status.trim().toLocaleLowerCase() == "Co-Signed".trim().toLocaleLowerCase()) {
                this.tranFilters.progress_status = this.statusOptionTrans[4];
            }
            this.tranFilters.note_status = event.note_status;
            this.status = event.status;
            // this.tranFilters.patient = event.patient
            localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
            //if user is transcriber
            let pro;
            if (this.datas.user_type == '2' || this.datas.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
                let providerIndex;
                if (this.category == 'tran') {
                    providerIndex = this.fData.providers.tran.findIndex(i => i._id == event.doctor_id_ref)
                    this.tranFilters.provider = this.fData.providers.tran[providerIndex];
                    localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
                    //@ts-ignore
                    pro = this.tranFilters.provider._id;
                }
                else if (this.category == 'dict') {

                    providerIndex = this.fData.providers.dict.findIndex(i => i._id == event.doctor_id_ref)
                    this.dictFilters.provider = this.fData.providers.dict[providerIndex];
                    localStorage.setItem('dictFilters', JSON.stringify(this.dictFilters));
                    //@ts-ignore
                    pro = this.tranFilters.provider._id;
                }
                // console.log("providerIndex: ", providerIndex);

                // this.tranFilters.provider = this.fData.providers.tran[providerIndex];
                // console.log("this.tranFilters.provider: ", this.tranFilters.provider);
                // localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
                // //@ts-ignore
                // let pro = this.tranFilters.provider._id;
                this.facilityControl.disable();
                this.facilityControlDict.disable();
                const facilitiesResponse: any = await this._transcriptionCreateService
                    .getFacilities(pro).toPromise();
                if (facilitiesResponse.status === 200) {
                    this.fData.facilities.tran = facilitiesResponse.data.array;
                    this.facilityControl.enable();
                    this.facilityControlDict.enable();
                }
                let facilityIndex = this.fData.facilities.tran.findIndex(i => i._id == event.facility)
                this.tranFilters.facility = this.fData.facilities.tran[facilityIndex];
                localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
            }
            else {
                let providerIndex;
                if (this.category == 'tran') {
                    providerIndex = this.fData.providers.tran.findIndex(i => i._id == event.doctor_id_ref)
                }
                this.tranFilters.provider = this.fData.providers.tran[providerIndex];
                localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
                //@ts-ignore
                let pro = this.tranFilters.provider._id;
                this.facilityControl.disable();
                this.facilityControlDict.disable();
                const facilitiesResponse: any = await this._transcriptionCreateService
                    .getFacilities(pro).toPromise();
                if (facilitiesResponse.status === 200) {
                    this.fData.facilities.tran = facilitiesResponse.data.array;
                    this.facilityControl.enable();
                    this.facilityControlDict.enable();
                }
                let facilityIndex = this.fData.facilities.tran.findIndex(i => i._id == event.facility)
                this.tranFilters.facility = this.fData.facilities.tran[facilityIndex];
                localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
            }
            if (event.date) {
                end = event.date;
            }
            this.tranFilters.dos = end;
            localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
        }
        else {
            endDate = this.noteDateFilter.endDate;
            startDate = this.noteDateFilter.startDate;
        }
        this.loading = true;
        this.transcriptionsLoaded = false;
        console.log("applyCounterFilterTrans ", this.tranFilters.facility);
        console.log("2nd ", this.isIQAofProvider, this.isEQAofProvider);
        // if(!this.tranFilters){
        if (this.isIQAofProvider) {
            console.log("this is IQA");
            this.dashboard.getTeamNotesForTranscribers(
                this.tranFilters,
                'IQA',
                // end.format(global.moment_date_time_format),
                // start.format(global.moment_date_time_format),
                endDate,
                startDate
            )
                .subscribe(
                    (response: any) => {
                        this.transcriptions = response;
                        this.transCountData = response.countData;
                        global.cached.set('dashboard:transcription', response);
                        this.initDictationsAndTranscriptions();
                    },
                    error => {

                    },
                    () => {
                        this.loading = false;
                        this.transcriptionsLoaded = true;
                    }
                );
        } else if (this.isEQAofProvider) {
            console.log("this is EQA");

            this.dashboard.getTeamNotesForTranscribers(
                this.tranFilters,
                'EQA',
                // end.format(global.moment_date_time_format),
                // start.format(global.moment_date_time_format),
                endDate,
                startDate
            )
                .subscribe(
                    (response: any) => {
                        this.transcriptions = response;
                        this.transCountData = response.countData;
                        global.cached.set('dashboard:transcription', response);
                        this.initDictationsAndTranscriptions();
                    },
                    error => {

                    },
                    () => {
                        this.loading = false;
                        this.transcriptionsLoaded = true;
                    }
                );
        } else {
            this.dashboard.getTeamNotes(
                this.tranFilters,
                endDate,
                startDate,
            )
                .subscribe(
                    (response: any) => {
                        this.transcriptions = response;
                        if (this.tranFilters.dos && this.tranFilters.dos != '') {
                            for (let i in response.data.array) {
                                if (moment(new Date(response.data.array[i].filterCreationDate).toLocaleString('en-US', { timeZone: response.data.array[i].timezone })).format('MMM DD, YYYY') != moment(new Date(this.tranFilters.dos).toLocaleString('en-US', { timeZone: response.data.array[i].timezone })).format('MMM DD, YYYY')) {
                                    response.data.array[i] = {}
                                }
                            }
                        }
                        this.transCountData = response.countData;
                        global.cached.set('dashboard:transcription', response);
                        this.initDictationsAndTranscriptions();
                    },
                    error => {

                    },
                    () => {
                        this.loading = false;
                        this.transcriptionsLoaded = true;
                    }
                );
        }
    }

    applyNoteFiltersforQAs(type?) {

        let elem = document.getElementById('inputIDFac');
        elem.blur();
        this.tranFilters.patient = null;
        localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
        this.fData.patients = [];
        let start = moment(this.noteDateFilter.startDate).toLocaleString();
        let end = moment(this.noteDateFilter.endDate).toLocaleString();
        this.loading = true;
        this.transcriptionsLoaded = false;

        this.dashboard.getTeamNotesForTranscribers(
            this.tranFilters,
            type,
            end,
            start
        )
            .subscribe(
                (response: any) => {
                    this.transcriptions = response;
                    this.transCountData = response.countData;
                    global.cached.set('dashboard:transcription', response);
                    this.initDictationsAndTranscriptions();
                },
                error => {

                },
                () => {
                    this.loading = false;
                    this.transcriptionsLoaded = true;
                }
            );

    }

    applyNoteFilters() {

        let elem = document.getElementById('inputIDFac');
        elem.blur();
        this.tranFilters.patient = null;
        localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
        this.fData.patients = [];
        let start = moment(this.noteDateFilter.startDate.toDate()).toLocaleString();
        let end = moment(this.noteDateFilter.endDate.toDate()).toLocaleString();
        this.loading = true;
        this.transcriptionsLoaded = false;
        console.log("applyNoteFilters : ", this.tranFilters);

        console.log("2nd ", this.isIQAofProvider, this.isEQAofProvider);
        // if(!this.tranFilters){
        if (this.isIQAofProvider) {
            console.log("this is IQA");
            this.dashboard.getTeamNotesForTranscribers(
                this.tranFilters,
                'IQA',
                // end.format(global.moment_date_time_format),
                // start.format(global.moment_date_time_format),
                end,
                start
            )
                .subscribe(
                    (response: any) => {
                        this.transcriptions = response;
                        this.transCountData = response.countData;
                        global.cached.set('dashboard:transcription', response);
                        this.initDictationsAndTranscriptions();
                    },
                    error => {

                    },
                    () => {
                        this.loading = false;
                        this.transcriptionsLoaded = true;
                    }
                );
        } else if (this.isEQAofProvider) {
            console.log("this is EQA");

            this.dashboard.getTeamNotesForTranscribers(
                this.tranFilters,
                'EQA',
                // end.format(global.moment_date_time_format),
                // start.format(global.moment_date_time_format),
                end,
                start
            )
                .subscribe(
                    (response: any) => {
                        this.transcriptions = response;
                        this.transCountData = response.countData;
                        global.cached.set('dashboard:transcription', response);
                        this.initDictationsAndTranscriptions();
                    },
                    error => {

                    },
                    () => {
                        this.loading = false;
                        this.transcriptionsLoaded = true;
                    }
                );
        } else {
            console.log("is provider itself ");

            if ((this.datas.user_type === global.USER_TYPE.DOCTOR || this.datas.user_type === global.USER_TYPE.SNF_WC_NURSE || this.datas.user_type === global.USER_TYPE.WOUND_NURSE || this.datas.user_type === global.USER_TYPE.VIEW_ONLY_USER) && this.tranFilters.facility == 'All') {
                this.tranFilters.facility = {
                    title: 'All',
                    source: 'dn'
                }
                localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
            }
            if (this.datas.user_type === global.USER_TYPE.WOUND_NURSE) {
                this.tranFilters.provider = "";
                localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
                this.allfacilities = [...this.fData.facilities.tran.array];
                if(this.tranFilters.facility.title === 'All') {
                    this.tranFilters.facilities = this.allfacilities;
                }else {
                    delete this.tranFilters.facilities;
                }
            }
            this.dashboard.getTeamNotes(
                this.tranFilters,
                end,
                start,
            )
                .subscribe(
                    (response: any) => {
                        this.transcriptions = response;
                        this.transCountData = response.countData;
                        global.cached.set('dashboard:transcription', response);
                        this.initDictationsAndTranscriptions();
                    },
                    error => {

                    },
                    () => {
                        this.loading = false;
                        this.transcriptionsLoaded = true;
                    }
                );
        }
    }

    applyNoteFiltersByPatient(event) {

        if (event.option.value.name === '--All--') {
            this.getPatientByFacility(this.tranFilters.facility._id);
            this.applyNoteFilters();
        }
        else {
            let elem = document.getElementById('inputIDPatient');
            elem.blur();
            // this.tranFilters.patient = null;
            localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
            this.fData.patients = [];
            let start = moment(this.noteDateFilter.startDate).toLocaleString();
            let end = moment(this.noteDateFilter.endDate).toLocaleString();
            this.loading = true;
            this.transcriptionsLoaded = false;
            console.log("applyNoteFiltersByPatient : ");
            if (this.isIQAofProvider) {
                console.log("this is IQA");
                this.dashboard.getTeamNotesForTranscribers(
                    this.tranFilters,
                    'IQA',
                    // end.format(global.moment_date_time_format),
                    // start.format(global.moment_date_time_format),
                    end,
                    start,
                    this.tranFilters.patient
                )
                    .subscribe(
                        (response: any) => {
                            this.transcriptions = response;
                            this.transCountData = response.countData;
                            global.cached.set('dashboard:transcription', response);
                            this.initDictationsAndTranscriptions();
                        },
                        error => {

                        },
                        () => {
                            this.loading = false;
                            this.transcriptionsLoaded = true;
                        }
                    );
            } else if (this.isEQAofProvider) {
                console.log("this is EQA");

                this.dashboard.getTeamNotesForTranscribers(
                    this.tranFilters,
                    'EQA',
                    // end.format(global.moment_date_time_format),
                    // start.format(global.moment_date_time_format),
                    end,
                    start,
                    this.tranFilters.patient
                )
                    .subscribe(
                        (response: any) => {
                            this.transcriptions = response;
                            this.transCountData = response.countData;
                            global.cached.set('dashboard:transcription', response);
                            this.initDictationsAndTranscriptions();
                        },
                        error => {

                        },
                        () => {
                            this.loading = false;
                            this.transcriptionsLoaded = true;
                        }
                    );
            } else {
                this.dashboard.getTeamNotes(
                    this.tranFilters,
                    end,
                    start,
                    this.tranFilters.patient
                )
                    .subscribe(
                        (response: any) => {
                            this.transcriptions = response;
                            this.transCountData = response.countData;
                            global.cached.set('dashboard:transcription', response);
                            this.initDictationsAndTranscriptions();
                        },
                        error => {

                        },
                        () => {
                            this.loading = false;
                            this.transcriptionsLoaded = true;
                        }
                    );
            }
        }
    }

    applyDictationFilters(facility = null) {
        let elem = document.getElementById('inputIDFac');
        elem.blur();
        let start =  this.dateFilter.startDate.format(global.moment_date_time_format).toLocaleString();
        let end = this.dateFilter.endDate.format(global.moment_date_time_format).toLocaleString();
        this.loading = true;
        this.dictationsLoaded = false;
        localStorage.setItem('dictFilters', JSON.stringify(this.dictFilters));
        this.dashboard.getAudiosinfo(
            // null,
            this.surpassedTAT,
            this.dictFilters,
            this.datas._id,
            this.datas.auth_key,
            this.datas.user_type,
            end,
            start,
        )
            .subscribe(
                (response: any) => {
                    this.recordings = response.data.array;
                    this.dictCountData = response.data.countData;
                    global.cached.set('dashboard:dictations', response);
                    this.initDictationsAndTranscriptions();
                },
                error => {

                },
                () => {
                    this.loading = false;
                    this.dictationsLoaded = true;
                }
            );
    }


    filterByPatient(event) {
        if (event === -1) {
            this.tranFilters.patient = null;
            localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
            this.fData.patients = [];
            this.applyCounterFilterTrans()
            // this.initDictationsAndTranscriptions();
            return;
        }
        this.tranFilters.patient = event.option.value;
        localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
        this.initDictationsAndTranscriptions();
        let patient_id = event.option.value._id;
        this.sortedTransriptions = this.sortedTransriptions
            .filter(t => t.patient_id === patient_id);
        if (this.sortedTransriptions) {
            this.groupedSortedTranscriptions = this.groupItemList(this.sortedTransriptions,
                this.transcriptionsGroupingKey);
        }
    }

    patientFilter(filterValue: string) {
        let patientName = filterValue.trim();
        // @ts-ignore
        const facilityID = this.tranFilters.facility._id;
        this.patientListService.getFacilityPatients(facilityID, patientName).subscribe((response: any) => {
            if (response.status == 200) {
                this.fData.patients = response.data.array;
            }
            else {

            }
        })
    }

    /**
     * end filters
     */

    ngOnDestroy() {
        // clearInterval(this.refreshDashboardIntervalId);
        this.dashboard.publishNotes(this.sortedTransriptions);
        const sortedAudios = [];
        for (const key in this.groupedSortedRecordings) {
            if (Object.prototype.hasOwnProperty.call(this.groupedSortedRecordings, key)) {
                for (let audio of this.groupedSortedRecordings[key]) {
                    sortedAudios.push(audio);
                }
            }
        }
        this.dashboard.publishAudios(sortedAudios);
        if (this.notification_response)
            this.notification_response.unsubscribe();
        // 
    }

    // ngAfterViewInit() {

    //     this.refreshDashboardEvent.emit();

    //     // this.refreshDashboardIntervalId = setInterval(() => {
    //     //   this.refreshDashboardEvent.emit();
    //     // }, 1 * 60 * 1000); // min * sec * milisec
    // }

    /*
    * Initializing previous selected category via query parameters #mohsin-dev
    * For maintaining back button browser history
    */

    // ngDoCheck() {

    //     if (!this.datas) {
    //         // if not login, stop and redirect to login #mohsin-dev
    //         localStorage.remove('currentUser');
    //         this.router.navigate(['/login']);
    //         return;
    //     }
    //     // this was causing dictation do not show up when using back button from anoherpage #mohsin-dev

    //     // check if Transactions Response is Done
    //     // const categoryParam = this._route.snapshot.paramMap.get('category');
    //     // if(categoryParam) this.category = categoryParam;
    //     // const statusParam = this._route.snapshot.paramMap.get('status');
    //     // if(statusParam) this.status = statusParam;
    //     // this.initDictationsAndTranscriptions(this.category, this.status);
    //     if (!this.responseUpdateDone && this.transcriptions && this.recordings) {
    //         this._route.paramMap.subscribe((params: ParamMap) => {
    //             // Changed logic to fix initialization bugs #mohsin-dev
    //             const categoryParam = params.get('category');
    //             if (categoryParam) {
    //                 this.category = categoryParam;
    //             }
    //             const statusParam = params.get('status');
    //             if (statusParam) {
    //                 this.status = statusParam;
    //             }
    //             this.initDictationsAndTranscriptions();

    //             this.refreshDashboardEvent.emit();
    //         });
    //         // transactionsResponseDone  mark true
    //         this.responseUpdateDone = true;
    //     }
    // }

    /**
     * fixed all dictations not loading when using back button from another page #mohsin-dev
     * by using callback to assign this.recordings if empty
     */
    getAudiosinfo(callback = null) {
        this.dashboard.getAudiosinfo(
            // null,
            this.surpassedTAT,
            this.dictFilters,
            this.datas._id,
            this.datas.auth_key,
            this.datas.user_type).subscribe(async (data: any) => {
                if (data.status === 200) {

                    this.datasource = data;
                    this.dictCountData = data.data.countData;
                    // if(this.datasource.status !== 200) {this.toastr.error(this.datasource.message, "Failed"); return;}; // Show error message when response is not 200/success
                    this.datasource.data.array = this.sortAudioArrayDateDescending(this.datasource.data.array);
                    this.recordings = this.datasource.data.array;
                    // append uploaded_by_full_name to all recordings #mohsin-dev

                    for (let recording of this.recordings) {

                        // const uploaded_by_response:any = await this.dashboard.getUserDetail(recording.uploaded_by_id).toPromise()  ;
                        // if(uploaded_by_response.status === 200) {
                        //   const uploaded_by = uploaded_by_response.data.array;
                        //   recording.uploaded_by_full_name = `${uploaded_by.first_name} ${uploaded_by.last_name}`;
                        // }
                        let date = recording.date_time;
                        recording.date = date;
                        if (recording.progress_status === '18') {
                            recording.status = 'PENDING';
                        }
                        if (recording.progress_status === '19') {
                            recording.status = 'IN PROGRESS';
                        }
                        if (recording.progress_status === '20') {
                            recording.status = 'COMPLETED';
                        }
                        if (recording.progress_status === '21') {
                            recording.status = 'DRAFT';
                        }
                        if (recording.progress_status === '23') {
                            recording.status = 'TRASHED';
                        }
                    }

                    if (typeof callback === 'function') {
                        callback();
                    }
                } else {
                    // throw Error(data.message)
                }

            });
    }

    async getAudiosinfoByDate(from_date, to_date?) {
            const response: any = await this.dashboard.getAudiosinfo(
            // null,
            this.surpassedTAT,
            this.dictFilters,
            this.datas._id,
            this.datas.auth_key,
            this.datas.user_type,
            from_date,
            to_date
        ).toPromise();

        if (response.status === 200) {

            if (response.data.uploading_date) {
                this.recordingsFetchDate = new Date(response.data.uploading_date);
            }

            const recordings = response.data.array;
            this.dictCountData = response.data.countData;
            for (let recording of recordings) {
                if (recording?.date_time) {
                    let date = recording?.date_time;
                    recording.date = date;
                    if (recording.progress_status === '18') {
                        recording.status = 'PENDING';
                    }
                    if (recording.progress_status === '19') {
                        recording.status = 'IN PROGRESS';
                    }
                    if (recording.progress_status === '20') {
                        recording.status = 'COMPLETED';
                    }
                    if (recording.progress_status === '21') {
                        recording.status = 'DRAFT';
                    }
                    if (recording.progress_status === '23') {
                        recording.status = 'TRASHED';
                    }
                }
            }
            return recordings;
        }
    }

    async loadMoreRecordings() {

        this.loadMoreInprogress = true; // show/hide load more button when in progress

        this.recordingsFetchDate = moment(new Date(this.recordingsFetchDate)).subtract('days', 1).toDate();
        global.cached.set('dashboard:recordingsFetchDate', this.recordingsFetchDate);

        const formattedDate = this.datepipe.transform(this.recordingsFetchDate, global.date_format);
        const previousDaysRecordings = await this.getAudiosinfoByDate(formattedDate, this.dictFilters);
        // if (previousDaysRecordings.length === 0) {
        //     previousDaysRecordings.push(
        //         {
        //             uploading_date: this.datepipe.transform(this.recordingsFetchDate, global.date_format),
        //             date_time: this.datepipe.transform(this.recordingsFetchDate, global.date_format),
        //             empty: true,
        //             is_trashed: 'false'
        //         }
        //     );
        // }
        this.recordings = [...this.recordings, ...previousDaysRecordings];
        global.cached.set('dashboard:recordings', this.recordings);
        this.initDictationsAndTranscriptions();
        // this.virtualScroller.scrollToIndex(Object.keys(this.groupedSortedRecordings).length-1, true, 166);
        // this.virtualScroller.scrollToPosition(document.querySelector('.right-col').scrollHeight);
        this.scrollDictationsToBottom();

        this.loadMoreInprogress = false;
    }

    scrollDictationsToBottom() {
        setTimeout(() => {
            const rightCol = document.querySelector('.right-col');
            rightCol.scrollTop = rightCol.scrollHeight + 200;
        });
    }

    sortAudioArrayDateDescending(array) {
        if (typeof array != 'undefined') {
            if(array.length > 0){
                array.sort(function (a, b) {
                    return parseInt(b?.audio_file_name) - parseInt(a?.audio_file_name);
                });
            }
        } else {
            array = [];
        }

        return array;
    }

    refreshDashboard() {
        // ACTIVITY LOG
        this.showReportsContainer = false;
        this._socketService.addActivityLog({
            id: this.datas._id,
            screen: 'Dashboard',
            operation: `Reload Dashboard Click API Call`,
            datetime: this.datepipe.transform(new Date(), global.date_time_format)
        });
        this.refreshDashboardEvent.emit();
    }

    groupRecordingsBy(group_index) {
        this.recordingsGroupingKey = group_index;
        this.groupedSortedRecordings = this.groupItemList(this.sortedRecordings, group_index);
    }

    groupTranscriptionsBy(group_index) {
        this.transcriptionsGroupingKey = group_index;

        this.groupedSortedTranscriptions = this.groupItemList(this.sortedTransriptions, group_index);
    }

    // renamed function name from recordingStatus to initDictationsAndTranscriptions #mohsin-dev
    initDictationsAndTranscriptions = (updated = null) => {
        if (!this.transcriptions && !this.recordings) {
            return;
        } // added to fix bug #mohsin-dev
        // this.onChangeStatusTrans(this.tranFilters.note_status);
        if (isObject(updated)) {
            const { transcriptions = null, audios = null } = updated;

            // if (isArray(this.sortedTransriptions) && isArray(transcriptions)) {
            //     for (let updatedT of transcriptions) {
            //         this.sortedTransriptions = this.sortedTransriptions.map(t => (updatedT?._id === t?._id ? updatedT : t));
            //         this.transcriptions.data.array = this.transcriptions.data.array.map(t => (updatedT?._id === t?._id ? updatedT : t));
            //     }
            //     global.cached.set('dashboard:transcriptions', this.transcriptions);
            // }
            // if (isArray(this.sortedRecordings) && isArray(audios)) {
            //     for (let updatedA of audios) {
            //         this.sortedRecordings = this.sortedRecordings.map(a => (updatedA?._id === a?._id ? updatedA : a));
            //         this.recordings = this.recordings.map(a => (updatedA?._id === a?._id ? updatedA : a));
            //     }
            //     global.cached.set('dashboard:recordings', this.recordings);
            // }
        }
        // this.recordingStatusQuery = this.status;
        if (this.category === 'tran') {
            if ((!this.transcriptions || !this.transcriptions.data) || !isArray(this.recordings)) {
                return;
            }
            this.sortedTransriptions = this.transcriptions.data.array;
            let tranFilter = JSON.parse(localStorage.getItem('tranFilters'));
            this.status = tranFilter.progress_status;
            // this.sortedTransriptions = this.transcriptions.data.array.filter(this.statusFilterTranscriptions());
            // if (this.datas.user_type == 1) {
            //     this.sortedTransriptions = this.sortedTransriptions.filter(t => t.is_published == 'true');
            // }


            this.sortedTransriptions.sort((a, b) => {
                // const dateA = a.date ? a.date : a.date_of_service;
                // const dateB = b.date ? b.date : b.date_of_service;
                return new Date(a.date_of_service).getTime() - new Date(a.date_of_service).getTime();
            });
            this.isFilterVisible = this.sortedTransriptions.length > 0;

        } else if (this.category === 'dict') {
            // Proper filtering dictations #mohsin-dev
            switch (this.status) {
                case 'In_Progress':
                    this.sortedRecordings = this.recordings.filter((r: any) => (r?.status === 'IN PROGRESS' && r?.is_trashed === 'false') || r?.empty);
                    break;
                case 'Completed':
                    this.sortedRecordings = this.recordings.filter((r: any) => (r?.status === 'COMPLETED' && r?.is_trashed === 'false') || r?.empty);
                    break;
                case 'Trash':
                    this.sortedRecordings = this.recordings.filter((r: any) => r?.is_trashed === 'true');
                    break;

                default:
                    this.sortedRecordings = this.recordings.filter((r: any) => r?.is_trashed === 'false');
                    this.isFilterVisible = this.sortedRecordings.length > 0;
            }
        }

        this.applyFilter();

        if (this.sortedTransriptions) {
            this.groupedSortedTranscriptions = this.groupItemList(this.sortedTransriptions, this.transcriptionsGroupingKey);
            // 
        }

        // const inProgressDictations = this.recordings.filter(t => t.status === 'IN PROGRESS' && t.is_trashed === 'false' && t.empty != true);
        // if (inProgressDictations) {
        //     this.countData.inProgressDictations = inProgressDictations.length;
        //     global.cached.set('dashboard:countData', this.countData);
        // }
        if (this.sortedRecordings) {
            this.groupedSortedRecordings = this.slicePaginatedGroupsItemsOnly(this.sortedRecordings.slice().reverse(), this.recordingsGroupingKey);
            // this.scrollDictationsToBottom();
            // 
        }
    };

    slicePaginatedGroupsItemsOnly(items, groupByIndex) {
        const tempGroups = this.groupItemList(items, groupByIndex);

        this.pagination.last_page_no = Math.ceil(items.length / this.pagination.per_page);
        // validate & sanitize current page no
        if (this.pagination.current_page_no < 1 || (!isNaN(this.pagination.last_page_no) && this.pagination.current_page_no > this.pagination.last_page_no)) {
            this.pagination.current_page_no = 1;
            global.cached.set('dashboard:dictation:current_page_no', 1);
        }

        if (items.length <= this.pagination.per_page) {
            // No need of slicing because items are under per page limit
            return tempGroups;
        }

        //  const arr = this._commonService.flattenObjectToArray(tempGroups);
        //  const slicedArr = this.slicePaginatedItemsOnly(arr);
        //  const slicedGroups = this.groupItemList(slicedArr, groupByIndex);
        //  return slicedGroups;
        const tempItems = [];
        const reversedKeys = Object.keys(tempGroups);
        reversedKeys.reverse();
        reversedKeys.forEach((key) => {
            const groupItems = tempGroups[key];

            for (let i = 0; i < groupItems.length; i++) {
                tempItems.push(groupItems[i]);
            }
        });
        const slicedItems = this.slicePaginatedItemsOnly(tempItems);
        const slicedGroups = this.groupItemList(slicedItems, groupByIndex);
        return slicedGroups;
    }

    slicePaginatedItemsOnly(items: Array<any>) {
        const begin = (this.pagination.current_page_no - 1) * this.pagination.per_page;
        const end = begin + this.pagination.per_page;

        const slicedArr = items.slice(begin, end);
        return slicedArr;
    }

    async dictationPageNavigate(nextPrev: number) {
        if (nextPrev === 0) return;
        let uploading_date, end_uploading_date;
        if (nextPrev > 0) {
            if (moment().startOf('day').isSame(moment(this.recordingsFetchDate).startOf('day'))) return;
            uploading_date = moment(this.recordingsFetchDate).startOf('day').add(this.paginationDaysRange, 'days');
        } else {
            uploading_date = moment(this.recordingsFetchDate).subtract('day').subtract(this.paginationDaysRange, 'days');
        }
        end_uploading_date = moment(uploading_date).subtract(this.paginationDaysRange, 'days');

        this.recordingsFetchDate = uploading_date;

        this.dictationsLoaded = false;
        const newRecordings = this.sortAudioArrayDateDescending(await this.getAudiosinfoByDate(
            uploading_date.format(global.moment_date_time_format).toLocaleString(),
            end_uploading_date.format(global.moment_date_time_format).toLocaleString())
        );

        this.recordings = newRecordings;
        this.initDictationsAndTranscriptions();
        this.dictationsLoaded = true;
    }

    async notePageNavigate(nextPrev: number) {

        if (nextPrev === 0) return;
        let creation_date, end_creation_date;
        if (nextPrev > 0) {
            if (moment().startOf('day').isSame(moment(this.transcriptionsFetchDate).startOf('day'))) return;
            creation_date = moment(this.transcriptionsFetchDate).startOf('day').add(this.paginationDaysRange, 'days');
        } else {
            creation_date = moment(this.transcriptionsFetchDate).subtract('day').subtract(this.paginationDaysRange, 'days');
        }
        end_creation_date = moment(creation_date).subtract(this.paginationDaysRange, 'days');
        this.transcriptionsFetchDate = creation_date;

        this.transcriptionsLoaded = false;
        console.log("notePageNavigate : ");
        this.transcriptions = await this.dashboard.getTeamNotes(null, creation_date.format(global.moment_date_time_format), end_creation_date.format(global.moment_date_time_format)).toPromise();
        //  if(this.transcriptions.data.array.length === 0) {
        //     this.transcriptions.data.array.push({
        //         creation_date: this.datepipe.transform(this.transcriptionsFetchDate, global.date_format),
        //         date_of_service: this.datepipe.transform(this.transcriptionsFetchDate, global.date_format),
        //         empty: true
        //     });
        // }
        this.transCountData = this.transcriptions.countData;
        this.initDictationsAndTranscriptions();
        this.transcriptionsLoaded = true;
    }

    isPaginationOnTodayDate(paginationDate) {
        return moment(paginationDate).startOf('day').isSame(moment().startOf('day'))
    }

    paginationEndDate(date) {
        return moment(date).startOf('day').subtract(this.paginationDaysRange, 'days').toDate()
    }

    paginationNavigate(nextPrev: number) {
        if (nextPrev === 0) {
            return;
        }

        if (nextPrev > 0) {
            // next
            this.pagination.current_page_no = this.pagination.current_page_no + 1;
        } else {
            // prev
            this.pagination.current_page_no = this.pagination.current_page_no - 1;
        }
        global.cached.set('dashboard:dictation:current_page_no', this.pagination.current_page_no);
        this.initDictationsAndTranscriptions();
    }

    statusFilterTranscriptions = () => {

        let statusFilter = item => item.is_active == 'true';

        if (this.status == 'Un_Signed') {
            // statusFilter = item => item.is_active == 'true' && item.is_signed != 'true';
            if (this.datas.user_type == global.USER_TYPE.DOCTOR || this.datas.user_type == global.USER_TYPE.SNF_WC_NURSE) {
                statusFilter = item => item.is_active == 'true' && item.is_published == 'true' && item.is_signed != 'true' && item.is_completed != 'true' || item.empty;
            } else {
                statusFilter = item => item.is_active == 'true' && item.is_signed != 'true' && item.is_completed != 'true' || item.empty;
            }
        } else if (this.status == 'Signed') {
            statusFilter = item => item.is_active == 'true' && item.is_signed == 'true' && item.is_completed != 'true' || item.empty;
        } else if (this.status == 'Completed') {
            statusFilter = item => item.is_active == 'true' && item.is_signed == 'true' && item.is_completed == 'true' || item.empty;
        }
        return statusFilter;
    }

    groupItemList(itemList, groupByIndex) {
        this.multiSelectedTranscriptions = [];
        let timezone = "America/New_York"
        let months = ['Jan', 'Feb', 'March']
        // date.toLocaleString('en-US', {month: 'short'});
        const grouped = {};
        // for (const i in itemList) {
        for (let row of itemList) {
            const item = { ...row };

            if (item.facility_id_ref && item.facility_id_ref.pcc_timeZone) {
                timezone = item.facility_id_ref.pcc_timeZone
            }
            else if (item.facility_id_ref && item.facility_id_ref.timeZone) {
                timezone = item.facility_id_ref.timeZone
            }

            let groupIndex = item[groupByIndex];

            if (groupByIndex.indexOf('date') >= 0) {
                if (groupByIndex == 'date_time') {

                    // if is date, format date and discard time
                    // groupIndex = this.datepipe.transform(item[groupByIndex], global.date_format);
                    //mohsin
                    // groupIndex = moment(new Date(item.filterServiceDate)).format('MMM DD, YYYY');
                    //ali
                    // groupIndex = moment(new Date(item.filterServiceDate).toLocaleString('en-US', { timeZone: item.timezone })).format('MMM DD, YYYY');
                    // groupIndex = moment(new Date(item.filterServiceDate)).format('MMM DD, YYYY');
                    if (item.date_obj && item.date_obj.year) {
                        let { year, month, day, hour, minute } = item.date_obj;
                        // if(month < 10){
                        //     month = '0' + month;
                        // }
                        // if(day < 10){
                        //     day = '0' + day;
                        // }
                        let date = new Date(`${month}\/${day}\/${year}`);
                        //month = date.toLocaleString('en-US', {month: 'short'});
                        groupIndex = moment(date).format('MMM DD, YYYY');
                        // groupIndex = `${month} ${day}, ${year}` ;
                    }
                    else {
                        groupIndex = moment(new Date(item.filterServiceDate).toLocaleString('en-US', { timeZone: timezone })).format('MMM DD, YYYY');

                    }

                }
                else if (groupByIndex == 'uploading_date') {

                    // if is date, format date and discard time
                    // groupIndex = this.datepipe.transform(item[groupByIndex], global.date_format);
                    //mohsin
                    // groupIndex = moment(new Date(item.filterUploadingDate)).format('MMM DD, YYYY');
                    //ali
                    // groupIndex = moment(new Date(item.filterUploadingDate).toLocaleString('en-US', { timeZone: item.timezone })).format('MMM DD, YYYY');
                    // groupIndex = moment(new Date(item.filterUploadingDate)).format('MMM DD, YYYY');
                    groupIndex = moment(new Date(item.filterUploadingDate).toLocaleString('en-US', { timeZone: timezone })).format('MMM DD, YYYY');

                }
                else if (groupByIndex == 'creation_date') {

                    // if is date, format date and discard time
                    // groupIndex = this.datepipe.transform(item[groupByIndex], global.date_format);
                    //mohsin
                    // groupIndex = moment(new Date(item.filterCreationDate)).format('MMM DD, YYYY');
                    //ali
                    groupIndex = moment(new Date(item.filterCreationDate).toLocaleString('en-US', { timeZone: timezone })).format('MMM DD, YYYY');
                    // groupIndex = moment(new Date(item.filterCreationDate)).format('MMM DD, YYYY');
                }
                else if (groupByIndex == 'date_of_service') {
                    // if is date, format date and discard time
                    // groupIndex = this.datepipe.transform(item[groupByIndex], global.date_format);
                    //mohsin
                    // groupIndex = moment(new Date(item.filterServiceDate)).format('MMM DD, YYYY');
                    // ali
                    if (item.date_obj && item.date_obj.year) {
                        let { year, month, day, hour, minute } = item.date_obj;
                        // if(month < 10){
                        //     month = '0' + month;
                        // }
                        // if(day < 10){
                        //     day = '0' + day;
                        // }
                        let date = new Date(`${month}\/${day}\/${year}`);
                        //month = date.toLocaleString('en-US', {month: 'short'});
                        groupIndex = moment(date).format('MMM DD, YYYY');
                        // groupIndex = `${month} ${day}, ${year}` ;
                    }
                    else {
                        groupIndex = moment(new Date(item.filterServiceDate).toLocaleString('en-US', { timeZone: timezone })).format('MMM DD, YYYY');

                    }

                    // groupIndex = moment(new Date(item.filterServiceDate)).format('MMM DD, YYYY');
                }
            } else {

                if (groupByIndex.indexOf('facility') >= 0) {
                    groupIndex = item.facility_id_ref?.title;
                }
                else if (groupByIndex.indexOf('patient') >= 0) {
                    groupIndex = item.patient_id_ref?.last_name + ', ' + item.patient_id_ref?.first_name;
                }
                else if (item.date_obj && item.date_obj.year) {
                    let { year, month, day, hour, minute } = item.date_obj;

                    let date = new Date(`${month}\/${day}\/${year}`);
                    groupIndex = moment(date).format('MMM DD, YYYY');
                }
                else {
                    groupIndex = moment(new Date(groupIndex).toLocaleString('en-US', { timeZone: timezone })).format('MMM DD, YYYY');

                }

            }



            // if invalid key make undefined
            if (!groupIndex || groupIndex == 'Invalid date') {
                groupIndex = 'undefined';
            }

            if (!Array.isArray(grouped[groupIndex])) {
                grouped[groupIndex] = [];
            }
            grouped[groupIndex].push(item);
        }
        // remove empty items if atleast one non empty item in a group
        for (let key in grouped) {
            // if one non empty found
            if (grouped[key].find(e => !e.empty)) {
                grouped[key] = grouped[key].filter(e => !e.empty);
            }
        }
        return grouped;
    }

    applyTranscriptionSearchQueryFilter(e) {
        const searchQuery = e.target.value;
        this.sortedTransriptions = this.transcriptions.data.array.filter(this.statusFilterTranscriptions()).filter((t) => {
            if (typeof searchQuery === 'string' && searchQuery.length > 0) {
                const cleanedTitle = this._commonService.cleanTranscriptionTitle(t.transcribtion_title);
                if (cleanedTitle.toLowerCase().indexOf(searchQuery.toLowerCase().trim()) !== -1) {
                    return true;
                } else {
                    return false;
                }
            }
            return true;
        });

        this.groupedSortedTranscriptions = this.groupItemList(this.sortedTransriptions, this.transcriptionsGroupingKey);
    }

    applyRecordingSearchQueryFilter(e) {
        const recordingSearchQuery = e.target.value;
        this.sortedRecordings = this.recordings.filter((recording) => {
            if (typeof recordingSearchQuery === 'string' && recordingSearchQuery.length > 0 && recording.audio_title) {
                if (recording.audio_title && recording.audio_title.toLowerCase().indexOf(recordingSearchQuery.toLowerCase().trim()) !== -1 && recording.is_trashed === 'false') {
                    return true;
                } else {
                    return false;
                }
            }
            return true;
        });

        this.groupedSortedRecordings = this.groupItemList(this.sortedRecordings, this.recordingsGroupingKey);
    }

    selectedAudio(recording) {
        if (this.datas) {
            localStorage.setItem('category', this.category);

            this.router.navigate(['/transcription-detail', { audio_id: recording._id }]);
            localStorage.setItem('CurrentRecording', JSON.stringify(recording));
        }
    }

    closeLeftCol() {
        this.leftColClose = true;
        this.leftColWidth = true;
    }

    openLeftCol() {
        this.leftColWidth = false;
        setTimeout(() => {
            this.leftColClose = false;
        }, 250);
    }

    startMultiDownloadDictations(downloadType) {

        // ACTIVITY LOG
        this._socketService.addActivityLog({
            id: this.datas._id,
            screen: 'Dashboard',
            operation: `Multiple ${downloadType} Download API Call`,
            datetime: this.datepipe.transform(new Date(), global.date_time_format)
        });

        this.multiDownloadDictationsInprogress = true;
        this.downloadProgress.show = true;
        this.downloadProgress.progress = 0;

        const fileName = `${this.multiSelectedDictations.length} dictation(s).zip`;
        this.dashboard.multiDownloadDictationsByIds(downloadType, this.multiSelectedDictations).subscribe((res: any) => {

            if (res instanceof HttpResponse) {
                this.multiDownloadDictationsInprogress = false;
                this.downloadProgress.show = false;
                // saveAs(res, fileName);
                var url = window.URL.createObjectURL(res.body);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
            } else {
                if (res.type === 0) {
                } else if (res.type === 3) {
                    const { loaded, total } = res;
                    const progress = Math.round(loaded / total * 100);
                    if (progress > 0) {
                        this.downloadProgress.mode = 'determinate';
                        this.downloadProgress.progress = progress;
                    }
                }
            }
            // if(data) {

            //   saveAs(data, fileName);
            // }
        }, () => {
            this.toastr.error('Something went wrong while downloading dictations', 'Failed');
        },
            () => {
                this.multiSelectedDictations = [];

                this.multiDownloadDictationsInprogress = false;
                this.downloadProgress.show = false;
                this.downloadProgress.progress = 0;
                // this.isMultiDownloadDictationsOn = false;

            });
    }

    download(downloadType, recording) {
        // fixed mp3/mp4 file naming to audio_file_name and dictation title #mohsin-dev
        const dateObj = new Date(recording.date);
        const date = `${dateObj.getDate()}-${dateObj.getMonth()}-${dateObj.getFullYear()}`;
        const file_name = (`${recording.audio_title}_${recording.uploader_name}_${recording.facility_title}_${date}`).replace(/\s/g, '_');

        let downloadResponse;
        let infoToastr: any;
        if (downloadType == 'mp4') {
            recording.file_name = file_name + '.mp4';
            infoToastr = this.toastr.info("Converting audio file...", "Please wait", {
                disableTimeOut: true
            });
            downloadResponse = this.dashboard
                .downloadFile(global.url + API_URL.ROUTES.downloadMP4File, { recording })
            // this.downloadFile(global.url + '/downloadMP4File',recording);
        } else {
            // Added toastr notification for file conversion #mohsin-dev
            infoToastr = this.toastr.info("Converting audio file...", "Please wait", {
                disableTimeOut: true
            });
            downloadResponse = this.dashboard
                .downloadFile(global.url + API_URL.ROUTES.convertMp4ToMp3Anddownload, { recording })

            // this.dashboardService.convertMp4ToMp3Anddownload(recording).subscribe(data => {
            //   this.toastr.clear(infoToastr.toastrId);
            //   recording.file_name = file_name + '.mp3';
            //     // this.downloadFile(global.url + '/downloadMP3File',recording);
            //   });
        }
        downloadResponse.subscribe((res: any) => {
            if (infoToastr) {
                this.toastr.clear(infoToastr.toastrId)
            }

            if (res instanceof HttpResponse) {
                // this.downloadProgress.show = false;
                localStorage.removeItem('downloadInProgress');
                // saveAs(res, fileName);
                var url = window.URL.createObjectURL(res.body);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                console.log(url)
                a.download = `${recording.audio_title}.${downloadType}`;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
            } else {
                if (res.type === 0) {
                } else if (res.type === 3) {
                    const { loaded, total } = res;
                    const progress = Math.round(loaded / total * 100);
                    // console.log(res.data.loaded);
                }
            }
        })
    }

    downloadFile(url, params) {
        const infoToastr: any = this.toastr.info('Preparing file download', 'Please wait...', {
            disableTimeOut: true
        });

        return this.http
            .get(url, {
                responseType: 'blob',
                params: {
                    ...params
                }
            }).pipe(
                map(res => {
                    return {
                        filename: params.file_name,
                        data: res
                    };
                }))
            .subscribe(res => {
                this.toastr.clear(infoToastr.toastrId);
                var url = window.URL.createObjectURL(res.data);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element

            }, error => {
            }, () => {
            });
    }

    /*
    * Delete Audio (dictation) #mohsin-dev
    */
    deleteAudio(recording, shouldDelete = true) {

        // ACTIVITY LOG
        this._socketService.addActivityLog({
            id: this.datas._id,
            screen: 'Dashboard',
            operation: `Delete Audio Status Change API Call`,
            datetime: this.datepipe.transform(new Date(), global.date_time_format)
        });
        this.dashboard.deleteAudio(recording._id, shouldDelete).subscribe((data: any) => {
            if (data.status === 200) {
                recording.is_trashed = shouldDelete ? 'true' : 'false';
                this.sortedRecordings = this.recordings.filter(r => r.is_trashed === recording.is_trashed);
                this.responseUpdateDone = false;
                this.toastr.success(data.message, 'Success');
            } else {
                this.toastr.error(data.message, 'Failed');
            }
        });
    }

    /**
     * onChange upload file event
     * Converts docx file to and redirects to create transcription page and initializes editor with converted text
     * #mohsin-dev
     */
    uploadFileAndCreateTranscription(event) {
        const userInfo = {
            id: this.datas._id,
            auth_key: this.datas.auth_key,
            userType: this.datas.user_type
        };
        const [file] = <Array<File>>event.target.files;
        var filename = file.name;
        const infoToast = this.toastr.info('Uploading Note...', 'Please wait', { disableTimeOut: true });
        this.fileConversionService
            .makeFileRequest(userInfo, global.url + API_URL.ROUTES.uploadFile, file, filename)
            .then((result: any) => {
                if (result.status == 200) {

                    localStorage.setItem('convertedHTML', result.data);
                    this.router.navigate(['/transcription-create', { category: this.category, new: 1 }]);
                } else {
                    console.error(result.message);
                    this.toastr.error(result.message, 'Failed');
                }
                this.toastr.clear(infoToast.toastId);
            }, console.error);
    }

    docxFile(detail) {
        // Added check if there is file uplaoded or not #mohsin-dev
        if (!detail.path) {
            return false;
        }

        var path = detail.path.split('.');
        if (path[path.length - 1] == 'docx') {
            return true;
        } else {
            return false;
        }
    }

    isEditable(transcription) {
        if (!transcription) {
            return false;
        }

        if (this.datas.user_type === global.USER_TYPE.DOCTOR || this.datas.user_type === global.USER_TYPE.SNF_WC_NURSE) {
            return true;
        } else if ((this.datas.user_type == 2 && transcription.is_published != 'true' && transcription.is_signed != 'true') || (this.datas.user_type == global.USER_TYPE.MEDICAL_ASSISTANT && transcription.is_published != 'true' && transcription.is_signed != 'true')) {
            return true;
        }
        return false;
    }

    selectedfile(file) {
        localStorage.setItem('selectedFile', JSON.stringify(file));
        const transcriptionFiles = this.sortedTransriptions;
        localStorage.setItem('transcriptionFiles', JSON.stringify(transcriptionFiles)); // for next and prev buttons

        localStorage.setItem('category', this.category);
        localStorage.setItem('backURL', '/dashboard');
    }

    initTranscriptionFiles() {
        const transcriptionFiles = this.transcriptions.data.array;
        localStorage.setItem('transcriptionFiles', JSON.stringify(transcriptionFiles)); // for next and prev buttons
    }

    newFileAddedEvent(data) {
    }

    downloadDocFiles(file) {
        return this.http
            .get(file.path, {
                responseType: 'blob'
            }).pipe(
                map(res => {
                    return {
                        filename: file.transcribtion_title,
                        data: res
                    };
                }))
            .subscribe(res => {
                var url = window.URL.createObjectURL(res.data);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
            }, error => {
            }, () => {
            });
    }

    /*
    * Unsign Transcription #mohsin-dev
    */
    async unSignTranscription(transcription, category) {
        transcription.is_signed = 'false';
        // transcription.is_published = 'false';
        transcription.transcribtion_id = transcription._id;
        transcription.id = this.datas._id;
        transcription.auth_key = this.datas.auth_key;
        transcription.usertype = this.datas.user_type;
        const toastInfo = this.toastr.info('Processing...', 'Please wait', { disableTimeOut: true });
        const response: any = await this._transcriptionCreateService.unSignTranscription(transcription, this.category).toPromise();
        if (response.status === 200) {
            this.transcriptions.data.array = this.transcriptions.data.array.map(t => t._id === transcription._id ? transcription : t);
            this.initDictationsAndTranscriptions();
        } else {
            this.toastr.error(response.message, 'Failed');
        }
        this.toastr.clear(toastInfo.toastId);
    }


    /*
    * Export as PDF #mohsin-dev
    */
    async downloadAsPDF(transcription) {
        /**
         * FLOW: Decrypt Transcript Text => Generate PDF => Get Doctor Name => Generate File Name => Download PDF File
         */
        const infoToastr = this.toastr.info('Generating PDF', 'Please wait...', { disableTimeOut: true });

        try {
            transcription.transcribtion_text = await this.EncryptDecryptService.decrypt(transcription.transcribtion_text).toPromise();

            const generatePDFResponse: any = await this.dashboard.generatePDF(transcription).toPromise();
            if (generatePDFResponse.status !== 200) {
                throw new Error(generatePDFResponse.message);
            }

            const doctorUserDetailsResponse: any = await this.dashboard.getUserDetail(transcription.doctor_id).toPromise();
            if (doctorUserDetailsResponse.status !== 200) {
                throw new Error(doctorUserDetailsResponse.message);
            }

            const doctor = doctorUserDetailsResponse.data.array;
            const doctorName = doctor.first_name + ' ' + doctor.last_name;
            const file_name = transcription.transcribtion_title + '_'
                + transcription.facility_id_ref.title + '_' + doctorName;
            this.downloadFile(global.url + '/downloadAsPDF', {
                file_name: file_name
            });
        } catch (error) {

            const { message = 'Something went wrong, please try again' } = error;
            this.toastr.error(message);
        } finally {
            this.toastr.clear(infoToastr.toastId);
        }
    }

    async deleteTranscription(transcription, category) {
        if (!confirm('Are you sure you want to delete?')) {
            return;
        }


        transcription.is_active = 'false';
        transcription.transcribtion_id = transcription._id;
        transcription.id = this.datas._id;
        transcription.auth_key = this.datas.auth_key;
        transcription.usertype = this.datas.user_type;
        const toastInfo = this.toastr.info('Deleting...', 'Please wait', { disableTimeOut: true });
        const response: any = await this._transcriptionCreateService.deleteTranscription(transcription, category).toPromise();
        if (response.status === 200) {
            this.transcriptions.data.array = this.transcriptions.data.array.map(t => t._id === transcription._id ? transcription : t);
            this.initDictationsAndTranscriptions();
        } else {
            this.toastr.error(response.message, 'Failed');
        }
        this.toastr.clear(toastInfo.toastId);
    }

    /**
     *
     * @param date <Date>
     * @param dateKeyInObject <String>
     * @param itemList <Array>
     * Created generic function to filter out items (used in filtering dictations and transcriptions by date of service)
     * #mohsin-dev
     */
    filterItemsByDate(date, dateKeyInObject, itemList) {
        const filterDateTime = new Date(date);
        const filterDate = filterDateTime.getDate();
        const filterMonth = filterDateTime.getMonth() + 1;
        const filterYear = filterDateTime.getFullYear();

        itemList = itemList.filter(t => {
            const dateTime = new Date(t[dateKeyInObject]);
            const date = dateTime.getDate();
            const month = dateTime.getMonth() + 1;
            const year = dateTime.getFullYear();

            return date === filterDate && month === filterMonth && year === filterYear;
        });
        return itemList;
    }

    /**
     * @param date_of_service <String>, <Date>
     * Filter dications by date of service
     * #mohsin-dev
     */
    filterDictationsByDateOfService(date_of_service) {
        this.sortedRecordings = this.filterItemsByDate(date_of_service, 'date_time', this.sortedRecordings);
    }

    /**
     * @param facility <Facility>
     * Filter dications by facility id
     * #mohsin-dev
     */
    filterDictationsByFacility(facility_id) {

        this.sortedRecordings = this.sortedRecordings.filter(d => d.facility_id === facility_id);

    }

    /**
     * @param date_of_service <String>, <Date>
     * Filter transcriptions by date of service
     * #mohsin-dev
     */
    filterTranscriptionsByDateOfService(date_of_service) {
        this.sortedTransriptions = this.filterItemsByDate(date_of_service, 'date_of_service', this.sortedTransriptions);
    }

    /**
     * @param patient <Patient>
     * Filter transcriptions by patient id
     * #mohsin-dev
     */
    filterTranscriptionsByPatient(patient_id) {
        this.sortedTransriptions = this.sortedTransriptions.filter(t => t.patient_id === patient_id);
    }

    /**
     * @param provider <Patient>
     * Filter transcriptions by patient id
     * #mohsin-dev
     */
    filterTranscriptionsByProvider(provider) {
        this.sortedTransriptions = this.sortedTransriptions.filter(t => t.doctor_id === provider);
    }

    /**
     * @param provider <Provider>
     * Filter transcriptions by patient id
     * #mohsin-dev
     */
    filterDictationsByProvider(provider_id) {
        this.sortedRecordings = this.sortedRecordings.filter(t => t.uploaded_by_id === provider_id);
    }

    /**
     * @param provider <Provider>
     * Filter transcriptions by patient id
     * #mohsin-dev
     */
    filterDictationsByStatus(status_code) {
        this.sortedRecordings = this.sortedRecordings.filter(t => t.progress_status == status_code);
    }

    /**
     * @param patient <Patient>
     * Filter transcriptions by patient id
     * #mohsin-dev
     */
    filterTranscriptionsByFacility(facility_id) {
        this.sortedTransriptions = this.sortedTransriptions.filter(t => t.facility_id === facility_id);
    }

    openFilterDialog(): void {
        let data = { category: this.category };
        if (this.filterData) {
            data = { ...data, ...this.filterData };
        }
        const dialogRef = this.dialog.open(FilterDialog, {
            width: '500px',
            data
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            } else if (result.filter === 'cancel') {
                return;
            } else if (result.filter === 'reset') {
                this.filterData = null;
                this.initDictationsAndTranscriptions();
                return;
            } else if (!result.payload) {
                return;
            }

            // reset initialization first

            this.filterData = result;
            this.initDictationsAndTranscriptions();
        });
    }

    applyFilter() {
        if (this.filters.dos) {
            if (this.category === 'dict') {
                this.filterDictationsByDateOfService(this.filters.dos);
            } else {
                this.filterTranscriptionsByDateOfService(this.filters.dos);
            }
        }
        if (this.filters.facility) {
            if (this.category === 'dict') {
                this.filterDictationsByFacility(this.filters.facility);
            } else {
                this.filterTranscriptionsByFacility(this.filters.facility);
            }
        }
        if (this.filters.patient) {

            if (this.category === 'dict') {
                // this.filterDictationsByPatient(this.filterData.payload);
            } else {
                // @ts-ignore
                this.filterTranscriptionsByPatient(this.filters.patient._id);
            }
        }
        if (this.filters.provider) {

            if (this.category === 'dict') {
                // @ts-ignore
                this.filterDictationsByProvider(this.filters.provider._id);
            } else {
                // @ts-ignore
                this.filterTranscriptionsByProvider(this.filters.provider._id);
            }
        }
        if (this.filters.progress_status) {
            if (this.category === 'dict') {
                this.resetDictationMultiSelect();
                this.filterDictationsByStatus(this.filters.progress_status);
            } else {
            }
        }
        return;
        if (!this.filterData) {
            return;
        }
        switch (this.filterData.filter) {
            case 'date_of_service':
                if (this.category === 'dict') {
                    this.filterDictationsByDateOfService(this.filterData.payload);
                } else {
                    this.filterTranscriptionsByDateOfService(this.filterData.payload);
                }
                break;
            case 'facility':
                if (this.category === 'dict') {
                    this.filterDictationsByFacility(this.filterData.payload);
                } else {
                    this.filterTranscriptionsByFacility(this.filterData.payload);
                }
                break;
            case 'patient':
                if (this.category === 'dict') {
                    // this.filterDictationsByPatient(this.filterData.payload);
                } else {
                    const patient_id = this.filterData.payload._id;
                    this.filterTranscriptionsByPatient(patient_id);
                }
            case 'provider':
                if (this.category === 'dict') {
                    const provider_id = this.filterData.payload._id;
                    this.filterDictationsByProvider(provider_id);
                } else {
                    const provider_id = this.filterData.payload._id;
                    this.filterTranscriptionsByProvider(provider_id);
                }
                break;
            case 'progress_status':
                if (this.category === 'dict') {
                    this.resetDictationMultiSelect();
                    this.filterDictationsByStatus(this.filterData.payload);
                } else {
                }
                break;
            default:

        }
    }

    handleFilter(result) {
        this.filterDropdownActive = false;
        if (!result) {
            return;
        } else if (result.filter === 'cancel') {
            return;
        } else if (result.filter === 'reset') {
            this.filterData = null;
            this.initDictationsAndTranscriptions();
            return;
        } else if (!result.payload) {
            return;
        }

        // reset initialization first

        this.filterData = result;


        this.initDictationsAndTranscriptions();

    }

    filterChange(filters) {
        this.filters = filters;
        this.initDictationsAndTranscriptions();
        return;
        if (this.filters.dos) {
            if (this.category === 'dict') {
                this.filterDictationsByDateOfService(this.filters.dos);
            } else {
                this.filterTranscriptionsByDateOfService(this.filters.dos);
            }
        }
        if (this.filters.facility) {
            if (this.category === 'dict') {
                this.filterDictationsByFacility(this.filters.facility);
            } else {
                this.filterTranscriptionsByFacility(this.filters.facility);
            }
        }
        if (this.filters.patient) {
            if (this.category === 'dict') {
                // this.filterDictationsByPatient(this.filterData.payload);
            } else {
                const patient_id = this.filterData.payload._id;
                this.filterTranscriptionsByPatient(this.filters.patient);
            }
        }
        if (this.filters.provider) {
            if (this.category === 'dict') {
                const provider_id = this.filterData.payload._id;
                this.filterDictationsByProvider(this.filters.provider);
            } else {
                const provider_id = this.filterData.payload._id;
                this.filterTranscriptionsByProvider(this.filters.provider);
            }
        }
        if (this.filters.progress_status) {
            if (this.category === 'dict') {
                this.resetDictationMultiSelect();
                this.filterDictationsByStatus(this.filters.progress_status);
            } else {
            }
        }

    }

    async applyDateFilterDict(dates = null) {
        if (dates.startDate && dates.endDate && dates !== null) {
            this.dateFilter = { ...dates }
            localStorage.setItem('dateFilter', JSON.stringify(this.dateFilter));
            // }
            if (this.datas.user_type == '1') {
                this.dictFilters.provider = '';
                localStorage.setItem('dictFilters', JSON.stringify(this.dictFilters));
            }
            let dDate = JSON.parse(localStorage.getItem('dateFilter'));
            let start = this.dateFilter.startDate.format(global.moment_date_time_format).toLocaleString();
            let end = this.dateFilter.endDate.format(global.moment_date_time_format).toLocaleString();
            this.dictationsLoaded = false;
            if (!this.countFilter) {
                this.dictFilters.dos = '';
                localStorage.setItem('dictFilters', JSON.stringify(this.dictFilters));
                const newRecordings = this.sortAudioArrayDateDescending(await this.getAudiosinfoByDate(
                    end,
                    start)
                );
                this.recordings = newRecordings;
                this.initDictationsAndTranscriptions();
                this.dictationsLoaded = true;
            }
        }

    }
    async applyDateFilterTrans(dates) {
        this.noteDateFilter = { ...dates }
        if (dates.startDate && dates.endDate && dates !== null) {
            localStorage.setItem('noteDateFilter', JSON.stringify(this.noteDateFilter));
            if (!this.countFilter) {
                this.tranFilters.dos = '';
                localStorage.setItem('tranFilters', JSON.stringify(this.tranFilters));
                let start = this.noteDateFilter.startDate;
                let end = this.noteDateFilter.endDate;
                this.transcriptionsLoaded = false;
                console.log("applyDateFilterTrans : ");

                this.transcriptions = await this.dashboard
                    .getTeamNotes(
                        this.tranFilters,
                        // end.format(global.moment_date_time_format),
                        // start.format(global.moment_date_time_format),
                        end,
                        start
                    ).toPromise();
                this.transCountData = this.transcriptions.countData;
                this.initDictationsAndTranscriptions();
                this.transcriptionsLoaded = true;
            }

        }
    }

    async applyNoteDate(dates) {

        if (dates.startDate && dates.endDate && dates !== null) {
            localStorage.setItem('noteDateFilter', JSON.stringify(this.noteDateFilter));

            let start = moment(this.noteDateFilter.startDate);
            let end = moment(this.noteDateFilter.endDate);
            console.log("applyNoteDate")
            this.transcriptionsLoaded = false;
            this.transcriptions = await this.dashboard
                .getTeamNotes(
                    this.tranFilters,
                    // end.format(global.moment_date_time_format),
                    // start.format(global.moment_date_time_format),
                ).toPromise();
            this.transCountData = this.transcriptions.countData;
            this.initDictationsAndTranscriptions();
            this.transcriptionsLoaded = true;
        }
    }

    countTranscriptions(type) {
        if (this.transcriptions && this.transcriptions.data && this.transcriptions.data.array) {
            if (type === 'inbox') {
                let transcriptions = this.transcriptions.data.array.filter(t => t.is_active == 'true' && t.is_signed != 'true' && t.is_completed != 'true');
                if (this.datas.user_type == global.USER_TYPE.DOCTOR || this.datas.user_type == global.USER_TYPE.SNF_WC_NURSE) {
                    transcriptions = transcriptions.filter(t => t.is_published == 'true');
                }
                return transcriptions.length;
            }
            if (type === 'signed') {
                let transcriptions = this.transcriptions.data.array.filter(t => t.is_active == 'true' && t.is_signed == 'true' && t.is_completed != 'true');
                if (this.datas.user_type == global.USER_TYPE.DOCTOR || this.datas.user_type == global.USER_TYPE.SNF_WC_NURSE) {
                    transcriptions = transcriptions.filter(t => t.is_published == 'true');
                }
                return transcriptions.length;
            }
            if (type === 'uploaded') {
                let transcriptions = this.transcriptions.data.array.filter(t => t.is_active == 'true' && t.is_signed == 'true' && t.is_completed == 'true');
                if (this.datas.user_type == global.USER_TYPE.DOCTOR || this.datas.user_type == global.USER_TYPE.SNF_WC_NURSE) {
                    transcriptions = transcriptions.filter(t => t.is_published == 'true');
                }
                return transcriptions.length;
            }
        }
    }

    // countDictations(type) {
    //     if (this.sortedRecordings) {
    //         if (type === 'In_Progress') {
    //             let dications = this.sortedRecordings.filter(t => t.status === 'IN PROGRESS' && t.is_trashed === 'false' && t.empty != true);
    //             return dications.length;
    //         }
    //         return 0;
    //         // if (type === 'All'){
    //         //     let AllDications = this.recordings.filter(t => t.is_trashed === "false" && t.empty != true);
    //         //     return AllDications.length;
    //         // }
    //     }
    // }

    pageSubTitle() {
        const prefix = '> ';
        const subTitles = {
            'dict': {
                'All_Dictations': 'All Dictations',
                'In_Progress': 'In Progress',
                'Completed': 'Uploaded',
                'Trash': 'Trash'
            },
            'tran': {
                'Un_Signed': 'Unsigned Notes',
                'Signed': 'Signed Notes',
                'Completed': 'Uploaded Notes'
            }
        };
        return subTitles[this.category][this.status] ? prefix + subTitles[this.category][this.status] : '';
    }

    toggleDictationMultiDownloads(dictation: any) {
        const i = this.multiSelectedDictations.indexOf(dictation);
        if (i === -1) {
            this.multiSelectedDictations.push(dictation);
        } else {
            this.multiSelectedDictations.splice(i, 1);
        }
    }

    toggleTranscriptionMultiSelect(transcription: any) {
        console.log('toggleTranscriptionMultiSelect', transcription);
        const i = this.multiSelectedTranscriptions.indexOf(transcription);
        if (i === -1) {
            this.multiSelectedTranscriptions.push(transcription);
        } else {
            this.multiSelectedTranscriptions.splice(i, 1);
        }
    }
    async exportNotes() {

        const dialogRef = this.dialog.open(ExportNotesDialogComponent, { width: '40%' });
        dialogRef.afterClosed().subscribe(async result => {
            if (result.emails.length > 0) {
                this.toastr.info('Sending emails', 'Sending...');
                if (this.multiSelectedTranscriptions.length > 0) {
                    this.multiSelectedTranscriptions = this.multiSelectedTranscriptions.map(d => d?._id)

                }
                // @ts-ignore 
                this.noteEditorService.generatePDFsLinksAndEmail(result.emails, this.multiSelectedTranscriptions, this.tranFilters.provider?.name, this.tranFilters.facility?.title).subscribe((response: any) => {

                    //                this.noteEditorService.generatePDFsAndEmail(result.emails, this.multiSelectedTranscriptions, this.tranFilters.provider?.name, this.tranFilters.facility?.title).subscribe((response: any) => {
                    if (response.status == 200) {
                        console.log("in success case");

                        this.toastr.info('Emails sent');
                    }
                }), () => {
                    console.log("in error block");

                }, () => {
                    console.log("in final block");

                }

                this.multiSelectedTranscriptions = []
                this.multiSelectedTranscriptions.length = 0;
            }
        })

    }

    resetTranscriptionMultiSelect() {
        this.multiSelectedTranscriptions = [];
    }

    resetDictationMultiSelect() {
        this.multiSelectedDictations = [];
    }

    multiSelectChangeTranscriptionStatus(statusKey, statusValue) {
        const multiSelectedTranscriptions = this.multiSelectedTranscriptions.map(t => {
            t[statusKey] = statusValue;
            return t;
        });

        // iu message
        const action: any = {};
        switch (statusKey) {

            case 'is_signed':
                if (statusValue == 'true') {
                    action.thirdForm = 'Signed';
                    action.continousForm = 'Signing';
                } else {
                    action.thirdForm = 'Unsigned';
                    action.continousForm = 'Unsigning';
                }
                break;
            case 'is_completed':
                if (statusValue == 'true') {
                    action.thirdForm = 'Uploaded';
                    action.continousForm = 'Uploading';
                } else {
                    action.thirdForm = 'Reverted';
                    action.continousForm = 'Reverting';
                }
                break;
        }

        const infoToast = this.toastr.info(`${action.continousForm} ${multiSelectedTranscriptions.length} Notes...`, 'Please wait', { disableTimeOut: true });
        this.dashboard.multiSelectChangeTranscriptionStatus(statusKey, statusValue, multiSelectedTranscriptions).subscribe((response: any) => {
            if (response.status === 200) {
                if (statusKey === 'is_signed' && statusValue === 'true') {
                    // this.multiSelectChangeTranscriptionStatus('is_completed', 'true');
                }
                this.resetTranscriptionMultiSelect();
                // this.notesCounts();
                this.initDictationsAndTranscriptions({ transcriptions: multiSelectedTranscriptions });
                this.toastr.success(`${multiSelectedTranscriptions.length} ${multiSelectedTranscriptions.length > 1 ? 'Notes have' : 'Note has'} been ${action.thirdForm}`, 'Success');
            } else {
                this.toastr.error('Something went wrong while updating status', 'Failed');
            }
        }, console.error, () => {
            this.multiSelectedTranscriptions = [];
            this.toastr.clear(infoToast.toastId);
        });
    }

    selectAllTranscriptions(selectAll = true) {

        const nonEmptyTranscriptions = this.sortedTransriptions.filter(t => !t.empty);
        this.multiSelectedTranscriptions = selectAll ? [...nonEmptyTranscriptions] : [];
    }

    selectAllDictations(selectAll = true) {
        const nonEmptyDictations = this.sortedRecordings.filter(t => !t.empty);
        this.multiSelectedDictations = selectAll ? [...nonEmptyDictations] : [];
    }


    multiSelectChangeDictationStatus(status_code: string) {
        const multiDownloadDictations = this.multiSelectedDictations.map(d => {
            d.progress_status = String(status_code);
            return d;
        });


        const infoToast = this.toastr.info(`Changing dictation status...`, 'Please wait', { disableTimeOut: true });
        this.dashboard.multiSelectChangeDictationStatus(status_code, multiDownloadDictations).subscribe((response: any) => {
            if (response.status === 200) {
                this.resetDictationMultiSelect();
                this.initDictationsAndTranscriptions({ audios: multiDownloadDictations });
                this.toastr.success(`${multiDownloadDictations.length} ${multiDownloadDictations.length > 1 ? 'Dictations status have' : 'Dictation status has'} been updated`, 'Success');
            } else {
                this.toastr.error('Something went wrong while updating status', 'Failed');
            }
        }, console.error, () => {
            this.multiSelectedTranscriptions = [];
            this.toastr.clear(infoToast.toastId);
        });

    }

    isNotesLoading() {
        if (this.transcriptions && this.transcriptions.data && this.transcriptions.data.array) {
            return this.transcriptions.data.array.length === 0 && !this.transcriptionsLoaded;
        }
        return !this.transcriptionsLoaded;
    }

    isDictationsLoading() {
        console.log(this.dictationsLoaded);

        return this.dictationsLoaded
    }

    importPCCFacilities() {
        this.pccService.pccUserinfo$.subscribe(pccUserinfo => {
            if (pccUserinfo) {
                this.pccService.importPCCFacilities().subscribe((response: any) => {
                    if (response.status === 200) {
                        console.log('Facilities has been imported from pcc');
                    }
                })
            }
        })
    }

    addNewTicket() {
        const dialogRef = this.dialog.open(AddTicketsComponent, {
            height: '550px',
            width: '55%',
        });
        dialogRef.afterClosed().subscribe(async (data) => {
            console.log('out if data', data);
            if (data) {
                console.log('in if data', data);
            }
        });
    }

    async getCount() {
        let doctorId;
        if (this.providerFromCensus) {
            doctorId = this.providerFromCensus;
        } else {
            doctorId = this.tranFilters.provider._id;
        }
        let res: any = await this.billerDashboardService.getDraftedChargeCount(doctorId, this.filters).toPromise()
        if (res.status == 200) {
            this.chargesResponse = res;
            this.draftCount = res.draftCount;
        }
    }

    async getAddendumReqCount() {
        let doctorId;
        if (this.providerFromCensus) {
            doctorId = this.providerFromCensus;
        } else {
            doctorId = this.tranFilters.provider._id;
        }

        let ownerId = doctorId ? doctorId : this._authService.currentUser._id;
        let companyId = this._authService.currentUser.company_id;
        let filter = {
            company_id: companyId,
            owner_id: ownerId,
            bill_status: 'addendum_req',
            is_active: true
        }
        let res: any = await this.billerDashboardService.getAddendumReqCount(filter).toPromise()
        if (res.status == 200) {
            this.addendumReqCount = res.count;
        }
    }

    async getMissingFacesheets() {
        let ownerId;
        if (this.providerFromCensus) {
            ownerId = this.providerFromCensus;
            console.log('ownerIdownerIdownerId', ownerId);

        } else {
            ownerId = this.tranFilters.provider._id;
            // ownerId = this._authService.currentUser._id;
        }
        let companyId = this._authService.currentUser.company_id;
        let filter = {
            chargeType: "submit",
            dos: null,
            facesheetFilter: "upload",
            facility: "",
            patient: "",
            provider: ""
        }
        let res: any = await this.billerDashboardService.getMissingFacesheets(1, ownerId, companyId, filter).toPromise()
        if (res.status == 200) {
            this.missingFaceSheetsCount = res.totalCount;
        }
    }

    onChargesClick(status, facesheet, billStatus?) {
        this.showWoundContainer = false;
        this.showMipsContainer = false;
        this.showChargesContainer = false;
        this.showReportsContainer = false;
        this.prevMipsValue = '';
        setTimeout(() => {
            this.showChargesContainer = true;
        }, 10);
        this.chargeStatus = status;
        this.faceSheetStatus = facesheet;
        this.billStatus = billStatus;
    }
    onWoundClick(filter) {
        this.qapiReportFilter = filter;
        this.showWoundContainer = false;
        this.showMipsContainer = false;
        this.prevMipsValue = '';
        this.showReportsContainer = false;
        this.showChargesContainer = false;
        setTimeout(() => {
            this.showWoundContainer = true;
        }, 10);
    }
    async initFacilities() {

        // const item:any = {
        //   id: this.currentUser._id,
        //   auth_key: this.currentUser.auth_key,
        //   usertype: this.currentUser.user_type
        // }
        if (this.datas.user_type === global.USER_TYPE.DOCTOR) {
            const facilitiesResponse: any = await this._transcriptionCreateService.getFacilities(this.datas._id).toPromise();
            if (facilitiesResponse.status === 200) {
                this.facilities = facilitiesResponse.data.array;

            }
        } else if (this.datas.user_type == 2 || this.datas.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
            const associatedFacilityOfTranscriberResponse: any = await this.dashboard.getAssociatedFacilityOfTranscriber().toPromise();
            if (associatedFacilityOfTranscriberResponse.status === 200) {

                this.facilities = associatedFacilityOfTranscriberResponse.data.array;
            }
        }
        else if (this.datas.user_type === global.USER_TYPE.SNF_WC_NURSE || this.datas.company_type === global.COMPANY_TYPE.SNF) {
            const filter = {
                associated_snf_wc_nurse_ids: this.datas.user_type === global.USER_TYPE.SNF_WC_NURSE ? this.datas._id : this.tranFilters.provider._id,
                company_id: this._authService.currentUser.company_id
            };
            const nurseProjection = {
                first_name: 1,
                last_name: 1,
                title: 1,
            };
            const facilityProjection = {
                title: 1,
                pcc_facId: 1,
                pcc_2_legged_authentication: 1,
                source: 1,
                pcc_orgUuid: 1
            };
            const facilitiesResponse: any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
            if (facilitiesResponse.status === 200) {
                this.facilities = facilitiesResponse.data;
            }
        }

    }

    async getCompanyPermissions(filter, projection) {
        const response: ICompanyPermissionResponse = await lastValueFrom(this.companyService.getCompany(filter, projection));
        if (response.status == 200) {
            this.shouldShowEncounter = response.data.should_show_encounter;
        }
    }
    
    async getQapiReportCount(facilityid) {
        let creationDate = {
            startDate: moment().subtract(27, 'days'),
            endDate: moment()
        };
        let facilityId = facilityid;
        let facilities = [];
        if (this.facilities?.length) {
            facilities = this.facilities.map((facility) => facility._id);
            facilities = facilities.filter((f) => f !== null);
        } else {
            facilities = null
        }
        let responseP: any = await this.woundService.getQapiReportCount(creationDate, facilityId, null, 'progress', facilities).toPromise();
        if (responseP.status == 200) {
            this.improvingCount = responseP.qapiReportCount.improving;
            this.stalledCount = responseP.qapiReportCount.stalled;
            this.deterioratingCount = responseP.qapiReportCount.deteriorating;
        }
        let responseA: any = await this.woundService.getQapiReportCount(creationDate, facilityId, null, 'amputation', facilities).toPromise();
        if (responseA.status == 200) {
            this.amputationCount = responseA.qapiReportCount.amputation
        }
        let responseR: any = await this.woundService.getQapiReportCount(creationDate, facilityId, null, 'remission', facilities).toPromise();
        if (responseR.status == 200) {
            this.remissionCount = responseR.qapiReportCount.remission;
        }
    }
    handleCountChanges(data) {
        if (data) {
            if (data.type == 'improving') {
                this.improvingCount = data.count
            } else if (data.type == 'stalled') {
                this.stalledCount = data.count
            } else if (data.type == 'deteriorating') {
                this.deterioratingCount = data.count
            } else if (data.type == 'remission') {
                this.remissionCount = data.count
            } else if (data.type == 'amputation') {
                this.amputationCount = data.count
            }
        }
    }
    disableChargesContainer(status) {
        this.showWoundContainer = false;
        this.showChargesContainer = false;
        this.showReportsContainer = false;
        this.prevMipsValue = '';
        this.chargeStatus = status;
    }
    onReportClick(route) {
        this.reportView = route || '';
        console.log("reportView : ", route, this.reportView);
        this.showWoundContainer = false;
        this.prevMipsValue = '';
        this.showChargesContainer = false;
        this.showReportsContainer = true;
        this.showMipsContainer = false;
        // setTimeout(() => {
        //     this.showReportsContainer = true;
        // }, 10);
    }
    deleteMultipleUnsignedNotes() {
        let dialogRef = this.dialog.open(DialogConfirmationComponent, {
            data: {
                message: 'Are you sure you want to delete these Progress Notes?'
            }
        });
        let confirmed = "false";
        dialogRef.afterClosed().subscribe(result => {
            if (result === "true") {
                this.dashboard.deleteMultipleUnsignedNotes(this.multiSelectedTranscriptions.map(a => a._id)).subscribe((res: any) => {
                    if (res.status == 200) {
                        this.refreshDashboard();
                        this.toastr.success('Note removed Successfully', 'Success');
                        // this.tranFilters.progress_status = false;
                    }
                });
            }
        }),
            () => this.toastr.error('Something went wrong while removing Notes', 'Failed')
    }
    async deleteMultipleTrashedNotes() {
        const message = "Are you sure you want to delete this Note Permanently ?"
        const dialogBoxValue = await this.dashboard.DeleteConfirmationDialog(message);
        if (dialogBoxValue === 'true') {
            this.dashboard.deleteMultipleTrashedNotes(this.multiSelectedTranscriptions.map(a => a._id)).subscribe((res: any) => {
                if (res.status == 200) {
                    this.refreshDashboard();
                    this.toastr.success('Note removed Successfully', 'Success');
                }
            });
        } else {
            () => this.toastr.error('Something went wrong while removing Notes', 'Failed')
        }
    }
}


export interface FilterDialogData {
    category: string,
    filter: string,
    payload: any
}


@Component({
    selector: 'filter-dialog',
    templateUrl: './filter-dialog.html',
    styleUrls: ['./dashboard.component.css']
})
export class FilterDialog implements OnInit, DoCheck {
    filter: string = '';
    payload: any = null;

    patients: Array<any> = [];
    filteredPatients: Array<any> = [];

    facilities: Array<any> = [];

    loading: boolean = false;
    currentDate = new Date();
    currentUser;
    constructor(
        public dialogRef: MatDialogRef<FilterDialog>,
        private _dashboardService: DashboardService,
        private dialog: MatDialog,
        private _transcriptionCreateService: TranscriptionCreateService,
        private _patientListService: PatientListService,
        private _authService: AuthService,
        private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
        @Inject(MAT_DIALOG_DATA) public data: FilterDialogData,
        ) {
            if (data.filter && data.payload) {
                this.filter = data.filter;
                this.payload = data.payload;
            }
            this.currentUser = this._authService.currentUser;
        }

    onCancel(): void {
        this.dialogRef.close();
    }
    async ngOnInit() {

        this.loading = true;

        await this.initFacilities();
        await this.initPatients();

        if (this.filter === 'patient') {
            if (this.payload) {
                setTimeout(() =>
                    this.payload = this.getPatientName(this.patients.find(p => p._id === this.payload)));
            }
        }

        this.loading = false;
    }

    ngDoCheck() {


        if (this.filter === 'date_of_service' && (!this.payload || (this.payload && !this.payload.getTime))) {
            this.payload = new Date(); // default/initial value for date of service in dialog
        }
    }

    async initPatients() {
        const item: any = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type
        };
        if (this.currentUser.user_type === global.USER_TYPE.DOCTOR || this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
            await this.getPatientsForDoctor(item);
        } else if (this.currentUser.user_type == 2 || this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
            await this.getPateintsForTranscriber(item);
        }

    }

    async initFacilities() {

        // const item:any = {
        //   id: this.currentUser._id,
        //   auth_key: this.currentUser.auth_key,
        //   usertype: this.currentUser.user_type
        // }
        if (this.currentUser.user_type == global.USER_TYPE.DOCTOR) {
            const facilitiesResponse: any = await this._transcriptionCreateService.getFacilities(this.currentUser._id).toPromise();
            if (facilitiesResponse.status === 200) {
                this.facilities = facilitiesResponse.data.array;

            }
        } else if (this.currentUser.user_type == 2 || this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
            const associatedFacilityOfTranscriberResponse: any = await this._dashboardService.getAssociatedFacilityOfTranscriber().toPromise();
            if (associatedFacilityOfTranscriberResponse.status === 200) {

                this.facilities = associatedFacilityOfTranscriberResponse.data.array;
            }
        }

    }


    async getPatientsForDoctor(item) {
        return new Promise((resolve, reject) => {
            // DOCTOR 1
            // const infoToast = this._toastr.info("Fetching patients...", "Please wait");
            this._patientListService.getPatients(item).subscribe((response: any) => {
                if (response.status === 200) {
                    this.patients = response.data.array
                        .filter(patient => patient.is_active === 'true')
                        .map(patient => ({
                            patient_id: patient._id,
                            name: `${patient.first_name} ${patient.middle_name} ${patient.last_name}`,
                            ...patient
                        })
                        );
                    this.filteredPatients = this.patients;
                    resolve(this.patients);
                }
            }, (error) => {
                // console.error(error);
                reject(error);
                // this._toastr.clear(infoToast.toastId);
            },
                () => {
                    // this._toastr.clear(infoToast.toastId);
                }
            );

        });
    }

    async getPateintsForTranscriber(item) {
        return new Promise((resolve, reject) => {

            // TANSCRIBER 2
            // const infoToast = this._toastr.info("Fetching patients...", "Please wait");
            this._transcriptionCreateService.getAssociatedDoctorsList(this._authService.currentUser.company_id).subscribe((associatedDoctorsListResponse: any) => {
                if (associatedDoctorsListResponse.status === 200) {
                    // const associatedDoctorsList = associatedDoctorsListResponse.data.array; // array
                    const associatedDoctorsList = associatedDoctorsListResponse.data; // array
                    const associatedDoctorsIds = associatedDoctorsList.map(d => d._id);
                    item.associated_doctors_ids = associatedDoctorsIds; // additional parameter
                    this._patientListService.getPatients(item).subscribe((patientsResponse: any) => {
                        this.patients = patientsResponse.data.array
                            .filter(patient => patient.is_active === 'true')
                            .map(patient => ({
                                patient_id: patient._id,
                                name: `${patient.first_name} ${patient.middle_name} ${patient.last_name}`,
                                ...patient
                            })
                            );
                        this.filteredPatients = this.patients;
                        resolve(this.patients);
                    }, (error) => {
                        // console.error(error);
                        reject(error);
                        // this._toastr.clear(infoToast.toastId);

                    },
                        // () => this._toastr.clear(infoToast.toastId)

                    );
                }
            });
        });
    }

    private getPatientName(patient) {
        let patientName = '';
        const { first_name = '', middle_name = '', last_name = '' } = patient;
        patientName = first_name;
        if (middle_name) {
            patientName += ' ' + middle_name;
        }

        if (last_name) {
            patientName += ' ' + last_name;
        }

        return patientName;
    }

    filterPatients = (filterPatientValue) => {
        if (typeof filterPatientValue === 'string' && filterPatientValue.trim().length > 0) {
            filterPatientValue = filterPatientValue.trim();
            const patients = this.patients.filter(patient => this.getPatientName(patient).toLowerCase().startsWith(filterPatientValue));
            this.filteredPatients = patients;
        } else {
            this.filteredPatients = this.patients;
        }
    };
}
