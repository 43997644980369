import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from "../../../../environments/api-url";
import * as global from '../../global';


@Injectable({
  providedIn: 'root'
})
export class QueryReportService {

  constructor(
    private httpclient: HttpClient,
    private _authService: AuthService,
  ) { }


  createGeneralReport(payload) {
    const details = {
      ...this._authService.authObject,
      ...payload
    }
    return this.httpclient
      .post(global.url + API_URL.QUERY_REPORT.createGeneralReport, { details: details });

  };

  getGeneralReport(filter, project) {
    const details = {
      ...this._authService.authObject,
      filter, 
      project
    }
    return this.httpclient
      .post(global.url + API_URL.QUERY_REPORT.getGeneralReport, { details: details });

  };

  getQueryReport(filter, project) {
    const details = {
      ...this._authService.authObject,
      filter, 
      project
    }
    return this.httpclient
      .post(global.url + API_URL.QUERY_REPORT.getQueryReport, { details: details });

  };

  createQueryReport(payload) {
    const details = {
      ...this._authService.authObject,
      ...payload
    }
    return this.httpclient
      .post(global.url + API_URL.QUERY_REPORT.createQueryReport, { details: details });

  };
  updateQueryReport(payload) {
    const details = {
      ...this._authService.authObject,
      ...payload
    }
    return this.httpclient
      .post(global.url + API_URL.QUERY_REPORT.updateQueryReport, { details: details });

  };

  updateGeneralReport(payload) {
    const details = {
      ...this._authService.authObject,
      ...payload
    };
    return this.httpclient
      .post(global.url + API_URL.QUERY_REPORT.updateGeneralReport, { details: details });

  };

  deleteQueryReport(queryReportId) {
    const details = {
      ...this._authService.authObject,
      queryReportId
    };
    return this.httpclient
      .post(global.url + API_URL.QUERY_REPORT.deleteQueryReport, { details: details });

  };

  executeQueryReport(filter, project) {
    const details = {
      ...this._authService.authObject,
      filter, 
      project
    };
    return this.httpclient
      .post(global.url + API_URL.QUERY_REPORT.executeQueryReport, { details: details });

  };

}
