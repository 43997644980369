<div class="organization-settings">
    <div class="flex justify-between items-center gap-5"
        style="width: 100%;height: 35px; margin:15px 5px 15px 10px">

        <div class="flex">
            <mat-label style="font-weight: bold;">All Organizations</mat-label>
        </div>

        <div class="flex" style="padding-right: 20px;">
            <button mat-raised-button class="dn-button-primary" (click)="openOrganizationDialog()">
                Create Organization
            </button>

        </div>

    </div>

    <div>
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef>Title</th>
                <td mat-cell *matCellDef="let element">
                    {{element.title }}
                </td>
            </ng-container>

            <ng-container matColumnDef="tin">
                <th mat-header-cell *matHeaderCellDef>TIN</th>
                <td mat-cell *matCellDef="let element">
                    {{element.tin }}
                </td>
            </ng-container>

            <ng-container matColumnDef="users">
                <th mat-header-cell *matHeaderCellDef>Users</th>
                <td mat-cell *matCellDef="let element">
                    <ng-multiselect-dropdown 
                        name="associated_users" 
                        [(ngModel)]="element.user_ids"
                        [settings]="userDropdownSettings" 
                        [data]="users" 
                        [placeholder]="'Select user(s)'"
                        (onSelect)="onUserSelect($event, element);" 
                        (onDeSelect)="onUserDeSelect($event, element);"
                        (onSelectAll)="onSelectAllUsers($event, element)"
                        (onDeSelectAll)="onDeSelectAllUsers($event, element)"
                        [ngClass]="{'inactive-chip': !element.is_active}"
                        [disabled]="!element.is_active">
                    </ng-multiselect-dropdown>
                </td>
            </ng-container>

            <ng-container matColumnDef="operations">
                <th mat-header-cell *matHeaderCellDef mat-sort-header-container></th>
                <td mat-cell *matCellDef="let element; let i= index">
                    <div class="flex items-center justify-center">
                        <a style="cursor: pointer">
                            <i class="icon icon-edit" matTooltip="Edit Org" matTooltipPosition="above" id="edit-icon"
                                (click)="openOrganizationDialog(element)"></i>
                        </a>
                        <a style="cursor: pointer; margin-left: 1rem;">
                            <mat-slide-toggle [checked]="element.is_active" (change)="onChangeStatus(element,$event)">
                            </mat-slide-toggle>
                        </a>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>