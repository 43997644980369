<div class="phrases-wrapper flex flex-col gap-2">
    <div *ngFor="let dictionaryElement of dictionary; let i = index" class="flex items-start justify-baseline gap-2">
        <div class="flex items-start justify-center flex-grow">
            <div style="color:rgb(104, 104, 104); font-size: 12px;">{{i+1}}</div>

            <mat-form-field class="flex-grow">
                <mat-label>Shortcut</mat-label>
                <input matInput type="text" [value]="dictionaryElement ? getMarker() + dictionaryElement.key : ''"
                    readonly>
            </mat-form-field>
        </div>
    </div>
</div>
