<br> <br> <br>
 <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    
    <ng-container  matColumnDef="Sr No.">
      <th mat-header-cell *matHeaderCellDef> No. </th>
      <td mat-cell *matCellDef="let element; let i = index;">{{i+1}}</td>
    </ng-container>
  
    
    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
  
    <ng-container matColumnDef="Issue Type">
      <th mat-header-cell *matHeaderCellDef> Issue Type </th>
      <td mat-cell *matCellDef="let element"> 
      <div *ngIf="element.issue_type==='bug'" style="color:red;">
        {{element.issue_type | titlecase}} 
      </div> 
      <div *ngIf="element.issue_type!=='bug'">
        {{element.issue_type | titlecase}} 
      </div>  
      </td>
    </ng-container>
    
    
    <ng-container matColumnDef="Comment">
      <th mat-header-cell *matHeaderCellDef> Feedback </th>
      <td mat-cell *matCellDef="let element"> {{element?.text?.length > 20 ? element?.text?.slice(0,20).concat(" ","...") : element?.text}}</td>
    </ng-container>

    <ng-container matColumnDef="Status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let element"> <span *ngIf="element.acceptance_status===true"><img width="10px" src="../../../assets/img/green_dot.png" alt=""><span style="color:green;">&nbsp;&nbsp;Approved</span></span><span *ngIf="element.acceptance_status===false"><img width="10px"  src="../../../assets/img/red_dot.png" alt=""><span style="color:red;">&nbsp;&nbsp;Rejected</span></span> </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openDialog(row)" style="cursor: pointer;"></tr>
  </table>
  
  