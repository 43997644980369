import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CompanyLogoAndMongogramService } from 'src/app/services/company-logo-and-mongogram.service';

@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.css']
})
export class CompanySettingsComponent implements OnInit {
  loaderId = 'company-settings';
  @Input('company') company;
  name: string;
  logo: any = false;
  logoName: string = "";
  @Output() exit = new EventEmitter<boolean>();
  @Output() updateCurrentCompany = new EventEmitter<boolean>();
  isLogoRemovebuttonVisible: boolean = false;
  isMonogramRemovebuttonVisible: boolean = false;
  monogramName: string = "";
  monogram: any = false;
  monogramSrcPath: string = "";
  logoSrcPath: string = "";
  companyOwnerFullName: any = "";
  isSaveButtonDisabled: boolean = false;

  companyLogoPath = '';
  companyMonogramPath = '';
  companyLogoExists : Boolean = false;
  companyMonogramExists  : Boolean = false;
  logoChange : Boolean = false;
  monogramChange  : Boolean = false;
  nameChanged: Boolean = false;
  tinChanged: Boolean = false;
  oldTin: any;
  newTin: any;
  oldCompanyName: String = "";
  newCompanyName: String = "";
  constructor(
    private _companyService: CompanyService,
    private _toastr: ToastrService,
    private _authService: AuthService,
    private _loader: NgxUiLoaderService,
    private _companyLogoAndMonogramService: CompanyLogoAndMongogramService
  ) { }

  ngOnInit(): void {
    this.companyOwnerFullName = this.company.owner?.last_name + " " + this.company.owner?.first_name;
    this.oldCompanyName = this.company.name;
    this.oldTin = this.company.tin;
    if(this.company.logo_aws_path){
      this.companyLogoPath = this.company.logo_aws_path;
      this.companyLogoExists = true
    }
    if(this.company.monogram_aws_path){
      this.companyMonogramPath = this.company.monogram_aws_path;
      this.companyMonogramExists = true;
    }
  }

  savePccFacilityActivationNote() {
    // this._companyService.().subscribe((response: any) => {
    //   if(response.status === 200) {
    //     this._toastr.success("Note Saved", "Success");
    //   } 
    // });
  }
  onNameChange(value: any) {
    if (this.oldCompanyName != value) {
      this.nameChanged = true;
      this.newCompanyName = value;
    } else {
      this.nameChanged = false;
    }
  }
  onTinChange(value: any) {
    if (this.oldTin != value) {
      this.tinChanged = true;
      this.newTin = value;
    }
    else
      this.tinChanged = false;
  }
  // saveModifier(){
  //   const data={
  //     companyId:this.company._id,
  //     modifier:this.company.modifier
  //   };
  //   console.log("data : ", data);
  //   this._companyService.saveCompanyModifier(data).subscribe((res:any)=>{
  //     console.log("modifier saved : ", res.data);
  //   })
  // }
  onLogoSelect(e) {
    this.logoName = e.target.files[0].name;
    this.logo = e.target.files[0];
    this.isLogoRemovebuttonVisible = true;
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (event: any) => {
      this.logoSrcPath = event.target.result;
    }
    this.logoChange = true;
    // this.isSaveButtonDisabled = false;
  }
  onLogoDeselect(e) {
    this.logoSrcPath = "";
    this.logoName = "";
    this.logo = null;
    this.isLogoRemovebuttonVisible = false;
    e.value = "";
    this.logoChange = false;
  }

  onMonogramSelect(e) {
    this.monogramName = e.target.files[0].name;
    this.monogram = e.target.files[0];
    this.isMonogramRemovebuttonVisible = true;
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (event: any) => {
      this.monogramSrcPath = event.target.result;
    }
    this.monogramChange = true;
    // this.isSaveButtonDisabled = false;
  }
  onMonogramDeselect(e) {
    this.monogramSrcPath = "";
    this.monogramName = "";
    this.monogram = null;
    this.isMonogramRemovebuttonVisible = false;
    e.value = "";
    this.monogramChange = false;
  }

  updateName() {
    if (this.nameChanged && this.newCompanyName != '') {
      if (this.newCompanyName != this.oldCompanyName) {
        this.company.name = this.newCompanyName;
      }
    } else {
      this.company.name = this.oldCompanyName;
    }
  }
  updateTin() {
    if (this.tinChanged) {
      if (this.oldTin != this.newTin) {
        this.company.tin = this.newTin;
      }
    }
  }
  goBack() {
    this.exit.emit(false);
    this.updateCurrentCompany.emit(this.company);
  }
  submitForm() {
    this._loader.startLoader(this.loaderId);
    const formData = new FormData();
    if (this.logo) {
      formData.append('logo', this.logo);
    }
    if (this.monogram) {
      formData.append('monogram', this.monogram);
    }
    if (this.nameChanged) {
      this.company.name = this.newCompanyName;
      formData.append('name', this.company.name);
    }
    if (this.tinChanged) {
      this.company.tin = this.newTin;
      formData.append('tin', this.company.tin);
    }
    formData.append('companyId', this.company._id);

    this._companyService.saveCompanyImages(formData).subscribe((response: any) => {
      if (response.status === 200) {
        this.updateName();
        this._toastr.success("Changes Saved", "Success");
        this._loader.stopLoader(this.loaderId);
        this.company = response.data;
        this.companyLogoPath = response.data.logo_aws_path;
        this.companyMonogramPath = response.data.monogram_aws_path;
        this.companyLogoExists = true;
        this.companyMonogramExists = true;
        this.isLogoRemovebuttonVisible = false;
        this.isMonogramRemovebuttonVisible = false;
        this.logoSrcPath = "";
        this.monogramSrcPath = "";
        this.logoChange = false;
        this.monogramChange = false;
        this.nameChanged = false;
        this.tinChanged = false;
        this._companyLogoAndMonogramService.setCompanyLogo(this.companyLogoPath);
      } else {
        this._toastr.error(response.message, "Error");
        this._loader.stopLoader(this.loaderId);
      }
    });
  }

  disableButton() {
    if (this.companyLogoExists && this.companyMonogramExists) {
      if (this.monogramChange || this.logoChange || this.nameChanged || this.tinChanged) {
        return false;
      } else {
        return true;
      }
    } else {
      if (this.monogramChange && this.logoChange) {
        return false;
      } else if (this.nameChanged || this.tinChanged) {
        return false;
      }
      else {
        return true;
      }
    }
  }
}
