import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/Modules/user.service';
import { FacilityService } from 'src/app/services/facility.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { USER_TYPE } from 'src/app/includes/global';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as global from '../../../includes/global';
import { CommonService } from 'src/app/services/common.service';

@Component({
    selector: 'app-company-user-management',
    templateUrl: './company-user-management.component.html',
    styleUrls: ['./company-user-management.component.css'],
})
export class CompanyUserManagementComponent implements OnInit {
    USER_TYPE = USER_TYPE;

    public dataSource: any;
    selectedCompany: any = '';
    users: any[] = [];
    usersFilter = {
        user_type: '1'
    };
    prev_user_type;
    displayedColumns: string[] = ['first_name', 'last_name', 'email', 'role', 'superadmin_switch', 'operations'];
    loaderId = 'company-user-management-loader';
    params: {
        id: string
    }
    defaultUserRoleType: string = "all";
    activatedRoute: string = 'doctor-list'
    pagination = {
        pageNo: 1,
        hasNextPage: false,
        hasPrevPage: false,
        totalRecords: 0,
        PAGE_LIMIT: 10,
        skip: 0
    };
    global = global;
    currentCompany: any = {};
    constructor(
        private loader: NgxUiLoaderService,
        private companyService: CompanyService,
        private route: ActivatedRoute,
        private userService: UserService,
        private toastr: ToastrService,
        private _router: Router,
        private commonService: CommonService
    ) {
        this.activatedRoute = this.route.snapshot.firstChild.routeConfig.path;
        this.params = {
            id: this.route.parent.parent.snapshot.params.id
        };
        if (this.commonService.getLocalStorageItem('admin-Dashboard'))
            this.currentCompany.company_type = this.commonService.getLocalStorageItem('admin-Dashboard').currentCompanyType;
    }

    ngOnInit(): void {
        this.prev_user_type = localStorage.getItem('prev_user_type');
        if (this.prev_user_type && this.prev_user_type != this.usersFilter.user_type) {
            this.usersFilter.user_type = this.prev_user_type;
        }
        if (this.currentCompany.company_type === global.COMPANY_TYPE.SNF) {
            this.usersFilter.user_type = USER_TYPE.SNF_WC_NURSE
            this.commonService.setSeletedUserType(USER_TYPE.SNF_WC_NURSE);
            this.activatedRoute = 'snf-wc-nurse-list'
            this._router.navigate([`${this.activatedRoute}`], { relativeTo: this.route })
        } else {
            this.initCompanyUsers();
        }
     
    }
  
  
    handleChangeUserType() {
        localStorage.setItem("prev_user_type", this.usersFilter.user_type);
        this.initCompanyUsers();
        this.handleChangeRoleType("all");
        this.defaultUserRoleType = "all";
    }
    handleChangeRoleType(event) {
        this.companyService.changeUserRoleType(event);
    }

    initCompanyUsers = async () => {
        if (this.usersFilter.user_type == 'allUserType') {
            this.commonService.setSeletedUserType('allUserType');
            this.activatedRoute = 'all-user'
            this._router.navigate([`${this.activatedRoute}`], { relativeTo: this.route })
        }
        if (this.usersFilter.user_type == '1') {
            this.commonService.setSeletedUserType('1');
            this.activatedRoute = 'doctor-list'
            this._router.navigate([`${this.activatedRoute}`], { relativeTo: this.route })
        }
        if (this.usersFilter.user_type == 'test_provider') {
            this.commonService.setSeletedUserType('1');
            this.activatedRoute = 'test-provider-list'
            this._router.navigate([`${this.activatedRoute}`], { relativeTo: this.route })
        }
        if (this.usersFilter.user_type == 'cnp') {
            this.commonService.setSeletedUserType('1');
            this.activatedRoute = 'cnp-list'
            this._router.navigate([`${this.activatedRoute}`], { relativeTo: this.route })
        }
        else if (this.usersFilter.user_type == '2') {
            this.commonService.setSeletedUserType('2');
            this.activatedRoute = 'transcriber-list'
            this._router.navigate([`${this.activatedRoute}`], { relativeTo: this.route })
        }
        else if (this.usersFilter.user_type == '6') {
            this.commonService.setSeletedUserType('6');
            this.activatedRoute = 'biller-list'
            this._router.navigate([`${this.activatedRoute}`], { relativeTo: this.route })
        }
        else if (this.usersFilter.user_type == '5' || this.usersFilter.user_type == 'nurse') {
            this.commonService.setSeletedUserType('nurse');
            this.activatedRoute = 'nurse-list'
            this._router.navigate([`${this.activatedRoute}`], { relativeTo: this.route })
        }
        else if (this.usersFilter.user_type == '7') {
            this.commonService.setSeletedUserType('7');
            this.activatedRoute = 'admin-list'
            this._router.navigate([`${this.activatedRoute}`], { relativeTo: this.route })
        }
        else if (this.usersFilter.user_type == 'medical_assistant') {
            this.commonService.setSeletedUserType('medical_assistant');
            this.activatedRoute = 'medical-assistant-list'
            this._router.navigate([`${this.activatedRoute}`], { relativeTo: this.route })
        }
        else if (this.usersFilter.user_type == '3') {
            this.commonService.setSeletedUserType('3');
            this.activatedRoute = 'manager-list'
            this._router.navigate([`${this.activatedRoute}`], { relativeTo: this.route })
        }
        else if (this.usersFilter.user_type == 'business_officer') {
            this.commonService.setSeletedUserType('business_officer');
            this.activatedRoute = 'business-officer-list'
            this._router.navigate([`${this.activatedRoute}`], { relativeTo: this.route })
        }
        else if (this.usersFilter.user_type == USER_TYPE.SNF_WC_NURSE) {
            this.commonService.setSeletedUserType(USER_TYPE.SNF_WC_NURSE);
            this.activatedRoute = 'snf-wc-nurse-list'
            this._router.navigate([`${this.activatedRoute}`], { relativeTo: this.route })
        }
        else if (this.usersFilter.user_type == USER_TYPE.WOUND_NURSE) {
            this.commonService.setSeletedUserType(USER_TYPE.WOUND_NURSE);
            this.activatedRoute = 'wound-nurse-list'
            this._router.navigate([`${this.activatedRoute}`], { relativeTo: this.route })
        }
        else if (this.usersFilter.user_type == USER_TYPE.VIEW_ONLY_USER) {
            this.commonService.setSeletedUserType(USER_TYPE.VIEW_ONLY_USER);
            this.activatedRoute = 'view-only-users-list'
            this._router.navigate([`${this.activatedRoute}`], { relativeTo: this.route })
        }

        this.loader.startLoader(this.loaderId);

        const company_id = this.params.id;
        let filter: any = {};
        if (this.usersFilter.user_type === '') {
            filter = {
                is_activated: 'true',
                company_id
            }
        } else {
            filter = {
                is_activated: 'true',
                user_type: this.usersFilter.user_type,
                company_id
            }
        }
        filter.pageNo = this.pagination.pageNo;

        const projection = {
            'first_name': 1,
            'last_name': 1,
            'email': 1,
            'title': 1,
            'user_type': 1,
            'admin_type': 1,
            'account_type': 1
        }

        const response: any = await this.userService.getUsers(filter, projection).toPromise();
        this.users = response.data;
        console.log('responseee', response);
        this.pagination.totalRecords = response.totalRecords;
        this.handlePagination();
        if (this.users) {
            this.users = this.users.map(u => {
                u.role = this.getRole(u?.user_type);
                return u;
            })
        }

        this.loader.stopLoader(this.loaderId);

        this.dataSource = new MatTableDataSource(this.users);
    }

    handlePagination() {
        this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
        if (this.pagination.skip + this.users.length < this.pagination.totalRecords) {
            this.pagination.hasNextPage = true;
        }
        else {
            this.pagination.hasNextPage = false;
        }
        if (this.pagination.skip > 0) {
            this.pagination.hasPrevPage = true;
        }
        else {
            this.pagination.hasPrevPage = false;
        }
    }

    goToPreviousPage() {
        this.pagination.pageNo--;
        this.initCompanyUsers();
    }
    goToNextPage() {
        this.pagination.pageNo++;
        this.initCompanyUsers();
    }

    getRole(user_type) {
        if (user_type === '1') return 'Provider';
        if (user_type === '2') return 'Transcriber';
        if (user_type === '3') return 'Manager';
        if (user_type === '4') return 'PA';
        if (user_type === '5') return 'NURSE';
        if (user_type === '6') return 'BILLER';
        if (user_type === 'medical_assistant') return 'MEDICAL_ASSISTANT';
        if (user_type === 'business_officer') return 'business_officer';
        if (user_type === global.USER_TYPE.SNF_WC_NURSE) return global.USER_TYPE.SNF_WC_NURSE;
        if (user_type === global.USER_TYPE.WOUND_NURSE) return global.USER_TYPE.WOUND_NURSE;
        if (user_type === global.USER_TYPE.VIEW_ONLY_USER) return global.USER_TYPE.VIEW_ONLY_USER;
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    toggleSuperadmin(user) {
        const { admin_type } = user;
        user.admin_type = admin_type === 'full_admin' || 'system_admin' ? 'false' : 'true'
        const data = {
            _id: user._id,
            admin_type: user.admin_type
        }
        this.userService.updateUser(data).subscribe((response: any) => {
            if (response.status === 200) {

            } else {
                user.admin_type = admin_type;
                this.toastr.error('Something went wrong, please try again');
            }
        }, () => {
            user.admin_type = admin_type;
            this.toastr.error('Something went wrong, please try again');
        })
    }

    onDeleteClick(user) {
        if (!confirm('Are you sure you want to delete?')) {
            return;
        }

        this.userService.deleteUser(user._id).subscribe((response: any) => {
            if (response.status === 200) {
                this.initCompanyUsers();
            }
        })
    }
    isOptionVisibleForCompany( option ) {
        const companyId = this.params.id;
        return this.userService.isOptionVisibleForCompany( option, companyId);
    }
}
