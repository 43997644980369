<form (ngSubmit)="submitForm()">

    <mat-form-field style="width: 100%; margin-bottom: -15px;">
        <mat-select (selectionChange)="onSelectEmailTemplate($event.value);" value="none">
            <mat-option value="none">
                Select Email Template
            </mat-option>
            <mat-option *ngFor="let emailTemplate of emailTemplates" [value]="emailTemplate._id">
                {{emailTemplate.title}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field style="width: 100%; margin-bottom: -15px;">
        <mat-label>Subject</mat-label>
        <input matInput type="text" [readonly]="isOneTimeEmail" name="subject" #subject="ngModel" [(ngModel)]="model.subject">
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 100%; margin-bottom: -15px;">
        <mat-chip-grid #emailIdChipList aria-label="Email">
            <mat-chip *ngFor="let emailId of emailIds" [selectable]="true" [removable]="true"
                (removed)="removeEmailIdAsChip(emailId)">
                {{emailId}}
                <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
            </mat-chip>
            <input (focus)="handleEmailIdInputFocus($event)" placeholder="Email" #eamilIdInput
                [formControl]="emailIdCtrl" [matChipInputFor]="emailIdChipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addEmailIdAsChip($event)"  [readonly]="isOneTimeEmail"/>
        </mat-chip-grid>
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 100%; margin-bottom: -15px;">
        <mat-chip-grid #ccEmailIdChipList aria-label="CC">
            <mat-chip *ngFor="let ccEmailId of ccEmailIds" [selectable]="true" [removable]="true"
                (removed)="removeCCEmailIdAsChip(ccEmailId)">
                {{ccEmailId}}
                <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
            </mat-chip>
            <input (focus)="handleCCEmailIdInputFocus($event)" placeholder="CC" #ccEamilIdInput
                [formControl]="ccEmailIdCtrl" [matChipInputFor]="ccEmailIdChipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addCCEmailIdAsChip($event)" [readonly]="isOneTimeEmail" />
        </mat-chip-grid>
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 100%; margin-bottom: -7px;">
        <mat-chip-grid #bccEmailIdChipList aria-label="BCC">
            <mat-chip *ngFor="let bccEmailId of bccEmailIds" [selectable]="true" [removable]="true"
                (removed)="removeBCCEmailIdAsChip(bccEmailId)">
                {{bccEmailId}}
                <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
            </mat-chip>
            <input (focus)="handleBCCEmailIdInputFocus($event)" placeholder="BCC" #ccEamilIdInput
                [formControl]="bccEmailIdCtrl" [matChipInputFor]="bccEmailIdChipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addBCCEmailIdAsChip($event)" [readonly]="isOneTimeEmail" />
        </mat-chip-grid>
    </mat-form-field>

    <div style="height: 400px;">
        <ckeditor [(ngModel)]="model.emailBody" name="emailBody" class="h100"
            editorUrl="../../../../../assets/ckeditor/ckeditor.js" [readOnly]="!cke4Config.editable"
            [config]="cke4Config" type="divarea">
        </ckeditor>
    </div>
    <br>
    <div class="flex justify-between items-center" *ngIf="!isOneTimeEmail">
        <div class="flex justify-between items-center gap-1">
            <mat-checkbox [checked]="model?.isAutomatic" (change)="handleCheckboxChange($event)" class="heading-sizes">
                Automated Email
            </mat-checkbox>

            <mat-form-field *ngIf="model?.isAutomatic" appearance="none" class="dropdown" class="flex-grow">
                <mat-label>Duration</mat-label>
                <mat-select [value]="model?.duration" (selectionChange)="handleDurationChange($event.value)">
                    <mat-option value="second">
                        Every Second
                    </mat-option>
                    <mat-option value="minute">
                        Every Minute
                    </mat-option>
                    <mat-option value="hour">
                        Every Hour
                    </mat-option>
                    <mat-option value="day">
                        Every Day
                    </mat-option>
                    <mat-option value="week">
                        Every Week
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="gap-5">
            <button mat-raised-button class="dn-button-secondary" mat-dialog-close type="button" style="margin-right: 10px;">Cancel</button>
            <button mat-raised-button type="button" *ngIf="!emailId" class="dn-button-primary" [disabled]="emailIds.length === 0"
                type="submit">Send</button>
            <button mat-raised-button type="button" *ngIf="emailId" class="dn-button-primary" [disabled]="emailIds.length === 0"
                type="submit">Update</button>
        </div>
    </div>
</form>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>