import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { AudioService } from 'src/app/services/Modules/audio.service';
import { TranscriptionDetailService } from '../transcription-detail/transcription-detail.service';
import { NoteCommentsService } from './note-comments/note-comments.service';
import * as global from '../global'
import { SidebarChargesComponent } from 'src/app/components/sidebar-addCharges/sidebar-charges/sidebar-charges.component';
import { BehaviorSubject } from 'rxjs';
import { NoteAddChargeComponent } from './note-addCharge/note-add-charge/note-add-charge.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ActivatedRoute } from '@angular/router';
import { NoteAddChargeService } from './note-addCharge/note-add-charge.service';
import { WoundCareWidgetService } from './wound-care-widget/wound-care-widget.service';
import { WatchHelpVideoDialog } from '../drive/watch-help-video-dialog.component';
import { WoundCareWidgetComponent } from './wound-care-widget/wound-care-widget.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-note-sidebar',
  templateUrl: './note-sidebar.component.html',
  styleUrls: ['./note-sidebar.component.css']
})
export class NoteSidebarComponent implements OnInit, OnChanges {
  @Input() censusId: String;
  @Input() patientId: String;
  @Input() noteId: String;
  @Input() note: any;
  @Input() addendum_counts: any;
  @Input() audioId: string;
  @Input() noteEditorIcds: any[] = [];
  @Input() companySubmitMipsStatus: any = false;
  @Input() noteEditorCpts: any[] = [];
  @Input() emCodeEditorCpts: any[] = [];
  @Input() signedNotesStatus: any = false;
  @Input() carryChargeStatusIcds: any;
  @Input() carryChargeStatusCpts: any;
  @Input() configuration: any;
  @Input() companyPermissions: any
  @Input() telemedPhrase: any = false;
  @Input() shouldShowNoteTimeLogs: boolean = false;

  currentUser: any;

  global: any = global;
  @Output() onTemplateSelect: EventEmitter<any> = new EventEmitter()
  @Output() closeSidebar: EventEmitter<Boolean> = new EventEmitter()
  @Output("onAddendumClick") onAddendumClick: EventEmitter<any> = new EventEmitter()
  @Output() onChargeChange: EventEmitter<any> = new EventEmitter();
  @Output() onChargeAndICDChange: EventEmitter<any> = new EventEmitter();
  @Output() onICDsSave: EventEmitter<any> = new EventEmitter();
  @Output() onHtmlTextChanges: EventEmitter<any> = new EventEmitter();
  @Output() onNoteEdiorCptsChange: EventEmitter<any> = new EventEmitter();
  @Output() onNoteEdiorIcdsChange: EventEmitter<any> = new EventEmitter();
  @Output() woundDetailsChange: EventEmitter<any> = new EventEmitter();
  @Output() procedureNoteDetailsChange: EventEmitter<any> = new EventEmitter();
  @Output() checkallWoundAssessmentPhrase: EventEmitter<any> = new EventEmitter();
  @Output() insertPhraseText: EventEmitter<any> = new EventEmitter();
  


  selectedTabIndex = 0;
  tabIndexAutoInitialized = false;

  @ViewChild(NoteAddChargeComponent) noteAddChargeComponent: NoteAddChargeComponent;
  @ViewChild(SidebarChargesComponent) sidebarChargesComponent: SidebarChargesComponent;
  @ViewChild(WoundCareWidgetComponent) skinwoundCareComponent: WoundCareWidgetComponent;
  tabs: any = [
    { name: 'Skin/Wound', is_active: false },
    { name: 'Charge Capture', is_active: true },
    { name: 'Patient Details', is_active: false },
    { name: 'Phrases', is_active: false },
    { name: 'Census Comments', is_active: false },
    { name: 'History', is_active: false },
    { name: 'Communication', is_active: false },
    { name: 'Addendums', is_active: false },
    { name: 'Therapy', is_active: false },
    { name: 'Note Time Logs', is_active: false }, 
    // { name: 'Transcript', is_active: false },
    // { name: 'Chunks', is_active: false },
  ];
  updatedArray: any = [];
  tab: any;
  tabConditions: any = {};
  currentUserChargeStatus: Boolean;
  roundingSheetId;
  patient_id;
  isChargeTabActive: boolean = false;
  allDataLoaded: Boolean = false;
  patient_object: any;
  constructor(
    private _authService: AuthService,
    private noteCommentsService: NoteCommentsService,
    private _activatedRoute: ActivatedRoute,
    private _noteAddChargeService: NoteAddChargeService,
    private _woundCareService: WoundCareWidgetService,
    private _toaster: ToastrService,
    private dialog: MatDialog,
  ) {
    const currentUser = this._authService.currentUser;
    if (currentUser) {
      // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.currentUser = currentUser;
      this.currentUserChargeStatus = this.currentUser.charge_status;
    }
    if (this.currentUser.other_settings.show_telemedicine == true) {
      for (let i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i].name === 'Charge Capture') {
          this.tabs.splice(i + 1, 0, { name: 'Telemedicine', is_active: false });
          break;
        }
      }
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!this.tabIndexAutoInitialized) {

      const noteChanges = changes['note'];
      if (!noteChanges) return;
      let curNote = JSON.stringify(noteChanges.currentValue);
      let prevNote = JSON.stringify(noteChanges.previousValue);
      const note = noteChanges.currentValue;
      if (curNote !== prevNote && note?.rounding_sheet_id) {
        this.selectedTabIndex = 0;
        this.tabIndexAutoInitialized = true;
      }
    }
  }

  async ngOnInit() {
    console.log('noteeeeee', this.note);
    console.log('this current user', this.currentUser);
    console.log('this.shouldNoteTimeLogs', this.shouldShowNoteTimeLogs);
    
    if (!this.patientId) {
      this.patient_id = this.note?.patient?._id;
      this.patient_object=this.note?.patient;
      this.patientId = this.patient_id;
    }
    if (!this.censusId) {
      this.roundingSheetId = this.note?.rounding_sheet_id;
      this.censusId = this.roundingSheetId;
    }
    await this.initTabs();
    this.tabConditions = {
      "Skin/Wound": !(this.currentUser.company_type !== global.COMPANY_TYPE.WOUND || this.currentUser.company_type !== global.COMPANY_TYPE.SNF) ||  this.currentUser.company_type === global.COMPANY_TYPE.NURSING,
      "Census Comments": !(this.censusId || this.roundingSheetId),
      "Communication": !this.noteId,
      "Addendums": this.addendum_counts?.total <= 0,
      "Charge Capture": !(this.note?.facility?.enabled_charge_capture != false && this.currentUserChargeStatus != false && (this.censusId || this.roundingSheetId))
      || this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE || this.currentUser.user_type === global.USER_TYPE.CNP || this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE,
      "Phrases": this.currentUser.user_type === global.USER_TYPE.BUSINESS_OFFICER,
      "Note Time Logs" : !this.shouldShowNoteTimeLogs
    };
    if (this.updatedArray.length == 0) {
      console.log('first time', this.updatedArray.length);
      this.updatedArray = this.tabs;
    }

    this.applyTabConditions();
    this.updatedArray.forEach(element => {
      element.is_active = false;
    });

    if (this._activatedRoute.snapshot.paramMap.get('isChargeTabActive')) {
      const index = this.updatedArray.findIndex(item => item.name === "Charge Capture");
      if (index !== -1) {
        this.tab = this.updatedArray[index].name;
        this.updatedArray[index].is_active = true;
      }
    }
    else {
      this.tab = this.updatedArray[0].name;
      this.updatedArray[0].is_active = true;
    }
    this.allDataLoaded = true;
    //move therapy tab to last index
    for(var x in this.updatedArray) this.updatedArray[x].name == "Therapy" ? this.updatedArray.push( this.updatedArray.splice(x,1)[0] ) : 0;
  }
  ngOnDestroy(): void {
    this._noteAddChargeService.setisCPT(false);
    this._noteAddChargeService.setisICD(false);
  }
  initTabs() {
    return new Promise<void>((resolve, reject) => {
      this.noteCommentsService.getTabSettings().subscribe(data => {
        // @ts-ignore
        if (data.data && data.data.length > 0) {
          // @ts-ignore
          this.updatedArray = data.data;
          this.updatedArray = [...this.updatedArray, ...this.tabs.filter(t => !(this.updatedArray.map(tab => tab.name).includes(t.name)))]
          this.updatedArray = this.updatedArray.filter((item => item.name !== 'Chunks' && item.name !== 'Therapy Tab' && item.name !== 'Transcript' && item.name !== 'Comments'))

        }
        resolve(null);
      }, error => {
        reject('error => ' + error.toString());
      });
    });
  }
  applyTabConditions() {
    for (const key in this.tabConditions) {
      if (this.tabConditions[key]) {
        this.filtertabs(key);
      }
    }
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.updatedArray, event.previousIndex, event.currentIndex);
    this.noteCommentsService.addTabSettings(this.updatedArray).subscribe((Response: any) => {
      if (Response.status == 200) {
        this._toaster.success('Setting Updated');
      }
      else {
        this._toaster.error("Something went wrong while updating setting.")
      }
    });

  }
  filtertabs(tabName: String) {
    this.updatedArray = this.updatedArray.filter(t => t.name !== tabName);
  }

  public tabChange(tab, index) {
    this.tab = tab.name;
    this.updatedArray.forEach((element, i) => {
      if (index !== i)
        element.is_active = false
    });
    tab.is_active = true;
  }

  handleAddendumClick(e) {
    this.onAddendumClick.emit(e);
  }

  handleTemplateSelect(e) {
    this.onTemplateSelect.emit(e);
  }
  sidebarClose(event) {
    this.closeSidebar.emit(true)
  }

  handleChargeChange(e) {
    this.onChargeChange.emit(e);
  }
  handleChargeandICDChange(e) {
    this.onChargeAndICDChange.emit(e);
  }
  async addCharge() {
    await this.sidebarChargesComponent.addCharge();
  }
  async addICD(icd) {
    this.sidebarChargesComponent.addICD(icd);
  }
  handleICDsSave(icds) {
    console.log('note-sidebar > handleICDsSave')
    if(icds.previous_icds !== undefined){
      this.noteAddChargeComponent.removeDiagnosesUsingWoundDiagnosis(icds.previous_icds);
    }
    this.onICDsSave.emit(icds);
  }
  watchHelpVideo( videoId ) {
    const dialogRef = this.dialog.open(WatchHelpVideoDialog, {
      data: { videoId }
    });

    dialogRef.afterClosed().subscribe( console.log );
  }
  updateWoundfromNote(id, data, justUpdateObject = false){
    this.skinwoundCareComponent.updateWoundfromNote(id, data, justUpdateObject);
  }
  checkChildWoundExists(id){
    return this.skinwoundCareComponent.checkChildWoundExists(id);
  }
  isWoundLocked(id){
    return this.skinwoundCareComponent.isWoundLocked(id);
  }
  handlePhrasesChange($event) {
    this.onHtmlTextChanges.emit($event);
  }
  handleWoundDetailsChange($event){
    this.woundDetailsChange.emit($event);
  }
  handleProcedureNoteDetailsChange(detailsObj) {
    if(detailsObj.data && detailsObj.data.deletedProcedure.length > 0){
      this.removeICDsUsingModifer(detailsObj.data.deletedProcedure.map(procedure => `woundprocedure-${procedure._id}`));
    }
    this.procedureNoteDetailsChange.emit(detailsObj);
  }
  handleNoteEditorCptsChange($event){
    this.onNoteEdiorCptsChange.emit($event)
  }
  handleNoteEditorIcdsChange($event){
    this.onNoteEdiorIcdsChange.emit($event)
  }
  handlecheckallWoundAssessmentPhrase(event){
    this.checkallWoundAssessmentPhrase.emit(event)
  }
  refreshWoundsAfterResolvingPhrase(){
    if(this.skinwoundCareComponent){
      this.skinwoundCareComponent.refreshWounds();
    }
  }
  removeICDsUsingModifer(modifiers) {
    this.noteAddChargeComponent.removeICDsUsingModifer(modifiers);
  }
}
