
<div class="flex flex-col gap-2">
    <div class="flex items-center justify-center">
        <h1 mat-dialog-title style="color: black">Add MIP </h1>
    </div>
    <div class="flex justify-between items-start gap-5" style="width: 100%;height: 35px;"> 
        <span style="margin-right: 10px; flex-direction: row; box-sizing: border-box; width: 90px;"> 
            Measure
        </span>

        <span style="    flex-direction: row; box-sizing: border-box; display: flex; flex: 1 1 0%;">
            <input matInput  style="width: 100%; border: none; border-bottom: ridge;" 
            type="text" id="meaure_text"  /> 
        </span>
    </div> 

    <div class="flex justify-between items-start gap-5" style="width: 100%;height: 35px;"> 
        <span style="margin-right: 10px; flex-direction: row; box-sizing: border-box;width: 90px;"> 
            Description
        </span>

        <span style="flex-direction: row; box-sizing: border-box; display: flex; flex: 1 1 0%;">
            <input matInput  style="width: 100%; border: none; border-bottom: ridge;" 
            type="text" id="meaure_text"  /> 
        </span>
    </div> 

    <div class="flex justify-between items-start gap-5" style="width: 100%;height: 35px;"> 
        <span style="margin-right: 10px; flex-direction: row; box-sizing: border-box;width: 90px;"> 
            CPT Code
        </span> 
        <span style="flex-direction: row; box-sizing: border-box; display: flex; flex: 1 1 0%;">
            <input matInput  style="width: 100%; border: none; border-bottom: ridge;" 
            type="text" id="meaure_text"  /> 
        </span>
    </div> 
    <div class="flex justify-between items-start gap-5" style="width: 100%;height: 35px;"> 
        <span style="margin-right: 10px; flex-direction: row; box-sizing: border-box;"> 
            Age Criteria
        </span> 
        <span style="flex-direction: row; box-sizing: border-box; display: flex; flex: 1 1 0%;">
            <input matInput  style="width: 100%; border: none; border-bottom: ridge;" 
            type="text" id="meaure_text"  /> 
        </span>
    </div> 
    <div class="flex justify-between items-start gap-5" style="width: 100%;height: 35px;"> 
        <span style="margin-right: 10px; flex-direction: row; box-sizing: border-box; "> 
            Main Encounter CPT
        </span> 
        <span style="flex-direction: row; box-sizing: border-box; display: flex; flex: 1 1 0%; ">
            <input matInput  style="width: 100%; border: none; border-bottom: ridge;" 
            type="text" id="meaure_text"  /> 
        </span>
    </div> 
    <div class="flex justify-between items-start gap-5" style="width: 100%; height: 35px;"> 
        <span style="margin-right: 10px; flex-direction: row; box-sizing: border-box;width: 90px;"> 
            DXs Codes
        </span> 
        <span style="flex-direction: row; box-sizing: border-box; display: flex; flex: 1 1 0%;">
            <input matInput  style="width: 100%; border: none; border-bottom: ridge;" 
            type="text" id="meaure_text"  /> 
        </span>
    </div> 
    <div  class="gap-5" style="width: 100%; height: 35px; text-align: right;"> 
        <button mat-raised-button class="dn-button-secondary">Cancel</button>
       <button mat-raised-button class="dn-button-primary">Save</button>
    </div> 
 
</div>

<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>