<mat-card>
    <div *ngIf="facility_id" class="flex items-center justify-end gap-5">
        <button mat-raised-button (click)="openDialog()" type="button" class="dn-button-primary">
            Add more Contact
        </button>
    </div>
    <br />
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Name
            </th>
            <td mat-cell *matCellDef="let element">
                {{(element?.last_name && element?.first_name) ? (element?.last_name + ' ' + element?.first_name) :
                null}}
            </td>
        </ng-container>

        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Position
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.position}}
            </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Email
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.email}}
            </td>
        </ng-container>

        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
                Phone
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.phone}}
            </td>
        </ng-container>

        <ng-container matColumnDef="operations">
            <th mat-header-cell *matHeaderCellDef></th>
            <ng-container *matCellDef="let element">
                <td mat-cell style="max-width: 120px;" class="buttons-div">
                    <div class="operation-buttons buttons-div flex flex-row items-center gap-5"
                        >
                        <i class="icon icon-edit" matTooltip="Edit" matTooltipPosition="above"
                            (click)="editFacilityContact(element)"></i>
                        <i *ngIf="element._id !== model.contact?._id" class="icon icon-delete" matTooltip="Delete"
                            matTooltipPosition="above" (click)="deleteFacilityContact(element)"></i>
                    </div>
                </td>
            </ng-container>
        </ng-container>
        <ng-container matColumnDef="association">
            <th mat-header-cell *matHeaderCellDef>Association</th>
            <ng-container *matCellDef="let element">
                <td mat-cell style="max-width: 120px;" class="buttons-div">
                    <div class="operation-buttons buttons-div flex flex-row items-center gap-5">
                        <mat-slide-toggle *ngIf="element.is_associated" (change)="toggle(element,$event)"
                            [checked]="element.is_associated" matTooltip="Disable Association"
                            matTooltipPosition="above">
                        </mat-slide-toggle>
                        <mat-slide-toggle *ngIf="!element.is_associated" (change)="toggle(element,$event)"
                            [checked]="element.is_associated" matTooltip="Enable Association"
                            matTooltipPosition="above">
                        </mat-slide-toggle>
                    </div>
                </td>
            </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</mat-card>