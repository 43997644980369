import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation, ViewChild } from '@angular/core';
import { PatientListService } from '../patient-list/patient-list-service';
import { PatientDetailService } from './patient-detail.service';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../global'
import { PCCService } from 'src/app/services/pcc.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Patient } from 'src/app/classes/patient';
import { MatDialog } from '@angular/material/dialog';
import { CreateNonPccAllergyIntolerancesDialogComponent } from './non-pcc-allergy-intolerances/create-non-pcc-allergy-intolerances-dialog/create-non-pcc-allergy-intolerances-dialog.component';
import moment from 'moment-timezone';
import { FacilityService } from 'src/app/services/facility.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/services/common.service';
import { lastValueFrom, Subscription } from "rxjs";
import { NoteEditorService } from '../note-editor/note-editor.service';
import { PatientRoundingSheetService } from '../patient-rounding-sheets/patient-rounding-sheet.service';
import { ToastrService } from 'ngx-toastr';
import { PreviousRouteService } from 'src/app/services/previous-route.service';
import { CensusPatientListService } from '../census-patient-list/census-patient-list.service';
import { DrugHistoryConsentDialog } from '../census-patient-list/drug-history-consent-dialog/drug-history-consent-dialog.component';
import { UserService } from "../../services/Modules/user.service";
import { CompanyService } from 'src/app/services/Modules/company.service';

// internal use only
enum PatientTab {
    PatientNotes = 'Patient Notes',
    PatientDocuments = 'Patient Documents',
    AdmitDischargeHistory = 'Admit/Discharge History',
    Communication = 'Communication',
    Encounter = 'Encounter',
    ActivityOfDailyLiving = 'Activity Of Daily Living',
    ActivityOfDailyLivingScore = 'Activity Of Daily Living Score',
    DiagnosisList = 'Diagnosis List',
    VitalSigns = 'Vital Signs',
    Medications = 'Medications',
    Allergies = 'Allergies',
    MedicalHistory = 'Medical History',
    Coverages = 'Coverages',
    Immunizations = 'Immunizations',
    Imaging = 'Imaging',
    Labs = 'Labs',
    NutritionOrders = 'Nutrition Orders',
    Orders = 'Orders',
    Wounds = 'Wounds',
    PayerInformation = 'Payer Information'
}

type TabsDirection = 'horizontal' | 'vertical';

@Component({
    selector: 'app-patient-detail',
    templateUrl: './patient-detail.component.html',
    styleUrls: ['./patient-detail.component.css']
})
export class PatientDetailComponent implements OnInit, OnDestroy {

    global = global;
    admin = false
    isCompany = false
    company = {};
    loading = false;
    currentUser = null;
    patient = null;
    tabIndex = 0;
    currentRouteAdmin: boolean;
    patientFacilityFromRelation;
    pccApiData: any = {
        'Observation': '',
        'Episode of Care': {},
        'Medications': '',
        'Coverages': '',
        'Conditions': '',
    };
    reloadTab = true;
    pccPayerTypes = ["managedCare", "medicaid", "medicareA", "medicareB", "medicareD", "other", "outpatient", "private"];
    isPCCLoggedIn = false;
    companyId: any;
    dialogSubscription: Subscription;
    tab = 'Patient Notes';
    tabLayout: TabsDirection = 'horizontal';
    updatedArray: any = [];
    savedTabs: any = [];
    should_show_view_icon: boolean = false;
    tabs = [
        { name: 'Patient Notes', is_active: true },
        { name: 'Wounds', is_active: false, },
        { name: 'Patient Documents', is_active: false },
        { name: 'Admit/Discharge History', is_active: false },
        { name: 'Communication', is_active: false },
        { name: 'Diagnosis List', is_active: false },
        { name: 'Encounter', is_active: false },
        // { name: 'Activity Of Daily Living Score', is_active: false },
        { name: 'Activity Of Daily Living', is_active: false },
        { name: 'Appointment', is_active: false },
        { name: 'Vital Signs', is_active: false },
        { name: 'Medications', is_active: false },
        { name: 'Allergies', is_active: false },
        // { name: 'Medical History', is_active: false },
        { name: 'Coverages', is_active: false },
        { name: 'Immunizations', is_active: false },
        { name: 'Imaging', is_active: false },
        { name: 'Labs', is_active: false },
        { name: 'Nutrition Orders', is_active: false },
        { name: 'Orders', is_active: false },
    ];
    matrixTabs = [
        { name: 'Patient Notes', is_active: true },
        { name: 'Wounds', is_active: false, },
        { name: 'Patient Documents', is_active: false },
        { name: 'Admit/Discharge History', is_active: false },
        { name: 'Communication', is_active: false },
        { name: 'Encounter', is_active: false },
        { name: 'Diagnosis List', is_active: false },
        { name: 'Vital Signs', is_active: false },
        { name: 'Medications', is_active: false },
        { name: 'Allergies', is_active: false },
        // { name: 'Medical History', is_active: false },
        { name: 'Coverages', is_active: false },
        { name: 'Immunizations', is_active: false },
        { name: 'Imaging', is_active: false },
        { name: 'Labs', is_active: false },
        { name: 'Nutrition Orders', is_active: false },
        { name: 'Orders', is_active: false },
    ];
    nonPCCtabs = [
        { name: 'Patient Notes', is_active: true },
        { name: 'Wounds', is_active: false, },
        { name: 'Patient Documents', is_active: false },
        { name: 'Diagnosis List', is_active: false },
        { name: 'Admit/Discharge History', is_active: false },
        { name: 'Communication', is_active: false },
        { name: 'Encounter', is_active: false },
        { name: 'Allergies', is_active: false },
        { name: 'Medical History', is_active: false },
        { name: 'Payer Information', is_active: false },
        { name: 'Orders', is_active: false },
    ];
    recentNote: any;
    currentRoute: string;
    outpatientData: any;
    disableButton: boolean;
    previousUrl = '';
    targetRoute = '/appointment-calandar';
    constructor(
        private location: Location,
        private loader: NgxUiLoaderService,
        private _authService: AuthService,
        private _patientService: PatientListService,
        private _pccService: PCCService,
        private _patientDetailService: PatientDetailService,
        private dialog: MatDialog,
        private _router: Router,
        private toastr: ToastrService,
        private commonService: CommonService,
        private facilityService: FacilityService,
        private _patientRoundingSheetService: PatientRoundingSheetService,
        private _noteEditorService: NoteEditorService,
        private previousRouteService: PreviousRouteService,
        private censusPatientListService: CensusPatientListService,
        private userService: UserService,
        private _companyService: CompanyService,
        private _route: ActivatedRoute) {
        this.currentRouteAdmin = false;
        if (window.location.pathname.includes('company')) {
            this.currentRouteAdmin = true;
            this.companyId = this._route.parent.snapshot.params.id;
        }
        else {
            this.companyId = this._authService.currentUser.company_id;
        }
        if (this._authService.currentUser.company_type === this.global.COMPANY_TYPE.NURSING) {
            this.tabs.splice(1, 1);
            this.nonPCCtabs.splice(1, 1);
            this.matrixTabs.splice(1, 1)
        }
    }

    async ngOnInit() {
        this.previousUrl = this.previousRouteService.getPreviousUrl();
        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.currentRoute = event.url;
                console.log('Route changed:', this.currentRoute);
                this.loadPatientData();
                this.refreshTabs()
            }
        });
        await this.getCompany();
        await this.loadPatientData();
    }

    async getCompany() {
        const filter = {
            _id: this.companyId
        };
        const project = {
            name: 1,
            company_type: 1,
            portal_type: 1
        };
        const { status, data }: any = await lastValueFrom(this._companyService.getCompany(filter, project));
        if (status == 200) {
            this.company = data;
        }
        else if (status === 500) {
            this.toastr.error(data.message, "ERROR");
        }
    }

    switchTabsLayout(): void {
        this.tabLayout = (this.tabLayout === 'horizontal') ? 'vertical' : 'horizontal';
        this.userService.updateUIPreferences({ tabs_direction: this.tabLayout }).subscribe();
    }

    async loadOutPatientMenu() {
        const fullmenu = this.commonService.getPatientMenu();
        if ((!fullmenu) || fullmenu.length == 0) {
            this.outpatientData = null;
            return;
        }
        const patient_id = this._route.snapshot.params.id || localStorage.getItem('patient_id');
        this.outpatientData = fullmenu.find(item => item._id.toString() == patient_id.toString());
    }

    async loadPatientData() {
        if (window.location.pathname.includes('admin')) {
            this.admin = true;
        }
        else if (window.location.pathname.includes('company')) {
            this.isCompany = true;
        }
        else {
            this.admin = false;
        }
        console.log('PATIENT in patient details screen:  ', this.patient)
        this.isPCCLoggedIn = !!this._pccService.pccUserinfo
        this._pccService.pccUserinfo$.subscribe(data => {
            this.isPCCLoggedIn = !!data;
        });

        this.currentUser = this._authService.currentUser;
        this.tabLayout = this.currentUser.other_settings?.ui_preferences?.tabs_direction;
        const patient_id = this._route.snapshot.params.id || localStorage.getItem('patient_id');
        const userInfo = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            patient_id
        };
        let relation;

        const patientResponse: any = await this._patientService.getPatient(userInfo).toPromise();
        if (patientResponse.status === 200) {

            await this._patientService.getOnePatient_fromCompFacPatient({ patient_id: patient_id, company_id: this.currentUser.company_id }).subscribe((res: any) => {
                relation = res?.data;
                this.facilityService.getFacility(relation.facility_id).subscribe((response: any) => {
                    if (response.status == 200) {
                        this.patientFacilityFromRelation = response?.data;
                        this.patient.facility_id_ref = response?.data;

                    }
                });
            })

            this.loadOutPatientMenu();

            this.patient = new Patient(patientResponse.data.patient);
            this.recentNote = new Patient(patientResponse.data.recentNote);

            console.log('...patient 2', this.patient);
            if (this.patient.date_of_birth_d) {
                this.patient.date_of_birth_d = this.patient.date_of_birth_d.substr(0, 10)
            }
            // if(this.patient.last_admit_date){
            //     const last_admit_date = moment.tz(this.patient.last_admit_date, "UTC").format('MMM DD, YYYY');
            //     this.patient.last_admit_date = last_admit_date;
            // }
            this.loadPCCApiData();

        }
        await this.initTabs();
        if (this.savedTabs.length != this.tabs.length || this.savedTabs.length != this.matrixTabs.length || this.savedTabs.length != this.nonPCCtabs.length) {
            await this.initTabs();
        }
        if (this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
            this.tabs = this.tabs.filter(tab => tab.name === 'Patient Notes' || tab.name === 'Wounds');
            this.matrixTabs = this.matrixTabs.filter(tab => tab.name === 'Patient Notes' || tab.name === 'Wounds');
            this.nonPCCtabs = this.nonPCCtabs.filter(tab => tab.name === 'Patient Notes' || tab.name === 'Wounds');
        }
        if (this.currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER) {
            this.tabs = this.tabs.filter(tab => tab.name != 'Orders');
            this.matrixTabs = this.matrixTabs.filter(tab => tab.name != 'Orders');
            this.nonPCCtabs = this.nonPCCtabs.filter(tab => tab.name != 'Orders');
        }
    }
    ngOnDestroy() {
        if (this.dialogSubscription) {
            this._patientDetailService.onChangePatientAllergy(null);
            this.dialogSubscription.unsubscribe();
        }
    }

    getLastSeen() {
        let company_id = this.currentUser?.company_id?.toString()
        if (window.location.pathname.includes('company')) {
            this.commonService.castCompany.subscribe(company => {
                if (company) {
                    company_id = company._id.toString();
                }
            })
        }

        let last_seen = '-';
        if (this.patient?.last_seen) {
            if (Array.isArray(this.patient?.last_seen) && this.patient?.last_seen?.length) {

                for (let element of this.patient?.last_seen) {
                    if (element.company_id?.toString().includes(company_id)) {
                        if (element.user_type && element.user_type === global.USER_TYPE.WOUND_NURSE) {
                            last_seen = `<strong>(WN)</strong> ${element.last_seen || ''}`;
                            break;
                        } else {
                            last_seen = element.last_seen || '';
                            break;
                        }
                    }
                };

            } else {
                last_seen = this.patient?.last_seen || '';
            }
        }
        return last_seen;

        //return  this.patient?.last_seen||'';

        // let lastSeen = '';
        // const { recentNote } = this.recentNote;
        // if (recentNote && recentNote.doctor_id_ref) {

        //   const { first_name, last_name } = recentNote.doctor_id_ref;
        //   lastSeen += `${first_name[0]}${last_name[0]}`.toUpperCase();
        //   // console.log("recentNote", recentNote);
        //   // lastSeen += ` - ${moment(new Date(recentNote.filterServiceDate).toLocaleString('en-US', { timeZone: recentNote.timezone })).format('M/D/YYYY')}`;
        //   if (recentNote.date_obj) {
        //     lastSeen += ` - ${this.commonService.dateObjToString(recentNote.date_obj, false)}`;
        //   }
        //   else {
        //     const [year, month, day] = this.commonService.convertDateToStringforMoment(moment(recentNote.filterServiceDate)).split("-");
        //     lastSeen += ` -${month}/${day}/${year}`;
        //   }
        // }
        // return lastSeen;
    }

    isWoundCompany() {
        return this.currentUser.company_type === global.COMPANY_TYPE.WOUND || this.currentUser.company_type === global.COMPANY_TYPE.SNF;
    }

    async loadPCCApiData() {
        const pccPatientID = this.patient.pcc_patientId;
        if (!pccPatientID) return;
    }

    goBack() {
        this.location.back();
    }

    onEditClickAdmin(patientid) {
        this._router.navigate([`/company/${this.companyId}/admin-patient-edit/${patientid}`]);
    }

    async getPatientFacility(patient) {

        if (this.patientFacilityFromRelation) {

            console.log("this.patientFacilityFromRelation.title : : ", this.patientFacilityFromRelation?.title);
            return this.patientFacilityFromRelation?.title;
        }
        else return patient.facility_id_ref?.title
    }

    onEditClick(patient) {
        patient.facility_id_ref = patient.facility_id_ref?._id;

        console.log("patient lc", JSON.stringify(patient));
        localStorage.setItem('editPatient', JSON.stringify(patient));
        this._router.navigate([`/patient-add`]);
    }

    getRecentPatientADTRecord(patient) {
        patient.adtRecords.sort((a, b) => new Date(b.effectiveDateTime).getTime() - new Date(a.effectiveDateTime).getTime())
        return patient.adtRecords[0];
    }
    public tabChange(tab, index, tabs): void {
        this.tab = tab.name;
        tabs.forEach((element, i) => {
            if (index !== i)
                element.is_active = false
        });
        tab.is_active = true;
    }
    drop(event: CdkDragDrop<string[]>) {
        const { previousIndex, currentIndex } = event;
        if (!Number.isNaN(previousIndex) && !Number.isNaN(currentIndex) && previousIndex != undefined && currentIndex != undefined && previousIndex != currentIndex) {
            moveItemInArray(this.tabs, previousIndex, currentIndex);
            this._patientDetailService.addPatientTabSettings(this.tabs, 'pcc').subscribe((Response: any) => {
                if (Response.status == 200) {
                    console.log('Tabs settings Saved');
                }
            });
        }
    }

    dropNonPCC(event: CdkDragDrop<string[]>) {
        const { previousIndex, currentIndex } = event;
        if (!Number.isNaN(previousIndex) && !Number.isNaN(currentIndex) && previousIndex != undefined && currentIndex != undefined && previousIndex != currentIndex) {
            moveItemInArray(this.nonPCCtabs, previousIndex, currentIndex);
            this._patientDetailService.addPatientTabSettings(this.nonPCCtabs, 'nonpcc').subscribe((Response: any) => {
                if (Response.status == 200) {
                    console.log('Tabs settings Saved');
                }
            });
        }
    }
    dropMatrix(event: CdkDragDrop<string[]>) {
        const { previousIndex, currentIndex } = event;
        if (!Number.isNaN(previousIndex) && !Number.isNaN(currentIndex) && previousIndex != undefined && currentIndex != undefined && previousIndex != currentIndex) {
            moveItemInArray(this.nonPCCtabs, previousIndex, currentIndex);
            this._patientDetailService.addPatientTabSettings(this.matrixTabs, 'matrix').subscribe((Response: any) => {
                if (Response.status == 200) {
                    console.log('Tabs settings Saved');
                }
            });
        }
    }
    refreshTabs() {
        this.reloadTabContent()
    }
    // Method to reload the tab content
    reloadTabContent(): void {
        this.reloadTab = false;
        setTimeout(() => {
            this.reloadTab = true;
        }, 2000);
    }
    async initTabs() {
        return new Promise<void>((resolve, reject) => {
            if (this.patient.pcc_patientId) {
                this._patientDetailService.getPatientTabSettings('pcc').subscribe(data => {
                    // @ts-ignore
                    if (data.data.length > 0) {
                        // @ts-ignore
                        let tabsData = data.data;
                        this.savedTabs = tabsData;
                        if (tabsData.length != this.tabs.length) {
                            this._patientDetailService.addPatientTabSettings(this.tabs, 'pcc').subscribe((Response: any) => {
                                if (Response.status == 200) {
                                    console.log('Tabs settings Saved');
                                }
                            });
                            return;
                        }
                        tabsData.forEach(data => {
                            if (data.name === 'Patient Notes') {
                                data.is_active = true;
                                this.tab = data.name;
                            } else {
                                data.is_active = false;
                            }
                            if (data.name.toLowerCase() === 'appointment') {
                                data.name = 'Encounter';
                            }
                        });
                        this.tabs = tabsData;
                    }
                    resolve(null);
                }, error => {
                    reject('error => ' + error.toString());
                });
            }
            if (this.patient.source === 'MatrixCare') {
                this._patientDetailService.getPatientTabSettings('matrix').subscribe(data => {
                    // @ts-ignore
                    if (data.data.length > 0) {
                        // @ts-ignore
                        let tabsData = data.data;
                        this.savedTabs = tabsData;
                        if (tabsData.length != this.matrixTabs.length) {
                            this._patientDetailService.addPatientTabSettings(this.matrixTabs, 'matrix').subscribe((Response: any) => {
                                if (Response.status == 200) {
                                    console.log('Tabs settings Saved');
                                }
                            });
                            return;
                        }
                        tabsData.forEach(data => {
                            if (data.name === 'Patient Notes') {
                                data.is_active = true;
                                this.tab = data.name;
                            } else {
                                data.is_active = false;
                            }
                            if (data.name.toLowerCase() === 'appointment') {
                                data.name = 'Encounter';
                            }
                        });
                        this.matrixTabs = tabsData;
                    }
                    resolve(null);
                }, error => {
                    reject('error => ' + error.toString());
                });
            }
            if (!this.patient.pcc_patientId) {
                this._patientDetailService.getPatientTabSettings('nonpcc').subscribe(data => {
                    // @ts-ignore
                    if (data.data.length > 0) {
                        // @ts-ignore
                        let tabsData = data.data;
                        this.savedTabs = tabsData;
                        if (tabsData.length != this.nonPCCtabs.length) {
                            this._patientDetailService.addPatientTabSettings(this.nonPCCtabs, 'nonpcc').subscribe((Response: any) => {
                                if (Response.status == 200) {
                                    console.log('Tabs settings Saved');
                                }
                            });
                            return;
                        }
                        tabsData.forEach(data => {
                            if (data.name === 'Patient Notes') {
                                data.is_active = true;
                                this.tab = data.name;
                            } else {
                                data.is_active = false;
                            }
                            if (data.name.toLowerCase() === 'appointment') {
                                data.name = 'Encounter';
                            }
                        });
                        this.nonPCCtabs = tabsData;
                    }
                    resolve(null);
                }, error => {
                    reject('error => ' + error.toString());
                });
            }

        });
    }
    openCreatePaitentAllergyDialog() {
        let options: any = {
            width: '50%',
            height: '45vh',
            data: {
                patientId: this.patient._id
            }
        }
        this.dialogSubscription = this.dialog.open(CreateNonPccAllergyIntolerancesDialogComponent, {
            ...options,
        }).afterClosed().subscribe();
    }

    async getCCD(patient) {
        // console.log("in get CCD", patient)
        let obj = {
            _id: patient._id,
            gender: patient.gender,
            first_name: patient.first_name,
            middle_name: patient.middle_name,
            last_name: patient.last_name,
            date_of_birth: patient.date_of_birth,
            ssn: patient.patient_ssn,
            facility_id_ref: patient.facility_id_ref._id,
            facility_external_id: patient.facility_id_ref.external_id,
            matrix_payload: {
                patient_id: patient.matrix_payload.patient_id || "",
            }
        }
        let res: any = await lastValueFrom(this._patientDetailService.getCCD(obj)) as any;
        console.log("in CCD : ", res)
    }

    openOrderTab(): void {
        if (this.patient.pcc_patientId) {
            const orderTab = this.tabs.find(tab => tab.name === PatientTab.Orders);
            const index = this.tabs.findIndex(tab => tab.name === PatientTab.Orders);
            this.tabChange(orderTab, index, this.tabs)
        }
        if (!this.patient.pcc_patientId && this.patient.source !== 'MatrixCare') {
            const orderTab = this.nonPCCtabs.find(tab => tab.name === PatientTab.Orders);
            const index = this.nonPCCtabs.findIndex(tab => tab.name === PatientTab.Orders);
            this.tabChange(orderTab, index, this.nonPCCtabs)
        } if (this.patient.source == 'MatrixCare') {
            const orderTab = this.matrixTabs.find(tab => tab.name === PatientTab.Orders);
            const index = this.matrixTabs.findIndex(tab => tab.name === PatientTab.Orders);
            this.tabChange(orderTab, index, this.matrixTabs)
        }
    }
    async createNoteClick() {
        this.outpatientData.facility_id = this.patient.facility_id_ref._id;
        if (!this.outpatientData.date_of_service) {
            this.toastr.warning('No appointment scheduled');
            return;
        }
        if (!this.disableButton) {
            await this.addPatientRoundingSheet();
        }
        this.disableButton = true;
        setTimeout(() => {
            this.disableButton = false;
        }, 2500);
        console.log("ot pt dt : ", this.outpatientData);
        // return;


    }

    createNote(patient_id, facility, rounding_sheet_id, provider, appointment_id = null) {
        console.log("Appointment", appointment_id);
        return new Promise<void>((resolve, reject) => {
            // const dateObj = this.commonService.splitMomentToObj(moment());
            let user_type = 'Other'
            const currentDate = new Date();
            let note_date_obj = {
                day: this.outpatientData.date_obj.day,
                month: this.outpatientData.date_obj.month,
                year: this.outpatientData.date_obj.year,
                hour: currentDate.getHours(),
                minute: currentDate.getMinutes(),
            }
            const note = {
                patient: { _id: patient_id },
                facility: { _id: facility },
                rounding_sheet_id,
                date_obj: note_date_obj,
                htmlText: "No Text",
                title: "No Title",
                dates: { creation: new Date(), service: new Date() },
                provider: { _id: provider },
                uploadedBy: { _id: this.currentUser._id },
                user_type: user_type,
                outPatient: true,
                appointment_id: appointment_id
            };
            this._noteEditorService.saveNote(note).subscribe((response: any) => {
                if (response.status === 200) {
                    this._router.navigate(['/note-editor/', response.data.noteId])
                } else if (response.status === 409) {
                    this.toastr.info('Note already exists');
                    this._router.navigate(['/note-editor/', response.data._id]);
                } else {
                    this.toastr.error('Error creating note');
                }
            });
        })
    }


    async addPatientRoundingSheet() {
        console.log(this.outpatientData);
        this.toastr.info('Creating Note...');
        const noteId: any = await this.createNote(
            this.outpatientData._id,
            this.outpatientData.facility_id,
            this.outpatientData.census_id,
            this.outpatientData.provider_id,
            this.outpatientData.appointment_id
        );

    }
    isPreviousRoute(previousUrl, targetRoute) {
        return previousUrl === targetRoute;
    }

    isPermittedFor(permission) {
        return this.currentUser.permissions?.find(permissions => (permissions.key === permission)) != null;
    }

    private redirectToScriptSureModule(patientId) {
        const currentUrl = this._router.url;

        this._router.navigate(['/patient-scriptsure', patientId], {
            queryParams: {
                from: currentUrl
            }
        });
    }

    async openScriptSureModule(patient) {
        patient.isScriptSureLoading = true;
        const response: any = await this.censusPatientListService.checkPatientComplianceWithScriptSure(patient._id);
        patient.isScriptSureLoading = false;

        if (response.status !== 200) {
            this.toastr.error("Something Went Wrong")
            return;
        }

        if (response.data.isCompliant === false) {
            this.toastr.error(response.data.error, null, {
                positionClass: 'toast-center-center',
                timeOut: 3000,
                closeButton: true
            });

            return;
        }
        if (response.data.patient.download_drug_history_consent == null) {
            const dialogRef = this.dialog.open(DrugHistoryConsentDialog);

            dialogRef.afterClosed().subscribe(async result => {
                if (result && result.saveClick === true) {
                    patient.isScriptSureLoading = true;
                    const updateResponse: any = await this.censusPatientListService.updatePatientDrugHistoryConsent(patient._id, result.consentGiven);

                    if (updateResponse.status !== 200) {
                        patient.isScriptSureLoading = false;
                        this.toastr.error("Something Went Wrong")
                        return;
                    }

                    this.redirectToScriptSureModule(patient._id);
                }
            });

            return;
        }

        this.redirectToScriptSureModule(patient._id);
    }
    protected readonly PatientTab = PatientTab;
}
