<div class="wound-image">
    <h1 mat-dialog-title style="text-align: center;">Last Update : {{wound.updated_At}}</h1>
    <div class="image-wrapper">
        <button
            mat-icon-button
            (click)="navigateImage(-1)"
            class="nav-arrow nav-arrow-left"
            *ngIf="hasNavigateImage(-1)"
        >
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <div
            class="image"
            [style.backgroundImage]="'url(' + getImageUrl(0) + ')'"
        ></div>
        <button
            mat-icon-button
            (click)="navigateImage(1)"
            class="nav-arrow nav-arrow-right"
            *ngIf="hasNavigateImage(1)"
        >
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
    </div>
</div>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>
