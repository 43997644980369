import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { UserService } from 'src/app/services/Modules/user.service';
import * as global from '../../includes/global';
import { MatTableDataSource } from '@angular/material/table';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ViewOnlyUsersService } from 'src/app/services/Modules/view-only-users.service';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-view-only-users-provider-association',
  templateUrl: './view-only-users-provider-association.component.html',
  styleUrls: ['./view-only-users-provider-association.component.css']
})
export class ViewOnlyUsersProviderAssociationComponent implements OnInit {
  global = global;
  providers: any = [];
  users: any = [];
  companyId = '';
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = ['users', 'providers'];
  providerDropdownSettings: any = {};
  relations: any = [];
  loaderId: string = "app-view-only-users-provider-association";
  currentCompany : any = {};
  orignalData: any = [];
  searchProviderText: any;

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private loader: NgxUiLoaderService,
    private viewOnlyUsersService: ViewOnlyUsersService,
    private commonService : CommonService
  ) { 

    this.providerDropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'full_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: true,
    };

  }

  async ngOnInit() {
    this.currentCompany = this.commonService.getLocalStorageItem('admin-Dashboard');
    this.companyId = this.route.parent.snapshot.params.id;
    this.loader.startLoader(this.loaderId);
    await this.initProviders();
    await this.initAllViewOnlyUsers();
    await this.loadRelations();
    this.loader.stopLoader(this.loaderId);
  }
 async initProviders() {
    let filter = { company_id : this.companyId, user_type : this.currentCompany.currentCompanyType === global.COMPANY_TYPE.SNF ? global.USER_TYPE.SNF_WC_NURSE : global.USER_TYPE.DOCTOR, is_activated : 'true' };
    let projection = { first_name : 1, last_name : 1, title : 1 , user_type : 1 };
    const usersResponse : any = await lastValueFrom(this.userService.getUsers(filter,projection));
    if(usersResponse.status == 200) {
      this.providers = usersResponse.data;
      this.providers.forEach(provider => {
        provider.full_name = provider.first_name + ' ' + provider.last_name + ' ' + provider.title; 
      });
    }else {
      this.toastr.warning(usersResponse.message);
    }
  }

  async initAllViewOnlyUsers() {
    let filter = { company_id : this.companyId, user_type : global.USER_TYPE.VIEW_ONLY_USER, is_activated : 'true' };
    let projection = { first_name : 1, last_name : 1, title : 1 , user_type : 1 };
    const usersResponse : any = await lastValueFrom(this.userService.getUsers(filter,projection));
    if(usersResponse.status == 200) {
      this.users = usersResponse.data;
      this.dataSource.data = this.users;
    }else {
      this.toastr.warning(usersResponse.message);
    }
  }

  async loadRelations() {
    let filter = { company_id : this.companyId };
    const relations : any = await lastValueFrom(this.viewOnlyUsersService.getViewOnlyUsersProviderRelation(filter));
    if(relations.status == 200) {
      this.relations = relations.data;
      this.users.forEach(user => {
        user.associated_provider_ids = [];
        this.relations.forEach(relation => {
          if(user._id === relation.user_id) {
            user.associated_provider_ids = relation.associated_provider_ids;
          }
        });
      });
      this.orignalData = JSON.parse(JSON.stringify(this.users));
    }
  }

  async onProviderSelect($event, element) {
        
    const filter = { user_id : element._id, company_id : this.companyId };
    const data = { associated_provider_ids: $event._id };
    // element.associated_provider_ids = [...element.associated_provider_ids, $event]
    const relationResponse : any = await lastValueFrom(this.viewOnlyUsersService.updateViewOnlyUsersProviderRelation(filter, data));
    if (relationResponse.status == 200) {
      this.toastr.success("Relation Updated");
    }
    else {
      this.toastr.error(relationResponse.message);
    }
          
        
        
      
    
  }

  onDataSelection($event) {
    // let providers = element.associated_provider_ids.map(a => a._id);
    // let idx = this.users.findIndex(user => user._id.toString == element._id.toString());
    // this.users[idx] = element;
    // this.dataSource.data = this.users;
    if($event.data == 'selectAll') {
      this.onSelectAllProviders($event.element.associated_provider_ids, $event.element);

    }
    else if($event.data == 'unSelect') {
      this.onDeSelectAllProviders([], $event.element);
    }
    else {
      if(!$event.status) {
        this.onProviderDeSelect($event.data, $event.element);
      }
      else {
        this.onProviderSelect($event.data, $event.element);
      }
    }
  }

  async onProviderDeSelect($event, element) {
    const filter = { user_id : element._id, company_id : this.companyId };
    const data = { associated_provider_ids: $event._id };
    let isRemoving: boolean = true;
    element.associated_provider_ids = element.associated_provider_ids.filter(nurse => nurse._id !== $event._id);
    for(let nurse of this.orignalData) {
      if(nurse._id.toString() == element._id.toString()) {
        nurse.associated_provider_ids = nurse.associated_provider_ids.filter(nurse => nurse._id !== $event._id);
      }
    }
    const relationResponse : any = await lastValueFrom(this.viewOnlyUsersService.updateViewOnlyUsersProviderRelation(filter, data, isRemoving));
      if (relationResponse.status == 200) {
        this.toastr.success("Relation Updated");
      }
      else {
        this.toastr.error(relationResponse.message);
      }
  }

  async onSelectAllProviders($event, element) {
    const filter = { user_id : element._id, company_id : this.companyId };
    const data = { associated_provider_ids: $event };
    
    const relationResponse : any = await lastValueFrom(this.viewOnlyUsersService.updateViewOnlyUsersProviderRelation(filter, data));
      if (relationResponse.status == 200) {
        this.toastr.success("Relations Updated");
      }
      else {
        this.toastr.error(relationResponse.message);
      }
  }

  onDataRemove($event) {
    this.onProviderDeSelect($event.data, $event.element);
  }

  async onDeSelectAllProviders($event, element) {
    const filter = { user_id : element._id, company_id : this.companyId };
    const data = { associated_provider_ids: $event };
    let isRemoving: boolean = true;
    
    const relationResponse : any = await lastValueFrom(this.viewOnlyUsersService.updateViewOnlyUsersProviderRelation(filter, data, isRemoving));
      if (relationResponse.status == 200) {
        this.toastr.success("Relations Updated");
      }
      else {
        this.toastr.error(relationResponse.message);
      }
  }

  applyFilter(filterValue: string) {
    let filter = filterValue.trim().toLowerCase();
    if (filter) {
      let filterVal = [];
      this.users.forEach(user => {
        if (user.full_name.trim().toLowerCase().includes(filter)) {
          filterVal.push(user)
          this.dataSource = filterVal;
        }
      });
    }
    else {
      this.dataSource = this.users;
    }
  }

  compareProviders(provider1: any, provider2: any): boolean {
    return provider1 && provider2 ? provider1._id === provider2._id : provider1 === provider2;
  }

}
