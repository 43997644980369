import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PatientDetailService } from '../patient-detail.service';

@Component({
  selector: 'app-patient-diagnosis',
  templateUrl: './patient-diagnosis.component.html',
  styleUrls: ['./patient-diagnosis.component.css']
})
export class PatientDiagnosisComponent implements OnInit {
  @Input('showCheckboxes') showCheckboxes = false;
  @Input('patient') patient;
  @Input('selectedDiagnoses') selectedDiagnoses;


  loaderId = 'dn-conditions-loader';
  inprogress = false;
  selectedSidebarICDs: any[] = [];
  
  displayedColumns = [
    "date",
    "code",
    "diagnosis",
  ];  
  apiData: any;
  dataSource: any;
  constructor( 
    private loader: NgxUiLoaderService,
    private service: PatientDetailService) { }


  ngOnInit(): void {
    console.log("patient in matrix diagnosis-------: ", this.patient);

    this.loadData();  

  }

  async loadData() {
    this.loader.startLoader(this.loaderId);
    this.apiData = this.patient.dn_diagnosis;
    this.loader.stopLoader(this.loaderId);
    this.dataSource = new MatTableDataSource(this.apiData);

  }

}
