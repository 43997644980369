import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../../../includes/global';
import ClassicEditor from '../ckeditor';

type EditorModel = {
  editorData: string,
  disabled?: boolean,

  config?: unknown
}

@Component({
  selector: 'app-note-editor-v2',
  templateUrl: './note-editor-v2.component.html',
  styleUrls: ['./note-editor-v2.component.css']
})
export class NoteEditorV2Component implements OnInit {
  currentUser: any;
  global = global;
  public Editor = ClassicEditor;


  model:EditorModel = {
    editorData: ''
  }
  constructor(private _authService: AuthService) { 

    this.currentUser = this._authService.currentUser;
  }

  ngOnInit(): void {
  }

}
