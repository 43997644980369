export class DoctorProfileInformation {
    user_id_ref:string|object;
    user_type:string;
    first_name:string;
    middle_name:string;
    last_name:string;
    pcc_name:string;
    title:string;
    degree:'MD'|'OD'|'';
    office_phone:string;
    secondary_phone:string;
    cell_phone:string;
    secondary_email_address:string;
    work_email:string;
    personal_email:string;
    company_name:string;
    company_id:string;
    address:string;
    ein:string;
    routing_number:string;
    bank_account_number:string;
    ssn:string;
    dob:Date;
    npi:string;
    tin: string;
    birth_city:string;
    birth_state:string;
    birth_country:string;
    speciality:string;
    // ecfmg_date:string;
    caqh_username:string;
    caqh_password:string;
    board_name:string;
    certificate_number:string;
    sex:'male'|'female'|'';
    trueRCMID: string;
    supervisingProvider: string;
    supervisingProviderTrueRCMID: string;
}