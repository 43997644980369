import {Component, OnInit, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {AddAppointmentComponent, AddAttributeComponent, AddPayorComponent} from '../..';
import {FacilityService} from '../../../../services/facility.service';
import {AppointmentDataService} from '../../service/data.service';
import {ToastrService} from 'ngx-toastr';
import {PatientListService} from '../../../../includes/patient-list/patient-list-service';
import {AuthService} from '../../../../services/auth.service';
import {TranscriptionCreateService} from '../../../../includes/transcription-create/transcription-create.service';
import { connect } from 'twilio-video';
import {MatDialog} from "@angular/material/dialog";
import * as global from "../../../../includes/global";
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
@Component({
    selector: 'app-user-appointment-filter',
    templateUrl: './filter.template.html'
})
export class UserFilterComponent implements OnInit {
    providers = [];
    facilities = [];
    patients = [];
    // payors = [];
    filters = {
        facility_id: undefined,
        patient_id: undefined,
        payor_id: undefined,
        payor_ids: [],
        provider_id: undefined
    };
    selectedProvider = undefined;
    selectedFacility = undefined;
    
    calenderSelectedValue: Date = new Date();
    
    @Output() dateSelection = new EventEmitter();
    
    
    @ViewChild('payorInput') payorInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;

    separatorKeysCodes: number[] = [ENTER, COMMA];
    payorCtrl = new FormControl();
    filteredpayors: Observable<any[]>;
    payors = [];
    allpayors = [];
    // payorDropdownSettings = {
    //     singleSelection: false,
    //     idField: '_id',
    //     textField: 'abbreviation',
    //     allowSearchFilter: true,
    // };

    constructor(private dialog: MatDialog,
                private facilityService: FacilityService,
                private service: AppointmentDataService,
                private toastr: ToastrService,
                private authService: AuthService,
                private transcriptionService: TranscriptionCreateService,
                private patientService: PatientListService) {
                    this.filteredpayors = this.payorCtrl.valueChanges.pipe(
                        startWith(''),
                        map((filterValue: string | null) => filterValue ? this._filterPayor(filterValue) : this.allpayors.slice()));
                
    }

    handlePayorInputFocus(event) {
        this.payorCtrl.setValue(event.target.value);
        
    } 
    addPayorAsChip(event: MatChipInputEvent): void {
        const input = event.input;
        const value:any = event.value;

        // Add our payor
        this.payors.push(value);
        this.filters.payor_ids = this.payors.map(p => p._id);
        this.service.setFilter(this.filters);

        // Reset the input value
        if (input) {
        input.value = '';
        }

        this.payorCtrl.setValue(null);
    }

    removePayorAsChip(payor: any): void {
        const index = this.payors.indexOf(payor);

        if (index >= 0) {
            this.payors.splice(index, 1);
            
            this.filters.payor_ids = this.payors.map(p => p._id);
            this.service.setFilter(this.filters);
        }
    }

    selectedPayorAsChip(event: MatAutocompleteSelectedEvent): void {
        this.payors.push(event.option.value);
        
        this.filters.payor_ids = this.payors.map(p => p._id);
        this.service.setFilter(this.filters);
        
        this.payorInput.nativeElement.value = '';
        this.payorCtrl.setValue(null);
    }

    private _filterPayor(value: any): any[] {
        console.log({
            typeof: typeof value,
            value
        });
        
        const filterValue = typeof value === 'string' ? value.toLowerCase() : value;

        return this.allpayors.filter((payor:any) => payor.abbreviation.toLowerCase().indexOf(filterValue) === 0);
        
    }
    async providerChange(provider_id) {
        //
        this.filters.provider_id = provider_id.value;
        await Promise.all([this.loadFacilities(), this.loadPatients()])
        this.service.setFilter(this.filters);
    }

    facilityChange(facility_id) {
        //
        this.filters.facility_id = facility_id.value;
        this.service.setFilter(this.filters);
    }

    patientChange(patient_id) {
        
        if (patient_id.value === 'All') {
            this.filters.patient_id = undefined;
        } else {
            this.filters.patient_id = patient_id.value;
        }
        this.service.setFilter(this.filters);
    }

    payorChange(payor_id) {
        
        if (payor_id.value === 'All') {
            this.filters.payor_id = undefined;
        } else {
            this.filters.payor_id = payor_id.value;
        }
        this.service.setFilter(this.filters);
    }

    async ngOnInit() {
        try {
            await this.getDoctors();
            this.loadData();
        } catch (e) {
            this.toastr.error('something went wrong in filters ', 'Error');
        }
    }
    async loadData() {
        // await this.loadFacilities();
        this.service.getPayors().subscribe(data => {
            this.payors = data.data.array;
        }, error => {
        });
        await this.loadPatients();
    }

    async loadFacilities() {
        try {
            const data = await this.facilityService.getMyFacilities(this.filters.provider_id).toPromise()
            // @ts-ignore
            this.facilities = data.data.array;
        } catch(error) {
        }
    }
    async loadPatients() {
        try {
            const data = await this.service.getProviderPatients({provider_id: this.filters.provider_id}).toPromise();
            // @ts-ignore
            this.patients = data.data.array;
        }catch(error) {
        }
    }
    getDoctors() {
        return new Promise<void>((resolve, reject)=> {
            this.transcriptionService.getAllProviders().subscribe(data => {
                //
                // @ts-ignore
                this.providers = data.data.array;
                this.selectedProvider = this.providers[0]._id;
                this.filters.provider_id = this.providers[0]._id;
                resolve(null);
            }, error => {
                reject();
            });
        });
    }
    schduleBtnClick() {
        this.service.sheduleBtnClick = {is_add_view: true};
    }
    goToQuickCall() {
        window.open(`${global.base_url}/meeting/quick-call?room_id=${this.authService.authObject.id}`, '_blank');
    }
    
    handlePayorSelect(e) {
        if(this.filters.payor_ids.indexOf(e._id) < 0) {
            this.filters.payor_ids.push(e._id)
            this.service.setFilter(this.filters);

        }
    }
    handleSelectAllPayor(e) {        
        this.filters.payor_ids = e.map(payor => payor._id);
        this.service.setFilter(this.filters);
    }
    handleDeSelectAllPayor(e) {
        this.filters.payor_ids = [];
        this.service.setFilter(this.filters);

    }
    handlePayorDeSelect(e) {
        const index = this.filters.payor_ids.findIndex(payorId => payorId==e._id);
        if(index >= 0) {
            this.filters.payor_ids.splice(index, 1);
            this.service.setFilter(this.filters);
        }
    }
}
