import { AfterViewInit, Component, DoCheck, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment'
import { NoteAuditoryHistoryService } from 'src/app/includes/note-auditory-history/note-auditory-history.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.css']
})
export class ReportDetailComponent implements OnInit, DoCheck {
  // displayedColumns = ['title', 'facility', 'patient', 'dos', 'noteStatus']
  displayedColumns = ['sr','date','dos', 'patient', 'facility','creator','report_type']
  @Input() detailData: any;
  @Input() applyFilter: any;
  noteData: any;
  dataSource: any;
  facilityFilter:any;

  constructor(
    private auditoryService: NoteAuditoryHistoryService,
    private commonService: CommonService,
  ) { } 
  ngDoCheck(): void {
    // throw new Error('Method not implemented.');
    this.dataSource = new MatTableDataSource(this.detailData);
  } 

  ngOnInit() { 
    // this.facilityFilter = this.applyFilter;
    this.noteData = this.detailData.slice(); 
    
    // this.facilitiesData = this.facilities; 
    this.dataSource = new MatTableDataSource(this.detailData);
  }
  getPatientName(note) {
    if (note && note.patient_id_ref) {
      return note.patient_id_ref.last_name + ' ' + note.patient_id_ref.first_name;
    }
    else return '-'
  }
  getDate(element){
    if(element){
      return moment(new Date(element.filterCreationDate).toLocaleString('en-US', { timeZone: element.timezone })).format('MMM DD, YYYY h:mm a');
    }
    else{
      return '';
    }
  } 
  getServiceDate(trans) {
    if (trans) { 
      if (trans.filterServiceDate) { 
        return moment(new Date(trans.filterServiceDate).toLocaleString('en-US', { timeZone: trans.timezone })).format('MMM DD, YYYY h:mm a');
      }
      else return '-';
    }
  }
  getNoteStatus(status) {
    return this.auditoryService.formatOperation(status).toUpperCase();
  }
  getCreatorName(element){
    return element.uploaded_by_id_ref.first_name + ' ' + element.uploaded_by_id_ref.last_name;
  }
}
