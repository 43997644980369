<div class="patient-notes-dialog flex gap-5"
>
    <div class="flex-grow patient-notes-view flex flex-col">
        <div class="flex justify-between items-center">
            <h1 mat-dialog-title  style="margin: 0;">Comment</h1>
        </div>
        <br />
        <form (submit)="$event.preventDefault(); handleSubmitForm()" class="flex flex-col flex-grow" *ngIf="!loading">
            <mat-form-field style="width:100%" class="flex-grow">
                <mat-label>Note</mat-label>
               <b> {{userInitials}} </b>
                <textarea class="comment-textarea"
                matInput placeholder="Comment" name="note_detal" #note_detail="ngModel"
                [(ngModel)]="activePatientNote.note_detail" required></textarea>
            </mat-form-field>
            <div class="flex gap-2 justify-end items-center" style="padding-bottom: 5px;">
                <button mat-raised-button class="dn-button-primary" type="submit">Save</button>
            </div>
        </form>
        <p *ngIf="loading" style="font-size: 12px;">Loading</p>
    </div>
</div>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>