<ng-container *ngIf="patientMedicalHistoryDataset">
    <div class="columns-main flex gap-2">
        <div class="columns flex gap-2">
            <div class="column flex-grow" *ngFor="let column of patientMedicalHistoryDataset">
                <div class="column-header">{{ column.header }}</div>
                <div class="rows flex flex-col">
                   
                    <div class="row" *ngFor="let data of column.rows">
                        <div class="item" 
                        [class.selected]="isSelectedItem(column._id, data.value)"
                        (click)="selectItem(column._id, data.value)"
                        >
                            <ng-container *ngIf="data.data_type === 'Text'">
                                {{ data.value }}
                            </ng-container>
                            <ng-container *ngIf="data.data_type === 'Dropdown'">
                                <mat-select>
                                    <mat-option *ngFor="let item of data.value">{{ item }}</mat-option>
                                </mat-select>
                            </ng-container>
                            <ng-container *ngIf="data.data_type === 'Date'">
                                {{ data.value | date: 'MM/mm/yyyy' }}
                            </ng-container>
                        </div>
                    </div>
                  
                </div>

                    <ng-container>
                        <span>
                            <mat-chip-list #chipList aria-label="Data selection">
                                <div *ngIf="column.free_text.length > 0">
                                    <mat-chip *ngFor="let item of column.free_text" (removed)="removeFreeTextField(item,column)"
                                        class="freeText" [removeable]="true" matTooltip='{{item.value}}' matTooltipPosition="above">
                                        <div class="item" [class.selected]="isSelectedItem(column._id, item.value)"
                                            (click)="selectItem(column._id, item.value)">
                                            {{item.value.length >= 13 ? item.value.substring(0,12).concat("...") : item.value}}
                                        </div>
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                </div>
                                <mat-form-field style="width: 100%; margin: 1rem;" appearance="outline" floatLabel="never">
                                    <input style="width: 96%" id="{{column._id}}" matInput [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        (matChipInputTokenEnd)="addNewFreeTextField($event,column)">
                                </mat-form-field>
                            </mat-chip-list>
                        </span>
                        </ng-container>
            </div>

        </div>
    </div>
</ng-container>