<div style="width: 100%; text-align: left">
    <h2>Wound Assessment Report</h2>
</div>
<table class="format" border="0" style="width: 100%;">
    <tr>
        <td class="td-width3"><strong>Wound No:</strong></td>
        <td>{{wound.woundNo}}</td>
    </tr>
    <tr>
        <td class="td-width3"><strong>Name:</strong></td>
        <td>{{patient.last_name}},&nbsp; {{patient.first_name}}</td>
    </tr>

    <tr>
        <td class="td-width3"><strong>DOB:</strong></td>
        <td>{{patient.date_of_birth}}</td>

    </tr>
    <tr>
        <td class="td-width3"><strong>Wound Evaluation Date:</strong></td>
        <td>{{wound.last_assessment_date | date: 'dd/MM/yyyy'}}</td>
    </tr>
    <tr>
        <td class="td-width3"><strong>Location:</strong></td>
        <td>{{wound.body_part || ""}}</td>

    </tr>

</table>
<table class="format" border="0" style="width: 100%">
    <tr>
        <td colspan="2">

            <h3 style="background-color: #23406D; color: #ffffff;">&nbsp;&nbsp;Measurements</h3>
        </td>
    </tr>
    <tr>
        <td class="td-width3"><strong>Length:</strong></td>
        <td>{{(wound.length).toFixed(2)}} cm</td>
    </tr>
    <tr>
        <td class="td-width3"><strong>Width:</strong></td>
        <td>{{(wound.width).toFixed(2)}} cm</td>
    </tr>
    <tr>
        <td class="td-width3"><strong>L x W:</strong></td>
        <td>{{(wound.length * wound.width).toFixed(2)}} cm<sup>2</sup></td>
    </tr>
    <tr>
        <td class="td-width3"><strong>Depth:</strong></td>
        <td>{{(wound.depth).toFixed(2)}} cm</td>
    </tr>

</table>



<table class="format" border="0" style="width: 100%">
    <tr>
        <td colspan="2">
            <h3 style="background-color: #23406D; color: #ffffff;">&nbsp;&nbsp;Observations</h3>
        </td>
    </tr>
    <tr>
        <td class="td-width3"><strong>Location:</strong></td>
        <td>{{wound.body_part || ""}}</td>
    </tr>
    <tr>
        <td class="td-width3"><strong>Etiology:</strong></td>
        <td>{{wound.etiolgy || ''}}</td>
    </tr>
    
    <tr>
        <td class="td-width3"><strong>Stage/Severity:</strong></td>
        <td>{{wound.stage || ''}}</td>
    </tr>
    <tr>
        <td class="td-width3"><strong>Acquired in House:</strong></td>
        <td>
            <ng-container *ngIf="wound.wound_recognition_date_type == 'facility_aquired'">Yes</ng-container> 
            <ng-container *ngIf="wound.wound_recognition_date_type == 'present_on_admission'">No</ng-container>
            <ng-container *ngIf="!wound.wound_recognition_date_type"></ng-container>
        </td>
    </tr>
    <tr>
        <td class="td-width3"><strong>Date Wound Acquired:</strong></td>
        <td *ngIf="wound.wound_recognition_date_type === 'facility_aquired' && !wound.wound_recognition_undetermined">{{getFormattedDate(wound.wound_recognition_date)}}</td>
        <td *ngIf="wound.wound_recognition_undetermined && wound.wound_recognition_date_type === 'facility_aquired'">Undetermined</td>
        <td *ngIf="wound.wound_recognition_date_type !== 'facility_aquired' && (!wound.wound_recognition_undetermined || wound.wound_recognition_undetermined)">{{getFormattedDate(wound.wound_recognition_date)}}</td>
    </tr>
    <tr>
        <td class="td-width3"><strong>Wound Status:</strong></td>
        <td>{{wound.wound_status || ''}}</td>
    </tr>

    <tr>
        <td class="td-width3"><strong>Undermining:</strong></td>
        <td>{{getUndermining(wound)}}</td>
    </tr>

    <tr>
        <td class="td-width3"><strong>Tunneling:</strong></td>
        <td>{{getTunneling(wound)}}</td>
    </tr>
    
    <tr>
        <td class="td-width3"><strong>% Epithelial:</strong></td>
        <td>{{wound.epithelial || ''}}</td>
    </tr>
    <tr>
        <td class="td-width3"><strong>% Granulation:</strong></td>
        <td>{{wound.granulation || ''}}</td>
    </tr>
    <tr>
        <td class="td-width3"><strong>% Slough:</strong></td>
        <td>{{wound.slough || ''}}</td>
    </tr>
    <tr>
        <td class="td-width3"><strong>% Eschar:</strong></td>
        <td>{{wound.eschar || ''}}</td>
    </tr>
    
    <tr>
        <td class="td-width3"><strong>Exposed Tissue:</strong></td>
        <td>{{wound.exposed_tissues || ''}}</td>
    </tr>
    <tr>
        <td class="td-width3"><strong>Wound Edges:</strong></td>
        <td>{{wound.exposed_structure || ''}}</td>
    </tr>
    <tr>
        <td class="td-width3"><strong>Periwound:</strong></td>
        <td>{{wound.periwound || ""}}</td>
    </tr>
    <tr>
        <td class="td-width3"><strong>Exudate Amount:</strong></td>
        <td>{{wound.exudate || ''}}</td>
    </tr>
    <tr>
        <td class="td-width3"><strong>Exudate Description:</strong></td>
        <td>{{wound.exudate_type || ''}}</td>
    </tr>
    <tr>
        <td class="td-width3"><strong>Odor Post Cleansing:</strong></td>
        <td>{{wound.odor || ""}}</td>
    </tr>

</table>

<table class="format" border="0" style="width: 100%">
    <tr>
        <td colspan="2">

            <h3 style="background-color: #23406D; color: #ffffff;">&nbsp;&nbsp;Treatment</h3>

        </td>
    </tr>
    <tr>
        <td class="td-width3"><strong>Dressing Change Frequency:</strong></td>
        <td>{{wound.change_frequency || ""}}</td>
    </tr>
    <tr>
        <td class="td-width3"><strong>Clean Wound With:</strong></td>
        <td>{{wound.cleanse_wound_with || ""}}</td>
    </tr>

    <tr>
        <td class="td-width3"><strong>Primary Treatment:</strong></td>
        <td>{{wound.primary_treatment || ""}}</td>
        
    </tr>
    <tr>
        <td class="td-width3"><strong>Other Dressings:</strong></td>
        <td>{{wound.secondary_dressing || ""}}</td>
    </tr>
</table>