<div style="height: calc(100vh - 130px);overflow-y: auto;" >
    <h3 style="    text-align: center; margin-top: 3rem">Upload Charges as HL7 in {{company ? company.name : 'N/A'}}</h3>
    <form (ngSubmit)="onSubmit()" style="display: flex;">
        <div style="margin-top: 4rem; margin-left: 10rem; margin-left: 10rem; display: flex;align-items: center;">
            <mat-form-field appearance="fill">
                <mat-label>Select Id</mat-label>
                <mat-select [(ngModel)]="selectedId" name="id">
                    <mat-option *ngFor="let practiceId of ids" [value]="practiceId.id">
                        {{practiceId.viewId}}
                    </mat-option>
                </mat-select>

            </mat-form-field>
        </div>
        <div style="margin-top:4rem; margin-left: 10rem;  display: flex;align-items: center;" class="datepicker">

            <mat-form-field appearance="fill">
                <mat-label style="margin-right:20px;color:#999">Date </mat-label>
                <input matInput type="text" ngxDaterangepickerMd [(ngModel)]="date" name="date" class="mat-like-input"
                    [ranges]="preDateRanges" [alwaysShowCalendars]="true" [maxDate]="this.current_date"
                    [linkedCalendars]="true" [showClearButton]="true" />
            </mat-form-field>

        </div>


        <button class="theme-btn" type="submit" mat-raised-button class="dn-button-primary"
            style="margin-top: 5rem; margin-left: 10rem; display: block;">Send Data</button>

    </form>

</div>