<h1 mat-dialog-title>Filter {{data.category === 'dict' ? 'Dictations' : "Notes"}}</h1>
<div mat-dialog-content>
  <mat-radio-group aria-label="Select an option" [(ngModel)]="filter" class="flex flex-col gap-5">
    <mat-radio-button value="date_of_service">Date of Service</mat-radio-button>
    <mat-radio-button value="facility">Facility</mat-radio-button>
    <mat-radio-button value="patient" *ngIf="data.category !== 'dict'">Patient</mat-radio-button>
  </mat-radio-group>

  <div [ngSwitch]="filter" style="margin-top:20px;">
      <div *ngSwitchCase="'date_of_service'">
      <mat-form-field>
        <input matInput [matDatepicker]="picker" [max]="currentDate" (focus)="picker.open()" placeholder="Date of Service" [(ngModel)]="payload" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="!payload">Please enter date of service</mat-error>
      </mat-form-field>
    </div>
    <div *ngSwitchCase="'facility'">
        <mat-form-field>
            <mat-label>Select Facility</mat-label>
            <mat-select [(ngModel)]="payload">
              <mat-option *ngFor="let facility of facilities" [value]="facility._id">
                <app-facility-title [facility]="facility"></app-facility-title>
              </mat-option>
            </mat-select>
          </mat-form-field>
    </div>
    <div *ngSwitchCase="'patient'">
            <mat-form-field>
                <input 
                type="text" 
                [(ngModel)]="payload"
                placeholder="Select Patient" aria-label="Select Patient"
                (input)="filterPatients($event.target.value)"
                matInput [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="dialogRef.close({filter: filter, payload: $event.option.value})">
                    <mat-option *ngFor="let patient of filteredPatients" [value]="patient._id">
                      <app-patient-name [patient]="patient"></app-patient-name>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
    </div>
    <div *ngSwitchDefault>Please choose a filter</div>
  </div>

</div>
<div mat-dialog-actions class="flex items-center justify-between gap-5">
  <button mat-raised-button class="db-button-secondary" [mat-dialog-close]="{filter:'reset'}">Reset</button>
  <button mat-raised-button class="db-button-primary" [mat-dialog-close]="{filter: filter, payload: payload}" cdkFocusInitial>Apply Filter</button>
</div>