import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import * as global from '../../../includes/global'; 
import { API_URL } from "../../../../environments/api-url"; 
import moment from 'moment'
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';


@Injectable({
    providedIn: 'root'
})
export class MatrixConditionsService {
    currentUser: any;
    constructor(private httpclient: HttpClient,
        private _authService: AuthService) {
        this.currentUser = this._authService.currentUser;
    }
    getmatrixConditionsService(patient_id) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,
            patient_id: patient_id 
        }; 
        return this.httpclient.post(global.url + API_URL.PATIENT.getmatrixConditionsService, { details: details }); 
    }
}