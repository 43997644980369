import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { PatientListComponent } from '../patient-list/patient-list.component';
import { Router, ActivatedRoute } from '@angular/router';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

import { PatientAddDialog } from '../transcription-create/patient-add-dialog/patient-add-dialog.component';
import { PatientDetailDialog } from '../transcription-create/patient-detail-dialog/patient-detail-dialog.component';
import { PCCService } from 'src/app/services/pcc.service';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../global';
import { MatrixPatientsComponent } from '../matrix-patients/matrix-patients/matrix-patients.component';
import { CompanyService } from 'src/app/services/Modules/company.service';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.css']
})
export class PatientsComponent implements OnInit {
  patientListComponent:PatientListComponent;
  admin:boolean=false
  company:boolean=false
  matrixPatientListComponent:MatrixPatientsComponent;
  activatedRoute:'list'|'pcc-list' | 'matrix-list' | 'advance-search-list' | any = "list";
  currentUser;
  isLoggedInToPCC:boolean = false;
  global = global;
  matrix = false;
  patientSearchAcrossCompany = false;
  constructor(
    private dialog: MatDialog, 
    private _router:Router,
    private _route:ActivatedRoute,
    private _authService:AuthService,
    public pccService:PCCService,
    private _companyService: CompanyService
    ) {
      this.currentUser = this._authService.currentUser;
      // this._route.params.subscribe((params:any) => {
      //   if(params.patientList) {
      //     this.activatedRoute = params.patientList;
      //   } else {
      //     this.changeRoute();
      //   }
      // });
  }

  async ngOnInit() {
    if (window.location.pathname.includes('admin')) {
      this.admin = true;
    }
    else if (window.location.pathname.includes('company')) {
      this.company = true;
    }
    else {
      this.admin = false;
    }
    let res:any = await this._companyService.checkPatientSearchCompanyAcrosss().toPromise();
    if(res.status == 200){
      this.patientSearchAcrossCompany = res.patientSearch
    }
      // if(!this.pccService.pccUserinfo && this.activatedRoute !== 'My-patient-list') {
      //   this.activatedRoute = 'My-patient-list';
      // }
    // this._router.events.subscribe((val) => {
    //   this.dialog.closeAll();
    // });
    this.activatedRoute = this._route.snapshot.firstChild.url[0].path;
    
    this.isLoggedInToPCC = !!this.pccService.pccUserinfo;
    this.pccService.pccUserinfo$.subscribe(value => {
      this.isLoggedInToPCC = !!value
    })
  }
  onOutletLoaded = (component) => {
    if(component instanceof PatientListComponent) {
      component.onAddNewClick = new EventEmitter();
      component.onAddNewClick.subscribe(this.onAddNewClick.bind(this));
      component.onEditClick = new EventEmitter();
      component.onEditClick.subscribe(this.onEditClick.bind(this)); 
      component.onDeleteClick = new EventEmitter();
      component.onDeleteClick.subscribe(this.onDeleteClick.bind(this)); 
      component.onDetailClick = new EventEmitter();
      component.onDetailClick.subscribe(this.onDetailClick.bind(this));
      this.patientListComponent = component;
      this.matrix = false;
    }
    if(component instanceof MatrixPatientsComponent) {
      // console.log("here in patient component ")
      // component.onAddNewClick = new EventEmitter();
      // component.onAddNewClick.subscribe(this.onAddNewClick.bind(this));
      component.onEditClick = new EventEmitter();
      component.onEditClick.subscribe(this.onEditClick.bind(this)); 
      component.onDeleteClick = new EventEmitter();
      component.onDeleteClick.subscribe(this.onDeleteClick.bind(this)); 
      component.onDetailClick = new EventEmitter();
      component.onDetailClick.subscribe(this.onDetailClick.bind(this));
      this.matrixPatientListComponent = component;
      this.matrix = true;
    }
  }
  changeRoute() {
    this._router.navigateByUrl(`/patients/${this.activatedRoute}`)
  }
  onAddNewClick() {
      this._router.navigate(['patient-add']);
      // this.showPatientAddDialog();
  }
  onEditClick($event) {
    const onSuccess = new EventEmitter();
    onSuccess.subscribe(() => {
      this._router.navigate(['/patient-add'])
      // this.showPatientAddDialog();
    })
    if(this.matrix){
      this.matrixPatientListComponent.editPatient($event, onSuccess);
    }
    else{
      this.patientListComponent.editPatient($event, onSuccess);
    }
  }

  onDeleteClick($event) {
    this.patientListComponent.deletePatient($event);
  }

  onDetailClick($event) {
    // this.patientListComponent.patientDetail($event);
    this._router.navigate([`patient-detail/${$event}`]);
    // this.showPatientDetailDialog();
  }

  showPatientAddDialog() {
    const dialogRef = this.dialog.open(PatientAddDialog, {width: '80%', data: {}});
    dialogRef.beforeClosed().subscribe((patient:any) => {
      // this.currentPatient = patient;
      
    var details = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <any>null,
      primary_doctor_id: <string>null,
      index:<string>null
    };
    details.id = this.currentUser._id;
    details.auth_key = this.currentUser.auth_key;
    details.usertype = this.currentUser.user_type;
    details.index = 'All'
      this.patientListComponent.initPatients(details);
    });
  }
  showPatientDetailDialog() {
    const dialogRef = this.dialog.open(PatientDetailDialog, {width: '80%', data: {}});
  }
  isRoundingSheetButtonVisible() {
    return this.currentUser.user_type == '1' && this.currentUser.has_pcc_account == 'true'

  }
}
