import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PatientDetailService } from '../../../patient-detail-list/patient-detail-list.service';
import { AuthService } from '../../../../services/auth.service';
import * as global from '../../../global';
import moment from 'moment'
import { NoteAuditoryHistoryService } from '../../../note-auditory-history/note-auditory-history.service';
import { Router } from '@angular/router';
import { PCCViewNoteDialog } from 'src/app/includes/transcription-create/view-pcc-note-dialog/view-pcc-note-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PCCService } from 'src/app/services/pcc.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { IProgressNotesResponse } from 'src/app/services/pccAPIInterfaces';
import { DashboardService } from 'src/app/includes/dashboard/dashboard.service';
import { UserService } from 'src/app/services/Modules/user.service';

@Component({
  selector: 'app-pcc-notes-list',
  templateUrl: './pcc-notes-list.component.html',
  styleUrls: ['./pcc-notes-list.component.css'],
})
export class PCCNotesListComponent implements OnInit {
  global = global;
  @Input() note: any;
  @Input() patient: any;
  @Input() excludeNoteId: string;
  @Input() isTelehealthReport: boolean = false;
  @Output() pccNotes = new EventEmitter();
  apiData: IProgressNotesResponse;

  displayedColumns: string[];
  dataSource: any;
  loaderId = 'pcc-note-list-loader';
  
  startDate;
  endDate;
  facility;
  searchTextProvider:any;
  providers: Array<any> = [];
  currentUser: any;
  provider: any = '';

  constructor(
    private patientDetailService: PatientDetailService,
    private auditoryService: NoteAuditoryHistoryService,
    private authService: AuthService,
    private _router: Router,
    private dialog: MatDialog,
    private pccService:PCCService,
    private loader: NgxUiLoaderService,
    private _dashboardService: DashboardService,
    private _userService: UserService,
    ) {
    this.displayedColumns = ['icon', 'note_type', 'created_date', 'created_by'];
    this.currentUser = this.authService.currentUser;

  }

  async ngOnInit() {
    console.log('PCC Note list ', this.note)
    await this.getUsers();

    if (this.patient) {
      this.facility = this.patient.facility_id_ref
      this.initNotes(this.patient.pcc_patientId, this.startDate, this.endDate);
    }
  }

  async getUsers() {
    const filter: any = {
      company_id: this.currentUser.company_id,
      user_type: this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE ? global.USER_TYPE.SNF_WC_NURSE : global.USER_TYPE.DOCTOR
    };
    const projection = {
      first_name: 1,
      last_name: 1,
      full_name: 1,
      title: 1
    };
    return new Promise<void>((resolve, reject) => {
      this._userService.getUsers(filter, projection).subscribe((response: any) => {
        if (response.status === 200) {
          this.providers = response.data;
          resolve(null);
        }
      }, error => {
        reject('error => ' + error.toString());
      });
  });
  }

  handleProviderChange() {
    this.initNotes(this.patient.pcc_patientId, this.startDate, this.endDate);

  }
    

  async initNotes(pccPatientId, startDate, endDate) {
    // const details = {
    //   ...this.authService.authObject,

    //   patient_id: this.patientId
    // };
    // const transcriptionsByPatientResponse: any = await this.patientDetailService.getTranscriptionsByPatientId(details).toPromise();
    // if (transcriptionsByPatientResponse.status === 200) {
    //   this.notes = transcriptionsByPatientResponse.data.array;

    //   if(this.excludeNoteId) {
    //     this.notes = this.notes.filter(note => note._id !== this.excludeNoteId)
    //   }

    //   this.notes.sort((a, b) => {
    //     const Adate = a.date_of_service;
    //     const Bdate = b.date_of_service;
    //     return new Date(Bdate).getTime() - new Date(Adate).getTime();
    //   });


    //   this.dataSource = new MatTableDataSource(this.notes);
    // }
    this.loader.startLoader(this.loaderId);
    const pcc_orgUuid = this.facility.pcc_orgUuid;
    const company_id = this.authService.currentUser.company_id;
    const provider_id = this.provider ? this.provider : null;
    const notesResponse: any = await this.pccService.getNotes(pcc_orgUuid, pccPatientId, startDate, endDate, undefined, undefined, company_id, provider_id).toPromise();
    if (notesResponse.status === 200) {
      this.apiData = notesResponse.data;
      this.pccNotes.emit(this.apiData.data.length);
      this.dataSource = new MatTableDataSource(this.apiData.data);
    }
    this.loader.stopLoader(this.loaderId);

  }

  getServiceDate(trans){  
    // if(trans?.audio_id_ref){
    if(trans?.filterServiceDate){
          // return moment(new Date(trans.audio_id_ref.filterServiceDate).toLocaleString('en-US', { timeZone:  trans.audio_id_ref.timezone })).format('MMM DD, YYYY');
          //mohsin
            // return moment(new Date(trans.audio_id_ref.filterServiceDate)).format('MMM DD, YYYY');
      // }
      
      // else{ 
        // ali
        return moment(new Date(trans.filterServiceDate).toLocaleString('en-US', { timeZone:  trans.timezone })).format('MMM DD, YYYY');
        // mohsin
        // return moment(new Date(trans.filterServiceDate)).format('MMM DD, YYYY');
      }
      else{
        return '';
      }
      
}
  getNoteStatus(status) {
    return this.auditoryService.formatOperation(status);
  }
  goToNoteEditor(note) {
      this._router.navigate([`/note-editor/${note._id}`]);

  }
  showViewNoteDialog(pccNote) {
    
    const dialogRef = this.dialog.open(PCCViewNoteDialog, {
        width: '80%',
        data: {
            pccNote,
            patient: this.patient
        }
    });
    // dialogRef.beforeClosed().subscribe(async (confirm: any) => {
    // });
}

 isCurrentNote(pccNote) {
  return String(pccNote.progressNoteId) === String(this.note.pcc_progressNoteId)
 }
}
