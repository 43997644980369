import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild,signal, ChangeDetectorRef } from '@angular/core';
import { FullCalendarComponent, } from '@fullcalendar/angular';
import { EventInput,CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import * as global from '../global'
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { PatientListService } from '../patient-list/patient-list-service';
import moment from "moment";
import { CreateCensusCalenderComponent } from '../create-census-calender/create-census-calender.component';
import { CensusCalenderService } from './census-calender.service';
import { CommonService } from 'src/app/services/common.service';
import { PatientAddService } from '../patient-add/patient-add-service';
import { FormControl, FormGroup } from '@angular/forms';
import { TranscriptionCreateService } from '../transcription-create/transcription-create.service';
import { CensusListService } from '../census-list/census-list..service';
import { lastValueFrom } from 'rxjs';
import { SnfWcNurseAssociationService } from '../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { ViewOnlyUsersService } from 'src/app/services/Modules/view-only-users.service';
@Component({
  selector: 'app-census-calender',
  templateUrl: './census-calender.component.html',
  styleUrls: ['./census-calender.component.css']
})
export class CensusCalenderComponent implements OnInit, OnChanges {
  now = new Date();
  admin: Boolean = false;
  company: Boolean = false;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  calendarWeekends = true;
  calendarEvents: EventInput[];
  selectInfo = {
    start: this.now
  };
  calendarVisible = signal(true);
  calendarOptions = signal<CalendarOptions>({
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
    ],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    },
    initialView: 'dayGridMonth',
    initialEvents: [], // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    eventClick:this.handleEventClick.bind(this),
    eventContent:this.tooltipEvent.bind(this),
    dateClick:this.handleDateClick.bind(this)
  });
  currentDate = new Date();
  test_time = '12:00 AM';
  minTime = moment().format('LT');
  @Input('data') data: any;
  appointmentId = '';
  currentUser: any;
  facility_id: any;
  currentUserDetails: { id: any; auth_key: any; usertype: any; };
  censuses: any;
  providerIdParam: any;
  filter_provider_id: any;
  providers: any;
  provider_id: any;
  facilities: any;
  selectedFacility: any;
  searchText: any;
  searchTextP: any;

  filterFormGroup: FormGroup;
  censusOpened: boolean = false;
  constructor(
    private _patientListService: PatientListService,
    private _authService: AuthService,
    private _censusListService: CensusListService,
    private commonService: CommonService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService,
    private dashboardService: DashboardService,
    private _transcriptionCreateService: TranscriptionCreateService,
    private router: Router,
    private route: ActivatedRoute,
    private _patientAddService: PatientAddService,
    private _censusCalenderService: CensusCalenderService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
    private viewOnlyUsersService: ViewOnlyUsersService

  ) {

    this.currentUser = this._authService.currentUser;
    this.currentUserDetails = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type
    }
    this.providerIdParam = this.currentUser._id;

    if (this.data?.provider_id) {
      this.provider_id = this.data.provider_id;
    } else {
      this.provider_id = this.providerIdParam;
    }
    if (this.data?.facility_id) {
      this.facility_id = this.data.facility_id;
    } else {
      this.facility_id = null;
    }
    this.filterFormGroup = new FormGroup({
      facility: new FormControl(null),
      provider: new FormControl(null),

    });
    this.searchText = '';
    this.searchTextP = '';
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.provider_id = changes.data.currentValue.provider_id;
      this.facility_id = changes.data.currentValue.facility_id;
      if (this.provider_id && this.provider_id != 'none') {
        this.loadData(this.provider_id, this.facility_id);
      } else if (this.provider_id == 'none' && this.facility_id == 'none') {
        this.calendarEvents = [];
        this.censuses = [];
      }
    }
  }
  tooltipEvent(info) {
    const italicEl = document.createElement('div');
    if(info.event.extendedProps.imageurl){
      italicEl.className = 'event-title-container';
      let imageurl = info.event.extendedProps.imageurl;
      italicEl.innerHTML = `<div><img src="${imageurl}" width='17' height='15' style='margin-right:5px'>${info.event.title}</div>
                            <div class="description">${info.event.extendedProps.description}</div>`;
      italicEl.addEventListener('mouseover', () => {
        const descriptionDiv = italicEl.querySelector('.description') as HTMLElement;
        if (descriptionDiv) {
          descriptionDiv.style.display = 'block';
        }
      });
      italicEl.addEventListener('mouseout', () => {
        const descriptionDiv = italicEl.querySelector('.description') as HTMLElement;
        if (descriptionDiv) {
          descriptionDiv.style.display = 'none';
        }
      });
    }
    const arrayOfDomNodes = [italicEl];
    return { domNodes: arrayOfDomNodes };
  }

  // tooltipEvent(info) {
  //     // if (info.event.extendedProps.imageurl) {
  //     //     let imageurl = info.event.extendedProps.imageurl;
  //     //     let title = $(info.event._context.calendarApi.el).find('.fc-title');

  //     //     title.prepend("<img src='" + imageurl +"' width='17' height='15' style='margin-right:5px'>");
  //     // }
  //   let calender = document.getElementById('calender');
  //   // const instance = new Tooltip(info.event._context.calendarApi.el, {
  //   //   title: info.event.extendedProps.description,
  //   //   html: true,
  //   //   placement: 'auto-start',
  //   //   trigger: 'hover',
  //   //   container: '#calender'
  //   // });
  //   const italicEl = document.createElement('div');
  //   if(info.event.extendedProps.imageurl){
  //     italicEl.className = 'event-title-container';
  //     let imageurl = info.event.extendedProps.imageurl;
  //     italicEl.innerHTML = `<img src="${imageurl}" width='17' height='15' style='margin-right:5px'>${info.event.title}`;
  //   }
  //   const arrayOfDomNodes = [italicEl];
  //   return { domNodes: arrayOfDomNodes };
  // }

  async loadFacilities(provider_id) {
    if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE || this.currentUser.company_type === global.COMPANY_TYPE.SNF) {
      const filter = {
        associated_snf_wc_nurse_ids: provider_id,
        company_id: this._authService.currentUser.company_id
      };
      const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      const facilityProjection = {
        title: 1,
        pcc_facId: 1,
        pcc_2_legged_authentication: 1,
        source: 1,
        pcc_orgUuid: 1
      };
      const response: any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
      if (response.status === 200 && response.data.length > 0) {
        this.facilities = response.data;
        this.facilities.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
        const selectedFacility = localStorage.getItem('censusCalendarFacility');
        if (selectedFacility) {
          this.facility_id = this.facilities.find(f => f._id?.toString() == JSON.parse(selectedFacility)._id?.toString());
        } 
        // else {
        //   this.filterFormGroup.get('facility').setValue('all');
        // }
      }
    } else {
    let facres = await this._patientAddService.getFacilities({ doctor_id: provider_id }).subscribe((res: any) => {
      let response = res.data?.array;
      if (response) {
        this.facilities = response;
        const selectedFacility = localStorage.getItem('censusCalendarFacility');
        if (selectedFacility) {
          this.facility_id = this.facilities.find(f => f._id?.toString() == JSON.parse(selectedFacility)._id?.toString());
        } 
        // else {
        //   this.filterFormGroup.get('facility').setValue('all');
        // }
      } else {
        console.log("something went wrong!");
      }
    });
  }
  }

  clearInput() {
    this.searchText = '';
  }

  clearInputP() {
    this.searchTextP = '';
  }

  async ngOnInit(): Promise<void> {
    if (window.location.pathname.includes('admin')) {
      this.admin = true;
    }
    else if (window.location.pathname.includes('company')) {
      this.company = true;
    }
    else {
      this.admin = false;
      if (this.currentUser.user_type == global.USER_TYPE.DOCTOR) {
        let response: any = await this.dashboardService.getP2PRelation().toPromise()
        if (response.status == 200) {
          this.providers = [this.currentUser].concat(response.data.assoc_provider_id);
          this._censusListService.castFilterProviderId.subscribe(filterProviderId => {
            if(filterProviderId){
              this.provider_id = filterProviderId;
            }else{
              this.provider_id = this.providers[0]._id;
            }
            this.filterFormGroup.get('provider').setValue(this.provider_id);
            this.loadFacilities(this.provider_id);
          })

        }
      }
      else if (this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
        this.initAssociatedProvidersListForMA();
      }
      else if (this.currentUser.user_type == global.USER_TYPE.BUSINESS_OFFICER) {
        this.initAssociatedProvidersListForBO();
      }else if (this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE) {
        this.initAssociatedNurseList();
      }else if (this.currentUser.user_type == global.USER_TYPE.VIEW_ONLY_USER) {
        this.getAssociatedProvidersListForViewOnlUsers();
      }
      else {
        this.initAssociatedProvidersList();
      }
      //select 'all' facility initially 
      if  (!this.facility_id) {
        this.facility_id = 'all'
      }
      this.filterFormGroup.get('provider').valueChanges.subscribe(async (val) => {
        this.provider_id = val;

        if (val=='all'){
          this.provider_id = null
          this.facility_id = val;
          this.facilities=[];
          this.loadData(this.providers.map((e:any)=>{return e._id;}), null);
          return;
        }else{
          await this.loadFacilities(this.provider_id);
        }

      });
      this.filterFormGroup.get('facility').valueChanges.subscribe((val) => {
        if (val == 'all'){
          this.facility_id = val;
          // this.facility_id = null;
          this.loadData(this.provider_id, null);
        }
        else{
          this.facility_id = val;
          this.loadData(this.provider_id, this.facility_id?._id);
        }
      });

    }
  }

  async getAssociatedProvidersListForViewOnlUsers() {
    const doctorsResponse : any = await lastValueFrom(this.viewOnlyUsersService.getAssociatedProvidersListForViewOnlUsers({user_id : this.currentUser._id, company_id : this.currentUser.company_id}));
    if (doctorsResponse.status === 200) {
      if(doctorsResponse.data){
        this.providers = doctorsResponse.data;
      }
      this._censusListService.castFilterProviderId.subscribe(filterProviderId => {
        if (filterProviderId) {
          this.provider_id = filterProviderId;
        } else {
          this.provider_id = this.providers[0]._id;
        }
        this.filterFormGroup.get('provider').setValue(this.provider_id);
        this.loadFacilities(this.provider_id);
      })
    }
  }

  initAssociatedNurseList() {
    const filter = {
      snf_wc_nurse_id: this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE ? this.currentUser._id : this.provider_id,
      company_id: this.currentUser.company_id
    };
    const project = {
      first_name: 1,
      last_name: 1,
      title: 1,
    };
    this._snfWcNurseAssociationService.getAssociatedSnfWcNurses(filter, project)
      .subscribe((response: any) => {
        if (response.status === 200) {
          if(response.data?.associated_snf_wc_nurse_ids){
            this.providers = [this.currentUser].concat(response.data.associated_snf_wc_nurse_ids);
          }else{
            this.providers = [this.currentUser];
          }
          this._censusListService.castFilterProviderId.subscribe(filterProviderId => {
            if (filterProviderId) {
              this.provider_id = filterProviderId;
            } else {
              this.provider_id = this.providers[0]._id;
            }
            this.filterFormGroup.get('provider').setValue(this.provider_id);
            this.loadFacilities(this.provider_id);
          })
        }
        console.log("this.providers : ", this.providers);
      });
  }
  ngOnDestroy(): void {
        this._censusListService.changeFilterProviderId(this.provider_id);
        // this._censusListService.changeFilterFacilityId(this.facility_id?._id);
        if(!this.censusOpened) {
          localStorage.removeItem('censusCalendarFacility');
        }
  }
  navigateCalenderToDate(date) {
    const calendarApi = this.calendarComponent.getApi();
    calendarApi.changeView('dayGridMonth', date);
  }
  getBackgroundColor(element) {
    return 'red';
  }
  showPatientRoundingSheetDialog(calender_date) {
    const obj = {
      filter_facility_id: this.facility_id?this.facility_id._id:null,
      filter_provider_id: this.provider_id ? this.provider_id : this.currentUser._id,
      calender_date: calender_date
    }
    console.log("this.provider_id pesa: ", this.provider_id)
    if((this.provider_id&&(!Array.isArray(this.provider_id)))){   // &&(this.facility_id!=null&&this.facility_id!='all')
      const dialogRef = this.dialog.open(CreateCensusCalenderComponent, {
        data: obj,
        width: '50%'
      });
      dialogRef.afterClosed().subscribe(async (data) => {
        console.log("facility: ", this.facility_id)
        if (data) {
          this.loadData(this.provider_id, this.facility_id?._id ? this.facility_id?._id : null);
        }
      });
    }else{
      this.toastr.warning('Please select provider from dropdown.');
    }
  }

  handleDateClick(arg) {
    if(this.currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER) return;
    if (!(this.company))

      if (arg.date) {
        //
        let clickedDate = moment(arg.date).format('L');
        let momentDate = moment().format('L');
        if (moment(clickedDate).isSame(momentDate)) {
          this.minTime = moment().format('LT');
          this.test_time = this.minTime;
        } else if (moment(clickedDate).isBefore(momentDate)) {
          return;
        } else if (moment(clickedDate).isAfter(momentDate)) {
          this.minTime = '0:00 AM';
          this.test_time = this.minTime;
        }
        this.showPatientRoundingSheetDialog(arg.date);
        this.test_time = arg.date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      }
  }

  handleEventClick(arg) {
    this.viewClicked(arg.event.extendedProps._id);
  }

  toolTipInfo(e) {
    let totalPatients = e.patientList?.length || 0;
    let totalInActivePatients = e.patientsInactive?.length || 0;
    let totalActivePatients = totalPatients - totalInActivePatients;
    let savedNotesCount = e.savedNotesCount;
    let signedNotesCount = e.signedNotesCount;
    let draftChargesCount = e.draftChargesCount;
    let submittedChargesCount = e.submittedChargesCount;
    let totalSeenPatients = e.patientsSeen?.length || 0;
    let is_void = e.is_void ? 'Yes' : 'No';


    let toolText = `Census Name: <strong>${e?.name}</strong> </br>
                    Total Patients: <strong>${totalPatients}</strong> </br> 
                    Saved Notes: <strong>${savedNotesCount}</strong> </br>
                    Signed Notes: <strong>${signedNotesCount}</strong> </br>`;
    
    if (this.currentUser.user_type != global.USER_TYPE.SNF_WC_NURSE) {
      toolText += `Drafted Charges: <strong>${draftChargesCount}</strong> </br>
                   Submitted Charges: <strong>${submittedChargesCount}</strong> </br>`;

      }else{
        if(this.providers&&this.providers.length){
          const censusOwner = this.providers.filter((ele:any)=>{return ele._id?.toString()==e.createdBy?.toString()});
          if(censusOwner&&censusOwner.length){
            toolText += `Created By: <strong>${censusOwner[0].last_name+' '+censusOwner[0].first_name+', '+censusOwner[0].title}</strong> </br>`;
          }
        }
    }

    // let toolText=` Census Name: <strong>${e?.name}</strong> </br> Facility: <strong>${e.facility?.title}</strong> </br> Total Patients: <strong>${totalPatients}</strong> 
    //   </br> InActive Patients: <strong>${totalInActivePatients}</strong> </br> Active Patients: <strong>${totalActivePatients}</strong>
    //   </br> Seen Patients: <strong>${totalSeenPatients}</strong> </br> Void Census: <strong>${is_void}</strong> `;

    return toolText;
  }

  async getCensuses(provider_id = this.currentUser._id, facility_id = null) {
    if ((provider_id == this.currentUser._id) && (this.currentUser.user_type != '1' && this.currentUser.user_type != global.USER_TYPE.SNF_WC_NURSE) && facility_id == null) return;

    return new Promise<void>(async (resolve, reject) => {

      // this._censusCalenderService.getCensuses({ provider_id: provider_id, facility_id: facility_id }).subscribe((resp: any) => {
      let resp: any = await lastValueFrom(this._censusCalenderService.getCensuses({ provider_id: provider_id, facility_id: facility_id }));
      if (resp.status == 200) {
        this.censuses = resp.data;
        this.calendarEvents = resp.data.map(e => {
          let facility_title = e?.facility?.title;
          // e.title = `${e?.name} \n ${facility_title}`;
          // e.description = `${e?.name} / ${facility_title}`;

          e.title = `${facility_title}`;
          e.description = this.toolTipInfo(e);

          if (e?.facility?.source == 'PointClickCare' || e?.facility?.pcc_facId) {
            e.imageurl = '../../../assets/icons/pcc-icon.svg';
          } else if (e?.facility?.source == 'MatrixCare') {
            e.imageurl = '../../../assets/icons/matrix.png';
          } else {
            e.imageurl = '../../../assets/img/DN.png';
          }

          e.start = this.commonService.dateObjToString_calenderFormat(e.date_obj);
          e.id = `${e?._id}`;
          e.title_id = `${e?._id}`;
          e.allDay = true;
          e.color = this.applyRowColors(e);
          e.textColor = 'darkslategrey';
          e.borderColor = 'darkgray';

          return e;
        })
        this.calendarOptions.update((options) => ({
          ...options,
          events: this.calendarEvents,
        }));
        resolve(null);
      }
      // }, error => {
      //   reject('error => ' + error.toString());
      // });
    });
  }

  applyRowColors(row) {
    let past = '#eeeeee';
    let future = '#bac5f5';
    let present = '#c0face';
    if (row.date_obj) {

      const censusDate = new Date(`${row.date_obj?.year||new Date(row?.createdAt).getFullYear()}-
                    ${row.date_obj?.month||new Date(row?.createdAt).getMonth()}-
                    ${row.date_obj?.day||new Date(row?.createdAt).getDate()}`).getTime();

      const currentDate = new Date();
      let day = currentDate.getDate();
      let month = currentDate.getMonth();
      let year = currentDate.getFullYear();
      const currentDateFormat = new Date(`${year}-${month + 1}-${day}`).getTime();
      if (censusDate < currentDateFormat) {
        return past;
      }
      else if (censusDate == currentDateFormat) {
        return present
      } else if (censusDate > currentDateFormat) {
        return future
      }
    }
    if (row.date && !row.date_obj) {
      let currentDate = new Date().toISOString();
      if (row.date < currentDate) {
        return past;
      }
    }
  }

  async loadData(provider_id, facility_id) {
    try {
      this.loader.startLoader('loader-01');
      await this.getCensuses(provider_id, facility_id);
      this.loader.stopLoader('loader-01');
    } catch (e) {
      this.toastr.error('something went wrong in loading data', 'Error');
    }
  }

  async initAssociatedProvidersList() {
    await this.dashboardService
      .getAssociatedDoctorsList(this.currentUser.company_id)
      .subscribe(async (response: any) => {
        if (response.status === 200) {
          this.providers = response.data;
          if(this.providers.length){
              this.provider_id = this.providers[0]._id;
              this.filterFormGroup.get('provider').setValue(this.provider_id);
              await this.loadFacilities(this.provider_id);
          }
        }
      });
  }

  initAssociatedProvidersListForMA() {
    this._transcriptionCreateService
      .getAssociatedDoctorsListForMA()
      .subscribe(async (response: any) => {
        if (response.status === 200) {
          this.providers = response.data;
          if(this.providers.length){
           // if (!this.provider_id) {
              this.provider_id = this.providers[0]._id;
              this.filterFormGroup.get('provider').setValue(this.provider_id);
              await this.loadFacilities(this.provider_id);

           // }
          }
        }
      });
  }

  initAssociatedProvidersListForBO() {
    this._transcriptionCreateService
      .getAssociatedDoctorsListForBO(this.currentUser._id, this.currentUser.company_id, this.currentUser.user_type)
      .subscribe(async (response: any) => {
        if (response.status === 200) {
          this.providers = response.data;
          if(this.providers.length){
           // if (!this.provider_id) {
              this.provider_id = this.providers[0]._id;
              this.filterFormGroup.get('provider').setValue(this.provider_id);
              await this.loadFacilities(this.provider_id);

           // }
          }
        }
      });
  }

  async viewClicked(census_id) {
    if(!(this.facility_id == '' || this.facility_id == null)) {
      localStorage.setItem('censusCalendarFacility',JSON.stringify(this.facility_id));
      this.censusOpened = true;
    }
    localStorage.setItem('hide_census', 'false')
    let pro;
    if (this.providerIdParam) {
      const userDetailResponse: any = await this.dashboardService.getUserDetail(this.providerIdParam).toPromise();
      if (userDetailResponse.status == 200) {
        pro = userDetailResponse.data;
      }
    }
    let telemedicine = pro[0]?.other_settings?.show_telemedicine || 'false';
    if (this.admin) {
      this.router.navigateByUrl(`/admin/census/${census_id};telemedicine=${telemedicine}`);
    }
    if (this.company) {
      this.router.navigateByUrl(`/company/${this.currentUser.company_id}/census/${census_id};telemedicine=${telemedicine}`);
    }
    else {
      localStorage.setItem('calView', 'true');
      this.router.navigateByUrl(`/census/${census_id};telemedicine=${telemedicine}`);
    }
  }




  //   async initProviders() {
  //     this.providerIdParam = this.route.snapshot.params.provider_id;
  //     if (this.providerIdParam) {
  //       this.filter_provider_id = this.providerIdParam;
  //     }
  //     if (this.currentUser.user_type == global.USER_TYPE.DOCTOR) {
  //       let response: any = await this.dashboardService.getP2PRelation().toPromise()
  //       if (response.status == 200) {
  //         this.providers = [this.currentUser].concat(response.data.assoc_provider_id);

  //         this.providers = this.providers.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));
  //         if (this.filter_provider_id) {
  //           let filtered_provider = this.providers.filter(item => item._id == this.filter_provider_id)
  //           if (filtered_provider) {
  //             this.filter_provider_id = filtered_provider[0]._id
  //             return
  //           }

  //         }
  //         if (!this.filter_provider_id) {
  //           this.filter_provider_id = this.providers[0]._id;
  //         }
  //       }
  //     }

  //     // else if (this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
  //     //   this.initAssociatedProvidersListForMA();
  //     // }
  //     // else if (this.currentUser.user_type == global.USER_TYPE.BUSINESS_OFFICER) {
  //     //   //console.log('userrrrr', this.currentUser);
  //     //   this.initAssociatedProvidersListForBO();
  //     // }
  //     // else {
  //     //   this.initAssociatedProvidersList();
  //     // }
  //   }

  //   initAssociatedProvidersList() {
  //     this.dashboardService
  //       .getAssociatedDoctorsList(this.currentUser.company_id)
  //       .subscribe((response: any) => {
  //         if (response.status === 200) {
  //           this.providers = response.data;
  //           if (this.filter_provider_id) {
  //             this.initFacilities(null, this.filter_provider_id);
  //           }
  //           else {
  //             this.initFacilities();
  //           }
  //           this.providers = this.providers.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));
  //           if (!this.filter_provider_id) {
  //             this.filter_provider_id = this.providers[0]._id;
  //           }
  //         }
  //       });
  //   }

  //   initAssociatedProvidersListForMA() {
  //     this._transcriptionCreateService
  //       .getAssociatedDoctorsListForMA()
  //       .subscribe((response: any) => {
  //         if (response.status === 200) {
  //           this.providers = response.data;
  //           if (this.providers?.length > 0) {
  //             const providerId = this.filter_provider_id || this.providers[0]._id;
  //             this.initFacilities(null, providerId);
  //           }
  //           if (this.providers?.length > 0) {
  //             this.providers = this.providers.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));
  //             if (!this.filter_provider_id) {
  //               this.filter_provider_id = this.providers[0]._id;
  //             }
  //           }
  //         }
  //       });
  //   }

  //   initAssociatedProvidersListForBO() {
  //     this._transcriptionCreateService
  //       .getAssociatedDoctorsListForBO(this.currentUser._id, this.currentUser.company_id, this.currentUser.user_type)
  //       .subscribe((response: any) => {
  //         if (response.status === 200) {
  //           this.providers = response.data;
  //           if (this.providers?.length > 0) {
  //             const providerId = this.filter_provider_id || this.providers[0]._id;
  //             this.initFacilities(null, providerId);
  //           }
  //           if (this.providers?.length > 0) {
  //             this.providers = this.providers.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));
  //             if (!this.filter_provider_id) {
  //               this.filter_provider_id = this.providers[0]._id;
  //             }
  //           }
  //         }
  //       });
  //   }

  // initFacilities(selectedProvider = null, provider_id?) {
  //   if (!this.filter_facility_id && this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER) {
  //     if (this.providers?.length > 0) {
  //       selectedProvider = this.providers[0]._id;
  //     }
  //     else return;
  //   }
  //   if (provider_id) selectedProvider = provider_id;
  //   this.facilities = []
  //   this.addressSubscription = this.facilityService.getMyFacilities(null, selectedProvider).subscribe((response: any) => {
  //     if (response.status == 200) {
  //       this.facilities = response.data.array;
  //       this.facilities.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
  //       this.selectedFacility = this.facilities.find(f => f._id === this.filter_facility_id);
  //       if (!this.selectedFacility) {
  //         this.pccService.pccUserinfo$.subscribe(u => {
  //           if (u) {

  //             const selectedFacility = this.facilities.find(f => this.isDefaultLoggedInFacility(f));
  //             if (selectedFacility && this.selectedFacility?._id !== selectedFacility._id) {
  //               this.selectedFacility = selectedFacility;
  //               this.filter_facility_id = this.selectedFacility._id;
  //               this.handleFilterFacilityChange();
  //             }
  //           } else {
  //             this.selectedFacility = this.facilities[0];
  //             this.filter_facility_id = this.facilities[0]._id;
  //             this.handleFilterFacilityChange();
  //           }
  //         });
  //       } else {
  //         this.facility_charge_capture_enabled = this.selectedFacility.enabled_charge_capture;
  //         if ((this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER || this.currentUser.user_type === global.USER_TYPE.DOCTOR) && (!this.facility_charge_capture_enabled || !this.user_charge_capture_enabled)) {
  //           this.displayedColumns.splice(this.displayedColumns.indexOf('draft_submitted_charges'), 1);
  //           this.displayedColumns.splice(this.displayedColumns.indexOf('status'), 1);
  //         }
  //         else if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT) {
  //           this.displayedColumns = this.displayedColumns.filter(column => column !== 'saved_signed_notes' && column !== 'draft_submitted_charges' && column !== 'status');
  //         }
  //       }
  //       if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT)
  //         this.initPatientRoundingSheets(this.filter_facility_id, this.filter_provider_id, this.showLimit);
  //     }
  //   });
  // }




}
