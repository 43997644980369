import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { ProfileService } from '../../profile/profile.service';

@Component({
  selector: 'app-dialog-with-choice',
  templateUrl: './dialog-with-choice.component.html',
  styleUrls: ['./dialog-with-choice.component.css']
})
export class DialogWithChoiceComponent implements OnInit {
  title:String;
  reason:String='All treatment completed';
  currentUser: any;
  
  constructor(public dialogRef: MatDialogRef<DialogWithChoiceComponent>,
    private _profileService: ProfileService,
    private _authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
    const currentUser = this._authService.currentUser;
    this.currentUser = currentUser;

    }
  output:any;
  ngOnInit(): void {
    this.title = this.data.message;
    this.getWoundCompanyStatus();
  }

  handleChangeReason(){

  }

  handleClose(result){
    this.output={
      reason:this.reason,
      result:result
    }
    this.dialogRef.close(this.output);
    
  }

  getWoundCompanyStatus(){
    if(this.currentUser.company_type?.toLowerCase()=='wound') 
      return true;
    else return false;  
  }

  changeDischargeStatus(value){
    console.log("dgdfg", value);
    let oldOtherSettings = this.currentUser.other_settings || {};
    if(oldOtherSettings && oldOtherSettings != null){
      this.currentUser.other_settings.discharge = value;
    }
    else{
      let newOtherSettings = {
        other_settings: {
          discharge: value
        }
      }
      oldOtherSettings = Object.assign(oldOtherSettings, newOtherSettings);
    }
    console.log("dgdfg2222", value);

    this._profileService.updateOtherSettings(oldOtherSettings).subscribe((response:any)=>{
      if(response.status == 200){

        this._authService.currentUser = this.currentUser = {
          ...this.currentUser,
          other_settings: oldOtherSettings
        }
      }
    })
  }

}
