<div class="wrapper">
  <div class="form-group flex flex-col items-start">
    <div class="flex items-start justify-start">
      <div class="flex-grow">Patient eligible for lower extremity neurological exam with 10G.</div>
      <div style="flex: 0 0 150px">
        <button
          class="button"
          [class.active]="model.button === true"
          mat-button
          (click)="resetModel(); model.button = true"
        >
          Yes
        </button>
        <button
          class="button"
          [class.active]="model.button === false"
          mat-button
          (click)="resetModel(); model.button = false"
        >
          No
        </button>
      </div>
    </div>
    <div class="flex items-baseline" *ngIf="model.button">
      <mat-form-field style="width:310px">
        <mat-select
          name="patientScreeningExclusionsOption"
          [(ngModel)]="model.ANSWER"
        >
        <mat-option value="" [disabled]="true"> Select </mat-option>
        <mat-option
            *ngFor="let option of options"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      </div>
  </div>
  <ng-container *ngIf="answer">
    <div [class.negative-answer]="isNegativeCode(answer)">
      <div class="answer_code">{{ answer }}</div>
      <div class="answer_description">{{ answerDescription }}</div>
    </div>
  </ng-container>

</div>
