import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from "@angular/material/table";
import { SnfWcNurseAssociationService } from './snf_wc_nurse_association.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/Modules/user.service';
import { lastValueFrom } from 'rxjs';
import * as global from '../../includes/global';
import { AuthService } from 'src/app/services/auth.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-snf_wc_nurse-snf_wc_nurse-association',
  templateUrl: './snf_wc_nurse-snf_wc_nurse-association.component.html',
  styleUrls: ['./snf_wc_nurse-snf_wc_nurse-association.component.css']
})
export class SnfWcNurseNurseAssociationComponent implements OnInit {
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = ['nurse_name', 'associated_nurses_name'];
  nurseDropdownSettings: any = {};
  nurses: any = [];
  associatedNurses: any = [];
  companySide: Boolean = false;
  companyId: any;

  relationData: any;
  providers: any = [];
  loaderId: string = "app-snf_wc_nurse-snf_wc_nurse-association";
  orignalData: any = [];

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private snfWcNurseAssociationService: SnfWcNurseAssociationService,
    private userService: UserService,
    private _authService: AuthService,
    private loader: NgxUiLoaderService
  ) {
    this.nurseDropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'full_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: true,
    };
    if (window.location.pathname.includes('company')) {
      this.companySide = true
      this.companyId = this.route.parent.snapshot.params.id

    }
    else {
      this.companySide = false
      this.companyId = ''
    }
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId);
    await this.initAssociations();
    await this.initSnfWcNurses();
    this.normalizeData();
    this.loader.stopLoader(this.loaderId);
  }

  normalizeData() {
    // if (this.associatedNurses.length > 0 && this.nurses.length > 0) {
      for (const nurse of this.nurses) {
        nurse['allNurses']  = [];
        nurse['associated_snf_wc_nurse_ids'] = [];
        nurse.allNurses = this.nurses.filter(snfWcNurse => snfWcNurse._id !== nurse._id);
        for (const associatedNurse of this.associatedNurses) {
          if (associatedNurse.snf_wc_nurse_id && associatedNurse.snf_wc_nurse_id._id === nurse._id) {
            nurse.associated_snf_wc_nurse_ids = associatedNurse.associated_snf_wc_nurse_ids;
          }
        }
        // this.orignalData.push(JSON.parse(JSON.stringify(nurse)));
      }
      this.dataSource.data = this.nurses;
      // this.orignalData = JSON.parse(JSON.stringify(this.nurses));
      
    // }
  }
  async initAssociations() {
    const associatedProjection = { first_name: 1, last_name: 1, title: 1, full_name: 1 };
    const nurseAssociatedFilter = { company_id: this.companyId };
    const response: any = await lastValueFrom(this.snfWcNurseAssociationService.getAssociatedSnfWcNurses(nurseAssociatedFilter, associatedProjection));
    if (response.status === 200) {
      this.associatedNurses = response.data;
    }
    else
      this.toastr.warning(response.message);
  }

  async initSnfWcNurses() {
    const nurseFilter = { company_id: this.companyId, user_type: global.USER_TYPE.SNF_WC_NURSE };
    const projection = { first_name: 1, last_name: 1, title: 1, email: 1, full_name: 1 };
    const response: any = await lastValueFrom(this.userService.getUsers(nurseFilter, projection));
    if (response.status === 200) {
      this.nurses = response.data;
    }
    else
      this.toastr.warning(response.message);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onDataSelection($event) {
    // let providers = element.associated_provider_ids.map(a => a._id);
    // let idx = this.users.findIndex(user => user._id.toString == element._id.toString());
    // this.users[idx] = element;
    // this.dataSource.data = this.users;
    if($event.data == 'selectAll') {
      this.onSelectAllNurse($event.element.assos_providers, $event.element);

    }
    else if($event.data == 'unSelect') {
      this.onDeSelectAllNurse($event.element);
    }
    else {
      if(!$event.status) {
        this.onNurseDeSelect($event.data, $event.element);
      }
      else {
        this.onNurseSelect($event.data, $event.element);
      }
    }
  }
  
  onDataRemove($event) {
    this.onNurseDeSelect($event.data, $event.element);
  }

  onNurseSelect(event, element) {
    console.log(event, element);
    this.loader.startLoader(this.loaderId);
      
    const filter = { snf_wc_nurse_id: element._id };
    const projection = { associated_snf_wc_nurse_ids: event._id };

    this.snfWcNurseAssociationService.createAssociatedSnfWcNursesRelation(filter, projection).subscribe((res: any) => {
      if (res.status == 200) {
        const index = this.nurses.findIndex(nurse => nurse._id === element._id);
        this.nurses[index].associated_snf_wc_nurse_ids = [element, ...this.nurses[index].associated_snf_wc_nurse_ids || []];
        this.dataSource.data = [];
        this.dataSource.data = this.nurses;
        this.toastr.success("Snf Wc Nurse Added Successfully");
        this.loader.stopLoader(this.loaderId);
      }
      else {
        this.toastr.warning(res.message);
        this.loader.stopLoader(this.loaderId);
      }
    });
  
    
  }

  onSelectAllNurse(event, element) {
    this.loader.startLoader(this.loaderId);
    const ids = event.map(nurse => nurse._id);
    let filter = { snf_wc_nurse_id: element._id, associated_snf_wc_nurse_ids: ids };
    this.snfWcNurseAssociationService.createManySnfWcNurseRelations(filter).subscribe((res: any) => {
      if (res.status == 200) {
        for (const nurse of this.nurses) {
          if (nurse._id !== element._id) {
            if (
              !(Array.isArray(nurse.associated_snf_wc_nurse_ids) &&
                nurse.associated_snf_wc_nurse_ids.map(associated_snf_wc_nurse => associated_snf_wc_nurse._id)
                  .includes(element._id))
            ) {
              nurse.associated_snf_wc_nurse_ids = [{ _id: element._id, full_name: element.full_name },
              ...nurse.associated_snf_wc_nurse_ids || []
              ]
            }
          }
        }
        this.dataSource.data = [];
        this.dataSource.data = this.nurses;
        this.toastr.success("Snf Wc Nurses Added Successfully");
        this.loader.stopLoader(this.loaderId);
      }
      else {
        this.toastr.warning(res.message);
        this.loader.stopLoader(this.loaderId);
      }
    })
  }

  onNurseDeSelect(event, element) {
    this.loader.startLoader(this.loaderId);
    const filter = { snf_wc_nurse_id: element._id };
    const projection = { associated_snf_wc_nurse_ids: event._id };
    this.snfWcNurseAssociationService.deleteAssociatedSnfWcNursesRelation(filter, projection).subscribe((res: any) => {
      if (res.status == 200) {
        element.associated_snf_wc_nurse_ids = element.associated_snf_wc_nurse_ids.filter(nurse => nurse._id !== event._id);
        const index = this.nurses.findIndex(nurse => nurse._id === event._id);
        this.nurses[index].associated_snf_wc_nurse_ids = this.nurses[index].associated_snf_wc_nurse_ids
          .filter(associated_snf_wc_nurse => associated_snf_wc_nurse._id !== element._id);
        this.dataSource.data = [];
        this.dataSource.data = this.nurses;
        this.toastr.success("Snf Wc Nurse Removed Successfully");
        this.loader.stopLoader(this.loaderId);
      }
      else {
        this.toastr.warning(res.message);
        this.loader.stopLoader(this.loaderId);
      }
    });
  }

  onDeSelectAllNurse(element) {
    this.loader.startLoader(this.loaderId);
    console.log(element);
    const ids = this.nurses.filter(nurse => nurse._id !== element._id).map(nurse => nurse._id);
    let filter = { snf_wc_nurse_id: element._id, associated_snf_wc_nurse_ids: ids };
    this.snfWcNurseAssociationService.deleteManySnfWcNurseRelations(filter).subscribe((res: any) => {
      if (res.status == 200) {
        for (const nurse of this.nurses) {
          if (nurse._id !== element._id) {
            if (
              Array.isArray(nurse.associated_snf_wc_nurse_ids) &&
              nurse.associated_snf_wc_nurse_ids.map(associated_snf_wc_nurse => associated_snf_wc_nurse._id)
                .includes(element._id)
            ) {
              nurse.associated_snf_wc_nurse_ids = nurse.associated_snf_wc_nurse_ids.filter(associated_snf_wc_nurse => associated_snf_wc_nurse._id !== element._id);
            }
          }
        }
        this.dataSource.data = [];
        this.dataSource.data = this.nurses;
        this.loader.stopLoader(this.loaderId);
        this.toastr.success("Snf Wc Nurses Removed Successfully");
      }
      else {
        this.loader.stopLoader(this.loaderId);
        this.toastr.warning(res.message);
      }
    });
  }

  compareProviders(provider1: any, provider2: any): boolean {
    return provider1 && provider2 ? provider1._id === provider2._id : provider1 === provider2;
  }

}
