import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from "../../../../environments/api-url";
import * as global from '../../../includes/global';
import * as _ from 'lodash';
import moment from 'moment'
import { CommonService } from 'src/app/services/common.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NoteIcdSuggestionsService {

  constructor(
    private _httpclient: HttpClient,
    private _router: Router,
    private _authService: AuthService,
    private _commonService: CommonService,
  ) { }
  getICDSuggestions(noteId) {
    const payload = {
      noteId
    }
    return this._httpclient.post(global.url + API_URL.ICD_SUGGESTION.getICDSuggestions, payload);
  }
  getICDSuggestionsFromPythonService(noteText) {
    const payload = {
      noteText
    }
    return this._httpclient.post(global.url + API_URL.CHARGE.getICDSuggestions, payload).pipe(
      map((response: any) => {
        if (response.status === 200) {
          response.data.map(icd => {
            if (icd.end_date) {
              const [month, day, year] = icd.end_date.split("/");
              if (moment(`${year}-${month}-${day}`).isBefore(moment())) {
                icd.isExpired = true;
              }
            }
            return icd;
          });
          return response;
        }
      })
    );
  }
  uploadNote(noteId, noteText) {
    const payload = {
      noteId,
      noteText
    }
    return this._httpclient.post(global.url + API_URL.ICD_SUGGESTION.uploadNote, payload);
  }
}
