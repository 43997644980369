import { Component, Inject, NgZone, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { CKEditorService } from '../../note-editor/ckeditor.service';

@Component({
  selector: 'app-drive-phrase-editor',
  templateUrl: './drive-phrase-editor.component.html',
  styleUrls: ['./drive-phrase-editor.component.css']
})
export class DrivePhraseEditorComponent implements OnInit, OnDestroy {

  addPhraseKey: any = {};
  isNew = false;
  key: any = {key: "", value: ""};
  cke4Config;
  phraseUserId: any;
  currentUser: any;
  dictionary: any = [];
  uniqueKeyError: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<DrivePhraseEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ckeditorService: CKEditorService,
    private authService: AuthService,
    private ngZone: NgZone,

  ) {
    this.currentUser = this.authService.currentUser;

    this.cke4Config = {
      ...this.ckeditorService.getDefaultConfig(),
      editable: true
    };
    const extraPlugins = this.cke4Config.extraPlugins.split(',');
    this.cke4Config.extraPlugins = [...extraPlugins, 'selectdropdown'].join(',')

    if (data.dictionaryElement?.user_id) {
      this.phraseUserId = data.dictionaryElement.user_id;
    }
    console.log('data', data); 
    if (data) {
      this.cke4Config.editable = data.editable;
      if(data.dictionaryElement === undefined) {
        this.key.key = "";
        this.key.value = "";
      }
      else{
      this.key = data.dictionaryElement;
      data.dictionary = data.dictionary.filter(ele=> (ele.key !== data.dictionaryElement.key && ele.key !== data.dictionaryElement.value));
      }
      this.dictionary = data.dictionary;
    }
    this.isNew = data ? false : true;
  }

  ngOnInit(): void {
    this.initGlobalScope();
  }

  initGlobalScope() {
    window['angularComponentReference'] = {
        component: this, zone: this.ngZone,
        componentDataMembers: {
            componentName: 'drive-phrase',
            selectdropdown: {
                disableOptionSelection: true
            }
        },

        componentFunctions: {
        }
    };
}
  checkError(key) {
    for(let i=0; i < this.dictionary.length; i++ ){
      if(this.dictionary[i].key === key) {
        this.uniqueKeyError = true;
        break;
      }
      else {
        this.uniqueKeyError = false;
      }
    }
  }
  onReadyCkeditor( e ) {
    const { editor } = e;

    this.ckeditorService.setEditorInstance( editor );
    this.ckeditorService.ckeditorRemoveTitle( editor );
  }

  ngOnDestroy() {
    this.ckeditorService.destroyEditorInstance();
  }

  onSave() {
    // if ( this.key.phrase_type === 'text_based' || this.key.phrase_type == null ) {
    this.key.value = this.ckeditorService.getEditorInstance().getData();
    // }

    this.dialogRef.close( this.key );
  }
}
