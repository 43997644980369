<ng-container>
    <div class="tag-settings">
    <div class=" white-rounded-rect">
        <div class="flex items-baseline justify-end">
            <button mat-raised-button class="dn-button-primary" (click)="addNewTag()">
                Add Tag
            </button>
        </div>
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
    <div>
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                    {{element.name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="color">
                <th mat-header-cell *matHeaderCellDef>Color</th>
                <td mat-cell *matCellDef="let element">
                    <input [style.background]="element.color"
                        class="tags-column" />
                </td>
            </ng-container>

            <ng-container matColumnDef="phrase">
                <th mat-header-cell *matHeaderCellDef>Phrase</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.phrase }}
                </td>
            </ng-container>

            <ng-container matColumnDef="phrase_text">
                <th mat-header-cell *matHeaderCellDef>Phrase text</th>
                <td mat-cell *matCellDef="let element" class="crop"  matTooltip="{{element.extracted_phrase_text}}" matTooltipPosition="above">
                    {{ element.extracted_phrase_text }}
                </td>
            </ng-container>

            <ng-container matColumnDef="operation">
                <th mat-header-cell *matHeaderCellDef mat-sort-header-container></th>
                <td mat-cell *matCellDef="let element; let i= index">
                    <a style="cursor: pointer" (click)="updateTag(i, element)" *ngIf="!element.shouldUpdate">
                        <i class="icon icon-edit" matTooltip="Edit" matTooltipPosition="above" id="edit-icon"></i>
                    </a>
                    <a style="cursor: pointer; margin-left: 1rem;" (click)="deleteTag(i, element);"
                        *ngIf="!element.shouldDelete">
                        <i class="icon icon-delete" matTooltip="Delete Tag" matTooltipPosition="above"
                            id="delete-icon"></i>
                    </a>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    </div>
</ng-container>