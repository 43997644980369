import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { PCCService } from 'src/app/services/pcc.service';
import { SocketService } from 'src/app/services/socket.service';
import { LoginService } from '../login/login.service';
import * as global from '../global';
import moment from 'moment'
import LogRocket from 'logrocket';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css'],
  providers: [DatePipe]

})
export class AdminLoginComponent implements OnInit {
  hide = true;
  iOReQA: Boolean = false;
  public user: any;
  status: any;
  verificationResponse: any;
  datas: any;
  encryptedResponse: any;
  private subscription: Subscription;
  email: string = "";
  password: string = "";
  remember_me_check: boolean = false; // Added remember me check #mohsin-dev
  isInProgress = false;
  params: any;
  currentURL: string;
  browserFingerprint;

  public constructor(
    private router: Router,
    public loginService: LoginService,
    private _toastr: ToastrService,
    private _router: Router,
    private _socketService: SocketService,
    private authService: AuthService,
    private pccService: PCCService,
    private toastr: ToastrService,
    public datePipe: DatePipe,
    private _route: ActivatedRoute,
  ) {
    // Initialize email if there is value in remembered_email when next time login is opened #mohsin-dev
    const remembered_email = localStorage.getItem('remembered_email');
    if (typeof remembered_email === 'string' && remembered_email.length) {
      this.remember_me_check = true;
      this.email = remembered_email;
    } else {
      this.remember_me_check = false;
    }

    // window.onpopstate = function() {
    //     window.history.forward()
    // }

    let i = 0;
    let key = sessionStorage.key(0);
    while (key !== null) {
      sessionStorage.removeItem(key);
      i++;
      key = sessionStorage.key(i);
    }
  }

  async ngOnInit() {
    if(global.ENVIRONMENT != 'LOCAL' ){
      // LogRocket.init('mdbzwd/logrocket-demo'); //ali
      LogRocket.init('dn_wc/logrocket-demo_wc'); //rafay
    }
    this._route.paramMap.subscribe(async (params: ParamMap) => {
      // if (params.get('params') !== 'null') {
        this.params = params.get('params');
      // }
    });
    // console.log("sdf",this._route.snapshot.params);
    // this.currentURL = window.location.href;
    // if(this.currentURL.includes('=')) {
    // this.params = this.currentURL.slice(this.currentURL.indexOf('=') + 1);
    // }
    // console.log("this.", this.params);
    // localStorage.removeItem('currentUser');
    const clientDeviceDetails = await this.authService.getClientDeviceDetails();
    this.browserFingerprint = clientDeviceDetails.visitorId;
  }

  async loginWithCognito(form: NgForm) {
    if (!form.valid || !form.touched) return;
    this.isInProgress = true;

    // Add remember_me_check to remember the email next time login is opened #mohsin-dev
    if (form.value.remember_me_check) {
      localStorage.setItem('remembered_email', this.email);
    } else {
      localStorage.removeItem('remembered_email');
    }

    const infoToast = this._toastr.info("Logging in...", 'Please wait', { disableTimeOut: true });
    try {
      const loginURL = window.location.href;
      const loginInfoResponse: any = await this.loginService.loginWithCognito(form.value.email, form.value.password, moment.tz.guess(), this.browserFingerprint, loginURL).toPromise();
      if (loginInfoResponse.status === 200) {
        // this._toastr.success(loginInfoResponse['message'], 'Success');
        const { user, token, mfaSession } = loginInfoResponse.data;
        if (mfaSession) {
          if (this.params) {
            return this.router.navigate(['/admin-auth', { mfaSession, email: form.value.email, params: this.params }]);
          }
          else {
            return this.router.navigate(['/admin-auth', { mfaSession, email: form.value.email }]);
          }
        }

        if ((user.user_type == '2' && loginInfoResponse.data.array && loginInfoResponse.data.array.length > 0) || (user.user_type == global.USER_TYPE.MEDICAL_ASSISTANT && loginInfoResponse.data.array && loginInfoResponse.data.array.length > 0)) {
          loginInfoResponse.data.array.some(element => {
            if (element.qa_e_id == user._id || element.qa_id == user._id) {
              this.iOReQA = true;
              localStorage.setItem('iOReQA', JSON.stringify(this.iOReQA))
            }
          });
        }

        const auth_key = user.web_auth_key + ".WEB";
        user.auth_key = auth_key;
        // ACTIVITY LOG
        this._socketService.addActivityLog({
          id: user._id,
          screen: 'Login',
          operation: 'Login API Call',
          datetime: this.datePipe.transform(new Date(), global.date_time_format)

        });

        if (token) {
          this.authService.setToken(token);
        }
        
        localStorage.setItem('currentUserAuth', JSON.stringify(user));
        if (user.cognito) {
          this._toastr.clear(infoToast.toastId);
          if (user.two_factor_auth) {
            const verficationCodeInfoToast = this._toastr.info('Sending verification code...', "Please wait", { disableTimeOut: true });
            const respones = await this.loginService.send2FactorVerificationCode(user, token && token.idToken).toPromise();
            this._toastr.clear(verficationCodeInfoToast.toastId);

            this.verificationResponse = respones;
            if (this.verificationResponse['status'] === 200) {
              // this._toastr.success(this.verificationResponse['message'], 'Success');
              if (this.params) {
                this.router.navigate(['/admin-auth', { params: this.params }]);
              }
              else {
                this.router.navigate(['/admin-auth']);
              }

            } else {
              this._toastr.error(this.verificationResponse['message'], 'Failed');
            }
            this._toastr.clear(verficationCodeInfoToast.toastId);
          } else {
            console.log("this.params", this.params);
            this.authService.currentUser = user;
            if (this.params) {
              this.router.navigate(['/company/' + user.company_id + '/' + this.params]);
            }
            else {
              this.router.navigate(['/company/' + user.company_id]);
            }

          }
        } else {
          this._toastr.warning('This account is not verified, please verify first', "Warning");
          // localStorage.setItem('register_auth', JSON.stringify(user));
          this._router.navigate([`/company/${user.company_id}/user-create-password`],{queryParams: {user_id: user._id, email: user.email}})
        }
      } 
      else if(loginInfoResponse.status === 1000){
        this._router.navigate([`/company/${loginInfoResponse.data.companyId}/user-create-password`],{queryParams: {user_id: loginInfoResponse.data.userId, email: loginInfoResponse.data.email}})
      }
      else {
        this._toastr.error(loginInfoResponse.message, 'Failed');
        this.password = '';
        //  form.resetForm();
      }
      this._toastr.clear(infoToast.toastId);
    } catch (error) {
      console.error(error);
      this._toastr.clear();
    } finally {
      this._toastr.clear(infoToast.toastId);
      this.isInProgress = false;
    }

    if (form.invalid) {
      return;
    }

  }

}
