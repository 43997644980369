import { Component, Input, OnInit } from '@angular/core';
import { PCCService } from 'src/app/services/pcc.service';
import moment from 'moment'
import * as global from '../global'
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import {PatientImporterDialog} from '../patient-rounding-sheets/patient-importer-dialog/patient-importer-dialog';
import { eventTypes } from 'src/app/constants/pcc_webhook_constants';

@Component({
  selector: 'app-pcc-notification-panel',
  templateUrl: './pcc-notification-panel.component.html',
  styleUrls: ['./pcc-notification-panel.component.css']
})
export class PccNotificationPanelComponent implements OnInit {
  global = global;
  notifications = [];
  currentUser;
  @Input() primaryProviderId;

  constructor(
    private _pccService:PCCService,
    private _authService:AuthService,
    private toastr:ToastrService,
    private dialog: MatDialog,
  ) { 
    this.currentUser = this._authService.currentUser;
  }

  ngOnInit() {
    this._pccService.getPCCEventNotifications().subscribe((response: any) => {
      if(response.status === 200) {
        this.notifications = response.data.array.map(notification => {
          notification.createdOn = moment(notification.createdOn).format(global.moment_date_time_format);

          if(!notification.seenBy.includes(this.currentUser._id)) {
            this.markSeenBy(notification);
          }

          return notification;
        });
      }
    });

    this._pccService.webhookNotficationReceive.subscribe((pusherData) => {
      this.notifications.unshift(pusherData.payload);
    })
  }
  
  importPCCFacilityAndPatient(notification) {
    const infoToast = this.toastr.info('Importing Patient', 'Please wait', {disableTimeOut: true});
    const pccOrgUuid = notification.eventPayload.orgUuid;
    const pccFacId = notification.eventPayload.facId;
    const pccPatientId = notification.eventPayload.patientId;
    const company_id = this._authService.currentUser.company_id;
      this._pccService.importPCCFacilityAndPatient(pccOrgUuid, pccFacId, this.primaryProviderId, pccPatientId, company_id).subscribe((response:any) => {
          if(response.status === 200) {
            this.toastr.clear(infoToast.toastId)
            // this.toastr.success('Imported Successfully','Success')

            this.markImportedBy(notification);            
          }else {
            this.toastr.error('Patient could not be imported at this time.', 'Failed')
          }
      }, 
      () => this.toastr.error('Patient could not be imported at this time.', 'Failed'),
      () => this.toastr.clear(infoToast.toastId))
  }

  importPCCPatient(notification, facilityId) {
    const primaryProviderId = this.currentUser._id;
    const pccPatientId = notification.patient.patientId;
    const pccOrgUuid = this.currentUser.pcc_auth.orgUuid
    const infoToast = this.toastr.info('Importing Patient', 'Please wait', {disableTimeOut: true});
    const selectedFacilityId = facilityId;

    this._pccService.importPCCPatient(pccOrgUuid, pccPatientId, primaryProviderId, selectedFacilityId).subscribe((response:any) => {
        if(response.status === 200) {
          this.toastr.clear(infoToast.toastId)
          // this.toastr.success('Imported Successfully','Success');
          const patient = response.data;
          this.showPatientImporterDialog(patient)
          this.markImportedBy(notification);            
        }else {
          this.toastr.error('Patient could not be imported at this time.', 'Failed')
        }
    }, 
      () => this.toastr.error('Patient could not be imported at this time.', 'Failed'),
      () => this.toastr.clear(infoToast.toastId));
}


  markImportedBy(notification) {
    const pccPatientId = notification.eventPayload.patientId;
    this._pccService.updatePCCEventNotificationBy(notification._id, 'importedBy').subscribe((response:any) => {
      if(response.status == 200) {
        const notification = this.notifications.find(n => n.eventPayload.patientId == pccPatientId);
        if(notification) {
          notification.importedBy.push(this.currentUser._id);
        }
      }
    });
  }

  markSeenBy(notification) {
    if(Array.isArray(notification.seenBy) && !notification.seenBy.includes(this.currentUser._id)) {
      this._pccService.updatePCCEventNotificationBy(notification._id, 'seenBy').subscribe();
    }
  }

  isImportVisible(notification) {
    // PATIENT_ADMIT
    
    return notification.eventType === eventTypes.PATIENT_ADMIT;
  }
  formatEventType(eventType) {
    // console.log(eventType);
    
   return eventType.split('.').map(word => {
      return word[0].toUpperCase() + word.substr(1).toLowerCase()
    }).join(' ')
  }
  
  showPatientImporterDialog(patient) {
    console.log('showPatientImporterDialog');
    
    this.dialog.open(PatientImporterDialog, {width: '50%', data: {patient, action: 'import'}});
  }

}
