import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProductOwnerAndFullAdminGuard implements CanActivate {
  constructor(
    private _authService: AuthService,
    private _router: Router,
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this._authService.currentUser?.product_owner || this._authService.currentUser?.admin_type === 'full_admin' || this._authService.currentUser?.admin_type === 'system_admin') {
        return true;
      }
      else {
        this._router.navigate(['/403']);
        return false;
      }
  }
  
}
