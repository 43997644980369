import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { LayoutModule } from '../../layout/layout.module';
// import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
// import { DoctorProfileComponent } from './doctor-profile/doctor-profile.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MatRadioModule } from "@angular/material/radio";
import { CompanyComponent } from './company.component';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { CompanyUserManagementComponent } from './company-user-management/company-user-management.component';
import { CompanyFacilitiesComponent } from './company-facilities/company-facilities.component';
import { CompanyProfileFormComponent } from './company-profile/company-profile-form/company-profile-form.component';
import { CompanyUserManagementFormComponent } from './company-user-management/company-user-management-add-user/company-user-management-form/company-user-management-form.component';
import { CompanyUserManagementAddUserComponent } from './company-user-management/company-user-management-add-user/company-user-management-add-user.component';
// import { CompanyFacilityFormComponent } from './company-facilities/company-add-facility/company-facility-form/company-facility-form.component';
import { CompanyAddFacilityComponent } from './company-facilities/company-add-facility/company-add-facility.component';
import { CompanyUserProfileComponent } from './company-user-profile/company-user-profile.component';
import { CompanyUserCreatePasswordComponent } from './company-user-create-password/company-user-create-password.component';
import { FacilityProviderAssociationComponent } from './company-facilities/facility-provider-association/facility-provider-association.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ImeProviderAssociationComponent } from './company-imes/ime-provider-association/ime-provider-association.component';
import { CompanyImesComponent } from './company-imes/company-imes.component';
import { CompanyAddImeComponent } from './company-imes/company-add-ime/company-add-ime.component';
import { ProviderTranscriberAssociationComponent } from './company-user-management/provider-transcriber-association/provider-transcriber-association.component';
import { UserTimeScheduleComponent } from './company-user-management/user-time-schedule/user-time-schedule.component';
import { AppModule } from "../../app.module";
import { MinTimePipe } from "../../shared/pipes/min-time.pipe";
import { UserFacilityComponent } from './company-user-management/user-facility/user-facility.component';
import { UserImeListComponent } from './company-user-management/user-ime-list/user-ime-list.component';
// import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { MipsDashboardComponent } from './mips/mips-dashboard/mips-dashboard/mips-dashboard.component';
import { CompanyFacilityFormComponent } from './company-facilities/company-add-facility/company-facility-form/company-facility-form.component';
import { CompanyAssociationComponent } from './company-association/company-association.component';
import { MipsQuestionComponent } from './mips/mips-dashboard/mips-questions/mips-questions.component';
import { AdminPccFacilitiesComponent } from './admin-pcc-facilities/admin-pcc-facilities.component';
import { CreateStrongPasswordComponent } from './create-strong-password/create-strong-password.component';
import { AssociatedProviderDialogComponent } from './associated-provider-dialog/associated-provider-dialog.component';
import { FacilityDeactivationDialogComponent } from './admin-pcc-facilities/email-template-dialog/facility-deactivation-dialog.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { FacilityActivationEmailComponent } from './facility-activation-email/facility-activation-email.component';
import { FacilityActivationEmailDialogComponent } from './facility-activation-email/facility-activation-email-dialog/facility-activation-email-dialog.component';
import { AssociatedCompaniesDialogComponent } from './associated-companies-dialog/associated-companies-dialog.component';
import { ManageCptCodesComponent } from './manage-cpt-codes/manage-cpt-codes.component';
import { AddNewCptCodeComponent } from './manage-cpt-codes/add-new-cpt-code/add-new-cpt-code.component';
import { ViewCptCodesComponent } from './manage-cpt-codes/view-cpt-codes/view-cpt-codes.component';
import { CompanyFacilityTabsComponent } from './company-facilities/company-facility-tabs/company-facility-tabs.component';
import { FacilitySettingsComponent } from './company-facilities/facility-settings/facility-settings.component';
import { CompanyFacilityContactComponent } from './company-facilities/company-facility-contact/company-facility-contact.component';
import { WoundNurseHeaderComponent } from 'src/app/includes/wound-nurse-header/wound-nurse-header.component';
import { MatMenuModule } from '@angular/material/menu';
import { CompanyFacilityPermissionsComponent } from './company-facilities/company-facility-permissions/company-facility-permissions.component';
import { ApproveByAdminComponent } from './approve-by-admin/approve-by-admin.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ManageCptsComponent } from './manage-cpts/manage-cpts.component';
import { ManageCptsIcdsSettingsComponent } from './manage-cpts-icds-settings/manage-cpts-icds-settings.component';
import { ManageIcdCodesComponent } from './manage-icd-codes/manage-icd-codes.component';
import { DialogueAddCptCodeComponent } from './manage-cpts/dialogue-add-cpt-code/dialogue-add-cpt-code.component';
import { DialogueManageCptCodesComponent } from './manage-cpts/dialogue-manage-cpt-codes/dialogue-manage-cpt-codes.component';
import { DialogueAddIcdCodeComponent } from './manage-icd-codes/dialogue-add-icd-code/dialogue-add-icd-code.component';
import { DialogueManageIcdCodesComponent } from './manage-icd-codes/dialogue-manage-icd-codes/dialogue-manage-icd-codes.component';
import { IeFuMappingComponent } from './ie-fu-mapping/ie-fu-mapping.component';
import { CptPosComponent } from './manage-cpt-codes/cpt-pos/cpt-pos.component';
import { AddIcdToProviderComponent } from './manage-icd-codes/add-icd-to-provider/add-icd-to-provider.component';
// import { CompanyFacilityFormComponent } from './company-facilities/company-facility-form/company-facility-form.component';
// import { CompanyFacilityFormComponent } from './company-facilities/company-facility-form/company-facility-form.component';
// import { AddMipsComponent } from './mips/mips-dashboard/mips-dashboard/add-mips/add-mips/add-mips.component';
@NgModule({
    declarations: [
        CompanyComponent,
        MinTimePipe,
        CompanyProfileComponent,
        CompanyUserManagementComponent,
        CompanyFacilitiesComponent,
        CompanyProfileFormComponent,
        CompanyUserManagementFormComponent,
        CompanyUserManagementAddUserComponent,
        CompanyFacilityFormComponent,
        CompanyAddFacilityComponent,
        CompanyUserProfileComponent,
        CompanyUserCreatePasswordComponent,
        CreateStrongPasswordComponent,
        FacilityProviderAssociationComponent,
        ImeProviderAssociationComponent,
        CompanyImesComponent,
        CompanyAddImeComponent,
        ProviderTranscriberAssociationComponent,
        UserTimeScheduleComponent,
        UserFacilityComponent,
        UserImeListComponent,
        MipsDashboardComponent,
        MipsQuestionComponent,
        CompanyAssociationComponent,
        AdminPccFacilitiesComponent,
        AssociatedProviderDialogComponent,
        FacilityDeactivationDialogComponent,
        FacilityActivationEmailComponent,
        FacilityActivationEmailDialogComponent,
        AssociatedCompaniesDialogComponent,
        ManageCptCodesComponent,
        AddNewCptCodeComponent,
        ViewCptCodesComponent,
        CompanyFacilityTabsComponent,
        FacilitySettingsComponent,
        CompanyFacilityContactComponent,
        WoundNurseHeaderComponent,
        CompanyFacilityPermissionsComponent,
        ApproveByAdminComponent,
        ManageIcdCodesComponent,
        DialogueManageIcdCodesComponent,
        DialogueAddIcdCodeComponent,
        ManageCptsIcdsSettingsComponent,
        AddIcdToProviderComponent,
        ManageCptsIcdsSettingsComponent,
        DialogueManageIcdCodesComponent,
        ManageCptsComponent,
        DialogueAddCptCodeComponent,
        DialogueManageCptCodesComponent,
        CptPosComponent,
        IeFuMappingComponent

        // AddMipsComponent
    ],
    imports: [
        CommonModule,
        // PasswordStrengthMeterModule,
        LayoutModule,
        MaterialModule,
        FormsModule,
        BrowserModule,
        RouterModule,
        FormsModule,
        // FlexLayoutModule,
        MatRadioModule,
        MatSlideToggleModule,
        CKEditorModule,
        MatMenuModule,
        BrowserAnimationsModule,
    ],
    exports: [CompanyComponent, ManageIcdCodesComponent],
    providers: [],
    // entryComponents: [
    //     FacilityDeactivationDialogComponent,
    //     FacilityActivationEmailDialogComponent,
    //     AddNewCptCodeComponent,
    //     ViewCptCodesComponent,
    //     DialogueAddIcdCodeComponent,
    //     DialogueManageIcdCodesComponent,
    //     AddIcdToProviderComponent,
    // ],
    bootstrap: [CompanyComponent],
})
export class CompanyModule {

}