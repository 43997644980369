type DayShedule = {
    availability: boolean,
    start_time: string,
    end_time: string
}
export class DoctorTimeShedule {
    user_id_ref:string|object;
    user_type:string;
    Monday: DayShedule = {
        availability: false,
        start_time: null,
        end_time: null
    };
    Tuesday: DayShedule = {
        availability: false,
        start_time: null,
        end_time: null
    };
    Wednesday: DayShedule = {
        availability: false,
        start_time: null,
        end_time: null
    };
    Thursday: DayShedule = {
        availability: false,
        start_time: null,
        end_time: null
    };
    Friday: DayShedule = {
        availability: false,
        start_time: null,
        end_time: null
    };
}