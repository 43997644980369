<div class="flex items-start justify-start" style="overflow: auto;height: 100%;">
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="phrase_key">
            <th mat-header-cell *matHeaderCellDef style="width: 13%;">Phrase </th>
            <td mat-cell *matCellDef="let element;let i=index">
                <mat-form-field style="width: 100%;" class="text-field">
                    <input matInput [value]="'.' + element.phrase_key" style="color:#000;height: 40px;padding-left: 5px;" [readonly]="element._id" />
                </mat-form-field>
            </td>
        </ng-container>
        <ng-container matColumnDef="template">
            <th mat-header-cell *matHeaderCellDef>Template </th>
            <td mat-cell *matCellDef="let element;let i=index">
                <mat-form-field style="width: 100%;" class="text-field">
                    <input matInput [value]="convertHtmltotext(element.template)" style="color:#000;height: 40px;padding-left: 5px;" [readonly]="element._id" />
                </mat-form-field>
            </td>
        </ng-container>
        <ng-container matColumnDef="operation">
            <th mat-header-cell *matHeaderCellDef style="width: 5%;"></th>
            <td mat-cell *matCellDef="let element;let i=index">
                <div class="operation-buttons buttons-div flex flex-inline items-center justify-center gap-2">
                    <span class="material-icons" (click)="showEditorDialog(element)"
                        matTooltip="Edit Phrase" matTooltipPosition="above" style="cursor: pointer;">
                        edit_note
                    </span>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>