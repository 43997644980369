

import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { ToastrComponentlessModule, ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { FacilityService } from 'src/app/services/facility.service';
import { PatientRoundingSheetService } from '../patient-rounding-sheets/patient-rounding-sheet.service';
import * as global from '.././global'
import moment from 'moment'
import { DashboardService } from '.././dashboard/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { CensusRecurrentService } from 'src/app/services/censusRecurrent.service';
import { RecurrentOptionDialogComponent } from '../recurrent-option-dialog/recurrent-option-dialog.component';
import { lastValueFrom } from 'rxjs';
import { SnfWcNurseAssociationService } from '../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
export interface PatientRoundingSheetDialogData {
  pusherDataPayload: any
}
export interface filter_facility_id {
  filter_facility_id: string
}

@Component({
  selector: 'app-create-census-calender',
  templateUrl: './create-census-calender.component.html',
  styleUrls: ['./create-census-calender.component.css']
})
export class CreateCensusCalenderComponent implements OnInit {

  global = global;
  currentUser: any;
  currentUserDetails;
  name: string = "Daily Census";
  facility: any;
  filter_facility_id;
  date: Date = new Date();
  patientRoundingSheets: any = [];
  facilities: any = [];
  providers: any = [];
  currentProvider: any;
  selected: any = "";
  companySide: boolean = false;
  patientRoundingSheet: any; // for copying patients
  isCopyPatients = false;
  ischargecheck = false;
  isnotecheck = false;
  isvoidcheck = false;
  minDate = moment(new Date()).subtract(60, 'day').toDate();
  time_zone: 'America/New_York';
  DOSPlaceholder = 'Visit Date'
  zone = "";
  serviceDate: any;
  date_obj;
  @ViewChild('censusSelect') censusSelect;
  carryChargeStatus: any;
  calender_date: any;
  isRecurrentCensus: any;
  selectedWeeks:1;
  weeks:any;
  workingDays:any;
  recurrenceMap: any;
  date_objects: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(MAT_DIALOG_DATA) public facilityId: filter_facility_id,
    
    public dialogRef: MatDialogRef<CreateCensusCalenderComponent>,
    private dialog: MatDialog,
    private _authService: AuthService,
    private facilityService: FacilityService,
    private _patientRoundingSheetService: PatientRoundingSheetService,
    private toastr: ToastrService,
    private _dashboardService: DashboardService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _CensusRecurrentService:CensusRecurrentService,
    private _company: CompanyService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,


  ) {
    this.currentUser = this._authService.currentUser;
    this.currentUserDetails = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type
    }
    this.handleDateChange();


    if (this.data.calender_date) {
      
      this.calender_date = this.data.calender_date;
      
      var [year, month, day] = this.getDate().split('-');
      this.date_obj = {
        year, month, day
      }
      //console.log("this.date_obj : ", this.date_obj);

    }

  }



  async ngOnInit() {
    await this._company.getCompanyChargeandSignNoteStatus(this.currentUser.company_id).subscribe((res: any) => {
      if (res.status == 200) {
        this.carryChargeStatus = res.data[0].charges_carry_forward;
      }
    });
    // //console.log("This.data:  ", this.data);

    // if (this.data.calender_date) {
      
    //   this.calender_date = this.data.calender_date;
      
    //   var [year, month, day] = this.getDate().split('-');
    //   this.date_obj = {
    //     year, month, day
    //   }
    //   //console.log("this.date_obj : ", this.date_obj);

    // }

    if (window.location.pathname.includes('company') && window.location.pathname.includes('provider_id')) {
      //console.log("path", window.location.pathname);
      this.companySide = true;
    }
    if (this.data?.filter_facility_id) {
      this.facility = this.data.filter_facility_id;
    }
    else if (this.facilityId?.filter_facility_id) {
      this.facility = this.facilityId.filter_facility_id;
    }
    // var [year, month, day] = this.getDate().split('-');
    // this.date_obj = {
    //   year, month, day
    // }
    this.initProviders();
    await this.initFacilities();
    this.attachTimeToDate();
  }
  getDate() {
    /////   date from calender
    return moment(this.calender_date).format('YYYY-MM-DD');
  }

  getCensusDate(census) {
    if (census.date_obj) {
      let { year, month, day } = census.date_obj;
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      return `${month}/${day}/${year}`;
    } else {
      return moment(census.date).format('MM/DD/YYYY');
    }
  }

  initPatientRoundingSheets(provider_id) {
    // //console.log("this.fac", this.facility);
    if (provider_id) {
      this._patientRoundingSheetService.getCensusList(this.facility, provider_id).subscribe((response: any) => {
        if (response.status === 200) {
          this.patientRoundingSheets = response.data.array;
          if (this.patientRoundingSheets.length > 0) {
            this.patientRoundingSheet = this.patientRoundingSheets[0];
          }
        }
        else if (response.status === 403) {
          this.patientRoundingSheets = [];
        }
      },
        () => this.toastr.error('Something went wrong', 'Failed'))
    }
  }

  async initFacilities() {
    if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER || this.companySide) {
      // //console.log("data",this.data);
      let response: any = await this.facilityService.getMyFacilities(this.data?.filter_provider_id).toPromise()
      if (this.data?.filter_provider_id && response.status === 200) {
        this.facilities = response.data.array;
        // this.facility = this.facilityId;
        this.facility = this.data.filter_facility_id;
        // //console.log('facility Id',this.facilityId);
      }
      // });
    }
    else if (this.currentUser.user_type === global.USER_TYPE.DOCTOR && !this.companySide) {
      let response: any = await this.facilityService.getMyFacilities().toPromise()
      if (response.status === 200) {
        this.facilities = response.data.array;
        this.facility = this.facilityId?.filter_facility_id;
        //console.log('facility Id', this.facilityId);

      }
    }else if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      const filter = {
        associated_snf_wc_nurse_ids: this.data?.filter_provider_id||this.currentUser._id,
      };
      const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      const facilityProjection = {
        title: 1,
        pcc_facId: 1,
        pcc_2_legged_authentication: 1,
        source: 1,
        timeZone: 1,
        pcc_orgUuid: 1
      };
      const response: any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
      if (response.status === 200 && response.data.length > 0) {
        this.facilities = response.data;
      }
      
    }
  }

  async initProviders() {
    if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER || this.companySide) {
      if (!this.data?.filter_provider_id) return;
      this._dashboardService.getUserDetail(this.data?.filter_provider_id).subscribe((response: any) => {
        if (response.status === 200) {
          this.currentProvider = response.data.array;
          this.selected = this.currentProvider._id;
        }
      });
      this._dashboardService.getP2PRelation(null, this.data?.filter_provider_id).subscribe((response: any) => {
        if (response.status === 200) {
          this.providers = response.data.assoc_provider_id;
          if (this.currentUser.USER_TYPE === global.USER_TYPE.DOCTOR)
            this.providers.unshift(this.currentProvider);
        }
      });
      this.initPatientRoundingSheets(this.data?.filter_provider_id);
      // //console.log(this.selected);
      this.handleCopyPatients(this.selected);
    }
    else if (this.currentUser.user_type === global.USER_TYPE.DOCTOR && !this.companySide) {
      this._dashboardService.getP2PRelation().subscribe((response: any) => {
        if (response.status === 200) {
          this.providers = response.data.assoc_provider_id;
          // this.providers.unshift(this._authService.currentUser);
        }
      });
      this.initPatientRoundingSheets(this._authService.currentUser._id);
      this.selected = this.currentUser._id;
      this.handleCopyPatients(this.selected);
    } else if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      const filter = {
        snf_wc_nurse_id: this._authService.currentUser._id,
        company_id: this._authService.currentUser.company_id
      };
      const project = {
        first_name: 1,
        last_name: 1,
        title: 1,
        company_id: 1
      };
      const doctorsResponse: any = await lastValueFrom(this._snfWcNurseAssociationService.getAssociatedSnfWcNurses(filter, project));
      if (doctorsResponse.status === 200) {
        this.providers = doctorsResponse.data.associated_snf_wc_nurse_ids || [];
        this.providers.concat(this.currentUser);
        this.providers.unshift(this.currentUser);
        if(this.data.filter_provider_id) {
          this.selected = this.data.filter_provider_id;
        }else {
          this.selected = this.providers[0]._id;
        }
        this.initPatientRoundingSheets(this.selected);
      }
    }
  }


  async addRecurrentPatientRoundingSheets(previous_census_id, date_objects, recurrence_map) {
    let patientRoundingSheetId = undefined;
    if (previous_census_id && this.isRecurrentCensus) {
      patientRoundingSheetId = previous_census_id;
    }else return;

    let oldPatientRoundingSheetId = undefined;
    if (this.patientRoundingSheets.length > 0 && this.isCopyPatients) {
      oldPatientRoundingSheetId = this.patientRoundingSheet._id;
    }

    let selected_facility = this.facilities.filter(item => item._id === this.facility)

    const details = {
      ...this.currentUserDetails,
      facilityId: this.facility,
      name: this.name,
      ischargecheck: this.ischargecheck,
      isnotecheck: this.isnotecheck,
      parent_census_id:patientRoundingSheetId,
      date_objs:date_objects,
      provider_id:this.currentUser._id,
      company_id:this.currentUser.company_id,
      recurrence_map:recurrence_map,
      oldPatientRoundingSheetId:oldPatientRoundingSheetId
    }

    if (selected_facility[0].pcc_timeZone) {
      this.time_zone = selected_facility[0].pcc_timeZone
    }
    else if (selected_facility[0].timeZone) {
      this.time_zone = selected_facility[0].timeZone
    }

    let clientOffSet = moment(this.date).utcOffset();
    let facOffSet = moment().tz(this.time_zone).utcOffset();
  
    let gValue, lValue;
    if (clientOffSet >= facOffSet) {
      gValue = clientOffSet;
      lValue = facOffSet;
    }
    else {
      gValue = facOffSet;
      lValue = clientOffSet;
    }

    let diffBetweenOffset = gValue - lValue
    let newDate;

    if (diffBetweenOffset >= 0) {
      if (clientOffSet < 0 && facOffSet < 0) {
        newDate = moment(this.date).subtract(diffBetweenOffset, 'minutes')
      }
      else {
        newDate = moment(this.date).add(diffBetweenOffset, 'minutes')
      }
    }
    else {
      newDate = moment(this.date).add(diffBetweenOffset, 'minutes')
    }

    details.date = newDate;
    const currentDate = new Date();

    details.date_obj = this.date_obj;
    details.date_obj.hour = currentDate.getHours();
    details.date_obj.minute = currentDate.getMinutes();

    
    if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER || this.companySide) {
      details.doctor_id = this.currentProvider?._id;
    }
    const infoToast = this.toastr.info('Creating Recurrent Patient Census', 'Please wait', { disableTimeOut: true });
    //console.log("details : :", details);


    // return;

    await this._CensusRecurrentService.addNewRecurrentCensuses(details).subscribe(async (response: any) => {
      if (response.status === 200) {
       
        this.toastr.clear(infoToast.toastId);
        this.dialogRef.close(response.data);
        //console.log("res");
  
      }
    },
      () => this.toastr.error('Something went wrong creating rounding sheet', 'Failed'),

    )
  }



  async addPatientRoundingSheet() {
    let patientRoundingSheetId = undefined;
    if (this.patientRoundingSheets.length > 0 && this.isCopyPatients) {
      patientRoundingSheetId = this.patientRoundingSheet._id;
    }
    // this.attachTimeToDate();
    let selected_facility = this.facilities.filter(item => item._id === this.facility)
    const details = {
      ...this.currentUserDetails,
      facilityId: this.facility,
      name: this.name,
      ischargecheck: this.ischargecheck,
      isnotecheck: this.isnotecheck,
      is_recurrent:this.recurrenceMap?.length?this.isRecurrentCensus:false,
      // date:selected_facility[0].pcc_timeZone?moment(new Date(this.date).toLocaleString('en-US', { timeZone: selected_facility[0].pcc_timeZone })):moment(new Date(this.date).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })).format('MMM DD, YYYY'),
      // date: moment(this.date).toDate(),
      patientRoundingSheetId
    }
    if (selected_facility[0].pcc_timeZone) {
      this.time_zone = selected_facility[0].pcc_timeZone
      // details.date = moment(new Date(this.date).toLocaleString('en-US', { timeZone: selected_facility[0].pcc_timeZone })).utc()
    }
    else if (selected_facility[0].timeZone) {
      this.time_zone = selected_facility[0].timeZone
      // details.date = moment(new Date(this.date).toLocaleString('en-US', { timeZone: selected_facility[0].timeZone })).utc()
    }
    else {
      // details.date = moment(new Date(this.date).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })).utc()
    }

    let clientOffSet = moment(this.date).utcOffset();
    let facOffSet = moment().tz(this.time_zone).utcOffset();
    // let facOffSet = moment(aa).utcOffset();

    // //console.log('111111', moment(event.value).utc().utcOffset()); 
    // //console.log('222222', moment(facOffSet).utcOffset());
    let gValue, lValue;
    if (clientOffSet >= facOffSet) {
      gValue = clientOffSet;
      lValue = facOffSet;
    }
    else {
      gValue = facOffSet;
      lValue = clientOffSet;
    }
    // let diffBetweenOffset = 300 - -300 = 300 + 300 = 600
    // //console.log("clientOffSet: ", clientOffSet);
    // //console.log("facOffSet: ", facOffSet);

    let diffBetweenOffset = gValue - lValue
    // //console.log("diffBetweenOffset: ", diffBetweenOffset);
    let newDate;
    // newDate = moment(event.value).add(diffBetweenOffset, 'minutes')
    if (diffBetweenOffset >= 0) {
      if (clientOffSet < 0 && facOffSet < 0) {
        newDate = moment(this.date).subtract(diffBetweenOffset, 'minutes')
      }
      else {
        newDate = moment(this.date).add(diffBetweenOffset, 'minutes')
      }
    }
    else {
      newDate = moment(this.date).add(diffBetweenOffset, 'minutes')
    }
    details.date = newDate;
    const currentDate = new Date();
    details.date_obj = this.date_obj;
    details.date_obj.hour = currentDate.getHours();
    details.date_obj.minute = currentDate.getMinutes();

    // details.date = moment(newDate).utc().tz(this.time_zone); 
    // let timezone_date = new Date(details.date._i);
    // details.name =  this.months[timezone_date.getMonth()] + " " + timezone_date.getDate() + ", " + timezone_date.getFullYear();
    if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER || this.companySide) {
      details.doctor_id = this.currentProvider?._id;
    }
    const infoToast = this.toastr.info('Creating Patient Census', 'Please wait', { disableTimeOut: true });
    //console.log("details : :", details);
    await this._patientRoundingSheetService.addPatientRoundingSheet(details).subscribe(async (response: any) => {
      if (response.status === 200) {
        if(this.recurrenceMap?.length){
        this.toastr.clear(infoToast.toastId);
          await this.addRecurrentPatientRoundingSheets(response.data._id, this.date_objects, this.recurrenceMap);
        }else{
          this.toastr.clear(infoToast.toastId);
          this.dialogRef.close(response.data);

        }
 
        if (this.isvoidcheck) {
          let detail = {
            _id: this.patientRoundingSheet._id,
            is_void: true,
            date: this.patientRoundingSheet.date
          }
          if (this.patientRoundingSheet.date_obj) {

            detail['date_obj'] = this.patientRoundingSheet.date_obj
          }
          let res = await this._patientRoundingSheetService.updatePatientRoundingSheet(detail).toPromise();
        }
        //console.log("res");
  
      }
    },
      () => this.toastr.error('Something went wrong creating rounding sheet', 'Failed'),
      // () => {
      //   //console.log("this")
      // this.toastr.clear(infoToast.toastId)
      // this.dialogRef.close()
      // }

    )
  }

  EnvCheck() {
    //console.log("global.ENVIRONMENT", global.ENVIRONMENT);
    if (global.ENVIRONMENT == 'DEV' || global.ENVIRONMENT == 'LOCAL') {
      return true;
    } else return false;
  }


  handleSubmit(event) {


    // return;
    if (!this.name) {
      return this.toastr.warning('Please select facility', 'Warning')
    }
    if (!this.facility) {
      return this.toastr.warning('Please select facility', 'Warning')
    }
    if (!this.date_obj) {
      return this.toastr.warning('Please enter date', 'Warning')
    }
    this.addPatientRoundingSheet()
  }

  setTime() {
    let clientOffSet = moment(new Date()).utcOffset();
    let facOffSet = moment().tz(this.time_zone).utcOffset();
    // let facOffSet = moment(aa).utcOffset();

    // //console.log('111111', moment(event.value).utc().utcOffset()); 
    // //console.log('222222', moment(facOffSet).utcOffset());
    let gValue, lValue;
    if (clientOffSet >= facOffSet) {
      gValue = clientOffSet;
      lValue = facOffSet;
    }
    else {
      gValue = facOffSet;
      lValue = clientOffSet;
    }
    // let diffBetweenOffset = 300 - -300 = 300 + 300 = 600
    //console.log("clientOffSet: ", clientOffSet);
    //console.log("facOffSet: ", facOffSet);

    let diffBetweenOffset = gValue - lValue
    // diffBetweenOffset = 0;
    //console.log("diffBetweenOffset: ", diffBetweenOffset);
    let newDate;
    // newDate = moment(event.value).add(diffBetweenOffset, 'minutes')
    if (diffBetweenOffset >= 0) {
      if (clientOffSet < 0 && facOffSet < 0) {
        newDate = moment(new Date()).subtract(diffBetweenOffset, 'minutes')
      }
      else {
        newDate = moment(new Date()).add(diffBetweenOffset, 'minutes')
      }
    }
    else {
      newDate = moment(new Date()).add(diffBetweenOffset, 'minutes')
    }

    //console.log("new Date()=========: ", new Date());
    //console.log("newDate=========: ", newDate);
    this.serviceDate = newDate;
    this.date = this.serviceDate;
  }

  attachTimeToDate(t = null) {
    if (!this.facility) return;
    let selected_facility = this.facilities.filter(item => item._id === this.facility)
    if (selected_facility[0]?.pcc_timeZone) {
      this.time_zone = selected_facility[0].pcc_timeZone
    }
    else if (selected_facility[0]?.timeZone) {
      this.time_zone = selected_facility[0].timeZone
    }
    else {
    }
    this.zone = moment.tz(this.time_zone).zoneAbbr()
    // //console.log("===========: ", this.zone);
    this.DOSPlaceholder = "Visit Date"
    this.DOSPlaceholder = this.DOSPlaceholder + " shown in " + this.zone;

    // this.setTime()
    if (t) {
      this.time_zone = t;
    }
    let facTimeZonedDate = moment(new Date()).tz(this.time_zone)
    this.serviceDate = facTimeZonedDate;
    this.date = this.serviceDate

    // const dateStr = moment(this.date).format('YYYY-MM-DD');
    // const timeStr = moment().format('HH:mm');


    // let date = moment(dateStr);
    // const time = moment(timeStr, 'HH:mm');


    // date.set({
    //   hour: time.get('hour'),
    //   minute: time.get('minute'),
    //   second: time.get('second')
    // });
    // this.date = date.toDate(); 

  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.serviceDate = event.value
    this.date = this.serviceDate
    return;
  }

  handleDateChange() {

    this.date_obj = {
      year: '----',
      month: '--',
      day: '--'
    };
    //console.log(this.date_obj)
  }

  // patientRoundingSheets
  filteredRoundingSheets() {
    return this.patientRoundingSheets.filter(sheet => {
      if (sheet.facility) {
        return sheet.facility._id == this.facility;
      }
      return false;
    })
  }
  handleCopyPatients(provider_id) {
    if (provider_id) {
      if (this.isCopyPatients) {
        this.initPatientRoundingSheets(provider_id);
      }
    }
  }


  fetchCensusList(event) {
    // //console.log("Eveny: ", event);
    // //console.log("this.facility: ", this.facility);

    this.attachTimeToDate()
    if (this.currentUser.user_type === global.USER_TYPE.DOCTOR) {
      this.initPatientRoundingSheets(this.currentUser._id);
    }
  }

  showDialog(e){
    //console.log("e : ", e);
    if(this.isRecurrentCensus){

      const obj = {
        ...this.data,
        date_obj: this.date_obj
        
      }
      const dialogRef = this.dialog.open(RecurrentOptionDialogComponent, {
        data: obj,
        width: '40%'
      });

      dialogRef.afterClosed().subscribe(async (data) => {
        if(data){
          this.isRecurrentCensus=true;
          //console.log("closedd true", data);
          this.recurrenceMap=data.recurrenceMap;
          this.date_objects=data.date_objects;

        }else{
          this.isRecurrentCensus=false;
          //console.log("closedd false", data);
          this.recurrenceMap=[];
          this.date_objects=[];
        }
      });


    }else{
      /// clear feilds
          this.recurrenceMap=[];
          this.date_objects=[];
    }
  }

}
