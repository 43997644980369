<mat-card>
    <mat-card-content>
        <div class="top-block flex space-between items-center">
            <div class="flex flex-grow gap-4">
                <mat-form-field>
                    <mat-select [(ngModel)]="selectUserTypes" (ngModelChange)="ngOnInit()" name="data-set-type"
                        placeholder="User Types">
                        <mat-option *ngFor="let types of allUserList" value="{{types.id}}">
                            {{types.text}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-select [(ngModel)]="selectedProcedureType" (ngModelChange)="onChangeProcedureType()"
                        name="procedure-type" placeholder="Procedure Type">
                        <mat-option *ngFor="let type of allProcedureTypes" value="{{type._id}}">
                            {{type.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <button mat-raised-button class="dn-button-primary" *ngIf="currentUser.admin_type == 'system_admin'"
                (click)="addColumn()">
                Add Column
            </button>
        </div>
    </mat-card-content>
</mat-card>

<div class="flex gap-2 container-dataset" cdkDropList (cdkDropListDropped)="drop($event)"
    cdkDropListOrientation="horizontal">
    <mat-card *ngFor="let col of columns;let i = index" cdkDrag (cdkDragEnded)="dragEnded($event, false, col)"
        (cdkDragStarted)="dragStart($event)" class="header-card">
        <mat-card-content>

            <div class="col-header">
                <ng-container *ngIf="col.header_confirmed == false">
                    <mat-form-field class="w-full">
                        <input matInput [(ngModel)]="col.header" type="text" name="{{col.header}}" placeholder="Header"
                            required>
                        <mat-error *ngIf="col.header != ''">Please enter header</mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-full">
                        <mat-select name="data-set-type" [(ngModel)]="col.header_type" [disabled]="disableEditing"
                            required placeholder="Dataset Shown As">
                            <mat-option *ngFor="let types of data_types" [value]="types">
                                {{types}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <app-formula-builder *ngIf="col.header_type == 'Input'" [formulaInput]="col.formula"  [inputFields]="col.rows" (formulaOutput)="col.formula = $event"></app-formula-builder>
                    <div class="flex justify-between items-end">
                        <div class="dataset-options">
                            <mat-checkbox [(ngModel)]="col.multiselect_enabled">
                                Multi Select
                            </mat-checkbox>
                            <mat-form-field *ngIf="col.header_confirmed == false && col.header_type == 'Input' "
                                class="flex-grow-0 flex-shrink-1 w-12 dataset-unit">
                                <mat-select [(ngModel)]="col.unit" placeholder="Unit"
                                    (selectionChange)="handleUnitChange(i)">
                                    <mat-option *ngFor="let measurement of measurements" [value]="measurement.unit">
                                        {{measurement.label}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <ng-container *ngIf="currentUser.admin_type == 'system_admin'">
                            <button class="save-image" (click)="ConfirmColumnHeader(i)">
                                <img src="assets\icons\save-white.png" />
                            </button>
                        </ng-container>
                    </div>
                </ng-container>

                <div class="header-text" (click)="toggleHeaderState(i)" *ngIf="col.header_confirmed == true">
                    {{col.header}}
                </div>

                <ng-container *ngIf="currentUser.admin_type == 'system_admin'">
                    <button class="close-image" (click)="deleteColumn(i)">
                        <img src="assets\icons\minus.png" />
                    </button>
                </ng-container>
            </div>

            <div class="col-content">
                <div id="rowDragg" cdkDropList (cdkDropListDropped)="dropRow($event,col)"
                    cdkDropListOrientation="vertical">
                    <div *ngFor="let row of col.rows;let x = index" cdkDrag (cdkDragEnded)="dragEnded($event,true, col)"
                        (cdkDragStarted)="dragStart($event,true)">
                        <div class="item flex justify-between items-center">
                            <div>
                                <mat-checkbox *ngIf="col.header_type != 'Input'" [checked]="row.value === col.default_value"
                                    (change)="toggleDefaultValueSelection(i, row.value, $event)">
                                </mat-checkbox>
                            </div>
                            <div>
                                <p (click)="toggleRowState(i,x)" *ngIf="col.row_confirmed[x]">
                                    {{row.value}}
                                </p>
                                <mat-form-field class="full-width" *ngIf="!col.row_confirmed[x]" appearance="outline">
                                    <div class="flex items-center">
                                        <input matInput type="text" [(ngModel)]="row.value" name="{{row.value}}"
                                            required>
                                        <mat-select [(ngModel)]="row.unit"
                                            class="flex-grow-0 flex-shrink-1 w-12 dataset-unit-dropdown"
                                            placeholder="Unit" *ngIf=" col.header_type == 'Input' ">
                                            <mat-option *ngFor="let measurement of measurements"
                                                [value]="measurement.unit">
                                                {{measurement.label}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </mat-form-field>
                            </div>

                            <ng-container *ngIf="currentUser.admin_type == 'system_admin'">
                                <ng-container *ngIf="col.row_confirmed[x] == false">
                                    <button class="save-image" (click)="ConfirmRow(i,x)">
                                        <img src="assets\icons\save-white.png" />
                                    </button>
                                </ng-container>
                                <button class="close-image" (click)="deleteRow(i,x)">
                                    <img src="assets\icons\minus.png" />
                                </button>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="currentUser.admin_type == 'system_admin'">
                <button class="add-image" *ngIf="col.header_confirmed == true" (click)="addRow(i)">
                    <img src="assets\icons\plus.png" />
                </button>
                <button class="add-image" *ngIf="col.header_confirmed == false" matTooltip="First Confirm Header">
                    <img src="assets\icons\plus-disabled.png" />
                </button>
            </ng-container>

        </mat-card-content>
    </mat-card>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>