// import { FeedbackComponent } from './includes/feedback/feedback/feedback.component';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './includes/login/login.component';
import { AuthComponent } from './includes/auth/auth.component';
import { EmailVerificationComponent } from './includes/email-verification/email-verification.component';
import { RegisterComponent } from './includes/register/register.component';
import { FacilityComponent } from './includes/facility/facility.component';
import { ForgotPasswordComponent } from './includes/forgot-password/forgot-password.component';
import { CodeVerificationComponent } from './includes/code-verification/code-verification.component';
import { DashboardComponent } from './includes/dashboard/dashboard.component';
import { PatientsComponent } from './includes/patients/patients.component';
import { PatientAddComponent } from './includes/patient-add/patient-add.component';
import { PatientRoundingSheetsComponent } from './includes/patient-rounding-sheets/patient-rounding-sheets.component';
import { PatientRoundingSheetPatientListComponent } from './includes/patient-rounding-sheet-patient-list/patient-rounding-sheet-patient-list.component';
import { CensusPatientListComponent } from './includes/census-patient-list/census-patient-list.component';
import { TranscriptionDetailComponent } from './includes/transcription-detail/transcription-detail.component';
import { ProfileComponent } from './includes/profile/profile.component';
import { ResetPasswordComponent } from './includes/reset-password/reset-password.component';
import { PdfViewComponent } from './includes/pdf-view/pdf-view.component';
import { AdminComponent } from './includes/admin/admin.component';
import { AdminDashboardComponent } from './includes/admin-dashboard/admin-dashboard.component';
import { ActivityComponent } from './includes/activity/activity.component';
import { AdminDictationsComponent } from './includes/admin-dictations/admin-dictations.component';
import { AdminNotesComponent } from './includes/admin-notes/admin-notes.component';
import { SimulateUserComponent } from './includes/simulate-user/simulate-user.component';
import { UserManagementComponent } from './includes/user-management/user-management.component';
import { DoctorListComponent } from './includes/doctor-list/doctor-list.component';
import { TranscriberListComponent } from './includes/transcriber-list/transcriber-list.component';
import { RelationshipComponent } from './includes/relationship/relationship.component';
import { AdminNotificationsSettingsComponent } from './includes/admin-notifications-settings/admin-notifications-settings.component';
import { TranscriptionCreateComponent } from './includes/transcription-create/transcription-create.component';
import { TranscriptionViewComponent } from './includes/transcription-view/transcription-view.component';
import { DriveComponent } from './includes/drive/drive.component';
import { TemplatesComponent } from './includes/templates/templates.component';
import { TemplateCreateComponent } from './includes/template-create/template-create.component';
import { PatientDetailComponent } from './includes/patient-detail/patient-detail.component';
import { PatientScriptSureComponent } from './includes/scriptsure/patient-scriptsure.component';
import { FacesheetComponent } from './includes/patient-detail/facesheet/facesheet.component';
import { PrivacyPolicyComponent } from './includes/privacy-policy/privacy-policy.component';
import { NotificationsPageComponent } from './includes/notifications-page/notifications-page.component';
import { NotificationsSettingsComponent } from './includes/notifications-settings/notifications-settings.component';
import { AppointmentComponent } from './components/appointment/appointment.component';
import { ReportGenerateComponent } from './components/report_generate/report-generate/report-generate.component';
import { DetailReporComponent } from './components/report_generate/detail-report/detail-repor/detail-repor.component';
import { TrainingSystemComponent } from './components/training_system/training-system/training-system.component';
import { VideoCallComponent } from "./components/appointment/video-call/video-call.component";
// import { QuickCallComponent, PatientIntakeFormPageComponent } from "./components/twilio";
import { PreviewDocumentComponent } from './components/report_generate/preview-document/preview-document/preview-document.component';
import { DoctorProfileComponent } from './components/doctor_profile/doctor-profile/doctor-profile.component';
import { BillerListComponent } from './includes/biller-list/biller-list/biller-list.component';
import { BillerDashboardComponent } from './includes/biller-dashboard/biller-dashboard/biller-dashboard.component';
import { BillerProviderAssociationComponent } from './includes/biller-provider-association/biller-provider-association/biller-provider-association.component';
import { ChargeCaptureComponent } from './includes/charge-capture/charge-capture/charge-capture.component';
import { CompanyComponent, CompanyProfileComponent, CompanyUserManagementComponent, CompanyFacilitiesComponent, CompanyUserManagementAddUserComponent, CompanyAddFacilityComponent, CompanyUserProfileComponent, CompanyUserCreatePasswordComponent, MipsDashboardComponent, ProviderTranscriberAssociationComponent, FacilityProviderAssociationComponent, CompanyImesComponent, ImeProviderAssociationComponent } from './components/company';
import { NoteEditorComponent } from './includes/note-editor/note-editor.component';
import { PccAuthRedirectComponent } from './includes/pcc-auth-redirect/pcc-auth-redirect.component';
import { PatientChargeComponent } from "./components/patient-charge/patient-charge.component";
import { ProviderChargeComponent } from "./includes/provider-charge/provider-charge.component";
import { ProviderChargeCaptureComponent } from "./includes/charge-capture/provider-charge-capture/provider-charge-capture.component";
import { ManageChargeComponent } from "./includes/charge-capture/manage-charge/manage-charge.component";
import { ReportsComponent } from './includes/report/reports/reports.component';
import { CensusListComponent } from './includes/census-list/census-list.component';
import { PatientListComponent } from './includes/patient-list/patient-list.component';
import { PccPatientListComponent } from './includes/pcc-patient-list/pcc-patient-list.component';
import { MobileEditorComponent } from "./includes/note-editor/mobile-editor/mobile-editor.component";
import { CompanyAddImeComponent } from './components/company/company-imes/company-add-ime/company-add-ime.component';
import { CompanyAssociationComponent } from './components/company/company-association/company-association.component';
import { ManageCategoriesComponent } from './shared/popup-dialogs/charges-catagories/charges-catagories-dialog/manage-categories/manage-categories.component';
import { ManageCompaniesComponent } from './includes/manage-companies/manage-companies.component';
import { RegisterCompanyComponent } from './includes/manage-companies/register-company/register-company/register-company.component';
import { TemplateMobileEditorComponent } from "./includes/note-editor/template-mobile-editor/template-mobile-editor.component";
import { PhraseComponent } from './includes/phrase-component/phrase-component';
import { AdminSideTemplatesComponent } from './includes/adminSide-templates/admin-side-templates/admin-side-templates.component';
import { ChargesCatagoriesDialogComponent } from './shared/popup-dialogs/charges-catagories/charges-catagories-dialog/charges-catagories-dialog.component';
import { PhrasesAssociationComponent } from './includes/phrases/phrases-association/phrases-association.component';
import { AdminSidePatientsComponent } from './includes/adminside-patients/admin-side-patients/admin-side-patients.component';
import { AudioComponent } from './includes/audio/audio.component';
import { ReleaseComponent } from './includes/release/release.component';
import { NotificationBarComponent } from './includes/Notification-bar/notification-bar/notification-bar.component';
import { AdminFeedbackComponent } from './includes/admin-feedback/admin-feedback.component';
import { ActiveCensusPatientListComponent } from './includes/active-census-patient-list/active-census-patient-list.component';
import { AdminPccFacilitiesComponent } from './components/company/admin-pcc-facilities/admin-pcc-facilities.component';
import { MedicalAssistantListComponent } from './includes/medical-assistant-list/medical-assistant-list.component';
import { TranscribeComponent } from './includes/transcribe/transcribe.component';
import { DrivePhrasesComponent } from './includes/drive-phrases/drive-phrases.component';
import { CreateStrongPasswordComponent } from './components/company/create-strong-password/create-strong-password.component';
import { AmazonMedicalTranscribeComponent } from './components/amazon-medical-transcribe/amazon-medical-transcribe/amazon-medical-transcribe.component';
import { AwsTranscribeComponent } from './includes/aws-transcribe/aws-transcribe.component';
import { PhraseMobileEditorComponent } from './includes/phrase-mobile-editor/phrase-mobile-editor.component';
import { ForbiddenPageComponent } from './forbidden-page/forbidden-page.component';
import { AdminLoginComponent } from './includes/admin-login/admin-login.component';
import { AdminAuthComponent } from './includes/admin-auth/admin-auth.component';
import { CensusListV1Component } from './includes/census-list-v1/census-list-v1.component';
import { NoteEditorNewVersionComponent } from './includes/note-editor-new-version/note-editor-new-version.component';
import { AuthenticationGuard } from './includes/auth-guard/authentication/authentication.guard';
import { PatientMatrixCareGuard } from './includes/auth-guard/patient-authorization/patient-matrix-care.guard';
import { PccPatientGuard } from './includes/auth-guard/patient-authorization/pcc-patient.guard';
import { AdminListComponent } from './includes/admin-list/admin-list.component';
import { AuthorizationGuard } from './includes/auth-guard/authorization/authorization.guard';
import { ProductOwnerGuard } from './includes/auth-guard/authorization/product-owner.guard';
import { ChargesReportComponent } from './includes/report/charges-report/charges-report.component';
import { ManagerListComponent } from './includes/manager-list/manager-list.component';
import { AdminChargeCaptureComponent } from './includes/biller-dashboard/admin-charge-capture/admin-charge-capture.component';
import { CensusNotesComponent } from './includes/census-notes/census-notes/census-notes.component';
import { TicketsComponent } from './includes/tickets/tickets.component';
import { UserTicketsComponent } from './includes/tickets/user-tickets/user-tickets.component';
import { WoundComponent } from './includes/wound/wound.component';
import { ForgotPasswordCodeVerificationComponent } from './includes/forgot-password-code-verification/forgot-password-code-verification.component';
import { GeneralReportComponent } from './includes/general-report/general-report.component';
import { WoundReportsComponent } from './includes/wound/wound-reports/wound-reports.component';
import { ChargeTimePeriodReportComponent } from './includes/biller-dashboard/charge-time-period-report/charge-time-period-report.component';
import { WoundChartComponent } from './includes/wound-chart/wound-chart.component';
import { WoundPeriodicTreatmentReportComponent } from './includes/wound/wound-reports/reports/wound-periodic-treatment-report/wound-periodic-treatment-report.component';
import { WoundHealingRateReportComponent } from './includes/wound/wound-reports/reports/wound-healing-rate-report/wound-healing-rate-report.component';
import { UwhTypeTabularReportComponent } from './includes/wound/wound-reports/reports/uwh-type-tabular-report/uwh-type-tabular-report.component';
import { AdminPatientMedicalHistoryComponent } from './includes/adminside-patients/admin-patient-medical-history/admin-patient-medical-history.component';
import { PatientDischargeReportComponent } from './includes/wound/wound-reports/reports/discharged-patient-report/discharged-patient-report.component';
import { DnsReportComponent } from './includes/wound/wound-reports/reports/dns-report/dns-report.component';
import { QapiReportComponent } from './includes/wound/wound-reports/reports/qapi-report/qapi-report.component';
import { VisitReportComponent } from './includes/wound/wound-reports/reports/visit-report/visit-report.component';
import { NurseListComponent } from './includes/nurse-list/nurse-list.component';
import { NursingDashboardComponent } from './includes/nursing-module/nursing-dashboard/nursing-dashboard.component';
import { DebridementReportComponent } from './includes/wound/wound-reports/reports/debridement-report/debridement-report.component';
import { ProductOwnerAndFullAdminGuard } from './includes/auth-guard/authorization/product-owner-and-full-admin.guard';
import { AdminSidePatientEditComponent } from './includes/admin-side-patient-edit/admin-side-patient-edit.component';
import { CensusListV2Component } from './includes/census-list-v2/census-list-v2.component';
import { CensusPatientListV2Component } from './includes/census-patient-list-v2/census-patient-list-v2.component';
import { BusinessOfficerListComponent } from './includes/business-officer-list/business-officer-list.component';
import { MergePatientsComponent } from './includes/merge-patients/merge-patients.component';
import { MergingComponent } from './includes/merging/merging.component';
import { MissedFacilityReportComponent } from './includes/wound/wound-reports/reports/missed-facility-report/missed-facility-report.component';
import { MatrixPatientsComponent } from './includes/matrix-patients/matrix-patients/matrix-patients.component';
import { MergeFacilitiesComponent } from './includes/merge-facilities/merge-facilities.component';
import { WoundNotesComponent } from './includes/wound/wound-notes/wound-notes.component';
import { TestProviderListComponent } from './includes/test-provider-list/test-provider-list.component';
import { ContactUsComponent } from './includes/contact-us/contact-us.component';
import { CnpListComponent } from './includes/cnp-list/cnp-list.component';
import { WoundphrasebothComponent } from './includes/phrases/woundphraseboth/woundphraseboth.component';
import { CensusBothComponent } from './includes/census-both/census-both.component';
import { AdminSchedulerComponent } from './includes/admin-scheduler/admin-scheduler.component';
import { ManageCptCodesComponent } from './components/company/manage-cpt-codes/manage-cpt-codes.component';
import { DnsHealingReportComponent } from './includes/wound/wound-reports/reports/dns-healing-report/dns-healing-report.component';
import { TissueAnalyticReportComponent } from './includes/wound/wound-reports/reports/tissue-analytic/tissue-analytic.component';
import { KentVisitReportComponent } from './includes/wound/wound-reports/reports/kent-visit-report/kent-visit-report.component';
import { AllCompaniesComponent } from './includes/manage-companies/all-companies/all-companies.component';
import { LoginAuthRedirectComponent } from './includes/login-auth-redirect/login-auth-redirect.component';
import { AdminChargesReportComponent } from './includes/wound/wound-reports/reports/charges-report/admin-charges-report.component';
import { LosReportComponent  } from './includes/report/los-report/los-report.component';
import { IpaReportComponent } from './includes/report/ipa-report/ipa-report.component';
import { UwhTypeTabularReportV2Component } from './includes/wound/wounds-v2/wound-reports-v2/reports-v2/uwh-type-tabular-report-v2/uwh-type-tabular-report-v2.component';
import { DnsReportV2Component } from './includes/wound/wounds-v2/wound-reports-v2/reports-v2/dns-report-v2/dns-report-v2.component';
import { QapiReportV2Component } from './includes/wound/wounds-v2/wound-reports-v2/reports-v2/qapi-report-v2/qapi-report-v2.component';
import { WoundReportsV2Component } from './includes/wound/wounds-v2/wound-reports-v2/wound-reports-v2.component';
import { DnsHealingReportV2Component } from './includes/wound/wounds-v2/wound-reports-v2/reports-v2/dns-healing-report-v2/dns-healing-report-v2.component';
import { CompanyFacilityTabsComponent } from './components/company/company-facilities/company-facility-tabs/company-facility-tabs.component';
import { PostRoundsReportComponent } from './includes/wound/wounds-v2/wound-reports-v2/reports-v2/post-rounds-report/post-rounds-report.component';
import { SnfWcNurseListComponent } from './includes/snf-wc-nurse-list/snf-wc-nurse-list.component';
import { AdminCensusWoundReportComponent } from './includes/wound/admin-census-wound-report/admin-census-wound-report.component';
import { NoteAgingReportComponent } from './includes/note-aging-report/note-aging-report.component';
import { WoundNurseListComponent } from './includes/wound-nurse-list/wound-nurse-list.component';
import { PressureWoundReportComponent } from './includes/wound/wound-reports/reports/pressure-wound-report/pressure-wound-report.component';
import { ViewOnlyUsersListComponent } from './includes/view-only-users-list/view-only-users-list.component';
import { DataExportComponent } from './includes/data-export/data-export.component';
import { HpOperationalReportComponent } from './includes/wound/wound-reports/reports/hp-operational-report/hp-operational-report.component';
import { NoteEditorV2Component } from './modules/note-editor/note-editor-v2/note-editor-v2.component';
import { CustomWoundReportComponent } from './includes/wound/wound-reports/reports/custom-wound-report/custom-wound-report.component';
import { WoundExceptionsReportComponent } from './includes/wound/wound-reports/reports/wound-exceptions-report/wound-exceptions-report.component';
import { WoundDermComponent } from './includes/wound-derm/wound-derm.component';
import { ApproveByAdminComponent } from './components/company/approve-by-admin/approve-by-admin.component';
import { TelehealthWoundAssessmentReportComponent } from './includes/wound/wound-reports/reports/telehealth-wound-assessment-report/telehealth-wound-assessment-report.component';
import { ReportsModuleComponent } from './includes/reports-module/reports-module.component';
import { ManageCptsIcdsSettingsComponent } from './components/company/manage-cpts-icds-settings/manage-cpts-icds-settings.component';
import { CompanyUsersComponent } from './includes/company-users/company-users.component';
// import { CalandarComponent } from './calandar/calandar.component';
import { WoundEnterpriseReportsComponent } from './includes/wound/wound-enterprise-reports/wound-enterprise-reports.component';
import { AddNewAppointmentComponent } from './includes/add-new-appointment/add-new-appointment.component';
import { AppointmentCalenderComponent } from './includes/appointment-calender/appointment-calender.component';
import { AppointmentPatientDetailComponent } from './includes/appointment-patient-detail/appointment-patient-detail.component';
import { DailyAppointmentListComponent } from './includes/daily-appointment-list/daily-appointment-list.component';
import { ScheduleAppointmentComponent } from './includes/schedule-appointment/schedule-appointment.component';
import { CompanyLogsModuleComponent } from './components/company/company-logs/company-logs-module.component';
import { OrderSettingComponent } from './includes/order-setting/order-setting.component';
import { ConsentComponent } from './includes/manage-companies/consent/consent.component';
import { DnsLogsComponent } from './includes/dns-logs/dns-logs.component';
import { PORTAL_TYPE } from './includes/global'
import { DaysToRemissionReportComponent } from './includes/wound/wound-reports/reports/days-to-remission-report/days-to-remission-report.component';
import { WoundOutcomeReportComponent } from './includes/wound/wound-reports/reports/wound-outcome-report/wound-outcome-report.component';




// import {PageEditFormComponent} from './components/report_generate/page-edit-form/page-edit-form.component';

// @ts-ignore

const APP_ROUTES: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'login/:company_code', component: LoginComponent },
    { path: '403', component: ForbiddenPageComponent },
    { path: 'login', component: LoginComponent },
    { path: 'admin-login', component: AdminLoginComponent },
    { path: 'audio', component: AudioComponent },
    { path: 'transcribe', component: TranscribeComponent },
    { path: 'auth/:company_code', component: AuthComponent },
    { path: 'auth', component: AuthComponent },
    { path: 'admin-auth', component: AdminAuthComponent },
    { path: 'pccAuthRedirect', component: PccAuthRedirectComponent },
    { path: 'loginAuthRedirect', component: LoginAuthRedirectComponent },
    { path: 'email-verification', component: EmailVerificationComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'facility', component: FacilityComponent },
    { path: 'forgot-password/:company_code', component: ForgotPasswordComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'contact-us/:company_code', component: ContactUsComponent },
    { path: 'contact-us', component: ContactUsComponent },
    { path: 'code-verification/:company_code', component: CodeVerificationComponent },
    { path: 'code-verification', component: CodeVerificationComponent },
    { path: 'forget-code-verification/:company_code', component: ForgotPasswordCodeVerificationComponent },
    { path: 'forget-code-verification', component: ForgotPasswordCodeVerificationComponent },
    { path: 'dashboard',  component: DashboardComponent },
    { path: 'patient-detail/:id/facesheet', component: FacesheetComponent },
    { path: 'patient-detail/:id',  component: PatientDetailComponent },
    { path: 'patient-detail', component: PatientDetailComponent },
    { path: 'patient-scriptsure/:id',  component: PatientScriptSureComponent },
    { path: 'amt', component: AmazonMedicalTranscribeComponent },
    { path: 'user-tickets', component: UserTicketsComponent },
    { path: 'wound-chart', component: WoundChartComponent },
    { path: 'notifications-settings', component: AdminNotificationsSettingsComponent },
    
    // { path: 'calandar', component: CalendarComponent },

    { path: 'schedule-appointment', component: ScheduleAppointmentComponent },
    { path: 'appointment-calandar', component: AppointmentCalenderComponent },
    { path: 'appointment-patient-detail', component: AppointmentPatientDetailComponent },
    { path: 'add-new-appointment', component: AddNewAppointmentComponent },
    { path: 'daily-appointment-list', component: DailyAppointmentListComponent },


    // { path: 'news', component: NotificationBarComponent },
    // *******    biller header routes *******
    { path: 'biller-notes', component: AdminNotesComponent },
    { path: 'biller-charges/patient-charge', component: PatientChargeComponent },
    {
        path: 'biller-charges', component: AdminChargeCaptureComponent,
        // children: [
        //     // { path: '', redirectTo: 'doctor-list', pathMatch: 'full' },
        //     { path: 'patient-charge', component: PatientChargeComponent },
        // ]
    },
    { path: 'biller/charge-duration-report', component: ChargeTimePeriodReportComponent },
    { path: 'biller-note-editor/:note_id', component: NoteEditorComponent },
    // *******************************                       ***************************
    {
        path: 'patients', canActivateChild: [AuthenticationGuard], component: PatientsComponent,
        children: [
            { path: '', redirectTo: 'pcc-list', pathMatch: 'full' },
            { path: 'list', component: PatientListComponent },
            { path: 'advance-search-list', component: PatientListComponent },
            { path: 'pcc-list', canActivate: [PccPatientGuard], component: PccPatientListComponent },
            { path: 'matrix-list', canActivate: [PatientMatrixCareGuard], component: MatrixPatientsComponent },
        ]
    },
    { path: 'patient-add',  component: PatientAddComponent },
    // { path: 'feedback', component: FeedbackComponent },
    { path: 'patient-rounding-sheets/:id', component: PatientRoundingSheetPatientListComponent },
    { path: 'census/active/:id', component: ActiveCensusPatientListComponent },
    { path: 'census-v2/:id',  component: CensusPatientListV2Component },
    { path: 'census/:id',  component: CensusPatientListComponent },
    { path: 'patient-rounding-sheets', component: PatientRoundingSheetsComponent },
    // { path: 'census', component: CensusListComponent },
    { path: 'census-v2',  component: CensusListV2Component },
    { path: 'census-list',  component: CensusListComponent },
    { path: 'census',  component: CensusBothComponent },
    { path: 'patient-charge',  component: PatientChargeComponent },
    { path: 'transcription-detail', component: TranscriptionDetailComponent },
    { path: 'profile',  component: ProfileComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'pdf-view', component: PdfViewComponent },
    { path: 'appointment', component: AppointmentComponent },
    { path: 'meeting', component: VideoCallComponent },
    { path: 'report-generate', component: ReportGenerateComponent },
    { path: 'report-detail', component: DetailReporComponent },
    { path: 'preview-document', component: PreviewDocumentComponent },
    { path: 'biller-dashboard',  component: BillerDashboardComponent },
    { path: 'provider-charge', component: ProviderChargeComponent },
    { path: 'provider-charge-capture', component: ProviderChargeCaptureComponent },
    { path: 'biller-provider-relation', component: BillerProviderAssociationComponent },
    { path: 'jsondetail', component: DataExportComponent },
    { path: 'charge-capture', component: ChargeCaptureComponent },
    { path: 'manage-charge', component: ManageChargeComponent },
    { path: 'user-create-password', component: CompanyUserCreatePasswordComponent },
    // { path: 'manage-companies', component: ManageCompaniesComponent },
    { path: 'app-release', component: ReleaseComponent },
    // { path: 'manage-category', component: ManageCategoriesComponent },
    // {
    //     path: 'meeting/quick-call',
    //     component: QuickCallComponent
    // },
    // {
    //     path: 'patient-intake-form',
    //     component: PatientIntakeFormPageComponent
    // },
    // @ts-ignore
    //{ path: 'meeting', loadChildren: () => import('src/app/components/twilio/twilio.module').then(m => m.TwilioModule) },
    {
        path: 'nursing', canActivateChild: [AuthenticationGuard], component: NursingDashboardComponent,
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'dashboard', component: NursingDashboardComponent },
        ]
    },
    {
        path: 'admin', component: AdminComponent,
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            {
                path: 'dashboard', component: AdminDashboardComponent,
                children: [
                    { path: '', redirectTo: 'dictations', pathMatch: 'full' },
                    { path: 'dictations', component: AdminDictationsComponent },
                    { path: 'notes', component: AdminNotesComponent },
                    { path: 'charges', component: BillerDashboardComponent },
                    { path: 'charge-capture', component: ChargeCaptureComponent },
                ],
            },
            { path: 'simulate/:id', component: SimulateUserComponent },

            { path: 'activity', component: ActivityComponent },

            {
                path: 'user-management', component: UserManagementComponent,
                children: [
                    { path: '', redirectTo: 'doctor-list', pathMatch: 'full' },
                    { path: 'doctor-list', component: DoctorListComponent },
                    { path: 'test-provider-list', component: TestProviderListComponent },
                    { path: 'cnp-list', component: CnpListComponent },
                    { path: 'admin-list', component: AdminListComponent },
                    { path: 'biller-list', component: BillerListComponent },
                    { path: 'transcriber-list', component: TranscriberListComponent },
                    { path: 'medical-assistant-list', component: MedicalAssistantListComponent },
                    { path: 'manager-list', component: ManagerListComponent },

                ]
            },
            { path: 'relationship', component: RelationshipComponent },
            // { path: 'notifications-settings', component: AdminNotificationsSettingsComponent },
            {
                path: 'user-settings',
                
                loadChildren: () =>
                    import('src/app/feature-modules/user-settings/user-settings.module').then((module) => module.UserSettingsModule),
            },
            { path: 'training-system', component: TrainingSystemComponent },
            { path: 'doctor-profile', component: DoctorProfileComponent },
            { path: 'reports', component: ReportsComponent },
            { path: 'charges-report', component: ChargesReportComponent },
            { path: 'manage-category', component: ManageCategoriesComponent },
            //
            { path: 'census-v2/:id', component: CensusPatientListV2Component },
            { path: 'census/:id', component: CensusPatientListComponent },
        ]
    },
    // { path: 'activity', component: ActivityComponent },
    // { path: 'user-management', component: UserManagementComponent,
    //   children: [
    //     {path: '', redirectTo: 'doctor-list', pathMatch: 'full'},
    //     {path: 'doctor-list', component: DoctorListComponent},
    //     {path: 'transcriber-list', component: TranscriberListComponent},
    //   ]
    // },
    // { path: 'doctor-list', component: DoctorListComponent },
    // { path: 'transcriber-list', component: TranscriberListComponent },
    // { path: 'admin-dictations', component: AdminDictationsComponent },
    // { path: 'relationship', component: RelationshipComponent },
    { path: 'transcription-create', component: TranscriptionCreateComponent },
    {
        path: 'note-editor', 
        children: [
            { path: '',  redirectTo: 'new', pathMatch: 'full' },
            { path: 'new',  component: NoteEditorComponent},
            { path: 'mobile-editor/:note_id', component: MobileEditorComponent },
            { path: ':note_id', component: NoteEditorComponent  },

            { path: 'v2/:note_id', component: NoteEditorV2Component },
            { path: 'v2/', component: NoteEditorV2Component },

        ]
    },
    {
        path: 'note-editor-new-version',
        children: [
            { path: '', redirectTo: 'new', pathMatch: 'full' },
            { path: 'new', component: NoteEditorNewVersionComponent },
            { path: 'mobile-editor/:note_id', component: MobileEditorComponent },
            { path: ':note_id', component: NoteEditorNewVersionComponent },

        ]
    },
    { path: 'transcription-view', component: TranscriptionViewComponent },
    {
        path: 'drive',  component: DriveComponent, children: [
            {
                path: 'shared-with-me',
                component: DriveComponent
            },
            {
                path: 'templates',
                component: DriveComponent
            },
            {
                path: 'phrases',
                component: DrivePhrasesComponent
            },
            {
                path: 'rrgen',
                component: DriveComponent
            },
            {
                path: 'googleDrive',
                component: DriveComponent
            }
        ]
    },
    { path: 'templates',  component: TemplatesComponent },
    {
        path: 'template-editor',
        children: [
            { path: 'mobile-editor/:template_id', component: TemplateMobileEditorComponent }
        ]
    },
    {
        path: 'phrase-editor',
        children: [
            { path: 'mobile-editor/:phrase_id/:phrase_key', component: PhraseMobileEditorComponent }
        ]
    },
    { path: 'template-create',  component: TemplateCreateComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'notifications', component: NotificationsPageComponent },
    // { path: 'notifications-settings',  component: NotificationsSettingsComponent },
    {
        path: 'user-settings',
        
        loadChildren: () =>
            import('src/app/feature-modules/user-settings/user-settings.module').then((module) => module.UserSettingsModule),
    },
    { path: 'aws-transcribe', component: AwsTranscribeComponent },
    { path: 'company', component: CompanyComponent },
    { path: 'company/:id/user-create-password/:company_code', component: CompanyUserCreatePasswordComponent },
    { path: 'company/:id/user-create-password', component: CompanyUserCreatePasswordComponent },
    { path: 'company/:id/create-strong-password', component: CreateStrongPasswordComponent },
    { path: 'approveByAdmin/:id',  component: ApproveByAdminComponent },

    {
        path: 'company/:id',  component: CompanyComponent,
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'mips-dashboard', component: MipsDashboardComponent },
            { path: 'phrase-component', component: PhraseComponent },
            {
                path: 'manage-companies',
                canActivate: [ProductOwnerAndFullAdminGuard],
                component: ManageCompaniesComponent
            },
            { path: 'register-company',  canActivate: [ProductOwnerAndFullAdminGuard],  component: RegisterCompanyComponent },
            { path: 'merge', canActivate: [ProductOwnerAndFullAdminGuard], component: MergingComponent },
            // { path: 'scheduler', canActivate: [ProductOwnerAndFullAdminGuard], component: AdminSchedulerComponent }, // old scheduler
            // { path: 'scheduler', canActivate: [ProductOwnerAndFullAdminGuard], component: CalendarComponent },// Appointment Schedule
            { path: 'order', canActivate: [ProductOwnerAndFullAdminGuard], component: OrderSettingComponent }, // Order
            { path: 'phrases-association', component: PhrasesAssociationComponent },
            { path: 'charges-report', component: ChargesReportComponent },
            { path: 'manage-cpt-codes', component: ManageCptsIcdsSettingsComponent },
            { path: 'error-logs', component: CompanyLogsModuleComponent },
            { path: 'reports', component: ReportsModuleComponent },
            {
                path: 'dashboard', component: AdminDashboardComponent,
                children: [
                    { path: '', redirectTo: 'notes', pathMatch: 'full' },
                    { path: 'dictations', component: AdminDictationsComponent },
                    { path: 'notes', component: AdminNotesComponent },
                    { path: 'charges', component: BillerDashboardComponent },
                    { path: 'patient-charge', component: PatientChargeComponent},
                    { path: 'admin-charges', component: AdminChargeCaptureComponent },
                    { path: 'charge-duration-report', component: ChargeTimePeriodReportComponent },
                    { path: 'charge-capture', component: ChargeCaptureComponent },
                    { path: 'census-notes', component: CensusNotesComponent },
                    { path: 'wound-notes', component: WoundNotesComponent },
                    { path: 'census-wound-notes', component: AdminCensusWoundReportComponent },
                    { path: 'wound-reports', canActivate: [ProductOwnerAndFullAdminGuard], component: WoundReportsComponent },
                    { path: 'wound-enterprise-reports', canActivate: [ProductOwnerAndFullAdminGuard], component: WoundEnterpriseReportsComponent },
                    { path: 'wound-reports-v2', canActivate: [ProductOwnerAndFullAdminGuard], component: WoundReportsV2Component },
                    { path: 'note-aging-report', component: NoteAgingReportComponent },
                    { path: 'consent-form', component: ConsentComponent },
                    { path: 'dns-logs', component: DnsLogsComponent },
                ],
            },
            { path: 'note-editor/:note_id', component: NoteEditorComponent },
            { path: 'transcription-detail', component: TranscriptionDetailComponent },
            { path: 'app-release', component: ReleaseComponent },
            // { path: 'app-admin-feedback', component: AdminFeedbackComponent },
            { path: 'tickets', component: TicketsComponent },
            { path: 'patients/patient-medical-history', component: AdminPatientMedicalHistoryComponent },
            { path: 'patients', component: AdminSidePatientsComponent },
            { path: 'patient-add', component: PatientAddComponent },
            { path: 'admin-patient-edit/:id', component: AdminSidePatientEditComponent },
            { path: 'relationship', component: RelationshipComponent },
            { path: 'templates', component: AdminSideTemplatesComponent },
            { path: 'template-create', component: TemplateCreateComponent },
            { path: 'user-profile', component: CompanyUserProfileComponent },
            { path: 'profile', component: CompanyProfileComponent },
            { path: 'reports', component: ReportsComponent },
            { path: 'pcc-facilites', component: AdminPccFacilitiesComponent },
            // { path: 'charges-categories', component: ChargesCatagoriesDialogComponent },
            { path: 'manage-category', component: ManageCategoriesComponent },
            {
                path: 'user-management',
                children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                    {
                        path: 'list', component: CompanyUserManagementComponent,
                        children: [
                            { path: '', redirectTo: 'doctor-list', pathMatch: 'full' },
                            { path: 'all-user', component: CompanyUsersComponent },
                            { path: 'doctor-list', component: DoctorListComponent },
                            { path: 'test-provider-list', component: TestProviderListComponent },
                            { path: 'cnp-list', component: CnpListComponent },
                            { path: 'biller-list', component: BillerListComponent },
                            { path: 'nurse-list', component: NurseListComponent },
                            { path: 'transcriber-list', component: TranscriberListComponent },
                            { path: 'admin-list', component: AdminListComponent },
                            { path: 'medical-assistant-list', component: MedicalAssistantListComponent },
                            { path: 'manager-list', component: ManagerListComponent },
                            { path: 'business-officer-list', component: BusinessOfficerListComponent },
                            { path: 'snf-wc-nurse-list', component: SnfWcNurseListComponent },
                            { path: 'wound-nurse-list', component: WoundNurseListComponent },
                            { path: 'view-only-users-list', component: ViewOnlyUsersListComponent },
                        ]

                    },
                    // {path: 'provider-association', component: ProviderTranscriberAssociationComponent},
                    { path: 'add', component: CompanyUserManagementAddUserComponent },
                    { path: 'addfacility', component: CompanyAddFacilityComponent },
                    { path: 'edit/:id', component: CompanyUserManagementAddUserComponent },
                    { path: 'relationship', component: RelationshipComponent },
                    { path: 'doctor-profile', component: DoctorProfileComponent },
                    { path: 'template-create', component: TemplateCreateComponent },
                    { path: 'simulate/:id', component: SimulateUserComponent },
                    //
                ]
            },
            { path: 'census-v2/:id', component: CensusPatientListV2Component },
            { path: 'census/:id', component: CensusPatientListComponent },
            { path: 'notifications-settings', component: AdminNotificationsSettingsComponent },
            { path: 'activity', component: ActivityComponent },
            // {path: 'relationship', component: RelationshipComponent},

            {
                path: 'facilities',
                children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                    { path: 'list', component: CompanyFacilitiesComponent },
                    { path: 'association', component: FacilityProviderAssociationComponent },
                    { path: 'add', component: CompanyAddFacilityComponent },
                    { path: 'edit/:id', component: CompanyFacilityTabsComponent },

                ]
            },
            {
                path: 'ime-vendors',
                children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                    { path: 'list', component: CompanyImesComponent },
                    { path: 'association', component: ImeProviderAssociationComponent },
                    { path: 'add', component: CompanyAddImeComponent },
                    { path: 'edit/:id', component: CompanyAddImeComponent },
                ]
            },
            { path: 'general-report', component: GeneralReportComponent },
            { path: 'patient-detail/:id', component: PatientDetailComponent },
            { path: 'patient-detail/:id/facesheet', component: FacesheetComponent },
            // { path: 'wound', component: WoundComponent },
            { path: 'wound-derm', component: WoundDermComponent },


            /**         Wound Reports       **/
            { path: 'weeks-in-treatment-report', component: WoundPeriodicTreatmentReportComponent },
            { path: 'healing-success-rate-report', component: WoundHealingRateReportComponent },
            { path: 'uwh-type-tabular-report', component: UwhTypeTabularReportComponent },
            { path: 'discharged-patient-report', component: PatientDischargeReportComponent },
            { path: 'dns-report', component: DnsReportComponent },
            { path: 'dns-healing-report', component: DnsHealingReportComponent },
            { path: 'qapi-report', component: QapiReportComponent },
            { path: 'visit-report', component: VisitReportComponent },
            { path: 'kent-visit-report', component: KentVisitReportComponent },
            { path: 'missed-facility-report', component: MissedFacilityReportComponent },
            { path: 'debridement-report', component: DebridementReportComponent },
            { path: 'dns-tissue-analytic-report', component: TissueAnalyticReportComponent },
            { path: 'admin-charges-report', component: AdminChargesReportComponent },
            { path: 'los-report', component: LosReportComponent },
            { path: 'ipa-report', component: IpaReportComponent },
            { path: 'uwh-type-tabular-report-v2', component: UwhTypeTabularReportV2Component },
            { path: 'dns-report-v2', component: DnsReportV2Component },
            { path: 'qapi-report-v2', component: QapiReportV2Component },
            { path: 'dns-healing-report-v2', component: DnsHealingReportV2Component },
            { path: 'post-rounds-report', component: PostRoundsReportComponent },
            { path: 'days-to-remission-report', component: DaysToRemissionReportComponent },
            { path: 'wound-outcome-report', component: WoundOutcomeReportComponent },
            { path: 'pressure-wound-report', component: PressureWoundReportComponent },
            { path: 'hp-operational-report', component: HpOperationalReportComponent },
            { path: 'custom-wound-report', component: CustomWoundReportComponent },
            { path: 'telehealth-wound-assessment-report', component: TelehealthWoundAssessmentReportComponent },

            // { path: 'company-notifications-settings',  component: NotificationsSettingsComponent },
            {
                path: 'company-notifications-settings',
                
                loadChildren: () =>
                    import('src/app/feature-modules/user-settings/user-settings.module').then((module) => module.UserSettingsModule),
            },

            { path: 'wound-exceptions-report', component: WoundExceptionsReportComponent },
        ]
    },
    // { path: '**', redirectTo: '/dashboard' }
];

export const routing = RouterModule.forRoot(APP_ROUTES, {
    onSameUrlNavigation: 'reload'
});
