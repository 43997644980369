import { Injectable } from '@angular/core';
import { API_URL } from 'src/environments/api-url';
import * as global from '../../../includes/global';
import { HttpService } from 'src/app/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class Hl7ChargesService {
  constructor(private http: HttpService) { }

  saveFomValues(date, id){
    console.log("in service.......... ")
    let details = {date: date, practiceId: id};
    console.log("detailssssssssssssssss............ ", details)
    return this.http.post(global.url + API_URL.CHARGE.hl7Charge, details);
  }

}
