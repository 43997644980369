<div class="container" *ngIf="!isQueryReportVisible">
    <mat-card>
        <div class="flex justify-end items-center">
            <button mat-raised-button class="dn-button-primary" (click)="openCreateGeneralReportDialog();"
                type="button">+</button>
        </div>
        <br>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

            <ng-container matColumnDef="sr">
                <th mat-header-cell *matHeaderCellDef> Sr </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{i + 1}}
                </td>
            </ng-container>

            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef> Title </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    {{element?.title}}
                </td>
            </ng-container>


            <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef> Company </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    <ng-container *ngIf="element?.associated_company_ids?.length > 0">
                        <ng-container *ngFor="let company of element.associated_company_ids">
                            {{company?.name}}
                            {{element.associated_company_ids.length > 1 ? ',' : null}}
                        </ng-container>
                    </ng-container>
                </td>
            </ng-container>


            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    <div class="operation-buttons buttons-div flex flex-row items-center gap-5">
                        <span class="material-icons" matTooltip="View" matTooltipPosition="above"
                            (click)="showQueryReport(element._id, element.title)" style="cursor: pointer;">
                            remove_red_eye
                        </span>
                        <span class="material-icons" (click)="openCreateGeneralReportDialog(element._id , i);"
                        matTooltip="Edit" matTooltipPosition="above" style="cursor: pointer;">
                        edit_note
                    </span>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

    </mat-card>

</div>
<app-query-report *ngIf="isQueryReportVisible" [parentReport]="parentReport" (exit)="isQueryReportVisible=$event; parentReport={};"></app-query-report>