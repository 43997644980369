import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {LayoutModule} from '../../layout/layout.module';
// import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule} from '@angular/forms';
import {MaterialModule} from '../material.module';
import { DoctorProfileComponent } from './doctor-profile/doctor-profile.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { DoctorProfileGeneralDetailComponent } from './doctor-profile-general-detail/doctor-profile-general-detail.component';
import { DoctorProfileDocumentsComponent } from './doctor-profile-documents/doctor-profile-documents.component';
import { DoctorProfileTimeScheduleComponent } from './doctor-profile-time-schedule/doctor-profile-time-schedule.component';
import { DoctorProfileFeeStructureComponent } from './doctor-profile-fee-structure/doctor-profile-fee-structure.component';
import {MatRadioModule} from "@angular/material/radio";
import { DoctorProfileInformationComponent } from './doctor-profile-information/doctor-profile-information.component';
import {DoctorDocumentsUploadDialog} from './doctor-profile-documents/doctor-documents-upload-dialog/doctor-documents-upload-dialog.component';
import { DoctorProfileFacilitiesComponent } from './doctor-profile-facilities/doctor-profile-facilities/doctor-profile-facilities.component';
import { AssociatedProviderInformationComponent } from './associated-provider-information/associated-provider-information.component';
import { DoctorProfileScriptsureComponent } from './doctor-profile-scriptsure/doctor-profile-scriptsure.component';
import { PccAccountPermissionsComponent } from './pcc-account-permissions/pcc-account-permissions.component';
@NgModule({
    declarations: [
        DoctorProfileComponent,
        DoctorProfileGeneralDetailComponent, 
        DoctorProfileDocumentsComponent, 
        DoctorProfileTimeScheduleComponent, 
        DoctorProfileFeeStructureComponent, 
        DoctorProfileInformationComponent,
        DoctorDocumentsUploadDialog,
        DoctorProfileFacilitiesComponent,
        AssociatedProviderInformationComponent,
        DoctorProfileScriptsureComponent,
        PccAccountPermissionsComponent,
    ],
    imports: [
        CommonModule,
        LayoutModule,
        MaterialModule,
        FormsModule,
        BrowserModule,
        RouterModule,
        FormsModule,
        // FlexLayoutModule,
        MatRadioModule
    ],
    exports: [DoctorProfileComponent],
    providers: [],
    // entryComponents: [DoctorDocumentsUploadDialog],
    bootstrap: [DoctorProfileComponent],
})
export class DoctorProfileModule {
}
