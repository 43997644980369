import { Component, OnInit, Input } from '@angular/core';

import { DoctorProfileScriptsureService } from './doctor-profile-scriptsure.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-doctor-profile-scriptsure',
  templateUrl: './doctor-profile-scriptsure.component.html',
  styleUrls: [ './doctor-profile-scriptsure.component.scss' ]
})
export class DoctorProfileScriptsureComponent implements OnInit {
  @Input() userDetail: any;
  isInviteSent: boolean = false;
  inviteSentOn: any = null;
  sendingInvite: boolean = false;
  currentUser: any;

  constructor(
    private _scriptsureService: DoctorProfileScriptsureService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if ( this.userDetail == null ) {
      return;
    }

    this.currentUser = this.authService.currentUser;

    this.populateInviteStatus( this.userDetail.scriptsure_invite_sentOn );
  }

  populateInviteStatus( inviteSentOn ) {
    this.isInviteSent = inviteSentOn != null;
    this.inviteSentOn = inviteSentOn ?? null;
  }

  onSendInvite() {
    if ( !this.currentUser.product_owner ) {
      return;
    }

    this.sendingInvite = true;

    this._scriptsureService.sendInvite( this.userDetail._id )
      .then( response => this.populateInviteStatus( response.data.scriptsure_invite_sentOn ))
      .catch( error => {
        console.log( 'ERROR OCCURRED WHILE SENDING SCRIPTSURE INVITATION' );
        console.log( error );
      })
      .finally(() => {
        this.sendingInvite = false;
      });
  }
}
