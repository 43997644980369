import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { FacilityService } from 'src/app/services/facility.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { BillingCompanyAssociationService } from '../billing-company-association.service';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment'
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';

@Component({
  selector: 'app-billing-company-association',
  templateUrl: './billing-company-association.component.html',
  styleUrls: ['./billing-company-association.component.css']
})
export class BillingCompanyAssociationComponent implements OnInit {
  currentUser: any;
  companyID: '';
  selectedCompany: any ={
    _id: null
  };
  selectedFacilityId: any;
  selectedFacility: any;
  selectedProviderId: any;
  selectedBillingCompanyId: any;
  model = {
    company_id:"",
    facility_id:"",
    provider_id:"",
    dnBillingCompany_id:"",
    external_provider_id:"",
    external_facility_id:"",
    association_type: "single"
  }
  filter = {
    company_id: "",
    is_active: true
  };
  search = {
    provider : ""
  }
  projection = {};
  facilities = [];
  companies = [];
  providers = [];
  billingCompanies = [];
  billingCompaniesRelation = [];
  companyName = "";
  exPro = true;
  exFac = true;
  facId = true;
  proId = true;
  billId = true;
  proControl = new FormControl('');
  public dataSource: any;
  searchCompanyText: any;
  searchFacilityText: any;
  searchProviderText: any;
  searchBillingCompanyText: any;
  timeoutId: any;
  proValue = "all"
  public pagination: {
    pageNo: number,
    hasNextPage: boolean,
    hasPrevPage: boolean,
    totalCount: number,
    totalPages: number
  };
  oldProviderExternalId = '';
  oldFacilityExternalId = '';
  displayedColumns: string[] = ['company_name', 'facility_name','provider_name', 'billing_company', 'creation_date', 'external_provider', 'external_facility', "operations"];
  providerExternalId = '';
  facilityExternalId = '';
  showTextField : boolean = false;
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private facilityService: FacilityService,
    private billingCompanyAssociationService: BillingCompanyAssociationService,
    private companyService: CompanyService,
    private toastr: ToastrService,
  ) { 
    this.currentUser =  this.authService.currentUser;
    this.companyID =  this.route.parent.snapshot.params.id
    // console.log('this.companyID',this.companyID);
    this.pagination = {
      pageNo: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalCount: 0,
      totalPages: 0
    };
  }

  async ngOnInit() {
    this.filter = { 
      is_active: true,
      company_id: this.companyID,
    }
    this.projection = {
      'title': 1,
      'address': 1,
      'city': 1,
      'state': 1,
      'zip_code': 1,
      'is_active': 1,
      'source': 1,
      'provider_ids_ref': 1
    }

    if(this.currentUser.product_owner){
      let res:any = await this.companyService.getAllCompanies().toPromise();
      if(res.status == 200 ){
        this.companies = res.data
        this.companies.forEach(element =>{
          if(this.route.parent.snapshot.params.id == element._id){
            this.selectedCompany._id = element._id;
            this.filter.company_id = element._id;
            this.model.company_id = element._id;
          }
        });
      }
    }
    else{
      let res:any = await this.companyService.getCompanyName(this.companyID).toPromise();
      if(res.status ==  200){
        this.companyName = res.data.name;
        this.selectedCompany = res.data;
        this.model.company_id = this.selectedCompany._id
      }
    }
    this.getDNBillingAssociation(1);
    await this.getBillingCompanies();
    this.loadData()
    console.log("this.model.company_id", this.model.company_id, this.selectedCompany._id)
  }
  
  getcreationDate(date){
    return  `${moment(date).format('MM-DD-yyyy')}` 
  }
  async loadData(){
    let filter = {
      assoc_company_ids: this.filter.company_id,
      is_active: true
    }

    let providerPopulation: facilityPopulationInterface = {
      collection: 'users',
      field: ["provider_ids_ref"],
      filter: { $expr: { $and: [{ $eq: ["true", '$is_activated'] }, { $eq: [true, { $in: ['$_id', '$$provider_ids_ref'] }] }] } },
      project: {
        first_name: 1, last_name: 1, title: 1, recentLogin: 1, trueRCMID: 1,
        full_name: { $concat: ["$first_name", "$last_name", ", ", "$title"] }
      },
    };
    
    let facilitiesResponse:any = await this.facilityService.getFacilities(filter, this.projection, [providerPopulation]).toPromise();
    if(facilitiesResponse.status == 200){
      this.facilities = facilitiesResponse.data.array;
      // this.facilities.map(fac=>{
      //   fac.billing_companies = this.billingCompanies
      // })
      // console.log("Facilities after adding billing companies: ", this.facilities);
      // this.dataSource = new MatTableDataSource(this.facilities); 
    }
  }

  async getBillingCompanies(){
    let res:any = await this.billingCompanyAssociationService.getBillingCompanies().toPromise();
    if(res.status == 200){
      this.billingCompanies = res.data;
    }
  }

  async getDNBillingAssociation(page){
    this.pagination.pageNo = page;
    let res:any = await this.billingCompanyAssociationService.getDNBillingAssociation(page, this.search, this.selectedCompany._id, this.model).toPromise();
    if(res.status == 200){
      this.billingCompaniesRelation = res.data;
      this.billingCompaniesRelation.forEach(element => {
        element.showProviderTextField = false;
        element.showFacilityTextField = false;
      });
      
      this.dataSource = new MatTableDataSource(this.billingCompaniesRelation); 
      this.pagination.totalCount = res.totalRelations;
      this.initPagination();
    }
  }

  applyCompanyFilter(event){ 
    this.filter.company_id = event.value;
    let com = this.companies.map(c => {
      if(c._id == this.filter.company_id){
        return c;
      }
    }).filter(a=> a)
    this.selectedCompany = com[0];
    this.model.company_id = this.selectedCompany._id
    // console.log("this.model.company_id", this.model.company_id, this.selectedCompany._id)
    this.loadData()
    this.getDNBillingAssociation(1)
  }

  applyFacilityChange(event){
    if(event.value && event.value !== 'all') {
      this.model.association_type = "single"
      this.selectedFacilityId = event.value;
      this.model.facility_id = this.selectedFacilityId;
      this.selectedFacility = this.facilities.find((f) => f._id == event.value);
      this.model.external_facility_id = this.selectedFacility?.trueRCMID;

      this.providers = this.selectedFacility.provider_ids_ref;

      this.selectedProviderId = '';
      this.model.provider_id = '';

    } else if(event.value) {
      this.model.association_type = "all"
      this.selectedFacilityId = event.value;
      this.selectedFacility = 'all';
      this.model.facility_id = this.selectedFacilityId;
      this.model.external_facility_id = "";

      let allProviders = [];
      this.facilities.map(fac => {
        allProviders.push(...fac.provider_ids_ref)
      }) 
      let uniqueProviders = []; 
      allProviders.map(p => {
        if(uniqueProviders.some(a => a._id == p._id)){
        }
        else{
          uniqueProviders.push(p)
        } 
      })
      // console.log("uniqueProviders: ", uniqueProviders); 
      this.providers =  uniqueProviders;


      this.selectedProviderId = 'all';
      this.model.provider_id = 'all';
    }
    if(!event.value){
      this.selectedFacilityId = '';
      this.model.facility_id = '';
    }
    this.getDNBillingAssociation(1);

  //   if(event.value != 'all'){
  //     this.selectedFacilityId = event.value;
  //     this.model.association_type = "single"
  //     this.model.facility_id = [];
  //     this.model.facility_id.push(this.selectedFacilityId)
  //     this.selectedFacility = this.facilities.map(fac => {
  //       if(fac._id == this.selectedFacilityId){
  //         return fac;
  //       }
  //     }).filter(a => a)
  //     this.providers = this.selectedFacility[0].provider_ids_ref;
  //   }
  //   else{
  //     this.model.association_type = "all"
  //     this.model.facility_id = this.facilities.map(fac => fac._id)
  //     // console.log("this.model.facility_id: ====== ", this.model.facility_id)
  //     let allProviders = [];
  //     this.facilities.map(fac => {
  //       allProviders.push(...fac.provider_ids_ref)
  //     }) 
  //     // console.log("allProviders: ", allProviders);
  //     let uniqueProviders = []; 
  //     allProviders.map(p => {
  //       if(uniqueProviders.some(a => a._id == p._id)){
  //       }
  //       else{
  //         uniqueProviders.push(p)
  //       } 
  //     })
  //     // console.log("uniqueProviders: ", uniqueProviders); 
  //     this.providers =  uniqueProviders;
  // }
} 
  // }
  applyProviderChange(event = null){ 

    if(event.value && event.value !== 'all') {
      this.model.association_type = "single"
      this.selectedProviderId = event.value;
      this.model.provider_id = this.selectedProviderId;
      let selectedProvider = this.providers.find((p) => p._id == event.value);
      this.model.external_provider_id = selectedProvider?.trueRCMID;
    } else if (event.value) {
      this.model.association_type = "all"
      this.selectedProviderId = event.value;
      this.model.provider_id = this.selectedProviderId;
      this.model.external_provider_id = "";
      this.selectedFacility = 'all';
      this.model.facility_id = 'all';
    }
    if(!event.value){
      this.selectedProviderId = '';
      this.model.provider_id = '';
    }
    this.getDNBillingAssociation(1);
    // if(event.value != 'all'){
    //   this.proValue = event.value;
    //   this.model.association_type = "single"
    //   this.selectedProviderId = event.value;
    //   this.model.provider_id = []
    //   this.model.provider_id.push(this.selectedProviderId);
    // }
    // else{
    //   // this.proValue = 'all'
    //   this.selectedProviderId = "All";
    //   this.model.association_type = "all"
    //   this.model.provider_id = this.providers.map(p => p._id)
    // } 
  } 
  applyBillingCompnayChange(event){
    this.selectedBillingCompanyId = event.value;
    this.getDNBillingAssociation(1);
    // this.getProviders()
  }

  async getProviders(){
    let res:any = await this.billingCompanyAssociationService.getProviders(this.filter.company_id, this.selectedFacilityId).toPromise();
    if(res.status == 200){
      this.providers = res.data.provider_ids_ref;
      this.facilities.map(fac => {
        if(fac._id == res.data._id){
          fac.provider_ids_ref = res.data.provider_ids_ref
        } 
      })
    }
  }

  applyFilter(filterValue: string) {
    let filter = filterValue.trim().toLowerCase();
    // if (filter) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => { 
        this.search.provider = filter
        this.getDNBillingAssociation(1)
      }, 1000);
    // }

    // let filter = filterValue.trim().toLowerCase();
    // if (filter) {
    //   let filterVal = []; 
    //   this.billingCompaniesRelation.forEach(billCom=> { 
    //   if (billCom.providerId.first_name.trim().toLowerCase().includes(filter) || (billCom.providerId.last_name.trim().toLowerCase().includes(filter))) {
    //       filterVal.push(billCom)  
    //       this.dataSource = new MatTableDataSource(filterVal);
    //     }
    // });
    // }
    // else {
    //   this.dataSource = new MatTableDataSource(this.billingCompaniesRelation);
    // }
  }

  async createAssociation(){ 
    if(!this.model.facility_id){ 
      return this.facId = false
    }
    else if(!this.model.provider_id){
      return this.proId = false
    }
    else if(!this.model.dnBillingCompany_id || this.model.dnBillingCompany_id == ''){
      return this.billId = false
    }
    // else if(!this.model.external_provider_id){
    //   return this.exPro = false
    // }
    // else if(!this.model.external_facility_id){
    //   return this.exFac = false
    // }
    else {
      this.toastr.info("Creating Association/s")
      let res:any = await this.billingCompanyAssociationService.createBillingCompanyAssociation(this.model).toPromise();
      if(res.status == 200 || res.status == 201){
        this.exFac = true;
        this.exPro = true;
        this.model.dnBillingCompany_id = "";
        this.model.external_provider_id = "";
        this.model.external_facility_id = "";
        // this.model.association_type = "single";
        this.model.company_id = this.selectedCompany._id;
        this.getDNBillingAssociation(1)
        if(res.status == 200){
          this.toastr.success(res.message)
        }
        else{
          this.toastr.error(res.message)
        }
      }
      else{
        this.toastr.error(res.message)
      }
    }

    
  }
  getProviderName(element){
    if(element.association_type == 'single' || (element.providerId)){
      return element.providerId? element.providerId.first_name+ ' ' +element.providerId.last_name + ',' + element.providerId.title : ''
    }
    else{
      return "All"
    }
  }
  getProviderTrueRCMID(element){
    if(element.association_type == 'single' || (element.providerId)){
      return element.providerId? element.providerId.trueRCMID : ''
    }else{
      const trueRCMIDs = this.facilities.reduce((acc, next) => {
        const trueRCMIDsByFacility = next.provider_ids_ref.map(p => p.trueRCMID).filter(p => p);
        return [...acc, ...trueRCMIDsByFacility];
      }, []);
      return trueRCMIDs.join(', ');
    }
  }
  getFacilityName(element){
    if(element.association_type == 'single' || (element.facilityId)){
      return element.facilityId? element.facilityId.title: ""
    }
    else{
      return "All"
    }
  }

  getFacilityTrueRCMID(element){
    if(element.association_type == 'single' || (element.facilityId)){
      return element.facilityId? element.facilityId.trueRCMID: ""
    }
    else{
      const trueRCMIDs = this.facilities.map(f => f.trueRCMID).filter(f => f);
      return trueRCMIDs.join(', ');
    }
  }

  async onDeleteClick(element){ 
    let res:any = await this.billingCompanyAssociationService.deleteRelation(element._id).toPromise();
    if(res.status == 200){
      let index = this.billingCompaniesRelation.findIndex(r => element._id == r._id)
      this.billingCompaniesRelation.splice(index, 1);
      this.dataSource = new MatTableDataSource(this.billingCompaniesRelation)
    }
  }

  handleNextClick() {
    this.getDNBillingAssociation(this.pagination.pageNo + 1);
  }

  handlePrevClick() {
    this.getDNBillingAssociation(this.pagination.pageNo - 1);
  }
  getcountOfRelations() {
    return ( this.billingCompaniesRelation.length > 0 ? (this.pagination.pageNo * 10) - 9 : 0 ) + ' - ' + (((this.pagination.pageNo * 10) - 10) + (this.billingCompaniesRelation.length)) + " out of " + (this.pagination.totalCount);
  }
  initPagination() {
    this.pagination.totalPages = Math.ceil(this.pagination.totalCount / 10); 
    if( this.billingCompaniesRelation){
      this.pagination.hasNextPage = this.pagination.pageNo < this.pagination.totalPages;
    }
    this.pagination.hasPrevPage = this.pagination.pageNo > 1;
    // this.loader.stopLoader('loader-01');
    // this.loading = false;
  }
  
  updateExternalProviderId(element) {
    console.log('elementt',element);
    this.billingCompanyAssociationService.updateProviderExternalId(element._id,this.oldProviderExternalId).subscribe((res:any)=>{
      if(res.status == 200) {
        console.log('res',res);
        element.providerTrueRCMID = this.oldProviderExternalId;
        element.showProviderTextField = !element.showProviderTextField;
      }
    });

  }

  updateExternalFacilityId(element) {
    console.log('elementt',element);
    this.billingCompanyAssociationService.updateFacilityExternalId(element._id,this.oldFacilityExternalId).subscribe((res:any)=>{
      if(res.status == 200) {
        element.facilityTrueRCMID = this.oldFacilityExternalId;
        element.showFacilityTextField = !element.showFacilityTextField;
        console.log('res',res);
      }
    });

  }
  isPCCFacility() {
    return this.selectedFacility != undefined && this.selectedFacility != 'all' && (this.selectedFacility?.source === 'PointClickCare' || this.selectedFacility?.pcc_facId)
  }
  isMatrixFacility() {
    return this.selectedFacility != undefined && this.selectedFacility != 'all' && this.selectedFacility?.source === 'MatrixCare'
  }
  isDNFacility() {
    return this.selectedFacility != undefined && this.selectedFacility != 'all' && this.selectedFacility?.source != 'PointClickCare' && !this.selectedFacility?.pcc_facId && this.selectedFacility?.source != 'MatrixCare'
  }

  editExternalProvider(element) {
    element.showProviderTextField = !element.showProviderTextField;
    this.oldProviderExternalId = element.providerTrueRCMID;
  }
  
  editExternalFacility(element) {
    element.showFacilityTextField = !element.showFacilityTextField;
    this.oldFacilityExternalId = element.facilityTrueRCMID;
  }
}
