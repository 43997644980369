import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { CreateGeneralReportDialogComponent } from './create-general-report-dialog/create-general-report-dialog.component';
import { QueryReportService } from './service/query-report.service';

@Component({
  selector: 'app-general-report',
  templateUrl: './general-report.component.html',
  styleUrls: ['./general-report.component.css']
})
export class GeneralReportComponent implements OnInit {

  displayedColumns: string[] = [
    'sr', 'title', 'company', 'actions'];
  dataSource: any = new MatTableDataSource([]);
  isQueryReportVisible: boolean = false;
  dialogSubscription;
  generalReports: any[];
  parentReport: any = {};

  constructor(
    private _authService: AuthService,
    private _toastr: ToastrService,
    private _route: ActivatedRoute,
    private dialog: MatDialog,
    private _queryService: QueryReportService,

  ) {
    this._route.queryParams.subscribe(params => {
      if (params) {
        this.showQueryReport(params.id, params.title);
      }
    });
  }

  ngOnInit(): void {
    this.getGeneralReport();
  }
  getGeneralReport() {
    let filter = {};
    let project = {
      title: 1,
      associated_company_ids: 1
    };
    this._queryService.getGeneralReport(filter, project).subscribe((response: any) => {
      if (response.status == 200) {
        this.generalReports = response.data;
        this.dataSource.data = this.generalReports;
      }
    }, err => {
      this._toastr.error(err, "ERROR");
    }
    );
  }

  showQueryReport(parentReportId, title) {
    this.parentReport.id = parentReportId;
    this.parentReport.title = title;
    this.isQueryReportVisible = true;
  }

  openCreateGeneralReportDialog(generalReportId = null, index = null) {
    // let data: any = {
    //   parentReport: generalReportId
    // };
    // data.queryId = queryId;
    let options: any = {
      width: '50%',
      height: '40vh',
      data: generalReportId
    }
    this.dialogSubscription = this.dialog.open(CreateGeneralReportDialogComponent, {
      ...options,
    }).afterClosed().subscribe((generalReport) => {
      if (generalReport) {
        this.dataSource.data = [];
        if (!generalReportId) this.generalReports.push(generalReport);
        else this.generalReports[index] = generalReport;
        this.dataSource.data = this.generalReports;
      }
    });


  }

}
