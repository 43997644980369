<div calss="container">
    <div class="flex flex-row">
        <app-input-with-dropdown class="flex" [noDropdown]="true" placeholder="Search Nurse"
            (inputChange)="applyFilter($event.target.value)">
        </app-input-with-dropdown>
    </div>
    <div style="height: 10px;"></div>
    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="nurse_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nurse Name</th>
            <td mat-cell *matCellDef="let element" style="width: 20%">
                {{element.first_name}} {{element.last_name}}
                <div style="font-size: 11px;">{{getEmailId(element)}}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="provider_name">
            <th mat-header-cell *matHeaderCellDef> Providers </th>
            <td mat-cell *matCellDef="let element" style="width: 60%">
                <ng-multiselect-dropdown name="transcribers" [(ngModel)]="element.assos_providers"
                    [placeholder]="'Select Provider(s)'" [settings]="providerDropdownSettings"
                    [data]="element.providers" (onSelect)="onProviderSelect($event, element); "
                    (onSelectAll)="onSelectAllProvider($event, element); "
                    (onDeSelect)="onProviderDeSelect($event, element);">
                </ng-multiselect-dropdown>
            </td>

        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
    </table>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>