import { Component, Input,OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { Input } from 'hammerjs';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CompanyService } from 'src/app/services/Modules/company.service';
import {
	MatTable,
	MatTableDataSource
} from '@angular/material/table';
import { MatSort, MatSortable, Sort } from "@angular/material/sort";
import { MatDialog } from '@angular/material/dialog';
import { AppointmentTypeDialogComponent } from '../appointment-type-dialog/appointment-type-dialog.component';
import { DialogConfirmationComponent } from '../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { UserService } from 'src/app/services/Modules/user.service';
import { AuthService } from 'src/app/services/auth.service';
import * as global from 'src/app/includes/global'
import { AppointmentService } from 'src/app/services/appointment.service';
@Component({
  selector: 'app-scheduler-setting',
  templateUrl: './scheduler-setting.component.html',
  styleUrls: ['./scheduler-setting.component.css']
})
export class SchedulerSettingComponent implements OnInit {
  @Input() company:any;
  formData: any = {};
  hours: string[] = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
  minutes: string[] = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));
  dataSource: any = new MatTableDataSource([]);
  appointmentTypes: any[] = []; // Initialize as an empty array
  displayedColumns: string[] = ['name', 'duration','action'];
  selectedStartHour: string = '07';
  selectedStartMinute: string = '00';
  selectedEndHour:string = '19';
  selectedEndMinute:string = '00';
  companyPath: Boolean = false;
  currentCompanyId: any;
  company_id: any;
  searchTextProvider:any = '';
  uniqueProviders: any = [];
  loaderId = "scheduler-settings-loader";
  daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  workingHours: { daysOfWeek: any[], startTime: string, endTime: string }[] = [];
  selectedDays: string[] = []; // Array to store selected days
  users: Array < any > = [];
  constructor( 	private appointmentService: AppointmentService,private companyService: CompanyService,private toastr: ToastrService,private route: ActivatedRoute,private loader: NgxUiLoaderService,private dialog: MatDialog,private userService: UserService,private authService: AuthService,) {
    this.companyPath = window.location.pathname.includes('company');
        if (this.companyPath) {
            if (this.route.snapshot.params.companyId) {
                this.currentCompanyId = this.route.snapshot.params.companyId
            }
            this.company_id = this.route.snapshot.params.id;
            if (!this.company_id) {
                // this.company_id = this.route.snapshot.paramMap.get('id');
                this.company_id = this.route.parent.snapshot.params.id;
            }
            
        }
   }

  async ngOnInit() {
    await this.getCompanName()
    await this.getSettings()
    await this.getResources()
  //   this.workingHours = [
  //     {
  //         "daysOfWeek": [
  //             1
  //         ],
  //         "startTime": "07:00",
  //         "endTime": "17:00"
  //     },
  //     {
  //         "daysOfWeek": [
  //             2
  //         ],
  //         "startTime": "07:00",
  //         "endTime": "17:00"
  //     },
  //     {
  //         "daysOfWeek": [
  //             3
  //         ],
  //         "startTime": "07:00",
  //         "endTime": "17:00"
  //     },
  //     {
  //         "daysOfWeek": [
  //             4
  //         ],
  //         "startTime": "07:00",
  //         "endTime": "17:00"
  //     },
  //     {
  //         "daysOfWeek": [
  //             5
  //         ],
  //         "startTime": "07:00",
  //         "endTime": "17:00"
  //     }
  //  ];
  
    
  }
  async getCompanName() {
    let companyId = this.company_id;
    this.loader.startLoader("scheduler-settings-loader");
    if (this.currentCompanyId) companyId = this.currentCompanyId;
    let response = await this.companyService.getCompanyName(companyId).toPromise();
    if (response.status == 200 && response.data) {
        const [startHour, startMinute]  = response.data.businessStartTime.split(':');
        this.selectedStartHour = startHour
        this.selectedStartMinute = startMinute
        const [endHour, endMinute] = response.data.businessEndTime.split(':');
        this.selectedEndHour = endHour
        this.selectedEndMinute = endMinute
        
    }
 }
 getResources() {
  const filter = {
    is_activated: "true",
    company_id: this.authService.currentUser.company_id,
    user_type: global.USER_TYPE.DOCTOR,
  };
  const projection = {
    first_name: 1,
    last_name: 1,
    title: 1,
    company_id: 1,
  };
  this.userService
    .getUsers(filter, projection)
    .subscribe(async (response: any) => {
      if (response.status == 200) {
        // this.users = [...response.data];
        this.users = response.data.map((u) => {
          u.name = `${u.first_name} ${u.last_name}`;
          if (u.user_type == "1") {
            u.name += `, ${u.title}`;
          }
          return u;
        });
        // this.providers = this.providers.map(d => ({ ...d, name: `${d.first_name} ${d.last_name}, ${d.title}` }));
        const map = new Map();
        for (const item of this.users) {
          if (!map.has(item._id) && item.first_name) {
            map.set(item._id, true); // set any value to Map
            this.uniqueProviders.push({
              id: item._id,
              name: `${item.first_name} ${item.last_name}, ${item.title}`,
            });
          }
        }
        this.uniqueProviders = this.sortFirstNameVise(this.uniqueProviders);
      }
    });
 }
 sortFirstNameVise(arrayTosort) {
  let users = arrayTosort;
  let result = users.sort(function(a, b) {
    if (a.first_name?.toLowerCase() < b.first_name?.toLowerCase()) {
      return -1;
    }
    if (a.first_name?.toLowerCase() > b.first_name?.toLowerCase()) {
      return 1;
    }
    return 0;
  })
  return result;
}
  onSelectProvider(item = null) {
    this.toggleLoader(true)
    const data = {
      company_id: this.company._id,
      provider_id:this.formData.providerId,
    }
    this.appointmentService.getUserBusinesshour(data).subscribe(
      (response: any) => {
        if(response.status === 200) {
          this.selectedDays = response.data.map(selectedDay => selectedDay.daysOfWeek[0]);
          console.log(this.selectedDays,'selected')
          this.workingHours = response.data || []
          this.toggleLoader(false)
        }
      },
      (error) => {
        console.log(error);
      }
    );
    console.log('his.workingHours ',data )
  }
  onStartTimeChange() {
    const time = `${this.selectedStartHour}:${this.selectedStartMinute}`;
    // this.timeChange.emit(time);
  }
  onEndTimeChange() {
    const time = `${this.selectedEndHour}:${this.selectedEndMinute}`;
    // this.timeChange.emit(time);
  }
  updateComapny(){
    let data = {
      _id: this.company._id,
      businessStartTime:`${this.selectedStartHour}:${this.selectedStartMinute}`,
      businessEndTime:`${this.selectedEndHour}:${this.selectedEndMinute}`
    }
    this.companyService.updateCompany(data).subscribe((response: any) => {
      if (response.status == 200) {
        this.getCompanName()
        this.toastr.success(response.message, 'Success');
      } else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  addAppointmentTypeDialog(data = null){
    const dialogRef = this.dialog.open(AppointmentTypeDialogComponent, {
      width: '400px',
      data: data,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.handleAppointmentTypesAction(result)      
      }
    });
  }
  handleAppointmentTypesAction(result){
    // Add appointment type
    let message = ''
    console.log('handleAppointmentTypesAction',result);
  if (result.action === 'add') {
    this.appointmentTypes.push(result.appointmentType);
     message = 'Appointment Type added Successfully.'
  }
  
  // Update appointment type
  if (result.action === 'update') {
    const index = this.appointmentTypes.findIndex(type => type._id === result.appointmentType._id);
    if (index !== -1) {
      this.appointmentTypes[index] = result.appointmentType;
      message = 'Appointment Type updated Successfully.'
    }
  }
  
  // Delete appointment type
  if (result.action === 'delete') {
    const index = this.appointmentTypes.findIndex(type => type._id === result.appointmentType._id);
    // const index = this.appointmentTypes.findIndex(type => type._id === result.appointmentTypeId);
    if (index !== -1) {
      this.appointmentTypes.splice(index, 1);
      message = 'Appointment Type deleted Successfully.'
    }
  }
  this.loader.startLoader("scheduler-settings-loader");
  // Save changes to the backend API
  this.companyService.saveCompanyAppointmentTypes(this.company._id, this.appointmentTypes).subscribe(
    (response: any) => {
      if(response.status === 200) {
        this.toastr.success(message, "Success");
        this.getSettings();
      }
    },
    (error) => {
      console.log(error);
    }
  );
  }
  updateAppointmentType(index,data){
   this.addAppointmentTypeDialog(data)
  }
  deleteAppointmentType(index,appointment_type){
    let dialogReference = this.dialog.open(DialogConfirmationComponent, {
			data: {
				message: 'Are you sure you want to delete this appointment Type?'
			}
		});
		dialogReference.afterClosed().subscribe(async result => {
			if (result === "true") {
				const type = {
          action : 'delete',
          appointmentType:appointment_type
        }
        this.handleAppointmentTypesAction(type) 
			}
		}), () => this.toastr.error('Something went wrong removing Patient Census', 'Failed')
  }
  getSettings(){
    // this.loader.startLoader("scheduler-settings-loader");
    this.companyService.getCompanyAppointmentTypes(this.company._id).subscribe((response: any) => {
        this.appointmentTypes = response.data;
          this.dataSource.data =  response.data;
          this.loader.stopLoader("scheduler-settings-loader")
    })
  }
  addWorkingDay(day: string) {
    const existingDayIndex = this.workingHours.findIndex(item => item.daysOfWeek.includes(day));

    if (existingDayIndex !== -1) {
      // If the day is already added, remove it
      this.workingHours.splice(existingDayIndex, 1);
    } else {
      // If the day is not added, add it
      this.workingHours.push({ daysOfWeek: [day], startTime: '', endTime: '' });
    }
  }
    // Function to toggle selection of a day
    toggleDaySelection(day: string) {
      if (this.selectedDays.includes(day)) {
        // If the day is already selected, remove it
        this.selectedDays = this.selectedDays.filter(selectedDay => selectedDay !== day);
      } else {
        // If the day is not selected, add it
        this.selectedDays.push(day);
      }
    }
   // Function to copy times to all selected days
   copyTimesToAll() {
    if (this.selectedDays.length > 0 && this.workingHours.length > 0) {
      const selectedDayTimes = this.workingHours.find(item => item.daysOfWeek.includes(this.selectedDays[0]));
  
      // Update times for all selected days
      this.selectedDays.forEach(day => {
        const existingDayIndex = this.workingHours.findIndex(item => item.daysOfWeek.includes(day));
  
        if (existingDayIndex !== -1) {
          // If the day already exists, update the times
          this.workingHours[existingDayIndex].startTime = selectedDayTimes.startTime;
          this.workingHours[existingDayIndex].endTime = selectedDayTimes.endTime;
        } else {
          // If the day does not exist, add a new row
          // this.workingHours.push({ daysOfWeek: [day], startTime: selectedDayTimes.startTime, endTime: selectedDayTimes.endTime });
        }
      });
    }
  }
  toggleLoader(loading: boolean) {
    if (loading) {
      this.loader.startLoader('business-loader');
    } else {
      this.loader.stopLoader('business-loader');
    }
  }
  saveWorkingHours(){
    const data = {
      company_id: this.company._id,
      provider_id:this.formData.providerId,
      businessHours: this.workingHours
    }
    this.appointmentService.saveUserBusinesshour(data).subscribe(
      (response: any) => {
        if(response.status === 200) {
          this.toastr.success(response.message, "Success");
          // this.getSettings();
        }
      },
      (error) => {
        console.log(error);
      }
    );
    console.log('his.workingHours ',data )
  }
}
