<div class="h100 flex md:flex-col justify-center items-center" *ngIf="showScreen">
  <mat-card class="w-2/5 xs:w-full">

    <mat-card-title class="flex flex-col justify-center items-center">
      <img [src]="companylogoPath ? companylogoPath : defaultImg">

      <h2 *ngIf="!verifyExpiry">Create Password</h2>
      <h2 *ngIf="verifyExpiry">Verification Link Expired</h2>
    </mat-card-title>

    <mat-card-content class="flex flex-col">
      <ng-container  *ngIf="!verifyCodeView && !verifyExpiry">
      <form (submit)="onSubmitWithCognito(loginForm)" #loginForm="ngForm">
        <mat-form-field>
          <input matInput type="text"
                 name="email"
                 placeholder="Email address"
                 #emailField="ngModel" [(ngModel)]="params.email" required readonly>
          <mat-error *ngIf="emailField.invalid">Please enter email</mat-error>
        </mat-form-field>

        <div class="flex gap-5">
        <mat-form-field class="w-1/2">
          <input matInput
                 name="newPassword"
                 placeholder="New Password"
                 #newPasswordField="ngModel" [(ngModel)]="newPassword" ngModel required [autocomplete]="false" (click)="clearErrors()" (input)="isPasswordValid()"
                 [type]="hide ? 'password' : 'text'" >
                 <mat-icon matSuffix style="cursor: pointer;" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="newPasswordField.invalid">Please enter new password</mat-error>
        </mat-form-field>



        <mat-form-field class="w-1/2">
          <input matInput
                 name="confirmPassword"
                 placeholder="Confirm Password"
                 #confirmPasswordField="ngModel" [(ngModel)]="confirmPassword" ngModel required [autocomplete]="false" (input)="isPasswordValid()"
                 [type]="hide ? 'password' : 'text'" [disabled]="!enableConfirmPassword">
                 <mat-icon matSuffix style="cursor: pointer;" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="confirmPasswordField.invalid">Please enter confirm password</mat-error>
        </mat-form-field>
        </div>
        <div style="text-align: left; font-size: 11px;line-height: 7px;">
          <h1 style="margin-bottom: 14px; font-size: 14px; color: grey;"><b>Password Policy</b></h1>
          <p [ngStyle]="{'color': policyUpperCase ? '#00FF00':'	#FF0000'}">1. Require at least 1 Upper case</p>
          <p [ngStyle]="{'color': policyLowerCase ? '#00FF00':'	#FF0000'}">2. Require at least 1 Lower case </p>
          <p [ngStyle]="{'color': policyNumber ? '#00FF00':'	#FF0000'}">3. Require at least 1 number</p>
          <p [ngStyle]="{'color': policySpecialCharacter ? '#00FF00':'	#FF0000'}">4. Require at least 1 special character</p>
          <p [ngStyle]="{'color': policyLength ? '#00FF00':'	#FF0000'}">5. Min Length: 10</p>
        </div>

        <div class="flex justify-center items-center" style="margin-bottom: 23px;">
          <button mat-raised-button class="dn-button-primary w-1/2" mat-stroked-button type="submit" [disabled]="!enableSaveButton">Save</button>
        </div>

        <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

      </form>
      </ng-container>
      <ng-container *ngIf="verifyCodeView && !verifyExpiry">
        <div style="font-size: 12px;">A verification code has been sent to your phone.</div>
        <form (submit)="verifyCode(verifyCodeForm)" #verifyCodeForm="ngForm">
          <mat-form-field>
            <input matInput [type]="hide ? 'password' : 'text'"
                   name="code"
                   placeholder="Verification Code"
                   #codeField="ngModel" [(ngModel)]="code" [disabled]="display == '00:00'" required>
              <mat-icon matSuffix style="cursor: pointer;" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="codeField.invalid">Please enter verification code</mat-error>
          </mat-form-field>
          <mat-error *ngIf="error">{{error}}</mat-error>

          <div class="flex justify-center items-center">
            <button mat-raised-button class="dn-button-primary w-1/2" mat-stroked-button type="submit" [disabled]="display == '00:00'">Verify</button>
          </div>
          <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

        </form>
        <div class="resend-code">
          If you have not received your temporary authentication code 2 minutes, click 
          <button class="resend-code-link" type="button" mat-button (click)="resendConfirmationCode()" [disabled]="display != '00:00' || disableButton">
              resend
          </button>
          to receive a new code. ({{display}})
        </div>

      </ng-container>
      <ng-container *ngIf="verifyExpiry">
        <div style="font-size: 12px; text-align: center; ">Registration link has been expired. Please contact your admin.</div>
      </ng-container>
    </mat-card-content>

  </mat-card>
</div>