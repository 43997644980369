<form #form="ngForm" (ngSubmit)="handleSubmit(form)" >
    <div  class="fields-sections flex-col">
        <div class="fields-section">

            <mat-form-field class="field-item">
                <mat-label>User type</mat-label>
                <mat-select [(ngModel)]="model.user_type" name="user_type" required>
                    <mat-option value="provider">
                        Provider
                    </mat-option>
                    <mat-option value="transcriber">
                        Transcriber
                    </mat-option>
                    <mat-option value="manager">
                        Admin
                    </mat-option>
                    <mat-option value="biller">
                        Biller
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="!form.value.user_type">Please select user type</mat-error>
            </mat-form-field>

        </div>
        <div class="fields-section">

            <div class="flex flex-col">
                <mat-form-field class="field-item" *ngIf="model.user_type === 'provider'">
                    <mat-label>Title</mat-label>
                    <input matInput type="text" [(ngModel)]="model.title" name="title" [matAutocomplete]="titleAutoComplete"
                    style="cursor: pointer;" [formControl]="titleControl">
                    <mat-icon matSuffix style="cursor: pointer;">keyboard_arrow_down</mat-icon>
                    <mat-autocomplete [displayWith]="displayProviderFn"
                                    #titleAutoComplete="matAutocomplete">
                                    <mat-option *ngFor="let title of titles" [value]="title">
                                        {{title}}
                                    </mat-option>
                                </mat-autocomplete> 
                    <mat-error *ngIf="!form.value.title">Please select title</mat-error>
                </mat-form-field>
                <mat-form-field class="field-item">
                    <input matInput placeholder="First Name" [(ngModel)]="model.first_name" name="first_name" required/>
                    <mat-error *ngIf="!form.value.first_name">Please enter first name</mat-error>
                </mat-form-field>

                <mat-form-field class="field-item">
                    <input matInput placeholder="Last Name" [(ngModel)]="model.last_name" name="last_name" required/>
                    <mat-error *ngIf="!form.value.last_name">Please enter last name</mat-error>
                </mat-form-field>
                
                <mat-form-field  class="field-item" aria-readonly="company">
                    <mat-label>Select Company</mat-label>
                    <mat-select  [(ngModel)]="model.company_id" name='company'>
                        <mat-option *ngFor="let company of companies" [value]='company._id'>{{company.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="flex flex-col">
                <mat-form-field class="field-item">
                    <input matInput type="email" placeholder="Work Email" [(ngModel)]="model.email" name="email"
                           required pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"/>
                    <mat-error *ngIf="!form.value.email">Please enter email address</mat-error>
                </mat-form-field>

                <mat-form-field class="field-item">
                    <input matInput type="number" placeholder="Phone Number" [(ngModel)]="model.mobile" name="mobile" required/>
                    <mat-error *ngIf="!form.value.mobile">Please enter phone number</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
    <br/>
    <div class="flex flex-row-reverse gap-5">
        <button type="button" mat-raised-button class="dn-button-secondary" (click)="cancel()">
            Cancel
        </button>
        <button mat-raised-button class="dn-button-primary" type="submit">
            Save
        </button>
    </div>
</form>
