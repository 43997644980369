<div>
    <div class="flex justify-between gap-5">
        <span *ngIf="!currentUser.product_owner" style="align-self: center;font-weight: bolder;">
            {{companyName}}
        </span>
        <mat-form-field *ngIf="currentUser.product_owner">
            <mat-label>Companies</mat-label>
            <mat-select value="true" [ngModel]="filter.company_id" (selectionChange)="applyCompanyFilter($event)">
                <input placeholder="Search" [(ngModel)]="searchCompanyText" [ngModelOptions]="{standalone: true}"
                style="color:#000;height: 40px;padding-left: 5px; border: none; width: 100%;" />
                <mat-option *ngFor="let company of companies | searchCompany : searchCompanyText" value="{{company._id}}">{{company.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="flex justify-center items-center gap-2" *ngIf="billingCompaniesRelation">
        
            <button mat-raised-button class="dn-button-primary"   [disabled]="!pagination.hasPrevPage"
                (click)="handlePrevClick()"> Prev</button>
        
                <span style="background: white; border-radius: 2px; font-size: 12px;padding: 5px;"> {{getcountOfRelations()}}</span>
        
            <button mat-raised-button class="dn-button-primary" [disabled]="!pagination.hasNextPage"
                (click)="handleNextClick()">Next</button>
        </div>
    </div>

    <div style="height: 10px;"></div>

    <div class="flex gap-5" style="align-items: flex-end !important; place-content: space-between;">
        <mat-form-field>
            <mat-label>Select Facility</mat-label>
            <div class="flex justify-start items-center">
                <img *ngIf="isPCCFacility() && model.facility_id" src="../../../assets/icons/pcc-icon.svg" alt=""
                    style="width: 35px;height: 20px;margin-left: -5px;;vertical-align: middle;">
                <img *ngIf="isMatrixFacility() && model.facility_id" src="../../../assets/icons/matrix.png" alt=""
                    style="height: 12px;width: 20px;vertical-align: middle;margin-right: 5px;margin-left: 5px;">
                <img *ngIf="isDNFacility() && model.facility_id" class="dn-icon" src="../../../assets/img/DN.png" alt="">
                <mat-select [ngModel]="model.facility_id" (selectionChange)="applyFacilityChange($event); facId = true">
                    <input  placeholder="Search" [(ngModel)]="searchFacilityText" [ngModelOptions]="{standalone: true}"
                        style="color:#000;height: 40px;padding-left: 5px; border: none; width: 100%;" />
                        <mat-option value="" style=" margin-left: 5px;">----</mat-option>
                    <mat-option value="all" style=" margin-left: 6px;">All</mat-option>
                    <mat-option *ngFor="let facility of facilities | searchFacility : searchFacilityText"
                        value="{{facility._id}}">
                        <img *ngIf="facility?.source === 'PointClickCare' || facility?.pcc_facId"
                            src="../../../assets/icons/pcc-icon.svg" alt=""
                            style="width: 35px;height: 20px;margin-left: -5px;;vertical-align: middle;">
                        <img *ngIf="facility?.source === 'MatrixCare' " src="../../../assets/icons/matrix.png" alt=""
                            style="height: 12px;width: 20px;vertical-align: middle;margin-right: 5px;margin-left: 5px;">
                        <img *ngIf="facility?.source != 'PointClickCare' && !facility?.pcc_facId &&facility?.source != 'MatrixCare'"
                            class="dn-icon" src="../../../assets/img/DN.png" alt=""> {{facility.title ?facility.title : ""}}
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="!facId" style="color: red;">Please enter Facility</mat-hint>
            </div>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Select Provider</mat-label>
            <mat-select (selectionChange)="applyProviderChange($event); proId = true" [ngModel]='model.provider_id' >
                <input  placeholder="Search" [(ngModel)]="searchProviderText" [ngModelOptions]="{standalone: true}"
                style="color:#000;height: 40px;padding-left: 5px; border: none; width: 100%;" />
                <mat-option value="" style=" margin-left: 5px;">----</mat-option>
                <mat-option value="all" >All</mat-option>
                <mat-option *ngFor="let pro of providers | searchProvider : searchProviderText" value="{{pro._id}}">{{pro.full_name ? pro.full_name : ""}}</mat-option>
            </mat-select>
            <mat-hint *ngIf="!proId" style="color: red;">Please enter Provider</mat-hint>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Select billing company</mat-label>
            <mat-select (selectionChange)="applyBillingCompnayChange($event); billId = true" [(ngModel)]="model.dnBillingCompany_id">
                <input  placeholder="Search" [(ngModel)]="searchBillingCompanyText" [ngModelOptions]="{standalone: true}"
                style="color:#000;height: 40px;padding-left: 5px; border: none; width: 100%;" />
                <mat-option value="" style=" margin-left: 5px;">----</mat-option>
                <mat-option *ngFor="let bilCom of billingCompanies | searchCompany : searchBillingCompanyText" value="{{bilCom._id}}">
                    {{bilCom.name ? bilCom.name : ""}}</mat-option>
            </mat-select>
            <mat-hint *ngIf="!billId" style="color: red;">Please enter billing company</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" style="align-self: center;">
            <mat-label>External provider ID</mat-label>
            <input matInput [(ngModel)]="model.external_provider_id" [formControl]="proControl">
        </mat-form-field>

        <mat-form-field appearance="fill" style="align-self: center;">
            <mat-label>External Facility ID</mat-label>
            <input matInput [(ngModel)]="model.external_facility_id">

        </mat-form-field>

        <button mat-raised-button class="dn-button-primary" (click)="createAssociation()"> Create Association</button>

    </div>

    <div style="height: 10px;"></div>

    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="company_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Company</th>
            <td mat-cell *matCellDef="let element"> 
                {{element.companyId? element.companyId.name : ''}} 
            </td>
        </ng-container>

        <ng-container matColumnDef="facility_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Facility</th>
            <td mat-cell *matCellDef="let element">
                <img *ngIf="element.facilityId?.source === 'PointClickCare' || element.facilityId?.pcc_facId"
                    src="../../../assets/icons/pcc-icon.svg" alt=""
                    style="width: 35px;height: 20px;margin-left: -5px;;vertical-align: middle;">
                <img *ngIf="element.facilityId?.source === 'MatrixCare' " src="../../../assets/icons/matrix.png" alt=""
                    style="height: 12px;width: 20px;vertical-align: middle;margin-right: 5px;margin-left: 5px;">
                <img *ngIf="element.facilityId != null && element.facilityId?.source != 'PointClickCare' && !element.facilityId?.pcc_facId &&element.facilityId?.source != 'MatrixCare'"
                    class="dn-icon" src="../../../assets/img/DN.png" alt="">
                {{getFacilityName(element)}}  
            </td>
        </ng-container>

        <ng-container matColumnDef="provider_name">
            <th mat-header-cell *matHeaderCellDef> Providers </th>
            <td mat-cell *matCellDef="let element ; let i = index;">
                {{getProviderName(element)}}  
            </td>
        </ng-container>


        <ng-container matColumnDef="billing_company">
            <th mat-header-cell *matHeaderCellDef> Billing Company </th>
            <td mat-cell *matCellDef="let element ; let i = index;">
                {{element.billingCompanyId? element.billingCompanyId.name : ''}}  
            </td>
        </ng-container>
        
        <ng-container matColumnDef="creation_date">
            <th mat-header-cell *matHeaderCellDef> Creation Date </th>
            <td mat-cell *matCellDef="let element ; let i = index;">
                {{element.createdAt? getcreationDate(element.createdAt) : ''}}  
            </td>
        </ng-container>

        <ng-container matColumnDef="external_provider">
            <th mat-header-cell *matHeaderCellDef> External Provider </th>
            <td mat-cell *matCellDef="let element ; let i = index;">
                <div class="flex justify-start items-center gap-5">
                    <span style="min-width: 50px;" *ngIf="!element.showProviderTextField">                    
                        <div>
                            {{getProviderTrueRCMID(element)}}
                        </div>
                    </span>
                    <mat-form-field style="width: 100px;" *ngIf="element.showProviderTextField" appearance="fill">
                        <input matInput type="text" [(ngModel)]="oldProviderExternalId">
                        <button *ngIf="getProviderTrueRCMID(element)" matSuffix mat-icon-button aria-label="Clear" (click)="element.showProviderTextField = !element.showProviderTextField">
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                    <span style="cursor:pointer;" *ngIf="element.showProviderTextField" class="material-icons" (click)="updateExternalProviderId(element)">save</span>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="external_facility">
            <th mat-header-cell *matHeaderCellDef> External Facility </th>
        <td mat-cell *matCellDef="let element ; let i = index;">
            <div class="flex justify-start items-center gap-5">
                <span style="min-width: 50px;" *ngIf="!element.showFacilityTextField">
                    <div>
                        {{getFacilityTrueRCMID(element)}}
                    </div>
                </span>
                <mat-form-field style="width: 100px;" *ngIf="element.showFacilityTextField" appearance="fill">
                    <input matInput type="text" [(ngModel)]="oldFacilityExternalId">
                    <button *ngIf="getFacilityTrueRCMID(element)" matSuffix mat-icon-button aria-label="Clear" (click)="element.showFacilityTextField = !element.showFacilityTextField">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                    <span style="cursor:pointer;" *ngIf="element.showFacilityTextField" class="material-icons" (click)="updateExternalFacilityId(element)">save</span>
            </div>
        </td>
        </ng-container>

        <ng-container matColumnDef="operations">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element ; let i = index;">
                <i class="icon icon-delete" matTooltip="Delete" matTooltipPosition="above" (click)="onDeleteClick(element)"></i>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

</div>