import { Component, Inject } from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";


// UPLOAD FILE DIALOG
export interface UploadFileDialogData {
    parent_id:string,
    file:any
  }
  @Component({
    selector: 'upload-file-dialog',
    templateUrl: './upload-file-dialog.html',
    styleUrls: ['./drive.component.css']
  })
  export class UploadFileDialog {
    emptyError:boolean=false;
    constructor(
      public dialogRef: MatDialogRef<UploadFileDialog>,
      @Inject(MAT_DIALOG_DATA) public data: UploadFileDialogData) { 
        
      }

      onSelectFile(e){
        console.log('fileee', e);
        
      }
  }
