import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-visit-status-dialog',
  templateUrl: './visit-status-dialog.component.html',
  styleUrls: ['./visit-status-dialog.component.css']
})
export class VisitStatusDialogComponent implements OnInit {
  visitStatusData:any = {
    color : '#ff0000',
  }
  
  colorPickerOpen: boolean = true;
   constructor(
     public dialogRef: MatDialogRef<VisitStatusDialogComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any
   ) {}
   ngOnInit(): void {
     if(this.data){
     this.visitStatusData = this.data;
     }
   }
 
   onSaveClick() {
     if(this.visitStatusData.name && this.visitStatusData.color){
       console.log(this.visitStatusData)
       const data = {
         action :  this.visitStatusData._id ? 'update' : 'add',
         visitStatus: this.visitStatusData
       }
       this.dialogRef.close(data);
     }
   }
 
   onCancelClick() {
     // Close the dialog without saving
     this.dialogRef.close();
   }
   onColorChange(color: string) {
   }
 
   closeColorPicker() {
     this.colorPickerOpen = false;
   }

}
