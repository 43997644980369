<ng-container *ngIf="!shouldShowDataOptions">
    <div [ngClass]="{'container': (companySide || isUserDashboard) && !(nursingSide)}">
        <h3 class="dark-blue-heading flex justify-between items-center">
            <ng-container *ngIf="!isUserDashboard && !nursingSide">
                <mat-icon style="cursor: pointer;" matTooltip="Go Back" matTooltipPosition="above"
                    (click)="goBack();">keyboard_backspace</mat-icon>
            </ng-container>

            <div [ngClass]="{'label-margin': !isUserDashboard}">
                <label style="white-space: nowrap;">
                    Custom Wound Report
                </label>
            </div>
        </h3>

        <div>
            <div class="top-block justify-between items-center">

                <div class="flex justify-start items-center gap-1">
                    <mat-form-field appearance="fill">
                        <mat-label>Search Report</mat-label>
                        <input matInput aria-label="Search Report"
                        (input)="searchReports($event.target.value)" type="text" name="" />
                    </mat-form-field>
            
                    <mat-form-field appearance="fill">
                        <mat-label>Select Reports</mat-label>
                        <mat-select value="all" (selectionChange)="filterReports($event.value)">
                            <mat-option value="all">All</mat-option>
                            <mat-option value="favourite">Favorites</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            
                <button mat-raised-button class="dn-button-primary data-options-button"
                    (click)="shouldShowDataOptions=true;"> Data options </button>
            </div>
            
            <mat-card class="vertical-height p-4">
                <div class="table-height">

                    <table class="table-row-hover mat-mdc-table mat-sort">
                        <thead>
                            <tr class="mat-mdc-header-row">
                                <th class="mat-mdc-header-cell favorite"></th>
                                <th class="mat-mdc-header-cell text-left">Reports</th>
                                <th class="mat-mdc-header-cell actions">Actions</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="customWoundReports.length > 0">
                            <tr class="mat-mdc-row" *ngFor="let customWoundReport of customWoundReports; let index = index">
                                <td class="mat-mdc-cell">
                                    <span class="material-icons" style="cursor: pointer"
                                        [matTooltip]="customWoundReport.is_favourite ? 'Remove From Favourites' : 'Add To Favourites'"
                                        matTooltipPosition="above"
                                        (click)="updateCustomWoundReport(index, customWoundReport._id, !customWoundReport.is_favourite);">
                                        {{customWoundReport.is_favourite ? 'star' : 'star_outline' }} </span>
                                </td>
                                <td class="mat-mdc-cell"> {{customWoundReport.title}} </td>
                                <td class="mat-mdc-cell actions">
                                    <span class="material-symbols-outlined" matTooltip="Generate report"
                                        matTooltipPosition="above" (click)="showDataOptions(customWoundReport);">
                                        export_notes
                                    </span>
                                    <span class="material-symbols-outlined" matTooltip="Delete Report"
                                        matTooltipPosition="above"
                                        (click)="openDeleteDialog(index, customWoundReport._id, customWoundReport.is_favourite, true);">
                                        delete
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </mat-card>
        </div>
        <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
    </div>
</ng-container>

<app-data-option *ngIf="shouldShowDataOptions" [dataOption]="dataOption" [shouldShowDataOptions]="shouldShowDataOptions"
    (updateData)="updateData($event);" (shouldShowSavedReports)="showSavedReports(!$event);"></app-data-option>