<div class="phrases-wrapper flex flex-col gap-2">
    <div class="flex" style="padding-right: 6px;">
        <input matInput type="text" style="margin:3px 10px 7px 3px; padding-left:7px; border-radius:12px; border: groove;height: 35px;width: 100%;" 
            placeholder="Search User Defined Phrases" [(ngModel)]="searchText" (keyup)="applyFilter()">
            <div class="flex" >
            <button *ngIf="loggedInUser.user_type !== global.USER_TYPE.BILLER" style="margin-left:auto;margin-right: 2px;" mat-raised-button class="dn-button-primary"
                (click)="showDictionaryEditorDialog()">Add</button>
        </div>
    </div>
    <div *ngFor="let dictionaryElement of dataSource?.filteredData; let i = index" class="flex items-start justify-baseline gap-2">
        <div class="flex flex-grow items-start justify-center">
            <div class="m-auto" style="color:rgb(104, 104, 104); font-size: 12px;">{{i+1}}</div>

            <mat-form-field class="flex-grow">
                <mat-label>Shortcut</mat-label>
                <input matInput type="text" [value]="dictionaryElement ? getMarker() + dictionaryElement.key : ''"
                    readonly>
            </mat-form-field>
        </div>
        <div class="flex items-start justify-center" *ngIf="isOwner(dictionaryElement)">
            <button mat-button>
                <i class="icon icon-view" matTooltip="View Phrase" matTooltipPosition="above"
                    (click)="showDictionaryEditorDialog(dictionaryElement)"></i>
            </button>
            <button mat-button (click)="deleteFromNoteBuilderDictionary(dictionaryElement)">X</button>
        </div>
    </div>
    <div *ngIf="isNew" class="flex items-start justify-baseline">
        <mat-form-field class="w-1/4">
            <mat-label>Shortcut</mat-label>
            <input matInput type="text" [(ngModel)]="newDictionaryElement.key" required>
        </mat-form-field>
        <mat-form-field class="flex-grow">
            <mat-label>Value</mat-label>
            <input matInput type="text" [(ngModel)]="newDictionaryElement.value" required>
        </mat-form-field>
        <button mat-button (click)="saveDictionaryElement(newDictionaryElement)">Save</button>
    </div>
  <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

</div>