import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { FacilityService } from 'src/app/services/facility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map, startWith } from 'rxjs/operators';
import { WoundService } from 'src/app/services/wound.service';
import { ToastrService } from 'ngx-toastr';
import { HttpResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';

@Component({
  selector: 'app-uwh-type-tabular-report',
  templateUrl: './uwh-type-tabular-report.component.html',
  styleUrls: ['./uwh-type-tabular-report.component.css']
})
export class UwhTypeTabularReportComponent implements OnInit {
  currentDate = moment(new Date());
  filter: any = {
    creationDate: {
      startDate: moment().subtract(6, 'days').toDate(),
      endDate: moment().toDate()
    },
    facilityId: null,
    companyId: null
  };
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 1 Week': [moment().subtract(6, 'days'), moment()],
    'Last 2 Week': [moment().subtract(13, 'days'), moment()],
    'Last 3 Week': [moment().subtract(20, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  iscreationDateFilterSelected: boolean = true;
  filteredFacilities: Observable<string[]>;
  displayedColumns: string[] = [
    'name', 'created_date', 'wound_num', 'location', 'wound_type', 'stage', 'size', 'status', 'weeks'];
  facilityControl = new FormControl();
  facilities: Array<any> = [];
  companyId: any;
  woundUWHReport: any = [];
  dataSource: any = new MatTableDataSource([]);
  loaderId = 'app-uwh-type-tabular-report';
  currentUser: any;
  companySide: boolean;
  nursingSide: boolean;
  user_dashboard: Boolean = false;
  constructor(
    private router: Router,
    private _toastr: ToastrService,
    private _location: Location,
    private _facilityService: FacilityService,
    private _route: ActivatedRoute,
    private _wound: WoundService,
    private loader: NgxUiLoaderService,
    private _authService: AuthService

  ) {
    this.filter.companyId = this._route.parent.snapshot.params.id;
    this.currentUser = this._authService.currentUser;
    
    if(this._route.parent.snapshot.params.id){
      this.companySide=true;
    }else{
      this.filter.companyId = this.currentUser.company_id;
    }
    if(window.location.pathname.includes('dashboard')){
      this.user_dashboard = true;
    }
    if(window.location.pathname.includes('nursing')){
      this.nursingSide=true;
    }
  }

  async ngOnInit(): Promise<void> {
    this.loader.startLoader(this.loaderId);
    await this.initFacilities();
    this.selectDefaultFacility();
    this.initUWHTabularReport();
  }
  private filterFacilities(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) !== -1);
    }
    else {
      return this.facilities;
    }
  }
  async initFacilities() {
    const filter = {
      assoc_company_ids: this.filter.companyId,
      is_active: true
    };
    const projection = {
      title: 1,
      source: 1,
      assoc_company_ids: 1
    };

    let companyAssocPopulation: facilityPopulationInterface = {
      collection: 'companies',
      field: ["assoc_company_ids"],
      filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: [true, { $in: ['$_id', '$$assoc_company_ids'] }] }] } },
      project: { name: 1 },
    };
    
    const facilitiesResponse: any = await this._facilityService.getFacilities(filter, projection, [companyAssocPopulation]).toPromise();
    if (facilitiesResponse.status == 200) {
      this.facilities = facilitiesResponse.data.array;
      if (this.facilities.length > 0) {
        this.showFacilityDropdown();
      }
    }
  }
  selectDefaultFacility() {
    if (this.facilities.length > 0) {
      this.filter.facilityId = this.facilities[0]._id;
    }
    this.facilityControl.setValue(this.facilities[0].title);
  }
  onSelectFacility($event, facility) {
    if ($event.isUserInput) {
      this.filter.facilityId = facility;
    }
  }
  changeCreationDate(event) {
    if (event.startDate && event.endDate) {
      this.filter.creationDate.startDate = moment(event.startDate).toDate();
      this.filter.creationDate.endDate = moment(event.endDate).toDate();
    }
  }
  initUWHTabularReport() {
    this.loader.startLoader(this.loaderId);
    this._wound.getUWHTabularReport(this.filter).subscribe((response: any) => {
      if (response.status == 200) {
        this.woundUWHReport = response.data;
        this.dataSource.data = this.woundUWHReport;
        this.loader.stopLoader(this.loaderId);
      }
    }, err => {
      this.loader.stopLoader(this.loaderId);
      this._toastr.error(err, "ERROR");
    }
    );
  }
  handleApplyFilter() {
    this.initUWHTabularReport();
  }
  getWoundStatus(state) {
    if (state == 'remission') return "Resolved";
    return "Not Healed";
  }
  getLocation(body_part, body_side) {
    let part = body_part;
    let side = body_side
    if (body_part == null && body_side == null) {
      return "";
    }
    if (body_part == null) {
      part = "";
    }
    if (body_side == null) {
      side = "";
    }
    return part + ", " + side;
  }
  getsize(length, width, depth) {
    let l = length;
    let w = width;
    let d = depth;
    if (l == "") {
      l = "0";
    }
    if (w == "") {
      w = "0";
    }
    if (d == "") {
      d = "0";
    }
    return l + ", " + w + ", " + d;
  }
  dateDifference(createdDate) {
    if (createdDate) {
      const dateDifference = Math.abs(new Date().getTime() - new Date(createdDate).getTime());
      const daysDifference = dateDifference / (1000 * 60 * 60 * 24);
      const weeksInTx = Math.round(daysDifference / 7);
      return weeksInTx;
    }
  }
  showFacilityDropdown(): void {
    this.filteredFacilities = this.facilityControl.valueChanges.pipe(
      startWith(''),
      map((value:any) => this.filterFacilities(value)));
  }
  resetFilter() {
    delete this.filter.creationDate;
    const defaultDate = {
      startDate: moment().subtract(6, 'days').toDate(),
      endDate: moment().toDate()
    };
    this.filter.creationDate = defaultDate;
    this.selectDefaultFacility();
    this.initUWHTabularReport();
  }
  exportReportAsXLSX() {
    this._wound.exportWounUWHTabularReportAsXLSX(this.dataSource.data).subscribe((response: any) => {
      if (response instanceof HttpResponse) {
        var url = window.URL.createObjectURL(response.body);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `Reports`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }
    });
  }
  goBack() {
    this._location.back();
  }
}
