import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-notes-widget',
  templateUrl: './notes-widget.component.html',
  styleUrls: ['./notes-widget.component.css']
})
export class NotesWidgetComponent implements OnInit {

  @Input() note:any;
  @Input() patient:any;
  constructor() { }
  
  ngOnInit(): void {
  }

}
