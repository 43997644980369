import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminNotesService } from './admin-notes.service';
import { TranscriptionCreateService } from '../transcription-create/transcription-create.service';
import { DashboardService } from '../dashboard/dashboard.service';
import moment from 'moment';
import * as global from '../global';
import { CommonService } from 'src/app/services/common.service';
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AdminDictationsService } from '../admin-dictations/admin-dictations.service';
import { FormControl } from '@angular/forms';
import { Observable, lastValueFrom } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { API_URL } from "../../../environments/api-url";
import { AuthService } from "../../services/auth.service";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { NoteEditorService } from "../note-editor/note-editor.service";
import { NoteAuditoryHistoryService } from "../note-auditory-history/note-auditory-history.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ViewNoteDialog } from '../transcription-create/view-note-dialog/view-note-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmationComponent } from '../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FacilityService } from 'src/app/services/facility.service';
import { UserService } from 'src/app/services/Modules/user.service';
import { SnfWcNurseAssociationService } from '../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { Buffer } from 'buffer';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { WoundCareWidgetService } from '../note-sidebar/wound-care-widget/wound-care-widget.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { ICompanyPermissionResponse } from 'src/app/classes/company';

interface IFilter {
    active: boolean,
    creation_date: {
        startDate: any | Date | moment.Moment,
        endDate: any | Date | moment.Moment
    },
    date_of_service: {
        startDate: any | Date | moment.Moment,
        endDate: any | Date | moment.Moment
    },
    user: string,
    facility: string,
    patient: string,
    progress_status: string,
    isExportNotes: boolean
}
interface BReport {
    dos: string,
    provider: any[]
}
@Component({
    selector: 'app-admin-notes',
    templateUrl: './admin-notes.component.html',
    styleUrls: ['./admin-notes.component.css'],
    animations: [
        trigger('accordionTrigger', [
            state('collapsed', style({ height: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class AdminNotesComponent implements OnInit {
    filteredFacilities: Observable<string[]>;
    facilityControl = new FormControl();
    filteredProvider: Observable<string[]>;
    providerControl = new FormControl();
    expandedElementId: string = "";

    global = global;
    public isVisibleTable: boolean = true;
    public dataSource: any;
    public sortedData: Array<any>;
    public notes: Array<any>;
    public group_notes: Array<any>;
    public notesGroup;
    public sortNotesGroup;
    public c_user;
    public c_date = moment(new Date());
    public creationDateFilter: Boolean = true;
    public serviceDateFilter: Boolean = true;
    displayedColumns: string[] = ['date_of_service', "Expansion Icon", 'patient_name', 'facility_title', 'doctor_name', 'title', 'uploader_name', 'progress_status', 'stackholders', 'details'];

    @ViewChild(MatSort) sort: MatSort;

    provideModel: any = '';
    facilityMOdel: any = "";
    isAdminSide: boolean = false;
    company_id: any;
    filter: IFilter;
    appliedFilter: IFilter;
    users: Array<any> = [];
    providers: Array<any> = [];
    note_types: Array<any> = [];
    facilities: Array<any> = [];
    patients: Array<any> = [];
    uniqueProviders: any = [];
    status = [
        { title: 'Unsigned' },
        { title: 'Signed' },
        { title: 'Uploaded' }
    ];

    preDateRanges: any = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };

    currentDate = new Date();
    notesToShow = null;
    currentRouteUrl: any;
    currentUser: any;
    noteStatus: string = 'default';
    showPccLogo: boolean = false;
    currentCompany: any = {};
    isButtonDisable = true;
    selectedUser: any;
    expandedRows: any = [];
    tabIndex: number = 0;
    shouldShowEncounter : boolean = false;
    constructor(
        private _adminNotesService: AdminNotesService,
        private _transcriptionCreateService: TranscriptionCreateService,
        private _dashboardService: DashboardService,
        private _commonService: CommonService,
        private _dictationService: AdminDictationsService,
        private _authService: AuthService,
        private toastr: ToastrService,
        private loader: NgxUiLoaderService,
        private _router: Router,
        private httpclient: HttpClient,
        private noteEditorService: NoteEditorService,
        private auditoryService: NoteAuditoryHistoryService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private facilityService: FacilityService,
        private commonService: CommonService,
        private userService: UserService,
        private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
        private _woundCareWidgetService: WoundCareWidgetService,
        private companyService: CompanyService


    ) {
        this.currentUser = this._authService.currentUser;
        this.route.paramMap.subscribe((params: ParamMap) => {
            // this.resetTranscriptionMultiSelect.emit();
            this.currentRouteUrl = this._router.url.includes(';') ? this._router.url.split(';')[0] : this._router.url;

        });

    }

    async ngOnInit() {
        this._woundCareWidgetService.setIsTeleHealthReport(true);
        const currentCompany = this.commonService.getLocalStorageItem('admin-Dashboard');
        if (currentCompany) {
            this.currentCompany = currentCompany;
        }
        if (window.location.pathname.includes('company')) {
            this.isAdminSide = true;
        }
        if (window.location.pathname.includes('company') && !window.location.pathname.includes('manage-companies')) {
            this.company_id = this.route.parent.parent.snapshot.params.id;
        }
        else if (window.location.pathname.includes('manage-companies')) {
            this.company_id = this.route.parent.parent.snapshot.params.id;
        }
        else if (this._authService.currentUser.user_type === global.USER_TYPE.BILLER) {
            this.company_id = this._authService.currentUser.company_id;
        }
        this.filter = this.filterInitialState();

        const isDOS = JSON.parse(localStorage.getItem('admin-notes:check_filter_dos'));
        if (isDOS) {
            this.filter.date_of_service = {
                startDate: moment(moment(isDOS.startDate).toLocaleString()),
                endDate: moment(moment(isDOS.endDate).toLocaleString()),
            };
        }

        const isProvider = JSON.parse(localStorage.getItem('admin-notes:provider_id_name'));
        if (isProvider) {
            this.provideModel = isProvider.name
            this.filter.user = isProvider.id
        }

        const isFacility = JSON.parse(localStorage.getItem('admin-notes:facility_id_title'));
        if (isFacility) {
            this.facilityMOdel = isFacility.title
            this.filter.facility = isFacility.id
        }

        const isStatus = JSON.parse(localStorage.getItem("admin-notes:select_status"))
        if (isStatus) {
            this.filter.progress_status = isStatus.status
            this.noteStatus = isStatus.value
        }

        this.appliedFilter = { ...this.filter };
        this.initNotes();
        // this.initUsers();
        this.initProviders();
        this.initCompanyFacilities();
        await this.getCompanyPermissions({ _id: this.currentCompany._id }, { should_show_encounter: 1 });
        // this.initFacilities();
        this.filteredProvider = this.providerControl.valueChanges.pipe(
            startWith(''),
            map((value: any) => this.filterProvider(value)));

        this.filteredFacilities = this.facilityControl.valueChanges.pipe(
            startWith(''),
            map((value: any) => this.filterFacilities(value)));

        if (this.currentUser.user_type === global.USER_TYPE.BILLER) {
            this.facilityMOdel = 'All';
        }
        
        if (this.shouldShowEncounter) {
            this.displayedColumns = [...this.displayedColumns.slice(0, -2), 'encounters', ...this.displayedColumns.slice(-2)];
        }
    }

    ngOnDestroy(): void {
        this._woundCareWidgetService.setIsTeleHealthReport(false);
        if (window.location.pathname.includes('note-editor')) {
            return;
        }
        this.removeFiltersFromStorage()
    }

    removeFiltersFromStorage() {
        localStorage.removeItem("admin-notes:check_filter_dos");
        localStorage.removeItem("admin-notes:provider_id_name");
        localStorage.removeItem("admin-notes:facility_id_title");
        localStorage.removeItem("admin-notes:select_status");
    }

    isPCCFacility(facility_id) {
        for (const facility of this.facilities) {
            if (facility_id === facility._id) {
                if (facility.pcc_facId) {
                    return true;
                }
            }
        }
    }
    private filterFacilities(value: string): string[] {
        if (value) {
            const filterValue = value.toLowerCase();
            if (value.length == 1) {
                this.showPccLogo = false;
            }
            return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) === 0);
        }
        else {
            return this.facilities;
        }
    }

    private filterProvider(value: string): string[] {
        if (value) {
            const filterValue = value.toLowerCase();
            let users = this.uniqueProviders.filter(option => option.name.toLowerCase().indexOf(filterValue) > -1);

            return this.sortFirstNameVise(users);
        }
        else {
            return this.sortFirstNameVise(this.uniqueProviders);
        }


    }
    sortFirstNameVise(arrayTosort) {
        let users = arrayTosort;
        let result = users.sort(function (a, b) {
            if (a.first_name?.toLowerCase() < b.first_name?.toLowerCase()) { return -1; }
            if (a.first_name?.toLowerCase() > b.first_name?.toLowerCase()) { return 1; }
            return 0;
        })
        return result;
    }


    initNotes() {
        // var cloned = JSON.parse(JSON.stringify(this.filter));
        this._adminNotesService.getNotes(null, this.filter, this.company_id).subscribe((response: any) => {
            if (response.status == 200) {
                this.notes = response.data;
                this.sortedData = this.notes;
                this.notesGroup = this.sortNotesByCreationDate(this.sortedData);
                // this.notesGroup = this.sortedData;//this.sortNotesByCreationDate(this.sortedData);
                this.dataSource = new MatTableDataSource(this.notesGroup);
                // this.resetFilter();
            }
        });
    }

    sortNotesByCreationDate(notes) {
        var byDate = notes.slice(0);
        byDate.sort(function (a, b) {
            // return Date.parse(a.sort_date)  < Date.parse(b.sort_date)  ? 1 : Date.parse(a.sort_date)  > Date.parse(b.sort_date)  ? -1 : 0;
            return Date.parse(a._id) < Date.parse(b._id) ? 1 : Date.parse(a._id) > Date.parse(b._id) ? -1 : 0;

        });
        return byDate;
    }
    getNotesDOS(note) {
        if (note && (note.date_obj || note.date_obj_str)) {
            let datee = note.date_obj_str ? note.date_obj_str : note.date_obj.year.toString() + '-' + note.date_obj.month.toString() + '-' + note.date_obj.day.toString();
            return moment(new Date(datee)).format('MMM DD, YYYY');
        }
        else if (note && note.filterServiceDate) {
            return moment(new Date(note.filterServiceDate).toLocaleString('en-US', { timeZone: note.timezone })).format('MMM DD, YYYY h:mm a');
        }
        else
            return ''
    }
    getNoteDOS(note) {
        if (note && (note.date_obj || note.date_obj_str)) {
            let datee = note.date_obj_str ? note.date_obj_str : note.date_obj.year.toString() + '-' + note.date_obj.month.toString() + '-' + note.date_obj.day.toString();
            return moment(new Date(datee)).format('MMM DD, YYYY');
        }
        else if (note && note.filterServiceDate) {
            return moment(new Date(note.filterServiceDate).toLocaleString('en-US', { timeZone: note.timezone })).format('MMM DD, YYYY');
        }
        else
            return ''
    }
    getNoteCreatedDate(note) {
        if (note && note.createdAt) {
            return moment(new Date(note.createdAt).toLocaleString('en-US', { timeZone: note.timezone })).format('MMM DD, YYYY');
        }
        else
            return ''
    }
    getNoteupdatedDate(note) {
        if (note && note.updatedAt) {
            return moment(new Date(note.updatedAt).toLocaleString('en-US', { timeZone: note.timezone })).format('MMM DD, YYYY');
        }
        else
            return ''
    }
    getServiceDate(note) {
        //
        //note.filterServiceDate
        if (note && note.DOS) {
            return moment(new Date(note.DOS).toLocaleString('en-US', { timeZone: note.timezone })).format('MMM DD, YYYY h:mm a');
        }
        else {
            return ''
        }
        // if(note && note.audio_id_ref){
        //     // return moment(new Date(note.audio_id_ref.filterServiceDate).toLocaleString('en-US', { timeZone:  note.audio_id_ref.timezone })).format('MMM DD, YYYY h:mm a');
        //     return moment(new Date(note.audio_id_ref.filterServiceDate)).format('MMM DD, YYYY h:mm a');
        // }
        // else{
        //     // return moment(new Date(note.filterServiceDate).toLocaleString('en-US', { timeZone:  note.timezone })).format('MMM DD, YYYY h:mm a');
        //     return moment(new Date(note.filterServiceDate)).format('MMM DD, YYYY h:mm a');
        // }
    }
    resetFilter() {
        this.showPccLogo = false;
        this.facilityMOdel = '';
        this.provideModel = '';
        this.noteStatus = 'default';
        this.removeFiltersFromStorage();
        this.filter = this.filterInitialState();
        this.appliedFilter = { ...this.filter };
        this.filter.date_of_service = {
            startDate: moment().subtract(7, 'days'),
            endDate: moment(),
        };

        this.applyFilter();
        this.initCompanyFacilities();
        this.setIsButttonDisable();
    }
    clearProviderFilter() {
        this.provideModel = '';
        this.filter.user = '';
        this.initCompanyFacilities();
        this.setIsButttonDisable();
    }
    clearFacilityFilter() {
        this.facilityMOdel = '';
        this.filter.facility = '';
        this.showPccLogo = false;
        this.setIsButttonDisable();
    }


    async initProviders() {
        if (this.currentCompany.currentCompanyType === global.COMPANY_TYPE.SNF) {
            const filter = {
                shouldFetchAll: true,
                user_type: global.USER_TYPE.SNF_WC_NURSE,
                company_id: this.company_id
            }
            const projection = {
                'account_type': 1,
                'first_name': 1,
                'last_name': 1,
                'title': 1,
                full_name: 1,
                is_activated: 1
            }
            const usersResponse: any = await lastValueFrom(this.userService.getUsers(filter, projection));
            if (usersResponse.status == 200) {
                this.users = usersResponse.data.map(u => {
                    u.name = `${u.first_name} ${u.last_name}`;
                    if (u.user_type == '1') {
                        u.name += `, ${u.title}`
                    }
                    return u;
                });
                const map = new Map();
                for (const item of this.users) {
                    if (!map.has(item._id) && item.first_name) {
                        map.set(item._id, true);    // set any value to Map
                        this.uniqueProviders.push(item);
                    }
                }
                this.uniqueProviders = this.sortFirstNameVise(this.uniqueProviders);
            }
        } else {
            const filter = {
                shouldFetchAll: true,
                company_id: this.company_id,
                user_type: global.USER_TYPE.DOCTOR,
            }
            const projection = {
                'first_name': 1,
                'last_name': 1,
                'title': 1,
                'full_name': 1,
                'user_type':1,
                is_activated: 1
            }
            this.userService.getUsers(filter, projection).subscribe((response: any) => {
                if (response.status == 200) {
                    // this.users = [...response.data];
                    this.users = response.data.map(u => {
                        u.name = `${u.first_name} ${u.last_name}`;
                        if (u.user_type == '1') {
                            u.name += `, ${u.title}`
                        }
                        return u;
                    });

                    const map = new Map();
                    for (const item of this.users) {
                        if (!map.has(item._id) && item.first_name) {
                            map.set(item._id, true);    // set any value to Map
                            this.uniqueProviders.push(item);
                        }
                    }
                    this.uniqueProviders = this.sortFirstNameVise(this.uniqueProviders);

                    // this.uniqueProviders.sort(this.sortProviders)
                }
            });
        }
    }
    sortProviders(a, b) {
        if (a.first_name < b.last_name) {
            return -1;
        }
        if (a.first_name > b.last_name) {
            return 1;
        }
        return 0;
    }
    onSelectProvider(item = null) {
        if (item) {
            this.selectedUser = item;
            this.provideModel = item.name;
            this.facilityMOdel = '';
            this.filter.facility = '';
            this.filter.user = item._id;
            this.initFacilities(item);
            const setProvider = {
                id: item._id,
                name: item.name
            }
            localStorage.setItem('admin-notes:provider_id_name', JSON.stringify(setProvider));
        }
        else {
            this.filter.user = null;
            this.provideModel = null;
            this.initFacilities();
        }
        this.setIsButttonDisable();
    }
    onSelectFacility(item = null) {
        if (item) {
            this.facilityMOdel = item.title;
            this.filter.facility = item._id;
            const setFacility = {
                id: item._id,
                title: item.title
            }
            localStorage.setItem('admin-notes:facility_id_title', JSON.stringify(setFacility));
            if (item.pcc_facId || item.source === 'PointClickCare') {
                this.showPccLogo = true;
            } else {
                this.showPccLogo = false;
            }
        }
        else {
            if (this.currentUser.user_type === global.USER_TYPE.BILLER) {
                this.facilityMOdel = 'All';
                this.filter.facility = null;
            } else {
                this.facilityMOdel = '';
                this.filter.facility = null;
            }
        }
    }
    getNoteById(note) {
        console.log("Note:", note)
        const noteData = this.noteEditorService.getNoteById(note.noteId).subscribe((response: any) => {
            if (response.status == 200) {
                console.log("response: ", response)
            }
            else if (response.status === 403) {
                // this.toastr.error(response.message, 'ERROR');
                this._router.navigate(['/403']);
            }

        })
    }
    getCurrentUser(user) {
        this.c_user = this._authService.currentUser.first_name + ' ' + this._authService.currentUser.last_name;
        if (this.c_user === user)
            return true;
        else
            return false;
    }
    getProvider(provider) {
        //console.log("Provider: "+provider.doctor_id_ref.first_name+' '+provider.doctor_id_ref.last_name);
        return provider.doctor_id_ref.first_name + ' ' + provider.doctor_id_ref.last_name;
    }
    resetProvider(provider) {
        console.log(provider);
    }
    // initUsers() {
    //     this._adminNotesService.getTranscribersAndProviders().subscribe((response: any) => {
    //         if (response.status == 200) {
    //             this.users = response.data.map(u => {
    //                 u.name = `${u.first_name} ${u.last_name}`;
    //                 if (u && u.user_type == '1') {
    //                     u.name += ` ${u.title}`;
    //                 }
    //                 return u;
    //             });

    async initFacilities(provider = null) {
        if (this.currentCompany.currentCompanyType === global.COMPANY_TYPE.SNF) {
            const filter = {
                associated_snf_wc_nurse_ids: provider._id,
            };
            const nurseProjection = {
                first_name: 1,
                last_name: 1,
                title: 1,
            };
            const facilityProjection = {
                title: 1,
                pcc_facId: 1,
                pcc_2_legged_authentication: 1,
                source: 1,
                pcc_orgUuid: 1
            };
            const facilitiesResponse: any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
            if (facilitiesResponse.status == 200) {
                this.facilities = facilitiesResponse.data;
                this.filteredFacilities = this.facilityControl.valueChanges.pipe(
                    startWith(''),
                    map((value: any) => this.filterFacilities(value))
                );
            }
        } else {
            let response: any = {};
            if (provider) {
                response = await this._transcriptionCreateService.getFacilities(provider._id).toPromise();
            }
            if (response.status === 200) {
                this.facilities = response.data.array;
                this.filteredFacilities = this.facilityControl.valueChanges.pipe(
                    startWith(''),
                    map((value: any) => this.filterFacilities(value))
                );
            }
        }
    }
    // if (response.status === 200) {
    //     this.facilities = response.data.array;
    //     this.filteredFacilities = this.facilityControl.valueChanges.pipe(
    //         startWith(''),
    //         map((value:any) => this.filterFacilities(value))
    //     );
    // }

    /*if (user) {
        //&& user != null
        response = await this._transcriptionCreateService.getFacilities(user._id).toPromise();
    } else {
        //response = await this._transcriptionCreateService.getFacilities().toPromise(); //by me
        // response = await this._dashboardService.getAssociatedFacilityOfTranscriber(user._id).toPromise();
    }
    if (response.status === 200) {
        this.facilities = response.data.array;
        this.filteredFacilities = this.facilityControl.valueChanges.pipe(
            startWith(''),
            map((value:any) => this.filterFacilities(value))
            );
    }*/
    // this.loading = false;
    //}

    // async initFacilities(provider = null) {
    //     // this.loading = true;
    //     const user = this.uniqueProviders.find(u => u._id === this.filter.user);

    //     let response: any = {};
    //     if (user.user_type == global.USER_TYPE.DOCTOR) {
    //         response = await this._transcriptionCreateService.getFacilities(user._id).toPromise();
    //     } else {
    //         response = await this._dashboardService.getAssociatedFacilityOfTranscriber(user._id).toPromise();
    //     }
    //     if (response.status === 200) {
    //         console.log("facilities: ", response.data.array);
    //         this.facilities = response.data.array;
    //     }
    //     // this.loading = false;
    // }

    filterInitialState(): IFilter {
        // return {
        //     active: false,
        //     date_of_service: null,
        //     creation_date: {
        //         startDate: moment().subtract(7, 'days'),
        //         endDate: moment(),
        //     },
        //     user: '',
        //     facility: '',
        //     patient: '',
        //     progress_status: ''
        // };


        return {
            active: false,
            date_of_service: {
                startDate: moment().subtract(2, 'days'),
                endDate: moment(),
            },
            creation_date: null,
            user: '',
            facility: '',
            patient: '',
            progress_status: '',
            isExportNotes: false
        };

    }


    handleApplyFilterClick() {
        this.applyFilter();
        this.appliedFilter = { ...this.filter };
    }

    applyFilter() {
        this.initNotes();
        // this.sortedData = this.notes;
        //this.notesGroup = this.groupNotesByCreationDate(this.sortedData);
        return;
        this.sortedData = this.notes.filter(note => {
            if (this.filter.date_of_service && (this.filter.date_of_service.startDate && this.filter.date_of_service.endDate)) {
                const date = moment(new Date(note.date_of_service)).startOf('day');
                const startDate = moment(this.filter.date_of_service.startDate).startOf('day');
                const endDate = moment(this.filter.date_of_service.endDate).startOf('day');
                const dateResult = date.isBetween(startDate, endDate) || date.isSame(startDate) || date.isSame(endDate);

                return dateResult;
            }
            return true;
        }).filter(note => {
            if (this.filter.creation_date && (this.filter.creation_date.startDate && this.filter.creation_date.endDate)) {
                const date = moment(new Date(note.creation_date)).startOf('day');
                const startDate = moment(this.filter.creation_date.startDate).startOf('day');
                const endDate = moment(this.filter.creation_date.endDate).startOf('day');

                const dateResult = date.isBetween(startDate, endDate) || date.isSame(startDate) || date.isSame(endDate);

                return dateResult;
            }
            return true;
        }).filter(note => {
            if (this.filter.user) {
                return note.uploaded_by_id == this.filter.user;
            }
            return true;
        }).filter(note => {
            if (this.filter.facility) {
                return note.facility_id == this.filter.facility;
            }
            return true;
        }).filter(note => {
            switch (this.filter.progress_status) {
                case 'Uploaded':
                    return note.is_completed === 'true' && note.is_signed === 'true' && note.is_active === 'true';
                case 'Signed':
                    return note.is_signed === 'true' && note.is_completed !== 'true' && note.is_active === 'true';
                case 'Unsigned':
                    return note.is_signed !== 'true' && note.is_completed !== 'true' && note.is_active === 'true' && note.is_published === 'true';
                case 'Unpublished':
                    return note.is_signed !== 'true' && note.is_completed !== 'true' && note.is_active === 'true' && note.is_published !== 'true' || null;
                default:
                    return true;
            }
        });
        //this.notesGroup = this.groupNotesByCreationDate(this.sortedData);
        // this.dataSource = new MatTableDataSource(this.sortedData);
    }

    sortData(sort: Sort, notesToShow) {
        const data = notesToShow.data.slice();
        if (!sort.active || sort.direction === '') {
            // notesToShow = data;
            // this.filter = this.filterInitialState();
            return;
        }

        notesToShow = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'title':
                    return compare(a.title, b.title, isAsc);
                case 'uploader_name':
                    return compare(a.uploader_name, b.uploader_name, isAsc);
                case 'date_of_time':
                    return compare(new Date(a.date_of_time).getTime(), new Date(b.date_of_time).getTime(), isAsc);
                case 'facility_title':
                    return compare(a.facility_title, b.facility_title, isAsc);
                case 'progress_status':
                    return compare(a.progress_status, b.progress_status, isAsc);
                case 'patient_name':
                    console.log("a.patient_id_ref?.first_name : ", a.patient_id_ref?.first_name);
                    return compare(a.patient_id_ref?.first_name, b.patient_id_ref?.first_name, isAsc);
                default:
                    return 0;
            }
        });
        this.notesToShow.data = notesToShow;
    }
    getDate(date) {
        return moment(new Date(date)).format('MMM DD, YYYY');
    }
    getStatus(status) {
        return this.auditoryService.formatOperation(status);
    }


    filterData() {
        const date_of_service = (this.appliedFilter.date_of_service && (this._commonService.dateRangeToString(this.appliedFilter.date_of_service.startDate, this.appliedFilter.date_of_service.endDate)) || '-');
        const date_of_submission = this.appliedFilter.creation_date && (this._commonService.dateRangeToString(moment(this.appliedFilter.creation_date.startDate), moment(this.appliedFilter.creation_date.endDate)) || '-');
        const user = this.appliedFilter.user ? this._commonService.getUserFullName(this.users.find(p => p._id === this.appliedFilter.user)) : '-';
        const facility = this.appliedFilter.facility ? this._commonService.getFacilityTitle(this.facilities.find(f => f._id === this.appliedFilter.facility)) : '-';
        const progress_status = this.appliedFilter.progress_status ? this.appliedFilter.progress_status : '-';
        //const total = this.notes ? this.notes.length : 0;
        var i = 0, j = 0;
        var total1 = 0;
        for (i = 0; i < this.notes?.length; i++)
            for (j = 0; j < this.notes[i].groupedData.length; j++)
                total1 = total1 + this.notes[i].groupedData[j].notedata.total;
        return [
            { label: 'Date of Service', value: date_of_service },
            { label: 'Creation Date', value: date_of_submission },
            { label: this.currentCompany.currentCompanyType === global.COMPANY_TYPE.SNF ? 'SNF WC NURSE' : 'Provider', value: user },
            { label: 'Facility', value: facility },
            { label: 'Progress Status', value: progress_status },
            { label: 'Total', value: total1 },
        ];
    }

    getFacilityTitle(facility_id) {
        const f = this.notes.find(d => d.facility_id === facility_id);

        return f.facility_id_ref.title;
    }

    getUploaderName(uploaded_by_id) {
        const up = this.notes.find(d => {
            return d.uploaded_by_id === uploaded_by_id;
        });

        if (up?.uploaded_by_id_ref) {
            return up.uploaded_by_id_ref.first_name + ' ' + up.uploaded_by_id_ref.last_name;
        } else {
            return 'Uploader Name Not Found'
        }
    }
    // getProviderName(doctor_id){
    //     const up = this.notes.find(d => {
    //         return d.doctor_id_ref._id === doctor_id;
    //     });
    //     if(up?.doctor_id_ref) {
    //         return up.doctor_id_ref.first_name + ' ' + up.doctor_id_ref.last_name;
    //     } else {
    //         return 'Provider Name Not Found'
    //     }
    //
    // }

    getProviderName(doctor_id) {
        console.log("Provider:  ", { ...doctor_id });
        let note = { ...doctor_id };
        return note.groupedData.notedata.provider.first_name + ' ' + note.groupedData.notedata.provider.last_name;
    }

    async setNotesToShow(notes: any) {
        let notesToShow, groupData;

        if (notes && notes.noteData.length > 0) {
            const IDs = notes.noteData.map((id: any) => id.noteId);

            const respNotes: any = await this._adminNotesService.getNotes_single(IDs).toPromise();
            if (respNotes.status == 200 && respNotes.data && respNotes.data?.noteData?.length) {
                notesToShow = respNotes.data.noteData;
                groupData = respNotes.data;
            }
        }

        this.notesToShow = Array.isArray(notesToShow) ? new MatTableDataSource(notesToShow.map((note: any) => {
            // this.notesToShow = Array.isArray(notes?.noteData) ? new MatTableDataSource(notes.noteData.map((note: any) => {
            const notes_data = {

                ...note,
                doctor_id_ref: groupData.provider,
                facility_id_ref: groupData.facility,
                //patient_id_ref : groupData.patient,
                uploaded_by_id_ref: groupData.user,
            }
            notes_data.patient_id_ref.facility_id_ref = notes_data.facility_id_ref;

            return notes_data;
        }
        )) : null;
    }

    isNotesVisible() {
        return this.notesToShow !== null;
    }

    groupNotesByCreationDate(notes) {
        const notesByDateOfServiceGroups = {};
        for (let note of notes) {
            // const momentDate = moment(new Date(note.creation_date ? note.creation_date : note.date_of_service));

            const doctor_id = note.doctor_id_ref?._id || note.doctor_id;
            const uploadedbyId = note.uploaded_by_id_ref?._id || note.uploaded_by_id;
            const momentDate = moment(new Date(note.filterCreationDate));
            const creationDate = momentDate.format('MMM DD, YYYY');
            if (!notesByDateOfServiceGroups[creationDate]) {
                notesByDateOfServiceGroups[creationDate] = {};
            }
            if (!notesByDateOfServiceGroups[creationDate][doctor_id]) {
                notesByDateOfServiceGroups[creationDate][doctor_id] = {};
            }
            if (!Array.isArray(notesByDateOfServiceGroups[creationDate][doctor_id][note.facility_id])) {
                notesByDateOfServiceGroups[creationDate][doctor_id][note.facility_id] = [];
            }
            if (!Array.isArray(notesByDateOfServiceGroups[creationDate][doctor_id][note.facility_id][uploadedbyId])) {
                notesByDateOfServiceGroups[creationDate][doctor_id][note.facility_id][uploadedbyId] = [];
            }
            // for unpublish
            if (!(Array.isArray(notesByDateOfServiceGroups[creationDate][doctor_id][note.facility_id][uploadedbyId][note.is_active])) && note.is_signed !== 'true' && note.is_completed !== 'true' && note.is_active === 'true' && note.is_published !== 'true' || null) {
                notesByDateOfServiceGroups[creationDate][doctor_id][note.facility_id][uploadedbyId][note.is_active] = [];
            }
            //for unsigned
            if (!(Array.isArray(notesByDateOfServiceGroups[creationDate][doctor_id][note.facility_id][uploadedbyId][note.is_signed])) && note.is_signed !== 'true' && note.is_completed !== 'true' && note.is_active === 'true' && note.is_published === 'true') {
                notesByDateOfServiceGroups[creationDate][doctor_id][note.facility_id][uploadedbyId][note.is_signed] = [];
            }
            //for signed
            if (!(Array.isArray(notesByDateOfServiceGroups[creationDate][doctor_id][note.facility_id][uploadedbyId][note.is_published])) && note.is_signed === 'true' && note.is_completed !== 'true' && note.is_active === 'true') {
                notesByDateOfServiceGroups[creationDate][doctor_id][note.facility_id][uploadedbyId][note.is_published] = [];
            }
            //for completed
            if (!(Array.isArray(notesByDateOfServiceGroups[creationDate][doctor_id][note.facility_id][uploadedbyId][note.is_completed])) && note.is_completed === 'true' && note.is_signed === 'true' && note.is_active === 'true') {
                notesByDateOfServiceGroups[creationDate][doctor_id][note.facility_id][uploadedbyId][note.is_completed] = [];
            }
            //
            // pushing unpublished
            if (note.is_signed !== 'true' && note.is_completed !== 'true' && note.is_active === 'true' && note.is_published !== 'true' || null) {
                notesByDateOfServiceGroups[creationDate][doctor_id][note.facility_id][uploadedbyId][note.is_active].push(note);
            }
            // pushing unsigned
            if (note.is_signed !== 'true' && note.is_completed !== 'true' && note.is_active === 'true' && note.is_published === 'true') {
                notesByDateOfServiceGroups[creationDate][doctor_id][note.facility_id][uploadedbyId][note.is_signed].push(note);
            }
            // pushing signed
            if (note.is_signed === 'true' && note.is_completed !== 'true' && note.is_active === 'true') {
                notesByDateOfServiceGroups[creationDate][doctor_id][note.facility_id][uploadedbyId][note.is_published].push(note);
            }
            // pushing completed
            if (note.is_signed === 'true' && note.is_completed === 'true' && note.is_active === 'true') {
                notesByDateOfServiceGroups[creationDate][doctor_id][note.facility_id][uploadedbyId][note.is_completed].push(note);
            }
            // notesByDateOfServiceGroups[creationDate][note.uploaded_by_id][note.facility_id].push(note);

        }
        return notesByDateOfServiceGroups;
    }
    changeCreationDate(event) {
        const noOfDays = this.commonService.getDifferenceOfDates(event.startDate, event.endDate,'days');
        if (noOfDays > 30) {
            this.creationDateFilter = false;
            this.filter.creation_date.endDate = moment(this.filter.creation_date.startDate).add(29, "days").startOf('day');
            this.toastr.error('Can not select more than 30 days', 'ERROR');

            setTimeout(() => {
                this.creationDateFilter = true;
            }, 300)
        }
    }
    changeServiceDate(event) {
        if (event.startDate && event.endDate) {
            this.filter.date_of_service = {
                startDate: null,
                endDate: null
            }
            let changeDate = {
                startDate: event.startDate,
                endDate: event.endDate
            }
            localStorage.setItem('admin-notes:check_filter_dos', JSON.stringify(changeDate));
            let noOfDays = this.commonService.getDifferenceOfDates(event.startDate, event.endDate,'days');
            if (noOfDays > 30) {
                this.serviceDateFilter = false;
                this.filter.date_of_service.endDate = moment(this.filter.date_of_service?.startDate).add(29, "days").startOf('day');
                this.toastr.error('Can not select more than 30 days', 'ERROR');

                setTimeout(() => {
                    this.serviceDateFilter = true;
                }, 300)
            }
            else {
                this.filter.date_of_service.startDate = event.startDate;
                this.filter.date_of_service.endDate = event.endDate;
             }
        }
    }


    getNoteStackholdersData(note) {
        const { uploaded_by_id_ref, qa, qa_e, doctor_id_ref } = note;

        if (doctor_id_ref) {
            doctor_id_ref.stackholder_type = 'P';
        }
        if (qa) {
            qa.stackholder_type = 'IQ';
        }
        if (qa_e) {
            qa_e.stackholder_type = 'EQ';
        }
        if (uploaded_by_id_ref) {
            if (uploaded_by_id_ref?.user_type === global.USER_TYPE.MEDICAL_ASSISTANT) {
                uploaded_by_id_ref.stackholder_type = 'MA';
            } else {
                uploaded_by_id_ref.stackholder_type = 'MT';
            }
        }
        const stackholders = [uploaded_by_id_ref, qa, qa_e, doctor_id_ref].filter(t => t?._id);
        // if(uploaded_by_id_ref.user_type === global.USER_TYPE.TRANSCRIBER) {
        //     stackholders.unshift(uploaded_by_id_ref);
        // }
        const selectedStackholder = stackholders.find(t => t._id === note.note_status.id);
        return {
            stackholders,
            selectedStackholder
        }
    }

    handleStackHolderChange(event, note) {
        const stackholderId = event.value;
        note.note_status.id = stackholderId;
        const { qa, qa_e, doctor_id_ref } = note;

        if (stackholderId === qa._id) {
            note.note_status.status = 'internal_qa';
        } else if (stackholderId === qa_e._id) {
            note.note_status.status = 'external_qa'
        } else if (stackholderId === doctor_id_ref._id) {
            note.note_status.status = 'published'
        } else {
            note.note_status.status = 'in_progress'
        }
        this._adminNotesService.assignNoteManually(note.noteId, note.note_status)
            .subscribe((response: any) => {
                if (response.status === 200)
                    this.toastr.success(response.message, 'Success');
            }, () => {
                this.toastr.warning('Something went wrong please try again', 'Failure');
            })
    }

    goToNoteEditor(note) {
        if (this.checkVoidCensus(note.rounding_sheet_id)) {
            if (this._authService.currentUser.user_type === global.USER_TYPE.BILLER) {
                return this._router.navigate([`biller-note-editor/${note.noteId}`]);
            }
            else {
                return this._router.navigate([`company/${this.company_id}/note-editor/${note.noteId}`]);
            }
        } else {
            const dialogRef = this.dialog.open(ViewNoteDialog, {
                width: '80%',
                data: {
                    note_id: note.noteId,
                    timezone: 'America/New_York',
                    void_census: this.checkVoidCensus(note.rounding_sheet_id)
                }
            });
        }
        // if((this.currentRouteUrl === '/dashboard' && this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER) || (this.currentRouteUrl === '/dashboard' && this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT)) {
        //     this._router.navigate([`/note-editor/${note._id}`, {editable: true}]);
        // } else {
        //     this._router.navigate([`/note-editor/${note._id}`]);
        // }
    }

    totalCount(groupedData) {
        if (Array.isArray(groupedData)) {
            let totalCount = 0;
            for (let i = 0; i < groupedData.length; i++) {
                totalCount += groupedData[i]?.notedata?.total;
            }
            return totalCount;
        }
    }
    async downloadReport(reportType = "grouped"): Promise<void> {
        let allNotesData = [];
        if (!this.currentCompany.currentCompanyType)
            this.currentCompany.currentCompanyType = this.currentUser.company_type;
        if (!this.currentCompany.currentCompanyId)
            this.currentCompany.currentCompanyId = this.currentUser.company_id;
        if (reportType === "grouped") {
            this.notesGroup.forEach((gNotes) => {
                gNotes.groupedData.forEach((gData) => {
                    if (this.noteStatus !== "default") {
                        allNotesData.push({
                            "provider": gData.notedata?.provider?.first_name + " " + gData.notedata?.provider?.last_name,
                            "dos": this.getDate(gNotes._id),
                            "facility": gData.notedata?.facility?.title,
                            "total": gData.notedata?.total,
                            "status": this.getStatus(this.noteStatus),
                            "created_by": gData.notedata?.user?.first_name + " " + gData.notedata?.user?.last_name
                        });
                    } else {
                        allNotesData.push({
                            "provider": gData.notedata?.provider?.first_name + " " + gData.notedata?.provider?.last_name,
                            "dos": this.getDate(gNotes._id),
                            "facility": gData.notedata?.facility?.title,
                            "total": gData.notedata?.total,
                            "created_by": gData.notedata?.user?.first_name + " " + gData.notedata?.user?.last_name
                        });
                    }
                });
            });
            this._adminNotesService.exportGroupedReportAsXLSX(allNotesData, this.currentCompany.currentCompanyType, this.currentCompany.currentCompanyId).subscribe((response: any) => {
                if (response instanceof HttpResponse) {
                    var url = window.URL.createObjectURL(response.body);
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.setAttribute('style', 'display: none');
                    a.href = url;
                    a.download = `Grouped Notes Report`;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove(); // remove the element
                    this.toastr.success("Report Downloaded", "Success");
                }
            });
        } else {

            // this._adminNotesService.getNotesFullReport(null, this.filter, this.company_id).subscribe((response: any) => {
            //     this.toastr.info('Full Notes Report is being created, you will recieve a notification as soon as this will be done.', 'Please wait');
            // })

            this.toastr.info('Full Notes Report is being created.', 'Please wait');
            const resp = await this._adminNotesService.getNotes(null, this.filter, this.company_id).toPromise();

            if (resp.status == 200 && resp.data) {
                const notesGroups = this.sortNotesByCreationDate(resp.data);

                notesGroups.forEach((gNotes) => {
                    gNotes.groupedData.forEach((gData) => {
                        gData.notedata.noteData.forEach((nData) => {
                            allNotesData.push({
                                "title": nData?.trans_title,
                                "facility": gData.notedata?.facility?.title,
                                "uploader": gData.notedata?.user?.first_name + " " + gData.notedata?.user?.last_name,
                                "provider": gData.notedata?.provider?.first_name + " " + gData.notedata?.provider?.last_name,
                                "dos": this.getNoteDOS(nData),
                                "status": this.getStatus(nData?.note_status?.status),
                                "created_at": this.getNoteCreatedDate(nData),
                                "updated_at": this.getNoteupdatedDate(nData),
                                "patient": nData.patient_id_ref?.first_name + ' ' + nData.patient_id_ref?.last_name
                            });
                        });
                    });
                });
                await this._adminNotesService.exportFullReportAsXLSX(allNotesData, this.currentCompany.currentCompanyType, this.currentCompany.currentCompanyId).subscribe((response: any) => {
                    if (response instanceof HttpResponse) {
                        const dataString: any = Buffer.from(response.body.data).toString('utf-8');
                        const blob = new Blob([dataString], { type: 'text/csv' });
                        console.log("res", response.body.data, dataString)
                        var url = window.URL.createObjectURL(blob);

                        var a = document.createElement('a');
                        document.body.appendChild(a);
                        a.setAttribute('style', 'display: none');
                        a.href = url;
                        a.download = `All Notes Reports`;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        a.remove();
                        this.toastr.success("Report Downloaded", "Success");
                    }
                });
            }

        }
    }
    checkVoidCensus(patientRoundingSheet) {
        if (patientRoundingSheet) {
            if (patientRoundingSheet?.is_void) {
                if (patientRoundingSheet.is_void === true) return false;
                else return true;
            } else {
                return true
            }
        } else {
            return true;
        }
    }
    onSelectStatus(item = null) {
        if (item == 'signed' || item == 'uploaded' || item == 'not_uploaded' || item == 'published') {
            this.filter.progress_status = item;

            const setStatus = {
                status: this.filter.progress_status,
                value: this.noteStatus
            }
            localStorage.setItem('admin-notes:select_status', JSON.stringify(setStatus));

        }
        else {
            this.noteStatus = 'default';
            delete this.filter?.progress_status;
        }
    }
    async deleteNote(selectedNote) {
        let dialogRef = this.dialog.open(DialogConfirmationComponent, {
            data: {
                message: "Are you sure you want to delete this Progress Note?"
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result === "true") {
                const infoToast = this.toastr.info('Deleting Note..', 'Please wait')
                let details = {
                    transcribtion_id: selectedNote.noteId,
                    is_active: "false",
                    is_trashed: "true"
                };
                let response: any = await this._transcriptionCreateService.deleteTranscription(details, "").toPromise();
                if (response.status) {
                    await this.initNotes();
                    this.notesToShow = null;
                    this.toastr.success("Note Deleted Successfully", "Success");
                } else {
                    this.toastr.clear(infoToast.toastId);
                }
            }
        });
    }
    async downloadBatchReport() {
        this.toastr.info('Downloading Batch Notes Report', 'Downloading...');
        let batchReportDetails: Array<BReport> = [];
        this.notesGroup.forEach((gNotes) => {
            const report = {} as BReport;
            report.dos = this.getDate(gNotes._id);
            report.provider = [];
            gNotes.groupedData.forEach((gData) => {
                let index = report.provider.findIndex((p) => p.name === (gData.notedata?.provider?.first_name + " " + gData.notedata?.provider?.last_name));
                if (index > -1) {
                    report.provider[index].noteIds.push(...gData.notedata.noteData.map(n => n.noteId));
                } else {
                    report.provider.push({
                        'name': gData.notedata?.provider?.first_name + " " + gData.notedata?.provider?.last_name,
                        'noteIds': gData.notedata.noteData.map(n => n.noteId)
                    })
                }
            });
            batchReportDetails.push(report);
        });
        await this._adminNotesService.generateBatchNotesPDF(batchReportDetails).subscribe((res) => {
            if (res instanceof HttpResponse) {
                var url = window.URL.createObjectURL(res.body);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = "Batch Report";
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
                this.toastr.success('Batch Report Downloaded', 'Success');
            } else {

            }
        }, (err) => {
            this.toastr.error('Something went wrong while downloading batch report', 'Failed');
        },
            () => {
            });
        console.log(batchReportDetails);
    }

    async initCompanyFacilities() {
        const company_id = this.company_id;

        const filter: any = {
            is_active: true,
            company_id,
            source: 'all',
            trueRcmStatus: 'all'
        };

        const projection = {
            'title': 1,
            'address': 1,
            'city': 1,
            'state': 1,
            'zip_code': 1,
            'provider_ids_ref': 1,
            // 'template_ids_ref': 1
        }
        let result: any = await this.facilityService.getAllFacilitiesProviderTemplateAssoc(filter, projection).toPromise();
        if (result.status == 200) {
            this.facilities = result.data.facilities;
            this.filteredFacilities = this.facilityControl.valueChanges.pipe(
                startWith(''),
                map((value: any) => this.filterFacilities(value))
            );
        }
        else {
            console.log('error', result);
        }
    }


    setIsButttonDisable() {
        if (this.filter.date_of_service && this.provideModel && this.facilityMOdel) { // if all of the filters are selected, then enable button
            this.isButtonDisable = false;
        }
        else
            this.isButtonDisable = true;
    }

    exportNotes(){
        this.filter.isExportNotes = true;
       
        this._adminNotesService.getNotes(null, this.filter, this.company_id).subscribe((response: any) => {
            if (response.status == 200) {
                response.data.push({isExportNotes: true});
                this.noteEditorService.downloadBatchNotes(response.data).subscribe((res: any) => {
                    if (res instanceof HttpResponse) {
                    this.filter.isExportNotes = false;
                      var url = window.URL.createObjectURL(res.body);
                      var a = document.createElement('a');
                      document.body.appendChild(a);
                      a.setAttribute('style', 'display: none');
                      a.href = url;
                      a.download = "All Notes.zip";
                      a.click();
                      window.URL.revokeObjectURL(url);
                      a.remove(); // remove the element
                      this.toastr.success('Census notes has been downloaded successfully', "Success")
                    }
                  })
               
            }
        });
    }
    toggleRow(id) {
        this.expandedRows.splice(this.expandedRows.indexOf(id), 1);
        this.expandedRows.push(id);
    }

    onTabChange(event) {
        this.tabIndex = event.index;
    }

    async getCompanyPermissions(filter, projection) {
        const response: ICompanyPermissionResponse = await lastValueFrom(this.companyService.getCompany(filter, projection));
        if (response.status == 200) {
            this.shouldShowEncounter = response.data.should_show_encounter;
        }
    }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

