import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FacilitySettingsService } from './facility-settings.service';
import { FacilityService } from 'src/app/services/facility.service';
import { lastValueFrom } from 'rxjs';
import * as global from '../../../../includes/global'
import { CompanyService } from 'src/app/services/Modules/company.service';

type DocumentCategory = {
    id: number | string;
    description: string;
    isCustom?: boolean;
}
@Component({
    selector: "app-facility-settings",
    templateUrl: "./facility-settings.component.html",
    styleUrls: ["./facility-settings.component.css"]
})
export class FacilitySettingsComponent implements OnInit {
    companyId: any;
    facilityId: any;
    facility: any;
    refeshingFacility: boolean = false;
    configuration: any = {
        wound_assessment_report_category: "",
        progress_note_submission_type: "",
        wound_assessment_report_submission_type: "",
        wound_assessment_report_name: "",
        document_category: "",
        // edit_charge_duration: 5,
        matrix_wound_report_destination_folder: "",
        matrix_pdf_note_destination_folder: ""
    };
    facilitySettings: any;
    loaderId = "app-facility-settings";
    documentCategories: DocumentCategory[] = [];
    matrixCareDocumentCategories: DocumentCategory[] = [
        "History & Physical",
        "Consults",
        "Dietary",
        "Resident Forms",
        "Resident Notes",
        "Lab",
        "Other",
        "Radiology",
        "Rehab Forms"
    ].map((category) => ({
        description: category,
        id: category
    }));
    global = global;
    company: any;

    constructor(
        private _facilitySettingsService: FacilitySettingsService,
        private _route: ActivatedRoute,
        private _loader: NgxUiLoaderService,
        private _toastr: ToastrService,
        private facilityService: FacilityService,
        private _companyService: CompanyService
    ) {
        this.companyId = this._route.parent.parent.snapshot.params.id;
        this.facilityId = this._route.snapshot.params.id;
    }

    async ngOnInit(): Promise<void> {
        await this.getCompany();
        this.initFacilitySettings();
        this.initFacility();
    }

    async getCompany() {
        const filter = {
            _id: this.companyId
        };
        const project = {
            name: 1,
            portal_type: 1
        };
        const { status, data }: any = await lastValueFrom(this._companyService.getCompany(filter, project));
        if (status == 200) {
            this.company = data;
        }
        else if (status === 500) {
            this._toastr.error(data.message, "ERROR");
        }
    }

    initDocumentCategories() {
        if (this.facility?.source === "PointClickCare") {
            this.documentCategories =
                this.facility.pcc_pickList?.documentCategories || [];
        } else if (this.facility?.source === "MatrixCare") {
            this.documentCategories = this.matrixCareDocumentCategories;
        }
    }
    initFacility() {
        this._loader.startLoader(this.loaderId);

        this.facilityService
            .getFacility(this.facilityId)
            .subscribe((response: any) => {
                if (response.status === 200) {
                    this.facility = response.data;

                    this.initDocumentCategories();
                }
                this._loader.stopLoader(this.loaderId);
            });
    }

    initFacilitySettings() {
        this._loader.startLoader(this.loaderId);
        this._facilitySettingsService
            .getFacilitySettings(this.facilityId, this.companyId)
            .subscribe({
                next: (response: any) => {
                    if (response.status === 200) {
                        this.configuration = response.data.configuration;
                        if (
                            !this.configuration.wound_assessment_report_name ||
                            this.configuration.wound_assessment_report_name ===
                            ""
                        ) {
                            this.configuration.wound_assessment_report_name =
                                "Wound Assessment Report";
                        }
                        if (
                            !this.configuration
                                .matrix_wound_report_destination_folder ||
                            this.configuration
                                .matrix_wound_report_destination_folder === ""
                        ) {
                            this.configuration.matrix_wound_report_destination_folder =
                                "Resident Notes";
                        }
                        if (
                            !this.configuration
                                .matrix_pdf_note_destination_folder ||
                            this.configuration
                                .matrix_pdf_note_destination_folder === ""
                        ) {
                            this.configuration.matrix_pdf_note_destination_folder =
                                "Resident Notes";
                        }
                        // if(!this.configuration.edit_charge_duration || this.configuration.edit_charge_duration === ""){
                        //   this.configuration.edit_charge_duration = 5;
                        // }
                        this.facilitySettings = response.data;
                        this._loader.stopLoader(this.loaderId);
                    }
                },
                error: (error) => {
                    console.log("err", error);
                    // this._toastr.clear(infoToastr.toastId);
                    this._loader.stopLoader(this.loaderId);
                }
            });
    }
    onSelectionChange() {
        this._loader.startLoader(this.loaderId);
        this._facilitySettingsService
            .updateFacilitySettings(
                this.facilitySettings._id,
                this.configuration
            )
            .subscribe({
                next: (response: any) => {
                    if (response.status === 200) {
                        this.configuration = response.data.configuration;
                        this.facilitySettings = response.data;
                        this._loader.stopLoader(this.loaderId);
                        this._toastr.success("Updated Successfully");
                    }
                },
                error: (error) => {
                    console.log("err", error);
                    this._toastr.error("Cannot update setting.");
                    this._loader.stopLoader(this.loaderId);
                }
            });
    }

    async handleRefreshFacility() {
        this.refeshingFacility = true;
        try {
            const response = (await lastValueFrom(
                this.facilityService.refreshDocumentCategories(this.facilityId)
            )) as any;

            if (response.status === 200) {
                this._toastr.success(
                    "Document categories refreshed successfully."
                );
                this.facility = response.data;
                this.initDocumentCategories();
            }
        } catch (error) {
        } finally {
            this.refeshingFacility = false;
        }
    }
}
