import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { FacilityService } from 'src/app/services/facility.service';
import { ReportModuleService } from '../report-module.service';
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { ReportsService } from '../../report/reports.service';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { PatientOpportunitiesPopupComponent } from './patient-opportunities-popup/patient-opportunities-popup.component';

@Component({
  selector: 'app-patient-opportunities-report',
  templateUrl: './patient-opportunities-report.component.html',
  styleUrls: ['./patient-opportunities-report.component.css']
})
export class PatientOpportunitiesReportComponent implements OnInit {
  currentUser: any;
  company_id: any = '';
  facilities: any = [];
  selectedFacility : any;
  selectedFacilityId: any;
  providers: any = [];
  selectedProvider : any;
  selectedProviderId : any;
  projection = {};
  filter = {
    facility_id: '',
    provider_id: '',
    date_of_creation: {
      startDate: moment().subtract(7, 'days'),
        endDate: moment()
    }, 
  };
  searchFacilityText: any;
  searchProviderText: any;
  public submissionDateFilter: Boolean = true;
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  public dataSource : any; 
  displayedColumns: string[] = ['provider', 'facility', 'firstName', 'lastName' , 'contact','address','city','state', 'zipCode',
  'primaryIns','SecondaryIns','TertiaryIns','admissionDate','initialAdmissionDate','pccDiagnosesList','medicationList','attendingPhysician','atleasProvider','lastSeen',
   ]; 
  loaderId = 'patient-opportunities-report';
  
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private facilityService: FacilityService,
    private reportModuleService: ReportModuleService,
    private loader: NgxUiLoaderService,
    private userTypesRelationshipService: UserTypesRelationshipService,
    private reportsService :ReportModuleService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
  ) { 
    this.currentUser = this.authService.currentUser;
    if (window.location.pathname.includes('company')) {
      this.company_id = this.route.parent.snapshot.params.id
    }
    else {
        this.company_id = '';
    }
    this.projection = {
      'title': 1,
      'address': 1,
      'city': 1,
      'state': 1,
      'zip_code': 1,
      'is_active': 1
    }
    this.filter = { 
      facility_id: '',
      provider_id: '',
      date_of_creation: {
        startDate: moment().subtract(7, 'days'),
        endDate: moment(),
      }, 
    }
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId);  
    await this.getFacilities();
    this.getProviders()
    this.getPatientOpportunitiesReport()
  }

  async getFacilities(){
    let population = 'true';
    let res = await lastValueFrom(this.facilityService.getAllFacilities(this.filter, this.projection, population)) as any;
    // console.log("res---- ", res)
    if(res.status == 200){
      this.facilities = res.data.array;
    }
  }

  applyFacilityChange(event){
    if(event.value && event.value !== '') {
      this.selectedFacilityId = event.value;
      this.filter.facility_id = this.selectedFacilityId;
      this.selectedFacility = this.facilities.find((f) => f._id == event.value);

      this.providers = this.selectedFacility.provider_ids_ref;

      this.selectedProviderId = '';
      this.filter.provider_id = '';

    } else if(!event.value) {
      this.selectedFacilityId = event.value;
      this.selectedFacility = '';
      this.filter.facility_id = this.selectedFacilityId;
      let allProviders = [];
      this.facilities.map(fac => { allProviders.push(...fac.provider_ids_ref) }) 
      let uniqueProviders = []; 
      allProviders.map(p => {
        if(uniqueProviders.some(a => a._id == p._id)){
        }
        else{
          uniqueProviders.push(p)
        } 
      })
      // console.log("uniqueProviders: ", uniqueProviders); 
      this.providers =  uniqueProviders;

      this.selectedProviderId = '';
      this.filter.provider_id = '';
    } 
  }

  applyProviderChange(event = null){ 

    if(event.value && event.value !== '') {
      this.selectedProviderId = event.value;
      this.filter.provider_id = this.selectedProviderId;
      let selectedProvider = this.providers.find((p) => p._id == event.value);
    } else if (!event.value) {
      this.selectedProviderId = event.value;
      this.filter.provider_id = this.selectedProviderId;
      this.selectedProviderId = '';
      this.filter.provider_id = '';
      // this.selectedFacility = '';
      // this.filter.facility_id = '';
    }
    // if(!event.value){
    //   this.selectedProviderId = '';
    //   this.filter.provider_id = '';
    // } 
  }
  getPatientOpportunitiesReport(){
    this.loader.startLoader(this.loaderId); 
    this.reportsService.getPatientOpportunities(this.filter).subscribe((res:any)=>{
      this.loader.stopLoader(this.loaderId);
      this.dataSource = res.data
    })
  }
 
  async getProviders() {
    const doctorsResponse: any = await this.userTypesRelationshipService.getDoctorsList('true', this.company_id).toPromise();
    if (doctorsResponse.status === 200) {
      this.providers = doctorsResponse.data;
      if (this.providers.length > 0) {
        this.providers = this.sortFirstNameVise(this.providers);
      }    }
  }
  isPCCFacility() {
    return this.selectedFacility != undefined && this.selectedFacility != 'all' && (this.selectedFacility?.source === 'PointClickCare' || this.selectedFacility?.pcc_facId)
  }
  isMatrixFacility() {
    return this.selectedFacility != undefined && this.selectedFacility != 'all' && this.selectedFacility?.source === 'MatrixCare'
  }
  isDNFacility() {
    return this.selectedFacility != undefined && this.selectedFacility != 'all' && this.selectedFacility?.source != 'PointClickCare' && !this.selectedFacility?.pcc_facId && this.selectedFacility?.source != 'MatrixCare'
  }
  
  changeSubmissionDate(event){
    this.filter.date_of_creation = {
      startDate: event.startDate,
      endDate: event.endDate,
    }

  }
  getLastSeen(patient) {
    let last_seen='-';
    if(patient?.last_seen?.length){
        if(Array.isArray(patient?.last_seen)&&patient?.last_seen?.length){

            for(let element of patient?.last_seen) {
                    last_seen= element.last_seen||'--';
            };

        }else{
            last_seen= patient?.last_seen||'--';
        }
    }
    else {
      last_seen = '--'
    }
    return last_seen;
  }

  getProviderName(element){
    if(element){
      return `${element.last_name} ${element.first_name}`
    }
    else{
      return '--'
    }
  }

  sortFirstNameVise(arrayTosort) {
    let users = arrayTosort;
    let result = users.sort(function (a, b) {
      if (a.first_name?.toLowerCase() < b.first_name?.toLowerCase()) { return -1; }
      if (a.first_name?.toLowerCase() > b.first_name?.toLowerCase()) { return 1; }
      return 0;
    })
    return result;
  }
  getPatientInitialAdmitDate(data:any){
    if(data.patient_adt_histories?.length){
      let formattedDate = this.datePipe.transform(data.patient_adt_histories[0].createdAt, 'shortDate');
      return formattedDate
    }
    else {
      if(data.last_admit_date_obj){
        return  `${data.last_admit_date_obj?.month}/${data.last_admit_date_obj?.day}/${data.last_admit_date_obj?.year}`
      }
      else{
        return '--'
      }
    }
  }
  getLastAdmitDate(data){
    if(data.last_admit_date_obj){
      return  `${data.last_admit_date_obj?.month}/${data.last_admit_date_obj?.day}/${data.last_admit_date_obj?.year}`
    }
    else{
      return '--'
    }
  }
  getAttendentName(element:any){
   if(element.source == 'PointClickCare'){
    if(element.pcc_primaryPractitioner){
      return `${element.pcc_primaryPractitioner.lastName} ${element.pcc_primaryPractitioner.firstName} `
    }
    else{
      return '--'
    }
   }
   else {
    if (element.primary_practitioner_first_name || element.primary_practitioner_last_name) {
      return `${element.primary_practitioner_first_name ? element.primary_practitioner_first_name  : ''} ${element.primary_practitioner_last_name ? element.primary_practitioner_last_name :''} `}
     else {
      return '--'
     }
  }
  }
  getPatientInsurance(element:any,type:string){
    if(element.source == 'PointClickCare'){
      if(element.pcc_coverage_data.prayers){
        const primaryIns = element.pcc_coverage_data.prayers.filter(prayer => prayer.payerRank === 'Primary');
        const secondaryIns = element.pcc_coverage_data.prayers.filter(prayer => prayer.payerRank === 'Secondary');
        const tertiaryIns = element.pcc_coverage_data.prayers.filter(prayer => prayer.payerRank === 'Third');
        if (type == 'primary'){
           return primaryIns ? primaryIns : '--'
        }
        else if(type == 'secondary'){
          return secondaryIns ? secondaryIns : '--'
        }
        else if(type == 'tertiary'){
          return tertiaryIns ? tertiaryIns : '--'
        }
      }
    else {
       return '--'
    }  
    }
    else if (element.source == 'MatrixCare'){
      if(type == 'primary'){
        return this.getInsuranceCompany('Primary',element)
      }
      if(type == 'tertiary'){
        return this.getInsuranceCompany('Tertiary',element)
      }
      else {
        return this.getInsuranceCompany('Secondary',element)
      }
    }
    else {
      let primaryIns = element.patientinsurances[0]?.p_insurance_carrier?.length ? element.patientinsurances[0].p_insurance_carrier : '--'
      let secondaryIns = element.patientinsurances[0]?.length ? element.patientinsurances[0].s_insurance_carrier : '--'
      if(type == 'primary'){
        return primaryIns
      }
      else if(type == 'secondary'){
        return secondaryIns
      }
      else if(type == 'tertiary') {
        return '--'
      }
    }
  }

  getInsuranceCompany(type, element){ 
    let obj;
    let patientCoverages = element.matrix_payload.coverages
    let secondaryIns;
    if(patientCoverages){
      if(type == 'Primary'){
          for(let i =0 ; i < patientCoverages.length; i++){
            if(patientCoverages[i].insurance_type == 'primary'){
              obj =  patientCoverages[i]? patientCoverages[i] : null ;
            }
            if(obj) break;
          }
              return (obj && obj.insurance_company_name)? obj.insurance_company_name : '--'
      }
      else if(type == 'Secondary'){
        for(let i =0 ; i < patientCoverages.length; i++){
          if(patientCoverages[i].insurance_type != 'primary' ){
            obj =  patientCoverages[i]? patientCoverages[i] : null ;
          }
          if(obj) break;
          }
              secondaryIns = (obj && obj.insurance_company_name)? obj.insurance_company_name : '--'
              console.log("this.secondaryIns: ", secondaryIns);
              return (obj && obj.insurance_company_name)? obj.insurance_company_name : '--'
      }
      else if(type == 'Tertiary' ){
        for(let i =0 ; i < patientCoverages.length ; i++){
          if(patientCoverages[i+1] && patientCoverages[i+1].insurance_company_name != secondaryIns){
            if(patientCoverages[i+1].insurance_type != 'primary'){
              obj =  patientCoverages[i+1]? patientCoverages[i+1] : null ;
            }
            if(obj) break;
            }
          else{
            obj = null
          }
        } 
          return (obj && obj.insurance_company_name)? obj.insurance_company_name : 'N/A'
      }
    }
  } 

  showPatientDiagnosis(patient:any,type:any) {
    let data={
      patient:patient,
      type:type
    }
    const dialogRef = this.dialog.open(PatientOpportunitiesPopupComponent, {
      data: data, width: '40%', maxHeight: "500px", autoFocus: false 
    });
}
}
