import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../../../app/includes/global';
import { UserService } from 'src/app/services/Modules/user.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { BillerPracticeService } from './biller-practice.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-biller-practice-association',
  templateUrl: './biller-practice-association.component.html',
  styleUrls: ['./biller-practice-association.component.css']
})
export class BillerPracticeAssociationComponent implements OnInit {

  currentUser: any = {};
  global = global;
  billers = [];
  companies = [];
  hasBillerLoaded: boolean = false;
  companyDropdownSettings: any = {};
  displayedColumns: string[] = ['biller', 'companies'];
  dataSource = [];
  companyId: any = '';
  previousData: any = [];

  constructor(
    private _authService: AuthService,
    private _userService: UserService,
    private _companyService: CompanyService,
    private _billerPracticeService : BillerPracticeService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) { 
    this.companyDropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: true,
    }; 
  }

  async ngOnInit() {
    this.currentUser = this._authService.currentUser;
    this.companyId = this.route.parent.snapshot.params.id;
    await this.getUsers();
    await this.getAllCompanies();
    await this.populateData();
  }

  async getUsers() {
    const filter: any = {
      company_id: this.companyId,
      user_type: global.USER_TYPE.BILLER
    };
    const projection = {
      first_name: 1,
      last_name: 1,
      full_name: 1,
      title: 1,
      associated_company_ids : 1,
      company_id: 1
    };
    const populate = { associated_company_ids : 'company name' }
    return new Promise<void>((resolve, reject) => {
      this._userService.getUsers(filter, projection, populate).subscribe((response: any) => {
        if (response.status === 200) {
          this.billers = response.data;

          this.previousData = JSON.parse(JSON.stringify(this.billers));
          this.populateData();
          resolve(null);
        }
      }, error => {
        reject('error => ' + error.toString());
      });
    });
  }

  async getAllCompanies() {
    this._companyService.getAllCompanies({is_active : true}, this.companyId).subscribe((response: any) => {
      if (response.status == 200) {
          this.companies = response.data;
      }
      else if (response.status == 403) {
          this.toastr.error(response.message, "ERROR");
      }
  })
  }

  async populateData() {
    this.dataSource = this.billers;
  }

  async onCompanySelect($event, element) {
    if($event.isUserInput) {
      let data = $event.source.value;
      
        if($event.source._selected) {
          element.associated_company_ids =  [...element.associated_company_ids, data];
          await this.updateRelation(data._id, element._id, true);
  
        }
        else {
          element.associated_company_ids = element.associated_company_ids.filter(company => company._id != data._id)
          this.onCompanyDeSelect(data, element);
        }
      
      
    }
  }

  async onCompanyDeSelect($event, element) {
    await this.updateRelation([$event._id], element._id, false);
    element.associated_company_ids = element.associated_company_ids.filter(company => company._id != $event._id)
    // for(let biller of this.previousData) {
    //   if(biller._id == $event._id) {
    //     biller.associated_company_ids = biller.associated_company_ids.filter(company => company._id != element._id);
    //   }
    // }
  }

  async onSelectAllCompanies($event, element) {
    let company_ids = [];
    $event.forEach(company => {
      company_ids.push(company._id);
    });
    await this.updateRelation(company_ids, element._id, true);
  }

  async onDeSelectAllCompanies($event, element) {
    let company_ids = [];
    this.companies.forEach(company => {
      company_ids.push(company._id);
    });
    await this.updateRelation(company_ids, element._id, false);
  }

  async updateRelation(company_ids, user_id, isCreating) {
    const filter = { _id: user_id };
    const updateQuery = { associated_company_ids: company_ids };
    this._billerPracticeService.updateRelation(filter, updateQuery, isCreating, this.companyId).subscribe(async (res: any) => {
      if (res.status == 200) {
        this.toastr.success('Relation Updated', 'Success');
      } else {
        this.toastr.error('Cannot Remove', 'Error');
        this.getUsers();
      }
    });
  }

  compareCompanies(company1: any, company2: any): boolean {
    return company1 && company2 ? company1._id === company2._id : company1 === company2;
  }
}
