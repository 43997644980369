<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
<form (ngSubmit)="submitForm()">

    <mat-form-field style="width: 100%">
        <mat-label>Title</mat-label>
        <input matInput type="text" name="title" #title="ngModel" [(ngModel)]="model.title">
    </mat-form-field>
    <mat-form-field style="width: 100%;">
        <mat-chip-list #companyChipList aria-label="Company Selection">
            <mat-chip *ngFor="let company of companies" [selectable]="true" [removable]="true"
                (removed)="removeCompanyAsChip(company)">
                {{company?.name}}
                <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
            </mat-chip>
            <input (focus)="handleCompanyInputFocus($event)" name="company" placeholder="Company" #companyInput
                [formControl]="companyControl" [matAutocomplete]="auto" [matChipInputFor]="companyChipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addCompanyAsChip($event)" />
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectCompany($event)">
            <mat-option *ngFor="let company of filteredCompanies | async" [value]="company">
                {{company?.name}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>


    <div class="flex justify-end items-center gap-7">
        <button mat-raised-button class="dn-button-secondary" mat-dialog-close type="button">Cancel</button>
        <button mat-raised-button type="button" class="dn-button-primary" type="submit">Save</button>
    </div>
</form>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>