import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CKEditorService } from '../../note-editor/ckeditor.service';
import * as global from '../../../../app/includes/global'
import { AuthService } from 'src/app/services/auth.service';
import { TicketService } from '../ticket.service';
import { FacilityService } from 'src/app/services/facility.service';
import { ToastrService } from 'ngx-toastr';
import { SnfWcNurseAssociationService } from '../../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { PatientListService } from '../../patient-list/patient-list-service';
import { lastValueFrom } from 'rxjs';
import { WoundNurseRelationService } from 'src/app/services/Modules/woundNurse.service';
import { TranscriptionCreateService } from '../../transcription-create/transcription-create.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { NoteEditorService } from "../../note-editor/note-editor.service";


@Component({
  selector: 'app-add-tickets',
  templateUrl: './add-tickets.component.html',
  styleUrls: ['./add-tickets.component.css']
})

export class AddTicketsComponent implements OnInit {

  selected = 'none';
  cke4Config;
  currentUser: any;
  globals = global;
  ticketIdForEdit: any;
    patientName: any='';
  ticketModel = {
    first_name: '',
    last_name: '',
    middle_name: '',
    title: '',
    facility_id: '',
    patient_name:'',
    patient_id: '',
    priority: '',
    description: '',
    company_id: '',
  };
  facilities: any;
  showIcon: boolean = false;
  searchText: any;
  disableSubmitButton: boolean = false;
  patients = [];
  patientSelected = 'none'
  patientSearch: any;
  isDisabledPatient: boolean = false;
  providers: any;
  provider_id:any;

  constructor(private ckeditorService: CKEditorService,
    private dialogRef: MatDialogRef<AddTicketsComponent>,
    private authService: AuthService,
    private ticketService: TicketService,
    private facilityService: FacilityService,
    private _patientListService: PatientListService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
    private toastr: ToastrService,
    private _transcriptionCreateService: TranscriptionCreateService,
    private noteEditorService: NoteEditorService,
    @Inject(MAT_DIALOG_DATA) public data: any = AddTicketsComponent,
    private dashboardService: DashboardService,
    private woundNurseService: WoundNurseRelationService,
    ) {

    this.cke4Config = {
      ...this.ckeditorService.getDefaultConfig(),
      editable: true
    };

    console.log('DATA recevied to edit', data);
    if(data){
            this.ticketModel.title = data['title'];
      this.ticketModel.facility_id = data['facility_id_ref'];
      this.ticketModel.patient_name = data["patient_name"];
      this.ticketModel.patient_id = data['patient_id'] ? data['patient_id'] : data['_id'];
      this.ticketModel.priority = data['priority'];
      this.ticketModel.description = data['description'];
      this.ticketIdForEdit = data['_id'];
      this.ticketModel.first_name = data['first_name'];
      this.ticketModel.last_name = data['last_name'];
      this.ticketModel.middle_name = data['middle_name'];

      if( !this.ticketModel.middle_name ) { this.ticketModel.middle_name = '' }
      if(this.ticketModel.facility_id['_id']){
        this.ticketModel.facility_id = this.ticketModel.facility_id['_id'];
      }
      if(this.ticketModel.first_name || this.ticketModel.middle_name || this.ticketModel.last_name){
        this.patientName = this.ticketModel.first_name + ' ' + this.ticketModel.middle_name + ' ' + this.ticketModel.last_name ;
        this.ticketModel.patient_name = this.ticketModel.last_name + ' ' + this.ticketModel.middle_name + ' ' + this.ticketModel.first_name;
      }
      if (data.is_editing_ticket) this.isDisabledPatient = true;
    }
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    if(this.currentUser.user_type === global.USER_TYPE.DOCTOR){
      this.getFacilities();
    }
    if (this.patientName != "") {
        this.ticketModel.description = "Patient Name: " + this.patientName;
    }
    if (this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type == global.USER_TYPE.TRANSCRIBER || this.currentUser.user_type == global.USER_TYPE.BUSINESS_OFFICER) {
        this.initAssociatedProvidersListForAll();
    }
  }
  initAssociatedProvidersListForAll() {
    if (this.currentUser && this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT) {
      const providerId = this.data?.providerId;
      this._transcriptionCreateService.getAssociatedDoctorsListForMA().subscribe(async (response: any) => {
        if (response.status === 200) {
          this.providers = response.data;
          if (this.providers.length) {
            if (providerId) {
              this.provider_id = providerId;
            }
            else {
              this.provider_id = this.providers[0]._id;
            }
            this.getAssociatedProviderFacility();
          }
        }
      });
    }
    else if (this.currentUser && this.currentUser.user_type === global.USER_TYPE.BUSINESS_OFFICER) {
      this._transcriptionCreateService.getAssociatedDoctorsListForBO(this.currentUser._id, this.currentUser.company_id, this.currentUser.user_type)
        .subscribe(async (response: any) => {
          if (response.status === 200) {
            this.providers = response.data;
            if (this.providers.length) {
              this.provider_id = this.providers[0]._id;
              this.getAssociatedProviderFacility();
            }
          }
        });
    }
    else if (this.currentUser && this.currentUser.user_type == global.USER_TYPE.TRANSCRIBER) {
        this.noteEditorService.getAssociatedDoctorsList(this.currentUser.company_id).subscribe(async (response: any) => {
          if (response.status === 200) {
            this.providers = response.data;
            if(this.providers.length){
              this.provider_id = this.providers[0]._id;
              this.getAssociatedProviderFacility();
            }
          }
        });
  }
  }
  getAssociatedProviderFacility(){
    this.facilityService.getMyFacilities(this.provider_id).subscribe(data => {
    this.facilities = data.data.array;
      if(!this.ticketModel.facility_id) {
        this.ticketModel.facility_id = this.facilities[0]._id;
                this.getFacilityPatients(this.ticketModel.facility_id)
      }
        this.showIcon = true;
    });
}

  closeDialog() {
    this.dialogRef.close();
  }

  getFacilities() {
    return new Promise<void>(async (resolve, reject) => {
      const filter = {
        associated_snf_wc_nurse_ids: this.authService.currentUser._id,
        company_id: this.currentUser.company_id
      };
      const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      const facilityProjection = {
        title: 1,
        pcc_facId: 1,
        pcc_2_legged_authentication: 1,
        source: 1
      };
      if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
        this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection).subscribe((response: any) => {
          if (response.status === 200 && response.data.length > 0) {
            this.facilities = response.data;
            this.getFacilityPatients(this.ticketModel.facility_id)
            this.showIcon = true;
          }
        });
      } else if(this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
        const filter = {
          company_id_ref: this.currentUser.company_id,
          associated_wound_nurse_ids: this.currentUser._id
        };
        const nurseProjection = {
          first_name: 1,
          last_name: 1,
          title: 1,
        };
        const facilityProjection = {
          title: 1,
          pcc_facId: 1,
          pcc_2_legged_authentication: 1,
          source: 1
        };
        const facilitiesResponse : any = await lastValueFrom(this.woundNurseService.getWoundNurseFacilities(filter, nurseProjection, facilityProjection));
        if (facilitiesResponse.status === 200) {
          this.facilities = facilitiesResponse.data;
          this.getFacilityPatients(this.ticketModel.facility_id);
          this.showIcon = true;
        }
      }
      else {
        this.facilityService.getMyFacilities().subscribe(data => {

          this.facilities = data.data.array;
          // console.log('facilities', this.facilities);
          this.getFacilityPatients(this.ticketModel.facility_id)
          this.showIcon = true;
          resolve(null);
        }, error => {
          reject('error => ' + error.toString());
        });
      }
    });
  }

  onReadyCkeditor(e) {
    const { editor } = e;
    this.ckeditorService.ckeditorRemoveTitle(editor);

    editor.on('paste', (e) => {
      const { data, editor } = e;
      if (data.type === 'text') {
        const modifiedhtml = e.data.dataValue
          .split('<br />')
          .join('<p><br /></p>');
        //@ts-ignore
        var filter = new CKEDITOR.filter('p b br'),
          // Parse the HTML string to a pseudo-DOM structure.
          // @ts-ignore
          fragment = CKEDITOR.htmlParser.fragment.fromHtml(modifiedhtml),
          // @ts-ignore
          writer = new CKEDITOR.htmlParser.basicWriter();

        filter.applyTo(fragment);
        fragment.writeHtml(writer);
        const html = writer.getHtml();
        e.data.dataValue = html;
      }
    });
  }
  
  isPCCFacility(facility_id) {
    for(const facility of this.facilities) {
      if(facility_id === facility._id) {
        if(facility.pcc_facId) {
          return true;
        }
      }
    }
  }

  async getFacilityPatients(id, facility_changed?) {

    var details = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <any>null,
      primary_doctor_id: <string>null,
      index: <string>null,
      all: <Boolean>true
    };

    details.id = this.currentUser._id;
    details.auth_key = this.currentUser.auth_key;
    details.usertype = this.currentUser.user_type;
    details.index = null;
    details.all = true;
    if (facility_changed) this.ticketModel.patient_id = "";

    const response: any = await this._patientListService.getPatientsByFacilityId_v2(details, null, { facility: id }, null).toPromise();
    if (response.status === 200) {
      this.patients = response.data.array;
    }

  }

  selectPatient(patient) {
    this.ticketModel.first_name = patient.first_name;
    this.ticketModel.patient_id = patient._id;
    this.ticketModel.last_name = patient.last_name;
    this.ticketModel.patient_name = patient.last_name + " " + patient.first_name;
  }

  submitTicketForm(formObj=null) {
    const infoToast = this.toastr.info('Submitting Ticket', 'Please wait...', { disableTimeOut: true });
    this.disableSubmitButton = true;
    if(this.data?.title){
      this.ticketService.editTicket(this.ticketIdForEdit,this.ticketModel).subscribe((Response:any)=>{
        if (Response.status == 200) {
          this.dialogRef.close(Response.data);
          this.toastr.success(`Ticket submitted Successfully!`, 'Success');
          this.disableSubmitButton = false;
          this.toastr.clear(infoToast.toastId);          
        }
      });
    }else{
      this.ticketModel.company_id = this.currentUser.company_id;
      this.ticketService.addTicket(this.ticketModel).subscribe((response: any) => {  
        if (response.status == 200) {
          this.closeDialog();
          this.toastr.success(`Ticket submitted Successfully!`, 'Success');
          this.disableSubmitButton = false;
          this.toastr.clear(infoToast.toastId)
        }
      });
    }
  }
}
