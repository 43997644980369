import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import * as global from '../global';
import { FacilityService } from 'src/app/services/facility.service';
import { AuthService } from 'src/app/services/auth.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { WoundService } from 'src/app/services/wound.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpResponse } from '@angular/common/http';
import { CommonService } from 'src/app/services/common.service';

interface IFilter {
  date_of_service: {
    startDate: any | Date | moment.Moment,
    endDate: any | Date | moment.Moment
  }
}

@Component({
  selector: 'app-dns-logs',
  templateUrl: './dns-logs.component.html',
  styleUrls: ['./dns-logs.component.css']
})
export class DnsLogsComponent implements OnInit {
  public serviceDateFilter: Boolean = true;
  filter: IFilter;
  public c_date = moment(new Date());
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  showPccLogo: boolean = false;
  facilityControl = new FormControl();
  filteredFacilities: Observable<string[]>;
  facilities: any = [];
  companyId: any;
  currentUser: any;
  facility: any;
  facilityMOdel: any = "";
  global = global;
  displayedColumns = ["sr", "date", "sent_to", "action"];
  loaderId = 'dns-logs';
  dataSource: any = new MatTableDataSource([]);
  constructor(
    private toastr: ToastrService,
    private _route: ActivatedRoute,
    private facilityService: FacilityService,
    private _authService: AuthService,
    private loader: NgxUiLoaderService,
    private _woundService: WoundService,
    private _commonService: CommonService
  ) {
    this.currentUser = this._authService.currentUser;
  }

  async ngOnInit() {
    if (window.location.pathname.includes('company') && !window.location.pathname.includes('manage-companies')) {
      this.companyId = this._route.parent.parent.snapshot.params.id;
    }
    else if (window.location.pathname.includes('manage-companies')) {
      this.companyId = this._route.parent.parent.snapshot.params.id;
    }
    else if (this.currentUser.user_type === global.USER_TYPE.BILLER) {
      this.companyId = this.currentUser.company_id;
    }
    this.filter = this.filterInitialState();
    await this.initCompanyFacilities();

    this.filteredFacilities = this.facilityControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => this.filterFacilities(value))
    );
  }
  getDnsWeeklyReports() {
    this.loader.startLoader(this.loaderId);
    let date = { startDate: moment(this.filter.date_of_service.startDate).format('YYYY-MM-DD'), endDate: moment(this.filter.date_of_service.endDate).format('YYYY-MM-DD') }
    this._woundService.getDnsWeeklyReport({ companyId: this.companyId, date: date, facilityId: this.facility._id }).subscribe((response: any) => {
      if (response.status === 200) {
        this.dataSource.data = response.data;
        this.loader.stopLoader(this.loaderId);
      }
      else {
        this.loader.stopLoader(this.loaderId);
      }
    }, err => {
      this.toastr.error(err, "ERROR");
      this.loader.stopLoader(this.loaderId);
    });
  }
  downloadReport(element: any) {
    if (!element.fileName || element.fileName == '') {
      this.toastr.error("This Report cannot be downloaded!!!", "FileName Missing");
      return;
    }
    this.loader.startLoader(this.loaderId);
    this._woundService.downloadDnsWeeklyReport({ report: element }).subscribe((response: any) => {
      if (response.status === 200) {
        if (response.data instanceof HttpResponse) {
          const url = window.URL.createObjectURL(response.data);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = `Dns Report`;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }
        this.loader.stopLoader(this.loaderId);
      }
      else {
        this.loader.stopLoader(this.loaderId);
      }
    }, err => {
      this.toastr.error(err, "ERROR");
      this.loader.stopLoader(this.loaderId);
    });
  }
  filterInitialState(): IFilter {
    return {
      date_of_service: {
        startDate: moment().subtract(2, 'days'),
        endDate: moment(),
      }
    };
  }
  private filterFacilities(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      if (value.length == 1) {
        this.showPccLogo = false;
      }
      return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) === 0);
    }
    else {
      return this.facilities;
    }
  }
  async initCompanyFacilities() {
    const filter = { company_id: this.companyId, is_active: true };
    const projection = {
      'title': 1,
      'is_active': 1,
      'source': 1,
    }
    const response: any = await this.facilityService.getAllFacilities(filter, projection).toPromise();
    if (response.status === 200) {
      this.facilities = response.data.array;
      this.facility = this.facilities[0];
      this.facilityMOdel = this.facility.title;

      this.filteredFacilities = this.facilityControl.valueChanges.pipe(
        startWith(''),
        map((value: any) => this.filterFacilities(value)));

      await this.getDnsWeeklyReports();
    }
  }
  async onSelectFacility(item = null) {
    if (item) {
      console.log('item', item)
      this.facility = item;

      if (item.pcc_facId || item.source === 'PointClickCare') {
        this.showPccLogo = true;
      } else {
        this.showPccLogo = false;
      }
    }
    this.filteredFacilities = this.facilityControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => this.filterFacilities(value))
    ); 
  }
  changeServiceDate(event) {
    if (event.startDate && event.endDate) {
      this.filter.date_of_service = {
        startDate: null,
        endDate: null
      }
      this.filter.date_of_service.startDate = event.startDate;
      this.filter.date_of_service.endDate = event.endDate;
  
      let changeDate = {
        startDate: event.startDate,
        endDate: event.endDate
      }
      localStorage.setItem('admin-notes:check_filter_dos', JSON.stringify(changeDate));
      const noOfDays = this._commonService.getDifferenceOfDates(event.startDate, event.endDate,'days');
      if (noOfDays > 30) {
        this.serviceDateFilter = false;
        this.filter.date_of_service.endDate = moment(this.filter.date_of_service?.startDate).add(29, "days").startOf('day');
        this.toastr.error('Can not select more than 30 days', 'ERROR');
        setTimeout(() => {
          this.serviceDateFilter = true;
        }, 300)
      }
    }
  }
  clearFacilityFilter() {
    this.showPccLogo = false;
    this.facilityMOdel = '';
    this.facility = '';
  }
  handleApplyFilterClick() {
    this.getDnsWeeklyReports();
  }

  resetFilter() {
    this.showPccLogo = false;
    this.facility = this.facilities[0];
    this.facilityMOdel = this.facility.title;
    this.filteredFacilities = this.facilityControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => this.filterFacilities(value)));
    this.getDnsWeeklyReports();
  }
}
