<div class="flex items-start justify-start gap-2" style="overflow: auto; height: 100%;">
    <mat-card style="min-width: 22%; height: 100%;">
        <mat-card-content>
            <div class="col-header"> All Procedure Types </div>

            <div class="col-content">
                <div *ngFor="let procedure of allProcedureTypes; let i = index">
                    <div [class.active-item]="activatedProcedure?._id === procedure?._id" class="item">
                        <div (click)="handleProcedureTypeClick(procedure, i)">
                            <p style="width: 100%">{{procedure.title}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card style="flex:1; min-width: 22%; height: 100%;">
        <mat-card-content>
            <div class="col-header"> All CPT Codes </div>

            <mat-form-field class="search" appearance="fill">
                <mat-label>Search</mat-label>
                <input matInput placeholder="Search charges" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="chargeFilter">
            </mat-form-field>

            <div class="col-content-2">
                <div *ngFor="let cptCode of allCPTs | searchCPTCode: chargeFilter">
                    <div [class.active-item]="cptCode?.selected === true" class="item">
                        <div (click)="handleCPTCodeClick(cptCode)">
                            <p>
                                {{cptCode.code}} <br />
                                <span>{{getCPTCodeShortDescription(cptCode.description)}}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <ngx-ui-loader [loaderId]="loaderId2"></ngx-ui-loader>
        </mat-card-content>
    </mat-card>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>