<h3>Choose patient</h3>
<div class="flex flex-col gap-2">
  <app-patient-list 
  [selectable]="true"
  [provider]="provider" 
  [facility]="facility" 
  [patient]="patient" 
  (onAddNewClick)="onAddNewClick($event)"
  (onEditClick)="onEditClick($event)"
  (onDeleteClick)="onDeleteClick($event)"
  (onDetailClick)="onDetailClick($event)"
  (onSelect)="onSelectPatient($event)"></app-patient-list>
  <div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close ><mat-icon >cancel</mat-icon></button>
  </div>
</div>