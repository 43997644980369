<app-biller-header *ngIf="currentUser.user_type === global.USER_TYPE.BILLER"></app-biller-header>
<div
    [ngStyle]="{'padding-top' : currentUser.user_type === global.USER_TYPE.BILLER ? '70px' : null, 'background-color': currentUser.user_type === global.USER_TYPE.BILLER ? '#44597d': null, 'padding-left': currentUser.user_type === global.USER_TYPE.BILLER ? '8px' : null, 'padding-right': currentUser.user_type === global.USER_TYPE.BILLER ? '8px' : null, 'padding-bottom': currentUser.user_type === global.USER_TYPE.BILLER ? '6px' : null }">
    <br>
    <div class="flex">
        <div class="flex-grow" style="position: relative; margin-left: 10px; background-color: white; border-radius: 5px; height: 55px;">
            <mat-form-field style="width: 100%;">
              <mat-label>Facility</mat-label>
              <div class="flex items-start justify-center" >
                
                  <img src="../../../assets/icons/pcc-icon.svg" *ngIf="isPCCFacility(filter_facility_id)" width="20px" height="20px" alt="">
                  <img src="../../../assets/icons/matrix.png" *ngIf="isMatrixFacility(filter_facility_id)" alt=""
                  style="height: 12px;
                      width: 20px;  
                      margin-right: 5px;
                      vertical-align: middle;">
                
                <mat-select name="facility" #facility="ngModel" [(ngModel)]="filter_facility_id"
                  (ngModelChange)="handleFilterFacilityChange()">
                  <input placeholder="Search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
                    style="color:#000;height: 40px;padding-left: 5px; ; position: sticky; top: 0; z-index:1000; background-color: lightgray;border: none; width: 100%; " (keydown)="$event.stopPropagation()" />
                  <mat-option *ngFor="let facility of facilities | searchFacility: searchText" value="{{facility._id}}">
                    <app-facility-title [facility]="facility"></app-facility-title>
                  </mat-option>
                </mat-select>
              </div>
            </mat-form-field>
        </div>
        
        <app-input-with-dropdown style="margin-left: 10px;" class="flex-grow" [noDropdown]="true" placeholder="Search By Provider"
            (inputChange)="applyFilter($event.target.value);">
        </app-input-with-dropdown>
        
    </div>
    <br>

    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="provider">
            <th mat-header-cell *matHeaderCellDef style="background-color: #cedbdf;">Provider</th>
            <td mat-cell *matCellDef="let element">
                {{element?.groupedByOwner[0]?.groupedByOwnerAndDays[0]?.owner_id?.last_name}}
                {{element?.groupedByOwner[0]?.groupedByOwnerAndDays[0]?.owner_id?.first_name}}, {{element?.groupedByOwner[0]?.groupedByOwnerAndDays[0]?.owner_id?.title}}
                {{element?.groupedByOwner[0]?.groupedByOwnerAndDays[0]?.owner_id.is_activated !== 'true' ? '(Inactive)' : ''}}
            </td>
        </ng-container>

        <ng-container matColumnDef="0-1 days">
            <th mat-header-cell *matHeaderCellDef style="background-color: #cedbdf;">0-1 Days</th>
            <td mat-cell *matCellDef="let element">
                {{element?.days['0-1']}}
            </td>
        </ng-container>

        <ng-container matColumnDef="2 days">
            <th mat-header-cell *matHeaderCellDef style="background-color: #cedbdf;">2 days</th>
            <td mat-cell *matCellDef="let element">
                {{element?.days['2']}}
            </td>
        </ng-container>

        <ng-container matColumnDef="3 days">
            <th mat-header-cell *matHeaderCellDef style="background-color: #cedbdf;">3 Days</th>
            <td mat-cell *matCellDef="let element">
                {{element?.days['3']}}

            </td>
        </ng-container>

        <ng-container matColumnDef="4-7 days">
            <th mat-header-cell *matHeaderCellDef style="background-color: #cedbdf;">4-7 Days</th>
            <td mat-cell *matCellDef="let element">
                {{element?.days['4-7']}}
            </td>
        </ng-container>

        <ng-container matColumnDef="8-10 days">
            <th mat-header-cell *matHeaderCellDef style="background-color: #cedbdf;">8-10 Days</th>
            <td mat-cell *matCellDef="let element">
                {{element?.days['8-10']}}
            </td>
        </ng-container>

        <ng-container matColumnDef="11-15 days">
            <th mat-header-cell *matHeaderCellDef style="background-color: #cedbdf;">11-15 Days</th>
            <td mat-cell *matCellDef="let element">
                {{element?.days['11-15']}}
            </td>
        </ng-container>

        <ng-container matColumnDef="16-20 days">
            <th mat-header-cell *matHeaderCellDef style="background-color: #cedbdf;">16-20 Days</th>
            <td mat-cell *matCellDef="let element">
                {{element?.days['16-20']}}
            </td>
        </ng-container>

        <ng-container matColumnDef="21+ days">
            <th mat-header-cell *matHeaderCellDef style="background-color: #cedbdf;">21+ Days</th>
            <td mat-cell *matCellDef="let element">
                {{element?.days['21+']}}
            </td>
        </ng-container>

        <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef style="background-color: #cedbdf;">Total</th>
            <td mat-cell *matCellDef="let element">
                {{getTotalChargeInDays(element)}}
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>