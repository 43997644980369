<ng-container>
    <div class=" white-rounded-rect" style="padding: 17px;">
        <div class="flex justify-between">
            <h2 style="color:#000;margin-top:0;margin-bottom:0">Filter</h2>

            <div class="filter-controls flex gap-2">
                
                <button mat-raised-button class="dn-button-primary" (click)="handleApplyFilterClick();">Apply Filter
                </button>

                <button mat-raised-button class="dn-button-secondary" (click)="resetFilter();">Reset Filter
                </button>
                <button mat-raised-button class="dn-button-primary" (click)="exportCensusReportAsExcel();">Download Report
                </button>

            </div>
        </div>
        <div class="flex items-center justify-start gap-2">

            <div class="input-with-cross flex items-baseline justify-start">
                <mat-label style="margin-right:20px;color:#999">Date of Service</mat-label>

                <input *ngIf="this.serviceDateFilter" type="text" ngxDaterangepickerMd
                       [(ngModel)]="filter.date_of_service" (datesUpdated)="changeServiceDate($event)"
                       class="mat-like-input" [ranges]="preDateRanges" [alwaysShowCalendars]="true" [maxDate]="this.c_date"
                       [linkedCalendars]="true" [showClearButton]="true" />
            </div>
        
            <div class="flex items-baseline justify-start provider-field">
                <mat-form-field style="width: 100%;">
                    <mat-label>{{currentCompany.company_type === global.COMPANY_TYPE.SNF ? 'Select SNF WC NURSE' :'Select Provider'}}</mat-label>
                    <input type="text" aria-label="provider" matInput
                        [formControl]="providerControl" [matAutocomplete]="providerAutoControl" [ngStyle]="{color: selectedUser && selectedUser.is_activated !== 'true' ? 'gray' : '' }">
                    <mat-autocomplete #providerAutoControl="matAutocomplete">
                        <mat-option [class.text-gray]="provider.is_activated !== 'true'" *ngFor="let provider of filteredProviders | async" [value]="provider.full_name"
                            (click)="onSelectProvider(provider);">
                            {{provider.full_name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <button  style="height:10px;"  *ngIf="getProvControlStatus()" mat-button mat-icon-button matSuffix (click)="clearProviderFilter()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        
            <div class="flex items-baseline justify-start" style="width: 25%;">
                <mat-form-field appearance="fill" style="width: 100%;">
                    <div class="flex items-center justify-start">
        
                        <img *ngIf="showPccLogo" src="../../../assets/icons/pcc-icon.svg" width="20px" height="20px" alt="">
        
                        <input type="text" placeholder="Select Facility" aria-label="facility" matInput [formControl]="facilityControl"
                               [matAutocomplete]="facilityAutoControl">
                        <mat-autocomplete #facilityAutoControl="matAutocomplete" autoActiveFirstOption>
                            <mat-option *ngFor="let facility of filteredFacilities | async" [value]="facility.title"
                                        (click)="onSelectFacility(facility);">
                                <app-facility-title [facility]="facility"></app-facility-title>
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </mat-form-field>
                <button  style="height:10px;"  *ngIf="getFacControlStatus()" mat-button mat-icon-button matSuffix (click)="clearFacilityFilter()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        
        </div>
    </div>
    <div class="br-spacing"></div>
    <div style="padding:17px" class="white-rounded-rect flex items-center">
        <div class="flex items-center justify-between w-full">
            <div class="flex flex-col items-center justify-center">
                <div class="flex items-center justify-start">
                    <strong class="header-service">Date of Service
                    </strong>
                </div>
                <div class="flex items-center justify-start">
                    <span class="date-of-service-size">
                        {{filter?.date_of_service?.startDate | date}} - {{this.filter?.date_of_service?.endDate | date}}
                    </span>
                </div>
            </div>
            <div class="flex flex-col items-center justify-center">
                <div class="flex items-center justify-start">
                    <strong class="header-service">Creation Date
                    </strong>
                </div>
                <div class="flex items-center justify-end">
                    <span class="date-of-service-size">
                        {{filter?.creation_date?.startDate | date}} - {{this.filter?.creation_date?.endDate | date}}
                    </span>
                </div>
            </div>
            <div class="flex flex-col items-center justify-center">
                <strong class="header-service">{{currentCompany.company_type === global.COMPANY_TYPE.SNF ? 'SNF WC NURSE' :'Provider'}}</strong>
                <div class="flex items-center justify-start">
                    <span class="date-of-service-size">
                        {{ fitlerButtonClicked && provider ? provider?.first_name : null}}
                        {{ fitlerButtonClicked && provider ? provider?.last_name : null}}
                    </span>
                </div>
            </div>
            <div class="flex flex-col items-center justify-center">
                <strong class="header-service">Facility</strong>
                <div class="flex items-center justify-start">
                    <span class="date-of-service-size">
                        {{ fitlerButtonClicked && facility ? facility?.title : null}}
                    </span>
                </div>
            </div>

            <div class="flex flex-col items-center justify-center">
                <span class="header-service">Total
                    <br>
                    {{totalRecords}}
                </span>
            </div>
            <div class="flex flex-col items-center justify-center">
                <button *ngIf="!isVisibleTable && totalRecords!==0" mat-raised-button
                    (click)="isVisibleTable=true; isVisibleDetailTable=false;" class="dn-button-primary">View
                </button>
                <button *ngIf="isVisibleTable && totalRecords!==0" mat-raised-button (click)="isVisibleTable=false"
                    class="dn-button-secondary">Hide
                </button>
            </div>
        </div>
    </div>
    <div class="br-spacing"></div>
</ng-container>

<div class="flex flex-col gap-2" *ngIf="isVisibleTable">
    <div class="notes-group-dateofservice" *ngFor="let dateGroup of censuses">
        <div class="flex flex-col gap-2">
            <div class="group-index-title">
                <span>{{dateGroup._id| date:'MM/dd/yyyy'}}</span>
            </div>
            <div class="card">
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width:3%"></th>
                            <th style="width:20%">{{currentCompany.company_type === global.COMPANY_TYPE.SNF ? 'SNF WC NURSE' :'Provider'}}</th>
                            <th style="width:20%">Facility</th>
                            <th style="width:17%">Census</th>
                            <th style="width:7%">Patients</th>
                            <th style="width:15%">Saved / Signed Notes</th>
                            <th *ngIf="currentCompany.company_type !== global.COMPANY_TYPE.SNF" style="width:18%">Draft / Submitted Charges</th>
                            <th style="width:18%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let subset of dateGroup?.groupedData">
                        <tr *ngFor="let census of subset?.census" [class.text-gray]="census.createdBy.is_activated !== 'true'">
                            
                            <td style="text-align:  center;">
                                <ng-container [ngSwitch]="showStatus(census)">
                                    <ng-container *ngSwitchCase="'red'">
                                        <img width="10px" src="../../../../assets/img/red_dot.png" alt="">
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'green'">
                                        <img width="10px" src="../../../../assets/img/green_dot.png" alt="">
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'yellow'">
                                        <img width="10px" src="../../../../assets/img/red_dot.png" alt="">
                                    </ng-container>
                                </ng-container>
                            </td>

                            <td>
                                {{census?.createdBy?.first_name}}
                                {{census?.createdBy?.last_name}}, {{census?.createdBy?.title}}
                                
                            </td>
                            <td>
                                <img *ngIf="census?.facility?.source === 'PointClickCare' || census?.facility?.pcc_facId"
                                    src="../../../assets/icons/pcc-icon.svg" alt=""
                                    style="width: 35px;height: 20px;margin-left: -5px;;vertical-align: middle;">
                                <img *ngIf="census?.facility?.source === 'MatrixCare' " src="../../../assets/icons/matrix.png" alt=""
                                    style="height: 12px;width: 20px;vertical-align: middle;margin-right: 5px;margin-left: 5px;">
                                <img *ngIf="census?.facility?.source != 'PointClickCare' && !census?.facility?.pcc_facId &&census?.facility?.source != 'MatrixCare'"
                                    class="dn-icon" src="../../../assets/img/DN.png" alt="">
                                {{census?.facility?.title}}
                            </td>
                            <td style="cursor: pointer;" [routerLink]="['../../census/' + census._id]">
                                {{census?.name}}
                            </td>

                            <td style="text-align:  center;">
                                {{census?.totalPatients}}
                            </td>

                            <td style="text-align:  center;">
                                {{getNotesCount(census)}}
                            </td>

                            <td *ngIf="currentCompany.company_type !== global.COMPANY_TYPE.SNF" style="text-align:  center; cursor: pointer;" [routerLink]="['../../census/' + census._id]">
                                {{getChargesCount(census)}}
                            </td>

                            

                            <td class="left">
                                <div class="flex gap-2">
                                    <i class="icon icon-view" matTooltip="Details" matTooltipPosition="above"
                                    [routerLink]="['../../census/' + census._id]">
                                    </i>
                                </div>
                            </td>

                        </tr>
                    </ng-container>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>