import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.css']
})
export class SchedulesComponent implements OnInit {

  @Input('data') data;
  dataSource;
  displayedColumns = [
      "dose",
      "doseUOM",
      "scheduleType",
      "frequency",
      "indicationsForUse",
      "directions",
      "startDateTime",
      "endDateTime",
      "lastAdministration",
  ];

  view: ''|'lastAdministration' = '';
  viewData;

  constructor() { }

  ngOnInit(): void {

    this.dataSource = new MatTableDataSource(this.data);
  }

  changeView(view, viewData) {
    this.view = view,
    this.viewData = viewData;
  }
}
