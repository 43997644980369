import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-procedure-note-checkbox',
  templateUrl: './procedure-note-checkbox.component.html',
  styleUrls: ['./procedure-note-checkbox.component.css']
})
export class ProcedureNoteCheckboxComponent implements OnInit, OnChanges {

  @Input() options: { value: string }[] = [];
  @Input() label: string = '';
  @Input() multiselect: boolean = true;
  @Input() default_value: string = "";
  @Input() selectedOptions: string[] = [];
  @Input() categorySelected: string;

  @Output() optionSelected: EventEmitter<string[]> = new EventEmitter<string[]>();

  userInteracted: boolean = false;

  ngOnInit(): void {
    this.setDefaultValue();
  }
  ngOnChanges(): void {
    this.setDefaultValue();
  }

  setDefaultValue() {
    if (!this.userInteracted && this.categorySelected && this.selectedOptions.length === 0 && this.default_value) {
      this.selectedOptions.push(this.default_value);
      this.optionSelected.emit(this.selectedOptions);
    }
  }

  selectOption(option: string): void {
    this.userInteracted = true;
    const index = this.selectedOptions.indexOf(option);
    if (index === -1) {
      if (!this.multiselect) {
        this.selectedOptions = [option];
      } else {
        this.selectedOptions.push(option);
      }
    } else {
      this.selectedOptions.splice(index, 1);
    }
    this.optionSelected.emit(this.selectedOptions);
  }

}