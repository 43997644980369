<div class="container flex flex-col gap-2">


    <div class="filter-form white-rounded-rect">
        <div class="flex justify-between items-center">

            <div class="flex items-center justify-center gap-2">
                <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
                    Back
                </button>

                <h2 style="color:#000;margin-top:0;margin-bottom:0">LOS Report</h2>
            </div>

            <div class="filter-controls flex gap-2">
                    <mat-form-field>
                        <mat-label>Practices</mat-label>
                        <mat-select value="true" [ngModel]="filter.company_id" (selectionChange)="applyCompanyFilter($event)">
                            <mat-option *ngFor="let company of companies" value="{{company._id}}">{{company.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-select [(value)]="selected" name="facility_id"
                            [(ngModel)]="filter.facility_id" (selectionChange)="applyFacilityChange($event);">
                            <input matInput placeholder="Search" [(ngModel)]="searchText"
                                style="color:#000;height: 40px;padding-left: 5px;" />
                            <mat-option *ngFor="let facility of facilities | searchFacility: searchText"
                                value="{{facility._id}}">
                                <app-facility-title [facility]="facility"></app-facility-title>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
    
                <button mat-raised-button class="dn-button-primary" style="height: 35px !important;" (click)="downloadLosReport()">Export
                    As XLSX
                </button>
            </div>
        </div>
    </div>
    <div class="br-spacing"></div>


    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="patient">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Patient Name</th>
            <td mat-cell *matCellDef="let element">
                {{element.name}}
            </td>
        </ng-container>

        <ng-container matColumnDef="facility">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Facility Name</th>
            <td mat-cell *matCellDef="let element">
                {{selectedFacility.title}}
            </td>
        </ng-container>



        <ng-container matColumnDef="ipa">
            <th mat-header-cell *matHeaderCellDef> IPA/Insurance </th>
            <td mat-cell *matCellDef="let element ; let i = index;">
                {{element.ipa}}
            </td>
        </ng-container>

        <ng-container matColumnDef="admit">
            <th mat-header-cell *matHeaderCellDef> Admit Date </th>
            <td mat-cell *matCellDef="let element ; let i = index;">
                {{getAdmitFormatedDate(element)}}
            </td>
        </ng-container>

        <ng-container matColumnDef="discharge">
            <th mat-header-cell *matHeaderCellDef> Discharge Date </th>
            <td mat-cell *matCellDef="let element ; let i = index;">
                {{getDischargeFormatedDate(element)}}
            </td>
        </ng-container>

        <ng-container matColumnDef="los">
            <th mat-header-cell *matHeaderCellDef> LOS (Days) </th>
            <td mat-cell *matCellDef="let element ; let i = index;">
                {{getDays(element)}}
            </td>
        </ng-container>

        <ng-container matColumnDef="readmit">
            <th mat-header-cell *matHeaderCellDef>Re-admitted </th>
            <td mat-cell *matCellDef="let element ; let i = index;">
                {{element.readmitted}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

</div>
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>