<mat-card class="vertical-height">
    <div class="flex justify-start items-center" style="margin-bottom: 25px;">
        <button mat-raised-button class="dn-button-secondary" type="button" (click)="goBack();">Back</button>
    </div>
    <div class="flex flex-col flex-inline gap-2">
        <mat-checkbox class="top-margin" [checked]="company?.should_upload_facesheet"
            (change)="updateFacesheetSetting(company._id, $event.checked)">
            Mandatory upload face sheet before submitting charge
        </mat-checkbox>

        <mat-checkbox class="top-margin" [checked]="company?.should_extract_facesheet_data"
            (change)="updateFacesheetExtactionSetting(company._id, $event.checked)">
            Allow Face sheet extraction
        </mat-checkbox>
        <mat-checkbox class="top-margin" [checked]="company?.should_show_ipa"
            (change)="updateIPASetting(company._id, $event.checked)">
            IPA
        </mat-checkbox>

        <mat-form-field appearance="outline">
            <mat-label>Default Note Uploading Method</mat-label>
            <mat-select [ngModel]="company.note_uploading_method"
                (selectionChange)="updateNoteUploadingMethodSetting(company._id, $event.value)">
                <mat-option *ngFor="let noteUploadingMethod of getCompanyUploadingMethods(company.company_type)"
                    [value]="noteUploadingMethod.value">
                    {{ noteUploadingMethod.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</mat-card>