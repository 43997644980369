import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { FacilityService } from 'src/app/services/facility.service';
import { PatientListService } from '../../patient-list/patient-list-service';
import * as global from '../../global';
import { DashboardService } from '../../dashboard/dashboard.service';
import { TranscriptionCreateService } from '../../transcription-create/transcription-create.service';

import moment from 'moment'
import { PatientRoundingSheetService } from '../../patient-rounding-sheets/patient-rounding-sheet.service';
import { lastValueFrom } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { SnfWcNurseAssociationService } from '../../snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';

@Component({
  selector: 'app-admin-side-patients',
  templateUrl: './admin-side-patients.component.html',
  styleUrls: ['./admin-side-patients.component.css']
})
export class AdminSidePatientsComponent implements OnInit {
  facilities: any = [];
  currentUser: any = [];
  allPatients: any = [];
  assocProviders: Array<any> = [];
  dataSource: any;
  applySearchFilter: Boolean = false;
  activeAlphabeticBarIndex = 'All';
  global = global;
  dob: any;
  patientRoundingSheets: any[] = [];
  displayedColumns = ['name', 'gender', 'date_of_birth', 'facility', 'last_seen','operations']
  filter: any = {
    dob: '',
    provider: '',
    active: true,
    company_id: '',
    facility: '',
    patientName: '',
  }
  public pagination: {
    pageNo: number,
    hasNextPage: boolean,
    hasPrevPage: boolean,
    totalCount: number,
    totalPages: number
  };
  loaderId = 'patient-list-loader';
  searchProviderText: any;
  searchFacilityText: any;
  timeoutId: any;
  searchCompanyBase:boolean=false;
  isAdvancedTextSearchComponentVisible: boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  editingPatient: boolean = false;
  company_type: any = 'Wound';
  // @Output() onAddNewClick: EventEmitter<any> = new EventEmitter();
  constructor(

    private _patientRoundingSheets: PatientRoundingSheetService,
    private _dashboardService: DashboardService,
    private router: Router,
    private route: ActivatedRoute,
    private facilityService: FacilityService,
    private authService: AuthService,
    private _patientListService: PatientListService,
    private _transcriptionCreateService: TranscriptionCreateService,
    private loader: NgxUiLoaderService,
    private commonService: CommonService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,

  ) {
    this.currentUser = this.authService.currentUser;
    this.pagination = {
      pageNo: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalCount: 0,
      totalPages: 0
    };
  }

  async ngOnInit() {
    const currentCompany = this.commonService.getLocalStorageItem('admin-Dashboard');
    if(currentCompany) {
        this.company_type = currentCompany.currentCompanyType;
    }
    const searchAllPatients = localStorage.getItem( 'admin-side-patients:searchAllPatients' );
    if (window.location.pathname.includes('company')) {
      this.filter.company_id = this.route.parent.snapshot.params.id;
    }
    else {
      this.filter.company_id = ''
    }
    const isPatientName = localStorage.getItem('patient_list_name');
    if (isPatientName) {
      this.filter.patientName = isPatientName;
    }
    const isDOB = localStorage.getItem('patient_list_dob');
    if (isDOB) {
      let dobDate = moment(isDOB);
      this.dob=dobDate;
      let date = moment(isDOB).format(global.moment_date_format2);
      this.filter.dob = date;
    }
    // await this.getFacilities();
    await this.initAssocProviders();
    if (this.assocProviders?.length > 0) {
      if (localStorage.getItem("patient_list_provider") !== null) {
        await this.initFacilities(localStorage.getItem("patient_list_provider"));
      }
      else {
        await this.initFacilities(this.currentUser._id);
        const isFacilitySelected = localStorage.getItem('patient_list_facility');
        if(isFacilitySelected) {
          this.filter.facility = isFacilitySelected;
        }
      }
    }
    if (this.facilities?.length > 0 && searchAllPatients !== 'true') {
      this.initPatients(1);
      if (this.currentUser.user_type === global.USER_TYPE.DOCTOR) {
        this.initPatientRoundingSheets(this.currentUser._id, this.filter.facility);
      } else if (this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser === global.USER_TYPE.TRANSCRIBER) {
        this.initPatientRoundingSheets();
      }
    }
    if( searchAllPatients === 'true' ) {
      this.searchCompanyBase = true;
      this.filter.facility = '';
      this.filterOnPatiens();
    }
  }
  async getFacilities() {
    const filter = {
      assoc_company_ids: this.filter.company_id,
      is_active: true
    }

    const projection = {
      title: 1,
      pcc_facId: 1,
      source: 1,
      pcc_2_legged_authentication: 1,
      pcc_orgUuid: 1,
      assoc_company_ids: 1
    }

    let companyAssocPopulation: facilityPopulationInterface = {
      collection: 'companies',
      field: ["assoc_company_ids"],
      filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: [true, { $in: ['$_id', '$$assoc_company_ids'] }] }] } },
      project: { name: 1 },
    };
    
    await this.facilityService.getFacilities(filter, projection, [companyAssocPopulation]).subscribe((response: any) => {
      if (response.status == 200) {
        this.facilities = this.sortTitleVise(response.data.array);
        this.filter.facility = this.facilities[0]._id;
        this.initPatients(1);
      }
    })
  }
  async initPatients(page, index = null) {

    var details = {
      id: <string>null,
      auth_key: <string>null,
      usertype: <any>null,
      primary_doctor_id: <string>null,
      index: <string>null
    };
    details.id = this.currentUser._id;
    details.auth_key = this.currentUser.auth_key;
    details.usertype = this.currentUser.user_type;
    details.index = index;
    // this.loader.startLoader(this.loaderId);

    // if (this.filter.facility) {
    //   await this.getPatientsByFacilityId(details, page);

    // }
    this.getPatientsV2(index, page);
    // this.loader.stopLoader(this.loaderId); 
  }

  isPCCFacility(facility_id) {
    for(const facility of this.facilities) {
      if(facility_id === facility._id) {
        if(facility.pcc_facId) {
          return true;
        }
      }
    }
  }
  isMatrixFacility(facility_id){
    for(const facility of this.facilities) {
      if(facility_id === facility._id) {
        if(facility.source == 'MatrixCare') {
          return true;
        }
      }
    }
  } 
  
  async getPatientsByFacilityId(item, page) {
    // DOCTOR 1
    // const infoToast = this._toastr.info("Fetching patients...", "Please wait");
    const response: any = await this._patientListService.getPatientsByFacilityId(item, page, this.filter).toPromise();
    if (response.status === 200) {
      this.allPatients = response.data.array
        .filter(patient => patient.is_active === 'true');
      const totalCount = response.data.totalCount;
      this.pagination.totalCount = totalCount;
      this.pagination.pageNo = page;
      this.initPagination();
      this.initTableDataSource(this.allPatients);
      this.dataSource.sort = this.sort;
    }
  }
  
  async getPatientsV2(index, page) {
    if (this.filter.patientName == '') {
      localStorage.removeItem('patient_list_name');
    }
    if (this.filter.dob == '') {
      localStorage.removeItem('patient_list_dob');
    }
    const provider = this.assocProviders.find(provider => provider._id === this.filter.provider);
    // const company_id = provider.company_id;
    const company_id = this.route.parent.snapshot.params.id;
    let facility_id = this.filter.facility;
    let searchText = this.filter.patientName;
    const dob = this.filter.dob;
    let provider_id;
    this.loader.startLoader(this.loaderId);
    if(!facility_id || facility_id === 'undefined') {
      provider_id = provider._id;
      facility_id = undefined;
    }
    let serachPatient = true;
    if(searchText.includes(",")){
      const searchSplitText = searchText.split(",");
      if(searchSplitText.length > 1 && searchSplitText[0].length > searchSplitText[1].length) {
        searchText = searchSplitText[0].trim();
      }else if(searchSplitText.length > 1 && searchSplitText[1].length > searchSplitText[0].length){
        searchText = searchSplitText[1].trim();
      }
    }
    if(this.searchCompanyBase) {
      if(!searchText && !dob) {
        serachPatient = false;
      }
    }
    if(serachPatient) {
      const { data, status } = await lastValueFrom(this._patientListService.getPatientsV2(company_id, facility_id, provider_id, searchText, dob, page, index, undefined, undefined)) as any;
      if (status === 200) {
        this.allPatients = data.array.filter(patient => patient.is_active === 'true');
        const totalCount = data.totalCount;
        this.pagination.totalCount = totalCount;
        this.pagination.pageNo = page;
        this.loader.stopLoader(this.loaderId);
      } else {
        this.allPatients = [];
        this.pagination.totalCount = 0;
        this.pagination.pageNo = 1;
        this.loader.stopLoader(this.loaderId);
      }
    } 
    else {
      this.allPatients = [];
      this.pagination.totalCount = 0;
      this.pagination.pageNo = 1;
      this.loader.stopLoader(this.loaderId);
    }
    this.initPagination();
    this.initTableDataSource(this.allPatients);
    this.dataSource.sort = this.sort;
  }
  initPagination() {
    this.pagination.totalPages = Math.ceil(this.pagination.totalCount / 50);
    if (this.allPatients) {
      this.pagination.hasNextPage = this.allPatients.length > 0 && this.pagination.pageNo < this.pagination.totalPages;
    }
    this.pagination.hasPrevPage = this.pagination.pageNo > 1;
    // this.loader.stopLoader('loader-01');
  }
  handleNextClick() {
    this.initPatients(this.pagination.pageNo + 1);
  }

  handlePrevClick() {
    this.initPatients(this.pagination.pageNo - 1);
  }
  getcountofPatients() {
    return ( this.allPatients.length > 0 ? (this.pagination.pageNo * 50) - 49 : 0 ) + ' - ' + (((this.pagination.pageNo * 50) - 50) + (this.allPatients.length)) + " out of " + (this.pagination.totalCount);
  }

  initTableDataSource(patients) {
    this.dataSource = new MatTableDataSource(patients);
  }
  applyFilter(filterValue: string) {
    localStorage.setItem("patient_list_name", filterValue);
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      // this.hasFacilitiesLoaded = true;
      if(this.filter.patientName){
        if(this.filter.patientName.length >= 4){
          this.initPatients(1)
        }
      }else{
        this.initPatients(1)
      }
    }, 1000);
    // this.applySearchFilter = true;
    // this.filter.patientName = filterValue.trim();
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    // this.dataSource.filterPredicate = function (data, filter: string): boolean {
    //   return data.name.toLowerCase().includes(filter);
    // };
  }
  clearFilter() {
    this.filter.active = true;
    this.filter.dob = '';
    this.applySearchFilter = false
    this.filter.facility = Array.isArray(this.facilities) && this.facilities.length > 0 ? this.facilities[0]._id : ''
    this.filter.patientName = '';
    this.dob = null;
    this.searchCompanyBase=false;
    localStorage.removeItem('admin-side-patients:searchAllPatients');
    this.initPatients(1)
  }

  getLastSeen(patient) {
    let company_id=this.currentUser?.company_id?.toString()
    if (window.location.pathname.includes('company')) {
      company_id = this.route.parent.snapshot.params.id?.toString();
    }
    let last_seen='-';
    if(patient?.last_seen){
        if(Array.isArray(patient?.last_seen)&&patient?.last_seen?.length){

            for(let element of patient?.last_seen) {
                if(element.company_id?.toString().includes(company_id)){
                    last_seen= element.last_seen||'';
                    break;
                }
            };

        }else{
            last_seen= patient?.last_seen||'';
        }
    }
    return last_seen;

    //return  this.patient?.last_seen||'';
    // let lastSeen = '';
    // const { recentNote } = patient;
    // if (recentNote && recentNote.doctor_id_ref) {

    //   const { first_name, last_name } = recentNote.doctor_id_ref;
    //   lastSeen += `${first_name[0]}${last_name[0]}`.toUpperCase();
    //   // lastSeen += ` - ${moment(new Date(recentNote.filterServiceDate).toLocaleString('en-US', { timeZone: recentNote.timezone })).format('M/D/YYYY')}`;
    //   if (recentNote.date_obj) {
    //     lastSeen += ` - ${this.commonService.dateObjToString(recentNote.date_obj, false)}`;
    //   }
    //   else {
    //     const [year, month, day] = this.commonService.convertDateToStringforMoment(moment(recentNote.filterServiceDate)).split("-");
    //     lastSeen += ` -${month}/${day}/${year}`;
    //   }
    // }
    // return lastSeen;
  }
  filterOnPatiens() {
    this.filter.active = true;
    this.initPatients(1);
    if ((this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT || this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER) && this.filter?.provider !== undefined && this.filter?.facility !== undefined) {
      this.initPatientRoundingSheets(this.filter.provider, this.filter.facility);
    }

    else if (this.currentUser.user_type === global.USER_TYPE.DOCTOR || this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      this.initPatientRoundingSheets(this.currentUser._id, this.filter.facility);
    }
  }
  handleAphabeticIndexButtonClick(index) {
    this.initPatients(1, index);
  }
  onAddNewClick() {
    this.router.navigate([`/company/${this.filter.company_id}/patient-add`]);
    // this.showPatientAddDialog();
  }
  onEditPatient(patient_id) {
    this.editingPatient = true;
    this.router.navigate([`/company/${this.filter.company_id}/admin-patient-edit/${patient_id}`]);
    // this.showPatientAddDialog();
  }
  sortFirstNameVise(arrayTosort){
    let users=arrayTosort;
    let result=users.sort(function(a, b){
        if(a.first_name?.toLowerCase() < b.first_name?.toLowerCase()) { return -1; }
        if(a.first_name?.toLowerCase() > b.first_name?.toLowerCase()) { return 1; }
        return 0;
    })
    return result;
  }
  sortTitleVise(arrayTosort){
    let users=arrayTosort;
    let result=users.sort(function(a, b){
        if(a.title?.toLowerCase() < b.title?.toLowerCase()) { return -1; }
        if(a.title?.toLowerCase() > b.title?.toLowerCase()) { return 1; }
        return 0;
    })
    return result;
  }

  async initAssocProviders() {
    // const item:any = {
    //   id: this.currentUser._id,
    //   auth_key: this.currentUser.auth_key,
    //   usertype: this.currentUser.user_type
    // }
    if (this.currentUser.user_type == 2) {
      const assocProvidersResponse: any = await this._dashboardService.getAssociatedDoctorsList(this.currentUser.company_id).toPromise();
      if (assocProvidersResponse.status === 200) {
        // this.assocProviders = assocProvidersResponse.data;
        this.assocProviders = this.sortFirstNameVise(assocProvidersResponse.data);
        // this.assocProviders = [this.currentUser, ...assocProvidersResponse.data.assoc_provider_id];
      }
      if (!this.filter.provider) {
        this.assocProviders.forEach((e) => {
          if (e._id == this.currentUser._id) {
            this.filter.provider = this.currentUser._id;
          } else {
            this.filter.provider = this.assocProviders[0]._id;
          }
        })
        if (localStorage.getItem("patient_list_provider") !== null) {
          this.filter.provider = localStorage.getItem("patient_list_provider");
        }
      }
    }
    if (this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
      const assocProvidersResponse: any = await this._transcriptionCreateService.getAssociatedDoctorsListForMA().toPromise();
      if (assocProvidersResponse.status === 200) {
        // this.assocProviders = assocProvidersResponse.data;
        this.assocProviders = assocProvidersResponse.data;
        // this.assocProviders = [this.currentUser, ...assocProvidersResponse.data.assoc_provider_id];
      }
      if (!this.filter.provider) {
        this.filter.provider = this.currentUser._id;
        if (localStorage.getItem("patient_list_provider") !== null) {
          this.filter.provider = localStorage.getItem("patient_list_provider");
        }
      }
    }
    else if (this.currentUser.user_type == 1) {
      const assocProvidersResponse: any = await this._dashboardService.getP2PRelation().toPromise();
      if (assocProvidersResponse.status === 200) {
        // this.assocProviders = assocProvidersResponse.data;
        this.assocProviders = assocProvidersResponse.data.assoc_provider_id;
        this.assocProviders.unshift(this.currentUser);
        // this.assocProviders = [this.currentUser, ...assocProvidersResponse.data.assoc_provider_id];
      }
      if (!this.filter.provider) {
        this.filter.provider = this.currentUser._id;
        if (localStorage.getItem("patient_list_provider") !== null) {
          this.filter.provider = localStorage.getItem("patient_list_provider");
        }
      }
    } else if (this.company_type === global.COMPANY_TYPE.SNF) {
      const filter = {
        snf_wc_nurse_id: this.currentUser._id,
        company_id: this.currentUser.company_id
      };
      const project = {
        first_name: 1,
        last_name: 1,
        title: 1,
        company_id: 1
      };
      const snfNursesResponse: any = await lastValueFrom(this._snfWcNurseAssociationService.getAssociatedSnfWcNurses(filter, project));
      if (snfNursesResponse.status === 200) {
        this.assocProviders = snfNursesResponse.data.associated_snf_wc_nurse_ids || [];
        this.assocProviders.unshift(this.currentUser);
        console.log('this.assocProviders',this.assocProviders); 
        this.filter.provider = this.currentUser._id;
        if (localStorage.getItem("patient_list_provider") !== null) {
          this.filter.provider = localStorage.getItem("patient_list_provider");
        }
      }
    }
  }
  handleProviderChange(e) {
    localStorage.setItem("patient_list_provider", e);
    console.log("prov: ", this.filter.provider)
    this.initFacilities(this.filter.provider);
  }
  handleFacilityChange(e) {
    this.filterOnPatiens();
    localStorage.setItem('patient_list_facility',e);
  }
  searchByCompany(event: MatCheckboxChange) {
    if (event.checked) {
      localStorage.setItem('admin-side-patients:searchAllPatients', 'true');
      this.searchCompanyBase = event.checked;
      this.filter.facility = '';
      //this.filterOnPatiens();
      this.filter.dob = '';
      this.dob = null;
      this.filter.patientName = '';
      this.dataSource.data = [];
      this.allPatients = [];
      this.pagination.totalCount = 0;
      this.pagination.pageNo = 1;
      this.initPagination();
    }
    if (!event.checked) {
      localStorage.removeItem('admin-side-patients:searchAllPatients');
      this.searchCompanyBase = event.checked;
      this.clearFilter();
    }
  }
  async initFacilities(provider_id) {
    // const item:any = {
    //   id: this.currentUser._id,
    //   auth_key: this.currentUser.auth_key,
    //   usertype: this.currentUser.user_type
    // 
    if (this.company_type === global.COMPANY_TYPE.SNF) {
      const filter = {
        associated_snf_wc_nurse_ids: provider_id,
        company_id: this.currentUser.company_id
      };
      const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      const facilityProjection = {
        title: 1,
        pcc_facId: 1,
        pcc_2_legged_authentication: 1,
        source: 1
      };
      const facilitiesResponse : any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
      if (facilitiesResponse.status === 200) {
        this.facilities = facilitiesResponse.data
      }
    }
    if (this.currentUser.user_type == 1) {
      const facilitiesResponse: any = await this._transcriptionCreateService.getFacilities(provider_id).toPromise();
      if (facilitiesResponse.status === 200) {
        this.facilities = facilitiesResponse.data.array;
      }
    } else if (this.currentUser.user_type == 2 || this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
      // const associatedFacilityOfTranscriberResponse: any = await this._dashboardService.getAssociatedFacilityOfTranscriber().toPromise();
      const associatedFacilityOfTranscriberResponse: any = await this._transcriptionCreateService.getFacilities(provider_id).toPromise();
      if (associatedFacilityOfTranscriberResponse.status === 200) {
        this.facilities = associatedFacilityOfTranscriberResponse.data.array;
      }
    }
    if (!this.filter.facility && Array.isArray(this.facilities) && this.facilities.length > 0) {
      this.filter.facility = this.facilities[0]._id;
      if (localStorage.getItem("patient_list_facility") !== null) {
        this.filter.facility = localStorage.getItem("patient_list_facility");
      }
    }
    const searchAllPatients = localStorage.getItem( 'admin-side-patients:searchAllPatients' );
    if(searchAllPatients !== 'true'){
      this.initPatients(1);
    }
  }
  initPatientRoundingSheets(provider_id?, facility_id?) {
    // const details = {
    //   ...this.currentUserDetails
    // }
    this._patientRoundingSheets.getCensusList(facility_id, provider_id).subscribe((response: any) => {
      if (response.status === 200) {
        this.patientRoundingSheets = response.data.array;
      }
    })
  }

  onDateChange(event) {
    if (event.value) {
      let filterDOB = new Date(event.value);
      localStorage.patient_list_dob = filterDOB;
      this.dob = '';
      this.dob = event.value;
      this.filter.dob = moment(event.value).format(global.moment_date_format2);
      this.filterOnPatiens()
    } else {
      localStorage.removeItem('patient_list_dob');
      this.dob = '';
      this.filter.dob = '';
      this.filterOnPatiens()
    }
  }
  goToPatientDetailScreen(patient) {
    this.editingPatient = true;
    return this.router.navigate([`/company/${this.filter.company_id}/patient-detail/${patient._id}`]);
  }

  ngOnDestroy() {
    if (!this.editingPatient) {
      localStorage.removeItem('patient_list_facility');
      localStorage.removeItem('patient_list_name');
      localStorage.removeItem('patient_list_dob');
      localStorage.removeItem('admin-side-patients:searchAllPatients');
    }
  }

}
