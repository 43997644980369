import { Component,Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as global from '../../global';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TemplatesService } from '../../templates/templates.service';
// UPLOAD FILE DIALOG
export interface PreviewTemplateDialogData {
  title:string;
  rawText:string;
}

@Component({
  selector: 'app-preview-template-dialog',
  templateUrl: './preview-template-dialog.component.html',
  styleUrls: ['./preview-template-dialog.component.css']
})
export class PreviewTemplateDialog implements OnInit {
  global = global;
  title:string;
  rawText:string;
  plainText:string;

  loaderId = 'preview-template-dialog-loader';
  constructor(
    public dialogRef: MatDialogRef<PreviewTemplateDialog>,
    private tempalteService: TemplatesService,
    private loader: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: PreviewTemplateDialogData) { 
      if(data) {
        this.title = data.title;                 
        this.rawText = data.rawText;                 
      }
    }
    
    ngOnInit(): void {
      this.initNote();
    }

    initNote() {
    this.loader.startLoader(this.loaderId);
      
      this.tempalteService.previewNoteAsPCC({rawText: this.rawText}).subscribe((response:any) => {
        if(response.status === 200) {
          this.loader.stopLoader(this.loaderId);
          this.plainText = response.data.plainText;
        }
      });
    }

}
