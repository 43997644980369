import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { WoundService } from 'src/app/services/wound.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DialogConfirmationComponent } from '../../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CompanyService } from 'src/app/services/Modules/company.service';
import * as global from '../../../includes/global';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

interface UserTypes {
  id: String,
  text: String
}
interface Row {
  row_id: String,
  value: any,
  dropdownTitle: any,
  row_confirmed: Boolean,
  data_type: String
}
interface DataSet {
  header_id: String,
  header: String,
  multiselect_enabled: Boolean,
  header_confirmed: Boolean,
  row: Array<Row>,
  user_type: String
}
interface DataSetHeaders{
  header: String,
  selected: Boolean
}

@Component({
  selector: 'app-data-sets',
  templateUrl: './data-sets.component.html',
  styleUrls: ['./data-sets.component.css']
})
export class DataSetsComponent implements OnInit {
  @Input() set: any;
  global = global;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  data_types = [
    "Text",
    "Date",
    "Dropdown"
  ];
  minDate = moment(new Date()).subtract(7, 'day').toDate();
  columns: DataSet[];
  dataSet: any[];
  loaderId = 'app-data-sets';
  currentUser: any;
  disableEditing: boolean = false;
  datasetSettings: any[];
  selectUserTypes =  global.USER_TYPE.DOCTOR;
  allUserList: Array<UserTypes> = [];
  dataset1Headers= ['LOCATION', 'WOUND TYPE', 'STAGE', 'WOUND STATUS', 'ODOR', 'EXUDATE AMOUNT', 'EXUDATE TYPE'];
  dataset2Headers= ['EPITHELIAL', 'GRANULATION', 'SLOUGH', 'ESCHAR', 'EXPOSED TISSUES', 'WOUND EDGES', 'PERIWOUND', 'INFECTION SIGNS', 'PAIN', 'CHANGE FREQUENCY', 'CLEANSE WOUND WITH', 'PRIMARY TREATMENT','DRESSINGS','SECONDARY DRESSING'];
  datasetHeaders: Array<DataSetHeaders> = [];
  company_id: any;
  matMenuLoaderId: any = "matMenuLoaderId";
  swapMenuData: any = [];
  showMatMenu: boolean = false;
  divTop: string;
  divLeft: string;
  constructor(private _wound: WoundService,
    private _company: CompanyService,
    private toastr: ToastrService,
    private _authService: AuthService,
    private loader: NgxUiLoaderService,
    private dialog: MatDialog,
    private route: ActivatedRoute) {
    this.currentUser = this._authService.currentUser;
    if (this.currentUser.admin_type != 'system_admin') {
      this.disableEditing = true;
    }
    Object.keys(this.global.USER_TYPE).forEach((key)=>{
      var newUserType = {} as UserTypes;
      newUserType.id = this.global.USER_TYPE[key];
      newUserType.text = key;
      this.allUserList.push(newUserType);
    });
  }

  async ngOnInit() {
    if (window.location.pathname.includes('company')) {
      this.company_id = this.route.parent.snapshot.params.id;
    }else{
      this.company_id = this.currentUser.company_id;
    }
    if (global.base_url == 'https://aswc.doctornow.io'){
      this.dataset2Headers.push( 'DIRECTION');
    } else if(this.company_id === "65d6073a1aab725d8472c9d8") {
      this.dataset1Headers.push('DIRECTION');
    }
    this.columns = [];
    this.datasetHeaders = [];
    if(this.set === 1){
      this.dataset1Headers.forEach((h)=>{
        var newDatasetHeader = {} as DataSetHeaders;
        newDatasetHeader.header = h;
        newDatasetHeader.selected = false;
        this.datasetHeaders.push(newDatasetHeader);
      });
    }else{
      this.dataset2Headers.forEach((h)=>{
        var newDatasetHeader = {} as DataSetHeaders;
        newDatasetHeader.header = h;
        newDatasetHeader.selected = false;
        this.datasetHeaders.push(newDatasetHeader);
      });
    }
    if (this.set == 2) {
      this.loaderId = 'app-data-sets-2'
    }
    this.loader.startLoader(this.loaderId);
    await this.getDataSetsColumns();
    await this.getAllDatasets();
    this.loader.stopLoader(this.loaderId);
  }
 
  async getDataSetsColumns() {
    let response: any = await this._company.getDataSetColumnsSetting(this.set, this.company_id, this.selectUserTypes).toPromise();
    if (response.status == 200) {
      this.datasetSettings = response.data;
    }else{
      this.datasetSettings = [];
    }
  }
  addColumn() {
    var myObject = {} as DataSet;

    myObject.header_id = "";
    myObject.header = "";
    myObject.header_confirmed = false;
    myObject.multiselect_enabled = false;
    myObject.row = [];
    myObject.user_type = this.selectUserTypes;
    this.columns.push(myObject);
  }
  async deleteColumn(index) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: "Are you sure you want to delete entire column?"
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result === "true") {
        const infoToast = this.toastr.info('Deleting Header', 'Please wait', { disableTimeOut: true });
        if (this.columns[index].header_confirmed == true) {
          let response = await this._wound.removeHeaderfromWoundDataSet(this.columns[index].header_id).toPromise();
          if (response['status'] == 200) {
            this.columns.splice(index, 1);
            this.toastr.clear(infoToast.toastId);
            this.toastr.success('Header Deleted Successfully', 'Success');
            this.updateColumnPositions();
          } else {
            this.toastr.clear(infoToast.toastId);
            this.toastr.error('Something went wrong deleting header', 'Failed');
          }
        } else {
          this.columns.splice(index, 1);
          this.toastr.clear(infoToast.toastId);
          this.toastr.success('Header Deleted Successfully', 'Success');
        }
      }
    });
  }
  toggleHeaderState(index) {
    if(this.currentUser.admin_type == 'system_admin'){
      this.columns[index].header_confirmed = !this.columns[index].header_confirmed;
    }
  }
  async ConfirmColumnHeader(index) {
    if (!(this.columns[index].header == "")) {
      if (this.columns[index].header_id == "") {
        const infoToast = this.toastr.info('Adding Header', 'Please wait', { disableTimeOut: true });
        let response = await this._wound.addNewHeaderinWoundDataSet(this.columns[index].header.trim(), this.columns[index].multiselect_enabled, this.set, this.selectUserTypes, this.company_id).toPromise();
        if (response['status'] == 200) {
          const headerIndex = this.datasetHeaders.findIndex((h)=> h.header === this.columns[index].header);
          if(headerIndex > -1){
            this.datasetHeaders[headerIndex].selected = true;
          }
          this.columns[index].header_confirmed = !this.columns[index].header_confirmed;
          this.columns[index].header_id = response['data']['_id'];
          this.toastr.clear(infoToast.toastId);
          this.toastr.success('Header Added Successfully', 'Success');
          this.updateColumnPositions();
        } else {
          this.toastr.clear(infoToast.toastId);
          this.toastr.error('Something went wrong adding header', 'Failed');
        }
      } else {
        const infoToast = this.toastr.info('Updating Header', 'Please wait', { disableTimeOut: true });
        let response = await this._wound.updateHeader(this.columns[index].header.trim(), this.columns[index].multiselect_enabled,this.columns[index].header_id, this.company_id).toPromise();
        if (response['status'] == 200) {
          this.columns[index].header_confirmed = !this.columns[index].header_confirmed;
          this.toastr.clear(infoToast.toastId);
          this.toastr.success('Header Updated Successfully', 'Success');
        } else {
          this.toastr.clear(infoToast.toastId);
          this.toastr.error('Something went wrong', 'Failed');
        }
      }
    }
  }

  toggleRowState(col_index, row_index) {
    if(this.currentUser.admin_type == 'system_admin'){
      this.columns[col_index].row[row_index].row_confirmed = !this.columns[col_index].row[row_index].row_confirmed;
    }
  }
  async ConfirmRow(i, x) {
    if (!(this.columns[i].row[x].value == "")) {
      if ((this.columns[i].row[x].data_type != 'Dropdown') ||
        (this.columns[i].row[x].dropdownTitle != undefined && this.columns[i].row[x].dropdownTitle != null && this.columns[i].row[x].dropdownTitle?.trim() != "")) {
        if (this.columns[i].row[x].row_id == "") {
          const infoToast = this.toastr.info('Adding Row', 'Please wait', { disableTimeOut: true });
          let item = {
            data_type: this.columns[i].row[x].data_type,
            value: this.columns[i].row[x].value,
            dropdownTitle: this.columns[i].row[x].dropdownTitle
          };
          let response = await this._wound.addNewRowinWoundDataSet(this.columns[i].header_id, item).toPromise();
          if (response['status'] == 200) {
            this.columns[i].row[x].row_id = response['row_id'];
            this.columns[i].row[x].row_confirmed = !this.columns[i].row[x].row_confirmed;
            this.toastr.clear(infoToast.toastId);
            this.toastr.success('Row Added Successfully', 'Success');
          } else {
            this.toastr.clear(infoToast.toastId);
            this.toastr.error('Something went wrong adding row', 'Failed');
          }
        } else {
          const infoToast = this.toastr.info('Updating Row', 'Please wait', { disableTimeOut: true });
          let response = await this._wound.updateRowinDataset(this.columns[i].header_id, this.columns[i].row[x].row_id, this.columns[i].row[x].data_type, this.columns[i].row[x].value, this.columns[i].row[x].dropdownTitle, this.company_id).toPromise();
          if (response['status'] == 200) {
            this.columns[i].row[x].row_confirmed = !this.columns[i].row[x].row_confirmed;
            this.toastr.clear(infoToast.toastId);
            this.toastr.success('Row Updated Successfully', 'Success');
          } else {
            this.toastr.clear(infoToast.toastId);
            this.toastr.error('Something went wrong adding row', 'Failed');
          }
        }
      }
      else {
        this.toastr.error("Add Dropdown title...")
      }
    }
    else {
      this.toastr.error("Add Data...")
    }
  }
  addRow(col_index) {
    var rowObject = {} as Row;
    rowObject.value = "";
    rowObject.row_id = "";
    rowObject.row_confirmed = false;
    rowObject.data_type = "Text";
    this.columns[col_index].row.push(rowObject);
  }
  async deleteRow(col_index, row_index) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: "Are you sure you want to delete row?"
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result === "true") {
        const infoToast = this.toastr.info('Deleting Row', 'Please wait', { disableTimeOut: true });
        if (this.columns[col_index].row[row_index].row_confirmed == true) {
          let response = await this._wound.removeRowfromWoundDataSet(this.columns[col_index].header_id, this.columns[col_index].row[row_index].row_id).toPromise();
          if (response['status'] == 200) {
            this.columns[col_index].row.splice(row_index, 1);
            this.toastr.clear(infoToast.toastId);
            this.toastr.success('Row Deleted Successfully', 'Success');
          } else {
            this.toastr.clear(infoToast.toastId);
            this.toastr.error('Something went wrong deleting row', 'Failed');
          }
        } else {
          this.columns[col_index].row.splice(row_index, 1);
          this.toastr.clear(infoToast.toastId);
          this.toastr.success('Row Deleted Successfully', 'Success');
        }
      }
    });
  }
  changetoString(col_index, row_index) {
    if (this.columns[col_index].row[row_index].data_type == 'Date') {
      return this.columns[col_index].row[row_index].value.format('DD/MM/YYYY');
    }
    return this.columns[col_index].row[row_index].value;
  }
  add(event: MatChipInputEvent, col_index, row_index): void {
    const value = (event.value || '').trim();
    if (value) {
      this.columns[col_index].row[row_index].value.push(value);
    }
    // Clear the input value
    event.input.value = '';
  }
  remove(value: string, col_index, row_index): void {
    const index = this.columns[col_index].row[row_index].value.indexOf(value);

    if (index >= 0) {
      this.columns[col_index].row[row_index].value.splice(index, 1);
    }
  }
  changeType(col_index, row_index) {
    if (this.columns[col_index].row[row_index].data_type == 'Date') {
      this.columns[col_index].row[row_index].value = moment(new Date());
    }
    if (this.columns[col_index].row[row_index].data_type == 'Dropdown') {
      this.columns[col_index].row[row_index].value = [];
    }
  }
  showButton() {
    if (this.set == 1) {
      if (this.columns.length < 7) {
        return true;
      } else {
        return false;
      }
    } else {
      if(this.columns.findIndex((ele)=> ele.header_confirmed === false) > -1){
        return false
      }else{
        return true;
      }
    }
  }
  convertValueObjtoDataSet(element){
    let myObject = {} as DataSet;
    myObject.header_id = element['_id'];
    myObject.header = element['header'];
    myObject.multiselect_enabled = element['multiselect_enabled'];
    myObject.header_confirmed = true;
    myObject.user_type = element['user_type'];
    myObject.row = [];
    let dataSetHeaderIndex = this.datasetHeaders.findIndex((h)=>h.header === myObject.header);
    if(dataSetHeaderIndex > -1){
      this.datasetHeaders[dataSetHeaderIndex].selected = true; 
    }
    element.rows.forEach(element => {
      let rowObject = {} as Row;
      if (element['data_type'] == "Date") {
        rowObject.value = moment(element['value']);
      } else {
        rowObject.value = element['value'];
        rowObject.dropdownTitle = element['dropdownTitle'];
      }
      rowObject.row_id = element['_id'];
      rowObject.row_confirmed = true;
      rowObject.data_type = element['data_type'];
      myObject.row.push(rowObject);
    });
    return myObject;
  }
  async getAllDatasets() {
    let response = await this._wound.getAllDatasetinCompany(this.set, this.selectUserTypes, this.company_id).toPromise();
    if (response['status'] == 200) {
      this.dataSet = response['data'];
      this.dataSet.forEach((element: any, index) => {
        const dataSetObj = this.convertValueObjtoDataSet(element)
        this.columns.push(dataSetObj);
      });
      this.reArrangeDataSet(this.datasetSettings);
    }
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    this.updateColumnPositions();

  }
  async updateColumnPositions() {
    let column_ids = this.columns.map(col => col.header_id);
    let response:any = await this._company.updateDataSetColumnsSettings(this.set,this.company_id,column_ids,this.selectUserTypes).toPromise();
    if(response.status == 200){
      console.log(response);
    }
  }
  async updateRowPositions(col) {
    let response = await this._wound.updateRowPositions(col.header_id, col.row).toPromise();
  }
  dropRow(event: CdkDragDrop<string[]>, col) {
    moveItemInArray(col.row, event.previousIndex, event.currentIndex);
    this.updateRowPositions(col)
  }
  dragStart($event, rowDrag = false) {
    if (rowDrag) {
      let divTag = $event.source.dropContainer.element.nativeElement;
      var ele = divTag.getElementsByTagName("button");
      if (ele.length > 0) {
        for (let i = 0; i < ele.length; i++) {
          ele[i].style.display = "none";
        }
      }
    } else {
      let divTag = $event.source.element.nativeElement.children[0].children[0];
      var ele = divTag.getElementsByTagName("button");
      if (ele.length > 0) {
        for (let i = 0; i < ele.length; i++) {
          ele[i].style.display = "none";
        }
      }
    }
  }
  dragEnded($event, rowDrag = false, col) {
    if (rowDrag) {
      let divTag = $event.source.dropContainer.element.nativeElement;
      var ele = divTag.getElementsByTagName("button");
      if (ele.length > 0) {
        for (let i = 0; i < ele.length; i++) {
          ele[i].style.display = "block";
        }
      }
    } else {
      let divTag = $event.source.element.nativeElement.children[0].children[0];
      var ele = divTag.getElementsByTagName("button");
      if (ele.length > 0) {
        for (let i = 0; i < ele.length; i++) {
          ele[i].style.display = "block";
        }
      }
    }
  }
  reArrangeDataSet(colIDs) {
    if (colIDs.length > 0) {
      this.columns = this.columns.sort(function (a, b) {
        return colIDs.indexOf(a.header_id.toString()) - colIDs.indexOf(b.header_id.toString());
      });
    }
  }
  async swapColumn(mainHeaderId, swapColumn){
    swapColumn['dataSet'] = this.set === 1 ? '2' : '1';
    const mainColumn = this.dataSet.find((ele)=> ele._id.toString() === this.columns[mainHeaderId].header_id.toString());
    const infoToast = this.toastr.info('Swapping Header', 'Please wait', { disableTimeOut: true });
    let response = await this._wound.swapColumnswithOther(mainColumn,swapColumn).toPromise();
    const swalHeaderDataSetObj = this.convertValueObjtoDataSet(swapColumn);
    if (response['status'] == 200) {
      this.columns[mainHeaderId] = swalHeaderDataSetObj;
      this.toastr.success('Header Swapped Successfully', 'Success');
    } else {
      this.toastr.clear(infoToast.toastId);
      this.toastr.error('Something went wrong', 'Failed');
    }
  }
  @HostListener('document:click', ['$event'])
  clickOutside(event: MouseEvent) {
    if (this.showMatMenu) {
      this.showMatMenu = false;
    }
  }
  async getDataSetData(header_id){
    this.matMenuLoaderId = header_id
    this.showMatMenu = false;
    let response:any = await this._wound.getAllDatasetinCompany(this.set === 1 ? 2 : 1, this.selectUserTypes, this.company_id).toPromise();
    if (response['status'] == 200) {
      this.swapMenuData = response.data;
      const button = document.getElementById(header_id);
      const buttonRect = button.getBoundingClientRect();
      this.divTop = (buttonRect.bottom + window.scrollY) + 'px';
      this.divLeft = buttonRect.left + 'px';
      this.showMatMenu = true;
    }
  }
}