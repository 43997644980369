<br>
<div class="flex items-center justify-end">
<div style="padding: 0 24px;">
    <button mat-raised-button class="dn-button-primary" (click)="openTemplateDialog();" type="button">Create
        Template</button>
    </div>
</div>
<br>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

    <ng-container matColumnDef="sr">
        <th mat-header-cell *matHeaderCellDef> Sr </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            {{i + 1}}
        </td>
    </ng-container>

    <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef> Title </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            {{element.title}}
        </td>
    </ng-container>

    <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef> Subject </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            {{element.subject}}
        </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef> Created At </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            {{element?.createdAt | date : 'MMM d, y' }}
        </td>
    </ng-container>

    <ng-container matColumnDef="updatedAt">
        <th mat-header-cell *matHeaderCellDef> Updated At </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            {{element?.updatedAt | date : 'MMM d, y' }}
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            <div class="operation-buttons buttons-div flex items-center gap-5">
                <span class="material-icons" (click)="openTemplateDialog(element._id, i)" matTooltip="Edit"
                    matTooltipPosition="above" style="cursor: pointer;">
                    edit_note
                </span>
                <span class="material-icons" matTooltip="Delete" (click)="deleteEmailTemplate(element._id, i)"
                    matTooltipPosition="above" style="cursor: pointer;">
                    delete_outline
                </span>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>