import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-provider-transcriber-association',
  templateUrl: './provider-transcriber-association.component.html',
  styleUrls: ['./provider-transcriber-association.component.css']
})
export class ProviderTranscriberAssociationComponent implements OnInit {

  doctorNameSearchQuery: string;
  public doctors: any = [];
  public transcribers: any = [];
  public responseTrans: any;
  public response: any;
  public dataSource: any;

  // public dataSource2: any;
  public billers: any = [];
  public providers: any = [];
  public providersName: any = [];
  selectedProviders:any;
  providerTranscriber:Boolean= true;
  providerBiller:Boolean = false;
  activatedRoute:any;
  activatedRouter:string = 'provider-transcriber'

  transcriberForm: FormGroup;
  selectedTranscribers: any = [];
  transcriberDropdownSettings: any = {};
  providerDropdownSettings: any = {};
  qaDropdownSettings: any = {};
  qaList = [];
  selectedItems: any = [];
  currentDoctor: string = '';
  swalOptions: any = {};
  
  loaderId = 'company-transcriber-association-loader';


  public constructor(
      public services: UserTypesRelationshipService,
      private toastr: ToastrService,
      private transcriber: FormBuilder,
      private router: Router,
      private loader: NgxUiLoaderService

  ) {
      // this.services.getTranscriberList().subscribe(data => {
      //     this.responseTrans = data;
      //
      //     this.transcribers = this.responseTrans.data;
      //     this.transcribers.forEach(element => {
      //         element['full_name'] = element['first_name'] + ' ' + element['last_name'];
      //     });
      //     this.services.getDoctorsWithRelashionship().subscribe(data => {
      //
      //         this.response = data;
      //         this.doctors = this.response.data;
      //         this.doctors.forEach(doctor => {
      //             var ids = doctor.assoc_trancriber_id;
      //             if (ids && ids.length > 0) {
      //                 var transcribersList = [];
      //                 for (var i = 0; i < ids.length; i++) {
      //                     var trans = this.transcribers.filter(obj => obj._id == ids[i]);
      //                     if (trans.length > 0) {
      //                         transcribersList.push(trans[0]);
      //                     }
      //                     if (i == (ids.length - 1)) {
      //                         doctor['transcribers'] = transcribersList;
      //                     }
      //                 }
      //             } else {
      //                 doctor['transcribers'] = [];
      //             }
      //         });
      //         setTimeout(() => {
      //             this.dataSource = new MatTableDataSource(this.doctors);
      //         }, 100);
      //
      //     });
      //
      //     // https://www.npmjs.com/package/ng-multiselect-dropdown
      //     this.transcriberDropdownSettings = {
      //         singleSelection: false,
      //         idField: '_id',
      //         textField: 'full_name',
      //         selectAllText: 'Select All',
      //         unSelectAllText: 'UnSelect All',
      //         itemsShowLimit: 10,
      //         allowSearchFilter: true,
      //     };
      // });
    
  }

  displayedColumns: string[] = ['doctor_name', 'transcriber_name', 'qa'];
  // displayedColumns2: string[] = ['biller_name', 'provider_name' ];
  @ViewChild(MatSort) sort: MatSort;


  async loadData() {
      return  new Promise((resovle, reject) => {
          this.services.getTranscriberList().subscribe(data => {
              this.responseTrans = data;

              this.transcribers = this.responseTrans.data;
              this.transcribers.forEach(element => {
                  element['full_name'] = element['first_name'] + ' ' + element['last_name'];
                  console.log("element",element['full_name']);
              });  
              
              this.services.getDoctorsWithRelashionship().subscribe(async data => {
                  this.response = data;
                  this.doctors = this.response.data; 
                  console.log("doctor",this.response.data);
                  this.processDoctorData(this.doctors);
                  this.doctors.forEach(doctor => { 
                      var ids = doctor.assoc_trancriber_id;
                      if (ids && ids.length > 0) {
                          var transcribersList = [];
                          for (var i = 0; i < ids.length; i++) {
                              var trans = this.transcribers.filter(obj => obj._id == ids[i]);
                              if (trans.length > 0) {
                                  transcribersList.push(trans[0]);
                              }
                              if (i == (ids.length - 1)) {
                                  doctor['transcribers'] = transcribersList;
                              }
                          }
                      } else {
                          doctor['transcribers'] = [];
                      }
                      resovle(null);
                  });
                  setTimeout(() => {
                      this.dataSource = new MatTableDataSource(this.doctors);
                  }, 100);

              });

              // https://www.npmjs.com/package/ng-multiselect-dropdown
              
              this.qaDropdownSettings = {
                  singleSelection: true,
                  idField: '_id',
                  textField: 'full_name',
                  selectAllText: 'Select All',
                  unSelectAllText: 'UnSelect All',
                  itemsShowLimit: 10,
                  allowSearchFilter: false,
              };
          });
      });
  }


  async ngOnInit() {
      this.loader.startLoader(this.loaderId);

      await this.loadData();
      this.loader.stopLoader(this.loaderId);

  }
  changeRoute()
  { 
      if(this.activatedRouter == 'provider-transcriber'){ 
          this.providerTranscriber = !this.providerTranscriber; 
      }
      else{ 
          this.providerTranscriber = !this.providerTranscriber; 
          // this.router.navigateByUrl('/biller-provider-relation')
          // this.dataSource = [];
          // this.loadDataForBiller();
          // this.getBillerRelations();
      } 
  }

  async processDoctorData(data) { 
      let processed = [];
      for (let i = 0; i < data.length; i++) {
          if (data[i].qa) {
              processed[i] = _.cloneDeep(data[i]);
              processed[i].qaElement = [];
              processed[i].qaElement[0] = processed[i].qa;
          } else {
              processed[i] = _.cloneDeep(data[i]);
          }
          if ((i + 1) === data.length) {
              this.doctors = processed;
              break;
          }
      }
  }

  async changeQA(transcriber, doctor) {
      this.toastr.info('QA change is in progress', 'Updating...', {
          timeOut: 0
      });

      const params = {
          doctor_id: doctor._id,
          transcriber_id: transcriber
      };
      this.services.changeQA(params).subscribe(
          async data => {

              // location.reload();
              await this.loadData();
              this.toastr.success('QA assigned successfully',
                  'Success', {
                  timeOut: 3000
                  });
          },
          error => {

          }
      );
  }

  async selectQA(event, doctor) {

      await this.changeQA(event._id, doctor);
  }

  async removeQA(event, doctor) {
      await this.changeQA(null, doctor);
      // this.swalOptions = {
      //     title: 'Are you sure?',
      //     text: 'You won\'t be able to revert this!',
      //     icon: 'warning',
      //     showCancelButton: true,
      //     confirmButtonColor: '#3085d6',
      //     cancelButtonColor: '#d33',
      //     confirmButtonText: 'Yes, delete it!'
      // };
      // swal.fire(this.swalOptions).then((async result => {
      //     if (result.value) {
      //
      //         await this.changeQA(null, doctor);
      //     } else {
      //         swal.fire(
      //             'Cancelled',
      //             'Your data is safe',
      //             'error'
      //         );
      //     }
      // }));
  }

  onTranscriberSelect(item: any, doctor_id: any) {
      console.log("element: ", item);
      console.log("doctor_id: ", doctor_id);
      
      this.currentDoctor = doctor_id;
  }

  onSelectAllTranscriber(items: any, doctor_id: any) {
      console.log("element: ", items);
      console.log("doctor_id: ", doctor_id);
      this.currentDoctor = doctor_id;
  }

  onTranscriberDeSelect(items: any, doctor_id: any) {
      this.currentDoctor = doctor_id;
  }

  createRelationship(doctor) {
      var transList = [];
      if (doctor.transcribers.length) {
          for (var i = 0; i < doctor.transcribers.length; i++) {
              transList.push(doctor.transcribers[i]._id);
          }
      }
      var updateObject = {
          doctor_id: doctor._id,
          assoc_trancriber_id: transList
      };
      this.services.createRelationship(updateObject).subscribe(data => {
          this.response = data;
          if (this.response.status == 200) {
              this.toastr.success('Success', this.response.message);
          } else {
              this.toastr.error('Failed', this.response.message);
          }
      });

  }

  applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
  } 
  

}
