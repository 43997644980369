import { Component, OnInit, Input, DoCheck, EventEmitter, Output, OnDestroy } from '@angular/core';
// import { PCCService } from 'src/app/services/pcc.service';
// import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatTableDataSource } from '@angular/material/table';
// import { ConditionsService } from './conditions.service';
// import { CensusListService } from '../../census-list/census-list..service';
import { SidebarDiagnosisService } from 'src/app/components/sidebar-addCharges/sidebarDiagnonses/sidebar-diagnoses/sidebar-diagnosis.service';
import { PatientRoundingSheetService } from '../../patient-rounding-sheets/patient-rounding-sheet.service';
import { MatrixConditionsService } from './matrix-diagnosis.service';

@Component({
  selector: 'app-matrix-conditions',
  templateUrl: './matrix-conditions.component.html',
  styleUrls: ['./matrix-conditions.component.css']
})
export class MatrixConditionsComponent implements  OnInit {
  @Input('showCheckboxes') showCheckboxes = false;
  @Input('patient') patient;
  @Input('selectedDiagnoses') selectedDiagnoses;
  @Output() pccDiagnosesChange = new EventEmitter<any>();
  // @Input('pccOrgUuid') pccOrgUuid;
  constructor(
    // private _pccService: PCCService,
    // private toastr: ToastrService,
    private loader: NgxUiLoaderService,
    private matrixConditionsService: MatrixConditionsService,
    private sidebarDiagnosisService: SidebarDiagnosisService,
    private service: PatientRoundingSheetService,
    // private censusListService: CensusListService
    ) { } 
  // pccOrgUuid: string;
  apiData;
  page = 1;
  dataSource;
  pccSelectedDiagnosis: any = [];
  displayedColumns = [
    // "onsetDate",
    // "icd10_checkbox",
    "icd10",
    "icd10Description",
    // "rankDescription",
    // "classificationDescription",
    // "clinicalStatus",
    // "therapy",
    "date",
    // "principalDiagnosis",
    // "conditionId"
  ];

  loaderId = 'conditions-loader';
  inprogress = false;
  selectedSidebarICDs: any[] = [];
 

  ngOnInit(): void {

    // this.pccOrgUuid = this.patient.facility_id_ref.pcc_orgUuid;
    // console.log("this.pccOrgUuid: ", this.pccOrgUuid);
    console.log("patient in matrix diagnosis-------: ", this.patient);

    this.loadData();  

    // this.sidebarDiagnosisService.castSelectedICDs.subscribe((icds => {
      
    //   this.selectedSidebarICDs = icds;
    // }));
  }

  async loadData() {
    this.loader.startLoader(this.loaderId);
    this.apiData = this.patient.matrix_diagnosis;
    let icds = this.apiData.map(a => a.icd10)
    this.service.compareICDs(icds).subscribe((res: any) => {
      if (res.status == 200) {
        let i = 0;
        this.apiData.forEach(p => {
          if (res.data.some(a => a.code == p.icd10)) {
            p.pdpm_status = true;
          }
          else {
            p.pdpm_status = false;
          }
        });
        this.apiData = this.apiData;
        this.dataSource = new MatTableDataSource(this.apiData);
        console.log(this.apiData);
        // this.page = page;
      }
    })


    // let response:any =  await this.matrixConditionsService.getmatrixConditionsService(this.patient._id).toPromise();
    // if(response.status == 200){
    //   this.apiData = response.data;
    //   let icds = this.apiData.map(a => a.icd10)
    //   this.service.compareICDs(icds).subscribe((res: any) => {
    //     if (res.status == 200) {
    //       let i = 0;
    //       this.apiData.forEach(p => {
    //         if (res.data.some(a => a.code == p.icd10)) {
    //           p.pdpm_status = true;
    //         }
    //         else {
    //           p.pdpm_status = false;
    //         }
    //       });
    //       this.apiData = this.apiData;
    //       this.dataSource = new MatTableDataSource(this.apiData);
    //       console.log(this.apiData);
    //       // this.page = page;
    //     }
    //   })
    // }
    this.loader.stopLoader(this.loaderId);
  }


  // navigate(prevNext) {
  //   const newPageNo = this.page + prevNext;
  //   this.loadData();
  // }

  // print() {
  //   return JSON.stringify(this.apiData);
  // }
  // applyFilterICD(value) {
  //   if (this.apiData) {
  //     if (value) {
  //       let filteredData = [];
  //       this.apiData.data.map(a => {
  //         if (a.icd10.toLowerCase().includes(value) || a.icd10Description.toLowerCase().includes(value)) {
  //           filteredData.push(a)
  //         }
  //       })
  //       this.dataSource.data = filteredData;
  //     }
  //     else {
  //       this.dataSource.data = this.apiData.data;
  //     }
  //   }
  // }
  // indexOfSelectedICDCode(code) {
  //   if (Array.isArray(this.selectedSidebarICDs)) {
  //     const index = this.selectedSidebarICDs.map((icd: any) => icd.code.toLowerCase().trim()).indexOf(code.toLowerCase().trim());
  //     return index;
  //   }
  //   return -1;
  // }
  // isICDCodeSelected(code) {
  //   return this.indexOfSelectedICDCode(code) >= 0
  // }
  // async toggleSelectICDCode(code: string, description: string) {
    
  //   const selectedICDCodes = this.selectedSidebarICDs.slice();
  //   const index = this.indexOfSelectedICDCode(code);
  //   // icd.flag = 'pcc';
  //   if (index === -1) {
  //     const details = {
  //       icdCode: code,
  //       description: description,
  //       pcc: true
  //     }
  //     const icdResponse: any = await this.censusListService.addICD(details).toPromise();
  //     if (icdResponse.status === 200) {
  //       const icd = icdResponse.data;
  //       icd.pcc = true;
  //       this.selectedSidebarICDs.push(icd);
  //     }
  //   } else {
  //     this.selectedSidebarICDs.splice(index, 1);
  //   }
  //   this.sidebarDiagnosisService.setSelectedICDs(this.selectedSidebarICDs);
  //   this.pccDiagnosesChange.emit(this.selectedSidebarICDs)
  // }

}
