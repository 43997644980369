import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PatientListService } from '../../patient-list/patient-list-service';
import moment from 'moment'


@Component({
  selector: 'app-patient-edit-dialog',
  templateUrl: './patient-edit-dialog.component.html',
  styleUrls: ['./patient-edit-dialog.component.css']
})
export class PatientEditDialogComponent implements OnInit {

  model: any = {
    censusIdRef: null,
    last_room_num: null,
    lastAdmitDate: moment().toDate(),
    payerName: null,
    primaryPractitionerFirstName: null,
    primaryPractitionerLastName: null,
    primaryPractitionerFullName: null,
    wingNum: null,
    source: null
  }

  minDate = new Date(1900, 0, 1);
  maxDate = new Date();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _patientService: PatientListService,
    private _toastr: ToastrService,
    public dialogRef: MatDialogRef<PatientEditDialogComponent>,
  ) {
    this.model.patientId = this.data._id;
    this.model.censusIdRef = this.data.censusIdRef;
    this.model.lastAdmitDate = this.data?.last_admit_date;
    this.model.payerName = this.data?.non_pcc_payerName;
    this.model.primaryPractitionerFirstName = this.data.primary_practitioner_first_name;
    this.model.primaryPractitionerLastName = this.data.primary_practitioner_last_name;
    this.model.primaryPractitionerFullName = this.model.primaryPractitionerFirstName + ' ' + this.model.primaryPractitionerLastName;
    this.model.source = this.data.source;
    if (this.data.source == 'MatrixCare') {
      this.model.roomNo = this.data.last_room_num;
      this.model.wingNum = this.data?.matrix_payload?.facility_id_pointOfCare;
    }
    else if (this.data.non_pcc_payload) {
      this.model.non_pcc_payload = this.data.non_pcc_payload;
      if (this.data.non_pcc_payload.room_no)
        this.model.roomNo = this.data.non_pcc_payload.room_no;
      // let payload;
      // for (const item of this.data.non_pcc_payload) {
      //   if (item.census_id_ref == this.data.censusIdRef) {
      //     payload = item;
      //   }
      // }
      // if (payload) {
      //   this.model.non_pcc_payload = payload
      //   this.model.roomNo = this.model.non_pcc_payload.room_no;
      // } else {
      //   this.model.roomNo = this.data.non_pcc_payload.room_no;
      // }
    }
  }

  ngOnInit(): void {
  }
  getLastName(str) {
    const indexOfSpace = str.indexOf(' ');
    if (indexOfSpace === -1) {
      return '';
    }
    return str.substring(indexOfSpace + 1);
  }

  updatePatient() {
    this.model.primaryPractitionerFirstName = this.model.primaryPractitionerFullName.split(' ')[0];
    let lastName = this.getLastName(this.model.primaryPractitionerFullName);
    this.model.primaryPractitionerLastName = lastName;
    if (!(this.data.source == 'MatrixCare')) {
      delete this.model.source;
      delete this.model.wingNum;
    }
    this._patientService.updatePatient(this.model).subscribe((response: any) => {
      console.log(response);
      if (response.status === 200) {
        this.dialogRef.close(response.data);
        this._toastr.success('Patient status updated successfully')
      }
    });
  }
}
