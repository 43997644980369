import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PatientRoundingSheetService } from '../../patient-rounding-sheets/patient-rounding-sheet.service';
import { NoteSidebarIcdsComponent } from '../note-addCharge/note-add-charge/note-sidebar-icds/note-sidebar-icds/note-sidebar-icds.component';
import { AuthService } from 'src/app/services/auth.service';
import * as global from "../../../includes/global"

@Component({
  selector: 'app-note-icd-history',
  templateUrl: './note-icd-history.component.html',
  styleUrls: ['./note-icd-history.component.css']
})
export class NoteIcdHistoryComponent implements OnInit {

  dataSource: any = new MatTableDataSource([]);
  loaderId = 'app-note-icd-history';
  @Input() note: any;
  @Input() searchDiagnosis = '';
  @Input('selectedDiagnoses') selectedDiagnoses;
  @Input('fvrtICDs') fvrtICDs;
  @Output() toggleICDsinHistory = new EventEmitter<any>();
  @Output() toggleFvrtICDsinHistory = new EventEmitter<any>();

  icds: any = [];
  allCharges: any = []
  displayedColumns: string[] = ['checkbox', 'code', 'fvrt'];
  selectedSidebarICDs: any[] = [];
  fvrtSidebarICDs: any[] = [];
  searchFilter: any;
  timeOutId: any;
  currentUser: any;
  global = global;
  constructor(
    private loader: NgxUiLoaderService,
    private _toastr: ToastrService,
    private _patientRoundingSheetService: PatientRoundingSheetService,
    private authService: AuthService
  ) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(JSON.stringify(this.selectedSidebarICDs) != JSON.stringify(changes.selectedDiagnoses.previousValue)) {
      this.selectedDiagnoses = changes.selectedDiagnoses.currentValue;
      this.selectedSidebarICDs = this.selectedDiagnoses;
      // this.selectedSidebarICDs.forEach((icd)=>{
      //   if(changes.selectedDiagnoses.previousValue.findIndex((diagnose)=> diagnose._id.toString() === icd._id.toString()) == -1){
      //     this.icds[this.icds.findIndex((d)=> d._id.toString() === icd._id.toString())]['selected'] = false;
      //   }
      // });
      this.initTable();
    }
    if(changes.searchDiagnosis != undefined && changes.searchDiagnosis != null){
      if( changes.searchDiagnosis.previousValue !=undefined ){
        this.searchDiagnosis = changes.searchDiagnosis.currentValue;
        this.searchFilter = this.searchDiagnosis;
        if(this.timeOutId){
          clearTimeout(this.timeOutId);
          this.timeOutId = setTimeout(() => {
            this.filterICDHistory(this.searchFilter)
          }, 2000);
        }else{
          this.timeOutId = setTimeout(() => {
            this.filterICDHistory(this.searchFilter)
          }, 2000);
        }
      }
    }
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    if (this.selectedDiagnoses) {
      this.selectedSidebarICDs = this.selectedDiagnoses;
    }
    if (this.fvrtICDs) {
      this.fvrtSidebarICDs = this.fvrtICDs;
    }
    this.initICDHistory();
  }
  initICDHistory() {
    this.loader.startLoader(this.loaderId);
    const payload = {
      patientId: this.note.patient._id,
      status: null,
      sort: true
    };
    this._patientRoundingSheetService.getCharges(payload).subscribe((response: any) => {
      if (response.status == 200 && Array.isArray(response.data.array) && response.data.array.length) {
        this.allCharges = response.data.array;
        this.initTable();
        this.loader.stopLoader(this.loaderId);
      }
      else {
        this.loader.stopLoader(this.loaderId);
      }
    }, err => {
      this.loader.stopLoader(this.loaderId);
      this._toastr.error(err, "ERROR");
    }
    );
  }
  filterICDHistory(search = "") {
    if(search == ""){
      this.dataSource.data = this.icds;
    }else{
      let filterCPTs = this.icds.filter((diag)=> diag.code.includes(search) || diag.description.includes(search));
      this.dataSource.data = filterCPTs;
    }
  }
  toggleFavouriteDiagnoses(element, action = "add") {
    let data = {
      icd: element,
      action
    }
    if (action == "add") {
      element.fvrt = true;
    } else {
      element.fvrt = false;
    }
    this.toggleFvrtICDsinHistory.emit(data);
  }
  toggleICDSelection(element) {
    this.toggleICDsinHistory.emit(element);
    element.selected = !element.selected;
  }
  icdSelected(element) {
    if (this.selectedSidebarICDs.findIndex(icd => icd.code === element.code) >= 0) {
      element.selected = true;
      return true;
    }else{
      element.selected = false;
      return false;
    }
  }
  initTable(){
    let uniqueICDs = [];
    for (const charge of this.allCharges) {
      if (charge.icd_id?.length > 0) {
        for (const icd of charge.icd_id) {
          let icdExists = false;
          for (const uniqueICD of uniqueICDs) {
            if (uniqueICD.code === icd.code) {
              icdExists = true;
              break;
            }
          }
          if (!icdExists) {
            if (this.fvrtSidebarICDs.findIndex(diagnose => diagnose._id.toString() === icd._id.toString()) > -1) {
              icd["fvrt"] = true;
            } else {
              icd["fvrt"] = false;
            }
            icd['selected'] = false;
            uniqueICDs.push(icd);
          }
        }
      }
    }
    this.icds = uniqueICDs;
    console.log(this.icds);
    this.dataSource.data = this.icds;
  }

  shouldDisableCheckbox() {
    const isSameProvider = this.note.provider._id === this.currentUser._id && this.currentUser.user_type === global.USER_TYPE.DOCTOR;
    const isAssociatedProvider = this.note.provider._id != this.currentUser._id && this.currentUser.user_type === global.USER_TYPE.DOCTOR;
    const isMedicalAssistant = this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT;
    if(isSameProvider || isMedicalAssistant) {
      return false;
    }
    if(isAssociatedProvider) {
      return true;
    }
  }
}
