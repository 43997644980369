<div class="flex flex-col gap-2" style="padding: 10px;">
    <div style="width: 100%;" class="flex items-center justify-between">
            <mat-form-field appearance="fill" style="width: 220px;">
                <mat-label>Report Type</mat-label>
                <mat-select value="all" (selectionChange)="applySourceTypeFilter($event.value)"
                    [(ngModel)]="sourceTypeFilter">
                    <mat-option value="all">All</mat-option>
                    <mat-option value="goal">Goal Status Report (NetHealth)</mat-option>
                    <mat-option value="wfs">Weekly Functional Status Report (NetHealth)</mat-option>
                    <mat-option value="census">Census Details Report (NetHealth)</mat-option>
                    <mat-option value="tens">TENs Report (NetHealth)</mat-option>
                </mat-select>
            </mat-form-field>
    </div>
    <div style="width: 100%; height: 450px;overflow: auto;overflow-x: hidden" *ngIf="sourceTypeFilter == 'census'">
        <app-census-detail-report [patient]="patient"></app-census-detail-report>
    </div>
    <div style="width: 100%; height: 450px;overflow: auto;overflow-x: hidden;" *ngIf="sourceTypeFilter == 'goal'" >
        <app-goal-status-report [patient]="patient"></app-goal-status-report>
    </div>
    <div style="width: 100%; height: 450px;overflow: auto;overflow-x: hidden;" *ngIf="sourceTypeFilter == 'wfs'" >
        <app-weekly-functional-status-report [patient]="patient"></app-weekly-functional-status-report>
    </div>
    <div style="width: 100%; height: 450px;overflow: auto;overflow-x: hidden;" *ngIf="sourceTypeFilter == 'tens'" >
        <app-tens-report [patient]="patient"></app-tens-report>
    </div>
</div>