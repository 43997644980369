import {ViewEncapsulation, Component, OnInit, DoCheck, AfterViewInit, Inject, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import {DatePipe} from '@angular/common';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { RRGenExplorerService } from './rrgen-explorer.service';
import { ToastrService } from 'ngx-toastr';
import {CreateNewFolderDialog} from '../drive/create-new-folder-dialog.component'
import {DeleteConfirmationDialog} from '../drive/delete-confirmation-dialog.component'
import {ShareItemDialog} from '../drive/share-item-dialog.component'
// import {UploadTemplateDialog} from '../drive/upload-template-dialog.component'
import { Router, ActivatedRoute } from '@angular/router';
import {ITemplate, IFolder, IFile, IInsuranceCompany} from './models'
import { DashboardService } from '../dashboard/dashboard.service';
import * as global from '../global';
// import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
// import { DragulaService } from 'ng2-dragula';
import { TranscriptionCreateService } from '../transcription-create/transcription-create.service';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {MatMenuTrigger} from "@angular/material/menu";
import { AppointmentDataService } from 'src/app/components/appointment/service/data.service';
import { UserService } from 'src/app/services/Modules/user.service';
import { FacilityService } from 'src/app/services/facility.service';
import { RRgenExplorerFilesUploadDialog } from './rrgen-explorer-files-upload-dialog/rrgen-explorer-files-upload-dialog.component';
import { ESignService } from 'src/app/components/e-sign/e-sign.service';

// import { DROPZONE_CONFIG, DropzoneComponent } from 'ngx-dropzone-wrapper';
// import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

// const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
//  // Change this to your upload POST address:
//   url: global.url_documents + '/uploadTemplate',
//   maxTemplatesize: 50,
//   clickable:false
// };

@Component({
  selector: 'app-rrgen-explorer',
  templateUrl: './rrgen-explorer.component.html',
  styleUrls: ['./rrgen-explorer.component.css'],
  encapsulation:ViewEncapsulation.None,
  providers:[DatePipe],
  // styleUrls: ['./template-explorer.component.css', '../../../../../node_modules/dropzone/dist/min/dropzone.min.css'],
  // providers: [
    
  //   {
  //     provide: DROPZONE_CONFIG,
  //     useValue: DEFAULT_DROPZONE_CONFIG
  //   }
  // ]
})
export class RRGenExplorerComponent implements OnInit, AfterViewInit, OnDestroy {
  isSharedTemplateExplorer:boolean = false;
  global:any = global;
  displayedColumns:Array<string> = ['title', 'creation_date', 'updation_date'];
  @ViewChild(MatSort) sort: MatSort;  
  // dataSource:MatTableDataSource<ITemplate|IFolder>;
  dataSource:MatTableDataSource<IFile|IInsuranceCompany>;
  // allTemplatesOrFacilities:Array<ITemplate|IFolder> = [];
  allTemplatesOrInsuranceCompanies:Array<IFile|IInsuranceCompany> = [];

  folders:Array<IFolder> = [];
  facilities:Array<IInsuranceCompany> = [];
  templates:Array<ITemplate> = [];
  templatesLoaded:Boolean = false;
  navigating:Boolean = false;

  // IFolder & Payor
  historyStack:Array<any> = [];
  currentUser:any;
  loading:boolean = false;
  header_title:string = "RRGen";

  selectedItem:ITemplate|IFolder = null;
  view_type:string = 'GRID'; // GRID LIST 
  
  showTemplateSidebar:boolean = false;
  isEditingDescription:boolean = false;

  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };

  // DRAGULA_FILES_GROUP = 'DRAGULA_FILES_GROUP';

  public droppedFiles: any[] = [];

  uploadingOptions = {
    date: null,
    provider_id: null,
    location: null
  };

  providers = [];

  locations = [];

  constructor(
    protected dialog: MatDialog, 
    protected rrgenExplorerService:RRGenExplorerService, 
    protected toastr:ToastrService,
    protected route:ActivatedRoute,
    protected router:Router,
    protected dashboardService:DashboardService,
    protected _changeDetectorRefs: ChangeDetectorRef,
    protected _date_pipe:DatePipe,
    protected _transcriptionCreateService:TranscriptionCreateService,
    protected _dashboardService:DashboardService,
    protected appointmentDataService: AppointmentDataService,
    protected userService: UserService,
    protected facilityService: FacilityService,
    protected eSignService: ESignService,

    // private dragulaService: DragulaService
    ) {
     }

  ngOnInit() {
   
    const currentUser = localStorage.getItem('currentUser');
    if(currentUser) {
      this.currentUser = JSON.parse(currentUser);
    }

    this.userService.getUsers({
      user_type: global.USER_TYPE.DOCTOR
    }, {
      _id: 1,
      first_name: 1,
      last_name: 1
    }).subscribe((response:any) => {
      if(response.status === 200) {
        this.providers = response.data;
      }
    });
    this.initFacilities();
  }


  get historyStackTop() {
        const historyStackLength = this.historyStack.length;
        if(historyStackLength > 0) {
          return this.historyStack[historyStackLength-1];
        } else {
          return null;
        }
  }
 async ngAfterViewInit() {
    // if(!this.isSharedTemplateExplorer && !this.dragulaService.find(this.DRAGULA_FILES_GROUP)) {

    //     this.dragulaService.createGroup(this.DRAGULA_FILES_GROUP, {
    //       revertOnSpill:true,
    //       accepts: (el, target, source, sibling) => {
    //         const targetItem = this.folders.find(f => f._id === target.id);
            
    //         return source.id !== target.id && (!!targetItem || target.classList.contains('empty'));
    //       }
    //     });
    //     this.dragulaService.drag(this.DRAGULA_FILES_GROUP).subscribe(e => {
    //       e.el.classList.add('on-drag');
    //     })
    //     this.dragulaService.dragend(this.DRAGULA_FILES_GROUP).subscribe(e => {
    //       e.el.classList.remove('on-drag');
    //     })
    //     this.dragulaService.drop(this.DRAGULA_FILES_GROUP).subscribe((e:any) => {
    //       this.navigating = true;
          
    //       const {id: targetId} = e.target;
    //       const {id: sourceId} = e.source;


    //       const item = this.allTemplatesAndFolders.find(f => f._id === sourceId);

    //       this.folders = this.folders.filter(f => f._id !== sourceId);
    //       this.templates = this.templates.filter(f => f._id !== sourceId);
    //       this.allTemplatesAndFolders = [...this.folders, ...this.templates];
          
    //       e.el.style.display = 'none';
    //       this.changeParentFolderId(item,targetId);

    //     });
    // }
    console.log('ngAfterViewInit');
    
    
      try {
        const insuranceCompanies = await this.fetchInsuranceCompanies();
        this.initializeInsuranceCompanies(insuranceCompanies);
    
        
      } catch (error) {
        console.log(error.toString());
    
        
      }

      // const dropZoneDiv:HTMLElement = document.querySelector('.ngx-template-drop__drop-zone');
      // dropZoneDiv.classList.add('dismis-template-selection');
 }
 
 ngOnDestroy(): void {
   this.toastr.clear()
}

initFacilities() {
  // this.facilityService.getFacilities().subscribe((response:any) => {
  //   this.locations = response.data.array.map(e => e.title);
  // })
}

 ownedItem(item:ITemplate|IFolder) {
  return item.owner_id === this.currentUser._id
 }

 initializeInsuranceCompanies(insuranceCompanies) {

  
  insuranceCompanies.sort((a, b) => a.abbreviation > b.abbreviation ? 1 : -1);
  this.allTemplatesOrInsuranceCompanies = insuranceCompanies;


  setTimeout(() => {
    this.dataSource = new MatTableDataSource(this.allTemplatesOrInsuranceCompanies);
    this.dataSource.sort = this.sort;
    this._changeDetectorRefs.detectChanges();
  });
    // this._changeDetectorRefs.detectChanges();
  this.templatesLoaded = true;  
 }
 initializeItems(fileFolderItems) {
  const [item] = fileFolderItems;
  if(this.historyStack.length === 0) {
    // 0 - Companies
     fileFolderItems.sort((a, b) => a.abbreviation > b.abbreviation ? 1 : -1);
  } else if(this.historyStack.length === 1) {
    const company = this.historyStackTop;
   // 1 - Providers
    fileFolderItems.sort((a, b) => a.title > b.title ? 1 : -1);
    //  this.uploadingOptions.provider_id = null;
   } 
   else if(this.historyStack.length === 2) {
    const provider = this.historyStackTop;
    this.uploadingOptions.provider_id = provider.rrgen_metadata.provider;
    
    // 2 - Date + Locations
    fileFolderItems.sort((a, b) => {
      const dateA = new Date(a.rrgen_metadata.date);
      const dateB = new Date(b.rrgen_metadata.date);

      return dateA.getTime() - dateB.getTime();
    });
    
    // this.uploadingOptions.location = null;
    // this.uploadingOptions.date = null;
   } else if(this.historyStack.length === 3) {
    const dateLocation = this.historyStackTop;
    const [, month, date, location] = /(\d+)-(\d+)\s(.+)/.exec(dateLocation.title);
    this.uploadingOptions.location = location;
    this.uploadingOptions.date = new Date();
    this.uploadingOptions.date.setMonth(parseInt(month)-1, parseInt(date))

     // 3 - Dropped Folder
    fileFolderItems.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);

      return dateA.getTime() - dateB.getTime();
    });
   } else {
    fileFolderItems.sort((a, b) => a.title > b.title ? 1 : -1);
   }
  
  this.allTemplatesOrInsuranceCompanies = fileFolderItems;


  setTimeout(() => {
    this.dataSource = new MatTableDataSource(this.allTemplatesOrInsuranceCompanies);
    this.dataSource.sort = this.sort;
    this._changeDetectorRefs.detectChanges();
  });
    // this._changeDetectorRefs.detectChanges();
  this.templatesLoaded = true;  
 }
 /*
 initializeTemplatesAndFolders(templatesAndFolders) {

  let folders:Array<IFolder> = [];
  let templates:Array<ITemplate> = [];

  folders = templatesAndFolders.folders;
  folders.sort((a, b) => a.title > b.title ? 1 : -1);

  templates = templatesAndFolders.templates;
  templates.sort((a, b) => a.title > b.title ? 1 : -1);



  this.folders = folders;
  this.templates = templates;
  this.allTemplatesAndFolders = [...folders, ...templates];
  setTimeout(() => {
    this.dataSource = new MatTableDataSource(this.allTemplatesAndFolders);
    this.dataSource.sort = this.sort;
    this._changeDetectorRefs.detectChanges();
  });
    // this._changeDetectorRefs.detectChanges();
  this.templatesLoaded = true;  
 }
*/
 
async fetchInsuranceCompanies() {
  return new Promise(async (resolve,reject) => {
    try {
      //  this.loading = true;
      this.navigating = true;
      // if(item) this.historyStack.push(item);
       
      //  const templatesAndfoldersResponse:any = await this.templateExplorerService.getMyTemplatesAndFolders(item ? item._id : '').toPromise();
      let response:any = await this.appointmentDataService.getPayors().toPromise();
      // if(this.currentUser.user_type == global.USER_TYPE.DOCTOR) {
      //   response = await this._transcriptionCreateService.getFacilities(this.currentUser._id).toPromise();
      // } else {
      //   response = await this._dashboardService.getAssociatedFacilityOfTranscriber().toPromise();
      // }
      if(response.status === 200) {
        resolve(response.data.array.map(payor => {
          payor.title = payor.abbreviation;
          return payor;
        }));
      } else {
        reject(response.message)
      }

    } catch(err) {
      reject(err);
    } finally {
      this.navigating = false;
    }

   })
 }

 async fetchTemplates(facility_id) {
  //  this.loading = true;
   return new Promise(async (resolve,reject) => {
     try {
      this.navigating = true;
      // if(item) this.historyStack.push(item);
       
      //  const templatesAndfoldersResponse:any = await this.templateExplorerService.getMyTemplatesAndFolders(item ? item._id : '').toPromise();
      
      let response:any = await this.rrgenExplorerService.getTemplatesByFacilityId(facility_id).toPromise();
      if(response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.message)
      }

    } catch(err) {
      reject(err);
    } finally {
      this.navigating = false;
    }

   })
 }

//  async fetchTemplatesAndFolders(item:IFolder=null) {
//   //  this.loading = true;
//    return new Promise(async (resolve,reject) => {
//      try {
//       this.navigating = true;
//       if(item) this.historyStack.push(item);
       
//        const templatesAndfoldersResponse:any = await this.templateExplorerService.getMyTemplatesAndFolders(item ? item._id : '').toPromise();

//        if(templatesAndfoldersResponse.status === 200) {
//          this.initializeTemplatesAndFolders(templatesAndfoldersResponse.data.array);
//        }

//       resolve(null);
//     } catch(err) {
//       reject(err);
//     } finally {
//       // this.loading = false;
//       this.navigating = false;
//      };
//    })
//  }
 
  async fetchFolders(parent_folder_id) {
    //  this.loading = true;
    return new Promise(async (resolve, reject) => {
      try {

        this.navigating = true;

        // if(item) this.historyStack.push(item);

        //  const templatesAndfoldersResponse:any = await this.templateExplorerService.getMyTemplatesAndFolders(item ? item._id : '').toPromise();

        let response: any = await this.rrgenExplorerService.getFilesAndFoldersByParentFolderId(parent_folder_id).toPromise();
        if (response.status === 200) {
          resolve(response.data.array);
        } else {
          reject(response.message)
        }

      } catch (err) {
        reject(err);
      } finally {
        this.navigating = false;
      }

    })
  }
 
  isEmpty() {
    return this.allTemplatesOrInsuranceCompanies.length === 0;
  }

  async upOneLevel() {
    if(this.navigating) return;
    this.historyStack.pop(); // discard current folder
    // this.fetchTemplatesAndFolders(this.historyStack.pop()); // pop the previous folder

    
    if(this.historyStack.length === 0) {
      
    const facilities = await this.fetchInsuranceCompanies();
    this.initializeInsuranceCompanies(facilities);
    } else {

      const items = await this.fetchFolders(this.historyStackTop._id) as {
        files: IFile[],
        folders:IFolder[]
      };
      console.log(items);
      
      this.initializeItems([...items.folders, ...items.files]);
    }
    this.selectedItem = null;
    
    if(this.historyStack.length === 1) {
      // 1 - Providers
       this.uploadingOptions.provider_id = null;
     } 
     else if(this.historyStack.length === 2) {
      // 2 - Date + Locations
      this.uploadingOptions.location = null;
      this.uploadingOptions.date = null;
     } 
  }

  onFolderClick(folder:IFolder) {
    if(this.navigating) return;
    // uncomment
    this.toggleTemplateSelection(folder);
    // this.fetchTemplatesAndFolders(folder);  
  }
  
  onTemplateClick(template:ITemplate) {
    if(this.navigating) return;
    // uncomment
    this.toggleTemplateSelection(template);
    // this.downloadTemplate(template);
    
  }
  onFileClick(file: IFile) {
    if (this.navigating) {
        return;
    }
    // uncomment
    this.toggleTemplateSelection(file);
    // this.downloadFile(file);

}
  // uncomment
  onFileDoubleClick(file: IFile) {
    this.downloadFile(file);
  }
  async onFolderDoubleClick(folder:any) {
    // this.fetchTemplatesAndFolders(folder);
    this.historyStack.push(folder);
    const items = await this.fetchFolders(folder._id) as {
      files: IFile[],
      folders:IFolder[]
    };
    
    this.initializeItems([...items.folders, ...items.files]);
    this.selectedItem = null;
  }
  async downloadFile(file: IFile) {
    const infoToast = this.toastr.info('Preparing file to download...', 'Please wait', {disableTimeOut: true});
    try {
        var url =<string>file.file_path ;
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = <string>file.title;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element

    } catch (error) {
      console.log(error);
      
        this.toastr.error('Unable to download file.', 'Failed');
    } finally {
        this.toastr.clear(infoToast.toastId);
    }
}
 toggleTemplateSelection(thisItem:ITemplate|IFolder) {
    // const index = this.selectedTemplates.indexOf(item);
    // if(index === -1) {
    //   item.selected = true;
    //   this.selectedTemplates.push(item);
    // } else {
    //   item.selected = false;
    //   this.selectedTemplates.splice(index,1);
    // }
    
    this.selectedItem = thisItem;
    if(this.selectedItem.selected) {
      this.selectedItem.selected = false;
      this.selectedItem = null;
    } else {
      this.allTemplatesOrInsuranceCompanies.forEach((item:IFile|IInsuranceCompany)=> {
        item.selected = (item === this.selectedItem);
      });
      // this.getDriveActivity(this.selectedItem);
    }
  }
  dismisTemplateSelection() {
    if(this.selectedItem) {
      this.selectedItem.selected = false;
      this.selectedItem = null;
      this.showTemplateSidebar = false;
    }
    // this.allTemplatesAndFolders.filter((item:ITemplate|IFolder) => {
    //   item.selected = false;
    // });
  }
  onTemplateContainerClick(e) {
    const classList = Array.from(e.target.classList);
    if(classList.includes('dismis-file-selection') || classList.includes('mat-figure') ) {
      this.dismisTemplateSelection();
    }
  }
  toggleTemplatesView() {
    this.dismisTemplateSelection();
    if(this.view_type === 'GRID') {
      this.view_type = "LIST";
    } else {
      this.view_type = "GRID";
    }
  }
  
  onItemContextMenu(event: MouseEvent, item: ITemplate|IFolder) {
    // event.preventDefault();
    // this.contextMenuPosition.x = event.clientX + 'px';
    // this.contextMenuPosition.y = event.clientY + 'px';
    // this.contextMenu.menuData = { 'item': item };
    // this.contextMenu.openMenu();
    // uncomment
    // if(item !== this.selectedItem) this.toggleTemplateSelection(item);
  }

  onRenameItemClick(contextMenuData:IFolder) {    
    const dialogRef = this.dialog.open(CreateNewFolderDialog, {width: '500px', data: {action:'rename', title: contextMenuData.title}});
    dialogRef.afterClosed().subscribe( (title) => {
      if(!title) return;
      this.renameFolder(contextMenuData, title);
    })
  }

  onShareItemClick(contextMenuData:ITemplate|IFolder) {   
    // const dialogRef = this.dialog.open(ShareItemDialog, {width: '500px', data: {item: contextMenuData}});
    // dialogRef.afterClosed().subscribe( (emails:String[]) => {
    //   if(!emails) return;
    //   this.shareItem(contextMenuData, emails);
    // })
  }
  onDeleteItemClick(contextMenuData:ITemplate|IFolder) {
    const dialogRef = this.dialog.open(DeleteConfirmationDialog, {width: '500px', data: {title:(<ITemplate>contextMenuData).title || (<IFolder>contextMenuData).title}});
    dialogRef.afterClosed().subscribe( (result) => {
      if(!result) return;
      // if((<ITemplate>contextMenuData).template_text) {
      //   this.deleteTemplate(<ITemplate>contextMenuData);
      // } else {
      //   this.deleteFolder(<IFolder>contextMenuData);
      // }
      this.deleteItem(contextMenuData);
    })
  }
  onDownloadTemplateClick(contextMenuData:ITemplate) {
    this.downloadTemplate(contextMenuData);
  }

  async shareItem(item:ITemplate|IFolder, emails:String[]) {
    // const infoToast = this.toastr.info('Processing...', 'Please wait', {disableTimeOut:true});
    // let item_type:String;
    // if((<ITemplate>item).template_text) {
    //   item_type = 'template';
    // } else {
    //   item_type = 'folder';
    // }
    // 
    // const shareItemResponse:any = await this.templateExplorerService.shareItem(item._id,emails, item_type).toPromise();

    // if(shareItemResponse.status === 200) {
    //   // sending emails to newly added emails
    //   const emailSubject = `${this.currentUser.first_name} ${this.currentUser.last_name} shared ${item.title}`;
    //   const emailBody = `<strong>${this.currentUser.first_name} ${this.currentUser.last_name}</strong> has shared ${item_type} <strong>${item.title}</strong> with you. <a href="https://doctornow.io">Click Here</a> to view.`;
    //   for(let email of emails) {
    //     if(!item.shared_by_email.includes(email)) {
    //       var emailDetails = {
    //         email: email,                  
    //         text:emailBody,
    //         subject:emailSubject
    //       };
    //       await this.dashboardService.sendNewDictaionRequestEmail(emailDetails).toPromise();
    //     }
    //   }

    //   await this.reload();
    // } else {
    //   this.toastr.error(shareItemResponse.message, 'Failed');
    // }
    // this.toastr.clear(infoToast.toastId);
  }

  async downloadTemplate(template:ITemplate) {
    const infoToast = this.toastr.info("Preparing template to download...", 'Please wait',{disableTimeOut:true});
    try {
      const downloadTemplateResponse:any = await this.rrgenExplorerService.downloadTemplate(template._id).toPromise();
      var url = window.URL.createObjectURL(downloadTemplateResponse);
      var a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = <string>template.title;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove(); // remove the element
      
    } catch(error) {
      this.toastr.error("Unable to download template.", "Failed");
    } finally {
      this.toastr.clear(infoToast.toastId);
    }
  }
  
  async createNewFolder(folder:any) {
    return new Promise( async (resolve, reject) => {
      const infoToast = this.toastr.info('Creating New Folder...', 'Please wait', {disableTimeOut:true, positionClass:'toast-bottom-right'});
      const createFolderResponse:any = await this.rrgenExplorerService.createNewFolder(folder).toPromise();
      if(createFolderResponse.status === 200) {
        await this.reload(); 
        resolve(createFolderResponse.data);
      } else {
        this.toastr.error(createFolderResponse.message, 'Failed');
        reject(createFolderResponse.message);
      }
      this.toastr.clear(infoToast.toastId);
    });
  }
  // async deleteFolder(folder:IFolder) {
  //   const infoToast = this.toastr.info('Deleting Folder...', 'Please wait', {disableTimeOut:true});
  //   const deleteFolderResponse:any = await this.templateExplorerService.deleteFolder(folder._id).toPromise();
  //   if(deleteFolderResponse.status === 200) {
  //     await this.reload();
  //   } else {
  //     this.toastr.error(deleteFolderResponse.message, 'Failed');
  //   }
  //   this.toastr.clear(infoToast.toastId);
  // }
  async deleteTemplate(template:ITemplate) {
    const message = 'Are you sure you want to delete this template?'
    const dialogBoxValue = await this.dashboardService.DeleteConfirmationDialog(message);
    if(dialogBoxValue === 'true') {
    const infoToast = this.toastr.info('Deleting Template...', 'Please wait', {disableTimeOut:true});
    const deleteTemplateResponse:any = await this.rrgenExplorerService.deleteTemplate(template._id).toPromise();
    if(deleteTemplateResponse.status === 200) {
      await this.reload();
      this.toastr.success('Deleted SuccessFully');
    } else {
      this.toastr.error(deleteTemplateResponse.message, 'Failed');
     }
     this.toastr.clear(infoToast.toastId);
     }else{
         () => this.toastr.error('Something went wrong while removing template', 'Failed')
     }
  }

    async deleteItem(item:ITemplate|IFolder) {
    // const infoToast = this.toastr.info('Deleting...', 'Please wait', {disableTimeOut:true});
    // const item_type = (<ITemplate> item).template_text ? 'template' : 'folder';
    // const deleteItemResponse:any = await this.templateExplorerService.deleteItem(item._id, item_type).toPromise();
    // if(deleteItemResponse.status === 200) {
    //   await this.reload();
    // } else {
    //   this.toastr.error(deleteItemResponse.message, 'Failed');
    // }
    // this.toastr.clear(infoToast.toastId);
  }
  async renameFolder(folder:IFolder, new_folder_title) {
    // const infoToast = this.toastr.info('Renaming Folder', 'Please wait', {disableTimeOut:true});
    // const renameFolderResponse:any = await this.templateExplorerService.renameFolder(folder._id, new_folder_title).toPromise();
    // if(renameFolderResponse.status === 200) {
    //   await this.reload();
    // } else {
    //   this.toastr.error(renameFolderResponse.message, 'Failed');
    // }
    // this.toastr.clear(infoToast.toastId);
  }
  async changeParentFolderId(item:IFolder, parent_folder_id) {
  //  const item_type = 'folder';
  //   const changeParentFolderIdResponse:any = await this.templateExplorerService.changeParentFolderId(item._id, parent_folder_id, item_type).toPromise();
  //   if(changeParentFolderIdResponse.status === 200) {
  //     await this.reload();
  //   } else {
  //     this.toastr.error(changeParentFolderIdResponse.message, 'Failed');
  //   }
  }

  async uploadTemplate(templateBlob:any, templateData:ITemplate = null) {
    // const parent_folder_id = this.historyStackTop === null ? '' : this.historyStackTop._id;
    
    // const template:ITemplate = templateData ? templateData : {
    //   _id: '',
    //   parent_folder_id: parent_folder_id,
    //   title: templateBlob.name,
    //   creation_date: this._date_pipe.transform(new Date().toString(), global.date_time_format),
    //   updation_date: this._date_pipe.transform(new Date().toString(), global.date_time_format),
    //   owner_id: this.currentUser._id,
    //   owner_name: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
    //   mime_type: templateBlob.type,
    //   shared_by_email: [],
    //   is_deleted: 'false',
    //   template_text: null // will be replaced with aws template path
    // }

    // const infoToast = this.toastr.info(templateBlob.name, 'Uploading Template', {tapToDismiss:false, disableTimeOut:true,positionClass:'toast-bottom-right'});
    // const uploadTemplateResponse:any = await this.templateExplorerService.uploadTemplate(template, templateBlob);
    // if(uploadTemplateResponse.status === 200) {
    //   await this.reload();
    // } else {
    //   this.toastr.error(uploadTemplateResponse.message, 'Failed');
    // }
    // this.toastr.clear(infoToast.toastId);

  }


  
  async reload() {
    new Promise(async (resolve, reject) => {
      try {
        if(this.historyStackTop) {

          const items = await this.fetchFolders(this.historyStackTop._id) as {
            files: IFile[],
            folders:IFolder[]
          };
          
          const result = await this.initializeItems([...items.folders, ...items.files]);
  
          this.selectedItem = null;
          resolve(result); 
        } else {
          
        const insuranceCompanies = await this.fetchInsuranceCompanies();
        this.initializeInsuranceCompanies(insuranceCompanies);
        resolve(insuranceCompanies);
        }
      } catch(error) {
        reject(error);
      }
    })
  }

  onCreateNewFolderClick() {
    // const dialogRef = this.dialog.open(CreateNewFolderDialog, {width: '500px', data: {action:'create'}});
    // dialogRef.afterClosed().subscribe( (title) => {
    //   if(!title) return;
    //   const parent_folder_id = this.historyStackTop === null ? '' : this.historyStackTop._id;
    //   const folder:IFolder = {
    //     _id: '',
    //     parent_folder_id: parent_folder_id,
    //     title:title,
    //     creation_date: this._date_pipe.transform(new Date().toString(), global.date_time_format),
    //     updation_date: this._date_pipe.transform(new Date().toString(), global.date_time_format), 
    //     owner_id:this.currentUser._id,
    //     owner_name: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
    //     // shared_by_email:[],
    //     is_deleted:'false'
    //   };
    //   this.createNewFolder(folder)
    // })
  }

  onUploadTemplateClick() {
    // const dialogRef = this.dialog.open(UploadTemplateDialog, {width: '500px'});
    // dialogRef.afterClosed().subscribe( (templateInput) => {
    //   if(!templateInput || (templateInput && templateInput.templates.length === 0)) return;
    //   const templateBlob:Template = templateInput.templates[0];
    //   this.uploadTemplate(templateBlob);
    // })
  }

  onSharedByMeClick() {
    // this.router.navigate(['shared-with-me'], {relativeTo: this.route})
  }
  
  getItemIcon(item) {
    if (item.file_path) {
      const { title } = item;
      const extension = title.substr(title.lastIndexOf('.') + 1);
      const filetypeIconsPath = '../../../assets/img/filetypes';

      return (`${filetypeIconsPath}/${extension}.svg`);
    } else {
      // folder
      return (`../../../assets/img/folder-icon.png`);
    }
  }

  
  async updateItemDescription(item, description) {
    this.isEditingDescription = false;
    // this.loading = true;
    item.updation_date = new Date();
    try {
      let item_type;
      if(item.template_text) {
        item_type = 'template';
      } else {
        item_type = 'folder';
      }
      await this.rrgenExplorerService.updateItemDescription(item._id, description, item_type).toPromise();
    } catch(err) {
      console.error(err)
    } finally {
      // this.loading = false;
    }
  }
//  async getDriveActivity(item) {
//     try {
//      const driveActivityResponse:any = await this.templateExplorerService.getDriveActivity(item._id).toPromise();
//      if(driveActivityResponse.status === 200) {
//        item.drive_activity = driveActivityResponse.data.array.map(activity => {
//          activity.activity_description = activity.activity_description.replace(`${this.currentUser.first_name} ${this.currentUser.last_name}`, 'You').replace(this.currentUser.email, 'You');
//          return activity;
//        });
//      }
//     } catch(err) {
//       console.error(err)
//     } finally {
//       // this.loading = false;
//     }
//   }
 

  public arrangeIntoTree(paths) {
    // Adapted from http://brandonclapp.com/arranging-an-array-of-flat-paths-into-a-json-tree-like-structure/
    
    const findWhere = (array, key, value) => {
        // Adapted from https://stackoverflow.com/questions/32932994/findwhere-from-underscorejs-to-jquery
      let t = 0; // t is used as a counter
        while (t < array.length && array[t][key] !== value) { t++; }; // find the index where the id is the as the aValue

        if (t < array.length) {
            return array[t]
        } else {
            return false;
        }
    }
    var tree = [];

    for (var i = 0; i < paths.length; i++) {
        var path = paths[i];
        var currentLevel = tree;
        for (var j = 0; j < path.length; j++) {
            var part = path[j];

            var existingPath = findWhere(currentLevel, 'name', part);

            if (existingPath) {
                currentLevel = existingPath.children;
            } else {
                var newPart = {
                    name: part,
                    children: [],
                }

                currentLevel.push(newPart);
                currentLevel = newPart.children;
            }
        }
    }
    return tree;
  }

  showFilesUploadDialog(filesObject) {
    const dialogRef = this.dialog.open(RRgenExplorerFilesUploadDialog, {width: '980px', data: {filesObject}});
    dialogRef.afterClosed().subscribe(async (filesData) => {
      if(!filesData) return;      
      let foldersUploaded = 0;
      const totalFolders = Object.values(filesObject).length;
      let percentage = 0;
      for (const folderName in filesObject) {
        let infoToast = this.toastr.info(`Uploading (${foldersUploaded+1} of ${totalFolders}). `, `${folderName}`, { disableTimeOut: true, tapToDismiss: false, progressBar: true, progressAnimation: 'increasing',enableHtml: true });

        const esignFiles = [];
        const rrgenFiles = [];
        for (const file of filesObject[folderName]) {
          const fileObject = {
            file,
            fileData: filesData[file.name]
          }
          if(fileObject.fileData.type === 'Sign Form') {
            esignFiles.push(fileObject)
          } else {
            rrgenFiles.push(fileObject)
          }
        }
        // @ts-ignore
        await Promise.all(
          [
            await this.rrgenExplorerService.createFolderAndUploadFilesToRRGen(this.historyStack[0]._id, folderName, rrgenFiles, this.uploadingOptions),
            await this.eSignService.uploadFiletoSign(this.uploadingOptions.provider_id, esignFiles)
          ]
        )
        await this.reload();
        this.toastr.success(`${folderName} uploaded successfully`, '', {timeOut:1000});
        this.toastr.clear(infoToast.toastId);
        foldersUploaded++;
        percentage = ((foldersUploaded/totalFolders)*100)


      }


    })
  }

  public async dropped(droppedFiles: any[]) {
    
    if(this.historyStack.length === 0 || this.historyStack.length >= 4) {
      return this.toastr.error('Folder cannot be uploaded here')
    }  else {

      if(!this.uploadingOptions.provider_id) {
        return this.toastr.error('Please select provider and try again.')
      } 
      
      if(!this.uploadingOptions.date) {
        return this.toastr.error('Please select date and try again.')
      }
      
      if(!this.uploadingOptions.location) {
        return this.toastr.error('Please select location and try again.')
      }
    }
    
    

    this.droppedFiles = droppedFiles;
    const filesObject = {};
    for (let i = 0; i < this.droppedFiles.length; i++) {
      const element = this.droppedFiles[i];
      const [folderName] = element.relativePath.split('/').filter(_ => _);
      if(!filesObject[folderName]) {
        filesObject[folderName] = [];
      }

      // const fileEntry = element.fileEntry as FileSystemFileEntry;
      const fileEntry = element.fileEntry;
      const fileEntryFile:any = await new Promise((resolve, reject) => {
        try {
          fileEntry.file(resolve)
        } catch (error) {
          reject(error);
        }
      });

      filesObject[folderName].push(fileEntryFile);

    }
    // const docFile = largeFile.fileEntry  as FileSystemFileEntry;
    if (this.historyStack[0]) {

      this.showFilesUploadDialog(filesObject);

    }
  }
 
  public templateOver(event){
  }
 
  public templateLeave(event){
  }

}

