import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { WoundService } from 'src/app/services/wound.service';
import { PatientRoundingSheetService } from '../../patient-rounding-sheets/patient-rounding-sheet.service';
import * as global from "../../../includes/global"

@Component({
  selector: 'app-note-cpt-procedure-codes',
  templateUrl: './note-cpt-procedure-codes.component.html',
  styleUrls: ['./note-cpt-procedure-codes.component.css']
})
export class NoteCptProcedureCodesComponent implements OnInit {
  dataSource: any = new MatTableDataSource([]);
  loaderId = 'app-note-cpt-procedure-codes';
  @Input() note: any;
  @Input() selectedCharges: any;
  @Input() fvrtCPTs: any;
  @Input() searchCharges = '';
  @Output() toggleFvrtCPTsinHistory = new EventEmitter<any>();
  @Output() toggleCPTsinHistory = new EventEmitter<any>();
  selectedCPTCodes: any = [];
  fvrtCPTCodes: any = [];
  allProcedureTypes: any = [];
  selectedProcedureTypeCPTS: any = [];
  selectedProcedureType: any;
  displayedColumns: string[] = ['checkbox', 'code', 'fvrt'];
  searchFilter = '';
  timeOutId: any;
  currentUser: any;
  global = global;
  constructor(
    private loader: NgxUiLoaderService,
    private _toastr: ToastrService,
    private _patientRoundingSheetService: PatientRoundingSheetService,
    private _woundService: WoundService,
    private _authService: AuthService
  ) { }
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.selectedCharges != undefined && changes.selectedCharges != null) {
      if (JSON.stringify(this.selectedCPTCodes) != JSON.stringify(changes.selectedCharges.previousValue) && changes.selectedCharges.previousValue != undefined) {
        this.selectedCharges = changes.selectedCharges.currentValue;
        this.selectedCPTCodes = this.selectedCharges;
        this.initPRocedureTypesChanges();
      }
    }
    if (changes.searchCharges != undefined && changes.searchCharges != null) {
      if (changes.searchCharges.previousValue != undefined) {
        this.searchCharges = changes.searchCharges.currentValue;
        this.searchFilter = this.searchCharges;
        if (this.timeOutId) {
          clearTimeout(this.timeOutId);
          this.timeOutId = setTimeout(() => {
            this.filterCPTHistory(this.searchFilter)
          }, 2000);
        } else {
          this.timeOutId = setTimeout(() => {
            this.filterCPTHistory(this.searchFilter)
          }, 2000);
        }
      }
    }
  }
  async ngOnInit(): Promise<void> {
    this.currentUser = this._authService.currentUser;
    if (this.selectedCharges) {
      this.selectedCPTCodes = this.selectedCharges;
    }
    if (this.fvrtCPTs) {
      this.fvrtCPTCodes = this.fvrtCPTs;
    }
    await this.initAllProcedureTypes();
  }
  initPRocedureTypesChanges() {
    if (this.selectedProcedureType?.relation?.cpt_id_ref) {
      this.selectedProcedureTypeCPTS = this.selectedProcedureType?.relation?.cpt_id_ref;
      this.selectedProcedureTypeCPTS.forEach((fvrt) => {
        if (this.fvrtCPTCodes.findIndex(charges => charges._id.toString() === fvrt._id.toString()) > -1) {
          fvrt["fvrt"] = true;
        } else {
          fvrt["fvrt"] = false;
        }
        if (this.selectedCPTCodes.findIndex(charges => charges._id.toString() === fvrt._id.toString()) > -1) {
          fvrt["selected"] = true;
        }
        else {
          fvrt["selected"] = false;
        }
      });
      this.dataSource.data = this.sortCodes(this.selectedProcedureTypeCPTS);
    } else {
      this.dataSource.data = [];
    }
  }
  async initAllProcedureTypes() {
    this.loader.startLoader(this.loaderId);
    let payload = {
      company_id: this._authService.currentUser.company_id
    }
    let response: any = await this._woundService.getProcedureTypeswithCPTRelation(payload).toPromise();
    if (response.status == 200) {
      this.allProcedureTypes = response.data.array;
      this.selectedProcedureType = this.allProcedureTypes[0];
      if (this.selectedProcedureType?.relation?.cpt_id_ref) {
        this.selectedProcedureTypeCPTS = this.selectedProcedureType?.relation?.cpt_id_ref;
        this.selectedProcedureTypeCPTS.forEach((fvrt) => {
          if (this.fvrtCPTCodes.findIndex(charges => charges._id.toString() === fvrt._id.toString()) > -1) {
            fvrt["fvrt"] = true;
          } else {
            fvrt["fvrt"] = false;
          }
        });
        this.dataSource.data = this.sortCodes(this.selectedProcedureTypeCPTS);
      } else {
        this.dataSource.data = [];
      }
      this.loader.stopLoader(this.loaderId);
    }
  }

  sortCodes(data:any){
    console.log("datadata : ",data);

    return data.sort((a, b) => {
      if((b.code == '' || b.code == null || b.code == undefined)){ 
        if (a.code < 'ZZZZZ9999') {
          return -1;
        }
        if (a.code > 'ZZZZZ9999') {
          return 1;
        }
      }
      if((a.code == '' || a.code == null || a.code == undefined)){ 
        if ('ZZZZZ9999' < b.code) {
          return -1;
        }
        if ('ZZZZZ9999' > b.code) {
          return 1;
        }
      }
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    });
  }

  procedureChange($event) {
    this.selectedProcedureType = $event.value;
    if (this.selectedProcedureType?.relation?.cpt_id_ref) {
      this.selectedProcedureTypeCPTS = this.selectedProcedureType?.relation?.cpt_id_ref;
      this.selectedProcedureTypeCPTS.forEach((fvrt) => {
        if (this.fvrtCPTCodes.findIndex(charges => charges._id.toString() === fvrt._id.toString()) > -1) {
          fvrt["fvrt"] = true;
        } else {
          fvrt["fvrt"] = false;
        }
      });
      this.dataSource.data = this.sortCodes(this.selectedProcedureTypeCPTS);
    } else {
      this.dataSource.data = [];
    }
  }
  filterCPTHistory(search = "") {
    if (search == "") {
      this.dataSource.data = this.sortCodes(this.selectedProcedureTypeCPTS);
    } else {
      let filterCPTs = this.selectedProcedureTypeCPTS.filter((cp) => cp.code.includes(search) || cp.description.includes(search));
      this.dataSource.data = this.sortCodes(filterCPTs);
    }
  }
  cptsSelected(element) {
    if (element.selected) return true;
    if (this.selectedCPTCodes.findIndex(cpt => cpt._id === element._id) >= 0) {
      element.selected = true;
      return true;
    }
    element.selected = false;
    return false;
  }
  toggleCPTSelection(element) {
    this.toggleCPTsinHistory.emit(element);
    element.selected = !element.selected;
    return false;
  }
  toggleFavouriteCharges(element, action = "add") {
    let data = {
      cpts: element,
      action
    }
    if (action == "add") {
      element.fvrt = true;
    } else {
      element.fvrt = false;
    }
    this.toggleFvrtCPTsinHistory.emit(data);
  }
  shouldDisableCheckbox() {
    const isSameProvider = this.note.provider._id === this.currentUser._id && this.currentUser.user_type === global.USER_TYPE.DOCTOR;
    const isAssociatedProvider = this.note.provider._id != this.currentUser._id && this.currentUser.user_type === global.USER_TYPE.DOCTOR;
    const isMedicalAssistant = this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT;
    if(isSameProvider || isMedicalAssistant) {
      return false;
    }
    if(isAssociatedProvider) {
      return true;
    }
  }
}
