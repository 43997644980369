import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NoteBuilderService {
    constructor() {
    }
    getdnkeys() {
        let dn = ['notetype@dn', 'facility@dn',
            'patient@dn', 'dos@dn', 'provider@dn'];

        return dn;
    }

    mapdnkeys(data) {
        let dn = {
            'facility@dn': data.facility,
            'patient@dn': data.patient,
            'dos@dn': data.dos,
            'provider@dn': data.provider
        };

        return dn;
    }
}
