<div class="flex flex-col" style="height: calc(100vh - 302px)">
    <div class="flex justify-between items-center gap-1 action-buttons">
        <div>
        </div>
        <div>
            <button [ngClass]="{'action-buttons-disable': checkSigneNote(),'action-buttons-enable': !checkSigneNote()}"
                [disabled]="checkSigneNote()" matTooltip="Undo Action" (click)="undoAction()"><span
                    class="material-icons">undo</span></button>
            <button [ngClass]="{'action-buttons-disable': checkSigneNote(),'action-buttons-enable': !checkSigneNote()}"
                [disabled]="checkSigneNote()" style="margin-left: 3px;" matTooltip="Redo Action"
                (click)="redoAction()"><span class="material-icons">redo</span></button>
        </div>
    </div>
    <div class="selected-items" *ngIf="selectedDiagnoses.length">
        <div cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListDisabled]="charge?.status == 'submit'">
            <div cdkDrag *ngFor="let element of selectedDiagnoses; let i = index"
                style="cursor: move;border: groove;border-width: 1px;padding: 7px;" class="flex gap-2 justify-between items-baseline"
                [class.pdpm_highlight]="element.pdpm_status"
                [ngStyle]="{ background: i % 2 == 0? '#f4f5f8': 'white'}">
                <mat-checkbox (change)="removeDiagnose(element, $event, 'icd');" [checked]="true" [disabled]="shouldDisableCheckbox()">
                    {{element.code}} <span>({{element.description}})</span>
                </mat-checkbox>
            </div>
        </div>
    </div>
    <div class="flex"  style="margin: 10px;align-items: baseline;">
        <mat-form-field style="width: 100%;height: 44px;margin-top: -10px;" appearance="fill">
            <mat-label>Search </mat-label>
            <input matInput placeholder="Type Diagnosis/ICD10"
                (keyup)="searchDiagnosesWithDelay($event.target.value)" (keydown)="$event.stopPropagation();" name="diagnoseFilter">
            <div matSuffix class="flex justify-between items-center gap-1">
                <mat-spinner *ngIf="isDataLoading" [diameter]="18"></mat-spinner>
                <mat-icon mat-button mat-icon-button (click)="clearSearchField();searchDiagnose('');"
                *ngIf="diagnoseFilter">close</mat-icon>
            </div>
        </mat-form-field>
          <button *ngIf="companyWound" style="margin-left: 10px;" class="dn-button-primary note-refresh-btn" mat-raised-button type="button" (click)="refreshicd10codes()">Refresh Wound ICD 10</button>
    </div>
    <div class="flex flex-col note-sidebar" >
        <ng-container>
            <div *ngIf="currentCompany.should_allow_customized_tab" cdkDropList cdkDropListOrientation="horizontal" class="tabs-list flex justify-start"
              (cdkDropListDropped)="drop_($event)">
              <ng-container *ngFor="let tab of tabsToBeDisplayed; let index = index">
                <div class="tabs-group tabs-ripple-container" cdkDrag matRipple 
                (click)="tabChange(tab,index)"
                [ngStyle]="{'border-bottom':tab === activeTab ? '2px solid #3f51b5' : '' }"
                >
                    <div [ngSwitch]="tab">
                        <div *ngSwitchCase="'All'">My ICD</div>
                        <div *ngSwitchDefault>{{ tab }}</div>
                    </div>
                </div>
              </ng-container>
            </div>
            <div *ngIf="!currentCompany.should_allow_customized_tab" class="tabs-list flex justify-start">
              <ng-container *ngFor="let tab of tabsToBeDisplayed; let index = index">
                <div class="tabs-group tabs-ripple-container" 
                (click)="tabChange(tab,index)"
                [ngStyle]="{'border-bottom':tab === activeTab ? '2px solid #3f51b5' : '' }"
                >
                    <div [ngSwitch]="tab">
                        <div *ngSwitchCase="'All'">My ICD</div>
                        <div *ngSwitchDefault>{{ tab }}</div>
                    </div> 
                </div>
              </ng-container>
            </div>
            <div *ngIf="activeTab == 'Favorites'">
                <span style=" width: 100%; height: 100%;overflow: auto;">
                    <table mat-table [dataSource]="dataSourceFvrtICDs" matSort cdkDropList
                        (cdkDropListDropped)="onListDropfvrtICDs($event)">
                        <ng-container matColumnDef="checkbox">
                            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                            </th>
                            <td mat-cell *matCellDef="let element" style="width: 50px;">
                                <mat-checkbox (change)="addToModel(element, $event, 'icd');"
                                    [checked]="isICDChecked(element)" name="icd_fvrt" 
                                    [disabled]="shouldDisableCheckbox()">
                                </mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="fvrt">
                            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                            </th>
                            <td mat-cell *matCellDef="let element" style="width: 50px;">
                                <!--  *ngIf="!isFavourite(element, 'icd','fvrts')" -->
                                <span
                                    (click)="$event.preventDefault();$event.stopPropagation();removeFavouriteDiagnoses(element)"
                                    style="cursor: pointer" class="material-icons">
                                    star
                                </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="code">
                            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                            </th>
                            <td mat-cell *matCellDef="let element" 
                                (click)="shouldDisableCheckbox() ? null : addToModel(element, $event, 'icd');"
                                style="cursor: pointer;">
                                <div [class.pdpm_highlight]="element.pdpm_status">
                                    {{element.code}}
                                    <span cdkDrag>({{element.description}})</span>
                                </div>
                            </td>
                        </ng-container>
                        <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
                        <!-- <tr *matHeaderRowDef="displayedColumns"></tr> -->
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag
                        [ngClass]="row.isExpired ? 'red-row' : ''"></tr>
                    </table>
                </span>
            </div>
            <div *ngIf="activeTab == 'All'">
                <span style=" width: 100%; height: 100%;overflow: auto;">
                    <table mat-table [dataSource]="dataSourceAllICDs" matSort>
                        <ng-container matColumnDef="checkbox">
                            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                            </th>
                            <td mat-cell *matCellDef="let element" style="width: 50px;">
                                <mat-checkbox 
                                    (change)="addToModel(element, $event, 'icd')"
                                    [checked]="isICDChecked(element)" 
                                    name="icd_all"
                                    [disabled]="shouldDisableCheckbox()">
                                </mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="fvrt">
                            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                            </th>
                            <td mat-cell *matCellDef="let element" style="width: 50px;">
                                <!-- *ngIf="isFavourite(element, 'icd', 'all')" -->
                                <span *ngIf="element.fvrt === true"
                                    (click)="$event.preventDefault();$event.stopPropagation();removeFavouriteDiagnoses(element)"
                                    style="cursor: pointer" class="material-icons">
                                    star
                                </span>
                                <!-- *ngIf="!isFavourite(element, 'icd', 'all')" -->
                                <span class="material-icons" *ngIf="element.fvrt !== true"
                                    (click)="$event.preventDefault();$event.stopPropagation();addToFavouriteDiagnoses(element)"
                                    style="cursor: pointer">star_outline</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="code">
                            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                            </th>
                            <td mat-cell *matCellDef="let element" 
                                (click)="shouldDisableCheckbox() ? null : addToModel(element, 'row', 'icd');"
                                style="cursor: pointer;">
                                <div [class.pdpm_highlight]="element.pdpm_status">
                                    {{element.code}}
                                    <span>({{element.description}})</span>
                                </div>
                            </td>
                        </ng-container>
                        <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
                        <!-- <tr *matHeaderRowDef="displayedColumns"></tr> -->
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                        [ngClass]="row.isExpired ? 'red-row' : ''"></tr>
                    </table>
                    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
                </span>
                <div style="height: 5px;"></div>
                <div class="flex justify-center items-center gap-2 table-nav"
                    style="margin-bottom: 8px;">

                    <!-- <button mat-raised-button class="dn-button-primary"
                            [disabled]="!paginationDiagnosesAll.hasPrevPage" type="button"
                            (click)="handlePrevClick('diagnoseAll')"> Prev</button> -->

                    <!-- <span style="background: white; border-radius: 2px; font-size: 12px;padding: 5px;">
                        {{getCountofCodes()}}</span> -->

                    <!-- <button mat-raised-button class="dn-button-primary"
                        [disabled]="!paginationDiagnosesAll.hasNextPage" type="button" (click)="handleNextClick()">
                        Next </button> -->
                </div>
            </div>
            <div *ngIf="activeTab == 'PCC Diagnosis'">
                <app-conditions 
                #pccDiagnoseChild *ngIf="note.patient.pcc_patientId" 
                [patient]="note.patient"
                [note]="note"
                [showCheckboxes]="true" 
                [selectedDiagnoses]="selectedDiagnoses"
                (pccDiagnosesChange)="pccDiagnosesChange($event)">
            </app-conditions>
            </div>
            <div *ngIf="activeTab == 'Suggestions'">
                <app-note-icd-suggestions [noteText]="note.htmlText" [fvrtICDs]="fvrtICDs"
                [selectedDiagnoses]="selectedDiagnoses" (checkboxStateChange)="addToModel($event, null, 'icd');"
                (removeFromFavorites)="removeFavouriteDiagnoses($event);"
                (addToFromFavorites)="addToFavouriteDiagnoses($event);" [noteId]="note._id"></app-note-icd-suggestions>
            </div>
            <div *ngIf="activeTab == 'History'">
                <app-note-icd-history [note]="note" [searchDiagnosis]="diagnoseFilter" [selectedDiagnoses]='getSelectedDiagonoseCopy()'
                [fvrtICDs]="fvrtICDs" (toggleICDsinHistory)="toggleICDsinHistory($event)" (toggleFvrtICDsinHistory)="toggleFvrtICDsinHistory($event)"></app-note-icd-history>
            </div>
            <div *ngIf="activeTab == 'Matrix'">
                <app-matrix-diagnosis 
                #pccDiagnoseChild
                [showCheckboxes]="true" 
                [selectedDiagnoses]="getSelectedDiagonoseCopy()"
                [patient]="note.patient"
                (pccDiagnosesChange)="toggleICDsinHistory($event)">
            </app-matrix-diagnosis>
            </div>
          </ng-container>
          
    </div>
</div>