import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "./services/auth.service";
import { CommonService } from 'src/app/services/common.service';
import moment from "moment";

let done = false;
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  timezone_offset: string;

  constructor(
    public auth: AuthService,
    protected router: Router, 
    private commonService: CommonService,
    private toastr:ToastrService) {
      this.timezone_offset = moment().format("Z")
    }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.auth.getToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          // 'Content-Type': 'application/json; charset=utf-8',
          // "Content-Type": "multipart/form-data",
          // Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          local_timezone: moment.tz.guess(),
          timezone_offset: this.timezone_offset,
          component_name: this.getCurrentComponentName()
        },
      });
    }
    return next.handle(request)
    .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            const response:any = event;
            if(response && response.body) {
              
              const {status} = response.body;
              if (!done && [401, 599].indexOf(parseInt(status)) >= 0) {
                  this.toastr.clear();
                  this.toastr.error("Your session token expired please login again.");
                  // localStorage.clear();
                  this.auth.cleanupLogout();
                // this.router.navigate(['/login']); 
                done = true;
                const code = localStorage.getItem("companyCode");
                if (code) {
                  const validateCode = this.commonService.isValidPath(code);
                  window.location.href = `/login/${validateCode}`;
                } else {
                  window.location.href = '/login';
                }
              }
            }
          }
        }), 
        catchError(err => {
          const intCode = parseInt(err.status);
          if (!done && [401, 599].indexOf(intCode) >= 0) {

            this.toastr.clear();
            this.toastr.error("Your session expired please login again");
            // localStorage.clear();
            this.auth.cleanupLogout();
            // this.router.navigate(['/login']); 
            done = true;
            const code = localStorage.getItem("companyCode");
            if(code) {
              const validateCode = this.commonService.isValidPath(code);
              window.location.href = `/login/${validateCode}`;
            }else {
              window.location.href = '/login';
            }
          }

          return of('error', err)
        })
      );
  }

  getCurrentComponentName(): string {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    if (route.snapshot && route.snapshot.component) {
      const component = route.snapshot.component as any;
      if (component && component.name) {
        const componentName = component.name;
        return componentName.startsWith('_') ? componentName.substring(1) : componentName;
      }
    }
    return "Unknown Component";
  }

}