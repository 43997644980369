import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { UserTypesRelationshipService } from '../../services/user-types-relationship.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserManagementService } from '../user-management/user-management.service';
import { ToastrService } from 'ngx-toastr';
import { RolesDialog } from '../../shared/popup-dialogs/roles-dialog/roles.dialog';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import * as global from '../../includes/global'
import { CompanyService } from 'src/app/services/Modules/company.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ProfileService } from '../profile/profile.service';

export interface OnInit {
  ngOnInit(): void;
}
@Component({
  selector: 'app-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.css']
})
export class AdminListComponent{
  filteredCompanies: Observable<string[]>;
  companyControl = new FormControl();
  usersFilter = {
    user_type: '1'
  };
  disabled = false;
  loading = false;
  public admins: any;
  public currentUser: any;
  public response: any;
  public dataSource: any;
  // selectedCompany: any;
  public roleDialogRef;
  is_activated = 'true';
  userStatus = 'Active';
  company_id: any;
  localOrDevEnvironment: Boolean = false;
  displayedColumns: String[];
  companies: any = [];
  currentCompany: any;

  public constructor(
    public services: UserTypesRelationshipService,
    private _router: Router,
    private userManagementService: UserManagementService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private _authService: AuthService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private _profileService: ProfileService,
  ) {
    this.displayedColumns = ['first_name', 'last_name', 'email',
      'last_login', 'status', 'charge-status'
      , 'telemedicine', 'pcc', 'cognito'];

    this.currentUser = this._authService.currentUser;
    console.log("user", this.currentUser);
    if (this.currentUser.admin_type === "full_admin" || this.currentUser.admin_type === "system_admin") {
      console.log(this.displayedColumns);
      this.displayedColumns.push('user-type');
    }
    if (this.currentUser.product_owner) {
      this.displayedColumns.push('companies');
    }
    if (!this.currentUser) {
      // if not login, stop and redirect to login #mohsin-dev
      localStorage.setItem('currentUser', 'null');
      this._router.navigate(['/login']);
      return;
    }
    this.loading = true;
    // this.company_id = this.currentUser.company_id;

    // if(this.currentUser.account_type == 'company'){  
    //   this.company_id = this.currentUser.company_id;
    // }

    if (window.location.pathname.includes('company') && !window.location.pathname.includes('manage-companies')) {
      this.company_id = this.route.parent.parent.parent.snapshot.params.id;
    }
    else if (window.location.pathname.includes('manage-companies')) {
      this.company_id = this.route.parent.parent.parent.snapshot.params.id;
    }
    // this.company_id = localStorage.getItem('company_id')

  }
  // displayedColumns: string[] = ['first_name','last_name', 'email','doctor-profile', 'role', 'status', 'charge-status', 'admin','pcc'];
  @ViewChild(MatSort) sort: MatSort;
  async ngOnInit() {
    /*this.dataSource.sort = this.sort;*/
    try {
      if (this.currentUser.product_owner) {
        await this.getCompanies();
      }
      this.getAdminList();

      this.filteredCompanies = this.companyControl.valueChanges.pipe(
        startWith(''),
        map((value:any) => this.filterCompany(value)));
    } catch (err) {
      console.log("err", err);
    }
  }
  private filterCompany(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.companies.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    else {
      return this.companies;
    }
  }
  getAdminList() {
    console.log("rrr")
    this.services.getAdminList('true', this.company_id).subscribe(data => {
      console.log("rrr",data)
      this.response = data;
      this.admins = this.response.data;
      this.admins.forEach(dctr => {
        dctr.companies = this.companies
        this.companies.forEach(company => {
          if (dctr.company_id == company._id) {
            dctr.selectedCompany = company._id;
          }
        });
        if (dctr.admin_type == "full_admin" || dctr.admin_type == 'full_admin' || dctr.admin_type == 'system_admin') {
          dctr.admin = 'full_admin'
        }
        else if (dctr.admin_type == "transcriber_admin") {
          dctr.admin = 'transcriber_admin'
        }
        else if (dctr.admin_type == "none") {
          dctr.admin = 'none'
        }
      });

      this.dataSource = new MatTableDataSource(this.admins);
    }, console.error, () => this.loading = false);
  }
  getCompanies() {
    return new Promise<void>((resolve, reject) => {
      this.companyService.getAllCompanies().subscribe(async (response: any) => {
        if (response.status == 200) {
          this.companies = response.data;
          this.currentCompany = this.route.parent.parent.parent.snapshot.params.id
          var index = this.companies.findIndex(company => this.currentCompany === company._id);
          this.currentCompany = this.companies[index];
          this.companies.splice(index, 1);
          // console.log("curre",this.currentCompany);
          this.companyControl.setValue(this.currentCompany?.name);
          resolve(null);
        }
        else if (response.status == 403) {
          this.toastr.error(response.message, "ERROR");
        }
      }, err => {
        reject();
        this.toastr.error(err, "ERROR");
      }
      );
    });
  }
  onCompanyChange(element, company) {
    this.companyService.companyChangeForUser(element._id, company, element.company_id).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("Company Changed")
        this.getCompanies();
      }
    })
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyUserFilter(event) {
    this.loading = true;
    this.services.getAdminList(event.value, this.company_id).subscribe(data => {
      this.response = data;
      this.admins = this.response.data;
      this.dataSource = new MatTableDataSource(this.admins);
    }, console.error, () => this.loading = false);
  }

  getUserActiveStatus(provider) {
    
    if (provider && provider.isActivated) {
      if (provider.isActivated == true) {
        return true
      }
      else return false;
    }
    else return false;
  }
  checkEnvironment() {
    if (global.ENVIRONMENT == 'LOCAL' || global.ENVIRONMENT == 'DEV') {
      return true;
    }
    else {
      return false;
    }
  }

  changeUserActiveStatus(provider, status) {         //userId:string
    this.userManagementService.changeUserActiveStatus(provider._id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      } else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  changeChargeStatus(provider, status) {
    this.userManagementService.changeChargeStatus(provider._id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }

  changeRRGenStatus(provider, status) {
    this.userManagementService.changeRRGenStatus(provider._id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }

  changeTelemedicineStatus(provider, status) {
    this.userManagementService.changeTelemedicineStatus(provider._id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  // changeTATStatus(provider, status){ 
  //   this.userManagementService.changeTATStatus(provider._id, status).subscribe((response:any)=> {
  //     if(response.status == 200){
  //       this.toastr.success(response.message, 'Success');
  //     }
  //     else{
  //       this.toastr.error(response.message, 'Failed');
  //     }
  //   })
  // } 
  changeAdminStatus(provider, status) {
    this.userManagementService.changeAdminStatus(provider._id, this.company_id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  getChargeStatus(provider) {
    // console.log("getChargeStatus: Provider: ", provider);
    if (provider && provider.charge_status) {
      if (provider.charge_status == true) {
        return true
      }
      else return false;
    }
    else return false;
  }
  getAdminStatus(provider) {
    if (provider && provider.admin_type) {
      if (provider.admin_type == 'full_admin' || provider.admin_type == 'system_admin') {
        return true;
      }
      else {
        return false;
      }
    }
  }
  getPccStatus(provider) {
    if (provider && provider.has_pcc_account) {
      if (provider.has_pcc_account == 'true')
        return true;
    }
    else {
      return false;
    }
  }

  changePccStatus(provider, status) {
    let updateObject = {
      _id: provider._id,
      has_pcc_account: status
    };
    
    this.userManagementService.updateUser(updateObject).subscribe((response: any) => {
      
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  changeTwoFactorAllowance(provider, status) {
    let updateObject = {
      _id: provider._id,
      allow_two_factor_change_by_admin: status
    };
    
    this.userManagementService.updateUser(updateObject).subscribe((response: any) => {
      
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  changeCognitoStatus(provider, status) {
    let updateObject = {
      _id: provider._id,
      enableCognito: status
    };
    
    this.userManagementService.updateUser(updateObject).subscribe((response: any) => {
      
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }

  showRolesDialog(user) {
    this.roleDialogRef = this.dialog.open(RolesDialog, { width: '80%', data: { user } });
  }


  handleChangeUserType(userId, event) {
    var update = {
      user_type: event.value,
      user_id: userId
    }
    console.log("event", update);
    this._profileService.updateUserType(update).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("User type changed");
        this.getAdminList();
      }
    });
  }
  handleChangeAdminType(userId, event) {
    var update = {
      admin_type: event.value,
      user_id: userId
    }
    console.log("event", update);
    this._profileService.updateAdminType(update).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("Admin type changed");
        //  this.getDoctorList();
      }
    });
  }

  adminType(element) {
    console.log("Admin type: ", element);
    return 'full Admin'
  }

  ResendEmail(userId) {
    //    console.log("userId",userId);
    this.companyService.ResendEmail(userId).subscribe((response: any) => {
      if (response.status === 200) {
        console.log(response.data);
        this.toastr.success("Email sent successfully");
      }
    });
  }

}

