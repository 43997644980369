<div class="container flex flex-col gap-2">
  <div class="flex justify-between items-center gap-2"
    style="background: white;align-items: center !important; padding-left: 10px;">
    <div>
      <mat-form-field appearance="fill" style="height: 43px;">
        <input type="text" placeholder="Select Provider" aria-label="provider" matInput 
        [formControl]="providerFormControl"   [matAutocomplete]="providerAutoComplete" >
      <mat-autocomplete #providerAutoComplete="matAutocomplete" autoActiveFirstOption>
        <mat-option (click)="onSelectProvider()">--none--</mat-option>
        <mat-option *ngFor="let provider of filteredProvider | async"  [value]="provider.name"
          (click)="onSelectProvider(provider);">
          {{provider.name}}
        </mat-option>
      </mat-autocomplete>

      </mat-form-field>
    </div>

    <div>
      <div class="flex items-baseline input-with-cross">
        <mat-label style="margin-right:20px;color:#999">Date of Service</mat-label>

        <input
                type="text"
                ngxDaterangepickerMd
                [(ngModel)]="filter.date_of_service"
                class="mat-like-input"
                [ranges]="preDateRanges"
                [alwaysShowCalendars]="true"
                [linkedCalendars]="true"
                [showClearButton]="true"
        />
        <button mat-icon-button
                *ngIf="filter.date_of_service && (filter.date_of_service.startDate || filter.date_of_service.endDate)"
                (click)="filter.date_of_service = null">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    </div>

    <div>
      <mat-form-field appearance="fill" style="height: 43px;">
        <mat-label>Filter by Status</mat-label>
        <mat-select [(ngModel)]="filter.selectedStatus" name="status">
          <mat-option (click)="onChangeStatus()">--none--</mat-option>
          <mat-option *ngFor="let status of statusOption" [value]="status"
            (click)="onChangeStatus(status);">
            {{status}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <button class="dn-button-primary" mat-raised-button (click)="applyFilter()">Apply Fliter</button>
      <button class="dn-button-secondary" mat-raised-button (click)="resetFilter()" style="margin-left: 5px; margin-right: 5px">Clear
        Fliter</button>
        <button class="dn-button-primary" mat-raised-button (click)="generateCSV()"style="margin-right: 5px" >Chunks CSV</button>
        <button class="dn-button-primary" mat-raised-button (click)="getPhrasesCSV()"style="margin-right: 5px" >Phrases CSV</button>
    </div>

  </div>

  <div class="flex justify-end items-center gap-2 table-nav" *ngIf="pagination.totalPages">

    <button mat-raised-button class="dn-button-primary" *ngIf="pagination.hasPrevPage"
      (click)="handlePrevClick()">Prev</button>

    <button mat-raised-button class="dn-button-primary" *ngIf="pagination.hasNextPage"
      (click)="handleNextClick()">Next</button>
  </div>

  <div style=" position: relative;flex-direction: column " class="flex gap-0" [loaderId]="loaderId"
    ngxUiLoaderBlurred>
    <div class="flex table-wrapper gap-2" style="overflow: auto;">
      <table mat-table matSort [dataSource]="dataSource" class="note-list-table" id="matTable">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            ID
          </th>
          <td mat-cell *matCellDef="let element; let i = index" style="padding-right: 10px;">
            {{i+1}}
          </td>
        </ng-container>
        <ng-container matColumnDef="provider">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Provider
          </th>
          <td mat-cell *matCellDef="let element" style="padding-right: 10px;" contenteditable="false">
            {{getProvider(element)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="dictation_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Dictation ID
          </th>
          <td mat-cell *matCellDef="let element" style="padding-right: 10px;" contenteditable="fakse">
            {{getDictationID(element)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="chunk_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Chunk ID
          </th>
          <td mat-cell *matCellDef="let element" style="padding-right: 10px;" contenteditable="false">
            {{getChunkName(element)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="dos">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Date of Service
          </th>
          <td mat-cell *matCellDef="let element" style="padding-right: 10px;">
            {{getCreatedDate(element)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="transcription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Transcription
          </th>
          <td mat-cell *matCellDef="let element" style="padding-right: 10px; width: 23%"
            (blur)="updateChunk(element,$event)" contenteditable="true">
            {{element.transcript}}
          </td>
        </ng-container>
        <ng-container matColumnDef="action" style="padding-right: 10px;">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Action
          </th>
          <td mat-cell *matCellDef="let element" style="width: 20%;">
            <audio style="width: 250px" src="{{element.chunk_path}}" controlsList="nodownload" controls></audio>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Completed
          </th>
          <td mat-cell *matCellDef="let element" style="padding-right: 10px;"> 
            <mat-slide-toggle class="tp-margin" [checked]="getStatus(element)" [disabled]="disabled" (change)="changeStatus(element, $event.checked)">
            </mat-slide-toggle>
          </td>
        </ng-container>
        
         <ng-container matColumnDef="exported">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            
          </th>
          <td mat-cell *matCellDef="let element" style="padding-right: 10px;">
            {{getExportStatus(element)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            MT ID
          </th>
          <td mat-cell *matCellDef="let element" style="padding-right: 10px;">
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </table>


    </div>

  </div>
  <ngx-ui-loader [hasProgressBar]="true" [loaderId]="loaderId"></ngx-ui-loader>

</div>