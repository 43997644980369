import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Input, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { ProcedureNotesService } from 'src/app/services/procedure-notes.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DialogConfirmationComponent } from '../../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CompanyService } from 'src/app/services/Modules/company.service';
import * as global from '../../../includes/global';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ProcedureType } from '../../wound/procedure-datasets/procedure-datasets.component';
import { WoundService } from 'src/app/services/wound.service';

interface UserTypes {
  id: String,
  text: String
}
interface DatatSetRow {
  value: String,
  unit: String
}
interface DataSet {
  header_id: String,
  header: String,
  header_type: String,
  multiselect_enabled: Boolean,
  header_confirmed: Boolean,
  rows: Array<DatatSetRow>,
  row_confirmed: Array<Boolean>,
  saved_row: Array<Boolean>,
  user_type: String,
  procedure_type_id: String,
  default_value: String,
  unit: String,
  formula: String
}

@Component({
  selector: 'app-procedure-note-dataset',
  templateUrl: './procedure-note-dataset.component.html',
  styleUrls: ['./procedure-note-dataset.component.css']
})
export class ProcedureNoteDatasetComponent implements OnInit {
  global = global;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  measurements = [
    { label: 'None', unit: '' },
    { label: 'cm', unit: 'cm' },
    { label: 'cc', unit: 'cc' },
  ];
  data_types = [
    "Input",
    "Checkbox",
    "Dropdown"
  ];
  minDate = moment(new Date()).subtract(7, 'day').toDate();
  columns: DataSet[];
  dataSet: any[];
  loaderId = 'app-procedure-note-datasets';
  currentUser: any;
  disableEditing: boolean = false;
  datasetSettings: any[];
  selectUserTypes = global.USER_TYPE.DOCTOR;
  allUserList: Array<UserTypes> = [];
  selectedProcedureType: string = "";
  allProcedureTypes: Array<ProcedureType> = [];
  company_id: string;
  selectedRow: string;
  constructor(
    private _wound: WoundService,
    private _procedureNotes: ProcedureNotesService,
    private _company: CompanyService,
    private toastr: ToastrService,
    private _authService: AuthService,
    private loader: NgxUiLoaderService,
    private dialog: MatDialog,
    private route: ActivatedRoute) {
    this.currentUser = this._authService.currentUser;
    if (this.currentUser.admin_type != 'system_admin') {
      this.disableEditing = true;
    }
    Object.keys(this.global.USER_TYPE).forEach((key) => {
      var newUserType = {} as UserTypes;
      newUserType.id = this.global.USER_TYPE[key];
      newUserType.text = key;
      this.allUserList.push(newUserType);
    });
  }

  async ngOnInit() {
    if (window.location.pathname.includes('company')) {
      this.company_id = this.route.parent.snapshot.params.id;
    } else {
      this.company_id = this.currentUser.company_id;
    }
    this.columns = [];
    this.loader.startLoader(this.loaderId);
    await this.initProcedureType();
    this.loader.stopLoader(this.loaderId);
  }
  async initProcedureType() {
    let response = await lastValueFrom(this._wound.getProcedureTypes(this.company_id));
    if (response['status'] == 200) {
      this.allProcedureTypes = response['data'].array as ProcedureType[];
      this.selectedProcedureType = this.allProcedureTypes[0]._id;
      await this.getDataSetsColumns();
      await this.getAllDatasets();
    } else {
      this.allProcedureTypes = [];
      this.selectedProcedureType = "";
    }
  }

  async onChangeProcedureType() {
    this.loader.startLoader(this.loaderId);
    await this.getDataSetsColumns();
    await this.getAllDatasets();
    this.loader.stopLoader(this.loaderId);
  }

  async getDataSetsColumns() {
    let response = await this._company.getProcedureNoteDataSetColumnsSetting(this.company_id, this.selectUserTypes, this.selectedProcedureType).toPromise();
    if (response['status'] == 200) {
      this.datasetSettings = response['data'];
    } else {
      this.datasetSettings = [];
    }
  }
  addColumn() {
    var myObject = {} as DataSet;

    myObject.header_id = "";
    myObject.header = "";
    myObject.header_type = 'Input';
    myObject.header_confirmed = false;
    myObject.multiselect_enabled = false;
    myObject.unit = "";
    myObject.formula = "";
    myObject.rows = [];
    myObject.row_confirmed = [];
    myObject.saved_row = [];
    myObject.user_type = this.selectUserTypes;
    myObject.procedure_type_id = this.selectedProcedureType;
    this.columns.push(myObject);
  }
  async deleteColumn(index) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: "Are you sure you want to delete entire column?"
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result === "true") {
        const infoToast = this.toastr.info('Deleting Header', 'Please wait', { disableTimeOut: true });
        if (this.columns[index].header_confirmed == true) {
          let response = await this._procedureNotes.removeProcedureNoteDataSet(this.columns[index].header_id).toPromise();
          if (response['status'] == 200) {
            this.columns.splice(index, 1);
            this.toastr.clear(infoToast.toastId);
            this.toastr.success('Header Deleted Successfully', 'Success');
            this.updateColumnPositions();
          } else {
            this.toastr.clear(infoToast.toastId);
            this.toastr.error('Something went wrong deleting header', 'Failed');
          }
        } else {
          this.columns.splice(index, 1);
          this.toastr.clear(infoToast.toastId);
          this.toastr.success('Header Deleted Successfully', 'Success');
        }
      }
    });
  }
  toggleHeaderState(index) {
    if (this.currentUser.admin_type == 'system_admin') {
      this.columns[index].header_confirmed = !this.columns[index].header_confirmed;
    }
  }
  async ConfirmColumnHeader(index) {
    if (!(this.columns[index].header == "")) {
      if (this.columns[index].header_id == "") {
        const infoToast = this.toastr.info('Adding Header', 'Please wait', { disableTimeOut: true });
        const data = {
          company_id: this.company_id,
          header: this.columns[index].header,
          header_type: this.columns[index].header_type,
          multiselect_enabled: this.columns[index].multiselect_enabled,
          unit: this.columns[index].unit,
          user_type: this.selectUserTypes,
          procedure_type_id: this.selectedProcedureType,
          formula: this.columns[index].formula
        }
        let response = await this._procedureNotes.addProcedureNoteDataSet(data).toPromise();
        if (response['status'] == 200) {
          this.columns[index].header_confirmed = !this.columns[index].header_confirmed;
          this.columns[index].header_id = response['data']['_id'];
          this.toastr.clear(infoToast.toastId);
          this.toastr.success('Header Added Successfully', 'Success');
          this.updateColumnPositions();
        } else {
          this.toastr.clear(infoToast.toastId);
          this.toastr.error('Something went wrong adding header', 'Failed');
        }
      } else {
        const infoToast = this.toastr.info('Updating Header', 'Please wait', { disableTimeOut: true });
        const data = {
          company_id: this.company_id,
          header: this.columns[index].header,
          header_type: this.columns[index].header_type,
          multiselect_enabled: this.columns[index].multiselect_enabled,
          unit: this.columns[index].unit,
          rows: this.columns[index].rows,
          procedure_type_id: this.selectedProcedureType,
          formula: this.columns[index].formula
        }
        let response = await this._procedureNotes.updateProcedureNoteDataSet(this.columns[index].header_id, data).toPromise();
        if (response['status'] == 200) {
          this.columns[index].header_confirmed = !this.columns[index].header_confirmed;
          this.toastr.clear(infoToast.toastId);
          this.toastr.success('Header Updated Successfully', 'Success');
        } else {
          this.toastr.clear(infoToast.toastId);
          this.toastr.error('Something went wrong', 'Failed');
        }
      }
    }
  }

  handleUnitChange(col_index) {
    this.columns[col_index].rows.forEach((row) => {
      row.unit = this.columns[col_index].unit;
    });
  }
  toggleRowState(col_index, row_index) {
    if (this.currentUser.admin_type == 'system_admin') {
      this.columns[col_index].row_confirmed[row_index] = !this.columns[col_index].row_confirmed[row_index];
    }
  }
  async ConfirmRow(i, x) {
    if (!(this.columns[i].rows[x].value == "")) {
      const infoToast = this.toastr.info(`${this.columns[i].saved_row[x] ? 'Updating' : 'Adding'} Row`, 'Please wait', { disableTimeOut: true });
      const updateData = {
        rows: this.columns[i].rows
      };
      let response = await this._procedureNotes.updateProcedureNoteDataSet(this.columns[i].header_id, updateData).toPromise();
      if (response['status'] == 200) {
        this.columns[i].row_confirmed[x] = !this.columns[i].row_confirmed[x];
        this.toastr.clear(infoToast.toastId);
        this.toastr.success(`Row ${this.columns[i].saved_row[x] ? 'Updated' : 'Added'} Successfully`, 'Success');
        this.columns[i].saved_row[x] = true;
      } else {
        this.toastr.clear(infoToast.toastId);
        this.toastr.error(`Something went wrong ${this.columns[i].saved_row[x] ? 'updating' : 'adding'} row`, 'Failed');
      }
    } else {
      this.toastr.error("Please Add Row Data...");
    }
  }
  addRow(col_index) {
    var myObject = {} as DatatSetRow;
    myObject.value = "";
    myObject.unit = this.columns[col_index].unit;
    this.columns[col_index].rows.push(myObject);
    this.columns[col_index].row_confirmed.push(false);
    this.columns[col_index].saved_row.push(false);
  }
  async deleteRow(col_index, row_index) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: "Are you sure you want to delete row?"
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result === "true") {
        const infoToast = this.toastr.info('Deleting Row', 'Please wait', { disableTimeOut: true });
        this.columns[col_index].rows.splice(row_index, 1);
        if (this.columns[col_index].row_confirmed[row_index] == true) {
          const data = {
            rows: this.columns[col_index].rows
          };
          let response = await this._procedureNotes.updateProcedureNoteDataSet(this.columns[col_index].header_id, data).toPromise();
          if (response['status'] == 200) {
            this.columns[col_index].row_confirmed.splice(row_index, 1);
            this.columns[col_index].saved_row.splice(row_index, 1);
            this.toastr.clear(infoToast.toastId);
            this.toastr.success('Row Deleted Successfully', 'Success');
          } else {
            this.toastr.clear(infoToast.toastId);
            this.toastr.error('Something went wrong deleting row', 'Failed');
          }
        } else {
          this.columns[col_index].row_confirmed.splice(row_index, 1);
          this.columns[col_index].saved_row.splice(row_index, 1);
          this.toastr.clear(infoToast.toastId);
          this.toastr.success('Row Deleted Successfully', 'Success');
        }
      }
    });
  }
  async getAllDatasets() {
    this.columns = [];
    let response = await this._procedureNotes.getProcedureNoteDataset(this.selectUserTypes, this.selectedProcedureType, this.company_id).toPromise();
    if (response['status'] == 200) {
      this.dataSet = response['data'];
      this.dataSet.forEach((element, index) => {
        let myObject = {} as DataSet;
        myObject.header_id = element['_id'];
        myObject.header = element['header'];
        myObject.header_type = element['header_type'];
        myObject.unit = element['unit'];
        myObject.multiselect_enabled = element['multiselect_enabled'];
        myObject.header_confirmed = true;
        myObject.user_type = element['user_type'];
        myObject.procedure_type_id = element['procedure_type_id'];
        myObject.rows = element['rows'];
        myObject.default_value = element['default_value'];
        myObject.formula = element['formula'] || '';
        myObject.row_confirmed = element['rows'].map((row) => true);
        myObject.saved_row = element['rows'].map((row) => true);
        this.columns.push(myObject);
      });
      this.reArrangeDataSet(this.datasetSettings);
    }
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    this.updateColumnPositions();

  }
  async updateColumnPositions() {
    let column_ids = this.columns.map(col => col.header_id);
    let response = await this._company.updateProcedureNoteDataSetColumnsSettings(this.company_id, column_ids, this.selectUserTypes, this.selectedProcedureType).toPromise();
    if (response['status'] == 200) {
      this.toastr.success('Settings updated successfully', 'Success');
    } else {
      this.toastr.error('Something went wrong updating settings', 'Failed');
    }
  }
  async updateRowPositions(col) {
    const data = {
      rows: col.rows
    };
    let response = await this._procedureNotes.updateProcedureNoteDataSet(col.header_id, data).toPromise();
  }
  dropRow(event: CdkDragDrop<string[]>, col) {
    moveItemInArray(col.rows, event.previousIndex, event.currentIndex);
    this.updateRowPositions(col)
  }
  dragStart($event, rowDrag = false) {
    if (rowDrag) {
      let divTag = $event.source.dropContainer.element.nativeElement;
      var ele = divTag.getElementsByTagName("button");
      if (ele.length > 0) {
        for (let i = 0; i < ele.length; i++) {
          ele[i].style.display = "none";
        }
      }
    } else {
      let divTag = $event.source.element.nativeElement.children[0].children[0];
      var ele = divTag.getElementsByTagName("button");
      if (ele.length > 0) {
        for (let i = 0; i < ele.length; i++) {
          ele[i].style.display = "none";
        }
      }
    }
  }
  dragEnded($event, rowDrag = false, col) {
    if (rowDrag) {
      let divTag = $event.source.dropContainer.element.nativeElement;
      var ele = divTag.getElementsByTagName("button");
      if (ele.length > 0) {
        for (let i = 0; i < ele.length; i++) {
          ele[i].style.display = "block";
        }
      }
    } else {
      let divTag = $event.source.element.nativeElement.children[0].children[0];
      var ele = divTag.getElementsByTagName("button");
      if (ele.length > 0) {
        for (let i = 0; i < ele.length; i++) {
          ele[i].style.display = "block";
        }
      }
    }
  }
  reArrangeDataSet(colIDs) {
    if (colIDs.length > 0) {
      this.columns = this.columns.sort(function (a, b) {
        return colIDs.indexOf(a.header_id.toString()) - colIDs.indexOf(b.header_id.toString());
      });
    }
  }

  async toggleDefaultValueSelection(col_index: number, row: string, event: any) {
    if (event.checked) {
      this.columns[col_index].default_value = row;
    } else {
      this.columns[col_index].default_value = '';
    }
    const data = {
      default_value: this.columns[col_index].default_value
    };
    let response = await lastValueFrom(this._procedureNotes.updateProcedureNoteDataSet(this.columns[col_index].header_id, data));
    if (response['status'] === 200) {
      this.toastr.success('Default Value Set Successfully', 'Success');
    } else {
      this.toastr.error('Something went wrong', 'Failed');
    }
}
}
