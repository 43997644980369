import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { CompanyService } from 'src/app/services/Modules/company.service';

@Component({
  selector: 'app-assosiation-dialog',
  templateUrl: './assosiation-dialog.component.html',
  styleUrls: ['./assosiation-dialog.component.css']
})
export class AssosiationDialogComponent implements OnInit {
  currentUser: any;
  allCompanies: any;
  allOtherCompanies: any;
  selectedCompany: any;
  searchTextCompany: string;
  filterFormGroup: FormGroup;
  DropdownSettings: any = {};
  toEdit: any;
  title: String = "Practice Association";
  allCompaniesandPractices: any;
  constructor(
    public dialogRef: MatDialogRef<AssosiationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private toastr: ToastrService,
    private _commonService: CommonService,
    private _authService: AuthService,
  ) {
    this.currentUser = this._authService.currentUser;
    this.DropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true,
    };

  }

  ngOnInit(): void {
    if (this.data?.company_id) {
      this.toEdit = this.data?.company_id;
      this.title = "Edit Association of Practice : " + this.toEdit?.name;
    }
    this.searchTextCompany = '';
    this.filterFormGroup = new FormGroup({
      company: new FormControl(null)
    });
    this.getAllCompanies();
    this.filterFormGroup.get('company').valueChanges.subscribe(async (val) => {
      this.loadAssosiations(val);
    });
  }


  onCompanyDeSelect(event, selectedCompany) {

    const relationIndex = selectedCompany.relation.findIndex((e) => { return (e._id?.toString() == event._id.toString()) })
    if (relationIndex > -1) {
      selectedCompany.relation.splice(relationIndex, 1);
    }

    const index = this.allCompanies.findIndex((e) => { return (e._id?.toString() == selectedCompany._id.toString()) })
    this.allCompanies[index] = selectedCompany;
    this.selectedCompany = selectedCompany;

    this.saveinDB(selectedCompany);

  }
  onSelectAllCompany(selectedCompany) {

    selectedCompany.relation = this.allOtherCompanies;

    const index = this.allCompanies.findIndex((e) => { return (e._id?.toString() == selectedCompany._id.toString()) })
    this.allCompanies[index] = selectedCompany;
    this.selectedCompany = selectedCompany;

    this.saveinDB(selectedCompany);

  }

  onDeSelectAllCompany(selectedCompany) {

    selectedCompany.relation = [];

    const index = this.allCompanies.findIndex((e) => { return (e._id?.toString() == selectedCompany._id.toString()) })
    this.allCompanies[index] = selectedCompany;
    this.selectedCompany = selectedCompany;

    this.saveinDB(selectedCompany);

  }
  onCompanySelect(event, selectedCompany) {

    const relationIndex = selectedCompany.relation.findIndex((e) => { return (e._id?.toString() == event._id.toString()) })
    if (relationIndex == -1) {
      selectedCompany.relation.push(event);
    }

    const index = this.allCompanies.findIndex((e) => { return (e._id?.toString() == selectedCompany._id.toString()) })
    this.allCompanies[index] = selectedCompany;
    this.selectedCompany = selectedCompany;

    this.saveinDB(selectedCompany);
  }

  saveinDB(element) {
    const assosiated_company_id = element.relation?.map(e => e._id) || [];
    const parent_company_id = element._id;
    const created_by = this.currentUser._id;

    this.companyService.saveCompanyCompanyRelation({ parent_company_id: parent_company_id, assosiated_company_id: assosiated_company_id, created_by: created_by }).subscribe((res: any) => {
      this.toastr.success('Association has updated successfully');
    })

  }

  loadAssosiations(element) {
    if (element) {
      setTimeout(() => {
        this.allOtherCompanies = this.allCompaniesandPractices.filter(company => company.category == 'practice') || [];

        // this.allOtherCompanies = this.allCompanies.filter((e) => { return !(e._id?.toString() == element._id?.toString()) });

        // const twoWayAssosiatedCompanies = this.allCompanies.filter((e) => {
        //  return (e.relation?.findIndex((rel: any) => {
        //     return rel?._id?.toString()==element._id?.toString()
        //   }))<0;
        // });
        // this.allOtherCompanies = this.allOtherCompanies.filter((e) => {  return (twoWayAssosiatedCompanies?.findIndex((rel: any) => {
        //   return rel?._id?.toString()==e._id?.toString()
        // }))>=0; });

      }, 700);
      this.selectedCompany = element;
    }
  }

  clearInputcompany() {
    this.searchTextCompany = '';
  }

  getAllCompanies() {
    this.companyService.getAllCompaniesWithCompanyAssosiation({}).subscribe((res: any) => {
      if (res.status == 200) {
        if (res.data) {
          this.allCompaniesandPractices = res.data || [];
          this.allCompanies = res.data?.filter(company => company.category == 'company') || [];
          if (!this.toEdit) {
            this.filterFormGroup.get('company').setValue(this.allCompanies[0]);
          } else {
            this.loadAssosiations(this.toEdit || this.allCompanies[0]);
          }
        }
      }
    })
  }


  closeDialog(status) {

    this.dialogRef.close(status);
  }
}
