        <div class="flex gap-5" style="width:max-content;place-content: space-between;"> 
            <button
            mat-raised-button
            class="dn-button-secondary button-alignments" 
            (click)="goBack()"
          >
            Go Back
          </button>
          <button mat-raised-button class="dn-button-primary" [routerLink]="['../addfacility']"
            [queryParams]="{associate:'yes',provider_id:this.provider_id}">
            Add Facility
          </button>
        </div>
        <br />
        <table  mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="facility">
            <th
              class="header-data"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header-container
            >
              Facility
            </th>
            <td mat-cell *matCellDef="let element">
              <app-facility-title [facility]="element"></app-facility-title>
            </td>
          </ng-container>
          <ng-container matColumnDef="address">
            <th
              class="header-data"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header-container
            >
              Address
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.address }}
            </td>
          </ng-container>

          <ng-container matColumnDef="TrueRCM ID">
            <th
              class="header-data"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header-container
            >
            Unique ID
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.trueRCMID }}
            </td>
          </ng-container>
          <ng-container matColumnDef="note_uploading_method_per_facility">
            <th
              class="header-data"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header-container
            >
            Note Uploading Method
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-form-field appearance="outline" *ngIf="element.source == 'PointClickCare' || element.source == 'MatrixCare'">
                <mat-select
                  [ngModel]="getNoteUploadingMethodValue(element)"
                  (selectionChange)="onNoteUploadingMethodChange(element, $event)"
                >
                  <mat-option *ngFor="let noteUploadingMethod of noteUploadingMethods" [value]="noteUploadingMethod">
                    {{ noteUploadingMethod.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="city">
            <th
              class="header-data"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header-container
            >
              City
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.city }}
            </td>
          </ng-container>
          <ng-container matColumnDef="state">
            <th
              class="header-data"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header-container
            >
              State
            </th>
            <td mat-cell *matCellDef="let element">
              {{
                element.state >= 0 && usa_states[element.state] ? usa_states[element.state].abbreviation : ''
              }}
            </td>
          </ng-container>
          <ng-container matColumnDef="zip_code">
            <th
              class="header-data"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header-container
            >
              Zip Code
            </th>
            <td mat-cell *matCellDef="let element">{{ element.zip_code }}</td>
          </ng-container>
          
          <ng-container matColumnDef="pcc_2_legged_authentication">
            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
              2-Legged Auth
            </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element?.pcc_facId">
                <mat-slide-toggle (change)="onChangePcc2LeggedAuthentication(element,$event)"
                  [checked]="element?.pcc_2_legged_authentication"></mat-slide-toggle>
              </span>
            </td>
          </ng-container>
          
          <ng-container matColumnDef="operations">
            <th
              class="header-data"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header-container
            >
              
            </th>
            <td mat-cell *matCellDef="let element">
              <div *ngIf="currentUser.product_owner" class="operation-buttons buttons-div flex flex-inline justify-start items-center gap-5">
                  <i class="icon icon-edit" matTooltip="Edit" matTooltipPosition="above"
                  [routerLink]="['../../facilities/edit/' + element._id]"></i>
    
              
              </div>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader> 
  