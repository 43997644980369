<mat-card class="biller-feature">
    <div class="flex items-center" style="margin-bottom: 25px;">
        <button mat-raised-button class="dn-button-secondary" type="button" (click)="goBack();">Back</button>
    </div>

    <mat-checkbox class="top-margin" [checked]="company?.charge_npi === true"
        (change)="updateComapny(company, 'charge_npi', $event.checked); company.charge_npi = true">
        Bill Under Supervising Provider NPI
    </mat-checkbox>
    <div style="margin-top: 30px;" *ngIf="canEditModifier">
        <mat-form-field style="width: 15%">
        <mat-label>Modifier</mat-label>
        <input matInput type="text" name="modifier" 
            [(ngModel)]="company.modifier">
    </mat-form-field>
    <button mat-raised-button type="button" class="dn-button-primary" style="margin-left: 20px;"
    (click)="saveModifier()">Save</button>
    <br>
    <b>Cpt Selection</b>
        <ng-multiselect-dropdown name="codes" [placeholder]="'Select codes'" [settings]="cptCodesDropdownSettings"
            [data]="cpts" [(ngModel)]="company.assoc_cpt_ids" (onSelect)="onCodeSelect(company,$event); "
            (onDeSelect)="onCodeDeSelect(company,$event);" (onSelectAll)="onSelectAllCodes(company,$event);"
            (onDeSelectAll)="onDeSelectAllCodes(company,$event);">
        </ng-multiselect-dropdown>
    </div>
</mat-card>