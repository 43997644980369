import {Injectable} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import * as global from '../../global';
import {AuthService} from 'src/app/services/auth.service';
import {API_URL} from "../../../../environments/api-url";
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import moment from 'moment';
@Injectable({
  providedIn: 'root',
})
export class AddendumService {
  private addendums = new BehaviorSubject<any[]>([]);
   castAddendums= this.addendums.asObservable();

  public constructor(
      private httpclient: HttpClient,
      private _authService: AuthService
  ) {
  }
  addendumNote(data) {
    let currentUserDate=moment(new Date()).format('MM/DD/YYYY');
    let currentUserTime=moment(new Date()).format('hh:mm A');
    let currentUserDateTime=moment(new Date()).format('MM/DD/YYYY hh:mm A');
    const details = {
      ...this._authService.authObject,
      currentUserDate:currentUserDate.toString(),
      currentUserTime:currentUserTime.toString(),
      currentUserDateTime:currentUserDateTime.toString(),
      ...data
    };


    return this.httpclient
        .post(global.url + API_URL.NOTE.addendumNote, {details}).pipe(
          tap((response:any)=> {
            if(response.status === 200) {
              const oldData = this.addendums.value;
              const newData = [...oldData, response.data.addendum]

              
              this.addendums.next(newData)
            }
          })
        );
  }
  saveAddendumNote(data) {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone; 
    let currentUserDate=moment(new Date()).format('MM/DD/YYYY');
    let currentUserTime=moment(new Date()).format('hh:mm A');
    let currentUserDateTime=moment(new Date()).format('MM/DD/YYYY hh:mm A');
    const details = {
      ...this._authService.authObject,
      tz,
      currentUserDate:currentUserDate.toString(),
      currentUserTime:currentUserTime.toString(),
      currentUserDateTime:currentUserDateTime.toString(),
      ...data
    };


    return this.httpclient
        .post(global.url + API_URL.NOTE.saveAddendumNote, {details}).pipe(
          tap((response:any)=> {
            if(response.status === 200) {

              if(!data.addendumId) {
                const oldData = this.addendums.value;
                const newData = [...oldData, response.data.addendum]
  
                
                this.addendums.next(newData)
              }
            }
          })
        );
  }

  uploadAddendumNote(data) {
    let currentUserDate=moment(new Date()).format('MM/DD/YYYY');
    let currentUserTime=moment(new Date()).format('hh:mm A');
    let currentUserDateTime=moment(new Date()).format('MM/DD/YYYY hh:mm A');
    const details = {
      ...this._authService.authObject,
      currentUserDate:currentUserDate.toString(),
      currentUserTime:currentUserTime.toString(),
      currentUserDateTime:currentUserDateTime.toString(),
      ...data
    };


    return this.httpclient
        .post(global.url + API_URL.NOTE.uploadAddendumNote, {details}).pipe(
          tap((response:any)=> {
            if(response.status === 200) {
              
              const oldData = this.addendums.value;
              const newData = oldData.map(e => {
                if(e._id === response.data.addendum._id) {
                  return response.data.addendum;
                } 
                return e;
              })

              
              this.addendums.next(newData)
            }
          })
        );
  }

  getNoteAddendums(data) {

    const details = {
      ...this._authService.authObject,

      ...data
    };
    return this.httpclient
        .post(global.url + API_URL.NOTE.getNoteAddendums, {details}).pipe(
          tap((response:any)=> {
            if(response.status === 200) {
              this.addendums.next(response.data.array)
            }
          })
        );
  }
}

