import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import * as global from '../global';
import { AuthService } from 'src/app/services/auth.service';
import {API_URL} from "../../../environments/api-url";

@Injectable({
    providedIn: 'root',
  })
export class NoteAuditoryHistoryService {
    currentUser:any = {};
    userInfo:any = {};
    public constructor(
        private httpclient: HttpClient,
        private _authService: AuthService) {

    }

  getNoteAudit(noteId) {

    const details = {
      ...this._authService.authObject,
      note_id: noteId
    }
    return this.httpclient
      .post(global.url + API_URL.NOTE.getNoteAudit, { details: details }).pipe();
  }

  getNoteTimeLogs(filter) {
    const details = {
      filter
    }
    return this.httpclient
      .post(global.url + API_URL.NOTE.getNoteTimeLogs, { details: details }).pipe();
  }

  formatOperation(operation, source = null) {
      const labels = {
          delete: 'deleted',
          create: 'Saved',
          save: 'Saved',
          draft_save: 'auto saved as draft',
          publish: 'ready for review',     
          published: 'ready for review',     
          internal_qa: 'submitted to internal QA',     
          external_qa: 'submitted to external QA',     
          sign: 'Signed',     
          upload: 'Uploaded',     
          addendum_save: 'Addendum Saved',
          addendum_uploaded: 'Addendum Uploaded',
          revert_qai: 'returned to transcriber',
          revert_qae: 'returned to internal QA',
          revert_provider: 'returned to QA',
          in_progress: 'in progress', //note_status.status
          not_uploaded: 'not uploaded', //note_status.status

          assign_transcriber_manually: 'assigned transcriber manually',
          assign_qa_manually: 'assigned to QA manually',
          assign_qae_manually: 'assigned to external QA manually',
          co_sign: 'co-signed',
          wound_report: 'Wound Report Uploaded',
          'sign & uploaded': "Note Signed & Uploaded",
          'signed & uploaded': "Note Signed & Uploaded"
          
      }
      
      if(source == 'MatrixCare'){
        labels['sign & uploaded'] = "Note Signed & Uploaded"
        labels['signed & uploaded']= "Note Signed & Uploaded"
        labels['wound_report'] = "Wound Report Uploaded to MatrixCare"
      } else if(source == 'PointClickCare') {
        labels['sign & uploaded'] = "Note Signed & Uploaded to PCC"
        labels['signed & uploaded']= "Note Signed & Uploaded to PCC"
        labels['wound_report'] = "Wound Report Uploaded to PCC"
      }
      return labels[operation] || operation;
  }
}
