import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { WoundEnterpriseReportService } from 'src/app/services/wound-enterprise-report.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-add-wound-enterprise-report-dialogue',
  templateUrl: './add-wound-enterprise-report-dialogue.component.html',
  styleUrls: ['./add-wound-enterprise-report-dialogue.component.css']
})
export class AddWoundEnterpriseReportDialogueComponent implements OnInit {
  woundNameDialogue:string ="Add New"
  newWoundEnterpriseReport = {
    title: '',
    iframe_value: '',
    is_deleted: false
  };

  isCloseDialogue = false;
  isButtonDisable = true;

  constructor(private toastr: ToastrService, private dialogRef: MatDialogRef<AddWoundEnterpriseReportDialogueComponent>, private _WoundEnterpriseReportService: WoundEnterpriseReportService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

     }

  ngOnInit(): void {
    if(this.data){
      this.woundNameDialogue = "Update"
      this.newWoundEnterpriseReport.title = this.data.title;
      this.newWoundEnterpriseReport.iframe_value= this.data.iframe_value;
      this.checkIsEmpty()
    }
  }

  checkIsEmpty() {
    if (this.newWoundEnterpriseReport.title.trim().length === 0 || this.newWoundEnterpriseReport.iframe_value.trim().length === 0) {
      this.isButtonDisable = true;
    }
    else{
      this.isButtonDisable = false;
    }
  }

  async handleSubmit(event: any) {
    let res,toastrMessage: any, toastrId: any;
    try {
      toastrMessage =  this.data ? "Updating Report":"Adding Report";
      toastrId = this.toastr.info(toastrMessage, "Please Wait...", { disableTimeOut: true });
       res = await lastValueFrom(
        this.data
          ? this._WoundEnterpriseReportService.updateWoundEnterpriseReport({ _id: this.data?._id }, this.newWoundEnterpriseReport)
          : this._WoundEnterpriseReportService.addWoundEnterpriseReport(this.newWoundEnterpriseReport)
      );
      if (res.status === 200) {
        this.toastr.clear(toastrId);
        this.toastr.success(res.message, "Success");
        this.dialogRef.close(this.data ? res.data : { data: res });
      }
    } catch (err) {
      this.toastr.clear(toastrId);
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  closeDialog() {
    this.isCloseDialogue = true;
    this.dialogRef.close();
  }
  
  clearDetails() {
    this.newWoundEnterpriseReport = {
      title: '',
      iframe_value: '',
      is_deleted: false
    };
    this.isButtonDisable = true;
  }


}
