<app-header></app-header>
<div class="container">
    <mat-card>
        <form (ngSubmit)="showGraph()">
            <div class="flex justify-between items-center" *ngFor="let form of forms; let index = index;">
                <h2> Day {{form.day}} </h2>
                <mat-form-field style="width: 45%;">
                    <mat-label>Area</mat-label>
                    <input matInput type="number" name="area{{index}}" [(ngModel)]="form.area">
                   
                </mat-form-field>
                <div style="width: 45%;">
                    <mat-form-field style="width: 92%;">
                        <mat-label>Volume</mat-label>
                        <input matInput type="number" name="volume{{index}}" [(ngModel)]="form.volume">
                        
                    </mat-form-field>
                    <img *ngIf="index !== 0" id="remove-img" src="assets\icons\minus.png" (click)="removeForm(index)" />
                </div>
            </div>
            <div class="flex justify-center items-center gap-1">
                <img (click)="addForm();" id="add-img" src="assets\icons\plus.png" />
                <button mat-raised-button type="button" class="dn-button-primary" type="submit">Show Graph</button>
            </div>
        </form>
        <div class="div-chart">
            <canvas #lineChart></canvas>
        </div>
    </mat-card>
</div>