import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-clinical-summary-report-v2',
  templateUrl: './clinical-summary-report-v2.component.html',
  styleUrls: ['./clinical-summary-report-v2.component.css']
})
export class ClinicalSummaryReportV2Component implements OnInit {
  @Input() clinicalSummaryReport = {};
  @Input() facilityTitle = "";
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = [
    // 'faciltiy', 'company',
     'totalWounds', 'improving', 'stalled', 'deteriorating',
    'inRemission', 'amputations', 'healingSuccess', 'avgDaysToRemission', 'skinTear', 'pressure',
    'diabetic', 'venous', 'arterial', 'masd', 'other'];

  constructor() { }

  ngOnInit(): void {
    this.dataSource.data = this.clinicalSummaryReport;
  }

}
