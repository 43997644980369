import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment'
import { ToastrService } from 'ngx-toastr';
import { Observable, lastValueFrom } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FacilityService } from 'src/app/services/facility.service';
import { Location } from '@angular/common';
import { WoundService } from 'src/app/services/wound.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { BillerDashboardService } from 'src/app/includes/biller-dashboard/biller-dashboard.service';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';
import * as global from "src/app/includes/global";
import { WoundNurseRelationService } from 'src/app/services/Modules/woundNurse.service';
import { NursingService } from 'src/app/includes/nursing-module/nursing.service';
import { SnfWcNurseAssociationService } from 'src/app/includes/snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-dns-healing-report-v2',
  templateUrl: './dns-healing-report-v2.component.html',
  styleUrls: ['./dns-healing-report-v2.component.css']
})
export class DnsHealingReportV2Component implements OnInit {
  global = global;
  currentDate = moment(new Date());
  minDate = moment(new Date()).subtract(6, 'days');
  loaderId = 'app-dns-report';
  filter: any = {
    updationDate: {
      startDate: moment().subtract(6, 'days'),
      endDate: moment()
    },
    facilityId: null,
    companyId: null,
    state: 'all',
    dateType: "last_assessment_date_obj"
  };
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 1 Week': [moment().subtract(6, 'days'), moment()],
    // 'Last 2 Week': [moment().subtract(13, 'days'), moment()],
    // 'Last 3 Week': [moment().subtract(20, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    // 'This Month': [moment().startOf('month'), moment().endOf('month')],
    // 'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  filteredFacilities: Observable<string[]>;
  displayedColumns: string[] = [
    'room_number', // Room #
    'name', // Last Name, First Name
    'body_part', // Wound Location
    // 'woundNo', // #
    'etiolgy', // Etiology
    'stage', // Severity
    'last_admit_date_obj', // First Evaluation Date
    'latest_evaluation_date', // Latest Evaluation Date
    // 'state', // Status
    // 'diffInWeek', // Days in Tx
    // 'area_change', // Area Percent Change
    'length', // Lenth
    'width', // Width
    'depth', // Depth
    'wound_status',
    'wound_recognition_date_type', // Present on Admission
    'wound_recognition_date', // Date Acquired

    'dressing_change_frequency', // Dressing Change Frequency
    'cleanse_wound_with', // Cleanse Wound With
    // 'dressings', // Dressings
    'primary_treatment',
    'secondary_dressings', // Secondary Dressings
  ];
  facilityControl = new FormControl();
  facilities: Array<any> = [];
  dnsReport: any = [];
  dataSource: any = new MatTableDataSource([]);
  poaCount: number = 0;
  facilityAcquiredCount: number = 0;
  openWoundsCount: number = 0;
  inRemissionWoundsCount: number = 0;
  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    // totalPages: 0,
    // currentRecords: 0
    PAGE_LIMIT: 50,
    skip: 0
  };
  companySide: boolean;
  currentUser: any;
  reportName = 'Post Rounds Report';
  base_url: string;
  nursingSide: boolean;
  user_dashboard: Boolean = false;
  facilityInputText: string;
  timeoutId: any;
  constructor(
    private _toastr: ToastrService,
    private _facilityService: FacilityService,
    private _route: ActivatedRoute,
    private _location: Location,
    private _wound: WoundService,
    private loader: NgxUiLoaderService,
    private _authService: AuthService,
    private billerDashboardService: BillerDashboardService,
    private _woundNurseRelationService: WoundNurseRelationService,
    private _nursingService: NursingService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
    private _commonService: CommonService
  ) {
    this.currentUser = this._authService.currentUser;
    this.filter.companyId = this._route.parent.snapshot.params.id;
    if (this._route.parent.snapshot.params.id) {
      this.companySide = true;
    } else {
      this.filter.companyId = this.currentUser.company_id;
    }
    if (window.location.pathname.includes('dashboard')) {
      this.user_dashboard = true;
    }
    if (window.location.pathname.includes('nursing')) {
      this.nursingSide = true;
    }
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId);
    await this.initFacilities();
    this.selectDefaultFacility();
    await this.initDnsHealingReport();
    this.filteredFacilities = this.facilityControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => this.filterFacilities(value)));
    this.loader.stopLoader(this.loaderId);
  }
  private filterFacilities(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) > -1);
    }
    else {
      return this.facilities;
    }
  }
  selectDefaultFacility() {
    if (this.facilities.length > 0) {
      this.filter.facilityId = this.facilities[0]._id;
    }
    this.facilityControl.setValue(this.facilities[0].title);
  }
  async initDnsHealingReport() {
    this.loader.startLoader(this.loaderId);
    this.filter.pageNo = this.pagination.pageNo;
    const response: any = await lastValueFrom(this._wound.getDnsHealingReportV2(this.filter));
    if (response.status == 200) {
      this.dnsReport = response.data.dnsReport;
      this.pagination.totalRecords = response.data.totalRecords;
      this.handlePagination();
      this.countTotal();
      this.dataSource.data = this.dnsReport;
      this.loader.stopLoader(this.loaderId);
    }
    else {
      this.loader.stopLoader(this.loaderId);
      this._toastr.error('Something Went Wrong', 'Error');
    }
  }

  countTotal() {
    [this.inRemissionWoundsCount, this.openWoundsCount,
    this.poaCount, this.facilityAcquiredCount] = [0, 0, 0, 0];
    if (this.dnsReport.length > 0) {
      for (const report of this.dnsReport) {
        if (report.wound_recognition_date_type === "yes") {
          this.facilityAcquiredCount++;
        }
        else {
          this.poaCount++;
        }
        if (report.state === "active") {
          this.openWoundsCount++;
        }
        else if (report.state === "remission") {
          this.inRemissionWoundsCount++;
        }
      }
    }
  }

  handlePagination() {
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    if (this.pagination.skip + this.dnsReport.length < this.pagination.totalRecords) {
      this.pagination.hasNextPage = true;
    }
    else {
      this.pagination.hasNextPage = false;
    }
    if (this.pagination.skip > 0) {
      this.pagination.hasPrevPage = true;
    }
    else {
      this.pagination.hasPrevPage = false;
    }
  }

  async initFacilities() {
    if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      const filter = {
        associated_snf_wc_nurse_ids: this.currentUser._id,
        company_id: this._authService.currentUser.company_id
      };
      const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      const facilityProjection = {
        title: 1,
        pcc_facId: 1,
        pcc_2_legged_authentication: 1,
        source: 1
      };
      const response: any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
      if (response.status === 200 && response.data.length > 0) {
        this.facilities = response.data;
      }
    }
    else if (this.currentUser.user_type === global.USER_TYPE.NURSE) {
      const response: any = await lastValueFrom(this._nursingService.getAssociatedFacilities());
      if (response.status === 200 && response.data.array.length > 0) {
        this.facilities = response.data.array;
      }
    }
    else if (this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
      const filter = {
        company_id_ref: this.filter.companyId,
      };
      const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      const facilityProjection = {
        title: 1,
        pcc_facId: 1,
        pcc_2_legged_authentication: 1,
        source: 1
      };
      const facilitiesResponse: any = await lastValueFrom(this._woundNurseRelationService.getWoundNurseFacilities(filter, nurseProjection, facilityProjection));
      if (facilitiesResponse.status === 200) {
        this.facilities = facilitiesResponse.data;
      }
    }
    else {
      const filter = {
        company_id: this.filter.companyId,
        is_active: true
      };
      const projection = {
        title: 1
      };
      const facilitiesResponse: any = await lastValueFrom(this._facilityService.getMyFacilities(this.currentUser._id));
      if (facilitiesResponse.status == 200) {
        this.facilities = facilitiesResponse.data.array;
      }
    }
  }
  onSelectFacility($event, facility) {
    if ($event.isUserInput) {
      this.filter.facilityId = facility._id;
    }
  }
  changeUpdationDate(event) {
    if (event.startDate && event.endDate) {
      this.filter.updationDate = {
        startDate: null,
        endDate: null
      };
      const noOfDays = this._commonService.getDifferenceOfDates(event.startDate,event.endDate,'days');
      if (noOfDays > 29) {
        this.filter.updationDate = {};
        this.filter.updationDate.startDate = moment(event.startDate);
        this.filter.updationDate.endDate = moment(this.filter.updationDate.startDate).add(29, "days").endOf('day');
        this._toastr.error('Can not select more than 30 days', 'Date Error');
      }
      else {
        this.filter.updationDate.startDate = event.startDate;
        this.filter.updationDate.endDate = event.endDate;
      }
    }
  }

  goBack() {
    this._location.back();
  }
  handleApplyFilter() {

    this.facilityInputText = (<HTMLInputElement>document.getElementById('facilityControlInput')).value;
    console.log("this.facili tyInput Text : ", this.facilityInputText);

    if (this.facilityInputText == '') {
      this._toastr.warning('Please select facility from list', 'Warning');
      return;
    } else {

      const exists = this.facilities.filter(option => option.title.toLowerCase().trim() == this.facilityInputText.toLowerCase().trim());
      if (!exists.length) {
        this._toastr.warning('Please select facility from list', 'Warning');
        return;
      }

    }


    this.pagination = {
      pageNo: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalRecords: 0,
      PAGE_LIMIT: 50,
      skip: 0
    };
    this.initDnsHealingReport();
  }

  calculateArea(ele) {
    if (ele.earliestRevisionArea && ele.recentWound) {
      return 100 - ((ele.recentWound.recentRevisionArea / ele.earliestRevisionArea) / 100)
    }
    return ele.earliestRevisionArea;
  }
  onStateChange(value) {
    this.filter.state = value;
  }
  async resetFilter() {
    delete this.filter.updationDate;
    const defaultDate = {
      startDate: moment().subtract(6, 'days'),
      endDate: moment()
    };
    this.filter.updationDate = defaultDate;
    this.filter.state = "all";
    this.filter.dateType = "date_of_service";
    this.selectDefaultFacility();
    await this.initDnsHealingReport();
  }

  async exportDnsHealingReportAsXLSX(exportOption: string) {
    delete this.filter.pageNo; //To export all
    const infoToastr = this._toastr.info(exportOption === 'download' ? 'Exporting as XLSX...' : 'Sending via email...',
      'Please wait', { disableTimeOut: true, });
    const response: any = await lastValueFrom(this._wound.exportDnsHealingReportV2AsXLSX({ ...this.filter, exportOption }));
    /**********Amazon API Gateway Timeouts in 30s ********/
    clearTimeout(this.timeoutId);
    if (exportOption === 'email') {
      this.timeoutId = setTimeout(() => {
        this._toastr.clear(infoToastr.toastId);
        this._toastr.success("Post Rounds Report has been sent to your email");
      }, 1500);
    }
    if (exportOption === 'download') {
      if (response.body instanceof Blob) {
        this._toastr.clear(infoToastr.toastId);
        const url = window.URL.createObjectURL(response.body);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = "Post Rounds Report";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this._toastr.clear(infoToastr.toastId);
      }
      else if (response.status === 504) {
        this._toastr.clear(infoToastr.toastId);
        this._toastr.error("Maximum limit reached, send via email.");
      }
    }
  }

  exportDnsHealingReportAsPDF() {
    let innerHtml = document.getElementById("dnsReport").innerHTML;

    this.billerDashboardService.generatePDFAndDownload(innerHtml).subscribe((res: any) => {
      if (res instanceof HttpResponse) {
        var url = window.URL.createObjectURL(res.body);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = 'Post Rounds Report.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }
    });
  }
  goToPreviousPage() {
    this.pagination.pageNo--;
    this.initDnsHealingReport();
  }
  goToNextPage() {
    this.pagination.pageNo++;
    this.initDnsHealingReport();
  }

}
