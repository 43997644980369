<div>
    <mat-card >
        <div class="flex justify-between items-center gap-5 cpts">
            <app-input-with-dropdown class="flex-grow" [noDropdown]="true" placeholder="Search Practice by Name"
                (inputChange)="applyFilter($event.target.value)">
            </app-input-with-dropdown>
            <button mat-raised-button class="dn-button-primary" (click)="handleAddNewCode()"> Add new CPT Code</button>
        </div>
        <div style="margin-top: 20px;height: 80vh;overflow: auto;" class="flex justify-start items-start cpts" >
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="company">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;">Practices</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.name ? element.name : element.company_id.name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="codes">
                    <th mat-header-cell *matHeaderCellDef> CPT Codes </th>
                    <td mat-cell *matCellDef="let element">
                            <ng-multiselect-dropdown name="codes" [placeholder]="'Select codes'"
                                [settings]="cptCodesDropdownSettings" [data]="cpts" [(ngModel)]="element.cpt_ids"
                                (onSelect)="onCodeSelect(element,$event); "
                                (onDeSelect)="onCodeDeSelect(element,$event);"
                                (onSelectAll)="onSelectAllCodes(element,$event);"
                                (onDeSelectAll)="onDeSelectAllCodes(element,$event);">
                            </ng-multiselect-dropdown>
                    </td>              
                </ng-container>

                <ng-container matColumnDef="operation">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header-container style="width: 50px;"></th>
                    <td mat-cell *matCellDef="let element">
                        <i class="icon icon-view" matTooltip="View" (click)="showDetails(element)"
                            matTooltipPosition="above"></i>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
            <div *ngIf="isViewEnabled" style="height: 80vh;overflow: auto;width: 26%;">
                <div>
                    <app-view-cpt-codes [company]="company" (exit)="exit($event)" (updateCPTS)="updateCPTS($event)"></app-view-cpt-codes>
                </div>
            </div>
        </div>
    </mat-card>
</div>