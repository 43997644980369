<div class="container" style="display: flex; flex-direction: column !important">
    <mat-card>
        <mat-card-content>
            <mat-tab-group (selectedTabChange)="onTabClick($event)">
                <mat-tab label="Company-Facility" *ngIf="currentUser.product_owner && currentUser.admin_type !== global.ADMIN_TYPE.WOUND_NURSE_ADMIN">
                    <div class="mat-tab-content">
                        <app-company-facility-association></app-company-facility-association>
                    </div>
                </mat-tab>
                <mat-tab label="Provider-Facility" *ngIf="currentUser.admin_type !== 'transcriber_admin' && currentUser.admin_type !== global.ADMIN_TYPE.WOUND_NURSE_ADMIN && company_type !== global.COMPANY_TYPE.SNF">
                    <div class="mat-tab-content" *ngIf="selectedTabName === 'Provider-Facility'">
                        <app-provider-facility-association></app-provider-facility-association>
                    </div>
                </mat-tab>
                <mat-tab label="Template-Facility"
                    *ngIf="currentUser.admin_type !== 'transcriber_admin' && company_type !== global.COMPANY_TYPE.WOUND && company_type !== global.COMPANY_TYPE.SNF && company.portal_type !== global.PORTAL_TYPE.WC">
                    <div class="mat-tab-content" *ngIf="selectedTabName === 'Template-Facility'">
                        <app-template-facility-association></app-template-facility-association>
                    </div>
                </mat-tab>
                <mat-tab label="Biller-Practice"
                    *ngIf="currentCompany.currentCompanyType != global.COMPANY_TYPE.SNF && company_type == global.COMPANY_TYPE.NURSING">
                    <div class="mat-tab-content" *ngIf="selectedTabName == 'Biller-Practice'">
                        <app-biller-practice-association></app-biller-practice-association>
                    </div>
                </mat-tab>
                <mat-tab label="Nurse-Facility" *ngIf="currentUser.admin_type !== 'transcriber_admin' && currentUser.admin_type !== global.ADMIN_TYPE.WOUND_NURSE_ADMIN && company_type !== global.COMPANY_TYPE.SNF">
                    <div class="mat-tab-content" *ngIf="selectedTabName === 'Nurse-Facility'">
                        <app-nurse-facility-association></app-nurse-facility-association>
                    </div>
                </mat-tab>
                <mat-tab [label]="currentCompany.currentCompanyType === global.COMPANY_TYPE.SNF ? 'View Only User-SNF-WC-NURSE' : 'View Only User-Provider'" *ngIf="currentUser.admin_type !== 'transcriber_admin' && currentUser.admin_type !== global.ADMIN_TYPE.WOUND_NURSE_ADMIN">
                    <div class="mat-tab-content" *ngIf="(selectedTabName === 'View Only User-SNF-WC-NURSE' || selectedTabName == 'View Only User-Provider')">
                        <app-view-only-users-provider-association></app-view-only-users-provider-association>
                    </div>
                </mat-tab>
                <mat-tab label="SNF-WC-Nurse" *ngIf="currentUser.admin_type !== 'transcriber_admin' && currentUser.admin_type !== global.ADMIN_TYPE.WOUND_NURSE_ADMIN && company_type !== global.COMPANY_TYPE.WOUND">
                    <div class="mat-tab-content" *ngIf="selectedTabName === 'SNF-WC-Nurse'">
                        <app-snf-wc-nurse-association> </app-snf-wc-nurse-association>
                    </div>
                </mat-tab>
                <mat-tab label="Wound-Nurse" *ngIf="currentUser.admin_type !== 'transcriber_admin' && company_type !== global.COMPANY_TYPE.SNF">
                    <div class="mat-tab-content" *ngIf="selectedTabName === 'Wound-Nurse'">
                        <app-wound-nurse-associations></app-wound-nurse-associations>
                    </div>
                </mat-tab>
                <mat-tab label="Provider-Provider" *ngIf="currentUser.admin_type !== 'transcriber_admin' && currentUser.admin_type !== global.ADMIN_TYPE.WOUND_NURSE_ADMIN && company_type !== global.COMPANY_TYPE.SNF">
                    <div class="mat-tab-content" *ngIf="selectedTabName === 'Provider-Provider'">
                        <app-provider-provider-association> </app-provider-provider-association>
                    </div>
                </mat-tab>
                <mat-tab label="Provider-Biller" *ngIf="currentUser.admin_type !== 'transcriber_admin' && currentUser.admin_type !== global.ADMIN_TYPE.WOUND_NURSE_ADMIN && company_type !== global.COMPANY_TYPE.SNF">
                    <div class="mat-tab-content" *ngIf="selectedTabName === 'Provider-Biller'">
                        <app-biller-provider-association></app-biller-provider-association>
                    </div>
                </mat-tab>
                <mat-tab label="Phrase Association" *ngIf="currentUser.admin_type !== 'transcriber_admin' && currentUser.admin_type !== global.ADMIN_TYPE.WOUND_NURSE_ADMIN && company_type !== global.COMPANY_TYPE.SNF">
                    <div class="mat-tab-content" *ngIf="selectedTabName === 'Phrase Association'">
                        <app-phrases-association></app-phrases-association>
                    </div>
                </mat-tab>
                <mat-tab label="Provider-MA-BO" *ngIf="currentUser.admin_type !== 'transcriber_admin' && currentUser.admin_type !== global.ADMIN_TYPE.WOUND_NURSE_ADMIN && company_type !== global.COMPANY_TYPE.SNF">
                    <div class="mat-tab-content" *ngIf="selectedTabName === 'Provider-MA-BO'">
                        <mat-tab-group>
                            <mat-tab label="Provider-MA">
                                <br>
                                <app-medical-assistant-association></app-medical-assistant-association>
                            </mat-tab>
                            <mat-tab label="Provider-BO">
                                <br>
                                <app-business-officer-association></app-business-officer-association>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </mat-tab>
                    <mat-tab label="Provider-Transcriber" *ngIf="company_type !== global.COMPANY_TYPE.WOUND">
                        <div class="mat-tab-content" *ngIf="selectedTabName === 'Provider-Transcriber'">
                            <div class="flex">
                                <app-input-with-dropdown class="flex-grow" [noDropdown]="true" placeholder="Search"
                                    (inputChange)="applyFilter($event.target.value)">
                                </app-input-with-dropdown>
                            </div>
                            <div class="br-spacing"></div>
                            <table mat-table [dataSource]="dataSource" matSort>

                                <ng-container matColumnDef="doctor_name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Providers Name</th>
                                    <td mat-cell *matCellDef="let element" style="width: 10%">
                                        {{element.first_name}} {{element.last_name}}
                                        <div style="font-size: 11px; white-space: nowrap;
                                        text-overflow: ellipsis;
                                        width: 190px;
                                        display: block;
                                        overflow: hidden;;"> {{getEmailId(element)}} </div>
                                    </td>
                                </ng-container>

]                                <ng-container matColumnDef="transcriber_name">
                                    <th mat-header-cell *matHeaderCellDef> Transcribers</th>
                                    <td mat-cell *matCellDef="let element" style="width: 40%">
                                        <app-mat-multi-select-chips [element]="element" [ngModelData]="element.transcribers || []" [isMulti]="true" [isProvider]="true" [allData]="transcribers" [providerKey]="'transcribers'" [selectAll]="true" (onDataSelection)="onDataSelection($event,'transcribers')" (onDataRemove)="onDataRemove($event,'transcribers')"></app-mat-multi-select-chips>
                                    </td>

                                </ng-container>
                                <ng-container matColumnDef="trusted_trans">
                                    <th mat-header-cell *matHeaderCellDef> Trusted MT</th>
                                    <td mat-cell *matCellDef="let element" style="width: 17%">
                                                <mat-form-field style="width: 100%">
                                        
                                                <mat-select [value]="element.trsutedElement ? element.trsutedElement[0]._id: ''" [ngModelOptions]="{standalone: true}" (selectionChange)="selectTrustedTrans($event.value, element)">
                                                      <ng-container *ngIf="element.transcribers || element.transcribers != undefined">
                                                        <mat-option *ngFor="let provider of element.transcribers" [value]="provider._id">{{provider.full_name}}</mat-option>

                                                    </ng-container>
                                                    <ng-container *ngIf="!element.transcribers || element.transcribers == undefined">
                                                        <mat-option>No data found</mat-option>

                                                    </ng-container>
                                                    
                                                </mat-select>
                                            </mat-form-field>
                                    </td>

                                </ng-container>

                                <ng-container matColumnDef="qa">
                                    <th mat-header-cell *matHeaderCellDef> QA</th>
                                    <td mat-cell *matCellDef="let element" style="width: 16%">
                                        <mat-form-field style="width: 100%">
                                        <mat-select [value]="element.qaElement ? element.qaElement[0]._id: ''" [ngModelOptions]="{standalone: true}" (selectionChange)="selectQA($event.value, element)">
                                              <ng-container *ngIf="element.only_QA || element.only_QA != undefined">
                                                <mat-option *ngFor="let provider of element.only_QA" [value]="provider._id">{{provider.full_name}}</mat-option>

                                            </ng-container>
                                            <ng-container *ngIf="!element.only_QA || element.only_QA == undefined">
                                                <mat-option>No data found</mat-option>

                                            </ng-container>
                                            
                                        </mat-select>
                                        </mat-form-field>
                                    </td>

                                </ng-container>
                                
                                <ng-container matColumnDef="qa_e">
                                    <th mat-header-cell *matHeaderCellDef> QA External</th>
                                    <td mat-cell *matCellDef="let element" style="width: 16%">
                                        <mat-form-field style="width: 100%">
                                            <mat-select [value]="element.qa_eElement ? element.qa_eElement[0]._id: ''" [ngModelOptions]="{standalone: true}" (selectionChange)="selectQAE($event.value, element)">
                                                  <ng-container *ngIf="element.only_QAE || element.only_QAE != undefined">
                                                    <mat-option *ngFor="let provider of element.only_QAE" [value]="provider._id">{{provider.full_name}}</mat-option>
    
                                                </ng-container>
                                                <ng-container *ngIf="!element.only_QAE || element.only_QAE == undefined">
                                                    <mat-option>No data found</mat-option>
    
                                                </ng-container>
                                                
                                            </mat-select>
                                        </mat-form-field>
                                    </td>

                                </ng-container>
                                <ng-container matColumnDef="qa_select">
                                    <th mat-header-cell *matHeaderCellDef> Select QA</th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="">
                                            <select class="select-style" style="padding: 8px 8px; width: 158px"
                                                (change)="changeQA($event.target.value, element)">
                                                <option value="0">Select QA.</option>
                                                <option *ngFor="let item of element.transcribers" [value]="item._id">
                                                    {{item.first_name + ' ' + item.last_name}}
                                                </option>
                                            </select>
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="qa_name">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Current QA
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <h3 *ngIf="element.qa" style="color: black;">{{element.qa.first_name + ' ' +
                                            element.qa.last_name}}</h3>
                                        <h3 *ngIf="element.qa === undefined || element.qa === null" style="color: black;">
                                            None
                                        </h3>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </div>
                    </mat-tab>
                <mat-tab label="Billing Company" *ngIf="currentUser.admin_type !== 'transcriber_admin' && currentUser.admin_type !== global.ADMIN_TYPE.WOUND_NURSE_ADMIN && company_type !== global.COMPANY_TYPE.SNF">
                    <div class="mat-tab-content" *ngIf="selectedTabName === 'Billing Company'">
                        <app-billing-company-association> </app-billing-company-association>
                    </div>
                </mat-tab>
                <mat-tab label="CPT-ICD Mapping" *ngIf="currentUser.admin_type !== 'transcriber_admin' && currentUser.admin_type !== global.ADMIN_TYPE.WOUND_NURSE_ADMIN && company_type !== global.COMPANY_TYPE.SNF">
                    <div class="mat-tab-content" *ngIf="selectedTabName === 'CPT-ICD Mapping'">
                        <app-cpt-icd-association></app-cpt-icd-association>
                    </div>
                </mat-tab>
                <mat-tab label="Companies Configration" *ngIf="currentUser.admin_type !== 'transcriber_admin' && currentUser.admin_type !== global.ADMIN_TYPE.WOUND_NURSE_ADMIN && company_type !== global.COMPANY_TYPE.SNF">
                    <div class="mat-tab-content" *ngIf="selectedTabName === 'Companies Configration'">
                        <app-companies-configration></app-companies-configration>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </mat-card>
</div>