import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MipsService } from 'src/app/services/Modules/mips.service';

export interface AddQuestionDialogData {
  measure_number:string;
  company_id;
  mipsQuestionObj;
}
@Component({
  selector: 'app-add-mips-question',
  templateUrl: './add-mips-question.component.html',
  styleUrls: ['./add-mips-question.component.css']
})
export class AddMipsQuestionComponent implements OnInit {
  measure_number:string;
  company_id;
  mips_measure_number=[]
  model: {
    measure_number:String,
    question:String,
    answer:String,
    performance_type: String,
    data_source: String,
    condition: String,
    modifier: String,
  }
  isEdit: boolean=false;
  constructor(
    private mipsService:MipsService,
    @Inject(MAT_DIALOG_DATA) public data: AddQuestionDialogData
  ) { 
    this.model = {
      measure_number: '',
      question: '',
      answer: '',
      performance_type: '',
      data_source: '',
      condition: '',
      modifier: '',
    }

    console.log("dataaaaaaaa : ", data);
    
    if(data&&data.mipsQuestionObj){
      this.isEdit=true;
      this.model = {
        measure_number: data.mipsQuestionObj.measure_number,
        question: data.mipsQuestionObj.question,
        answer: data.mipsQuestionObj.answer,
        performance_type: data.mipsQuestionObj.performance_type,
        data_source: data.mipsQuestionObj.data_source,
        condition: data.mipsQuestionObj.condition,
        modifier: data.mipsQuestionObj.modifier,
      }
      
    }
    this.company_id = data.company_id;

    if(data) {
      this.measure_number = data.measure_number;
    }
    if(this.measure_number) {
      this.model.measure_number = this.measure_number
    }
  }

  async ngOnInit() {
    await this.getAllMips();
  }
  async getAllMips() {
    let res: any = await this.mipsService.getAllMips(this.company_id).toPromise();
    if (res.status == 200) {
      res.data.array.forEach((item) => {
        console.log(item.quality_number);
        this.mips_measure_number.push(item.quality_number);
      })
    }
  }

  getMeasureNumbers() {
    return this.mips_measure_number;
  }
  

  isValid() {
     return (this.model.measure_number.length > 0 &&  this.model.question.length > 0)
  }



}
