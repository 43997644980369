<div style="margin-top: 15px;">
    <mat-form-field
        style="width: 100%; background: #f4f5f8;border-radius: 10px; padding: 5px; margin-bottom: 0px !important;"
        appearance="fill">
        <mat-label>Biller Note</mat-label>
        
        <input matInput type="text" name="visit_date" 
            [(ngModel)]="model.note"  
            (ngModelChange)="changeBillerNote()"
            placeholder="description"
            name="description">
    </mat-form-field>
</div>