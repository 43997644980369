<div class="container flex flex-col gap-2">
    <div class="flex justify-end items-center gap-2">
        <button mat-flat-button mat-button style="background-color: #43adff;color: white;" class="dn-button-primary-fix"
            (click)="clickTab()">Add Phrase</button>
    </div>
    <mat-tab-group [(selectedIndex)]="selectedIndex" mat-align-tabs="left" animationDuration="0ms" class="h100"
        style="background-color: white">
        <mat-tab class="h100" label="Company Phrases" style="height: calc(100vh - 137px)">
            <div class="filter-form white-rounded-rect flex flex-col gap-2"
                style="height: calc(100vh - 141px)">

                <div class="flex justify-between items-center gap-2">
                    <mat-form-field style="color: black !important;max-width: 180px;">
                        <mat-label *ngIf="company_type != global.COMPANY_TYPE.SNF">Select Provider</mat-label>
                        <mat-label *ngIf="company_type === global.COMPANY_TYPE.SNF">Select SNF WC NURSE</mat-label>
                        <mat-select [value]="selectedProvider?._id" name="type">
                            <input placeholder="Search" [(ngModel)]="searchText"
                                [ngModelOptions]="{standalone: true}" class="select-input-class" />
                            <mat-option *ngFor="let provider of providers | searchProvider : searchText"
                                [value]="provider._id" (onSelectionChange)="onProviderClick($event, provider);">
                                {{provider.first_name}} {{provider.last_name}}, {{provider.title}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>


                    <app-input-with-dropdown class="flex-grow" [noDropdown]="true" placeholder="Search Phrases"
                        (inputChange)="applyFilter($event.target.value);searchValue=$event.target.value">
                    </app-input-with-dropdown>

                    <div class="justify-start items-center gap-2">
                        <div class="flex flex-grow justify-center items-center gap-2 table-nav">
                            <button mat-raised-button class="dn-button-primary" (click)="goToPreviousPage()"
                                [disabled]="!pagination.hasPrevPage"> Prev</button>
                            <span style="width: 120px">
                                {{dictionary?.length > 0 ? (pagination.currentRecords - dictionary?.length + 1) : 0}} -
                                {{pagination.currentRecords}} out of {{pagination.totalPhrases}}</span>

                            <button mat-raised-button class="dn-button-primary" (click)="goToNextPage()"
                                [disabled]="!pagination.hasNextPage || pagination.currentRecords === pagination.totalPhrases">Next</button>
                        </div>
                    </div>
                    <button 
                        mat-raised-button 
                        class="dn-button-primary" 
                        (click)="onShareAllPhrases()"
                        [disabled]="dictionary && dictionary.length == 0">Share All Phrases
                    </button>
                    <div>
                        <button mat-flat-button mat-button color="primary" style="background-color: #43adff;"
                            (click)="addToProvider()">
                            {{company_type === global.COMPANY_TYPE.SNF ? 'Share with SNF WC NURSE' : 'Share with Provider'}}
                        </button>
                    </div>
                </div>

                <table mat-table [dataSource]="dataSourceUserPhrases" matSort>
                    <ng-container matColumnDef="select">
                        <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>
                            <mat-checkbox [value]="-1" [(ngModel)]="masterSelected" (change)="onchangeMaster($event)">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let element;  let i = index">
                            <mat-checkbox value="{{i}}" [checked]='element.select' (change)="onchange(element,$event)">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="shortcut">
                        <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container> Shortcut
                        </th>
                        <td mat-cell *matCellDef="let element;  let i = index">{{element?.key ? getMarker() +
                            element.key : ''}} </td>
                    </ng-container>
                    <ng-container matColumnDef="operations">
                        <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container></th>
                        <ng-container *matCellDef="let element">
                            <td mat-cell style="max-width: 120px" class="buttons-div">
                                <div class="operation-buttons buttons-div" class="flex flex-inline justify-start items-center gap-5">
                                    <span class="material-icons" matTooltip="View Phrase" matTooltipPosition="above"
                                        (click)="showDictionaryEditorDialog(element)" style="cursor: pointer;">
                                        remove_red_eye
                                    </span>
                                    <span *ngIf="company.portal_type !== global.PORTAL_TYPE.NON_WC || (company.portal_type === global.PORTAL_TYPE.NON_WC && currentUser._id == phrases_user_id ) "
                                        class="material-icons" (click)="showDictionaryEditorDialog(element)" matTooltip="Edit Phrase"
                                        matTooltipPosition="above" style="cursor: pointer;">
                                        edit_note
                                    </span>
                                    <span *ngIf="company.portal_type !== global.PORTAL_TYPE.NON_WC || (company.portal_type === global.PORTAL_TYPE.NON_WC && currentUser._id == phrases_user_id ) "
                                        class="material-icons" matTooltip="Delete Phrase" (click)="deleteFromNoteBuilderDictionary(element)"
                                        matTooltipPosition="above" style="cursor: pointer;">
                                        delete_outline
                                    </span>

                                </div>
                            </td>
                        </ng-container>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsUserPhrases"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsUserPhrases"></tr>
                </table>
            </div>
        </mat-tab>
        <mat-tab class="h100" label="System Defined Phrases" style="height: calc(100vh - 137px)">
            <div class="filter-form white-rounded-rect flex flex-col gap-2"
                style="height: calc(100vh - 141px)">
                <app-input-with-dropdown [noDropdown]="true" placeholder="Search Phrases"
                    (inputChange)="applySystemPhrasesFilter($event.target.value);searchValue=$event.target.value">
                </app-input-with-dropdown>
                <table mat-table [dataSource]="dataSourceSystemPhrases" matSort>
                    <ng-container matColumnDef="sr">
                        <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container>Sr. </th>
                        <td mat-cell *matCellDef="let element;  let i = index"> {{i+1}} </td>
                    </ng-container>
                    <ng-container matColumnDef="shortcut">
                        <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container> Shortcut
                        </th>
                        <td mat-cell *matCellDef="let element;  let i = index">{{element?.key ? getMarker() +
                            element.key : ''}} </td>
                    </ng-container>

                    <ng-container matColumnDef="operations">
                        <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header-container></th>
                        <ng-container *matCellDef="let element">
                            <td mat-cell style="max-width: 120px" class="buttons-div">
                                <div class="operation-buttons buttons-div flex flex-inline justify-start items-center gap-5">
                                    <span class="material-icons" (click)="editSystemPhrase(element.key,element._id)"
                                        matTooltip="View Phrase" matTooltipPosition="above" style="cursor: pointer;">
                                        remove_red_eye
                                    </span>
                                    <span class="material-icons" matTooltip="Delete Phrase"
                                        (click)="deleteSystemPhrase(element._id)" matTooltipPosition="above"
                                        style="cursor: pointer;">
                                        delete_outline
                                    </span>

                                </div>
                            </td>
                        </ng-container>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsSystemPhrases"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsSystemPhrases"></tr>
                </table>
            </div>
        </mat-tab>
    </mat-tab-group>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

</div>