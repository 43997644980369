<div class="container">
  <mat-card>
    <mat-card-content>
      <div style="text-align: end;">
        <button mat-raised-button class="dn-button-primary" (click)="createNewTab()">Create New Order Set</button>
      </div>

      <div *ngIf="tabs.length > 0">
        <mat-tab-group>
          <mat-tab *ngFor="let tab of tabs; let tabIndex = index">
            <ng-template mat-tab-label>
              <ng-container *ngIf="!tab.editMode; else editTemplate">
                <div (dblclick)="toggleEditMode(tab)" class="tab-label">{{ tab.title }}</div>
              </ng-container>

              <ng-template #editTemplate>
                <input [(ngModel)]="tab.title" class="edit-template-input" />
                <mat-icon class="example-tab-icon" (click)="saveTabName(tab)">save</mat-icon>
                <mat-icon class="example-tab-icon" (click)="deleteTab(tabIndex,tab._id)">delete</mat-icon>
              </ng-template>
            </ng-template>


            <button style="margin-top: 0.5rem;margin-bottom: 0.5rem;" mat-raised-button class="dn-button-primary"
              (click)="addCheckbox(tabIndex)">Create New Order</button>

            <div *ngFor="let checkbox of tab.order; let checkboxIndex = index" class="checkbox-item"
              (mouseenter)="toggleIcons(tabIndex, checkboxIndex, true)"
              (mouseleave)="toggleIcons(tabIndex, checkboxIndex, false)">
              <div>
              <span class="checkbox-name" [hidden]="showInput[tabIndex][checkboxIndex]"
               (click)="toggleInput(tabIndex, checkboxIndex, true)">{{ checkbox.name }}</span>
                <input type="text" placeholder="Enter Name" [(ngModel)]="checkbox.name" [hidden]="!showInput[tabIndex][checkboxIndex]"/>
                  <div *ngIf="!checkbox.name && showInput[tabIndex][checkboxIndex]" class="validation-error">
                    Name is required.
                </div>
                  <div>
                    <span class="checkbox-name" style="font-size: 14px;
                    font-weight: 400;" [hidden]="showInput[tabIndex][checkboxIndex]"
                    (click)="toggleInput(tabIndex, checkboxIndex, true)">{{ checkbox.description }}</span>
                    <div *ngIf="showInput[tabIndex][checkboxIndex]" class="description-input">
                      <input type="text"  placeholder="Enter Description" [(ngModel)]="checkbox.description" placeholder="Add a description" />
                    </div>
                  </div>
              </div>

              <mat-icon *ngIf="showInput[tabIndex][checkboxIndex]" (click)="saveDescription(tabIndex, checkboxIndex,tab._id)">save</mat-icon>
              <i class="icon icon-edit" *ngIf="showIcons[tabIndex][checkboxIndex] && !showInput[tabIndex][checkboxIndex]" matTooltip="Edit"
                (click)="toggleInput(tabIndex, checkboxIndex, true)" matTooltipPosition="above"></i>
              <i *ngIf="showIcons[tabIndex][checkboxIndex]" class="icon icon-delete" matTooltip="Delete"
                matTooltipPosition="above" (click)="deleteCheckbox(tabIndex, checkboxIndex,tab._id)"></i>
            </div>

          </mat-tab>
        </mat-tab-group>
      </div>
    </mat-card-content>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
  </mat-card>
</div>