<div [ngClass]="{'container': (companySide || user_dashboard) && !(nursingSide)}">
    <mat-card class="vertical-height">
        <div class="flex items-center justify-start gap-1">
            <div *ngIf="!user_dashboard">
                <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
                    Back
                </button>
            </div>
            <div *ngIf="!user_dashboard">
                <h2 style="width: 100px;">QAPI Report</h2>
            </div>
            <div class="flex gap-1 flex-grow">
                <mat-form-field class="patients-page">
                    <mat-select [value]="filter.reportType" (selectionChange)="handleReportChange($event.value)">
                        <mat-option value="improving">
                            Wound Improving
                        </mat-option>
                        <mat-option value="stalled">
                            Wound Stalled
                        </mat-option>
                        <mat-option value="deteriorating">
                            Wound Deteriorating
                        </mat-option>
                        <mat-option value="remission">
                            Wound In Remission
                        </mat-option>
                        <mat-option value="amputation">
                            Amputation
                        </mat-option>
                        <mat-option value="clinical-summary" *ngIf="!user_dashboard">
                            Clinical Summary
                        </mat-option>
                        <mat-option value="operations-summary" *ngIf="!user_dashboard">
                            Operations Summary
                        </mat-option>
                        <mat-option value="rehospitalization" *ngIf="!user_dashboard">
                            Rehospitalization
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" *ngIf="filter.reportType !== 'rehospitalization'">
                    <mat-label style="color:#999">Date of Service </mat-label>
                    <input matInput *ngIf="iscreationDateFilterSelected" type="text" ngxDaterangepickerMd
                        [(ngModel)]="filter.creationDate" (datesUpdated)="changeCreationDate($event)"
                        class="mat-like-input" [ranges]="preDateRanges" [alwaysShowCalendars]="true"
                        [maxDate]="currentDate" [linkedCalendars]="true" [showClearButton]="true" />
                </mat-form-field>
               
                <mat-form-field appearance="fill" class="flex-grow" *ngIf="filter.reportType !== 'rehospitalization'">
                    <mat-label>Select Facility</mat-label>
                    <input type="text" placeholder="Select Facility" aria-label="facility" matInput
                        [formControl]="facilityControl" [matAutocomplete]="facilityAutoControl">
                    <mat-autocomplete #facilityAutoControl="matAutocomplete" autoActiveFirstOption>
                        <mat-option (onSelectionChange)="onSelectFacility($event, facility);"
                            *ngFor="let facility of filteredFacilities | async" [value]="facility.title">
                            <app-facility-title [facility]="facility"></app-facility-title>
                        </mat-option>
                    </mat-autocomplete>

                </mat-form-field>
                <button mat-raised-button class="dn-button-primary" (click)="handleApplyFilter();">Apply Filter
                </button>

                <button mat-raised-button class="dn-button-secondary" (click)="resetFilter();">Reset Filter
                </button>

                <button *ngIf="!user_dashboard" mat-raised-button class="dn-button-primary"
                    (click)="exportQapiReportAsXLSX();">Export
                    As XLSX
                </button>
                <button mat-raised-button class="dn-button-primary" (click)="goToPreviousPage()"
                    [disabled]="!pagination.hasPrevPage">
                    Prev</button>
                <span>
                    {{pagination.skip + qapiReport?.length === 0 ? 0 : pagination.skip + 1 }} - {{pagination.skip +
                    qapiReport?.length}} out
                    of {{pagination.totalRecords}}
                </span>
                <button mat-raised-button class="dn-button-primary" (click)="goToNextPage()"
                    [disabled]="!pagination.hasNextPage">Next</button>
            </div>
        </div>
        <div class="table-height">
            <table *ngIf="filter.reportType !== 'rehospitalization' 
                && filter.reportType !== 'clinical-summary' 
                && filter.reportType !== 'operations-summary' 
                && filter.reportType !== 'amputation'" mat-table [dataSource]="dataSource" class="mat-elevation-z8"
                matSort>
                <ng-container matColumnDef="assessment_date">
                    <th mat-header-cell *matHeaderCellDef> Last Assessment </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.assessment_date}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="first_name">
                    <th mat-header-cell *matHeaderCellDef> First Name </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.patient_id.first_name}}
                    </td>
                </ng-container>


                <ng-container matColumnDef="last_name">
                    <th mat-header-cell *matHeaderCellDef> Last Name </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.patient_id.last_name}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="woundNo">
                    <th mat-header-cell *matHeaderCellDef> Wound # </th>
                    <td mat-cell *matCellDef="let element; let i = index;" style="cursor: pointer;">
                        {{element.woundNo}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="body_part">
                    <th mat-header-cell *matHeaderCellDef> Location </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.location}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="etiolgy">
                    <th mat-header-cell *matHeaderCellDef> Etiolgy </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.etiolgy}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="stage">
                    <th mat-header-cell *matHeaderCellDef> Severity </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.stage}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="diffInWeek">
                    <th mat-header-cell *matHeaderCellDef> Weeks </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.diffInWeek}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="area_change">
                    <th mat-header-cell *matHeaderCellDef> Area Change </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.area_change}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <app-clinical-summary-report-v2 *ngIf="isClinicalSummaryReportVisible" [clinicalSummaryReport]="qapiReport"
                [facilityTitle]="facilityTitle">
            </app-clinical-summary-report-v2>

            <app-operations-summary-report-v2 *ngIf="isOperationsSummaryReportVisible"
                [operationsSummaryReport]="qapiReport"
                [facilityTitle]="facilityTitle"></app-operations-summary-report-v2>

            <app-amputation-report-v2 *ngIf="isAmputationReportVisible" [amputationReport]="qapiReport">
            </app-amputation-report-v2>

            <app-rehospitalization-report-v2 *ngIf="isRehospitalizationReportVisible"
                [rehospitalizationReport]="qapiReport">
            </app-rehospitalization-report-v2>

            <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
        </div>
    </mat-card>
</div>