import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { lastValueFrom } from 'rxjs';
import { CptIcdAssociationService } from 'src/app/includes/cpt-icd-association/cpt-icd-association.service';
import { WoundEtiology } from 'src/app/includes/wound/wound-groups/wound-groups.component';
import { WoundTag } from 'src/app/includes/wound/wound-tags/wound-tags.component';
import { WoundService } from 'src/app/services/wound.service';
import { WoundCareWidgetService } from '../wound-care-widget.service';
import { getFieldName } from '../wound-utils';
import moment from 'moment';

export interface WoundEtiologyGroupsWithTags {
  _id: {
    _id: string,
    title: string
  },
  tags: WoundTag[],

  deletable?: boolean,
}

export interface WoundDiagnosis {
  wound_id: string,
  selected_data: [{
    wound_group_id: string,
    icd_id: any,
    wound_tag: string
  }]
}
@Component({
  selector: 'app-wound-icd10-dialog',
  templateUrl: './wound-icd10-dialog.component.html',
  styleUrls: ['./wound-icd10-dialog.component.css']
})
export class WoundICD10DialogComponent implements OnInit {
  loaderId = 'wound-icd10-dialog-loader';
  provider;
  patient;
  wound;
  selectedDataset = {
    etiolgy: '',
    body_part: '',
    stage: '',
    body_side: '',
    state: '',
    direction: '',
    wound_recognition_date: null,
    wound_recognition_date_type: ''
  }

  woundEtiologyGroupsWithTags: WoundEtiologyGroupsWithTags[] = [];
  woundEtiologies: WoundEtiology[] = [];

  selectedWoundEtiology: WoundEtiology;

  selectedWoundGroupTag: {
    [key: string]: WoundTag
  } = {};

  woundGroupDetailICDs: {
    [key: string]: any
  } = {};

  selectedWoundGroupDetailsICD: {
    [key: string]: WoundTag
  } = {};
  previousSelectedWoundGroupDetailsICD: {
    [key: string]: WoundTag
  } = {};


  icdSearchQuery: {
    [key: string]: string
  } = {};
  tempSelectedWoundGroupTags: {
    [key: string]: WoundTag
  } = {};

  woundDiagnosis: WoundDiagnosis;

  initializing = false;
  constructor(
    private loader: NgxUiLoaderService,
    private _woundService: WoundService,
    public dialogRef: MatDialogRef<WoundICD10DialogComponent>,
    private _cptIcdAssociationService: CptIcdAssociationService,
    @Inject(MAT_DIALOG_DATA) public data: any, private woundCareWidgetService: WoundCareWidgetService,
  ) {
    if (this.data) {
      this.provider = data.provider;
      this.patient = data.patient;
      this.wound = data.wound;
      this.selectedDataset = data.selectedDataset;
      if (!this.selectedDataset.wound_recognition_date) {
        if (this.patient.pcc_payload) {
          this.selectedDataset.wound_recognition_date = new Date(this.patient.pcc_payload.admissionDate);
        } else {
          this.selectedDataset.wound_recognition_date = new Date();
        }
      }
    }
  }

  async ngOnInit() {
    this.initializing = true;
    this.loader.startLoader(this.loaderId);
    await this.initWoundEtiologies(undefined, null);
    await this.initWoundDiagnosis();
    if (this.woundDiagnosis) {
      for (const woundGroupSelectedData of this.woundDiagnosis.selected_data) {
        const { wound_group_id, icd_id, wound_tag } = woundGroupSelectedData;

        if (!wound_tag) {
          this.icdSearchQuery[wound_group_id] = '';
        }
        if (icd_id) {
          if (!Array.isArray(this.woundGroupDetailICDs[wound_group_id])) this.woundGroupDetailICDs[wound_group_id] = [];
          const exists = this.woundGroupDetailICDs[wound_group_id].find(icd => icd._id === woundGroupSelectedData.icd_id._id);
          if (!exists) {
            this.woundGroupDetailICDs[wound_group_id].push(icd_id)
            this.selectWoundGroupDetailsICD(icd_id, wound_group_id, false);
          }
        }
      }
    }


    // derive diagnosis group value from wound dataset
    if (!this.selectedWoundEtiology && this.selectedDataset.etiolgy) {
      const selectedWoundEtiology = this.woundEtiologies.find(x => x.label === this.selectedDataset.etiolgy);
      if (selectedWoundEtiology) {
        await this.handleWoundEtiologyChange({ value: selectedWoundEtiology });
      }
    }
    // derive diagnosis tag value from wound dataset
    // if tag is not selected
    for (const woundEtiologyGroupWithTags of this.woundEtiologyGroupsWithTags) {
      const tags = woundEtiologyGroupWithTags.tags;
      const woundGroupId = woundEtiologyGroupWithTags._id._id;
      if (!this.selectedWoundGroupTag[woundGroupId]) {
        const bodySides = {
          L: 'Left',
          R: 'Right',
          BL: 'Bilateral'
        }
        const tag = tags.find(x => x.label === bodySides[this.selectedDataset.body_side]);
        if (tag) {
          await this.selectWoundGroupTag(tag, woundGroupId);
        }
      }
    }

    this.loader.stopLoader(this.loaderId);
    this.initializing = false;
  }

  async initWoundDiagnosis() {
    const { data, status } = await lastValueFrom(this._woundService.getWoundDiagnosis(this.wound._id)) as any;

    if (status == 200 && data) {
      this.woundDiagnosis = data;
      const selectedWoundEtiology = this.woundEtiologies.find(x => x._id === data.etiology_id);
      if (selectedWoundEtiology) {
        await this.handleWoundEtiologyChange({ value: selectedWoundEtiology });
      }
    }
  }
  async initWoundEtiologies(search = '', limit = 10) {
    const { data, status } = await lastValueFrom(this._woundService.getWoundEtiologies(this.provider.company_id, search, limit)) as any;
    if (status == 200 && data) {
      this.woundEtiologies = data;
    }
    if (this.selectedWoundEtiology) {
      const exists = this.woundEtiologies.find(x => x._id === this.selectedWoundEtiology._id);
      if (!exists) {
        this.woundEtiologies.push(this.selectedWoundEtiology);
      }
    }
  }

  async handleWoundEtiologyChange($event) {
    this.selectedWoundEtiology = $event.value;
    this.selectedWoundGroupDetailsICD = {};
    await this.initWoundEtiologyGroupsWithTags(this.selectedWoundEtiology);
  }

  async searchWoundEtiologies(value) {
    await this.initWoundEtiologies(value, 10);
  }

  async initWoundEtiologyGroupsWithTags(woundEtiology) {
    const { data, status } = await lastValueFrom(this._woundService.getWoundEtiologyGroupsWithTags(woundEtiology._id)) as any;
    if (status == 200 && data) {
      this.woundEtiologyGroupsWithTags = data;

      for (const woundGroup of this.woundEtiologyGroupsWithTags) {
        const woundGroupId = woundGroup._id._id;
        const woundGroupSelectedData = this.woundDiagnosis?.selected_data.find(x => x.wound_group_id === woundGroupId);
        if (woundGroupSelectedData) {
          const { icd_id, wound_tag } = woundGroupSelectedData;

          const selected_wound_tag = woundGroup.tags.find(tag => tag._id === wound_tag);
          if (selected_wound_tag) {
            await this.selectWoundGroupTag(selected_wound_tag, woundGroupId);

            if (this.woundGroupDetailICDs[woundGroupId] && icd_id) {
              const selected_icd = this.woundGroupDetailICDs[woundGroupId].find(x => x._id === icd_id._id);
              if (selected_icd) {
                this.selectWoundGroupDetailsICD(selected_icd, woundGroupId, false);
              } else {
                this.woundGroupDetailICDs[woundGroupId].push(icd_id);
                this.selectWoundGroupDetailsICD(icd_id, woundGroupId, false);
              }
            }
          }


        }
      }
    }
  }


  async updateWoundData() {
    // const {status, data} = await lastValueFrom(this.woundCareWidgetService.updateWoundData(this.wound._id, this.selectedDataset));
    // if(status == 200) {
    //   for (const key in this.selectedDataset) {
    //     if (Object.prototype.hasOwnProperty.call(this.selectedDataset, key)) {
    //       this.wound[key] = this.selectedDataset[key];
    //     }
    //   }
    // }
  }
  async updateWoundDataAndClose() {
    // await this.updateWoundData();
    // this.dialogRef.close(this.selectedDataset);
  }

  async saveWoundDiagnosisAndClose() {
    const woundDiagnosis = await this.saveWoundDiagnosis();
    this.dialogRef.close({
      woundDiagnosis,
      selectedWoundGroupDetailsICD: this.selectedWoundGroupDetailsICD,
      previousSelectedWoundGroupDetailsICD: this.previousSelectedWoundGroupDetailsICD
    });
  }

  isWoundGroupTagSelected(tag, woundGroupId) {
    return this.selectedWoundGroupTag[woundGroupId]?._id === tag._id
  }
  async selectWoundGroupTag(tag, woundGroupId) {
    this.selectedWoundGroupTag[woundGroupId] = tag;
    await this.initWoundEtiologyGroupDetails(tag, woundGroupId);
  }

  async initWoundEtiologyGroupDetails(tag, woundGroupId) {
    // this.selectedWoundGroupDetailICD
    if (this.selectedWoundGroupDetailsICD[woundGroupId]) {
      delete this.selectedWoundGroupDetailsICD[woundGroupId];
    }
    const { data, status } = await lastValueFrom(this._woundService.getWoundEtiologyGroupDetailsICDs(woundGroupId, [tag._id])) as any;
    if (status == 200 && data) {
      const woundEtiologyICDS = data.map(item => item.icd_id);
      const searchedICDS = woundEtiologyICDS.map(icd => {
        if (!icd.end_date)
          icd.end_date = "";
        const [month, day, year] = icd.end_date.split("/");
        if (icd.end_date != "" && moment(`${year}-${month}-${day}`).isBefore(moment()))
          icd.status = 'Expired';
        else
          icd.status = 'Active';

        return icd;
      });
      this.woundGroupDetailICDs[woundGroupId] = searchedICDS.filter(icd => icd.status == 'Active');
    }
  }


  isWoundGroupDetailsICDSelected(icd, woundGroupId) {
    return this.selectedWoundGroupDetailsICD[woundGroupId]?._id === icd._id
  }
  selectWoundGroupDetailsICD(icd, woundGroupId, selectPrevious = true) {

    if (selectPrevious) {
      if ((woundGroupId in this.selectedWoundGroupDetailsICD)) {
        this.previousSelectedWoundGroupDetailsICD[woundGroupId] = JSON.parse(JSON.stringify(this.selectedWoundGroupDetailsICD[woundGroupId]));
      }
    }
    this.selectedWoundGroupDetailsICD[woundGroupId] = JSON.parse(JSON.stringify(icd));
  }
  removeWoundGroupDetailsICD(icd, woundGroupId) {
    this.removeWoundGroupDetailsICD[woundGroupId] = this.selectedWoundGroupDetailsICD[woundGroupId];
    this.selectedWoundGroupDetailsICD[woundGroupId] = null;
  }
  async saveWoundDiagnosis() {
    const selected_data = this.woundEtiologyGroupsWithTags.map((woundGroup: any) => {
      const woundGroupId = woundGroup?._id?._id;
      return {
        wound_group_id: woundGroupId,
        wound_tag: this.selectedWoundGroupTag[woundGroupId]?._id || null,
        icd_id: this.selectedWoundGroupDetailsICD[woundGroupId]?._id,
      }
    })
    if (this.woundDiagnosis) {
      const { status, data } = await lastValueFrom(this._woundService.updateWoundDiagnosis(this.wound._id, this.selectedWoundEtiology._id, selected_data)) as any;
      if (status == 200) {
        return data;
      }
    } else {
      const { status, data } = await lastValueFrom(this._woundService.addWoundDiagnosis(this.wound._id, this.selectedWoundEtiology._id, selected_data)) as any;
      if (status == 200) {
        return data;
      }
    }
  }

  isICDSearchMode(woundGroupId: string) {
    return this.icdSearchQuery[woundGroupId] !== undefined;
  }
  toggleICDSearchMode(woundGroupId: string,) {
    if (this.icdSearchQuery[woundGroupId] === undefined) {
      this.icdSearchQuery[woundGroupId] = '';
      this.tempSelectedWoundGroupTags[woundGroupId] = this.selectedWoundGroupTag[woundGroupId];
      this.selectedWoundGroupTag[woundGroupId] = null;
    } else {
      this.icdSearchQuery[woundGroupId] = undefined;
      if (this.tempSelectedWoundGroupTags[woundGroupId]) this.selectedWoundGroupTag[woundGroupId] = this.tempSelectedWoundGroupTags[woundGroupId];
    }
  }
  async searchNewDiagnose(value, woundGroupId) {
    // console.log(value,woundGroupId)
    const details = {
      search: value
    }
    const { data, status } = await lastValueFrom(this._cptIcdAssociationService.getICDs(details)) as any;
    if(status == 200) {
      const searchedICDS = data.array.map(icd => {
        if (!icd.end_date)
          icd.end_date = "";
        const [month, day, year] = icd.end_date.split("/");
        if (icd.end_date != "" && moment(`${year}-${month}-${day}`).isBefore(moment()))
          icd.status = 'Expired';
        else
          icd.status = 'Active';

        return icd;
      });
      this.woundGroupDetailICDs[woundGroupId] = searchedICDS.filter(icd => icd.status == 'Active');
    }
  }

  async addNewWoundGroup() {
    const newWoundGroupName = `Diagnosis ${this.woundEtiologyGroupsWithTags.length + 1}`;
    const { data, status } = await lastValueFrom(this._woundService.addWoundEtiologyGroup(this.selectedWoundEtiology._id, newWoundGroupName)) as any;
    if (status == 200) {
      this.woundEtiologyGroupsWithTags.push({
        _id: {
          _id: data._id,
          title: data.title
        },
        tags: [],
        deletable: true
      });
      this.selectedWoundGroupTag[data._id] = null;
      this.selectedWoundGroupDetailsICD[data._id] = null;
      this.icdSearchQuery[data._id] = '';
    }
  }

  async deleteWoundGroup(woundGroup) {
    const { status, data } = await lastValueFrom(this._woundService.updateWoundEtiologyGroup(woundGroup._id._id, undefined, true)) as any;
    if (status == 200) {
      const index = this.woundEtiologyGroupsWithTags.findIndex(_woundGroup => _woundGroup._id._id === woundGroup._id._id);
      this.woundEtiologyGroupsWithTags.splice(index, 1);
    }
  }

  isValid() {
    for (let woundGroup of this.woundEtiologyGroupsWithTags) {
      const wound_group_id = woundGroup._id._id;
      if (this.selectedWoundEtiology && this.selectedWoundGroupDetailsICD[wound_group_id]) {
        return true;
      }
    }
    return false;
  }
}
