import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import * as _ from 'lodash';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-mips-questionnaire-126',
  templateUrl: './mips-questionnaire-126.component.html',
  styleUrls: ['./mips-questionnaire-126.component.css'],
})
export class MipsQuestionnaire126Component implements OnInit, DoCheck {
  @Input() measure;
  @Output() onAnswerCalculation = new EventEmitter<any>();
  questions: any[] = [];

  currentQuestionIndex = 0;
  answer: string;
  answerDescription: string;
  currentUser: any;
  questionAnswerMap: any = {};

  options:string[] = [
    'Lower extremity neurological exam performed.',
    'Lower extremity neurological exam not performed.'
  ]

  model: any;

  constructor(
    private authService: AuthService,
  ) {
    this.currentUser = this.authService.currentUser;
    this.resetModel();
  }
  resetModel() {
    this.model = {
      button: null,
      ANSWER: "",
    };

    this.answer = null;
    this.answerDescription = null;
  }
  resetAnswer() {
    (this.answer = null), (this.answerDescription = null);
  }
  ngDoCheck(): void {
    this.doCheckAnswer();
  }

  ngOnInit(): void {
    this.initQuestions();
  }

  doCheckAnswer() {
    if (this.model.ANSWER === this.options[0]) {
      this.answer = 'G8404';
      this.answerDescription =
        'Lower extremity neurological exam performed.';
      return this.doneAnswer();
    }
    if (this.model.ANSWER === this.options[1]) {
      this.answer = 'G8405';
      this.answerDescription =
        'Lower extremity neurological exam not performed.';
      return this.doneAnswer();
    }
    
    this.resetAnswer();
  }
  initQuestions() {}


  doneAnswer() {
    if(this.answer) {
      
      this.onAnswerCalculation.emit({
        question: {
          measure_number: this.measure.quality_number,
          answer: this.answer,
        },
        goToNext: false
      });
    }
  }

  handleDoneClick() {
    this.onAnswerCalculation.emit({
      question: {
        measure_number: this.measure.quality_number,
        answer: this.answer,
      },
      goToNext: false
    });
  }

  isNegativeCode(answer) {
    
    return [
      'G8405'
    ].includes(answer.trim().toUpperCase());
  }
}
