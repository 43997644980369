import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {TemplatesService} from "../../../includes/templates/templates.service";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-dictionary-dialog',
    templateUrl: './dictionary-dialog.component.html',
    styleUrls: ['./dictionary-dialog.component.css']
})
export class DictionaryDialogComponent implements OnInit {
    phrase: any;

    constructor(public dialogRef: MatDialogRef<DictionaryDialogComponent>,
                private toastr: ToastrService,
                private templateService: TemplatesService) {
    }

    ngOnInit(): void {
    }

    addPhrase(form) {

        let data = {
          phrases: form.facility_title
        };
        this.templateService.addPhrase(data).subscribe((res) => {

            this.phrase = null;
            this.toastr.success('Phrased saved', 'Success');

        }, error => {

        })
    }

}
