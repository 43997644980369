<div class="flex flex-grow gap-2" style="overflow: auto; background: white;">
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="sr">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element;  let i = index;" style="cursor: pointer;" > 
                {{i+1}}
            </td>
        </ng-container> 
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element" style="cursor: pointer;" > 
                {{getDate(element)}}
            </td>
        </ng-container>
        <ng-container matColumnDef="dos">
            <th mat-header-cell *matHeaderCellDef>DOS</th>
            <td mat-cell *matCellDef="let element" style="cursor: pointer;" >
                {{getServiceDate(element)}}
            </td>
        </ng-container>
        <ng-container matColumnDef="patient">
            <th mat-header-cell *matHeaderCellDef>Patient</th>
            <td mat-cell *matCellDef="let element" style="cursor: pointer;" >
                {{getPatientName(element)}}
            </td>
        </ng-container>
        <ng-container matColumnDef="facility">
            <th mat-header-cell *matHeaderCellDef>Facility</th>
            <td mat-cell *matCellDef="let element" style="cursor: pointer;" > 
            {{commonService.cleanTranscriptionTitle(element.facility_id_ref?.title) | uppercase}}
            </td>
        </ng-container>
       
        <ng-container matColumnDef="creator">
            <th mat-header-cell *matHeaderCellDef>Creator</th>
            <td mat-cell *matCellDef="let element" style="cursor: pointer;" >
                {{getCreatorName(element)}}
            </td>
        </ng-container> 
        <ng-container matColumnDef="report_type">
            <th mat-header-cell *matHeaderCellDef>Note Status</th>
            <td mat-cell *matCellDef="let element" style="cursor: pointer;" >
                {{element.note_status? getNoteStatus(element.note_status.status) : ''}}
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
    </table>
</div>