<div class="h100 flex xs:flex-col items-center justify-center">
  <mat-card class="w-2/5 xs:w-full">

    <mat-card-title class="flex flex-col justify-center items-center">
      <img [src]="companylogoPath ? companylogoPath : defaultImg">
      <h2>Forgot your password?</h2>
    </mat-card-title>

    <mat-card-content class="flex flex-col">

      <form (submit)="onForgetPassword(forgetPasswordForm)" #forgetPasswordForm="ngForm">
        <mat-error *ngIf="status != 200 && status!= null">Invalid Email!</mat-error>
        <mat-form-field>
          <input matInput type="text" name="email" placeholder="Email address" #email="ngModel" ngModel required>
          <mat-error *ngIf="email.invalid">Please enter email</mat-error>
        </mat-form-field>

        <div class="flex justify-center items-center">
          <button class="theme-btn w-1/2" mat-stroked-button type="submit">Send</button>
        </div>
      </form>

    </mat-card-content>

  </mat-card>
</div>