<div class="heading-bar">
  <h3 class="flex items-center">Templates</h3>
</div>
<div class="flex flex-row">
  <div class="model-search-block theme-form flex justify-end items-center flex-grow">
    <mat-form-field class="flex-grow">
      <input type="text" matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter template by title" />
      <mat-icon matSuffix color="primary">search</mat-icon>
    </mat-form-field>
    <button class="dn-button-primary" mat-raised-button [routerLink]="['/template-create']">Add New Template</button>
  </div>
</div>
<div class="flex items-center justify-center gap-5">
</div>
<div>
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
      <td mat-cell *matCellDef="let element"> {{element.template_title}} </td>
    </ng-container>

    <ng-container matColumnDef="creation_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created on </th>
      <td mat-cell *matCellDef="let element"> {{element.creation_date | date: 'MMM dd, yyyy, h:mm a'}} </td>
    </ng-container>

    <ng-container matColumnDef="updation_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Updated on </th>
      <td mat-cell *matCellDef="let element"> {{element.updation_date | date: 'MMM dd, yyyy, h:mm a'}} </td>
    </ng-container>

    <ng-container matColumnDef="operations">
      <th mat-header-cell *matHeaderCellDef></th>
      <ng-container *matCellDef="let element">
        <td mat-cell>
          <div class="operation-buttons flex flex-row items-center gap-5">            
            <i class="icon icon-edit" 
            matTooltip="Edit" matTooltipPosition="above" 
            (click)="editTemplate(element)"></i>
            
            <i class="icon icon-delete" 
            matTooltip="Delete" matTooltipPosition="above" 
            (click)="deleteTemplate(element)"></i>
          </div>
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>