import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as global from '../global';
import {API_URL} from "../../../environments/api-url";



@Injectable({
    providedIn: 'root',
})
export class CodeVerificationService {

    public constructor(private httpclient: HttpClient) {

    }

    checkPasswordResetCodeAndRestPassword(details){
        return this.httpclient
            .post(global.url + API_URL.AUTH.checkPasswordResetCodeAndRestPassword, {details: details}).pipe();
    }
    checkPasswordResetCode(details){
        return this.httpclient
            .post(global.url + API_URL.AUTH.checkPasswordResetCode, {details: details}).pipe();
    }

}
