<mat-tab-group class="child-tabs" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="Wound Nurse-Provider" *ngIf="currentUser.admin_type !== global.ADMIN_TYPE.WOUND_NURSE_ADMIN">
        <div class="mat-tab-content" *ngIf="tabIndex === 0">
            <app-wound-nurse-provider-association></app-wound-nurse-provider-association>
        </div>
    </mat-tab>
    <mat-tab [label]="woundNurseFacilityAssociationLabel">
        <div class="mat-tab-content" *ngIf="tabIndex === 1 || (currentUser.admin_type === global.ADMIN_TYPE.WOUND_NURSE_ADMIN && tabIndex == 0)">
            <app-wound-nurse-facility-association></app-wound-nurse-facility-association>
        </div>
    </mat-tab>
</mat-tab-group>