import {Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-switch-button',   // app-switch-button
    templateUrl: './switch-button.component.html',
    styleUrls: ['./switch-button.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class SwitchButtonComponent implements OnInit {
    @Input() dark = false; // css

    @Input() checked = true; // value

    @Input() labelOff: string;
    @Input() labelOn: string;
    @Input() thirdForm = true;

    @Input() manualChange = false;


    @Output() handleOn: EventEmitter<any> = new EventEmitter();
    @Output() handleOff: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    async ngOnInit() {
    }

    handleClick($event) {
        if (this.checked) {
            setTimeout(() => this.handleOff.emit(), 200);
        } else {
            setTimeout(() => this.handleOn.emit(), 200);
        }
        if(this.manualChange) return;

        this.checked = !this.checked;
    }
}
