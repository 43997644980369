import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CptCodesService } from 'src/app/services/Modules/cpt-codes.service';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { lastValueFrom } from 'rxjs';
@Component({
  selector: 'app-dialogue-manage-cpt-codes',
  templateUrl: './dialogue-manage-cpt-codes.component.html',
  styleUrls: ['./dialogue-manage-cpt-codes.component.css']
})
export class DialogueManageCptCodesComponent implements OnInit {

  previousData: any;
  object: any;
  isEmptyCodeDescription = false;
  pos = [];

  newCPTmodel : any = {
    code: "",
    description: "",
    end_date: "",
    default_unit: ""
  };

  place_of_service = [];
  pos_ids = [];
  company_id: any;
  expiry_date = null;
  public current_date = new Date();

  constructor( private dialogRef: MatDialogRef<DialogueManageCptCodesComponent>, private _CPTcodesService: CptCodesService,
    private companyService: CompanyService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.object = data;
    this.setPreviousData(this.object)
    this.company_id = data.company_id;
  }

  async ngOnInit(){
    this.setCptData();
    await this.getPOS();
    await this.getCptPos();

  }

  async getPOS() {
    const res: any = await lastValueFrom(this.companyService.getAllPOS({}, {}))
      if (res.status == 200) {
        this.pos = res.data;
      } else {
        console.log('something went wrong');
      }
  }

  async getCptPos() {
    const res: any = await lastValueFrom(this.companyService.getCPTPOSRelations({ company_id: this.company_id, cpt_id: this.data._id }))
    if (res.status == 200) {
      this.place_of_service = res.data[0].pos_ids;
      this.pos_ids = res.data[0].pos_ids.map(pos_id => pos_id._id);
    }
  }

  closeDialog() {
    const data = this.getPreviousData()
    this.dialogRef.close(data);
  }

  setPreviousData(data: any){
    this.previousData = data;
  }

  getPreviousData(){
    return this.previousData;

  }

  setCptData(){
    this.newCPTmodel = {
      code: this.object.code,
      description: this.object.description,
      end_date: this.object.end_date,
      default_unit: this.object.default_unit
    };

    if (this.newCPTmodel.end_date != "")
      this.expiry_date = moment(this.newCPTmodel.end_date, 'MM/DD/YYYY', true).format();

    if(this.object.use_once_per_admit)
      this.newCPTmodel.use_once_per_admit = this.object.use_once_per_admit;
  }

checkIsEmpty(){
  if (this.newCPTmodel.code.trim().length === 0)
    this.isEmptyCodeDescription = true;
  else if (this.newCPTmodel.description.trim().length === 0)
    this.isEmptyCodeDescription = true;
  else
    this.isEmptyCodeDescription = false;

}

  save(){
    this.checkIsEmpty();

    if (this.expiry_date != null)
      this.newCPTmodel.end_date = moment(this.expiry_date).format('MM/DD/YYYY');
    else
      this.newCPTmodel.end_date = "";
    if (this.newCPTmodel.default_unit == 0)
      this.newCPTmodel.default_unit = ""; 

      let details = {filter: {_id: this.object._id}, query: this.newCPTmodel}

      this._CPTcodesService.updateCpt(details)
        .subscribe((res: any) => {
          if (res.status === 200){
              // when jsonData.posID is an array of ids, "isMultiplePos" is set true
            this.updatePOSRelation(this.company_id, res.data._id, this.pos_ids, false, true);

            this.toastr.success("Updated Successfully!")
          }
          else if (res.status === 500)
            this.toastr.warning(res.message)
          this.dialogRef.close(res.data);
        })
    }
    
  clearDetails() {
    this.newCPTmodel = {
      code: this.newCPTmodel.code,
      description: "",
      end_date: "",
      default_unit: ""
    };
    this.place_of_service = []
    this.expiry_date = null;
    this.isEmptyCodeDescription = true;
    this.pos_ids = [];
  }

  async updatePOSRelation(companyId, cptId, posID, isCreating: boolean, isMultiplePos = false) {
    this.companyService.updatePOSRelation(companyId, cptId, posID, isCreating, isMultiplePos).subscribe((res: any) => {
      if (res.status == 200) {
        this.toastr.success('CPT POS Relation Updated!', 'Success');
      } else {
        console.log('something went wrong');
      }
    });
  }

  triggerEvent(value){
    this.place_of_service = [];
    this.place_of_service = this.pos.filter(pos => this.pos_ids.includes(pos._id));

  }

  addFieldToModel($event) {
    this.newCPTmodel.use_once_per_admit = $event.checked;
  }

  keyPressNumbers(event) {
    if (event.target.value == 0){
      event.target.value = "";
    }
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
        event.preventDefault();
        return false;
    }
    else {
        return true;
    }
  }
}
