import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import * as global from '../global';
import { Subscription, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from "../../../environments/api-url";

@Injectable({
    providedIn: 'root'
})
export class ProviderProviderAssociationService {
    currentUser: any;
    constructor(
        private httpclient: HttpClient,
        private _router: Router,
        private _authService: AuthService
        ) {
        this.currentUser = this._authService.currentUser;
    }
    getAllSameCompanyProviders(filter, projection,populate?) {
        return this.httpclient.post(global.url + API_URL.USER.getAllSameCompanyProviders, { filter, projection }).pipe();
    }
    getDoctorsList(data = {}) {
        var item = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            ...data
        };
        item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type;
        return this.httpclient.post(global.url + API_URL.USER.getDoctorsList, { details: item }).pipe();
    }
    getAllDoctorsList(){
        var item = {
            c_user: <string>null,
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
        };
        item.c_user=this.currentUser,item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type;
        return this.httpclient.post(global.url + API_URL.USER.getAllDoctorsList, { details: item }).pipe();

    }
    createRelationProvider2Provider(relationship) {
        var item = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            company_id:<string>null,
            ...relationship
        }
        item.id = this.currentUser._id, 
        item.auth_key = this.currentUser.auth_key, 
        item.usertype = this.currentUser.user_type , 
        item.company_id=relationship.company_id;
        return this.httpclient.post(global.url + API_URL.USER.createRelationProvider2Provider, {details: item}).pipe();
    }
    updateRelationWithAllProviders(relationship) {
        var item = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            company_id:<string>null,
            ...relationship
        }
        item.id = this.currentUser._id, 
        item.auth_key = this.currentUser.auth_key, 
        item.usertype = this.currentUser.user_type , 
        item.company_id=relationship.company_id;
        return this.httpclient.post(global.url + API_URL.USER.updateRelationWithAllProviders, {details: item}).pipe();
    }
    deleteRelationProvider2Provider(relationship) {
        var item = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            company_id:<string>null,

            ...relationship
        }
        item.id = this.currentUser._id, 
        item.auth_key = this.currentUser.auth_key, 
        item.usertype = this.currentUser.user_type,
        item.company_id=relationship.company_id;
        return this.httpclient.post(global.url + API_URL.USER.deleteRelationProvider2Provider, {details: item}).pipe();
    }
    getProviderRelations(company_id){
        var item = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            company_id:<string>null,
        }
        item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type,item.company_id=company_id;
        return this.httpclient.post(global.url + API_URL.USER.getProviderRelations, {details: item}).pipe();

    }
    getAssociatedProviders(provider){
        var item = {
            id: <string>null,
            //c_user: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            company_id:<string>null,
            provider_id:provider
        };
        //item.c_user=this.currentUser,
        item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type,item.company_id=this.currentUser.company_id;

        return this.httpclient.post(global.url + API_URL.USER.getAssociatedProviders, { details: item }).pipe();

    }
}
