import { Component, OnInit, Input } from '@angular/core';
import { PCCService } from 'src/app/services/pcc.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-episodes-of-care',
  templateUrl: './episodes-of-care.component.html',
  styleUrls: ['./episodes-of-care.component.css']
})
export class EpisodesOfCareComponent implements OnInit {

  @Input('patient') patient;
  constructor(
    private _pccService: PCCService,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService) { }
  pccPayerTypes = ["managedCare", "medicaid", "medicareA", "medicareB", "medicareD", "other", "outpatient", "private"];
  payerType = 'managedCare';
  apiData;
  page = 1;
  dataSource;
  displayedColumns = [
    "episodeOfCareId",
    "name",
    "type",
    "status",
    "model",
    "duration",
    "startDate",
    // actualDrg,
    // estimatedDrg
    "payerName",
    "payerType",
    "payerCode",
  ];

  loaderId = 'episodes-of-care-loader';
  inprogress = false;

  ngOnInit(): void {
    this.loadData(this.page, this.payerType);
  }

  loadData(page, payerType) {
    this.loader.startLoader(this.loaderId);
    this._pccService.getEpisodesOfCare(
      this.patient.pcc_patientId,
      payerType,
      undefined,
      String(page),
      undefined
    ).subscribe((response: any) => {

      if (response.status === 200) {
        this.apiData = response.data;
        this.dataSource = new MatTableDataSource(this.apiData.data);
        this.page = page;
      } else {
        this.toastr.error('Something went wrong while fetching Episodes Of Care data');
      }
    }, (error) => {
      this.toastr.error('Something went wrong while fetching Episodes Of Care data');
    }, () => {
      this.loader.stopLoader(this.loaderId);
    })
  }
  navigate(prevNext) {
    const newPageNo = this.page + prevNext;
    this.loadData(newPageNo, this.payerType);
  }

}
