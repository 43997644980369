import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AdvancedNoteTextSearchService } from './advanced-note-text-search.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { FacilityService } from 'src/app/services/facility.service';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { ViewNoteDialog } from '../transcription-create/view-note-dialog/view-note-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConnectionPositionPair, OverlayModule } from '@angular/cdk/overlay';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-advanced-note-text-search',
  templateUrl: './advanced-note-text-search.component.html',
  styleUrls: ['./advanced-note-text-search.component.css']
})
export class AdvancedNoteTextSearchComponent implements OnInit {
  displayedColumns: string[] = ['first_name', 'last_name', 'facility_id', 'date_of_birth', 'gender', 'date_of_service', 'actions'];
  dataSource: any = new MatTableDataSource([]);
  @Output() hideAdvancedTextSearchComponent = new EventEmitter<boolean>();
  filter: any = {
    note_text: "",
    company_id: ""
  };
  extractedNoteTextArray = [];
  loaderId = 'app-advanced-note-text-search';
  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    PAGE_LIMIT: 100,
    skip: 0
  };
  facilityControl = new FormControl();
  facilities: Array<any> = [];
  filteredFacilities: Observable<string[]>;
  selectedFacility: any = null;
  dialogSubscription: Subscription;
  highlightWords: string[] = [];
  isOverlayOpen = false;
  positionPairs: ConnectionPositionPair[] = [
    {
      // offsetX: 0,
      offsetY: 10,
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      // panelClass: null,
    },
  ];

  constructor(
    private _advancedNoteTextSearchService: AdvancedNoteTextSearchService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _toastr: ToastrService,
    private loader: NgxUiLoaderService,
    private _facilityService: FacilityService,
    private _matDialog: MatDialog,
    private _authService: AuthService
  ) {
    this.filter.company_id = this._route.parent.snapshot.params.id;
  }

  async ngOnInit(): Promise<void> {
    this.loader.startLoader(this.loaderId);
    await this.initFacilities();
    this.loader.stopLoader(this.loaderId);
  }

  // ngOnDestroy() {
  //   if (this.dialogSubscription) {
  //     this.dialogSubscription.unsubscribe();
  //   }
  // }

  async initFacilities() {
    const filter = {
      is_active: true,
      assoc_company_ids: this.filter.company_id
    };
    const projection = {
      'title': 1,
      'source': 1,
      'assoc_company_ids': 1
    };
    const response: any = await lastValueFrom(this._facilityService.getFacilities(filter, projection));
    if (response.status === 200) {
      this.facilities = response.data.array;
      this.showFacilityDropdown();
    }
    else {
      this._toastr.error(response.data.message, 'Error');
    }
  }

  showFacilityDropdown(): void {
    this.filteredFacilities = this.facilityControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => this.filterFacilities(value)));
  }

  filterFacilities(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) !== -1);
  }

  async onSelectFacility($event, facility) {
    if ($event.isUserInput) {
      this.selectedFacility = facility;
      this.filter.facility_id = facility._id;
    }
  }

  onChangeFacilityText(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value.trim();
    if (inputValue === '') {
      this.selectedFacility = null;
      delete this.filter.facility_id;
    }
  }

  clearFacilityField(event: Event) {
    event.stopPropagation();
    this.selectedFacility = null;
    delete this.filter.facility_id;
  }

  toggleOverlay() {
    this.isOverlayOpen = !this.isOverlayOpen;
  }

  viewNoteDialog(noteId) {
    this.highlightWords = this.filter.note_text.split(/\s+/);
    this._matDialog.open(ViewNoteDialog, {
      width: '80%',
      //height:'80%',
      data: {
        note_id: noteId,
        highlightWords: this.highlightWords
      }
    });
  }

  async searchNoteText() {
    if (this.filter.note_text) {
      this.loader.startLoader(this.loaderId);
      this.filter.pageNo = this.pagination.pageNo;
      const response: any = await lastValueFrom(this._advancedNoteTextSearchService.getExtractedNoteText(this.filter, {}));
      if (response.status == 200) {
        this.extractedNoteTextArray = response.data.extractedNoteTextArray;
        this.pagination.totalRecords = response.data.totalRecords;
        this.handlePagination();
        this.dataSource.data = this.extractedNoteTextArray;
      }
      else {
        this._toastr.error(response.message, 'Error');
      }
      this.loader.stopLoader(this.loaderId);
    }
  }

  handlePagination() {
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    if (this.pagination.skip + this.extractedNoteTextArray.length < this.pagination.totalRecords) {
      this.pagination.hasNextPage = true;
    }
    else {
      this.pagination.hasNextPage = false;
    }
    if (this.pagination.skip > 0) {
      this.pagination.hasPrevPage = true;
    }
    else {
      this.pagination.hasPrevPage = false;
    }
  }

  goToPatientDetailScreen(patientId) {
    return this._router.navigate([`/company/${this.filter.company_id}/patient-detail/${patientId}`]);
  }

  async handlePageNavigation(shouldgoBack: boolean) {
    shouldgoBack ? this.pagination.pageNo-- : this.pagination.pageNo++;
    await this.searchNoteText();
  }

  goBack() {
    this.hideAdvancedTextSearchComponent.emit(false);
  }

}
