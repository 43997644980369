import { Component, OnInit, Input } from '@angular/core';
import { PCCService } from 'src/app/services/pcc.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatTableDataSource } from '@angular/material/table';
import { PatientDetailService } from '../patient-detail.service';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-patient-admit-discharge-history',
  templateUrl: './patient-admit-discharge-history.component.html',
  styleUrls: ['./patient-admit-discharge-history.component.css']
})
export class PatientAdmitDischargeHistory implements OnInit {

  @Input('patient') patient;
  Alldata: [];
  // @Input('pccOrgUuid') pccOrgUuid;
  constructor(
    private _pccService: PCCService,
    private patientDetailService: PatientDetailService,
    private toastr:ToastrService,
    private loader: NgxUiLoaderService,
    private _authService: AuthService,
    ) { }
  dataSource;
  displayedColumns = [
    "facility",
    "provider",
    "reason",
    "date",
    "event_type",
  ];

  loaderId ='patient-admit-discharge-history-loader';
  inprogress = false;

  
  view:'' = '';
  viewData;

  ngOnInit(): void {
    this.loadData();
  }

  async sorting(array){
    return array.sort((a,b) => Date.parse(a.createdAt) - Date.parse(b.createdAt))
     
  }

  getRowColor(element){
    if(element.reason) return true;
    else {
      if(element.event_type=='admit') return false;
      else if(element.event_type=='discharge') return true;
      else return false;
    }
  }

  getReason(element){
    if(element.reason) return element.reason;
    else {
      if(element.event_type=='admit') return `Moved to this Facililty`;
      else if(element.event_type=='discharge') return `Removed from this Facililty`;
      else return '---';
    }
  }

  async loadData() {
    this.loader.startLoader(this.loaderId);
    const details  = {
      patient_id: this.patient._id,
      company_id: this._authService.currentUser.company_id
    };
    const {status, data} = await lastValueFrom(this.patientDetailService.getPatientADTHistory({details})) as any;
    if(status === 200) {
      this.Alldata=await this.sorting(data.array);
      this.dataSource = new MatTableDataSource(this.Alldata);
     // console.log("await this.sorting(data.array) : ", await this.sorting(data.array));
    }
    this.loader.stopLoader(this.loaderId);
  }
}
