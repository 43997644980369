import { Component, OnInit } from '@angular/core';
import { AdminNotificationsService  } from './admin-notification.service';
import { ToastrService } from 'ngx-toastr';
import {MatTableDataSource} from "@angular/material/table";
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { OpenPostDialogComponent } from '../open-post-dialog/open-post-dialog.component';
import { ReleaseService } from '../release/release.service';
import { NotificationsService } from '../notifications/notifications.service';



@Component({
  selector: 'app-admin-notifications-settings',
  templateUrl: './admin-notifications-settings.component.html',
  styleUrls: ['./admin-notifications-settings.component.css']
})
export class AdminNotificationsSettingsComponent implements OnInit {

  public dataSource:any;
  currentUser;
  notification_operations:any[] = [];
  user_types:any[] = [];
  displayedColumns: string[] = ['button'];
  notifications = [];
  private dialog: MatDialog;
  notes: any;
  searchNotificationText: '';
  shouldShowButton = false;

  constructor(private _adminNotificationsService:AdminNotificationsService, private router: Router, private authService: AuthService, private toastr:ToastrService, private newdialog: MatDialog,
    private releaseService: ReleaseService, private notificationsService: NotificationsService) { 
    if (window.location.pathname.includes('company'))
      this.shouldShowButton = true;
    }

  ngOnInit() {
    this.initNotificationOperations();
    this.initUserTypes();
    this.currentUser = this.authService.currentUser;
    if((this.currentUser.admin_type ==='full_admin' || this.currentUser.admin_type ==='system_admin') && this.currentUser.product_owner === false){
      this.router.navigate(['/403']);
    }

    this.getNotifications();  

  }

  initNotificationOperations() {
    this._adminNotificationsService.getAllNotificationOperations().subscribe((response:any) => {
      if(response.status === 200) {
        this.notification_operations = response.data.array;
        this.dataSource = new MatTableDataSource(this.notification_operations.slice());
      }
    });
  }

  initUserTypes() {
    this._adminNotificationsService.getUserTypes().subscribe((response:any) => {
      if(response.status === 200) {
        this.user_types = response.data.array;
      }
    })
  }

  handleDropdownSelectionChange(e, notificationOperation) {
    const all = e.value.indexOf('all') !== -1;
    const none = e.value.indexOf('none') !== -1;
    const values = e.value.filter(value => value !== 'all' || value !== 'none');
    if(none) {
      notificationOperation.for_user_types = [];
    } else if(all) {
      notificationOperation.for_user_types = this.user_types.map(user_type => user_type.value);
    } else {
      notificationOperation.for_user_types = values;
    }
    this.updateNotificationOperation(notificationOperation);
  }

  handleNotificationChannelChange(e, by, notificationOperation) {
    notificationOperation[by] = e.checked ? 'true' : 'false';
    
    this.updateNotificationOperation(notificationOperation);
  }

  updateNotificationOperation(notificationOperation) {
    this._adminNotificationsService.updateNotificationOperation(notificationOperation).subscribe((response:any) => {
      if(response.status === 200) {
        this.toastr.success(response.message, 'Success');
      } else {
        this.toastr.error(response.message, 'Failed');
      }
    });
  }
  // dropdownValue(notificationOperation, by) {
  //   if(notificationOperation[by] === 'true') {
  //     if(notificationOperation['for_provider'] === 'true' && notificationOperation['for_transcriber'] === 'true') {
  //       return 'both';
  //     } else if(notificationOperation['for_provider'] === 'true') {
  //       return 'provider';
  //     } else if(notificationOperation['for_transcriber'] === 'true') {
  //       return 'transcriber';
  //     }
  //   }
  //   return 'none';
  // }
  // handleDropdownValueChange(e, notificationOperation, by) {
  //   switch(e.value) {
  //     case 'both': 
  //       notificationOperation[by]='true';
  //       notificationOperation['for_provider'] = 'true';
  //       notificationOperation['for_transcriber'] = 'true';
  //     break;
  //     case 'provider':
  //       notificationOperation[by]='true';
  //       notificationOperation['for_provider'] = 'true';
  //     break;
  //     case 'transcriber':
  //       notificationOperation[by]='true';
  //       notificationOperation['for_transcriber'] = 'true';
  //     break;
  //     case 'none':
  //       notificationOperation[by]='false';
  //   }
  //
  //   this._adminNotificationsService.updateNotificationOperation(notificationOperation).subscribe((response:any) => {
  //     if(response.status === 200) {
  //       this.toastr.success(response.message, 'Success');
  //     } else {
  //       this.toastr.error(response.message, 'Failed');
  //     }
  //   });
  // }
  openPostDialog(addNotes) {
    const dialogRef = this.newdialog.open(OpenPostDialogComponent, {
      width: '800px',
      data: addNotes
    });
    dialogRef.afterClosed().subscribe(async (addNotes) => {
          console.log('addnotes', addNotes);
          if (addNotes) {
            this._adminNotificationsService.addNotes(addNotes).subscribe((response: any) => {
              if (response.status == 200) {
                this.toastr.success('Saved');
                this.getNotifications();
              }
              else
                this.toastr.error(response.message, 'error');
            });
          } else {
            console.log('something went wrong!');
          }
    
        });
  }

  getNotifications(){
    this.notificationsService.getAllNotifications('notification').subscribe((response: any) => {
      if (response.status == 200) {
        this.notifications = response.notification;
      }
      else
      this.toastr.error(response.message, 'error');
    });
  } 

}
