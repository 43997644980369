import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NoteAddChargeService } from '../note-addCharge/note-add-charge.service';
import { PatientRoundingSheetService } from '../../patient-rounding-sheets/patient-rounding-sheet.service';

@Component({
  selector: 'app-view-favourite-cpts-dialog',
  templateUrl: './view-favourite-cpts-dialog.component.html',
  styleUrls: ['./view-favourite-cpts-dialog.component.css']
})
export class ViewFavouriteCptsDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: PatientRoundingSheetService,
    private dialogRef: MatDialogRef<ViewFavouriteCptsDialogComponent>,


  ) { }

  selectedCpts = [];
  color = '#ff0000';
  colorPickerOpen: boolean = false;
  displayedColumns: string[] = [
    'checkbox',
    'code',
  ];
  dataSource: any;

  ngOnInit(): void {
    this.dataSource = this.data;
  }

  handleAddCpt($event, element) {
    console.log($event, element);
    if ($event.checked) this.selectedCpts.push(element);
    else {
      const index = this.selectedCpts.indexOf(element);
      if (index >= 0) this.selectedCpts.splice(index, 1);
    }
  }

  toggleColorPicker() {
    this.colorPickerOpen = !this.colorPickerOpen;
  }

  closeColorPicker() {
    this.colorPickerOpen = false;
  }

  // You can listen to the color change event
  onColorChange(color: string) {
  }

  closeDialog(data) {
    this.dialogRef.close(data);
  }

  applyColor() {
    this.service.applyColorToFavouriteCPT(this.selectedCpts.map(cpt => cpt._id), this.color).subscribe((res: any) => {
      if (res.status == 200) {
        this.closeDialog(res);
      } else {
        console.log('error');
      }
    })
  }
}

