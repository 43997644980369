import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import { NoteEditorV2Component } from './note-editor-v2/note-editor-v2.component';
import { LayoutModule } from 'src/app/layout/layout.module';


@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    CKEditorModule
  ],
  declarations: [
    NoteEditorV2Component
  ]
})
export class NoteEditorModule { }