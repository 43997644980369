<form>

  <div class="mat-dialog-header flex justify-between items-center">
    <h2 mat-dialog-title>Add a new patient</h2>
    <span class="material-symbols-outlined mat-dialog-close-icon" mat-dialog-close>cancel</span>
  </div>

  <div mat-dialog-content>
    <div class="form-row flex gap-4">
      <mat-form-field class="w-1/4">
        <input
          matInput
          type="text"
          name="pFirstName"
          placeholder="First Name"
          [(ngModel)]="patientData.first_name"
          required
          (keypress)="allowOnlyText($event)"
          appCapitalizeFirst
        />
        <mat-error *ngIf="!patientData.first_name">Please enter first name</mat-error>
      </mat-form-field>
      <mat-form-field class="flex w-1/4">
        <input
          appCapitalizeFirst
          matInput
          type="text"
          name="pMiddleName"
          placeholder="Middle Name (optional)"
          [(ngModel)]="patientData.middle_name"
          (keypress)="allowOnlyText($event)"
        />
      </mat-form-field>
      <mat-form-field class="w-1/4">
        <input
          appCapitalizeFirst
          matInput
          type="text"
          name="pLastName"
          placeholder="Last Name"
          [(ngModel)]="patientData.last_name"
          required
          (keypress)="allowOnlyText($event)"
        />
        <mat-error *ngIf="!patientData.last_name">Please enter last name</mat-error>
      </mat-form-field>
      <mat-form-field class="w-1/4">
        <input
          matInput
          type="text"
          name="mrn"
          [readonly]="true"
          placeholder="MRN"
          [(ngModel)]="patientData.mrn"
          (keypress)="allowOnlyText($event)"
        />
      </mat-form-field>
    </div>

    <div class="form-row flex gap-4">
      <app-date-picker-widget
        [placeholder]="'Birth'"
        [date]="patientData.date_of_birth"
        (dateEvent)="dateOfBirth($event)"
        class="w-3/4"
      >
      </app-date-picker-widget>
      <mat-form-field class="w-1/4">
        <input
          appCapitalizeFirst
          matInput
          type="number"
          name="pSSN"
          placeholder="SSN"
          [(ngModel)]="patientData.patient_ssn"
        />
      </mat-form-field>
    </div>

    <div class="form-row flex gap-4">
      <mat-form-field class="w-1/4">
        <mat-label>Sex</mat-label>
        <mat-select [(ngModel)]="patientData.gender" name="gender">
          <mat-option value="" disabled selected> -- SELECT -- </mat-option>
          <mat-option value="Unknown"> Unknown </mat-option>
          <mat-option value="Male"> Male </mat-option>
          <mat-option value="Female"> Female </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="flex w-1/4">
        <mat-label>Gender Identity</mat-label>
        <mat-select
          [(ngModel)]="patientData.gender_identity"
          name="gender_identity"
        >
          <mat-option value="" disabled selected> -- SELECT -- </mat-option>
          <mat-option
            *ngFor="let identity of genderIdenties"
            [value]="identity.value"
          >
            {{identity.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="w-1/4">
        <mat-label>Marital Status</mat-label>
        <mat-select
          [(ngModel)]="patientData.marital_status"
          name="gender_identity"
        >
          <mat-option value="" disabled selected> -- SELECT -- </mat-option>
          <mat-option
            *ngFor="let identity of marital_status_array"
            [value]="identity.value"
          >
            {{identity.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="w-1/4">
        <mat-label>Race</mat-label>
        <mat-select
          [(ngModel)]="patientData.race"
          name="gender_identity"
        >
          <mat-option value="" disabled selected> -- SELECT -- </mat-option>
          <mat-option
            *ngFor="let race of races"
            [value]="race.value"
          >
            {{race.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-row flex gap-4">
      <mat-form-field *ngIf="data.showFacility" class="flex-grow">
        <mat-label>Select Facility</mat-label>
        <div class="flex items-center">
          <mat-select name="facility" required #facility="ngModel" [(ngModel)]="patientData.facility_id">
            <input matInput placeholder="Search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
              style="color:#000;height: 40px;padding-left: 5px; ; position: sticky; top: 0; z-index:1000; background-color: lightgray; " (keydown)="$event.stopPropagation()" />
            <mat-option value="" disabled selected>-- SELECT --</mat-option>
            <mat-option *ngFor="let facility of facilities | searchFacility: searchText" value="{{facility._id}}">
              <app-facility-title [facility]="facility"></app-facility-title>
            </mat-option>
          </mat-select>
        </div>
      </mat-form-field>
      <mat-form-field class="flex-grow">
        <input
          appCapitalizeFirst
          matInput
          type="text"
          name="Address"
          placeholder="Address 1"
          (keypress)="keyPressAlphanumeric($event)"
          [(ngModel)]="patientData.address"
        />
      </mat-form-field>
      <mat-form-field class="flex-grow">
        <input
          appCapitalizeFirst
          matInput
          type="text"
          name="Address_2"
          placeholder="Address 2"
          (keypress)="keyPressAlphanumeric($event)"
          [(ngModel)]="patientData.address_2"
        />
      </mat-form-field>
    </div>

    <div class="form-row flex gap-4">
      <mat-form-field class="w-1/3">
        <input
          matInput
          type="number"
          name="zipCode"
          placeholder="Zip code"
          [(ngModel)]="patientData.zip_code"
          />
      </mat-form-field>
      <mat-form-field class="w-1/3">
        <input
          appCapitalizeFirst
          matInput
          type="text"
          name="pCity"
          placeholder="City"
          (keypress)="allowOnlyText($event)"
          [(ngModel)]="patientData.city"
        />
      </mat-form-field>
      <mat-form-field class="w-1/3">
        <mat-label>State</mat-label>
        <mat-select name="states" [(ngModel)]="patientData.state">
          <input
            matInput
            placeholder="Search"
            [(ngModel)]="searchState"
            [ngModelOptions]="{standalone: true}"
            style="color:#000;height: 40px;padding-left: 5px;"
          />
          <mat-option value="" disabled selected> -- SELECT -- </mat-option>
          <mat-option
            *ngFor="let state of usaStates | searchCompany : searchState"
            [value]="state.name"
          >
            {{state.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-row flex gap-4">
      <mat-form-field class="w-1/3">
        <input
          matInput
          type="text"
          #mobile="ngModel"
          name="phone"
          placeholder="Phone"
          [(ngModel)]="patientData.phone"
          (ngModelChange)="handlePhoneModelChange($event)"
          pattern="\(\d{3}\)\s\d{3}\-\d{4}"
        />
      </mat-form-field>
      <mat-form-field class="w-1/3">
        <mat-label>Type</mat-label>
        <mat-select [(ngModel)]="patientData.type" name="type">
          <mat-option value="" disabled selected> -- SELECT -- </mat-option>
          <mat-option value="mobile"> Mobile </mat-option>
          <mat-option value="home"> Home </mat-option>
          <mat-option value="work"> Work </mat-option>
          <mat-option value="other"> Other </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="w-1/3">
        <input
          matInput
          type="email"
          name="email"
          placeholder="Email"
          [(ngModel)]="patientData.email"
        />
      </mat-form-field>
    </div>

    <div class="form-row flex gap-4">
      <mat-form-field class="w-1/3">
        <input
          appCapitalizeFirst
          matInput
          type="text"
          name="employer"
          placeholder="Employer"
          (keypress)="keyPressAlphanumeric($event)"
          [(ngModel)]="patientData.employer"
        />
      </mat-form-field>
      
    </div>

    <div style="margin-top: 24px;">
      <h3 style="margin: 0;">Primary Care Provider</h3>
    </div>
    <div class="form-row flex gap-4">
      <mat-form-field class="w-1/4">
        <input
          appCapitalizeFirst
          matInput
          type="text"
          name="pcpFirstName"
          placeholder="First name"
          [(ngModel)]="patientData.pcpFirstName"
        />
      </mat-form-field>
      <mat-form-field class="w-1/4">
        <input
          appCapitalizeFirst
          matInput
          type="text"
          name="pcpLastName"
          placeholder="Last name"
          [(ngModel)]="patientData.pcpLastName"
        />
      </mat-form-field>
    
      <mat-form-field class="w-1/4">
        <input
          matInput
          type="text"
          name="pcpPhoneNumber"
          placeholder="Phone Number"
          [(ngModel)]="patientData.pcpPhoneNumber"
          (ngModelChange)="handlePhoneModelChange($event)"
          pattern="\(\d{3}\)\s\d{3}\-\d{4}"
        />
      </mat-form-field>
      <mat-form-field class="w-1/4">
        <input
          matInput
          type="text"
          name="pcpFaxNumber"
          placeholder="Fax Number"
          pattern="^\d{3}-\d{3}-\d{4}$"
          [(ngModel)]="patientData.pcpFaxNumber"
          #pcpFaxNumberInput="ngModel"
        />
        <mat-error *ngIf="pcpFaxNumberInput.invalid">
          Fax Number must be in the format XXX-XXX-XXXX.
        </mat-error>
      </mat-form-field>
    </div>

    <div style="margin-top: 24px;">
      <h3 style="margin: 0;">Insurance</h3>
    </div>

    <div class="form-row flex flex-row gap-4">
        <mat-form-field class="w-1/2">
          <input matInput type="text" name="responsibleParty" placeholder="Responsible Party"
            [(ngModel)]="patientData.responsible_party">
        </mat-form-field>
        <mat-form-field class="w-1/2">
          <input appCapitalizeFirst matInput type="text" name="responsiblePartyContact"
            placeholder="Responsible Party Contact Info" [(ngModel)]="patientData.responsible_party_contact">
        </mat-form-field>
    </div>
     <div class="flex justify-start items-start" style="margin-top: 24px;">
      <p style="margin: 0px;">Primary Insurance</p>
    </div>
    <div class="flex items-center justify-between gap-4">
      <mat-form-field class="w-1/2">
        <input appCapitalizeFirst matInput type="text" name="primaryInsuranceCarrier"
          placeholder="Primary Insurance Carrier" [(ngModel)]="patientData.p_insurance_carrier">
      </mat-form-field>
      <mat-form-field class="w-1/2">
        <input appCapitalizeFirst matInput type="text" name="pInsuranceHolderName"
          placeholder="Insurance Policy Holder Name" [(ngModel)]="patientData.p_insurance_holder_name">
      </mat-form-field>
    </div>
    <div class="flex items-center justify-between gap-4">
      <mat-form-field class="w-1/2">
        <input appCapitalizeFirst matInput type="text" name="p_insurancePolicyNumber" placeholder="Policy #"
          [(ngModel)]="patientData.p_insurancePolicyNumber">
      </mat-form-field>
      <mat-form-field class="w-1/2">
        <input appCapitalizeFirst matInput type="text" name="p_insuranceGroupNumber" placeholder="Group #"
          [(ngModel)]="patientData.p_insuranceGroupNumber">
      </mat-form-field>
    </div>


    <div class="flex justify-center items-center" style="margin-top: 24px;">
      <p style="margin: 0px;">Secondary Insurance</p>
    </div>
    <div class="flex justify-between items-center gap-4">
      <mat-form-field class="w-1/2">
        <input appCapitalizeFirst matInput type="text" name="secondaryInsuranceCarrier"
          placeholder="Secondary Insurance Carrier" [(ngModel)]="patientData.s_insurance_carrier">
      </mat-form-field>
      <mat-form-field class="w-1/2">
        <input appCapitalizeFirst matInput type="text" name="sInsuranceHolderName"
          placeholder="Insurance Policy Holder Name" [(ngModel)]="patientData.s_insurance_holder_name">
      </mat-form-field>
    </div>
    <div class="flex justify-between items-center gap-4">
      <mat-form-field class="w-1/2">
        <input appCapitalizeFirst matInput type="text" name="s_insurancePolicyNumber" placeholder="Policy #"
          [(ngModel)]="patientData.s_insurancePolicyNumber">
      </mat-form-field>
      <mat-form-field class="w-1/2">
        <input appCapitalizeFirst matInput type="text" name="s_insuranceGroupNumber" placeholder="Group #"
          [(ngModel)]="patientData.s_insuranceGroupNumber">
      </mat-form-field>
    </div>
  </div>

  <div mat-dialog-actions>
    <div class="flex justify-end items-center" style="margin-left: auto;">
      <button mat-raised-button class="dn-button-primary" type="submit" (click)="onSaveClick()">Save</button>
      <button mat-raised-button class="dn-button-secondary" mat-dialog-close type="reset">Cancel</button>
    </div>
  </div>

</form>