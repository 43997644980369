import {Component, OnInit, Inject, ViewChild, Input} from '@angular/core';
import {FacilityService} from '../../../services/facility.service';
import * as usa_states from './usa_states';
import {AuthService} from 'src/app/services/auth.service';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";


export interface FacilityAddDialogData {
    facility?: IFacility,
    disableClose?: boolean
}

export interface IFacility {
    _id?: string,
    title: string,
    user_id: string,
    user_type: string,
    address?: string,
    street?: string,
    city?: string,
    state?: String,
    zip_code?: string,
}

@Component({
    selector: 'app-facility-add-dialog',
    templateUrl: './facility-add.dialog.html',
    styleUrls: ['./facility-add.dialog.css']
})
export class FacilityAddDialog implements OnInit {
    loading = false;
    usa_states = usa_states.default;
    facility: IFacility;
    disableClose: boolean;

    currentUser: any;

    constructor(
        public dialogRef: MatDialogRef<FacilityAddDialog>,
        @Inject(MAT_DIALOG_DATA) public data: FacilityAddDialogData,
        private facilityService: FacilityService,
        private _authService: AuthService
    ) {
        // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.currentUser = this._authService.currentUser;
        if (data) {
            this.disableClose = !data.disableClose;
        }
        if (data && data.facility) {
            this.facility = Object.assign({}, data.facility);
        } else {

            this.facility = {
                title: '',
                user_id: this.currentUser._id,
                user_type: this.currentUser.user_type,
                address: '',
                street: '',
                city: '',
                state: '-1',
                zip_code: ''
            };
        }

    }

    ngOnInit() {
        // this.initFaculty();
    }


    onSubmit(facilityForm) {

        if (!facilityForm.valid) {
            return;
        }
        this.dialogRef.close(this.facility);
        if (!this.facility._id) {
            this.facilityService.addNewFacility(this.facility).subscribe((response: any) => {
                if (response.status === 200) {
                    this.dialogRef.close(this.facility); 
                }
            });
        } else {
            this.facilityService.updateFacility(this.facility).subscribe((response: any) => {
                if (response.status === 200) {
                    this.dialogRef.close(this.facility); 
                }
            });
        }
    }

    numberToString(number) {
        return String(number);
    }
}
