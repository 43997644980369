import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse,} from '@angular/common/http';
import {IFacility} from '../../../shared/popup-dialogs/facility-add-dialog/facility-add.dialog';
import * as global from '../../../includes/global';
import {AuthService} from '../../../services/auth.service';
import {API_URL} from "../../../../environments/api-url";

@Injectable({
    providedIn: 'root',
})
export class AudioListDialogService {
    currentUser: any;


    constructor(private httpclient: HttpClient,
                private _authService: AuthService) {
        this.currentUser = this._authService.currentUser;
    }

    getAudiosByPatientId(patient_id) {
        const details = {
            id: this.currentUser._id,
            auth_key: this.currentUser.auth_key,
            usertype: this.currentUser.user_type,

            patient_id
        };

        return this.httpclient.post(global.url + API_URL.AUDIO.getAudiosByPatientId, {details}).pipe();

    }
}