import * as _ from 'lodash';

export class PCCPatient {
    orgId: number;
    orgUuid: string;
    facId: number;
    patientId: number;
    medicalRecordNumber: string;
    medicareNumber: string;
    medicaidNumber: string;
    patientExternalId: string;
    patientStatus: string;
    lastName: string;
    firstName: string;
    outpatient: boolean;
    ompId: number;
    birthDate: string;
    admissionDate: string;
    admissionDateTime: string;
    dischargeDate: string;
    waitingList: boolean;
    hasPhoto: boolean;
    gender: string;
    languageCode: string;
    languageDesc: string;
    religion: string;
    citizenship: string;
    maritalStatus: string;
    deceased: boolean;
    adtRecord:any;
    constructor(patient) {
        for (const key in patient) {
            if (Object.prototype.hasOwnProperty.call(patient, key)) {
                const element = patient[key];
                this[key] = element;
            }
        }
    }
    isPCCPatient() {
        // return !!this.pcc_patientId;
        return true;
    }
    hasADTRecords() {
        // if (_.isArray(this.adtRecords)) {
        //     return this.adtRecords.length > 0;
        // }
        return false;
    }
    getRecentPatientADTRecord() {
        // return this.adtRecords[0];
    }
    getDischargeDate() {
        // return this.adtRecords?.find(adt => adt.actionCode === 'DD' || adt.actionCode === 'DHOME')?.effectiveDateTime;
        return this.dischargeDate;
    }
    getPatientLocation() {
        // const adt = this.getRecentPatientADTRecord();
        const adt = this.adtRecord;
        if (adt) {
            return `${adt.floorDesc} ${adt.roomDesc} - ${adt.bedDesc}`;
        }
        return ''
    }

}
