<h2 mat-dialog-title>Change Appointment Status</h2>
<mat-dialog-content>
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef>Color</th>
            <td mat-cell *matCellDef="let element">
                <span [style.background]="element.color" style="display: inline-block; width: 20px; height: 20px; border-radius: 50%; margin-left: 5px;"></span>
            </td>
          </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>
        <ng-container matColumnDef="enable">
          <th mat-header-cell *matHeaderCellDef>Enable</th>
          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle [checked]="element.enabled" (change)="onEnableChange(element,$event)">
              </mat-slide-toggle>
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
</mat-dialog-content>
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
<mat-dialog-actions>
  <button mat-button class="dn-button-secondary" (click)="onCancel()">Cancel</button>
</mat-dialog-actions>
