<div class="flex justify-between items-center" style="background-color: #44597d; height: 38px; color: white;">
    <h2 style="position: absolute; top: -7px; left: 10px;">De-activation</h2>
    <div class="top-right-close-btn">
        <button mat-mini-fab mat-dialog-close>
            <mat-icon>cancel</mat-icon>
        </button>
    </div>
</div>
<div style="padding-left: 18px; padding-right: 18px; padding-top: 5px; padding-bottom: 10px;">
    <form (ngSubmit)="submitDeactivationForm()" #form="ngForm" class="flex flex-col items-center justify-start">
        <mat-form-field appearance="fill" style="width: 100%;">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedEmailIdAsChip($event)">
                <mat-option *ngFor="let emailId of emailIds" [value]="emailId">
                    {{emailId}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 100%;">
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <input matInput type="text" name="customer_organization_name" #customer_organization_name="ngModel"
                [(ngModel)]="deactivationModel.customer_organization_name" placeholder="Customer Organization Name"
                required>
        </mat-form-field>
        <mat-form-field style="width: 100%">
            <input matInput type="text" name="customer_organization_code" #customer_organization_code="ngModel"
                [(ngModel)]="deactivationModel.customer_organization_code" placeholder="Customer Organization Code"
                required>
        </mat-form-field>
        <mat-form-field style="width: 100%">
            <input matInput type="text" name="facility_names" #facility_names="ngModel"
                [(ngModel)]="deactivationModel.facility_names" placeholder="Facility Names">
        </mat-form-field>
        <mat-form-field style="width: 100%">
            <input matInput type="text" name="facility_ids" #facility_ids="ngModel"
                [(ngModel)]="deactivationModel.facility_ids" placeholder="Facility IDs" required>
            <mat-error *ngIf="facility_ids.invalid  && (facility_ids.dirty || facility_ids.touched)">
                Please enter facility id
            </mat-error>
        </mat-form-field>
        <mat-form-field style="width: 100%">
            <input matInput type="text" name="customer_info" #customer_info="ngModel"
                [(ngModel)]="deactivationModel.customer_info" placeholder="Customer Name/Role/Email">
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <input matInput type="text" name="production_application_name" #production_application_name="ngModel"
                [(ngModel)]="deactivationModel.production_application_name" placeholder="Production Application Name"
                required>
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <input matInput type="text" name="partner_info" #partner_info="ngModel"
                [(ngModel)]="deactivationModel.partner_info" placeholder="Partner Name/Role/Email" required>
            <mat-error *ngIf="partner_info.invalid  && (partner_info.dirty || partner_info.touched)">
                Please enter partner info
            </mat-error>
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <input matInput type="text" name="deactivation_reason" #deactivation_reason="ngModel"
                [(ngModel)]="deactivationModel.deactivation_reason" placeholder="Reason for Deactivation" required>
        </mat-form-field>

        <br>
        <div class="flex justify-center items-center gap-7" style="margin: auto;">
            <button mat-raised-button class="dn-button-secondary" mat-dialog-close type="button">Cancel</button>
            <button mat-raised-button [disabled]="!form.form.valid || emailIds.length === 0"
                type="submit" class="dn-button-primary">Send</button>
        </div>


    </form>
</div>