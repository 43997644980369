<div *ngIf="patientTensReport?.PhysicalTherapyTable.length > 0">
    <h1>PT</h1>
    <div class="flex gap-17">
         <div class="flex flex-col w-4/5 gap-1">
            <span><strong>Med Dx</strong>: {{patientTensReport?.PhysicalTherapyTable[0].med_dx}} <small>({{patientTensReport?.PhysicalTherapyTable[0].ptMedDxDescription}})</small></span>
            <span><strong>Tx Dx</strong>: {{patientTensReport?.PhysicalTherapyTable[0].tx_dx}} <small>({{patientTensReport?.PhysicalTherapyTable[0].ptTxDxDescription}})</small> </span>
        </div>
    </div>
    <div style="margin-top: 5px;">
        <strong>Precautions</strong>: <small>{{patientTensReport?.PhysicalTherapyTable[0].precautions}}</small>
    </div>
    <table mat-table [dataSource]="dataSourcePT">
        <ng-container matColumnDef="dos">
            <th mat-header-cell *matHeaderCellDef>DOS</th>
            <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                <span>{{element.dos}} </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                <span>{{element.name}} </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>value</th>
            <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                <span [matTooltip]="getValue(element)" matTooltipPosition="before">{{element.value}}</span>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsPT; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsPT;"></tr>
    </table>
</div>
<div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
    <mat-progress-spinner [diameter]="30" color="primary" mode="indeterminate">
    </mat-progress-spinner>
</div>

<div *ngIf="patientTensReport?.OccupationalTherapyTable.length > 0">
    <h1>OT</h1>
    <div class="flex gap-17">
        <div class="flex flex-col w-4/5 gap-1">
            <span><strong>Med Dx</strong>: {{patientTensReport?.OccupationalTherapyTable[0].med_dx}} <small>({{patientTensReport?.OccupationalTherapyTable[0].otMedDxDescription}})</small></span>
            <span><strong>Tx Dx</strong>: {{patientTensReport?.OccupationalTherapyTable[0].tx_dx}} <small>({{patientTensReport?.OccupationalTherapyTable[0].otTxDxDescription}})</small></span>
        </div>
    </div>
    <div style="margin-top: 5px;">
        <strong>Precautions</strong>: <small>{{patientTensReport?.OccupationalTherapyTable[0].precautions}}</small>
    </div>
    <table mat-table [dataSource]="dataSourceOT">
        <ng-container matColumnDef="dos">
            <th mat-header-cell *matHeaderCellDef>DOS</th>
            <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                <span>{{element.dos}} </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                <span>{{element.name}} </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>value</th>
            <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                <span [matTooltip]="getValue(element)" matTooltipPosition="before">{{element.value}}</span>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsOT; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsOT;"></tr>
    </table>
</div>


<div *ngIf="patientTensReport?.SuspisiousTherapyTable.length > 0">
    <h1>ST</h1>
    <div class="flex gap-5">
        <div class="flex flex-col w-3/5 gap-1">
            <span><strong>Med Dx</strong>: {{patientTensReport?.SuspisiousTherapyTable[0].med_dx}} <small>({{patientTensReport?.SuspisiousTherapyTable[0].stMedDxDescription}})</small></span>
            <span><strong>Tx Dx</strong>: {{patientTensReport?.SuspisiousTherapyTable[0].tx_dx}} <small>({{patientTensReport?.SuspisiousTherapyTable[0].stTxDxDescription}})</small></span>
        </div>
    </div>
    <div style="margin-top: 5px;">
        <strong>Precautions</strong>: <small>{{patientTensReport?.SuspisiousTherapyTable[0].precautions}}</small>
    </div>
    <table mat-table [dataSource]="dataSourceST">
        <ng-container matColumnDef="dos">
            <th mat-header-cell *matHeaderCellDef>DOS</th>
            <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                <span>{{element.dos}} </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                <span [matTooltip]="getName(element)" matTooltipPosition="before">{{element.name}} </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>value</th>
            <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                <span [matTooltip]="getValue(element)" matTooltipPosition="before">{{element.value}}</span>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsST; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsST;"></tr>
    </table>
</div>