<app-biller-header *ngIf="currentUser.user_type === global.USER_TYPE.BILLER &&!isAdminSide">
</app-biller-header>
<div [ngClass]="{'container': currentUser.user_type === global.USER_TYPE.BILLER}">
    <ng-container>
        <div class="filter-form white-rounded-rect">
            <div class="flex justify-between">
                <h2 style="color:#000;margin-top:0;margin-bottom:0">Filter</h2>

                <div class="filter-controls flex gap-2">
                    <button mat-raised-button class="dn-button-primary"
                        (click)="filter.active=true; isVisibleTable=false; handleApplyFilterClick();">Apply Filter
                    </button>
                    <button mat-raised-button class="dn-button-secondary"
                        (click)="this.isVisibleTable = false; resetFilter()">Reset Filter
                    </button>
                    <div>
                        <button mat-raised-button class="dn-button-primary" [matMenuTriggerFor]="menu">
                            Download Report
                        </button>
                        <mat-menu #menu="matMenu" [class]="'menus-panel'">
                            <button class="flex justify-start items-center gap-2" mat-menu-item
                                (click)="downloadReport()">
                                <span class="tag-style">Grouped Report</span>
                            </button>
                            <button class="flex justify-start items-center gap-2" mat-menu-item
                                (click)="downloadReport('full')">
                                <span class="tag-style">Full Report</span>
                            </button>
                        </mat-menu>
                    </div>
                    <button *ngIf="currentUser.user_type === global.USER_TYPE.BILLER" mat-raised-button
                        class="dn-button-primary" (click)="downloadBatchReport()">Batch Report Download</button>
                </div>
            </div>
            <div class="flex start baseline gap-8">

                <div class="flex justify-start items-baseline input-with-cross">
                    <mat-label style="margin-right:20px;color:#999">Date of Service</mat-label>
                    <input *ngIf="this.serviceDateFilter" type="text" ngxDaterangepickerMd
                        [(ngModel)]="filter.date_of_service" (datesUpdated)="changeServiceDate($event)"
                        class="mat-like-input" [ranges]="preDateRanges" [alwaysShowCalendars]="true"
                        [maxDate]="this.c_date" [linkedCalendars]="true" [showClearButton]="true"/>
                </div>

                <div class="flex justify-start items-baseline">
                    <mat-form-field appearance="fill">
                        <mat-label>{{currentCompany.currentCompanyType === global.COMPANY_TYPE.SNF ? 'Select SNF WC
                            NURSE' :'Select Provider'}}</mat-label>
                        <input type="text" [(ngModel)]="provideModel" aria-label="provider" matInput
                            [formControl]="providerControl" [matAutocomplete]="providerAutoControl"
                            [ngStyle]="{color: selectedUser && selectedUser.is_activated !== 'true' ? 'gray' : '' }" class="input-font-styles">



                        <mat-autocomplete #providerAutoControl="matAutocomplete" autoActiveFirstOption>
                            <mat-option (click)="onSelectProvider()">--none--</mat-option>
                            <mat-option [class.text-gray]="provider.is_activated !== 'true'"
                                *ngFor="let provider of filteredProvider | async"
                                [value]="provider.name ?provider.name:''" (click)="onSelectProvider(provider);">
                                {{provider.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <button style="height:10px;" *ngIf="provideModel" mat-button mat-icon-button matSuffix
                        (click)="clearProviderFilter()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>

                <div class="flex justify-start items-baseline" style="width: 25%;">
                    <mat-form-field appearance="fill" style="width: 100%;">
                        <div class="flex justify-start items-center">

                            <img *ngIf="showPccLogo" src="../../../assets/icons/pcc-icon.svg" width="20px" height="20px"
                                alt="">
                            <input type="text" style="display:flex;" placeholder="Select Facility" aria-label="facility"
                                matInput [formControl]="facilityControl" [matAutocomplete]="facilityAutoControl"
                                [(ngModel)]="facilityMOdel" class="input-font-styles">

                            <mat-autocomplete #facilityAutoControl="matAutocomplete" autoActiveFirstOption>
                                <mat-option
                                    *ngIf="currentUser.user_type === global.USER_TYPE.DOCTOR || currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE"
                                    (click)="onSelectFacility()">--none--</mat-option>
                                <mat-option *ngIf="currentUser.user_type === global.USER_TYPE.BILLER"
                                    (click)="onSelectFacility()" [value]="All">All</mat-option>
                                <mat-option *ngFor="let facility of filteredFacilities | async" [value]="facility.title"
                                    (click)="onSelectFacility(facility);">
                                    <app-facility-title [facility]="facility"></app-facility-title>
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                    </mat-form-field>
                    <button style="height:10px;" *ngIf="facilityMOdel" mat-button mat-icon-button matSuffix
                        (click)="clearFacilityFilter()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                <div class="flex justify-start items-baseline">

                    <mat-form-field appearance="fill" class="status-select">
                        <mat-label>Select Status</mat-label>
                        <mat-select name="status" [(ngModel)]="noteStatus" #statusValue
                            (selectionChange)="onSelectStatus($event.value)">
                            <mat-option value="default">
                                All
                            </mat-option>
                            <mat-option value="published">Ready for Review
                            </mat-option>
                            <mat-option value="signed">Signed
                            </mat-option>
                            <mat-option value="uploaded">Uploaded
                            </mat-option>
                            <mat-option value="not_uploaded">Not Uploaded
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>


            </div>

        </div>
        <div class="br-spacing"></div>
        <div class="filter-data white-rounded-rect flex justify-center gap-5" style="padding:20px" >
            <div class="flex justify-evenly items-start gap-5 flex-grow">
                <div class="flex flex-col gap-1 filter-data-element"
                    *ngFor="let filterElement of filterData()">
                    <strong style="font-weight: 500;">{{filterElement.label}}</strong>
                    <span>{{filterElement.value}}</span>
                </div>
            </div>
            <button mat-raised-button (click)="isVisibleTable=true" class="dn-button-primary"
                *ngIf="isVisibleTable===false && sortedData && sortedData.length">View
            </button>
            <button mat-raised-button (click)="isVisibleTable=false" class="dn-button-secondary"
                *ngIf="isVisibleTable===true && sortedData && sortedData.length">Hide
            </button>
        </div>
        <div class="br-spacing"></div>
    </ng-container>

    <div *ngIf="isNotesVisible()" class="flex flex-col gap-2">
        <button mat-raised-button class="dn-button-primary" style="align-self: start;" (click)="setNotesToShow(null)">Go
            Back
        </button>

        <mat-table [dataSource]="notesToShow" matSort multiTemplateDataRows
            (matSortChange)="sortData($event, notesToShow)">
            <ng-container matColumnDef="title">
                <mat-header-cell style="font-weight: 500 !important;font-size: 14px; color: black"
                    [style.flex]="'0 0 15%'" *matHeaderCellDef mat-sort-header>Title
                </mat-header-cell>
                <mat-cell [style.flex]="'0 0 15%'" *matCellDef="let note" (click)="goToNoteEditor(note)"
                    style="cursor: pointer;">
                    {{note.trans_title | slice: 0:25}}{{note.trans_title.length > 25 ? ' ...' : ''}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="uploader_name">
                <mat-header-cell style="font-weight: 500 !important;font-size: 14px; color: black" *matHeaderCellDef
                    mat-sort-header>Uploaded By
                </mat-header-cell>
                <mat-cell *matCellDef="let note" (click)="goToNoteEditor(note)" style="cursor: pointer;">
                    {{note.uploaded_by_id_ref?.first_name + ' ' + note.uploaded_by_id_ref?.last_name}}
                    {{note.uploaded_by_id_ref.is_activated !== 'true' ? '(Inactive)' : ''}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="doctor_name">
                <mat-header-cell style="font-weight: 500 !important;font-size: 14px; color: black" *matHeaderCellDef
                    mat-sort-header>{{currentCompany.currentCompanyType === global.COMPANY_TYPE.SNF ? 'SNF WC NURSE' :
                    'Provider'}}
                </mat-header-cell>
                <mat-cell *matCellDef="let note" (click)="goToNoteEditor(note)" style="cursor: pointer;">
                    {{note.doctor_id_ref?.first_name + ' ' + note.doctor_id_ref?.last_name}}
                    {{note.doctor_id_ref.is_activated !== 'true' ? '(Inactive)' : ''}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="date_of_service">
                <mat-header-cell style="font-weight: 500 !important;font-size: 14px; color: black" *matHeaderCellDef
                    mat-sort-header>DOS
                </mat-header-cell>
                <mat-cell *matCellDef="let note" (click)="goToNoteEditor(note)" style="cursor: pointer;">
                    {{getNotesDOS(note)}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="facility_title">
                <mat-header-cell style="font-weight: 500 !important;font-size: 14px; color: black" *matHeaderCellDef
                    mat-sort-header>Facility
                </mat-header-cell>
                <mat-cell *matCellDef="let note" (click)="goToNoteEditor(note)" style="cursor: pointer;">
                    <app-facility-title [facility]="note.facility_id_ref"></app-facility-title>

                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="progress_status">
                <mat-header-cell style="font-weight: 500 !important;font-size: 14px; color: black" *matHeaderCellDef
                    mat-sort-header>Progress Status
                </mat-header-cell>
                <mat-cell *matCellDef="let note" (click)="goToNoteEditor(note)" style="cursor: pointer;">
                    {{getStatus(note.note_status.status)}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="Expansion Icon">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
                <td mat-cell *matCellDef="let note" class="expansion-icon">
                    <button mat-icon-button aria-label="expand row" (click)="expandedElementId = expandedElementId === note.noteId ? null : note.noteId;
                    toggleRow(note.noteId); tabIndex = 0;">
                        <mat-icon *ngIf="expandedElementId !== note.noteId">keyboard_arrow_down</mat-icon>
                        <mat-icon *ngIf="expandedElementId === note.noteId">keyboard_arrow_up</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="patient_name">
                <mat-header-cell style="font-weight: 500 !important;font-size: 14px; color: black" *matHeaderCellDef
                    mat-sort-header>Patient Name
                </mat-header-cell>
                <mat-cell *matCellDef="let note" (click)="goToNoteEditor(note)" style="cursor: pointer;">
                    {{note.patient_id_ref?.first_name + ' ' + note.patient_id_ref?.last_name}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="stackholders">
                <mat-header-cell style="font-weight: 500 !important;font-size: 14px; color: black" *matHeaderCellDef
                    mat-sort-header>Assign Note
                </mat-header-cell>
                <mat-cell *matCellDef="let note">
                    <mat-select
                        *ngIf="note.uploaded_by_id_ref.user_type === global.USER_TYPE.TRANSCRIBER || note.uploaded_by_id_ref.user_type === global.USER_TYPE.MEDICAL_ASSISTANT"
                        [disabled]="note.is_signed==='true'" name="stackholder"
                        [value]="getNoteStackholdersData(note)?.selectedStackholder?._id || ''"
                        (selectionChange)="handleStackHolderChange($event, note)">
                        <mat-option value="" disabled>
                            -- None --
                        </mat-option>
                        <mat-option *ngFor="let t of getNoteStackholdersData(note).stackholders" [value]="t._id">
                            ({{t.stackholder_type}}) {{t.first_name + ' ' + t.last_name}}
                        </mat-option>
                    </mat-select>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="encounters">
                <mat-header-cell style="font-weight: 500 !important;font-size: 14px; color: black" *matHeaderCellDef
                    mat-sort-header>Encounter Type
                </mat-header-cell>
                <mat-cell *matCellDef="let note">
                    {{ (note.template_id_ref && note.template_id_ref.for_note_type) ? note.template_id_ref.for_note_type : '' }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="details">
                <mat-header-cell style="font-weight: 500 !important;font-size: 14px; color: black" *matHeaderCellDef
                    mat-sort-header>Operations
                </mat-header-cell>

                <mat-cell *matCellDef="let note" style="cursor: pointer;">
                    <div *ngIf="checkVoidCensus(note.rounding_sheet_id)" class="flex gap-2">
                        <i class="icon icon-view" matTooltip="Details" matTooltipPosition="above"
                            [routerLink]="['../../note-editor/' + note.noteId]">
                        </i>
                    </div>
                    <div *ngIf="!checkVoidCensus(note.rounding_sheet_id)" class="flex gap-2">
                        <img width="22px" src="../../../../assets/icons/void_census.png" alt="">
                    </div>
                    <div style="margin-left: 5px;">
                        <i class="icon icon-delete" *ngIf="note.note_status.status ==='published'"
                            matTooltip="Delete Note" matTooltipPosition="above" (click)="deleteNote(note)"></i>
                    </div>

                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="accordion">
                <td mat-cell *matCellDef="let note" [attr.colspan]="displayedColumns.length">
                    <div class="accordion-cell"
                        [@accordionTrigger]="note.noteId === expandedElementId ? 'expanded' : 'collapsed'">
                        <mat-tab-group *ngIf="expandedRows.includes(note.noteId);" class="expanded-child"
                            [(selectedIndex)]="tabIndex" (selectedTabChange)="onTabChange($event);">
                            <mat-tab label="Patient Notes">
                                <app-patient-detail-list *ngIf="tabIndex === 0" [patient]="note.patient_id_ref"
                                    [isTelehealthReport]="true"></app-patient-detail-list>
                            </mat-tab>
                            <mat-tab label="Patient Documents">
                                <app-patient-documents-list *ngIf="tabIndex === 1"
                                    [patient]="note.patient_id_ref"></app-patient-documents-list>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </td>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            <tr mat-row *matRowDef="let row; columns: ['accordion']" class="accordion-row"></tr>
        </mat-table>
        <ngx-ui-loader loaderId="notes-list-loader"></ngx-ui-loader>
    </div>
    <div *ngIf="isVisibleTable" class="flex flex-col gap-2" [class.hidden]="isNotesVisible()">
        <div class="notes-group-dateofservice" *ngFor="let dateGroup of notesGroup ">
            <div class="flex flex-col gap-2">
                <div class="group-index-title">
                    <span>{{dateGroup._id| date:'MM/dd/yyyy'}}</span>
                </div>
                <div class="card">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="left" style="width:20%">{{currentCompany.currentCompanyType ===
                                    global.COMPANY_TYPE.SNF ? 'SNF WC NURSE' : 'Provider'}}</th>
                                <th class="left" style="width:35%">Facility</th>
                                <th class="left" style="width:10%">Count</th>
                                <th class="left" style="width:25%">Created By</th>
                                <th class="left" style="width:30%">Operations</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let gNotes of dateGroup.groupedData"
                                [class.text-gray]="gNotes.notedata?.provider.is_activated !== 'true'">
                                <td>
                                    {{gNotes.notedata?.provider?.first_name}} {{gNotes.notedata?.provider?.last_name}},
                                    {{gNotes.notedata?.provider?.title}}
                                </td>
                                <td>
                                    <img *ngIf="gNotes.notedata?.facility?.source === 'PointClickCare' || gNotes.notedata?.facility?.pcc_facId"
                                        src="../../../assets/icons/pcc-icon.svg" alt=""
                                        style="width: 35px;height: 20px;margin-left: -5px;;vertical-align: middle;">
                                    <img *ngIf="gNotes.notedata?.facility?.source === 'MatrixCare' "
                                        src="../../../assets/icons/matrix.png" alt=""
                                        style="height: 12px;width: 20px;vertical-align: middle;margin-right: 5px;margin-left: 5px;">
                                    <img *ngIf="gNotes.notedata?.facility?.source != 'PointClickCare' && !gNotes.notedata?.facility?.pcc_facId &&gNotes.notedata?.facility?.source != 'MatrixCare'"
                                        class="dn-icon" src="../../../assets/img/DN.png" alt="">
                                    {{gNotes.notedata?.facility?.title}}
                                </td>
                                <td>
                                    {{gNotes.notedata?.total}}
                                </td>
                                <td>
                                    {{gNotes.notedata?.user?.first_name}} {{gNotes.notedata?.user?.last_name}}

                                </td>
                                <td class="left">
                                    <div class="flex gap-2">
                                        <i class="icon icon-view" matTooltip="Details" matTooltipPosition="above"
                                            (click)="setNotesToShow(gNotes.notedata)">
                                        </i>
                                    </div>
                                </td>

                            </tr>

                            <tr style="background-color: #44597d;color: white; ">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <b>
                                        Total Count: {{totalCount(dateGroup.groupedData)}}
                                    </b>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>