<mat-card class="h100" style="padding-top:0">
    <mat-card-content>
        <div class="filter-form white-rounded-rect" style="padding: 16px 0 0 !important">

            <mat-calendar [selected]="calenderSelectedValue" (selectedChange)="calenderSelectedValue=$event;dateSelection.emit($event)"></mat-calendar>
            <div class="flex flex-col items-start justify-start gap-0">
                <div class="flex flex-col justify-start items-stretch gap-0"
                     style="padding-left: 10px;"
                     >
                    <div class="input-with-cross flex items-baseline">
                        <mat-form-field>
                            <mat-label>Select Facility</mat-label>
                            <mat-select [value]="selectedFacility" (selectionChange)="facilityChange($event)">
                                <mat-option value = "all">--All--</mat-option>
                                <mat-option *ngFor="let f of facilities" [value]="f._id">{{f.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
