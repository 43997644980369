<div>
    <mat-card >
        <div class="flex justify-between items-center gap-5 pos">
            <app-input-with-dropdown class="flex-grow" [noDropdown]="true" placeholder="Search CPT by Code"
                (inputChange)="applyFilter($event.target.value)">
            </app-input-with-dropdown>
        </div>
        <div style="margin-top: 20px;height: 77vh;overflow: auto;" class="flex justify-start items-start pos">
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="cpt">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;">CPTs</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.code}} ( {{element.description}})
                    </td>
                </ng-container>

                <ng-container matColumnDef="pos">
                    <th mat-header-cell *matHeaderCellDef style="width: 60rem;"> POSs </th>
                    <td mat-cell *matCellDef="let element">
                        <div style="height: 5rem; white-space: nowrap; position: absolute; width: 60rem;">
                            <ng-multiselect-dropdown name="pos" [placeholder]="'Select POS'"
                                [settings]="posDropdownSettings" [data]="allPos" [(ngModel)]="element.pos_ids"
                                (onSelect)="onPOSSelect(element,$event); " (onDeSelect)="onPOSDeSelect(element,$event);"
                                (onSelectAll)="onSelectAllPOS(element,$event);"
                                (onDeSelectAll)="onDeSelectAllPOS(element,$event);">
                            </ng-multiselect-dropdown>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="defaultPOS">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%;">Default POS</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-form-field style="width: 100%; margin-bottom: -1rem;">
                            <mat-label>Select Default POS</mat-label>
                            <mat-select [(ngModel)]="element.default_pos" (selectionChange)="onDefaultPosChange(element,$event)">
                                <mat-option *ngFor="let pos of element.arrayOfDefaultPOS" [value]="pos.code.toString()">
                                    {{pos.codeName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
        </div>
    </mat-card>
</div>