import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OranizationService } from './oranization.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-organization-dialog',
  templateUrl: './organization-dialog.component.html',
  styleUrls: ['./organization-dialog.component.css']
})
export class OrganizationDialogComponent implements OnInit {
  model: any = {
    title: '',
    tin: ''
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private organizationService: OranizationService,
    private dialogRef: MatDialogRef<OrganizationDialogComponent>,
    private toastr: ToastrService,


  ) {
    this.model.company_id = this.data.company_id;
    if(this.data && this.data.organization) {
      this.model.title = this.data.organization.title;
      this.model.tin = this.data.organization.tin;
      this.model._id = this.data.organization._id;
    }
  }

  async ngOnInit() {
  }

  onSaveOrganization() {
    this.organizationService.saveOrganization(this.model).subscribe((res: any) => {
      if (res.status == 200) {
        this.toastr.success(res.message, 'Success');
        this.closeDialog(res.data);
      }
    });
  }

  closeDialog(data = null) {
    this.dialogRef.close(data);
  }
}
