<mat-card style="display: grid; place-content: center">

    <mat-card-content style="margin-left: 50px" *ngIf="loaded">
        <div  style="max-width: 700px;">
            <div class="flex justify-between items-center">
                <h2 style="color: black;margin:0">Appointment Detail {{editMode ? 'Update' : ''}}</h2>
                
                <div class="flex justify-end items-center gap-2">
                    <i class="icon icon-edit"
                    *ngIf="editMode === false"
                    (click)="editMode = true"
                    matTooltip="Edit Appointment" matTooltipPosition="above"></i>
                    <i class="icon icon-delete"
                    (click)="deleteAppointment()"
                    matTooltip="Delete Appointment" matTooltipPosition="above"></i>
                    <button mat-raised-button
                        class="dn-button-primary"
                        (click)="exitToParent()"
                        type="button">
                        <mat-icon>keyboard_backspace</mat-icon>
                        Go to Calendar
                    </button>
                    <button mat-raised-button
                        type="button"
                        [disabled]="!model.is_video"
                        (click)="goToMeeting()"
                        class="dn-button-primary">
                        <mat-icon>call</mat-icon>
                        Go to Meeting
                    </button>
                </div>
            </div>
                <form class="flex flex-col" (ngSubmit)="addAppointment(form.value)" #form="ngForm">
                    <div class="flex flex-col">
                        <mat-grid-list cols="2" rowHeight="60px" style="width: 400px; margin: -10px">
                            <mat-grid-tile colspan="1" rowspan="1">
                                <mat-form-field>
                                    <mat-label>Title</mat-label>
                                    <input matInput
                                           readonly
                                           name="title"
                                           [(ngModel)]="model.title">
                                </mat-form-field>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="1" rowspan="1">
                                <mat-form-field>
                                    <mat-label>Select Facility</mat-label>
                                    <input matInput
                                           readonly
                                           name="facility_id"
                                           [(ngModel)]="model.facility_id.title">
                                </mat-form-field>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="1" rowspan="1">
                            </mat-grid-tile>
                            <mat-grid-tile colspan="1" rowspan="1">
                                <mat-form-field>
                                    <mat-label>Select payor</mat-label>
                                    <input matInput
                                           readonly
                                           name="payor_id"
                                           [(ngModel)]="model.payor_id.abbreviation">
                                </mat-form-field>
                            </mat-grid-tile>
                        </mat-grid-list>
                        <mat-form-field>
                            <mat-label>Notification Type</mat-label>
                            <input matInput
                                   readonly
                                   name="notify_type"
                                   [(ngModel)]="notify_type">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Notification Time(minutes earlier)</mat-label>
                            <input matInput
                                   readonly
                                   name="notify_time"
                                   [(ngModel)]="model.notify_time">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Add Conference</mat-label>
                            <input matInput
                                   readonly
                                   name="conference"
                                   [(ngModel)]="model.is_video">
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput
                                   [matDatepicker]="picker"
                                   name="dos"
                                   #dos="ngModel"
                                   [(ngModel)]="model.dos"
                                   readonly
                                   placeholder="Appointment date">
                            <mat-datepicker-toggle disabled matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker disabled #picker></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Appointment start time</mat-label>
                            <input matInput
                                   readonly
                                   name="tos"
                                   [(ngModel)]="model.startTime">
                        </mat-form-field>
                    </div>
                </form>
        </div>
    </mat-card-content>
</mat-card>
