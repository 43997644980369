<form (ngSubmit)="submitForm()">
    <mat-form-field style="width: 100%">
        <mat-label>Title</mat-label>
        <input matInput type="text" name="title" #title="ngModel" [(ngModel)]="consentForm.title">
    </mat-form-field>

    <div style="height: 51vh;">
        <ckeditor [(ngModel)]="consentForm.description" name="description" class="h100"
            editorUrl="../../../../../assets/ckeditor/ckeditor.js" [readOnly]="!cke4Config.editable"
            [config]="cke4Config" type="divarea">
        </ckeditor>
    </div>
    <br>
    <div class="flex justify-center items-center gap-2">
        <button mat-raised-button class="dn-button-secondary" mat-dialog-close type="button">Cancel</button>
        <button mat-raised-button type="button" class="dn-button-primary" [disabled]="!(consentForm.description && consentForm.title)" type="submit">Save</button>
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</form>