import { Component, OnInit, Output,EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from 'src/app/services/Modules/company.service';
import * as usa_states from 'src/app/shared/popup-dialogs/facility-add-dialog/usa_states';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FacilityService } from 'src/app/services/facility.service';
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { FacilityContactDialogComponent } from "src/app/shared/popup-dialogs/facility-contact-dialog/facility-contact-dialog.component";
import { AuthService } from '../../../../../services/auth.service';
import { lastValueFrom } from 'rxjs';
import { FormControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';

interface TimeZone {
    text: string;
    value: string;
}

@Component({
    selector: 'app-company-facility-form',
    templateUrl: './company-facility-form.component.html',
    styleUrls: ['./company-facility-form.component.css']
})
export class CompanyFacilityFormComponent implements OnInit {
    @Output() newFacilityOutput=new EventEmitter();
    usa_states = usa_states.default.slice(1)
    loaderId = 'company-user-management-form-loader';
    facility_id: string;
    timezones: TimeZone[] = [];
    pods = [
        "E. WA",
        "S. WA",
        "N. WA",
        "Utah",
        "W. TX",
        "ID/AZ",
        "Oregon"
    ];
    model = {
        title: '',
        address: '',
        street: '',
        city: '',
        state: '',
        zip_code: '',
        trueRCMID: '',
        pod: '',
        company_id: null,
        source: null,
        external_id: null,
        organization_id: null,
        npi: null,
        // contact: {
        //     first_name: '',
        //     last_name: '',
        //     phone: '',
        //     email: '',
        //     position: ''
        // }
        place_of_service: '',
        initial_load: false,
        initial_load_progress: 'not_started',
        npi_org_ids: [],
        timeZone: ''
    }
    public dataSource: any;
    displayedColumns: string[] = ['name', 'position', 'email', 'phone', 'operations', 'association'];
    isRedirectedFromParentRoute: boolean = false;
    isProductOwner: boolean = false;
    currentUser: any;
    isPccFacililty: boolean = false;
    initial_load_inprogress = false
    isInitialLoadBtnDisable:boolean=false
    streetControl = new FormControl();
    zipControl = new FormControl();
    npiControl = new FormControl();
    externalIdControl = new FormControl();
    orgIdControl = new FormControl();
    facIdControl = new FormControl();
    pccOrgIdControl = new FormControl();

    constructor(private _location: Location,
        private route: ActivatedRoute,
        private loader: NgxUiLoaderService,
        private companyService: CompanyService,
        private facilityService: FacilityService,
        private dialog: MatDialog,
        private _router: Router,
        public commonService: CommonService,
        private _authService: AuthService,
        private toastr: ToastrService) {
        this.model.company_id = this.route.parent.parent.snapshot.params.id;
        this.facility_id = this.route.snapshot.params.id;
        this.currentUser = this._authService.currentUser;
        this.route.queryParams.subscribe((params: any) => {
            if (params) {
                this.isRedirectedFromParentRoute = params.isRedirectedFromParentRoute;
            }
        });
    }

    ngOnInit(): void {
        this.timezones = this.commonService.getFormattedTimeZones();
        if (this.facility_id) {

            this.loader.startLoader(this.loaderId);

            this.facilityService.getFacility(this.facility_id).subscribe((response: any) => {
                if (response.status === 200) {
                    // if(response.data.contact === undefined) {
                    //     response.data.contact = {
                    //         first_name: '',
                    //         last_name: '',
                    //         phone: '',
                    //         email: '',
                    //         position: ''
                    //     }
                    // }
                    if (response.data.trueRCMID === undefined) {
                        response.data.trueRCMID = "";
                    }

                    if(response.data&&(response.data.source?.includes('PointClickCare')||(response.data.pcc_facId))){
                        this.isPccFacililty=true;
                    }
                    this.model = response.data;
                    this.model.company_id = this.route.parent.parent.snapshot.params.id;
                    if(response.data.npi_org_ids !== undefined && response.data.npi_org_ids.length > 0) {
                        let idx = response.data.npi_org_ids.findIndex(npi_org_id => npi_org_id.company_id.toString() === this.model.company_id.toString());
                        if(idx !== -1) {
                            this.model.npi = response.data.npi_org_ids[idx].npi;
                            this.model.organization_id = response.data.npi_org_ids[idx].organization_id;
                        }
                        else {
                            this.model.npi = response.data.npi;
                            this.model.organization_id = response.data.organization_id;
                        }
                    }
                    
                    console.log(response.data);
                }
                this.loader.stopLoader(this.loaderId);
            });
            this.companyService.getFacilityContacts({ facility: this.facility_id }, 'all')
                .subscribe(res => {
                    console.log(res.data.array);
                    this.dataSource = new MatTableDataSource(res.data.array);
                });

        }
        if (this.currentUser.product_owner == true) {
            this.isProductOwner = true;
        }
    }


    goBack() {
        if (this.isRedirectedFromParentRoute) {
            const companyId = this.route.parent.parent.snapshot.params.id;
            return this._router.navigate([`/company/${companyId}/facilities/list`]);
        }
        this._location.back();
    }
    matrixPatientExtract(){
        this.isInitialLoadBtnDisable = true
        this.initial_load_inprogress = true;
        this.toastr.info('Initial Patient Load is in progress...')
        let result =  this.companyService.matrixPatientExtract(this.facility_id, this.model.external_id).subscribe((response: any)=>{
            if(response){
                this.isInitialLoadBtnDisable = false
            }
        })
    }

    async handleSubmit(e) {
        
        if (this.model.title
            //  && this.model.contact.first_name 
            //  && this.model.contact.last_name 
            //  && this.model.contact.email 
            //  && this.model.contact.phone 
            //  && this.model.contact.position
        ) {

            try {
                this.loader.startLoader(this.loaderId);
                if (this.facility_id) {
                    let response = await lastValueFrom(this.companyService.updateFacility(this.model)) as any;
                        this.loader.stopLoader(this.loaderId);
                        this.toastr.success('Facility updated succesfully', 'Success')
                        // this.goBack();
                    // })
                } else {
                    let data = await  lastValueFrom(this.companyService.addFacility(this.model) ) as any
                        // (data) => {
                            this.newFacilityOutput.emit(data);
                            this.toastr.success('Facility added successfully', 'Success');
                            // this.goBack();
                        // }
                        // );
                        if(this.model.source == 'MatrixCare' && this.model.external_id ){
                            this.matrixPatientExtract()
                        }
                    }
                this.goBack();
            } catch (error) {
                this.loader.stopLoader(this.loaderId);
                this.toastr.error('Something went wrong', 'Error');
            }
        }
    }

    editFacilityContact(contact) {
        const dialogRef = this.dialog.open(FacilityContactDialogComponent, {
            width: '600px',
            data: { update: true, contact: contact }
        });

        dialogRef.afterClosed().subscribe(async (data) => {
            // @ts-ignore
            if (contact._id === this.model.contact._id) {
                this.loader.startLoader(this.loaderId);

                this.facilityService.getFacility(this.facility_id).subscribe((response: any) => {
                    if (response.status === 200) {
                        this.model = response.data;
                        this.model.company_id = this.route.parent.parent.snapshot.params.id;
                    }
                    this.loader.stopLoader(this.loaderId);
                });
            }
            this.companyService.getFacilityContacts({ facility: this.facility_id }, 'all')
                .subscribe(res => {
                    this.dataSource = new MatTableDataSource(res.data.array);
                });
        });
    }

    deleteFacilityContact(contact) {
        let params = {
            _id: contact._id,
            is_active: false
        };
        this.companyService.updateFacilityContact(params)
            .subscribe(res => {
                this.toastr.success('Facility Contact Deleted', 'Success');
                this.companyService.getFacilityContacts({ facility: this.facility_id }, 'all')
                    .subscribe(res => {
                        this.dataSource = new MatTableDataSource(res.data.array);
                    });
            });
    }

    openDialog() {
        if (!this.facility_id) {
            this.toastr.error('Please add facility first', 'Error');
            return;
        }
        const dialogRef = this.dialog.open(FacilityContactDialogComponent, {
            width: '600px',
            data: { facility_id: this.facility_id }
        });

        dialogRef.afterClosed().subscribe(async (data) => {
            this.companyService.getFacilityContacts({ facility: this.facility_id }, 'all')
                .subscribe(res => {
                    this.dataSource = new MatTableDataSource(res.data.array);
                });
        });
    }

    numberToString(number) {
        return String(number);
    }
    keyPressNumbers(event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        // Only Numbers 0-9
        if ((charCode < 48 || charCode > 57)) {
            event.preventDefault();
            return false;
        }
        else {
            return true;
        }
    }
    keyPressAlphanumeric(event) {

        var inp = String.fromCharCode(event.keyCode);

        if (/[a-zA-Z0-9\s/-_-.-]/.test(inp)) {
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }
    allowOnlyText(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode == 32) {
            return true;    
        }
        if (charCode > 90 && charCode < 97) {
            return false;
        }

        if (charCode < 65 || charCode > 122) {
            return false;
        }
        return true;
    }
    toggle(contact, $event) {
        let params = {
            _id: contact._id,
            is_associated: $event.checked,
        };
        this.companyService.updateFacilityContact(params)
            .subscribe(res => {
                this.toastr.success('Facility Contact Association Updated', 'Success');
                this.companyService.getFacilityContacts({ facility: this.facility_id }, 'all')
                    .subscribe(res => {
                        this.dataSource = new MatTableDataSource(res.data.array);
                    });
            });
    }
}
