<table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="facility">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Facility
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.facility_id_ref?.title || 'Unknown Facility'}}
        </td>
    </ng-container>

    <ng-container matColumnDef="provider">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Provider
        </th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.provider">
                {{element.provider?.last_name+' '+element.provider?.first_name+', '+element.provider?.title}}
            </span>
            <span *ngIf="!element.provider">
                {{'---'}}
            </span>
        </td>
    </ng-container>


    <ng-container matColumnDef="reason">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Reason
        </th>
        <td mat-cell *matCellDef="let element">
            {{getReason(element)}}
        </td>
    </ng-container>


    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Date
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.createdAt |date: 'MM/dd/yyyy'}}
        </td>
    </ng-container>


    <ng-container matColumnDef="event_type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Event Type
        </th>
        <td mat-cell *matCellDef="let element">
            {{(element.event_type||'discharge')| uppercase}}
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" 
            style="background-color: pink;"
            [ngStyle]="{'background-color': getRowColor(row) ? 'pink' : 'rgb(185 230 185)' }"
            >
    </tr>
</table>
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>