<app-header></app-header>

<main>
  <div class="container">
    <button *ngIf="shouldShowButton" mat-raised-button class="dn-button-primary" (click)="openPostDialog()"
      style="width: 100px; position: absolute; right: 30px; top: 75px;" >Post</button>
    <div [ngClass]="shouldShowButton ? 'button-margin':''" *ngIf="notifications.length == 0">
      <mat-card class="notifications-list notification-card" >
        <mat-card-content>
          <div style="min-height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;">
            No notifications to show.
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    
    <div *ngIf="notifications.length > 0" [ngClass]="shouldShowButton ? 'button-margin':''" >
      <mat-card class="notifications-list notification-card" *ngFor="let notification of notifications">
        <mat-card-content class="card-content">
          <div class="notification-item">
              <div class="notification-description">
                <div class="notification-icon">
                  <mat-icon>notifications_none</mat-icon>
                </div>
                <div style="font-weight: 500;" [innerHTML]="notification.description"></div>
              </div>
              <div class="notification-end">
                <div class="notifcation-date">{{ notification.createdAt | date: 'MM/dd/yyyy' }}</div>
                <div style="display: flex;align-items: center; justify-content: center; margin: 5px;">
                </div>
              </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</main>
