<div class="flex flex-col" style="display: contents;" *ngIf="dictations">
    <div class="flex flex-grow" style="width: 100%;">
        <div class="flex justify-end items-center" style="width: 100%; flex-direction: row-reverse; padding: 7px;" >
            <mat-label style="margin-right: 15px;">In Progress</mat-label>
            <mat-slide-toggle  style="margin-right: 15px;" class="tp-margin" [(ngModel)]="checkedDict" (click)="$event.stopPropagation();" (change)="changeDictStatus($event.checked)">
              </mat-slide-toggle>
            <mat-label>All&nbsp;</mat-label>
        </div>
    </div>
    

    <table mat-table [dataSource]="dataSource" matSort style=" overflow: auto; max-height: 440px;" (matSortChange)="sortData($event)">
        <ng-container matColumnDef="date">
            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header > Date of Creation </th>
            <td mat-cell *matCellDef="let element">
                {{getDate(element)}}
            </td>
        </ng-container>
        <ng-container matColumnDef="provider">
            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header> Provider </th>
            <td mat-cell *matCellDef="let element">
                {{getProvider(element)}} 
            </td>
        </ng-container>
        <ng-container matColumnDef="facility">
            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header> Facility </th>
            <td mat-cell *matCellDef="let element">
                {{getFacility(element)}} 
            </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header > Status </th>
            <td mat-cell *matCellDef="let element">
                {{getStatus(element)}} 
            </td>
        </ng-container>
        <ng-container matColumnDef="count">
            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header> Count </th>
            <td mat-cell *matCellDef="let element">
                {{getCount(element)}} 
            </td>
        </ng-container>
        <ng-container matColumnDef="view">
            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header> </th>
            <td mat-cell *matCellDef="let element">
                <i matTooltip="View" matTooltipPosition="above"  class="icon icon-view"></i>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" (click)="$event.stopPropagation()"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="rowClicked(row)"></tr>
    </table>
</div> 

<div class="flex flex-col" style="display: contents;" *ngIf="!dictations">
<div class="flex flex-grow" style="width: 100%;">
    <div class="flex justify-start items-center" style="width: 100%; flex-direction: row-reverse; padding: 7px;" >
        <mat-slide-toggle *ngIf="_currentUser.user_type == '2' || _currentUser.user_type == 'medical_assistant'" style="margin-right: 15px;" class="tp-margin" [(ngModel)]="checked" (click)="$event.stopPropagation();" (change)="changeStatus($event.checked)">
          </mat-slide-toggle>
          <mat-label *ngIf="_currentUser.user_type == '2' || _currentUser.user_type == 'medical_assistant'">Only Show My Data&nbsp;</mat-label>
    </div>
    <div class="flex items-end" style="width: 100%; flex-direction: row-reverse; padding: 7px;" >
        <mat-label style="margin-right: 15px;">Unsigned: None</mat-label>
        <mat-slide-toggle  style="margin-right: 15px;" class="tp-margin" [(ngModel)]="checkedNotes" (click)="$event.stopPropagation();" (change)="changeNoteStatus($event.checked)">
          </mat-slide-toggle>
        <mat-label>All&nbsp;</mat-label>
    </div>
</div>

    <table mat-table [dataSource]="dataSource" matSort style=" overflow: auto; max-height: 440px;" (matSortChange)="sortData($event)">
        <ng-container matColumnDef="date">
            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cedbdf;"> Date of Creation </th>
            <td mat-cell *matCellDef="let element">
                {{getDate(element)}} 
            </td>
        </ng-container>
        <ng-container matColumnDef="provider">
            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cedbdf;"> Provider </th>
            <td mat-cell *matCellDef="let element">
                {{element.creator[0].first_name}} {{element.creator[0].last_name}} {{element.creator[0].title}}
            </td>
        </ng-container>
        <ng-container matColumnDef="facility">
            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cedbdf;"> Facility </th>
            <td mat-cell *matCellDef="let element">
                {{getFacility(element)}}
            </td>
        </ng-container>
        <ng-container matColumnDef="note_status">
            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cedbdf;"> Note Status </th>
            <td mat-cell *matCellDef="let element">
                {{getNoteStatus(element)}}
            </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cedbdf;"> Signed Status </th>
            <td mat-cell *matCellDef="let element">
                {{getStatus(element, 'trans')}} 
            </td>
        </ng-container>

        <ng-container matColumnDef="count">
            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cedbdf;"> Count </th>
            <td mat-cell *matCellDef="let element">
                {{getCount(element)}}
            </td>
        </ng-container>
        <ng-container matColumnDef="view">
            <th class="header-data" mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cedbdf;"> </th>
            <td mat-cell *matCellDef="let element">
                <i matTooltip="View" matTooltipPosition="above"  class="icon icon-view"></i>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true" (click)="$event.stopPropagation()"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns2;" (click)="rowClickedTrans(row)"></tr>
    </table>
</div>