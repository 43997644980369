<!-- <div class="container" style="display: flex; flex-direction: column !important;min-height: unset !important;height: 100% !important;"> -->
<div class="container">
    <mat-card class="adnan">
        <mat-card-content>
            <mat-tab-group (selectedTabChange)="onTabClick($event)" style="height: 100% !important;">
                <mat-tab label="Wound">
                    <div class="mat-tab-content" *ngIf="tabIndex == 0">
                        <app-wound [company_id]="company_id"></app-wound>
                    </div>
                </mat-tab>
                <mat-tab label="DERM">
                    <div class="mat-tab-content" *ngIf="tabIndex == 1">
                        <app-derm [company_id]="company_id"></app-derm>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </mat-card>
</div>