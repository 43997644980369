import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment'
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FacilityService } from 'src/app/services/facility.service';
import { Location } from '@angular/common';
import { WoundService } from 'src/app/services/wound.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { WoundCareWidgetService } from 'src/app/includes/note-sidebar/wound-care-widget/wound-care-widget.service';
import { MatDialog } from '@angular/material/dialog';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-qapi-report-v2',
  templateUrl: './qapi-report-v2.component.html',
  styleUrls: ['./qapi-report-v2.component.css']
})
export class QapiReportV2Component implements OnInit {
  @Input() reportFilter: any;
  @Input() selectedFacility: any;
  @Output() countChanges = new EventEmitter<any>();
  currentDate = moment(new Date());
  loaderId = 'app-qapi-report';
  filter: any = {
    creationDate: {
      startDate: moment().subtract(27, 'days'),
      endDate: moment()
    },
    facilityId: null,
    state: "active",
    reportType: "improving",
    companyId: null,
    facilities: null
  };
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 1 Week': [moment().subtract(6, 'days'), moment()],
    // 'Last 2 Week': [moment().subtract(13, 'days'), moment()],
    // 'Last 3 Week': [moment().subtract(20, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    // 'This Month': [moment().startOf('month'), moment().endOf('month')],
    // 'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  iscreationDateFilterSelected: boolean = true;
  filteredFacilities: Observable<string[]>;
  displayedColumns: string[] = [
    // 'organization', 'faciltiy', 
    'assessment_date', 'first_name', 'last_name', 'woundNo', 'body_part', 'etiolgy', 'stage', 'diffInWeek', 'area_change'];
  facilityControl = new FormControl();
  facilities: Array<any> = [];
  companyId: any;
  dataSource: any = new MatTableDataSource([]);
  qapiReport: any;
  isClinicalSummaryReportVisible: boolean = false;
  isOperationsSummaryReportVisible: boolean = false;
  isAmputationReportVisible: boolean = false;
  facilityTitle: any;
  isRehospitalizationReportVisible: boolean = false;
  placeContent: string = "space-between center";
  user_dashboard: Boolean = false;
  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    PAGE_LIMIT: 10,
    skip: 0
  };
  nursingSide: boolean;
  currentUser: any;
  companySide: boolean;
  constructor(
    private _toastr: ToastrService,
    private _facilityService: FacilityService,
    private _route: ActivatedRoute,
    private _woundCareWidgetService: WoundCareWidgetService,
    private _location: Location,
    private _wound: WoundService,
    private loader: NgxUiLoaderService,
    private dialog: MatDialog,
    private _authService: AuthService,
    private _commonService: CommonService
  ) {
    this.currentUser = this._authService.currentUser;
    if (window.location.pathname.includes('dashboard')) {
      this.user_dashboard = true;
      this.companyId = this._authService.currentUser.company_id;
      this.preDateRanges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 1 Week': [moment().subtract(6, 'days'), moment()],
        'Last 2 Weeks': [moment().subtract(13, 'days'), moment()],
        'Last 3 Weeks': [moment().subtract(20, 'days'), moment()],
        'Last 4 Weeks': [moment().subtract(27, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    } else {
      this.companyId = this._route.parent.snapshot.params.id;
      if (this._route.parent.snapshot.params.id) {
        this.companySide = true;
      } else {
        this.companyId = this.currentUser.company_id;
      }
    }
    if (window.location.pathname.includes('nursing')) {
      this.nursingSide = true;
    }
    this.filter.companyId = this.companyId;
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId);
    if (this.reportFilter) {
      this.filter.reportType = this.reportFilter;
    }
    if (this.user_dashboard) {
      if (this.nursingSide) {
        await this.initFacilities();
      } else {
        await this.getFacilitiess();
      }
    } else {
      await this.initFacilities();
    }
    if (this.selectedFacility) {
      if (this.facilities.length > 0) {
        this.filter.facilityId = this.selectedFacility._id;
      }
      this.facilityControl.setValue(this.selectedFacility.title);
    } else {
      this.selectDefaultFacility();
    }
    await this.getCount();
    await this.initQapiReport();
    this.filteredFacilities = this.facilityControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => this.filterFacilities(value)));
    this.loader.stopLoader(this.loaderId);
  }
  private filterFacilities(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) !== -1);
    }
    else {
      return this.facilities;
    }
  }
  selectDefaultFacility() {
    if (this.facilities.length > 0) {
      this.filter.facilityId = this.facilities[0]._id;
    }
    this.facilityControl.setValue(this.facilities[0].title);
  }
  getCount() {
    let f_facilities;
    if (this.user_dashboard && this.filter.facilityId == null) {
      f_facilities = this.facilities.map((facility) => facility._id);
      f_facilities = f_facilities.filter((f) => f !== null);
    } else {
      f_facilities = null;
    }
    this._wound.getQapiReportCount(this.filter.creationDate, this.filter.facilityId, this.filter.reportType, null, f_facilities).subscribe((res: any) => {
      if (res.status == 200) {
        this.pagination.totalRecords = res.qapiReportCount;
        this.handlePagination();
        if (this.user_dashboard) {
          this.countChanges.emit({ count: res.qapiReportCount, type: this.filter.reportType });
        }
      }
    });
  }
  initQapiReport() {
    return new Promise<void>((resolve, reject) => {
      let pageNo = this.pagination.pageNo;
      if (this.user_dashboard && this.filter.facilityId == null) {
        let f_facilities = this.facilities.map((facility) => facility._id);
        this.filter.facilities = f_facilities.filter((f) => f !== null);
      } else {
        this.filter.facilities = null
      }
      this.loader.startLoader(this.loaderId);
      this.handleReportChange(this.filter.reportType);
      this._wound.getQapiReportV2(this.filter, pageNo).subscribe((response: any) => {
        if (response.status == 200) {
          if (response.data.qapiReport) {
            this.qapiReport = response.data.qapiReport;
            this.pagination.totalRecords = response.data.totalRecords || 0;
          }
          else {
            this.qapiReport = response.data;
          }
          console.log("this.qapiReport", this.qapiReport);
          this.handlePagination();
          if (this.filter.reportType === 'clinical-summary'
            || this.filter.reportType === 'operations-summary'
            && typeof response.data === "object" && Object.keys(response.data).length > 0) {
            this.pagination.hasNextPage = false;
            this.pagination.hasPrevPage = false;
            this.pagination.totalRecords = 1;
          }
          if (this.filter.reportType !== 'clinical-summary' &&
            this.filter.reportType !== 'operations-summary' &&
            this.filter.reportType !== 'rehospitalization') {
            this.dataSource.data = this.qapiReport;
          }
          resolve(null);
          if (this.user_dashboard) {
            if (this.filter.reportType === 'amputation') {
              this.isAmputationReportVisible = true;
            }
          }
          this.loader.stopLoader(this.loaderId);
        }
        else {
          resolve(null);
          this.loader.stopLoader(this.loaderId);

        }
      }, err => {
        reject('error => ' + err.toString());
        this.loader.stopLoader(this.loaderId);
        this._toastr.error(err, "ERROR");
      }
      );
    });
  }
  resetPagination() {
    this.pagination.hasNextPage = false;
    this.pagination.hasPrevPage = false;
    this.pagination.pageNo = 1;
    this.pagination.skip = 0;
    this.pagination.totalRecords = 0;
  }
  handlePagination() {
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    if (this.pagination.skip + this.qapiReport?.length < this.pagination.totalRecords) {
      this.pagination.hasNextPage = true;
    }
    else {
      this.pagination.hasNextPage = false;
    }
    if (this.pagination.skip > 0) {
      this.pagination.hasPrevPage = true;
    }
    else {
      this.pagination.hasPrevPage = false;
    }
  }

  goToPreviousPage() {
    this.pagination.pageNo--;
    this.initQapiReport();
  }
  goToNextPage() {
    this.pagination.pageNo++;
    this.initQapiReport();
  }
  async initFacilities() {
    const filter = {
      assoc_company_ids: this.companyId,
      is_active: true
    };
    const projection = {
      title: 1,
      source: 1,
      assoc_company_ids: 1
    };

    let companyAssocPopulation: facilityPopulationInterface = {
      collection: 'companies',
      field: ["assoc_company_ids"],
      filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: [true, { $in: ['$_id', '$$assoc_company_ids'] }] }] } },
      project: { name: 1 },
    };
    
    const facilitiesResponse: any = await this._facilityService.getFacilities(filter, projection, [companyAssocPopulation]).toPromise();
    if (facilitiesResponse.status == 200) {
      this.facilities = facilitiesResponse.data.array;
    }
  }
  async getFacilitiess() {
    let response: any = await this._facilityService.getMyFacilities().toPromise()
    // this.loader.stopLoader('loader');
    if (response.status == 200) {
      this.facilities = response.data.array;
      this.facilities.unshift({
        source: "All",
        title: "All",
        _id: null
      })
    }
    // });
  }
  onSelectFacility($event, facility) {
    if ($event.isUserInput) {
      this.filter.facilityId = facility._id;
      this.facilityTitle = facility.title;
    }
  }
  changeCreationDate(event) {
    if (event.startDate && event.endDate) {
      this.filter.creationDate = {
        startDate: null,
        endDate: null
      };
      const noOfDays = this._commonService.getDifferenceOfDates(event.startDate,event.endDate,'days');
      if (noOfDays > 29 && !this.user_dashboard) {
        this.filter.creationDate = {};
        this.filter.creationDate.startDate = moment(event.startDate);
        this.filter.creationDate.endDate = moment(this.filter.creationDate.startDate).add(29, "days").endOf('day');
        this._toastr.error('Can not select more than 30 days', 'Date Error');
      }
      else {
        this.filter.creationDate.startDate = event.startDate;
        this.filter.creationDate.endDate = event.endDate;
      }
    }
  }

  goBack() {
    this._location.back();
  }
  async handleApplyFilter() {
    this.resetPagination();
    [this.isClinicalSummaryReportVisible,
    this.isOperationsSummaryReportVisible,
    this.isAmputationReportVisible,
    this.isRehospitalizationReportVisible] = [false, false, false, false];
    await this.getCount();
    await this.initQapiReport();
    if (this.filter.reportType === 'clinical-summary') {
      this.isClinicalSummaryReportVisible = true;
    }
    else if (this.filter.reportType === 'operations-summary') {
      this.isOperationsSummaryReportVisible = true;
    }
    else if (this.filter.reportType === 'amputation') {
      this.isAmputationReportVisible = true;
    }
    else if (this.filter.reportType === 'rehospitalization') {
      this.isRehospitalizationReportVisible = true;
    }
  }

  // async showGraphofWound(element){
  //   // this.loader.startLoader(this.loaderId)
  //   if(this.user_dashboard){
  //     this._toastr.info('Calculating Wound Graph', 'Please wait..');
  //     const { status, data } = await lastValueFrom(this._woundCareWidgetService.getWoundGraphData(element._id)) as any;
  //     if (status == 200) {
  //       const wounds = data.array.map(item => ({
  //         length: parseFloat(item.length),
  //         width: parseFloat(item.width),
  //         depth: parseFloat(item.depth),
  //         createdAt: new Date(item.createdAt),
  //         updatedAt: new Date(item.updatedAt),
  //         area: item?.area,
  //         volume: item?.volume,
  //         source: item.source,
  //         entered_dot_phrases: item?.entered_dot_phrases
  //       }));
  //       if(wounds?.length){
  //         this.dialog.open(WounndGraphDialogComponent, {
  //           width: '50%',
  //           data: { wounds:wounds},
  //         });
  //       }else{
  //         this._toastr.warning('No Data Found', 'Sorry');
  //       }
  //     }
  //   }
  //   // this.loader.stopLoader(this.loaderId)
  // }

  getDate(element) {
    if (element.recentNote) {
      if (element.recentNote.date_obj) {
        return moment(element.recentNote.date_obj.month + "/" + element.recentNote.date_obj.day + "/" + element.recentNote.date_obj.year).format("MMM/DD/YYYY");
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  async resetFilter() {
    delete this.filter.creationDate;
    const defaultDate = {
      startDate: moment().subtract(6, 'days'),
      endDate: moment()
    };
    this.filter.creationDate = defaultDate;
    [this.isClinicalSummaryReportVisible,
    this.isOperationsSummaryReportVisible] = [false, false];
    this.filter.reportType = "improving";
    this.filter.state = "active";
    this.selectDefaultFacility();
    await this.initQapiReport();
  }

  handleReportChange(value) {
    this.filter.reportType = value;
    [this.isClinicalSummaryReportVisible,
    this.isOperationsSummaryReportVisible,
    this.isAmputationReportVisible] = [false, false, false];
    this.dataSource.data = [];
    this.qapiReport = [];
    if (value === "improving" || value === "stalled" || value === "deteriorating") {
      this.filter.state = "active";
    }
    else if (value === "remission") {
      this.filter.state = "remission";
    }
    else if (value === "amputation") {
      this.filter.state = "amputated";
    }
    else if (value === "rehospitalization") {
      delete this.filter.state;
      delete this.filter.facilityId;
      this.placeContent = "end center";
    }
    else if (value === "clinical-summary"
      || value === "operations-summary") {
      delete this.filter.state;
      // delete this.filter.facilityId;
    }
  }

  exportQapiReportAsXLSX() {
    const infoToastr = this._toastr.info('Exporting as XLSX...', 'Please wait', { disableTimeOut: true });
    this._wound.exportQapiReportV2AsXLSX(this.filter).subscribe((response: any) => {
      if (response instanceof HttpResponse) {
        var url = window.URL.createObjectURL(response.body);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `Qapi Report`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
        this._toastr.clear(infoToastr.toastId);
      }
    }, error => {
      console.log("err", error);
      this._toastr.clear(infoToastr.toastId);
    });
  }

}
