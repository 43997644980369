import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL } from "../../../environments/api-url";
import * as global from '../../includes/global';


@Injectable({
  providedIn: 'root'
})
export class BillerPracticeService {

  constructor(private _httpclient: HttpClient) { }

  updateRelation(filter: any, updateQuery: any, isCreating, company_id) {
    return this._httpclient.post(global.url + API_URL.BILLER.updateBillerPracticeRelation, { filter: filter, updateQuery: updateQuery, isCreating : isCreating, company_id: company_id });
  }

  getAssociatedCompanies(filter : any) {
    return this._httpclient.post(global.url + API_URL.BILLER.getAssociatedCompanies, { filter: filter });
  }

}
