import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { BillerDashboardService } from '../biller-dashboard.service';
import moment from 'moment';
import swal from 'sweetalert';
import * as global from '../../global';
import { API_URL } from '../../../../environments/api-url'
import { DashboardService } from '../../dashboard/dashboard.service';
import { FileExplorerService } from '../../drive/file-explorer/file-explorer.service';
import { FaceSheetService } from '../../patient-detail/facesheet/face-sheet.service';
import { PatientDocumentService } from '../../patient-documents-list/patient-document-list.service';
import { AuditPopupComponent } from '../audit-popup/audit-popup/audit-popup.component';
import { ChargesPopupComponent } from '../charges-popUp/charges-popup/charges-popup.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ChargeCommentDialogComponent } from '../charge-comment-dialog/charge-comment-dialog.component';
import _ from 'lodash';
import { DialogConfirmationComponent } from '../../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { TranscriptionCreateService } from '../../transcription-create/transcription-create.service';
import { PdfViewerPopComponent } from '../../patient-documents-list/pdf-viewer-pop/pdf-viewer-pop.component';
import { lastValueFrom } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { PCCService } from 'src/app/services/pcc.service';
import { PatientDocumentsUploadDialog } from '../../patient-documents-list/patient-documents-upload-dialog/patient-documents-upload-dialog.component';
import { ViewNoteDialog } from '../../transcription-create/view-note-dialog/view-note-dialog.component';

@Component({
  selector: 'app-show-charges-table',
  templateUrl: './show-charges-table.component.html',
  styleUrls: ['./show-charges-table.component.css']
})
export class ShowChargesTableComponent implements OnInit {
  @Input() chargesArray = [];
  @Input() company_id = [];
  @Input() totalFlatCharges = 0;
  @Input() chargeFilter: any = {};
  @Output() operationInChildComponent = new EventEmitter();
  @Output() searchPatient = new EventEmitter();

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  chargeStatus: string = 'default';
  displayedColumns: string[] = ['index', 'date_of_creation', 'patient_name', 'facility_title', 'dos', 'dob', 'updatedAt', 'provider_name', 'census_name', 'progress_status', 'bill_status', 'face_sheet', 'audit', 'biller_note', 'operation'];
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  auditPopup: any;
  chargesPopup: any;
  currentUser: any;
  selectedCharges: any=[];
  @Input() pagination :any = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    PAGE_LIMIT: 20,
    skip: 0,
    shouldShowCount: false
  };

  constructor(
    private commonService: CommonService,
    private _transcriptionCreateService: TranscriptionCreateService,
    public _authService: AuthService,
    private billerDashboardService: BillerDashboardService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private _router: Router,
    private companyService: CompanyService,
    private pccService: PCCService,
    private patientDocumentService: PatientDocumentService,
    private dashboardService: DashboardService,
    private _faceSheetService: FaceSheetService,
    private fileExplorerService: FileExplorerService,
    private route: ActivatedRoute,
  ) { 
    this.currentUser=this._authService.currentUser;
  }

  ngOnInit(): void {
    console.log("chargesArray: ", this.chargesArray);
    this.initDataSource();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chargesArray) {
      this.initDataSource();
    }
  }

  initDataSource(){
    this.dataSource = new MatTableDataSource(this.chargesArray);
  }

  getDateOfCreation(element) {
    let timeZone = 'America/New_York'
    if (element.facility_id_ref.pcc_timeZone) {
      timeZone = element.facility_id_ref.pcc_timeZone
    }
    else if (element.facility_id_ref.timeZone) {
      timeZone = element.facility_id_ref.timeZone
    }
    if (element.createdAt) {
      return moment(new Date(element.createdAt).toLocaleString('en-US', { timeZone: timeZone })).format('MMM DD, YYYY')
    }
    else {
      return '-'
    }
  }
  
  sortData(sort: Sort) {
    const data = this.chargesArray.slice();
    this.chargesArray = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {

        case 'patient_name':
          return compare(a.patient_id?.last_name, b.patient_id?.last_name, isAsc);
        default: return 0;
      }
    });
    // this.dataSource = this.selectedpatients;
    this.dataSource = new MatTableDataSource(this.chargesArray);
    this.dataSource.sort = this.sort;

  }

  isPCCFacility(source) {
    return source?.toLowerCase() === 'pointclickcare';
  }
  isMatrixFacility(source) {
    return source?.toLowerCase() === 'matrixcare';
  }
  isDNFacility(source) {
    return source?.toLowerCase() != 'pointclickcare' && source?.toLowerCase() != 'matrixcare';
  }
  isPCCFacilityDetails(facility) {
    let source = facility.facility_id_ref.source;
    return source?.toLowerCase() === 'pointclickcare';
  }
  isMatrixFacilityDetails(facility) {
    let source = facility.facility_id_ref.source;
    return source?.toLowerCase() === 'matrixcare';
  }
  isDNFacilityDetails(facility) {
    let source = facility.facility_id_ref.source;
    return source?.toLowerCase() != 'pointclickcare' && source?.toLowerCase() != 'matrixcare';
  }
  getVisitDate(element) {
    if (element.date_obj) {
      // console.log("element.date_obj: ", element.date_obj);
      let { year, month, day, hour, minute } = element.date_obj;
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      let str = `${month}/${day}/${year}`;
      return str
    }
    else {
      let timeZone = 'America/New_York'
      if (element.facility_id_ref.pcc_timeZone) {
        timeZone = element.facility_id_ref.pcc_timeZone
      }
      else if (element.facility_id_ref.timeZone) {
        timeZone = element.facility_id_ref.timeZone
      }
      return moment(new Date(element.visit_date).toLocaleString('en-US', { timeZone: timeZone })).format('MM/DD/YYYY')
    }
    // return moment(new Date(date).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })).format('MMM DD, YYYY h:mm a');
  }
  getdate(date) {
    return moment(new Date(date).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })).format('MMM DD, YYYY');
  }
  getdateOnly(date) {
    return moment(new Date(date)).format('MMM DD, YYYY');
  }
  getDateOfUpdation(element) {
    // 'MMM d, y, h:mm a'
    let timeZone = 'America/New_York'
    if (element.facility_id_ref.pcc_timeZone) {
      timeZone = element.facility_id_ref.pcc_timeZone
    }
    else if (element.facility_id_ref.timeZone) {
      timeZone = element.facility_id_ref.timeZone
    }
    if (element.updatedAt) {
      return moment(new Date(element.updatedAt).toLocaleString('en-US', { timeZone: timeZone })).format('MMM DD, YYYY')
    }
    else return '-'
  }
  auditClicked(element) {
    console.log("Audit clicked: ", element)
    let charge_id = element._id;
    this.auditPopup = this.dialog.open(AuditPopupComponent, { data: charge_id, width: '40%', maxHeight: "40%" })
    this.auditPopup.afterClosed().subscribe(async result => {

    });
  }
  viewBillerNote(note) {
    swal({
      title: "Biller Comment",
      text: note,
      dangerMode: true,
      buttons: [, 'Close'],
    })
      .then(() => {
      });
  }
  openDialog(element) {
    console.log(element);
    this.billerDashboardService.getPatientsChargesAdmin(null, null, this.company_id, {}, element._id).subscribe((response: any) => {
      if (response.status == 200) {
        if (Array.isArray(response.data) && response.data.length === 1) {
          let patient = response.data[0];
          this.chargesPopup = this.dialog.open(ChargesPopupComponent, { data: { patient, arrayOfcharges: this.chargesArray, route: this.route, adminSide: true }, width: '80%' });
          // this.chargesPopup.afterClosed().subscribe(() => {
          //   this.initCharges();
          //   this.isVisibleDetailTable = false;
          //   this.isVisibleTable = true;
          // });
        }
      }
    });
  }
  async onDeleteClick(element, index) {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: "Are you sure you want to delete this Charge?"
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result === "true") {
        const infoToast = this.toastr.info('Deleting Charge...', 'Please wait');
        let response: any = {};
        response = await this._transcriptionCreateService.deleteDraftedCharge(element._id, element.note_id).toPromise();
        if (response.status === 200) {
          this.dataSource.data = [];
          this.chargesArray.splice(index, 1);
          this.dataSource.data = this.chargesArray;
          this.dataSource.sort = this.sort;
          this.operationInChildComponent.emit(true);
          this.toastr.success("Charge deleted", "success");
        } else {
          this.toastr.error(response.message, 'Failed');
        }
      }
    });
  }

  checkVoidCensus(patientRoundingSheet) {
    if (patientRoundingSheet) {
      if (patientRoundingSheet?.is_void) {
        if (patientRoundingSheet.is_void === true) return false;
        else return true;
      } else {
        return true
      }
    } else {
      return true;
    }
  }

  async editCharge(charge) {
    let response: any = await this.billerDashboardService.getPatientsChargesAdmin(null, null, this.company_id, {}, charge._id).toPromise()
    if (response.status == 200) {
      if (Array.isArray(response.data) && response.data.length === 1) {
        let patientCharge = response.data[0];
        console.log("Edit Charge Clicked: ", patientCharge)
        let date_obj;
        if (patientCharge.date_obj) {
          // console.log("patientCharge.date_obj: ", patientCharge.date_obj);
          let { year, month, day, hour, minute } = patientCharge.date_obj;
          if (month < 10) {
            month = '0' + month;
          }
          if (day < 10) {
            day = '0' + day;
          }
          let str = `${month}-${day}-${year}`;
          if (hour && minute) {
            if (minute < 10) {
              minute = `0${minute}`
            }
            if (hour < 10) {
              hour = `0${hour}`
            }
            str += ` ${hour}:${minute}`
          }
          date_obj = str;
        }
        this.commonService.setDraftedCharge(patientCharge);
        this._router.navigate(['/biller-charges/patient-charge'], {
          queryParams: {
            patient_id: patientCharge.patient_id._id,
            rs_id: patientCharge.rounding_sheet_id._id,
            visit_date: patientCharge.visit_date.toString(),
            patient_name: patientCharge.patient_id.first_name + ' ' + patientCharge.patient_id.last_name,
            dob: patientCharge.patient_id.date_of_birth,
            // facility_id: patient.facility_id
            facility_id: patientCharge.facility_id_ref._id,
            charge_id: patientCharge._id,
            date_obj,
            backCtrl: true,
          }
        });
      }
    }
  }
  openChargeCommentDialog(charge, index) {
    const config = {
      data: _.cloneDeep(charge),
      width: '50%'
    }
    this.dialog.open(ChargeCommentDialogComponent, config).afterClosed().subscribe((updatedCharge) => {
      if (updatedCharge) {
        charge.note = updatedCharge.note;
        this.chargesArray[index].note = updatedCharge.note;
      }
    });
  }

  selectedCharge(element) {
    if (this.selectedCharges.findIndex(charge => charge == element._id) > -1) {
      return true
    }
    else {
      return false
    }
  }
  selectCharge(element, event) {
    if (event.checked) {
      this.selectedCharges.push(element._id)
    }
    if (!event.checked) {
      let index = this.selectedCharges.indexOf(element._id);
      if (index > -1) {
        this.selectedCharges.splice(index, 1)
      }
    }
  }
  selectChargeAll($event) {
    if ($event.checked) {
      let allCharges = this.dataSource.data;
      this.selectedCharges = allCharges.map(charg => charg._id);
    }
    if (!$event.checked) {
      this.selectedCharges = [];
    }
  }

  async billedAllCharges(billStatus, charge?) {
    if (charge) {
      this.selectedCharges = [];
      this.selectedCharges.push(charge._id)
    }
    let response: any = await this.billerDashboardService.SubmitAllandApprove(this.selectedCharges, 'billed', billStatus).toPromise();
    if (response.status == 200) {
      this.selectedCharges = [];
      this.toastr.success("Selected Charge is billed", "Success");
    } else {
      this.toastr.error("Something went Wrong", "Error");
    }
  }
  async archivedAllCharges() {
    let response: any = await this.billerDashboardService.SubmitAllandApprove(this.selectedCharges, 'archive').toPromise();
    if (response.status == 200) {
      this.selectedCharges = [];
      this.toastr.success("All Selected Charges are archived", "Success");
      this.operationInChildComponent.emit();
      // this.isVisibleDetailTable = false;
    } else {
      this.toastr.error("Something went Wrong", "Error");
    }
  }

  viewPDF(element, index?) {
    if (element.faceSheet?.aws_path && element.faceSheet.aws_path.includes("http")) {
      this.dialog.open(PdfViewerPopComponent, {
        width: '50vw',
        height: '95vh',
        data: { file_path: element.faceSheet.aws_path, file: element }
      })
    } else {
      let data = {
        patient_id: element.patient_id._id,
        aws_path: element.faceSheet?.aws_path,
        title: element.faceSheet?.title
      }
      this.patientDocumentService.generateViewPdfUrl(data).subscribe((response: any) => {
        if (response.status === 200) {
          if (!element.faceSheet) {
            let faceSheet = {
              aws_path: null,
              title: null
            }
            element = {
              ...element, faceSheet
            }
          }
          element.faceSheet.aws_path = response.document.aws_path
          element.faceSheet.title = response.document.title
          this.dialog.open(PdfViewerPopComponent, {
            width: '50vw',
            height: '95vh',
            data: { file_path: element.faceSheet.aws_path, file: element }
          });
        }
        else {
          this.toastr.error('Something went wrong please try again.')
        }
      }, () => this.toastr.error('Something went wrong please try again.')
      )
    }
  }
  downloadFaceSheetActive(element, index) {
    const charge = element;
    const patient = charge.patient_id;
    if (!patient) return false;

    if (patient.source === 'PointClickCare') return true;
    if (patient.source === 'MatrixCare' && patient.matrix_payload?.coverages?.length > 0) return true;
    if (charge.faceSheet) return true;

    return false;
  }

  async handleUploadFacesheetClick(element, index) {
    const charge = element;
    const patient = charge.patient_id;
    this.showPatientDocumentsUploadDialog(index, patient._id)
  }

  showPatientDocumentsUploadDialog(index, patientId) {
    console.log("this.chargesArray", this.chargesArray);
    const dialogRef = this.dialog.open(
      PatientDocumentsUploadDialog,
      { width: '80%' }
    );
    dialogRef.beforeClosed().subscribe(async (fileInput: any) => {
      if (fileInput) {
        const [file] = fileInput.files;
        const infoToast = this.toastr.info('Uploading Face Sheet...', 'Please wait')
        const company_id = this._authService.currentUser.company_id;
        this._faceSheetService.uploadFaceSheet(file, patientId, company_id).subscribe((response: any) => {
          if (response.status === 200) {
            const { _id, title, aws_path } = response.data;
            const faceSheet = {
              _id,
              title,
              aws_path
            };
            this.chargesArray[index].faceSheet = faceSheet;
            this.dataSource.data[index].faceSheet = faceSheet;
            this.toastr.success("Face Sheet Uploaded", 'Success');
          }
          else {
            console.error(response.data.status, response.data.message);
            this.toastr.error(response.data.message, 'Failed');
          }
        });
      }
    });
  }

  async handleDownloadFacesheetClick(element, index) {
    const charge = element;
    const patient = charge.patient_id;

    if (patient.source === 'PointClickCare') {
      let hasPCCFacesheetData = false;
      const details = {
        ...this._authService.authObject,
        patientId: patient._id,
      }
      const { status, data } = await lastValueFrom(this.pccService.syncCoveragesData({ details })) as any;
      if (status === 200) {
        const _patient = data;
        if ((_patient?.pcc_coverage_data?.Primary || _patient?.pcc_coverage_data?.Secondary || _patient?.pcc_coverage_data?.Tertiary)) {
          hasPCCFacesheetData = true
        }
      }
      if (hasPCCFacesheetData) {
        this.generateFacesheetAndDownloadAsPDF(patient._id);
        return;
      }
    }
    if (patient.source === 'MatrixCare' && patient.matrix_payload?.coverages?.length > 0) {
      this.generateFacesheetForMatrixAndDownloadAsPDF(patient._id);
      return;
    }
    const infoToast = this.toastr.info('Preparing file to download...', 'Please wait', { disableTimeOut: true });
    try {
      const downloadFileResponse: any = await this.fileExplorerService.downLoadAnyFileWithURL(element.faceSheet.aws_path, element.faceSheet.title, element.patient_id?._id).toPromise();
      var url = window.URL.createObjectURL(downloadFileResponse);
      var a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = <string>element.faceSheet.title;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    } catch (error) {
      this.toastr.error('Unable to download file.', 'Failed');
    } finally {
      this.toastr.clear(infoToast.toastId);
    }
  }

  generateFacesheetForMatrixAndDownloadAsPDF(patient_id) {
    const infoToastr: any = this.toastr.info('Generating PDF', 'Please wait...');
    try {
      const params = {
        source: 'MatrixCare',
        patient_id
      }
      return this.dashboardService.downloadFile(global.url + API_URL.PATIENT.generateFacesheetForMatrixAndDownloadAsPDF, params).subscribe((res: any) => {
        if (res instanceof HttpResponse) {

          var url = window.URL.createObjectURL(res.body);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = 'facesheet.pdf';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
          this.toastr.clear(infoToastr.toastId);
        } else {
          if (res.type === 0) {
          } else if (res.type === 3) {
            const { loaded, total } = res;
            const progress = Math.round(loaded / total * 100);
            if (progress > 0) {
            }
          }
        }
        if (res.status == 200) {
          return true;
        }
      })
    } catch (error) {
      const { message = 'Something went wrong, please try again' } = error;
      this.toastr.error(message);
    } finally {
      this.toastr.clear(infoToastr.toastId);
    }
  }

  generateFacesheetAndDownloadAsPDF(patient_id) {
    console.log('patient id', patient_id);
    const infoToastr: any = this.toastr.info('Generating PDF', 'Please wait...');
    try {
      const params = {
        patient_id
      }
      return this.dashboardService.downloadFile(global.url + API_URL.PATIENT.generateFacesheetAndDownloadAsPDF, params).subscribe((res: any) => {
        if (res instanceof HttpResponse) {

          var url = window.URL.createObjectURL(res.body);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = 'facesheet.pdf';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
          this.toastr.clear(infoToastr.toastId);
        } else {
          if (res.type === 0) {
          } else if (res.type === 3) {
            const { loaded, total } = res;
            const progress = Math.round(loaded / total * 100);
            if (progress > 0) {
            }
          }
        }
        if (res.status == 200) {
          return true;
        }
      })
    } catch (error) {
      const { message = 'Something went wrong, please try again' } = error;
      this.toastr.error(message);
    } finally {
      this.toastr.clear(infoToastr.toastId);
    }
  }

  viewNoteDialog(charge) {
    let time_zone = 'America/New_York'
    if (charge && charge.facility_id_ref) {
      time_zone = charge.facility_id_ref.pcc_timeZone ? charge.facility_id_ref.pcc_timeZone : charge.facility_id_ref.timeZone
    }
    const dialogRef = this.dialog.open(ViewNoteDialog, {
      width: '80%',
      //height:'80%',
      data: {
        note_id: charge.note_id,
        timezone: time_zone
        // void_census: this.checkVoidCensus(charge.rounding_sheet_id),
      }
    });
  }

  applyFilter(filterValue: string) {
    // console.log('filterValue', filterValue);
    if (filterValue || filterValue === "" ) {
      // this.dataSource.filter = filterValue.trim().toLowerCase();
      // this.dataSource.filterPredicate = function (data, filter: string): boolean {
      //   return (
      //      data.patient_id?.first_name.toLowerCase().includes(filter) 
      //   || data.patient_id?.last_name.toLowerCase().includes(filter) 
      //   || data.status.toLowerCase().includes(filter)
      //   );
      // };
      this.pagination.shouldShowCount = false;
      this.chargeFilter.pageNo = this.pagination.pageNo;
      this.chargeFilter.patientName = filterValue;
      this.searchPatient.emit();
    }
    // else {
    //   this.initDataSource();
    // }
  }

  handlePagination() {
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    if (this.pagination.skip + this.chargesArray.length < this.pagination.totalRecords) {
      this.pagination.hasNextPage = true;
    }
    else {
      this.pagination.hasNextPage = false;
    }
    if (this.pagination.skip > 0) {
      this.pagination.hasPrevPage = true;
    }
    else {
      this.pagination.hasPrevPage = false;
    }
  }
  goToPreviousPage() {
    this.pagination.shouldShowCount = false;
    this.pagination.pageNo--;
    this.searchPatient.emit();
  }
  goToNextPage() {
    this.pagination.shouldShowCount = false;
    this.pagination.pageNo++;
    this.searchPatient.emit();
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}