import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as global from '../global';
import {API_URL} from "../../../environments/api-url";

@Injectable({
    providedIn: 'root',
  })
  export class PatientDetailService {
      currentUser:any;
      constructor(private httpclient: HttpClient) {
      }

      getTranscriptionsByPatientId(payload, isInitialNoteDosPhrase?: boolean) {  
        return this.httpclient
            .post(global.url + API_URL.NOTE.getTranscriptionsByPatientId, {patient: payload, isInitialNoteDosPhrase: isInitialNoteDosPhrase}).pipe();
      }
  }
