import { Component, OnInit } from '@angular/core';
import { ICompany } from 'src/app/classes/company';
import { LegalAuthenticator } from 'src/app/classes/company';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as usa_states from '../../../../shared/popup-dialogs/facility-add-dialog/usa_states';
import { AuthService } from "../../../../services/auth.service";
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-company-profile-form',
  templateUrl: './company-profile-form.component.html',
  styleUrls: ['./company-profile-form.component.css']
})
export class CompanyProfileFormComponent implements OnInit {
  constructor(private companyService: CompanyService,
    private authService: AuthService,
    private toastr: ToastrService, private loader: NgxUiLoaderService,
    private route: ActivatedRoute,
    private location: Location) {
    this.company_id = this.route.parent.snapshot.params.id;
  }
  company_id;
  setLegalAuthenticator: boolean = false;
  usa_states = usa_states.default;

  loaderId = "company-profile-form-loader";
  model: Partial<ICompany> = {
    name: '',
    description: '',
    address: '',
    address_2: '',
    state: '',
    city: '',
    zip: '',
    phone: '',
    email: ''
  };
  legalModel: LegalAuthenticator = {
    company_id_ref: '',
    streetAddressLine: '',
    city: '',
    state: '',
    zipCode: '',
    telecom: '',
    representedOrganization: '',
    firstName: '',
    lastName: '',
  }


  ngOnInit(): void {
    this.loader.startLoader(this.loaderId);
    this.companyService
      .getAssociatedCompanyId(null, this.company_id)
      .subscribe((response: any) => {
        this.model = { ...response.data };
      });
    this.companyService
      .getLegalAuthenticator(this.company_id)
      .subscribe((response: any) => {
        this.legalModel = { ...response.data };
        if (response.data) {
          this.legalModel.firstName = response.data.assignedPerson.firstName;
          this.legalModel.lastName = response.data.assignedPerson.lastName;
        }
      });
    this.loader.stopLoader(this.loaderId);
    // this.companyService.castCompany.subscribe(company => {
    //     this.model = {...company};
    // })
  }
  onCheckboxChange(e) {
    if (e.checked) {
      this.setLegalAuthenticator = true;
    } else {
      this.setLegalAuthenticator = false;
    }
  }
  handleSubmit(e) {
    if (e.invalid) {
      return;
    }
    this.loader.startLoader(this.loaderId);
    let data: any = {};
    data.companyData = this.model;
    if (this.legalModel.firstName != '' || this.legalModel.lastName != '' || this.legalModel.city != '' || this.legalModel.state != ''
      || this.legalModel.zipCode != '' || this.legalModel.telecom != '' || this.legalModel.streetAddressLine != '' || this.legalModel.representedOrganization != '') {
      this.legalModel.company_id_ref = this.model._id;
      data.legalData = this.legalModel;
    }
    this.companyService.updateCompany(data).subscribe((response) => {
      this.toastr.success(response.message)
      this.loader.stopLoader(this.loaderId);
      this.location.back();
      console.log('goBack()...');
    })
  }
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    else {
      return true;
    }
  }
  keyPressAlphanumeric1(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9\s/-_-.-]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  allowOnlyText(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 90 && charCode < 97) {
      return false;
    }

    if (charCode < 65 || charCode > 122) {
      return false;
    }
    return true;
  }
  keyPressAlphanumeric(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9\s]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}
