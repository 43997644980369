import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { AudioService } from 'src/app/services/Modules/audio.service';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../../../includes/global'
import moment from 'moment'
import { DashboardService } from '../../dashboard/dashboard.service';
import { NoteAuditoryHistoryService } from '../../note-auditory-history/note-auditory-history.service';

@Component({
  selector: 'app-count-filter',
  templateUrl: './count-filter.component.html',
  styleUrls: ['./count-filter.component.css']
})
export class CountFilterComponent implements OnInit {
  dateS: Boolean = false;
  StatusS: Boolean = false;
  StatusD: Boolean = false;
  dictData: any = [];
  transData: any = [];
  checked: Boolean;
  checkedNotes: Boolean = false;
  checkedDict: Boolean = false;
  displayedColumns: any = [];
  displayedColumns2: any = [];
  dataSource: any;
  loading: Boolean = true;
  filteredData: any = [];
  sortedData: any = [];
  _currentUser: any;
  @Input() dateFilter: any = {};
  @Input() noteDateFilter: any = {};
  @Input() dictations: any = {};
  @Output() onApplyFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onApplyFilterTrans: EventEmitter<any> = new EventEmitter<any>();
  @Output() onApplyDateChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onApplyDateChangeTrans: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatSort) sort: MatSort;

  rowData: any = {};
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  dictDateFilter = {
    startDate: moment().subtract(1, 'days'),
    endDate: moment()
  };
  constructor(
    private _audioService: AudioService,
    private _dashboardService: DashboardService,
    private _authService: AuthService,
    private auditoryService: NoteAuditoryHistoryService
  ) {
    this._currentUser = this._authService.currentUser;
    this.displayedColumns = ['date', 'provider', 'facility', 'status', 'count', 'view'];
    this.displayedColumns2 = ['date', 'provider', 'facility', 'note_status', 'status', 'count', 'view'];
  }
  // ngAfterViewInit(): void {
  //   // throw new Error('Method not implemented.');
  //   this.dataSource = new MatTableDataSource(this.filteredData);
  //   this.dataSource.sort = this.sort;
  // }

  ngOnInit(): void {
    let isCheck = JSON.parse(localStorage.getItem('is_checked'));
    if (isCheck == 'no') {
      this.checked = false;
    }
    else {
      this.checked = true;
    }
    this.initCountFilter();
  }
  noteStatusSort() {
    if (!this.StatusS) {
      
      this.filteredData.sort(function (a, b) {
        var nameA = a._id.note_status.toUpperCase(); // ignore upper and lowercase
        var nameB = b._id.note_status.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.dataSource = new MatTableDataSource(this.filteredData);
      this.StatusS = true;
    } else {
      
      this.filteredData.sort(function (a, b) {
        var nameA = a._id.note_status.toUpperCase(); // ignore upper and lowercase
        var nameB = b._id.note_status.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.dataSource = new MatTableDataSource(this.filteredData);
      this.StatusS = false;
    }
  }
  dateSort() {
    if (!this.dateS) {
      
      this.filteredData.sort(function (a, b) {
        var nameA = a._id.dayOfYear;
        var nameB = b._id.dayOfYear;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.dataSource = new MatTableDataSource(this.filteredData);
      this.dateS = true;
    } else {
      
      this.filteredData.sort(function (a, b) {
        var nameA = a._id.dayOfYear;
        var nameB = b._id.dayOfYear;
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.dataSource = new MatTableDataSource(this.filteredData);
      this.dateS = false;
    }
  }

  dictStatus() {
    if (!this.StatusD) {
      
      this.filteredData.sort(function (a, b) {
        var nameA = a._id.progress_status;
        var nameB = b._id.progress_status;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.dataSource = new MatTableDataSource(this.filteredData);
      this.StatusD = true;
    } else {
      
      this.filteredData.sort(function (a, b) {
        var nameA = a._id.progress_status;
        var nameB = b._id.progress_status;
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.dataSource = new MatTableDataSource(this.filteredData);
      this.StatusD = false;
    }
  }

  changeStatus(event) {
    if (event) {
      localStorage.setItem('is_checked', JSON.stringify('yes'));
    }
    else {
      localStorage.setItem('is_checked', JSON.stringify('no'));
    }
    this.initCountFilter();
  }

  changeNoteStatus(event) {
    this.checkedNotes = event;
    this.initCountFilter();
  }
  changeDictStatus(event) {
    this.checkedDict = event;
    this.initCountFilter();
  }

  async initCountFilter() {
    this.loading = true;
    let from_date;
    let to_date;
    if (this.dateFilter && this.dateFilter.startDate) {
      from_date = this.dateFilter.endDate;
      to_date = this.dateFilter.startDate;
    }
    else {
      from_date = this.noteDateFilter.endDate;
      to_date = this.noteDateFilter.startDate;
    }
    if (this.dictations) {
      const countFilterData = await this._audioService.getCountGroup(this._currentUser._id, from_date, to_date, this.checkedDict).subscribe((response: any) => {
        if (response.status == 200) {
          console.log("response.data: ", response.data);
          
          this.filteredData = response.data;
          this.dictData = response.data;
          this.sortedData = response.data;
          this.dataSource = new MatTableDataSource(this.filteredData);
          this.dataSource.sort = this.sort;
          this.loading = false;
        }
      })
    }
    else {
      const countFilterData = await this._dashboardService.getCountGroup(this._currentUser._id, this._currentUser.user_type, from_date, to_date, this.checked, this.checkedNotes).subscribe((response: any) => {
        if (response.status == 200) {
          // console.log("response for Transcriptions: ", response);
          this.filteredData = response.data;
          this.transData = response.data;
          this.sortedData = response.data;
          this.dataSource = new MatTableDataSource(this.filteredData);
          this.dataSource.sort = this.sort;
          this.loading = false;
        }
      })
    }
  }
  applyDateFilter(dates) {
    if (dates.startDate && dates.endDate) {
      if (this.dictations) {
        localStorage.setItem('dateFilter', JSON.stringify(this.dateFilter));
        this.initCountFilter();
        this.onApplyDateChange.emit(this.dateFilter)
      }
      else {
        localStorage.setItem('noteDateFilter', JSON.stringify(this.noteDateFilter));
        this.initCountFilter();
        this.onApplyDateChangeTrans.emit(this.noteDateFilter)
      }
    }
  }

  getProvider(element) {
    if (element && element.provider[0] && element.provider[0].first_name) {
      return element.provider[0].first_name + ' ' + element.provider[0].last_name;
    }
    else
      return ''
  }
  getFacility(element) {
    if (element && element.facility[0] && element.facility[0].title) {
      return element.facility[0].title;
    }
    else
      return ''
  }
  getPatient(element) {
    if (element && element.patient[0]) {
      return element.patient[0].first_name + ' ' + element.patient[0].last_name;
    }
    else return '';
  }
  getCreatorType(element) {
    if (element && element.creator[0] && element.creator[0].user_type) {
      if (element.creator[0].user_type == '1') {
        return 'Provider'
      }
      if (element.creator[0].user_type == '2') {
        return 'Transcriber'
      }
      if (element.creator[0].user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
        return 'medical_assistant'
      }
      else return ''
    }
    else return ''
  }
  getStatus(element, type = null) {
    if (element && element._id && element._id.progress_status && !type) {
      if (element._id.progress_status == '19') {
        return "In Progress";
      }
      else if (element._id.progress_status == '20') {
        return "Completed";
      }
      else
        return 'none';
    }
    else if (type == 'trans' && element && element._id) {
      if (element._id.is_signed == 'true') {
        return "Signed"
      }
      else return "Unsigned"
    }
    else
      return 'none';
  }

  getNoteStatus(element) {
    if (element && element._id && element._id.note_status) {
      return this.auditoryService.formatOperation(element._id.note_status).toUpperCase();
    }
    else return ''
  }
  propercase(phrase) {
    if (phrase) {
      return phrase
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
    else {
      return phrase;
    }
  }
  getCount(element) {
    if (element && element.total) {
      return element.total
    }
    else
      return '';
  }
  getDate(element) { 
    if(!this.dictations){

      if (element && element.noteData[0]?.filterCreationDate ) { 
        // return moment(new Date(element.noteData[0].filterCreationDate).toLocaleString('en-US', { timeZone:  element.noteData[0].timezone })).format('MMM DD, YYYY');
        return moment(new Date(element.noteData[0].filterCreationDate)).format('MMM DD, YYYY');
      }
      else return '';
    }
    else{
      if (element && element.audioData[0]?.filterUploadingDate ) {
        // return moment(new Date(element.audioData[0].filterUploadingDate).toLocaleString('en-US', { timeZone:  element.audioData[0].timezone })).format('MMM DD, YYYY');
        return moment(new Date(element.audioData[0].filterUploadingDate)).format('MMM DD, YYYY');
      }
      else return ''
    }
  }
  rowClicked(element) {
    
    if (element) { 
      this.rowData.timezone = element.audioData[0].timezone;
      if (element && element.audioData[0].filterUploadingDate) {
        // let dd = moment(element.audioData[0].filterUploadingDate).dayOfYear(Number(element._id.dayOfYear));
        // this.rowData.date = moment(dd).startOf('day').toLocaleString();
        this.rowData.date = element.audioData[0].filterUploadingDate;
      }
      else {
        this.rowData.date = null;
      }
      if (element && element._id && element._id.progress_status) {
        if (element._id.progress_status == 19) {
          this.rowData.status = '19';
        }
        else if (element._id.progress_status == 20) {
          this.rowData.status = '20'
        }
        else {
          this.rowData.status = null;
        }
      }
      else {
        this.rowData.status = null
      }
    }
    if (element && element.facility[0] && element.facility[0]._id) {
      this.rowData.facility = element.facility[0]._id.trim();
    }
    else {
      this.rowData.facility = null
    }
    if (element && element.provider[0] && element.provider[0]._id) {
      this.rowData.provider = element.provider[0]._id.trim();
    }
    else {
      this.rowData.provider = null
    }
    this.onApplyFilter.emit(this.rowData);
  }

  rowClickedTrans(element) { 
    this.rowData.timezone = element.noteData[0].timezone;
    if (element.creator[0]) {
      this.rowData.provider = element.creator[0]._id;
    }
    else {
      this.rowData.creator = null;
    }
    if (element.noteData[0].filterCreationDate) {
      // let ddate = moment(element.noteData[0].filterCreationDate).dayOfYear(Number(element._id.dayOfYear));
      // this.rowData.date = moment(ddate).toDate();
      this.rowData.date = element.noteData[0].filterCreationDate;
    }
    else {
      this.rowData.date = null;
    }
    if (element.facility[0] && element.facility[0]._id) {
      this.rowData.facility = element.facility[0]._id
    }
    else {
      this.rowData = null
    }
    // if(element.patient[0]){
    //   this.rowData.patient = element.patient[0];
    // }
    // else{
    //   this.rowData.patient = null
    // }
    // if (element._id) {
    //   if (element._id.is_signed == 'true') {
    //     this.rowData.status = "Signed"
    //   }
    //   else this.rowData.status = "Un_Signed"
    // }
    // else return '';
    if (element.total) {
      this.rowData.count = element.total
    }
    this.rowData.facility_id_ref = element._id.facility_id_ref;
    // this.rowData.patient_id_ref = element._id.patient_id_ref;
    // this.rowData.uploaded_by_id_ref = element._id.uploaded_by_id_ref;
    this.rowData.doctor_id_ref = element._id.doctor_id_ref;
    this.rowData.note_status = element._id.note_status;
    this.onApplyFilterTrans.emit(this.rowData);
  }

  sortData(sort: Sort) { 
    const data = this.filteredData.slice();
    this.filteredData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date': 
          if (a && a._id && a._id.dayOfYear && b && b._id && b._id.dayOfYear) {
            return compare(a._id.dayOfYear, b._id.dayOfYear, isAsc);
          }
          else {
            return;
          }
        case 'provider': 
          if(this.dictations){
            if (a && a.provider[0] && a.provider[0].first_name && b && b.provider[0] && b.provider[0].first_name) {
              return compare(a.provider[0].first_name.trim().toLowerCase(), b.provider[0].first_name.trim().toLowerCase(), isAsc);
            }
            else {
              return;
            }
          }
          else{
            if (a && a.creator[0] && a.creator[0].first_name && b && b.creator[0] && b.creator[0].first_name) {
              return compare(a.creator[0].first_name.trim().toLowerCase(), b.creator[0].first_name.trim().toLowerCase(), isAsc);
            }
            else {
              return;
            }
          }
        case 'facility': 
          if (a && a.facility[0] && a.facility[0].title && b && b.facility[0] && b.facility[0].title) {
            return compare(a.facility[0].title.trim().toLowerCase(), b.facility[0].title.trim().toLowerCase(), isAsc);
          }
          else {
            return;
          }
        case 'status': 
          if(this.dictations){
            if (a && a._id && a._id.progress_status && a && a._id && a._id.progress_status) {
              return compare(a._id.progress_status.trim().toLowerCase(), b._id.progress_status.trim().toLowerCase(), isAsc);
            }
            else {
              return;
            }
          }
          else{
            if (a && a._id && a._id.is_signed && a && a._id && a._id.is_signed) {
              return compare(a._id.is_signed.trim().toLowerCase(), b._id.is_signed.trim().toLowerCase(), isAsc);
            }
            else {
              return;
            }
          }
        case 'count': 
          if (a && a && a.total && b && b && b.total) {
            return compare(a.total, b.total, isAsc);
          }
          else {
            return;
          }
          case 'note_status': 
            if (a && a._id && a._id.note_status && b && b._id && b._id.note_status) {
              return compare(a._id.note_status.trim().toLowerCase(), b._id.note_status.trim().toLowerCase(), isAsc);
            }
            else {
              return;
            }

        default: return 0;
      }
    });
    // this.dataSource = this.selectedpatients;
    this.dataSource = new MatTableDataSource(this.filteredData);
    this.dataSource.sort = this.sort;

  }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
