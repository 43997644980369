<form #appointmentForm="ngForm">
  <div class="mat-dialog-header flex justify-between items-center">
    <h2 mat-dialog-title>{{appointmentBtn}} Block</h2>
    <span class="material-symbols-outlined mat-dialog-close-icon" mat-dialog-close>cancel</span>
  </div>

  <div mat-dialog-content>
    <div class="form-row flex gap-4">
      <mat-form-field appearance="fill" class="w-1/2">
        <mat-label>Select Provider</mat-label>
        <mat-select name="provider" #provider="ngModel" [(ngModel)]="blockData.providerType"
        (ngModelChange)="onSelectProvider()" [disabled]="isDisabledProvider">
        <input matInput placeholder="Search" [(ngModel)]="searchTextProvider"
          [ngModelOptions]="{standalone: true}" style="color:#000;height: 40px;padding-left: 5px;" />
        <mat-option *ngFor="let provider of uniqueProviders | searchProvider : searchTextProvider"
          value="{{provider._id}}">
          {{provider.name}}
        </mat-option>
      </mat-select>
      </mat-form-field>

      <div class="w-1/2" style="padding-top: 10px; position: relative;">
        <mat-form-field class="w-full">
          <mat-label>Select Location</mat-label>
          <mat-select [disabled]="isDisabledFacility"  name="facilityType" [(ngModel)]="blockData.facilityType" required>
            <mat-option *ngFor="let facility of facilities" [value]="facility._id">
              <app-facility-title [facility]="facility"></app-facility-title>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <ngx-ui-loader [loaderId]="locationSearchLoaderId"></ngx-ui-loader>
      </div>
    </div>
    
    <div class="form-row flex gap-4">
      <mat-form-field class="flex">
        <mat-label>Reasons</mat-label>
        <mat-select  (selectionChange)="onSelectAppointmentType()" name="reason" [disabled]="isDisabledReason" [(ngModel)]="blockData.reason" required>
          <mat-option disabled>--select--</mat-option>
          <mat-option *ngFor="let rsn of reasons" [value]="rsn._id">
            {{rsn.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <app-time-picker-widget class="w-1/2" [class.disabled] = "isDisabledTime === true" [isDisabledWatch]="isDisabledTime" [label]="'Start time'" [format]="'24'" [readonly]="true" [value]="blockData.start_time" (onchangeTime)="onchangeTime($event, 'start')"></app-time-picker-widget>
      
    </div>


    <div class="form-row flex gap-4">
      <mat-form-field class="flex-1">
        <mat-label>Duration</mat-label>
        <mat-select (selectionChange)="resetCustomDuration()" [(ngModel)]="blockData.duration" name="duration" required>
          <mat-option *ngFor="let option of durationOptions" [value]="option.value">
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="flex" *ngIf="blockData.duration === 'custom'">
        <mat-form-field class="flex">
          <mat-label>Custom Duration</mat-label>
          <input matInput oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" min="0" type="number" name="customDuration" [(ngModel)]="blockData.customDuration" required />
        </mat-form-field>
      </div>
    </div>

    <div class="form-row">
      <div class="form-field">
        <mat-form-field class="flex">
          <mat-label>Repeating</mat-label>
          <mat-select name="repeating" [(ngModel)]="blockData.repeating">
            <mat-option disabled>--select--</mat-option>
            <mat-option (click)="resetArrays()" *ngFor="let rpt of repeatings" [value]="rpt.value">
              {{rpt.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <ng-container *ngIf="blockData.repeating">
      <div class="form-row flex gap-4">
        <mat-form-field appearance="fill" style="align-self: flex-end;">
          <mat-label>Start Date</mat-label>
          <input matInput [matDatepicker]="dospicker" (focus)="dospicker.open()"
              placeholder="Start Date" [value]="blockData.start_date"
              (dateChange)="setDOS($event, 'start_date')" [disabled]="isDisabledStartDate">
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="dospicker">
              <span matDatepickerToggleIcon [class.disabled] = "isDisabledStartDate === true" class="material-symbols-outlined icon-navy">
                  calendar_month
              </span>
          </mat-datepicker-toggle>
          <mat-datepicker #dospicker></mat-datepicker>
       </mat-form-field>

       <mat-form-field appearance="fill" style="align-self: flex-end;">
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="end_datepicker" (focus)="end_datepicker.open()"
            placeholder="End Date" [value]="blockData.end_date"
            (dateChange)="setDOS($event, 'end_date')"  [disabled]="isDisabledEndDate">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="end_datepicker">
            <span matDatepickerToggleIcon [class.disabled] = "isDisabledEndDate === true" class="material-symbols-outlined icon-navy">
                calendar_month
            </span>
        </mat-datepicker-toggle>
         <mat-datepicker #end_datepicker></mat-datepicker>
      </mat-form-field>
      </div>
    </ng-container>

    <div class="circular-list" *ngIf="blockData.repeating">
      <div *ngIf="blockData.repeating === 'Monthly by Date'" class="circle days">
        <div
          *ngFor="let day of daysOfMonth"
          class="item"
          [class.selected]="isSelected(day,'days')"
          (click)="toggleSelection(day,'days')"
        >
          {{ day }}
        </div>
      </div>
      <div *ngIf="blockData.repeating === 'Monthly by Day'" class="circle weeks">
        <div
          *ngFor="let week of weeksOfMonth"
          class="item"
          [class.selected]="isSelected(week,'weeks')"
          (click)="toggleSelection(week,'weeks')"
        >
          {{ week }}
        </div>
      </div>

      <div *ngIf="blockData.repeating === 'Monthly by Day' || blockData.repeating === 'Weekly' || blockData.repeating === 'Biweekly'" class="circle weekdays">
        <div
          *ngFor="let weekday of weekdays"
          class="item"
          [class.selected]="isSelected(weekday,'weekdays')"
          (click)="toggleSelection(weekday,'weekdays')"
        >
          {{ weekday }}
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions>
    <div class="flex flex-row justify-end items-center" style="margin-left: auto;">

      <button mat-raised-button class="dn-button-primary" (click)="submitForm()" color="primary"
        type="submit">{{appointmentBtn}}</button>
      <button mat-raised-button class="dn-button-secondary" (click)="closeDialog()">Cancel</button>
    </div>
  </div>
</form>