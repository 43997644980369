import { Component, OnInit } from '@angular/core';
import { DermService } from '../derm.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { DialogConfirmationComponent } from '../../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { MatDialog } from '@angular/material/dialog';


// interface Row {
//   _id: String,
//   value: any,
//   row_confirmed: Boolean,
//   data_type: String
// };
// interface DataSet {
//   _id: String,
//   question: String,
//   multiselect_enabled: Boolean,
//   question_confirmed: Boolean,
//   row: Array<Row>
// };

@Component({
  selector: 'app-derm-dataset',
  templateUrl: './derm-dataset.component.html',
  styleUrls: ['./derm-dataset.component.css']
})
export class DermDatasetComponent implements OnInit {
  dataSetQuestionChange: any = false;
  dataSetAnswerChange: any = false;
  allDataSet: any[];
  question: String = "";
  answer: String = "";
  company_id: any;
  selectedDataSet = null;
  editDataset = null;
  editRow = null;
  constructor(
    private dermService: DermService,
    private toastService: ToastrService,
    private _route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    this.company_id = this._route.parent.snapshot.params.id;
  }

  async ngOnInit(): Promise<void> {
    await this.getAllDataset();
  }
  async getAllDataset(){
    const response:any = await this.dermService.getAllDataSet(this.company_id).toPromise();
    if(response.status === 200){
      this.allDataSet = response.data;
      this.allDataSet[0]['selected'] = true;
      this.selectedDataSet = this.allDataSet[0];
    }
  }
  async addNewQuestion(){
    if(this.editDataset){
      const data = {
        question: this.question
      }
      const response:any = await this.dermService.updateDataSet(this.editDataset._id,data).toPromise();
      if(response.status === 200){
        this.toastService.success("Question Updated Successfully","Success");
        const index = this.allDataSet.findIndex((dataset)=> dataset._id.toString() === this.editDataset._id.toString());
        if(index > -1){
          this.allDataSet[index].question = this.question; 
        }
        this.cancelAnswer();
        this.cancelQuestion();
      }
    }else{
      const response:any = await this.dermService.addNewDataSet(this.question).toPromise();
      if(response.status === 200){
        this.allDataSet.push(response.data);
        this.toastService.success("New Question Added Successfully","Success");
        this.cancelQuestion();
      }
    }
  }
  selectDataSet(data){
    this.allDataSet.forEach((dataSet)=>{
      if(dataSet._id.toString() === data._id.toString()){
        dataSet['selected'] = true;
      }else{
        dataSet['selected'] = false;
      }
    });
    this.selectedDataSet = data;
    this.cancelAnswer();
  }
  editDataSet(data){
    this.question = data.question;
    this.editDataset = data;
    this.dataSetQuestionChange = true;
  }
  cancelQuestion(){
    this.dataSetQuestionChange = false;
    this.question = '';
    this.editDataset = null;
  }
  cancelAnswer(){
    this.dataSetAnswerChange = false;
    this.answer = '';
    this.editRow = null;
  }
  async confirmAnswer(){
    let answers = JSON.parse(JSON.stringify(this.selectedDataSet.answers));
    if(this.editRow){
      const index = answers.findIndex((ans)=> ans._id.toString() === this.editRow._id.toString());
      let data = {}
      if(index > -1){
        answers[index].value = this.answer;
        data = {
          answers
        }
      }
      const response:any = await this.dermService.updateDataSet(this.selectedDataSet._id,data).toPromise();
      if(response.status === 200){
        this.toastService.success("Answer Updated Successfully","Success");
        const selectedQuesIndex = this.allDataSet.findIndex((data)=> data._id.toString() === this.selectedDataSet._id.toString());
        if(selectedQuesIndex > -1){
          this.allDataSet[selectedQuesIndex].answers = answers;
          this.selectedDataSet.answers = answers;
        }
        this.cancelAnswer();
      }
    }else{
      answers.push({
        data_type: 'Text',
        value: this.answer
      });
      const data = {
        answers
      }
      const response:any = await this.dermService.updateDataSet(this.selectedDataSet._id,data).toPromise();
      if(response.status === 200){
        this.toastService.success("New Answer Added Successfully","Success");
        const selectedQuesIndex = this.allDataSet.findIndex((data)=> data._id.toString() === this.selectedDataSet._id.toString());
        if(selectedQuesIndex > -1){
          this.allDataSet[selectedQuesIndex].answers = response.data.answers;
          this.selectedDataSet.answers = response.data.answers;
        }
        this.cancelAnswer();
      }
    }
  }
  editAnswer(data){
    this.dataSetAnswerChange = true;
    this.answer = data.value;
    this.editRow = data;
  }
  async deleteQuestion(question){
    const data = {
      is_deleted: true
    }
    const response:any = await this.dermService.updateDataSet(question._id,data).toPromise();
    if(response.status === 200){
      this.toastService.success("Question Deleted Successfully","Success");
      const selectedQuesIndex = this.allDataSet.findIndex((data)=> data._id.toString() === question._id.toString());
      if(selectedQuesIndex > -1){
        this.allDataSet.splice(selectedQuesIndex,1);
      }
      this.cancelQuestion();
      if(this.selectedDataSet){
      this.selectedDataSet.answers = []
      }
      
    }
  }
  async deleteAnswer(answer,index){
    let answers = JSON.parse(JSON.stringify(this.selectedDataSet.answers));
    answers.splice(index,1);
    const data = {
      answers
    }
    const response:any = await this.dermService.updateDataSet(this.selectedDataSet._id,data).toPromise();
    if(response.status === 200){
      this.toastService.success("Answer Deleted Successfully","Success");
      const selectedQuesIndex = this.allDataSet.findIndex((data)=> data._id.toString() === this.selectedDataSet._id.toString());
      if(selectedQuesIndex > -1){
        this.allDataSet[selectedQuesIndex].answers = answers;
        this.selectedDataSet.answers = answers;
      }
      this.cancelQuestion();
      this.cancelAnswer();
    }
  }

  async DeleteQuestionDialog(dataset){
    let dialogRef = this.dialog.open(DialogConfirmationComponent,{
        data: {
          message: "Are you sure you want to delete this question?"
        }
      });
      dialogRef.afterClosed().subscribe(async result => {
        if (result === "true") {
          console.log("dataset",dataset)
          this.deleteQuestion(dataset)
        }
    });
  }

  async DeleteAnswerDialog(answer,index){
    let dialogRef = this.dialog.open(DialogConfirmationComponent,{
        data: {
          message: "Are you sure you want to delete this answer?"
        }
      });
      dialogRef.afterClosed().subscribe(async result => {
        if (result === "true") {
          console.log("dataset",answer,index)
          this.deleteAnswer(answer,index)
        }
    });
  }
}
