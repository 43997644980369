<mat-card>
    <mat-card-header>
        <mat-card-title>
                <h2 style="color: black;margin:0">
                    Appointment Update
                </h2>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content style="margin-left: 50px">
            
            <form style="max-width: 700px;"
            class="flex flex-col"
            (ngSubmit)="updateAppointment(form.value)" #form="ngForm">
                                   <mat-form-field>
                                    <mat-label>Title</mat-label>
                                    <mat-select name="title"
                                                required
                                                #title="ngModel"
                                                [(ngModel)]="model.title_id">
                                        <mat-option (click)="showTitleAddDialog()">
                                            <strong>+ Add New Note Type</strong>
                                        </mat-option>
                                        <mat-option *ngFor="let t of titles"
                                                    value="{{t._id}}">{{t.title}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="title.invalid  && (title.dirty || title.touched)">
                                        Please select title
                                    </mat-error>
                                </mat-form-field>
            
                                <mat-form-field>
                                    <mat-label>Select Facility</mat-label>
                                    <mat-select name="facility"
                                                required
                                                #facility="ngModel"
                                                [(ngModel)]="model.facility_id">
                                        <mat-option *ngFor="let f of facilities"
                                                    value="{{f._id}}">{{f.title}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                            *ngIf="facility.invalid  && (facility.dirty || facility.touched)">
                                        Please select facility
                                    </mat-error>
                                </mat-form-field>
            
                                <mat-form-field>
                                    <mat-label>Provider</mat-label>
                                    <input matInput
                                            name="doctor"
                                            disabled
                                            [(ngModel)]="doctor">
                                </mat-form-field>
            
                                <mat-form-field class="flex-grow">
                                    <mat-label>Patient</mat-label>
                                        <input matInput type="text" [(ngModel)]="model.patient_id" [formControl]="patientControl"
                                            [matAutocomplete]="patientAutoComplete">
                                        <mat-autocomplete #patientAutoComplete="matAutocomplete" [displayWith]="displayPatientFn">
                                            <mat-option value="" (click)="showPatientAddDialog()">
                                                                    <strong>+ Add New Patient</strong>
                                                                </mat-option>
                                            <mat-option *ngFor="let patient of filteredPatients | async" [value]="patient">
                                                <app-patient-name [patient]="patient"></app-patient-name>
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>

                                <mat-form-field>
                                    <mat-label>Select IME Vendor</mat-label>
                                    <mat-select name="payor"
                                                required
                                                #payor="ngModel"
                                                [(ngModel)]="model.payor_id">
                                        <mat-option *ngFor="let pay of payors"
                                                    value="{{pay._id}}">{{pay.abbreviation}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="payor.invalid  && (payor.dirty || payor.touched)">
                                        Please select payor
                                    </mat-error>
                                </mat-form-field>
            
                                <mat-form-field *ngIf="!hasSelectedPatientEmail(model.patient_id)">
                                    <mat-label>Patient Email</mat-label>
                                    <input matInput
                                            name="patient_email"
                                            required
                                            #patient_email="ngModel"
                                            [(ngModel)]="model.patient_email">
                                            <mat-error *ngIf="patient_email.invalid  && (patient_email.dirty || patient_email.touched)">
                                                Please enter patient email
                                            </mat-error>
                                </mat-form-field>
                                <div class="flex flex-col gap-2">
                                    <div class="flex flex-row justify-start items-baseline gap-2">
                                        <mat-label>Reminder</mat-label>
                                        <mat-form-field style="width:auto">
                                            <mat-label>Type</mat-label>
                                            <mat-select name="notify_type"
                                                        required
                                                        #notify_type="ngModel"
                                                        multiple
                                                        [(ngModel)]="model.notify_type">
                                                <mat-option value="email">Email</mat-option>
                                                <mat-option value="phone">Phone</mat-option>
                                            </mat-select>
                                            <mat-error
                                                    *ngIf="notify_type.invalid  && (notify_type.dirty || notify_type.touched)">
                                                Please select one option
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field style="width:auto">
                                            <mat-label>Time</mat-label>
                                            <mat-select name="notify_time"
                                                        required
                                                        #notify_time="ngModel"
                                                        [(ngModel)]="model.notify_time">
                                                <mat-option value="30">30 minutes earlier</mat-option>
                                                <mat-option value="15">15 minutes earlier</mat-option>
                                                <mat-option value="5">5 minutes earlier</mat-option>
                                            </mat-select>
                                            <mat-error
                                                    *ngIf="notify_time.invalid  && (notify_time.dirty || notify_time.touched)">
                                                Please select one option
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div>
                                        <mat-checkbox
                                        name="video"
                                        #video="ngModel"
                                        [(ngModel)]="model.is_video">
                                            Video Conference
                                        </mat-checkbox>
                                    </div>
                                    <div>
                                        <mat-form-field style="width: auto;">
                                            <input matInput
                                                    [matDatepicker]="picker"
                                                    [min]="currentDate"
                                                    (dateChange)="dateChange($event)"
                                                    name="dos"
                                                    #dos="ngModel"
                                                    [(ngModel)]="model.dos"
                                                    placeholder="Choose a date">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                            <mat-error *ngIf="dos.invalid  && (dos.dirty || dos.touched)">
                                                Please select date of service
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
     
                                    <div class="flex">
                                        <input matInput [hidden]="true" />

                                    </div>
                                </div>

                <div class="flex items-center justify-center gap-2"
                        style="margin-top: 10px; padding: 10px;"
                        >
                    <button mat-raised-button
                            class="dn-button-secondary"
                            (click)="didExit.emit(true)"
                            type="button">Cancel
                    </button>
                    <button mat-raised-button
                            [disabled]="!form.form.valid"
                            style=""
                            type="submit"
                            class="dn-button-primary">Save
                    </button>
                </div>
            </form>
    </mat-card-content>
</mat-card>
