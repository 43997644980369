import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { lastValueFrom } from 'rxjs';
import { WoundCareWidgetService } from '../../wound-care-widget.service';
import { calculateArea, calculateVolume } from '../../wound-utils';
import moment from 'moment'
import { CompanyService } from 'src/app/services/Modules/company.service';
import { CensusListService } from 'src/app/includes/census-list/census-list..service';
import { AuthService } from 'src/app/services/auth.service';
import { PatientRoundingSheetService } from 'src/app/includes/patient-rounding-sheets/patient-rounding-sheet.service';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-wound-care-widget-item-graph',
  templateUrl: './wound-care-widget-item-graph.component.html',
  styleUrls: ['./wound-care-widget-item-graph.component.css']
})
export class WoundCareWidgetItemGraphComponent implements OnInit, AfterViewInit {
  loaderId = 'app-wound-care-widget-item-graph';
  @Input() note;
  @Input() parentWoundId;
  @Input() readOnlyMode;
  wounds: {
    length: number,
    width: number,
    depth: number,
    createdAt: Date | string,
    updatedAt: Date | string,
    area: number | string,
    volume: number | string,
    source: string,
    entered_dot_phrases: any

  }[] = []
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
  canvas: any;
  ctx: any;
  @ViewChild('lineChart') lineChart: any;
  chart: Chart<"line", any[], string>;
  carryOverCharge: any;
  charge: any;
  carryChargeStatus: boolean;
  currentUser: any;

  constructor(private _woundCareWidgetService: WoundCareWidgetService,
    private loader: NgxUiLoaderService,
    private _company: CompanyService,
    private censusListService: CensusListService,
    private authService: AuthService,
    private service: PatientRoundingSheetService,
    private _commonService: CommonService
  ) {
    this.currentUser = this.authService.currentUser;
  }
  async ngAfterViewInit() {
    await this._company.getCompanyChargeandSignNoteStatus(this.currentUser.company_id).subscribe((res: any) => {
      if (res.status == 200) {
        if(res.data[0].charges_carry_forward != undefined || res.data[0].charges_carry_forward != null){
          this.carryChargeStatus = res.data[0].charges_carry_forward;
        }
      }
    });
    await this.initWounds();

    this.createGraph();
  }

  ngOnInit(){
  }
  async initCharge() {
    const details = {
      patient_id: this.note.patient._id,
      rounding_sheet_id: this.note.rounding_sheet_id,
      visit_date: this.note.dates.service,
      status: null
    }
    let response: any = await this.censusListService.getCharge(details).toPromise();
    if (response.status === 200) {
      this.charge = response.data;
      if (!this.charge && this.carryChargeStatus) {
        let res: any = await this.service.getRecentCharge(details).toPromise()
        if (res.status == 200) {
          this.carryOverCharge = res.data;
          this.charge = this.carryOverCharge;
        }
      }
    }
  }

  async initWounds() {
    this.loader.startLoader(this.loaderId)
    const { status, data } = await lastValueFrom(this._woundCareWidgetService.getWoundGraphData(this.parentWoundId)) as any;
    if (status == 200) {
      this.wounds = data.array.map(item => ({
        length: parseFloat(item.length),
        width: parseFloat(item.width),
        depth: parseFloat(item.depth),
        createdAt: new Date(this._commonService.dateObjToString(item.last_assessment_date_obj, false)),
        updatedAt: new Date(item.updatedAt),
        area: item?.area,
        volume: item?.volume,
        source: item.source,
        entered_dot_phrases: item.entered_dot_phrases || []
      }));
    }
    this.loader.stopLoader(this.loaderId)
  }

  createGraph() {
    let MONTHS = { Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12 };
    let charge_phrase = []
    let enteredDotphrases = this.wounds[0].entered_dot_phrases;
    enteredDotphrases.forEach((ele)=>{
        if(ele.enter_date){
          if(ele.enter_date.day < 10){
            charge_phrase.push({
              key: ele.key,
              enter_date: this.months[ele.enter_date.month - 1] + "/" +"0"+ ele.enter_date.day + "/" + ele.enter_date.year
            })
          }else{
            charge_phrase.push({
              key: ele.key,
              enter_date: this.months[ele.enter_date.month - 1] + "/" + ele.enter_date.day + "/" + ele.enter_date.year
            })
          }
        }
      });
    let area = JSON.parse(JSON.stringify(this.wounds.map(calculateArea)))
    area.forEach((ar,index)=>{
      if(area.length > 1){
        if(ar <= 0 && (index < area.length - 1)){
          area[index] = null
        } 
      }else{
        if(ar <= 0){
          area[index] = null
        }
      }
    })
    let volume = JSON.parse(JSON.stringify(this.wounds.map(calculateVolume)))
    volume.forEach((vol,index)=>{
      if(volume.length > 1){
        if(vol <= 0 && (index < volume.length - 1)){
          volume[index] = null
        }
      }else{
        if(vol <= 0){
          volume[index] = null
        }
      }
    })
    let woundDates = []
    woundDates = this.wounds.map(wound=> moment(wound.createdAt).format('MMM/DD/YYYY'));
    enteredDotphrases = charge_phrase;
    woundDates.forEach((d, indexx)=>{
      let index = enteredDotphrases.findIndex((c)=> c?.enter_date === d);
      if(index > -1){
        enteredDotphrases.splice(index,1); 
      }
    });
    enteredDotphrases = enteredDotphrases.map((p) => p.enter_date);
    let allDates=[...woundDates, ...enteredDotphrases];
    allDates.sort(function (a, b) {
    var aa = a.split('/'),
        bb = b.split('/');
    return aa[2] - bb[2] || aa[1] - bb[1] || MONTHS[aa[0]] - MONTHS[bb[0]];});
    if(allDates.length > area.length){
      enteredDotphrases.forEach((date)=>{
        const dateIndex = allDates.findIndex((d)=> d === date);
        if(dateIndex > -1){
          if(dateIndex > 1){
            area.splice(dateIndex,0,area[dateIndex - 1])
            volume.splice(dateIndex,0,volume[dateIndex - 1])
          }else{
            area.splice(dateIndex,0,area[0])
            volume.splice(dateIndex,0,volume[0])
          }
        }
      });
    }
    let pointScale = [];
    allDates.forEach((d)=>{
      let index = charge_phrase.findIndex((c)=> c?.enter_date === d); 
      if(index > -1){
        var letterImage = new Image();
        letterImage.width = 20;
        letterImage.height = 20;
        if(charge_phrase[index].key.toLowerCase().includes('deb')){
          letterImage.src ="../../../../../../assets/icons/letterD.png";
        }else if(charge_phrase[index].key.toLowerCase().includes('biopsy')){
          letterImage.src ="../../../../../../assets/icons/letterB.png";
        }else if (charge_phrase[index].key.toLowerCase().includes('woundprocedure')) {
          letterImage.src ="../../../../../../assets/icons/letterW.png";
        } else {
          letterImage.src ="../../../../../../assets/icons/letterG.png";
        }
        pointScale.push(letterImage);
      }else{
        pointScale.push('circle')
      }
    })

    this.canvas = this.lineChart.nativeElement;
    this.ctx = this.canvas.getContext('2d');
    if (this.chart) {
      this.chart.destroy();
    }
    this.chart = new Chart(this.ctx, {
      type: 'line',
      data: {
        datasets: [{
          label: 'Area',
          data: area,
          backgroundColor: "rgb(115 185 243 / 65%)",
          borderColor: "green",
          pointStyle: pointScale
        },
        {
          label: 'Volume',
          data: volume,
          backgroundColor: "#47a0e8",
          borderColor: "blue",
        }],
        labels: allDates
      },
      options: {
        plugins: {
          subtitle: {
            display: true,
            color: "#FF0000",
            text: 'D=Debridement  B=Biopsy  G=Graft  W=Wound Procedure'
        }
        },
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }

    });
  }
}
