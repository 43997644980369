<div class="h100 flex xs:flex-col items-center justify-center">
    <mat-card class="w-2/5 xs:w-full">

        <mat-card-title class="flex flex-col justify-center items-center">
            <img src="../../../assets/img/DNlogo-new.png">

            <h2>Admin Portal Login</h2>
        </mat-card-title>

        <mat-card-content class="flex flex-col">
            <form (submit)="loginWithCognito(loginForm)" #loginForm="ngForm">
                <mat-error *ngIf="status != 200 && status!= null">Invalid Credentials!</mat-error>
                <mat-form-field>
                    <input matInput type="text" name="email" id="user-email" placeholder="Email address"
                        #emailField="ngModel" [(ngModel)]="email" required>
                    <mat-error *ngIf="emailField.invalid">Please enter email</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input matInput type="password" name="password" id="user-password" placeholder="Password"
                        #passwordField="ngModel" [(ngModel)]="password" ngModel required
                        [type]="hide ? 'password' : 'text'">
                    <mat-icon matSuffix style="cursor: pointer;" (click)="hide = !hide">{{hide ? 'visibility_off' :
                        'visibility'}} </mat-icon>
                    <mat-error *ngIf="passwordField.invalid">Please enter password</mat-error>
                </mat-form-field>

                <div class="remember-me flex justify-between items-center">
                    <div class="w-2/5">
                        <mat-checkbox name="remember_me_check" [(ngModel)]="remember_me_check">Remember me
                        </mat-checkbox>
                    </div>
                    <div class="flex justify-end w-2/5">
                        <a href="/forgot-password">Forgot password?</a>
                    </div>
                </div>
                <div class="flex justify-center items-center">
                    <button mat-raised-button class="dn-button-primary w-1/2" id="form-submit" [disabled]="isInProgress"
                        mat-stroked-button type="submit">Login</button>
                </div>

                <div class="member flex flex-col justify-center items-center">


                </div>

            </form>

        </mat-card-content>

    </mat-card>
</div>