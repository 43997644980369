import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import * as global from '../../global';
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators'
import { isArray, isObject } from 'lodash';
import { MergeFacilityService } from './merge-facility.service';

@Component({
  selector: 'app-merge-facilities-data',
  templateUrl: './merge-facilities-data.component.html',
  styleUrls: ['./merge-facilities-data.component.css']
})
export class MergeFacilitiesDataComponent implements OnInit {
  facility_one: any;
  facility_two: any;

  facility_one_after: any;
  facility_two_after: any;

  facilitySearchLoaderId = 'facility_one_loader';
  facilityOne = [];
  facilityTwo = [];

  revertStatus: boolean;
  revertStatusone: boolean;
  mergeStatusone: boolean;
  mergeStatustwo: boolean;
  choice: number;
  first_facility_id: any;
  second_facility_id: any;

  constructor(
    private mergeFacilityService: MergeFacilityService,
    private loader: NgxUiLoaderService,
    private toastr: ToastrService) {
    this.choice = 0;
  }

  ngOnInit(): void {
    this.revertStatusone = true;
    this.revertStatus = true;
    this.mergeStatusone = false;
    this.mergeStatustwo = false;
  }
  async fetchOne() {
    this.toastr.info('Fetching Facility', 'Wait....');
    await this.mergeFacilityService.searchTheFacilityById(this.first_facility_id).subscribe((res: any) => {
      if (res.data.length == 0) {
        this.toastr.warning(' 0 Facility found.', 'Warning');
        this.revertStatusone = true;
        this.revertStatus = true;
        this.mergeStatusone = false;
        this.mergeStatustwo = false;
        return;
      } else {
        this.facility_one = res.data;
        this.facility_one_after = null;
        this.revertStatusone = true;
        this.revertStatus = true;
        this.mergeStatusone = false;
        this.mergeStatustwo = false;
        this.facilityOne = res.data;
        this.toastr.success('Facility one has been fetched.', 'Success');
      }
    });
  }

  async fetchTwo() {
    this.toastr.info('Fetching Facility', 'Wait....');
    await this.mergeFacilityService.searchTheFacilityById(this.second_facility_id).subscribe((res: any) => {
      if (res.data.length == 0) {
        this.toastr.warning(' 0 Facility found.', 'Warning');
        this.revertStatusone = true;
        this.revertStatus = true;
        this.mergeStatusone = false;
        this.mergeStatustwo = false;
        return;
      } else {
        this.facility_two = res.data;
        this.facility_two_after = null;
        this.revertStatusone = true;
        this.revertStatus = true;
        this.mergeStatusone = false;
        this.mergeStatustwo = false;
        this.facilityOne = res.data;
        this.toastr.success('Facility two has been fetched.', 'Success');
      }
    });
  }

  async patient_one_to_patient_two() {
    let query;
    query = {
      src_facility_id: this.facility_one._id,
      dst_facility_id: this.facility_two._id,
    }
    if (this.facility_one.source && this.facility_one.source === 'PointClickCare') {
      query.is_srcF_pcc = true;
    }
    if (this.facility_two.source && this.facility_two.source === 'PointClickCare') {
      query.is_dstF_pcc = true;
    }
    if (this.facility_one._id == this.facility_two._id) {
      this.toastr.error('Same Facilities can not be merged.', 'ERROR');
      this.clearFilterTwo();
      this.clearFilterOne();
      return;
    }

    this.mergeStatusone = true;
    this.mergeStatustwo = true;
    this.loader.startLoader(this.facilitySearchLoaderId);
    this.toastr.info("Please Wait! Merging in progress..", 'Wait');

    await this.mergeFacilityService.getMergingAudit(query).subscribe(async (res: any) => {
      let result = res.data;
      this.choice = 1;
      if (result) {
        this.loader.stopLoader(this.facilitySearchLoaderId);
        this.toastr.error('Facilities are already Merged.', 'ERROR');
        this.revertStatusone = false;
        this.revertStatus = false;
      } else {
        await this.mergeFacilityService.mergeTheFacilityData(query).subscribe(async (res: any) => {
          this.toastr.success("Merging Done", 'Success');
          this.revertStatusone = false;
          this.revertStatus = false;
          await this.searchTheFacilitybyId(this.facility_one._id, '1');
          await this.searchTheFacilitybyId(this.facility_two._id, '2');
          this.loader.stopLoader(this.facilitySearchLoaderId);
        })
      }
    })

  }

  async patient_two_to_patient_one() {
    let query;
    query = {
      src_facility_id: this.facility_two._id,
      dst_facility_id: this.facility_one._id,
    }
    if (this.facility_two.source && this.facility_two.source === 'PointClickCare') {
      query.is_srcF_pcc = true;
    }
    if (this.facility_one.source && this.facility_one.source === 'PointClickCare') {
      query.is_dstF_pcc = true;
    }
    if (this.facility_one._id == this.facility_two._id) {
      this.toastr.error('Same Facilities can not be merged.', 'ERROR');
      this.clearFilterTwo();
      this.clearFilterOne();
      return;
    }
    this.mergeStatusone = true;
    this.mergeStatustwo = true;
    this.loader.startLoader(this.facilitySearchLoaderId);
    this.toastr.info("Please Wait! Merging in progress..", 'Wait');

    await this.mergeFacilityService.getMergingAudit(query).subscribe(async (res: any) => {
      let result = res.data;
      this.choice = 2;
      if (result) {
        this.loader.stopLoader(this.facilitySearchLoaderId);
        this.toastr.error('Facilities are already Merged.', 'ERROR');
        this.revertStatusone = true;
        this.revertStatus = false;
      } else {
        await this.mergeFacilityService.mergeTheFacilityData(query).subscribe(async (res: any) => {
          this.toastr.success("Merging Done", 'Success');
          this.revertStatusone = true;
          this.revertStatus = false;
          await this.searchTheFacilitybyId(this.facility_one._id, '1');
          await this.searchTheFacilitybyId(this.facility_two._id, '2');
          this.loader.stopLoader(this.facilitySearchLoaderId);
        })
      }
    })
  }

  clearFilterOne() {
    this.revertStatus = true;
    this.mergeStatusone = false;
    this.mergeStatustwo = false;
    this.facility_one = null;
    this.facility_one_after = null;
    this.facilityOne = [];
    this.choice = 0;
  }

  clearFilterTwo() {
    this.revertStatus = true;
    this.mergeStatusone = false;
    this.mergeStatustwo = false;
    this.facility_two = null;
    this.facility_two_after = null;
    this.facilityTwo = []
    this.choice = 0;
  }

  async searchTheFacilitybyId(patient_id, whichOne) {
    await this.mergeFacilityService.searchTheFacilityById(patient_id).subscribe((res: any) => {
      if (whichOne == '1') {
        this.facility_one_after = res.data;
      } else if (whichOne == '2') {
        this.facility_two_after = res.data;
      }
    })
  }

  async revertMerge() {
    if (this.choice == 1) {
      await this.revertChanges_one_to_two();
    } else if (this.choice == 2) {
      await this.revertChanges_two_to_one();
    }
  }

  async revertChanges_one_to_two() {
    if (true) {
      let query;
      query = {
        src_facility_id: this.facility_one._id,
        dst_facility_id: this.facility_two._id,
      }
      this.revertStatusone = true;
      this.revertStatus = true;
      this.loader.startLoader(this.facilitySearchLoaderId);
      this.toastr.info("Please Wait! Reverting in progress..", 'Wait');
      await this.mergeFacilityService.getMergingAudit(query).subscribe(async (res: any) => {
        let result = res.data;
        if (result) {
          await this.mergeFacilityService.revertTheFacilityData(query).subscribe(async (res: any) => {
            let result = res.data;
            this.toastr.success('Merge is Reverted.', 'Success');
            this.choice = 0;
            this.mergeStatusone = false;
            this.mergeStatustwo = false;
            await this.searchTheFacilitybyId(this.facility_one._id, '1');
            await this.searchTheFacilitybyId(this.facility_two._id, '2');
            this.loader.stopLoader(this.facilitySearchLoaderId);
          })
        } else {
          this.loader.stopLoader(this.facilitySearchLoaderId);
          this.toastr.error('Facilities are Not Merged.', 'ERROR');
          this.choice = 0;
          this.mergeStatusone = false;
          this.mergeStatustwo = false;
        }
      })
    } else {
    }
  }

  async revertChanges_two_to_one() {
    if (true) {
      let query;
      query = {
        src_facility_id: this.facility_two._id,
        dst_facility_id: this.facility_one._id,
      }
      this.revertStatusone = true;
      this.revertStatus = true;
      this.loader.startLoader(this.facilitySearchLoaderId);
      this.toastr.info("Please Wait! Reverting in progress..", 'Wait');
      await this.mergeFacilityService.getMergingAudit(query).subscribe(async (res: any) => {
        let result = res.data;
        if (result) {
          await this.mergeFacilityService.revertTheFacilityData(query).subscribe(async (res: any) => {
            let result = res.data;
            this.toastr.success('Merge is Reverted.', 'Success');
            this.choice = 0;
            this.mergeStatusone = false;
            this.mergeStatustwo = false;
            await this.searchTheFacilitybyId(this.facility_one._id, '1');
            await this.searchTheFacilitybyId(this.facility_two._id, '2');
            this.loader.stopLoader(this.facilitySearchLoaderId);
          })
        }
        else {
          this.loader.stopLoader(this.facilitySearchLoaderId);
          this.toastr.error('Facilities are Not Merged.', 'ERROR');
          this.choice = 0;
          this.mergeStatusone = false;
          this.mergeStatustwo = false;
        }
      })
    } else {
    }
  }
}