import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/includes/login/login.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { CompanyService } from 'src/app/services/Modules/company.service';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyLogoAndMongogramService } from 'src/app/services/company-logo-and-mongogram.service';

@Component({
  selector: 'app-create-strong-password',
  templateUrl: './create-strong-password.component.html',
  styleUrls: ['./create-strong-password.component.css'],
})
export class CreateStrongPasswordComponent implements OnInit { 

  verifyCodeView = false;
  code:string = '';
  first_name: string = '';
  last_name: string = '';
  newPassword: string = '';
  confirmPassword: string = '';
  display: any;
  public timerInterval: any;
  hide = true;
  params: {
    user_id: string;
    verification_code: string;
    email: string;
  } = {
    user_id: null,
    verification_code: null,
    email: null,
  };
  error: string;

  loaderId = 'user-create-password';

  passwordStrength: number = 0;

  currentUser;
  companyCode: any;
  defaultImg: string;
  companylogoPathSubscription: any;
  companylogoPath: any;
  disableButton: boolean = false
  public constructor(
    private router: Router,
    public loginService: LoginService,
    private _toastr: ToastrService,
    private _router: Router,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService,
    private authService: AuthService,
    private companyLogoAndMonogramService: CompanyLogoAndMongogramService
  ) {}

  async ngOnInit() {
    this.currentUser = this.authService.currentUser;
    if(this.currentUser.cognitoPasswordEntered) {
      this.verifyCodeView = true;
    }
    if (this.route.snapshot.params.company_code) {
      this.companyCode = this.route.snapshot.params.company_code;
      console.log('this.companyCode', this.companyCode);
      await this.getCompanyLogo();
    } else {
      this.defaultImg = "../../../assets/img/DNlogo-new.png";
    }
    this.timer(2);
  }

  async getCompanyLogo() {
    this.companylogoPathSubscription = this.companyLogoAndMonogramService.castCompanyMonogram.subscribe((res:any)=>{
      if(res){
        this.companylogoPath = res;
      }else{
        this.defaultImg = "../../../assets/img/DNlogo-new.png";
      }
    });
  }
  
  
  async onSubmitWithCognito(form: NgForm) {

    if (!form.valid || !form.touched) return;

    if (this.newPassword !== this.confirmPassword) {
      this.error = 'Confirm password does not match';
      return;
    } else if(this.passwordStrength < 3) {
        form.controls['password'].setErrors({'incorrect': true})
        return;
    } else {
      this.error = '';
    }

    this.loader.startLoader(this.loaderId);

    try {
      // const encrypted: any = await this.loginService
      //   .getEncryptPassword(form.value.newPassword)
      //   .toPromise(); 
      // encrypted.encreptedPass
      const data = {
        user_id:this.currentUser._id,
        email:this.currentUser.email,
        password: form.value.newPassword,
      }; 
      const response: any = await this.companyService
        .createStrongPassword(data)
        .toPromise(); 
      if (response.status === 200) {
        this.verifyCodeView = true;
        this.currentUser.cognitoPasswordEntered = true;
        this.currentUser = {...this.currentUser};
        // this._toastr.success('Your password has been changed.', 'Success');
        // this._router.navigate(['/dashboard']); 
      } else {
        this._toastr.error(response.message, 'Success');
      }

      this.loader.stopLoader(this.loaderId);
    } catch (error) {
      console.error(error);
      this._toastr.clear();
    }
  }
  async verifyCode(form: NgForm) {

    if (!form.valid || !form.touched) return;

    if (!this.code) {
      this.error = 'Please enter verification code';
      return;
    } else {
      this.error = '';
    }

    this.loader.startLoader(this.loaderId);

    try {
      const data = {
        user_id:this.currentUser._id,
        email:this.currentUser.email,
        code: form.value.code,
      }; 
      const response: any = await this.companyService
        .verifyCode(data)
        .toPromise(); 
      if (response.status === 200) {
        this.verifyCodeView = true;
        this._toastr.success('Verification Successful, please login again with new password', 'Success');
        this._router.navigate(['/login']); 
      } else {
        this._toastr.error(response.message, 'Success');
      }

      this.loader.stopLoader(this.loaderId);
    } catch (error) {
      console.error(error);
      this._toastr.clear();
    }
  }

  async resendConfirmationCode() {
    this.disableButton = true;
    const data = {
      user_id:this.currentUser._id,
      email:this.currentUser.email
    }; 
    const response: any = await this.companyService
        .resendConfirmationCode(data)
        .toPromise(); 
      if (response.status === 200) {
        this.timer(2);
        this.verifyCodeView = true;
        this._toastr.success('Verification code has been sent', 'Success');
      } else {
        this._toastr.error(response.message, 'Success');
      }
  }
  strengthChange(passwordStrength) {
    this.passwordStrength = passwordStrength; 
  }

  timer(minute) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
      if(this.display.includes('-')){
        this.display = '00:00';
        clearInterval(this.timerInterval);
      }
      if (seconds <= 0) {
        if(this.display !== '00:00'){
          this.display = '00:00';
        }
        // console.log('finished');
        clearInterval(this.timerInterval);
      }
      this.disableButton = false;
    }, 1000);
  }
}
