import { Component,Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TranscriptionCreateService } from '../transcription-create.service';
import * as global from '../../global';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import moment from 'moment'
import { ToastrService } from 'ngx-toastr';
import { PCCService } from 'src/app/services/pcc.service';
import { lastValueFrom } from 'rxjs';

// UPLOAD FILE DIALOG
export interface PCCViewNoteDialog {
  pccNote
}

@Component({
  selector: 'app-view-pcc-note-dialog',
  templateUrl: './view-pcc-note-dialog.component.html',
  styleUrls: ['./view-pcc-note-dialog.component.css']
})
export class PCCViewNoteDialog implements OnInit {
  global = global;

  pccNote;
  facility;
  patient;

  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<PCCViewNoteDialog>,
    private toastr: ToastrService,
    private pccService: PCCService,
    // private loader: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: PCCViewNoteDialog) {
      if(data) {

        this.pccNote = data.pccNote;
        this.patient = data.patient;
        this.facility = data.patient.facility_id_ref

      }
    }

    ngOnInit(): void {
      this.initPccNote();
    }

  async initPccNote() {
    try {
      this.isLoading = true;
      const pcc_orgUuid = this.facility.pcc_orgUuid;
      const response: any = await lastValueFrom(this.pccService.getNote(pcc_orgUuid, this.pccNote.progressNoteId));
      this.pccNote = response.data;
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  }
    applyTimezone(date) {
        return moment(new Date(date).toLocaleString('en-US', { timeZone:  this.pccNote.filterServiceDate.timezone })).format('MMM DD, YYYY h:mm a');
    }
    copyToClipboard(text) {
      text = text.split('<br />').join('\n');
      window.navigator.clipboard.writeText(text).then(() => {
        this.toastr.info('Copied to clipboard');
      });
    }
    getSection(section) {
      if(Array.isArray(this.pccNote.sections)) {
        return this.pccNote.sections.find(s => s.name === section)
      }
  // 0: {name: "Date of Service", value: "12/21/2020 07:46 AM"}
  // 1: {name: "Visit Type", value: "FORMATTED INITIAL EVAL"}
  // 2: {name: "Transition of Care", value: "N/A"}
  // 3: {name: "History Details", value: "N/A"}
  // 4: {name: "Details",…}
  // 5: {name: "Provider", value: "Provider Provider 4, M.D."}
  // 6: {name: "Signed Date", value: "12/21/2020 07:49 AM"}
    }
    isNoteTextSection(sectionName) {
      return ['Note Text', 'Details'].includes(sectionName);
    }
}
