import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { CKEditorService } from 'src/app/includes/note-editor/ckeditor.service';
import { TagsService } from 'src/app/services/tags.service';

@Component({
  selector: 'app-edit-tags-dialogue',
  templateUrl: './edit-tags-dialogue.component.html',
  styleUrls: ['./edit-tags-dialogue.component.css']
})
export class EditTagsDialogueComponent implements OnInit {
  newTag = {
    name: '',
    color: '',
    phrase: '',
    phrase_text: ''
  }
  previousData: any;
  isButtonDisable = false;
  isCloseDialogue = false;
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = ['name', 'color', 'phrase', 'phrase_text'];
  tags = [];
  isShowTable = false;
  phrase: string = '';
  cke4Config;

  constructor(private ckeditorService: CKEditorService, private dialogRef: MatDialogRef<EditTagsDialogueComponent>, private toastr: ToastrService, private tagsService: TagsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.setPreviousData(data)
    this.cke4Config = {
      ...this.ckeditorService.getDefaultConfig(),
      editable: true
    };
  }

  ngOnInit(): void {
    this.setTagData(this.data);
  }

  setTagData(data: any) {
    this.newTag = {
      name: data.name,
      color: data.color,
      phrase: data.phrase,
      phrase_text: data.phrase_text
    };

    this.isButtonDisable = false;

  }

  closeDialog() {
    const data = this.getPreviousData()
    this.isCloseDialogue = true;
    this.dialogRef.close(data);
  }

  setPreviousData(data: any) {
    this.previousData = data;
  }

  getPreviousData() {
    return this.previousData;

  }

  checkIsEmpty() {
    if (this.newTag.name.trim().length === 0)
      this.isButtonDisable = true;
    else
      this.isButtonDisable = false;

  }

  generatePhrase() {
    let prefix = '.tag_';
    let suffix = '@dn';
    this.newTag.name = this.newTag.name.trim();
    this.newTag.name = this.newTag.name.replace(/\s+/g, " ");               // remove extra white spaces in between words
    let words = this.newTag.name.toLowerCase().split(' ');
    let phrase = prefix.concat(words.join("_"));
    this.newTag.phrase = phrase.concat(suffix);

    this.checkIsPhraseExist();
  }

  checkIsPhraseExist() {
    this.checkIsEmpty();
    if (this.phrase == this.newTag.phrase)
      this.isButtonDisable = true;
    else
      this.isButtonDisable = false;
  }

  checkPhraseText(){
    let phrase_text = this.tagsService.extractContentFromHtmlTag(this.newTag.phrase_text);
    if (phrase_text.trim().length === 0)
      return true;
    return false;  
  }

  async save() {
    let res: any, toastrId: any;
    try {
      this.checkIsEmpty();

      if (!this.isCloseDialogue) {
        let isContainSpace = this.checkPhraseText();

        if (isContainSpace)
          this.newTag.phrase_text = '';

        if (!this.isButtonDisable) {
          toastrId = this.toastr.info("Tag is Updating ", "Please Wait...", { disableTimeOut: true });

          res = await lastValueFrom(this.tagsService.updateTag({ _id: this.data._id }, this.newTag));
          if (res.status == 200) {
            this.toastr.clear(toastrId);
            this.toastr.success(res.message, "Success");
            this.dialogRef.close(res.data);
          } else {
            if (res.data[0]._id != this.data._id) {
              this.toastr.clear(toastrId);
              res.data[0].extracted_phrase_text = this.tagsService.extractContentFromHtmlTag(res.data[0].phrase_text);
              this.tags = res.data;
              this.dataSource.data = this.tags;
              this.isShowTable = true;
              this.phrase = res.data[0].phrase;
              this.isButtonDisable = true;
              this.toastr.warning(res.message);
            }
            else {
              res = await lastValueFrom(this.tagsService.updateTag({ _id: this.data._id, isAllowToUpdate: true }, this.newTag));
              if (res.status == 200) {
                this.toastr.clear(toastrId);
                this.toastr.success(res.message, "Success");
                this.dialogRef.close(res.data);
              } else {
                this.toastr.clear(toastrId);
                this.toastr.error(res.message, "Error");
                this.dialogRef.close(res.data);
              }
            }

          }
        }
      }
      else
        this.dialogRef.close({ data: res });


    } catch (err) {
      this.toastr.clear(toastrId);
      this.toastr.error('Something went wrong', 'Error');
    }

  }

  clearDetails() {
    this.newTag = {
      name: '',
      color: '#2bc6b9',
      phrase: '',
      phrase_text: ''
    }

    this.isButtonDisable = true;

  }

}
