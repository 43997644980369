<div style="display: flex; flex-direction: column;">
    <div>
        <mat-form-field class="flex w-full" appearance="fill">
            <mat-label>Select File Type</mat-label>
            <mat-select required [(ngModel)]="selectedFileType">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let fileType of fileTypes" [value]="fileType.value">
                    {{fileType.name}}
                </mat-option>
            </mat-select> 
        </mat-form-field>
    </div>
    <div style="align-self: center;margin-bottom: 10px">
        <input (change)="selectFiles($event)" hidden accept=".pdf, image/*" type="file" id="file_uploader"
            #file_uploader  [(ngModel)]='censusFile'/>
        {{fileName()}}
        <button #file_uploader_btn class="dn-button-primary" mat-raised-button (click)="file_uploader.click()">Add
            File</button>
    </div>
    <div style="align-self: flex-end;">
        <button style="margin-left: 10px;" class="dn-button-secondary" mat-raised-button (click)="cancelClicked()" >Cancel</button>
        <button style="margin-left: 10px;" class="dn-button-primary" mat-raised-button (click)="upload(file_uploader)"  [disabled]="!selectedFiles || !selectedFileType">Upload</button>
    </div>
</div>

<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>