import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { TemplateExplorerService } from '../../templates/template-explorer/template-explorer.service';

@Component({
  selector: 'app-co-signer',
  templateUrl: './co-signer.component.html',
  styleUrls: ['./co-signer.component.css']
})
export class CoSignerComponent implements OnInit {
  @Input('company') company;
  @Output() exit = new EventEmitter<boolean>();
  @Output() updateCurrentCompany = new EventEmitter<boolean>();
  canEditModifier: any;
  cptCodesDropdownSettings: any = {};
  cpts: any;
  company_id = '';


  constructor(
    private companyService: CompanyService,
    private toastr: ToastrService,
    private templateExplorerService: TemplateExplorerService,
    private route: ActivatedRoute,

  ) {
    this.cptCodesDropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'codeDescription',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: true,
    };
  }

  async ngOnInit() {
    console.log('this company co signer', this.company);
    this.company_id = this.company._id;
    this.canEditModifier = this.company.charge_npi;
    await this.initCompanycptCodes(this.company._id);
  }

  async initCompanycptCodes(company_id) {
    console.log('company_id',company_id);
    return new Promise<void>((resolve, reject) => {
      this.templateExplorerService.getAllCompanyCPTs(company_id).subscribe((response: any) => {
        if (response.status == 200) {
          this.cpts = response.data.cpt_ids;
          resolve(null);
        } else {
          console.log('something went wrong');
        }
      }, err => {
        reject();
        console.log('something went wrong', err);
      });
    })
  }

  updateComapny(Company, field, value) {
    let data = {
      _id: Company._id,
      [field]: value
    }
    this.companyService.updateCompany(data).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      } else {
        this.toastr.error(response.message, 'Failed');
      }
    });
    this.canEditModifier = value;
  }

  saveModifier() {
    const data = {
      companyId: this.company._id,
      modifier: this.company.modifier
    };
    this.companyService.saveCompanyModifier(data).subscribe((res: any) => {
      if(res.status == 200) {
        this.toastr.success(res.message, 'Success');
      }else {
        this.toastr.error(res.message, 'Failed');
      }
    })
  }

  goBack() {
    this.exit.emit(false);
    this.updateCurrentCompany.emit(this.company);
  }

  async onCodeSelect(element, $event) {
    let isCreating = true;
    let cpts = [];
    cpts.push($event._id);
    await this.updateCptRelation(element._id, cpts, isCreating);
  }

  async onCodeDeSelect(element, $event) {
    let isCreating = false;
    let cpts = [];
    cpts.push($event._id);
    await this.updateCptRelation(element._id, cpts, isCreating);
  }

  async onSelectAllCodes(element, $event) {
    let isCreating = true;
    await this.updateCptRelation(element._id, $event, isCreating);
  }

  async onDeSelectAllCodes(element, $event) {
    let isCreating = false;
    await this.updateCptRelation(element._id, $event, isCreating);
  }

  async updateCptRelation(companyId, cptId, isCreating: boolean) {
    this.companyService.updateCompanyCptRelationForModifier(companyId, cptId, isCreating).subscribe((res: any) => {
      if (res.status == 200) {
        this.toastr.success('Relation Updated!', 'Success');
      } else {
        console.log('something went wrong');
      }
    });
  }

}
