import { Component, OnInit, ViewChild } from '@angular/core';
import * as global from '../global'
import { PatientListService } from '../patient-list/patient-list-service';
import { AuthService } from 'src/app/services/auth.service';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, Sort} from "@angular/material/sort";
import { PatientRoundingSheetService } from './patient-rounding-sheet.service';
import { MatDialog } from '@angular/material/dialog';
import {PatientRoundingSheetDialog} from './create-patient-rounding-sheet-dialog/create-patient-rounding-sheet-dialog';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { FacilityService } from 'src/app/services/facility.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MultiplePatientsAddDialog } from './multiple-patients-add-dialog/multiple-patients-add-dialog';
import moment from 'moment'
@Component({
  selector: 'app-patient-rounding-sheets',
  templateUrl: './patient-rounding-sheets.component.html',
  styleUrls: ['./patient-rounding-sheets.component.css']
})
export class PatientRoundingSheetsComponent implements OnInit {
  global=global;
  currentUser;
  currentUserDetails;

  patients = [];
  patientRoundingSheets:any = [];
  displayedColumns: string[] = [
    'name', 
    'facility',   
    'patients_count_total',
    'patients_count_new',
    'patients_count_followUp',
    'patients_count_discharge',
    'operations'
  ];
  // Removed // 'date',
  dataSource :MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  facilities = [];
  filter_facility_id = "";

  
  loaderId = 'patient-rounding-sheets-loader';
  inprogress = false;

  constructor(
    private _patientListService: PatientListService,
    private _authService: AuthService,
    private _patientRoundingSheetService:PatientRoundingSheetService,
    private dialog: MatDialog,
    private toastr:ToastrService,
    private facilityService:FacilityService,
    private loader: NgxUiLoaderService
  ) {
    this.currentUser = this._authService.currentUser;
    this.currentUserDetails = {
      id: this.currentUser._id,
      auth_key: this.currentUser.auth_key,
      usertype: this.currentUser.user_type
    }
   }

  ngOnInit() {
    // this.initPatients();
    this.initPatientRoundingSheets();
    this.initFacilities();
  }

  initPatients() {
    const details = {
      ...this.currentUserDetails
    }
    this._patientListService.getPatients(details).subscribe((response: any) => {
      if (response.status === 200) {
        this.patients = response.data.array
          .filter(patient => patient.is_active === 'true')
      }
    })
  }
  initPatientRoundingSheets(facility_id?) {
    const details = {
      ...this.currentUserDetails,
      facility_id
    }
    this.loader.startLoader(this.loaderId);

    this._patientRoundingSheetService.getPatientRoundingSheets(details).subscribe((response:any) => {
      if(response.status === 200) {
        this.patientRoundingSheets = response.data.array;
        this.dataSource = new MatTableDataSource(this.patientRoundingSheets);
        this.loader.stopLoader(this.loaderId);
      }
    })
  }
  initFacilities() {
    this.facilityService.castFacilities.subscribe((facilities: any) => {
      if (Array.isArray(facilities)) {
        this.facilities = facilities;

      }
    });
  }
  onPatientSelect($event, patientRoundingSheet) {
    console.log($event, patientRoundingSheet);
    this.addPatientToPatientRoundingSheet(patientRoundingSheet._id, $event._id);  
    
  }
  onSelectAllPatients($event, patientRoundingSheet) {
    console.log($event, patientRoundingSheet);
    
  }
  onPatientDeSelect($event, patientRoundingSheet) {
    console.log($event, patientRoundingSheet);
    this.removePatientFromPatientRoundingSheet(patientRoundingSheet._id, $event._id);      
  }
  showPatientRoundingSheetDialog() {
    const dialogRef = this.dialog.open(PatientRoundingSheetDialog, {width: '50%'});
    dialogRef.afterClosed().subscribe(async (data) => {
      this.initPatientRoundingSheets();
    });
  }
  showMultiplePatientsAddDialog(patientRoundingSheet) {
    const dialogRef = this.dialog.open(MultiplePatientsAddDialog, {width: '50%', data: {patientRoundingSheet}});
    dialogRef.afterClosed().subscribe(async (patients) => {

      if(Array.isArray(patients)) {
        patientRoundingSheet.patientList = patients;
        // this.initPatientRoundingSheets()
      }
    });
  }
  addPatientToPatientRoundingSheet(patientRoundingSheetId, patientId) {
    const details = {
      ...this.currentUserDetails,
      patientRoundingSheetId:patientRoundingSheetId,
      patientId:patientId,
    }
    const infoToast = this.toastr.info('Adding', 'Please wait', {disableTimeOut: true});

    this._patientRoundingSheetService.addPatientToPatientRoundingSheet(details).subscribe((response:any) => {
      if(response.status === 200) {
        this.toastr.success('Patient added Successfully','Success');
      } else {
        this.toastr.warning(response.message);
      }
    }, 
    () => this.toastr.error('Something went wrong adding patient', 'Failed'),
    () => this.toastr.clear(infoToast.toastId))}
  
    removePatientFromPatientRoundingSheet(patientRoundingSheetId, patientId) {
      const details = {
        ...this.currentUserDetails,
        patientRoundingSheetId:patientRoundingSheetId,
        patientId:patientId,
      }
      const infoToast = this.toastr.info('Removing', 'Please wait', {disableTimeOut: true});
  
      this._patientRoundingSheetService.removePatientFromPatientRoundingSheet(details).subscribe((response:any) => {
        if(response.status === 200) {
          this.toastr.success('Patient removed Successfully','Success');
        }
      }, 
      () => this.toastr.error('Something went wrong removing patient', 'Failed'),
      () => this.toastr.clear(infoToast.toastId))
    }

    
    deletePatientRoundingSheet(patientRoundingSheetId) {
      const details = {
        ...this.currentUserDetails,
        sheetid: patientRoundingSheetId
      }
      const infoToast = this.toastr.info('Deleting Patient Census', 'Please wait', {disableTimeOut: true});
  
      this._patientRoundingSheetService.deletePatientRoundingSheet(details).subscribe((response:any) => {
        if(response.status === 200) {
          this.toastr.success('Patient Census removed Successfully','Success');
          this.initPatientRoundingSheets();
        }
      }, 
      () => this.toastr.error('Something went wrong removing Patient Census', 'Failed'),
      () => this.toastr.clear(infoToast.toastId))
    }
    sortData(sort: Sort) {
      const data = this.patientRoundingSheets.slice();
    console.log('sortData', sort);
    
      this.patientRoundingSheets = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
            case 'name': 
              const momentDateA = moment(a.name, 'MMM D, YYYY', true);
              const momentDateB = moment(b.name, 'MMM D, YYYY', true);
              return compare(momentDateA.isValid() ? momentDateA.get('milliseconds') : a.name, momentDateB.isValid() ? momentDateB.get('milliseconds') : b.name, isAsc);

            case 'facility': return compare(a.facility?.title, b.facility?.title, isAsc);

            case 'date': return compare(new Date(a.date).getTime(), new Date(b.date).getTime(), isAsc);           

            case 'patient_count': return compare(a.patientList.length, b.patientList.length, isAsc);           

            default: return 0;
        }
      });
      this.dataSource = new MatTableDataSource(this.patientRoundingSheets);      
    }
    
    handleFilterFacilityChange() {
      this.initPatientRoundingSheets(this.filter_facility_id && this.filter_facility_id)
    }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  if(typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  }
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
