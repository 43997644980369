import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { UserService } from 'src/app/services/Modules/user.service';
import { USER_TYPE } from 'src/app/includes/global';
import { IcdCodesService } from 'src/app/services/Modules/icd-codes.service';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-add-icd-to-provider',
  templateUrl: './add-icd-to-provider.component.html',
  styleUrls: ['./add-icd-to-provider.component.css']
})

export class AddIcdToProviderComponent implements OnInit {
  providers: any = [];
  selectedProvider;
  providertoShare: any;
  currentUser: any;
  providerDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'full_name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 10,
    allowSearchFilter: true,
    enableCheckAll: true,
  };
  sharedProviders: any = [];
  companies: any;
  company_id: any;
  selectedCompany: any;
  searchCompanyText: "";
  
  constructor(public dialogRef: MatDialogRef<AddIcdToProviderComponent>,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrService,
    private companyService : CompanyService,
    private userService: UserService,
    private _ICDcodesService: IcdCodesService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.currentUser = this.authService.currentUser._id;
  }

  async ngOnInit() {
    this.company_id = this.currentUser.company_id;
    await this.initAssociatedCompanies();
  }
  
  onCompanyChange($event) {
    this.selectedCompany = $event.value;
    this.getProviders();
  }

  async initAssociatedCompanies() {
    let query = {
      adminId: this.currentUser._id,
      parentCompanyId: this.data.company_id
    }
    this.companyService.getAllAssosiatedCompaniesWithCount(query).subscribe(async (response: any) => {
      if (response.status == 200) {
        this.companies = response.data;
        this.selectedCompany = this.companies[0]._id
        await this.getProviders();
      }
      else {
        this.toastr.error(response.message, "ERROR");
      }

    })
  }

  async getProviders() {
    const filter = {
      is_activated: 'true',
      user_type: USER_TYPE.DOCTOR,
      company_id: this.selectedCompany
    }

    const projection = {
      'account_type': 1,
      'first_name': 1,
      'last_name': 1,
      'title': 1,
      'product_owner': 1,
      'full_name': 1
    }

    const companyUsers: any = await lastValueFrom(this.userService.getUsers(filter, projection));
    if (companyUsers.status === 200) {
      this.providers = companyUsers.data;
      if (Array.isArray(this.providers) && this.providers.length > 0)
        this.providers.sort((a, b) => a.full_name.localeCompare(b.full_name));
    }

  }

  onProviderSelect($event) {
    if (this.sharedProviders.indexOf($event._id) === -1) {
      this.sharedProviders.push($event._id);
    }
  }
  onProviderDeselect($event) {
    const index = this.sharedProviders.findIndex(providerId => providerId === $event._id);
    if (index !== -1) {
      this.sharedProviders.splice(index, 1);
    }
  }

  shareIcds() {
    this.data.provider_ids_ref = this.sharedProviders;
    this.data.assoc_company_id = this.selectedCompany;

    let query = { provider_ids_ref: this.data.provider_ids_ref, company_id: this.data.company_id, icd_codes: this.data.icd_codes};

    let res: any = this._ICDcodesService.createIcdProviderAssociation(query).subscribe((response: any) => {
      if (response.status == 200)
        this.toastr.success(response.message, "Success");
      else
        this.toastr.error(response.message, "ERROR");

    this.data.icd_codes = [];

    })
  }
  onAllProviderSelect(providers) {
    const allProviders = providers.map(provider => provider._id);
    for (const id of allProviders) {
      if (!this.sharedProviders.includes(id)) {
        this.sharedProviders.push(id);
      }
    }
  }
  onAllProviderDeSelect($event) {
    this.sharedProviders = $event;
  }
}


