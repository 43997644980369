import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-save-report-dialog',
  templateUrl: './save-report-dialog.component.html',
  styleUrls: ['./save-report-dialog.component.css']
})
export class SaveReportDialogComponent implements OnInit {

  title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SaveReportDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  closeDialog(is_favourite) {
    this.dialogRef.close({
      is_favourite,
      title: this.title
    });
  }

}
