import { Injectable } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import * as global from "../../includes/global";
import { API_URL } from "../../../environments/api-url";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import moment from "moment";
import * as _ from "lodash";

@Injectable({
    providedIn: "root"
})
export class DermService {
    currentUser: any;
    constructor(
        private _authService: AuthService,
        private httpClient: HttpClient
    ) {
        this.currentUser = this._authService.currentUser;
    }

    addNewDataSet(question){
        const data = {
            company_id: this.currentUser.company_id,
            question: question,
            created_by: this.currentUser._id
        }
        return this.httpClient.post(global.url + API_URL.DERM.addNewDataSet, { details: data }).pipe();
    }
    updateDataSet(dataset_id,dataset_data){
        const data ={
            dataset_id,
            dataset_data
        }
        return this.httpClient.post(global.url + API_URL.DERM.updateDataSet, { details: data }).pipe();
    }
    getAllDataSet(company_id){
        const data = {
            company_id
        }
        return this.httpClient.post(global.url + API_URL.DERM.getAllDataSet, { details: data }).pipe();
    }

}
