import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { PatientListService } from '../../patient-list/patient-list-service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DashboardService } from '../../dashboard/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { HttpResponse } from '@angular/common/http';
import * as global from '../../global';
import { API_URL } from '../../../../environments/api-url'
import {Location} from '@angular/common';
import { forIn } from 'lodash';
import { PCCService } from 'src/app/services/pcc.service';
import { lastValueFrom, of } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'app-facesheet',
  templateUrl: './facesheet.component.html',
  styleUrls: ['./facesheet.component.css']
})
export class FacesheetComponent implements OnInit {

  patient;

  patientCoverages;

  currentUser:any;
  matrixCareCoverages;
  loaderId = 'facesheet-loader';
  inprogress = false;
  secondaryIns = ''
  primaryArray = [];
  secondaryArray = [];
  primaryObj = {};
  secondaryObj = {};
  coverages  = [] 
  constructor(
    private route:ActivatedRoute,
    private patientService:PatientListService,
    private authService:AuthService,
    private loader: NgxUiLoaderService,
    private toastr:ToastrService,
    private dashboardService:DashboardService,
    private _location: Location,
    private pccService: PCCService
  ) { 
    this.currentUser = this.authService.currentUser;
    
    
  }
  goBack() {
    this._location.back();
}

  async ngOnInit() {
    const patient_id = this.route.snapshot.params.id;

    await this.syncCoveragesData(patient_id);
    // debugger
    // await this.initPatient(patient_id);
  }

  async initPatient(patient_id) {
    this.loader.startLoader(this.loaderId);

    const filter = {
      _id:  patient_id
    }
 
    const patientResponse: any = await this.patientService.getPatientDetail(filter).toPromise();
    if(patientResponse.status === 200) {
      this.patient = patientResponse.data;
      if(this.patient.source == 'MatrixCare'){
        this.patientCoverages = this.patient.matrix_payload.coverages
        // if(!this.patient.matrix_payload.coverages || this.patient.matrix_payload.coverages.length < 0){
        //   let response:any = await this.patientService.getInsuranceInfo(patient_id).toPromise();
        //   if(response.status == 200){
        //     this.matrixCareCoverages = response.data;
            
        //     for (const key in this.matrixCareCoverages) {
        //       if (Object.prototype.hasOwnProperty.call(this.matrixCareCoverages, key)) {
        //         if(key.includes('p_')){ 
        //           let colName = key.replace('p_', '');
        //           this.primaryArray.push(  { [colName]: this.matrixCareCoverages[key], isPrimary: key.includes('p_') })
        //         }
        //         if(key.includes('s_')){ 
        //           let colName = key.replace('s_', '');
        //           this.secondaryArray.push(  { [colName]: this.matrixCareCoverages[key], isPrimary: key.includes('p_') })
        //         }
                
        //       }
        //     }
        //     this.primaryObj = {...this.primaryArray}
        //     this.secondaryObj = {...this.secondaryArray} 
            
        //     this.coverages.push([...this.primaryArray], [...this.secondaryArray])
        //   }
        // }
        // else{
        //   this.patientCoverages = this.patient.matrix_payload.coverages
        // }
      }
      this.loader.stopLoader(this.loaderId);
    }
  }

  
  formatPatientAddress(address) {
    const {country, ...restAddress} = address
    return Object.values(restAddress).join(', ') + ', ' + country;
  }
  formatPolicyNumber(coverage_data, payerRank) {
    if(!coverage_data)  {
      return 'N/A';
    }
    // 
    const payer = coverage_data[payerRank];
    if(payer?.issuer?.planNumber) {
      return payer.issuer.planNumber;
    }
    return 'N/A';
  }
  formatInsuranceName(coverage_data, payerRank) {
    if(!coverage_data)  {
      return 'N/A';
    }
    // 
    const payer = coverage_data[payerRank];
    if(payer) {
      return payer.payerName;
    }
    return 'N/A';
  }
  raw() {
    return JSON.stringify(this.patient, null, 4);
  } 

  getPatientGender(gender){
    if(gender == 'F' || gender == 'FEMALE')
    {
      return 'FEMALE'
    }
    else if(gender == 'M' || gender == 'MALE')
    {
      return 'MALE'
    }
    else{
      return 'UNKNOWN'
    }
  }
  getPolicyNumber(type){   
    let obj;
    if(this.patientCoverages){
      if(type == 'Primary'){
        obj = this.patientCoverages[0]? this.patientCoverages[0] : null 
     }
     else if(type == 'Secondary'){
      obj = this.patientCoverages[1]? this.patientCoverages[1] : null 
     }
     else if(type == 'Tertiary'){
      obj = this.patientCoverages[2]? this.patientCoverages[2] : null 
     }
     for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if(key == 'insurance_policy_number'){
          return obj[key]? obj[key]: 'N/A'
        } 
      }
     }
    }
    else{
      return 'N/A'
      // if(type == 'Primary'){
      //    obj = this.primaryArray
      // }
      // else if(type == 'Secondary'){
      //    obj = this.secondaryArray
      // }
      // else if(type == 'Tertiary'){
      //    return 'N/A'
      // }
      // for (const iterator of obj) {
      //   for (const key in iterator) {  
      //     if(key == 'insurancePolicyNumber'){
      //       return iterator[key]? iterator[key]: 'N/A'
      //     }
      //   } 
      // } 
    }
  } 
  
  getAdmissionDate(patient) {
    // return this.last_admission_date || this.pcc_payload?.admissionDate
    if(patient.last_admit_date_obj){
      let year=patient.last_admit_date_obj.year;
      let month=patient.last_admit_date_obj.month;
      let day=patient.last_admit_date_obj.day;
      if (month < 10) {month = '0' + month;}
      if (day < 10) {day = '0' + day;}
      return (month+'/'+day+'/'+year);
    }else if(patient.last_admit_date) {
      return moment.tz(patient.last_admit_date, "UTC").format('MM/DD/YYYY');
    } else {
      return patient.pcc_payload?.admissionDate ? moment.tz(patient.pcc_payload?.admissionDate, "UTC").format('MM/DD/YYYY'):'';
    }
}

  getInsuranceCompany(type, field){ 
    let obj;
    if(this.patientCoverages){
      if(type == 'Primary'){
          for(let i =0 ; i < this.patientCoverages.length; i++){
            if(this.patientCoverages[i].insurance_type == 'primary'){
              obj =  this.patientCoverages[i]? this.patientCoverages[i] : null ;
            }
            if(obj) break;
          }
          if(field == 'company'){
              return (obj && obj.insurance_company_name)? obj.insurance_company_name : 'N/A'
          }
          else if(field == 'policy'){
              return (obj && obj.insurance_policy_number)? obj.insurance_policy_number : 'N/A'
          }
          // obj = this.patientCoverages[0]? this.patientCoverages[0] : null 
      }
      else if(type == 'Secondary'){
        for(let i =0 ; i < this.patientCoverages.length; i++){
          if(this.patientCoverages[i].insurance_type != 'primary' ){
            obj =  this.patientCoverages[i]? this.patientCoverages[i] : null ;
          }
          if(obj) break;
          }
          if(field == 'company'){
              this.secondaryIns = (obj && obj.insurance_company_name)? obj.insurance_company_name : 'N/A'
              console.log("this.secondaryIns: ", this.secondaryIns);
              return (obj && obj.insurance_company_name)? obj.insurance_company_name : 'N/A'
          }
          else if(field == 'policy'){
              return (obj && obj.insurance_policy_number)? obj.insurance_policy_number : 'N/A'
          }
        // obj = this.patientCoverages[1]? this.patientCoverages[1] : null 
      }
      else if(type == 'Tertiary' ){
        for(let i =0 ; i < this.patientCoverages.length ; i++){
          if(this.patientCoverages[i+1] && this.patientCoverages[i+1].insurance_company_name != this.secondaryIns){
            if(this.patientCoverages[i+1].insurance_type != 'primary'){
              obj =  this.patientCoverages[i+1]? this.patientCoverages[i+1] : null ;
            }
            if(obj) break;
            }
          else{
            obj = null
          }
        } 
        if(field == 'company'){
          return (obj && obj.insurance_company_name)? obj.insurance_company_name : 'N/A'
          }
          else if(field == 'policy'){
              return (obj && obj.insurance_policy_number)? obj.insurance_policy_number : 'N/A'
          }
      }
    }



    // let obj;
    // if(this.patientCoverages){
    //   if(type == 'Primary'){
    //     for(let i =0 ; i < this.patientCoverages.length; i++){
    //       if(this.patientCoverages[i].insurance_type == 'primary'){
    //         obj =  this.patientCoverages[i]? this.patientCoverages[i] : null ;
    //       }
    //       if(obj) break;
    //     }
    //     // obj = this.patientCoverages[0]? this.patientCoverages[0] : null 
    //  }
    //  else if(type == 'Secondary'){
    //   for(let i =0 ; i < this.patientCoverages.length; i++){
    //     if(this.patientCoverages[i].insurance_type != 'primary'){
    //       obj =  this.patientCoverages[i]? this.patientCoverages[i] : null ;
    //     }
    //     if(obj) break;
    //   }
    //   // obj = this.patientCoverages[1]? this.patientCoverages[1] : null 
    //  }
    //  else if(type == 'Tertiary'){
    //   for(let i =0 ; i < this.patientCoverages.length ; i++){
    //     if(this.patientCoverages[i+1]){
    //       if(this.patientCoverages[i+1].insurance_type != 'primary'){
    //         obj =  this.patientCoverages[i+1]? this.patientCoverages[i+1] : null ;
    //       }
    //       if(obj) break;
    //       }
    //     else{
    //       obj = null
    //     }
    //   }
        
    //   // obj = this.patientCoverages[2]? this.patientCoverages[2] : null 
    //  }
    //  for (const key in obj) {
    //   if (Object.prototype.hasOwnProperty.call(obj, key)) {
    //     if(key == 'insurance_company_name'){
    //       return obj[key]
    //     } 
    //   }
    //  }
    // }
    // else{
    //   return 'N/A'
    //   // if(type == 'Primary'){
    //   //    obj = this.primaryArray
    //   // }
    //   // else if(type == 'Secondary'){
    //   //    obj = this.secondaryArray
    //   // }
    //   // else if(type == 'Tertiary'){
    //   //    return 'N/A'
    //   // }
    //   // for (const iterator of obj) {
    //   //   for (const key in iterator) {  
    //   //     if(key == 'insurance_company_name'){
    //   //       return iterator[key]? iterator[key]: 'N/A'
    //   //     }
    //   //   } 
    //   // } 
    // }
  } 
  
  generateFacesheetAndDownload(patient_id) {
    // const infoToastr: any = this.toastr.info('Generating PDF', 'Please wait...');
    if(this.patient.source == 'MatrixCare'){
      this.generateFacesheetForMatrixAndDownloadAsPDF(patient_id)
    }
    else{
      this.generateFacesheetAndDownloadAsPDF(patient_id)
    } 
  }

generateFacesheetAndDownloadAsPDF(patient_id) {
    const infoToastr: any = this.toastr.info('Generating PDF', 'Please wait...'); 
    try {
        const params = { 
            patient_id
        }
        return this.dashboardService.downloadFile(global.url + API_URL.PATIENT.generateFacesheetAndDownloadAsPDF, params).subscribe((res: any) => {
            if (res instanceof HttpResponse) {

                var url = window.URL.createObjectURL(res.body);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = 'facesheet.pdf';
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
                this.toastr.clear(infoToastr.toastId);
            } else {
                if (res.type === 0) {
                } else if (res.type === 3) {
                    const { loaded, total } = res;
                    const progress = Math.round(loaded / total * 100);
                    if (progress > 0) {
                    }
                }
            }
            if (res.status == 200) {
                return true;
            }
        })
    } catch (error) {
        const { message = 'Something went wrong, please try again' } = error;
        this.toastr.error(message);
    } finally {
        this.toastr.clear(infoToastr.toastId);
    }
}

async syncCoveragesData(patientId) {
  this.loader.startLoader(this.loaderId);
  const details = {
    ...this.authService.authObject,
    patientId,
  }
    const {status, data} =  await lastValueFrom(this.pccService.syncCoveragesData({details})) as any;
    if(status === 200) {
      this.patient = data;
      if(this.patient.source == 'MatrixCare'){
        this.patientCoverages = this.patient.matrix_payload.coverages
        // if(!this.patient.matrix_payload.coverages || this.patient.matrix_payload.coverages.length < 0){
        //   let response:any = await this.patientService.getInsuranceInfo(patient_id).toPromise();
        //   if(response.status == 200){
        //     this.matrixCareCoverages = response.data;
            
        //     for (const key in this.matrixCareCoverages) {
        //       if (Object.prototype.hasOwnProperty.call(this.matrixCareCoverages, key)) {
        //         if(key.includes('p_')){ 
        //           let colName = key.replace('p_', '');
        //           this.primaryArray.push(  { [colName]: this.matrixCareCoverages[key], isPrimary: key.includes('p_') })
        //         }
        //         if(key.includes('s_')){ 
        //           let colName = key.replace('s_', '');
        //           this.secondaryArray.push(  { [colName]: this.matrixCareCoverages[key], isPrimary: key.includes('p_') })
        //         }
                
        //       }
        //     }
        //     this.primaryObj = {...this.primaryArray}
        //     this.secondaryObj = {...this.secondaryArray} 
            
        //     this.coverages.push([...this.primaryArray], [...this.secondaryArray])
        //   }
        // }
        // else{
        //   this.patientCoverages = this.patient.matrix_payload.coverages
        // }
      }
      this.loader.stopLoader(this.loaderId);
    }
}

generateFacesheetForMatrixAndDownloadAsPDF(patient_id) {
    const infoToastr: any = this.toastr.info('Generating PDF', 'Please wait...');
    try {
        const params = { 
            source: 'MatrixCare',
            patient_id
        }
        return this.dashboardService.downloadFile(global.url + API_URL.PATIENT.generateFacesheetForMatrixAndDownloadAsPDF, params).subscribe((res: any) => {
            if (res instanceof HttpResponse) {

                var url = window.URL.createObjectURL(res.body);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = 'facesheet.pdf';
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
                this.toastr.clear(infoToastr.toastId);
            } else {
                if (res.type === 0) {
                } else if (res.type === 3) {
                    const { loaded, total } = res;
                    const progress = Math.round(loaded / total * 100);
                    if (progress > 0) {
                    }
                }
            }
            if (res.status == 200) {
                return true;
            }
        })
    } catch (error) {
        const { message = 'Something went wrong, please try again' } = error;
        this.toastr.error(message);
    } finally {
        this.toastr.clear(infoToastr.toastId);
    }
}
}
