import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-formula-builder',
  templateUrl: './formula-builder.component.html',
  styleUrl: './formula-builder.component.css'
})
export class FormulaBuilderComponent implements OnInit {
  @Input() inputFields;
  @Input() formulaInput: String;
  @Output() formulaOutput: EventEmitter<any> = new EventEmitter();

  labels: string[] = [];
  operations: { symbol: string, displayName: string }[] = [
    { symbol: '+', displayName: 'Add' },
    { symbol: '-', displayName: 'Subtract' },
    { symbol: '*', displayName: 'Multiply' },
    { symbol: '/', displayName: 'Divide' }
  ];
  formula: string = "";
  error: string | null = null;
  constructor() { }



  ngOnInit(): void {
    if(this.formulaInput) {
      this.formula = this.formulaInput.toString() || '';
    }
    if(this.inputFields) {
      this.labels = this.inputFields.map(field => field.value);
    }
  }

  insertLabel(label: string) {
    this.formula += label;
  }

  insertOperation(operation: string) {
    this.formula += ` ${operation} `;
  }

  evaluate() {
    const { error } = this.evaluateFormula(this.formula);
    this.error = error;
  }

  clear() {
    this.formula = '';
    this.error = null;
  }
  evaluateFormula(formula: string): { result: string | null, error: string | null } {
    // Validate that all labels in the formula are recognized
    const invalidLabels = this.findInvalidLabels(formula);
    if (invalidLabels.length > 0) {
      return { result: null, error: `Unrecognized labels: ${invalidLabels.join(', ')}` };
    }
    try {
      // Check if the formula is valid JavaScript
      if (this.isValidFormula(formula)) {
        this.formulaOutput.emit(formula);
        return { result: formula, error: null };
      } else {
        return { result: null, error: 'Invalid formula syntax.' };
      }
    } catch (e) {
      return { result: null, error: 'Error evaluating formula.' };
    }
  }

  private findInvalidLabels(formula: string): string[] {
    // Extract labels from the formula
    const regex = /\b[a-zA-Z_][a-zA-Z0-9_]*\b/g;
    const matches = formula.match(regex) || [];
    return matches.filter(label => !this.labels.includes(label));
  }

  isValidFormula(formula: string): boolean {
    // Remove all whitespaces
    formula = formula.replace(/\s+/g, '');
    // Regex to allow numbers, operators, parentheses, and alphabetic labels
    const validStructure = /^[0-9+\-*/().a-zA-Z]*$/;
    if (!validStructure.test(formula)) {
      return false;
    }
    // Check for balanced parentheses
    let balance = 0;
    for (const char of formula) {
      if (char === '(') balance++;
      if (char === ')') balance--;
      if (balance < 0) return false; // More closing than opening
    }
    if (balance !== 0) return false; // Unbalanced parentheses
  
    // Check for valid operator placement
    const invalidOperators = /[+\-*/]{2,}|[+\-*/][\(\)]|[\(\)][+\-*/]/;
    if (invalidOperators.test(formula)) {
      return false;
    }
    // Check if the formula ends with an operator or has trailing operators
    const trailingOperators = /[+\-*/]$/;
    if (trailingOperators.test(formula)) {
        return false;
    }
    
    // Check if the formula starts with an operator (except for unary operators at the start)
    const leadingOperators = /^[+\-*/]/;
    if (leadingOperators.test(formula)) {
        return false;
    }
  
    return true;
  }
  
}
