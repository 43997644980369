<div class="flex"  *ngIf="providerPhrase">
    <app-input-with-dropdown
            class="flex-grow"
            [noDropdown]="true"
            placeholder="Search Provider"
            (inputChange)="applyFilter($event.target.value)">
    </app-input-with-dropdown>
</div>
<div style="height: 10px;"></div>
<div style="height: 70vh;overflow: auto;">
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="providers">
            <th mat-header-cell *matHeaderCellDef style="width: 20%;">Provider</th>
            <td mat-cell *matCellDef="let element">{{element.full_name}}</td>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </ng-container>

        <ng-container matColumnDef="phrases">
            <th mat-header-cell *matHeaderCellDef> Phrases </th>
            <td mat-cell *matCellDef="let element" style="width: 60%">
                <app-mat-multi-select-chips [element]="element" [ngModelData]="element.assoc_phrase_ids || []" [isMulti]="true" [isPhrase]="true" [allData]="element.phrases || []" [providerKey]="'assoc_phrase_ids'" [selectAll]="false" (onDataSelection)="onDataSelection($event)" (onDataRemove)="onDataRemove($event)"></app-mat-multi-select-chips> 
            </td>
        </ng-container>

    </table>
</div>
