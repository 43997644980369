import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { AuthService } from 'src/app/services/auth.service';
import { CKEditorService } from '../note-editor/ckeditor.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmailTemplateService } from '../email-template/service/email-template.service';


@Component({
  selector: 'app-send-email-template-dialog',
  templateUrl: './send-email-template-dialog.component.html',
  styleUrls: ['./send-email-template-dialog.component.css']
})
export class SendEmailTemplateDialogComponent implements OnInit {
  @ViewChild('emailIdInput') emailIdInput: ElementRef<HTMLInputElement>;
  @ViewChild('ccEmailIdInput') ccEmailIdInput: ElementRef<HTMLInputElement>;
  @ViewChild('bccEmailIdInput') bccEmailIdInput: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];


  model: any = {
    subject: null,
    emailIds: null,
    ccEmailIds: null,
    emailBody: null,
  };
  email;
  cke4Config;
  emailIds: any = [];
  ccEmailIds: any = [];
  bccEmailIds: any = [];
  filteredEmailTempaltes: Observable<string[]>;
  emailIdCtrl = new FormControl();
  ccEmailIdCtrl = new FormControl();
  bccEmailIdCtrl = new FormControl();
  emailTemplateControl = new FormControl();
  project = {};
  filter = {};
  emailTemplates = [];
  isAutomated: Boolean = false;
  emailId: any;
  isOneTimeEmail: Boolean = false;
  constructor(
    private ckeditorService: CKEditorService,
    private _authService: AuthService,
    private dialogRef: MatDialogRef<SendEmailTemplateDialogComponent>,
    private _emailTemplateService: EmailTemplateService,
    private _toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) {
    this.cke4Config = {
      ...this.ckeditorService.getDefaultConfig(),
      editable: true
    };
    if(this.data?.isOneTimeEmail) {   //For One Time email
      this.emailId = this.data.emailId;
      this.isOneTimeEmail = this.data.isOneTimeEmail;
      this.cke4Config.editable = false;
    }
    else if (this.data) {   //For Automatic Email
      this.emailId = this.data;
    }
  }

  ngOnInit(): void {
    this.getEmailTemplate();
    this.filteredEmailTempaltes = this.emailTemplateControl.valueChanges.pipe(
      startWith(''),
      map((value:any) => this.filterEmailTemplates(value)));
    if (this.emailId) {
      this.getEmail();
    }

  }

  getEmailTemplate() {
    // return new Promise<void>((resolve, reject) => {
    this._emailTemplateService.getEmailTemplate(this.filter, this.project).subscribe((response: any) => {
      if (response.status == 200) {
        this.emailTemplates = response.data;
        // resolve(null);
      }
    }, err => {
      // reject();
      this._toastr.error(err, "ERROR");
    }
    );
    // });
  }

  getEmail() {
    const filter = {
      _id: this.emailId
    };
    const project = {};
    this._emailTemplateService.getEmail(filter, project).subscribe((response: any) => {
      if (response.status == 200) {
        const { email_id, cc_email_id, bcc_email_id, duration, is_automatic, subject, body } = response.data;
        this.model = {
          subject,
          emailBody: body,
          isAutomatic: is_automatic,
          duration
        };
        this.emailIds = email_id;
        this.ccEmailIds = cc_email_id;
        this.bccEmailIds = bcc_email_id;
      }
    }, err => {
      this._toastr.error(err, "ERROR");
    }
    );
  }

  onSelectEmailTemplate(emailTemplateId) {
    if (emailTemplateId !== "none") {
      const selectedTemplate = this.emailTemplates.filter(ele => ele._id === emailTemplateId)[0];
      this.model.emailBody = selectedTemplate.email_body;
      this.model.subject = selectedTemplate.subject;
    }
  }
  private filterEmailTemplates(value: string): string[] {
    if (value && this.emailTemplates.length > 0) {
      const filterValue = value.toLowerCase();
      return this.emailTemplates.filter(option => (option.title.toLowerCase().indexOf(filterValue) === 0));
    }
    else {
      return this.emailTemplates;
    }
  }

  addEmailIdAsChip(event: MatChipInputEvent): void {
    const input = event.input;
    const value: any = event.value;

    this.emailIds.push(value);

    if (input) {
      input.value = '';
    }

    this.emailIdCtrl.setValue(null);
  }

  addCCEmailIdAsChip(event: MatChipInputEvent): void {
    const input = event.input;
    const value: any = event.value;
    this.ccEmailIds.push(value);

    if (input) {
      input.value = '';
    }

    this.ccEmailIdCtrl.setValue(null);
  }

  addBCCEmailIdAsChip(event: MatChipInputEvent): void {
    const input = event.input;
    const value: any = event.value;
    this.bccEmailIds.push(value);

    if (input) {
      input.value = '';
    }

    this.bccEmailIdCtrl.setValue(null);
  }
  removeEmailIdAsChip(emailId: any): void {
    const index = this.emailIds.indexOf(emailId);
    if (index >= 0) {
      this.emailIds.splice(index, 1);
    }
  }

  removeCCEmailIdAsChip(ccEmailId: any): void {
    const index = this.ccEmailIds.indexOf(ccEmailId);
    if (index >= 0) {
      this.ccEmailIds.splice(index, 1);
    }
  }

  removeBCCEmailIdAsChip(bccEmailId: any): void {
    const index = this.bccEmailIds.indexOf(bccEmailId);
    if (index >= 0) {
      this.bccEmailIds.splice(index, 1);
    }
  }


  handleEmailIdInputFocus(event) {
    this.emailIdCtrl.setValue(event.target.value);
  }

  handleCCEmailIdInputFocus(event) {
    this.ccEmailIdCtrl.setValue(event.target.value);
  }

  handleBCCEmailIdInputFocus(event) {
    this.bccEmailIdCtrl.setValue(event.target.value);
  }
  selectedEmailIdAsChip(event: MatAutocompleteSelectedEvent): void {
    // this.emailIds.push(event.option.value);

    this.emailIdInput.nativeElement.value = '';
    this.emailIdCtrl.setValue(null);
  }

  selectedCCEmailIdAsChip(event: MatAutocompleteSelectedEvent): void {
    // this.emailIds.push(event.option.value);

    this.ccEmailIdInput.nativeElement.value = '';
    this.ccEmailIdCtrl.setValue(null);
  }

  selectedBCCEmailIdAsChip(event: MatAutocompleteSelectedEvent): void {
    // this.emailIds.push(event.option.value);

    this.bccEmailIdInput.nativeElement.value = '';
    this.bccEmailIdCtrl.setValue(null);
  }

  handleChangeUserType(userType: string) {
    this.model.userType = userType;
  }

  handleDurationChange(duration: string) {
    this.model.duration = duration;
  }

  handleCheckboxChange($event) {
    if ($event.checked) {
      this.model.isAutomatic = $event.checked;
    }
    else {
      delete this.model.isAutomatic;
      delete this.model.duration;
    }
  }

  submitForm() {
    this.model.emailIds = this.emailIds;
    this.model.ccEmailIds = this.ccEmailIds;
    if (this.emailId) {
      this.model.emailId = this.emailId;
    }
    this.model.emailId = this.model.emailId;
    this._emailTemplateService.sendEmailTemplate(this.model).subscribe((response: any) => {
      if (response.status == 200) {
        if (this.emailId) {
          this._toastr.success("Email Updated", "Success");
        }
        else {
          this._toastr.success("Email Sent", "Success");
        }
        this.dialogRef.close(response.data);
      }
    }, err => {
      this._toastr.error(err, "ERROR");
    }
    );
  }
}
