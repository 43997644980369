<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>

<div *ngIf="isShowTable">
    This Tag is Associated with these patient(s)
    <ng-container>
        <div style="margin-top: 2rem;">
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Patient Name</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.name }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </ng-container>

</div>

<div class="flex justify-center gap-4" style="margin-top: 25px;">
    <div>Are you sure you want to delete this Tag?</div>
    <button mat-raised-button class="dn-button-secondary" type="button" (click)="closeDialog()">
        No
    </button>
    <button mat-raised-button class="dn-button-primary" type="button" (click)="deleteTag()">
        Yes
    </button>
</div>