<div class="flex flex-col gap-5 heading-settings">
    <div class="flex items-center justify-between gap-2">
        <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
            Back
        </button>
        <button mat-raised-button class="dn-button-primary activation-email-btn"
            (click)="openDialog();">Compose</button>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

        <ng-container matColumnDef="recipient">
            <th mat-header-cell *matHeaderCellDef style="width: 20%; text-align: center;"> Recipient </th>
            <td mat-cell *matCellDef="let element; let i = index;" style="width: 20%; text-align: center;">
                {{element?.recipient}}
            </td>
        </ng-container>

        <ng-container matColumnDef="regarding">
            <th mat-header-cell *matHeaderCellDef style="width: 20%; text-align: center;"> Re </th>
            <td mat-cell *matCellDef="let element; let i = index;" style="width: 20%; text-align: center;">
                {{element?.regarding}}
            </td>
        </ng-container>

        <ng-container matColumnDef="provider_name">
            <th mat-header-cell *matHeaderCellDef style="width: 20%; text-align: center;"> Provider </th>
            <td mat-cell *matCellDef="let element; let i = index;" style="width: 20%; text-align: center;">
                {{element?.provider_id_ref.last_name}} {{element?.provider_id_ref.first_name}},
                {{element?.provider_id_ref.title}}
            </td>
        </ng-container>

        <ng-container matColumnDef="company_name">
            <th mat-header-cell *matHeaderCellDef style="width: 20%; text-align: center;"> Company </th>
            <td mat-cell *matCellDef="let element; let i = index;" style="width: 20%; text-align: center;">
                {{element?.company_id_ref?.name}}
            </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef style="width: 20%; text-align: center;"> Date </th>
            <td mat-cell *matCellDef="let element; let i = index;" style="width: 20%; text-align: center;">
                {{element?.createdAt | date : 'MMM d, y, h:mm a' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef style="width: 20%; text-align: center;"> Action </th>
            <td mat-cell *matCellDef="let element; let i = index;" style="width: 20%; text-align: center;">
                <span class="material-icons" matTooltip="View" matTooltipPosition="above"
                (click)="openDialog(element._id)" style="cursor: pointer;">
                remove_red_eye
            </span>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>