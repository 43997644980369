<div style="position: relative;min-height: 100px;" class="wound-list flex flex-col gap-1">
    <ng-container *ngFor="let wound of filterWounds(wounds, woundState); let i = index">
        <app-wound-care-widget-item #latestWoundChild *ngIf="i === 0" [provider]="provider" [patient]="patient" [readOnlyMode]="readOnlyMode"
            [wound]="wound" [isPreview]="isPreview" [note]="note" [created_userType]="created_userType"
            [configuration]="configuration" [companyPermissions]="companyPermissions" (changeWoundState)="removeWoundfromList($event)"
            (woundAnalyticReport)="showWoundReport($event)" (onICDsSave)="handleICDsSave($event)"
            (woundDetailsChange)="handleWoundDetailsChange($event)" (procedureNoteDetailsChange)="handleProcedureNoteDetailsChange($event)"  [should_show_view_icon]="should_show_view_icon"
            (refreshAllWounds)="refreshWoundsandCheckWoundsinNoteEditor($event)" [skinSubRefferal]="skinSubRefferal"
            (checkallWoundAssessmentPhrase)="hanldeCheckallWoundAssessmentPhrase($event)"></app-wound-care-widget-item>
        <app-wound-care-widget-item *ngIf="i !== 0" [provider]="provider" [patient]="patient" [readOnlyMode]="readOnlyMode"
            [wound]="wound" [isPreview]="isPreview" [note]="note" [created_userType]="created_userType"
            [configuration]="configuration" [companyPermissions]="companyPermissions" (changeWoundState)="removeWoundfromList($event)"  [should_show_view_icon]="should_show_view_icon"
            (woundAnalyticReport)="showWoundReport($event)" (onICDsSave)="handleICDsSave($event)"
            (woundDetailsChange)="handleWoundDetailsChange($event)" (procedureNoteDetailsChange)="handleProcedureNoteDetailsChange($event)" [skinSubRefferal]="skinSubRefferal"
            (refreshAllWounds)="refreshWoundsandCheckWoundsinNoteEditor($event)"
            (checkallWoundAssessmentPhrase)="hanldeCheckallWoundAssessmentPhrase($event)"></app-wound-care-widget-item>
    </ng-container>
    <ngx-ui-loader [loaderId]="woundsLoaderId"></ngx-ui-loader>
</div>