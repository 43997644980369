import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {CompanyService} from 'src/app/services/Modules/company.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {USER_TYPE} from '../../../../includes/global'
import {MatTableDataSource} from '@angular/material/table';
import {FacilityService} from 'src/app/services/facility.service';
import {UserService} from 'src/app/services/Modules/user.service';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';

@Component({
    selector: 'app-facility-provider-association',
    templateUrl: './facility-provider-association.component.html',
    styleUrls: ['./facility-provider-association.component.css']
})
export class FacilityProviderAssociationComponent implements OnInit {

    public response: any;
    public dataSource: any;
    public facilities: any = [];
    providers: any = [];
    providersName: any = [];
    selectedProviders: any = [];
    providerTranscriber: Boolean = true;
    providerBiller: Boolean = false;
    providerDropdownSettings: any = {};
    companySide:Boolean = false;

    displayedColumns: string[] = ['facility', 'provider'];
    @ViewChild(MatSort) sort: MatSort;

    params: {
        id: string
    }

    loaderId = 'facility-provider-association-loader';

    constructor(
        public companyService: CompanyService,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private loader: NgxUiLoaderService,
        private facilityService: FacilityService,
        private userService: UserService,
    ) {
        this.providerDropdownSettings = {
            singleSelection: false,
            idField: '_id',
            textField: 'full_name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 10,
            allowSearchFilter: true,
        };
        if(window.location.pathname.includes('company')){
            this.companySide = true
            this.params = {
                id: this.route.parent.parent.snapshot.params.id
            };
        }
        else{
            this.companySide = false
            this.params = {
                id: ''
            };
        }
    }

    async ngOnInit() { 
        this.loader.startLoader(this.loaderId);

        await this.initProviders();
        await this.initFacilities();


        this.loader.stopLoader(this.loaderId);

    }

    initFacilities = async () => {
        const company_id = this.params.id;

        const filter = {
            is_active: true,
            assoc_company_ids: company_id
        }
        const projection = {
            'title': 1,
            'address': 1,
            'city': 1,
            'state': 1,
            'zip_code': 1,
            'provider_ids_ref': 1,
            'assoc_company_ids': 1
        }
        let companyAssocPopulation: facilityPopulationInterface = {
            collection: 'companies',
            field: ["assoc_company_ids"],
            filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: [true, { $in: ['$_id', '$$assoc_company_ids'] }] }] } },
            project: { name: 1 },
        };
        const facilitiesResponse: any = await this.facilityService.getFacilities(filter, projection, [companyAssocPopulation]).toPromise();


        if (facilitiesResponse.status === 200) {
            this.facilities = facilitiesResponse.data.array;
            for (const facility of this.facilities) {
                facility.provider_ids_ref = facility.provider_ids_ref
                    .filter((facilityProvider) => {
                        if (typeof facilityProvider === 'string') {
                            return this.providers.find(provider => provider._id === facilityProvider);
                        }
                        return true;
                    }).map((facilityProvider) => {
                        if (typeof facilityProvider === 'string') {
                            return this.providers.find(provider => provider._id === facilityProvider);
                        } else {
                            return facilityProvider;
                        }
                    })

            }
            this.dataSource = new MatTableDataSource(this.facilities);
        }
    }

    initProviders = async () => {
        const company_id = this.params.id;

        const filter = {
            is_activated: 'true',
            user_type: USER_TYPE.DOCTOR,
            company_id
        }

        const projection = {
            'account_type': 1,
            'first_name': 1,
            'last_name': 1,
            'title': 1,
        }

        const companyUsersResponse: any = await this.userService.getUsers(filter, projection).toPromise();


        if (companyUsersResponse.status === 200) {
            this.providers = companyUsersResponse.data.map(p => Object.assign(p, {
                full_name: p.account_type === 'company' ?
                    `${p.first_name} ${p.last_name}, ${p.title} (company owner)` :
                    `${p.first_name} ${p.last_name}, ${p.title}`
            }));
        }
    }

    applyFilter(filterValue: string) {
        let filter = filterValue.trim().toLowerCase();
        if (filter) {
            let filterVal = [];
            this.facilities.forEach(facility => {
                if (facility.title.trim().toLowerCase().includes(filter)) {
                    filterVal.push(facility)
                    this.dataSource = new MatTableDataSource(filterVal);
                }
            });
        } else {
            this.dataSource = this.facilities;
        }
    }

    // biller-provider
    onProviderSelect(provider, facility) {
        this.updateFacilityProviders(facility);
    }

    onSelectAllProvider(providers, facility) {
        console.log({
            providers,
            facility
        });

        this.updateFacilityProviders(facility);
    }

    onDeSelectAllProvider(providers, facility) {
        console.log({
            providers,
            facility
        });

        this.updateFacilityProviders(facility);
    }

    onProviderDeSelect(provider, facility) {
        this.updateFacilityProviders(facility);
    }

    updateFacilityProviders(facility) {
        const {provider_ids_ref} = facility;
        // return this.facilityService.updateFacilityProviders(facility._id, provider_ids_ref.map(p => p._id)).toPromise()
    }
}
