<div class="flex flex-col flex-grow" >
    <b>{{title}}</b>
    <b style="font-size: 15px; margin-bottom: 13px;">Reason:</b>
            <mat-radio-group
          [(ngModel)]="reason"
          (ngModelChange)="handleChangeReason()"
          class="flex flex-col flex-grow gap-2"
          style="font-size: 12px;"
            >
            <mat-radio-button value='All treatment completed'
                >All treatment completed</mat-radio-button
            >
            <mat-radio-button value='Patient declines further treatment'
                >Patient declines further treatment</mat-radio-button
            >
            <mat-radio-button value='Facility declines referral'
            >Facility declines referral</mat-radio-button
            >
            <mat-radio-button value='Discharged home'
            >Discharged home</mat-radio-button
            >
            <mat-radio-button value='Care transferred to hospice'
            >Care transferred to hospice</mat-radio-button
            >
            <mat-radio-button value='Readmitted to hospital(non-wound related)'
            >Readmitted to hospital(non-wound related)</mat-radio-button
            >
            <mat-radio-button value='Readmitted to the hospital due to wound complication(s)'
            >Readmitted to the hospital due to wound complication(s)</mat-radio-button
            >
            <mat-radio-button value='Deceased'
                >Deceased</mat-radio-button
            >
            <mat-radio-button value='Other'
                >Other</mat-radio-button
            >
        </mat-radio-group>
        <mat-dialog-actions style="justify-content: center; margin-top: 10px;">
            <button mat-raised-button color="primary" (click)="handleClose('false')">No</button>
            <button mat-raised-button color="warn" (click)="handleClose('true')">Yes</button> &nbsp;&nbsp;
        </mat-dialog-actions>
    </div>
