import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import * as global from "src/app/includes/global";

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuard implements CanActivateChild {
  constructor(
    private _authService: AuthService,
    private _router: Router,
  ) { }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (
      Object.values(global.ADMIN_TYPE).filter(adminTypes => adminTypes !== global.ADMIN_TYPE.NONE)
        .includes(this._authService.currentUser.admin_type) || this._authService.currentUser?.product_owner) {
      return true;
    }
    else {
      this._router.navigate(['/403']);
      return false;
    }
  }

}
