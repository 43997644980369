<table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="payer">
        <th mat-header-cell *matHeaderCellDef>Payer Type</th>
        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
            {{element.payor_type}}
        </td>
    </ng-container>
    <ng-container matColumnDef="pt_soc">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">PT (SOC)</th>
        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
            {{element.pt_soc}}
        </td>
    </ng-container>
    <ng-container matColumnDef="pt_eoc">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">PT (EOC)</th>
        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
            {{element.pt_eoc}}
        </td>
    </ng-container>
    <ng-container matColumnDef="ot_soc">    
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">OT (SOC)</th>
        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
            {{element.ot_soc}}
        </td>
    </ng-container>
    <ng-container matColumnDef="ot_eoc">    
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">OT (EOC)</th>
        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
            {{element.ot_eoc}}
        </td>
    </ng-container>
    <ng-container matColumnDef="st_soc">    
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">ST (SOC)</th>
        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
            {{element.st_soc}}
        </td>
    </ng-container>
    <ng-container matColumnDef="st_eoc">    
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">ST (EOC)</th>
        <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;">
            {{element.st_eoc}}
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>