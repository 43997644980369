<div [ngClass]="{'container': companySide==true}">
    <mat-card class="vertical-height">
        <div class="flex justify-between items-center">
            <div class="flex gap-2">
                <div>
                    <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
                        Back
                    </button>
                </div>
                <h2>DNS Report</h2>
            </div>
            <div class="flex justify-end items-center gap-2">
                <mat-label style="margin-right:20px;color:#999">Date of Updation </mat-label>
                <input type="text" ngxDaterangepickerMd [(ngModel)]="filter.updationDate"
                    (datesUpdated)="changeUpdationDate($event)" class="mat-like-input date-updation" [ranges]="preDateRanges"
                    [alwaysShowCalendars]="true" [maxDate]="currentDate" [linkedCalendars]="true"
                    [showClearButton]="true" />

                <mat-form-field appearance="fill" style="width: 285px;">
                    <mat-label>Select Facility</mat-label>
                    <input type="text" placeholder="Select Facility" aria-label="facility" matInput
                        [formControl]="facilityControl" [matAutocomplete]="facilityAutoControl">
                    <mat-autocomplete #facilityAutoControl="matAutocomplete" autoActiveFirstOption>
                        <mat-option (onSelectionChange)="onSelectFacility($event, facility);"
                            *ngFor="let facility of filteredFacilities | async" [value]="facility.title">
                            {{facility.title}}
                        </mat-option>
                    </mat-autocomplete>

                </mat-form-field>

                <mat-form-field style="width: 95px;">
                    <mat-label>Select State</mat-label>
                    <mat-select [value]="filter.state" (selectionChange)="onStateChange($event.value)">
                        <mat-option value="all">All</mat-option>
                        <mat-option value="active">Active</mat-option>
                        <mat-option value="remission">Remission</mat-option>
                        <mat-option value="amputated">Amputated</mat-option>
                    </mat-select>
                </mat-form-field>

                <button mat-raised-button class="dn-button-primary" (click)="handleApplyFilter();">Apply Filter
                </button>

                <button mat-raised-button class="dn-button-secondary" (click)="resetFilter();">Reset Filter
                </button>
                <button mat-raised-button class="dn-button-primary" (click)="exportDnsReportAsXLSX();">Export
                    As XLSX
                </button>
            </div>
        </div>
        <div class="table-height">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.patientFullName}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="wound_recognition_date">
                    <th mat-header-cell *matHeaderCellDef> Date Acquired </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.wound_recognition_date}}
                    </td>
                </ng-container>


                <ng-container matColumnDef="wound_recognition_date_type">
                    <th mat-header-cell *matHeaderCellDef> POA </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.wound_recognition_date_type}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="woundNo">
                    <th mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.woundNo}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="body_part">
                    <th mat-header-cell *matHeaderCellDef> Location </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.body_part}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="etiolgy">
                    <th mat-header-cell *matHeaderCellDef> Etiology </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.etiolgy}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="stage">
                    <th mat-header-cell *matHeaderCellDef> Severity </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.stage}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="length">
                    <th mat-header-cell *matHeaderCellDef> L </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.length}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="width">
                    <th mat-header-cell *matHeaderCellDef> W </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.width}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="depth">
                    <th mat-header-cell *matHeaderCellDef> D </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.depth}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef> State </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.state}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="diffInWeek">
                    <th mat-header-cell *matHeaderCellDef> Weeks </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.days_in_treatment}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="area_change">
                    <th mat-header-cell *matHeaderCellDef> Area Change (%)</th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.area_change}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="icds">
                    <th mat-header-cell *matHeaderCellDef>DX10 Codes </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element.icds}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
            <div class="flex justify-between items-center bottom-row">
                <b>POA: {{poaCount}}</b>
                <b>Facility Acquired: {{facilityAcquiredCount}}</b>
                <b>Open: {{openWoundsCount}}</b>
                <b>In Remission: {{inRemissionWoundsCount}}</b>
                <div>
                    <button mat-raised-button class="dn-button-primary" (click)="goToPreviousPage()"
                        [disabled]="!pagination.hasPrevPage"> Prev</button>
                    <span>
                        {{pagination.skip + dnsReport.length === 0 ? 0 : pagination.skip + 1 }} - {{pagination.skip +
                        dnsReport.length}} out of {{pagination.totalRecords}}
                    </span>
                    <button mat-raised-button class="dn-button-primary" (click)="goToNextPage()"
                        [disabled]="!pagination.hasNextPage">Next</button>
                </div>
            </div>
        </div>
    </mat-card>
</div>