<mat-tab-group style="height: 100%;">
    <mat-tab style="height: 100%;" label="System Phrases">
        <ng-template matTabContent>
            <app-wound-pharases [note]="note"></app-wound-pharases>
        </ng-template>
    </mat-tab>
    <mat-tab style="height: 100%;" label="Procedure Phrases">
        <ng-template matTabContent>
            <app-procedurephrase [note]="note"></app-procedurephrase>
        </ng-template>
    </mat-tab>
</mat-tab-group>