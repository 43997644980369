import { Component, DoCheck, Input, OnInit, ViewChild } from '@angular/core';
import { BillerDashboardService } from '../biller-dashboard.service';
import { BillerProviderAssociationService } from '../../biller-provider-association/biller-provider-association.service';
import { MatSort, Sort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table"
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import moment from "moment";
import { FormGroup, FormControl } from '@angular/forms';
import { ChargesPopupComponent } from '../charges-popUp/charges-popup/charges-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from "ngx-toastr";
import { CommonService } from 'src/app/services/common.service';
import { DialogConfirmationComponent } from '../../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as global from "../../global"
import { ViewNoteDialog } from '../../transcription-create/view-note-dialog/view-note-dialog.component';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { PatientDocumentsUploadDialog } from '../../patient-documents-list/patient-documents-upload-dialog/patient-documents-upload-dialog.component';
import { FaceSheetService } from '../../patient-detail/facesheet/face-sheet.service';
import { PdfViewerPopComponent } from '../../patient-documents-list/pdf-viewer-pop/pdf-viewer-pop.component';
import swal from 'sweetalert';
import { AuditPopupComponent } from '../audit-popup/audit-popup/audit-popup.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MergePatientsService } from '../../merge-patients/merge-patients.service';
import { lastValueFrom } from 'rxjs';
import { DashboardService } from '../../dashboard/dashboard.service';
import { FileExplorerService } from '../../drive/file-explorer/file-explorer.service';
import { HttpResponse } from '@angular/common/http';
import { API_URL } from 'src/environments/api-url';
import { PCCService } from 'src/app/services/pcc.service';
import { UserService } from 'src/app/services/Modules/user.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { WoundCareWidgetService } from '../../note-sidebar/wound-care-widget/wound-care-widget.service';
@Component({
  selector: 'app-biller-charges-admin',
  templateUrl: './biller-charges-admin.component.html',
  styleUrls: ['./biller-charges-admin.component.css'],
  animations: [
    trigger('accordionTrigger', [
      state('collapsed', style({ height: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BillerChargesAdminComponent implements OnInit {

  displayedColumns = ['select', 'visit', 'time', 'create_time', "Expansion Icon", 'patient_name', 'location', 
    //  'operation',
    'audit','view', 'approve']
  displayedColumns2 = ['provider']
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('myCheckbox') myCheckbox: MatCheckbox;
  dataSource: any;
  dataSource2: any;
  loading: Boolean = true;
  firstDoctor: any;
  dataLoaded: Boolean = false;
  // loaderId1 = 'loader-01';
  currentUser: any;
  picker: any;
  filterDropdownActive = false;
  dropdownSettings: any = {};
  closeDropdownSelection = true;
  disabled = false;
  facilities: any = [];
  patientsBySearch :any = [];
  allFacilities: any;
  selectedFacility: any;
  dataToPass: any;
  selectedProvider: any;
  selectedpatients: any;
  completedCharges: any;
  allPatients: any;
  providers: any;
  selectedDOV: any;
  patientNameModel: any = '';
  DOSModel: any = '';
  facilityModel: any = '';
  Modelfacility: any;
  selectedProviderRow: any;
  chargesPopup: any;
  auditPopup: any;
  filter: Boolean = false;
  timeoutId: any;
  filters = {
    patient: '',
    dos: null,
    facility: '',
    provider: '',
    facesheetFilter: '',
    // chargeType: 'submit'
    chargeType: 'submit',
    patient_id:null
    // is_archived: false
  }
  loaderId = 'loaderp';
  filterChargesPatient: any = [];
  filterChargesDate: any = [];
  filterChargesFacility: any = [];
  calenderDate: any;
  admin: Boolean = false;
  chargesIDs = [];
  selectAllCheck = false;
  timeUp = false;
  chargesResponse: any;
  draftCount: any;
  submitCount: any;
  billedCount: any;
  companyId: any = "";
  searchText: any;
  current_provider_id: any;
  submitChargeFlag: any;
  checkbox_status = false;
  selectedBillingCompanies = []
  selectedChargeToSend = ""
  billingComapny;
  public pagination: {
    pageNo: number,
    hasNextPage: boolean,
    hasPrevPage: boolean,
    totalCount: number,
    totalPages: number
  };
  global = global;
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 1 Week': [moment().subtract(6, 'days'), moment()],
    'Last 2 Week': [moment().subtract(13, 'days'), moment()],
    'Last 3 Week': [moment().subtract(20, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  currentDate = moment(new Date());
  signedNotesStatus: any;
  selected = '';
  isBillerDashboard: boolean = false;
  @Input() chargeStatus: string;
  @Input() faceSheetStatus: string;
  @Input() searchBy: string;
  patientsByProviderControl = new FormControl();
  faceSheetFilter: string = '';
  patient_one: any;
  searchTextProvider = '';
  tabIndex: number = 0;
  expandedRows: any = [];
  expandedElementId: null;

  constructor(
    
    public billerDashboardService: BillerDashboardService,
    public billerProviderAssociationService: BillerProviderAssociationService,
    private _router: Router,
    private loader: NgxUiLoaderService,
    private mergePatientService: MergePatientsService,
    private dialog: MatDialog,
    private _faceSheetService: FaceSheetService,
    private _route: ActivatedRoute,
    private commonService: CommonService,
    // public loader1: NgxUiLoaderService,
    private _authService: AuthService,
    private toastr: ToastrService,
    private _company: CompanyService,
    private chargesPopupComponent: ChargesPopupComponent,
    private auditPopupComponent: AuditPopupComponent,
    private dashboardService: DashboardService,
    private fileExplorerService: FileExplorerService,
    private userService : UserService,
    private _woundCareWidgetService: WoundCareWidgetService,
    private pccService: PCCService) {
    this.currentUser = this._authService.currentUser;
    this.pagination = {
      pageNo: 1,
      hasNextPage: false,
      hasPrevPage: false,
      totalCount: 0,
      totalPages: 0
    };

  }
  // ngDoCheck(): void {
  //   let value = this.chargesPopupComponent.submitCharge.getValue()
  //   if(value){
  //     console.log("value of submitCharge is: ", value);

  //   }
  // }

  toggleCloseDropdownSelection() {
    this.closeDropdownSelection = !this.closeDropdownSelection;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { closeDropDownOnSelection: this.closeDropdownSelection });
  }
  getValue(element) {
    if (this.billingComapny?.name) {
      return `Send to ${element.billingCompanyId.name}`
    } else {
      return '';
    }
  }

  auditClicked(element){
    console.log("Audit clicked: ", element)
    let charge_id =  element._id;
    this.auditPopup = this.dialog.open(AuditPopupComponent, { data: charge_id, width: '40%', maxHeight: "40%"  })
    this.auditPopup.afterClosed().subscribe(async result => {
      // let value = this.chargesPopupComponent.submitCharge.getValue()
      // let value = this.chargesPopupComponent.chargeSubmitted;
      // if(this.submitChargeFlag){
      //   
      // dialogSubscription.unsubscribe()
      // this.initProviderSide()
      // }
    });
  }
  async sendToBilling() {
    // console.log("Sending to Billing Company: ",this.selectedBillingCompanies );
    // console.log("Sending to Billing Company: ",element );
    this.toastr.info("Sending Data")
    let response: any = await this.billerDashboardService.sendDataToBillingCompany(this.selectedChargeToSend, this.selectedBillingCompanies).toPromise();
    if (response.status == 200) {
      // console.log("Data Uploaded")
      this.selectedBillingCompanies = [];
      this.selectedChargeToSend = ""
      this.toastr.success('Data Uploaded')
    }
  }
  getVisitDate(element) {
    // console.log("Element----: ", element)
    if (element.date_obj) {
      // console.log("element.date_obj: ", element.date_obj);
      let { year, month, day, hour, minute } = element.date_obj;
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      let str = `${month}/${day}/${year}`;
      return str
    }
    else {
      let timeZone = 'America/New_York'
      if (element.facility_id_ref?.pcc_timeZone) {
        timeZone = element.facility_id_ref.pcc_timeZone
      }
      else if (element.facility_id_ref?.timeZone) {
        timeZone = element.facility_id_ref.timeZone
      }
      return moment(new Date(element.visit_date).toLocaleString('en-US', { timeZone: timeZone })).format('MM/DD/YYYY')
    }
  }
  async initPatientsByProvider() {

    this.patientsByProviderControl.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(async (name) => this.doInitPatients(name));
  }

  async doInitPatients(name) {
    if (!name) return;
    console.log("namee :: ", name);
    if (name !== null && typeof name === 'object') {
      console.log("namee :is object: ", name);

      this.patient_one = name;
      this.filters.patient_id=this.patient_one?._id;
      this.getPatientCharges(1,null);
      return;
    }
    name=name?.replace(',','');
    if(name.length<4) {
      return;
    }
    this.toastr.info('Fetching Patients', 'Wait....');

    let filter1={
      first_name:'',
      last_name:''
    }
    filter1.first_name = name?.trim().split(" ")[0]?.trim();
    filter1.last_name = name?.trim().split(" ")[1]?.trim();

    await this.mergePatientService.searchThePatientOnly(filter1)
      .subscribe((patientsResponse: any) => {
       
        if(patientsResponse.data.length==0){
          this.toastr.warning(' 0 Patient found.', 'Warning');
        }else{
          this.toastr.success('Patient has been fetched.', 'Success');
        }

        this.patientsBySearch = patientsResponse.data;
      })
  }


  async ngOnInit() {
    this._woundCareWidgetService.setIsTeleHealthReport(true);
    if(this.currentUser.user_type === global.USER_TYPE.BILLER)
    this.getProviderList();
    // if (this._router.url.indexOf('/dashboard') > -1) {
    //   this.isBillerDashboard = false;
    // } else {
    //   this.isBillerDashboard = true;
    // }
    if(this.currentUser.user_type != global.USER_TYPE.BILLER)
    this.filters.chargeType = 'draft';
    console.log("searchBy status in biller : ", this.searchBy);
    if(this.searchBy){
    }

    await this._company.getCompanyChargeandSignNoteStatus(this.currentUser.company_id).subscribe((res: any) => {
      if (res.status == 200) {
        this.signedNotesStatus = res.data[0].should_sign_note;
      }
    });
    this.dataSource = new MatTableDataSource([]);
    if (this.filters.chargeType == 'submit') {
      this.displayedColumns = ['visit', 'time', 'create_time', "Expansion Icon", 'patient_name', 'location', 'face_sheet','audit', 'charge', 'biller_note', 'timer',  'view', 'delete', 'approve',
        // 'operation'
      ]
    }
    else if (this.filters.chargeType == 'draft') {
      this.currentUser.user_type === global.USER_TYPE.BILLER ? this.displayedColumns = ['visit', 'time', 'create_time', "Expansion Icon", 'patient_name', 'location', 'face_sheet','audit', 'charge', 'biller_note', 'note', 'view', 'delete'] : this.displayedColumns = ['select', 'visit', 'time', 'create_time', "Expansion Icon", 'patient_name', 'location', 'face_sheet','audit', 'charge', 'biller_note', 'note', 'view', 'delete']
    }
    // if (window.location.pathname.includes('company') || window.location.pathname.includes('admin')) {
    //   this.admin = true;
    //   this.companyId = this._route.parent.parent.snapshot.params.id;
    //   this._route.snapshot.paramMap.get('provider_id') ? this.current_provider_id = this._route.snapshot.paramMap.get('provider_id') : null;
    // }
    // else {
    //   this.admin = false;
    //   this.companyId = this._authService.currentUser.company_id;
    // }
    // this.firstDoctor = this._authService.currentUser._id;
    // this.selectedProvider = this.currentUser;
    // if (this.admin) {
    //   this.firstDoctor = this.current_provider_id;
    // }
    // //this.toaster.info('Fetching Charge Captures...')
    this.initPatientsByProvider();
    this.loading = true
    this.dropdownSettings = {
      singleSelection: true,
      allowSearchFilter: true,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    // this.loader.startLoader('loader');
    this.dataSource2 = new MatTableDataSource([]);
    this.dataSource2.sort = this.sort;
    this.dataSource2.paginator2 = this.paginator2
    // await this.getFacilitiess();

    //// await this.getBillingComapnyAssociation(this.firstDoctor)

    // if (this.currentUser.user_type == 6) {
    //   this.displayedColumns.push('checkBox')
    //   await this.initBillerSide()
    // }
    // else {
    //   // await this.initProviderSide();
    // }
  }

  ngOnDestroy() {
    this._woundCareWidgetService.setIsTeleHealthReport(false);
  }

  async handleproviderChange(provider_id) {
    localStorage.setItem('filter_provider_id', provider_id.value);
    this.firstDoctor = provider_id.value;
    await this.getFacilitiess();
  }

  async getProviderList() {
    const filter = {
      is_activated: 'true',
      company_id: this.currentUser.company_id,
      user_type: global.USER_TYPE.DOCTOR,

    }
    const projection = {
      'first_name': 1,
      'last_name': 1,
      'title': 1,
      'full_name': 1
    }
    this.userService.getUsers(filter, projection).subscribe((response: any) => {
      if (response.status === 200) {
        this.providers = response.data;
      }
    });
  }

  initBillerSide() {
    this.billerDashboardService.getProvidersCharges().subscribe((response: any) => {
      if (response.status == 200) {
        this.providers = response.data;
        if (response.data.length > 0) {
          this.selectedProviderRow = response.data[0]._id;
          this.firstDoctor = response.data[0]._id;
          this.selectedProvider = response.data[0];
        }
        this.dataSource2.data = response.data;
        // this.loader.stopLoader('loader')
      }
      if (this.firstDoctor) {
        this.getPatientCharges(null, this.firstDoctor);
      }
      // this.billerDashboardService.getFacilities(this.firstDoctor).subscribe((response: any) => {
      //   if (response.status == 200) {
      //     this.allFacilities = response.data;
      //   }
      // })
      // this.loading = false;
    })
  }

  getLengthDraft() {
    // if(this.filters.chargeType == 'draft'){
    //   return this.selectedpatients?.length;
    // }
    // else{
    //   return this.chargesResponse?.draftCount;
    // }
    return this.chargesResponse?.draftCount;
  }

  getLengthSubmitted() {
    // if(this.filters.chargeType == 'submit'){
    //   return this.selectedpatients?.length;
    // }
    // else{  
    //   return this.chargesResponse?.submittedCount;
    // }
    return this.chargesResponse?.submittedCount;
  }
  async initProviderSide(page = 1) {
    this.dataLoaded = false;
    this.loading = true;
    // await this.getBillingComapnyAssociation(this.firstDoctor)
    // await this.getCount();
    this.getPatientCharges(1, this.firstDoctor)

    // this.loading = false;
  }

  async getBillingComapnyAssociation(doctor = null) {
    // this.firstDoctor = 
    if (this.filters.chargeType == "submit") {
      let response: any = await this.billerDashboardService.getBillingComapnyAssociation(doctor, this.filters.facility).toPromise()
      if (response.status == 200) {
        if (response.data && response.data.length > 0) {
          this.billingComapny = response.data;
        }
      }
    }
    else {
      return;
    }
  }

  async getFacilitiess() {
    let response: any = await this.billerDashboardService.getFacilities(this.firstDoctor, this.companyId).toPromise()
    // this.loader.stopLoader('loader');
    if (response.status == 200) {
      this.allFacilities = response.data;
      // this.filters.facility = this.allFacilities[0]?._id;
      // console.log("Selected facility: ", this.filters.facility)
      this.facilities = this.allFacilities.map(facility => {
        return facility.title;
      })
      this.getPatientCharges(1, this.firstDoctor)
    }
    // });
  }

  async getCount() {
    let doctorId = this._authService.currentUser._id;
    if (this.admin) {
      doctorId = this.current_provider_id;
    }
    return;

    let res: any = await this.billerDashboardService.getCount(doctorId, this.filters).toPromise()
    if (res.status == 200) {
      this.chargesResponse = res;
      console.log("ress", res);
      this.submitCount = res.submittedCount;
      this.draftCount = res.draftCount;
      this.billedCount = res.billedCount;
      if (this.filters.chargeType == 'submit') {
        this.pagination.totalCount = Number(this.submitCount);
      }
      else if(this.filters.chargeType == 'billed'){
        this.pagination.totalCount = Number(this.billedCount);
      }
      else {
        this.pagination.totalCount = Number(this.draftCount);
      }
      // this.initPagination();
    }

    // })
  }
  changeChargeType() {
    this.chargesIDs = [];
    // this.filters.facility = '';
    // this.filters.patient = '';
    // this.filters.dos = '';

    if (this.filters.chargeType == 'submit') {
      this.displayedColumns = ['visit', 'time', 'create_time', "Expansion Icon", 'patient_name', 'location', 'face_sheet','audit', 'charge', 'timer',
        // 'operation',
        'view', 'delete', 'approve']
    }
    // if (this.currentUser.user_type == '6') {
    //   this.displayedColumns.push('checkBox')
    // }
    if (this.filters.chargeType == 'draft') {
      this.currentUser.user_type === global.USER_TYPE.BILLER ? this.displayedColumns = ['visit', 'time', 'create_time', "Expansion Icon", 'patient_name', 'location', 'face_sheet','audit', 'charge', 'biller_note', 'note', 'view', 'delete'] : this.displayedColumns = ['select', 'visit', 'time', 'create_time', 'Expansion Icon', 'patient_name', 'location', 'face_sheet','audit', 'charge', 'biller_note', 'note', 'view', 'delete']
    }
  }
  editChargeClicked(charge) {
    // console.log("charge---------------: ", charge);
    let date_obj;
    if (charge.date_obj) {
      // console.log("charge.date_obj: ", charge.date_obj);
      let { year, month, day, hour, minute } = charge.date_obj;
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      let str = `${month}-${day}-${year}`;
      if (hour && minute) {
        if (minute < 10) {
          minute = `0${minute}`
        }
        if (hour < 10) {
          hour = `0${hour}`
        }
        str += ` ${hour}:${minute}`
      }
      date_obj = str;
    }
    let obj = {
      cpts: charge.cpt_id,
      icds: charge.icd_id,
      status: charge.status,
      // owner_id : charge.owner_id,
      // patient : charge.patient_id,
      // visit_date : charge.visit_date,
      // rounding_sheet_id : charge.rounding_sheet_id,
      measure: charge.measure,
      // billing_note : charge.billing_note,
      note: charge.note,
      _id: charge._id,
      parent: 'biller-dashboard'
    }
    this.commonService.setDraftedCharge(charge);
    this._router.navigate(['/patient-charge'], {
      queryParams: {
        patient_id: charge.patient_id._id,
        rs_id: charge?.rounding_sheet_id?._id,
        visit_date: charge.visit_date.toString(),
        patient_name: charge.patient_id.first_name + ' ' + charge.patient_id.last_name,
        dob: charge.patient_id.date_of_birth,
        // facility_id: patient.facility_id
        facility_id: charge.facility_id_ref._id,
        charge_id: charge._id,
        date_obj,
      }
    });
  }

  handleNextClick() {
    this.chargesIDs = [];
    this.getPatientCharges(this.pagination.pageNo + 1, this.firstDoctor);
  }

  handlePrevClick() {
    this.chargesIDs = [];
    this.getPatientCharges(this.pagination.pageNo - 1, this.firstDoctor);
  }
  getcountofPatients() {
    return ((this.pagination.pageNo * 10) - 9) + ' - ' + (((this.pagination.pageNo * 10) - 10) + Number(this.allPatients.length)) + " out of " + (this.pagination.totalCount);
  }
  initPagination() {
    this.pagination.totalPages = Math.ceil(this.pagination.totalCount / 10);
    console.log("this.pagination.pageNo: ", this.pagination.pageNo);
    console.log("this.pagination.totalPages: ", this.pagination.totalPages);
    if (this.allPatients) {
      this.pagination.hasNextPage = this.pagination.pageNo < this.pagination.totalPages;
    }
    this.pagination.hasPrevPage = this.pagination.pageNo > 1;
    // this.loader.stopLoader('loader-01');
    this.loading = false;
  }

  async getPatientCharges(page, fDoctor = null) {
    // this.toaster.info('Fetching Charges...')
    this.loading = true;
    if(this.searchBy=='facesheet'){
      if(!this.filters.facesheetFilter) return;
      this.loader.startLoader('loaderf');
    }else{
      if(!(this.filters.patient||this.filters.patient_id)) return;
      this.loader.startLoader('loaderp');
    }

    // await this.getBillingComapnyAssociation(this.firstDoctor)
    this.firstDoctor = fDoctor;
    if (this.admin) {
      this.firstDoctor = "";
      fDoctor = this.current_provider_id;
    }
    // if(this.filters.chargeType == 'archive'){
    //   this.filters.is_archived = true;
    // }
    let pat = await this.billerDashboardService.getPatientsChargesAdminCharges(page, this.firstDoctor,this.filters).subscribe((response: any) => {
      if (response.status == 200) {

        if(this.searchBy=='facesheet'){
          this.loader.stopLoader('loaderf');
        }else{
          this.loader.stopLoader('loaderp');
        }

        this.selectedpatients = response.data;
        this.allPatients = response.data;
        this.completedCharges = response.data;
        this.pagination.totalCount = Number(response.totalCount);
        // this.dataSource = response.data; 
        if (response.data.length <= 0) {
          this.toastr.info('No data to show...')
        }
        this.dataSource = new MatTableDataSource(response.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        // this.pagination.totalCount = Number(response.totalCount);
        this.pagination.pageNo = page;
        this.initPagination();
        // this.loader.stopLoader('loader')
        this.dataLoaded = true;
  
      }
    })
  }

  async deleteChargeClicked(element) {
    if (!confirm('Do you want to delete the charge.')) {
      return;
    }
    this.loading = true
    let chargeID = element._id;
    const response: any = await this.billerDashboardService.deleteChargeClicked(chargeID).toPromise();
    if (response.status == 200) {
      this.toastr.success("Charge deleted...")
      this.getCount();
      let index = this.selectedpatients.findIndex(a => a._id == response.data._id);

      if (index > -1) {
        this.selectedpatients.splice(index, 1)
        this.dataSource = new MatTableDataSource(this.selectedpatients);
      }
      this.loading = false;
      // this.selectedpatients.map(a =>)
    }

  }

  async changeCheckBox(element, event) {
    let chargesID = element._id;
    element.biller_status = event.checked;

    const updateStatus = await this.billerDashboardService.updatePatientCharges(chargesID, event.checked).subscribe((response: any) => {
      if (response.status == 200) {
      }
    })
  }
  checkBoxValue(element) {
    if (element && element.biller_status && element.biller_status == 'completed') {
      return true;
    }
    else {
      return false
    }
  }
  selectCharge(element, event) {
    if (event.checked) {
      if (element.is_approved == 'false' || element.is_approved == false) {
        this.chargesIDs.push(element._id)
      }
    }
    if (!event.checked) {
      // this.selectAllCheck = false 
      let index = this.chargesIDs.indexOf(element._id);
      if (index > -1) {
        this.chargesIDs.splice(index, 1)
      }
    }
  }
  selectChargeAll(event) { 
    if (event.checked) {
      this.chargesIDs = [];
      this.selectAllCheck = true;
      this.chargesIDs = this.allPatients.map(c => {
        if ( (c.is_approved == 'true' || c.is_approved == true || c.cpt_id.length == 0 || c.icd_id.length == 0 || !this.checkVoidCensus(c.rounding_sheet_id))) {
        }
        else {
          return c._id;
        }
      });
      this.chargesIDs = this.chargesIDs.filter(item => item);
      let charges = this.allPatients.map(c => {
        if ((c.is_approved == 'true' || c.is_approved == true || c.cpt_id.length == 0 || c.icd_id.length == 0 || !this.checkVoidCensus(c.rounding_sheet_id))) {

        } else {
          c['select'] = true
        }
        return c;
      });
      this.dataSource = new MatTableDataSource(charges);
    }
    if (!event.checked) {
      this.selectAllCheck = false;
      this.chargesIDs = [];
      let charges = this.allPatients.map(c => {
        c['select'] = false
        return c;
      })
      this.dataSource = new MatTableDataSource(charges);
    }
  }
  selectedCharge(element) {
    if (element.select) {
      return true
    }
    else {
      return false
    }
  }

  isSlected(element) {
    if (element._id == this.selectedProviderRow) {
      return true
    }
    else {
      return false;
    }
  }

  billingCompanyselection(event, element) {
    // console.log("billingCompanyselection: ", event)
    this.selectedChargeToSend = element._id
    if (event == "" || !event) {
      this.selectedBillingCompanies = this.billingComapny.map(a => a.billingCompanyId.name)
    }
    else {
      this.selectedBillingCompanies.push(event.billingCompanyId.name)
    }
    // console.log("this.selectedBillingCompanies: ", this.selectedBillingCompanies)
    this.sendToBilling()
  }
  patientClicked(patient) {
    let dialogSubscription = this.chargesPopupComponent.submitCharge.subscribe(val => {

      this.submitChargeFlag = val;
    })
    this.chargesPopup = this.dialog.open(ChargesPopupComponent, { data: { patient, arrayOfcharges: this.allPatients, companyNoteStatus: this.signedNotesStatus }, width: '80%' })
    this.chargesPopup.afterClosed().subscribe(async result => {
      // let value = this.chargesPopupComponent.submitCharge.getValue()
      // let value = this.chargesPopupComponent.chargeSubmitted;
      // if(this.submitChargeFlag){
      //   
      dialogSubscription.unsubscribe()
      // this.initProviderSide()
      // }
    });
  }
  //filters
  applyFilterPatientName(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.filterPredicate = function (data, filter: string): boolean {
      return (data.patient_id.first_name.toLowerCase().includes(filter) || data.patient_id.last_name.toLowerCase().includes(filter));
    };
  }

  applyFilter2(filterValue: string) {
    // this.dataSource2.filter = filterValue.trim().toLowerCase(); 
    let filter = filterValue.trim().toLowerCase();
    if (this.providers) {
      if (filter && filter != '') {
        let filterProvider = [...this.providers]
        filterProvider = filterProvider.filter(pro => {
          if (pro.first_name.trim().toLowerCase().includes(filter) || pro.last_name.trim().toLowerCase().includes(filter))
            return pro
        })
        this.dataSource2.data = (filterProvider)
      }
      else {
        this.dataSource2.data = (this.providers);
      }
    }
  }

  async applyFilter() {
    // console.log("filter",this.filters.facility);
    // this.getCount();
    // this.initProviderSide()
    if (this.allPatients) {
      if (this.filters.patient && this.filters.patient != '') {
        this.filterChargesPatient = [...this.allPatients]
        this.filterChargesPatient = this.filterChargesPatient.filter(charge => {
          if (charge.patient_id && charge.patient_id.first_name.trim().toLowerCase().includes(this.filters.patient) ||
            charge.patient_id && charge.patient_id.last_name.trim().toLowerCase().includes(this.filters.patient)) {
            return charge;
          }
        })
      }
      else {
        this.filterChargesPatient = [...this.allPatients]
      }
      // if (this.filters.dos && this.filters.dos != null) {
      //   this.filterChargesPatient = this.filterChargesPatient.filter(charge => {
      //     if (new Date(charge.visit_date).toLocaleDateString().trim() == this.filters.dos) {
      //       return charge;
      //     }
      //   })
      // }
      // if (this.filters.facility && this.filters.facility != '') {
      //   this.filterChargesPatient = this.filterChargesPatient.filter(charge => {
      //     if (charge.facility_id_ref?._id.trim().toLowerCase() == this.filters.facility.trim().toLowerCase()) {
      //       return charge;
      //     }
      //   })
      // }
      // else if (this.filters.facility && this.filters.facility === '') {
      //   this.filterChargesPatient = this.filterChargesPatient.filter(charge => {
      //     return charge;
      //   })
      // }
      this.selectedpatients = this.filterChargesPatient;
      this.completedCharges = this.filterChargesPatient;
      this.dataSource = new MatTableDataSource(this.filterChargesPatient)
    }
  }
  async search() {
    if (this.filters.dos || this.filters.patient) {
      await this.getCount()
      this.getPatientCharges(1, this.firstDoctor)
    }
  }
  onDateChange(date: MatDatepickerInputEvent<Date>) {
    this.chargesIDs = []
    if (date.value) {
      this.calenderDate = new Date(date.value);
      // this.filters.dos = new Date(date.value).toLocaleDateString().trim();
      // this.filters.dos = new Date(date.value);
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        await this.getCount();
        this.getPatientCharges(1, this.firstDoctor)
      }, 1000);
    }
    else {
      this.calenderDate = null;
      this.filters.dos = null;
    }
    // this.getCount();
    // this.getPatientCharges(1, this.firstDoctor)
    // this.applyFilter();
  }

  async applyFilterFacility($event) {
    this.chargesIDs = []
    if ($event.value === "") {
      this.filters.facility = "";
    }
    await this.getBillingComapnyAssociation(this.firstDoctor)
    // this.initProviderSide(); 
    await this.getCount();
    this.getPatientCharges(1, this.firstDoctor)
  }
  async applyFilterPatient(filterValue: String) {
    this.chargesIDs = [];
    filterValue = filterValue.trim();
    // console.log("filterValue : ", filterValue, filterValue.length);
    if (filterValue&&filterValue?.length>3) {
      this.filters.patient = filterValue.trim();
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        // await this.getCount();
        this.getPatientCharges(1, this.firstDoctor)
      }, 1600);
    }else{
      this.selectedpatients = [];
      this.allPatients = [];
      this.completedCharges = [];
      this.pagination.totalCount = Number(0);
   
      this.dataSource.data=[];
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataLoaded = true;
    }
  }

  async clearFilter() {
    this.filters.dos = null;
    this.calenderDate = '';
    this.firstDoctor = '';
    this.filters.provider = '';
    this.faceSheetFilter = '';
    this.filters.facesheetFilter = '';
    // this.filters.facility = '';
    // this.filters.facility = this.allFacilities[0]?._id? this.allFacilities[0]._id: ''; 
    this.filters.patient = '';
    // this.filters.chargeType = 'draft'

    // await this.getBillingComapnyAssociation(this.firstDoctor)
    await this.getCount();
    this.getPatientCharges(1, this.firstDoctor)
    // this.initProviderSide();
    // this.dataSource = new MatTableDataSource(this.allPatients)
  }

  displayPatientFn(patient): string {
    return patient && patient.first_name ? patient.first_name + " " + patient.last_name : '';
  }

  getVisit(element) {
    if (element.owner_id) {
      let visit = element.owner_id.first_name.slice(0, 1) + element.owner_id.last_name.slice(0, 2);
      if (element.date_obj) {
        let { year, month, day, hour, minute } = element.date_obj;
        if (month < 10) {
          month = '0' + month;
        }
        if (day < 10) {
          day = '0' + day;
        }
        let str = `${month}/${day}/${year}`;
        visit = visit + ' ' + str
      }
      else {
        let timeZone = 'America/New_York'
        if (element.facility_id_ref?.pcc_timeZone) {
          timeZone = element.facility_id_ref.pcc_timeZone
        }
        else if (element.facility_id_ref?.timeZone) {
          timeZone = element.facility_id_ref.timeZone
        }
        let newDate = moment(new Date(element.visit_date).toLocaleString('en-US', { timeZone: timeZone })).format('MM/DD/YYYY')

        // let newDate = new Date(element.visit_date).toLocaleDateString();
        visit = visit + ' ' + newDate;
        // visit = visit + '  ' + moment(element.visit_date).format("hh:mm:ss a");
      }
      return visit;
    }
  }
  prividerClicked(element) {
    this.selectedProvider = element._id;
    this.selectedProviderRow = element._id;
    // this.loader.startLoader('loader');
    // this.getBillingComapnyAssociation(element._id)
    this.getPatientCharges(null, element._id)
    this.getFacilities(element._id)
  }

  getProviderName(element) {
    if (element && element.first_name) {
      return element.first_name + ' ' + element.last_name + ' ' + element.title;
    }
    else {
      return '';
    }
  }
  getPatientName(element) {
    if (element && element.patient_id && element.patient_id.first_name) {
      return element.patient_id.first_name + ' ' + element.patient_id.last_name;
    }
    else {
      return '';
    }
  }

  getFacilities(provider) {
    this.billerDashboardService.getFacilities(provider).subscribe((response: any) => {
      if (response.status == 200) {
        this.allFacilities = response.data;
        this.facilities = this.allFacilities.map(facility => {
          return facility.title;
        })
      }
    })
  }
  getFacility(element) {
    if (element && element.facility_id_ref) {
      return element.facility_id_ref.title
    }
    else {
      return '';
    }
  }

  async captureCharges() {
    if (this.admin) {
      this.firstDoctor = this.current_provider_id
    }
    this.toastr.info('Generating Report', 'Info');
    let pat = await this.billerDashboardService.getPatientsChargesAdminCharges(this.pagination.pageNo, null,this.filters).subscribe((response: any) => {
      if (response.status == 200) {
        this.completedCharges = response.data.filter(charge => {
          return charge.is_approved == 'true'
        })
        if (this.admin) {
          this._router.navigate([`/company/${this.companyId}/dashboard/charge-capture`], { state: { provider: this.selectedProvider, patients: this.completedCharges, facilities: this.allFacilities } });
        }
        else {
          this._router.navigate(['/charge-capture'], { state: { provider: this.selectedProvider, patients: this.completedCharges, facilities: this.allFacilities } });
        }
      }
    })
  }

  // async captureCharges() {
  //   // this.toaster.info('Generating Report', 'Info');;
  //   if (this.admin) {
  //     this.firstDoctor = "";
  //   }

  //   this.completedCharges = this.selectedpatients.filter(charge => {
  //     return charge.is_approved == 'true'
  //   })

  //   if (this.admin) {
  //     this._router.navigate([`/company/${this.companyId}/dashboard/charge-capture`], { state: { provider: this.selectedProvider, patients: this.completedCharges, facilities: this.allFacilities } });
  //   }
  //   else {
  //     this._router.navigate(['/charge-capture'], { state: { provider: this.selectedProvider, patients: this.completedCharges, facilities: this.allFacilities } });
  //   }
  // }

  getTime(element) {
    // console.log("getTime visit date: ", moment(element.visit_date).format("hh:mm:ss a") );
    // console.log("visit date", element.visit_date);
    return moment(new Date(element.visit_date).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })).format('MMM DD, YYYY');;
    // return (moment(element.visit_date).format("hh:mm:ss a"))
  }
  getCreatedTime(element) {
    if (element.createdAt) {
      // console.log("getTime visit date: ", moment(element.visit_date).format("hh:mm:ss a") );
      return moment(new Date(element.createdAt).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })).format('MMM DD, YYYY h:mm a');
      // return (moment(element.visit_date).format("hh:mm:ss a"))
    }
    else {
      return '-'
    }
  }
  getEndTimeForTimer(charge) {
    if (charge && charge.createdAt) {
      // let time = moment(new Date(charge.createdAt).toLocaleString('en-US', { timeZone: charge.timezone })).add(1, 'days');
      let time = moment(new Date(charge.createdAt)).add(3, 'days');
      return time.toString()
    }
    else {
      return '';
    }
  }
  triggerFunction() {
    this.timeUp = true;
  }

  checkFaceSheet(element){
    if(element.faceSheet) return 'View';
    else return 'Upload';
  }

  sortData(sort: Sort) {
    console.log("sort.active : ", sort.active, sort.direction);
    const data = this.selectedpatients.slice();
    this.selectedpatients = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'patient_name':
          if (a.patient_id && a.patient_id.first_name && b.patient_id && b.patient_id.first_name) {
            return compare(a.patient_id.first_name, b.patient_id.first_name, isAsc);
          }
          else {
            return;
          }
          case 'face_sheet':
            return compare(this.checkFaceSheet(a), this.checkFaceSheet(b), isAsc);
          
        case 'location':
          if (a.facility_id_ref && a.facility_id_ref.title && b.facility_id_ref && b.facility_id_ref.title) {
            return compare(a.facility_id_ref.title, b.facility_id_ref.title, isAsc);
          }
          else {
            return;
          }
        case 'visit':
          if ((a.visit_date||a.date_obj) && (b.visit_date||b.date_obj)) {
            let dateA=this.getVisitDate(a);
            let dateB=this.getVisitDate(b);

            return compare(dateA, dateB, isAsc);
          }
        case 'time':
          if ((a.visit_date||a.date_obj) && (b.visit_date||b.date_obj)) {
            let dateA=this.getVisitDate(a);
            let dateB=this.getVisitDate(b);

            return compare(dateA, dateB, isAsc);
          }
        case 'create_time':
          if (a.createdAt && b.createdAt) {
            return compare(new Date(a.createdAt).getTime(), new Date(b.createdAt).getTime(), isAsc);
          }
        case 'approve':
          if (a.is_approved && b.is_approved) {
            return compare(a.is_approved, b.is_approved, isAsc);
          }
          else {
            return;
          }

        default: return 0;
      }
    });
    // this.dataSource = this.selectedpatients;
    this.dataSource = new MatTableDataSource(this.selectedpatients);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator
  }



  clearfilterData() {
    this.selectedDOV = null;
    this.Modelfacility = '';
    this.filter = false;
    this.selectedpatients = this.allPatients;
    // this.dataSource = new MatTableDataSource(this.selectedpatients);
    this.dataSource = new MatTableDataSource(this.allPatients);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator
  }
  async approveAll() {
    let is_approved = this.chargesIDs.length > 0 ? 'true' : 'false';
    let response: any = await this.billerDashboardService.approveAll(this.chargesIDs, is_approved).toPromise();
    if (response.status == 200) {
      this.chargesIDs = [];
      this.selectAllCheck = false;
      this.toastr.info("Charge Status updated");
      // this.initProviderSide();
      await this.getCount();
      this.getPatientCharges(1, this.firstDoctor)
    }
  }
  async archivedAll() {
    console.log(this.chargesIDs);
    let status = 'archive';
      let response: any = await this.billerDashboardService.SubmitAllandApprove(this.chargesIDs, status).toPromise();
      if (response.status == 200) {
        this.chargesIDs = [];
        this.selectAllCheck = false;
        // this.initProviderSide();
        this.getCount();
        this.getPatientCharges(1, this.firstDoctor)
        this.toastr.success("All Charges Status updated");
      }else{
        this.toastr.info(`${this.chargesIDs.length} note/notes are not billed.`);
      }
  }
  //SubmitAllandApprove
  async submitAll() { 
    let note_SignedCharges = this.dataSource.data;
    if(this.signedNotesStatus){
      note_SignedCharges = note_SignedCharges.filter(f => this.chargesIDs.includes(f._id));
      note_SignedCharges = note_SignedCharges.filter(element => element.note_id && element.note_id.is_signed === 'true');
      note_SignedCharges = note_SignedCharges.map(element => element._id);
      let status = this.chargesIDs.length > 0 ? 'submit' : 'draft';
      
      let size_difference = this.chargesIDs.length - note_SignedCharges.length;
      if (note_SignedCharges.length > 0) {
        let response: any = await this.billerDashboardService.SubmitAllandApprove(note_SignedCharges, status).toPromise();
        if (response.status == 200) {
          this.chargesIDs = [];
          this.selectAllCheck = false;
          // this.initProviderSide();
          this.getCount();
          this.getPatientCharges(1, this.firstDoctor)
          if(size_difference == 0){
            this.toastr.success("All Charges Status updated");
          }else{
            this.toastr.info(`${size_difference} note/notes are not signed.`);
          }
        }
      }else{
        this.toastr.info(`${size_difference} note/notes are not signed.`);
      }
      
    }
    else{
      note_SignedCharges = note_SignedCharges.filter(f => this.chargesIDs.includes(f._id));
      note_SignedCharges = note_SignedCharges.map(element => element._id);
      let status = this.chargesIDs.length > 0 ? 'submit' : 'draft';
      let response: any = await this.billerDashboardService.SubmitAllandApprove(note_SignedCharges, status).toPromise();
        if (response.status == 200) {
          this.chargesIDs = [];
          this.selectAllCheck = false;
          // this.initProviderSide();
          this.getCount();
          this.getPatientCharges(1, this.firstDoctor) 
          this.toastr.success("All Charges Status updated"); 
        }
      } 
  }

  async deleteAll() {
    let dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: {
        message: 'Are you sure you want to delete all selected charges?'
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result === "true") {
        let response: any = await this.billerDashboardService.deleteAll(this.chargesIDs).toPromise();
        if (response.status == 200) {
          this.chargesIDs.forEach(element => {
            Array.from(this.allPatients, (child, index) => {
              if (element == child['_id']) {
                this.allPatients.splice(index, 1);
              }
            });
          });
          this.dataSource = new MatTableDataSource(this.allPatients);
          if (this.filters.chargeType == 'draft') {
            this.draftCount -= this.chargesIDs.length;
          } else {
            this.submitCount -= this.chargesIDs.length;
          }
          this.chargesIDs = [];
          this.selectAllCheck = false;
          this.myCheckbox.checked = false;
          this.toastr.success("Selected Charges Deleted");
        }
      }
    });
  }

  toggleApproveCharge(element) {
    element.is_approved = element.is_approved === 'true' ? 'false' : 'true';

    this.billerDashboardService.toggleApproveCharge(element._id, element.is_approved)
      .subscribe((response: any) => {
      })
  }
  viewNote(ele) {
    let time_zone = 'America/New_York'
    // if (this.census && this.census.facility) {
    //   time_zone = this.census.facility.pcc_timeZone ? this.census.facility.pcc_timeZone : this.census.facility.timeZone
    // }
    const dialogRef = this.dialog.open(ViewNoteDialog, {
      width: '80%',
      //height:'80%',
      data: {
        note_id: ele.note_id,
        timezone: time_zone,
        void_census: this.checkVoidCensus(ele.rounding_sheet_id)
      }
    });
  }
  viewBillerNote(note) {
    swal({
      title: "Biller Comment",
      text: note,
      dangerMode: true,
      buttons: [, 'Close'],
    })
      .then(() => {
      });
  }
  changeCreationDate(event) {
    if (event.startDate && event.endDate) {
      this.chargesIDs = [];
      this.filters.dos = {
        startDate: moment(event.startDate).toDate(),
        endDate: moment(event.endDate).toDate()
      }

    } else {
      this.filters.dos = null;
    }
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(async () => {
      await this.getCount();
      this.getPatientCharges(1, this.firstDoctor)
    }, 1000);
    console.log(this.filters);
  }
  checkVoidCensus(patientRoundingSheet) {
    if (patientRoundingSheet) {
      if (patientRoundingSheet?.is_void) {
        if (patientRoundingSheet.is_void === true) return false;
        else return true;
      } else {
        return true
      }
    } else {
      return true;
    }
  }
  showPatientDocumentsUploadDialog(index, patientId) {
    const dialogRef = this.dialog.open(
      PatientDocumentsUploadDialog,
      { width: '80%' }
    );
    dialogRef.beforeClosed().subscribe(async (fileInput: any) => {
      if (fileInput) {
        const [file] = fileInput.files;
        const infoToast = this.toastr.info('Uploading Face Sheet...', 'Please wait')
        const company_id = this._authService.currentUser.company_id;
        this._faceSheetService.uploadFaceSheet(file, patientId, company_id).subscribe((response: any) => {
          if (response.status === 200) {
            const { _id, title, aws_path } = response.data;
            const faceSheet = {
              _id,
              title,
              aws_path
            };
            this.completedCharges[index]['faceSheet'] = faceSheet;
            this.dataSource.data[index]['faceSheet'] = faceSheet;
            this.toastr.success("Face Sheet Uploaded", 'Success');
          }
          else {
            console.error(response.data.status, response.data.message);
            this.toastr.error(response.data.message, 'Failed');
          }
        });
      }
    });
  }

  applyFaceSheetFilter(filterValue: any) {

    this.filters.facesheetFilter = filterValue;
    this.getPatientCharges(this.pagination.pageNo, this.firstDoctor);
  }
  generateFacesheetAndDownloadAsPDF(patient_id) {
    console.log('patient id', patient_id);
    const infoToastr: any = this.toastr.info('Generating PDF', 'Please wait...');
    try {
      const params = {
        patient_id
      }
      return this.dashboardService.downloadFile(global.url + API_URL.PATIENT.generateFacesheetAndDownloadAsPDF, params).subscribe((res: any) => {
        if (res instanceof HttpResponse) {

          var url = window.URL.createObjectURL(res.body);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = 'facesheet.pdf';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
          this.toastr.clear(infoToastr.toastId);
        } else {
          if (res.type === 0) {
          } else if (res.type === 3) {
            const { loaded, total } = res;
            const progress = Math.round(loaded / total * 100);
            if (progress > 0) {
            }
          }
        }
        if (res.status == 200) {
          return true;
        }
      })
    } catch (error) {
      const { message = 'Something went wrong, please try again' } = error;
      this.toastr.error(message);
    } finally {
      this.toastr.clear(infoToastr.toastId);
    }
  }
  viewPDF(filePath) {
    this.dialog.open(PdfViewerPopComponent, {
      width: '50vw',
      height: '95vh',
      data: {
        file_path: filePath
      }
    });
  }

  
  generateFacesheetForMatrixAndDownloadAsPDF(patient_id) {
    const infoToastr: any = this.toastr.info('Generating PDF', 'Please wait...');
    try {
        const params = { 
            source: 'MatrixCare',
            patient_id
        }
        return this.dashboardService.downloadFile(global.url + API_URL.PATIENT.generateFacesheetForMatrixAndDownloadAsPDF, params).subscribe((res: any) => {
            if (res instanceof HttpResponse) {

                var url = window.URL.createObjectURL(res.body);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = 'facesheet.pdf';
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
                this.toastr.clear(infoToastr.toastId);
            } else {
                if (res.type === 0) {
                } else if (res.type === 3) {
                    const { loaded, total } = res;
                    const progress = Math.round(loaded / total * 100);
                    if (progress > 0) {
                    }
                }
            }
            if (res.status == 200) {
                return true;
            }
        })
    } catch (error) {
        const { message = 'Something went wrong, please try again' } = error;
        this.toastr.error(message);
    } finally {
        this.toastr.clear(infoToastr.toastId);
    }
  }

  uploadFaceSheetActive(element, index) {
    return true;
  }
  downloadFaceSheetActive(element, index) {
    const charge = element;
    const patient = charge.patient_id;

    if(patient?.source === 'PointClickCare') return true;
    if(patient?.source === 'MatrixCare' && patient?.matrix_payload?.coverages?.length > 0) return true;
    if(charge.faceSheet) return true;

    return false;
  }
  async handleUploadFacesheetClick(element, index) {
    const charge = element;
    const patient = charge.patient_id;
    if(!patient) return;
    this.showPatientDocumentsUploadDialog(index, patient._id)
  }
  async handleDownloadFacesheetClick(element, index) {
    const charge = element;
    const patient = charge.patient_id;
    if(!patient) return; 
    if(patient?.source === 'PointClickCare') {
      let hasPCCFacesheetData = false;
      const details = {
        ...this._authService.authObject,
        patientId: patient._id,
      }
      const {status, data} = await lastValueFrom(this.pccService.syncCoveragesData({details})) as any;
      if(status === 200) {
        const _patient = data;
        if((_patient?.pcc_coverage_data?.Primary || _patient?.pcc_coverage_data?.Secondary || _patient?.pcc_coverage_data?.Tertiary)) {
          hasPCCFacesheetData = true
        }
      }
      if(hasPCCFacesheetData) {
        this.generateFacesheetAndDownloadAsPDF(patient._id);
        return;
      }
    } 
    if(patient?.source === 'MatrixCare' && patient.matrix_payload?.coverages?.length > 0) {
      this.generateFacesheetForMatrixAndDownloadAsPDF(patient._id);
      return;
    }
    const infoToast = this.toastr.info('Preparing file to download...', 'Please wait', { disableTimeOut: true });
    try {
      const downloadFileResponse: any = await this.fileExplorerService.downLoadAnyFileWithURL(element.faceSheet.aws_path, element.faceSheet.title, element.patient_id?._id).toPromise();
      var url = window.URL.createObjectURL(downloadFileResponse);
      var a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = <string>element.faceSheet.title;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    } catch (error) {
      this.toastr.error('Unable to download file.', 'Failed');
    } finally {
      this.toastr.clear(infoToast.toastId);
    }
  }

  onTabChange(event) {
    this.tabIndex = event.index;
  }
  
  toggleRow(id) {
    this.expandedRows.splice(this.expandedRows.indexOf(id), 1);
    this.expandedRows.push(id);
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}




