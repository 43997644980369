import { Component, OnInit } from "@angular/core";


@Component({
  selector: 'app-drug-history-consent-dialog',
  templateUrl: './drug-history-consent-dialog.component.html',
  styleUrls: [ './drug-history-consent-dialog.component.css' ]
})
export class DrugHistoryConsentDialog implements OnInit {
  consentGiven: boolean = false;

  ngOnInit(): void {}
}
