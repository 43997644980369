  <b style="position: absolute; top: 15px; left: 24px;">Select template</b>

<div style="margin-top: 30px;" class="flex flex-grow justify-between items-center">
  <div class="flex flex-col justify-around items-start gap-12" style="max-width: 254px;height: 100%;"> 
    <span>Blank Template</span>
    <span>Select from library</span>
    <span>Select from Recent Notes</span>
    <span>Select from Associated Provider of {{note.facility.title + ' facility'}} </span>
  </div>

  <div class="flex flex-col gap-1 justify-around items-end" style="margin-top: 20px;">
    <br *ngIf="associatedProviderTemplates.length > 0">
    <button mat-button [mat-dialog-close]="{blank: true}" class="blank-item" style="height: 15px;"></button>
    <mat-form-field>
      <mat-label>Template</mat-label>
      <mat-select (selectionChange)="onSelectTemplate($event)" [value]="currentTemplate ? currentTemplate._id : ''">
        <mat-option class="uppercase" *ngFor="let template of templates" [value]="template._id">
          <span *ngIf="template.template_type == 'system_defined';">[S] </span>
          <span *ngIf="template.template_type == 'user_defined';">[U] </span>
          {{template.title | uppercase}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="patientId">
      <mat-label>Recent Note</mat-label>
      <mat-select (selectionChange)="onSelectNote($event)">
        <mat-option *ngFor="let note of recentNotes" [value]="note._id">
          {{note.transcribtion_title}}
          {{getDOS(note)}}
          ({{note.doctor_id_ref?.first_name}} {{note.doctor_id_ref?.last_name}}, {{note.doctor_id_ref?.title}})
        </mat-option>
      </mat-select>
    </mat-form-field>
    <span style="font-size: 14.5px;margin-top: 0px;" *ngIf="!patientId">
      <br />
      Please select patient first
    </span>


    <mat-form-field>
      <mat-label>Providers</mat-label>
      <mat-select name="provider"
      #associatedProvider
      value="Select Provider"
        (selectionChange)="getAssociatedProviderTemplate($event.value._id)">
        <mat-option value="Select Provider">Select Provider</mat-option>
        <mat-option *ngFor="let p of associatedProviders" [value]="p">
          {{p?.first_name}} {{p?.last_name}}, {{p?.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    
    <div>
      <mat-form-field *ngIf="associatedProviderTemplates.length > 0">
        <mat-label>Associated Provider Template</mat-label>
        <mat-select (selectionChange)="onAossociateProviderTemplateSelect($event)" [value]="currentTemplate ? currentTemplate._id : ''">
          <mat-option class="uppercase" *ngFor="let template of associatedProviderTemplates" [value]="template._id">
            {{template.title | uppercase}}
          </mat-option>
        </mat-select>
      </mat-form-field>     
      <div *ngIf="associatedProviderTemplatesLoaded && associatedProvider.value?._id && associatedProviderTemplates.length === 0">
        <span style=" font-size:12px; color:rgb(255, 86, 48)">No templates found</span>
      </div>
    </div>

  </div>
</div>
<div class="top-right-close-btn">
  <button mat-mini-fab mat-dialog-close>
    <mat-icon>cancel</mat-icon>
  </button>
</div>