<div style="margin:1px 1px 1px 10px; overflow-x: hidden; height: 82vh;">
    <div class="flex justify-between items-center gap-5"
        style="width: 100%;height: 35px; margin:15px 5px 15px 10px">

        <div class="flex">
            <mat-label style="font-weight: bold;">All Association</mat-label>
        </div>

        <div class="flex" style="padding-right: 20px;">
            <button mat-raised-button class="dn-button-primary" (click)="openAssosiationDialog()">
                Create Association
            </button>

        </div>

    </div>

    <div class="flex" style="width: 100%;height: 2px; background-color: rgb(95 174 255 / 69%)"></div>
    <div class="flex justify-between items-center gap-5"
        style="width: 100%; height: 45px; background-color: #e1e1e1;">
        <div class="flex justify-between items-center" style="width: 29%; padding-left: 10px;">
            {{'Companies'}}
        </div>
        <div class="flex justify-between items-center gap-2" 
            style="width: 40%; padding-right: 10px;padding-left: 10px;">
            {{'Practices'}}
        </div>
        <div class="flex justify-between items-center gap-2" >
            Shared Modules
        </div>
        <div class="flex justify-between items-center"  style="width: 6%; padding-right: 10px;">

        </div>
    </div>
    <div class="flex" style="width: 100%;height: 2px; background-color: rgb(95 174 255 / 69%)"></div>

    <div *ngIf="allCompanies?.length">
        <div *ngFor="let item of allCompanies; let i=index"
            class="even-row flex justify-between items-center gap-5" [ngClass]="(i%2 == 0)?'even-row':'odd-row'"
            style="width: 100%; height: fit-content;">
            <div class="flex justify-between items-center" style="width: 29%; padding-left: 10px;">
                {{item.name}}
            </div>
            <div *ngIf="item.relation?.length" class="flex justify-between items-center gao-2"
                style="width: 42%; padding-right: 10px;padding-left: 10px;">
                <mat-chip-list aria-label="Practices">
                    <mat-chip *ngFor="let relation of item.relation" [selectable]="false" [removable]="true"
                        (removed)="onCompanyDeSelect(relation, item)">
                        {{relation.name}}
                        <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
            <div>
                <mat-form-field appearance="fill">
                    <mat-label>Shared Modules</mat-label>
                    <mat-select>
                        <mat-option style="color: rgb(43, 41, 41);">
                            <mat-checkbox class="top-margin" [checked]="item.shared_modules?.includes('notes')"
                                (change)="updateCompanyAssociation( item, $event.checked, 'notes');">
                                Notes
                            </mat-checkbox>
                        </mat-option>
                        <mat-option style="color: rgb(43, 41, 41);">
                            <mat-checkbox class="top-margin" [checked]="item.shared_modules?.includes('wounds')"
                                (change)="updateCompanyAssociation( item, $event.checked, 'wounds');">
                                Wounds
                            </mat-checkbox>
                        </mat-option>
                        <mat-option style="color: rgb(43, 41, 41);">
                            <mat-checkbox class="top-margin" [checked]="item.shared_modules?.includes('docs')"
                                (change)="updateCompanyAssociation( item, $event.checked, 'docs');">
                                Documents
                            </mat-checkbox>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="flex justify-between items-center" style="width: 6%; padding-right: 10px;">
                <button (click)="editassosiation(item)" matTooltip="Edit Association" matTooltipPosition="above"
                    mat-icon-button><mat-icon>edit</mat-icon></button>
                <button (click)="removeFullassosiation(item)" matTooltip="Clear Association" matTooltipPosition="above"
                    mat-icon-button><mat-icon>delete</mat-icon></button>
            </div>
        </div>
    </div>
</div>