<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

    <ng-container matColumnDef="organization">
        <th mat-header-cell *matHeaderCellDef> Organization </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            {{element?.company_id?.name}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="faciltiy">
        <th mat-header-cell *matHeaderCellDef> Facility </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            {{element?.facility_id_ref?.title}}
        </td>
    </ng-container>

    <ng-container matColumnDef="first_name">
        <th mat-header-cell *matHeaderCellDef> First Name </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            {{element.first_name}}
        </td>
    </ng-container>


    <ng-container matColumnDef="last_name">
        <th mat-header-cell *matHeaderCellDef> Last Name </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            {{element.last_name}}
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>