<app-header></app-header>
<div class="h100">
    <div class="h100" *ngIf="authService.userType === global.USER_TYPE.DOCTOR || authService.userType === global.USER_TYPE.SNF_WC_NURSE">
        <app-provider-appointment></app-provider-appointment>
    </div>
    <div class="h100" *ngIf="authService.userType === '2'">
        <app-transcriber-appointment></app-transcriber-appointment>
    </div>
    <div class="h100" *ngIf="authService.userType === '3' ||
    authService.userType === '4' ||
    authService.userType === '5'">
        <app-user-appointment></app-user-appointment>
    </div>
</div>
