<mat-card>
    <mat-card-content>
        <div  *ngIf="tabs.length > 0 && isShowOrders">
            <div style="display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 0.5rem;margin-top: 1rem;">
                <button mat-raised-button class="dn-button-primary" [disabled]="!shouldDisablePrintAndPDFBtn" (click)="savePatientOrder()">Signed Order</button>
                <button mat-icon-button  [disabled]="shouldDisablePrintAndPDFBtn" matTooltip="Download as PDF" 
                (click)="downloadAsPDF()"  style="cursor: pointer;" aria-label="Example icon button with a open in new tab icon">
                    <mat-icon>picture_as_pdf</mat-icon>
                  </button>
                  <button mat-icon-button   [disabled]="shouldDisablePrintAndPDFBtn" matTooltip="Print Orders" (click)="printOrders()" style="cursor: pointer;" aria-label="Example icon button with a open in new tab icon">
                    <mat-icon>local_printshop</mat-icon>
                  </button>
            </div>
            <mat-tab-group>
                <mat-tab *ngFor="let tab of tabs; let tabIndex = index">
                    <ng-template mat-tab-label>
                        <ng-container>
                            <div class="tab-label">{{ tab.title }}</div>
                        </ng-container>
                    </ng-template>

                    <div class="order-list">
                        <div
                            *ngFor="
                                let checkbox of tab.order;
                                let checkboxIndex = index
                            "
                            class="checkbox-item"
                        >
                            <input type="checkbox"  [checked]="isOrderSelected(checkbox._id)"  (click)="selectedPatientOrder(checkbox._id)" [disabled]="!shouldDisablePrintAndPDFBtn"/>
                            <div>
                                <span class="checkbox-name">{{
                                    checkbox.name
                                }}</span>
                                <div>
                                    <span
                                        class="checkbox-name"
                                        style="font-size: 14px; font-weight: 400"
                                        >{{ checkbox.description }}</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </mat-card-content>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</mat-card>
