import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { BillerDashboardService } from '../../biller-dashboard/biller-dashboard.service';
import { HttpResponse } from '@angular/common/http';
import moment from 'moment'
import { Location } from '@angular/common';
import swal from 'sweetalert'
import { ToastrService } from "ngx-toastr";
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-charge-capture',
  templateUrl: './charge-capture.component.html',
  styleUrls: ['./charge-capture.component.css']
})
export class ChargeCaptureComponent implements OnInit {
  // angular2Csv: Angular2Csv;
  data: any;
  codes: any;
  allCodes: any = {};
  records: any;
  patients: any;
  provider: any;
  facilities: any;
  currentUser: any;
  admin = false;
  constructor(
    private router: Router,
    public billerDashboardService: BillerDashboardService,
    private toaster: ToastrService,
    private _authService: AuthService,
    private _location: Location,
  ) {
    this.data = this.router.getCurrentNavigation().extras.state;
    this.currentUser = this._authService.currentUser;
  }

  ngOnInit(): void {
    if (window.location.pathname.includes('company') || window.location.pathname.includes('admin')) {
      this.admin = true;
    }
    else {
      this.admin = false;
    }
    if (this.data) {
      this.records = this.data.patients;
      this.patients = this.data.patients;
      this.provider = this.data.provider;
      this.facilities = this.data.facilities;
    }

  }
  getpatientInfo(patient) {
    let patientInfo = patient.patient_first_name + " " + patient.patient_last_name
    if (patient.patient_dob) {
      let newDate = patient.patient_dob.split('-');
      let [year, month, date] = newDate;
      let formattedDate = `${month}/${date}/${year}`
      patientInfo = patientInfo + "  (" + formattedDate + ")";
    }
    if (patient.patient_gender?.toLowerCase() == 'male') {
      patientInfo = patientInfo + " - M";
    }
    else {
      patientInfo = patientInfo + " - F";
    }
    return patientInfo;
  }
  getProvider(codeIndex, record) {
    let index = this.codes.findIndex(element => element.code == codeIndex.code);
    if (index == 0) {
      return record.owner_id?.first_name + " " + record.owner_id.last_name
    }
    else {
      return '';
    }
  }

  getAddress(codeIndex, patient) {
    let index = this.codes.findIndex(element => element.code == codeIndex.code);
    if (index == 0) {
      if (patient && patient.facility_id_ref && patient.facility_id_ref.title) {
        return patient.facility_id_ref.title;
      }
      else {
        return '';
      }
    }
  }
  getVisitDate(codeIndex, record) {
    let index = this.codes.findIndex(element => element.code == codeIndex.code);
    if (index == 0) {
      if(record.date_obj && record.date_obj.year){
        let {year, month, day, hour, minute} = record.date_obj;
                if(month < 10){
                    month = '0' + month;
                }
                if(day < 10){
                    day = '0' + day;
                }

                let date = new Date(`${month}-${day}-${year}`);
                month = date.toLocaleString('en-US', {month: 'short'});
                let str = `${month} ${day}, ${year}`;
                if(hour && minute) {
                    if(minute < 10){
                        minute = `0${minute}`
                    }
                    if(hour < 10){
                        hour = `0${hour}`
                    }
                    str += ` ${hour}:${minute}`
                }
                return str
      }
      else{
        let date = record.visit_date
        if (date) {
          // return moment(new Date(date).toLocaleString('en-US', { timeZone:  'America/Los_Angeles' })).format('MMM DD, YYYY h:mm a');
          return moment(new Date(date)).format('MMM DD, YYYY hh:mm');
  
          // let visitDate: any;
          // visitDate = new Date(date).toLocaleDateString();
          // visitDate = visitDate + ' ' + moment(date).format("hh:mm:ss a");
          // return visitDate;
        }
        else {
          return '';
        }
      }
    }
    else {
      return '';
    }
  }
  getCodesLength(record) {
    let icdCode = record.icd_id.length;
    let cptCode = record.cpt_id.length;

    if (icdCode >= cptCode) {
      this.codes = record.icd_id;
      this.allCodes.cpt = record.cpt_id;
      this.allCodes.icd = record.icd_id;
    }
    else {
      this.codes = record.cpt_id;
      this.allCodes.cpt = record.cpt_id;
      this.allCodes.icd = record.icd_id;
    }
    return this.codes;
  }
  getICD(codeIndex, record) {
    // console.log(codeIndex, record);
    let index = this.codes.findIndex(element => element.code == codeIndex.code);
    if (record.icd_id[index] && record.icd_id[index]?.code) {
      return record.icd_id[index].code
    }
    else {
      return '';
    }
  }
  getCPT(codeIndex, record) {
    //console.log(codeIndex, record);
    let index = this.codes.findIndex(element => element.code == codeIndex.code);
    if (record.cpt_id[index]) {
      return record.cpt_id[index].code
    }
    else {
      return '';
    }
  }

  getICDName(codeIndex, record) {
    let index = this.codes.findIndex(element => element.code == codeIndex.code);
    if (record.icd_id[index] && record.icd_id[index]?.shortDescription) {
      return record.icd_id[index].shortDescription
    }
    else {
      return '';
    }
  }
  getCPTName(codeIndex, record) {
    let index = this.codes.findIndex(element => element.code == codeIndex.code);
    if (record.cpt_id[index]) {
      return record.cpt_id[index].description
    }
    else {
      return '';
    }
  }
  // logout() {
  //   localStorage.removeItem('currentUser');
  //   setTimeout(() => {
  //     window.location.href = '/login';
  //   });
  // }
  goBack() {
    this._location.back();
  }
  // goBack() {

  //   // window.location.href = '/biller-dashboard';
  //   this.router.navigate(['/biller-dashboard']);
  //   // this.router.navigateByUrl['/biller-dashboard']
  // }

  downloadReport() {

    swal("Download File as", {
      buttons: {
        // cancel: "Run away!",
        pdf: {
          text: "PDF",
          value: "pdf",
        },
        csv: {
          text: "CSV",
          value: "csv",
        },
        // Cancel: {
        //   text: "None",
        //   value: "none",
        // },
      },
    })
      .then((value) => {
        switch (value) {

          case "pdf":
            this.toaster.info('Downloading as PDF')
            // swal("pdf selected");
            let innerHtml = "<div style='text-align: center;font-weight: bold;'>Summary of Charge Capture</div>" + document.getElementById("chargeCapture").innerHTML;
            // console.log("innerHtml: ", innerHtml); 

            this.billerDashboardService.generatePDFAndDownload(innerHtml).subscribe((res: any) => {
              if (res instanceof HttpResponse) {
                var url = window.URL.createObjectURL(res.body);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = 'Summary of Charge Capture.pdf';
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
              } else {
                if (res.type === 0) {
                } else if (res.type === 3) {
                  const { loaded, total } = res;
                  const progress = Math.round(loaded / total * 100);
                  if (progress > 0) {
                  }
                }
              }
            })
            break;

          case "csv":
            this.toaster.info('Downloading as CSV')
            const csvData = this.createCSV();
            // swal("CSV is not availble");
            // this.toaster.info('CSV is not availble')

            break;

          default:
          // swal("NONE/CANCEL");
        }
      });

  }

  getCPTCodes(patient) {
    let str = '';
    if (patient.cptString) {
      let cpts = patient.cptString
      str = cpts.replace(/,/g, '');
      // str = str.slice(0, -1)
    } else if (patient.cpt_id) {
      patient.cpt_id.forEach(cpt => cpt.code ? str = str + cpt.code + ' ' : str = str)
    }
    return str
  }
  getICDCodes(patient) {
    let str = '';
    if (patient.icdString) {
      let icds = patient.icdString
      str = icds.replace(/,/g, '');
      // str = str.slice(0, -1)
    } else if (patient.icd_id) {
      patient.icd_id.forEach(icd => icd.code ? str = str + icd.code + ' ' : str = str)
    }
    return str
  }

  async createCSV() {
    const headerList = ['created_date', 'facility', 'provider', 'dos', 'name', 'dob', 'cpt', 'icd10']
    let array = typeof this.patients != 'object' ? JSON.parse(this.patients) : this.patients;
    let finalJson = [];
    for await (let x of array) {
      // let codesLength = await this.getCodesLength(x)
      // codesLength.forEach(async element => { 
      let row = {
        created_date: new Date().toLocaleDateString(),
        facility: x.facility_id_ref?.title ? x.facility_id_ref.title : ' ',
        provider: x.owner_id.first_name + ' ' + x.owner_id.last_name,
        dos: x.visit_date ? new Date(x.visit_date).toLocaleDateString() : ' ',
        name: x.patient_first_name+ ' ' + x.patient_last_name,
        dob: x.patient_dob? new Date(x.patient_dob).toLocaleDateString() : ' ',
        // cpt: this.getCPT(element, patient)?this.getCPT(element, patient):' ',
        cpt: this.getCPTCodes(x),
        icd10: this.getICDCodes(x),
        // cpt: patient.cptString,
        // icd10: this.getICD(element, patient)?this.getICD(element, patient):' ', 
        // icd10: patient.icdString, 
      }
      console.log("ROW data: ", row);
      finalJson.push(row);
    }

    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < finalJson.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        // let head = headerList[index].trim();  
        let head = headerList[index];
        line += ',' + finalJson[i][head];
      }
      str += line + '\r\n';

    }
    // console.log("STR===== ", str);
    await this.billerDashboardService.downlaodCSV(str);
    return str;
  }
}