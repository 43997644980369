<div class="flex justify-start items-start gap-2" id="container-dataset" style="overflow: auto;height: 100%;">
    <mat-card style="min-width: 22%;height: 100%;">
        <mat-card-content>
            <div class="col-header"> Etiology </div>

            <div class="col-content">
                <div cdkDropList (cdkDropListDropped)="drop($event)">
                    <div cdkDrag *ngFor="let etiology of etiologies;let i = index"
                        class="flex justify-between items-baseline gap-2 cdk-list-div"
                        [ngStyle]="{ background: i % 2 == 0? '#f4f5f8': 'white'}">
                        <p *ngIf="!isEditingEtiology(etiology, i)" (click)="handleEtiologyClick(etiology, i)">
                            {{etiology.label}}
                        </p>
                        <ng-container *ngIf="currentUser.admin_type == 'system_admin'">
                            <button class="close-image" (click)="deleteEtiology(etiology, i)">
                                <img src="assets\icons\minus.png" />
                            </button>
                        </ng-container>
                    </div>
                </div>

                <div class="item flex justify-between items-center" *ngIf="showNewEtiologyInput && currentUser.admin_type == 'system_admin'">
                    <mat-form-field>
                        <input matInput type="text" matInputAutofocus [(ngModel)]="newEtiologyInputValue"
                            (keyup)="handleNewEiologyInputValueChange($event)" name="newEtiologyInputValue" required>
                    </mat-form-field>
                    <button class="save-image" (click)="addNewEtiology(newEtiologyInputValue)">
                        <img src="assets\icons\save-white.png" />
                    </button>
                </div>

                <ng-container *ngIf="currentUser.admin_type == 'system_admin'">
                    <button class="add-image" (click)="handleAddNewEtiology()">
                        <img src="assets\icons\plus.png" />
                    </button>
                </ng-container>
            </div>

        </mat-card-content>
    </mat-card>

    <mat-card style="min-width: 22%; height: 100%;" *ngIf="activeEtiology">
        <mat-card-content>
            <div class="col-header"> {{activeEtiology? activeEtiology.label + '/' : ''}}Groups </div>

            <div class="col-content">
                <div *ngFor="let woundEtiologyGroup of woundEtiologyGroups;let i = index">
                    <div class="item flex justify-between items-center"
                        [class.active-item]="activeWoundEtiologyGroup?._id === woundEtiologyGroup?._id">
                        <p *ngIf="!isEditingWoundEtiologyGroup(woundEtiologyGroup, i)"
                            (click)="handleWoundEtiologyGroupClick(woundEtiologyGroup, i)" style="width: 100%">
                            {{woundEtiologyGroup.title}}
                        </p>
                        <ng-container *ngIf="currentUser.admin_type == 'system_admin'">
                            <button class="close-image"
                                (click)="deleteWoundEtiologyGroup(woundEtiologyGroup, i)">
                                <img src="assets\icons\minus.png" />
                            </button>
                        </ng-container>
                    </div>
                </div>

                <div class="item flex justify-between items-center" *ngIf="showNewWoundEtiologyGroupInput && currentUser.admin_type == 'system_admin'">
                    <mat-form-field>
                        <input matInput matInputAutofocus type="text" [(ngModel)]="newWoundEtiologyGroupInputValue"
                            (keyup)="handleEditWoundEtiologyGroupInputValueChange($event)" name="newInputValue"
                            required>
                    </mat-form-field>
                    <button class="save-image" (click)="addNewWoundEtiologyGroup(newWoundEtiologyGroupInputValue)">
                        <img src="assets\icons\save-white.png" />
                    </button>
                </div>
            </div>

            <ng-container *ngIf="currentUser.admin_type == 'system_admin'">
                <button class="add-image" (click)="handleAddNewWoundEtiologyGroup()">
                    <img src="assets\icons\plus.png" />
                </button>
            </ng-container>
        </mat-card-content>
    </mat-card>

    <mat-card style="flex:1; min-width: 22%; height: 100%;" *ngIf="activeWoundEtiologyGroup">
        <mat-card-content>
            <div class="col-header"> {{activeEtiology? activeEtiology.label + '/' : ''}}{{activeWoundEtiologyGroup?
                activeWoundEtiologyGroup.title + '/' : ''}}Mapping
            </div>

            <app-wound-groups-icdtags-mapping style="height: 100%;" *ngIf="woundEtiologyGroupDetails"
                [company_id]="company_id" [activeWoundEtiologyGroup]="activeWoundEtiologyGroup"
                [woundEtiologyGroupDetails]="woundEtiologyGroupDetails">
            </app-wound-groups-icdtags-mapping>
        </mat-card-content>
    </mat-card>

    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>