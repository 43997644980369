
import { Component, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { UserTypesRelationshipService } from '../../services/user-types-relationship.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserManagementService } from '../user-management/user-management.service';
import { ToastrService } from 'ngx-toastr';
import { RolesDialog } from '../../shared/popup-dialogs/roles-dialog/roles.dialog';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import * as global from '../../includes/global'
import { CompanyService } from 'src/app/services/Modules/company.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ProfileService } from '../profile/profile.service';
import { HttpResponse } from '@angular/common/http';
import moment from 'moment'
import { CommonService } from 'src/app/services/common.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DialogConfirmationComponent } from '../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { UserService } from 'src/app/services/Modules/user.service';


export interface OnInit {
  ngOnInit(): void;
}

@Component({
  selector: 'app-test-provider-list',
  templateUrl: './test-provider-list.component.html',
  styleUrls: ['./test-provider-list.component.css']
})
export class TestProviderListComponent implements OnInit {
  filteredCompanies: Observable<string[]>;
  companyControl = new FormControl();
  usersFilter = {
    user_type: 'test_provider'
  };
  disabled = false;
  loading = false;
  public doctors: any = [];
  public currentUser: any;
  public response: any;
  public dataSource: any;
  // selectedCompany: any;
  public roleDialogRef;
  is_activated = 'true';
  userStatus = 'Active';
  company_id: any;
  localOrDevEnvironment: Boolean = false;
  displayedColumns: String[];
  companies: any = [];
  currentCompany: any;
  userRoleTypeSubscription: any;
  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    PAGE_LIMIT : 50,
    skip: 0
  };
  userNameRegExp: any = "";
  timeoutId: any;
  hasDoctorsLoaded: boolean = false;
  selectedRoleType: string;
  currentUserType: any;
  selectedUserType : any;
  selectedUserTypeSubscription: any;
  permissionsList: any;
  selectedPermissions: string[] = [];
  loaderId = 'doctor-list-loader';
  userTypes = [
    { title : 'Provider' , value :'1' },
    { title : 'Test Provider' , value : 'test_provider' },
    // { title : 'CNP - Clinical Nursing Practitioner' , value : 'cnp' },
    // { title : 'Transcriber' , value :'2' },
    // { title : 'Manager' , value :'3' },
    { title : 'Nurse' , value :'nurse' },
    { title : 'Medical Assistant' , value :'medical_assistant' },
    { title : 'Business Officer' , value :'business_officer' },
    { title : 'View Only User' , value : 'view_only_user' }
  ];
  global = global;
  company_type: any;
  public constructor(
    private loader: NgxUiLoaderService,
    public services: UserTypesRelationshipService,
    private _router: Router,
    private userManagementService: UserManagementService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private _authService: AuthService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private _profileService: ProfileService,
    private commonService: CommonService,
    private userService: UserService,
  ) {
    this.displayedColumns = ['first_name', 'last_name', 'email',
      'last_login',
      'status'
      , 'cognito', 'user-permissions', 'mobile_version'];
    this.currentUser = this._authService.currentUser;
    if(this.currentUser.product_owner){
      this.displayedColumns = ['first_name', 'last_name', 'email',
      // 'doctor-profile',
      'last_login',
      // 'timezone', 
      'status'
      // , 'rrgen'
        , 'cognito', 'admin_', 'user-permissions', 'mobile_version'];
    }

    if (this.currentUser.admin_type === "full_admin" || this.currentUser.admin_type === "system_admin") {
      this.displayedColumns.push('user-type');
    }
    if (this.currentUser.product_owner) {
      this.displayedColumns.push('companies');
    }
    this.displayedColumns.push('operations');
    if (!this.currentUser) {
      // if not login, stop and redirect to login #mohsin-dev
      localStorage.setItem('currentUser', 'null');
      this._router.navigate(['/login']);
      return;
    }
    this.loading = true;
    // this.company_id = this.currentUser.company_id;

    // if(this.currentUser.account_type == 'company'){  
    //   this.company_id = this.currentUser.company_id;
    // }

    if (window.location.pathname.includes('company') && !window.location.pathname.includes('manage-companies')) {
      this.company_id = this.route.parent.parent.parent.snapshot.params.id;
    }
    else if (window.location.pathname.includes('manage-companies')) {
      this.company_id = this.route.parent.parent.parent.snapshot.params.id;
    }
    // this.company_id = localStorage.getItem('company_id')

  }
  // displayedColumns: string[] = ['first_name','last_name', 'email','doctor-profile', 'role', 'status', 'charge-status', 'admin','pcc'];
  @ViewChild(MatSort) sort: MatSort;
  
  ngOnDestroy(): void {
    if(this.userRoleTypeSubscription) {
      this.companyService.changeUserRoleType(null);
      this.userRoleTypeSubscription.unsubscribe();
    }
    if(this.selectedUserTypeSubscription) {
      this.selectedUserTypeSubscription.unsubscribe();
    }
    if (!window.location.pathname.includes('doctor-profile')) {
      localStorage.removeItem("test-provider-name")
    }
  }
  async ngOnInit() {
    /*this.dataSource.sort = this.sort;*/
    await this.userService.getUserPermission(this.company_id).subscribe((response: any) => {
      if (response.status !== 200) return;
      this.permissionsList = response.data;
      let idx = this.permissionsList.findIndex((per) => per.key === 'spell_check_status');
      if(idx == -1) {
        this.permissionsList.push({key: 'spell_check_status', value: 'Spell Check'})
      }
    });
    this.getCompanyType();
    try {
      if (this.currentUser.product_owner) {
        await this.getCompanies();
      }
      this.userNameRegExp = localStorage.getItem("test-provider-name")

      this.getDoctorList();

      this.filteredCompanies = this.companyControl.valueChanges.pipe(
        startWith(''),
        map((value:any) => this.filterCompany(value)));
        
        this.selectedUserTypeSubscription = this.commonService.castselectedUserType.subscribe(userType => {
          if(userType){
            this.selectedUserType = userType;
          }
        });

        this.userRoleTypeSubscription = this.companyService.castUserRoleType.subscribe(role => {
          if(role) {
          this.selectedRoleType = role;
          this.getDoctorList();
        }
        });
      } 
      
      catch (err) {
      console.log("err", err);
    }
  }
  private filterCompany(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.companies.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    else {
      return this.companies;
    }
  }

  searchDoctors() {
    clearTimeout(this.timeoutId);
    localStorage.setItem("test-provider-name", (this.userNameRegExp));
    this.timeoutId = setTimeout(() => {
      this.hasDoctorsLoaded = true;
      this.getDoctorList();
    }, 1000);
  }

  async getCompanyType() {
    const company_id = this.company_id;

    await this.companyService.getCompanyName(company_id).subscribe((res: any) => {
      let company = res.data;
      this.company_type = company.company_type
      if (this.company_type !== 'Wound') {
        this.userTypes = [
          { title: 'Provider', value: '1' },
          { title: 'Test Provider', value: 'test_provider' },
          { title: 'Transcriber', value: '2' },
          { title: 'Nurse', value: 'nurse' },
          { title: 'Medical Assistant', value: 'medical_assistant' },
          { title: 'Business Officer', value: 'business_officer' },
        ];
      }
    })
  }

  deleteUser(id : string){
    let dialogRef = this.dialog.open(DialogConfirmationComponent,{
      data: {
        message: "Are you sure you want to delete this User ?"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === "true") {
        this._profileService.deleteUser(id).subscribe((res:any)=>{
            // this.doctors = this.doctors.filter(user => user._id !== id)
            // this.dataSource.data = [];
            // this.dataSource.data = this.doctors;
          if (res.status == 200)
            this.toastr.success("User Deleted Successfully");
          else
            this.toastr.warning(res.message);
          this.getDoctorList();
        });
      }else{
        return;
      }
    })
  }

  changedRole(){
    this.userRoleTypeSubscription = this.companyService.castUserRoleType.subscribe(role => {
      if(role) {
      this.selectedRoleType = role;
      this.getDoctorList();
    //   this.dataSource.data = this.doctors;
    //   if(role === "all") {
    //       this.dataSource.data = this.doctors;
    //   }
    //   else {
    //       this.dataSource.data = this.dataSource.data.filter(provider => provider.admin_type === role);
    //   }
    }
    });
  }
  
  getDoctorList() {
    let pageNo = this.pagination.pageNo;
    if(this.userNameRegExp !== ''){
      pageNo = null;
    }
      let getOnlyDoctors : boolean = false;
      this.services.getDoctorsList(this.is_activated, this.company_id, this.pagination.pageNo, this.userNameRegExp, this.selectedRoleType, 'demo_provider', global.USER_TYPE.DOCTOR).subscribe((data:any) => {
      this.hasDoctorsLoaded = false;
      this.pagination.totalRecords = data.totalRecords;
      this.response = data;
      this.doctors = this.response.data;
      if (this.userNameRegExp !== "") { //update page count for title search
        this.pagination.totalRecords = data.totalRecords;
        this.pagination.hasNextPage = false;
        this.pagination.hasPrevPage = false;
        this.pagination.skip = 0;
      }
      this.sortUsers(this.doctors);
      if (this.userNameRegExp === "" || this.userNameRegExp === null) {
        this.handlePagination();
      }
      this.doctors.forEach(dctr => {
        if(dctr.recentLogin) {
          dctr.recentLogin = moment(dctr.recentLogin).tz('America/Los_Angeles').format('YYYY-MMM-DD h:mm A');
        }
        dctr.companies = this.companies
        this.companies.forEach(company => {
          if (dctr.company_id == company._id) {
            dctr.selectedCompany = company._id;
          }
        });
        if (dctr.admin_type == "full_admin" || dctr.admin_type == "system_admin") {
          dctr.admin = 'full_admin'
        }
        else if (dctr.admin_type == "transcriber_admin") {
          dctr.admin = 'transcriber_admin'
        }
        else if (dctr.admin_type == "none") {
          dctr.admin = 'none'
        }
        dctr.permissions = dctr.permissions.map(per => per.key);
      });
      this.dataSource = new MatTableDataSource(this.doctors);
    }, console.error, () => this.loading = false);
  }

  handlePagination() {
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    if (this.pagination.skip + this.doctors.length < this.pagination.totalRecords) {
      this.pagination.hasNextPage = true;
    }
    else{
      this.pagination.hasNextPage = false;
    }
    if (this.pagination.skip > 0) {
      this.pagination.hasPrevPage = true;
    }
    else{
      this.pagination.hasPrevPage = false;
    }
  }

  goToPreviousPage() {
    this.pagination.pageNo--;
    this.pagination.hasPrevPage = false;
    this.getDoctorList();
  }
  goToNextPage() {
    this.pagination.pageNo++;
    this.pagination.hasNextPage = false;
    this.getDoctorList();
  }

  getCompanies() {
    return new Promise<void>((resolve, reject) => {
      this.companyService.getAllCompanies({ company_type : { $ne : global.COMPANY_TYPE.SNF } }).subscribe(async (response: any) => {
        if (response.status == 200) {
          this.companies = response.data;
          this.currentCompany = this.route.parent.parent.parent.snapshot.params.id
          var index = this.companies.findIndex(company => this.currentCompany === company._id);
          this.currentCompany = this.companies[index];
          this.companies.splice(index, 1);
          this.companyControl.setValue(this.currentCompany?.name);
          resolve(null);
        }
        else if (response.status == 403) {
          this.toastr.error(response.message, "ERROR");
        }
      }, err => {
        reject();
        this.toastr.error(err, "ERROR");
      }
      );
    });
  }
  onCompanyChange(element, company) {
    this.companyService.companyChangeForUser(element._id, company, element.company_id).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("Company Changed")
        this.dataSource.data = this.dataSource.data.filter(user => user._id !== element._id);
        // this.getCompanies();
      }
    })
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyUserFilter(event) {
    let pageNo = this.pagination.pageNo;
 
    this.userNameRegExp = "";
    this.is_activated = event.value == "true" ? "true" : "false";

    this.loading = true;
    this.services.getDoctorsList( this.is_activated, this.company_id, pageNo, this.userNameRegExp, this.selectedRoleType, 'demo_provider').subscribe(data => {
      this.response = data;
      this.pagination.totalRecords = this.response.totalRecords;
      this.doctors = this.response.data;
      this.doctors.forEach(dctr => {
        if (dctr.admin_type == "full_admin" || dctr.admin_type == 'full_admin' || dctr.admin_type == 'system_admin') {
          dctr.admin = 'full_admin'
        }
        else if (dctr.admin_type == "transcriber_admin") {
          dctr.admin = 'transcriber_admin'
        }
        else if (dctr.admin_type == "none") {
          dctr.admin = 'none'
        }
        dctr.permissions = dctr.permissions.map(per => per.key);
        this.handlePagination();
      });
      this.dataSource = new MatTableDataSource(this.doctors);
    }, console.error, () => this.loading = false);
  }

  getUserActiveStatus(provider) {
    
    if (provider && provider.isActivated) {
      if (provider.isActivated == true) {
        return true
      }
      else return false;
    }
    else return false;
  }
  checkEnvironment() {
    if (global.ENVIRONMENT == 'LOCAL' || global.ENVIRONMENT == 'DEV') {
      return true;
    }
    else {
      return false;
    }
  }

  changeUserActiveStatus(provider, status) {         //userId:string
    this.userManagementService.changeUserActiveStatus(provider._id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      } else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  changeChargeStatus(provider, status) {
    this.userManagementService.changeChargeStatus(provider._id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }

  changeRRGenStatus(provider, status) {
    this.userManagementService.changeRRGenStatus(provider._id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }

  changeTelemedicineStatus(provider, status) {
    this.userManagementService.changeTelemedicineStatus(provider._id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  changeMatrixStatus(provider, status) {
    this.userManagementService.changeMatrixStatus(provider._id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  // changeTATStatus(provider, status){ 
  //   this.userManagementService.changeTATStatus(provider._id, status).subscribe((response:any)=> {
  //     if(response.status == 200){
  //       this.toastr.success(response.message, 'Success');
  //     }
  //     else{
  //       this.toastr.error(response.message, 'Failed');
  //     }
  //   })
  // } 
  changeAdminStatus(provider, status) {
    this.userManagementService.changeAdminStatus(provider._id, this.company_id, status).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  getChargeStatus(provider) {
    if (provider && provider.charge_status) {
      if (provider.charge_status == true) {
        return true
      }
      else return false;
    }
    else return false;
  }
  getAdminStatus(provider) {
    if (provider && provider.admin_type) {
      if (provider.admin_type == 'full_admin' || provider.admin_type == 'system_admin') {
        return true;
      }
      else {
        return false;
      }
    }
  }
  getPccStatus(provider) {
    if (provider && provider.has_pcc_account) {
      if (provider.has_pcc_account == 'true')
        return true;
    }
    else {
      return false;
    }
  }

  resolvePermission(key) {
    if (key) {
      let index = this.permissionsList.findIndex(permissions => permissions.key == key);
      return this.permissionsList[index].value;
    }
  }
  async changeUserPermissions(element, event) {
    this.selectedPermissions = event.value;
    element.permissions = event.value;
    let filterArray = this.permissionsList.filter((per) => element.permissions.findIndex((selectedPer) => selectedPer === per.key) > -1);
    let details = {
      userId: element._id,
      company_id: this.company_id,
      filterArray: filterArray,
    }
    const infoToast = this.toastr.info('Updating user permissions...', 'Please wait', { disableTimeOut: true });
    await this.userService.setUserPermission(details).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.success(response.message, 'Success');
      } else {
        this.toastr.error('something went wrong', 'Error');
      }
      this.toastr.clear(infoToast.toastId);
    });
  }
  getCheckedPerm(element, perm) {
    if (element.findIndex((permission) => permission.key === perm) > -1) {
      return true;
    } else {
      return false;
    }

  }

  changePccStatus(provider, status) {
    let updateObject = {
      _id: provider._id,
      has_pcc_account: status
    };
    
    this.userManagementService.updateUser(updateObject, this.company_id).subscribe((response: any) => {
      
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  changeTwoFactorAllowance(provider, status) {
    let updateObject = {
      _id: provider._id,
      allow_two_factor_change_by_admin: status
    };
    
    this.userManagementService.updateUser(updateObject).subscribe((response: any) => {
      
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }
  changeDragonStatus(providerId, event){
    let updateObject = {
      _id: providerId,
      enabled_speech_kit: event.value
    };
    this.userManagementService.updateUser(updateObject).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    });
  }
  changeCognitoStatus(provider, status) {
    let updateObject = {
      _id: provider._id,
      enableCognito: status
    };
    
    this.userManagementService.updateUser(updateObject).subscribe((response: any) => {
      
      if (response.status == 200) {
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }

  showRolesDialog(user) {
    this.roleDialogRef = this.dialog.open(RolesDialog, { width: '80%', data: { user } });
  }


  handleChangeUserType(userId, event) {
    let additionalRole: string = '';
    var update = {
      user_type: event.value,
      user_id: userId
    }
    if(event.value == 'test_provider') {
      additionalRole = 'demo_provider';
    }else if(event.value == 'cnp') {
      additionalRole = 'cnp';
    }
    this._profileService.updateUserType(update,additionalRole).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("User type changed");
        this.getDoctorList();
      }
    });
  }
  handleChangeAdminType(userId, event) {
    var update = {
      admin_type: event.value,
      user_id: userId
    }
    this._profileService.updateAdminType(update).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success("Admin type changed");
        //  this.getDoctorList();
      }
    });
  }

  adminType(element) {
    console.log("Admin type: ", element);
    return 'full Admin'
  }

  ResendEmail(userId) {
    this.companyService.ResendEmail(userId).subscribe((response: any) => {
      if (response.status === 200) {
        this.toastr.success("Email sent successfully");
      }
    });
  }
  exportUsersAsXLSX() {
    this.loader.startLoader(this.loaderId);
    this.companyService.exportUsersAsXLSX(this.company_id, this.selectedUserType, this.is_activated,'demo_provider', this.selectedRoleType).subscribe((response: any) => {
      if (response instanceof HttpResponse) {
        // this.toastr.success("Status changed");
        this.loader.stopLoader(this.loaderId);
        var url = window.URL.createObjectURL(response.body);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `Users`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }
    });
  }

  changeDictationStatus(provider, status) {
    let updateObject = {
      _id: provider._id,
      is_dictation_enabled: status
    };

    this.userManagementService.updateUser(updateObject).subscribe((response: any) => {

      if (response.status == 200) {
        if (this.currentUser._id === response.data._id) {
          const currentUser = JSON.parse(localStorage.getItem("currentUser"));
          currentUser.is_dictation_enabled = response.data.is_dictation_enabled;
          localStorage.setItem("currentUser", JSON.stringify(currentUser));
        }
        this.toastr.success(response.message, 'Success');
      }
      else {
        this.toastr.error(response.message, 'Failed');
      }
    })
  }

  sortUsers(arr: any[]) {
    arr.sort((a, b) => a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase()));
  }

}
