import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
  })
  export class CKEditorService {
    private config = {
        mentions: [],
        extraPlugins: 'divarea,justify,spacingsliders,autocomplete,image',// autoplaceholder,
        autoplaceholder: {
            tokenList: {
            },
            defaultText: 'Add Value'
        }, 
        spacingsliders : {
          lineheight_min : 1,
          lineheight_max : 20,
          letterspacing_min : 0,
          letterspacing_max : 20
        },
        font_defaultLabel: 'Times New Roman',
        // allowedContent: "span[data-*]",
        extraAllowedContent: "p(text-align-center,text-align-right); img; p span table",
        removePlugins: 'font,scayt,wsc'

    }

    async saveData() {
        
    }

    getDefaultConfig() {
        return this.config;
    }

    ckeditorRemoveTitle(editor) {
        const cke_wysiwyg_div = editor.container.$.querySelector('.cke_wysiwyg_div')
        cke_wysiwyg_div.removeAttribute('title')
        cke_wysiwyg_div.removeAttribute('aria-label')
    }
  }
