import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { PatientScriptSureService } from './patient-scriptsure.service';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../global';


@Component({
    selector: 'app-patient-scriptsure',
    templateUrl: './patient-scriptsure.component.html',
    styleUrls: [ './patient-scriptsure.component.css' ]
})
export class PatientScriptSureComponent implements OnInit, OnDestroy {
    global = global;
    admin:boolean=false;
    company:boolean=false;
    currentUser: any = null;
    scriptsureLoginData: any = {};
    scriptsureLaunchUrl: string = null;
    loaderId: string = 'patient-scriptsure-page-loader';
    isLogoutNeededOnDestroy: boolean = false;

    constructor(
        private _authService: AuthService,
        private _patientScriptSureService: PatientScriptSureService,
        private _route: ActivatedRoute,
        private _router: Router,
        private loader: NgxUiLoaderService
    ) {}

    ngOnInit() {
        this.currentUser = this._authService.currentUser;
        if (window.location.pathname.includes('admin')) {
            this.admin = true;
          }
          else if (window.location.pathname.includes('company')) {
            this.company = true;
          }
          else {
            this.admin = false;
          }
        const
            userId = this.currentUser._id,
            patientId = this._route.snapshot.params.id;
        
        this.showLoader();

        this._patientScriptSureService.vendorLogin({ userId, patientId })
            .then(( response: any ) => {
                this.scriptsureLoginData = response.data.scriptsureLoginData;
                this.scriptsureLaunchUrl = response.data.scriptsureLaunchUrl;
                this.isLogoutNeededOnDestroy = true;

                this.hideLoader();
            })
            .catch( error => {
                console.log( 'ERROR!' );
                console.log( error );

                this.goBack();
            });
    }

    ngOnDestroy() {
        if ( this.isLogoutNeededOnDestroy === true ) {
            this.vendorLogout();
        }
    }

    showLoader() {
        this.loader.startLoader( this.loaderId );
    }

    hideLoader() {
        this.loader.stopLoader( this.loaderId );
    }

    goBack() {
        this.hideLoader();
        const backUrl = this._route.snapshot.queryParams.from || '/census';
        this._router.navigateByUrl( backUrl );
    }

    vendorLogout() {
        return this._patientScriptSureService.vendorLogout({ sessionToken: this.scriptsureLoginData.sessionToken });
    }
    
    onClose() {
        this.showLoader();

        this.vendorLogout()
            .catch( error => {
                console.log( 'ERROR!' );
                console.log( error );
            })
            .then(() => {
                console.log( 'LOGGED OUT!' );
                this.isLogoutNeededOnDestroy = false;
                this.goBack();
            });
    }
}
