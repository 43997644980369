import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {CompanyService} from "../../../../services/Modules/company.service";
import {MatTableDataSource} from "@angular/material/table";

@Component({
    selector: 'app-user-ime-list',
    templateUrl: './user-ime-list.component.html',
    styleUrls: ['./user-ime-list.component.css']
})
export class UserImeListComponent implements OnInit {

    public dataSource: any;
    displayedColumns: string[] = ['name', 'abbreviation', 'email',
        'description', 'address'];
    companyId;
    providerId;

    constructor(private route: ActivatedRoute,
                private companyService: CompanyService) {
        this.companyId = this.route.parent.parent.snapshot.params.id;
        this.providerId = this.route.snapshot.params.id;
    }

    ngOnInit(): void {
        this.getIMEs();
    }

    getIMEs() {
        let filter = {
            company_id: this.companyId,
            user_ids: this.providerId,
        };
        this.companyService.getPayors(filter)
            .subscribe((res) => {
                this.dataSource = new MatTableDataSource(res.data.array);
            });
    }

}
