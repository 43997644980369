<div *ngIf="chargesArray" class="flex flex-col gap-1">

    <div class="flex justify-between ietms-center gap-1" *ngIf="chargeFilter.facility_id">
        <div class="flex flex-grow justify-between items-start" style="background: white; border-radius:7px">
            <input style="padding: 10px 3px 10px 15px;" class="search-charge-by-patient-input" type="text" matInput (keyup)="applyFilter($event.target.value)"
                placeholder="Search Charges by Patient" />
        </div>
        <mat-spinner *ngIf="!pagination.shouldShowCount" [diameter]="25"></mat-spinner>
        <div class="flex justify-between items-center" style="flex:13%">
            <button mat-raised-button class="dn-button-primary" (click)="goToPreviousPage()"
                [disabled]="!pagination.hasPrevPage"> Prev</button>
            <span style="color: white;" *ngIf="pagination.shouldShowCount">
                {{pagination.skip + chargesArray.length === 0 ? 0 : pagination.skip + 1 }} - {{pagination.skip +
                chargesArray.length}} out of {{pagination.totalRecords}}
            </span>
            <button mat-raised-button class="dn-button-primary" (click)="goToNextPage()"
                [disabled]="!pagination.hasNextPage">Next</button>
        </div>
    </div>

    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
        <ng-container matColumnDef="index">

            <th mat-header-cell *matHeaderCellDef>
                #
            </th>
            <td mat-cell *matCellDef="let element; let i = index">
                {{i + 1}}
            </td>
        </ng-container>
        <ng-container matColumnDef="date_of_creation">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date of Creation</th>
            <td mat-cell *matCellDef="let element">
                {{getDateOfCreation(element)}}
            </td>
        </ng-container>


        <ng-container matColumnDef="patient_name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Patient </th>
            <td mat-cell *matCellDef="let element">
                {{element?.patient_id?.first_name}}
                {{element?.patient_id?.last_name}}
            </td>

        </ng-container>

        <ng-container matColumnDef="facility_title">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Facility </th>
            <td mat-cell *matCellDef="let element">
                <img src="../../../../assets/icons/pcc-icon.svg" *ngIf="isPCCFacilityDetails(element)" class="pcc-icon"
                    alt="">
                <img src="../../../../assets/icons/matrix.png" *ngIf="isMatrixFacilityDetails(element)"
                    style="height: 12px;
                                                                                                width: 20px;  
                                                                                                margin-right: 5px;
                                                                                                vertical-align: middle;" alt="">
                <img src="../../../../assets/img/DN.png" *ngIf="isDNFacilityDetails(element)" class="dn-icon" alt="">
                {{element?.facility_id_ref?.title}}
            </td>

        </ng-container>

        <ng-container matColumnDef="provider_name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Provider </th>
            <td mat-cell *matCellDef="let element">
                {{element?.owner_id?.first_name}}
                {{element?.owner_id?.last_name}}

            </td>

        </ng-container>

        <ng-container matColumnDef="census_name">
            <th mat-header-cell *matHeaderCellDef> Census </th>
            <td mat-cell *matCellDef="let element">
                {{element?.rounding_sheet_id?.name}}
            </td>

        </ng-container>

        <ng-container matColumnDef="dos">
            <th mat-header-cell *matHeaderCellDef> DOS </th>
            <td mat-cell *matCellDef="let element">
                {{getVisitDate(element)}}
            </td>
        </ng-container>

        <ng-container matColumnDef="dob">
            <th mat-header-cell [hidden]="currentUser.user_type != '6'" *matHeaderCellDef> DOB
            </th>
            <td mat-cell [hidden]="currentUser.user_type != '6'" *matCellDef="let element">
                {{element?.patient_id?.date_of_birth}}
            </td>
        </ng-container>

        <ng-container matColumnDef="updatedAt">
            <th mat-header-cell *matHeaderCellDef> Updated At </th>
            <td mat-cell *matCellDef="let element">
                {{getDateOfUpdation(element) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="progress_status">
            <th mat-header-cell *matHeaderCellDef> Charge Status </th>
            <td mat-cell *matCellDef="let element">
                {{element?.status === 'submit' ? 'submitted': element?.status}}
            </td>
        </ng-container>

        <ng-container matColumnDef="bill_status">
            <th mat-header-cell *matHeaderCellDef> Billed Status </th>
            <td mat-cell *matCellDef="let element">
                <mat-form-field *ngIf="element.status=='submit'" appearance="fill" class="dropdown">
                    <mat-select name="bill_status" [(ngModel)]="element.bill_status"
                        (selectionChange)="billedAllCharges($event.value,element)">
                        <mat-option value="">
                            None
                        </mat-option>
                        <mat-option value="billed">
                            Billed
                        </mat-option>
                        <mat-option value="coded">
                            Coded
                        </mat-option>
                        <mat-option value="addendum_req">
                            Addendum REQ
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="face_sheet">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Face Sheet</th>
            <td mat-cell *matCellDef="let element; let index=index;" style="cursor: pointer;">
                <div class="flex gap-1">
                    <button mat-icon-button matTooltip="Upload Face Sheet" 
                (click)="handleUploadFacesheetClick(element, index)">
                    <mat-icon>file_upload</mat-icon>
                </button>

                <button mat-icon-button matTooltip="Downlaod Face Sheet" 
                [disabled]="!downloadFaceSheetActive(element, index)"
                (click)="handleDownloadFacesheetClick(element, index)">
                    <mat-icon>file_download</mat-icon>
                </button>
                <button mat-icon-button matTooltip="View Face Sheet" [disabled]="!downloadFaceSheetActive(element, index)"
                    (click)="viewPDF(element)">
                    <mat-icon>visibility</mat-icon>
                </button>
                </div>

            </td>
        </ng-container>
        <ng-container matColumnDef="audit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Audit</th>
            <td mat-cell *matCellDef="let element; let index=index;" style="cursor: pointer;">
                <div style="color: blue;" (click)="auditClicked(element)">view</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="biller_note">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Biller Note</th>
            <td mat-cell *matCellDef="let element; let index=index;" style="cursor: pointer;">
                <div *ngIf="element.note!=''" style="color: blue;" (click)="viewBillerNote(element.note)">view</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="operation">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element;let index=index">
                <div class="flex gap-2">
                    <i class="icon icon-file" matTooltip="View Note" matTooltipPosition="above" (click)=" viewNoteDialog(element)"></i>
                    <i class="icon icon-view" matTooltip="Details" matTooltipPosition="above" style="cursor: pointer;"
                        (click)=" openDialog(element);">
                    </i>
                    <i class="icon icon-delete" matTooltip="Delete" matTooltipPosition="above"
                        *ngIf="element.status == 'draft'" (click)="onDeleteClick(element,index)">
                    </i>
                    <i *ngIf="element.status == 'submit' && checkVoidCensus(element.rounding_sheet_id)"
                        class="icon icon-edit" matTooltip="Edit" matTooltipPosition="above"
                        (click)="editCharge(element);" style="cursor: pointer;">
                    </i>
                    <mat-icon style="cursor: pointer;" matTooltip="Comment" matTooltipPosition="above"
                        (click)="openChargeCommentDialog(element, index);">comment</mat-icon>
                    <div *ngIf="!checkVoidCensus(element.rounding_sheet_id)">
                        <img width="22px" src="../../../../assets/icons/void_census.png" alt="">
                    </div>
                </div>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>