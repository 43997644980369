import { Component, Inject, OnInit } from "@angular/core";
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef
} from "@angular/material/dialog";
export interface PatientDialogData {
    patient_first_name: string;
    patient_last_name: string;
    patient_dob: string;
    patient_gender: string;
}
@Component({
    selector: "app-update-patient-info-dialog",
    templateUrl: "./update-patient-info-dialog.component.html",
    styleUrls: ["./update-patient-info-dialog.component.css"]
})
export class UpdatePatientInfoDialogComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: PatientDialogData,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<UpdatePatientInfoDialogComponent>
    ) {}
    patient_first_name: string | null;
    patient_last_name: string | null;
    patient_dob: string | null;
    patient_gender: string | null;
    genderOptions: string[] = ['Male', 'Female'];
    ngOnInit() {
      console.log("data",this.data)
        if (this.data) {
            this.patient_first_name = this.data.patient_first_name;
            this.patient_last_name = this.data.patient_last_name;
            this.patient_dob = this.data.patient_dob;
            this.patient_gender = this.data.patient_gender;
        }
    }
    submit() {
        let data = {
            patient_first_name: this.patient_first_name,
            patient_last_name: this.patient_last_name,
            patient_dob: this.patient_dob,
            patient_gender: this.patient_gender
        };
        if (this.patient_first_name && this.patient_last_name && this.patient_dob && this.patient_gender) {
            this.dialogRef.close(data);
        }
    }
}
