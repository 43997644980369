<table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Date
        </th>
        <td mat-cell *matCellDef="let element">
            {{getDate(element.date_obj) |date: 'MM/dd/yyyy'}}
        </td>
    </ng-container>

    <ng-container matColumnDef="provider">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Provider
        </th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.createdBy">
                {{element.createdBy?.last_name+' '+element.createdBy?.first_name+', '+element.createdBy?.title}}
            </span>
            <span *ngIf="!element.createdBy">
                {{'---'}}
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="facility">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Facility
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.facility?.title || 'Unknown Facility'}}
        </td>
    </ng-container>

    <ng-container matColumnDef="encounters">
        <th mat-header-cell *matHeaderCellDef mat-sort-header-container>
            Encounter Type
        </th>
        <td mat-cell *matCellDef="let element">
            {{(element.transcription && element.transcription.template_id_ref && element.transcription.template_id_ref.for_note_type) ? element.transcription.template_id_ref.for_note_type : "" }}
        </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" 
            style="background-color: pink;"
            [ngStyle]="{'background-color': isToday(row?.date_obj)?'#80dd97':getRowColor(row?.date_obj)?'rgb(191 197 201)':'pink' }"
            >
    </tr>
</table>
<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>