import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {BillerDashboardService} from "../../biller-dashboard/biller-dashboard.service";
import moment from "moment";
import swal from "sweetalert";
import {HttpResponse} from "@angular/common/http";

@Component({
  selector: 'app-provider-charge-capture',
  templateUrl: './provider-charge-capture.component.html',
  styleUrls: ['./provider-charge-capture.component.css']
})
export class ProviderChargeCaptureComponent implements OnInit {
  data: any;
  codes: any;
  allCodes: any = {};
  records: any;
  patients: any;
  provider: any;
  facilities: any;

  constructor(
      private router: Router,
      public billerDashboardService: BillerDashboardService,
  ) {
    this.data = this.router.getCurrentNavigation().extras.state;
  }

  ngOnInit(): void {
    if (this.data) {
      this.records = this.data.patients;
      this.patients = this.data.patients;
      this.provider = this.data.provider;
      this.facilities = this.data.facilities;
    }

  }
  getpatientInfo(patient) {
    let patientInfo = patient.patient_id.first_name + " " + patient.patient_id.last_name
    if (patient.patient_id.date_of_birth) {
      patientInfo = patientInfo + "  (" + new Date(patient.patient_id.date_of_birth).toLocaleDateString() + ")";
    }
    if (patient.patient_id.gender == 'Male') {
      patientInfo = patientInfo + " - M";
    }
    else {
      patientInfo = patientInfo + " - F";
    }
    return patientInfo;
  }
  getProvider(codeIndex, record) {
    let index = this.codes.findIndex(element => element.code == codeIndex.code);
    if (index == 0) {
      return record.owner_id.first_name + " " + record.owner_id.last_name
    }
    else {
      return '';
    }
  }

  getAddress(codeIndex, patient) {
    let index = this.codes.findIndex(element => element.code == codeIndex.code);
    if (index == 0) {
      if (patient && patient.facilityName && patient.facilityName.title) {
        return patient.facilityName.title;
      }
      else {
        return '';
      }
    }
  }
  getVisitDate(codeIndex, record) {
    let index = this.codes.findIndex(element => element.code == codeIndex.code);
    if (index == 0) {
      let date = record.visit_date
      if (date) {
        let visitDate: any;
        visitDate = new Date(date).toLocaleDateString();
        visitDate = visitDate + ' ' + moment(date).format("hh:mm:ss a");
        return visitDate;
        // return new Date(date).toLocaleDateString();
      }
      else {
        return '';
      }
    }
    else {
      return '';
    }
  }
  getCodesLength(record) {
    let icdCode = record.icd_id.length;
    let cptCode = record.cpt_id.length;

    if (icdCode >= cptCode) {
      this.codes = record.icd_id;
      this.allCodes.cpt = record.cpt_id;
      this.allCodes.icd = record.icd_id;
    }
    else {
      this.codes = record.cpt_id;
      this.allCodes.cpt = record.cpt_id;
      this.allCodes.icd = record.icd_id;
    }
    return this.codes;
  }
  getICD(codeIndex, record) {
    let index = this.codes.findIndex(element => element.code == codeIndex.code);
    if (record.icd_id[index]) {
      return record.icd_id[index].code
    }
    else {
      return '';
    }
  }
  getCPT(codeIndex, record) {
    let index = this.codes.findIndex(element => element.code == codeIndex.code);
    if (record.cpt_id[index]) {
      return record.cpt_id[index].code
    }
    else {
      return '';
    }
  }

  getICDName(codeIndex, record) {
    let index = this.codes.findIndex(element => element.code == codeIndex.code);
    if (record.icd_id[index]) {
      return record.icd_id[index].description
    }
    else {
      return '';
    }
  }
  getCPTName(codeIndex, record) {
    let index = this.codes.findIndex(element => element.code == codeIndex.code);
    if (record.cpt_id[index]) {
      return record.cpt_id[index].description
    }
    else {
      return '';
    }
  }
  // logout() {
  //   localStorage.removeItem('currentUser');
  //   setTimeout(() => {
  //     window.location.href = '/login';
  //   });
  // }
  goBack() {
    this.router.navigate(['/provider-charge']);
    // window.location.href = '/provider-charge';
    // this.router.navigateByUrl['/biller-dashboard']
  }

  downloadReport() {

    swal("Download File as", {
      buttons: {
        // cancel: "Run away!",
        pdf: {
          text: "PDF",
          value: "pdf",
        },
        csv: {
          text: "CSV",
          value: "csv",
        },
        Cancel: {
          text: "None",
          value: "none",
        },
        // defeat: true,
      },
    })
        .then((value) => {
          switch (value) {

            case "pdf":
              // swal("pdf selected");
              let innerHtml = document.getElementById("chargeCapture").innerHTML;
              // console.log("innerHtml: ", innerHtml);

              this.billerDashboardService.generatePDFAndDownload(innerHtml).subscribe((res: any) => {
                if (res instanceof HttpResponse) {
                  var url = window.URL.createObjectURL(res.body);
                  var a = document.createElement('a');
                  document.body.appendChild(a);
                  a.setAttribute('style', 'display: none');
                  a.href = url;
                  a.download = 'summary.pdf';
                  a.click();
                  window.URL.revokeObjectURL(url);
                  a.remove(); // remove the element
                } else {
                  if (res.type === 0) {
                  } else if (res.type === 3) {
                    const { loaded, total } = res;
                    const progress = Math.round(loaded / total * 100);
                    if (progress > 0) {
                    }
                  }
                }
              })
              break;

            case "csv":
              // swal("CSV is not availble");
              const csvData = this.createCSV();
              break;

            default:
              // swal("NONE/CANCEL");
          }
        });

  }

  async createCSV() {
    const headerList = ['created_date', 'facility', 'provider', 'dos', 'name', 'dob', 'cpt', 'icd1']
    let array = typeof this.patients != 'object' ? JSON.parse(this.patients) : this.patients;
    let finalJson = [];
    await array.forEach(async patient => {
      let codesLength = await this.getCodesLength(patient)
      codesLength.forEach(async element => {
        let row = {
          created_date: new Date().toLocaleDateString(),
          facility: patient.facilityName.title,
          provider: patient.owner_id.first_name + ' ' + patient.owner_id.last_name,
          dos: patient.visit_date?new Date(patient.visit_date).toLocaleDateString():' ',
          name: patient.patient_id.first_name + ' ' + patient.patient_id.last_name,
          dob: patient.patient_id.date_of_birth?new Date(patient.patient_id.date_of_birth).toLocaleDateString():' ',
          cpt: this.getCPT(element, patient)?this.getCPT(element, patient):' ',
          acd1: this.getICD(element, patient)?this.getICD(element, patient):' ',
        }
        await finalJson.push(row);
      });
    });

    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < finalJson.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index].trim();
        line += ',' + finalJson[i][head];
      }
      str += line + '\r\n';
    }
    this.billerDashboardService.downlaodCSV(str);
    return str;
  }

}
