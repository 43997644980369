import { Component } from '@angular/core';


@Component({
  selector: 'app-facility',
  templateUrl: './facility.component.html',
  styleUrls: ['./facility.component.css']
})

export class FacilityComponent {
  
}
