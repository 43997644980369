import { ENTER } from '@angular/cdk/keycodes';
import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { WoundService } from 'src/app/services/wound.service';
import { PatientRoundingSheetService } from '../../patient-rounding-sheets/patient-rounding-sheet.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { ProcedureNotesService } from 'src/app/services/procedure-notes.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import * as global from '../../../includes/global';

export interface ProcedureType {
  _id?: string,
  title: string,

  createdAt: string|Date,
  updatedAt: string|Date,
}
export interface CPTCode {
    _id? : string,
    description: string,
    code: string,
}
export interface ProcedureDataset {
  _id?: string,
  procedure_type_id: ProcedureType | string,
  
  category: string,
  size: string,
  code_1: CPTCode,
  code_2: CPTCode,
  code_3: CPTCode,
  template: string,
  procedure_note_template: string,
  createdAt: string|Date,
  updatedAt: string|Date,

}


@Component({
  selector: 'app-procedure-datasets',
  templateUrl: './procedure-datasets.component.html',
  styleUrls: ['./procedure-datasets.component.css']
})
export class ProcedureDatasetsComponent implements OnInit {
  @Input() company_id:string;
  separatorKeysCodes: number[] = [ENTER];
 
  procedureTypes: ProcedureType[] = [];
  activeProcedureType:ProcedureType = null;

  procedureDatasets: ProcedureDataset[] = null;

  loaderId = 'app-procedure-datasets';
  loaderId2 = 'app-procedure-types';

  newProcedureTypeInputValue:string = '';
  showNewProcedureTypeInput = false;
  editingProcedureType:ProcedureType= null;

  cptCodeList: any = [];
  tabIndex: number = 0;
  selectedTabIndex: number = 0;
  activatedRouter: string = "Mapping";
  currentUser: any;
  procedureNotePhrases: string[] = [];
  companyPermissions: { should_show_procedure_note: false, should_allow_auto_procedure_coding: false };
  global = global;

  constructor(private _wound: WoundService,
    private toastr: ToastrService,
    private _authService: AuthService,
    private loader: NgxUiLoaderService,
    private _procedureNotes: ProcedureNotesService,
    private companyService: CompanyService,
    private dashboardService: DashboardService){
    this.currentUser = this._authService.currentUser;
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId);
    await this.initProcedureType();
    await this.getCompany();
    this.loader.stopLoader(this.loaderId);
  }
  ngOnDestroy(): void {
    localStorage.removeItem("procedureDatasets");
  }
  async initProcedureType() {
    let {status, data} = await lastValueFrom(this._wound.getProcedureTypes(this.company_id)) as any;
    if (status == 200) {
      this.procedureTypes = data.array as ProcedureType[];
    }
  }

  async getCompany() {
    const response = await lastValueFrom(this.companyService.getCompany({ _id: this.currentUser.company_id }, { should_show_procedure_note: 1, should_allow_auto_procedure_coding: 1 }));
    if (response.status == 200) {
      this.companyPermissions = response.data;
    } else {
      this.companyPermissions = {
        should_show_procedure_note: false,
        should_allow_auto_procedure_coding: false
      };
    }
  }



  async initProcedureDatasets() {
    let {status, data} = await lastValueFrom(this._wound.getProcedureDatasets(this.activeProcedureType._id)) as any;
    if (status == 200) {
      this.procedureDatasets = data.array as ProcedureDataset[];
      if (this.companyPermissions.should_show_procedure_note) {
        await this.getProcedureNotePhrases();
      }
      localStorage.setItem("procedureDatasets",JSON.stringify(this.procedureDatasets));
    }
  }
  
  async getProcedureNotePhrases() {
    try {
      const isMedicalAssistant = this.currentUser.user_type === 'Medical Assistant';
      const result = await lastValueFrom(this._procedureNotes.getProcedureNoteDataset(isMedicalAssistant ? global.USER_TYPE.DOCTOR : this.currentUser.user_type, this.activeProcedureType._id, this.currentUser.company_id));
      if (result['status'] === 200) {
        this.procedureNotePhrases = result['data'];
      } else {
        this.procedureNotePhrases = [];
      }
    } catch (error) {
      this.toastr.error('Something went wrong', 'Failed');
    }
  }



  isEditingProcedureType(procedureType, i) {
    return this.editingProcedureType === procedureType;
  }

  async addNewProcedureType(value, i?) {
    const infoToast = this.toastr.info('Adding Procedure Type', 'Please wait', { disableTimeOut: true });
    let {status, data} = await lastValueFrom(this._wound.addProcedureType(this.company_id, value)) as any;
    if (status == 200) {
      this.procedureTypes.push(data as ProcedureType);
      this.newProcedureTypeInputValue = '';
      this.showNewProcedureTypeInput = false;
      this.toastr.clear(infoToast.toastId);
      this.toastr.success("Added Successfully", "Success");
    }else{
      this.toastr.clear(infoToast.toastId);
    }
  }
  editProcedureType(procedureType, i) {
    this.editingProcedureType = procedureType;
    this.newProcedureTypeInputValue = '';    
  }


  handleAddNewProcedureType() {
    this.showNewProcedureTypeInput = true;
  }
  handleEditProcedureTypeInputValueChange($event, i) {
    if ($event.keyCode === ENTER) {
      this.addNewProcedureType($event.target.value, i);
      this.editingProcedureType = null;
    }
  }

  updateProcedureType(value, i) {
    this.procedureTypes[i].title = value;
    this.editingProcedureType = null;
  }

  async deleteProcedureType(procedureType, i) {
    const messageData = {
      message: `Are you sure you want to delete this ${procedureType.title} Procedure?`
    }
    const dialogBoxValue = await this.dashboardService.DeleteConfirmationDialog(messageData.message);
    if (dialogBoxValue === "true") {
    const infoToast = this.toastr.info('Deleting Procedure Type', 'Please wait', { disableTimeOut: true });
    const {status, data} = await lastValueFrom(this._wound.updateProcedureType(procedureType._id, undefined, true)) as any;
    if(status == 200) {
      if(this.activeProcedureType?._id == procedureType._id) {
        this.activeProcedureType = null;
      }
      this.toastr.clear(infoToast.toastId);
      this.toastr.success("Deleted Successfully", "Success");
      this.procedureTypes.splice(i, 1);
    }
   }
   else{
    () => this.toastr.error(`Something went wrong while removing ${procedureType} Procedure`, 'Failed')
   } 
  }

  async handleProcedureTypeClick(procedureType, i) {
    this.loader.startLoader(this.loaderId2);
    this.activeProcedureType = procedureType;
    await this.initProcedureDatasets();
    this.selectedTabIndex = 0;
    this.tabIndex = 0;
    this.activatedRouter = 'Mapping';
    this.loader.stopLoader(this.loaderId2);
  }
  onTabClick(event:any){
    if (event?.index == 0) {
      this.tabIndex = 0;
      this.activatedRouter = 'Mapping';
      this.selectedTabIndex = 0;
    }
    else if(event?.index == 1){
      this.tabIndex = 1;
      this.activatedRouter = 'Category Templates';
      this.selectedTabIndex = 1;
    }
  }
}
