import { Component, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { UserTypesRelationshipService } from '../../services/user-types-relationship.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserManagementService } from '../user-management/user-management.service';
import { ToastrService } from 'ngx-toastr';
import { RolesDialog } from '../../shared/popup-dialogs/roles-dialog/roles.dialog';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import * as global from '../../includes/global'
import { CompanyService } from 'src/app/services/Modules/company.service';
import { FormControl } from '@angular/forms';
import { Observable, lastValueFrom } from 'rxjs';
import { startWith, map } from 'rxjs/operators'
import { ProfileService } from '../profile/profile.service';
import { HttpResponse } from '@angular/common/http';
import moment from 'moment'
import { CommonService } from 'src/app/services/common.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DialogConfirmationComponent } from '../census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { UserService } from 'src/app/services/Modules/user.service';
export interface OnInit {
    ngOnInit(): void;
}

@Component({
    selector: 'app-transcriber',
    templateUrl: './transcriber-list.component.html',
    styleUrls: ['./transcriber-list.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class TranscriberListComponent implements OnDestroy {
    usersFilter = {
        user_type: '2'
    };
    disabled: Boolean = false;
    public transcibers: any = [];
    public currentUser: any;
    public response: any;
    public dataSource: any;
    companyPath: Boolean = false;
    public roleDialogRef;
    company_id: any;
    is_activated = 'true';
    currentCompany: any;
    localOrDevEnvironment: Boolean = false;
    displayedColumns: String[];
    companies: any = [];
    filteredCompanies: Observable<string[]>;
    companyControl = new FormControl();
    userRoleTypeSubscription: any;
    userNameRegExp: any = "";
    hasTranscribersLoaded: boolean = false;
    pagination = {
        pageNo: 1,
        hasNextPage: false,
        hasPrevPage: false,
        totalRecords: 0,
        PAGE_LIMIT: 50,
        skip: 0
    };
    timeoutId: any;
    selectedRoleType: string;
    selectedUserType: any;
    selectedUserTypeSubscription: any;
    loaderId = 'transcriber-list-loader';
    company_type: any;
    selectedPermissions: string[] = [];
    permissionsList: any;
    company: any;
    public constructor(
        private loader: NgxUiLoaderService,
        public services: UserTypesRelationshipService,
        private _router: Router,
        private _profileService: ProfileService,
        private route: ActivatedRoute,
        private userManagementService: UserManagementService,
        private toastr: ToastrService,
        private dialog: MatDialog,
        private _authService: AuthService,
        private companyService: CompanyService,
        private commonService: CommonService,
        private userService: UserService,

    ) {
        this.displayedColumns = ['first_name', 'last_name', 'email', 'last_login', 'timezone', 'status', 'enable_dictation', 'tat_status', 'rrgen', 'cognito', 'simulate', 'user-permissions'];
        this.companyPath = window.location.pathname.includes('company');
        this.currentUser = this._authService.currentUser;
        if (this.currentUser.admin_type === "full_admin" || this.currentUser.admin_type === "system_admin") {
            this.displayedColumns.push('user-type');
        }
        if (this.currentUser.product_owner) {
            this.displayedColumns.push('companies');
        }
        // this.displayedColumns.push('operations');
        if (!this.currentUser.product_owner) {
            this.displayedColumns = this.displayedColumns.filter(f => f !== 'two-factor');
            this.displayedColumns = this.displayedColumns.filter(f => f !== 'cognito');
        }
        if (!this.currentUser) {
            // if not login, stop and redirect to login #mohsin-dev
            localStorage.setItem('currentUser', 'null');
            this._router.navigate(['/login']);
            return;
        }
        // this.company_id = this.currentUser.company_id;
        // if(this.currentUser.account_type == 'company'){ 
        //     this.company_id = this.currentUser.company_id;
        //   }
        // if(this.currentUser.account_type == 'company' && !window.location.pathname.includes('manage-companies')){  

        if (window.location.pathname.includes('company') && !window.location.pathname.includes('manage-companies')) {
            this.company_id = this.route.parent.parent.parent.snapshot.params.id;
        }
        else if (window.location.pathname.includes('manage-companies')) {
            this.company_id = this.route.parent.parent.parent.snapshot.params.id;
        }
    }
    @ViewChild(MatSort) sort: MatSort;

    ngOnDestroy(): void {
        if (this.userRoleTypeSubscription) {
            this.companyService.changeUserRoleType(null);
            this.userRoleTypeSubscription.unsubscribe();
        }
        if (this.selectedUserTypeSubscription) {
            this.selectedUserTypeSubscription.unsubscribe();
        }
    }


    async ngOnInit() {
        this.userService.getUserPermission(this.company_id).subscribe((response: any) => {
            if (response.status !== 200) return;
            this.permissionsList = response.data.filter((permission) => {
                if (permission.key == "is_dictation_enabled" || permission.key == "has_pcc_account") {
                    return true;
                }
                return false;
            });
        });
        await this.getCompany();
        try {
            if (this.currentUser.product_owner) {
                await this.getCompanies();
            }
            this.getTranscribers();
            this.filteredCompanies = this.companyControl.valueChanges.pipe(
                startWith(''),
                map((value: any) => this.filterCompany(value)));

            this.selectedUserTypeSubscription = this.commonService.castselectedUserType.subscribe(userType => {
                if (userType) {
                    this.selectedUserType = userType;
                }
            });

            this.userRoleTypeSubscription = this.companyService.castUserRoleType.subscribe(role => {
                if (role) {
                    this.selectedRoleType = role;
                    this.getTranscribers();
                }
            });
        } catch (err) {
            console.log("err", err);
        }
        if (this.company.portal_type === global.PORTAL_TYPE.NON_WC) {
            this.displayedColumns = this.displayedColumns.filter(column => column != 'timezone' && column != 'enable_dictation' && column != 'tat_status' && column != 'rrgen' && column != 'simulate');
        }
    }
    private filterCompany(value: string): string[] {
        if (value) {
            const filterValue = value.toLowerCase();
            return this.companies.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
        }
        else {
            return this.companies;
        }
    }

    isOptionVisibleForCompany(option) {
        const companyId = this.route.parent.parent.parent.snapshot.params.id;
        return this.userService.isOptionVisibleForCompany(option, companyId);
    }

    searchTranscribers() {
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
            this.hasTranscribersLoaded = true;
            this.getTranscribers();
        }, 1000);
    }

    getCompanies() {
        return new Promise<void>((resolve, reject) => {
            this.companyService.getAllCompanies().subscribe(async (response: any) => {
                if (response.status == 200) {
                    this.companies = response.data;
                    this.currentCompany = this.route.parent.parent.parent.snapshot.params.id
                    var index = this.companies.findIndex(company => this.currentCompany === company._id);
                    this.currentCompany = this.companies[index];
                    this.companies.splice(index, 1);
                    this.companyControl.setValue(this.currentCompany?.name);
                    resolve(null);
                }
                else if (response.status == 403) {
                    this.toastr.error(response.message, "ERROR");
                }
            }, err => {
                reject();
                this.toastr.error(err, "ERROR");
            });
        });
    }

    async getCompany() {
        const filter = {
            _id: this.company_id
        };
        const project = {
            name: 1,
            portal_type: 1,
            company_type: 1
        };
        const { status, data }: any = await lastValueFrom(this.companyService.getCompany(filter, project));
        if (status == 200) {
            this.company = data;
        }
        else if (status === 500) {
            this.toastr.error(data.message, "ERROR");
        }
    }

    deleteUser(id: string) {
        let dialogRef = this.dialog.open(DialogConfirmationComponent, {
            data: {
                message: "Are you sure you want to delete this User ?"
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result === "true") {
                this._profileService.deleteUser(id).subscribe((res: any) => {
                    // this.doctors = this.doctors.filter(user => user._id !== id)
                    // this.dataSource.data = [];
                    // this.dataSource.data = this.doctors;
                    this.getTranscribers();
                });
            } else {
                return;
            }
        })
    }

    async getTranscribers() {
        let pageNo = this.pagination.pageNo;
        this.services.getTranscriberList(this.is_activated, this.company_id, pageNo, this.userNameRegExp, this.selectedRoleType).subscribe((data: any) => {
            this.response = data;
            this.pagination.totalRecords = data.totalRecords;
            this.transcibers = this.response.data;
            this.hasTranscribersLoaded = false;


            if (this.userNameRegExp !== "") { //update page count for title search
                this.pagination.totalRecords = data.totalRecords;
                this.pagination.hasNextPage = false;
                this.pagination.hasPrevPage = false;
                this.pagination.skip = 0;
            }
            this.sortUsers(this.transcibers);
            this.handlePagination();
            let perListKeys = this.permissionsList.map(per => per.key)
            this.transcibers.forEach(transcriber => {
                transcriber.companies = this.companies
                if (transcriber.recentLogin) {
                    transcriber.recentLogin = moment(transcriber.recentLogin).tz('America/Los_Angeles').format('YYYY-MMM-DD h:mm A');
                }
                this.companies.forEach(company => {
                    if (transcriber.company_id == company._id) {
                        transcriber.selectedCompany = company._id;
                    }
                });
                if (transcriber.admin_type == "full_admin" || transcriber.admin_type == "system_admin") {
                    transcriber.admin = 'full_admin'
                }
                else if (transcriber.admin_type == "transcriber_admin") {
                    transcriber.admin = 'transcriber_admin'
                }
                else if (transcriber.admin_type == "none") {
                    transcriber.admin = 'none'
                }
                transcriber.permissions = transcriber.permissions.map(per => per.key);
                transcriber.permissions = transcriber.permissions.filter(per => perListKeys.includes(per))
            });
            this.dataSource = new MatTableDataSource(this.transcibers);
        });
    }

    async changeUserPermissions(element, event) {
        this.selectedPermissions = event.value;
        element.permissions = event.value;
        let filterArray = this.permissionsList.filter((per) => element.permissions.findIndex((selectedPer) => selectedPer === per.key) > -1);
        let details = {
            userId: element._id,
            company_id: this.company_id,
            filterArray: filterArray,
        }
        await this.userService.setUserPermission(details).subscribe((response: any) => {
            if (response.status === 200) {
                console.log("permissions set successfully");
                this.toastr.success("Permission Updated successfully");
            }
        });
    }
    resolvePermission(key) {
        if (key) {
            let index = this.permissionsList.findIndex(permissions => permissions.key == key);
            return this.permissionsList[index].value;
        }
    }

    handlePagination() {
        this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
        if (this.pagination.skip + this.transcibers.length < this.pagination.totalRecords) {
            this.pagination.hasNextPage = true;
        }
        else {
            this.pagination.hasNextPage = false;
        }
        if (this.pagination.skip > 0) {
            this.pagination.hasPrevPage = true;
        }
        else {
            this.pagination.hasPrevPage = false;
        }
    }

    goToPreviousPage() {
        this.pagination.pageNo--;
        this.getTranscribers();
    }
    goToNextPage() {
        this.pagination.pageNo++;
        this.getTranscribers();
    }

    onCompanyChange(element, company) {
        this.companyService.companyChangeForUser(element._id, company).subscribe((response: any) => {
            if (response.status == 200) {
                this.toastr.success("Company Changed")
                this.dataSource.data = this.dataSource.data.filter(user => user._id !== element._id);
                // this.getCompanies();
            }
        })
    }
    handleChangeAdminType(userId, event) {
        var update = {
            admin_type: event.value,
            user_id: userId
        }
        this._profileService.updateAdminType(update).subscribe((response: any) => {
            if (response.status == 200) {
                this.toastr.success("Admin type changed");
                //  this.getDoctorList();
            }
        });
    }

    changeTwoFactorAllowance(provider, status) {
        let updateObject = {
            _id: provider._id,
            allow_two_factor_change_by_admin: status
        };

        this.userManagementService.updateUser(updateObject).subscribe((response: any) => {

            if (response.status == 200) {
                this.toastr.success(response.message, 'Success');
            }
            else {
                this.toastr.error(response.message, 'Failed');
            }
        })
    }
    // isChecked(element){
    //     return element.other_settings.show_tata
    // }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }
    checkEnvironment() {
        if (global.ENVIRONMENT == 'LOCAL' || global.ENVIRONMENT == 'DEV') {
            return true;
        }
        else {
            return false;
        }
    }

    applyUserFilter(event) {
        let pageNo = this.pagination.pageNo;
        this.userNameRegExp = '';
        this.is_activated = event.value == "true" ? "true" : "false";
        // this.userNameRegExp = localStorage.removeItem("transcriber-provider-name");

        this.services.getTranscriberList(this.is_activated, this.company_id, pageNo, this.userNameRegExp, this.selectedRoleType).subscribe(data => {
            //sorting algorithm
            this.response = data;
            this.transcibers = this.response.data;
            this.transcibers.forEach(transcriber => {
                if (transcriber.admin_type == "full_admin" || transcriber.admin_type == 'full_admin' || transcriber.admin_type == 'system_admin') {
                    transcriber.admin = 'full_admin'
                }
                else if (transcriber.admin_type == "transcriber_admin") {
                    transcriber.admin = 'transcriber_admin'
                }
                else if (transcriber.admin_type == "none") {
                    transcriber.admin = 'none'
                }
            });
            this.dataSource = new MatTableDataSource(this.transcibers);
        });
    }

    changeUserActiveStatus(userId: string, status) {
        this.userManagementService.changeUserActiveStatus(userId, status).subscribe((response: any) => {
            if (response.status === 200) {
                this.toastr.success(response.message, 'Success');
            } else {
                this.toastr.error(response.message, 'Failed');
            }
        })
    }
    changeAdminStatus(transcriber, status) {
        this.userManagementService.changeAdminStatus(transcriber._id, this.company_id, status).subscribe((response: any) => {
            if (response.status == 200) {
                this.toastr.success(response.message, 'Success');
            }
            else {
                this.toastr.error(response.message, 'Failed');
            }
        })
    }
    changeTATStatus(transcriber, status) {
        this.userManagementService.changeTATStatus(transcriber._id, status).subscribe((response: any) => {
            if (response.status == 200) {
                this.toastr.success(response.message, 'Success');
            }
            else {
                this.toastr.error(response.message, 'Failed');
            }
        })
    }
    changeRRGenStatus(transcriber, status) {
        this.userManagementService.changeRRGenStatus(transcriber._id, status).subscribe((response: any) => {
            if (response.status == 200) {
                this.toastr.success(response.message, 'Success');
            }
            else {
                this.toastr.error(response.message, 'Failed');
            }
        })
    }
    getAdminStatus(transcriber) {
        if (transcriber && transcriber.admin_type) {
            if (transcriber.admin_type == 'full_admin' || transcriber.admin_type == 'system_admin') {
                return true;
            }
            else {
                return false;
            }
        }
    }

    showRolesDialog(user) {
        this.roleDialogRef = this.dialog.open(RolesDialog, { width: '80%', data: { user } });
    }
    handleChangeUserType(userId, event) {
        let additionalRole: string = '';
        var update = {
            user_type: event.value,
            user_id: userId
        }
        if (event.value == 'test_provider') {
            additionalRole = 'demo_provider';
        } else if (event.value == 'cnp') {
            additionalRole = 'cnp';
        }
        this._profileService.updateUserType(update, additionalRole).subscribe((response: any) => {
            if (response.status == 200) {
                this.toastr.success("User type changed");
                this.getTranscribers();
            }
        });
    }

    ResendEmail(userId) {
        this.companyService.ResendEmail(userId).subscribe((response: any) => {
            if (response.status === 200) {
                this.toastr.success("Email sent successfully");
            }
        });
    }
    changeCognitoStatus(transciber, status) {
        let updateObject = {
            _id: transciber._id,
            enableCognito: status
        };

        this.userManagementService.updateUser(updateObject).subscribe((response: any) => {

            if (response.status == 200) {
                this.toastr.success(response.message, 'Success');
            }
            else {
                this.toastr.error(response.message, 'Failed');
            }
        })
    }

    changeMatrixStatus(provider, status) {
        this.userManagementService.changeMatrixStatus(provider._id, status).subscribe((response: any) => {
            if (response.status == 200) {
                this.toastr.success(response.message, 'Success');
            }
            else {
                this.toastr.error(response.message, 'Failed');
            }
        })
    }

    exportUsersAsXLSX() {
        this.loader.startLoader(this.loaderId);
        this.companyService.exportUsersAsXLSX(this.company_id, this.selectedUserType, this.is_activated).subscribe((response: any) => {
            if (response instanceof HttpResponse) {
                this.loader.stopLoader(this.loaderId);
                // this.toastr.success("Status changed");
                var url = window.URL.createObjectURL(response.body);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = `Users`;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
            }
        });
    }
    changeDictationStatus(provider, status) {
        let updateObject = {
            _id: provider._id,
            is_dictation_enabled: status
        };

        this.userManagementService.updateUser(updateObject).subscribe((response: any) => {

            if (response.status == 200) {
                if (this.currentUser._id === response.data._id) {
                    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
                    currentUser.is_dictation_enabled = response.data.is_dictation_enabled;
                    localStorage.setItem("currentUser", JSON.stringify(currentUser));
                }
                this.toastr.success(response.message, 'Success');
            }
            else {
                this.toastr.error(response.message, 'Failed');
            }
        })
    }
    sortUsers(arr: any[]) {
        arr.sort((a, b) => a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase()));
    }
}
