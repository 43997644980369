<div style="position: relative;">
    <form class="patient-rounding-sheet-form flex flex-col gap-5" style="margin:auto"
        (submit)="handleSubmit()">
        <h3>Move Wound To</h3>
        <mat-radio-group class="flex flex-row gap-5" name="move_to" [(ngModel)]="move_to"
            (ngModelChange)="changeMoveTo(patientRoundingSheet)">
            <mat-radio-button value="census">Census</mat-radio-button>
            <mat-radio-button
                *ngIf="global.ENVIRONMENT === this.global.LOCAL || global.ENVIRONMENT === this.global.DEV || global.ENVIRONMENT === this.global.WOUNDCARE_DEV"
                value="patient">Patient</mat-radio-button>
        </mat-radio-group>
        <div class="flex flex-col">
            <mat-form-field class="flex-grow">
                <mat-select name="patientRoundingSheet" [(ngModel)]="patientRoundingSheet" placeholder="Select Census"
                    (selectionChange)="changeMoveTo($event.value)" #censusSelect>
                    <mat-option *ngFor="let patientRoundingSheet of filteredRoundingSheets()"
                        [value]="patientRoundingSheet">
                        {{patientRoundingSheet.name}} {{getCensusDate(patientRoundingSheet)}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="filteredRoundingSheets().length === 0">No Census in this facility</mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="move_to=='patient'" class="flex-grow">
                <mat-select name="patient" [(ngModel)]="patient" placeholder="Select Patient">
                    <mat-option *ngIf="censusPatients.length === 0" value="" [disabled]="true">
                        No Patient in this Census
                    </mat-option>
                    <mat-option *ngFor="let patient of censusPatients" [value]="patient">
                        <app-patient-title [patient]="patient"></app-patient-title>
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="censusPatients.length === 0">No Patient in this Census</mat-error>
            </mat-form-field>
        </div>
        <div class="flex flex-row justify-end items-center">
            <button mat-raised-button class="dn-button-primary" type="submit"
                [disabled]="disableCreateButton">Move</button>
        </div>
    </form>
</div>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>