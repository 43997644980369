import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NoteIcdSuggestionsService } from './note-icd-suggestions.service';

@Component({
  selector: 'app-note-icd-suggestions',
  templateUrl: './note-icd-suggestions.component.html',
  styleUrls: ['./note-icd-suggestions.component.css']
})
export class NoteIcdSuggestionsComponent implements OnInit {

  dataSource: any = new MatTableDataSource([]);
  loaderId = 'app-note-icd-suggestions';
  private _noteText: string;
  @Input() noteId: any;
  timeoutId: any;
  @Input() noteText: any;
  @Input() selectedDiagnoses: any = [];
  @Input('fvrtICDs') fvrtICDs = [];
  // @Output() isChecked = new EventEmitter<any>();
  @Output() checkboxStateChange = new EventEmitter<any>();
  @Output() addToFromFavorites = new EventEmitter<any>();
  @Output() removeFromFavorites = new EventEmitter<any>();
  icdGroups: any = [];
  icds: any = [];
  displayedColumns: string[] = ['checkbox', 'code', 'fvrt'];
  // @Input() set noteText(value: string) {
  //   this._noteText = value;
  //   console.log("input change detected");
  // }
  // get noteText(): string {
  //   return this._noteText;
  // }

  ngOnChanges(changes: SimpleChanges) {
    clearTimeout(this.timeoutId);
    if (changes.noteText.currentValue !== changes.noteText.previousValue) {
      this.timeoutId = setTimeout(async () => {
        await this.initICDSuggestions();
      }, 5000);
    }
  }

  constructor(
    private loader: NgxUiLoaderService,
    private _toastr: ToastrService,
    private _noteIcdSuggestionsService: NoteIcdSuggestionsService,
  ) {
  }

  async ngOnInit() {
    if (this.noteText) {
      await this.initICDSuggestions();
    }
  }
  async initICDSuggestions() {
    return new Promise<void>((resolve, reject) => {
      this.loader.startLoader(this.loaderId);
      this._noteIcdSuggestionsService.getICDSuggestionsFromPythonService(this.noteText).subscribe((response: any) => {
        if (response.status == 200 && response.data.length > 0) {
          // if (response.data.length === 1) {
          //   this.icdGroups = response.data[0].suggestions;
          //   console.log("icds", this.icdGroups);
          //   // this.dataSource.data = this.icdGroups;
          // }
          this.icds = this.normalizeData(response.data);
          this.dataSource.data = this.icds;
          this.loader.stopLoader(this.loaderId);
          resolve(null);
        }
        else {
          resolve(null);
          this.loader.stopLoader(this.loaderId);

        }
      }, err => {
        this.loader.stopLoader(this.loaderId);
        this._toastr.error(err, "ERROR");
        reject();
      }
      );
    });
  }
  normalizeData(icds) {
    return icds.map(icd => {
      if (this.fvrtICDs.length > 0 && this.fvrtICDs.some(fvrtIcd => fvrtIcd._id === icd._id)) icd.fvrt = true;
      else icd.fvrt = false;
      if (this.selectedDiagnoses.length > 0 && this.selectedDiagnoses.some(selectedDiagnose => selectedDiagnose._id === icd._id)) icd.isSelected = true;
      else icd.isSelected = false;
      return icd;
    });
  }
  async uploadNoteTextToBucket() {
    return new Promise<void>((resolve, reject) => {
      this.loader.startLoader(this.loaderId);
      this._noteIcdSuggestionsService.uploadNote(this.noteId, this.noteText).subscribe((response: any) => {
        if (response.status == 200) {
          this.loader.stopLoader(this.loaderId);
          resolve(null);
        }
        else {
          this.loader.stopLoader(this.loaderId);
          resolve(null);
        }
      }, err => {
        this.loader.stopLoader(this.loaderId);
        this._toastr.error(err, "ERROR");
        reject();
      }
      );
    });
  }

  onCheckboxChange($event, icd) {
    this.checkboxStateChange.emit(icd);
  }
  toggleFavouriteDiagnoses(icd, action = "add") {
    if (action == "add") {
      icd.fvrt = true;
      this.addToFromFavorites.emit(icd);
    } else {
      icd.fvrt = false;
      this.removeFromFavorites.emit(icd);
    }
  }
}
