import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as global from '../global';
import { API_URL } from "../../../environments/api-url";

/*
* implemented #mohsin-dev
*/


@Injectable({
    providedIn: 'root',
})
export class ForgetPasswordService {

    public constructor(private httpclient: HttpClient) {

    }

    checkCognitoStatus(details) {

        return this.httpclient.post(global.url + API_URL.AUTH.getCognitoStatus, { details: details }).pipe();
    }
    sendPasswordResetCode(details) {
        return this.httpclient.post(global.url + API_URL.AUTH.sendPasswordResetCode, { details: details }).pipe();
    }
    sendCognitoResetCode(details) {
        return this.httpclient.post(global.url + API_URL.AUTH.forgotCognitoPassword, { details: details }).pipe();
    }
    confirmForgotCognitoPassword(details) {
        return this.httpclient.post(global.url + API_URL.AUTH.confirmForgotCognitoPassword, { details: details }).pipe();

    }
    updatePassword(details) {
        return this.httpclient
            .post(global.url + API_URL.AUTH.updatePassword, { details: details }).pipe();
    }
}
