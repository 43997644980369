import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import * as global from '../../global'
import { TranscriptionCreateService } from '../transcription-create.service';
import { AddendumService } from './addendum-service.service';
import { ViewNoteAddendumDialog } from '../view-note-addendum-dialog/view-note-addendum-dialog.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-note-addendums',
  templateUrl: './note-addendums.component.html',
  styleUrls: ['./note-addendums.component.css']
})
export class NoteAddendumsComponent implements OnInit {
  global=global;
  @Input() note: any;
  @Output() onAddendumClick:EventEmitter<any> = new EventEmitter()

  addendums:any[] = [];

  constructor(
    // private transcriptionCreateService:TranscriptionCreateService,
    private addendumService:AddendumService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.initAddendums();    
  }
  initAddendums() {
    this.addendumService.getNoteAddendums({noteId: this.note._id}).subscribe((response:any) => {
      if(response.status === 200) {
        this.addendums = response.data.array;

        this.addendumService.castAddendums.subscribe(addendums => this.addendums = addendums)

      }
    });
  }
  showAddendumNoteDialog(addendum) {
      console.log(addendum);
      
      const dialogRef = this.dialog.open(ViewNoteAddendumDialog, {
          width: '80%',
          data: {
              addendum,
              note: this.note,
          }
      });
  }
  handleAddendumClick(e, addendum) {
    if(e.target.classList.contains('icon-view')) return;
    if(addendum.uploadedAt) return;
    this.onAddendumClick.emit(addendum);    
  }
}
