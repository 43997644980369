<div class="flex flex-col gap-2">
  
    <div style="height: 400px;">
        <ckeditor
            [(ngModel)]="addNotes"
            name="notes_value" class="h100"
            editorUrl="../../../../assets/ckeditor/ckeditor.js" 
            [readOnly]="!cke4Config.editable" 
            [config]="cke4Config"
            type="divarea" (ready)="onReadyCkeditor($event)"></ckeditor>
    </div>

    <div class="flex justify-center items-center gap-2">
        <button class="dn-button-primary" mat-raised-button [mat-dialog-close]="addNotes" [disabled]="">Save</button>
        <button class="dn-button-secondary" mat-raised-button type="button" mat-dialog-close>Cancel</button>
    </div>
</div>

<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>