<div class="flex flex-col gap-2">
    <mat-form-field>
        <input matInput type="text" name="phrase_key" [value]="phrase_key" placeholder="Phrase" [disabled]="true">
    </mat-form-field>
    <ng-container>
        <div style="height: 400px;">
            <ckeditor [(ngModel)]="phrase_template" name="phrase_value" class="h100"
                editorUrl="../../../../assets/ckeditor/ckeditor.js" [readOnly]="!cke4Config.editable"
                [config]="cke4Config" type="divarea" (ready)="onReadyCkeditor($event)"></ckeditor>
        </div>
    </ng-container>
    <div *ngIf="!viewOnly" class="flex flex-row justify-center items-center gap-2">
        <button class="dn-button-primary" mat-raised-button (click)="saveProceduretemplate()">Save</button>
        <button class="dn-button-secondary" mat-raised-button type="button" mat-dialog-close>Cancel</button>
    </div>
</div>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>