import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PCCService } from 'src/app/services/pcc.service';
import * as global from '../../global';
import { eventTypes as pccEventTypes } from 'src/app/constants/pcc_webhook_constants';
@Component({
  selector: 'app-pcc-event-notifications',
  templateUrl: './pcc-event-notifications.component.html',
  styleUrls: ['./pcc-event-notifications.component.css']
})
export class PccEventNotificationsComponent implements OnInit, OnChanges {
  global = global;
  pccEventTypes = pccEventTypes;
  pccEventNotifications: any[] = [];
  @Input() title: string = 'PCC Event Notifications';
  @Input() eventTypes: string[] = [];
  @Input() facilityIds: string[] = [];
  @Output() onImportPatient: EventEmitter<string> = new EventEmitter();
  constructor(
    private pccService: PCCService
  ) { }

  async ngOnInit() {
    await this.initPCCEventNotifications(this.eventTypes, this.facilityIds);
    console.log("pccEventTypes", pccEventTypes.PATIENT_ADMIT);
  }

  async initPCCEventNotifications(eventTypes, facilityIds) {
    const response: any = await this.pccService.getPCCEventNotifications(eventTypes, facilityIds).toPromise();
    if (response.status === 200) {
      this.pccEventNotifications = response.data.array.filter(n => !!n.patient);
    }
  }
  async ngOnChanges(changes: SimpleChanges) {
    const facilityIdsChanges = changes['facilityIds'];
    let currFacilityIds = JSON.stringify(facilityIdsChanges.currentValue);
    let prevFacilityIds = JSON.stringify(facilityIdsChanges.previousValue);
    if (currFacilityIds !== prevFacilityIds) {
      await this.initPCCEventNotifications(this.eventTypes, this.facilityIds);
    }
  }

  importPCCPatient(notification) {
    this.onImportPatient.emit(notification.patientId);
    notification.isImported = true;
  }
  applyColorOnPccEventTypes(eventType) {
    return {
      "min-width": "100px",
      ...(this.pccEventTypes.PATIENT_ADMIT === eventType
        && { "background-color": "#18ff004f" }),
      ...(this.pccEventTypes.PATIENT_TRANSFER === eventType
        && { "background-color": "#0000ff4f" }),
      ...(this.pccEventTypes.PATIENT_CANCELADMIT === eventType
        && { "background-color": "#ff00005e" }),
      ...(this.pccEventTypes.PATIENT_CANCELDISCHARGE === eventType
        && { "background-color": "#ff000029" }),
      ...(this.pccEventTypes.PATIENT_CANCELLEAVE === eventType
        && { "background-color": "#ff00004a" }),
      ...(this.pccEventTypes.PATIENT_CANCELRETURNFROMLEAVE === eventType
        && { "background-color": "#ff6f0069" }),
      ...(this.pccEventTypes.PATIENT_DISCHARGE === eventType
        && { "background-color": "#f700ff30" }),
      ...(this.pccEventTypes.PATIENT_LEAVE === eventType
        && { "background-color": "#ff1f005e" }),
      ...(this.pccEventTypes.PATIENT_READMIT === eventType
        && { "background-color": "#ff2f0062" }),
      ...(this.pccEventTypes.PATIENT_RETURNFROMLEAVE === eventType
        && { "background-color": "#ff9f0030" }),
      ...(this.pccEventTypes.PATIENT_UPDATERESIDENTINFO === eventType
        && { "background-color": "#ff8f0071" }),
    }
  }
}
