<div [ngClass]="{'container': (companySide || user_dashboard) && !(nursingSide)}">

    <h3 class="dark-blue-heading flex justify-between items-center">
        <ng-container *ngIf="!user_dashboard">
            <button type="button" mat-raised-button class="dn-button-secondary" (click)="goBack()">
                Backing
            </button>
        </ng-container>

        <label [ngClass]="{'label-margin': !user_dashboard}"> QAPI Report </label>

        <ng-container *ngIf="filter.reportType !== 'rehospitalization'">
            <div>
            <input matInput *ngIf="iscreationDateFilterSelected" type="text" ngxDaterangepickerMd
                [(ngModel)]="filter.creationDate" (datesUpdated)="changeCreationDate($event)"
                class="mat-like-input" [ranges]="preDateRanges" [alwaysShowCalendars]="true"
                [maxDate]="currentDate" [linkedCalendars]="true" [showClearButton]="false"/>
            </div>
        </ng-container>
        
        <button mat-raised-button class="dn-button-primary" [matMenuTriggerFor]="sendMenu">Download Report</button>
        <mat-menu #sendMenu="matMenu" xPosition="before">
            <button mat-menu-item (click)="exportQapiReportAsXLSX('xlsx');">Export As XLSX</button>        
            <button mat-menu-item (click)="exportQapiReportAsXLSX('pdf');">Export As PDF</button>
        </mat-menu>
    </h3>

    <div class="search-block theme-form items-start justify-center" 
        style="background-color: white; border-radius: 4px; padding-top: 8px; padding-bottom: 5px; padding-left: 8px;padding-right: 8px;">

        <div class="flex items-center justify-between flex-grow">
            <div class="flex items-center justify-start gap-2">

                <mat-form-field class="patients-page">
                    <mat-select [value]="filter.reportType" (selectionChange)="handleReportChange($event.value)">
                        <mat-option value="improving">
                            Wound Improving
                        </mat-option>
                        <mat-option value="stalled">
                            Wound Stalled
                        </mat-option>
                        <mat-option value="deteriorating">
                            Wound Deteriorating
                        </mat-option>
                        <mat-option value="remission">
                            Wound In Remission
                        </mat-option>
                        <mat-option value="amputation">
                            Amputation
                        </mat-option>
                        <mat-option value="clinical-summary" *ngIf="!user_dashboard">
                            Clinical Summary
                        </mat-option>
                        <mat-option value="operations-summary" *ngIf="!user_dashboard">
                            Operations Summary
                        </mat-option>
                        <mat-option value="rehospitalization" *ngIf="!user_dashboard">
                            Rehospitalization
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" style="width:250px" *ngIf="filter.reportType !== 'rehospitalization'">
                    <mat-label>Select Facility</mat-label>
                    <input  id="facilityControlInput" type="text" placeholder="Select Facility" aria-label="facility" matInput
                        [formControl]="facilityControl" [matAutocomplete]="facilityAutoControl">
                    <mat-autocomplete #facilityAutoControl="matAutocomplete" autoActiveFirstOption>
                        <mat-option (onSelectionChange)="onSelectFacility($event, facility);"
                            *ngFor="let facility of filteredFacilities | async" [value]="facility.title">
                            <app-facility-title [facility]="facility"></app-facility-title>
                        </mat-option>
                </mat-autocomplete>

                </mat-form-field>
                <button mat-raised-button class="dn-button-primary" (click)="handleApplyFilter();">Apply Filter
                </button>

                <button mat-raised-button class="dn-button-secondary" (click)="resetFilter();">Reset Filter
                </button>
            </div>

            <div class="flex text-center items-center ml-4">
                <button mat-raised-button class="dn-button-primary" (click)="goToPreviousPage()"
                    [disabled]="!pagination.hasPrevPage">
                    Prev</button>
                <span class="flex justify-center items-center p-1">
                    {{pagination.skip + qapiReport.length === 0 ? 0 : pagination.skip + 1 }} - {{pagination.skip + qapiReport.length}} out
                    of {{pagination.totalRecords}}
                </span>
                <button mat-raised-button class="dn-button-primary" (click)="goToNextPage()"
                    [disabled]="!pagination.hasNextPage">Next</button>
            </div>
            
        </div>

    </div>

    <mat-card class="vertical-height">

        <div class="table-height">
            <table *ngIf="filter.reportType !== 'rehospitalization' 
                && filter.reportType !== 'clinical-summary' 
                && filter.reportType !== 'operations-summary' 
                && filter.reportType !== 'amputation'" mat-table [dataSource]="dataSource" class="mat-elevation-z8"
                matSort>

                <ng-container matColumnDef="organization">
                    <th mat-header-cell *matHeaderCellDef> Organization </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.company_id?.name}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="faciltiy">
                    <th mat-header-cell *matHeaderCellDef> Facility </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.facility_id?.title}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="assessment_date">
                    <th mat-header-cell *matHeaderCellDef> Last Assessment </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{getDate(element)}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="first_name">
                    <th mat-header-cell *matHeaderCellDef> First Name </th>
                    <td mat-cell *matCellDef="let element; let i = index;" (click)="showGraphofWound(element)" [ngStyle]="{
                        cursor: user_dashboard == true ? 'pointer' : 'pointer', color:'blue'
                      }">
                        {{element?.patient_id?.first_name}}
                    </td>
                </ng-container>


                <ng-container matColumnDef="last_name">
                    <th mat-header-cell *matHeaderCellDef> Last Name </th>
                    <td mat-cell *matCellDef="let element; let i = index;" (click)="showGraphofWound(element)" [ngStyle]="{
                        cursor: user_dashboard == true ? 'pointer' : 'pointer', color:'blue'
                      }" >
                        {{element?.patient_id?.last_name}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="woundNo">
                    <th mat-header-cell *matHeaderCellDef> Wound # </th>
                    <td mat-cell *matCellDef="let element; let i = index;" (click)="showGraphofWound(element)" style="cursor: pointer;">
                        {{element?.woundNo}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="body_part">
                    <th mat-header-cell *matHeaderCellDef> Location </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.body_part}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="wound_status">
                    <th mat-header-cell *matHeaderCellDef> Wound Status </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{getWoundStatus(element)}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="etiolgy">
                    <th mat-header-cell *matHeaderCellDef> Etiolgy</th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.etiolgy}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="stage">
                    <th mat-header-cell *matHeaderCellDef> Severity </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.stage}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="diffInWeek">
                    <th mat-header-cell *matHeaderCellDef> Weeks </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{element?.diffInWeek}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="area_change">
                    <th mat-header-cell *matHeaderCellDef> Area Change </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        {{calculateArea(element) | number : '1.0-2'}} %
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <app-clinical-summary-report *ngIf="isClinicalSummaryReportVisible" [clinicalSummaryReport]="qapiReport"
                [facilityTitle]="facilityTitle">
            </app-clinical-summary-report>

            <app-operations-summary-report *ngIf="isOperationsSummaryReportVisible"
                [operationsSummaryReport]="qapiReport" [facilityTitle]="facilityTitle"></app-operations-summary-report>

            <app-amputation-report *ngIf="isAmputationReportVisible" [amputationReport]="qapiReport">
            </app-amputation-report>

            <app-rehospitalization-report *ngIf="isRehospitalizationReportVisible"
                [rehospitalizationReport]="qapiReport">
            </app-rehospitalization-report>

        </div>
    </mat-card>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
</div>