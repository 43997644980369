import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../auth.service';
import {API_URL} from 'src/environments/api-url';
import * as global from '../../includes/global';

@Injectable({
    providedIn: 'root'
})
export class AudioService {

    constructor(private _httpclient: HttpClient,
                private _authService: AuthService) {
    }

    getAudio(filter, projection, populate?) {
        const details = {
            ...this._authService.authObject,
            filter,
            projection,
            populate
        }
        return this._httpclient
            .post(global.url + API_URL.AUDIO.getAudio, {details}).pipe();
    }
    getAudioChunks(audio) {
        const data = {
          ...this._authService.authObject,
          audio
        }

        return this._httpclient.post(global.url + API_URL.AUDIO.getAudioChunks,
            {audio: data}).pipe();
    }
    updateChunk(chunk) {
        const data = {
            ...this._authService.authObject,
            chunk
        };

        return this._httpclient.post(global.url + API_URL.AUDIO.updateAudioChunk,
            {audio: data}).pipe();
    }
    getCountGroup(id, from_date, to_date, checkedDict) {
        const detail = {
            ...this._authService.authObject,
            id,
            from_date,
            to_date,
            checkedDict
        };
        return this._httpclient.post(global.url + API_URL.AUDIO.getCountGroup,
            {details: detail}).pipe();
    }
}
