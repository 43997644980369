import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment'
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FacilityService } from 'src/app/services/facility.service';
import { Location } from '@angular/common';
import { WoundService } from 'src/app/services/wound.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as global from 'src/app/includes/global';
import { UserTypesRelationshipService } from 'src/app/services/user-types-relationship.service';
import { UserService } from 'src/app/services/Modules/user.service';
import { Options, LabelType } from "@angular-slider/ngx-slider";
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-missed-facility-report',
  templateUrl: './missed-facility-report.component.html',
  styleUrls: ['./missed-facility-report.component.css']
})
export class MissedFacilityReportComponent implements OnInit {

  loaderId = 'app-missed-facility-report';
  filter: any = {
    filterServiceDate: {
      startDate: moment().subtract(6, 'days').toDate(),
      endDate: moment().toDate()
    },
    weekStartNo: moment().isoWeek()-5,
    weekEndNo: moment().isoWeek(),
    companyId: null
  };

  isfilterServiceDateFilterSelected: boolean = true;
  filteredFacilities: Observable<string[]>;
  displayedColumns: string[];
  facilityControl = new FormControl();
  providerControl = new FormControl();
  facilities: Array<any> = [];
  providers: Array<any> = [];
  filteredProviders: Observable<string[]>;
  companyId: any;
  missedFacilityReport: any = [];
  dataSource: any = new MatTableDataSource([]);
  initialTotalNotes: number = 0;
  subsequentTotalNotes: number = 0;
  grandTotal: number = 0;
  currentYear: string = moment().year().toString();
  currentUser: any;
  companySide: boolean;
  nursingSide: boolean;
  user_dashboard: Boolean = false;
  options: Options = {
    floor: 1,
    ceil: 52,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "<b>Min Week:</b> " + value;
        case LabelType.High:
          return "<b>Max Week:</b> " + value;
        default:
          return "" + value;
      }
    }
  };
  constructor(
    private router: Router,
    private _toastr: ToastrService,
    private _facilityService: FacilityService,
    private _route: ActivatedRoute,
    private _location: Location,
    private _woundService: WoundService,
    private loader: NgxUiLoaderService,
    private _userTypesRelationshipService: UserTypesRelationshipService,
    private _userService: UserService,
    private _authService: AuthService

  ) {
    this.filter.companyId = this._route.parent.snapshot.params.id;
    this.currentUser = this._authService.currentUser;
    
    if(this._route.parent.snapshot.params.id){
      this.companySide=true;
    }else{
      this.filter.companyId = this.currentUser.company_id;
    }
    if(window.location.pathname.includes('dashboard')){
      this.user_dashboard = true;
    }
    if(window.location.pathname.includes('nursing')){
      this.nursingSide=true;
    }
    this.convertWeeksToDate();
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId);
    this.initMissedFacilityReport();
  }

  initMissedFacilityReport() {
    this.grandTotal = 0;
    this.subsequentTotalNotes = 0;
    this.initialTotalNotes = 0;
    this.loader.startLoader(this.loaderId);
    this._woundService.getMissedFacilityReport(this.filter).subscribe((response: any) => {
      if (response.status == 200) {
        this.missedFacilityReport = response.data;
        console.log("this.missedFacilityReport", this.missedFacilityReport);
        this.addColumnsDynamically();
        this.dataSource.data = this.missedFacilityReport;
        this.loader.stopLoader(this.loaderId);
      }
      else {
        this.loader.stopLoader(this.loaderId);

      }
    }, err => {
      this.loader.stopLoader(this.loaderId);
      this._toastr.error(err, "ERROR");
    }
    );
  }
  addColumnsDynamically() {
    this.displayedColumns = ['Facility Name', 'Provider', 'POD'];
    for (let j = this.filter.weekEndNo; j >= this.filter.weekStartNo; j--) {
      this.displayedColumns.push(`${j}`);
    }
  }
  convertWeeksToDate() {
    this.filter.filterServiceDate.startDate = this._woundService.getDateFromWeekNo(this.filter.weekStartNo, this.currentYear, true);
    this.filter.filterServiceDate.endDate = this._woundService.getDateFromWeekNo(this.filter.weekEndNo, this.currentYear, false);
  }

  onChangeSlider(event) {
    console.log("eve", event, this.options);
    // this.filter.weekStartNo = event.value;
    // this.filter.weekEndNo = event.highValue;
    this.convertWeeksToDate();
  }
  goBack() {
    this._location.back();
  }
  handleApplyFilter() {
    this.initMissedFacilityReport();
  }

  resetFilter() {
    this.filter.weekEndNo = moment().isoWeek();
    this.filter.weekStartNo = moment().isoWeek()-5;
    this.convertWeeksToDate();
    this.initMissedFacilityReport();
  }
  exportMissedFacilityReportAsXLSX() {
    const infoToastr = this._toastr.info('Exporting as XLSX...', 'Please wait', { disableTimeOut: true });
    this._woundService.exportMissedFacilityReportAsXLSX(this.filter).subscribe((response: any) => {
      if (response instanceof HttpResponse) {
        const url = window.URL.createObjectURL(response.body);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `Missed Facility Report`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
        this._toastr.clear(infoToastr.toastId);
      }
    }, error => {
      console.log("err", error);
      this._toastr.clear(infoToastr.toastId);
    });
  }

}
