<app-header *ngIf="!admin && !isCompany"></app-header>

<main>
  <div class="container flex flex-col gap-2">
    <mat-card style="width:99vw">
      <mat-card-content style="display:flex">
        <div class="w-4/5" *ngIf="patient && patient.source === 'PointClickCare' ">
          <div class="flex justify-between items-center gap-1">
            <div class="flex flex-col justify-start items-start gap-1" style="height: 150px;">
              <div>
                <strong>
                  <app-patient-name [patient]="patient"></app-patient-name>
                </strong>
              </div>
            
              <div>
                <b>DOB/Gender : </b>
                <span>
                  {{(patient.date_of_birth ? patient.date_of_birth : patient.date_of_birth_d ? patient.date_of_birth_d : 'Jan 1,
                  1955')}}
                  -
                  {{patient.gender}}
                </span>
              </div>
            
              <div>
                <b>Facility : </b>
                <span>{{patient.facility_id_ref?.title}}</span>
              </div>
            
              <div>
                <b>MRN : </b>
                <span>{{ patient?.mrn ? patient?.mrn : '' }}</span>
              </div>
            
              <div *ngIf="patient.source == 'PointClickCare'">
                <b>PCC-MRN : </b>
                <span>{{ patient?.pcc_payload.medicalRecordNumber ? patient?.pcc_payload.medicalRecordNumber : '' }}</span>
              </div>

            </div>
  
            <div class="flex flex-col justify-start items-start gap-1" style="height: 150px;">
              <ng-container *ngIf="patient?.pcc_payload">
                <div>
                  <b>A/D : </b>
                  <span>{{patient.getAdmissionDate()}}</span>
                </div>
      
                <div>
                  <b>D/C : </b>
                  <span *ngIf="patient.getPatientStatus(patient.facility_id_ref) === 'Discharged'">{{patient.getDischargeDate() | date: 'MMM d, y'}}</span>
                </div>

                <div>
                  <b>Admit Date : </b>
                  <span>{{patient.getAdmissionDate()}}</span>
                </div>

                <div>
                  <b>Last Seen : </b>
                  <span [innerHTML]="getLastSeen()"></span>
                </div>
              
                <div *ngIf="patient?.facility_id_ref && patient.getPatientStatus(patient.facility_id_ref) === 'Discharged'">
                  <span><strong>Discharge Date: </strong>{{patient.getDischargeDate() | date: 'MMM d, y'}}</span>
                </div>

              </ng-container>
            </div>
  
            <div class="flex flex-col items-start gap-1" style="height: 150px;">
              <div *ngIf="patient?.facility_id_ref">
                <b>Status : </b>
                <span>{{ patient.getPatientStatus(patient.facility_id_ref) || patient.pcc_payload?.patientStatus}}</span>
              </div>

              <div>
                <b>Payor : </b>
                <span>{{getRecentPatientADTRecord(patient)?.payerName}}</span>
              </div>
              <div>
                <b>Source : </b>
                <span>{{patient.source || 'portal'}}</span>
              </div>
              
              <div>
                <b>Location : </b>
                  <span>{{patient.getPatientLocation()}}</span>
              </div>
              <div *ngIf="patient.pcc_primaryPractitioner">
                <b>PCP : </b>
                <span>{{patient.pcc_primaryPractitioner.firstName}} {{patient.pcc_primaryPractitioner.lastName}}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="w-4/5" *ngIf="patient && patient.source != 'PointClickCare' ">
          <div class="flex justify-between items-center gap-1">
            <div class="flex flex-col justify-start items-start gap-1" style="height: 150px;">
              <div>
                <strong>
                  <app-patient-name [patient]="patient"></app-patient-name>
                </strong>
              </div>
            
              <div>
                <b>DOB/Gender : </b>
                <span>
                  {{(patient.date_of_birth ? patient.date_of_birth : patient.date_of_birth_d ? patient.date_of_birth_d : 'Jan 1,
                  1955')}}
                  -
                  {{patient.gender}}
                </span>
              </div>

              <div>
                <b>MRN : </b>
                <span>{{ patient?.mrn ? patient?.mrn : '' }}</span>
              </div>
            
              <div>
                <b>Facility : </b>
                <span>{{patient.facility_id_ref?.title}}</span>
              </div>

            </div>
  
            <div class="flex flex-col justify-start items-start gap-1" style="height: 150px;">
              <ng-container>
               
                <div>
                  <b>Admit Date : </b>
                  <span>{{patient.getAdmissionDate()}}</span>
                </div>

                <div>
                  <b>Discharge Date : </b>
                  <span>{{patient.getDischargeDate() | date: 'MMM d, y'}}</span>
                </div>

                <div>
                  <b>Last Seen : </b>
                  <span [innerHTML]="getLastSeen()"></span>
                </div>

              </ng-container>
            </div>
  
            <div class="flex flex-col justify-start items-start gap-1" style="height: 150px;">
              

            </div>
          </div>
        </div>
        <div class="flex flex-col justify-start items-end gap-1 w-1/5" style="height: 150px;">
          <div *ngIf="patient">
              <i *ngIf="currentRouteAdmin" class="icon icon-edit" matTooltip="Edit" matTooltipPosition="above"
              (click)="onEditClickAdmin(patient._id)"></i>
              <i *ngIf="!currentRouteAdmin && (currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER && currentUser.user_type != global.USER_TYPE.WOUND_NURSE)" class="icon icon-edit" matTooltip="Edit" matTooltipPosition="above"
              (click)="onEditClick(patient)"></i>
              <i *ngIf="company.portal_type === global.PORTAL_TYPE.NON_WC">
                <img
                matTooltip="ePrescription"
                matTooltipPosition="above"
                style="cursor: pointer; width: 22px; margin: 0px 5px 0px 5px;"
                src="../../../assets/icons/rx.png"
                alt="ePrescription"
                (click)="openScriptSureModule(patient)"
                *ngIf="isPermittedFor( 'eprescription' ) && !patient.isScriptSureLoading"
              />
              <mat-progress-spinner
                matTooltip="Loading..."
                matTooltipPosition="above"
                diameter="22"
                mode="indeterminate"
                *ngIf="patient.isScriptSureLoading"
              ></mat-progress-spinner>
              </i>
              <button *ngIf="patient && ( patient.pcc_patientId || patient.source == 'MatrixCare')" mat-raised-button class="dn-button-primary" style="margin-left: 10px;"
              [routerLink]="['facesheet']">Facesheet</button>

              <button *ngIf="patient && patient.source == 'MatrixCare'" mat-raised-button class="dn-button-primary" style="margin-left: 10px;"
              (click)="getCCD(patient)">Get CCD</button>
          </div>
        </div>
      </mat-card-content>

    </mat-card>

    <div class="flex flex-col gap-2" style="width:99vw;">
      <div class="flex justify-between items-center" *ngIf="patient">
        <button class="dn-button-secondary" mat-raised-button (click)="goBack()">Back</button>
        <div style="gap: 1rem;
        display: flex;">
           <button *ngIf="!patient.pcc_patientId && tab == 'Allergies' && currentUser.user_type != global.USER_TYPE.VIEW_ONLY_USER" mat-raised-button class="dn-button-primary"
          (click)="openCreatePaitentAllergyDialog();">Add Allergy</button>
  
          <div class="order-actions flex gap-1"  *ngIf="!(currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER) && isPreviousRoute(previousUrl,targetRoute)">
            <span class="material-symbols-outlined"  (click)="createNoteClick()" matTooltip="Create Note">
              add_notes
            </span>
            <span class="material-symbols-outlined" matTooltip="Create Charge">
              attach_money
            </span>
            <span class="material-symbols-outlined" matTooltip="Create Order" (click)="openOrderTab()">
              grading
            </span>
            <span class="material-symbols-outlined" matTooltip="ePrescription">
              prescriptions
            </span>
          </div>
        </div>
      </div>
      <ng-container *ngIf="patient && reloadTab">

          <ng-container *ngIf="tabLayout === 'horizontal'" [ngTemplateOutlet]="matTabsTmpl"></ng-container>
          <div class="side-nav-container">
              <ng-container *ngIf="tabLayout === 'vertical'" [ngTemplateOutlet]="sideNavListTmpl"></ng-container>

              <div *ngIf="tab === PatientTab.PatientNotes" style='background-color: #fff;'>
                  <app-patient-detail-list [patient]="patient"></app-patient-detail-list>
              </div>

              <div *ngIf="tab === PatientTab.PatientDocuments" style='background-color: #fff;'>
                  <app-patient-documents-list [patient]="patient"></app-patient-documents-list>
              </div>

              <div *ngIf="tab === PatientTab.AdmitDischargeHistory" style='background-color: #fff;'>
                  <app-patient-admit-discharge-history *ngIf="patient.source !== 'MatrixCare'" [patient]="patient">

                  </app-patient-admit-discharge-history>
              </div>

              <div *ngIf="tab === PatientTab.Communication" style='background-color: #fff;'>
                  <app-communication [patient]="patient"></app-communication>
              </div>

              <div *ngIf="tab === PatientTab.Encounter" style='background-color: #fff;'>
                  <app-patient-appointments [patient]="patient"></app-patient-appointments>
              </div>

              <div *ngIf="tab === PatientTab.ActivityOfDailyLiving && patient.pcc_patientId" style='background-color: #fff;'>
                  <app-activities-of-daily-living [patient]="patient"></app-activities-of-daily-living>
              </div>

              <div *ngIf="tab === PatientTab.ActivityOfDailyLivingScore && patient.pcc_patientId" style='background-color: #fff;'>
                  <!-- <app-activities-of-daily-living-score [patient]="patient"></app-activities-of-daily-living-score> -->
                  <app-activities-of-daily-living [patient]="patient"></app-activities-of-daily-living>
              </div>

              <div *ngIf="tab === PatientTab.DiagnosisList" style='background-color: #fff;'>
                  <app-conditions *ngIf="patient.source == 'PointClickCare'"  [patient]="patient"></app-conditions>
                  <app-matrix-conditions *ngIf="patient.source == 'MatrixCare'" [patient]="patient"></app-matrix-conditions>
                  <app-patient-diagnosis *ngIf="patient.source != 'MatrixCare' && patient.source != 'PointClickCare'" [patient]="patient"></app-patient-diagnosis>
              </div>

              <div *ngIf="tab === PatientTab.VitalSigns" style='background-color: #fff;'>
                  <app-observation *ngIf="patient.source !== 'MatrixCare'" [patient]="patient"></app-observation>
              </div>

              <div *ngIf="tab === PatientTab.Medications" style='background-color: #fff;'>
                  <app-medications *ngIf="patient.source !== 'MatrixCare'" [patient]="patient">

                  </app-medications>
              </div>

              <div *ngIf="tab === PatientTab.Allergies" style='background-color: #fff;'>
                  <app-allergy-intolerances *ngIf="patient.pcc_patientId" [patient]="patient"></app-allergy-intolerances>
                  <app-non-pcc-allergy-intolerances *ngIf="!patient.pcc_patientId && patient.source !== 'MatrixCare'" [patient]="patient"> </app-non-pcc-allergy-intolerances>
                  <app-non-pcc-allergy-intolerances *ngIf="patient.source === 'MatrixCare'" [patient]="patient"> </app-non-pcc-allergy-intolerances>
              </div>

              <div *ngIf="tab === PatientTab.MedicalHistory" style='background-color: #fff;'>
                  <app-patient-medical-history [patient]="patient"></app-patient-medical-history>
              </div>

              <div *ngIf="tab === PatientTab.Coverages" style='background-color: #fff;'>
                  <app-patient-coverages *ngIf="patient.source !== 'MatrixCare'" [patient]="patient">
                  </app-patient-coverages>
                  <app-matrix-patient-coverages *ngIf="patient.source == 'MatrixCare'" [patient]="patient">
                  </app-matrix-patient-coverages>
              </div>

              <div *ngIf="tab === PatientTab.Immunizations" style='background-color: #fff;'>
                  <app-immunizations *ngIf="patient.source !== 'MatrixCare'" [patient]="patient">

                  </app-immunizations>
              </div>

              <div *ngIf="tab === PatientTab.Imaging" style='background-color: #fff;'>
                  <app-patient-diagnostic-reports *ngIf="patient.source !== 'MatrixCare'" [patient]="patient" reportType="radiology">

                  </app-patient-diagnostic-reports>
              </div>

              <div *ngIf="tab === PatientTab.Labs" style='background-color: #fff;'>
                  <app-patient-diagnostic-reports *ngIf="patient.source !== 'MatrixCare'" [patient]="patient" reportType="laboratory">

                  </app-patient-diagnostic-reports>
              </div>

              <div *ngIf="tab === PatientTab.NutritionOrders" style='background-color: #fff;'>
                  <app-patient-nutrition-orders *ngIf="patient.source !== 'MatrixCare'" [patient]="patient"></app-patient-nutrition-orders>
              </div>

              <div *ngIf="tab === PatientTab.Orders" style='background-color: #fff;'>
                  <app-patient-orders  [patient]="patient"></app-patient-orders>
              </div>

              <div *ngIf="tab === PatientTab.Wounds && isWoundCompany()" style='background-color: #fff;'>
                  <app-wound-care-widget [readOnlyMode]=true [patient]="patient" [facility_id]="patient.facility_id_ref._id" [should_show_view_icon]="should_show_view_icon"></app-wound-care-widget>
              </div>

              <div *ngIf="tab === PatientTab.PayerInformation && patient.source != 'MatrixCare' && patient.source != 'PointClickCare'" style='background-color: #fff;'>
                  <app-payer-information [patient]="patient"></app-payer-information>
              </div>
          </div>
      </ng-container>

    </div>
  </div>
</main>


<!--Tabs Layout-->
<ng-template #sideNavListTmpl>

    <ul *ngIf="patient.pcc_patientId" class="side-tabs" cdkDropList cdkDropListOrientation="vertical" (cdkDropListDropped)="drop($event)">
        <li *ngFor="let tab of tabs; let index = index"
            [class.active]="tab.is_active"
            [id]="'list-'+index"
            (click)="tabChange(tab,index,tabs)"
            cdkDrag
        ><span>{{ tab.name }}</span></li>
    </ul>
    <ul *ngIf="!patient.pcc_patientId && patient.source !== 'MatrixCare'" class="side-tabs" cdkDropList cdkDropListOrientation="vertical" (cdkDropListDropped)="dropNonPCC($event)">
        <li *ngFor="let tab of nonPCCtabs; let index = index"
            [class.active]="tab.is_active"
            [id]="'list-'+index"
            (click)="tabChange(tab,index,nonPCCtabs)"
            cdkDrag
        ><span>{{ tab.name }}</span></li>
    </ul>
    <ul *ngIf="patient.source == 'MatrixCare'" class="side-tabs" cdkDropList cdkDropListOrientation="vertical" (cdkDropListDropped)="dropMatrix($event)">
        <li *ngFor="let tab of matrixTabs; let index = index"
            [class.active]="tab.is_active"
            [id]="'list-'+index"
            (click)="tabChange(tab,index,matrixTabs)"
            cdkDrag
        ><span>{{ tab.name }}</span></li>
    </ul>
</ng-template>
<ng-template #matTabsTmpl>
    <div  style="display: flex; flex-direction: column !important; background-color: white;">
        <div mat-tab-nav-bar *ngIf="patient.pcc_patientId" #tabGroup cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)" >
            <div cdkDrag mat-tab-link [ngStyle]="{'border-bottom':tab.is_active ? '2px solid #3f51b5' : '' }" *ngFor="let tab of tabs; let index = index">
                <div style="padding: 15px;" (click)="tabChange(tab,index,tabs)" [id]="'list-'+index">
                    <div>{{ tab.name }}</div>
                </div>
            </div>
        </div>

        <div mat-tab-nav-bar *ngIf="!patient.pcc_patientId && patient.source !== 'MatrixCare'" #tabGroup cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="dropNonPCC($event)" >
            <div cdkDrag mat-tab-link [ngStyle]="{'border-bottom':tab.is_active ? '2px solid #3f51b5' : '' }" *ngFor="let tab of nonPCCtabs; let index = index">
                <div style="padding: 15px;" (click)="tabChange(tab,index,nonPCCtabs)" [id]="'list-'+index">
                    <div>{{ tab.name }}</div>
                </div>
            </div>
        </div>
        <div mat-tab-nav-bar *ngIf="patient.source == 'MatrixCare'" #tabGroup cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="dropMatrix($event)" >
            <div cdkDrag mat-tab-link [ngStyle]="{'border-bottom':tab.is_active ? '2px solid #3f51b5' : '' }"  *ngFor="let tab of matrixTabs; let index = index">
                <div style="padding: 15px;" (click)="tabChange(tab,index,matrixTabs)" [id]="'list-'+index">
                    <div>{{ tab.name }}</div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
