import { Component, OnInit } from '@angular/core';
import { DoctorProfileInformation } from '../../../classes/doctor_profile_information'
import { DoctorProfileService } from 'src/app/services/Modules/doctorProfile.service';
import { AuthService } from 'src/app/services/auth.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/Modules/user.service';
import { Location } from '@angular/common';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { MatTableDataSource } from '@angular/material/table';
import {ENVIRONMENT} from '../../../../app/includes/global'
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as global from '../../../../app/includes/global';
@Component({
  selector: 'app-doctor-profile-information',
  templateUrl: './doctor-profile-information.component.html',
  styleUrls: ['./doctor-profile-information.component.css']
})
export class DoctorProfileInformationComponent implements OnInit {
  global = global;
  model: DoctorProfileInformation;
  currentUser: any;
  companyId: any;
  isIRCCompany: boolean;
  supervisingProvider = '';
  supervisingProviderTrueRCMID = '';
  company: any;
  allPermissions:any;
  displayedColumns: string[] = ['code', 'title', 'operation'];
  dataSource: any = new MatTableDataSource([])
  dataSourceUser:any=new MatTableDataSource([]);
  selectedUser: any;
  env=ENVIRONMENT;
  hidePermissions: boolean = false;
  selectedUserType;
  titleControl = new FormControl();
  displayProviderFn(title): string {
    return title;
  }
  titles = ["M.D.", "D.O.", "PA-C", "ARNP", "R.N.", "N.P.", "P.A.", "L.Ac", "DNP", "CWS", "CWSP", "WOCN", "ANP-BC", "CWCN" ];
  isEditing = false;
  countries = ['US / CA(+1)', 'Philippines(+63)', 'Pak(+92)', 'Ind(+91)'];
  selectedCountry: any;
  phoneCleaned = '';
  maxMobileLength = 12;
  oldPhone = '';
  isMandatoryNPI: boolean = false;

  constructor(private doctorProfileService: DoctorProfileService,
    private authService: AuthService,
    private companyService: CompanyService,
    private loader: NgxUiLoaderService,
    private route: ActivatedRoute,
    private userService: UserService, 
    private toastr: ToastrService,
    private _location: Location,) {
    this.model = new DoctorProfileInformation();
    this.currentUser = this.authService.currentUser;
    this.companyId = this.route.parent.parent.snapshot.params.id;
    // if (this.companyId === '60c0bb216f04165c099f7c33' || this.companyId === '5f69d0651b1eba8bee2d3ac4') {
    //   this.isIRCCompany = true;
    // }
  }
  doctorProfileInformationLoader = 'doctor-profile-information-loader';

  async ngOnInit() {
    await this.getCompanyName();
    if (this.company.company_type !== 'Wound') {
      this.isIRCCompany = true;
      console.log('this.isIRCCompany = true',this.isIRCCompany);
    }
    await this.getUser();
  }

  async getUser() {
    const provider_id = this.route.snapshot.paramMap.get('provider_id');
    const filter = {
      _id: provider_id,
      is_activated: "false"
    }
    const projection = {
      user_type: 1,
      first_name: 1,
      last_name: 1,
      email: 1,
      title: 1,
      mobile: 1,
      trueRCMID: 1,
      secondary_phone: 1,
      secondary_email_address: 1,
      permission:1,
      tin:1,
      pcc_name:1
    }
    const userResponse: any = await this.userService.getUser(filter, projection).subscribe((response: any) => {
      if (response.status === 200) {
        this.selectedUser = response.data;
        this.selectedUserType = this.selectedUser.user_type.toLowerCase();
        if (this.selectedUserType == 'medical_assistant' || this.selectedUserType === 'nurse') {
          this.hidePermissions = true;
        }
        this.initProfile(response.data);
      }
    });
  }

  async getCompanyName() {
    const companyName = await this.companyService.getCompanyName(this.companyId).toPromise()
    this.company = companyName.data;
  }

  goBack() {
    this._location.back();
  }

  initProfile(user) {
    this.model.user_id_ref = user._id;
    this.model.user_type = user.user_type;
    if (this.model.user_type === global.USER_TYPE.DOCTOR) this.isMandatoryNPI = true;

    this.loader.startLoader(this.doctorProfileInformationLoader);

    const filter = {
      user_id_ref: user._id
    }
    this.doctorProfileService.getProfile(filter, {}).subscribe((response: any) => {
      if (response.status == 200) {
        console.log('response',response);
        if (!response.data) {
          // this.model.user_id_ref = this.currentUser._id;
          this.model.first_name = user.first_name;
          this.model.last_name = user.last_name;
          this.model.title = user.title;
          this.model.work_email = user.email;
          this.model.office_phone = user.mobile ? user.mobile : '';
          this.model.trueRCMID = user.trueRCMID ? user.trueRCMID : '';
          this.model.secondary_phone = user.secondary_phone ? user.secondary_phone : '';
          this.model.secondary_email_address = user.secondary_email_address ? user.secondary_email_address : '';
          this.model.npi = user.npi ? user.npi : '';
          this.model.tin = user.tin ? user.tin : '';
          this.model.pcc_name = user.pcc_name;
          this.oldPhone = user.mobile ? user.mobile : '';

        }
        else {

          this.model = response.data;
          this.model.first_name = user.first_name;
          this.model.last_name = user.last_name;
          this.model.title = user.title;
          this.model.work_email = user.email;
          this.model.office_phone = user.mobile ? user.mobile : '';
          this.model.trueRCMID = user.trueRCMID ? user.trueRCMID : '';
          this.model.secondary_phone = user.secondary_phone ? user.secondary_phone : '';
          this.model.secondary_email_address = user.secondary_email_address ? user.secondary_email_address : '';
          this.model.npi = user.npi ? user.npi : '';
          this.model.tin = user.tin ? user.tin : '';
          this.model.pcc_name = user.pcc_name;
          this.oldPhone = user.mobile ? user.mobile : '';
        }
      }
    }, (error) => {
      console.error('Error occured', error.toString());

    }, () => {
      this.loader.stopLoader(this.doctorProfileInformationLoader);
    })
  }
  removePermissionCode(element){

    console.log("element : remve", element);
  }
  addPermissionCode(element){

    console.log("element : add", element);

  }
  checkPermissionCode(element){

    return false;
  }
  handleSubmit(values) {
    this.model.company_id = this.companyId
    let supervisorProviderDetails : any = {};
    if(this.supervisingProvider){
      supervisorProviderDetails.supervisingProvider = this.supervisingProvider;
    }
    if(this.supervisingProviderTrueRCMID){
      supervisorProviderDetails.supervisingProviderTrueRCMID = this.supervisingProviderTrueRCMID;
    }

    const infoToastr = this.toastr.info('Saving Profile Information...', 'Please wait', { disableTimeOut: true });
    this.loader.startLoader(this.doctorProfileInformationLoader);

    this.doctorProfileService.saveProfile(this.model,supervisorProviderDetails).subscribe((response: any) => {
    }, (error) => {
      console.error('Error occured', error.toString());
      this.toastr.clear(infoToastr.toastId);
      this.toastr.error("Somethimg Went Wrong", "Failed");
    }, () => {
      this.loader.stopLoader(this.doctorProfileInformationLoader);
    })

    let data = {
      _id: this.model.user_id_ref,
      first_name: this.model.first_name,
      last_name: this.model.last_name,
      title: this.model.title,
      trueRCMID: this.model.trueRCMID,
      secondary_phone : this.model.secondary_phone,
      secondary_email_address : this.model.secondary_email_address,
      npi: this.model.npi,
      tin: this.model.tin,
      pcc_name:this.model.pcc_name,
      company_id: this.companyId
    }
    this.userService.updateUser(data).subscribe((response: any) => {

    }, (error) => {
      console.error('Error occured', error.toString());
      this.toastr.clear(infoToastr.toastId);
      this.toastr.error("Somethimg Went Wrong", "Failed");
    }, () => {
      this.loader.stopLoader(this.doctorProfileInformationLoader);
      this.toastr.clear(infoToastr.toastId);
      this.toastr.success("Profile Information Saved Successfully", "Success");
    })
  }

  updatePhone() {
    if(!this.phoneCleaned.includes('+')) {
      if (this.selectedCountry === 'Pak(+92)') {
        this.phoneCleaned = '+92' + this.phoneCleaned;
      }
      else if (this.selectedCountry === 'US / CA(+1)') {
        this.phoneCleaned = '+1' + this.phoneCleaned;
      }
      else if (this.selectedCountry === 'Philippines(+63)') {
        this.phoneCleaned = '+63' + this.phoneCleaned;
      }
      else if(this.selectedCountry === 'Ind(+91)') {
        this.phoneCleaned = '+91' + this.phoneCleaned;
      }
    }

    if(this.oldPhone === this.phoneCleaned) { 
      this.toastr.warning('Please enter new number','Warning');
      return;
    }

    if(!this.model.office_phone) {
      this.toastr.warning('Please enter number','Warning');
      return;
    }
    
    if(this.model.office_phone.length < this.maxMobileLength) {
      this.toastr.warning('Please enter valid number','Warning');
      return;
    }

    if(!this.selectedCountry) {
      this.toastr.warning('Please Select Country','Warning');
      return;
    }
    
    const infoToastr = this.toastr.info('Updating Information...', 'Please wait', { disableTimeOut: true });
    this.loader.startLoader(this.doctorProfileInformationLoader);
    let data = {
      _id: this.model.user_id_ref,
      office_phone : this.phoneCleaned
    }
    this.userService.updateUserPhone(data).subscribe((response: any) => {
    }, (error) => {
      console.error('Error occured', error.toString());
      this.toastr.clear(infoToastr.toastId);
      this.toastr.error("Somethimg Went Wrong", "Failed");
    }, () => {
      this.loader.stopLoader(this.doctorProfileInformationLoader);
      this.toastr.clear(infoToastr.toastId);
      this.toastr.success("Profile Information Saved Successfully", "Success");
      this.getUser();
      this.isEditing = false;
      this.selectedCountry = '';
    });
  }

  allowOnlyText(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 90 && charCode < 97) {
      return false;
    }

    if (charCode < 65 || charCode > 122) {
      return false;
    }
    return true;
  }

  edit() {
    this.isEditing = true;
    console.log('oldPhone',this.oldPhone);
  }

  cancel() {
    this.isEditing = false;
    this.model.office_phone = this.oldPhone;
    this.selectedCountry = '';
    console.log('this.oldPhone',this.oldPhone);
    
  }

  countryFilter(Country) {
    this.phoneCleaned = "";
    this.model.office_phone = "";
    if (Country == 'US / CA(+1)') {
        this.selectedCountry = Country;
        this.maxMobileLength = 12;
    }
    else if (Country === 'Philippines(+63)') {
        this.selectedCountry = Country;
        this.maxMobileLength = 10;
    }
    else if (Country === 'Pak(+92)') {
        this.selectedCountry = Country;
        this.maxMobileLength = 12;
    }
    else if(Country === 'Ind(+91)') {
      this.selectedCountry = Country;
      this.maxMobileLength = 11;
    }
  }

  handlePhoneModelChange(value) {
    if (this.selectedCountry === 'Philippines(+63)') {
      this.model.office_phone = value;
      this.phoneCleaned = value;
    }
    else if (value) {
      const cleaned = value.replace(/-/g, '');
      if (this.selectedCountry === 'Ind(+91)') {
        this.model.office_phone = cleaned.match(/.{1,4}/g).join('-');
        if (this.model.office_phone.length > 9) {
          let tempNum = this.model.office_phone.split('-');
          let num = [tempNum[0], tempNum[1] + tempNum[2]];
          this.model.office_phone = num.join('-');
        }

      }
      else {
        this.model.office_phone = cleaned.match(/.{1,3}/g).join('-');
      }
      if (this.model.office_phone.length > 12) {
        let position = this.model.office_phone.lastIndexOf('-');
        this.model.office_phone = this.model.office_phone.substring(0, position) + this.model.office_phone.substring(position + 1);

      }
      this.phoneCleaned = cleaned.trim();
    }
  }

  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedData = clipboardData.getData('Text').toString();
    let number: any = pastedData.match(/\d/g);
    number = number.join("");
    if (number.length > 10) {
      number = number.substring(0, 10);
    }
    this.handlePhoneModelChange(number);
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    else {
      return true;
    }
  }
}
