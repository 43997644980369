import { HttpResponse } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AuthService } from "src/app/services/auth.service";
import { OrderService } from "src/app/services/order.service";

@Component({
    selector: "app-patient-orders",
    templateUrl: "./patient-orders.component.html",
    styleUrls: ["./patient-orders.component.css"]
})
export class PatientOrdersComponent implements OnInit {
    @Input("patient") patient;
    tabs: any[] = [];
    loaderId = "patient-order-loader";
    selectedOrders: string[] = [];
    isShowOrders: boolean = false;
    shouldDisablePrintAndPDFBtn:boolean = true;
    patientOrderReportName:string = "patient-order-report"
    constructor(
        private orderService: OrderService,
        private _authService: AuthService,
        private loader: NgxUiLoaderService,
        private _toastr: ToastrService,
    ) { }

    async ngOnInit() {
        this.loader.startLoader(this.loaderId);
        await this.getAllOrderSet();
        await this.getPatientOrders();
    }
    private getDataForRequest(
        orderSetID?: any,
        order?: any,
        updateOrderSet?: any
    ): any {
        return {
            company_id: this._authService.currentUser.company_id,
            orderSetID,
            order,
            updateOrderSet
        };
    }
    getPatientOrders() {
        const data = {
            company_id: this._authService.currentUser.company_id,
            patient_id: this.patient._id
        };
        this.orderService.getPatientOrders(data).subscribe(
            (response: any) => {
                this.selectedOrders = response.data;
                this.shouldDisablePrintAndPDFBtn = !(response.data.length > 0);
                this.isShowOrders = true
                this.loader.stopLoader(this.loaderId);
                console.log("Successfully retrieved order set:", response);
            },
            (error) => this.handleError(error)
        );
    }
    getAllOrderSet(): void {
        const data = this.getDataForRequest();
        this.orderService.getOrders(data).subscribe(
            (response: any) => {
                this.tabs =
                    response.data.length > 0 ? response.data[0].order_set : [];
                console.log("Successfully retrieved order set:", response);
            },
            (error) => this.handleError(error)
        );
    }
    private handleError(error: any): void {
        console.error("Error:", error);
    }
    savePatientOrder() {
        const data = {
            company_id: this._authService.currentUser.company_id,
            orderIds:this.selectedOrders,
            patient_id: this.patient._id
        };
        this.orderService.savePatientOrder(data).subscribe(
            (response: any) => {
                this.getPatientOrders();
                this._toastr.success('Patient order saved successfully', "Success");

            },
            (error) => this.handleError(error)
        );
    }
    selectedPatientOrder(orderId) {
        const userIndex = this.selectedOrders.indexOf(orderId);
        if (userIndex === -1) {
            // User not found, add to the array
            this.selectedOrders.push(orderId);
        } else {
            // User found, remove from the array
            this.selectedOrders.splice(userIndex, 1);
        }
        console.log('selectedOrders', this.selectedOrders)
    }
    // Your function to check if an order is selected
    isOrderSelected(order_id: string): boolean {
        // console.log("Checking", order_id, this.selectedOrders.includes(order_id), this.selectedOrders)
        return this.selectedOrders.includes(order_id);
    }
    downloadAsPDF() {
        console.log("Downloading as PDF...");
        const data = {
          company_id: this._authService.currentUser.company_id,
          orderIds: this.selectedOrders,
          patient_id: this.patient._id,
        };
      
        this.orderService.generateOrderPDF(data).subscribe(
          (response: any) => {
            if (response instanceof HttpResponse && response.status === 200) {
              const url = window.URL.createObjectURL(response.body);
              const a = document.createElement("a");
              document.body.appendChild(a);
              a.setAttribute("style", "display: none");
              a.href = url;
              const date = moment().format("YYYY-MM-DD");
              a.download = `${this.patientOrderReportName}-${date}`;
              a.click();
              window.URL.revokeObjectURL(url);
              a.remove(); // remove the element
              this._toastr.success("Patient order PDF Download successfully", "Success");
            //   this.getPatientOrders();
            }
          },
          (error) => this.handleError(error)
        );
      }
    printOrders() {
        const data = {
            company_id: this._authService.currentUser.company_id,
            orderIds: this.selectedOrders,
            patient_id: this.patient._id,
          };
          
          this.orderService.generateOrderPDF(data).subscribe(
            (response: any) => {
              if (response instanceof HttpResponse && response.status === 200) {
                const url = window.URL.createObjectURL(response.body);

                // Open PDF in a new window
                const win = window.open(url, '_blank');
                if (win) {
                    win.onload = () => {
                    win.print(); // Trigger print functionality
                    };
                } else {
                    console.error('Failed to open the PDF in a new window.');
                }

                window.URL.revokeObjectURL(url);
              }
            },
            (error) => this.handleError(error)
          );
    }
}
