<mat-tab-group *ngIf="patient">

    <mat-tab label="Patient Notes"> 
        <app-notes-widget [note]="note" [patient]="patient"></app-notes-widget>
    </mat-tab>
    <mat-tab label="Diagnosis List" *ngIf="patient.pcc_patientId"> 
        <ng-template matTabContent>
          <app-conditions [patient]="patient" [showCheckboxes]="false"></app-conditions>
        </ng-template>
      </mat-tab>
      <mat-tab label="Vital Signs" *ngIf="patient.pcc_patientId"> 
        <ng-template matTabContent>
            <app-observation [patient]="patient"></app-observation>
        </ng-template>
      </mat-tab>
      <mat-tab label="Medications" *ngIf="patient.pcc_patientId"> 
        <ng-template matTabContent>
          <app-medications [patient]="patient"></app-medications>
        </ng-template>
      </mat-tab>
      <mat-tab label="Allergies" *ngIf="patient.pcc_patientId"> 
        <ng-template matTabContent>
          <app-allergy-intolerances [patient]="patient"></app-allergy-intolerances>
        </ng-template>
      </mat-tab>
    <mat-tab label="Coverages" *ngIf="patient.pcc_patientId"> 
      <ng-template matTabContent>
        <app-patient-coverages [patient]="patient"></app-patient-coverages>
      </ng-template>
    </mat-tab>
    <mat-tab label="Immunizations" *ngIf="patient.pcc_patientId"> 
        <ng-template matTabContent>
          <app-immunizations [patient]="patient"></app-immunizations>
        </ng-template>
      </mat-tab>
      <mat-tab label="Imaging" *ngIf="patient.pcc_patientId"> 
        <ng-template matTabContent>
          <app-patient-diagnostic-reports [patient]="patient" reportType="radiology"></app-patient-diagnostic-reports>
        </ng-template>
      </mat-tab>
      <mat-tab label="Labs" *ngIf="patient.pcc_patientId"> 
        <ng-template matTabContent>
          <app-patient-diagnostic-reports [patient]="patient" reportType="laboratory"></app-patient-diagnostic-reports>
        </ng-template>
      </mat-tab>
      <mat-tab label="Nutrition Orders" *ngIf="patient.pcc_patientId"> 
        <ng-template matTabContent>
          <app-patient-nutrition-orders [patient]="patient"></app-patient-nutrition-orders>
        </ng-template>
      </mat-tab> 
      <mat-tab label="Communication" *ngIf="showCommunicationTab" >
        <ng-template matTabContent>
          <app-communication
            [patient]="patient">
          </app-communication>
        </ng-template>
      </mat-tab>
</mat-tab-group>