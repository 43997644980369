import { Component, OnInit, Input, Output, EventEmitter, DoCheck, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranscriptionCreateService } from 'src/app/includes/transcription-create/transcription-create.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { DashboardService } from 'src/app/includes/dashboard/dashboard.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as global from '../../includes/global';
import { API_URL } from "../../../environments/api-url";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/services/auth.service';
import { TranscriptionService } from "../../services/transcription.service";
import { NoteAuditoryHistoryService } from 'src/app/includes/note-auditory-history/note-auditory-history.service';
import moment from 'moment';
import { NoteEditorService } from 'src/app/includes/note-editor/note-editor.service';
import { ViewNoteDialog } from 'src/app/includes/transcription-create/view-note-dialog/view-note-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmationComponent } from 'src/app/includes/census-patient-list/dialog-confirmation/dialog-confirmation.component';
import { UserService } from 'src/app/services/Modules/user.service';
import { ViewSharedFacilityNoteComponent } from 'src/app/includes/transcription-create/view-shared-facility-note/view-shared-facility-note.component';
import { WoundCareWidgetService } from 'src/app/includes/note-sidebar/wound-care-widget/wound-care-widget.service';
import { WoundService } from 'src/app/services/wound.service';
import { PCCService } from 'src/app/services/pcc.service';
import { lastValueFrom } from 'rxjs';
import { Buffer } from 'buffer';
import { ICompanyPermissionResponse } from 'src/app/classes/company';
import { CompanyService } from 'src/app/services/Modules/company.service';

@Component({
    selector: 'app-transcription-list',
    templateUrl: './transcription-list.component.html',
    styleUrls: ['./transcription-list.component.css']
})
export class TranscriptionListComponent implements OnInit, DoCheck, OnDestroy {
    global = global;
    @Input() category: string;
    @Input() parentComponent: string;
    @Input() status: string;
    @Input() transcriptions: Array<any> = [];
    @Input() group_index: string;
    @Input() transcriptionFiles: Array<any> = [];
    @Input() multiSelectedTranscriptions: Array<any> = [];
    @Input() isVisibleSelectAll: boolean = true;
    @Input() isVisibleControls: boolean = true;
    @Input() readonly: boolean = false;
    @Input() excludeInfo: string[] = [];
    @Input() selectedDoctor: any;
    @Input() forPatientDetailList: boolean = false;
    @Input() patient;
    @Input() noteErrors = {};
    @Input () tranFilters;
    @Output() totalFiles = new EventEmitter();
    @Output() initDictationsAndTranscriptions = new EventEmitter();
    @Output() toggleTranscriptionMultiSelect = new EventEmitter();
    @Output() resetTranscriptionMultiSelect = new EventEmitter();
    @Output() updateTranscriptionsArray = new EventEmitter();
    @Output() updateCount = new EventEmitter();
    @Output() isNoteView = new EventEmitter();
    currentUser: any;
    associations: any;
    providers: Array<any> = [];
    provider: any = '';
    isCheckedAll = false;
    isQA = false;
    noteStatus = 'SAVED';

    currentRouteUrl;
    showSelectAll: boolean = true;
    shouldShowEncounter : boolean = false;

    constructor(
        private _toastr: ToastrService,
        private _transcriptionCreateService: TranscriptionCreateService,
        private _encryptDecryptService: EncryptDecryptService,
        private _transcriptionService: TranscriptionService,
        private _dashboardService: DashboardService,
        private _httpClient: HttpClient,
        private _router: Router,
        private _route: ActivatedRoute,
        private commonService: CommonService,
        private _authService: AuthService,
        private auditoryService: NoteAuditoryHistoryService,
        private noteEditorService: NoteEditorService,
        private dialog: MatDialog,
        private dashboard: DashboardService,
        private _userService: UserService,
        private _woundCareWidgetService: WoundCareWidgetService,
        private _woundService: WoundService,
        private _pccService: PCCService,
        private companyService: CompanyService
    ) {
        // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.currentUser = this._authService.currentUser;
        this._route.paramMap.subscribe((params: ParamMap) => {
            this.resetTranscriptionMultiSelect.emit();
            this.currentRouteUrl = this._router.url.includes(';') ? this._router.url.split(';')[0] : this._router.url;
        });
    }

    getAudioDetailById(audio_id: string) {
        return '';
        // const response: any =  this._dashboardService.getAudioDetailById(audio_id, this.currentUser._id, this.currentUser.auth_key, this.currentUser.user_type).toPromise();
        //
        // if (response.status === 200) {
        //
        //     return response.data.audio_title;
        // }
    }
    checkDate(date) {
        if (new Date(date)) {
            return true;
        }
        else {
            return false;
        }
    }

    async downloadTranscription_encrypted(transcription) {

        if (this.currentUser.user_type == 'nurse') {


            const fileName = `note.pdf`;
            this._toastr.info('Generating PDF', 'Please wait...');

            await this.dashboard.downloadFile(global.url + API_URL.NOTE.encrypted_zip_file, [transcription._id]).subscribe((res: any) => {
                //await this.dashboard.downloadFile(global.url + API_URL.NOTE.generateMultiplePDF, this.multiSelectedTranscriptions).subscribe((res: any) => {
                if (res instanceof HttpResponse) {

                    var url = window.URL.createObjectURL(res.body);
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.setAttribute('style', 'display: none');
                    a.href = url;
                    a.download = fileName;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove(); // remove the element
                    this._toastr.info('Note Downloaded successfully', 'Sucess');

                } else {

                }
            }, (err) => {
                this._toastr.error('Something went wrong while downloading notes', 'Failed');
            },
                () => {
                });
        } else {
            this.downloadTranscription(transcription);
        }
    }

    downloadTranscription(transcription) {
        this._toastr.info('Generating PDF', 'Please wait...');
        this.noteEditorService.getNoteById(transcription._id).subscribe((response: any) => {
            if (response.status == 200) {
                let innerHtml = response.data.note.htmlText;
                this.noteEditorService.generatePDFAndDownload(innerHtml, transcription._id).subscribe((res: any) => {
                    if (res instanceof HttpResponse) {
                        var url = window.URL.createObjectURL(res.body);
                        var a = document.createElement('a');
                        document.body.appendChild(a);
                        a.setAttribute('style', 'display: none');
                        a.href = url;
                        a.download = `${response.data.note.title}`;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        a.remove(); // remove the element
                    } else {
                        if (res.type === 0) {
                        } else if (res.type === 3) {
                            const { loaded, total } = res;
                            const progress = Math.round(loaded / total * 100);
                            if (progress > 0) {
                            }
                        }
                    }
                })

            }
            else if (response.status === 403) {
                // this._toastr.error(response.message, 'ERROR');
                this._router.navigate(['/403']);
            }
        })

    }
    async ngOnInit() {
        if (global.ENVIRONMENT === "LOCAL") {
            console.log(this.isVisibleControls);
            // console.log("this.transcriptionFiles", this.transcriptionFiles);
            // console.log("this.transcriptions", this.transcriptions);
            // console.log("this.excludeInfo", this.excludeInfo);
        }
        await this.getCompanyPermissions({ _id: this.currentUser.company_id }, { should_show_encounter: 1 });
        if (this.currentUser.user_type == global.USER_TYPE.TRANSCRIBER || this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT) {
            this._dashboardService.getAssociatedDoctorsList(this.currentUser.company_id).subscribe((res: any) => {
                if (res.status === 200) {
                    this.associations = res.data;
                }
            });
        }
        this.checkQA();
        await this.getUsers();

        this.transcriptions.forEach(transcription => {
            transcription.filter = false;
        });

    }

    async getCompanyPermissions(filter, projection) {
        const response: ICompanyPermissionResponse = await lastValueFrom(this.companyService.getCompany(filter, projection));
        if (response.status == 200) {
            this.shouldShowEncounter = response.data.should_show_encounter;
        }
    }
    
    async getUsers() {
        const filter: any = {
            company_id: this.currentUser.company_id,
            user_type: this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE ? global.USER_TYPE.SNF_WC_NURSE : global.USER_TYPE.DOCTOR
        };
        const projection = {
            first_name: 1,
            last_name: 1,
            full_name: 1,
            title: 1
        };
        return new Promise<void>((resolve, reject) => {
            this._userService.getUsers(filter, projection).subscribe((response: any) => {
                if (response.status === 200) {
                    this.providers = response.data;
                    resolve();
                }
            }, error => {
                reject('error => ' + error.toString());
            });
        });
    }

    handleProviderChange(provider_id) {
        let count = 0;
        this.transcriptions.forEach(transcription => {
            if (transcription.doctor_id_ref && transcription.doctor_id_ref._id === provider_id || provider_id == '') {
                count++;
                transcription.filter = false;
            } else {
                transcription.filter = true;
            }
        });
        count == 0 ? this.showSelectAll = false : this.showSelectAll = true;
        let sendObj = {
            provider_id,
            count
        }
        this.updateCount.emit(sendObj);
    }

    onTabClickCharges(event) {
        if (event.tab.textLabel.toLowerCase() === 'dn notes') {
            this.totalFiles.emit(this.transcriptions.length);
        }
    }
    pccNotes(event) {
        this.totalFiles.emit(event);
    }
    getNoteStatus(status) {
        return this.auditoryService.formatOperation(status);
        // let NStatus = status.replace(/_/g, " ")
        // if(NStatus.trim().toLowerCase() == 'published'.trim().toLowerCase()){
        //     return "Ready for Review"
        // }
        // else {
        //     return NStatus;
        // } 
    }
    shouldShowCheckbox(transcription) {
        if (((this.currentUser.user_type === global.USER_TYPE.VIEW_ONLY_USER || this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) && transcription.note_status.status === 'published')) return false;
        return true;
    }
    getServiceDate(trans) {

        //ali
        if (trans) {
            if (trans.date_obj && (trans.date_obj.year != null || trans.date_obj.year != undefined)) {
                let { year, month, day, hour, minute } = trans.date_obj;
                if (month < 10) {
                    month = '0' + month;
                }
                if (day < 10) {
                    day = '0' + day;
                }
                let str = `${month}-${day}-${year}`;
                if ((hour != null && minute != null) || (hour != undefined && minute != undefined)) {
                    if (minute < 10) {
                        minute = `0${minute}`
                    }
                    if (hour < 10) {
                        hour = `0${hour}`
                    }
                    str += ` ${hour}:${minute}`
                }
                return str;
            }
            else if (trans.filterServiceDate) {
                let timezone = "America/New_York"
                if (trans.facility_id_ref && trans.facility_id_ref.pcc_timeZone) {
                    timezone = trans.facility_id_ref.pcc_timeZone
                }
                else if (trans.facility_id_ref && trans.facility_id_ref.timeZone) {
                    timezone = trans.facility_id_ref.timeZone
                }
                return moment(new Date(trans.filterServiceDate).toLocaleString('en-US', { timeZone: timezone })).format('MM-DD-YYYY hh:mm');
            }
            else {
                return '';
            }

            // if(trans.rounding_sheet_id){
            // return moment(new Date(trans.filterServiceDate)).format('MMM DD, YYYY h:mm a');
            // }
            // else
            // {
            //     return moment(new Date(trans.filterServiceDate).toLocaleString('en-US', { timeZone: trans.timezone })).format('MMM DD, YYYY h:mm a');
            // }
        }
        else return '';

        //mohsin
        // return moment(trans.filterServiceDate).format('MMM DD, YYYY h:mm a');
    }

    ngDoCheck() {
        if (Array.isArray(this.transcriptions)) {
            let isCheckedAll = true;
            for (let t of this.transcriptions) {
                if (this.multiSelectedTranscriptions.indexOf(t) === -1) {
                    isCheckedAll = false;
                    break;
                }
            }
            this.isCheckedAll = isCheckedAll;
        }
    }

    ngOnDestroy() {
        // this.resetTranscriptionMultiSelect.emit();
        if (this.currentRouteUrl === '/transcription-detail' && Array.isArray(this.transcriptions)) {
            this._dashboardService.publishNotes(this.transcriptions);
        }
    }

    checkQA() {
        return false;
        // let params = {
        //     transcriber_id: this.currentUser.id,
        //     doctor_id: this.selectedDoctor._id
        // };
        // this._transcriptionService.checkNoteQA(params).subscribe((res) => {
        //     this.isQA = res.success;
        //     return this.isQA;
        // });
    }

    showStatus(note) {
        if (!this.selectedDoctor || this.currentUser.user_type != 2) return false;
        return true;
    }

    getStatus(note) {
        if (this.isQA) {
            if (note.is_signed === 'false' && note.is_locked === false
                && note.is_published === 'false' && note.is_completed === 'false') {
                return 'IN PROGRESS';
            }
            if (note.is_signed === 'false' && note.is_locked === true
                && note.is_published === 'false' && note.is_completed === 'false') {
                return 'UNDER REVIEW';
            }
        } else {
            if (note.is_signed === 'false' && note.is_locked === false
                && note.is_published === 'false' && note.is_completed === 'false') {
                return 'IN PROGRESS';
            }
            if (note.is_signed === 'false' && note.is_locked === true
                && note.is_published === 'false' && note.is_completed === 'false') {
                return 'SUBMITTED';
            }
        }
    }

    selectAllTranscriptions(selectAll = true) {
        this.transcriptions.forEach(t => {
            const i = this.multiSelectedTranscriptions.indexOf(t);
            if (i === -1 && selectAll) {
                if (!t.rounding_sheet_id?.is_void) this.multiSelectedTranscriptions.push(t);
            } else if (!selectAll) {
                this.multiSelectedTranscriptions.splice(i, 1);
            }
        });
    }

    isVisibleButton(transcription, buttonName) {
        if (!transcription) {
            return false;
        }

        switch (buttonName) {
            case 'goToDictationButton':
                return transcription.audio_id ? true : false;
                break;

            case 'uploadButton':
                // if(this.currentUser.user_type == 2) {
                return transcription.is_signed == 'true' && transcription.is_completed != 'true';
                // }
                // return false;
                break;

            case 'revertButton':
                // if(this.currentUser.user_type == 2) {
                return transcription.is_signed == 'true' && transcription.is_completed == 'true';
                // }
                // return false;

                break;
            case 'viewButton':
                return true;
                break;

            case 'editButton':
                return false;
                break;


            // case 'downloadButton':{
            //     
            //     return transcription.is_signed === 'true';
            //     break;
            // }


            case 'deleteButton':
                if (this.currentUser._id == transcription.uploaded_by_id || this.currentUser._id == transcription.note_status.id) {
                    // if (this.currentUser.user_type == global.USER_TYPE.TRANSCRIBER && transcription.is_published != 'true' && transcription.is_signed != 'true' && transcription.is_completed != 'true') {
                    if ((this.currentUser.user_type == global.USER_TYPE.TRANSCRIBER && transcription.is_signed != 'true' && transcription.is_completed != 'true') || (this.currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT && transcription.is_signed != 'true' && transcription.is_completed != 'true')) {
                        return true;
                    }
                    if ((this.currentUser.user_type == global.USER_TYPE.DOCTOR || this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE) && transcription.is_signed != 'true' && transcription.is_completed != 'true') {
                        return true;
                    }
                }
                return false;
                // return this.currentUser.user_type == '1' ? this.currentUser._id == transcription.uploaded_by_id && transcription.is_signed != 'true' :  this.currentUser._id == transcription.uploaded_by_id && transcription.is_signed != 'true' &&  transcription.is_published != 'true'
                break;

            case 'downloadButton':
                if (this.currentUser.user_type == global.USER_TYPE.NURSE) {
                    return true;
                }
                if (this.associations?.length > 0 && transcription.is_signed == 'true') {
                    const obj = this.associations.find(user => transcription.note_status.id === user._id);
                    if (typeof obj === "object") {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                if (this.currentUser._id == transcription.uploaded_by_id || this.currentUser._id == transcription.note_status.id) {
                    if (this.currentUser.user_type == global.USER_TYPE.DOCTOR || this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE) {
                        // if(transcription.is_signed == 'true'){
                        //     return true;
                        // } 
                        if (transcription.coSign_status.status == true || (transcription.is_signed == true && transcription.is_completed == 'true')) {
                            return false
                        }
                        else {
                            return true
                        }
                    }
                    else return false;
                }
                else return false;
                // return this.currentUser.user_type == '1' ? this.currentUser._id == transcription.uploaded_by_id && transcription.is_signed != 'true' :  this.currentUser._id == transcription.uploaded_by_id && transcription.is_signed != 'true' &&  transcription.is_published != 'true'
                break;

            default:
                return true;
                break;

        }
    }

    blockClick($event, transcription) {
        const excludeClasses = ['mat-checkbox-inner-container', 'mat-icon'];
        for (let cls of excludeClasses) {
            if ($event.target.classList.value.indexOf(cls) >= 0) {
                return;
            }
        }
        if (this.multiSelectedTranscriptions.length > 0) {
            this.toggleTranscriptionMultiSelect.emit(transcription);
        } else {
            this.selectedfile(transcription);
        }
    }

    selectedfile(file) {
        localStorage.setItem('selectedFile', JSON.stringify(file));
        const transcriptionFiles = this.transcriptionFiles;
        let emptyAudios = [];
        localStorage.setItem('dictationFiles', JSON.stringify(emptyAudios));
        localStorage.setItem('transcriptionFiles', JSON.stringify(transcriptionFiles)); // for next and prev buttons

        localStorage.setItem('category', this.category);
        localStorage.setItem('backURL', '/dashboard');
        const routerMapParams: any = { category: 'tran', id: file._id, readonly: this.readonly };
        if (file.audio_id) {
            routerMapParams.audio_id = file.audio_id;
            routerMapParams.category = 'dict';

        }
        this._router.navigate(['/transcription-create', routerMapParams]);
    }
    goToNoteEditor(note) {
        if (note.note_active_status == 'trashed') {
            return;
        }
        this.isNoteView.emit(true);
        if (this.currentUser.user_type == global.USER_TYPE.NURSE || note?.rounding_sheet_id?.is_void) {
            let time_zone = 'America/New_York'
            // if (this.census && this.census.facility) {
            //     time_zone = this.census.facility.pcc_timeZone ? this.census.facility.pcc_timeZone : this.census.facility.timeZone
            // }
            const dialogRef = this.dialog.open(ViewNoteDialog, {
                width: '80%',
                //height:'80%',

                data: {
                    note_id: note._id,
                    timezone: time_zone
                }
            });
        }
        else if ((this.currentRouteUrl === '/dashboard' && this.currentUser.user_type === global.USER_TYPE.TRANSCRIBER) || (this.currentRouteUrl === '/dashboard' && this.currentUser.user_type === global.USER_TYPE.MEDICAL_ASSISTANT)) {
            this._router.navigate([`/note-editor/${note._id}`, { editable: true }]);
        } else {
            if (window.location.pathname.includes('company')) {
                this._router.navigate([`company/${this.currentUser.company_id}/note-editor/${note._id}`]);
            } else {
                this._router.navigate([`/note-editor/${note._id}`]);
            }
        }

    }
    showTrashedNotes(note) {
        const dialogRef = this.dialog.open(ViewNoteDialog, {
            width: '80%',
            // height:'80%',
            data: {
                note_id: note._id,
            }
        });
    }

    /*
  * Export as PDF #mohsin-dev
  */
    async downloadAsPDF(transcription) {
        /**
         * FLOW: Decrypt Transcript Text => Generate PDF => Get Doctor Name => Generate File Name => Download PDF File
         */
        const infoToastr = this._toastr.info('Generating PDF', 'Please wait...', { disableTimeOut: true });

        try {
            transcription.transcribtion_text = await this._encryptDecryptService.decrypt(transcription.transcribtion_text).toPromise();

            const generatePDFResponse: any = await this._dashboardService.generatePDF(transcription).toPromise();
            if (generatePDFResponse.status !== 200) {
                throw new Error(generatePDFResponse.message);
            }

            const doctorUserDetailsResponse: any = await this._dashboardService.getUserDetail(transcription.doctor_id).toPromise();
            if (doctorUserDetailsResponse.status !== 200) {
                throw new Error(doctorUserDetailsResponse.message);
            }

            const doctor = doctorUserDetailsResponse.data.array;
            const doctorName = doctor.first_name + ' ' + doctor.last_name;
            const file_name = transcription.transcribtion_title + '_'
                + transcription.facility_id_ref.title + '_' + doctorName;
            this.downloadFile(global.url + '/downloadAsPDF', {
                file_name: file_name
            });
        } catch (error) {
            const { message = 'Something went wrong, please try again' } = error;
            this._toastr.error(message);
        } finally {
            this._toastr.clear(infoToastr.toastId);
            this._toastr.success("Downloaded successFully")
        }
    }

    async deleteTranscription(transcription, category) {
        this.updateTranscriptionsArray.emit(transcription);
        if (transcription.is_trashed == 'false' && transcription.is_active == 'true') {
            let dialogRef = this.dialog.open(DialogConfirmationComponent, {
                data: {
                    message: 'Are you sure you want to delete this Progress Note?'
                }
            });
            dialogRef.afterClosed().subscribe((res) => {
                if (res === 'true') {
                    this.deleteAfterConfirmation(transcription, category);
                }
            });
        }
        if (transcription.is_trashed == 'true' && transcription.is_active == 'false' && transcription.note_active_status == 'trashed') {
            let dialogRef = this.dialog.open(DialogConfirmationComponent, {
                data: {
                    message: 'Are you sure to delete permanently?'
                }
            });
            dialogRef.afterClosed().subscribe((res) => {

                if (res === 'true') {
                    this.deleteAfterConfirmation(transcription, category);
                }
            });
        }
    }

    async deleteAfterConfirmation(transcription, category) {
        let toastInfo;
        if (transcription.is_trashed === 'false' && transcription.is_active === 'true' && transcription.note_active_status === 'active') {
            transcription.is_trashed = 'true';
            transcription.is_active = 'false';
            transcription.note_active_status = 'trashed'
        }
        else if (transcription.is_trashed === 'true' && transcription.is_active === 'false' && transcription.note_active_status === 'trashed') {
            transcription.is_active = 'false'
            transcription.note_active_status = 'deleted'
            transcription.is_trashed = 'true';
        }
        toastInfo = this._toastr.info('Deleting...', 'Please wait', { disableTimeOut: true });

        // transcription.is_active = 'false';
        transcription.transcribtion_id = transcription._id;
        transcription.id = this.currentUser._id;
        transcription.auth_key = this.currentUser.auth_key;
        transcription.usertype = this.currentUser.user_type;
        const response: any = await this._transcriptionCreateService.deleteTranscription(transcription, category).toPromise();
        if (response.status === 200) {
            this.transcriptions = this.transcriptions.filter(t => t._id !== transcription._id);
            this.initDictationsAndTranscriptions.emit({ transcriptions: this.transcriptions });
            this._toastr.success("Deleted Successfully")
        } else {
            this._toastr.error(response.message, 'Failed');
        }
        this._toastr.clear(toastInfo.toastId);
    }

    async restoreTranscription(transcription) {
        let toastInfo;
        // if (!confirm('Are you sure to restore Transcription?')) {
        //     return;
        // }
        let dialogRef = this.dialog.open(DialogConfirmationComponent, {
            data: {
                message: 'Are you sure to restore Transcription?'
            }
        });
        dialogRef.afterClosed().subscribe(async (res) => {
            if (res === 'true') {
                transcription.is_trashed = 'false';
                // transcription.is_active = 'false';
                transcription.transcribtion_id = transcription._id;
                transcription.id = this.currentUser._id;
                transcription.auth_key = this.currentUser.auth_key;
                transcription.usertype = this.currentUser.user_type;
                toastInfo = this._toastr.info('Restoring...', 'Please wait', { disableTimeOut: true });
                const response: any = await this._transcriptionCreateService.restoreTranscription(transcription).toPromise();
                if (response.status === 200) {
                    this.transcriptions = this.transcriptions.map(t => t._id === transcription._id ? transcription : t);
                    this.initDictationsAndTranscriptions.emit({ transcriptions: this.transcriptions });
                    this._toastr.clear(toastInfo.toastId);
                    this._toastr.success("Restored Successfully")
                } else {
                    this._toastr.error(response.message, 'Failed');
                }


            }
        });

    }


    downloadFile(url, params) {
        const infoToastr: any = this._toastr.info('Preparing file download', 'Please wait...', {
            disableTimeOut: true
        });

        return this._httpClient
            .get(url, {
                responseType: 'blob',
                params: {
                    ...params
                }
            }).pipe(
                map(res => {
                    return {
                        filename: params.file_name,
                        data: res
                    };
                }))
            .subscribe(res => {
                this._toastr.clear(infoToastr.toastrId);
                var url = window.URL.createObjectURL(res.data);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element

            }, error => {
            }, () => {
            });
    }

    isMultiSelectVisisble(transcription) {
        if (this.currentUser.user_type == global.USER_TYPE.DOCTOR || this.currentUser.user_type == global.USER_TYPE.SNF_WC_NURSE) {
            return transcription.is_completed == 'false';
        }

        return false;
    }

    isTranscriptionInMultiSelect(transcription) {
        const flag = !!this.multiSelectedTranscriptions.find(t => t?._id === transcription?._id);
        return flag
    }

    isInfoExcluded(info) {
        return this.excludeInfo.indexOf(info) >= 0
    }


    toggleCheckedNotes(note) {
        this._dashboardService.toggleCheckedNotes(note);
    }

    isCheckedNote(note) {
        this._dashboardService.toggleCheckedNotes(note);
    }
    checkVoidCensus(patientRoundingSheet) {
        if (patientRoundingSheet) {
            if (patientRoundingSheet?.is_void) {
                if (patientRoundingSheet.is_void === true) return false;
                else return true;
            } else {
                return true
            }
        } else {
            return true;
        }
    }

    noteError(note) {
        if (this.noteErrors && this.noteErrors[note._id]) {
            return `Note signing error: ${this.noteErrors[note._id].message}`
        }
    }

    async generateWoundsReportPreview(note, downloadReport = false) {
        let dateAssessment = '';
        if (note.date_obj) {
            let day = (note.date_obj.day < 10) ? ('0' + (note.date_obj.day).toString()) : (note.date_obj.day).toString();
            let month = (note.date_obj.month < 10) ? ('0' + (note.date_obj.month).toString()) : (note.date_obj.month).toString();
            let year = (note.date_obj.year);
            dateAssessment = month + '/' + day + '/' + year;
        }
        let patientObj = {
            date_of_birth: note.patient_id_ref.date_of_birth,
            first_name: note.patient_id_ref.first_name,
            gender: note.patient_id_ref.gender,
            last_name: note.patient_id_ref.last_name,
            _id: note.patient_id_ref._id,
            timeZone: note.facility_id_ref.timeZone ? note.facility_id_ref.timeZone : 'America/New_York'
        }
        const response: any = await lastValueFrom(this._woundCareWidgetService.generateReportForAllWounds(patientObj, "active", note.date_obj));
        if (response.status == 200) {
            if (downloadReport) {
                this.commonService.convertToBlobAndDownload(response.data, `${dateAssessment}`, "pdf");
            } else {
                const blob = new Blob([Buffer.from(response.data)], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                window.open(url, '_blank');
                window.URL.revokeObjectURL(url);
            }
        } else {
            if (response.status == 500) {
                this._toastr.warning(`No wound found assessed on ${dateAssessment}`, '');
            }
        }
    }

    uploadWoundReportToPCC(note) {
        if (this.hasLoggedInToPCC() || this._authService.canUploadReportToPCCUsingTwoLegged()) {
            const payload = {
                noteId: note._id
            }
            this._woundService.generateWoundReportUploadToPcc(payload)
                .subscribe((response: any) => {
                    if (response.status === 200) {
                        this._toastr.success(`Wound Report has uploaded to PCC successfully`);
                    } else {
                        this._toastr.error(response.message, "Failed");
                    }
                });
        } else {
            this._toastr.error("Report was NOT uploaded into PCC. Please sign in to PCC to upload the report.");
        }
    }

    hasLoggedInToPCC() {
        return !!this._pccService?.pccUserinfo;
    }
}
