<ng-template [ngIf]="userDetail != null">
    <div class="invitation-btn-wrap">
        <button
            mat-raised-button
            class="dn-button-primary"
            (click)="onSendInvite()"
            [disabled]="sendingInvite === true"
        >
            {{ isInviteSent === true ? 'Resend' : 'Send' }} Invitation
        </button>
        <span class="invitation-sending-text" *ngIf="sendingInvite === true">{{ isInviteSent === true ? 'Resending' : 'Sending' }} invitation, please wait...</span>
    </div>
    <table class="invitation-status-table" *ngIf="isInviteSent === true; else elseBlock">
        <tbody>
            <tr>
                <th>Last Invitation Status:</th>
                <td>Sent</td>
            </tr>
            <tr>
                <th>Last Invitation Sent On:</th>
                <td>{{ inviteSentOn | date:'long' }}</td>
            </tr>
        </tbody>
    </table>
    <ng-template #elseBlock>
        <div><i>No invitation has been sent to this user!</i></div>
    </ng-template>
</ng-template>
