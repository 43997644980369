import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { LoginService } from '../login/login.service';
import * as global from 'src/app/includes/global';

@Component({
  selector: 'app-wound-nurse-header',
  templateUrl: './wound-nurse-header.component.html',
  styleUrls: ['./wound-nurse-header.component.css']
})
export class WoundNurseHeaderComponent implements OnInit {
  currentUser: any;
  menus = [];
  updatedUserNameSubscription: any;
  defaultImg: string;
  companylogoPath: any;
  global = global;
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private commonService: CommonService,
    public loginService: LoginService,
  ) {
    this.currentUser = this._authService.currentUser;
    this.menus = [
      {
        link: [`/company/${this.currentUser.company_id}/relationship`],
        title: ['Associations'],
        activeUrls: [`/company/${this.currentUser.company_id}/relationship`]
      },
    ];
  }

  async ngOnInit() {
    await this.getCompanyLogo();
    if (this.currentUser.user_type !== global.USER_TYPE.WOUND_NURSE) {
      localStorage.removeItem('currentUser');
      setTimeout(() => {
        window.location.href = '/login';
      });
    }
    this.updatedUserNameSubscription = this.commonService.castUpdatedUsername.subscribe((name: any) => {
      this.currentUser.first_name = name.first_name;
      this.currentUser.last_name = name.last_name;
      this.currentUser.title = name.title;
    });
  }

  async getCompanyLogo() {
    this.loginService.getCompanyLogo(null, this.currentUser.company_id).subscribe((res: any) => {
      if (res.status = 200) {
        if (res.data[0].logo_aws_path) {
          this.companylogoPath = res.data[0].logo_aws_path;
        } else {
          this.defaultImg = "../../../assets/img/DN.png";
        }
      } else {
        this.defaultImg = "../../../assets/img/DN.png";
      }
    });
  }

  ngOnDestroy(): void {
    if (this.updatedUserNameSubscription) {
      this.updatedUserNameSubscription.unsubscribe();
    }
  }
  isActive(menu) {
    const currentUrl = this._router.url;
    for (let url of menu.activeUrls) {
      if ((currentUrl.indexOf(url) !== -1)) {
        return true;
      }
    }
    return false;
  }
  navigateToURL(menu) {
    return this._router.navigate(menu.link)
  }
  logout() {
    localStorage.removeItem('currentUser');
    this.commonService.removeLocalStorageItem("admin-charge-capture");
    setTimeout(() => {
      window.location.href = '/login';
    });
  }
  setCompany(company) {
    this.commonService.setCompanySelected(company);
  }
  checkAuthForAdmin(param?) {
    this._authService.checkAuthForAdmin().subscribe((response: any) => {
      if (response.status === 200) {
        let url;
        if (param) {
          url = this._router.serializeUrl(
            this._router.createUrlTree([`/admin-login`, { params: param }])
          );
        }
        else {
          url = this._router.serializeUrl(
            this._router.createUrlTree([`/admin-login`])
          );
        }
        window.open(url, '_blank', "height=640,width=960,toolbar=yes,menubar=yes,scrollbars=yes,location=yes, titlebar=yes, status=no, directories=yes");
      }
      else if (response.status === 403) {
        this._router.navigate(['/403']);
      }
    });
  }


}
