import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment'
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { ReportsService } from '../reports.service';
import swal from 'sweetalert';
import { CommonService } from 'src/app/services/common.service';
import { NoteAuditoryHistoryService } from '../../note-auditory-history/note-auditory-history.service';
import { CompanyService } from 'src/app/services/Modules/company.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { BillerDashboardService } from '../../biller-dashboard/biller-dashboard.service';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  displayedColumns = ['provider', 'initial', 'followUp', 'others', 'byProvider', 'byTranscriber', 'withAudio', 'withoutAudio', 'total',]
  dataSource: any;

  currentUser: any;
  reportDetail: Boolean = false;
  transData: Boolean = true;
  dictData: Boolean = false;
  detailData: any;
  savedDetailData: any;
  applyFilter: Boolean = true;
  facilities: any;
  reportData: any = [];
  transReportData: any;
  reportDateFilter = {
    startDate: moment().subtract(7, 'days'),
    endDate: moment(),
  };
  loaderId = 'reportData';
  type: any = 'trans';
  noteType: any = '';

  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  statusOption = ['Unsigned', 'Signed', 'Uploaded'];
  noteStatusFilter: any = 'Unsigned';
  facilityFilter: any;
  company_id: any;
  filteredCompanies: Observable<string[]>;
  companyControl = new FormControl();
  companies: any = [];
  currentCompany: any;
  selectedCompany: any;
  charges: any = [];
  isChargeByTimePeriodReportVisible: boolean = false;
  // allObj: any ='All';

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    public loader: NgxUiLoaderService,
    private dialog: MatDialog,
    private companyService: CompanyService,
    // public loader1: NgxUiLoaderService,
    private _authService: AuthService,
    private toaster: ToastrService,
    private reportService: ReportsService,
    private commonService: CommonService,
    private auditoryService: NoteAuditoryHistoryService,
    private _billerDashboardService: BillerDashboardService,
  ) {
    this.currentUser = this._authService.currentUser;
  }

  ngOnInit(): void {
    if (window.location.pathname.includes('company')) {
      this.company_id = this.route.parent.snapshot.params.id;
    }
    else {
      this.company_id = ''
    }
    this.initTrans();
    if (this.currentUser.product_owner) {
      this.getCompanies();
    }
    this.companyService.getCompanyName(this.company_id).subscribe((response: any) => {
      if (response.status == 200) {
        this.selectedCompany = response.data.name;
      }

    })
    this.filteredCompanies = this.companyControl.valueChanges.pipe(
      startWith(''),
      map((value:any) => this.filterCompany(value)));

  }
  private filterCompany(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.companies.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    else {
      return this.companies;
    }
  }

  async getCompanies() {
    const response: any = await this.companyService.getAllCompanies().toPromise();
    // console.log("init companies");
    if (response.status == 200) {
      this.companies = response.data;
      // console.log("companies",this.companies);
      this.currentCompany = this.route.parent.snapshot.params.id;
      var index = this.companies.findIndex(company => this.currentCompany === company._id);
      this.currentCompany = this.companies[index]._id;

      // this.companies.splice(index,1);
      // console.log("curre",this.currentCompany);
      // this.companyControl.setValue(this.currentCompany.name);     
    }
    else if (response.status == 403) {
      this.toaster.error(response.message, "ERROR");
    }
  }
  initTrans() {
    // this.loader.startLoader('reportData');
    this.dictData = false;
    this.transData = true;
    var cloned = JSON.parse(JSON.stringify(this.reportDateFilter));
    if (this.reportDateFilter.startDate != null) {
      this.reportService.getreportData(cloned, this.noteStatusFilter, this.company_id).subscribe((response: any) => {
        if (response.status == 200) {
          // this.dictData = false;
          this.dataSource = new MatTableDataSource(response.data);
          // this.loader.stopLoader('reportData');
        }
      })
    }
  }

  initDict() {
    this.dictData = false;
    // this.loader.startLoader('reportData');
    var cloned = JSON.parse(JSON.stringify(this.reportDateFilter));
    // getReportDataDict
    this.reportService.getReportDataDict(cloned, this.company_id).subscribe((response: any) => {
      if (response.status == 200) {
        this.transReportData = response.data;
        this.transData = false;
        this.dictData = true;
        // this.loader.stopLoader('reportData');
      }
    })
  }

  radioChange(event) {
    this.type = event.value;
    this.isChargeByTimePeriodReportVisible = false;
    if (this.type == 'trans') {
      this.initTrans();
    }
    else if (this.type == 'dict') {
      this.initDict();
    }
    else if (this.type == 'charges') {
      this.initChargesReport();
    }
  }

  initChargesReport() {
    this._router.navigate([`/company/${this.company_id}/charges-report`])
  }

  onChangeStatus(status) {
    this.noteStatusFilter = status;
    this.initTrans();
  }
  applyDateFilterReport(dates) {
    this.reportDateFilter = { ...dates }
    if (this.transData) {
      this.initTrans();
    }
    else {
      this.initDict()
    }
  }

  getProviderName(element) {
    let name = '';
    name = (element[0]?.provider[0]?.first_name) ? element[0].provider[0].first_name : ''
    name = name + " ";
    name = name + element[0]?.provider[0]?.last_name
    return name;
  }
  getInitialCount(element) {
    let initialCount = 0;
    for (let group of element) {
      if (group._id.results[0]?.match?.trim().toLowerCase() == 'init') {
        initialCount = initialCount + group.total;
      }
    }
    return initialCount;
  }
  getFollowUpCount(element) {
    let followUpCount = 0;
    for (let group of element) {
      if (group._id.results[0]?.match?.trim().toLowerCase() == 'follow') {
        followUpCount = followUpCount + group.total;
      }
    }
    return followUpCount;
  }
  getOthersCount(element) {
    // console.log("element: ", element);
    let othersCount = 0;
    for (let group of element) {
      // console.log("group: ", group);
      if (!group._id.results[0] || group._id.results.length <= 0) {
        othersCount = othersCount + group.total;
      }
    }
    return othersCount;
  }
  getTotalCount(element) {
    let totalCount = 0;
    totalCount = this.getInitialCount(element) + this.getFollowUpCount(element) + this.getOthersCount(element);
    return totalCount;
  }
  getCountTransByPro(element) {
    let count = 0;
    for (let i = 0; i < element[0]?.noteData.length; i++) {
      if (element[0]._id?.doctor_id_ref == element[0]?.noteData[i]?.uploaded_by_id_ref) {
        count++;
      }
    }
    return count;
  }
  getCountTransByTran(element) {
    let count = 0;
    for (let i = 0; i < element[0]?.noteData.length; i++) {
      if (element[0]._id?.doctor_id_ref != element[0]?.noteData[i]?.uploaded_by_id_ref) {
        count++;
      }
    }
    return count;
  }
  getCountTransWithAudio(element) {
    let count = 0;
    for (let i = 0; i < element[0]?.noteData.length; i++) {
      if (element[0].noteData[i]?.audio_id_ref) {
        count++;
      }
    }
    return count;
  }
  getCountTransWithoutAudio(element) {
    let count = 0;
    for (let i = 0; i < element[0]?.noteData.length; i++) {
      if (!element[0].noteData[i]?.audio_id_ref) {
        count++;
      }
    }
    return count;
  }

  getSpecificNoteData(element, type = null) {
    this.toaster.info('Fetching Data')
    // this.loader.startLoader('reportData');
    this.noteType = type;
    var cloned = JSON.parse(JSON.stringify(this.reportDateFilter));
    let provider;
    if (element[0]?._id?.doctor_id_ref) {
      provider = element[0]?._id?.doctor_id_ref;
    }
    // let facilities = this.reportService.getFacilities()
    const notes = this.reportService.getSpecificNoteData(cloned, provider, type, this.noteStatusFilter).subscribe((response: any) => {
      if (response.status == 200) {
        this.detailData = response.data;
        this.savedDetailData = response.data;
        this.facilities = response.facilities;
        this.reportDetail = true;
        // this.loader.stopLoader('reportData');
      }
    })
  }
  onClickBack() {
    this.reportDetail = false;
  }
  facilityChaged(fac = null) {
    this.applyFilter = false;
    if (!fac) {
      this.detailData = this.savedDetailData.slice();;
      this.applyFilter = true;
    }
    else {
      this.detailData = [];
      for (let i = 0; i < this.savedDetailData.length; i++) {
        if (fac._id == this.savedDetailData[i].facility_id_ref._id) {
          this.detailData.push(this.savedDetailData[i]);

        }
      }

      this.applyFilter = true;
    }
  }
  downloadReport() {
    const csvData = this.createCSV();
    // swal("Download File as", {
    //   buttons: {
    //     // pdf: {
    //     //   text: "PDF",
    //     //   value: "pdf",
    //     // },
    //     csv: {
    //       text: "CSV",
    //       value: "csv",
    //     },
    //     // Cancel: {
    //     //   text: "None",
    //     //   value: "none",
    //     // },
    //   },
    // })
    // .then((value)=>{
    //   switch (value) {
    //     case "csv":
    //         this.toaster.info('DownLoading as CSV')
    //         // swal("CSV is not availble");
    //         const csvData = this.createCSV(); 
    //         break;

    //       default:
    //   }

    // })
  }
  getServiceDate(note) {
    if (note) {
      if (note.filterServiceDate) {
        return moment(new Date(note.filterServiceDate).toLocaleString('en-US', { timeZone: note.timezone })).format('MMM DD YYYY');
      }
      else return ' ';
    }
  }
  getDate(note) {
    if (note) {
      if (note.filterCreationDate) {
        return moment(new Date(note.filterCreationDate).toLocaleString('en-US', { timeZone: note.timezone })).format('MMM DD YYYY');
      }
      else return ' ';
    }
  }
  getNoteType(note) {
    if (note.transcribtion_title.toLowerCase().includes('init')) {
      return "INITIAL"
    }
    else if (note.transcribtion_title.toLowerCase().includes('follow')) {
      return "FOLLOW UP"
    }
    else return "other"

  }
  getNoteStatus(status) {
    return this.auditoryService.formatOperation(status).toUpperCase();
  }

  async createCSV() {
    // const headerList = ['dos', 'facility', 'provider','patient', 'note_type' ]
    const headerList = ['date', 'dos', 'patient', 'facility', 'note_type']
    let array = typeof this.detailData != 'object' ? JSON.parse(this.detailData) : this.detailData;
    let finalJson = [];
    await array.forEach(async note => {
      let row = {
        // created_date: new Date().toLocaleDateString(),
        date: note?.filterServiceDate ? this.getDate(note) : '',
        dos: note?.filterServiceDate ? this.getServiceDate(note) : '',
        patient: note?.patient_id_ref ? note.patient_id_ref.last_name + ' ' + note.patient_id_ref.first_name : '',
        facility: note?.facility_id_ref?.title ? this.commonService.cleanTranscriptionTitle(note.facility_id_ref.title).toUpperCase() : ' ',
        // provider: note?.doctor_id_ref?  note.doctor_id_ref.first_name + ' ' + note?.doctor_id_ref.last_name : '',
        // title: note?.transcribtion_title? this.commonService.cleanTranscriptionTitle(note.transcribtion_title).toUpperCase() : ' ',
        // status: note?.note_status?.status? this.getNoteStatus(note.note_status.status): '',
        note_type: this.getNoteType(note),
      }
      await finalJson.push(row);
      // });
    });

    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < finalJson.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index].trim();
        line += ',' + finalJson[i][head];
      }
      str += line + '\r\n';
    }
    this.reportService.downlaodCSV(str);
    return str;
  }

  navigate($event, id) {
    
    if ($event.isUserInput) {
      // console.log("id", id);
      this.currentCompany = id;
      // console.log({id});
      // this._router.navigateByUrl(`/company/${id}/dashboard/notes`); 
      if (this.currentCompany !== this.route.parent.snapshot.params.id) {
        this._router.navigateByUrl(`/dashboard`, { skipLocationChange: true }).then(() =>
          this._router.navigate([`/company/${this.currentCompany}/reports`]));
      }
    }

  }

}
