<h3>Upload Documents</h3>
<br />
<div class="flex flex-col gap-2">
  <div mat-dialog-content>
      <form  (submit)="fileInput.value ? dialogRef.close(fileInput) : emptyError=true">
        <input placeholder="Select file" type="file" #fileInput (change)="emptyError = fileInput.value ? false : true" required>
        <p style="color:red" *ngIf="emptyError">You must select the file to upload.</p>

        <button class="dn-button-primary" mat-raised-button>Upload</button>
      </form>
    </div>
  <div class="top-right-close-btn">
      <button mat-mini-fab mat-dialog-close ><mat-icon >cancel</mat-icon></button>
    </div>
</div>