<h3>
  Preview Template
</h3>

<div class="flex flex-col gap-2" style="font-size: 12px;line-height: 2;">


  <div class="flex flex-col">

    <h3 style="text-align: center;font-weight: bold;">{{title | uppercase}}</h3>
    
    <pre *ngIf="plainText" [innerText]="plainText"></pre>
  </div>

  <div class="flex gap-5 justify-end">
    <button mat-raised-button class="dn-button-secondary" [mat-dialog-close]="false">Close</button>
  </div>

  <div class="top-right-close-btn">
      <button mat-mini-fab mat-dialog-close ><mat-icon >cancel</mat-icon></button>
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>

</div>