import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { EmailTemplateService } from '../email-template/service/email-template.service';
import { CKEditorService } from '../note-editor/ckeditor.service';

@Component({
  selector: 'app-create-email-template-dialog',
  templateUrl: './create-email-template-dialog.component.html',
  styleUrls: ['./create-email-template-dialog.component.css']
})
export class CreateEmailTemplateDialogComponent implements OnInit {
  cke4Config: any;
  model = {
    title: null,
    subject: null,
    emailBody: null,
  }
  filter: any = {};
  project: any = {};
  constructor(
    private ckeditorService: CKEditorService,
    public dialogRef: MatDialogRef<CreateEmailTemplateDialogComponent>,
    private _authService: AuthService,
    private _emailTemplateService: EmailTemplateService,
    private _toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) {
    this.cke4Config = {
      ...this.ckeditorService.getDefaultConfig(),
      editable: true
    };
  }

  ngOnInit() {
    if (this.data) {
      this.filter = { ...this.filter, _id: this.data };
      this.getEmailTemplate();
    }
  }

  getEmailTemplate() {
    this.project = {
      title: 1,
      subject: 1,
      emailBody: 1
    };
    this._emailTemplateService.getEmailTemplate(this.filter, this.project).subscribe((response: any) => {
      if (response.status == 200) {
        this.model = response.data;
      }
    }, err => {
      this._toastr.error(err, "ERROR");
    }
    );
  }

  submitForm() {
    if (this.data) {
      this._emailTemplateService.updateEmailTemplate(this.model).subscribe((response: any) => {
        if (response.status == 200) {
          this._toastr.success("Email template updated", "Success");
          this.dialogRef.close(response.data);
        }
      }, err => {
        this._toastr.error(err, "ERROR");
      }
      );
    }
    else {
      this._emailTemplateService.createEmailTemplate(this.model).subscribe((response: any) => {
        if (response.status == 200) {
          this._toastr.success("Email template created", "Success");
          this.dialogRef.close(response.data);
        }
      }, err => {
        this._toastr.error(err, "ERROR");
      }
      );
    }
  }

}
