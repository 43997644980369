import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment'
import * as global from '../../../../global';
import { ToastrService } from 'ngx-toastr';
import { Observable, lastValueFrom } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FacilityService } from 'src/app/services/facility.service';
import { Location } from '@angular/common';
import { WoundService } from 'src/app/services/wound.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { SnfWcNurseAssociationService } from 'src/app/includes/snf_wc_nurse-snf_wc_nurse-association/snf_wc_nurse_association.service';
import { NursingService } from 'src/app/includes/nursing-module/nursing.service';
import { WoundNurseRelationService } from 'src/app/services/Modules/woundNurse.service';
import { CommonService } from 'src/app/services/common.service';
import { WoundCareWidgetService } from 'src/app/includes/note-sidebar/wound-care-widget/wound-care-widget.service';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';
import * as _ from 'lodash';

@Component({
  selector: 'app-pressure-wound-report',
  templateUrl: './pressure-wound-report.component.html',
  styleUrls: ['./pressure-wound-report.component.css']
})
export class PressureWoundReportComponent implements OnInit {

  currentDate = moment(new Date()).add(1, 'days');
  minDate = moment(new Date()).subtract(6, 'days');
  loaderId = 'app-pressure-wound-report';
  isSummaryReport: boolean = false;
  filter: any = {
    updationDate: {
      startDate: moment(),
      endDate: moment()
    },
    facilityId: null,
    companyId: null,
    state: 'active',
    etiolgy: "",
    dateType: 'last_assessment_date_obj',
  };
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 1 Week': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  };
  filteredFacilities: Observable<string[]>;
  displayedColumns: string[] = [
    'room_number', // Room #
    'name', // Last Name, First Name
    'etiolgy', // Etiology
    'wound_recognition_date', // Facility Admission Date
    'body_part', // Wound Location
    'last_assessment_date_obj',
    'wound_recognition_date_type', // Present on Admission
    'wound_status',
    'stage', // Severity
    'push_score',
    'push_trend',
    'key_assessment_parameters'
  ];

  facilityControl = new FormControl();
  facilities: Array<any> = [];
  report: any = [];
  dataSource: any = new MatTableDataSource([]);
  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    PAGE_LIMIT: 50,
    skip: 0
  };
  currentUser: any;
  companySide: boolean;
  nursingSide: boolean;
  isUserDashboard: Boolean = false;
  title: string = 'Wound Report by Etiology';
  isSnfNurse: boolean = false;
  isNurse: boolean = false;
  isWoundNurse: boolean = false;
  nurseAssociatedFacilities: Array<any> = [];
  summary: any = {};
  etiologies: any = [];
  facilityInputText: string = '';
  timeoutId: any;

  constructor(
    private router: Router,
    private _toastr: ToastrService,
    private _facilityService: FacilityService,
    private _route: ActivatedRoute,
    private _location: Location,
    private _wound: WoundService,
    private loader: NgxUiLoaderService,
    private _authService: AuthService,
    private _snfWcNurseAssociationService: SnfWcNurseAssociationService,
    private _nurseService: NursingService,
    private woundNurseService: WoundNurseRelationService,
    private commonService: CommonService,
    private _woundCareWidgetService: WoundCareWidgetService

  ) {
    this.currentUser = this._authService.currentUser;
    this.filter.companyId = this._route.parent.snapshot.params.id;
    if (this._route.parent.snapshot.params.id) {
      this.companySide = true;
    } else {
      this.filter.companyId = this.currentUser.company_id;
    }
    if (window.location.pathname.includes('dashboard')) {
      this.isUserDashboard = true;
    }
    if (window.location.pathname.includes('nursing')) {
      this.nursingSide = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE) {
      this.isSnfNurse = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.NURSE) {
      this.isNurse = true;
    }
    if (this.currentUser.user_type === global.USER_TYPE.WOUND_NURSE) {
      this.isWoundNurse = true;
    }

  }

  async ngOnInit() {
    if (this.currentUser.company_type.toLowerCase() == "wound") {
      this.title = 'Wound Report by Etiology'
    }
    this.loader.startLoader(this.loaderId);
    await this.getEtiologies();
    await this.initFacilities();
    this.selectDefaultFacility();
    this.loader.stopLoader(this.loaderId);
    await this.handleApplyFilter();
    this.filteredFacilities = this.facilityControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => this.filterFacilities(value)));
  }

  async getEtiologies() {
    const response: any = await lastValueFrom(this._woundCareWidgetService.getAllDataset(this.filter.companyId,
      null, "1", null, null,
      this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE ? this.currentUser.user_type : global.USER_TYPE.DOCTOR,
      "WOUND TYPE"));
    if (response.status === 200 && response.data) {
      const [woundDataset] = response.data;
      if (woundDataset && Array.isArray(woundDataset.rows) && woundDataset.rows.length > 0) {
        this.etiologies = woundDataset.rows.map(row => row.value);
        this.filter.etiolgy = [this.etiologies[0]];
      }
    }
  }

  private filterFacilities(value: string): string[] {
    if (this.isSnfNurse || this.isNurse || this.isWoundNurse) {
      if (value) {
        const filterValue = value.toLowerCase();
        return this.nurseAssociatedFacilities.filter(option => option.title.toLowerCase().indexOf(filterValue) > -1);
      }
      else {
        return this.nurseAssociatedFacilities;
      }
    } else {
      if (value) {
        const filterValue = value.toLowerCase();
        return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) !== -1);
      }
      else {
        return this.facilities;
      }
    }
  }
  selectDefaultFacility() {
    if (this.isSnfNurse || this.isNurse || this.isWoundNurse) {
      if (this.nurseAssociatedFacilities.length > 0) {
        this.facilityControl.setValue(this.nurseAssociatedFacilities[0].title);
        this.filter.facilityId = this.nurseAssociatedFacilities[0]._id
        this.facilityControl.setValue(this.nurseAssociatedFacilities[0].title);
      }
    } else {
      if (this.facilities.length > 0) {
        this.filter.facilityId = this.facilities[0]._id;
        this.facilityControl.setValue(this.facilities[0].title);
      }
    }
  }
  async initPressureWoundReport() {
    this.filter.pageNo = this.pagination.pageNo;
    const filter = _.cloneDeep(this.filter);
    // delete filter.companyId;
    this.loader.startLoader(this.loaderId);
    console.log("filterrrrr : ", filter);
    const response: any = await lastValueFrom(this._wound.getPressureWoundReport(filter));
    if (response.status == 200) {
      this.report = response.data.report;
      this.pagination.totalRecords = response.data.totalRecords;
      this.handlePagination();
      this.dataSource.data = this.report;
      this.loader.stopLoader(this.loaderId);
      if (this.report.length == 0) {
        this._toastr.info("No data available on selected DOS");
      }
    }
    else {
      this.loader.stopLoader(this.loaderId);
    }
  }

  async initSummaryPresureReport() {
    this.filter.pageNo = this.pagination.pageNo;
    const response: any = await lastValueFrom(this._wound.getSummaryPressureReport(this.filter));
    if (response.status == 200 && response.data.length) {
      this.summary = response.data[0];
    }
    else
      this.loader.stopLoader(this.loaderId);
  }

  handlePagination() {
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    if (this.pagination.skip + this.report.length < this.pagination.totalRecords) {
      this.pagination.hasNextPage = true;
    }
    else {
      this.pagination.hasNextPage = false;
    }
    if (this.pagination.skip > 0) {
      this.pagination.hasPrevPage = true;
    }
    else {
      this.pagination.hasPrevPage = false;
    }
  }

  async initFacilities() {
    if (this.isSnfNurse) {
      const filter = {
        company_id: this.filter.companyId,
        associated_snf_wc_nurse_ids: this.currentUser._id,
      };
      const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      const facilityProjection = {
        title: 1,
        pcc_facId: 1,
        pcc_2_legged_authentication: 1,
        source: 1
      };
      const response: any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
      if (response.status === 200 && response.data.length > 0) {
        this.nurseAssociatedFacilities = response.data;
        this.nurseAssociatedFacilities.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
        this.facilities = this.nurseAssociatedFacilities;
      }
    }
    else if (this.isNurse) {
      const response: any = await lastValueFrom(this._nurseService.getAssociatedFacilities());
      if (response.status === 200 && response.data.array.length > 0) {
        this.nurseAssociatedFacilities = response.data.array;
        this.nurseAssociatedFacilities.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
        this.facilities = this.nurseAssociatedFacilities;
      }
    }
    else if (this.isWoundNurse) {
      const filter = {
        company_id_ref: this.filter.companyId,
        associated_wound_nurse_ids: this._authService.currentUser._id
      };
      const nurseProjection = {
        first_name: 1,
        last_name: 1,
        title: 1,
      };
      const facilityProjection = {
        title: 1,
        pcc_facId: 1,
        pcc_2_legged_authentication: 1,
        source: 1
      };
      const facilitiesResponse: any = await lastValueFrom(this.woundNurseService.getWoundNurseFacilities(filter, nurseProjection, facilityProjection));
      if (facilitiesResponse.status === 200) {
        this.nurseAssociatedFacilities = facilitiesResponse.data;
        this.nurseAssociatedFacilities.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
        this.facilities = this.nurseAssociatedFacilities;
      }
    }
    else {
      const filter: any = {
        assoc_company_ids: this.filter.companyId,
        is_active: true
      };
      if (this.isUserDashboard) {
        filter.provider_ids_ref = this.currentUser._id;
      }
      const projection = {
        title: 1,
        source: 1,
        assoc_company_ids: 1
      };

      let companyAssocPopulation: facilityPopulationInterface = {
        collection: 'companies',
        field: ["assoc_company_ids"],
        filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: [true, { $in: ['$_id', '$$assoc_company_ids'] }] }] } },
        project: { name: 1 },
      };

      const facilitiesResponse: any = await lastValueFrom(this._facilityService.getFacilities(filter, projection, [companyAssocPopulation]));
      if (facilitiesResponse.status == 200) {
        this.facilities = this.sortTitleVise(facilitiesResponse.data.array);
      }
    }
  }
  sortTitleVise(arrayTosort) {
    let facilities = arrayTosort;
    let result = facilities.sort(function (a, b) {
      if (a.title?.toLowerCase() < b.title?.toLowerCase()) { return -1; }
      if (a.title?.toLowerCase() > b.title?.toLowerCase()) { return 1; }
      return 0;
    })
    return result;
  }
  onSelectFacility($event, facility) {
    if ($event.isUserInput) {
      this.filter.facilityId = facility._id;
    }
  }
  changeUpdationDate(event) {
    if (event.startDate && event.endDate) {
      this.filter.updationDate = {
        startDate: null,
        endDate: null
      };
      const noOfDays = this.commonService.getDifferenceOfDates(event.startDate,event.endDate,'days');
      if (noOfDays > 29) {
        this.filter.updationDate = {};
        this.filter.updationDate.startDate = moment(event.startDate);
        this.filter.updationDate.endDate = moment(this.filter.updationDate.startDate).add(29, "days").endOf('day');
        this._toastr.error('Can not select more than 30 days', 'Date Error');
      }
      else {
        this.filter.updationDate.startDate = event.startDate;
        this.filter.updationDate.endDate = event.endDate;
      }
    }
  }

  goBack() {
    this._location.back();
  }
  async handleApplyFilter() {
    this.facilityInputText = (<HTMLInputElement>document.getElementById('facilityControlInput')).value;
    console.log("feildd in text : ", this.facilityInputText);
    if (this.facilityInputText == '') {
      // this._toastr.warning('Please select facility from list', 'Warning');
      // return;
    } else {
      if (this.isSnfNurse) {
        const exists = this.nurseAssociatedFacilities.filter(option => option.title.toLowerCase().trim() == this.facilityInputText.toLowerCase().trim());
        if (!exists.length) {
          this._toastr.warning('Please select facility from list', 'Warning');
          return;
        }
      } else {
        const exists = this.facilities.filter(option => option.title.toLowerCase().trim() == this.facilityInputText.toLowerCase().trim());
        if (!exists.length) {
          this._toastr.warning('Please select facility from list', 'Warning');
          return;
        }
      }
    }
    await this.initPressureWoundReport();
    // await this.initSummaryPresureReport();
  }

  onStateChange(value) {
    this.filter.state = value;
  }
  async resetFilter() {
    delete this.filter.updationDate;
    this.filter.updationDate = {
      startDate: moment(),
      endDate: moment()
    };
    this.filter.orientation = 'landscape';
    this.filter.dateType = 'last_assessment_date_obj';
    this.filter.state = "all";
    this.selectDefaultFacility();
    await this.handleApplyFilter();
  }

  onOrientationChange(value) {
    this.filter.orientation = value;
  }

  goToPreviousPage() {
    this.loader.startLoader(this.loaderId);
    this.pagination.pageNo--;
    this.initPressureWoundReport();
    this.loader.stopLoader(this.loaderId);
  }
  goToNextPage() {
    this.loader.startLoader(this.loaderId);
    this.pagination.pageNo++;
    this.initPressureWoundReport();
    this.loader.stopLoader(this.loaderId);
  }

  async exportPressureWoundReportAsXLSX(exportOption: string) {
    if (this.report.length == 0) {
     return this._toastr.info("No data available on selected DOS");
    }
    const filter = _.cloneDeep(this.filter);
    // delete filter.companyId;
    filter.exportOption = exportOption;
    const infoToastr = this._toastr.info('Exporting as XLSX...', 'Please wait', { disableTimeOut: true });
    const response: any = await lastValueFrom(this._wound.exportPressureWoundReportAsXLSX(filter));
    /**********Amazon API Gateway Timeouts in 30s ********/
    clearTimeout(this.timeoutId);
    if (exportOption === 'email') {
      this.timeoutId = setTimeout(() => {
        this._toastr.clear(infoToastr.toastId);
        this._toastr.success("Wound Report By Etiology has been sent to your email");
      }, 1500);
    }
    if (exportOption === 'download') {
      if (response.body instanceof Blob) {
        this._toastr.clear(infoToastr.toastId);
        const url = window.URL.createObjectURL(response.body);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = "Wound Report By Etiology";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this._toastr.clear(infoToastr.toastId);
      }
      else if (response.status === 504) {
        this._toastr.clear(infoToastr.toastId);
        this._toastr.error("Maximum limit reached, send via email.");
      }
    }
  }

  changeEtiology(value) {
    this.filter.etiolgy = value;
    this.masterSelected = false;
    if (this.filter.etiolgy.length == this.etiologies.length) {
      this.masterSelected = true;
    }
  }
  masterSelected: boolean = false;
  selectAllWoundTypes(event) {
    if (event.checked) {
      this.filter.etiolgy = this.etiologies;
    }
    else {
      this.filter.etiolgy = [];
      this.filter.etiolgy.push(this.etiologies[0])
    }
  }
}