import { Component, OnInit, Input } from '@angular/core';
import { PCCService } from 'src/app/services/pcc.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-patient-diagnostic-reports',
  templateUrl: './patient-diagnostic-reports.component.html',
  styleUrls: ['./patient-diagnostic-reports.component.css']
})
export class PatientDiagnosticReportsComponent implements OnInit {
  loaderId ='patient-diagnostic-reports-loader';
  @Input('patient') patient;
  // @Input('pccOrgUuid') pccOrgUuid;
  constructor(private _pccService: PCCService,
    private toastr:ToastrService,
    private loader: NgxUiLoaderService) { }
  pccOrgUuid:string;
  apiData;
  page = 1;
  dataSource;
  displayedColumns = [
    "reportId",
    "reportName",
    "reportingLaboratory",
    "reportType",
    "performingLaboratory",
    "effectiveDateTime",
    "issuedDateTime",
    "reportFile",
    "reportStatus",
    "comment",
    "codeDescription",
    "testSet", // object
    "orderingPractitioner", // object
  ];

  inprogress = false;

  @Input('reportType') reportType:'laboratory'|'radiology' = 'radiology'; // Type of report requested. Acceptable values are 'laboratory' or 'radiology'.
  pccReportTypes = ["laboratory", "radiology"];

  view:''|'testSet' = '';
  viewData;

  ngOnInit(): void {
    this.pccOrgUuid = this.patient.facility_id_ref.pcc_orgUuid;
    console.log("this.reportType : ", this.reportType);
    if(this.reportType=='laboratory'){
      this.loaderId='patient-diagnostic-reports-loader';
    }else if(this.reportType=='radiology'){
      this.loaderId='patient-diagnostic-reports-loader_two';
    }else{
      this.loaderId='patient-diagnostic-reports-loader_three';
    }

    this.loadData(this.page, this.reportType);
  }

  loadData(page, reportType) {
    this.loader.startLoader(this.loaderId);
    let authMode;
    if(!this.patient.facility_id_ref.pcc_2_legged_authentication) {
      authMode = 'APP'
    }
    this._pccService.getPatientDiagnosticReports({
      pccOrgUuid:this.pccOrgUuid,
      pccPatientId:this.patient.pcc_patientId,
      pccReportType: reportType,
      pccPage: String(page),
      authMode
      }).subscribe((response: any) => {

      if (response.status === 200) {
        this.apiData = response.data;
        
        this.dataSource = new MatTableDataSource(this.apiData.data);
        this.page = page;
      } else {
        this.toastr.error('Something went wrong while fetching Immunizations data');
      }
    }, (error) => {
      this.toastr.error('Something went wrong while fetching Immunizations data');
    }, () => {
      this.loader.stopLoader(this.loaderId);
    })
  }
  navigate(prevNext) {
    const newPageNo = this.page + prevNext;
    this.loadData(newPageNo, this.reportType);
  }

  changeView(view, viewData) {
    this.view = view;
    this.viewData = viewData;

  }
}
