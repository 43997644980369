
<app-header></app-header>
<div class="parent_class flex">

  <div *ngIf="false" [hidden]="!show_card_view" class="child_one_class ">
    <div class="close_button">
      <mat-icon style="    margin-right: 10px;margin-top: 10px;cursor: pointer;"
        (click)="show_card_view=!show_card_view">
        close
      </mat-icon>
    </div>
    <app-census-list [detect_refresh_child]="detect_refresh_child"></app-census-list>
  </div>
  <div *ngIf="false" (click)="show_card_view=!show_card_view" [ngClass]="{'Mid_child1': show_card_view, 
  'Mid_child': !show_card_view}">
    <mat-icon *ngIf="!show_card_view" class="arrow_icon_class">
      menu
    </mat-icon>
  </div>
  <div *ngIf="false" class="child_two_class">
    <ng-container *ngIf="!error2LeggedAuthFlag">
      <div *ngIf="params.id=='null'">
        <p class="no_census_available">Please create a new Census on the left to view details.</p>
      </div>
    </ng-container>
    <div *ngIf="error2LeggedAuthFlag">
      <p class="no_census_available">{{error2LeggedAuth}}</p>
    </div>

  </div>
  <mat-sidenav-container>

    <mat-sidenav mode="side" *ngIf="displayCommentSidenav" [(opened)]="displayCommentSidenav" position="end">
      <app-census-comment (recentComment)="getCensusRecentComment($event)" (closeSideNav)="displayCommentSidenav=$event"
        [patient]="currentPatient">
      </app-census-comment>
    </mat-sidenav>

    <main>

      <div class="flex gap-2">

        <div class="wrapper flex flex-col gap-2">

          <div class="census-header flex justify-between items-baseline gap-2">

            <button class="dn-button-secondary" mat-raised-button (click)="goBack()">
              Go Back
            </button>
            <div class="flex flex-grow gap-2">
              <ng-container *ngIf="isPccSearchPatientEnabled(census)">
                <mat-card style="padding-top: 0; padding-bottom: 0; height: 42px">
                  <mat-card-content>
                    <mat-form-field style="width: 60px;">
                      <mat-label>Source</mat-label>
                      <mat-select [(ngModel)]="patientsImportSource">
                        <mat-option value="dn">DN</mat-option>
                        <mat-option value="pcc" *ngIf="census?.facility?.pcc_facId">PCC</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </mat-card-content>
                </mat-card>

                <ng-container *ngIf="patientsImportSource === 'dn'">
                  <mat-card style="padding-top: 0; padding-bottom: 0; height: 42px" class="flex-grow">
                    <mat-card-content>
                      <mat-form-field style="width: 100%">
                        <mat-label>Search and add Patients.</mat-label>
                        <input matInput type="text" name="patientsByProvider" [formControl]="patientsByProviderControl"
                          [matAutocomplete]="patientAutoComplete" />
                        <mat-autocomplete #patientAutoComplete="matAutocomplete" (optionSelected)="
                    handleAddPatient($event.option.value)
                    " [displayWith]="displayPatientFn">
                          <mat-option *ngFor="let patient of filteredPatientsByProvider | async" [value]="patient">
                            <div class="flex justify-between">
                              <app-patient-name [patient]="patient"></app-patient-name>
                              <div class="flex justify-end gap-2">

                                <button mat-raised-button class="dn-button-primary"
                                  style="background-color: #FFF; color: #43adff;" (click)="$event.preventDefault();"
                                  *ngIf="!selectedPatientForImport(patient)">
                                  Add to Census
                                </button>
                              </div>
                            </div>
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                      <ngx-ui-loader [loaderId]="patientsSearchLoaderId"></ngx-ui-loader>
                    </mat-card-content>
                  </mat-card>
                </ng-container>
                <ng-container *ngIf="patientsImportSource === 'pcc'">
                  <mat-card style="
                padding-top: 0;
                padding-bottom: 0;
                height: 42px;
                position: relative;
              " class="flex-grow">
                    <mat-card-content>
                      <mat-form-field style="width: 100%">
                        <mat-label>Search and add PCC Patients</mat-label>
                        <input matInput type="text" name="pccPatients" [formControl]="pccPatientsControl"
                          [matAutocomplete]="pccPatientAutoComplete" #pccPatientSearch />
                        <mat-autocomplete #pccPatientAutoComplete="matAutocomplete" (optionSelected)="
                      handleImportPatient($event.option.value, true)
                    " [displayWith]="displayPatientFn">
                          <mat-option *ngFor="let patient of filteredPccPatients | async" [value]="patient">
                            <div class="flex justify-between">
                              <app-patient-name [patient]="patient"></app-patient-name>
                              <div class="flex justify-end gap-2">

                                <button mat-raised-button class="dn-button-primary"
                                  style="background-color: #FFF; color: #43adff;" (click)="$event.preventDefault();"
                                  *ngIf="!selectedPatientForImport(patient)">
                                  Add to Census
                                </button>
                                <button mat-raised-button class="dn-button-primary"
                                  (click)="$event.stopPropagation();togglePatientFromQueueForImport($event, patient)"
                                  *ngIf="!selectedPatientForImport(patient)">
                                  Add to Queue (multiple patients)
                                </button>
                                <button mat-raised-button class="dn-button-secondary"
                                  (click)="$event.stopPropagation();togglePatientFromQueueForImport($event, patient)"
                                  *ngIf="selectedPatientForImport(patient)">
                                  Remove from Queue
                                </button>
                              </div>
                            </div>
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                      <ngx-ui-loader [loaderId]="patientsSearchLoaderId"></ngx-ui-loader>
                    </mat-card-content>
                  </mat-card>
                  <button mat-raised-button class="dn-button-primary" style="margin-bottom: 10px;"
                    *ngIf="selectedPatients.length > 0" (click)="importMultiplePatients()">Import
                    {{selectedPatients.length}}
                    Patients</button>
                  <mat-card style="padding-top: 0; padding-bottom: 0; height: 42px">
                    <mat-card-content>
                      <mat-form-field style="width: 120px;">
                        <mat-select [(ngModel)]="searchPatientStatus"
                          (ngModelChange)="doInitPccPatients(pccPatientSearch.value)">
                          <mat-option disabled value="">Patient Status</mat-option>
                          <mat-option *ngFor="let status of patientStatuses" [value]="status">{{status}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </mat-card-content>
                  </mat-card>
                </ng-container>

              </ng-container>
            </div>
            <mat-card style="padding-top: 0; padding-bottom: 0; height: 42px">
              <mat-card-content>
                <mat-form-field style="width: 90px;">
                  <mat-label>Sort</mat-label>
                  <mat-select [disabled]="!census" name="filterSort" [(ngModel)]="filterSort"
                    (ngModelChange)="handleSortFilter($event)">
                    <mat-option value="user_sort">User Sort</mat-option>
                    <mat-option value="seen">Seen ({{ getSeenPatientsCount() }})</mat-option>
                    <mat-option value="not_seen">Not Seen ({{ getNotSeenPatientsCount() }})</mat-option>
                    <mat-option value="name">Name</mat-option>
                    <mat-option value="room_no">Room No</mat-option>
                    <mat-option value="new_patient">New Patient</mat-option>
                  </mat-select>
                </mat-form-field>
              </mat-card-content>
            </mat-card>

            <mat-card style="padding-top: 0; padding-bottom: 0; height: 42px;">
              <mat-card-content>
                <mat-form-field style="width: 80px;">
                  <mat-label>Activation</mat-label>
                  <mat-select name="filterSort" value="active"
                    (selectionChange)="handleActivationFilter($event.value)">
                    <mat-option value="active">Active</mat-option>
                    <mat-option value="inactive">Inactive</mat-option>
                    </mat-select>
                </mat-form-field>
              </mat-card-content>
            </mat-card>
            
            <button *ngIf="patientsImportSource == 'dn'" class="dn-button-primary" mat-raised-button (click)="addPatientForm()">
              Add Patient
            </button>

            <button *ngIf="isOwner(census)" class="dn-button-primary" mat-raised-button (click)="uploadFileDialoge()">
              Upload File
            </button>

            <span *ngIf="census" style="font-size: 12px;color:#FFF;display:inline-block">(<span style="cursor: pointer;"
                (click)="$event.preventDefault(); viewRehabDocs(census._id)">{{census.rehab_docs_count}}</span>)</span>
          </div>

          <div class="census-content flex gap-2">
            <div class="flex flex-col flex-grow gap-2">
              <div style="color: #fff" class="flex justify-between">
                <div>{{ census?.name }}</div>
                <div>Active Patients: {{ census?.patientList?.length - census?.patientsInactive?.length }}</div>
                <div>Inactive Patients: {{ census?.patientsInactive?.length }}</div>
                <div>I/E: {{ getPatientsCounts('new') }}</div>
                <div>F/U: {{ getPatientsCounts('followUp') }}</div>
                <div class="flex justfy-cente gap-1">
                  <app-facility-title [facility]="census?.facility"></app-facility-title>
                </div>

                <div *ngIf="false" class="flex justify-start items-center">

                  <span matTooltip="Download" style="cursor: pointer;">
                    <img src="../../../assets/img/down-arrow.png" style="width: 20px; opacity: .8;padding-right: 10px;"
                      (click)="printCensusPatientList()" />
                  </span>
                </div>

                <span style="display: inline-flex;">
                  <mat-icon (click)="printCensusPatientList()" matTooltip="Download"
                    style="cursor: pointer; margin-right: 15px;">file_download</mat-icon>
                  <mat-icon matTooltip="PDF Settings" style="cursor: pointer; margin-right: 15px;"
                    [routerLink]="['/user-settings',{census: 'census'}]">settings</mat-icon>
                  <ng-container *ngIf="isOwner(census)" style="margin-right: 15px;">
                    <mat-icon *ngIf="!reloadingCensus" (click)="reloadCensus()"
                      matTooltip="Reload Census & Update PCC Info">
                      refresh</mat-icon>
                    <mat-progress-spinner matTooltip="Reloading Census" diameter="20" *ngIf="reloadingCensus"
                      mode="indeterminate"></mat-progress-spinner>
                  </ng-container>
                </span>
              </div>
              <div>
                <div class="flex flex-grow gap-2">
                  <app-input-with-dropdown style="width: 100%;" placeholder="Search Patient by Name"
                    (inputChange)="applyFilter($event.target.value)" [dropdownActive]="filterDropdownActive"
                    (onDropdownChange)="filterDropdownActive = $event" [value]="filterSearch" [noDropdown]="true">
                  </app-input-with-dropdown>
                  <button class="dn-button-primary" mat-raised-button (click)="deleteAllPatients()"> Delete Discharged
                    Patients
                  </button>
                  <button *ngIf="selectedPatientsToImport.length > 0" class="dn-button-primary" mat-raised-button
                    (click)="importPatientDialoge()">
                    Add to Census
                  </button>
                </div>
              </div>
              <div>
                <div *ngIf="census" class="roundingsheet-patient-list-table-wrapper flex gap-2">
                  <table class="table-row-hover roundingsheet-patient-list-table w-1/2" mat-table matSort
                    [dataSource]="dataSource" cdkDropList style="height: fit-content;"
                    (cdkDropListDropped)="onListDrop($event)"
                    (matSortChange)="sortData($event); castCensusPaitentSort($event);">
                    <ng-container matColumnDef="switch-status">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="switch-status">Status</th>
                      <td mat-cell *matCellDef="let element">
                        <mat-slide-toggle (change)="
                      markPatientSeen(element, !isPatientSeenInCensus(element))
                    " [checked]="isPatientSeenInCensus(element)" [class.isSeen]="isPatientSeenInCensus(element)"
                          [class.isNotSeen]="!isPatientSeenInCensus(element)"></mat-slide-toggle>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="name" (click)="filterSort = 'name'">Name
                      </th>
                      <td mat-cell *matCellDef="let element" (click)="onRowClick($event, element.patient._id)" cdkDrag>
                        <app-patient-title [patient]="element.patient"></app-patient-title>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="deleteNote">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element">

                        <ng-container *ngIf="element.recent_note">

                          <app-note-delete-icon style="margin-left: 2px;
                      margin-right: -20px;" *ngIf="element.recent_note"
                            [note]="element.recent_note"
                            (click)="handleRemoveCensusNote(element.recent_note._id)"></app-note-delete-icon>
                        </ng-container>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="note">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="note">Note</th>
                      <td mat-cell *matCellDef="let element">

                        <ng-container *ngIf="element.recent_note">

                          <app-note-status-icon [note]="element.recent_note"
                            (click)="showViewNoteDialog(element.recent_note._id)">
                          </app-note-status-icon>
                        </ng-container>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="discharge">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="discharge">D/C</th>
                      <td mat-cell *matCellDef="let element">
                        <div class="flex flex-inline justify-center items-center" *ngIf="element.patient.discharge_date"
                        >
                          <p style="margin-right: 5px;width: max-content;">{{element.patient.discharge_date ? getDate(element.patient.discharge_date): null}}</p>
                          <img src="../../../assets/discharge.png" alt="discharge" style="width: 24px; height: 24px" />
                        </div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="date_of_birth">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="date_of_birth">DOB</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.patient.date_of_birth | date: 'M/d/yyyy' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="pcc_patient_diagnosis">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="pcc_patient_diagnosis">PDX</th>
                      <td matTooltip={{element.pcc_primaryDiagnosis?.icd10Description}} mat-cell
                        *matCellDef="let element">
                        {{element.patient.pdx}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="gender">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="gender">Gender</th>
                      <td mat-cell *matCellDef="let element">{{ element.patient.gender }}</td>
                    </ng-container>

                    <ng-container matColumnDef="facility">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="facility">Facility</th>
                      <td mat-cell *matCellDef="let element">
                        <app-facility-title [facility]="census.facility.title"></app-facility-title>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="location">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="location" (click)="filterSort = 'room_no'">
                        Room
                        No</th>
                      <td mat-cell *matCellDef="let element">
                        {{element.patient.room_no}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="admission_date">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="admission_date">A/D</th>
                      <td mat-cell *matCellDef="let element">
                        {{
                          element.patient.admission_date ? (element.patient.admission_date | date: 'M/d/yyyy') : '' 
                        }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="payer">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="payer">Payor</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.payor_name }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="pcp">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="pcp">PCP</th>
                      <td mat-cell *matCellDef="let element">
                        {{element.patient.pcp}}
                      
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="comments_count">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="comments_count">Comments</th>
                      <td mat-cell *matCellDef="let element" style="padding-right: 20px">
                        <a *ngIf="element.patient_roundingsheet_comments_count > 0" href="#"
                          (click)="$event.preventDefault(); showPatientCommentsDialog(element)">View
                          ({{element.patient_roundingsheet_comments_count}})</a>

                        <a href="#" style="max-width: 200px;
                    display: block;
                    word-break: break-all;" (click)="$event.preventDefault(); showCommentSidenav(element); displayCommentSidenav=!displayCommentSidenav;
                    ">

                          {{(element?.census_comment?.comment) ? (element?.census_comment?.comment.substring(0, 27)) :
                          '--' }}
                        </a>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="last_seen">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="last_seen">Last Seen</th>
                      <td mat-cell *matCellDef="let element">
                        {{getLastSeen(element)}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="edit_patient">
                      <th mat-header-cell *matHeaderCellDef>
                      </th>
                      <td mat-cell *matCellDef="let row; let i=index">
                        <span *ngIf="!row.pcc_patientId" class="material-icons" matTooltip="Edit" matTooltipPosition="above"
                        style="cursor: pointer;" (click)="openEditPatientDialog(row, i);">
                        edit_note
                        </span>
                      </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="operations">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="operations">Actions</th>
                      <ng-container *matCellDef="let element; let i=index">
                        <td mat-cell *ngIf="element.is_active == 'true'">
                          <div class="operation-buttons flex flex-inline justify-start items-center gap-1">
                          <ng-container *ngIf="!isActivePatientList">
                            <mat-icon matTooltip="Restore Patient" matTooltipPosition="above" style="cursor: pointer;" (click)="restoreInactivePatient(element._id)">
                              restore
                            </mat-icon>
                          </ng-container>
                          <ng-container *ngIf="isActivePatientList">
                            <a *ngIf="census && isOwner(census) ||census && this.currentUser.user_type===global.USER_TYPE.MEDICAL_ASSISTANT || census && this.currentUser.user_type===global.USER_TYPE.TRANSCRIBER "
                              (click)="noteEditor(element, false, i)" class="flex flex-inline justify-center items-center">
                              <i class="icon icon-add" matTooltip="Create Note" matTooltipPosition="above"></i>
                            </a>
                            <a *ngIf="currentUser.charge_status && this.currentUser.user_type === global.USER_TYPE.DOCTOR && isOwner(census)"
                              href="#" style="color: #7f7e7e !important"
                              (click)="$event.preventDefault(); " matTooltipPosition="above">
                              <mat-icon (click)="$event.preventDefault(); RedirectToChargeCaptureInNote(element);" matTooltip="Drafted Charge" *ngIf="hasChargeAndStatus(element, 'draft')"
                                style="color:orange;">attach_money</mat-icon>
                              <mat-icon matTooltip="Submitted Charge" *ngIf="hasChargeAndStatus(element, 'submit')"
                                style="color:green;">attach_money</mat-icon>
                              <mat-icon (click)="$event.preventDefault(); RedirectToChargeCaptureInNote(element);" matTooltip="No Charge"
                                *ngIf="!(hasChargeAndStatus(element, 'submit') || hasChargeAndStatus(element, 'draft'))"
                                style="color: #7f7e7e80;">
                                attach_money</mat-icon>
                            </a>

                            <i *ngIf="telemedicine === 'true'" class="icon icon-call" matTooltip="Video Call"
                              matTooltipPosition="above" (click)="noteEditor(element, true)"></i>
                            </ng-container>
                            <i *ngIf="census && isOwner(census) || currentUser.user_type===global.USER_TYPE.MEDICAL_ASSISTANT || currentUser.user_type===global.USER_TYPE.BUSINESS_OFFICER" class="icon icon-delete" matTooltip="Delete"
                              matTooltipPosition="above" (click)="handleRemovePatientClick(element._id)"></i>
                          </div>
                        </td>
                      </ng-container>
                    </ng-container>

                    <ng-container matColumnDef="checkbox">


                      <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                      </th>
                      <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)=" patientSlected(row, $event.checked)"
                          [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                      </td>
                    </ng-container>

                    <ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    </ng-container>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"
                      [class.darkBlueBg]="getRowClass(row) === 'PRIMARY_DARK'"
                      [class.darkGrayBg]="getRowClass(row) === 'DARK_GRAY'"></tr>
                  </table>

                </div>
                <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
              </div>
            </div>

          </div>
        </div>
      </div>
    </main>
  </mat-sidenav-container>

  <div class="flex flex-col" style="margin-right: -5px;" *ngIf="show_adt_view && census && isLoggedIntoPCC()"
    class="child_one_class ">
    <div class="close_button">
      <mat-icon style="    margin-right: 10px;margin-top: 10px;cursor: pointer;" (click)="show_adt_view=!show_adt_view">
        close
      </mat-icon>
    </div>
    <div class="census-sidebar">
      <app-pcc-event-notifications *ngIf="census" title="PCC Patients Admit/Readmit"
        [eventTypes]="[pccEventTypes.PATIENT_ADMIT, pccEventTypes.PATIENT_READMIT]"
        [facilityIds]="[census.facility?._id]" (onImportPatient)="handleImportPatient($event)">
      </app-pcc-event-notifications>

    </div>
  </div>
  <div *ngIf="patientsImportSource == 'pcc'" (click)="show_adt_view=!show_adt_view" [ngClass]="{'Mid_child1': show_adt_view, 
'Mid_child': !show_adt_view}">
    <mat-icon *ngIf="!show_adt_view" class="arrow_icon_class">
      menu
    </mat-icon>
  </div>
</div>