<div class="wound-care-widget" class="flex flex-col">
    <div class="flex flex-col gap-2" *ngIf="patient">
        <div class="buttons">
            <button class="dn-button-primary" style="margin-right: 7px;" mat-raised-button
                (click)="groupWoundImgs()">Download images</button>
            <button class="dn-button-primary" mat-raised-button (click)="refreshWounds()">Refresh Wounds</button>
            <button
            *ngIf="!isAdminSide && isNoteEditor && should_show_wound_assessment && (currentUser.user_type == global.USER_TYPE.DOCTOR || currentUser.user_type == global.USER_TYPE.MEDICAL_ASSISTANT)"
            class="dn-button-primary" style="margin-left: 7px;" mat-raised-button (click)="addInitAssessment()">Add Initial
            Assessment</button>
        </div>
        <mat-tab-group (selectedTabChange)="onTabChanged($event);">
            <mat-tab label="ACTIVE({{activeCount}})"> 
                <ng-template matTabContent>
                    <app-wound-care-widget-wound-list [created_userType]="created_userType" [configuration]="configuration" [companyPermissions]="companyPermissions" [note]="note" [readOnlyMode]="readOnlyMode" [patient]="patient" [provider]="provider" woundState="active"
                    [facility_id]="facility_id" [should_show_view_icon]="should_show_view_icon"
                    (onICDsSave)="handleICDsSave($event)"
                    (woundDetailsChange) = "handleWoundDetailsChange($event)"
                    (procedureNoteDetailsChange)="handleProcedureNoteDetailsChange($event)"
                    (checkallWoundAssessmentPhrase) = "handlecheckallWoundAssessmentPhrase($event)"
                    ></app-wound-care-widget-wound-list>
                </ng-template>
            </mat-tab>
            <mat-tab label="IN REMISSION({{remissionCount}})"> 
                <ng-template matTabContent>
                    <app-wound-care-widget-wound-list [created_userType]="created_userType" [configuration]="configuration" [companyPermissions]="companyPermissions" [note]="note" [readOnlyMode]="readOnlyMode" [patient]="patient" [provider]="provider" woundState="remission"
                    [facility_id]="facility_id" [should_show_view_icon]="should_show_view_icon"
                    (onICDsSave)="handleICDsSave($event)"
                    (woundDetailsChange) = "handleWoundDetailsChange($event)"
                    (procedureNoteDetailsChange)="handleProcedureNoteDetailsChange($event)"
                    ></app-wound-care-widget-wound-list>
                </ng-template>
            </mat-tab>
            <mat-tab label="AMPUTATED({{amputatedCount}})"> 
                <ng-template matTabContent>
                    <app-wound-care-widget-wound-list [created_userType]="created_userType" [configuration]="configuration" [companyPermissions]="companyPermissions" [note]="note" [readOnlyMode]="readOnlyMode" [patient]="patient" [provider]="provider" woundState="amputated"
                    [facility_id]="facility_id" [should_show_view_icon]="should_show_view_icon"
                    (onICDsSave)="handleICDsSave($event)"
                    (woundDetailsChange) = "handleWoundDetailsChange($event)"
                    (procedureNoteDetailsChange)="handleProcedureNoteDetailsChange($event)"
                    ></app-wound-care-widget-wound-list>
                </ng-template>
            </mat-tab>
            <mat-tab label="ARCHIVE({{archiveCount}})"> 
                <ng-template matTabContent>
                    <app-wound-care-widget-wound-list [facility_id]="facility_id" [should_show_view_icon]="should_show_view_icon" [created_userType]="created_userType" [configuration]="configuration" [companyPermissions]="companyPermissions" [note]="note" [readOnlyMode]="readOnlyMode" [patient]="patient" [provider]="provider" woundState="archived"></app-wound-care-widget-wound-list>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

