import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import * as global from '../../global';
import { Subscription, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { API_URL } from "../../../../environments/api-url";

@Injectable({
    providedIn: 'root'
})
export class ProviderPhraseAssociationService {
    currentUser: any;
    constructor(
        private httpclient: HttpClient,
        private _router: Router,
        private _authService: AuthService) {
        this.currentUser = this._authService.currentUser;
    }
    getAllSameCompanyProviders(filter, projection,populate?) {
        return this.httpclient.post(global.url + API_URL.USER.getAllSameCompanyProviders, { filter, projection }).pipe();

    }
    getPhraseAssociations(filter) {
        return this.httpclient
            .post(global.url + API_URL.PHRASE.getPhraseAssociations, {filter: filter}).pipe();
    };

    createRelationProvider2Phrase(relationship) {
        var item = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            company_id:<string>null,
            ...relationship
        }
        item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type , item.company_id=this.currentUser.company_id;
        return this.httpclient.post(global.url + API_URL.PHRASE.createRelationProvider2Phrase, {details: item}).pipe();
    }
    deleteRelationProvider2Phrase(relationship) {
        var item = {
            id: <string>null,
            auth_key: <string>null,
            usertype: <string>null,
            company_id:<string>null,

            ...relationship
        }
        item.id = this.currentUser._id, item.auth_key = this.currentUser.auth_key, item.usertype = this.currentUser.user_type,item.company_id=this.currentUser.company_id;
        return this.httpclient.post(global.url + API_URL.PHRASE.deleteRelationProvider2Phrase, {details: item}).pipe();
    }
}
