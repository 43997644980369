import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment'
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FacilityService } from 'src/app/services/facility.service';
import { Location } from '@angular/common';
import { WoundService } from 'src/app/services/wound.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { WoundCareWidgetService } from 'src/app/includes/note-sidebar/wound-care-widget/wound-care-widget.service';
import { MatDialog } from '@angular/material/dialog';
import { WounndGraphDialogComponent } from './wounnd-graph-dialog/wounnd-graph-dialog.component';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';
import { SnfWcFacilityAssociationService } from 'src/app/includes/snf-wc-nurse-facility-association/snf-wc-facility-association.service';
import * as global from '../../../../global';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-qapi-report',
  templateUrl: './qapi-report.component.html',
  styleUrls: ['./qapi-report.component.css']
})
export class QapiReportComponent implements OnInit {
  @Input() reportFilter : any;
  @Input() selectedFacility : any;
  @Output() countChanges = new EventEmitter<any>(); 
  currentDate = moment(new Date());
  loaderId = 'app-qapi-report';
  filter: any = {
    creationDate: {
      startDate: moment().subtract(6, 'days'),
      endDate: moment()
    },
    facilityId: null,
    state: "active",
    reportType: "improving",
    companyId: null,
    facilities: null
  };
  preDateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 1 Week': [moment().subtract(6, 'days'), moment()],
    // 'Last 2 Week': [moment().subtract(13, 'days'), moment()],
    // 'Last 3 Week': [moment().subtract(20, 'days'), moment()],
    // 'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    // 'This Month': [moment().startOf('month'), moment().endOf('month')],
    // 'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  iscreationDateFilterSelected: boolean = true;
  filteredFacilities: Observable<string[]>;
  displayedColumns: string[] = [
    'organization', 'faciltiy', 'assessment_date','first_name', 'last_name', 'woundNo', 'body_part', 'wound_status','etiolgy', 'stage', 'diffInWeek', 'area_change'];
  facilityControl = new FormControl();
  facilities: Array<any> = [];
  companyId: any;
  dataSource: any = new MatTableDataSource([]);
  qapiReport: any = [];
  isClinicalSummaryReportVisible: boolean = false;
  isOperationsSummaryReportVisible: boolean = false;
  isAmputationReportVisible: boolean = false;
  facilityTitle: any;
  isRehospitalizationReportVisible: boolean = false;
  placeContent: string = "space-between center";
  user_dashboard: Boolean = false;
 
  nursingSide: boolean;
  currentUser: any;
  companySide: boolean;

  pagination = {
    pageNo: 1,
    hasNextPage: false,
    hasPrevPage: false,
    totalRecords: 0,
    PAGE_LIMIT : 10,
    skip: 0
  };
  facilityInputText: string = '';
  constructor(
    private _toastr: ToastrService,
    private _facilityService: FacilityService,
    private _route: ActivatedRoute,
    private _woundCareWidgetService: WoundCareWidgetService,
    private _location: Location,
    private _wound: WoundService,
    private loader: NgxUiLoaderService,
    private dialog: MatDialog,
    private _authService: AuthService,
    private _snfWcNurseAssociationService: SnfWcFacilityAssociationService,
    private _commonService: CommonService
  ) {
      this.currentUser = this._authService.currentUser;

      if (window.location.pathname.includes("dashboard")) {
          this.user_dashboard = true;
          this.companyId = this._authService.currentUser.company_id;
      } else {
          this.companyId = this._route.parent.snapshot.params.id;

          if (this._route.parent.snapshot.params.id) {
              this.companySide = true;
          } else {
              this.companyId = this.currentUser.company_id;
          }
      }

      if (window.location.pathname.includes("nursing")) {
          this.nursingSide = true;
      }

      this.filter.companyId = this.companyId;
  }

  async ngOnInit() {
    this.loader.startLoader(this.loaderId);
    if(this.reportFilter){
      this.filter.reportType = this.reportFilter;
    }
    if(this.user_dashboard){
      // await this.getFacilitiess();
      if(this.currentUser.user_type === global.USER_TYPE.SNF_WC_NURSE){
        await this.getSNFNurseAssociatedFacilities();
      }else{
        await this.getFacilitiess();
      }
    }else{
      await this.initFacilities();
    }
    if(this.selectedFacility){
      if (this.facilities.length > 0) {
        this.filter.facilityId = this.selectedFacility._id;
      }
      this.facilityControl.setValue(this.selectedFacility.title);
    }else{
      this.selectDefaultFacility();
    }
    await this.getCount();
    await this.initQapiReport();
    this.filteredFacilities = this.facilityControl.valueChanges.pipe(
      startWith(''),
      map((value:any) => this.filterFacilities(value)));
    this.loader.stopLoader(this.loaderId);
  }
  private filterFacilities(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) !== -1);
    }
    else {
      return this.facilities;
    }
  }
  selectDefaultFacility() {
    if (this.facilities.length > 0) {
      this.filter.facilityId = this.facilities[0]._id;
    }
    this.facilityControl.setValue(this.facilities[0].title);
  }
  getCount() {
    let f_facilities;
    if(this.user_dashboard && this.filter.facilityId == null){
      f_facilities = this.facilities.map((facility)=> facility._id);
      f_facilities = f_facilities.filter((f) => f !== null);
    }else{
      f_facilities=null;
    }
    // if(this.filter.creationDate) {
    //   console.log('this.filter.creationDate',this.filter.creationDate);
    //   let dateObj = this.filter.creationDate;
      
    //   dateObj.startDate = moment(dateObj.startDate).toDate();
    //   dateObj.endDate = moment(dateObj.endDate).toDate()
    //   console.log('dateObj',dateObj);
    //   // this.filter.creationDate = dateObj;
    //   console.log('this.filter.creationDate',this.filter.creationDate);
      
    // }
      this._wound.getQapiReportCount(this.filter.creationDate,this.filter.facilityId,this.filter.reportType, null, f_facilities).subscribe((res:any)=>{
      if(res.status == 200){
        this.pagination.totalRecords = res.qapiReportCount;
        this.handlePagination();
        if(this.user_dashboard){
          this.countChanges.emit({count: res.qapiReportCount, type: this.filter.reportType});
        }
      }
    });
  }
  initQapiReport() {
    return new Promise<void>((resolve, reject) => {
      let pageNo = this.pagination.pageNo;
      if(this.user_dashboard && this.filter.facilityId == null){
        let f_facilities = this.facilities.map((facility)=> facility._id);
        this.filter.facilities = f_facilities.filter((f) => f !== null);
      }else{
        this.filter.facilities = null
      }
      // if(this.filter.creationDate) {
      //   let dateObj = JSON.parse(JSON.stringify(this.filter.creationDate));
      //   dateObj.startDate = moment(dateObj.startDate);
      //   dateObj.endDate = moment(dateObj.endDate);
      //   this.filter.creationDate = dateObj;
      // }
      this.loader.startLoader(this.loaderId);
      this.handleReportChange(this.filter.reportType);
      this._wound.getQapiReport(this.filter, pageNo).subscribe((response: any) => {
        if (response.status == 200) {
          this.qapiReport = response.data;
          this.handlePagination();
          // console.log("this.qapiReport", this.qapiReport);
          if (this.filter.reportType !== 'clinical-summary' &&
            this.filter.reportType !== 'operations-summary' &&
            this.filter.reportType !== 'rehospitalization') {
            this.dataSource.data = this.qapiReport;
          }
          resolve(null);
          if (this.user_dashboard) {
            if (this.filter.reportType === 'amputation') {
              this.isAmputationReportVisible = true;
            }
          }
          this.loader.stopLoader(this.loaderId);
        }
        else {
          resolve(null);
          this.loader.stopLoader(this.loaderId);

        }
      }, err => {
        reject('error => ' + err.toString());
        this.loader.stopLoader(this.loaderId);
        this._toastr.error(err, "ERROR");
      }
      );
    });
  }
  resetPagination() {
    this.pagination.hasNextPage = false;
    this.pagination.hasPrevPage = false;
    this.pagination.pageNo = 1;
    this.pagination.skip = 0;
    this.pagination.totalRecords = 0;
  }
  handlePagination() {
    this.pagination.skip = (this.pagination.pageNo - 1) * this.pagination.PAGE_LIMIT;
    if (this.pagination.skip + this.qapiReport.length < this.pagination.totalRecords) {
      this.pagination.hasNextPage = true;
    }
    else{
      this.pagination.hasNextPage = false;
    }
    if (this.pagination.skip > 0) {
      this.pagination.hasPrevPage = true;
    }
    else{
      this.pagination.hasPrevPage = false;
    }
    console.log('pagination',this.pagination);
  }

  goToPreviousPage() {
    this.pagination.pageNo--;
    this.initQapiReport();
  }
  goToNextPage() {
    this.pagination.pageNo++;
    this.initQapiReport();
  }
  async initFacilities() {
    const filter = {
      assoc_company_ids: this.companyId,
      is_active: true
    };
    const projection = {
      title: 1,
      source: 1,
      assoc_company_ids: 1
    };

    let companyAssocPopulation: facilityPopulationInterface = {
      collection: 'companies',
      field: ["assoc_company_ids"],
      filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: [true, { $in: ['$_id', '$$assoc_company_ids'] }] }] } },
      project: { name: 1 },
    };
    
    const facilitiesResponse: any = await this._facilityService.getFacilities(filter, projection, [companyAssocPopulation]).toPromise();
    if (facilitiesResponse.status == 200) {
      this.facilities = facilitiesResponse.data.array;
    }
  }
  async getFacilitiess() {
    let response: any = await this._facilityService.getMyFacilities().toPromise()
    // this.loader.stopLoader('loader');
    if (response.status == 200) {
      this.facilities = response.data.array;
      this.facilities.unshift({
          source:"All",
          title:"All",
          _id:null
      })
    }
    // });
  }
  onSelectFacility($event, facility) {
    if ($event.isUserInput) {
      this.filter.facilityId = facility._id;
      this.facilityTitle = facility.title;
    }
  }
  changeCreationDate(event) {
    if (event.startDate && event.endDate) {
      event = JSON.parse(JSON.stringify(event));
      const noOfDays = this._commonService.getDifferenceOfDates(event.startDate,event.endDate,'days');
      if (noOfDays > 6) {
        this.filter.creationDate = {};
        this.filter.creationDate.startDate = moment(event.startDate);
        this.filter.creationDate.endDate = moment(this.filter.creationDate.startDate).add(6, "days").endOf('day');
        this._toastr.error('Can not select more than 7 days', 'Date Error');
      } else {
        this.filter.creationDate.startDate = moment(event.startDate);
        this.filter.creationDate.endDate = moment(event.endDate);
        console.log('this.filter.creationDate',this.filter.creationDate);

      }
    }
  }
  
  goBack() {
    this._location.back();
  }
  async handleApplyFilter() {

    this.facilityInputText = (<HTMLInputElement>document.getElementById('facilityControlInput')).value;

    if(this.facilityInputText==''||this.facilityInputText.toLowerCase()=='all'){
     
    }else{
     
      const exists = this.facilities.filter(option => option.title.toLowerCase().trim()==this.facilityInputText.toLowerCase().trim());
      if(!exists.length){
        this._toastr.warning('Please select facility from list', 'Warning');
        return;
      }
      
    }

    this.resetPagination();
    [this.isClinicalSummaryReportVisible,
    this.isOperationsSummaryReportVisible,
    this.isAmputationReportVisible,
    this.isRehospitalizationReportVisible] = [false, false, false, false];
    await this.getCount();
    await this.initQapiReport();
    if (this.filter.reportType === 'clinical-summary') {
      this.isClinicalSummaryReportVisible = true;
    }
    else if (this.filter.reportType === 'operations-summary') {
      this.isOperationsSummaryReportVisible = true;
    }
    else if (this.filter.reportType === 'amputation') {
      this.isAmputationReportVisible = true;
    }
    else if (this.filter.reportType === 'rehospitalization') {
      this.isRehospitalizationReportVisible = true;
    }
  }

  async showGraphofWound(element){
    // this.loader.startLoader(this.loaderId)
    // if(this.user_dashboard){
      this._toastr.info('Calculating Wound Graph', 'Please wait..');
      const { status, data } = await lastValueFrom(this._woundCareWidgetService.getWoundGraphData(element._id)) as any;
      if (status == 200) {
        const wounds = data.array.map(item => ({
          length: parseFloat(item.length),
          width: parseFloat(item.width),
          depth: parseFloat(item.depth),
          createdAt: new Date(item.createdAt),
          updatedAt: new Date(item.updatedAt),
          area: item?.area,
          volume: item?.volume,
          source: item.source,
          entered_dot_phrases: item?.entered_dot_phrases
        }));
        if(wounds?.length){
          this.dialog.open(WounndGraphDialogComponent, {
            width: '50%',
            data: { wounds:wounds},
          });
        }else{
          this._toastr.warning('No Data Found', 'Sorry');
        }
      // }
    }
    // this.loader.stopLoader(this.loaderId)
  }
  calculateArea(ele) {
    if (ele.earliestRevisionArea && ele.recentWound) {
      if(ele.recentWound.recentRevisionArea <= 0 || ele.earliestRevisionArea <= 0){
        return 0;
      }
      return ((ele.recentWound.recentRevisionArea - ele.earliestRevisionArea)/ele.earliestRevisionArea) * 100
    }
    return ele.earliestRevisionArea;
  }
  getDate(element){
    // if(element.recentNote){
    //   if(element.recentNote.date_obj){
    //     return moment(element.recentNote.date_obj.month + "/" + element.recentNote.date_obj.day + "/" + element.recentNote.date_obj.year).format("MMM/DD/YYYY");
    //   }else{
    //     return '';
    //   }
    // }else{
    //   return '';
    // }
    if (element?.recentNote?.date_obj && element?.recentWound?.last_assessment_date_obj) {
      let { year, month, day } = element.recentNote.date_obj;
      let assessmentYear = element?.recentWound?.last_assessment_date_obj.year;
      let assessmentMonth = element?.recentWound?.last_assessment_date_obj.month;
      let assessmentDay = element?.recentWound?.last_assessment_date_obj.day;
      if (month < 10) month = `0${month}`;
      if (day < 10) day = `0${day}`;
      if (assessmentMonth < 10) assessmentMonth = `0${assessmentMonth}`;
      if (assessmentDay < 10) assessmentDay = `0${assessmentDay}`;
      const dateObject1 = new Date(year, month - 1, day);
      const dateObject2 = new Date(assessmentYear, assessmentMonth - 1, assessmentDay);     
      if (dateObject1 > dateObject2) {
        return `${month}-${day}-${year}`;
      } else {
        return `${assessmentMonth}-${assessmentDay}-${assessmentYear}`;
      }
    }else if(element.last_assessment_date_obj || element?.recentNote?.date_obj){
      let {
        year,
        month,
        day
      } = element.last_assessment_date_obj ? element.last_assessment_date_obj : element?.recentNote?.date_obj;
      if (month < 10) month = `0${month}`;
      if (day < 10) day = `0${day}`;
      return `${month}-${day}-${year}`;
    }else{
      return '';
    }
  }

  async resetFilter() {
    delete this.filter.creationDate;
    const defaultDate = {
      startDate: moment().subtract(6, 'days'),
      endDate: moment()
    };
    this.filter.creationDate = defaultDate;
    [this.isClinicalSummaryReportVisible,
    this.isOperationsSummaryReportVisible] = [false, false];
    this.filter.reportType = "improving";
    this.filter.state = "active";
    this.selectDefaultFacility();
    await this.handleApplyFilter();
  }

  handleReportChange(value) {
    this.filter.reportType = value;
    [this.isClinicalSummaryReportVisible,
    this.isOperationsSummaryReportVisible,
    this.isAmputationReportVisible] = [false, false, false];
    this.dataSource.data = [];
    this.qapiReport = [];
    if (value === "improving" || value === "stalled" || value === "deteriorating") {
      this.filter.state = "active";
    }
    else if (value === "remission") {
      this.filter.state = "remission";
    }
    else if (value === "amputation") {
      this.filter.state = "amputated";
    }
    else if (value === "rehospitalization") {
      delete this.filter.state;
      delete this.filter.facilityId;
      this.placeContent="end center";
    }
    else if (value === "clinical-summary"
      || value === "operations-summary") {
      delete this.filter.state;
      // delete this.filter.facilityId;
    }
  }
  async getSNFNurseAssociatedFacilities(){
    const filter = {
      associated_snf_wc_nurse_ids: this.currentUser._id,
      company_id: this._authService.currentUser.company_id
  };
  const nurseProjection = {
      first_name: 1,
      last_name: 1,
      title: 1,
  };
  const facilityProjection = {
      title: 1,
      pcc_facId: 1,
      pcc_2_legged_authentication: 1,
      source: 1,
      pcc_orgUuid: 1
  };
  const facilitiesResponse:any = await lastValueFrom(this._snfWcNurseAssociationService.getFacilityAssociatedSnfWcNurses(filter, nurseProjection, facilityProjection));
  if (facilitiesResponse.status === 200) {
      this.facilities = facilitiesResponse.data;
  }
  }
  exportQapiReportAsXLSX(docType) {
    let infoToastr;
    if(docType === 'xlsx'){
      infoToastr = this._toastr.info('Exporting as XLSX...', 'Please wait', { disableTimeOut: true });
    }else{
      infoToastr = this._toastr.info('Exporting as PDF...', 'Please wait', { disableTimeOut: true });
    }
    this._wound.exportQapiReportAsXLSX(this.filter, docType).subscribe((response: any) => {
      if (response instanceof HttpResponse) {
        var url = window.URL.createObjectURL(response.body);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `Qapi Report`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
        this._toastr.clear(infoToastr.toastId);
      }
    }, error => {
      console.log("err", error);
      this._toastr.clear(infoToastr.toastId);
    });
  }
  getWoundStatus(element){
    if(element.recentWound){
      return element.recentWound.wound_status;
    }else if(element.wound_status){
      return element.wound_status
    }else{
      return "";
    }
  }
}
