import { Component, Input, OnInit } from '@angular/core';

import { AuthService } from './../../../services/auth.service';
import { PhrasesService } from './../../phrases/phrases.service';

@Component({
  selector: 'app-template-create-sidebar',
  templateUrl: './template-create-sidebar.component.html',
  styleUrls: [ './template-create-sidebar.component.css' ]
})
export class TemplateCreateSidebarComponent implements OnInit {
  @Input() selectedSource: any = null;
  @Input() forNoteType: any = null;
  @Input() useDragonStyle: any = false;
  @Input() updateTabGroup: any = null;

  constructor( private phrasesService: PhrasesService, private authService: AuthService ) {}

  ngOnInit(): void {
    this.initPhrases();
  }

  initPhrases() {
    this.phrasesService.getNoteBuilderDictionary( this.authService.currentUser._id ).subscribe();
  }
}
