<div class="phrases-wrapper" class="flex flex-col gap-2">
    <div *ngFor="let dictionaryElement of dictionary; let i = index" class="flex items-start justify-baseline gap-2">
        <div class="flex items-start justify-center gap-2 flex-grow">
            <div style="color:rgb(104, 104, 104); font-size: 12px;">{{i+1}}</div>

            <mat-form-field class="flex-grow">
                <mat-label>Shortcut</mat-label>
                <div class="flex">
                    <input matInput type="text" [value]="dictionaryElement ? getMarker() + dictionaryElement.key : ''"
                        readonly>
                    <i class="icon icon-view" matTooltip="View Template" matTooltipPosition="above"
                        (click)="openPhraseViewer(dictionaryElement)"></i>
                </div>
            </mat-form-field>
        </div>
    </div>
</div>