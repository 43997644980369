<form #form="ngForm" (ngSubmit)="handleSubmit(form)">

    <div class="flex flex-row-reverse gap-5">
        <button mat-raised-button class="dn-button-primary">Save</button>
    </div>
    <br />
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="day">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container> Day </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox name="day" [checked]="isAvailable(element.day)" (change)="model[element.day].availability = $event.checked">
                     {{element.day}} 
                </mat-checkbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="start_time">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container> Start Time </th>
            <td mat-cell *matCellDef="let element">
             <div class="time_picker" >
             </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="end_time">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container> End Time </th>
            <td mat-cell *matCellDef="let element">
                <div class="time_picker" >
                 </div>
            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <ngx-ui-loader [loaderId]="doctorTimeSheduleLoader"></ngx-ui-loader>
</form>
