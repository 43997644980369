<app-header></app-header>

<div class="container">

    <div class="search-block">
        <form #appointmentForm="ngForm" class="flex justify-between items-baseline">
            <div class="flex flex-1 justify-start items-baseline gap-4">
                <div class="flex justify-start items-baseline">
                    <mat-form-field>
                        <mat-label>Select Provider</mat-label>
                        <mat-select name="provider" [(ngModel)]="filter.provider_id"
                          (ngModelChange)="handleProviderChange()">
                          <input matInput placeholder="Search" [(ngModel)]="searchTextProvider"
                            [ngModelOptions]="{standalone: true}" style="color:#000;height: 40px;padding-left: 5px;" />
                          <mat-option *ngFor="let provider of providers | searchProvider : searchTextProvider"
                            value="{{provider._id}}">
                            {{provider.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>
                <div class="flex flex-col justify-start items-baseline" style="width: 25%;">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Select Facility</mat-label>
                        <div class="flex items-center">
                            <mat-select name="facility" #facility="ngModel" [(ngModel)]="filter.facility_id"
                          (ngModelChange)="handleFilterFacilityChange()">
                            <input matInput placeholder="Search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
                              style="color:#000;height: 40px;padding-left: 5px; ; position: sticky; top: 0; z-index:1000; background-color: lightgray; " (keydown)="$event.stopPropagation()" />
                            <mat-option [value]="''">All</mat-option>
                            <mat-option *ngFor="let facility of facilities | searchFacility: searchText" value="{{facility._id}}">
                              <app-facility-title [facility]="facility"></app-facility-title>
                            </mat-option>
                          </mat-select>
                        </div>
                      </mat-form-field>
                </div>
                <div class="flex items-start justify-start input-with-cross">
                    <mat-form-field appearance="fill" style="align-self: flex-end;">
                        <mat-label>Date of Service</mat-label>
                        <input matInput [matDatepicker]="dospicker" (focus)="dospicker.open()"
                            placeholder="Date of Service"  [(ngModel)]="date_of_service"  name="date_of_service"
                            (dateChange)="changeServiceDate($event)">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="dospicker">
                            <span matDatepickerToggleIcon class="material-symbols-outlined icon-navy">
                                calendar_month
                            </span>
                        </mat-datepicker-toggle>
                        <mat-datepicker #dospicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div style="align-items: baseline; gap: 1rem;">
                <div class="w-full" style="position: relative;">
                    <mat-form-field style="width: 400px;">
                      <mat-label>Search</mat-label>
                      <input matInput type="text" placeholder="Search by Name or Date of Birth" name="patientsByProvider" [formControl]="patientsByProviderControl"
                        [matAutocomplete]="patientAutoComplete" />
                        <mat-autocomplete #patientAutoComplete="matAutocomplete" (optionSelected)="
                          setFormValue($event.option.value)" [displayWith]="displayPatientFn">
                          <mat-option *ngFor="let patient of filteredPatientsByProvider | async" [value]="patient">
                            <app-patient-name [isDateOfBirthVisible]="true" [patient]="patient"></app-patient-name>
                          </mat-option>
                      </mat-autocomplete>
                      <mat-hint *ngIf="patientNotFound" style="color: #F44336;">
                        No patient found with the matched result.
                      </mat-hint>
                    </mat-form-field>
                    <ngx-ui-loader [loaderId]="patientsSearchLoaderId"></ngx-ui-loader>
                  </div>
            </div>
            <button mat-icon-button [disabled]="!dataSource.data?.length" matTooltip="Download as PDF"
                    (click)="convertToPdf('download')"  style="cursor: pointer;" aria-label="Example icon button with a open in new tab icon">
                <mat-icon>picture_as_pdf</mat-icon>
            </button>
            <button mat-icon-button  [disabled]="!dataSource.data?.length" matTooltip="Print Orders" (click)="convertToPdf('print')" style="cursor: pointer;" aria-label="Example icon button with a open in new tab icon">
                <mat-icon>local_printshop</mat-icon>
            </button>
        </form>
    </div>
    <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
    <mat-card>
        <table mat-table  [dataSource]="dataSource" matSort (matSortChange)="onSortChange($event)" 
        matSortDisableClear class="table-row-hover mat-table">
            <ng-container  matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header="start" mat-sort-header-start="start" style="width: 150px;"> <span class="material-symbols-outlined sort-icon"> sort </span>Time</th>
                <td mat-cell *matCellDef="let element; let i = index;">{{ element.start }}</td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="name" > <span class="material-symbols-outlined sort-icon"> sort </span>Name</th>
                <td mat-cell *matCellDef="let element; let i = index;">{{ element.name }}</td>
            </ng-container>

            <ng-container matColumnDef="dob">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="dob"> <span class="material-symbols-outlined sort-icon"> sort </span>DOB</th>
                <td mat-cell *matCellDef="let element; let i = index;">{{ element.dob }}</td>
            </ng-container>

            <ng-container matColumnDef="gender">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="gender"> <span class="material-symbols-outlined sort-icon"> sort </span>Gender</th>
                <td mat-cell *matCellDef="let element; let i = index;">{{ element.gender }}</td>
            </ng-container>

            <ng-container matColumnDef="appointment">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="appointment_type"> <span class="material-symbols-outlined sort-icon"> sort </span>Appointment</th>
                <td mat-cell *matCellDef="let element; let i = index;">{{ element.appointment_type }}</td>
            </ng-container>

            <ng-container matColumnDef="provider">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="providerName"> <span class="material-symbols-outlined sort-icon"> sort </span>Provider</th>
                <td mat-cell *matCellDef="let element; let i = index;">{{ element.providerName }}</td>
            </ng-container>

            <ng-container matColumnDef="charge">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <span class="material-symbols-outlined sort-icon"> sort </span>Charge</th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    Done
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="appointment_status"> <span class="material-symbols-outlined sort-icon"> sort </span>Status</th>
                <td mat-cell *matCellDef="let element; let i = index;" (click)="$event.stopPropagation()">
                    <span *ngIf="providerCanChangeAppointmentStatus else badgeWithoutMenu" class="badge" [class.disabled]="element.id | templateExpression: disableStatusDropdown: this" [style.background-color]="element.appointment_visit_status?.color" [matMenuTriggerFor]="statusDropdown" [matMenuTriggerData]="{ element: element }">{{element.appointment_status}}
                        <ngx-ui-loader [pbThickness]="0" [fgsSize]="10" [overlayColor]="'#00000070'" [loaderId]="element.id" [bgsOpacity]="0.5"></ngx-ui-loader>
                    </span>
                    <ng-template #badgeWithoutMenu><span class="badge" [style.background-color]="element.appointment_visit_status?.color">{{element.appointment_status}}</span></ng-template>
                </td>
                <mat-menu #statusDropdown="matMenu" xPosition="before" class="dropdown">
                    <ng-template *ngIf="visitStatuses" matMenuContent let-element="element">
                        <li *ngFor="let status of visitStatuses" mat-menu-item disableRipple (click)="updateVisitStatus(element, status)">
                            <span class="material-symbols-outlined filled item-icon start" [style.color]="status.color">circle</span>
                            <label>{{ status?.name }}</label>
                            <span *ngIf="element.appointment_visit_status?._id === status?._id" class="material-symbols-outlined item-icon end big">check_circle</span>
                        </li>
                    </ng-template>
                </mat-menu>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row class="mat-row" [ngStyle]="{ 'background-color': row.appointmentTypeColor || 'transparent' }"
    *matRowDef="let row; columns: displayedColumns;" (click)="onRowClick(row)"></tr>

        </table>
        <div class="no-appointments flex flex-col justify-center items-center my-4" 
        *ngIf="dataSource.data.length === 0">
            No appointments found
        </div>
    </mat-card>
</div>