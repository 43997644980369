import { Component, OnInit, VERSION } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Observable, lastValueFrom } from 'rxjs';
import { USER_TYPE } from '../../../../../includes/global'
import { CompanyService } from 'src/app/services/Modules/company.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { startWith, map } from 'rxjs/operators';
import { UserService } from 'src/app/services/Modules/user.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FacilityService } from 'src/app/services/facility.service';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import moment from 'moment';
import * as global from '../../../../../../app/includes/global';
import { facilityPopulationInterface } from 'src/app/facilityPopulationInterface';
import { PhrasesService } from 'src/app/includes/phrases/phrases.service';
import { DrivePhraseService } from 'src/app/includes/drive-phrases/drive-phrase.service';

export type DictionaryElement = {
    _id?: string,
    key: string,
    value: string,
    select?: boolean
}

const user_types = {
    'provider': USER_TYPE.DOCTOR,
    'company': USER_TYPE.DOCTOR,
    'transcriber': USER_TYPE.TRANSCRIBER,
    'manager': USER_TYPE.MANAGER,
    'pa': USER_TYPE.PA,
    'nurse': USER_TYPE.NURSE,
    'biller': USER_TYPE.BILLER,
    'medical_assistant': USER_TYPE.MEDICAL_ASSISTANT,
    'business_officer': USER_TYPE.BUSINESS_OFFICER,
    'snf_wc_nurse': USER_TYPE.SNF_WC_NURSE,
    'wound_nurse': USER_TYPE.WOUND_NURSE,
    'view_only_user': USER_TYPE.VIEW_ONLY_USER,
}


@Component({
    selector: 'app-company-user-management-form',
    templateUrl: './company-user-management-form.component.html',
    styleUrls: ['./company-user-management-form.component.css']
})
export class CompanyUserManagementFormComponent implements OnInit {
    global = global;
    USER_TYPE = USER_TYPE;
    loaderId = 'company-user-management-form-loader';
    model :any = {
        user_type: '',
        title: '',
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        speciality: '',
        // password: '',
        company_id: null,
        cc_email: 'management@doctornow.io',
        country: '',
        trueRCMID: null,
        companyCode: '',
        npi: '',
        tin: ''
    }
    user_id: string;
    phoneCleaned = '';
    dataSource: MatTableDataSource<any>;
    dataSource2: MatTableDataSource<any>;
    facilityControl = new FormControl();
    filteredFacilities: Observable<string[]>;
    typesOfShoes: string[] = ['Boots', 'Clogs', 'Loafers', 'Moccasins', 'Sneakers'];

    titles = ["M.D.", "D.O.", "PA-C", "ARNP", "R.N.", "N.P.", "P.A.", "L.Ac", "DNP", "CWS", "CWSP", "WOCN", "ANP-BC", "CWCN"]
    facilities: any = [];
    displayedColumns = ['facility', 'checkbox'];
    displayedColumnsForPhrases = [ 'checkbox', 'phrase'];
    selectedFacilities: any = [];
    facilityError: boolean = true;
    product_owner: boolean;
    selectedCountry: any;
    isIRCCompany: boolean;
    companyCode: any;
    company: any;
    company_type: any;

    selectAllFacility = false;
    displayProviderFn(title): string {
        return title;
    }
    companies: any = [];
    selectedCompany: any;
    Country = ['US / CA(+1)', 'Philippines(+63)', 'Pak(+92)', 'Ind(+91)'];
    maxMobileLength = 12;
    currentCompany: any = {};
    providers: any = [];
    selectedProvider: any = {};
    phrases: any = [];
    dataSourcePhrases: any;
    dictionary: Array<DictionaryElement>;
    currentUser: any;
    currentUserdictionary: any = [];
    selectedTab = 0;
    phraseArray: any = [];
    masterSelected: boolean = false;
    isMandatoryNPI: boolean = false;

    constructor(private _location: Location,
        private _current_user: AuthService,
        private route: ActivatedRoute,
        private companyService: CompanyService,
        private userService: UserService,
        private loader: NgxUiLoaderService,
        private facilityService: FacilityService,
        private toastr: ToastrService,
        private commonService: CommonService,
        private drivePhraseService: DrivePhraseService,
        private phrasesService: PhrasesService,

    ) {
        this.currentUser = this._current_user.currentUser;
        this.model.company_id = this.route.parent.parent.snapshot.params.id;
        this.user_id = this.route.snapshot.params.id;
        // if (this.model.company_id === '60c0bb216f04165c099f7c33' || this.model.company_id === '5f69d0651b1eba8bee2d3ac4') {
        //     this.isIRCCompany = true;
        // }
        if (this.commonService.getLocalStorageItem('admin-Dashboard'))
        this.currentCompany.company_type = this.commonService.getLocalStorageItem('admin-Dashboard').currentCompanyType;
    }

    async ngOnInit() {
        await this.getCompanyName();
        if (this.company.company_type !== 'Wound') {
            this.isIRCCompany = true;
            console.log('this.isIRCCompany = true',this.isIRCCompany);
        }
        this.product_owner = this._current_user.currentUser.product_owner
        this.dataSource2 = new MatTableDataSource(this.selectedFacilities);
        this.dataSource = new MatTableDataSource(this.facilities);
        this.getAllCompanies()
        await this.initFacilities();
        if (this.user_id) {
            this.loader.startLoader(this.loaderId);

            const filter = {
                _id: this.user_id
            }
            const projection = {
                user_type: 1,
                title: 1,
                first_name: 1,
                last_name: 1,
                email: 1,
                mobile: 1,
                company_id: 1
            }

            this.userService.getUser(filter, projection).subscribe((response: any) => {
                if (response.status === 200) {
                    this.model = response.data;

                    const { user_type } = this.model;
                    for (const key in user_types) {
                        if (user_types[key] == user_type) {
                            this.model.user_type = key;
                            break;
                        }
                    }

                }
                this.loader.stopLoader(this.loaderId);
            })

        }

        // this.filteredFacilities = this.facilityControl.valueChanges.pipe(
        //     startWith(''),
        //     map((value:any) => this.filterFacility(value)));
        await this.getProviders();
        await this.getNoteBuilderDictionaryAdmin(this.selectedProvider._id);
        this.model.tin = this.company.tin;

    }

    async getProviders() {
        // const company_id = this.params.id;
        const filter = {
          is_activated: 'true',
          user_type: this.company_type === global.COMPANY_TYPE.SNF ? USER_TYPE.SNF_WC_NURSE : USER_TYPE.DOCTOR,
          company_id: this.company._id
        }
        const projection = {
          'account_type': 1,
          'first_name': 1,
          'last_name': 1,
          'title': 1,
        }
    
        const companyUsersResponse: any = await this.userService.getUsers(filter, projection).toPromise();
        if (companyUsersResponse.status === 200) {
          let providerss = companyUsersResponse.data.map(p => Object.assign(p, {
            full_name: p.account_type === 'company' ?
              `${p.first_name} ${p.last_name}, ${p.title} (company owner)` :
              `${p.first_name} ${p.last_name}, ${p.title}`
          }));
          this.providers = providerss.sort((a, b) => a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase()));
          this.selectedProvider = this.providers[0];
        }
    }

    sortPhrases(arr: any[]) {
        arr.sort((a, b) => a.key.toLowerCase().localeCompare(b.key.toLowerCase()));
    }

    async onProviderClick($event, provider) {
        this.masterSelected = false;
        if ($event.isUserInput) {
          this.selectedProvider = provider;
          await this.getNoteBuilderDictionaryAdmin(provider._id);
        }
    }

    async getNoteBuilderDictionaryAdmin(id) {
        const response : any = await lastValueFrom(this.drivePhraseService.getAllPhrasesByProviderId(id));
          if (response.status == 200) {
            this.dictionary = response.data?.phrases.dictionary;
            if (this.currentUser._id === id) {
              this.currentUserdictionary = response.data?.phrases.dictionary;
            }
          } else if (!response.data.dictionary) {
            this.dictionary = [];
          }
          if (this.dictionary?.length > 0) {
            this.sortPhrases(this.dictionary);
          }
          if(this.phraseArray.length > 0) {
            for(let phrase of this.phraseArray) {
                for(let dictionaryPhrase of this.dictionary) {
                    if(phrase.key === dictionaryPhrase.key) {
                        dictionaryPhrase['select'] = true;
                    }
                }
            }
        }
          this.dataSourcePhrases = new MatTableDataSource(this.dictionary);
    }

    async getCompanyName() {
        const companyName = await this.companyService.getCompanyName(this.model.company_id).toPromise()
        this.company = companyName.data;
        this.company_type = this.company.company_type; 
    }
    // private filterFacility(value: string): string[] {
    //     if (value) {
    //         const filterValue = value.toLowerCase();
    //         return this.facilities.filter(option => option.title.toLowerCase().indexOf(filterValue) === 0);
    //     }
    //     else {
    //         return this.facilities;
    //     }
    // }


    initFacilities = async () => {
        const assoc_company_ids = this.route.parent.parent.snapshot.params.id;

        const filter = {
            is_active: true,
            assoc_company_ids
        }
        const projection = {
            'title': 1,
            'address': 1,
            'city': 1,
            'state': 1,
            'zip_code': 1,
            'source': 1,
            'provider_ids_ref': 1,
            'assoc_company_ids': 1,
        }

        let companyAssocPopulation: facilityPopulationInterface = {
            collection: 'companies',
            field: ["assoc_company_ids"],
            filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: [true, { $in: ['$_id', '$$assoc_company_ids'] }] }] } },
            project: { name: 1 },
        };

        const facilitiesResponse: any = await this.facilityService.getFacilities(filter, projection, [companyAssocPopulation]).toPromise();

        if (facilitiesResponse.status === 200) {
            this.facilities = new MatTableDataSource(facilitiesResponse.data.array);
            this.facilities.data.forEach(element => {
                element.selected = false;
            });
            // this.facilityControl.setValue(this.facilities[0].title);     

        }
    }

    updateFacilities = async (value) => {
        const company_id = value;
        const filter = {
            is_active: true,
            company_id
        }
        const projection = {
            'title': 1,
            'address': 1,
            'city': 1,
            'state': 1,
            'zip_code': 1,
            'source': 1,
            'provider_ids_ref': 1,
            'assoc_company_ids': 1,
        }
        let companyAssocPopulation: facilityPopulationInterface = {
            collection: 'companies',
            field: ["assoc_company_ids"],
            filter: { $expr: { $and: [{ $eq: [true, '$is_active'] }, { $eq: [true, { $in: ['$_id', '$$assoc_company_ids'] }] }] } },
            project: { name: 1 },
        };

        const facilitiesResponse: any = await this.facilityService.getFacilities(filter, projection, [companyAssocPopulation]).toPromise();
        if (facilitiesResponse.status === 200) {
            this.facilities = new MatTableDataSource(facilitiesResponse.data.array);
            this.facilities.data.forEach(element => {
                element.selected = false;
            });
        }
        this.selectedFacilities = [];
        this.dataSource2 = new MatTableDataSource(this.selectedFacilities);
        this.selectAllFacility = false;
    }

    handlePhoneModelChange(value) {
        if (this.selectedCountry === 'Philippines(+63)') {
            this.model.mobile = value;
            this.phoneCleaned = value;
        }
        else if (value) {
            const cleaned = value.replace(/-/g, '')
            if (this.selectedCountry === 'Ind(+91)') {
                this.model.mobile = cleaned.match(/.{1,4}/g).join('-');
                if (this.model.mobile.length > 9) {
                    let tempNum = this.model.mobile.split('-');
                    let num = [tempNum[0], tempNum[1] + tempNum[2]];
                    this.model.mobile = num.join('-');
                }

            }
            else {
                this.model.mobile = cleaned.match(/.{1,3}/g).join('-');
            }
            if (this.model.mobile.length > 12) {
                let position = this.model.mobile.lastIndexOf('-');
                this.model.mobile = this.model.mobile.substring(0, position) + this.model.mobile.substring(position + 1);
            }
            // const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            // if (match) {
            //     this.model.mobile = '(' + match[1] + ') ' + match[2] + '-' + match[3];
            // }
            // else{
            //     this.model.mobile = null;
            // }
            // }
            this.phoneCleaned = cleaned.trim();
            //console.log('phoneCleaned', this.phoneCleaned)
            //console.log('Mobile model', this.model.mobile)
        }
    }
    onPaste(event: ClipboardEvent) {
        let clipboardData = event.clipboardData;
        let pastedData = clipboardData.getData('Text').toString();
        let number: any = pastedData.match(/\d/g);
        number = number.join("");
        if (number.length > 10) {
            number = number.substring(0, 10);
        }
        this.handlePhoneModelChange(number);
        // if (!isNaN(parseInt(clipboardData.getData('text'))) && clipboardData.getData('text').length) {
        //     let pastedText = clipboardData.getData('text');
        //     const cleaned = pastedText.replace(/-/g, '')
        //     console.log('cleaned', cleaned);
        //     if (this.selectedCountry === 'Pak(+92)') {
        //         if (this.model.mobile.includes('+92')) {
        //             this.model.mobile += "-" + cleaned.match(/.{1,3}/g).join('-');
        //             this.phoneCleaned = cleaned.trim();
        //             this.phoneCleaned = "+92" + this.phoneCleaned;
        //         } else {
        //             this.model.mobile = cleaned.match(/.{1,3}/g).join('-');
        //             this.phoneCleaned = cleaned.trim();
        //         }
        //     } else {
        //         if (this.model.mobile.includes('+1')) {
        //             this.model.mobile += "-" + cleaned.match(/.{1,4}/g).join('-');
        //             this.phoneCleaned = cleaned.trim();
        //             this.phoneCleaned = "+1" + this.phoneCleaned;
        //         } else {
        //             this.model.mobile += " " + cleaned.match(/.{1,3}/g).join('-');
        //             this.phoneCleaned = cleaned.trim();
        //         }
        //     }
        // }
    }

    selectAllFacilities($event) {
        if ($event.checked) {
            this.facilities.data.forEach(element => {
                this.selectedFacilities.push(element);
                element.selected = true;
            });
            this.dataSource2 = new MatTableDataSource(this.selectedFacilities);
        } else {
            this.facilities.data.forEach(element => {
                element.selected = false;
            });
            this.selectedFacilities = [];
            this.dataSource2 = new MatTableDataSource(this.selectedFacilities);
        }
    }

    addFacilities(fac) {
        let facility = fac.data;
        this.selectAllFacility = false;
        console.log('fac',fac);
        // JSON.parse(JSON.stringify(facility.data));
        // console.log(fac);
        let index = this.facilities.data.indexOf(fac);
        if (fac.selected === false) {
            fac.selected = true;
            this.facilities.data.splice(index, 1, fac);
            this.selectedFacilities.push(fac);
            // to detect change for mat table
            this.dataSource2 = new MatTableDataSource(this.selectedFacilities);

        }
        else if (fac.selected === true) {
            this.selectedFacilities.splice(this.selectedFacilities.indexOf(fac), 1);
            // to detect change for mat table
            this.dataSource2 = new MatTableDataSource(this.selectedFacilities);

            fac.selected = false;
            this.facilities.data.splice(index, 1, fac);
        }
        if (this.model.user_type === 'provider' && this.selectedFacilities.length > 0) {
            this.facilityError = false;
        }
        if (this.model.user_type === 'provider' && this.selectedFacilities.length === 0) {
            this.facilityError = true;
        }
        if (this.facilities.data.length == this.selectedFacilities.length) {
            this.selectAllFacility = true;
        }

    }

    removeFromSelectedFacilities(facility) {
        this.selectedFacilities.splice(this.selectedFacilities.indexOf(facility), 1);
        // to detect change for mat table
        this.dataSource2 = new MatTableDataSource(this.selectedFacilities);

        facility.selected = false;
        this.facilities.data.splice(this.facilities.data.indexOf(facility), 1, facility);
        if (this.model.user_type === 'provider' && this.selectedFacilities.length > 0) {
            this.facilityError = false;
        }
        if (this.model.user_type === 'provider' && this.selectedFacilities.length === 0) {
            this.facilityError = true;
        }
        this.selectAllFacility = false;
    }


    goBack() {
        this._location.back();
    }

    getUserType(type) {

        return user_types[type];

    }

    applyFilter(filterValue: string) {
        // console.log("filterValue");

        this.dataSource2.filter = filterValue.trim().toLowerCase();
    }

    applyFilter2(filterValue: string) {

        this.facilities.filter = filterValue.trim().toLowerCase();
    }

    handleSubmit(e) {
        // if(this.model.user_type === 'provider' && this.selectedFacilities.length === 0) {
        // this.facilityError = true;
        // }
        if (!e.valid) return;
        this.model.email = this.model.email.toLowerCase();
        this.model.email.replace( /\s/g, '');
        this.model.cc_email = this.model.cc_email.toLowerCase();
        this.model.cc_email.replace( /\s/g, '');
        const user_type = this.getUserType(this.model.user_type);
        if (this.selectedCountry === 'Pak(+92)') {
            this.phoneCleaned = '+92' + this.phoneCleaned;
        }
        else if (this.selectedCountry === 'US / CA(+1)') {
            this.phoneCleaned = '+1' + this.phoneCleaned;
        }
        else if (this.selectedCountry === 'Philippines(+63)') {
            this.phoneCleaned = '+63' + this.phoneCleaned;
        }
        else if(this.selectedCountry === 'Ind(+91)') {
            this.phoneCleaned = '+91' + this.phoneCleaned;
        }
        const model = { ...this.model };
        model.mobile = this.phoneCleaned;
        if(localStorage.getItem('companyCode')){
            this.companyCode = localStorage.getItem('companyCode');
            model.companyCode = this.companyCode;
        }

        const data: any = {
            ...model,
            user_type
        }

        if (this.selectedFacilities.length > 0) {
            data.facilities = this.selectedFacilities.map(ele => { return ele = ele._id });
            // console.log(data);
        }

        try {
            this.loader.startLoader(this.loaderId);
            if (this.user_id) {
                this.userService.updateUser(data).subscribe((response: any) => {
                    this.loader.stopLoader(this.loaderId);
                    this.toastr.success('User added succesfully', 'Success')
                    this.goBack();
                    // e.reset(); 
                })
            } else {
                let dateTime = this.commonService.convertDateToStringforMoment(moment(),true);
                data.dateTime = dateTime; //changes
                data.creatorId = this._current_user.currentUser._id;
                this.companyService.addUser(data).subscribe(
                    (res) => {
                        this.loader.stopLoader(this.loaderId);
                        if (res.status === 200) {
                            this.toastr.success('User added successfully', 'Success');
                            this.goBack();
                            // e.reset(); 
                            this.importPhrases(null, [res.data._id]);
                        } else {
                            this.toastr.error('Email already exist', 'Error');
                            this.phoneCleaned, this.model.mobile = "";
                        }
                    }
                );
            }
        } catch (error) {
            this.loader.stopLoader(this.loaderId);
            this.toastr.error('Something went wrong', 'Error');
        }
    }

    importPhrases(sharedProviderId, sharedProviderIds = null) {
        if (sharedProviderIds) {
          sharedProviderId = null;
        }
        this.drivePhraseService.saveNoteBuilderDictionaryAdmin(sharedProviderId, this.phraseArray, sharedProviderIds).subscribe((response: any) => {
          console.log(response);
          if (response.status === 200) {
            this.toastr.success('Shared successfully!');
          }
        })
    }

    getAllCompanies() {
        if (this.product_owner) {
            this.companyService.getAllCompanies().subscribe((response: any) => {
                if (response.status == 200) {
                    this.companies = response.data
                }
                else if (response.status == 403) {
                    this.toastr.error(response.message, "ERROR");
                }
            })
        }
        else if (this._current_user.currentUser.admin_type === "full_admin" || this._current_user.currentUser.admin_type === "system_admin") {
            this.companyService.getCompanyName(this.model.company_id).subscribe((response: any) => {
                if (response.status == 200) {
                    this.companies.push(response.data);
                }
            });
        }
    }
    allowOnlyText(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 90 && charCode < 97) {
            return false;
        }

        if (charCode < 65 || charCode > 122) {
            return false;
        }
        return true;
    }
    keyPressNumbers(event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        // Only Numbers 0-9
        if ((charCode < 48 || charCode > 57)) {
            event.preventDefault();
            return false;
        }
        else {
            return true;
        }
    }
    countryFilter(Country) {
        this.phoneCleaned = "";
        this.model.mobile = "";
        if (Country == 'US / CA(+1)') {
            this.selectedCountry = Country;
            this.maxMobileLength = 12;
        }
        else if (Country === 'Philippines(+63)') {
            this.selectedCountry = Country;
            this.maxMobileLength = 10;
        }
        else if (Country === 'Pak(+92)') {
            this.selectedCountry = Country;
            this.maxMobileLength = 12;
        }
        else if(Country === 'Ind(+91)') {
            this.selectedCountry = Country;
            this.maxMobileLength = 11;
        }
    }
    changeUserType($event) {
        this.model.title = '';
        if($event === 'test_provider'){
            this.model['additionalUserRole'] = 'demo_provider';
            this.model.user_type = 'provider';
        }
        else if($event === 'cnp'){
            this.model['additionalUserRole'] = 'cnp';
            this.model.user_type = 'provider';
        }
        if ($event === 'wound_nurse') {
            this.titles = ['LPN']
        } else {
            this.titles = ["M.D.", "D.O.", "PA-C", "ARNP", "R.N.", "N.P.", "P.A.", "L.Ac", "DNP", "CWS", "CWSP", "WOCN","ANP-BC" , "CWCN"];
        }
        console.log('this.model',this.model);
        if (this.model.user_type === "provider") this.isMandatoryNPI = true;
        else this.isMandatoryNPI = false;
    }
    isOptionVisibleForCompany( option ) {
        const companyId = this.route.parent.parent.snapshot.params.id;
        return this.userService.isOptionVisibleForCompany( option, companyId);
    }

    getMarker() {
        return this.phrasesService.marker
    }
    addToPhraseArray(e,$event) {
        if ($event.checked) {
            this.phraseArray.push(e);
          } else if (!$event.checked) {
            const index = this.phraseArray.findIndex(p => p._id.toString() == e._id.toString());
            if (index > -1) {
              this.phraseArray.splice(index, 1);
            }
          }
          const id = e._id;
          const isChecked = $event.checked
          this.dictionary.map((d) => {
            if (id === d._id) {
              this.masterSelected = false;
              d.select = isChecked;
              return d;
            }
            return d;
        });
    }
    selectAllPhrases($event) {
        const isChecked = $event.checked;
        if (isChecked) {
        //   this.phraseArray = [];
          this.dictionary.forEach(element => {
            this.phraseArray.push(element);
            element.select = true;
          });
        } else {
          this.dictionary.forEach(element => {
            this.phraseArray = [];
            element.select = false;
          });
        }
    }
    filterPhrases(filterValue: string) {
        this.dataSourcePhrases.filter = filterValue.trim().toLowerCase();
    }
}

