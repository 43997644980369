import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-information',
  templateUrl: './dialog-information.component.html',
  styleUrls: ['./dialog-information.component.css']
})
export class DialogInformationComponent implements OnInit {

  title:String
  button:String='Back';
  
  constructor( @Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<DialogInformationComponent>) { 
    
  }

  goBack(status){
    this.dialogRef.close({back:status?status:false});
  }

  ngOnInit(): void {
    this.title = this.data.message
    this.button = this.data.button

  }

}
