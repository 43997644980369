import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import * as global from '../../../includes/global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URL } from "../../../../environments/api-url";

@Injectable({
  providedIn: 'root'
})
export class LosReportService {

  currentUser: any;
  constructor(
    private authService: AuthService,
    private httpclient: HttpClient,
  ) {
    this.currentUser = this.authService.currentUser;
  }


  downloadLosReport(dataSource, facilityName) {
    // console.log("updated patients data", dataSource);
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Content-Type', 'application/octet-stream');
    let patients = [];
    for(let patient of dataSource){
      let obj = {
        name: patient.name,
        title: facilityName,
        days: patient.days,
        admitDate: patient.admitDate,
        dischargeDate: patient.dischargeDate,
        readmitted: patient.readmitted,
        ipa: patient.ipa
      }
      patients.push(obj);
    }
    // console.log("updated patients data", patients)
    return this.httpclient.post(global.url + API_URL.CHARGE.downloadLosReport, { patients }, {
      responseType: "blob",
      reportProgress: true,
      observe: 'events',
      headers
    });
  }
}
