<div class="audio-list-dialog">
    <h1 mat-dialog-title>Audios</h1>
    <div class="flex flex-col gap-2">
        <div class="audio-item flex items-center justify-between gap-5" *ngFor="let audio of audios">
            <div class="flex flex-col">
                <div class="flex-grow" style="min-width:200px">{{audio.audio_title}}</div>
                <div class="flex gap-8" style="color: gray;">
                    <small style="min-width: 200px;">DOS: {{audio.date_time | date: global.date_format}}</small>
                    <small>Provider: {{audio.uploaded_by_id_ref.first_name}} {{audio.uploaded_by_id_ref.last_name}}</small>
                </div>
            </div>
            <audio [src]="audio.aws_path" controls controlsList="nodownload"></audio>
            <a
            *ngIf="isOwner(audio)"
            [routerLink]="['/note-editor/new', 
              {
                audio_id: audio._id,
                patient_id: patient._id,
                dos: census.date,
                census_id: census._id
              }
            ]"
            mat-dialog-close 
            class="flex flex-row items-center justify-center"
            >
              <i class="icon icon-add" 
              matTooltip="Create Audio Note" matTooltipPosition="above"></i>
            </a>
        </div>
    </div>
</div>
<div class="top-right-close-btn">
    <button mat-mini-fab mat-dialog-close nofocus>
        <mat-icon>cancel</mat-icon>
    </button>
</div>