<app-header *ngIf="!admin && !company"></app-header>
<main>
  <div [class.container]="!admin && !company">
    <mat-card *ngIf=" currentUser.user_type !== global.USER_TYPE.SNF_WC_NURSE && currentUser.user_type !== global.USER_TYPE.VIEW_ONLY_USER && userCompany.should_show_census_charges_count" class="census-list">
          <div class="flex gap-8 mt-3 ml-3">
            <div>
              <span class="cursor-pointer text-[13px]" [routerLink]="['/dashboard']" [queryParams]="{prop: 'draft', providerId : newSelectedProvider ? newSelectedProvider : currentUser._id }">Drafted Charges ({{chargesResponse?.draftCount}})</span>
            </div>
            <div>
              <span class="cursor-pointer text-[13px]" [routerLink]="['/dashboard']" [queryParams]="{prop: 'submit' , providerId : newSelectedProvider ? newSelectedProvider : currentUser._id }">Submitted Charges({{chargesResponse?.submittedCount}})</span>
            </div>
            <div>
              <span class="cursor-pointer text-[13px]" [routerLink]="['/dashboard']" [queryParams]="{prop: 'billed' , providerId : newSelectedProvider ? newSelectedProvider : currentUser._id }">Billed Charges({{chargesResponse?.billedCount}})</span>
            </div>
            <div>
              <span class="cursor-pointer text-[13px]" [routerLink]="['/dashboard']" [queryParams]="{prop: 'missingFacesheet' , providerId : newSelectedProvider ? newSelectedProvider : currentUser._id }">Charges with Missing Facesheets ({{missingFaceSheetsCount}})</span>
            </div>
            <div>
              <span class="cursor-pointer text-[13px]" [routerLink]="['/dashboard']" [queryParams]="{prop: 'addendumReq' , providerId : newSelectedProvider ? newSelectedProvider : currentUser._id }">Addendum REQ ({{addendumReqCount ? addendumReqCount : 0}})</span>
            </div>
          </div>
    </mat-card>
    <mat-card *ngIf="chargeStatus && !isNurseUser && censusData && userCompany.enable_company_mips" style="margin-bottom: 5px; height: 3rem;">
      <div class="flex gap-12">
        <div  class="justify-between" style="margin-right: 10px;">
          <span>MIPS Status:</span>
        </div>
        <div style="width: 100%;" class="flex justify-between items-center">
          <ng-container *ngIf="userMips.length > 0">
            <span style="font-size: 13px; text-align:center;" *ngFor="let data of userMips">{{data.mips_code}}<br/>  
              <ng-container *ngIf="data.count == undefined && !data.loading"><span style="cursor: pointer; color:blue;
                text-decoration:underline;" (click)="onMipsSelect(data)">Show</span></ng-container>
              <ng-container *ngIf="data.count != undefined && !data.loading"><span style="font-weight: 600;">{{data.count}}%</span></ng-container>
              <ng-container *ngIf="data.loading"><span>Loading...</span></ng-container>
              </span>
          </ng-container>
          <ng-container *ngIf="userMips.length == 0">
            <span style="font-size: 13px; text-align:center;">No MIPS Available.</span>
          </ng-container>
        </div>  
      </div>
    </mat-card>
    <mat-card class="tabs-card">
        <mat-tab-group [selectedIndex]=defaultTabIndex style="height: 100%;">
            <mat-tab style="height: 100%;" label="List View">
                <ng-template matTabContent>
                    <app-census-list (refreshCounts)="refreshCounts($event)" (mipsUpdate)="mipsUpdate($event)"></app-census-list>
                </ng-template>
            </mat-tab>
            <mat-tab style="height: 100%;" label="Calender View">
                <ng-template matTabContent>
                    <app-census-calender></app-census-calender>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</div></main>



