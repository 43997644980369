<ng-container *ngIf="showMipsDialog && measures && measures.length">
  <div class="mips-dialog-overlay"></div>
  <div class="mips-dialog">

    <div class="wrapper flex flex-row gap-5" *ngIf="measures && measures.length">

      <ul class="mips-codes" [ngClass]="{'selected-mips' : !hideMips && selectedMeasure}">
        <li class="flex gap-2 items-center" *ngFor="let measure of measures" 
            (click)="handleMeasureSelect(measure)"
            matTooltip="{{ measure.description }}"
          >
            <span class="mips-codes-item" 
            [class.active]="selectedMeasure?.quality_number === measure.quality_number"
            [class.answered]="isMeasureAnswered(measure)"
            >
              {{ measure.quality_number }}
            </span>
            <label>{{ measure.title }}</label>
        </li>
      </ul>

        <app-mips-questionnaire
          *ngIf="!hideMips && selectedMeasure"
          [measure]="selectedMeasure"
          [draftMips]="draftMips"
          (onAnswerCalculation)="handleAnswerCalculate($event)"
        ></app-mips-questionnaire>
     
    </div>
 
    <div class="mips-dialog-footer flex gap-5 justify-center items-center">
      <button class="dn-button-primary" mat-raised-button type="button" (click)="saveMipsasDraft()" [disabled]="getMipsValueForButton()||submitClicked">Save as draft</button>
      <button class="dn-button-primary" mat-raised-button type="button" (click)="mipsDialog()">Close</button>
      <button class="dn-button-primary" mat-raised-button type="button" (click)="submitMips()" [disabled]="getMipsValueForButton()||submitClicked">Submit MIPS</button>

    </div>

    
  </div>
</ng-container>


  <button class="dn-button-primary" style="width: 150px;" *ngIf="mipsLoaded" mat-raised-button type="button" (click)="initDraftQuestionare('showMipsDialog')">Select MIPS</button>
