<ng-container *ngIf="showMipsDialog">
    <div class="mips-dialog-overlay"></div>
    <div class="mips-dialog">
        <div class="wrapper">
            <div class="list-container" *ngIf="formattedMips&&formattedMips.length">
                <table>
                    <thead>
                        <tr>
                            <th class="col-1">{{'Number'}}</th>
                            <th class="col-2">{{'Code'}}</th>
                            <th class="col-3">{{'Measure Title'}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let mip of formattedMips; let i = index">
                            <td class="col-1"><span class="mips-codes-item">{{mip.number}}</span></td>
                            <td class="col-2 mip-question" [matTooltip]="getQuestion(mip)">{{getCode(mip)}}</td>
                            <td class="col-3">{{mip.title}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>  

        <div class="mips-dialog-footer">
            <button class="dn-button-primary" mat-raised-button type="button" (click)="mipsDialog()">Close</button>
        </div>

        <div class="info">*MIPS Measures already submitted</div>
    
    </div>
</ng-container>


<button class="dn-button-primary" mat-raised-button type="button" (click)="mipsDialog()">Submitted MIPS</button>
