<form #form="ngForm" (ngSubmit)="handleSubmit(form)">

    <div class="flex flex-row justify-between items-center">
        <div>
            <mat-form-field>
                <mat-label>Select Facility</mat-label>
                <mat-select name="facility"
                            #facility="ngModel"
                            (selectionChange)="refreshSchedule($event)"
                            [(ngModel)]="selectedFacility">
                    <mat-option *ngFor="let f of facilities"
                                value="{{f._id}}">{{f.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <button mat-raised-button class="dn-button-primary">Save</button>
        </div>
    </div>
    <br />
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="day">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container> Day </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox name="day"
                              [checked]="isAvailable(element.day)"
                              (change)="model[element.day].availability = $event.checked">
                    {{element.day}}
                </mat-checkbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="start_time">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container> Start Time </th>
            <td mat-cell *matCellDef="let element">
            </td>
        </ng-container>
        <ng-container matColumnDef="end_time">
            <th mat-header-cell *matHeaderCellDef mat-sort-header-container> End Time </th>
            <td mat-cell *matCellDef="let element">
                <div class="time_picker" *ngIf="element">
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <ngx-ui-loader [loaderId]="timeScheduleLoader"></ngx-ui-loader>
</form>
