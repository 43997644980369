import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/includes/login/login.service';
import { RegisterService } from 'src/app/includes/register/register.service';
import * as global from '../../../global'
import { Location } from '@angular/common';

@Component({
  selector: 'app-register-company',
  templateUrl: './register-company.component.html',
  styleUrls: ['./register-company.component.css']
})
export class RegisterCompanyComponent {
  global = global;
  user_type: string = "";
  title: string = ""; // only for transcriber
  firstname: string = "";
  lastname: string = "";
  email: string = "";
  mobile: string = "";
  // phone_number:string="";
  password: string = "";
  confirm_password: string = "";
  passwordStrength: number = 0;
  passwordError: String = "";
  submitted = false;
  passError: String = "";
  tin: any;

  // accountType: 'user'|'company' = 'user';
  accountType: 'user' | 'company' = 'company';
  titles = ["M.D.", "D.O.", "PA-C", "ARNP", "R.N.", "N.P.", "P.A.", "L.Ac", "DNP", "CWS", "CWSP", "WOCN","ANP-BC", "CWCN"];
  Country = ['US / CA(+1)', 'Philippines(+63)', 'Pak(+92)', 'Ind(+91)'];
  titleControl = new FormControl();
  phoneCleaned: string;
  selectedCountry: any;
  maxMobileLength: number;
  displayProviderFn(title): string {
    return title;
  }

  constructor(
    private _registerService: RegisterService,
    private _toastr: ToastrService,
    private _loginService: LoginService,
    private _router: Router,
    private _location: Location
  ) { }
  
  onRegisterCompany(registerForm) {
    if (!registerForm.valid) {
      return
    }
    const {
      company_name,
      company_description,
      company_type,
      email,
      mobile,
      company_address,
      firstname,
      lastname,
      tin,
    } = registerForm.value;

  
    const details = {
      title: this.titles[0],
      user_type: global.USER_TYPE.DOCTOR,
      account_type: this.accountType,
      company_name,
      company_description,
      company_type,
      email,
      mobile,
      firstname,
      lastname,
      company_address,
      profile_pic: "https://i.imgur.com/RXB5dFH.png",
      is_verified: 'false',
      email_notification: 'true',
      push_notification: 'true',
      registered_by: '26',
      email_auth_notification: 'true',
      sms_auth_notification: 'false',
      device_token: '',
      admin_type: "full_admin",
      tin
    };
    if (this.selectedCountry === 'Pak(+92)') {
      details.mobile = '+92' + this.phoneCleaned;
    }
    else if (this.selectedCountry === 'US / CA(+1)') {
      details.mobile = '+1' + this.phoneCleaned;
    }
    else if (this.selectedCountry === 'Philippines(+63)') {
      details.mobile = '+63' + this.phoneCleaned;
    }
    else if(this.selectedCountry === 'Ind(+91)') {
      details.mobile = '+91' + this.phoneCleaned;
    }

    const infoToastr = this._toastr.info('Registering...', 'Please wait', { disableTimeOut: true });
    

    this._registerService.doRegister(details).subscribe((response: any) => {
      if (response.status === 200) {
        this._toastr.success('Company has been created', "Success");
        this.goBack();
        

      } else {
        this._toastr.error(response.message, "Failed");
      }
    }, console.error, () => this._toastr.clear(infoToastr.toastId));

  }
  getUserType(type) {
    if (type === 'provider' || type === 'company') {
      return '1';
    } else if (type === 'transcriber') {
      return '2';
    } else if (type === 'manager') {
      return '3';
    } else if (type === 'pa') {
      return '4';
    } else if (type === 'nurse') {
      return '5';
    } else if (type === 'biller') {
      return '6';
    }
  }

  strengthChange(passwordStrength) {
    this.passwordStrength = passwordStrength;
  }

  isPasswordValid() {
    if (this.password != this.confirm_password) {
      this.passError = "Confirm Password does not match";
    } else
      if (this.passwordStrength < 3) {
        const strengthMsg: any = ["Password strength is low",
          "Password strength low",
          "Password should not contain common words"];
        this.passwordError = strengthMsg[String(this.passwordStrength)];
      } else {
        this.passwordError = "";
      }
  }

  goBack() {
    this._location.back();
  }

  countryFilter(Country) {
    this.phoneCleaned = "";
    this.mobile = "";
    if (Country == 'US / CA(+1)') {
        this.selectedCountry = Country;
        this.maxMobileLength = 12;
    }
    else if (Country === 'Philippines(+63)') {
        this.selectedCountry = Country;
        this.maxMobileLength = 10;
    }
    else if (Country === 'Pak(+92)') {
        this.selectedCountry = Country;
        this.maxMobileLength = 12;
  }
    else if (Country === 'Ind(+91)') {
      this.selectedCountry = Country;
      this.maxMobileLength = 11;
    }
}

handlePhoneModelChange(value) {
  if (this.selectedCountry === 'Philippines(+63)') {
      this.mobile = value;
      this.phoneCleaned = value;
  }
  else if (value) {
      const cleaned = value.replace(/-/g, '');
      if (this.selectedCountry === 'Ind(+91)') {
        this.mobile = cleaned.match(/.{1,4}/g).join('-');
        if (this.mobile.length > 9) {
          let tempNum = this.mobile.split('-');
          let num = [tempNum[0], tempNum[1] + tempNum[2]];
          this.mobile = num.join('-');
        }

      }
      else {
        this.mobile = cleaned.match(/.{1,3}/g).join('-');
      }
      if (this.mobile.length > 12) {
          let position = this.mobile.lastIndexOf('-');
          this.mobile = this.mobile.substring(0, position) + this.mobile.substring(position + 1);
      }
      this.phoneCleaned = cleaned.trim();
  }
}
onPaste(event: ClipboardEvent) {
  let clipboardData = event.clipboardData;
  let pastedData = clipboardData.getData('Text').toString();
  let number: any = pastedData.match(/\d/g);
  number = number.join("");
  if (number.length > 10) {
      number = number.substring(0, 10);
  }
  this.handlePhoneModelChange(number);
}
keyPressNumbers(event) {
  var charCode = (event.which) ? event.which : event.keyCode;
  // Only Numbers 0-9
  if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
  }
  else {
      return true;
  }
}
}

