import {
	Component,
	OnInit,
	Inject,
} from '@angular/core';
import {
	MatDialogRef,
	MAT_DIALOG_DATA
} from '@angular/material/dialog';
import {
	AuthService
} from '../services/auth.service';
import * as global from 'src/app/includes/global'
import {
	FormControl
} from '@angular/forms';
import {
	Observable,
	of,
	Subscription
} from 'rxjs';
import {
	NgxUiLoaderService
} from 'ngx-ui-loader';
import {
	PatientListService
} from '../includes/patient-list/patient-list-service';
import {
	IPatient,
	Patient
} from 'src/app/classes/patient';
import {
	debounceTime,
	distinctUntilChanged,
	map,
	startWith,
	tap,
} from 'rxjs/operators'
import {
	CommonService
} from '../services/common.service';
import {
	PCCService
} from '../services/pcc.service';
import {
	UserService
} from '../services/Modules/user.service';
import {
	FacilityService
} from '../services/facility.service';
import moment from 'moment';
import {
	AppointmentService
} from '../services/appointment.service';
import {
	MatDialog
} from '@angular/material/dialog';
import {
	DialogConfirmationComponent
} from '../includes/census-patient-list/dialog-confirmation/dialog-confirmation.component';
import {
	ToastrService
} from 'ngx-toastr';
import {
	AppointmentAddPatientDialogComponent
} from '../appointment-add-patient-dialog/appointment-add-patient-dialog.component';
import {
	DatePipe
} from '@angular/common';
import {
	SocketService
} from '../services/socket.service';
import {
	PatientAddService
} from '../includes/patient-add/patient-add-service';
import { CompanyService } from '../services/Modules/company.service';
import { ActivatedRoute } from '@angular/router';
import { RRule, RRuleSet } from "rrule";
import { AppointmentUtilityService } from '../services/appointment-utility.service';
import { IDropdownSettings } from "ng-multiselect-dropdown";

@Component({
	selector: 'app-add-appointment-dialog',
	templateUrl: './add-appointment-dialog.component.html',
	styleUrls: ['./add-appointment-dialog.component.css'],
	providers: [DatePipe]
})
export class AddAppointmentDialogComponent implements OnInit {
	formData: any = {}; // Object to hold form data
	appointmentBtn: string = 'Add';
	appointmentTypes = []
	currentUser: any;
	global = global;
	patientsByProviderControl = new FormControl();
	filteredPatientsByProvider$: Observable<IPatient[]>;
	filteredProvider: Observable<string[]>;
	filteredFacilities: Observable<string[]>;
	pccPatientsControl = new FormControl();
	filteredPccPatients: Observable<any[]>;
	patientsByProvider: Patient[] = [];
	provideModel: any = '';
	providerControl = new FormControl();
	facilityControl = new FormControl();
	pccPatients: Patient[] = [];
	selectedPatients: any[] = [];
	searchPatientStatus = 'Current';
	patientsSearchLoaderId = 'pcc-patients-loader';
	locationSearchLoaderId = 'location-loader';
	users: any[] = [];
	uniqueProviders: any[] = [];
	resources: any[] = [];
	addressSubscription: Subscription;
	searchTextProvider: string = '';
	searchTreatmentTeam: string = '';
	facilities = [];
	isAutoCompleteOpen = false;
	selectedPatient: string = '';
	patientNotFound: boolean = false
	isDisabledProvider: boolean = false;
	isDisabledFacility: boolean = false;
	isDisabledStartDate: boolean = false;
    isDisabledEndDate: boolean = false;
	isDisabledTime: boolean = false;
	company_id: string = '';
	isDisabledAppointmentType: boolean = false;
	treatmentTeam: string[] = [];
	providerDropdownSettings: IDropdownSettings = {
		singleSelection: false,
		idField: "_id",
		textField: "full_name",
		selectAllText: "Select All",
		unSelectAllText: "UnSelect All",
		itemsShowLimit: 5,
		limitSelection: 5,
		noDataAvailablePlaceholderText: 'No Providers found',
		allowSearchFilter: true,
		enableCheckAll: true
	};
	repeatings = [
		{value: "Does not Repeat"},
		{value: "Weekly"},
		{value: "Biweekly"},
		{value: "Monthly by Day"},
		{value: "Monthly by Date"},
	];
	daysOfMonth: number[] = Array.from({length: 31}, (_, i) => i + 1);
	weekdays: string[] = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
	weeksOfMonth: string[] = ["1st", "2nd", "3rd", "4th", "Last"];
	selectedDays: number[] = [];
	selectedWeekdays: string[] = [];
	selectedWeeks: string[] = [];
    initialPatientSearchDone: boolean = false;

    constructor(
		public dialogRef: MatDialogRef<AddAppointmentDialogComponent>,
		private authService: AuthService,
		private _toastr: ToastrService,
		private loader: NgxUiLoaderService, private _patientListService: PatientListService,
		public commonService: CommonService, private pccService: PCCService,
		private userService: UserService, private facilityService: FacilityService,
		private appointmentService: AppointmentService,
		private companyService: CompanyService,
		public route: ActivatedRoute,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialog: MatDialog,
		private _date_pipe: DatePipe,
		private _socketService: SocketService,
		private _patientAddServie: PatientAddService,
        private appointmentUtility: AppointmentUtilityService
    ) {
		this.currentUser = this.authService.currentUser;
		const currentCompany = this.commonService.getLocalStorageItem('admin-Dashboard');
		if (currentCompany) {
			this.company_id = currentCompany.currentCompanyId;
		} else {
			this.company_id = this.route.snapshot.firstChild.params.id;
		}
	}

	ngOnInit(): void {
		this.getSettings()
		this.initPatientsByProvider();
		this.getResources();
		this.initFilteredProvider();

		if (this.data) {
			this.initializeFormDataFromData();
			this.getFacilities();
			this.handleDataTitle();
		} else {
			this.formData.repeating = "Does not Repeat";
			this.formData.start_time = moment().format("HH:mm a");
			this.formData.end_time = moment().format("HH:mm a");
			this.formData.start_date = moment().format("YYYY-MM-DD");
			this.formData.end_date = moment().format("YYYY-MM-DD");
			// old fields
			this.formData.time = moment().format(' HH:mm');
			this.formData.date = moment().format('YYYY-MM-DD HH:mm');
			this.formData.dos =  moment().format('YYYY-MM-DD');
			this.isDisabledEndDate = this.formData.repeating == "Does not Repeat" ?  true:false;
		}
	}
	private initFilteredProvider() {
		this.filteredProvider = this.providerControl.valueChanges.pipe(
			startWith(''),
			map(value => this.data.providerName ? this.filterProvider(this.data.providerName) : this.filterProvider(value))
		);
	}

	private initializeFormDataFromData() {
		this.isDisabledProvider  = true;
        this.isDisabledFacility = this.data.facility_id ? true : false;
		this.isDisabledStartDate = true;
		this.isDisabledTime = true;
		this.isDisabledAppointmentType = this.data.appointment_type ? true : false;
		this.appointmentBtn = this.data.appointment_id ? 'Update' : 'Add';
		this.formData.type = this.data.appointment_id ? 'update' : 'add';
		this.formData.date = moment(this.data.start).format('YYYY-MM-DD HH:mm');
		this.formData.time = moment(this.data.start).format(' HH:mm');
		this.formData.dos =  moment(this.data.start).format('YYYY-MM-DD');
		this.formData.duration = this.data.duration;
		this.formData.providerType = this.data.providerId;
		this.formData.facilityType = this.data.facility_id;
		this.provideModel = this.data.providerName || '';
		this.formData.appointmentType = this.data.appointment_type;
		this.isAutoCompleteOpen = false;
		this.formData._id = this.data.appointment_id;
		this.formData.comments = this.data.comments  || '';
		this.formData.patient = this.data.patient_id || '';
		this.formData.start_time =
        this.data.startTime ?? moment(this.data.start).format("HH:mm a");
        this.formData.start_date = this.data.start_date_obj
     		? moment({
				year: this.data.start_date_obj.year,
				month: this.data.start_date_obj.month - 1, // Adjust month to 0-indexed
				day: this.data.start_date_obj.day,
     	 	}).format("YYYY-MM-DD")
      		: moment(this.data.start).format("YYYY-MM-DD");
   		this.formData.end_time =
      	this.data.endTime ?? moment(this.data.end).format("HH:mm a");
   		this.formData.end_date = this.data.end_date_obj
     	 ? moment({
          year: this.data.end_date_obj.year,
          month: this.data.end_date_obj.month - 1, // Adjust month to 0-indexed
          day: this.data.end_date_obj.day,
        }).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");
		this.selectedDays = this.data.selectedDays;
		this.selectedWeeks = this.data.selectedWeeks;
		this.selectedWeekdays = this.data.selectedWeekdays;
		this.provideModel = this.data.providerName || "";
		this.formData.repeating = this.data.repeating || "Does not Repeat";
		this.isDisabledEndDate = this.formData.repeating == "Does not Repeat" ?  true:false;
        this.treatmentTeam = this.data.treatment_team ?? [];

		if (this.data.title) {
			this.selectedPatient = this.data.title
			this.formData.patientName = this.data.title;
			this.doInitPatients(this.data.title.split(', ')[0]);
			this.filteredPatientsByProvider$.subscribe(patients => {
				if (patients.length > 0) {
					const matchedPatient = patients.find(patient => patient._id === this.data.patient_id);
					if (matchedPatient) {
						this.patientsByProviderControl.setValue(matchedPatient); // Set the matched patient as the default value
					}
					// this.patientsByProviderControl.setValue(patients[0]); // Set the first patient as the default value
				}
			});
		}

	}

	private handleDataTitle() {
		if (this.data.title) {
			this.formData.patientName = this.data.title;
			this.doInitPatients(this.data.title.split(', ')[0]);
			this.filteredPatientsByProvider$.subscribe(patients => {
				if (patients.length > 0) {
					this.patientsByProviderControl.setValue(patients[0]);
				}
			});
		}
	}
	getSettings(){
    // this.loader.startLoader("scheduler-settings-loader");
    this.companyService.getCompanyAppointmentTypes(this.company_id).subscribe((response: any) => {
        this.appointmentTypes = response.data;
    })
  }
	getFacilities() {
		this.addressSubscription = this.facilityService.getMyFacilities(null, this.formData.providerType,false ,this.company_id).subscribe((response: any) => {
			if (response.status == 200) {
				this.facilities = response.data.array;
				this.facilities.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
				this.loader.stopLoader(this.locationSearchLoaderId);
			} else {
				this.loader.stopLoader(this.locationSearchLoaderId);
			}
		});
	}
	private filterProvider(value: any): string[] {
		if (value) {
			const filterValue = value.toLowerCase();
			let users = this.uniqueProviders.filter(option => option.name.toLowerCase().indexOf(filterValue) > -1);

			return this.sortFirstNameVise(users);
		} else {
			return this.sortFirstNameVise(this.uniqueProviders);
		}
	}
	clearProviderFilter() {
		this.provideModel = '';
		this.formData.providerType = '';
		this.filteredProvider = this.providerControl.valueChanges.pipe(
			startWith(''),
			map((value: any) => this.filterProvider(value)));
		// this.initCompanyFacilities();
	}
	getResources() {
		const filter = {
			is_activated: 'true',
			company_id: this.company_id,
			user_type: global.USER_TYPE.DOCTOR,
		}
		const projection = {
			'first_name': 1,
			'last_name': 1,
			'title': 1,
			'full_name': 1,
			'company_id': 1,
		}
		this.userService.getUsers(filter, projection).subscribe((response: any) => {
			if (response.status == 200) {
				// this.users = [...response.data];
				this.users = response.data.map(u => {
					u.name = `${u.first_name} ${u.last_name}`;
					if (u.user_type == '1') {
						u.name += `, ${u.title}`
					}
					return u;
				});

				const map = new Map();
				for (const item of this.users) {
					if (!map.has(item._id) && item.first_name) {
						map.set(item._id, true); // set any value to Map
						this.uniqueProviders.push(item);
					}
				}
				this.uniqueProviders = [...this.uniqueProviders]; // update ref
			}
		});
	}
	sortFirstNameVise(arrayTosort) {
		let users = arrayTosort;
		let result = users.sort(function(a, b) {
			if (a.first_name?.toLowerCase() < b.first_name?.toLowerCase()) {
				return -1;
			}
			if (a.first_name?.toLowerCase() > b.first_name?.toLowerCase()) {
				return 1;
			}
			return 0;
		})
		return result;
	}
	closeDialog(): void {
		this.dialogRef.close();
	}
	submitForm() {
		const {
			providerType,
			facilityType,
			patient,
			appointmentType,
			time,
			dos,
			start_time,
			duration,
			repeating,
			start_date
		} = this.formData;
		if (providerType && facilityType && patient && appointmentType && time && dos) {
			if(this.formData.repeating == "Does not Repeat"){
				this.formData.start_date = dos;
				this.formData.end_date = dos;
			}
			this.formData.start_time = moment(start_time, 'HH:mm a').format("HH:mm"); // convert back to 24 hour format to save
			this.formData.end_time = this.addDurationToTime(this.formData.start_time,Number(duration));
            this.formData.treatment_team = this.treatmentTeam;
			this.formData.selectedDays = this.selectedDays;
			this.formData.selectedWeekdays = this.selectedWeekdays;
			this.formData.selectedWeeks = this.selectedWeeks;
		    this.formData.start_date_obj = {
				day: moment(this.formData.start_date).date(),
				month: moment(this.formData.start_date).month() + 1,
				year: moment(this.formData.start_date).year()
			};
			this.formData.end_date_obj ={
				day: moment(this.formData.end_date).date(),
				month: moment(this.formData.end_date).month() + 1,
				year: moment(this.formData.end_date).year()
			};
			const {rules,occurrences} = this.appointmentUtility.generateRecurringRule(this.formData.repeating,this.formData);
			this.formData.recurrenceRule = rules;
            this.formData.occurrences = occurrences
			this.dialogRef.close(this.formData);
		}

	}
	displayPatientFn(patient): string {
		return patient && patient.name ? patient.name : '';
	}
	async initPccPatients() {
		this.pccPatientsControl.valueChanges
			.pipe(debounceTime(1000), distinctUntilChanged())
			.subscribe(async (name) => this.doInitPccPatients(name));
	}
	async initPatientsByProvider() {
		this.patientsByProviderControl.valueChanges
			.pipe(debounceTime(1000), distinctUntilChanged())
			.subscribe(async (name) => {
				console.log('search', name);
				this.doInitPatients(name)
			});
	}
	doInitPatients(name: string): void {
		if (!name || typeof name !== 'string'|| name.length < 4) {
			this.filteredPatientsByProvider$ = of([]);
			return;
		}

		this.loader.startLoader(this.patientsSearchLoaderId);
		this.filteredPatientsByProvider$ = this._patientListService
			.getPatientsV2(
				this.company_id,
				this.formData.facilityType,
				undefined,
				name,
				undefined,
				undefined,
                undefined,
                undefined,
                undefined,
                true
			)
			.pipe(
				tap((patientsResponse: any) => {
					if(patientsResponse.status === 200) {
                        this.patientsByProvider = patientsResponse.data.array.map(
                            (p) => new Patient(p)
                        );

						// Enable add patient button on first search with no results
						if (!patientsResponse.data.array?.length) {
							this.initialPatientSearchDone = true;
						}
                    }else{
                        this._toastr.error(patientsResponse.message, "Error");
                    }
					this.loader.stopLoader(this.patientsSearchLoaderId);
				}),
				map((patientsResponse: any) => {
					return patientsResponse.data && patientsResponse.data.array
						?.filter(p => !!p)
						.map((p) => ({
							...p,
							name: this.commonService.getPatientFullNameFormat2(p)
						}));
				}),
				tap((filteredPatients: any[]) => {
					this.patientNotFound = Array.isArray(filteredPatients) && filteredPatients.length === 0;
				})
			);
	}

	async doInitPccPatients(name) {
		if (!name) return;

		if (this.searchPatientStatus === 'All') {
			this.searchPatientStatus = ''
		}

		this.loader.startLoader(this.patientsSearchLoaderId);


		const args: any = {
			pccOrgUuid: this.formData.facilityType.pcc_orgUuid,
			pccFacID: this.formData.facilityType.pcc_facId,
			pccPatientStatus: this.searchPatientStatus,
			pccPatientName: name,
			page: 1,
			pageSize: 200
		}

		this.filteredPccPatients = this.pccService
			.getPatientsByFacilityID(
				args
			)
			.pipe(
				tap((pccPatientsResponse: any) => {
					this.pccPatients = pccPatientsResponse.data.data;
					this.loader.stopLoader(this.patientsSearchLoaderId);
				}),
				map((pccPatientsResponse: any) => {
					return pccPatientsResponse.data.data
						?.filter(p => !!p)
						.map((p) => ({
							...p,
							name: `${p.lastName}, ${p.firstName}`,
							pcc_patientId: String(p.patientId),
						}));
				})
			);

	}
	setFormValue(value) {
		this.selectedPatient = value.name
		this.formData.patient = value
	}
	setDOS(dateEvent: any, field: "start_date" | "end_date"): void {
		this.formData[field] = moment(dateEvent.value);
	  }
	onchangeTime(event: {hour: string; min: string}, type: string) {
		const {hour, min} = event;
	
		const startDate = moment(this.formData.start_date, "YYYY-MM-DD");
		const endDate = moment(this.formData.end_date, "YYYY-MM-DD");
	
		if (type === "start") {
		  const momentStartDateTime = startDate
			.hour(Number(hour))
			.minute(Number(min));
		  this.formData.start_date = momentStartDateTime.format("YYYY-MM-DD");
		  this.formData.start_time = momentStartDateTime.format("HH:mm a");
		} else if (type === "end") {
		  const momentEndDateTime = endDate.hour(Number(hour)).minute(Number(min));
		  this.formData.end_date = momentEndDateTime.format("YYYY-MM-DD");
		  this.formData.end_time = momentEndDateTime.format("HH:mm a");
		}
	}
	onSelectProvider(item = null) {
		// if (item) {
			this.loader.startLoader(this.locationSearchLoaderId);
		// 	this.provideModel = item.name;
		// 	this.formData.providerType = item._id;
		// 	this.formData.facilityType = ''
			this.getFacilities();
		// }
	}
	deleteAppointment() {
		this.formData.type = "delete"
		let dialogReference = this.dialog.open(DialogConfirmationComponent, {
			data: {
				message: 'Are you sure you want to delete this appointment?'
			}
		});
		dialogReference.afterClosed().subscribe(async result => {
			if (result === "true") {
				this.dialogRef.close(this.formData);
			}
		}), () => this._toastr.error('Something went wrong removing Patient Census', 'Failed')
	}
	openAddPatientDialog() {
		const dialogRef = this.dialog.open(AppointmentAddPatientDialogComponent, {
			data:{
        showFacility: false,
      }
		});

		dialogRef.afterClosed().subscribe(async result => {
			if (result) {
				await this.savePatient(result)
			}
		});
	}
	savePatient(data) {
		const patient: any = {
			// userInfo
			id: this.currentUser._id,
			auth_key: this.currentUser.auth_key,
			usertype: this.currentUser.user_type,
			first_name: data.first_name,
			middle_name: data.middle_name || "",
			last_name: data.last_name,
			patient_ssn: data.patient_ssn || "",
			mrn: data.mrn,
			email: data.email || "",
			phone: data.phone || "",
			date_of_birth: data.date_of_birth,
			city: data.city || "",
			zip_code: data.zip_code || "",
			address: data.address || "",
			address_2: data.address_2 || "",
			state: data.state || "",
			race: data.race,
			// ste_no: data.apt || "",
			// confidential_method: data.confidential_method || "",
			employer: data.employer || "",
			// financial_class: data.financial_class || "",
			gender_identity: data.gender_identity || "",
			phone_type: data.type || "",
			gender: data.gender || "",
			facility_id: this.formData.facilityType || "",
			primary_doctor_id: this.formData.providerType || '',
			pcpFirstName : data.pcpFirstName || '',
			pcpLastName : data.pcpLastName || '',
			pcpPhoneNumber: data.pcpPhoneNumber || '',
			pcpFaxNumber: data.pcpFaxNumber || '',
			marital_status: data.marital_status || '',
			p_insurance_carrier: data.p_insurance_carrier,
			p_insurance_holder_name: data.p_insurance_holder_name,
			p_insurancePolicyNumber: data.p_insurancePolicyNumber,
			p_insuranceGroupNumber: data.p_insuranceGroupNumber,
			s_insurance_carrier: data.s_insurance_carrier,
			s_insurance_holder_name: data.s_insurance_holder_name,
			s_insurancePolicyNumber: data.s_insurancePolicyNumber,
			s_insuranceGroupNumber: data.s_insuranceGroupNumber,
			responsible_party: data.responsible_party,
			responsible_party_contact: data.responsible_party_contact,
			is_active: 'true',
		}
		this.addNewPatient(patient);
	}
	addNewPatient(patient) {
		const formData = new FormData();
		patient.date_of_birth_d = moment(patient.date_of_birth);
		patient.company_id = this.company_id;
		for (const key in patient) {
			formData.append(key, patient[key]);
		}
		formData.append('timezone', moment.tz.guess());
		this._socketService.addActivityLog({
			id: this.currentUser._id,
			screen: 'Patient Add',
			operation: `Add Patient API Call`,
			datetime: this._date_pipe.transform(new Date(), global.date_time_format)
		})
		const infoToastr = this._toastr.info('Adding new patient...', "Please wait");
		this._patientAddServie.addNewPatient(
			formData
		).subscribe((response: any) => {
			if (response.status == 200) {
				if (response.exists == true) {
					this._toastr.error(response.message, "Failed");
				} else {
					this.doInitPatients(response.data.last_name)
					this.filteredPatientsByProvider$.subscribe(patients => {
						if (patients.length > 0) {
							this.patientsByProviderControl.setValue(patients[0]); // Set the first patient as the default value
						}
					});
					this.formData.patientName = `${response.data.last_name}, ${response.data.first_name}`;
					this.selectedPatient = this.formData.patientName;
					this.formData.patient = response.data._id
					this._toastr.success(response.message, "Success");
				}

			} else {
				this._toastr.error(response.message, "Failed");
			}
		}, console.error, () => this._toastr.clear(infoToastr.toastId))
	}
	setDateOfService($event){
		if($event){
			const time = this.formData.time.replace(/\s+/g, '');
			this.formData.date = $event.value + " " + time;
			this.formData.dos = $event.value
		}else{
			this.formData.dos = false
		}
	}
	onSelectAppointmentType(){
		const selectedAppointment = this.appointmentTypes.find(appointment=>appointment._id === this.formData.appointmentType)
		this.formData.duration = selectedAppointment.duration
	}

	resetArrays(): void {
		this.selectedDays = [];
		this.selectedWeekdays = [];
		this.selectedWeeks = [];
	}
	isSelected(
		item: number | string,
		category: "days" | "weekdays" | "weeks",
	  ): boolean {
		const categoryArray = this.getCategoryArray(category);
	
		// Add logic to handle special cases based on the category
		if (category === "weekdays" && typeof item === "string") {
		  // Convert weekday string to its index
		  item = this.getWeekdayIndex(item);
		} else if (category === "weeks" && typeof item === "string") {
		  // Convert week of month string to its index
		  item = this.getWeekOfMonthIndex(item);
		} else if (category === "weekdays" && typeof item === "number") {
		  item = this.isValueAtIndexMatch(item);
		}
		return categoryArray.includes(item);
	}
	toggleSelection(
		item: number | string,
		category: "days" | "weekdays" | "weeks",
	  ): void {
		if (category === "weekdays" && typeof item === "string") {
		  // Convert weekday string to its index
		  item = this.getWeekdayIndex(item);
		} else if (category === "weeks" && typeof item === "string") {
		  // Convert week of month string to its index
		  item = this.getWeekOfMonthIndex(item);
		}
		const categoryArray = this.getCategoryArray(category);
	
		const index = categoryArray.indexOf(item);
		if (index === -1) {
		  categoryArray.push(item);
		} else {
		  categoryArray.splice(index, 1);
		}
	  }	
	private getCategoryArray(
		category: "days" | "weekdays" | "weeks",
	  ): (number | string)[] {
		switch (category) {
		  case "days":
			return this.selectedDays;
		  case "weekdays":
			return this.selectedWeekdays;
		  case "weeks":
			return this.selectedWeeks;
		  default:
			return [];
		}
	}
	getWeekdayIndex(weekday: string): number {
		return this.weekdays.indexOf(weekday);
	}
	
	getWeekOfMonthIndex(weekOfMonth: string): number {
		const index = this.weeksOfMonth.indexOf(weekOfMonth) + 1; // Adding 1 to make it 1-indexed
		return index == 5 ? -1 : index;
	}
	isValueAtIndexMatch(targetIndex: number): string {
		// Check if the array has the target index and if the value at that index matches the provided value
		return this.weekdays[targetIndex];
	}
	addDurationToTime(startTime: string, duration: number): string {
		const time = moment(startTime, "HH:mm");

		// Add the duration (in minutes) to the time
		time.add(duration, 'minutes');

		// Return the new time as a string in the same format
		return time.format("HH:mm");
	}
    onSelectrecurrenceRule(){
        this.isDisabledEndDate = this.formData.repeating === "Does not Repeat";

    }
}