import { FeedbackService } from './../../feedback/feedback.service';
import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-feedback',
  templateUrl: './dialog-feedback.component.html',
  styleUrls: ['./dialog-feedback.component.css']
})
export class DialogFeedbackComponent implements OnInit {
  imageType: boolean;
  videoType: boolean;
  imageUrl;
  videoUrl;
  obj: {
    status: false;
    _id;
  };
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private feedbackService: FeedbackService) {   }
  
  ngOnInit(): void {
    if(this.data?.attachment_type?.includes('image')){
      this.imageType = true;
      console.log(this.data.attachment_link);
      this.imageUrl = this.data.attachment_link;
    }
    if(this.data?.attachment_type?.includes('video')){
      this.videoType = true;
      this.videoUrl = this.data.attachment_link;
    }
  }

  approve(status) {
    this.data.acceptance_status = status;
    this.feedbackService.updateStatus(this.data).subscribe(res => {
      console.log(res);
    });
  }
}
