<app-header *ngIf="!admin && !company"></app-header>

<main>
    <div class="container patients-page flex flex-col">
        
        <div class="flex-grow">
            <mat-card class="patients-tabs">
                <mat-card-content>
                    <nav mat-tab-nav-bar>
                        <a mat-tab-link class="small-links" *ngIf="currentUser.has_pcc_account"
                        routerLinkActive #rla2="routerLinkActive"
                        [active]="rla2.isActive"
                        [routerLink]="'/patients/pcc-list'">PointClick Care Patients</a>
                        <a mat-tab-link class="small-links" *ngIf="currentUser.other_settings.show_matrixCare"
                        routerLinkActive #rla3="routerLinkActive"
                        [active]="rla3.isActive"
                        [routerLink]="'/patients/matrix-list'">MatrixCare Patients</a>
                        <a mat-tab-link class="small-links"
                        routerLinkActive #rla="routerLinkActive"
                        [active]="rla.isActive"
                        [routerLink]="'/patients/list'">My Patients</a>
                        <a mat-tab-link class="small-links" *ngIf="patientSearchAcrossCompany"
                        routerLinkActive #rla4="routerLinkActive"
                        [active]="rla4.isActive"
                        [routerLink]="'/patients/advance-search-list'">Advanced Search</a>
                    </nav>
                </mat-card-content>
            </mat-card>
            <router-outlet (activate)="onOutletLoaded($event)"></router-outlet>

        </div>
  

</div>


</main>