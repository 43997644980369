import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-snf-wc-nurse-association',
  templateUrl: './snf-wc-nurse-association.component.html',
  styleUrls: ['./snf-wc-nurse-association.component.css']
})
export class SnfWcNurseAssociationComponent implements OnInit {

  tabIndex = 0;
  constructor() { }

  ngOnInit(): void {
  }

  onTabChange($event) {
    this.tabIndex = $event.index;
  }

}
