import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from "../../../environments/api-url";
import * as global from '../../includes/global';

@Injectable({
  providedIn: 'root'
})
export class SnfWcFacilityAssociationService {

  constructor(private _httpclient: HttpClient) { }

  getFacilityAssociatedSnfWcNurses(filter: any, nurseProjection: any, facilityProjection: any) {
    return this._httpclient.post(global.url + API_URL.SnfWcNurseFacilities.getAssociatedSnfWcNurseFacilities, { filter: filter, nurseProjection: nurseProjection, facilityProjection: facilityProjection })
  }

  createSnfWcNurseFacilitiesRelation(filter: any, projection: any) {
    return this._httpclient.post(global.url + API_URL.SnfWcNurseFacilities.createSnfWcNurseFacilitiesRelation, { filter: filter, projection: projection })
  }

  deleteSnfWcNurseFacilitiesRelation(filter: any, projection: any = null) {
    return this._httpclient.post(global.url + API_URL.SnfWcNurseFacilities.deleteSnfWcNurseFacilitiesRelation, { filter: filter, projection: projection })
  }

}
