import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Template } from "../../templates/templates.component";
import { TemplatesService } from "../../templates/templates.service";
import { PatientDetailService } from "../../patient-detail-list/patient-detail-list.service";
import * as global from "../../global";
import moment from "moment";
import { CommonService } from "src/app/services/common.service";
import { DashboardService } from "../../dashboard/dashboard.service";
import { Note } from "../../note-editor/model/note";
import { lastValueFrom } from "rxjs";
import { ToastrService } from "ngx-toastr";

// UPLOAD FILE DIALOG
export interface TranscriptionTemplateDialogData {
    currentUser: any;
    currentTemplate: Template;
    facilityId: string;
    patientId: string;
    providerId: string;
    note: Note;
}

@Component({
    selector: "app-transcription-template-dialog",
    templateUrl: "./transcription-template-dialog.component.html",
    styleUrls: ["./transcription-template-dialog.component.css"]
})
export class TranscriptionTemplateDialog implements OnInit {
    global = global;
    currentTemplate: Template;
    currentUser: any;
    facilityId: string;
    patientId: string;
    providerId: string;
    templates: Template[] | any = [];
    filteredTemplatesByFacilityId: Template[] = [];
    recentNotes: Array<any> = [];
    template_type: string;
    systemTemplates: any;
    associatedProviders: any = [];
    associatedProviderTemplates: Template[] = [];
    note: Note;
    associatedProviderTemplatesLoaded = false;

    constructor(
        public dialogRef: MatDialogRef<TranscriptionTemplateDialog>,
        private _templatesService: TemplatesService,
        private _patientsDetailService: PatientDetailService,
        @Inject(MAT_DIALOG_DATA) public data: TranscriptionTemplateDialogData,
        private _dashboardService: DashboardService,
        private commonService: CommonService,
        private _toastr: ToastrService,
    ) {
        if (data) {
            this.currentUser = data.currentUser;
            this.currentTemplate = data.currentTemplate;
            this.facilityId = data.facilityId;
            this.patientId = data.patientId;
            this.providerId = data.providerId;
            this.note = data.note;
        }
    }

    async ngOnInit(): Promise<void> {
        await this.initTemplates();
        this.initRecentNotes(this.patientId);
        // this.getP2PRelation();
        this.getAssociatedProvidersForTemplates();
    }
    // async initTemplates() {
    //     const templatesResponse: any = await this._templatesService
    //         .getTemplatesByFacilityId(this.facilityId, this.providerId, null, false)
    //         .toPromise();
    //     if ((templatesResponse.status = 200)) {
    //         this.templates = templatesResponse.data;
    //         console.log("this.templates", this.templates);
    //         this.templates.forEach((temp) => {
    //             if (temp.template_type == "user_defined") {
    //                 if (temp.template_type == "user_defined") {
    //                     if (temp.hidden_for.length == 0) {
    //                         temp.is_active = true;
    //                     }
    //                     temp.hidden_for.forEach((element) => {
    //                         if (element == this.currentUser._id) {
    //                             temp.is_active = false;
    //                         } else {
    //                             temp.is_active = true;
    //                         }
    //                     });
    //                 }
    //             }
    //         });
    //         const SystemtemplatesResponse: any = await this._templatesService
    //             .getSystemTemplatesByFacilityId(
    //                 this.facilityId,
    //                 this.providerId,
    //                 false
    //             )
    //             .toPromise();
    //         this.systemTemplates = SystemtemplatesResponse.data;
    //         // this.filteredTemplatesByFacilityId = this.facilityId ? templatesResponse.data.filter(t => t.facility_id === this.facilityId) : this.templates;
    //         // this.filteredTemplatesByFacilityId = this.facilityId ? templatesResponse.data.filter(t => t.facility_ids.includes(this.facilityId)) : this.templates;
    //         this.filteredTemplatesByFacilityId = this.templates.filter((t) =>
    //             t.facility_ids.includes(this.facilityId)
    //         );
    //         this.systemTemplates.forEach((temp) => {
    //             this.templates.push(temp);
    //             this.filteredTemplatesByFacilityId.push(temp);
    //         });
    //         // console.log('this.filteredTemplatesByFacilityId', this.filteredTemplatesByFacilityId);
    //         this.filteredTemplatesByFacilityId.forEach((temp) => {
    //             if (temp.template_type == "system_defined") {
    //                 if (temp.template_type == "system_defined") {
    //                     if (temp.hidden_for.length == 0) {
    //                         temp.is_active = true;
    //                     }
    //                     temp.hidden_for.forEach((element) => {
    //                         if (element == this.currentUser._id) {
    //                             temp.is_active = false;
    //                         } else {
    //                             temp.is_active = true;
    //                         }
    //                     });
    //                 }
    //             }
    //         });
    //         this.filteredTemplatesByFacilityId = this.templates.filter(
    //             (t) => t.is_active == true
    //         );
    //     }
    // }

    async initTemplates() {
        const filter = {
            facility_ids: this.facilityId,
            $or: [
                { template_type: "system_defined", company_id: this.currentUser.company_id, },
                { template_type: "user_defined", owner_id: this.providerId }
            ],
            is_deleted: "false",
            hidden_for: { $nin: [this.currentUser._id] }
        };
        const project = { title: 1, template_type: 1, hidden_for: 1, template_text: 1, assoc_cpt_ids: 1 };
        const populate = { path: "assoc_cpt_ids", select: "code description" };
        const response: any = await lastValueFrom(this._templatesService.getTemplates(filter, project, populate));
        if (response.status === 200) {
            this.templates = response.data;
        }
        else {
            this._toastr.error('Something Went Wrong', 'Error');
        }
    }

    async initRecentNotes(patient_id: string, maxNotesCount = 5) {
        return new Promise(async (resolve, reject) => {
            try {
                const payload = {
                    id: this.currentUser._id,
                    auth_key: this.currentUser.auth_key,
                    usertype: this.currentUser.user_type,
                    patient_id
                };
                const response: any = await this._patientsDetailService
                    .getTranscriptionsByPatientId(payload)
                    .toPromise();

                if (response.status === 200) {
                    for (
                        let i = 0;
                        i < maxNotesCount && i < response.data.array.length;
                        i++
                    ) {
                        response.data.array[i].transcribtion_title =
                            this.commonService.cleanTranscriptionTitle(
                                response.data.array[i].transcribtion_title
                            );
                        if (
                            this.commonService
                                .cleanTranscriptionTitle(
                                    response.data.array[i].transcribtion_title
                                )
                                ?.toLowerCase() != "no title"
                        )
                            if (response.data.array[i]._id !== this.note._id) {
                                this.recentNotes.push(response.data.array[i]);
                            }
                    }
                    if(this.currentUser.portal_type === global.PORTAL_TYPE.NON_WC) {
                        if(this.recentNotes.length == 1) {
                            this.recentNotes[0]['initialNote'] = `[Initial Note]`;
                        } else {
                            let initinalNote = await this.getInitialNote(response.data.array);
                            initinalNote['initialNote'] = `[Initial Note]`;
                            if(this.commonService.cleanTranscriptionTitle(initinalNote.transcribtion_title)?.toLowerCase() != "no title" && initinalNote._id !== this.note._id) 
                            this.recentNotes.push(initinalNote);
                        }
                    }

                    resolve(this.recentNotes);
                } else {
                    reject(response.message);
                }
            } catch (err) {
                reject(err);
            }
        });
    }
    getInitialNote(notes) {
        if (notes.length === 0) {
            return null;
        }
        let oldestNote = notes[0];
        for (const note of notes) {
            if(note.date_obj && note.date_obj.year && note.date_obj.month && note.date_obj.day && note.date_obj.hour && note.date_obj.minute) {
                const noteDate = new Date(
                    note.date_obj.year,
                    note.date_obj.month,
                    note.date_obj.day,
                    note.date_obj.hour,
                    note.date_obj.minute
                );
                const oldestNoteDate = new Date(
                    oldestNote.date_obj.year,
                    oldestNote.date_obj.month,
                    oldestNote.date_obj.day,
                    oldestNote.date_obj.hour,
                    oldestNote.date_obj.minute
                );
                if (noteDate < oldestNoteDate) {
                    oldestNote = note;                
                }
            }
        }
        return oldestNote;
    }
    async onSelectTemplate(e) {
        const template = this.templates.find((template) => template._id === e.value);
        const response: any = await lastValueFrom(this._templatesService.decryptText(template.template_text));
        if (response.status === 200) {
            template.template_text = response.data;
            this.dialogRef.close({
                template: template
            });
        }
        else {
            this._toastr.error('Something Went Wrong', 'Error');
        }
    }
    onAossociateProviderTemplateSelect(e) {
        this.dialogRef.close({
            template: this.associatedProviderTemplates.find(
                (t) => t._id === e.value
            )
        });
    }
    onSelectNote(e) {
        this.dialogRef.close({
            note: this.recentNotes.find((n) => n._id === e.value)
        });
    }

    getDOS(note) {
        // if(trans?.audio_id_ref){
        if (note.date_obj) {
            let { year, month, day, hour, minute } = note.date_obj;
            if (month < 10) {
                month = "0" + month;
            }
            if (day < 10) {
                day = "0" + day;
            }
            let str = `${month}-${day}-${year}`;
            if (hour && minute) {
                if (minute < 10) {
                    minute = `0${minute}`;
                }
                if (hour < 10) {
                    hour = `0${hour}`;
                }
                str += ` ${hour}:${minute}`;
            }
            return moment(str).format('MM DD, YYYY');
        } else if (note?.filterServiceDate) {
            return moment(new Date(note.filterServiceDate)).format("MM DD, YYYY");
        } else {
            return "";
        }
    }
    getP2PRelation() {
        this._dashboardService.getP2PRelation().subscribe((response: any) => {
            if (response.status === 200) {
                this.associatedProviders = response.data.assoc_provider_id;
            }
        });
    }

    getAssociatedProvidersForTemplates() {
        this._dashboardService
            .getAssociatedProvidersForTemplates(
                this.note.provider._id,
                this.note.facility._id
            )
            .subscribe((response: any) => {
                if (response.status === 200) {
                    // console.log("this",response.data);
                    this.associatedProviders = response.data;
                }
            });
    }

    getAssociatedProviderTemplate(userId) {
        this.associatedProviderTemplatesLoaded = false;
        if (userId === undefined) {
            this.associatedProviderTemplates = [];
            this.associatedProviderTemplatesLoaded = true;
            return;
        }
        this._templatesService
            .getTemplatesByFacilityId(this.facilityId, userId)
            .subscribe((response: any) => {
                if (response.status === 200) {
                    // console.log(response.data);
                    this.associatedProviderTemplates = response.data;
                    this.associatedProviderTemplatesLoaded = true;
                }
            });
    }
}
