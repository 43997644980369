<div class="container">
    <mat-card >
        
        <div style="margin-top: 20px;height: 80vh;overflow: auto;" class="flex justify-start items-start" >
            <div [ngStyle]="{ 'width' : !showLogs? '100%' : '70%' }">
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="error_code">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Error Code</th>
                    <td mat-cell *matCellDef="let element"> 
                        {{element.error_code}}
                    </td>
                </ng-container> 
          
                <ng-container matColumnDef="error_msg">
                    <th mat-header-cell *matHeaderCellDef> Error Message </th>
                    <td mat-cell *matCellDef="let element ; let i = index;">
                        {{element.error_message}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="support_msg">
                    <th mat-header-cell *matHeaderCellDef> Support Message </th>
                    <td mat-cell *matCellDef="let element ; let i = index;">
                        {{element.support_message}}                       
                    </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let element ; let i = index;">
                        <span class="material-icons"
                        matTooltip="View" matTooltipPosition="above" style="cursor: pointer;" (click)="onSelectErrorLog(element.error_code);">
                        remove_red_eye
                    </span>
                    </td>
                </ng-container>
          
          
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
            <div *ngIf="showLogs" style="height: 80vh;overflow: auto;width: 30%;">
                <div>

                    <div >
                        <div style="background-color: #cedbdf; height: 56px; padding-left: 15px; padding-right: 15px;margin-left: 5px;"
                            class="flex justify-between items-center">
                            <b style="font-size: 16px;">Error Code: {{errorCode}}</b>

                            <span style="color: firebrick; cursor: pointer;" (click)="closeSideBar()">Close</span>
                        </div>

                        <div class="flex flex-col" style="padding-left: 0px; padding-right: 0px; margin-left: 5px;">
                            <ul>
                                <ng-container *ngIf="selectedErrorCode.length > 0">
                                    <li *ngFor="let log of selectedErrorCode" class="flex justify-between items-center">
                                        <span><b>Error Message: </b>{{log.error_msg}}<br/><b>Error Stack: </b>{{log.errorStack}}<br/><b>Created At: </b>{{log.createdAt |  date: 'dd/MM/yyyy'}}</span>
                                        
                                    </li>
                                </ng-container>
                                
                                <ng-container *ngIf="selectedErrorCode.length == 0">
                                    <li class="flex justify-between items-center">
                                        <span>No Data found</span>
                                        
                                    </li>
                                </ng-container>
                                
                            </ul>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </mat-card>
</div>
